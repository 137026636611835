import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { GroupOriginResponse } from '../../responses/medical-record/group-origin.response';

@Injectable({
  providedIn: 'root'
})
export class GroupOriginService extends CommonService {

  constructor(private httpClient: HttpClient) { super(); }

  public getGroupOrigin(): Observable<GroupOriginResponse> {
    let uri = `GroupOrigin`;

    return this.httpClient.get<GroupOriginResponse>(environment.urlApiOrchestratorUser + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}