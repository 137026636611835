import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { HealthUnitGroupService } from 'src/app/shared/services/API/user/health-unit-group.service';
import { BIProfileService } from 'src/app/shared/services/API/business-intelligence/bi-profile.service';
import { ReportProfileRequest } from 'src/app/shared/services/requests/business-intelligence/report-profile.request';
import { ProfileService } from 'src/app/shared/services/API/user/profile.service';
import { ListProfileStruct } from 'src/app/shared/services/structs/user/list-profile.struct';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ReportProfileStruct } from 'src/app/shared/services/structs/business-intelligence/report-profile.struct';
import { ProfileExceptionStruct } from 'src/app/shared/services/structs/business-intelligence/profile-exception.struct';

@Component({
  selector: 'app-business-intelligence-config',
  templateUrl: './business-intelligence-config.component.html',
  styleUrls: ['./business-intelligence-config.component.css']
})
export class BusinessIntelligenceConfigComponent implements OnInit {

  constructor(
    private bIProfileService: BIProfileService,
    private profileService: ProfileService,
    private alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.business_intelligence;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.bi_lista_relatórios;
  public isFirstLoading: boolean = false;
  public isLoading: boolean = false;

  public isMaster: boolean = false;
  public idHealthUnitMaster: number;
  public listHealthUnitGroup: any[];
  public listProfile: ListProfileStruct[];
  public listReportProfile: ReportProfileStruct[];
  public listProfileException: ProfileExceptionStruct[];
  public model: UntypedFormGroup;
  public modelExceptions: UntypedFormGroup;

  ngOnInit(): void {
    this.isFirstLoading = true;
    this.model = this.formBuilder.group({
      profiles: this.formBuilder.array([]),
      profileExceptions: this.formBuilder.array([]),
    });

    let token = JSON.parse(sessionStorage.getItem("token"));
    this.isMaster = !token.isHealthUnitGroupMaster ? false: token.isHealthUnitGroupMaster;
    this.listHealthUnitGroup = token.listHealthUnitGroup;

    this.populateProfileSelect();
    this.populatePage();
  }

  populatePage() {
    this.bIProfileService.GetConfig().subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.isFirstLoading = false;
      this.listReportProfile = response.listReportProfile;
      this.listReportProfile.forEach((x) => {
        (this.model.controls['profiles'] as UntypedFormArray).push(this.newReportProfile(x.idReport, x.idProfile, x.reportName))
      });
      this.listProfileException = response.listProfileException;
      if (this.listProfileException && this.listProfileException.length > 0){
        this.listProfileException.forEach((x) => {
          (this.model.controls['profileExceptions'] as UntypedFormArray).push(this.newProfileException(x.idProfile, x.listIdHealthUnit));
        });
      }
      if (!this.listProfileException || this.listProfileException.length == 0) {
        this.addNext();
      }
      this.isFirstLoading = false;
      this.isLoading = false;
    },
      (error) => {
        this.isFirstLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  profiles(): UntypedFormArray {
    return this.model.get('profiles') as UntypedFormArray;
  }

  createInput() {
    return this.formBuilder.group({
      idProfile: [null],
      listIdHealthUnit: [null],
    })
  }

  addNext() {
    (this.model.controls['profileExceptions'] as UntypedFormArray).push(this.createInput());
  }

  removeException(index: number) {
    (this.model.controls['profileExceptions'] as UntypedFormArray).removeAt(index);
    if (this.model.get('profileExceptions')['controls'].length == 0){
      this.addNext();
    }
  }

  newReportProfile(idReport: number, idProfile: number[], reportName: string): UntypedFormGroup {
    return this.formBuilder.group({
      idReport,
      idProfile: [idProfile],
      reportName,
    });
  }

  newProfileException(idProfile: number, listIdHealthUnit: number[]): UntypedFormGroup {
    return this.formBuilder.group({
      idProfile: [idProfile],
      listIdHealthUnit: [listIdHealthUnit],
    });
  }

  submit() {
    if (this.isLoading) {
      return;
    }

    if (this.model.invalid){
      this.alertService.show('Erro', 'Preencha todos os campos em vermelho', AlertType.error);
      return;
    }

    this.isLoading = true;

    let request: ReportProfileRequest = new ReportProfileRequest();
    request.listReportFunctionProfile = [];
    request.listProfileException = [];

    this.model.get('profiles').value.forEach((x, i) => {
      var reportProfile = new ReportProfileStruct();
      reportProfile.idProfile = x.idProfile ? x.idProfile : [];
      reportProfile.idReport = x.idReport;
      request.listReportFunctionProfile.push(reportProfile);
    });

    if (this.isMaster) {
      this.model.get('profileExceptions').value.forEach(x => {
        if (x.idProfile && (x.listIdHealthUnit && x.listIdHealthUnit.length > 0)) {
          let profileException = new ProfileExceptionStruct();
          profileException.idProfile = x.idProfile;
          profileException.listIdHealthUnit = x.listIdHealthUnit;
          request.listProfileException.push(profileException);
        }
      });

    }
    request.isMaster = this.isMaster;

    this.bIProfileService.SaveConfig(request).subscribe((response) => {
      this.isLoading = false;
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.alertService.show('Sucesso', "Configurações salvas com sucesso", AlertType.success);
      this.isLoading = false;
    },
      (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  updateRequirements(event: any, i: number){
    if (event.source.value){
      this.model.get('profileExceptions')['controls'][i].get('idProfile').setValidators(Validators.required);
      this.model.get('profileExceptions')['controls'][i].get('idProfile').updateValueAndValidity();
      this.model.get('profileExceptions')['controls'][i].get('listIdHealthUnit').setValidators(Validators.required);
      this.model.get('profileExceptions')['controls'][i].get('listIdHealthUnit').updateValueAndValidity();
    }
  }

  populateProfileSelect() {
    this.profileService.listAllProfile().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.listProfile = response.listProfile;
    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }
}