<div>
    <header>
        <h1 class="title-name">Atendimento Remoto</h1>
        <div class="header-logo">
            <div class="header-logo-content">
                <img class="client-brand" src="assets/images/logo-colorido.png">
            </div>
        </div>
    </header>
    <body class="body-container">

        <div class="body-content">
            <div class="white-container">
                <div class="welcome-title">
                    <h1>Seja bem-vindo(a) ao <b>Atendimento Remoto</b></h1>
                </div>
                <div class="welcome-img">
                    <img src="assets/images/welcome-remote.png" />
                </div>
                <hr>
                <div class="welcome-content">
                    <p>Para iniciar o seu atendimento, clique no botão abaixo.</p>
                </div>
                <div class="col-12">
                    <button mat-flat-button type="button" color="primary" class="btn-block enter-button" (click)="enter()">
                        <span>ENTRAR</span>
                    </button>
                </div>
            </div>
        </div>
        
    </body>
</div>