import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EpisodeStatusEnum } from 'src/app/shared/enum/episode-status.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { OccupiedBedService } from 'src/app/shared/services/API/orchestrator-patient/occupied-bed.service';
import { PresenceReportService } from 'src/app/shared/services/API/orchestrator-patient/presence-report.service';
import { EpisodeReleaseRequest } from 'src/app/shared/services/requests/orchestrator-patient/episode-release.request';
import { PostPresenceReportRequest } from 'src/app/shared/services/requests/orchestrator-patient/post-presence-report.request';

@Component({
  selector: 'app-episode-administrative-release-confirm-modal',
  templateUrl: './episode-administrative-release-confirm-modal.component.html',
  styleUrls: ['./episode-administrative-release-confirm-modal.component.css']
})
export class EpisodeAdministrativeReleaseConfirmModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<EpisodeAdministrativeReleaseConfirmModalComponent>,
  private alertService: AlertService,
  private presenceReportService: PresenceReportService,
  private occupiedBedService: OccupiedBedService,
  ) { }

  public idStatusEpisodeInternar:number = EpisodeStatusEnum.FinalizadoInternar.valueOf();
  public idStatusEpisodeAlta:number = EpisodeStatusEnum.FinalizadoAlta.valueOf();
  public idStatusEpisodeAltaAdministrativa:number = EpisodeStatusEnum.FinalizadoAltaAdministrativa.valueOf();
  public isLoading:boolean;

  ngOnInit(): void {
    this.isLoading = false;
  }
  
  close(){
    this.matDialogRef.close(); 
  }
  
  clickConfirm(){

    this.isLoading = true;

    this.episodeRelease();
  }
  
  clickCancel(){
    this.matDialogRef.close(); 
  }

  episodeRelease(){
    this.isLoading = true;   
    let episodeReleaseRequest: EpisodeReleaseRequest = new EpisodeReleaseRequest();

    episodeReleaseRequest.idEpisode = this.data.episode.idEpisode;
    episodeReleaseRequest.idEpisodeStatus = this.data.episode.idStatus;
    episodeReleaseRequest.idBed = this.data.idBed? this.data.idBed : null;

    this.occupiedBedService.episodeAdministrativeRelease(episodeReleaseRequest).subscribe((response)=>{      
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      let postPresenceReportRequest = new PostPresenceReportRequest()
      if (this.data.episode != null) {
        postPresenceReportRequest.idStatus = this.data.episode.idStatus;
        postPresenceReportRequest.idEpisode = this.data.episode.idEpisode;

        this.openCreateStatementOfAttendance(postPresenceReportRequest);
      }

      this.alertService.show('Sucesso', "Episódio liberado com sucesso!", AlertType.success);
      this.isLoading = false;
      this.matDialogRef.close({release: true}); 
    },
    (error)=>{
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  openCreateStatementOfAttendance(postPresenceReportRequest: PostPresenceReportRequest) {
    this.presenceReportService.postReportPDF(postPresenceReportRequest).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      });
  }
}
