<div class="sub-section" *ngIf="listPrescription != null && listPrescription.length > 0">
    <h3>Medicamentos não padronizados</h3>
</div>
<div class="card-list" *ngIf="listPrescription != null && listPrescription.length > 0">
    <div class="row row-list">
        <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listPrescription; let index = index;">
            <div class="card-item">
                <div class="infos">
                    <span class="primary">{{item.description}}</span>
                    <span class="secundary">Quantidade: {{item.quantity}}</span>
                    <span class="secundary">Frequência: {{item.frequency}}</span>
                    <span class="secundary">Observação: {{item.observation}}</span>
                </div>
                <div class="actions">
                    <a>
                        <mat-icon aria-hidden="false" aria-label="Editar"
                            (click)="openPrescriptionModal(item)">visibility
                        </mat-icon>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="listPrescription == null || listPrescription.length == 0">
    <p>Este atendimento não possui prescrições externas!</p>
</div>