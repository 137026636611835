<div mat-dialog-content>
    <app-loading-list *ngIf="isFirstLoading"></app-loading-list>
    <div *ngIf="!isFirstLoading" class="mat-dialog-content">
        
        <div *ngIf="this.lastTriage">
            <div class="section-container">
                <div class="title-header">
                    <h1>Atendimento anterior do paciente</h1>
                </div>
                <div class="row section">
                    <div *ngIf="lastTriage.startClassification">
                        <span><b>Data e hora do atendimento anterior: </b></span>
                        <span>{{lastTriage.startClassification | date: 'dd/MM/yyyy HH:mm'}}</span>
                    </div>
                    <div *ngIf="lastTriage.complaint">
                        <span><b>Queixa: </b></span>
                        <span>{{lastTriage.complaint}}</span>
                    </div>
                    <div *ngIf="lastTriage.flowchart">
                        <span><b>Fluxograma: </b></span>
                        <span>{{lastTriage.flowchart}}</span>
                    </div>
                    <div *ngIf="lastTriage.discriminator">
                        <span><b>Discriminador: </b></span>
                        <span>{{lastTriage.discriminator}}</span>
                    </div>
                    <div *ngIf="healthUnitNameByLastTriage">
                        <span><b>Unidade de atendimento: </b></span>
                        <span>{{healthUnitNameByLastTriage}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-footer class="footer-modal">
    <div *ngIf="isLoading == false" class="col-12 col-md-4">
        <button mat-flat-button type="button" color="accent" class="btn-block" (click)="closeModal()">
            <span>Fechar</span>
            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
        </button>
    </div>
</div>