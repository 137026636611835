<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-flow-modal-list">
            <h2>Selecione o paciente!</h2>
         </div>
         
         <div class="body-flow-modal-list">
            <div class="card-list">
                <div class="row list-patient-content">
                    <div *ngFor="let patient of data" class="col-4" (click)="clickSelect(patient)">
                        <div class="card-item patient-item">
                            <div class="infos">
                                <span class="primary">{{patient.patientName}}</span>
                                <span class="secundary">{{patient.cpf.cpfNumber}}</span>
                                <span class="secundary">{{patient.cns}}</span>
                                <!-- <span class="secundary">{{patient.birthDate | date: 'dd/MM/yyyy HH:mm'}}</span> -->
                                <span class="secundary">{{patient.motherName}}</span>
                                <span class="secundary">{{patient.fatherName}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         </div>
    </div>
</div>

