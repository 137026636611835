import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ExamTypeService } from 'src/app/shared/services/API/SADT/exam-type.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { ExamTypeRequest } from 'src/app/shared/services/requests/SADT/exam-type.request';

@Component({
  selector: 'app-exam-type-register',
  templateUrl: './exam-type-register.component.html',
  styleUrls: ['./exam-type-register.component.css']
})
export class ExamTypeRegisterComponent implements OnInit {

  constructor(private formBuilder: UntypedFormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private examTypeService: ExamTypeService) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.sadt;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.master_exam;

  public model: UntypedFormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;
  public actualServiceModule: number;

  public masks: Masks;
  public idExamType: number;
  public examTypeRequest: ExamTypeRequest;

  ngOnInit(): void {

    this.isLoading = false;

    this.model = this.formBuilder.group({
      name: ['', [Validators.required]],
      status: ['', [Validators.required]],
      description: [''],
    });

    this.masks = this.maskService.getMasks();

    this.examTypeRequest = new ExamTypeRequest();

    this.idExamType == null;
    this.isUpdate == false;
    if (this.activatedRoute.snapshot.paramMap.get('idExamType'))
      this.idExamType = parseInt(this.activatedRoute.snapshot.paramMap.get('idExamType'));

    if (this.idExamType != null) {
      this.isFirstLoading = true;
      this.isUpdate = true;
      this.populateExamTypeData();
    }
  }

  submit() {
    if(this.isLoading){
      return;
    }

    if(!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    this.isLoading = true;

    this.examTypeRequest.description = this.model.get('description').value;
    
    if (this.model.get('status').value === "true") {
      this.examTypeRequest.isActive = true;
    }
    if (this.model.get('status').value === "false") {
      this.examTypeRequest.isActive = false;
    }
  
    this.examTypeRequest.examTypeName = this.model.get('name').value;

    if (this.isUpdate)
      this.updateExamType();
    else
      this.createExamType();
  }

  populateExamTypeData() {
    this.examTypeService.getExamType(this.idExamType).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.model.get('name').setValue(response.examType.examTypeName);
      this.model.get('status').setValue(response.examType.isActive.toString());
      this.model.get('description').setValue(response.examType.description);

      this.isLoading = false;
      this.isFirstLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  updateExamType() {
    this.examTypeService.updateExamType(this.idExamType, this.examTypeRequest).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/sadt/exam-type']);

    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  createExamType() {
    this.examTypeService.createExamType(this.examTypeRequest).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/sadt/exam-type']);
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }
}

