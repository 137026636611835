<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-confirm-modal-list">
            <div class="img-head">
                <img src="assets/images/exclamation-circle.png" />
            </div>
            <h1><b>CPF não encontrado!</b></h1>
        </div>
        <div class="footer-confirm-modal-list">
            <button mat-flat-button color="primary" class="btn-block" (click)="clickTryAgain()">
                <span *ngIf="isLoading == false">Tentar novamente</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
        </div>
    </div>
</div>