<div mat-dialog-container class="content">
    <h1 class="txtCenter title-text">Está sala já está conectada em outro lugar!</h1>
    <h2 class="txtCenter">Deseja fechar a outra sala?</h2>
    <h4 class="txtCenter">Se estiver ocorrendo um atendimento ele será finalizado!</h4>
    <div class="row">
        <div class="col-6">
            <button mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Cancelar</button>
        </div>
        <div class="col-6">
            <button mat-flat-button (click)="clickConfirm()" color="primary" class="btn-block">
                <span>Sim</span>
            </button>
        </div>
    </div>
</div>