import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MemedPrescriptionStruct } from 'src/app/shared/services/structs/medical-record/memed-prescription.struct';
import { ReadExternalPrescriptionStruct } from 'src/app/shared/services/structs/orchestrator-patient/read-external-prescription.struct';

@Component({
  selector: 'app-memed-prescription-list',
  templateUrl: './memed-prescription-list.component.html',
  styleUrls: ['./memed-prescription-list.component.css']
})
export class MemedPrescriptionListComponent implements OnInit {

  @Input() listMemedPrescriptionStruct: MemedPrescriptionStruct[] = [];

  constructor(
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    
  }
}

