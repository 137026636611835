export class FavoriteNonStandardMedicineModel {
    public idFavoriteNonStandardMedicine: number;

    public idMedicFavoriteNonStandardPrescription: number;

    public description: string;

    public quantity: string;

    public frequency: string;

    public idMeasurementUnit: number;

    public measurementUnitName: string;

    public datetimeInclusion: Date;
}