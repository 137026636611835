<div mat-dialog-content>
    <div class="mat-dialog-header">
        <h3>Selecione quais documentos você deseja ver e/ou imprimir!</h3>
    </div>
    <div class="mat-dialog-content">
        <form class="form" [formGroup]="reportModel">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <ul>
                        <li>
                            <mat-checkbox formControlName="admissionReport">
                                Relatório de Admissão
                            </mat-checkbox>
                        </li>
                    </ul>
                </div>
            </div>
        </form>
        <div class="footer-panel-modal-list">
            <div class="row">
                <div class="col-12">
                    <button mat-flat-button color="primary" class="btn-block" (click)="clickOK()">Ok</button>
                </div>
            </div>
        </div>
    </div>
</div>