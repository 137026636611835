import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { ListMedicalSpecialtyResponse } from '../../responses/digital-prompt-service/list-medical-specialty.response';
import { MedicalSpecialtyRequest } from '../../requests/digital-prompt-service/medical-specialty.request';
import { MedicalSpecialtyResponse } from '../../responses/digital-prompt-service/medical-specialty.response';

@Injectable({
  providedIn: 'root'
})
export class MedicalSpecialtyService extends CommonService{
  
  constructor(private httpClient: HttpClient,) {
    super();
  }
  
  public listMedicalSpecialty(searchText: string): Observable<ListMedicalSpecialtyResponse> {
    
    let uri = 'MedicalSpecialty?'
    
    if(searchText != null)
    uri = uri+ `searchText=${searchText}&`;
    
    return this.httpClient.get<ListMedicalSpecialtyResponse>(environment.urlApiDigitalPrompt + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public listSelectMedicalSpecialty(idUser: number): Observable<ListMedicalSpecialtyResponse> {
    
    let uri = `MedicalSpecialty/idUser/${idUser}`;
    
    return this.httpClient.get<ListMedicalSpecialtyResponse>(environment.urlApiDigitalPrompt + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public getMedicalSpecialty(idMedicalSpecialty: number): Observable<MedicalSpecialtyResponse>{
    return this.httpClient.get<MedicalSpecialtyResponse>(environment.urlApiDigitalPrompt + `MedicalSpecialty/idMedicalSpecialty/${idMedicalSpecialty}`, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public createMedicalSpecialty(createMedicalSpecialtyRequest: MedicalSpecialtyRequest): Observable<ReturnStruct>{
    return this.httpClient.post<ReturnStruct>(environment.urlApiDigitalPrompt + `MedicalSpecialty`, createMedicalSpecialtyRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public updateMedicalSpecialty(idMedicalSpecialty: number, updateMedicalSpecialtyRequest: MedicalSpecialtyRequest): Observable<ReturnStruct>{
    return this.httpClient.put<ReturnStruct>(environment.urlApiDigitalPrompt + `MedicalSpecialty/idMedicalSpecialty/${idMedicalSpecialty}`, updateMedicalSpecialtyRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public deleteMedicalSpecialty(idMedicalSpecialty: number): Observable<ReturnStruct>{
    return this.httpClient.delete<ReturnStruct>(environment.urlApiDigitalPrompt + `MedicalSpecialty/idMedicalSpecialty/${idMedicalSpecialty}`, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
    
    