import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { RoomRequest } from '../../requests/flow/room.request';
import { ListRoomResponse } from '../../responses/flow/list-room.response';
import { GetRoomResponse } from '../../responses/flow/get-room.response';
import { GetListRoomResponse } from '../../responses/flow/get-list-room.response';

@Injectable({
  providedIn: 'root'
})
export class GetRoomService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getRoom(idRoom: number): Observable<GetRoomResponse> {

    let uri = `GetRoom/idRoom/${idRoom}`;

    return this.httpClient.get<GetRoomResponse>(environment.urlApiFlow + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getRoomsBySector(idSectors: string): Observable<GetListRoomResponse> {

    let uri = `GetRoom/BySectors?idSectors=${idSectors}`;

    return this.httpClient.get<GetListRoomResponse>(environment.urlApiFlow + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

}
