import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { SectorDeleteModalComponent } from './sector-delete-modal/sector-delete-modal.component';
import { SectorStruct } from 'src/app/shared/services/structs/flow/sector.struct';
import { SectorService } from 'src/app/shared/services/API/flow/sector.service';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-sector-list',
  templateUrl: './sector-list.component.html',
  styleUrls: ['./sector-list.component.css']
})
export class SectorListComponent implements OnInit {

  constructor(private sectorService: SectorService,
    private alertService: AlertService,
    public dialog: MatDialog,
    private utilService: UtilService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.master;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.master_sector;

  public listSector: SectorStruct[];

  public isLoading: boolean = true;
  public isActive: boolean;

  public searchText: string;
  ip: string = '192.168.0.2';

  ngOnInit(): void {
    this.search();
    setTimeout(() => { this.getIP(); }, 2000);
  }

  getIP() {
    this.utilService.getIP().subscribe({
      next: (response) => {
        if (response && response.ip) {
          this.ip = response.ip;
        }
      }
    });
  }

  search() {
    this.isLoading = true;

    if (this.searchText == "")
      this.searchText = null;

    this.sectorService.listSector(this.searchText, this.isActive).subscribe({
      next: (response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listSector = response.listSectorStruct;

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13)
        $this.search();
    }, 1000);
  }

  openModal(idSector) {
    const dialogRef = this.dialog.open(SectorDeleteModalComponent, {
      data: {
        idSector: idSector,
        ip: this.ip
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.deleteSector)
          this.search();
      },
      error: (error) => {
        console.log(error);
      }
    });
  }
}