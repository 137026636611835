<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <h1>Equipamentos</h1>
        <div class="white-body">
            <a mat-flat-button color="primary" class=" add-user"
                routerLink="/remote-monitoring/sync-measurer/register"><mat-icon aria-hidden="false"
                    aria-label="Plus">add</mat-icon> Cadastrar Equipamento</a>
            <div class="filters">
                <div class="row">
                    <div class="col-12 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Nome Paciente</mat-label>
                            <input matInput type="text" id="patientName" name="patientName" [(ngModel)]="patientName"
                                (keyup)="onKeySearch($event)">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>ID Equipamento</mat-label>
                            <input matInput type="text" id="syncMeasurerHash" name="syncMeasurerHash"
                                [(ngModel)]="syncMeasurerHash" (keyup)="onKeySearch($event)">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Status</mat-label>
                            <mat-select [(ngModel)]="isActive" (selectionChange)="search()">
                                <mat-option value="">Todos</mat-option>
                                <mat-option value="true">Ativo</mat-option>
                                <mat-option value="false">Inativo</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Período de Inclusão</mat-label>
                            <mat-date-range-input [rangePicker]="picker">
                                <input matStartDate [(ngModel)]="beginPeriod" placeholder="Início"
                                    (dateChange)="search()">
                                <input matEndDate [(ngModel)]="endPeriod" placeholder="Fim" (dateChange)="search()">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker></mat-date-range-picker>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="card-list" *ngIf="listSyncMeasurer != null">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listSyncMeasurer">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary">{{item.syncMeasurerHash}}</span>
                                <span class="secundary">{{item.listPatientName}}</span>
                                <span class="secundary">{{item.datetimeInclusion|date: 'dd/MM/yyyy'}}</span>
                                <span class="secundary-status last-info">{{item.isActive? "Ativo":"Inativo"}}</span>
                            </div>
                            <div class="actions">
                                <a><mat-icon aria-hidden="false" aria-label="Editar"
                                        [routerLink]="['/remote-monitoring/sync-measurer/register', {idSyncMeasurer: item.idSyncMeasurer}]">edit</mat-icon>
                                </a>
                                <a><mat-icon aria-hidden="false" aria-label="Editar"
                                        (click)="openModal(item.idSyncMeasurer)">delete_forever</mat-icon> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <app-empty-list *ngIf="listSyncMeasurer != undefined && listSyncMeasurer.length == 0"></app-empty-list>
        </div>
        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>
</div>