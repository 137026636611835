import { Component, Inject, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { VerifyStorageService } from 'src/app/shared/services/API/pharmacy/verify-storage.service';
import { StorageStruct } from 'src/app/shared/services/structs/pharmacy/storage.struct';

@Component({
  selector: 'app-observation-medicine-bar-code-modal',
  templateUrl: './observation-medicine-bar-code-modal.component.html',
  styleUrls: ['./observation-medicine-bar-code-modal.component.css']
})
export class ObservationMedicineBarCodeModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<ObservationMedicineBarCodeModalComponent>,
    public verifyStorageService: VerifyStorageService,
    private alertService: AlertService
  ) { }

  public isLoading:boolean;  
  public barcodeValue: string;
  public idStorage: number;
  public storage: StorageStruct;
  public isValid: boolean;
  ngOnInit(): void {
    this.isLoading = false;
    this.isValid = false;
  }
  
  close(){
    this.matDialogRef.close({checkMedicine: false}); 
  }
  
  clickConfirm(){
    if(!this.storage.idStorage)
      return;

    this.matDialogRef.close({checkMedicine: this.storage.idStorage}); 
  }
  
  clickCancel(){
    this.matDialogRef.close({checkMedicine: false}); 
  }

  checkMedicine(){
    if(this.isLoading)
      return;

    this.isLoading = true;
    this.verifyStorageService.verifyMedicineStorage(parseInt(this.idStorage.toString()), this.data.idMedicine).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.isLoading = false;
      this.isValid = true;
      this.storage = response.storage;
      this.alertService.show('Sucesso', "Medicação confirmada", AlertType.success);
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  private timeoutKeySearch: any = null;
  
  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      $this.checkMedicine();
    }, 1000);
  }
}
