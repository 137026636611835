import { Component, Input, OnInit } from "@angular/core";


@Component({
  selector: 'app-gauge-chart',
  templateUrl: './gauge-chart.component.html',
  styleUrls: ['./gauge-chart.component.css'],
})
export class GaugeChartComponent implements OnInit {

  constructor() { }

  @Input() title: string = 'Total';
  @Input() value: number = 0;
  @Input() showValue: string = '00:00:00';
  @Input() chartLimits: number[] = [10, 20, 30];
  @Input() textTooltip: string;

  public gaugeElement: HTMLElement;
  public firstLimit: number;
  public secondLimit: number;
  public thirdLimit: number;

  ngOnInit(): void {
    // document.documentElement.style.setProperty('--colorValue', this.colorValue);
    this.gaugeElement = document.querySelector(".gauge");
    if(this.showValue == null || this.showValue == undefined)
      this.showValue = '00:00:00';
    this.firstLimit = this.chartLimits[0];
    this.secondLimit = this.chartLimits[1];
    this.thirdLimit = this.chartLimits[2];
  }

}
