import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { VerifyStorageService } from 'src/app/shared/services/API/pharmacy/verify-storage.service';
import { GetStorageReturnStruct } from 'src/app/shared/services/structs/pharmacy/get-storage-return.struct';

@Component({
  selector: 'app-bar-code-modal',
  templateUrl: './bar-code-modal.component.html',
  styleUrls: ['./bar-code-modal.component.css']
})
export class BarCodeModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<BarCodeModalComponent>,
    public verifyStorageService: VerifyStorageService,
    private alertService: AlertService,
    private router: Router,
  ) { }

  public isLoading: boolean;
  public barcodeValue: string;
  public idStorage: number;
  public storage: GetStorageReturnStruct;
  public isValid: boolean;
  
  ngOnInit(): void {
    this.isLoading = false;
    this.isValid = false;
  }

  close() {
    this.matDialogRef.close({ barcodeValue: null });
  }

  clickConfirm() {
    if (!this.storage.idStorage)
      return;

    this.matDialogRef.close({ storage: this.storage });
  }

  clickCancel() {
    this.matDialogRef.close({ storage: null });
    this.router.navigate(['/pharmacy']);
  }

  checkMedicine() {
    if (this.isLoading)
      return;

    this.isLoading = true;
    this.verifyStorageService.verifyStorage(this.idStorage).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.isLoading = false;
      this.isValid = true;
      this.storage = response.storage;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.checkMedicine();
      }
    }, 1000);
  }
}