import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ItemRequest } from '../../requests/pharmacy/Item.request';
import { GetItemResponse } from '../../responses/pharmacy/get-item.response';
import { ListItemResponse } from '../../responses/pharmacy/list-item.response';

@Injectable({
  providedIn: 'root'
})
export class ItemService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listItem(searchText: string): Observable<ListItemResponse>{
    let uri = `Item?`;

    if(searchText)
    uri = uri+`searchText=${searchText}&`

    return this.httpClient.get<ListItemResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public getItem(idItem: number): Observable<GetItemResponse>{
    let uri = `Item/idItem/${idItem}`;

    return this.httpClient.get<GetItemResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public listAllItem(): Observable<ListItemResponse> {
    let uri = 'Item/getAll'
    
    return this.httpClient.get<ListItemResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public createItem(body: ItemRequest): Observable<ReturnStruct>{

    let uri = `Item`

    return this.httpClient.post<ReturnStruct>(environment.urlApiPharmacy + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public updateItem(idItem: number, body: ItemRequest): Observable<ReturnStruct>{

    let uri = `Item/idItem/${idItem}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiPharmacy + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public deleteItem(idItem: number): Observable<ReturnStruct>{

    let uri = `Item/idItem/${idItem}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiPharmacy + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}