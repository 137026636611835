<div class="totem">
    <header>
        <span *ngIf="!authenticated">
            <img class="brand" src="assets/images/logo-branco.png">
        </span>

        <h1 *ngIf="authenticated" class="header__h1">{{totemName}}</h1>
        <h3 *ngIf="authenticated" class="header__h3">{{healthUnitStruct? healthUnitStruct.healthUnitName : null}}</h3>
    </header>
    <div *ngIf="!onlineOffline" class="offline">
        <span><mat-icon class="offline--icon">wifi_off</mat-icon>Você esta sem conexão com a internet no momento.</span>
    </div>
    <app-pin-totem *ngIf="!authenticated" (onAuthenticated)="checkPin($event)" [isLoading]="isLoading"></app-pin-totem>
    <app-totem-options *ngIf="authenticated && !finished" (onFinish)="finish($event)" [listPriority]="listPriority"
        [listTotemPriority]="listTotemPriority" [listTotemButton]="listTotemButton" [state]="state"
        [timeZoneValue]="timeZoneValue" [isLoading]="isLoading" [idHealthUnit]="idHealthUnit"
        (populatePriorities)="getTotemPriority()">
    </app-totem-options>
    <app-totem-success *ngIf="authenticated && finished"
        [generateEpisodeResponse]="generateEpisodeResponse"></app-totem-success>
</div>