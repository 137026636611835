<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-service-modal-list">
            <h2>Deseja salvar o serviço?</h2>
         </div>
         
         <div class="body-service-modal-list">
            Você realmente deseja salvar este serviço? Por não estar vinculado à um módulo, ele será salvo como chamador genérico.
         </div>
         <div class="footer-service-modal-list">
            <button mat-flat-button color="primary" class="btn-block" (click)="clickDelete()">
                <span *ngIf="isLoading == false">Sim, salvar</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
            <button mat-flat-button color="accent" class="btn-block"(click)="clickCancel()">Cancelar</button>
        </div>
    </div>
</div>