<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-confirm-modal-list">
            <h2>Excluir Totem </h2>
        </div>
        <form [formGroup]="model" (ngSubmit)="submit()">
            <div class="body-confirm-modal-list">
                <span>
                    Deseja realmente excluir este totem?
                </span>
                <mat-slide-toggle formControlName="confirm">
                    {{model.get('confirm').value ? "Sim" : "Não"}}
                </mat-slide-toggle>
            </div>
            <div class="footer-confirm-modal-list">
                <button mat-flat-button color="primary" class="btn-block" type="submit">
                    <span>{{model.get('confirm').value ? "Confirmar" : "Retornar"}}</span>
                </button>
            </div>
        </form>
    </div>
</div>