import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-totem-exit-modal',
  templateUrl: './totem-exit-modal.component.html',
  styleUrls: ['./totem-exit-modal.component.css']
})
export class TotemExitModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<TotemExitModalComponent>,
  ) {
    this.matDialogRef.disableClose = true;
  }

  public isLoading: boolean;

  ngOnInit(): void {
    this.isLoading = false;
  }

  submit() {
    if (this.isLoading)
      return;

    this.isLoading = true;
    this.matDialogRef.close({ confirm: true });
  }

  clickCancel() {
    this.matDialogRef.close({ confirm: false });
  }
}