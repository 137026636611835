<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <a class="back-link" routerLink="/observation/hygienization-totem">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Voltar
        </a>
        <h1>Nova Configuração</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body" *ngIf="!isFirstLoading">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <h2>Totem</h2>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome</mat-label>
                            <input matInput type="text" formControlName="hygienizationTotemName">
                            <mat-error *ngIf="model.get('hygienizationTotemName').invalid">Informe o nome</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-slide-toggle formControlName="status">
                            {{model.get('status').value ? "Ativo" : "Inativo"}}
                        </mat-slide-toggle>
                    </div>
                </div>
                <div class="row" *ngIf="isUpdate">
                    <div class="col-12 col-sm-6 col-md-4">
                        <div class="field-container">
                            <mat-form-field appearance="outline">
                                <mat-label>Url</mat-label>
                                <input matInput type="text" formControlName="url">
                            </mat-form-field>
                            <button matTooltip="Copiar" aria-label="Copiar" class="copy-to-clipboard" type="button"
                                [cdkCopyToClipboard]="urlApiBaseUI + model.get('url').value"
                                [cdkCopyToClipboardAttempts]="5">
                                <mat-icon class="copy-icons">content_copy</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <div class="field-container">
                            <mat-form-field appearance="outline">
                                <mat-label>PIN</mat-label>
                                <input matInput type="text" formControlName="pin">
                            </mat-form-field>
                            <button matTooltip="Copiar" aria-label="Copiar" class="copy-to-clipboard" type="button"
                                [cdkCopyToClipboard]="model.get('pin').value" [cdkCopyToClipboardAttempts]="5">
                                <mat-icon class="copy-icons">content_copy</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <h2>Usuários</h2>
                <div class="row" formArrayName="listUser"
                    *ngFor="let item of model.get('listUser')['controls']; let i = index">
                    <div class="row" [formGroupName]="i">
                        <div class="col-4 col-md-4 col-sm-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Nome do usuário</mat-label>
                                <mat-select formControlName="idUser" (selectionChange)="updateAvailableUsers(i)">
                                    <input matInput placeholder="Pesquisar" (keyup)="filterUsers($event)">
                                    <mat-option *ngFor="let user of listUserDisplay" [value]="user.idUser" appearance="outline"
                                        [disabled]="selectedUsers.includes(user.idUser)">
                                        {{user.userName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-2 col-md-2 col-sm-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Identificador rápido</mat-label>
                                <input matInput #input type="text" formControlName="quickIdentifier"
                                    (keyup)="onKeySearch($event, item.get('idUser').value)"
                                    maxlength="10" pattern="[0-9]*"
                                    oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                                <mat-error *ngIf="item.get('quickIdentifier').invalid"> Este identificador já existe, escolha outro </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-2 col-md-2 col-sm-2">
                            <mat-form-field appearance="outline">
                                <mat-label>PIN Usuário</mat-label>
                                <input matInput type="number" formControlName="pinUser">                                <mat-error *ngIf="item.get('quickIdentifier').invalid"> Este identificador já existe, escolha outro </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-2 col-sm-2 col-md-2">
                            <button
                                *ngIf="model.get('listUser')['controls'][i].get('idUser').value != null && model.get('listUser')['controls'].length < listUser.length"
                                class="exception-button " color="primary" mat-mini-fab type="button" (click)="addNext()">
                                <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
                            </button>
                            <button class="exception-button " color="primary" mat-mini-fab type="button"
                                *ngIf="model.get('listUser')['controls'].length > 0" (click)="removeUser(i)">
                                <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block" [disabled]="model.invalid">
                            <span *ngIf="!isLoading">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <a mat-flat-button type="button" routerLink="/observation/hygienization-totem" color="accent"
                            class=" btn-block">
                            Cancelar
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>
    
</div>