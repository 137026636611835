import { CorrectClassificationReasonStruct } from "../../structs/audit/correct-classification-reason.struct";
import { IncorrectClassificationReasonStruct } from "../../structs/audit/incorrect-classification-reason.struct";

export class AuditConfigRequest {
    public allowAudited: boolean;
    public viewTimeTag: boolean;
    public displayCarePriorityInRiskClassification: boolean;
    public listIncorrectClassificationReasonNotDefault: IncorrectClassificationReasonStruct[];
    public listCorrectClassificationReasonNotDefault: CorrectClassificationReasonStruct[];
    public relationUserRemovePriorReasonModal: boolean;
    constructor() {
        this.listIncorrectClassificationReasonNotDefault = [];
        this.listCorrectClassificationReasonNotDefault = [];        
    }
    
}