import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { AttendPatientResponse } from '../../responses/orchestrator-schedule/attend-patient.response';

@Injectable({
  providedIn: 'root'
})
export class AttendPatientService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getAttendance(idPatientAppointment: number): Observable<AttendPatientResponse> {

    let uri = `AttendPatient/idPatientAppointment/${idPatientAppointment}`;

    return this.httpClient.get<AttendPatientResponse>(environment.urlApiOrchestratorSchedule + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
