import { DestinationStruct } from "../../structs/form/destination.struct";

export class FormEpisodeRequest
{    
    public idPatient: number;
    public origin: string;
    public arrivalType: string;
    public idProtocol: number;
    public idFlowchart: number;
    public idDiscriminator: number;
    public idGravity: number;
    public idConclusionType: number;
    public idCid: number;
    public idBed: number;
    public idDestination: number;
    public observationHours: number;
    public observationMinutes: number;
    public observationSeconds: number;
    public observationDays: number;
    public idArrivalReason: number;
    public idArrivalType: number;
    public idModuleEvasion: number;
    public idClassificationRoom: number;
    public idMedicRoom: number;
    public isPriority: boolean;    
    public isCovidSuspect: boolean;

    public idOrigin: number;
    public datetimeStartAttendance: Date;
    public datetimeStartEpisode: Date;
    public datetimeFinishEpisode: Date;
    public hourStartEpisode: String;
    public hourFinishEpisode: String;

    public datetimeStartReception: Date;
    public datetimeFinishReception: Date;
    public hourStartReception: String;
    public hourFinishReception: String;
    
    public datetimeStartClassification: Date;
    public datetimeFinishClassification: Date;
    public hourStartClassification: String;
    public hourFinishClassification: String;
    
    public datetimeStartReclassification: Date;
    public datetimeFinishReclassification: Date;
    public hourStartReclassification: String;
    public hourFinishReclassification: String;
    
    public datetimeEvasion: Date;
    public hourEvasion: String;
    
    public datetimeStartMedicalAtt: Date;
    public datetimeFinishMedicalAtt: Date;
    public hourStartMedicalAtt: String;
    public hourFinishMedicalAtt: String;
    
    public datetimeStartReturnMedicalAtt: Date;
    public datetimeFinishReturnMedicalAtt: Date;
    public hourStartReturnMedicalAtt: String;
    public hourFinishReturnMedicalAtt: String;
    
    public datetimeStartBed: Date;
    public datetimeFinishBed: Date; 
    public hourStartBed: String;
    public hourFinishBed: String;
    
    public listIdDestination: DestinationStruct[];
    public listIdExam: number[];
    public listIdStatus: number[];
}