import { Injectable } from '@angular/core';
import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ErrorRequestPageRequest } from './requests/srv-log/error-request-page.request';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }

  // Manipulação de erros
  handleError(error: HttpErrorResponse) {

    let errorMessage = '';
    let handlerErrorRequest: HandlerErrorRequest = new HandlerErrorRequest();

    if (error.error instanceof ErrorEvent) {
      // Erro ocorreu no lado do client
      errorMessage = error.error.message;
      return throwError(errorMessage);
    }

    if (error.status == 504) {
      errorMessage = `GATEWAY TIMEOUT 504- Acione sua TI local para verificar sua rede/roteador/proxy local.`;
      handlerErrorRequest.setSessionErroRequest(error);
      return throwError(errorMessage);
    }

    if (error.status == 502) {
      errorMessage = `GATEWAY TIMEOUT 502- Tente novamente!`;
      return throwError(errorMessage);
    }

    if (error.status == 0) {
      errorMessage = `Você está sem conexão com a internet no momento.`;
      handlerErrorRequest.setSessionErroRequest(error);
      return throwError(error);
    }

    // Erro ocorreu no lado do servidor
    errorMessage = `Código do erro: ${error.status}, mensagem: ${error.message}`;

    if (error.status == 401) {
      sessionStorage.removeItem("token");
      window.location.href = '/';
    }

    return throwError(errorMessage);
  };

  handleErrorTotem(error: HttpErrorResponse) {

    let errorMessage = '';
    let handlerErrorRequest: HandlerErrorRequest = new HandlerErrorRequest();

    if (error.error instanceof ErrorEvent) {
      // Erro ocorreu no lado do client
      errorMessage = error.error.message;
      return throwError(errorMessage);
    }

    if (error.status == 504) {
      errorMessage = `GATEWAY TIMEOUT 504- Acione sua TI local para verificar sua rede/roteador/proxy local.`;
      handlerErrorRequest.setSessionErroRequest(error);
      return throwError(errorMessage);
    }

    if (error.status == 502) {
      errorMessage = `GATEWAY TIMEOUT 502- Tente novamente!`;
      return throwError(errorMessage);
    }

    if (error.status == 0) {
      errorMessage = `Você está sem conexão com a internet no momento.`;
      handlerErrorRequest.setSessionErroRequest(error);
      return throwError(error);
    }

    // Erro ocorreu no lado do servidor
    errorMessage = `Código do erro: ${error.status}, mensagem: ${error.message}`;

    if (error.status == 401) {
      sessionStorage.removeItem("tokenTotem");
    }

    return throwError(errorMessage);
  };

  addHeaderInternalToken() {
    let headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Authorization': environment.authorizationHash
    }
    let requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return requestOptions;
  }

  addHeaderToken() {
    let strToken = sessionStorage.getItem('token');
    let token: string = "";

    if (strToken) {
      token = JSON.parse(strToken).token;
    }

    let headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Authorization': token
    }

    let requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return requestOptions;
  }

  addHeaderPanelToken() {
    let strToken = sessionStorage.getItem('panelToken');
    let token: string = "";

    if (strToken)
      token = JSON.parse(strToken).token;

    let headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Authorization': token
    }

    let requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return requestOptions;
  }

  addHeaderHashTotem() {

    let token: string = JSON.parse(sessionStorage.getItem('totemToken')).token;

    let headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Authorization': token ? token : environment.hashTotemAuthorization
    }

    let requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return requestOptions;
  }

  addHeaderHashTotemNps() {
    let strToken = sessionStorage.getItem('totemTokenNps');
    let token: string = "";

    if (strToken)
      token = JSON.parse(strToken).token;

    let headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Authorization': token ? token : environment.hashTotemAuthorization
    }

    let requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return requestOptions;
  }

  addHeaderHashEmailSms() {
    let headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Authorization': environment.hashEmailSmsAuthorization
    }

    let requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return requestOptions;
  }

  addHeaderHashBusinessIntelligence() {
    let headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Authorization': environment.hashBusinessIntelligenceAuthorization
    }

    let requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return requestOptions;
  }

  addHeaderHashWebhook(token: string) {

    let headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Authorization': token ? token : environment.hashTotemAuthorization
    }

    let requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return requestOptions;
  }


}
export class HandlerErrorRequest {
  setSessionErroRequest(error: HttpErrorResponse) {

    const tokenSessionError = "sessionErroRequest";

    let errorRequestPageRequest: ErrorRequestPageRequest = new ErrorRequestPageRequest();
    errorRequestPageRequest.datetimeRequest = new Date();
    errorRequestPageRequest.urlRequest = error.url;

    let errorRequestPageRequestArray: ErrorRequestPageRequest[] = [errorRequestPageRequest];

    let sessionString: string = sessionStorage.getItem(tokenSessionError);

    if (sessionString == null) {
      //Cria novo objeto 
      sessionStorage.setItem(tokenSessionError, JSON.stringify(errorRequestPageRequestArray));
    }
    else {
      //altera objeto
      errorRequestPageRequestArray = JSON.parse(sessionStorage.getItem(tokenSessionError));
      errorRequestPageRequestArray.push(errorRequestPageRequest);
      sessionStorage.setItem(tokenSessionError, JSON.stringify(errorRequestPageRequestArray));
    }

    //OBS: Ao gravar na storage, o menu abre o settimeout para enviar os erros para a API
  }
}