import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { PatientBillingDataResponse } from '../../responses/billing/patient-billing-data.response';

@Injectable({
  providedIn: 'root'
})
export class ListPatientBillingDataService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  listPatientBillingData(beginPeriod: string, endPeriod: string, searchTextProcedureSigTap: string, searchTextCodSigTap: string): Observable<PatientBillingDataResponse>{
    let uri = `ListPatientBillingData/beginPeriod/${beginPeriod}/endPeriod/${endPeriod}?`

    if (searchTextProcedureSigTap)
      uri = uri + `searchTextProcedureSigTap=${searchTextProcedureSigTap}&`;
    if (searchTextCodSigTap)
      uri = uri + `searchTextCodSigTap=${searchTextCodSigTap}`;
      
    return this.httpClient.get<PatientBillingDataResponse>(environment.urlApiOrchestratorBilling + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
