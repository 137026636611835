import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReportModalComponent } from 'src/app/shared/components/report-modal/report-modal.component';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { PresenceReportService } from 'src/app/shared/services/API/orchestrator-patient/presence-report.service';
import { PostPresenceReportRequest } from 'src/app/shared/services/requests/orchestrator-patient/post-presence-report.request';

@Component({
  selector: 'app-patient-list-reprint-report-modal',
  templateUrl: './patient-list-reprint-report-modal.component.html',
  styleUrls: ['./patient-list-reprint-report-modal.component.css']
})
export class PatientListReprintReportModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<PatientListReprintReportModalComponent>,
  private presenceReportService: PresenceReportService,
  private alertService: AlertService) { }

  public admissionString64: string;
  public wristbandString64: string;
  public presenceString64: string;
  public isLoading: boolean;

  ngOnInit(): void {
    this.admissionString64 = this.data.admissionString64;
    this.wristbandString64 = this.data.wristbandString64;
  }

  clickReport(string64: string) {
    let reportDialog = this.dialog.open(ReportModalComponent, {
      data: {
        reportPdf64: string64,
      },
    });
  }

  openCreateStatementOfAttendance() {
    this.presenceReportService.postReportPDF(this.data.postPresenceReportRequest).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      let reportDialog = this.dialog.open(ReportModalComponent, {
        data: {
          reportPdf64: response.reportPdf64,
        },
      });
    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      });
  }

  clickCancel() {
    this.matDialogRef.close(); 
  }

  close(){
    this.matDialogRef.close(); 
  }
}