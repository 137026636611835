import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { AvailableFlowsRequest } from '../../requests/flow/available-flows.request';
import { GetAvailableFlowsResponse } from '../../responses/flow/get-available-flows.response';


@Injectable({
  providedIn: 'root'
})
export class ForwardService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getAvailableFlows(body: AvailableFlowsRequest): Observable<GetAvailableFlowsResponse> {
    
    let uri = `Forward/getAvailable`;  
    
    return this.httpClient.post<GetAvailableFlowsResponse>(environment.urlApiFlow + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
