import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MetaDataDeleteModalComponent } from './meta-data-delete-modal/meta-data-delete-modal.component';
import { MetaDataService } from 'src/app/shared/services/API/meta-data/meta-data.service';
import { MetaDataStruct } from 'src/app/shared/services/structs/meta-data/meta-data.struct';

@Component({
  selector: 'app-meta-data-list',
  templateUrl: './meta-data-list.component.html',
  styleUrls: ['./meta-data-list.component.css']
})
export class MetaDataListComponent implements OnInit {

  constructor(
    private metaDataService: MetaDataService,
    private alertService: AlertService,
    public dialog: MatDialog) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.master;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.master_meta_data;

  public listMetaDataResponse: MetaDataStruct[];
  public isLoading: boolean;
  public isActive: boolean;
  public searchText: string;

  ngOnInit(): void {
    this.isLoading = true;
    this.search();
  }

  search() {
    this.isLoading = true;

    if (this.searchText == "")
      this.searchText = null;

    this.metaDataService.getBySearchMetaData(this.searchText).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.warning);
          this.isLoading = false;
          return;
        }

        this.listMetaDataResponse = response.listMetaDataStruct;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13)
        $this.search();
    }, 1000);
  }

  openModal(idMetaData) {
    const dialogRef = this.dialog.open(MetaDataDeleteModalComponent, {
      data: {
        idMetaData: idMetaData
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.deleteMetaData)
          this.search();
      }
    });
  }
}