import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiProfessionalComponent } from './pages/multi-professional/multi-professional.component';
import { MultiProfessionalPatientListComponent } from './pages/multi-professional-patient-list/multi-professional-patient-list.component';
import { PatientListReprintReportModalComponent } from './pages/multi-professional-patient-list/patient-list-reprint-report-modal/patient-list-reprint-report-modal.component';
import { SharedModule } from '../shared/shared.module';
import { MultiProfessionalRoutingModule } from './multi-professional-routing.module';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckbox, MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatTree, MatTreeModule } from '@angular/material/tree';
import { MatRadioButton, MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { AttendPatientComponent } from './pages/multi-professional-attend-patient/attend-patient.component';
import { AssistanceOutcomeBlockComponent } from './pages/multi-professional-attend-patient/assistance-outcome-block/assistance-outcome-block.component';
import { CancelConfirmModalComponent } from './pages/multi-professional-attend-patient/cancel-confirm-modal/cancel-confirm-modal.component';
import { ConclusionNoteModalComponent } from './pages/multi-professional-attend-patient/conclusion-note-modal/conclusion-note-modal.component';
import { ExamsRequestsBlockComponent } from './pages/multi-professional-attend-patient/exams-requests-block/exams-requests-block.component';
import { NewAttendanceBlockComponent } from './pages/multi-professional-attend-patient/new-attendance-block/new-attendance-block.component';
import { NonStandardPrescriptionsBlockComponent } from './pages/multi-professional-attend-patient/non-standard-prescriptions-block/non-standard-prescriptions-block.component';
import { ProceduresPerformedBlockComponent } from './pages/multi-professional-attend-patient/procedures-performed-block/procedures-performed-block.component';
import { SickNoteBlockComponent } from './pages/multi-professional-attend-patient/sick-note-block/sick-note-block.component';
import { UpdateExternalPrescriptionModalComponent } from './pages/multi-professional-attend-patient/non-standard-prescriptions-block/update-external-prescription-modal/update-external-prescription-modal.component';
import { MultiProfessionalConfigListComponent } from './pages/multi-professional-config-list/multi-professional-config-list.component';
import { MultiProfessionalConfigRegisterComponent } from './pages/multi-professional-config-register/multi-professional-config-register.component';
import { MultiProfessionalConfigDeleteModalComponent } from './pages/multi-professional-config-register/multi-professional-config-delete-modal/multi-professional-config-delete-modal.component';
import { MetaDataBlockComponent } from './pages/multi-professional-attend-patient/meta-data-block/meta-data-block.component';
import { MatDialogModule } from '@angular/material/dialog';
import { CareLineBlockComponent } from './pages/multi-professional-attend-patient/care-line-block/care-line-block.component';
import { StandartPrescriptionsBlockComponent } from './pages/multi-professional-attend-patient/standart-prescriptions-block/standart-prescriptions-block.component';
import { PrescriptionModalComponent } from './pages/multi-professional-attend-patient/standart-prescriptions-block/prescription-modal/prescription-modal.component';
import { ConfirmAllergicPatientModalComponent } from './pages/multi-professional-attend-patient/standart-prescriptions-block/confirm-allergic-patient-modal/confirm-allergic-patient-modal.component';
import { MultiProfessionalCareAccordionComponent } from './pages/multi-professional-attend-patient/multi-professional-care-accordion/multi-professional-care-accordion.component';

@NgModule({
  declarations: [
    MultiProfessionalComponent,
    MultiProfessionalPatientListComponent,
    PatientListReprintReportModalComponent,
    MultiProfessionalConfigListComponent,
    MultiProfessionalConfigRegisterComponent,
    MultiProfessionalConfigDeleteModalComponent,
    AttendPatientComponent,
    AssistanceOutcomeBlockComponent,
    CancelConfirmModalComponent,
    ConclusionNoteModalComponent,
    ExamsRequestsBlockComponent,
    NewAttendanceBlockComponent,
    MetaDataBlockComponent,
    NonStandardPrescriptionsBlockComponent,
    UpdateExternalPrescriptionModalComponent,
    ProceduresPerformedBlockComponent,
    SickNoteBlockComponent,
    CareLineBlockComponent,
    StandartPrescriptionsBlockComponent,
    PrescriptionModalComponent,
    ConfirmAllergicPatientModalComponent,
    MultiProfessionalCareAccordionComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MultiProfessionalRoutingModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTreeModule,
    MatRadioModule,
    MatTooltipModule,
    MatToolbarModule,
    NgxMatFileInputModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    MatSlideToggleModule,
    CdkAccordionModule,
    MatPaginatorModule,
    MatDialogModule
  ],
  providers:[
    AuthGuard,
    MatCheckbox,
    MatCheckboxChange,
    MatTree,
    MatRadioButton,
  ]
})
export class MultiProfessionalModule { }
