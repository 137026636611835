<div class="card-list" *ngIf="listProcedure != null && listProcedure.length > 0">
    <div class="row row-list">
        <ng-container *ngFor="let item of listProcedure; let index = index;">
            <div *ngIf="item.idMedicalCare != null" class=" col-12 col-sm-6 col-md-4">
                <div class="card-item">
                    <div class="infos">
                        <span class="primary">{{item.procedureName}}</span>
                        <span class="secundary">Observação do médico: {{item.observation}}</span>
                        <span class="secundary">Solicitação: {{item.userName}}</span>
                    </div>
                    <div class="actions" *ngIf="listProcedure">
                        <a>
                            <mat-icon aria-hidden="false" aria-label="Editar" (click)="openProcedureModal(item, false)">
                                visibility
                            </mat-icon>
                        </a>
                        <a class="actions"
                            *ngIf="item.isRecurring && item.medicalProcedureCheck == null && !item.idMedicalCare">
                            <mat-icon aria-hidden="false" aria-label="Excluir"
                                (click)="deleteProcedure(item.idMedicalProcedure)">delete</mat-icon>
                        </a>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngFor="let item of listProceduresNursing; let index = index;">
            <div *ngIf="item.idMedicalCare == null && item.idProcedureStatus == 1" class="col-12 col-sm-6 col-md-4">
                <div class="card-item">
                    <div class="infos">
                        <span class="primary">{{item.procedureName}}</span>
                        <span class="secundary">Observação da Enfermagem: {{item.observation}}</span>
                        <span class="secundary">Solicitação: {{item.userName}}</span>
                    </div>
                    <div class="actions" *ngIf="listProceduresNursing">
                        <a>
                            <mat-icon aria-hidden="false" aria-label="Editar" (click)="openProcedureModal(item, false)">
                                visibility
                            </mat-icon>
                        </a>
                        <a class="actions"
                            *ngIf="item.isRecurring && item.medicalProcedureCheck == null && item.idMedicalCare">
                            <mat-icon aria-hidden="false" aria-label="Excluir"
                                (click)="deleteProcedure(item.idMedicalProcedure)">delete</mat-icon>
                        </a>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
<div *ngIf="listProcedure == null || listProcedure.length == 0">
    <p>Este atendimento não possui procedimentos internos!</p>
</div>