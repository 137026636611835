import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { GetPharmacyConfigResponse } from '../../responses/pharmacy/get-pharmacy-config.response';
import { LookupsBooleanChoiceRequest } from '../../requests/pharmacy/lookups-boolean-choice.request';
import { GetLookupsResponse } from '../../responses/pharmacy/get-lookups.response';

@Injectable({
  providedIn: 'root'
})
export class LookupService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public searchLookupsUsingBooleanRequestFilter(body: LookupsBooleanChoiceRequest): Observable<GetLookupsResponse> {
    let uri = 'Lookup/searchLookupsUsingBooleanRequestFilter';
    
    return this.httpClient.post<GetLookupsResponse>(environment.urlApiPharmacy + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
