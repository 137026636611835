import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-plot-counter',
  templateUrl: './plot-counter.component.html',
  styleUrls: ['./plot-counter.component.css']
})
export class PlotCounterComponent implements OnInit {

  constructor() { }


  @Input() label: string;
  @Input() value: number;


  ngOnInit(): void {
  }
}
