<div mat-dialog-content>
    <div class="mat-dialog-content">

        <h1 class="modal-button-name">{{this.data.buttonName}}</h1>
        <hr />
        <div class="row body-phone-content">
            <mat-icon class="icon-call" aria-hidden="false"
                aria-label="call">call</mat-icon>{{this.data.contactValue | mask: maskToUse}}
        </div>
        <div class="button-footer">
            <button mat-flat-button color="secundary" class="button-selection" (click)="close()">
                <span *ngIf="isLoading == false">Fechar</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
        </div>

    </div>
</div>