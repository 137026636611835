import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { RemoteAttendanceReportAccessService } from 'src/app/shared/services/API/medical-record/remote-attendance-report-access.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-remote-attendance-report-viewer',
  templateUrl: './remote-attendance-report-viewer.component.html',
  styleUrls: ['./remote-attendance-report-viewer.component.css']
})
export class RemoteAttendanceReportViewerComponent implements OnInit {
  pdfData: SafeResourceUrl;

  constructor(private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private remoteAttendanceReportAccessService: RemoteAttendanceReportAccessService,
    public sanitizer: DomSanitizer
  ) { }

  public checkedLoading: boolean = false;
  public isLoading: boolean = false;
  public guid: string;

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.paramMap.get('guid')) {
      this.guid = this.activatedRoute.snapshot.paramMap.get('guid');
      this.getRemoteAttendancePatientReportByGuid(this.guid);
    }
  }

  getRemoteAttendancePatientReportByGuid(guid: string) {
    this.remoteAttendanceReportAccessService.getRemoteAttendanceReportFromGuid(guid).subscribe({
      next: (response) => {
        if (response.isError) {
          this.checkedLoading = true;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.guid = null;
          return;
        }
        this.pdfData = this.sanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,' + response.reportPdf64);
        this.checkedLoading = true;
        this.isLoading = true;
      },
      error: (error) => {
        this.checkedLoading = true;
        console.log(error);
        this.guid = null;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}