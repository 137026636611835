import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LookupService } from 'src/app/shared/services/API/private-billing/lookup.service';
import { ProcedureService } from 'src/app/shared/services/API/private-billing/procedure.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { TerminologyOrigin } from 'src/app/shared/services/models/private-billing/terminology-origin.model';
import { Terminology } from 'src/app/shared/services/models/private-billing/terminology.model';
import { ProcedureRequest } from 'src/app/shared/services/requests/private-billing/procedure.request';
import { ProcedureStruct } from 'src/app/shared/services/structs/private-billing/procedure.struct';

@Component({
  selector: 'app-procedure-basic-data',
  templateUrl: './procedure-basic-data.component.html',
  styleUrls: ['./procedure-basic-data.component.css']
})
export class ProcedureBasicDataComponent implements OnInit {
  constructor(
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private procedureService: ProcedureService,
    private lookupService: LookupService,
    private router: Router,
  ) { }  

  @Output() onProcedureFilled = new EventEmitter<{
    isValid: boolean;
    idProcedure: number;
    isUpdate: boolean;
    procedureStruct: ProcedureStruct;
  }>();

  @Input() listTerminologyOrigin: TerminologyOrigin[] = [];
  @Input() procedureStruct: ProcedureStruct;
  @Input() isUpdate: boolean;

  public model: FormGroup;
  public searchTextTerminology: string;
  public idTerminologyOrigin: number;
  private timeoutKeySearchTerminology: any = null;
  public listTerminology: Terminology[] = [];
  public preSelectedlistTerminology: Terminology[] = [];
  public isLoading: boolean = false;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      nameProcedure: ['', [Validators.required, Validators.maxLength(150)]],
      descriptionProcedure: ['', Validators.maxLength(150)],
      idTerminology: [{value: null, disabled: true}],
    });

    if (this.isUpdate)
      this.mapperData();
  }

  submit() {
    if (this.isLoading)
      return;

    if (this.model.invalid) {
      this.alertService.show('Atenção', 'Favor verificar todos os campos', AlertType.warning);
      return;
    }

    this.isLoading = true;

    let request: ProcedureRequest = new ProcedureRequest();
    request.nameProcedure = this.model.get('nameProcedure').value;
    request.descriptionProcedure = this.model.get('descriptionProcedure').value;
    request.idTerminology = this.model.get('idTerminology').value ? parseInt(this.model.get('idTerminology').value) : null;

    if (this.isUpdate) this.put(request, this.procedureStruct.idProcedure);    
    else this.post(request);
  }

  backToProcedureList() {
    if (this.isLoading)
      return;

    this.router.navigate(['/billing/procedure']);
  }

  mapperData() {
    this.model.get('nameProcedure').setValue(this.procedureStruct.nameProcedure);
    this.model.get('descriptionProcedure').setValue(this.procedureStruct.descriptionProcedure);

    if(Number.isInteger(this.procedureStruct.idTerminology) &&  this.procedureStruct.idTerminology > 0){
      this.model.get('idTerminology').enable();
      this.model.get('idTerminology').addValidators(Validators.required);
      this.model.get('idTerminology').setValue(this.procedureStruct.idTerminology);
      this.model.get('idTerminology').updateValueAndValidity();

      this.idTerminologyOrigin = this.procedureStruct.idTerminologyOrigin;
      this.preSelectedlistTerminology.push(this.procedureStruct.terminology); //adicionar lista

      this.getTerminologyLookup();
    }
  }

  changeTerminologyOrigin(idTerminologyOrigin: number){
    if (idTerminologyOrigin == this.idTerminologyOrigin) 
      return;

    this.preSelectedlistTerminology = [];
    this.idTerminologyOrigin = idTerminologyOrigin;
    this.searchTextTerminology = "";

    this.model.get('idTerminology').setValue(null);

    if (idTerminologyOrigin){
      if ( this.model.get('idTerminology').disabled == true){
        this.model.get('idTerminology').enable();        
        this.model.get('idTerminology').addValidators(Validators.required);        
        this.model.get('idTerminology').updateValueAndValidity();
      }        

      this.getTerminologyLookup();   
    }      
    else {
      this.model.get('idTerminology').disable();
      this.listTerminology = [];
    }       
  }

  changeTerminology(idTerminology: any){       
    if (idTerminology) {
      let terminology: Terminology = this.listTerminology.find(item => item.idTerminology == idTerminology);

      if (terminology){
        this.preSelectedlistTerminology = [];
        this.preSelectedlistTerminology.push(terminology);

        if (terminology.terminologyNameTuss && terminology.terminologyNameTuss.trim() != "")
          this.model.get('nameProcedure').setValue(terminology.terminologyNameTuss);
      }      
    }
    else {
      this.preSelectedlistTerminology = [];
    }      
  }

  
  onKey(event: any) {
    clearTimeout(this.timeoutKeySearchTerminology);
    var $this = this;
    this.timeoutKeySearchTerminology = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.getTerminologyLookup(event);
      }
    }, 1000);
  }

  getTerminologyLookup(searchText: string = null) {
    this.isLoading = true;

    this.lookupService.listTerminologyLookup(this.idTerminologyOrigin, searchText).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        if (!response.listTerminology || response.listTerminology.length == 0){
          this.listTerminology = this.preSelectedlistTerminology && this.preSelectedlistTerminology.length > 0 ? [...this.preSelectedlistTerminology] : [];
          this.alertService.show('Alerta', "Nenhum procedimento encontrado", AlertType.warning);
        }
        else {
          this.listTerminology = response.listTerminology;

          if (this.preSelectedlistTerminology && this.preSelectedlistTerminology.length > 0) {
            var ids = new Set(this.preSelectedlistTerminology.map(d => d.idTerminology));
            this.listTerminology = [...this.preSelectedlistTerminology, ...this.listTerminology.filter(d => !ids.has(d.idTerminology))];
          }
        }       

        this.isLoading = false;
        
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  post(request: ProcedureRequest) {
    this.isLoading = true;

    this.procedureService.Post(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        if (!response.procedureStruct) {
          this.throwException("Erro na criação do procedimento.");
          return;
        }

        this.procedureStruct = response.procedureStruct;
        this.procedureStruct.idTerminologyOrigin = this.idTerminologyOrigin;
        this.procedureStruct.terminology = this.preSelectedlistTerminology && this.preSelectedlistTerminology.length == 1 ? this.preSelectedlistTerminology[0] : null;

        this.isUpdate = true;

        this.onProcedureFilled.emit({
          isValid: this.model.valid,
          idProcedure: this.procedureStruct.idProcedure,
          isUpdate: this.isUpdate,
          procedureStruct: this.procedureStruct
        });

        this.isLoading = false;
        this.alertSave("Procedimento criado com sucesso!");

      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  put(request: ProcedureRequest, idProcedure: number) {
    this.isLoading = true;

    this.procedureService.Put(request, idProcedure).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        if (!response.procedureStruct) {
          this.throwException("Erro na criação do procedimento.");
          return;
        }

        this.procedureStruct = response.procedureStruct;
        this.procedureStruct.idTerminologyOrigin = this.idTerminologyOrigin;
        this.procedureStruct.terminology = this.preSelectedlistTerminology && this.preSelectedlistTerminology.length == 1 ? this.preSelectedlistTerminology[0] : null;

        this.isUpdate = true;

        this.onProcedureFilled.emit({
          isValid: this.model.valid,
          idProcedure: this.procedureStruct.idProcedure,
          isUpdate: this.isUpdate,
          procedureStruct: this.procedureStruct
        });

        this.isLoading = false;
        this.alertSave("Procedimento atualizado com sucesso!");

      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  private alertSave(text: string) {
    this.alertService.show('Sucesso', text, AlertType.success);
    this.isLoading = false;
  }

  private throwException(error: string) {
    this.alertService.show('Erro inesperado', error, AlertType.error);
    this.isLoading = false;
  }
}