import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { GetListFavoriteGroupExamPrescriptionResponse } from '../../responses/medic/get-list-favorite-group-exam-prescription.response';
import { FavoriteGroupExamPrescriptionRequest } from '../../requests/medic/favorite-group-exam-prescription.request';
import { GetFavoriteExamPrescriptionResponse } from '../../responses/medic/get-favorite-exam-prescription.response';

@Injectable({
  providedIn: 'root'
})
export class FavoriteExamPrescriptionService extends CommonService {

  constructor(private httpClient: HttpClient) { super(); }

  public listFavoriteGroupExamPrescription(): Observable<GetListFavoriteGroupExamPrescriptionResponse> {
    let uri = `FavoriteExamPrescription`

    return this.httpClient.get<GetListFavoriteGroupExamPrescriptionResponse>(environment.urlApiMedic + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public getFavoriteGroupExamPrescription(idDoctorFavoriteGroupExamPrescription: number): Observable<GetFavoriteExamPrescriptionResponse> {
    let uri = `FavoriteExamPrescription/idDoctorFavoriteGroupExamPrescription/${idDoctorFavoriteGroupExamPrescription}`

    return this.httpClient.get<GetFavoriteExamPrescriptionResponse>(environment.urlApiMedic + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public deleteFavoriteGroupExamPrescription(idDoctorFavoriteGroupExamPrescription: number): Observable<ReturnStruct> {
    let uri = `FavoriteExamPrescription/idDoctorFavoriteGroupExamPrescription/${idDoctorFavoriteGroupExamPrescription}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiMedic + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
  
  public postFavoriteGroupExamPrescription(request: FavoriteGroupExamPrescriptionRequest): Observable<ReturnStruct> {
    let uri = `FavoriteExamPrescription`

    return this.httpClient.post<ReturnStruct>(environment.urlApiMedic + uri, request, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public putFavoriteGroupExamPrescription(request: FavoriteGroupExamPrescriptionRequest): Observable<ReturnStruct> {
    let uri = `FavoriteExamPrescription`

    return this.httpClient.put<ReturnStruct>(environment.urlApiMedic + uri, request, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}