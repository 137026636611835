import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { AttendRequest } from '../../requests/sync-measurer/attend.request';
import { DeviceOnlineResponse } from '../../responses/sync-measurer/device-online.response';
import { BloodPressureHistoryResponse, GlucoseHistoryResponse, HeartRateHistoryResponse, HistoryLenghtResponse, HistoryMeasurerPatientResponse, SaturationHistoryResponse, TemperatureHistoryResponse } from '../../responses/sync-measurer/history-measure-patient.response';
import { HistoryMeasurerResponse } from '../../responses/sync-measurer/history-measurer.response';
import { ListCareLineResponse } from '../../responses/sync-measurer/list-care-line.response';


@Injectable({
    providedIn: 'root'
  })
  export class SyncMeasurerPatientService extends CommonService {
  
    constructor(private router: Router, private httpClient: HttpClient) {
      super();
    }

  public listChartMeasureHistory(idPatient: number): Observable<HistoryMeasurerPatientResponse> {

    let uri = `SyncMeasurerPatient/MeasureHistory/${idPatient}`

    return this.httpClient.get<HistoryMeasurerPatientResponse>(environment.urlApiSyncMeasurer + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public GlucoseHistory(idPatient: number, pagination: number, numberOfItemsChart: number): Observable<GlucoseHistoryResponse> {

    let uri = `SyncMeasurerPatient/GlucoseHistory/${idPatient}/${pagination}/${numberOfItemsChart}`

    return this.httpClient.get<GlucoseHistoryResponse>(environment.urlApiSyncMeasurer + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public TemperatureHistory(idPatient: number, pagination: number, numberOfItemsChart: number): Observable<TemperatureHistoryResponse> {

    let uri = `SyncMeasurerPatient/TemperatureHistory/${idPatient}/${pagination}/${numberOfItemsChart}`

    return this.httpClient.get<TemperatureHistoryResponse>(environment.urlApiSyncMeasurer + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public HeartRateHistory(idPatient: number, pagination: number, numberOfItemsChart: number): Observable<HeartRateHistoryResponse> {

    let uri = `SyncMeasurerPatient/HeartRateHistory/${idPatient}/${pagination}/${numberOfItemsChart}`

    return this.httpClient.get<HeartRateHistoryResponse>(environment.urlApiSyncMeasurer + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public SaturationHistory(idPatient: number, pagination: number, numberOfItemsChart: number): Observable<SaturationHistoryResponse> {

    let uri = `SyncMeasurerPatient/SaturationHistory/${idPatient}/${pagination}/${numberOfItemsChart}`

    return this.httpClient.get<SaturationHistoryResponse>(environment.urlApiSyncMeasurer + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public BloodPressureHistory(idPatient: number, pagination: number, numberOfItemsChart: number): Observable<BloodPressureHistoryResponse> {

    let uri = `SyncMeasurerPatient/BloodPressureHistory/${idPatient}/${pagination}/${numberOfItemsChart}`

    return this.httpClient.get<BloodPressureHistoryResponse>(environment.urlApiSyncMeasurer + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public GetHistoryLenght(idPatient: number): Observable<HistoryLenghtResponse> {

    let uri = `SyncMeasurerPatient/GetHistoryLenght/${idPatient}`

    return this.httpClient.get<HistoryLenghtResponse>(environment.urlApiSyncMeasurer + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
