import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CommonService } from "src/app/shared/services/common-service";
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';
import { catchError } from "rxjs/operators";
import { MedicalCareRequest } from "../../requests/medical-record/medical-care.request";
import { NewMedicalCareResponse } from "../../responses/medical-record/new-medical-record.response";

@Injectable({
  providedIn: 'root'
})
export class MedicalCareService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public postMedicalCare(medicalCareRequest: MedicalCareRequest): Observable<NewMedicalCareResponse> {
    let uri = `MedicalRecord`

    return this.httpClient.post<NewMedicalCareResponse>(environment.urlApiMedicalRecord + uri, medicalCareRequest, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}