<div class="row display-contents">
    <form class="form" [formGroup]="model" (ngSubmit)="submit()">
        <div class="col-12" style="padding: 2rem;">
            <div class="row border-box">
                <div class="title-header">
                    <h3>Campos obrigatórios</h3>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Guia de consulta</mat-label>
                        <mat-select formControlName="idLookupAppointmentRequiredField" multiple>
                            <mat-option *ngFor="let item of listLookupAppointmentRequiredField"
                                [value]="item.idLookupAppointmentRequiredField">
                                {{item.appointmentFieldName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Guia SP/SADT</mat-label>
                        <mat-select formControlName="idLookupSpSadtRequiredField" multiple>
                            <mat-option *ngFor="let item of listLookupSpSadtRequiredField"
                                [value]="item.idLookupSpSadtRequiredField">
                                {{item.fieldName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Guia de honorários</mat-label>
                        <mat-select formControlName="idLookupFeesRequiredField" multiple>
                            <mat-option *ngFor="let item of listLookupFeesRequiredField"
                                [value]="item.idLookupFeesRequiredField">
                                {{item.fieldName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Guia de internação</mat-label>
                        <mat-select formControlName="idLookupAdmissionRequiredField" multiple>
                            <mat-option *ngFor="let item of listLookupAdmissionRequiredField"
                                [value]="item.idLookupAdmissionRequiredField">
                                {{item.fieldName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="row" style="padding-left: 20px;">
            <div class="col-12 col-lg-6 col-xl-3 col-md-6 col-sm-6">
                <a mat-flat-button type="button" color="accent" class="btn-block" (click)="onCancelClick()">
                    Cancelar
                </a>
            </div>
            <div class="col-12 col-lg-6 col-xl-3 col-md-6 col-sm-6">
                <button mat-flat-button color="primary" class="btn-block" type="submit">
                    <span *ngIf="isLoading == false">Salvar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
            </div>
        </div>
    </form>
</div>