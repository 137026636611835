import { Component, Input, OnInit } from '@angular/core';
import { ListMedicalPrescriptionPendingResponse } from 'src/app/shared/services/responses/medical-record/list-medical-prescription-pending.response';

@Component({
  selector: 'app-bed-management-prescription-tab',
  templateUrl: './bed-management-prescription-tab.component.html',
  styleUrls: ['./bed-management-prescription-tab.component.css']
})
export class BedManagementPrescriptionTabComponent implements OnInit {

  constructor() { }

  @Input() listMedicalPrescriptionPendingResponse: ListMedicalPrescriptionPendingResponse;
  @Input() idSector: number;
  @Input() idService: number;
  @Input() idRoom: number;

  public isLoading: boolean;

  ngOnInit(): void {
  }
}