import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { MetaDataTypeEnum } from 'src/app/shared/enum/meta-data/meta-data-type.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { LookupService } from 'src/app/shared/services/API/meta-data/lookup.service';
import { MetaDataService } from 'src/app/shared/services/API/meta-data/meta-data.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { MetaDataOptionModel } from 'src/app/shared/services/models/meta-data/meta-data-option.model';
import { MetaDataTypeModel } from 'src/app/shared/services/models/meta-data/meta-data-type.model';
import { PostPutMetaDataRequest } from 'src/app/shared/services/requests/meta-data/post-put-meta-data.request';

@Component({
  selector: 'app-meta-data-register',
  templateUrl: './meta-data-register.component.html',
  styleUrls: ['./meta-data-register.component.css']
})
export class MetaDataRegisterComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private metaDataService: MetaDataService,
    private lookupMetaDataService: LookupService) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.master;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.master_meta_data;

  public model: FormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean = false;
  public actualServiceModule: number;
  public isSelect: boolean;

  public masks: Masks;
  public idMetaData: number;
  public metaDataRequest: PostPutMetaDataRequest = new PostPutMetaDataRequest();
  public listMetaDataType: MetaDataTypeModel[];
  public listMetaDataOption: MetaDataOptionModel[];

  ngOnInit(): void {
    this.populateMetaDataTypeSelect();

    this.model = this.formBuilder.group({
      idMetaData: [''],
      name: ['', [Validators.required]],
      idMetaDataType: ['', [Validators.required]],
      listOptions: this.formBuilder.array([])
    });

    this.masks = this.maskService.getMasks();

    this.idMetaData = null;
    if (this.activatedRoute.snapshot.paramMap.get('idMetaData'))
      this.idMetaData = parseInt(this.activatedRoute.snapshot.paramMap.get('idMetaData'));

    if (this.idMetaData != null) {
      this.isFirstLoading = true;
      this.isUpdate = true;
      this.populateMetaData();
    }

    if (!this.isUpdate)
      this.addNext();

    this.isLoading = false;
  }

  submit() {
    if (this.isLoading)
      return;

    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    this.isLoading = true;

    this.metaDataRequest.listMetaDataOptionName = [];
    this.metaDataRequest.idMetaDataType = this.model.get('idMetaDataType').value;
    this.metaDataRequest.metaDataName = this.model.get('name').value;

    this.model.get('listOptions').value.forEach(x => {
      this.metaDataRequest.listMetaDataOptionName.push(x.metaDataOptionName);
    });

    if (this.isUpdate)
      this.updateMetaData();
    else
      this.createMetaData();
  }

  populateMetaDataTypeSelect() {
    this.lookupMetaDataService.listLookup().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listMetaDataType = response.listMetaDataType;
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateMetaData() {
    this.metaDataService.getByIdMetaData(this.idMetaData).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.model.get('idMetaData').setValue(response.metaDataStruct.metaData.idMetaData);
        this.model.get('name').setValue(response.metaDataStruct.metaData.metaDataName);
        this.model.get('idMetaDataType').setValue(response.metaDataStruct.metaData.idMetaDataType);

        if (response.metaDataStruct.metaData.idMetaDataType == MetaDataTypeEnum.Select) {
          this.isSelect = true;
          response.metaDataStruct.listMetaDataOptionName.forEach(x => {
            (this.model.controls['listOptions'] as FormArray).push(
              this.formBuilder.group({ metaDataOptionName: [x] }));
          });
        }

        this.isLoading = false;
        this.isFirstLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  verifyMetaDataType() {
    let metadataType = this.model.get('idMetaDataType').value;

    if (metadataType != MetaDataTypeEnum.Select)
      this.isSelect = false;
    else {
      this.isSelect = true;
      if (this.model.controls['listOptions'].value.length == 0)
        this.addNext();
    }
  }

  updateMetaData() {
    this.metaDataService.updateMetaData(this.idMetaData, this.metaDataRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;
        this.router.navigate(['/master/meta-data']);
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  createMetaData() {
    this.metaDataService.createMetaData(this.metaDataRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;
        this.router.navigate(['/master/meta-data']);
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  createInput() {
    return this.formBuilder.group({
      metaDataOptionName: [''],
    });
  }

  addNext() {
    (this.model.controls['listOptions'] as FormArray).push(this.createInput())
  }

  removeOption(index: number) {
    (this.model.controls['listOptions'] as FormArray).removeAt(index)
  }
}