import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { EpisodePrescriptionComponent } from './pages/episode-prescription/episode-prescription.component';
import { ItemListComponent } from './pages/item-list/item-list.component';
import { ItemRegisterComponent } from './pages/item-register/item-register.component';
import { LocationListComponent } from './pages/location-list/location-list.component';
import { LocationRegisterComponent } from './pages/location-register/location-register.component';
import { MedicineGroupListComponent } from './pages/medicine-group-list/medicine-group-list.component';
import { MedicineGroupRegisterComponent } from './pages/medicine-group-register/medicine-group-register.component';
import { MedicineListComponent } from './pages/medicine-list/medicine-list.component';
import { MedicineRegisterComponent } from './pages/medicine-register/medicine-register.component';
import { MedicineSubgroupListComponent } from './pages/medicine-subgroup-list/medicine-subgroup-list.component';
import { MedicineSubgroupRegisterComponent } from './pages/medicine-subgroup-register/medicine-subgroup-register.component';
import { MedicineTypeListComponent } from './pages/medicine-type-list/medicine-type-list.component';
import { MedicineTypeRegisterComponent } from './pages/medicine-type-register/medicine-type-register.component';
import { PharmacyMovementListComponent } from './pages/pharmacy-movement-list/pharmacy-movement-list.component';
import { PharmacyMovementRegisterComponent } from './pages/pharmacy-movement-register/pharmacy-movement-register.component';
import { PharmacyComponent } from './pages/pharmacy/pharmacy.component';
import { PrescriptionListComponent } from './pages/prescription-list/prescription-list.component';
import { StorageListComponent } from './pages/storage-list/storage-list.component';
import { StorageRegisterComponent } from './pages/storage-register/storage-register.component';
import { KitListComponent } from './pages/kit-list/kit-list.component';
import { KitRegisterComponent } from './pages/kit-register/kit-register.component';
import { ReturnMedicineItemComponent } from './pages/return-medicine-item/return-medicine-item.component';
import { PharmacyConfigComponent } from './pages/pharmacy-config/pharmacy-config.component';

const routes: Routes = [
  { path: 'pharmacy', component: PharmacyComponent, canActivate: [AuthGuard] },
  { path: 'pharmacy/medicine', component: MedicineListComponent, canActivate: [AuthGuard] },
  { path: 'pharmacy/medicine/register', component: MedicineRegisterComponent, canActivate: [AuthGuard] },
  { path: 'pharmacy/medicine-subgroup', component: MedicineSubgroupListComponent, canActivate: [AuthGuard] },
  { path: 'pharmacy/medicine-subgroup/register', component: MedicineSubgroupRegisterComponent, canActivate: [AuthGuard] },
  { path: 'pharmacy/medicine-type', component: MedicineTypeListComponent, canActivate: [AuthGuard] },
  { path: 'pharmacy/medicine-type/register', component: MedicineTypeRegisterComponent, canActivate: [AuthGuard] },
  { path: 'pharmacy/medicine-group', component: MedicineGroupListComponent, canActivate: [AuthGuard] },
  { path: 'pharmacy/medicine-group/register', component: MedicineGroupRegisterComponent, canActivate: [AuthGuard] },
  { path: 'pharmacy/location', component: LocationListComponent, canActivate: [AuthGuard] },
  { path: 'pharmacy/location/register', component: LocationRegisterComponent, canActivate: [AuthGuard] },
  { path: 'pharmacy/item', component: ItemListComponent, canActivate: [AuthGuard] },
  { path: 'pharmacy/item/register', component: ItemRegisterComponent, canActivate: [AuthGuard] },
  { path: 'pharmacy/storage', component: StorageListComponent, canActivate: [AuthGuard] },
  { path: 'pharmacy/storage/register', component: StorageRegisterComponent, canActivate: [AuthGuard] },
  { path: 'pharmacy/movement', component: PharmacyMovementListComponent, canActivate: [AuthGuard] },
  { path: 'pharmacy/movement/register', component: PharmacyMovementRegisterComponent, canActivate: [AuthGuard] },
  { path: 'pharmacy/prescription', component: PrescriptionListComponent, canActivate: [AuthGuard] },
  { path: 'pharmacy/prescription/episode', component: EpisodePrescriptionComponent, canActivate: [AuthGuard] },
  { path: 'pharmacy/kit', component: KitListComponent, canActivate: [AuthGuard] },
  { path: 'pharmacy/kit/register', component: KitRegisterComponent, canActivate: [AuthGuard] },
  { path: 'pharmacy/return-medicine-item', component: ReturnMedicineItemComponent, canActivate: [AuthGuard] },
  { path: 'pharmacy/pharmacy-config', component: PharmacyConfigComponent, canActivate: [AuthGuard] }
];
    
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PharmacyRoutingModule { }
