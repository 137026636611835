import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { HygienizationTotemStatusEnum } from 'src/app/shared/enum/hygienization-totem-status.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { HygienizationTotemService } from 'src/app/shared/services/API/bed/hygienization-totem.service';
import { HygienizationTotemStruct } from 'src/app/shared/services/structs/bed/hygienization-totem.struct';
import { environment } from 'src/environments/environment';
import { HygienizationTotemDeleteModalComponent } from './hygienization-totem-delete-modal/hygienization-totem-delete-modal.component';

@Component({
  selector: 'app-hygienization-totem-list',
  templateUrl: './hygienization-totem-list.component.html',
  styleUrls: ['./hygienization-totem-list.component.css']
})
export class HygienizationTotemListComponent implements OnInit {

  constructor(
    private router: Router,
    private alertService: AlertService,
    public dialog: MatDialog,
    private hygienizationTotemService: HygienizationTotemService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.observation;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.observation_hygienization_totem;

  public allStatus = HygienizationTotemStatusEnum.todos;
  public activeStatus = HygienizationTotemStatusEnum.ativo;
  public inactiveStatus = HygienizationTotemStatusEnum.inativo;
  public isLoading: boolean;
  public searchText: string;
  public status: number;
  public listTotem: HygienizationTotemStruct[];

  ngOnInit(): void {
    this.status = this.allStatus;
    this.search();
  }

  search() {
    this.isLoading = true;
    if (this.searchText == "")
      this.searchText = null;
    this.hygienizationTotemService.getAllHygienizationTotem(this.searchText, this.status).subscribe({

      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listTotem = response.listTotemStruct;

        this.listTotem.forEach(x => {
          x.hygienizationTotemUrl = environment.urlApiBaseUI + "client/hygienization-totem/" + x.idHygienizationTotem;
        });

        this.isLoading = false;
      },

      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      },
    });
  }

  deleteTotem(idHygienizationTotem: number) {
    this.isLoading = true;
    this.hygienizationTotemService.deleteHygienizationTotem(idHygienizationTotem).subscribe({

      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.alertService.show('Sucesso', "Totem excluído com sucesso", AlertType.success);
        this.search();
      },

      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      },
    });
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }

  openDeleteModal(idHygienizationTotem: number) {
    const dialogRef = this.dialog.open(HygienizationTotemDeleteModalComponent, {
      data: {
        idHygienizationTotem: idHygienizationTotem,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.confirm) {
        this.deleteTotem(idHygienizationTotem);
      }
    });
  }
}

