import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { HygienizationStatusEnum } from 'src/app/shared/enum/hygienization-status.enum';
import { StatusQueueEnum } from 'src/app/shared/enum/status-queue.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { BedEpisodeService } from 'src/app/shared/services/API/bed/bed-episode.service';
import { FreeBedService } from 'src/app/shared/services/API/bed/free-bed.service';
import { UpdateStatusQueueService } from 'src/app/shared/services/API/orchestrator-queue/update-status-queue.service';
import { BedEpisodeRequest } from 'src/app/shared/services/requests/bed/bed-episode.request';
import { StatusQueueRequest } from 'src/app/shared/services/requests/queue/status-queue.request';
import { BedStruct } from 'src/app/shared/services/structs/bed/bed.struct';

@Component({
  selector: 'app-observation-patient-list-link-bed-modal',
  templateUrl: './observation-patient-list-link-bed-modal.component.html',
  styleUrls: ['./observation-patient-list-link-bed-modal.component.css']
})
export class ObservationPatientListLinkBedModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ObservationPatientListLinkBedModalComponent>,
    private alertService: AlertService,
    private bedEpisodeService: BedEpisodeService,
    private freeBedService: FreeBedService,
    private formBuilder: UntypedFormBuilder,
    private updateStatusQueueService: UpdateStatusQueueService,
    private router: Router,
  ) { }

  public isLoading: boolean;
  public model: UntypedFormGroup;
  public listFreeBed: BedStruct[] = [];
  public idSector: number;
  public hygienization: boolean = false;
  public hygienizedStatus = HygienizationStatusEnum.higienizado;
  public hygienizingStatus = HygienizationStatusEnum.em_higienizacao;
  public unhygienizedStatus = HygienizationStatusEnum.nao_higienizado;
  public inUseStatus = HygienizationStatusEnum.em_uso;
  public selectedBed: BedStruct;
  public unhygienizedAllocationConfirm: boolean = false;
  public oldStatusHygienization: number = null;  
  public showTissGuideButton: boolean = false;

  ngOnInit(): void {
    this.isLoading = false;
    this.idSector = this.data.idSector;
    this.hygienization = this.data.hygienization;
    this.showTissGuideButton = this.data.showTissGuideButton;
    this.populateFreeBedSelect();
    this.model = this.formBuilder.group({
      idBed: [null],
      unhygienizedAllocation: [false],
    });
  }

  close() {
    this.matDialogRef.close({ isAttend: false });
  }

  submit() {
    if (this.isLoading || !this.model.valid) {
      return;
    }
    this.isLoading = true;

    if (!this.data.observationConfigSector) {
      this.alertService.show('Erro', "Esse setor não possui configuração do módulo vinculada a ele.", AlertType.error);
      this.isLoading = false;
      return;
    }
    if (this.data.observationConfigSector.observationConfig.isMandatory && !this.listFreeBed) {
      this.alertService.show('Erro', "Você não possui leitos livres para realizar o atendimento. Cadastre um leito novo ou libere um leito antes.", AlertType.error);
      this.isLoading = false;
      return;
    }
    if (this.data.observationConfigSector.observationConfig.isMandatory && !this.model.get('idBed').value) {
      this.alertService.show('Erro', "Você deve alocar o paciente a um leito antes de atende-lo.", AlertType.error);
      this.isLoading = false;
      return;
    }

    if (!this.model.get('idBed').value) {
      this.isLoading = false;
      this.router.navigate(['/observation/bed-management/details', { idEpisode: this.data.idEpisode, idSector: this.data.idSector, idService: this.data.idService, idRoom: this.data.idRoom, idBed: null, isBedManagement: false, idQueue: this.data.idQueue, showTissGuideButton: this.showTissGuideButton }]);
      this.matDialogRef.close({ isAttend: true });
    }
    else {
      this.linkBedEpisode();
    }
  }

  clickCancel() {
    this.matDialogRef.close({ isAttend: false });
  }

  populateFreeBedSelect() {
    this.freeBedService.listFreeBed(this.idSector, this.hygienization).subscribe({
      next: (response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listFreeBed = response.listBedStruct;
        this.listFreeBed.sort((a, b) => {

          if (a.idHygienizationStatus == b.idHygienizationStatus) {
            return 0;
          }

          else {

            if (a.idHygienizationStatus == this.hygienizedStatus) {
              return -1;
            }

            else if (b.idHygienizationStatus == this.hygienizedStatus) {
              return 1;
            }

            else if (a.idHygienizationStatus == this.hygienizingStatus) {
              return -1;
            }

            else if (b.idHygienizationStatus == this.hygienizingStatus) {
              return 1;
            }

            else if (a.idHygienizationStatus == this.unhygienizedStatus) {
              return -1;
            }

            else if (b.idHygienizationStatus == this.unhygienizedStatus) {
              return 1;
            }
          }
        });
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  updateStatusPatient() {

    let updateStatusRequest: StatusQueueRequest = new StatusQueueRequest();

    updateStatusRequest.idStatusQueue = StatusQueueEnum.atendido;
    updateStatusRequest.idQueue = this.data.idQueue;
    updateStatusRequest.idEpisode = this.data.idEpisode;

    this.updateStatusQueueService.updateStatusBedLink(this.data.idService, updateStatusRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.alertService.show('Sucesso', "Paciente foi associado ao leito com sucesso", AlertType.success);
        this.matDialogRef.close({ isAttend: true });
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  linkBedEpisode() {
    let bedEpisodeRequest: BedEpisodeRequest = new BedEpisodeRequest();

    bedEpisodeRequest.idBed = this.model.get('idBed').value;
    bedEpisodeRequest.idEpisode = this.data.idEpisode;
    bedEpisodeRequest.idLastRoom = this.data.idRoom;
    bedEpisodeRequest.hygienizationConfig = this.hygienization;

    let bed: BedStruct = this.listFreeBed.find(x => x.idBed == bedEpisodeRequest.idBed);

    if (this.hygienization && (bed.idHygienizationStatus == this.hygienizingStatus || bed.idHygienizationStatus == this.unhygienizedStatus)) {

      bedEpisodeRequest.idBedHygienizationStatus = bed.idHygienizationStatus;
      this.model.get('unhygienizedAllocation').setValidators(Validators.requiredTrue);
      this.model.get('unhygienizedAllocation').updateValueAndValidity();
    }

    this.bedEpisodeService.linkBedEpisode(bedEpisodeRequest).subscribe({
      next: (response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.updateStatusPatient();
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  checkHygienization(event: any) {
    if (event.value) {
      let idBed = event.value;
      this.selectedBed = this.listFreeBed.find(x => x.idBed === idBed);

      if (this.hygienization && (this.selectedBed.idHygienizationStatus == this.hygienizingStatus || this.selectedBed.idHygienizationStatus == this.unhygienizedStatus)) {
        this.unhygienizedAllocationConfirm = true;
        this.model.get('unhygienizedAllocation').setValidators(Validators.requiredTrue);
        this.model.get('unhygienizedAllocation').updateValueAndValidity();
      }
      else {
        this.unhygienizedAllocationConfirm = false;
        this.model.get('unhygienizedAllocation').setValidators([]);
        this.model.get('unhygienizedAllocation').setValue(false);
        this.model.get('unhygienizedAllocation').updateValueAndValidity();
      }
    }
  }

  toggleConfirmation(event: any) {
    if (event?.checked ?? false) {
      this.oldStatusHygienization = this.selectedBed.idHygienizationStatus
      this.selectedBed.idHygienizationStatus = this.hygienizedStatus;
    }
    else
      this.selectedBed.idHygienizationStatus = this.oldStatusHygienization;
  }
}