export class ReleaseEpisodeRequest{
    
    public idEpisode: number;
    
    public isLiberateBed: boolean;

    public idSector: number;

    public idService: number;

    public idEpisodeAdministrativeRelease: number;
}