<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <h1>Prescrições</h1>
        <div class="white-body">
            <div class="filters">
                <div class="row">
                    <div class="col-12 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Nome do Paciente</mat-label>
                            <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText"
                                (keyup)="onKeySearch($event)">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Episódio</mat-label>
                            <input matInput type="number" id="idEpisode" name="idEpisode" [(ngModel)]="idEpisode"
                                (keyup)="onKeyEpisodeSearch($event)">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Setor</mat-label>
                            <mat-select [(ngModel)]="idSector" (selectionChange)="search()">
                                <mat-option value="">Todos</mat-option>
                                <mat-option *ngFor="let item of listSector"
                                    [value]="item.idSector">{{item.sectorName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Linha de cuidado</mat-label>
                            <mat-select [(ngModel)]="idCareLinePriority" (selectionChange)="search()">
                                <mat-option value="">Todas</mat-option>
                                <mat-option *ngFor="let item of listCareLinePriority"
                                    [value]="item.idCareLinePriority">{{item.careLinePriorityName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-md-2">
                        <mat-checkbox class="example-margin" (change)="checked($event)">Liberados nas últimas
                            24h</mat-checkbox>
                        <mat-checkbox class="example-margin" (change)="nonDispensed($event)">Não
                            dispensados</mat-checkbox>
                    </div>
                </div>
            </div>
            <div class="card-list" *ngIf="!isLoading && listMedicalPrescriptionEpisode != null">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listMedicalPrescriptionEpisode">
                        <div class="card-item">
                            <div *ngIf="item.hasPrescription" class="infos">
                                <div *ngIf="item.triage" class="col-12 header">
                                    <app-gravity-tag [gravityName]="item.triage.gravityName"
                                        [idGravity]="item.triage.idGravity">
                                    </app-gravity-tag>
                                    <span class="care-line" *ngIf="item.episode.careLinePriorityName"><b>Linha de
                                            cuidado:</b>
                                        {{item.episode.careLinePriorityName}}</span>
                                </div>
                                <span class="primary">{{item.lastPrescriptionDate | date:'dd/MM/yyyy HH:mm'}} -
                                    {{item.episode.episodeLocation}}</span>
                                <span class="secundary">Episódio: {{item.episode.idEpisode}}</span>
                                <span class="bold">Paciente: {{item.episode.patientSocialName?
                                    item.episode.patientSocialName
                                    : item.episode.patientName}}</span>
                                <span *ngIf="item.episode.birthDate" class="bold">Data de nascimento:
                                    {{item.episode.birthDate | date: 'dd/MM/yyyy'}}</span>
                                <span class="secundary">Prescrições: {{item.episode.internalPrescriptionCount}}</span>
                                <span class="secundary">Quant. materiais: {{item.requestMaterial.length}}</span>
                                <span class="secundary-status last-info">{{item.prescriptionStatus}}</span>
                            </div>
                            <div *ngIf="!(item.hasPrescription)" class="infos">
                                <div *ngIf="item.triage" class="col-12">
                                    <app-gravity-tag [gravityName]="item.triage.gravityName"
                                        [idGravity]="item.triage.idGravity">
                                    </app-gravity-tag>
                                </div>
                                <span class="primary">{{item.lastRequestMaterialDate | date:'dd/MM/yyyy HH:mm'}} -
                                    Pedido de Material - {{item.episode.episodeLocation}}</span>
                                <span class="secundary">Episódio: {{item.episode.idEpisode}}</span>
                                <span class="secundary">{{item.episode.patientSocialName? item.episode.patientSocialName
                                    : item.episode.patientName}}</span>
                                <span class="secundary" *ngIf="item.episode.careLinePriorityName">Linha de cuidado:
                                    {{item.episode.careLinePriorityName}}</span>
                                <span class="secundary">Quant. materiais: {{item.requestMaterial.length}}</span>
                                <span class="secundary-status last-info">{{item.requestMaterialStatus}}</span>
                                <span class="secundary"></span>
                            </div>
                            <div class="actions">
                                <a><mat-icon aria-hidden="false" aria-label="Editar"
                                        [routerLink]="['/pharmacy/prescription/episode', {idEpisode: item.episode.idEpisode, idSector: this.idSector}]">edit</mat-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <app-empty-list
                *ngIf="!isLoading && listMedicalPrescriptionEpisode != undefined && listMedicalPrescriptionEpisode.length == 0"></app-empty-list>
        </div>

        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>

</div>