import { Component, Input, OnInit } from '@angular/core';
import { DataSourceVariable } from 'src/app/shared/services/models/orchestrator-data-intelligence/data-source-variable.model';
import { CompleteTableData } from '../complete-table-data';

@Component({
  selector: 'app-data-source-result-table',
  templateUrl: './data-source-result-table.component.html',
  styleUrls: ['./data-source-result-table.component.css']
})
export class DataSourceResultTableComponent implements OnInit {

  @Input() listDSVariable: DataSourceVariable[];
  @Input() isLoading: boolean;
  @Input() completeTableData: CompleteTableData;

  constructor() { }

  ngOnInit(): void {
  }


  openResultModal() {
    
  }
}
