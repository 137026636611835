import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ExamTypeRequest } from '../../requests/SADT/exam-type.request';
import { GetExamTypeResponse } from '../../responses/SADT/get-exam-type.response';
import { ListExamTypeResponse } from '../../responses/SADT/list-exam-type.response';

@Injectable({
  providedIn: 'root'
})
export class ExamTypeService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getExamType(idExamType: number): Observable<GetExamTypeResponse> {
    
    let uri = `ExamType/idExamType/${idExamType}`;  
    
    return this.httpClient.get<GetExamTypeResponse>(environment.urlApiSADT + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public listExamType(searchText: string, isActive: boolean): Observable<ListExamTypeResponse> {
    
    let uri = 'ExamType?' 

    if(searchText != null)
      uri = uri+ `searchText=${searchText}&`;
    
    if(isActive !=null)
      uri = uri + `isActive=${isActive}&`   
    
    return this.httpClient.get<ListExamTypeResponse>(environment.urlApiSADT + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public listAllExamType(): Observable<ListExamTypeResponse> {
    
    let uri = 'ExamType/getAll' 
    
    return this.httpClient.get<ListExamTypeResponse>(environment.urlApiSADT + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public listAllExamTypeWithExams(): Observable<ListExamTypeResponse> {
    
    let uri = 'ExamType/getAllWithExams'
    
    return this.httpClient.get<ListExamTypeResponse>(environment.urlApiSADT + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public createExamType(body: ExamTypeRequest): Observable<ReturnStruct>{

    let uri = `ExamType`

    return this.httpClient.post<ReturnStruct>(environment.urlApiSADT + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public updateExamType(idExamType: number, body: ExamTypeRequest): Observable<ReturnStruct>{

    let uri = `ExamType/idExamType/${idExamType}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiSADT + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public deleteExamType(idExamType: number): Observable<ReturnStruct>{

    let uri = `ExamType/idExamType/${idExamType}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiSADT + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
