import { PatientNecessity } from "../../responses/medical-record/lookup.response";
import { RegisteredMetaDataStruct } from "../medical-record/registered-meta-data.struct";
import { SigaCertificateStruct } from "./siga-certificate.struct";
import { SigaCnhStruct } from "./siga-cnh.struct";
import { SigaCpfStruct } from "./siga-cpf.struct";
import { SigaCtpsStruct } from "./siga-ctps.struct";
import { SigaForeignIdentityStruct } from "./siga-foreign-identity.struct";
import { SigaIdentityStruct } from "./siga-identity.struct";
import { SigaPassportStruct } from "./siga-passport.struct";
import { SigaPatientAddressStruct } from "./siga-patient-address.struct";
import { SigaPatientContactStruct } from "./siga-patient-contact.struct";
import { SigaPisPasepStruct } from "./siga-pis-pasep.struct";
import { SigaVoterRegistrationStruct } from "./siga-voter-registration.struct";

export class SigaPatientStruct {
    public idRoom: number;
    public idPatient: number;
    public idEpisode: number;
    public fullTicket: string;
    public isPriority: boolean;
    public cns: string;
    public patientName: string;
    public socialName: string;
    public fatherName: string;
    public unknownFather: boolean;
    public motherName: string;
    public unknownMother: boolean;
    public birthDate: Date;
    public susSexCode: string; // select Sexo
    public susRaceCode: string; // select  Raça
    public susEthnicityCode: string;  // select etnia
    public susFamilySituationCode: string;  // select situação familiar
    public susEducationCode: string;  // select escolaridade
    public frequentsSchool: boolean; 
    public susOccupationCode: string;  // select ocupação
    public nationalityTypeCode: string;  // select tipo nacionalidade 2=brasileiro, 3= brasileiro anturalizado, 4=extrangeiro
    public birthCitytCodeSus: string; // select cidade
    public countryArrivalDate: Date;
    public naturalizationOrdinance: string; 
    public naturalizationDate: Date;
    public registerProtocol: string; 
    public susHealthInsuranceCode: string;  // select convênio
    public hasSusHealthInsurance: boolean; 
    public codigoCnesEstabelecimentoCadastro: string;  //[CodigoCMES]
    public codigoCnesEstabelecimentoVinculo: string;  //[CodigoCMES]
    public observation: string; 
    public birthCountryCode: string;  // select país de nascimento
    public dnv: string; 
    public patientContact: SigaPatientContactStruct = new SigaPatientContactStruct(); 
    public patientAddress: SigaPatientAddressStruct = new SigaPatientAddressStruct(); 
    public identity: SigaIdentityStruct = new SigaIdentityStruct(); 
    public foreignIdentity: SigaForeignIdentityStruct = new SigaForeignIdentityStruct(); 
    public voterRegistration: SigaVoterRegistrationStruct = new SigaVoterRegistrationStruct(); 
    public ctps: SigaCtpsStruct = new SigaCtpsStruct(); 
    public cpf: SigaCpfStruct = new SigaCpfStruct(); 
    public pisPasep: SigaPisPasepStruct = new SigaPisPasepStruct(); 
    public cnh: SigaCnhStruct = new SigaCnhStruct(); 
    public birthCertificate: SigaCertificateStruct = new SigaCertificateStruct(); 
    public marriageCertificate: SigaCertificateStruct = new SigaCertificateStruct(); 
    public divorceCertificate: SigaCertificateStruct = new SigaCertificateStruct(); 
    public indigenousAdmCertificate: SigaCertificateStruct = new SigaCertificateStruct(); 
    public passport: SigaPassportStruct = new SigaPassportStruct(); 
    public listMetadata: RegisteredMetaDataStruct[] = [];

    public unidentifiedPatientDescription: string;
    public idArrivalType: number;
    public idOrigin: number;
    public companionName: string;
    public companionPhone: string;
    public companionCpf: string;
    public companionParentage: string;
    public patientNecessities: PatientNecessity[] = [];
    public listIdPatientNecessity: number[] = [];
}