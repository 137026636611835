<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-confirm-modal-list">
            <h2>Aviso</h2>
        </div>
        <div class="body-confirm-modal-list">
            <p>Paciente sem dispositivo para classificação remota!</p>
            <p>Caso não deseje realizar um procedimento remoto, selecione outra sala!</p>
            <p>Ou procure por outro CPF!</p>
        </div>
        <div class="footer-confirm-modal-list">
            <button mat-flat-button color="accent" class="btn-block" (click)="clickCancel(1)">Nova Sala</button>
            <button mat-flat-button color="accent" class="btn-block" (click)="clickCancel(2)">Procurar outro CPF</button>
        </div>
    </div>
</div>