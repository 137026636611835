import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ClassificationData, UtilsClassificationService } from 'src/app/classification/utils.service';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { birthdateValidator } from 'src/app/shared/custom-validators/birthdate.validator';
import { VerifyCNS } from 'src/app/shared/custom-validators/cns.validator';
import { VerifyCPF } from 'src/app/shared/custom-validators/cpf.validator';
import { NatureOfAttendanceEnum } from 'src/app/shared/enum/medical-record/nature-of-attendance.enum';
import { CalcAgeService } from 'src/app/shared/services/API/medical-record/calc-age.service';
import { EpisodeService } from 'src/app/shared/services/API/medical-record/episode.service';
import { MedicalRecordLookupService } from 'src/app/shared/services/API/medical-record/lookup.service';
import { PatientService } from 'src/app/shared/services/API/medical-record/patient.service';
import { ReportService } from 'src/app/shared/services/API/orchestrator-patient/report.service';
import { FowardService } from 'src/app/shared/services/API/orchestrator-queue/foward.service';
import { VirtualTriageConfigService } from 'src/app/shared/services/API/virtual-triage/virtual-triage-config.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { PriorityModel } from 'src/app/shared/services/models/totem/priority.model';
import { PatientPatchRequest } from 'src/app/shared/services/requests/medical-record/patient-patch.request';
import { PatientRequest } from 'src/app/shared/services/requests/medical-record/patient.request';
import { CalcAgeResponse } from 'src/app/shared/services/responses/medical-record/calc-age.response';
import { Gender, Origin, ArrivalType, Comorbidity } from 'src/app/shared/services/responses/medical-record/lookup.response';
import { AllergyStruct } from 'src/app/shared/services/structs/medical-record/allergy.struct';
import { PatientStruct } from 'src/app/shared/services/structs/medical-record/patient.struct';
import { MetaDataKeyValueStruct } from 'src/app/shared/services/structs/meta-data/meta-data-key-value.struct';
import { RoomMeasurerStruct } from 'src/app/shared/services/structs/risk-classification/room-measurer.struct';
import { VirtualTriageConfigStruct } from 'src/app/shared/services/structs/virtual-triage/virtual-triage-config.struct';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-pre-register',
  templateUrl: './pre-register.component.html',
  styleUrls: ['./pre-register.component.css']
})
export class PreRegisterComponent implements OnInit, OnDestroy {

  constructor(private formBuilder: FormBuilder,
    private alertService: AlertService,
    private patientService: PatientService,
    private episodeService: EpisodeService,
    private lookupService: MedicalRecordLookupService,
    private maskService: MaskService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public fowardService: FowardService,
    public reportService: ReportService,
    public router: Router,
    public utilService: UtilService,
    public utilClassification: UtilsClassificationService,
    private calcAgeService: CalcAgeService,
    private virtualTriageConfigService: VirtualTriageConfigService,
  ) { }

  @Input() idEpisode: number;
  @Input() idRoom: number;
  @Input() idSector: number;
  @Output() next = new EventEmitter<any>();
  @Output() cancelAttendance = new EventEmitter<any>();

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.digital_prompt_service;

  public model: FormGroup;
  public masks: Masks;
  public virtualTriageConfigStruct: VirtualTriageConfigStruct;

  public genderList: Gender[];
  public listOfPriorities: PriorityModel[];
  public roomMeasurerStruct: RoomMeasurerStruct[];
  public listAllergy: AllergyStruct[] = [];
  public originList: Origin[];
  public arrivalTypeList: ArrivalType[];
  public listComorbidity: Comorbidity[] = [];
  public listValueMetaData: MetaDataKeyValueStruct[] = [];

  public isFirstLoading: boolean = true;
  public isLoading: boolean = false;
  public newPatient: boolean = false;
  public showMandatoryCnsCheckbox: boolean = false;
  public showMandatoryCpfCheckbox: boolean = false;
  public validNps: boolean = false;

  private ageYear: number;
  private ageMonth: number;
  private ageDay: number;
  public idPatient: number = null;

  public disallowedIdPriorities: number[] = [];

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();

    this.model = this.formBuilder.group({
      patientName: ['', [Validators.required]],
      socialName: [''],
      cpf: ['', [VerifyCPF()]],
      birthDate: ['', [Validators.required, birthdateValidator()]],
      idGender: [''],
      cns: ['', [VerifyCNS()]],
      listIdPriority: [''],
      patientAge: [{ value: '', disabled: true }],
      notMandatoryCpf: [false],
      notMandatoryCns: [false],
      phone: [''],
      secondePhone: [''],
      listIdComorbidity: [],
    });

    this.populateLookupSelect();
    this.getLookupComorbidity();

    if (this.idEpisode != null)
      this.populatePatientData(this.idEpisode);

    if (this.idSector != null)
      this.getConfig();
  }

  submit() {
    if (this.model.invalid) {
      this.alertService.show('Erro', 'Você precisa informar todos os campos obrigatórios', AlertType.error);
      return;
    }

    let patient = new PatientPatchRequest();
    if (Number.isInteger(this.idPatient))
      patient.idPatient = this.idPatient;

    if (Number.isInteger(this.idEpisode))
      patient.idEpisode = this.idEpisode;

    patient.patientName = this.model.get('patientName').value;
    patient.socialName = this.model.get('socialName').value;
    patient.cpf = this.model.get('cpf').value ? this.model.get('cpf').value.replace(/[^0-9]+/g, '') : "";
    patient.birthDate = this.maskService.formatStringToDate(this.model.get('birthDate').value);
    patient.idGender = this.model.get('idGender').value;
    patient.cns = this.model.get('cns').value;
    patient.phone = this.model.get('phone').value ? this.model.get('phone').value : null;
    patient.listIdComorbidity = this.model.get('listIdComorbidity').value;
    if (this.model.get('notMandatoryCns').value)
      patient.notMandatoryCns = this.model.get('notMandatoryCns').value;
    if (this.model.get('notMandatoryCpf').value)
      patient.notMandatoryCpf = this.model.get('notMandatoryCpf').value;
    patient.idNatureOfAttendance = NatureOfAttendanceEnum.online;

    if (this.virtualTriageConfigStruct && this.virtualTriageConfigStruct.listIdMetaData && this.virtualTriageConfigStruct.listIdMetaData.length > 0) {
      for (let idMetaData of this.virtualTriageConfigStruct.listIdMetaData) {
        const formControl = this.model.get(idMetaData.toString());

        if (formControl && formControl.value) {
          let metadataSend = new MetaDataKeyValueStruct();
          metadataSend.key = idMetaData;
          metadataSend.value = formControl.value.toString();
          patient.metaData.push(metadataSend);
        }
      }
    }

    this.isLoading = true;
    this.patientService.updatePatient(patient).subscribe({
      next: (response) => {
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        let data: ClassificationData = new ClassificationData();
        data.idEpisode = response.idEpisode;
        data.idPatient = response.idPatient;
        data.datetimeStartTriage = response.datetimeStartTriage;
        data.idGender = patient.idGender;
        data.birthDate = patient.birthDate;
        data.newPatient = this.newPatient;
        data.ageYears = this.ageYear;
        data.ageMonth = this.ageMonth;
        data.ageDay = this.ageDay;
        data.listAllergy = this.listAllergy;
        data.idSector = parseInt(this.activatedRoute.snapshot.paramMap.get('idSector'));
        data.idRoom = parseInt(this.activatedRoute.snapshot.paramMap.get('idRoom'));

        data.patientData = new PatientRequest();
        data.patientData.idPatient = response.idPatient;
        data.patientData.idEpisode = response.idEpisode;
        data.patientData.patientName = patient.patientName;
        data.patientData.socialName = patient.socialName;
        data.patientData.phone1 = patient.phone;
        data.patientData.phone2 = this.model.get('secondePhone') ? this.model.get('secondePhone').value : null;
        data.patientData.cpf = patient.cpf;
        data.patientData.cns = patient.cns;
        data.patientData.genderName = this.model.get('idGender') ? this.genderList.find(x => x.idGender == this.model.get('idGender').value).genderName : null;
        data.patientData.birthDate = patient.birthDate;
        data.patientData.patientAge = this.model.get('patientAge') ? this.model.get('patientAge').value : null;

        this.utilClassification.updateClassificationData(data);

        this.forward();
      }
    });
  }

  cpfChangeValidator(event: MatCheckboxChange) {
    if (event.checked) {
      this.model.get('cpf').clearValidators();
      this.model.get('cpf').setValue('');
      this.model.get('cpf').disable();
      this.model.get('cpf').updateValueAndValidity();
    }
    else {
      this.model.get('cpf').setValidators([Validators.required, VerifyCPF()]);
      this.model.get('cpf').enable();
      this.model.get('cpf').updateValueAndValidity();
      this.model.get('notMandatoryCpf').setValue(false);
    }
  }

  cnsChangeValidator(event: MatCheckboxChange) {
    if (event.checked) {
      this.model.get('cns').clearValidators();
      this.model.get('cns').setValue('');
      this.model.get('cns').disable();
      this.model.get('cns').updateValueAndValidity();
    }
    else {
      this.model.get('cns').setValidators([Validators.required, VerifyCNS()]);
      this.model.get('cns').enable();
      this.model.get('cns').updateValueAndValidity();
      this.model.get('notMandatoryCns').setValue(false);
    }
  }

  populatePatientData(idEpisode: number) {
    this.episodeService.getPatientByEpisode(idEpisode).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        if (response.episodeStruct.listAllergy && response.episodeStruct.listAllergy.length > 0)
          this.listAllergy = response.episodeStruct.listAllergy;

        if (response.patient != null && response.patient.idPatient != null)
          this.mapModel(response.patient);

        this.isFirstLoading = false;
      }
    });
  }

  mapModel(patient: PatientStruct) {
    this.idPatient = patient.idPatient;
    this.model.get('patientName').setValue(patient.patientName);
    this.model.get('socialName').setValue(patient.socialName);
    this.model.get('cpf').setValue(patient.cpf);
    let birthDate = this.maskService.formatDateToString(patient.birthDate, false);
    this.model.get('birthDate').setValue(birthDate);
    if (birthDate) {
      this.calcAgeService.getAge(birthDate).subscribe((calcAgeResponse: CalcAgeResponse) => {
        this.ageYear = calcAgeResponse.year;
        this.ageMonth = calcAgeResponse.months;
        this.ageDay = calcAgeResponse.days;
        this.model.get('patientAge').setValue(`${calcAgeResponse.year}a ${calcAgeResponse.months}m ${calcAgeResponse.days}d`);
      });
    }

    this.model.get('idGender').setValue(patient.idGender ? patient.idGender.toString() : null);
    this.model.get('cns').setValue(patient.cns?.replaceAll(" ", ""));
    this.model.get('phone').setValue(patient.phone1?.replaceAll(" ", "").replaceAll("(", "").replaceAll(")", "").replaceAll("-", ""));
    this.model.get('listIdComorbidity').setValue(patient.listIdComorbidity);
    this.listValueMetaData = patient.metaData;

    let data: ClassificationData = new ClassificationData();
    data.idEpisode = this.idEpisode;
    data.idPatient = patient.idPatient;
    data.idGender = patient.idGender;
    data.birthDate = patient.birthDate;
    data.listAllergy = patient.listAllergy;
    data.idSector = parseInt(this.activatedRoute.snapshot.paramMap.get('idSector'));
    data.idRoom = parseInt(this.activatedRoute.snapshot.paramMap.get('idRoom'));

    data.patientData = new PatientRequest();
    data.patientData.idPatient = patient.idPatient;
    data.patientData.idEpisode = this.idEpisode;
    data.patientData.patientName = patient.patientName;
    data.patientData.socialName = patient.socialName;
    data.patientData.phone1 = patient.phone1;
    data.patientData.phone2 = patient.phone2;
    data.patientData.cpf = patient.cpf;
    data.patientData.cns = patient.cns;
    data.patientData.genderName = patient.idGender ? this.genderList?.find(x => x.idGender == patient.idGender).genderName : null;
    data.patientData.birthDate = patient.birthDate;
    data.patientData.patientAge = patient.patientAge;

    this.utilClassification.updateClassificationData(data);
  }

  populateLookupSelect() {
    this.lookupService.getLookup().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.genderList = response.listGenders;
        this.isFirstLoading = false;
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getLookupComorbidity() {
    this.lookupService.getLookup().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listComorbidity = response.listComorbidity;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  onKeySearchAge(event: any) {
    if (this.model.get('birthDate').invalid)
      return;

    if (event.target.value.length == 10) {
      this.calcAgeService.getAge(event.target.value).subscribe((calcAgeResponse: CalcAgeResponse) => {
        this.ageYear = calcAgeResponse.year;
        this.ageMonth = calcAgeResponse.months;
        this.ageDay = calcAgeResponse.days;
        this.model.get('patientAge').setValue(`${calcAgeResponse.year}a ${calcAgeResponse.months}m ${calcAgeResponse.days}d`);
      });
    }
  }

  forward() {
    this.next.emit();
  }

  ngOnDestroy(): void {

  }

  getConfig() {
    this.virtualTriageConfigService.getVirtualTriageConfigBySectorWithoutPermission(this.idSector).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (response.virtualTriageConfigStruct) {
          this.virtualTriageConfigStruct = new VirtualTriageConfigStruct();
          this.virtualTriageConfigStruct = response.virtualTriageConfigStruct;
        }

        this.isLoading = false;
        this.isFirstLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  cancelAttandance() {
    if (this.isLoading) return;
    this.isLoading = true;
    this.cancelAttendance.emit();
  }
}