import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { LeanVelocimeterConfigStruct } from 'src/app/shared/services/structs/panel-lean-config/lean-velocimeter-config.struct';
import { ConfigPanelLeanService } from 'src/app/shared/services/API/panel-lean-config/config-panel-lean.service';
import { PostPutConfigPanelLeanRequest } from 'src/app/shared/services/requests/panel-lean-config/post-put-config-panel-lean.request';
import { KpiLeanFlowStruct } from 'src/app/shared/services/structs/panel-lean-config/kpi-lean-flow.struct';
import { KpiLeanFieldStruct } from 'src/app/shared/services/structs/panel-lean-config/kpi-lean-field.struct';
import { KpiLeanFieldTypeEnum } from 'src/app/shared/enum/panel-lean-config/kpi-lean-field-type.enum';
import { RelationKpiLeanFieldHealthUnitModel } from 'src/app/shared/services/models/panel-lean-config/relation-kpi-lean-field-health-unit.model';
@Component({
  selector: 'app-lean-config',
  templateUrl: './lean-config.component.html',
  styleUrls: ['./lean-config.component.css']
})
export class LeanConfigComponent implements OnInit{
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private configPanelLeanService: ConfigPanelLeanService,
    private maskService: MaskService,
  ) { }

  public leanVelocimeterConfig: LeanVelocimeterConfigStruct;
  public listKpiLeanFlowStruct: KpiLeanFlowStruct[] = [];

  public masks: Masks;
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.flow_management;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.lean_config;
  public timeKpiLeanFieldTypeEnum: KpiLeanFieldTypeEnum = KpiLeanFieldTypeEnum.Time;
  public intKpiLeanFieldTypeEnum: KpiLeanFieldTypeEnum = KpiLeanFieldTypeEnum.int;
  public compareValue: boolean = false;

  public model: UntypedFormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;

  ngOnInit(): void {
    this.isFirstLoading = true;
    this.isLoading = true;
    this.leanVelocimeterConfig = new LeanVelocimeterConfigStruct;
    this.masks = this.maskService.getMasks();

    this.model = this.formBuilder.group({
      timeAverageMedicDoor: ['', [Validators.required]],
      maxTimeMedicDoor: ['', [Validators.required]],
      timeAverageMedicDecision: ['', [Validators.required]],
      maxTimeMedicDecision: ['', [Validators.required]],
      timeAverageDecisionExit: ['', [Validators.required]],
      maxTimeDecisionExit: ['', [Validators.required]],
      listKpiLeanFields: this.formBuilder.array([]),
    });

    this.getConfigPanelLean();
  } 

  submit() {
    if (this.isLoading)
      return;

    this.isLoading = true;
    if (this.model.invalid) {
      this.isLoading = false;
      this.alertService.show('Atenção', 'Verifique/preencha todos os campos em vermelho', AlertType.warning);
      return;
    }
    
    

    if(this.model.get('maxTimeMedicDecision').value <= this.model.get('timeAverageMedicDecision').value || this.model.get('maxTimeDecisionExit').value <= this.model.get('timeAverageDecisionExit').value || this.model.get('maxTimeMedicDoor').value <= this.model.get('timeAverageMedicDoor').value)
    {
      this.isLoading = false;
      this.alertService.show('Atenção', 'O valor do tempo máximo não pode ser menor ou igual ao tempo ideal', AlertType.warning);
      return;
    }

    let request: PostPutConfigPanelLeanRequest = new PostPutConfigPanelLeanRequest();
    request = this.populateRequest(request);

    this.configPanelLeanService.PostPutConfigPanelLean(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.alertService.show('Sucesso!', 'Dados configurados com sucesso', AlertType.success);
        this.isLoading = false;
        this.reloadComponent();
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      }
    });
  }

  backToFlow(){
    if (this.isLoading)
      return;

    this.router.navigate(['/flow-management']);
  }

  reloadComponent(){
    const url= this.router.url;
    this.router.navigateByUrl('/',{skipLocationChange:true}).then(()=>{
        this.router.navigate([`/${url}`]).then(()=>{
        })
    })
}

  getConfigPanelLean(){
    this.isLoading = true;
    this.configPanelLeanService.GetConfigPanelLeanByHealthUnit().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        this.leanVelocimeterConfig = response.leanVelocimeterConfigStruct;
        this.listKpiLeanFlowStruct = response.listKpiLeanFlowStruct;
        this.populateVelocimeterConfigValues(this.leanVelocimeterConfig);
        this.populateKpiLeanFields();
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      }
    });
  }

  populateRequest(request: PostPutConfigPanelLeanRequest): PostPutConfigPanelLeanRequest{
    request.timeAverageMedicDoor = this.maskService.formatStringToTime(this.model.get('timeAverageMedicDoor').value.toString());
    request.maxTimeMedicDoor = this.maskService.formatStringToTime(this.model.get('maxTimeMedicDoor').value.toString());
    request.timeAverageMedicDecision = this.maskService.formatStringToTime(this.model.get('timeAverageMedicDecision').value.toString());
    request.maxTimeMedicDecision = this.maskService.formatStringToTime(this.model.get('maxTimeMedicDecision').value.toString());
    request.timeAverageDecisionExit = this.maskService.formatStringToTime(this.model.get('timeAverageDecisionExit').value.toString());
    request.maxTimeDecisionExit = this.maskService.formatStringToTime(this.model.get('maxTimeDecisionExit').value.toString());

    if (this.model.get('listKpiLeanFields') && this.model.get('listKpiLeanFields').value && this.model.get('listKpiLeanFields').value.length > 0) {
      (this.model.controls['listKpiLeanFields'] as UntypedFormArray).value.forEach( controlValueItem  => {
       
        if ((controlValueItem.alert &&  controlValueItem.alert.trim() != '') || (controlValueItem.critical &&  controlValueItem.critical.trim() != '')) {
          let item: RelationKpiLeanFieldHealthUnitModel = new RelationKpiLeanFieldHealthUnitModel();
          item.alert = (controlValueItem.alert &&  controlValueItem.alert.trim() != '') ? controlValueItem.alert : null;
          item.critical = (controlValueItem.critical &&  controlValueItem.critical.trim() != '') ? controlValueItem.critical : null;
          item.idKpiLeanField = controlValueItem.idKpiLeanField;

          request.listRelationKpiLeanFieldHealthUnit.push(item);
        }       
        
      });
    }
    
    return request;
  }

  populateVelocimeterConfigValues(leanVelocimeterConfig: LeanVelocimeterConfigStruct){
    if (leanVelocimeterConfig){
      this.model.get('timeAverageMedicDoor').setValue(this.maskService.formatTimeToString(leanVelocimeterConfig.timeAverageMedicDoor));
      this.model.get('maxTimeMedicDoor').setValue(this.maskService.formatTimeToString(leanVelocimeterConfig.maxTimeMedicDoor));
      this.model.get('timeAverageMedicDecision').setValue(this.maskService.formatTimeToString(leanVelocimeterConfig.timeAverageMedicDecision));
      this.model.get('maxTimeMedicDecision').setValue(this.maskService.formatTimeToString(leanVelocimeterConfig.maxTimeMedicDecision));
      this.model.get('timeAverageDecisionExit').setValue(this.maskService.formatTimeToString(leanVelocimeterConfig.timeAverageDecisionExit));
      this.model.get('maxTimeDecisionExit').setValue(this.maskService.formatTimeToString(leanVelocimeterConfig.maxTimeDecisionExit));
    }    
  }

  populateKpiLeanFields(){
    this.isLoading = true;
    let index: number = 0;
    this.listKpiLeanFlowStruct?.forEach(itemFlow => {
      itemFlow?.listKpiLeanSectionStruct?.forEach(itemSection => {
        itemSection?.listKpiLeanFieldStruct?.forEach(itemField => {
          if (!itemField) 
            return;

          itemField.alert = itemField.alert?.replace(/\D/g, '');
          itemField.critical = itemField.critical?.replace(/\D/g, '');
          itemField.indexOnFormArray = index;
          (this.model.controls['listKpiLeanFields'] as UntypedFormArray).insert(index, this.createInput(itemField));

          index++;

        })
      })
    })

    this.isLoading = false;
    this.isFirstLoading = false;
  }

  createInput(kpiLeanFieldStruct: KpiLeanFieldStruct) {
    if (kpiLeanFieldStruct.idKpiLeanFieldType == this.timeKpiLeanFieldTypeEnum){
      let form: FormGroup =  this.formBuilder.group({
        idKpiLeanField: [kpiLeanFieldStruct.idKpiLeanField],
        alert: [kpiLeanFieldStruct.alert, this.verifyMinTime()],
        critical: [kpiLeanFieldStruct.critical, this.verifyMinTime()],
        idKpiLeanFieldType: [kpiLeanFieldStruct.idKpiLeanFieldType],
      },
      { 
        validators: [this.compareTime()] 
      });

      return form;  
    }
    else{
      let form: FormGroup =  this.formBuilder.group({
        idKpiLeanField: [kpiLeanFieldStruct.idKpiLeanField],
        alert: [kpiLeanFieldStruct.alert, this.verifyMinNumberValue()],
        critical: [kpiLeanFieldStruct.critical, this.verifyMinNumberValue()],
        idKpiLeanFieldType: [kpiLeanFieldStruct.idKpiLeanFieldType],
      },
      { 
        validators: [this.compareNumber()] 
      });

      return form;
    }
  }

  compareNumber() : ValidatorFn{
    return (formGroup: FormGroup): ValidationErrors => {
      let isValid: boolean = true;
      let error = { invalidComparison: true };
      let critical: AbstractControl = formGroup.get('critical');
      let alert: AbstractControl = formGroup.get('alert');
      

      let criticalNumber: number = (critical && critical.value && critical.value.match(/^\d+$/) != null) ? parseInt(critical.value) : null;
      let alertNumber: number = (alert && alert.value && alert.value.match(/^\d+$/) != null) ? parseInt(alert.value) : null;
      

      if (criticalNumber && alertNumber && alertNumber >= criticalNumber) {
        isValid = false; 
        formGroup.get('alert').setErrors({ alertEqualCritical: true });
      } 
      else {
        isValid = true;
        if (formGroup.get('alert').hasError('alertEqualCritical')){
          formGroup.get('alert').setErrors({ alertEqualCritical: null });
          formGroup.get('alert').updateValueAndValidity({emitEvent: false});
        }  
      }
      
      return !isValid ?  error : null
    };
  }

  compareTime() : ValidatorFn{
    return (formGroup: FormGroup): ValidationErrors => {
      let isValid: boolean = true;
      let error = { invalidComparison: true };
      let critical: AbstractControl = formGroup.get('critical');
      let alert: AbstractControl = formGroup.get('alert');

      if (critical && critical.value && critical.value.trim() != '' && alert && alert.value && alert.value.trim() != '') {
        let criticalTime: number = this.stringToSeconds(critical.value);
        let alertTime: number = this.stringToSeconds(alert.value);

        if (alertTime >= criticalTime && criticalTime > 0){
          isValid = false; 
          formGroup.get('alert').setErrors({ alertEqualCritical: true });
        }        
        else {
          isValid = true; 
          if (formGroup.get('alert').hasError('alertEqualCritical')){
            formGroup.get('alert').setErrors({ alertEqualCritical: null });
            formGroup.get('alert').updateValueAndValidity({emitEvent: false});
          }          
        }      
        
      }
      else {
        isValid = true;
        if (formGroup.get('alert').hasError('alertEqualCritical')){
          formGroup.get('alert').setErrors({ alertEqualCritical: null });
          formGroup.get('alert').updateValueAndValidity({emitEvent: false});
        }  
      }
      
      return !isValid ?  error : null
    };
  }

  verifyMinTime(){
    return (formControl: FormControl): ValidationErrors => {
      let isValid: boolean = true;
      let error = { minTime: true };

      if (formControl && formControl.value && formControl.value.trim() != '') {
        let time: number = this.stringToSeconds(formControl.value);

        if (time == 0)
          isValid = false;    
        else 
          isValid = true;   
      }
      else 
        isValid = true;
      
      return !isValid ?  error : null
    };
  }

  verifyMinNumberValue(){
    return (formControl: FormControl): ValidationErrors => {
      let isValid: boolean = true;
      let error = { minValue: true };

      if (formControl && formControl.value && formControl.value.trim() != "" && formControl.value.match(/^\d+$/) != null) {
        let value: number = parseInt(formControl.value);

        if (value == 0)
          isValid = false;    
        else 
          isValid = true;   
      }
      else 
        isValid = true;
      
      return !isValid ?  error : null
    };
  }

  stringToSeconds(timeString: string): number {
    if(timeString && timeString.trim() == '')
      return 0;

    let result: string = timeString.replace(/\D/g, '');
    if(result && result.trim() == '')
      return 0;

    let hours: number = parseInt(result.slice(0, -4)); 
    let minutes: number = parseInt(result.slice(-4, -2)); 
    let seconds: number = parseInt(result.slice(-2)); 
    let parts: number[] = [hours, minutes, seconds];

    return parts[0] * 3600 + parts[1] * 60 + parts[2];
  }

}