<div *ngIf="listData" class="table-width">
    <table class="basic-table desktop">
        <thead class="basic-table__head" *ngIf="variableName && variableName.length > 0">
            <tr>
                <th *ngFor="let item of variableName; let index = index;">{{item}}</th>
            </tr>
        </thead>
        <tbody class="basic-table__body">
            <tr class="basic-table__body__row" *ngFor="let item of listData; let index = index;">
                <td *ngFor="let cell of item; let j = index;" class="truncate">
                    <span>{{cell}}</span>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<app-empty-list *ngIf="listData && listData.length == 0"></app-empty-list>
