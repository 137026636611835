<div class="row display-contents">
    <div class="col-12 padding-box">
        <div class="row border-box">
            <div class="title-header">
                <h3>Planos</h3>
            </div>
            <div class="filters">
                <div class="row align-item-box">
                    <div class="col-12 col-md-4 col-lg-4 col-xl-4 search-correction">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Pesquisar</mat-label>
                            <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText"
                                (keyup)="onKeySearch($event)">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-3 col-lg-3 col-xl-2">
                        <button mat-flat-button color="primary" class="btn-block" (click)="openModal(null)">
                            <span>Novo plano</span>
                        </button>
                    </div>
                </div>
                <app-loading-progress-bar *ngIf="isLoading"></app-loading-progress-bar>
                <table class="col-12">
                    <thead>
                        <tr>
                            <th class="column-name">Nome</th>
                            <th class="column-action">Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let plan of listPlan">
                            <td>{{plan.planName}}</td>
                            <td class="ajust-buttons">
                                <a class="action-icon">
                                    <mat-icon aria-hidden="false" aria-label="Editar" (click)="openAlertModal(plan)">
                                        delete
                                    </mat-icon>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="row">
                    <div class="col-12">
                        <mat-paginator [length]="fullListSize" #paginator [pageIndex]="pageIndex" [pageSize]="pageSize"
                            aria-label="Select page" (page)="changePage($event)" [hidePageSize]="true">
                        </mat-paginator>
                    </div>
                </div>
            </div>
            <app-loading-list *ngIf="isLoading"></app-loading-list>
        </div>
    </div>
</div>