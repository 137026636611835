import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { MaxSizeValidator } from '@angular-material-components/file-input';
@Component({
  selector: 'app-health-unit-logo-modal',
  templateUrl: './health-unit-logo-modal.component.html',
  styleUrls: ['./health-unit-logo-modal.component.css']
})
export class HealthUnitLogoModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<HealthUnitLogoModalComponent>,
    public sanitizer: DomSanitizer,
    private formBuilder: UntypedFormBuilder) { }

  public oldSrc: any;
  public newSrc: any;
  readonly maxSize = 153600;

  public uploadedLogo: string;

  public haveLogo: boolean;

  public isLoading: boolean;
  public model: UntypedFormGroup;

  ngOnInit(): void {
    this.isLoading = true;

    this.model = this.formBuilder.group({
      requiredfile: ['', [Validators.required, MaxSizeValidator(this.maxSize)]]
    });
    if (this.data.logo) {
      this.oldSrc = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${this.data.logo}`);
      this.haveLogo = true;
    }
    if (this.data.newLogo) {
      this.newSrc = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${this.data.newLogo}`);
      this.haveLogo = true;
    }

    this.isLoading = false;
  }

  inputChange() {
    var reader = new FileReader();
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(this.model.get('requiredfile').value);
  }

  _handleReaderLoaded(e) {   
    let reader = e.target;
    var testSrc = reader.result;
    var stringArray = testSrc.split("base64,", 2);

    this.newSrc = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${stringArray[1]}`);
    this.uploadedLogo = stringArray[1];
    this.haveLogo = true;
  }

  clickApply() {
    this.matDialogRef.close(
      {
        newLogo: this.uploadedLogo,
        haveLogo: this.haveLogo
      });
  }
  
  clickCancel() {
    this.matDialogRef.close(
      {
        haveLogo: this.haveLogo,
      });
  }
}
