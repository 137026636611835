import { Injectable } from "@angular/core";
import { AlertService, AlertType } from "src/app/shared/services/alert.service";

@Injectable({
    providedIn: 'root'
})
export class UtilsHealthcareAgreements {
    constructor(private alertService: AlertService) { }

    formatCurrencyToString(event: any) {
        let input = event.target.value;

        input = input.replace(/[^\d,]/g, '');

        const decimalSeparator = (1.1).toLocaleString().substring(1, 2);

        input = input.replace(decimalSeparator, '');

        let value = parseInt(input, 10);

        const maxValue = 99999999;
        if (value > maxValue) {
            value = maxValue;

            this.alertService.show('Error', 'O valor máximo para essa operação é de R$ 999.999,99', AlertType.error);
        }

        event.target.value = (value / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 }).replace('R$', '');

        return event.target.value;
    }

    formatCurrencyBackToFront(value: number): string {
        return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 }).replace('R$', '');
    }

    formatCurrencyFrontToBack(valueFront: string): number {
        return parseFloat(valueFront.replace(/[^\d,]/g, '').replace(',', '.'));
    }
}    