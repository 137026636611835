import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PatientStruct } from 'src/app/shared/services/structs/medical-record/patient.struct';

@Component({
  selector: 'app-select-patient-modal',
  templateUrl: './select-patient-modal.component.html',
  styleUrls: ['./select-patient-modal.component.css']
})
export class SelectPatientModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<SelectPatientModalComponent>) { }

  public isLoading: boolean;

  ngOnInit(): void {
    this.isLoading = false;
  }

  close() {
    this.matDialogRef.close();
  }

  clickSelect(patient: PatientStruct) {
    this.matDialogRef.close(patient);
  }

  clickCancel() {
    this.matDialogRef.close();
  }
}