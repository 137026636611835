<mat-dialog-content>
    <div class="head-confirm-modal-list">
        <div class="img-head">
            <img style="width: 5rem;" src="assets/images/exclamation-circle.png" />
        </div>
        <div class="title-modal">
            <h1>Atenção!</h1>
        </div>
    </div>
    <div class="body-modal">
        <p>Os seguintes itens estão em falta no estoque:</p>
        <ul>
            <li *ngFor="let item of listItemFormatted">
                {{item.itemName ? 'Item' : 'Medicamento'}} solicitado: <b>{{item.itemName ? item.itemName : item.medicineName}}</b> | Qtde. solicitada: <b>{{item.quantityNeeded}}</b> | Em estoque: <b>{{item.quantityHaving}}</b>
            </li>
        </ul>
        <p><b>Deseja substituir a quantidade solicitada pelo total em estoque?</b></p>
    </div>
</mat-dialog-content>
<div class="row">
    <div class="col-md-6">
        <button [disabled]="buttonAccept" mat-flat-button color="primary" class="btn-block" (click)="clickYes()">
            <span *ngIf="isLoading == false">Utilizar o estoque disponível</span>
            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
        </button>
    </div>
    <div class="col-md-6">
        <button mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Voltar</button>
    </div>
</div>