<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <h1>Configurações médico</h1>
        <div class="white-body">
            <a mat-flat-button color="primary" class=" add-user" routerLink="/medic/medic-config/register"><mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon> Cadastrar configuração</a>
            <div class="filters">
                <div class="row">   
                    <div class="col-12 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Pesquisar</mat-label>
                            <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText" (keyup)="onKeySearch($event)">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-md-2"> 
                        <mat-form-field appearance="outline">                    
                            <mat-label>Setores</mat-label>
                            <mat-select [(ngModel)]="listIdSector" multiple (selectionChange)="search()">
                                <mat-option *ngFor="let item of listSector" value="{{item.idSector}}">{{item.sectorName}}</mat-option> 
                            </mat-select>
                        </mat-form-field>
                    </div>            
                    <div class="col-12 col-sm-6">
                        <div class="row">
                            <div class="medicine-list-checkbox col-12 col-sm-6 col-md-4">
                                <mat-slide-toggle [(ngModel)]="hasProcedure" (change)="toggleHasProcedure()">{{hasProcedure? "Desativar" : "Ativar"}} Solicitação Procedimentos</mat-slide-toggle>
                            </div>
                            <div class="medicine-list-checkbox col-12 col-sm-6  col-md-4">
                                <mat-slide-toggle [(ngModel)]="dischargeOutcome" (change)="toggleDischargeOutcome()" [disabled]="isLoading">Desfecho de alta após encaminhamento</mat-slide-toggle>
                            </div>
                            <div class="medicine-list-checkbox col-12 col-sm-6  col-md-4">
                                <mat-slide-toggle [(ngModel)]="memedPrescription" (change)="toggleMemedPrescription()" [disabled]="isLoading">{{memedPrescription? "Desativar" : "Ativar"}} Prescrições Memed</mat-slide-toggle>
                            </div>
                            <div class="medicine-list-checkbox col-12 col-sm-6  col-md-4">
                                <mat-slide-toggle [(ngModel)]="isDisableExternalRequest" (change)="toggleExternalRequest()" [disabled]="isLoading">Bloquear pedidos externos para pacientes não identificados</mat-slide-toggle>
                            </div>
                            <div class="medicine-list-checkbox col-12 col-sm-6  col-md-4">
                                <mat-slide-toggle [(ngModel)]="blockAllergicPrescription" (change)="toggleAllergicPrescription()" [disabled]="isLoading">Bloquear prescrições com alergia</mat-slide-toggle>
                            </div>
                        </div>         
                    </div>    
                </div>
            </div>
            <div class="card-list" *ngIf="listConfig != null">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listConfig">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary">{{item.medicConfigName}}</span>
                            </div>
                            <div class="actions">
                                <a><mat-icon aria-hidden="false" aria-label="Editar" [routerLink] = "['/medic/medic-config/register', {idMedicConfig: item.idMedicConfig}]">edit</mat-icon> </a>
                                <a><mat-icon aria-hidden="false" aria-label="Editar" (click)="openModal(item.idMedicConfig)">delete_forever</mat-icon> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <app-empty-list *ngIf="listConfig != undefined && listConfig.length == 0"></app-empty-list>
        </div>
        
        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>
    
</div>    
