<div *ngFor="let item of listGraphsOrder" class="background-block" [ngClass]="'block-size'+item.idGraphWidth">
    <div class="graph-header">
        <div class="title" [matTooltip]="item.graphName">{{item.graphName}}</div>
        <div class="actions">
            <a (click)="clickSettigns(item.idGraph)" class="graph-icons">
                <mat-icon aria-hidden="false" aria-label="Configurar">settings</mat-icon>
            </a>
            <!-- <a class="graph-icons">
                <mat-icon aria-hidden="false" aria-label="Tela cheia">
                    fullscreen</mat-icon>
            </a> -->
            <a class="graph-icons">
                <mat-icon aria-hidden="false" aria-label="Excluir" (click)="openExclude(item.idGraph)">delete_forever
                </mat-icon>
            </a>
        </div>
    </div>
    <div class="graph-block" *ngIf="item.idGraphType == graphTypeCounter">
        <app-plot-counter [value]="item.value" [label]="item.label"></app-plot-counter>
    </div>
    <div class="graph-block table-block" *ngIf="item.idGraphType == graphTypeTable">
        <app-plot-table [listData]="item.listData" [variableName]="item.listLabels">
        </app-plot-table>
    </div>
    <div class="graph-block" *ngIf="item.idGraphType == graphTypeColumns">
        <app-plot-column [listColumnGraphData]="item.listColumnGraphData" [variableName]="item.variableName"
            [idSize]="item.idGraphWidth">
        </app-plot-column>
    </div>
    <div class="graph-block" *ngIf="item.idGraphType == graphTypeLines">
        <app-plot-line [labelsDate]="item.labelsDate" [listLineGraphData]="item.listLineGraphData" [idSize]="item.idGraphWidth">
        </app-plot-line>
    </div>
    <div class="graph-block" *ngIf="item.idGraphType == graphTypePie">
        <app-plot-pie [listPieGraphData]="item.listPizzaGraphData" [variableName]="item.variableName"
            [idSize]="item.idGraphWidth">
        </app-plot-pie>
    </div>
    <div class="graph-footer">
        {{item.order}}
    </div>
</div>