import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { GetTimeZoneResponse } from '../../responses/user/get-time-zone.response';
import { ListTimeZoneResponse } from '../../responses/user/list-time-zone.response';

@Injectable({
  providedIn: 'root'
})
export class TimeZoneService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listTimeZone(): Observable<ListTimeZoneResponse> {

    let uri = 'TimeZone/getAll'

    return this.httpClient.get<ListTimeZoneResponse>(environment.urlApiUser + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getTimeZone(idTimeZone: number): Observable<GetTimeZoneResponse> {

    let uri = `TimeZone/idTimeZone/${idTimeZone}`;

    return this.httpClient.get<GetTimeZoneResponse>(environment.urlApiUser + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
