import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { FavoriteStandardMedicineService } from 'src/app/shared/services/API/medic/favorite-standard-medicine.service';
import { MedicFavoriteStandardPrescriptionService } from 'src/app/shared/services/API/medic/medic-favorite-standard-prescription.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { FavoriteStandardMedicine } from 'src/app/shared/services/models/medic/favorite-standard-medicine.model';
import { AddMedicineModalComponent } from './add-medicine-modal/add-medicine-modal.component';
import { LookupMedicalCareService } from 'src/app/shared/services/API/medical-record/lookup-medical-care.service';
import { LookupMedicalCareResponse } from 'src/app/shared/services/responses/medical-record/lookup-medical-care-response';
import { ListMedicineResponse } from 'src/app/shared/services/responses/pharmacy/list-medicine.response';
import { MedicineService } from 'src/app/shared/services/API/pharmacy/medicine.service';
import { MedicFavoriteStandardPrescriptionRequest } from 'src/app/shared/services/requests/medic/medic-favorite-standard-prescription-request';
import { FavoriteStandardPrescriptionStruct } from 'src/app/shared/services/structs/medic/favorite-standard-prescription.struct';
import { FavoriteMedicatedSolutionStruct } from 'src/app/shared/services/structs/medic/favorite-medicated-solution.struct';

@Component({
  selector: 'app-favorite-standard-medicine',
  templateUrl: './favorite-standard-medicine.component.html',
  styleUrls: ['./favorite-standard-medicine.component.css']
})
export class FavoriteStandardMedicineComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public medicineService: MedicineService,
    public lookupMedicalCareService: LookupMedicalCareService,
    private favoriteStandardMedicineService: FavoriteStandardMedicineService,
    private medicFavoriteStandardPrescriptionService: MedicFavoriteStandardPrescriptionService
  ) { }

  public model: FormGroup;
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.medic;

  public isLoading: boolean;
  public isUpdate: boolean = false;
  public idMedicFavoriteStandardPrescription: number;
  public idRoom: number;
  public idSector: number;

  public listFavoriteStandardMedicine: FavoriteStandardMedicine[] = [];
  public listFavoriteMedicatedSolutionStruct: FavoriteMedicatedSolutionStruct[] = [];
  public lookupMedicalCareResponse: LookupMedicalCareResponse;
  public listMedicine: ListMedicineResponse = new ListMedicineResponse();
  public viewToggleShareWithMedicalTeam: boolean = false;


  ngOnInit(): void {
    this.isLoading = true;

    this.getLookupMedicalCare();
    this.getLookupMedicine();

    this.model = this.formBuilder.group({
      name: ['', [Validators.required]],
      shareWithMedicalTeam: [false],
    });

    if (this.activatedRoute.snapshot.paramMap.get('idRoom'))
      this.idRoom = parseInt(this.activatedRoute.snapshot.paramMap.get('idRoom'));

    if (this.activatedRoute.snapshot.paramMap.get('idSector'))
      this.idSector = parseInt(this.activatedRoute.snapshot.paramMap.get('idSector'));

    if (this.activatedRoute.snapshot.paramMap.get('idMedicFavoriteStandardPrescription'))
      this.idMedicFavoriteStandardPrescription = parseInt(this.activatedRoute.snapshot.paramMap.get('idMedicFavoriteStandardPrescription'));

    if (this.idMedicFavoriteStandardPrescription != null)
      this.populateCardList();
    else
      this.isLoading = false;
  }

  submit() {
    if (this.isLoading)
      return;

    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      this.isLoading = false;
      return;
    }

    if ((!this.listFavoriteStandardMedicine || this.listFavoriteStandardMedicine.length == 0) 
      && (!this.listFavoriteMedicatedSolutionStruct || this.listFavoriteMedicatedSolutionStruct.length == 0)) {
      this.alertService.show('Erro', "Adicione ao menos um medicamento ou solução medicamentosa..", AlertType.error);
      this.isLoading = false;
      return;
    }

    this.isLoading = true;

    let request = new MedicFavoriteStandardPrescriptionRequest();
    request.listFavoriteStandardMedicine = this.listFavoriteStandardMedicine;
    request.listMedicatedSolutionPrescription = this.listFavoriteMedicatedSolutionStruct;
    request.favoriteMedicineName = this.model.get('name').value;
    request.shareWithMedicalTeam = this.model.get('shareWithMedicalTeam').value;

    if (this.isUpdate) {
      request.idMedicFavoriteStandardPrescription = this.idMedicFavoriteStandardPrescription;
      this.putFavoritePrescription(request);
    }
    else
      this.postFavoritePrescription(request);
  }

  postFavoritePrescription(request: MedicFavoriteStandardPrescriptionRequest) {
    this.medicFavoriteStandardPrescriptionService.postMedicFavoriteStandarPrescription(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;
        this.router.navigate(['/medic/medic-favorite-standard-prescription', { idSector: this.idSector, idRoom: this.idRoom }]);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
        return;
      }
    });
  }

  putFavoritePrescription(request: MedicFavoriteStandardPrescriptionRequest) {
    this.medicFavoriteStandardPrescriptionService.putMedicFavoriteStandarPrescription(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações atualizadas com sucesso", AlertType.success);
        this.isLoading = false;
        this.router.navigate(['/medic/medic-favorite-standard-prescription', { idSector: this.idSector, idRoom: this.idRoom }]);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
        return;
      }
    });
  }

  getLookupMedicalCare() {
    this.lookupMedicalCareService.getLookupMedicalCare().subscribe({
      next: (response) => {
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.lookupMedicalCareResponse = response;
        this.viewToggleShareWithMedicalTeam = this.lookupMedicalCareResponse.viewToggleShareWithMedicalTeam;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getLookupMedicine() {
    this.medicineService.listAllMedicine().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listMedicine = response;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateCardList() {
    this.favoriteStandardMedicineService.getFavoriteStandardMedicine(this.idMedicFavoriteStandardPrescription).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listFavoriteStandardMedicine = response.listFavoriteStandardMedicine;
        this.listFavoriteMedicatedSolutionStruct = response.listFavoriteMedicatedSolution;
        this.model.get('name').setValue(response.medicFavoriteStandardPrescription.favoritePrescriptionName);
        this.model.get('shareWithMedicalTeam').setValue(response.medicFavoriteStandardPrescription.shareWithMedicalTeam);
        this.isLoading = false;
        this.isUpdate = true;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openPrecriptionModalWithMedicine(index: number, medicalPrescription: FavoriteStandardMedicine, isUpdate: boolean) {
    let prescription = new FavoriteStandardPrescriptionStruct();
    prescription.favoriteStandardMedicine = medicalPrescription;
    this.openMedicineModal(index, prescription, isUpdate, false);
  }

  openPrecriptionModalWithMedicatedSolution(index: number, medicalPrescription: FavoriteMedicatedSolutionStruct, isUpdate: boolean) {
    let prescription = new FavoriteStandardPrescriptionStruct();
    prescription.favoriteMedicatedSolutionStruct = medicalPrescription;
    this.openMedicineModal(index, prescription, isUpdate, true);
  }

  openMedicineModal(index: number, prescription: FavoriteStandardPrescriptionStruct, isUpdate: boolean, isMedicatedSolution: boolean) {
    if (isUpdate)
      this.openUpdatePrescriptionModal(index, prescription, isMedicatedSolution);
    else
      this.openFavoriteStandardMedicine(prescription);
  }

  openUpdatePrescriptionModal(index: number, prescription: FavoriteStandardPrescriptionStruct, isMedicatedSolution: boolean) {
    const dialogRef = this.dialog.open(AddMedicineModalComponent, {
      data: {
        prescription,
        listMedicine: this.listMedicine,
        lookupMedicalCareResponse: this.lookupMedicalCareResponse,
        isMedicatedSolution: isMedicatedSolution,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.medicalPrescription && result.medicalPrescription.favoriteStandardMedicine) {
          if (index >= 0)
            this.listFavoriteStandardMedicine.splice(index, 1);

          this.listFavoriteStandardMedicine.push(result.medicalPrescription.favoriteStandardMedicine);
        }
        else if (result && result.medicalPrescription && result.medicalPrescription.favoriteMedicatedSolutionStruct) {
          if (index >= 0)
            this.listFavoriteMedicatedSolutionStruct.splice(index, 1);

          this.listFavoriteMedicatedSolutionStruct.push(result.medicalPrescription.favoriteMedicatedSolutionStruct);
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  openFavoriteStandardMedicine(prescription: FavoriteStandardPrescriptionStruct) {
    const dialogRef = this.dialog.open(AddMedicineModalComponent, {
      data: {
        prescription,
        lookupMedicalCareResponse: this.lookupMedicalCareResponse,
        listMedicine: this.listMedicine
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.medicalPrescription && result.medicalPrescription.favoriteStandardMedicine) 
          this.listFavoriteStandardMedicine.push(result.medicalPrescription.favoriteStandardMedicine);
        
        else if (result && result.medicalPrescription && result.medicalPrescription.favoriteMedicatedSolutionStruct) 
          this.listFavoriteMedicatedSolutionStruct.push(result.medicalPrescription.favoriteMedicatedSolutionStruct);
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  removeMedicine(index: number) {
    this.listFavoriteStandardMedicine.splice(index, 1);
  }

  deletePrescriptionMedicatedSolution(index: number) {
    this.listFavoriteMedicatedSolutionStruct.splice(index, 1);
  }
}