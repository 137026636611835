import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { SearchFlowchartRequest } from '../../requests/risk-classification/search-flowchart.request';
import { GetAuditResponse } from '../../responses/orchestrator-audit/get-audit.response';
import { GetAllAuditRequest } from '../../requests/audit/get-all-audit.request';
import { ListWithNameAuditsResponse } from '../../responses/orchestrator-audit/list-with-name-audits.response';

@Injectable({
  providedIn: 'root'
})
export class GetAuditService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getAllUserEvenDeletedAndTriageAudited(flowchartRequest: SearchFlowchartRequest): Observable<GetAuditResponse> {
    let uri = `Audit`;

    return this.httpClient.post<GetAuditResponse>(environment.urlApiOrchestratorAudit + uri, flowchartRequest, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public listAllAudit(request: GetAllAuditRequest): Observable<ListWithNameAuditsResponse> {
    let uri = `Audit/getAll`;

    return this.httpClient.post<ListWithNameAuditsResponse>(environment.urlApiOrchestratorAudit + uri, request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}