import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MedicalRecordService } from 'src/app/shared/services/API/orchestrator-patient/medical-record.service';
import { MedicineService } from 'src/app/shared/services/API/pharmacy/medicine.service';
import { ExamService } from 'src/app/shared/services/API/SADT/exam.service';
import { MedicalCareService } from 'src/app/shared/services/API/medical-record/medical-care.service';
import { LookupMedicalCareService } from 'src/app/shared/services/API/medical-record/lookup-medical-care.service';
import { FowardService } from 'src/app/shared/services/API/orchestrator-queue/foward.service';
import { environment } from 'src/environments/environment';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { MedicalCareModel } from 'src/app/shared/services/models/medical-record/medical-care.model';
import { BillingDataService } from 'src/app/shared/services/API/billing/billing-data.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { BillingDataRequest } from 'src/app/shared/services/requests/billing/billing-data-request';
import { ProcedureTriggersEnum } from 'src/app/shared/enum/procedure-triggers.enum';
import { ProcedureSigtapCBOService } from 'src/app/shared/services/API/billing/procedure-sigtap-cbo.service';
import { ProcedureSigtapModel } from 'src/app/shared/services/models/billing/procedure-sigtap.model';
import { BedQueueService } from 'src/app/shared/services/API/queue/bed-queue.service';
import { MedicConfigSectorService } from 'src/app/shared/services/API/medic/medic-config-sector.service';
import { ProcedureService } from 'src/app/shared/services/API/medic/procedure.service';
import { ProcedureModel } from 'src/app/shared/services/models/medic/procedure-model';
import { ForwardService } from 'src/app/shared/services/API/flow/forward.service';
import { FlowSequenceService } from 'src/app/shared/services/API/flow/flow-sequence.service';
import { OrganizationService } from 'src/app/shared/services/API/flow/organization.service';
import { GetCompleteMultiProfessionalConfigStruct } from 'src/app/shared/services/structs/multi-professional/get-complete-multi-professional-config.struct';

@Component({
  selector: 'app-procedures-performed-block',
  templateUrl: './procedures-performed-block.component.html',
  styleUrls: ['./procedures-performed-block.component.css']
})
export class ProceduresPerformedBlockComponent implements OnInit, AfterViewInit {

  @Input() model: UntypedFormGroup;
  @Input() completeMultiprofessionalConfig: GetCompleteMultiProfessionalConfigStruct;

  constructor(
    private alertService: AlertService,
    public procedureService: ProcedureService,
    public medicalRecordService: MedicalRecordService,
    public medicineService: MedicineService,
    public lookupMedicalCareService: LookupMedicalCareService,
    public examService: ExamService,
    public medicalCareService: MedicalCareService,
    public router: Router,
    public fowardService: FowardService,
    public forwardFlowService: ForwardService,
    public flowSequenceService: FlowSequenceService,
    public organizationService: OrganizationService,
    private maskService: MaskService,
    private billingDataService: BillingDataService,
    private procedureSigtapCBOService: ProcedureSigtapCBOService,
    public bedQueueService: BedQueueService,
    public medicConfigSectorService: MedicConfigSectorService,
    private authService: AuthService
  ) { }

  public isLoading: boolean = false;
  public isFirstLoading: boolean;
  public isIsolation: boolean;
  public selectedEpisode: number;
  public newMedicalCare: MedicalCareModel;
  public listCid: any[] = [];
  
  public masks: Masks;
  
  public listProcedureSigtap: any[] = [];
  public urlListProcedure: string;
  public codCbo: string;
  public idCompetence: number;
  public listPreselectedPRocedure: ProcedureSigtapModel[] = [];
  public listTriggersProcedure: ProcedureSigtapModel[] = [];
  public listProcedure: ProcedureModel[];
  public hasProcedure: boolean = false;

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.getListProcedures();

    this.codCbo = this.authService.getTokenMenu().codCbo;
    this.idCompetence = this.authService.getTokenMenu().healthUnit.idProcedureSigtapCompetence;

    if (this.codCbo && this.idCompetence) {
      let url = `ProcedureSigtapCBO/codCbo/${this.codCbo}/idCompetence/${this.idCompetence}`
      this.urlListProcedure = environment.urlApiBilling + url;
    }

  }

  ngAfterViewInit() {
    if (this.codCbo && this.idCompetence) {
      this.getPreselectedProcedures();
    }
  }

  addItemProcedure(event: any) {
    this.listProcedureSigtap = event;
    this.listPreselectedPRocedure = this.listProcedureSigtap;
  }

  postBillingData(billingDataRequest: BillingDataRequest) {
    if (this.codCbo) {
      this.billingDataService.postBillingData(billingDataRequest).subscribe((response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
      },
        (error) => {
          this.isLoading = false;
          this.alertService.show('Erro inesperado', error, AlertType.error);
        });
    }
  }


  mapToBillingDataRequest(idRelation): BillingDataRequest {
    let billingDataRequest: BillingDataRequest = new BillingDataRequest();
    billingDataRequest.listCid = this.listCid.map(x => x.code);
    billingDataRequest.idRelation = idRelation;
    billingDataRequest.idModule = MenuModuleEnum.medic;
    billingDataRequest.idEpisode = this.selectedEpisode;
    billingDataRequest.listIdProcedure = this.listProcedureSigtap.map(a => a.idProcedureSigtap);
    return billingDataRequest;
  }

  getListProcedures() {
    this.procedureService.listAllProcedure().subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }

      this.listProcedure = response.listProcedure;
      this.hasProcedure = response.hasProcedure;
    },
      (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  getPreselectedProcedures() {
    let idsTriggers: number[] = [];
    idsTriggers.push(ProcedureTriggersEnum.afericao_pressao_arterial.valueOf());
    idsTriggers.push(ProcedureTriggersEnum.glicemia_capilar.valueOf());
    idsTriggers.push(ProcedureTriggersEnum.afericao_temperatura.valueOf());
    idsTriggers.push(ProcedureTriggersEnum.atendimento_urgencia_observacao.valueOf());

    this.procedureSigtapCBOService.listProcedureSigtapCodProcedure(idsTriggers, this.idCompetence, this.codCbo).subscribe(response => {
      if (response.isError) {
        this.alertService.show("Erro", response.errorDescription, AlertType.error);
        return;
      }

      this.listTriggersProcedure = response.list;
      let trigger = this.listTriggersProcedure.find(c => c.idTriggersProcedureSigtap == ProcedureTriggersEnum.atendimento_urgencia_observacao.valueOf())
      if (trigger) {
        this.listPreselectedPRocedure = null;
        this.listPreselectedPRocedure = [trigger];
        this.listProcedureSigtap = this.listPreselectedPRocedure;
      } else {
        this.listPreselectedPRocedure = [];
      }
    })
  }

  onBlurPressure() {
    var bloodPressure = this.model.get("bloodPressure").value;
    if (/^([0-9]+\/[0-9]{2,})$/.test(bloodPressure.replace("_", ""))) {
      this.setPreselectTrigger(ProcedureTriggersEnum.afericao_pressao_arterial);
    } else {
      this.removePreselectTrigger(ProcedureTriggersEnum.afericao_pressao_arterial);
    }
  }


  onBlurTemperature() {
    var temperature = this.model.get("temperature").value;
    if (/^([0-9]{2,}(,|.)[0-9]+)$/.test(temperature)) {
      this.setPreselectTrigger(ProcedureTriggersEnum.afericao_temperatura)
    } else {
      this.removePreselectTrigger(ProcedureTriggersEnum.afericao_temperatura);
    }
  }

  onBlurGlucose() {
    var glucose = this.model.get("glucose").value;
    if (glucose.replace("_", "").length >= 1) {
      this.setPreselectTrigger(ProcedureTriggersEnum.glicemia_capilar);
    } else {
      this.removePreselectTrigger(ProcedureTriggersEnum.glicemia_capilar);
    }
  }


  setPreselectTrigger(idTrigger: ProcedureTriggersEnum) {
    let listPreselectedPRocedure = [...this.listPreselectedPRocedure];
    this.listPreselectedPRocedure = null;
    let trigger = this.listTriggersProcedure.find(c => c.idTriggersProcedureSigtap == idTrigger.valueOf())
    var indexOf = listPreselectedPRocedure.findIndex(c => c.idTriggersProcedureSigtap == idTrigger.valueOf());
    if (trigger && indexOf < 0) {
      listPreselectedPRocedure.push(trigger);

    }
    this.listPreselectedPRocedure = [...listPreselectedPRocedure]; 
    this.listProcedureSigtap = this.listPreselectedPRocedure;
  }

  removePreselectTrigger(idTrigger: ProcedureTriggersEnum) {
    let listPreselectedPRocedure = [...this.listPreselectedPRocedure];
    this.listPreselectedPRocedure = null;
    var indexOf = listPreselectedPRocedure.findIndex(c => c.idTriggersProcedureSigtap == idTrigger.valueOf());
    if (indexOf >= 0) {
      listPreselectedPRocedure.splice(indexOf, 1);
    }
    this.listPreselectedPRocedure = [...listPreselectedPRocedure];
    this.listProcedureSigtap = this.listPreselectedPRocedure;
  }

  getProcedureName(idProcedure: number): string {
    return this.listProcedure?.find(l => l.idProcedure == idProcedure)?.procedureName;
  }

}