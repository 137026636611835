import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { LocationByModuleService } from 'src/app/shared/services/API/flow/location-by-module.service';
import { AihConfigService } from 'src/app/shared/services/API/hospital-document/aih-config.service';
import { LookupService } from 'src/app/shared/services/API/hospital-document/lookup.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { AihConfigModel } from 'src/app/shared/services/models/hospital-document/aih_config.model';
import { LookupHospitalizationReportModel } from 'src/app/shared/services/models/hospital-document/lookup_hospitalization-report.model';
import { RelationAihConfigFieldRequiredModel } from 'src/app/shared/services/models/hospital-document/relation_aih_config_field_required.model';
import { AihConfigRequest } from 'src/app/shared/services/requests/hospital-document/aih-config.request';
import { SectorStruct } from 'src/app/shared/services/structs/flow/sector.struct';

@Component({
  selector: 'app-aih-fill-config-register',
  templateUrl: './aih-fill-config-register.component.html',
  styleUrls: ['./aih-fill-config-register.component.css']
})
export class AihFillConfigRegisterComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private locationByModuleService: LocationByModuleService,
    private alertService: AlertService,
    private aihConfigService: AihConfigService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private lookupService: LookupService) {
  }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.medic;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.aih_fill_config;
  public model: FormGroup;

  public listSector: SectorStruct[];
  public listLookupHospitalizationReport: LookupHospitalizationReportModel[];
  public aihConfig: AihConfigModel;
  public listRelationAihConfigFieldRequired: RelationAihConfigFieldRequiredModel[];

  public idAihConfig: number;

  public isUpdate: boolean = false;
  public isLoading: boolean = false;
  public isFirstLoading: boolean = false;

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.paramMap.get('idAihConfig'))
      this.idAihConfig = parseInt(this.activatedRoute.snapshot.paramMap.get('idAihConfig'));

    this.model = this.formBuilder.group({
      name: ['', [Validators.required]],
      idSector: [, [Validators.required]],
      listIdLookup: [,],
    });

    if (this.idAihConfig != null) {
      this.isFirstLoading = true;
      this.isUpdate = true;
    }

    this.getSectors();
  }

  submit() {
    if (this.isLoading)
      return;

    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    this.isLoading = true;

    let request: AihConfigRequest = new AihConfigRequest();

    request.name = this.model.get('name').value;
    request.sectorName = this.listSector.find(x => x.idSector == this.model.get('idSector').value).sectorName;
    request.idSector = this.model.get('idSector').value;
    if (this.model.get('listIdLookup').value)
      request.listIdLookup = this.model.get('listIdLookup').value;

    if (this.isUpdate)
      this.updateMedicConfig(request);
    else
      this.createMedicConfig(request);
  }

  getAihConfigById() {
    this.aihConfigService.getAihConfigById(this.idAihConfig).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription)
          return;
        }

        this.aihConfig = response.aihConfig;
        this.listLookupHospitalizationReport = response.listLookupHospitalizationReport;
        this.listRelationAihConfigFieldRequired = response.listRelationAihConfigFieldRequired;

        this.model.get('name').setValue(this.aihConfig.aihConfigName);
        this.model.get('idSector').setValue(this.aihConfig.idSector);
        this.model.get('listIdLookup').setValue(this.listRelationAihConfigFieldRequired.map(x => x.idLookupHospitalizationReport));
        this.isLoading = false;
        this.isFirstLoading = false;
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  getSectors() {
    let listId: number[] = [];
    listId.push(MenuModuleEnum.medic);

    let jsonList = JSON.stringify(listId);

    this.locationByModuleService.listSectorByModule(jsonList).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription)
          return;
        }

        this.listSector = response.listSectorStruct;

        if (this.isUpdate)
          this.getAihConfigById();
        else
          this.getLookup();
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  getLookup() {
    this.lookupService.getLookup().subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription)
          return;
        }

        this.listLookupHospitalizationReport = response.listLookupHospitalizationReport;

        this.isLoading = false;
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  updateMedicConfig(request: AihConfigRequest) {
    this.aihConfigService.updateAihConfig(this.idAihConfig, request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription)
          return;
        }

        this.alertSave();
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  createMedicConfig(request: AihConfigRequest) {
    this.aihConfigService.createAihConfig(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription)
          return;
        }

        this.alertSave();
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  throwException(error: any) {
    console.log(error);
    this.isLoading = false;
    this.alertService.show('Erro inesperado', error, AlertType.error);
  }

  alertSave() {
    this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
    this.isLoading = false;
    this.router.navigate(['/medic/aih-fill-config']);
  }
}