<div mat-dialog-content>
    <div class="img-head">
        <img src="assets/images/exclamation-circle.png" />
    </div>
    <h3><b>Algo deu errado!</b></h3>
    <h3>Certifique-se que seu CPF está correto e que você foi o último paciente chamado no painel.</h3>
    <div class="row">
        <div class="col-6">
            <button mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Cancelar</button>
        </div>
        <div class="col-6">
            <button mat-flat-button (click)="clickConfirm()" color="primary" class="btn-block">
                <span>Tentar Novamente</span>
            </button>
        </div>
    </div>
</div>