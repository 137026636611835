import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { DisplayLayoutRightTemplateComponent } from './display-layout-right-template.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [DisplayLayoutRightTemplateComponent],
  exports: [DisplayLayoutRightTemplateComponent],
  imports: [
    CommonModule,
    MatIconModule,
    SharedModule,
    MatDialogModule
  ]
})

export class DisplayLayoutRightTemplateModule { }