export class LeanVelocimeterConfigStruct{
    
public timeAverageMedicDoor: string; 

public maxTimeMedicDoor: string; 

public timeAverageMedicDecision: string;

public maxTimeMedicDecision: string; 

public timeAverageDecisionExit: string; 

public maxTimeDecisionExit: string; 

}