export enum MedicRequiredFieldEnum {
    allergies = 1,
    medicalNotes = 2,
    physicalExamination = 3,
    cid = 4,
    therapeuticPlan = 5,
    diagnosedHypothesis = 6,
    temperature = 7,
    respiratoryFrequency = 8,
    heartRate = 9,
    glucose = 10,
    bloodPressure = 11,
    satO2 = 12,
    conclusion = 13,
    prescriptionQuantity = 14,
    prescriptionAdministrationType = 15,
    prescriptionDilution = 16,
    prescriptionPresentation = 17,
    prescriptionObservation = 18,
    prescriptionMeasurementUnit = 19,
}