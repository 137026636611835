import { Input, Component, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MedicalRecordService } from 'src/app/shared/services/API/orchestrator-patient/medical-record.service';
import { CompleteMedicalCareStruct } from 'src/app/shared/services/structs/orchestrator-patient/complete-medical-care.struct';
import { MedicineService } from 'src/app/shared/services/API/pharmacy/medicine.service';
import { LookupMedicalCareService } from 'src/app/shared/services/API/medical-record/lookup-medical-care.service';
import { environment } from 'src/environments/environment';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { AllergyModel } from 'src/app/shared/services/models/medical-record/allergy.model';
import { NewAttendanceBlockStruct } from 'src/app/shared/structs/multi-professional/new-attendance-block.struct';
import { GetCompleteMultiProfessionalConfigStruct } from 'src/app/shared/services/structs/multi-professional/get-complete-multi-professional-config.struct';
import { VisibleFieldEnum } from 'src/app/shared/enum/multi-professional/visible-field.enum';
import { ProcedureTriggersEnum } from 'src/app/shared/enum/procedure-triggers.enum';
import { GetCareLinePriorityService } from 'src/app/shared/services/API/care-line-priority/get-care-line-priority.service';
import { CareLinePriority } from 'src/app/shared/services/models/care-line-priority/care-line-priority.model';
import { EpisodeStruct } from 'src/app/shared/services/structs/orchestrator-patient/episode.struct';

@Component({
  selector: 'app-new-attendance-block',
  templateUrl: './new-attendance-block.component.html',
  styleUrls: ['./new-attendance-block.component.css']
})
export class NewAttendanceBlockComponent implements OnInit {

  @Input() model: FormGroup;
  @Input() completeMultiprofessionalConfig: GetCompleteMultiProfessionalConfigStruct;
  @Input() allergiesInput: AllergyModel[];
  @Input() allergyError: boolean = false;
  @Input() episodeStruct: EpisodeStruct;

  @Output() changeAllergies = new EventEmitter<any>();
  @Input() listCid: any[];
  @Output() changeCid = new EventEmitter<any>();
  @Output() setPreselectTrigger = new EventEmitter<any>();
  @Output() removePreselectTrigger = new EventEmitter<any>();

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    public medicalRecordService: MedicalRecordService,
    public medicineService: MedicineService,
    public lookupMedicalCareService: LookupMedicalCareService,
    public router: Router,
    private maskService: MaskService,
    private getCareLinePriorityService: GetCareLinePriorityService,
  ) { }

  public masks: Masks;

  public urlListCid: string = environment.urlApiMedicalRecord + 'cid';
  public urlListAllergies: string = environment.urlApiMedicalRecord + "Allergy";

  public hasCid: boolean = false;
  public wasSaved: boolean = false;
  public isLoading: boolean = false;
  public hasGlucose: boolean = false;
  public hasAlergies: boolean = false;
  public hasEvolution: boolean = false;
  public hasHeartRate: boolean = false;
  public hasSaturation: boolean = false;
  public isFirstLoading: boolean = true;
  public hasTemperature: boolean = false;
  public hasMedicalNotes: boolean = false;
  public hasBloodPressure: boolean = false;
  public hasTherapeuticPlan: boolean = false;
  public hasPriorityLineOfCare: boolean = false;
  public hasDiagnosedHypothesis: boolean = false;
  public hasPhysicalExamination: boolean = false;
  public hasRespiratoryFrequency: boolean = false;

  public newAttendanceData: NewAttendanceBlockStruct = new NewAttendanceBlockStruct();
  public allergies: AllergyModel[] = [];
  public allergiesToSave: AllergyModel[] = [];
  public listCareLinePriority: CareLinePriority[] = [];

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.populateCareLinePrioritySelect();
    this.verifyVisibleField();
    this.verifyRequiredFields();
    if (this.episodeStruct.idCareLinePriority && this.episodeStruct.idCareLinePriority != null) {
      this.model.get('idCareLinePriority').setValue(this.episodeStruct.idCareLinePriority);
      this.model.get('idCareLinePriority').disable();
    }
  }

  verifyVisibleField() {
    this.hasAlergies = this.completeMultiprofessionalConfig.listIdVisibleField.includes(VisibleFieldEnum.AtendimentoAlergias);
    this.hasEvolution = this.completeMultiprofessionalConfig.listIdVisibleField.includes(VisibleFieldEnum.EvolucaoPaciente);
    this.hasMedicalNotes = this.completeMultiprofessionalConfig.listIdVisibleField.includes(VisibleFieldEnum.AtendimentoAnotaçõesMédicas);
    this.hasPhysicalExamination = this.completeMultiprofessionalConfig.listIdVisibleField.includes(VisibleFieldEnum.AtendimentoExameFísica);
    this.hasCid = this.completeMultiprofessionalConfig.listIdVisibleField.includes(VisibleFieldEnum.AtendimentoCID);
    this.hasTherapeuticPlan = this.completeMultiprofessionalConfig.listIdVisibleField.includes(VisibleFieldEnum.AtendimentoPlanoTerapêutico);
    this.hasDiagnosedHypothesis = this.completeMultiprofessionalConfig.listIdVisibleField.includes(VisibleFieldEnum.AtendimentoHipóteseDiagnóstica);
    this.hasTemperature = this.completeMultiprofessionalConfig.listIdVisibleField.includes(VisibleFieldEnum.AtendimentoTemperatura);
    this.hasRespiratoryFrequency = this.completeMultiprofessionalConfig.listIdVisibleField.includes(VisibleFieldEnum.AtendimentoFR);
    this.hasHeartRate = this.completeMultiprofessionalConfig.listIdVisibleField.includes(VisibleFieldEnum.AtendimentoFC);
    this.hasGlucose = this.completeMultiprofessionalConfig.listIdVisibleField.includes(VisibleFieldEnum.AtendimentoGC);
    this.hasBloodPressure = this.completeMultiprofessionalConfig.listIdVisibleField.includes(VisibleFieldEnum.AtendimentoPA);
    this.hasSaturation = this.completeMultiprofessionalConfig.listIdVisibleField.includes(VisibleFieldEnum.AtendimentoSatO2);
    this.hasPriorityLineOfCare = this.completeMultiprofessionalConfig.listIdVisibleField.includes(VisibleFieldEnum.AtendimentoLinhaDeCuidadoEmPrioridade);
  }

  addItemCid(event: any) {
    this.listCid = event;
    this.changeCid.emit(this.listCid);
  }

  populateLastMedicalCare(medicalCare: CompleteMedicalCareStruct) {
    this.model.get("medicalNotes").setValue(medicalCare.medicalCare.medicalNotes);
    this.model.get("physicalExamination").setValue(medicalCare.medicalCare.physicalExamination);
    this.model.get("therapeuticPlan").setValue(medicalCare.medicalCare.therapeuticPlan);
    this.model.get("diagnosedHypothesis").setValue(medicalCare.medicalCare.diagnosedHypothesis);
    this.model.get("temperature").setValue(medicalCare.medicalCare.temperature);
    this.model.get("respiratoryFrequency").setValue(medicalCare.medicalCare.respiratoryFrequency);
    this.model.get("heartRate").setValue(medicalCare.medicalCare.heartRate);
    this.model.get("glucose").setValue(medicalCare.medicalCare.glucose);
    this.model.get("bloodPressure").setValue(medicalCare.medicalCare.bloodPressureSystole + "/" + medicalCare.medicalCare.bloodPressureDiastole);
    this.model.get("saturation").setValue(medicalCare.medicalCare.saturation);
  }

  onBlurPressure() {
    var bloodPressure = this.model.get("bloodPressure").value;
    if (/^([0-9]+\/[0-9]{2,})$/.test(bloodPressure.replace("_", "")))
      this.setPreselectTrigger.emit(ProcedureTriggersEnum.afericao_pressao_arterial);
    else
      this.removePreselectTrigger.emit(ProcedureTriggersEnum.afericao_pressao_arterial);
  }

  onBlurTemperature() {
    var temperature = this.model.get("temperature").value;
    if (/^([0-9]{2,}(,|.)[0-9]+)$/.test(temperature))
      this.setPreselectTrigger.emit(ProcedureTriggersEnum.afericao_temperatura)
    else
      this.removePreselectTrigger.emit(ProcedureTriggersEnum.afericao_temperatura);

  }

  onBlurGlucose() {
    var glucose = this.model.get("glucose").value;
    if (glucose.replace("_", "").length >= 1)
      this.setPreselectTrigger.emit(ProcedureTriggersEnum.glicemia_capilar);
    else
      this.removePreselectTrigger.emit(ProcedureTriggersEnum.glicemia_capilar);
  }

  addItemAllergies(event: any) {
    this.changeAllergies.emit(event);
  }

  updateAllergiesToSave(allergies: AllergyModel[]) {
    this.allergiesToSave = allergies;
    this.addItemAllergies(this.allergiesToSave);
  }

  verifyRequiredFields() {
    if (this.completeMultiprofessionalConfig) {
      this.completeMultiprofessionalConfig.listIdFieldRequired.forEach(x => {

        //sinais vitais
        if (x == VisibleFieldEnum.AtendimentoTemperatura) {
          this.model.get('temperature').setValidators([Validators.required]);
          this.model.get('temperature').updateValueAndValidity();
        }
        else if (x == VisibleFieldEnum.AtendimentoFR) {
          this.model.get('respiratoryFrequency').setValidators([Validators.required]);
          this.model.get('respiratoryFrequency').updateValueAndValidity();
        }
        else if (x == VisibleFieldEnum.AtendimentoFC) {
          this.model.get('heartRate').setValidators([Validators.required]);
          this.model.get('heartRate').updateValueAndValidity();
        }
        else if (x == VisibleFieldEnum.AtendimentoGC) {
          this.model.get('glucose').setValidators([Validators.required]);
          this.model.get('glucose').updateValueAndValidity();
        }
        else if (x == VisibleFieldEnum.AtendimentoSatO2) {
          this.model.get('saturation').setValidators([Validators.required]);
          this.model.get('saturation').updateValueAndValidity();
        }
        else if (x == VisibleFieldEnum.AtendimentoPA) {
          this.model.get('bloodPressure').setValidators([Validators.required]);
          this.model.get('bloodPressure').updateValueAndValidity();
        }

        //outros campos
        else if (x == VisibleFieldEnum.AtendimentoAnotaçõesMédicas) {
          this.model.get('medicalNotes').setValidators([Validators.required]);
          this.model.get('medicalNotes').updateValueAndValidity();
        }
        else if (x == VisibleFieldEnum.AtendimentoExameFísica) {
          this.model.get('physicalExamination').setValidators([Validators.required]);
          this.model.get('physicalExamination').updateValueAndValidity();
        }
        else if (x == VisibleFieldEnum.AtendimentoCID) {
          this.model.get('firstChildGroup').get('cid').setValidators([Validators.required]);
          this.model.get('firstChildGroup').get('cid').updateValueAndValidity();
        }
        else if (x == VisibleFieldEnum.AtendimentoAlergias) {
          this.model.get('firstChildGroup').get('listIdAllergies').setValidators([Validators.required]);
          this.model.get('firstChildGroup').get('listIdAllergies').updateValueAndValidity();
        }
        else if (x == VisibleFieldEnum.AtendimentoHipóteseDiagnóstica) {
          this.model.get('diagnosedHypothesis').setValidators([Validators.required]);
          this.model.get('diagnosedHypothesis').updateValueAndValidity();
        }
        else if (x == VisibleFieldEnum.AtendimentoPlanoTerapêutico) {
          this.model.get('therapeuticPlan').setValidators([Validators.required]);
          this.model.get('therapeuticPlan').updateValueAndValidity();
        }
        else if (x == VisibleFieldEnum.EvolucaoPaciente) {
          this.model.get('evolution').setValidators([Validators.required]);
          this.model.get('evolution').updateValueAndValidity();
        }
      });
    }
  }

  createInput() {
    return this.formBuilder.group({
      idFlow: [null],
    });
  }

  populateCareLinePrioritySelect() {
    this.getCareLinePriorityService.getAllByIdHealthUnit().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listCareLinePriority = response.listCareLinePriority;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}