export class CareLineModel {
    public idCareLine: number;

    public careLineName: string;

    public isActive: boolean = false;

    public idHealthUnitTwilioAccount: number;

    public twilioFlowSid: string;

    public twilioFlowName: string;

    public dateTimeInclusion: Date;
}