export class GraphStruct {
    constructor(name: string, value: number) {
        this.name = name;
        this.value = value;
    }
    public name: string;
    public value: number;
}

export class GroupedBarGraphStruct {
    constructor(name: string, series: GraphStruct[]) {
        this.name = name;
        this.series = series;
    }
    public name: string;
    public series: GraphStruct[];
}