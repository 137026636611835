import { BlockedDaysStruct } from "../../structs/schedule/blocked-days.struct";
import { ScheduleDayStruct } from "../../structs/schedule/schedule-day.struct";

export class ScheduleRequest {
    
    public idUser: number;
    
    public description: string;
    
    public appointmentDuration: number;
    
    public datetimeEnd: Date;
    public datetimeStart: Date;
    
    public listScheduleDay: ScheduleDayStruct[] = [];
    public listBlockedDays: BlockedDaysStruct[] = [];
}