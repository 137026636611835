import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { UserService } from '../../../../shared/services/API/user/user.service';
import { AlertModalComponent } from 'src/app/shared/components/alert-modal/alert-modal.component';

@Component({
  selector: 'app-user-delete-modal',
  templateUrl: './user-delete-modal.component.html',
  styleUrls: ['./user-delete-modal.component.css']
})
export class UserDeleteModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<UserDeleteModalComponent>,
    private userService: UserService,
    private alertService: AlertService) { }

  public isLoading: boolean;

  ngOnInit(): void {
    this.isLoading = false;
  }

  close() {
    this.matDialogRef.close({ deleteUser: false });
  }

  clickDelete() {
    this.isLoading = true;
    this.userService.deleteUser(this.data.idUser, this.data.ip).subscribe({
      next: (response) => {

        if (response.isError && response.errorCode == 1) {
          const dialogRef = this.dialog.open(AlertModalComponent, {
            data: {
              title: "Atenção",
              description: response.errorDescription,
              hasImage: true
            },
          });

          dialogRef.afterClosed().subscribe(result => {
            this.isLoading = false;
            this.matDialogRef.close({ deleteUser: false });
          });
          return;
        }

        else if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Usuário excluido com sucesso!", AlertType.success);
        this.matDialogRef.close({ deleteUser: true });
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  clickCancel() {
    this.matDialogRef.close({ deleteUser: false });
  }
}