import { Socket } from 'socket.io-client';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertService, AlertType } from '../alert.service';
import { Router } from '@angular/router';
import { WebsocketAihService } from '../websocket-aih.service';

@Injectable({ providedIn: 'root' })

export class WebsocketAihUtilService {

    constructor(private websocketAihService: WebsocketAihService,
        public dialog: MatDialog,
        private alertService: AlertService,
        private router: Router) { }
    socket: Socket;
    idAihGroup: number = null;
    login: string = null;
    userName: string = null;
    seeOccupiedRoom: boolean;
    onClientAih: Function;
    thisParam: any;
    onSomeoneIsConnect: Function;

    getIdRoom(): number {
        return this.idAihGroup;
    }

    returnSocket() {
        return this.socket;
    }

    isConnect(): boolean {
        return this.socket && this.socket.connected;
    }

    clearSocket(): void {
        if (this.socket && this.socket.connected)
            this.socket.disconnect();

        this.socket = null;
        this.seeOccupiedRoom = null;
        this.clearVariables();
    }

    setFunctions(onSomeoneIsConnect: Function) {
        this.onSomeoneIsConnect = onSomeoneIsConnect ? onSomeoneIsConnect : this.blankFunction;
    }

    blankFunction() {

    }

    connectWebsocketRoom(thisParam: any, idAihGroup: number, login: string, userName: string): void {
        this.login = login;
        this.idAihGroup = idAihGroup;
        this.userName = userName;

        this.socket = this.websocketAihService.websocketConnection();
        this.socket.emit('join', idAihGroup)
            .on('clientAih', (res) => {
                this.onClientAih(res, thisParam)
            })
            .on("someoneIsConnecting", (res) => {
                if (res.login != this.login && this.idAihGroup === res.idAihGroup)
                    this.socket.emit('someoneIsConnect', { idAihGroup: this.idAihGroup, login: this.login, userName: this.userName})
            })
            .on("someoneIsConnect", (res) => {
                if (res.login != this.login && this.idAihGroup === res.idAihGroup) {
                    this.login = null;
                    this.idAihGroup = null;
                    this.alertService.show('Atenção!', `${res.userName} está editando esta AIH no momento.`, AlertType.warning);
                    this.onSomeoneIsConnect.apply(thisParam);
                }
            })
            .io.on("reconnect", (res) => {
                this.socket.emit('join', idAihGroup);
            });
    }

    setVariables(idAihGroup: number, login: string, userName: string) {
        this.login = login;
        this.idAihGroup = idAihGroup;
        this.userName = userName;

        this.socket.emit('someoneIsConnecting', { idAihGroup, login, userName });
    }

    clearVariables() {
        this.idAihGroup = null;
        this.login = null;
        this.userName = null;
    }
}