export class FavoriteStandardMedicine {
    public idFavoriteStandardMedicine: number;
    public idMedicFavoriteStandardPrescription: number;
    public administrationTypeName: string;
    public dilution: string;
    public dilutionName: string;
    public frequencyName: string;
    public idAdministrationType: number;
    public idDilution: number;
    public idFrequency: number;
    public idMeasurementUnit: number;
    public idMedicine: number;
    public idPresentation: number;
    public idTreatmentPeriod: number;
    public idUsage: number;
    public medicineName: string;
    public presentation: string;
    public presentationName: string;
    public quantity: number;
    public administrationType: string;
    public usage: string;
    public treatmentPeriodName: string;
    public idSubFrequency: number;
    public subFrequencyName: string;
    public idDilutionUnit: number;
    public dilutionQuantity: string;
    public dilutionUnitName: string;
    public idFavoriteMedicatedSolution: number;
}