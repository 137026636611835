export class AihGroupModel {
    public idAihGroup: number;
    public idHealthUnit: number;
    public datetimeInclusion: Date;
    public idEpisode: number;
    public idAihStatus: number;
    public isDeleted: boolean;
    public deletedMotive: string;
    public idUserCreator: number;
    public idMedicalCare: number;
    public idSector: number;
    public patientName: string;
    public userCreatorName: string;
}
