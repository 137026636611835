<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <a class="back-link" routerLink="/pharmacy/location">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Estoque
        </a>
        <h1>Cadastrar Estoque</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Tipo de produto</mat-label>
                            <mat-select formControlName="productType" (ngModelChange)="onChangeType($event)"
                                [disabled]="isUsedStorage">
                                <mat-option ngValue="null">Selecione</mat-option>
                                <mat-option value="1">Medicamento</mat-option>
                                <mat-option value="2">Item</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="productType==2" class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Item</mat-label>
                            <mat-select formControlName="idItem" [disabled]="isUsedStorage">
                                <mat-option ngValue="null">Selecione</mat-option>
                                <mat-option *ngFor="let item of listItem" [value]="item.idItem">
                                    {{item.itemName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="productType==1" class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Medicamento</mat-label>
                            <mat-select formControlName="idMedicine" [disabled]="isUsedStorage"
                                (click)="verifyListMedicine($event)" (selectionChange)="searchMedicine()">
                                <input class="inputSearchSelect" type="text" (keyup)="onKey($event.target.value)"
                                    placeholder="Pesquisar" autocomplete="off">
                                <mat-option *ngFor="let item of selectedMedicineList"
                                    [value]="item.medicine.idMedicine">
                                    {{item.medicine.medicineDescription}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="productType==1" class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Apresentação</mat-label>
                            <mat-select formControlName="idMedicinePresentationType"
                                (selectionChange)="onChangeSelect($event.target.value)">
                                <mat-option *ngFor="let item of listPresentationType"
                                    [value]="item.idMedicinePresentationType">{{item.medicinePresentationTypeName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Quantidade</mat-label>
                            <input matInput type="number" formControlName="amountTotal">
                        </mat-form-field>
                    </div>
                    <div *ngIf="isBottle" class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Número total de gotas</mat-label>
                            <input matInput type="number" formControlName="numberDrops">
                            <mat-error *ngIf="model.get('numberDrops').invalid">Número de gotas não pode ser inferior a quantidade</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2" *ngIf="isUsedStorage">
                        <mat-form-field appearance="outline">
                            <mat-label>Quantidade Utilizada</mat-label>
                            <input matInput type="number" formControlName="amountUsed">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Lote</mat-label>
                            <input matInput type="text" formControlName="batch">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Data de validade</mat-label>
                            <input matInput [mask]="masks.date" type="text" formControlName="dateExpiration">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Localização</mat-label>
                            <mat-select formControlName="idLocation" [disabled]="isUsedStorage">
                                <mat-option ngValue="null">Selecione</mat-option>
                                <mat-option *ngFor="let item of listLocation" [value]="item.idLocation">
                                    {{item.locationName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="title-header">
                    <h1>Etiqueta de estoque</h1>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-4 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Dimensão</mat-label>
                            <mat-select formControlName="idMedicineTag"
                                (selectionChange)="changeDimensionsSelect($event)">
                                <mat-option [value]="dimensions.idMedicineTag"
                                    *ngFor="let dimensions of listMedicineTag">
                                    {{dimensions.medicineTagName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-4 col-md-2" *ngIf="isOtherDimensionsSelected">
                        <mat-form-field appearance="outline">
                            <mat-label>Largura</mat-label>
                            <input matInput type="number" formControlName="width">
                            <span matSuffix>mm</span>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-4 col-md-2" *ngIf="isOtherDimensionsSelected">
                        <mat-form-field appearance="outline">
                            <mat-label>Altura</mat-label>
                            <input matInput type="number" formControlName="height">
                            <span matSuffix>mm</span>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-4 col-md-2" *ngIf="isOtherDimensionsSelected">
                        <button mat-flat-button type="button" color="primary" class=" visualize-btn"
                            (click)="visualizeTemplate()">
                            <span *ngIf="isVisualizeLoading == false">Visualizar Etiqueta</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isVisualizeLoading == true"></mat-spinner>
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-2">
                        <button mat-flat-button color="primary" class=" btn-block" type="submit">
                            <span *ngIf="!isLoading">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <a mat-flat-button type="button" routerLink="/pharmacy/storage" color="accent"
                            class=" btn-block">
                            Cancelar
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>

</div>