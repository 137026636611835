import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CareLineMonitoringRoutingModule } from './care-line-monitoring-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { NgxMaskModule } from 'ngx-mask';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { SharedModule } from '../shared/shared.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgChartsModule } from 'ng2-charts';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { CareLineMonitoringComponent } from './pages/care-line-monitoring/care-line-monitoring.component';
import { CareLineRegisterComponent } from './pages/care-line-register/care-line-register.component';


@NgModule({
    declarations: [
        CareLineMonitoringComponent,
        CareLineRegisterComponent,
    ],
    imports: [
        CommonModule,
        CareLineMonitoringRoutingModule,
        SharedModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatButtonModule,
        MatCheckboxModule,
        MatTreeModule,
        MatRadioModule,
        MatTooltipModule,
        MatToolbarModule,
        NgxMatFileInputModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMaskModule.forRoot(),
        MatSlideToggleModule,
        CdkAccordionModule,
        MatDatepickerModule,
        MatPaginatorModule,
        NgChartsModule,
        MatDialogModule
    ]
})
export class CareLineMonitoringModule { }
