import { ReturnStruct } from "../../../structs/return.struct";
import { NpsCommentCollectionStruct } from "../../structs/nps/nps-comment-collection.struct";

export class GetNpsByHealthUnitResponse extends ReturnStruct {
    public praiser: number;
    public praiserByPercent: number;
    public neutral: number;
    public neutralByPercent: number;
    public detractor: number;
    public detractorByPercent: number;
    public totalAnswer: number;
    public totalShooting: number;
    public nps: number;
    public notes: number[];
    public listCommentCollection: NpsCommentCollectionStruct[];
}