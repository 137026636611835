import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';

@Injectable({
    providedIn: 'root'
  })
  export class HealthUnitDisableExternalRequestService extends CommonService{
  
    constructor(private router: Router, private httpClient: HttpClient) {
      super();
    }
  
    public postDisableExternalRequestConfig(): Observable<ReturnStruct> {
      
      let uri = 'HealthUnitDisableExternalRequest'
  
      return this.httpClient.post<ReturnStruct>(environment.urlApiMedic + uri, null, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
    }

    // public getDisableExternalRequestConfig(): Observable<HealthUnitExternalRequestResponse> {
    //     let uri = 'HealthUnitDisableExternalRequest'
  
    //   return this.httpClient.get<HealthUnitExternalRequestResponse>(environment.urlApiMedic + uri, this.addHeaderToken())
    //   .pipe(
    //     catchError(this.handleError)
    //   )
    // }
  }