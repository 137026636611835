<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="title-header">
            <h1>Procedimento derivado</h1>
        </div>
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="row padding-field">                
                <div class="col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Procedimento</mat-label>
                        <mat-select formControlName="idProcedureChild">
                            <mat-option [value]="null">Nenhum</mat-option>
                            <mat-option *ngFor="let item of listProcedure" [value]="item.idProcedure">{{item.nameProcedure}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Quantidade</mat-label>
                        <input matInput type="number" formControlName="quantity">
                        <mat-error *ngIf="model.get('quantity').invalid">
                            Informe o quantidade
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <a mat-flat-button type="button" (click)="clickCancel()" color="accent" class="btn-block">
                        Cancelar
                    </a>
                </div>
                <div class="col-12">
                    <button mat-flat-button color="primary" class="btn-block">
                        <span *ngIf="isLoading == false">Salvar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn"
                            *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>                
            </div>
        </form>
    </div>
</div>