<app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
<div class="white-body">
    <h1 class="border-green-left-title">Pré Cadastro - Paciente</h1>
    <form class="form" [formGroup]="model" (ngSubmit)="submit()">
        <div class="title-header">
            <div class="row">
                <div class="col-6 col-sm-6 col-md-6">
                    <h1>Dados Pessoais</h1>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-6 col-md-4">
                <mat-form-field appearance="outline">
                    <mat-label>Nome</mat-label>
                    <input matInput type="text" formControlName="patientName">
                    <mat-error *ngIf="model.get('patientName').invalid">Informe o nome do paciente</mat-error>
                </mat-form-field>
            </div>
            <div class="col-12 col-sm-6 col-md-3">
                <mat-form-field appearance="outline">
                    <mat-label>Nome Social</mat-label>
                    <input matInput type="text" formControlName="socialName">
                </mat-form-field>
            </div>
            <div class="col-6 col-sm-3 col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>Data de Nascimento</mat-label>
                    <input matInput type="text" (blur)="onKeySearchAge($event)" formControlName="birthDate"
                        [mask]="masks.date">
                    <mat-error
                        *ngIf="model.get('birthDate').invalid && model.get('birthDate').errors && !model.get('birthDate').errors.invalidDate">Data
                        é obrigatória</mat-error>
                    <mat-error
                        *ngIf="model.get('birthDate').invalid && model.get('birthDate').errors && model.get('birthDate').errors.invalidDate">{{model.get('birthDate').errors.invalidDate}}</mat-error>
                </mat-form-field>
            </div>

            <div class="col-6 col-sm-3 col-md-3">
                <mat-form-field appearance="outline">
                    <mat-label>Idade</mat-label>
                    <input matInput type="text" formControlName="patientAge">
                </mat-form-field>
            </div>
            <div class="row">
                <div class="col-6 col-sm-3 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Genêro</mat-label>
                        <mat-select formControlName="idGender">
                            <mat-option *ngFor="let item of genderList" value="{{item.idGender}}">
                                {{item.genderName}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="model.get('idGender').invalid">Informe o gênero</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-6 col-sm-3 col-md-3">
                    <mat-form-field appearance="outline" class="my-form-field">
                        <mat-label>CPF</mat-label>
                        <input matInput type="text" formControlName="cpf" [mask]="masks.cpf">
                        <mat-error *ngIf="model.get('cpf').invalid">Informe o CPF</mat-error>
                    </mat-form-field>
                    <div class="adjust-checkbox">
                        <mat-checkbox *ngIf="showMandatoryCpfCheckbox" (change)='cpfChangeValidator($event)'
                            formControlName="notMandatoryCpf" class="my-checkbox-field">
                            CPF não informado
                        </mat-checkbox>
                    </div>
                </div>
                <div class="col-6 col-sm-3 col-md-3">
                    <mat-form-field appearance="outline" class="my-form-field">
                        <mat-label>CNS</mat-label>
                        <input matInput type="text" formControlName="cns" [mask]="masks.cns">
                        <mat-error *ngIf="model.get('cns').invalid">CNS Inválido</mat-error>
                    </mat-form-field>
                    <div class="adjust-checkbox">
                        <mat-checkbox *ngIf="showMandatoryCnsCheckbox" (change)='cnsChangeValidator($event)'
                            formControlName="notMandatoryCns" class="my-checkbox-field">
                            CNS não informado
                        </mat-checkbox>
                    </div>
                </div>
                <div class="col-6 col-sm-3 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Telefone 1</mat-label>
                        <input matInput type="text" formControlName="phone" [mask]="masks.phone">
                        <mat-error *ngIf="model.get('phone').invalid">Informe o Telefone</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-6 col-sm-3 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Telefone 2</mat-label>
                        <input matInput type="text" formControlName="secondePhone" [mask]="masks.phone">
                        <mat-error *ngIf="model.get('secondePhone').invalid">Informe o Telefone</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Comorbidades</mat-label>
                        <mat-select formControlName="listIdComorbidity" multiple>
                            <mat-option *ngFor="let item of listComorbidity" [value]="item.idComorbidity">
                                {{item.comorbidityName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="title-header">
        </div>
        <app-meta-data *ngIf="this.virtualTriageConfigStruct"
            [listIdMetaDataForUsage]="this.virtualTriageConfigStruct.listIdMetaData"
            [listIdRequiredMetaData]="this.virtualTriageConfigStruct.listIdRequiredMetaData" [model]="this.model"
            [listValueMetaData]="this.listValueMetaData">
        </app-meta-data>
        <div class="row">
            <div class="col-12 col-sm-3 col-md-2">
                <button mat-flat-button type="submit" color="primary" class=" btn-block">
                    <span *ngIf="isLoading == false">Salvar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
            </div>
            <div class="col-12 col-sm-3 col-md-2">
                <a mat-flat-button color="accent" (click)="cancelAttandance()" class=" btn-block">
                    <span *ngIf="isLoading == false">Cancelar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </a>
            </div>
        </div>
    </form>
</div>