import { AllergyModel } from "../../models/medical-record/allergy.model";
import { NursingConsultationStruct } from "../../structs/medical-record/nursing-consultation.struct";
import { CompleteMedicalCareStruct } from "../../structs/orchestrator-patient/complete-medical-care.struct";

export class ReportObservationRequest{
    
    public digitalSignature: boolean;
    
    public medicalCareHistorie: CompleteMedicalCareStruct[];
    
    public listNursingConsultation: NursingConsultationStruct[];
    
    public listAllergy: AllergyModel[];
}