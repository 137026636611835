import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { MedicineAdministrationTypeEnum } from 'src/app/shared/enum/medicine-administration-type.enum';
import { MedicinePresentationTypeEnum } from 'src/app/shared/enum/medicine-presentation-type.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { LookupMedicalCareService } from 'src/app/shared/services/API/medical-record/lookup-medical-care.service';
import { MedicineService } from 'src/app/shared/services/API/pharmacy/medicine.service';
import { MedicineAdministrationTypeModel } from 'src/app/shared/services/models/pharmacy/medicine-administration-type.model';
import { MedicinePresentationTypeModel } from 'src/app/shared/services/models/pharmacy/medicine-presentation-type.model';
import { MedicineRequest } from 'src/app/shared/services/requests/pharmacy/medicine.request';
import { MedicineGroupTypeStruct } from 'src/app/shared/services/structs/pharmacy/medicine-group-type.struct';
import { MedicineSubgroupStruct } from 'src/app/shared/services/structs/pharmacy/medicine-subgroup.struct';
import { DilutionStruct } from 'src/app/shared/services/structs/medical-record/dilution.struct'
import { MeasurementUnit } from 'src/app/shared/services/structs/medical-record/measurement-unit.struct';
import { AllergyModel } from 'src/app/shared/services/models/medical-record/allergy.model';
import { AllergyStruct } from 'src/app/shared/services/structs/medical-record/allergy.struct';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KitMultiselectStruct } from 'src/app/shared/services/structs/pharmacy/kit-multiselect.struct';
import { LookupService } from 'src/app/shared/services/API/pharmacy/lookup.service';
import { LookupsBooleanChoiceRequest } from 'src/app/shared/services/requests/pharmacy/lookups-boolean-choice.request';
import { PharmacyConfigService } from 'src/app/shared/services/API/pharmacy/pharmacy-config.service';

@Component({
  selector: 'app-medicine-register',
  templateUrl: './medicine-register.component.html',
  styleUrls: ['./medicine-register.component.css']
})
export class MedicineRegisterComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private medicineService: MedicineService,
    private lookupMedicalCareService: LookupMedicalCareService,
    private lookupService: LookupService,
    private pharmacyConfigService: PharmacyConfigService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.pharmacy;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.pharmacy_medicine;
  public model: FormGroup;
  public isLoading: boolean;
  public isUpdate: boolean;
  public isGroup: boolean;
  public allergies: AllergyModel[] = [];
  public allergiesToSave: AllergyModel[] = [];
  public allergyError: boolean = false;
  public idMedicine: number;
  public listMedicineGroup: MedicineGroupTypeStruct[] = [];
  public listMedicineSubgroup: MedicineSubgroupStruct[] = [];
  public listMedicinePresentationType: MedicinePresentationTypeModel[] = [];
  public listMedicineDilution: DilutionStruct[] = [];
  public listMedicineMeasurementUnit: MeasurementUnit[] = [];
  public listMedicineAdministrationType: MedicineAdministrationTypeModel[] = [];
  public listKit: KitMultiselectStruct[] = [];
  public medicineRequest: MedicineRequest;

  ngOnInit(): void {
    this.isLoading = false;
    this.isGroup = true;
    this.idMedicine = null;
    this.isUpdate = false;

    this.model = this.formBuilder.group({
      code: [''],
      description: ['', [Validators.required]],
      idMedicineGroup: [null, [Validators.required]],
      idMedicineSubgroup: [null],
      idMedicinePresentationType: [null, [Validators.required]],
      idMedicineAdministrationType: [null],
      listIdMedicineAdministrationType: [],
      medicinePresentationOther: [''],
      medicineAdministrationOther: [''],
      radioGroup: [''],
      status: [true, [Validators.required]],
      fastTrack: [true, [Validators.required]],
      idDilution: [null],
      idMeasurementUnit: [null],
      minimumAmount: [null, [Validators.required]],
      idKit: [null],
    });

    this.model.get('medicineAdministrationOther').disable();
    this.model.get('medicinePresentationOther').disable();

    this.medicineRequest = new MedicineRequest();

    this.populatePharmacyLookups();
    this.populateMedicalRecordLookups();    

    if (this.activatedRoute.snapshot.paramMap.get('idMedicine'))
      this.idMedicine = parseInt(this.activatedRoute.snapshot.paramMap.get('idMedicine'));

    if (this.idMedicine && this.idMedicine > 0) {
      this.isUpdate = true;
      this.model.get('idMedicineGroup').disable();
      this.model.get('idMedicineSubgroup').disable();
      this.model.get('radioGroup').disable();      
    }

    this.populatePharmacyConfig(this.isUpdate);
  }

  submit() {
    if (this.isLoading)
      return;

    if (!this.model.valid) {
      this.alertService.show('Erro', "Preencha todos em vermelho", AlertType.error);
      this.isLoading = false;
      return;
    }

    if (this.isGroup === true)
      this.medicineRequest.idMedicineGroup = this.model.get('idMedicineGroup').value;
    else
      this.medicineRequest.idMedicineSubgroup = this.model.get('idMedicineSubgroup').value;

    this.medicineRequest.idMedicinePresentationType = this.model.get('idMedicinePresentationType').value ? this.model.get('idMedicinePresentationType').value : null;
    this.medicineRequest.medicineDescription = this.model.get('description').value;
    this.medicineRequest.medicineCode = this.model.get('code').value?.toString();
    this.medicineRequest.listIdMedicineAdministrationType = this.model.get('listIdMedicineAdministrationType').value ? this.model.get('listIdMedicineAdministrationType').value : null;
    this.medicineRequest.medicinePresentationOther = this.model.get('medicinePresentationOther').value;
    this.medicineRequest.medicineAdministrationOther = this.model.get('medicineAdministrationOther').value;
    this.medicineRequest.idDilution = this.model.get('idDilution').value ? this.model.get('idDilution').value : null;
    this.medicineRequest.idMeasurementUnit = this.model.get('idMeasurementUnit').value ? this.model.get('idMeasurementUnit').value : null;
    this.medicineRequest.isActive = this.model.get('status').value;
    this.medicineRequest.fastTrack = this.model.get('fastTrack').value;
    this.medicineRequest.minimumAmount = this.model.get('minimumAmount').value;
    this.medicineRequest.idKit = this.model.get('idKit').value;

    if (this.isUpdate)
      this.updateMedicine();
    else
      this.createMedicine();
  }

  mapActivePrinciple() {
    this.medicineRequest.listAllergy = this.allergiesToSave.map(a => new AllergyStruct(a, null));
  }

  populateMedicineData(hasAutoIncrementConfig: boolean) {
    if (this.isLoading)
      return;

    this.isLoading = true;

    this.medicineService.getMedicine(this.idMedicine).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isGroup = response.medicine.idMedicineGroup != null ? true : false;
        this.model.get('description').setValue(response.medicine.medicineDescription);
        this.model.get('code').setValue(response.medicine.medicineCode);

        if (hasAutoIncrementConfig)
          this.model.get('code').disable();

        if (response.medicine.idMedicineGroup)
          this.model.get('idMedicineGroup').setValue(response.medicine.idMedicineGroup);

        if (response.medicine.idMedicineSubgroup)
          this.model.get('idMedicineSubgroup').setValue(response.medicine.idMedicineSubgroup);

        this.model.get('idMedicinePresentationType').setValue(response.medicine.idMedicinePresentationType);
        this.model.get('idMedicineAdministrationType').setValue(response.medicine.idMedicineAdministrationType);
        this.model.get('listIdMedicineAdministrationType').setValue(response.listIdMedicineAdministrationType);
        this.model.get('idDilution').setValue(response.medicine.idDilution);
        this.model.get('idMeasurementUnit').setValue(response.medicine.idMeasurementUnit);

        this.model.get('medicinePresentationOther').setValue(response.medicine.medicinePresentationOther);
        this.model.get('medicineAdministrationOther').setValue(response.medicine.medicineAdministrationOther);
        this.model.get('status').setValue(response.medicine.isActive);
        this.model.get('fastTrack').setValue(response.medicine.fastTrack);
        this.model.get('minimumAmount').setValue(response.medicine.minimumAmount);
        this.model.get('idKit').setValue(response.medicine.idKit);

        if (this.model.get('idMedicinePresentationType') && this.model.get('idMedicinePresentationType').value != MedicinePresentationTypeEnum.Gota) {
          this.listMedicinePresentationType = this.listMedicinePresentationType.filter(item => {
            return !(item.idMedicinePresentationType === MedicinePresentationTypeEnum.Gota);
          });

        }

        this.checkOtherPresentation();
        this.checkOtherAdministration();

        if (response.listAllergy != null) {
          this.allergies = response.listAllergy.map(a => new AllergyModel(a));
          this.updateAllergiesToSave(this.allergies);
        }

        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateMedicalRecordLookups() {
    this.lookupMedicalCareService.getLookupMedicalCare().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listMedicineDilution = [];

        if (response.listDilution && response.listDilution.length > 0)
          this.listMedicineDilution = [...response.listDilution];

        this.listMedicineMeasurementUnit = [];

        if (response.listMeasurementUnit && response.listMeasurementUnit.length > 0)
          this.listMedicineMeasurementUnit = [...response.listMeasurementUnit];
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populatePharmacyLookups() {
    let request: LookupsBooleanChoiceRequest = new LookupsBooleanChoiceRequest();    
    request.isToGetMedicineGroupTypeLookup = true;
    request.isToGetMedicineSubgroupLookup = true;  
    request.isToGetMedicinePresentationTypeLookup = true;  
    request.isToGetMedicineAdministrationTypeLookup = true;  
    request.isToGetKitMultiselectLookup = true;

    this.lookupService.searchLookupsUsingBooleanRequestFilter(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listMedicineGroup = [];

        if (response.listMedicineGroupTypeStruct && response.listMedicineGroupTypeStruct.length > 0)
          this.listMedicineGroup = [...response.listMedicineGroupTypeStruct];

        this.listMedicineSubgroup = [];

        if (response.listMedicineSubgroupStruct && response.listMedicineSubgroupStruct.length > 0)
          this.listMedicineSubgroup = [...response.listMedicineSubgroupStruct];

        this.listMedicinePresentationType = [];

        if (response.listMedicinePresentationType && response.listMedicinePresentationType.length > 0)
          this.listMedicinePresentationType = [...response.listMedicinePresentationType];

        this.listMedicineAdministrationType = [];

        if (response.listMedicineAdministrationType && response.listMedicineAdministrationType.length > 0)
          this.listMedicineAdministrationType = [...response.listMedicineAdministrationType];

        this.listKit = [];

        if (response.listKitMultiselectStruct && response.listKitMultiselectStruct.length > 0)
          this.listKit = [...response.listKitMultiselectStruct];
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populatePharmacyConfig(isToPopulateMedicine: boolean = false) {  
    if (this.isLoading)
      return;
    
    this.isLoading = true;

    let isToGetItemAutoIncrement: boolean = false;
    let isToGetMedicineAutoIncrement: boolean = true;

    this.pharmacyConfigService.getConfigByHealthUnit(isToGetItemAutoIncrement, isToGetMedicineAutoIncrement).subscribe({
      next: (response) => {
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        let hasAutoIncrementConfig: boolean = response.nextMedicineCode && response.nextMedicineCode.trim() != "";

        if (this.isUpdate == false && hasAutoIncrementConfig) {
          this.model.get('code').setValue(response.nextMedicineCode);
          this.model.get('code').disable();
        }

        this.isLoading = false;

        if (isToPopulateMedicine)
          this.populateMedicineData(hasAutoIncrementConfig);
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  updateMedicine() {
    if (this.isLoading)
      return;

    this.isLoading = true;

    this.medicineService.updateMedicine(this.idMedicine, this.medicineRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;

        this.router.navigate(['/pharmacy/medicine']);
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  createMedicine() {
    if (this.isLoading)
      return;

    this.isLoading = true;

    this.medicineService.createMedicine(this.medicineRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;

        this.router.navigate(['/pharmacy/medicine']);
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  onChange(value) {
    this.isGroup = value;
    this.model.get('idMedicineSubgroup').setValidators([]);
    this.model.get('idMedicineGroup').setValidators([]);

    if (value) {
      this.model.get('idMedicineGroup').setValidators([Validators.required]);
      this.model.get('idMedicineSubgroup').setValue(null);
    }
    else {
      this.model.get('idMedicineGroup').setValue(null);
      this.model.get('idMedicineSubgroup').setValidators([Validators.required]);
    }

    this.model.get('idMedicineGroup').updateValueAndValidity();
    this.model.get('idMedicineSubgroup').updateValueAndValidity();
  }

  checkOtherPresentation() {
    if (this.model.get('idMedicinePresentationType').value == MedicinePresentationTypeEnum.Outro) {
      this.model.get('medicinePresentationOther').enable();
      this.model.get('medicinePresentationOther').setValidators([Validators.required]);
    }
    else {
      this.model.get('medicinePresentationOther').disable();
      this.model.get('medicinePresentationOther').setValidators([]);
    }

    this.model.get('medicinePresentationOther').updateValueAndValidity();
  }

  updateAllergiesToSave(allergies: AllergyModel[]) {
    this.allergiesToSave = allergies;
    this.mapActivePrinciple();
  }

  checkOtherAdministration() {
    var listId = this.model.get("listIdMedicineAdministrationType").value ? this.model.get("listIdMedicineAdministrationType").value : [];
    if (listId.filter(x => x == MedicineAdministrationTypeEnum.Outros).length > 0) {
      this.model.get('medicineAdministrationOther').enable();
      this.model.get('medicineAdministrationOther').setValidators([Validators.required]);
    }
    else {
      this.model.get('medicineAdministrationOther').disable();
      this.model.get('medicineAdministrationOther').setValidators([]);
    }

    this.model.get('medicineAdministrationOther').updateValueAndValidity();
  }

  removePresentationType() {
    this.listMedicinePresentationType = this.listMedicinePresentationType.filter(item => {
      return !(item.idMedicinePresentationType === MedicinePresentationTypeEnum.Gota);
    });
  }
}