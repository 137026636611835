import { TagStruct } from "src/app/shared/components/tag/tag.struct";
import { ExpressionRequest } from "./expression.request";

export class FlowRequest{
    public flowName: string;
    
    public description: string;
    
    public isActive: boolean;
    
    public idOriginTotemButton: number;
    public idOriginService: number;
    public idOriginSector: number;
    public idOriginRoom: number;
    public idDestinationService: number;
    public idDestinationSector: number;
    public idDestinationRoom: number;

    public idIntermediaryService: number;
    public idIntermediarySector: number;
    public idIntermediaryRoom: number;

    public priority: number;

    public expressions: ExpressionRequest[];

    public expressionsAuto: ExpressionRequest[];

    public listTags: TagStruct[];
}