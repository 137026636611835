<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <h1>Manutenção do Estoque</h1>
        <div class="container-block">
            <div class="col-12 col-lg-6">

                <div class="white-body">
                    <div class="filters">
                        <form class="form" [formGroup]="model" (ngSubmit)="buttonSearch()">
                            <div class="row">
                                <div class="col-12 col-sm-6 col-md-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Código do produto</mat-label>
                                        <input matInput type="text" formControlName="code">
                                    </mat-form-field>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Descrição</mat-label>
                                        <input matInput type="text" formControlName="description">
                                    </mat-form-field>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4">
                                    <button mat-flat-button color="primary" class=" btn-block" type="submit">
                                        <span *ngIf="!isLoading">Pesquisar</span>
                                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="card-list" *ngIf="listStorage != null">
                        <div class="row">
                            <div class="col-11" *ngFor="let item of listStorage">
                                <div class="card-item">
                                    <div class="infos">
                                        <span class="primary">Produto: {{item.productCode.product}}</span>
                                        <span class="primary">Código: {{item.productCode.code}}</span>
                                    </div>
                                    <div class="actions">
                                        <a>
                                            <mat-icon aria-hidden="false" aria-label="Editar"
                                                [routerLink]="['/pharmacy/movement/register', {idMedicine: item.idMedicine, idItem :item.idItem}]">
                                                edit</mat-icon>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            
                    <app-empty-list *ngIf="!isLoading && listStorage != undefined && listStorage.length == 0"></app-empty-list>
                    <div *ngIf="!isLoading" class="row">
                        <div class="col-12 col-sm-12 col-md-12">
                            <mat-paginator [length]="fullSize" #paginator [pageIndex]="pageIndex" [pageSize]="50"
                                aria-label="Select page" (page)="changePage($event)" [hidePageSize]="true">
                            </mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="container-big-number">
                    <div class="white-body product">
                        <h4>Produtos abaixo da quantidade mínima:</h4>
                        <p>{{this.totalBelowMinimum}}</p>
                    </div>
                    <div class="white-body batch">
                        <h4>Lotes vencidos em estoque: </h4>
                        <p>{{this.totalExpired}}</p>
                    </div>
                </div>
                <div class="white-body table">
                    <div class="table-header">
                        <h1>Aviso de compra</h1>
                        <div class="col-12 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-icon matPrefix class="my-icon">search</mat-icon>
                                <mat-label>Buscar na tabela</mat-label>
                                <input matInput type="text" id="searchTextBelowMinimum" name="searchTextBelowMinimum" [(ngModel)]="searchTextBelowMinimum"
                                    (keyup)="onKeySearchBelowMinimum($event)">
                            </mat-form-field>
                        </div>
                    </div>
                    <div *ngIf="!isLoading">
                        <table class="basic-table">
                            <thead class="basic-table__head">
                                <tr>
                                    <th>Produto</th>
                                    <th>Qtd Atual</th>
                                    <th>Qtd Mínima</th>
                                </tr>
                            </thead>
                            <tbody class="basic-table__body" *ngIf="this.listStorageBelowMinimum.length > 0">
                                <tr class="basic-table__body__row" *ngFor="let item of this.listStorageBelowMinimum; let index = index;">
                                    <td class="truncate"><span>{{item.productCode.product}}</span></td>
                                    <td class="truncate"><span>{{item.storageAmountLocation.amountRemaining}}</span></td>
                                    <td class="truncate"><span>{{item.medicine.minimumAmount}}</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row" *ngIf="!isLoading && this.listStorageBelowMinimum.length > 0">
                        <div class="col-12 col-sm-12 col-md-12">
                            <mat-paginator [length]="this.totalBelowMinimum" #paginator [pageIndex]="0"
                                [pageSize]="this.pageSizeTable" aria-label="Select page"
                                (page)="changePageBelowMinimum($event)" [hidePageSize]="true">
                            </mat-paginator>
                        </div>
                    </div>
                    <div *ngIf="this.listStorageBelowMinimum.length == 0 && !isLoading" class="empty-table"><p>Nenhum dado disponível na tabela</p></div>
                
                </div>
                <div class="white-body table">
                    <div class="table-header">
                        <h1>Aviso de vencimento</h1>
                        <div class="col-12 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-icon matPrefix class="my-icon">search</mat-icon>
                                <mat-label>Buscar na tabela</mat-label>
                                <input matInput type="text" id="searchTextExpired" name="searchTextExpired" [(ngModel)]="searchTextExpired"
                                    (keyup)="onKeySearchExpired($event)">
                            </mat-form-field>
                        </div>
                    </div>
                    <div *ngIf="!isLoading">
                        <table class="basic-table">
                            <thead class="basic-table__head">
                                <tr>
                                    <th>Produto</th>
                                    <th>Lote</th>
                                    <th>Qtd</th>
                                    <th>Vencimento</th>
                                </tr>
                            </thead>
                            <tbody class="basic-table__body" *ngIf="this.listStorageExpired.length > 0">
                                <tr class="basic-table__body__row" *ngFor="let item of this.listStorageExpired; let index = index;">
                                    <td class="truncate"><span>{{item.productCode.product}}</span></td>
                                    <td class="truncate"><span>{{item.batch}}</span></td>
                                    <td class="truncate"><span>{{item.storageAmountLocation.amountTotal}}</span></td>
                                    <td class="truncate"><span>{{item.dateExpiration | date:'dd/MM/yyyy'}}</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row" *ngIf="!isLoading && this.listStorageExpired.length > 0">
                        <div class="col-12 col-sm-12 col-md-12">
                            <mat-paginator [length]="this.totalExpired" #paginator [pageIndex]="0"
                                [pageSize]="this.pageSizeTable" aria-label="Select page"
                                (page)="changePageExpired($event)" [hidePageSize]="true">
                            </mat-paginator>
                        </div>
                    </div>
                    <div *ngIf="this.listStorageExpired.length == 0 && !isLoading" class="empty-table"><p>Nenhum dado disponível na tabela</p></div>
                </div>
            </div>
        </div>
        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>
    
</div>