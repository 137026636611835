export enum MenuFunctionalityGroupEnum {
    usuario = 1,
    organizacao = 2,
    equipamentos = 3,
    medicamentos = 4,
    exames = 5,
    sadt = 6,
    relatorios = 7,
    configuracao_medico = 9,
    configuracao_master = 14,
    autotriagem = 15,
    billing = 16,
    higienizacao = 17,
    nps = 18,
    menu_painel_lean = 19,
    gerenciamento_de_aih = 20,
    faturamento_tiss = 21,
    central_do_paciente = 22,
    triagem_virtual = 23,
    telemedicina = 24,
}