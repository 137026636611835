import { Component, Input, OnInit } from '@angular/core';
import { CompleteMedicalCareStruct } from 'src/app/shared/services/structs/orchestrator-patient/complete-medical-care.struct';
import { ReadMedicalPrescriptionStruct } from 'src/app/shared/services/structs/orchestrator-patient/read-medical-prescription.struct';
import { MatDialog } from '@angular/material/dialog';
import { ListMedicineResponse } from 'src/app/shared/services/responses/pharmacy/list-medicine.response';
import { ListExamResponse } from 'src/app/shared/services/responses/SADT/list-exam.response';
import { LookupMedicalCareResponse } from 'src/app/shared/services/responses/medical-record/lookup-medical-care-response';
import { PrescriptionModalComponent } from 'src/app/medic/pages/attend-patient/prescription-modal/prescription-modal.component';
import { SadtModalComponent } from 'src/app/shared/components/sadt-modal/sadt-modal.component';
import { DownloadReportService } from 'src/app/shared/services/API/medical-record/download-report.service';
import { ReportModalComponent } from 'src/app/shared/components/report-modal/report-modal.component';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ReadExternalPrescriptionStruct } from 'src/app/shared/services/structs/orchestrator-patient/read-external-prescription.struct';
import { ExternalPrescriptionModalComponent } from 'src/app/shared/components/external-prescription-modal/external-prescription-modal.component';
import { SadtGroupStruct } from 'src/app/shared/services/structs/medical-record/sadt-group.struct';
import { ExamTypeModel } from 'src/app/shared/services/models/SADT/exam-type.model';
import { MedicatedSolutionStruct } from 'src/app/shared/services/structs/medical-record/medicated-solution.struct';
import { StandardPrescriptionStruct } from 'src/app/shared/services/structs/medical-record/standard-prescription.struct';
import { MemedPrescriptionStruct } from 'src/app/shared/services/structs/medical-record/memed-prescription.struct';

@Component({
  selector: 'app-medical-care-patient-history',
  templateUrl: './medical-care-accordion.component.html',
  styleUrls: ['./medical-care-accordion.component.css']
})
export class MedicalCareAccordionComponent implements OnInit {

  constructor(private dialog: MatDialog,
    private downloadReportService: DownloadReportService,
    private alertService: AlertService,
  ) { }


  @Input() medicalCare: CompleteMedicalCareStruct[] = [];
  @Input() lookupMedicalCareResponse: LookupMedicalCareResponse;
  @Input() listMedicine: ListMedicineResponse[] = [];
  @Input() listExam: ListExamResponse;
  @Input() listExamType: ExamTypeModel[];
  @Input() isLoading: boolean = false;

  ngOnInit(): void {
  }

  openPrecriptionModalWithMedicine(medicalPrescription: ReadMedicalPrescriptionStruct) {
    let prescription = new StandardPrescriptionStruct();
    prescription.readMedicalPrescriptionStruct = medicalPrescription;
    this.openPrescriptionModal(prescription, false);
  }

  openPrecriptionModalWithMedicatedSolution(medicalPrescription: MedicatedSolutionStruct) {
    let prescription = new StandardPrescriptionStruct();
    prescription.medicatedSolutionStruct = medicalPrescription;
    this.openPrescriptionModal(prescription, true);
  }

  openPrescriptionModal(prescription: StandardPrescriptionStruct, isMedicatedSolution: boolean) {
    const dialogRef = this.dialog.open(PrescriptionModalComponent, {
      data: {
        prescription,
        readOnly: true,
        lookupMedicalCareResponse: this.lookupMedicalCareResponse,
        listMedicine: this.listMedicine,
        isMedicatedSolution: isMedicatedSolution,
      },
    });
  }

  openExternalPrescriptionModal(prescription: ReadExternalPrescriptionStruct) {
    const dialogRef = this.dialog.open(ExternalPrescriptionModalComponent, {
      data: {
        prescription,
        lookupMedicalCareResponse: this.lookupMedicalCareResponse,
        readOnly: true,
      },
    });
  }

  openSadtModal(sadt: SadtGroupStruct, readOnly: boolean) {
    const dialogRef = this.dialog.open(SadtModalComponent, {
      data: {
        sadt,
        readOnly: true,
        listPlace: this.lookupMedicalCareResponse.listPlace,
        listExam: this.listExam,
        listExamType: this.listExamType,
      },
    });
  }

  downloadReport(idEpisode: number, reportName: string) {
    this.isLoading = true;
    this.downloadReportService.downloadReport(idEpisode, reportName).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.isLoading = false;
      let reportDialog = this.dialog.open(ReportModalComponent, {
        data: {
          reportName: response.reportName,
          reportPdf64: response.reportPdf64,
        },
      });
      reportDialog.afterClosed().subscribe(result => {
      });
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  openReportMemed(listMemedPrescriptionStruct: MemedPrescriptionStruct[]) {
    listMemedPrescriptionStruct.forEach(item => {

      this.dialog.open(ReportModalComponent, {
        data: {
          reportPdf64: item.memedPrescriptionReport64,
        },
      });
    });
  }
}
