<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <h1>Configurações de Totem</h1>
        <app-loading-progress-bar *ngIf="isLoading"></app-loading-progress-bar>
        <div class="white-body">
            <a mat-flat-button color="primary" class=" add-user" routerLink="/observation/hygienization-totem/register">
                <mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon> Cadastrar Totem
            </a>
            <div class="filters">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Pesquisar</mat-label>
                            <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText"
                                (keyup)="onKeySearch($event)">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Status</mat-label>
                            <mat-select [(ngModel)]="status" (selectionChange)="search()">
                                <mat-option [value]="allStatus">
                                    Todos
                                </mat-option>
                                <mat-option [value]="activeStatus">
                                    Ativos
                                </mat-option>
                                <mat-option [value]="inactiveStatus">
                                    Inativos
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="card-list" *ngIf="listTotem != null">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listTotem">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary">Nome: {{item.hygienizationTotemName}}</span>
                                <span class="secundary">
                                    URL: <a type="button" class="direct-link" [href]="item.hygienizationTotemUrl"
                                        target="_blank">{{'client/hygienization-totem/' + item.idHygienizationTotem}}</a>
                                    <button matTooltip="Copiar" aria-label="Copiar" class="copy-to-clipboard" type="button"
                                        [cdkCopyToClipboard]="item.hygienizationTotemUrl" [cdkCopyToClipboardAttempts]="5">
                                        <mat-icon class="copy-icons">content_copy</mat-icon>
                                    </button>
                                </span>
                                <span class="secundary" [cdkCopyToClipboard]="item.pin">
                                    PIN: {{item.pin}}
                                    <button matTooltip="Copiar" aria-label="Copiar" class="copy-to-clipboard" type="button"
                                        [cdkCopyToClipboard]="item.pin" [cdkCopyToClipboardAttempts]="5">
                                        <mat-icon class="copy-icons">content_copy</mat-icon>
                                    </button>
                                </span>
                                <span class="secundary-status active" *ngIf="item.isActive">Ativo</span>
                                <span class="secundary-status inactive" *ngIf="!item.isActive">Inativo</span>
                            </div>
                            <div class="actions">
                                <a><mat-icon aria-hidden="false" aria-label="Editar"
                                        [routerLink]="['/observation/hygienization-totem/register', {idHygienizationTotem: item.idHygienizationTotem}]">edit</mat-icon>
                                </a>
                                <a><mat-icon aria-hidden="false" aria-label="Editar"
                                        (click)="openDeleteModal(item.idHygienizationTotem)">delete_forever</mat-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <app-empty-list *ngIf="listTotem != undefined && listTotem.length == 0"></app-empty-list>
        </div>
    </div>
    
</div>