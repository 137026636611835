import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { StorageMigrationModalComponent } from 'src/app/shared/components/storage-migration-modal/storage-migration-modal.component';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { LocationService } from 'src/app/shared/services/API/pharmacy/location.service';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { LocationRequest } from 'src/app/shared/services/requests/pharmacy/location.request';

@Component({
  selector: 'app-location-register',
  templateUrl: './location-register.component.html',
  styleUrls: ['./location-register.component.css']
})
export class LocationRegisterComponent implements OnInit {

  constructor(private formBuilder: UntypedFormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private locationService: LocationService,
    public dialog: MatDialog,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.pharmacy;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.pharmacy_location;

  public model: UntypedFormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;
  public actualServiceModule: number;

  public masks: Masks;
  public idLocation: number;
  public locationRequest: LocationRequest;

  public hasStorage: boolean;

  ngOnInit(): void {

    this.isLoading = false;
    this.locationRequest = new LocationRequest();

    this.model = this.formBuilder.group({
      name: ['', [Validators.required]],
      status: ['', [Validators.required]],
    });

    this.masks = this.maskService.getMasks();

    this.idLocation == null;
    this.isUpdate == false;
    if (this.activatedRoute.snapshot.paramMap.get('idLocation'))
      this.idLocation = parseInt(this.activatedRoute.snapshot.paramMap.get('idLocation'));

    if (this.idLocation != null) {
      this.isFirstLoading = true;
      this.isUpdate = true;
      this.populateLocationData();
    }
  }

  async submit() {
    if (this.isLoading) {
      return;
    }

    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }
    this.isLoading = true;

    this.locationRequest.locationName = this.model.get('name').value;

    if (this.model.get('status').value === "true") {
      this.locationRequest.isActive = true;
    }
    if (this.model.get('status').value === "false") {
      this.locationRequest.isActive = false;
    }

    if (this.hasStorage && !this.locationRequest.isActive) {
      await this.openMigrationModal();
    }

    else {
      if (this.isUpdate)
        this.updateLocation();
      else
        this.createLocation();
    }
  }

  async openMigrationModal() {
    const dialogRef = this.dialog.open(StorageMigrationModalComponent, {
      data: {
        idCurrentLocation: this.idLocation
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.idNewLocation) {
        this.locationRequest.idNewLocation = result.idNewLocation
      }
      if (this.isUpdate)
        this.updateLocation();
      else
        this.createLocation();
    });
  }

  populateLocationData() {
    this.isLoading = true;
    this.locationService.getLocation(this.idLocation).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.model.get('name').setValue(response.location.locationName);
        this.model.get('status').setValue(response.location.isActive.toString());
        this.hasStorage = response.hasStorage;

        this.isFirstLoading = false;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });

  }

  updateLocation() {
    this.locationService.updateLocation(this.idLocation, this.locationRequest).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/pharmacy/location']);

    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  createLocation() {
    this.locationService.createLocation(this.locationRequest).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/pharmacy/location']);
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }
}

