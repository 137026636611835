import { ReturnStruct } from "../../../structs/return.struct";
import { HealthUnitStruct } from "../../structs/user/health-unit.struct";
import { ListModuleGroupStruct } from "../../structs/user/list-module-group.struct";
import { ModuleStruct } from "../../structs/user/module.struct";
import { RoleStruct } from "../../structs/user/role.struct";

export class UserLoginResponse extends ReturnStruct {

    public token: string;

    public idUser: number;

    public login: string;

    public userName: string;

    public email: string;

    public idDefaultModule: number;

    public uriDefaultModule: string;

    public defaultModuleName: string;

    public isFirstAccess: boolean;

    public isHealthUnitGroupMaster: boolean;

    public isMaster: boolean;

    public isActive: boolean;

    public healthUnit: HealthUnitStruct;

    public listHealthUnitGroup: HealthUnitStruct[];

    public listModule: ModuleStruct[];

    public listModuleGroup: ListModuleGroupStruct[];

    public listRole: RoleStruct[];

    public signatureHash: string;

    public haveMultipleHealthUnit: boolean;

    public codCbo: string;

    public idReportSadtTemplate: number;

    public isExpiredPassword: boolean;

    public listHealthUnit: HealthUnitStruct[];

    public guid: string;

    public canStayConnected: boolean; // esse dado só existe no front desse response (usado para ser colocado na session)
}
