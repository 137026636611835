import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { MatDialog } from '@angular/material/dialog';
import { ReportService } from 'src/app/shared/services/API/orchestrator-patient/report.service';
import { FowardService } from 'src/app/shared/services/API/orchestrator-queue/foward.service';
import { RoomService } from 'src/app/shared/services/API/flow/room.service';
import { SectorService } from 'src/app/shared/services/API/flow/sector.service';
import { ViaCepService } from 'src/app/shared/services/API/integration/via-cep.service';
import { AllergyService } from 'src/app/shared/services/API/medical-record/allergy.service';
import { ClockService } from 'src/app/shared/services/API/orchestrator-queue/clock.service';
import { SigaPatientStruct } from 'src/app/shared/services/structs/siga-front-desk/siga-patient.struct';
import { MetaDataStruct } from 'src/app/shared/services/structs/meta-data/meta-data.struct';
import { WebsocketAttendanceUtilService } from 'src/app/shared/services/websocket-util/websocket-attendance-util.service';
import { StatusQueueRequest } from 'src/app/shared/services/requests/queue/status-queue.request';
import { UpdateStatusQueueService } from 'src/app/shared/services/API/orchestrator-queue/update-status-queue.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AttendanceOccupiedRemoveModalComponent } from 'src/app/shared/components/attendance-occupied-remove-modal/attendance-occupied-remove-modal.component';

@Component({
  selector: 'app-siga-patient-register',
  templateUrl: './siga-patient-register.component.html',
  styleUrls: ['./siga-patient-register.component.css']
})
export class SigaPatientRegisterComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public fowardService: FowardService,
    public reportService: ReportService,
    public roomService: RoomService,
    public sectorService: SectorService,
    public router: Router,
    public viaCepService: ViaCepService,
    public allergyService: AllergyService,
    public clockService: ClockService,
    public websocketAttendanceUtilService: WebsocketAttendanceUtilService,
    public updateStatusQueueService: UpdateStatusQueueService,
    private alertService: AlertService,
    private authService: AuthService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.frontdesk;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.frontdesk_patient_register;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public findPatient: boolean = false;
  public datetimeStartRegister: Date;
  public metaData: MetaDataStruct[] = [];
  public idEpisode: number = null;
  public idQueue: number = null;
  public idSector: number = null;
  public idRoom: number = null;
  public isUpdateFrontDesk: boolean = false;
  public patientData: SigaPatientStruct;
  public isWristbandActive: boolean;
  public wristbandWidth: number;
  public wristbandHeight: number;
  public idIdentification: number;
  public isAttended: boolean = false;
  public userLogin: string;
  public userName: string;
  public isAttendanceRemoval: boolean = false;

  ngOnInit(): void {
    this.isLoading = true;
    this.isFirstLoading = true;
    this.patientData = new SigaPatientStruct();
    this.userLogin = this.authService.getTokenMenu().login;
    this.userName = this.authService.getTokenMenu().userName;

    if (this.activatedRoute.snapshot.paramMap.get('idEpisode')) 
      this.idEpisode = parseInt(this.activatedRoute.snapshot.paramMap.get('idEpisode'));

    this.idSector = parseInt(this.activatedRoute.snapshot.paramMap.get('idSector'));
    this.idRoom = parseInt(this.activatedRoute.snapshot.paramMap.get('idRoom'));
    this.idQueue = parseInt(this.activatedRoute.snapshot.paramMap.get('idQueue'));

    if (this.activatedRoute.snapshot.paramMap.get('isUpdateFrontdesk')) 
      this.isUpdateFrontDesk = this.activatedRoute.snapshot.paramMap.get('isUpdateFrontdesk') == "true" ? true : false;

    if (this.isUpdateFrontDesk) this.findPatient = true;

    if (Number.isInteger(this.idQueue) &&  this.idQueue && this.idQueue > 0 && Number.isInteger(this.idEpisode) &&  this.idEpisode && this.idEpisode > 0 && this.isUpdateFrontDesk == false) 
      this.startWebsocket();    
    
    this.isFirstLoading = false;
    this.isLoading = false;
  }

  ngOnDestroy() {
    if (this.websocketAttendanceUtilService.isConnect()){
      this.websocketAttendanceUtilService.emitFinalizeAttendance(this.idRoom);

      if (this.isAttended == false && Number.isInteger(this.idQueue) &&  this.idQueue && this.idQueue > 0 && Number.isInteger(this.idEpisode) 
        && this.idEpisode && this.idEpisode > 0 && this.isUpdateFrontDesk == false
        && !this.isAttendanceRemoval) {          
        this.updateStatusQueue(this.idQueue, this.idEpisode);
      }
    }
  }

  updateStatusQueue(idQueue: number, idEpisode: number) {
    this.isLoading = true;
    this.updateStatusQueueService.updateQueueToOldStatus(idQueue, idEpisode).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show("Error", response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
      }
    });
  }

   //Websocket
  startWebsocket() {
    if (!this.websocketAttendanceUtilService.isConnect())
      this.websocketAttendanceUtilService.connectwebsocketRoom(this.idRoom, this.idQueue, this.idEpisode, this, false, false, this.authService.getToken(), this.userLogin, this.userName);

    this.websocketAttendanceUtilService.setContext(this);
    this.websocketAttendanceUtilService.setFunctions(null, null, this.onRemoveAttendant);         
  }


  newPatient() {
    this.findPatient = true;
  }

  getPatientData(event) {
    this.patientData = event;
  }

  getFindPatient(event) {
    this.findPatient = event;
  }

  clearPatientEvent() {
    this.patientData = new SigaPatientStruct();
  }

  setIsAttended(event) {
    this.isAttended = event;
  }

  onRemoveAttendant(thisParam, res: any){
    thisParam.isAttendanceRemoval = false;
    
    if(res.userLogin != thisParam.userLogin){
      thisParam.isAttendanceRemoval = true;
      
      thisParam.websocketAttendanceUtilService.clearSocket();
      let ref = thisParam.dialog.open(AttendanceOccupiedRemoveModalComponent, { data: { ...res } });
      ref.afterClosed().subscribe(() => {
        thisParam.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          thisParam.router.navigate(['/front-desk/patient-list', { idSector: thisParam.idSector, idRoom: thisParam.idRoom }]));
      })
    }
  }
}