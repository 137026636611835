import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { DashboardRequest } from '../../requests/data-intelligence/dashboard-request';
import { CreateDashboardResponse } from '../../responses/data-intelligence/create-dashboard.response';
import { DashboardResponse } from '../../responses/data-intelligence/dashboard.response';
import { ListDashboardResponse } from '../../responses/data-intelligence/list-dashboard.response';


@Injectable({
    providedIn: 'root'
})
export class DashboardService extends CommonService {

    constructor(private router: Router, private httpClient: HttpClient) {
        super();
    }

    public get(idDashboard: number): Observable<DashboardResponse> {

        let uri = `Dashboard/idDashboard/${idDashboard}`;

        return this.httpClient.get<DashboardResponse>(environment.urlApiDataIntelligence + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }

    public getAll(searchText: string, jsonListTags: string): Observable<ListDashboardResponse> {

        let uri = `Dashboard`;
        if (searchText != null)
            uri = uri + `?dashboardName=${searchText}`;

        if (jsonListTags != null)
            uri = uri + `?jsonListTags=${jsonListTags}`;

        return this.httpClient.get<ListDashboardResponse>(environment.urlApiDataIntelligence + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }

    public create(body: DashboardRequest): Observable<CreateDashboardResponse> {

        let uri = `Dashboard`;

        return this.httpClient.post<CreateDashboardResponse>(environment.urlApiDataIntelligence + uri, body, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }

    public update(body: DashboardRequest, idDashboard: number): Observable<ReturnStruct> {

        let uri = `Dashboard/idDashboard/${idDashboard}`;

        return this.httpClient.put<ReturnStruct>(environment.urlApiDataIntelligence + uri, body, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }

    public delete(idDashboard: number): Observable<ReturnStruct> {

        let uri = `Dashboard/idDashboard/${idDashboard}`;

        return this.httpClient.delete<ReturnStruct>(environment.urlApiDataIntelligence + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }
}