import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { BedHygienizationRequest } from '../../requests/bed/bed-hygienization.request';
import { ListBedCategoryResponse } from '../../responses/bed/list-bed-category-response.response';
import { ListBedHygienizationResponse } from '../../responses/bed/list-bed-hygienization.response';

@Injectable({
  providedIn: 'root'
})
export class BedHygienizationService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listBedHygienization(): Observable<ListBedHygienizationResponse>{

    let uri = `BedHygienization`

    return this.httpClient.get<ListBedHygienizationResponse>(environment.urlApiBed + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public startBedHygienization(body: BedHygienizationRequest): Observable<ReturnStruct>{

    let uri = `BedHygienization`

    return this.httpClient.post<ReturnStruct>(environment.urlApiBed + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public finishBedHygienization(body: BedHygienizationRequest): Observable<ReturnStruct>{

    let uri = `BedHygienization`

    return this.httpClient.put<ReturnStruct>(environment.urlApiBed + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
