<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'
        [hasSecundaryHeader]="true" [episode]="idEpisode" [hasCachedModule]="true" [cachedModuleName]="'medic'"
        [painColorCode]="painColorCode" [discriminators]="discriminators"></app-menu>
    <div class="container-body-secundary-header">
        <div class="white-body-online-attend">
            <div class="component-container">
                <app-pre-register *ngIf="flowControl == preRegister" (cancelAttendance)="cancelAttendanceBtn()"
                    (next)="next()" [idEpisode]="idEpisode" [idRoom]="idRoom" [idSector]="idSector">
                </app-pre-register>

                <app-protocol-select *ngIf="flowControl == protocolSelect" (next)="next()" [idEpisode]="idEpisode"
                    [idRoom]="idRoom" [idSector]="idSector">
                </app-protocol-select>

                <app-flowchart-selection *ngIf="flowControl == flowchartSelect" (next)="next()" [idEpisode]="idEpisode"
                    [idRoom]="idRoom" [idSector]="idSector">
                </app-flowchart-selection>

                <app-discriminator-select *ngIf="flowControl == discriminatorSelect" (next)="next()"
                    [painColorCode]="painColorCode" [discriminators]="discriminators"></app-discriminator-select>

                <app-counseling-selection *ngIf="flowControl == counselingSelect" (next)="next()" (back)="back()"
                    [idEpisode]="idEpisode" [idRoom]="idRoom" [idSector]="idSector">
                </app-counseling-selection>

                <app-forward-select *ngIf="flowControl == forwardEpisode" (next)="next()" (back)="back()"
                    (mapValues)="mapToTriageStruct()" [idEpisode]="idEpisode" [idRoom]="idRoom" [idSector]="idSector">
                </app-forward-select>

                <app-triage-resume *ngIf="flowControl == triageResume" [isLoading]="isLoading"
                    [isTelephonceClassification]="true" [patientForm]="patientForm" [isTeletriage]="true"
                    [triage]="triage" [hasEndDateTime]="hasTriage" (next)="next()"></app-triage-resume>

                <div class="col-12 col-sm-6 col-md-3"
                    *ngIf="flowControl != protocolSelect && flowControl != preRegister && flowControl != counselingSelect">
                    <button mat-flat-button type="button" (click)="back()" color="accent" class=" btn-block">
                        <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon>
                        <span *ngIf="!isLoading">Voltar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                    </button>
                </div>
            </div>
            <div class="fixed-video col-12 col-sm-3" id="videoDiv">
                <app-twilio-video #twiliovideo (leaveRoom)="onLeaveRoom()"></app-twilio-video>
            </div>
            <!-- <button mat-flat-button (click)="toggleLoading()">
                Loading: {{isLoading}}
                <span class="icon-ask"><mat-icon aria-hidden="false" aria-label="Plus">help_outline</mat-icon></span>
            </button> -->
        </div>
    </div>
</div>