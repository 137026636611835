import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MedicalRecordLookupService } from 'src/app/shared/services/API/medical-record/lookup.service';
import { DiscriminatorService } from 'src/app/shared/services/API/risk-classification/discriminator.service';
import { FlowchartService } from 'src/app/shared/services/API/risk-classification/flowchart.service';
import { ProtocolService } from 'src/app/shared/services/API/risk-classification/protocol.service';
import { ExamService } from 'src/app/shared/services/API/SADT/exam.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { FlowchartModel } from 'src/app/shared/services/models/risk-classification/flowchart.model';
import { ProtocolModel } from 'src/app/shared/services/models/risk-classification/protocol.model';
import { SearchFlowchartRequest } from 'src/app/shared/services/requests/risk-classification/search-flowchart.request';
import { environment } from 'src/environments/environment';
import { DiscriminatorStruct } from 'src/app/shared/services/structs/risk-classification/discriminator.struct';
import { ExamStruct } from 'src/app/shared/services/structs/SADT/exam.struct';
import { Gender } from 'src/app/shared/services/responses/medical-record/lookup.response';
import { FormStatus } from 'src/app/shared/services/models/form/form-status.model';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { PatientStruct } from 'src/app/shared/services/structs/medical-record/patient.struct';
import { GetPatientByCpfService } from 'src/app/shared/services/API/medical-record/get-patient-by-cpf.service';
import { ListPatientByCnsService } from 'src/app/shared/services/API/medical-record/list-patient-by-cns.service';
import { MatDialog } from '@angular/material/dialog';
import { FormPatientService } from 'src/app/shared/services/API/medical-record/form-patient.service';
import { BedService } from 'src/app/shared/services/API/bed/bed.service';
import { FormPatientRequest } from 'src/app/shared/services/requests/medical-record/form-patient.request';
import { FormEpisodeRequest } from 'src/app/shared/services/requests/form/form-episode.request';
import { BedStruct } from "src/app/shared/services/structs/bed/bed.struct";
import { FormSelectPatientModalComponent } from './form-select-patient-modal/form-select-patient-modal.component';
import { FormEpisodeService } from 'src/app/shared/services/API/form/form-episode.service';
import { FormStatusService } from 'src/app/shared/services/API/form/form-status.service';
import { LocationByModuleService } from 'src/app/shared/services/API/flow/location-by-module.service';
import { GetFormEpisodeReponse } from 'src/app/shared/services/responses/form/get-form-episode.response';
import { DestinationStruct } from 'src/app/shared/services/structs/form/destination.struct';
import { ClassificationRoomStruct } from 'src/app/shared/services/structs/form/classification-room.struct';
import { DestinationTypeEnum } from 'src/app/shared/enum/destination-type.enum';
import { LookupRiskService } from 'src/app/shared/services/API/risk-classification/lookup-risk.service';
import { PriorityColorModel } from 'src/app/shared/services/models/risk-classification/priority-color.model';
import { SearchPatientModalComponent } from './search-patient-modal/search-patient-modal.component';
import { ViaCepService } from 'src/app/shared/services/API/integration/via-cep.service';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-form-register',
  templateUrl: './form-register.component.html',
  styleUrls: ['./form-register.component.css']
})
export class FormRegisterComponent implements OnInit {

  constructor(private formBuilder: UntypedFormBuilder,
    private maskService: MaskService,
    private getPatientByCpfService: GetPatientByCpfService,
    private listPatientByCnsService: ListPatientByCnsService,
    private bedService: BedService,
    private utilService: UtilService,
    private protocolService: ProtocolService,
    private flowchartService: FlowchartService,
    private discriminatorService: DiscriminatorService,
    private examService: ExamService,
    private lookupService: MedicalRecordLookupService,
    private formStatusService: FormStatusService,
    private formEpisodeService: FormEpisodeService,
    private formPatientService: FormPatientService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private locationByModuleService: LocationByModuleService,
    private lookupRiskService: LookupRiskService,
    public dialog: MatDialog,
    public viaCepService: ViaCepService,) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.form;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.form_episode_list;

  public idEpisode: number;
  public isUpdate: boolean;

  public model: UntypedFormGroup;
  public isLoading: boolean;
  public isEditable: boolean;
  public isFirstLoading: boolean;
  public isUnidentifiedPatient: boolean;
  public isCovidSuspect: boolean;
  public isPriority: boolean;
  public patientAge: string;
  public timeoutKeySearch: any = null;
  public isLoadingCpf: boolean;
  public idPatient: number;
  public isLoadingCns: boolean;
  public isLoadingCep: boolean;

  public masks: Masks;

  public genderList: Gender[];
  public urlListCid: string = environment.urlApiMedicalRecord + 'cid';
  public selectCid: any;
  public protocolList: ProtocolModel[];
  public flowchartList: FlowchartModel[];
  public discriminatorList: DiscriminatorStruct[];
  public statusList: FormStatus[];
  public listBed: BedStruct[];
  public allDestinationList: DestinationStruct[];
  public classificationRoomList: ClassificationRoomStruct[];
  public medicRoomList: ClassificationRoomStruct[];
  public examList: ExamStruct[];
  public priorityColorsList: PriorityColorModel[];

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.isLoading = false;
    this.isFirstLoading = true;
    this.isUnidentifiedPatient = false;
    this.isCovidSuspect = false;
    this.isPriority = false;
    this.isEditable = true;
    this.isLoadingCep = false;
    this.allDestinationList = [];
    this.listBed = [];
    this.classificationRoomList = [];
    this.medicRoomList = [];
    this.model = this.formBuilder.group({
      patientName: ['', [Validators.required]],
      socialName: [''],
      birthDate: ['', [Validators.required]],
      idGender: [],
      cpf: [''],
      cns: [''],
      zipCode: [''],
      street: [''],
      neighborhood: [''],
      city: [''],
      state: [''],
      country: [''],
      houseNumber: [],
      apartmentNumber: [''],
      origin: [''],
      arrivalType: [''],
      idProtocol: [, [Validators.required]],
      idFlowchart: [],
      idDiscriminator: [],
      idClassificationRoom: [, [Validators.required]],
      idMedicRoom: [],
      idGravity: [, [Validators.required]],
      observationDays: [],
      observationHours: [],
      idConclusionType: [],
      idArrivalReason: [],
      idArrivalType: [],
      idOrigin: [],
      firstChildGroup: this.formBuilder.group({
        idCid: [],
      }),
      idBed: [],
      idModuleEvasion: [],
      idDestination: [],

      datetimeAttendance: [],

      datetimeStartEpisode: ['', [Validators.required]],
      datetimeFinishEpisode: ['', [Validators.required]],
      hourStartEpisode: ['', [Validators.required]],
      hourFinishEpisode: ['', [Validators.required]],

      datetimeStartReception: ['', [Validators.required]],
      datetimeFinishReception: ['', [Validators.required]],
      hourStartReception: ['', [Validators.required]],
      hourFinishReception: ['', [Validators.required]],

      datetimeStartClassification: ['', [Validators.required]],
      datetimeFinishClassification: ['', [Validators.required]],
      hourStartClassification: ['', [Validators.required]],
      hourFinishClassification: ['', [Validators.required]],

      datetimeStartReclassification: [],
      datetimeFinishReclassification: [],
      hourStartReclassification: [],
      hourFinishReclassification: [],

      datetimeStartObservation: [],
      datetimeFinishObservation: [],
      hourStartObservation: [],
      hourFinishObservation: [],

      datetimeEvasion: [],
      hourEvasion: [],

      datetimeStartMedicalAtt: [],
      datetimeFinishMedicalAtt: [],
      hourStartMedicalAtt: [],
      hourFinishMedicalAtt: [],

      datetimeStartReturnMedicalAtt: [],
      datetimeFinishReturnMedicalAtt: [],
      hourStartReturnMedicalAtt: [],
      hourFinishReturnMedicalAtt: [],

      datetimeStartBed: [],
      datetimeFinishBed: [],
      hourStartBed: [],
      hourFinishBed: [],

      listStatus: [[]],
      listExam: [[]]
    });

    this.populateGender();
    this.populateProtocol();
    this.populateStatus();
    this.populateForward();
    this.populateExam();
    this.populateGravity();
    this.populateBed();

    this.model.get('idDiscriminator').disable();
    this.model.get('idFlowchart').disable();



    if (this.activatedRoute.snapshot.paramMap.get('idEpisode'))
      this.idEpisode = parseInt(this.activatedRoute.snapshot.paramMap.get('idEpisode'));

    if (this.idEpisode != null) {
      this.isFirstLoading = true;
      this.isUpdate = true;
      this.updateDisable();
      this.populateFormEpisodeData(this.idEpisode);
    }
    else
      this.openModalSearchByCPF();
  }

  updateDisable() {
    this.model.get('patientName').disable();
    this.model.get('patientName').disable();
    this.model.get('socialName').disable();
    this.model.get('cpf').disable();
    this.model.get('birthDate').disable();
    this.model.get('idGender').disable();
    this.model.get('cns').disable();
    this.model.get('zipCode').disable();
    this.model.get('street').disable();
    this.model.get('neighborhood').disable();
    this.model.get('city').disable();
    this.model.get('state').disable();
    this.model.get('country').disable();
    this.model.get('houseNumber').disable();
    this.model.get('apartmentNumber').disable();
  }

  submit() {
    if (this.isLoading) {
      return;
    }

    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    if (!this.validateTime(this.model.get('observationHours').value)) {
      return;
    }

    this.isLoading = true;

    if (this.idEpisode == null)
      this.createFormEpisode()
    else
      this.updateFormEpisode();
  }

  createFormEpisode() {

    if (this.isUnidentifiedPatient) {
      this.newEpisode(null)
      return;
    }

    let requestPatient: FormPatientRequest = new FormPatientRequest();
    requestPatient.idPatient = this.idPatient ? this.idPatient : null;
    requestPatient.patientName = this.model.get('patientName').value;
    requestPatient.socialName = this.model.get('socialName').value;
    requestPatient.cpf = this.model.get('cpf').value.replace(/[^0-9]+/g, '');
    requestPatient.cns = this.model.get('cns').value;
    requestPatient.birthDate = this.maskService.formatStringToDate(this.model.get('birthDate').value);
    requestPatient.idGender = this.model.get('idGender').value;
    requestPatient.zipCode = this.model.get('zipCode').value;
    requestPatient.street = this.model.get('street').value
    requestPatient.neighborhood = this.model.get('neighborhood').value
    requestPatient.city = this.model.get('city').value
    requestPatient.state = this.model.get('state').value
    requestPatient.country = this.model.get('country').value
    requestPatient.houseNumber = this.model.get('houseNumber').value
    requestPatient.apartmentNumber = this.model.get('apartmentNumber').value

    this.formPatientService.savePatient(requestPatient).subscribe({
      next:(response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        this.newEpisode(response.idPatient)
      },
      error:(error) => {
        this.isLoading = false;
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  updateFormEpisode() {
    let formEpisodeRequest: FormEpisodeRequest = new FormEpisodeRequest();
    formEpisodeRequest.idPatient = this.idPatient;
    formEpisodeRequest.origin = this.model.get('origin').value;
    formEpisodeRequest.arrivalType = this.model.get('arrivalType').value;
    formEpisodeRequest.idProtocol = this.model.get('idProtocol').value;
    formEpisodeRequest.idFlowchart = this.model.get('idFlowchart').value;
    formEpisodeRequest.idDiscriminator = this.model.get('idDiscriminator').value;
    formEpisodeRequest.idGravity = this.model.get('idGravity').value;
    formEpisodeRequest.idConclusionType = this.model.get('idConclusionType').value;
    formEpisodeRequest.idArrivalReason = this.model.get('idArrivalReason').value;
    formEpisodeRequest.idArrivalType = this.model.get('idArrivalType').value;
    formEpisodeRequest.idOrigin = this.model.get('idOrigin').value;
    formEpisodeRequest.idModuleEvasion = this.model.get('idModuleEvasion').value;
    formEpisodeRequest.idClassificationRoom = this.model.get('idClassificationRoom').value;
    formEpisodeRequest.idMedicRoom = this.model.get('idMedicRoom').value;
    formEpisodeRequest.idBed = this.model.get('idBed').value;
    formEpisodeRequest.idCid = this.selectCid
    formEpisodeRequest.isPriority = this.isPriority;
    formEpisodeRequest.isCovidSuspect = this.isCovidSuspect;

    if (this.model.get('observationHours').value) {
      let time = this.model.get('observationHours').value.split(':');
      formEpisodeRequest.observationHours = parseInt(time[0]);
      formEpisodeRequest.observationMinutes = parseInt(time[1]);
      formEpisodeRequest.observationSeconds = parseInt(time[2]);
    }

    formEpisodeRequest.observationDays = this.model.get('observationDays').value;
    formEpisodeRequest.datetimeStartAttendance = this.model.get('datetimeStartEpisode').value;

    formEpisodeRequest.datetimeStartEpisode = this.model.get('datetimeStartEpisode').value;
    formEpisodeRequest.datetimeFinishEpisode = this.model.get('datetimeFinishEpisode').value;
    formEpisodeRequest.hourStartEpisode = this.formatHour(this.model.get('hourStartEpisode').value?.toString());
    formEpisodeRequest.hourFinishEpisode = this.formatHour(this.model.get('hourFinishEpisode').value?.toString());

    formEpisodeRequest.datetimeStartReception = this.model.get('datetimeStartReception').value;
    formEpisodeRequest.datetimeFinishReception = this.model.get('datetimeFinishReception').value;
    formEpisodeRequest.hourStartReception = this.formatHour(this.model.get('hourStartReception').value?.toString());
    formEpisodeRequest.hourFinishReception = this.formatHour(this.model.get('hourFinishReception').value?.toString());

    formEpisodeRequest.datetimeStartClassification = this.model.get('datetimeStartClassification').value;
    formEpisodeRequest.datetimeFinishClassification = this.model.get('datetimeFinishClassification').value;
    formEpisodeRequest.hourStartClassification = this.formatHour(this.model.get('hourStartClassification').value?.toString());
    formEpisodeRequest.hourFinishClassification = this.formatHour(this.model.get('hourFinishClassification').value?.toString());

    formEpisodeRequest.datetimeStartReclassification = this.model.get('datetimeStartReclassification').value;
    formEpisodeRequest.datetimeFinishReclassification = this.model.get('datetimeFinishReclassification').value;
    formEpisodeRequest.hourStartReclassification = this.formatHour(this.model.get('hourStartReclassification').value?.toString());
    formEpisodeRequest.hourFinishReclassification = this.formatHour(this.model.get('hourFinishReclassification').value?.toString());

    formEpisodeRequest.datetimeEvasion = this.model.get('datetimeEvasion').value;
    formEpisodeRequest.hourEvasion = this.formatHour(this.model.get('hourEvasion').value?.toString());

    formEpisodeRequest.datetimeStartMedicalAtt = this.model.get('datetimeStartMedicalAtt').value;
    formEpisodeRequest.datetimeFinishMedicalAtt = this.model.get('datetimeFinishMedicalAtt').value;
    formEpisodeRequest.hourStartMedicalAtt = this.formatHour(this.model.get('hourStartMedicalAtt').value?.toString());
    formEpisodeRequest.hourFinishMedicalAtt = this.formatHour(this.model.get('hourFinishMedicalAtt').value?.toString());

    formEpisodeRequest.datetimeStartReturnMedicalAtt = this.model.get('datetimeStartReturnMedicalAtt').value;
    formEpisodeRequest.datetimeFinishReturnMedicalAtt = this.model.get('datetimeFinishReturnMedicalAtt').value;
    formEpisodeRequest.hourStartReturnMedicalAtt = this.formatHour(this.model.get('hourStartReturnMedicalAtt').value?.toString());
    formEpisodeRequest.hourFinishReturnMedicalAtt = this.formatHour(this.model.get('hourFinishReturnMedicalAtt').value?.toString());

    formEpisodeRequest.datetimeStartBed = this.model.get('datetimeStartBed').value;
    formEpisodeRequest.datetimeFinishBed = this.model.get('datetimeFinishBed').value;
    formEpisodeRequest.hourStartBed = this.formatHour(this.model.get('hourStartBed').value?.toString());
    formEpisodeRequest.hourFinishBed = this.formatHour(this.model.get('hourFinishBed').value?.toString());


    formEpisodeRequest.idDestination = this.model.get('idDestination').value;
    formEpisodeRequest.listIdExam = this.model.get('listExam').value;
    formEpisodeRequest.listIdStatus = this.model.get('listStatus').value;
    this.formEpisodeService.updateEpisode(formEpisodeRequest, this.idEpisode).subscribe({
      next:(response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        this.router.navigate(['/form/form-episode-list']);
      },
      error:(error) => {
        this.isLoading = false;
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  formatHour(hour: string): string {
    if (!hour) return;
    return hour.slice(0, 2) + ":" + hour.slice(2, 4);
  }

  newEpisode(idPatient: number) {
    let formEpisodeRequest: FormEpisodeRequest = new FormEpisodeRequest();
    formEpisodeRequest.idPatient = idPatient;
    formEpisodeRequest.origin = this.model.get('origin').value;
    formEpisodeRequest.arrivalType = this.model.get('arrivalType').value;
    formEpisodeRequest.idProtocol = this.model.get('idProtocol').value;
    formEpisodeRequest.idFlowchart = this.model.get('idFlowchart').value;
    formEpisodeRequest.idDiscriminator = this.model.get('idDiscriminator').value;
    formEpisodeRequest.idGravity = this.model.get('idGravity').value;
    formEpisodeRequest.idConclusionType = this.model.get('idConclusionType').value;
    formEpisodeRequest.idArrivalReason = this.model.get('idArrivalReason').value;
    formEpisodeRequest.idArrivalType = this.model.get('idArrivalType').value;
    formEpisodeRequest.idOrigin = this.model.get('idOrigin').value;
    formEpisodeRequest.idModuleEvasion = this.model.get('idModuleEvasion').value;
    formEpisodeRequest.idClassificationRoom = this.model.get('idClassificationRoom').value;
    formEpisodeRequest.idMedicRoom = this.model.get('idMedicRoom').value;
    formEpisodeRequest.idBed = this.model.get('idBed').value;

    formEpisodeRequest.idCid = this.selectCid;
    formEpisodeRequest.isPriority = this.isPriority;
    formEpisodeRequest.isCovidSuspect = this.isCovidSuspect;

    if (this.model.get('observationHours').value) {
      let time = this.model.get('observationHours').value.split(':');
      formEpisodeRequest.observationHours = parseInt(time[0]);
      formEpisodeRequest.observationMinutes = parseInt(time[1]);
      formEpisodeRequest.observationSeconds = parseInt(time[2]);
    }

    formEpisodeRequest.observationDays = this.model.get('observationDays').value;
    formEpisodeRequest.datetimeStartAttendance = this.model.get('datetimeStartEpisode').value?.toISOString();

    formEpisodeRequest.datetimeStartEpisode = this.model.get('datetimeStartEpisode').value?.toISOString();
    formEpisodeRequest.datetimeFinishEpisode = this.model.get('datetimeFinishEpisode').value?.toISOString();
    formEpisodeRequest.hourStartEpisode = this.formatHour(this.model.get('hourStartEpisode').value?.toString());
    formEpisodeRequest.hourFinishEpisode = this.formatHour(this.model.get('hourFinishEpisode').value?.toString());

    formEpisodeRequest.datetimeStartReception = this.model.get('datetimeStartReception').value?.toISOString();
    formEpisodeRequest.datetimeFinishReception = this.model.get('datetimeFinishReception').value?.toISOString();
    formEpisodeRequest.hourStartReception = this.formatHour(this.model.get('hourStartReception').value?.toString());
    formEpisodeRequest.hourFinishReception = this.formatHour(this.model.get('hourFinishReception').value?.toString());

    formEpisodeRequest.datetimeStartClassification = this.model.get('datetimeStartClassification').value?.toISOString();
    formEpisodeRequest.datetimeFinishClassification = this.model.get('datetimeFinishClassification').value?.toISOString();
    formEpisodeRequest.hourStartClassification = this.formatHour(this.model.get('hourStartClassification').value?.toString());
    formEpisodeRequest.hourFinishClassification = this.formatHour(this.model.get('hourFinishClassification').value?.toString());

    formEpisodeRequest.datetimeStartReclassification = this.model.get('datetimeStartReclassification').value?.toISOString();
    formEpisodeRequest.datetimeFinishReclassification = this.model.get('datetimeFinishReclassification').value?.toISOString();
    formEpisodeRequest.hourStartReclassification = this.formatHour(this.model.get('hourStartReclassification').value?.toString());
    formEpisodeRequest.hourFinishReclassification = this.formatHour(this.model.get('hourFinishReclassification').value?.toString());

    formEpisodeRequest.datetimeEvasion = this.model.get('datetimeEvasion').value?.toISOString();
    formEpisodeRequest.hourEvasion = this.formatHour(this.model.get('hourEvasion').value?.toString());

    formEpisodeRequest.datetimeStartMedicalAtt = this.model.get('datetimeStartMedicalAtt').value?.toISOString();
    formEpisodeRequest.datetimeFinishMedicalAtt = this.model.get('datetimeFinishMedicalAtt').value?.toISOString();
    formEpisodeRequest.hourStartMedicalAtt = this.formatHour(this.model.get('hourStartMedicalAtt').value?.toString());
    formEpisodeRequest.hourFinishMedicalAtt = this.formatHour(this.model.get('hourFinishMedicalAtt').value?.toString());

    formEpisodeRequest.datetimeStartReturnMedicalAtt = this.model.get('datetimeStartReturnMedicalAtt').value?.toISOString();
    formEpisodeRequest.datetimeFinishReturnMedicalAtt = this.model.get('datetimeFinishReturnMedicalAtt').value?.toISOString();
    formEpisodeRequest.hourStartReturnMedicalAtt = this.formatHour(this.model.get('hourStartReturnMedicalAtt').value?.toString());
    formEpisodeRequest.hourFinishReturnMedicalAtt = this.formatHour(this.model.get('hourFinishReturnMedicalAtt').value?.toString());

    formEpisodeRequest.datetimeStartBed = this.model.get('datetimeStartBed').value?.toISOString();
    formEpisodeRequest.datetimeFinishBed = this.model.get('datetimeFinishBed').value?.toISOString();
    formEpisodeRequest.hourStartBed = this.formatHour(this.model.get('hourStartBed').value?.toString());
    formEpisodeRequest.hourFinishBed = this.formatHour(this.model.get('hourFinishBed').value?.toString());

    formEpisodeRequest.idDestination = this.model.get('idDestination').value;
    formEpisodeRequest.listIdExam = this.model.get('listExam').value;
    formEpisodeRequest.listIdStatus = this.model.get('listStatus').value;
    this.formEpisodeService.createEpisode(formEpisodeRequest).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.isLoading = false;
      this.router.navigate(['/form/form-episode-list']);
    },
      (error) => {
        this.isLoading = false;
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  setReadOnly() {
    this.model.get('patientName').disable();
    this.model.get('socialName').disable();
    this.model.get('birthDate').disable();
    this.model.get('idGender').disable();
    this.model.get('cpf').disable();
    this.model.get('cns').disable();
    this.model.get('zipCode').disable();
    this.model.get('origin').disable();
    this.model.get('arrivalType').disable();

    this.model.get('idProtocol').disable();
    this.model.get('idFlowchart').disable();
    this.model.get('idDiscriminator').disable();
    this.model.get('idGravity').disable();
    this.model.get('idArrivalType').disable();
    this.model.get('idArrivalReason').disable();
    this.model.get('idClassificationRoom').disable();
    this.model.get('idMedicRoom').disable();
    this.model.get('idOrigin').disable();
    this.model.get('firstChildGroup').get('idCid').disable();
    this.model.get('idBed').disable();
    this.model.get('idModuleEvasion').disable();
    this.model.get('observationDays').disable();
    this.model.get('observationHours').disable();
    this.model.get('idConclusionType').disable();
    //this.model.get('isPriority').disable();
    //this.model.get('isCovidSuspect').disable();

    this.model.get('datetimeAttendance').disable();

    this.model.get('datetimeStartEpisode').disable();
    this.model.get('datetimeFinishEpisode').disable();
    this.model.get('hourStartEpisode').disable();
    this.model.get('hourFinishEpisode').disable();

    this.model.get('datetimeStartReception').disable();
    this.model.get('datetimeFinishReception').disable();
    this.model.get('hourStartReception').disable();
    this.model.get('hourFinishReception').disable();

    this.model.get('datetimeStartClassification').disable();
    this.model.get('datetimeFinishClassification').disable();
    this.model.get('hourStartClassification').disable();
    this.model.get('hourFinishClassification').disable();

    this.model.get('datetimeStartReclassification').disable();
    this.model.get('datetimeFinishReclassification').disable();
    this.model.get('hourStartReclassification').disable();
    this.model.get('hourFinishReclassification').disable();

    this.model.get('datetimeEvasion').disable();
    this.model.get('hourEvasion').disable();

    this.model.get('datetimeStartMedicalAtt').disable();
    this.model.get('datetimeFinishMedicalAtt').disable();
    this.model.get('hourStartMedicalAtt').disable();
    this.model.get('hourFinishMedicalAtt').disable();

    this.model.get('datetimeStartReturnMedicalAtt').disable();
    this.model.get('datetimeFinishReturnMedicalAtt').disable();
    this.model.get('hourStartReturnMedicalAtt').disable();
    this.model.get('hourFinishReturnMedicalAtt').disable();

    this.model.get('datetimeStartBed').disable();
    this.model.get('datetimeFinishBed').disable();
    this.model.get('hourStartBed').disable();
    this.model.get('hourFinishBed').disable();

    this.model.get('listStatus').disable();
    this.model.get('idDestination').disable();
    this.model.get('listExam').disable();
    this.model.get('street').disable();
    this.model.get('neighborhood').disable();
    this.model.get('city').disable();
    this.model.get('state').disable();
    this.model.get('country').disable();
    this.model.get('houseNumber').disable();
    this.model.get('apartmentNumber').disable();
  }

  populateFormEpisodeData(idEpisode: number) {
    this.formEpisodeService.getFormEpisode(idEpisode).subscribe({
      next:(response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isFirstLoading = false;
          return;
        }
  
        if (!response.isEditable) {
          this.setReadOnly();
          this.isEditable = false;
        }
  
        this.mapEpisodeModel(response);
  
        if (response.idPatient)
          this.populatePatientData(response.idPatient);
        else {
          this.isUnidentifiedPatient = true;
          this.setDisablePatient(true);
        }
  
        this.isFirstLoading = false;
      },
      error:(error) => {
        this.isLoading = false;
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populatePatientData(idPatient: number) {
    this.formPatientService.getPatient(idPatient).subscribe({
      next:(response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isFirstLoading = false;
          return;
        }
        this.mapPatientModel(response.patient)
  
        this.isFirstLoading = false;
      },
      error:(error) => {
        this.isLoading = false;
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openModalSearchByCPF() {
    const dialogRef = this.dialog.open(SearchPatientModalComponent);
    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        if (response.length == 1) {
          this.mapPatientModel(response[0]);
        } else if (response.length > 1) {
          this.openModal(response);
        }
      }
    });
    return dialogRef;
  }

  populateExam() {
    this.examService.listAllExam(null).subscribe({
      next:(response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isFirstLoading = false;
          return;
        }
        this.examList = response.listExam;
      },
      error:(error) => {
        this.isLoading = false;
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateBed() {
    this.bedService.listBedGetAll().subscribe({next:(response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isFirstLoading = false;
        return;
      }
      this.listBed = response.listBedStruct;
    },
    error:(error) => {
      this.isLoading = false;
      console.log(error)
      this.alertService.show('Erro inesperado', error, AlertType.error);
    }});
  }

  populateForward() {
    this.locationByModuleService.listRoomByModule(MenuModuleEnum.sadt).subscribe({
      next:(response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isFirstLoading = false;
          return;
        }
        response.sectors.forEach(element => {
          let forward: DestinationStruct = new DestinationStruct();
          forward.idDestination = element.idSector;
          forward.idDestinationType = DestinationTypeEnum.Setor;
          forward.destinationName = element.sectorName;
          this.allDestinationList.push(forward);
        });
        response.rooms.forEach(element => {
          let forward: DestinationStruct = new DestinationStruct();
          forward.idDestination = element.idRoom;
          forward.idDestinationType = DestinationTypeEnum.Sala;
          forward.destinationName = element.roomName;
          this.allDestinationList.push(forward);
        });
      },
      error:(error) => {
        this.isLoading = false;
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
    this.locationByModuleService.listRoomByModule(MenuModuleEnum.observation).subscribe({
      next:(response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isFirstLoading = false;
          return;
        }
        response.sectors.forEach(element => {
          let forward: DestinationStruct = new DestinationStruct();
          forward.idDestination = element.idSector;
          forward.idDestinationType = DestinationTypeEnum.Setor;
          forward.destinationName = element.sectorName;
          this.allDestinationList.push(forward);
        });
        response.rooms.forEach(element => {
          let destination: DestinationStruct = new DestinationStruct();
          destination.idDestination = element.idRoom;
          destination.idDestinationType = DestinationTypeEnum.Sala;
          destination.destinationName = element.roomName;
          this.allDestinationList.push(destination);
        });
      },
      error:(error) => {
        this.isLoading = false;
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
    this.locationByModuleService.listRoomByModule(MenuModuleEnum.classification).subscribe({
      next:(response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isFirstLoading = false;
          return;
        }
        response.rooms.forEach(element => {
          let classificationRoom: ClassificationRoomStruct = new ClassificationRoomStruct();
          classificationRoom.idRoom = element.idRoom;
          classificationRoom.roomName = element.roomName;
          this.classificationRoomList.push(classificationRoom);
        });
      },
      error:(error) => {
        this.isLoading = false;
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });

    this.locationByModuleService.listRoomByModule(MenuModuleEnum.medic).subscribe({
      next:(response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isFirstLoading = false;
          return;
        }
        response.rooms.forEach(element => {
          let medicRoom: ClassificationRoomStruct = new ClassificationRoomStruct();
          medicRoom.idRoom = element.idRoom;
          medicRoom.roomName = element.roomName;
          this.medicRoomList.push(medicRoom);
        });
      },
      error:(error) => {
        this.isLoading = false;
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateStatus() {
    this.formStatusService.getAllFormStatus().subscribe({
      next:(response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isFirstLoading = false;
          return;
        }
        this.statusList = response.listFormStatus;
      },
      error:(error) => {
        this.isLoading = false;
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateDiscriminator(idFlowchart: number, idGravity: number) {
    this.discriminatorService.getDiscriminator(idFlowchart, null, idGravity).subscribe({
      next:(response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isFirstLoading = false;
          return;
        }
        this.discriminatorList = response.discriminator;
        if (this.isEditable)
          this.model.get('idDiscriminator').enable();
      },
      error:(error) => {
        this.isLoading = false;
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateGravity() {
    this.lookupRiskService.getLookup().subscribe({
      next:(response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isFirstLoading = false;
          return;
        }
        this.priorityColorsList = response.priorityColors;
      },
      error:(error) => {
        this.isLoading = false;
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateFlowchart(idProtocol: number) {
    let request: SearchFlowchartRequest = new SearchFlowchartRequest();
    request.idProtocol = idProtocol;

    this.flowchartService.getFlowchart(request).subscribe({
      next:(response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isFirstLoading = false;
          return;
        }
        this.flowchartList = response.flowcharts;
        if (this.isEditable)
          this.model.get('idFlowchart').enable();
  
      },
      error:(error) => {
        this.isLoading = false;
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateProtocol() {
    this.protocolService.listProtocol().subscribe({
      next:(response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isFirstLoading = false;
          return;
        }
        this.protocolList = response.listProtocol;
      },
      error:(error) => {
        this.isLoading = false;
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateGender() {
    this.lookupService.getLookup().subscribe({
      next:(response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isFirstLoading = false;
          return;
        }
        this.genderList = response.listGenders;
        this.isFirstLoading = false;
      },
      error:(error) => {
        this.isLoading = false;
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  changeProtocol(event: any) {
    this.populateFlowchart(event);
    this.model.get('idFlowchart').setValidators([Validators.required]);
    this.model.get('idFlowchart').updateValueAndValidity();
  }

  changeFlowchartOrGravity() {
    if (!this.model.get('idFlowchart').value || !this.model.get('idGravity').value)
      return;
    this.populateDiscriminator(this.model.get('idFlowchart').value, this.model.get('idGravity').value);
  }

  onKeySearchCPF(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;

    let cpf = event.target.value.replace(/[^0-9]+/g, '');

    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13 && cpf.length == 11) {
        $this.searchByCPF(cpf);
      }
    }, 1000);
  }

  searchByCPF(cpf: string) {
    this.isLoading = true;
    this.isLoadingCpf = true;
    this.getPatientByCpfService.getPatientByCPF(cpf.replace(/[^0-9]+/g, '')).subscribe({
      next:(response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          this.isLoadingCpf = false;
          return;
        }
        this.mapPatientModel(response.patient);
        this.isLoading = false;
        this.isLoadingCpf = false;
      },
      error:(error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  onKeySearchCEP(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13 && event.target.value.length > 5) {
        $this.searchByCEP(event.target.value);
      }
    }, 1000);
  }

  searchByCEP(cep: string) {
    this.isLoadingCep = true;
    this.isLoading = true;
    this.viaCepService.getAddressByCep(cep).subscribe({
      next:(response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoadingCep = false;
          this.isLoading = false;
          return;
        }
        this.model.get('zipCode').setValue(response.cep);
        this.model.get('street').setValue(response.logradouro);
        this.model.get('neighborhood').setValue(response.bairro);
        this.model.get('city').setValue(response.localidade);
        this.model.get('state').setValue(response.uf);
        this.model.get('country').setValue("Brasil");
        this.isLoadingCep = false;
        this.isLoading = false;
      },
      error:(error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  onKeySearchAge(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13 && event.target.value.length > 5) {
        let patientAge = $this.utilService.getAge(event.target.value);
        $this.model.get('patientAge').setValue(`${patientAge.years}a ${patientAge.months}m ${patientAge.days}d`);
      }
    }, 1000);
  }

  mapPatientModel(patient: PatientStruct) {
    this.model.get('patientName').setValue(patient.patientName);
    this.model.get('socialName').setValue(patient.socialName);
    this.model.get('cpf').setValue(patient.cpf);
    this.model.get('birthDate').setValue(this.maskService.formatDateToString(patient.birthDate, false));
    this.model.get('idGender').setValue(patient.idGender ? patient.idGender.toString() : null);
    this.model.get('cns').setValue(patient.cns);
    this.utilService.getAge(this.model.get('birthDate').value);
    this.model.get('zipCode').setValue(patient.zipCode);
    this.model.get('street').setValue(patient.street);
    this.model.get('neighborhood').setValue(patient.neighborhood);
    this.model.get('city').setValue(patient.city);
    this.model.get('state').setValue(patient.state);
    this.model.get('country').setValue(patient.country);
    this.model.get('houseNumber').setValue(patient.houseNumber);
    this.model.get('apartmentNumber').setValue(patient.apartmentNumber);
  }

  mapEpisodeModel(episode: GetFormEpisodeReponse) {
    this.idPatient = episode.idPatient;
    this.model.get('origin').setValue(episode.origin);
    this.model.get('arrivalType').setValue(episode.arrivalType);
    this.model.get('idProtocol').setValue(episode.idProtocol?.toString());

    this.model.get('idFlowchart').setValue(episode.idFlowchart?.toString());
    if (episode.idProtocol)
      this.populateFlowchart(episode.idProtocol);

    this.model.get('idDiscriminator').setValue(episode.idDiscriminator?.toString());
    this.model.get('idGravity').setValue(episode.idGravity?.toString());
    this.model.get('idOrigin').setValue(episode.idOrigin?.toString());
    this.model.get('idArrivalType').setValue(episode.idArrivalType?.toString());
    this.model.get('idArrivalReason').setValue(episode.idArrivalReason?.toString());
    this.model.get('idModuleEvasion').setValue(episode.idModuleEvasion?.toString());
    this.model.get('idClassificationRoom').setValue(episode.idClassificationRoom?.toString());
    this.model.get('idMedicRoom').setValue(episode.idMedicRoom?.toString());
    this.model.get('idBed').setValue(episode.idBed?.toString());
    this.model.get('idDestination').setValue(episode.idDestination?.toString());
    this.model.get('listStatus').setValue(episode.listFormStatus);
    this.model.get('listExam').setValue(episode.listExam);

    this.addItemCidOnUpdate(episode.idCid)
    this.isCovidSuspect = episode.isCovidSuspect;
    this.isPriority = episode.isPriority;
    this.selectCid = episode.idCid;

    if (episode.idFlowchart)
      this.populateDiscriminator(episode.idFlowchart, episode.idGravity);

    this.model.get('idConclusionType').setValue(episode.idConclusionType?.toString());
    this.model.get('observationHours').setValue(episode.observationHours?.toString());
    this.model.get('observationDays').setValue(episode.observationDays?.toString());
    this.model.get('datetimeAttendance').setValue(episode.datetimeStartAttendance);

    this.model.get('datetimeStartEpisode').setValue(episode.datetimeStartEpisode);
    this.model.get('datetimeFinishEpisode').setValue(episode.datetimeFinishEpisode);
    this.model.get('hourStartEpisode').setValue(episode.hourStartEpisode?.toString());
    this.model.get('hourFinishEpisode').setValue(episode.hourFinishEpisode?.toString());

    this.model.get('datetimeStartReception').setValue(episode.datetimeStartReception);
    this.model.get('datetimeFinishReception').setValue(episode.datetimeFinishReception);
    this.model.get('hourStartReception').setValue(episode.hourStartReception?.toString());
    this.model.get('hourFinishReception').setValue(episode.hourFinishReception?.toString());

    this.model.get('datetimeStartClassification').setValue(episode.datetimeStartClassification);
    this.model.get('datetimeFinishClassification').setValue(episode.datetimeFinishClassification);
    this.model.get('hourStartClassification').setValue(episode.hourStartClassification?.toString());
    this.model.get('hourFinishClassification').setValue(episode.hourFinishClassification?.toString());

    this.model.get('datetimeStartReclassification').setValue(episode.datetimeStartReclassification);
    this.model.get('datetimeFinishReclassification').setValue(episode.datetimeFinishReclassification);
    this.model.get('hourStartReclassification').setValue(episode.hourStartReclassification?.toString());
    this.model.get('hourFinishReclassification').setValue(episode.hourFinishReclassification?.toString());

    this.model.get('datetimeEvasion').setValue(episode.datetimeEvasion);
    this.model.get('hourEvasion').setValue(episode.hourEvasion?.toString());

    this.model.get('datetimeStartMedicalAtt').setValue(episode.datetimeStartMedicalAtt);
    this.model.get('datetimeFinishMedicalAtt').setValue(episode.datetimeFinishMedicalAtt);
    this.model.get('hourStartMedicalAtt').setValue(episode.hourStartMedicalAtt?.toString());
    this.model.get('hourFinishMedicalAtt').setValue(episode.hourFinishMedicalAtt?.toString());

    this.model.get('datetimeStartReturnMedicalAtt').setValue(episode.datetimeStartReturnMedicalAtt);
    this.model.get('datetimeFinishReturnMedicalAtt').setValue(episode.datetimeFinishReturnMedicalAtt);
    this.model.get('hourStartReturnMedicalAtt').setValue(episode.hourStartReturnMedicalAtt?.toString());
    this.model.get('hourFinishReturnMedicalAtt').setValue(episode.hourFinishReturnMedicalAtt?.toString());

    this.model.get('datetimeStartBed').setValue(episode.datetimeStartBed);
    this.model.get('datetimeFinishBed').setValue(episode.datetimeFinishBed);
    this.model.get('hourStartBed').setValue(episode.hourStartBed?.toString());
    this.model.get('hourFinishBed').setValue(episode.hourFinishBed?.toString());
  }

  onKeySearchCNS(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13 && event.target.value.length > 5)
        $this.searchByCNS(event.target.value);
    }, 1000);
  }

  searchByCNS(cns: string) {
    this.isLoading = true;
    this.isLoadingCns = true;
    this.listPatientByCnsService.getPatientByCNS(cns).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          this.isLoadingCns = false;
          return;
        }

        if (response.listPatient.length == 1)
          this.mapPatientModel(response.listPatient[0]);
        else if (response.listPatient.length > 1)
          this.openModal(response.listPatient);

        this.isLoading = false;
        this.isLoadingCns = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openModal(patients: PatientStruct[]) {
    const dialogRef = this.dialog.open(FormSelectPatientModalComponent, {
      data: patients,
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result)
          this.mapPatientModel(result);
      }
    });
  }

  changeIndigent(event: any) {
    this.setDisablePatient(event.checked);
  }

  setDisablePatient(isIndigent: boolean) {
    if (isIndigent) {
      this.model.get('patientName').disable();
      this.model.get('socialName').disable();
      this.model.get('cpf').disable();
      this.model.get('birthDate').disable();
      this.model.get('idGender').disable();
      this.model.get('cns').disable();
      this.model.get('zipCode').disable();
      this.model.get('street').disable();
      this.model.get('neighborhood').disable();
      this.model.get('city').disable();
      this.model.get('state').disable();
      this.model.get('country').disable();
      this.model.get('houseNumber').disable();
      this.model.get('apartmentNumber').disable();
    }
    else {
      this.model.get('patientName').enable();
      this.model.get('socialName').enable();
      this.model.get('cpf').enable();
      this.model.get('birthDate').enable();
      this.model.get('idGender').enable();
      this.model.get('cns').enable();
      this.model.get('zipCode').enable();
      this.model.get('street').enable();
      this.model.get('neighborhood').enable();
      this.model.get('city').enable();
      this.model.get('state').enable();
      this.model.get('country').enable();
      this.model.get('houseNumber').enable();
      this.model.get('apartmentNumber').enable();
    }
  }

  validateTime(fullTime: string): boolean {
    if (!fullTime || fullTime.replace("_", "") == "::")
      return true;

    let time = fullTime.split(':');

    time.forEach(element => {
      if (element.includes('_'))
        this.alertService.show('Erro', "O padrão do campo horas é 01:01:01.", AlertType.error);
    });

    if (parseInt(time[0]) > 23) {
      this.alertService.show('Erro', "Valor máximo de horas é 23.", AlertType.error);
      return false;
    }

    if (parseInt(time[1]) > 59) {
      this.alertService.show('Erro', "Valor máximo de minutos é 59.", AlertType.error);
      return false;
    }

    if (parseInt(time[2]) > 59) {
      this.alertService.show('Erro', "Valor máximo de segundos é 59.", AlertType.error);
      return false;
    }

    return true;
  }

  addItemCidOnUpdate(id: any) {
    this.selectCid = id;
  }

  addItemCid(event: any) {
    this.selectCid = event.idCid;
  }
}