import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { GetMedicineResponse } from '../../responses/pharmacy/get-medicine.response';
import { ListMedicineByIdResponse } from '../../responses/pharmacy/list-medicine-type-response copy';

@Injectable({
  providedIn: 'root'
})
export class GetMedicineDataService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getMedicine(idMedicine: number): Observable<GetMedicineResponse> {
    let uri = `GetMedicineData/idMedicine/${idMedicine}`;

    return this.httpClient.get<GetMedicineResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getListMedicine(listIdMedicine: number[]): Observable<ListMedicineByIdResponse> {
    let uri = `GetMedicineData`;

    return this.httpClient.post<ListMedicineByIdResponse>(environment.urlApiPharmacy + uri, listIdMedicine, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

}
