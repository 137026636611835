<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-modal-list">
            <h2>Observação Clínica</h2>
        </div>

        <div class="body-modal-list">
            <form class="form" [formGroup]="model">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12">
                        <span><strong>Médico: </strong>{{(listObservation && listObservation.length >0)?listObservation[0].userAttendantName : ""}}</span>
                    </div>
                </div>
                <div class="row" class="textarea-margin">
                    <div class="col-12 col-sm-12 col-md-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Observação</mat-label>
                            <textarea matInput type="text" formControlName="observation"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <button mat-flat-button color="primary" class="btn-block" type="button" (click)="submit()">
                    <span *ngIf="isLoading == false">Salvar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>           
                <div *ngIf="listObservation">
                    <h2>Histórico de Observações</h2>
                    <cdk-accordion class="profile-register-accordion">
                        <cdk-accordion-item *ngFor="let item of listObservation; let index = index;"
                            #accordionItemModule="cdkAccordionItem" class="profile-register-accordion-item" role="button"
                            tabindex="0" [attr.id]="'accordion-header-'" [attr.aria-expanded]="accordionItemModule.expanded"
                            [attr.aria-controls]="'accordion-body-'">
                            <div [ngClass]="{'profile-register-accordion-item-header-expanded': accordionItemModule.expanded}"
                                class="profile-register-accordion-item-header" (click)="accordionItemModule.toggle()">
                                <div class="row">
                                    <div class="col-12"><strong>{{ item.datetimeInclusion | date:'dd/MM/yy, H:mm'}}</strong></div>
                                    <div class="col-12"><strong>Médico:</strong> {{item.userAttendantName}} </div>
                                </div>
                                <span
                                    [ngClass]="{'profile-register-accordion-item-description-expanded': accordionItemModule.expanded}"
                                    class="profile-register-accordion-item-description">
                                    Clique para {{ accordionItemModule.expanded ? 'fechar' : 'abrir' }}
                                </span>
                            </div>
                            <div class="profile-register-accordion-item-body" role="region"
                                [style.display]="accordionItemModule.expanded ? '' : 'none'" [attr.id]="'accordion-body-' + index" 
                                [attr.aria-labelledby]="'accordion-header-' + index">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="padding-accordeon">
                                            <span><strong>Paciente/Colaborador:</strong>  {{item.patientName}}</span>
                                                <p class="observation-text">{{item.observation}}</p>
                                        </div>   
                                    </div>
                                </div>
                            </div>
                        </cdk-accordion-item>
                    </cdk-accordion>
                </div>
                <button mat-flat-button type="button" color="primary" class="btn-block" (click)="close()"><span>Fechar</span></button>
            </form>
        </div>
    </div>
</div>