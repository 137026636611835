export class ServiceRequest{

    public serviceName: string;

    public isActive: boolean;

    public description: string;
    public ip: string;

    public idModule: number;
    public idFunctionalityGroup: number;
}