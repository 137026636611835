import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { EpisodeStatusEnum } from 'src/app/shared/enum/episode-status.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { AttendOtherQueuePatientService } from 'src/app/shared/services/API/orchestrator-queue/attend-other-queue-patient.service';
import { CallPanelEpisodeRequest } from 'src/app/shared/services/requests/orchestrator-panel/call-panel-episode.request';

@Component({
  selector: 'app-other-queue-patient-modal',
  templateUrl: './other-queue-patient-modal.component.html',
  styleUrls: ['./other-queue-patient-modal.component.css']
})
export class OtherQueuePatientModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<OtherQueuePatientModalComponent>,
    private alertService: AlertService,
    private attendOtherQueuePatientService: AttendOtherQueuePatientService,
  ) { }

  public isLoading:boolean;  
  public isCheckToggle:boolean = false;  
  public activeEpisodeStatus = EpisodeStatusEnum.Iniciado;

  ngOnInit(): void {
    this.isLoading = false;
  }
  
  close(){
    this.matDialogRef.close({isAttend: false}); 
  }
  
  clickConfirm(){

    this.isLoading = true;
    let request = new CallPanelEpisodeRequest();
    request.episodeData = this.data.episodeData;

    this.attendOtherQueuePatientService.attendPatient(this.data.idRoom, this.data.idService, this.data.idEpisode,).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }   
      this.isLoading = false;    
      this.matDialogRef.close({isAttend: true}); 
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }
  
  clickCancel(){
    this.matDialogRef.close({isAttend: false}); 
  }

  checkConfirm(event): boolean{

    return event.value
  }
}