import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { CareLineService } from 'src/app/shared/services/API/sync-measurer/care-line.service';
import { CareLineLookupService } from 'src/app/shared/services/API/sync-measurer/lookup.service';
import { CareLineTypeModel } from 'src/app/shared/services/models/sync-measurer/care-line-type.model';
import { ListedCareLineStruct } from 'src/app/shared/services/structs/sync-measurer/list-care-line.struct';
import { UtilService } from 'src/app/shared/services/util.service';
import { CareLineDeleteModalComponent } from './care-line-delete-modal/care-line-delete-modal.component';

@Component({
  selector: 'app-care-line-list',
  templateUrl: './care-line-list.component.html',
  styleUrls: ['./care-line-list.component.css']
})
export class CareLineListComponent implements OnInit {

  constructor(private careLineService: CareLineService,
    private lookupService: CareLineLookupService,
    private router: Router,
    private alertService: AlertService,
    private utilService: UtilService,
    public dialog: MatDialog) { }
    
    public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.master;
    public menuFunctionalityEnum:MenuFunctionalityEnum = MenuFunctionalityEnum.master_care_line;
    
    public listCareLine: ListedCareLineStruct[];
    public listCareLineType: CareLineTypeModel[];
    public isLoading: boolean;
    public isActive: boolean;
    public searchText: string;
    public idCareLineType: number;

  ngOnInit(): void {
    this.isLoading = true;
    this.populateCareLineTypeSelect();
    this.search();
  }
  search(){
    this.isLoading = true;  
    if(this.searchText == "")
    this.searchText = null;
    
    this.careLineService.listCareLine(this.searchText, this.idCareLineType, this.isActive).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.listCareLine = response.listCareLineStruct;  
    
      this.isLoading = false;    
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  populateCareLineTypeSelect(){
    this.lookupService.listLookup().subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        
        return;
      }
      
      this.listCareLineType = response.listCareLineType;     
    },
    (error)=>{
      console.log(error)    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  private timeoutKeySearch: any = null;
  
  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }
  
  openModal(idCareLine){
    const dialogRef = this.dialog.open(CareLineDeleteModalComponent, {
      data: {
        idCareLine: idCareLine
      },
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.deleteCareLine){
        this.search();
      }
    });
  }
}
