<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <a class="back-link" routerLink="/master/service"><mat-icon aria-hidden="false"
                aria-label="Plus">arrow_back</mat-icon> Serviços</a>
        <h1>Cadastrar serviços</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="title-header">
                    <h1>Dados do serviço</h1>
                </div>

                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome</mat-label>
                            <input matInput type="text" formControlName="name">
                            <mat-error *ngIf="model.get('name').invalid">Informe o nome</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Descrição</mat-label>
                            <input matInput type="text" formControlName="description">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Módulo</mat-label>
                            <mat-select formControlName="module" (selectionChange)="changeSelectModule($event.value)"
                                [disabled]="isDisabledSelect">
                                <mat-option *ngFor="let item of listModule"
                                    [value]="item.idModule">{{item.nameModule}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <p *ngIf="isDisabledSelect">Todos os módulos já estão vinculados à um serviço.</p>
                    </div>
                    <div *ngIf="isDigitalPromptService" class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Tipo de serviço</mat-label>
                            <mat-select formControlName="idFunctionalityGroup" [disabled]="isDisabledSelect">
                                <mat-option *ngFor="let item of listModuleWithDigitalPromptServiceStruct"
                                    [value]="item.idFunctionalityGroup">{{item.functionalityGroupName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <p *ngIf="isDisabledSelect">Todos os módulos já estão vinculados à um serviço.</p>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Status</mat-label>
                            <mat-select formControlName="status">
                                <mat-option value="true">Ativo</mat-option>
                                <mat-option value="false">Inativo</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <a mat-flat-button type="button" routerLink="/master/service" color="accent" class=" btn-block">
                            Cancelar
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>

</div>