import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ObservationProcedureToggleService } from 'src/app/shared/services/API/bed/observation-procedure-toggle.service';

@Component({
  selector: 'app-observation-procedures-modal',
  templateUrl: './observation-procedures-modal.component.html',
  styleUrls: ['./observation-procedures-modal.component.css']
})
export class ObservationProceduresModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<ObservationProceduresModalComponent>,
    private observationProcedureToggleService: ObservationProcedureToggleService,
    private alertService: AlertService,
  ) { }

  public isLoading:boolean;  
  public hasObservationProcedure:boolean;  

  ngOnInit(): void {
    this.hasObservationProcedure = this.data.hasObservationProcedure;
    this.isLoading = false;
  }
  
  close(){
    this.matDialogRef.close({changeConfig: false}); 
  }
  
  clickConfirm(){
    
    this.isLoading = true;

    this.observationProcedureToggleService.postProcedureConfig().subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.alertService.show('Sucesso', "Configuração alterada com sucesso!", AlertType.success); 
      this.matDialogRef.close({changeConfig: true}); 
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }
  
  clickCancel(){
    this.matDialogRef.close({changeConfig: false}); 
  }
}

