<h1 mat-dialog-title>Pré Resumo</h1>
<div mat-dialog-content>
    <div class="modal-body text-center">
        <app-triage-resume [protocolName]="protocolName" [pain]="pain" [painType]="painType" [allergy]="alergies" [specialNecessityName]="specialNecessityName" [triage]="triage"></app-triage-resume>
    </div>
    <div class="row">
        <div *ngIf="!data.isPatientWhite" class="col-6">
            <button mat-flat-button type="button" (click)="goBack()"
                color="accent" class="btn-block btn-concluir" [mat-dialog-close]="">
                <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Voltar
            </button>
        </div>
        <div *ngIf="data.isPatientWhite" class="col-6">
            <button mat-flat-button type="button" routerLink="/classification/white-patient"
                color="accent" class=" btn-block" [mat-dialog-close]="">
                <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Voltar
            </button>
        </div>
        <div class="col-6">
            <button mat-flat-button type="submit" color="primary" class="btn-block" cdkFocusInitial [mat-dialog-close]="">
                Concluir <mat-icon aria-hidden="false" aria-label="Plus">arrow_forward</mat-icon>
            </button>
        </div>
    </div>
</div> 