import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { PriceProcedureHealthcareService } from 'src/app/shared/services/API/private-billing/price-procedure-healthcare.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { PriceProcedureHealthcareStruct } from 'src/app/shared/services/structs/private-billing/price-procedure-healthcare.struct';
import { PriceChangeHistoryModalComponent } from './price-change-history-modal/price-change-history-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ProcedureHealthcareService } from 'src/app/shared/services/API/private-billing/procedure-healthcare.service';
import { UtilsHealthcareAgreements } from '../utils-healthcare-agreements.service';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { PriceProcedureHealthcareRequest } from 'src/app/shared/services/requests/private-billing/price-procedure-healthcare.request';

@Component({
  selector: 'app-price-procedure-healthcare',
  templateUrl: './price-procedure-healthcare.component.html',
  styleUrls: ['./price-procedure-healthcare.component.css']
})
export class PriceProcedureHealthcareComponent implements OnInit {
  constructor(
    private priceProcedureHealthcareService: PriceProcedureHealthcareService,
    private procedureHealthcareService: ProcedureHealthcareService,
    private cdr: ChangeDetectorRef,
    private alertService: AlertService,
    public dialog: MatDialog,
    private utilsHealthcareAgreements: UtilsHealthcareAgreements
  ) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() fullListSize: number;
  @Input() listPriceProcedureHealthcareStruct: PriceProcedureHealthcareStruct[] = [];
  @Input() idHealthcareAgreement: number;
  @Output() changeIndex = new EventEmitter();

  public isLoading: boolean = false;
  public isActivePriceProcedureHealthcare: boolean = null;
  public buttonSelected: 'ativos' | 'inativos' | null = null;
  public listOriginalPriceProcedureHealthcareStruct: PriceProcedureHealthcareStruct[] = [];

  public searchText: string = null;
  inputValue: string;

  public pageIndex: number = 0;
  public pageSize: number = 50;

  ngOnInit(): void {
    this.controlButtonStatus('ativos');
  }

  submit() {
    this.isLoading = true;

    let request: PriceProcedureHealthcareRequest = new PriceProcedureHealthcareRequest();
    request.listPriceProcedureHealthcareStruct = [];

    this.listPriceProcedureHealthcareStruct?.forEach(item => {
      if (!Number.isSafeInteger(item.idPriceProcedureHealthcare) && (!item.valueFront || item.valueFront.trim() == "0,00"))
        return;

      else if (!Number.isSafeInteger(item.idPriceProcedureHealthcare) && item.valueFront && item.valueFront.trim() != "0,00") {
        item.value = this.utilsHealthcareAgreements.formatCurrencyFrontToBack(item.valueFront);
        request.listPriceProcedureHealthcareStruct.push(item);
      }

      else {
        let priceProcedureHealthcare: PriceProcedureHealthcareStruct = this.listOriginalPriceProcedureHealthcareStruct?.find(itemOriginal => itemOriginal.idPriceProcedureHealthcare == item.idPriceProcedureHealthcare && itemOriginal.valueFront != item.valueFront);

        if (priceProcedureHealthcare) {
          item.value = this.utilsHealthcareAgreements.formatCurrencyFrontToBack(item.valueFront);
          request.listPriceProcedureHealthcareStruct.push(item);
        }
      }
    });

    request.idHealthcareAgreement = this.idHealthcareAgreement;
    request.pageSize = this.pageSize;
    request.pageIndex = this.pageIndex;
    request.isActivePriceProcedureHealthcare = this.isActivePriceProcedureHealthcare;
    request.searchText = this.searchText;

    this.priceProcedureHealthcareService.Put(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        if (response && response.listPriceProcedureHealthcareStruct && response.listPriceProcedureHealthcareStruct.length > 0) {
          response.listPriceProcedureHealthcareStruct.forEach(x => {
            x.valueFront = this.utilsHealthcareAgreements.formatCurrencyBackToFront(x.value).trim();
          });

          this.listPriceProcedureHealthcareStruct = response.listPriceProcedureHealthcareStruct;
        }

        this.listOriginalPriceProcedureHealthcareStruct = JSON.parse(JSON.stringify(this.listPriceProcedureHealthcareStruct));

        this.fullListSize = response.fullListSize;
        this.pageSize = response.pageSize;
        this.alertService.show('Sucesso', 'Procedimentos salvos com sucesso!', AlertType.success);
        this.isLoading = false;
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.listPriceProcedureHealthcareStruct) {
      this.listPriceProcedureHealthcareStruct = changes.listPriceProcedureHealthcareStruct.currentValue ? changes.listPriceProcedureHealthcareStruct.currentValue : [];
      this.listPriceProcedureHealthcareStruct.forEach(x => {
        x.valueFront = this.utilsHealthcareAgreements.formatCurrencyBackToFront(x.value).trim();
      });

      this.listOriginalPriceProcedureHealthcareStruct = JSON.parse(JSON.stringify(this.listPriceProcedureHealthcareStruct));
    }
  }

  private timeoutKeySearch: any = null;
  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.pageIndex = 0;
        $this.search();
      }
    },
      1000);
  }

  search() {
    if (this.idHealthcareAgreement) {
      this.isLoading = true;

      this.priceProcedureHealthcareService.GetAll(this.idHealthcareAgreement, this.searchText, this.pageIndex, this.isActivePriceProcedureHealthcare).subscribe({
        next: (response) => {
          if (response.isError) {
            this.throwException(response.errorDescription);
            return;
          }

          if (response && response.listPriceProcedureHealthcareStruct && response.listPriceProcedureHealthcareStruct.length > 0) {
            response.listPriceProcedureHealthcareStruct.forEach(x => {
              x.valueFront = this.utilsHealthcareAgreements.formatCurrencyBackToFront(x.value).trim();
            });
          }

          this.listPriceProcedureHealthcareStruct = response.listPriceProcedureHealthcareStruct;

          this.listOriginalPriceProcedureHealthcareStruct = JSON.parse(JSON.stringify(this.listPriceProcedureHealthcareStruct));

          this.fullListSize = response.fullListSize;
          this.pageSize = response.pageSize;
          this.cdr.detectChanges();
          this.isLoading = false;
        },
        error: (error) => {
          this.throwException(error);
        }
      });
    }
  }

  controlButtonStatus(buttonType: 'ativos' | 'inativos') {
    this.pageIndex = 0;

    if (this.buttonSelected === buttonType) {
      this.buttonSelected = null;
      this.isActivePriceProcedureHealthcare = null;
      this.search();
    }
    else {
      this.buttonSelected = buttonType;

      if (this.buttonSelected == 'ativos') {
        this.isActivePriceProcedureHealthcare = true;
        this.search();
      }
      else if (this.buttonSelected == 'inativos') {
        this.isActivePriceProcedureHealthcare = false;
        this.search();
      }
    }
  }

  isButtonActive(buttonType: 'ativos' | 'inativos'): boolean {
    return buttonType === this.buttonSelected;
  }

  changePage(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.search();
  }

  openHistoryCardModal(idProcedureHealthcare: number) {
    const dialogRef = this.dialog.open(PriceChangeHistoryModalComponent, {
      data: {
        idProcedureHealthcare: idProcedureHealthcare
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  putStatus(event: any, idProcedureHealthcare: number) {
    this.isLoading = true;

    this.procedureHealthcareService.PutStatus(idProcedureHealthcare, event.checked).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.isLoading = false;
        const agreementToUpdate = this.listPriceProcedureHealthcareStruct.find(x => x.idProcedureHealthcare === idProcedureHealthcare);

        this.listPriceProcedureHealthcareStruct?.forEach(x => {
          if (x.idProcedureHealthcare === idProcedureHealthcare)
            x.isActive = event.checked;
        });

        if (agreementToUpdate)
          agreementToUpdate.isActive = event.checked;

        this.alertService.show('Sucesso', 'Status da relação: ' + agreementToUpdate.nameProcedure + ' atualizado com sucesso.', AlertType.success);
      },
      error: (error) => {
        this.throwException(error);
        this.search();
      }
    });
  }

  formatCurrency(event: any, item: PriceProcedureHealthcareStruct) {
    if (isNaN(parseFloat(event.target.value)))
      event.target.value = "0,00";

    let value = this.utilsHealthcareAgreements.formatCurrencyToString(event);
    item.valueFront = value.trim();
  }

  private throwException(error: string) {
    console.log(error);
    this.alertService.show('Erro inesperado', error, AlertType.error);
    this.isLoading = false;
  }

  onCancelarClick() {
    let reportDialog = this.dialog.open(ConfirmModalComponent, {
      data: {
        title: "Aviso!",
        message: "Você realmente deseja cancelar? Qualquer alteração realizada, pode não ter sido salvo!",
        btnPrimary: "Sim, cancelar",
        btnAccent: "Não, continuar nesta aba"
      },
    });
    reportDialog.afterClosed().subscribe(result => {
      if (result && result.clickPrimary) {
        //Do something
        this.search();
        this.changeIndex.emit(0);
      } else if (result && result.clickAccent) {
        //Do something else
      }
    });
  }

}