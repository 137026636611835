import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { DataIntVariableRequest } from '../../requests/orchestrator-data-intelligence/data-int-variable.request';
import { BedDataIntResponse } from '../../responses/orchestrator-data-intelligence/bed-data-int.response';

@Injectable({
  providedIn: 'root'
})
export class BedDataIntService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getBedData(idSourceData: number): Observable<BedDataIntResponse> {
    
    let uri = `Bed/idSourceData/${idSourceData}`;

    return this.httpClient.get<BedDataIntResponse>(environment.urlApiOrchestratorDataIntelligence + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public getVariableResult(variableRequest: DataIntVariableRequest): Observable<BedDataIntResponse> {
    
    let uri = `Bed`;

    return this.httpClient.post<BedDataIntResponse>(environment.urlApiOrchestratorDataIntelligence + uri, variableRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}