import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { WebsocketTelemedicineActionEnum } from 'src/app/shared/enum/websocket-telemedicine-action.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { TriggerWebsocketService } from 'src/app/shared/services/API/orchestrator-telemedicine/trigger-websocket.service';
import { StatusQueueService } from 'src/app/shared/services/API/queue/status-queue.service';

@Component({
  selector: 'app-cancel-confirm',
  templateUrl: './cancel-confirm-modal.component.html',
  styleUrls: ['./cancel-confirm-modal.component.css']
})
export class CancelConfirmModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<CancelConfirmModalComponent>,
    public router:Router,
    private statusQueueService: StatusQueueService,
    private triggerWebsocketService: TriggerWebsocketService,
    private alertService: AlertService,
  ) { }

  public idEpisode: number;

  ngOnInit(): void {
    if (this.data.idEpisode){
      this.idEpisode = this.data.idEpisode;
    }
  }
  
  clickConfirm(){
    this.matDialogRef.close({isCancel:true});
  }

  close(){
    this.matDialogRef.close({isCancel:false}); 
  }

  clickCancel(){
    this.matDialogRef.close({isCancel:false}); 
  }
}

