import { ReturnStruct } from "src/app/shared/structs/return.struct";
import { BedStruct } from "../../structs/bed/bed.struct";
import { NursingConsultationStruct } from "../../structs/medical-record/nursing-consultation.struct";
import { PatientStruct } from "../../structs/medical-record/patient.struct";
import { MultiProfessionalCareCompleteStruct } from "../../structs/multi-professional/multi-professional-care-complete.struct";
import { CompleteMedicalCareStruct } from "../../structs/orchestrator-patient/complete-medical-care.struct";
import { EpisodeStruct } from "../../structs/orchestrator-patient/episode.struct";
import { OccupiedBedStruct } from "../../structs/orchestrator-patient/occupied-bed.struct";
import { TriageStruct } from "../../structs/orchestrator-patient/triage.struct";
import { PatientCareLinePriorityStruct } from "../../structs/care-line-priority/patient-care-line-priority-struct";
import { SuspicionEvaluationStruct } from "../../structs/medical-record/suspicion-evaluation.struct";
import { MedicalProcedureModel } from "../../models/medical-record/medical-procedure.model";
import { ServiceObservationStruct } from "../../structs/orchestrator-queue/service-observation.struct";
import { SadtGroupStruct } from "../../structs/medical-record/sadt-group.struct";
import { AdmissionGuideStruct } from "../../structs/private-billing/admission-guide.struct";
import { SpSadtGuideStruct } from "../../structs/private-billing/sp-sadt-guide.struct";
import { AppointmentGuideStruct } from "../../structs/private-billing/appointment-guide.struct";
import { FeesGuideStruct } from "../../structs/private-billing/fees-guide.struct";
import { SolutionDispensationStruct } from "../../structs/orchestrator-patient/solution-dispensation.struct";
import { DispensationStruct } from "../../structs/orchestrator-patient/dispensation.struct";
import { PatientAvoidanceLogResponse } from "../srv-log/patient-avoidance-log.response";


export class PatientHistoryResponse extends ReturnStruct {
    public occupiedBedStruct: OccupiedBedStruct;
    public episode: EpisodeStruct;
    public listMedicalCareSadt: SadtGroupStruct[] = [];
    public medicalCareHistorie: CompleteMedicalCareStruct[] = [];
    public multiProfessionalCareHistorie: MultiProfessionalCareCompleteStruct[] = [];
    public triage: TriageStruct[] = [];
    public diseaseHistories: string[] = [];
    public allergies: string[] = [];
    public listNursingConsultation: NursingConsultationStruct[] = [];
    public hasPatient: boolean;
    public patient: PatientStruct;
    public priority: number[] = [];
    public patientNecessities: string[] = [];
    public listBedStruct: BedStruct[] = [];
    public showReturnPatientBtn: boolean;
    public patientCareLinePriorityStruct: PatientCareLinePriorityStruct;
    public patientAvoidanceLogResponse: PatientAvoidanceLogResponse;
    public suspicionEvaluation: SuspicionEvaluationStruct;
    public listProcedures: MedicalProcedureModel[] = [];
    public listServiceObservationStruct: ServiceObservationStruct[] = [];
    public startTimeSoFar: string;
    public listAdmissionGuideStruct: AdmissionGuideStruct[] = [];
    public listSpSadtGuideStruct: SpSadtGuideStruct[] = [];
    public listAppointmentGuideStruct: AppointmentGuideStruct[] = [];
    public listFeesGuideStruct: FeesGuideStruct[] = [];

    public listDispensationStruct:DispensationStruct[];
    public listSolutionDispensationStruct: SolutionDispensationStruct[];
}