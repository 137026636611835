export class ExamRequest{
    public idExamType: number;
    
    public referential: number;
    
    public examName: string;
    
    public description: string;
    
    public isActive: boolean;
}