import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { ProtocolSectorRequest } from '../../requests/risk-classification/protocol-sector.request';
import { ListProtocolSectorResponse } from '../../responses/risk-classification/list-protocol-sector.response';
import { MetaDataStruct } from '../../structs/meta-data/meta-data.struct';
import { retry, timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProtocolSectorService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  linkProtocolSector(body: ProtocolSectorRequest): Observable<ReturnStruct> {
    let uri = `ProtocolSector`

    return this.httpClient.post<ReturnStruct>(environment.urlApiRiskClassification + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }

  getProtocolBySector(idSector: number, isTelephone: boolean, listMetaData: MetaDataStruct[]): Observable<ListProtocolSectorResponse> {
    let uri = `ProtocolSector/GetListProtocols?jsonListIdSector=[${idSector}]`

    if (isTelephone != null)
      uri = uri + `&isTelephone=${isTelephone}`

    return this.httpClient.post<ListProtocolSectorResponse>(environment.urlApiRiskClassification + uri, listMetaData, this.addHeaderToken())
      .pipe(
        timeout(environment.requestTimeOut),
        retry(environment.requestRetry),
        catchError(this.handleError)
      );
  }

  listProtocolBySector(listIdSector: number[], listMetaData: MetaDataStruct[]): Observable<ListProtocolSectorResponse> {
    let uri = `ProtocolSector/GetListProtocols?jsonListIdSector=[${listIdSector}]`

    return this.httpClient.post<ListProtocolSectorResponse>(environment.urlApiRiskClassification + uri, listMetaData, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }
}