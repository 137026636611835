import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cancel-confirm',
  templateUrl: './cancel-confirm-modal.component.html',
  styleUrls: ['./cancel-confirm-modal.component.css']
})
export class CancelConfirmModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<CancelConfirmModalComponent>,
    public router:Router
  ) { }
  public confirm: boolean = false;

  ngOnInit(): void {
    
  }
  
  clickConfirm(){
    this.confirm = true;
    this.matDialogRef.close({ confirm: this.confirm});
    if(this.data.isBedManagement) this.router.navigate(['/medic/medic-bed-management'])
  }

  close(){
    this.matDialogRef.close(); 
  }

  clickCancel(){
    this.matDialogRef.close(); 
  }
}

