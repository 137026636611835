import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { CompleteMedicalCareStruct } from 'src/app/shared/services/structs/orchestrator-patient/complete-medical-care.struct';
import { MedicalCareStruct } from 'src/app/shared/services/structs/orchestrator-patient/medical-care.struct';

@Component({
  selector: 'app-medical-care-accordion',
  templateUrl: './medical-care-accordion.component.html',
  styleUrls: ['./medical-care-accordion.component.css']
})
export class MedicalCareAccordionComponent implements OnInit {

  @Input() completeMedicalCare: CompleteMedicalCareStruct;
  @Input() isLoading: boolean;
  @Input() patientName: string;
  @Input() birthDate: Date;

  public medicalCare: MedicalCareStruct;
  public medicalEvolution:Boolean;

  constructor(private alertService:AlertService) { }

  public fileName: string;

  ngOnInit(): void {
    this.medicalCare = new MedicalCareStruct();
    this.medicalCare = this.completeMedicalCare.medicalCare;
    this.medicalEvolution = this.completeMedicalCare.medicalEvolution;
    if(!this.medicalEvolution){
      this.alertService.show('AVISO', "Seu usuário não tem permissão para ver as evoluções médicas!", AlertType.warning);
    }
  }
}