import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { SadtModalComponent } from 'src/app/shared/components/sadt-modal/sadt-modal.component';
import { ExamStruct } from 'src/app/shared/services/structs/SADT/exam.struct';
import { ExamTypeService } from 'src/app/shared/services/API/SADT/exam-type.service';
import { ExamTypeModel } from 'src/app/shared/services/models/SADT/exam-type.model';
import { GetCompleteMultiProfessionalConfigStruct } from 'src/app/shared/services/structs/multi-professional/get-complete-multi-professional-config.struct';
import { MedicalCareSadtStruct } from 'src/app/shared/services/structs/medical-record/medical-care-sadt.struct';
import { SadtGroupStruct } from 'src/app/shared/services/structs/multi-professional/sadt-group.struct';
import { MultiProfessionalCareSadt } from 'src/app/shared/services/models/multi-professional/multi-professional-care-sadt.model';
import { LookupResponse } from 'src/app/shared/services/responses/multi-professional/lookup.response';
import { LookupService } from 'src/app/shared/services/API/multi-professional/lookup.service';

@Component({
  selector: 'app-exams-requests-block',
  templateUrl: './exams-requests-block.component.html',
  styleUrls: ['./exams-requests-block.component.css']
})
export class ExamsRequestsBlockComponent implements OnInit{

  @Input() completeMultiprofessionalConfig: GetCompleteMultiProfessionalConfigStruct;
  @Input() listSADT: SadtGroupStruct[];
  @Output() changeSADTGroup = new EventEmitter<any>();

  constructor(
    private alertService: AlertService,
    private dialog: MatDialog,
    public lookupService: LookupService,
    public router: Router,
    private examTypeService: ExamTypeService,
  ) { }

  public isLoading: boolean = false;
  public lookupResponse: LookupResponse;

  public listLookupExam: ExamStruct[];
  public satdOthers: number = 0;
  private listExamType: ExamTypeModel[];

  public listIdExam: number[] = [];
  public isAllExams: boolean;

  ngOnInit(): void {

    this.getLookupSadtType();
    this.getLookup();

  }

  getLookupSadtType() {
    this.examTypeService.listAllExamTypeWithExams().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        this.listExamType = response.listExamType;
      }
    });
  }

  getLookup() {
    this.lookupService.getLookup().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.lookupResponse = response;
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openSadtModal(sadt: MedicalCareSadtStruct, readOnly: boolean, index: number) {
    const dialogRef = this.dialog.open(SadtModalComponent, {
      data: {
        sadt,
        readOnly,
        listPlace: this.lookupResponse.listPlace,
        listSadtSchedule: this.lookupResponse.listSadtSchedule,
        listExamType: this.listExamType,
        listIdExam: this.listIdExam,
        isAllExams: true,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result) {  

          if (index >= 0){
            this.listSADT.splice(index, 1);
          }

          let multiProfessionalSadtGroupStruct = new SadtGroupStruct();
          multiProfessionalSadtGroupStruct.idPlace = result.newSadt.idPlace;
          multiProfessionalSadtGroupStruct.idSadtSchedule = result.newSadt.idSadtSchedule;
          multiProfessionalSadtGroupStruct.idStatus = result.newSadt.idStatus;
          multiProfessionalSadtGroupStruct.idTypeSadt = result.newSadt.idTypeSadt;
          multiProfessionalSadtGroupStruct.observationMedic = result.newSadt.observationMedic;
          multiProfessionalSadtGroupStruct.statusName = result.newSadt.statusName;
          multiProfessionalSadtGroupStruct.typeSadtName = result.newSadt.typeSadtName;
          multiProfessionalSadtGroupStruct.placeName = result.newSadt.placeName;
          multiProfessionalSadtGroupStruct.sadtDescription = result.newSadt.sadtDescription;
          
          let multiProfessionalCareSadt = new MultiProfessionalCareSadt()
          if (result.newSadt.medicalCareSadt && result.newSadt.medicalCareSadt.length > 0){
            result.newSadt.medicalCareSadt.forEach(element => {
              multiProfessionalCareSadt.idSadt = element.idSadt;
              multiProfessionalCareSadt.sadtName = element.sadtName;

              multiProfessionalSadtGroupStruct.listMultiProfessionalCareSadt.push(multiProfessionalCareSadt);
              multiProfessionalCareSadt = new MultiProfessionalCareSadt();
            });
          }

          this.listSADT.push(multiProfessionalSadtGroupStruct);
          this.changeSADTGroup.emit(this.listSADT);
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  deleteSadt(index: number) {
    this.listSADT.splice(index, 1);
    this.changeSADTGroup.emit(this.listSADT);
  }

}