<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="header-modal">
            <h1>Guia de Internação</h1>
        </div>
        <app-loading-progress-bar *ngIf="isLoading"></app-loading-progress-bar>
        <div class="body-modal">
            <form class="form" [formGroup]="model" id="admission-form">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-3">
                        <app-select-2 [formControlField]="'ansRegistration'"
                            [multiple]="false" [url]="urlListAnsRegistration" [idField]="'idHealthcareAgreement'"
                            [nameField]="'ansWithName'" [labelField]="'1 - Registro ANS'"
                            [preSelectedBlock]="this.idAdmissionGuide != null ? true : false">
                        </app-select-2>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>2 - Número da guia no prestador</mat-label>
                            <input matInput type="text" [maxlength]="admissionGuideMaxLength.guideNumber"
                                formControlName="guideNumber"
                                oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                            <mat-error
                                *ngIf="model.get('guideNumber').invalid && !model.get('guideNumber').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ model.get('guideNumber').value ?
                                model.get('guideNumber').value.length : 0
                                }}/{{admissionGuideMaxLength.guideNumber}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>3 - Nº da guia operadora</mat-label>
                            <input matInput type="text" [maxlength]="admissionGuideMaxLength.providerGuideNumber"
                                formControlName="providerGuideNumber"
                                oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                            <mat-error
                                *ngIf="model.get('providerGuideNumber').invalid && !model.get('providerGuideNumber').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ model.get('providerGuideNumber').value ?
                                model.get('providerGuideNumber').value.length : 0
                                }}/{{admissionGuideMaxLength.providerGuideNumber}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3 status-select">
                        <mat-form-field appearance="outline">
                            <mat-label>Status da guia</mat-label>
                            <mat-select formControlName="idGuideStatus">
                                <mat-option *ngFor="let item of listGuideStatus" [value]="item.idGuideStatus">
                                    <span class="color-dot" [style.background-color]="item.color"></span> {{item.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idGuideStatus').invalid">Selecione um status</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>4 - Data da Autorização</mat-label>
                            <input matInput type="text" [mask]="masks.date" formControlName="datetimeAuthorization">
                            <mat-error *ngIf="model.get('datetimeAuthorization').invalid">Data
                                inválida</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>5 - Senha</mat-label>
                            <input matInput type="text" [maxlength]="admissionGuideMaxLength.ticket"
                                formControlName="ticket">
                            <mat-error
                                *ngIf="model.get('ticket').invalid && !model.get('ticket').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ model.get('ticket').value ? model.get('ticket').value.length : 0
                                }}/{{admissionGuideMaxLength.ticket}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>6 - Validade da Senha</mat-label>
                            <input matInput type="text" [mask]="masks.date" formControlName="datetimeExpirationTicket">
                            <mat-error *ngIf="model.get('datetimeExpirationTicket').invalid">Data inválida</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row margin-section">
                    <div class="title-header">
                        <h3>Dados do beneficiário (paciente)</h3>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>7 - Número da carteira</mat-label>
                            <input matInput type="text" [maxlength]="admissionGuideMaxLength.beneficiaryDocumentNumber"
                                formControlName="beneficiaryDocumentNumber"
                                oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                            <mat-error
                                *ngIf="model.get('beneficiaryDocumentNumber').invalid && !model.get('beneficiaryDocumentNumber').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ model.get('beneficiaryDocumentNumber').value ?
                                model.get('beneficiaryDocumentNumber').value.length : 0
                                }}/{{admissionGuideMaxLength.beneficiaryDocumentNumber}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>8 - Validade da Carteira</mat-label>
                            <input matInput type="text" [mask]="masks.date"
                                formControlName="datetimeExpirationDocumentNumber">
                            <mat-error *ngIf="model.get('datetimeExpirationDocumentNumber').invalid">Data
                                inválida</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>9 - Atendimento a Recém-Nascido</mat-label>
                            <mat-select formControlName="newbornAttendanceIndicator">
                                <mat-option [value]="true">Sim</mat-option>
                                <mat-option [value]="false">Não</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6">
                        <mat-form-field appearance="outline">
                            <mat-label>10 - Nome do beneficiário</mat-label>
                            <input matInput type="text" [maxlength]="admissionGuideMaxLength.beneficiaryName"
                                formControlName="beneficiaryName">
                            <mat-error
                                *ngIf="model.get('beneficiaryName').invalid && !model.get('beneficiaryName').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ model.get('beneficiaryName').value ?
                                model.get('beneficiaryName').value.length : 0
                                }}/{{admissionGuideMaxLength.beneficiaryName}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6">
                        <mat-form-field appearance="outline">
                            <mat-label>50 - Nome Social</mat-label>
                            <input matInput type="text" [maxlength]="admissionGuideMaxLength.beneficiarySocialName"
                                formControlName="beneficiarySocialName">
                            <mat-error
                                *ngIf="model.get('beneficiarySocialName').invalid && !model.get('beneficiarySocialName').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ model.get('beneficiarySocialName').value ?
                                model.get('beneficiarySocialName').value.length : 0
                                }}/{{admissionGuideMaxLength.beneficiarySocialName}}</mat-hint>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row margin-section">
                    <div class="title-header">
                        <h3>Dados do contratado solicitante</h3>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Identificação no prestador</mat-label>
                            <mat-select formControlName="idProviderIdentityType"
                                (selectionChange)="checkProviderIdentityType($event.value)">

                                <mat-option *ngFor="let item of listProviderIdentityType"
                                    [value]="item.idProviderIdentityType">
                                    {{item.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idProviderIdentityType').invalid">Selecione um
                                status</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>12 - Código na operadora</mat-label>
                            <input matInput type="text" [maxlength]="requesterCodeMaxLength"
                                formControlName="requesterCodeOperator" [mask]="requesterCodeMask"
                                (input)="inputNumber('requesterCodeOperator')">
                            <mat-error
                                *ngIf="model.get('requesterCodeOperator').invalid && !model.get('requesterCodeOperator').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ model.get('requesterCodeOperator').value ?
                                model.get('requesterCodeOperator').value.length : 0 }} /
                                {{ admissionGuideMaxLength.requesterCodeOperator }}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-5">
                        <mat-form-field appearance="outline">
                            <mat-label>13 - Nome do contratado</mat-label>
                            <input matInput type="text" [maxlength]="admissionGuideMaxLength.requesterName"
                                formControlName="requesterName">
                            <mat-error
                                *ngIf="model.get('requesterName').invalid && !model.get('requesterName').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ model.get('requesterName').value ?
                                model.get('requesterName').value.length : 0
                                }}/{{admissionGuideMaxLength.requesterName}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <app-select-2
                            *ngIf="listProfessionalAgreementStruct && listProfessionalAgreementStruct.length > 0"
                            [formControlField]="'idProfessionalAgreement'"
                            (selectedValue)="onProfessionalChange($event)" [multiple]="false"
                            [list]="this.listProfessionalAgreementStruct" [idField]="'idProfessionalAgreement'"
                            [nameField]="'nameProfessional'" [labelField]="'14 - Nome do Profissional Solicitante'">
                        </app-select-2>
                    </div>
                    <div class="col-12 col-sm-6 col-md-5">
                        <mat-form-field appearance="outline">
                            <mat-label>15 - Conselho</mat-label>
                            <mat-select formControlName="idProfessionalCouncil">
                                <mat-option *ngFor="let item of listProfessionalCouncil"
                                    [value]="item.idProfessionalCouncil">
                                    {{item.termCode}} - {{item.councilName}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idProfessionalCouncil').invalid">Selecione um
                                conselho</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>16 - Número no conselho</mat-label>
                            <input matInput type="text"
                                [maxlength]="admissionGuideMaxLength.requesterProfessionalNumberCouncil"
                                formControlName="requesterProfessionalNumberCouncil"
                                oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                            <mat-error
                                *ngIf="model.get('requesterProfessionalNumberCouncil').invalid && !model.get('requesterProfessionalNumberCouncil').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ model.get('requesterProfessionalNumberCouncil').value ?
                                model.get('requesterProfessionalNumberCouncil').value.length : 0
                                }}/{{admissionGuideMaxLength.requesterProfessionalNumberCouncil}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>17 - UF</mat-label>
                            <mat-select formControlName="idState">
                                <mat-option *ngFor="let item of listState" [value]="item.idState">
                                    {{item.stateCode}} - {{item.stateInitials}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idState').invalid">Selecione um estado</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-5">
                        <mat-form-field appearance="outline">
                            <mat-label>18 - Código CBO</mat-label>
                            <mat-select formControlName="idCbo">
                                <mat-option *ngFor="let item of listCbo" [value]="item.idCbo">
                                    {{item.cboCode}} - {{item.cboName}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idCbo').invalid">Selecione um código de CBO</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row margin-section">
                    <div class="title-header">
                        <h3>Dados do hospital/local solicitado/dados da internação</h3>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>19 - Código na Operadora/CNPJ</mat-label>
                            <input matInput type="text" [maxlength]="admissionGuideMaxLength.requesterCodeOperatorCnpj"
                                formControlName="requesterHospitalCode"
                                oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                            <mat-error
                                *ngIf="model.get('requesterHospitalCode').invalid && !model.get('requesterHospitalCode').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ model.get('requesterHospitalCode').value ?
                                model.get('requesterHospitalCode').value.length : 0
                                }}/{{admissionGuideMaxLength.requesterHospitalCode}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>20 - Nome do hospital/local solicitado</mat-label>
                            <input matInput type="text" [maxlength]="admissionGuideMaxLength.requesterHospitalName"
                                formControlName="requesterHospitalName">
                            <mat-error
                                *ngIf="model.get('requesterHospitalName').invalid && !model.get('requesterHospitalName').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ model.get('requesterHospitalName').value ?
                                model.get('requesterHospitalName').value.length : 0
                                }}/{{admissionGuideMaxLength.requesterHospitalName}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>21 - Data sugerida para internação</mat-label>
                            <input matInput type="text" [mask]="masks.date"
                                formControlName="datetimeSuggestedAdmission">
                            <mat-error *ngIf="model.get('datetimeSuggestedAdmission').invalid">Data inválida</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>22 - Caráter do Atendimento</mat-label>
                            <mat-select formControlName="idAttendanceAspect">
                                <mat-option *ngFor="let item of listAttendanceAspect" [value]="item.idAttendanceAspect">
                                    {{item.termCode}} - {{item.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idAttendanceAspect').invalid">Selecione um Caráter do
                                Atendimento</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>23 - Tipo de internação</mat-label>
                            <mat-select formControlName="idHospitalizationType">
                                <mat-option *ngFor="let item of listHospitalizationType"
                                    [value]="item.idHospitalizationType">
                                    {{item.codeTerm}} - {{item.hospitalizationTypeName}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idHospitalizationType').invalid">Selecione um
                                Tipo de internação</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>24 - Regime de internação</mat-label>
                            <mat-select formControlName="idHospitalizationRegime">
                                <mat-option *ngFor="let item of listHospitalizationRegime"
                                    [value]="item.idHospitalizationRegime">
                                    {{item.termCode}} - {{item.hospitalizationRegimeDescription}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idHospitalizationRegime').invalid">Selecione um
                                Regime de internação</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>25 - Qtde. Diárias Solicitadas</mat-label>
                            <input matInput type="text"
                                [maxlength]="admissionGuideMaxLength.requesterHospitalDailyCount"
                                formControlName="requesterHospitalDailyCount"
                                oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                            <mat-error
                                *ngIf="model.get('requesterHospitalDailyCount').invalid && !model.get('requesterHospitalDailyCount').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ model.get('requesterHospitalDailyCount').value ?
                                model.get('requesterHospitalDailyCount').value.length : 0
                                }}/{{admissionGuideMaxLength.requesterHospitalDailyCount}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>26 - Uso de OPME</mat-label>
                            <mat-select formControlName="opmeUsageForecast">
                                <mat-option [value]="true">S</mat-option>
                                <mat-option [value]="false">N</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>27 - Uso de quimioterápico</mat-label>
                            <mat-select formControlName="predictionChemotherapyUse">
                                <mat-option [value]="true">S</mat-option>
                                <mat-option [value]="false">N</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>28 - Indicação clínica</mat-label>
                            <input matInput type="text" [maxlength]="admissionGuideMaxLength.clinicalIndication"
                                formControlName="clinicalIndication">
                            <mat-error
                                *ngIf="model.get('clinicalIndication').invalid && !model.get('clinicalIndication').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ model.get('clinicalIndication').value ?
                                model.get('clinicalIndication').value.length : 0
                                }}/{{admissionGuideMaxLength.clinicalIndication}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>29 - CID 10 Principal (1)</mat-label>
                            <input matInput type="text" [maxlength]="admissionGuideMaxLength.cidTenPrimary"
                                formControlName="cidTenPrimary">
                            <mat-error
                                *ngIf="model.get('cidTenPrimary').invalid && !model.get('cidTenPrimary').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ model.get('cidTenPrimary').value ?
                                model.get('cidTenPrimary').value.length : 0
                                }}/{{admissionGuideMaxLength.cidTenPrimary}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>30 - CID 10 (2)</mat-label>
                            <input matInput type="text" [maxlength]="admissionGuideMaxLength.cidTenSecondary"
                                formControlName="cidTenSecondary">
                            <mat-error
                                *ngIf="model.get('cidTenSecondary').invalid && !model.get('cidTenSecondary').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ model.get('cidTenSecondary').value ?
                                model.get('cidTenSecondary').value.length : 0
                                }}/{{admissionGuideMaxLength.cidTenSecondary}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>31 - CID 10 (3)</mat-label>
                            <input matInput type="text" [maxlength]="admissionGuideMaxLength.cidTenTertiary"
                                formControlName="cidTenTertiary">
                            <mat-error
                                *ngIf="model.get('cidTenTertiary').invalid && !model.get('cidTenTertiary').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ model.get('cidTenTertiary').value ?
                                model.get('cidTenTertiary').value.length : 0
                                }}/{{admissionGuideMaxLength.cidTenTertiary}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>32 - CID 10 (4)</mat-label>
                            <input matInput type="text" [maxlength]="admissionGuideMaxLength.cidTenQuaternary"
                                formControlName="cidTenQuaternary">
                            <mat-error
                                *ngIf="model.get('cidTenQuaternary').invalid && !model.get('cidTenQuaternary').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ model.get('cidTenQuaternary').value ?
                                model.get('cidTenQuaternary').value.length : 0
                                }}/{{admissionGuideMaxLength.cidTenQuaternary}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>33 - Indicação de acidente</mat-label>
                            <mat-select formControlName="idAccidentIndication">
                                <mat-option *ngFor="let item of listAccidentIndication"
                                    [value]="item.idAccidentIndication">
                                    {{item.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idAccidentIndication').invalid">Selecione uma
                                Indicação de acidente</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row margin-section">
                    <div class="title-header">
                        <h3>Procedimentos ou itens assistenciais solicitados</h3>
                        <app-loading-progress-bar *ngIf="isLoading"></app-loading-progress-bar>
                    </div>
                    <div class="col-12">
                        <app-admission-procedure-history *ngIf="!isLoading && !isFirstLoading" #admissionProcedureComponent
                            [listAdmissionProcedure]="listAdmissionProcedure" [isEditingAllowed]="isEditingAllowed"
                            [showAdditionalFields]="showAdditionalFields" [idAdmissionGuide]="idAdmissionGuide"
                            [tussTerminologyGroupMap]="tussTerminologyGroupMap"
                            [idHealthcareAgreement]="idHealthcareAgreement" [listProcedure]="listProcedure"
                            (onDataChanged)="onCreateDataChanged($event)">
                        </app-admission-procedure-history>
                    </div>
                </div>
                <div class="row margin-section">
                    <div class="title-header">
                        <h3>Dados da autorização</h3>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>39 - Data Provável da Admissão Hospitalar</mat-label>
                            <input matInput type="text" [mask]="masks.date"
                                formControlName="datetimeHospitalAuthorization">
                            <mat-error *ngIf="model.get('datetimeHospitalAuthorization').invalid">Data
                                inválida</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>40 - Qtde. Diárias Autorizadas</mat-label>
                            <input matInput type="text"
                                [maxlength]="admissionGuideMaxLength.authorizedHospitalDailyCount"
                                formControlName="authorizedHospitalDailyCount"
                                oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                            <mat-error
                                *ngIf="model.get('authorizedHospitalDailyCount').invalid && !model.get('authorizedHospitalDailyCount').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ model.get('authorizedHospitalDailyCount').value ?
                                model.get('authorizedHospitalDailyCount').value.length : 0
                                }}/{{admissionGuideMaxLength.authorizedHospitalDailyCount}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>41 - Tipo da acomodação autorizada</mat-label>
                            <mat-select formControlName="idAccommodationType">
                                <mat-option *ngFor="let item of listAccommodationType"
                                    [value]="item.idAccommodationType">
                                    {{item.termCode}} - {{item.accommodationTypeDescription}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idAccommodationType').invalid">Selecione um
                                Tipo da acomodação</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>42 - Código na Operadora/CNPJ Autorizado</mat-label>
                            <input matInput type="text"
                                [maxlength]="admissionGuideMaxLength.requesterCodeOperatorAuthorized"
                                formControlName="requesterCodeOperatorAuthorized"
                                oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                            <mat-error
                                *ngIf="model.get('requesterCodeOperatorAuthorized').invalid && !model.get('requesterCodeOperatorAuthorized').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ model.get('requesterCodeOperatorAuthorized').value ?
                                model.get('requesterCodeOperatorAuthorized').value.length : 0
                                }}/{{admissionGuideMaxLength.requesterCodeOperatorAuthorized}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>43 - Nome do Hospital/Local Autorizado</mat-label>
                            <input matInput type="text" formControlName="requesterHospitalNameAuthorized"
                                [maxlength]="admissionGuideMaxLength.requesterHospitalNameAuthorized">
                            <mat-error *ngIf="model.get('requesterHospitalNameAuthorized').invalid">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ model.get('requesterHospitalNameAuthorized').value ?
                                model.get('requesterHospitalNameAuthorized').value.length : 0
                                }}/{{admissionGuideMaxLength.requesterHospitalNameAuthorized}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>44 - Código CNES</mat-label>
                            <input matInput type="text" [maxlength]="admissionGuideMaxLength.codeCnes"
                                formControlName="codeCnes" [mask]="masks.cnes"
                                oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                            <mat-error
                                *ngIf="model.get('codeCnes').invalid && !model.get('codeCnes').errors?.maxlength">CNES
                                Inválido</mat-error>
                            <mat-hint align="end">{{ model.get('codeCnes').value ?
                                model.get('codeCnes').value.length : 0
                                }}/{{admissionGuideMaxLength.codeCnes}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>45 - Observação/Justificativa</mat-label>
                            <textarea matInput type="text"
                                [maxlength]="admissionGuideMaxLength.observationJustification"
                                formControlName="observationJustification"></textarea>
                            <mat-error
                                *ngIf="model.get('observationJustification').invalid && !model.get('observationJustification').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ model.get('observationJustification').value ?
                                model.get('observationJustification').value.length : 0
                                }}/{{admissionGuideMaxLength.observationJustification}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>46 - Data da solicitação</mat-label>
                            <input matInput type="text" [mask]="masks.date" formControlName="datetimeRequest">
                            <mat-error *ngIf="model.get('datetimeRequest').invalid">Data inválida</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div mat-dialog-footer class="footer-modal">
                    <button mat-flat-button color="accent" class="btn-block" (click)="close()" type="button">
                        <span *ngIf="!isLoading">Fechar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                    </button>
                </div>
            </form>
            <app-loading-list *ngIf="isFirstLoading"></app-loading-list>
        </div>
    </div>
</div>