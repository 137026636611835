import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { BillingOverviewMonthService } from 'src/app/shared/services/API/billing-by-health-unit/billing-overview-month.service';
import { LookupService } from 'src/app/shared/services/API/billing-by-health-unit/lookup.service';
import { BillingOverview } from 'src/app/shared/services/models/billing-by-health-unit/billing-overview.model';
import { BillingYear } from 'src/app/shared/services/models/billing-by-health-unit/billing-year.model';
import { GetBillingByYearRequest } from 'src/app/shared/services/requests/billing-by-health-unit/get-billing-by-year.request';
import { BillingOverviewData } from 'src/app/shared/services/structs/billing-by-health-unit/billing-overview-data.model';
import { BillingOverviewSeries } from 'src/app/shared/services/structs/billing-by-health-unit/billing-overview-series.model';

@Component({
  selector: 'app-billing-health-unit',
  templateUrl: './billing-health-unit.component.html',
  styleUrls: ['./billing-health-unit.component.css']
})
export class BillingHealthUnitComponent implements OnInit {

  constructor(
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private lookupService: LookupService,
    private billingOverviewMonthService: BillingOverviewMonthService,
  ) {
    Object.assign(this, { year: this.year })
  }

  public model: FormGroup;
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.master;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.master_batch;

  public isLoading: boolean;
  public listBillingYear: BillingYear[];
  public getRequest: GetBillingByYearRequest;
  public searchDate: Date;

  public annualAverage: number;
  public listBillingOverview: BillingOverview[];
  public year: BillingOverviewData[] = [];
  public showXAxis: boolean = true;
  public showYAxis: boolean = true;
  public noBarWhenZero: boolean = false;
  public showLegend: boolean = true;
  public showXAxisLabel: boolean = true;
  public showYAxisLabel: boolean = true;
  public legendTitle: string = '';
  public colorScheme = {
    domain: ['#7FA6E7', '#A27DA9']
  };

  ngOnInit(): void {
    this.isLoading = true;
    this.model = this.formBuilder.group({
      idBillingYear: [],
    });

    this.populateSelectPeriod();
  }

  searchByPeriod(event: any) {
    this.isLoading = true;
    this.year = [];

    this.getRequest = new GetBillingByYearRequest();
    this.getRequest.year = event.year;
    this.billingOverviewMonthService.getBillingOverviewPerYear(this.getRequest.year).subscribe({
      next: (response) => {
        if (response.isError && response.errorCode == 0) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        else if(response.isError && response.errorCode == 1){
          this.alertService.show('Alerta', response.errorDescription, AlertType.warning);
          this.isLoading = false;
          return;
        }
        
        this.annualAverage = response.annualAverage;
        response.listBillingOverview.forEach(x => {
          let billingData = new BillingOverviewData();
          let series = new BillingOverviewSeries();
          let newSeries = new BillingOverviewSeries();

          billingData.name = x.monthName;

          series.name = "Atendimentos do plano";
          series.value = x.planQuantity;
          billingData.series.push(series);

          newSeries.name = "Atendimentos extra";
          newSeries.value = x.quantityExtraAttendance;
          billingData.series.push(newSeries);

          this.year.push(billingData);
        });

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateSelectPeriod() {
    this.lookupService.listBillingYear().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listBillingYear = response.listBillingYear;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  onSelect(data): void {
    JSON.parse(JSON.stringify(data));
  }

  onActivate(data): void {
    JSON.parse(JSON.stringify(data));
  }

  onDeactivate(data): void {
    JSON.parse(JSON.stringify(data));
  }
}