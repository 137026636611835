import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { RoomDeleteModalComponent } from './room-delete-modal/room-delete-modal.component';
import { RoomStruct } from 'src/app/shared/services/structs/flow/room.struct';
import { SectorStruct } from 'src/app/shared/services/structs/flow/sector.struct';
import { RoomService } from 'src/app/shared/services/API/flow/room.service';
import { SectorService } from 'src/app/shared/services/API/flow/sector.service';

@Component({
  selector: 'app-room-list',
  templateUrl: './room-list.component.html',
  styleUrls: ['./room-list.component.css']
})
export class RoomListComponent implements OnInit {

  constructor(private roomService: RoomService,
    private sectorService: SectorService,
    private alertService: AlertService,
    public dialog: MatDialog) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.master;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.master_room;

  public listRoom: RoomStruct[];
  public listSector: SectorStruct[];
  public isLoading: boolean = true;
  public isActive: boolean;
  public searchText: string;
  public idSector: number;

  ngOnInit(): void {
    this.populateSectorSelect();
    this.search();
  }
  search() {
    this.isLoading = true;

    if (this.searchText == "")
      this.searchText = null;

    this.roomService.listRoom(this.searchText, this.isActive, this.idSector).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listRoom = response.listRoomStruct;

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateSectorSelect() {
    this.sectorService.listAllSector().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listSector = response.listSectorStruct;

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13)
        $this.search();
    }, 1000);
  }

  openModal(idRoom) {
    const dialogRef = this.dialog.open(RoomDeleteModalComponent, {
      data: {
        idRoom: idRoom
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.deleteRoom)
          this.search();
      },
      error: (error) => {
        console.log(error);
      }
    });
  }
}
