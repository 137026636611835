<div class="white-body">
    <div [formGroup]="infoStruct.formGroup">
        <div class="audit-container">
            <div class="audit-header row">
                <div class="user-name col-12">
                    <h1>Classificador:</h1>
                    <span>{{infoStruct.userNameTriage}}</span>
                </div>
                <div class="user-info col-12 col-lg-4">
                    <div class="row">
                        <div class="user-tag col-12">{{infoStruct.triageUserProfession}} - N°Conselho:
                            {{infoStruct.triageUserCouncil}}
                        </div>
                        <div *ngIf="infoStruct.classificationRiskAuditTriage.timeLongerThreeMinutes && this.viewTimeTag"
                            class="user-tag-duration col-12">
                            <mat-icon>warning</mat-icon>
                            Duração maior que 3 minutos
                        </div>
                    </div>
                </div>
                <div class="auditor-info col-12 col-lg-8">
                    <div class="auditor-name" *ngIf="showFirst">
                        <h1>1º Auditor:</h1>
                        <span> {{userFirstAuditor}}</span>
                    </div>
                    <div class="auditor-name" *ngIf="showSecond">
                        <h1>2º Auditor:</h1>
                        <span>{{userSecondAuditor}}</span>
                    </div>
                    <div class="last-auditor-name"
                        *ngIf="showOther || (this.idAuditStatus == 4 && numberOtherStatusAudit > 2)">
                        <h1>{{numberOtherStatusAudit}}º Auditor:</h1>
                        <span>{{userOtherAuditor}}</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-4 col-lg-4 top-left quadrant">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12">
                            <div class="audit-triage-field">
                                <span class="audit-triage-field-name">Paciente: </span>
                                <span>{{infoStruct.patientName}}</span>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6">
                            <div class="audit-triage-field">
                                <span class="audit-triage-field-name">Gênero: </span>
                                <span>{{infoStruct.gender}}</span>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6" *ngIf="infoStruct.socialName">
                            <div class="audit-triage-field">
                                <span class="audit-triage-field-name">Nome social: </span>
                                <span>{{infoStruct.socialName}}</span>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6">
                            <div class="audit-triage-field">
                                <span class="audit-triage-field-name">Idade: </span>
                                <span>{{infoStruct.patientAge}}</span>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6">
                            <div class="audit-triage-field">
                                <span class="audit-triage-field-name">Data de nascimento: </span>
                                <span>{{infoStruct.birthDate | date:'dd/MM/yyyy'}}</span>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6">
                            <div class="audit-triage-field">
                                <span class="audit-triage-field-name">Episódio: </span>
                                <span>{{infoStruct.idEpisode}}</span>
                            </div>
                        </div>
                    </div>
                    <hr />
                </div>
                <div class="col-sm-12 col-md-8 col-lg-8 quadrant">
                    <div class="row">
                        <div *ngIf="displayCarePriorityInRiskClassification" class="col-12 col-md-4">
                            <div class="audit-triage-field">
                                <span class="audit-triage-field-name">Linha de cuidado em prioridade: </span>
                                <span>{{infoStruct.careLinePriorityName}}</span>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="audit-triage-field">
                                <span class="audit-triage-field-name">Início da classificação: </span>
                                <span>{{infoStruct.dateBeginTriage | date:'dd/MM/yyyy HH:mm:ss'}}</span>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="audit-triage-field">
                                <span class="audit-triage-field-name">Queixa: </span>
                                <span>{{infoStruct.complaint}}</span>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="audit-triage-field">
                                <span class="audit-triage-field-name">Gravidade: </span>
                                <div class="gravity-tag" [ngStyle]="{'background-color': gravityColorCode}">
                                    {{infoStruct.gravity}}
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="audit-triage-field">
                                <span class="audit-triage-field-name">Fim da classificação: </span>
                                <span>{{infoStruct.dateEndTriage | date:'dd/MM/yyyy HH:mm:ss'}}</span>
                            </div>
                        </div>
                        <div class="col-12 col-md-4" *ngIf="infoStruct.lastFlowchart">
                            <div class="audit-triage-field">
                                <span class="audit-triage-field-name">Fluxograma anterior: </span>
                                <span>{{infoStruct.lastFlowchart}}</span>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="audit-triage-field">
                                <span class="audit-triage-field-name">Fluxograma: </span>
                                <span>{{infoStruct.flowchart}}</span>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="audit-triage-field">
                                <span class="audit-triage-field-name">Encaminhamento: </span>
                                <span>{{infoStruct.fowardName}}</span>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="audit-triage-field">
                                <span class="audit-triage-field-name">Duração da classificação: </span>
                                <span>{{infoStruct.triageDuration}}</span>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="audit-triage-field">
                                <span *ngIf="infoStruct.idGravity != idWhiteGravity"
                                    class="audit-triage-field-name">Discriminador: </span>
                                <span *ngIf="infoStruct.idGravity == idWhiteGravity"
                                    class="audit-triage-field-name">Razão
                                    de
                                    entrada: </span>
                                <span>{{infoStruct.discriminator}}</span>
                            </div>
                        </div>
                    </div>
                    <hr />
                </div>
            </div>
            <div class="row bottom-quadrant">
                <div *ngIf="!isAuditConcluded" class="col-sm-12 col-md-4 col-lg-4 bottom-left quadrant">
                    <ng-container *ngIf="this.showFirst">
                        <mat-radio-group aria-label="" formControlName="firstAuditorAnswer"
                            (change)="changeRadioFirst()">
                            <mat-radio-button [value]="true">Correto</mat-radio-button>
                            <mat-radio-button [value]="false">Incorreto</mat-radio-button>
                        </mat-radio-group>
                        <mat-form-field *ngIf="infoStruct.formGroup.get('firstAuditorAnswer').value == false"
                            appearance="outline">
                            <mat-label>Motivo</mat-label>
                            <mat-select formControlName="firstAuditorIdIncorrectClassificationReason"
                                (selectionChange)="changeSelectIncorrectFirst()">
                                <mat-option *ngFor="let item of listAllIncorrectClassificationReason"
                                    [value]="item.idIncorrectClassificationReason">{{item.description}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field
                            *ngIf="infoStruct.formGroup.get('firstAuditorIdIncorrectClassificationReason').value == 3"
                            appearance="outline">
                            <mat-label>Observação</mat-label>
                            <textarea matInput type="text"
                                formControlName="firstAuditorIncorrectObservation"></textarea>
                            <mat-error *ngIf="infoStruct.formGroup.get('firstAuditorIncorrectObservation').invalid">A
                                observação
                                é obrigatória.</mat-error>
                        </mat-form-field>

                        <mat-form-field *ngIf="infoStruct.formGroup.get('firstAuditorAnswer').value == true"
                            appearance="outline">
                            <mat-label>Motivo</mat-label>
                            <mat-select formControlName="firstAuditorIdCorrectClassificationReason"
                                (selectionChange)="changeSelectCorrectFirst()">
                                <mat-option [value]="null"></mat-option>
                                <mat-option *ngFor="let item of listAllCorrectClassificationReason"
                                    [value]="item.idCorrectClassificationReason">{{item.description}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field
                            *ngIf="infoStruct.formGroup.get('firstAuditorIdCorrectClassificationReason').value == 1"
                            appearance="outline">
                            <mat-label>Observação</mat-label>
                            <textarea matInput type="text" formControlName="firstAuditorCorrectObservation"></textarea>
                        </mat-form-field>

                    </ng-container>
                    <ng-container *ngIf="this.showSecond">
                        <div class="col-12 col-sm-12 col-md-12">
                            <mat-radio-group aria-label="" formControlName="secondAuditorAnswer"
                                (change)="changeRadioSecond()">
                                <mat-radio-button [value]="true">Correto</mat-radio-button>
                                <mat-radio-button [value]="false">Incorreto</mat-radio-button>
                            </mat-radio-group>
                            <mat-form-field *ngIf="infoStruct.formGroup.get('secondAuditorAnswer').value == false"
                                appearance="outline">
                                <mat-label>Motivo</mat-label>
                                <mat-select formControlName="secondAuditorIdIncorrectClassificationReason"
                                    (selectionChange)="changeSelectIncorrectSecond()">
                                    <mat-option *ngFor="let item of listAllIncorrectClassificationReason"
                                        [value]="item.idIncorrectClassificationReason">{{item.description}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field
                                *ngIf="infoStruct.formGroup.get('secondAuditorIdIncorrectClassificationReason').value == 3"
                                appearance="outline">
                                <mat-label>Observação</mat-label>
                                <textarea matInput type="text"
                                    formControlName="secondAuditorIncorrectObservation"></textarea>
                                <mat-error
                                    *ngIf="infoStruct.formGroup.get('secondAuditorIncorrectObservation').invalid">A
                                    observação
                                    é obrigatória.</mat-error>
                            </mat-form-field>

                            <mat-form-field *ngIf="infoStruct.formGroup.get('secondAuditorAnswer').value == true"
                                appearance="outline">
                                <mat-label>Motivo</mat-label>
                                <mat-select formControlName="secondAuditorIdCorrectClassificationReason"
                                    (selectionChange)="changeSelectCorrectSecond()">
                                    <mat-option [value]="null"></mat-option>
                                    <mat-option *ngFor="let item of listAllCorrectClassificationReason"
                                        [value]="item.idCorrectClassificationReason">{{item.description}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field
                                *ngIf="infoStruct.formGroup.get('secondAuditorIdCorrectClassificationReason').value == 1"
                                appearance="outline">
                                <mat-label>Observação</mat-label>
                                <textarea matInput type="text"
                                    formControlName="secondAuditorCorrectObservation"></textarea>
                            </mat-form-field>

                        </div>
                    </ng-container>
                    <ng-container *ngIf="this.showOther && this.idAuditStatus != 4">
                        <div class="col-12 col-sm-12 col-md-12">
                            <mat-radio-group aria-label="" formControlName="otherAuditorAnswer"
                                (change)="changeRadioOther()">
                                <mat-radio-button [value]="true">Correto</mat-radio-button>
                                <mat-radio-button [value]="false">Incorreto</mat-radio-button>
                            </mat-radio-group>
                            <mat-form-field *ngIf="infoStruct.formGroup.get('otherAuditorAnswer').value == false"
                                appearance="outline">
                                <mat-label>Motivo</mat-label>
                                <mat-select formControlName="otherAuditorIdIncorrectClassificationReason"
                                    (selectionChange)="changeSelectIncorrectOther()">
                                    <mat-option *ngFor="let item of listAllIncorrectClassificationReason"
                                        [value]="item.idIncorrectClassificationReason">{{item.description}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field
                                *ngIf="infoStruct.formGroup.get('otherAuditorIdIncorrectClassificationReason').value == 3"
                                appearance="outline">
                                <mat-label>Observação</mat-label>
                                <textarea matInput type="text"
                                    formControlName="otherAuditorIncorrectObservation"></textarea>
                                <mat-error
                                    *ngIf="infoStruct.formGroup.get('otherAuditorIncorrectObservation').invalid">A
                                    observação
                                    é obrigatória.</mat-error>
                            </mat-form-field>

                            <mat-form-field *ngIf="infoStruct.formGroup.get('otherAuditorAnswer').value == true"
                                appearance="outline">
                                <mat-label>Motivo</mat-label>
                                <mat-select formControlName="otherAuditorIdCorrectClassificationReason"
                                    (selectionChange)="changeSelectCorrectOther()">
                                    <mat-option [value]="null"></mat-option>
                                    <mat-option *ngFor="let item of listAllCorrectClassificationReason"
                                        [value]="item.idCorrectClassificationReason">{{item.description}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field
                                *ngIf="infoStruct.formGroup.get('otherAuditorIdCorrectClassificationReason').value == 1"
                                appearance="outline">
                                <mat-label>Observação</mat-label>
                                <textarea matInput type="text"
                                    formControlName="otherAuditorCorrectObservation"></textarea>
                            </mat-form-field>

                        </div>
                    </ng-container>
                </div>
                <div *ngIf="isAuditConcluded" class="col-sm-12 col-md-4 col-lg-4 bottom-left quadrant">
                    <button mat-flat-button type="button" color="primary" class="btn-block triages-audit-button"
                        (click)="openResumeCompletedAudit()">
                        <span *ngIf="isLoading == false">
                            <mat-icon aria-hidden="false" aria-label="History">history</mat-icon>
                            Ver histórico completo
                        </span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
                <div class="col-sm-12 col-md-8 col-lg-8 quadrant section">
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <div class="audit-triage-field">
                                <span class="audit-triage-field-name">Pressão Arterial: </span>
                                <span *ngIf="infoStruct.bloodPressure">{{infoStruct.bloodPressure}} mmHg</span>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="audit-triage-field">
                                <span class="audit-triage-field-name">Glicemia: </span>
                                <span *ngIf="infoStruct.glucose">{{infoStruct.glucose}} mg/dL
                                    {{infoStruct.discriminator.toLocaleLowerCase().search('cetose') >= 0? 'com sinais de
                                    cetose.':''}}</span>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="audit-triage-field">
                                <span class="audit-triage-field-name">Temperatura: </span>
                                <span *ngIf="infoStruct.temperature">{{infoStruct.temperature}} C°</span>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="audit-triage-field">
                                <span class="audit-triage-field-name">Frequência Respiratória: </span>
                                <span *ngIf="infoStruct.respiratoryFrequency">{{infoStruct.respiratoryFrequency}}
                                    irpm</span>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="audit-triage-field">
                                <span class="audit-triage-field-name">Frequência Cardíaca: </span>
                                <span *ngIf="infoStruct.heartRate">{{infoStruct.heartRate}} bpm
                                    {{infoStruct.heartRateRegular
                                    === true ? "- Regular" : infoStruct.heartRateRegular === false ? "- Irregular" :
                                    ""}}
                                    {{infoStruct.isHeartRateSleeping === true? ' - Dormindo' :
                                    infoStruct.isHeartRateSleeping
                                    === false? ' - Acordado' : ''}}</span>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="audit-triage-field">
                                <span class="audit-triage-field-name">Perfusão Capilar: </span>
                                <span>{{infoStruct.capillaryFillingTime === false ? 'Menor que 2 segundos (<2s)':
                                        (infoStruct.capillaryFillingTime===true ? 'Maior que 2 segundos (>2s)' : ''
                                        )}}</span>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="audit-triage-field">
                                <span class="audit-triage-field-name">Escala de Coma de Glasgow: </span>
                                <span>{{infoStruct.glasgow}}</span>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="audit-triage-field">
                                <span class="audit-triage-field-name">Saturação: </span>
                                <span *ngIf="infoStruct.saturation">{{infoStruct.saturation}}%
                                    {{infoStruct.atmosphericAir
                                    === true ? "- Atmosférico" : infoStruct.heartRateRegular === false ?"- Terapia O2":
                                    ""}} </span>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="audit-triage-field">
                                <span class="audit-triage-field-name">Escala de Dor: </span>
                                <span *ngIf="infoStruct.idPain != null">
                                    {{infoStruct.painLevel}}
                                    <span
                                        *ngIf=" infoStruct.painLevel >= 1 && infoStruct.painLevel <= 4">{{infoStruct.idPainType>=min_less_seven
                                        && infoStruct.idPainType <= max_less_seven ?"- Menos de 7 dias":
                                            infoStruct.idPainType>=
                                            min_over_seven
                                            && infoStruct.idPainType<= max_over_seven ?"- Mais de 7 dias": "" }} </span>
                                    </span>
                                    <span class="bullet" [ngStyle]="{'background-color': painColorCode}"></span>
                            </div>
                        </div>
                        <div class="col-12 col-md-4" *ngIf="infoStruct.openProtocolTriage">
                            <div class="audit-triage-field">
                                <span class="audit-triage-field-name">Protocolo iniciado?: </span>
                                <span>{{infoStruct.openProtocolTriage ? " Sim " : " Não"}} </span>
                            </div>
                        </div>
                        <div class="col-12 col-md-4" *ngIf="infoStruct.openProtocolTriage">
                            <div class="audit-triage-field">
                                <span class="audit-triage-field-name">Nome do protocolo: </span>
                                <span>{{infoStruct.protocolName}} </span>
                            </div>
                        </div>
                        <div class="col-12 col-md-4" *ngIf="infoStruct.openProtocolTriage">
                            <div class="audit-triage-field">
                                <span class="audit-triage-field-name">Abertura: </span>
                                <span>{{infoStruct.datetimeRegisterProtocolCareLine | date:'dd/MM/yyyy HH:mm:ss'}}
                                </span>
                            </div>
                        </div>
                        <div class="col-12" *ngIf="infoStruct.openProtocolTriage">
                            <div class="audit-triage-field">
                                <span class="audit-triage-field-name">Direcionamentos do protocolo: </span>
                                <div *ngFor="let item of infoStruct.listNextStepsAfterTriageProtocolDescription">
                                    <span>{{item}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 section-observation"
                            *ngIf="infoStruct.justification != null && infoStruct.justification != ''">
                            <mat-form-field appearance="outline">
                                <mat-label>Observação</mat-label>
                                <textarea matInput readonly
                                    style="resize: none;">{{ infoStruct.justification }}</textarea>
                            </mat-form-field>
                        </div>
                        <div class="col-8 discriminatorSelectionMotive"
                            *ngIf="infoStruct.discriminatorSelectionMotive != null && infoStruct.discriminatorSelectionMotive != ''">
                            <div class="row">
                                <div class="col-12 col-md-6 audit-triage-field">
                                    <span class="audit-triage-field-name"></span>
                                    <span>*Não foi possível aferir os sinais vitais - Paciente AUTISTA (TEA)</span>
                                </div>
                                <div class="col-12 col-md-6 audit-triage-field">
                                    <span class="audit-triage-field-name">Motivo: </span>
                                    <span>{{infoStruct.discriminatorSelectionMotive}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>