import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { BiPanelRequest } from '../../requests/business-intelligence/bi-panel.request';
import { GetBiPanelResponse } from '../../responses/business-intelligence/get-bi-panel.response';
import { ListBiPanelResponse } from '../../responses/business-intelligence/list-bi-panel.response';

@Injectable({
  providedIn: 'root'
})
export class BiPanelService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listBiPanel(searchText: string): Observable<ListBiPanelResponse> {
    
    let uri = 'BiPanel?'
    
    if(searchText != null)
      uri = uri+ `searchText=${searchText}&`;
    
    return this.httpClient.get<ListBiPanelResponse>(environment.urlApiBusinessIntelligence + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public listAllBiPanel(): Observable<ListBiPanelResponse> {
    
    let uri = 'BiPanel/GetAll'
    
    return this.httpClient.get<ListBiPanelResponse>(environment.urlApiBusinessIntelligence + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public getBiPanel(idBiPanel: number): Observable<GetBiPanelResponse> {
    
    let uri = `BiPanel/idBiPanel/${idBiPanel}`;  
    
    return this.httpClient.get<GetBiPanelResponse>(environment.urlApiBusinessIntelligence + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public createBiPanel(body: BiPanelRequest): Observable<ReturnStruct>{

    let uri = `BiPanel`

    return this.httpClient.post<ReturnStruct>(environment.urlApiBusinessIntelligence + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public updateBiPanel(idBiPanel: number, body: BiPanelRequest): Observable<ReturnStruct>{

    let uri = `BiPanel/idBiPanel/${idBiPanel}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiBusinessIntelligence + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public deleteBiPanel(idBiPanel: number): Observable<ReturnStruct>{

    let uri = `BiPanel/idBiPanel/${idBiPanel}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiBusinessIntelligence + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
