import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ListExamResponse } from 'src/app/shared/services/responses/SADT/list-exam.response';
import { SadtModalComponent } from 'src/app/shared/components/sadt-modal/sadt-modal.component';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ReadExternalPrescriptionStruct } from 'src/app/shared/services/structs/orchestrator-patient/read-external-prescription.struct';
import { ExternalPrescriptionModalComponent } from 'src/app/shared/components/external-prescription-modal/external-prescription-modal.component';
import { SadtGroupStruct } from 'src/app/shared/services/structs/medical-record/sadt-group.struct';
import { ExamTypeModel } from 'src/app/shared/services/models/SADT/exam-type.model';
import { MultiProfessionalCareCompleteStruct } from 'src/app/shared/services/structs/multi-professional/multi-professional-care-complete.struct';
import { MultiProfessionalCareService } from 'src/app/shared/services/API/multi-professional/multi-professional-care.service';
import { ReportModalComponent } from 'src/app/shared/components/report-modal/report-modal.component';


@Component({
  selector: 'app-multi-professional-care-patient-history',
  templateUrl: './multi-professional-care-accordion.component.html',
  styleUrls: ['./multi-professional-care-accordion.component.css']
})
export class MultiProfessionalCareAccordionComponent implements OnInit {

  constructor(private dialog: MatDialog,
    private alertService: AlertService,
    private multiProfessionalCareService: MultiProfessionalCareService,
  ) { }


  @Input() multiProfessionalCare: MultiProfessionalCareCompleteStruct[] = [];
  @Input() listExam: ListExamResponse;
  @Input() listExamType: ExamTypeModel[];
  @Input() isLoading: boolean = false;

  ngOnInit(): void {
  }

  openExternalPrescriptionModal(prescription: ReadExternalPrescriptionStruct) {
    const dialogRef = this.dialog.open(ExternalPrescriptionModalComponent, {
      data: {
        prescription,
        readOnly: true,
      },
    });
  }

  openSadtModal(sadt: SadtGroupStruct, readOnly: boolean) {
    const dialogRef = this.dialog.open(SadtModalComponent, {
      data: {
        sadt,
        readOnly: true,
        listPlace: [{idPlace: 2, placeName: 'Externo'}],
        listExam: this.listExam,
        listExamType: this.listExamType,
      },
    });
  }

  downloadReport(idEpisode: number, reportName: string) {
    this.isLoading = true;
    this.multiProfessionalCareService.downloadReports(idEpisode, reportName).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        let reportDialog = this.dialog.open(ReportModalComponent, {
          data: {
            reportName: response.reportName,
            reportPdf64: response.reportPdf64,
          },
        });
        reportDialog.afterClosed().subscribe(result => {
        });
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}
