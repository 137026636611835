export enum AuditStatusEnum {
    PrimeiroAuditando = 1,
    PrimeiroAuditado = 2,
    SegundoAuditando = 3,
    Concluido = 4,
    Divergência = 5,
    Aguardando2ªAuditoria = 6,
    OpiniãoAguardando = 7,
    Auditado = 8,
    SegundoAuditado = 9,
    Auditando = 10,
    DivergênciaResolvida = 11
}