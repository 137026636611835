import { Component, Input, OnInit } from '@angular/core';
import { CompleteMedicalCareStruct } from 'src/app/shared/services/structs/orchestrator-patient/complete-medical-care.struct';
import { ReadMedicalPrescriptionStruct } from 'src/app/shared/services/structs/orchestrator-patient/read-medical-prescription.struct';
import { PrescriptionModalComponent } from '../prescription-modal/prescription-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ListMedicineResponse } from 'src/app/shared/services/responses/pharmacy/list-medicine.response';
import { SadtModalComponent } from 'src/app/shared/components/sadt-modal/sadt-modal.component';
import { LookupMedicalCareResponse } from 'src/app/shared/services/responses/medical-record/lookup-medical-care-response';
import { ReadExternalPrescriptionStruct } from 'src/app/shared/services/structs/orchestrator-patient/read-external-prescription.struct';
import { ExamTypeModel } from 'src/app/shared/services/models/SADT/exam-type.model';
import { MedicalProcedureStruct } from 'src/app/shared/services/structs/medical-record/medical-procedure.struct';
import { MedicalProcedureModalComponent } from '../medical-procedure-modal/medical-procedure-modal.component';
import { ExternalPrescriptionModalComponent } from 'src/app/shared/components/external-prescription-modal/external-prescription-modal.component';
import { MedicalCareSadtStruct } from 'src/app/shared/services/structs/medical-record/medical-care-sadt.struct';
import { StandardPrescriptionStruct } from 'src/app/shared/services/structs/medical-record/standard-prescription.struct';
import { MedicatedSolutionStruct } from 'src/app/shared/services/structs/medical-record/medicated-solution.struct';

@Component({
  selector: 'app-medical-care-accordion',
  templateUrl: './medical-care-accordion.component.html',
  styleUrls: ['./medical-care-accordion.component.css']
})
export class MedicalCareAccordionComponent implements OnInit {

  constructor(private dialog: MatDialog) { }

  @Input() medicalCare: CompleteMedicalCareStruct[] = [];
  @Input() lookupMedicalCareResponse: LookupMedicalCareResponse;
  @Input() listMedicine: ListMedicineResponse[] = [];
  @Input() listExam: ExamTypeModel[];

  ngOnInit(): void {
  }

  openPrecriptionModalWithMedicine(medicalPrescription: ReadMedicalPrescriptionStruct) {
    let prescription = new StandardPrescriptionStruct();
    prescription.readMedicalPrescriptionStruct = medicalPrescription;
    this.openPrescriptionModal(prescription, false);
  }

  openPrecriptionModalWithMedicatedSolution(medicalPrescription: MedicatedSolutionStruct) {
    let prescription = new StandardPrescriptionStruct();
    prescription.medicatedSolutionStruct = medicalPrescription;
    this.openPrescriptionModal(prescription, true);
  }

  openPrescriptionModal(prescription: StandardPrescriptionStruct, isMedicatedSolution: boolean) {
    const dialogRef = this.dialog.open(PrescriptionModalComponent, {
      data: {
        prescription,
        readOnly: true,
        lookupMedicalCareResponse: this.lookupMedicalCareResponse,
        listMedicine: this.listMedicine,
        isMedicatedSolution: isMedicatedSolution,
      },
    });
  }

  openProcedureModal(procedure: MedicalProcedureStruct) {
    const dialogRef = this.dialog.open(MedicalProcedureModalComponent, {
      data: {
        procedure: procedure,
        idMedicalProcedure: procedure.idMedicalProcedure,
      },
    });
  }

  openExternalPrescriptionModal(prescription: ReadExternalPrescriptionStruct) {
    const dialogRef = this.dialog.open(ExternalPrescriptionModalComponent, {
      data: {
        prescription,
        readOnly: true,
      },
    });
  }

  openSadtModal(sadt: MedicalCareSadtStruct, readOnly: boolean) {
    const dialogRef = this.dialog.open(SadtModalComponent, {
      data: {
        sadt,
        readOnly: true,
        listPlace: this.lookupMedicalCareResponse.listPlace,
        listExamType: this.listExam,
        listSadtSchedule: this.lookupMedicalCareResponse.listSadtSchedule,
      },
    });
  }
}