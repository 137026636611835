<div mat-dialog-content class="dialog-container">
    <div class="mat-dialog-content dialog-container">
        <form class="form" [formGroup]="model" (ngSubmit)="clickApply()">
            <div class="row">
                <div class="col-12 ngx-personalized">
                    <mat-form-field appearance="outline">
                        <ngx-mat-file-input formControlName="requiredfile" placeholder="Upload de nova Logo" required
                            [accept]="['.png']" (ngModelChange)="inputChange($event)">
                        </ngx-mat-file-input>
                    </mat-form-field>
                </div>
            </div>
        </form>
        <div *ngIf="oldSrc" class="logo-div">
            <h3>Logo Atual:</h3>
            <img [src]="oldSrc" />
        </div>
        <div *ngIf="newSrc" class="logo-div">
            <h3>Logo Nova:</h3>
            <img [src]="newSrc" />
        </div>
        <mat-slide-toggle style="margin: 10px 0px;" [(ngModel)]="useTolifeLogo">Utilizar, também, a logo da ToLife</mat-slide-toggle>
        <div class="footer-exam-modal-list">
            <div class="row">
                <div class="col-6 col-sm-6 col-md-6">
                    <button mat-flat-button color="primary" class="btn-block" (click)="clickApply()">
                        <span *ngIf="isLoading == false">Aplicar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
                <div class="col-6 col-sm-6 col-md-6">
                    <button mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">
                        <span *ngIf="this.uploadedLogo">Cancelar</span>
                        <span *ngIf="!this.uploadedLogo">Voltar</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>