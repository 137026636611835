<div class="container-info slide-bottom">
    <div class="button-container">
        <a class="back-btn" (click)="back()">
            <mat-icon>chevron_left</mat-icon>
            Voltar
        </a>
        <a color="accent" *ngIf="isMenu" class="delete-btn" (click)="confirmDeleteSignature()">
            <mat-icon>delete</mat-icon>
            Reiniciar
        </a>
    </div>
    <div class="provider-info">
        <img src="/assets/images/signature-providers/safe-id/safeid-logo.png">
        <span>{{title}}</span>
        <span>
            {{description}}
        </span>
    </div>
    <form appFormScroll *ngIf="ready && formMetaData != null && !isLoading" class="form" [formGroup]="model"
        (ngSubmit)="submit()">
        <div class="row" *ngFor="let item of model.controls | keyvalue : keyDescOrder">
            <mat-form-field appearance="outline">
                <mat-label>{{formMetaData[item.key].label}}</mat-label>
                <mat-icon *ngIf="formMetaData[item.key].hideable" matSuffix
                    (click)="toggleVisibility(item.key)">{{formMetaData[item.key].hide ?
                    'visibility' :
                    'visibility_off'}}</mat-icon>
                <input #input matInput type="{{formMetaData[item.key].hide ? 'password' : 'text'}}"
                    [mask]="formMetaData[item.key].mask" formControlName="{{item.key}}">
            </mat-form-field>
        </div>
        <button *ngIf="state != stateEnum.signable" mat-flat-button type="submit" color="primary"
            class="btn-block">Confirmar</button>
    </form>
    <app-loading-progress-bar *ngIf="isLoading"></app-loading-progress-bar>
</div>