import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { ReportModalComponent } from 'src/app/shared/components/report-modal/report-modal.component';
import { ClassificationTagEnum } from 'src/app/shared/enum/classification-tag.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { WristbandClassificationReportService } from 'src/app/shared/services/API/report/wristband-classification-report.service';
import { ClassificationPrintingToggleService } from 'src/app/shared/services/API/risk-classification/classification-printing-toggle.service';
import { IdentificationService } from 'src/app/shared/services/API/front-desk/identification.service';
import { WristbandDimensionsService } from 'src/app/shared/services/API/risk-classification/wristband-dimensions.service';
import { WristbandDimensions } from 'src/app/shared/services/models/front-desk/wristband-dimensions.model';
import { IdentificationModel } from 'src/app/shared/services/models/front-desk/identification.model';
import { WristbandClassificationReportRequest } from 'src/app/shared/services/requests/Report/wristband-classification-report.request';
import { ClassificationWristbandToggleRequest } from 'src/app/shared/services/requests/risk-classification/classification-printing.request';
import { ClassificationWristbandDimensionsRequest } from 'src/app/shared/services/requests/risk-classification/classification-wristband-dimensions.request';
import { StandardIdentificationEnum } from 'src/app/shared/enum/standard-identification.enum';

@Component({
  selector: 'app-classification-printing',
  templateUrl: './classification-printing.component.html',
  styleUrls: ['./classification-printing.component.css']
})
export class ClassificationPrintingComponent implements OnInit {

  constructor(public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private wristbandDimensionsService: WristbandDimensionsService,
    private alertService: AlertService,
    private identificationService: IdentificationService,
    public wristbandClassificationReportService: WristbandClassificationReportService,
    public classificationPrintingToggleService: ClassificationPrintingToggleService) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.classification;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.classification_printing;

  public model: UntypedFormGroup;
  public isLoading: boolean;
  public isWristbandActive: boolean = false;
  public classificationPrintingRequest: ClassificationWristbandToggleRequest;
  public classificationWristbandDimensionsRequest: ClassificationWristbandDimensionsRequest;
  public isOtherDimensionsSelected: boolean;
  public isFirstLoading: boolean;
  public listIdentification: IdentificationModel[];
  public isVisualizeLoading: boolean = false;
  public idWristbandDimensions: number;
  public classificationWristbandToggleRequest: ClassificationWristbandToggleRequest;
  public listWristbandDimensions: WristbandDimensions[];
  public wristbandDimensions: WristbandDimensions;
  public idIdentification: number;
  public idField: number;
  public wristbandDimensionsDescription: string;

  ngOnInit(): void {
    this.classificationPrintingRequest = new ClassificationWristbandToggleRequest();

    this.getWristbandConfig();

    this.populateDimensionsSelect();
    this.model = this.formBuilder.group({
      idWristbandDimensions: [],
      customWristbandWidth: [],
      customWristbandHeight: [],
      standardIdentification: []
    });
  }

  submit() {
    if (this.isLoading)
      return;

    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    this.isLoading = true;

    this.classificationPrintingRequest.idWristbandDimensions = this.model.get('idWristbandDimensions').value;
    this.classificationPrintingRequest.width = this.model.get('customWristbandWidth').value;
    this.classificationPrintingRequest.height = this.model.get('customWristbandHeight').value;
    this.classificationPrintingRequest.idIdentification = this.model.get('standardIdentification').value;
    this.classificationPrintingRequest.isActive = this.isWristbandActive;
  }

  getWristbandConfig() {
    this.classificationPrintingToggleService.getWristbandConfig().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.isWristbandActive = response.isActive;
        this.wristbandDimensions = response.wristbandDimensions;
        this.idIdentification = response.idIdentification;

        if (this.isWristbandActive)
          this.populateIdentificationSelect(this.idIdentification);

        if (response.wristbandDimensions.idWristbandDimensions == ClassificationTagEnum.Outras) {
          this.model.get('idWristbandDimensions').setValue(response.wristbandDimensions.idWristbandDimensions);
          this.model.get('customWristbandWidth').setValue(response.wristbandDimensions.width);
          this.model.get('customWristbandHeight').setValue(response.wristbandDimensions.height);
          this.model.get('standardIdentification').setValue(response.idIdentification);
          this.isLoading = false;
          this.isOtherDimensionsSelected = true;
        }
        else {
          this.model.get('idWristbandDimensions').setValue(response.wristbandDimensions.idWristbandDimensions);
          this.model.get('customWristbandWidth').setValue(response.wristbandDimensions.width);
          this.model.get('customWristbandHeight').setValue(response.wristbandDimensions.height);
          this.model.get('standardIdentification').setValue(response.idIdentification);
          this.model.get('customWristbandWidth').disable();
          this.model.get('customWristbandHeight').disable();
        }
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateIdentificationSelect(idIdentification) {
    this.identificationService.listIdentification().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listIdentification = response.listIdentification;

        if (idIdentification == null)
          this.model.get('standardIdentification').setValue(StandardIdentificationEnum.Episódio);
        else
          this.model.get('standardIdentification').setValue(idIdentification);
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateDimensionsSelect() {
    this.wristbandDimensionsService.listWristbandDimensions().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isWristbandActive = response.isActive;
        this.listWristbandDimensions = response.listWristbandDimensions;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  othersAlterDimensions() {
    if (!this.model.get('customWristbandHeight').value || !this.model.get('customWristbandWidth').value) {
      this.model.get('customWristbandWidth').setValidators([Validators.required]);
      this.model.get('customWristbandHeight').setValidators([Validators.required]);
      this.model.get('customWristbandWidth').updateValueAndValidity();
      this.model.get('customWristbandHeight').updateValueAndValidity();
      this.model.get('customWristbandWidth').markAsTouched();
      this.model.get('customWristbandHeight').markAsTouched();

      this.alertService.show('Erro', "Preencha a altura e largura para salvar a pulseira.", AlertType.error);
      return this.isLoading = false;
    }
    this.isLoading = false;

    this.classificationPrintingRequest.idWristbandDimensions = this.model.get('idWristbandDimensions').value;
    this.classificationPrintingRequest.width = this.model.get('customWristbandWidth').value;
    this.classificationPrintingRequest.height = this.model.get('customWristbandHeight').value;
    this.classificationPrintingRequest.idIdentification = this.model.get('standardIdentification').value;
    this.classificationPrintingRequest.isActive = this.isWristbandActive;
    this.classificationPrintingRequest.wristbandDimensionsDescription = this.listWristbandDimensions.find(x => x.idWristbandDimensions == this.classificationPrintingRequest.idWristbandDimensions).wristbandDimensionsDescription;

    this.classificationPrintingToggleService.postOthersDimensions(this.classificationPrintingRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        if (this.model.get('standardIdentification').value != StandardIdentificationEnum.Episódio) {
          this.idField = this.listIdentification.find(x => x.idIdentification == this.model.get('standardIdentification').value).idField;
          this.identificationRequired(this.idField)
        }

        this.isLoading = false;
        this.alertService.show('Sucesso', "Outras dimensões alterada com sucesso!", AlertType.success);
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  identificationRequired(idField: number) {
    this.identificationService.postIdentification(idField).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  toggleWristband(event: any) {
    if (event?.checked ?? false) {
      this.isWristbandActive = true;
      this.model.get('idWristbandDimensions').setValue(null);
      this.model.get('idWristbandDimensions').setValidators([Validators.required]);
      this.populateIdentificationSelect(this.idIdentification);
    }
    else {
      this.isWristbandActive = false;
      this.model.get('idWristbandDimensions').setValue(null);
      this.model.get('idWristbandDimensions').setValidators([]);
    }
    this.model.get('idWristbandDimensions').updateValueAndValidity();
    this.changeDimensionsSelect(null);
    if (event.checked == false)
      this.confirmWristbandToggle();
  }

  changeDimensionsSelect(event: any) {
    if (event?.value == ClassificationTagEnum.Outras ?? false) {
      this.isOtherDimensionsSelected = true;
      if (this.listWristbandDimensions) {
        this.model.get('customWristbandWidth').setValue(this.listWristbandDimensions.find(x => x.idWristbandDimensions == event.value).width);
        this.model.get('customWristbandHeight').setValue(this.listWristbandDimensions.find(x => x.idWristbandDimensions == event.value).height);
      }
      this.model.get('customWristbandWidth').setValidators([Validators.required, Validators.min(70)]);
      this.model.get('customWristbandHeight').setValidators([Validators.required, Validators.min(20)]);
      this.model.get('customWristbandWidth').enable();
      this.model.get('customWristbandHeight').enable();
    }
    else {
      this.isOtherDimensionsSelected = false;
      if (event) {
        this.model.get('customWristbandWidth').setValue(this.listWristbandDimensions.find(x => x.idWristbandDimensions == event.value).width);
        this.model.get('customWristbandHeight').setValue(this.listWristbandDimensions.find(x => x.idWristbandDimensions == event.value).height);
      }
      this.model.get('customWristbandWidth').setValidators([]);
      this.model.get('customWristbandHeight').setValidators([]);
      this.model.get('customWristbandWidth').disable();
      this.model.get('customWristbandHeight').disable();
    }

    this.model.get('customWristbandWidth').updateValueAndValidity();
    this.model.get('customWristbandHeight').updateValueAndValidity();

    if (event) {
      this.classificationPrintingRequest.idWristbandDimensions = this.model.get('idWristbandDimensions').value;
      this.classificationPrintingRequest.width = this.model.get('customWristbandWidth').value;
      this.classificationPrintingRequest.height = this.model.get('customWristbandHeight').value;
      this.classificationPrintingRequest.isActive = this.isWristbandActive;
      this.isLoading = true;

      this.confirmWristbandToggle();
    }
  }

  confirmWristbandToggle() {
    this.isLoading = false;

    this.classificationPrintingToggleService.postOthersDimensions(this.classificationPrintingRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Configuração alterada com sucesso!", AlertType.success);
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  visualizeTemplate() {
    if (this.isVisualizeLoading)
      return;
    this.isVisualizeLoading = true;

    if (!this.model.get('customWristbandHeight').value || !this.model.get('customWristbandWidth').value) {
      this.alertService.show('Erro', "Preencha a altura e largura para visualizar a pulseira.", AlertType.error);
      this.isVisualizeLoading = false;
      return;
    }
    let wristbandReportRequest: WristbandClassificationReportRequest = new WristbandClassificationReportRequest();

    wristbandReportRequest.height = this.model.get('customWristbandHeight').value;
    wristbandReportRequest.width = this.model.get('customWristbandWidth').value;
    wristbandReportRequest.patientName = "Nome paciente";
    if (this.model.get('standardIdentification').value == StandardIdentificationEnum.PlanoDeSaúde)
      wristbandReportRequest.identificationName = "Plano de Saúde"
    else
      wristbandReportRequest.identificationName = this.listIdentification.find(x => x.idIdentification == this.model.get('standardIdentification').value).identificationName;
    wristbandReportRequest.identificationDescription = "174581924736515"
    wristbandReportRequest.motherName = "Mãe do paciente";
    wristbandReportRequest.gravityName = "Tag de gravidade";
    wristbandReportRequest.idEpisode = 25;
    wristbandReportRequest.idGravity = 2;
    wristbandReportRequest.gravityColor = "#ff0000";
    wristbandReportRequest.patientBirthDate = "07-08-1997";

    this.wristbandClassificationReportService.getReport(wristbandReportRequest, 1).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isVisualizeLoading = false;
          return;
        }

        let reportDialog = this.dialog.open(ReportModalComponent, {
          data: {
            reportName: response.reportName,
            reportPdf64: response.reportPdf64,
          },
        });

        this.isVisualizeLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isVisualizeLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}