<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <h1>Configurações de regras</h1>
        <div class="white-body">
            <div class="row header">
                <div class="col-12 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-icon matPrefix class="my-icon">search</mat-icon>
                        <mat-label>Pesquisar</mat-label>
                        <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText"
                            (keyup)="onKeySearch($event)">
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-4">
                    <a mat-flat-button color="primary" class=" add-user"
                        routerLink="/flow-management/kpi-config-register"><mat-icon aria-hidden="false"
                            aria-label="Plus">add</mat-icon> Cadastrar Configuração
                    </a>
                </div>
            </div>
            <div class="card-list" *ngIf="listKpi != null">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listKpi">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary"><b>Setor:</b> {{item.sectorName}}</span>
                                <span class="secundary">Criado em: {{item.datetimeInclusion | date:'dd/MM/yyyy -
                                    HH:mm'}}</span>
                            </div>
                            <div class="actions">
                                <a matTooltip="Editar"><mat-icon aria-hidden="false" aria-label="Editar"
                                        [routerLink]="['/flow-management/kpi-config-register', {idSector: item.idSector}]">edit</mat-icon>
                                </a>
                                <a matTooltip="Excluir"><mat-icon aria-hidden="false" aria-label="Editar"
                                        (click)="openModal(item.idSector)">delete_forever</mat-icon> </a>
                                <a matTooltip="Criar regra a partir desta"><mat-icon aria-hidden="false"
                                        aria-label="Copiar"
                                        [routerLink]="['/flow-management/kpi-config-register', {copyFrom: item.idSector}]">control_point_duplicate</mat-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <app-empty-list *ngIf="listKpi != undefined && listKpi.length == 0 && !isLoading"></app-empty-list>
        </div>

        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>

</div>