<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    <div class="container-body">
        <div class="white-body">
            <div class="title-header">
                <h1>Overview</h1>
            </div>
            <form class="form" [formGroup]="model">
                <div class="row header-container">
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-card class="border-card">
                            <mat-card-content class="card-content">Custo Médio Mensal</mat-card-content>
                            <p *ngIf="this.annualAverage != null" class="text-p">R$ {{this.annualAverage | number: '1.2-2'}}
                            </p>
                        </mat-card>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3 select-period">
                        <mat-form-field appearance="outline">
                            <mat-label>Selecione um período</mat-label>
                            <mat-select formControlName="idBillingYear">
                                <mat-option value="{{item.idBillingYear}}" *ngFor="let item of listBillingYear"
                                    (click)="searchByPeriod(item)">{{item.year}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </form>
            <div class="charts-container" *ngIf="this.year.length > 0">
                <ngx-charts-bar-vertical-2d [scheme]="colorScheme" [results]="year" [xAxis]="showXAxis"
                    [legend]="showLegend" [yAxis]="showYAxis" [showXAxisLabel]="showXAxisLabel" [legendTitle]="legendTitle"
                    [showYAxisLabel]="showYAxisLabel" [noBarWhenZero]="noBarWhenZero" (select)="onSelect($event)"
                    (activate)="onActivate($event)" (deactivate)="onDeactivate($event)">
                </ngx-charts-bar-vertical-2d>
            </div>
        </div>
        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>
    
</div>