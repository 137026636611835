import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { ScheduleLookupService } from 'src/app/shared/services/API/schedule/schedule-lookup.service';
import { ScheduleService } from 'src/app/shared/services/API/schedule/schedule.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { Day } from 'src/app/shared/services/models/schedule/day.model';
import { Frequency } from 'src/app/shared/services/models/schedule/frequency.model';
import { PriorityModel } from 'src/app/shared/services/models/totem/priority.model';
import { ScheduleRequest } from 'src/app/shared/services/requests/schedule/schedule.request';
import { BlockedDaysStruct } from 'src/app/shared/services/structs/schedule/blocked-days.struct';
import { ScheduleDayStruct } from 'src/app/shared/services/structs/schedule/schedule-day.struct';
import { ListUserStruct } from 'src/app/shared/services/structs/user/list-user.struct';
import { ViewCalendarScheduleModalComponent } from './view-calendar-schedule-modal/view-calendar-schedule-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { TimeStruct } from 'src/app/shared/structs/time.struct';
import { TimeSpanStruct } from 'src/app/shared/structs/time-span.struct';
import { BlockedDaysModalComponent } from './blocked-days-modal/blocked-days-modal.component';
import { ValidateDateRangeRequest } from 'src/app/shared/services/requests/schedule/validate-date-range.request';
import { BlockedDaysDeleteModalComponent } from './blocked-days-delete-modal/blocked-days-delete-modal.component';
import { MedicalSpecialtyService } from 'src/app/shared/services/API/digital-prompt/medical-specialty.service';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { PreventedChangingTimeCalendarModalComponent } from './prevented-changing-time-calendar-modal/prevented-changing-time-calendar-modal.component';
import { PatientAppointment } from 'src/app/shared/services/models/schedule/patient-appointment.model';
import { UserPatientService } from 'src/app/shared/services/API/patient-center/user-patient.service';
import { ListUserScheduleService } from 'src/app/shared/services/API/orchestrator-schedule/list-user-schedule.service';
import { ErrorInScheduleChangeEnum } from 'src/app/shared/enum/schedule/error-in-schedule-change.enum';
import { MedicalSpecialtyStruct } from 'src/app/shared/services/structs/digital-prompt-service/medical-specialty.struct';

@Component({
  selector: 'app-schedule-register',
  templateUrl: './schedule-register.component.html',
  styleUrls: ['./schedule-register.component.css']
})
export class ScheduleRegisterComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private scheduleService: ScheduleService,
    private scheduleLookupService: ScheduleLookupService,
    private listUserScheduleService: ListUserScheduleService,
    private medicalSpecialtyService: MedicalSpecialtyService,
    public dialog: MatDialog,
    private userPatientService: UserPatientService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.digital_prompt_service;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.schedule_schedule;

  public model: FormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;
  public masks: Masks;
  public listUser: ListUserStruct[];
  public listDay: Day[];
  public listFrequency: Frequency[];
  public listPrioritiesNames: PriorityModel[] = [];
  public listMedicalSpecialty: MedicalSpecialtyStruct[] = [];
  public idSchedule: number;
  public listTimes: string[] = [];
  public filteredOptions: string[];
  public appointmentTime: number;
  public today = new Date();
  public minDate: Date;
  public inEffect = false;
  public currentAppointmentDuration: number;
  public errorInScheduleChangeEnum: typeof ErrorInScheduleChangeEnum = ErrorInScheduleChangeEnum;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      idUser: ['', [Validators.required]],
      description: [''],
      appointmentTime: ['', [Validators.required, Validators.min(10)]],
      startDate: [{ value: null, disabled: true }, [Validators.required]],
      expirationDate: [{ value: null, disabled: true }, [Validators.required]],
      listWeekDay: this.formBuilder.array([]),
      listBlockedDays: this.formBuilder.array([]),
    });
    this.isLoading = false;
    this.masks = this.maskService.getMasks();
    this.populateUserSelect();
    this.populateLookup();
    //this.populateSpecialty();
    this.idSchedule = null;
    this.isUpdate = false;
    if (this.activatedRoute.snapshot.paramMap.get('idSchedule')) {
      this.idSchedule = parseInt(this.activatedRoute.snapshot.paramMap.get('idSchedule'));
      this.isUpdate = true;
    }
  }

  validateUser() {
    let idUser = this.model.get('idUser').value;

    if (idUser) {
      this.model.get('startDate').enable();
      this.model.get('expirationDate').enable();
      this.verifyDateRange();
      this.populateSpecialty(idUser, false);
    }
  }

  validateTimeInputs() {
    this.model.updateValueAndValidity();
    let appointmentTime = this.model.get('appointmentTime').value;
    this.appointmentTime = appointmentTime;

    if (appointmentTime) {
      this.model.get('listWeekDay')['controls'].forEach(x => {
        if (!x.disabled) {
          let listEvent = x.get('listEvent');
          listEvent['controls'].forEach(y => {
            y.get('startTime').enable();
            y.get('endTime').enable();
          });
        }
      });
    }

    else {
      this.model.get('listWeekDay')['controls'].forEach(x => {
        if (!x.disabled) {
          let listEvent = x.get('listEvent');
          listEvent['controls'].forEach(y => {
            y.get('startTime').disable();
            y.get('endTime').disable();
          });
        }
      });
    }
  }

  submit() {
    if (this.isLoading)
      return;

    if(Number(this.model.get('appointmentTime').value) < 10){
      this.alertService.show('Aviso!', 'O tempo de duração não pode ser menor que 10 min.', AlertType.warning);
      return;
    }
    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    let request = this.mapRequest();

    if (this.isUpdate)
      this.updateSchedule(request);
    else
      this.createSchedule(request);
  }

  populateScheduleData() {
    this.scheduleService.getSchedule(this.idSchedule).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        let start = new Date(response.schedule.dateStart);
        let end = new Date(response.schedule.dateEnd);

        if ((start.getTime() <= this.today.getTime()) && (end.getTime() >= this.today.getTime()))
          this.inEffect = true;

        this.minDate = start;

        this.model.get('idUser').setValue(response.schedule.idUser);
        this.populateSpecialty(response.schedule.idUser, true);
        this.model.get('description').setValue(response.schedule.description);
        this.model.get('appointmentTime').setValue(response.schedule.appointmentDuration);
        this.model.get('expirationDate').setValue(response.schedule.dateEnd);
        this.model.get('startDate').setValue(response.schedule.dateStart);

        this.model.get('startDate').enable();
        this.model.get('expirationDate').enable();

        this.currentAppointmentDuration = response.schedule.appointmentDuration;

        let weekDaysArray = (this.model.controls['listWeekDay'] as FormArray);

        for (let i = 0; i < response.listScheduleDay.length; i++) {
          let idWeekDay = this.listDay.find(x => x.idDay == response.listScheduleDay[i].idDay).idDay;

          let weekDayForm = weekDaysArray.get(idWeekDay.toString());
          weekDayForm.enable();

          let scheduleDays = response.listScheduleDay.filter(x => x.idDay == weekDayForm.get('idDay').value)

          let listEvent = (weekDayForm.get('listEvent') as FormArray);
          listEvent.clear();

          for (let j = 0; j < scheduleDays.length; j++) {
            let scheduleDay: ScheduleDayStruct = scheduleDays[j];
            listEvent.push(this.createInput(scheduleDay));
          }
        }

        let blockedDaysArray = (this.model.controls['listBlockedDays'] as FormArray);

        for (let i = 0; i < response.listBlockedDays.length; i++) {
          blockedDaysArray.push(this.createBlockedDay(response.listBlockedDays[i]))
        }

        for (let i = 0; i < weekDaysArray.length; i++) {
          let dayForm = weekDaysArray.get(i.toString());

          if (!dayForm.valid)
            dayForm.disable();
        }

        this.validateTimeInputs();

        this.isLoading = false;
        this.isFirstLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  createInput(scheduleDay?: ScheduleDayStruct): AbstractControl {
    if (scheduleDay) {

      let startTime: string[] = scheduleDay.timeStart.toString().split('T')[1].split(':');
      let endTime: string[] = scheduleDay.timeEnd.toString().split('T')[1].split(':');

      return this.formBuilder.group({
        idSchedule: [scheduleDay.idSchedule],
        idScheduleDay: [scheduleDay.idScheduleDay],
        idSpecialty: [scheduleDay.idSpecialty, [Validators.required]],
        startTime: [startTime[0] + startTime[1], [Validators.required]],
        endTime: [endTime[0] + endTime[1], [Validators.required]],
      });
    }
    else {
      return this.formBuilder.group({
        idSchedule: [null],
        idScheduleDay: [null],
        idSpecialty: [null, [Validators.required]],
        startTime: [{ value: null, disabled: !this.model.get('appointmentTime').value }, [Validators.required]],
        endTime: [{ value: null, disabled: !this.model.get('appointmentTime').value }, [Validators.required]],
      });
    }
  }

  updateSchedule(request: ScheduleRequest) {
    this.isLoading = true;
    this.scheduleService.putSchedule(request, this.idSchedule).subscribe({
      next: (response) => {
        if (response.isError && (response.errorCode === this.errorInScheduleChangeEnum.ErroNaAlteracaoDoDia || response.errorCode === this.errorInScheduleChangeEnum.ErroNoTempoDeAtendimento)) {
          this.populateUserName(response.listAffectedPatient, response.errorCode);
          this.isLoading = false;
          return;
        }
        else if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Agenda atualizada com sucesso", AlertType.success);
        this.isLoading = false;

        this.router.navigate(['/digital-prompt-service/patient-center/schedule/list']);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  createSchedule(request: ScheduleRequest) {
    this.isLoading = true;
    this.scheduleService.postSchedule(request).subscribe({
      next: (response) => {
        if (response.isError && response.errorCode == 1) {
          this.alertService.show('Erro', "É necessário preencher pelo menos um dia com horários disponíveis.", AlertType.error);
          this.isLoading = false;
          return;
        }
        else if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Agenda criada com sucesso", AlertType.success);
        this.isLoading = false;

        this.router.navigate(['/digital-prompt-service/patient-center/schedule/list']);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  createWeekDay(day: Day): AbstractControl {
    let dayForm = this.formBuilder.group({
      idDay: [day.idDay],
      dayName: [day.dayName.slice(0, 3)],
      listEvent: this.formBuilder.array([]),
    }, {
      validators: [this.VerifyTimeRanges()]
    });

    if (this.isUpdate) {
      dayForm.disable();
      dayForm.get('listEvent').disable();
    }

    return dayForm;
  }

  createBlockedDay(blockedDay?: BlockedDaysStruct): AbstractControl {
    if (blockedDay) {
      let startTime: string[] = blockedDay.startTime.toString().split('T')[1].split(':');
      let endTime: string[] = blockedDay.endTime.toString().split('T')[1].split(':');

      let frequency = this.listFrequency.find(x => x.idFrequency == blockedDay.idFrequency);

      return this.formBuilder.group({
        idBlockedDays: [blockedDay.idBlockedDays],
        idDay: [blockedDay.idDay, [Validators.required]],
        dayName: [this.listDay.find(x => x.idDay == blockedDay.idDay).dayName],
        motive: [blockedDay.motive],
        repetition: [blockedDay.repetition ? true : false],
        repetitionNumber: [blockedDay.repetition],
        idFrequency: [blockedDay.idFrequency],
        frequencyName: [frequency ? frequency.frequencyName : ''],
        startTime: [startTime[0] + startTime[1], [Validators.required]],
        endTime: [endTime[0] + endTime[1], [Validators.required]],
      });
    }
    else {
      return this.formBuilder.group({
        idBlockedDays: [null],
        idDay: [null, [Validators.required]],
        dayName: [''],
        motive: [''],
        repetition: [false],
        repetitionNumber: [null],
        idFrequency: [null],
        frequencyName: [''],
        startTime: [null, [Validators.required]],
        endTime: [null, [Validators.required]],
      });
    }
  }

  addNext(index: number) {
    if (this.model.get('idUser').invalid && this.model.get('appointmentTime').invalid) {
      this.model.get('idUser').markAsTouched();
      this.model.get('appointmentTime').markAsTouched();
      return;
    }

    let events = this.model.get('listWeekDay')['controls'][index].controls['listEvent'] as FormArray;

    if (this.model.get('listWeekDay')['controls'][index].enabled)
      events.push(this.createInput());
    else
      this.model.get('listWeekDay')['controls'][index].enable();
  }

  removeButton(index: number, subIndex) {
    let events = this.model.get('listWeekDay')['controls'][index].controls['listEvent']

    if (events.length > 1)
      (events as FormArray).removeAt(subIndex);
    else
      this.model.get('listWeekDay')['controls'][index].disable();
  }

  removeBlockedDay(index: number) {
    (this.model.controls['listBlockedDays'] as FormArray).removeAt(index);
  }

  populateUserSelect() {
    this.listUserScheduleService.listUser().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listUser = response.listUser;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateLookup() {
    this.scheduleLookupService.listLookup().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listDay = response.listDay.sort((a, b) => a.idDay - b.idDay);
        this.listFrequency = response.listFrequency;

        this.listDay.forEach((day, index) => {
          (this.model.controls['listWeekDay'] as FormArray).push(this.createWeekDay(day));
          (this.model.get('listWeekDay')['controls'][index].controls['listEvent'] as FormArray).push(this.createInput());
        });

        if (this.isUpdate) {
          this.isFirstLoading = true;
          this.populateScheduleData();
        }
        else
          this.minDate = this.today;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openPreview() {
    if (this.isLoading)
      return;

    if (!this.model.valid) {
      this.model.markAllAsTouched();
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }
    if(Number(this.model.get('appointmentTime').value) < 10){
      this.alertService.show('Aviso!', 'O tempo de duração não pode ser menor que 10 min.', AlertType.warning);
      return;
    }

    // this.mapRequest();
    let scheduleRequest = new ScheduleRequest();
    scheduleRequest = this.mapRequestToPreview();

    this.dialog.open(ViewCalendarScheduleModalComponent, {
      data: {
        scheduleRequest: scheduleRequest
      },
      panelClass: 'view-calendar-schedule-modal'
    });
  }

  mapRequest(): ScheduleRequest {
    let scheduleRequest = new ScheduleRequest();

    scheduleRequest.listScheduleDay = [];//botões +-
    scheduleRequest.listBlockedDays = [];//cards

    scheduleRequest.appointmentDuration = this.model.get('appointmentTime').value;

    scheduleRequest.datetimeStart = new Date(this.model.get('startDate').value.toString() + 'Z');
    scheduleRequest.datetimeEnd = new Date(this.model.get('expirationDate').value.toString().replace("00:00:00","23:59:59") + 'Z');   
    scheduleRequest.description = this.model.get('description').value;
    scheduleRequest.idUser = this.model.get('idUser').value;

    this.model.get('listWeekDay').value.forEach(weekDay => {
      if (!weekDay.disabled) {
        if (weekDay.invalid) {
          this.alertService.show('Atenção', 'Preencha todos os campos em vermelho', AlertType.warning);
          return;
        }

        let events = weekDay.listEvent;
        for (let i = 0; i < events.length; i++) {
          var scheduleDayStruct: ScheduleDayStruct = new ScheduleDayStruct();

          let event = events[i];

          if (!event.startTime || !event.endTime)
            continue;

          scheduleDayStruct.idSchedule = this.isUpdate ? this.idSchedule : null;
          scheduleDayStruct.idDay = weekDay.idDay;
          scheduleDayStruct.dayName = weekDay.dayName;

          let startTime: TimeStruct = {
            hours: parseInt(event.startTime.slice(0, 2)),
            minutes: parseInt(event.startTime.slice(2, 5))
          }

          let endTime: TimeStruct = {
            hours: parseInt(event.endTime.slice(0, 2)),
            minutes: parseInt(event.endTime.slice(2, 5))
          }

          let auxDate = new Date().toISOString().split('Z')[0];

          let timeStart = new Date(auxDate);
          timeStart.setUTCHours(startTime.hours);
          timeStart.setMinutes(startTime.minutes);
          timeStart.setSeconds(0);
          scheduleDayStruct.timeStart = timeStart;

          let timeEnd = new Date(auxDate);
          timeEnd.setUTCHours(endTime.hours);
          timeEnd.setMinutes(endTime.minutes);
          timeEnd.setSeconds(0);
          scheduleDayStruct.timeEnd = timeEnd;

          scheduleDayStruct.idSpecialty = event.idSpecialty;

          scheduleRequest.listScheduleDay.push(scheduleDayStruct);
        };
      }
    });

    this.model.get('listBlockedDays').value.forEach(blockade => {
      var blockedDaysStruct = new BlockedDaysStruct();

      blockedDaysStruct.idDay = blockade.idDay;
      blockedDaysStruct.motive = blockade.motive;
      blockedDaysStruct.idFrequency = blockade.idFrequency;
      blockedDaysStruct.repetition = blockade.repetition ? blockade.repetitionNumber : null;

      let startTime: TimeStruct = {
        hours: parseInt(blockade.startTime.slice(0, 2)),
        minutes: parseInt(blockade.startTime.slice(2, 5))
      }

      let endTime: TimeStruct = {
        hours: parseInt(blockade.endTime.slice(0, 2)),
        minutes: parseInt(blockade.endTime.slice(2, 5))
      }

      let timeStart = new Date();
      timeStart.setUTCHours(startTime.hours);
      timeStart.setMinutes(startTime.minutes);
      timeStart.setSeconds(0);

      blockedDaysStruct.startTime = timeStart;

      let timeEnd = new Date();
      timeEnd.setUTCHours(endTime.hours);
      timeEnd.setMinutes(endTime.minutes);
      timeEnd.setSeconds(0);

      blockedDaysStruct.endTime = timeEnd;

      scheduleRequest.listBlockedDays.push(blockedDaysStruct);

    });
    return scheduleRequest;
  }

  mapRequestToPreview(): ScheduleRequest {
    let scheduleRequest = new ScheduleRequest();

    scheduleRequest.listScheduleDay = [];//botões +-
    scheduleRequest.listBlockedDays = [];//cards

    scheduleRequest.appointmentDuration = this.model.get('appointmentTime').value;

    scheduleRequest.datetimeStart = new Date(this.model.get('startDate').value.toString());
    scheduleRequest.datetimeEnd = new Date(this.model.get('expirationDate').value.toString());

    scheduleRequest.description = this.model.get('description').value;
    scheduleRequest.idUser = this.model.get('idUser').value;

    this.model.get('listWeekDay').value.forEach(weekDay => {
      if (!weekDay.disabled) {
        let events = weekDay.listEvent;
        for (let i = 0; i < events.length; i++) {
          var scheduleDayStruct: ScheduleDayStruct = new ScheduleDayStruct();

          let event = events[i];

          if (!event.startTime || !event.endTime)
            continue;

          let startTime: TimeStruct = {
            hours: parseInt(event.startTime.slice(0, 2)),
            minutes: parseInt(event.startTime.slice(2, 5))
          }

          let endTime: TimeStruct = {
            hours: parseInt(event.endTime.slice(0, 2)),
            minutes: parseInt(event.endTime.slice(2, 5))
          }
          scheduleDayStruct.idSchedule = this.isUpdate ? this.idSchedule : null;
          scheduleDayStruct.idDay = weekDay.idDay;
          scheduleDayStruct.dayName = weekDay.dayName;

          // let auxDate = new Date().toLocaleString('pt-BR', { 'timeZone': 'UTC' });
          let auxDate = new Date().toISOString().split('Z')[0];

          let timeStart = new Date(auxDate);
          timeStart.setHours(startTime.hours);
          timeStart.setMinutes(startTime.minutes);
          timeStart.setSeconds(0);
          scheduleDayStruct.timeStart = timeStart;

          let timeEnd = new Date(auxDate);
          timeEnd.setHours(endTime.hours);
          timeEnd.setMinutes(endTime.minutes);
          timeEnd.setSeconds(0);
          scheduleDayStruct.timeEnd = timeEnd;

          scheduleRequest.listScheduleDay.push(scheduleDayStruct);
        };
      }
    });

    this.model.get('listBlockedDays').value.forEach(blockade => {
      var blockedDaysStruct = new BlockedDaysStruct();

      blockedDaysStruct.idDay = blockade.idDay;
      blockedDaysStruct.motive = blockade.motive;
      blockedDaysStruct.idFrequency = blockade.idFrequency;
      blockedDaysStruct.repetition = blockade.repetition ? blockade.repetitionNumber : null;

      let startTime: TimeStruct = {
        hours: parseInt(blockade.startTime.slice(0, 2)),
        minutes: parseInt(blockade.startTime.slice(2, 5))
      }

      let endTime: TimeStruct = {
        hours: parseInt(blockade.endTime.slice(0, 2)),
        minutes: parseInt(blockade.endTime.slice(2, 5))
      }

      let timeStart = new Date();
      timeStart.setHours(startTime.hours);
      timeStart.setMinutes(startTime.minutes);
      timeStart.setSeconds(0);

      blockedDaysStruct.startTime = timeStart;

      let timeEnd = new Date();
      timeEnd.setHours(endTime.hours);
      timeEnd.setMinutes(endTime.minutes);
      timeEnd.setSeconds(0);

      blockedDaysStruct.endTime = timeEnd;

      scheduleRequest.listBlockedDays.push(blockedDaysStruct);

    });
    return scheduleRequest;
  }

  VerifyTimeRanges(): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors | null => {
      if (!formGroup)
        return null;

      let events = formGroup.get('listEvent').getRawValue();

      let intervals = new Set<TimeSpanStruct>;

      let missing: boolean[] = [];
      let missingError = { missing: true };

      let invalidTime: boolean[] = [];
      let invalidTimeError = { invalidTime: true };

      let intersection: boolean[] = [];
      let intersectionError = { intersection: true };

      let inadequateRange: boolean[] = [];
      let inadequateRangeError = { inadequateRange: true };

      let invalidRange: boolean[] = [];
      let invalidRangeError = { invalidRange: true };

      let duration = this.model.get('appointmentTime').value;

      for (let i = 0; i < events.length; i++) {
        let event = events[i];
        if (formGroup.enabled && (!event.startTime || !event.endTime)) {
          if (!event.startTime)
            formGroup.get('listEvent').get(i.toString()).get('startTime').setErrors(missingError);

          if (!event.endTime)
            formGroup.get('listEvent').get(i.toString()).get('endTime').setErrors(missingError);

          invalidTime.push(true);
          continue;
        }

        //Validando se o formato de tempo é válido
        let expression: RegExp = /^([0-1]?[0-9]|2[0-3])[0-5][0-9]$/;

        if (!expression.test(event.startTime) || (event.startTime.length != 4)) {
          formGroup.get('listEvent').get(i.toString()).get('startTime').setErrors(invalidTimeError);
          invalidTime.push(true);
        }
        else if (!expression.test(event.endTime) || (event.endTime.length != 4)) {
          formGroup.get('listEvent').get(i.toString()).get('endTime').setErrors(invalidTimeError);
          invalidTime.push(true);
        }

        if (invalidTime.some(x => x))
          continue;

        let startTime: TimeStruct = {
          hours: parseInt(event.startTime.slice(0, 2)),
          minutes: parseInt(event.startTime.slice(2, 5))
        }

        let endTime: TimeStruct = {
          hours: parseInt(event.endTime.slice(0, 2)),
          minutes: parseInt(event.endTime.slice(2, 5))
        }

        let interval = new TimeSpanStruct(startTime, endTime);

        //Validando se o intervalo faz sentido

        if (interval.endTime.hours < interval.startTime.hours) {
          invalidRange.push(true);
          let dayEvent = formGroup.get('listEvent').get(i.toString());
          dayEvent.get('startTime').setErrors(invalidRangeError);
          dayEvent.get('endTime').setErrors(invalidRangeError);
          dayEvent.markAllAsTouched();
          continue;
        }

        else if (interval.endTime.hours === interval.startTime.hours) {
          if (interval.endTime.minutes <= interval.startTime.minutes) {
            invalidRange.push(true);
            let dayEvent = formGroup.get('listEvent').get(i.toString());
            dayEvent.get('startTime').setErrors(invalidRangeError);
            dayEvent.get('endTime').setErrors(invalidRangeError);
            dayEvent.markAllAsTouched();
            continue;
          }
        }

        //Validando se o intervalo se adequa ao tempo de atendimento

        let fullMinutes = (interval.endTime.hours - interval.startTime.hours) * 60 + (interval.endTime.minutes - interval.startTime.minutes);

        if (fullMinutes % duration != 0) {
          inadequateRange.push(true);
          let dayEvent = formGroup.get('listEvent').get(i.toString());
          dayEvent.get('startTime').setErrors(inadequateRangeError);
          dayEvent.get('endTime').setErrors(inadequateRangeError);
          dayEvent.markAllAsTouched();
          continue;
        }

        //Validando se há alguma sobreposição de horários

        intervals.add(interval);

        intervals.forEach(element => {
          if (element === interval) {
            //pass
          }
          else {
            if (element.intersects(interval)) {
              intersection.push(true);
              let dayEvent = formGroup.get('listEvent').get(i.toString());
              dayEvent.get('startTime').setErrors(intersectionError);
              dayEvent.get('endTime').setErrors(intersectionError);
              dayEvent.markAllAsTouched();
            }
          }
        });
      };

      if (invalidTime.some(x => x))
        return invalidTimeError;

      else if (invalidRange.some(x => x))
        return invalidRangeError;

      else if (intersection.some(x => x))
        return intersectionError;

      else if (inadequateRange.some(x => x))
        return inadequateRangeError;

      else {
        let size = formGroup.get('listEvent')['controls'].length;

        for (let i = 0; i < size; i++) {
          let dayForm = formGroup.get('listEvent').get(i.toString());
          dayForm.get('startTime').setErrors(null);
          dayForm.get('endTime').setErrors(null);
        }
        return null;
      }
    };
  }

  verifyDateRange() {
    let startDate: Date = this.model.get('startDate').value;
    let endDate: Date = this.model.get('expirationDate').value;
    let idUser: number = this.model.get('idUser').value

    if (!startDate || !endDate || !idUser)
      return;

    let request: ValidateDateRangeRequest = new ValidateDateRangeRequest();
    request.datetimeStart = startDate;
    request.datetimeEnd = endDate;
    request.idUser = idUser;

    if (this.isUpdate)
      request.idSchedule = this.idSchedule;

    this.scheduleService.validateSchedule(request).subscribe({

      next: (response) => {
        if (response.isError) {
          this.alertService.show('Aviso', 'Já existe uma agenda para este usuário nesse intervalo', AlertType.warning);
          this.model.get('startDate').setErrors({ invalidDateRange: true });
          this.model.get('expirationDate').setErrors({ invalidDateRange: true });
          this.isLoading = false;
          return;
        }

        this.model.get('startDate').setErrors(null);
        this.model.get('expirationDate').setErrors(null);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    })
  }

  openBlockedDaysModal(index?: number) {
    let isUpdate = (index != null);

    let formArray = this.model.get('listBlockedDays').value;

    const dialogRef = this.dialog.open(BlockedDaysModalComponent, {
      data: {
        masks: this.masks,
        listDay: this.listDay,
        listFrequency: this.listFrequency,
        isUpdate: isUpdate,
        index: index,
        form: isUpdate ? formArray[index] : null,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.form) {
          let blockedDays = (this.model.controls['listBlockedDays'] as FormArray);

          if (!result.isUpdate)
            blockedDays.push(result.form);
          else
            blockedDays.controls[index.toString()] = result.form;
        }
      }
    });
  }

  openDeleteBlockedDay(idBlockedDay) {
    const dialogRef = this.dialog.open(BlockedDaysDeleteModalComponent, {
      data: {
        idBlockedDay: idBlockedDay
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.deleteBlockedDay)
        this.removeBlockedDay(idBlockedDay);
    });
  }

  populateSpecialty(idUser: number, isFirstCall: boolean) {
    this.isLoading = true;
    this.medicalSpecialtyService.listSelectMedicalSpecialty(idUser).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if(!isFirstCall){
          this.model.get('listWeekDay')['controls'].forEach(x =>{
            x.get('listEvent').controls.forEach(c =>{
              c.get('idSpecialty').setValue(null);
            });
          })
        }
        
        this.listMedicalSpecialty = response.listMedicalSpecialty;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  openPreventedChangingTimeCalendar(listAffectedPatient: any, errorCode: number) {
    const dialogRef = this.dialog.open(PreventedChangingTimeCalendarModalComponent, {
      data: {
        listAffectedPatient: listAffectedPatient,
        idUser: this.model.get('idUser').value,
        errorCode: errorCode,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (errorCode == this.errorInScheduleChangeEnum.ErroNoTempoDeAtendimento)
        this.model.get('appointmentTime').setValue(this.currentAppointmentDuration);
    });
  }

  populateUserName(listAffectedPatient: PatientAppointment[], errorCode: number) {
    this.userPatientService.GetByIdsPatientUser(listAffectedPatient).subscribe({
      next: (response) => {
        if (response.isError) {
          this.isLoading = false;
          return;
        }

        this.openPreventedChangingTimeCalendar(response.listPatientAppointmentStruct, errorCode)
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }
}