import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MedicalPrescriptionModalComponent } from 'src/app/observation/pages/bed-management-details/presciption-list/medical-prescription-modal/medical-prescription-modal.component';
import { ReportModalComponent } from 'src/app/shared/components/report-modal/report-modal.component';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { DownloadReportService } from 'src/app/shared/services/API/medical-record/download-report.service';
import { NursingConsultationHistoryStruct } from 'src/app/shared/services/structs/medical-record/nursing-consultation-history.struct';

@Component({
  selector: 'app-nursing-history-accordion',
  templateUrl: './nursing-history-accordion.component.html',
  styleUrls: ['./nursing-history-accordion.component.css']
})
export class NursingHistoryAccordionComponent implements OnInit {

  @Input() nursingConsultation: NursingConsultationHistoryStruct;

  constructor(private alertService: AlertService,
    private downloadReportService: DownloadReportService,
    public dialog: MatDialog,) { }

  ngOnInit(): void {
    
  }

  downloadEvolutionReport() {
    this.downloadReportService.downloadReport(this.nursingConsultation.idEpisode, this.nursingConsultation.evolutionReportName).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      let reportDialog = this.dialog.open(ReportModalComponent, {
        data: {
          reportName: response.reportName,
          reportPdf64: response.reportPdf64,
        },
      });
      reportDialog.afterClosed().subscribe(result => {
      });
    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  openPrescriptionModal(prescription: any, isMedicatedSolution) {
    this.dialog.open(MedicalPrescriptionModalComponent, {
      data: {
        prescription: prescription,
        isMedicatedSolution: isMedicatedSolution,
      },
    });
  }

}
