import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ListBedCategoryResponse } from '../../responses/bed/list-bed-category-response.response';

@Injectable({
  providedIn: 'root'
})
export class BedCategoryService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listBedCategory(): Observable<ListBedCategoryResponse>{

    let uri = `BedCategory/getAll`

    return this.httpClient.get<ListBedCategoryResponse>(environment.urlApiBed + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
