<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <h1>Gestão de Leitos</h1>
        <div class="white-body">
            <div class="generic-call__info__container">
                <div class="row">
                    <div class="col-md-9">
                        <span class="generic-call__info__container__selected-room"
                            *ngIf="selectedRoom">{{selectedRoom.sectorName}} - {{selectedRoom.roomName}}</span>
                        <span class="generic-call__info__container__selected-room" *ngIf="!selectedRoom">Selecionar
                            sala</span>
                        <mat-icon (click)="selectRoomModal()"
                            class="generic-call__info__container__edit-room">edit</mat-icon>
                    </div>
                </div>
            </div>
            <div class="filters">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Pesquisar</mat-label>
                            <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText"
                                (keyup)="onKeySearch($event,'searchText')">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Data de Nascimento</mat-label>
                            <input matInput type="text" id="birthDate" name="birthDate" [(ngModel)]="birthDate"
                                (keyup)="onKeySearch($event,'birthDate')" [mask]="masks.date">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="card-list" *ngIf="listOccupiedBed != null">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listOccupiedBed">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary">{{item.bedName}}</span>
                                <span class="secundary patient-name">{{item.patientName}}</span>
                                <span class="secundary">Episódio: {{item.idEpisode}}</span>
                                <span class="secundary">{{item.birthDate | date : 'dd/MM/yyyy'}}</span>
                                <span class="secundary">{{item.patientAge}}</span>
                                <app-patient-status-tag [patientStatus]="item.statusName" [idStatus]="item.idStatus"
                                    [patientAdmStatus]="item.episodeAdministrativeReleaseStatus"
                                    [idAdmStatus]="item.idEpisodeAdministrativeReleaseStatus">
                                </app-patient-status-tag>
                            </div>
                            <div *ngIf="item.idStatus != endRealease" class="actions">
                                <a><mat-icon aria-hidden="false" aria-label="Detalhes" (click)="goMedic(item)">description</mat-icon></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <app-empty-list
                *ngIf="selectedRoom && listOccupiedBed != undefined && listOccupiedBed.length == 0"></app-empty-list>
        </div>
    
        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>
    
</div>