<div mat-dialog-content>
    <form class="form" [formGroup]="model" (ngSubmit)="submit()">
        <div class="mat-dialog-content">
            <div class="head-modal-list">
                <h2>Selecione a data</h2>
            </div>

            <div class="row">
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Mês/Ano</mat-label>
                        <mat-select formControlName="yearMonth" (selectionChange)="setDays($event.value)">
                            <mat-option *ngFor="let item of listDate" [value]="item">{{item | date:"MMM/yyyy"}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="model.get('yearMonth').invalid">Selecione o Ano e Mês</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-6 col-sm-3 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Dia Inicial</mat-label>
                        <mat-select formControlName="initialDate" [disabled]="!isMonthSelected"
                            (selectionChange)="clearFinalDayValue($event.value)">
                            <mat-option *ngFor="let item of listDays" [value]="item">{{item}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="model.get('initialDate').invalid">Selecione o Dia Inicial</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-6 col-sm-3 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Dia Final</mat-label>
                        <mat-select formControlName="finalDate" [disabled]="!isMonthSelected">
                            <ng-container *ngFor="let item of listDays">
                                <mat-option [value]="item" *ngIf="item >= model.get('initialDate').value">{{item}}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                        <mat-error *ngIf="model.get('finalDate').invalid">Selecione o Dia Final</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="footer-modal-list">
                <button mat-flat-button color="primary" class="btn-block">
                    <span *ngIf="isLoading == false">Buscar Procedimentos</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
            </div>
        </div>
    </form>
</div>