import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TotemComponent } from './pages/totem/totem.component';
import { DisplayComponent } from './pages/display/display.component';
import { BiPanelComponent } from './pages/bi-panel/bi-panel.component';
import { IframeComponent } from './pages/iframe/iframe.component';
import { HygienizationTotemComponent } from './pages/hygienization-totem/hygienization-totem.component';
import { TelemedicineReportComponent } from './pages/telemedicine-report/telemedicine-report.component';
import { ReportViewerComponent } from './pages/report-viewer/report-viewer.component';
import { NpsTotemComponent } from './pages/nps-totem/nps-totem.component';
import { PinRemoteAttendanceComponent } from './pages/remote-attendace/pages/pin-remote-attendance/pin-remote-attendance.component';
import { WelcomeRemoteAttendanceComponent } from './pages/remote-attendace/pages/welcome-remote-attendance/welcome-remote-attendance.component';
import { CpfValidityRemoteAttendanceComponent } from './pages/remote-attendace/pages/cpf-validity-remote-attendance/cpf-validity-remote-attendance.component';
import { AttendPatientVideoComponent } from './pages/remote-attendace/pages/attend-patient-video/attend-patient-video.component';
import { EndedQueryComponent } from './pages/remote-attendace/pages/ended-query/ended-query.component';
import { RemoteAttendanceReportViewerComponent } from './pages/remote-attendace/pages/remote-attendance-report-viewer/remote-attendance-report-viewer.component';
import { SupportPageComponent } from './pages/support-page/support-consulting.component';
import { AuthGuard } from '../shared/services/auth-guard.service';

const routes: Routes = [
  { path: 'client/totem/:id', component: TotemComponent },
  { path: 'client/display/:id', component: DisplayComponent },
  { path: 'client/bi-panel/:id', component: BiPanelComponent },
  { path: 'client/iframe/:type', component: IframeComponent },
  { path: 'client/hygienization-totem/:id', component: HygienizationTotemComponent },
  { path: 'client/telemedicine/:guid', component: TelemedicineReportComponent },
  { path: 'client/report-viewer/:guid', component: ReportViewerComponent },
  { path: 'client/nps-totem/:id', component: NpsTotemComponent },
  { path: 'client/remote-attendance/welcome', component: WelcomeRemoteAttendanceComponent },
  { path: 'client/remote-attendance/cpf', component: CpfValidityRemoteAttendanceComponent },
  { path: 'client/remote-attendance/attend-patient-video', component: AttendPatientVideoComponent },
  { path: 'client/remote-attendance/AttendPatientEnded', component: EndedQueryComponent },
  { path: 'client/remote-attendance/:id', component: PinRemoteAttendanceComponent },
  { path: 'client/remote-attendance/report/:guid', component: RemoteAttendanceReportViewerComponent },
  { path: 'suportetolife', component: SupportPageComponent },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})

export class ClientRoutingModule { }