import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UtilsClientService } from 'src/app/client/utils.service';

@Component({
  selector: 'app-pin-display',
  templateUrl: './pin-display.component.html',
  styleUrls: ['./pin-display.component.css']
})
export class PinDisplayComponent implements OnInit {

  @Input() isLoading: boolean;

  @Output() onAuthenticated: EventEmitter<string> = new EventEmitter<string>();

  constructor(private formBuilder: UntypedFormBuilder, 
    private utilService: UtilsClientService,
  ) { }

  public model: UntypedFormGroup;
  public savePin: boolean = false;
  
  ngOnInit(): void {
    this.model = this.formBuilder.group({
      pin: ['', [Validators.required]],
    });

    this.verifyLogin();
  }

  submit(){
    if(this.model.invalid || this.isLoading == true)
      return;

    let pin = this.model.get('pin').value;

    if(this.savePin){
      this.utilService.updatePanelPin(pin.toString());
    }

    this.onAuthenticated.emit(pin);        
  }

  verifyLogin(){
    let pin = this.utilService.getPanelPin();

    if(pin) this.onAuthenticated.emit(pin);
  }
}
