import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { PharmacyTagRequest } from '../../requests/Report/pharmacy-tag.request';
import { ReportResponse } from '../../responses/orchestrator-patient/report.response';

@Injectable({
  providedIn: 'root'
})
export class PharmacyTagService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  getTags(body: PharmacyTagRequest, modelTag: number): Observable<ReportResponse> {
    let uri = `PharmacyTag?model=${modelTag}`;

    return this.httpClient.post<ReportResponse>(environment.urlApiReport + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
