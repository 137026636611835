export class WristbandReportRequest{
     
     public patientName: string;
     public patientBirthDate: Date;
     public dateTimeInclusion: Date;
     public idEpisode: number;
     public width: number;
     public height: number;
     public identification: any;
     public idIdentification: number;
     public motherName: string;
     public gravityColor: string;
     public gravityName: string;
     public idGravity: number;
}