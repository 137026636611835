export class GraphRequest {

    public graphName: string;

    public idGraphWidth: number;

    public height: number;

    public order: number;

    public idDashboard: number;

    public idClassification: number;

    public idGraphType: number;

    public idDataSource: number;

    public listAxisX: number[];

    public listAxisY: number[];

    public listAggregation: number[];

    public idDataSourceVariable: number;
}