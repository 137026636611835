import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MedicalPrescriptionService } from 'src/app/shared/services/API/medical-record/medical-prescription.service';
import { CallEpisodePrescriptionService } from 'src/app/shared/services/API/orchestrator-patient/call-episode-prescription.service';
import { LocationService } from 'src/app/shared/services/API/pharmacy/location.service';
import { MedicinePresentationTypeService } from 'src/app/shared/services/API/pharmacy/medicine-presentation-type.service';
import { VerifyStorageService } from 'src/app/shared/services/API/pharmacy/verify-storage.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { PrescriptionNonDispensationRequest } from 'src/app/shared/services/requests/medical-record/prescription-non-dispensation.request';
import { LiberateSolutionPrescriptionRequest } from 'src/app/shared/services/requests/orchestrator-patient/liberate-solution-prescription.request';

@Component({
  selector: 'app-prescription-non-dispensation-all-modal',
  templateUrl: './prescription-non-dispensation-all-modal.component.html',
  styleUrls: ['./prescription-non-dispensation-all-modal.component.css']
})
export class PrescriptionNonDispensationAllModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<PrescriptionNonDispensationAllModalComponent>,
    public verifyStorageService: VerifyStorageService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    public locationService: LocationService,
    public callEpisodePrescriptionService: CallEpisodePrescriptionService,
    public medicinePresentationTypeService: MedicinePresentationTypeService,
    private medicalPrescriptionService: MedicalPrescriptionService
  ) { this.matDialogRef.disableClose = true; }

  public model: FormGroup;
  public isLoading: boolean;
  public idMedicalPrescription: number;

  ngOnInit(): void {
    this.isLoading = true;

    this.model = this.formBuilder.group({
      patientName: [{ value: this.data.patientName, disabled: true }],
      nonDispensationReason: ['', [Validators.required]],
    });

    this.isLoading = false;
  }

  submit() {
    if (this.isLoading || this.model.invalid)
      return;

    let liberateSolutionPrescriptionRequest: LiberateSolutionPrescriptionRequest = new LiberateSolutionPrescriptionRequest();
    this.data.solution.forEach(x => {
      if (x) {
        let prescriptionNonDispensationRequest: PrescriptionNonDispensationRequest = new PrescriptionNonDispensationRequest();
        prescriptionNonDispensationRequest.idMedicalPrescription = x.idMedicalPrescription;
        prescriptionNonDispensationRequest.nonDispensationReason = this.model?.get('nonDispensationReason')?.value || '';
        liberateSolutionPrescriptionRequest.listPrescriptionNonDispensationRequest.push(prescriptionNonDispensationRequest);
      }
    });

    this.medicalPrescriptionService.updatePrescriptionSolution(liberateSolutionPrescriptionRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.alertService.show('Sucesso', "Prescrição não dispensada!", AlertType.success);
        this.matDialogRef.close({ notDispensed: true });
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  clickCancel() {
    this.matDialogRef.close({ notDispensed: false });
  }
}
