
<h2 mat-dialog-title>Informe aqui o CPF ou CNS</h2>

<div mat-dialog-content>
    <div *ngIf="this.ticket && this.ticket != ''" class="col-12 ticket">
        <h1><b>Senha: </b>{{this.ticket}}</h1>
    </div>
    <form [formGroup]="searchModel"  (ngSubmit)="submit()">
        <mat-form-field appearance="outline">
            <mat-label>CPF ou CNS</mat-label>
            <input matInput type="text" formControlName="cpfCns" [mask]="cpfCnsMask">
            <mat-error *ngIf="searchModel.get('cpfCns').invalid">Informe o CPF ou CNS</mat-error>
        </mat-form-field>
    </form>
</div>

<div mat-dialog-actions>
    <button mat-flat-button (click)="submit()" color="primary" class="btn-block">
        <span *ngIf="isLoading == false">Buscar</span>
        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
    </button>
    <button [disabled]="isLoading" mat-flat-button (click)="clickCancel()" color="accent" class="btn-block" style="margin-left: 0px;">
        Voltar
    </button>
</div>
