import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { GetAllUserPermissionBiResponse } from '../../responses/user/get-all-user-permission-bi.response';

@Injectable({
  providedIn: 'root'
})
export class GetAllUserPermissionBiService extends CommonService{
  
  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getAllUserPermissionBi(): Observable<GetAllUserPermissionBiResponse> {

    let uri = `GetAllUserPermissionBi`;

    return this.httpClient.get<GetAllUserPermissionBiResponse>(environment.urlApiUser + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}