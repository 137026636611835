
<div [formGroup]="formGroup">
    <div class="row" formArrayName="{{formArrayName}}"
    *ngFor="let item of formGroup.get(formArrayName)['controls']; let i = index;first as isFirst">
    <div class="col-2 col-sm-3 col-md-1">
        <div [formGroupName]="i" *ngIf="!isFirst">
            <mat-form-field appearance="outline">
                <mat-select formControlName="idConjunction">
                    <mat-option *ngFor="let item of listConjunction" value="{{item.idConjunction}}">
                        {{item.conjunctionName}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="col-4 col-sm-5 col-md-3">
            <div [formGroupName]="i">
                <mat-form-field appearance="outline">
                    <mat-select formControlName="idVariable" (selectionChange)="changeVariableType($event, i)">
                        <mat-option *ngFor="let item of listVariable" value="{{item.idDataSourceVariable}}">
                            {{item.dataSourceVariableName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-2 col-sm-3 col-md-2">
                <div [formGroupName]="i">
                    <mat-form-field appearance="outline">
                        <mat-select formControlName="idLogicalExpression">
                            <mat-option *ngFor="let item of listLogicalExpression"
                            value="{{item.idLogicalExpression}}">{{item.logicalExpressionName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-4 col-sm-5 col-md-3">
                <div *ngIf="arrayVariables[i] == null || arrayVariables[i].type == textVariable" [formGroupName]="i">
                    <mat-form-field appearance="outline">
                        <mat-label>Valor</mat-label>
                        <input matInput type="text" formControlName="value">
                    </mat-form-field>
                </div>
                <div *ngIf="arrayVariables[i] != null && arrayVariables[i].type == selectVariable" [formGroupName]="i">
                    <mat-form-field appearance="outline">
                        <mat-select formControlName="value">
                            <mat-option *ngFor="let item of this.listVariable[arrayVariables[i].index].listValues"
                            value="{{item.id}}">{{item.label}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="arrayVariables[i] != null && arrayVariables[i].type == numberVariable" [formGroupName]="i">
                    <mat-form-field appearance="outline">
                        <mat-label>Valor</mat-label>
                        <input matInput type="number" formControlName="value">
                    </mat-form-field>
                </div>
                <div *ngIf="arrayVariables[i] != null && arrayVariables[i].type == datetimeVariable" [formGroupName]="i">
                    <mat-form-field appearance="outline">
                        <mat-label>Data e Hora</mat-label>
                        <input matInput [mask]="masks.dateTime" type="text" formControlName="value">
                    </mat-form-field>
                </div>
                <div *ngIf="arrayVariables[i] != null && arrayVariables[i].type == ageVariable" [formGroupName]="i">
                    <mat-form-field appearance="outline">
                        <mat-label>Idade</mat-label>
                        <input (blur)="validaAge($event.target.value)" matInput [mask]="masks.age.mask" [pattern]="masks.age.pattern" type="text" formControlName="value">
                        <mat-hint>013a11m29d</mat-hint>
                    </mat-form-field>
                </div>
                <div *ngIf="arrayVariables[i] != null && arrayVariables[i].type == boolVariable" [formGroupName]="i">
                    <mat-form-field appearance="outline">
                        <mat-select formControlName="value">
                            <mat-option value="true">Verdadeiro</mat-option>
                            <mat-option value="false">Falso</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="arrayVariables[i] != null && arrayVariables[i].type == timeVariable" [formGroupName]="i">
                    <mat-form-field appearance="outline">
                        <mat-label>Tempo</mat-label>
                        <input matInput [mask]="masks.time" type="text" formControlName="value">
                    </mat-form-field>
                </div>
            </div>
            <div class="col-3 col-sm-3 col-md-3">
                <button class="panel-origin-button " color="primary" mat-mini-fab type="button" (click)="addNext()">
                    <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
                </button>
                <button class="panel-origin-button " color="primary" mat-mini-fab type="button"
                (click)="removeExpression(i)">
                <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
            </button>
        </div>
    </div>
</div>

<button *ngIf="formGroup.get(formArrayName)['controls'].length == 0" color="accent" class=""
    mat-flat-button type="button" (click)="addNext()">
    <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
    Adicionar nova regra
</button>