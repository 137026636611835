import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { ReportModalComponent } from 'src/app/shared/components/report-modal/report-modal.component';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { IndividualAuditReportService } from 'src/app/shared/services/API/orchestrator-audit/individual-audit-report.service';
import { ClassificationRiskAuditCountStruct } from 'src/app/shared/services/structs/audit/classification-risk-audit-count.struct';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-individual-audit-report',
  templateUrl: './individual-audit-report.component.html',
  styleUrls: ['./individual-audit-report.component.css']
})
export class IndividualAuditReportComponent implements OnInit {

  constructor(private individualAuditReportService: IndividualAuditReportService,
    private router: Router,
    private alertService: AlertService,
    private utilService: UtilService,
    public dialog: MatDialog) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.audit;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.individual_audit_report;
  public listDate: Date[];
  public listDays: number[];
  public isMonthSelected: boolean;

  public isLoading: boolean;
  public isGeneratingReport: boolean;
  public yearMonth: Date;
  public initialDate: number;
  public finalDate: number;
  public idAudit: number;

  public listClassificationAudit: ClassificationRiskAuditCountStruct[];

  ngOnInit(): void {
    this.isLoading = true;
    this.populateDateSelect();
    this.search();
  }

  search() {      
    this.isLoading = true;
    this.listClassificationRiskAudit();
  }

  searchByDate() {
    if((this.initialDate && !this.finalDate) || (!this.initialDate && this.finalDate))
      return;
    
    this.search();
  }

  listClassificationRiskAudit() {
    let year: number;
    let month: number;
    let beginPeriod: Date;
    let endPeriod: Date;
    if(this.yearMonth && this.initialDate && this.finalDate) {
      year = this.yearMonth.getFullYear();
      month = this.yearMonth.getMonth();
      beginPeriod = new Date(Date.UTC(year, month, this.initialDate, 0, 0, 1));
      endPeriod = new Date(Date.UTC(year, month, this.finalDate, 23, 59, 59));
    }
    else {
      beginPeriod = null;
      endPeriod = null;
    }

    this.individualAuditReportService.listAllAudits(this.idAudit, beginPeriod, endPeriod).subscribe({next:(response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }

      this.listClassificationAudit = response.listClassificationAudit;

      this.isLoading = false;
    },
    error:(error) => {
      console.log(error)
      this.alertService.show('Erro inesperado', error, AlertType.error);
    }});
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }

  clearFinalDayValue(initialDay: number) {
    if (this.finalDate <= initialDay) {
      this.finalDate = null;
    }
  }

  populateDateSelect() {
    this.listDate = [];
    let todayDate = new Date();
    let year = todayDate.getFullYear();
    let month = todayDate.getMonth();
    for (let _i = 0; _i < 60; _i++) {
      this.listDate.push(new Date(year, month - _i));
    }
  }

  setDays(yearMonth: Date) {

    this.listDays = [];
    let month: number = new Date(yearMonth).getMonth();
    let year: number = new Date(yearMonth).getFullYear();
    let longMonth: number[] = [0, 2, 4, 6, 7, 9, 11];
    let shortMonth: number[] = [3, 5, 8, 10];

    if (longMonth.indexOf(month) > -1) {
      this.listDays = Array(31).fill(0).map((x, i) => i + 1);
    }
    else if (shortMonth.indexOf(month) > -1) {
      this.listDays = Array(30).fill(0).map((x, i) => i + 1);
    }
    else {
      this.listDays = ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0) ? Array(29).fill(0).map((x, i) => i + 1) : Array(28).fill(0).map((x, i) => i + 1);
    }
    this.isMonthSelected = true;
  }

  openModalReport(idAudit:number){
    this.isGeneratingReport = true;
    this.individualAuditReportService.getIndividualAuditReport(idAudit).subscribe({next:(response)=>{ 
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isGeneratingReport = false;
        return;
      }    
      let reportDialog = this.dialog.open(ReportModalComponent, {
        data: {
          reportName: response.reportName,
          reportPdf64: response.reportPdf64,
        },
      });
      reportDialog.afterClosed().subscribe(result => {
        this.router.navigate(['/audit/individual-audit-report']);
        this.isGeneratingReport = false;
      });
    },
    error:(error)=>{
      console.log(error)
      this.isGeneratingReport = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    }});
  }

}
