<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-bed-type-modal-list">
            <h2>Excluir regra?</h2>
        </div>

        <div class="body-bed-type-modal-list">
            Você realmente deseja excluir esta regra? Você perderá todas as configurações realizadas e essa ação é
            irreversível.
        </div>
        <img class="img-container" src="assets/images/delete-confirm.png">
        <div class="footer-bed-type-modal-list">
            <button mat-flat-button color="primary" class="btn-block" (click)="clickDelete()">
                <span>Sim, excluir</span>
            </button>
            <button mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Cancelar</button>
        </div>
    </div>
</div>