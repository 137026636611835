import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PrioritiesStatusEnum } from 'src/app/shared/enum/priorities-status.enum';
import { PriorityModel } from 'src/app/shared/services/models/totem/priority.model';

@Component({
  selector: 'app-totem-priority-list',
  templateUrl: './totem-priority-list.component.html',
  styleUrls: ['./totem-priority-list.component.css']
})
export class TotemPriorityListComponent implements OnInit {

  @Input() listPriority: Array<PriorityModel> = new Array<PriorityModel>();
  @Input() listTotemPriority: Array<PriorityModel> = new Array<PriorityModel>();
  @Input() birthDate: Date;
  @Input() isLoading: boolean;

  @Output() onPrint: EventEmitter<any> = new EventEmitter();
  @Output() onBack: EventEmitter<void> = new EventEmitter();

  prioritiesSelected: number[];
  listFilteredTotemPriority: Array<PriorityModel>;
  patientAge: number;
  public priorityAutism: PrioritiesStatusEnum = PrioritiesStatusEnum.AutistaTEA;

  constructor() { }

  ngOnInit(): void {
    this.listFilteredTotemPriority = JSON.parse(JSON.stringify(this.listTotemPriority)); //DEEP COPY
    if (this.birthDate) {
      this.patientAge = this.getAge(this.birthDate).years;
    }
    if (this.listFilteredTotemPriority.length >= 1) {
      this.listFilteredTotemPriority.forEach((x, index) => {
        if (x.idPriority == PrioritiesStatusEnum.MaiorDe60Anos && this.patientAge < 60)
          this.listFilteredTotemPriority.splice(index, 1)
      });
      this.listFilteredTotemPriority.forEach((x, index) => {
        if (x.idPriority == PrioritiesStatusEnum.MaiorDe80Anos && this.patientAge < 80)
          this.listFilteredTotemPriority.splice(index, 1)
      });
      this.listFilteredTotemPriority.forEach((x, index) => {
        if (x.idPriority == PrioritiesStatusEnum.MenorDe1Ano && this.patientAge > 1)
          this.listFilteredTotemPriority.splice(index, 1)
      });
      this.listPriority.forEach((priority: PriorityModel) => {
        priority.selected = false;
      });
    }
    else {
      this.listFilteredTotemPriority.forEach((x, index) => {
        if (x.idPriority == PrioritiesStatusEnum.MaiorDe60Anos && this.patientAge < 60)
          this.listFilteredTotemPriority.splice(index, 1)
      });
      this.listFilteredTotemPriority.forEach((x, index) => {
        if (x.idPriority == PrioritiesStatusEnum.MaiorDe80Anos && this.patientAge < 80)
          this.listFilteredTotemPriority.splice(index, 1)
      });
      this.listFilteredTotemPriority.forEach((x, index) => {
        if (x.idPriority == PrioritiesStatusEnum.MenorDe1Ano && this.patientAge > 1)
          this.listFilteredTotemPriority.splice(index, 1)
      });
      this.listFilteredTotemPriority.forEach((priority: PriorityModel) => {
        priority.selected = false;
      });
    }

    setTimeout(() => {
      this.listFilteredTotemPriority.forEach((x, index) => {
        if (x.idPriority == PrioritiesStatusEnum.MaiorDe60Anos && this.patientAge >= 60 && this.patientAge < 80)
      document.getElementById(x.idPriority.toString()).click();
      });

      this.listFilteredTotemPriority.forEach((x, index) => {
        if (x.idPriority == PrioritiesStatusEnum.MaiorDe80Anos && this.patientAge >= 80)
      document.getElementById(x.idPriority.toString()).click();
      });

      this.listFilteredTotemPriority.forEach((x, index) => {
        if (x.idPriority == PrioritiesStatusEnum.MenorDe1Ano && this.patientAge < 1)
      document.getElementById(x.idPriority.toString()).click();
      });
        }, 500);
  }

  selectOption(selectedOption: PriorityModel) {
    if (this.listFilteredTotemPriority.length >= 1) {
      this.listFilteredTotemPriority.forEach((priority: PriorityModel) => {
        if (priority.idPriority === selectedOption.idPriority) {
          priority.selected = !priority.selected;
        }
      });
    }
    else {
      this.listPriority.forEach((priority: PriorityModel) => {
        if (priority.idPriority === selectedOption.idPriority) {
          priority.selected = !priority.selected;
        }
      });
    }
  }

  back() {
    this.onBack.emit();
  }

  print() {
    if (this.isLoading) 
      return;

    this.isLoading = true;
    if (this.listFilteredTotemPriority.length >= 1) {
      this.prioritiesSelected = this.listFilteredTotemPriority.filter((priority: PriorityModel) => priority.selected).map(priority => priority.idPriority);
      this.listFilteredTotemPriority.forEach((priority: PriorityModel) => {
        priority.selected = false;
      });
    }
    else {
      this.prioritiesSelected = this.listPriority.filter((priority: PriorityModel) => priority.selected).map(priority => priority.idPriority);
      this.listPriority.forEach((priority: PriorityModel) => {
        priority.selected = false;
      });
    }

    this.onPrint.emit(this.prioritiesSelected);
  }


  toISOFormat(dateTimeString) {
    const [DD, MM, YYYY] = dateTimeString.split('T')[0].split('-');
    return `${YYYY}-${MM}-${DD}`;
  }

  getAge(birthDate: Date) {
    let dob = new Date(birthDate);
    var daysInMonth = 30.436875; // Days in a month on average.
    let aad = new Date();
    let yearAad = aad.getFullYear();
    let yearDob = dob.getFullYear();
    let years = yearAad - yearDob; // Get age in years.
    dob.setFullYear(yearAad); // Set birthday for this year.
    let aadMillis = aad.getTime();
    let dobMillis = dob.getTime();
    if (aadMillis < dobMillis) {
      --years;
      dob.setFullYear(yearAad - 1); // Set to previous year's birthday
      dobMillis = dob.getTime();
    }
    let days = (aadMillis - dobMillis) / 86400000;
    let monthsDec = days / daysInMonth; // Months with remainder.
    let months = Math.floor(monthsDec); // Remove fraction from month.
    days = Math.floor(daysInMonth * (monthsDec - months));
    return { years: years, months: months, days: days };
  }
}