import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CommonService } from "src/app/shared/services/common-service";
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';
import { catchError } from "rxjs/operators";
import { MedicalProcedureWithNameResponse } from "../../models/medical-record/medical-procedure.model";
import { MedicalRecordResponse } from "../../responses/orchestrator-patient/medical-record.response";

@Injectable({
    providedIn: 'root'
  })
  export class MedicalProceduresService extends CommonService{
  
    constructor(private router: Router, private httpClient: HttpClient) {
      super();
    }
  
  public getMedicalRecord(idEpisode: number): Observable<MedicalRecordResponse> {
    let uri = `MedicalRecord/idEpisode/${idEpisode}`

    return this.httpClient.get<MedicalRecordResponse>(environment.urlApiOrchestratorPatient + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getProceduresByCbo(): Observable<MedicalRecordResponse> {
    let uri = `ListProcedureByCbo`

    return this.httpClient.get<MedicalRecordResponse>(environment.urlApiMedic + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
  public getObservationMedicalProcedure(idEpisode: number): Observable<MedicalProcedureWithNameResponse> {
    let uri = `MedicalProcedures/idEpisode/${idEpisode}`

    return this.httpClient.get<MedicalProcedureWithNameResponse>(environment.urlApiOrchestratorPatient + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}