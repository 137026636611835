export class ProcedureSigtapModel{
    
    public idProcedureSigtap: number;
    
    public procedureName: string;
    
    public idProcedureSigtapCompetence: number;
    
    public codProcedure: string;

    public idTriggersProcedureSigtap: number;
}