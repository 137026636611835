export class MemedPrescription {
    public idMemedPrescription: number;
    public idMedicalCare: number;
    public prescriptionUuid: string;
    public idMemed: number;
    public lme: boolean;
    public createdAt: Date;
    public signed: number;
    public prescriptionDateOriginal: Date;
    public datetimeInclusion: Date;
    public memedPrescriptionReportName: string;
}