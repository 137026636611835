import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { AccessKeyService } from 'src/app/shared/services/API/integration/access-key.service';
import { UserByHealthUnitService } from 'src/app/shared/services/API/user/user-by-health-uint.service';
import { AccessKeyModel } from 'src/app/shared/services/models/integration/access-key.model';
import { ListUserStruct } from 'src/app/shared/services/structs/user/list-user.struct';
import { UtilService } from 'src/app/shared/services/util.service';
import { AccessKeyDeleteModalComponent } from './access-key-delete-modal/access-key-delete-modal.component';

@Component({
  selector: 'app-access-key-list',
  templateUrl: './access-key-list.component.html',
  styleUrls: ['./access-key-list.component.css']
})
export class AccessKeyListComponent implements OnInit {

  constructor(private accessKeyService: AccessKeyService,
    private userByHealthUnitService: UserByHealthUnitService,
    private router: Router,
    private alertService: AlertService,
    private utilService: UtilService,
    public dialog: MatDialog) { }
    
    public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.integration;
    public menuFunctionalityEnum:MenuFunctionalityEnum = MenuFunctionalityEnum.integration_key_list;
    
    public listUser: ListUserStruct[];
    public listAccessKey: AccessKeyModel[];
    public isLoading: boolean;
    public isActive: boolean;
    public searchText: string;
    public idExamType: number;

  ngOnInit(): void {
    this.isLoading = true;
    this.search();
  }
  search(){
    this.isLoading = true;  
    if(this.searchText == "")
    this.searchText = null;
    
    this.accessKeyService.listAccessKey(this.searchText, this.isActive).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.listAccessKey = response.listAccessKey;  
      this.listUserHealthUnit();

      this.isLoading = false;    
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  listUserHealthUnit(){
    this.userByHealthUnitService.listUser().subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        
        return;
      }
      
      this.listUser = response.listUser;  
      
      this.listAccessKey.forEach(x =>{
        this.listUser.forEach(c => {
          if(x.idUser == c.idUser){
            x.userName = c.userName;
          }
        })
      })
      
    },
    (error)=>{
      console.log(error)    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  private timeoutKeySearch: any = null;
  
  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }
  
  openModal(idAccessKey){
    const dialogRef = this.dialog.open(AccessKeyDeleteModalComponent, {
      data: {
        idAccessKey: idAccessKey
      },
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.deleteAccessKey){
        this.search();
      }
    });
  }
}
