import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MedicalProcedureCheckRequest } from 'src/app/shared/services/requests/medical-record/medical-procedure-check.request';
import { MedicalProcedureStruct } from 'src/app/shared/services/structs/medical-record/medical-procedure.struct';
import { MedicalProcedureDeleteModalComponent } from '../medical-procedure-delete-modal/medical-procedure-delete-modal.component';
import { MedicalProcedureModalComponent } from './medical-procedure-modal/medical-procedure-modal.component';

@Component({
  selector: 'app-procedure-list',
  templateUrl: './procedure-list.component.html',
  styleUrls: ['./procedure-list.component.css']
})
export class ProcedureListComponent implements OnInit {

  @Input() listProcedure: MedicalProcedureStruct[];
  @Input() listProceduresNursing: MedicalProcedureStruct[];
  @Input() listCheckedProcedure: MedicalProcedureCheckRequest[];
  @Input() patientName: string;
  @Input() birthDate: Date;
  @Input() idEpisode: number;
  @Input() actions: boolean;
  @Input() idNursingConsultation: number;

  @Output() procedureSigtapSelected: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  public listDisplay: number[];

  constructor(
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.listDisplay = [];
  }

  openProcedureModal(procedure: MedicalProcedureStruct) {
    const dialogRef = this.dialog.open(MedicalProcedureModalComponent, {
      data: {
        procedure: procedure,
        patientName: this.patientName,
        birthDate: this.birthDate,
        idMedicalProcedure: procedure.idMedicalProcedure,
        actions: this.actions,
        idEpisode: this.idEpisode,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let index = this.listCheckedProcedure.findIndex(l => l.idMedicalProcedure == result.medicalProcedureCheckRequest.idMedicalProcedure);
        if(index >= 0)
          this.listCheckedProcedure[index] = result.medicalProcedureCheckRequest
        else
          this.listCheckedProcedure.push(result.medicalProcedureCheckRequest);
        if(result.idProcedureSigtap)
          this.procedureSigtapSelected.emit(result.idProcedureSigtap);
      }
    });
  }
  deleteProcedure(idMedicalProcedure){
    const dialogRef = this.dialog.open(MedicalProcedureDeleteModalComponent, {
      data: {
        idMedicalProcedure: idMedicalProcedure
      },
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.deletePanel){
      }
    });
  }
}