import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertPolicyService } from 'src/app/shared/services/API/alert-policy/alert-policy.service';
import { ServiceService } from 'src/app/shared/services/API/flow/service.service';
import { UserService } from 'src/app/shared/services/API/user/user.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { AlertPolicy } from 'src/app/shared/services/models/alert-policy/alert-policy.model';
import { CreateAlertPolicyRequest } from 'src/app/shared/services/requests/alert-policy/create-alert-policy.request';
import { UpdateAlertPolicyRequest } from 'src/app/shared/services/requests/alert-policy/update-alert-policy.request';
import { ListServiceStruct } from 'src/app/shared/services/responses/flow/list-service-response';
import { AlertPolicyStruct } from 'src/app/shared/services/structs/alert-policy/alert-policy.struct';
import { ListUserStruct } from 'src/app/shared/services/structs/user/list-user.struct';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-alert-policy-register',
  templateUrl: './alert-policy-register.component.html',
  styleUrls: ['./alert-policy-register.component.css']
})
export class AlertPolicyRegisterComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private alertPolicyService: AlertPolicyService,
    private serviceService: ServiceService,
    private userService: UserService,
    private router: Router,
    private alertService: AlertService,
    private utilService: UtilService,
    public dialog: MatDialog) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.master;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.master_alert_policy;

  public idAlertPolicy: number;
  public listAlertPolicy: AlertPolicy[] = [];
  public alertPolicyStruct: AlertPolicyStruct;
  public listService: ListServiceStruct[] = [];
  public unavailableServices: Set<number> = new Set<number>();
  public listUser: ListUserStruct[] = [];
  public isLoading: boolean;
  public isUpdate: boolean = false;
  public isActive: boolean;
  public listIdService: number[] = [];
  public model: FormGroup;
  ip: string = '192.168.0.2';

  ngOnInit() {
    if (this.activatedRoute.snapshot.paramMap.get('idAlertPolicy')) {
      this.idAlertPolicy = parseInt(this.activatedRoute.snapshot.paramMap.get('idAlertPolicy'));
      this.isUpdate = true;
    }

    this.model = this.formBuilder.group({
      idService: [null, Validators.required],
      minQueueCount: [null, Validators.required],
      minQueueTime: [null, Validators.required],
      listIdUser: [[], Validators.required],
    });
    this.listServices();
    this.listUsers();
    if (this.isUpdate) {
      this.populatePolicy();
    }

    else {
      this.listPolicies();

    }

    setTimeout(() => { this.getIP(); }, 2000);
  }

  getIP() {
    this.utilService.getIP().subscribe({
      next: (response) => {
        if (response && response.ip) {
          this.ip = response.ip;
        }
      }
    });
  }

  listServices() {
    this.isLoading = true;
    this.serviceService.listService("", true).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listService = response.listService;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  listUsers() {
    this.isLoading = true;
    this.userService.listUser(true, "", "").subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.listUser = response.listUser;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  listPolicies() {
    this.isLoading = true;
    this.alertPolicyService.ListAlertPolicy().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        response.listPolicy.forEach(policy => {
          this.listAlertPolicy.push(policy);
          this.unavailableServices.add(policy.idService);
        });


        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  populatePolicy() {
    this.isLoading = true;
    this.alertPolicyService.GetAlertPolicy(this.idAlertPolicy).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.alertPolicyStruct = response.policy;
        this.model.get('idService').setValue(this.alertPolicyStruct.policy.idService);
        this.model.get('idService').disable();
        this.model.get('minQueueCount').setValue(this.alertPolicyStruct.policy.minQueueCount);
        this.model.get('minQueueTime').setValue(this.parseMinutes(this.alertPolicyStruct.policy.minTime));
        this.model.get('listIdUser').setValue(this.alertPolicyStruct.listIdUser);
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  private parseMinutes(timeString: string): number {
    let hms = timeString.split(':');
    let minutes = parseInt(hms[0]) * 60 + parseInt(hms[1]);
    return minutes
  }

  submit() {
    if (this.isLoading) {
      return;
    }
    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    if (this.isUpdate) {
      this.updateAlertPolicy();
    }
    else {
      this.createAlertPolicy();
    }
  }

  createAlertPolicy() {
    let request = new CreateAlertPolicyRequest();
    request.idService = this.model.get('idService').value;
    request.minQueueCount = this.model.get('minQueueCount').value;
    request.minTime = this.model.get('minQueueTime').value;
    request.listIdUser = this.model.get('listIdUser').value;
    request.ip = this.ip;

    this.isLoading = true;
    this.alertPolicyService.CreateAlertPolicy(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        this.alertService.show('Sucesso', 'Configuração salva com sucesso', AlertType.success);
        this.router.navigate(['/master/alert-policy/list']);
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });

  }
  updateAlertPolicy() {
    let request = new UpdateAlertPolicyRequest();
    let form = this.model.getRawValue();
    request.idAlertPolicy = this.idAlertPolicy;
    request.idService = form.idService;
    request.minQueueCount = form.minQueueCount;
    request.minTime = form.minQueueTime;
    request.listIdUser = form.listIdUser;
    request.ip = this.ip;

    this.isLoading = true;
    this.alertPolicyService.UpdateAlertPolicy(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        this.alertService.show('Sucesso', 'Alterações salvas com sucesso', AlertType.success);
        this.router.navigate(['/master/alert-policy/list']);
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  back() {
    this.router.navigate(['/master/alert-policy/list'])
  }
}
