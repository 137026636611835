import { FormGroup, FormControl, ValidatorFn, ValidationErrors, AbstractControl } from "@angular/forms";

// To validate password and confirm password
export function birthdateValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    if (!control || !control.value) {
      return null;
    }
    if(control.value.length < 8){
      return { invalidDate: 'Data inválida' };
    }

    let darr = control.value.split("");//["2", "9", "0", "1", "2", "0", "1", "6"]

    let longMonth: number[] = [1, 3, 5, 7, 8, 10, 12];
    let shortMonth: number[] = [4, 6, 9, 11];    
    let year = parseInt(darr[4] + darr[5] + darr[6] + darr[7]);
    let month = parseInt(darr[2] + darr[3]);
    let day = parseInt(darr[0] + darr[1]);

    if(month <= 0 || month>12){
      return { invalidDate: 'Data inválida' };
    }
    if(year <= 1500 || year > 5000){
      return { invalidDate: 'Data inválida' };
    }

    if (longMonth.indexOf(month) > -1) {
      if(day <= 0 || day>31){
        return { invalidDate: 'Data inválida' };
      }
    }
    else if (shortMonth.indexOf(month) > -1) {
      if(day <= 0 || day>=31){
        return { invalidDate: 'Data inválida' };
      }
    }
    else {
      if((year % 4 == 0) && (year % 100 != 0) || (year % 400 == 0)){ // ano bissexto
        if(day <= 0 || day>=30){
          return { invalidDate: 'Data inválida' };
        }
      }
      else{
        if(day <= 0 || day>=29){
          return { invalidDate: 'Data inválida' };
        }
      }
    }    

    //Validando se data de nascimento é maior uqe a data de hoje
    let today = new Date();
    today.setHours(0,0,0,0);
    let controlDate  = new Date(year, (month-1), day); //mês -1 pq em JS o Mês vai de 0 a 11;
    
    if(controlDate > today){
      return { invalidDate: 'Data inadequada!' };
    }
   return null;
  }
}