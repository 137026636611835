<div class="feature-content">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12">
            <mat-label>
                <strong>Alergias do paciente: </strong>
                <div class="list-tag">
                    <div class="tag" *ngFor="let item of listAllergyEpisode">{{item.allergyName}}</div>
                </div>

            </mat-label>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12">
            <mat-label>
                <strong>Evolução do paciente: </strong>
                <p>{{nursingConsultation.patientEvolution}}</p>
            </mat-label>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12">
            <mat-label>
                <strong>Sinais vitais :</strong>
            </mat-label>
        </div>
    </div>
    <div class="row">
        <div class="col-6 col-sm-3 col-md-2">
            <mat-form-field appearance="outline">
                <mat-label>Temperatura</mat-label>
                <input type="number" readonly matInput value="{{nursingConsultation.temperature}}">
                <span matSuffix>ºC</span>
            </mat-form-field>
        </div>
        <div class="col-6 col-sm-3 col-md-2">
            <mat-form-field appearance="outline">
                <mat-label>FR</mat-label>
                <input type="number" readonly matInput value="{{nursingConsultation.respiratoryFrequency}}">
                <span matSuffix>irpm</span>
            </mat-form-field>
        </div>
        <div class="col-6 col-sm-3 col-md-2">
            <mat-form-field appearance="outline">
                <mat-label>FC</mat-label>
                <input type="number" readonly matInput value="{{nursingConsultation.heartRate}}">
                <span matSuffix>bpm</span>
            </mat-form-field>
        </div>
        <div class="col-6 col-sm-3 col-md-2">
            <mat-form-field appearance="outline">
                <mat-label>GC</mat-label>
                <input type="number" readonly matInput value="{{nursingConsultation.glucose}}">
                <span matSuffix>mg/dL</span>
            </mat-form-field>
        </div>
        <div class="col-6 col-sm-3 col-md-2">
            <mat-form-field appearance="outline">
                <mat-label>PA</mat-label>
                <input type="text" readonly matInput
                    value="{{nursingConsultation.bloodPressureSystole}}/{{nursingConsultation.bloodPressureDiastole}}">
                <span matSuffix>mmHG</span>
            </mat-form-field>
        </div>
        <div class="col-6 col-sm-3 col-md-2">
            <mat-form-field appearance="outline">
                <mat-label>SatO2</mat-label>
                <input type="number" readonly matInput value="{{nursingConsultation.saturation}}">
                <span matSuffix>%</span>
            </mat-form-field>
        </div>
    </div>
    <mat-label>
        <strong>Checagem de prescrição: </strong>
        <app-prescription-list [listPrescription]="nursingConsultation.listMedicalPrescriptionStructs"
            [patientName]="patientName" [birthDate]="birthDate" [idEpisode]="idEpisode" [actions]="false"
            [idNursingConsultation]="nursingConsultation.idNursingConsultation">
        </app-prescription-list>
    </mat-label>
    <mat-label>
        <strong>Checagem de procedimento: </strong>
        <div class="card-list"
            *ngIf="nursingConsultation.listMedicalProcedureStructs != null && nursingConsultation.listMedicalProcedureStructs.length > 0">
            <div class="row row-list">
                <div class="col-12 col-sm-6 col-md-4" 
                    *ngFor="let item of nursingConsultation.listMedicalProcedureStructs">
                    <div class="card-item">
                        <div class="infos">
                            <span class="primary">{{item.procedureName}}</span>
                            <span class="secundary">Observação do médico: {{item.observation}}</span>
                            <span class="secundary">Solicitação: {{item.userName}}</span>
                        </div>
                        <div class="actions">
                            <a>
                                <mat-icon aria-hidden="false" aria-label="Ver mais" (click)="openProcedureModal(item)">
                                    visibility</mat-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-label>
</div>