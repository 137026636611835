export class PrintRequest {
    public patientName: string;

    public idEpisode: number;

    public healthUnitName: string;

    public ticketInitials: string;

    public ticketSequence: number;

    public ticketGenerationDate: Date;

    public totemButtonName: string;

    public printerName: string;

    public qrCodeImage: string;
}