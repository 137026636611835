import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ListAllAuditsResponse } from '../../responses/orchestrator-audit/list-all-audits.response';
import { ReportResponse } from '../../responses/orchestrator-patient/report.response';

@Injectable({
  providedIn: 'root'
})
export class IndividualAuditReportService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getIndividualAuditReport(idAudit: number): Observable<ReportResponse> {
    let uri = `IndividualAuditReport/idAudit/${idAudit}`  
      
    return this.httpClient.get<ReportResponse>(environment.urlApiOrchestratorAudit + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public listAllAudits(idAudit: number, beginPeriod: Date, endPeriod: Date): Observable<ListAllAuditsResponse> {
    let uri = `IndividualAuditReport?`

    uri += idAudit != null ? `idAudit=${idAudit}` : `idAudit=`;
    uri += beginPeriod != null ? `&beginPeriod=${beginPeriod.toISOString()}` : `&beginPeriod=`;
    uri += endPeriod != null ? `&endPeriod=${endPeriod.toISOString()}` : `&endPeriod=`;
      
    return this.httpClient.get<ListAllAuditsResponse>(environment.urlApiOrchestratorAudit + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
