export class MedicalCareStruct {
    public idMedicalCare: number;
    public idUser: number;
    public userName: string;
    public userCouncilNumber: string;
    public idEpisode: number;
    public medicalNotes: string;
    public physicalExamination: string;
    public therapeuticPlan: string;
    public diagnosedHypothesis: string;
    public temperature: number;
    public respiratoryFrequency: number;
    public heartRate: number;
    public glucose: number;
    public bloodPressureDiastole: number;
    public bloodPressureSystole: number;
    public saturation: number;
    public datetimeInclusion: Date;
    public datetimeStartAttendance: Date;
    public medicalCareReportName: string;
    public sickNoteReportName: string;
    public sadtReportInternalName: string;
    public sadtReportExternalName: string;
    public prescriptionReportName: string;
    public datetimeEndIsolation: Date;
    public sickDaysOffAmount: number;
    public externalPrescriptionReportName: string;
    public isolationReportName: string;
    public isolationAcknowledgeReportName: string;
    public medicalProcedureReportName: string;
    public serviceTime: string;
}