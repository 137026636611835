import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FavoriteNameModalComponent } from 'src/app/shared/components/favorite-name-modal/favorite-name-modal.component';
import { AlertService } from 'src/app/shared/services/alert.service';
import { FavoriteHealthUnitStruct } from 'src/app/shared/services/structs/business-intelligence/favorite-health-unit.struct';
import { HealthUnitStruct } from 'src/app/shared/services/structs/user/health-unit.struct';

@Component({
  selector: 'app-favorite-select-modal',
  templateUrl: './favorite-select-modal.component.html',
  styleUrls: ['./favorite-select-modal.component.css']
})
export class FavoriteSelectModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<FavoriteNameModalComponent>,
    public router: Router,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
  ) { }

  public model: FormGroup;
  public listFavorite: FavoriteHealthUnitStruct[] = [];
  public listFavoriteDisplay: FavoriteHealthUnitStruct[] = [];
  public listHealthUnit: HealthUnitStruct[] = [];
  public listHealthUnitDisplay: HealthUnitStruct[] = [];
  public isLoading: boolean;
  public searchText: string;
  public idCurrentFavorite: number;
  
  ngOnInit(): void {
    this.listFavorite = this.data.listFavorite;
    this.listFavoriteDisplay = this.listFavorite;
    this.idCurrentFavorite = this.data.idCurrentFavorite;
    this.listHealthUnit = this.data.listHealthUnit.sort((a: HealthUnitStruct, b: HealthUnitStruct) => a.healthUnitName.localeCompare(b.healthUnitName));
    this.model = this.formBuilder.group({
      idFavorite: [null, [Validators.required]],
    });
  }

  populateNames(index: number) {
    let favorite = this.listFavoriteDisplay[index];
    this.listHealthUnitDisplay = this.listHealthUnit.filter(x => favorite.listIdHealthUnit.includes(x.idHealthUnit));
  }

  submit(favorite: FavoriteHealthUnitStruct) {
    this.matDialogRef.close({
      'favorite': favorite,
      'delete': false
    });
  }

  clickCancel() {
    this.matDialogRef.close();
  }

  deleteFavorite(favorite: FavoriteHealthUnitStruct, event: Event) {
    event.stopPropagation();
    this.matDialogRef.close({
      'favorite': favorite,
      'delete': true
    });
  }

  onKeySearch() {
    setTimeout(() => {
      if (this.searchText) {
        this.listFavoriteDisplay = this.listFavorite.filter((favorite: FavoriteHealthUnitStruct) => {
          let roomName = favorite.favoriteName
          return roomName.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1;
        });
      } else {
        this.listFavoriteDisplay = this.listFavorite;
      }
    });
  }

  stop(event: Event) {
    event.stopImmediatePropagation();
  }
}
