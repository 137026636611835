import { Injectable } from '@angular/core';
import { FilterSelectModel } from '../components/filter-select/filter-select.model';
import { UserLoginResponse } from './responses/user/auth.response';
import { ModuleStruct } from './structs/user/module.struct';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { CommonService } from './common-service';
import { UserHasModuleStruct } from '../structs/user-has-module.struct';
import { RoleStruct } from './structs/user/role.struct';
import { MemedPrescriptionStruct } from './structs/medical-record/memed-prescription.struct';
import { MemedCompletePrescriptionStruct } from './structs/medical-record/memed-complete-prescription.struct';

@Injectable({
  providedIn: 'root'
})
export class UtilService extends CommonService {

  constructor(
    private httpClient: HttpClient
  ) {
    super();
  }

  creatInitials(userName): string {
    if (userName.split(' ').length == 2)
      return userName.split(' ')[0][0] + userName.split(' ')[1][0];
    else
      return userName[0];
  }

  setIntegrationEvade(hasSiga: boolean): void {
    sessionStorage.setItem('hasSigaIntegration', hasSiga.toString());
  }

  getIntegrationEvade(): boolean {
    let hasSiga = sessionStorage.getItem('hasSigaIntegration');
    return hasSiga == "true" ? true : false;
  }

  getModules(): ModuleStruct[] {
    let token: UserLoginResponse = JSON.parse(sessionStorage.getItem('token'));
    return token.listModule;
  }

  getRoles(): RoleStruct[] {
    let token: UserLoginResponse = JSON.parse(sessionStorage.getItem('token'));
    return token.listRole;
  }

  getToken(): UserLoginResponse {
    const data: string = sessionStorage.getItem('token');
    let token: UserLoginResponse = data ? JSON.parse(sessionStorage.getItem('token')) : null;
    return token;
  }

  updateToken(userLoginResponse: UserLoginResponse) {
    sessionStorage.setItem("token", JSON.stringify(userLoginResponse));
  }

  getKeySelectedModel(filterSelectModel: FilterSelectModel) {
    if (filterSelectModel == null)
      return null;

    let listKeys: number[] = [];

    filterSelectModel.itemSelectModel.forEach(element => {
      if (element.checked)
        listKeys.push(parseInt(element.key))
    });

    if (listKeys.length == 0)
      return null;

    return JSON.stringify(listKeys);
  }

  setSecundaryHeaderModuleConfig(data: any, moduleName: string): void {
    sessionStorage.setItem(moduleName, JSON.stringify(data));
  }

  getSecundaryHeaderModuleConfig(moduleName: string): any {
    const data: string = sessionStorage.getItem(moduleName);
    return data ? JSON.parse(data) : false
  }

  cleanSecundaryHeaderModuleConfig(moduleName: string): void {
    sessionStorage.removeItem(moduleName);
  }

  getReturnToQueueConfig(): boolean {
    const data: string = sessionStorage.getItem('token');

    let token: UserLoginResponse = data ? JSON.parse(data) : null;

    return token ? token.healthUnit.canReturnToQueue : true;
  }

  getAge(dateString) {
    var daysInMonth = 30.436875;
    let dob;
    if (dateString instanceof Date)
      dob = dateString;
    else {
      let date = this.toISOFormat(dateString);
      dob = new Date(date);
    }
    let aad = new Date();
    let yearAad = aad.getFullYear();
    let yearDob = dob.getFullYear();
    let years = yearAad - yearDob;
    dob.setFullYear(yearAad);
    let aadMillis = aad.getTime();
    let dobMillis = dob.getTime();
    if (aadMillis < dobMillis) {
      --years;
      dob.setFullYear(yearAad - 1);
      dobMillis = dob.getTime();
    }
    let days = (aadMillis - dobMillis) / 86400000;
    let monthsDec = days / daysInMonth;
    let months = Math.floor(monthsDec);
    days = Math.floor(daysInMonth * (monthsDec - months));

    return { years: years, months: months, days: days };
  }
  getAgeString(dateString) {
    var daysInMonth = 30.436875;
    let dob;
    if (dateString instanceof Date)
      dob = dateString;
    else {
      let date = this.toISOFormat(dateString);
      dob = new Date(date);
    }
    let aad = new Date();
    let yearAad = aad.getFullYear();
    let yearDob = dob.getFullYear();
    let years = yearAad - yearDob;
    dob.setFullYear(yearAad);
    let aadMillis = aad.getTime();
    let dobMillis = dob.getTime();
    if (aadMillis < dobMillis) {
      --years;
      dob.setFullYear(yearAad - 1);
      dobMillis = dob.getTime();
    }
    let days = (aadMillis - dobMillis) / 86400000;
    let monthsDec = days / daysInMonth;
    let months = Math.floor(monthsDec);
    days = Math.floor(daysInMonth * (monthsDec - months));

    return `${years}a ${months}m ${days}d`;
  }

  toISOFormat(dateTimeString: string) {
    let newDate = dateTimeString.replaceAll('/' || '-', '');
    let DD = newDate.substring(0, 2);
    let MM = newDate.substring(2, 4);
    let YYYY = newDate.substring(4, 8);

    return `${YYYY}-${MM}-${DD}`;
  }

  setKpiSectors(listIdSectors: number[]) {
    sessionStorage.setItem("kpiSectors", JSON.stringify(listIdSectors))
  }

  readKpiSectors() {
    const listIdSectors = sessionStorage.getItem("kpiSectors");
    if (listIdSectors) {
      return JSON.parse(listIdSectors);
    }
  }

  validateUserHasModuleList(listModuleToVerify: number[]): UserHasModuleStruct[] {
    const data: string = sessionStorage.getItem('token');
    let token: UserLoginResponse = data ? JSON.parse(sessionStorage.getItem('token')) : null;

    let listUserHasModule: UserHasModuleStruct[] = [];
    let userHasModule = new UserHasModuleStruct();

    if (token && token.listModule && listModuleToVerify) {
      listModuleToVerify.forEach(element => {
        userHasModule.userHasModule = token.listModule.some(moduleStruct => moduleStruct.idModule == element && moduleStruct.isModulePermitted);
        userHasModule.idModule = element;

        listUserHasModule.push(userHasModule);
        userHasModule = new UserHasModuleStruct();
      })
    }

    return listUserHasModule;
  }

  setGuidStayConnected(stayConnected: StayConnected): void {
    localStorage.setItem('stayConnected', JSON.stringify(stayConnected));
  }

  getGuidStayConnected(): StayConnected {
    let stayConnected: StayConnected = JSON.parse(localStorage.getItem('stayConnected'));
    return stayConnected;
  }

  cleanGuidStayConnected(): void {
    localStorage.removeItem("stayConnected");
  }

  setPreviousURL(previousURL: any): void {
    sessionStorage.setItem('previousURL', previousURL);
  }

  getPreviousURL(): string {
    let previousURL: string = sessionStorage.getItem('previousURL');
    return previousURL;
  }

  cleanPreviousURL(): void {
    sessionStorage.removeItem("previousURL");
  }

  public getIP(): Observable<IPReturn> {

    return this.httpClient.get<IPReturn>("https://api.ipify.org/?format=json")
      .pipe(
        catchError(this.handleError)
      )
  }

  setColumnFlowManagement(listColumnToDisplay: number[]): void {
    sessionStorage.setItem('listColumnFlowManagement', JSON.stringify(listColumnToDisplay));

  }

  getColumnFlowManagement() {
    const listColumnFlowManagement = sessionStorage.getItem("listColumnFlowManagement");
    if (listColumnFlowManagement) {
      return JSON.parse(listColumnFlowManagement);
    }
  }

  setMemedPrescriptionSession(memedPrescriptionSession: MemedPrescriptionSession): void {
    sessionStorage.setItem('memedPrescriptionSession', JSON.stringify(memedPrescriptionSession));
  }

  getMemedPrescriptionSession(): MemedPrescriptionSession {
    let memedPrescriptionSession: MemedPrescriptionSession = JSON.parse(sessionStorage.getItem('memedPrescriptionSession'));
    return memedPrescriptionSession;
  }

  cleanMemedPrescriptionSession(): void {
    sessionStorage.removeItem("memedPrescriptionSession");
  }
  // Se precisar separar do token normal
  // getSafeIDToken(): string {
  //   return sessionStorage.getItem('SafeIDtoken');
  // }

  // updateSafeIDToken(token: string) {
  //   sessionStorage.setItem("SafeIDtoken", JSON.stringify(token));
  // }

  consoleLog(log: string) {
    console.log(log);
  }
}

export class IPReturn {
  public ip: string
}

export class StayConnected {
  public guid: string;
  public name: string;
  public healthUnit: string;
}

export class MemedPrescriptionSession {
  listMemedPrescriptionStruct: MemedPrescriptionStruct[] = [];
  listMemedCompletePrescriptionStruct: MemedCompletePrescriptionStruct[] = [];
}