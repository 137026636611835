<div mat-dialog-content>
    <div class="mat-dialog-content">
        <form class="form" [formGroup]="model" (ngSubmit)="clickConfirm()">
            <div class="head-modal-delete-list">
                <h2>Os itens liberados não coincidem com os pedidos!</h2>
            </div>
            <div class="body-modal-delete-list">
                <p *ngFor="let item of data.wrongMessages">{{item}}</p>
                <p>Realmente deseja liberar?</p>
                <div class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Observação</mat-label>
                            <textarea matInput type="text" formControlName="observation"></textarea>
                            <mat-error *ngIf="model.get('observation').invalid">A observação é
                                obrigatória.
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="footer-modal-delete-list">
                <button mat-flat-button type="submit" color="primary" class="btn-block">
                    <span>Sim, Liberar</span>
                </button>
                <button mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Cancelar</button>
            </div>
        </form>
    </div>
</div>