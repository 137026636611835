<div mat-dialog-content>
    <div class="mat-dialog-header">
        <h3>Procedimento</h3>
    </div>
    <form class="form" [formGroup]="procedureModel" (ngSubmit)="onSubmit()">
        <div class="mat-dialog-content">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-form-field appearance="outline" [matTooltip]="procedureTooltip" matTooltipPosition="above">
                        <mat-label>Procedimento</mat-label>
                        <mat-select formControlName="idProcedure">
                            <input class="inputSearchSelect" type="text" (keyup)="onKey($event.target.value)"
                                placeholder="Pesquisar" autocomplete="off">
                            <mat-option *ngFor="let item of selectedProcedureList" value="{{item.idProcedure}}" (click)="selectProcedure(item)">
                                {{item.procedureName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Frequência</mat-label>
                        <mat-select formControlName="isRecurring">
                            <mat-option [value]="false">
                                Único
                            </mat-option>
                            <mat-option [value]="true">
                                Recorrente
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Observação</mat-label>
                        <textarea [readonly]="this.data.readOnly" matInput formControlName="observation"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-12">
                <mat-form-field appearance="outline" matTooltip="{{this.procedureModel.get('procedureSigtapName').value}}" matTooltipPosition="above">
                    <mat-label>Código SIGTAP</mat-label>
                    <input matInput type="text" formControlName="procedureSigtapName">
                </mat-form-field>
            </div>

            <div class="footer-panel-modal-list">
                <div class="row">
                    <div *ngIf="!this.data.readOnly" class="col-6">
                        <a mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Cancelar</a>
                    </div>
                    <div *ngIf="!this.data.readOnly" class="col-6">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span>Salvar</span>
                        </button>
                    </div>
                    <div class="col-12">
                        <a *ngIf="this.data.readOnly" mat-flat-button color="primary" class="btn-block"
                            (click)="clickCancel()">Ok</a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>