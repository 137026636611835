import { Component, Input, OnInit } from '@angular/core';
import { ListMedicalPrescriptionPendingStruct } from 'src/app/shared/services/structs/medical-record/list-medical-prescription-pending.struct';

@Component({
  selector: 'app-bed-management-list-pending-checks',
  templateUrl: './bed-management-list-pending-checks.component.html',
  styleUrls: ['./bed-management-list-pending-checks.component.css']
})
export class BedManagementListPendingChecksComponent implements OnInit {

  constructor() { }

  @Input() listPrescriptionPending: ListMedicalPrescriptionPendingStruct[];
  @Input() idSector: number;
  @Input() idService: number;
  @Input() idRoom: number;

  public isLoading: boolean;

  ngOnInit(): void {
    this.isLoading = false;
  }

  statusIdToText(status: number) : string {
    if(status == 1)
      return "Atrasada";
    else if(status == 2)
      return "Aguardando";
    else if(status == 3)
      return "Próxima";
  }
}
