export class MedicalSpecialtyRequest {
    public idMedicalSpecialty: number;
    public idHealthUnit: number;
    public idMedicalSpecialtyFederalCouncilMedicine: number;

    public medicalSpecialtyName: string;

    public allowSelectionHealthcareAgreement: boolean;
    public isDeleted: boolean;
    
    public listUser: number[] = [];
    public listNatureOfAttendance: number[] = [];
    public listIdHealthcareAgreement: number[] = [];
    public listIdHealthcareAgreementPlan: number[] = []
}