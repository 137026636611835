<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    <form class="form" [formGroup]="model" (ngSubmit)="submit()">
        <div class="container-body">
            <div class="row title">
                <h1 class="bold">Configuração Lean</h1>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-4">
                    <div class="lean-white-body">
                        <div class="section-header">
                            <h3 class="bold">Tempo porta médico</h3>
                        </div>
                        <div class="content">
                            <p class="bold">Tempo ideal para atendimento do paciente</p>
                            <mat-form-field appearance="outline">
                                <mat-label>hh:mm:ss</mat-label>
                                <input matInput type="text" minlength="6" formControlName="timeAverageMedicDoor" [mask]="masks.fullTime.mask" [pattern]="masks.fullTime.pattern" placeholder="00:00:00" aria-label="Number">
                                <mat-error *ngIf="!model.get('timeAverageMedicDoor').value">Informe o tempo</mat-error>
                                <mat-error *ngIf="model.get('timeAverageMedicDoor').errors?.intersection">Intervalo sobreposto</mat-error>
                                <mat-error *ngIf="model.get('timeAverageMedicDoor').errors?.inadequateRange">Intervalo incompatível</mat-error>
                                <mat-error *ngIf="model.get('timeAverageMedicDoor').errors?.invalidRange">Intervalo inválido</mat-error>
                                <mat-error *ngIf="model.get('timeAverageMedicDoor').errors?.invalidTime">Formato inválido</mat-error>
                            </mat-form-field>
                            <p class="bold">Tempo máximo para atendimento do paciente</p>
                            <mat-form-field appearance="outline">
                                <mat-label>hh:mm:ss</mat-label>
                                <input matInput type="text" minlength="6" formControlName="maxTimeMedicDoor" [mask]="masks.fullTime.mask" [pattern]="masks.fullTime.pattern" placeholder="00:00:00" aria-label="Number">
                                <mat-error *ngIf="!model.get('maxTimeMedicDoor').value">Informe o tempo</mat-error>
                                <mat-error *ngIf="model.get('maxTimeMedicDoor').errors?.intersection">Intervalo sobreposto</mat-error>
                                <mat-error *ngIf="model.get('maxTimeMedicDoor').errors?.inadequateRange">Intervalo incompatível</mat-error>
                                <mat-error *ngIf="model.get('maxTimeMedicDoor').errors?.invalidRange">Intervalo inválido</mat-error>
                                <mat-error *ngIf="model.get('maxTimeMedicDoor').errors?.invalidTime">Formato inválido</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4">
                    <div class="lean-white-body">
                        <div class="section-header">
                            <h3 class="bold">Tempo médico-decisão</h3>
                        </div>
                        <div class="content">
                            <p class="bold">Tempo ideal para atendimento do paciente</p>
                            <mat-form-field appearance="outline">
                                <mat-label>hh:mm:ss</mat-label>
                                <input matInput type="text" minlength="6" formControlName="timeAverageMedicDecision" [mask]="masks.fullTime.mask" [pattern]="masks.fullTime.pattern" placeholder="00:00:00" aria-label="Number">
                                <mat-error *ngIf="!model.get('timeAverageMedicDecision').value">Informe o tempo</mat-error>
                                <mat-error *ngIf="model.get('timeAverageMedicDecision').errors?.intersection">Intervalo sobreposto</mat-error>
                                <mat-error *ngIf="model.get('timeAverageMedicDecision').errors?.inadequateRange">Intervalo incompatível</mat-error>
                                <mat-error *ngIf="model.get('timeAverageMedicDecision').errors?.invalidRange">Intervalo inválido</mat-error>
                                <mat-error *ngIf="model.get('timeAverageMedicDecision').errors?.invalidTime">Formato inválido</mat-error>
                            </mat-form-field>
                            <p class="bold">Tempo máximo para atendimento do paciente</p>
                            <mat-form-field appearance="outline">
                                <mat-label>hh:mm:ss</mat-label>
                                <input matInput type="text" minlength="6" formControlName="maxTimeMedicDecision" [mask]="masks.fullTime.mask" [pattern]="masks.fullTime.pattern" placeholder="00:00:00" aria-label="Number">
                                <mat-error *ngIf="!model.get('maxTimeMedicDecision').value">Informe o tempo</mat-error>
                                <mat-error *ngIf="model.get('maxTimeMedicDecision').errors?.intersection">Intervalo sobreposto</mat-error>
                                <mat-error *ngIf="model.get('maxTimeMedicDecision').errors?.inadequateRange">Intervalo incompatível</mat-error>
                                <mat-error *ngIf="model.get('maxTimeMedicDecision').errors?.invalidRange">Intervalo inválido</mat-error>
                                <mat-error *ngIf="model.get('maxTimeMedicDecision').errors?.invalidTime">Formato inválido</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4">
                    <div class="lean-white-body">
                        <div class="section-header">
                            <h3 class="bold">Tempo decisão-saída</h3>
                        </div>
                        <div class="content">
                            <p class="bold">Tempo ideal para atendimento do paciente</p>
                            <mat-form-field appearance="outline">
                                <mat-label>hh:mm:ss</mat-label>
                                <input matInput type="text" minlength="6" formControlName="timeAverageDecisionExit" [mask]="masks.fullTime.mask" [pattern]="masks.fullTime.pattern" placeholder="00:00:00" aria-label="Number">
                                <mat-error *ngIf="!model.get('timeAverageDecisionExit').value">Informe o tempo</mat-error>
                                <mat-error *ngIf="model.get('timeAverageDecisionExit').errors?.intersection">Intervalo sobreposto</mat-error>
                                <mat-error *ngIf="model.get('timeAverageDecisionExit').errors?.inadequateRange">Intervalo incompatível</mat-error>
                                <mat-error *ngIf="model.get('timeAverageDecisionExit').errors?.invalidRange">Intervalo inválido</mat-error>
                                <mat-error *ngIf="model.get('timeAverageDecisionExit').errors?.invalidTime">Formato inválido</mat-error>
                            </mat-form-field>
                            <p class="bold">Tempo máximo para atendimento do paciente</p>
                            <mat-form-field appearance="outline">
                                <mat-label>hh:mm:ss</mat-label>
                                <input matInput type="text" minlength="6" formControlName="maxTimeDecisionExit" [mask]="masks.fullTime.mask" [pattern]="masks.fullTime.pattern" placeholder="00:00:00" aria-label="Number">
                                <mat-error *ngIf="!model.get('maxTimeDecisionExit').value">Informe o tempo</mat-error>
                                <mat-error *ngIf="model.get('maxTimeDecisionExit').errors?.intersection">Intervalo sobreposto</mat-error>
                                <mat-error *ngIf="model.get('maxTimeDecisionExit').errors?.inadequateRange">Intervalo incompatível</mat-error>
                                <mat-error *ngIf="model.get('maxTimeDecisionExit').errors?.invalidRange">Intervalo inválido</mat-error>
                                <mat-error *ngIf="model.get('maxTimeDecisionExit').errors?.invalidTime">Formato inválido</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row title">
                <h1 class="bold">Manutenção dos indicadores</h1>
                <hr/> 
            </div>        
            <div *ngIf="isFirstLoading && model.get('listKpiLeanFields') && model.get('listKpiLeanFields').value && model.get('listKpiLeanFields').value.length == 0">
                <app-loading-list *ngIf="isLoading"></app-loading-list>                
            </div>
            <div *ngIf="!isFirstLoading && model.get('listKpiLeanFields') && model.get('listKpiLeanFields').value && model.get('listKpiLeanFields').value.length == 0">
                <app-empty-list [message]="'Não há KPIs para configurar o valor'"></app-empty-list>
            </div> 

                              
            <div *ngIf="!isFirstLoading && model.get('listKpiLeanFields') && model.get('listKpiLeanFields').value && model.get('listKpiLeanFields').value.length > 0" class="row">
                <div class="kpi-flow col-lg-12 col-xl-4" formArrayName="listKpiLeanFields" *ngFor="let itemFlow of listKpiLeanFlowStruct">
                    <div class="lean-white-body">
                        <h2 class="sector-kpi-header">{{itemFlow.kpiLeanFlow.description}}</h2>
                        <div class="kpi-section" *ngFor="let itemSection of itemFlow.listKpiLeanSectionStruct">
                            <div class="row kpi-field">
                                <div class="col-4">
                                    <p class="section-kpi-header kpi-name">{{itemSection.kpiLeanSection.description}}</p>
                                </div>
                                <div class="col-4">
                                    <p class="section-kpi-header kpi-alert">ALERTA</p>
                                </div>
                                <div class="col-4">
                                    <p class="section-kpi-header kpi-critical">CRÍTICO</p>
                                </div>
                            </div>
                            <div class="row kpi-field" *ngFor="let itemField of itemSection.listKpiLeanFieldStruct" [formGroupName]="itemField.indexOnFormArray">
                                <div class="col-4">
                                    <p>{{itemField.description}}</p>
                                </div>
                                <div *ngIf="itemField.idKpiLeanFieldType == timeKpiLeanFieldTypeEnum" class="col-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>hh:mm:ss</mat-label>
                                        <input matInput formControlName="alert" type="text" pattern="(\d+)([0-5][0-9])([0-5][0-9])" mask="00:00:00 || 000:00:00">
                                        <mat-error *ngIf="model.get('listKpiLeanFields')['controls'][itemField.indexOnFormArray].get('alert').errors?.pattern">Formato inválido</mat-error>
                                        <mat-error *ngIf="model.get('listKpiLeanFields')['controls'][itemField.indexOnFormArray].get('alert').errors?.alertEqualCritical 
                                                    || model.get('listKpiLeanFields')['controls'][itemField.indexOnFormArray].get('alert').errors?.minTime">
                                                    Valor inválido
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="itemField.idKpiLeanFieldType == timeKpiLeanFieldTypeEnum" class="col-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>hh:mm:ss</mat-label>
                                        <input matInput formControlName="critical" type="text" pattern="(\d+)([0-5][0-9])([0-5][0-9])" mask="00:00:00 || 000:00:00">
                                        <mat-error *ngIf="model.get('listKpiLeanFields')['controls'][itemField.indexOnFormArray].get('critical').errors?.pattern">Formato inválido</mat-error>
                                        <mat-error *ngIf="model.get('listKpiLeanFields')['controls'][itemField.indexOnFormArray].get('critical').errors?.minTime">Valor inválido</mat-error>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="itemField.idKpiLeanFieldType == intKpiLeanFieldTypeEnum" class="col-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>00</mat-label>
                                        <input matInput formControlName="alert" type="text" inputmode="numeric" pattern="(^\d+$)"
                                            oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                                        <mat-error *ngIf="model.get('listKpiLeanFields')['controls'][itemField.indexOnFormArray].get('alert').errors?.pattern">Formato inválido</mat-error>
                                        <mat-error *ngIf="model.get('listKpiLeanFields')['controls'][itemField.indexOnFormArray].get('alert').errors?.alertEqualCritical 
                                                    || model.get('listKpiLeanFields')['controls'][itemField.indexOnFormArray].get('alert').errors?.minValue">
                                                    Valor inválido
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="itemField.idKpiLeanFieldType == intKpiLeanFieldTypeEnum" class="col-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>00</mat-label>
                                        <input matInput formControlName="critical" type="text" inputmode="numeric" pattern="(^\d+$)"
                                            oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                                        <mat-error *ngIf="model.get('listKpiLeanFields')['controls'][itemField.indexOnFormArray].get('critical').errors?.pattern">Formato inválido</mat-error>
                                        <mat-error *ngIf="model.get('listKpiLeanFields')['controls'][itemField.indexOnFormArray].get('critical').errors?.minValue">Valor inválido</mat-error>
                                    </mat-form-field>
                                </div>                             
                            </div>
                        </div>
                    </div>
                </div>                
            </div>  
            <div class="row footer">
                <div class="col-md-2 save">
                    <button mat-flat-button type="submit" color="primary" class=" btn-block save">
                        <span *ngIf="isLoading == false">Salvar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
                <div class="col-md-2 save">
                    <button mat-flat-button type="button" color="accent" class=" btn-block save" (click)="backToFlow()">
                        <span *ngIf="isLoading == false">Cancelar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
            </div>
        </div>        
    </form>
</div>