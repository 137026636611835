import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FowardDestinationEnum } from 'src/app/shared/enum/foward-destination.enum';

@Component({
  selector: 'app-ended-query',
  templateUrl: './ended-query.component.html',
  styleUrls: ['./ended-query.component.css']
})
export class EndedQueryComponent implements OnInit {

  isMobileVersion: boolean;
  forwardName: string;

  // @Input() colorCode: string;
  @Output() finish = new EventEmitter<any>();

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.isMobileVersion = false;
  }

  ngOnInit(): void {
    let conclusionType = parseInt(this.activatedRoute.snapshot.paramMap.get('conclusionType'));
    let destinationName = this.activatedRoute.snapshot.paramMap.get('destinationName');
    if (conclusionType != null) {
      if (conclusionType == FowardDestinationEnum.Alta)
        this.forwardName = FowardDestinationEnum[conclusionType];

      else if (conclusionType == FowardDestinationEnum.Manter_em_obervacao)
        this.forwardName = "FICAR EM OBSERVAÇÃO"

      else if (conclusionType == FowardDestinationEnum.Alta_Encaminhamento) 
          this.forwardName = destinationName;

      else if (conclusionType == FowardDestinationEnum.Internacao)
        this.forwardName = "INTERNAR"

      else if (conclusionType == FowardDestinationEnum.Obito)
        this.forwardName = "ÓBITO"

      else if (conclusionType == FowardDestinationEnum.Encaminhamento) 
          this.forwardName = destinationName;
    }

    this.isMobile();
  }

  endedQuery() {
    this.router.navigate(['client/remote-attendance/welcome']);
  }

  isMobile() {
    const userAgent: string = navigator.userAgent.toLowerCase();

    let isMobile: boolean = /iphone|android/i.test(navigator.userAgent);

    let isTablet: boolean = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);

    if (isMobile || isTablet || screen.orientation.type == "portrait-primary")
      this.isMobileVersion = true;
    else
      this.isMobileVersion = false;
  }
}