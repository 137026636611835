import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { FlowRequest } from '../../requests/flow/flow.request';
import { GetFlowResponse } from '../../responses/flow/get-flow.response';
import { ListFlowResponse } from '../../responses/flow/list-flow.response';

@Injectable({
  providedIn: 'root'
})
export class FlowService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  public listFlow(searchText: string, isActive: boolean, listIdTag: string): Observable<ListFlowResponse> {

    let uri = 'Flow/getAll?'

    if (searchText != null)
      uri = uri + `searchText=${searchText}&`;

    if (isActive != null)
      uri = uri + `isActive=${isActive}&`

    if (listIdTag)
      uri = uri + `jsonListIdtag=${listIdTag}&`

    return this.httpClient.get<ListFlowResponse>(environment.urlApiFlow + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getFlow(idFlow: number): Observable<GetFlowResponse> {

    let uri = `Flow/idFlow/${idFlow}`;

    return this.httpClient.get<GetFlowResponse>(environment.urlApiFlow + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public createFlow(body: FlowRequest): Observable<ReturnStruct> {

    let uri = `Flow`

    return this.httpClient.post<ReturnStruct>(environment.urlApiOrchestratorQueue + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public updateFlow(idFlow: number, body: FlowRequest): Observable<ReturnStruct> {

    let uri = `Flow/idFlow/${idFlow}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiFlow + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public deleteFlow(idFlow: number, ip: string): Observable<ReturnStruct> {

    let uri = `Flow/idFlow/${idFlow}/ip/${ip}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiFlow + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}