import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ServiceTypeEnum } from 'src/app/shared/enum/service-type.enum';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { DirectionButton } from 'src/app/shared/services/models/self-triage/direction-button.model';
import { ServiceType } from 'src/app/shared/services/models/self-triage/service-type.model';
import { TelemedicineConfigStruct } from 'src/app/shared/services/structs/telemedicine/telemedicine-config.struct';

@Component({
  selector: 'app-direction-button-list',
  templateUrl: './direction-button-list.component.html',
  styleUrls: ['./direction-button-list.component.css']
})
export class DirectionButtonListComponent implements OnInit {

  @Input() formGroup: UntypedFormGroup;
  @Input() formArrayName: string;
  @Input() listButtons: DirectionButton[];
  @Input() listServiceType: ServiceType[];
  @Input() validationRegex: RegExp;
  @Input() telemedicineConfig: TelemedicineConfigStruct;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private maskService: MaskService,
  ) { }

  
  public masks: Masks;
  public buttonTypeTelephone: number = ServiceTypeEnum.Telefone;
  public buttonTypeLink: number = ServiceTypeEnum.Link;
  public buttonTypeTelemedicine: number = ServiceTypeEnum.telemedicine;

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
  }

  createInput() {
    return this.formBuilder.group({
      idButton: [''],
      buttonName: ['', [Validators.required]],
      description: [''],
      buttonType: [, [Validators.required]],
      buttonRedirection: ['', [Validators.required]],
    })
  }

  addNext() {
    (this.formGroup.controls['listButtons'] as UntypedFormArray).push(this.createInput())
  }

  removeButton(index: number) {
    if (index != 0) {
      (this.formGroup.controls['listButtons'] as UntypedFormArray).removeAt(index)
    }
  }

  validateUri(event, index){
    if(event){
      let result = this.validationRegex.test(event.key);

      if(!result){
        let uri = this.formGroup.get('listButtons')['controls'][index].get('buttonRedirection').value.replaceAll(event.key,"");
        this.formGroup.get('listButtons')['controls'][index].get('buttonRedirection').setValue(uri);
      }  
    }
  }

  setUrl(index){
    if(this.formGroup.get('listButtons')['controls'][index].get('buttonType').value == this.buttonTypeTelemedicine){
      this.formGroup.get('listButtons')['controls'][index].get('buttonRedirection').setValue(null);
      this.formGroup.get('listButtons')['controls'][index].get('buttonRedirection').setValidators(null);
      this.formGroup.get('listButtons')['controls'][index].get('buttonRedirection').disable();
    }
    else{
      this.formGroup.get('listButtons')['controls'][index].get('buttonRedirection').setValue(null);
      this.formGroup.get('listButtons')['controls'][index].get('buttonRedirection').setValidators([Validators.required]);
      this.formGroup.get('listButtons')['controls'][index].get('buttonRedirection').enable();
    }

    this.formGroup.get('listButtons')['controls'][index].get('buttonRedirection').updateValueAndValidity();
  }
}
