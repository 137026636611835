import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Router } from '@angular/router';



@Component({
  selector: 'app-remote-hold-modal',
  templateUrl: './remote-hold-modal.component.html',
  styleUrls: ['./remote-hold-modal.component.css']
})
export class RemoteHoldModalComponent implements OnInit, OnDestroy {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<RemoteHoldModalComponent>,
    public alertService: AlertService,
    public router: Router,
  ) {
    this.matDialogRef.disableClose = true;
  }

  public seconds: number;;
  public isLoading: boolean = false;
  public interval: any;
  public isUpdated: boolean = false;
  public mockTime: string = ":00"

  ngOnInit(): void {
    this.mockTime = this.data.watingTime + this.mockTime;
    this.seconds = this.data.watingTime * 60;
    this.startTime();
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  startTime() {
    this.interval = setInterval(myMethod, 1000);

    let duration = this.seconds;
    this.mockTime = null;
    var $this = this;
    function myMethod() {
      if (duration > 0)
        duration = duration - 1;

      let minutes = String(Math.trunc(duration / 60));
      let seconds = String(duration % 60);

      seconds = seconds.length === 1 ? "0" + seconds : seconds;
      let time: string = minutes + " min " + seconds + "s";

      if (document.getElementById('segundo') != null) {
        document.getElementById('segundo').innerHTML = time;
      }

      if (duration == 0 && !$this.isLoading) {
        clearInterval(this.interval);
        $this.matDialogRef.close({ isCancel: false })
      }
    }
  }

  cancelAttendance() {
    this.matDialogRef.close({ isCancel: true })
  }
}