import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ClassificationData, UtilsClassificationService } from 'src/app/classification/utils.service';
import { UtilsClientService } from 'src/app/client/utils.service';
import { IframeClassificationPageEnum } from 'src/app/shared/enum/iframe-classification-page.enum';
import { TelephonePatientService } from 'src/app/shared/services/API/medical-record/telephone-patient.service';
import { SearchPatientFrontdeskService } from 'src/app/shared/services/API/orchestrator-patient/search-patient-frontdesk.service';
import { ClockService } from 'src/app/shared/services/API/orchestrator-queue/clock.service';
import { ProtocolSelectService } from 'src/app/shared/services/API/risk-classification/protocol-select.service';
import { PatientRequest } from 'src/app/shared/services/requests/medical-record/patient.request';
import { SearchPatientTotemRequest } from 'src/app/shared/services/requests/orchestrator-totem/search-patient-totem.request';
import { UtilsIframeService } from '../../util-iframe.service';
import { GravityEnum } from 'src/app/shared/enum/gravity.enum';

@Component({
  selector: 'app-iframe-telemedicine',
  templateUrl: './iframe-telemedicine.component.html',
  styleUrls: ['./iframe-telemedicine.component.css']
})
export class IframeTelemedicineComponent implements OnInit {

  constructor(private formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private utilsIframeService: UtilsIframeService,
    private protocolSelectService: ProtocolSelectService,
    private utilService: UtilsClassificationService,
    private searchPatientService: SearchPatientFrontdeskService,
    private telephonepatientService: TelephonePatientService,
    private clockService: ClockService
  ) { }

  public currentPage: IframeClassificationPageEnum;
  public findedProtocol: IframeClassificationPageEnum = IframeClassificationPageEnum.flowchart;
  public findedFlowchart: IframeClassificationPageEnum = IframeClassificationPageEnum.discriminator;
  public findedDiscriminator: IframeClassificationPageEnum = IframeClassificationPageEnum.counseling;
  public resumePage: IframeClassificationPageEnum = IframeClassificationPageEnum.result;
  public whitePatient: IframeClassificationPageEnum = IframeClassificationPageEnum.whitePatient;

  private patientName: string;
  private patientSocialName: string;
  private cpf: string;
  private cns: string;
  private gender: string;
  private birthDate: string;
  private metadata: string;
  private hasCounseling: boolean;
  private idPatient: number;
  private datetimeStartRegister: Date;
  public patientIsMaped: boolean = false;
  public whitePatientGravity: GravityEnum = GravityEnum.branco;

  ngOnInit() {
    this.utilService.updateClassificationData(new ClassificationData());
    this.getDateTimeStart();
    let idProtocol = parseInt(this.activatedRoute.snapshot.paramMap.get('idProtocol'));
    if (!idProtocol || Number.isNaN(idProtocol)) {
      this.utilsIframeService.openModalError("É obrigatório o passar o id do protocolo como parâmetro!");
      return;
    }
    this.patientName = this.activatedRoute.snapshot.paramMap.get('patientName');

    if (!this.patientName) {
      this.utilsIframeService.openModalError("É obrigatório passar o nome do paciente como parâmetro!");
      return;
    }
    this.patientSocialName = this.activatedRoute.snapshot.paramMap.get('patientSocialName');
    this.cpf = this.activatedRoute.snapshot.paramMap.get('cpf');
    this.cns = this.activatedRoute.snapshot.paramMap.get('cns');
    if (!this.cpf && !this.cns) {
      this.utilsIframeService.openModalError("É obrigatório passar o CPF ou o CNS do paciente como parâmetro!");
      return;
    }
    this.searchByCPFCNS();
    this.gender = this.activatedRoute.snapshot.paramMap.get('gender');
    if (this.gender && !(this.gender == "M" || this.gender == "F")) {
      this.utilsIframeService.openModalError("O gênero do paciente de ser (M, F)!");
      return;
    }
    this.birthDate = this.activatedRoute.snapshot.paramMap.get('birthDate');
    if (!this.birthDate || !this.isValidDate(this.birthDate)) {
      this.utilsIframeService.openModalError("É obrigatório passar a data de nascimento do paciente(dd-MM-yyyy) como parâmetro!");
      return;
    }
    this.metadata = this.activatedRoute.snapshot.paramMap.get('metadata');
    this.validate(idProtocol);
  }

  validate(idProtocol: number) {
    this.protocolSelectService.protocol(idProtocol).subscribe({
      next: (response) => {
        if (response.isError) {
          this.utilsIframeService.openModalError("Erro ao buscar o protocolo pelo id:" + response.errorDescription);
          return;
        }
        if (!response.protocol) {
          this.utilsIframeService.openModalError("O Protocolo escolhido não existe!");
          return;
        }
        let values = this.utilService.getClassificationData();
        values.idProtocol = idProtocol;
        values.protocolName = response.protocol.protocolName;
        values.outOfOrder = response.protocol.outOfOrder;
        values.isTelephoneClassification = response.protocol.isTelephone;
        this.utilService.updateClassificationData(values);
        this.currentPage = this.findedProtocol;

      }, error: (error) => {
        this.utilsIframeService.openModalError("Erro inesperado ao buscar o protocolo: " + error);
        return;
      }
    });
  }

  getDateTimeStart() {
    this.clockService.getDate().subscribe((response) => {
      this.datetimeStartRegister = response.date;
    });
  }

  searchByCPFCNS() {
    let request: SearchPatientTotemRequest = new SearchPatientTotemRequest();
    if (this.cpf) {
      request.cpf = this.cpf ? this.cpf.replace(/[^0-9]/g, '').replace(' ', '') : null;
    } else {
      request.cns = this.cns ? this.cns.replace(/[^0-9]/g, '').replace(' ', '') : null;
    }
    this.searchPatientService.searchPatientCpfCns(request).subscribe({
      next: (response) => {
        if (response.listPatient && response.listPatient.length > 0) {
          this.idPatient = response.listPatient[0].idPatient;
          this.mapPatientData();
        } else {
          let patient: PatientRequest = new PatientRequest();
          patient.patientName = this.patientName;
          patient.socialName = this.patientSocialName;
          patient.cpf = this.cpf ? this.cpf.replace(/[^0-9]+/g, '') : null;
          patient.cns = this.cns ? this.cns.replace(/[^0-9]+/g, '') : null;
          patient.idGender = this.gender ? (this.gender == "M" ? 2 : 3) : null;
          patient.birthDate = this.formatDate(this.birthDate);
          this.createPatient(patient);
        }
      },
      error: (error) => {
        this.utilsIframeService.openModalError("Erro inesperado: " + error);
        return;
      }
    });
  }

  mapPatientData() {
    let values = this.utilService.getClassificationData();
    values.idPatient = this.idPatient;
    values.patientData = new PatientRequest();
    values.patientData.patientName = this.patientName;
    values.patientData.socialName = this.patientSocialName;
    values.patientData.cpf = this.cpf;
    values.patientData.cns = this.cns;
    values.patientData.idGender = this.gender ? (this.gender == "M" ? 2 : 3) : null;
    values.patientData.birthDate = this.formatDate(this.birthDate);
    values.metadata = this.metadata;
    values.datetimeStartTriage = this.datetimeStartRegister;
    this.utilService.updateClassificationData(values);
    this.patientIsMaped = true;
  }

  createPatient(patient: PatientRequest) {
    this.telephonepatientService.savePatient(patient).subscribe((response) => {
      if (response.isError) {
        this.utilsIframeService.openModalError("Erro ao salvar paciente:" + response.errorDescription);
        return;
      }
      this.idPatient = response.idPatient;
      this.mapPatientData();
    });
  }

  formatDate(dateString: string): Date {
    var parts = dateString.split("-");
    var day = parseInt(parts[0], 10);
    var month = parseInt(parts[1], 10) - 1;
    var year = parseInt(parts[2], 10);
    return new Date(year, month, day);
  }

  isValidDate(dateString): boolean {
    // First check for the pattern
    if (!/^\d{1,2}-\d{1,2}-\d{4}$/.test(dateString))
      return false;

    // Parse the date parts to integers
    var parts = dateString.split("-");
    var day = parseInt(parts[0], 10);
    var month = parseInt(parts[1], 10);
    var year = parseInt(parts[2], 10);

    // Check the ranges of month and year
    if (year < 1000 || year > 3000 || month == 0 || month > 12)
      return false;

    var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Adjust for leap years
    if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
      monthLength[1] = 29;

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
  }

  selectFlowchart(idFlowchart: number) {
    if (idFlowchart && idFlowchart != 0) {
      this.currentPage = this.findedFlowchart;
    } else if (idFlowchart === 0) {
      this.currentPage = this.whitePatient;
    }
  }

  previousFirstPage() {
    this.currentPage = this.findedProtocol;
  }

  previousCounseling() {
    let value = this.utilService.getClassificationData()
    if (value.priority.idPriorityColor == this.whitePatientGravity.valueOf()) {
      this.currentPage = this.whitePatient;
    } else {
      this.currentPage = this.findedFlowchart;
    }
  }

  previousWhite() {
    this.currentPage = this.findedProtocol;
  }

  selectedDiscriminator(hasCounseling) {
    this.hasCounseling = hasCounseling;
    if (hasCounseling) {
      this.currentPage = this.findedDiscriminator;
    } else {
      this.currentPage = this.resumePage;
    }
  }

  previousResult() {
    if (this.hasCounseling) {
      this.currentPage = this.findedDiscriminator;
    } else {
      let value = this.utilService.getClassificationData()
      if (value.priority.idPriorityColor == this.whitePatientGravity.valueOf()) {
        this.currentPage = this.whitePatient;
      } else {
        this.currentPage = this.findedFlowchart;
      }
    }
  }

  selectedCounseling() {
    this.currentPage = this.resumePage;
  }

  selectArrivalReason() {
    this.hasCounseling = false;
    this.currentPage = this.resumePage;
  }
}
