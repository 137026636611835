import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { MedicLookupResponse } from '../../responses/medic/medic-lookup.response';

@Injectable({
  providedIn: 'root'
})
export class MedicLookupService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getMedicLookup(): Observable<MedicLookupResponse> {
    let uri = `Lookup?`
  
      
    return this.httpClient.get<MedicLookupResponse>(environment.urlApiMedic + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}