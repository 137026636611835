import { ReturnStruct } from "src/app/shared/structs/return.struct";
import { ConjunctionModel } from "../../models/data-intelligence/conjunction.model";
import { CutOffDateModel } from "../../models/data-intelligence/cut-off-date.model";
import { DataSourceTypeModel } from "../../models/data-intelligence/data-source-type.model";
import { DataSourceVariableModel } from "../../models/data-intelligence/data-source-variable.model";
import { LogicalExpressionModel } from "../../models/data-intelligence/logical-expression.model";
import { VariableTypeModel } from "../../models/data-intelligence/variable-type.model";

export class GetLookupDataResponse extends ReturnStruct {
    public listCutOffDate: CutOffDateModel[];
    public listVariableType: VariableTypeModel[];
    public listLogicalExpression: LogicalExpressionModel[];
    public listDataSourceType: DataSourceTypeModel[];
    public listConjunction: ConjunctionModel[];
    public listDataSourceVariable: DataSourceVariableModel[];
}