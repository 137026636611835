import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { GetListGuideBatchResponse } from '../../responses/private-billing/get-list-guide-batch.response';
import { DownloadGuideReportResponse } from '../../responses/private-billing/download-guide-report.response';
import { ListTissGuideReportResponse } from '../../responses/private-billing/list-tiss-guide-report.response';
import { PostGuideBatchRequest } from '../../requests/private-billing/post-guide-batch.request';
import { GetAllGuideToGuideBatchResponse } from '../../responses/private-billing/get-all-guide-to-guide-batch.response';
import { GetGuideBatchResponse } from '../../responses/private-billing/get-guide-batch.response';


@Injectable({
    providedIn: 'root'
})
export class GuideBatchService extends CommonService {
    constructor(private httpClient: HttpClient) {
        super();
    }

    public GetAll(pageIndex: number = 0, searchText: string = null, idHealthcareAgreement: number = null, dateStringProcessingEnd: string = null,
        beginDateStringSendGuideBatch: string = null, endDateStringSendGuideBatch: string = null): Observable<GetListGuideBatchResponse> {
        let uri = `GuideBatch/GetAll?`

        if (pageIndex)
            uri = uri + `pageIndex=${pageIndex}&`;

        if (searchText)
            uri = uri + `searchText=${searchText}&`

        if (idHealthcareAgreement)
            uri = uri + `idHealthcareAgreement=${idHealthcareAgreement}&`

        if (dateStringProcessingEnd)
            uri = uri + `datetimeProcessingEnd=${dateStringProcessingEnd}&`;

        if (beginDateStringSendGuideBatch)
            uri = uri + `beginDatetimeSendGuideBatch=${beginDateStringSendGuideBatch}&`

        if (endDateStringSendGuideBatch)
            uri = uri + `endDatetimeSendGuideBatch=${endDateStringSendGuideBatch}&`;

        return this.httpClient.get<GetListGuideBatchResponse>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
            .pipe(catchError(this.handleError));
    }

    public GetAllGuideToGuideBatch(idHealthcareAgreement: number, idGuideType: number, beginDateString: string, endDateString: string, pageIndex: number = 0): Observable<GetAllGuideToGuideBatchResponse> {
        let uri = `GuideBatch/GetAllGuideToGuideBatch/idHealthcareAgreement/${idHealthcareAgreement}/idGuideType/${idGuideType}/beginDatetime/${beginDateString}/endDatetime/${endDateString}?`;

        if (pageIndex)
            uri = uri + `pageIndex=${pageIndex}`;

        return this.httpClient.get<GetAllGuideToGuideBatchResponse>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
            .pipe(catchError(this.handleError));
    }

    public GetByIdGuideBatch(idGuideBatch: number, searchText: string = null, pageIndex: number = 0): Observable<GetGuideBatchResponse> {
        let uri = `GuideBatch/GetByIdGuideBatch/idGuideBatch/${idGuideBatch}?`;

        if (pageIndex)
            uri = uri + `pageIndex=${pageIndex}&`;

        if (searchText)
            uri = uri + `searchText=${searchText}`;

        return this.httpClient.get<GetGuideBatchResponse>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
            .pipe(catchError(this.handleError));
    }

    public PutSendStatus(idGuideBatch: number, idSendGuideBatchStatus: number): Observable<ReturnStruct> {
        let uri = `GuideBatch/idGuideBatch/${idGuideBatch}/idSendGuideBatchStatus/${idSendGuideBatchStatus}`

        return this.httpClient.put<ReturnStruct>(environment.urlApiPrivateBilling + uri, null, this.addHeaderToken())
            .pipe(catchError(this.handleError));
    }

    public CreateGuideBatch(request: PostGuideBatchRequest): Observable<ReturnStruct> {
        let uri = `GuideBatch`

        return this.httpClient.post<ReturnStruct>(environment.urlApiPrivateBilling + uri, request, this.addHeaderToken())
            .pipe(catchError(this.handleError));
    }


    public DeleteGuideBatch(idGuideBatch: number): Observable<ReturnStruct> {

        let uri = `GuideBatch/idGuideBatch/${idGuideBatch}`;

        return this.httpClient.delete<ReturnStruct>(
            environment.urlApiPrivateBilling + uri, this.addHeaderToken()
        )
            .pipe(catchError(this.handleError));
    }

    public DownloadPdfGuidesFromBatch(idGuideBatch: number): Observable<DownloadGuideReportResponse> {
        let uri = `GuideBatch/downloadZipPdfByBatch/idGuideBatch/${idGuideBatch}`;

        return this.httpClient.get<DownloadGuideReportResponse>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }

    public DownloadXmlGuidesFromBatch(idGuideBatch: number): Observable<DownloadGuideReportResponse> {
        let uri = `GuideBatch/downloadZipXmlByBatch/idGuideBatch/${idGuideBatch}`;

        return this.httpClient.get<DownloadGuideReportResponse>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }

}