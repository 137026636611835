<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <h1>Impulsione o engajamento do seu time!</h1>
        <div class="white-body">
            <a mat-flat-button color="primary" class=" add-user" routerLink="/master/gamification/register">
                <mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon> Cadastrar nova área
            </a>
            <div class="filters">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Pesquisar</mat-label>
                            <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText"
                                (keyup)="onKeySearch($event)">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="card-list" *ngIf="listArea != null && loaded">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listArea">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary"><b>{{item.areaName}}</b></span>
                                <span class="secundary">{{item.areaDescription}}</span>
                                <span class="secundary"><b>Serviço: </b>{{item.serviceName}}</span>
                                <span class="secundary" matTooltip="{{item.sectorName}}">
                                    <b>Setor: </b>{{item.sectorName}}</span>
                                <span class="secundary-status last-info">{{item.isActive? "Ativo":"Inativo"}}</span>
                            </div>
                            <div class="actions">
                                    <a><mat-icon aria-hidden="false" aria-label="Editar" [routerLink] = "['/master/gamification/register', {idArea: item.idArea}]">edit</mat-icon> </a>
                                    <a><mat-icon aria-hidden="false" aria-label="Editar" (click)="openModal(item.idArea)">delete_forever</mat-icon> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <app-empty-list *ngIf="listArea != undefined && listArea.length == 0"></app-empty-list>
        </div>
    
        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>
    
</div>