<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <h1>Histórico</h1>
        <div class="white-body">
            <form class="form" [formGroup]="model" (ngSubmit)="buttonSearch()">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Código</mat-label>
                            <input matInput type="text" formControlName="idPatient" oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>CPF</mat-label>
                            <input matInput [mask]="masks.cpf" type="text" formControlName="CPF">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome do Paciente</mat-label>
                            <input matInput type="text" formControlName="patientName">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Data de Nascimento</mat-label>
                            <input matInput [mask]="masks.date" type="text" formControlName="birthdate">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <button mat-flat-button color="primary" class=" btn-block" type="submit">
                            <span *ngIf="!isLoading">Pesquisar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                        </button>
                    </div>
                </div>
            </form>
            <div class="col-12">
                <div class="white-body table">
                    <div class="table-header">
                        <h1>Resultados</h1>
                    </div>
                    <div *ngIf="!isLoading">
                        <table class="basic-table">
                            <thead class="basic-table__head">
                                <tr>
                                    <th>Código</th>
                                    <th>Nome completo</th>
                                    <th>Data Nasc</th>
                                    <th>Sexo</th>
                                    <th>Celular</th>
                                    <th>CPF</th>
                                    <th>Cidade</th>
                                </tr>
                            </thead>
                            <tbody class="basic-table__body" *ngIf="this.listPatient.length > 0">
                                <tr class="basic-table__body__row" *ngFor="let item of this.listPatient; let index = index;">
                                    <td class="truncate"><span>{{item.idPatient}}</span></td>
                                    <td class="truncate"><span>{{item.patientName}}</span></td>
                                    <td class="truncate"><span>{{item.birthDate | date:'dd/MM/yyyy'}} {{item.patientAge}}</span></td>
                                    <td class="truncate"><span>{{item.genderName}}</span></td>
                                    <td class="truncate"><span>{{item.phone1 ? item.phone1 : item.phone2}}</span></td>
                                    <td class="truncate"><span>{{item.cpf}}</span></td>
                                    <td class="truncate"><span>{{item.city}}</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row" *ngIf="!isLoading && this.listPatient.length > 0">
                        <div class="col-12">
                            <mat-paginator [length]="this.fullSize" #paginator [pageIndex]="pageIndex"
                                [pageSize]="this.pageSize" aria-label="Select page"
                                (page)="changePage($event)" [hidePageSize]="true">
                            </mat-paginator>
                        </div>
                    </div>
                    <div *ngIf="this.listPatient.length == 0 && !isLoading" class="empty-table"><p>Nenhum dado disponível na tabela</p></div>
                </div>
                <app-loading-list *ngIf="isLoading"></app-loading-list>
            </div>
        </div>
    </div>
    
</div>