import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Cbo } from 'src/app/shared/services/models/private-billing/cbo.model';
import { ProfessionalCouncil } from 'src/app/shared/services/models/private-billing/professional-council.model';
import { ProviderIdentityType } from 'src/app/shared/services/models/private-billing/provider-identity-type.model';
import { State } from 'src/app/shared/services/models/private-billing/state.model';
import { ProfessionalAgreementStruct } from 'src/app/shared/services/structs/private-billing/professional-agreement.struct';
import { ViewDataComponent } from './view-data/view-data.component';
import { ProfessionalAgreementService } from 'src/app/shared/services/API/private-billing/professional-agreement.service';
import { ProfessionalAgreementRequest } from 'src/app/shared/services/requests/private-billing/professional-agreement.request';
import { ParticipationDegree } from 'src/app/shared/services/models/private-billing/participation-degree.model';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-professionals',
  templateUrl: './professionals.component.html',
  styleUrls: ['./professionals.component.css']
})
export class ProfessionalsComponent implements OnInit {
  constructor(
    private alertService: AlertService,
    private cdr: ChangeDetectorRef,
    private professionalAgreementService: ProfessionalAgreementService,
    private dialog: MatDialog) {
  }
  @ViewChild(ViewDataComponent) viewDataComponent: ViewDataComponent;

  @Input() listProfessionalCouncil: ProfessionalCouncil[] = [];
  @Input() listParticipationDegree: ParticipationDegree[] = [];
  @Input() listProviderIdentityType: ProviderIdentityType[] = [];
  @Input() listCbo: Cbo[] = [];
  @Input() listState: State[] = [];
  @Input() listProfessionalAgreementStruct: ProfessionalAgreementStruct[] = [];
  @Input() idHealthcareAgreement: number;
  @Output() profesisonalDataChanged = new EventEmitter<any>();
  @Output() changeIndex = new EventEmitter();

  public model: FormGroup;
  public isLoading: boolean = false;
  public firstLoading: boolean = false;
  public showAdditionalFields: boolean = false;
  public isActiveProfessionalsAgreement: boolean = null;

  public buttonSelected: 'ativos' | 'inativos' | null = null;

  public searchText: string = null;

  public newRule: ProfessionalAgreementStruct = new ProfessionalAgreementStruct();
  public listOriginalProfessionalAgreementStruct: ProfessionalAgreementStruct[] = [];
  public listNewProfessionalAgreementStruct: ProfessionalAgreementStruct[] = [];
  public isAccordionDataComplete: boolean[] = [];

  ngOnInit(): void {
    this.listOriginalProfessionalAgreementStruct = this.listProfessionalAgreementStruct;
    this.controlButtonStatus('ativos');
  }

  submit() {
    this.isLoading = true;

    let request: ProfessionalAgreementRequest = new ProfessionalAgreementRequest();
    request.idHealthcareAgreement = this.idHealthcareAgreement;
    request.listProfessionalAgreementStruct = this.listProfessionalAgreementStruct;

    this.professionalAgreementService.PostPut(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.listOriginalProfessionalAgreementStruct = response.listProfessionalAgreementStruct;
        this.onDataChange(this.listOriginalProfessionalAgreementStruct);
        this.isLoading = false;
        this.cdr.detectChanges();
        this.search();
        this.alertSave("Configuração salva com sucesso!");
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  isButtonActive(buttonType: 'ativos' | 'inativos'): boolean {
    return buttonType === this.buttonSelected;
  }

  controlButtonStatus(buttonType: 'ativos' | 'inativos') {

    if (this.buttonSelected === buttonType) {
      this.buttonSelected = null;
      this.isActiveProfessionalsAgreement = null;
      this.search();
    }
    else {
      this.buttonSelected = buttonType;

      if (this.buttonSelected == 'ativos') {
        this.isActiveProfessionalsAgreement = true;
        this.search();
      }
      else if (this.buttonSelected == 'inativos') {
        this.isActiveProfessionalsAgreement = false;
        this.search();
      }
    }
  }

  private timeoutKeySearch: any = null;
  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    },
      1000);
  }

  search() {
    if (this.idHealthcareAgreement) {
      this.isLoading = true;

      this.professionalAgreementService.getProfessional(this.idHealthcareAgreement, this.searchText, this.isActiveProfessionalsAgreement).subscribe({
        next: (response) => {
          if (response.isError) {
            this.throwException(response.errorDescription);
            return;
          }

          this.listProfessionalAgreementStruct = response.list;

          this.cdr.detectChanges();
          this.isLoading = false;
        },
        error: (error) => {
          this.throwException(error);
        }
      });
    }
  }

  addNewRule() {
    this.listNewProfessionalAgreementStruct.push(this.newRule);
  }

  onProfessionalAgreementDataChanged(updatedProfessionalAgreement: any, index: number, isEdited: boolean) {
    this.listProfessionalAgreementStruct[index] = updatedProfessionalAgreement.professionalAgreementStruct;
    this.cdr.detectChanges();
  }

  onCreateDataChanged(createProfessionalAgreement: any) {
    this.listProfessionalAgreementStruct.push(createProfessionalAgreement.professionalAgreementStruct);

    this.listNewProfessionalAgreementStruct = [];
    this.newRule = new ProfessionalAgreementStruct();
    this.showAdditionalFields = false;
    this.cdr.detectChanges();
  }

  private throwException(error: string) {
    console.log(error);
    this.alertService.show('Erro inesperado', error, AlertType.error);
    this.isLoading = false;
  }

  private alertSave(text: string) {
    this.alertService.show('Sucesso', text, AlertType.success);
    this.isLoading = false;
  }

  onDataChange(listProfessionalAgreementStruct: ProfessionalAgreementStruct[]) {
    this.profesisonalDataChanged.emit(listProfessionalAgreementStruct);
  }

  cancel() {
    let reportDialog = this.dialog.open(ConfirmModalComponent, {
      data: {
        title: "Aviso!",
        message: "Você realmente deseja cancelar? Qualquer alteração realizada pode não ter sido salva.",
        btnPrimary: "Sim, cancelar",
        btnAccent: "Não, continuar nesta aba"
      },
    });
    reportDialog.afterClosed().subscribe(result => {
      if (result && result.clickPrimary) {
        this.isLoading = true;
        this.listProfessionalAgreementStruct = this.listOriginalProfessionalAgreementStruct;
        this.profesisonalDataChanged.emit(this.listOriginalProfessionalAgreementStruct);
        this.isLoading = false;
        this.changeIndex.emit(0);
        this.cdr.detectChanges();
      }
    });
  }

  cancelNew() {
    this.listNewProfessionalAgreementStruct = [];
    this.newRule = new ProfessionalAgreementStruct();
    this.showAdditionalFields = false;
  }
}