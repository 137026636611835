<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    <div class="container-body">
        <h1>Agenda</h1>
        <div class="white-body">
            <a mat-flat-button color="primary" class=" add-user" routerLink="/digital-prompt-service/patient-center/schedule/register"><mat-icon
                    aria-hidden="false" aria-label="Plus">add</mat-icon> Cadastrar Agenda</a>
            <div class="filters">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Pesquisar</mat-label>
                            <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText"
                                (keyup)="onKeySearch($event)">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="card-list" *ngIf="listSchedule != null">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listSchedule">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary">{{item.userName}}</span>
                                <span class="secundary">{{item.attendanceDays}}</span>
                                <span class="secundary">{{item.dateStart | date:'dd/MM/yyyy'}} - {{item.dateEnd |
                                    date:'dd/MM/yyyy'}}</span>
                            </div>
                            <div class="actions">
                                <a><mat-icon aria-hidden="false" aria-label="Editar"
                                        [routerLink]="['/digital-prompt-service/patient-center/schedule/register', {idSchedule: item.idSchedule}]">edit</mat-icon>
                                </a>
                                <a><mat-icon aria-hidden="false" aria-label="Editar"
                                        (click)="openModal(item.idSchedule)">delete_forever</mat-icon> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <app-empty-list *ngIf="listSchedule != undefined && listSchedule.length == 0"></app-empty-list>
        </div>
        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>
</div>