import { Component, OnInit } from '@angular/core';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { CsatService } from 'src/app/shared/services/API/csat/csat.service';

@Component({
  selector: 'app-patient-history',
  templateUrl: './patient-history.component.html',
  styleUrls: ['./patient-history.component.css']
})
export class PatientHistoryComponent implements OnInit {

  constructor(private csatService: CsatService) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.patient_history;

  public seeButton: boolean = false;

  ngOnInit(): void {
    this.getCsat();
  }

  getCsat() {
    this.csatService.getCsat().subscribe({
      next: (response) => {
        this.seeButton = response.seeButton;
      },
    });
  }
}
