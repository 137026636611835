<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <h1>Detalhes da classificação por telefone</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <form class="form" [formGroup]="model">
                <div class="title-header">
                    <h1>Classificação</h1>
                </div>
                <app-triage-resume *ngIf="!isFirstLoading" [isTelephonceClassification]="true" [isHistorical]="true" [triage]="triage" [hasEndDateTime]="hasTriage"></app-triage-resume>
                <div class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Aconselhamento</mat-label>
                            <textarea matInput type="text" readonly formControlName="listCounseling"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="title-header">
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6">
                                <h1>Dados Pessoais</h1>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="this.isUnidentifiedPatient" class="row">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Descrição do paciente não identificado</mat-label>
                                <textarea matInput readonly type="text"
                                    formControlName="unidentifiedPatientDescription"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                    <div *ngIf="!this.isUnidentifiedPatient">
                        <div class="row">
                            <div class="col-12 col-sm-4 col-md-4">
                                <mat-form-field appearance="outline">
                                    <mat-label>Nome</mat-label>
                                    <input matInput type="text" readonly formControlName="patientName">
                                </mat-form-field>
                            </div>
    
                            <div class="col-6 col-sm-3 col-md-2">
                                <mat-form-field appearance="outline">
                                    <mat-label>Data de Nascimento</mat-label>
                                    <input matInput type="text" readonly formControlName="birthDate"
                                        [mask]="masks.date">
                                </mat-form-field>
                            </div>
                            <div class="col-6 col-sm-3 col-md-2">
                                <mat-form-field appearance="outline" class="outline-spinner">
                                    <mat-label>Idade</mat-label>
                                    <input matInput type="text" readonly formControlName="patientAge">
                                </mat-form-field>
                            </div>
                            <div class="col-md-4">
                                <button mat-flat-button color="primary" class="" type="button" (click)="openDetailsModal()">
                                    <span *ngIf="!isFirstLoading">Detalhes</span>
                                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isFirstLoading == true"></mat-spinner>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <button mat-flat-button type="button" (click)="goBack()" color="accent" class=" btn-block">
                            <mat-icon *ngIf="isLoading == false" aria-hidden="false" aria-label="Plus">arrow_back</mat-icon>
                            <span *ngIf="isLoading == false">Voltar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div *ngIf="this.newTriage" class="col-12 col-sm-6 col-md-3">
                        <button mat-flat-button type="button" (click)="submit()" color="primary" class=" btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    
</div>