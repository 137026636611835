import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ListPatientResponse } from '../../responses/orchestrator-queue/list-patient.response';

@Injectable({
  providedIn: 'root'
})

export class PatientListService extends CommonService {
  constructor(private httpClient: HttpClient) { super(); }

  public listPatient(searchText: string, idRoom: number, jsonListIdStatusQueue?: string, pageIndex?: number, idModule?: number, remoteAttendance: boolean = false, agendados: boolean = false, myPatientsCheck: boolean = false): Observable<ListPatientResponse> {
    let uri = `PatientList/idRoom/${idRoom}?`

    if (searchText != null)
      uri = uri + `searchText=${searchText}&`;

    if (jsonListIdStatusQueue != null)
      uri = uri + `jsonListIdStatusQueue=${jsonListIdStatusQueue}&`;

    if (pageIndex)
      uri = uri + `pageIndex=${pageIndex}&`;

    if (idModule)
      uri = uri + `idModule=${idModule}&`;

    if (remoteAttendance)
      uri = uri + `remoteAttendance=${remoteAttendance}&`;

    if (agendados)
      uri = uri + `agendados=${agendados}&`;

    if (myPatientsCheck)
      uri = uri + `myPatientsCheck=${myPatientsCheck}&`;

    return this.httpClient.get<ListPatientResponse>(environment.urlApiOrchestratorQueue + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public listAllPatient(pageIndex?: number, searchText?: string, jsonListIdStatusQueue?: number[], jsonListKpiStatus?: number[], listIdService?: number[], listIdSector?: number[]): Observable<ListPatientResponse> {
    let uri = `PatientList/getAll?`

    if (pageIndex)
      uri = uri + `pageIndex=${pageIndex}&`;

    if (searchText)
      uri = uri + `searchText=${searchText}&`;

    if (jsonListIdStatusQueue)
      uri = uri + `jsonListIdStatusQueue=${JSON.stringify(jsonListIdStatusQueue)}&`;

    if (jsonListKpiStatus)
      uri = uri + `jsonListKpiStatus=${JSON.stringify(jsonListKpiStatus)}&`;

    if (listIdService)
      uri = uri + `jsonListIdService=${JSON.stringify(listIdService)}&`;

    if (listIdSector)
      uri = uri + `jsonListIdSector=${JSON.stringify(listIdSector)}&`;

    return this.httpClient.get<ListPatientResponse>(environment.urlApiOrchestratorQueue + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}