import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PlanService } from 'src/app/shared/services/API/private-billing/plan.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Plan } from 'src/app/shared/services/models/private-billing/plan.model';
import { PlanRequest } from 'src/app/shared/services/requests/private-billing/plan.request';
import { PlanResponse } from 'src/app/shared/services/responses/private-billing/plan.response';

@Component({
  selector: 'app-plan-modal',
  templateUrl: './plan-modal.component.html',
  styleUrls: ['./plan-modal.component.css']
})
export class PlanModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<PlanModalComponent>,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private planService: PlanService
  ) { this.matDialogRef.disableClose = true; }

  public model: FormGroup;

  public isLoading: boolean = false;
  public nameHealthcareAgreement: string = this.data.nameHealthcareAgreement;
  public idHealthcareAgreement: number = this.data.idHealthcareAgreement;
  public plan: Plan = this.data.plan ?? null;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      planName: ['', [Validators.required]],
    });

    if (this.plan != null)
      this.model.get('planName').setValue(this.plan.planName);
  }

  submit() {
    this.isLoading = true;

    if (this.model.invalid) {
      this.alertService.show("Erro", "O nome do plano é obrigatório!", AlertType.error);
      this.isLoading = false;
      return;
    }

    let request: PlanRequest = new PlanRequest();
    request.idHealthcareAgreement = this.idHealthcareAgreement;
    request.planName = this.model.get('planName').value;

    if (this.plan == null)
      this.post(request);
    else
      this.put(request);
  }

  post(request: PlanRequest) {
    this.planService.Post(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.alertSave("Plano salvo com sucesso!", response);
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  put(request: PlanRequest) {
    this.planService.Put(request, this.plan.idPlan).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.alertSave("Plano atualizado com sucesso!", response);
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  clickCancel() {
    this.matDialogRef.close({ sucess: false });
  }

  private alertSave(text: string, response: PlanResponse) {
    this.alertService.show('Sucesso', text, AlertType.success);
    this.isLoading = false;
    this.matDialogRef.close({ success: true, plan: response.plan });
  }

  private throwException(error: string) {
    console.log(error);
    this.alertService.show('Erro inesperado', error, AlertType.error);
    this.isLoading = false;
  }
}