<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-confirm-modal-list">
            <h2>Buscar por CPF </h2>
         </div>
         <form [formGroup]="model" (ngSubmit)="submit()">
            <div class="body-confirm-modal-list">
                <p>Informe aqui o CPF ou CNS do paciente para ser encontrado no banco de dados</p>
                <mat-form-field appearance="outline">
                    <mat-label>CPF ou CNS</mat-label>
                    <input matInput type="text" formControlName="cpfCns" [mask]="cpfCnsMask" autofocus>
                    <mat-error *ngIf="this.model.get('cpfCns').invalid">Informe um CPF ou CNS</mat-error>
                </mat-form-field> 
            </div>
            <div class="footer-confirm-modal-list">
                <button mat-flat-button color="primary" class="btn-block" type="submit">
                    <span *ngIf="isLoading == false">Buscar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
                <button mat-flat-button type="button" color="accent" class="btn-block"(click)="clickCancel()">Fechar</button>
            </div>
        </form>
    </div>
</div>
