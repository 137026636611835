<form class="form" [formGroup]="model">
    <div class="row">
        <div class="col-12 col-sm-5 col-md-4 col-lg-3">
        </div>
        <div class="col-12 col-sm-2 col-md-4 col-lg-6">
        </div>
        <!-- <div class="col-12 col-sm-5 col-md-4 col-lg-3">
            <button mat-flat-button type="button" (click)="previousFirstPage()" color="accent" class=" btn-block">
                <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Voltar
            </button>
        </div> -->
    </div>
    <div class="row">
        <div class="col-md-6 col-separador">
            <div *ngFor="let item of firstColumn; let i = index;" class="row-escolha-triagem">
                <span *ngIf="item.nameAndColor" [ngStyle]="{'background-color': item.nameAndColor.coloerHex}"
                    class="nivel-urgencia">{{item.nameAndColor.priorityColorName}}</span>
                <app-discriminator (discriminatorValues)="setDiscriminatorsValues($event)" [list]="list"
                    [discriminator]="item.column" [discriminatorsValues]="discriminators"
                    [forceOpenModal]="item.forceOpenModal" [openModal]="item.openModalValidate">
                </app-discriminator>
                <input (click)="setValue(item.column)" class="radio-sim" type="radio"
                    id="radio-{{item.column.idDiscriminator}}-sim" name="radio-{{item.column.idDiscriminator}}"
                    formControlName="radio-{{item.column.idDiscriminator}}">
                <label class="label-radio-sim" for="radio-{{item.column.idDiscriminator}}-sim"><img
                        src="assets/images/icone-radio-sim.svg" /></label>
            </div>
            <div *ngIf="currentPage > 0" class="col-6 col-sm-6 col-md-4">
                <a (click)="previous()" type="button" [ngStyle]="{'background-color': getPreviousColor()}"
                    class="change-nivel-urgencia ">{{getPreviousColorName()}}
                </a>
            </div>
        </div>
        <div class="col-md-6">
            <div *ngFor="let item of secondColumn; let i = index;" class="row-escolha-triagem">
                <span *ngIf="item.nameAndColor" [ngStyle]="{'background-color': item.nameAndColor.coloerHex}"
                    class="nivel-urgencia">{{item.nameAndColor.priorityColorName}}</span>
                <app-discriminator (discriminatorValues)="setDiscriminatorsValues($event)" [list]="list"
                    [discriminator]="item.column" [discriminatorsValues]="discriminators"
                    [forceOpenModal]="item.forceOpenModal" [openModal]="item.openModalValidate">
                </app-discriminator>
                <input (click)="setValue(item.column)" class="radio-sim" type="radio"
                    id="radio-{{item.column.idDiscriminator}}-sim" name="radio-{{item.column.idDiscriminator}}"
                    formControlName="radio-{{item.column.idDiscriminator}}">
                <label class="label-radio-sim" for="radio-{{item.column.idDiscriminator}}-sim"><img
                        src="assets/images/icone-radio-sim.svg" /></label>
            </div>
            <div *ngIf="currentPage + 1 < currentIndexPages" class="col-button col-6 col-sm-6 col-md-4">
                <a (click)="next()" type="button" [ngStyle]="{'background-color': getNextColor()}"
                    class="change-nivel-urgencia ">{{getNextColorName()}}
                </a>
            </div>
        </div>
    </div>
</form>