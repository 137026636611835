import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-patient-without-scheduling-modal',
  templateUrl: './patient-without-scheduling-modal.component.html',
  styleUrls: ['./patient-without-scheduling-modal.component.css']
})
export class PatientWithoutSchedulingModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<PatientWithoutSchedulingModalComponent>
  ) { this.matDialogRef.disableClose = true; }

  isLoading: boolean;

  ngOnInit(): void {
    this.isLoading = false;
  }

  return() {
    if (this.isLoading)
      return;

    this.isLoading = true;
    this.matDialogRef.close(true);
  }
}