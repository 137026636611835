<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-type-modal-list">
            <h2>Deseja aplicar o Template?</h2>
         </div>
         
         <div class="body-type-modal-list">
            <p>Ao aplicar este template, toda a configuração de <span>Tipo medicação, Grupo da medicação, Subgrupo da medicação e Medicações</span> serão substituídos por esse template.</p> <p><span>Essa ação é IRREVERSÍVEL. Você gostaria de continuar mesmo assim?</span></p>
         </div>
         <div class="footer-type-modal-list">
            <button mat-flat-button color="primary" class="btn-block" (click)="clickApplyTemplate()">
                <span *ngIf="isLoading == false">Sim, aplicar</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
            <button mat-flat-button color="accent" class="btn-block"(click)="clickCancel()">Cancelar</button>
        </div>
    </div>
</div>
