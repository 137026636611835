import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlowManagementRoutingModule } from './flow-management-routing.module';
import { FlowManagementComponent } from './pages/flow-management/flow-management.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { NgxColorsModule } from 'ngx-colors';
import { SharedModule } from '../shared/shared.module';
import { FlowManagementListComponent } from './pages/flow-management-list/flow-management-list.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { NgxMaskModule } from 'ngx-mask';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { KpiConfigListComponent } from './pages/kpi-config-list/kpi-config-list.component';
import { KpiConfigRegisterComponent } from './pages/kpi-config-register/kpi-config-register.component';
import { KpiConfigDeleteModalComponent } from './pages/kpi-config-list/kpi-config-delete-modal/kpi-config-delete-modal.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { GaugeChartComponent } from './pages/lean-panel/gauge-chart/gauge-chart.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxSkeletonLoaderComponent, NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { LeanConfigComponent } from './pages/lean-config/lean-config.component';
import { LeanPanelComponent } from './pages/lean-panel/lean-panel.component';

@NgModule({
  declarations: [
    FlowManagementComponent,
    FlowManagementListComponent,
    KpiConfigListComponent,
    KpiConfigRegisterComponent,
    KpiConfigDeleteModalComponent,
    LeanConfigComponent,
    LeanPanelComponent,
    GaugeChartComponent,
  ],
  imports: [
    CommonModule,
    FlowManagementRoutingModule,
    SharedModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonModule,
    NgxMaskModule.forRoot(),
    MatCheckboxModule,
    MatTreeModule,
    MatRadioModule,
    ClipboardModule,
    MatTooltipModule,
    NgxChartsModule,
    MatToolbarModule,
    NgxMatFileInputModule,
    CdkAccordionModule,
    MatNativeDateModule,
    MatDatepickerModule,
    NgxColorsModule,
    MatPaginatorModule,
    MatDialogModule,
    MatExpansionModule,
    NgxSkeletonLoaderModule,
  ]
})
export class FlowManagementModule { }
