import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-alert-sms-sp-modal',
  templateUrl: './alert-sms-sp-modal.component.html',
  styleUrls: ['./alert-sms-sp-modal.component.css']
})
export class AlertSmsSpModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<AlertSmsSpModalComponent>,
  ) { }
  
  public isLoading: boolean = false;
  public certifiedManchester: boolean;

  ngOnInit(): void {
  }
  
  clickConfirm(){
    this.matDialogRef.close({certifiedManchester: this.certifiedManchester}); 
  }
}
