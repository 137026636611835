import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, retry, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { AihConfigResponse } from '../../responses/hospital-document/aih-config.response';
import { LookupResponse } from '../../responses/hospital-document/lookup.response';
import { GetAihConfigResponse } from '../../responses/hospital-document/get-aih-config-response.response';
import { AihConfigRequest } from 'src/app/shared/services/requests/hospital-document/aih-config.request';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';

@Injectable({
  providedIn: 'root'
})
export class AihConfigService extends CommonService {

  constructor(private httpClient: HttpClient) { super(); }

  public getListAihConfig(): Observable<AihConfigResponse> {
    let uri = `AihConfig`

    return this.httpClient.get<AihConfigResponse>(
      environment.urlApiHospitalDocument + uri, this.addHeaderToken()
    )
      .pipe(timeout(environment.requestTimeOut), retry(environment.requestRetry), catchError(this.handleError));
  }

  public getAihConfigById(idAihConfig: number): Observable<GetAihConfigResponse> {
    let uri = `AihConfig/idAihConfig/${idAihConfig}`;

    return this.httpClient.get<GetAihConfigResponse>(
      environment.urlApiHospitalDocument + uri, this.addHeaderToken()
    )
      .pipe(catchError(this.handleError));
  }

  public createAihConfig(request: AihConfigRequest): Observable<ReturnStruct> {

    let uri = 'AihConfig'

    return this.httpClient.post<ReturnStruct>(
      environment.urlApiHospitalDocument + uri, request, this.addHeaderToken()
    )
      .pipe(catchError(this.handleError));
  }

  public updateAihConfig(idAihConfig: number, request: AihConfigRequest): Observable<ReturnStruct> {

    let uri = `AihConfig/idAihConfig/${idAihConfig}`;

    return this.httpClient.put<ReturnStruct>(
      environment.urlApiHospitalDocument + uri, request, this.addHeaderToken()
    )
      .pipe(catchError(this.handleError));
  }

  public deleteAihConfig(idAihConfig: number): Observable<ReturnStruct> {

    let uri = `AihConfig/idAihConfig/${idAihConfig}`;

    return this.httpClient.delete<ReturnStruct>(
      environment.urlApiHospitalDocument + uri, this.addHeaderToken()
    )
      .pipe(catchError(this.handleError));
  }
}