<div class="container-out" (click)="close()" *ngIf="isVisible"></div>

<div class="container-info slide-bottom" [style.position]="isMenu ? 'fixed' : 'sticky'" *ngIf="isVisible">
    <div class="title" *ngIf="openManager">
        <h2><b>Assinatura digital</b></h2>
        <span class="subtitle">
            Selecione o provedor responsável pelo certificado para ativar a assinatura digital da sua conta
        </span>
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <mat-radio-group formControlName="selectedProvider">
                <div class="row">
                    <!-- usar slide toggles para controlar o provider selecionado -->
                    <div class="col-2 col-md-2">
                        <mat-radio-button [value]="signatureProvidersEnum.safeID"
                            (change)="selectProvider($event)"></mat-radio-button>
                    </div>
                    <div class="col-10 col-md-10">
                        <div class="provider" [id]="signatureProvidersEnum.safeID" (click)="openComponent($event)">
                            <div class="provider-info">
                                <img src="/assets/images/signature-providers/safe-id/safeid-logo.png">
                                <mat-icon>{{safeIDStatusIcon}}</mat-icon>
                            </div>
                            <mat-icon>chevron_right</mat-icon>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2 col-md-2">
                        <mat-radio-button [value]="signatureProvidersEnum.techCert"
                            (change)="selectProvider($event)"></mat-radio-button>
                    </div>
                    <div class="col-10 col-md-10">
                        <div class="provider" [id]="signatureProvidersEnum.techCert" (click)="openComponent($event)">
                            <div class="provider-info">
                                <img src="/assets/images/signature-providers/tech-cert/techcert-logo.png">
                                <mat-icon>{{techCertStatusIcon}}</mat-icon>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2 col-md-2">
                        <mat-radio-button [value]="signatureProvidersEnum.vidaaS"
                            (change)="selectProvider($event)"></mat-radio-button>
                    </div>
                    <div class="col-10 col-md-10">
                        <div class="provider" [id]="signatureProvidersEnum.vidaaS" (click)="openComponent($event)">
                            <div class="provider-info">
                                <img src="/assets/images/signature-providers/vidaaS/logo-vidaas.png">
                                <mat-icon>{{vidaaSStatusIcon}}</mat-icon>
                            </div>
                            <mat-icon>chevron_right</mat-icon>
                        </div>
                    </div>
                </div>
            </mat-radio-group>
        </form>
    </div>
    <app-safeID-authenticator [isMenu]="isMenu" (goBack)="reset($event)" (credentialsRequest)="submit($event)"
        (setStatusIcon)="setStatusIcon($event, signatureProvidersEnum.safeID)"
        *ngIf="openSafeID"></app-safeID-authenticator>
    <app-vidaas-authenticator [isMenu]="isMenu" (goBack)="reset($event)" (credentialsRequest)="submit($event)"
        (setStatusIcon)="setStatusIcon($event, signatureProvidersEnum.vidaaS)"
        *ngIf="openVidaaS"></app-vidaas-authenticator>
    <div *ngIf="!isMenu && !openSafeID && !isLoading && !openVidaaS" class="button-container">
        <a class="link-button" (click)="cancelSigning()">Seguir sem
            assinatura</a>
    </div>
</div>