import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { AttendPatientRequest } from '../../requests/orchestrator-queue/attend-patient.request';
import { AttendPatientResponse } from '../../responses/orchestrator-queue/attend-patient.response';

@Injectable({
  providedIn: 'root'
})
export class AttendOtherQueuePatientService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public attendPatient(idRoom: number, idService: number, idEpisode: number): Observable<AttendPatientResponse> {
    let uri = `AttendOtherQueuePatient/idRoom/${idRoom}/idService/${idService}/idEpisode/${idEpisode}`;

    return this.httpClient.post<AttendPatientResponse>(environment.urlApiOrchestratorQueue + uri, null, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
