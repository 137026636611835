<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <a class="back-link" routerLink="/pharmacy/location">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Estoque
        </a>
        <h1>Devolver Produto</h1>
        <div class="white-body">
            <div class="row">
                <div class="col-6 col-md-3">
                    <p>Código do produto:</p>
                    <p><b>{{returnItemStruct.itemMedicineCode}}</b></p>
                </div>
                <div class="col-6 col-md-3">
                    <p>Descrição do produto:</p>
                    <p><b>{{returnItemStruct.itemMedicineDescription}}</b></p>
                </div>
                <div class="col-6 col-md-3">
                    <p>Lote:</p>
                    <p><b>{{returnItemStruct.batch}}</b></p>
                </div>
                <div class="col-6 col-md-3">
                    <p>Validade:</p>
                    <p><b>{{returnItemStruct.dateExpiration | date : 'dd/MM/yyyy'}}</b></p>
                </div>
            </div>
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Quantidade Devolvida</mat-label>
                            <input matInput type="text" formControlName="amount">
                            <mat-error *ngIf="model.get('amount').invalid">Quantidade Devolvida</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Localização</mat-label>
                            <mat-select formControlName="idLocation">
                                <mat-option *ngFor="let item of returnItemStruct.listLocations"
                                    [value]="item.idLocation">
                                    {{item.locationName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <a mat-flat-button type="button" routerLink="/pharmacy" color="accent" class=" btn-block">
                            Voltar
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>
    
</div>