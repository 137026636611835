import { ObservationConfigSectorStruct } from "../../structs/bed/observation-config-sector.struc";

export class PostPutObservationConfigRequest {
    public observationConfigName: string;
    public observationConfigDescription: string;
    public isMandatory: boolean;
    public isReleasable: boolean;
    public allocateToBed: boolean;
    public allowEvasion: boolean;
    public isReleasablePharmacy: boolean;
    public listSector: ObservationConfigSectorStruct[];
    public listIdTextTemplate: number[];
}