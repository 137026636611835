<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="title-preview">
            <h1>Pré-visualização</h1>
        </div>
        <div class="body-calendar">
            <app-calendar-schedule *ngIf="this.viewCalendar" 
                [minDate]="this.scheduleRequest.datetimeStart"
                [maxDate]="this.scheduleRequest.datetimeEnd"
                [events]="this.events" 
                [viewDate]="this.scheduleRequest.datetimeStart" 
                [startsWithToday]="false" 
                (newEvent)="addNewEvents($event)">
            </app-calendar-schedule>
        </div>
        <div class="btn-container">
            <button mat-flat-button color="primary" class="button-close-modal" (click)="close()">Ok</button>
        </div>
    </div>
</div>
