<div mat-dialog-content class="patient-content">
    <div class="title">
        <h2>Confira as informações do paciente antes de continuar</h2>
    </div>
    <div class="patient-info">
        <p><b>Senha:</b> {{this.episodeData.ticketInitials}}{{this.episodeData.ticketSequence}}</p>
        <p><b>Nome:</b> {{this.patientData.patientName}}</p>
        <p><b>Data de nascimento:</b> {{this.patientData.birthDate | date:'dd/MM/yyyy'}}</p>
        <p><b>Nome da mãe:</b> {{this.patientData.motherName}}</p>
    </div>
    <div class="actions">
        <button mat-flat-button (click)="clickConfirm()" color="primary" class="btn-block">
            <span *ngIf="isLoading == false">Confirmar</span>
            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
        </button>
        <button [disabled]="isLoading" mat-flat-button (click)="clickCancel()" color="accent" class="btn-block" style="margin-left: 0px;">
            Voltar
        </button>
    </div>
</div>