import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ProcedureSigtapService } from 'src/app/shared/services/API/billing/procedure-sigtap.service';
import { ProcedureSigtapModel } from 'src/app/shared/services/models/billing/procedure-sigtap.model';
import { ProcedureModel } from 'src/app/shared/services/models/medic/procedure-model';
import { MedicalProcedureModel } from 'src/app/shared/services/models/medical-record/medical-procedure.model';

@Component({
  selector: 'app-procedure-modal',
  templateUrl: './procedure-modal.component.html',
  styleUrls: ['./procedure-modal.component.css']
})
export class ProcedureModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: ({medicalProcedure: MedicalProcedureModel, procedureList: ProcedureModel[], readOnly: boolean, idEpisode: number}),
    public activatedRoute: ActivatedRoute,  
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ProcedureModalComponent>,
    private alertService: AlertService,
    private procedureSigtapService: ProcedureSigtapService,
    private formBuilder: FormBuilder
  ) { }

  public isLoading: boolean;
  public medicalProcedure: MedicalProcedureModel;
  public procedureModel: FormGroup;
  public procedureList: ProcedureModel[];
  public selectedProcedureList: ProcedureModel[];
  public procedureSigtap: ProcedureSigtapModel;
  public procedureTooltip: string = '';

  ngOnInit(): void {
    this.isLoading = false;
    this.medicalProcedure = this.data.medicalProcedure;
    this.procedureList = this.data.procedureList;
    this.selectedProcedureList = this.procedureList;
    this.procedureModel = this.formBuilder.group({
      idProcedure: [{ value: null, disabled: this.data.readOnly }, Validators.required],
      isRecurring: [{ value: null, disabled: this.data.readOnly }, Validators.required],
      observation: [{ value: null, disabled: this.data.readOnly }],
      procedureSigtapName: [{ value: null, disabled: true }],
    });

    if (this.medicalProcedure) {
      this.populateValues();
    }
  }

  populateValues() {
    this.procedureModel.get('idProcedure').setValue(this.medicalProcedure.idProcedure);
    this.procedureModel.get('isRecurring').setValue(this.medicalProcedure.isRecurring);
    this.procedureModel.get('observation').setValue(this.medicalProcedure.observation);
    if(this.medicalProcedure.idProcedureSigtap)
      this.getProcedure(this.medicalProcedure.idProcedureSigtap);

    if (this.data.readOnly) {
      this.procedureModel.get('idProcedure').disable();
      this.procedureModel.get('isRecurring').disable();
      this.procedureModel.get('observation').disable();
    }
  }

  clickCancel() {
    this.matDialogRef.close();
  }
  close() {
    this.matDialogRef.close();
  }

  onSubmit() {
    if (this.procedureModel.invalid) {
      this.alertService.show('Erro', "Preencha todos os campos obrigatórios!", AlertType.error);
      return;
    }
    let newMedicalProcedure: MedicalProcedureModel = new MedicalProcedureModel();
    newMedicalProcedure.idProcedure = this.procedureModel.get('idProcedure').value;
    newMedicalProcedure.isRecurring = this.procedureModel.get('isRecurring').value;
    newMedicalProcedure.observation = this.procedureModel.get('observation').value;
    if(this.procedureSigtap && this.procedureSigtap.idProcedureSigtap)
      newMedicalProcedure.idProcedureSigtap = this.procedureSigtap.idProcedureSigtap;
    newMedicalProcedure.idEpisode = this.data.idEpisode;

    this.matDialogRef.close({ newMedicalProcedure: newMedicalProcedure });
  }

  onKey(value) {
    this.selectedProcedureList = this.search(value);
  }

  search(value: string) {
    let filter = value.toLowerCase();
    return this.procedureList.filter(option => option.procedureName.toLowerCase().includes(filter));
  }

  selectProcedure(procedureSelected: ProcedureModel) {
    if(!procedureSelected){
      this.procedureTooltip = '';
      return;
    }

    this.procedureTooltip = procedureSelected.procedureName;

    if(procedureSelected.idProcedureSigtap){
      this.getProcedure(procedureSelected.idProcedureSigtap)
    } else {
      this.procedureSigtap = new ProcedureSigtapModel();
      this.procedureModel.get('procedureSigtapName').setValue(null);
    }
  }

  getProcedure(idProcedureSigtap: number) {
    this.procedureSigtapService.getProcedureSigtap(idProcedureSigtap).subscribe({
      next: (response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.procedureSigtap = response.procedureSigtap;
        if(this.procedureSigtap)
          this.procedureModel.get('procedureSigtapName').setValue(this.procedureSigtap.procedureName);
        else
          this.procedureModel.get('procedureSigtapName').setValue(null);
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}
