<div class="row">
    <div class="col-12 col-md-6 col-xxl-3">
        <button mat-flat-button color="primary" class="btn-block" (click)="setFormVisibility()">
            <span *ngIf="isLoading == false"> {{ isFormVisible ? 'Cancelar cadastro' : '+Nova Carteirinha' }}</span>
            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
        </button>
    </div>
</div>

<form *ngIf="isFormVisible" class="form" [formGroup]="model">
    <div class="row">
        <div class="col-12 col-md-6 col-xxl-3 add-healthcare-agreement">
            <app-select-2 [formControlField]="'idHealthcareAgreement'" (selectedValue)="getPlans(null, $event)"
                [multiple]="false" [url]="urlListHealthcareAgreement" [idField]="'idHealthcareAgreement'"
                [nameField]="'nameHealthcareAgreement'" [labelField]="'Convênio'">
            </app-select-2>
        </div>
        <div class="col-12 col-md-6 col-xxl-3 add-healthcare-agreement">
            <mat-form-field appearance="outline">
                <mat-label>Plano</mat-label>
                <mat-select formControlName="idPlan">
                    <input class="inputSearchSelect" type="text" (keyup)="onKeySearch($event.target.value)"
                        (keydown)="$event.stopImmediatePropagation()" placeholder="Pesquisar"
                        autocomplete="off">
                    <mat-option *ngFor="let item of listPlan" [value]="item.idPlan">
                        {{item.planName}}</mat-option>
                </mat-select>
                <mat-error *ngIf="model.get('idPlan').invalid">Campo de preenchimento
                    obrigatório</mat-error>
            </mat-form-field>
        </div>
        <div class="col-12 col-md-6 col-xxl-3">
            <mat-form-field appearance="outline">
                <mat-label class="input-label">Número da carteirinha</mat-label>
                <input matInput inputmode="numeric" type="text" formControlName="healthcareAgreementCardNumber"
                    oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');"
                    min="0">
                <mat-error *ngIf="model.get('healthcareAgreementCardNumber').invalid">carteirinha inválida</mat-error>
            </mat-form-field>
        </div>
        <div class="col-12 col-md-6 col-xxl-3">
            <mat-form-field appearance="outline">
                <mat-label>Data de expiração da carteirinha</mat-label>
                <input matInput type="text" formControlName="healthcareAgreementExpirationDate" [mask]="masks.date">
                <mat-error *ngIf="model.get('healthcareAgreementExpirationDate').invalid">Data de expiração
                    inválida</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row div-button-create-healthcare-agreement">
        <div class="col-12 col-md-6 col-xxl-3">
            <button mat-flat-button color="primary" class="button-submit" [disabled]="model.invalid" type="button"
                (click)="addHealthcareAgreement()">
                <span *ngIf="isLoading == false">Adicionar convênio</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
        </div>
    </div>
</form>

<div class="row card-list">
    <div *ngFor="let item of listPatientHealthcareAgreementDetails; let index = index"
        class="col-12 col-md-6 col-xxl-3 input-radio-list card-item">
        <input type="radio" id="radio-{{index}}" name="forward" [checked]="item.isSelectedInEpisode"
            (click)="onClickCardItem(item)">
        <label for="radio-{{index}}">
            <div class="infos">
                <span class="primary"><b></b>{{item.nameHealthcareAgreement}}</span>
                <span class="secundary"><b>Plano:</b> {{item.planName}}</span>
                <span class="secundary"><b>Número carteirinha:</b>
                    {{item.patientHealthcareAgreement.healthcareAgreementCardNumber}}</span>
                <span class="secundary"><b>Validade carteirinha:</b>
                    {{item.patientHealthcareAgreement.healthcareAgreementExpirationDate | date:'dd/MM/yyyy'}}</span>
            </div>
            <div class="actions">
                <button class="delete-button" mat-mini-fab color="primary" (click)="confirmRemoveItemFromList(item, index)">
                    <mat-icon class="delete-icon" aria-hidden="false" aria-label="delete">delete</mat-icon>
                </button>
            </div>
        </label>
    </div>
</div>