<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <h1>Tipo de Medicamento</h1>
        <div class="white-body">
            <div class="row">
                <div class="col-12 col-md-6">
                    <a mat-flat-button color="primary" class=" add-user" routerLink="/pharmacy/medicine-type/register"><mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon> Cadastrar Tipo de Medicamento</a>
                </div>
                <div class="col-12 col-md-6">
                    <a mat-flat-button color="primary" class="" (click)="openModalTemplate()">Usar Template</a>
                </div>
            </div>
            <div class="filters">
                <div class="row">   
                    <div class="col-12 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Pesquisar</mat-label>
                            <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText" (keyup)="onKeySearch($event)">
                        </mat-form-field>
                    </div>
    
                </div>
            </div>
            <div class="card-list" *ngIf="listMedicineType != null">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listMedicineType">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary">{{item.medicineTypeName}}</span>
                                <span class="secundary">{{item.medicineTypeDescription}}</span>
                            </div>
                            <div class="actions">
                                <a><mat-icon aria-hidden="false" aria-label="Editar" [routerLink] = "['/pharmacy/medicine-type/register', {idMedicineType: item.idMedicineType}]">edit</mat-icon> </a>
                                <a><mat-icon aria-hidden="false" aria-label="Editar" (click)="openModal(item.idMedicineType)">delete_forever</mat-icon> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <app-empty-list *ngIf="listMedicineType != undefined && listMedicineType.length == 0"></app-empty-list>
        </div>
    
        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>
    
</div>