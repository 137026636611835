<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <h1>Configurações de Atendimento Multiprofissional</h1>
        <div class="white-body">
            <a mat-flat-button color="primary" class=" add-user" routerLink="/multi-professional/multi-professional-config/register"><mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon>Cadastrar configuração</a>
            <div class="filters">
                <div class="row">   
                    <div class="col-12 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Pesquisar</mat-label>
                            <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText" (keyup)="onKeySearch($event)">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-md-2"> 
                        <mat-form-field appearance="outline">
                          <mat-label>Setor</mat-label>
                          <mat-select [(ngModel)]="listIdSector" (selectionChange)="search()" multiple>
                            <mat-option *ngFor="let sector of listSector" value="{{sector.idSector}}">{{this.getSectorName(sector.idSector)}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="card-list" *ngIf="listMultiConfig != null">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listMultiConfig">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary">{{item.multiProfessionalConfigName}}</span>
                                <span class="secundary">{{this.listSectorName(item.listIdSector)}}</span>
                            </div>
                            <div class="actions">
                                <a><mat-icon aria-hidden="false" aria-label="Editar" [routerLink] = "['/multi-professional/multi-professional-config/register', {idMultiProfessionalConfig: item.idMultiProfessionalConfig}]">edit</mat-icon> </a>
                                <a><mat-icon aria-hidden="false" aria-label="Editar" (click)="openModal(item.idMultiProfessionalConfig)">delete_forever</mat-icon> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <app-empty-list *ngIf="listMultiConfig != undefined && listMultiConfig.length == 0"></app-empty-list>
        </div>
    
        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>
    
</div>