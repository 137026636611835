import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { UbsMonitoringConfigRegisterComponent } from './pages/ubs-monitoring-config-register/ubs-monitoring-config-register.component';
import { UbsMonitoringComponent } from './pages/ubs-monitoring/ubs-monitoring.component';

const routes: Routes = [
    { path: 'ubs-monitoring', component: UbsMonitoringComponent, canActivate: [AuthGuard] },
    { path: 'ubs-monitoring/config-register', component: UbsMonitoringConfigRegisterComponent, canActivate: [AuthGuard] },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UbsMonitoringRoutingModule { }
