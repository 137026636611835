import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { SelectRoomModalComponent } from 'src/app/shared/components/select-room-modal/select-room-modal.component';
import { PatientListService } from 'src/app/shared/services/API/orchestrator-queue/patient-list.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ListPatientResponse } from 'src/app/shared/services/responses/orchestrator-queue/list-patient.response';
import { WebsocketQueueService } from 'src/app/shared/services/websocket-queue.service';
import { WebsocketRoomUtilService } from 'src/app/shared/services/websocket-util/websocket-room-util.service';
import { StatusQueueEnum } from 'src/app/shared/enum/status-queue.enum';
import { EpisodeStatusEnum } from 'src/app/shared/enum/episode-status.enum';
import { AttendPatientService } from 'src/app/shared/services/API/orchestrator-queue/attend-patient.service';
import { AttendPatientRequest } from 'src/app/shared/services/requests/orchestrator-queue/attend-patient.request';
import { ListPatientStruct } from 'src/app/shared/services/structs/orchestrator-queue/list-patient.struct';
import { RoomStruct } from 'src/app/shared/services/structs/flow/room.struct';
import { EvadePatientModalComponent } from 'src/app/shared/components/evade-patient-modal/evade-patient-modal.component';
import { WebsocketAttendanceUtilService } from 'src/app/shared/services/websocket-util/websocket-attendance-util.service';
import { HealthUnitService } from 'src/app/shared/services/API/user/health-unit.service';
import { TissGuideManagementModalComponent } from 'src/app/shared/components/tiss-guides/tiss-guide-management-modal/tiss-guide-management-modal.component';
import { PrioritiesStatusEnum } from 'src/app/shared/enum/priorities-status.enum';
import { HoldModalComponent } from 'src/app/shared/components/hold-modal/hold-modal.component';
import { FunctionalityGroupEnum } from 'src/app/shared/enum/functionality-group.enum';
import { GetRoomService } from 'src/app/shared/services/API/flow/get-room.service';
import { ReturnToQueueService } from 'src/app/shared/services/API/queue/return-to-queue.service';

@Component({
  selector: 'app-virtual-triage-patient-list',
  templateUrl: './virtual-triage-patient-list.component.html',
  styleUrls: ['./virtual-triage-patient-list.component.css']
})
export class VirtualTriagePatientListComponent implements OnInit {

  constructor(
    private patientListService: PatientListService,
    private router: Router,
    private alertService: AlertService,
    public dialog: MatDialog,
    private websocketqueueService: WebsocketQueueService,
    public websocketRoomUtilService: WebsocketRoomUtilService,
    public websocketAttendanceUtilService: WebsocketAttendanceUtilService,
    private activatedRoute: ActivatedRoute,
    private attendPatientService: AttendPatientService,
    private getRoomService: GetRoomService,
    private healthUnitService: HealthUnitService,
    public returnToQueueService: ReturnToQueueService,
  ) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  public isLoading: boolean = false;
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.digital_prompt_service;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.virtual_triage_patient_list;
  public autismEnum: number = PrioritiesStatusEnum.AutistaTEA;
  public overSixtyYearsEnum: number = PrioritiesStatusEnum.MaiorDe60Anos;
  public overEightyYearsEnum: number = PrioritiesStatusEnum.MaiorDe80Anos;
  public selectedEpisode: number = 0;
  public idRoom: number;
  public idSector: number;
  public selectedRoom: RoomStruct;
  public searchText: string;
  public listPatientResponse: ListPatientResponse = new ListPatientResponse();
  public jsonListIdStatusQueue: string;
  public agendados: boolean = false;
  public showTissGuideButton: boolean = false;
  public selectedPatient: ListPatientStruct | null = null;

  //Websocket
  private socket: any;

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.paramMap.get('idRoom'))
      this.idRoom = parseInt(this.activatedRoute.snapshot.paramMap.get('idRoom'));

    if (this.activatedRoute.snapshot.paramMap.get('idSector'))
      this.idSector = parseInt(this.activatedRoute.snapshot.paramMap.get('idSector'));

    if (!this.idRoom || !this.idSector)
      this.selectRoomModal();
    else {
      if (this.websocketRoomUtilService.getIdRoom() != this.idRoom)
        this.websocketRoomUtilService.clearSocket();

      this.startWebsocket();
      this.getRoom();
    }

    if (this.websocketAttendanceUtilService.isConnect())
      this.websocketAttendanceUtilService.clearSocket();

    this.getHealthUnitConfig();
  }

  getRoom() {
    this.isLoading = true;
    this.getRoomService.getRoom(this.idRoom).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listPatientResponse.listPatient = [];
        this.selectedRoom = response.roomStruct;
        this.paginator.firstPage();
        this.search(0);
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    });
  }

  search(pageIndex: number) {
    this.isLoading = true;

    if (this.searchText == "")
      this.searchText = null;

    this.patientListService.listPatient(this.searchText, this.idRoom, this.jsonListIdStatusQueue, pageIndex, null, false, this.agendados).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listPatientResponse = response;
        this.listPatientResponse.listPatient.forEach(x => {
          x.isLoading = false;
        });

        if (this.listPatientResponse?.returnEpisodes != null && this.listPatientResponse?.returnEpisodes > 0 && (this.jsonListIdStatusQueue == null || !this.jsonListIdStatusQueue.includes(StatusQueueEnum.retorno.toString())))
          this.alertService.show('Atenção! ', this.listPatientResponse?.returnEpisodes + ' pacientes aguardando reavaliação - retorno', AlertType.warning);

        if (this.listPatientResponse?.scheduleEpisodes != null && this.listPatientResponse?.scheduleEpisodes > 0)
          this.alertService.show('Atenção! ', this.listPatientResponse?.scheduleEpisodes + ' pacientes agendados aguardando atendimento', AlertType.warning);

        this.isLoading = false;
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    });
  }

  //Websocket
  startWebsocket() {
    this.socket = this.websocketqueueService.websocketConnection();
    this.socket.emit('join', `idRoom-${this.idRoom}`)
      .on("updateList", (res) => this.updatePatientList(res))
      .io.on("reconnect", (res) => {
        this.socket.emit('join', `idRoom-${this.idRoom}`);
      });
  }

  updatePatientList(client: any) {
    if (client && client.action == "newpatient") {
      this.paginator.firstPage();
      this.search(0);
      return;
    }
    if (!this.listPatientResponse || !this.listPatientResponse.listPatient || this.listPatientResponse.listPatient.length == 0 || !client)
      return;

    let patient = this.listPatientResponse.listPatient.filter(x => x.idEpisode == client.idEpisode);

    let lastPatient = null;
    if (client.idLastEpisode)
      lastPatient = this.listPatientResponse.listPatient.filter(x => x.idEpisode == client.idLastEpisode);

    if (client.action == "changestatus") {
      patient[0].idStatusQueue = client.idStatusQueue;
      patient[0].statusQueueName = client.newStatusQeue;
      patient[0].attendantUserName = client.userName;
      patient[0].idAttendantUser = client.idUser;
      if (lastPatient && lastPatient[0]) {
        lastPatient[0].idStatusQueue = client.idLastStatusQueue;
        lastPatient[0].statusQueueName = client.lastStatusQueueName;
      }
    }
    else if (client.action == "removepatient") {
      let index = this.listPatientResponse.listPatient.indexOf(patient[0]);
      if (index >= 0) {
        let deleted = this.listPatientResponse.listPatient.splice(index, 1);
        if (deleted && deleted.length >= 1) {
          this.listPatientResponse.totalTickets = (this.listPatientResponse.totalTickets - 1);
          this.listPatientResponse.fullListSize = this.listPatientResponse.totalTickets;
        }
      }
    }
  }

  attendPatient(patient: ListPatientStruct, index: number) {
    let attendPatientRequest: AttendPatientRequest = new AttendPatientRequest();
    attendPatientRequest.patientQueue = patient;
    attendPatientRequest.idModule = this.menuModuleEnum;
    attendPatientRequest.idRoomSector = this.listPatientResponse.idRoomSector;
    attendPatientRequest.idRoomService = this.listPatientResponse.idRoomService;
    attendPatientRequest.remoteAttendance = false;
    this.selectedEpisode = patient.idEpisode;

    this.toggleIsLoading(index);
    this.makeCallAttendPatient(patient, index, attendPatientRequest);
  }

  makeCallAttendPatient(patient: ListPatientStruct, index: number, attendPatientRequest: AttendPatientRequest) {
    this.attendPatientService.attendPatient(attendPatientRequest, this.idRoom, true, false, true).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
          this.toggleIsLoading(index);
          return;
        }

        sessionStorage.setItem("videoToken", response.tokenMedic);
        this.toggleIsLoading(index);
        this.openHoldModal(patient)
      },
      error: (error) => {
        this.toggleIsLoading(index);
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openHoldModal(patient: ListPatientStruct) {
    const dialogRef = this.dialog.open(HoldModalComponent, {
      panelClass: 'medic-hold-modal',
      data: {
        time: false,
        idEpisode: patient.idEpisode,
        idQueue: patient.idQueue,
        idRoom: this.idRoom,
        idSector: this.idSector,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.confirmAttend) {
          this.updateReturnToQueue();
          this.router.navigate(['digital-prompt-service/virtual-triage/virtual-triage-attend-patient', {
            idEpisode: patient.idEpisode,
            idSector: this.idSector,
            idRoom: this.idRoom,
            idQueue: patient.idQueue
          }]);
        }
        else {
          this.search(0);
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  selectRoomModal() {
    const dialogRef = this.dialog.open(SelectRoomModalComponent, {
      data: {
        idModule: this.menuModuleEnum,
        idFunctionalityGroup: FunctionalityGroupEnum.TriagemVirtual
      },
    });
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result && result.res) {
          this.idRoom = result.res.idRoom;
          this.idSector = result.res.idSector
          this.selectedRoom = result.res;
          this.listPatientResponse.listPatient = [];
          this.startWebsocket();
          this.paginator.firstPage();
          this.search(0);
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  isAttendedOrEvaded(idStatusQueue) {
    return (idStatusQueue == StatusQueueEnum.atendido || idStatusQueue == StatusQueueEnum.evadido);
  }

  isAttended(idStatusQueue) {
    return (idStatusQueue == StatusQueueEnum.atendido);
  }

  isAttendedOrInAttendanceOrEvaded(idStatusQueue) {
    return (idStatusQueue == StatusQueueEnum.atendido || idStatusQueue == StatusQueueEnum.em_atendimento || idStatusQueue == StatusQueueEnum.evadido)
  }

  isInitiated(idEpisodeStatus) {
    return (idEpisodeStatus == EpisodeStatusEnum.Iniciado);
  }

  changePage(event: PageEvent) {
    this.search(event.pageIndex);
  }

  toggleIsLoading(index: number) {
    this.listPatientResponse.listPatient[index].isLoading = !this.listPatientResponse.listPatient[index].isLoading;
  }

  cancelPatient(idQueue: number, idStatus: number, idEpisode: number, index: number) {
    if (this.websocketRoomUtilService.getIsOccupied()) {
      this.alertDynamic("Aviso", "Você está em modo de visualização!", AlertType.warning);
      return;
    }

    if (this.isLoading)
      return;

    this.toggleIsLoading(index);
    this.openEvadePatientModal(idQueue, idStatus, idEpisode, index);
  }

  openEvadePatientModal(idQueue: number, idStatusQueue: number, idEpisode: number, index: number) {
    const dialogRef = this.dialog.open(EvadePatientModalComponent, {
      data: {
        idStatusQueue: idStatusQueue,
        idQueue: idQueue,
        idRoom: this.idRoom,
        idEpisode: idEpisode,
        manualEvasion: true,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (!result || !result.isEvade)
          this.toggleIsLoading(index);
        else
          this.alertDynamic('Sucesso', "Paciente evadido com sucesso", AlertType.success);
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  filterListPatient({ selectedItems, agendados }) {
    this.agendados = agendados;

    if (!selectedItems || selectedItems.length == 0)
      this.jsonListIdStatusQueue = null;
    else
      this.jsonListIdStatusQueue = JSON.stringify(selectedItems);

    this.paginator.firstPage();
    this.search(0);
  }

  getHealthUnitConfig() {
    this.healthUnitService.getHealthUnitConfig().subscribe({
      next: (response) => {
        if (response.isError)
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);

        this.showTissGuideButton = response.healthUnit.canAddTissGuide;
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openModalTiss(patientStruct: ListPatientStruct) {
    this.dialog.open(TissGuideManagementModalComponent, {
      data: {
        idEpisode: patientStruct.idEpisode,
        patientName: patientStruct.patientName,
        patientSocialName: patientStruct.patientSocialName,
        idRoom: this.idRoom,
        idSector: this.idSector,
        idPatient: patientStruct.idPatient
      },
      panelClass: "tiss-guide-management-modal"
    });
  }

  selectPatient(patient: ListPatientStruct): void {
    this.selectedPatient = patient;
    this.selectedEpisode = patient.idEpisode;
  }

  updateReturnToQueue() {
    this.returnToQueueService.removeReturnToQueue(this.selectedEpisode).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

      }, error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  alertDynamic(alertTypeDescription: string, alertDescription: string, alertType: AlertType) {
    if (alertType && alertType.valueOf() == AlertType.error)
      console.log(alertDescription);

    this.alertService.show(alertTypeDescription, alertDescription, alertType ? alertType.valueOf() : AlertType.error);
    this.endLoading();
  }

  endLoading() {
    this.isLoading = false;
  }
}