import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { GetMedicineTagUserResponse } from '../../responses/pharmacy/get-medicine-tag-user.response';

@Injectable({
  providedIn: 'root'
})
export class MedicineTagUserService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getMedicineTagUser(): Observable<GetMedicineTagUserResponse> {
    let uri = 'MedicineTagUser'
    
    return this.httpClient.get<GetMedicineTagUserResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
