import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BusinessIntelligenceService } from 'src/app/shared/services/API/business-intelligence/business-intelligence.service';
import { OrchestratorBiService } from 'src/app/shared/services/API/business-intelligence/orchestrator-bi.service';
declare var powerbi: any;

@Component({
  selector: 'app-business-intelligence-details',
  templateUrl: './business-intelligence-details.component.html',
  styleUrls: ['./business-intelligence-details.component.css']
})
export class BusinessIntelligenceDetailsComponent implements OnInit {

  constructor(private bIService: BusinessIntelligenceService,
    private orchestratorBiService: OrchestratorBiService,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private router: Router) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.business_intelligence;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.bi_lista_relatórios;
  public isFirstLoading: boolean = true;
  public models;

  public reportName: string;
  public config = {
    type: 'report',
    tokenType: 1,
    accessToken: undefined,
    embedUrl: undefined,
    id: undefined,
    permissions: 0,
    viewMode: 0,
    settings: {
      filterPaneEnabled: false,
      navContentPaneEnabled: false,
      layoutType: 1 // Set the default layout type to Master
    }
  };

  ngOnInit(): void {

    let idReportFunctionHealthUnit: number = null;
    if (this.activatedRoute.snapshot.paramMap.get('idReportFunctionHealthUnit')) {
      idReportFunctionHealthUnit = parseInt(this.activatedRoute.snapshot.paramMap.get('idReportFunctionHealthUnit'));
    }
    else {
      this.alertService.show('Erro', "Para conseguir ver o relatório precisa do seu id", AlertType.error);
      this.router.navigate(['/audit/audit']);
    }
    this.models = window['powerbi-client'].models;
    this.getReport(idReportFunctionHealthUnit, true);
  }

  
  ngAfterViewInit() {
    let idReportFunctionHealthUnit: number = parseInt(this.activatedRoute.snapshot.paramMap.get('idReportFunctionHealthUnit'));
    this.getReport(idReportFunctionHealthUnit, false);
  }

  getReport(idReportFunctionHealthUnit: number, showAlert: boolean) {
    this.orchestratorBiService.showReport(idReportFunctionHealthUnit).subscribe((response) => {
      this.isFirstLoading = false;
      if (response.isError && showAlert) {
        this.alertService.show("Erro", response.errorDescription, AlertType.error);
        return;
      } else if (response.isError) {
        return;
      }
      this.config.embedUrl = response.report.reportLink;
      this.config.id = response.report.biReport;
      this.config.accessToken = response.report.token;
      this.reportName = response.report.reportName;   

      const isMobile = window.innerWidth < 768; // Check if the page width is less than 768px (adjust the threshold as needed)
      if (isMobile) {
        this.config.settings.layoutType = this.models.LayoutType.MobilePortrait; // Set the layout type to MobilePortrait for mobile devices
      } else {
        this.config.settings.layoutType = this.models.LayoutType.Master; // Set the layout type to Custom for non-mobile devices
      }

      let divToEmbbed = document.getElementById("reportDiv");
      let report = powerbi.embed(divToEmbbed, this.config);
      report.on('loaded', function () {
        report.getPages().then(function (pages) {
            let page = pages[parseInt(response.report.page)-1];
            if(page) page.setActive();
        });
      });
      })
  }
}