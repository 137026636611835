<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-confirm-modal-list">
            <h2>Relatórios</h2>
        </div>
        <div class="footer-confirm-modal-list">
            <div class="row" *ngIf="admissionString64">
                <button mat-flat-button color="primary" class="btn-block" (click)="clickReport(admissionString64)">Relatório de Admissão</button>
            </div>
            <div class="row">
                <button mat-flat-button color="primary" class="btn-block" (click)="openCreateStatementOfAttendance()">Declaração de Comparecimento</button>
            </div>
            <div class="row" *ngIf="wristbandString64">
                <button mat-flat-button color="primary" class="btn-block" (click)="clickReport(wristbandString64)">Pulseira</button>
            </div>
            <div class="row">
                <button mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Fechar</button>
            </div>
        </div>
    </div>
</div>