import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { SpecialNecessityEnum } from 'src/app/shared/enum/risk-classification.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { FowardService } from 'src/app/shared/services/API/orchestrator-queue/foward.service';
import { SpecialNecessityModel } from 'src/app/shared/services/models/risk-classification/special-necessity.model';
import { FowardRequest } from 'src/app/shared/services/requests/orchestrator-queue/foward.request';
import { LocationStruct } from 'src/app/shared/services/structs/orchestrator-queue/location.struct';
import { ClassificationData, UtilsClassificationService } from '../../utils.service';
import { LookupRiskService } from 'src/app/shared/services/API/risk-classification/lookup-risk.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';
import { BillingDataRequest } from 'src/app/shared/services/requests/billing/billing-data-request';
import { ProcedureSigtapCBOService } from 'src/app/shared/services/API/billing/procedure-sigtap-cbo.service';
import { DiscriminatorTypeEnum } from 'src/app/shared/enum/discriminator-type.enum';
import { ProcedureSigtapModel } from 'src/app/shared/services/models/billing/procedure-sigtap.model';
import { DeviceTypeEnum } from 'src/app/shared/enum/device-type.enum';
import { UtilsMeasurerService } from '../flowchart/protocol-v2/utils-measurer.service';
import { ProcedureTriggersEnum } from 'src/app/shared/enum/procedure-triggers.enum';
import { FlowStruct } from 'src/app/shared/services/structs/flow/flow.struct';
import { MatDialog } from '@angular/material/dialog';
import { ModalPreResumeComponent } from './modal-pre-resume/modal-pre-resume.component';
import { WebsocketAttendanceUtilService } from 'src/app/shared/services/websocket-util/websocket-attendance-util.service';
import { DeviceTypeSectorResponse } from 'src/app/shared/services/responses/risk-classification/device-type-sector.response';
import { AttendanceOccupiedRemoveModalComponent } from 'src/app/shared/components/attendance-occupied-remove-modal/attendance-occupied-remove-modal.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-forward-select',
  templateUrl: './forward-select.component.html',
  styleUrls: ['./forward-select.component.css']
})
export class ForwardSelectComponent implements OnInit, OnDestroy {

  constructor(private forward: FowardService,
    private alertService: AlertService,
    private utilService: UtilsClassificationService,
    public router: Router,
    private formBuilder: FormBuilder,
    public lookupService: LookupRiskService,
    private utilClassification: UtilsClassificationService,
    private procedureSigtapCBOService: ProcedureSigtapCBOService,
    private authService: AuthService,
    public measurerService: UtilsMeasurerService,
    public dialog: MatDialog,
    private websocketAttendanceUtilService: WebsocketAttendanceUtilService,
    private activatedRoute: ActivatedRoute) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.classification;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.classification_pre_registration;
  public isFirstLoading: boolean = true;
  public idEpisode: number;
  public idRoom: number;
  public idSector: number;
  public idQueue: number = null;
  public data: ClassificationData;
  public painColorCode: string[] = [];
  public model: FormGroup;
  public location: LocationStruct[] = [];
  public idSpecialNecessityOthers: number = SpecialNecessityEnum.outros;
  public idSpecialNecessity: number;
  public specialNecessityList: SpecialNecessityModel[];
  public isAutomaticFoward: boolean = false;
  public idLastQueue: number;
  public listProcedureSigtap: any[] = [];
  public codCbo: string;
  public urlListProcedure: string;
  public idCompetence: number;
  public listPreselectedPRocedure: ProcedureSigtapModel[] = [];
  public isFlowSequence: boolean;
  public flowSequence: FlowStruct[] = [];
  public isNext: boolean = false;
  public setUppercaseText: boolean = false;
  public userLogin: string;
  public userName: string;
  public isAttendanceRemoval: boolean = false;

  ngOnInit(): void {
    this.userLogin = this.authService.getTokenMenu().login;
    this.userName = this.authService.getTokenMenu().userName;
    this.data = this.utilService.getClassificationData();
    this.painColorCode[0] = this.data.painColorCode;
    this.idEpisode = this.data.idEpisode;
    this.model = this.formBuilder.group({
      forward: [null, [Validators.required]],
      specialNecessity: [this.data.specialNecessity],
      idSpecialNecessity: [this.data.idSpecialNecessity],
      observation: [this.data.observation]
    });
    this.getAllDestination();
    this.populateLookup();
    this.codCbo = this.authService.getTokenMenu().codCbo;
    this.idCompetence = this.authService.getTokenMenu().healthUnit.idProcedureSigtapCompetence;
    if (this.codCbo && this.idCompetence) {
      this.getPreselectedProcedures();
      let url = `ProcedureSigtapCBO/codCbo/${this.codCbo}/idCompetence/${this.idCompetence}`
      this.urlListProcedure = environment.urlApiBilling + url;
    }
    if ((this.data.idDeviceType == DeviceTypeEnum.Trius || this.data.idDeviceType == DeviceTypeEnum.Berco || this.data.idDeviceType == DeviceTypeEnum.Bluetooth) && Array.isArray(this.data.discriminatorAllValues)) {
      let selectedIndex: number = this.data.discriminatorAllValues.findIndex(c => c.idDiscriminator == this.data.discriminator.idDiscriminator);
      selectedIndex = selectedIndex > -1 ? selectedIndex : this.data.discriminatorAllValues.length + 1;
      this.measurerService.setIndex(selectedIndex);
      this.data.discriminatorsPersist = this.data.discriminatorsPersist == undefined ? [] : this.data.discriminatorsPersist;
      this.measurerService.createReference(this.data.discriminatorValues, this.data.discriminatorsPersist, this.data.discriminatorAllValues,
        this.data.deviceThermometer, this.data.deviceGlucometer, this.data.deviceOximeter, this.data.devicePressure);
      this.measurerService.restartMeasurer();
    }
    let deviceTypeSectorResponse: DeviceTypeSectorResponse = this.utilClassification.getDevicetypeBySector()
    if(deviceTypeSectorResponse.deviceTypeSector.setUppercaseText)
      this.setUppercaseText = deviceTypeSectorResponse.deviceTypeSector.setUppercaseText;

    this.idSector = parseInt(this.activatedRoute.snapshot.paramMap.get('idSector'));
    this.idRoom = parseInt(this.activatedRoute.snapshot.paramMap.get('idRoom'));
    this.idQueue = parseInt(this.activatedRoute.snapshot.paramMap.get('idQueue'));

    if (Number.isInteger(this.idQueue) &&  this.idQueue && this.idQueue > 0 && Number.isInteger(this.idEpisode) &&  this.idEpisode && this.idEpisode > 0 && this.data && this.data.isTelephoneClassification != true) 
      this.startWebsocket();  

    this.openDialogPreResume();
  }

  getPreselectedProcedures() {
    let idsTriggers: number[] = [ProcedureTriggersEnum.classificacao_risco.valueOf()];
    if (!this.data.isPatientWhite) {
      if (this.data.discriminatorValues[DiscriminatorTypeEnum.PressaoArterial]) {
        idsTriggers.push(ProcedureTriggersEnum.afericao_pressao_arterial.valueOf());
      }
      if (this.data.discriminatorValues[DiscriminatorTypeEnum.Glicemia]) {
        idsTriggers.push(ProcedureTriggersEnum.glicemia_capilar.valueOf());
      }
      if (this.data.discriminatorValues[DiscriminatorTypeEnum.Temperatura]) {
        idsTriggers.push(ProcedureTriggersEnum.afericao_temperatura.valueOf());
      }
    }

    this.procedureSigtapCBOService.listProcedureSigtapCodProcedure(idsTriggers, this.idCompetence, this.codCbo).subscribe(response => {
      if (response.isError) {
        this.alertService.show("Erro", response.errorDescription, AlertType.error);
        return;
      }
      this.listPreselectedPRocedure = response.list;
      this.listProcedureSigtap = response.list;
    })
  }

  getAllDestination() {
    let fowardRequest: FowardRequest = new FowardRequest();
    fowardRequest.idEpisode = this.idEpisode;
    fowardRequest.idRoom = this.data.idRoom;
    fowardRequest.birthDate = this.data.birthDate;
    fowardRequest.idPriorityColor = this.data.priority.idPriorityColor;
    fowardRequest.idFlowchart = this.data.idFlowchart;
    if (!this.data.newPatient) {
      this.forward.listDestination(fowardRequest).subscribe((response) => {
        this.isFirstLoading = false;
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        } else if (response.isAutomaticFoward) {
          this.isAutomaticFoward = true;
          this.alertService.show('Aviso', response.automaticFoward.toString(), AlertType.warning);
          return;
        }
        this.isFlowSequence = (response.flowSequence && response.flowSequence.length > 0);
        this.flowSequence = response.flowSequence;
        if (this.isFlowSequence) {
          this.model.get('forward').setValue(this.flowSequence[1].idFlow);
        }
        this.location = response.location;
        this.idLastQueue = response.idLastQueue;
      });
    }
    else {
      this.forward.listEpisodeDestination(fowardRequest).subscribe((response) => {
        this.isFirstLoading = false;
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        } else if (response.isAutomaticFoward) {
          this.isAutomaticFoward = true;
          this.alertService.show('Aviso', response.automaticFoward.toString(), AlertType.warning);
          return;
        }
        this.isFlowSequence = (response.flowSequence && response.flowSequence.length > 0);
        this.flowSequence = response.flowSequence;
        this.location = response.location;
      });
    }
  }

  onChangeSpecialNecessity(event: number) {
    this.idSpecialNecessity = event;
    if (this.idSpecialNecessity == this.idSpecialNecessityOthers) {
      this.model.get('specialNecessity').setValidators(Validators.required);
    } else {
      this.model.get('specialNecessity').setValidators(Validators.nullValidator);
    }
  }

  populateLookup() {
    this.lookupService.getLookup().subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.specialNecessityList = response.specialNecessity;
    });
  }

  mapToBillingDataRequest(): BillingDataRequest {
    let billingDataRequest: BillingDataRequest = new BillingDataRequest();
    billingDataRequest.idModule = MenuModuleEnum.classification;
    billingDataRequest.idEpisode = this.idEpisode;
    billingDataRequest.listIdProcedure = this.listProcedureSigtap.map(a => a.idProcedureSigtap);
    return billingDataRequest;
  }

  addItemProcedure(event: any) {
    this.listProcedureSigtap = event;
    this.listPreselectedPRocedure = this.listProcedureSigtap;
  }

  submit() {
    if (this.model.invalid && !this.isAutomaticFoward) {
      this.alertService.show('Erro', "Nenhum Encaminhamento foi selecionado. ", AlertType.error);
      return;
    }
    let data = this.utilClassification.getClassificationData();
    data.forward = {
      'idFlow': this.model.get('forward').value,
      'idLastQueue': this.idLastQueue,
      'locationName': this.location.length > 0 ? (this.location.find(c => c.idFlow == this.model.get('forward').value).locationName) : null,
    };
    data.flowSequence = this.flowSequence;
    data.billingData = this.mapToBillingDataRequest();
    data.specialNecessity = this.model.get('specialNecessity').value;
    data.idSpecialNecessity = this.model.get('idSpecialNecessity').value;
    data.observation = this.model.get('observation').value;
    data.discriminatorValues = this.data.discriminatorValues;
    this.utilClassification.updateClassificationData(data);
    this.isNext = true;
    this.router.navigate(['/classification/supplementary-data', { idEpisode: this.idEpisode, idRoom: this.idRoom, idQueue: this.idQueue}]);
  }

  // private keepAliveIntervalId = setInterval(() => {
  //   if (this.data.idRoom) {
  //     this.roomAccessUtilService.postRoomAccessHistory(this.data.idRoom);

  //   }
  // }, this.roomAccessUtilService.roomKeepAliveTime());

  ngOnDestroy() {
    if (this.data.idDeviceType == DeviceTypeEnum.Trius || this.data.idDeviceType == DeviceTypeEnum.Berco || this.data.idDeviceType == DeviceTypeEnum.Bluetooth) {
      this.measurerService.setIndex(-1);
    }
    // clearInterval(this.keepAliveIntervalId);
  }

  openDialogPreResume(): void {
    const dialogRef = this.dialog.open(ModalPreResumeComponent, {});
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.isGoBack){
          this.isNext = false;
          this.router.navigate(['/classification/flowchart', { idEpisode: this.idEpisode, idRoom: this.idRoom, idQueue: this.idQueue}]);
        }          
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  async restartMeasurers() {
    await this.measurerService.restartMeasurer();
    this.alertService.show('Info', "Medidores reiniciados", AlertType.info);
  }

   //Websocket
   startWebsocket() {
    if (!this.websocketAttendanceUtilService.isConnect())
      this.websocketAttendanceUtilService.connectwebsocketRoom(this.idRoom, this.idQueue, this.idEpisode, this, true, false, this.authService.getToken(), this.userLogin, this.userName);

    this.websocketAttendanceUtilService.setContext(this);
    this.websocketAttendanceUtilService.setFunctions(null, null, this.onRemoveAttendant);         
  }

  onRemoveAttendant(thisParam, res: any){
    thisParam.isAttendanceRemoval = false;
    
    if(res.userLogin != thisParam.userLogin){
      thisParam.isAttendanceRemoval = true;
      
      thisParam.websocketAttendanceUtilService.clearSocket();
      let ref = thisParam.dialog.open(AttendanceOccupiedRemoveModalComponent, { data: { ...res } });
      ref.afterClosed().subscribe(() => {
        thisParam.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          thisParam.router.navigate(['/classification/classification-patient-list', { idSector: thisParam.idSector, idRoom: thisParam.idRoom }]));
      })
    }
  }
}
