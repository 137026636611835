import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilsClassificationService } from 'src/app/classification/utils.service';
import { DiscriminatorTypeEnum } from 'src/app/shared/enum/discriminator-type.enum';
import { DiscriminadorHeartRateSleepy, DiscriminadorHeartRegular, DiscriminadorSaturationAtmosphericAir } from 'src/app/shared/enum/risk-classification/discriminators.enum';
import { TriageRequest } from 'src/app/shared/services/requests/orchestrator-patient/triage.request';

@Component({
  selector: 'app-modal-pre-resume',
  templateUrl: './modal-pre-resume.component.html',
  styleUrls: ['./modal-pre-resume.component.css']
})
export class ModalPreResumeComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ModalPreResumeComponent>,
    private utilService: UtilsClassificationService) {
  }

  public alergies: string;
  public specialNecessityName: string = "";
  public triage: TriageRequest = new TriageRequest();
  public pain: number;
  public painType: number;
  public protocolName: string;

  ngOnInit(): void {
    this.data = this.utilService.getClassificationData();
    this.data.discriminatorValues = this.data.discriminatorValues && this.data.discriminatorValues.length > 0 ? this.data.discriminatorValues : [];
    this.alergies = "";
    this.mapValues();
    this.specialNecessityName = this.data.specialNecessityName;
  }

  mapValues() {
    if (this.data.flowSequence && this.data.flowSequence.length > 0) {
      this.triage.idFlowCompleted = this.data.flowSequence[0].idFlow;
      if (this.data.flowSequence.length > 1) {
        this.triage.idFlow = this.data.flowSequence[1].idFlow;
      }
    }

    this.protocolName = this.data.protocolName;
    let discriminatorValues = this.data.discriminatorValues && this.data.discriminatorValues.length > 0 ? this.data.discriminatorValues : false;
    this.triage.startClassification = this.data.datetimeStartTriage;
    this.triage.idEpisode = this.data.idEpisode;
    this.triage.idGravity = this.data.priority.idPriorityColor;
    this.triage.gravityColor = this.data.priority.coloerHex;
    this.triage.temperature = discriminatorValues[DiscriminatorTypeEnum.Temperatura] ? parseFloat(discriminatorValues[DiscriminatorTypeEnum.Temperatura]) : null;;
    this.triage.respiratoryFrequency = discriminatorValues[DiscriminatorTypeEnum.FrequenciaRespiratoria] ? parseInt(discriminatorValues[DiscriminatorTypeEnum.FrequenciaRespiratoria]) : null;;
    this.triage.heartRate = discriminatorValues[DiscriminatorTypeEnum.FrequenciaCardiaca] ? parseInt(discriminatorValues[DiscriminatorTypeEnum.FrequenciaCardiaca]) : null;
    if (!Array.isArray(this.data.discriminatorAllValues)) {
      this.data.discriminatorAllValues = [];
    }
    let heartRate = this.data.discriminatorAllValues.find(c => c.idDiscriminatorType == DiscriminatorTypeEnum.FrequenciaCardiaca);
    this.triage.capillaryFillingTimeValue = this.data.perfusaoCapilarValue;
    if (heartRate) {
      this.triage.heartRateRegular = heartRate.type == DiscriminadorHeartRegular.regular.toString();
    } else if (this.data.frequencyPulseType) {
      this.triage.heartRateRegular = this.data.frequencyPulseType == DiscriminadorHeartRegular.regular.toString();
    } else {
      this.triage.heartRateRegular = null;
    }
    this.triage.glucose = discriminatorValues[DiscriminatorTypeEnum.Glicemia] ? parseInt(discriminatorValues[DiscriminatorTypeEnum.Glicemia]) : null;
    this.triage.bloodPressureDiastole = discriminatorValues[DiscriminatorTypeEnum.PressaoArterial] ? parseInt(discriminatorValues[DiscriminatorTypeEnum.PressaoArterial].split("/")[1]) : null;;
    this.triage.bloodPressureSystole = discriminatorValues[DiscriminatorTypeEnum.PressaoArterial] ? parseInt(discriminatorValues[DiscriminatorTypeEnum.PressaoArterial].split("/")[0]) : null;
    this.triage.saturation = discriminatorValues[DiscriminatorTypeEnum.Saturacao] ? parseInt(discriminatorValues[DiscriminatorTypeEnum.Saturacao]) : null;
    let discriminatorPain = this.data.discriminatorAllValues.find(c => c.idDiscriminatorType == DiscriminatorTypeEnum.Dor || c.idDiscriminatorType == DiscriminatorTypeEnum.DorCoceira);
    this.pain = discriminatorPain ? discriminatorPain.value : null;
    this.painType = discriminatorPain ? discriminatorPain.type : null;
    this.triage.idPain = discriminatorPain ? discriminatorPain.type : null;
    this.triage.complaint = this.data.complain;
    this.triage.flowchart = this.data.flowchartName;
    this.triage.idFlowchart = this.data.idFlowchart;
    this.triage.discriminatorSelectionMotive = this.data.discriminatorSelectionMotive;
    this.triage.discriminator = !this.data.isPatientWhite ? this.data.discriminator.discriminatorName : this.data.arrivalReason;
    this.triage.idDiscriminator = this.data.discriminator ? this.data.discriminator.idDiscriminator : null;
    this.triage.glasgow = discriminatorValues[DiscriminatorTypeEnum.Glasgow] ? discriminatorValues[DiscriminatorTypeEnum.Glasgow] : null;
    this.triage.glasgowEye = this.data.glasgowEye;
    this.triage.glasgowVerbal = this.data.glasgowVerbal;
    this.triage.glasgowMotor = this.data.glasgowMotor;
    let DiscriminatorSaturation = this.data.discriminatorAllValues.find(c => c.idDiscriminatorType == DiscriminatorTypeEnum.Saturacao);
    this.triage.atmosphericAir = DiscriminatorSaturation ? DiscriminatorSaturation.type == DiscriminadorSaturationAtmosphericAir.atmosphericAir.toString() : null;
    this.triage.capillaryFillingTime = this.data.perfusaoCapilar;
    this.triage.fallRisk = null;
    this.triage.idSuspicion = this.data.idSuspicion;
    this.triage.diseaseHistory = this.data.diseaseHistory;
    this.triage.comorbidities = this.data.comorbidities;
    this.triage.height = this.data.height ? parseFloat(this.data.height) : null;
    this.triage.weight = this.data.weight;
    this.triage.idRoom = this.data.idRoom;
    this.triage.idForward = this.data.idRoom;
    this.triage.gravityName = this.data.priority.priorityColorName;
    this.triage.observations = (this.data.observation ? this.data.observation : "") + "" + (this.data.arrivalReasonDesc ? this.data.arrivalReasonDesc : "");
    this.triage.allergies = this.alergies;
    this.triage.billingData = this.data.billingData;
    this.triage.idLastFlowchart = this.data.idLastFlowchart;
    this.triage.lastFlowchart = this.data.lastFlowchart;

    this.triage.temperatureComplementaryData = this.data.temperatureComplementaryData;
    this.triage.heartRateComplementaryData = this.data.heartRateComplementaryData;
    this.triage.bloodPressureDiastoleComplementaryData = this.data.bloodPressureDiastoleComplementaryData;
    this.triage.bloodPressureSystoleComplementaryData = this.data.bloodPressureSystoleComplementaryData;
    this.triage.respiratoryFrequencyComplementaryData = this.data.respiratoryFrequencyComplementaryData;
    this.triage.glucoseComplementaryData = this.data.glucoseComplementaryData;
    this.triage.saturationComplementaryData = this.data.saturationComplementaryData;

    // this.triage.idAllergies = this.data.allergies.reduce((acc, cur) => {
    //   acc.push(cur.idAllergy)
    //   return acc;
    // }, []);
    this.triage.listIdComorbodity = this.data.listIdComorbodity;
    this.triage.hasFlowchartIA = this.data.hasFlowchartIA;
    this.triage.iaResult = this.data.iaResult;
    if (heartRate && heartRate.state) {
      this.triage.isHeartRateSleeping = heartRate.state == DiscriminadorHeartRateSleepy.sleepy.toString();
    } else if (this.data.frequencyPulseState) {
      this.triage.isHeartRateSleeping = this.data.frequencyPulseState == DiscriminadorHeartRateSleepy.sleepy.toString();
    } else {
      this.triage.isHeartRateSleeping = null;
    }
  }

  goBack(){
    this.matDialogRef.close({isGoBack: true});
  }
}
