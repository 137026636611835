import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { TotemRequest } from '../../requests/totem/totem.request';
import { GenerateEpisodeResponse } from '../../responses/orchestrator-totem/generate-episode.response';

@Injectable({
  providedIn: 'root'
})
export class EpisodeService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public createEpisode(pin: string, idTotem: number, idHealthUnit: number, body: TotemRequest): Observable<GenerateEpisodeResponse> {

    let uri = `Episode/idTotem/${idTotem}/pin/${pin}/idHealthUnit/${idHealthUnit}`

    return this.httpClient.post<GenerateEpisodeResponse>(environment.urlApiOrchestratorTotem + uri, body, this.addHeaderHashTotem())
    .pipe(
      catchError(this.handleErrorTotem)
    )
  }
}
