export enum LookupHospitalizationReportEnum {
    Condicoes_que_justicam_a_internacao = 21,
    Principais_resultados_de_provas_diagnosticas_resultados_de_exames_realizados = 22,
    Cid_10_secundario = 25,
    Cid_10_causas_associadas = 26,
    Descricao_do_procedimento_solicitado = 27,
    Codigo_do_procedimento = 28,
    Clinica = 29,
    Carater_da_internacao = 30,
    Documento = 31,
    N_documento_cns_cpf_do_profissional_solicitante_assistente = 32,
    Nome_do_profissional_solicitante_assistente = 33,
    Data_da_solicitacao = 34,
    Assinatura_e_carimbo_n_do_registro_do_conselho = 35,
}