import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { ListFieldResponse } from 'src/app/shared/services/responses/front-desk/list-field.response';
import { ListIdentificationResponse } from '../../responses/front-desk/list-identification.response';

@Injectable({
  providedIn: 'root'
})
export class FieldService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listField(): Observable<ListFieldResponse>{
    let uri = `Field`;  
    
    return this.httpClient.get<ListFieldResponse>(environment.urlApiFrontDesk + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
