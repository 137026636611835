<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-confirm-modal-list">
            <h2>Buscar por CPF </h2>
         </div>
         <form [formGroup]="searchModel">
            <div class="body-confirm-modal-list">
                <p>Informe aqui o CPF do paciente para ser encontrado no banco de dados</p>
                <mat-form-field appearance="outline">
                    <mat-label>CPF</mat-label>
                    <input #cpfInput matInput type="text" formControlName="cpf" [mask]="masks.cpf">
                    <mat-error *ngIf="searchModel.get('cpf').invalid">Informe o CPF</mat-error>
                </mat-form-field> 
            </div>
            <div class="footer-confirm-modal-list">
                <button mat-flat-button color="primary" class="btn-block"
                    (click)="clickPesquisar()">
                    <span *ngIf="isLoading == false">Buscar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
            </div>
        </form>
    </div>
</div>