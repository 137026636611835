import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { VariablesDataSourceEnum } from 'src/app/shared/enum/variables-data-source.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { DataSourceService } from 'src/app/shared/services/API/data-intelligente/data-source.service';
import { LookupDataService } from 'src/app/shared/services/API/data-intelligente/lookup-data.service';
import { VariableService } from 'src/app/shared/services/API/flow/variable.service';
import { DataSourceVariableModel } from 'src/app/shared/services/models/data-intelligence/data-source-variable.model';
import { PostPutDataSourceRequest } from 'src/app/shared/services/requests/data-intelligence/post-put-data-source.request';
import { GetDataSourceResponse } from 'src/app/shared/services/responses/data-intelligence/get-data-source.response';
import { GetLookupDataResponse } from 'src/app/shared/services/responses/data-intelligence/get-lookup-data.response';
import { CompleteDataSourceStruct } from 'src/app/shared/services/structs/data-intelligence/complete-data-source.struct';
import { RequestDataSourceStruct } from 'src/app/shared/services/structs/data-intelligence/request-data-source.struct';
import { RequestExpressionStruct } from 'src/app/shared/services/structs/data-intelligence/request-expression.struct';
import { ConfirmChangeTypeModalComponent } from './confirm-change-type-modal/confirm-change-type-modal.component';
import { BedDataIntService } from 'src/app/shared/services/API/orchestrator-data-intelligence/bed-data-int.service';
import { BedWithPatientService } from 'src/app/shared/services/API/orchestrator-data-intelligence/bed-with-patient.service';
import { EpisodeDataIntService } from 'src/app/shared/services/API/orchestrator-data-intelligence/episode-data-int.service';
import { PatientDataIntService } from 'src/app/shared/services/API/orchestrator-data-intelligence/patient-data-int.service';
import { TriageDataIntService } from 'src/app/shared/services/API/orchestrator-data-intelligence/triage-data-int.service';
import { QueueDataIntService } from 'src/app/shared/services/API/orchestrator-data-intelligence/queue-data-int.service';
import { DataIntVariableRequest } from 'src/app/shared/services/requests/orchestrator-data-intelligence/data-int-variable.request';
import { DataSourceTypeEnum } from 'src/app/shared/enum/data-source-type.enum';
import { ExpressionStruct } from 'src/app/shared/services/structs/orchestrator-data-intelligence/expression.struct';
import { DataSourceVariableService } from 'src/app/shared/services/API/orchestrator-data-intelligence/data-source-variable.service';
import { DataSourceVariable } from 'src/app/shared/services/models/orchestrator-data-intelligence/data-source-variable.model';
import { CompleteTableData, TableCell, TableResult } from './complete-table-data';

@Component({
  selector: 'app-data-source-register',
  templateUrl: './data-source-register.component.html',
  styleUrls: ['./data-source-register.component.css']
})
export class DataSourceRegisterComponent implements OnInit {

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dataSourceService: DataSourceService,
    private lookupService: LookupDataService,
    private router: Router,
    private alertService: AlertService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private variableService: VariableService,
    private bedDataIntService: BedDataIntService,
    private bedWithPatientService: BedWithPatientService,
    private episodeDataIntService: EpisodeDataIntService,
    private patientDataIntService: PatientDataIntService,
    private triageDataIntService: TriageDataIntService,
    private queueDataIntService: QueueDataIntService,
    private dataSourceVariableService: DataSourceVariableService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.dashboard;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.data_source;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isActive: boolean;
  public lookupResponse: GetLookupDataResponse;
  public filteredList: DataSourceVariableModel[];
  public model: UntypedFormGroup;
  public idDataSource: number;
  public listExpression: RequestExpressionStruct[];
  public arrayVariables: any[];
  public oldDataSourceType: number;
  public isDuplicate: boolean;
  public variableResultList: any[];
  public completeTableData: CompleteTableData;
  public listDSVariable: DataSourceVariable[];
  public isLongTime: boolean = false;

  ngOnInit(): void {
    this.isLoading = true;
    this.isFirstLoading = true;
    this.lookupResponse = new GetLookupDataResponse();
    this.completeTableData = new CompleteTableData();
    this.filteredList = [];

    this.model = this.formBuilder.group({
      dataSourceName: ['', [Validators.required]],
      description: [''],
      idDataSourceType: [null, Validators.required],
      idCutOffDate: [null, Validators.required],
      expressions: this.formBuilder.array([]),
    });


    if (this.activatedRoute.snapshot.paramMap.get('idDataSource'))
      this.idDataSource = parseInt(this.activatedRoute.snapshot.paramMap.get('idDataSource'));

    if (this.activatedRoute.snapshot.paramMap.get('isDuplicate'))
      this.isDuplicate = this.activatedRoute.snapshot.paramMap.get('isDuplicate') === 'true';

    if (this.idDataSource) {
      this.getDataSource(this.idDataSource);
    } else {
      this.populateDataSourceLookup();
    }

  }

  submit() {
    if (this.isLoading || this.model.invalid)
      return;

    this.isLoading = true;
    let request: PostPutDataSourceRequest;
    request = new PostPutDataSourceRequest();
    request.dataSource = new RequestDataSourceStruct();
    request.dataSource.listExpressions = [];

    request.dataSource.dataSourceName = this.model.get('dataSourceName').value;
    request.dataSource.description = this.model.get('description').value;
    request.dataSource.idDataSourceType = this.model.get('idDataSourceType').value;
    request.dataSource.idCutOffDate = this.model.get('idCutOffDate').value;

    this.model.get('expressions').value.forEach((x) => {
      var expression = new RequestExpressionStruct();
      expression.idConjunction = x.idConjunction ? parseInt(x.idConjunction) : null;
      expression.idDataSourceVariable = x.idVariable;
      expression.idLogicalExpression = x.idLogicalExpression;
      expression.value = x.value.toString();
      request.dataSource.listExpressions.push(expression);
    });
    if (!this.idDataSource || this.isDuplicate)
      this.create(request);
    else
      this.update(request);
  }

  getDataSource(idDataSource: number) {
    this.dataSourceService.get(idDataSource).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.mapGetResponseToForm(response.completeDataSourceStruct);
      this.isLoading = false;
    },
      (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  mapGetResponseToForm(dataSource: CompleteDataSourceStruct) {
    this.model.get('dataSourceName').setValue(dataSource.dataSource.dataSourceName);
    this.model.get('description').setValue(dataSource.dataSource.description);
    this.model.get('idDataSourceType').setValue(dataSource.dataSource.idDataSourceType);
    this.model.get('idCutOffDate').setValue(dataSource.dataSource.idCutOffDate);
    if (this.model.get('idCutOffDate').value > 1){
      this.isLongTime = true;
    };
    
    this.oldDataSourceType = dataSource.dataSource.idDataSourceType;

    dataSource.listExpressions.forEach((x) => {
      (this.model.controls['expressions'] as UntypedFormArray).push(
        this.formBuilder.group({
          idConjunction: [x.idConjunction?.toString()],
          idLogicalExpression: [x.idLogicalExpression.toString()],
          idVariable: [x.idDataSourceVariable.toString()],
          value: [x.value]
        })
      )
    });
    this.populateDataSourceLookup();
    this.getVariablesByType();
  }

  create(createRequest: PostPutDataSourceRequest) {
    this.dataSourceService.create(createRequest).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.isLoading = false;
      this.router.navigate(['/dashboard/data-source']);
    },
      (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  update(updateRequest: PostPutDataSourceRequest) {
    this.dataSourceService.update(updateRequest, this.idDataSource).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.isLoading = false;
      this.router.navigate(['/dashboard/data-source']);
    },
      (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  populateDataSourceLookup() {
    this.lookupService.listLookup(false).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.isFirstLoading = false;
      this.lookupResponse.listCutOffDate = response.listCutOffDate;
      this.lookupResponse.listVariableType = response.listVariableType;
      this.lookupResponse.listLogicalExpression = response.listLogicalExpression;
      this.lookupResponse.listDataSourceType = response.listDataSourceType;
      this.lookupResponse.listConjunction = response.listConjunction;
      this.lookupResponse.listDataSourceVariable = response.listDataSourceVariable;
      this.lookupResponse.listDataSourceVariable.forEach((c) => {
        if (c.idVariableType == VariablesDataSourceEnum.select) {
          this.variableService.getValuesVariables(c.urlSelect).subscribe((response) => {
            if (response.isError) {
              this.alertService.show('Erro', "Ao buscar os valores da variável:" + c.dataSourceVariableName, AlertType.error);
              return
            }
            c.listValues = response.list;
          });
        }
      })

      if (this.idDataSource) {
        this.setArrayOfVariables();
      }

      if (this.model.get('idDataSourceType').value) {
        this.changeType(this.model.get('idDataSourceType').value);
      }
      this.isLoading = false;
    },
      (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      });
  }

  userChangeType(event: any) {
    if (this.oldDataSourceType)
      this.openModal(event.value);
    else {
      this.changeType(event.value);
      this.oldDataSourceType = event.value;
      this.completeTableData = null;
      this.listDSVariable = null;
      this.getVariablesByType();
      this.setArrayOfVariables();
    }
  }

  openModal(value: number) {
    const dialogRef = this.dialog.open(ConfirmChangeTypeModalComponent, {
      data: {
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.changeDataSource) {
        this.changeType(value);
        this.model.controls['expressions'] = this.formBuilder.array([]);
        this.oldDataSourceType = value;
        this.completeTableData = null;
        this.listDSVariable = null;
        this.setArrayOfVariables();
        this.getVariablesByType();
      }
      else {
        this.model.get('idDataSourceType').setValue(this.oldDataSourceType);
      }
    });
  }

  setArrayOfVariables() {
    this.arrayVariables = null;
    let arrayVariablesAux = [];
    this.model.controls['expressions']['controls'].forEach((x) => {
      let listItem = this.lookupResponse.listDataSourceVariable.filter(c => c.idDataSourceType == this.model.get('idDataSourceType').value);
      let item = listItem.findIndex(c => c.idDataSourceVariable == x.value.idVariable);
      arrayVariablesAux.push({ type: listItem[item].idVariableType, index: item });
    });
    this.arrayVariables = [...arrayVariablesAux];
  }

  changeType(value: number) {
    this.filteredList = [];
    this.filteredList = this.lookupResponse.listDataSourceVariable?.filter(l => l.idDataSourceType == value) ?? [];
  }

  validateExpression() {
    let isExpressionsInvalid: boolean = false;
    this.model.get('expressions').value.forEach((x, i) => {
      let teste = true;
      if (i == 0) {
        teste = x.idDataSourceVariable || x.value || x.idLogicalExpression;
      } else {
        teste = x.idConjunction || x.idDataSourceVariable || x.value || x.idLogicalExpression;
      }
      if (x.idVariableType == VariablesDataSourceEnum.age && !this.validaAge(x.value)) {
        teste = false;
      }
      if (x.idVariableType == VariablesDataSourceEnum.time && !this.validateTime(x.value)) {
        teste = false;
      }
      if (!teste) {
        isExpressionsInvalid = true;
        return;
      }
    });
    return isExpressionsInvalid;
  }

  validaAge(value) {
    if (!value) {
      return;
    }
    let splitYears = value.split("a");
    let splitMonth = splitYears[1].split("m");
    let days: string = splitMonth[1].replace("d");
    let month: string = splitMonth[0];
    let years: string = splitYears[0];
    if (years.includes("_") || days.includes("_") || month.includes("_")) {
      this.alertService.show('Erro', "O padrão do campo idade é 010anos 01meses 02dias.", AlertType.error);
      return false;
    }
    if (parseInt(days) > 29) {
      this.alertService.show('Erro', "O valor máximo de dias do campo idade é 29, após deve ser adicionado no mês!", AlertType.error);
      return false;
    }
    if (parseInt(month) > 11) {
      this.alertService.show('Erro', "O valor máximo de meses do campo idade é 11, após deve ser adicionado no ano!", AlertType.error);
      return false;
    }
    return true;
  }

  validateTime(fullTime: string): boolean {
    if (!fullTime || fullTime.replace("_", "") == "::") {
      return true;
    }
    let time = fullTime.split(':');

    time.forEach(element => {
      if (element.includes('_'))
        this.alertService.show('Erro', "O padrão do campo horas é 01:01:01.", AlertType.error);
    });

    if (parseInt(time[0]) > 23) {
      this.alertService.show('Erro', "Valor máximo de horas é 23.", AlertType.error);
      return false;
    }

    if (parseInt(time[1]) > 59) {
      this.alertService.show('Erro', "Valor máximo de minutos é 59.", AlertType.error);
      return false;
    }

    if (parseInt(time[2]) > 59) {
      this.alertService.show('Erro', "Valor máximo de segundos é 59.", AlertType.error);
      return false;
    }

    return true;
  }

  showVariableResult() {
    if (this.isLoading) {
      return;
    }

    if (!this.model.get('idCutOffDate').value || !this.model.get('idDataSourceType').value) {
      this.alertService.show('Erro', "É necessário preencher a data de corte e tipo de fonte de dados para visualizar os resultados.", AlertType.error);
      return;
    }

    this.isLoading = true;
    this.variableResultList = [];
    this.completeTableData = new CompleteTableData();
    this.completeTableData.listTableResult = [];

    let variableRequest: DataIntVariableRequest = new DataIntVariableRequest();

    variableRequest.idCutOffDate = this.model.get('idCutOffDate').value;
    variableRequest.idDataSourceType = this.model.get('idDataSourceType').value;
    variableRequest.expressions = [];

    if (this.model.get('expressions').value) {
      for (let expression of this.model.get('expressions').value) {
        let expressionStruct: ExpressionStruct = new ExpressionStruct();
        expressionStruct.idConjunction = expression.idConjunction ? parseInt(expression.idConjunction) : null;
        expressionStruct.idLogicalExpression = expression.idLogicalExpression;
        expressionStruct.idDataSourceVariable = expression.idVariable;
        expressionStruct.value = expression.value;
        expressionStruct.variableType = this.filteredList.find(c => c.idDataSourceVariable == expression.idVariable).idVariableType;
        variableRequest.expressions.push(expressionStruct);
      }
    }


    if (this.model.get('idDataSourceType').value == DataSourceTypeEnum.bed) {
      this.getBedVariables(variableRequest);
    }

    else if (this.model.get('idDataSourceType').value == DataSourceTypeEnum.bedWithPatients) {
      this.getBedWithPatientVariables(variableRequest);
    }

    else if (this.model.get('idDataSourceType').value == DataSourceTypeEnum.episode) {
      this.getEpisodeVariables(variableRequest);
    }

    else if (this.model.get('idDataSourceType').value == DataSourceTypeEnum.patient) {
      this.getPatientVariables(variableRequest);
    }

    else if (this.model.get('idDataSourceType').value == DataSourceTypeEnum.queue) {
      this.getQueueVariables(variableRequest);
    }

    else if (this.model.get('idDataSourceType').value == DataSourceTypeEnum.classification) {
      this.getTriageVariables(variableRequest);
    }
  }

  getBedVariables(variableRequest: DataIntVariableRequest) {
    this.bedDataIntService.getVariableResult(variableRequest).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.variableResultList = response.beds;
      this.isLoading = false;
      this.generateTableTada();
    },
      (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  getBedWithPatientVariables(variableRequest: DataIntVariableRequest) {
    this.bedWithPatientService.getVariableResult(variableRequest).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.variableResultList = response.listBedWithPatient;
      this.isLoading = false;
      this.generateTableTada();
    },
      (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  getEpisodeVariables(variableRequest: DataIntVariableRequest) {
    this.episodeDataIntService.getVariableResult(variableRequest).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.variableResultList = response.episodes;
      this.isLoading = false;
      this.generateTableTada();
    },
      (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  getPatientVariables(variableRequest: DataIntVariableRequest) {
    this.patientDataIntService.getVariableResult(variableRequest).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.variableResultList = response.patients;
      this.isLoading = false;
      this.generateTableTada();
    },
      (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  getQueueVariables(variableRequest: DataIntVariableRequest) {
    this.queueDataIntService.getVariableResult(variableRequest).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.variableResultList = response.queues;
      this.isLoading = false;
      this.generateTableTada();
    },
      (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  getTriageVariables(variableRequest: DataIntVariableRequest) {
    this.triageDataIntService.getVariableResult(variableRequest).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.variableResultList = response.triages;
      this.isLoading = false;
      this.generateTableTada();
    },
      (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  getVariablesByType() {
    this.dataSourceVariableService.getDataSourceVariable(this.model.get('idDataSourceType').value).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listDSVariable = response.listDataSourceVariable;
    },
      (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  generateTableTada() {
    if (!this.variableResultList || !this.listDSVariable) {
      return;
    }
    this.completeTableData.listTableResult = []
    //pegar na posição 0 as chaves

    var keys = Object.keys(this.variableResultList[0])
    this.variableResultList.forEach(x => {
      let listData: TableResult = new TableResult();
      listData.listTableCell = [];
      this.listDSVariable.forEach(c => {
        let key = keys.find(p => p == c.fieldName)
        if (key) {
          let tableCell: TableCell = new TableCell();
          tableCell.columnName = c.dataSourceVariableName;
          tableCell.fieldName = c.fieldName;
          tableCell.rowValue = x[key];

          listData.listTableCell.push(tableCell);
        }
      })
      this.completeTableData.listTableResult.push(listData);
    });


  }

  clearVariableResult() {
    this.completeTableData = null;
    if (this.model.get('idCutOffDate').value > 1){
      this.isLongTime = true;
    }
    else{
      this.isLongTime = false;
    }
  }
}
