import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-config-totem-modal',
  templateUrl: './config-totem-modal.component.html',
  styleUrls: ['./config-totem-modal.component.css']
})
export class ConfigTotemModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<ConfigTotemModalComponent>,
  private alertService: AlertService,) { }

  public isLoading:boolean;  

  ngOnInit(): void {
    this.isLoading = false;
  }
  
  close(){
    this.matDialogRef.close({deleteTotem: false}); 
  }
  
  clickCancel(){
    this.matDialogRef.close({deleteTotem: false}); 
  }
}

