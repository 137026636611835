import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { MedicalRecordService } from 'src/app/shared/services/API/orchestrator-patient/medical-record.service';
import { TriageStruct } from 'src/app/shared/services/structs/orchestrator-patient/triage.struct';
import { ExamStruct } from 'src/app/shared/services/structs/SADT/exam.struct';
import { EpisodeStatusEnum } from 'src/app/shared/enum/episode-status.enum';
import { FowardService } from 'src/app/shared/services/API/orchestrator-queue/foward.service';
import { environment } from 'src/environments/environment';
import { CancelConfirmModalComponent } from './cancel-confirm-modal/cancel-confirm-modal.component';
import { AllergyModel } from 'src/app/shared/services/models/medical-record/allergy.model';
import { ClockService } from 'src/app/shared/services/API/orchestrator-queue/clock.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { FowardDestinationEnum } from 'src/app/shared/enum/foward-destination.enum';
import { NursingConsultationStruct } from 'src/app/shared/services/structs/medical-record/nursing-consultation.struct';
import { EpisodeStruct } from 'src/app/shared/services/structs/orchestrator-patient/episode.struct';
import { PresenceReportService } from 'src/app/shared/services/API/orchestrator-patient/presence-report.service';
import { OrganizationService } from 'src/app/shared/services/API/flow/organization.service';
import { RoomStruct } from 'src/app/shared/services/structs/flow/room.struct';
import { SectorStruct } from 'src/app/shared/services/structs/flow/sector.struct';
import { ServiceStruct } from 'src/app/shared/services/structs/flow/service.struct';
import { GetCompleteMultiProfessionalConfigStruct } from 'src/app/shared/services/structs/multi-professional/get-complete-multi-professional-config.struct';
import { SectionFillEnum } from 'src/app/shared/enum/multi-professional/section-fill.enum';
import { MultiProfessionalCareRequest } from 'src/app/shared/services/requests/multi-professional/multi-professional-care.request';
import { ExternalPrescriptionStruct } from 'src/app/shared/services/structs/medical-record/external-prescription.struct';
import { MultiProfessionalCareService } from 'src/app/shared/services/API/orchestrator-patient/multi-professional-care.service';
import { ProceduresPerformedBlockComponent } from './procedures-performed-block/procedures-performed-block.component';
import { BillingDataRequest } from 'src/app/shared/services/requests/billing/billing-data-request';
import { BillingDataService } from 'src/app/shared/services/API/billing/billing-data.service';
import { SelectedLocationRequest } from 'src/app/shared/services/requests/orchestrator-queue/selected-location.request';
import { MultiProfessionalCareModel } from 'src/app/shared/services/models/multi-professional/multi-professional-care.model';
import { AllergyStruct } from 'src/app/shared/services/structs/medical-record/allergy.struct';
import { UserGetNameService } from 'src/app/shared/services/API/user/user-get-name.service';
import { EpisodeCareLineStruct } from 'src/app/shared/services/structs/care-line/episode-care-line.struct';
import { CareLineModel } from 'src/app/shared/services/models/care-line/care-line.model';
import { EpisodeCareLineRequest } from 'src/app/shared/services/requests/care-line/episode-care-line.request';
import { CareLinePriorityStatusEnum } from 'src/app/shared/enum/care-line-priority/care-line-priority-status.enum';
import { MetaDataKeyValueStruct } from 'src/app/shared/services/structs/meta-data/meta-data-key-value.struct';
import { MetaDataTypeEnum } from 'src/app/shared/enum/meta-data/meta-data-type.enum';
import { MetaDataStruct } from 'src/app/shared/services/structs/meta-data/meta-data.struct';
import { MetaDataService } from 'src/app/shared/services/API/meta-data/meta-data.service';
import { SadtGroupStruct } from 'src/app/shared/services/structs/multi-professional/sadt-group.struct';
import { ReadMultiProfessionalPrescriptionStruct } from 'src/app/shared/services/structs/orchestrator-patient/read-multi-professional-prescription.struct';
import { WebsocketAttendanceUtilService } from 'src/app/shared/services/websocket-util/websocket-attendance-util.service';
import { UpdateStatusQueueService } from 'src/app/shared/services/API/orchestrator-queue/update-status-queue.service';
import { MultiProfessionalCareCompleteStruct } from 'src/app/shared/services/structs/multi-professional/multi-professional-care-complete.struct';
import { TissGuideManagementFloatingWindowComponent } from 'src/app/shared/components/tiss-guides/tiss-guide-management-floating-window/tiss-guide-management-floating-window.component';
import { AllPrivateBillingGuideReportStruct } from 'src/app/shared/services/structs/orchestrator-patient/all-private-billing-guide-report.struct';
import { UserLoginResponse } from 'src/app/shared/services/responses/user/auth.response';
import { UserRoleEnum } from 'src/app/shared/enum/user-role.enum';
import { UtilService } from 'src/app/shared/services/util.service';
import { SignatureCredentialsModalComponent } from 'src/app/shared/components/signature-credentials-modal/signature-credentials-modal.component';
import { ReportResponse } from 'src/app/shared/services/responses/orchestrator-patient/report.response';
import { ReportViewSelectModalComponent } from 'src/app/shared/components/report-view-select-modal/report-view-select-modal.component';
import { MultiProfessionalConfigMedicalServiceService } from 'src/app/shared/services/API/multi-professional/multi-professional-config-medical-service.service';
import { AttendanceOccupiedRemoveModalComponent } from 'src/app/shared/components/attendance-occupied-remove-modal/attendance-occupied-remove-modal.component';

@Component({
  selector: 'app-attend-patient',
  templateUrl: './attend-patient.component.html',
  styleUrls: ['./attend-patient.component.css']
})
export class AttendPatientComponent implements OnInit, OnDestroy {

  @ViewChild('procedureSigtap') procedureSigtap: ProceduresPerformedBlockComponent;
  @ViewChild(TissGuideManagementFloatingWindowComponent) childComponent: TissGuideManagementFloatingWindowComponent;

  constructor(private activatedRoute: ActivatedRoute,
    public changeDetector: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private dialog: MatDialog,
    public medicalRecordService: MedicalRecordService,
    public multiProfessionalCareService: MultiProfessionalCareService,
    public router: Router,
    public fowardService: FowardService,
    public organizationService: OrganizationService,
    private clockService: ClockService,
    private authService: AuthService,
    private billingDataService: BillingDataService,
    private userGetNameService: UserGetNameService,
    private metaDataService: MetaDataService,
    public websocketAttendanceUtilService: WebsocketAttendanceUtilService,
    public updateStatusQueueService: UpdateStatusQueueService,
    private utilsService: UtilService,
    public presenceReportService: PresenceReportService,
    public multiProfessionalConfigMedicalServiceService: MultiProfessionalConfigMedicalServiceService
  ) { this.getOrganization(); this.getAllMetaData(); }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.multi_professional;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.multi_professional_patient_list;

  public isLoading: boolean = true;
  public isFirstLoading: boolean = true;
  public wasSaved: boolean = false;
  public readonly: boolean = false;
  public hasMultiprofessionalConfig: boolean = false;
  public hasNewAttendance: boolean = false;
  public hasMetaData: boolean = false;
  public hasNonStandardPrescriptions: boolean = false;
  public hasStandardPrescriptions: boolean = false;
  public hasExamesRequests: boolean = false;
  public hasSickNote: boolean = false;
  public hasProceduresPerformed: boolean = false;
  public hasAssistanceOutcome: boolean = false;
  public hasCareLine: boolean = false;
  public allergyError: boolean = false;
  public dischargeOutcome: boolean = true;
  public hideDataPatientHistory: boolean = false;
  public userLogin: string = this.authService.getTokenMenu().login;
  public userName: string = this.authService.getTokenMenu().userName;
  public isAttendanceRemoval: boolean = false;

  private showTissGuideButton: boolean = false;
  public showTissGuideButtonValidation: boolean = false;

  public idMedicalCare: number;
  public idMultiprofessional: number;
  public idService: number;
  public selectedEpisode: number = parseInt(this.activatedRoute.snapshot.paramMap.get('idEpisode'));;
  public idSector: number = parseInt(this.activatedRoute.snapshot.paramMap.get('idSector'));
  public idQueue: number = parseInt(this.activatedRoute.snapshot.paramMap.get('idQueue'));
  public idRoom: number = parseInt(this.activatedRoute.snapshot.paramMap.get('idRoom'));
  public idCompetence: number = this.authService.getTokenMenu().healthUnit.idProcedureSigtapCompetence;
  public destinationTypeAltaEncaminhamento: number = FowardDestinationEnum.Alta_Encaminhamento;
  public destinationTypeEncaminhamento: number = FowardDestinationEnum.Encaminhamento;
  public destinationTypeAlta: number = FowardDestinationEnum.Alta;
  public episodeStatusAltaEnum: EpisodeStatusEnum = EpisodeStatusEnum.FinalizadoAlta;

  public codCbo: string = this.authService.getTokenMenu().codCbo;
  public stringValidator: string = "";
  public urlListProcedure: string;

  public model: FormGroup;

  public datetimeStartAttendance: Date;
  public datetimeInclusionDischarge: Date;

  public completeMultiprofessionalConfig: GetCompleteMultiProfessionalConfigStruct;
  public newMultiProfessionalCare: MultiProfessionalCareModel;
  public allPrivateBillingGuideReportStruct: AllPrivateBillingGuideReportStruct;
  public episodeStruct: EpisodeStruct;
  public triage: TriageStruct = null;
  public listRoom: RoomStruct[];
  public listSector: SectorStruct[];
  public listService: ServiceStruct[];
  public listMetaData: MetaDataStruct[];
  public listMetaDataStruct: MetaDataStruct[] = [];
  public multiProfessionalCare: MultiProfessionalCareCompleteStruct[] = [];
  public listProcedureSigtap: any[] = [];
  public listSADT: SadtGroupStruct[] = [];
  public listLookupExam: ExamStruct[];
  public listCid: any[] = [];
  public allergiesInput: AllergyModel[] = [];
  public listNursingConsultation: NursingConsultationStruct[];
  public listExternalPrescription: ExternalPrescriptionStruct[] = [];
  public listInternalPrescription: ReadMultiProfessionalPrescriptionStruct[] = [];

  //Linhas de Cuidado
  public listPastEpisodeCareLine: EpisodeCareLineStruct[] = [];
  public listCurrentEpisodeCareLine: EpisodeCareLineStruct[] = [];
  public listNewEpisodeCareLine: EpisodeCareLineStruct[] = [];
  public listCareLine: CareLineModel[] = [];
  public patientPhoneNumber: string = "";

  //Assinatura digital
  public sign: boolean;
  public requireSignature: boolean = this.checkSigningResponsibility();

  ngOnInit(): void {
    if (this.selectedEpisode) {
      this.isFirstLoading = true;
      this.populateAttendPatient();
    }
    else {
      this.getDate();
      this.GetMultiProfessionalConfigMedicalService();
    }

    if (this.activatedRoute.snapshot.paramMap.get('readonly'))
      this.readonly = this.activatedRoute.snapshot.paramMap.get('readonly') === "true";

    if (Number.isInteger(this.idQueue) && this.idQueue && this.idQueue > 0 && Number.isInteger(this.selectedEpisode) && this.selectedEpisode && this.selectedEpisode > 0)
      this.startWebsocket();

    if (parseInt(this.activatedRoute.snapshot.paramMap.get('idService')))
      this.idService = parseInt(this.activatedRoute.snapshot.paramMap.get('idService'));

    if (this.activatedRoute.snapshot.paramMap.get('showTissGuideButton'))
      this.showTissGuideButton = this.activatedRoute.snapshot.paramMap.get('showTissGuideButton') == "true" ? true : false;

    this.showTissGuideButtonValidation = this.showTissGuideButton;

    if (this.codCbo && this.idCompetence) {
      let url = `ProcedureSigtapCBO/codCbo/${this.codCbo}/idCompetence/${this.idCompetence}`
      this.urlListProcedure = environment.urlApiBilling + url;
    }

    this.model = this.formBuilder.group({
      evolution: [{ value: '', disabled: this.wasSaved }],
      medicalNotes: [{ value: '', disabled: this.wasSaved }],
      physicalExamination: [{ value: '', disabled: this.wasSaved }],
      therapeuticPlan: [{ value: '', disabled: this.wasSaved }],
      diagnosedHypothesis: [{ value: '', disabled: this.wasSaved }],
      temperature: [{ value: '', disabled: this.wasSaved }],
      respiratoryFrequency: [{ value: '', disabled: this.wasSaved }],
      heartRate: [{ value: '', disabled: this.wasSaved }],
      glucose: [{ value: '', disabled: this.wasSaved }],
      bloodPressure: [{ value: '', disabled: this.wasSaved }],
      saturation: [{ value: '', disabled: this.wasSaved }],
      conclusionNote: [{ value: '', disabled: this.wasSaved }],
      idFlow: [{ value: '', disabled: this.wasSaved }],
      conclusionType: [{ value: this.destinationTypeAlta.toString(), disabled: this.wasSaved }],
      sickDaysOffAmount: [0, [Validators.min(0)]],
      isCidOnSickNote: [{ value: false, disabled: true }, [Validators.required]],
      firstChildGroup: this.formBuilder.group({
        listIdAllergies: [this.allergiesInput.map(x => x.idAllergy)],
        cid: [this.listCid.map(a => a.idCid)],
      }),
      flowSequence: this.formBuilder.array([]),
      idCareLinePriority: [],
    });
  }

  ngAfterContentChecked() {
    this.changeDetector.detectChanges();
  }

  startWebsocket() {
    if (!this.websocketAttendanceUtilService.isConnect())
      this.websocketAttendanceUtilService.connectwebsocketRoom(this.idRoom, this.idQueue, this.selectedEpisode, this, false, false, this.authService.getToken(), this.userLogin, this.userName);

    this.websocketAttendanceUtilService.setContext(this);
    this.websocketAttendanceUtilService.setFunctions(null, null, this.onRemoveAttendant);
  }

  GetMultiProfessionalConfigMedicalService() {
    this.multiProfessionalConfigMedicalServiceService.GetMultiProfessionalConfigMedicalService(this.listMetaData, this.idSector).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException('Erro', response.errorDescription);
          return;
        }

        if (response && response.completeMultiProfessionalConfigStruct) {
          this.completeMultiprofessionalConfig = response.completeMultiProfessionalConfigStruct;

          this.dischargeOutcome = this.completeMultiprofessionalConfig.dischargeOutcome;
          this.hideDataPatientHistory = this.completeMultiprofessionalConfig.hideDataPatientHistory;
          this.datetimeInclusionDischarge = this.completeMultiprofessionalConfig.datetimeInclusion;
          if (this.datetimeInclusionDischarge != null && this.episodeStruct?.datetimeInclusion) {
            const dischargeComparsion = new Date(this.datetimeInclusionDischarge);
            const episodeDateComparsion = new Date(this.episodeStruct.datetimeInclusion);
            if (episodeDateComparsion.getTime() < dischargeComparsion.getTime()) {
              this.dischargeOutcome = !this.dischargeOutcome;
              this.completeMultiprofessionalConfig.dischargeOutcome = !this.completeMultiprofessionalConfig.dischargeOutcome;
            }
          }

          this.hasMultiprofessionalConfig = true;
          this.VerifyVisibleFill();
        }
        else {
          this.alertService.show('Aviso', 'Configurações não encontradas, verifique se há configurações cadastradas para este setor', AlertType.warning);

          this.endAllLoading();
        }
      },
      error: (error) => {
        this.throwException('Erro inesperado', error);
      }
    });
  }

  getAllMetaData() {
    this.metaDataService.getAllMetaData().subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException('Erro', response.errorDescription);
          return;
        }

        this.listMetaData = response.listMetaDataStruct;
      },
      error: (error) => {
        this.throwException('Erro inesperado', error);
      }
    });
  }

  VerifyVisibleFill() {
    this.hasNewAttendance = this.completeMultiprofessionalConfig.listIdSectionFill.includes(SectionFillEnum.NewAttendance);
    this.hasMetaData = (this.completeMultiprofessionalConfig.listMetaData.length > 0) ? true : false;
    this.hasNonStandardPrescriptions = this.completeMultiprofessionalConfig.listIdSectionFill.includes(SectionFillEnum.NonStandardPrescriptions);
    this.hasStandardPrescriptions = this.completeMultiprofessionalConfig.listIdSectionFill.includes(SectionFillEnum.StandartPrescriptions);
    this.hasExamesRequests = this.completeMultiprofessionalConfig.listIdSectionFill.includes(SectionFillEnum.ExamesRequests);
    this.hasSickNote = this.completeMultiprofessionalConfig.listIdSectionFill.includes(SectionFillEnum.SickNote);
    this.hasProceduresPerformed = this.completeMultiprofessionalConfig.listIdSectionFill.includes(SectionFillEnum.ProceduresPerformed);
    this.hasAssistanceOutcome = this.completeMultiprofessionalConfig.listIdSectionFill.includes(SectionFillEnum.AssistanceOutcome);
    this.hasCareLine = this.completeMultiprofessionalConfig.listIdSectionFill.includes(SectionFillEnum.CareLine);

    this.endAllLoading();
  }

  populateAttendPatient() {
    this.medicalRecordService.getMedicalRecord(this.selectedEpisode).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException('Erro', response.errorDescription);
          return;
        }

        this.idMedicalCare = response.medicalCareHistorie[0]?.medicalCare?.idMedicalCare;
        this.datetimeStartAttendance = response.datetimeStartAttendance;
        this.triage = response.triage;
        this.episodeStruct = response.episode;
        this.allergiesInput = response.listAllergy;
        this.listNursingConsultation = response.nursingConsultationHistory;

        this.listPastEpisodeCareLine = response.listPastEpisodeCareLine;
        this.listCurrentEpisodeCareLine = response.listCurrentEpisodeCareLine
        this.listCareLine = response.listCareLine;
        this.multiProfessionalCare = response.multiProfessionalCareHistorie;

        let listIdUser: number[] = this.allergiesInput.filter(x => x.isDeleted && x.idUser != null).map(x => x.idUser);

        if (listIdUser && listIdUser.length > 0)
          this.getUserNames(listIdUser);

        this.GetMultiProfessionalConfigMedicalService();
      },
      error: (error) => {
        this.throwException('Erro inesperado', error);
      }
    });
  }

  getUserNames(listIdUser: number[]) {
    this.userGetNameService.listUser(listIdUser).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException('Erro', response.errorDescription);
          return;
        }

        this.allergiesInput.forEach(allergy => {
          if (allergy.isDeleted && allergy.idUser != null)
            allergy.userName = response.listUser.find(x => x.idUser == allergy.idUser).userName;
        });
      },
      error: (error) => {
        this.throwException('Erro inesperado', error);
      }
    });
  }

  populateCareLines(event: any) {
    this.listNewEpisodeCareLine = this.listNewEpisodeCareLine.concat(event.newEpisodeCareLines);
    this.listCurrentEpisodeCareLine = this.listCurrentEpisodeCareLine.concat(event.newEpisodeCareLines);
    this.patientPhoneNumber = event.patientPhoneNumber;
  }

  removeCareLine(index: number) {
    this.listCurrentEpisodeCareLine.splice(index, 1);
  }

  submit(sign: boolean) {
    if (this.isLoading)
      return;

    this.sign = sign;

    this.startLoading();

    if (!this.model.get('idFlow').valid) {
      this.alertService.show('Erro', "Selecione o encaminhamento", AlertType.error);
      this.endAllLoading();
      return;
    }

    if (!this.model.valid) {
      this.model.markAllAsTouched();
      this.model.get('firstChildGroup').get('listIdAllergies').markAsTouched();
      this.allergyError = this.model.get('firstChildGroup').get('listIdAllergies').invalid && this.model.get('firstChildGroup').get('listIdAllergies').touched;
      this.alertService.show('Erro', "Preencha todos em vermelho" + this.stringValidator + "!", AlertType.error);
      this.endAllLoading();
      return;
    }

    let multiProfessionalCareRequest = this.mapToRequest();
    if (multiProfessionalCareRequest.saturation < 0 || multiProfessionalCareRequest.saturation > 100) {
      this.alertService.show('Erro', "O valor da saturação deve ser de 0 a 100", AlertType.error);
      this.endAllLoading();
      return;
    }

    if (multiProfessionalCareRequest.bloodPressureDiastole && !multiProfessionalCareRequest.bloodPressureSystole ||
      !multiProfessionalCareRequest.bloodPressureDiastole && multiProfessionalCareRequest.bloodPressureSystole) {
      this.alertService.show('Erro', "Favor preencher o campo de pressão completamente", AlertType.error);
      this.endAllLoading();
      return;
    }

    multiProfessionalCareRequest.metaData = [];

    if (this.listMetaData && this.listMetaData.length > 0) {
      for (let item of this.listMetaData) {
        const formControl = this.model.get(item.metaData.idMetaData.toString())

        if (formControl && formControl.value) {
          const metadataSend = new MetaDataKeyValueStruct();
          metadataSend.key = item.metaData.idMetaData;
          metadataSend.value = item.metaDataType.idMetaDataType === MetaDataTypeEnum.Numero ?
            formControl.value.toString() : formControl.value;
          multiProfessionalCareRequest.metaData.push(metadataSend);
        }
      }
    }

    //Request de linhas de cuidado
    let episodeCareLineRequest = new EpisodeCareLineRequest();
    episodeCareLineRequest.listIdCareLine = this.listNewEpisodeCareLine.map(x => x.idCareLine);
    episodeCareLineRequest.idEpisode = this.selectedEpisode;
    episodeCareLineRequest.phoneNumber = this.patientPhoneNumber;

    multiProfessionalCareRequest.episodeCareLineRequest = episodeCareLineRequest;
    multiProfessionalCareRequest.administrativeRelease = false;
    multiProfessionalCareRequest.idRoomAttendance = this.idRoom;
    multiProfessionalCareRequest.idQueueUsed = this.idQueue;

    if (this.sign)
      this.verifySign(multiProfessionalCareRequest);
    else
      this.makeCallPost(multiProfessionalCareRequest);
  }

  verifySign(multiProfessionalCareRequest: MultiProfessionalCareRequest) {
    const dialogRef = this.dialog.open(SignatureCredentialsModalComponent, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (!result) {
          this.endAllLoading();
          return;
        }

        if (result.sign) {
          multiProfessionalCareRequest.multiProfessionalCareReportStruct.idSignatureProvider = result.idSignatureProvider;
          multiProfessionalCareRequest.multiProfessionalCareReportStruct.signatureAuthentication = JSON.stringify(result.signatureAuthentication);
        }
        else {
          multiProfessionalCareRequest.multiProfessionalCareReportStruct.idSignatureProvider = null;
          multiProfessionalCareRequest.multiProfessionalCareReportStruct.signatureAuthentication = null;
        }

        this.makeCallPost(multiProfessionalCareRequest);
      },
      error: (error) => {
        this.throwException('Erro inesperado', error);
      }
    });
  }

  makeCallPost(multiProfessionalCareRequest: MultiProfessionalCareRequest) {
    this.multiProfessionalCareService.postMedicalCare(multiProfessionalCareRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException('Erro', response.errorDescription);
          return;
        }

        this.newMultiProfessionalCare = response.multiProfessionalCare;
        this.wasSaved = true;

        //Criar procedimentos aqui
        let billingDataRequest = this.mapToBillingDataRequest(this.newMultiProfessionalCare.idMultiProfessionalCare);
        this.postBillingData(billingDataRequest);

        if (response.errorCode == 11)
          this.alertService.show("Erro", response.errorDescription, AlertType.error);

        if (response.allPrivateBillingGuideReportStruct) {
          this.allPrivateBillingGuideReportStruct = new AllPrivateBillingGuideReportStruct();
          this.allPrivateBillingGuideReportStruct = response.allPrivateBillingGuideReportStruct;
        }

        if (this.hasReport(response.reportResponse))
          this.openReportViewSelectModal(response.reportResponse);
        else
          this.redirectAfterSaveAttendance();
      },
      error: (error) => {
        this.throwException('Erro inesperado', error);
      }
    });
  }

  openReportViewSelectModal(response: ReportResponse) {
    let reportDialog = this.dialog.open(ReportViewSelectModalComponent, {
      data: {
        medicalCareReport: response.multiProfessionalCareReport64,
        examInternalReport: response.sadtInternalReport64,
        examExternalReport: response.sadtExternalReport64,
        internalPrescriptionReport: response.internalPrescriptionReport64,
        externalPrescriptionReport: response.externalPrescriptionReport64,
        sickNoteReport: response.sickNoteReport64,
        allReportsConcat: response.allReportsConcat64,
        sign: this.sign,
        appointmentGuideReport: this.allPrivateBillingGuideReportStruct?.appointmentGuideReport64,
        admissionGuideReport: this.allPrivateBillingGuideReportStruct?.admissionGuideReport64,
        feesGuideReport: this.allPrivateBillingGuideReportStruct?.feesGuideReport64,
        spSadtGuideReport: this.allPrivateBillingGuideReportStruct?.spSadtGuideReport64,
      },
    });

    reportDialog.afterClosed().subscribe({
      next: () => {
        this.redirectAfterSaveAttendance();
      },
      error: (error) => {
        this.throwException('Erro inesperado', error);
        this.redirectAfterSaveAttendance();
      }
    });
  }

  redirectAfterSaveAttendance() {
    if (this.model.get('conclusionType').value == this.destinationTypeEncaminhamento || this.model.get('conclusionType').value == this.destinationTypeAltaEncaminhamento)
      this.forwardPatient();
    else
      this.router.navigate(['/multi-professional/multi-professional-patient-list', { idSector: this.idSector, idRoom: this.idRoom }]);
  }

  hasReport(response: ReportResponse) {
    return Boolean(
      response.multiProfessionalCareReport64 ||
      response.sadtInternalReport64 ||
      response.sadtExternalReport64 ||
      response.externalPrescriptionReport64 ||
      response.sickNoteReport64 ||
      response.internalPrescriptionReport64 ||
      response.allReportsConcat64 ||
      this.allPrivateBillingGuideReportStruct?.appointmentGuideReport64 ||
      this.allPrivateBillingGuideReportStruct?.admissionGuideReport64 ||
      this.allPrivateBillingGuideReportStruct?.feesGuideReport64 ||
      this.allPrivateBillingGuideReportStruct?.spSadtGuideReport64
    );
  }

  mapToRequest(): MultiProfessionalCareRequest {
    let multiProfessionalCareRequest: MultiProfessionalCareRequest = new MultiProfessionalCareRequest();
    multiProfessionalCareRequest.listExternalPrescription = this.listExternalPrescription;
    multiProfessionalCareRequest.listInternalPrescription = this.listInternalPrescription;
    multiProfessionalCareRequest.multiProfessionalCareSadt = this.listSADT;
    multiProfessionalCareRequest.conclusionType = this.model.get('conclusionType').value;
    multiProfessionalCareRequest.idEpisode = this.selectedEpisode;
    multiProfessionalCareRequest.medicalNotes = this.model.get('medicalNotes').value;
    multiProfessionalCareRequest.physicalExamination = this.model.get('physicalExamination').value;
    multiProfessionalCareRequest.therapeuticPlan = this.model.get('therapeuticPlan').value;
    multiProfessionalCareRequest.diagnosedHypothesis = this.model.get('diagnosedHypothesis').value;
    multiProfessionalCareRequest.hideDataPatientHistory = this.hideDataPatientHistory;
    let temperatura = this.model.get('temperature').value ? this.model.get('temperature').value.toString().replace(".", "") : null;
    temperatura = temperatura ? parseFloat(temperatura.split("")[0] + temperatura.split("")[1] + "." + temperatura.split("")[2]) : null;
    multiProfessionalCareRequest.temperature = temperatura;
    multiProfessionalCareRequest.respiratoryFrequency = this.model.get('respiratoryFrequency').value ? parseInt(this.model.get('respiratoryFrequency').value) : null;
    multiProfessionalCareRequest.heartRate = this.model.get('heartRate').value ? parseInt(this.model.get('heartRate').value) : null;
    multiProfessionalCareRequest.glucose = this.model.get('glucose').value ? parseInt(this.model.get('glucose').value) : null;
    multiProfessionalCareRequest.idMedicalCare = this.idMedicalCare;

    if (this.model.get('idCareLinePriority').value) {
      multiProfessionalCareRequest.careLinePriorityStatusStruct.idCareLinePriority = parseInt(this.model.get('idCareLinePriority').value);
      multiProfessionalCareRequest.careLinePriorityStatusStruct.idCareLinePriorityStatus = CareLinePriorityStatusEnum.suspeita;
    }

    if (this.model.get("bloodPressure").value) {
      let pa: string = this.model.get("bloodPressure").value.split("/")[0].split("");
      if (pa.length < 4) {
        this.alertService.show('Erro', "Preencha o valor da pressão arterial corretamente", AlertType.error);
        return;
      }

      multiProfessionalCareRequest.bloodPressureSystole = pa.length == 4 ? parseInt(pa[0] + pa[1]) : parseInt(pa[0] + pa[1] + pa[2]);
      multiProfessionalCareRequest.bloodPressureDiastole = pa.length == 4 ? parseInt(pa[2] + pa[3]) : pa.length == 5 ? parseInt(pa[3] + pa[4]) : parseInt(pa[3] + pa[4] + pa[5]);
    }

    multiProfessionalCareRequest.saturation = parseInt(this.model.get('saturation').value);
    multiProfessionalCareRequest.cid = this.listCid.map(a => a.idCid);
    multiProfessionalCareRequest.isCidOnSickNote = this.model.get('isCidOnSickNote').value;
    multiProfessionalCareRequest.listAllergy = this.allergiesInput.map(a => new AllergyStruct(a, this.selectedEpisode));
    multiProfessionalCareRequest.datetimeStartAttendance = this.datetimeStartAttendance;
    multiProfessionalCareRequest.sickDaysOffAmount = this.model.get('sickDaysOffAmount').value;
    multiProfessionalCareRequest.metaData = [];
    multiProfessionalCareRequest.conclusionNote = this.model.get('conclusionNote').value;
    multiProfessionalCareRequest.patientEvolution = this.model.get('evolution').value;
    multiProfessionalCareRequest.idSector = this.idSector;

    if (this.showTissGuideButtonValidation) {
      let guide = this.childComponent.getGuidRequest();
      if (guide)
        multiProfessionalCareRequest.guideRequest = guide;
    }

    return multiProfessionalCareRequest;
  }

  getDate() {
    this.clockService.getDate().subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException('Erro', response.errorDescription);
          return;
        }

        this.datetimeStartAttendance = response.date;
      },
      error: (error) => {
        this.throwException('Erro inesperado', error);
      }
    });
  }

  mapToBillingDataRequest(idRelation): BillingDataRequest {
    let billingDataRequest: BillingDataRequest = new BillingDataRequest();
    billingDataRequest.listCid = this.listCid.map(x => x.code);
    billingDataRequest.idRelation = idRelation;
    billingDataRequest.idModule = MenuModuleEnum.multi_professional;
    billingDataRequest.idEpisode = this.selectedEpisode;
    billingDataRequest.listIdProcedure = this.listProcedureSigtap.map(a => a.idProcedureSigtap);
    return billingDataRequest;
  }

  postBillingData(billingDataRequest: BillingDataRequest) {
    if (this.codCbo) {
      this.billingDataService.postBillingData(billingDataRequest).subscribe({
        next: (response) => {
          if (response.isError) {
            this.throwException('Erro', response.errorDescription);
            return;
          }
        },
        error: (error) => {
          this.throwException('Erro inesperado', error);
        }
      });
    }
  }

  forwardPatient() {
    this.startLoading();
    let selectedLocationRequest = new SelectedLocationRequest();
    selectedLocationRequest.idEpisode = this.selectedEpisode;
    selectedLocationRequest.idFlow = this.model.get('idFlow').value;
    selectedLocationRequest.idRoomOrigin = this.idRoom;
    selectedLocationRequest.idQueue = this.idQueue;

    this.fowardService.fowardPatient(selectedLocationRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException('Erro', response.errorDescription);
          return;
        }

        this.endAllLoading();
        this.router.navigate(['/multi-professional/multi-professional-patient-list', { idSector: this.idSector, idRoom: this.idRoom }])
      },
      error: (error) => {
        this.throwException('Erro inesperado', error);
      }
    });
  }

  openCancelConfirmModal() {
    let guideWasUpdate = false;
    if (this.showTissGuideButtonValidation) {
      let guideRequest = this.childComponent.getGuidRequest();
      if ((guideRequest.admissionGuideStruct ||
        guideRequest.appointmentGuideStruct ||
        guideRequest.feesGuideStruct ||
        guideRequest.spSadtGuideStruct)) {
        guideWasUpdate = true;
      }
    }

    this.dialog.open(CancelConfirmModalComponent, {
      data: {
        idRoom: this.idRoom,
        idSector: this.idSector,
        guideWasUpdate
      },
    });
  }

  ngOnDestroy() {
    if (this.websocketAttendanceUtilService.isConnect())
      this.websocketAttendanceUtilService.emitFinalizeAttendance(this.idRoom);

    if (this.wasSaved == false && !this.isAttendanceRemoval) {
      this.updateStatusQueue(this.idQueue, this.selectedEpisode);
    }
  }

  updateStatusQueue(idQueue: number, idEpisode: number) {
    this.startLoading();
    this.updateStatusQueueService.updateQueueToOldStatus(idQueue, idEpisode).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException('Erro', response.errorDescription);
          return;
        }

        this.endAllLoading();
      },
      error: (error) => {
        this.throwException('Erro inesperado', error);
      }
    });
  }

  getOrganization() {
    this.organizationService.GetOrganization().subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException('Erro', response.errorDescription);
          return;
        }

        this.listRoom = response.listRoom;
        this.listSector = response.listSector;
        this.listService = response.listService;
      },
      error: (error) => {
        this.throwException('Erro inesperado', error);
      }
    });
  }

  triggerSetPreselect(event) {
    if (this.procedureSigtap)
      this.procedureSigtap.setPreselectTrigger(event);
  }

  triggerRemovePreselect(event) {
    if (this.procedureSigtap)
      this.procedureSigtap.removePreselectTrigger(event);
  }

  changeAllergies(event) {
    this.allergiesInput = event;
    this.model.get("firstChildGroup").get("listIdAllergies").setValue(this.allergiesInput.map(x => x.idAllergy));
  }

  changeCid(event) {
    this.listCid = event;
    if (!this.listCid || this.listCid.length == 0) {
      this.model.get('isCidOnSickNote').setValue(false);
      this.model.get('isCidOnSickNote').disable();
    }
    else
      this.model.get('isCidOnSickNote').enable();
  }

  changeSADTGroup(event) {
    this.listSADT = event;
  }

  changeExternalPrescription(event) {
    this.listExternalPrescription = event;
  }

  changeStandartPrescription(event) {
    this.listInternalPrescription = event;
  }

  checkSigningResponsibility(): boolean {
    let token: UserLoginResponse = this.utilsService.getToken();
    return (token.listRole.map(x => x.idRole).includes(UserRoleEnum.requerer_assinatura_multiprofissional) && !token.isMaster);
  }

  throwException(alertTypeDescription: string, error: string) {
    this.alertService.show(alertTypeDescription, error, AlertType.error);
    console.log(error);

    this.endAllLoading();
  }

  endAllLoading() {
    this.isLoading = false;
    this.isFirstLoading = false;
  }

  startLoading() {
    this.isLoading = true;
  }

  onRemoveAttendant(thisParam, res: any) {
    thisParam.isAttendanceRemoval = false;

    if (res.userLogin != thisParam.userLogin) {
      thisParam.isAttendanceRemoval = true;

      thisParam.websocketAttendanceUtilService.clearSocket();
      let ref = thisParam.dialog.open(AttendanceOccupiedRemoveModalComponent, { data: { ...res } });
      ref.afterClosed().subscribe(() => {
        thisParam.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          thisParam.router.navigate(['/multi-professional/multi-professional-patient-list', { idSector: thisParam.idSector, idRoom: thisParam.idRoom }]));
      });
    }
  }
}