import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { UbsConfigRequest } from '../../requests/ubs-health-promotion/ubs-config.request';
import { UBSScreenConfigResponse } from '../../responses/ubs-health-promotion/ubs-screen-config.response';
import { UbsScreenResponse } from '../../responses/ubs-health-promotion/ubs-screens.response';


@Injectable({
  providedIn: 'root'
})
export class ScreenLookupService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getScreens(): Observable<UbsScreenResponse>{

    let uri = `ScreenLookup/getAll`;

    return this.httpClient.get<UbsScreenResponse>(environment.urlApiUbsHealthPromotion + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }  
}