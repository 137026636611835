import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-hygienization-totem-delete-modal',
  templateUrl: './hygienization-totem-delete-modal.component.html',
  styleUrls: ['./hygienization-totem-delete-modal.component.css']
})
export class HygienizationTotemDeleteModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<HygienizationTotemDeleteModalComponent>,
    private alertService: AlertService,
    private formBuilder: UntypedFormBuilder) { }


  public isLoading: boolean;
  public model: UntypedFormGroup;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      confirm: [false],
    });
  }

  submit() {
    this.matDialogRef.close({ confirm: this.model.get('confirm').value });
  }
}
