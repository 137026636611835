import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { MedicFavoriteStandardPrescriptionService } from 'src/app/shared/services/API/medic/medic-favorite-standard-prescription.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MedicFavoriteStandardDeleteModalComponent } from './medic-favorite-standard-delete-modal/medic-favorite-standard-delete-modal.component';
import { MedicFavoriteStandardPrescriptionStruct } from 'src/app/shared/services/structs/medic/medic-favorite-standard-prescription.struct';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-medic-favorite-standard-prescription',
  templateUrl: './medic-favorite-standard-prescription.component.html',
  styleUrls: ['./medic-favorite-standard-prescription.component.css']
})
export class MedicFavoriteStandardPrescriptionComponent implements OnInit {

  constructor(private alertService: AlertService,
    public dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private medicFavoriteStandardPrescriptionService: MedicFavoriteStandardPrescriptionService
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.medic;
  public listMedicFavoriteStandardPrescriptionStruct: MedicFavoriteStandardPrescriptionStruct[];
  public isLoading: boolean;
  public idRoom: number;
  public idSector: number;

  ngOnInit(): void {
    this.isLoading = true;

    if (this.activatedRoute.snapshot.paramMap.get('idRoom'))
      this.idRoom = parseInt(this.activatedRoute.snapshot.paramMap.get('idRoom'));

    if (this.activatedRoute.snapshot.paramMap.get('idSector'))
      this.idSector = parseInt(this.activatedRoute.snapshot.paramMap.get('idSector'));

    this.populateCardList();
  }

  populateCardList() {
    this.medicFavoriteStandardPrescriptionService.listMedicFavoritStandardPrescription().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listMedicFavoriteStandardPrescriptionStruct = response.listMedicFavoriteStandardPrescriptionStruct;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openModal(idMedicFavoriteStandardPrescription: number) {
    const dialogRef = this.dialog.open(MedicFavoriteStandardDeleteModalComponent, {
      data: {
        idMedicFavoriteStandardPrescription: idMedicFavoriteStandardPrescription
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.isDeleted)
          this.ngOnInit();
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  clickBack() {
    this.router.navigate(['/medic/medic-patient-list', { idSector: this.idSector, idRoom: this.idRoom }]);
  }
}