import { Socket } from 'socket.io-client';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { WebsocketRemoteService } from '../websocket-remote.service';
import { WarnPatientDoctorEvadeComponent } from 'src/app/client/pages/remote-attendace/modals/warn-patient-doctor-evade/warn-patient-doctor-evade.component';
import { AlertService, AlertType } from '../alert.service';
import { RoomDuplicatedComponent } from 'src/app/client/pages/remote-attendace/modals/room-duplicated/room-duplicated.component';
import { RoomClosedComponent } from 'src/app/client/pages/remote-attendace/modals/room-closed/room-closed.component';
import { timeout } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class WebsocketRemoteUtilService {

    constructor(
        private websocketRemoteService: WebsocketRemoteService,
        public dialog: MatDialog,
        private router: Router,
        private alertService: AlertService) { }
    socket: Socket;
    idRoom: number;
    idEpisode: number;
    hash: string;
    patientEvaded: boolean = true;
    medicLeft: boolean = false;

    getMedicLeft(): boolean {
        return this.medicLeft;
    }

    getIdEpisode(): number {
        let idEpisodeRemote: number = sessionStorage.getItem("idEpisodeRemote") ? parseInt(sessionStorage.getItem("idEpisodeRemote")) : null;
        return this.idEpisode || idEpisodeRemote;
    }

    isConnect(): boolean {
        if (this.socket) {
        }
        return this.socket && this.socket.connected;
    }

    clearSocket(): void {
        if (this.socket && this.socket.connected) {
            this.socket.disconnect();
        }
        this.socket = null;
        this.idRoom = null;
        this.hash = null;
    }

    clearSession() {
        sessionStorage.removeItem("videoToken");
        sessionStorage.removeItem("idEpisodeRemote");
        sessionStorage.removeItem("attendantUserName");
        this.idEpisode = null;
        this.patientEvaded = true;
        sessionStorage.removeItem("patientConnected");
    }

    connectwebsocketRoom(idRoom: number): void {
        this.idRoom = idRoom;
        this.socket = this.websocketRemoteService.websocketConnection();
        this.hash = (Math.random() + 1).toString(36).substring(2);
        this.socket.emit('join', { room: `idRoom-${this.idRoom}`, medicSide: false, hash: this.hash })
            .on("medicLeft", (res) => {
                this.medicLeft = true;
                if (sessionStorage.getItem("patientConnected") === 'true') {
                    this.dialog.open(WarnPatientDoctorEvadeComponent);
                }
                this.clearSession();
            })
            .on("roomConnected", (res) => {
                if (res != this.hash) {
                    this.socket.emit('roomAlredyConnected', { idRoom: this.idRoom, hash: this.hash });
                }
            })
            .on("roomAlredyConnected", (res) => {
                if (res != this.hash) {
                    let dilogRef = this.dialog.open(RoomDuplicatedComponent);
                    dilogRef.afterClosed().subscribe((result) => {
                        if (!result.continueConnected) {
                            this.clearSession();
                            this.alertService.show("Aviso", "Sua sala foi fechada, procure a recepção!", AlertType.warning);
                            this.router.navigate(['client/remote-attendance/inv']);
                        } else {
                            this.socket.emit('closeOtherRoom', { idRoom: this.idRoom, hash: this.hash });
                        }
                    })
                }
            })
            .on("closeOtherRoom", (res) => {
                if (res != this.hash) {
                    this.dialog.open(RoomClosedComponent);
                    this.clearSession();
                    this.router.navigate(['client/remote-attendance/inv']);
                }
            })
            .on("clientRemote", (res) => {
                setTimeout(() => {
                    this.idEpisode = res.idEpisode;
                    sessionStorage.setItem("idEpisodeRemote", res.idEpisode);
                    sessionStorage.setItem("videoToken", res.tokenPatient);
                    sessionStorage.setItem("attendantUserName", res.attendantUserName);
                }, 8000);
            })
            .on('someoneDisconnected', () => {
                if (sessionStorage.getItem("patientConnected") === 'true' && !this.medicLeft) {
                    setTimeout(() => {
                        this.socket.emit('verifying', `idRoom-${this.idRoom}`);
                    }, 20000);
                    this.alertService.show("Aviso", "O médico está com instabilidade, aguarde alguns segundos!", AlertType.warning);
                }
            })
            .on('personsInRoom', (res) => {
                if (!Number.isNaN(res) && res == 1) {
                    if (sessionStorage.getItem("patientConnected") === 'true') {
                        this.medicLeft = true;
                        this.dialog.open(WarnPatientDoctorEvadeComponent);
                    }
                    this.clearSession();
                }
            }).on("endRemoteAttendance", (res) => {
                let destinationName = res.destinationRoomName ?? res.destinationSectorName
                    ?? res.destinationServiceName;
                this.patientEvaded = false;
                this.router.navigate(['client/remote-attendance/AttendPatientEnded',
                    {
                        conclusionType: res.conclusionType,
                        destinationName: destinationName ?? "",
                    }]);
            })
            .io.on("reconnect", (res) => {
                this.socket.emit('join', { room: `idRoom-${this.idRoom}`, medicSide: false, hash: this.hash });
            });
    }


    emitVerifying() {
        this.socket.emit('verifying', `idRoom-${this.idRoom}`);
    }

    emitPatientIsConnecting() {
        sessionStorage.setItem("patientConnected", "true");
        this.socket.emit("patientConnected", { idRoom: this.idRoom })
    }

    emitPatientEvaded() {
        if (this.patientEvaded) {
            this.socket.emit("emitPatientEvaded", { idRoom: this.idRoom })
        }
        this.clearSession();
    }
}