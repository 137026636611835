export class AreaRequest{
    public areaName: string;

    public areaDescription: string;

    public idService: number;

    public listIdSector: number[];

    public showNames: boolean;
    
    public showRanking: boolean;

    public isActive: boolean;
}