import { Component, Input, OnInit } from '@angular/core';
import { AuditUserSelectStruct } from 'src/app/shared/services/structs/audit/audit-user-select.struct';

@Component({
  selector: 'app-audit-user-select',
  templateUrl: './audit-user-select.component.html',
  styleUrls: ['./audit-user-select.component.css']
})
export class AuditUserSelectComponent implements OnInit {

  constructor() { }
  @Input() auditUserSelectStruct: AuditUserSelectStruct;
  @Input() allowAudited: boolean;

  ngOnInit(): void {
    this.auditUserSelectStruct.formGroup.get('sampleAmount').disable();
    this.auditUserSelectStruct.formGroup.get('isFixedSampleSize').disable();
    if(!this.allowAudited){
      this.auditUserSelectStruct.formGroup.get('isAudited').setValue(true);
      this.auditUserSelectStruct.formGroup.get('isAudited').disable();
      this.changeAudit(true);
    }
  }

  invalidate() {
    if(!this.auditUserSelectStruct.formGroup.get('sampleAmount').value) {
      this.auditUserSelectStruct.formGroup.get('sampleAmount').markAsTouched();
      this.auditUserSelectStruct.formGroup.get('sampleAmount').markAsDirty();
    }
  }

  changeAudit(isAudited: boolean) {
    if (isAudited == true) {
      if(this.auditUserSelectStruct.classificationAmount < 10){
        this.auditUserSelectStruct.formGroup.get('sampleAmount').setValue(this.auditUserSelectStruct.classificationAmount);     
        this.auditUserSelectStruct.formGroup.get('sampleAmount').disable();
        this.auditUserSelectStruct.formGroup.get('isFixedSampleSize').disable();
      }
      else{
        this.auditUserSelectStruct.formGroup.get('sampleAmount').enable();
        this.auditUserSelectStruct.formGroup.get('isFixedSampleSize').enable();
      }
    }
    else {
      this.auditUserSelectStruct.formGroup.get('sampleAmount').disable();
      this.auditUserSelectStruct.formGroup.get('isFixedSampleSize').disable();
      this.auditUserSelectStruct.formGroup.get('sampleAmount').setValue(null);
      this.auditUserSelectStruct.formGroup.get('isFixedSampleSize').setValue(null);
    }
  }

  changeFixedSampleSize(isFixedSampleSize: boolean) {
    if (isFixedSampleSize == true) {
      var sampleAmount = this.auditUserSelectStruct.classificationAmount * 0.02;
      sampleAmount = Math.round(sampleAmount);
      
      if(sampleAmount < 10) {
        sampleAmount = 10;
      }
      if (sampleAmount > this.auditUserSelectStruct.classificationAmount) {
        sampleAmount = this.auditUserSelectStruct.classificationAmount;
      }
      this.auditUserSelectStruct.formGroup.get('sampleAmount').setValue(sampleAmount);
      this.auditUserSelectStruct.formGroup.get('sampleAmount').disable();
    }
    else {
      this.auditUserSelectStruct.formGroup.get('sampleAmount').setValue(null);
      this.auditUserSelectStruct.formGroup.get('sampleAmount').enable();
    }
  }
}
