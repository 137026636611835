<div class="border-list">
    <div class="title-list">
        <div class="row">
            <div class="col-6 col-sm-6 col-md-4">
                <span class="subtitle">Linhas de Cuidado</span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6 col-sm-6 col-md-4">
            <a mat-flat-button type="button" (click)="openCareLineModal(listCareLine)" color="accent"
                class=" btn-block">
                <mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon> Adicionar Linha de
                Cuidado
            </a>
        </div>
    </div>
    <div class="card-list"
        *ngIf="listCurrentEpisodeCareLine != null && listCurrentEpisodeCareLine.length > 0">
        <span class="section">
            Ativas
        </span>
        <div class="row row-list">
            <div class="col-12 col-sm-6 col-md-4"
                *ngFor="let item of listCurrentEpisodeCareLine; let index = index;">
                <div class="card-item">
                    <div class="infos">
                        <span class="primary">{{item.careLineName}}</span>
                        <span *ngIf="item.userName" class="secundary">Alocado por:
                            {{item.userName}}</span>
                        <span *ngIf="item.datetimeInclusion"
                            class="secundary">{{item.datetimeInclusion}}</span>
                    </div>
                    <div class="actions">
                        <a *ngIf="listNewEpisodeCareLine.includes(item)">
                            <mat-icon aria-hidden="false" aria-label="Excluir"
                                (click)="removeCareLine(index)">delete</mat-icon>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-list"
        *ngIf="listPastEpisodeCareLine != null && listPastEpisodeCareLine.length > 0">
        <span class="section">
            Passadas
        </span>
        <div class="row row-list">
            <div class="col-12 col-sm-6 col-md-4"
                *ngFor="let item of listPastEpisodeCareLine; let index = index;">
                <div class="card-item">
                    <div class="infos">
                        <span class="primary">{{item.careLineName}}</span>
                        <span class="secundary">Alocado por: {{item.userName}}</span>
                        <span class="secundary">{{item.datetimeInclusion}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>