<form class="form" [formGroup]="model">
    <div class="row">
        <div class="col-12 col-sm-4 col-md-5">
        </div>
        <div class="col-12 col-sm-4 col-md-5">
        </div>
        <div class="col-12 col-sm-4 col-md-2">
            <button style="display: none;" mat-flat-button type="button" (click)="previous()" color="accent"
                class=" btn-block">
                <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Voltar
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-6">
            <div class="row">
                <div class="col-md-12 row-escolha-triagem">
                    <div class="descricao descricao-com-info">
                        <span [matTooltip]="trtsDescription" [matTooltipPosition]="'above'" class="texto">
                            TRTS*
                            <a><img src="assets/images/icode-radio-ask.svg" /></a>
                        </span>
                        <span (click)="setValuetrt()" class="info"></span>
                    </div>
                    <input [value]="true" class="radio-sim" type="radio" id="radio-trt-sim" formControlName="radio-trt"
                        name="radio-trt">
                    <label (click)="setValuetrt()" class="label-radio-sim" for="radio-trt-sim"><img
                            src="assets/images/icone-radio-sim.svg" /></label>
                    <input [value]="false" class="radio-nao" type="radio" id="radio-trt-nao" formControlName="radio-trt"
                        name="radio-trt">
                    <label (click)="setValuetrt()" class="label-radio-nao" for="radio-trt-nao"><img
                            src="assets/images/icone-radio-nao.svg" /></label>
                </div>
            </div>
        </div>
    </div>
</form>