import { TriageSampleUserAmountStruct } from "../../structs/medical-record/triage-sample-user-amount.struct";

export class GetSampleRequest {
    public beginPeriod: Date;
    public endPeriod: Date;
    public idProtocol: number;
    public datetimeStart: Date;
    public datetimeEnd: Date;
    public listSampleUserAmount: TriageSampleUserAmountStruct[];
    public listIdFlowchart: number[];
}