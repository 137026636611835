import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-without-device',
  templateUrl: './without-device.component.html',
  styleUrls: ['./without-device.component.css']
})
export class WithoutDeviceComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<WithoutDeviceComponent>) { }

  ngOnInit(): void {
  }

  clickCancel(value: number) {
    this.matDialogRef.close({ value });
  }
}

