<div mat-dialog-content class="next-steps-proto">
    <div class="mat-dialog-content next-steps-proto">
        <div class="head-confirm-modal-list">
            <div class="img-head">
                <img style="width: 5rem;" src="assets/images/exclamation-circle.png" />
            </div>
            <div class="title-modal">
                <h1>Falha na Assinatura Digital</h1>
            </div>
        </div>
        <div class="body-confirm-modal-list">
            <div class="row">
                <div class="body-modal">
                    <h3>Encontramos um erro para assinar o(s) seguinte(s) documento(s):</h3>
                </div>
                <ul *ngFor="let item of listSignedHasError">
                    <li>{{item}}</li>
                </ul>
                <h2 style="text-align: center;">Deseja tentar assinar novamente ou continuar sem assinatura?</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <button mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Continuar sem
                    assinar</button>
            </div>
            <div class="col-6">
                <button mat-flat-button (click)="clickConfirm()" color="primary" class="btn-block">
                    <span>Tentar assinar novamente</span>
                </button>
            </div>
        </div>
        <div class="row">
            <h6 style="text-align: center;"><strong>Lembre-se:</strong> sem a assinatura, os documentos gerados não
                serão enviados ao paciente.</h6>
        </div>
    </div>
</div>