export class CompleteTableData{
    listTableResult: TableResult[];
}

export class TableResult{
    listTableCell: TableCell[];
}

export class TableCell{
    public columnName: string;
    public fieldName: string;
    public rowValue: any;
}