<div [formGroup]="model" class="sector">
    <div class="white-body">
        <div class="row">
            <div class="col-12">
                <h2 class="section-title">Setor: {{sector.sectorName}}</h2>
            </div>
            <div class="col-6 col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>Protocolos para {{sector.sectorName}}</mat-label>
                    <mat-select formControlName="listIdProtocol" multiple>
                        <mat-option *ngFor="let item of listProtocol" [value]="item.idProtocol">
                            {{item.protocolName}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-6 col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>Tipo de Dispositivo para {{sector.sectorName}}</mat-label>
                    <mat-select formControlName="idDeviceType" (selectionChange)="verifyDeviceType()">
                        <mat-option *ngFor="let item of listDeviceType" [value]="item.idDeviceType">
                            {{item.deviceTypeName}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-7 col-md-4">
                <mat-form-field appearance="outline">
                    <mat-label>Campos obrigatórios na pré-admissão</mat-label>
                    <mat-select formControlName="listPreAdmissionIdRequiredField" multiple>
                        <mat-option *ngFor="let item of listPreAdmissionRequiredField"
                            [value]="item.idPreAdmissionRequiredField">
                            {{item.preAdmissionRequiredFieldName}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-7 col-md-4">
                <mat-form-field appearance="outline">
                    <mat-label>Campos obrigatórios nos dados complementares</mat-label>
                    <mat-select formControlName="listSupplementaryDataIdRequiredField" multiple>
                        <mat-option *ngFor="let item of listSupplementaryDataRequiredField"
                            [value]="item.idSupplementaryDataRequiredField">
                            {{item.supplementaryDataRequiredFieldName}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4 metaData">
                    <mat-form-field appearance="outline">
                        <mat-label>Selecionar metadados</mat-label>
                        <mat-select formControlName="listMetadata" multiple>
                            <mat-option *ngFor="let item of listMetaData" (click)="selectMetaData(item.metaData.idMetaData)"
                                [value]="item.metaData.idMetaData">
                                {{ item.metaData.metaDataName }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4 selectMetaData">
                    <mat-form-field appearance="outline">
                        <mat-label>Metadados obrigatórios</mat-label>
                        <mat-select formControlName="listMetaDataRequiredSelected" multiple>
                            <mat-option *ngFor="let item of listMetaDataRequiredSelected"
                                (click)="selectRequiredMetaData(item.metaData.idMetaData)"
                                [value]="item.metaData.idMetaData">
                                {{ item.metaData.metaDataName }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-3 col-md-3">
                    <mat-slide-toggle formControlName="disableScreenSupplementaryData">Desabilitar tela de dados complementares</mat-slide-toggle>
                </div>
                <div class="col-2 col-md-2">
                    <mat-slide-toggle formControlName="disableShowReport"> {{model.get('disableShowReport').value ? 'Não visualizar' : 'Visualizar'}} relatório</mat-slide-toggle>
                </div>
                <div class="col-3 col-md-3">
                    <mat-slide-toggle formControlName="allowAdmissionDataChanging">Permitir alterar dados da pré-admissão</mat-slide-toggle>
                </div>
                <div class="col-3 col-md-3">
                    <mat-slide-toggle formControlName="setUppercaseText">Transformar textos para caixa alta</mat-slide-toggle>
                </div>
            </div>
            <div class="row">
                <div class="col-3 col-md-3">
                    <mat-slide-toggle formControlName="skipIdentifiedPatientPreRegister">Pular pré-cadastro para paciente identificado</mat-slide-toggle>
                </div>
            </div>
        </div>
        <ng-container *ngIf="!isRemoteDevice">
            <div formArrayName="listRoomMeasurer"
                *ngFor="let roomMeasurer of model.get('listRoomMeasurer')['controls']; let i = index">
                <app-room-measurer-select [formGroup]="roomMeasurer" [index]="i" [room]="sector.listRoom[i]"
                    [sector]="sector" [listOximeter]="listOximeter" [listThermometer]="listThermometer"
                    [listPressureMeasurer]="listPressureMeasurer" [listGlucometer]="listGlucometer" 
                    [idRoom]="idRoom">
                </app-room-measurer-select>
            </div>
        </ng-container>
    </div>
</div>