<div class="row display-contents">
    <div class="col-12 col-sm-12 col-md-12 padding-box">
        <div class="row border-box">  
            <div class="title-header">
                <h3>PROCEDIMENTOS DERIVADOS</h3>
            </div>             
            <div class="col-12 organization-fields">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <a mat-flat-button color="primary" class="add-user"
                            (click)="openModalCreateEdit()">
                            <mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon>
                            Adicionar Novo</a>
                    </div>
                </div> 
                <div class="row">
                    <div class="card-list" *ngIf="listProcedureRelationshipStruct && listProcedureRelationshipStruct.length > 0">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listProcedureRelationshipStruct">
                                <div class="card-item">
                                    <div class="infos">
                                        <span class="primary">Procedimento:</span>
                                        <span class="secundary">{{item.nameProcedureChild}}</span>  
                                        <span class="primary">Quantidade: </span>
                                        <span class="secundary">{{item.quantity}}</span>                              
                                    </div>
                                    <div class="actions">
                                        <a><mat-icon aria-hidden="false" aria-label="Editar" (click)="openModalCreateEdit(item)">edit</mat-icon> </a>
                                        <a><mat-icon aria-hidden="false" aria-label="Editar" (click)="openModalDelete(item)">delete_forever</mat-icon> </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <app-empty-list *ngIf="!listProcedureRelationshipStruct || listProcedureRelationshipStruct.length == 0 && !isLoading"></app-empty-list>
                </div>                   
            </div>
        </div>
    </div>
    <div class="row" style="padding-left: 20px;">
        <div class="col-3">
            <button mat-flat-button (click)="backToProcedureList()" color="accent" class="btn-block">
                <span *ngIf="isLoading == false">Voltar aos procedimentos</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
        </div>
    </div>
</div>