import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ItemService } from 'src/app/shared/services/API/pharmacy/Item.service';
import { PharmacyConfigService } from 'src/app/shared/services/API/pharmacy/pharmacy-config.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { ItemRequest } from 'src/app/shared/services/requests/pharmacy/Item.request';

@Component({
  selector: 'app-item-register',
  templateUrl: './item-register.component.html',
  styleUrls: ['./item-register.component.css']
})
export class ItemRegisterComponent implements OnInit {

  constructor(private formBuilder: UntypedFormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private itemService: ItemService,
    private pharmacyConfigService: PharmacyConfigService,
  ) { }
    
  public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.pharmacy;
  public menuFunctionalityEnum:MenuFunctionalityEnum = MenuFunctionalityEnum.pharmacy_item;
    
  public model: UntypedFormGroup;
  public isLoading: boolean;
  public isUpdate: boolean;
    
  public masks:Masks;
  public idItem: number;
  public itemRequest: ItemRequest;
    
    ngOnInit(): void {
      
      this.isLoading = false;
      this.itemRequest = new ItemRequest();

      this.model = this.formBuilder.group({
        name: ['', [Validators.required]],
        itemCode: ['', [Validators.required]],
      });
      
      this.masks = this.maskService.getMasks();
      
      this.idItem = null;
      this.isUpdate = false;

      if (this.activatedRoute.snapshot.paramMap.get('idItem'))
        this.idItem = parseInt(this.activatedRoute.snapshot.paramMap.get('idItem'));
      
      if (this.idItem && this.idItem > 0){
        this.isUpdate = true;       
      }

      this.populatePharmacyConfig(this.isUpdate);
    }
    
    submit(){
      if(this.isLoading){
        return;
      }

      if(!this.model.valid) {
        this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
        return;
      } 
      
      this.itemRequest.itemCode = this.model.get('itemCode').value;
      this.itemRequest.itemName = this.model.get('name').value;
      
      if(this.isUpdate)
        this.updateItem();
      else
        this.createItem();
    }

    populatePharmacyConfig(isToPopulateItem: boolean = false) {   
      if (this.isLoading)
        return;
      
      this.isLoading = true;

      let isToGetItemAutoIncrement: boolean = true;
  
      this.pharmacyConfigService.getConfigByHealthUnit(isToGetItemAutoIncrement).subscribe({
        next: (response) => {
          if (response.isError) {
            this.isLoading = false;
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            return;
          }

          let hasAutoIncrementConfig: boolean = response.nextItemCode && response.nextItemCode.trim() != "";
  
          if (this.isUpdate == false && hasAutoIncrementConfig) {
            this.model.get('itemCode').setValue(response.nextItemCode);
            this.model.get('itemCode').disable();
          }

          this.isLoading = false;

          if (isToPopulateItem)
            this.populateItemData(hasAutoIncrementConfig);

        },
        error: (error) => {
          this.isLoading = false;
          this.alertService.show('Erro inesperado', error, AlertType.error);
        }
      });
    }
    
    populateItemData(hasAutoIncrementConfig: boolean){
      if (this.isLoading)
        return;

      this.isLoading = true;

      this.itemService.getItem(this.idItem).subscribe({
        next: (response)=>{        
          if(response.isError){
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            this.isLoading = false;
            return;
          }

          this.model.get('name').setValue(response.item.itemName);
          this.model.get('itemCode').setValue(response.item.itemCode);

          if (hasAutoIncrementConfig)
            this.model.get('itemCode').disable();
          
          this.isLoading = false; 
        },
        error: (error)=>{
          console.log(error)
          this.isLoading = false;    
          this.alertService.show('Erro inesperado', error, AlertType.error);
        }
      });
    }
    
    updateItem(){
      if (this.isLoading)
        return;

      this.isLoading = true;

      this.itemService.updateItem(this.idItem, this.itemRequest).subscribe({
        next: (response)=>{        
          if(response.isError){
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            this.isLoading = false;
            return;
          }
          
          this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
          this.isLoading = false; 
          
          this.router.navigate(['/pharmacy/item']);
          
        },
        error: (error)=>{
          console.log(error)
          this.isLoading = false;    
          this.alertService.show('Erro inesperado', error, AlertType.error);
        }
      });
    }
    
    createItem(){
      if (this.isLoading)
        return;

      this.isLoading = true;

      this.itemService.createItem(this.itemRequest).subscribe({
        next: (response)=>{        
          if(response.isError){
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            this.isLoading = false;
            return;
          }
          
          this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
          this.isLoading = false;    
          
          this.router.navigate(['/pharmacy/item']);       
        },
        error: (error)=>{
          console.log(error)
          this.isLoading = false;    
          this.alertService.show('Erro inesperado', error, AlertType.error);
        }
      });
    }
  }
