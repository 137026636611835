<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <h1>Lista de Pedidos de Processamento</h1>
        <div class="white-body">
            <a mat-flat-button color="primary" class=" add-user" routerLink="/master/batch/register">
                <mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon> Gerar Pedido
            </a>
            <div class="filters">
                <div class="row">
                    <div class="col-6 col-md-2"> 
                        <mat-form-field appearance="outline">
                          <mat-label>Tipo de Pedido</mat-label>
                          <mat-select [(ngModel)]="idBatchType" (selectionChange)="search()">
                            <mat-option [value]="">Todos</mat-option> 
                            <mat-option *ngFor="let item of listBatchType" value="{{item.idBatchType}}">{{item.batchTypeName}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="card-list" *ngIf="listBatchRequests != null">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listBatchRequests">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary">{{item.idBatchRequest}} - {{item.batchTypeName}}</span>
                                <span class="secundary">{{item.description}}</span>
                                <span class="secundary">{{item.datetimeBatchBegin | date: 'dd/MMM/yyyy'}}</span>
                                <span class="secundary">{{item.datetimeBatchEnd | date: 'dd/MMM/yyyy'}}</span>
                                <span class="last-info">{{item.batchStatusName}}</span>
                            </div>
                            <div class="actions">
                                <a *ngIf="item.fileName"><mat-icon aria-hidden="false" aria-label="Baixar" (click)="downloadFile(item.idBatchRequest)">summarize</mat-icon></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <app-empty-list *ngIf="listBatchRequests != undefined && listBatchRequests.length == 0">
            </app-empty-list>
        </div>
    
        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>
    
</div>