import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ListBedTypeResponse } from '../../responses/bed/list-bed-type-response.response';

@Injectable({
  providedIn: 'root'
})
export class BedTypeService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listBedType(): Observable<ListBedTypeResponse>{

    let uri = `BedType/getAll`

    return this.httpClient.get<ListBedTypeResponse>(environment.urlApiBed + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
