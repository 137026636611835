import { Component, Inject, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CalendarSchedulerEvent } from 'angular-calendar-scheduler';
import { addDays, addHours, startOfHour } from 'date-fns';
import { DayEnum } from 'src/app/shared/enum/schedule/day.enum';
import { FrequencyEnum } from 'src/app/shared/enum/schedule/frequency.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Schedule } from 'src/app/shared/services/models/schedule/schedule.model';
import { ScheduleRequest } from 'src/app/shared/services/requests/schedule/schedule.request';
import { startOfWeek, endOfWeek, isSameWeek } from 'date-fns';

@Component({
  selector: 'app-view-calendar-schedule-modal',
  templateUrl: './view-calendar-schedule-modal.component.html',
  styleUrls: ['./view-calendar-schedule-modal.component.css']
})
export class ViewCalendarScheduleModalComponent implements OnInit {
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<ViewCalendarScheduleModalComponent>,
    private alertService: AlertService
  ) { }

  public isLoading: boolean; 
  public events: CalendarSchedulerEvent[] = []; 
  public eventsBlocked: CalendarSchedulerEvent[] = []; 

  public scheduleRequest: ScheduleRequest;
  public idDayOfTimeStart: number;
  public countEvent: number = 0;
  public dayCalc: number = 0;
  public duration: number = 0;
  // public calendarDate: Date;
  public calendarEvent: Date = new Date();
  public dateTimeStart: Date;
  public dateTimeEnd: Date;
  public viewCalendar: boolean = false;
  public numberRepetition: number[] = [];

  ngOnInit(): void {
    this.isLoading = false;
    this.scheduleRequest = new ScheduleRequest();
    this.scheduleRequest = this.data.scheduleRequest;
    this.numberRepetition = new Array(this.scheduleRequest.listBlockedDays.length);
    this.duration = this.scheduleRequest.appointmentDuration;
    this.scheduleRequest.datetimeEnd.setHours(23);
    this.scheduleRequest.datetimeEnd.setMinutes(59);
    this.scheduleRequest.datetimeEnd.setSeconds(59);
    this.addNewEventsBlocked();
  }

  close(){
    this.matDialogRef.close(); 
  }

  addNewEvents(event) {
    this.newFreeEvents(event.viewDate, event.buttonBack);
  }

  newFreeEvents(calendarDate: Date, buttonBack: boolean) {
    this.idDayOfTimeStart = calendarDate.getDay();
    this.events = [...this.eventsBlocked];

    if (isSameWeek(calendarDate, this.scheduleRequest.datetimeStart)) {
      let dayStart = this.scheduleRequest.datetimeStart.getDay();
      if(this.scheduleRequest.listScheduleDay.length > 0) {
        this.scheduleRequest.listScheduleDay.forEach((schedule) => {
          if(schedule.idDay >= dayStart){

            this.dayCalc = (schedule.idDay - this.idDayOfTimeStart);
    
            let timeTotal = ((schedule.timeEnd.getHours() - schedule.timeStart.getHours()) * 60) + (schedule.timeEnd.getMinutes() - schedule.timeStart.getMinutes());
            let totalLoop = timeTotal/this.duration;
    
            for(let i = 0; i < totalLoop; i ++) {
      
              this.dateTimeStart = new Date(calendarDate.getFullYear(),
                                            calendarDate.getMonth(), 
                                            (calendarDate.getDate() + this.dayCalc), 
                                            schedule.timeStart.getHours(),
                                            schedule.timeStart.getMinutes()+(this.duration*i));
              this.dateTimeEnd = new Date(calendarDate.getFullYear(),
                                          calendarDate.getMonth(), 
                                          (calendarDate.getDate() + this.dayCalc), 
                                          schedule.timeEnd.getHours(),
                                          schedule.timeEnd.getMinutes()-(this.duration*(totalLoop-i-1)));
              
              let calendarEvent = <CalendarSchedulerEvent>{
                id: this.countEvent.toString(),
                start: this.dateTimeStart,
                end: this.dateTimeEnd,
                title: '',
                content: '-',
                color: { 
                  primary: '#85C443', 
                  secondary: '#85C443',
                  secondaryText: "#FFFFFF"
                },
                // actions: this.actions,
                // status: 'danger' as CalendarSchedulerEventStatus,
                isClickable: true,
                isDisabled: false,
                draggable: false,
              }
      
              this.events.push(calendarEvent);
              this.countEvent++;
            }
          }
        });
      }
    } else if (isSameWeek(calendarDate, this.scheduleRequest.datetimeEnd)) {
      let dayEnd = this.scheduleRequest.datetimeEnd.getDay();
      if(this.scheduleRequest.listScheduleDay.length > 0) {
        this.scheduleRequest.listScheduleDay.forEach((schedule) => {
          if(schedule.idDay <= dayEnd) {

            this.dayCalc = (schedule.idDay - this.idDayOfTimeStart);
    
            let timeTotal = ((schedule.timeEnd.getHours() - schedule.timeStart.getHours()) * 60) + (schedule.timeEnd.getMinutes() - schedule.timeStart.getMinutes());
            let totalLoop = timeTotal/this.duration;
    
            for(let i = 0; i < totalLoop; i ++) {
      
              this.dateTimeStart = new Date(calendarDate.getFullYear(),
                                            calendarDate.getMonth(), 
                                            (calendarDate.getDate() + this.dayCalc), 
                                            schedule.timeStart.getHours(),
                                            schedule.timeStart.getMinutes()+(this.duration*i));
              this.dateTimeEnd = new Date(calendarDate.getFullYear(),
                                          calendarDate.getMonth(), 
                                          (calendarDate.getDate() + this.dayCalc), 
                                          schedule.timeEnd.getHours(),
                                          schedule.timeEnd.getMinutes()-(this.duration*(totalLoop-i-1)));
              
              let calendarEvent = <CalendarSchedulerEvent>{
                id: this.countEvent.toString(),
                start: this.dateTimeStart,
                end: this.dateTimeEnd,
                title: '',
                content: '-',
                color: { 
                  primary: '#85C443', 
                  secondary: '#85C443',
                  secondaryText: "#FFFFFF"
                },
                // actions: this.actions,
                // status: 'danger' as CalendarSchedulerEventStatus,
                isClickable: true,
                isDisabled: false,
                draggable: false,
              }
      
              this.events.push(calendarEvent);
              this.countEvent++;
            }
          }
        });
      }
    } else if (calendarDate >= this.scheduleRequest.datetimeStart && calendarDate <= this.scheduleRequest.datetimeEnd) {
  
      if(this.scheduleRequest.listScheduleDay.length > 0) {
        this.scheduleRequest.listScheduleDay.forEach((schedule) => {
  
          this.dayCalc = (schedule.idDay - this.idDayOfTimeStart);
  
          let timeTotal = ((schedule.timeEnd.getHours() - schedule.timeStart.getHours()) * 60) + (schedule.timeEnd.getMinutes() - schedule.timeStart.getMinutes());
          let totalLoop = timeTotal/this.duration;
  
          for(let i = 0; i < totalLoop; i ++) {
    
            this.dateTimeStart = new Date(calendarDate.getFullYear(),
                                          calendarDate.getMonth(), 
                                          (calendarDate.getDate() + this.dayCalc), 
                                          schedule.timeStart.getHours(),
                                          schedule.timeStart.getMinutes()+(this.duration*i));
            this.dateTimeEnd = new Date(calendarDate.getFullYear(),
                                        calendarDate.getMonth(), 
                                        (calendarDate.getDate() + this.dayCalc), 
                                        schedule.timeEnd.getHours(),
                                        schedule.timeEnd.getMinutes()-(this.duration*(totalLoop-i-1)));
            
            let calendarEvent = <CalendarSchedulerEvent>{
              id: this.countEvent.toString(),
              start: this.dateTimeStart,
              end: this.dateTimeEnd,
              title: '',
              content: '-',
              color: { 
                primary: '#85C443', 
                secondary: '#85C443',
                secondaryText: "#FFFFFF"
              },
              // actions: this.actions,
              // status: 'danger' as CalendarSchedulerEventStatus,
              isClickable: true,
              isDisabled: false,
              draggable: false,
            }
    
            this.events.push(calendarEvent);
            this.countEvent++;
          }
        });
      }
    }
    this.viewCalendar = true;
  }

  addNewEventsBlocked() {
    this.eventsBlocked = [];
    this.idDayOfTimeStart = this.scheduleRequest.datetimeStart.getDay();

    if (this.scheduleRequest.listBlockedDays && this.scheduleRequest.listBlockedDays.length > 0) {

      // Controle de repetição
      this.scheduleRequest.listBlockedDays.forEach((blockDay, index) => {
        this.numberRepetition[index] = blockDay.repetition ? blockDay.repetition : 0; 
      });

      this.scheduleRequest.listBlockedDays.forEach((blockDay, index) => {
        
        let calendarDateBlocked = new Date(this.scheduleRequest.datetimeStart.getFullYear(),
                                      this.scheduleRequest.datetimeStart.getMonth(), 
                                      this.scheduleRequest.datetimeStart.getDate());

        this.dayCalc = (blockDay.idDay - this.idDayOfTimeStart);

        if(blockDay.idFrequency == FrequencyEnum.Diario) {

          //valida se a data analisada é a data inicial para mostrar no calendário
          if(calendarDateBlocked.getDate() == this.scheduleRequest.datetimeStart.getDate()
          && calendarDateBlocked.getMonth() == this.scheduleRequest.datetimeStart.getMonth()
          && calendarDateBlocked.getFullYear() == this.scheduleRequest.datetimeStart.getFullYear()) {
            
            if(blockDay.idDay >= calendarDateBlocked.getDay()){
              
              // acrescentar o bloqueio no restante da semana
              for(let i = 0; i < (7 - blockDay.idDay); i ++ ){

                if(this.numberRepetition[index] > 0){
                  
                  this.dateTimeStart = new Date(calendarDateBlocked.getFullYear(),
                                          calendarDateBlocked.getMonth(), 
                                          (calendarDateBlocked.getDate() + this.dayCalc), 
                                          blockDay.startTime.getHours(),
                                          blockDay.startTime.getMinutes());
                  this.dateTimeEnd = new Date(calendarDateBlocked.getFullYear(),
                                              calendarDateBlocked.getMonth(), 
                                              (calendarDateBlocked.getDate() + this.dayCalc), 
                                              blockDay.endTime.getHours(),
                                              blockDay.endTime.getMinutes());

                  let calendarEvent = <CalendarSchedulerEvent>{
                    id: this.countEvent.toString(),
                    start: this.dateTimeStart,
                    end: this.dateTimeEnd,
                    title: blockDay.motive ? blockDay.motive : '-',
                    content: 'Horário bloqueado',
                    color: { 
                      primary: '#FF0000', 
                      secondary: '#FF0000',
                      secondaryText: "#FFFFFF"
                    },
                    // actions: this.actions,
                    // status: 'danger' as CalendarSchedulerEventStatus,
                    isClickable: false,
                    isDisabled: false,
                    draggable: false,
                  }
      
                  this.dayCalc += 1;
                  this.eventsBlocked.push(calendarEvent);
                  this.numberRepetition[index]--;
                  this.countEvent++;
                } else {
                  break;
                }
              }
            }
            calendarDateBlocked.setDate(calendarDateBlocked.getDate() + 7);
          }
          while (this.numberRepetition[index] > 0) {
            
            if(this.numberRepetition[index] != this.scheduleRequest.listBlockedDays[index].repetition){
              this.dayCalc = -this.idDayOfTimeStart;
            }

            for(let i = (this.numberRepetition[index] == this.scheduleRequest.listBlockedDays[index].repetition)? (blockDay.idDay) : 0; i < 7; i ++ ){

              if(this.numberRepetition[index] > 0){
                  
                this.dateTimeStart = new Date(calendarDateBlocked.getFullYear(),
                                        calendarDateBlocked.getMonth(), 
                                        (calendarDateBlocked.getDate() + this.dayCalc), 
                                        blockDay.startTime.getHours(),
                                        blockDay.startTime.getMinutes());
                this.dateTimeEnd = new Date(calendarDateBlocked.getFullYear(),
                                            calendarDateBlocked.getMonth(), 
                                            (calendarDateBlocked.getDate() + this.dayCalc), 
                                            blockDay.endTime.getHours(),
                                            blockDay.endTime.getMinutes());
    
                let calendarEvent = <CalendarSchedulerEvent>{
                  id: this.countEvent.toString(),
                  start: this.dateTimeStart,
                  end: this.dateTimeEnd,
                  title: blockDay.motive ? blockDay.motive : '-',
                  content: 'Horário bloqueado',
                  color: { 
                    primary: '#FF0000', 
                    secondary: '#FF0000',
                    secondaryText: "#FFFFFF"
                  },
                  // actions: this.actions,
                  // status: 'danger' as CalendarSchedulerEventStatus,
                  isClickable: false,
                  isDisabled: false,
                  draggable: false,
                }
    
                this.eventsBlocked.push(calendarEvent);
                this.numberRepetition[index]--;
                this.countEvent++;
                this.dayCalc += 1;
              } else {
                break;
              }
            }

            calendarDateBlocked.setDate(calendarDateBlocked.getDate() + 7);
          }

        } else if (blockDay.idFrequency == FrequencyEnum.Semanal 
          || blockDay.idFrequency == FrequencyEnum.Mensal) {
          //valida se a data analisada é a data inicial para mostrar no calendário
          if(calendarDateBlocked.getDate() == this.scheduleRequest.datetimeStart.getDate()
          && calendarDateBlocked.getMonth() == this.scheduleRequest.datetimeStart.getMonth()
          && calendarDateBlocked.getFullYear() == this.scheduleRequest.datetimeStart.getFullYear()
          && blockDay.idDay < calendarDateBlocked.getDay()) {

            calendarDateBlocked.setDate(calendarDateBlocked.getDate() + 7);
          }

          while (this.numberRepetition[index] > 0) {

            if (blockDay.idFrequency == FrequencyEnum.Mensal){
              this.dayCalc = (blockDay.idDay - calendarDateBlocked.getDay());
            }

            this.dateTimeStart = new Date(calendarDateBlocked.getFullYear(),
                                        calendarDateBlocked.getMonth(), 
                                        (calendarDateBlocked.getDate() + this.dayCalc), 
                                        blockDay.startTime.getHours(),
                                        blockDay.startTime.getMinutes());
            this.dateTimeEnd = new Date(calendarDateBlocked.getFullYear(),
                                        calendarDateBlocked.getMonth(), 
                                        (calendarDateBlocked.getDate() + this.dayCalc), 
                                        blockDay.endTime.getHours(),
                                        blockDay.endTime.getMinutes());

            let calendarEvent = <CalendarSchedulerEvent>{
              id: this.countEvent.toString(),
              start: this.dateTimeStart,
              end: this.dateTimeEnd,
              title: blockDay.motive ? blockDay.motive : '-',
              content: 'Horário bloqueado',
              color: { 
                primary: '#FF0000', 
                secondary: '#FF0000',
                secondaryText: "#FFFFFF"
              },
              // actions: this.actions,
              // status: 'danger' as CalendarSchedulerEventStatus,
              isClickable: false,
              isDisabled: false,
              draggable: false,
            }

            this.eventsBlocked.push(calendarEvent);
            this.numberRepetition[index]--;
            this.countEvent++;
            
            if (blockDay.idFrequency == FrequencyEnum.Mensal){
              calendarDateBlocked.setMonth(calendarDateBlocked.getMonth() + 1);
            } else {
              calendarDateBlocked.setDate(calendarDateBlocked.getDate() + 7);
            }
          }
          
        } else {

          if(blockDay.idDay < calendarDateBlocked.getDay()){
            calendarDateBlocked.setDate(calendarDateBlocked.getDate() + 7);
          }

          this.dateTimeStart = new Date(calendarDateBlocked.getFullYear(),
                        calendarDateBlocked.getMonth(), 
                        (calendarDateBlocked.getDate() + this.dayCalc), 
                        blockDay.startTime.getHours(),
                        blockDay.startTime.getMinutes());
          this.dateTimeEnd = new Date(calendarDateBlocked.getFullYear(),
                        calendarDateBlocked.getMonth(), 
                        (calendarDateBlocked.getDate() + this.dayCalc), 
                        blockDay.endTime.getHours(),
                        blockDay.endTime.getMinutes());

          let calendarEvent = <CalendarSchedulerEvent>{
            id: this.countEvent.toString(),
            start: this.dateTimeStart,
            end: this.dateTimeEnd,
            title: blockDay.motive ? blockDay.motive : '-',
            content: 'Horário bloqueado',
            color: { 
              primary: '#FF0000', 
              secondary: '#FF0000',
              secondaryText: "#FFFFFF"
            },
            // actions: this.actions,
            // status: 'danger' as CalendarSchedulerEventStatus,
            isClickable: false,
            isDisabled: false,
            draggable: false,
          }

          this.eventsBlocked.push(calendarEvent);
          this.countEvent++;
        }
      })
    }
    this.newFreeEvents(this.scheduleRequest.datetimeStart, false);
  }

}
