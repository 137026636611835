import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { EquipmentTypeService } from 'src/app/shared/services/API/form/equipment-type.service';
import { EquipmentService } from 'src/app/shared/services/API/form/equipment.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { EquipmentTypeModel } from 'src/app/shared/services/models/form/equipment-type.model';
import { EquipmentRequest } from 'src/app/shared/services/requests/form/equipment.request';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-equipment-register',
  templateUrl: './equipment-register.component.html',
  styleUrls: ['./equipment-register.component.css']
})
export class EquipmentRegisterComponent implements OnInit {

  constructor(private formBuilder: UntypedFormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private equipmentService: EquipmentService,
    private equipmentTypeService: EquipmentTypeService) { }
    
    public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.form;
    public menuFunctionalityEnum:MenuFunctionalityEnum = MenuFunctionalityEnum.form_equipment;
    
    public model: UntypedFormGroup;
    public isLoading: boolean;
    public isFirstLoading: boolean;
    public isUpdate: boolean;
    public actualServiceModule: number;
    public urlList: string;
    
    public masks:Masks;
    public idEquipment: number;
    public listEquipmentType: EquipmentTypeModel[];
    public preSelectedEquipament: EquipmentTypeModel[];
    public equipmentRequest: EquipmentRequest;
    
    ngOnInit(): void {
      
      this.isLoading = false;
      this.urlList = `${environment.urlApiForm}EquipmentType`
     
      
      this.model = this.formBuilder.group({
        name: ['', [Validators.required]],
        status: ['', [Validators.required]],
        department: ['', Validators.required],
        idEquipmentType: ['', Validators.required],
        quantity: ['', Validators.required],
      });
      
      this.masks = this.maskService.getMasks();
      
      this.equipmentRequest = new EquipmentRequest();
      
      
      
      this.idEquipment == null;
      this.isUpdate == false;
      if(this.activatedRoute.snapshot.paramMap.get('idEquipment'))
      this.idEquipment = parseInt(this.activatedRoute.snapshot.paramMap.get('idEquipment'));
      
      if(this.idEquipment != null){
        this.isFirstLoading = true;
        this.isUpdate = true;
        this.populateEquipmentTypeSelect();        
      }

    }
    
    submit(){
      if(this.isLoading){
        return;
      }

      if(!this.model.valid) {
        this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
        return;
      }
      this.isLoading = true;   
      
      this.equipmentRequest.department = this.model.get('department').value;
      this.equipmentRequest.idEquipmentType = parseInt(this.model.get('idEquipmentType').value);

      if (this.model.get('status').value === "true") {
        this.equipmentRequest.isActive = true;
      }
      if (this.model.get('status').value === "false") {
        this.equipmentRequest.isActive = false;
      }
      
      this.equipmentRequest.equipmentName = this.model.get('name').value;
      this.equipmentRequest.quantity = this.model.get('quantity').value;
      
      if(this.isUpdate)
      this.updateEquipment();
      else
      this.createEquipment();
    }
    
    populateExamData(){
      this.equipmentService.getEquipment(this.idEquipment).subscribe((response)=>{
        
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.model.get('name').setValue(response.equipment.equipmentName);
        this.model.get('status').setValue(response.equipment.isActive.toString());
        this.model.get('idEquipmentType').setValue(response.equipment.idEquipmentType.toString());
        this.model.get('quantity').setValue(response.equipment.quantity.toString());
        this.model.get('department').setValue(response.equipment.department);
        
        this.preSelectedEquipament = this.listEquipmentType.filter(c => c.idEquipmentType == response.equipment.idEquipmentType);
        this.isLoading = false; 
        this.isFirstLoading = false;
      },
      (error)=>{
        console.log(error)
        this.isLoading = false;    
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
    }
    
    populateEquipmentTypeSelect(){
      var searchText = null;
      this.equipmentTypeService.listEquipmentType(searchText).subscribe((response)=>{
        
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.listEquipmentType = response.list; 
        if(this.idEquipment != null)
          this.populateExamData();        
      },
      (error)=>{
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
    }

    selectChange(values) {
      this.model.get('idEquipmentType').setValue(values.idEquipmentType);
    }
    
    updateEquipment(){
      this.equipmentService.updateEquipment( this.idEquipment, this.equipmentRequest).subscribe((response)=>{
        
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        
        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false; 
        
        this.router.navigate(['/form/equipment']);
        
      },
      (error)=>{
        console.log(error)
        this.isLoading = false;    
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
    }
    
    createEquipment(){
      this.equipmentService.createEquipment(this.equipmentRequest).subscribe((response)=>{
        
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        
        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;    
        
        this.router.navigate(['/form/equipment']);       
      },
      (error)=>{
        console.log(error)
        this.isLoading = false;    
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
    }
  }
