import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ClassificationData } from '../../../utils.service';
import { WebsocketAttendanceUtilService } from 'src/app/shared/services/websocket-util/websocket-attendance-util.service';
import { ActivatedRoute } from '@angular/router';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { UpdateStatusQueueService } from 'src/app/shared/services/API/orchestrator-queue/update-status-queue.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AttendanceOccupiedRemoveModalComponent } from 'src/app/shared/components/attendance-occupied-remove-modal/attendance-occupied-remove-modal.component';

@Component({
  selector: 'app-pre-register-default',
  templateUrl: './pre-register-default.component.html',
  styleUrls: ['./pre-register-default.component.css']
})
export class PreRegisterDefaultComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    private websocketAttendanceUtilService: WebsocketAttendanceUtilService,
    private activatedRoute: ActivatedRoute,
    private updateStatusQueueService: UpdateStatusQueueService,
    private alertService: AlertService,
    private authService: AuthService,
  ) { }

  @Input() idEpisode: number;
  @Input() idRoom: number;
  @Input() idSector: number;
  @Input() idService: number;
  @Input() idDeviceType: number;
  @Input() reclassification: boolean;
  @Input() classificationData: ClassificationData;
  @Input() isFirstLoading: Boolean;
  @Output() repopulateHeader = new EventEmitter<any>();

  public isLoading: boolean = true;
  public idQueue: number = parseInt(this.activatedRoute.snapshot.paramMap.get('idQueue'));
  public isNext: boolean = false;
  public userLogin: string = this.authService.getTokenMenu().login;
  public userName: string = this.authService.getTokenMenu().userName;
  public isAttendanceRemoval: boolean = false;

  ngOnInit(): void {
    if (Number.isInteger(this.idQueue) && this.idQueue && this.idQueue > 0 && Number.isInteger(this.idEpisode) && this.idEpisode && this.idEpisode > 0)
      this.startWebsocket();

    this.isLoading = false;
  }

  ngOnDestroy() {
    if (this.isNext == false && Number.isInteger(this.idQueue) && this.idQueue && this.idQueue > 0 && Number.isInteger(this.idEpisode) && this.idEpisode && this.idEpisode > 0 && !this.isAttendanceRemoval) {
      if (this.websocketAttendanceUtilService.isConnect())
        this.websocketAttendanceUtilService.emitFinalizeAttendance(this.idRoom);

      this.updateStatusQueue(this.idQueue, this.idEpisode);
    }
  }

  updateStatusQueue(idQueue: number, idEpisode: number) {
    this.isLoading = true;
    this.updateStatusQueueService.updateQueueToOldStatus(idQueue, idEpisode).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show("Error", response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
      }
    });
  }

  //Websocket
  startWebsocket() {
    if (!this.websocketAttendanceUtilService.isConnect())
      this.websocketAttendanceUtilService.connectwebsocketRoom(this.idRoom, this.idQueue, this.idEpisode, this, true, false, this.authService.getToken(), this.userLogin, this.userName);

    this.websocketAttendanceUtilService.setContext(this);
    this.websocketAttendanceUtilService.setFunctions(null, null, this.onRemoveAttendant);
  }

  repopulate() {
    this.repopulateHeader.emit()
  }

  setIsNext(event) {
    this.isNext = event;
  }

  onRemoveAttendant(thisParam, res: any) {
    thisParam.isAttendanceRemoval = false;

    if (res.userLogin != thisParam.userLogin) {
      thisParam.isAttendanceRemoval = true;

      thisParam.websocketAttendanceUtilService.clearSocket();
      let ref = thisParam.dialog.open(AttendanceOccupiedRemoveModalComponent, { data: { ...res } });
      ref.afterClosed().subscribe(() => {
        thisParam.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          thisParam.router.navigate(['/classification/classification-patient-list', { idSector: thisParam.idSector, idRoom: thisParam.idRoom }]));
      });
    }
  }
}