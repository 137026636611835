import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SolutionDispensationStruct } from 'src/app/shared/services/structs/orchestrator-patient/solution-dispensation.struct';

@Component({
  selector: 'app-solution-dispensation-accordion',
  templateUrl: './solution-dispensation-accordion.component.html',
  styleUrls: ['./solution-dispensation-accordion.component.css']
})
export class SolutionDispensationAccordionComponent implements OnInit {

  @Input() solutionDispensation: SolutionDispensationStruct;

  constructor(
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    
  }

  
}
