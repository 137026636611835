import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { AttendPatientOnlineComponent } from './pages/attend-patient-online/attend-patient-online.component';
import { AttendPatientComponent } from './pages/attend-patient/attend-patient.component';
import { MedicBedManagementComponent } from './pages/medic-bed-management/medic-bed-management.component';
import { MedicConfigListComponent } from './pages/medic-config-list/medic-config-list.component';
import { MedicConfigRegisterComponent } from './pages/medic-config-register/medic-config-register.component';
import { MedicPatientListComponent } from './pages/medic-patient-list/medic-patient-list.component';
import { MedicComponent } from './pages/medic/medic.component';
import { ProcedureListComponent } from './pages/procedure-list/procedure-list.component';
import { ProcedureRegisterComponent } from './pages/procedure-register/procedure-register.component';
import { AttendPatientOnlineAppointmentComponent } from './pages/attend-patient-online-appointment/attend-patient-online-appointment.component';
import { MedicalScheduleComponent } from './pages/medical-schedule/medical-schedule.component';
import { MedicFavoriteStandardPrescriptionComponent } from './pages/medic-favorite-prescription/medic-favorite-standard-prescription/medic-favorite-standard-prescription.component';
import { FavoriteStandardMedicineComponent } from './pages/medic-favorite-prescription/medic-favorite-standard-prescription/favorite-standard-medicine/favorite-standard-medicine.component';
import { MedicFavoriteExamRequestComponent } from './pages/medic-favorite-prescription/medic-favorite-exam-request/medic-favorite-exam-request.component';
import { MedicFavoriteExamRequestRegisterComponent } from './pages/medic-favorite-prescription/medic-favorite-exam-request/medic-favorite-exam-request-register/medic-favorite-exam-request-register.component';
import { MedicFavoriteNonStandardPrescriptionComponent } from './pages/medic-favorite-prescription/medic-favorite-non-standard-prescription/medic-favorite-non-standard-prescription.component';
import { FavoriteNonStandardMedicineComponent } from './pages/medic-favorite-prescription/medic-favorite-non-standard-prescription/favorite-non-standard-medicine/favorite-non-standard-medicine.component';
import { AihFillConfigListComponent } from './pages/aih-fill-config-list/aih-fill-config-list.component';
import { AihFillConfigRegisterComponent } from './pages/aih-fill-config-register/aih-fill-config-register.component';
import { AihEditorComponent } from './pages/aih-editor/aih-editor.component';
import { AihPendingListComponent } from './pages/aih-pending-list/aih-pending-list.component';
import { AihCompletedListComponent } from './pages/aih-completed-list/aih-completed-list.component';

const routes: Routes = [
  { path: 'medic', component: MedicComponent, canActivate: [AuthGuard] },
  { path: 'medic/medic-patient-list', component: MedicPatientListComponent, canActivate: [AuthGuard] },
  { path: 'medic/attend-patient', component: AttendPatientComponent, canActivate: [AuthGuard] },
  { path: 'medic/attend-patient-online', component: AttendPatientOnlineComponent, canActivate: [AuthGuard] },
  { path: 'medic/attend-patient-online-appointment', component: AttendPatientOnlineAppointmentComponent, canActivate: [AuthGuard] },
  { path: 'medic/medic-bed-management', component: MedicBedManagementComponent, canActivate: [AuthGuard] },
  { path: 'medic/medic-config', component: MedicConfigListComponent, canActivate: [AuthGuard] },
  { path: 'medic/medic-config/register', component: MedicConfigRegisterComponent, canActivate: [AuthGuard] },
  { path: 'medic/procedure', component: ProcedureListComponent, canActivate: [AuthGuard] },
  { path: 'medic/procedure/register', component: ProcedureRegisterComponent, canActivate: [AuthGuard] },
  { path: 'medic/medical-schedule', component: MedicalScheduleComponent, canActivate: [AuthGuard] },
  { path: 'medic/medic-favorite-standard-prescription', component: MedicFavoriteStandardPrescriptionComponent, canActivate: [AuthGuard] },
  { path: 'medic/favorite-standard-medicine', component: FavoriteStandardMedicineComponent, canActivate: [AuthGuard] },
  { path: 'medic/medic-favorite-non-standard-prescription', component: MedicFavoriteNonStandardPrescriptionComponent, canActivate: [AuthGuard] },
  { path: 'medic/favorite-non-standard-medicine', component: FavoriteNonStandardMedicineComponent, canActivate: [AuthGuard] },
  { path: 'medic/medic-favorite-exam-request', component: MedicFavoriteExamRequestComponent, canActivate: [AuthGuard] },
  { path: 'medic/medic-favorite-exam-request/register', component: MedicFavoriteExamRequestRegisterComponent, canActivate: [AuthGuard] },
  { path: 'medic/aih-fill-config', component: AihFillConfigListComponent, canActivate: [AuthGuard] },
  { path: 'medic/aih-fill-config/register', component: AihFillConfigRegisterComponent, canActivate: [AuthGuard] },
  { path: 'medic/aih-editor', component: AihEditorComponent, canActivate: [AuthGuard] },
  { path: 'medic/list-aih-pending', component: AihPendingListComponent, canActivate: [AuthGuard] },
  { path: 'medic/list-aih-completed', component: AihCompletedListComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MedicRoutingModule { }
