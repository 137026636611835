export enum SectionFillEnum {
    NewAttendance = 1,
    NonStandardPrescriptions = 2,
    ExamesRequests = 3,
    Procedures = 4,
    SickNote = 5,
    ProceduresPerformed = 6,
    AssistanceOutcome = 7,
    CareLine = 8,
    StandartPrescriptions = 13,
}