import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { ClinicalObservationResponse } from '../../responses/orchestrator-patient/clinical-observation.response';

@Injectable({
  providedIn: 'root'
})
export class ClinicalObservationPatientService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public get(idPatient: number): Observable<ClinicalObservationResponse>{
    let uri = `ClinicalObservation/idPatient/${idPatient}`;

    return this.httpClient.get<ClinicalObservationResponse>(environment.urlApiOrchestratorPatient + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
