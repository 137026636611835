import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { GetAllergyResponse } from '../../responses/medical-record/get-allergy.response';


@Injectable({
  providedIn: 'root'
})
export class AllergyService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getAllergiesById(allergies:string): Observable<GetAllergyResponse>{
    let uri = `GetAllbyId?jsonListId=${allergies}`
    return this.httpClient.get<GetAllergyResponse>(environment.urlApiMedicalRecord + uri)
    .pipe(
      catchError(this.handleError)
    )
  }
  
  public getAllAllergies(): Observable<GetAllergyResponse>{
    let uri = `Allergy?`
    return this.httpClient.get<GetAllergyResponse>(environment.urlApiMedicalRecord + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
