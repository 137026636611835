export class ReturnRestrictionsStruct {
    public idReturnRestrictions: number;
    public idProcedureHealthcare: number;
    public idProcedure: number;
    public number: number;
    public idTypePeriod: number;

    public periodName: string;
    public nameProcedure: string;

    public returnWithSameProfessional: boolean;
    public isDeleted: boolean;
}