<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-flow-modal-list">
            <h2>Cancelar o cadastro?</h2>
        </div>

        <div class="body-flow-modal-list">
            Tem certeza que deseja cancelar essa operação. Todas as informações inseridas serão perdidas.
        </div>
        <div class="footer-flow-modal-list">
            <button mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Sim, cancelar!</button>
            <button mat-flat-button color="primary" class="btn-block" (click)="clickStay()">Não, desejo continuar!</button>
        </div>
    </div>
</div>