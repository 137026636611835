<section>
    <div class="group-navigation">
        <div class="btn-group">
            <div
                class="btn btn-outline-secondary"
                mwlCalendarToday
                [(viewDate)]="viewDate"
                (click)="addNewEvents(true, false)">
                Hoje
            </div>
            <div
                class="btn btn-primary btn-back"
                mwlCalendarPreviousView
                [view]="view"
                [(viewDate)]="viewDate"
                (click)="addNewEvents(true, false)"> 
                <               
            </div>
            <div
                class="btn btn-primary btn-next"
                mwlCalendarNextView
                [view]="view"
                [(viewDate)]="viewDate"
                (click)="addNewEvents(false, true)">
                >
            </div>
        </div>
    </div>
</section>
<calendar-scheduler-view 
    [viewDays]="viewDays"
    [viewDate]="viewDate"
    [events]="events"
    [locale]="locale"
    [responsive]="true"
    [weekStartsOn]="weekStartsOn"
    [excludeDays]="excludeDays"
    [startsWithToday]="startsWithToday"
    [hourSegments]="hourSegments"
    [dayStartHour]="dayStartHour"
    [dayEndHour]="dayEndHour"
    [dayModifier]="dayModifier"
    [hourModifier]="hourModifier"
    [segmentModifier]="segmentModifier"
    [eventModifier]="eventModifier"
    [showEventActions]="false"
    [showSegmentHour]="false"
    [zoomEventOnHover]="true"
    [refresh]="refresh"
    (eventClicked)="eventClicked($event.event)">
</calendar-scheduler-view>