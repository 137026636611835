import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MedicineGroupService } from 'src/app/shared/services/API/pharmacy/medicine-group.service';
import { MedicineSubgroupService } from 'src/app/shared/services/API/pharmacy/medicine-subgroup.service';
import { MaskService } from 'src/app/shared/services/mask.service';
import { MedicineSubgroupRequest } from 'src/app/shared/services/requests/pharmacy/medicine-subgroup.request';
import { MedicineGroupTypeStruct } from 'src/app/shared/services/structs/pharmacy/medicine-group-type.struct';

@Component({
  selector: 'app-medicine-subgroup-register',
  templateUrl: './medicine-subgroup-register.component.html',
  styleUrls: ['./medicine-subgroup-register.component.css']
})
export class MedicineSubgroupRegisterComponent implements OnInit {

  constructor(
    private formBuilder: UntypedFormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private medicineSubgroupService: MedicineSubgroupService,
    private medicineGroupService: MedicineGroupService,
    ) { }
  
  public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.pharmacy;
  public menuFunctionalityEnum:MenuFunctionalityEnum = MenuFunctionalityEnum.pharmacy_medicine_subgroup;
  
  public model: UntypedFormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;

  public idMedicineSubgroup: number;
  public listMedicineGroupType: MedicineGroupTypeStruct[];
  public medicineSubgroupRequest: MedicineSubgroupRequest;

  
  ngOnInit(): void {
    this.isLoading = true;    

    this.model = this.formBuilder.group({
      name: ['', [Validators.required]],
      description: [''],
      idMedicineGroup: ['', [Validators.required]],
    });

    this.medicineSubgroupRequest = new MedicineSubgroupRequest();

    this.populateMedicineGroupSelect();


    this.idMedicineSubgroup == null;
    this.isUpdate == false;

    if(this.activatedRoute.snapshot.paramMap.get('idMedicineSubgroup'))
      this.idMedicineSubgroup = parseInt(this.activatedRoute.snapshot.paramMap.get('idMedicineSubgroup'));
      
    if(this.idMedicineSubgroup != null){
      this.isFirstLoading = true;
      this.isUpdate = true;
      this.populateMedicineSubgroupData();
    }
  }

  submit(){
    if(this.isLoading){
      return;
    }

    if(!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }
    this.isLoading = true;   
    
    this.medicineSubgroupRequest.medicineSubgroupDescription = this.model.get('description').value;
    this.medicineSubgroupRequest.idMedicineGroup = parseInt(this.model.get('idMedicineGroup').value);
    this.medicineSubgroupRequest.medicineSubgroupName = this.model.get('name').value;
    
    if(this.isUpdate) {
      this.updateMedicineSubgroup();
    }
    else {
      this.createMedicineSubgroup();
    }
  }

  populateMedicineSubgroupData(){
    this.medicineSubgroupService.getMedicineSubgroup(this.idMedicineSubgroup).subscribe((response)=>{
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.model.get('name').setValue(response.medicineSubgroup.medicineSubgroupName);
      this.model.get('idMedicineGroup').setValue(response.medicineSubgroup.idMedicineGroup.toString());
      this.model.get('description').setValue(response.medicineSubgroup.medicineSubgroupDescription);
      
      this.isLoading = false;    
      this.isFirstLoading = false;
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  populateMedicineGroupSelect(){
    this.medicineGroupService.listAllMedicineGroup().subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      
      this.listMedicineGroupType = response.listMedicineGroupTypeStruct;
      this.isLoading = false;
    },
    (error)=>{
      console.log(error)    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }
  
  updateMedicineSubgroup(){
    this.medicineSubgroupService.updateMedicineSubgroup(this.idMedicineSubgroup, this.medicineSubgroupRequest).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false; 
      
      this.router.navigate(['/pharmacy/medicine-subgroup']);
      
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }
  
  createMedicineSubgroup(){
    this.medicineSubgroupService.createMedicineSubgroup(this.medicineSubgroupRequest).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;    
      
      this.router.navigate(['/pharmacy/medicine-subgroup']);
      
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }
}
