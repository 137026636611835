export enum MenuFunctionalityEnum {
    master_user = 1,
    master_profile = 2,
    master_service = 3,
    master_sector = 4,
    master_room = 5,
    master_totem = 6,
    master_flow = 7,
    master_panel = 8,
    generic_call = 15,
    pharmacy_medicine_type = 10,
    pharmacy_medicine_group = 11,
    pharmacy_medicine_subgroup = 12,
    pharmacy_medicine = 13,
    master_front_desk_config = 16,
    frontdesk_patient_register = 17,
    front_desk_patient_list = 18,
    master_exam = 21,
    medic_patient_list = 22,
    master_exam_type = 23,
    master_health_unit = 24,
    classification_pre_registration = 9,
    classification_config_protocol_sector = 27,
    sadt_patient_list = 28,
    classification_patient_list = 29,
    integration_key_list = 31,
    audit_list = 30,
    integration_log = 33,
    integration_iframe = 80,
    individual_audit_report = 35,
    divergent_audit_report = 36,
    observation_bed = 37,
    observation_patientList = 38,
    observation_bedManagement = 39,
    medic_bedManagement = 40,
    patient_history_list = 42,
    webhook_list = 41,
    remoteMonitoring_syncMeasurer = 43,
    form_episode_list = 45,
    form_equipment = 46,
    form_medical_schedule = 47,
    billing_professionalProductionAttendance = 50,
    audit_monthly_report = 48,
    master_care_line = 52,
    remote_monitoring_patient_list = 53,
    bi_lista_relatórios = 55,
    pharmacy_location = 56,
    pharmacy_storage = 58,
    pharmacy_movement = 60,
    pharmacy_kit = 62,
    pharmacy_item = 57,
    pharmacy_printing = 76,
    bi_biPanel = 59,
    pharmacy_prescription = 61,
    pharmacy_return_medicine_item = 63,
    master_batch = 64,
    telephone_classification = 65,
    data_source = 67,
    observation_config = 68,
    medic_config = 69,
    dashboard_dashboards = 71,
    medic_procedure = 73,
    billing_patientProcedureMade = 72,
    classification_printing = 75,
    self_triage_selfTriageConfig = 77,
    telemedicine_config = 78,
    flow_management_flow_management = 79,
    flow_management_kpi_config = 105,
    flow_management_lean_panel = 107,
    master_meta_data = 81,
    multi_professional_config = 82,
    multi_professional_patient_list = 83,
    master_gamification = 84,
    master_gamification_register = 85,
    self_triage_button_config = 86,
    master_billing_health_unit = 88,
    master_billing_alert = 89,
    bi_send_report = 90,
    observation_bed_hygienization = 87,
    observation_hygienization_totem = 91,
    care_line_monitoring_register = 92,
    nps = 93,
    master_totem_nps = 94,
    audit_config = 95,
    result_nps = 96,
    schedule_schedule = 98,
    digital_prompt_service_config = 99,
    medical_specialty = 100,
    medical_schedule = 104,
    virtual_triage_patient_list = 106,
    patient_list = 108,
    lean_config = 109,
    aih_fill_config = 110,
    master_alert_policy = 111,
    healthcare_agreements_and_plans = 115,
    billing_procedure = 116,
    grievance_notification = 114,
    aih_list_completed = 112,
    aih_list_pending = 113,    
    tiss_guide_report_list = 117,
    tiss_batch_control = 118,
    virtual_triage_config = 119,
    text_template = 120,
    master_changelog = 121
}