import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { ListPriorityResponse } from '../../responses/totem/list-priority.response';



@Injectable({
  providedIn: 'root'
})
export class ClientPriorityService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listPriority(pin: string, idTotem: number): Observable<ListPriorityResponse> {
    
    let uri = `ClientPriority/idTotem/${idTotem}/pin/${pin}`
    
    let requestOptions = {
      headers: new HttpHeaders({
        'Authorization': environment.authorizationHash
      }),
    };
    
    return this.httpClient.get<ListPriorityResponse>(environment.urlApiTotem + uri, requestOptions)
    .pipe(
      catchError(this.handleError)
    )
  }
}
