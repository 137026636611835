<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <h1 class="h1">Lista de Relatórios</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <div class="row">
                <h2 class="h2">Relatórios</h2>
                <div class="col-12 col-sm-6 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-icon matPrefix class="my-icon">search</mat-icon>
                        <mat-label>Pesquisar</mat-label>
                        <input (keyup)="onKeySearch($event)" matInput type="text">
                    </mat-form-field>
                </div>
                <div *ngIf="isMaster" class="col-12 col-sm-6 col-md-4">
                    <app-select (selectedValue)="selectChange($event)" [multiple]="true" [list]="listHealthUnit"
                        [idField]="'idHealthUnit'" [nameField]="'healthUnitName'" [preSelectedlist]="preselectedList"
                        [labelField]="fieldLabel">
                    </app-select>
                </div>
                <div *ngIf="isMaster" class="col-2 col-sm-2 col-md-2 favorite-container">
                    <mat-icon id="favorite-star" *ngIf="!isFavorite" (click)="openFavoriteNameModal()"
                        matTooltip="Adicionar aos favoritos"
                        class="material-symbols-outlined favorite-btn">star</mat-icon>
                    <mat-icon id="favorite-star" *ngIf="isFavorite" class="favorite-btn">star</mat-icon>
                    <button (click)="loadFavorite()" mat-flat-button color="primary" class="btn-block">
                        <span *ngIf="!isLoading">Meus Favoritos</span>
                        <mat-icon>star</mat-icon>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                    </button>
                </div>
            </div>
            <div *ngIf="filterHealthUnitReport != null  && filterHealthUnitReport.length > 0">
                <div class="row" *ngFor="let healthReports of filterHealthUnitReport">
                    <div *ngIf="healthReports.reports != null && healthReports.reports.length > 0" class="col-12">
                        <div class="title-header">
                            <h1 class="h1">{{healthReports.healthUnitName}}</h1>
                        </div>
                        <div class="card-list">
                            <div class="row">
                                <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of healthReports.reports">
                                    <div class="card-item">
                                        <div class="infos">
                                            <span class="primary">{{item.reportName}}</span>
                                            <span class="secundary last-item">{{item.lastAccessDate | date: 'dd/MMM/yyyy
                                                HH:mm:ss'}}</span>
                                        </div>
                                        <div class="last-card-items">
                                            <div class="actions">
                                                <div class="separation-icon">
                                                    <a>
                                                        <mat-icon aria-hidden="false" aria-label="Visualizar"
                                                            [routerLink]="['/bi/details', {idReportFunctionHealthUnit: item.idReportFunctionHealthUnit}]">
                                                            visibility
                                                        </mat-icon>
                                                    </a>
                                                </div>
                                                <div class="separation-icon-name"
                                                    [routerLink]="['/bi/details', {idReportFunctionHealthUnit: item.idReportFunctionHealthUnit}]">
                                                    Visualizar
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <app-empty-list
                *ngIf="filterHealthUnitReport != undefined && filterHealthUnitReport.length == 0 && !isFirstLoading">
            </app-empty-list>
        </div>
        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>
</div>