import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ClassificationData, UtilsClassificationService } from 'src/app/classification/utils.service';
import { DeviceTypeEnum } from 'src/app/shared/enum/device-type.enum';
import { DiscriminatorTypeEnum } from 'src/app/shared/enum/discriminator-type.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MeasurerService } from 'src/app/shared/services/API/measurer/measurer.service';
import { DiscriminatorModel } from 'src/app/shared/services/models/risk-classification/discriminator.model';
import { PriorityColorModel } from 'src/app/shared/services/models/risk-classification/priority-color.model';
import { DiscriminatorResponse } from 'src/app/shared/services/responses/risk-classification/discriminator.response';
import { DiscriminatorStruct } from 'src/app/shared/services/structs/risk-classification/discriminator.struct';
import { environment } from 'src/environments/environment';
import { GreaterGravityModalComponent } from '../protocol-v2/greater-gravity-modal/greater-gravity-modal.component';
import { FlowChartStructure } from '../protocol-v2/protocol-v2.component';
import { UtilsMeasurerRemoteService } from '../protocol-v2/utils-measurer-remote.service';
import { UtilsMeasurerService } from '../protocol-v2/utils-measurer.service';
import { SelfTriageResumeModalComponent } from '../self-triage-resume-modal/self-triage-resume-modal.component';

@Component({
  selector: 'app-protocol-v1',
  templateUrl: './protocol-v1.component.html',
  styleUrls: ['./protocol-v1.component.css']
})
export class ProtocolV1Component implements OnInit, OnDestroy {

  constructor(private measurer: MeasurerService,
    private utilService: UtilsClassificationService,
    private alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private measurerService: UtilsMeasurerService,
    private router: Router,
    private measurerRemote: UtilsMeasurerRemoteService) { }

  @Input() discriminatorResponse: DiscriminatorResponse;
  @Input() discriminators: any[] = [];
  @Output() selectedValue = new EventEmitter<any>();
  @Output() previousPage = new EventEmitter<any>();

  public list: DiscriminatorStruct[];
  public discriminatorsPersist: any[][] = [];
  public firstColumn: FlowChartStructure[];
  public secondColumn: FlowChartStructure[];
  public priorityColor: PriorityColorModel[];
  public currentIndexPages: number;
  public currentPage: number = 0;
  public isSelfTriage: boolean;

  private data: ClassificationData;
  private showList: DiscriminatorStruct[] = [];
  public model: UntypedFormGroup;

  ngOnInit(): void {
    this.data = this.utilService.getClassificationData();
    this.model = this.formBuilder.group({});
    this.data = this.utilService.getClassificationData();
    this.list = this.discriminatorResponse.discriminator;

    this.list.forEach((c, index) => {
      if (c.idDiscriminatorType != DiscriminatorTypeEnum.Numerico
        && c.idDiscriminatorType != DiscriminatorTypeEnum.Boleano) {
        if (!Array.isArray(this.discriminatorsPersist[c.idDiscriminatorType])) {
          this.discriminatorsPersist[c.idDiscriminatorType] = []
          this.discriminatorsPersist[c.idDiscriminatorType].push(index);
        } else {
          this.discriminatorsPersist[c.idDiscriminatorType].push(index);
        }
      }
      if (Array.isArray(this.data.discriminatorAllValues) && this.data.discriminatorAllValues[index] && this.data.discriminatorAllValues[index].hasValue) {
        c.value = this.data.discriminatorAllValues[index].value;
        c.type = this.data.discriminatorAllValues[index].type;
        c.state = this.data.discriminatorAllValues[index].state;
        c.hasValue = this.data.discriminatorAllValues[index].hasValue;
      } else if (this.discriminators && Array.isArray(this.discriminators)) {

        if (c.idDiscriminatorType == DiscriminatorTypeEnum.PressaoArterial && this.discriminators[DiscriminatorTypeEnum.PressaoArterial]) {
          c.value = this.discriminators[DiscriminatorTypeEnum.PressaoArterial];
          c.hasValue = true;
        } else if (c.idDiscriminatorType == DiscriminatorTypeEnum.FrequenciaCardiaca && this.discriminators[DiscriminatorTypeEnum.FrequenciaCardiaca]) {
          c.value = this.discriminators[DiscriminatorTypeEnum.FrequenciaCardiaca];
        } else if ((c.idDiscriminatorType == DiscriminatorTypeEnum.Glicemia || c.idDiscriminatorType == DiscriminatorTypeEnum.GlicemiaCapilar) && this.discriminators[DiscriminatorTypeEnum.Glicemia]) {
          c.value = this.discriminators[DiscriminatorTypeEnum.Glicemia];
          c.hasValue = c.idDiscriminatorType == DiscriminatorTypeEnum.Glicemia || (c.idDiscriminatorType == DiscriminatorTypeEnum.GlicemiaCapilar && c.value <= c.maxValueAllowed);
        } else if (c.idDiscriminatorType == DiscriminatorTypeEnum.Saturacao && this.discriminators[DiscriminatorTypeEnum.Saturacao]) {
          c.value = this.discriminators[DiscriminatorTypeEnum.Saturacao];
        } else if (c.idDiscriminatorType == DiscriminatorTypeEnum.Temperatura && this.discriminators[DiscriminatorTypeEnum.Temperatura]) {
          c.value = this.discriminators[DiscriminatorTypeEnum.Temperatura];
          c.hasValue = true;
        }
      }
      this.model.addControl("radio-" + c.idDiscriminator.toString(), this.formBuilder.control({ value: '' }));
      if (c.idDiscriminatorType == DiscriminatorTypeEnum.Boleano.valueOf()) {
        c.hasValue = true;
      }
    });

    this.priorityColor = this.discriminatorResponse.priorityColor;
    this.currentIndexPages = Math.ceil(this.list.length / 16);
    this.calculateColumnsAndNext();
    this.data.discriminatorsPersist = this.discriminatorsPersist;
    this.utilService.updateClassificationData(this.data);
    if (this.data.idDeviceType == DeviceTypeEnum.Trius || this.data.idDeviceType == DeviceTypeEnum.Berco) {
      this.measurerService.setIndex(-1);
      this.measurerService.createReference(this.discriminators, this.discriminatorsPersist, this.list, this.data.deviceThermometer, this.data.deviceGlucometer, this.data.deviceOximeter, this.data.devicePressure);
      this.measurerService.restartMeasurer();
    } else if (this.data.idDeviceType == DeviceTypeEnum.Remoto) {
      this.measurerRemote.isOnline(this.data.device.idDevice);
      this.measurerRemote.createReference(this.discriminators, this.discriminatorsPersist, this.list);
      this.measurerRemote.startWebsocket(this.data.device.deviceHash);
    }


  }

  calculateColumnsAndNext(openModalValidate: boolean = true) {
    this.showList = [...this.list.slice(0 + (16 * this.currentPage), 16 + (16 * this.currentPage))];
    this.firstColumn = [];
    this.secondColumn = [];
    this.showList.slice(0, 8).forEach((value, index) => {
      this.firstColumn[index] = new FlowChartStructure();
      this.firstColumn[index].column = value;
      this.firstColumn[index].forceOpenModal = false
      if (!this.data.isPunctuationSystem) {
        if (index == 0 || (index - 1 >= 0 && this.firstColumn[index - 1].column.idPriorityColor != this.firstColumn[index].column.idPriorityColor)) {
          this.firstColumn[index].nameAndColor = this.priorityColor.find(c => c.idPriorityColor == value.idPriorityColor);
        }
      }
    });
    this.showList.slice(8, 16).forEach((value, index) => {
      this.secondColumn[index] = new FlowChartStructure();
      this.secondColumn[index].column = value;
      this.secondColumn[index].forceOpenModal = false
      if (!this.data.isPunctuationSystem) {
        if ((index == 0 && value.idPriorityColor != this.firstColumn[7].column.idPriorityColor) || (index - 1 >= 0 && this.secondColumn[index - 1].column.idPriorityColor != this.secondColumn[index].column.idPriorityColor)) {
          this.secondColumn[index].nameAndColor = this.priorityColor.find(c => c.idPriorityColor == value.idPriorityColor);
        }
      }
    });
  }

  next() {
    let actualPage = this.list.slice(0 + (16 * this.currentPage), 16 + (16 * this.currentPage))
    if (!actualPage.some(c => !c.hasValue && c.isRequired)) {
      this.currentPage += 1;
      this.calculateColumnsAndNext();
    } else {
      this.alertService.show("Erro", "Preencha todos os valores clinicos antes de passar para próxima tela!", AlertType.error);
    }
  }

  getNextColor() {
    let next = this.list[16 + (16 * this.currentPage)];
    return this.priorityColor.find(c => c.idPriorityColor == next.idPriorityColor).coloerHex;
  }

  getNextColorName() {
    let next = this.list[16 + (16 * this.currentPage)];
    return this.priorityColor.find(c => c.idPriorityColor == next.idPriorityColor).priorityColorName;
  }

  previous() {
    this.currentPage -= 1;
    this.calculateColumnsAndNext();
  }

  getPreviousColor() {

    let Previous = this.list[16 + (16 * (this.currentPage - 1))];
    return this.priorityColor.find(c => c.idPriorityColor == Previous.idPriorityColor).coloerHex;
  }

  getPreviousColorName() {
    let Previous = this.list[16 + (16 * (this.currentPage - 1))];
    return this.priorityColor.find(c => c.idPriorityColor == Previous.idPriorityColor).priorityColorName;
  }

  setDiscriminatorsValues(discriminatorsValues: any) {
    this.discriminators[this.list[discriminatorsValues.index].idDiscriminatorType] = this.list[discriminatorsValues.index].value;
    this.propagateValue(discriminatorsValues.index);
    if (discriminatorsValues.select) {
      let result = this.list.some((c, index) => !c.hasValue && index <= discriminatorsValues.index);
      if (result) {
        this.alertService.show("Aviso", "Valor cliníco alterado, mas é necessário preencher os valores clínicos anteriores!", AlertType.warning);
        return;
      } else {
        this.selectedValue.emit({ item: this.list[discriminatorsValues.index], discriminatorsValues: this.list });
      }
    } else {
      this.list.forEach(
        (c) => {
          if (c.idDiscriminatorType != DiscriminatorTypeEnum.Boleano) {
            if (this.utilService.verifySelectDiscriminator(c, c.value, c.type, c.state)) {
              this.selectedValue.emit({ item: c, discriminatorsValues: this.list });
              return;
            }
          }
        }
      )

    }
  }

  propagateValue(index: number) {
    let openModalGreaterGravity: boolean = false;
    let discriminatorGravity: DiscriminatorStruct;
    if (this.list[index].idDiscriminatorType != DiscriminatorTypeEnum.Numerico &&
      this.list[index].idDiscriminatorType != DiscriminatorTypeEnum.Boleano) {
      this.list.forEach((c, i) => {
        if (index != i &&
          (this.list[index].idDiscriminatorType == c.idDiscriminatorType ||
            (this.list[index].idDiscriminatorType == DiscriminatorTypeEnum.Glicemia && c.idDiscriminatorType == DiscriminatorTypeEnum.GlicemiaCapilar) ||
            (this.list[index].idDiscriminatorType == DiscriminatorTypeEnum.GlicemiaCapilar && c.idDiscriminatorType == DiscriminatorTypeEnum.Glicemia))) {
          c.value = this.list[index].value;
          c.state = this.list[index].state;
          c.type = this.list[index].type;
          c.hasValue = true;
          if (i < index) {
            if (!openModalGreaterGravity) {
              discriminatorGravity = this.list[i];
              openModalGreaterGravity = this.utilService.verifySelectDiscriminator(this.list[i], this.list[i].value, this.list[i].type, this.list[i].state);
            }
          }
        }
      });
    }
    if (openModalGreaterGravity) {
      this.openModalGreaterGravity(index, discriminatorGravity);
      return false;
    }
  }

  openModalGreaterGravity(index: number, discriminatorGravity: DiscriminatorStruct) {
    let data: any = {};
    data.discriminatorNowName = this.list[index].discriminatorName;
    data.discriminatorName = discriminatorGravity.discriminatorName;
    data.priorityName = this.priorityColor.find((c) => c.idPriorityColor == discriminatorGravity.idPriorityColor).priorityColorName;
    const dialogRef = this.dialog.open(GreaterGravityModalComponent, { data });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.response) {
        this.selectedValue.emit({ item: discriminatorGravity, discriminatorsValues: this.list });
      } else {
        this.alertService.show('Aviso', "É necessário reavaliar o valor do discriminador " + this.list[index].discriminatorName, AlertType.warning);
        this.forceToOpenModal(this.list[index], true);
      }
    });
  }

  forceToOpenModal(item: DiscriminatorModel, withValue: boolean = false): void {

    let discriminator = this.list.find(c => c.idDiscriminator == item.idDiscriminator);

    if (!discriminator.hasValue || withValue) {
      let indexFirstColumn = this.firstColumn.findIndex(c => c.column.idDiscriminator == item.idDiscriminator);
      if (indexFirstColumn >= 0) {
        this.firstColumn[indexFirstColumn].forceOpenModal = !this.firstColumn[indexFirstColumn].forceOpenModal;
      } else {
        let indexSecondColumn = this.secondColumn.findIndex(c => c.column.idDiscriminator == item.idDiscriminator);
        if (indexSecondColumn >= 0) {
          this.secondColumn[indexSecondColumn].forceOpenModal = !this.secondColumn[indexSecondColumn].forceOpenModal;
        }
      }
    }
  }

  setValue(item: DiscriminatorStruct) {
    let indexSelected = this.list.findIndex(c => c.idDiscriminator == item.idDiscriminator);
    let result = this.list.some((c, index) => !c.hasValue && c.isRequired && index < indexSelected)
    if (!this.list[indexSelected].hasValue) {
      this.model.get("radio-" + item.idDiscriminator).setValue(false);
      let indexFirstColumn = this.firstColumn.findIndex(c => c.column.idDiscriminator == item.idDiscriminator);
      if (indexFirstColumn >= 0) {
        this.firstColumn[indexFirstColumn].forceOpenModal = true;
        return;
      } else {
        let indexSecondColumn = this.secondColumn.findIndex(c => c.column.idDiscriminator == item.idDiscriminator);
        if (indexSecondColumn >= 0) {
          this.secondColumn[indexSecondColumn].forceOpenModal = true;
          return;
        }
      }
    } else if (result) {
      this.model.get("radio-" + item.idDiscriminator).setValue(false);
      this.alertService.show("Erro", "Preencha todos os valores clinicos anteriores, antes de selecionar este discriminador!", AlertType.error);
      return;
    }
    if (this.list[indexSelected].idDiscriminatorType != DiscriminatorTypeEnum.Numerico &&
      this.list[indexSelected].idDiscriminatorType != DiscriminatorTypeEnum.Boleano) {
      if (!this.utilService.verifySelectDiscriminator(item, this.list[indexSelected].value, this.list[indexSelected].type, this.list[indexSelected].state)) {
        this.model.get("radio-" + item.idDiscriminator).setValue(false);
        this.alertService.show('Aviso', "O valor inserido não se enquadra no parâmetro definido para " + this.list[indexSelected].discriminatorName, AlertType.warning);
        return;
      }
    }
    this.selectedValue.emit({ item, discriminatorsValues: this.list });
  }

  previousFirstPage() {
    var data = this.utilService.getClassificationData();
    data.isPatientWhite = false;
    data.discriminatorAllValues = [];
    data.discriminatorsPersist = [];
    data.idFlowchart = null;
    this.utilService.updateClassificationData(data);
    this.previousPage.emit(true);
  }

  
  ngOnDestroy() {
    if (this.data.idDeviceType == DeviceTypeEnum.Trius || this.data.idDeviceType == DeviceTypeEnum.Berco || this.data.idDeviceType == DeviceTypeEnum.Bluetooth) {
      this.measurerService.setIndex(-1);
    } else if (this.data.idDeviceType == DeviceTypeEnum.Remoto) {
      this.measurerRemote.clearAllInterval();
    }
  }
}
