import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsClassificationService } from 'src/app/classification/utils.service';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ProtocolSectorService } from 'src/app/shared/services/API/risk-classification/protocol-sector.service';
import { ProtocolModel } from 'src/app/shared/services/models/risk-classification/protocol.model';

@Component({
  selector: 'app-protocol-select',
  templateUrl: './protocol-select.component.html',
  styleUrls: ['./protocol-select.component.css']
})
export class ProtocolSelectComponent implements OnInit {

  constructor(private protocolSectorService: ProtocolSectorService,
    private alertService: AlertService,
    public router: Router,
    private utilService: UtilsClassificationService
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.digital_prompt_service;

  @Output() next = new EventEmitter<any>();

  public listProtocol: ProtocolModel[] = [];
  public matrixProtocol: any[] = [];
  public isFirstLoading: boolean = true;
  public idEpisode: number;
  public idSector: number;
  public idRoom: number;
  private isTelephoneClassification: boolean = true;

  ngOnInit(): void {
    let data = this.utilService.getClassificationData();
    this.idEpisode = data.idEpisode;
    this.idSector = data.idSector ? data.idSector : 0;
    this.idRoom = data.idRoom;
    this.getAllProtocols();
  }

  getAllProtocols() {
    this.protocolSectorService.getProtocolBySector(this.idSector, this.isTelephoneClassification, []).subscribe({
      next: (response) => {
        this.isFirstLoading = false;
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        let i = 0;

        response.listProtocolSectorStruct[0].listProtocol.forEach((element, index) => {
          this.listProtocol.push(element);
          if ((index + 1) % 4 == 0) {
            i++;
            this.matrixProtocol.push(this.listProtocol);
            this.listProtocol = [];
          }
          else if ((index + 1) % 4 != 0 && index + 1 == response.listProtocolSectorStruct[0].listProtocol.length)
            this.matrixProtocol.push(this.listProtocol);
        });

        this.utilService.updateDisableShowReport(response.listProtocolSectorStruct[0].disableShowReport);
      }
    });
  }

  selectProtocol(idProtocol: number) {
    let values = this.utilService.getClassificationData();
    values.idProtocol = idProtocol;

    let protocol = new ProtocolModel;
    for (let i = 0; i < this.matrixProtocol.length; i++) {
      let protocolSearch = this.matrixProtocol[i].find(x => x.idProtocol == idProtocol)
      if (protocolSearch != null) {
        protocol = protocolSearch;
        break;
      }
    }

    values.protocolName = protocol.protocolName;
    values.outOfOrder = protocol.outOfOrder;

    this.utilService.updateClassificationData(values);
    this.next.emit();
  }
}