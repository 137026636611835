import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { FormShiftService } from 'src/app/shared/services/API/form/form-shift.service';
import { MedicalScheduleService } from 'src/app/shared/services/API/form/medical-schedule.service';
import { CboService } from 'src/app/shared/services/API/user/cbo.service';
import { GetAllUserEvenDeletedService } from 'src/app/shared/services/API/user/get-all-user-even-deleted.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { DailyMedicalSchedule } from 'src/app/shared/services/models/form/daily-medical-schedule';
import { ShiftModel } from 'src/app/shared/services/models/form/shift.model';
import { CboModel } from 'src/app/shared/services/models/user/cbo.model';
import { MedicalScheduleRequest } from 'src/app/shared/services/requests/form/medical-schedule.request';
import { ListAllUserStruct } from 'src/app/shared/services/structs/user/list-all-user.struct';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-form-medical-schedule-register',
  templateUrl: './form-medical-schedule-register.component.html',
  styleUrls: ['./form-medical-schedule-register.component.css']
})
export class FormMedicalScheduleRegisterComponent implements OnInit {

  constructor(private formBuilder: UntypedFormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private medicalScheduleService: MedicalScheduleService,
    private cboService: CboService,
    private shiftService: FormShiftService,
    private getAllUserEvenDeletedService: GetAllUserEvenDeletedService,
    private dialog: MatDialog,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.form;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.form_medical_schedule;

  public model: UntypedFormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;
  public actualServiceModule: number;
  public masks: Masks;
  public idMedicalSchedule: number;

  public listUser: ListAllUserStruct[];
  public listShift: ShiftModel[];
  public listDailyMedicalSchedule: DailyMedicalSchedule[];
  public medicalScheduleRequest: MedicalScheduleRequest;
  public urlListCbo: string;
  public preSelectCbo: any[] = [];

  public idUser: number;
  public totalPeriodValue: number;
  public startDate: string;
  public endDate: string;
  public idShift: number;
  public startHour: string;
  public startMinute: string;
  public endHour: string;
  public endMinute: string;
  public firstChildGroup: [];

  ngOnInit(): void {
    this.isLoading = false;
    this.urlListCbo = environment.urlApiUser + "Cbo";

    this.model = this.formBuilder.group({
      idUser: [null, Validators.required],
      totalPeriodValue: [],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      idShift: ['', Validators.required],
      startHour: [, [Validators.required, Validators.max(23), Validators.min(0)]],
      startMinute: [, [Validators.required, Validators.max(60), Validators.min(0)]],
      endHour: [, [Validators.required, Validators.max(23), Validators.min(0)]],
      endMinute: [, [Validators.required, Validators.max(60), Validators.min(0)]],
      firstChildGroup: this.formBuilder.group({
        idCbo: [, Validators.required],
      }),
      formStepperList: this.formBuilder.array([]),
    });

    this.masks = this.maskService.getMasks();

    this.idMedicalSchedule == null;
    this.isUpdate == false;

    if (this.activatedRoute.snapshot.paramMap.get('idMedicalSchedule'))
      this.idMedicalSchedule = parseInt(this.activatedRoute.snapshot.paramMap.get('idMedicalSchedule'));
    this.populateUserSelect();
    this.model.get('startHour').disable();
    this.model.get('startMinute').disable();
    this.model.get('endHour').disable();
    this.model.get('endMinute').disable();

    if (this.idMedicalSchedule != null) {
      this.isFirstLoading = true;
      this.isUpdate = true;
      this.populateMedicalScheduleData();
    }
  }

  submit() {
    if (this.isLoading) {
      return;
    }

    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      // this.alertService.show('Aviso', "Verifique se todos os meses foram preenchidos corretamente.", AlertType.warning);
      return;
    }

    this.isLoading = true;

    this.medicalScheduleRequest = new MedicalScheduleRequest();
    this.medicalScheduleRequest.idUserResponsible = this.model.get('idUser').value;
    this.medicalScheduleRequest.startDatetime = this.model.get('startDate').value;
    this.medicalScheduleRequest.endDatetime = this.model.get('endDate').value;
    this.medicalScheduleRequest.idCbo = this.model.get('firstChildGroup').get('idCbo').value;
    this.medicalScheduleRequest.idShift = this.model.get('idShift').value;
    this.medicalScheduleRequest.startPeriodTime = new Date(Date.UTC(1, 0, 1, this.model.get('startHour').value, this.model.get('startMinute').value, 1, 1));
    this.medicalScheduleRequest.endPeriodTime = new Date(Date.UTC(1, 0, 1, this.model.get('endHour').value, this.model.get('endMinute').value, 1, 1));

    this.model.get('formStepperList').value.forEach((x) => {
      x.listDailyMedicalSchedule.forEach((y) => {
        let medicalSchedule = new DailyMedicalSchedule();
        medicalSchedule.actualAmountPredict = y.actualAmountPredict;
        medicalSchedule.predictedAmount = y.predictedAmount;
        medicalSchedule.dayDate = y.newDate;
        this.medicalScheduleRequest.listDailyMedicalSchedule.push(medicalSchedule);
      });
    });
    if (this.isUpdate)
      this.updateMedicalSchedule();
    else
      this.createMedicalSchedule();
  }

  populateMedicalScheduleData() {
    this.medicalScheduleService.getMedicalSchedule(this.idMedicalSchedule).subscribe({
      next:(response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
  
        this.listDailyMedicalSchedule = response.listDailyMedicalSchedule.reduce((acc, cur) => {
          let medicalSchedule = new DailyMedicalSchedule();
          medicalSchedule.actualAmountPredict = cur.actualAmountPredict;
          medicalSchedule.predictedAmount = cur.predictedAmount;
          let dayDate = cur.dayDate.toString().split('T')[0];
          let dayDateF = new Date(parseInt(dayDate.split("-")[0]), parseInt(dayDate.split("-")[1]) - 1, parseInt(dayDate.split("-")[2]));
          medicalSchedule.dayDate = dayDateF;
          acc.push(medicalSchedule);
          return acc;
        }, []);
        this.model.get('totalPeriodValue').setValue(response.totalPeriodValue);
        this.model.get('idUser').setValue(response.medicalSchedule.idUserResponsible);
        let startDatetime = response.medicalSchedule.startDatetime.toString().split('T')[0];
        let endDatetime = response.medicalSchedule.endDatetime.toString().split('T')[0];
        let startDate = new Date(parseInt(startDatetime.split("-")[0]), parseInt(startDatetime.split("-")[1]) - 1, parseInt(startDatetime.split("-")[2]));
        let endDate = new Date(parseInt(endDatetime.split("-")[0]), parseInt(endDatetime.split("-")[1]) - 1, parseInt(endDatetime.split("-")[2]));
        this.model.get('startDate').setValue(startDate);
        this.model.get('endDate').setValue(endDate);
        this.model.get('idShift').setValue(response.medicalSchedule.idShift);
        if (response.medicalSchedule.idShift == 3) {
          this.model.get('startHour').enable();
          this.model.get('startMinute').enable();
          this.model.get('endHour').enable();
          this.model.get('endMinute').enable();
        }
  
        let startTime = response.medicalSchedule.startPeriodTime.toString().split(/\D+/);
        let endTime = response.medicalSchedule.endPeriodTime.toString().split(/\D+/);
        this.model.get('startHour').setValue(startTime[3]);
        this.model.get('startMinute').setValue(startTime[4]);
        this.model.get('endHour').setValue(endTime[3]);
        this.model.get('endMinute').setValue(endTime[4]);
        this.model.get('firstChildGroup').get('idCbo').setValue(response.medicalSchedule.idCbo);
        
        this.makeDinamicForm()
        this.isFirstLoading = false;
      },
      error:(error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateShiftSelect() {
    this.shiftService.getAllShift().subscribe({
      next:(response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
  
        this.listShift = response.listShift;
      },
      error:(error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateUserSelect() {
    this.getAllUserEvenDeletedService.getAllUserEvenDeleted().subscribe({
      next:(response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
  
        this.listUser = response.listAllUserStruct;
      },
      error:(error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  updateMedicalSchedule() {
    this.medicalScheduleService.updateMedicalSchedule(this.medicalScheduleRequest, this.idMedicalSchedule).subscribe({
      next:(response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
  
        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;
  
        this.router.navigate(['/form/medical-schedule']);
      },
      error:(error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  createMedicalSchedule() {
    this.medicalScheduleService.createMedicalSchedule(this.medicalScheduleRequest).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/form/medical-schedule']);
    },
      (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  changeShift(event: any) {
    this.model.get('startHour').disable();
    this.model.get('startMinute').disable();
    this.model.get('endHour').disable();
    this.model.get('endMinute').disable();
    if (event == 1) {
      this.model.get('startHour').setValue(7);
      this.model.get('startMinute').setValue(0);
      this.model.get('endHour').setValue(18);
      this.model.get('endMinute').setValue(59);
    }
    else if (event == 2) {
      this.model.get('startHour').setValue(19);
      this.model.get('startMinute').setValue(0);
      this.model.get('endHour').setValue(6);
      this.model.get('endMinute').setValue(59);
    }
    else if (event == 3) {
      this.model.get('startHour').setValue(null);
      this.model.get('startMinute').setValue(null);
      this.model.get('endHour').setValue(null);
      this.model.get('endMinute').setValue(null);
      this.model.get('startHour').enable();
      this.model.get('startMinute').enable();
      this.model.get('endHour').enable();
      this.model.get('endMinute').enable();
    }
  }

  verifyDate() {
    if (this.model.get('startDate').value && this.model.get('endDate').value) {
      if (this.model.get('startDate').value > this.model.get('endDate').value) {
        this.alertService.show('Erro', "Data Inicial não pode ser maior que a Data Final.", AlertType.error);
        return;
      }
      else {
        if (this.listDailyMedicalSchedule) {
          this.listDailyMedicalSchedule = this.listDailyMedicalSchedule.filter(c => !(c.dayDate < this.model.get('startDate').value || c.dayDate > this.model.get('endDate').value));
        }
        this.makeDinamicForm();
      }
    }
    else
      return;
  }

  makeDinamicForm() {
    (this.model.controls['formStepperList'] as UntypedFormArray).clear()
    let startDate = this.model.get('startDate').value;
    let endDate = this.model.get('endDate').value;
    let startDateF = this.formatDateToLabel(startDate);
    let endDateF = this.formatDateToLabel(endDate);
    let startMonth = startDateF.split("/")[1] + "/" + startDateF.split("/")[2];
    let aux: string = startMonth;
    let endMonth = endDateF.split("/")[1] + "/" + endDateF.split("/")[2];
    let getEnd = startMonth == endMonth ? endDate : null;
    this.AddformStepper(aux, startDate, getEnd);
    while (aux != endMonth) {
      let month = parseInt(aux.split("/")[0])
      let nextMonth = month + 1 > 12 ? 1 : month + 1;
      let year = month + 1 > 12 ? parseInt(aux.split("/")[1]) + 1 : parseInt(aux.split("/")[1]);
      aux = (nextMonth <= 9 ? "0" + nextMonth : nextMonth) + "/" + year;
      getEnd = aux == endMonth ? endDate : null;
      let getStartDate = new Date(year, nextMonth - 1, 1);
      this.AddformStepper(aux, getStartDate, getEnd);
    }
  }

  formatDateToLabel(dateToFormat: Date): string {
    var day = dateToFormat.getDate().toString().length > 1 ? dateToFormat.getDate() : "0" + dateToFormat.getDate();
    var month = (dateToFormat.getMonth() + 1).toString().length > 1 ? (dateToFormat.getMonth() + 1) : "0" + (dateToFormat.getMonth() + 1);
    var dataFormatada = day + '/' + (month) + '/' + dateToFormat.getFullYear();
    return dataFormatada;
  }

  createInput(dataFormatada: string, newDate: Date) {
    let value = this.listDailyMedicalSchedule ? this.listDailyMedicalSchedule.find(c => {
      return c.dayDate.getTime() == newDate.getTime();
    }) : null;

    return this.formBuilder.group({
      actualAmountPredict: [value ? value.actualAmountPredict : null],
      predictedAmount: [value ? value.predictedAmount : null, Validators.required],
      dayDate: [dataFormatada],
      newDate: [newDate]
    })
  }

  predictedAmountChanged(dayDateValue: Date, event: any) {
    let updated = false;
    if (!Array.isArray(this.listDailyMedicalSchedule)) {
      this.listDailyMedicalSchedule = [];
    }
    if (this.listDailyMedicalSchedule) {
      this.listDailyMedicalSchedule.forEach(c => {
        if (c.dayDate.getTime() == dayDateValue.getTime()) {
          c.predictedAmount = event.target.value;
          updated = true;
        }
      });
    }
    if (!updated) {
      let dailyMedicalSchedule: DailyMedicalSchedule = new DailyMedicalSchedule();
      dailyMedicalSchedule.dayDate = dayDateValue;
      dailyMedicalSchedule.predictedAmount = event.target.value;
      this.listDailyMedicalSchedule.push(dailyMedicalSchedule);
    }
  }
  actualAmountPredictChanged(dayDateValue: Date, event: any) {
    let updated = false;
    if (!Array.isArray(this.listDailyMedicalSchedule)) {
      this.listDailyMedicalSchedule = [];
    }
    if (this.listDailyMedicalSchedule) {
      this.listDailyMedicalSchedule.forEach(c => {
        if (c.dayDate.getTime() == dayDateValue.getTime()) {
          c.actualAmountPredict = event.target.value;
          updated = true;
        }
      });
    }
    if (!updated) {
      let dailyMedicalSchedule: DailyMedicalSchedule = new DailyMedicalSchedule();
      dailyMedicalSchedule.dayDate = dayDateValue;
      dailyMedicalSchedule.actualAmountPredict = event.target.value;
      this.listDailyMedicalSchedule.push(dailyMedicalSchedule);
    }
  }

  addNext(startDate: Date, endDate: Date): UntypedFormArray {
    let dateSchedule: UntypedFormArray = this.formBuilder.array([]);
    let novaData = startDate;
    let i: number = 0;
    endDate = endDate ? endDate : new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
    do {
      var day = novaData.getDate().toString().length > 1 ? novaData.getDate() + "" : "0" + novaData.getDate();
      var month = (novaData.getMonth() + 1).toString().length > 1 ? (novaData.getMonth() + 1) + "" : "0" + (novaData.getMonth() + 1);
      var dataFormatada = day + '/' + (month) + '/' + novaData.getFullYear();
      dateSchedule.push(this.createInput(dataFormatada, novaData))
      i = i + 1;
      novaData = new Date(startDate.getTime() + (24 * 60 * 60 * 1000) * i);
    } while (novaData <= endDate)
    return dateSchedule;
  }

  createformStepper(month: string, startDate: Date, endDate: Date): UntypedFormGroup {
    return this.formBuilder.group({
      month: [month],
      listDailyMedicalSchedule: this.addNext(startDate, endDate)
    })
  }

  AddformStepper(month: string, startDate: Date, endDate: Date) {
    (this.model.controls['formStepperList'] as UntypedFormArray).push(this.createformStepper(month, startDate, endDate));
  }

  formatDate(dateUnformatted: string): string {
    if (dateUnformatted) {
      let darr = dateUnformatted.split("-");  //2019-10-01
      let dobj = darr[2] + "/" + darr[1] + "/" + darr[0];
      return dobj;
    }
    return null;
  }
}