import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { LookupService } from 'src/app/shared/services/API/private-billing/lookup.service';
import { ProcedureService } from 'src/app/shared/services/API/private-billing/procedure.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { TerminologyOrigin } from 'src/app/shared/services/models/private-billing/terminology-origin.model';
import { PriceProcedureHealthcareStruct } from 'src/app/shared/services/structs/private-billing/price-procedure-healthcare.struct';
import { ProcedureRelationshipStruct } from 'src/app/shared/services/structs/private-billing/procedure-relationship.struct';
import { ProcedureStruct } from 'src/app/shared/services/structs/private-billing/procedure.struct';

@Component({
  selector: 'app-procedure-register',
  templateUrl: './procedure-register.component.html',
  styleUrls: ['./procedure-register.component.css']
})
export class ProcedureRegisterComponent implements OnInit {
  constructor(
    private lookupService: LookupService,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private procedureService: ProcedureService,
    private router: Router,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.billing;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.billing_procedure;

  public listTerminologyOrigin: TerminologyOrigin[] = []; 
  public listPriceProcedureHealthcareStruct: PriceProcedureHealthcareStruct[] = [];
  public listProcedureRelationshipStruct : ProcedureRelationshipStruct[] = [];
  public procedureStruct: ProcedureStruct;

  public idProcedure: number = null;
  public isUpdate: boolean = false;
  public isLoading: boolean = true;
  public procedureFieldsFilled: boolean = false;

  ngOnInit(): void {
    this.getLookup();
  }

  getLookup() {
    this.isLoading = true;

    this.lookupService.listAllLookups().subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.listTerminologyOrigin = response.listTerminologyOrigin;

        if (this.activatedRoute.snapshot.paramMap.get('idProcedure')) {
          this.idProcedure = parseInt(this.activatedRoute.snapshot.paramMap.get('idProcedure'));
          this.getProcedureById(this.idProcedure);
        }
        else
          this.isLoading = false;
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  getProcedureById(idProcedure: number) {
    this.procedureService.GetById(idProcedure).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        if (!response.procedureStruct){
          this.throwException("Procedimento não encontrado.");
          return;
        }

        this.procedureFieldsFilled = true;
        this.isUpdate = true;
        this.procedureStruct = response.procedureStruct; 
        this.idProcedure = response.procedureStruct.idProcedure;        

        if (response.listPriceProcedureHealthcareStruct && response.listPriceProcedureHealthcareStruct.length > 0) 
          this.listPriceProcedureHealthcareStruct = response.listPriceProcedureHealthcareStruct;

        this.listProcedureRelationshipStruct = response.listProcedureRelationshipStruct;

        this.isLoading = false;
        
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  onProcedureFilled(eventData: { isValid: boolean, idProcedure: number, isUpdate: boolean, procedureStruct: ProcedureStruct}) {
    this.procedureFieldsFilled = eventData.isValid;
    this.idProcedure = eventData.idProcedure;
    this.isUpdate = eventData.isUpdate; 

    if (eventData.procedureStruct)
      this.procedureStruct = eventData.procedureStruct;

    if (this.isUpdate && !this.activatedRoute.snapshot.paramMap.get('idProcedure') && Number.isSafeInteger(this.idProcedure)){
      let newUrl: string = `/billing/procedure/register;idProcedure=${this.idProcedure}`;
      this.router.navigateByUrl(newUrl, { skipLocationChange: false });
      this.getProcedureById(this.idProcedure);
    }
  }

  private throwException(error: string) {
    console.log(error);
    this.alertService.show('Erro inesperado', error, AlertType.error);
    this.isLoading = false;
  }
}