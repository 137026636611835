<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-flow-modal-list">
            <h2>Preenchimento AIH</h2>
         </div>
         
         <div class="body-flow-modal-list">
            Você deseja preencher a AIH deste paciente ao finalizar este atendimento? Se optar por preencher depois, o formulário estará disponível para ser concluído no menu "Gerenciamento de AIH", do módulo Médico.
         </div>
         <div class="footer-flow-modal-list">
            <button mat-flat-button color="primary" class="btn-block" (click)="clickConfirm()">
                <span>Sim, preencher</span>
            </button>
            <button mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">
                Preencher depois
            </button>
        </div>
    </div>
</div>

