import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MetaDataService } from 'src/app/shared/services/API/meta-data/meta-data.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MetaDataSectorResponse } from 'src/app/shared/services/responses/orchestrator-patient/meta-data-sector.response';
import { SectorStruct } from 'src/app/shared/services/structs/flow/sector.struct';

@Component({
  selector: 'app-meta-data-delete-modal',
  templateUrl: './meta-data-delete-modal.component.html',
  styleUrls: ['./meta-data-delete-modal.component.css']
})
export class MetaDataDeleteModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<MetaDataDeleteModalComponent>,
    private metaDataService: MetaDataService,
    private alertService: AlertService) { }

  public isLoading: boolean;
  public listSectorStruct: SectorStruct[]

  ngOnInit(): void {
    this.isLoading = false;
  }

  close() {
    this.matDialogRef.close({ deleteMetaData: false });
  }

  clickDelete() {
    this.isLoading = true;

    this.metaDataService.deleteMetaData(this.data.idMetaData, this.data.ip).subscribe({
      next: (response: MetaDataSectorResponse) => {
        if (response.isError) {
          if (response.errorCode == 2) {

            this.listSectorStruct = response.listSectorStruct
            return;
          }
          else {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            this.isLoading = false;
            return;
          }
        }

        this.alertService.show('Sucesso', "Metadado excluido com sucesso!", AlertType.success);
        this.matDialogRef.close({ deleteMetaData: true });
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  clickCancel() {
    this.matDialogRef.close({ deleteMetaData: false });
  }
}