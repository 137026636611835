import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ModuleProfileStruct } from 'src/app/shared/services/structs/user/module-profile.struct';
import { ListAllUserStruct } from 'src/app/shared/services/structs/user/list-all-user.struct';
import { ChangelogStruct } from 'src/app/shared/services/structs/orchestrator-changelog/changelog.struct';
import { ChangelogService } from 'src/app/shared/services/API/changelog/changelog.service';
import { TypeTimeSearchEnum } from 'src/app/shared/enum/changelog/type-time-search.enum';
import { MatDialog } from '@angular/material/dialog';
import { ChangelogDetailsModalComponent } from './changelog-details-modal/changelog-details-modal.component';
import { ChangelogSearchRequest } from 'src/app/shared/services/requests/changelog/changelog-search.request';
import { SelectStruct } from 'src/app/shared/services/structs/queue/select.struct';
import { ChangeLogService } from 'src/app/shared/services/API/orchestrator-user/changelog.service';

@Component({
  selector: 'app-changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.css']
})
export class ChangelogComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator

  minDate: Date;
  maxDate: Date;

  constructor(
    private alertService: AlertService,
    private changelogService: ChangelogService,
    private orchChangelogService: ChangeLogService,
    public dialog: MatDialog,
  ) {
    this.maxDate = new Date();

    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
  
    const minAllowedDate = new Date(2024, 9, 1);

    this.minDate = sixMonthsAgo > minAllowedDate ? sixMonthsAgo : minAllowedDate;
  }

  menuModuleEnum: MenuModuleEnum = MenuModuleEnum.master;
  menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.master_changelog;

  listModuleProfileStruct: Array<ModuleProfileStruct> = new Array<ModuleProfileStruct>();
  listChangeLogStruct: ChangelogStruct[] = [];
  listUser: ListAllUserStruct[] = [];
  listMenuNameRequest: string[] = [];
  listIdUserRequest: number[] = [];
  listMenuNameResponse: SelectStruct[] = [];

  isLoading: boolean = true;
  isFirstLoading: boolean = true;

  idModule: number = MenuModuleEnum.master;
  pageSize: number = 10;
  pageIndex: number = 0;
  fullListSize: number = null;

  searchText: string = null;
  dateRangeError: string | null = null;
  selectedPeriod: TypeTimeSearchEnum = TypeTimeSearchEnum.LastHour;
  selectedPeriodLastHour: TypeTimeSearchEnum = TypeTimeSearchEnum.LastHour;
  selectedPeriodLast4Hours: TypeTimeSearchEnum = TypeTimeSearchEnum.Last4Hours;
  selectedPeriodLast12Hours: TypeTimeSearchEnum = TypeTimeSearchEnum.Last12Hours;
  selectedPeriodLast24Hours: TypeTimeSearchEnum = TypeTimeSearchEnum.Last24Hours;
  selectedPeriodLast7Days: TypeTimeSearchEnum = TypeTimeSearchEnum.Last7Days;
  selectedPeriodLast30Days: TypeTimeSearchEnum = TypeTimeSearchEnum.Last30Days;
  selectedPeriodCustom: TypeTimeSearchEnum = TypeTimeSearchEnum.Custom;

  startOfAttendance: Date | null = null;
  endOfAttendance: Date | null = null;

  columns: { header: string, field: string, isAction?: boolean, width?: string }[] = [
    { header: 'Usuário', field: 'userName', width: '13%' },
    { header: 'Data da alteração', field: 'datetimeInclusion', width: '10%' },
    { header: 'Log de Alteração', field: 'updateLogAudit', width: '30%' },
    { header: 'Módulo', field: 'module', width: '5%' },
    { header: 'IP do navegador', field: 'ip', width: '10%' },
    { header: 'Ações', field: 'actions', isAction: true, width: '2%' }
  ];

  changelogList = [];

  ngOnInit(): void {
    let asyncCallsCounter = 0;

    const handleAsyncCallCompleted = () => {
      asyncCallsCounter++;
      if (asyncCallsCounter === 1) {
        this.isFirstLoading = false;
        this.search(MenuModuleEnum.master);
      }
    };

    this.getChangelogLookup(handleAsyncCallCompleted);
  }

  getChangelogLookup(callback: () => void) {
    this.orchChangelogService.GetLookups(this.idModule).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (response && response.listModuleStruct)
          this.listModuleProfileStruct = response.listModuleStruct.filter(x => x.isModulePermitted && x.idModule == MenuModuleEnum.master);

        this.listUser = response.listAllUserStruct;
        this.listMenuNameResponse = response.list;

        callback();
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  changePage(pageIndex: number) {
    this.pageIndex = pageIndex;
    this.search(this.idModule);
  }

  search(idModule: number) {
    this.isLoading = true;
    this.changelogList = [];

    let request = new ChangelogSearchRequest();
    request.idModule = idModule;
    request.selectedPeriod = this.selectedPeriod;
    request.startOfAttendance = this.startOfAttendance;
    request.endOfAttendance = this.endOfAttendance;
    request.pageIndex = this.pageIndex;
    request.listMenuNameRequest = this.listMenuNameRequest;
    request.listIdUserRequest = this.listIdUserRequest;
    request.searchText = this.searchText;

    this.changelogService.Search(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (response) {
          if (response.listChangeLogStruct && response.listChangeLogStruct.length > 0) {
            this.listChangeLogStruct = response.listChangeLogStruct;
            this.changelogList = this.transformToTableRows(response.listChangeLogStruct);
          }

          this.fullListSize = response.fullSize;
        }

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  setDateToSendoInSearch(selectedDate: Date = null): string {
    if (!selectedDate)
      return null;

    let copyOfDate: Date = new Date(selectedDate);
    let timeZoneOffset: number = copyOfDate.getTimezoneOffset();
    copyOfDate.setMinutes(copyOfDate.getMinutes() - timeZoneOffset);

    return copyOfDate.toISOString();
  }

  transformToTableRows(changelogList: ChangelogStruct[]) {
    return changelogList.map(log => {
      const logDataParsed = JSON.parse(log.logUpdateAudit.logData);

      const formattedLogData = this.formatLogData(logDataParsed);

      return {
        userName: this.listUser?.find(x => x.idUser == log.logUpdateAudit.idUser)?.userName,
        datetimeInclusion: log.logUpdateAudit.datetimeInclusion,
        updateLogAudit: formattedLogData,
        module: "Master",
        ip: log.logUpdateAudit.ip
      };
    });
  }

  formatLogData(logData: any): string {
    const entries = Object.entries(logData);

    const formatSpecialFields = (key: string, value: any): string | null => {
      const formatRange = (value: any) => {
        return `${value}`;
      };

      if (key.toLowerCase() === 'isactive')
        return typeof value === 'boolean' ? (value ? 'Ativo' : 'Inativo') : 'Não possui';
      if (key.toLowerCase() === 'isemergency')
        return typeof value === 'boolean' ? (value ? 'Emergência: Ativo' : 'Emergência: Inativo') : 'Não possui';
      if (key.toLowerCase() === 'listmessagefiringrules') {
        return value.map((rule: any) => {
          return `Conteúdo da mensagem: '${rule.descriptionOfMessage}', Periodicidade: '${rule.frequency}', Tempo para disparo: '${rule.timeOfTrigger}', Gatilho de Disparo: '${rule.idMessageFiringTrigger}', Natureza de atendimento: '${rule.idNatureOfAttendance}'\n`;
        }).join(', ');
      }
      if (key.toLowerCase() === 'listprofile') {
        return value.map((rule: any) => {
          return `'${rule}'`;
        }).join(', ');
      }
      if (key.toLowerCase() === 'idalertpolicy')
        return `Dados do alerta:`; // Primeiro item na formatação
      if (key.toLowerCase() === 'minqueuecount')
        return `'${value}'`;
      if (key.toLowerCase() === 'mintime')
        return `'${value} minutos'`;
      if (key.toLowerCase() === 'listiduser')
        return `${value.join(', ')}`;
      if (key.toLowerCase() === 'listidrole')
        return `${value.join(', ')}`;
      if (key.toLowerCase() === 'npsscreenconfig')
        return `Configuração NPS - Usar cor: '${value.useColor}', Cor: '${value.colorCode}', Possui logo: '${value.isLogoUploaded ? 'Ativo' : 'Inativo'}', Habilitar coleta de comentário após a nota: '${value.commentCollectionActive ? 'Ativo' : 'Inativo'}'`;
      if (key.toLowerCase() === 'logostring64')
        return value ? 'Possui' : 'Não possui';
      if (key.toLowerCase() === 'cpf')
        return `********${value.slice(-3)}`;
      if (key.toLowerCase() === 'temppassword')
        return '';
      if (key.toLowerCase() === 'login')
        return `${value.slice(0, 2)}${'*'.repeat(value.length - 2)}`;
      if (key.toLowerCase() === 'email')
        return `${value.slice(0, 2)}${'*'.repeat(value.length - 2)}`;
      if (key.toLowerCase() === 'cns')
        return `**********${value.slice(-4)}`;
      if (key.toLowerCase() === 'listidcarelinetype')
        return `${value.join(', ')}`;
      if (key.toLowerCase() === 'listbillingalertrule') {
        return value.map((rule: any) => {
          const channels = rule.idChannelType.map((id: number) => (id === 1 ? 'E-mail' : 'SMS')).join(', ');
          return `'Tipo de serviço: '${rule.idTypeService === 1 ? 'Quantidade de atendimentos' : ''}', Variável: '${rule.idAlertVariable === 1 ? '≥' : ''}', Valor: '${rule.value}', Canal de alerta: ${channels}\n`;
        }).join(', ');
      }
      if (key.toLowerCase() === 'listbuttons') {
        return value.map((button: any) => {
          return `Nome do Botão: '${button.totemButtonName}', Sigla: '${button.initials}', Sigla Prioritária: '${button.priorityInitials}', É para pacientes agendados: ${button.isScheduleToggle ? 'Ativo' : 'Inativo'}, Linha de Cuidado em prioridade: ${button.idCareLinePriority !== null ? button.idCareLinePriority : 'Não possui'}\n`;
        }).join('');
      }
      if (key.toLowerCase() === 'listidagerange')
        return `${value.join(', ')}`;
      if (key.toLowerCase() === 'listidcomorbidity')
        return `${value.join(', ')}`;
      if (key.toLowerCase() === 'listidmedicine')
        return value.length ? `${value.join(', ')}` : 'Não possui';
      if (key.toLowerCase() === 'phone')
        return `****${value.slice(-8, -4)}****`;
      if (key.toLowerCase() === 'carelinename')
        return `${value}`;
      if (key.toLowerCase() === 'carelinedescription')
        return value ? `${value}` : 'Não possui';
      if (key.toLowerCase().includes('bloodpressure')) {
        return formatRange(value);
      }
      if (key.toLowerCase().includes('temperature'))
        return formatRange(value);
      if (key.toLowerCase().includes('heartrate'))
        return formatRange(value);
      if (key.toLowerCase().includes('saturation'))
        return formatRange(value);
      if (key.toLowerCase().includes('glucose'))
        return formatRange(value);
      if (key.toLowerCase() === 'listidsector')
        return `${value.join(', ')}`;
      if (key.toLowerCase() === 'showNames')
        return value ? 'Sim' : 'Não';
      if (key.toLowerCase() === 'showRanking')
        return value ? 'Sim' : 'Não';

      return typeof value === 'string' || typeof value === 'number' ? value.toString() : (typeof value === 'boolean' ? (value ? 'Ativo' : 'Inativo') : "Não possui");
    };

    const formattedEntries = entries
      .filter(([key, value]) => {
        const lowerKey = key.toLowerCase();
        return (
          value != null && value !== '' &&
          (lowerKey !== 'ip') &&
          (lowerKey !== 'panellogo' || value) &&
          (lowerKey !== 'datetimeinclusion') &&
          (lowerKey !== 'listmetadataoptionname') &&
          (lowerKey !== 'idcarelinepriority')
        );
      })
      .map(([key, value]) => {
        const label = this.getLabelForKey(key);
        const formattedValue = formatSpecialFields(key, value);
        return formattedValue ? `${label}: '${formattedValue}'` : '';
      })
      .filter(entry => entry)
      .join(', ');

    return formattedEntries;
  }

  getLabelForKey(key: string): string {
    const dynamicLabels = {
      panelname: 'Nome',
      servicename: 'Nome',
      sectorname: 'Setor',
      roomname: 'Sala',
      flowname: 'Fluxo',
      description: 'Descrição',
      isactive: 'Status',
      idservice: 'Serviço',
      idsector: 'Setor',
      totemnpsname: 'Totem NPS',
      npstext: 'Texto do NPS',
      istorequestcpf: 'Identificação obrigatória do paciente',
      idroom: 'Sala',
      isemergency: 'Emergência',
      idspecialityattendance: 'Especialidade de Atendimento',
      idlayout: 'Layout',
      idorigintotembutton: 'Botão de Origem',
      iddestinationservice: 'Serviço de Destino',
      priority: 'Prioridade',
      expressions: 'Expressões',
      idmodule: 'ID Módulo',
      listmessagefiringrules: 'Regras de disparo de mensagens',
      listprofile: 'Perfil',
      npsscreenconfig: 'Configuração NPS',
      logostring64: 'Logo',
      havesignature: 'Assinatura Digital',
      havelogo: 'Possui Logo',
      council: 'Número do Conselho',
      idprofession: 'Profissão',
      hasicpbrasiltokencertificate: 'Usar assinatura digital via token físico',
      hasicpbrasilcertificate: 'Usar assinatura digital em nuvem',
      certifiedmanchester: 'Possui certificação no Manchester',
      callbypassword: 'Chamar pela senha',
      videoformat: 'Formato do vídeo',
      idcbo: 'CBO',
      idstatecouncil: 'UF do conselho',
      username: 'Nome',
      cpf: 'CPF',
      temppassword: 'Senha temporária',
      login: 'Matrícula',
      profilename: 'Nome',
      canstayconnected: 'Permitir "Permanecer conectado',
      listidrole: 'IDs de Função',
      carelinename: 'Nome da Linha de Cuidado',
      carelinedescription: 'Descrição da Linha de Cuidado',
      othermedication: 'Outra Medicação',
      othercomorbity: 'Outra Comorbidade',
      listidcarelinetype: 'Tipos de Linha de Cuidado',
      listidagerange: 'Faixa Etária',
      listidcomorbidity: 'Comorbidades',
      listidmedicine: 'Medicamentos',
      otheragerangemax: 'Idade máxima',
      otheragerangemin: 'Idade mínima',
      bloodpressurediastolegreenmin: 'Pressão Arterial Diastólica Verde min',
      bloodpressurediastolegreenmax: 'Pressão Arterial Diastólica Verde max',
      bloodpressurediastoleyellowmin: 'Pressão Arterial Diastólica Amarelo min',
      bloodpressurediastoleyellowmax: 'Pressão Arterial Diastólica Amarelo max',
      bloodpressurediastoleredmin: 'Pressão Arterial Diastólica Vermelho min',
      bloodpressurediastoleredmax: 'Pressão Arterial Diastólica Vermelho max',
      bloodpressuresystolegreenmin: 'Pressão Arterial Sistólica Verde min',
      bloodpressuresystolegreenmax: 'Pressão Arterial Sistólica Verde max',
      bloodpressuresystoleyellowmin: 'Pressão Arterial Sistólica Amarelo min',
      bloodpressuresystoleyellowmax: 'Pressão Arterial Sistólica Amarelo max',
      bloodpressuresystoleredmin: 'Pressão Arterial Sistólica Vermelho min',
      bloodpressuresystoleredmax: 'Pressão Arterial Sistólica Vermelho max',
      temperaturegreenmin: 'Temperatura Verde min',
      temperaturegreenmax: 'Temperatura Verde max',
      temperatureyellowmin: 'Temperatura Amarelo min',
      temperatureyellowmax: 'Temperatura Amarelo max',
      temperatureredmin: 'Temperatura Vermelho min',
      temperatureredmax: 'Temperatura Vermelho max',
      glucosegreenmin: 'Glicose Amarelo min',
      glucosegreenmax: 'Glicose Amarelo max',
      glucoseyellowmin: 'Glicose Verde min',
      glucoseyellowmax: 'Glicose Verde max',
      glucoseredmin: 'Glicose Vermelho min',
      glucoseredmax: 'Glicose Vermelho max',
      saturationgreenmin: 'Saturação Amarelo min',
      saturationgreenmax: 'Saturação Amarelo max',
      saturationyellowmin: 'Saturação Verde min',
      saturationyellowmax: 'Saturação Verde max',
      saturationredmin: 'Saturação Vermelho min',
      saturationredmax: 'Saturação Vermelho max',
      heartrategreenmin: 'Frequência Cardíaca Verde min',
      heartrategreenmax: 'Frequência Cardíaca Verde max',
      heartrateyellowmin: 'Frequência Cardíaca Amarelo min',
      heartrateyellowmax: 'Frequência Cardíaca Amarelo max',
      heartrateredmin: 'Frequência Cardíaca Vermelho min',
      heartrateredmax: 'Frequência Cardíaca Vermelho max',
      areaname: 'Nome',
      areadescription: 'Descrição',
      shownames: 'Exibir nomes dos usuários no ranking geral',
      showranking: 'Visualizar ranking',
      listidsector: 'Setores',
      billingemail: 'E-mail',
      billingtelephone: 'Telefone',
      listbillingalertrule: 'Canal de alerta',
      idalertpolicy: 'Dados do alerta',
      minqueuecount: 'Qtde. mínima de pacientes aguardando',
      mintime: 'Tempo máximo de espera em fila',
      listiduser: 'Usuários',
      totemname: 'Nome',
      useqrcode: 'Imprimir QR Code',
      printername: 'Nome da Impressora',
      listbuttons: 'Botões Totem',
      listidpriorityrequired: 'Prioridades exibidas',
      idmetadatatype: 'Tipo do Metadado',
      metadataname: 'Nome'
    };

    return dynamicLabels[key.toLowerCase()] || key.toLowerCase();
  }

  changelogDetails(changelog: any) {
    let numberOfLinesInTextPatientExitLog = this.countLinesInArea(changelog.updateLogAudit, 825, 16);
    const dialogRef = this.dialog.open(ChangelogDetailsModalComponent, {
      disableClose: true,
      data: { changelog, numberOfLinesInTextPatientExitLog }
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
      }
    });
  }

  countLinesInArea(text: string, areaWidth: number, fontSize: number): number {
    if (!text || text.trim() === "") {
      return 0;
    }

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    if (!context) return 0;

    context.font = `${fontSize}px Arial`; // Ajuste a fonte conforme necessário

    const words = text.split(' ');
    let lineWidth = 0;
    let lines = 1;

    for (const word of words) {
      const wordWidth = context.measureText(word + ' ').width;
      if (lineWidth + wordWidth > areaWidth) {
        lines++;
        lineWidth = wordWidth; // Começa uma nova linha
      } else {
        lineWidth += wordWidth;
      }
    }

    return lines;
  }

  change() {
    this.startOfAttendance = null;
    this.endOfAttendance = null;
  }

  cancel() {
    this.listMenuNameRequest = null;
    this.selectedPeriod = TypeTimeSearchEnum.LastHour;
    this.listIdUserRequest = null;
    this.searchText = null;
    this.search(this.idModule);
  }
}