import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { PatientRequest } from '../../requests/medical-record/patient.request';
import { PostPatientResponse } from '../../responses/medical-record/post-patient.response';

@Injectable({
  providedIn: 'root'
})
export class TelephonePatientService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public savePatient(body: PatientRequest): Observable<PostPatientResponse>{

    let uri = `TelephonePatient`

    return this.httpClient.post<PostPatientResponse>(environment.urlApiMedicalRecord + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
