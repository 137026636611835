import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CommonService } from "src/app/shared/services/common-service";
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';
import { catchError } from "rxjs/operators";
import { ReturnStruct } from "src/app/shared/structs/return.struct";
import { NursingConsultationRequest } from "../../requests/medical-record/nursing-consultation.request";
import { NursingConsultationResponse } from "../../responses/medical-record/nursing-consultation.response";
import { PostNursingConsultationResponse } from "../../responses/medical-record/post-nursing-consultation.response";

@Injectable({
  providedIn: 'root'
})
export class NursingConsultationService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listNursingConsultation(idEpisode:number): Observable<NursingConsultationResponse> {
    let uri = `NursingConsultation/idEpisode/${idEpisode}`

    return this.httpClient.get<NursingConsultationResponse>(environment.urlApiMedicalRecord + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public postNursingConsultation(nursingConsultationRequest: NursingConsultationRequest): Observable<PostNursingConsultationResponse> {
    let uri = `NursingConsultation`

    return this.httpClient.post<PostNursingConsultationResponse>(environment.urlApiMedicalRecord + uri, nursingConsultationRequest, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}