  import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { ArrivalType, Origin, PatientNecessity } from 'src/app/shared/services/responses/medical-record/lookup.response';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MedicalRecordLookupService } from 'src/app/shared/services/API/medical-record/lookup.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { MatDialog } from '@angular/material/dialog';
import { FowardRequest } from 'src/app/shared/services/requests/orchestrator-queue/foward.request';
import { ReportService } from 'src/app/shared/services/API/orchestrator-patient/report.service';  
import { PriorityModel } from 'src/app/shared/services/models/totem/priority.model';
import { GetFrontDeskBySectorService } from 'src/app/shared/services/API/front-desk/get-front-desk-by-sector.service';
import { FowardService } from 'src/app/shared/services/API/orchestrator-queue/foward.service';
import { PriorityService } from 'src/app/shared/services/API/totem/priority.service';
import { RoomService } from 'src/app/shared/services/API/flow/room.service';
import { SectorService } from 'src/app/shared/services/API/flow/sector.service';
import { ViaCepService } from 'src/app/shared/services/API/integration/via-cep.service';
import { AllergyService } from 'src/app/shared/services/API/medical-record/allergy.service';
import { ForwardResponse } from 'src/app/shared/services/responses/orchestrator-queue/forward.response';
import { ClockService } from 'src/app/shared/services/API/orchestrator-queue/clock.service';
import { FlowStruct } from 'src/app/shared/services/structs/flow/flow.struct';
import { SigaPatientStruct } from 'src/app/shared/services/structs/siga-front-desk/siga-patient.struct';
import { PatientSigaRequest } from 'src/app/shared/services/requests/orchestrator-patient/patient-siga.request';
import { AddressOrigin } from 'src/app/shared/services/models/integration-siga/address-origin.model';
import { BirthCountry } from 'src/app/shared/services/models/integration-siga/birth-country.model';
import { City } from 'src/app/shared/services/models/integration-siga/city.model';
import { District } from 'src/app/shared/services/models/integration-siga/district.model';
import { Education } from 'src/app/shared/services/models/integration-siga/education.model';
import { EmissorEntity } from 'src/app/shared/services/models/integration-siga/emissor-entity.model';
import { Ethnicity } from 'src/app/shared/services/models/integration-siga/ethnicity.model';
import { HealthInsurance } from 'src/app/shared/services/models/integration-siga/health-insurance.model';
import { Nationality } from 'src/app/shared/services/models/integration-siga/nationality.model';
import { Occupation } from 'src/app/shared/services/models/integration-siga/occupation.model';
import { Race } from 'src/app/shared/services/models/integration-siga/race.model';
import { Sex } from 'src/app/shared/services/models/integration-siga/sex.model';
import { State } from 'src/app/shared/services/models/integration-siga/state.model';
import { StreetType } from 'src/app/shared/services/models/integration-siga/street-type.model';
import { SigaLookupService } from 'src/app/shared/services/API/integration-siga/siga-lookup.service';
import { ParentalBond } from 'src/app/shared/services/models/integration-siga/parental-bond.model';
import { NationalityType } from 'src/app/shared/services/models/integration-siga/nationality-type.model';
import { environment } from 'src/environments/environment';
import { SigaFrontDeskService } from 'src/app/shared/services/API/orchestrator-patient/siga-front-desk.service';
import { FamilySituation } from 'src/app/shared/services/models/integration-siga/family-situation.model';
import { GetPatientFlowService } from 'src/app/shared/services/API/orchestrator-patient/get-patient-flow.service';
import { SigaSearchPatientService } from 'src/app/shared/services/API/orchestrator-patient/siga-search-patient.service';
import { SearchPatientFrontdeskService } from 'src/app/shared/services/API/orchestrator-patient/search-patient-frontdesk.service';
import { SearchPatientTotemRequest } from 'src/app/shared/services/requests/orchestrator-totem/search-patient-totem.request';
import { PatientStruct } from 'src/app/shared/services/structs/medical-record/patient.struct';
import { SelectPatientModalComponent } from '../../patient-register/select-patient-modal/select-patient-modal.component';
import { Justification } from 'src/app/shared/services/models/integration-siga/justification.model';
import { NewCretificate, OldCretificate } from 'src/app/shared/services/structs/siga-front-desk/siga-certificate.struct';
import { UtilService } from 'src/app/shared/services/util.service';
import { MetaDataStruct } from 'src/app/shared/services/structs/meta-data/meta-data.struct';
import { MetaDataService } from 'src/app/shared/services/API/meta-data/meta-data.service';
import { MetaDataKeyValueStruct } from 'src/app/shared/services/structs/meta-data/meta-data-key-value.struct';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MetaDataTypeEnum } from 'src/app/shared/enum/meta-data/meta-data-type.enum';
import { GroupOriginService } from 'src/app/shared/services/API/orchestrator-user/group-origin.service';
import { PostPutRelationQueueRoomAttendOnFrontDeskRequest } from 'src/app/shared/services/requests/front-desk/post-put-relation-queue-room-attend-on-front-desk.request';
import { RelationQueueRoomAttendOnFrontDeskService } from 'src/app/shared/services/API/front-desk/relation-queue-room-attend-on-front-desk.service';
import { SigaForwardPatientModalComponent } from '../siga-forward-patient-modal/siga-forward-patient-modal.component';
import { ReportViewSelectModalComponent } from 'src/app/shared/components/report-view-select-modal/report-view-select-modal.component';
import { PatientSigaResponse } from 'src/app/shared/services/responses/integration-siga/patient-siga.response';
import { SignatureCredentialsModalComponent } from 'src/app/shared/components/signature-credentials-modal/signature-credentials-modal.component';
import { UserLoginResponse } from 'src/app/shared/services/responses/user/auth.response';
import { UserRoleEnum } from 'src/app/shared/enum/user-role.enum';
import { SigaSaveErrorsEnum } from 'src/app/shared/enum/siga-save-errors.enum';

@Component({
  selector: 'app-siga-patient-register-data',
  templateUrl: './siga-patient-register-data.component.html',
  styleUrls: ['./siga-patient-register-data.component.css']
})
export class SigaPatientRegisterDataComponent implements OnInit {
  @Input() patientData: SigaPatientStruct;
  @Output() emitFindPatient = new EventEmitter<any>();
  @Output() clearPatientEvent = new EventEmitter<any>();
  @Output() emitIsAttended = new EventEmitter<any>();
  

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private utilService: UtilService,
    private getPatientFlowService: GetPatientFlowService,
    private sigaSearchPatientService: SigaSearchPatientService,
    private searchPatientFrontdeskService: SearchPatientFrontdeskService,
    private priorityService: PriorityService,
    private getFrontDeskBySectorService: GetFrontDeskBySectorService,
    private lookupService: MedicalRecordLookupService,
    private sigaLookupService: SigaLookupService,
    private sigaFrontDeskService: SigaFrontDeskService,
    private maskService: MaskService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public fowardService: FowardService,
    public reportService: ReportService,
    public roomService: RoomService,
    public sectorService: SectorService,
    public router: Router,
    public viaCepService: ViaCepService,
    public allergyService: AllergyService,
    public clockService: ClockService,
    private metaDataService: MetaDataService,
    private GroupOriginByOrchestratorService: GroupOriginService,
    private relationQueueRoomAttendOnFrontDeskService: RelationQueueRoomAttendOnFrontDeskService,
    private utilsService: UtilService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.frontdesk;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.frontdesk_patient_register;
  public model: FormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isLoadingCep: boolean;
  public isLoadingCpf: boolean;
  public isLoadingCns: boolean;
  public isWristbandActive: boolean;
  public wristbandWidth: number;
  public wristbandHeight: number;
  public datetimeStartRegister: Date;

  public unknownMother: boolean = false;
  public unknownFather: boolean = false;
  public hasCellPhone: boolean = true;
  public hasHouseNumber: boolean = true;
  public hasHealthInsurance: boolean = true;
  public findLookup: boolean = false;
  public cepIsValid: boolean = false;
  public countryIsPopulated: boolean = false;
  public cityIsPopulated: boolean = false;
  public stateIsPopulated: boolean = false;

  public listAddressOrigin: AddressOrigin[];
  public listBirthCountry: BirthCountry[];
  public listCity: City[];
  public listDistrict: District[];
  public listEducation: Education[];
  public listEmissorEntity: EmissorEntity[];
  public listEthnicity: Ethnicity[];
  public listHealthInsurance: HealthInsurance[];
  public listNationality: Nationality[];
  public listNationalityType: NationalityType[];
  public listOccupation: Occupation[];
  public listRaces: Race[];
  public listSex: Sex[];
  public listState: State[];
  public listStreetType: StreetType[];
  public listParentalBond: ParentalBond[];
  public listFamilySituation: FamilySituation[];
  public listJustification: Justification[];
  public listMetaDataStruct: MetaDataStruct[] = [];
  public isAutomatic: boolean = false;

  public urlListCity: string = environment.urlApiIntegrationSiga + 'City';
  public urlListOccupation: string = environment.urlApiIntegrationSiga + "Occupation";
  public urlListHealthInsurance: string = environment.urlApiIntegrationSiga + "HealthInsurance";
  public listPatientNecessity: PatientNecessity[];

  public originList: Origin[];
  public arrivalTypeList: ArrivalType[];
  public masks: Masks;
  public listMetaData: MetaDataStruct[] = [];
  public requiredFields: string[];
  public newPatient: boolean = true;
  public idPatient: number = null;
  public idEpisode: number = null;
  public idQueue: number = null;
  public idSector: number = null;
  public idRoom: number = null;
  public ticket: string = '';
  public isUpdateFrontDesk: boolean = false;
  public patientAge: string = null;

  public listOfPriorities: PriorityModel[];
  public identification: string;
  public idIdentification: number;
  public flowSequence: FlowStruct[];

  public patientRequest: PatientSigaRequest = new PatientSigaRequest();

  //Assinatura digital
  public sign: boolean;
  public requireSignature: boolean;

  ngOnInit(): void {
    this.requireSignature = this.checkSigningResponsibility();
    if (this.activatedRoute.snapshot.paramMap.get('idEpisode'))
      this.idEpisode = parseInt(this.activatedRoute.snapshot.paramMap.get('idEpisode'));

    if (this.activatedRoute.snapshot.paramMap.get('isUpdateFrontdesk'))
      this.isUpdateFrontDesk = this.activatedRoute.snapshot.paramMap.get('isUpdateFrontdesk') == "true" ? true : false;

    if (this.activatedRoute.snapshot.paramMap.get('newPatient'))
      this.newPatient = this.activatedRoute.snapshot.paramMap.get('newPatient') == "true" ? true : false;

    if (this.activatedRoute.snapshot.paramMap.get('idPatient'))
      this.idPatient = parseInt(this.activatedRoute.snapshot.paramMap.get('idPatient'));

    if (this.activatedRoute.snapshot.paramMap.get('idQueue'))
      this.idQueue = parseInt(this.activatedRoute.snapshot.paramMap.get('idQueue'));

    if (this.isUpdateFrontDesk)
      this.getPatientById();

    this.idSector = parseInt(this.activatedRoute.snapshot.paramMap.get('idSector'));
    this.idRoom = parseInt(this.activatedRoute.snapshot.paramMap.get('idRoom'));
    this.ticket = this.activatedRoute.snapshot.paramMap.get('ticket');

    this.populateLookupSelect();
    this.masks = this.maskService.getMasks();
    this.isLoading = true;
    this.isFirstLoading = true;
    this.listPatientNecessity = [];
    this.model = this.formBuilder.group({
      patientName: ['', [Validators.required]],
      socialName: [''],
      cpf: ['', [Validators.required]],
      birthDate: ['', [Validators.required]],
      cns: [''],
      residentialPhone: [''],
      cellPhone: ['', [Validators.required]],
      noCellPhone: [false], //é um toggle
      comercialPhone: [''],
      contactPhone: [''],
      email: [''],
      patientAge: [{ value: '', disabled: true }],
      contactName: [''],
      rg: [''],
      expeditionDate: [''],
      unknownMother: [false],
      motherName: ['', [Validators.required]],
      unknownFather: [false],
      fatherName: [''],
      isNewBirthCertificate: [false, [Validators.required]],
      isNewDivorceCertificate: [false, [Validators.required]],
      isNewMarriageCertificate: [false, [Validators.required]],
      isNewIndigenousCertificate: [false, [Validators.required]],
      firstChildGroup: this.formBuilder.group({
        cpfJustification: ['', [Validators.required]],
        idRace: ['', [Validators.required]],
        idGender: ['', [Validators.required]],
        idEthnicity: [''],
        idFamilySituation: [''],
        idEducation: [''],
        idOccupation: [''],
        idNationalityType: ['', [Validators.required]],
        idBirthCountry: [''],
        idExpeditionIssuer: [''],
        idForeignNaturality: [''],
        idForeignEmissor: [''],
        idUfCtps: [''],
        idPassportEmissionCountry: [''],
        idStreet: [''],
        idAdmDistrict: [''],
        idExpeditionUf: [''],
        idBirthCity: [''],
        idCity: [''],
        idOriginAddress: ['', [Validators.required]],
        idHealthInsurance: [{ value: '', disabled: true }],
      }),
      frequentsSchool: [false],
      countryArrivalDate: [''],
      naturalizationOrdinance: [''],
      naturalizationDate: [''],
      registerProtocol: [{ value: '', disabled: true }],
      zipCode: [''],
      street: [''],
      neighborhood: [''],
      noHouseNumber: [false],
      houseNumber: [''],
      houseComplement: [''],
      referencePoint: [''],
      hasSusHealthInsurance: [false],
      cnesEstablishmentRegistrationCode: [{ value: '', disabled: true }],
      cnesEstablishmentBondCode: [{ value: '', disabled: true }],
      dnv: [''],
      foreignIdentity: [''],
      foreignExpirationDate: [''],
      voterRegistration: [''],
      voterEmissionDate: [''],
      voterZone: [''],
      voterSection: [''],
      ctps: [''],
      ctpsEmissionDate: [''],
      ctpsSeries: [''],
      pisPasep: [''],
      cnh: [''],
      cnhExpirationDate: [''],
      cnhEmissionDate: [''],
      newBirthCertificate: [''],
      newBirthCertificateEmissionDate: [''],
      oldBirthCertificate: [''],
      oldBirthCertificatePageNumber: [''],
      oldBirthCertificateBookNumber: [''],
      oldBirthCertificateTermNumber: [''],
      oldBirthCertificateEmissionDate: [''],
      newMarriageCertificate: [''],
      newMarriageCertificateEmissionDate: [''],
      oldMarriageCertificate: [''],
      oldMarriageCertificatePageNumber: [''],
      oldMarriageCertificateBookNumber: [''],
      oldMarriageCertificateTermNumber: [''],
      oldMarriageCertificateEmissionDate: [''],

      newDivorceCertificate: [''],
      newDivorceCertificateEmissionDate: [''],
      oldDivorceCertificate: [''],
      oldDivorceCertificatePageNumber: [''],
      oldDivorceCertificateBookNumber: [''],
      oldDivorceCertificateTermNumber: [''],
      oldDivorceCertificateEmissionDate: [''],

      newIndigenousAdmCertificate: [''],
      newIndigenousAdmCertificateEmissionDate: [''],
      oldIndigenousAdmCertificate: [''],
      oldIndigenousAdmCertificatePageNumber: [''],
      oldIndigenousAdmCertificateBookNumber: [''],
      oldIndigenousAdmCertificateTermNumber: [''],
      oldIndigenousAdmCertificateEmissionDate: [''],

      passport: [''],
      passportEmissionDate: [''],
      passportExpirationDate: [''],

      isPriority: [false],
      unidentifiedPatientDescription: [''],
      observation: [''],
      arrivalType: [null],
      origin: [null],
      companionName: [''],
      companionPhone: [''],
      companionCpf: [''],
      companionParentage: [''],
      listIdPatientNecessity: [[]],
    });

    this.getAllPriorities();
    this.getArrivalTypeAndOrigin();

    this.getListMetaData(parseInt(this.activatedRoute.snapshot.paramMap.get('idSector')));

    if (this.idEpisode)
      this.checkActualFlow();
  }

  restart() {
    this.patientData = new SigaPatientStruct();
    this.model.reset();
    this.clearPatientEvent.emit();
    this.emitFindPatient.emit(false);
  }

  async submit(sign: boolean) {
    if (this.isLoading)
      return;

    if (this.model.invalid) {
      this.alertService.show('Erro', 'Você precisa informar todos os campos obrigatórios', AlertType.error);
      return;
    }

    this.sign = sign;

    this.isLoading = true;    

    await this.MapPatientSigaRequestToSubmit();  

    if (this.newPatient) {
      this.PostNewEpisode();      
    } 

    else if (this.isUpdateFrontDesk || this.isAutomatic) {
      this.VerifySignAndGetReport();      
    }

    else {
      this.FowardListDestination();
    }        
  }

  PostNewEpisode() {
    this.sigaFrontDeskService.NewSigaFrontDeskEpisode(this.patientRequest).subscribe({
      next: (response) => {

        if (response.isError) {
          if (response.errorCode == SigaSaveErrorsEnum.erroIntegraçãoSiga)
            this.alertService.show('Erro Integração SIGA!', response.errorDescription, AlertType.error);

          else
            this.alertService.show('Erro', response.errorDescription, AlertType.error);

          this.isLoading = false;
          return;
        }

        this.idEpisode = response.idEpisode;
        this.idPatient = response.idPatient;
        this.patientRequest.sigaPatientStruct.idEpisode = this.idEpisode;
        this.patientRequest.sigaPatientStruct.idPatient = this.idPatient;

        if (response.listSigaPatientStruct[0])
          this.model.get('cns').setValue(response.listSigaPatientStruct[0].cns);

        this.FowardListEpisodeDestination();    

      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      }
    });
  } 

  PostAttendance() {
    this.sigaFrontDeskService.SaveSigaFrontDesk(this.patientRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          if (response.errorCode == SigaSaveErrorsEnum.erroIntegraçãoSiga) {
            if (!this.patientRequest.sigaPatientStruct.idPatient)
              this.patientRequest.sigaPatientStruct.idPatient = response.idPatient;    

            this.alertService.show('Erro Integração SIGA!', response.errorDescription, AlertType.error);
            this.isLoading = false;
            return;
          }

          else if (response.errorCode == SigaSaveErrorsEnum.pacienteEmOutroLocal && 
          this.patientRequest.idQueue && 
          this.patientRequest.idFlow) {
            this.alertService.show('Aviso', response.errorDescription, AlertType.warning);          
          }

          else {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            this.isLoading = false;
            return;
          }
        }       
        
        else {
          this.alertService.show('Sucesso', "Paciente salvo e encaminhado com sucesso.", AlertType.success);
        }      

        if (response.automaticFoward && response.isAutomaticFoward) 
          this.alertService.show('Aviso', response.automaticFoward, AlertType.warning);

        let idQueue = this.patientRequest.idQueue;

        if (!idQueue)
          idQueue = response.idQueue;

        if (this.HasReport(response))
          this.openReportViewSelectModal(response, idQueue); 
        
        else if(this.isUpdateFrontDesk)
          this.FinalizeUpdateFrontDesk();

        else 
          this.CreateRelationQueueRoomAttendOnFrontDesk(idQueue);

      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      }
    });
  } 

  FinalizeUpdateFrontDesk() {    
    this.isLoading = false;
    this.router.navigate(['/front-desk/patient-list', { idSector: this.idSector, idRoom: this.idRoom }]); 
  }

  
  async MapPatientSigaRequestToSubmit(): Promise<void> {
    return new Promise((resolve) => {
      if (Number.isInteger(this.idPatient))
        this.patientRequest.sigaPatientStruct.idPatient = this.idPatient;
  
      if (Number.isInteger(this.idEpisode))
        this.patientRequest.sigaPatientStruct.idEpisode = this.idEpisode;
  
      if (Number.isInteger(this.idQueue))
        this.patientRequest.idQueue = this.idQueue;  
  
      this.patientRequest.idFlow == null;
      this.patientRequest.newPatient = this.newPatient;
      this.patientRequest.isUpdateFrontdesk = this.isUpdateFrontDesk;
      this.patientRequest.idRoom = this.idRoom;
      this.patientRequest.sigaPatientStruct.fullTicket = this.ticket;
      this.patientRequest.datetimeStartRegister = this.datetimeStartRegister;
  
      this.patientRequest.sigaPatientStruct.patientName = this.model.get('patientName').value;
      this.patientRequest.sigaPatientStruct.socialName = this.model.get('socialName').value ? this.model.get('socialName').value : null;
      this.patientRequest.sigaPatientStruct.cpf.cpfNumber = this.model.get('cpf').value ? this.model.get('cpf').value.replace(/[^0-9]+/g, '') : null;
      this.patientRequest.sigaPatientStruct.cpf.justification = this.model.get('firstChildGroup').get('cpfJustification').value;
      this.patientRequest.sigaPatientStruct.birthDate = this.maskService.formatStringToDate(this.model.get('birthDate').value);
      this.patientRequest.sigaPatientStruct.susSexCode = this.model.get('firstChildGroup').get('idGender').value;
      this.patientRequest.sigaPatientStruct.cns = this.model.get('cns').value ? this.model.get('cns').value.replace(/[^0-9]+/g, '').replace(" ", '') : null;
      this.patientRequest.sigaPatientStruct.patientContact.dddResidential = this.model.get('residentialPhone').value ? this.model.get('residentialPhone').value.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll(" ", "").substring(0, 2) : null;
      this.patientRequest.sigaPatientStruct.patientContact.residentialPhone = this.model.get('residentialPhone').value ? this.model.get('residentialPhone').value.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll(" ", "").substring(2) : null;
      this.patientRequest.sigaPatientStruct.patientContact.noCellPhone = this.model.get('noCellPhone').value;
      this.patientRequest.sigaPatientStruct.patientContact.dddCellPhone = this.model.get('cellPhone').value ? this.model.get('cellPhone').value.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll(" ", "").substring(0, 2) : null;
      this.patientRequest.sigaPatientStruct.patientContact.cellPhone = this.model.get('cellPhone').value ? this.model.get('cellPhone').value.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll(" ", "").substring(2) : null;
      this.patientRequest.sigaPatientStruct.patientContact.dddComercial = this.model.get('comercialPhone').value ? this.model.get('comercialPhone').value.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll(" ", "").substring(0, 2) : null;
      this.patientRequest.sigaPatientStruct.patientContact.comercialPhone = this.model.get('comercialPhone').value ? this.model.get('comercialPhone').value.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll(" ", "").substring(2) : null;
      this.patientRequest.sigaPatientStruct.patientContact.dddContact = this.model.get('contactPhone').value ? this.model.get('contactPhone').value.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll(" ", "").substring(0, 2) : null;
      this.patientRequest.sigaPatientStruct.patientContact.contactPhone = this.model.get('contactPhone').value ? this.model.get('contactPhone').value.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll(" ", "").substring(2) : null;
      this.patientRequest.sigaPatientStruct.patientContact.email = this.model.get('email').value;
      this.patientRequest.sigaPatientStruct.patientContact.contactName = this.model.get('contactName').value;
      this.patientRequest.sigaPatientStruct.identity.number = this.model.get('rg').value;
      this.patientRequest.sigaPatientStruct.identity.susEmissorCode = this.model.get('firstChildGroup').get('idExpeditionIssuer').value;
      this.patientRequest.sigaPatientStruct.identity.emissionDate = this.maskService.formatStringToDate(this.model.get('expeditionDate').value);
      this.patientRequest.sigaPatientStruct.identity.ufCodeSus = this.model.get('firstChildGroup').get('idExpeditionUf').value;
      this.patientRequest.sigaPatientStruct.unknownMother = this.model.get('unknownMother').value;
      this.patientRequest.sigaPatientStruct.motherName = this.model.get('motherName').value;
      this.patientRequest.sigaPatientStruct.unknownFather = this.model.get('unknownFather').value;
      this.patientRequest.sigaPatientStruct.fatherName = this.model.get('fatherName').value;
      this.patientRequest.sigaPatientStruct.susRaceCode = this.model.get('firstChildGroup').get('idRace').value;
      this.patientRequest.sigaPatientStruct.susEthnicityCode = this.model.get('firstChildGroup').get('idEthnicity').value;
      this.patientRequest.sigaPatientStruct.susFamilySituationCode = this.model.get('firstChildGroup').get('idFamilySituation').value;
      this.patientRequest.sigaPatientStruct.susEducationCode = this.model.get('firstChildGroup').get('idEducation').value;
      this.patientRequest.sigaPatientStruct.frequentsSchool = this.model.get('frequentsSchool').value;
      this.patientRequest.sigaPatientStruct.susOccupationCode = this.model.get('firstChildGroup').get('idOccupation').value;
      this.patientRequest.sigaPatientStruct.nationalityTypeCode = this.model.get('firstChildGroup').get('idNationalityType').value;
      this.patientRequest.sigaPatientStruct.birthCitytCodeSus = this.model.get('firstChildGroup').get('idBirthCity').value;
      this.patientRequest.sigaPatientStruct.countryArrivalDate = this.maskService.formatStringToDate(this.model.get('countryArrivalDate').value);
      this.patientRequest.sigaPatientStruct.naturalizationOrdinance = this.model.get('naturalizationOrdinance').value;
      this.patientRequest.sigaPatientStruct.naturalizationDate = this.maskService.formatStringToDate(this.model.get('naturalizationDate').value);
      this.patientRequest.sigaPatientStruct.registerProtocol = this.model.get('registerProtocol').value;
      this.patientRequest.sigaPatientStruct.patientAddress.originAddress = this.model.get('firstChildGroup').get('idOriginAddress').value;
      this.patientRequest.sigaPatientStruct.patientAddress.zipCode = this.model.get('zipCode').value ? this.model.get('zipCode').value.replace("-", "") : null;
      this.patientRequest.sigaPatientStruct.patientAddress.streetType = this.model.get('firstChildGroup').get('idStreet').value;
      this.patientRequest.sigaPatientStruct.patientAddress.street = this.model.get('street').value;
      this.patientRequest.sigaPatientStruct.patientAddress.neighborhood = this.model.get('neighborhood').value;
      this.patientRequest.sigaPatientStruct.patientAddress.residenceCitySus = this.model.get('firstChildGroup').get('idCity').value;
      this.patientRequest.sigaPatientStruct.patientAddress.admDistrictCodeStruct = this.model.get('firstChildGroup').get('idAdmDistrict').value;
      this.patientRequest.sigaPatientStruct.patientAddress.noHouseNumber = this.model.get('noHouseNumber').value;
      this.patientRequest.sigaPatientStruct.patientAddress.houseNumber = this.model.get('houseNumber').value?.toString();
      this.patientRequest.sigaPatientStruct.patientAddress.houseComplement = this.model.get('houseComplement').value?.toString();
      this.patientRequest.sigaPatientStruct.patientAddress.referencePoint = this.model.get('referencePoint').value;
      this.patientRequest.sigaPatientStruct.hasSusHealthInsurance = this.model.get('hasSusHealthInsurance').value;
      this.patientRequest.sigaPatientStruct.susHealthInsuranceCode = this.model.get('firstChildGroup').get('idHealthInsurance').value;
      this.patientRequest.sigaPatientStruct.codigoCnesEstabelecimentoCadastro = this.model.get('cnesEstablishmentRegistrationCode').value;
      this.patientRequest.sigaPatientStruct.codigoCnesEstabelecimentoVinculo = this.model.get('cnesEstablishmentBondCode').value;
      this.patientRequest.sigaPatientStruct.birthCountryCode = this.model.get('firstChildGroup').get('idBirthCountry').value;
      this.patientRequest.sigaPatientStruct.dnv = this.model.get('dnv').value;
      this.patientRequest.sigaPatientStruct.foreignIdentity.number = this.model.get('foreignIdentity').value;
      this.patientRequest.sigaPatientStruct.foreignIdentity.expirationDate = this.maskService.formatStringToDate(this.model.get('foreignExpirationDate').value);
      this.patientRequest.sigaPatientStruct.foreignIdentity.naturality = this.model.get('firstChildGroup').get('idForeignNaturality').value;
      this.patientRequest.sigaPatientStruct.foreignIdentity.susEmissorCode = this.model.get('firstChildGroup').get('idForeignEmissor').value;
      this.patientRequest.sigaPatientStruct.voterRegistration.number = this.model.get('voterRegistration').value;
      this.patientRequest.sigaPatientStruct.voterRegistration.emissionDate = this.maskService.formatStringToDate(this.model.get('voterEmissionDate').value);
      this.patientRequest.sigaPatientStruct.voterRegistration.zone = this.model.get('voterZone').value;
      this.patientRequest.sigaPatientStruct.voterRegistration.section = this.model.get('voterSection').value;
      this.patientRequest.sigaPatientStruct.ctps.number = this.model.get('ctps').value;
      this.patientRequest.sigaPatientStruct.ctps.emissionDate = this.maskService.formatStringToDate(this.model.get('ctpsEmissionDate').value);
      this.patientRequest.sigaPatientStruct.ctps.ufSusCode = this.model.get('firstChildGroup').get('idUfCtps').value;
      this.patientRequest.sigaPatientStruct.ctps.series = this.model.get('ctpsSeries').value;
      this.patientRequest.sigaPatientStruct.pisPasep.number = this.model.get('pisPasep').value;
      this.patientRequest.sigaPatientStruct.cnh.cnhNumber = this.model.get('cnh').value;
      this.patientRequest.sigaPatientStruct.cnh.expirationDate = this.maskService.formatStringToDate(this.model.get('cnhExpirationDate').value);
      this.patientRequest.sigaPatientStruct.cnh.emissionDate = this.maskService.formatStringToDate(this.model.get('cnhEmissionDate').value);
      this.patientRequest.sigaPatientStruct.birthCertificate.isNewCertificate = this.model.get('isNewBirthCertificate').value;
      this.patientRequest.sigaPatientStruct.birthCertificate.newCertificate = new NewCretificate();
      this.patientRequest.sigaPatientStruct.birthCertificate.newCertificate.registerNumber = this.model.get('newBirthCertificate').value;
      this.patientRequest.sigaPatientStruct.birthCertificate.newCertificate.emissionDate = this.maskService.formatStringToDate(this.model.get('newBirthCertificateEmissionDate').value);
      this.patientRequest.sigaPatientStruct.birthCertificate.oldCertificate = new OldCretificate();
      this.patientRequest.sigaPatientStruct.birthCertificate.oldCertificate.registryName = this.model.get('oldBirthCertificate').value;
      this.patientRequest.sigaPatientStruct.birthCertificate.oldCertificate.pageNumber = this.model.get('oldBirthCertificatePageNumber').value;
      this.patientRequest.sigaPatientStruct.birthCertificate.oldCertificate.bookNumber = this.model.get('oldBirthCertificateBookNumber').value;
      this.patientRequest.sigaPatientStruct.birthCertificate.oldCertificate.termNumber = this.model.get('oldBirthCertificateTermNumber').value;
      this.patientRequest.sigaPatientStruct.birthCertificate.oldCertificate.emissionDate = this.maskService.formatStringToDate(this.model.get('oldBirthCertificateEmissionDate').value);
  
      this.patientRequest.sigaPatientStruct.marriageCertificate.isNewCertificate = this.model.get('isNewMarriageCertificate').value;
      this.patientRequest.sigaPatientStruct.marriageCertificate.newCertificate = new NewCretificate();
      this.patientRequest.sigaPatientStruct.marriageCertificate.newCertificate.registerNumber = this.model.get('newMarriageCertificate').value;
      this.patientRequest.sigaPatientStruct.marriageCertificate.newCertificate.emissionDate = this.maskService.formatStringToDate(this.model.get('newMarriageCertificateEmissionDate').value);
      this.patientRequest.sigaPatientStruct.marriageCertificate.oldCertificate = new OldCretificate();
      this.patientRequest.sigaPatientStruct.marriageCertificate.oldCertificate.registryName = this.model.get('oldMarriageCertificate').value;
      this.patientRequest.sigaPatientStruct.marriageCertificate.oldCertificate.pageNumber = this.model.get('oldMarriageCertificatePageNumber').value;
      this.patientRequest.sigaPatientStruct.marriageCertificate.oldCertificate.bookNumber = this.model.get('oldMarriageCertificateBookNumber').value;
      this.patientRequest.sigaPatientStruct.marriageCertificate.oldCertificate.termNumber = this.model.get('oldMarriageCertificateTermNumber').value;
      this.patientRequest.sigaPatientStruct.marriageCertificate.oldCertificate.emissionDate = this.maskService.formatStringToDate(this.model.get('oldMarriageCertificateEmissionDate').value);
  
      this.patientRequest.sigaPatientStruct.divorceCertificate.isNewCertificate = this.model.get('isNewDivorceCertificate').value;
      this.patientRequest.sigaPatientStruct.divorceCertificate.newCertificate = new NewCretificate();
      this.patientRequest.sigaPatientStruct.divorceCertificate.newCertificate.registerNumber = this.model.get('newDivorceCertificate').value;
      this.patientRequest.sigaPatientStruct.divorceCertificate.newCertificate.emissionDate = this.maskService.formatStringToDate(this.model.get('newDivorceCertificateEmissionDate').value);
      this.patientRequest.sigaPatientStruct.divorceCertificate.oldCertificate = new OldCretificate();
      this.patientRequest.sigaPatientStruct.divorceCertificate.oldCertificate.registryName = this.model.get('oldDivorceCertificate').value;
      this.patientRequest.sigaPatientStruct.divorceCertificate.oldCertificate.pageNumber = this.model.get('oldDivorceCertificatePageNumber').value;
      this.patientRequest.sigaPatientStruct.divorceCertificate.oldCertificate.bookNumber = this.model.get('oldDivorceCertificateBookNumber').value;
      this.patientRequest.sigaPatientStruct.divorceCertificate.oldCertificate.termNumber = this.model.get('oldDivorceCertificateTermNumber').value;
      this.patientRequest.sigaPatientStruct.divorceCertificate.oldCertificate.emissionDate = this.maskService.formatStringToDate(this.model.get('oldDivorceCertificateEmissionDate').value);
  
      this.patientRequest.sigaPatientStruct.indigenousAdmCertificate.isNewCertificate = this.model.get('isNewIndigenousCertificate').value;
      this.patientRequest.sigaPatientStruct.indigenousAdmCertificate.newCertificate = new NewCretificate();
      this.patientRequest.sigaPatientStruct.indigenousAdmCertificate.newCertificate.registerNumber = this.model.get('newIndigenousAdmCertificate').value;
      this.patientRequest.sigaPatientStruct.indigenousAdmCertificate.newCertificate.emissionDate = this.maskService.formatStringToDate(this.model.get('newIndigenousAdmCertificateEmissionDate').value);
      this.patientRequest.sigaPatientStruct.indigenousAdmCertificate.oldCertificate = new OldCretificate();
      this.patientRequest.sigaPatientStruct.indigenousAdmCertificate.oldCertificate.registryName = this.model.get('oldIndigenousAdmCertificate').value;
      this.patientRequest.sigaPatientStruct.indigenousAdmCertificate.oldCertificate.pageNumber = this.model.get('oldIndigenousAdmCertificatePageNumber').value;
      this.patientRequest.sigaPatientStruct.indigenousAdmCertificate.oldCertificate.bookNumber = this.model.get('oldIndigenousAdmCertificateBookNumber').value;
      this.patientRequest.sigaPatientStruct.indigenousAdmCertificate.oldCertificate.termNumber = this.model.get('oldIndigenousAdmCertificateTermNumber').value;
      this.patientRequest.sigaPatientStruct.indigenousAdmCertificate.oldCertificate.emissionDate = this.maskService.formatStringToDate(this.model.get('oldIndigenousAdmCertificateEmissionDate').value);
  
      this.patientRequest.sigaPatientStruct.passport.number = this.model.get('passport').value;
      this.patientRequest.sigaPatientStruct.passport.emissionCountry = this.model.get('firstChildGroup').get('idPassportEmissionCountry').value;
      this.patientRequest.sigaPatientStruct.passport.emissionDate = this.maskService.formatStringToDate(this.model.get('passportEmissionDate').value);
      this.patientRequest.sigaPatientStruct.passport.expirationDate = this.maskService.formatStringToDate(this.model.get('passportExpirationDate').value);
      this.patientRequest.sigaPatientStruct.isPriority = this.model.get('isPriority').value;
      this.patientRequest.sigaPatientStruct.listMetadata = [];
      this.patientRequest.sigaPatientStruct.observation = this.model.get('observation').value;
      this.patientRequest.sigaPatientStruct.idArrivalType = this.model.get('arrivalType').value;
      this.patientRequest.sigaPatientStruct.idOrigin = this.model.get('origin').value;
      this.patientRequest.sigaPatientStruct.companionName = this.model.get('companionName').value;
      this.patientRequest.sigaPatientStruct.companionPhone = this.model.get('companionPhone').value;
      this.patientRequest.sigaPatientStruct.companionCpf = this.model.get('companionCpf').value ? this.model.get('companionCpf').value.replace(/[^0-9]+/g, '') : null;
      this.patientRequest.sigaPatientStruct.companionParentage = this.model.get('companionParentage').value;
      this.patientRequest.sigaPatientStruct.listIdPatientNecessity = this.model.get('listIdPatientNecessity').value;

      for (let item of this.listMetaDataStruct) {
        const formControl = this.model.get(item.metaData.idMetaData.toString());
  
        if (formControl && formControl.value) {        
          let metadataSend = new MetaDataKeyValueStruct();
  
          metadataSend.key = item.metaData.idMetaData;
          metadataSend.value = item.metaDataType.idMetaDataType === MetaDataTypeEnum.Numero ?
            formControl.value.toString() : formControl.value;
  
          this.patientRequest.sigaPatientStruct.listMetadata.push(metadataSend);
        }
      }

      resolve(); 

    });
  }

  populatePatientData() {
    this.idPatient = this.patientData.idPatient;
    this.model.get('patientName').setValue(this.patientData.patientName);
    this.model.get('socialName').setValue(this.patientData.socialName);
    this.model.get('cpf').setValue(this.patientData.cpf.cpfNumber);
    this.model.get('firstChildGroup').get('cpfJustification').setValue(this.patientData.cpf.justification);
    if (this.model.get('firstChildGroup').get('cpfJustification').value) {
      this.model.get('cpf').setValidators(null);
      this.model.get('cpf').updateValueAndValidity();
    }

    if (this.model.get('cpf').value) {
      this.model.get('firstChildGroup').get('cpfJustification').setValidators(null);
      this.model.get('firstChildGroup').get('cpfJustification').updateValueAndValidity();
    }

    this.model.get('birthDate').setValue(this.maskService.formatDateToString(this.patientData.birthDate, false));
    this.model.get('firstChildGroup').get('idGender').setValue(this.patientData.susSexCode ? this.patientData.susSexCode.toString() : null);
    this.model.get('cns').setValue(this.patientData.cns);
    this.model.get('residentialPhone').setValue(this.patientData.patientContact.dddResidential ? (this.patientData.patientContact.dddResidential + this.patientData.patientContact.residentialPhone) : null);
    this.model.get('noCellPhone').setValue(this.patientData.patientContact.noCellPhone);
    this.model.get('cellPhone').setValue(this.patientData.patientContact.dddCellPhone ? (this.patientData.patientContact.dddCellPhone + this.patientData.patientContact.cellPhone) : null);
    this.model.get('comercialPhone').setValue(this.patientData.patientContact.dddComercial ? (this.patientData.patientContact.dddComercial + this.patientData.patientContact.comercialPhone) : null);
    this.model.get('contactPhone').setValue(this.patientData.patientContact.dddContact ? (this.patientData.patientContact.dddContact + this.patientData.patientContact.contactPhone) : null);
    this.model.get('email').setValue(this.patientData.patientContact.email);
    this.model.get('contactName').setValue(this.patientData.patientContact.contactName);
    this.model.get('rg').setValue(this.patientData.identity.number);
    this.model.get('firstChildGroup').get('idExpeditionIssuer').setValue(this.patientData.identity.susEmissorCode);
    this.model.get('expeditionDate').setValue(this.maskService.formatDateToString(this.patientData.identity.emissionDate, false));
    this.model.get('firstChildGroup').get('idExpeditionUf').setValue(this.patientData.identity.ufCodeSus);
    this.model.get('unknownMother').setValue(this.patientData.unknownMother);
    this.model.get('motherName').setValue(this.patientData.motherName);
    this.model.get('unknownFather').setValue(this.patientData.unknownFather);
    this.model.get('fatherName').setValue(this.patientData.fatherName);
    this.model.get('firstChildGroup').get('idRace').setValue(this.patientData.susRaceCode ? this.patientData.susRaceCode.toString() : null);
    this.model.get('firstChildGroup').get('idEthnicity').setValue(this.patientData.susEthnicityCode ? this.patientData.susEthnicityCode.toString() : null);
    this.model.get('firstChildGroup').get('idFamilySituation').setValue(this.patientData.susFamilySituationCode ? this.patientData.susFamilySituationCode.toString() : null);
    this.model.get('firstChildGroup').get('idEducation').setValue(this.patientData.susEducationCode ? this.patientData.susEducationCode.toString() : null);
    this.model.get('frequentsSchool').setValue(this.patientData.frequentsSchool);
    this.model.get('firstChildGroup').get('idOccupation').setValue(this.patientData.susOccupationCode ? this.patientData.susOccupationCode.toString() : null);
    this.model.get('firstChildGroup').get('idNationalityType').setValue(this.patientData.nationalityTypeCode ? this.patientData.nationalityTypeCode.toString() : null);
    this.model.get('firstChildGroup').get('idBirthCity').setValue(this.patientData.birthCitytCodeSus ? this.patientData.birthCitytCodeSus.toString() : null);
    this.model.get('countryArrivalDate').setValue(this.maskService.formatDateToString(this.patientData.countryArrivalDate, false));
    this.model.get('naturalizationOrdinance').setValue(this.patientData.naturalizationOrdinance);
    this.model.get('naturalizationDate').setValue(this.maskService.formatDateToString(this.patientData.naturalizationDate, false));
    this.model.get('registerProtocol').setValue(this.patientData.registerProtocol);
    this.model.get('firstChildGroup').get('idOriginAddress').setValue(this.patientData.patientAddress.originAddress);
    this.model.get('zipCode').setValue(this.patientData.patientAddress.zipCode?.replace("-", ""));
    this.model.get('firstChildGroup').get('idStreet').setValue(this.patientData.patientAddress.streetType);
    this.model.get('street').setValue(this.patientData.patientAddress.street);
    this.model.get('neighborhood').setValue(this.patientData.patientAddress.neighborhood);
    this.model.get('firstChildGroup').get('idCity').setValue(this.patientData.patientAddress.residenceCitySus);
    this.model.get('firstChildGroup').get('idAdmDistrict').setValue(this.patientData.patientAddress.admDistrictCodeStruct);
    this.model.get('noHouseNumber').setValue(this.patientData.patientAddress.noHouseNumber);
    this.model.get('houseNumber').setValue(this.patientData.patientAddress.houseNumber);
    this.model.get('houseComplement').setValue(this.patientData.patientAddress.houseComplement);
    this.model.get('referencePoint').setValue(this.patientData.patientAddress.referencePoint);
    this.model.get('hasSusHealthInsurance').setValue(this.patientData.hasSusHealthInsurance);
    this.model.get('firstChildGroup').get('idHealthInsurance').setValue(this.patientData.susHealthInsuranceCode);
    this.model.get('cnesEstablishmentRegistrationCode').setValue(this.patientData.codigoCnesEstabelecimentoCadastro);
    this.model.get('cnesEstablishmentBondCode').setValue(this.patientData.codigoCnesEstabelecimentoVinculo);
    this.model.get('firstChildGroup').get('idBirthCountry').setValue(this.patientData.birthCountryCode);
    this.model.get('dnv').setValue(this.patientData.dnv);
    this.model.get('foreignIdentity').setValue(this.patientData.foreignIdentity.number);
    this.model.get('foreignExpirationDate').setValue(this.maskService.formatDateToString(this.patientData.foreignIdentity.expirationDate, false));
    this.model.get('firstChildGroup').get('idForeignNaturality').setValue(this.patientData.foreignIdentity.naturality);
    this.model.get('firstChildGroup').get('idForeignEmissor').setValue(this.patientData.foreignIdentity.susEmissorCode);
    this.model.get('voterRegistration').setValue(this.patientData.voterRegistration.number);
    this.model.get('voterEmissionDate').setValue(this.maskService.formatDateToString(this.patientData.voterRegistration.emissionDate, false));
    this.model.get('voterZone').setValue(this.patientData.voterRegistration.zone);
    this.model.get('voterSection').setValue(this.patientData.voterRegistration.section);
    this.model.get('ctps').setValue(this.patientData.ctps.number);
    this.model.get('ctpsEmissionDate').setValue(this.maskService.formatDateToString(this.patientData.ctps.emissionDate, false));
    this.model.get('firstChildGroup').get('idUfCtps').setValue(this.patientData.ctps.ufSusCode);
    this.model.get('ctpsSeries').setValue(this.patientData.ctps.series);
    this.model.get('pisPasep').setValue(this.patientData.pisPasep.number);
    this.model.get('cnh').setValue(this.patientData.cnh.cnhNumber);

    this.model.get('cnhExpirationDate').setValue(this.maskService.formatDateToString(this.patientData.cnh.expirationDate, false));
    this.model.get('cnhEmissionDate').setValue(this.maskService.formatDateToString(this.patientData.cnh.emissionDate, false));

    this.model.get('isNewBirthCertificate').setValue(this.patientData.birthCertificate?.newCertificate ? true : false)
    this.model.get('newBirthCertificate').setValue(this.patientData.birthCertificate?.newCertificate?.registerNumber);
    this.model.get('newBirthCertificateEmissionDate').setValue(this.maskService.formatDateToString(this.patientData.birthCertificate?.newCertificate?.emissionDate, false));
    this.model.get('oldBirthCertificate').setValue(this.patientData.birthCertificate?.oldCertificate?.registryName);
    this.model.get('oldBirthCertificatePageNumber').setValue(this.patientData.birthCertificate?.oldCertificate?.pageNumber);
    this.model.get('oldBirthCertificateBookNumber').setValue(this.patientData.birthCertificate?.oldCertificate?.bookNumber);
    this.model.get('oldBirthCertificateTermNumber').setValue(this.patientData.birthCertificate?.oldCertificate?.termNumber);
    this.model.get('oldBirthCertificateEmissionDate').setValue(this.maskService.formatDateToString(this.patientData.birthCertificate?.oldCertificate?.emissionDate, false));

    this.model.get('isNewMarriageCertificate').setValue(this.patientData.marriageCertificate?.newCertificate ? true : false)
    this.model.get('newMarriageCertificate').setValue(this.patientData.marriageCertificate?.newCertificate?.registerNumber);
    this.model.get('newMarriageCertificateEmissionDate').setValue(this.maskService.formatDateToString(this.patientData.marriageCertificate?.newCertificate?.emissionDate, false));
    this.model.get('oldMarriageCertificate').setValue(this.patientData.marriageCertificate?.oldCertificate?.registryName);
    this.model.get('oldMarriageCertificatePageNumber').setValue(this.patientData.marriageCertificate?.oldCertificate?.pageNumber);
    this.model.get('oldMarriageCertificateBookNumber').setValue(this.patientData.marriageCertificate?.oldCertificate?.bookNumber);
    this.model.get('oldMarriageCertificateTermNumber').setValue(this.patientData.marriageCertificate?.oldCertificate?.termNumber);
    this.model.get('oldMarriageCertificateEmissionDate').setValue(this.maskService.formatDateToString(this.patientData.marriageCertificate?.oldCertificate?.emissionDate, false));

    this.model.get('isNewDivorceCertificate').setValue(this.patientData.divorceCertificate?.newCertificate ? true : false)
    this.model.get('newDivorceCertificate').setValue(this.patientData.divorceCertificate?.newCertificate?.registerNumber);
    this.model.get('newDivorceCertificateEmissionDate').setValue(this.maskService.formatDateToString(this.patientData.divorceCertificate?.newCertificate?.emissionDate, false));
    this.model.get('oldDivorceCertificate').setValue(this.patientData.divorceCertificate?.oldCertificate?.registryName);
    this.model.get('oldDivorceCertificatePageNumber').setValue(this.patientData.divorceCertificate?.oldCertificate?.pageNumber);
    this.model.get('oldDivorceCertificateBookNumber').setValue(this.patientData.divorceCertificate?.oldCertificate?.bookNumber);
    this.model.get('oldDivorceCertificateTermNumber').setValue(this.patientData.divorceCertificate?.oldCertificate?.termNumber);
    this.model.get('oldDivorceCertificateEmissionDate').setValue(this.maskService.formatDateToString(this.patientData.divorceCertificate?.oldCertificate?.emissionDate, false));

    this.model.get('isNewIndigenousCertificate').setValue(this.patientData.indigenousAdmCertificate?.newCertificate ? true : false)
    this.model.get('newIndigenousAdmCertificate').setValue(this.patientData.indigenousAdmCertificate?.newCertificate?.registerNumber);
    this.model.get('newIndigenousAdmCertificateEmissionDate').setValue(this.maskService.formatDateToString(this.patientData.indigenousAdmCertificate?.newCertificate?.emissionDate, false));
    this.model.get('oldIndigenousAdmCertificate').setValue(this.patientData.indigenousAdmCertificate?.oldCertificate?.registryName);
    this.model.get('oldIndigenousAdmCertificatePageNumber').setValue(this.patientData.indigenousAdmCertificate?.oldCertificate?.pageNumber);
    this.model.get('oldIndigenousAdmCertificateBookNumber').setValue(this.patientData.indigenousAdmCertificate?.oldCertificate?.bookNumber);
    this.model.get('oldIndigenousAdmCertificateTermNumber').setValue(this.patientData.indigenousAdmCertificate?.oldCertificate?.termNumber);
    this.model.get('oldIndigenousAdmCertificateEmissionDate').setValue(this.maskService.formatDateToString(this.patientData.indigenousAdmCertificate?.oldCertificate?.emissionDate, false));

    this.model.get('passport').setValue(this.patientData.passport.number);
    this.model.get('firstChildGroup').get('idPassportEmissionCountry').setValue(this.patientData.passport.emissionCountry);
    this.model.get('passportEmissionDate').setValue(this.maskService.formatDateToString(this.patientData.passport.emissionDate, false));
    this.model.get('passportExpirationDate').setValue(this.maskService.formatDateToString(this.patientData.passport.expirationDate, false));

    this.model.get('isPriority').setValue(this.patientData.isPriority);
    this.model.get('observation').setValue(this.patientData.observation);
    this.model.get('arrivalType').setValue(this.patientData.idArrivalType);
    this.model.get('origin').setValue(this.patientData.idOrigin);
    this.model.get('companionName').setValue(this.patientData.companionName);
    this.model.get('companionPhone').setValue(this.patientData.companionPhone);
    this.model.get('companionCpf').setValue(this.patientData.companionCpf);
    this.model.get('companionParentage').setValue(this.patientData.companionParentage);
    if (this.patientData.patientNecessities != null)
      this.model.get('listIdPatientNecessity').setValue(this.patientData.patientNecessities.map(c => c.idPatientNecessity));

    if (this.model.get('birthDate').value) {
      let patientAge = this.utilService.getAge(this.model.get('birthDate').value);
      this.model.get('patientAge').setValue(`${patientAge.years}a ${patientAge.months}m ${patientAge.days}d`);
    }

    if (this.model.get('unknownMother').value)
      this.model.get('motherName').disable();

    if (this.model.get('unknownFather').value)
      this.model.get('fatherName').disable();

    if (this.model.get('noCellPhone').value)
      this.model.get('cellPhone').disable();

    if (this.model.get('noHouseNumber').value)
      this.model.get('houseNumber').disable();

    if (this.model.get('hasSusHealthInsurance').value) {
      this.model.get('idHealthInsurance').enable();
      this.model.get('cnesEstablishmentRegistrationCode').enable();
      this.model.get('cnesEstablishmentBondCode').enable();
    }

    if (this.patientData.listMetadata != null && this.patientData.listMetadata.length > 0) {
      for (let item of this.listMetaData) {
        let valuePatient = this.patientData.listMetadata.find(c => c.key == item.metaData.idMetaData);
        if (valuePatient)
          this.model.get(item.metaData.idMetaData.toString()).setValue(valuePatient.value);
        else
          this.model.get(item.metaData.idMetaData.toString()).setValue(null);
      }
    }
  }

  populateLookupSelect() {
    this.isLoading = true;
    this.lookupMedicalRecord();

    this.sigaLookupService.getLookup().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error)
          return;
        }

        this.listAddressOrigin = response.listAddressOrigin;
        this.listBirthCountry = response.listBirthCountry;
        this.listCity = response.listCity;
        this.listDistrict = response.listDistrict;
        this.listEducation = response.listEducation;
        this.listEmissorEntity = response.listEmissorEntity;
        this.listEthnicity = response.listEthnicity;
        this.listHealthInsurance = response.listHealthInsurance;
        this.listNationality = response.listNationality;
        this.listNationalityType = response.listNationalityType;
        this.listOccupation = response.listOccupation;
        this.listRaces = response.listRaces;
        this.listSex = response.listSex;
        this.listState = response.listState;
        this.listStreetType = response.listStreetType;
        this.listParentalBond = response.listParentalBond;
        this.listFamilySituation = response.listFamilySituation;
        this.listJustification = response.listJustification;

        this.findLookup = true;
        this.isFirstLoading = false;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      },
    });
  }

  lookupMedicalRecord() {
    this.lookupService.getLookup().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error)
          return;
        }
        this.listPatientNecessity = response.listPatientNecessities;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getArrivalTypeAndOrigin() {
    this.GroupOriginByOrchestratorService.getGroupOrigin().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        this.originList = response.listOrigin;
        this.arrivalTypeList = response.listArrivalType;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getAllPriorities() {
    this.priorityService.listPriority().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listOfPriorities = response.listPriority;
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  getPatientById() {
    this.isLoading = true;

    this.sigaSearchPatientService.searchPatientById(this.idEpisode).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (response.listSigaPatientStruct.length > 0) {
          this.patientData = response.listSigaPatientStruct[0];
          this.populatePatientData();
        } else {
          this.alertService.show('Erro', "Paciente não encontrado na base de dados!", AlertType.error);
          this.isLoading = false;
          return;
        }
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getDateTimeStart() {
    this.clockService.getDate().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.datetimeStartRegister = response.date;
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  getAllMetaData(idSector: number, listMetaDataStruct: MetaDataStruct[]) {
    this.getFrontDeskBySectorService.getMetaDataFrontDesk(idSector, listMetaDataStruct).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isFirstLoading = false;
          this.isLoading = false;
          return;
        }

        this.isWristbandActive = response.frontDesk.idWristbandDimensions ? true : false;
        this.wristbandWidth = response.frontDesk.customWristbandWidth ?? response.wristbandDimensions.width;
        this.wristbandHeight = response.frontDesk.customWristbandHeight ?? response.wristbandDimensions.height;
        this.idIdentification = response.frontDesk.idIdentification;

        this.listMetaData = listMetaDataStruct.filter((item) => response.listMetaData.some(item2 => item.metaData.idMetaData == item2.idMetaData));
        if (this.listMetaData && this.listMetaData.length > 0) {
          for (let item of this.listMetaData) {
            if (item.metaData.isRequired)
              this.model.addControl(item.metaData.idMetaData.toString(), this.formBuilder.control('', [Validators.required]));
            else
              this.model.addControl(item.metaData.idMetaData.toString(), this.formBuilder.control(''));
          }
        }

        if (response.listIdMetaDataRequired != undefined && response.listIdMetaDataRequired.length > 0) {
          for (let item of response.listIdMetaDataRequired) {
            this.model.controls[item].setValidators([Validators.required]);
            this.model.controls[item].updateValueAndValidity();
          }
        }

        this.getDateTimeStart();
        if (this.patientData.cns != null)
          this.populatePatientData();
        else
          this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  public get metaDataTypeEnumResult(): typeof MetaDataTypeEnum {
    return MetaDataTypeEnum;
  }

  searchByCPF(cpf: string) {
    this.isLoading = true;
    this.isLoadingCpf = true;
    this.isLoading = true;
    let request: SearchPatientTotemRequest = new SearchPatientTotemRequest();
    request.cpf = cpf.replace(/[^0-9]/g, '').replace(' ', '');
    this.searchPatientFrontdeskService.searchPatientCpfCns(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          this.isLoadingCpf = false;
          return;
        }

        if (response.listPatient.length > 0)
          this.idPatient = response.listPatient[0].idPatient;

        this.isLoading = false;
        this.isLoadingCpf = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  searchByCNS(cns: string) {
    this.isLoading = true;
    this.isLoadingCns = true;
    let request: SearchPatientTotemRequest = new SearchPatientTotemRequest();
    request.cns = cns.replace(/[^0-9]/g, '').replace(' ', '');
    this.searchPatientFrontdeskService.searchPatientCpfCns(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          this.isLoadingCns = false;
          return;
        }

        if (response.listPatient.length == 1)
          this.idPatient = response.listPatient[0].idPatient;
        else if (response.listPatient.length > 1)
          this.openSelectPatientModal(response.listPatient);

        this.isLoading = false;
        this.isLoadingCns = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  searchByCEP(cep: string) {
    this.isLoadingCep = true;
    this.isLoading = true;
    this.viaCepService.getAddressByCep(cep).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoadingCep = false;
          this.isLoading = false;
          return;
        }

        this.model.get('zipCode').setValue(response.cep);
        this.model.get('street').setValue(response.logradouro);
        this.model.get('neighborhood').setValue(response.bairro);

        this.isLoadingCep = false;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  private timeoutKeySearch: any = null;

  onKeySearchCPF(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;

    let cpf = event.target.value.replace(/[^0-9]+/g, '');

    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13 && cpf.length == 11) {
        $this.model.get('firstChildGroup').get('cpfJustification').setValidators(null);
        $this.searchByCPF(cpf.replace(/[^0-9]+/g, ''));
      }
      else
        $this.model.get('firstChildGroup').get('cpfJustification').setValidators(Validators.required);

      $this.model.get('firstChildGroup').get('cpfJustification').updateValueAndValidity();
    },
      1000);
  }

  onKeySearchCNS(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13 && event.target.value.length > 5)
        $this.searchByCNS(event.target.value);
    },
      1000);
  }

  onKeySearchCEP(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13 && event.target.value.length > 5)
        $this.searchByCEP(event.target.value);
    },
      1000);
  }

  onKeySearchAge(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13 && event.target.value.length > 5) {
        let patientAge = $this.utilService.getAge(event.target.value);
        $this.model.get('patientAge').setValue(`${patientAge.years}a ${patientAge.months}m ${patientAge.days}d`);
      }
    },
      1000);
  }

  changeUnknownMother(event: any) {
    if (event.checked) {
      this.model.get('motherName').disable();
      this.model.get('motherName').setValue('');
      this.model.get('motherName').setValidators(null);
      this.model.get('motherName').updateValueAndValidity();
    }
    else {
      this.model.get('motherName').enable();
      this.model.get('motherName').setValidators([Validators.required]);
      this.model.get('motherName').updateValueAndValidity();
    }
  }

  changeUnknownFather(event: any) {
    if (event.checked) {
      this.model.get('fatherName').disable();
      this.model.get('fatherName').setValue('');
      this.model.get('fatherName').updateValueAndValidity();
    }
    else
      this.model.get('fatherName').enable();
  }

  changeCellPhone(event: any) {
    if (event.checked) {
      this.model.get('cellPhone').disable();
      this.model.get('cellPhone').setValue('');
      this.model.get('cellPhone').setValidators(null);
      this.model.get('cellPhone').updateValueAndValidity();
    }
    else {
      this.model.get('cellPhone').enable();
      this.model.get('cellPhone').setValidators([Validators.required]);
      this.model.get('cellPhone').updateValueAndValidity();
    }
  }

  changeHasHouseNumber(event: any) {
    if (event.checked) {
      this.model.get('houseNumber').disable();
      this.model.get('houseNumber').setValue('');
      this.model.get('houseNumber').setValidators([Validators.required]);
      this.model.get('houseNumber').updateValueAndValidity();
    }
    else {
      this.model.get('houseNumber').enable();
      this.model.get('houseNumber').setValidators([Validators.required]);
      this.model.get('houseNumber').updateValueAndValidity();
    }
  }

  changeHealthInsurance(event: any) {
    if (event.checked) {
      this.model.get('firstChildGroup').get('idHealthInsurance').enable();
      this.model.get('cnesEstablishmentRegistrationCode').enable();
      this.model.get('cnesEstablishmentBondCode').enable();
    }
    else {
      this.model.get('firstChildGroup').get('idHealthInsurance').disable();
      this.model.get('firstChildGroup').get('idHealthInsurance').setValue('');
      this.model.get('cnesEstablishmentRegistrationCode').disable();
      this.model.get('cnesEstablishmentRegistrationCode').setValue('');
      this.model.get('cnesEstablishmentBondCode').disable();
      this.model.get('cnesEstablishmentBondCode').setValue('');
    }
  }

  FowardListDestination() {
    let fowardRequest = new FowardRequest();
    fowardRequest.idEpisode = this.idEpisode;
    fowardRequest.idRoom = this.idRoom;

    this.fowardService.listDestination(fowardRequest).subscribe({
      next: (response) => {

        this.openModalForward(response);      
          
      },
      error: (error) => {
        this.isLoading = false;
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  FowardListEpisodeDestination() {
    let fowardRequest = new FowardRequest();
    fowardRequest.idEpisode = this.idEpisode;
    fowardRequest.idRoom = this.idRoom;

    this.fowardService.listEpisodeDestination(fowardRequest).subscribe({
      next: (response) => {

        this.openModalForward(response);

      },
      error: (error) => {
        this.isLoading = false;
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openModalForward(responseFoward: ForwardResponse) {
    if (responseFoward.isError) {
      this.alertService.show('Erro', responseFoward.errorDescription, AlertType.error);
      this.isLoading = false;
      return;
    }

    else {
      const dialogRef = this.dialog.open(SigaForwardPatientModalComponent, {
        disableClose: true,
        data: {
          listFoward: responseFoward.location,
          flowSequence: responseFoward.flowSequence,
          isFlowSequence: (responseFoward.flowSequence && responseFoward.flowSequence.length > 0),
        },
      });

      dialogRef.afterClosed().subscribe({
        next: (result) => {
          if (result && result.fowardPatient) {
            this.patientRequest.idFlow = result.idFlow;            
        
            if (responseFoward.flowSequence && responseFoward.flowSequence.length > 0)
              this.patientRequest.idFlowCompleted = responseFoward.flowSequence[0].idFlow;

            this.VerifySignAndGetReport();
          }             
        
          else {
            this.isLoading = false;
          }

        },
        error: (error) => {
          console.log(error);
        }
      });
    }
  }

  VerifySignAndGetReport(){
    if (this.sign)
      this.GetSignedReport();

    else
      this.PostAttendance();
  }

  GetSignedReport() {
    const dialogRef = this.dialog.open(SignatureCredentialsModalComponent, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result && result.sign) {
          this.patientRequest.idSignatureProvider = result.idSignatureProvider;
          this.patientRequest.signatureAuthentication = JSON.stringify(result.signatureAuthentication);
        }       

        this.PostAttendance();

      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  HasReport(response: PatientSigaResponse): Boolean {    
    return Boolean(
      response.admissionReportPdf64 ||
      response.wristbandReportPdf64 ||
      response.blankMedicalCareReportPdf64
    )
  }

  openReportViewSelectModal(response: PatientSigaResponse, idQueue: number = null) {
    let reportDialog = this.dialog.open(ReportViewSelectModalComponent, {
      data: {
        admissionReport: response.admissionReportPdf64,
        wristbandReport: response.wristbandReportPdf64,
        blankMedicalCareReport: response.blankMedicalCareReportPdf64,
      },
    });

    reportDialog.afterClosed().subscribe({
      next: () => {
        if(this.isUpdateFrontDesk) {
          this.FinalizeUpdateFrontDesk();
        }          

        else {
          this.CreateRelationQueueRoomAttendOnFrontDesk(idQueue); 
        }
                
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.CreateRelationQueueRoomAttendOnFrontDesk(idQueue);     
      }
    });
  }

  CreateRelationQueueRoomAttendOnFrontDesk(idQueue: number){
    this.isLoading = true;  

    if (this.isUpdateFrontDesk == true){
      this.router.navigate(['/front-desk/patient-list', { idSector: this.idSector, idRoom: this.idRoom }]);
      this.isLoading = false;
      return;
    }

    let request: PostPutRelationQueueRoomAttendOnFrontDeskRequest = new PostPutRelationQueueRoomAttendOnFrontDeskRequest();
    request.idQueue = idQueue ? idQueue : 0;
    request.idRoomAttendance = this.idRoom ? this.idRoom : 0;    

    this.relationQueueRoomAttendOnFrontDeskService.createRelationQueueRoomAttendOnFrontDesk(request).subscribe({
      next: (response) => {
        if (response.isError) 
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
        
        this.emitIsAttended.emit(true);
        this.router.navigate(['/front-desk/patient-list', { idSector: this.idSector, idRoom: this.idRoom }]);
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  checkActualFlow() {
    this.getPatientFlowService.checkPatientFlow(this.idEpisode).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isAutomatic = response.isAutomatic;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      }
    });
  }

  openSelectPatientModal(patients: PatientStruct[]) {
    const dialogRef = this.dialog.open(SelectPatientModalComponent, {
      data: patients,
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result)
          this.idPatient = result.idPatient;
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  cpfJustificationSelect(event) {
    if (!event) {
      this.model.get('cpf').setValidators([Validators.required]);
      this.model.get('cpf').enable();
    }
    else {
      this.model.get('cpf').setValidators(null);
      this.model.get('cpf').setValue(null);
      this.model.get('cpf').disable();
    }

    this.model.get('cpf').updateValueAndValidity();
  }

  getListMetaData(idSector: number) {
    this.metaDataService.getAllMetaData().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listMetaDataStruct = response.listMetaDataStruct;

        if (response.listMetaDataStruct)
          this.getAllMetaData(idSector, this.listMetaDataStruct)
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  checkSigningResponsibility(): boolean {
    let token: UserLoginResponse = this.utilsService.getToken();
    return (token.listRole.map(x => x.idRole).includes(UserRoleEnum.requerer_assinatura_recepcao) && !token.isMaster);
  }
}