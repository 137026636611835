import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { retry, timeout } from 'rxjs';
import { ChangelogResponse } from '../../responses/orchestrator-changelog/changelog.response';
import { ChangelogSearchRequest } from '../../requests/changelog/changelog-search.request';

@Injectable({
  providedIn: 'root'
})
export class ChangelogService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  public Search(request: ChangelogSearchRequest): Observable<ChangelogResponse> {

    let uri = `Changelog/Search`;

    return this.httpClient.post<ChangelogResponse>(environment.urlApiChangelog + uri, request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }
}