export class MedicConfigRequest {
    public medicConfigName: string;

    public medicConfigDescription: string;

    public listIdRequiredField: number[];

    public listIdSector: number[];

    public toggleAllMedicine: boolean;

    public toggleAllExam: boolean;

    public listIdMedicine: number[];

    public listIdExam: number[];

    public fastTrack: boolean;

    public allowEvasion: boolean;

    public remoteAttendance: boolean;

    public pin: string;

    public watingTime: number;

    public idFinalizationType: number;

    public maxReturns: number;
}