import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { ClinicalObservationRequest } from '../../requests/orchestrator-patient/clinical-observation.request';

@Injectable({
  providedIn: 'root'
})
export class ClinicalObservationService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public post(observation: ClinicalObservationRequest): Observable<ReturnStruct> {
    let uri = `ClinicalObservation`
    return this.httpClient.post<ReturnStruct>(environment.urlApiSyncMeasurer + uri, observation, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
