export class MedicFavoriteExamPrescriptionStruct {
    public idFavoriteExamPrescription: number;
    public idDoctorFavoriteGroupExamPrescription: number;
    public idPlace: number;
    public placeName: string;
    public idExamType: number;
    public examTypeName: string;
    public description: string;
    public listIdExam: number[] = [];
    public idSadtSchedule: number;
    public sadtScheduleName: string;
}