<div mat-dialog-header>
    <div class="head-user-modal-list">
        <h2>Histórico de Login</h2>
    </div>
    <div class="head-details">
        <span>IP</span>
        <span>Data do login</span>
    </div>
</div>
<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="body-user-modal-list" *ngIf="!isLoading">
            <div class="details" *ngFor="let item of listLogLoginHistory">
                <span>{{item.ip}}</span>
                <span>{{item.datetimeInclusion | date:'dd/MM/yyyy \'às\' HH:mm'}}</span>
            </div>
        </div>
        <app-loading-list *ngIf="isLoading"></app-loading-list>
        <app-empty-list *ngIf="listLogLoginHistory && listLogLoginHistory.length == 0  && !isLoading">
        </app-empty-list>
    </div>
</div>
<div mat-dialog-footer class="footer-modal">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12">
            <mat-paginator *ngIf="listLogLoginHistory" [length]="this.fullListSize"
                #paginator [pageIndex]="0" [pageSize]="this.pageSize"
                aria-label="Select page" (page)="changePage($event)" [hidePageSize]="true">
            </mat-paginator>
        </div>
    </div>
    <div class="footer-user-modal-list">
        <button mat-flat-button color="accent" class="btn-block"(click)="close()">Fechar</button>
    </div>
</div>
