import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertService } from 'src/app/shared/services/alert.service';
import { PatientStruct } from 'src/app/shared/services/structs/medical-record/patient.struct';

@Component({
  selector: 'app-list-patient',
  templateUrl: './list-patient.component.html',
  styleUrls: ['./list-patient.component.css']
})
export class ListPatientComponent implements OnInit {

  constructor(
    private alertService: AlertService,
  ) { }
    
    @Input() index: number;
    @Input() listPatient: PatientStruct[];
    
    @Output() patientDetails = new EventEmitter<number>();
    @Output() deletePatient = new EventEmitter<number>();
    
    ngOnInit(): void {
         
    }
    
    details(event){
      this.patientDetails.emit(event);
    }
    
    remove(event){
      this.deletePatient.emit(event);
    }
  }
