<div mat-dialog-content>
    <div class="mat-dialog-header">
        <h3>Adicionar medicamento</h3>
        <mat-slide-toggle class="medicated_solution" [checked]="isMedicatedSolution" (change)="changeToMedicatedSolution($event)">
            Montar solução
        </mat-slide-toggle>
    </div>

    <div class="mat-dialog-content">
        <app-favorite-medicine-form *ngIf="!isMedicatedSolution"
            [medicalPrescription]="this.medicalPrescription.favoriteStandardMedicine"
            [selectedMedicineList]="this.selectedMedicineList"
            [listSubFrequency]="this.listSubFrequency"
            [lookupMedicalCareResponse]="this.lookupMedicalCareResponse"
            [listMedicine]="this.listMedicine"
            (save)="submit($event)"
            (cancel)="close()">
        </app-favorite-medicine-form>
        <app-favorite-medicated-solution-form *ngIf="isMedicatedSolution"
            [medicatedSolutionStruct]="this.medicalPrescription.favoriteMedicatedSolutionStruct"
            [selectedMedicineList]="this.selectedMedicineList"
            [listSubFrequency]="this.listSubFrequency"
            [lookupMedicalCareResponse]="this.lookupMedicalCareResponse"
            [listMedicine]="this.listMedicine"
            (save)="submit($event)"
            (cancel)="close()">
        </app-favorite-medicated-solution-form>
    </div>
</div>