import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { BusinessIntelligenceReportResponse } from '../../responses/business-intelligence/business-intelligence-report.response';
import { GetBiPanelResponse } from '../../responses/business-intelligence/get-bi-panel.response';

@Injectable({
  providedIn: 'root'
})
export class ClientBiPanelService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public verifyPin(idBiPanel:number, pin: string): Observable<GetBiPanelResponse> {
    let uri = `ClientBiPanel/idBiPanel/${idBiPanel}/pin/${pin}`

    return this.httpClient.get<GetBiPanelResponse>(environment.urlApiBusinessIntelligence + uri)
    .pipe(
      catchError(this.handleError)
    )
  }
}
