

export class StorageAmountLocationStruct {
    
    public idStorageAmountLocation: number;

    public idLocation: number;

    public idStorage: number;

    public amountTotal: number;

    public amountUsed: number;

    public dateTimeInclusion: Date;

    public location: string;

    public amountRemaining: number;

    public numberDrops: number;
}