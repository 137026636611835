export class TotemButtonStruct{
    
    public idTotemButton: number;
    
    public totemButtonName: string;

    public initials: string;

    public priorityInitials: string;
    
    public idTotem: number;
    
    public totemName: string;

    public idCareLinePriority: number;

    public isScheduleToggle: boolean;
}