import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { ListCareLinePriorityResponse } from '../../responses/care-line-priority/list-care-line-priority.response';
import { retry, timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GetCareLinePriorityService extends CommonService {

  constructor(private httpClient: HttpClient) { super(); }

  public getAllByIdHealthUnit(): Observable<ListCareLinePriorityResponse> {
    return this.httpClient.get<ListCareLinePriorityResponse>(environment.urlApicareLinePriority + `GetCareLinePriority/getAllByIdHealthUnit`, this.addHeaderToken())
      .pipe(timeout(environment.requestTimeOut),
        retry(environment.requestRetry),
        catchError(this.handleError));
  }
}