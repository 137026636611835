import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { PizzaAxisStruct } from 'src/app/shared/services/structs/orchestrator-data-intelligence/pizza-axis.struct';

@Component({
  selector: 'app-plot-pie',
  templateUrl: './plot-pie.component.html',
  styleUrls: ['./plot-pie.component.css']
})
export class PlotPieComponent implements OnInit {

  @Input() variableName: string;
  @Input() idSize: number;
  @Input() listPieGraphData: PizzaAxisStruct[]; //[{label:"", value:""}]

  @ViewChildren(BaseChartDirective) charts: QueryList<BaseChartDirective>;
  public pieChartType: ChartType = 'pie';

  public pieChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'right',
      },
    }
  };
  public pieChartData: ChartData<'pie'>;


  ngOnInit(): void {
    this.pieChartData = {
      labels: this.listPieGraphData.map(c => c.label),
      datasets: [
        { data: this.listPieGraphData.map(c => c.value), label: this.variableName },
      ]
    }
  }
}
