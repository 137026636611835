import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-grievance-notification',
  templateUrl: './grievance-notification.component.html',
  styleUrls: ['./grievance-notification.component.css']
})
export class GrievanceNotificationComponent implements OnInit{

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.patient_history;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.grievance_notification;
  public isLoading: boolean;

  ngOnInit(): void {
    this.isLoading = false;
  }
}
