import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-audit-remove-prior-reason-modal',
  templateUrl: './audit-remove-prior-reason-modal.component.html',
  styleUrls: ['./audit-remove-prior-reason-modal.component.css']
})
export class AuditRemovePriorReasonModalComponent implements OnInit{
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<AuditRemovePriorReasonModalComponent>,
  private alertService: AlertService,) { }
  public isChecked: boolean = false;
  public isLoading:boolean; 

  ngOnInit(): void {
    this.isChecked = false;
    this.isLoading = false;
  }

  confirmDelete()
  {
    if(this.isChecked == true)
    {
      this.matDialogRef.close({confirmDelete: true, checked: true});
    }
    else
    {
      this.matDialogRef.close({confirmDelete: true});
    }
  }

  close()
  {
    if(this.isChecked == true)
    {
      this.matDialogRef.close({checked: true});
    }
    else
    {
      this.matDialogRef.close();
    }
  }

  clickCheckbox()
  {
    if(this.isChecked == false)
    {
      this.isChecked = true;
    }
    else
    {
      this.isChecked = false;
    }
  }
}
