<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body tab-header">
        <div class="row">
            <div class="col-12">
                <h1>Novo Procedimento</h1>
                <div class="white-body">
                    <div class="col-12">
                        <mat-tab-group>
                            <div class="white-body">
                                <mat-tab label="Dados básicos">
                                    <app-procedure-basic-data *ngIf="!isLoading" [listTerminologyOrigin]="listTerminologyOrigin"
                                        [procedureStruct]="procedureStruct"
                                        (onProcedureFilled)="onProcedureFilled($event)"
                                        [isUpdate]="isUpdate">
                                    </app-procedure-basic-data>
                                </mat-tab>
                                <mat-tab label="Valores por convênio" [disabled]="!procedureFieldsFilled">
                                    <app-procedure-price-by-healthcare *ngIf="!isLoading"
                                        [listPriceProcedureHealthcareStruct]="listPriceProcedureHealthcareStruct"
                                        [idProcedure]="idProcedure"
                                        [fullListSize]="fullSizeListPriceProcedureHealthcare">
                                    </app-procedure-price-by-healthcare>
                                </mat-tab>
                                <mat-tab label="Procedimentos derivados" [disabled]="!procedureFieldsFilled">
                                    <app-procedure-derivatives *ngIf="!isLoading" [listProcedureRelationshipStruct]="listProcedureRelationshipStruct" [procedureStruct]="procedureStruct">
                                    </app-procedure-derivatives>
                                </mat-tab>                                
                            </div>
                        </mat-tab-group>
                    </div>
                </div>
                <app-loading-list *ngIf="isLoading"></app-loading-list>
            </div>
        </div>
    </div>
</div>