<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    <div class="container-body">
        <div class="sub-title-and-buttons">
            <h1>Controle de lotes</h1>
            <div class="search-and-submit-button">
                <button mat-flat-button type="button" color="primary" class="btn-block" (click)="createNewBatch()">
                    <span *ngIf="isLoading == false">+ Gerar novo lote</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
            </div>
        </div>
        
        <div class="white-body">
            <div class="border-box">    
                <h2>Lotes Gerados</h2>            
                <div class="filters">
                    <div class="row">
                        <div class="col-12 col-lg-6 col-xl-3">
                            <mat-form-field appearance="outline">
                                <mat-icon matPrefix class="my-icon">search</mat-icon>
                                <mat-label>Pesquisar</mat-label>
                                <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-lg-6 col-xl-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Período de envio do lote</mat-label>
                                <mat-date-range-input [rangePicker]="pickerOne">
                                    <input matStartDate [(ngModel)]="beginDatetimeSendGuideBatch" placeholder="Início">
                                    <input matEndDate [(ngModel)]="endDatetimeSendGuideBatch" placeholder="Fim">
                                </mat-date-range-input>
                                <mat-datepicker-toggle matSuffix [for]="pickerOne"></mat-datepicker-toggle>
                                <mat-date-range-picker #pickerOne></mat-date-range-picker>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-lg-6 col-xl-3">
                            <mat-form-field>
                                <mat-label>Data do processamento</mat-label>
                                <input matInput [matDatepicker]="pickerTwo" [(ngModel)]="datetimeProcessingEnd">
                                <mat-datepicker-toggle matIconSuffix [for]="pickerTwo"></mat-datepicker-toggle>
                                <mat-datepicker #pickerTwo></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-lg-6 col-xl-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Convênio</mat-label>
                                <mat-select [(ngModel)]="idHealthcareAgreement">
                                    <mat-option [value]="null">Nenhum</mat-option>
                                    <mat-option *ngFor="let item of listHealthcareAgreementStruct"
                                        [value]="item.idHealthcareAgreement">
                                        {{item.nameHealthcareAgreement}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-3">
                            <button mat-flat-button type="button" color="primary" class="btn-block"
                                (click)="changePage(0)">
                                <span *ngIf="isLoading == false">Pesquisar</span>
                                <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                    *ngIf="isLoading == true"></mat-spinner>
                            </button>
                        </div>
                    </div>
                </div>
                <app-loading-progress-bar *ngIf="isLoading"></app-loading-progress-bar>
                <div class="table-block">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Número</th>
                                <th>Convênio</th>
                                <th>Processamento</th>
                                <th>Envio</th>
                                <th>Tipo</th>
                                <th>Nº de guias</th>
                                <th>Valor (R$)</th>
                                <th>Status</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="listGuideBatchStruct && listGuideBatchStruct.length > 0">
                            <tr *ngFor="let guideBatchStruct of listGuideBatchStruct">
                                <td>{{ guideBatchStruct.guideBatch.batchNumber.toString().padStart(12, '0') }}</td>
                                <td>{{ guideBatchStruct.nameHealthcareAgreement }}</td>
                                <td>{{ guideBatchStruct.guideBatch.datetimeProcessingEnd | date: 'dd/MM/yyy'  }}</td>
                                <td>{{ guideBatchStruct.guideBatch.datetimeSendGuideBatch | date: 'dd/MM/yyy' }}</td>
                                <td>{{ guideBatchStruct.guideTypeName }}</td>
                                <td>{{ guideBatchStruct.numberOfGuides }}</td>
                                <td>{{ guideBatchStruct.totalGuideValue | currency : 'R$'}}</td>
                                <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Selecione</mat-label>
                                        <mat-select [(value)]="guideBatchStruct.guideBatch.idSendGuideBatchStatus"
                                            (selectionChange)="putStatus(guideBatchStruct)">
                                            <mat-option *ngFor="let item of listSendGuideBatchStatus"
                                                [value]="item.idSendGuideBatchStatus">
                                                {{item.sendGuideBatchStatusName}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <div class="action-icons">
                                        <mat-icon matTooltip="Download PDF"
                                            (click)="downloadPdfGuidesFromBatch(guideBatchStruct.guideBatch.idGuideBatch)">print</mat-icon>
                                        <mat-icon matTooltip="Download XML"
                                            (click)="downloadXmlGuidesFromBatch(guideBatchStruct.guideBatch.idGuideBatch)">get_app</mat-icon>
                                        <mat-icon matTooltip="Editar" 
                                            (click)="openBatch(guideBatchStruct.guideBatch.idGuideBatch)">edit</mat-icon>
                                        <mat-icon matTooltip="Deletar"
                                            (click)="openModalDelete(guideBatchStruct.guideBatch)">delete</mat-icon>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <app-empty-list
                    *ngIf="(!listGuideBatchStruct || listGuideBatchStruct.length == 0) && !this.isLoading"></app-empty-list>
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12">
                        <mat-paginator [length]="fullListSize" #paginator [pageIndex]="pageIndex" [pageSize]="pageSize"
                            aria-label="Select page" (page)="changePage($event.pageIndex)" [hidePageSize]="true">
                        </mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>