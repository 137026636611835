import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RequestMaterialService } from 'src/app/shared/services/API/pharmacy/request-material.service';
import { ItemModel } from 'src/app/shared/services/models/pharmacy/item.model';
import { MedicineModel } from 'src/app/shared/services/models/pharmacy/medicine.model';
import { ItemFormattedSctruct, MaterialItemKitRequests, RequestMaterialValidateStruct } from 'src/app/shared/services/requests/pharmacy/RequestMaterial.request';
import { ListKitStruct } from 'src/app/shared/services/structs/pharmacy/list-kit.struct';


@Component({
  selector: 'app-bed-management-material-validate-modal.component',
  templateUrl: './bed-management-material-validate-modal.component.html',
  styleUrls: ['./bed-management-material-validate-modal.component.css']
})
export class BedManagementMaterialValidateModal implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ({ 
      listRequestMaterials: RequestMaterialValidateStruct[], 
      listKits: ListKitStruct[], 
      listItems: ItemModel[], 
      listMedicines: MedicineModel[], 
      listRequestMaterialsItemKit: MaterialItemKitRequests[] 
    }),
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<BedManagementMaterialValidateModal>,
    public requestMaterialService: RequestMaterialService,
  ) { }

  public isLoading: boolean;
  public buttonAccept = false;
  public itemFormatted: ItemFormattedSctruct;
  public listItemFormatted: ItemFormattedSctruct[] = [];

  ngOnInit(): void {
    this.isLoading = false;
    this.data.listRequestMaterials.filter(c => !c.hasEnough).forEach((c) => {      
      let item = this.data.listItems.find(x => x.idItem == c.idItem);
      let medicine = this.data.listMedicines.find(x => x.idMedicine == c.idMedicine);
      this.itemFormatted = new ItemFormattedSctruct();
      if(item) this.itemFormatted.itemName = item.itemName;
      if(medicine) this.itemFormatted.medicineName = medicine.medicineDescription;
      this.itemFormatted.quantityNeeded = c.quantityNeeded;
      this.itemFormatted.quantityHaving = c.quantityHaving;
      this.listItemFormatted.push(this.itemFormatted);
    });
    this.buttonAccept = this.data.listRequestMaterials.length == this.data.listRequestMaterials.filter(c => c.quantityHaving == 0).length;
  }

  close() {
    this.matDialogRef.close();
  }

  clickYes() {
    this.matDialogRef.close({ result: true });
  }

  clickCancel() {
    this.matDialogRef.close();
  }
}