<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-type-modal-list">
            <h2>Deseja usar um Template?</h2>
        </div>
        <div class="row">
            <div class="col-12 col-md-12 col-sm-12">
                <mat-form-field appearance="outline">
                    <mat-label>Template</mat-label>
                    <mat-select [(ngModel)]="idTemplate" (ngModelChange)="visualizeTemplate()">
                        <mat-option *ngFor="let item of listTemplate" value="{{item.idTemplate}}">
                            {{item.templateName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div *ngIf="templateStruct != null" class="template-content">
            <h2>Template: {{templateStruct.template.templateName}}</h2>
            <ol>
                <li class="type" *ngFor="let type of templateStruct.listTemplateMedicineTypeStruct">
                    <span class="name">{{type.templateMedicineType.medicineTypeName}}</span>
                    <ol>
                        <li class="group" *ngFor="let group of type.listTemplateMedicineGroupStruct">
                            <span class="name">{{group.templateMedicineGroup.medicineGroupName}}</span>
                            <ol>
                                <ul>
                                    <li class="medicine" *ngFor="let medicine of group.listTemplateMedicineStruct">
                                        <span class="identifier">
                                            {{medicine.templateMedicine.medicineIdentifier}}
                                        </span>
                                        <span class="medicine-name">
                                            {{medicine.templateMedicine.medicineName}}
                                        </span>
                                        <span class="description" *ngIf="medicine.templateMedicine.medicineDescription">
                                            {{medicine.templateMedicine.medicineDescription}}
                                        </span>
                                        <span class="administration-type">
                                            {{medicine.administrationTypeName}}
                                        </span>
                                        <span class="presentation-type">
                                            {{medicine.presentationTypeName}}
                                        </span>
                                    </li>
                                </ul>
                                <li class="subgroup" *ngFor="let subgroup of group.listTemplateMedicineSubgroupStruct">
                                    <span class="name">{{subgroup.templateMedicineSubgroup.medicineSubgroupName}}</span>
                                    <ul>
                                        <li class="medicine"
                                            *ngFor="let medicine of subgroup.listTemplateMedicineStruct">
                                            <span class="identifier">
                                                {{medicine.templateMedicine.medicineIdentifier}}
                                            </span>
                                            <span class="medicine-name">
                                                {{medicine.templateMedicine.medicineName}}
                                            </span>
                                            <span class="description" *ngIf="medicine.templateMedicine.medicineDescription">
                                                {{medicine.templateMedicine.medicineDescription}}
                                            </span>
                                            <span class="administration-type">
                                                {{medicine.administrationTypeName}}
                                            </span>
                                            <span class="presentation-type">
                                                {{medicine.presentationTypeName}}
                                            </span>
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                        </li>
                    </ol>
                </li>
            </ol>
        </div>
        <div class="row">
            <div class="col-6 col-md-6 col-sm-6 text-center">
                <button mat-flat-button color="primary" class="" (click)="openConfirmModal(idTemplate)" *ngIf="idTemplate != null">
                    <span *ngIf="isLoading == false">Aplicar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
            </div>
            <div class="col-6 col-md-6 col-sm-6">
                <button mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Cancelar</button>
            </div>
        </div>
    </div>