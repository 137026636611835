import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ListServiceStruct } from '../../../../shared/services/responses/flow/list-service-response';
import { RoomStruct } from 'src/app/shared/services/structs/flow/room.struct';
import { SectorStruct } from 'src/app/shared/services/structs/flow/sector.struct';
import { Observable, Subscription } from 'rxjs';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';

@Component({
  selector: 'app-panel-origin-select',
  templateUrl: './panel-origin-select.component.html',
  styleUrls: ['./panel-origin-select.component.css']
})
export class PanelOriginSelectComponent implements OnInit {

  constructor() { }

  @Input() index: number;
  @Input() formGroup: FormGroup;
  @Input() listRoom: RoomStruct[];
  @Input() listSector: SectorStruct[];
  @Input() listService: ListServiceStruct[];
  @Input() events: Observable<void>;

  @Output() addNextEvent = new EventEmitter<void>();
  @Output() removeOriginEvent = new EventEmitter<number>();

  public showSelected: number;
  public idModuleEnumClassification: number = MenuModuleEnum.classification;
  public hasSelectedClassificationModule: boolean = false;
  private eventsSubscription: Subscription;

  ngOnInit(): void {
    if (this.formGroup.get('idSector').value != null) {
      if (this.listSector?.find(x => x.idModule && x.idModule == this.idModuleEnumClassification && this.formGroup.get('idSector').value == x.idSector))
        this.hasSelectedClassificationModule = true;
      
      this.showSelected = 2;
      this.formGroup.get('idSector').setValidators([Validators.required]);
      this.formGroup.get('idSector').updateValueAndValidity();
      this.formGroup.get('showAverageWaitingTime').setValidators([Validators.required]);
      this.formGroup.get('showAverageWaitingTime').updateValueAndValidity();
    }
    else if (this.formGroup.get('idRoom').value != null) {
      if (this.listRoom?.find(x => x.idModule && x.idModule == this.idModuleEnumClassification && this.formGroup.get('idRoom').value == x.idRoom))
        this.hasSelectedClassificationModule = true;

      this.showSelected = 3;
      this.formGroup.get('idRoom').setValidators([Validators.required]);
      this.formGroup.get('idRoom').updateValueAndValidity();
      this.formGroup.get('showAverageWaitingTime').setValidators([Validators.required]);
      this.formGroup.get('showAverageWaitingTime').updateValueAndValidity();
    }
    else {
      if (this.listService?.find(x => x.idModule && x.idModule == this.idModuleEnumClassification && this.formGroup.get('idService').value == x.idService))
        this.hasSelectedClassificationModule = true;

      this.showSelected = 1;
      this.formGroup.get('idService').setValidators([Validators.required]);
      this.formGroup.get('idService').updateValueAndValidity();
      this.formGroup.get('showAverageWaitingTime').setValidators([Validators.required]);
      this.formGroup.get('showAverageWaitingTime').updateValueAndValidity();
    }

    this.eventsSubscription = this.events.subscribe(() => this.invalidate());
  }

  add() {
    this.addNextEvent.emit();
  }

  remove() {
    this.removeOriginEvent.emit(this.index);
  }

  invalidate() {
    Object.keys(this.formGroup.controls).forEach(key => {
      this.formGroup.controls[key].markAsDirty();
      this.formGroup.controls[key].markAsTouched();
    });
  }

  changeRadioOrigin(event) {
    this.showSelected = 0;

    Object.keys(this.formGroup.controls).forEach(key => {
      this.formGroup.controls[key].setValue(null);
      this.formGroup.controls[key].clearValidators();
      this.formGroup.controls[key].reset();
      this.formGroup.controls[key].updateValueAndValidity();
    });

    this.showSelected = event.value;

    if (this.showSelected == 1) {
      this.formGroup.get('idService').setValidators([Validators.required]);
      this.formGroup.get('idService').updateValueAndValidity();
      this.formGroup.get('showAverageWaitingTime').setValidators([Validators.required]);
      this.formGroup.get('showAverageWaitingTime').updateValueAndValidity();
    }
    else if (this.showSelected == 2) {
      this.formGroup.get('idSector').setValidators([Validators.required]);
      this.formGroup.get('idSector').updateValueAndValidity();
      this.formGroup.get('showAverageWaitingTime').setValidators([Validators.required]);
      this.formGroup.get('showAverageWaitingTime').updateValueAndValidity();
    }
    else if (this.showSelected == 3) {
      this.formGroup.get('idRoom').setValidators([Validators.required]);
      this.formGroup.get('idRoom').updateValueAndValidity();
      this.formGroup.get('showAverageWaitingTime').setValidators([Validators.required]);
      this.formGroup.get('showAverageWaitingTime').updateValueAndValidity();
    }
  }

  selectService(event: any) {
    if (this.listService?.find(x => x.idModule && x.idModule == this.idModuleEnumClassification && x.idService == event.value))
      this.setSelectedClassificationModule(true);
    else
      this.setSelectedClassificationModule(false);
  }

  selectSector(event: any) {
    if (this.listSector?.find(x => x.idModule && x.idModule == this.idModuleEnumClassification && x.idSector == event.value))
      this.setSelectedClassificationModule(true);
    else
      this.setSelectedClassificationModule(false);
  }

  selectRoom(event: any) {
    if (this.listRoom?.find(x => x.idModule && x.idModule == this.idModuleEnumClassification && x.idRoom == event.value))
      this.setSelectedClassificationModule(true);
    else
      this.setSelectedClassificationModule(false);
  }

  setSelectedClassificationModule(hasSelectedClassificationModule: boolean){
    this.hasSelectedClassificationModule = hasSelectedClassificationModule;
    if(this.hasSelectedClassificationModule){
      this.formGroup.get('showAverageWaitingTime').setValue(false);
    }
  }
}