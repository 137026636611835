import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { PatientTissGuideRequest } from '../../requests/orchestrator-patient/patient-tiss-guide.request';
import { PostPatientResponse } from '../../responses/medical-record/post-patient.response';

@Injectable({
  providedIn: 'root'
})
export class FrontDeskService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  public Post(request: PatientTissGuideRequest): Observable<PostPatientResponse> {
    let uri = `FrontDesk`

    return this.httpClient.post<PostPatientResponse>(environment.urlApiOrchestratorPatient + uri, request, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}