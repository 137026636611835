import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { BedEpisodeService } from 'src/app/shared/services/API/bed/bed-episode.service';

@Component({
  selector: 'app-change-bed-episode-confirm-modal',
  templateUrl: './change-bed-episode-confirm-modal.component.html',
  styleUrls: ['./change-bed-episode-confirm-modal.component.css']
})
export class ChangeBedEpisodeConfirmModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<ChangeBedEpisodeConfirmModalComponent>,
  private alertService: AlertService,
  private bedEpisodeService: BedEpisodeService,
  ) { }

  public isLoading:boolean;

  ngOnInit(): void {
    this.isLoading = false;
  }
  
  close(){
    this.matDialogRef.close(); 
  }
  
  clickConfirm(){

    this.isLoading = true;

    this.changeBedEpisode();
  }
  
  clickCancel(){
    this.matDialogRef.close(); 
  }

  changeBedEpisode(){
    this.bedEpisodeService.changeBedEpisode(this.data.bedEpisodeRequest).subscribe((response)=>{        
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.alertService.show('Sucesso', "Leito do paciente alterado com sucesso", AlertType.success);
      this.isLoading = false;

      this.matDialogRef.close({isChange: true});
    },
    (error)=>{
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }
}
