import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { ListReturnRestrictionsResponse } from '../../responses/private-billing/list-return-restrictions.response';
import { ReturnRestrictionsRequest } from '../../requests/private-billing/return-restrictions.request';
import { ReturnRestrictionsResponse } from '../../responses/private-billing/return-restrictions.response';
import { AdmissionProcedureStruct } from '../../structs/private-billing/admission-procedure.struct';
import { CheckHasRestrictionsRequest } from '../../requests/private-billing/check-has-restriction.request';

@Injectable({
  providedIn: 'root'
})
export class ReturnRestrictionsService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  public GetAll(idHealthcareAgreement: number, searchText: string, pageIndex: number): Observable<ListReturnRestrictionsResponse> {
    let uri = `ReturnRestrictions?idHealthcareAgreement=${idHealthcareAgreement}&`;

    if (searchText)
      uri = uri + `searchText=${searchText}&`

    if (pageIndex)
      uri = uri + `pageIndex=${pageIndex}`;

    return this.httpClient.get<ListReturnRestrictionsResponse>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public Post(body: ReturnRestrictionsRequest): Observable<ReturnRestrictionsResponse> {
    let uri = `ReturnRestrictions`

    return this.httpClient.post<ReturnRestrictionsResponse>(environment.urlApiPrivateBilling + uri, body, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public Put(body: ReturnRestrictionsRequest, idReturnRestrictions: number): Observable<ReturnRestrictionsResponse> {
    let uri = `ReturnRestrictions/idReturnRestrictions/${idReturnRestrictions}`

    return this.httpClient.put<ReturnRestrictionsResponse>(environment.urlApiPrivateBilling + uri, body, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public delete(idReturnRestrictions: number): Observable<ReturnStruct> {
    let uri = `ReturnRestrictions/idReturnRestrictions/${idReturnRestrictions}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public CheckHasRestriction(body: CheckHasRestrictionsRequest): Observable<ListReturnRestrictionsResponse> {
    let uri = `ReturnRestrictions/CheckHasRestriction`;

    return this.httpClient.post<ListReturnRestrictionsResponse>(environment.urlApiPrivateBilling + uri, body, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}