import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CareLineModalComponent } from 'src/app/shared/components/care-line-modal/care-line-modal.component';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { CareLineModel } from 'src/app/shared/services/models/care-line/care-line.model';
import { EpisodeCareLineStruct } from 'src/app/shared/services/structs/care-line/episode-care-line.struct';

@Component({
  selector: 'app-care-line-block',
  templateUrl: './care-line-block.component.html',
  styleUrls: ['./care-line-block.component.css']
})
export class CareLineBlockComponent implements OnInit {

  @Input() listPastEpisodeCareLine: EpisodeCareLineStruct[];
  @Input() listCurrentEpisodeCareLine: EpisodeCareLineStruct[];
  @Input() listNewEpisodeCareLine: EpisodeCareLineStruct[];
  @Input() listCareLine: CareLineModel[];
  @Input() idEpisode: number;
  @Input() patientPhoneNumber: string;
  @Output() populateCareLines = new EventEmitter<any>();
  @Output() removeCareLines = new EventEmitter<number>();

  constructor(private dialog: MatDialog,
    private maskService: MaskService,
  ) { }

  public masks: Masks;

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
  }

  openCareLineModal(listCareLine: CareLineModel[]) {
    const dialogRef = this.dialog.open(CareLineModalComponent, {
      data: {
        idEpisode: this.idEpisode,
        listCareLine: listCareLine,
        listPastEpisodeCareLine: this.listPastEpisodeCareLine,
        listCurrentEpisodeCareLine: this.listCurrentEpisodeCareLine,
        patientPhoneNumber: this.patientPhoneNumber,
        masks: this.masks,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.newEpisodeCareLines && result.patientPhoneNumber) {
          this.listNewEpisodeCareLine = this.listNewEpisodeCareLine.concat(result.newEpisodeCareLines);
          this.listCurrentEpisodeCareLine = this.listCurrentEpisodeCareLine.concat(result.newEpisodeCareLines);
          this.patientPhoneNumber = result.patientPhoneNumber;

          this.populateCareLines.emit(result);
        }
      }
    });
  }

  removeCareLine(index: number) {
    this.listCurrentEpisodeCareLine.splice(index, 1);
  }

}
