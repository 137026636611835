import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FowardService } from 'src/app/shared/services/API/orchestrator-queue/foward.service';


@Component({
  selector: 'app-siga-forward-patient-confirm-modal',
  templateUrl: './siga-forward-patient-confirm-modal.component.html',
  styleUrls: ['./siga-forward-patient-confirm-modal.component.css']
})
export class SigaForwardPatientConfirmModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<SigaForwardPatientConfirmModalComponent>,
    public fowardService: FowardService,
  ) { }

  public isLoading: boolean = false;
  public fowardPatient: boolean = false;

  ngOnInit(): void {
  }

  clickCancel() {
    this.matDialogRef.close({ fowardPatient: this.fowardPatient });
  }

  clickConfirm() {
    if (this.isLoading)
      return;

    this.isLoading = true;
    this.fowardPatient = true;

    this.matDialogRef.close({ fowardPatient: this.fowardPatient });
  }
}
