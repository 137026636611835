import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { FlowManagementListComponent } from './pages/flow-management-list/flow-management-list.component';
import { FlowManagementComponent } from './pages/flow-management/flow-management.component';
import { KpiConfigListComponent } from './pages/kpi-config-list/kpi-config-list.component';
import { KpiConfigRegisterComponent } from './pages/kpi-config-register/kpi-config-register.component';
import { LeanPanelComponent } from './pages/lean-panel/lean-panel.component';
import { LeanConfigComponent } from './pages/lean-config/lean-config.component';

const routes: Routes = [
  { path: 'flow-management', component: FlowManagementComponent, canActivate: [AuthGuard] },
  { path: 'flow-management/flow-management-list', component: FlowManagementListComponent, canActivate: [AuthGuard] },
  { path: 'flow-management/kpi-config-list', component: KpiConfigListComponent, canActivate: [AuthGuard] },
  { path: 'flow-management/kpi-config-register', component: KpiConfigRegisterComponent, canActivate: [AuthGuard] },
  { path: 'flow-management/lean-panel', component: LeanPanelComponent, canActivate: [AuthGuard] },
  { path: 'flow-management/lean-config', component: LeanConfigComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FlowManagementRoutingModule { }
