import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { OrchestratorMedicalScheduleRequest } from '../../requests/orchestrator-form/orchestrator-medical-schedule.request';
import { ListFormatedMedicalScheduleResponse } from '../../responses/orchestrator-form/list-formated-medical-schedule.response';

@Injectable({
  providedIn: 'root'
})
export class OrchestratorMedicalScheduleService extends CommonService{


  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listMedicalSchedule(request: OrchestratorMedicalScheduleRequest): Observable<ListFormatedMedicalScheduleResponse> {
    let uri = `MedicalSchedule/getAll`;
      
    return this.httpClient.post<ListFormatedMedicalScheduleResponse>(environment.urlApiOrchestratorForm + uri, request, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}