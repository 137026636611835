import { PatientHealthcareAgreementDetailsStruct } from "../../structs/medical-record/patient-healthcare-agreement-details.struct";
import { MetaDataKeyValueStruct } from "../../structs/meta-data/meta-data-key-value.struct";

export class PatientRequest {
    public idPatient: number;
    public idEpisode: number;
    public idHealthUnitCreator: number;
    public patientName: string;
    public cpf: string;
    public birthDate: Date;
    public datetimeInclusion: Date;
    public cns: string;
    public rg: string;
    public expeditionIssuer: string;
    public expeditionDate: Date;
    public socialName: string;
    public motherName: string;
    public fatherName: string;
    public idRace: number;
    public idEducation: number;
    public profession: string;
    public idMaritalStatus: number;
    public idGender: number;
    public zipCode: string;
    public street: string;
    public neighborhood: string;
    public city: string;
    public state: string;
    public country: string;
    public phone1: string;
    public phoneConfirmation: string;
    public phone2: string;
    public houseNumber: string;
    public apartmentNumber: string;
    public healthInsurance: string;
    public healthInsuranceRegistration: string;
    public healthInsuranceExpirationDate: Date;
    public healthInsuranceSecurityCode: string;
    public healthInsuranceHolderName: string;
    public healthInsuranceGracePeriod: Date;
    public healthInsuranceProcedure: string;
    public healthInsuranceAuthorization: string;
    public birthCountry: string;
    public birthState: string;
    public birthCity: string;
    public metaData: MetaDataKeyValueStruct[];
    public priorities: number[];
    public isUnidentifiedPatient: boolean;
    public unidentifiedPatientDescription: string;
    public email: string;

    public observation: string;
    public patientAge: string;
    public idArrivalType: string;
    public idOrigin: string;
    public companionName: string;
    public companionPhone: string;
    public companionCpf: string;
    public companionParentage: string;
    public listIdAllergy: number[];
    public listIdPatientNecessity: number[];
    public datetimeStartRegister: Date;
    public genderName: string;
    public idNatureOfAttendance: number;
    public updatePhoneOnly: boolean;
    public listPatientHealthcareAgreementDetails: PatientHealthcareAgreementDetailsStruct[] = [];
}