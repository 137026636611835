import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { BillingDataRequest } from '../../requests/billing/billing-data-request';
import { GetPatientProcedureMadeResponse } from '../../responses/billing/get-patient-procedure-made.response';
import { GetProfessionalProductionAttendmentResponse } from '../../responses/billing/get-professional-production-attendment.response';
import { ListPatientProcedureMadeResponse } from '../../responses/billing/list-patient-procedure-made.response';
import { ListProfessionalProductionAttendmentResponse } from '../../responses/billing/list-professional-production-attendment.response';

@Injectable({
  providedIn: 'root'
})
export class PatientProcedureMadeService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  listReportPatientProcedure(): Observable<ListPatientProcedureMadeResponse>{
    let uri = `PatientProcedureMade`
      
    return this.httpClient.get<ListPatientProcedureMadeResponse>(environment.urlApiBilling + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  getReportPatientProcedure(id: number): Observable<GetPatientProcedureMadeResponse>{
    let uri = `PatientProcedureMade/idPatientProcedureMade/${id}`
      
    return this.httpClient.get<GetPatientProcedureMadeResponse>(environment.urlApiBilling + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  postReporPatientProcedure(body: BillingDataRequest): Observable<ReturnStruct>{
    let uri = `PatientProcedureMade`
      
    return this.httpClient.post<ReturnStruct>(environment.urlApiBilling + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
