<div [formGroup]="formGroup">
    <div formArrayName="listHealthPlans"
        *ngFor="let item of formGroup.get('listHealthPlans')['controls']; let i = index">
        <div class="row" [formGroupName]="i">
            <div class="col-8">
                <div class="row">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome do Plano de Saúde</mat-label>
                        <input matInput type="text" formControlName="healthPlanName">
                        <mat-error *ngIf="item.get('healthPlanName').invalid">Informe o Nome do Plano de
                            Saúde</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-4">
                <button class="side-button " color="primary" mat-mini-fab type="button" (click)="addNext()">
                    <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
                </button>
                <button class="side-button " color="primary" mat-mini-fab type="button" (click)="removeButton(i)"
                    *ngIf="i != 0">
                    <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>