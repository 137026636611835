export class AihModel {
    public idAih: number;
    public idAihGroup: number;
    public idUser: number;
    public datetimeInclusion: Date;
    public nameRequesterEstablishment: string;
    public cnesRequesterEstablishment: string;
    public nameExecutingEstablishment: string;
    public cnesExecutingEstablishment: string;
    public patientName: string;
    public patientPromptuary: string;
    public patientCns: string;
    public patientBirthDate: Date;
    public patientIdGender: number;
    public patientRace: string;
    public patientMotherName: string;
    public patientMotherPhone: string;
    public responsibleName: string;
    public responsiblePhone: string;
    public patientAdress: string;
    public patientCity: string;
    public patientIbgeCityCode: string;
    public patientState: string;
    public patientZipCode: string;
    public clinicalSymptoms: string;
    public conditionsForInternment: string;
    public mainDiagnosticResults: string;
    public initialDiagnostic: string;
    public cid10Main: string;
    public idCid10Main: number;
    public cid10Secondary: string;
    public idCid10Secondary: number;
    public cid10AssociatedCauses: string;
    public idCid10AssociatedCauses: number;
    public procedureRequestedDescription: string;
    public procedureCode: string;
    public procedureClinic: string;
    public idProcedureClinic: number;
    public procedureInternmentCharacter: string;
    public procedureProfessionalIdDocumentType: number;
    public procedureProfessionalDocument: string;
    public procedureProfessionalName: string;
    public procedureRequestedDate: Date;
    public isTrafficAccident: boolean;
    public isTypicalWorkAccident: boolean;
    public isRouteWorkAccident: boolean;
    public insurerCnpj: string;
    public insurerTicketNumber: string;
    public insurerSeries: string;
    public companyCnpj: string;
    public companyCnae: string;
    public cbor: string;
    public idRelationshipWithSocialSecurity: number;
}
