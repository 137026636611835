<div class="content">
    
    <span class="success-message">
        <!-- <mat-icon class="success-icon" aria-hidden="false" aria-label="Done">done</mat-icon> -->
        Senha gerada com sucesso. Aguarde ser chamado no painel!
    </span>
    <span class="success-ticket">{{ticket}}</span>
    
    <div class="ticket-date">
        <label>Data: </label> 
        {{generateEpisodeResponse.ticketGenerationDate | date:'dd/MM/yyyy'}} 
        
        <label class="hour">Hora: </label> 
        {{generateEpisodeResponse.ticketGenerationDate | date:'HH:mm'}}
    </div>
    
    <span class="info-message spinner-color">
        <mat-spinner [diameter]="40" class="mat-spinner-btn"></mat-spinner>
        Imprimindo sua senha. Aguarde!
    </span>
</div>
