import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { PatientMedicalRecordResponse } from '../../responses/orchestrator-schedule/patient-medical-record.response';
import { PatientAppointmentRequest } from '../../requests/orchestrator-schedule/patient-appointment.request';
import { ListPatientAppointmentCancelRequest } from '../../requests/orchestrator-schedule/list-patient-appointment-cancel.request';

@Injectable({
  providedIn: 'root'
})
export class OrchestratorPatientAppointmentService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getAppointment(idPatientAppointment: number): Observable<PatientMedicalRecordResponse> {

    let uri = `PatientAppointment/idPatientAppointment/${idPatientAppointment}`;

    return this.httpClient.get<PatientMedicalRecordResponse>(environment.urlApiOrchestratorSchedule + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public cancelPatientAppointment(request: PatientAppointmentRequest): Observable<ReturnStruct> {
    let uri = `PatientAppointment/cancelAppointment`;

    return this.httpClient.post<ReturnStruct>(environment.urlApiOrchestratorSchedule + uri, request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }

  public deleteAppointments(request: ListPatientAppointmentCancelRequest): Observable<ReturnStruct> {
    let uri = `PatientAppointment/deleteAppointments`;

    return this.httpClient.post<ReturnStruct>(environment.urlApiOrchestratorSchedule + uri, request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }
}
