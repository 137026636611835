export class SelfTriageConfig {
    public idSelfTriageConfig: number;
    public idHealthUnit: number;

    public datetimeInclusion: Date;

    public titlePage: string;
    public descriptionPage: string;
    public emergency: string;
    public symptoms: string;
    public logoName: string;
    public logoNamePreview: string;
    public url: string;
    public urlName: string;
    public colorCode: string;
    public colorCodePreview: string;

    public isActiveTitleDescriptionAlteration: boolean;
    public useSelfTriageInPatientCenter: boolean;
    public isLogoUploaded: boolean;
    public isActive: boolean;
    public useColor: boolean;
    public useTolifeLogo: boolean;
}