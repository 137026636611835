<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <h1>Setor</h1>
        <div class="white-body">
            <a mat-flat-button color="primary" class=" add-user" routerLink="/master/sector/register"><mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon> Cadastrar Setor</a>
            <div class="filters">
                <div class="row">   
                    <div class="col-12 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Pesquisar</mat-label>
                            <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText" (keyup)="onKeySearch($event)">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-md-2"> 
                        <mat-form-field appearance="outline">
                          <mat-label>Status</mat-label>
                          <mat-select [(ngModel)]="isActive" (selectionChange)="search()">
                            <mat-option value="">Todos</mat-option> 
                            <mat-option value="true">Ativo</mat-option>
                            <mat-option value="false">Inativo</mat-option>
                          </mat-select>
                      </mat-form-field>
                  </div>
                </div>
            </div>
            <div class="card-list" *ngIf="listSector != null">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listSector">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary">{{item.sectorName}}</span>
                                <span class="secundary">{{item.serviceName}}</span>
                                <span class="secundary">{{item.description}}</span>
                                <span class="secundary-status last-info">{{item.isActive? "Ativo":"Inativo"}}</span>
                            </div>
                            <div class="actions">
                                <a><mat-icon aria-hidden="false" aria-label="Editar" [routerLink] = "['/master/sector/register', {idSector: item.idSector}]">edit</mat-icon> </a>
                                <a><mat-icon aria-hidden="false" aria-label="Editar" (click)="openModal(item.idSector)">delete_forever</mat-icon> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <app-empty-list *ngIf="listSector != undefined && listSector.length == 0"></app-empty-list>
        </div>
    
        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>
    
</div>
