import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { BedService } from 'src/app/shared/services/API/bed/bed.service';
import { UserMemedService } from 'src/app/shared/services/API/orchestrator-patient/user-memed.service';
import { DeleteMemedPrescriptionsRequest } from 'src/app/shared/services/requests/orchestrator-integration/delete-memed-prescriptions.request';

@Component({
  selector: 'app-memed-prescription-delete-modal',
  templateUrl: './memed-prescription-delete-modal.component.html',
  styleUrls: ['./memed-prescription-delete-modal.component.css']
})
export class MemedPrescriptionDeleteModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<MemedPrescriptionDeleteModalComponent>,
  private alertService: AlertService,
  private userMemedService: UserMemedService,
  ) { }

  public isLoading:boolean;
  public userTokenMemed:string;  

  ngOnInit(): void {
    this.isLoading = false;
    this.userTokenMemed = this.data.userTokenMemed;
  }
  
  close(){
    this.matDialogRef.close({delete: false}); 
  }
  
  clickDelete(){
    let deleteMemedPrescriptionsRequest:DeleteMemedPrescriptionsRequest = new DeleteMemedPrescriptionsRequest()
    deleteMemedPrescriptionsRequest.userToken = this.userTokenMemed;
    deleteMemedPrescriptionsRequest.listIdPrescriptionMemed = this.data.listIdMemed;

    this.isLoading = true;
    this.userMemedService.deletePrescriptionMemed(deleteMemedPrescriptionsRequest).subscribe({
      next: (response) => {
        this.isLoading = false;
        if (response.isError) {
          this.alertService.show("Erro", response.errorDescription, AlertType.error);
          return;
        }
        this.alertService.show("Sucesso", "Prescrição deletada com sucesso", AlertType.success);
        this.isLoading = false;
        this.matDialogRef.close({delete: true}); 
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show("Erro", 'Não foi possível deletar a prescrição selecionada', AlertType.error);
        return;
      }
    });
    
  }
  
  clickCancel(){
    this.matDialogRef.close({delete: false}); 
  }
}