<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="title-header">
            <h1>{{this.data.idEpisode}} - Episódio</h1>
        </div>        
        <h3>Histórico de Atendimentos</h3>
        <div class="row">
            <div class="col-12" *ngIf="medicalCare != null  && medicalCare.length > 0 && !isLoading">
                <cdk-accordion class="profile-register-accordion">
                    <app-medical-care-accordion [medicalCare]="medicalCare"
                        [lookupMedicalCareResponse]="lookupMedicalCareResponse" [listMedicine]="listMedicine"
                        [listExam]="listExam"></app-medical-care-accordion>
                </cdk-accordion>
            </div>
            <div class="col-12" *ngIf="(medicalCare == null  || medicalCare.length == 0) && !isLoading">
                <p>Não possui</p>
            </div>
        </div>
    
        <h3 style="margin-top: 30px;">Classificação de Risco</h3>
        <app-triage-resume *ngIf="triage != null"  [triage]="triage"></app-triage-resume>
        <p *ngIf="triage == null && !isLoading">Não possui</p>
  
        <app-loading-list *ngIf="isLoading"></app-loading-list>

        <div class="footer-panel-modal-list">
            <button mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Fechar</button>
        </div>
    </div>
</div>