<div *ngIf="findedProtocol == currentPage && patientIsMaped">
    <app-flowchart-selection-default (selectedFlowchart)="selectFlowchart($event)"></app-flowchart-selection-default>
</div>
<div *ngIf="currentPage == whitePatient">
    <app-white-patient (goBackEmitter)="previousWhite()"
        (selectArrivalReason)="selectArrivalReason()"></app-white-patient>
</div>
<div *ngIf="findedFlowchart == currentPage">
    <app-iframe-flowchart (selectedDiscriminator)="selectedDiscriminator($event)"
        (previousPage)="previousFirstPage($event)"></app-iframe-flowchart>
</div>
<div *ngIf="findedDiscriminator == currentPage">
    <app-iframe-counseling-selection (previousPage)="previousCounseling($event)"
        (selectedCounseling)="selectedCounseling()"></app-iframe-counseling-selection>
</div>
<div *ngIf="resumePage == currentPage">
    <app-iframe-classification-details (previousPage)="previousResult($event)"></app-iframe-classification-details>
</div>