import { Router } from "@angular/router";
import { CommonService } from "../../common-service";
import { HttpClient } from "@angular/common/http";
import { ReturnStruct } from "src/app/shared/structs/return.struct";
import { Observable, catchError } from "rxjs";
import { Injectable } from "@angular/core";
import { ListAlertPolicyResponse } from "../../responses/alert-policy/list-alert-policy.response";
import { AlertPolicyResponse } from "../../responses/alert-policy/alert-policy.response";
import { CreateAlertPolicyRequest } from "../../requests/alert-policy/create-alert-policy.request";
import { UpdateAlertPolicyRequest } from "../../requests/alert-policy/update-alert-policy.request";
import { environment } from "src/environments/environment";


@Injectable({
    providedIn: 'root'
})

export class AlertPolicyService extends CommonService {
    constructor(private router: Router, private httpClient: HttpClient) {
        super();
    }

    public ListAlertPolicy(): Observable<ListAlertPolicyResponse> {
        let uri = `AlertPolicy`;

        return this.httpClient.get<ListAlertPolicyResponse>(environment.urlApiAlertPolicy + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }

    public GetAlertPolicy(idAlertPolicy: number): Observable<AlertPolicyResponse> {
        let uri = `AlertPolicy/idAlertPolicy/${idAlertPolicy}`;

        return this.httpClient.get<AlertPolicyResponse>(environment.urlApiAlertPolicy + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }

    public CreateAlertPolicy(request: CreateAlertPolicyRequest): Observable<ReturnStruct> {
        let uri = `AlertPolicy`;

        return this.httpClient.post<ReturnStruct>(environment.urlApiAlertPolicy + uri, request, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }

    public UpdateAlertPolicy(request: UpdateAlertPolicyRequest): Observable<ReturnStruct> {
        let uri = `AlertPolicy`;

        return this.httpClient.put<ReturnStruct>(environment.urlApiAlertPolicy + uri, request, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }

    public DeleteAlertPolicy(idAlertPolicy: number): Observable<ReturnStruct> {
        let uri = `AlertPolicy/idAlertPolicy/${idAlertPolicy}`;

        return this.httpClient.delete<ReturnStruct>(environment.urlApiAlertPolicy + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }
}