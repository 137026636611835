import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { MedicConfigSectorResponse } from '../../responses/medic/medic-config-sector.response';

@Injectable({
  providedIn: 'root'
})
export class MedicConfigSectorService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listMedicConfigSector(idSector: number): Observable<MedicConfigSectorResponse> {
    
    let uri = `MedicConfigSector/idSector/${idSector}`;
    
    return this.httpClient.get<MedicConfigSectorResponse>(environment.urlApiMedic + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
