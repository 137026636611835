import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { VerifyIp } from 'src/app/shared/custom-validators/ip.validator';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ScreenLookupService } from 'src/app/shared/services/API/ubs-health-promotion/screen-lookup.service';
import { UbsConfigService } from 'src/app/shared/services/API/ubs-health-promotion/ubs-health-promotion-config.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { UbsScreenModel } from 'src/app/shared/services/models/ubs-health-promotion/UbsScreen.model';
import { UbsConfigUpdateRequest } from 'src/app/shared/services/requests/ubs-health-promotion/ubs-config-update.request';
import { UbsConfigRequest } from 'src/app/shared/services/requests/ubs-health-promotion/ubs-config.request';
import { ScreenFlowStruct } from 'src/app/shared/services/structs/ubs-health-promotion/screen-flow.struct';
import { ScreenStruct } from 'src/app/shared/services/structs/ubs-health-promotion/screen.struct';
import { UtilService } from 'src/app/shared/services/util.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ubs-monitoring-config-register',
  templateUrl: './ubs-monitoring-config-register.component.html',
  styleUrls: ['./ubs-monitoring-config-register.component.css']
})
export class UbsMonitoringConfigRegisterComponent implements OnInit {

  constructor(
    private maskService: MaskService,
    private router: Router,
    private alertService: AlertService,
    private utilService: UtilService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private ubsHealthPromotionConfigService: UbsConfigService,
    private screenLookupService: ScreenLookupService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.ubs_monitoring;
  public isLoading: boolean;
  public isFirstLoading: boolean;

  public masks: Masks;
  public model: UntypedFormGroup;

  public baseAccessUrl: string = environment.urlBaseUIDevice + "ubs/preview/";
  public completeAccessUrl: string;
  public baseIntegrationUrl: string;
  public completeIntegrationUrl: string;
  public listScreen: UbsScreenModel[] = [];
  public listDefaultOrder: number[] = [];
  public listSelectedScreens: number[] = [];
  public idConfig: number;
  public isUpdate: boolean;

  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      name: ['', [Validators.required]],
      ipDevice: ['', [Validators.required,
      VerifyIp()]],
      accessUrl: ['', [Validators.required]],
      integrationUrl: [''],
      isClientTest: [false, [Validators.required]],
      screenFlow: this.formBuilder.array([]),
    });

    await this.populateScreenSelect();
    
  }

  async submit() {
    if (this.model.invalid) {
      return;
    }
    this.isLoading = true;

    let request: UbsConfigRequest = new UbsConfigRequest();
    request.accessUrl = this.model.get('accessUrl').value;
    request.integrationUrl = this.model.get('integrationUrl').value;
    request.configName = this.model.get('name').value;
    request.ipDevice = this.model.get('ipDevice').value
    request.isClientTest = this.model.get('isClientTest').value

    let screenFlow: ScreenFlowStruct[] = [];

    this.model.get('screenFlow')['controls'].forEach((x, index) => {
      let screen: ScreenFlowStruct = new ScreenFlowStruct();
      if (x.value.idUBSScreen) {
        screen.idScreen = x.value.idUBSScreen;
        screen.order = index;
        screenFlow.push(screen);
      }
    });

    request.listScreen = screenFlow;

    if (this.isUpdate) {
      let updateRequest = new UbsConfigUpdateRequest();

      updateRequest.listScreen = request.listScreen;
      updateRequest.accessUrl = request.accessUrl;
      updateRequest.integrationUrl = request.integrationUrl;
      updateRequest.configName = request.configName;
      updateRequest.ipDevice = request.ipDevice;
      updateRequest.isClientTest = request.isClientTest;

      this.updateConfig(updateRequest);
    }

    else {
      this.createConfig(request);
    }

    return;
  }


  async createConfig(request: UbsConfigRequest) {
    this.ubsHealthPromotionConfigService.createConfig(request).subscribe({next:(response) => {
      this.isLoading = true;
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.isLoading = false;
      this.alertService.show('Sucesso', 'Configuração cadastrada com sucesso!', AlertType.success);
      this.ngOnInit();
    },
    error:(error) => {
      console.log(error)
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    }});
  }

  async updateConfig(request: UbsConfigUpdateRequest) {
    this.ubsHealthPromotionConfigService.updateConfig(request).subscribe({next:(response) => {
      this.isLoading = true;
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.isLoading = false;
      this.alertService.show('Sucesso', 'Configuração atualizada com sucesso!', AlertType.success);
    },
    error:(error) => {
      console.log(error)
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    }});
  }

  async populateConfig() {
    this.ubsHealthPromotionConfigService.getConfig().subscribe({next:(response) => {
      this.isLoading = true;
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.isUpdate = response.isUpdate;

      if (this.isUpdate) {
        this.model.get('name').setValue(response.configName);
        this.model.get('ipDevice').setValue(response.ipDevice);
        this.model.get('accessUrl').setValue(response.accessUrl);
        this.completeAccessUrl = this.baseAccessUrl + `${this.model.get('accessUrl').value}`;
        this.model.get('integrationUrl').setValue(response.integrationUrl);
        this.model.get('isClientTest').setValue(response.isClientTest);
        this.completeIntegrationUrl = response.integrationUrl;

        if (response.listScreenFlow && response.listScreenFlow.length > 0) {

          response.listScreenFlow.forEach((screenFlowStruct, index) => {
            let screen = this.listScreen.find(x => x.idUBSScreen == screenFlowStruct.idScreen);
            let screenStruct: ScreenStruct = new ScreenStruct();
            screenStruct.idScreen = screen.idUBSScreen;
            screenStruct.screenDescription = screen.screenDescription;
            screenStruct.screenUrl = screen.screenUrl;
            screenStruct.order = screenFlowStruct.order;
            this.addNext(screenStruct);
          });
        }
        else if(!response.listScreenFlow || response.listScreenFlow.length == 0){
          this.addNext();
        }
      }
      else {
        this.listDefaultOrder.forEach((idScreen, index) => {
          let screen = this.listScreen.find(x => x.idUBSScreen == idScreen);
          let screenStruct = new ScreenStruct();
          screenStruct.idScreen = screen.idUBSScreen;
          screenStruct.screenDescription = screen.screenDescription;
          screenStruct.screenUrl = screen.screenUrl;
          screenStruct.order = index;
          this.addNext(screenStruct);
        });

        let screenFlow = this.model.get('screenFlow')['controls'];
        this.listSelectedScreens = screenFlow.map(x => x.value.idUBSScreen);
      }

      this.isLoading = false;
    },
    error:(error) => {
      console.log(error)
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    }});
  }

  addNext(screen?: ScreenStruct) {

    if(this.model.get('screenFlow')['controls'].length == (this.listScreen.length)){     
      return;
    }

    let screenFlow = this.model.get('screenFlow')['controls'];
    this.listSelectedScreens = screenFlow.map(x => x.value.idUBSScreen);

    if (screen) {
      (this.model.controls['screenFlow'] as UntypedFormArray).push(this.createInput(screen));
    }

    else {
      (this.model.controls['screenFlow'] as UntypedFormArray).push(this.createInput());
    }
  }

  async populateScreenSelect() {
    this.screenLookupService.getScreens().subscribe({next:(response) => {
      this.isLoading = true;

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.listScreen = response.listScreen;
      this.listDefaultOrder = response.listDefaultOrder;
      this.isLoading = false;

      this.populateConfig();
    },
    error:(error) => {
      console.log(error)
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    }});
  }

  screens(): UntypedFormArray {
    return this.model.get('screenFlow') as UntypedFormArray;
  }

  setScreenData(event: any, index: number) {
    let screen = this.listScreen.find(x => x.idUBSScreen == event.value);
    this.model.get('screenFlow')['controls'][index].get('screenDescription').setValue(screen.screenDescription);
    this.model.get('screenFlow')['controls'][index].get('screenUrl').setValue(screen.screenUrl);
    this.model.get('screenFlow')['controls'][index].get('order').setValue(index);
    let screenFlow = this.model.get('screenFlow')['controls'];
    this.listSelectedScreens = screenFlow.map(x => x.value.idUBSScreen);
  }

  createInput(screen?: ScreenStruct) {
    if (screen) {
      return this.formBuilder.group({
        idUBSScreen: [screen.idScreen],
        screenDescription: [screen.screenDescription],
        screenUrl: [screen.screenUrl],
        order: [screen.order]
      });
    }

    else {
      return this.formBuilder.group({
        idUBSScreen: [null],
        screenDescription: [''],
        screenUrl: [''],
        order: [null],
      });
    }
  }

  getCompleteAccessUrl() {
    let accessUrl = this.model.get('accessUrl').value;
    this.model.get('accessUrl').patchValue(accessUrl.replace(/ /g, ''));
    this.completeAccessUrl = (this.baseAccessUrl + `${accessUrl.replace(/ /g, '')}`);
  }

  getCompleteIntegrationUrl() {
    let integrationUrl = this.model.get('integrationUrl').value;
    this.model.get('integrationUrl').patchValue(integrationUrl.replace(/ /g, ''));
  }

  removeScreen(index: number) {
    (this.model.controls['screenFlow'] as UntypedFormArray).removeAt(index);
    if (this.model.get('screenFlow')['controls'].length == 0) {
      this.addNext();
    }
    let screenFlow = this.model.get('screenFlow')['controls'];
    this.listSelectedScreens = screenFlow.map(x => x.value.idUBSScreen);
    screenFlow.forEach((x, index) => {
      x.get('order').setValue(index);
    })
  }

  onCopy(text) {
    this.alertService.show('Sucesso', text, AlertType.success);
  }

  async clearForm() {
    Object.keys(this.model.controls).forEach(key => {
      this.model.controls[key].setValue(null);
    });
  }
}
