import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CommonService } from "src/app/shared/services/common-service";
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';
import { catchError } from "rxjs/operators";
import { ReturnStruct } from "src/app/shared/structs/return.struct";
import { EpisodeStatusRequest } from "../../requests/medical-record/episode-status.request";

@Injectable({
    providedIn: 'root'
  })
  export class EpisodeStatusService extends CommonService{
  
    constructor(private router: Router, private httpClient: HttpClient) {
      super();
    }

    public updateEpisodeStatus(idEpisode: number, body:EpisodeStatusRequest): Observable<ReturnStruct>{
      let uri = `EpisodeStatus/idEpisode/${idEpisode}`
  
      return this.httpClient.put<ReturnStruct>(environment.urlApiOrchestratorPatient + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}