<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    <div class="container-body">
        <div class="indicator">
            <app-generic-indicator-display [iconColor]="'#85C443'" [mainInfo]="listPatientResponse?.totalTickets"
                description="Aguardando atendimento" [icon]="'person_add_alt'"></app-generic-indicator-display>
            <app-generic-indicator-display [iconColor]="'#85C443'" [mainInfo]="listPatientResponse?.averageWaitingTime"
                description="Tempo médio de espera última hora" [icon]="'schedule'"></app-generic-indicator-display>
            <app-generic-indicator-display [iconColor]="'#85C443'" [mainInfo]="listPatientResponse?.totalAttendedTicket"
                description="Episódios atendidos no dia" [icon]="'done_all'"></app-generic-indicator-display>
            <app-generic-indicator-display [iconColor]="'#85C443'" [mainInfo]="listPatientResponse?.totalEvadedTicket"
                description="Episódios evadidos no dia" [icon]="'remove_done'"></app-generic-indicator-display>
            <app-generic-indicator-display [iconColor]="'#85C443'" [mainInfo]="listPatientResponse?.returnEpisodes"
                description="Episódios aguardando Retorno" [icon]="'person_add_alt'"></app-generic-indicator-display>
            <app-generic-indicator-display [iconColor]="'#85C443'"
                [mainInfo]="listPatientResponse?.returnWaitingTimeMedian" description="Tempo médio de retorno"
                [icon]="'schedule'"></app-generic-indicator-display>
        </div>
        <div class="white-body">
            <div class="generic-call__table__container">
                <div class="filters">
                    <div class="row">
                        <div class="col-12 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-icon matPrefix class="my-icon">search</mat-icon>
                                <mat-label>Pesquisar</mat-label>
                                <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText"
                                    (keyup)="onKeySearch($event)">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Serviços</mat-label>                            
                                <mat-select [multiple]="true" [(ngModel)]="listServiceSelected" (selectionChange)="onChangeSelectService()">
                                    <!-- <mat-checkbox (click)="selectAllOptions()" [checked]="this.isAllOptions">
                                        Todas as colunas
                                    </mat-checkbox> -->
                                    <mat-option *ngFor="let item of listServiceStruct; let index = index;" [value]="item.idService" >
                                        {{item.serviceName}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-3">
                            <mat-form-field appearance="outline" >
                                <mat-label>Setores</mat-label>                            
                                <mat-select [multiple]="true" [(ngModel)]="listSectorSelected" (selectionChange)="onChangeSelectSector()" [disabled]="listServiceSelected.length < 1">
                                    <!-- <mat-checkbox (click)="selectAllOptions()" [checked]="this.isAllOptions">
                                        Todas as colunas
                                    </mat-checkbox> -->
                                    <mat-option *ngFor="let item of listSectorStruct; let index = index;" [value]="item.idSector" >
                                        {{item.sectorName}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="col-12 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Colunas a serem exibidas </mat-label>                            
                                <mat-select [multiple]="true" [(ngModel)]="listColumnToDisplay" (selectionChange)="onChangeSelectColumn()">
                                    <input class="inputSearchSelect" type="text"
                                    (keyup)="onKeySelectColumns($event.target.value)" placeholder="Pesquisar" autocomplete="off" (keydown)="$event.stopPropagation()">
                                    <mat-checkbox (click)="selectAllOptions()" [checked]="this.isAllOptions">
                                        Todas as colunas
                                    </mat-checkbox>
                                    <mat-option *ngFor="let item of listColumnNames; let index = index;"  [ngClass]="{'hidden-option': item.hidden}" [value]="index + 1" >
                                        {{item.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="filter-select row col-12">
                            <div class="col-12 col-sm-10 col-md-7">
                                <app-attendance-status [isLoading]="isLoading" [isFlowManagement]="true"
                                    (selectedStatus)="queueStatusSelect($event)" (selectedKPIStatus)="kpiStatusSelect($event)">
                                </app-attendance-status>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="patient-list-container"
                    *ngIf="listPatientBeyondGoal && listPatientBeyondGoal.length > 0 && !isLoading">
                    <div class="kpi-category beyond-goal">
                        <span>FORA DA META</span>
                    </div>
                    <app-patient-list [listColumnToDisplay]="listColumnToDisplay" [listPatient]="listPatientBeyondGoal"></app-patient-list>
                </div>
                <div *ngIf="listPatientAlert && listPatientAlert.length > 0 && !isLoading">
                    <div class="kpi-category alert">
                        <span>ALERTA</span>
                    </div>
                    <app-patient-list [listColumnToDisplay]="listColumnToDisplay" [listPatient]="listPatientAlert"></app-patient-list>
                </div>
                <div *ngIf="listPatientOk && listPatientOk.length > 0 && !isLoading">
                    <div class="kpi-category ok">
                        <span>DENTRO DA META</span>
                    </div>
                    <app-patient-list [listColumnToDisplay]="listColumnToDisplay" [listPatient]="listPatientOk"></app-patient-list>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-paginator *ngIf="listPatientResponse" [length]="this.listPatientResponse.fullListSize"
                        #paginator [pageIndex]="0" [pageSize]="this.listPatientResponse.pageSize"
                        aria-label="Select page" (page)="changePage($event)" [hidePageSize]="true">
                    </mat-paginator>
                </div>
            </div>
            <app-empty-list *ngIf="listPatientResponse && listPatientResponse.listPatient.length == 0  && !isLoading">
            </app-empty-list>
        </div>
        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>
</div>