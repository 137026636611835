export class SuspicionValidatedStruct {
    public idSuspicion: number;

    public suspicionName: string;

    public isSuspicionEvaluated: boolean;

    public suspicionEvaluationMotive: string;

    public suspicionUserName: string;

    public datetimeSuspicionEvaluation: Date;

    public idUserSuspicionEvaluation: number;
}