<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <h1>Relatório Mensal de Auditoria</h1>
        <div class="white-body">
            <a mat-flat-button color="primary" class=" add-user" routerLink="/audit/audit-monthly-report/register">
                <mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon> Gerar Relatório
            </a>
            <div class="filters">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Identificador do Relatório</mat-label>
                            <input matInput type="number" id="searchText" [(ngModel)]="idAuditMonthlyReport"
                                (keyup)="onKeySearch($event)">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Mês/Ano</mat-label>
                            <mat-select [(ngModel)]="yearMonth" (selectionChange)="search()">
                                <mat-option [value]="">Todos</mat-option>
                                <mat-option *ngFor="let item of listDate" [value]="item">{{item | date:"MMM/yyyy"}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="card-list" *ngIf="listAuditMonthlyReportModel && listAuditMonthlyReportModel.length > 0">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listAuditMonthlyReportModel">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary">{{item.idAuditMonthlyReport}}</span>
                                <span class="secundary last-info">{{item.datetimeInclusion | date: 'dd/MM/yyyy
                                    HH:mm:ss'}}</span>
                            </div>
                            <div class="actions">
                                <a *ngIf="!isGeneratingReport"><mat-icon aria-hidden="false" aria-label="Baixar"
                                        (click)="openDownloadModalReport(item.idAuditMonthlyReport)">summarize</mat-icon></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <app-empty-list *ngIf="listAuditMonthlyReportModel == undefined || listAuditMonthlyReportModel.length == 0">
            </app-empty-list>
        </div>

        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>
</div>