<div [formGroup]="formGroup">
    <div class="row" formArrayName="{{formArrayName}}"
        *ngFor="let item of formGroup.get(formArrayName)['controls']; let i = index;">
        <div class="col-3">
            <div [formGroupName]="i">
                <mat-form-field appearance="outline">
                    <mat-label>Tipo de serviço</mat-label>
                    <mat-select formControlName="idTypeService">
                        <mat-option *ngFor="let item of listTypeService" value="{{item.idTypeService}}">
                            {{item.typeServiceName}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="col-2 col-sm-3 col-md-2">
            <div [formGroupName]="i">
                <mat-form-field appearance="outline">
                    <mat-label>Variável</mat-label>
                    <mat-select formControlName="idAlertVariable">
                        <mat-option *ngFor="let item of listAlertVariable" value="{{item.idAlertVariable}}">
                            {{item.typeVariable}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="col-4 col-sm-5 col-md-2">
            <div [formGroupName]="i">
                <mat-form-field appearance="outline">
                    <mat-label>Valor</mat-label>
                    <input matInput type="number" formControlName="value">
                </mat-form-field>
            </div>
        </div>
        <div class="col-2 col-sm-3 col-md-2">
            <div [formGroupName]="i">
                <mat-form-field appearance="outline">
                    <mat-label>Canal de alerta</mat-label>
                    <mat-select formControlName="idChannelType" multiple>
                        <mat-option *ngFor="let item of listChannelType"
                            value="{{item.idChannelType}}">{{item.channelTypeName}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="col-3 col-sm-3 col-md-3">
            <button class="panel-origin-button " color="primary" mat-mini-fab type="button" (click)="addNext()">
                <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
            </button>
            <button class="panel-origin-button " color="primary" mat-mini-fab type="button"
                (click)="removeExpression(i)">
                <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
            </button>
        </div>
    </div>
</div>

<button *ngIf="formGroup.get(formArrayName)['controls'].length == 0" color="accent" class=""
    mat-flat-button type="button" (click)="addNext()">
    <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
    Adicionar nova regra
</button>