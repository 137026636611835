import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { GetCompleteTemplateResponse } from '../../responses/pharmacy/get-complete-template.response';
import { ListTemplateResponse } from '../../responses/pharmacy/list-template.response';

@Injectable({
  providedIn: 'root'
})
export class MedicineTemplateService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getTemplate(idTemplate: number): Observable<GetCompleteTemplateResponse>{
    let uri = `Template/idTemplate/${idTemplate}`;

    return this.httpClient.get<GetCompleteTemplateResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public listAllTemplate(): Observable<ListTemplateResponse> {
    let uri = 'Template/getAll';
    
    return this.httpClient.get<ListTemplateResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public listTemplate(searchText: string): Observable<ListTemplateResponse> {
    let uri = 'Template?';
    
    if(searchText != null)
      uri = uri+ `searchText=${searchText}&`;

    return this.httpClient.get<ListTemplateResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public insertTemplate(idTemplate: number): Observable<ReturnStruct> {
    let uri = `Template/idTemplate/${idTemplate}`;

    return this.httpClient.put<ReturnStruct>(environment.urlApiPharmacy + uri, null, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
