import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MedicineTemplateService } from 'src/app/shared/services/API/pharmacy/medicine-template.service';

@Component({
  selector: 'app-template-confirm-modal',
  templateUrl: './template-confirm-modal.component.html',
  styleUrls: ['./template-confirm-modal.component.css']
})
export class TemplateConfirmModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<TemplateConfirmModalComponent>,
  private medicineTemplateService: MedicineTemplateService,
  private alertService: AlertService,) { }

  public isLoading: boolean; 
  
  ngOnInit(): void {
    this.isLoading = false;
  }

  clickApplyTemplate() {
    this.isLoading = true;
    this.medicineTemplateService.insertTemplate(this.data.idTemplate).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Template aplicado com sucesso!!", AlertType.success);
      this.matDialogRef.close({applyTemplate: true});
    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  clickCancel(){
    this.matDialogRef.close({applyTemplate: false}); 
  }
}
