<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum'></app-menu>

    <div class="container-body">
        <div class="white-body">
            <div class="welcome-header">
                <div class="title-header">
                    <mat-icon aria-hidden="false" aria-label="Menu apps">manage_accounts</mat-icon>
                    <h1>Módulo Integração</h1>
                </div>
                <p>Bem vindo ao seu módulo de integração! Aqui você pode controlar sua integração.</p>
    
            <h2>Gerência de acesso:</h2>
            <p>Para acessar qualquer Endpoint e Webhook é necessário a criação de uma chave de acesso, na aba "Chaves de Acesso",
                onde deve ser vinculado um usuário integrador. A Chave de Acesso é um GUID que deve ser passado sempre que requisitado.
            </p>
    
            <h2>Gerência de log:</h2>
            <p>Todos os logs da integração são salvos, e podem ser consultados na aba "Logs de Integração".</p>
    
            <h2>Integração Pelas API's:</h2>
            <p>É possível consumir os Endpoints de Integração da ToLife, com base na documentação do Postman, linkado abaixo.
                Sempre enviando a Chave de Acesso quando requisitado.
            </p>
    
            <h2>Integração pelo Webhook:</h2>
            <p>É possível consumir os Webhooks da ToLife, que devem ser gerenciados na aba "Webhook".
                Não pode ser cadastrado multiplos Webhooks do mesmo tipo.
            </p>
    
            <h2>Postman:</h2>
            <p>O <a href="https://documenter.getpostman.com/view/14711796/UV5THLZg" target="_blank" rel="noopener noreferrer">link</a> de acesso à documentação do Postman.</p>
            </div>
            <div class="welcome-img">
                <img src="assets/images/default-img.png" aria-hidden="false" aria-label="welcome" />
            </div>    
        </div>
        <hr/>
        <section>
            <app-module-functionality [menuModuleEnum]="menuModuleEnum"></app-module-functionality>
        </section>
    </div>

</div>

<div *ngIf="seeButton">
    <app-floating-button [menuModuleEnum]='menuModuleEnum'></app-floating-button>
</div>