import { ReturnStruct } from "src/app/shared/structs/return.struct";
import { ListPatientStruct } from "../../structs/orchestrator-queue/list-patient.struct";

export class ListPatientResponse extends ReturnStruct {
    public listPatient: ListPatientStruct[];

    public idHealthUnit: number;
    public fullListSize: number;
    public pageSize: number;

    //Indicadores
    public averageWaitingTime: string;
    public returnWaitingTimeMedian: string;
    public totalTickets: number;
    public totalEvadedTicket: number;
    public totalAttendedTicket: number;
    public returnEpisodes: number;
    public scheduleEpisodes: number;

    //Dados da sala atual
    public idRoomService: number;
    public idRoomSector: number;
}