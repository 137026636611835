import { AbstractControl, ValidatorFn } from "@angular/forms";

export function CepValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (control.value) {
            const cepValue = control.value.replace(/\D/g, '');

            if (cepValue.length === 8 && cepValue !== '00000000')
                return null;
        } else if(!control.value){
            return null;
        }

        return { invalidCep: true };
    };
}