<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="button-close">
            <div class="close-button">
                <mat-icon class="close-icon" mat-icon aria-hidden="false" aria-label="close"
                    (click)="close()">close</mat-icon>
            </div>
        </div>
        <div class="title-preview">
            <h1>Agendamento marcado{{this.patientAppointment.idEpisode?' (Atendido)':''}}</h1>
        </div>
        <app-loading-list *ngIf="isFirstLoading"></app-loading-list>
        <div class="body-calendar" *ngIf="!isFirstLoading">
            <p>Nome do paciente: {{this.userPatient.userName}}</p>
            <p>Data e horário da consulta: {{this.appointment}}</p>
            <p>Agendamento: {{this.patientAppointment.idTypeOfCare == onlineAttendance ? 'Online':'Presencial'}}</p>
        </div>
        <div class="btn-container">
            <button *ngIf="this.patientAppointment.idTypeOfCare == onlineAttendance && !this.patientAppointment.idEpisode" mat-flat-button color="primary" class="button-attend-patient" (click)="attendPatient()">
                <span *ngIf="isLoading == false">Atender paciente</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn"
                    *ngIf="isLoading == true"></mat-spinner>
            </button>
            <button *ngIf="this.patientAppointment.idTypeOfCare == inPersonAttendance || this.patientAppointment.idEpisode" mat-flat-button color="primary" class="button-attend-patient" (click)="close()">
                <span *ngIf="isLoading == false">Fechar</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn"
                    *ngIf="isLoading == true"></mat-spinner>
            </button>
            <button [disabled]="!hasPermissionToCancelAppointment" mat-flat-button color="accent" class="btn-block button-attend-patient" (click)="clickCancelAppointmentButton()">
                <span *ngIf="isLoading == false">Cancelar agendamento</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn"
                    *ngIf="isLoading == true"></mat-spinner>
            </button>
        </div>
    </div>
</div>
