import { Component, Input, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { AuditStatusEnum } from 'src/app/shared/enum/audit-status.enum';
import { IncorrectClassificationReasonEnum } from 'src/app/shared/enum/audit/incorrect-classification-reason.enum';
import { GravityEnum } from 'src/app/shared/enum/gravity.enum';
import { IncorrectClassificationReason } from 'src/app/shared/services/models/audit/incorrect-classification-reason.model';
import { AuditTriageInfoStruct } from 'src/app/shared/services/structs/audit/audit-triage-info.struct';
import { ListOfCompletedAuditTriageModalComponent } from './list-of-completed-audit-triage-modal/list-of-completed-audit-triage-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { CorrectClassificationReason } from 'src/app/shared/services/models/audit/correct-classification-reason.model';
import { CorrectClassificationReasonEnum } from 'src/app/shared/enum/audit/correct-classification-reason.enum';

@Component({
  selector: 'app-execute-audit-triage',
  templateUrl: './execute-audit-triage.component.html',
  styleUrls: ['./execute-audit-triage.component.css']
})
export class ExecuteAuditTriageComponent implements OnInit {

  constructor(public dialog: MatDialog,) { }

  @Input() gravityColorCode: string;
  @Input() painColorCode: string;
  @Input() infoStruct: AuditTriageInfoStruct;
  @Input() events: Observable<void>;
  @Input() idAuditStatus: number;
  @Input() showFirst: boolean;
  @Input() showSecond: boolean;
  @Input() showOther: boolean;
  @Input() displayCarePriorityInRiskClassification: boolean;
  @Input() userFirstAuditor: string;
  @Input() userSecondAuditor: string;
  @Input() userOtherAuditor: string;
  @Input() numberOtherStatusAudit: number;
  @Input() listAllIncorrectClassificationReason: IncorrectClassificationReason[];
  @Input() listAllCorrectClassificationReason: CorrectClassificationReason[];
  @Input() viewTimeTag: boolean;
  @Input() datetimeInclusionClassificationRiskAudit: Date;

  public min_less_seven: number = 2;
  public max_less_seven: number = 5;
  public min_over_seven: number = 6;
  public max_over_seven: number = 9;

  public idWhiteGravity: number = GravityEnum.branco;
  public isAuditConcluded: boolean;
  public isLoading: boolean = false;

  ngOnInit(): void {
    if (this.infoStruct.patientAge && this.infoStruct.patientAge != "")
      this.infoStruct.patientAge = this.formatAge(this.infoStruct.patientAge);

    this.isAuditConcluded = (this.idAuditStatus == AuditStatusEnum.Concluido);
    if (!this.isAuditConcluded) {
      this.listAllIncorrectClassificationReason = this.listAllIncorrectClassificationReason.filter(x => x.isDeleted == false || (x.isDeleted == true && x.datetimeDeletion > this.datetimeInclusionClassificationRiskAudit));
      this.listAllCorrectClassificationReason = this.listAllCorrectClassificationReason.filter(x => x.isDeleted == false || (x.isDeleted == true && x.datetimeDeletion > this.datetimeInclusionClassificationRiskAudit));
    }
    else {
      if (this.showFirst) {
        this.infoStruct.formGroup.get("secondAuditorAnswer").disable();
        this.infoStruct.formGroup.get("secondAuditorIdIncorrectClassificationReason").disable();
        this.infoStruct.formGroup.get("secondAuditorIncorrectObservation").disable();
        this.infoStruct.formGroup.get("secondAuditorIdCorrectClassificationReason").disable();
        this.infoStruct.formGroup.get("secondAuditorCorrectObservation").disable();
      }
      if (this.showSecond || this.idAuditStatus == AuditStatusEnum.PrimeiroAuditado || this.idAuditStatus == AuditStatusEnum.Aguardando2ªAuditoria) {
        this.infoStruct.formGroup.get("firstAuditorAnswer").disable();
        this.infoStruct.formGroup.get("firstAuditorIdIncorrectClassificationReason").disable();
        this.infoStruct.formGroup.get("firstAuditorIncorrectObservation").disable();
        this.infoStruct.formGroup.get("firstAuditorIdCorrectClassificationReason").disable();
        this.infoStruct.formGroup.get("firstAuditorCorrectObservation").disable();
      }
    }
  }

  changeRadioFirst() {
    if (this.infoStruct.formGroup.get("firstAuditorAnswer").value == true) {
      this.infoStruct.formGroup.get("firstAuditorIdIncorrectClassificationReason").setValidators([]);
      this.infoStruct.formGroup.get("firstAuditorIdIncorrectClassificationReason").setValue(null);
      this.infoStruct.formGroup.get("firstAuditorIncorrectObservation").setValidators([]);
      this.infoStruct.formGroup.get("firstAuditorIncorrectObservation").setValue(null);
      this.infoStruct.formGroup.get('firstAuditorIncorrectObservation').updateValueAndValidity();
    }
    else {
      this.infoStruct.formGroup.get("firstAuditorCorrectObservation").setValue(null);
      this.infoStruct.formGroup.get('firstAuditorCorrectObservation').updateValueAndValidity();
      this.infoStruct.formGroup.get("firstAuditorIdCorrectClassificationReason").setValue(null);
      this.infoStruct.formGroup.get('firstAuditorIdCorrectClassificationReason').updateValueAndValidity();
      this.infoStruct.formGroup.get('firstAuditorIdIncorrectClassificationReason').markAsUntouched();
      this.infoStruct.formGroup.get("firstAuditorIdIncorrectClassificationReason").markAsPristine();
      this.infoStruct.formGroup.get("firstAuditorIdIncorrectClassificationReason").setValidators([Validators.required]);
    }

    this.infoStruct.formGroup.get('firstAuditorIdIncorrectClassificationReason').updateValueAndValidity();
  }

  changeRadioSecond() {
    if (this.infoStruct.formGroup.get("secondAuditorAnswer").value == true) {
      this.infoStruct.formGroup.get("secondAuditorIdIncorrectClassificationReason").setValidators([]);
      this.infoStruct.formGroup.get("secondAuditorIdIncorrectClassificationReason").setValue(null);
      this.infoStruct.formGroup.get("secondAuditorIncorrectObservation").setValidators([]);
      this.infoStruct.formGroup.get("secondAuditorIncorrectObservation").setValue(null);
      this.infoStruct.formGroup.get('secondAuditorIncorrectObservation').updateValueAndValidity();
    }
    else {
      this.infoStruct.formGroup.get("secondAuditorCorrectObservation").setValue(null);
      this.infoStruct.formGroup.get('secondAuditorCorrectObservation').updateValueAndValidity();
      this.infoStruct.formGroup.get("secondAuditorIdCorrectClassificationReason").setValue(null);
      this.infoStruct.formGroup.get('secondAuditorIdCorrectClassificationReason').updateValueAndValidity();
      this.infoStruct.formGroup.get("secondAuditorIdIncorrectClassificationReason").markAsUntouched();
      this.infoStruct.formGroup.get('secondAuditorIdIncorrectClassificationReason').markAsPristine();
      this.infoStruct.formGroup.get("secondAuditorIdIncorrectClassificationReason").setValidators([Validators.required]);
    }

    this.infoStruct.formGroup.get('secondAuditorIdIncorrectClassificationReason').updateValueAndValidity();
  }

  changeRadioOther() {
    if (this.infoStruct.formGroup.get("otherAuditorAnswer").value == true) {
      this.infoStruct.formGroup.get("otherAuditorIdIncorrectClassificationReason").setValidators([]);
      this.infoStruct.formGroup.get("otherAuditorIdIncorrectClassificationReason").setValue(null);
      this.infoStruct.formGroup.get("otherAuditorIncorrectObservation").setValidators([]);
      this.infoStruct.formGroup.get("otherAuditorIncorrectObservation").setValue(null);
      this.infoStruct.formGroup.get('otherAuditorIncorrectObservation').updateValueAndValidity();
    }
    else {
      this.infoStruct.formGroup.get("otherAuditorCorrectObservation").setValue(null);
      this.infoStruct.formGroup.get('otherAuditorCorrectObservation').updateValueAndValidity();
      this.infoStruct.formGroup.get("otherAuditorIdCorrectClassificationReason").setValue(null);
      this.infoStruct.formGroup.get('otherAuditorIdCorrectClassificationReason').updateValueAndValidity();
      this.infoStruct.formGroup.get("otherAuditorIdIncorrectClassificationReason").markAsUntouched();
      this.infoStruct.formGroup.get('otherAuditorIdIncorrectClassificationReason').markAsPristine();
      this.infoStruct.formGroup.get("otherAuditorIdIncorrectClassificationReason").setValidators([Validators.required]);
    }
    this.infoStruct.formGroup.get('otherAuditorIdIncorrectClassificationReason').updateValueAndValidity();
  }

  changeSelectIncorrectFirst() {
    if (this.infoStruct.formGroup.get("firstAuditorIdIncorrectClassificationReason").value != IncorrectClassificationReasonEnum.Outros) {
      this.infoStruct.formGroup.get("firstAuditorIncorrectObservation").setValue(null);
      this.infoStruct.formGroup.get("firstAuditorIncorrectObservation").setValidators([]);
    }
    else {
      this.infoStruct.formGroup.get("firstAuditorIncorrectObservation").setValidators([Validators.required]);
      this.infoStruct.formGroup.get('firstAuditorIncorrectObservation').markAsUntouched();
      this.infoStruct.formGroup.get('firstAuditorIncorrectObservation').markAsPristine();
    }

    this.infoStruct.formGroup.get('firstAuditorIncorrectObservation').updateValueAndValidity();
  }

  changeSelectCorrectFirst() {
    if (this.infoStruct.formGroup.get("firstAuditorIdCorrectClassificationReason").value != CorrectClassificationReasonEnum.Outros) {
      this.infoStruct.formGroup.get("firstAuditorCorrectObservation").setValue(null);
      this.infoStruct.formGroup.get('firstAuditorCorrectObservation').updateValueAndValidity();
    }
  }

  changeSelectIncorrectSecond() {
    if (this.infoStruct.formGroup.get("secondAuditorIdIncorrectClassificationReason").value != IncorrectClassificationReasonEnum.Outros) {
      this.infoStruct.formGroup.get("secondAuditorIncorrectObservation").setValue(null);
      this.infoStruct.formGroup.get("secondAuditorIncorrectObservation").setValidators([]);
    }
    else {
      this.infoStruct.formGroup.get("secondAuditorIncorrectObservation").setValidators([Validators.required]);
      this.infoStruct.formGroup.get('secondAuditorIncorrectObservation').markAsUntouched();
      this.infoStruct.formGroup.get('secondAuditorIncorrectObservation').markAsPristine();
    }

    this.infoStruct.formGroup.get('secondAuditorIncorrectObservation').updateValueAndValidity();
  }

  changeSelectCorrectSecond() {
    if (this.infoStruct.formGroup.get("secondAuditorIdCorrectClassificationReason").value != CorrectClassificationReasonEnum.Outros) {
      this.infoStruct.formGroup.get("secondAuditorCorrectObservation").setValue(null);
      this.infoStruct.formGroup.get('secondAuditorCorrectObservation').updateValueAndValidity();
    }
  }

  changeSelectIncorrectOther() {
    if (this.infoStruct.formGroup.get("otherAuditorIdIncorrectClassificationReason").value != IncorrectClassificationReasonEnum.Outros) {
      this.infoStruct.formGroup.get("otherAuditorIncorrectObservation").setValue(null);
      this.infoStruct.formGroup.get("otherAuditorIncorrectObservation").setValidators([]);
    }
    else {
      this.infoStruct.formGroup.get("otherAuditorIncorrectObservation").setValidators([Validators.required]);
      this.infoStruct.formGroup.get('otherAuditorIncorrectObservation').markAsUntouched();
      this.infoStruct.formGroup.get('otherAuditorIncorrectObservation').markAsPristine();
    }

    this.infoStruct.formGroup.get('otherAuditorIncorrectObservation').updateValueAndValidity();
  }

  changeSelectCorrectOther() {
    if (this.infoStruct.formGroup.get("otherAuditorIdCorrectClassificationReason").value != CorrectClassificationReasonEnum.Outros) {
      this.infoStruct.formGroup.get("otherAuditorCorrectObservation").setValue(null);
      this.infoStruct.formGroup.get('otherAuditorCorrectObservation').updateValueAndValidity();
    }
  }

  invalidate() {
    if (!this.infoStruct.formGroup.get('firstAuditorIncorrectObservation').value) {
      this.infoStruct.formGroup.get('firstAuditorIncorrectObservation').markAsTouched();
      this.infoStruct.formGroup.get('firstAuditorIncorrectObservation').markAsDirty();
    }
    if (!this.infoStruct.formGroup.get('secondAuditorIncorrectObservation').value) {
      this.infoStruct.formGroup.get('secondAuditorIncorrectObservation').markAsTouched();
      this.infoStruct.formGroup.get('secondAuditorIncorrectObservation').markAsDirty();
    }
    if (!this.infoStruct.formGroup.get('otherAuditorIncorrectObservation').value) {
      this.infoStruct.formGroup.get('otherAuditorIncorrectObservation').markAsTouched();
      this.infoStruct.formGroup.get('otherAuditorIncorrectObservation').markAsDirty();
    }

    if (!this.infoStruct.formGroup.get('firstAuditorIdIncorrectClassificationReason').value) {
      this.infoStruct.formGroup.get('firstAuditorIdIncorrectClassificationReason').markAsTouched();
      this.infoStruct.formGroup.get('firstAuditorIdIncorrectClassificationReason').markAsDirty();
    }
    if (!this.infoStruct.formGroup.get('secondAuditorIdIncorrectClassificationReason').value) {
      this.infoStruct.formGroup.get('secondAuditorIdIncorrectClassificationReason').markAsTouched();
      this.infoStruct.formGroup.get('secondAuditorIdIncorrectClassificationReason').markAsDirty();
    }
    if (!this.infoStruct.formGroup.get('otherAuditorIdIncorrectClassificationReason').value) {
      this.infoStruct.formGroup.get('otherAuditorIdIncorrectClassificationReason').markAsTouched();
      this.infoStruct.formGroup.get('otherAuditorIdIncorrectClassificationReason').markAsDirty();
    }
  }

  formatAge(age: string) {
    let split1 = age.split('a');
    let years = split1[0]

    let split2 = split1[1].split('m');

    let months = split2[0];

    let days = split2[1].split('d')[0];

    return years + " anos " + months + " meses " + days + " dias"
  }

  public get auditStatusEnum(): typeof AuditStatusEnum {
    return AuditStatusEnum;
  }

  openResumeCompletedAudit() {
    this.isLoading = true;
    const dialogRef = this.dialog.open(ListOfCompletedAuditTriageModalComponent, {
      data: {
        listAllIncorrectClassificationReason: this.listAllIncorrectClassificationReason,
        listAllCorrectClassificationReason: this.listAllCorrectClassificationReason,
        firstAuditorAnswer: this.infoStruct.formGroup.get("firstAuditorAnswer").value,
        firstAuditorIdIncorrectClassificationReason: this.infoStruct.formGroup.get("firstAuditorIdIncorrectClassificationReason").value,
        firstAuditorIncorrectObservation: this.infoStruct.formGroup.get("firstAuditorIncorrectObservation").value,

        firstAuditorIdCorrectClassificationReason: this.infoStruct.formGroup.get("firstAuditorIdCorrectClassificationReason").value,
        firstAuditorCorrectObservation: this.infoStruct.formGroup.get("firstAuditorCorrectObservation").value,

        secondAuditorAnswer: this.infoStruct.formGroup.get("secondAuditorAnswer").value,
        secondAuditorIdIncorrectClassificationReason: this.infoStruct.formGroup.get("secondAuditorIdIncorrectClassificationReason").value,
        secondAuditorIncorrectObservation: this.infoStruct.formGroup.get("secondAuditorIncorrectObservation").value,

        secondAuditorIdCorrectClassificationReason: this.infoStruct.formGroup.get("secondAuditorIdCorrectClassificationReason").value,
        secondAuditorCorrectObservation: this.infoStruct.formGroup.get("secondAuditorCorrectObservation").value,

        listClassificationRiskAuditOthers: this.infoStruct.listClassificationRiskAuditOthers,
        userFirstAuditor: this.userFirstAuditor,
        userSecondAuditor: this.userSecondAuditor
      },
      panelClass: "border-radius-box"
    });
    dialogRef.afterClosed().subscribe({
      next: () => {
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
      }
    });
  }
}