import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-select-send-date-modal',
  templateUrl: './select-send-date-modal.component.html',
  styleUrls: ['./select-send-date-modal.component.css']
})
export class SelectSendDateModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<SelectSendDateModalComponent>,
    private alertService: AlertService,
    private formBuilder: FormBuilder
  ) { }

  public model: FormGroup;

  ngOnInit(): void {

    this.model = this.formBuilder.group({
      dateSendGuideBatch: [null, [Validators.required]],
    });
  }

  submit() {
    if (this.model.invalid) {
      this.model.markAllAsTouched();
      this.alertService.show('Atenção', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.warning);
      return;
    }

    this.matDialogRef.close({
      submit: true,
      dateSendGuideBatch: this.model.get('dateSendGuideBatch').value,
    });
  }

  clickCancel() {
    this.matDialogRef.close({});
  }

}