import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ObservationConfigService } from 'src/app/shared/services/API/bed/observation-config.service';
import { LocationByModuleService } from 'src/app/shared/services/API/flow/location-by-module.service';
import { TextTemplateService } from 'src/app/shared/services/API/meta-data/text-template.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { TextTemplate } from 'src/app/shared/services/models/meta-data/text-template.model';
import { PostPutObservationConfigRequest } from 'src/app/shared/services/requests/bed/post-put-observation-config.request';
import { ObservationConfigSectorStruct } from 'src/app/shared/services/structs/bed/observation-config-sector.struc';
import { SectorStruct } from 'src/app/shared/services/structs/flow/sector.struct';

@Component({
  selector: 'app-observation-config-register',
  templateUrl: './observation-config-register.component.html',
  styleUrls: ['./observation-config-register.component.css']
})
export class ObservationConfigRegisterComponent implements OnInit {

  constructor(private formBuilder: UntypedFormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private observationConfigService: ObservationConfigService,
    private locationByModuleService: LocationByModuleService,
    private textTemplateService: TextTemplateService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.observation;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.observation_config;

  public model: UntypedFormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;
  public actualServiceModule: number;
  public listSector: SectorStruct[];
  public listTextTemplate: TextTemplate[] = [];

  public isAllocateToBed: boolean = true;
  public isMandatoryActive: boolean;
  public masks: Masks;
  public idObservationConfig: number;
  public postPutObservationConfigRequest: PostPutObservationConfigRequest;

  ngOnInit(): void {

    this.isLoading = false;

    this.model = this.formBuilder.group({
      observationConfigName: ['', [Validators.required]],
      observationConfigDescription: [''],
      isMandatory: [false, [Validators.required]],
      allocateToBed: [true, [Validators.required]],
      isReleasable: [false, [Validators.required]],
      isReleasablePharmacy: [false, [Validators.required]],
      allowEvasion: [false, [Validators.required]],
      listSector: [[], [Validators.required]],
      listIdTextTemplate: [[]],
    });

    this.masks = this.maskService.getMasks();

    this.idObservationConfig == null;
    this.isUpdate == false;

    this.populateSectorSelect();
    this.populateTextTemplateSelect();

    if (this.activatedRoute.snapshot.paramMap.get('idObservationConfig'))
      this.idObservationConfig = parseInt(this.activatedRoute.snapshot.paramMap.get('idObservationConfig'));

    if (this.idObservationConfig != null) {
      this.isFirstLoading = true;
      this.isUpdate = true;
      this.populateObservationConfigData();
    }
  }

  submit() {
    if (this.isLoading) {
      return;
    }

    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    let request: PostPutObservationConfigRequest = new PostPutObservationConfigRequest();

    this.isLoading = true;

    request.observationConfigName = this.model.get('observationConfigName').value;
    request.observationConfigDescription = this.model.get('observationConfigDescription').value;
    request.isMandatory = this.model.get('isMandatory').value;
    request.allocateToBed = this.model.get('allocateToBed').value;
    request.isReleasable = this.model.get('isReleasable').value;
    request.isReleasablePharmacy = this.model.get('isReleasablePharmacy').value;
    request.allowEvasion = this.model.get('allowEvasion').value;
    request.listIdTextTemplate = this.model.get('listIdTextTemplate').value;

    request.listSector = this.convertIdSectorToObject(this.model.get('listSector').value);

    if (this.isUpdate)
      this.updateObsConfig(request);
    else
      this.createObsConfig(request);
  }

  convertIdSectorToObject(listIdSector: number[]): ObservationConfigSectorStruct[] {
    if (!listIdSector || !this.listSector)
      return;

    let listRequestSector: ObservationConfigSectorStruct[] = [];

    listIdSector.forEach(element => {
      let requestSector: ObservationConfigSectorStruct = new ObservationConfigSectorStruct();
      let sector = this.listSector.find(l => l.idSector == element);
      requestSector.id = sector.idSector,
        requestSector.name = sector.sectorName;
      listRequestSector.push(requestSector);
    });

    return listRequestSector;
  }

  populateObservationConfigData() {
    this.observationConfigService.getObsConfig(this.idObservationConfig).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.isAllocateToBed = response.observationConfig.allocateToBed;
      this.model.get('observationConfigName').setValue(response.observationConfig.observationConfigName);
      this.model.get('observationConfigDescription').setValue(response.observationConfig.observationConfigDescription);
      this.model.get('isMandatory').setValue(response.observationConfig.isMandatory);
      this.model.get('allocateToBed').setValue(response.observationConfig.allocateToBed);
      this.model.get('isReleasable').setValue(response.observationConfig.isReleasable);
      this.model.get('allowEvasion').setValue(response.observationConfig.allowEvasion);
      this.model.get('isReleasablePharmacy').setValue(response.observationConfig.isReleasablePharmacy);
      this.model.get('listSector').setValue(response.observationConfig.listIdSector);
      this.model.get('listIdTextTemplate').setValue(response.observationConfig.listIdTextTemplate);

      this.isLoading = false;
      this.isFirstLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  populateSectorSelect() {
    this.locationByModuleService.listRoomByModule(MenuModuleEnum.observation).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }

      this.listSector = response.sectors;
    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  populateTextTemplateSelect() {
    this.textTemplateService.getBySearchTextTemplate(null).subscribe({
      next: (response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listTextTemplate = response.list;
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  updateObsConfig(request: PostPutObservationConfigRequest) {
    this.observationConfigService.updateObsConfig(this.idObservationConfig, request).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/observation/observation-config']);

    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  createObsConfig(request: PostPutObservationConfigRequest) {
    this.observationConfigService.createObsConfig(request).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/observation/observation-config']);
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  allocateToBed(event: any) {
    if (event?.checked ?? false)
      this.isAllocateToBed = true;
    else {
      this.isAllocateToBed = false;
      if (this.model.get('isMandatory').value == true) {
        this.model.get('isMandatory').setValue(false);
        this.isMandatoryActive = false;
      }
    }
  }

  isMandatory(event: any) {
    if (event?.checked ?? false)
      this.isMandatoryActive = true;
    else {
      this.isMandatoryActive = false;
    }
  }
}