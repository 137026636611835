export class LookupsBooleanChoiceRequest {
    public isToGetMedicineGroupTypeLookup: boolean;

    public isToGetMedicineSubgroupLookup: boolean;
  
    public isToGetMedicinePresentationTypeLookup: boolean;
  
    public isToGetMedicineAdministrationTypeLookup: boolean;
  
    public isToGetKitMultiselectLookup: boolean;
}