import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { PatientPatchRequest } from '../../requests/medical-record/patient-patch.request';
import { PatientRequest } from '../../requests/medical-record/patient.request';
import { UpdateEmailPatientRequest } from '../../requests/medical-record/update-email-patient.request';
import { ListPatientStructResponse } from '../../responses/medical-record/list-patient-struct-response';
import { PatchPatientResponse } from '../../responses/medical-record/patch-patient.response';
import { PostPatientResponse } from '../../responses/medical-record/post-patient.response';

@Injectable({
  providedIn: 'root'
})
export class PatientService extends CommonService{

  constructor(private httpClient: HttpClient) {
    super();
  }

  public savePatient(body: PatientRequest): Observable<PostPatientResponse>{
    let uri = `Patient`

    return this.httpClient.post<PostPatientResponse>(environment.urlApiMedicalRecord + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    );
  }

  public updatePatient(body: PatientPatchRequest): Observable<PatchPatientResponse>{
    let uri = `Patient`

    return this.httpClient.patch<PatchPatientResponse>(environment.urlApiMedicalRecord + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    );
  }

  public updateEmailPatient(body: UpdateEmailPatientRequest): Observable<PatchPatientResponse>{
    let uri = `Patient/updateEmail`

    return this.httpClient.post<PatchPatientResponse>(environment.urlApiMedicalRecord + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    );
  }

  public getPatient(ids: number[]): Observable<ListPatientStructResponse>{
    let uri = `Patient/jsonListIdPatient/${JSON.stringify(ids)}`

    return this.httpClient.get<ListPatientStructResponse>(environment.urlApiMedicalRecord + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    );
  }

  public get(ids: number[]): Observable<ListPatientStructResponse>{
    let uri = `ListSyncMeasurerPatient/jsonListIdPatient/${JSON.stringify(ids)}/`

    return this.httpClient.get<ListPatientStructResponse>(environment.urlApiOrchestratorPatient + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    );
  }
}