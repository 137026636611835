import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilsClassificationService } from 'src/app/classification/utils.service';
import { DiscriminatorTypeEnum } from 'src/app/shared/enum/discriminator-type.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { DiscriminatorModel } from 'src/app/shared/services/models/risk-classification/discriminator.model';
import { SubDiscriminatorModel } from 'src/app/shared/services/models/risk-classification/subDiscriminator.model';
import { DiscriminatorStruct } from 'src/app/shared/services/structs/risk-classification/discriminator.struct';
import { SubDiscriminatorStruct } from 'src/app/shared/services/structs/risk-classification/sub-discriminator.struct';


export class FlowChartStructure {
  public column: SubDiscriminatorModel;
  public openModalValidate: boolean;
  public forceOpenModal: boolean;
}

@Component({
  selector: 'app-sepsis-modal',
  templateUrl: './sepsis-modal.component.html',
  styleUrls: ['./sepsis-modal.component.css']
})
export class SepsisModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<SepsisModalComponent>,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private utilService: UtilsClassificationService) { }

  public model: UntypedFormGroup;
  public firstColumn: FlowChartStructure[] = [];
  public list: DiscriminatorStruct[] = [];
  public subList: SubDiscriminatorStruct[] = [];
  public discriminatorName: string;

  ngOnInit(): void {
    this.model = this.formBuilder.group({});
    this.discriminatorName = this.data.discriminator.discriminatorName;
    this.list = this.data.list;
    this.data.discriminator.subDiscriminator.forEach((value, index) => {
      let flowStructure: FlowChartStructure = { column: value, openModalValidate: index != 0, forceOpenModal: false };
      let dis = this.list.find(c => c.idDiscriminatorType == value.idDiscriminatorType);
      let result = null;
      if (dis && dis.value) {
        result = dis.value;
      } else if (this.data.discriminatorsValues.length > 0 && this.data.discriminatorsValues[value.idDiscriminatorType]) {
        result = this.data.discriminatorsValues[value.idDiscriminatorType];
      }
      let sub: SubDiscriminatorStruct = { ...value, state: dis ? dis.state : null, type: dis ? dis.type : null, value: result, hasValue: result != null && dis ? true : false };

      this.subList.push(sub);

      this.firstColumn.push(flowStructure);
      this.model.addControl("radio-" + value.idSubDiscriminator.toString() + "-" + value.idDiscriminator.toString(),
        this.formBuilder.control({
          value: null,
          disabled: !(index == 0 &&
            (value.idDiscriminatorType == DiscriminatorTypeEnum.Boleano || sub.hasValue))
        }));
    });
  }

  close() {
    this.matDialogRef.close();
  }

  setDiscriminatorsValues(discriminatorsValues: any): void {
    if (discriminatorsValues.select) {
      let motive = discriminatorsValues.motive ? discriminatorsValues.motive : null;
      this.matDialogRef.close({ discriminatorsSelected: discriminatorsValues, value: true, select: true, list: this.list, discriminatorsValues: this.data.discriminatorsValues, motive });
    }
    if (this.subList[discriminatorsValues.index].value) {
      if (DiscriminatorTypeEnum.Boleano != this.subList[discriminatorsValues.index].idDiscriminatorType && DiscriminatorTypeEnum.Numerico != this.subList[discriminatorsValues.index].idDiscriminatorType) {
        this.data.discriminatorsValues[this.subList[discriminatorsValues.index].idDiscriminatorType] = this.subList[discriminatorsValues.index].value;
        this.list.forEach((c) => {
          if (c.idDiscriminatorType == this.subList[discriminatorsValues.index].idDiscriminatorType) {
            c.value = this.subList[discriminatorsValues.index].value;
            c.type = this.subList[discriminatorsValues.index].type;
            c.state = this.subList[discriminatorsValues.index].state;
            c.hasValue = true;
          }
        })
      }
      this.model.get("radio-" + this.subList[discriminatorsValues.index].idSubDiscriminator + "-" + this.subList[discriminatorsValues.index].idDiscriminator).enable();
    }
  }

  forceToOpenModal(item: SubDiscriminatorModel): void {
    if (item.idDiscriminatorType == DiscriminatorTypeEnum.Boleano)
      return;
    let discriminator = this.subList.find(c => c.idSubDiscriminator == item.idSubDiscriminator);
    if (!discriminator.hasValue) {
      let indexFirstColumn = this.firstColumn.findIndex(c => c.column.idSubDiscriminator == item.idSubDiscriminator);
      if (indexFirstColumn >= 0) {
        this.firstColumn[indexFirstColumn].forceOpenModal = !this.firstColumn[indexFirstColumn].forceOpenModal;
      }
    }
  }

  setValue(item: SubDiscriminatorModel, type: boolean): void {
    let index = this.subList.findIndex(c => c.idSubDiscriminator == item.idSubDiscriminator);
    if (!type) {
      if (DiscriminatorTypeEnum.PerfusaoCapilar == item.idDiscriminatorType) {
        let data = this.utilService.getClassificationData();
        data.perfusaoCapilar = false;
        this.utilService.updateClassificationData(data);
      }
      if (this.subList[index].hasValue && this.utilService.verifySelectDiscriminator(item, this.subList[index].value, this.subList[index].type, this.subList[index].state)) {
        this.matDialogRef.close({ discriminatorsSelected: this.data.discriminator, value: true, select: true, list: this.list, discriminatorsValues: this.data.discriminatorsValues });
        return;
      }
      this.validateToOpenModal(item);
      if (!this.subList[index].hasValue) return;
      if (index + 1 >= this.subList.length) {
        this.matDialogRef.close({ discriminator: this.data.discriminator, value: true, select: false, list: this.list, discriminatorsValues: this.data.discriminatorsValues });
        return;
      } else if (this.subList[index + 1].idDiscriminatorType == DiscriminatorTypeEnum.Boleano) {
        this.model.get("radio-" + this.subList[index + 1].idSubDiscriminator + "-" + this.subList[index + 1].idDiscriminator).enable();
      } else if (this.subList[index + 1].hasValue) {
        this.model.get("radio-" + this.subList[index + 1].idSubDiscriminator + "-" + this.subList[index + 1].idDiscriminator).enable();
      }
    } else {
      if (DiscriminatorTypeEnum.Boleano != this.subList[index].idDiscriminatorType && DiscriminatorTypeEnum.DorCoceira != this.subList[index].idDiscriminatorType) {
        if (!this.utilService.verifySelectDiscriminator(item, this.subList[index].value, this.subList[index].type, this.subList[index].state)) {
          if (index + 1 < this.subList.length) {
            this.model.get("radio-" + this.subList[index + 1].idSubDiscriminator + "-" + this.subList[index].idDiscriminator).disable()
          }
          if (this.model.get("radio-" + this.subList[index].idSubDiscriminator + "-" + this.subList[index].idDiscriminator).value == null) {
            this.model.get("radio-" + this.subList[index].idSubDiscriminator + "-" + this.subList[index].idDiscriminator).setValue(null);
          } else if (this.model.get("radio-" + this.subList[index].idSubDiscriminator + "-" + this.subList[index].idDiscriminator).value == false) {
            this.model.get("radio-" + this.subList[index].idSubDiscriminator + "-" + this.subList[index].idDiscriminator).setValue(false);
          }
          this.alertService.show('Aviso', "O valor inserido não se enquadra no parâmetro definido para " + this.data.discriminator.discriminatorName, AlertType.warning);
          return;
        }
      }
      if (DiscriminatorTypeEnum.PerfusaoCapilar == item.idDiscriminatorType) {
        let data = this.utilService.getClassificationData();
        data.perfusaoCapilar = true;
        this.utilService.updateClassificationData(data);
      }
      this.matDialogRef.close({ discriminatorsSelected: this.data.discriminator, value: true, select: true, list: this.list, discriminatorsValues: this.data.discriminatorsValues });
    }
  }

  validateToOpenModal(item: SubDiscriminatorModel): void {
    let indexFirstColumn = this.firstColumn.findIndex(c => c.column.idSubDiscriminator == item.idSubDiscriminator);
    if (indexFirstColumn + 1 < this.firstColumn.length) {
      this.firstColumn[indexFirstColumn + 1].openModalValidate = false;
    }
  }
}

