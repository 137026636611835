<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-profile-modal-list">
            <h2>Você deseja confirmar a {{data.idStatus == idStatusEpisodeInternar? "Internação":"alta" }} do
                paciente?</h2>
        </div>

        <div class="body-modal-list">
            Ao fazer a {{data.idStatus == idStatusEpisodeInternar? "Internação":"liberação" }}, essa ação é irreversível, deseja continuar?
        </div>
        <div class="footer-modal-list">
            <button mat-flat-button color="primary" class="btn-block" (click)="episodeRelease()">
                <span *ngIf="isLoading == false">Sim</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
            <button mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Cancelar</button>
        </div>
    </div>
</div>