import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CommonService } from "src/app/shared/services/common-service";
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';
import { catchError } from "rxjs/operators";
import { ReturnStruct } from "src/app/shared/structs/return.struct";
import { ListPrescriptionResponse } from "../../responses/medical-record/list-prescription.response";
import { ReadExternalPrescriptionStruct } from "../../structs/orchestrator-patient/read-external-prescription.struct";
import { NewExternalPrescriptionResponse } from "../../responses/medical-record/new-external-prescription.response";

@Injectable({
  providedIn: 'root'
})
export class ExternalPrescriptionService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listPresciption(idEpisode: number): Observable<ListPrescriptionResponse> {
    let uri = `ExternalPrescription/idEpisode/${idEpisode}`

    return this.httpClient.get<ListPrescriptionResponse>(environment.urlApiMedicalRecord + uri,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public createPresciption(idMedicalCare: number, body: ReadExternalPrescriptionStruct): Observable<NewExternalPrescriptionResponse> {
    let uri = `ExternalPrescription/idMedicalCare/${idMedicalCare}`

    return this.httpClient.post<NewExternalPrescriptionResponse>(environment.urlApiMedicalRecord + uri, body,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public updatePresciption(body: ReadExternalPrescriptionStruct): Observable<ReturnStruct> {
    let uri = `ExternalPrescription`

    return this.httpClient.put<ReturnStruct>(environment.urlApiMedicalRecord + uri, body,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public deletePresciption(idExternalPrescription: number): Observable<ReturnStruct> {
    let uri = `ExternalPrescription/idExternalPrescription/${idExternalPrescription}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiMedicalRecord + uri,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}