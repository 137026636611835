import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { SectorRequest } from '../../requests/flow/sector.request';
import { AreaRequest } from '../../requests/gamification/area.request';
import { GetSectorResponse } from '../../responses/flow/get-sector.response';
import { ListSectorResponse } from '../../responses/flow/list-sector.response';
import { GetAreaResponse } from '../../responses/gamification/get-area.response';
import { ListAreaResponse } from '../../responses/gamification/list-area.response';

@Injectable({
  providedIn: 'root'
})
export class AreaService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listArea(searchText: string): Observable<ListAreaResponse> {

    let uri = 'Area?'

    if (searchText != null)
      uri = uri + `searchText=${searchText}&`;

    return this.httpClient.get<ListAreaResponse>(environment.urlApiGamification + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getAreaSector(idArea: number): Observable<GetAreaResponse> {

    let uri = `Area/idArea/${idArea}`;

    return this.httpClient.get<GetAreaResponse>(environment.urlApiGamification + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public deleteArea(idArea: number): Observable<ReturnStruct> {
    return this.httpClient.delete<ReturnStruct>(environment.urlApiGamification + `Area/idArea/${idArea}`, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public updateArea(idArea: number, body: AreaRequest): Observable<ReturnStruct> {

    let uri = `Area/idArea/${idArea}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiGamification + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public createArea(body: AreaRequest): Observable<ReturnStruct> {

    let uri = `Area`

    return this.httpClient.post<ReturnStruct>(environment.urlApiGamification + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
