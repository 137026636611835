import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { FlowSequenceUpdateRequest } from '../../requests/flow/flow-sequence-update.request';
import { FlowSequenceRequest } from '../../requests/flow/flow-sequence.request';
import { FlowRequest } from '../../requests/flow/flow.request';
import { GetFlowSequenceResponse } from '../../responses/flow/get-flow-sequence.response';
import { GetFlowResponse } from '../../responses/flow/get-flow.response';
import { ListFlowResponse } from '../../responses/flow/list-flow.response';

@Injectable({
  providedIn: 'root'
})
export class FlowSequenceService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getFlowSequence(idEpisode: number): Observable<GetFlowSequenceResponse> {
    
    let uri = `FlowSequence/idEpisode/${idEpisode}`;  
    
    return this.httpClient.get<GetFlowSequenceResponse>(environment.urlApiFlow + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public createFlowSequence(body: FlowSequenceRequest): Observable<ReturnStruct>{

    let uri = `FlowSequence/`

    return this.httpClient.post<ReturnStruct>(environment.urlApiFlow + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public updateFlowSequence(body: FlowSequenceUpdateRequest): Observable<ReturnStruct>{

    let uri = `FlowSequence/`

    return this.httpClient.put<ReturnStruct>(environment.urlApiFlow + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public deleteFlowSequence(idFlow: number): Observable<ReturnStruct>{

    let uri = `FlowSequence/`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiFlow + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
