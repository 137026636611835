import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { BedHygienizationConfigRequest } from '../../requests/bed/bed-hygienization-config.request';
import { BedHygienizationRequest } from '../../requests/bed/bed-hygienization.request';
import { HygienizationConfigResponse } from '../../responses/bed/hygienization-config.response';
import { HygienizationStatusResponse } from '../../responses/bed/hygienization-status-lookup.response';
import { ListBedCategoryResponse } from '../../responses/bed/list-bed-category-response.response';
import { ListBedHygienizationResponse } from '../../responses/bed/list-bed-hygienization.response';

@Injectable({
  providedIn: 'root'
})
export class BedHygienizationConfigService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public switchConfig(body: BedHygienizationConfigRequest): Observable<ReturnStruct>{

    let uri = `BedHygienizationConfig`

    return this.httpClient.post<ReturnStruct>(environment.urlApiBed + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public getConfig(): Observable<HygienizationConfigResponse>{

    let uri = `BedHygienizationConfig`

    return this.httpClient.get<HygienizationConfigResponse>(environment.urlApiBed + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public lookupStatus(): Observable<HygienizationStatusResponse>{

    let uri = `BedHygienizationConfig/BedHygienizationStatus`

    return this.httpClient.get<HygienizationStatusResponse>(environment.urlApiBed + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
