import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { MedicConfigSectorResponse } from '../../responses/medic/medic-config-sector.response';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';

@Injectable({
    providedIn: 'root'
})
export class PinValidityService extends CommonService {

    constructor(private router: Router, private httpClient: HttpClient) {
        super();
    }

    public validate(idSector: number, pin: string): Observable<ReturnStruct> {

        let uri = `PinValidity/idSector/${idSector}?pin=${pin}`;

        return this.httpClient.get<ReturnStruct>(environment.urlApiMedic + uri)
            .pipe(
                catchError(this.handleError)
            )
    }
}
