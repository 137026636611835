import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { TissBillingRequest } from '../../requests/private-billing/tiss-billing.request';
import { GetTissBillingResponse } from '../../responses/private-billing/get-tiss-billing.response';
import { CreateTissBillingResponse } from '../../responses/private-billing/create-tiss-billing.response';
import { ListTissBillingResponse } from '../../responses/private-billing/list-tiss-billing.response';

@Injectable({
  providedIn: 'root'
})

export class TissBillingService extends CommonService {
  constructor(private httpClient: HttpClient) {
    super();
  }

  public getAll(): Observable<ListTissBillingResponse> {
    let uri = `TissBilling`

    return this.httpClient.get<ListTissBillingResponse>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }

  public getById(idTissBilling: number): Observable<GetTissBillingResponse> {
    let uri = `TissBilling/idTissBilling/${idTissBilling}`

    return this.httpClient.get<GetTissBillingResponse>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }

  public create(body: TissBillingRequest): Observable<CreateTissBillingResponse> {
    let uri = `TissBilling`

    return this.httpClient.post<CreateTissBillingResponse>(environment.urlApiPrivateBilling + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }

  public update(idTissBilling: number, body: TissBillingRequest): Observable<ReturnStruct> {
    let uri = `TissBilling/idTissBilling/${idTissBilling}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiPrivateBilling + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }

  public delete(): Observable<ReturnStruct> {
    let uri = `TissBilling`

    return this.httpClient.get<ReturnStruct>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }

  public updateStatusTissBilling(idTissBilling: number, isActive: boolean): Observable<GetTissBillingResponse> {
    let uri = `TissBilling/idTissBilling/${idTissBilling}/isActive/${isActive}`

    return this.httpClient.put<GetTissBillingResponse>(environment.urlApiPrivateBilling + uri, null, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }
}