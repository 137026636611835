export class CareLinePriorityStruct
{
    public idCareLinePriority: number;

    public careLinePriorityName: string;

    public idCareLinePriorityStatus: number;

    public careLinePriorityStatusMotive: string;

    public careLinePriorityStatusUserName: string;

    public datetimeCareLinePriorityStatus: Date;
}