import { BillingDataRequest } from "../billing/billing-data-request";
import { FlowSequenceUpdateRequest } from "../flow/flow-sequence-update.request";
import { FlowSequenceRequest } from "../flow/flow-sequence.request";
import { EpisodeCareLineRequest } from "../care-line/episode-care-line.request";
import { EpisodeStatusRequest } from "../medical-record/episode-status.request";
import { MedicalCareRequest } from "../medical-record/medical-care.request";
import { SelectedLocationRequest } from "../orchestrator-queue/selected-location.request";
import { MedicalCareReportRequest } from "./medical-care-report.request";
import { PostPresenceReportRequest } from "./post-presence-report.request";
import { GuideRequest } from "../private-billing/guide.request";

export class SaveCompletedMedicalRecordRequest {
    public idFlowCompleted: number;

    public flowSequence: FlowSequenceRequest;

    public medicalRecordRequest: MedicalCareRequest;

    public flowSequenceUpdateRequest: FlowSequenceUpdateRequest;

    public selectedLocationRequest: SelectedLocationRequest;

    public medicalCareReportRequest: MedicalCareReportRequest;

    public billingDataRequest: BillingDataRequest;

    public episodeStatusRequest: EpisodeStatusRequest;

    public postPresenceReportRequest: PostPresenceReportRequest;

    public episodeCareLineRequest: EpisodeCareLineRequest;

    public conclusionType: number;

    public guideRequest: GuideRequest;
}