import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../../common-service';
import { ListFormEpisodeReponse } from '../../../responses/orchestrator-form/list-form-episode.response';

@Injectable({
  providedIn: 'root'
})
export class OrchestratorFormEpisodeService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listFormEpisode(patientSearchText: string, birthDate: Date, protocolSearchText: string, startOfAttendance: Date, endOfAttendance: Date, pageIndex: number): Observable<ListFormEpisodeReponse> {
    let uri = `Episode/getAll?`

    if(patientSearchText != null)
      uri = uri+ `patientSearchText=${patientSearchText}&`;

    if(birthDate != null)
      uri = uri+ `birthDate=${birthDate.toISOString()}&`;

    if(protocolSearchText != null)
      uri = uri+ `protocolSearchText=${protocolSearchText}&`;

    if(startOfAttendance != null)
      uri = uri+ `startOfAttendance=${startOfAttendance.toISOString()}&`;

    if(endOfAttendance != null)
      uri = uri+ `endOfAttendance=${endOfAttendance.toISOString()}&`;

    if(pageIndex != null)
      uri = uri+ `pageIndex=${pageIndex}&`;
    
      
    return this.httpClient.get<ListFormEpisodeReponse>(environment.urlApiOrchestratorForm + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}

