import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { GetListConjunctionResponse } from '../../responses/flow/get-list-conjunction.response';
import { GetListLogicalExpressionResponse } from '../../responses/flow/get-list-logical-expression.response';
import { GetListTypeResponse } from '../../responses/flow/get-list-type.response';
import { GetKpiTimesResponse } from '../../responses/flow/kpi-times.response';


@Injectable({
  providedIn: 'root'
})
export class FlowLookupService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listType(): Observable<GetListTypeResponse> {

    let uri = `Lookup/Type`

    return this.httpClient.get<GetListTypeResponse>(environment.urlApiFlow + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public listLogicalExpression(): Observable<GetListLogicalExpressionResponse> {

    let uri = `Lookup/LogicalExpression`

    return this.httpClient.get<GetListLogicalExpressionResponse>(environment.urlApiFlow + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public listConjunction(): Observable<GetListConjunctionResponse> {

    let uri = `Lookup/Conjunction`

    return this.httpClient.get<GetListConjunctionResponse>(environment.urlApiFlow + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public listKpiTimes(): Observable<GetKpiTimesResponse> {

    let uri = `Lookup/KpiTimes`

    return this.httpClient.get<GetKpiTimesResponse>(environment.urlApiFlow + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
