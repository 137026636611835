import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PharmacyMovementService } from 'src/app/shared/services/API/pharmacy/pharmacy-movement.service';
import { PharmacyMovementRequest } from 'src/app/shared/services/requests/pharmacy/pharmacy-movement.request';
import { StorageAmountLocationStruct } from 'src/app/shared/services/structs/pharmacy/storage-amount-location.struct';

@Component({
  selector: 'app-correction-modal',
  templateUrl: './correction-modal.component.html',
  styleUrls: ['./correction-modal.component.css']
})
export class CorrectionModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: StorageAmountLocationStruct,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<CorrectionModalComponent>,
    private alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
    private pharmacyMovementService: PharmacyMovementService
  ) { }

  public model: UntypedFormGroup;
  public isLoading: boolean = false;
  ngOnInit(): void {
    this.model = this.formBuilder.group({
      amount: ['', [Validators.required]],
      correctionType: ['', [Validators.required]],
    });
  }

  clickCancel() {
    if (this.isLoading) {
      return;
    }
    this.matDialogRef.close();
  }

  close() {
    this.matDialogRef.close();
  }

  onSubmit() {
    if (this.isLoading) {
      return;
    }
    if (this.model.invalid) {
      this.alertService.show("Erro", "Preencha todos os campos da tela!", AlertType.error);
      return;
    }
    if (this.model.get("amount").value <= 0) {
      this.alertService.show("Erro", "Não é permitido adicionar o remover valores negativos ou nulos!", AlertType.error);
      return;
    }
    this.isLoading = true;
    let value: PharmacyMovementRequest = new PharmacyMovementRequest();
    value.idStorage = this.data.idStorage;
    value.idStorageAmountLocation = this.data.idStorageAmountLocation;
    value.correctionType = this.model.get("correctionType").value;
    value.amount = this.model.get("amount").value;
    this.pharmacyMovementService.updateAmount(value).subscribe((response) => {
      if (response.isError) {
        this.isLoading = false;
        this.alertService.show("Erro", response.errorDescription, AlertType.error);
        return;
      }
      this.alertService.show("Sucesso", "Valores atualizados com sucesso!", AlertType.success);
      this.matDialogRef.close({ updated: true });
    }, (error) => {
      this.isLoading = false;
      this.alertService.show("Erro Inesperado", error, AlertType.error);
      return;
    });
  }
}

