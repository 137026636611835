import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { StorageMaterialService } from 'src/app/shared/services/API/pharmacy/storage-material.service';
import { VerifyStorageService } from 'src/app/shared/services/API/pharmacy/verify-storage.service';
import { ItemModel } from 'src/app/shared/services/models/pharmacy/item.model';
import { StorageMaterialRequest } from 'src/app/shared/services/requests/pharmacy/storage-material.request';
import { ItemLocationStorageStruct } from 'src/app/shared/services/structs/pharmacy/item-location-storage.struct';
import { StorageLocationStruct } from 'src/app/shared/services/structs/pharmacy/storage-location.struct';
import { MaterialNonDispensationModalComponent } from '../material-non-dispensation-modal/material-non-dispensation-modal.component';
import { ConfirmSaveModalComponent } from './confirm-save-modal/confirm-save-modal.component';
import { TypeItemKitEnum } from 'src/app/shared/enum/pharmacy/type-item-kit.enum';
import { MedicineModel } from 'src/app/shared/services/models/pharmacy/medicine.model';

@Component({
  selector: 'app-episode-material-modal',
  templateUrl: './episode-material-modal.component.html',
  styleUrls: ['./episode-material-modal.component.css']
})
export class EpisodeMaterialModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<EpisodeMaterialModalComponent>,
    public verifyStorageService: VerifyStorageService,
    private alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
    private storageMaterialService: StorageMaterialService
  ) { }

  public isLoading: boolean = true;
  public model: UntypedFormGroup;
  public typeItemKitEnum: typeof TypeItemKitEnum = TypeItemKitEnum;

  public listItem: ItemModel[] = [];
  public listMedicines: MedicineModel[] = [];
  public listStorage: StorageLocationStruct[] = [];
  public arrayLocationItem: any[] = [];
  public itemTotals: ItemLocationStorageStruct[];

  ngOnInit(): void {
    this.populateSugestion();
    this.listItem = this.data.listItems;
    this.listMedicines = this.data.listMedicines;
    this.listStorage = this.data.listStorage;
    this.model = this.formBuilder.group({
      storageLocationItem: this.formBuilder.array([]),
    })
  }

  close() {
    this.matDialogRef.close();
  }

  populateSugestion() {
    this.storageMaterialService.getSugestionList(this.data.requestMaterial.idRequestMaterial).subscribe((response) => {
      if (response.isError) {
        this.alertService.show("Erro", response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.itemTotals = response.itemTotals;
      this.populateForm(response.itemLocations);
      if (response.missingItem) {
        this.alertService.show("Aviso", "Os itens a seguir estão em falta: " + response.missingItemName.join(", "), AlertType.warning);
      }
    });
  }

  submit() {
    let body: StorageMaterialRequest = new StorageMaterialRequest();
    if (this.isLoading) {
      return;
    }
    if (this.model.invalid) {
      let isQuantity: boolean = false;
      let hasNegative: boolean = false;
      this.model.get('storageLocationItem').value.forEach((x, i) => {
        if (this.arrayLocationItem[i] && this.arrayLocationItem[i].location && x.value > this.arrayLocationItem[i].location.amountRemaining) {
          isQuantity = true;
        } else if(x.value < 0) {
          hasNegative = true;
        }
      });
      if (isQuantity) {
        this.alertService.show("Erro", "Quantidade pedida excede quantidade na localização selecionada!", AlertType.error);
      } else if (hasNegative) {
        this.alertService.show("Erro", "Não pode haver valores negativos!", AlertType.error);
      } else {
        this.alertService.show("Erro", "Preencha todos os campos do pedido!", AlertType.error);
      }
      return;
    }
    this.isLoading = true;
    body.itemLocations = [];

    this.model.get('storageLocationItem').value.forEach((x, i) => {
      var itemLocation = new ItemLocationStorageStruct();
      itemLocation.idItem = x.typeItem == this.typeItemKitEnum.Material ? x.idItem : null;
      itemLocation.idMedicine = x.typeItem == this.typeItemKitEnum.Medicine ? x.idItem : null;
      itemLocation.idStorage = x.idStorage;
      itemLocation.idLocation = x.idLocation;
      itemLocation.quantity = x.value;
      body.itemLocations.push(itemLocation);
    });
    if (body.itemLocations.length == 0) {
      this.alertService.show("Erro", "Nenhum item foi selecionado para ser liberado!", AlertType.error);
      this.isLoading = false;
      return;
    }
    this.storageMaterialService.validateMaterial(this.data.requestMaterial.idRequestMaterial, body).subscribe((response) => {
      if (response.isError) {
        this.alertService.show("Erro", response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      if (!response.isWrong) {
        this.saveRequestMaterial(body);
      } else {
        this.openConfirmModal(body, response.wrongMessages);
      }
    });
  }

  saveRequestMaterial(body: StorageMaterialRequest) {
    this.storageMaterialService.saveMaterial(this.data.requestMaterial.idRequestMaterial, body).subscribe((response) => {
      if (response.isError) {
        this.alertService.show("Erro", response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.matDialogRef.close({ save: true });
    });
  }

  openConfirmModal(body: StorageMaterialRequest, wrongMessages: string[]) {
    const dialogRef = this.dialog.open(ConfirmSaveModalComponent, {
      data: {
        wrongMessages
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.confirm) {
        body.observation = result.observation;
        this.saveRequestMaterial(body);
      } else {
        this.isLoading = false;
      }
    });
  }

  populateForm(itemLocations: ItemLocationStorageStruct[]) {
    itemLocations.forEach((x, i) => {
      this.arrayLocationItem.push({});
      if (x.idItem)
        this.arrayLocationItem[i].listStorage = [...this.listStorage.filter(c => c.idItem == x.idItem)];
      if(x.idMedicine)
        this.arrayLocationItem[i].listStorage = [...this.listStorage.filter(c => c.idMedicine == x.idMedicine)];
      if (x.idStorage) {
        let storages = this.listStorage.find(c => c.idStorage == x.idStorage);
        this.arrayLocationItem[i].listLocations = [...storages.storageAmountLocation];
      }
      if (x.idLocation) {
        this.arrayLocationItem[i].location = this.arrayLocationItem[i].listLocations.find(c => c.idLocation == x.idLocation);
        (this.model.controls['storageLocationItem'] as UntypedFormArray).push(
          this.formBuilder.group({
            typeItem: [x.idItem ? this.typeItemKitEnum.Material : this.typeItemKitEnum.Medicine, [Validators.required]],
            idItem: [x.idItem ? x.idItem : x.idMedicine, Validators.required],
            idStorage: [x.idStorage, [Validators.required]],
            idLocation: [x.idLocation, [Validators.required]],
            value: [x.quantity, [Validators.required, Validators.max(this.arrayLocationItem[i].location.amountRemaining), Validators.min(0)]]
          }));
      } else {
        (this.model.controls['storageLocationItem'] as UntypedFormArray).push(
          this.formBuilder.group({
            typeItem: [x.idItem ? this.typeItemKitEnum.Material : this.typeItemKitEnum.Medicine, [Validators.required]],
            idItem: [x.idItem ? x.idItem : x.idMedicine, Validators.required],
            idStorage: [x.idStorage, [Validators.required]],
            idLocation: [x.idLocation, [Validators.required]],
            value: [x.quantity, [Validators.required, Validators.min(0)]]
          }));
      }
    });
    this.isLoading = false;
  }

  openMaterialNonDispensationModalFromModal() {
    const dialogRef = this.dialog.open(MaterialNonDispensationModalComponent, {
      data: {
        material: this.data.requestMaterial,
        patientName: this.data.episode.patientName,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.notDispensed){
        this.clickCancel();
      }
    });
  }

  clickCancel() {
    this.matDialogRef.close();
  }
}