<cdk-accordion-item *ngFor="let itemMultiProfessionalCare of multiProfessionalCare; let index = index;"
    #accordionItemModule="cdkAccordionItem" class="profile-register-accordion-item" role="button" tabindex="0"
    [attr.id]="'accordion-header-' + index" [attr.aria-expanded]="accordionItemModule.expanded"
    [attr.aria-controls]="'accordion-body-' + index">
    <div [ngClass]="{'profile-register-accordion-item-header-expanded': accordionItemModule.expanded}"
        class="profile-register-accordion-item-header" (click)="accordionItemModule.toggle()">
        Atendimento Multiprofissional - ({{ itemMultiProfessionalCare.multiProfessionalCare.userName }} -
        {{itemMultiProfessionalCare.datetimeStartAttendance | date:'dd/MM/yy, H:mm' }})
        <span [ngClass]="{'profile-register-accordion-item-description-expanded': accordionItemModule.expanded}"
            class="profile-register-accordion-item-description">
            Clique para {{ accordionItemModule.expanded ? 'fechar' : 'abrir' }}
        </span>
    </div>
    <div class="profile-register-accordion-item-body" role="region"
        [style.display]="accordionItemModule.expanded ? '' : 'none'" [attr.id]="'accordion-body-' + index"
        [attr.aria-labelledby]="'accordion-header-' + index">
        <div class="feature-content">
            <div class="row">
                <div class="col-6 col-sm-3 col-md-2">
                    <mat-form-field appearance="outline" class="outline-spinner">
                        <mat-label>Usuário de atendimento</mat-label>
                        <input matInput type="text" readonly
                            [value]="itemMultiProfessionalCare.multiProfessionalCare.userName">
                    </mat-form-field>
                </div>
                <div class="col-6 col-sm-3 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>CRM/COREN</mat-label>
                        <input matInput type="text" readonly
                            [value]="itemMultiProfessionalCare.multiProfessionalCare.userCouncilNumber">
                    </mat-form-field>
                </div>
                <div class="col-6 col-sm-3 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Data e hora inicial</mat-label>
                        <input matInput type="text" readonly
                            value="{{itemMultiProfessionalCare.datetimeStartAttendance | date:'dd/MM/yy, H:mm' }}">
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-3 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Data e hora final</mat-label>
                        <input matInput type="text" readonly
                            value="{{itemMultiProfessionalCare.datetimeInclusion | date:'dd/MM/yy, H:mm' }}">
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-3 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Tempo de atendimento</mat-label>
                        <input matInput type="text" readonly [value]="itemMultiProfessionalCare.serviceTime">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Sala</mat-label>
                        <input matInput type="text" readonly
                            [value]="itemMultiProfessionalCare.multiProfessionalCare.roomAttendanceName">
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="!itemMultiProfessionalCare.hideDataPatientHistory && isArray(itemMultiProfessionalCare.listNameCid)"
                class="row">
                <div class="col-12">
                    <mat-form-field appearance="outline" class="outline-spinner">
                        <mat-label>CID</mat-label>
                        <textarea matInput readonly
                            [value]="itemMultiProfessionalCare.listNameCid.join(', ')"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="!itemMultiProfessionalCare.hideDataPatientHistory" class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Anotações médicas (queixas, história da doença atual, medicamentos em uso, história
                            pregressa)</mat-label>
                        <textarea matInput readonly value="{{itemMultiProfessionalCare.medicalNotes}}"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="!itemMultiProfessionalCare.hideDataPatientHistory" class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Exame físico</mat-label>
                        <textarea matInput readonly
                            value="{{itemMultiProfessionalCare.physicalExamination}}"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="!itemMultiProfessionalCare.hideDataPatientHistory" class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Plano Terapêutico</mat-label>
                        <textarea matInput readonly value="{{itemMultiProfessionalCare.therapeuticPlan}}"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="!itemMultiProfessionalCare.hideDataPatientHistory" class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Hipótese Diagnóstica</mat-label>
                        <textarea matInput readonly
                            value="{{itemMultiProfessionalCare.diagnosedHypothesis}}"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="!itemMultiProfessionalCare.hideDataPatientHistory" class="row">
                <div class="col-6 col-sm-3 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Temperatura</mat-label>
                        <input type="number" matInput readonly value="{{itemMultiProfessionalCare.temperature}}">
                        <span matSuffix>ºC</span>
                    </mat-form-field>
                </div>
                <div class="col-6 col-sm-3 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>FR</mat-label>
                        <input type="number" matInput readonly
                            value="{{itemMultiProfessionalCare.respiratoryFrequency}}">
                        <span matSuffix>irpm</span>
                    </mat-form-field>
                </div>
                <div class="col-6 col-sm-3 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>FC</mat-label>
                        <input type="number" matInput readonly value="{{itemMultiProfessionalCare.heartRate}}">
                        <span matSuffix>bpm</span>
                    </mat-form-field>
                </div>
                <div class="col-6 col-sm-3 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>GC</mat-label>
                        <input type="number" matInput readonly value="{{itemMultiProfessionalCare.glucose}}">
                        <span matSuffix>mg/dL</span>
                    </mat-form-field>
                </div>
                <div class="col-6 col-sm-3 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>PA</mat-label>
                        <input type="text" matInput readonly
                            value="{{itemMultiProfessionalCare.bloodPressureSystole && itemMultiProfessionalCare.bloodPressureDiastole ? itemMultiProfessionalCare.bloodPressureSystole + '/' + itemMultiProfessionalCare.bloodPressureDiastole : null}}">
                        <span matSuffix>mmHG</span>
                    </mat-form-field>
                </div>
                <div class="col-6 col-sm-3 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>SatO2</mat-label>
                        <input type="number" matInput readonly value="{{itemMultiProfessionalCare.saturation}}">
                        <span matSuffix>%</span>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-2"
                    *ngIf="!itemMultiProfessionalCare.hideDataPatientHistory && itemMultiProfessionalCare.multiProfessionalCareReportName">
                    <button mat-flat-button color="primary" class=" btn-block"
                        (click)="!isLoading ? downloadReport(itemMultiProfessionalCare.idEpisode, itemMultiProfessionalCare.multiProfessionalCareReportName) : false">
                        <span *ngIf="!isLoading">Atendimento Multiprofissional</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                    </button>
                </div>
                <div class="col-12 col-sm-6 col-md-2"
                    *ngIf="!itemMultiProfessionalCare.hideDataPatientHistory && itemMultiProfessionalCare.sickNoteReportName">
                    <button mat-flat-button color="primary" class=" btn-block"
                        (click)="downloadReport(itemMultiProfessionalCare.idEpisode, itemMultiProfessionalCare.sickNoteReportName)">
                        <span *ngIf="!isLoading">Atestado</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                    </button>
                </div>
                <div class="col-12 col-sm-6 col-md-2"
                    *ngIf="!itemMultiProfessionalCare.hideDataPatientHistory && itemMultiProfessionalCare.sadtReportExternalName">
                    <button mat-flat-button color="primary" class=" btn-block"
                        (click)="downloadReport(itemMultiProfessionalCare.idEpisode, itemMultiProfessionalCare.sadtReportExternalName)">
                        <span *ngIf="!isLoading">Exames Externos</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                    </button>
                </div>
                <div class="col-12 col-sm-6 col-md-2"
                    *ngIf="!itemMultiProfessionalCare.hideDataPatientHistory && itemMultiProfessionalCare.externalPrescriptionReportName">
                    <button mat-flat-button color="primary" class=" btn-block"
                        (click)="downloadReport(itemMultiProfessionalCare.idEpisode, itemMultiProfessionalCare.externalPrescriptionReportName)">
                        <span *ngIf="!isLoading">Prescrições Não Padronizadas</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                    </button>
                </div>
            </div>
            <div class="row"
                *ngIf="!itemMultiProfessionalCare.hideDataPatientHistory && isArray(itemMultiProfessionalCare.valueMetaData)">
                <div class="title-header">
                    <h3>Dados Adicionais</h3>
                </div>
                <div class="col-6 col-sm-3 col-md-2" *ngFor="let data of itemMultiProfessionalCare.valueMetaData">
                    <mat-form-field appearance="outline" class="outline-spinner">
                        <mat-label>{{data.metaDataName}}</mat-label>
                        <input matInput type="text" readonly [value]="data.metaDataValue">
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="!itemMultiProfessionalCare.hideDataPatientHistory" class="title-header">
                <h4>Atestado</h4>
            </div>
            <div *ngIf="!itemMultiProfessionalCare.hideDataPatientHistory" class="row">
                <div class="col-6 col-sm-3 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Dias de atestado</mat-label>
                        <input type="number" matInput readonly value="{{itemMultiProfessionalCare.sickDaysOffAmount}}">
                        <span matSuffix>dias</span>
                    </mat-form-field>
                </div>
            </div>
            <div class="card-list"
                *ngIf="!itemMultiProfessionalCare.hideDataPatientHistory && isArray(itemMultiProfessionalCare.externalPrescription)">
                <div class="title-header">
                    <h4>Lista de Prescrições Não Padronizadas</h4>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4"
                        *ngFor="let item of itemMultiProfessionalCare.externalPrescription">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary">{{item.description}}</span>
                                <span class="secundary">Quantidade: {{item.quantity}}</span>
                                <span class="secundary">Frequência: {{item.frequency}}</span>
                                <span class="secundary">Observação: {{item.observation}}</span>
                            </div>
                            <div class="actions">
                                <a>
                                    <mat-icon aria-hidden="false" aria-label="Ver mais"
                                        (click)="openExternalPrescriptionModal(item)">visibility</mat-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-list"
                *ngIf="!itemMultiProfessionalCare.hideDataPatientHistory && isArray(itemMultiProfessionalCare.sadtGroup)">
                <div class="title-header">
                    <h3>Lista de Exames</h3>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of itemMultiProfessionalCare.sadtGroup">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary">{{item.sadtName}}</span>
                                <span class="secundary"
                                    [ngClass]="{'exame-entregue': item.idStatus == 2}">{{item.statusName}}</span>
                                <span class="secundary">{{item.usageName}}</span>
                                <span class="secundary">{{item.datetimeInclusion | date:'dd/MM/yy, H:mm'}}</span>
                            </div>
                            <div class="actions">
                                <a>
                                    <mat-icon aria-hidden="false" aria-label="Ver mais" (click)="openSadtModal(item)">
                                        visibility</mat-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</cdk-accordion-item>