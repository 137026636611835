import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { GetTotemResponse } from '../../responses/totem/get-totem.response';

@Injectable({
  providedIn: 'root'
})
export class ClientTotemService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public verifyPin(pin: string, idTotem: number): Observable<GetTotemResponse> {
    
    let uri = `ClientTotem/idTotem/${idTotem}/pin/${pin}`
    
    let requestOptions = {
      headers: new HttpHeaders({
        'Authorization': environment.authorizationHash
      }),
    };

    return this.httpClient.get<GetTotemResponse>(environment.urlApiTotem + uri, requestOptions)
    .pipe(
      catchError(this.handleError)
    )
  }
}
