import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { AreaService } from 'src/app/shared/services/API/gamification/area.service';

@Component({
  selector: 'app-area-delete-modal',
  templateUrl: './area-delete-modal.component.html',
  styleUrls: ['./area-delete-modal.component.css']
})
export class AreaDeleteModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<AreaDeleteModalComponent>,
    private areaService: AreaService,
    private alertService: AlertService,) { }

  public isLoading: boolean;

  ngOnInit(): void {
    this.isLoading = false;
  }

  close() {
    this.matDialogRef.close({ deleteArea: false });
  }

  clickDelete() {
    this.isLoading = true;

    this.areaService.deleteArea(this.data.idArea, this.data.ip).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Area excluido com sucesso!", AlertType.success);
        this.matDialogRef.close({ deleteArea: true });
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  clickCancel() {
    this.matDialogRef.close({ deleteArea: false });
  }
}