import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ListUriResponse } from '../../responses/integration/list-uri.response';

@Injectable({
  providedIn: 'root'
})
export class UriService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listUri(): Observable<ListUriResponse> {
    
    let uri = 'Uri'
    
    return this.httpClient.get<ListUriResponse>(environment.urlApiIntegration + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
