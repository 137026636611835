<div mat-dialog-contentl>
    <div class="mat-dialog-content">
        <div class="head-confirm-modal-list">
            <h1><b>{{this.data.modalTitle}}</b></h1>
            <h2>{{this.data.modalBody}}</h2>
        </div>
        <form class="form">
            <div class="footer-confirm-modal-list">
                <button mat-flat-button color="primary" class="btn-block" type="button" (click)="click()">
                    <span *ngIf="isLoading == false">Fechar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
            </div>
        </form> 
    </div>
</div>