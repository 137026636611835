import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { PostPutMultiProfessionalConfigRequest } from '../../requests/multi-professional/post-put-multi-professional-config.request';
import { GetMultiProfessionalConfigResponse } from '../../responses/multi-professional/get-multi-professional-config.response';
import { ListMultiProfessionalConfigResponse } from '../../responses/multi-professional/list-multi-professional-config.response';
import { MetaDataStruct } from '../../structs/meta-data/meta-data.struct';

@Injectable({
  providedIn: 'root'
})
export class MultiProfessionalConfigService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  public listMultiConfig(searchText: string, body: number[]): Observable<ListMultiProfessionalConfigResponse> {
    let uri = 'MultiProfessionalConfig/GetAll?'

    if (searchText != null)
      uri = uri + `searchText=${searchText}`;

    return this.httpClient.post<ListMultiProfessionalConfigResponse>(environment.urlApiMultiProfessional + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }

  public getMultiProfessionalConfig(listMetaData: MetaDataStruct[], idMultiProfessionalConfig: number): Observable<GetMultiProfessionalConfigResponse> {
    let uri = `MultiProfessionalConfig/idMultiProfessionalConfig/${idMultiProfessionalConfig}`;

    return this.httpClient.post<GetMultiProfessionalConfigResponse>(environment.urlApiMultiProfessional + uri, listMetaData, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }

  public createMultiProfessionalConfig(body: PostPutMultiProfessionalConfigRequest): Observable<ReturnStruct> {
    let uri = `MultiProfessionalConfig`

    return this.httpClient.post<ReturnStruct>(environment.urlApiMultiProfessional + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }

  public updateMultiProfessionalConfig(idMultiProfessionalConfig: number, body: PostPutMultiProfessionalConfigRequest): Observable<ReturnStruct> {
    let uri = `MultiProfessionalConfig/idMultiProfessionalConfig/${idMultiProfessionalConfig}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiMultiProfessional + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }

  public deleteMultiProfessionalConfig(idMultiProfessionalConfig: number): Observable<ReturnStruct> {
    let uri = `MultiProfessionalConfig/idMultiProfessionalConfig/${idMultiProfessionalConfig}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiMultiProfessional + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }
}