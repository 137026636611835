import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { DashboardRequest } from '../../requests/data-intelligence/dashboard-request';
import { DashboardResponse } from '../../responses/data-intelligence/dashboard.response';
import { ListDashboardResponse } from '../../responses/data-intelligence/list-dashboard.response';


@Injectable({
  providedIn: 'root'
})
export class FavoritesService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public favorite(idDashboard: number): Observable<DashboardResponse> {
    
    let uri = `Favorites/idDashboard/${idDashboard}`;

    return this.httpClient.post<DashboardResponse>(environment.urlApiDataIntelligence + uri, null, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public unfavorite(idDashboard: number): Observable<DashboardResponse> {
    
    let uri = `Favorites/idDashboard/${idDashboard}`;

    return this.httpClient.delete<DashboardResponse>(environment.urlApiDataIntelligence + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}