import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { EvadePatientModalComponent } from 'src/app/shared/components/evade-patient-modal/evade-patient-modal.component';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { SelectRoomModalComponent } from 'src/app/shared/components/select-room-modal/select-room-modal.component';
import { StatusQueueEnum } from 'src/app/shared/enum/status-queue.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { GetRoomService } from 'src/app/shared/services/API/flow/get-room.service';
import { RoomService } from 'src/app/shared/services/API/flow/room.service';
import { CallPanelService } from 'src/app/shared/services/API/orchestrator-panel/call-panel.service';
import { AttendPatientService } from 'src/app/shared/services/API/orchestrator-queue/attend-patient.service';
import { PatientListService } from 'src/app/shared/services/API/orchestrator-queue/patient-list.service';
import { PriorityService } from 'src/app/shared/services/API/totem/priority.service';
import { PriorityModel } from 'src/app/shared/services/models/totem/priority.model';
import { AttendPatientRequest } from 'src/app/shared/services/requests/orchestrator-queue/attend-patient.request';
import { UpdateStatusQueueRequest } from 'src/app/shared/services/requests/queue/update-status-queue.request';
import { ListPatientResponse } from 'src/app/shared/services/responses/orchestrator-queue/list-patient.response';
import { RoomStruct } from 'src/app/shared/services/structs/flow/room.struct';
import { ListPatientStruct } from 'src/app/shared/services/structs/orchestrator-queue/list-patient.struct';
import { UtilService } from 'src/app/shared/services/util.service';
import { WebsocketQueueService } from 'src/app/shared/services/websocket-queue.service';
import { ReturnMotiveModalComponent } from 'src/app/patient-history/pages/patient-history-details/return-motive-modal/return-motive-modal.component';
import { ReturnPatientRequest } from 'src/app/shared/services/requests/orchestrator-queue/return-patient.request';
import { QueueHistoryService } from 'src/app/shared/services/API/orchestrator-queue/queue-history.service';
import { CallPanelEpisodeRequest } from 'src/app/shared/services/requests/orchestrator-panel/call-panel-episode.request';
import { PanelCallEpisodeStruct } from 'src/app/shared/services/structs/orchestrator-panel/panel-call-episode.struct';
import { EpisodeStatusEnum } from 'src/app/shared/enum/episode-status.enum';
import { GetMedicalCareReportService } from 'src/app/shared/services/API/medical-record/get-medical-care-report.service';
import { MedicalCareReprintReportModalComponent } from './medical-care-reprint-report-modal/medical-care-reprint-report-modal.component';
import { OtherQueuePatientModalComponent } from './other-queue-patient-modal/other-queue-patient-modal.component';
import { NatureOfAttendanceEnum } from 'src/app/shared/enum/medical-record/nature-of-attendance.enum';
import { MedicConfigSectorService } from 'src/app/shared/services/API/medic/medic-config-sector.service';
import { MedicConfigModel } from 'src/app/shared/services/models/medic/medic-config.model';
import { withoutPermissionEvadeModal } from 'src/app/shared/components/without-permission-evade-modal/without-permission-evade-modal.component';
import { WebsocketRoomUtilService } from 'src/app/shared/services/websocket-util/websocket-room-util.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CareLinePriorityStatusEnum } from 'src/app/shared/enum/care-line-priority/care-line-priority-status.enum';
import { CsatService } from 'src/app/shared/services/API/csat/csat.service';
import { MedicFavoritePrescriptionSelectModalComponent } from './medic-favorite-prescription-select-modal/medic-favorite-prescription-select-modal.component';
import { PrioritiesStatusEnum } from 'src/app/shared/enum/priorities-status.enum';
import { WebsocketAttendanceUtilService } from 'src/app/shared/services/websocket-util/websocket-attendance-util.service';
import { AlertModalComponent } from 'src/app/shared/components/alert-modal/alert-modal.component';
import { SignatureProviderEnum } from 'src/app/shared/enum/signature-providers/signature-providers.enum';
import { UserRoleEnum } from 'src/app/shared/enum/user-role.enum';
import { UserLoginResponse } from 'src/app/shared/services/responses/user/auth.response';
import { DigitalSignatureUtilService } from 'src/app/shared/services/digital-signature-util.service';
import { MenuComponent } from 'src/app/shared/components/menu/menu.component';
import { HealthUnitService } from 'src/app/shared/services/API/user/health-unit.service';
import { TissGuideManagementModalComponent } from 'src/app/shared/components/tiss-guides/tiss-guide-management-modal/tiss-guide-management-modal.component';
import { CareLinePriorityEnum } from 'src/app/shared/enum/care-line-priority/care-line-priority.enum';
import { HoldModalComponent } from 'src/app/shared/components/hold-modal/hold-modal.component';
import { PatientListAttendanceConfirmModalComponent } from 'src/app/shared/components/patient-list-attendance-confirm-modal/patient-list-attendance-confirm-modal.component';
import { PatientListLiberateAttendanceModalComponent } from 'src/app/shared/components/patient-list-liberate-attendance-modal/patient-list-liberate-attendance-modal.component';
import { PatientListLiberateCallConfirmModalComponent } from 'src/app/shared/components/patient-list-liberate-call-confirm-modal/patient-list-liberate-call-confirm-modal.component';
import { PatientListAlertCallConfirmModalComponent } from 'src/app/shared/components/patient-list-alert-call-confirm-modal/patient-list-alert-call-confirm-modal.component';
import { PatientListCallConfirmModalComponent } from 'src/app/shared/components/patient-list-call-confirm-modal/patient-list-call-confirm-modal.component';

@Component({
  selector: 'app-medic-patient-list',
  templateUrl: './medic-patient-list.component.html',
  styleUrls: ['./medic-patient-list.component.css']
})
export class MedicPatientListComponent implements OnInit, OnDestroy {

  constructor(
    private priorityService: PriorityService,
    private callPanelService: CallPanelService,
    private patientListService: PatientListService,
    private attendPatientService: AttendPatientService,
    private signatureUtilService: DigitalSignatureUtilService,
    private router: Router,
    private alertService: AlertService,
    public dialog: MatDialog,
    private websocketqueueService: WebsocketQueueService,
    private activatedRoute: ActivatedRoute,
    private roomService: RoomService,
    private getRoomService: GetRoomService,
    private utilsService: UtilService,
    private queueHistoryService: QueueHistoryService,
    private getMedicalCareReportService: GetMedicalCareReportService,
    private medicConfigSectorService: MedicConfigSectorService,
    public websocketRoomUtilService: WebsocketRoomUtilService,
    public websocketAttendanceUtilService: WebsocketAttendanceUtilService,
    private authService: AuthService,
    private csatService: CsatService,
    private healthUnitService: HealthUnitService,
  ) {
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MenuComponent) menu: MenuComponent;

  public myPatientsCheck: boolean = false;
  public seeButton: boolean = false;
  public idRoom: number;
  public idSector: number
  public selectedRoom: RoomStruct;
  public idQueue: number;
  public listPriority: PriorityModel[];
  public updateStatusRequest: UpdateStatusQueueRequest;
  public listPatientResponse: ListPatientResponse;
  public isLoading: boolean = false;
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.medic;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.medic_patient_list;
  public searchText: string = '';
  selectedEpisode: number = 0;
  public buttonReturn: number = StatusQueueEnum.retorno;
  public jsonListIdStatusQueue: string;
  public isOnline: boolean = true;
  public patientLeftQueue: boolean;
  public patientStartAttendance: boolean;
  public medicConfig: MedicConfigModel;
  public allowEvasionByMaster: boolean;
  private agendados: boolean = false;

  public selectedPatient: ListPatientStruct | null = null;

  public attendancePresential: NatureOfAttendanceEnum = NatureOfAttendanceEnum.presencial;
  public attendanceOnline: NatureOfAttendanceEnum = NatureOfAttendanceEnum.online;
  public remoteAttendance: boolean = false;
  public careLinePriorityStatusDenied = CareLinePriorityStatusEnum.negado;
  private seeOccupiedRoom: boolean = false;
  public canReturnToQueue: boolean = true;
  public showTissGuideButton: boolean = false;

  //websocket
  socketDisconneted: boolean = false;
  onlineOffline: boolean = navigator.onLine;
  private socket: any;

  public idStatusQueueRetorno: StatusQueueEnum = StatusQueueEnum.retorno;
  public idStatusQueueEmAtendimento: StatusQueueEnum = StatusQueueEnum.em_atendimento;
  public autismEnum: number = PrioritiesStatusEnum.AutistaTEA;
  public overSixtyYearsEnum: number = PrioritiesStatusEnum.MaiorDe60Anos;
  public overEightyYearsEnum: number = PrioritiesStatusEnum.MaiorDe80Anos;
  public dengue: CareLinePriorityEnum = CareLinePriorityEnum.Dengue;
  public hasReturn: boolean = false;

  ngOnInit(): void {
    this.jsonListIdStatusQueue = null;
    this.updateStatusRequest = new UpdateStatusQueueRequest();
    this.listPatientResponse = new ListPatientResponse();
    this.listPatientResponse.listPatient = [];
    this.canReturnToQueue = this.utilsService.getReturnToQueueConfig();
    this.utilsService.cleanSecundaryHeaderModuleConfig('medic');
    if (this.activatedRoute.snapshot.paramMap.get('idRoom'))
      this.idRoom = parseInt(this.activatedRoute.snapshot.paramMap.get('idRoom'));

    if (this.activatedRoute.snapshot.paramMap.get('idSector'))
      this.idSector = parseInt(this.activatedRoute.snapshot.paramMap.get('idSector'));

    if (this.idRoom && this.idSector) {
      if (this.websocketRoomUtilService.getIdRoom() != this.idRoom) {
        this.websocketRoomUtilService.clearSocket();
      }
      this.getRoomUnAuth();
      this.startWebsocket();
      this.getMedicConfigSector();
    }
    else
      this.selectRoomModal()

    if (this.websocketAttendanceUtilService.isConnect())
      this.websocketAttendanceUtilService.clearSocket();

    this.getCsat();
    this.populatePriorityMultiSelect();
    this.getHealthUnitConfig();
  }

  getCsat() {
    this.csatService.getCsat().subscribe({
      next: (response) => {
        this.seeButton = response.seeButton;
      },
    });
  }

  search(pageIndex: number) {
    this.isLoading = true;

    if (this.searchText == "")
      this.searchText = null;

    this.patientListService.listPatient(this.searchText, this.idRoom, this.jsonListIdStatusQueue, pageIndex, this.menuModuleEnum, this.remoteAttendance, this.agendados, this.myPatientsCheck).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.listPatientResponse = response;

        this.listPatientResponse.listPatient.forEach(x => {
          x.isLoading = false;
        });

        if (this.listPatientResponse?.returnEpisodes != null && this.listPatientResponse?.returnEpisodes > 0 && (this.jsonListIdStatusQueue == null || !this.jsonListIdStatusQueue.includes(StatusQueueEnum.retorno.toString())))
          this.alertService.show('Atenção! ', this.listPatientResponse?.returnEpisodes + ' pacientes aguardando reavaliação - retorno', AlertType.warning);

        if (this.listPatientResponse?.scheduleEpisodes != null && this.listPatientResponse?.scheduleEpisodes > 0)
          this.alertService.show('Atenção! ', this.listPatientResponse?.scheduleEpisodes + ' pacientes agendados aguardando atendimento', AlertType.warning);

        if (this.jsonListIdStatusQueue && JSON.parse(this.jsonListIdStatusQueue).includes(this.buttonReturn)) {
          this.listPatientResponse?.listPatient?.forEach(patient => {
            if (patient.requestsWereDelivered) {
              const patientName = patient.patientSocialName || patient.patientName;
              this.alertService.show('', `<strong>Exames liberados</strong> para o paciente ${patientName} (Episódio ${patient.idEpisode})`, AlertType.success);
            }
          });
        }

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  toggleIsLoading(index: number) {
    this.listPatientResponse.listPatient[index].isLoading = !this.listPatientResponse.listPatient[index].isLoading;
  }

  getMedicConfigSector() {
    this.medicConfigSectorService.listMedicConfigSector(this.idSector).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        if (Array.isArray(response.medicConfigSectorStruct) && response.medicConfigSectorStruct.length > 0) {
          this.medicConfig = response.medicConfigSectorStruct[0].medicConfig;
          this.allowEvasionByMaster = response.medicConfigSectorStruct[0].allowEvasionByMaster;
          this.remoteAttendance = response.medicConfigSectorStruct[0].medicConfig.remoteAttendance;
        } else {
          this.remoteAttendance = false;
        }
        this.paginator.firstPage();
        this.search(0);
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populatePriorityMultiSelect() {
    this.priorityService.listPriority().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listPriority = response.listPriority;
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  callPatient(patient: ListPatientStruct, index: number) {
    if (this.websocketRoomUtilService.getIsOccupied()) {
      this.alertService.show("Aviso", "Você está em modo de visualização!", AlertType.warning);
      return;
    }
    this.toggleIsLoading(index);

    let request = new CallPanelEpisodeRequest();
    request.episodeData = new PanelCallEpisodeStruct();

    request.episodeData.idEpisode = patient.idEpisode;
    request.episodeData.patientName = patient.patientName;
    request.episodeData.patientSocialName = patient.patientSocialName;
    request.episodeData.ticketInitials = patient.ticketInitials;
    request.episodeData.ticketSequence = patient.ticketSequence;
    request.episodeData.idAttendantUser = patient.idAttendantUser;
    request.episodeData.idStatusQueue = patient.idStatusQueue;
    request.episodeData.attendantUserName = patient.attendantUserName;
    this.callPanelService.callPatient(request, patient.idQueue, this.idRoom, true, this.menuModuleEnum, this.remoteAttendance).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.toggleIsLoading(index);
          return;
        }
        if (response.errorCode == -1)
          this.alertService.show('Aviso', response.errorDescription, AlertType.error);
        if (response.isRestricted)
          this.openRestrictedCallModal(request, response.confirmMessage, index, patient);
        else if (response.isAlert)
          this.openAlertModal(request, response.confirmMessage, index, patient);
        else if (response.isConfirm)
          this.openConfirmModal(request, response.confirmMessage, index, patient);
        else
          this.updateCallCount(index, patient);

        this.toggleIsLoading(index);
      },
      error: (error) => {
        console.log(error)
        this.toggleIsLoading(index);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
    this.selectedEpisode = patient.idEpisode;
  }

  cancelPatient(idQueue: number, idStatus: number, idEpisode: number, index: number) {
    if (this.websocketRoomUtilService.getIsOccupied()) {
      this.alertService.show("Aviso", "Você está em modo de visualização!", AlertType.warning);
      return;
    }
    var allowEvasion = this.medicConfig ? this.medicConfig.allowEvasion : true;
    var allowEvasionByMaster = this.allowEvasionByMaster;
    if (allowEvasion || allowEvasionByMaster) {
      this.toggleIsLoading(index);
      this.openEvadePatientModal(idQueue, idStatus, idEpisode, index);
    }
    else
      this.withoutPermissionEvadeModal();
  }

  withoutPermissionEvadeModal() {
    const dialogRef = this.dialog.open(withoutPermissionEvadeModal);
    dialogRef.afterClosed().subscribe();
  }

  openEvadePatientModal(idQueue: number, idStatusQueue: number, idEpisode: number, index: number) {
    const dialogRef = this.dialog.open(EvadePatientModalComponent, {
      data: {
        idStatusQueue: idStatusQueue,
        idQueue: idQueue,
        idRoom: this.idRoom,
        idEpisode: idEpisode,
        manualEvasion: true,
      },
    });

    dialogRef.afterClosed().subscribe({
      next: result => {
        if (!result || !result.isEvade)
          this.toggleIsLoading(index);
        else
          this.alertService.show('Sucesso', "Paciente evadido com sucesso", AlertType.success);
      }
    });
  }

  attendPatient(patient: ListPatientStruct, index: number, isCheckAttendance: boolean = true) {
    if (this.websocketRoomUtilService.getIsOccupied()) {
      this.alertService.show("Aviso", "Você está em modo de visualização!", AlertType.warning);
      return;
    }
    let attendPatientRequest: AttendPatientRequest = new AttendPatientRequest();
    attendPatientRequest.patientQueue = patient;
    attendPatientRequest.idModule = this.menuModuleEnum;
    attendPatientRequest.idRoomSector = this.listPatientResponse.idRoomSector;
    attendPatientRequest.idRoomService = this.listPatientResponse.idRoomService;
    attendPatientRequest.remoteAttendance = this.remoteAttendance;
    this.selectedEpisode = patient.idEpisode;
    if (this.remoteAttendance) {
      this.callPatient(patient, index);
    } else {
      this.toggleIsLoading(index);
      this.makeCallAttendPatient(patient, index, attendPatientRequest, isCheckAttendance);
    }
  }


  makeCallAttendPatient(patient: ListPatientStruct, index: number, attendPatientRequest: AttendPatientRequest, isCheckAttendance: boolean = true) {
    this.attendPatientService.attendPatient(attendPatientRequest, this.idRoom, isCheckAttendance).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.toggleIsLoading(index);
          return;
        }

        if (response.isRestrictedPermission)
          this.openRestrictedAttendmentModal(response.confirmMessage, attendPatientRequest, patient, response.tokenMedic);
        else if (response.isAlertPermission)
          this.openAlertAttendmentModal(response.confirmMessage, patient, response.isAlertPermission, response.tokenMedic);
        else if (response.isConfirm) {
          this.openAttendmentConfirmModal(response.confirmMessage, patient, index);
        }
        else {
          //redirect to register page
          if (patient.idNatureOfAttendance == NatureOfAttendanceEnum.online) {
            sessionStorage.setItem("videoToken", response.tokenMedic);
            this.openHoldModal(patient)
          }
          else
            window.location.replace(`/medic/attend-patient;idEpisode=${patient.idEpisode};idSector=${this.idSector};idRoom=${this.idRoom};idQueue=${patient.idQueue};showTissGuideButton=${this.showTissGuideButton}`);
        }

        this.toggleIsLoading(index);
      },
      error: (error) => {
        console.log(error)
        this.toggleIsLoading(index);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openHoldModal(patient: ListPatientStruct) {
    const dialogRef = this.dialog.open(HoldModalComponent, {
      panelClass: 'medic-hold-modal',
      data: {
        time: false,
        idEpisode: patient.idEpisode,
        idQueue: patient.idQueue,
        idRoom: this.idRoom,
        idSector: this.idSector,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.confirmAttend) {
          this.router.navigate(['/medic/attend-patient-online', {
            idEpisode: patient.idEpisode,
            idSector: this.idSector,
            idRoom: this.idRoom,
            idQueue: patient.idQueue,
            showTissGuideButton: this.showTissGuideButton
          }]);
        }
      }
    });
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.paginator.firstPage();
        $this.search(0);
      }
    }, 1000);
  }

  openConfirmModal(request: CallPanelEpisodeRequest, confirmMessage: string, index: number, patient: ListPatientStruct) {
    const dialogRef = this.dialog.open(PatientListCallConfirmModalComponent, {
      data: {
        confirmMessage: confirmMessage,
        idQueue: patient.idQueue,
        idRoom: this.idRoom,
        episodeData: request.episodeData,
        idModule: this.menuModuleEnum,
      },
      panelClass: "border-radius-box"
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.isCalled)
          this.updateCallCount(index, patient);
      }
    });
  }

  openAlertModal(request: CallPanelEpisodeRequest, confirmMessage: string, index: number, patient: ListPatientStruct) {
    const dialogRef = this.dialog.open(PatientListAlertCallConfirmModalComponent, {
      data: {
        confirmMessage: confirmMessage,
        idQueue: patient.idQueue,
        idRoom: this.idRoom,
        episodeData: request.episodeData,
        idModule: this.menuModuleEnum,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.isCalled)
          this.updateCallCount(index, patient);
      }
    });
  }

  openRestrictedCallModal(request: CallPanelEpisodeRequest, confirmMessage: string, index: number, patient: ListPatientStruct) {
    const dialogRef = this.dialog.open(PatientListLiberateCallConfirmModalComponent, {
      data: {
        confirmMessage: confirmMessage,
        idQueue: patient.idQueue,
        idRoom: this.idRoom,
        episodeData: request.episodeData,
        idModule: this.menuModuleEnum,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.isCalled)
          this.updateCallCount(index, patient);
      }
    });
  }

  openAttendmentConfirmModal(confirmMessage: string, patient: ListPatientStruct, index: number) {
    const dialogRef = this.dialog.open(PatientListAttendanceConfirmModalComponent, {
      data: {
        confirmMessage: confirmMessage,
        idQueue: patient.idQueue,
        idRoom: this.idRoom,
        idEpisode: patient.idEpisode,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.isAttend) {
          this.attendPatient(patient, index, false);
        }
      }
    });
  }

  openAlertAttendmentModal(confirmMessage: string, patient: ListPatientStruct, isAlertPermission: boolean, medicToken: string) {
    const dialogRef = this.dialog.open(PatientListAttendanceConfirmModalComponent, {
      data: {
        confirmMessage: confirmMessage,
        idQueue: patient.idQueue,
        idRoom: this.idRoom,
        idEpisode: patient.idEpisode,
        isAlertPermission: isAlertPermission,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.isAttend) {
          if (patient.idNatureOfAttendance == NatureOfAttendanceEnum.online) {
            sessionStorage.setItem("videoToken", medicToken);
            this.openHoldModal(patient)
          }
          else
            window.location.replace(`/medic/attend-patient;idEpisode=${patient.idEpisode};idSector=${this.idSector};idRoom=${this.idRoom};idQueue=${patient.idQueue};showTissGuideButton=${this.showTissGuideButton}`);
        }
      }
    });
  }

  openRestrictedAttendmentModal(confirmMessage: string, attendPatientRequest: AttendPatientRequest, patient: ListPatientStruct, medicToken: string) {
    const dialogRef = this.dialog.open(PatientListLiberateAttendanceModalComponent, {
      data: {
        confirmMessage: confirmMessage,
        attendPatientRequest: attendPatientRequest,
        idRoom: this.idRoom,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.isAttend) {
          if (patient.idNatureOfAttendance == NatureOfAttendanceEnum.online) {
            sessionStorage.setItem("videoToken", medicToken);
            this.openHoldModal(patient)
          }
          else
            window.location.replace(`/medic/attend-patient;idEpisode=${attendPatientRequest.patientQueue.idEpisode};idSector=${this.idSector};idRoom=${this.idRoom};idQueue=${attendPatientRequest.patientQueue.idQueue};showTissGuideButton=${this.showTissGuideButton}`);
        }
      }
    });
  }

  selectRoomModal() {
    const dialogRef = this.dialog.open(SelectRoomModalComponent, {
      data: {
        idModule: this.menuModuleEnum,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result && result.seeOccupiedRoom)
          this.seeOccupiedRoom = result.seeOccupiedRoom;
        if (result && result.res) {
          this.idRoom = result.res.idRoom;
          this.idSector = result.res.idSector
          this.selectedRoom = result.res;
          this.websocketRoomUtilService.clearSocket();

          if (this.socket) {
            this.socket.close();
            this.socket = undefined;
          }
          this.isLoading = true;
          this.getMedicConfigSector();
          this.startWebsocket();
          this.listPatientResponse.listPatient = [];
        }

        this.checkSigningResponsibility();
      }
    });
  }

  updateCallCount(index: number, patient: ListPatientStruct) {
    this.listPatientResponse.listPatient[index].ticketCallCount = this.listPatientResponse.listPatient[index].ticketCallCount + 1;

    if (this.remoteAttendance) {
      let attendPatientRequest: AttendPatientRequest = new AttendPatientRequest();;
      attendPatientRequest.patientQueue = patient;
      attendPatientRequest.idModule = this.menuModuleEnum;
      attendPatientRequest.idRoomSector = this.listPatientResponse.idRoomSector;
      attendPatientRequest.idRoomService = this.listPatientResponse.idRoomService;
      attendPatientRequest.remoteAttendance = this.remoteAttendance;

      this.makeCallAttendPatient(patient, index, attendPatientRequest);
    }
  }

  filterListPatient({ selectedItems, agendados }) {
    this.agendados = agendados;
    if (!selectedItems || selectedItems.length == 0)
      this.jsonListIdStatusQueue = null;
    else
      this.jsonListIdStatusQueue = JSON.stringify(selectedItems);

    this.hasReturn = selectedItems.findIndex(c => c == this.idStatusQueueRetorno.valueOf()) != -1;
    if (!this.hasReturn) {
      this.myPatientsCheck = false;
    }
    this.paginator.firstPage();
    this.search(0);
  }

  getMyPatientsCheck(event) {
    this.myPatientsCheck = event.checked;
    this.search(0);
  }

  //Websocket
  startWebsocket() {
    if (this.socket)
      this.socket.close();

    this.socket = this.websocketqueueService.websocketConnection();
    this.socket.emit('join', `idRoom-${this.idRoom}`)
      .on("updateList", (res) => this.updatePatientList(res))
      .io.on("reconnect", (res) => {
        this.socket.emit('join', `idRoom-${this.idRoom}`)
      });
    if (!this.websocketRoomUtilService.isConnect()) {
      this.websocketRoomUtilService.connectwebsocketRoom(this.idRoom, this.authService.getTokenMenu().login
        , this.authService.getTokenMenu().userName, "/medic/medic-patient-list", this.seeOccupiedRoom)
    }
  }

  private idInterval = setInterval(() => {
    if (this.socket) {
      if (this.socket.connected)
        this.socketDisconneted = false;
      else
        this.socketDisconneted = true;
    }
    else
      this.socketDisconneted = false;

    if (this.onlineOffline !== navigator.onLine)
      this.onlineOffline = navigator.onLine;
  }, 1000);

  // private keepAliveIntervalId = setInterval(() => {
  //   if (this.idRoom)
  //     this.roomAccessUtilService.postRoomAccessHistory(this.idRoom);

  // }, this.roomAccessUtilService.roomKeepAliveTime());

  ngOnDestroy() {
    if (this.socket)
      this.socket.close();

    clearInterval(this.idInterval);
    // clearInterval(this.keepAliveIntervalId);
  }

  updatePatientList(client: any) {

    this.socketDisconneted = false;
    if (client && client.action == "newpatient") {
      this.paginator.firstPage();
      this.search(0);
      return;
    }
    if (!this.listPatientResponse || !this.listPatientResponse.listPatient || this.listPatientResponse.listPatient.length == 0 || !client)
      return;

    let patient = this.listPatientResponse.listPatient.filter(x => x.idEpisode == client.idEpisode);

    let lastPatient = null;
    if (client.idLastEpisode)
      lastPatient = this.listPatientResponse.listPatient.filter(x => x.idEpisode == client.idLastEpisode);

    if (client.action == "changestatus") {
      if (patient && patient[0]) {
        patient[0].idStatusQueue = client.idStatusQueue;
        patient[0].statusQueueName = client.newStatusQeue;
        patient[0].attendantUserName = client.userName;
        patient[0].idAttendantUser = client.idUser;
      }
      if (lastPatient && lastPatient[0]) {
        lastPatient[0].idStatusQueue = client.idLastStatusQueue;
        lastPatient[0].statusQueueName = client.lastStatusQueueName;
      }
    }
    else if (client.action == "removepatient") {
      let index = this.listPatientResponse.listPatient.indexOf(patient[0]);
      if (index >= 0) {
        let deleted = this.listPatientResponse.listPatient.splice(index, 1);
        if (deleted && deleted.length >= 1) {
          this.listPatientResponse.totalTickets = (this.listPatientResponse.totalTickets - 1);
          this.listPatientResponse.fullListSize = this.listPatientResponse.totalTickets;
        }
      }
    }
  }

  getRoom() {
    this.isLoading = true;
    this.roomService.getRoom(this.idRoom).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.selectedRoom = response.roomStruct;
        this.paginator.firstPage();
        this.search(0);
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  getRoomUnAuth() {
    this.isLoading = true;
    this.getRoomService.getRoom(this.idRoom).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.selectedRoom = response.roomStruct;
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  isAttendedOrEvaded(idStatusQueue) {
    if (idStatusQueue == StatusQueueEnum.atendido || idStatusQueue == StatusQueueEnum.evadido)
      return false;
    else
      return true;
  }

  isAttended(idStatusQueue) {
    return idStatusQueue == StatusQueueEnum.atendido;
  }

  isInitiated(idEpisodeStatus) {
    return (idEpisodeStatus == EpisodeStatusEnum.Iniciado);
  }

  visualizePatient(idEpisode: number) {
    window.location.replace(`/medic/attend-patient;idEpisode=${idEpisode};readonly=true;idSector=${this.idSector};idRoom=${this.idRoom}`);
  }

  isEvaded(idStatusQueue) {
    return idStatusQueue == StatusQueueEnum.evadido;
  }

  openMotiveModal(idEpisode: number) {
    if (this.websocketRoomUtilService.getIsOccupied()) {
      this.alertService.show("Aviso", "Você está em modo de visualização!", AlertType.warning);
      return;
    }
    let reportDialog = this.dialog.open(ReturnMotiveModalComponent);
    reportDialog.afterClosed().subscribe((result: ReturnPatientRequest) => {
      if (result && result.motive) {
        this.isLoading = true;
        result.idEpisode = idEpisode;
        this.queueHistoryService.UpdateQueue(result).subscribe({
          next: (response) => {
            if (response.isError) {
              this.alertService.show('Erro', response.errorDescription, AlertType.error);
              return;
            }
            this.alertService.show('Sucesso', "Paciente retornado com sucesso para fila!", AlertType.success);
            this.search(0);
          }
        });
      }
    });
  }

  changePage(event: PageEvent) {
    this.search(event.pageIndex);
  }

  reprintReports(idMedicalCare: number) {
    this.isLoading = true;
    this.getMedicalCareReportService.getReport(idMedicalCare).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        const dialogRef = this.dialog.open(MedicalCareReprintReportModalComponent, {
          data: {
            medicalCareReportString64: response.medicalCareReportString64,
            sickNoteReportString64: response.sickNoteReportString64,
            sadtReportString64: response.sadtReportString64,
            prescriptionReportString64: response.prescriptionReportString64,
            externalPrescriptionReportString64: response.externalPrescriptionReportString64,
            isolationReportString64: response.isolationReportString64,
            isolationAcknowledgeReportString64: response.isolationAcknowledgeReportString64,
          },
        });
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openGetOtherQueuePatientModal(patient) {
    if (this.websocketRoomUtilService.getIsOccupied()) {
      this.alertService.show("Aviso", "Você está em modo de visualização!", AlertType.warning);
      return;
    }
    const dialogRef = this.dialog.open(OtherQueuePatientModalComponent, {
      data: {
        patient: patient,
        idEpisode: patient.idEpisode,
        idRoom: this.idRoom,
        idSector: this.listPatientResponse.idRoomService,
        idService: this.listPatientResponse.idRoomService,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.isAttend)
          window.location.replace(`/medic/attend-patient;idEpisode=${patient.idEpisode};idSector=${this.idSector};idRoom=${this.idRoom};showTissGuideButton=${this.showTissGuideButton}`);
      }
    });
  }

  openChoiceFavoritePrescription() {
    this.dialog.open(MedicFavoritePrescriptionSelectModalComponent, {
      data: {
        idRoom: this.idRoom,
        idSector: this.idSector,
      }
    });
  }

  removeCommas(tooltip: string[]): string {
    if (tooltip)
      return tooltip.join('\n').replace(/,/g, '');

    return '';
  }

  checkSigningResponsibility() {
    let token: UserLoginResponse = this.utilsService.getToken();
    let signatureMap: Object = this.signatureUtilService.listSignatureStruct();

    let requireSignature = token.listRole.map(x => x.idRole).includes(UserRoleEnum.requerer_assinatura_medico) && !token.isMaster;
    let signatureReady = signatureMap != null && ((signatureMap[SignatureProviderEnum.safeID] != null && signatureMap[SignatureProviderEnum.safeID].identifierCA != null) ||
      (signatureMap[SignatureProviderEnum.vidaaS] != null && signatureMap[SignatureProviderEnum.safeID].authorizationToken != null));

    if (requireSignature && !signatureReady) {
      const dialogRef = this.dialog.open(AlertModalComponent, {
        data: {
          isTwoButtonsModal: false,
          title: 'Atenção',
          description: 'Seu perfil foi marcado com obrigatoriedade de assinatura digital de documentos. Escolha um provedor e siga o processo para ativar sua assinatura digital no sistema.'
        },
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe({
        next: result => {
          this.menu.openSignatureManager();
        }
      });
    }
  }

  getHealthUnitConfig() {
    this.healthUnitService.getHealthUnitConfig().subscribe({
      next: (response) => {
        if (response.isError) {
          console.log(response.isError);
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
        }
        this.showTissGuideButton = response.healthUnit.canAddTissGuide;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  openModalTiss(patientStruct: ListPatientStruct) {
    this.dialog.open(TissGuideManagementModalComponent, {
      data: {
        idEpisode: patientStruct.idEpisode,
        patientName: patientStruct.patientName,
        patientSocialName: patientStruct.patientSocialName,
        idRoom: this.idRoom,
        idSector: this.idSector,
        idPatient: patientStruct.idPatient
      },
      panelClass: "tiss-guide-management-modal"
    });
  }

  selectPatient(patient: ListPatientStruct): void {
    this.selectedPatient = patient;
    this.selectedEpisode = patient.idEpisode;
  }
}