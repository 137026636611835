import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-hygienization-modal',
  templateUrl: './hygienization-modal.component.html',
  styleUrls: ['./hygienization-modal.component.css']
})
export class HygienizationModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<HygienizationModalComponent>,
  ) {
    this.matDialogRef.disableClose = true;
  }

  public isLoading: boolean;
  public startHygienization: boolean = false;

  ngOnInit(): void {
    this.isLoading = false;
    this.startHygienization = this.data.startHygienization;
  }

  submit() {
    if (this.isLoading)
      return;

    this.isLoading = true;
    this.matDialogRef.close({
      confirm: true,
      start: this.startHygienization,
      finish: !this.startHygienization,
    });
  }

  clickCancel() {
    this.matDialogRef.close({
      confirm: false,
      start: false,
      finish: false,
    });
  }
}