<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="title-header">
            <h1>Novo plano</h1>
        </div>
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">

            <div class="row padding-box">
                <div class="col-12 border-box">
                    <span class="sub-title">Convênio: </span>
                    <span>{{nameHealthcareAgreement}}</span>
                </div>
            </div>

            <div class="row padding-field">
                <div class="col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome do plano</mat-label>
                        <input matInput type="text" formControlName="planName">
                        <mat-error *ngIf="model.get('planName').invalid">
                            Informe o nome do plano
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-6">
                            <a mat-flat-button type="button" (click)="clickCancel()" color="accent" class="btn-block">
                                Cancelar
                            </a>
                        </div>
                        <div class="col-6">
                            <button mat-flat-button color="primary" class="btn-block">
                                <span *ngIf="isLoading == false">Salvar</span>
                                <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                    *ngIf="isLoading == true"></mat-spinner>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>