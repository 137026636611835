<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="button-close">
            <div class="close-button">
                <mat-icon class="close-icon" aria-hidden="false" aria-label="sound"
                    (click)="closeModal()">close</mat-icon>
            </div>
        </div>
        <div class="title-module-group">
            <mat-icon class="module-group-icon" aria-hidden="false" aria-label="Menu grupo">{{iconClass}}</mat-icon>
            <h2 class="module-group-name">{{this.nameModuleGroup}}</h2>
        </div>
        <div class="list-module">
            <ul>
                <ngContainer *ngFor="let item of filteredListModuleStruct" [ngClass]="{'disabled-module': !item.isModulePermitted}">
                    <li *ngIf="item.isModulePermitted || this.isMaster">
                        <a (click)="redirect(item)">
                            <span>{{item.nameModule}}</span>
                            <mat-icon class="chevron" aria-hidden="false" aria-label="Menu apps">chevron_right</mat-icon>
                            
                        </a>
                    </li>
                </ngContainer>
            </ul>
        </div>
    </div>
</div> 