<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <h1>Configuração de Preenchimento</h1>
        <div class="white-body">
            <a mat-flat-button color="primary" class=" add-user" routerLink="/medic/aih-fill-config/register"><mat-icon
                    aria-hidden="false" aria-label="Plus">add</mat-icon>Adicionar nova configuração</a>
            <div class="card-list" *ngIf="listAihConfig != null">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listAihConfig">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary">{{item.aihConfigName}}</span>
                                <span class="secundary">{{item.sectorName}}</span>
                            </div>
                            <div class="actions">
                                <a><mat-icon aria-hidden="false" aria-label="Editar"
                                        [routerLink]="['/medic/aih-fill-config/register', {idAihConfig: item.idAihConfig}]">edit</mat-icon>
                                </a>
                                <a><mat-icon aria-hidden="false" aria-label="Editar"
                                        (click)="openModal(item.idAihConfig)">delete_forever</mat-icon> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <app-loading-list *ngIf="isLoading"></app-loading-list>
            <app-empty-list
                *ngIf="!isLoading && listAihConfig != undefined && listAihConfig.length == 0"></app-empty-list>
        </div>
    </div>
</div>