<div mat-dialog-content>
    <div class="mat-dialog-header">
        <h3>Bloqueio de horários</h3>
    </div>
    <form class="form" [formGroup]="model" (ngSubmit)="submit()">
        <div class="mat-dialog-content">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Dia da semana</mat-label>
                        <mat-select (selectionChange)="setDayName()" formControlName="idDay">
                            <mat-option *ngFor="let item of listDay" [value]="item.idDay">
                                {{item.dayName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Início</mat-label>
                        <input type="text" [mask]="masks.timeUnit.mask" placeholder="00:00" aria-label="Number" matInput
                            formControlName="startTime">
                        <mat-error *ngIf="model.controls['startTime'].errors?.invalidRange">Intervalo
                            inválido
                        </mat-error>
                        <mat-error *ngIf="model.controls['startTime'].errors?.invalidTime">Formato
                            inválido</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-12 col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Fim</mat-label>
                        <input type="text" [mask]="masks.time" placeholder="00:00" aria-label="Number" matInput
                            formControlName="endTime">
                        <mat-error *ngIf="model.controls['endTime'].errors?.invalidRange">Intervalo
                            inválido
                        </mat-error>
                        <mat-error *ngIf="model.controls['endTime'].errors?.invalidTime">Formato
                            inválido</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Motivo</mat-label>
                        <textarea matInput formControlName="motive" rows="4">
                        </textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-6 col-sm-6 col-md-2">
                    <mat-slide-toggle formControlName="repetition">
                        {{model.get('repetition').value ? 'Repetir' : 'Não repetir'}}
                    </mat-slide-toggle>
                </div>
                <div class="col-12 col-sm-12 col-md-4" *ngIf="model.get('repetition').value">
                    <mat-form-field appearance="outline">
                        <mat-label>Frequência</mat-label>
                        <mat-select (selectionChange)="setFrequencyName()" formControlName="idFrequency">
                            <mat-option *ngFor="let item of listFrequency" [value]="item.idFrequency">
                                {{item.frequencyName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-12 col-md-4" *ngIf="model.get('repetition').value">
                    <mat-form-field appearance="outline">
                        <mat-label>Número de repetições</mat-label>
                        <input type="number" aria-label="Number" matInput formControlName="repetitionNumber">
                    </mat-form-field>
                </div>
            </div>
            <div class="footer-panel-modal-list">
                <div class="row">
                    <div *ngIf="!this.data.readOnly" class="col-6">
                        <a mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Cancelar</a>
                    </div>
                    <div *ngIf="!this.data.readOnly" class="col-6">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span>Salvar</span>
                        </button>
                    </div>
                    <div class="col-12">
                        <a *ngIf="this.data.readOnly" mat-flat-button color="primary" class="btn-block"
                            (click)="clickCancel()">Ok</a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>