import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { PatientAvoidanceLogRequest } from '../../requests/srv-log/patient-avoidance-log.request';

@Injectable({
  providedIn: 'root'
})
export class PatientAvoidanceLogService extends CommonService {

  constructor(private httpClient: HttpClient) { super(); }

  public post(body: PatientAvoidanceLogRequest): Observable<ReturnStruct> {

    return this.httpClient.post<ReturnStruct>(environment.urlApiSrvLog + "PatientAvoidanceLog", body, this.addHeaderToken())
  }
}