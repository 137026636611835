export class PatientAppointment {
    public idPatientAppointment: number;
    public idSchedule: number;
    public datetimeStart: Date;
    public datetimeEnd: Date;
    public datetimeInclusion: Date;
    public idUserPatient: number;
    public tokenUser: string;
    public tokenPatient: string;
    public idUser: number;
    public idHealthUnit: number;
    public idMedicalSpecialty: number;
    public idHealthPlan: number;
    public idTypeOfCare: number;
    public idEpisode: number;
    public datetimeCancellation: Date;
    public idUserCancellation: number;
    public idAppointmentStatus: number;
}