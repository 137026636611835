<div mat-dialog-content>
    <h1>Filtrar guias</h1>
    <hr>
    <form class="form" [formGroup]="model" (ngSubmit)="submit()">
        <div class="row">
            <div class="col-12 col-sm-6">
                <mat-form-field appearance="outline">
                    <mat-label>Convênio</mat-label>
                    <mat-select formControlName="idHealthcareAgreement">
                        <mat-option *ngFor="let item of listHealthcareAgreementStruct"
                            [value]="item.idHealthcareAgreement">
                            {{item.nameHealthcareAgreement}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12 col-sm-6">
                <mat-form-field appearance="outline">
                    <mat-label>Tipo de guia</mat-label>
                    <mat-select formControlName="idGuideType">
                        <mat-option *ngFor="let item of listGuideType" [value]="item.idGuideType">
                            {{item.guideName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-3">
                <mat-form-field>
                    <mat-label>Início</mat-label>
                    <input matInput [matDatepicker]="pickerOne" formControlName="beginDate">
                    <mat-datepicker-toggle matIconSuffix [for]="pickerOne"></mat-datepicker-toggle>
                    <mat-datepicker #pickerOne></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
                <mat-form-field>
                    <mat-label>Fim</mat-label>
                    <input matInput [matDatepicker]="pickerTwo" formControlName="endDate">
                    <mat-datepicker-toggle matIconSuffix [for]="pickerTwo"></mat-datepicker-toggle>
                    <mat-datepicker #pickerTwo></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>Status da guia</mat-label>
                    <input type="text" matInput value="Finalizada" [disabled]="true">
                </mat-form-field>
            </div>
        </div>
        <div class="row footer-buttons">
            <div class="col-12 col-sm-6">
                <a mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Cancelar</a>
            </div>
            <div class="col-12 col-sm-6">
                <button mat-flat-button type="submit" color="primary" class=" btn-block">
                    <span>Buscar</span>
                </button>
            </div>
        </div>
    </form>
</div>