export class MedicineToPrescriptionStruct {
    public idMedicine: number;
    public idMedicalPrescription: number;
    public idMedicalPrescriptionCheck: number;
    public quantity: number;
    public idMeasurementUnit: number;
    public idPrescriptionStatus: number;
    
    public medicineName: string;
    public measurementUnitName: string;

    //Dados da liberação da farmácia
    public storageExpirationDate: Date;
    public batch: string 
    public liberatedQuantity: number;
    public dispensationPresentationTypeName: string;
}