import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { SendReportRequest } from '../../requests/business-intelligence/send-report.request';
import { GetSendReportResponse } from '../../responses/business-intelligence/get-send-report.response';

@Injectable({
  providedIn: 'root'
})
export class SendReportService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  getSendReport(): Observable<GetSendReportResponse> {
    let uri = `SendReport`

    return this.httpClient.get<GetSendReportResponse>(environment.urlApiBusinessIntelligence + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public postSendReport(sendReportRequest: SendReportRequest): Observable<ReturnStruct>{
    let uri = `SendReport`;  
    
    return this.httpClient.post<ReturnStruct>(environment.urlApiBusinessIntelligence + uri, sendReportRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
  public putSendReport(sendReportRequest: SendReportRequest): Observable<ReturnStruct>{
    let uri = `SendReport`;  
    
    return this.httpClient.put<ReturnStruct>(environment.urlApiBusinessIntelligence + uri, sendReportRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}