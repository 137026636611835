import { BedStruct } from "../../structs/bed/bed.struct";

export class BedResumeReportRequest {
     public listUnhygienizedBed: BedStruct[];

     public listHygienizingBed: BedStruct[];

     public listHygienizedBed: BedStruct[];

     public listBedInUse: BedStruct[];

     public healthUnitName: string;
}