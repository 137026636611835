import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CommonService } from "src/app/shared/services/common-service";
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';
import { catchError } from "rxjs/operators";
import { ReportResponse } from "../../responses/orchestrator-patient/report.response";
import { SaveMultiProfessionalCareReportResponse } from "../../responses/multi-professional/save-multi-professional-care-report.response";

  @Injectable({
    providedIn: 'root'
  })
  export class MultiProfessionalCareService extends CommonService{
  
    constructor(private router: Router, private httpClient: HttpClient) {
      super();
    }

    public downloadReports(idEpisode: number, reportName?: string): Observable<SaveMultiProfessionalCareReportResponse> {
      let uri = `MultiProfessionalCare/downloadReports/idEpisode/${idEpisode}?`;

      if (reportName)
        uri = uri + `reportName=${reportName}&`;
  
      return this.httpClient.get<SaveMultiProfessionalCareReportResponse>(environment.urlApiMultiProfessional + uri, this.addHeaderToken())
        .pipe(
          catchError(this.handleError)
        )
    }
  }