<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <a class="back-link" routerLink="/bi"><mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon>
            Módulo Relatórios</a>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <div class="title-header">
                <h1>Envio de Relatórios</h1>
            </div>
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <app-create-rule [formGroup]="this.model" [formArrayName]="'expressions'"
                    [arrayVariables]="arrayVariables" [listUser]="listUser" [listFrequency]="listFrequency"
                    [listDaysMonth]="listDaysMonth" [listDaysWeek]="listDaysWeek" [listReport]="listReport">
                </app-create-rule>
                <div class="row">
                    <div class="col-md-2">
                        <button mat-flat-button type="submit" color="primary" class="btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <a mat-flat-button type="button" routerLink="/bi" color="accent" class=" btn-block">
                            Cancelar
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>