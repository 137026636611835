import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { HealthcareAgreementService } from 'src/app/shared/services/API/private-billing/healthcare-agreement.service';
import { LookupService } from 'src/app/shared/services/API/private-billing/lookup.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Plan } from 'src/app/shared/services/models/private-billing/plan.model';
import { Procedure } from 'src/app/shared/services/models/private-billing/procedure.model';
import { TypePeriod } from 'src/app/shared/services/models/private-billing/type-period.model';
import { HealthcareAgreementStruct } from 'src/app/shared/services/structs/private-billing/healthcare-agreement.struct';
import { PriceProcedureHealthcareStruct } from 'src/app/shared/services/structs/private-billing/price-procedure-healthcare.struct';
import { ReturnRestrictionsStruct } from 'src/app/shared/services/structs/private-billing/return-restrictions.struct';
import { PriceProcedureHealthcareComponent } from './price-procedure-healthcare/price-procedure-healthcare.component';
import { PriceProcedurePlanStruct } from 'src/app/shared/services/structs/private-billing/price-procedure-plan.struct';
import { TissBilling } from 'src/app/shared/services/models/private-billing/tiss-billing.model';
import { PriceProcedurePlanComponent } from './price-procedure-plan/price-procedure-plan.component';
import { ProfessionalCouncil } from 'src/app/shared/services/models/private-billing/professional-council.model';
import { State } from 'src/app/shared/services/models/private-billing/state.model';
import { Cbo } from 'src/app/shared/services/models/private-billing/cbo.model';
import { ProfessionalAgreementStruct } from 'src/app/shared/services/structs/private-billing/professional-agreement.struct';
import { ProviderIdentityType } from 'src/app/shared/services/models/private-billing/provider-identity-type.model';
import { LookupAdmissionRequiredField } from 'src/app/shared/services/models/private-billing/lookup-admission-required-field.model';
import { LookupAppointmentRequiredField } from 'src/app/shared/services/models/private-billing/lookup-appointment-required-field.model';
import { LookupFeesRequiredField } from 'src/app/shared/services/models/private-billing/lookup-fees-required-field.model';
import { LookupSpSadtRequiredField } from 'src/app/shared/services/models/private-billing/lookup-sp-sadt-required-field.model';
import { FeesRequiredField } from 'src/app/shared/services/models/private-billing/fees-required-field.model';
import { AdmissionRequiredField } from 'src/app/shared/services/models/private-billing/admission-required-field.model';
import { AppointmentRequiredField } from 'src/app/shared/services/models/private-billing/appointment-required-field.model';
import { SpSadtRequiredField } from 'src/app/shared/services/models/private-billing/sp-sadt-required-field.model';
import { FormControl } from '@angular/forms';
import { ParticipationDegree } from 'src/app/shared/services/models/private-billing/participation-degree.model';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-healthcare-agreements-and-plans-register',
  templateUrl: './healthcare-agreements-and-plans-register.component.html',
  styleUrls: ['./healthcare-agreements-and-plans-register.component.css']
})
export class HealthcareAgreementsAndPlansRegisterComponent implements OnInit {
  constructor(
    private lookupService: LookupService,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private healthcareAgreementService: HealthcareAgreementService,
    private router: Router,
    private dialog: MatDialog
  ) { }

  selected = new FormControl(0);
  reset = 0;

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.billing;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.healthcare_agreements_and_plans;
  @ViewChild(PriceProcedureHealthcareComponent) priceProcedureHealthcareComponent: PriceProcedureHealthcareComponent;
  @ViewChild(PriceProcedurePlanComponent) priceProcedurePlanComponent: PriceProcedurePlanComponent;

  public listProcedure: Procedure[] = [];
  public listTypePeriod: TypePeriod[] = [];
  public listParticipationDegree: ParticipationDegree[] = [];
  public listProfessionalCouncil: ProfessionalCouncil[] = [];
  public listProviderIdentityType: ProviderIdentityType[] = [];
  public listCbo: Cbo[] = [];
  public listState: State[] = [];
  public listPlan: Plan[] = [];
  public listLookupAdmissionRequiredField: LookupAdmissionRequiredField[] = [];
  public listLookupAppointmentRequiredField: LookupAppointmentRequiredField[] = [];
  public listLookupFeesRequiredField: LookupFeesRequiredField[] = [];
  public listLookupSpSadtRequiredField: LookupSpSadtRequiredField[] = [];
  public listReturnRestrictionsStruct: ReturnRestrictionsStruct[] = [];
  public listProfessionalAgreementStruct: ProfessionalAgreementStruct[] = [];
  public listPriceProcedureHealthcareStruct: PriceProcedureHealthcareStruct[] = [];
  public listPriceProcedurePlanStruct: PriceProcedurePlanStruct[] = [];
  public listFeesRequiredField: FeesRequiredField[] = [];
  public listAdmissionRequiredField: AdmissionRequiredField[] = [];
  public listAppointmentRequiredField: AppointmentRequiredField[] = [];
  public listSpSadtRequiredField: SpSadtRequiredField[] = [];
  public healthcareAgreementStruct: HealthcareAgreementStruct;
  public listPriceProcedureHealthcareFullListSize: number;
  public listPriceProcedurePlanFullListSize: number;
  public listProcedureFullListSize: number;
  public listPlanFullListSize: number;
  public listReturnRestrictionsFullListSize: number;
  public nameHealthcareAgreement: string;
  public ansRegistrationNumber: string;
  public phone: string;
  public tissBilling: TissBilling;

  public idHealthcareAgreement: number = null;
  public isUpdate: boolean = false;
  public isLoading: boolean = true;
  public healthcareAgreementFieldsFilled: boolean = false;
  public selectedTabIndex: number = 0;

  ngOnInit(): void {
    this.selected.valueChanges.subscribe({
      next: (data) => {
        this.reset = data;
      }
    });

    this.getLookup();
  }

  getLookup() {
    this.lookupService.listAllLookups().subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.listTypePeriod = response.listTypePeriod;
        this.listParticipationDegree = response.listParticipationDegree;
        this.listProfessionalCouncil = response.listProfessionalCouncil;
        this.listProviderIdentityType = response.listProviderIdentityType;
        this.listState = response.listState;
        this.listCbo = response.listCbo;

        this.listLookupAppointmentRequiredField = response.listLookupAppointmentRequiredField;
        this.listLookupFeesRequiredField = response.listLookupFeesRequiredField;
        this.listLookupSpSadtRequiredField = response.listLookupSpSadtRequiredField;
        this.listLookupAdmissionRequiredField = response.listLookupAdmissionRequiredField;

        if (this.activatedRoute.snapshot.paramMap.get('idHealthcareAgreement')) {
          this.idHealthcareAgreement = parseInt(this.activatedRoute.snapshot.paramMap.get('idHealthcareAgreement'));
          this.getAgreementById(this.idHealthcareAgreement);
        }
        else
          this.isLoading = false;
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  getAgreementById(idHealthcareAgreement: number) {
    this.healthcareAgreementService.GetById(idHealthcareAgreement, null).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.healthcareAgreementStruct = response.healthcareAgreementStruct;

        this.listPlan = response.listPlan;
        this.listPlanFullListSize = response.listPlanFullListSize;

        this.listReturnRestrictionsStruct = response.listReturnRestrictionsStruct;
        this.listReturnRestrictionsFullListSize = response.listReturnRestrictionsFullListSize;

        this.listProcedure = response.listProcedure;
        this.listProcedureFullListSize = response.listProcedureFullListSize;

        this.listPriceProcedureHealthcareStruct = response.listPriceProcedureHealthcareStruct;
        this.listPriceProcedureHealthcareFullListSize = response.listPriceProcedureHealthcareFullListSize;

        this.listPriceProcedurePlanStruct = response.listPriceProcedurePlanStruct;
        this.listPriceProcedurePlanFullListSize = response.listPriceProcedurePlanFullListSize;

        this.tissBilling = response.tissBilling;

        this.listProfessionalAgreementStruct = response.listProfessionalAgreementStruct;

        this.listFeesRequiredField = response.listFeesRequiredField;
        this.listAdmissionRequiredField = response.listAdmissionRequiredField;
        this.listAppointmentRequiredField = response.listAppointmentRequiredField;
        this.listSpSadtRequiredField = response.listSpSadtRequiredField;

        this.isLoading = false;
        this.isUpdate = true;
        this.onHealthcareAgreementFilled({
          isValid: true,
          nameHealthcareAgreement: this.healthcareAgreementStruct.nameHealthcareAgreement,
          idHealthcareAgreement: this.healthcareAgreementStruct.idHealthcareAgreement,
          ansRegistrationNumber: this.healthcareAgreementStruct.ansRegistrationNumber,
          phone: this.healthcareAgreementStruct.phone,
          isUpdate: true
        });
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  onHealthcareAgreementFilled(eventData: { isValid: boolean, nameHealthcareAgreement: string, idHealthcareAgreement: number, phone: string, ansRegistrationNumber: string, isUpdate: boolean }) {
    this.healthcareAgreementFieldsFilled = eventData.isValid;
    this.nameHealthcareAgreement = eventData.nameHealthcareAgreement;
    this.idHealthcareAgreement = eventData.idHealthcareAgreement;
    this.phone = eventData.phone;
    this.ansRegistrationNumber = eventData.ansRegistrationNumber;
    this.isUpdate = eventData.isUpdate;

    if (this.isUpdate && !this.activatedRoute.snapshot.paramMap.get('idHealthcareAgreement') && Number.isSafeInteger(this.idHealthcareAgreement)) {
      let newUrl: string = `/billing/healthcare-agreements-and-plans/register;idHealthcareAgreement=${this.idHealthcareAgreement}`;
      this.router.navigateByUrl(newUrl, { skipLocationChange: false });
      this.getAgreementById(this.idHealthcareAgreement);
    }
  }

  onPricePlanDataChanged(updatedPlanData: any) {
    this.listPriceProcedurePlanStruct = updatedPlanData;
  }

  onPlanDataChanged(updatedPlanData: any) {
    this.listPlan = updatedPlanData;
    this.ngOnInit();
  }

  onProfessionalDataChanged(updatedProfessionalData: any) {
    this.listProfessionalAgreementStruct = updatedProfessionalData;
  }

  onCancelarClick() {
    let reportDialog = this.dialog.open(ConfirmModalComponent, {
      data: {
        title: "Aviso!",
        message: "Você realmente deseja cancelar? Qualquer alteração realizada pode não ter sido salva.",
        btnPrimary: "Sim, cancelar",
        btnAccent: "Não, continuar nesta aba"
      },
    });
    reportDialog.afterClosed().subscribe(result => {
      if (result && result.clickPrimary) {
        //Do something
        this.selected.setValue(0);
      } else if (result && result.clickAccent) {
        //Do something else
      }
    });
  }

  onMoreSettingsCancelClicked() {
    this.selectTab(0);
  }

  selectTab(index: number) {
    this.selectedTabIndex = index;
  }

  private throwException(error: string) {
    console.log(error);
    this.alertService.show('Erro inesperado', error, AlertType.error);
    this.isLoading = false;
  }
}