<app-display-standart-template *ngIf="selectedTemplate === layoutPrincipal.valueOf()" [logoString64]="this.logoString64"
    [lastHourWaitingTimeAverage]="lastHourWaitingTimeAverage" [showAverageWaitingTime]="showAverageWaitingTime"
    [panelTime]="panelTime" (keepAlive)="keepPanelAlive()" (getServerDate)="getServerDate()">
</app-display-standart-template>
<app-display-layout-left-template *ngIf="selectedTemplate === layoutEsquerda.valueOf()"
    [logoString64]="this.logoString64" [lastHourWaitingTimeAverage]="lastHourWaitingTimeAverage"
    [showAverageWaitingTime]="showAverageWaitingTime" [panelTime]="panelTime" [video64]="this.video64"
    [videoFormat]="this.videoFormat" (keepAlive)="keepPanelAlive()" (getServerDate)="getServerDate()">
</app-display-layout-left-template>
<app-display-layout-right-template *ngIf="selectedTemplate === layoutDireita.valueOf()"
    [logoString64]="this.logoString64" [lastHourWaitingTimeAverage]="lastHourWaitingTimeAverage"
    [showAverageWaitingTime]="showAverageWaitingTime" [panelTime]="panelTime" [video64]="this.video64"
    [videoFormat]="this.videoFormat" (keepAlive)="keepPanelAlive()" (getServerDate)="getServerDate()">
</app-display-layout-right-template>
<app-display-standart-template-v2 *ngIf="selectedTemplate === layoutPrincipalV2.valueOf()"
    [logoString64]="this.logoString64" [lastHourWaitingTimeAverage]="lastHourWaitingTimeAverage"
    [showAverageWaitingTime]="showAverageWaitingTime" [panelTime]="panelTime" (keepAlive)="keepPanelAlive()"
    (getServerDate)="getServerDate()">
</app-display-standart-template-v2>