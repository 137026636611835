import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { CareLineRequest } from '../../requests/sync-measurer/care-line.request';
import { GetCareLineResponse } from '../../responses/sync-measurer/get-care-line.response';
import { ListCareLineResponse } from '../../responses/sync-measurer/list-care-line.response';

@Injectable({
  providedIn: 'root'
})
export class CareLineService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  public listCareLineSelect(): Observable<ListCareLineResponse> {

    let uri = `CareLine/getAll`

    return this.httpClient.get<ListCareLineResponse>(environment.urlApiSyncMeasurer + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getCareLine(idCareLine: number): Observable<GetCareLineResponse> {

    let uri = `CareLine/idCareLine/${idCareLine}`

    return this.httpClient.get<GetCareLineResponse>(environment.urlApiSyncMeasurer + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public deleteCareLine(idCareLine: number, ip: string): Observable<GetCareLineResponse> {
    let uri = `CareLine/idCareLine/${idCareLine}/ip/${ip}`

    return this.httpClient.delete<GetCareLineResponse>(environment.urlApiSyncMeasurer + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public listCareLine(searchText: string, idCareLineTYpe: number, isActive: boolean): Observable<ListCareLineResponse> {

    let uri = `CareLine?`
    if (searchText)
      uri = uri + `searchText=${searchText}&`;
    if (idCareLineTYpe)
      uri = uri + `idCareLineTYpe=${idCareLineTYpe}&`;
    if (isActive)
      uri = uri + `isActive=${isActive}&`;

    return this.httpClient.get<ListCareLineResponse>(environment.urlApiSyncMeasurer + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public createCareLine(request: CareLineRequest): Observable<ReturnStruct> {

    let uri = `CareLine`

    return this.httpClient.post<ReturnStruct>(environment.urlApiSyncMeasurer + uri, request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public updateCareLine(request: CareLineRequest, idCareLine: number): Observable<ReturnStruct> {

    let uri = `CareLine/idCareLine/${idCareLine}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiSyncMeasurer + uri, request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}