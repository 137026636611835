import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProcedureRelationshipService } from 'src/app/shared/services/API/private-billing/procedure-relationship.service';
import { ProcedureService } from 'src/app/shared/services/API/private-billing/procedure.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Procedure } from 'src/app/shared/services/models/private-billing/procedure.model';
import { ProcedureRelationshipStruct } from 'src/app/shared/services/structs/private-billing/procedure-relationship.struct';
import { ProcedureStruct } from 'src/app/shared/services/structs/private-billing/procedure.struct';
import { ProcedureDerivateEditModalComponent } from './procedure-derivate-edit-modal/procedure-derivate-edit-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { AlertModalComponent } from 'src/app/shared/components/alert-modal/alert-modal.component';

@Component({
  selector: 'app-procedure-derivatives',
  templateUrl: './procedure-derivatives.component.html',
  styleUrls: ['./procedure-derivatives.component.css']
})
export class ProcedureDerivativesComponent implements OnInit {
  constructor(
    private alertService: AlertService,
    private procedureService: ProcedureService,
    private procedureRelationshipService: ProcedureRelationshipService,
    private router: Router,
    public dialog: MatDialog,
  ) { }  

  @Input() listProcedureRelationshipStruct: ProcedureRelationshipStruct[] = [];
  @Input() procedureStruct: ProcedureStruct;

  public listProcedure: Procedure[] = [];
  public isLoading: boolean = false;

  ngOnInit(): void {    
    this.getAllProcedures();
  }

  getAllProcedures() {
    if (this.isLoading)
      return;

    this.isLoading = true;

    this.procedureService.GetAllByHealthUnit().subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.listProcedure = response.listProcedure && response.listProcedure.length > 0 ? response.listProcedure : [];
        this.isLoading = false;
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  delete(procedureRelationshipStruct: ProcedureRelationshipStruct){
    if (this.isLoading)
      return;

    this.isLoading = true;

    this.procedureRelationshipService.delete(procedureRelationshipStruct.idProcedureRelationship).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Item deletado.", AlertType.success);
        
        let index: number = this.listProcedureRelationshipStruct.findIndex(item => item.idProcedureRelationship == procedureRelationshipStruct.idProcedureRelationship);
        if (Number.isSafeInteger(index) && index >= 0) 
          this.listProcedureRelationshipStruct.splice(index, 1);

        this.isLoading = false;
         

      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      }
    });
  }

  backToProcedureList() {
    if (this.isLoading)
      return;

    this.router.navigate(['/billing/procedure']);
  }

  openModalDelete(procedureRelationshipStruct: ProcedureRelationshipStruct){
    const dialogRef = this.dialog.open(AlertModalComponent, {
      data: {
        isTwoButtonsModal: true,
        title: 'Atenção',
        description: 'Você deseja excluir este item?',
        hasImage: true
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.confirm) {
            this.delete(procedureRelationshipStruct);
        }        
      }
    });
  }

  openModalCreateEdit(procedureRelationshipStruct: ProcedureRelationshipStruct = null){
    const dialogRef = this.dialog.open(ProcedureDerivateEditModalComponent, {
      data: {
        procedureRelationshipStruct: procedureRelationshipStruct,
        idProcedureParent: this.procedureStruct.idProcedure,
        listProcedure: this.listProcedure
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.success && result.procedureRelationshipStruct) {

          const index = procedureRelationshipStruct ? this.listProcedureRelationshipStruct.findIndex(x => x.idProcedureRelationship == procedureRelationshipStruct.idProcedureRelationship) : -1;

          if (index != -1)
            this.listProcedureRelationshipStruct[index] = result.procedureRelationshipStruct;
          else
            this.listProcedureRelationshipStruct.push(...[result.procedureRelationshipStruct]);
        }
      }
    });
  }

  private alertSave(text: string) {
    this.alertService.show('Sucesso', text, AlertType.success);
    this.isLoading = false;
  }

  private throwException(error: string) {
    this.alertService.show('Erro inesperado', error, AlertType.error);
    this.isLoading = false;
  }
}