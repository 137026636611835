import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { PriceProcedureHealthcareRequest } from '../../requests/private-billing/price-procedure-healthcare.request';
import { ListPriceProcedureHealthcareResponse } from '../../responses/private-billing/list-price-procedure-healthcare.response';
import { HistoryPriceProcedureHealthcareResponse } from '../../responses/private-billing/history-price-procedure-healthcare.response';

@Injectable({
  providedIn: 'root'
})
export class PriceProcedureHealthcareService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  public Put(body: PriceProcedureHealthcareRequest): Observable<ListPriceProcedureHealthcareResponse> {
    let uri = `PriceProcedureHealthcare/updateValue`

    return this.httpClient.put<ListPriceProcedureHealthcareResponse>(environment.urlApiPrivateBilling + uri, body, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public GetAll(idHealthcareAgreement: number, searchText: string, pageIndex: number, isActivePriceProcedureHealthcare: boolean): Observable<ListPriceProcedureHealthcareResponse> {
    let uri = `PriceProcedureHealthcare?idHealthcareAgreement=${idHealthcareAgreement}&`;

    if (searchText)
      uri = uri + `searchText=${searchText}&`

    if (pageIndex)
      uri = uri + `pageIndex=${pageIndex}&`;

    if (isActivePriceProcedureHealthcare == true || isActivePriceProcedureHealthcare == false)
      uri = uri + `isActivePriceProcedureHealthcare=${isActivePriceProcedureHealthcare}`;

    return this.httpClient.get<ListPriceProcedureHealthcareResponse>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public GetAllByIdProcedure(idProcedure: number,searchText: string, isActiveProcedureHealthcare: boolean = null): Observable<ListPriceProcedureHealthcareResponse> {
    let uri = `PriceProcedureHealthcare/getAllByIdProcedure/idProcedure/${idProcedure}?`

    if (searchText)
      uri = uri + `searchText=${searchText}&`

    if (isActiveProcedureHealthcare == true || isActiveProcedureHealthcare == false)
      uri = uri + `isActiveProcedureHealthcare=${isActiveProcedureHealthcare}&`;

    return this.httpClient.get<ListPriceProcedureHealthcareResponse>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
    .pipe(catchError(this.handleError));
}

  public getHistoryPrice(idProcedureHealthcare: number): Observable<HistoryPriceProcedureHealthcareResponse> {
    let uri = `PriceProcedureHealthcare/idProcedureHealthcare/${idProcedureHealthcare}`;

    return this.httpClient.get<HistoryPriceProcedureHealthcareResponse>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError))
  }

  public PutStatus(idProcedureHealthcare: number, isActive: boolean): Observable<ReturnStruct> {
    let uri = `PriceProcedureHealthcare/idProcedureHealthcare/${idProcedureHealthcare}/isActive/${isActive}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiPrivateBilling + uri, null, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}