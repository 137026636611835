import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { PrintService } from 'src/app/shared/services/API/totem-printer/print.service';
import { PrintRequest } from 'src/app/shared/services/requests/totem-printer/print.request';
import { GenerateEpisodeResponse } from 'src/app/shared/services/responses/orchestrator-totem/generate-episode.response';
import { TotemButtonStruct } from "src/app/shared/services/structs/totem/totem-button.struct";
import { ClientTotemService } from 'src/app/shared/services/API/totem/client-totem.service';
import { ClientTotemButtonService } from 'src/app/shared/services/API/totem/client-totem-button.service';
import { EpisodeService } from 'src/app/shared/services/API/orchestrator-totem/episode.service';
import { ClientPriorityService } from 'src/app/shared/services/API/totem/client-priority.service';
import { TotemStruct } from 'src/app/shared/services/structs/totem/totem.struct';
import { PriorityModel } from 'src/app/shared/services/models/totem/priority.model';
import { UtilsClientService } from '../../utils.service';
import { ClientHealthUnitService } from 'src/app/shared/services/API/user/client-health-unit.service';
import { HealthUnitStruct } from 'src/app/shared/services/structs/user/health-unit.struct';
import { TotemPriorityService } from 'src/app/shared/services/API/totem/totem-priority.service';
import { MatDialog } from '@angular/material/dialog';
import { SecurityAlertModalComponent } from '../../../shared/components/security-alert-modal/security-alert-modal.component';
import { AuthService } from 'src/app/shared/services/auth.service';
import { TotemLoginRequest } from 'src/app/shared/services/requests/totem/totem-login.request';
import { TotemHealthUnitService } from 'src/app/shared/services/API/user/totem-health-unit.service';

@Component({
  selector: 'app-totem',
  templateUrl: './totem.component.html',
  styleUrls: ['./totem.component.css']
})
export class TotemComponent implements OnInit, OnDestroy {

  authenticated: boolean = false;
  finished: boolean = false;
  public isLoading: boolean = false;
  public timeZoneValue: number;
  state: string = 'options';

  totemName: string;
  pin: string;
  public totemStruct: TotemStruct;
  public idTotem: number;
  public idHealthUnit: number;
  public listTotemButton: TotemButtonStruct[];
  public listPriority: PriorityModel[];
  public listTotemPriority: PriorityModel[];
  public generateEpisodeResponse: GenerateEpisodeResponse;
  public printRequest: PrintRequest;
  onlineOffline: boolean = navigator.onLine;
  public healthUnitStruct: HealthUnitStruct;

  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    private clientTotemService: ClientTotemService,
    private clientPriorityService: ClientPriorityService,
    private clientTotemButtonService: ClientTotemButtonService,
    private episodeService: EpisodeService,
    private activatedRoute: ActivatedRoute,
    private printService: PrintService,
    private utilService: UtilsClientService,
    private cdref: ChangeDetectorRef,
    private totemPriorityService: TotemPriorityService,
    private totemHealthUnitService: TotemHealthUnitService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.idTotem = parseInt(this.activatedRoute.snapshot.paramMap.get('id'));
    this.listPriority = [];
    this.listTotemButton = [];
    this.listTotemPriority = [];
  }

  private intervalId = setInterval(() => {
    if (this.onlineOffline !== navigator.onLine)
      this.onlineOffline = navigator.onLine;

  }, 1000);

  ngOnDestroy() {
    clearInterval(this.intervalId);
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  checkPin(event) {
    this.pin = event;
    this.isLoading = true;

    let totemLoginRequest: TotemLoginRequest = new TotemLoginRequest();
    totemLoginRequest.idTotem = this.idTotem;
    totemLoginRequest.pin = this.pin;
    totemLoginRequest.ip = "192.168.0.1";

    this.authService.signInTotem(totemLoginRequest).subscribe({
      next: (response) => {
        if (response.isError) {

          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          this.utilService.removeTotemPin();
          if (response.errorCode === 2) {
            this.openSecurityAlertModal();
          }
          return;
        }

        this.totemStruct = response.totemStruct;
        this.authService.updateTotemToken(response);
        this.alertService.show('Sucesso', 'PIN autenticado com sucesso', AlertType.success);
        this.isLoading = false;
        this.getTotemPriority();
        this.getHealthUnit();
        this.getAllTotemButton();
        this.getAllPriority();

        this.totemName = response.totemStruct.totemName;
        this.authenticated = true;
        this.idHealthUnit = response.totemStruct.idHealthUnit;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.utilService.removeTotemPin();
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  finish(event) {
    this.isLoading = true;
    this.episodeService.createEpisode(this.pin, this.idTotem, this.totemStruct.idHealthUnit, event).subscribe({
      next: (response) => {
        if (response.isError) {
          if (response.errorCode === 3) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            this.isLoading = false;
            this.authenticated = false;
            this.utilService.removeTotemPin();
            return;
          }
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.generateEpisodeResponse = response;

        this.printRequest = new PrintRequest();
        this.printRequest.patientName = this.generateEpisodeResponse.patientName;
        this.printRequest.idEpisode = this.generateEpisodeResponse.idEpisode;
        this.printRequest.healthUnitName = this.generateEpisodeResponse.healthUnitName;
        this.printRequest.ticketInitials = this.generateEpisodeResponse.ticketInitials;
        this.printRequest.ticketSequence = this.generateEpisodeResponse.ticketSequence;
        this.printRequest.ticketGenerationDate = this.generateEpisodeResponse.ticketGenerationDate;
        this.printRequest.totemButtonName = this.generateEpisodeResponse.totemButtonName;
        this.printRequest.printerName = this.generateEpisodeResponse.printerName;
        this.printRequest.qrCodeImage = this.generateEpisodeResponse.qrCodeImage;

        this.printService.printTicket(this.printRequest).subscribe({
          next: (response) => {
            if (response.isError)
              this.alertService.show('Erro', response.errorDescription, AlertType.error);
          },
          error: (error) => {
            console.log(error)
            this.alertService.show('Programa da impressora não encontrado', error, AlertType.error);
          }
        });

        this.isLoading = false;
        this.finished = true;

        setTimeout(() => {
          this.finished = false;
          this.state = 'options';
        }, 7000);
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getAllTotemButton() {
    this.clientTotemButtonService.listButton(this.pin, this.idTotem).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listTotemButton = response.listTotemButton;
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getAllPriority() {
    this.clientPriorityService.listPriority(this.pin, this.idTotem).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listPriority = response.listPriority;
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getTotemPriority() {
    this.totemPriorityService.listTotemPriority(this.idTotem).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listTotemPriority = response.listTotemPriority;
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getHealthUnit() {
    this.totemHealthUnitService.getHealthUnit(this.totemStruct.idHealthUnit).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.healthUnitStruct = response.healthUnitStruct;
        this.timeZoneValue = this.healthUnitStruct.timeZoneValue;
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openSecurityAlertModal() {
    const dialogRef = this.dialog.open(SecurityAlertModalComponent, {
      data: {
        equipment: 'totem'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }
}