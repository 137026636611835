<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum'></app-menu>

    <div class="container-body">
        <div class="white-body">
            <h1>Gerenciamento Prescrições Padronizadas Favoritas</h1>
            <hr />
            <a mat-flat-button color="primary" class="add-user"
                [routerLink]="['/medic/favorite-standard-medicine', {idSector: this.idSector, idRoom: this.idRoom}]">
                Cadastrar nova prescrição
                <mat-icon aria-hidden="false" aria-label="Plus">add
                </mat-icon>
            </a>
            <div class="card-list" *ngIf="listMedicFavoriteStandardPrescriptionStruct != null">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4"
                        *ngFor="let item of listMedicFavoriteStandardPrescriptionStruct">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary">{{item.idMedicFavoriteStandardPrescription}} -
                                    {{item.favoritePrescriptionName}}</span>
                                <span class="secundary">Total de medicamento(s): {{item.totalNumberMedicine}}</span>
                                <span class="secundary">Total de solução(ões) medicamentosa(s): {{item.totalNumberMedicatedSolution}}</span>
                                <span class="secundary">{{item.datetimeInclusion | date: 'dd/MM/yyyy'}}</span>
                            </div>
                            <div class="actions">
                                <a>
                                    <mat-icon aria-hidden="false" aria-label="Editar"
                                        [routerLink]="['/medic/favorite-standard-medicine', {idMedicFavoriteStandardPrescription: item.idMedicFavoriteStandardPrescription, idSector: this.idSector, idRoom: this.idRoom}]">edit</mat-icon>
                                </a>
                                <a>
                                    <mat-icon aria-hidden="false" aria-label="Editar"
                                        (click)="openModal(item.idMedicFavoriteStandardPrescription)">delete_forever</mat-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <app-loading-list *ngIf="isLoading"></app-loading-list>
            <app-empty-list
                *ngIf="listMedicFavoriteStandardPrescriptionStruct !== undefined && listMedicFavoriteStandardPrescriptionStruct.length === 0 && !isLoading">
            </app-empty-list>
            <div class="spacing-top-button-back col-md-2">
                <a mat-flat-button type="button" color="accent" class="btn-block" (click)="clickBack()">
                    <span>
                        Voltar
                    </span>
                </a>
            </div>
        </div>
    </div>
</div>