import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-change-flowchart-modal',
  templateUrl: './change-flowchart-modal.component.html',
  styleUrls: ['./change-flowchart-modal.component.css']
})
export class ChangeFlowchartModalComponent {

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ChangeFlowchartModalComponent>) { }



  ngOnInit(): void {
  }

  closeYes() {
    this.matDialogRef.close({ answer: true });
  }
  closeNo() {
    this.matDialogRef.close({ answer: false });
  }
}

