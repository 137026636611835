<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-meta-data-modal-list"*ngIf="!this.listSectorStruct || this.listSectorStruct.length==0">
            <h2>Deseja excluir o metadado?</h2>
        </div>

        <div class="head-meta-data-modal-list"*ngIf="this.listSectorStruct && this.listSectorStruct.length>0">
            <h2>Metadado não pode ser excluído</h2>
        </div>

        <div class="body-meta-data-modal-list"*ngIf="!this.listSectorStruct || this.listSectorStruct.length==0">
            Você realmente deseja excluir este metadado? Essa opção é irreversível.
        </div>

        <div class="body-meta-data-modal-list" *ngIf="this.listSectorStruct && this.listSectorStruct.length>0">
            <p>Você não pode excluir esse metadado porque ele é utilizado nos seguinte setores:</p>
            <p *ngFor="let item of listSectorStruct">{{item.serviceName}} - {{item.sectorName}}</p>
        </div>

        <div class="footer-meta-data-modal-list">
            <button mat-flat-button color="primary" class="btn-block" (click)="clickDelete()" *ngIf="!this.listSectorStruct || this.listSectorStruct.length==0">
                <span *ngIf="isLoading == false">Sim, excluir</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
            <button mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Cancelar</button>
        </div>
    </div>
</div>