import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { BedHygienizationRequest } from '../../requests/bed/bed-hygienization.request';
import { HygienizationTotemRequest } from '../../requests/bed/hygienization-totem.request';
import { QuickIdentifierRequest } from '../../requests/bed/quick-identifier.request';
import { GetHygienizationTotemResponse } from '../../responses/bed/get-hygienization-totem.response';
import { ListBedCategoryResponse } from '../../responses/bed/list-bed-category-response.response';
import { ListBedHygienizationResponse } from '../../responses/bed/list-bed-hygienization.response';
import { ListHygienizationTotemResponse } from '../../responses/bed/list-hygienization-totem.response';

@Injectable({
  providedIn: 'root'
})
export class HygienizationTotemService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getHygienizationTotem(idHygienizationTotem: number): Observable<GetHygienizationTotemResponse>{

    let uri = `HygienizationTotem/idHygienizationTotem/${idHygienizationTotem}`

    return this.httpClient.get<GetHygienizationTotemResponse>(environment.urlApiBed + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public getAllHygienizationTotem(searchText: string, status: number): Observable<ListHygienizationTotemResponse>{

    let uri = `HygienizationTotem?`;

    if (searchText)
      uri += `searchText=${searchText}`;

    if (status)
      uri += `&status=${status}`;

    return this.httpClient.get<ListHygienizationTotemResponse>(environment.urlApiBed + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public createHygienizationTotem(body: HygienizationTotemRequest): Observable<ReturnStruct>{

    let uri = `HygienizationTotem`;

    return this.httpClient.post<ReturnStruct>(environment.urlApiBed + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public updateHygienizationTotem(body: HygienizationTotemRequest): Observable<ReturnStruct>{

    let uri = `HygienizationTotem`;

    return this.httpClient.put<ReturnStruct>(environment.urlApiBed + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public deleteHygienizationTotem(idHygienizationTotem: number): Observable<ReturnStruct>{

    let uri = `HygienizationTotem/idHygienizationTotem/${idHygienizationTotem}`;

    return this.httpClient.delete<ReturnStruct>(environment.urlApiBed + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public validateIdentifier(body: QuickIdentifierRequest): Observable<ReturnStruct>{

    let uri = `HygienizationTotem/validate`

    return this.httpClient.post<ReturnStruct>(environment.urlApiBed + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public verifyPinHygienizationTotem(pin: string, idTotem: number): Observable<GetHygienizationTotemResponse> {
    
    let uri = `HygienizationTotemUnauthorized/idTotem/${idTotem}/pin/${pin}`
    
    return this.httpClient.get<GetHygienizationTotemResponse>(environment.urlApiBed + uri)
    .pipe(
      catchError(this.handleError)
    )
  }
}
