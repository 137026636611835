import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { HealthcareAgreementService } from 'src/app/shared/services/API/private-billing/healthcare-agreement.service';
import { PlanService } from 'src/app/shared/services/API/private-billing/plan.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Plan } from 'src/app/shared/services/models/private-billing/plan.model';
import { PatientHealthcareAgreementDetailsStruct } from 'src/app/shared/services/structs/medical-record/patient-healthcare-agreement-details.struct';
import { ScheduledPatientHealthcareAgreementStruct } from 'src/app/shared/services/structs/medical-record/scheduled-patient-healthcare-agreement.struct';
import { HealthcareAgreementStruct } from 'src/app/shared/services/structs/private-billing/healthcare-agreement.struct';
import { ScheduledAgreementPlanConfirmModalComponent } from './scheduled-agreement-plan-confirm-modal/scheduled-agreement-plan-confirm-modal.component';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { DateStringNotBeforeToday, DateValidator } from 'src/app/shared/custom-validators/date.validator';
import { AlertModalComponent } from 'src/app/shared/components/alert-modal/alert-modal.component';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-select-patient-healthcare-agreement',
    templateUrl: './select-patient-healthcare-agreement.component.html',
    styleUrls: ['./select-patient-healthcare-agreement.component.css']
})
export class SelectPatientHealthcareAgreementComponent implements OnInit, OnChanges {

    constructor(
        private alertService: AlertService,
        private formBuilder: UntypedFormBuilder,
        private dialog: MatDialog,
        private healthcareAgreementService: HealthcareAgreementService,
        private maskService: MaskService,
        private planService: PlanService) { }

    @Input() listPatientHealthcareAgreementDetails: PatientHealthcareAgreementDetailsStruct[] = [];
    @Input() scheduledPatientHealthcareAgreement: ScheduledPatientHealthcareAgreementStruct;

    public isLoading: boolean = false;
    public masks: Masks;
    public model: FormGroup;
    public listPlan: Plan[] = [];
    public listHealthcareAgreement: HealthcareAgreementStruct[] = [];
    public isFormVisible: boolean = false;
    public listDeletedPatientHealthcareAgreementDetails: PatientHealthcareAgreementDetailsStruct[] = [];

    public urlListHealthcareAgreement: string = environment.urlApiPrivateBilling + 'HealthcareAgreement/getAllByHealthUnit';

    public isToOpenScheduledAgreementPlan: boolean = true;

    ngOnInit(): void {
        this.masks = this.maskService.getMasks();

        this.model = this.formBuilder.group({
            firstChildGroup: this.formBuilder.group({
                idHealthcareAgreement: ['', [Validators.required]],
              }),            
            idPlan: [{ value: '', disabled: true }, [Validators.required]],
            healthcareAgreementCardNumber: ['', [Validators.required]],
            healthcareAgreementExpirationDate: ['', [Validators.required, DateValidator(), DateStringNotBeforeToday()]],
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.scheduledPatientHealthcareAgreement && !changes.scheduledPatientHealthcareAgreement.firstChange) {
            this.checkHasScheduledAgreementPlan();
        }
    }

    getListPatientHealthcareAgreementToFrontDeskRequest(): PatientHealthcareAgreementDetailsStruct[] {
        let listToReturn: PatientHealthcareAgreementDetailsStruct[] = [];

        this.listPatientHealthcareAgreementDetails?.forEach(item => {
            if (!item.patientHealthcareAgreement.idPatientHealthcareAgreement || item.isSelectedInEpisode) {
                listToReturn.push(item);
            }
        });

        if (this.listDeletedPatientHealthcareAgreementDetails && this.listDeletedPatientHealthcareAgreementDetails.length > 0) {
            listToReturn.push(...this.listDeletedPatientHealthcareAgreementDetails);
        }

        return listToReturn;
    }

    checkHasScheduledAgreementPlan() {
        if (this.scheduledPatientHealthcareAgreement
            && this.isToOpenScheduledAgreementPlan
            && this.validateScheduledPatientHealthcareAgreement(this.scheduledPatientHealthcareAgreement)) {

            this.openScheduledAgreementPlanConfirmModal();
        }

        this.isToOpenScheduledAgreementPlan = false;
    }

    openScheduledAgreementPlanConfirmModal() {
        const dialogRef = this.dialog.open(ScheduledAgreementPlanConfirmModalComponent, {
            data: {
                scheduledPatientHealthcareAgreement: this.scheduledPatientHealthcareAgreement
            },
            disableClose: true
        });

        dialogRef.afterClosed().subscribe({
            next: async result => {
                if (result && result.confirm) {
                    this.listPatientHealthcareAgreementDetails.forEach((item) => {
                        item.isSelectedInEpisode = false;
                    });

                    let newPatientHealthcareAgreementDetails: PatientHealthcareAgreementDetailsStruct = new PatientHealthcareAgreementDetailsStruct();
                    newPatientHealthcareAgreementDetails.patientHealthcareAgreement.idHealthcareAgreement = this.scheduledPatientHealthcareAgreement.idHealthcareAgreement;
                    newPatientHealthcareAgreementDetails.patientHealthcareAgreement.idPlan = this.scheduledPatientHealthcareAgreement.idPlan;
                    newPatientHealthcareAgreementDetails.patientHealthcareAgreement.healthcareAgreementExpirationDate = this.scheduledPatientHealthcareAgreement.healthcareAgreementExpirationDate;
                    newPatientHealthcareAgreementDetails.patientHealthcareAgreement.healthcareAgreementCardNumber = this.scheduledPatientHealthcareAgreement.healthcareAgreementCardNumber;
                    newPatientHealthcareAgreementDetails.planName = this.scheduledPatientHealthcareAgreement.planName;
                    newPatientHealthcareAgreementDetails.nameHealthcareAgreement = this.scheduledPatientHealthcareAgreement.nameHealthcareAgreement;
                    newPatientHealthcareAgreementDetails.isSelectedInEpisode = true;

                    let existsItem: PatientHealthcareAgreementDetailsStruct = await this.checksIsItemInList(newPatientHealthcareAgreementDetails);

                    if (existsItem)
                        existsItem.isSelectedInEpisode = true;

                    else
                        this.listPatientHealthcareAgreementDetails.push(newPatientHealthcareAgreementDetails);


                }
                else {
                    this.scheduledPatientHealthcareAgreement = null;
                }

            }
        });
    }


    validateScheduledPatientHealthcareAgreement(data: ScheduledPatientHealthcareAgreementStruct): boolean {
        if (!data.nameHealthcareAgreement || data.nameHealthcareAgreement.trim() === '') return false;
        if (!data.planName || data.planName.trim() === '') return false;
        if (!data.idHealthcareAgreement || data.idHealthcareAgreement <= 0) return false;
        if (!data.idPlan || data.idPlan <= 0) return false;
        if (!data.healthcareAgreementCardNumber || data.healthcareAgreementCardNumber.trim() === '') return false;
        if (!data.healthcareAgreementExpirationDate || isNaN(new Date(data.healthcareAgreementExpirationDate).getTime())) return false;

        return true;
    }


    resetModel() {
        this.model.get('idPlan').setValue(null);
        this.model.get('idPlan').disable();
        this.model.reset();
    }

    setFormVisibility() {
        this.isFormVisible = !this.isFormVisible;

        if (!this.isFormVisible) {
            this.listPlan = [];
            this.resetModel();
        }
    }

    async addHealthcareAgreement() {
        if (this.model.invalid) {
            this.alertService.show('Erro', "Itens não preenchidos.", AlertType.error);
            return;
        }

        let healthcareAgreementStruct: HealthcareAgreementStruct = this.listHealthcareAgreement.find(item => item.idHealthcareAgreement == this.model.get('firstChildGroup').get('idHealthcareAgreement').value);
        let plan: Plan = this.listPlan.find(item => item.idPlan == this.model.value.idPlan);

        if (!healthcareAgreementStruct || !plan) {
            this.alertService.show('Erro', "Itens não encontrados.", AlertType.error);
            return;
        }

        let newPatientHealthcareAgreementDetails: PatientHealthcareAgreementDetailsStruct = new PatientHealthcareAgreementDetailsStruct();
        newPatientHealthcareAgreementDetails.patientHealthcareAgreement.idHealthcareAgreement = this.model.get('firstChildGroup').get('idHealthcareAgreement').value;
        newPatientHealthcareAgreementDetails.patientHealthcareAgreement.idPlan = this.model.value.idPlan;
        newPatientHealthcareAgreementDetails.patientHealthcareAgreement.healthcareAgreementExpirationDate = this.maskService.formatStringToDate(this.model.value.healthcareAgreementExpirationDate);
        newPatientHealthcareAgreementDetails.patientHealthcareAgreement.healthcareAgreementCardNumber = this.model.value.healthcareAgreementCardNumber;

        newPatientHealthcareAgreementDetails.isSelectedInEpisode = false;
        newPatientHealthcareAgreementDetails.planName = plan.planName;
        newPatientHealthcareAgreementDetails.nameHealthcareAgreement = healthcareAgreementStruct.nameHealthcareAgreement;

        let existsItem: PatientHealthcareAgreementDetailsStruct = await this.checksIsItemInList(newPatientHealthcareAgreementDetails);

        if (existsItem) {
            this.alertService.show('Erro', "Já existe um item com os mesmos dados.", AlertType.error);
            return;
        }

        this.listPatientHealthcareAgreementDetails.push(newPatientHealthcareAgreementDetails);
        this.listPlan = [];
        this.resetModel();
    }

    async checksIsItemInList(itemToVerify: PatientHealthcareAgreementDetailsStruct): Promise<PatientHealthcareAgreementDetailsStruct> {
        return new Promise((resolve) => {
            const verifyDate: string = this.formatDate(itemToVerify.patientHealthcareAgreement.healthcareAgreementExpirationDate);

            const patientHealthcareAgreementDetails: PatientHealthcareAgreementDetailsStruct = this.listPatientHealthcareAgreementDetails.find(listItem =>
                listItem.patientHealthcareAgreement.idHealthcareAgreement == itemToVerify.patientHealthcareAgreement.idHealthcareAgreement
                && listItem.patientHealthcareAgreement.idPlan == itemToVerify.patientHealthcareAgreement.idPlan
                && listItem.patientHealthcareAgreement.healthcareAgreementCardNumber == itemToVerify.patientHealthcareAgreement.healthcareAgreementCardNumber
                && this.formatDate(listItem.patientHealthcareAgreement.healthcareAgreementExpirationDate) == verifyDate
            );

            resolve(patientHealthcareAgreementDetails);

        });
    }

    formatDate(date: string | Date): string {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = ('0' + (d.getMonth() + 1)).slice(-2);
        const day = ('0' + d.getDate()).slice(-2);

        return `${year}-${month}-${day}`;
    }

    onClickCardItem(cardItem: PatientHealthcareAgreementDetailsStruct) {
        let newStatusToItem: boolean = !cardItem.isSelectedInEpisode;

        if (!this.listPatientHealthcareAgreementDetails || this.listPatientHealthcareAgreementDetails.length === 0)
            return;

        this.listPatientHealthcareAgreementDetails.forEach((item, idx) => {
            item.isSelectedInEpisode = false;
        });

        cardItem.isSelectedInEpisode = newStatusToItem;
    }

    confirmRemoveItemFromList(itemToRemove: PatientHealthcareAgreementDetailsStruct, index: number) {
        if (this.isLoading) {
            return;
        }

        const dialogRef = this.dialog.open(AlertModalComponent, {
            data: {
                isTwoButtonsModal: true,
                title: 'Atenção!',
                description: 'Você confirma a exclusão deste convênio?'
            },
        });

        dialogRef.afterClosed().subscribe({
            next: result => {
                if (result && result.confirm) {
                    this.removeItemFromList(itemToRemove, index);
                }
            }
        });
    }

    removeItemFromList(itemToRemove: PatientHealthcareAgreementDetailsStruct, index: number) {
        if (!this.listPatientHealthcareAgreementDetails || this.listPatientHealthcareAgreementDetails.length <= index)
            return;

        this.listPatientHealthcareAgreementDetails.splice(index, 1);
        let idPatientHealthcareAgreement: number = itemToRemove.patientHealthcareAgreement.idPatientHealthcareAgreement;

        if (idPatientHealthcareAgreement) {
            itemToRemove.isSelectedInEpisode = false;
            itemToRemove.patientHealthcareAgreement.isDeleted = true;
            this.listDeletedPatientHealthcareAgreementDetails.push(itemToRemove);
        }
        else {
            this.alertService.show('Sucesso', "Item deletado.", AlertType.success);
        }
    }

    getPlans(searchText: string = null, healthcareAgreementStruct: HealthcareAgreementStruct = null) {
        if (this.isLoading)
            return;

        if (healthcareAgreementStruct){
            this.listHealthcareAgreement = [healthcareAgreementStruct];
        }

        this.isLoading = true;
        let idHealthcareAgreement = this.model.get('firstChildGroup').get('idHealthcareAgreement').value ? this.model.get('firstChildGroup').get('idHealthcareAgreement').value : null;
        this.listPlan = [];
        this.model.get('idPlan').setValue(null);

        this.planService.GetAllToSelectById(idHealthcareAgreement, searchText, null).subscribe({
            next: (response) => {
                if (response.isError) {
                    this.isLoading = false;
                    this.alertService.show('Erro', response.errorDescription, AlertType.error);
                    return;
                }

                this.listPlan = response.listPlan.filter(item => !item.isDeleted);               

                if (this.listPlan?.length > 0)
                    this.model.get('idPlan').enable();
                else
                    this.model.get('idPlan').disable();

                this.isLoading = false;
            },
            error: (error) => {
                this.isLoading = false;
                this.alertService.show('Erro inesperado', error, AlertType.error);
            }
        });
    }

    private timeoutKeySearch: any = null;

    onKeySearch(event: any) {
      clearTimeout(this.timeoutKeySearch);
      var $this = this;
      this.timeoutKeySearch = setTimeout(function () {
        if (event.keyCode != 13) {
          $this.getPlans(event, null);
        }
      }, 1000);
    }
}