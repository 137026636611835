import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MedicalCareReportService } from 'src/app/shared/services/API/orchestrator-patient/medical-care-report.service';
import { ReSendEpisodReportRequest } from 'src/app/shared/services/requests/orchestrator-patient/re-send-episode-report.request';
import { MedicalCareStruct } from 'src/app/shared/services/structs/orchestrator-patient/medical-care.struct';

@Component({
  selector: 'app-document-send-modal',
  templateUrl: './document-send-modal.component.html',
  styleUrls: ['./document-send-modal.component.css']
})
export class DocumentSendModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<DocumentSendModalComponent>,
    private formBuilder: UntypedFormBuilder,
    private medicalCareReportService: MedicalCareReportService,
    private alertService: AlertService) { }

  public model: UntypedFormGroup;
  public isLoading: boolean = false;
  public listReports: any[] = [];
  public hasExternalExam: boolean = false;
  public hasInternalExam: boolean = false;
  public hasSickNote: boolean = false;
  public hasIsolation: boolean = false;
  public hasMedicalCare: boolean = false;
  public hasInternalPrescription: boolean = false;
  public hasProcedure: boolean = false;
  public hasExternalPrescription: boolean = false;
  public hasIsolationAknowledge: boolean = false;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      email: [this.data.email, Validators.required],
      cpf: [this.data.cpf, Validators.required],
      idEpisode: [this.data.idEpisode, Validators.required],
      responsbileUserName: [this.data.responsbileUserName, Validators.required],
      patientName: [this.data.patientName, Validators.required],
      allReport: [false],
      medicalCareReport: [false],
      examExternal: [false],
      examInternal: [false],
      prescriptionExternal: [false],
      prescriptionInternal: [false],
      sickNote: [false],
      isMedicalProcedureReport: [false],
      isolation: [false],
      isolationAcknowledge: [false],
    });

    this.populateReportList();
  }

  submit() {
    if(this.model.invalid){
      return;
    }

    if(!this.verifyCheckedOptions()){
      this.alertService.show('Erro', "Escolha ao menos uma opção para envio do documento", AlertType.error);
      return;
    }

    if(this.isLoading){
      return;
    }

    this.isLoading = true;
    let request = new ReSendEpisodReportRequest();

    request.cpf = this.model.get('cpf').value;
    request.idEpisode = this.model.get('idEpisode').value;
    request.email = this.model.get('email').value;
    request.userName = this.model.get('responsbileUserName').value;
    request.patientName = this.model.get('patientName').value;

    request.allReport = this.model.get('allReport').value;
    request.medicalCareReport = this.model.get('medicalCareReport').value;
    request.examExternal = this.model.get('examExternal').value;
    request.examInternal = this.model.get('examInternal').value;
    request.prescriptionExternal = this.model.get('prescriptionExternal').value;
    request.prescriptionInternal = this.model.get('prescriptionInternal').value;
    request.sickNote = this.model.get('sickNote').value;
    request.isMedicalProcedureReport = this.model.get('isMedicalProcedureReport').value;
    request.isolation = this.model.get('isolation').value;
    request.isolationAcknowledge = this.model.get('isolationAcknowledge').value;

    this.medicalCareReportService.reSendReportPDF(request).subscribe({next:(response) => {
      if (response.isError && response.errorCode != 1) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      if(response.isError && response.errorCode == 1){
        this.alertService.show('Aviso', response.errorDescription, AlertType.warning);
        this.isLoading = false;
        this.matDialogRef.close({noReport: response.errorDescription});
        return;
      }

      this.isLoading = false;
      this.matDialogRef.close({sent: true});
    },
    error:(error) => {
      console.log(error);
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    }});

    return;
  }

  clickCancel() {
    this.matDialogRef.close({sent: false});
  }

  populateReportList(){
    if(this.data.lastMedicalCare){
      if(this.data.lastMedicalCare.sadtReportExternalName) this.hasExternalExam = true;
      if(this.data.lastMedicalCare.sadtReportInternalName) this.hasInternalExam = true;
      if(this.data.lastMedicalCare.sickNoteReportName) this.hasSickNote = true;
      if(this.data.lastMedicalCare.isolationReportName) this.hasIsolation = true;
      if(this.data.lastMedicalCare.medicalCareReportName) this.hasMedicalCare = true;
      if(this.data.lastMedicalCare.prescriptionReportName) this.hasInternalPrescription = true;
      if(this.data.lastMedicalCare.medicalProcedureReportName) this.hasProcedure = true;
      if(this.data.lastMedicalCare.externalPrescriptionReportName) this.hasExternalPrescription = true;
      if(this.data.lastMedicalCare.isolationAcknowledgeReportName) this.hasIsolationAknowledge = true;
    }
  }

  verifyCheckedOptions(){
    if(!this.model.get('allReport').value
    && !this.model.get('medicalCareReport').value
    && !this.model.get('examExternal').value
    && !this.model.get('examInternal').value
    && !this.model.get('prescriptionExternal').value
    && !this.model.get('prescriptionInternal').value
    && !this.model.get('sickNote').value
    && !this.model.get('isMedicalProcedureReport').value
    && !this.model.get('isolation').value
    && !this.model.get('isolationAcknowledge').value){
      return false;
    }
    return true;
  }

  allSelected(){
    if(this.model.get('allReport').value){
      this.model.get('medicalCareReport').setValue(false);
      this.model.get('examExternal').setValue(false);
      this.model.get('examInternal').setValue(false);
      this.model.get('prescriptionExternal').setValue(false);
      this.model.get('prescriptionInternal').setValue(false);
      this.model.get('sickNote').setValue(false);
      this.model.get('isMedicalProcedureReport').setValue(false);
      this.model.get('isolation').setValue(false);
      this.model.get('isolationAcknowledge').setValue(false);
    } 
  }
}
