import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MedicFavoriteNonStandardPrescriptionStruct } from 'src/app/shared/services/structs/medic/medic-favorite-non-standard-prescription.struct';
import { FavoriteExamPrescriptionService } from 'src/app/shared/services/API/medic/favorite-exam-prescription.service';
import { DoctorFavoriteGroupExamPrescriptionStruct } from 'src/app/shared/services/structs/medic/doctor-favorite-group-exam-prescription.struc';
import { ExamStruct } from 'src/app/shared/services/structs/SADT/exam.struct';
import { SadtGroupStruct } from 'src/app/shared/services/structs/medical-record/sadt-group.struct';
import { MedicalCareSadtStruct } from 'src/app/shared/services/structs/medical-record/medical-care-sadt.struct';
import { LookupMedicalCareService } from 'src/app/shared/services/API/medical-record/lookup-medical-care.service';
import { LookupMedicalCareResponse } from 'src/app/shared/services/responses/medical-record/lookup-medical-care-response';
import { ExamTypeModel } from 'src/app/shared/services/models/SADT/exam-type.model';
import { ExamAndExamTypeService } from 'src/app/shared/services/API/SADT/exam-and-exam-type.service';

@Component({
  selector: 'app-select-favorite-exam-prescription-modal',
  templateUrl: './select-favorite-exam-prescription-modal.component.html',
  styleUrls: ['./select-favorite-exam-prescription-modal.component.css']
})
export class SelectFavoriteExamPrescriptionModalComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<SelectFavoriteExamPrescriptionModalComponent>,
    private alertService: AlertService,
    private examAndExamTypeService: ExamAndExamTypeService,
    public lookupMedicalCareService: LookupMedicalCareService,
    private favoriteExamPrescriptionService: FavoriteExamPrescriptionService,
    private formBuilder: FormBuilder
  ) { }

  public model: FormGroup;
  public listLookupExam: ExamStruct[] = [];
  public listSADT: SadtGroupStruct[] = [];
  public lookupMedicalCareResponse: LookupMedicalCareResponse;
  public listExamType: ExamTypeModel[] = [];
  public list: MedicFavoriteNonStandardPrescriptionStruct[] = [];
  public listInvalidWithExamType: ExamTypeModel[] = [];
  public listInvalidWithExam: ExamStruct[] = [];

  public listFavoriteGroupExamPrescription: DoctorFavoriteGroupExamPrescriptionStruct[] = [];
  public isLoading: boolean = true;
  public selected: boolean = false;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      idDoctorFavoriteGroupExamPrescription: ['', [Validators.required]]
    });

    let asyncCallsCounter = 0;

    const handleAsyncCallCompleted = () => {
      asyncCallsCounter++;
      if (asyncCallsCounter === 3)
        this.isLoading = false;
    };

    this.getAllFavoriteExamPrescription(handleAsyncCallCompleted);
    this.getExamAndExamType(handleAsyncCallCompleted);
    this.getLookupMedicalCare(handleAsyncCallCompleted);
  }

  getExamAndExamType(callback: () => void) {
    this.examAndExamTypeService.getAllExamAndExamType().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listLookupExam = response.listExam;
        this.listExamType = response.listExamType;
        callback();
      }
    });
  }

  getLookupMedicalCare(callback: () => void) {
    this.lookupMedicalCareService.getLookupMedicalCare().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.lookupMedicalCareResponse = response;
        callback();
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        callback();
      }
    });
  }

  getAllFavoriteExamPrescription(callback: () => void) {
    this.favoriteExamPrescriptionService.listFavoriteGroupExamPrescription().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listFavoriteGroupExamPrescription = response.listFavoriteGroupExamPrescription;

        if (!this.listFavoriteGroupExamPrescription || this.listFavoriteGroupExamPrescription.length === 0)
          this.alertService.show('Aviso', 'Não há prescrições favoritadas', AlertType.warning);

        callback();
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        callback();
      }
    });
  }

  loadExamPrescription(event: any) {
    if (!event.value || event.value == 0)
      return;

    else {
      this.isLoading = true;

      this.favoriteExamPrescriptionService.getFavoriteGroupExamPrescription(event.value).subscribe({
        next: (response) => {
          if (response.isError) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            this.isLoading = false;
            return;
          }

          this.selected = true;
          this.isLoading = false;

          if (response && response.listFavoriteExamPrescriptionStruct.length > 0) {
            this.listInvalidWithExamType = this.listExamType.filter(y =>
              response.listFavoriteExamPrescriptionStruct.some(x => (y.isDeleted || !y.isActive) && y.idExamType === x.favoriteExamPrescription.idExamType)
            );

            this.listInvalidWithExam = response.listFavoriteExamPrescriptionStruct.flatMap(x => {
              const filteredIdExams = x.listIdExam.filter(idExam =>
                this.listLookupExam.some(y => (y.isDeleted || !y.isActive) && y.idExam === idExam)
              );

              return filteredIdExams.map(idExam => {
                const lookupExam = this.listLookupExam.find(e => e.idExam == idExam);
                if (lookupExam) {
                  return {
                    idExam,
                    idExamType: lookupExam.idExamType,
                    referential: lookupExam.referential,
                    examName: lookupExam.examName,
                    examTypeName: lookupExam.examTypeName,
                    description: lookupExam.description,
                    isActive: lookupExam.isActive,
                    isDeleted: lookupExam.isDeleted,
                    datetimeInclusion: lookupExam.datetimeInclusion
                  };
                }
              });
            });
          }

          if (this.listInvalidWithExamType && this.listInvalidWithExamType.length > 0)
            response.listFavoriteExamPrescriptionStruct = response.listFavoriteExamPrescriptionStruct.filter(x =>
              !this.listInvalidWithExamType.some(invalid =>
                invalid.idExamType === x.favoriteExamPrescription.idExamType
              )
            );

          if (this.listInvalidWithExam && this.listInvalidWithExam)
            response.listFavoriteExamPrescriptionStruct = response.listFavoriteExamPrescriptionStruct.map(x => {
              const filteredIdExams = x.listIdExam.filter(idExam =>
                !this.listInvalidWithExam.some(invalid =>
                  invalid.idExam === idExam
                )
              );

              return { ...x, listIdExam: filteredIdExams };
            });

          response.listFavoriteExamPrescriptionStruct.forEach((exam) => {
            let newExam: SadtGroupStruct = new SadtGroupStruct();
            let idSadts = [...exam.listIdExam];
            let medicalCareSadt: MedicalCareSadtStruct;
            if (idSadts && idSadts.length >= 1) {
              newExam.medicalCareSadt = idSadts.reduce((acc: MedicalCareSadtStruct[], cur: number) => {
                if (cur != 0) {
                  medicalCareSadt = new MedicalCareSadtStruct();
                  medicalCareSadt.idSadt = cur;
                  medicalCareSadt.sadtName = this.listLookupExam.find(c => c.idExam == cur).examName;
                  acc.push(medicalCareSadt);
                }
                return acc;
              },
                []);
            }
            newExam.idStatus = 1;
            newExam.statusName = "Pedido";
            newExam.idTypeSadt = exam.favoriteExamPrescription.idExamType;
            newExam.typeSadtName = this.listExamType.find(c => c.idExamType == newExam.idTypeSadt) ? this.listExamType.find(c => c.idExamType == newExam.idTypeSadt).examTypeName : "Outros";
            newExam.idPlace = exam.favoriteExamPrescription.idPlace;
            newExam.placeName = this.lookupMedicalCareResponse.listPlace.find(c => c.idPlace == newExam.idPlace).placeName;
            newExam.sadtDescription = exam.favoriteExamPrescription.description;
            this.listSADT.push(newExam);
          });

          this.matDialogRef.close({
            selected: this.selected,
            listSADT: this.listSADT,
            listInvalidWithExam: this.listInvalidWithExam,
            listInvalidWithExamType: this.listInvalidWithExamType
          });
        },
        error: (error) => {
          console.log(error);
          this.isLoading = false;
          this.alertService.show('Erro inesperado', error, AlertType.error);
        }
      });
    }
  }

  clickCancel() {
    this.matDialogRef.close({});
  }

  favoriteExamPrescriptionName(idDoctorFavoriteGroupExamPrescription: number): string | undefined {
    const item = this.listFavoriteGroupExamPrescription.find(item => item.idDoctorFavoriteGroupExamPrescription === idDoctorFavoriteGroupExamPrescription);
    return item ? item.doctorFavoriteGroupExamPrescriptionName : undefined;
  }
}