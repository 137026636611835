import { UntypedFormGroup } from "@angular/forms";
import { ItemSearchSelectStruct } from "./item-search-select.struct";
import { MedicineModel } from "../../models/pharmacy/medicine.model";

export class KitItemSelectStruct {
    public idField: number;
    public isSingle: boolean;
    public preSelectedItem: ItemSearchSelectStruct[] = [];
    public preSelectedMedicine: MedicineModel[] = [];
    public formGroup: UntypedFormGroup;
}