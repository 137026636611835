import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-graph-register-modal',
  templateUrl: './graph-register-modal.component.html',
  styleUrls: ['./graph-register-modal.component.css']
})
export class GraphRegisterModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<GraphRegisterModalComponent>,) { }

  ngOnInit(): void {
  }
  
  close() {
    this.matDialogRef.close({ register: false });
  }

  clickGo() {
    this.matDialogRef.close({ register: true });
  }

  clickCancel() {
    this.matDialogRef.close({ register: false });
  }
}

