import { Component, OnInit } from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { GravityEnum } from 'src/app/shared/enum/gravity.enum';
import { KpiService } from 'src/app/shared/services/API/flow/kpi.service';
import { FlowLookupService } from 'src/app/shared/services/API/flow/lookup.service';
import { SectorService } from 'src/app/shared/services/API/flow/sector.service';
import { MedicalRecordLookupService } from 'src/app/shared/services/API/medical-record/lookup.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { KpiSectorGravityModel } from 'src/app/shared/services/models/flow/kpi-sector-gravity.model';
import { KpiTimeLookupModel } from 'src/app/shared/services/models/flow/kpi-time-lookup.model';
import { KpiSectorConfigRequest } from 'src/app/shared/services/requests/flow/kpi-config.request';
import { Gravity } from 'src/app/shared/services/responses/medical-record/lookup.response';
import { KpiSpecifySectorGravityStruct } from 'src/app/shared/services/structs/flow/kpi-specify-sector-gravity.struct';
import { SectorStruct } from 'src/app/shared/services/structs/flow/sector.struct';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-kpi-config-register',
  templateUrl: './kpi-config-register.component.html',
  styleUrls: ['./kpi-config-register.component.css']
})
export class KpiConfigRegisterComponent implements OnInit {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private medicalRecordLookupService: MedicalRecordLookupService,
    private flowLookupService: FlowLookupService,
    private sectorService: SectorService,
    private kpiService: KpiService,
    private utilService: UtilService,
    public dialog: MatDialog,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.flow_management;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.flow_management_kpi_config;

  public model: UntypedFormGroup;
  public isLoading: boolean = true;
  public isFirstLoading: boolean = true;
  public masks: Masks = this.maskService.getMasks();
  public isUpdate: boolean = false;
  public isCopy: boolean;
  public idKpiConfig: number;
  public idSector: number;
  public listSector: SectorStruct[];
  public listBlockedSectors: number[] = this.utilService.readKpiSectors();
  public listGravity: Gravity[];
  public listTimes: KpiTimeLookupModel[];
  public alertTimesControl: Map<string, number[]> = new Map();

  ngOnInit(): void {
    this.getSectors();

    if (this.activatedRoute.snapshot.paramMap.get('idSector')) {
      this.idSector = parseInt(this.activatedRoute.snapshot.paramMap.get('idSector'));
      this.isUpdate = true;
    }

    this.model = this.formBuilder.group({
      idKpiConfig: [this.idKpiConfig],
      idSector: [{
        value: this.idSector,
        disabled: this.isUpdate
      }, [Validators.required]],
      isGravitySpecific: [false],
      globalGravity: ["Global"],
      globalKpiGoal: [null, Validators.required],
      globalKpiAlert: [null, Validators.required],
      listGravityKpi: this.formBuilder.array([]),
    });
  }

  setRequirements() {
    if (this.model.get('isGravitySpecific').value) {
      this.model.get('globalKpiGoal').setValidators(null);
      this.model.get('globalKpiAlert').setValidators(null);
      this.model.get('globalKpiGoal').updateValueAndValidity();
      this.model.get('globalKpiAlert').updateValueAndValidity();
      this.model.get('listGravityKpi').enable();
    }
    else {
      this.model.get('globalKpiGoal').setValidators(Validators.required);
      this.model.get('globalKpiAlert').setValidators(Validators.required);
      this.model.get('globalKpiGoal').updateValueAndValidity();
      this.model.get('globalKpiAlert').updateValueAndValidity();
      this.model.get('listGravityKpi').disable();
    }
  }

  setupConfig() {
    this.listGravity.forEach(gravity => {
      (this.model.controls['listGravityKpi'] as FormArray).push(this.createGravityGoal(gravity, null))
    });
  }

  populateConfig() {
    this.kpiService.getKpi(this.idSector).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        let kpi = response.kpi;

        this.model.get('idKpiConfig').setValue(kpi.idKpiSectorConfig);
        this.model.get('idSector').setValue(kpi.idSector);
        this.model.get('isGravitySpecific').setValue(kpi.isGravitySpecific);

        let sectorStruct = this.listSector.find(x => x.idSector == kpi.idSector);

        this.verifySector(sectorStruct);

        if (!kpi.isGravitySpecific) {
          this.model.get('globalKpiGoal').setValue(kpi.listGravity[0].idKpiGoal);
          this.model.get('globalKpiAlert').setValue(kpi.listGravity[0].idKpiAlert);
          this.setGlobalAlerts();
        }
        let index: number = 0;

        this.listGravity.forEach(gravity => {
          let kpiGravity = response.kpi.listGravity.find(x => x.idGravity == gravity.idGravity);
          (this.model.controls['listGravityKpi'] as FormArray).push(this.createGravityGoal(gravity, kpiGravity));
          this.setGravityAlerts(index);
          index += 1;
        });

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  verifySector(item: SectorStruct) {
    if (item.idModule == MenuModuleEnum.classification)
      this.model.get("isGravitySpecific").disable();
    else
      this.model.get("isGravitySpecific").enable();
  }

  copyConfig(idSector: number) {
    this.isLoading = true;
    this.kpiService.getKpi(idSector).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        let kpi = response.kpi;

        this.model.get('isGravitySpecific').setValue(kpi.isGravitySpecific);

        if (!kpi.isGravitySpecific) {
          this.model.get('globalKpiGoal').setValue(kpi.listGravity[0].idKpiGoal);
          this.model.get('globalKpiAlert').setValue(kpi.listGravity[0].idKpiAlert);
          this.setGlobalAlerts();
        }
        let index: number = 0;

        this.listGravity.forEach(gravity => {
          let kpiGravity = response.kpi.listGravity.find(x => x.idGravity == gravity.idGravity);
          (this.model.controls['listGravityKpi'] as FormArray).push(this.copyGravityGoal(gravity, kpiGravity));
          this.setGravityAlerts(index);
          index += 1;
        });

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  createGravityGoal(gravity: Gravity, kpiGravity?: KpiSectorGravityModel) {
    let gravityForm = this.formBuilder.group({
      idGravity: [gravity.idGravity],
      gravityName: [gravity.gravityName],
      rgb: [gravity.rgb],
      idKpiSectorGravity: [kpiGravity ? kpiGravity.idKpiSectorGravity : null],
      gravityKpiGoal: [kpiGravity ? kpiGravity.idKpiGoal : null],
      gravityKpiAlert: [kpiGravity ? kpiGravity.idKpiAlert : null],
    });

    return gravityForm;
  }

  copyGravityGoal(gravity: Gravity, kpiGravity?: KpiSectorGravityModel) {
    let gravityForm = this.formBuilder.group({
      idGravity: [gravity.idGravity],
      gravityName: [gravity.gravityName],
      rgb: [gravity.rgb],
      idKpiSectorGravity: [null],
      gravityKpiGoal: [kpiGravity ? kpiGravity.idKpiGoal : null],
      gravityKpiAlert: [kpiGravity ? kpiGravity.idKpiAlert : null],
    });

    return gravityForm;
  }

  submit() {
    if (this.model.invalid) {
      this.alertService.show('Atenção', 'Preencha todos os campos em vermelho', AlertType.warning);
      return;
    }

    let request = new KpiSectorConfigRequest();

    let form = this.model.getRawValue();

    request.idKpiSectorConfig = form.idKpiConfig;
    request.idSector = form.idSector;
    request.isGravitySpecific = form.isGravitySpecific;

    if (this.model.get('isGravitySpecific').value)
      request.listSpecifyGravityKpi = this.mapperListKpiRequest(form, request.idKpiSectorConfig) as KpiSpecifySectorGravityStruct[];
    else
      request.listGravityKpi = this.mapperListKpiRequest(form, request.idKpiSectorConfig) as KpiSectorGravityModel[];

    if (request.idKpiSectorConfig != null)
      this.updateConfig(request);
    else
      this.createConfig(request);

    return;
  }

  createConfig(request: KpiSectorConfigRequest) {
    this.isLoading = true;
    this.kpiService.createKpi(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.alertService.show('Sucesso!', 'Dados cadastrados com sucesso', AlertType.success);
        this.isLoading = false;
        this.router.navigate(['/flow-management/kpi-config-list']);
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  updateConfig(request: KpiSectorConfigRequest) {
    this.isLoading = true;
    this.kpiService.updateKpi(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.alertService.show('Sucesso!', 'Dados atualizados com sucesso', AlertType.success);
        this.isLoading = false;
        this.router.navigate(['/flow-management/kpi-config-list']);
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  getGravities() {
    this.medicalRecordLookupService.getLookup().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listGravity = response.listGravities.filter(x => x.idGravity != GravityEnum.obito && x.idGravity != GravityEnum.branco);

        this.listGravity.forEach(x => {
          this.alertTimesControl.set(x.gravityName, []);
        });
        this.alertTimesControl.set("Global", []);

        let copyFrom: number;

        if (this.activatedRoute.snapshot.paramMap.get('copyFrom')) {
          this.isCopy = true;
          copyFrom = parseInt(this.activatedRoute.snapshot.paramMap.get('copyFrom'));
        }

        if (this.isUpdate)
          this.populateConfig();
        else if (this.isCopy)
          this.copyConfig(copyFrom);
        else
          this.setupConfig();

        if (!this.isUpdate)
          this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  getSectors() {
    this.sectorService.listAllSector().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.getTimes();

        this.listSector = response.listSectorStruct;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  getTimes() {
    this.flowLookupService.listKpiTimes().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listTimes = response.listTimes;

        this.getGravities();
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  setGlobalAlerts() {
    let globalGoal = this.model.get('globalKpiGoal').value;
    let listBlockedTimes = this.listTimes.filter(x => x.idKpiTimeLookup >= globalGoal).map(x => x.idKpiTimeLookup);
    this.alertTimesControl.set("Global", listBlockedTimes);

    let globalAlert = this.model.get('globalKpiAlert');

    if (listBlockedTimes.includes(globalAlert.value)) {
      globalAlert.setValue(null);
      globalAlert.updateValueAndValidity();
    }
  }

  setGravityAlerts(index) {
    let gravityForm = this.model.get('listGravityKpi').get(index.toString());
    let gravityGoal = gravityForm.get('gravityKpiGoal');
    let gravityAlert = gravityForm.get('gravityKpiAlert');

    let listBlockedTimes = this.listTimes.filter(x => x.idKpiTimeLookup >= gravityGoal.value).map(x => x.idKpiTimeLookup);

    this.alertTimesControl.set(gravityForm.get('gravityName').value, listBlockedTimes);

    if (gravityAlert.value && listBlockedTimes.includes(gravityAlert.value)) {
      gravityAlert.setValue(null);
      gravityAlert.updateValueAndValidity();
    }
  }

  mapperListKpiRequest(form: any, idKpiSectorConfig: number) {
    let listGravityKpi: KpiSectorGravityModel[] = [];
    let listSpecifyGravityKpi: KpiSpecifySectorGravityStruct[] = [];

    for (let i = 0; i < form.listGravityKpi.length; i++) {
      let gravityForm = form.listGravityKpi[i];
      let gravityKpi: KpiSectorGravityModel = new KpiSectorGravityModel();

      gravityKpi.idKpiSectorConfig = idKpiSectorConfig;
      gravityKpi.idKpiSectorGravity = gravityForm.idKpiSectorGravity;
      gravityKpi.idGravity = gravityForm.idGravity;

      if (this.model.get('isGravitySpecific').value) {
        gravityKpi.idKpiGoal = gravityForm.gravityKpiGoal;
        gravityKpi.idKpiAlert = gravityForm.gravityKpiAlert;

        listSpecifyGravityKpi.push(gravityKpi);
      }
      else {
        gravityKpi.idKpiGoal = form.globalKpiGoal;
        gravityKpi.idKpiAlert = form.globalKpiAlert;

        listGravityKpi.push(gravityKpi);
      }
    }

    if (this.model.get('isGravitySpecific').value)
      return listSpecifyGravityKpi;
    else
      return listGravityKpi;
  }
}