import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ScheduleRequest } from '../../requests/schedule/schedule.request';
import { ListScheduleResponse } from '../../responses/schedule/list-schedule.response';
import { ScheduleResponse } from '../../responses/schedule/schedule.response';
import { ValidateDateRangeRequest } from '../../requests/schedule/validate-date-range.request';
import { PutScheduleResponse } from '../../responses/schedule/put-schedule.response';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  public listSchedule(): Observable<ListScheduleResponse> {
    let uri = `Schedule`;

    return this.httpClient.get<ListScheduleResponse>(environment.urlApiSchedule + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }

  public getSchedule(idSchedule): Observable<ScheduleResponse> {
    let uri = `Schedule/idSchedule/${idSchedule}`;

    return this.httpClient.get<ScheduleResponse>(environment.urlApiSchedule + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }

  public postSchedule(request: ScheduleRequest): Observable<ReturnStruct> {
    let uri = `Schedule`;

    return this.httpClient.post<ReturnStruct>(environment.urlApiSchedule + uri, request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }

  public putSchedule(request: ScheduleRequest, idSchedule: number): Observable<PutScheduleResponse> {
    let uri = `Schedule/idSchedule/${idSchedule}`;

    return this.httpClient.put<PutScheduleResponse>(environment.urlApiSchedule + uri, request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }

  public deleteSchedule(idSchedule: number): Observable<ReturnStruct> {
    let uri = `Schedule/idSchedule/${idSchedule}`;

    return this.httpClient.delete<ReturnStruct>(environment.urlApiSchedule + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }

  public validateSchedule(request: ValidateDateRangeRequest): Observable<ReturnStruct> {
    let uri = `Schedule/validateDateRange`;

    return this.httpClient.post<ReturnStruct>(environment.urlApiSchedule + uri, request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }
}