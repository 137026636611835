import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { ProcedureRelationshipRequest } from '../../requests/private-billing/procedure-relationship.request';
import { PostPutProcedureRelationshipResponse } from '../../responses/private-billing/post-put-procedure-relationship.response';


@Injectable({
  providedIn: 'root'
})
export class ProcedureRelationshipService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

    public Post(body: ProcedureRelationshipRequest): Observable<PostPutProcedureRelationshipResponse> {
      let uri = `ProcedureRelationship`;

      return this.httpClient.post<PostPutProcedureRelationshipResponse>(environment.urlApiPrivateBilling + uri, body, this.addHeaderToken())
        .pipe(catchError(this.handleError));
    }

    public Put(body: ProcedureRelationshipRequest, idProcedureRelationship: number): Observable<PostPutProcedureRelationshipResponse> {
      let uri = `ProcedureRelationship/idProcedureRelationship/${idProcedureRelationship}`

      return this.httpClient.put<PostPutProcedureRelationshipResponse>(environment.urlApiPrivateBilling + uri, body, this.addHeaderToken())
        .pipe(catchError(this.handleError));
    }

    public delete(idProcedureRelationship: number): Observable<ReturnStruct> {
        let uri = `ProcedureRelationship/idProcedureRelationship/${idProcedureRelationship}`

        return this.httpClient.delete<ReturnStruct>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
            .pipe(catchError(this.handleError));
    }
}