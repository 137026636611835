import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { ProfessionalProductionAttendmentRequest } from '../../requests/orchestrator-billing/professional-production-attendment.request';
import { PostExcelReportResponse } from '../../responses/orchestrator-billing/post-excel-report.response';

@Injectable({
  providedIn: 'root'
})
export class PostProfessionalProductionAttendanceService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  postExcelReport(body: ProfessionalProductionAttendmentRequest): Observable<PostExcelReportResponse>{
    let uri = `PostProfessionalProductionAttendance`
      
    return this.httpClient.post<PostExcelReportResponse>(environment.urlApiOrchestratorBilling + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
