import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FlowSequenceModalComponent } from 'src/app/shared/components/flow-sequence-modal/flow-sequence-modal.component';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { FlowSequenceService } from 'src/app/shared/services/API/flow/flow-sequence.service';
import { MedicalCareSadtService } from 'src/app/shared/services/API/medical-record/medical-care-sadt.service';
import { FowardService } from 'src/app/shared/services/API/orchestrator-queue/foward.service';
import { ListMedicalCareSadtService } from 'src/app/shared/services/API/orchestrator-sadt/list-medical-care-sadt.service';
import { MedicalCareSadtRequest } from 'src/app/shared/services/requests/medical-record/medical-care-sadt.request';
import { FowardRequest } from 'src/app/shared/services/requests/orchestrator-queue/foward.request';
import { FlowStruct } from 'src/app/shared/services/structs/flow/flow.struct';
import { SadtGroupStruct } from 'src/app/shared/services/structs/medical-record/sadt-group.struct';
import { ForwardPatientSadtModalComponent } from './forward-patient-sadt-modal/forward-patient-sadt-modal.component';
import { FormGroup } from '@angular/forms';
import { SadtAttendanceService } from 'src/app/shared/services/API/orchestrator-sadt/sadt-attendance.service';
import { SaveSadtAttendanceRequest } from 'src/app/shared/services/requests/orchestrator-sadt/save-sadt-attendance.request';
import { AttendanceSadtGroupStruct } from 'src/app/shared/services/structs/medical-record/attendance-sadt-group.struct';
import { BillingDataStruct } from 'src/app/shared/services/structs/orchestrator-sadt/billing-data.struct';
import { UpdateStatusQueueService } from 'src/app/shared/services/API/orchestrator-queue/update-status-queue.service';
import { AlertModalComponent } from 'src/app/shared/components/alert-modal/alert-modal.component';
import { SadtStatusEnum } from 'src/app/shared/enum/sadt-status.enum';
import { WebsocketAttendanceUtilService } from 'src/app/shared/services/websocket-util/websocket-attendance-util.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { TissGuideManagementFloatingWindowComponent } from 'src/app/shared/components/tiss-guides/tiss-guide-management-floating-window/tiss-guide-management-floating-window.component';
import { AllPrivateBillingGuideReportStruct } from 'src/app/shared/services/structs/orchestrator-patient/all-private-billing-guide-report.struct';
import { ReportModalComponent } from 'src/app/shared/components/report-modal/report-modal.component';
import { ReportViewSelectModalComponent } from 'src/app/shared/components/report-view-select-modal/report-view-select-modal.component';
import { PostSadtAttendanceResponse } from 'src/app/shared/services/responses/orchestrator-sadt/post-sadt-attendance.response';
import { AttendanceOccupiedRemoveModalComponent } from 'src/app/shared/components/attendance-occupied-remove-modal/attendance-occupied-remove-modal.component';
import { UtilService } from 'src/app/shared/services/util.service';
import { MedicalPrescriptionService } from 'src/app/shared/services/API/medical-record/medical-prescription.service';
import { PrescriptionStatusEnum } from 'src/app/shared/enum/prescription-status.enum';
import { ForwardResponse } from 'src/app/shared/services/responses/orchestrator-queue/forward.response';

@Component({
  selector: 'app-sadt-attend-patient',
  templateUrl: './sadt-attend-patient.component.html',
  styleUrls: ['./sadt-attend-patient.component.css']
})
export class SadtAttendPatientComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private dialog: MatDialog,
    public medicalCareSadtService: MedicalCareSadtService,
    public listMedicalCareSadtService: ListMedicalCareSadtService,
    public flowSequenceService: FlowSequenceService,
    public router: Router,
    public fowardService: FowardService,
    private sadtAttendanceService: SadtAttendanceService,
    public updateStatusQueueService: UpdateStatusQueueService,
    public websocketAttendanceUtilService: WebsocketAttendanceUtilService,
    private authService: AuthService,
    public utilService: UtilService,
    public medicalPrescriptionService: MedicalPrescriptionService
  ) { }

  @ViewChild(TissGuideManagementFloatingWindowComponent) childComponent: TissGuideManagementFloatingWindowComponent;

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.sadt;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.sadt_patient_list;

  public isLoading: boolean = false;
  public isFirstLoading: boolean;
  public isFlowSequence: boolean = false;
  public isAttended: boolean = false;
  private showTissGuideButton: boolean = false;
  public showTissGuideButtonValidation: boolean = false;

  public model: FormGroup;
  public listSADT: SadtGroupStruct[] = [];
  public listAttendanceSadtGroupStruct: AttendanceSadtGroupStruct[] = [];
  public listProcedureSigtap: any[] = [];
  public medicalCareSadtRequest: MedicalCareSadtRequest = new MedicalCareSadtRequest();
  public allPrivateBillingGuideReportStruct: AllPrivateBillingGuideReportStruct;

  public fileName: string;
  public alergies: string;
  public userLogin: string;
  public userName: string;
  public isAttendanceRemoval: boolean = false;

  public idEpisode: number;
  public idSector: number = null;
  public idRoom: number = null;
  public idQueue: number = null;

  private datetimeStartAttendance: Date;

  ngOnInit(): void {
    this.userLogin = this.authService.getTokenMenu().login;
    this.userName = this.authService.getTokenMenu().userName;

    this.idEpisode = parseInt(this.activatedRoute.snapshot.paramMap.get('idEpisode'));
    this.idSector = parseInt(this.activatedRoute.snapshot.paramMap.get('idSector'));
    this.idRoom = parseInt(this.activatedRoute.snapshot.paramMap.get('idRoom'));
    this.idQueue = parseInt(this.activatedRoute.snapshot.paramMap.get('idQueue'));

    if (this.activatedRoute.snapshot.paramMap.get('showTissGuideButton'))
      this.showTissGuideButton = this.activatedRoute.snapshot.paramMap.get('showTissGuideButton') == "true" ? true : false;

    this.showTissGuideButtonValidation = this.showTissGuideButton;
    if (this.idEpisode)
      this.isFirstLoading = true;

    this.populateSadtAccordionData();

    if (Number.isInteger(this.idQueue) && this.idQueue && this.idQueue > 0 && Number.isInteger(this.idEpisode) && this.idEpisode && this.idEpisode > 0)
      this.startWebsocket();
  }

  ngOnDestroy() {
    // clearInterval(this.keepAliveIntervalId);
    if (this.websocketAttendanceUtilService.isConnect())
      this.websocketAttendanceUtilService.emitFinalizeAttendance(this.idRoom);

    if (this.isAttended == false && !this.isAttendanceRemoval)
      this.updateStatusQueue(this.idQueue, this.idEpisode);
  }

  //Websocket
  startWebsocket() {
    if (!this.websocketAttendanceUtilService.isConnect())
      this.websocketAttendanceUtilService.connectwebsocketRoom(this.idRoom, this.idQueue, this.idEpisode, this, false, false, this.authService.getToken(), this.userLogin, this.userName);

    this.websocketAttendanceUtilService.setContext(this);
    this.websocketAttendanceUtilService.setFunctions(null, null, this.onRemoveAttendant);
  }

  cancelSadtAttendance() {
    if (this.isLoading)
      return;

    let guideWasUpdate = '';
    if (this.showTissGuideButtonValidation) {
      let guideRequest = this.childComponent.getGuidRequest();
      if ((guideRequest.admissionGuideStruct ||
        guideRequest.appointmentGuideStruct ||
        guideRequest.feesGuideStruct ||
        guideRequest.spSadtGuideStruct)) {
        guideWasUpdate = ', inclusive alterações realizadas nas guias';
      }
    }

    const dialogRef = this.dialog.open(AlertModalComponent, {
      data: {
        isTwoButtonsModal: true,
        title: 'Aviso',
        description: `Após cancelar o atendimento todas os dados serão perdidos${guideWasUpdate}. Deseja confirmar o cancelamento?`
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.confirm) {
          this.isAttended = false;
          this.router.navigate(['/sadt/sadt-patient-list', { idSector: this.idSector, idRoom: this.idRoom }]);
        }

        this.endLoading();
      },
      error: (error) => {
        this.utilService.consoleLog(error);
      }
    });
  }

  submit() {
    if (this.isLoading)
      return;

    this.isLoading = true;
    let saveSadtAttendanceRequest: SaveSadtAttendanceRequest = new SaveSadtAttendanceRequest();
    saveSadtAttendanceRequest.idEpisode = this.idEpisode;
    saveSadtAttendanceRequest.idQueueUsed = this.idQueue;
    saveSadtAttendanceRequest.idRoomAttendance = this.idRoom;
    saveSadtAttendanceRequest.datetimeStartAttendance = this.datetimeStartAttendance;
    saveSadtAttendanceRequest.listAttendanceSadtGroupStruct = this.listAttendanceSadtGroupStruct;

    if (this.listProcedureSigtap && this.listProcedureSigtap.length > 0)
      saveSadtAttendanceRequest.billingDataStruct = this.mapToBillingDataRequest(this.listProcedureSigtap);

    if (this.showTissGuideButtonValidation) {
      let guide = this.childComponent.getGuidRequest();
      if (guide)
        saveSadtAttendanceRequest.guideRequest = guide;
    }

    this.isLoading = false;
    this.makeCallPostToSaveAttendance(saveSadtAttendanceRequest);
  }

  makeCallPostToSaveAttendance(saveSadtAttendanceRequest: SaveSadtAttendanceRequest) {
    if (this.isLoading)
      return;

    this.isLoading = true;
    this.sadtAttendanceService.SaveSadtAttendance(saveSadtAttendanceRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic("Erro", response.errorDescription, AlertType.error)
          return;
        }

        if (response.errorCode == 11)
          this.alertService.show("Erro", response.errorDescription, AlertType.error);

        if (response.allPrivateBillingGuideReportStruct) {
          this.allPrivateBillingGuideReportStruct = new AllPrivateBillingGuideReportStruct();
          this.allPrivateBillingGuideReportStruct = response.allPrivateBillingGuideReportStruct;
        }

        this.endLoading();

        if (this.hasReport(response)) {
          let reportDialog = this.dialog.open(ReportViewSelectModalComponent, {
            data: {
              appointmentGuideReport: this.allPrivateBillingGuideReportStruct?.appointmentGuideReport64,
              admissionGuideReport: this.allPrivateBillingGuideReportStruct?.admissionGuideReport64,
              feesGuideReport: this.allPrivateBillingGuideReportStruct?.feesGuideReport64,
              spSadtGuideReport: this.allPrivateBillingGuideReportStruct?.spSadtGuideReport64,
              allReportsConcat: response.allReportsConcat64,
              sign: false
            },
          });
          reportDialog.afterClosed().subscribe({
            next: () => {
              this.populateDestinationSelect();
            },
            error: (error) => {
              this.utilService.consoleLog(error);
            }
          });
        }

        else
          this.populateDestinationSelect();
      },
      error: (error) => {
        this.alertDynamic("Erro inesperado", error, AlertType.error)
        return;
      }
    });
  }

  hasReport(response: PostSadtAttendanceResponse) {
    return Boolean(
      response.allReportsConcat64 ||
      this.allPrivateBillingGuideReportStruct?.appointmentGuideReport64 ||
      this.allPrivateBillingGuideReportStruct?.admissionGuideReport64 ||
      this.allPrivateBillingGuideReportStruct?.feesGuideReport64 ||
      this.allPrivateBillingGuideReportStruct?.spSadtGuideReport64
    );
  }

  mapToBillingDataRequest(listProcedureSigtap: any[]): BillingDataStruct {
    let billingDataStruct: BillingDataStruct = new BillingDataStruct();
    billingDataStruct.idModule = MenuModuleEnum.sadt;
    billingDataStruct.idEpisode = this.idEpisode;
    billingDataStruct.listIdProcedure = listProcedureSigtap.map(a => a.idProcedureSigtap);

    return billingDataStruct;
  }

  saveSadt(event) {
    let attendanceSadtGroupStruct: AttendanceSadtGroupStruct = new AttendanceSadtGroupStruct();
    attendanceSadtGroupStruct.datetimeStartAttendance = this.datetimeStartAttendance;
    attendanceSadtGroupStruct.observation = event.medicalCareSadtRequest.observation;
    attendanceSadtGroupStruct.sadtResult = event.medicalCareSadtRequest.sadtResult;
    attendanceSadtGroupStruct.idSadtGroup = event.medicalCareSadtRequest.idSadtGroup;
    attendanceSadtGroupStruct.fileLinkedLater = event.medicalCareSadtRequest.fileLinkedLater;
    attendanceSadtGroupStruct.listSadtFile = event.medicalCareSadtRequest.listSadtFile;

    this.listAttendanceSadtGroupStruct.push(attendanceSadtGroupStruct);

    if (event.listProcedureSigtap && event.listProcedureSigtap.length > 0 && event.codCbo && event.codCbo.trim() != "")
      this.listProcedureSigtap.push(event.listProcedureSigtap);
  }

  populateSadtAccordionData() {
    this.isLoading = true;
    this.listMedicalCareSadtService.listSadt(this.idEpisode).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic("Erro", response.errorDescription, AlertType.error)
          return;
        }

        this.listSADT = response.listMedicalCareSadt;
        this.datetimeStartAttendance = response.datetimeStartAttendance;
        this.listSADT.forEach(element => {
          element.isLoadingSadt = false;
          element.isSaved = false;
          if (element.idStatus == SadtStatusEnum.Pedido)
            element.fileLinkedLater = true;
        });

        this.endLoading();
        this.isFirstLoading = false;
      },
      error: (error) => {
        this.alertDynamic("Erro inesperado", error, AlertType.error)
        return;
      }
    });
  }

  populateDestinationSelect() {
    if (this.isLoading)
      return;

    this.isLoading = true;
    let fowardRequest = new FowardRequest();
    fowardRequest.idEpisode = this.idEpisode;
    fowardRequest.idRoom = this.idRoom;

    this.fowardService.listDestination(fowardRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        else if (response.flowSequence && response.flowSequence.length > 1)
          this.isFlowSequence = true;
        else if (response.isAutomaticFoward) {
          this.isAttended = true;
          this.alertDynamic('Aviso', response.automaticFoward.toString(), AlertType.warning);
          this.router.navigate(['/sadt/sadt-patient-list', { idSector: this.idSector, idRoom: this.idRoom }]);
          return;
        }

        if (this.isFlowSequence)
          this.openFlowSequenceModal(this.idEpisode, response.idLastQueue, fowardRequest.idRoom, response.flowSequence);
        else
          this.getMedicineOpen(response);
      },
      error: (error) => {
        this.alertDynamic("Erro inesperado", error, AlertType.error)
        return;
      }
    });
  }

  openFlowSequenceModal(idEpisode: number, idQueue: number, idRoom: number, flowSequence: FlowStruct[]) {
    this.isLoading = true;
    const dialogRef = this.dialog.open(FlowSequenceModalComponent, {
      disableClose: true,
      data: {
        idEpisode: idEpisode,
        idQueue: idQueue,
        flowSequence: flowSequence,
        idOriginRoom: idRoom,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.fowardPatient) {
          this.isAttended = true;
          this.router.navigate(['/sadt/sadt-patient-list', { idSector: this.idSector, idRoom: this.idRoom }]);
        }

        this.endLoading();
      },
      error: (error) => {
        this.utilService.consoleLog(error);
        this.endLoading();
      }
    });
  }

  updateStatusQueue(idQueue: number, idEpisode: number) {
    this.isLoading = true;
    this.updateStatusQueueService.updateQueueToOldStatus(idQueue, idEpisode).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic("Error", response.errorDescription, AlertType.error)
          return;
        }

        this.endLoading();
      },
      error: (error) => {
        this.utilService.consoleLog(error);
        this.endLoading();
      }
    });
  }

  openReportDialog(reportName, reportPdf64) {
    if (reportPdf64 && reportPdf64.trim() !== "") {
      this.dialog.open(ReportModalComponent, {
        data: {
          reportName: reportName,
          reportPdf64: reportPdf64,
        },
      });
    }
  }

  onRemoveAttendant(thisParam, res: any) {
    thisParam.isAttendanceRemoval = false;

    if (res.userLogin != thisParam.userLogin) {
      thisParam.isAttendanceRemoval = true;

      thisParam.websocketAttendanceUtilService.clearSocket();
      let ref = thisParam.dialog.open(AttendanceOccupiedRemoveModalComponent, { data: { ...res } });
      ref.afterClosed().subscribe({
        next: () => {
          thisParam.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            thisParam.router.navigate(['/sadt/sadt-patient-list', { idSector: thisParam.idSector, idRoom: thisParam.idRoom }]));
        },
        error: (error) => {
          this.utilService.consoleLog(error);
        }
      });
    }
  }

  getMedicineOpen(forwardResponse: ForwardResponse) {
    this.isLoading = true;
    this.medicalPrescriptionService.GetListPrescriptionInternalOpenByIdEpisode(this.idEpisode).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic("Erro", response.errorDescription, AlertType.error)
          return;
        }

        let listMedicineInternalOpen = response.listPrescription
          .reduce((acc, curr) => {
            let existingItem = acc.find(item => item.idMedicine === curr.idMedicine);
            if (existingItem)
              existingItem.quantity++;
            else
              acc.push({ idMedicine: curr.idMedicine, medicineName: curr.medicineName, quantity: 1 });

            return acc;
          },
            []);

        let medicineNamesOpen = listMedicineInternalOpen.map(item => {
          return item.quantity > 1 ? `${item.medicineName} (${item.quantity})` : item.medicineName;
        }).join(', ');

        this.openModalForward(forwardResponse, medicineNamesOpen);
      },
      error: (error) => {
        this.alertDynamic("Erro inesperado", error, AlertType.error)
        return;
      }
    });
  }

  openModalForward(forwardResponse: ForwardResponse, medicineNamesOpen: string) {
    this.endLoading();

    const dialogRef = this.dialog.open(ForwardPatientSadtModalComponent, {
      disableClose: true,
      data: {
        idRoom: this.idRoom,
        idEpisode: this.idEpisode,
        idQueue: forwardResponse.idLastQueue,
        listFoward: forwardResponse.location,
        listSADT: this.listSADT,
        isFlowSequence: this.isFlowSequence,
        flowSequence: forwardResponse.flowSequence,
        medicineNamesOpen: medicineNamesOpen
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.fowardPatient) {
          this.isAttended = true;
          this.router.navigate(['/sadt/sadt-patient-list', { idSector: this.idSector, idRoom: this.idRoom }]);
        }

        this.endLoading();
      },
      error: (error) => {
        this.utilService.consoleLog(error);
        this.endLoading();
      }
    });
  }

  alertDynamic(alertTypeDescription: string, alertDescription: string, alertType: AlertType) {
    this.utilService.consoleLog(alertTypeDescription);

    this.alertService.show(alertTypeDescription, alertDescription, alertType ? alertType.valueOf() : AlertType.error);
    this.endLoading();
  }

  endLoading() {
    this.isLoading = false;
  }
}