import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { MedicalRecordService } from 'src/app/shared/services/API/orchestrator-patient/medical-record.service';
import { MedicineService } from 'src/app/shared/services/API/pharmacy/medicine.service';
import { ExamService } from 'src/app/shared/services/API/SADT/exam.service';
import { MedicalCareService } from 'src/app/shared/services/API/medical-record/medical-care.service';
import { ReportSelectModalComponent } from 'src/app/shared/components/report-select-modal/report-select-modal.component';
import { ReportModalComponent } from 'src/app/shared/components/report-modal/report-modal.component';
import { EpisodeStatusEnum } from 'src/app/shared/enum/episode-status.enum';
import { LookupMedicalCareService } from 'src/app/shared/services/API/medical-record/lookup-medical-care.service';
import { FowardRequest } from 'src/app/shared/services/requests/orchestrator-queue/foward.request';
import { FowardService } from 'src/app/shared/services/API/orchestrator-queue/foward.service';
import { MedicalCareModel } from 'src/app/shared/services/models/medical-record/medical-care.model';
import { LocationStruct } from 'src/app/shared/services/structs/orchestrator-queue/location.struct';
import { SelectedLocationRequest } from 'src/app/shared/services/requests/orchestrator-queue/selected-location.request';
import { FowardDestinationEnum } from 'src/app/shared/enum/foward-destination.enum';
import { EpisodeStatusRequest } from 'src/app/shared/services/requests/medical-record/episode-status.request';
import { EpisodeStatusService } from 'src/app/shared/services/API/medical-record/episode-status.service';
import { ForwardResponse } from 'src/app/shared/services/responses/orchestrator-queue/forward.response';
import { ObservationForwardModalComponent } from 'src/app/shared/components/observation-forward-modal/observation-forward-modal.component';
import { ObservationForwardService } from 'src/app/shared/services/API/orchestrator-queue/observation-foward.service';
import { BedQueueService } from 'src/app/shared/services/API/queue/bed-queue.service';
import { MedicConfigSectorService } from 'src/app/shared/services/API/medic/medic-config-sector.service';
import { MedicRequiredFieldEnum } from 'src/app/shared/enum/medic-required-field.enum';
import { ProcedureService } from 'src/app/shared/services/API/medic/procedure.service';
import { GetCompleteMultiProfessionalConfigStruct } from 'src/app/shared/services/structs/multi-professional/get-complete-multi-professional-config.struct';
import { VisibleFieldEnum } from 'src/app/shared/enum/multi-professional/visible-field.enum';
import { SectionFillEnum } from 'src/app/shared/enum/multi-professional/section-fill.enum';


@Component({
  selector: 'app-assistance-outcome-block',
  templateUrl: './assistance-outcome-block.component.html',
  styleUrls: ['./assistance-outcome-block.component.css']
})
export class AssistanceOutcomeBlockComponent implements OnInit {

  @Input() model: UntypedFormGroup;
  @Input() completeMultiprofessionalConfig: GetCompleteMultiProfessionalConfigStruct;
  @Input() stringValidator: string;

  constructor(private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private dialog: MatDialog,
    public procedureService: ProcedureService,
    public medicalRecordService: MedicalRecordService,
    public medicineService: MedicineService,
    public lookupMedicalCareService: LookupMedicalCareService,
    public examService: ExamService,
    public medicalCareService: MedicalCareService,
    public router: Router,
    public fowardService: FowardService,
    private episodeStatusService: EpisodeStatusService,
    public observationForwardService: ObservationForwardService,
    public bedQueueService: BedQueueService,
    public medicConfigSectorService: MedicConfigSectorService,
  ) { }


  public episodeStatusAltaEnum: EpisodeStatusEnum = EpisodeStatusEnum.FinalizadoAlta;

  public isLoading: boolean = false;
  public selectedEpisode: number;
  public newMedicalCare: MedicalCareModel;
  public wasSaved: boolean = false;
  public idSector: number = null;
  public idRoom: number = null;
  public listFoward: LocationStruct[];
  public destinationType: number;
  public destinationTypeAltaEncaminhamento: number = FowardDestinationEnum.Alta_Encaminhamento;
  public destinationTypeEncaminhamento: number = FowardDestinationEnum.Encaminhamento;
  public destinationTypeAlta: number = FowardDestinationEnum.Alta;
  public showFoward: boolean;
  public idService: number;

  public hasAlta: boolean = false;
  public hasAltaEncaminhamento: boolean = false;
  public hasEncaminhamento: boolean = false;
  public destinationTypeAltaColor: string = "accent";
  public destinationTypeEncaminhamentoColor: string = "accent";
  public destinationTypeAltaEncaminhamentoColor: string = "accent";
  public dischargeOutcome = true;


  ngOnInit(): void {
    this.hasAlta = this.completeMultiprofessionalConfig.listIdSectionFill.includes(SectionFillEnum.AssistanceOutcome);
    this.dischargeOutcome = this.completeMultiprofessionalConfig.dischargeOutcome;
    this.selectedEpisode = parseInt(this.activatedRoute.snapshot.paramMap.get('idEpisode'));

    this.idSector = parseInt(this.activatedRoute.snapshot.paramMap.get('idSector'));
    this.idRoom = parseInt(this.activatedRoute.snapshot.paramMap.get('idRoom'));
    this.changeConclusionType(null);
    this.populateFowardSelect();
  }

  populateFowardSelect() {

    let fowardRequest = new FowardRequest();
    fowardRequest.idEpisode = this.selectedEpisode;
    fowardRequest.idRoom = parseInt(this.activatedRoute.snapshot.paramMap.get('idRoom'));

    this.fowardService.listDestination(fowardRequest).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      if (response.isAutomaticFoward) {
        this.model.get('idFlow').disable();
        let automaticFoward: LocationStruct = new LocationStruct();
        automaticFoward.idFlow = -1;
        automaticFoward.locationName = 'Automático';
        this.listFoward.push(automaticFoward);
        return;
      }
      this.listFoward = response.location;
    },
      (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  changeConclusionType(event: any) {
    this.destinationType = this.model.get('conclusionType').value;
    this.stringValidator = "";
    this.showFoward = false;
    this.changeButtonColor();
    if (this.destinationType == this.destinationTypeAltaEncaminhamento) {
      this.stringValidator = " e para onde será encaminhado";
      this.model.get('conclusionNote').setValidators([Validators.required]);
      this.model.get('idFlow').setValidators([Validators.required]);
      this.showFoward = true;
      this.model.get('conclusionNote').updateValueAndValidity();
      this.model.get('idFlow').updateValueAndValidity();
    }
    else if (this.destinationType == this.destinationTypeEncaminhamento) {
      this.stringValidator = " e para onde será encaminhado";
      this.model.get('conclusionNote').setValidators([]);
      this.model.get('idFlow').setValidators([Validators.required]);
      this.showFoward = true;
      this.model.get('conclusionNote').updateValueAndValidity();
      this.model.get('idFlow').updateValueAndValidity();
    }
    else {
      this.model.get('conclusionNote').setValidators([Validators.required]);
      this.model.get('idFlow').setValidators([]);
      this.model.get('conclusionNote').updateValueAndValidity();
      this.model.get('idFlow').updateValueAndValidity();
    }

    if (!(this.completeMultiprofessionalConfig) ||
      !(this.completeMultiprofessionalConfig.listIdFieldRequired) ||
      this.completeMultiprofessionalConfig.listIdFieldRequired.findIndex(c => c == MedicRequiredFieldEnum.conclusion) == -1) {
      this.model.get('conclusionNote').setValidators([]);
      this.model.get('conclusionNote').updateValueAndValidity();
    }
  }

  populateDestinationSelect() {
    let fowardRequest = new FowardRequest();
    fowardRequest.idEpisode = this.selectedEpisode;
    fowardRequest.idRoom = this.idRoom;

    this.observationForwardService.listDestination(fowardRequest).subscribe((response) => {
      this.openModalForward(response, fowardRequest.idRoom);
    },
      (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });

  }

  openModalForward(response: ForwardResponse, idRoom: number) {
    this.isLoading = false;
    if (response.isError) {
      this.alertService.show('Erro', response.errorDescription, AlertType.error);
      return;
    } else if (response.isAutomaticFoward) {
      this.alertService.show('Aviso', response.automaticFoward, AlertType.warning);
      return;
    }
    const dialogRef = this.dialog.open(ObservationForwardModalComponent, {
      data: {
        idRoom: idRoom,
        idService: this.idService,
        actualIdSector: this.idSector,
        actualIdRoom: this.idRoom,
        idEpisode: this.selectedEpisode,
        idQueue: response.idLastQueue,
        listFoward: response.location,
        datetimeStartRegister: null,
        isMedicBedManagement: true,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.fowardPatient) {
        this.router.navigate(['/medic/medic-bed-management', { idSector: this.idSector, idRoom, idService: this.idService }]);
      }
    })
  }

  changeButtonColor() {
    this.destinationTypeAltaColor = this.destinationType == this.destinationTypeAlta ? "primary" : "accent";
    this.destinationTypeEncaminhamentoColor = this.destinationType == this.destinationTypeEncaminhamento ? "primary" : "accent";
    this.destinationTypeAltaEncaminhamentoColor = this.destinationType == this.destinationTypeAltaEncaminhamento ? "primary" : "accent";

  }
}