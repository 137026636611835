<div mat-dialog-content>
    <div class="mat-dialog-content">
         <div class="body-type-modal-list">
            <form class="form" [formGroup]="model">
                <div class="title-header">
                    <h1>Dados do Paciente</h1>
                </div>
                <div class="row">
                    <div class="col-6 col-sm-6">
                        <mat-form-field appearance="outline" class="outline-spinner">
                            <mat-label>CPF</mat-label>
                            <input matInput type="text" formControlName="cpf" [mask]="masks.cpf">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-6">
                        <mat-form-field appearance="outline" class="outline-spinner">
                            <mat-label>CNS</mat-label>
                            <input matInput type="text" formControlName="cns">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome do paciente</mat-label>
                            <input matInput type="text" formControlName="patientName">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Data de nascimento</mat-label>
                            <input matInput type="text" formControlName="birthDate" [mask]="masks.date">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-2">
                        <mat-form-field appearance="outline" class="outline-spinner">
                            <mat-label>Idade</mat-label>
                            <input matInput type="text" readonly formControlName="patientAge">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-6">
                        <mat-form-field appearance="outline" class="outline-spinner">
                            <mat-label>Gênero</mat-label>
                            <input matInput type="text" formControlName="cns">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-6">
                        <mat-form-field appearance="outline" class="outline-spinner">
                            <mat-label>Telefone</mat-label>
                            <input matInput type="text" formControlName="phone1">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-6">
                        <mat-form-field appearance="outline" class="outline-spinner">
                            <mat-label>Empresa</mat-label>
                            <input matInput type="text" formControlName="cns">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Cargo</mat-label>
                            <input matInput type="text" formControlName="roleEmployee">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Filial/Unidade</mat-label>
                            <input matInput type="text" formControlName="unitBranch">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-6">
                        <mat-form-field appearance="outline" class="outline-spinner">
                            <mat-label>Matrícula</mat-label>
                            <input matInput type="text" formControlName="registryEmployee">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-6">
                        <mat-form-field appearance="outline" class="outline-spinner">
                            <mat-label>Setor</mat-label>
                            <input matInput type="text" formControlName="sectorEmployee">
                        </mat-form-field>
                    </div>
                </div>
                <div class="title-header">
                    <h1>Endereço</h1>
                </div>
                <div class="row">
                    <div class="col-6 col-sm-3 col-md-3">
                        <mat-form-field appearance="outline" class="outline-spinner">
                            <mat-label>CEP</mat-label>
                            <input matInput type="text" formControlName="zipCode">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Logradouro</mat-label>
                            <input matInput type="text" formControlName="street">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Número</mat-label>
                            <input matInput type="number" formControlName="houseNumber">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Complemento</mat-label>
                            <input matInput type="text" formControlName="apartmentNumber">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Bairro</mat-label>
                            <input matInput type="text" formControlName="neighborhood">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Cidade</mat-label>
                            <input matInput type="text" formControlName="city">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Estado</mat-label>
                            <input matInput type="text" formControlName="state">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>País</mat-label>
                            <input matInput type="text" formControlName="country">
                        </mat-form-field>
                    </div>
                </div>
                <div class="title-header">
                    <h1>Anamnese</h1>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12"
                        *ngFor="let item of this.data.patient.listQuestionAnswer;  let i = index">
                        <label><b>{{i+1}} - {{item.question}}</b></label>
                        <div *ngFor="let answer of item.listAnswer">
                            <label *ngIf="!answer.isOthers" class="margin-answer">• {{answer.answer}}</label>
                        </div>
                         <div *ngIf="item.othersDescription">
                            <label class="margin-othersDescription">Outros: {{item.othersDescription}}</label>
                        </div>
                    </div>
                </div>
            </form>
         </div>
         <div class="footer-type-modal-list">
            <button mat-flat-button color="accent" class="btn-block btn-margin"(click)="close()">Fechar</button>
        </div>
    </div>
</div>