import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilsClassificationService, ClassificationData } from 'src/app/classification/utils.service';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { ProtocolEnum } from 'src/app/shared/enum/protocol.enum';
import { AiComplaintToFlowchartService } from 'src/app/shared/services/API/orchestrator-classification/ai-complaint-to-flowchart.service';
import { FlowchartGroupService } from 'src/app/shared/services/API/risk-classification/flowchart-group.service';
import { FlowchartService } from 'src/app/shared/services/API/risk-classification/flowchart.service';
import { LastAutoTriageService } from 'src/app/shared/services/API/risk-classification/last-auto-triage.service';
import { IaFlowchartService } from 'src/app/shared/services/API/user/ia-flowchart.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { FlowchartGroupModel } from 'src/app/shared/services/models/risk-classification/flowchart-group.model';
import { FlowchartModel } from 'src/app/shared/services/models/risk-classification/flowchart.model';
import { ComplaintToFlowchartRequest } from 'src/app/shared/services/requests/orchestrator-classification/complaint-to-flowchart.request';
import { SearchFlowchartRequest } from 'src/app/shared/services/requests/risk-classification/search-flowchart.request';

@Component({
  selector: 'app-flowchart-selection',
  templateUrl: './flowchart-selection.component.html',
  styleUrls: ['./flowchart-selection.component.css']
})
export class FlowchartSelectionComponent implements OnInit {

  constructor(private alertService: AlertService,
    private iaFlowchartService: IaFlowchartService,
    private aiComplaintToFlochartService: AiComplaintToFlowchartService,
    private utilService: UtilsClassificationService,
    private formBuilder: FormBuilder,
    private flowchartService: FlowchartService,
    private flowchartGroupService: FlowchartGroupService,
    private getLastSelfTriageService: LastAutoTriageService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.digital_prompt_service;

  public model: FormGroup;
  public isFirstLoading: boolean = true;
  public isLoadingAi: boolean = false;
  public listFlowchartGroup: FlowchartGroupModel[];
  public listFlowchart: FlowchartModel[];
  public listAiFlowchart: FlowchartModel[];
  public listFilterFlowchart: FlowchartModel[];
  public idFlowchart: number;
  public isTelephone: boolean;
  public isAIEnabled: boolean = false;
  public noAiResult: boolean = false;
  public listAIisClear: boolean = true;
  public classificationData: ClassificationData;

  @Input() idEpisode: number;
  @Input() idRoom: number;
  @Input() idSector: number;
  @Output() next = new EventEmitter<any>();

  ngOnInit(): void {
    this.classificationData = this.utilService.getClassificationData();
    this.isTelephone = this.classificationData.isTelephoneClassification;
    this.idEpisode = this.classificationData.idEpisode;

    this.getAllFlowcharts(this.classificationData);
    this.getAllFlowchartsGroup();
    this.getLastSelfTriageTwentyFourHours(this.classificationData);

    if (this.classificationData.idProtocol == ProtocolEnum.Manchester)
      this.getIfIsAIEnabled();

    this.model = this.formBuilder.group({
      complain: [this.classificationData.complain, [Validators.required]],
    });
  }

  getAllFlowcharts(data: ClassificationData) {
    let body: SearchFlowchartRequest = new SearchFlowchartRequest();
    body.idProtocol = data.idProtocol;

    if (!body.idProtocol || body.idProtocol == 0)
      body.idProtocol = ProtocolEnum.Manchester.valueOf();

    body.birthdate = data.birthDate;
    body.idGender = data.idGender;
    body.searchText = null;
    body.flowchartGroup = null;
    this.flowchartService.getFlowchart(body).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        this.isFirstLoading = false;
        this.listFlowchart = response.flowcharts;

        this.listFilterFlowchart = response.flowcharts;
      }
    });
  }

  getAllFlowchartsGroup() {
    this.flowchartGroupService.getFlowchartGroup().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listFlowchartGroup = response.flowchartsGroup;
      }
    });
  }

  onChangeSelect(event: any) {
    if (event.value.length == 0)
      this.listFilterFlowchart = this.listFlowchart;
    else
      this.listFilterFlowchart = this.listFlowchart.filter(c => event.value.some(x => c.flowchartGroup.includes(parseInt(x))))
  }

  selectFlowchart() {
    if (this.model.invalid) {
      this.alertService.show('Erro', "Preencha a queixa!", AlertType.error);
      return;
    }

    let values = this.utilService.getClassificationData();
    values.complain = this.model.get("complain").value;
    values.hasFlowchartIA = this.isAIEnabled;

    if (this.listAiFlowchart)
      values.iaResult = JSON.stringify(this.listAiFlowchart.map(l => l.idFlowchart));

    if (this.idFlowchart != 0) {
      values.idFlowchart = this.idFlowchart;
      values.flowchartName = this.listFlowchart.find(c => c.idFlowchart == this.idFlowchart).flowchartName;
      values.idFlowchartType = this.listFlowchart.find(c => c.idFlowchart == this.idFlowchart).idFlowchartType;
      this.utilService.updateClassificationData(values);
    }
    else
      this.utilService.updateClassificationData(values);

    this.forward();
  }

  onKeySearch(event: any) {
    if (!event.target.value)
      this.listFilterFlowchart = this.listFlowchart;
    else
      this.listFilterFlowchart = this.listFlowchart.filter(c => c.flowchartName.toLocaleLowerCase().search(event.target.value.toLocaleLowerCase()) >= 0)
  }

  onKeyChangeAi() {
    if (!this.isAIEnabled)
      return null;
    this.listAiFlowchart = [];
  }

  searchAI() {
    this.listAIisClear = false;
    this.getAIFlowchart(this.model.get("complain").value)
  }

  getIfIsAIEnabled() {
    this.isLoadingAi = true;
    this.iaFlowchartService.checkIfIsAIEnabled().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoadingAi = false;
          return;
        }

        this.isAIEnabled = response.hasFlowchartIA;
        this.isLoadingAi = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoadingAi = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getAIFlowchart(complaint: string) {
    this.isLoadingAi = true;
    let request: ComplaintToFlowchartRequest = {
      complaint: complaint,
      birthDate: this.classificationData.birthDate ? new Date(this.classificationData.birthDate) : null
    };

    this.aiComplaintToFlochartService.getAIFlowcharts(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoadingAi = false;
          return;
        }

        if ((response.flowcharts?.length > 0 ?? false) && response.flowcharts[0].idFlowchart == -1) {
          this.noAiResult = true;
          this.isLoadingAi = false;
          this.listAiFlowchart = [];
          return;
        }

        this.listAiFlowchart = response.flowcharts;
        this.isLoadingAi = false;
        this.noAiResult = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoadingAi = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getLastSelfTriageTwentyFourHours(data: ClassificationData) {
    this.getLastSelfTriageService.getAutoTriage(data.patientData.cpf).subscribe({
      next: (response) => {
        data.selfTriage = response;
        this.utilService.updateClassificationData(data);
      },
      error: (error) => {
        console.log(error);
      }
    });
  };

  forward() {
    this.next.emit();
  }
}