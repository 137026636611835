<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'
        [hasSecundaryHeader]="true" [episode]="selectedEpisode" [hasCachedModule]="true"
        [cachedModuleName]="'multiprofessional'"></app-menu>

    <div class="container-body-secundary-header">
        <h1>Atendimento Multiprofissional</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <app-loading-list *ngIf="isLoading" class="personalize-loading-list"></app-loading-list>

            <form class="form" [formGroup]="model">
                <app-new-attendance-block *ngIf="hasNewAttendance" [model]="this.model"
                    [completeMultiprofessionalConfig]="completeMultiprofessionalConfig"
                    [allergiesInput]="allergiesInput" [allergyError]="allergyError"
                    (changeAllergies)="changeAllergies($event)" [episodeStruct]="episodeStruct" [listCid]="listCid"
                    (changeCid)="changeCid($event)" (setPreselectTrigger)="triggerSetPreselect($event)"
                    (removePreselectTrigger)="triggerRemovePreselect($event)">
                </app-new-attendance-block>
                <app-meta-data-block *ngIf="hasMetaData" [model]="this.model"
                    [completeMultiprofessionalConfig]="completeMultiprofessionalConfig">
                </app-meta-data-block>
                <app-standart-prescriptions-block *ngIf="hasStandardPrescriptions"
                    [completeMultiprofessionalConfig]="completeMultiprofessionalConfig"
                    [listStandartPrescription]="listStandartPrescription" [allergiesToSave]="allergiesInput"
                    (changeStandartPrescription)="changeStandartPrescription($event)">
                </app-standart-prescriptions-block>
                <app-non-standard-prescriptions-block *ngIf="hasNonStandardPrescriptions"
                    [completeMultiprofessionalConfig]="completeMultiprofessionalConfig"
                    [listExternalPrescription]="listExternalPrescription"
                    (changeExternalPrescription)="changeExternalPrescription($event)">
                </app-non-standard-prescriptions-block>
                <app-exams-requests-block *ngIf="hasExamesRequests"
                    [completeMultiprofessionalConfig]="completeMultiprofessionalConfig" [listSADT]="listSADT"
                    (changeSADTGroup)="changeSADTGroup($event)">
                </app-exams-requests-block>
                <app-sick-note-block *ngIf="hasSickNote" [model]="this.model"
                    [completeMultiprofessionalConfig]="completeMultiprofessionalConfig">
                </app-sick-note-block>
                <app-procedures-performed-block #procedureSigtap *ngIf="hasProceduresPerformed" [model]="this.model"
                    [completeMultiprofessionalConfig]="completeMultiprofessionalConfig">
                </app-procedures-performed-block>
                <app-care-line-block *ngIf="hasCareLine" [listPastEpisodeCareLine]="listPastEpisodeCareLine"
                    [listCurrentEpisodeCareLine]="listCurrentEpisodeCareLine"
                    [listNewEpisodeCareLine]="listNewEpisodeCareLine" [listCareLine]="listCareLine"
                    [idEpisode]="selectedEpisode" [patientPhoneNumber]="patientPhoneNumber"
                    (populateCareLines)="populateCareLines($event)" (removeCareLines)="removeCareLines($event)">
                </app-care-line-block>
                <div *ngIf="multiProfessionalCare != null  && multiProfessionalCare.length > 0 ">
                    <div class="title-header">
                        <h1>Histórico de Atendimentos Multiprofissional</h1>
                    </div>
                    <div class="row">
                        <div class="col=12">
                            <cdk-accordion class="profile-register-accordion">
                                <app-multi-professional-care-patient-history [listExamType]="listExamType"
                                    [multiProfessionalCare]="multiProfessionalCare" [listExam]="listLookupExam"
                                    [isLoading]="isLoading">
                                </app-multi-professional-care-patient-history>
                            </cdk-accordion>
                        </div>
                    </div>
                </div>
                <app-assistance-outcome-block [model]="this.model" *ngIf="hasAssistanceOutcome"
                    [completeMultiprofessionalConfig]="completeMultiprofessionalConfig"
                    [stringValidator]="stringValidator">
                </app-assistance-outcome-block>
                <div class="row">
                    <div class="col-md-3 col-sm-3"></div>
                    <div *ngIf="this.readonly" class="col-md-3 col-sm-3"></div>
                    <div class="col-md-3 col-sm-3">
                        <a mat-flat-button type="button" (click)="openCancelConfirmModal()" color="accent"
                            class=" btn-block">
                            Cancelar
                        </a>
                    </div>
                    <div *ngIf="!this.readonly" class="col-md-3 col-sm-3">
                        <button mat-flat-button type="button" (click)="submit(true)"
                            color="{{requireSignature ? 'primary' : 'accent'}}" class="btn-block">
                            <span *ngIf="!isLoading">Salvar com assinatura e Imprimir</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                        </button>
                    </div>
                    <div *ngIf="!this.readonly && !requireSignature" type="button" class="col-md-3 col-sm-3">
                        <button mat-flat-button (click)="submit(false)"
                            color="{{requireSignature ? 'accent' : 'primary'}}" class="btn-block">
                            <span *ngIf="!isLoading">Salvar sem assinatura e Imprimir</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div *ngIf="showTissGuideButtonValidation">
    <app-tiss-guide-management-floating-window [idEpisode]="selectedEpisode"
        [isAttendance]="true"></app-tiss-guide-management-floating-window>
</div>