import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { BillingDataRequest } from '../../requests/billing/billing-data-request';
import { BillingDataResponse } from '../../responses/billing/billing-data.response';

@Injectable({
  providedIn: 'root'
})
export class ListBillingDataService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  postBillingData(body: BillingDataRequest): Observable<ReturnStruct>{
    let uri = `BillingData`
      
    return this.httpClient.post<ReturnStruct>(environment.urlApiOrchestratorBilling + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  listBillingData(beginPeriod: string, endPeriod: string, searchTextProcedureSigTap: string, searchTextCodSigTap: string): Observable<BillingDataResponse>{
    let uri = `ListBillingData/beginPeriod/${beginPeriod}/endPeriod/${endPeriod}?`

    if (searchTextProcedureSigTap)
      uri = uri + `searchTextProcedureSigTap=${searchTextProcedureSigTap}&`;
    if (searchTextCodSigTap)
      uri = uri + `searchTextCodSigTap=${searchTextCodSigTap}`;
      
    return this.httpClient.get<BillingDataResponse>(environment.urlApiOrchestratorBilling + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
