<div *ngIf="listMedicalProcedureCheckResponse">
    <ng-container *ngIf="listMedicalProcedureCheckResponse.listProcedureWaiting?.length > 0">
        <div class="title-header">
            <h1>Em andamento</h1>
        </div>
        <app-bed-management-list-procedure-checks
            [listProcedureCheck]="listMedicalProcedureCheckResponse.listProcedureWaiting"
            [idSector]="idSector" [idService]="idService" [idRoom]="idRoom">
        </app-bed-management-list-procedure-checks>
    </ng-container>
</div>