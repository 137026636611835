<div class="mat-dialog-content">
    <div mat-dialog-title>
        <div class="head-select-room-modal-list">
            <h2>Encaminhar Paciente</h2>
            <mat-icon (click)="close()" class="close-modal">close</mat-icon>
            <div class="col-12" *ngIf="medicineNamesOpen">
                <div class="circle">
                    <div class="dot">!</div>
                </div>
                <h4><strong>Atenção!</strong> O paciente possui medicamentos para serem realizados</h4>
            </div>
        </div>
    </div>
    <div mat-dialog-content>
        <div class="body-select-room-modal-list">
            <div class="filters">
                <div class="row">
                    <mat-form-field appearance="outline">
                        <mat-icon matPrefix class="my-icon">search</mat-icon>
                        <mat-label>Pesquisar</mat-label>
                        <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText"
                            (keyup)="onKeySearch()">
                    </mat-form-field>
                </div>
            </div>
            <app-loading-list *ngIf="isLoading"></app-loading-list>
            <div class="body-select-room-modal-list__options">
                <div mat-flat-button color="accent" class=" body-select-room-modal-list__options--buttons"
                    (click)="selectItem(item.idFlow)" *ngFor="let item of filteredListforward">
                    <span class="option-name truncate">{{item.locationName}} </span>
                    <mat-icon class="option-icon">navigate_next</mat-icon>
                </div>
            </div>
        </div>
    </div>
</div>