import {  Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AlertModalComponent } from 'src/app/shared/components/alert-modal/alert-modal.component';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { ReportModalComponent } from 'src/app/shared/components/report-modal/report-modal.component';
import { birthdateValidator } from 'src/app/shared/custom-validators/birthdate.validator';
import { CepValidator } from 'src/app/shared/custom-validators/cep.validator';
import { VerifyCNS } from 'src/app/shared/custom-validators/cns.validator';
import { AihFieldsMaxLengthEnum } from "src/app/shared/enum/hospital-document/aih-fields-max-length.enum";
import { LookupHospitalizationReportEnum } from 'src/app/shared/enum/hospital-document/lookup-hospitalization-report.enum';
import { AihDocumentType } from 'src/app/shared/enum/medic/aih-document-type.enum';
import { AihService } from "src/app/shared/services/API/orchestrator-patient/aih.service";
import { AlertService, AlertType } from "src/app/shared/services/alert.service";
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { AihGenderModel } from 'src/app/shared/services/models/hospital-document/aih-gender.model';
import { AihModel } from 'src/app/shared/services/models/hospital-document/aih.model';
import { ClinicType } from 'src/app/shared/services/models/hospital-document/clinic-type.model';
import { DocumentTypeModel } from 'src/app/shared/services/models/hospital-document/document-type.model';
import { LookupHospitalizationReportModel } from "src/app/shared/services/models/hospital-document/lookup_hospitalization-report.model";
import { RelationAihConfigFieldRequiredModel } from "src/app/shared/services/models/hospital-document/relation_aih_config_field_required.model";
import { RelationshipWithSocialSecurityModel } from 'src/app/shared/services/models/hospital-document/relationship-with-social-security.model';
import { PostAihRequest } from 'src/app/shared/services/requests/hospital-document/post-aih.request';
import { CidStruct } from 'src/app/shared/services/structs/medical-record/cid.struct';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-view-aih',
  templateUrl: './view-aih.component.html',
  styleUrls: ['./view-aih.component.css']
})
export class ViewAihComponent implements OnInit, OnChanges  {

  constructor(private formBuilder: FormBuilder,
    private alertService: AlertService,
    private dialog: MatDialog,
    private aihService: AihService,
    private maskService: MaskService,
  ) {
  }

  @Input() idAihGroup: number = null;
  @Input() idEpisode: number = null;
  @Input() idSector: number = null;
  @Input() listDocumentType: DocumentTypeModel[] = [];
  @Input() listClinicType: ClinicType[] = [];
  @Input() listAihGender: AihGenderModel[] = [];
  @Input() listRelationAihConfigFieldRequired: RelationAihConfigFieldRequiredModel[] = [];
  @Input() listLookupHospitalizationReport: LookupHospitalizationReportModel[] = [];
  @Input() listRelationshipWithSocialSecurity: RelationshipWithSocialSecurityModel[] = [];
  @Input() aih: AihModel;
  @Input() isEdit: boolean = false;
  @Input() isPending: boolean = false;
  @Input() idHealthUnit: number;

  @Output() back = new EventEmitter();
  @Output() reload = new EventEmitter();

  public masks: Masks;
  public model: FormGroup;
  public cidSelect: CidStruct[] = [];

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.medic;
  public aihFieldsMaxLengthEnum: typeof AihFieldsMaxLengthEnum = AihFieldsMaxLengthEnum;
  public lookupHospitalizationReportEnum: typeof LookupHospitalizationReportEnum = LookupHospitalizationReportEnum;

  public isLoading: boolean = false;
  public isStartWebsocket: boolean = false;

  public urlListCid: string = environment.urlApiMedicalRecord + 'cid';
  public urlApiBilling: string = environment.urlApiBilling + 'ProcedureSigtap/getList';
  public maskToProcedureProfessionalDocument: string = '';

  //PROJETO ATUAL
  ngOnInit(): void {
    this.isLoading = true;
    this.masks = this.maskService.getMasks();

    this.model = this.formBuilder.group({
      nameRequesterEstablishment: ['', [Validators.required, Validators.maxLength(AihFieldsMaxLengthEnum.nameExecutingEstablishment)]],
      cnesRequesterEstablishment: ['', [Validators.required, Validators.maxLength(AihFieldsMaxLengthEnum.cnesRequesterEstablishment)]],
      nameExecutingEstablishment: ['', [Validators.maxLength(AihFieldsMaxLengthEnum.nameExecutingEstablishment)]],
      cnesExecutingEstablishment: ['', [Validators.maxLength(AihFieldsMaxLengthEnum.cnesExecutingEstablishment)]],
      patientName: ['', [Validators.required, Validators.maxLength(AihFieldsMaxLengthEnum.patientName)]],
      patientPromptuary: ['', [Validators.required, Validators.maxLength(AihFieldsMaxLengthEnum.patientPromptuary)]],
      patientCns: ['', [VerifyCNS()]],
      patientBirthDate: ['', [Validators.required, birthdateValidator()]],
      patientIdGender: [{value: '', disabled: !this.isEdit}, [Validators.required]],
      patientRace: ['', [Validators.required, Validators.maxLength(AihFieldsMaxLengthEnum.patientRace)]],
      patientMotherName: ['', [Validators.required, Validators.maxLength(AihFieldsMaxLengthEnum.patientMotherName)]],
      patientMotherPhone: ['', [Validators.required]],
      responsibleName: ['', [Validators.maxLength(AihFieldsMaxLengthEnum.responsibleName)]],
      responsiblePhone: [''],
      patientAdress: ['', [Validators.required, Validators.maxLength(AihFieldsMaxLengthEnum.patientAdress)]],
      patientCity: ['', [Validators.required, Validators.maxLength(AihFieldsMaxLengthEnum.patientCity)]],
      patientIbgeCityCode: ['', [Validators.maxLength(AihFieldsMaxLengthEnum.patientIbgeCityCode)]],
      patientState: ['', [Validators.required, Validators.pattern(/^(AC|AL|AP|AM|BA|CE|DF|ES|GO|MA|MT|MS|MG|PA|PB|PR|PE|PI|RJ|RN|RS|RO|RR|SC|SP|SE|TO)$/)]],
      patientZipCode: ['', [Validators.required, CepValidator()]],
      clinicalSymptoms: ['', [Validators.required, Validators.maxLength(AihFieldsMaxLengthEnum.clinicalSymptoms)]],
      conditionsForInternment: ['', [Validators.maxLength(AihFieldsMaxLengthEnum.conditionsForInternment)]],
      mainDiagnosticResults: ['', [Validators.maxLength(AihFieldsMaxLengthEnum.mainDiagnosticResults)]],
      initialDiagnostic: ['', [Validators.required, Validators.maxLength(AihFieldsMaxLengthEnum.initialDiagnostic)]],
      cid10Main: ['', [Validators.maxLength(AihFieldsMaxLengthEnum.cid10Main)]],
      cid10Secondary: ['', [Validators.maxLength(AihFieldsMaxLengthEnum.cid10Secondary)]],
      cid10AssociatedCauses: ['', [Validators.maxLength(AihFieldsMaxLengthEnum.cid10AssociatedCauses)]],
      procedureCode: ['', [Validators.maxLength(AihFieldsMaxLengthEnum.procedureCode)]],
      procedureClinic: ['', [Validators.maxLength(AihFieldsMaxLengthEnum.procedureClinic)]],
      idProcedureClinic: [{value:null, disabled: !this.isEdit}],
      procedureInternmentCharacter: ['', [Validators.maxLength(AihFieldsMaxLengthEnum.procedureInternmentCharacter)]],
      procedureProfessionalIdDocumentType: [{value:null, disabled: !this.isEdit}],
      procedureProfessionalDocument: ['', [Validators.maxLength(AihFieldsMaxLengthEnum.procedureProfessionalDocument)]],
      procedureProfessionalName: ['', [Validators.maxLength(AihFieldsMaxLengthEnum.procedureProfessionalName)]],
      procedureRequestedDate: [null, birthdateValidator()],
      isTrafficAccident: [{value: false, disabled: !this.isEdit}],
      isTypicalWorkAccident: [{value: false, disabled: !this.isEdit}],
      isRouteWorkAccident: [{value: false, disabled: !this.isEdit}],
      insurerCnpj: ['', [Validators.maxLength(AihFieldsMaxLengthEnum.insurerCnpj)]],
      insurerTicketNumber: ['', [Validators.maxLength(AihFieldsMaxLengthEnum.insurerTicketNumber)]],
      insurerSeries: ['', [Validators.maxLength(AihFieldsMaxLengthEnum.insurerSeries)]],
      companyCnpj: ['', [Validators.maxLength(AihFieldsMaxLengthEnum.companyCnpj)]],
      companyCnae: ['', [Validators.maxLength(AihFieldsMaxLengthEnum.companyCnae)]],
      cbor: ['', [Validators.maxLength(AihFieldsMaxLengthEnum.cbor)]],
      idRelationshipWithSocialSecurity: [{value: null, disabled: !this.isEdit}],
      firstChildGroup: this.formBuilder.group({
        idCid10Main: [{value:null, disabled: !this.isEdit}, [Validators.required]],
        idCid10Secondary: [{value:null, disabled: !this.isEdit}],
        idCid10AssociatedCauses: [{value:null, disabled: !this.isEdit}],
        procedureRequestedDescription: ['', [Validators.maxLength(AihFieldsMaxLengthEnum.procedureRequestedDescription)]],
      }),
    });

    this.mapAihModelToFormGroup(this.aih);

  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isPending == true)
      return;

    else if (this.model && changes && changes.isEdit && changes.isEdit.currentValue == true){
      this.model.get('patientIdGender').enable();
      this.model.get('procedureProfessionalIdDocumentType').enable();
      this.model.get('isTrafficAccident').enable();
      this.model.get('isTypicalWorkAccident').enable();
      this.model.get('isRouteWorkAccident').enable();
      this.model.get('idRelationshipWithSocialSecurity').enable();
      this.model.get('cid10Main').enable();
      this.model.get('firstChildGroup').get('idCid10Main').enable();
      this.model.get('cid10Secondary').enable();
      this.model.get('firstChildGroup').get('idCid10Secondary').enable();
      this.model.get('cid10AssociatedCauses').enable();
      this.model.get('firstChildGroup').get('idCid10AssociatedCauses').enable();
      this.model.get('procedureClinic').enable();
      this.model.get('idProcedureClinic').enable();
    }
    else if (this.model && changes && changes.isEdit && changes.isEdit.currentValue != true) {
      this.model.get('patientIdGender').disable();
      this.model.get('procedureProfessionalIdDocumentType').disable();
      this.model.get('isTrafficAccident').disable();
      this.model.get('isTypicalWorkAccident').disable();
      this.model.get('isRouteWorkAccident').disable();
      this.model.get('idRelationshipWithSocialSecurity').disable();
      this.model.get('cid10Main').disable();
      this.model.get('firstChildGroup').get('idCid10Main').disable();
      this.model.get('cid10Secondary').disable();
      this.model.get('firstChildGroup').get('idCid10Secondary').disable();
      this.model.get('cid10AssociatedCauses').disable();
      this.model.get('firstChildGroup').get('idCid10AssociatedCauses').disable();
      this.model.get('procedureClinic').disable();
      this.model.get('idProcedureClinic').disable();
      
      this.mapAihModelToFormGroup(this.aih);
    }
  }

  submit() {
    if (this.isLoading)
      return;
    
    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    this.isLoading = true;

    let request: PostAihRequest = new PostAihRequest();
    request.aih = this.mapFormGroupToAihModel(this.model);
    request.idAihGroup = this.idAihGroup;
    request.idEpisode = this.idEpisode;
    request.idSector = this.idSector;    
    
    this.createAih(request);
  }

  cancelEdit(event: boolean) {
    if (this.isLoading)
      return;

    const dialogRef = this.dialog.open(AlertModalComponent, {
      data: {
        isTwoButtonsModal: true,
        title: 'Aviso',
        description: 'Após cancelar a edição, todos os dados preenchidos serão perdidos. Deseja confirmar a ação?'
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.confirm) {
          this.backToLastComponent()  
        }        
      }
    });    
  }

  backToLastComponent() {
    this.back.emit();
  }
  

  createAih(request: PostAihRequest) {
    this.aihService.createAih(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (!response.reportPdf64 || response.reportPdf64.trim().length == 0){
          this.alertService.show('Erro', "Erro na disponibilização do AIH", AlertType.error);
          this.isLoading = false;
          return;
        }
        else{
          this.openReportModal(response.reportName, response.reportPdf64);
          this.isLoading = false;
        }

      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      }
    });
  }

  openReportModal(reportName, reportPdf64) {
    this.isLoading = true;

    let reportDialog = this.dialog.open(ReportModalComponent, {
      data: {
        reportName: reportName,
        reportPdf64: reportPdf64,
      },
    });

    reportDialog.afterClosed().subscribe({
      next: () => {
        this.alertService.show('Sucesso', "Documento salvo com sucesso.", AlertType.success);
        this.isLoading = false;
        if (this.isPending != true)
          this.reload.emit();          
        else
          this.backToLastComponent();
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  mapFormGroupToAihModel(formGroup: FormGroup): AihModel {
    let aihModel = new AihModel();
    aihModel.nameRequesterEstablishment = formGroup.get('nameRequesterEstablishment').value;
    aihModel.cnesRequesterEstablishment = this.removeCharactersNotNumeric(formGroup.get('cnesRequesterEstablishment').value);
    aihModel.nameExecutingEstablishment = formGroup.get('nameExecutingEstablishment').value;
    aihModel.cnesExecutingEstablishment = this.removeCharactersNotNumeric(formGroup.get('cnesExecutingEstablishment').value);
    aihModel.patientName = formGroup.get('patientName').value;
    aihModel.patientPromptuary = formGroup.get('patientPromptuary').value;
    aihModel.patientCns = this.removeCharactersNotNumeric(formGroup.get('patientCns').value);
    aihModel.patientBirthDate = this.maskService.formatStringToDate(formGroup.get('patientBirthDate').value);
    aihModel.patientIdGender = formGroup.get('patientIdGender').value;
    aihModel.patientRace = formGroup.get('patientRace').value;
    aihModel.patientMotherName = formGroup.get('patientMotherName').value;
    aihModel.patientMotherPhone = this.removeCharactersNotNumeric(formGroup.get('patientMotherPhone').value);
    aihModel.responsibleName = formGroup.get('responsibleName').value;
    aihModel.responsiblePhone = this.removeCharactersNotNumeric(formGroup.get('responsiblePhone').value);
    aihModel.patientAdress = formGroup.get('patientAdress').value;
    aihModel.patientCity = formGroup.get('patientCity').value;
    aihModel.patientIbgeCityCode = formGroup.get('patientIbgeCityCode').value;
    aihModel.patientState = formGroup.get('patientState').value;
    aihModel.patientZipCode = this.removeCharactersNotNumeric(formGroup.get('patientZipCode').value);
    aihModel.clinicalSymptoms = formGroup.get('clinicalSymptoms').value;
    aihModel.conditionsForInternment = formGroup.get('conditionsForInternment').value;
    aihModel.mainDiagnosticResults = formGroup.get('mainDiagnosticResults').value;
    aihModel.initialDiagnostic = formGroup.get('initialDiagnostic').value;
    aihModel.cid10Main = formGroup.get('cid10Main').value;
    aihModel.idCid10Main = formGroup.get('firstChildGroup').get('idCid10Main').value;
    aihModel.cid10Secondary = formGroup.get('cid10Secondary').value;
    aihModel.idCid10Secondary = formGroup.get('firstChildGroup').get('idCid10Secondary').value;
    aihModel.cid10AssociatedCauses = formGroup.get('cid10AssociatedCauses').value;
    aihModel.idCid10AssociatedCauses = formGroup.get('firstChildGroup').get('idCid10AssociatedCauses').value;
    aihModel.procedureRequestedDescription = formGroup.get('firstChildGroup').get('procedureRequestedDescription').value;
    aihModel.procedureCode = formGroup.get('procedureCode').value;
    aihModel.procedureClinic = formGroup.get('procedureClinic').value;
    aihModel.idProcedureClinic = formGroup.get('idProcedureClinic').value;
    aihModel.procedureInternmentCharacter = formGroup.get('procedureInternmentCharacter').value;
    aihModel.procedureProfessionalIdDocumentType = formGroup.get('procedureProfessionalIdDocumentType').value;
    aihModel.procedureProfessionalDocument = this.removeCharactersNotNumeric(formGroup.get('procedureProfessionalDocument').value);
    aihModel.procedureProfessionalName = formGroup.get('procedureProfessionalName').value;
    aihModel.procedureRequestedDate = this.maskService.formatStringToDate(formGroup.get('procedureRequestedDate').value);
    aihModel.isTrafficAccident = formGroup.get('isTrafficAccident').value;
    aihModel.isTypicalWorkAccident = formGroup.get('isTypicalWorkAccident').value;
    aihModel.isRouteWorkAccident = formGroup.get('isRouteWorkAccident').value;
    aihModel.insurerCnpj = this.removeCharactersNotNumeric(formGroup.get('insurerCnpj').value);
    aihModel.insurerTicketNumber = formGroup.get('insurerTicketNumber').value;
    aihModel.insurerSeries = formGroup.get('insurerSeries').value;
    aihModel.companyCnpj = this.removeCharactersNotNumeric(formGroup.get('companyCnpj').value);
    aihModel.companyCnae = formGroup.get('companyCnae').value;
    aihModel.cbor = formGroup.get('cbor').value;
    aihModel.idRelationshipWithSocialSecurity = formGroup.get('idRelationshipWithSocialSecurity').value;

    return aihModel;
  }

  mapAihModelToFormGroup(aih: AihModel){
    this.isLoading = true;

    this.addValidations();
    this.selectDocumentTypeChange();

    if (aih) {
      this.model.get('nameRequesterEstablishment').setValue(aih.nameRequesterEstablishment);
      this.model.get('cnesRequesterEstablishment').setValue(this.removeCharactersNotNumeric(aih.cnesRequesterEstablishment));
      this.model.get('nameExecutingEstablishment').setValue(aih.nameExecutingEstablishment);
      this.model.get('cnesExecutingEstablishment').setValue(this.removeCharactersNotNumeric(aih.cnesExecutingEstablishment));
      this.model.get('patientName').setValue(aih.patientName);
      this.model.get('patientPromptuary').setValue(aih.patientPromptuary);
      this.model.get('patientCns').setValue(this.removeCharactersNotNumeric(aih.patientCns));
  
      let birthDate = this.maskService.formatDateToString(aih.patientBirthDate, false);
      this.model.get('patientBirthDate').setValue(birthDate);

      if (this.isPending != true)
        this.model.get('patientIdGender').setValue(aih.patientIdGender);

      this.model.get('patientRace').setValue(aih.patientRace);
      this.model.get('patientMotherName').setValue(aih.patientMotherName);
      this.model.get('patientMotherPhone').setValue(this.removeCharactersNotNumeric(aih.patientMotherPhone));
      this.model.get('responsibleName').setValue(aih.responsibleName);
      this.model.get('responsiblePhone').setValue(this.removeCharactersNotNumeric(aih.responsiblePhone));
      this.model.get('patientAdress').setValue(aih.patientAdress);
      this.model.get('patientCity').setValue(aih.patientCity);
      this.model.get('patientIbgeCityCode').setValue(aih.patientIbgeCityCode);
      this.model.get('patientState').setValue(aih.patientState);
      this.model.get('patientZipCode').setValue(this.removeCharactersNotNumeric(aih.patientZipCode));
      this.model.get('clinicalSymptoms').setValue(aih.clinicalSymptoms);
      this.model.get('conditionsForInternment').setValue(aih.conditionsForInternment);
      this.model.get('mainDiagnosticResults').setValue(aih.mainDiagnosticResults);
      this.model.get('initialDiagnostic').setValue(aih.initialDiagnostic);
      this.model.get('cid10Main').setValue(aih.cid10Main);
      this.model.get('firstChildGroup').get('idCid10Main').setValue(aih.idCid10Main);
      this.model.get('cid10Secondary').setValue(aih.cid10Secondary);
      this.model.get('firstChildGroup').get('idCid10Secondary').setValue(aih.idCid10Secondary);
      this.model.get('cid10AssociatedCauses').setValue(aih.cid10AssociatedCauses);
      this.model.get('firstChildGroup').get('idCid10AssociatedCauses').setValue(aih.idCid10AssociatedCauses);
      this.model.get('firstChildGroup').get('procedureRequestedDescription').setValue(aih.procedureRequestedDescription);
      this.model.get('procedureCode').setValue(aih.procedureCode);
      this.model.get('procedureClinic').setValue(aih.procedureClinic);
      this.model.get('idProcedureClinic').setValue(aih.idProcedureClinic);
      this.model.get('procedureInternmentCharacter').setValue(aih.procedureInternmentCharacter);
      this.model.get('procedureProfessionalIdDocumentType').setValue(aih.procedureProfessionalIdDocumentType);
      this.model.get('procedureProfessionalDocument').setValue(this.removeCharactersNotNumeric(aih.procedureProfessionalDocument));
      this.model.get('procedureProfessionalName').setValue(aih.procedureProfessionalName);
  
      let procedureRequestedDate = this.maskService.formatDateToString(aih.procedureRequestedDate, false);
      this.model.get('procedureRequestedDate').setValue(procedureRequestedDate);
  
      this.model.get('isTrafficAccident').setValue(aih.isTrafficAccident);
      this.model.get('isTypicalWorkAccident').setValue(aih.isTypicalWorkAccident);
      this.model.get('isRouteWorkAccident').setValue(aih.isRouteWorkAccident);
      this.model.get('insurerCnpj').setValue(this.removeCharactersNotNumeric(aih.insurerCnpj));
      this.model.get('insurerTicketNumber').setValue(aih.insurerTicketNumber);
      this.model.get('insurerSeries').setValue(aih.insurerSeries);
      this.model.get('companyCnpj').setValue(this.removeCharactersNotNumeric(aih.companyCnpj));
      this.model.get('companyCnae').setValue(aih.companyCnae);
      this.model.get('cbor').setValue(aih.cbor);
      this.model.get('idRelationshipWithSocialSecurity').setValue(aih.idRelationshipWithSocialSecurity);
    }

    this.isLoading = false;
    
  }

  addValidations() {
    if (this.listRelationAihConfigFieldRequired && this.listRelationAihConfigFieldRequired.length > 0 
    && this.listLookupHospitalizationReport && this.listLookupHospitalizationReport.length > 0){        
      this.listRelationAihConfigFieldRequired.forEach(itemAihConfig => {
        if (!itemAihConfig) 
          return;

        let lookupElement = this.listLookupHospitalizationReport.find(itemLookup => itemLookup.idLookupHospitalizationReport == itemAihConfig.idLookupHospitalizationReport);      

        if (lookupElement){
          let control;
          if(lookupElement.idLookupHospitalizationReport == this.lookupHospitalizationReportEnum.Cid_10_secundario ||
            lookupElement.idLookupHospitalizationReport == this.lookupHospitalizationReportEnum.Cid_10_causas_associadas ||
            lookupElement.idLookupHospitalizationReport == this.lookupHospitalizationReportEnum.Descricao_do_procedimento_solicitado){
            control = this.model.get('firstChildGroup').get(lookupElement.aihModelColumnName) as FormControl;
          }
          else
            control = this.model.get(lookupElement.aihModelColumnName) as FormControl;

          if (control) {
            control.addValidators(Validators.required);
            control.updateValueAndValidity();
          }
        }
      });
    }
  }

  removeCharactersNotNumeric(value: string): string{
    let newValue: string = '';

    if (value && value.trim().length > 0)
      newValue = value.replace(/\D/g, "");
    
    return newValue;
  }

  selectDocumentTypeChange(idDocumentType: number = null){
    if (idDocumentType == AihDocumentType.CNS) this.maskToProcedureProfessionalDocument = this.masks.cns;
    else if (idDocumentType == AihDocumentType.CPF) this.maskToProcedureProfessionalDocument = this.masks.cpf;
    else this.maskToProcedureProfessionalDocument = this.masks.cpf + '||' + this.masks.cns;
  }

  setAccidentType(eventValue:any, formControlName: string){
    if (eventValue && eventValue.checked == true){

      if (formControlName === 'isTrafficAccident') {
        this.model.get('isTypicalWorkAccident').setValue(false);
        this.model.get('isRouteWorkAccident').setValue(false);
      } else if (formControlName === 'isTypicalWorkAccident') {
        this.model.get('isTrafficAccident').setValue(false);
        this.model.get('isRouteWorkAccident').setValue(false);
      } else if (formControlName === 'isRouteWorkAccident') {
        this.model.get('isTrafficAccident').setValue(false);
        this.model.get('isTypicalWorkAccident').setValue(false);
      }

    }
  }

  selectCid(event: any, formControlName: string) {
    if(event && event.code)
      this.model.get(formControlName).setValue(event.code);
  }

  selectProcedureClinic(idProcedureClinic: number) {
    if(idProcedureClinic != null) {
      let clinicName = this.listClinicType.find(x => x.idClinicType == idProcedureClinic).clinicTypeName;
      this.model.get('procedureClinic').setValue(clinicName);
    }
    else 
      this.model.get('procedureClinic').setValue(null);

  }

  selectProcedureSigtap(event: any) {
    if(event && event.codProcedure)
      this.model.get('procedureCode').setValue(event.codProcedure);
  }

}