import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-end-nps-totem-modal',
  templateUrl: './end-nps-totem-modal.component.html',
  styleUrls: ['./end-nps-totem-modal.component.css']
})
export class EndNpsTotemModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<EndNpsTotemModalComponent>,
  ) {
    // this.matDialogRef.disableClose = true;
  }

  public isLoading: boolean;

  ngOnInit(): void {
    this.isLoading = false;

    setTimeout(() => {
      this.clickClose();
    }, 5000);
    
  }
  clickClose() {
    this.matDialogRef.close();
  }
}