import { Component, EventEmitter, OnInit, Output, Input, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { EpisodeStatusEnum } from 'src/app/shared/enum/episode-status.enum';
import { ProcedureTriggersEnum } from 'src/app/shared/enum/procedure-triggers.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { BillingDataService } from 'src/app/shared/services/API/billing/billing-data.service';
import { ProcedureSigtapCBOService } from 'src/app/shared/services/API/billing/procedure-sigtap-cbo.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { ProcedureSigtapModel } from 'src/app/shared/services/models/billing/procedure-sigtap.model';
import { AllergyModel } from 'src/app/shared/services/models/medical-record/allergy.model';
import { BillingDataRequest } from 'src/app/shared/services/requests/billing/billing-data-request';
import { MedicalPrescriptionCheckRequest } from 'src/app/shared/services/requests/medical-record/medical-prescription-check.request';
import { MedicalProcedureCheckRequest } from 'src/app/shared/services/requests/medical-record/medical-procedure-check.request';
import { NursingConsultationRequest } from 'src/app/shared/services/requests/medical-record/nursing-consultation.request';
import { ObservationConfigSectorStruct } from 'src/app/shared/services/structs/bed/observation-config-sector.struct';
import { AllergyStruct } from 'src/app/shared/services/structs/medical-record/allergy.struct';
import { EpisodeStruct } from 'src/app/shared/services/structs/orchestrator-patient/episode.struct';
import { ReadMedicalPrescriptionStruct } from 'src/app/shared/services/structs/orchestrator-patient/read-medical-prescription.struct';
import { ReadMedicalProcedureStruct } from 'src/app/shared/services/structs/orchestrator-patient/read-medical-procedure.struct';
import { environment } from 'src/environments/environment';
import { EpisodeReleaseConfirmModalComponent } from '../episode-release-confirm-modal/episode-release-confirm-modal.component';
import { CareLinePriority } from 'src/app/shared/services/models/care-line-priority/care-line-priority.model';
import { GetCareLinePriorityService } from 'src/app/shared/services/API/care-line-priority/get-care-line-priority.service';
import { CareLinePriorityStatusEnum } from 'src/app/shared/enum/care-line-priority/care-line-priority-status.enum';
import { ObservationReportService } from 'src/app/shared/services/API/orchestrator-patient/observation-report.service';
import { CompleteMedicalCareStruct } from 'src/app/shared/services/structs/orchestrator-patient/complete-medical-care.struct';
import { ReportModalComponent } from 'src/app/shared/components/report-modal/report-modal.component';
import { MedicatedSolutionStruct } from 'src/app/shared/services/structs/medical-record/medicated-solution.struct';
import { TissGuideManagementFloatingWindowComponent } from 'src/app/shared/components/tiss-guides/tiss-guide-management-floating-window/tiss-guide-management-floating-window.component';
import { AllPrivateBillingGuideReportStruct } from 'src/app/shared/services/structs/orchestrator-patient/all-private-billing-guide-report.struct';
import { PostNursingConsultationResponse } from 'src/app/shared/services/responses/medical-record/post-nursing-consultation.response';
import { ReportViewSelectModalComponent } from 'src/app/shared/components/report-view-select-modal/report-view-select-modal.component';
import { ExternalPrescriptionStruct } from 'src/app/shared/services/structs/medical-record/external-prescription.struct';
import { TextTemplate } from 'src/app/shared/services/models/meta-data/text-template.model';
import { ProcedureSigtapService } from 'src/app/shared/services/API/billing/procedure-sigtap.service';

@Component({
  selector: 'app-new-nursing-consultation',
  templateUrl: './new-nursing-consultation.component.html',
  styleUrls: ['./new-nursing-consultation.component.css']
})
export class NewNursingConsultationComponent implements OnInit {

  @Input() datetimeStartAttendance: Date;
  @Input() idEpisode: number;
  @Input() patientName: string;
  @Input() birthDate: string;
  @Input() listAllergy: AllergyModel[];
  @Input() listAllergyEpisode: AllergyModel[];
  @Input() listPrescription: ReadMedicalPrescriptionStruct[];
  @Input() listPrescriptionMedicatedSolution: MedicatedSolutionStruct[];
  @Input() listExternalPrescription: ExternalPrescriptionStruct[];
  @Input() listMedicalCare: CompleteMedicalCareStruct[];
  @Input() listProcedure: ReadMedicalProcedureStruct[];
  @Input() listProceduresNursing: ReadMedicalProcedureStruct[];
  @Input() listTextTemplate: TextTemplate[] = [];
  @Input() hasObservationProcedure: boolean;
  @Input() evolutionFillingIsRequired: boolean;
  @Input() episodeStruct: EpisodeStruct;
  @Input() observationConfigSector: ObservationConfigSectorStruct;
  @Input() idBed: number;
  @Input() idSector: number;
  @Input() idService: number;
  @Input() idRoom: number;
  @Input() isReleasable: boolean;
  @Input() isBedManagement: boolean;
  @Input() idQueue: number;
  @Input() showTissGuideButton: boolean = false;
  @Input() childComponent: TissGuideManagementFloatingWindowComponent;

  @Output() saveNursingConsultation = new EventEmitter<any>();
  public masks: Masks;
  public actions: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private maskService: MaskService,
    private billingDataService: BillingDataService,
    private authService: AuthService,
    private procedureSigtapCBOService: ProcedureSigtapCBOService,
    private dialog: MatDialog,
    public router: Router,
    private getCareLinePriorityService: GetCareLinePriorityService,
    public observationReportService: ObservationReportService,
  ) { }

  public model: UntypedFormGroup;
  public isLoading: boolean;
  public isDisable: boolean;
  public urlListAllergies: string = environment.urlApiMedicalRecord + "Allergy";
  public listProcedureSigtap: ProcedureSigtapModel[] = [];
  public listPreselectedPRocedure: ProcedureSigtapModel[] = [];
  public listTriggersProcedure: ProcedureSigtapModel[] = [];
  public urlListProcedure: string;
  public codCbo: string;
  public idCompetence: number;
  public listCheckedMedication: MedicalPrescriptionCheckRequest[];
  public listCheckedProcedure: MedicalProcedureCheckRequest[];
  public allergies: AllergyModel[] = [];
  public allergiesToSave: AllergyModel[] = [];
  public isSaved: boolean = false;
  public listCareLinePriority: CareLinePriority[] = [];

  // @ViewChild(TissGuideManagementFloatingWindowComponent) childComponent: TissGuideManagementFloatingWindowComponent;
  public allPrivateBillingGuideReportStruct: AllPrivateBillingGuideReportStruct;
  public showTissGuideButtonValidation: boolean = false;
  public trySubmit: boolean = false;

  public quillConfiguration = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      [{ 'list': 'ordered'}, { 'list': 'check' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    
      [{ 'background': [] }],
    ],
  };

  ngOnInit(): void {
    this.isLoading = false;
    this.isDisable = false;
    this.masks = this.maskService.getMasks();
    this.codCbo = this.authService.getTokenMenu().codCbo;
    this.idCompetence = this.authService.getTokenMenu().healthUnit.idProcedureSigtapCompetence;
    this.listCheckedMedication = [];
    this.listCheckedProcedure = [];

    this.showTissGuideButtonValidation = this.showTissGuideButton;

    if (this.codCbo && this.idCompetence) {
      let url = `ProcedureSigtapCBO/codCbo/${this.codCbo}/idCompetence/${this.idCompetence}`
      this.urlListProcedure = environment.urlApiBilling + url;
      this.getPreselectedProcedures();
    }
    this.model = this.formBuilder.group({
      patientEvolution: [''],
      temperature: [''],
      respiratoryFrequency: [''],
      heartRate: [''],
      glucose: [''],
      bloodPressure: [''],
      saturation: [''],
      idCareLinePriority: [],
    });
    if (this.evolutionFillingIsRequired) {
      this.model.get('patientEvolution').setValidators([Validators.required]);
      this.model.get('patientEvolution').updateValueAndValidity();
    }
    if (this.episodeStruct.idCareLinePriority && this.episodeStruct.idCareLinePriority != null) {
      this.model.get('idCareLinePriority').setValue(this.episodeStruct.idCareLinePriority);
      this.model.get('idCareLinePriority').disable();
    }
    this.allergies = this.listAllergyEpisode;
    this.populateCareLinePrioritySelect();
  }

  submit(openToRelease: boolean) {
    this.trySubmit = true;
    if (!this.model.valid) {
      if(this.evolutionFillingIsRequired && (this.model.get('patientEvolution').value == null || this.model.get('patientEvolution').value == "")){
        this.alertService.show('Erro', "O campo Evolução do Paciente é obrigatório. Insira um conteúdo para salvar.", AlertType.error);
        return
      }
      this.alertService.show('Erro', "Preencha todos os campos obrigatórios da evolução.", AlertType.error);
      return;
    }
    if (this.isLoading) {
      return;
    }
    if (this.isSaved) {
      this.alertService.show('Aviso', "Essa conduta ja foi salva.", AlertType.warning);
      return;
    }

    this.isLoading = true;

    let nursingConsultationRequest: NursingConsultationRequest = new NursingConsultationRequest();

    nursingConsultationRequest = this.mapToRequest()

    if (nursingConsultationRequest.saturation < 0 || nursingConsultationRequest.saturation > 100) {
      this.alertService.show('Erro', "O valor da saturação deve ser de 0 a 100", AlertType.error);
      this.isLoading = false;
      return;
    }

    this.postNursingConsultation(nursingConsultationRequest, openToRelease);
  }

  postNursingConsultation(nursingConsultationRequest: NursingConsultationRequest, openToRelease: boolean) {
    this.observationReportService.postNursingConsultation(nursingConsultationRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (response.errorCode == 11)
          this.alertService.show("Erro", response.errorDescription, AlertType.error);

        if (response.allPrivateBillingGuideReportStruct){
          this.allPrivateBillingGuideReportStruct = new AllPrivateBillingGuideReportStruct();
          this.allPrivateBillingGuideReportStruct = response.allPrivateBillingGuideReportStruct;          
        }

        this.isSaved = true;

        let billingDataRequest = this.mapToBillingDataRequest(response.idNursingConsultation);

        this.postBillingData(billingDataRequest);

        if (this.hasReport(response)) {
          let reportDialog = this.dialog.open(ReportViewSelectModalComponent, {
            data: {
              observationReport: response.reportPdf64,
              allReportsConcat: response.allReportsConcat64,
              sign: false,
              appointmentGuideReport: this.allPrivateBillingGuideReportStruct?.appointmentGuideReport64,
              admissionGuideReport: this.allPrivateBillingGuideReportStruct?.admissionGuideReport64,
              feesGuideReport: this.allPrivateBillingGuideReportStruct?.feesGuideReport64,
              spSadtGuideReport: this.allPrivateBillingGuideReportStruct?.spSadtGuideReport64,
            },
          });
          reportDialog.afterClosed().subscribe(() => {

            this.saveNursingConsultation.emit();

            if (openToRelease) {
              this.releaseModal();
            }
          });
        }

        else {
          this.saveNursingConsultation.emit();

            if (openToRelease) {
              this.releaseModal();
            }
        }
        
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  hasReport(response: PostNursingConsultationResponse) {
    return Boolean(
      response.reportPdf64 ||
      response.allReportsConcat64 ||
      this.allPrivateBillingGuideReportStruct?.appointmentGuideReport64 ||
      this.allPrivateBillingGuideReportStruct?.admissionGuideReport64 ||
      this.allPrivateBillingGuideReportStruct?.feesGuideReport64 ||
      this.allPrivateBillingGuideReportStruct?.spSadtGuideReport64
    )
  }

  mapToRequest(): NursingConsultationRequest {
    let nursingConsultationRequest: NursingConsultationRequest = new NursingConsultationRequest();
    nursingConsultationRequest.idEpisode = this.idEpisode;
    nursingConsultationRequest.patientEvolution = this.model.get('patientEvolution').value ? this.model.get('patientEvolution').value : null;
    let temperature = this.model.get('temperature').value ? this.model.get('temperature').value.split("") : null;
    nursingConsultationRequest.temperature = temperature ? parseFloat(temperature[0] + temperature[1] + "." + temperature[2]) : null;
    nursingConsultationRequest.respiratoryFrequency = this.model.get('respiratoryFrequency').value ? parseInt(this.model.get('respiratoryFrequency').value) : null;
    nursingConsultationRequest.heartRate = this.model.get('heartRate').value ? parseInt(this.model.get('heartRate').value) : null;
    nursingConsultationRequest.glucose = this.model.get('glucose').value ? parseInt(this.model.get('glucose').value) : null;
    let bloodPressure = this.maskService.formatBloodPressure(this.model.get('bloodPressure').value)
    nursingConsultationRequest.bloodPressureDiastole = bloodPressure && bloodPressure[1] ? bloodPressure[1] : null;
    nursingConsultationRequest.bloodPressureSystole = bloodPressure && bloodPressure[0] ? bloodPressure[0] : null;
    nursingConsultationRequest.saturation = this.model.get('saturation').value ? parseInt(this.model.get('saturation').value) : null;
    nursingConsultationRequest.listAllergy = this.allergiesToSave.map(allergy => new AllergyStruct(allergy, this.idEpisode));
    nursingConsultationRequest.datetimeStartAttendance = this.datetimeStartAttendance;
    nursingConsultationRequest.listMedicalPrescriptionCheck = this.listCheckedMedication;
    nursingConsultationRequest.listMedicalProcedureCheck = this.listCheckedProcedure;
    nursingConsultationRequest.idRoomAttendance = this.idRoom;

    if (this.model.get('idCareLinePriority').value) {
      nursingConsultationRequest.careLinePriorityStatusStruct.idCareLinePriority = parseInt(this.model.get('idCareLinePriority').value);
      nursingConsultationRequest.careLinePriorityStatusStruct.idCareLinePriorityStatus = CareLinePriorityStatusEnum.suspeita;
    }

    if (this.idQueue && this.isBedManagement != true) {
      nursingConsultationRequest.idQueueUsed = this.idQueue;
    }
    nursingConsultationRequest.medicalCareHistorie = this.listMedicalCare;

    if (this.showTissGuideButtonValidation) {
      let guide = this.childComponent.getGuidRequest();
      if (guide) {
        nursingConsultationRequest.guideRequest = guide;
      }
    }

    return nursingConsultationRequest;
  }

  disableOption(idAllergy: number) {
    return this.listAllergyEpisode.find(x => x.idAllergy == idAllergy) ? true : false
  }

  addItemAllergies(event: any) {
    this.allergies = event;
  }

  addItemProcedure(event: any) {
    this.listProcedureSigtap = event;
    this.listPreselectedPRocedure = this.listProcedureSigtap;
  }

  mapToBillingDataRequest(idRelation: number): BillingDataRequest {
    let billingDataRequest: BillingDataRequest = new BillingDataRequest();
    billingDataRequest.idRelation = idRelation;
    billingDataRequest.idModule = MenuModuleEnum.medic;
    billingDataRequest.idEpisode = this.idEpisode;
    billingDataRequest.listIdProcedure = this.listProcedureSigtap.map(a => a.idProcedureSigtap);
    return billingDataRequest;
  }

  postBillingData(billingDataRequest: BillingDataRequest) {
    if (this.codCbo) {
      this.billingDataService.postBillingData(billingDataRequest).subscribe((response) => {
        this.isLoading = false;
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        this.isSaved = true;
      },
        (error) => {
          this.isLoading = false;
          this.alertService.show('Erro inesperado', error, AlertType.error);
          return;
        });
    }

    this.alertService.show('Sucesso', "Nova conduta salva com sucesso", AlertType.success);
    this.isLoading = false;
    //this.saveNursingConsultation.emit();
  }

  getPreselectedProcedures() {
    let idsTriggers: number[] = [];
    idsTriggers.push(ProcedureTriggersEnum.afericao_pressao_arterial.valueOf());
    idsTriggers.push(ProcedureTriggersEnum.glicemia_capilar.valueOf());
    idsTriggers.push(ProcedureTriggersEnum.afericao_temperatura.valueOf());

    this.procedureSigtapCBOService.listProcedureSigtapCodProcedure(idsTriggers, this.idCompetence, this.codCbo).subscribe(response => {
      if (response.isError) {
        this.alertService.show("Erro", response.errorDescription, AlertType.error);
        return;
      }

      this.listTriggersProcedure = response.list;
    })
  }

  onBlurPressure() {
    var bloodPressure = this.model.get("bloodPressure").value;
    if (bloodPressure.length >= 4) {
      this.setPreselectTrigger(ProcedureTriggersEnum.afericao_pressao_arterial);
    } else {
      this.removePreselectTrigger(ProcedureTriggersEnum.afericao_pressao_arterial);
    }
  }


  onBlurTemperature() {
    var temperature = this.model.get("temperature").value;
    if (temperature.length == 3) {
      this.setPreselectTrigger(ProcedureTriggersEnum.afericao_temperatura)
    } else {
      this.removePreselectTrigger(ProcedureTriggersEnum.afericao_temperatura);
    }
  }

  onBlurGlucose() {
    var glucose = this.model.get("glucose").value;
    if (glucose.length >= 1) {
      this.setPreselectTrigger(ProcedureTriggersEnum.glicemia_capilar);
    } else {
      this.removePreselectTrigger(ProcedureTriggersEnum.glicemia_capilar);
    }
  }


  setPreselectTrigger(idTrigger: ProcedureTriggersEnum) {
    if (!this.listPreselectedPRocedure) return;
    let listPreselectedPRocedure = [...this.listPreselectedPRocedure];
    this.listPreselectedPRocedure = null;
    let trigger = this.listTriggersProcedure.find(c => c.idTriggersProcedureSigtap == idTrigger.valueOf())
    var indexOf = listPreselectedPRocedure.findIndex(c => c.idTriggersProcedureSigtap == idTrigger.valueOf());
    if (trigger && indexOf < 0)
      listPreselectedPRocedure.push(trigger);
    this.listPreselectedPRocedure = [...listPreselectedPRocedure];
    this.listProcedureSigtap = this.listPreselectedPRocedure;
  }

  removePreselectTrigger(idTrigger: ProcedureTriggersEnum) {
    let listPreselectedPRocedure = [...this.listPreselectedPRocedure];
    this.listPreselectedPRocedure = null;
    var indexOf = listPreselectedPRocedure.findIndex(c => c.idTriggersProcedureSigtap == idTrigger.valueOf());
    if (indexOf >= 0) {
      listPreselectedPRocedure.splice(indexOf, 1);
    }
    this.listPreselectedPRocedure = [...listPreselectedPRocedure];
    this.listProcedureSigtap = this.listPreselectedPRocedure;
  }

  releaseModal() {
    const dialogRef = this.dialog.open(EpisodeReleaseConfirmModalComponent, {
      data: {
        episode: this.episodeStruct,
        idStatus: EpisodeStatusEnum.FinalizadoAlta.valueOf(),
        observationConfigSector: this.observationConfigSector,
        idService: this.idService,
        idBed: this.idBed,
        idSector: this.idSector,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.release) {
        this.isLoading = false;
        if (this.isBedManagement) {
          this.router.navigate(['/observation/bed-management', { idSector: this.idSector, idRoom: this.idRoom, idService: this.idService }]);
        } else {
          this.router.navigate(['/observation/patient-list', { idSector: this.idSector, idRoom: this.idRoom, idService: this.idService }]);
        }
      }
    });
  }

  releasePatient() {
    if (!this.isSaved) {
      this.submit(true);
    } else {
      this.releaseModal();
    }
  }

  updateAllergiesToSave(allergies: AllergyModel[]) {
    this.allergiesToSave = allergies;
  }

  populateCareLinePrioritySelect() {
    this.getCareLinePriorityService.getAllByIdHealthUnit().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listCareLinePriority = response.listCareLinePriority;
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openReportDialog(reportName, reportPdf64) {
    if (reportPdf64 && reportPdf64.trim() !== "") {
      this.dialog.open(ReportModalComponent, {
        data: {
          reportName: reportName,
          reportPdf64: reportPdf64,
        },
      });
    }
  }

  selectTemplate(textTemplate: TextTemplate) {
    if(textTemplate == null){
      this.model.get('patientEvolution').setValue(null);
      return;
    }
    this.model.get('patientEvolution').setValue(textTemplate.textTemplateContent);
  }

  onCheckProcedure(idProcedureSigtap: number) {
    if(idProcedureSigtap)
      this.getProcedure(idProcedureSigtap);
  }

  getProcedure(idProcedureSigtap: number) {
    this.procedureSigtapCBOService.getProcedureSigtapByIdSigtap(idProcedureSigtap).subscribe({
      next: (response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        
        if(response.procedureSigtapCboStruct && this.listPreselectedPRocedure) {
          let listPreselectedPRocedure = [...this.listPreselectedPRocedure];
          this.listPreselectedPRocedure = null;
          let exitsProcedure = listPreselectedPRocedure.find(x => x.idProcedureSigtap == response.procedureSigtapCboStruct.idProcedureSigtap);
          if(!exitsProcedure){
            listPreselectedPRocedure.push(response.procedureSigtapCboStruct);
            this.listPreselectedPRocedure = [...listPreselectedPRocedure];
            this.listProcedureSigtap = this.listPreselectedPRocedure;
          }
        }
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

 }