export class SearchFlowchartRequest
{
    public searchText: string;

    public idGender: number;

    public birthdate: Date;

    public idProtocol: number;

    public flowchartGroup: number[];

    public beginPeriod: Date;
    
    public endPeriod: Date;
}