import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { ArrivalType, Education, Gender, MaritalStatus, Origin, PatientNecessity, Race } from 'src/app/shared/services/responses/medical-record/lookup.response';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MedicalRecordLookupService } from 'src/app/shared/services/API/medical-record/lookup.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { SelectPatientModalComponent } from './select-patient-modal/select-patient-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { FowardRequest } from 'src/app/shared/services/requests/orchestrator-queue/foward.request';
import { ForwardPatientModalComponent } from './forward-patient-modal/forward-patient-modal.component';
import { ReportService } from 'src/app/shared/services/API/orchestrator-patient/report.service';
import { PriorityModel } from 'src/app/shared/services/models/totem/priority.model';
import { PatientService } from 'src/app/shared/services/API/medical-record/patient.service';
import { PatientStruct } from 'src/app/shared/services/structs/medical-record/patient.struct';
import { PatientRequest } from 'src/app/shared/services/requests/medical-record/patient.request';
import { GetFrontDeskBySectorService } from 'src/app/shared/services/API/front-desk/get-front-desk-by-sector.service';
import { FowardService } from 'src/app/shared/services/API/orchestrator-queue/foward.service';
import { PriorityService } from 'src/app/shared/services/API/totem/priority.service';
import { RoomService } from 'src/app/shared/services/API/flow/room.service';
import { SectorService } from 'src/app/shared/services/API/flow/sector.service';
import { ViaCepService } from 'src/app/shared/services/API/integration/via-cep.service';
import { AllergyService } from 'src/app/shared/services/API/medical-record/allergy.service';
import { ForwardResponse } from 'src/app/shared/services/responses/orchestrator-queue/forward.response';
import { ClockService } from 'src/app/shared/services/API/orchestrator-queue/clock.service';
import { SearchPatientTotemRequest } from 'src/app/shared/services/requests/orchestrator-totem/search-patient-totem.request';
import { SearchPatientFrontdeskService } from 'src/app/shared/services/API/orchestrator-patient/search-patient-frontdesk.service';
import { FrontDeskPatientReportRequest } from 'src/app/shared/services/requests/orchestrator-patient/front-desk-patient-report.request';
import { FrontDeskIdentificationEnum } from 'src/app/shared/enum/front-desk-identification.enum';
import { NatureOfAttendanceEnum } from 'src/app/shared/enum/medical-record/nature-of-attendance.enum';
import { FlowStruct } from 'src/app/shared/services/structs/flow/flow.struct';
import { FlowSequenceModalComponent } from 'src/app/shared/components/flow-sequence-modal/flow-sequence-modal.component';
import { PrioritiesStatusEnum } from 'src/app/shared/enum/priorities-status.enum';
import { CalcAgeService } from 'src/app/shared/services/API/medical-record/calc-age.service';
import { CalcAgeResponse } from 'src/app/shared/services/responses/medical-record/calc-age.response';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MetaDataStruct } from 'src/app/shared/services/structs/meta-data/meta-data.struct';
import { MetaDataService } from 'src/app/shared/services/API/meta-data/meta-data.service';
import { MetaDataKeyValueStruct } from 'src/app/shared/services/structs/meta-data/meta-data-key-value.struct';
import { MetaDataTypeEnum } from 'src/app/shared/enum/meta-data/meta-data-type.enum';
import { VerifyDate } from 'src/app/shared/custom-validators/date-age.validator';
import { CepValidator } from 'src/app/shared/custom-validators/cep.validator';
import { GroupOriginService } from 'src/app/shared/services/API/orchestrator-user/group-origin.service';
import { RelationQueueRoomAttendOnFrontDeskService } from 'src/app/shared/services/API/front-desk/relation-queue-room-attend-on-front-desk.service';
import { PostPutRelationQueueRoomAttendOnFrontDeskRequest } from 'src/app/shared/services/requests/front-desk/post-put-relation-queue-room-attend-on-front-desk.request';
import { WebsocketAttendanceUtilService } from 'src/app/shared/services/websocket-util/websocket-attendance-util.service';
import { UpdateStatusQueueService } from 'src/app/shared/services/API/orchestrator-queue/update-status-queue.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { TissGuideManagementFloatingWindowComponent } from 'src/app/shared/components/tiss-guides/tiss-guide-management-floating-window/tiss-guide-management-floating-window.component';
import { FrontDeskService } from 'src/app/shared/services/API/orchestrator-patient/front-desk.service';
import { PatientTissGuideRequest } from 'src/app/shared/services/requests/orchestrator-patient/patient-tiss-guide.request';
import { TissGuideNotSavedConfirmModalComponent } from 'src/app/shared/components/tiss-guides/tiss-guide-not-saved-confirm-modal/tiss-guide-not-saved-confirm-modal.component';
import { AllPrivateBillingGuideReportStruct } from 'src/app/shared/services/structs/orchestrator-patient/all-private-billing-guide-report.struct';
import { UserRoleEnum } from 'src/app/shared/enum/user-role.enum';
import { UserLoginResponse } from 'src/app/shared/services/responses/user/auth.response';
import { UtilService } from 'src/app/shared/services/util.service';
import { SignatureCredentialsModalComponent } from 'src/app/shared/components/signature-credentials-modal/signature-credentials-modal.component';
import { FrontdeskReportResponse } from 'src/app/shared/services/responses/orchestrator-patient/front-desk-report.response';
import { ReportViewSelectModalComponent } from 'src/app/shared/components/report-view-select-modal/report-view-select-modal.component';
import { SearchModalComponent } from './search-modal/search-modal.component';
import { AttendanceOccupiedRemoveModalComponent } from 'src/app/shared/components/attendance-occupied-remove-modal/attendance-occupied-remove-modal.component';
import { EpisodeService } from 'src/app/shared/services/API/orchestrator-patient/episode.service';
import { PatientHealthcareAgreementDetailsStruct } from 'src/app/shared/services/structs/medical-record/patient-healthcare-agreement-details.struct';
import { ScheduledPatientHealthcareAgreementStruct } from 'src/app/shared/services/structs/medical-record/scheduled-patient-healthcare-agreement.struct';
import { SelectPatientHealthcareAgreementComponent } from './select-patient-healthcare-agreement/select-patient-healthcare-agreement.component';

@Component({
  selector: 'app-patient-register',
  templateUrl: './patient-register.component.html',
  styleUrls: ['./patient-register.component.css']
})
export class PatientRegisterComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private patientService: PatientService,
    private frontDeskService: FrontDeskService,
    private episodeService: EpisodeService,
    private priorityService: PriorityService,
    private getFrontDeskBySectorService: GetFrontDeskBySectorService,
    private lookupService: MedicalRecordLookupService,
    private maskService: MaskService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public fowardService: FowardService,
    public reportService: ReportService,
    public roomService: RoomService,
    public sectorService: SectorService,
    public router: Router,
    public viaCepService: ViaCepService,
    public allergyService: AllergyService,
    public clockService: ClockService,
    private searchPatientService: SearchPatientFrontdeskService,
    private calcAgeService: CalcAgeService,
    private metaDataService: MetaDataService,
    private GroupOriginByOrchestratorService: GroupOriginService,
    private relationQueueRoomAttendOnFrontDeskService: RelationQueueRoomAttendOnFrontDeskService,
    public websocketAttendanceUtilService: WebsocketAttendanceUtilService,
    public updateStatusQueueService: UpdateStatusQueueService,
    private authService: AuthService,
    private utilsService: UtilService,
  ) { }

  @Input() showTissGuideButton: boolean = false;

  @ViewChild(TissGuideManagementFloatingWindowComponent) childComponent: TissGuideManagementFloatingWindowComponent;
  @ViewChild(SelectPatientHealthcareAgreementComponent) childSelectPatientHealthcareAgreementComponent: SelectPatientHealthcareAgreementComponent;

  public showTissGuideButtonValidation: boolean = false;
  public allPrivateBillingGuideReportStruct: AllPrivateBillingGuideReportStruct;
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.frontdesk;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.frontdesk_patient_register;
  public model: FormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isLoadingCep: boolean;
  public cepIsValid: boolean = false;
  public countryIsPopulated: boolean = false;
  public cityIsPopulated: boolean = false;
  public stateIsPopulated: boolean = false;
  public isLoadingCpf: boolean;
  public isLoadingCns: boolean;
  public isWristbandActive: boolean;
  public wristbandWidth: number;
  public wristbandHeight: number;
  public datetimeStartRegister: Date;
  public listMetaDataStruct: MetaDataStruct[] = [];
  public raceList: Race[];
  public genderList: Gender[];
  public educationList: Education[];
  public maritalStatusList: MaritalStatus[];
  public originList: Origin[];
  public arrivalTypeList: ArrivalType[];
  public masks: Masks;
  public idPatient: number = null;
  public idEpisode: number = null;
  public idQueue: number = null;
  public idSector: number = null;
  public idRoom: number = null;
  public isUpdateFrontDesk: boolean = false;
  public patientAge: string = null;
  public listPatientNecessity: PatientNecessity[];
  public newEpisode: boolean = false;
  public isUnidentifiedPatient: boolean = false;
  public listOfPriorities: PriorityModel[];
  public identification: string;
  public idIdentification: number;
  public isFlowSequence: boolean;
  public flowSequence: FlowStruct[];
  public disallowedIdPriorities: number[] = [];
  public listMetaData: MetaDataStruct[] = [];
  public isAttended: boolean = false;
  public patientSocialName: string;
  public patientName: string;
  public userLogin: string;
  public userName: string;
  public isAttendanceRemoval: boolean = false;
  public listPatientHealthcareAgreementDetails: PatientHealthcareAgreementDetailsStruct[] = [];
  public scheduledPatientHealthcareAgreement: ScheduledPatientHealthcareAgreementStruct;

  //Assinatura digital
  public sign: boolean;
  public requireSignature: boolean;

  ngOnInit(): void {
    this.userLogin = this.authService.getTokenMenu().login;
    this.userName = this.authService.getTokenMenu().userName;
    this.requireSignature = this.checkSigningResponsibility();
    this.masks = this.maskService.getMasks();
    this.isLoading = true;
    this.isFirstLoading = true;
    this.listPatientNecessity = [];
    this.idSector = parseInt(this.activatedRoute.snapshot.paramMap.get('idSector'));
    this.idRoom = parseInt(this.activatedRoute.snapshot.paramMap.get('idRoom'));
    this.showTissGuideButtonValidation = this.showTissGuideButton;
    this.model = this.formBuilder.group({
      patientName: ['', [Validators.required]],
      socialName: [''],
      cpf: [''],
      birthDate: [''],
      idGender: [''],
      cns: [''],
      phone1: [''],
      phone2: [''],
      rg: [''],
      expeditionIssuer: [''],
      expeditionDate: [''],
      motherName: [''],
      fatherName: [''],
      idRace: [''],
      idEducation: [''],
      profession: [''],
      idMaritalStatus: [''],
      birthCountry: [''],
      birthState: [''],
      birthCity: [''],
      zipCode: ['', [CepValidator()]],
      street: [''],
      neighborhood: [''],
      city: [''],
      state: [''],
      country: [''],
      houseNumber: [''],
      apartmentNumber: [''],
      listIdPriority: [''],
      unidentifiedPatientDescription: [''],
      observation: [''],
      idArrivalType: [''],
      idOrigin: [''],
      companionName: [''],
      companionPhone: [''],
      companionCpf: [''],
      companionParentage: [''],
      listIdPatientNecessity: [''],
    });

    this.getAllPriorities();
    this.populateLookupSelect();
    this.getArrivalTypeAndOrigin();

    if (this.activatedRoute.snapshot.paramMap.get('idEpisode'))
      this.idEpisode = parseInt(this.activatedRoute.snapshot.paramMap.get('idEpisode'));
    else
      this.openModalSearchByCPF();

    if (this.activatedRoute.snapshot.paramMap.get('isUpdateFrontdesk'))
      this.isUpdateFrontDesk = this.activatedRoute.snapshot.paramMap.get('isUpdateFrontdesk') == "true" ? true : false;

    this.getListMetaData(this.idSector);


    this.idQueue = parseInt(this.activatedRoute.snapshot.paramMap.get('idQueue'));

    if (Number.isInteger(this.idQueue) && this.idQueue && this.idQueue > 0 && Number.isInteger(this.idEpisode) && this.idEpisode && this.idEpisode > 0 && this.isUpdateFrontDesk == false)
      this.startWebsocket();

  }

  submit(sign: boolean) {
    if (this.isLoading)
      return;

    this.sign = sign;

    if (this.isUnidentifiedPatient) {
      if (!this.model.get('unidentifiedPatientDescription').value) {
        this.alertService.show('Erro', 'A descrição do paciente não identificado é obrigatória', AlertType.error);
        return;
      }

      this.isLoading = true;

      let patient = new PatientRequest();
      if (Number.isInteger(this.idEpisode))
        patient.idEpisode = this.idEpisode;

      patient.isUnidentifiedPatient = this.isUnidentifiedPatient;
      patient.unidentifiedPatientDescription = this.model.get('unidentifiedPatientDescription').value;
      patient.priorities = this.model.get('listIdPriority').value ? this.model.get('listIdPriority').value : [];
      patient.datetimeStartRegister = this.datetimeStartRegister;
      patient.idNatureOfAttendance = NatureOfAttendanceEnum.presencial;

      this.patientService.savePatient(patient).subscribe({
        next: (response) => {
          if (response.isError) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            return;
          }
          else if (this.isUpdateFrontDesk) {

            this.alertService.show('Sucesso', "Dados atualizados com sucesso", AlertType.success);
            this.isLoading = false;
            this.VerifySignAndGetReport(response.idEpisode, null, null);
            return;
          }
          else
            this.populateDestinationSelect(response.idEpisode);
        },
        error: (error) => {
          console.log(error);
        }
      });
    }
    else {
      if (this.model.invalid) {
        this.alertService.show('Erro', 'Você precisa informar todos os campos obrigatórios', AlertType.error);
        return;
      }

      this.isLoading = true;
      let patient = new PatientRequest();
      if (Number.isInteger(this.idPatient))
        patient.idPatient = this.idPatient;

      if (Number.isInteger(this.idEpisode))
        patient.idEpisode = this.idEpisode;

      patient.patientName = this.model.get('patientName').value;
      patient.socialName = this.model.get('socialName').value;
      patient.cpf = this.model.get('cpf').value ? this.model.get('cpf').value.replace(/[^0-9]+/g, '') : null;
      patient.birthDate = this.maskService.formatStringToDate(this.model.get('birthDate').value);
      patient.idGender = this.model.get('idGender').value;
      patient.cns = this.model.get('cns').value ? this.model.get('cns').value.replace(/[^0-9]+/g, '').replace(" ", '') : null;
      patient.phone1 = this.maskService.formatPhoneToSave(this.model.get('phone1').value);
      patient.phone2 = this.maskService.formatPhoneToSave(this.model.get('phone2').value);
      patient.rg = this.model.get('rg').value;
      patient.expeditionIssuer = this.model.get('expeditionIssuer').value;
      patient.expeditionDate = this.maskService.formatStringToDate(this.model.get('expeditionDate').value);
      patient.motherName = this.model.get('motherName').value;
      patient.fatherName = this.model.get('fatherName').value;
      patient.idRace = this.model.get('idRace').value;
      patient.idEducation = this.model.get('idEducation').value;
      patient.profession = this.model.get('profession').value;
      patient.idMaritalStatus = this.model.get('idMaritalStatus').value;
      patient.birthCountry = this.model.get('birthCountry').value;
      patient.birthState = this.model.get('birthState').value;
      patient.birthCity = this.model.get('birthCity').value;
      patient.zipCode = this.model.get('zipCode').value;
      patient.street = this.model.get('street').value;
      patient.neighborhood = this.model.get('neighborhood').value;
      patient.city = this.model.get('city').value;
      patient.state = this.model.get('state').value;
      patient.country = this.model.get('country').value;
      patient.houseNumber = this.model.get('houseNumber').value;
      patient.apartmentNumber = this.model.get('apartmentNumber').value;
      patient.priorities = this.model.get('listIdPriority').value ? this.model.get('listIdPriority').value : [];
      patient.metaData = [];
      patient.observation = this.model.get('observation').value;

      patient.idArrivalType = this.model.get('idArrivalType').value;
      patient.idOrigin = this.model.get('idOrigin').value;
      patient.companionName = this.model.get('companionName').value;
      patient.companionPhone = this.maskService.formatPhoneToSave(this.model.get('companionPhone').value);
      patient.companionCpf = this.model.get('companionCpf').value ? this.model.get('companionCpf').value.replace(/[^0-9]+/g, '') : null;
      patient.companionParentage = this.model.get('companionParentage').value;
      patient.listIdPatientNecessity = this.model.get('listIdPatientNecessity').value ? this.model.get('listIdPatientNecessity').value.map(c => parseInt(c)) : [];
      patient.datetimeStartRegister = this.datetimeStartRegister;
      patient.idNatureOfAttendance = NatureOfAttendanceEnum.presencial;
      patient.listPatientHealthcareAgreementDetails = this.childSelectPatientHealthcareAgreementComponent?.getListPatientHealthcareAgreementToFrontDeskRequest();

      for (let item of Object.keys(patient)) {
        patient[item] = patient[item] ? patient[item] : null;
      }
      for (let item of this.listMetaDataStruct) {
        const formControl = this.model.get(item.metaData.idMetaData.toString());

        if (formControl && formControl.value) {
          let metadataSend = new MetaDataKeyValueStruct();
          metadataSend.key = item.metaData.idMetaData;
          metadataSend.value = item.metaDataType.idMetaDataType === MetaDataTypeEnum.Numero ?
            formControl.value.toString() : formControl.value;
          patient.metaData.push(metadataSend);
        }
      }
      let patientTissGuide: PatientTissGuideRequest = new PatientTissGuideRequest();

      patientTissGuide.patientRequest = patient;

      if (this.showTissGuideButtonValidation) {
        let guide = this.childComponent.getGuidRequest();
        if (guide) {
          patientTissGuide.guideRequest = guide;
        }
      }

      this.frontDeskService.Post(patientTissGuide).subscribe({
        next: (response) => {
          if (response.isError) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            this.isLoading = false;
            return;
          }

          if (response.errorCode == 11)
            this.alertService.show("Erro", response.errorDescription, AlertType.error);

          if (response.allPrivateBillingGuideReportStruct) {
            this.allPrivateBillingGuideReportStruct = new AllPrivateBillingGuideReportStruct();
            this.allPrivateBillingGuideReportStruct = response.allPrivateBillingGuideReportStruct;
          }

          if (this.isUpdateFrontDesk) {
            this.alertService.show('Sucesso', "Dados atualizados com sucesso", AlertType.success);
            this.isLoading = false;
            this.VerifySignAndGetReport(response.idEpisode, null, null);
            return;
          }
          else
            this.populateDestinationSelect(response.idEpisode);

          if (this.idIdentification == FrontDeskIdentificationEnum.CNS)
            this.identification = this.model.get('cns').value.toString();
          else
            this.identification = response.idEpisode.toString();
        },
        error: (error) => {
          console.log(error);
          this.alertService.show('Erro inesperado', error, AlertType.error);
          this.isLoading = false;
          return;
        }
      });
    }
  }

  populatePatientData(idEpisode: number, idQueue: number) {
    this.episodeService.getPatientEpisodeAndHealthcareAgreementByEpisode(idEpisode, idQueue).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isFirstLoading = false;
          this.isLoading = false;
          return;
        }

        if (response.patient.idArrivalType != null)
          this.model.get('idArrivalType').setValue(response.patient.idArrivalType);

        if (response.patient.idOrigin != null)
          this.model.get('idOrigin').setValue(response.patient.idOrigin);

        this.datetimeStartRegister = response.episodeStruct.datetimeStartRegister;

        if (!response.hasPatient || !response.episodeStruct.idPatient) {
          const dialogRef = this.dialog.open(SearchModalComponent, {
            data: {
              masks: this.masks,
            },
          });
          dialogRef.afterClosed().subscribe({
            next: result => {
              if (result && Array.isArray(result.listPatient) && result.listPatient.length > 0) {
                this.isUnidentifiedPatient = false;
                this.mapModel(result.listPatient[0]);
              }
            },
            error: (error) => {
              console.log(error);
            }
          });
        }

        this.isUnidentifiedPatient = response.episodeStruct.isUnidentifiedPatient;

        this.model.get('unidentifiedPatientDescription').setValue(response.episodeStruct.unidentifiedPatientDescription);

        if (response.hasPatient) {
          this.mapModel(response.patient);

          this.scheduledPatientHealthcareAgreement = response.episodeStruct.scheduledPatientHealthcareAgreement;

          if (response.priorities && response.priorities.length > 0)
            this.model.get('listIdPriority').setValue(response.priorities);
        }

        this.isFirstLoading = false;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  mapModel(patient: PatientStruct) {    
    this.idPatient = patient.idPatient;
    this.model.get('patientName').setValue(patient.patientName);
    this.model.get('socialName').setValue(patient.socialName);
    this.patientSocialName = patient.socialName
    this.patientName = patient.patientName
    this.model.get('cpf').setValue(patient.cpf);
    this.model.get('birthDate').setValue(this.maskService.formatDateToString(patient.birthDate, false));
    this.model.get('idGender').setValue(patient.idGender ? patient.idGender.toString() : null);
    this.model.get('cns').setValue(patient.cns);
    this.model.get('phone1').setValue(this.maskService.formatPhoneToField(patient.phone1));
    this.model.get('phone2').setValue(this.maskService.formatPhoneToField(patient.phone2));
    this.model.get('rg').setValue(patient.rg);
    this.model.get('expeditionIssuer').setValue(patient.expeditionIssuer);
    this.model.get('expeditionDate').setValue(this.maskService.formatDateToString(patient.expeditionDate, false));
    this.model.get('motherName').setValue(patient.motherName);
    this.model.get('fatherName').setValue(patient.fatherName);
    this.model.get('idRace').setValue(patient.idRace ? patient.idRace.toString() : null);
    this.model.get('idEducation').setValue(patient.idEducation ? patient.idEducation.toString() : null);
    this.model.get('profession').setValue(patient.profession);
    this.model.get('idMaritalStatus').setValue(patient.idMaritalStatus ? patient.idMaritalStatus.toString() : null);
    this.model.get('birthCountry').setValue(patient.birthCountry);
    this.model.get('birthState').setValue(patient.birthState);
    this.model.get('birthCity').setValue(patient.birthCity);
    this.model.get('zipCode').setValue(patient.zipCode);
    this.model.get('street').setValue(patient.street);
    this.model.get('neighborhood').setValue(patient.neighborhood);
    this.model.get('city').setValue(patient.city);
    this.model.get('state').setValue(patient.state);
    this.model.get('country').setValue(patient.country);
    this.model.get('houseNumber').setValue(patient.houseNumber);
    this.model.get('apartmentNumber').setValue(patient.apartmentNumber);
    this.model.get('observation').setValue(patient.observation);
    this.model.get('idArrivalType').setValue(patient.idArrivalType);
    this.model.get('idOrigin').setValue(patient.idOrigin);
    this.model.get('companionName').setValue(patient.companionName);
    this.model.get('companionPhone').setValue(this.maskService.formatPhoneToField(patient.companionPhone));
    this.model.get('companionCpf').setValue(patient.companionCpf);
    this.model.get('companionParentage').setValue(patient.companionParentage);
    this.model.get('listIdPatientNecessity').setValue(patient.patientNecessities.map(c => c.idPatientNecessity.toString()));
    if (this.model.get('birthDate').value) {
      this.calcAgeService.getAge(this.model.get('birthDate').value).subscribe((calcAgeResponse: CalcAgeResponse) => {
        this.patientAge = `${calcAgeResponse.year}a ${calcAgeResponse.months}m ${calcAgeResponse.days}d`;
      });
    }

    if (this.listMetaData && this.listMetaData.length > 0 && this.idEpisode) {
      for (let item of this.listMetaData) {
        let valuePatient = patient.metaData.find(c => c.key == item.metaData.idMetaData);
        if (valuePatient)
          this.model.get(item.metaData.idMetaData.toString()).setValue(valuePatient.value);
        else
          this.model.get(item.metaData.idMetaData.toString()).setValue(null);
      }
    }

    this.listPatientHealthcareAgreementDetails = [];

    if (patient.listPatientHealthcareAgreementDetails && patient.listPatientHealthcareAgreementDetails.length > 0)
      this.listPatientHealthcareAgreementDetails = [...patient.listPatientHealthcareAgreementDetails];

    this.mapIsValid();
  }

  populateLookupSelect() {
    this.lookupService.getLookup().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.raceList = response.listRaces;
        this.genderList = response.listGenders;
        this.educationList = response.listEducations;
        this.maritalStatusList = response.listMaritalStatuses;
        this.listPatientNecessity = response.listPatientNecessities;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getArrivalTypeAndOrigin() {
    this.GroupOriginByOrchestratorService.getGroupOrigin().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        this.originList = response.listOrigin;
        this.arrivalTypeList = response.listArrivalType;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getAllPriorities() {
    this.priorityService.listPriority().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listOfPriorities = response.listPriority;
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  getDateTimeStart() {
    this.clockService.getDate().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.datetimeStartRegister = response.date;
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  getAllMetaData(idSector: number, listMetaDataStruct: MetaDataStruct[]) {
    this.getFrontDeskBySectorService.getMetaDataFrontDesk(idSector, listMetaDataStruct).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isFirstLoading = false;
          this.isLoading = false;
          return;
        }

        this.isWristbandActive = response.frontDesk.idWristbandDimensions ? true : false;
        this.wristbandWidth = response.frontDesk.customWristbandWidth ?? response.wristbandDimensions.width;
        this.wristbandHeight = response.frontDesk.customWristbandHeight ?? response.wristbandDimensions.height;
        
        if (response.frontDesk.idIdentification == FrontDeskIdentificationEnum.CNS)
          this.idIdentification = response.frontDesk.idIdentification;
        else 
          this.idIdentification = FrontDeskIdentificationEnum.Episodio;

        this.listMetaData = listMetaDataStruct.filter((item) => response.listMetaData.some(item2 => item.metaData.idMetaData == item2.idMetaData));

        if (this.listMetaData && this.listMetaData.length > 0) {
          for (let item of this.listMetaData) {
            if (item.metaData.isRequired)
              this.model.addControl(item.metaData.idMetaData.toString(), this.formBuilder.control('', [Validators.required]));
            else
              this.model.addControl(item.metaData.idMetaData.toString(), this.formBuilder.control(''));
          }
        }

        if (response.listIdMetaDataRequired != undefined && response.listIdMetaDataRequired.length > 0) {
          for (let item of response.listIdMetaDataRequired) {
            this.model.controls[item].setValidators([Validators.required]);
            this.model.controls[item].updateValueAndValidity();
          }
        }

        for (let item of response.listRequiredFields) {
          if (this.model.controls[item]){
            this.model.controls[item].setValidators([Validators.required]);
            this.model.controls[item].updateValueAndValidity();
          }
        }

        if (this.idEpisode != null)
          this.populatePatientData(this.idEpisode, this.idQueue);
        else {
          this.isFirstLoading = false;
          this.isLoading = false;
          this.newEpisode = true;
          this.getDateTimeStart();
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  public get metaDataTypeEnumResult(): typeof MetaDataTypeEnum {
    return MetaDataTypeEnum;
  }

  searchByCPF(cpf: string) {
    this.isLoading = true;
    this.isLoadingCpf = true;
    this.isLoading = true;

    let request: SearchPatientTotemRequest = new SearchPatientTotemRequest();
    request.cpf = cpf.replace(/[^0-9]/g, '').replace(' ', '');

    this.searchPatientService.searchPatientCpfCns(request).subscribe({
      next: (response) => {
        if (response.isError && response.errorCode == 1)
          this.alertService.show('Atenção! ', response.errorDescription, AlertType.warning);

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          this.isLoadingCpf = false;
          return;
        }

        this.mapModel(response.listPatient[0]);
        this.isLoading = false;
        this.isLoadingCpf = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  searchByCNS(cns: string) {
    this.isLoading = true;
    this.isLoadingCns = true;

    let request: SearchPatientTotemRequest = new SearchPatientTotemRequest();
    request.cns = cns.replace(/[^0-9]/g, '').replace(' ', '');

    this.searchPatientService.searchPatientCpfCns(request).subscribe({
      next: (response) => {
        if (response.isError && response.errorCode == 1) {
          this.alertService.show('Alerta', response.errorDescription, AlertType.warning);
          this.isLoading = false;
          this.isLoadingCns = false;
          return;
        }

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          this.isLoadingCns = false;
          return;
        }

        if (response.listPatient.length == 1)
          this.mapModel(response.listPatient[0]);
        else
          this.openModal(response.listPatient);

        this.isLoading = false;
        this.isLoadingCns = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  searchByCEP(cep: string) {
    this.isLoadingCep = true;
    this.isLoading = true;
    this.viaCepService.getAddressByCep(cep).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoadingCep = false;
          this.cepIsValid = false;
          this.isLoading = false;
          return;
        }

        if (response.cep == null)
          return this.alertService.show('Alerta', `Não foi encontrado nenhum endereço para o CEP ${cep}.`, AlertType.warning);

        this.model.get('zipCode').setValue(response.cep);
        this.model.get('street').setValue(response.logradouro);
        this.model.get('neighborhood').setValue(response.bairro);
        this.model.get('city').setValue(response.localidade);
        this.model.get('state').setValue(response.uf);
        this.model.get('country').setValue("Brasil");
        this.cepIsValid = true;
        this.countryIsPopulated = true;
        this.cityIsPopulated = true;
        this.stateIsPopulated = true;
        this.isLoadingCep = false;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.cepIsValid = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  private timeoutKeySearch: any = null;

  onKeySearchCPF(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;

    let cpf = event.target.value.replace(/[^0-9]+/g, '');

    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13 && cpf.length == 11)
        $this.searchByCPF(cpf.replace(/[^0-9]+/g, ''));
    },
      1000);
  }

  onKeySearchCNS(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13 && event.target.value.length > 5)
        $this.searchByCNS(event.target.value);
    },
      1000);
  }

  onKeySearchCEP(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13 && event.target.value.length > 5 && event.target.value !== '00000000')
        $this.searchByCEP(event.target.value);
    },
      1000);
  }

  onKeySearchAge(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13 && event.target.value.length == 10) {
        $this.calcAgeService.getAge(event.target.value).subscribe((calcAgeResponse: CalcAgeResponse) => {
          $this.patientAge = `${calcAgeResponse.year}a ${calcAgeResponse.months}m ${calcAgeResponse.days}d`;
        });
      }
    },
      1000);
  }

  openModal(patients: PatientStruct[]) {
    const dialogRef = this.dialog.open(SelectPatientModalComponent, {
      data: patients,
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result)
          this.mapModel(result);
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  changeIndigent(event: any) {
    if (event.checked) {
      this.model.get('unidentifiedPatientDescription').setValidators([Validators.required])
      this.model.get('unidentifiedPatientDescription').updateValueAndValidity();
    }
    else {
      this.model.get('unidentifiedPatientDescription').setValidators([])
      this.model.get('unidentifiedPatientDescription').updateValueAndValidity();
    }

    this.isUnidentifiedPatient = event.checked;
    this.showTissGuideButtonValidation = !this.isUnidentifiedPatient && this.showTissGuideButton;
  }

  populateDestinationSelect(idEpisode: number) {
    let fowardRequest = new FowardRequest();
    fowardRequest.idEpisode = idEpisode;
    fowardRequest.idRoom = parseInt(this.activatedRoute.snapshot.paramMap.get('idRoom'));

    if (!this.newEpisode) {
      this.fowardService.listDestination(fowardRequest).subscribe({
        next: (response) => {
          this.isFlowSequence = (response.flowSequence && response.flowSequence.length > 0);
          if (this.isFlowSequence)
            this.openFlowSequenceModal(idEpisode, response, fowardRequest.idRoom);
          else
            this.openModalForward(response, fowardRequest.idRoom, idEpisode);
        },
        error: (error) => {
          console.log(error);
          this.isLoading = false;
          this.alertService.show('Erro inesperado', error, AlertType.error);
        }
      });
    }
    else {
      this.fowardService.listEpisodeDestination(fowardRequest).subscribe({
        next: (response) => {
          this.openModalForward(response, fowardRequest.idRoom, idEpisode);
        },
        error: (error) => {
          console.log(error);
          this.isLoading = false;
          this.alertService.show('Erro inesperado', error, AlertType.error);
        }
      });
    }
  }

  openFlowSequenceModal(idEpisode: number, response: ForwardResponse, idRoom: number) {
    const dialogRef = this.dialog.open(FlowSequenceModalComponent, {
      data: {
        idEpisode: idEpisode,
        idQueue: response.idLastQueue,
        flowSequence: response.flowSequence,
        idOriginRoom: idRoom,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.fowardPatient){
          let roomName = response.flowSequence[0]?.destinyRoomName;
          this.VerifySignAndGetReport(idEpisode, roomName, null, response.idLastQueue);
          //this.CreateRelationQueueRoomAttendOnFrontDesk(idQueue);
        } else this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  openModalForward(response: ForwardResponse, idRoom: number, idEpisode: number) {
    if (response.isError) {
      this.alertService.show('Erro', response.errorDescription, AlertType.error);
      return;
    }
    else if (response.isAutomaticFoward) {
      this.VerifySignAndGetReport(idEpisode, response.automaticFoward?.split(":")[1], response.automaticFoward, response.idLastQueue);
      return;
    }

    if (response.actualModule == MenuModuleEnum.frontdesk) {
      const dialogRef = this.dialog.open(ForwardPatientModalComponent, {
        disableClose: true,
        data: {
          actualIdRoom: this.idRoom,
          idEpisode: idEpisode,
          idQueue: response.idLastQueue,
          listFoward: response.location,
          datetimeStartRegister: this.datetimeStartRegister,
          flowSequence: response.flowSequence,
          isFlowSequence: (response.flowSequence && response.flowSequence.length > 0),
        },
      });
      dialogRef.afterClosed().subscribe({
        next: (result) => {
          if (result && result.fowardPatient && result.idQueue && result.idQueue != 0 && result.roomName) 
              this.VerifySignAndGetReport(idEpisode, result.roomName, null, result.idQueue);
          
          else
            this.isLoading = false;
        },
        error: (error) => {
          console.log(error);
        }
      });
    }
    else
      this.VerifySignAndGetReport(idEpisode, null, null, response.idLastQueue);
  } 

  MapFrontDeskPatientReportRequest(idEpisode: number, roomName: string): FrontDeskPatientReportRequest {
    let reportRequest: FrontDeskPatientReportRequest = new FrontDeskPatientReportRequest();
    reportRequest.idSignatureProvider = null;
    reportRequest.signatureAuthentication = null;

    reportRequest.admissionRoomName = roomName;
    reportRequest.generateWristband = this.isWristbandActive;
    reportRequest.idEpisode = idEpisode;
    reportRequest.wristbandHeight = this.wristbandHeight;
    reportRequest.wristbandWidth = this.wristbandWidth;
    reportRequest.idIdentification = this.idIdentification;
    reportRequest.idSector = this.idSector;

    if (this.idIdentification == FrontDeskIdentificationEnum.CNS)
      reportRequest.identification = this.model.get('cns').value.toString();
    else 
      reportRequest.identification = idEpisode.toString();

    return reportRequest;    
  }

  VerifySignAndGetReport(idEpisode: number, roomName: string, automaticForward: string = null, idQueue: number = null){

    let reportRequest: FrontDeskPatientReportRequest = new FrontDeskPatientReportRequest();
    reportRequest = this.MapFrontDeskPatientReportRequest(idEpisode, roomName);

    if (this.sign)
      this.GetSignedReport(reportRequest, automaticForward, idQueue);

    else
      this.GetReport(reportRequest, automaticForward, idQueue);
  }

  GetSignedReport(reportRequest: FrontDeskPatientReportRequest, automaticForward: string = null, idQueue: number = null) {
    const dialogRef = this.dialog.open(SignatureCredentialsModalComponent, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result && result.sign) {
          reportRequest.idSignatureProvider = result.idSignatureProvider;
          reportRequest.signatureAuthentication = JSON.stringify(result.signatureAuthentication);
        }       

        this.GetReport(reportRequest, automaticForward, idQueue);

      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  GetReport(reportRequest: FrontDeskPatientReportRequest, automaticForward: string = null, idQueue: number = null) {
    this.isLoading = true;    

    this.reportService.getReportPDF(reportRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (this.HasReport(response))
          this.openReportViewSelectModal(response, automaticForward, idQueue);        
          
        else 
          this.CreateRelationQueueRoomAttendOnFrontDesk(idQueue, automaticForward);
        
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  } 

  HasReport(response: FrontdeskReportResponse): Boolean {    
    return Boolean(
      response.admissionReportPdf64 ||
      response.wristbandReportPdf64 ||
      response.allReportsConcat64 ||
      this.allPrivateBillingGuideReportStruct?.appointmentGuideReport64 ||
      this.allPrivateBillingGuideReportStruct?.admissionGuideReport64 ||
      this.allPrivateBillingGuideReportStruct?.feesGuideReport64 ||
      this.allPrivateBillingGuideReportStruct?.spSadtGuideReport64
    )
  }

  openReportViewSelectModal(response: FrontdeskReportResponse, automaticForward: string = null, idQueue: number = null) {
    let reportDialog = this.dialog.open(ReportViewSelectModalComponent, {
      data: {
        admissionReport: response.admissionReportPdf64,
        wristbandReport: response.wristbandReportPdf64,
        allReportsConcat: response.allReportsConcat64,
        sign: this.sign,
        appointmentGuideReport: this.allPrivateBillingGuideReportStruct?.appointmentGuideReport64,
        admissionGuideReport: this.allPrivateBillingGuideReportStruct?.admissionGuideReport64,
        feesGuideReport: this.allPrivateBillingGuideReportStruct?.feesGuideReport64,
        spSadtGuideReport: this.allPrivateBillingGuideReportStruct?.spSadtGuideReport64,
      },
    });

    reportDialog.afterClosed().subscribe({
      next: () => {
        this.CreateRelationQueueRoomAttendOnFrontDesk(idQueue, automaticForward);       
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.CreateRelationQueueRoomAttendOnFrontDesk(idQueue, automaticForward);     
      }
    });
  }

  CreateRelationQueueRoomAttendOnFrontDesk(idQueue: number = null, automaticForward: string = null) {

    if (automaticForward)
      this.alertService.show('Aviso', automaticForward, AlertType.warning);

    this.isLoading = true;

    if (this.isUpdateFrontDesk == true) {
      this.router.navigate(['/front-desk/patient-list', { idSector: this.idSector, idRoom: this.idRoom }]);
      this.isLoading = false;
      return;
    }

    let request: PostPutRelationQueueRoomAttendOnFrontDeskRequest = new PostPutRelationQueueRoomAttendOnFrontDeskRequest();
    request.idQueue = idQueue ? idQueue : 0;
    request.idRoomAttendance = this.idRoom ? this.idRoom : 0;

    this.relationQueueRoomAttendOnFrontDeskService.createRelationQueueRoomAttendOnFrontDesk(request).subscribe({
      next: (response) => {
        if (response.isError)
          this.alertService.show('Alerta', response.errorDescription, AlertType.warning);

        this.isAttended = true;
        this.router.navigate(['/front-desk/patient-list', { idSector: this.idSector, idRoom: this.idRoom }]);
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  validatePriorities(priorities: any) {
    let listIdPriority = priorities.value;

    if (listIdPriority.includes(PrioritiesStatusEnum.MaiorDe80Anos.valueOf()) ||
      listIdPriority.includes(PrioritiesStatusEnum.MaiorDe60Anos.valueOf())) {

      this.disallowedIdPriorities = [PrioritiesStatusEnum.MenorDe1Ano.valueOf()];

      this.model.get('listIdPriority').setValue(listIdPriority.filter(x => !this.disallowedIdPriorities.includes(x.idPriority)));
    }
    else if (listIdPriority.includes(PrioritiesStatusEnum.MenorDe1Ano.valueOf())) {
      this.disallowedIdPriorities = [
        PrioritiesStatusEnum.MaiorDe60Anos.valueOf(),
        PrioritiesStatusEnum.MaiorDe80Anos.valueOf()];

      this.model.get('listIdPriority').setValue(listIdPriority.filter(x => !this.disallowedIdPriorities.includes(x.idPriority)));
    }
    else
      this.disallowedIdPriorities = [];
  }

  ngOnDestroy() {
    if (this.websocketAttendanceUtilService.isConnect()) {
      this.websocketAttendanceUtilService.emitFinalizeAttendance(this.idRoom);

      if (this.isAttended == false && Number.isInteger(this.idQueue) && this.idQueue && this.idQueue > 0 && Number.isInteger(this.idEpisode) 
        && this.idEpisode && this.idEpisode > 0 && this.isUpdateFrontDesk == false && !this.isAttendanceRemoval) {
        this.updateStatusQueue(this.idQueue, this.idEpisode);
      }
    }
  }

  updateStatusQueue(idQueue: number, idEpisode: number) {
    this.isLoading = true;
    this.updateStatusQueueService.updateQueueToOldStatus(idQueue, idEpisode).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show("Error", response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
      }
    });
  }

  //Websocket
  startWebsocket() {
    if (!this.websocketAttendanceUtilService.isConnect())
      this.websocketAttendanceUtilService.connectwebsocketRoom(this.idRoom, this.idQueue, this.idEpisode, this, false, false, this.authService.getToken(), this.userLogin, this.userName);

    this.websocketAttendanceUtilService.setContext(this);
    this.websocketAttendanceUtilService.setFunctions(null, null, this.onRemoveAttendant);
  }

  openModalSearchByCPF() {
    const dialogRef = this.dialog.open(SearchModalComponent);
    dialogRef.afterClosed().subscribe({
      next: (response) => {
        if (response.response)
          this.mapModel(response.response);
        else if (response.cpf) this.model.get('cpf').setValue(response.cpf);
        else if (response.cns) this.model.get('cns').setValue(response.cns?.replaceAll(" ", ""));
      },
      error: (error) => {
        console.log(error);
      }
    });
    return dialogRef;
  }

  getListMetaData(idSector: number) {
    this.metaDataService.getAllMetaData().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
        }

        this.listMetaDataStruct = response.listMetaDataStruct;

        if (response.listMetaDataStruct)
          this.getAllMetaData(idSector, this.listMetaDataStruct)
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  mapIsValid() {
    if (this.model.get('zipCode').value != null)
      this.cepIsValid = true;

    if (this.model.get('country').value != null)
      this.countryIsPopulated = true;

    if (this.model.get('city').value != null)
      this.cityIsPopulated = true;

    if (this.model.get('state').value != null)
      this.stateIsPopulated = true;
  }

  verifyDate(controlId: number): void {
    const control = this.model.get(controlId.toString()); // Transforme o número em uma string
    const validationError = VerifyDate()(control);

    if (validationError)
      control.setErrors({ ...control.errors, ...validationError });
    else
      control.setErrors(null);
  }

  formatCEP(cep: string): string {
    if (cep && cep.length === 8)
      return cep.substring(0, 5) + '-' + cep.substring(5);

    return cep;
  }

  LeaveRoom() {
    if (this.showTissGuideButtonValidation) {
      let guideRequest = this.childComponent.getGuidRequest();
      if ((guideRequest.admissionGuideStruct ||
        guideRequest.appointmentGuideStruct ||
        guideRequest.feesGuideStruct ||
        guideRequest.spSadtGuideStruct)) {
        let reportDialog = this.dialog.open(TissGuideNotSavedConfirmModalComponent);
        reportDialog.afterClosed().subscribe({
          next: result => {
            if (result)
              this.router.navigate(['/front-desk/patient-list', { idSector: this.idSector, idRoom: this.idRoom }]);
          }
        });
        this.isLoading = false;
      }
      else
        this.router.navigate(['/front-desk/patient-list', { idSector: this.idSector, idRoom: this.idRoom }]);
    }
    else
      this.router.navigate(['/front-desk/patient-list', { idSector: this.idSector, idRoom: this.idRoom }]);
  }

  checkSigningResponsibility(): boolean {
    let token: UserLoginResponse = this.utilsService.getToken();
    return (token.listRole.map(x => x.idRole).includes(UserRoleEnum.requerer_assinatura_recepcao) && !token.isMaster);
  }

  onRemoveAttendant(thisParam, res: any){
    thisParam.isAttendanceRemoval = false;
    
    if(res.userLogin != this.userLogin){
      thisParam.isAttendanceRemoval = true;
      
      thisParam.websocketAttendanceUtilService.clearSocket();
      let ref = thisParam.dialog.open(AttendanceOccupiedRemoveModalComponent, { data: { ...res } });
      ref.afterClosed().subscribe(() => {
        thisParam.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          thisParam.router.navigate(['/front-desk/patient-list', { idSector: thisParam.idSector, idRoom: thisParam.idRoom }]));
      })
    }
  }
}