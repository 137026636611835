import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { Gender } from 'src/app/shared/services/responses/medical-record/lookup.response';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MedicalRecordLookupService } from 'src/app/shared/services/API/medical-record/lookup.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { MatDialog } from '@angular/material/dialog';
import { PriorityModel } from 'src/app/shared/services/models/totem/priority.model';
import { PatientStruct } from 'src/app/shared/services/structs/medical-record/patient.struct';
import { PatientRequest } from 'src/app/shared/services/requests/medical-record/patient.request';
import { PriorityService } from 'src/app/shared/services/API/totem/priority.service';
import { ViaCepService } from 'src/app/shared/services/API/integration/via-cep.service';
import { ClockService } from 'src/app/shared/services/API/orchestrator-queue/clock.service';
import { SearchPatientTotemRequest } from 'src/app/shared/services/requests/orchestrator-totem/search-patient-totem.request';
import { SearchPatientFrontdeskService } from 'src/app/shared/services/API/orchestrator-patient/search-patient-frontdesk.service';
import { SelectPatientModalComponent } from '../pre-register/select-patient-modal/select-patient-modal.component';
import { ClassificationData, UtilsClassificationService } from '../../utils.service';
import { TelephonePatientService } from 'src/app/shared/services/API/medical-record/telephone-patient.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { LastAutoTriageService } from 'src/app/shared/services/API/risk-classification/last-auto-triage.service';
import { SearchModalComponent } from '../pre-register/search-modal/search-modal.component';

@Component({
  selector: 'app-telephone-pre-register',
  templateUrl: './telephone-pre-register.component.html',
  styleUrls: ['./telephone-pre-register.component.css']
})
export class TelephonePreRegisterComponent implements OnInit {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private telephonepatientService: TelephonePatientService,
    private priorityService: PriorityService,
    private lookupService: MedicalRecordLookupService,
    private maskService: MaskService,
    private utilService: UtilService,
    public dialog: MatDialog,
    public router: Router,
    public viaCepService: ViaCepService,
    public clockService: ClockService,
    private searchPatientService: SearchPatientFrontdeskService,
    private getLastSelfTriageService: LastAutoTriageService,
    private utilClassification: UtilsClassificationService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.classification;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.telephone_classification;
  public model: UntypedFormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isLoadingCep: boolean;
  public isLoadingCpf: boolean;
  public isLoadingCns: boolean;
  public datetimeStartRegister: Date;
  public genderList: Gender[];
  public masks: Masks;
  public idPatient: number = null;
  public patientAge: string = null;
  public listOfPriorities: PriorityModel[];
  public patientOld: PatientStruct;

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.isLoading = true;
    this.isFirstLoading = true;
    this.model = this.formBuilder.group({
      patientName: ['', [Validators.required]],
      socialName: [''],
      cpf: [''],
      birthDate: ['', [Validators.required]],
      idGender: [''],
      cns: [''],
      phone1: [''],
      phone2: [''],
      zipCode: [''],
      street: [''],
      neighborhood: [''],
      city: [''],
      state: [''],
      country: [''],
      patientAge: [{ value: '', disabled: true }],
      houseNumber: [''],
      apartmentNumber: ['']
    });

    this.getAllPriorities();
    this.populateLookupSelect();
    this.populatePatientData();
    this.getDateTimeStart();
  }


  submit() {
    if (this.isLoading)
      return;

    if (this.model.invalid) {
      this.alertService.show('Erro', 'Você precisa informar todos os campos obrigatórios', AlertType.error)
      return;
    }

    let patient = new PatientRequest();
    let data: ClassificationData = new ClassificationData();
    if (this.patientOld && this.patientOld.idPatient > 0) {
      patient.idPatient = this.patientOld.idPatient;
      patient.idHealthUnitCreator = this.patientOld.idHealthUnitCreator;
      patient.patientName = this.patientOld.patientName;
      patient.cpf = this.patientOld.cpf;
      patient.birthDate = this.patientOld.birthDate;
      patient.datetimeInclusion = this.patientOld.datetimeInclusion;
      patient.cns = this.patientOld.cns;
      patient.rg = this.patientOld.rg;
      patient.expeditionIssuer = this.patientOld.expeditionIssuer;
      patient.expeditionDate = this.patientOld.expeditionDate;
      patient.socialName = this.patientOld.socialName;
      patient.motherName = this.patientOld.motherName;
      patient.fatherName = this.patientOld.fatherName;
      patient.idRace = this.patientOld.idRace;
      patient.idEducation = this.patientOld.idEducation;
      patient.profession = this.patientOld.profession;
      patient.idMaritalStatus = this.patientOld.idMaritalStatus;
      patient.idGender = this.patientOld.idGender;
      patient.zipCode = this.patientOld.zipCode;
      patient.street = this.patientOld.street;
      patient.neighborhood = this.patientOld.neighborhood;
      patient.city = this.patientOld.city;
      patient.state = this.patientOld.state;
      patient.country = this.patientOld.country;
      patient.phone1 = this.patientOld.phone1;
      patient.phone2 = this.patientOld.phone2;
      patient.houseNumber = this.patientOld.houseNumber;
      patient.apartmentNumber = this.patientOld.apartmentNumber;
      patient.birthCountry = this.patientOld.birthCountry;
      patient.birthState = this.patientOld.birthState;
      patient.birthCity = this.patientOld.birthCity;
      patient.metaData = this.patientOld.metaData;
      patient.priorities = this.patientOld.priorities;
      patient.observation = this.patientOld.observation;
      patient.companionName = this.patientOld.companionName;
      patient.companionPhone = this.patientOld.companionPhone;
      patient.companionCpf = this.patientOld.companionCpf;
      patient.companionParentage = this.patientOld.companionParentage;
    }
    
    if (Number.isInteger(this.idPatient))
      patient.idPatient = this.idPatient;

    patient.patientName = this.model.get('patientName').value;
    patient.socialName = this.model.get('socialName').value;
    patient.cpf = this.model.get('cpf').value ? this.model.get('cpf').value.replace(/[^0-9]+/g, '') : null;
    patient.birthDate = this.maskService.formatStringToDate(this.model.get('birthDate').value);
    patient.idGender = this.model.get('idGender').value ? this.model.get('idGender').value : null;
    patient.genderName = this.model.get('idGender').value ? this.genderList.find(c => c.idGender == this.model.get('idGender').value).genderName : null;
    patient.cns = this.model.get('cns').value ? this.model.get('cns').value.replace(/[^0-9]+/g, '').replace(" ", '') : null;
    patient.phone1 = this.maskService.formatPhoneToSave(this.model.get('phone1').value);
    patient.phone2 = this.maskService.formatPhoneToSave(this.model.get('phone2').value);
    patient.zipCode = this.model.get('zipCode').value;
    patient.street = this.model.get('street').value;
    patient.neighborhood = this.model.get('neighborhood').value;
    patient.city = this.model.get('city').value;
    patient.state = this.model.get('state').value;
    patient.country = this.model.get('country').value;
    patient.houseNumber = this.model.get('houseNumber').value ? this.model.get('houseNumber').value : null;
    patient.apartmentNumber = this.model.get('apartmentNumber').value;
    patient.datetimeStartRegister = this.datetimeStartRegister;

    this.isLoading = true;
    this.telephonepatientService.savePatient(patient).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        data.patientData = patient;
        data.isTelephoneClassification = true;
        data.birthDate = patient.birthDate;
        data.datetimeStartTriage = this.datetimeStartRegister;
        data.idPatient = response.idPatient;
        data.idGender = patient.idGender;
        this.utilClassification.updateClassificationData(data);
        
        this.getSelfTriage(data);
      }
    });
  }

  getSelfTriage(data: ClassificationData) {
    this.getLastSelfTriageService.getAutoTriage(data.patientData.cpf).subscribe({
      next: (response) => {
        data.selfTriage = response;
        this.utilClassification.updateClassificationData(data);

        this.alertService.show('Sucesso', "Dados atualizados com sucesso", AlertType.success);
        this.router.navigate(['/classification/protocol-select']);
      },
      error: (error) => {
        console.log(error);
      }
    });
  };

  populatePatientData() {
    const dialogRef = this.dialog.open(SearchModalComponent, {
      data: {
        masks: this.masks,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.response && result.response.idPatient)
          this.mapModel(result.response)
      }
    });
  }

  mapModel(patient: PatientStruct) {
    this.idPatient = patient.idPatient;
    this.patientOld = patient;
    this.model.get('patientName').setValue(patient.patientName);
    this.model.get('socialName').setValue(patient.socialName);
    this.model.get('cpf').setValue(patient.cpf);
    let birthDate = this.maskService.formatDateToString(patient.birthDate, false)
    this.model.get('birthDate').setValue(birthDate);
    this.model.get('idGender').setValue(patient.idGender ? patient.idGender : null);
    this.model.get('cns').setValue(patient.cns);
    this.model.get('phone1').setValue(this.maskService.formatPhoneToField(patient.phone1));
    this.model.get('phone2').setValue(this.maskService.formatPhoneToField(patient.phone2));
    this.model.get('zipCode').setValue(patient.zipCode);
    this.model.get('street').setValue(patient.street);
    this.model.get('neighborhood').setValue(patient.neighborhood);
    this.model.get('city').setValue(patient.city);
    this.model.get('state').setValue(patient.state);
    this.model.get('country').setValue(patient.country);
    this.model.get('houseNumber').setValue(patient.houseNumber);
    this.model.get('apartmentNumber').setValue(patient.apartmentNumber);

    if (this.model.get('birthDate').value) {
      let patientAge = this.utilService.getAge(this.model.get('birthDate').value);
      this.model.get('patientAge').setValue(`${patientAge.years}a ${patientAge.months}m ${patientAge.days}d`);
    }
  }

  populateLookupSelect() {
    this.lookupService.getLookup().subscribe({
      next: (response) => {
        this.isFirstLoading = false;
        this.isLoading = false;
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.genderList = response.listGenders;
      },
      error: (error) => {
        this.isFirstLoading = false;
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getAllPriorities() {
    this.priorityService.listPriority().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listOfPriorities = response.listPriority;
      }
    });
  }

  getDateTimeStart() {
    this.clockService.getDate().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.datetimeStartRegister = response.date;
      }
    });
  }

  searchByCPF(cpf: string) {
    this.isLoading = true;
    this.isLoadingCpf = true;
    this.isLoading = true;
    let request: SearchPatientTotemRequest = new SearchPatientTotemRequest();
    request.cpf = cpf.replace(/[^0-9]/g, '').replace(' ', '');
    this.searchPatientService.searchPatientCpfCns(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          this.isLoadingCpf = false;
          return;
        }

        this.mapModel(response.listPatient[0]);
        this.isLoading = false;
        this.isLoadingCpf = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  searchByCNS(cns: string) {
    this.isLoading = true;
    this.isLoadingCns = true;
    let request: SearchPatientTotemRequest = new SearchPatientTotemRequest();
    request.cns = cns.replace(/[^0-9]/g, '').replace(' ', '');
    this.searchPatientService.searchPatientCpfCns(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          this.isLoadingCns = false;
          return;
        }

        if (response.listPatient.length == 1)
          this.mapModel(response.listPatient[0]);
        else
          this.openModal(response.listPatient);

        this.isLoading = false;
        this.isLoadingCns = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  searchByCEP(cep: string) {
    this.isLoadingCep = true;
    this.isLoading = true;
    this.viaCepService.getAddressByCep(cep).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoadingCep = false;
          this.isLoading = false;
          return;
        }
        this.model.get('zipCode').setValue(response.cep);
        this.model.get('street').setValue(response.logradouro);
        this.model.get('neighborhood').setValue(response.bairro);
        this.model.get('city').setValue(response.localidade);
        this.model.get('state').setValue(response.uf);
        this.model.get('country').setValue("Brasil");
        this.isLoadingCep = false;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  private timeoutKeySearch: any = null;

  onKeySearchCPF(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;

    let cpf = event.target.value.replace(/[^0-9]+/g, '');

    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13 && cpf.length == 11)
        $this.searchByCPF(cpf.replace(/[^0-9]+/g, ''));
    }, 1000);
  }

  onKeySearchCNS(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13 && event.target.value.length > 5)
        $this.searchByCNS(event.target.value);
    }, 1000);
  }

  onKeySearchCEP(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13 && event.target.value.length > 5)
        $this.searchByCEP(event.target.value);
    }, 1000);
  }

  onKeySearchAge(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13 && event.target.value.length > 5) {
        let patientAge = $this.utilService.getAge(event.target.value);
        $this.model.get('patientAge').setValue(`${patientAge.years}a ${patientAge.months}m ${patientAge.days}d`);
      }
    }, 1000);
  }

  openModal(patients: PatientStruct[]) {
    const dialogRef = this.dialog.open(SelectPatientModalComponent, {
      data: patients,
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result)
          this.mapModel(result);
      }
    });
  }
}