import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertModalComponent } from 'src/app/shared/components/alert-modal/alert-modal.component';
import { telefoneValidation, VerifyPhoneNumber } from 'src/app/shared/custom-validators/phone.validator';
import { HealthcareAgreementService } from 'src/app/shared/services/API/private-billing/healthcare-agreement.service';
import { LookupService } from 'src/app/shared/services/API/private-billing/lookup.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { TerminologyOrigin } from 'src/app/shared/services/models/private-billing/terminology-origin.model';
import { TissXml } from 'src/app/shared/services/models/private-billing/tiss-xml.model';
import { HealthcareAgreementRequest } from 'src/app/shared/services/requests/private-billing/healthcare-agreement.request';
import { LookupResponse } from 'src/app/shared/services/responses/private-billing/lookup.response';
import { HealthcareAgreementStruct } from 'src/app/shared/services/structs/private-billing/healthcare-agreement.struct';

@Component({
  selector: 'app-basic-data',
  templateUrl: './basic-data.component.html',
  styleUrls: ['./basic-data.component.css']
})
export class BasicDataComponent implements OnInit, AfterViewInit {
  constructor(
    public dialog: MatDialog,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer,
    private maskService: MaskService,
    private healthcareAgreementService: HealthcareAgreementService,
    private privateBillingLookupService: LookupService,
  ) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Output() healthcareAgreementFilled = new EventEmitter<{
    isValid: boolean;
    nameHealthcareAgreement: string;
    idHealthcareAgreement: number;
    ansRegistrationNumber: string;
    phone: string;
    isUpdate: boolean;
  }>();

  @Input() healthcareAgreementStruct: HealthcareAgreementStruct;
  @Input() isUpdate: boolean;
  @ViewChild('fileInput') fileInput: ElementRef;

  public model: FormGroup;
  public masks: Masks = this.maskService.getMasks();

  public oldSrc: any;
  public newSrc: any;
  public defaultImage: boolean = true;

  public haveLogo: boolean = false;
  public isLoading: boolean = false;
  public isLogoUploaded: boolean = false;

  public uploadedLogo: string = null;
  public listTissXml: TissXml[] = [];
  public listTerminologyOrigin: TerminologyOrigin[] = [];

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      nameHealthcareAgreement: ['', [Validators.required]],
      ansRegistrationNumber: ['000000', [this.maxLengthValidator(6)]],
      idGuide: [null],
      requiredfile: [''],
      phone: ['', telefoneValidation()],
      idTissXml: [null, Validators.required],
      nextBatchNumber: [1, [Validators.required]],
      nextGuideNumber: [1, [Validators.required]],
      idTerminologyOrigin: [null, [Validators.required]],
      privateHealthcare: [false],
    });

    this.populateLookupSelect();

    if (this.healthcareAgreementStruct != null && this.healthcareAgreementStruct.logoName != null) {
      this.oldSrc = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${this.healthcareAgreementStruct.logoString64}`);
      this.haveLogo = true;
    }
    else
      this.newSrc = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64,`);

    if (this.isUpdate)
      this.mapperData();
    else
      this.updateToggle(this.model.get('privateHealthcare').value);
  }

  ngAfterViewInit(): void {
    const fileInput = this.fileInput.nativeElement;

    fileInput.addEventListener('change', () => {
      const file: File = fileInput.files[0];

      const reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);

      if (file)
        reader.readAsDataURL(file);
    });
  }

  onANSKeyup() {
    if (this.model.get('ansRegistrationNumber') && this.model.get('ansRegistrationNumber').value != null) {
      let grossValue = this.model.get('ansRegistrationNumber').value.replace(/[^\d]/g, '');
      let value = parseInt('0' + grossValue);
      let newValue = this.pad(value, 6);
      this.model.get('ansRegistrationNumber').setValue(newValue, { emitEvent: false });
    }
  }

  _handleReaderLoaded(e) {
    let reader = e.target;
    var resultReader = reader.result;
    var stringArray = resultReader.split("base64,", 2);
    this.isLoading = false;
    this.defaultImage = false;
    this.oldSrc = false;
    this.newSrc = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${stringArray[1]}`);
    this.uploadedLogo = stringArray[1];
    this.isLogoUploaded = true;
    this.haveLogo = true;
  }

  triggerFileInputClick() {
    if (this.fileInput)
      this.fileInput.nativeElement.click();
  }

  submit() {
    this.isLoading = true;
    
    if (this.model.invalid) {
      this.alertService.show('Erro', "Preencha todos os campos obrigatórios.", AlertType.error);
      this.isLoading = false;
      return;
    }

    const ansRegistrationNumber = this.model.get('ansRegistrationNumber').value;
    if (this.model.get('privateHealthcare').value === false && /^0+$/.test(ansRegistrationNumber)) {
      this.alertService.show('Erro', `O campo precisa ser diferente de ${ansRegistrationNumber}.`, AlertType.error);
      this.isLoading = false;
      return;
    }

    let request: HealthcareAgreementRequest = new HealthcareAgreementRequest();
    request.ansRegistrationNumber = this.model.get('ansRegistrationNumber').value;
    request.nameHealthcareAgreement = this.model.get('nameHealthcareAgreement').value;
    request.idGuide = parseInt(this.model.get('idGuide').value);
    request.logoString64 = this.uploadedLogo;
    request.phone = this.maskService.formatPhoneToSave(this.model.get('phone').value);
    request.isActive = true;
    request.idTissXml = this.model.get('idTissXml').value;
    request.nextBatchNumber = this.model.get('nextBatchNumber').value?.toString();
    request.nextGuideNumber = this.model.get('nextGuideNumber').value?.toString();
    request.idTerminologyOrigin = this.model.get('idTerminologyOrigin').value;
    request.privateHealthcare = this.model.get('privateHealthcare').value;
    request.isLogoUploaded = this.isLogoUploaded;

    if (this.isUpdate)      
      this.put(request, this.healthcareAgreementStruct.idHealthcareAgreement);
    
    else
      this.openAlertModal(request);
  }

  mapperData() {
    this.model.get('ansRegistrationNumber').setValue(this.healthcareAgreementStruct.ansRegistrationNumber);
    this.model.get('nameHealthcareAgreement').setValue(this.healthcareAgreementStruct.nameHealthcareAgreement);
    this.model.get('nameHealthcareAgreement').disable();
    this.model.get('idGuide').setValue(this.healthcareAgreementStruct.idGuide);
    this.model.get('phone').setValue(this.maskService.formatPhoneToField(this.healthcareAgreementStruct.phone));
    this.model.get('idTissXml').setValue(this.healthcareAgreementStruct.idTissXml);
    this.model.get('nextBatchNumber').setValue(this.healthcareAgreementStruct.nextBatchNumber);
    this.model.get('nextGuideNumber').setValue(this.healthcareAgreementStruct.nextGuideNumber);
    this.model.get('idTerminologyOrigin').setValue(this.healthcareAgreementStruct.idTerminologyOrigin);
    this.model.get('privateHealthcare').setValue(this.healthcareAgreementStruct.privateHealthcare);

    this.model.get('nextBatchNumber').disable();
    this.model.get('nextGuideNumber').disable();

    this.healthcareAgreementFilled.emit({
      isValid: this.model.valid,
      nameHealthcareAgreement: this.healthcareAgreementStruct.nameHealthcareAgreement,
      idHealthcareAgreement: this.healthcareAgreementStruct.idHealthcareAgreement,
      ansRegistrationNumber: this.healthcareAgreementStruct.ansRegistrationNumber,
      phone: this.healthcareAgreementStruct.phone,
      isUpdate: true
    });
  }

  post(request: HealthcareAgreementRequest) {
    this.healthcareAgreementService.Post(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.healthcareAgreementFilled.emit({
          isValid: this.model.valid,
          nameHealthcareAgreement: request.nameHealthcareAgreement,
          idHealthcareAgreement: response.healthcareAgreementStruct.idHealthcareAgreement,
          ansRegistrationNumber: response.healthcareAgreementStruct.ansRegistrationNumber,
          phone: response.healthcareAgreementStruct.phone,
          isUpdate: true
        });

        this.alertSave("Convênio criado com sucesso!");
        this.isLoading = false;
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  openAlertModal(request: HealthcareAgreementRequest) {
    const dialogRef = this.dialog.open(AlertModalComponent, {
      data: {
        isTwoButtonsModal: true,
        title: 'Atenção!',
        description: 'Você tem certeza que deseja criar o convênio? Não será possível alterar algumas informações futuramente.',
        hasImage: true
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.confirm)
          this.post(request);
        else
          this.isLoading = false;
      }
    });
  }

  put(request: HealthcareAgreementRequest, idHealthcareAgreement: number) {
    this.healthcareAgreementService.Put(request, idHealthcareAgreement).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.healthcareAgreementFilled.emit({
          isValid: this.model.valid,
          nameHealthcareAgreement: request.nameHealthcareAgreement,
          idHealthcareAgreement: idHealthcareAgreement,
          ansRegistrationNumber: request.ansRegistrationNumber,
          phone: request.phone,
          isUpdate: true
        });

        this.alertSave("Convênio atualizado com sucesso!");
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  maxLengthValidator(maxLength: number) {
    return (control: { value: string | any[] }) => {
      if (control.value && control.value.length > maxLength)
        return { 'maxlength': true };

      return null;
    };
  }

  private alertSave(text: string) {
    this.alertService.show('Sucesso', text, AlertType.success);
    this.isLoading = false;
  }

  private throwException(error: string) {
    console.log(error);
    this.alertService.show('Erro inesperado', error, AlertType.error);
    this.isLoading = false;
  }

  pad(str: any, max: number): string {
    str = str.toString();
    while (str.length < max) {
      str = '0' + str;
    }
    return str.substr(0, max);
  }

  populateLookupSelect() {
    this.privateBillingLookupService.listAllLookups().subscribe({
      next: (response: LookupResponse) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.listTissXml = response.listTissXml;
        this.listTerminologyOrigin = response.listTerminologyOrigin;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  updateToggle(toggleValue: any) {
    this.model.get('privateHealthcare').setValue(toggleValue.checked);
    if (toggleValue.checked) {
      this.model.get('idTissXml').setValidators(null);
      this.model.get('idTissXml').updateValueAndValidity();
      this.model.get('idTerminologyOrigin').setValidators(null);
      this.model.get('idTerminologyOrigin').updateValueAndValidity();
      this.model.get('ansRegistrationNumber').setValidators([this.maxLengthValidator(6)]);
      if (this.model.get('ansRegistrationNumber').value == null)
        this.model.get('ansRegistrationNumber').setValue('000000');
    }
    else {
      this.model.get('idTissXml').setValidators([Validators.required]);
      this.model.get('idTissXml').updateValueAndValidity();
      this.model.get('idTerminologyOrigin').setValidators([Validators.required]);
      this.model.get('idTerminologyOrigin').updateValueAndValidity();
      this.model.get('ansRegistrationNumber').setValidators([Validators.required, this.maxLengthValidator(6)]);
      if (/^0+$/.test(this.model.get('ansRegistrationNumber').value))
        this.model.get('ansRegistrationNumber').setValue(null);
    }

    this.model.get('ansRegistrationNumber').updateValueAndValidity();
  }

  inputNumberMinOne(formName: string) {
    let value = this.model.get(formName).value ? this.model.get(formName).value : '';
    if (value) {
      value = value.replace(/[^0-9]/g, '');
      value = value.replace(/(\..*)\./g, '$1');
      if(parseInt(value) == 0){
        this.alertService.show('Atenção', 'O valor não pode ser menor que 1!', AlertType.warning);
        value = '1';
      }
      this.model.get(formName).setValue(value);
    }
  }
}