<form class="form" [formGroup]="model">
    <div class="row">
        <div class="col-12 col-sm-4 col-md-5">
        </div>
        <div class="col-12 col-sm-4 col-md-5">
        </div>
        <div class="col-12 col-sm-4 col-md-2">
            <button style="display: none;" mat-flat-button type="button" (click)="previous()" color="accent"
                class=" btn-block">
                <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Voltar
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-6">
            <div class="row">
                <div class="col-md-12 row-escolha-triagem">
                    <div class="descricao">
                        <span [matTooltip]="walkDescription" [matTooltipPosition]="'above'" class="texto">
                            Anda
                            <a><img src="assets/images/icode-radio-ask.svg" /></a>
                        </span>
                    </div>
                    <input [value]="true" class="radio-sim" type="radio" id="radio-anda-sim"
                        formControlName="radio-anda" name="radio-anda">
                    <label (click)="setValueAndaSim()" class="label-radio-sim" for="radio-anda-sim"><img
                            src="assets/images/icone-radio-sim.svg" /></label>
                    <input [value]="false" class="radio-nao" type="radio" id="radio-anda-nao"
                        formControlName="radio-anda" name="radio-anda">
                    <label (click)="setValueAndaNao()" class="label-radio-nao" for="radio-anda-nao"><img
                            src="assets/images/icone-radio-nao.svg" /></label>
                </div>
                <div class="col-md-12 row-escolha-triagem">
                    <div class="descricao">
                        <span [matTooltip]="breathAfterObstructionDescription" [matTooltipPosition]="'above'" class="texto">
                            Respira só após abertura de vias aéreas
                            <a><img src="assets/images/icode-radio-ask.svg" /></a>
                        </span>
                    </div>
                    <input [value]="true" class="radio-sim" type="radio" id="radio-respira-sim"
                        formControlName="radio-respira" name="radio-respira">
                    <label (click)="setValueRespiraSim()" class="label-radio-sim" for="radio-respira-sim"><img
                            src="assets/images/icone-radio-sim.svg" /></label>
                    <input [value]="false" class="radio-nao" type="radio" id="radio-respira-nao"
                        formControlName="radio-respira" name="radio-respira">
                    <label (click)="setValueRespiraNao()" class="label-radio-nao" for="radio-respira-nao"><img
                            src="assets/images/icone-radio-nao.svg" /></label>
                </div>
                <div class="col-md-12 row-escolha-triagem">
                    <div class="descricao">
                        <span matTooltip="" [matTooltipPosition]="'above'" class="texto">
                            Respira espontaneamente
                            <a><img src="assets/images/icode-radio-ask.svg" /></a>
                        </span>
                    </div>
                    <input [value]="true" class="radio-sim" type="radio" id="radio-respira-espontaneamente-sim"
                        formControlName="radio-respira-espontaneamente" name="radio-respira-espontaneamente">
                    <label (click)="setValueRespiraEspontaneamenteSim()" class="label-radio-sim" for="radio-respira-espontaneamente-sim"><img
                            src="assets/images/icone-radio-sim.svg" /></label>
                    <input [value]="false" class="radio-nao" type="radio" id="radio-respira-espontaneamente-nao"
                        formControlName="radio-respira-espontaneamente" name="radio-respira-espontaneamente">
                    <label (click)="setValueRespiraEspontaneamenteNao()" class="label-radio-nao" for="radio-respira-espontaneamente-nao"><img
                            src="assets/images/icone-radio-nao.svg" /></label>
                </div>
            </div>
        </div>
    </div>
</form>