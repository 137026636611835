import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { DeviceTypeEnum } from 'src/app/shared/enum/device-type.enum';
import { DiscriminatorTypeEnum } from 'src/app/shared/enum/discriminator-type.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MeasurerService } from 'src/app/shared/services/API/measurer/measurer.service';
import { CounselingService } from 'src/app/shared/services/API/risk-classification/counseling.service';
import { DiscriminatorService } from 'src/app/shared/services/API/risk-classification/discriminator.service';
import { LastAutoTriageService } from 'src/app/shared/services/API/risk-classification/last-auto-triage.service';
import { PunctuationSystemService } from 'src/app/shared/services/API/risk-classification/PunctuationSystem.service';
import { DiscriminatorResponse } from 'src/app/shared/services/responses/risk-classification/discriminator.response';
import { SelfTriageResponse } from 'src/app/shared/services/responses/self-triage/self-triage.response';
import { RoomAccessUtilService } from 'src/app/shared/services/room-access-util.service';
import { DiscriminatorStruct } from 'src/app/shared/services/structs/risk-classification/discriminator.struct';
import { ClassificationData, UtilsClassificationService } from '../../utils.service';
import { ChangeFlowchartModalComponent } from './change-flowchart-modal/change-flowchart-modal.component';
import { MultipleVictimPrimaryAssessmentComponent } from './multiple-victim-primary-assessment/multiple-victim-primary-assessment.component';
import { MultipleVictimSecondaryAssessmentComponent } from './multiple-victim-secondary-assessment/multiple-victim-secondary-assessment.component';
import { ProtocolV1Component } from './protocol-v1/protocol-v1.component';
import { ProtocolV2Component } from './protocol-v2/protocol-v2.component';
import { SelfTriageResumeModalComponent } from './self-triage-resume-modal/self-triage-resume-modal.component';
import { UtilsMeasurerService } from './protocol-v2/utils-measurer.service';
import { WebsocketAttendanceUtilService } from 'src/app/shared/services/websocket-util/websocket-attendance-util.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AttendanceOccupiedRemoveModalComponent } from 'src/app/shared/components/attendance-occupied-remove-modal/attendance-occupied-remove-modal.component';

@Component({
  selector: 'app-flowchart',
  templateUrl: './flowchart.component.html',
  styleUrls: ['./flowchart.component.css'],
})
export class FlowchartComponent implements OnInit {

  @ViewChild(ProtocolV1Component) childV1: ProtocolV1Component;
  @ViewChild(ProtocolV2Component) childV2: ProtocolV2Component;
  @ViewChild(MultipleVictimPrimaryAssessmentComponent) multipleVictimPrimaryAssessment: MultipleVictimPrimaryAssessmentComponent;
  @ViewChild(MultipleVictimSecondaryAssessmentComponent) multipleVictimSecondaryAssessment: MultipleVictimSecondaryAssessmentComponent;

  constructor(private alertService: AlertService,
    private dialog: MatDialog,
    private utilService: UtilsClassificationService,
    public router: Router,
    private formBuilder: UntypedFormBuilder,
    private discriminatorService: DiscriminatorService,
    private getLastSelfTriageService: LastAutoTriageService,
    private measurerService: MeasurerService,
    private measurerUtilsService: UtilsMeasurerService,
    private punctuationService: PunctuationSystemService,
    private roomAccessUtilService: RoomAccessUtilService,
    private counselingService: CounselingService,
    private activatedRoute: ActivatedRoute,
    private websocketAttendanceUtilService: WebsocketAttendanceUtilService,
    private authService: AuthService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.classification;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.classification_pre_registration;
  public isFirstLoading: boolean = true;
  public discriminatorResponse: DiscriminatorResponse;
  public idEpisode: number;
  public idRoom: number;
  public idSector: number;
  public idQueue: number = null;
  public flowchartName: string;
  public outOfOrder: boolean;
  public discriminators: any[] = [];
  public discriminatorRealValues: any[] = [];
  public painColorCode: string[] = [];
  private data: ClassificationData;
  public selfTriage: SelfTriageResponse;
  public isSelfTriage: boolean;
  public multiplePrimaryFlowchart: number = 2;
  public multipleSecondaryFlowchart: number = 3;
  public isNext: boolean = false;
  public userLogin: string;
  public userName: string;
  public isAttendanceRemoval: boolean = false;

  ngOnInit(): void {
    this.userLogin = this.authService.getTokenMenu().login;
    this.userName = this.authService.getTokenMenu().userName;
    this.data = this.utilService.getClassificationData();
    this.data.perfusaoCapilar = null;
    this.utilService.updateClassificationData(this.data);

    this.idEpisode = this.data.idEpisode;
    this.idRoom = parseInt(this.activatedRoute.snapshot.paramMap.get('idRoom'));
    this.idQueue = parseInt(this.activatedRoute.snapshot.paramMap.get('idQueue'));
    this.idSector = parseInt(this.activatedRoute.snapshot.paramMap.get('idSector'));

    if (!this.data.idFlowchart) {
      this.isNext = false;
      this.router.navigate(['/classification/flowchart-select', { idEpisode: this.idEpisode, idRoom: this.idRoom, idQueue: this.idQueue }]);
      return;
    }

    if (this.data.discriminatorValues) {
      this.discriminators = this.data.discriminatorValues;
      this.painColorCode[0] = this.data.painColorCode;
    }

    this.flowchartName = this.data.flowchartName;
    this.outOfOrder = this.data.outOfOrder;
    this.isSelfTriage = this.data.selfTriage && this.data.selfTriage.listQuestionAnswer && this.data.selfTriage.listQuestionAnswer.length > 0;

    this.getDiscriminator(this.data.idFlowchart, this.data.birthDate);

    if (Number.isInteger(this.idQueue) && this.idQueue && this.idQueue > 0 && Number.isInteger(this.idEpisode) && this.idEpisode && this.idEpisode > 0)
      this.startWebsocket();
  }

  //Websocket
  startWebsocket() {
    if (!this.websocketAttendanceUtilService.isConnect())
      this.websocketAttendanceUtilService.connectwebsocketRoom(this.idRoom, this.idQueue, this.idEpisode, this, true, false, this.authService.getToken(), this.userLogin, this.userName);

    this.websocketAttendanceUtilService.setContext(this);
    this.websocketAttendanceUtilService.setFunctions(null, null, this.onRemoveAttendant);         
  }

  onRemoveAttendant(thisParam, res: any){
    thisParam.isAttendanceRemoval = false;
    
    if(res.userLogin != thisParam.userLogin){
      thisParam.isAttendanceRemoval = true;
      
      thisParam.websocketAttendanceUtilService.clearSocket();
      let ref = thisParam.dialog.open(AttendanceOccupiedRemoveModalComponent, { data: { ...res } });
      ref.afterClosed().subscribe(() => {
        thisParam.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          thisParam.router.navigate(['/classification/classification-patient-list', { idSector: thisParam.idSector, idRoom: thisParam.idRoom }]));
      })
    }
  }

  getDiscriminator(idFlowchart: number, birthDate: Date) {
    this.discriminatorService.getDiscriminator(idFlowchart, birthDate, null).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.isFirstLoading = false;
      this.discriminatorResponse = response;
    });
  }

  stopMesurer() {
    this.measurerService.deleteBloodPressure(this.data.devicePressure?.uri).subscribe();
    this.measurerService.deleteGlucometer(this.data.deviceGlucometer?.uri).subscribe();
    this.measurerService.deleteOximeter(this.data.deviceOximeter?.uri).subscribe();
    this.measurerService.deleteThermometer(this.data.deviceThermometer?.uri).subscribe();
  }

  selectDiscriminator(event: any) {

    let data = this.utilService.getClassificationData();

    if (data.idDeviceType == DeviceTypeEnum.Trius || data.idDeviceType == DeviceTypeEnum.Berco || data.idDeviceType == DeviceTypeEnum.HL7) {
      this.stopMesurer();
    }

    if (DiscriminatorTypeEnum.PerfusaoCapilar == event.item.idDiscriminatorType) {
      data.perfusaoCapilar = true;
    }
    data.isPatientWhite = false;
    data.discriminator = event.item;
    data.discriminatorValues = this.discriminators;
    data.discriminatorAllValues = event.discriminatorsValues;
    data.discriminatorSelectionMotive = event.motive ? event.motive : null;

    if (event.item.idDiscriminatorType == DiscriminatorTypeEnum.LastDiscriminator) {
      this.openModalChoseOtherFlowchart(data);
      return;
    }
    if (data.isTelephoneClassification) {
      data.priority = this.discriminatorResponse.priorityColor.find(c => c.idPriorityColor == data.discriminator.idPriorityColor);
      this.getCounseling(data);
    } else if (data.isPunctuationSystem) {
      let values: string[] = this.discriminators.map(c => c ? c.toString() : null);
      this.punctuationService.getResult(data.idFlowchart, values).subscribe({
        next: (response) => {
          if (response.isError) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            return;
          }
          data.discriminator.discriminatorName = "TRTS* ";
          if (response.punctuation.idPunctuationScale == 4) {
            data.discriminator.discriminatorName += "1 à 10."
          } else if (response.punctuation.idPunctuationScale == 5) {
            data.discriminator.discriminatorName += "= 11."
          } else if (response.punctuation.idPunctuationScale == 6) {
            data.discriminator.discriminatorName += "= 12."
          } else if (response.punctuation.idPunctuationScale == 7) {
            data.discriminator.discriminatorName += "= 0."
          }
          data.discriminator.idPriorityColor = response.punctuation.idPriority;
          data.priority = this.discriminatorResponse.priorityColor.find(c => c.idPriorityColor == data.discriminator.idPriorityColor);
          this.utilService.updateClassificationData(data);
          this.isNext = true;
          this.router.navigate(['/classification/forward', { idEpisode: this.idEpisode, idRoom: this.idRoom, idQueue: this.idQueue }]);
        }
      });
    } else {
      data.priority = this.discriminatorResponse.priorityColor.find(c => c.idPriorityColor == data.discriminator.idPriorityColor);
      this.utilService.updateClassificationData(data);
      this.isNext = true;
      this.router.navigate(['/classification/forward', { idEpisode: this.idEpisode, idRoom: this.idRoom, idQueue: this.idQueue }]);
    }
  }

  openSelfTriageResumeModal() {
    let data: any = {};
    data = this.data.selfTriage;
    const dialogRef = this.dialog.open(SelfTriageResumeModalComponent, { data });
  }

  openModalChoseOtherFlowchart(data: ClassificationData) {
    const dialogRef = this.dialog.open(ChangeFlowchartModalComponent, { disableClose: true });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.answer) {
        data.lastFlowchart = data.flowchartName;
        data.idLastFlowchart = data.idFlowchart;
        data.flowchartName = null;
        data.idFlowchart = null;
        data.discriminatorAllValues = [];
        data.discriminatorsPersist = [];
        this.utilService.updateClassificationData(data);
        this.isNext = false;
        this.router.navigate(['/classification/flowchart-select', { idEpisode: this.idEpisode, idRoom: this.idRoom, idQueue: this.idQueue }]);
      } else {
        data.priority = this.discriminatorResponse.priorityColor.find(c => c.idPriorityColor == data.discriminator.idPriorityColor);
        this.isNext = true;
        this.utilService.updateClassificationData(data);
        this.router.navigate(['/classification/forward', { idEpisode: this.idEpisode, idRoom: this.idRoom, idQueue: this.idQueue }]);
      }
    });

  }

  getCounseling(data: ClassificationData) {
    this.counselingService.getCounseling(data.discriminator.idDiscriminator).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.utilService.updateClassificationData(data);
          this.router.navigate(['/classification/counseling-selection']);
          return;
        }

        this.isFirstLoading = false;
        data.listCounseling = response.listCounseling;

        this.utilService.updateClassificationData(data);

        if (data.listCounseling && data.listCounseling.length > 0) {

          this.router.navigate(['/classification/counseling-selection']);
        }

        else {

          this.router.navigate(['/classification/telephone-classification/details']);
        }

      }, error: (error) => {

        console.log(error)
        this.utilService.updateClassificationData(data);
        this.router.navigate(['/classification/telephone-classification/details']);

      }
    });
  }

  async restartMeasurers() {
    await this.measurerUtilsService.restartMeasurer();
    this.alertService.show('Info', "Medidores reiniciados", AlertType.info);
  }

  previousFirstPage() {
    this.isNext = false;

    if (this.outOfOrder && this.data.idFlowchartType == 1) {
      this.childV1.previous();
    } else if (!this.outOfOrder && this.data.idFlowchartType == 1) {
      this.childV2.previous();
    } else if (this.data.idFlowchartType == 2) {
      this.multipleVictimPrimaryAssessment.previous();
    } else if (this.data.idFlowchartType == 3) {
      this.multipleVictimSecondaryAssessment.previous();
    }
  }

  goBack() {
    this.isNext = false;
    this.router.navigate(['/classification/pre-register', { idEpisode: this.idEpisode, idRoom: this.idRoom, idQueue: this.idQueue }]);
  }

  goNextToFlowchartSelect() {
    this.isNext = true;
    this.router.navigate(['/classification/flowchart-select', { idEpisode: this.idEpisode, idRoom: this.idRoom, idQueue: this.idQueue }]);
  }

}
