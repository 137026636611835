export class TriageModel {
        public idLastFlowchart: number;

        public lastFlowchart: string;

        public idTriage: number;

        public idEpisode: number;

        public idGravity: number;

        public temperature: number;

        public respiratoryFrequency: number;

        public isolation: boolean;

        public heartRate: number;

        public heartRateRegular: boolean;

        public glucose: number;

        public bloodPressureDiastole: number;

        public bloodPressureSystole: number;

        public saturation: number;

        public gravityName: string;

        public idPain: number;

        public painColorCode: string;

        public idUserClassification: number;

        public gravityColor: string;

        public gravityColorCode: string;

        public complaint: string;

        public flowchart: string;

        public idFlowchart: number;

        public discriminator: string;

        public idDiscriminator: number;

        public score: number;

        public glasgow: number;

        public glasgowMotor: number;

        public glasgowVerbal: number;

        public glasgowEye: number;

        public justification: string;

        public atmosphericAir: boolean;

        public dpoc: boolean;

        public capillaryFillingTime: boolean;

        public capillaryFillingTimeValue: number;

        public fallRisk: boolean;

        public idSuspicion: number;

        public diseaseHistory: string;

        public height: number;

        public weight: number;

        public dateTimeInclusion: Date;

        public startClassification: Date;

        public endClassification: Date;

        public forwardName: string;

        public observations: string;

        public idForward: number;

        public allergies: string;

        public temperatureComplementaryData: number;

        public heartRateComplementaryData: number;

        public bloodPressureDiastoleComplementaryData: number;

        public bloodPressureSystoleComplementaryData: number;

        public respiratoryFrequencyComplementaryData: number;

        public glucoseComplementaryData: number;

        public saturationComplementaryData: number;

        public isHeartRateSleeping: boolean;

        public isSuspicionEvaluated: boolean;

        public suspicionEvaluationMotive: string;

        public datetimeSuspicionEvaluation: Date;

        public idUserSuspicionEvaluation: number;

        public idGrievanceNotification: number;
        
        public discriminatorSelectionMotive: string;
}