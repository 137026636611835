import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { SelfTriageResponse } from '../../responses/self-triage/self-triage.response';

@Injectable({
  providedIn: 'root'
})
export class LastAutoTriageService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  getAutoTriage(cpf: string): Observable<SelfTriageResponse> {
    let uri = `GetLastPatientSelfTriage/${cpf}`
    return this.httpClient.get<SelfTriageResponse>(environment.urlApiSelfTriage + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  getLastAutoTriageByIdPatient(idPatient: number): Observable<SelfTriageResponse> {
    let uri = `GetLastPatientSelfTriage/idPatient/${idPatient}`
    return this.httpClient.get<SelfTriageResponse>(environment.urlApiSelfTriage + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}