import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { CreateBatchRequest } from '../../requests/batch/create-batch.request';
import { ListBatchRequestResponse } from '../../responses/batch/list-batch-request.response';

@Injectable({
  providedIn: 'root'
})
export class BatchRequestService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listBatch(idBatchType: number): Observable<ListBatchRequestResponse>{

    let uri = `BatchRequest`;

    if(idBatchType)
      uri = uri + `?idBatchType=${idBatchType}`;

    return this.httpClient.get<ListBatchRequestResponse>(environment.urlApiBatch + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public createBatch(request: CreateBatchRequest): Observable<ListBatchRequestResponse>{

    let uri = `BatchRequest`

    return this.httpClient.post<ListBatchRequestResponse>(environment.urlApiBatch + uri, request, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
