import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ServiceRequest } from '../../requests/flow/service.request';
import { GetServiceResponse } from '../../responses/flow/get-service.response';
import { ListServiceResponse } from '../../responses/flow/list-service-response';
import { retry, timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ServiceService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  public listService(searchText: string, isActive: boolean): Observable<ListServiceResponse> {
    let uri = 'Service?'

    if (searchText != null)
      uri = uri + `searchText=${searchText}&`;

    if (isActive != null)
      uri = uri + `isActive=${isActive}&`

    return this.httpClient.get<ListServiceResponse>(environment.urlApiFlow + uri, this.addHeaderToken())
      .pipe(timeout(environment.requestTimeOut), retry(environment.requestRetry), catchError(this.handleError));
  }

  public listAllService(): Observable<ListServiceResponse> {
    let uri = `Service/getAll`

    return this.httpClient.get<ListServiceResponse>(environment.urlApiFlow + uri, this.addHeaderToken())
      .pipe(timeout(environment.requestTimeOut), retry(environment.requestRetry), catchError(this.handleError));
  }

  public getServiceData(idService: number): Observable<GetServiceResponse> {
    let uri = `Service/idService/${idService}`

    return this.httpClient.get<GetServiceResponse>(environment.urlApiFlow + uri, this.addHeaderToken())
      .pipe(timeout(environment.requestTimeOut), retry(environment.requestRetry), catchError(this.handleError));
  }

  public createService(serviceRegisterRequest: ServiceRequest): Observable<ReturnStruct> {
    let uri = `Service`

    return this.httpClient.post<ReturnStruct>(environment.urlApiFlow + uri, serviceRegisterRequest, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public updateService(serviceRegisterRequest: ServiceRequest, idService: number): Observable<ReturnStruct> {
    let uri = `Service/idService/${idService}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiFlow + uri, serviceRegisterRequest, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public deleteService(idService: number, ip: string): Observable<ReturnStruct> {
    let uri = `Service/idService/${idService}/ip/${ip}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiFlow + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}