import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AdministrationTypeEnum, PresentationEnum, DilutionEnum, UsageEnum, FrequencyEnum } from 'src/app/shared/enum/prescription.enum';
import { LookupMedicalCareService } from 'src/app/shared/services/API/medical-record/lookup-medical-care.service';
import { LookupMedicalCareResponse } from 'src/app/shared/services/responses/medical-record/lookup-medical-care-response';
import { FavoriteStandardPrescriptionStruct } from 'src/app/shared/services/structs/medic/favorite-standard-prescription.struct';
import { MedicineStruct } from 'src/app/shared/services/structs/pharmacy/medicine.struct';

@Component({
  selector: 'app-add-medicine-modal',
  templateUrl: './add-medicine-modal.component.html',
  styleUrls: ['./add-medicine-modal.component.css']
})
export class AddMedicineModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<AddMedicineModalComponent>,
    public lookupMedicalCareService: LookupMedicalCareService,
  ) { }

  public isLoading: boolean;
  public isMedicatedSolution: boolean = false;

  public selectedMedicineList: MedicineStruct[] = [];
  public listMedicine: MedicineStruct[] = [];
  public selectedMedicine: MedicineStruct;
  public administrationTypeListDisplay: any[] = [];
  public lookupMedicalCareResponse: LookupMedicalCareResponse;
  public medicalPrescription: FavoriteStandardPrescriptionStruct = new FavoriteStandardPrescriptionStruct();
  public administrationTypeOthersEnum: AdministrationTypeEnum = AdministrationTypeEnum.outros;
  public presentationOthersEnum: PresentationEnum = PresentationEnum.outros;
  public dilutionOthersEnum: DilutionEnum = DilutionEnum.outros;
  public frequencyEnum: FrequencyEnum = FrequencyEnum.now;
  public isFrequency: boolean = false;
  public frequencyNow: any;
  public ifNecessary: FrequencyEnum = FrequencyEnum.ifNecessary;
  public dilutionUnitList: any[] = [];
  public undiluted: boolean = false;
  public dilutionNoDilutionEnum: DilutionEnum = DilutionEnum.semDiluicao;
  public idDilution: number;

  ngOnInit(): void {
    if (this.data.prescription != null)
      this.medicalPrescription = this.data.prescription;
    else {
      this.medicalPrescription.favoriteStandardMedicine = null;
      this.medicalPrescription.favoriteMedicatedSolutionStruct = null;
    }

    this.selectedMedicineList = this.data.listMedicine.listMedicineStruct;
    this.listMedicine = this.data.listMedicine.listMedicineStruct;
    this.lookupMedicalCareResponse = this.data.lookupMedicalCareResponse;

    if (this.data.isMedicatedSolution != null)
      this.isMedicatedSolution = this.data.isMedicatedSolution;

  }

  submit(request: any) {
    this.medicalPrescription = new FavoriteStandardPrescriptionStruct()

    if (this.isMedicatedSolution) {
      this.medicalPrescription.favoriteStandardMedicine = null;
      this.medicalPrescription.favoriteMedicatedSolutionStruct = request.medicatedSolutionStruct;
    }
    else {
      this.medicalPrescription.favoriteStandardMedicine = request.favoriteMedicineModel;
      this.medicalPrescription.favoriteMedicatedSolutionStruct = null;
    }

    this.matDialogRef.close({ medicalPrescription: this.medicalPrescription });
  }

  close() {
    this.matDialogRef.close();
  }

  changeToMedicatedSolution(event: any) {
    this.isMedicatedSolution = !this.isMedicatedSolution;
  }
}