import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ProtocolSectorService } from 'src/app/shared/services/API/risk-classification/protocol-sector.service';
import { ProtocolModel } from 'src/app/shared/services/models/risk-classification/protocol.model';
import { ClassificationData, UtilsClassificationService } from '../../utils.service';
import { DeviceTypeEnum } from 'src/app/shared/enum/device-type.enum';
import { UtilsMeasurerService } from '../flowchart/protocol-v2/utils-measurer.service';
import { WebsocketAttendanceUtilService } from 'src/app/shared/services/websocket-util/websocket-attendance-util.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AttendanceOccupiedRemoveModalComponent } from 'src/app/shared/components/attendance-occupied-remove-modal/attendance-occupied-remove-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-protocol-select',
  templateUrl: './protocol-select.component.html',
  styleUrls: ['./protocol-select.component.css']
})
export class ProtocolSelectComponent implements OnInit, OnDestroy {

  constructor(private protocolSectorService: ProtocolSectorService,
    private alertService: AlertService,
    private utilService: UtilsClassificationService,
    public router: Router,
    public measurerService: UtilsMeasurerService,
    public websocketAttendanceUtilService: WebsocketAttendanceUtilService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    public dialog: MatDialog,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.classification;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.classification_pre_registration;
  public listProtocol: ProtocolModel[] = [];
  public matrixProtocol: any[] = [];
  public isFirstLoading: boolean = true;
  public idEpisode: number;
  public idSector: number;
  public idRoom: number;
  public idQueue: number = null;
  private isTelephoneClassification: boolean;
  public data: ClassificationData;
  public isNext: boolean = false;
  public userLogin: string;
  public userName: string;
  public isAttendanceRemoval: boolean = false;

  ngOnInit(): void {
    this.userLogin = this.authService.getTokenMenu().login;
    this.userName = this.authService.getTokenMenu().userName;
    this.data = this.utilService.getClassificationData();
    if(!this.data.discriminatorAllValues) this.data.discriminatorAllValues = [];
    if(!this.data.discriminatorValues) this.data.discriminatorValues = [];
    this.idEpisode = this.data.idEpisode;
    this.idSector = this.data.idSector ? this.data.idSector : 0;
    this.idRoom = this.data.idRoom;
    this.isTelephoneClassification = this.data.isTelephoneClassification;

    this.getAllProtocols();

    this.idQueue = parseInt(this.activatedRoute.snapshot.paramMap.get('idQueue'));

    if (Number.isInteger(this.idQueue) &&  this.idQueue && this.idQueue > 0 && Number.isInteger(this.idEpisode) &&  this.idEpisode && this.idEpisode > 0 && this.data && this.data.isTelephoneClassification != true) 
      this.startWebsocket();  

    if ((this.data.idDeviceType == DeviceTypeEnum.Trius || this.data.idDeviceType == DeviceTypeEnum.Berco || this.data.idDeviceType == DeviceTypeEnum.Bluetooth) && Array.isArray(this.data.discriminatorAllValues)) {
      let selectedIndex: number = this.data.discriminatorAllValues.findIndex(c => c.idDiscriminator == this.data.discriminator.idDiscriminator);
      selectedIndex = selectedIndex > -1 ? selectedIndex : this.data.discriminatorAllValues.length + 1;
      this.measurerService.setIndex(selectedIndex);
      this.data.discriminatorsPersist = this.data.discriminatorsPersist == undefined ? [] : this.data.discriminatorsPersist;
      this.measurerService.createReference(this.data.discriminatorValues, this.data.discriminatorsPersist, this.data.discriminatorAllValues, 
        this.data.deviceThermometer, this.data.deviceGlucometer, this.data.deviceOximeter, this.data.devicePressure);
      this.measurerService.restartMeasurer();
    }
  }

  getAllProtocols() {
    this.protocolSectorService.getProtocolBySector(this.idSector, this.isTelephoneClassification, []).subscribe({
      next: (response) => {
        this.isFirstLoading = false;
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        let i = 0;

        response.listProtocolSectorStruct[0].listProtocol.forEach((element, index) => {
          this.listProtocol.push(element);
          if ((index + 1) % 4 == 0) {
            i++;
            this.matrixProtocol.push(this.listProtocol);
            this.listProtocol = [];
          }
          else if ((index + 1) % 4 != 0 && index + 1 == response.listProtocolSectorStruct[0].listProtocol.length) {
            this.matrixProtocol.push(this.listProtocol);
          }
        });

        this.utilService.updateDisableShowReport(response.listProtocolSectorStruct[0].disableShowReport);

      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  selectProtocol(idProtocol: number) {
    this.data.idProtocol = idProtocol;

    let protocol = new ProtocolModel;
    for (let i = 0; i < this.matrixProtocol.length; i++) {
      let protocolSearch = this.matrixProtocol[i].find(x => x.idProtocol == idProtocol)
      if (protocolSearch != null) {
        protocol = protocolSearch;
        break;
      }
    }

    this.data.protocolName = protocol.protocolName;
    this.data.outOfOrder = protocol.outOfOrder;
    this.utilService.updateClassificationData(this.data);
    this.isNext = true;
    this.router.navigate(['/classification/flowchart-select', { idEpisode: this.idEpisode, idRoom: this.idRoom, idQueue: this.idQueue}]);
  }

  async restartMeasurers() {
    await this.measurerService.restartMeasurer();
    this.alertService.show('Info', "Medidores reiniciados", AlertType.info);
  }

  // private keepAliveIntervalId = setInterval(() => {
  //   if (this.idRoom)
  //     this.roomAccessUtilService.postRoomAccessHistory(this.idRoom);
  // }, this.roomAccessUtilService.roomKeepAliveTime());

  ngOnDestroy() {
    if (this.data.idDeviceType == DeviceTypeEnum.Trius || this.data.idDeviceType == DeviceTypeEnum.Berco || this.data.idDeviceType == DeviceTypeEnum.Bluetooth) {
      this.measurerService.setIndex(-1);
    }
    // clearInterval(this.keepAliveIntervalId);
  }
  //Websocket
  startWebsocket() {
    if (!this.websocketAttendanceUtilService.isConnect())
      this.websocketAttendanceUtilService.connectwebsocketRoom(this.idRoom, this.idQueue, this.idEpisode, this, true, false, this.authService.getToken(), this.userLogin, this.userName);

    this.websocketAttendanceUtilService.setContext(this);
    this.websocketAttendanceUtilService.setFunctions(null, null, this.onRemoveAttendant);         
  }

  goBack(){
    this.isNext = false;
    this.router.navigate(['/classification/pre-register', { idEpisode: this.idEpisode, idRoom: this.idRoom, idQueue: this.idQueue}]);
  }

  onRemoveAttendant(thisParam, res: any){
    thisParam.isAttendanceRemoval = false;
    
    if(res.userLogin != thisParam.userLogin){
      thisParam.isAttendanceRemoval = true;
      
      thisParam.websocketAttendanceUtilService.clearSocket();
      let ref = thisParam.dialog.open(AttendanceOccupiedRemoveModalComponent, { data: { ...res } });
      ref.afterClosed().subscribe(() => {
        thisParam.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          thisParam.router.navigate(['/classification/classification-patient-list', { idSector: thisParam.idSector, idRoom: thisParam.idRoom }]));
      })
    }
  }
}