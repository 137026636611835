import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { DateAfterTodayValidator } from 'src/app/shared/custom-validators/date.validator';
import { EpisodeStatusEnum } from 'src/app/shared/enum/episode-status.enum';
import { FowardDestinationEnum } from 'src/app/shared/enum/foward-destination.enum';
import { MedicRequiredFieldEnum } from 'src/app/shared/enum/medic-required-field.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { BillingDataService } from 'src/app/shared/services/API/billing/billing-data.service';
import { MedicConfigSectorService } from 'src/app/shared/services/API/medic/medic-config-sector.service';
import { ProcedureService } from 'src/app/shared/services/API/medic/procedure.service';
import { LookupMedicalCareService } from 'src/app/shared/services/API/medical-record/lookup-medical-care.service';
import { MedicalCareService } from 'src/app/shared/services/API/medical-record/medical-care.service';
import { MedicalPrescriptionService } from 'src/app/shared/services/API/medical-record/medical-prescription.service';
import { MedicalRecordService } from 'src/app/shared/services/API/orchestrator-patient/medical-record.service';
import { ClockService } from 'src/app/shared/services/API/orchestrator-queue/clock.service';
import { FowardService } from 'src/app/shared/services/API/orchestrator-queue/foward.service';
import { ObservationForwardService } from 'src/app/shared/services/API/orchestrator-queue/observation-foward.service';
import { MedicineService } from 'src/app/shared/services/API/pharmacy/medicine.service';
import { BedQueueService } from 'src/app/shared/services/API/queue/bed-queue.service';
import { ExamService } from 'src/app/shared/services/API/SADT/exam.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { AllergyModel } from 'src/app/shared/services/models/medical-record/allergy.model';
import { MedicalCareModel } from 'src/app/shared/services/models/medical-record/medical-care.model';
import { ExamTypeModel } from 'src/app/shared/services/models/SADT/exam-type.model';
import { LookupMedicalCareResponse } from 'src/app/shared/services/responses/medical-record/lookup-medical-care-response';
import { MedicConfigSectorStruct } from 'src/app/shared/services/structs/medic/medic-config-sector.struct';
import { SadtGroupStruct } from 'src/app/shared/services/structs/medical-record/sadt-group.struct';
import { CompleteMedicalCareStruct } from 'src/app/shared/services/structs/orchestrator-patient/complete-medical-care.struct';
import { EpisodeStruct } from 'src/app/shared/services/structs/orchestrator-patient/episode.struct';
import { EpisodeHistorieStruct } from 'src/app/shared/services/structs/orchestrator-patient/episodeHistorie.struct';
import { ReadExternalPrescriptionStruct } from 'src/app/shared/services/structs/orchestrator-patient/read-external-prescription.struct';
import { TriageStruct } from 'src/app/shared/services/structs/orchestrator-patient/triage.struct';
import { LocationStruct } from 'src/app/shared/services/structs/orchestrator-queue/location.struct';
import { ExamStruct } from 'src/app/shared/services/structs/SADT/exam.struct';
import { environment } from 'src/environments/environment';
import { UpdateExternalPrescriptionModalComponent } from '../attend-patient/update-external-prescription-modal/update-external-prescription-modal.component';
import { CancelConfirmModalComponent } from './cancel-confirm-modal/cancel-confirm-modal.component';
import { ExternalPrescriptionModalComponent } from 'src/app/shared/components/external-prescription-modal/external-prescription-modal.component';
import { SadtModalComponent } from 'src/app/shared/components/sadt-modal/sadt-modal.component';
import { MedicalCareRequest } from 'src/app/shared/services/requests/medical-record/medical-care.request';
import { BillingDataRequest } from 'src/app/shared/services/requests/billing/billing-data-request';
import { EpisodeModalComponent } from 'src/app/medic/pages/attend-patient/episode-modal/episode-modal.component';
import { TriageService } from 'src/app/shared/services/API/orchestrator-classification/triage.service';
import { EpisodeStatusRequest } from 'src/app/shared/services/requests/medical-record/episode-status.request';
import { UpdateStatusQueueService } from 'src/app/shared/services/API/orchestrator-queue/update-status-queue.service';
import { TwilioVideoComponent } from 'src/app/shared/components/twilio-video/twilio-video.component';
import { WebsocketTelemedicineActionEnum } from 'src/app/shared/enum/websocket-telemedicine-action.enum';
import { TriggerWebsocketService } from 'src/app/shared/services/API/orchestrator-telemedicine/trigger-websocket.service';
import { PatientLeaveModalComponent } from './patient-leave-modal/patient-leave-modal.component';
import { MedicalCareReportRequest } from 'src/app/shared/services/requests/orchestrator-patient/medical-care-report.request';
import { PatientService } from 'src/app/shared/services/API/medical-record/patient.service';
import { UpdateEmailPatientRequest } from 'src/app/shared/services/requests/medical-record/update-email-patient.request';
import { AllergyStruct } from 'src/app/shared/services/structs/medical-record/allergy.struct';
import { StatusQueueRequest } from 'src/app/shared/services/requests/queue/status-queue.request';
import { EpisodePreEvaluationStruct } from 'src/app/shared/services/structs/medical-record/episode-pre-evaluation.struct';
import { PinCertificateModalComponent } from 'src/app/shared/components/pin-certificate-modal/pin-certificate-modal.component';
import { TelephoneTriageStruct } from 'src/app/shared/services/structs/orchestrator-patient/telephone-triage.struct';
import { LastSelfTriageStruct } from 'src/app/shared/services/structs/orchestrator-patient/last-self-triage.struct';
import { SelfTriageResumeModalComponent } from 'src/app/shared/components/self-triage-resume-modal/self-triage-resume-modal.component';
import { StatusQueueService } from 'src/app/shared/services/API/queue/status-queue.service';
import { StatusQueueEnum } from 'src/app/shared/enum/status-queue.enum';
import { CareLineModel } from 'src/app/shared/services/models/care-line/care-line.model';
import { EpisodeCareLineStruct } from 'src/app/shared/services/structs/care-line/episode-care-line.struct';
import { CareLineModalComponent } from 'src/app/shared/components/care-line-modal/care-line-modal.component';
import { EpisodeCareLineRequest } from 'src/app/shared/services/requests/care-line/episode-care-line.request';
import { EmailConfirmationEndMedicalCareModalComponent } from 'src/app/shared/components/email-confirmation-end-medical-care-modal/email-confirmation-end-medical-care-modal.component';
import { WebsocketTelemedicineUtilService } from 'src/app/shared/services/websocket-util/websocket-telemedicine-util.service';
import { MedicalCareSadtStruct } from 'src/app/shared/services/structs/medical-record/medical-care-sadt.struct';
import { HealthUnitMemedPrescriptionService } from 'src/app/shared/services/API/medic/health-unit-memed-prescription.service';
import { UserMemedService } from 'src/app/shared/services/API/orchestrator-patient/user-memed.service';
import { GetPhoneModalComponent } from 'src/app/shared/components/get-phone-modal/get-phone-modal.component';
import { MemedCompletePrescriptionStruct } from 'src/app/shared/services/structs/medical-record/memed-complete-prescription.struct';
import { MemedPrescriptionStruct } from 'src/app/shared/services/structs/medical-record/memed-prescription.struct';
import { MemedPrescriptionSession, UtilService } from 'src/app/shared/services/util.service';
import { WebsocketAttendanceUtilService } from 'src/app/shared/services/websocket-util/websocket-attendance-util.service';
import { MemedPrescriptionDeleteModalComponent } from '../attend-patient/memed-prescription-delete-modal/memed-prescription-delete-modal.component';
import { ReportViewSelectModalComponent } from 'src/app/shared/components/report-view-select-modal/report-view-select-modal.component';
import { NewReportResponse } from 'src/app/shared/services/responses/orchestrator-patient/new-report.response';
import { UserRoleEnum } from 'src/app/shared/enum/user-role.enum';
import { UserLoginResponse } from 'src/app/shared/services/responses/user/auth.response';
import { SignatureCredentialsModalComponent } from 'src/app/shared/components/signature-credentials-modal/signature-credentials-modal.component';
import { TissGuideManagementFloatingWindowComponent } from 'src/app/shared/components/tiss-guides/tiss-guide-management-floating-window/tiss-guide-management-floating-window.component';
import { ExamTypeService } from 'src/app/shared/services/API/SADT/exam-type.service';
import { AllPrivateBillingGuideReportStruct } from 'src/app/shared/services/structs/orchestrator-patient/all-private-billing-guide-report.struct';
import { AttendanceOccupiedRemoveModalComponent } from 'src/app/shared/components/attendance-occupied-remove-modal/attendance-occupied-remove-modal.component';
import { MedicalRecordTelemedicineService } from 'src/app/shared/services/API/orchestrator-patient/medical-record-telemedicine.service';
import { SignedErrorModalComponent } from './signed-error-modal/signed-error-modal.component';
import { MedicalRecordTelemedicineRequest } from 'src/app/shared/services/requests/orchestrator-patient/medical-record-telemedicine.request';
import { MedicalRecordTelemedicineRetrySignedRequest } from 'src/app/shared/services/requests/orchestrator-patient/medical-record-telemedicine-retry-signed.request';
import { PatientTelemedicineIntegration } from 'src/app/shared/services/structs/telemedicine/patient-telemedicine-integration.struct';
import { PatientAvoidanceLogRequest } from 'src/app/shared/services/requests/srv-log/patient-avoidance-log.request';
import { EvasionScreenEnum } from 'src/app/shared/enum/evasion-screen.enum';
import { PatientAvoidanceLogService } from 'src/app/shared/services/API/srv-log/patient-avoidance-log.service';

@Component({
  selector: 'app-attend-patient-online',
  templateUrl: './attend-patient-online.component.html',
  styleUrls: ['./attend-patient-online.component.css']
})
export class AttendPatientOnlineComponent implements OnInit, OnDestroy {

  @ViewChild('twiliovideo') twiliovideo: TwilioVideoComponent;

  constructor(private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private dialog: MatDialog,
    private utilService: UtilService,
    public procedureService: ProcedureService,
    public medicalRecordService: MedicalRecordService,
    public medicalRecordTelemedicineService: MedicalRecordTelemedicineService,
    public medicineService: MedicineService,
    public lookupMedicalCareService: LookupMedicalCareService,
    public examService: ExamService,
    public medicalCareService: MedicalCareService,
    public router: Router,
    public fowardService: FowardService,
    private maskService: MaskService,
    private clockService: ClockService,
    private billingDataService: BillingDataService,
    private authService: AuthService,
    public websocketAttendanceUtilService: WebsocketAttendanceUtilService,
    private medicalPrescriptionService: MedicalPrescriptionService,
    public observationForwardService: ObservationForwardService,
    public bedQueueService: BedQueueService,
    public medicConfigSectorService: MedicConfigSectorService,
    public triageService: TriageService,
    public updateStatusQueueService: UpdateStatusQueueService,
    private statusQueueService: StatusQueueService,
    public websocketTelemedicineUtilService: WebsocketTelemedicineUtilService,
    private triggerWebsocketService: TriggerWebsocketService,
    private patientService: PatientService,
    private healthUnitMemedPrescriptionService: HealthUnitMemedPrescriptionService,
    private userMemedService: UserMemedService,
    private examTypeService: ExamTypeService,
    public patientAvoidanceLogService: PatientAvoidanceLogService,
  ) {
  }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.medic;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.medic_patient_list;
  @ViewChild(TissGuideManagementFloatingWindowComponent) childComponent: TissGuideManagementFloatingWindowComponent;

  public showTissGuideButton: boolean = false;
  public allPrivateBillingGuideReportStruct: AllPrivateBillingGuideReportStruct;

  public isLoading: boolean = false;
  public isFastTrack: boolean = false;
  public isFirstLoading: boolean;
  public isIsolation: boolean = false;
  public selectedEpisode: number;
  public triage: TriageStruct = null;
  public idPatient: number;
  public idSelfTriagePatient: number;
  public cpf: string;
  public patientEmail: string;
  public medicalCare: CompleteMedicalCareStruct[] = [];
  public episodeHistorie: EpisodeHistorieStruct[] = [];
  public model: FormGroup;
  public lookupMedicalCareResponse: LookupMedicalCareResponse;
  public newMedicalCare: MedicalCareModel;
  public listExternalPrescription: ReadExternalPrescriptionStruct[] = [];
  public listSADT: SadtGroupStruct[] = [];
  public listLookupExam: ExamStruct[];
  public wasSaved: boolean = false;
  public urlListCid: string = environment.urlApiMedicalRecord + 'cid';
  public listCid: any[] = [];
  public idSector: number = null;
  public idRoom: number = null;
  public masks: Masks = this.maskService.getMasks();
  public sadtOthers: number = 0;
  public urlListAllergies: string = environment.urlApiMedicalRecord + "Allergy";
  public datetimeStartAttendance: Date;
  public listFoward: LocationStruct[] = [];
  public idLastQueue: number;
  public destinationType: number;
  public destinationTypeAlta: number = FowardDestinationEnum.Alta;
  public readonly: boolean = false;
  public idMedicalCare: number;
  public stringValidator: string;
  public showForward: boolean;
  public medicalEvolution: boolean = true;
  private listExamType: ExamTypeModel[];
  public idService: number;
  public medicConfigSector: MedicConfigSectorStruct;
  public episodeStruct: EpisodeStruct;
  public episodePreEvaluation: EpisodePreEvaluationStruct = null;
  public medicalCareReportRequest: MedicalCareReportRequest = new MedicalCareReportRequest();
  public listIdExam: number[];
  public isAllExams: boolean;
  public codCbo: string;
  public idCompetence: number;
  public urlListProcedure: string;
  public idQueue: number;
  public newEmail: string;
  public idEpisode: number;
  public bloodPressure: string[] = [];
  public allergies: AllergyModel[] = [];
  public allergiesToSave: AllergyModel[] = [];
  idTimeout: any;
  public lastSelfTriage: LastSelfTriageStruct;
  public lastTelephoneTriage: TelephoneTriageStruct;
  public isAttended: boolean = false;
  public certificatePin: string = null;
  public isIcpBrailSigned: boolean = false;
  public isUserIcpBrasilSignature: boolean = false;
  public pacientEvadedByModal: boolean = false;
  public hasIcpBrasilTokenCertificate: boolean = false;

  public certificates: any[];
  public icpBrasil: boolean = true;
  public isIcpBrailTokenSigned: boolean = false;

  public isBedManagement: boolean = false;

  //Memed
  public isMemedPrescription: boolean = false;
  public userTokenMemed: string;
  public showMemed: boolean = false;
  public listMemedPrescriptionStruct: MemedPrescriptionStruct[] = [];
  public listMemedCompletePrescriptionStruct: MemedCompletePrescriptionStruct[] = [];

  //Linhas de cuidado
  public listPastEpisodeCareLine: EpisodeCareLineStruct[] = [];
  public listCurrentEpisodeCareLine: EpisodeCareLineStruct[] = [];
  public listNewEpisodeCareLine: EpisodeCareLineStruct[] = [];
  public listCareLine: CareLineModel[] = [];
  public patientCellPhone: string = "";
  public countryCode: string = "+55";
  public twilioTimeout: any;
  //websocket
  socketDisconneted: boolean = false;
  onlineOffline: boolean = navigator.onLine;
  private socket: any;
  private userLogin: string;
  private userName: string;
  public isAttendanceRemoval: boolean = false;

  //Assinatura digital  
  public sign: boolean;
  public requireSignature: boolean = this.checkSigningResponsibility();

  ngOnInit(): void {
    this.userLogin = this.authService.getTokenMenu().login;
    this.userName = this.authService.getTokenMenu().userName;
    this.selectedEpisode = parseInt(this.activatedRoute.snapshot.paramMap.get('idEpisode'));
    this.getMemedPrescription();
    this.getLookupSadtType();

    this.model = this.formBuilder.group({
      medicalNotes: [{ value: '', disabled: this.wasSaved }],
      physicalExamination: [{ value: '', disabled: this.wasSaved }],
      therapeuticPlan: [{ value: '', disabled: this.wasSaved }],
      diagnosedHypothesis: [{ value: '', disabled: this.wasSaved }],
      temperature: [{ value: '', disabled: this.wasSaved }],
      respiratoryFrequency: [{ value: '', disabled: this.wasSaved }],
      heartRate: [{ value: '', disabled: this.wasSaved }],
      glucose: [{ value: '', disabled: this.wasSaved }],
      bloodPressure: [{ value: '', disabled: this.wasSaved }],
      saturation: [{ value: '', disabled: this.wasSaved }],
      conclusionNote: [{ value: '', disabled: this.wasSaved }],
      idFlow: [{ value: '', disabled: this.wasSaved }],
      datetimeEndIsolation: [null],
      sickDaysOffAmount: [0, [Validators.min(0)]],
      isCidOnSickNote: [{ value: false, disabled: true }, [Validators.required]],
      firstChildGroup: this.formBuilder.group({
        listIdAllergies: [this.allergies.map(x => x.idAllergy)],
        cid: [this.listCid.map(a => a.idCid)],
      })
    });

    this.codCbo = this.authService.getTokenMenu().codCbo;
    this.idCompetence = this.authService.getTokenMenu().healthUnit.idProcedureSigtapCompetence;

    if (this.codCbo && this.idCompetence) {
      let url = `ProcedureSigtapCBO/codCbo/${this.codCbo}/idCompetence/${this.idCompetence}`
      this.urlListProcedure = environment.urlApiBilling + url;
    }

    if (this.selectedEpisode) {
      this.isFirstLoading = true;
      this.populateAttendPatient();
      this.startWebsocket();
    }

    if (this.activatedRoute.snapshot.paramMap.get('readonly'))
      this.readonly = this.activatedRoute.snapshot.paramMap.get('readonly') === "true";

    if (parseInt(this.activatedRoute.snapshot.paramMap.get('idRoom')))
      this.idRoom = parseInt(this.activatedRoute.snapshot.paramMap.get('idRoom'));

    if (parseInt(this.activatedRoute.snapshot.paramMap.get('idSector')))
      this.idSector = parseInt(this.activatedRoute.snapshot.paramMap.get('idSector'));

    if (parseInt(this.activatedRoute.snapshot.paramMap.get('idService')))
      this.idService = parseInt(this.activatedRoute.snapshot.paramMap.get('idService'));

    if (parseInt(this.activatedRoute.snapshot.paramMap.get('idQueue')))
      this.idQueue = parseInt(this.activatedRoute.snapshot.paramMap.get('idQueue'));

    if (parseInt(this.activatedRoute.snapshot.paramMap.get('idEpisode')))
      this.idEpisode = parseInt(this.activatedRoute.snapshot.paramMap.get('idEpisode'));

    if (this.activatedRoute.snapshot.paramMap.get('showTissGuideButton'))
      this.showTissGuideButton = this.activatedRoute.snapshot.paramMap.get('showTissGuideButton') == "true" ? true : false;

    this.getLookupMedicalCare();

    this.idTimeout = setTimeout(() => this.startCall(), 1000);

    let memedSession: MemedPrescriptionSession = new MemedPrescriptionSession();
    memedSession = this.utilService.getMemedPrescriptionSession();

    if (memedSession) {
      this.listMemedPrescriptionStruct = memedSession.listMemedPrescriptionStruct;
      this.listMemedCompletePrescriptionStruct = memedSession.listMemedCompletePrescriptionStruct;
    }
  }

  //Websocket
  startWebsocketLocalAttendance() {
    if (!this.websocketAttendanceUtilService.isConnect())
      this.websocketAttendanceUtilService.connectwebsocketRoom(this.idRoom, this.idQueue, this.selectedEpisode, this, false, this.isBedManagement, this.authService.getToken(), this.userLogin, this.userName);

    this.websocketAttendanceUtilService.setContext(this);
    this.websocketAttendanceUtilService.setFunctions(null, null, this.onRemoveAttendant);

    if (!this.isBedManagement && Number.isInteger(this.idQueue) && this.idQueue && this.idQueue > 0 && Number.isInteger(this.selectedEpisode) && this.selectedEpisode && this.selectedEpisode > 0) {
      if (!this.websocketAttendanceUtilService.isConnect())
        this.websocketAttendanceUtilService.connectwebsocketRoom(this.idRoom, this.idQueue, this.selectedEpisode, this, false, this.isBedManagement, this.authService.getToken(), this.userLogin, this.userName);

      this.websocketAttendanceUtilService.setContext(this);
      this.websocketAttendanceUtilService.setFunctions(null, null, this.onRemoveAttendant);
    }
  }

  updateAllergiesToSave(allergies: AllergyModel[]) {
    this.allergiesToSave = allergies;
  }

  submit(sign: boolean) {
    this.sign = sign;

    if (this.isLoading)
      return;

    this.isLoading = true;

    if (this.model.invalid) {
      this.alertService.show('Erro', "Preencha todos em vermelho" + this.stringValidator + "!", AlertType.error);
      this.isLoading = false;
      return;
    }

    if (this.model.get('bloodPressure').value) {
      let pa: string = this.model.get("bloodPressure").value.split("/")[0].split("");
      if (pa.length < 4) {
        this.alertService.show('Erro', "Preencha o valor da pressão arterial corretamente", AlertType.error);
        this.isLoading = false;
        return;
      }
      let value1 = pa.length == 4 ? pa[0] + pa[1] : pa[0] + pa[1] + pa[2];
      let value2 = pa.length == 4 ? pa[2] + pa[3] : pa.length == 5 ? pa[3] + pa[4] : pa[3] + pa[4] + pa[5];
      if (parseInt(value2) > parseInt(value1)) {
        this.alertService.show('Erro', "A pressão diastólica não pode ser maior do que a sistólica!", AlertType.error);
        this.model.get("bloodPressure").setValue("");
        this.isLoading = false;
        return;
      }

      this.bloodPressure.push(value1);
      this.bloodPressure.push(value2);
    }

    let medicalCareRequest = this.mapToRequest();

    if (medicalCareRequest.saturation < 0 || medicalCareRequest.saturation > 100) {
      this.alertService.show('Erro', "O valor da saturação deve ser de 0 a 100", AlertType.error);
      this.isLoading = false;
      return;
    }

    medicalCareRequest.idRoomAttendance = this.idRoom;
    medicalCareRequest.idQueueUsed = this.idQueue;

    if (this.isUserIcpBrasilSignature) this.openCertificatePinModal();
    else this.makeCallPost();
  }

  startConclusion(): EpisodeStatusRequest {
    let episodeStatusRequest: EpisodeStatusRequest = new EpisodeStatusRequest();
    episodeStatusRequest.conclusionNote = this.model.get("conclusionNote").value;
    episodeStatusRequest.idStatus = EpisodeStatusEnum.FinalizadoAlta;
    episodeStatusRequest.isBedManagement = false;
    return episodeStatusRequest;
  }

  openConclusionModal(saveCompletedMedicalRecord: MedicalRecordTelemedicineRequest) {
    const dialogRef = this.dialog.open(EmailConfirmationEndMedicalCareModalComponent, {
      disableClose: true,
      data: {
        email: this.patientEmail,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result.confirm && result.email) {
          this.newEmail = result.email;
          this.updateEmailPatient(saveCompletedMedicalRecord);
        }
        else if (!result.confirm) {
          this.endLoading();
          return;
        }
      }
    });
  }

  mapToRequest(): MedicalCareRequest {
    let medicalCareRequest: MedicalCareRequest = new MedicalCareRequest();
    medicalCareRequest.listExternalPrescription = this.listExternalPrescription;
    medicalCareRequest.medicalCareSadt = this.listSADT;
    medicalCareRequest.idEpisode = this.selectedEpisode;
    medicalCareRequest.medicalNotes = this.model.get('medicalNotes').value;
    medicalCareRequest.physicalExamination = this.model.get('physicalExamination').value;
    medicalCareRequest.therapeuticPlan = this.model.get('therapeuticPlan').value;
    medicalCareRequest.diagnosedHypothesis = this.model.get('diagnosedHypothesis').value;
    if (this.model.get('temperature').value) {
      medicalCareRequest.temperature = parseFloat(this.model.get('temperature').value.replace(",", ".").replace("_", ""));
    }
    if (this.model.get('respiratoryFrequency').value) {
      medicalCareRequest.respiratoryFrequency = parseInt(this.model.get('respiratoryFrequency').value.replace("_", ""));
    }
    if (this.model.get('heartRate').value) {
      medicalCareRequest.heartRate = parseInt(this.model.get('heartRate').value.replace("_", ""));
    }
    if (this.model.get('glucose').value) {
      medicalCareRequest.glucose = parseInt(this.model.get('glucose').value.replace("_", ""));
    }
    medicalCareRequest.bloodPressureDiastole = this.bloodPressure && this.bloodPressure[1] ? parseInt(this.bloodPressure[1].replace("_", "")) : null;
    medicalCareRequest.bloodPressureSystole = this.bloodPressure && this.bloodPressure[0] ? parseInt(this.bloodPressure[0].replace("_", "")) : null;
    if (this.model.get('saturation').value) {
      medicalCareRequest.saturation = parseInt(this.model.get('saturation').value.replace("_", ""));
    }
    medicalCareRequest.cid = this.listCid.map(a => a.idCid);
    medicalCareRequest.isCidOnSickNote = this.model.get('isCidOnSickNote').value;
    medicalCareRequest.listAllergy = this.allergiesToSave.map(a => new AllergyStruct(a, this.selectedEpisode));
    medicalCareRequest.datetimeStartAttendance = this.datetimeStartAttendance;
    medicalCareRequest.datetimeEndIsolation = this.model.get('datetimeEndIsolation').value?.toISOString();
    medicalCareRequest.sickDaysOffAmount = this.model.get('sickDaysOffAmount').value;
    medicalCareRequest.conclusionNote = this.model.get('conclusionNote').value;
    medicalCareRequest.listMemedCompletePrescriptionStruct = this.listMemedCompletePrescriptionStruct;
    medicalCareRequest.userTokenMemed = this.userTokenMemed;
    medicalCareRequest.conclusionType = FowardDestinationEnum.Alta;
    return medicalCareRequest;
  }

  mapToBillingDataRequest(): BillingDataRequest {
    let billingDataRequest: BillingDataRequest = new BillingDataRequest();
    billingDataRequest.listCid = this.listCid.map(x => x.code);
    //billingDataRequest.idRelation = idRelation;
    billingDataRequest.idModule = MenuModuleEnum.medic;
    billingDataRequest.idEpisode = this.selectedEpisode;
    // billingDataRequest.listIdProcedure = this.listProcedureSigtap.map(a => a.idProcedureSigtap);
    return billingDataRequest;
  }

  postBillingData(billingDataRequest: BillingDataRequest) {
    if (this.codCbo) {
      this.billingDataService.postBillingData(billingDataRequest).subscribe({
        next: (response) => {
          if (response.isError) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            return;
          }
        },
        error: (error) => {
          this.isLoading = false;
          this.alertService.show('Erro inesperado', error, AlertType.error);
        }
      });
    }
  }

  getLookupMedicalCare() {
    this.lookupMedicalCareService.getLookupMedicalCare().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error, false);
          return;
        }

        this.lookupMedicalCareResponse = response;
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error, false);
      }
    });
  }

  populateAttendPatient() {
    this.medicalRecordService.getMedicalRecord(this.selectedEpisode).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isFirstLoading = false;
          return;
        }
        this.lastSelfTriage = response.lastSelfTriage;
        this.lastTelephoneTriage = response.lastTelephoneTriage;
        this.isUserIcpBrasilSignature = response.isActualUserIcpBrasilSignature;
        this.hasIcpBrasilTokenCertificate = response.hasIcpBrasilTokenCertificate;
        this.datetimeStartAttendance = response.datetimeStartAttendance;
        this.idPatient = response.episode.idPatient;
        this.cpf = response.cpf;
        this.patientEmail = response.email;
        this.triage = response.triage;
        if (!this.readonly) {
          this.medicalCare = response.medicalCareHistorie;
          if (response.patientTelemedicineIntegration) {
            this.populatePatientTelemedicineIntegration(response.patientTelemedicineIntegration);
          }
        }
        else {
          if (response.medicalCareHistorie != null && response.medicalCareHistorie.length > 0) {
            this.populateLastMedicalCare(response.medicalCareHistorie[response.medicalCareHistorie.length - 1])
            response.medicalCareHistorie.pop();
            this.medicalCare = response.medicalCareHistorie;
          } else if (response.patientTelemedicineIntegration) {
            this.populatePatientTelemedicineIntegration(response.patientTelemedicineIntegration);
          }
        }
        this.patientCellPhone = response.episode.patientPhone1 ? response.episode.patientPhone1 : response.episode.patientPhone2;

        if (response.episodePreEvaluation && (response.episodePreEvaluation.symptomDescription || response.episodePreEvaluation.symptomDurationDescription)) {
          this.episodePreEvaluation = response.episodePreEvaluation;
        }

        this.episodeStruct = response.episode;
        this.episodeHistorie = response.episodeHistorie;
        this.allergies = response.listAllergy;
        this.listPastEpisodeCareLine = response.listPastEpisodeCareLine;
        this.listCurrentEpisodeCareLine = response.listCurrentEpisodeCareLine
        this.listCareLine = response.listCareLine;
        this.isFirstLoading = false;
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error, false);
      }
    });
  }
  populatePatientTelemedicineIntegration(patientTelemedicineIntegration: PatientTelemedicineIntegration) {
    this.model.get("temperature").setValue(patientTelemedicineIntegration.temperature);
    this.model.get("respiratoryFrequency").setValue(patientTelemedicineIntegration.respiratoryFrequency);
    this.model.get("heartRate").setValue(patientTelemedicineIntegration.heartRate);
    this.model.get("glucose").setValue(patientTelemedicineIntegration.glucose);
    this.model.get("bloodPressure").setValue(patientTelemedicineIntegration.bloodPressureSystole + "/" + patientTelemedicineIntegration.bloodPressureDiastole);
    this.model.get("saturation").setValue(patientTelemedicineIntegration.saturation);
  }
  populateLastMedicalCare(medicalCare: CompleteMedicalCareStruct) {
    this.model.get("medicalNotes").setValue(medicalCare.medicalCare.medicalNotes);
    this.model.get("physicalExamination").setValue(medicalCare.medicalCare.physicalExamination);
    this.model.get("therapeuticPlan").setValue(medicalCare.medicalCare.therapeuticPlan);
    this.model.get("diagnosedHypothesis").setValue(medicalCare.medicalCare.diagnosedHypothesis);
    this.model.get("temperature").setValue(medicalCare.medicalCare.temperature);
    this.model.get("respiratoryFrequency").setValue(medicalCare.medicalCare.respiratoryFrequency);
    this.model.get("heartRate").setValue(medicalCare.medicalCare.heartRate);
    this.model.get("glucose").setValue(medicalCare.medicalCare.glucose);
    this.model.get("bloodPressure").setValue(medicalCare.medicalCare.bloodPressureSystole + "/" + medicalCare.medicalCare.bloodPressureDiastole);
    this.model.get("saturation").setValue(medicalCare.medicalCare.saturation);
    this.listExternalPrescription = medicalCare.listExternalPrescription;
    this.listSADT = medicalCare.medicalCareSadt;
    this.idMedicalCare = medicalCare.medicalCare.idMedicalCare;
    this.medicalEvolution = medicalCare.medicalEvolution;
    if (!this.medicalEvolution)
      this.alertService.show('AVISO', "Seu usuário não tem permissão para ver as evoluções médicas!", AlertType.warning);
  }

  populateVirtualTriages() {
    this.triageService.getVirtualTriages(this.idPatient, this.cpf).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error, false);
          this.isFirstLoading = false;
          return;
        }
      },
      error: (error) => {
        this.alertDynamic('Erro', error, AlertType.error, false);
      }
    });
  }

  getDate() {
    this.clockService.getDate().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error, false);
          return;
        }

        this.datetimeStartAttendance = response.date;
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error, false);
      }
    });
  }

  openCareLineModal(listCareLine: CareLineModel[]) {
    const dialogRef = this.dialog.open(CareLineModalComponent, {
      data: {
        idEpisode: this.selectedEpisode,
        listCareLine: listCareLine,
        listPastEpisodeCareLine: this.listPastEpisodeCareLine,
        listCurrentEpisodeCareLine: this.listCurrentEpisodeCareLine,
        patientPhoneNumber: this.patientCellPhone,
        countryCode: this.countryCode,
        masks: this.masks,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.newEpisodeCareLines) {
          this.listNewEpisodeCareLine = this.listNewEpisodeCareLine.concat(result.newEpisodeCareLines);
          this.listCurrentEpisodeCareLine = this.listCurrentEpisodeCareLine.concat(result.newEpisodeCareLines);
          this.patientCellPhone = result.patientPhoneNumber;
        }
      }
    });
  }

  getMedicSectorConfiguration() {
    this.medicConfigSectorService.listMedicConfigSector(this.idSector).subscribe({
      next: response => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error, false);
          return;
        }

        if (response.medicConfigSectorStruct && response.medicConfigSectorStruct.length > 0)
          this.medicConfigSector = response.medicConfigSectorStruct[0];
        else
          this.alertService.show('Aviso', 'Configurações não encontradas, verifique se há configurações cadastradas para este setor', AlertType.warning);

        this.verifyRequiredFields();
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error, true);
      }
    });
  }

  verifyRequiredFields() {
    if (this.medicConfigSector) {
      this.medicConfigSector.listIdRequiredField.forEach(x => {

        //sinais vitais
        if (x == MedicRequiredFieldEnum.temperature) {
          this.model.get('temperature').setValidators([Validators.required]);
          this.model.get('temperature').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.respiratoryFrequency) {
          this.model.get('respiratoryFrequency').setValidators([Validators.required]);
          this.model.get('respiratoryFrequency').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.heartRate) {
          this.model.get('heartRate').setValidators([Validators.required]);
          this.model.get('heartRate').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.glucose) {
          this.model.get('glucose').setValidators([Validators.required]);
          this.model.get('glucose').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.satO2) {
          this.model.get('saturation').setValidators([Validators.required]);
          this.model.get('saturation').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.bloodPressure) {
          this.model.get('bloodPressure').setValidators([Validators.required]);
          this.model.get('bloodPressure').updateValueAndValidity();
        }

        //outros campos
        else if (x == MedicRequiredFieldEnum.medicalNotes) {
          this.model.get('medicalNotes').setValidators([Validators.required]);
          this.model.get('medicalNotes').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.physicalExamination) {
          this.model.get('physicalExamination').setValidators([Validators.required]);
          this.model.get('physicalExamination').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.cid) {
          this.model.get('firstChildGroup').get('cid').setValidators([Validators.required]);
          this.model.get('firstChildGroup').get('cid').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.allergies) {
          this.model.get('firstChildGroup').get('listIdAllergies').setValidators([Validators.required]);
          this.model.get('firstChildGroup').get('listIdAllergies').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.conclusion) {
          this.model.get('conclusionNote').setValidators([Validators.required]);
          this.model.get('conclusionNote').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.diagnosedHypothesis) {
          this.model.get('diagnosedHypothesis').setValidators([Validators.required]);
          this.model.get('diagnosedHypothesis').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.therapeuticPlan) {
          this.model.get('therapeuticPlan').setValidators([Validators.required]);
          this.model.get('therapeuticPlan').updateValueAndValidity();
        }
      });

      if (this.medicConfigSector.listIdRequiredField.findIndex(c => c == MedicRequiredFieldEnum.conclusion) == -1) {
        this.model.get('conclusionNote').setValidators([]);
        this.model.get('conclusionNote').updateValueAndValidity();
      }
    }
    else {
      this.model.get('conclusionNote').setValidators([]);
      this.model.get('conclusionNote').updateValueAndValidity();
    }
  }

  openUpdateExternalPrescriptionModal(index: number, prescription: ReadExternalPrescriptionStruct) {
    const dialogRef = this.dialog.open(UpdateExternalPrescriptionModalComponent, {
      data: {
        prescription,
        idMedicalCare: this.idMedicalCare,
        lookupMedicalCareResponse: this.lookupMedicalCareResponse,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result) {
          if (index >= 0) {
            this.listExternalPrescription.splice(index, 1);
          }
          this.listExternalPrescription.push(result.newMedicalPrescription);
        }

        this.isFirstLoading = false;
      }
    });
  }

  openDefaultExternalPrescriptionModal(index: number, prescription: ReadExternalPrescriptionStruct, readOnly: boolean) {
    const dialogRef = this.dialog.open(ExternalPrescriptionModalComponent, {
      data: {
        prescription,
        readOnly,
        lookupMedicalCareResponse: this.lookupMedicalCareResponse,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result) {
          if (index >= 0) {
            this.listExternalPrescription.splice(index, 1);
          }
          this.listExternalPrescription.push(result.newExternalPrescription);
        }

        this.isFirstLoading = false;
      }
    });
  }

  openSadtModal(sadt: MedicalCareSadtStruct, readOnly: boolean, index: number) {
    const dialogRef = this.dialog.open(SadtModalComponent, {
      data: {
        sadt,
        readOnly,
        listPlace: this.lookupMedicalCareResponse.listPlace,
        listExamType: this.listExamType,
        listIdExam: this.listIdExam,
        isAllExams: this.isAllExams,
        isExternal: true,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result) {
          if (index >= 0) {
            this.listSADT.splice(index, 1);
          }
          this.listSADT.push(result.newSadt);
        }
      }
    });
  }

  deleteExternalPrescription(index: number) {
    if (this.readonly) {

      if (this.isLoading)
        return;

      this.isLoading = true;
      this.medicalPrescriptionService.deletePresciption(this.listExternalPrescription[index].idExternalPrescription).subscribe({
        next: response => {
          this.isLoading = false;
          if (response.isError) {
            this.alertService.show("Erro", response.errorDescription, AlertType.error);
            return;
          }
          this.alertService.show("Sucesso", "Prescrição deletada com sucesso", AlertType.success);
          this.listExternalPrescription.splice(index, 1);
        },
        error: error => {
          this.alertService.show('Erro inesperado', error, AlertType.error);
        }
      });
    }
    else
      this.listExternalPrescription.splice(index, 1);
  }

  deleteSadt(index: number) {
    this.listSADT.splice(index, 1);
  }

  addItemAllergies(event: any) {
    this.allergies = event;
  }

  addItemCid(event: any) {
    this.listCid = event;
    if (!this.listCid || this.listCid.length == 0) {
      this.model.get('isCidOnSickNote').setValue(false);
      this.model.get('isCidOnSickNote').disable();
    }
    else
      this.model.get('isCidOnSickNote').enable();
  }

  changeIsolation(event: any) {
    if (event.checked) {
      this.model.get('datetimeEndIsolation').setValidators([DateAfterTodayValidator(), Validators.required]);
      this.model.get('datetimeEndIsolation').setValue(new Date());
    }
    else {
      this.model.get('datetimeEndIsolation').setValidators([]);
      this.model.get('datetimeEndIsolation').setValue(null);
    }

    this.model.get('datetimeEndIsolation').updateValueAndValidity();
  }

  openCancelConfirmModal() {
    let guideWasUpdate: boolean = false;
    if (this.showTissGuideButton) {
      let guideRequest = this.childComponent.getGuidRequest();
      if ((guideRequest.admissionGuideStruct ||
        guideRequest.appointmentGuideStruct ||
        guideRequest.feesGuideStruct ||
        guideRequest.spSadtGuideStruct)) {
        guideWasUpdate = true;
      }
    }
    const dialogRef = this.dialog.open(CancelConfirmModalComponent, {
      data: {
        idRoom: this.idRoom,
        idSector: this.idSector,
        idEpisode: this.selectedEpisode,
        guideWasUpdate
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.isCancel) {
          this.updateStatusQueueByIdEpisode();
          this.twiliovideo.onLeaveRoom(true);
          this.router.navigate(['/medic/medic-patient-list', { idSector: this.idSector, idRoom: this.idRoom }])
        }
      }
    });
  }

  openEpisodeModal(idEpisode: number) {
    const dialogRef = this.dialog.open(EpisodeModalComponent, {
      data: {
        idEpisode: idEpisode,
        lookupMedicalCareResponse: this.lookupMedicalCareResponse,
        listExam: this.listLookupExam,
        room: { idSector: this.idSector, idRoom: this.idRoom }
      },
    });
  }

  openPatientLeaveModal() {
    const dialogRef = this.dialog.open(PatientLeaveModalComponent, {
      data: {
        time: false,
        idQueue: this.idQueue,
        idEpisode: this.idEpisode,
        idRoom: this.idRoom,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        this.patientAvoidance();
      }
    });
  }

  patientAvoidance() {
    let request: PatientAvoidanceLogRequest = new PatientAvoidanceLogRequest();
    request.patientAvoidanceLog.idEpisode = this.idEpisode;
    request.patientAvoidanceLog.idEvasionScreen = EvasionScreenEnum.Telemedicina;
    request.patientAvoidanceLog.idPatient = this.idPatient;
    request.patientAvoidanceLog.patientExitLog = this.patientEvasionMapper();

    this.patientAvoidanceLogService.post(request).subscribe({
      next: (response) => {
        this.isLoading = false;
        this.pacientEvadedByModal = true;
        this.twiliovideo.onLeaveRoom(true);
        this.router.navigate(['/medic/medic-patient-list', { idSector: this.idSector, idRoom: this.idRoom }]);
      },
      error: (error) => {
        this.alertService.show('Erro Inesperado', error, AlertType.error);
        this.isLoading = false;
        this.pacientEvadedByModal = true;
        this.twiliovideo.onLeaveRoom(true);
        this.router.navigate(['/medic/medic-patient-list', { idSector: this.idSector, idRoom: this.idRoom }]);
      }
    });
  }

  patientEvasionMapper(): any {
    let patientAvoidanceLog: string[] = [];
    patientAvoidanceLog.push(this.patientAvoidanceSectionPatientStory());
    patientAvoidanceLog.push(this.patientAvoidanceSectionNewAttendance());
    patientAvoidanceLog.push(this.patientAvoidanceSectionExternalPrescription());
    patientAvoidanceLog.push(this.patientAvoidanceSectionExam());
    patientAvoidanceLog.push(this.patientAvoidanceSectionPrescriptionMemed());
    patientAvoidanceLog.push(this.patientAvoidanceSectionCareLine());
    patientAvoidanceLog.push(this.patientAvoidanceSectionIsolationAttestation());

    return patientAvoidanceLog.join('\n');
  }

  patientAvoidanceSectionPatientStory(): any {
    const infoParts: string[] = [];

    if (this.episodePreEvaluation?.symptomDescription)
      infoParts.push(`Qual é o principal sintoma relatado pelo paciente: ${this.episodePreEvaluation?.symptomDescription}`);

    if (this.episodePreEvaluation?.symptomDurationDescription)
      infoParts.push(`Há quanto tempo sente esses sintomas? ${this.episodePreEvaluation?.symptomDurationDescription}`);

    return infoParts.length
      ? `Relato do paciente\n${infoParts.join(', ')}\n`
      : '';
  }

  patientAvoidanceSectionNewAttendance(): any {
    const infoParts: string[] = [];

    if (this.allergiesToSave && this.allergiesToSave.length > 0) {
      let allergiesName = this.allergiesToSave.map(x => x.allergyName);
      let allergiesNameGrouped = allergiesName.join(', ');
      infoParts.push(`Alergias: ${allergiesNameGrouped}`);
    }

    if (this.model.get('medicalNotes') && this.model.get('medicalNotes').value != '') {
      infoParts.push(`Anotações médicas: ${this.model.get('medicalNotes').value}`);
    }

    if (this.model.get('physicalExamination') && this.model.get('physicalExamination').value != '') {
      infoParts.push(`Exame físico: ${this.model.get('physicalExamination').value}`);
    }

    if (this.listCid && this.listCid.length > 0) {
      let cidsName = this.listCid.map(x => x.cidName);
      let cidsNameGrouped = cidsName.join(', ');
      infoParts.push(`CID: ${cidsNameGrouped}`);
    }

    if (this.model.get('therapeuticPlan') && this.model.get('therapeuticPlan').value != '') {
      infoParts.push(`Plano Terapêutico: ${this.model.get('therapeuticPlan').value}`);
    }

    if (this.model.get('diagnosedHypothesis') && this.model.get('diagnosedHypothesis').value != '') {
      infoParts.push(`Hipótese Diagnóstica: ${this.model.get('diagnosedHypothesis').value}`);
    }

    if (this.model.get('conclusionNote') && this.model.get('conclusionNote').value != '') {
      infoParts.push(`Conclusão: ${this.model.get('conclusionNote').value}`);
    }

    if (this.model.get('temperature') && this.model.get('temperature').value != '') {
      infoParts.push(`Temperatura: ${this.model.get('temperature').value}ºC`);
    }

    if (this.model.get('respiratoryFrequency') && this.model.get('respiratoryFrequency').value != '') {
      infoParts.push(`FR: ${this.model.get('respiratoryFrequency').value}irpm`);
    }

    if (this.model.get('heartRate') && this.model.get('heartRate').value != '') {
      infoParts.push(`FC: ${this.model.get('heartRate').value}bpm`);
    }

    if (this.model.get('glucose') && this.model.get('glucose').value != '') {
      infoParts.push(`GC: ${this.model.get('glucose').value}mg/dL`);
    }

    if (this.model.get('bloodPressure') && this.model.get('bloodPressure').value != '') {
      infoParts.push(`PA: ${this.model.get('bloodPressure').value}mmHG`);
    }

    if (this.model.get('saturation') && this.model.get('saturation').value != '') {
      infoParts.push(`SatO2: ${this.model.get('saturation').value}%`);
    }

    return infoParts.length
      ? `Novo Atendimento\n${infoParts.join(', ')}\n`
      : '';
  }

  patientAvoidanceSectionExternalPrescription(): any {
    const infoParts: string[] = [];

    if (this.listExternalPrescription && this.listExternalPrescription.length > 0) {
      let externalPrescriptionName = this.listExternalPrescription.map(x => x.description);
      let externalPrescriptionNameGrouped = externalPrescriptionName.join(', ');
      infoParts.push(`Descrição: ${externalPrescriptionNameGrouped}`);
    }

    return infoParts.length
      ? `Prescrições Não padronizadas\n${infoParts.join(', ')}\n`
      : '';
  }

  patientAvoidanceSectionExam(): any {
    const infoParts: string[] = [];

    if (this.listSADT && this.listSADT.length > 0) {
      let examTypeName = this.listSADT.map(x => x.typeSadtName);
      let examTypeNameGrouped = examTypeName.join(', ');
      infoParts.push(`Descrição: ${examTypeNameGrouped}`);
    }

    return infoParts.length
      ? `Pedidos de Exames\n${infoParts.join(', ')}\n`
      : '';
  }

  patientAvoidanceSectionPrescriptionMemed(): any {
    const infoParts: string[] = [];

    if (this.listMemedPrescriptionStruct && this.listMemedPrescriptionStruct.length > 0) {
      let memedMedicineName = this.listMemedPrescriptionStruct.flatMap(x => x.listMemedMedicine.map(y => y.name));
      let memedMedicineNameGrouped = memedMedicineName.join(', ');
      infoParts.push(`Medicamento: ${memedMedicineNameGrouped}`);
    }

    return infoParts.length
      ? `Prescrições e Exames externos via Memed\n${infoParts.join(', ')}\n`
      : '';
  }

  patientAvoidanceSectionCareLine(): any {
    const infoParts: string[] = [];

    if (this.listCurrentEpisodeCareLine && this.listCurrentEpisodeCareLine.length > 0) {
      let careLineName = this.listCurrentEpisodeCareLine.map(x => x.careLineName);
      let careLineNameGrouped = careLineName.join(', ');
      infoParts.push(`Linhas de Cuidado: ${careLineNameGrouped}`);
    }

    return infoParts.length
      ? `Linha de Cuidado\n${infoParts.join(', ')}\n`
      : '';
  }

  patientAvoidanceSectionIsolationAttestation(): any {
    const infoParts: string[] = [];

    if (this.isIsolation) {
      infoParts.push(`Isolamento: ${this.isIsolation ? "SIM" : "NÃO"}`);
    }

    if (this.model.get('datetimeEndIsolation') && this.model.get('datetimeEndIsolation').value) {
      infoParts.push(`Último dia de Isolamento: ${this.maskService.formatDateToString(this.model.get('datetimeEndIsolation').value)}`);
    }

    if (this.model.get('sickDaysOffAmount') && this.model.get('sickDaysOffAmount').value) {
      infoParts.push(`Dias de atestado: ${this.model.get('sickDaysOffAmount').value}`);
    }

    if (this.model.get('isCidOnSickNote') && this.model.get('isCidOnSickNote').value) {
      infoParts.push(`Imprimir CID no atestado: ${this.model.get('isCidOnSickNote').value ? "SIM" : "NÃO"}`);
    }

    return infoParts.length
      ? `Isolamento e Atestado\n${infoParts.join(', ')}\n`
      : '';
  }

  onLeaveRoom() {
    //Não implementado
  }

  startWebsocket() {
    if (!this.websocketTelemedicineUtilService.isConnect())
      this.websocketTelemedicineUtilService.connectwebsocketRoom(this.selectedEpisode, this);

    this.websocketTelemedicineUtilService.setContext(this);
    this.websocketTelemedicineUtilService.setFunctions(this.verifyTrigger, null, null, this.patientinternetDown);
  }

  patientinternetDown() {
    this.alertDynamic("Aviso", "A chamada do paciente caiu, ele será retornado para fila!", AlertType.warning);
    this.router.navigate(['/medic/medic-patient-list', { idSector: this.idSector, idRoom: this.idRoom }]);
  }

  private idInterval = setInterval(() => {
    if (this.socket) {
      if (this.socket.connected)
        this.socketDisconneted = false;
      else
        this.socketDisconneted = true;
    }
    else
      this.socketDisconneted = false;

    if (this.onlineOffline !== navigator.onLine)
      this.onlineOffline = navigator.onLine;
  },
    1000);

  ngOnDestroy() {
    let memedSession: MemedPrescriptionSession = new MemedPrescriptionSession();
    memedSession = this.utilService.getMemedPrescriptionSession();

    if (memedSession) {
      this.listMemedPrescriptionStruct = memedSession.listMemedPrescriptionStruct;
      this.listMemedCompletePrescriptionStruct = memedSession.listMemedCompletePrescriptionStruct;
      this.utilService.cleanMemedPrescriptionSession();
    }

    if (this.websocketTelemedicineUtilService.isConnect())
      this.websocketTelemedicineUtilService.clearSocket();

    this.twiliovideo.onLeaveRoom(true);

    let statusQueueRequest: StatusQueueRequest = new StatusQueueRequest();
    if (!this.isAttended && this.pacientEvadedByModal != true && !this.isAttendanceRemoval) {
      statusQueueRequest.idStatusQueue = StatusQueueEnum.nao_atendido;
      statusQueueRequest.idEpisode = this.selectedEpisode;
      statusQueueRequest.idRoom = this.idRoom;
      statusQueueRequest.idQueue = this.idQueue
      this.updateStatusOnNgDestroy(statusQueueRequest)
    }

    clearInterval(this.idInterval);
    clearTimeout(this.idTimeout);
  }

  verifyTrigger(client: any) {
    this.socketDisconneted = false;
    if (client.action == WebsocketTelemedicineActionEnum.patientEvasion) {
      this.websocketTelemedicineUtilService.clearSocket();
      this.openPatientLeaveModal();
    }
  }

  startCall() {
    clearTimeout(this.twilioTimeout);
    if (sessionStorage.getItem("videoToken"))
      this.twiliovideo.onRoomChanged(sessionStorage.getItem("videoToken")).then(result => {
        this.isLoading = false;
      });
  }

  triggerAction(action: number) {
    this.triggerWebsocketService.triggerAction(this.idEpisode, action).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  selectReports(): MedicalCareReportRequest {
    this.medicalCareReportRequest.externalPrescriptionPrintCount = this.listExternalPrescription.length > 0 ? 1 : 0;
    this.medicalCareReportRequest.isSickNoteReport = true;
    this.medicalCareReportRequest.isCompleteMedicalCareReport = true;
    this.medicalCareReportRequest.isExamExternalReport = true;
    this.medicalCareReportRequest.isExamInternalReport = true;
    this.medicalCareReportRequest.isPrescriptionReport = true;
    this.medicalCareReportRequest.isPrescriptionMemedReport = true;
    this.medicalCareReportRequest.isMedicalProcedureReport = true;
    this.medicalCareReportRequest.isIsolationReport = true;
    this.medicalCareReportRequest.isIsolationAcknowledgeReport = true;

    this.medicalCareReportRequest.isBedManagement = this.isBedManagement;
    this.medicalCareReportRequest.idBed = null;
    this.medicalCareReportRequest.isOnline = true;
    this.medicalCareReportRequest.idSector = this.idSector;

    if (this.newEmail && this.newEmail != null)
      this.medicalCareReportRequest.email = this.newEmail;
    else
      this.medicalCareReportRequest.email = this.patientEmail;

    return this.medicalCareReportRequest;
  }

  updateEmailPatient(saveCompletedMedicalRecord: MedicalRecordTelemedicineRequest) {
    let updateEmail = new UpdateEmailPatientRequest();
    updateEmail.email = this.newEmail;
    updateEmail.idPatient = this.idPatient;

    this.patientService.updateEmailPatient(updateEmail).subscribe({
      next: response => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error, true);
          return;
        }

        this.finalizeAttendance(saveCompletedMedicalRecord);
      },
      error: error => {
        this.alertDynamic('Erro', error, AlertType.error, true);
      }
    });
  }

  makeCallPost() {
    let medicalCareRequest = this.mapToRequest();
    medicalCareRequest.idRoomAttendance = this.idRoom;
    medicalCareRequest.administrativeRelease = false;

    //Adicionando linhas de cuidado ao request
    let episodeCareLineRequest = new EpisodeCareLineRequest();
    episodeCareLineRequest.listIdCareLine = this.listNewEpisodeCareLine.map(x => x.idCareLine);
    episodeCareLineRequest.idEpisode = this.selectedEpisode;
    episodeCareLineRequest.phoneNumber = this.patientCellPhone;

    let saveCompletedMedicalRecord: MedicalRecordTelemedicineRequest = new MedicalRecordTelemedicineRequest();
    saveCompletedMedicalRecord.medicalCareReportRequest = this.selectReports();
    saveCompletedMedicalRecord.billingDataRequest = this.mapToBillingDataRequest();
    saveCompletedMedicalRecord.episodeStatusRequest = this.startConclusion();
    saveCompletedMedicalRecord.medicalRecordRequest = medicalCareRequest;
    saveCompletedMedicalRecord.episodeCareLineRequest = episodeCareLineRequest;

    if (this.childComponent) {
      saveCompletedMedicalRecord.guideRequest = this.childComponent.getGuidRequest();
      if (this.showTissGuideButton) {
        let guide = this.childComponent.getGuidRequest();
        if (guide)
          saveCompletedMedicalRecord.guideRequest = guide;
      }
    }
    this.isAttended = true;

    if (this.sign) {
      const dialogRef = this.dialog.open(SignatureCredentialsModalComponent, {
        disableClose: this.requireSignature,
      });

      dialogRef.afterClosed().subscribe({
        next: (result) => {
          if (!result) {
            this.isLoading = false;
            return;
          }
          if (result.sign) {
            saveCompletedMedicalRecord.medicalRecordRequest.idSignatureProvider = result.idSignatureProvider;
            saveCompletedMedicalRecord.medicalRecordRequest.signatureAuthentication = JSON.stringify(result.signatureAuthentication);

            if (this.listMemedPrescriptionStruct && this.listMemedPrescriptionStruct.length > 0 ||
              this.listCurrentEpisodeCareLine && this.listCurrentEpisodeCareLine.length > 0 ||
              this.listExternalPrescription && this.listExternalPrescription.length > 0 ||
              this.listSADT && this.listSADT.length > 0 ||
              this.isIsolation) { this.openConclusionModal(saveCompletedMedicalRecord); }
            else { this.finalizeAttendance(saveCompletedMedicalRecord); }
          }
          else {
            saveCompletedMedicalRecord.medicalRecordRequest.idSignatureProvider = null;
            saveCompletedMedicalRecord.medicalRecordRequest.signatureAuthentication = null;
          }
        }
      });
    }
    else {
      saveCompletedMedicalRecord.medicalRecordRequest.idSignatureProvider = null;
      saveCompletedMedicalRecord.medicalRecordRequest.signatureAuthentication = null;
      this.finalizeAttendance(saveCompletedMedicalRecord);
    }
  }

  openCertificatePinModal() {
    const dialogRef = this.dialog.open(PinCertificateModalComponent, {
      data: {
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.isICPSigned) {
          this.certificatePin = result.pin;
          this.isIcpBrailSigned = result.isICPSigned;
        }

        this.makeCallPost();
      }
    });
  }

  showSelfTtriageResume() {
    const dialogRef = this.dialog.open(SelfTriageResumeModalComponent, {
      data: {
        selfTriage: this.lastSelfTriage,
      },
    });
  }

  updateStatusQueueByIdEpisode() {
    if (this.socket)
      this.socket.disconnect();

    clearInterval(this.idInterval);

    if (this.idEpisode) {
      this.statusQueueService.UpdateStatusQueue(this.idEpisode).subscribe((response) => {
        if (response.isError) {
          this.alertService.show("Erro", response.errorDescription, AlertType.error);
          return;
        }

        this.alertService.show("Sucesso", "Fila do episódio atualizada com sucesso", AlertType.success);
        this.triggerAction(WebsocketTelemedicineActionEnum.medicEvasion);
      });
    }
    else
      this.alertService.show("Erro", "Episódio não encontrado", AlertType.error)
  }

  updateStatusOnNgDestroy(statusQueueRequest: StatusQueueRequest) {
    if (this.socket)
      this.socket.disconnect();

    clearInterval(this.idInterval);

    this.updateStatusQueueService.updateStatus(statusQueueRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error, true);
          return;
        }

        this.triggerAction(WebsocketTelemedicineActionEnum.medicEvasion);
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getMemedPrescription() {
    this.healthUnitMemedPrescriptionService.getMemedPrescriptionConfig().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error, true);
          return;
        }
        if (response.memedPrescription) {
          this.isMemedPrescription = response.memedPrescription;
          this.searchTokenMemed();
        }
      },
      error: (error) => {
        this.alertDynamic('Erro', error, AlertType.error, true);
      }
    });
  }

  searchTokenMemed() {
    this.userMemedService.getUserTokenMemed().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isFirstLoading = false;
          this.isMemedPrescription = false;
          return;
        }

        this.userTokenMemed = response.userTokenMemed;
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error, false);
        this.isMemedPrescription = false;
      }
    });
  }

  openMemedPrescription() {
    if (!this.patientCellPhone)
      this.openGetPhoneModal();
    else
      this.showMemed = true;
  }

  closeMemedModal() {
    this.showMemed = false;
  }

  openGetPhoneModal() {
    const dialogRef = this.dialog.open(GetPhoneModalComponent, {});
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result && result.phone) {
          this.patientCellPhone = result.phone;
          this.showMemed = true;
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  deleteMemedPrescription(index: number) {
    let listIdMemed: number[] = [];
    listIdMemed.push(this.listMemedPrescriptionStruct[index].idMemedPrescription)
    const dialogRef = this.dialog.open(MemedPrescriptionDeleteModalComponent, {
      data: {
        listIdMemed: listIdMemed,
        userTokenMemed: this.userTokenMemed,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result && result.delete) {
          let indexComplete = this.listMemedCompletePrescriptionStruct.findIndex(x => x.prescricao.id == this.listMemedPrescriptionStruct[index].idMemedPrescription);
          this.listMemedPrescriptionStruct.splice(index, 1);
          this.listMemedCompletePrescriptionStruct.splice(indexComplete, 1);
          let memedSession: MemedPrescriptionSession = new MemedPrescriptionSession();
          memedSession.listMemedPrescriptionStruct = this.listMemedPrescriptionStruct;
          memedSession.listMemedCompletePrescriptionStruct = this.listMemedCompletePrescriptionStruct;
          this.utilService.setMemedPrescriptionSession(memedSession);
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  newMemedPrescription(event: any) {
    if (event && event.memedPrescriptionStruct) {
      let hasPrescription = this.listMemedPrescriptionStruct.find(x => x.idMemedPrescription == event.memedPrescriptionStruct.idMemedPrescription)
      if (!hasPrescription) {
        this.listMemedPrescriptionStruct.push(event.memedPrescriptionStruct);

        this.listMemedCompletePrescriptionStruct.push(event.completePrescriptionStruct)
        let listidMemedPrescription = this.listMemedPrescriptionStruct.map(x => x.idMemedPrescription);
        if (this.websocketAttendanceUtilService.isConnect() && this.userTokenMemed)
          this.websocketAttendanceUtilService.emitMemedPrescriprtionSave(this.idRoom, this.userTokenMemed, listidMemedPrescription);

        let memedSession: MemedPrescriptionSession = new MemedPrescriptionSession();
        memedSession.listMemedPrescriptionStruct = this.listMemedPrescriptionStruct;
        memedSession.listMemedCompletePrescriptionStruct = this.listMemedCompletePrescriptionStruct;
        this.utilService.setMemedPrescriptionSession(memedSession);
      }
    }
  }

  hasReport(response: NewReportResponse) {
    return Boolean(
      response.wristbandReportPdf64 ||
      response.medicalCareReport64 ||
      response.sadtInternalReport64 ||
      response.sadtExternalReport64 ||
      response.prescriptionReport64 ||
      response.externalPrescriptionReport64 ||
      response.sickNoteReport64 ||
      response.isolationReport64 ||
      response.isolationAcknowledgeReport64 ||
      response.procedureReport64 ||
      response.allReportsConcat64 ||
      this.allPrivateBillingGuideReportStruct?.appointmentGuideReport64 ||
      this.allPrivateBillingGuideReportStruct?.admissionGuideReport64 ||
      this.allPrivateBillingGuideReportStruct?.feesGuideReport64 ||
      this.allPrivateBillingGuideReportStruct?.spSadtGuideReport64
    );
  }

  checkSigningResponsibility(): boolean {
    let token: UserLoginResponse = this.utilService.getToken();
    return (token.listRole.map(x => x.idRole).includes(UserRoleEnum.requerer_assinatura_medico) && !token.isMaster);
  }

  finalizeAttendance(saveCompletedMedicalRecord: MedicalRecordTelemedicineRequest) {
    this.medicalRecordTelemedicineService.postMedicalRecordTelemedicine(saveCompletedMedicalRecord).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Error', response.errorDescription, AlertType.error, true);
          return;
        }

        if (response.allPrivateBillingGuideReportStruct) {
          this.allPrivateBillingGuideReportStruct = new AllPrivateBillingGuideReportStruct();
          this.allPrivateBillingGuideReportStruct = response.allPrivateBillingGuideReportStruct;
        }

        if (this.hasErrorInSignedTelemedicine(response).length > 0) {
          let listSignedHasError = this.hasErrorInSignedTelemedicine(response);

          const dialogRef = this.dialog.open(SignedErrorModalComponent, {
            disableClose: true,
            data: {
              listSignedHasError: listSignedHasError
            },
          });
          dialogRef.afterClosed().subscribe({
            next: (result) => {
              if (result.confirm) {
                let retrySignedRequest: MedicalRecordTelemedicineRetrySignedRequest = new MedicalRecordTelemedicineRetrySignedRequest();

                retrySignedRequest.medicalCareReportRequest = saveCompletedMedicalRecord.medicalCareReportRequest;
                retrySignedRequest.medicalRecordRequest = saveCompletedMedicalRecord.medicalRecordRequest;
                retrySignedRequest.reportResponse = response;
                retrySignedRequest.newAttemptSigned = true;
                this.retrySigned(retrySignedRequest);
              }
              else if (!result.confirm)
                this.hasReport(response);
            }
          });
        }
        else if (this.hasReport(response))
          this.openReports(response);
        else
          this.redirectAndLeaveRoom();
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error, true);
        return;
      }
    });
  }

  retrySigned(retrySigned: MedicalRecordTelemedicineRetrySignedRequest) {
    this.medicalRecordTelemedicineService.retrySigned(retrySigned).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Error', response.errorDescription, AlertType.error, true);
          return;
        }

        if (response.allPrivateBillingGuideReportStruct) {
          this.allPrivateBillingGuideReportStruct = new AllPrivateBillingGuideReportStruct();
          this.allPrivateBillingGuideReportStruct = response.allPrivateBillingGuideReportStruct;
        }

        if (this.hasErrorInSignedTelemedicine(response).length > 0)
          this.alertDynamic('Error', "Não foi possível concluir a assinatura! O documento gerado está disponível no histórico do episódio", AlertType.error, false);

        if (this.hasReport(response))
          this.openReports(response);
        else
          this.redirectAndLeaveRoom();
      },
      error: (error) => {
        this.retrySigned(retrySigned);
        // this.alertDynamic('Erro inesperado', error, AlertType.error, true);
        // return;
      }
    });
  }

  getLookupSadtType() {
    this.examTypeService.listAllExamTypeWithExams().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listExamType = response.listExamType;
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  onRemoveAttendant(thisParam, res: any) {
    thisParam.isAttendanceRemoval = false;

    if (res.userLogin != thisParam.userLogin) {
      thisParam.isAttendanceRemoval = true;

      thisParam.websocketAttendanceUtilService.clearSocket();
      let ref = thisParam.dialog.open(AttendanceOccupiedRemoveModalComponent, { data: { ...res } });
      ref.afterClosed().subscribe(() => {
        thisParam.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          thisParam.router.navigate(['/medic/medic-patient-list', { idSector: thisParam.idSector, idRoom: thisParam.idRoom }]));
      });
    }
  }

  openReports(response: NewReportResponse) {
    this.triggerAction(WebsocketTelemedicineActionEnum.endMedicalCare);
    let reportDialog = this.dialog.open(ReportViewSelectModalComponent, {
      data: {
        medicalCareReport: response.medicalCareReport64,
        examInternalReport: response.sadtInternalReport64,
        examExternalReport: response.sadtExternalReport64,
        internalPrescriptionReport: response.prescriptionReport64,
        externalPrescriptionReport: response.externalPrescriptionReport64,
        procedureReport: response.procedureReport64,
        sickNoteReport: response.sickNoteReport64,
        isolationReport: response.isolationReport64,
        isolationAcknowledgeReport: response.isolationAcknowledgeReport64,
        appointmentGuideReport: this.allPrivateBillingGuideReportStruct?.appointmentGuideReport64,
        admissionGuideReport: this.allPrivateBillingGuideReportStruct?.admissionGuideReport64,
        feesGuideReport: this.allPrivateBillingGuideReportStruct?.feesGuideReport64,
        spSadtGuideReport: this.allPrivateBillingGuideReportStruct?.spSadtGuideReport64,
        listMemedPrescriptionPdf: response.listMemedPrescriptionPdf64,
        allReportsConcat: response.allReportsConcat64,
        sign: this.sign
      },
    });
    reportDialog.afterClosed().subscribe(() => {
      this.redirectAndLeaveRoom();
    });
  }

  redirectAndLeaveRoom() {
    this.twiliovideo.onLeaveRoom(true);
    this.router.navigate(['/medic/medic-patient-list', { idSector: this.idSector, idRoom: this.idRoom, idService: this.idService }])
  }

  hasErrorInSignedTelemedicine(response: NewReportResponse): string[] {
    let listSignedHasError: string[] = [];

    if (response.sickNoteReportSignError)
      listSignedHasError.push(response.sickNoteFileName);
    if (response.isolationAcknowledgeReportSignError)
      listSignedHasError.push(response.isolationAcknowledgeFileName);
    if (response.externalPrescriptionReportSignError)
      listSignedHasError.push(response.externalPrescriptionFileName);
    if (response.isolationReportSignError)
      listSignedHasError.push(response.isolationFileName);
    if (response.sadtExternalReportSignError)
      listSignedHasError.push(response.sadtExternalFileName);

    return listSignedHasError;
  }

  alertDynamic(alertTypeDescription: string, alertDescription: string, alertType: AlertType, endLoading: boolean = false) {
    if (alertType && alertType.valueOf() == AlertType.error)
      console.log(alertDescription);

    this.alertService.show(alertTypeDescription, alertDescription, alertType ? alertType.valueOf() : AlertType.error);
    if (endLoading)
      this.endLoading();
  }

  endLoading() {
    this.isLoading = false;
  }
}