<div [formGroup]="formGroup">
    <div class="row" formArrayName="{{formArrayName}}"
        *ngFor="let item of formGroup.get(formArrayName)['controls']; let i = index;">
        <div class="col-5">
            <div [formGroupName]="i">
                <mat-form-field appearance="outline">
                    <mat-label>Relatório</mat-label>
                    <mat-select formControlName="idReportFunctionHealthUnit">
                        <mat-option *ngFor="let item of listReport"
                            value="{{item.idReportFunctionHealthUnit}}">{{item.healthUnitName}} -
                            {{item.reportName}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="item.get('idReportFunctionHealthUnit').invalid">Informe o
                        relatório</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="col-2 col-sm-2 col-md-2">
            <div [formGroupName]="i">
                <mat-form-field appearance="outline">
                    <mat-label>Periodicidade</mat-label>
                    <mat-select formControlName="idFrequency">
                        <mat-option *ngFor="let item of listFrequency" value="{{item.idFrequency}}">
                            {{item.frequencyName}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="item.get('idFrequency').invalid">Informe o
                        relatório</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="col-2 col-sm-2 col-md-2" *ngIf="monthly == item.get('idFrequency').value">
            <div [formGroupName]="i">
                <mat-form-field appearance="outline">
                    <mat-label>Dia do mês</mat-label>
                    <mat-select formControlName="idDaysMonth">
                        <mat-option *ngFor="let item of listDaysMonth" value="{{item.idDaysMonth}}">
                            {{item.dayMonth}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="item.get('idDaysMonth').invalid">Informe o
                        relatório</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="col-2 col-sm-2 col-md-2" *ngIf="weekly == item.get('idFrequency').value">
            <div [formGroupName]="i">
                <mat-form-field appearance="outline">
                    <mat-label>Dia da semana</mat-label>
                    <mat-select formControlName="idDaysWeek">
                        <mat-option *ngFor="let item of listDaysWeek" value="{{item.idDaysWeek}}">
                            {{item.nameDayWeek}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="item.get('idDaysWeek').invalid">Informe o
                        relatório</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="col-2 col-sm-2 col-md-2">
            <div [formGroupName]="i">
                <mat-form-field appearance="outline">
                    <mat-label>Hora do Disparo</mat-label>
                    <input matInput type="time" formControlName="value">
                    <mat-error *ngIf="item.get('value').invalid">Informe o
                        relatório</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="col-5">
            <div [formGroupName]="i">
                <mat-form-field appearance="outline">
                    <mat-label>Usuário</mat-label>
                    <mat-select formControlName="idUser" multiple>
                        <mat-option *ngFor="let item of listUser" value="{{item.idUser}}">{{item.userName}} -
                            {{item.email}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="item.get('idUser').invalid">Informe o
                        relatório</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="col-2 col-sm-2 col-md-2">
            <button class="panel-origin-button " color="primary" mat-mini-fab type="button" (click)="addNext()">
                <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
            </button>
            <button class="panel-origin-button " color="primary" mat-mini-fab type="button"
                (click)="removeExpression(i)">
                <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
            </button>
        </div>
    </div>
</div>

<button *ngIf="formGroup.get(formArrayName)['controls'].length == 0" color="accent" class="" mat-flat-button
    type="button" (click)="addNext()">
    <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
    Adicionar nova regra
</button>