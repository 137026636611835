import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { SearchPersonFilterRequest } from '../../requests/integration-siga/search-person-filter.request';
import { PatientSigaResponse } from '../../responses/integration-siga/patient-siga.response';

@Injectable({
  providedIn: 'root'
})
export class SigaSearchPatientService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  public searchPatientByCpf(cpf: string): Observable<PatientSigaResponse> {
    let uri = `SigaSearchPatient/cpf/${cpf}`
    return this.httpClient.get<PatientSigaResponse>(environment.urlApiOrchestratorPatient + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public searchPatientByCns(cns: string): Observable<PatientSigaResponse> {
    let uri = `SigaSearchPatient/cns/${cns}`
    return this.httpClient.get<PatientSigaResponse>(environment.urlApiOrchestratorPatient + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public searchPatientById(idEpisode: number): Observable<PatientSigaResponse> {
    let uri = `SigaSearchPatient/idEpisode/${idEpisode}`
    return this.httpClient.get<PatientSigaResponse>(environment.urlApiOrchestratorPatient + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public searchPatientFullTerms(request: SearchPersonFilterRequest): Observable<PatientSigaResponse> {
    let uri = `SigaSearchPatient`
    return this.httpClient.post<PatientSigaResponse>(environment.urlApiOrchestratorPatient + uri, request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
