import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PlanService } from 'src/app/shared/services/API/private-billing/plan.service';
import { Plan } from 'src/app/shared/services/models/private-billing/plan.model';
import { MatDialog } from '@angular/material/dialog';
import { PlanModalComponent } from './plan-modal/plan-modal.component';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { AlertModalComponent } from 'src/app/shared/components/alert-modal/alert-modal.component';
import { PriceProcedurePlanStruct } from 'src/app/shared/services/structs/private-billing/price-procedure-plan.struct';

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.css']
})
export class PlanComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    private planService: PlanService,
    private cdr: ChangeDetectorRef,
    private alertService: AlertService,
  ) { }

  @Input() listPlan: Plan[] = [];
  @Input() nameHealthcareAgreement: string;
  @Input() idHealthcareAgreement: number;
  @Input() fullListSize: number;
  @Output() planDataChanged = new EventEmitter<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public searchText: string = null;
  public isLoading: boolean = false;
  public pageIndex: number = 0;
  public pageSize: number = 50;

  ngOnInit(): void {
  }

  changePage(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.search();
  }

  private timeoutKeySearch: any = null;
  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.pageIndex = 0;
        $this.search();
      }
    },
      1000);
  }

  search() {
    this.isLoading = true;

    this.planService.GetAll(this.idHealthcareAgreement, this.searchText, this.pageIndex).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.listPlan = response.listPlan;
        this.fullListSize = response.fullListSize;
        this.pageSize = response.pageSize;
        this.cdr.detectChanges();
        this.isLoading = false;
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  deletePlan(plan: Plan) {
    this.isLoading = true;

    this.planService.delete(plan.idPlan).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        let existingPlanIndex = this.listPlan.findIndex(x => x.idPlan === plan.idPlan);

        if (existingPlanIndex !== -1) {
          this.listPlan.splice(existingPlanIndex, 1);
          this.cdr.detectChanges();
          this.onDataChange(this.listPlan);
        }

        this.alertService.show('Sucesso', "Plano deletado com sucesso!", AlertType.success);
        this.search();
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  openModal(plan: Plan) {
    const dialogRef = this.dialog.open(PlanModalComponent, {
      data: {
        nameHealthcareAgreement: this.nameHealthcareAgreement,
        idHealthcareAgreement: this.idHealthcareAgreement,
        plan: plan
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.success) {
          const existingPlanIndex = plan !== null ? this.listPlan.findIndex(x => x.idPlan === plan.idPlan) : -1;

          if (existingPlanIndex !== -1)
            this.listPlan[existingPlanIndex] = result.plan;
          else
            this.listPlan.push(...[result.plan]);
          this.fullListSize += 1;
          this.cdr.detectChanges();
        }
      }
    });
  }

  private throwException(error: string) {
    console.log(error);
    this.alertService.show('Erro inesperado', error, AlertType.error);
    this.isLoading = false;
  }

  openAlertModal(plan: Plan) {
    let description: string = 'A exclusão deste plano poderá remover o vínculo com procedimentos já cadastrados. Deseja continuar?';
    const dialogRef = this.dialog.open(AlertModalComponent, {
      data: {
        isTwoButtonsModal: true,
        title: 'Atenção!',
        description: description,
        hasImage: true
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result.confirm == true) {
          this.deletePlan(plan);
          this.search();
        }
      }
    });
  }

  onDataChange(listPlan: Plan[]) {
    this.planDataChanged.emit(listPlan);
  }
}