import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { ClassificationData, UtilsClassificationService } from '../../utils.service';

@Component({
  selector: 'app-white-patient-parent',
  templateUrl: './white-patient-parent.component.html',
  styleUrls: ['./white-patient-parent.component.css']
})
export class WhitePatientParentComponent implements OnInit {

  constructor(
    public router: Router,
    public utilClassification: UtilsClassificationService,
    private activatedRoute: ActivatedRoute,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.classification;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.classification_pre_registration;
  public isFirstLoading: boolean = true;
  public idEpisode: number;
  public idQueue: number;
  public idRoom: number;

  ngOnInit(): void {
    let data = this.utilClassification.getClassificationData();
    this.idEpisode = data.idEpisode;
    this.idRoom = parseInt(this.activatedRoute.snapshot.paramMap.get('idRoom')); 
    this.idQueue = parseInt(this.activatedRoute.snapshot.paramMap.get('idQueue'));  
  }

  selectArrivalReason() {
    this.router.navigate(['/classification/forward', { idEpisode: this.idEpisode, idRoom: this.idRoom, idQueue: this.idQueue}]);
  }

  goBack() {
    this.router.navigate(['/classification/flowchart-select', { idEpisode: this.idEpisode, idRoom: this.idRoom, idQueue: this.idQueue}]);
  }

  firstLoading(event: boolean) {
    this.isFirstLoading = event;
  }
}
