import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilsClassificationService } from 'src/app/classification/utils.service';
import { DiscriminatorTypeEnum } from 'src/app/shared/enum/discriminator-type.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { DiscriminatorModel } from 'src/app/shared/services/models/risk-classification/discriminator.model';
import { UtilsMeasurerService } from '../../protocol-v2/utils-measurer.service';
import { DeviceTypeSectorResponse } from 'src/app/shared/services/responses/risk-classification/device-type-sector.response';

@Component({
  selector: 'app-pressure-modal',
  templateUrl: './pressure-modal.component.html',
  styleUrls: ['./pressure-modal.component.css']
})
export class PressureModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<PressureModalComponent>,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private utilService: UtilsClassificationService,
    private maskService: MaskService,
    public measurerService: UtilsMeasurerService) { }

  public model: UntypedFormGroup;
  public discriminator: DiscriminatorModel;
  public masks: Masks;
  public discriminatorType: DiscriminatorTypeEnum = DiscriminatorTypeEnum.PressaoArterial;
  public systolic: number;
  public diastolic: number;
  public autisticChecked: boolean = false;
  public setUppercaseText: boolean = false;

  ngOnInit(): void {
    let deviceTypeSectorResponse: DeviceTypeSectorResponse = this.utilService.getDevicetypeBySector()
    if (deviceTypeSectorResponse && deviceTypeSectorResponse.deviceTypeSector) {
      if (deviceTypeSectorResponse.deviceTypeSector.setUppercaseText)
        this.setUppercaseText = deviceTypeSectorResponse.deviceTypeSector.setUppercaseText;
    }
    
    this.measurerService.setModal(this.discriminatorType);
    this.masks = this.maskService.getMasks();
    this.discriminator = this.data.discriminator;
    this.systolic = this.data.discriminatorsValues[this.discriminatorType] ? this.data.discriminatorsValues[this.discriminatorType].split("/")[0] : null;
    this.diastolic = this.data.discriminatorsValues[this.discriminatorType] ? this.data.discriminatorsValues[this.discriminatorType].split("/")[1] : null;
    this.model = this.formBuilder.group({
      systolic: [this.systolic, Validators.required],
      diastolic: [this.diastolic, Validators.required],
      priorityAutistic: [false],
      discriminatorSelectionMotive: []
    });
  }

  changePriorityAutistic(event: any) {
    this.model.get('systolic').setValue(null);
    this.model.get('diastolic').setValue(null);
    this.autisticChecked = event.checked
    if (event.checked) {
      this.model.get('discriminatorSelectionMotive').setValidators([Validators.required]);
      this.model.get('systolic').disable();
      this.model.get('diastolic').disable();
    } else {
      this.model.get('discriminatorSelectionMotive').setValidators(Validators.nullValidator);
      this.model.get('systolic').enable();
      this.model.get('diastolic').enable();
    }
    this.model.get('discriminatorSelectionMotive').updateValueAndValidity();
  }

  close() {
    this.measurerService.setModal(null);
    this.matDialogRef.close();
  }

  submit() {
    if (this.data.discriminatorsValues[this.discriminatorType] &&
      ((this.systolic == this.model.get("systolic").value &&
        this.diastolic == this.model.get("diastolic").value) ||
        (this.model.get("systolic").value + "/" + this.model.get("diastolic").value != this.data.discriminatorsValues[this.discriminatorType] &&
          !(this.model.get("systolic").value || this.model.get("diastolic").value)))) {
      this.model.get("systolic").setValue(this.data.discriminatorsValues[this.discriminatorType].split("/")[0]);
      this.model.get("diastolic").setValue(this.data.discriminatorsValues[this.discriminatorType].split("/")[1]);
    }
    if (this.model.invalid) {
      if (this.model.get('priorityAutistic').value) {
        this.alertService.show('Erro', "Preencha o motivo!", AlertType.error);
      } else {
        this.alertService.show('Erro', "Preencha os campos de Pressão Arterial!", AlertType.error);
      }
      return;
    }
    if (this.model.get('priorityAutistic').value) {
      this.matDialogRef.close({ value: null, select: true, motive: this.model.get('discriminatorSelectionMotive').value });
    } else {
      let value1 = this.model.get("systolic").value.replace("_", "");
      let value2 = this.model.get("diastolic").value.replace("_", "");
      if (parseInt(value2) > parseInt(value1)) {
        this.alertService.show('Erro', "A pressão diastólica não pode ser maior do que a sistólica!", AlertType.error);
        return;
      }
      let select = this.utilService.verifySelectPressure(this.discriminator, value1, value2);
      this.measurerService.setModal(null);
      this.matDialogRef.close({ value: value1 + "/" + value2, select });
    }
  }
}

