import { Component, Inject, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertModalComponent } from 'src/app/shared/components/alert-modal/alert-modal.component';
import { NatureOfAttendanceEnum } from 'src/app/shared/enum/medical-record/nature-of-attendance.enum';
import { AttendPatientService } from 'src/app/shared/services/API/orchestrator-schedule/attend-patient.service';
import { OrchestratorPatientAppointmentService } from 'src/app/shared/services/API/orchestrator-schedule/orchestrator-patient-appointment.service';
import { ClientUserPatientService } from 'src/app/shared/services/API/patient-center/client-user-patient.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { UserPatient } from 'src/app/shared/services/models/patient-center/user-patient.model';
import { PatientAppointment } from 'src/app/shared/services/models/schedule/patient-appointment.model';
import { ConfirmCancelAppointmentModalComponent } from './confirm-cancel-appointment-modal/confirm-cancel-appointment-modal.component';
import { PatientAppointmentRequest } from 'src/app/shared/services/requests/orchestrator-schedule/patient-appointment.request';
import { AuthService } from 'src/app/shared/services/auth.service';
import { HealthUnitStruct } from 'src/app/shared/services/structs/user/health-unit.struct';
import { AppointmentEnum } from 'src/app/shared/enum/schedule/appointment.enum';

@Component({
  selector: 'app-view-scheduled-medical-care-modal',
  templateUrl: './view-scheduled-medical-care-modal.component.html',
  styleUrls: ['./view-scheduled-medical-care-modal.component.css']
})
export class ViewScheduledMedicalCareModalComponent implements OnInit {
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<ViewScheduledMedicalCareModalComponent>,
    private alertService: AlertService,
    private router: Router,
    private clientUserPatientService: ClientUserPatientService,
    private attendPatientService: AttendPatientService,
    private orchestratorPatientAppointmentService: OrchestratorPatientAppointmentService,
    private authService: AuthService
  ) { }

  public isLoading: boolean;
  public isFirstLoading: boolean;
  public patientAppointment: PatientAppointment;
  public userPatient: UserPatient;
  public appointment: string;
  public onlineAttendance: number  = NatureOfAttendanceEnum.online;
  public inPersonAttendance:number = NatureOfAttendanceEnum.presencial;
  public hasPermissionToCancelAppointment: boolean = false;
  public healthUnit: HealthUnitStruct;

  ngOnInit(): void {
    this.isLoading = false;
    this.userPatient = new UserPatient();
    if (!this.data || !this.data.patientAppointment){
      this.alertService.show('Erro', "Erro na abertura do agendamento", AlertType.error);
      this.close();
    }

    this.patientAppointment = this.data.patientAppointment;
    this.patientAppointment.datetimeStart = new Date(this.patientAppointment.datetimeStart);
    this.appointment = `${this.patientAppointment.datetimeStart.toLocaleString('pt-BR')}`;
    this.hasPermissionToCancelAppointment = this.data.hasPermissionToCancelAppointment;
    this.healthUnit = this.authService.getTokenMenu().healthUnit;

    this.searchUserPatient();
  }

  close(){
    this.matDialogRef.close(); 
  }

  attendPatient() {
    if (this.isLoading)
      return;

    this.isLoading = true;

    let diffNowToStart: number = this.differenceInMinutesBetweenAppointmentDateAndUtcNow(this.patientAppointment.datetimeStart);
    let diffNowToEnd: number = this.differenceInMinutesBetweenAppointmentDateAndUtcNow(this.patientAppointment.datetimeEnd);
    if((diffNowToStart - AppointmentEnum.tempoAntesDaConsultaParaSerCanceladaPeloAtendente) <= 0 && diffNowToEnd >= 0) {

      this.attendPatientService.getAttendance(this.patientAppointment.idPatientAppointment).subscribe({
        next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        sessionStorage.setItem('videoAppointmentToken', response.tokenUser);
        sessionStorage.setItem('appointmentDuration', response.appointmentDuration.toString());
        this.router.navigate(['/medic/attend-patient-online-appointment', { id_patient_appointment: this.patientAppointment.idPatientAppointment }]);
        this.matDialogRef.close(); 
        this.isLoading = false;
      },
        error: (error) => {
          this.isLoading = false;
          this.alertService.show('Erro inesperado', error, AlertType.error);
        }
      });
    } else if((diffNowToStart - AppointmentEnum.tempoAntesDaConsultaParaSerCanceladaPeloAtendente) > 0 && diffNowToEnd > 0) {
      this.dialog.open(AlertModalComponent, {
        data: {
          title: 'Acesso negado',
          description: 'Você só pode entrar na consulta 5 minutos antes do horário agendado.'
        },
      });
      this.isLoading = false;
    } else if(diffNowToEnd < 0) {
      this.dialog.open(AlertModalComponent, {
        data: {
          title: 'Acesso negado',
          description: 'Não é possível iniciar consultas antigas ou já finalizadas.'
        },
      });
      this.isLoading = false;
    }

  }

  searchUserPatient() {
    this.isFirstLoading = true;
    this.clientUserPatientService.getUserPatient(this.patientAppointment.idUserPatient).subscribe({
      next: (response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isFirstLoading = false;
        return;
      }
      this.userPatient = response.userPatient;
      this.isFirstLoading = false;
    },
      error: (error) => {
        this.isFirstLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  clickCancelAppointmentButton(){
    if (this.isLoading)
      return;    

    let diffNowToStart: number = this.differenceInMinutesBetweenAppointmentDateAndUtcNow(this.patientAppointment.datetimeStart);
    if((diffNowToStart - AppointmentEnum.tempoAntesDaConsultaParaSerCanceladaPeloAtendente) <= 0) {
      this.dialog.open(AlertModalComponent, {
        data: {
          title: 'Acesso negado',
          description: 'Não é possível cancelar consultas à cinco minutos do início, em andamento ou já finalizadas.'
        },
      });
      this.isLoading = false;
    }
    else{
      this.isLoading = true;
      const dialogRef = this.dialog.open(ConfirmCancelAppointmentModalComponent, {
        panelClass: "confirm-cancel-appointment-modal"
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result && result.cancel){
          this.cancelAppointment();
        } 
        else
          this.isLoading = false;
      });
    }    
  }

  cancelAppointment(){
    this.isLoading = true;
    let request: PatientAppointmentRequest = new PatientAppointmentRequest();
    request.isCancellation = true;
    request.idPatientAppointment = this.patientAppointment.idPatientAppointment;

    this.orchestratorPatientAppointmentService.cancelPatientAppointment(request).subscribe({
      next: (response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.matDialogRef.close({cancelAppointment: true}); 
      this.isLoading = false;
    },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  differenceInMinutesBetweenAppointmentDateAndUtcNow(datetimeToCompare: Date) {
    let todayDateLocal: Date = new Date();
    let todayDateUtc:Date = new Date(todayDateLocal.getTime() + (todayDateLocal.getTimezoneOffset() * 60000));

    let dateToCompare: Date = new Date(datetimeToCompare);
    dateToCompare = new Date(dateToCompare.getTime() - (this.healthUnit.timeZoneValue * 3600000));

    var diff = (dateToCompare.getTime() - todayDateUtc.getTime()) / 1000;
    diff /= 60;

    return diff;
  }

}
