export class TriageStruct {
    public idTriage: number;
    public idUser: number;
    public idUserClassification: number;
    public idEpisode: number;
    public gravityName: string;
    public gravityColor: string;
    public gravityColorCode: string;
    public userClassificationName: string;
    public serviceTime: string;
    public waitingTime: string;
    public gravityOrdenation: number;
    public dateTimeInclusion: Date;
    public startClassification: Date;
    public endClassification: Date;
    public idRoomAttendance: number;
    public idLastFlowchart: number;
    public idFlowchart: number;
}