<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <a class="back-link" routerLink="/master/text-template">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Usuários
        </a>
        <h1>Cadastrar Template</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome do template</mat-label>
                            <input matInput type="text" maxlength="50" formControlName="textTemplateName">
                            <mat-error *ngIf="model.get('textTemplateName').invalid">Informe o Nome do template</mat-error>
                            <mat-hint align="end">{{ model.get('textTemplateName').value ? model.get('textTemplateName').value.length : 0}}/50 caracteres</mat-hint>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <!-- <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Texto do template</mat-label>
                            <textarea matInput type="text" formControlName="textTemplateContent"></textarea>
                            <mat-error *ngIf="model.get('textTemplateContent').invalid">Informe o campo "texto do template"</mat-error>
                        </mat-form-field>
                    </div> -->
                    <p>Texto do Template</p>
                    <quill-editor class="quill-input" formControlName="textTemplateContent" [modules]="quillConfiguration" [placeholder]="''" [styles]="{'max-height': '400px'}"></quill-editor>
                    <mat-error *ngIf="model.get('textTemplateContent').invalid && this.trySubmit">Informe o campo "texto do template"</mat-error>
                </div>
                <div class="row button-container">
                    <div class="col-md-2">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <button mat-flat-button type="button" color="accent" class=" btn-block" (click)="clickCancel()">
                            <span *ngIf="isLoading == false">Cancelar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    
</div>