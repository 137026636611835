import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsClientService } from 'src/app/client/utils.service';
import { WebsocketRemoteUtilService } from 'src/app/shared/services/websocket-util/websocket-remote-util.service';

@Component({
  selector: 'app-welcome-remote-attendance',
  templateUrl: './welcome-remote-attendance.component.html',
  styleUrls: ['./welcome-remote-attendance.component.css']
})
export class WelcomeRemoteAttendanceComponent implements OnInit {
  constructor(
    private utilsService: UtilsClientService,
    private router: Router,
    private websocketRemoteUtilService: WebsocketRemoteUtilService
  ) { }

  ngOnInit(): void {
    let roomSector = this.utilsService.getSectorRoomRemote();
    if (!roomSector) {
      this.router.navigate(['client/remote-attendance/inv']);
      return;
    }
    let { idRoom } = JSON.parse(roomSector);
    if (!this.websocketRemoteUtilService.isConnect()) {
      this.websocketRemoteUtilService.connectwebsocketRoom(idRoom);
    }
  }

  enter() {
    this.router.navigate(['client/remote-attendance/cpf']);
  }
}
