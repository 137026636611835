import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { BillingDataService } from 'src/app/shared/services/API/billing/billing-data.service';
import { ListBillingDataService } from 'src/app/shared/services/API/orchestrator-billing/list-billing-data.service';
import { BillingDataStruct } from 'src/app/shared/services/structs/billing/billing-data.struct';
import { ConsolidatedDataStruct } from 'src/app/shared/services/structs/billing/consonlidated-data-struct';

@Component({
  selector: 'app-date-range-modal',
  templateUrl: './date-range-modal.component.html',
  styleUrls: ['./date-range-modal.component.css']
})
export class DateRangeModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<DateRangeModalComponent>,
  private listBillingDataService: ListBillingDataService,
  private alertService: AlertService,
  private formBuilder: UntypedFormBuilder,
) {}

  public isLoading:boolean;  
  public listDate: Date[];
  public listDays: number[];
  public isMonthSelected: boolean;
  public model: UntypedFormGroup;
  public beginPeriod: string;
  public endPeriod: string;
  public listConsolidatedData: ConsolidatedDataStruct[] = [];

  ngOnInit(): void {
    this.isLoading = false;
    this.populateDateSelect();

    this.model = this.formBuilder.group({
      yearMonth: ['', [Validators.required]],
      initialDate: ['', [Validators.required]],
      finalDate: ['', [Validators.required]],
    });
  }
  
  close(){
    this.matDialogRef.close(); 
  }

  submit(){
    let year = this.model.get('yearMonth').value.getFullYear();
    let month = this.model.get('yearMonth').value.getMonth()+1;

    this.beginPeriod = `${year}-${month}-${this.model.get('initialDate').value} 00:00:00`;
    this.endPeriod = `${year}-${month}-${this.model.get('finalDate').value} 23:59:59`;

    this.search();
  }

  populateDateSelect() {
    this.listDate = [];
    let todayDate = new Date();
    let year = todayDate.getFullYear();
    let month = todayDate.getMonth();
    for (let _i = 0; _i < 60; _i++) {
      this.listDate.push(new Date(year, month - _i));
    }
  }

  clearFinalDayValue(initialDay: number) {
    if (this.model.get('finalDate').value <= initialDay) {
      this.model.get('finalDate').setValue(null);
      this.model.get('finalDate').markAsUntouched();
      this.model.get('finalDate').markAsPristine();
    }
  }

  setDays(yearMonth: Date) {
    this.model.get('finalDate').setValue(null);
    this.model.get('finalDate').markAsUntouched();
    this.model.get('finalDate').markAsPristine();
    this.model.get('initialDate').setValue(null);
    this.model.get('initialDate').markAsUntouched();
    this.model.get('initialDate').markAsPristine();
    this.listDays = [];
    let month: number = new Date(yearMonth).getMonth();
    let year: number = new Date(yearMonth).getFullYear();
    let longMonth: number[] = [0, 2, 4, 6, 7, 9, 11];
    let shortMonth: number[] = [3, 5, 8, 10];

    if (longMonth.indexOf(month) > -1) {
      this.listDays = Array(31).fill(0).map((x, i) => i + 1);
    }
    else if (shortMonth.indexOf(month) > -1) {
      this.listDays = Array(30).fill(0).map((x, i) => i + 1);
    }
    else {
      this.listDays = ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0) ? Array(29).fill(0).map((x, i) => i + 1) : Array(28).fill(0).map((x, i) => i + 1);
    }
    this.isMonthSelected = true;
  }

  search(){
    if(!this.model.valid || this.isLoading){
      return;
    }
    this.isLoading = true;  
    
    this.listBillingDataService.listBillingData(this.beginPeriod, this.endPeriod, null, null).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listConsolidatedData = response.listConsolidatedData;  
      this.isLoading = false;    
      this.matDialogRef.close({
        listConsolidatedData: this.listConsolidatedData,
        beginPeriod: this.beginPeriod,
        endPeriod: this.endPeriod
      }); 
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }
}