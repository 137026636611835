import { HttpClient } from "@angular/common/http";
import { CommonService } from "../../common-service";
import { Observable, catchError, retry, timeout } from "rxjs";
import { GetAihGroupResponseModel } from "../../responses/hospital-document/get-aih-group.response";
import { environment } from "src/environments/environment";
import { Injectable } from "@angular/core";
import { PostAihRequest } from "../../requests/hospital-document/post-aih.request";
import { ReportResponse } from "../../responses/orchestrator-patient/report.response";
import { GetListAihGroupResponse } from "../../responses/hospital-document/get-list-aih-group.response";
import { ReturnStruct } from "src/app/shared/structs/return.struct";
import { PostAihGroupRequest } from "../../requests/hospital-document/post-aih-group.request";

@Injectable({
  providedIn: 'root'
})
export class AihService extends CommonService {

  constructor(private httpClient: HttpClient) { super(); }

    public getAihGroup(idAihGroup: number, idEpisode: number, isPending: boolean = true): Observable<GetAihGroupResponseModel> {
        let uri = `aih/getAihGroup/idAihGroup/${idAihGroup}/idEpisode/${idEpisode}?`;

        if(isPending) uri = uri +`isJustLastAihElement=${isPending}`;

        return this.httpClient.get<GetAihGroupResponseModel>(environment.urlApiOrchestratorPatient + uri, this.addHeaderToken())
            .pipe(catchError(this.handleError));
    }

    public createAih(request: PostAihRequest): Observable<ReportResponse> {

      let uri = 'Aih'
  
      return this.httpClient.post<ReportResponse>(
        environment.urlApiOrchestratorPatient + uri, request, this.addHeaderToken()
      )
        .pipe(catchError(this.handleError));
    }

    public listAihGroupByStatus(idAihStatus: number, pageIndex: number = null, patientName: string = null, userCreatorName: string = null, initialDate: Date = null, endDate: Date = null, idEpisode: number = null): Observable<GetListAihGroupResponse> {

      let uri = `aih/getAllAihGroup/idAihStatus/${idAihStatus}?`

  
      if (pageIndex)
        uri = uri + `pageIndex=${pageIndex}&`;
  
      if (patientName)
        uri = uri + `patientName=${patientName}&`;

      if (userCreatorName)
        uri = uri + `userCreatorName=${userCreatorName}&`;

      if (initialDate)
        uri = uri + `initialDate=${initialDate.toJSON()}&`;

      if (endDate)
        uri = uri + `endDate=${endDate.toJSON()}&`;

      if (idEpisode)
        uri = uri + `idEpisode=${idEpisode}&`;
  
      return this.httpClient.get<GetListAihGroupResponse>(environment.urlApiOrchestratorPatient + uri, this.addHeaderToken())
        .pipe(
          catchError(this.handleError)
        )
    }

    public deleteAihGroup(idAihGroup: number, request: PostAihGroupRequest): Observable<ReturnStruct> {

      let uri = `aihGroup/deleteAihGroup/idAihGroup/${idAihGroup}`;
  
      return this.httpClient.put<ReturnStruct>(
        environment.urlApiHospitalDocument + uri, request, this.addHeaderToken()
      )
        .pipe(catchError(this.handleError));
    }
}