<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <a class="back-link" routerLink="/form/medical-schedule">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Escala Médica
        </a>
        <h1>Cadastrar Escala Médica</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Usuário Responsável</mat-label>
                            <mat-select formControlName="idUser">
                                <mat-option *ngFor="let item of listUser" [value]="item.idUser">{{item.userName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Data inicial da Escala</mat-label>
                            <input matInput formControlName="startDate" [matDatepicker]="startScheduleDatePicker"
                                (dateChange)="verifyDate();">
                            <mat-datepicker-toggle matSuffix [for]="startScheduleDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #startScheduleDatePicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Data final da Escala</mat-label>
                            <input matInput formControlName="endDate" [matDatepicker]="endScheduleDatePicker"
                                (dateChange)="verifyDate();">
                            <mat-datepicker-toggle matSuffix [for]="endScheduleDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #endScheduleDatePicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-4">
                        <app-select-2 [formControlField]="'idCbo'"  [multiple]="false"
                            [url]="urlListCbo" [idField]="'idCbo'" [nameField]="'codeWithName'" [labelField]="'CBO'">
                        </app-select-2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Plantão</mat-label>
                            <mat-select formControlName="idShift" (selectionChange)="changeShift($event.value)">
                                <mat-option [value]="1">Diurno</mat-option>
                                <mat-option [value]="2">Noturno</mat-option>
                                <mat-option [value]="3">Outro</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Hora Inicial</mat-label>
                            <input matInput type="number" formControlName="startHour">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Minuto</mat-label>
                            <input matInput type="number" formControlName="startMinute">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Hora Final</mat-label>
                            <input matInput type="number" formControlName="endHour">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Minuto</mat-label>
                            <input matInput type="number" formControlName="endMinute">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 beside">
                        <b class="col-4 beside-the-field">Total no período</b>
                        <mat-form-field appearance="outline">
                            <mat-label>Quantidade Prevista</mat-label>
                            <input matInput type="number" formControlName="totalPeriodValue">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-horizontal-stepper linear #stepper formArrayName="formStepperList">
                            <mat-step [formGroupName]="i"
                                *ngFor="let itemStepper of model.get('formStepperList')['controls']; let i = index">
                                <ng-template matStepLabel>{{itemStepper.get("month").value}}</ng-template>
                                <div formArrayName="listDailyMedicalSchedule"
                                    *ngFor="let item of itemStepper.get('listDailyMedicalSchedule')['controls']; let x = index">
                                    <div class="row" [formGroupName]="x">
                                        <div class="col-12 col-sm-6 col-md-6 beside">
                                            <b class="col-4 beside-the-field">{{item.get("dayDate").value}}</b>
                                            <mat-form-field appearance="outline">
                                                <mat-label>Quantidade Prevista</mat-label>
                                                <input matInput type="number"
                                                    (change)="predictedAmountChanged(item.get('newDate').value, $event)"
                                                    formControlName="predictedAmount">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-6 beside">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Quantidade Real</mat-label>
                                                <input matInput type="number"
                                                    (change)="actualAmountPredictChanged(item.get('newDate').value, $event)"
                                                    formControlName="actualAmountPredict">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <button type="button" *ngIf="model.get('formStepperList')['controls'][i-1]"
                                        mat-flat-button color="accent" matStepperPrevious>Mês Anterior</button>
                                    <button type="button" *ngIf="model.get('formStepperList')['controls'][i+1]"
                                        mat-flat-button color="accent" matStepperNext>Próximo Mês</button>
                                </div>
                            </mat-step>
                        </mat-horizontal-stepper>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <a mat-flat-button type="button" routerLink="/form/medical-schedule" color="accent"
                            class=" btn-block">
                            Cancelar
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>