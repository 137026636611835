<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-confirm-modal-list">
            <h4>Selecione o novo leito do paciente</h4>
        </div>
        <form class="form" [formGroup]="model"  (ngSubmit)="submit()">
            <div class="body-confirm-modal-list">     
                <div class="row">   
                    <div class="col-md-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Leitos Livres</mat-label>
                            <mat-select formControlName="idBed">
                              <mat-option *ngFor="let item of listFreeBed" value="{{item.idBed}}">{{item.bedName}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idBed').invalid">Informe o Leito</mat-error>
                        </mat-form-field>
                    </div>
                </div>      
            </div>
            <div class="footer-confirm-modal-list">
                <button mat-flat-button color="primary" class="btn-block" type="submit">
                    <span *ngIf="isLoading == false">Salvar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
                <button mat-flat-button color="accent" class="btn-block"(click)="clickCancel()" type="button">Cancelar</button>
            </div>
        </form> 
    </div>
</div>