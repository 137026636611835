import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { LocationByModuleService } from 'src/app/shared/services/API/flow/location-by-module.service';
import { ServiceGetService } from 'src/app/shared/services/API/flow/service-get.service';
import { AreaService } from 'src/app/shared/services/API/gamification/area.service';
import { GetServiceResponse } from 'src/app/shared/services/responses/flow/get-service.response';
import { SectorStruct } from 'src/app/shared/services/structs/flow/sector.struct';
import { ListCompleteAreaStruct } from 'src/app/shared/services/structs/gamification/list-complete-area.struct';
import { AreaDeleteModalComponent } from './area-delete-modal/area-delete-modal.component';

@Component({
  selector: 'app-gamification-list',
  templateUrl: './gamification-list.component.html',
  styleUrls: ['./gamification-list.component.css']
})
export class GamificationListComponent implements OnInit {

  constructor(private areaService: AreaService,
    private alertService: AlertService,
    public dialog: MatDialog,
    private serviceGetService: ServiceGetService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.master;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.master_gamification;

  public listArea: ListCompleteAreaStruct[];
  public listServiceWithSector: GetServiceResponse;
  public isLoading: boolean;
  public searchText: string;
  public listIdSector: number[];
  public idService: number;
  public loaded: boolean;
  public string: string[];

  ngOnInit(): void {

    this.loaded = false;
    this.idService = null;
    this.isLoading = true;
    this.search();
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }

  search() {
    this.isLoading = true;

    if (this.searchText == "")
      this.searchText = null;

    this.areaService.listArea(this.searchText).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listArea = response.listArea;

      if (this.listArea.length > 0 && this.listArea.filter(x => x.listIdSector != null && x.listIdSector.length > 0)) {
        this.listIdSector = this.listArea.find(x => x.listIdSector).listIdSector;
        this.idService = this.listArea.find(x => x.idService).idService;

        this.populateServiceSelect(this.idService, this.listIdSector)
      }

      this.isLoading = false;
    },

      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  populateServiceSelect(idService: number, listIdSector: number[]) {
    this.serviceGetService.getServiceData(idService, listIdSector).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.listArea.forEach(x => {
        if (x.idService == idService) {
          x.serviceName = response.serviceName,
            x.listSector = response.listSector,
            x.sectorName = response.listSector.map(x => x.sectorName),
            x.isActive = response.isActive
        }
      });
      this.string = response.listSector.map(x => x.sectorName);
      this.loaded = true;

      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  openModal(idArea) {
    const dialogRef = this.dialog.open(AreaDeleteModalComponent, {
      data: {
        idArea: idArea
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.deleteArea)
        this.search();
    });
  }
}