import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { StatusEpisodeEnum } from 'src/app/shared/enum/status-episode.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { BedEpisodeService } from 'src/app/shared/services/API/bed/bed-episode.service';
import { AllergyService } from 'src/app/shared/services/API/medical-record/allergy.service';
import { NursingConsultationService } from 'src/app/shared/services/API/medical-record/nursing-consultation.service';
import { OccupiedBedService } from 'src/app/shared/services/API/orchestrator-patient/occupied-bed.service';
import { AllergyModel } from 'src/app/shared/services/models/medical-record/allergy.model';
import { LiberateBedRequest } from 'src/app/shared/services/requests/bed/liberate-bed.request';
import { NursingConsultationRequest } from 'src/app/shared/services/requests/medical-record/nursing-consultation.request';
import { MedicalRecordResponse } from 'src/app/shared/services/responses/orchestrator-patient/medical-record.response';
import { CompleteMedicalCareStruct } from 'src/app/shared/services/structs/orchestrator-patient/complete-medical-care.struct';
import { EpisodeStruct } from 'src/app/shared/services/structs/orchestrator-patient/episode.struct';
import { OccupiedBedStruct } from 'src/app/shared/services/structs/orchestrator-patient/occupied-bed.struct';
import { ChangeBedEpisodeModalComponent } from './change-bed-episode-modal/change-bed-episode-modal.component';
import { ReportObservationRequest } from 'src/app/shared/services/requests/orchestrator-patient/report-observation.request';
import { ObservationReportService } from 'src/app/shared/services/API/orchestrator-patient/observation-report.service';
import { NursingConsultationStruct } from 'src/app/shared/services/structs/medical-record/nursing-consultation.struct';
import { ReportModalComponent } from 'src/app/shared/components/report-modal/report-modal.component';
import { MedicalPrescriptionService } from 'src/app/shared/services/API/medical-record/medical-prescription.service';
import { EpisodeAdministrativeReleaseConfirmModalComponent } from './episode-administrative-release-confirm-modal/episode-administrative-release-confirm-modal.component';
import { EpisodeStatusEnum } from 'src/app/shared/enum/episode-status.enum';
import { MedicalPrescriptionStruct } from 'src/app/shared/services/structs/medical-record/medical-prescription.struct';
import { ObservationConfigSectorService } from 'src/app/shared/services/API/bed/observation-config-sector.service';
import { ObservationConfigSectorStruct } from 'src/app/shared/services/structs/bed/observation-config-sector.struct';
import { ObservationForwardModalComponent } from 'src/app/shared/components/observation-forward-modal/observation-forward-modal.component';
import { FowardRequest } from 'src/app/shared/services/requests/orchestrator-queue/foward.request';
import { ForwardResponse } from 'src/app/shared/services/responses/orchestrator-queue/forward.response';
import { BedQueueService } from 'src/app/shared/services/API/queue/bed-queue.service';
import { QueueModel } from 'src/app/shared/services/models/queue/queue.model';
import { ObservationForwardService } from 'src/app/shared/services/API/orchestrator-queue/observation-foward.service';
import { MedicalProcedureStruct } from 'src/app/shared/services/structs/medical-record/medical-procedure.struct';
import { MedicalRecordService } from 'src/app/shared/services/API/orchestrator-patient/medical-record.service';
import { MedicalProceduresService } from 'src/app/shared/services/API/orchestrator-patient/medical-procedures.service';
import { MedicalProcedureDeleteModalComponent } from 'src/app/observation/pages/bed-management-details/medical-procedure-delete-modal/medical-procedure-delete-modal.component';
import { BedManagementProcedureModalComponent } from './bed-management-procedure-modal/bed-management-procedure-modal.component';
import { BedManagementMaterialHistoryModalComponent } from './bed-management-material-history-modal/bed-management-material-history-modal.component';
import { MedicalProcedureModel, MedicalProcedureWithName } from 'src/app/shared/services/models/medical-record/medical-procedure.model';
import { ObservationProcedureToggleService } from 'src/app/shared/services/API/bed/observation-procedure-toggle.service';
import { FlowStruct } from 'src/app/shared/services/structs/flow/flow.struct';
import { FlowSequenceModalComponent } from 'src/app/shared/components/flow-sequence-modal/flow-sequence-modal.component';
import { UserGetNameService } from 'src/app/shared/services/API/user/user-get-name.service';
import { UpdateStatusQueueService } from 'src/app/shared/services/API/orchestrator-queue/update-status-queue.service';
import { WebsocketAttendanceUtilService } from 'src/app/shared/services/websocket-util/websocket-attendance-util.service';
import { MedicatedSolutionStruct } from 'src/app/shared/services/structs/medical-record/medicated-solution.struct';
import { AuthService } from 'src/app/shared/services/auth.service';
import { TissGuideManagementFloatingWindowComponent } from 'src/app/shared/components/tiss-guides/tiss-guide-management-floating-window/tiss-guide-management-floating-window.component';
import { AllPrivateBillingGuideReportStruct } from 'src/app/shared/services/structs/orchestrator-patient/all-private-billing-guide-report.struct';
import { AlertModalComponent } from 'src/app/shared/components/alert-modal/alert-modal.component';
import { AttendanceOccupiedRemoveModalComponent } from 'src/app/shared/components/attendance-occupied-remove-modal/attendance-occupied-remove-modal.component';
import { ExternalPrescriptionStruct } from 'src/app/shared/services/structs/medical-record/external-prescription.struct';
import { GetOccupiedBedResponse } from 'src/app/shared/services/responses/orchestrator-patient/get-occupied-bed.response';
import { UtilService } from 'src/app/shared/services/util.service';
import { error } from 'console';
import { MedicalCareSadtService } from 'src/app/shared/services/API/medical-record/medical-care-sadt.service';
import { MedicalCareSadtResponse } from 'src/app/shared/services/responses/medical-record/medical-care-sadt.response';
import { SadtStatusEnum } from 'src/app/shared/enum/sadt-status.enum';
import { MedicalCareSadtStruct } from 'src/app/shared/services/structs/medical-record/medical-care-sadt.struct';
import { PlaceEnum } from 'src/app/shared/enum/medical-record/plcae.enum';
import { TextTemplateService } from 'src/app/shared/services/API/meta-data/text-template.service';
import { TextTemplate } from 'src/app/shared/services/models/meta-data/text-template.model';


@Component({
  selector: 'app-bed-management-details',
  templateUrl: './bed-management-details.component.html',
  styleUrls: ['./bed-management-details.component.css']
})
export class BedManagementDetailsComponent implements OnInit, OnDestroy {

  constructor(private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private dialog: MatDialog,
    public nursingConsultationService: NursingConsultationService,
    public medicalRecordService: MedicalRecordService,
    public medicalProceduresService: MedicalProceduresService,
    public occupiedBedService: OccupiedBedService,
    public orchestratorOccupiedBedService: OccupiedBedService,
    public bedEpisodeService: BedEpisodeService,
    public allergyService: AllergyService,
    public router: Router,
    public observationReportService: ObservationReportService,
    public medicalPrescriptionService: MedicalPrescriptionService,
    private observationConfigSectorService: ObservationConfigSectorService,
    public observationForwardService: ObservationForwardService,
    public bedQueueService: BedQueueService,
    public observationProcedureToggleService: ObservationProcedureToggleService,
    private userGetNameService: UserGetNameService,
    public updateStatusQueueService: UpdateStatusQueueService,
    private websocketAttendanceUtilService: WebsocketAttendanceUtilService,
    private authService: AuthService,
    public utilService: UtilService,
    private medicalCareSadtService: MedicalCareSadtService,
    private textTemplateService: TextTemplateService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.observation;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.observation_bedManagement;

  public isLoading: boolean = false;
  public isFirstLoading: boolean;
  public selectedEpisode: number;
  public model: UntypedFormGroup;
  public listNursingConsultation: NursingConsultationStruct[] = [];
  public medicalRecordResponse: MedicalRecordResponse;
  public listMedicalCare: CompleteMedicalCareStruct[];
  public bedStruct: OccupiedBedStruct = new OccupiedBedStruct();
  public episodeStruct: EpisodeStruct = new EpisodeStruct();
  public listAllergyEpisode: AllergyModel[] = [];
  public listPrescription: MedicalPrescriptionStruct[] = [];
  public listPrescriptionMedicatedSolution: MedicatedSolutionStruct[] = [];
  public listOpenProcedureStructs: MedicalProcedureStruct[] = [];
  public listClosedProcedureStructs: MedicalProcedureStruct[] = [];
  public listProcedureStructs: MedicalProcedureStruct[] = [];
  public listTextTemplate: TextTemplate[] = [];
  public alergies: string;
  public nursingConsultationRequest: NursingConsultationRequest = new NursingConsultationRequest;
  public fileName: string;
  public isFinalized: boolean;
  public datetimeStartAttendance: Date;
  public idBed: number = null;
  public idRoom: number;
  public idSector: number;
  public idService: number;
  public isBedManagement: boolean = false;
  public observationConfigSector: ObservationConfigSectorStruct;
  public isReleasable: boolean = false;
  public isReleasablePharmacy: boolean = false;
  public queue: QueueModel;
  public isLoadingForward: boolean = false;
  public isEpisodeAdministrativeRelease: boolean = false;
  public idEpisodeAdministrativeRelease: number;
  public idStatusEpisodeInternar: number = EpisodeStatusEnum.FinalizadoInternar.valueOf();
  public idStatusEpisodeAlta: number = EpisodeStatusEnum.FinalizadoAlta.valueOf();
  public idStatusEpisodeAltaAdministrativa: number = EpisodeStatusEnum.FinalizadoAltaAdministrativa.valueOf();
  public listProcedures: MedicalProcedureWithName[] = [];
  public listProceduresNursing: MedicalProcedureWithName[] = [];
  public listProceduresMedic: MedicalProcedureWithName[] = [];
  public medicalProcedure: MedicalProcedureModel;
  public hasObservationProcedure: boolean = false;
  public evolutionFillingIsRequired: boolean = false;
  public isFlowSequence: boolean = false;
  public isAttended: boolean = false;
  private idLastQueue: number;
  public isAdministrativeRelease: boolean = false;
  public userLogin: string;
  public userName: string;
  public isAttendanceRemoval: boolean = false;
  public listOpenExternalPrescription: ExternalPrescriptionStruct[];

  @ViewChild(TissGuideManagementFloatingWindowComponent) childComponent: TissGuideManagementFloatingWindowComponent;
  public allPrivateBillingGuideReportStruct: AllPrivateBillingGuideReportStruct;
  public showTissGuideButton: boolean = false;
  public showTissGuideButtonValidation: boolean = false;

  ngOnInit(): void {
    this.userLogin = this.authService.getTokenMenu().login;
    this.userName = this.authService.getTokenMenu().userName;

    this.populateMedicalProcedure(parseInt(this.activatedRoute.snapshot.paramMap.get('idEpisode')));
    this.selectedEpisode = parseInt(this.activatedRoute.snapshot.paramMap.get('idEpisode'))

    if (parseInt(this.activatedRoute.snapshot.paramMap.get('idBed'))) {
      this.idBed = parseInt(this.activatedRoute.snapshot.paramMap.get('idBed'));
      this.isAttended = true;
    }

    if (parseInt(this.activatedRoute.snapshot.paramMap.get('idService')))
      this.idService = parseInt(this.activatedRoute.snapshot.paramMap.get('idService'));

    if (parseInt(this.activatedRoute.snapshot.paramMap.get('idQueue')))
      this.idLastQueue = parseInt(this.activatedRoute.snapshot.paramMap.get('idQueue'));

    this.idSector = parseInt(this.activatedRoute.snapshot.paramMap.get('idSector'));
    this.idRoom = parseInt(this.activatedRoute.snapshot.paramMap.get('idRoom'));

    if (this.activatedRoute.snapshot.paramMap.get('showTissGuideButton'))
      this.showTissGuideButton = this.activatedRoute.snapshot.paramMap.get('showTissGuideButton') == "true" ? true : false;

    this.showTissGuideButtonValidation = this.showTissGuideButton;
    this.isBedManagement = this.parseStringToBool(this.activatedRoute.snapshot.paramMap.get('isBedManagement'));

    if (Number.isInteger(this.idLastQueue) && this.idLastQueue && this.idLastQueue > 0 && Number.isInteger(this.selectedEpisode) && this.selectedEpisode && this.selectedEpisode > 0 && this.isBedManagement != true)
      this.startWebsocket();

    if (this.selectedEpisode)
      this.isFirstLoading = true;

    this.getBedData();
    this.getObservationSectorConfig();
    this.getProcedureConfig();
  }

  ngOnDestroy() {
    if (this.websocketAttendanceUtilService.isConnect())
      this.websocketAttendanceUtilService.emitFinalizeAttendance(this.idRoom);

    if (!this.isAttended && !this.isAdministrativeRelease && !this.isAttendanceRemoval) {
      this.updateStatusQueueService.updateQueueToOldStatus(this.idLastQueue, this.selectedEpisode).subscribe({
        next: (response) => {
          if (response.isError) {
            this.alertDynamic("Error", response.errorDescription, AlertType.error);
            return;
          }
        },
        error: (error) => {
          this.utilService.consoleLog(error);
        }
      });
    }
  }

  getBedData() {
    this.occupiedBedService.getOccupiedBed(this.selectedEpisode, this.idBed).subscribe({
      next: (response: GetOccupiedBedResponse) => {
        this.isLoading = false;
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.bedStruct = response.occupiedBedStruct;
        this.listMedicalCare = response.medicalCareHistorie;
        this.episodeStruct = response.episode;
        this.listNursingConsultation = response.listNursingConsultation;
        this.listAllergyEpisode = response.listAllergy;
        this.datetimeStartAttendance = response.datetimeStartAttendance;
        this.idEpisodeAdministrativeRelease = response.episode.idEpisodeAdministrativeRelease;
        this.listPrescription = response.listOpenPrescriptionStructs;
        this.listPrescriptionMedicatedSolution = response.listOpenMedicatedSolutionPrescriptionStructs;
        this.listOpenProcedureStructs = response.listOpenProcedureStructs;
        this.listProceduresNursing = this.listProceduresNursing;
        this.listClosedProcedureStructs = response.listClosedProcedureStructs;
        this.listProcedureStructs = response.listProcedureStructs;
        this.listOpenExternalPrescription = response.listOpenExternalPrescriptionStructs;

        this.isEpisodeAdministrativeRelease = !Number.isNaN(this.idEpisodeAdministrativeRelease);

        this.isFirstLoading = false;

        this.isFinalized = this.checkEpisodeStatus();

        let listIdUser: number[] = this.listAllergyEpisode.filter(x => x.isDeleted && x.idUser != null).map(x => x.idUser);
        this.getUserNames(listIdUser);
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    });
  }

  redirect() {
    this.showTissGuideButton = false;

    if (this.selectedEpisode)
      this.isFirstLoading = true;

    this.getBedData();
    this.getObservationSectorConfig();
    this.populateMedicalProcedure(parseInt(this.activatedRoute.snapshot.paramMap.get('idEpisode')));

    setTimeout(() => {
      this.showTissGuideButton = this.showTissGuideButtonValidation;
    },
      500);
  }

  populateMedicalProcedure(idEpisode: number) {
    this.medicalProceduresService.getObservationMedicalProcedure(idEpisode).subscribe({
      next: (response) => {
        this.isLoading = false;
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listProcedures = response.listProcedures;
        this.listProceduresMedic = response.listProceduresMedic;
        this.listProceduresNursing = response.listProceduresNursing;
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    });
  }

  leaveBed() {
    this.isLoading = true;

    let liberateBedRequest: LiberateBedRequest = new LiberateBedRequest();
    liberateBedRequest.idEpisode = this.selectedEpisode;

    this.occupiedBedService.liberateBed(liberateBedRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.alertDynamic('Sucesso', "Leito liberado com sucesso!", AlertType.success)
        this.router.navigate(['/observation/bed-management', { idSector: this.idSector, idRoom: this.idRoom, idService: this.idService }]);
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    });
  }

  goBack() {
    let guideWasUpdate = this.hasGuideToSave();

    if (guideWasUpdate) {
      const dialogRef = this.dialog.open(AlertModalComponent, {
        data: {
          isTwoButtonsModal: true,
          title: 'Atenção!',
          description: 'Uma guia foi ajustada neste atendimento, mas nenhuma conduta foi salva. Por favor, registre uma conduta para salvar corretamente a guia. Caso contrário, ao continuar o encaminhamento, as alterações na guia serão perdidas.',
          hasImage: true,
          textButtonConfirm: 'Continuar e não alterar a guia',
          textButtonCancel: 'Voltar e registrar conduta',
        },
      });
      dialogRef.afterClosed().subscribe({
        next: (result) => {
          if (result && result.confirm) {
            if (this.isBedManagement)
              this.router.navigate(['/observation/bed-management', { idSector: this.idSector, idRoom: this.idRoom, idService: this.idService }]);
            else
              this.router.navigate(['/observation/patient-list', { idSector: this.idSector, idRoom: this.idRoom, idService: this.idService }]);
          }
        },
        error: (error) => {
          this.utilService.consoleLog(error);
        }
      });
    }
    else {
      if (this.isBedManagement)
        this.router.navigate(['/observation/bed-management', { idSector: this.idSector, idRoom: this.idRoom, idService: this.idService }]);
      else
        this.router.navigate(['/observation/patient-list', { idSector: this.idSector, idRoom: this.idRoom, idService: this.idService }]);
    }
  }

  changeBedPatient() {
    const dialogRef = this.dialog.open(ChangeBedEpisodeModalComponent, {
      data: {
        idEpisode: this.selectedEpisode,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        this.isLoading = false;
        if (result.isChange)
          this.redirect();
      },
      error: (error) => {
        this.utilService.consoleLog(error);
      }
    });
  }

  bedProcedureModal(medicalProcedure?: MedicalProcedureModel) {
    const dialogRef = this.dialog.open(BedManagementProcedureModalComponent, {
      data: {
        medicalProcedure: medicalProcedure,
        idEpisode: this.selectedEpisode,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        this.redirect();
      },
      error: (error) => {
        this.utilService.consoleLog(error);
      }
    });
  }

  requestMaterialHistory() {
    const dialogRef = this.dialog.open(BedManagementMaterialHistoryModalComponent, {
      data: {
        listMedicalProcedures: this.listProcedures,
        idEpisode: this.selectedEpisode,
      },
      width: '800px'
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        this.redirect();
      },
      error: (error) => {
        this.utilService.consoleLog(error);
      }
    });
  }

  generateObservationReport() {
    let reportObservationRequest = new ReportObservationRequest();

    this.isLoading = true;

    reportObservationRequest.listAllergy = this.listAllergyEpisode;
    reportObservationRequest.listNursingConsultation = this.listNursingConsultation;
    reportObservationRequest.medicalCareHistorie = this.listMedicalCare;

    this.observationReportService.generateObservationReport(reportObservationRequest, this.episodeStruct.idEpisode).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.isLoading = false;
        let reportDialog = this.dialog.open(ReportModalComponent, {
          data: {
            reportName: response.reportName,
            reportPdf64: response.reportPdf64,
          },
        });
        reportDialog.afterClosed().subscribe({
          next: () => {
          },
          error: (error) => {
            this.utilService.consoleLog(error);
          }
        });
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    });
  }

  checkEpisodeStatus(): boolean {
    if (this.episodeStruct.idStatus == StatusEpisodeEnum.finalized_hospitalize
      || this.episodeStruct.idStatus == StatusEpisodeEnum.finalized_death
      || this.episodeStruct.idStatus == StatusEpisodeEnum.finalized_administrativeRelease
      || this.episodeStruct.idStatus == StatusEpisodeEnum.finalized_medicalRelease
      || this.episodeStruct.idStatus == StatusEpisodeEnum.finalized_patientLeave) {
      return true;
    }

    return false;
  }

  releaseEpisodeModal(idStatus: number) {
    const dialogRef = this.dialog.open(EpisodeAdministrativeReleaseConfirmModalComponent, {
      data: {
        episode: this.episodeStruct,
        idStatus: idStatus,
        idBed: this.idBed,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.release) {
          this.isLoading = false;
          this.isAdministrativeRelease = true;
          if (this.isBedManagement)
            this.router.navigate(['/observation/bed-management', { idSector: this.idSector, idRoom: this.idRoom, idService: this.idService }]);
          else
            this.router.navigate(['/observation/patient-list', { idSector: this.idSector, idRoom: this.idRoom, idService: this.idService }]);
        }
      },
      error: (error) => {
        this.utilService.consoleLog(error);
      }
    });
  }

  getObservationSectorConfig() {
    this.observationConfigSectorService.listObservationConfigSector(this.idSector).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.observationConfigSector = response.config;

        if (this.observationConfigSector.observationConfig) {
          this.isReleasable = this.observationConfigSector.observationConfig.isReleasable;
          this.isReleasablePharmacy = this.observationConfigSector.observationConfig.isReleasablePharmacy;
        }
        if(this.observationConfigSector.listIdTextTemplate && this.observationConfigSector.listIdTextTemplate.length > 0)
          this.getTextTemplateByListId(this.observationConfigSector.listIdTextTemplate);
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getTextTemplateByListId(listId: number[] = []) {
    this.textTemplateService.getByListIdTextTemplate(listId).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        this.listTextTemplate = response.list;
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  forwardPatient() {
    let guideWasUpdate = this.hasGuideToSave();

    if (guideWasUpdate) {
      const dialogRef = this.dialog.open(AlertModalComponent, {
        data: {
          isTwoButtonsModal: true,
          title: 'Atenção!',
          description: 'Uma guia foi ajustada neste atendimento, mas nenhuma conduta foi salva. Por favor, registre uma conduta para salvar corretamente a guia. Caso contrário, ao continuar o encaminhamento, as alterações na guia serão perdidas.',
          hasImage: true,
          textButtonConfirm: 'Continuar e não alterar a guia',
          textButtonCancel: 'Voltar e registrar conduta',
        },
      });
      dialogRef.afterClosed().subscribe({
        next: (result) => {
          if (result && result.confirm)
            this.populateDestinationSelect()
        },
        error: (error) => {
          this.utilService.consoleLog(error);
        }
      });
    }
    else
      this.populateDestinationSelect();
  }

  populateDestinationSelect() {
    this.isLoadingForward = true;
    let fowardRequest = new FowardRequest();
    fowardRequest.idEpisode = this.selectedEpisode;
    fowardRequest.idRoom = this.idRoom;

    this.observationForwardService.listDestination(fowardRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error, true);
          return;
        }

        this.isFlowSequence = (response.flowSequence != null && response.flowSequence.length > 1);

        if (this.isFlowSequence)
          this.openFlowSequenceModal(this.selectedEpisode, response.idLastQueue, fowardRequest.idRoom, response.flowSequence, response.currentQueueFinished);
        else
          this.getExamList(this.selectedEpisode, response);

        this.endLoading(true);
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error, true);
      }
    });
  }

  openModalForward(forwardResponse: ForwardResponse, idRoom: number, typesSadtNamesOpen: string) {
    this.endLoading();

    if (forwardResponse.isError) {
      this.alertDynamic('Erro', forwardResponse.errorDescription, AlertType.error);
      return;
    }
    else if (forwardResponse.isAutomaticFoward) {
      this.alertDynamic('Aviso', forwardResponse.automaticFoward, AlertType.warning);
      return;
    }

    const dialogRef = this.dialog.open(ObservationForwardModalComponent, {
      data: {
        idRoom: idRoom,
        idService: this.idService,
        actualIdSector: this.idSector,
        actualIdRoom: this.idRoom,
        idEpisode: this.selectedEpisode,
        idQueue: forwardResponse.idLastQueue,
        listFoward: forwardResponse.location,
        isFlowSequence: this.isFlowSequence,
        flowSequence: forwardResponse.flowSequence,
        datetimeStartRegister: null,
        idBed: this.idBed,
        isMedicBedManagement: false,
        isBedManagement: this.isBedManagement,
        typesSadtNamesOpen: typesSadtNamesOpen
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.fowardPatient) {
          this.isAttended = true;
          if (this.isBedManagement) {
            this.router.navigate(['/observation/bed-management', { idSector: this.idSector, idRoom: this.idRoom, idService: this.idService }]);
            this.isLoading = false;
          }
          else {
            this.router.navigate(['/observation/patient-list', { idSector: this.idSector, idRoom: this.idRoom, idService: this.idService }]);
            this.isLoading = false;
          }
        }
      },
      error: (error) => {
        this.utilService.consoleLog(error);
      }
    });
  }

  openFlowSequenceModal(idEpisode: number, idQueue: number, idRoom: number, flowSequence: FlowStruct[], currentQueueFinished: boolean) {
    const dialogRef = this.dialog.open(FlowSequenceModalComponent, {
      data: {
        idEpisode: idEpisode,
        idQueue: idQueue,
        flowSequence: flowSequence,
        idOriginRoom: idRoom,
        isBedManagement: this.isBedManagement,
        currentQueueFinished,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.fowardPatient) {
          this.isAttended = true;
          this.router.navigate(['/observation/patient-list', { idSector: this.idSector, idRoom: this.idRoom }]);
        }
      },
      error: (error) => {
        this.utilService.consoleLog(error);
      }
    });
  }

  deleteProcedure(idMedicalProcedure) {
    const dialogRef = this.dialog.open(MedicalProcedureDeleteModalComponent, {
      data: {
        idMedicalProcedure: idMedicalProcedure
      },
    });

    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.deleteProcedure)
          this.listProceduresNursing.splice(this.listProceduresNursing.findIndex(item => item.idMedicalProcedure === idMedicalProcedure), 1);
      },
      error: (error) => {
        this.utilService.consoleLog(error);
      }
    });
  }

  parseStringToBool(stringValue: string): boolean {
    if (stringValue) {
      if (stringValue.toLowerCase() == "true")
        return true;
      else
        return false;
    }
    else
      return false;
  }

  getProcedureConfig() {
    this.observationProcedureToggleService.getProcedureConfig().subscribe({
      next: (response) => {
        this.isLoading = false;
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.hasObservationProcedure = response.hasObservationProcedure;
        this.evolutionFillingIsRequired = response.evolutionFillingIsRequired;
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getUserNames(listIdUser: number[]) {
    this.isLoading = true;
    this.userGetNameService.listUser(listIdUser).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listAllergyEpisode.forEach(allergy => {
          if (allergy.isDeleted && allergy.idUser != null)
            allergy.userName = response.listUser.find(x => x.idUser == allergy.idUser).userName;
        });

        this.isLoading = false;
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getExamList(idEpisode: number, forwardResponse: ForwardResponse) {
    this.isLoading = true;
    this.medicalCareSadtService.getMedicalCareSadt(idEpisode).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
          return;
        }

        let listSadtOpen = response.listMedicalCareSadt
          .filter(x => x.idStatus === SadtStatusEnum.Pedido && x.idPlace === PlaceEnum.interno)
          .reduce((acc, curr) => {
            let existingItem = acc.find(item => item.idTypeSadt === curr.idTypeSadt);
            if (existingItem)
              existingItem.quantity++;
            else
              acc.push({ idTypeSadt: curr.idTypeSadt, typeSadtName: curr.typeSadtName, quantity: 1 });

            return acc;
          },
            []);

        let typesSadtNamesOpen = listSadtOpen.map(item => {
          return item.quantity > 1 ? `${item.typeSadtName} (${item.quantity})` : item.typeSadtName;
        }).join(', ');

        this.openModalForward(forwardResponse, this.idRoom, typesSadtNamesOpen);
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    });
  }

  //Websocket
  startWebsocket() {
    if (!this.websocketAttendanceUtilService.isConnect())
      this.websocketAttendanceUtilService.connectwebsocketRoom(this.idRoom, this.idLastQueue, this.selectedEpisode, this, false, this.isBedManagement, this.authService.getToken(), this.userLogin, this.userName);

    this.websocketAttendanceUtilService.setContext(this);
    this.websocketAttendanceUtilService.setFunctions(null, null, this.onRemoveAttendant);
  }

  hasGuideToSave(): boolean {
    let guideWasUpdate = false;
    if (this.showTissGuideButtonValidation) {
      let guideRequest = this.childComponent.getGuidRequest();
      if ((guideRequest.admissionGuideStruct ||
        guideRequest.appointmentGuideStruct ||
        guideRequest.feesGuideStruct ||
        guideRequest.spSadtGuideStruct)) {
        guideWasUpdate = true;
      }
    }

    return guideWasUpdate;
  }

  onRemoveAttendant(thisParam, res: any) {
    thisParam.isAttendanceRemoval = false;

    if (res.userLogin != thisParam.userLogin) {
      thisParam.isAttendanceRemoval = true;

      thisParam.websocketAttendanceUtilService.clearSocket();
      let ref = thisParam.dialog.open(AttendanceOccupiedRemoveModalComponent, { data: { ...res } });
      ref.afterClosed().subscribe({
        next: () => {
          thisParam.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            if (this.isBedManagement) {
              this.router.navigate(['/observation/bed-management', { idSector: thisParam.idSector, idRoom: thisParam.idRoom, idService: thisParam.idService }]);
              this.isLoading = false;
            }
            else {
              this.router.navigate(['/observation/patient-list', { idSector: thisParam.idSector, idRoom: thisParam.idRoom, idService: thisParam.idService }]);
              this.isLoading = false;
            }
          })
        },
        error: (error) => {
          this.utilService.consoleLog(error);
        }
      });
    }
  }

  alertDynamic(alertTypeDescription: string, alertDescription: string, alertType: AlertType, isEndLoadingForward: boolean = false) {
    if (alertType && (alertType.valueOf() == AlertType.error || alertType.valueOf() == AlertType.warning))
      this.utilService.consoleLog(alertTypeDescription);

    this.alertService.show(alertTypeDescription, alertDescription, alertType ? alertType.valueOf() : AlertType.error);
    this.endLoading(isEndLoadingForward);
  }

  endLoading(isEndLoadingForward: boolean = false) {
    if (isEndLoadingForward)
      this.isLoadingForward = false;

    this.isLoading = false;
  }
}