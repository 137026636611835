import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RemoteMonitoringRoutingModule } from './remote-monitoring-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { NgxMaskModule } from 'ngx-mask';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { RemoteMonitoringComponent } from './pages/remote-monitoring/remote-monitoring.component';
import { LinkPatientComponent } from './pages/sync-measurer-register/link-patient/link-patient.component';
import { ListPatientComponent } from './pages/sync-measurer-register/list-patient/list-patient.component';
import { SyncMeasurerRegisterComponent } from './pages/sync-measurer-register/sync-measurer-register.component';
import { SyncMeasurerDeleteModalComponent } from './pages/sync-measurer-list/sync-measurer-delete-modal/sync-measurer-delete-modal.component';
import { SyncMeasurerListComponent } from './pages/sync-measurer-list/sync-measurer-list.component';
import { SharedModule } from '../shared/shared.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CnsPatientModalComponent } from './pages/sync-measurer-register/cns-patient-modal/cns-patient-modal.component';
import { RemoteMonitoringPatientComponent } from './pages/remote-monitoring-patient/remote-monitoring-patient.component';
import { RemoteMonitoringPatientListComponent } from './pages/remote-monitoring-patient-list/remote-monitoring-patient-list.component';
import { PatientDataModalComponent } from './pages/remote-monitoring-patient/patient-data-modal/patient-data-modal.component';
import { UrgencyAlertModalComponent } from './pages/remote-monitoring-patient-list/urgency-alert-modal/urgency-alert-modal.component';
import { PatientCareLinesModalComponent } from './pages/remote-monitoring-patient/patient-care-lines-modal/patient-care-lines-modal.component';
import { PatientClinicalObservationModalComponent } from './pages/remote-monitoring-patient/patient-clinical-observation-modal/patient-clinical-observation-modal.component';
import { NgChartsModule } from 'ng2-charts';
import { PlotLineChartComponent } from './pages/remote-monitoring-patient/plot-line-chart/plot-line-chart.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';


@NgModule({
  declarations: [
    RemoteMonitoringComponent,
    SyncMeasurerListComponent,
    SyncMeasurerDeleteModalComponent,
    SyncMeasurerRegisterComponent,
    ListPatientComponent,
    LinkPatientComponent,
    CnsPatientModalComponent,
    RemoteMonitoringPatientComponent,
    RemoteMonitoringPatientListComponent,
    PatientDataModalComponent,
    PatientCareLinesModalComponent,
    PatientClinicalObservationModalComponent,
    UrgencyAlertModalComponent,
    PlotLineChartComponent,
  ],
  imports: [
    CommonModule,
    RemoteMonitoringRoutingModule,
    SharedModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTreeModule,
    MatRadioModule,
    MatTooltipModule,
    MatToolbarModule,
    NgxMatFileInputModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    MatSlideToggleModule,
    CdkAccordionModule,
    MatDatepickerModule,
    MatPaginatorModule,
    NgChartsModule,
    MatDialogModule
  ]
})
export class RemoteMonitoringModule { }
