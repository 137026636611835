import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { GenericCallComponent } from './pages/generic-call/generic-call.component';

const routes: Routes = [
  { path: 'generic-call', component: GenericCallComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GenericCallRoutingModule { }
