import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CommonService } from "src/app/shared/services/common-service";
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';
import { catchError } from "rxjs/operators";
import { MedicalRecordResponse } from "../../responses/orchestrator-patient/medical-record.response";
import { MedicalProcedureWithNameResponse } from "../../models/medical-record/medical-procedure.model";
import { SaveCompletedMedicalRecordRequest } from "../../requests/orchestrator-patient/save-completed-medical-care-record.request";
import { CompletePatientAppointmentCareRequest } from "../../requests/orchestrator-patient/complete-patient-appointment-care.request";
import { NewReportResponse } from "../../responses/orchestrator-patient/new-report.response";

@Injectable({
  providedIn: 'root'
})
export class MedicalRecordService extends CommonService {
  constructor(private httpClient: HttpClient) { super(); }

  public postMedicalRecord(content: SaveCompletedMedicalRecordRequest): Observable<NewReportResponse> {
    let uri = 'MedicalRecord/'

    return this.httpClient.post<NewReportResponse>(environment.urlApiOrchestratorPatient + uri, content, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public postPatientAppointmentCare(content: CompletePatientAppointmentCareRequest): Observable<NewReportResponse> {
    let uri = 'PatientAppointmentCare/'

    return this.httpClient.post<NewReportResponse>(environment.urlApiOrchestratorPatient + uri, content, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public getMedicalRecord(idEpisode: number): Observable<MedicalRecordResponse> {
    let uri = `MedicalRecord/idEpisode/${idEpisode}`

    return this.httpClient.get<MedicalRecordResponse>(environment.urlApiOrchestratorPatient + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public getProceduresByCbo(): Observable<MedicalRecordResponse> {
    let uri = `ListProcedureByCbo`

    return this.httpClient.get<MedicalRecordResponse>(environment.urlApiMedic + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public getObservationMedicalProcedure(idEpisode: number): Observable<MedicalProcedureWithNameResponse> {
    let uri = `MedicalProcedures/idEpisode/${idEpisode}`

    return this.httpClient.get<MedicalProcedureWithNameResponse>(environment.urlApiOrchestratorPatient + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}