import { Injectable } from "@angular/core";
import { PriorityModel } from "../shared/services/models/totem/priority.model";

@Injectable({
    providedIn: 'root'
})
export class UtilsClientService {
    constructor() { }

    //Painel
    updatePanelPin(panelPin: string) {
        localStorage.setItem("panelPin", panelPin);
    }

    removePanelPin() {
        localStorage.removeItem("panelPin");
    }

    getPanelPin() {
        let panelPin: string = localStorage.getItem("panelPin");
        return panelPin;
    }

    updatePinRemote(panelPin: string) {
        localStorage.setItem("remotePin", panelPin);
    }

    removeRemoteMemory() {
        localStorage.removeItem("remotePin");
        localStorage.removeItem("remoteSectorRoom");
    }

    getPinRemote() {
        let panelPin: string = localStorage.getItem("remotePin");
        return panelPin;
    }

    updateSectorRoomRemote(SectorRoom: string) {
        localStorage.setItem("remoteSectorRoom", SectorRoom);
    }

    setIsButtonSchedule(isSchedule: boolean) {
        if(isSchedule===true || isSchedule ===false)
            localStorage.setItem("isSchedule", isSchedule.toString());
        else
            localStorage.removeItem("isSchedule");
    }

    setIdUserPatientSchedule(IdUserPatient: number) {
        localStorage.setItem("IdUserPatient", String(IdUserPatient));
    }

    getIdUserPatientSchedule() {
        let IdUserPatient: number | null = parseInt(localStorage.getItem("IdUserPatient") || "0", 10);
        return IdUserPatient;
    }

    setIdSchedule(IdSchedule: number) {
        localStorage.setItem("IdSchedule", String(IdSchedule));
    }

    getIdSchedule() {
        let IdSchedule: number | null = parseInt(localStorage.getItem("IdSchedule") || "0", 10);
        return IdSchedule;
    }

    getSectorRoomRemote() {
        let sectorRoom: string = localStorage.getItem("remoteSectorRoom");
        return sectorRoom;
    }

    //Totem
    updateTotemPin(totemPin: string) {
        localStorage.setItem("totemPin", totemPin);
    }

    removeTotemPin() {
        localStorage.removeItem("totemPin");
    }

    getTotemPin() {
        let totemPin: string = localStorage.getItem("totemPin");
        return totemPin;
    }

    //PainelBi
    updateBiPanelPin(biPanelPin: string, idBIPanel: number) {
        localStorage.setItem("biPanelPin_" + idBIPanel, biPanelPin);
    }

    removeBiPanelPin(idBIPanel: number) {
        localStorage.removeItem("biPanelPin_" + idBIPanel);
    }

    getBiPanelPin(idBIPanel: number) {
        let biPanelPin: string = localStorage.getItem("biPanelPin_" + idBIPanel);
        return biPanelPin;
    }

    updateSessionBiPanelPin(biPanelPin: string) {
        sessionStorage.setItem("biPanelPin", biPanelPin);
    }

    getSessionBiPanelPin() {
        let biPanelPin: string = sessionStorage.getItem("biPanelPin");
        return biPanelPin;
    }

    updateBiPanelIndex(idBIPanel: number, index: number) {
        sessionStorage.setItem("biPanelIndex_" + idBIPanel, index.toString());
    }

    getBiPanelIndex(idBIPanel: number) {
        let biPanelPin: string = sessionStorage.getItem("biPanelIndex_" + idBIPanel);
        return biPanelPin;
    }

    removeBiPanelIndex(idBIPanel: number) {
        sessionStorage.removeItem("biPanelIndex_" + idBIPanel);
    }
}