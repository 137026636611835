import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { StorageService } from 'src/app/shared/services/API/pharmacy/storage.service';
import { StorageStruct } from 'src/app/shared/services/structs/pharmacy/storage.struct';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { LocationService } from 'src/app/shared/services/API/pharmacy/location.service';
import { LocationModel } from 'src/app/shared/services/models/pharmacy/location.model';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { PharmacyMovementService } from 'src/app/shared/services/API/pharmacy/pharmacy-movement.service';
import { PharmacyMovementDashboardService } from 'src/app/shared/services/API/pharmacy/pharmacy-movement-dashboard.service';


@Component({
  selector: 'app-pharmacy-movement-list',
  templateUrl: './pharmacy-movement-list.component.html',
  styleUrls: ['./pharmacy-movement-list.component.css']
})
export class PharmacyMovementListComponent implements OnInit {

  constructor(
    private pharmacyMovementService: PharmacyMovementService,
    private pharmacyMovementDashboardService: PharmacyMovementDashboardService,
    private alertService: AlertService,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
  ) { }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.pharmacy;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.pharmacy_storage;

  public listStorage: StorageStruct[];
  public isLoading: boolean;
  public model: UntypedFormGroup;
  public pageIndex: number;
  public code: string;
  public description: string;
  public fullSize: number;
  public searchTextBelowMinimum: string = '';
  public searchTextExpired: string = '';
  public listStorageBelowMinimum: StorageStruct[] = [];
  public listStorageExpired: StorageStruct[] = [];
  public pageSizeTable: number = 0;
  public totalBelowMinimum: number = 0;
  public totalExpired: number = 0;

  
  ngOnInit(): void {
    this.model = this.formBuilder.group({
      code: [''],
      description: [''],
    });
    this.isLoading = true;
    this.search();
    this.searchTableBelowMinimum(0);
    this.searchTableExpired(0);
  }

  search() {
    this.isLoading = true;
    this.pharmacyMovementService.listAllProducts(this.pageIndex, this.code, this.description).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listStorage = response.listStorage;
      this.fullSize = response.fullsize;
      this.totalBelowMinimum = response.totalBelowMinimum;
      this.totalExpired = response.totalExpired;
      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }


  buttonSearch() {
    this.pageIndex = 0;
    this.listStorage = [];
    this.code = this.model.get("code").value;
    this.description = this.model.get("description").value;
    this.search();
  }

  changePage(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.listStorage = [];
    this.model.get("code").setValue(this.code);
    this.model.get("description").setValue(this.description);
    this.search();
  }

  private timeoutKeySearchBelowMinimum: any = null;

  onKeySearchBelowMinimum(event: any) {
    clearTimeout(this.timeoutKeySearchBelowMinimum);
    var $this = this;
    this.timeoutKeySearchBelowMinimum = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.paginator.firstPage();
        $this.searchTableBelowMinimum(0);
      }
    }, 1000);
  }

  searchTableBelowMinimum(pageIndex: number) {
    this.isLoading = true;
    if (this.searchTextBelowMinimum == "")
      this.searchTextBelowMinimum = null;

    this.pharmacyMovementDashboardService.listAllBelowMinimumProducts(pageIndex, this.searchTextBelowMinimum).subscribe({
      next: (response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.listStorageBelowMinimum = response.listStorage;
        this.pageSizeTable = response.pageSize;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
    this.isLoading = false;
  }

  changePageBelowMinimum(event: PageEvent) {
    this.searchTableBelowMinimum(event.pageIndex);
  }

  private timeoutKeySearchExpired: any = null;

  onKeySearchExpired(event: any) {
    clearTimeout(this.timeoutKeySearchExpired);
    var $this = this;
    this.timeoutKeySearchExpired = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.paginator.firstPage();
        $this.searchTableExpired(0);
      }
    }, 1000);
  }

  searchTableExpired(pageIndex: number) {
    this.isLoading = true;
    if (this.searchTextExpired == "")
      this.searchTextExpired = null;
    
    this.pharmacyMovementDashboardService.listAllExpiredProducts(pageIndex, this.searchTextExpired).subscribe({
      next: (response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listStorageExpired = response.listStorage;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
    this.isLoading = false;
  }

  changePageExpired(event: PageEvent) {
    this.searchTableExpired(event.pageIndex);
  }
}
