import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CommonService } from "src/app/shared/services/common-service";
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';
import { catchError } from "rxjs/operators";
import { ListMedicalProcedureCheckResponse } from "../../responses/medical-record/list-medical-procedure-check.response";

@Injectable({
  providedIn: 'root'
})
export class ListMedicalProcedureCheckService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listMedicalProcedureCheck(): Observable<ListMedicalProcedureCheckResponse> {
    let uri = `ListMedicalProcedureCheck`

    return this.httpClient.get<ListMedicalProcedureCheckResponse>(environment.urlApiMedicalRecord + uri,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}