import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SADTRoutingModule } from './sadt-routing.module';
import { SadtComponent } from './pages/sadt/sadt.component';
import { SadtAttendPatientComponent } from './pages/sadt-attend-patient/sadt-attend-patient.component';
import { SharedModule } from '../shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckbox, MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatTree, MatTreeModule } from '@angular/material/tree';
import { MatRadioButton, MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgxMaskModule } from 'ngx-mask';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { SadtAccordionComponent } from './pages/sadt-attend-patient/sadt-accordion/sadt-accordion.component';
import { ForwardPatientSadtModalComponent } from './pages/sadt-attend-patient/forward-patient-sadt-modal/forward-patient-sadt-modal.component';
import { ForwardPatientSadtConfirmModalComponent } from './pages/sadt-attend-patient/forward-patient-sadt-modal/forward-patient-sadt-confirm-modal/forward-patient-sadt-confirm-modal.component';
import { SadtPatientListComponent } from './pages/sadt-patient-list/sadt-patient-list.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { RequestedExamsModalComponent } from './pages/sadt-patient-list/requested-exams-modal/requested-exams-modal.component';
import { SadtAttachFileModalComponent } from './pages/sadt-patient-list/sadt-attach-file-modal/sadt-attach-file-modal.component';
import { ConfirmConductSaveModalComponent } from './pages/sadt-attend-patient/confirm-conduct-save-modal/confirm-conduct-save-modal.component';
import { ExamTypeListComponent } from './pages/exam/exam-type-list/exam-type-list.component';
import { ExamTypeDeleteModalComponent } from './pages/exam/exam-type-list/exam-type-delete-modal/exam-type-delete-modal.component';
import { ExamTypeRegisterComponent } from './pages/exam/exam-type-register/exam-type-register.component';
import { ExamListComponent } from './pages/exam/exam-list/exam-list.component';
import { ExamDeleteModalComponent } from './pages/exam/exam-list/exam-delete-modal/exam-delete-modal.component';


@NgModule({
  declarations: [
    SadtComponent,
    SadtAttendPatientComponent,
    SadtAccordionComponent,
    ForwardPatientSadtModalComponent,
    ForwardPatientSadtConfirmModalComponent,
    SadtPatientListComponent,
    RequestedExamsModalComponent,
    SadtAttachFileModalComponent,
    ConfirmConductSaveModalComponent,
    ExamTypeListComponent,
    ExamTypeDeleteModalComponent,
    ExamTypeRegisterComponent,
    ExamListComponent,
    ExamDeleteModalComponent,
  ],
  imports: [
    CommonModule,
    SADTRoutingModule,
    SharedModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTreeModule,
    MatRadioModule,
    MatTooltipModule,
    MatToolbarModule,
    NgxMatFileInputModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    MatSlideToggleModule,
    CdkAccordionModule,
    MatPaginatorModule,
    MatDialogModule
  ],
  providers: [
    AuthGuard,
    MatCheckbox,
    MatCheckboxChange,
    MatTree,
    MatRadioButton,
  ]
})
export class SADTModule { }
