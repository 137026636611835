import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ExamService } from 'src/app/shared/services/API/SADT/exam.service';


@Component({
  selector: 'app-exam-delete-modal',
  templateUrl: './exam-delete-modal.component.html',
  styleUrls: ['./exam-delete-modal.component.css']
})
export class ExamDeleteModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<ExamDeleteModalComponent>,
  private examService: ExamService,
  private alertService: AlertService) { }

  public isLoading:boolean;  

  ngOnInit(): void {
    this.isLoading = false;
  }
  
  close(){
    this.matDialogRef.close({deleteExam: false}); 
  }
  
  clickDelete(){
    
    this.isLoading = true;

    this.examService.deleteExam(this.data.idExam).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.alertService.show('Sucesso', "Exame excluido com sucesso!", AlertType.success); 
      this.matDialogRef.close({deleteExam: true}); 
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }
  
  clickCancel(){
    this.matDialogRef.close({deleteExam: false}); 
  }
}

