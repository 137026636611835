import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { AuditMonthlyReportRequest } from '../../requests/orchestrator-audit/audit-monthly-report.request';
import { AuditMonthlyReportResponse } from '../../responses/orchestrator-audit/get-audit-monthly-report.response';

@Injectable({
  providedIn: 'root'
})
export class AuditMonthlyReportService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getMonthlyReport(beginPeriod: Date, endPeriod: Date): Observable<AuditMonthlyReportResponse> {
    let uri = `MonthlyReport?`;
    uri += beginPeriod != null ? `beginPeriod=${beginPeriod.toISOString()}` : `&beginPeriod=`;
    uri += endPeriod != null ? `&endPeriod=${endPeriod.toISOString()}` : `&endPeriod=`;
      
    return this.httpClient.get<AuditMonthlyReportResponse>(environment.urlApiOrchestratorAudit + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public createMonthlyReport(request: AuditMonthlyReportRequest): Observable<ReturnStruct> {
    let uri = `MonthlyReport`;
      
    return this.httpClient.post<ReturnStruct>(environment.urlApiOrchestratorAudit + uri, request, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}