<div class="gauge-content">
    <div class="gauge">
        <span class="gauge-metric-0">0</span>
        <span class="gauge-metric-firstLimit">{{this.firstLimit}}</span>
        <span class="gauge-metric-secondLimit">{{this.secondLimit}}</span>
        <span class="gauge-metric-thirdLimit">{{this.thirdLimit}}</span>
        <div class="gauge-body">
            <div class="gauge-fill" [ngStyle]="{ 'transform': 'rotate(' + this.value + 'turn)'}"></div>
            <div class="gauge-cover">
            </div>
            
            <div class="gauge-value" [matTooltip]="textTooltip">
                <span class="title">{{this.title}}</span>
                <span><b>{{this.showValue}}</b></span>
            </div>
        </div>
    </div>
</div>