<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <h1>Painel</h1>
        <div class="white-body">
            <a mat-flat-button color="primary" class=" add-user" routerLink="/master/panel/register"><mat-icon
                    aria-hidden="false" aria-label="Plus">add</mat-icon> Cadastrar Painel</a>
            <div class="filters">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Pesquisar</mat-label>
                            <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText"
                                (keyup)="onKeySearch($event)">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Status</mat-label>
                            <mat-select [(ngModel)]="isActive" (selectionChange)="search()">
                                <mat-option value="">Todos</mat-option>
                                <mat-option value="true">Ativo</mat-option>
                                <mat-option value="false">Inativo</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="card-list" *ngIf="listPanel != null">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listPanel">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary">{{item.panelName}}</span>
                                <span class="secundary">{{item.description}}</span>
                                <span class="secundary">
                                    url: <a type="button" class="direct-link" [href]="item.panelUrl"
                                        target="_blank">{{"/client/display/"+item.idPanel}}</a>
                                    <button matTooltip="Copiar" aria-label="Copiar" class="copy-to-clipboard"
                                        type="button" [cdkCopyToClipboard]="item.panelUrl"
                                        [cdkCopyToClipboardAttempts]="5" (click)="onCopy('item copiado')">
                                        <mat-icon class="copy-icons">content_copy</mat-icon>
                                    </button>
                                </span>
                                <span class="secundary">pin: {{item.pin}}
                                    <button matTooltip="Copiar" aria-label="Copiar" class="copy-to-clipboard"
                                        type="button" [cdkCopyToClipboard]="item.pin" [cdkCopyToClipboardAttempts]="5"
                                        (click)="onCopy('item copiado')">
                                        <mat-icon class="copy-icons">content_copy</mat-icon>
                                    </button>
                                    <button matTooltip="Atualizar PIN" aria-label="Atualizar PIN"
                                        class="copy-to-clipboard" type="button"
                                        (click)="openUpdatePinModal(item.idPanel)">
                                        <mat-icon class="copy-icons">refresh</mat-icon>
                                    </button>
                                </span>
                                <span class="secundary-status last-info">{{item.isActive? "Ativo":"Inativo"}}</span>
                            </div>
                            <div class="actions">
                                <a><mat-icon aria-hidden="false" aria-label="Editar"
                                        [routerLink]="['/master/panel/register', {idPanel: item.idPanel}]">edit</mat-icon>
                                </a>
                                <a><mat-icon aria-hidden="false" aria-label="Editar"
                                        (click)="openModal(item.idPanel)">delete_forever</mat-icon> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <app-empty-list *ngIf="listPanel != undefined && listPanel.length == 0"></app-empty-list>
        </div>

        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>

</div>