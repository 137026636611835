import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { BatchLookupResponse } from '../../responses/batch/batch-lookup.response';

@Injectable({
  providedIn: 'root'
})
export class BatchLookupService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getBatchLookup(): Observable<BatchLookupResponse>{

    let uri = `Lookup`;

    return this.httpClient.get<BatchLookupResponse>(environment.urlApiBatch + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}