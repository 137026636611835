import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisplayOptionsComponent } from './display-options.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { DisplayStandartTemplateModule } from './components/display-standart-template/display-standart-template.module';
import { DisplayLayoutLeftTemplateModule } from './components/display-layout-left-template/display-layout-left-template.module';
import { DisplayLayoutRightTemplateModule } from './components/display-layout-right-template/display-layout-right-template.module';
import { DisplayStandartTemplateComponentV2 } from './components/display-standart-template-v2/display-standart-template-v2.component';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [DisplayOptionsComponent, DisplayStandartTemplateComponentV2],
  exports: [DisplayOptionsComponent],
  imports: [
    CommonModule,
    SharedModule,
    MatIconModule,
    DisplayStandartTemplateModule,
    DisplayLayoutLeftTemplateModule,
    DisplayLayoutRightTemplateModule,
    MatDialogModule
  ]
})
export class DisplayOptionsModule { }