<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <a class="back-link" routerLink="/master">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Módulo Master
        </a>
        <h1>Configurações da Unidade de Saúde</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome</mat-label>
                            <input matInput type="text" formControlName="name">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Fuso Horário</mat-label>
                            <mat-select formControlName="idTimeZone">
                                <mat-option *ngFor="let item of listTimeZone" value="{{item.idTimeZone}}">
                                    {{item.timeZoneName}} <span style="font-weight: bold;">{{item.timeZoneValue}}
                                        (GMT)</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Tempo para Evasão Automática</mat-label>
                            <mat-select formControlName="idEvasionTime">
                                <mat-option *ngFor="let item of listEvasionTime" value="{{item.idEvasionTime}}">
                                    {{item.evasionTime}}h 
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Duração do Login</mat-label>
                            <mat-select formControlName="idLoginTimeout">
                                <mat-option *ngFor="let item of listLoginTimeout" value="{{item.idLoginTimeout}}">
                                    {{item.loginTimeoutName}} <span style="font-weight: bold;">{{item.idLoginTimeout == this.idMaxValue ? "Sem Limite" : item.loginTimeoutValue+"h"}}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Expiração de senha</mat-label>
                            <mat-select formControlName="idPasswordExpirationTime">
                                <mat-option *ngFor="let item of listPasswordExpirationTime" value="{{item.idPasswordExpirationTime}}">
                                    {{item.passwordExpirationTimeName}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idPasswordExpirationTime').invalid">Informe o tempo de expiração da senha</mat-error>
                        </mat-form-field>
                    </div>  
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Tempo de expiração automática de contas inativas</mat-label>
                            <mat-select formControlName="idUserExpirationTime">
                                <mat-option [value]="null">
                                    Não expira
                                </mat-option>
                                <mat-option *ngFor="let item of listUserExpirationTime" [value]="item.idUserExpirationTime">
                                    {{item.valueUserExpirationTime}} dias
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>   
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Tempo de alerta para retornos recorrentes</mat-label>
                            <mat-select formControlName="idAlertTimeRecurringReturns">
                                <mat-option [value]="null">Nenhum</mat-option>
                                <mat-option *ngFor="let item of listAlertTimeRecurringReturns" [value]="item.idAlertTimeRecurringReturns">
                                    {{item.alertTimeDescription}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>   
                </div>
                <div class="row" *ngIf="this.model.get('isBillingSigtap').value">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>CNES</mat-label>
                            <input matInput type="text" formControlName="cnes">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Coordenadoria</mat-label>
                            <mat-select formControlName="coordination">
                                <mat-option *ngFor="let item of listCoordination" value="{{item.idCoordination}}">
                                    {{item.coordinationName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Supervisão</mat-label>
                            <mat-select formControlName="supervision" disabled>
                                <mat-option *ngFor="let item of listSupervision" value="{{item.idSupervision}}">
                                    {{item.supervisionName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 col-sm-6 col-md-2">
                        <div class="form-field-toggle">
                            <mat-label>Usar Faturamento Sigtap</mat-label>
                            <mat-slide-toggle formControlName="isBillingSigtap"></mat-slide-toggle>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline" *ngIf="this.model.get('isBillingSigtap').value">
                            <mat-label>Faturamentos</mat-label>
                            <mat-select formControlName="idProcedureSigtapCompetence">
                                <mat-option value="-1">
                                    Sempre usar o mais atualizado
                                </mat-option>
                                <mat-option *ngFor="let item of listProcedureSigtapCompetence" value="{{item.idProcedureSigtapCompetence}}">
                                    {{item.dateCompetence | date:'mm/yyyy'}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    
                    <div class="col-12 col-md-3">
                        <div class="form-field-toggle">
                            <mat-slide-toggle [(ngModel)]="canAddTissGuide" [ngModelOptions]="{standalone: true}" (change)="changecanAddTissGuide()" *ngIf="!isFirstLoading">Exibir Botão Nova Guia TISS</mat-slide-toggle>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 col-sm-6 col-md-2">
                        <div class="form-field-toggle">
                            <mat-label>Usar Logo</mat-label>
                            <mat-slide-toggle formControlName="haveLogo"></mat-slide-toggle>
                        </div>
                    </div>
                    <div class="col-6 col-sm-6 col-md-2">
                        <a class="link-uploaded-logo" (click)="openModal()" *ngIf="this.model.get('haveLogo').value">
                            <mat-icon class="img-uploaded-logo">image</mat-icon>
                            <span *ngIf="this.logo">Visualizar Logo</span>
                            <span *ngIf="!this.logo">Selecionar Logo</span>
                        </a>
                    </div>
                </div>
                <div class="title-header">
                    <h1>Configuração de login</h1>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Tipo de login</mat-label>
                            <mat-select formControlName="idLoginType" (selectionChange)="verifyRequiredDomain()">
                                <mat-option *ngFor="let item of listLoginType" value="{{item.idLoginType}}">
                                    {{item.loginTypeName}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idLoginType').invalid">Informe o tipo de login</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row" formArrayName="loginConfig"
                        *ngFor="let item of model.get('loginConfig')['controls']; let i = index">
                        <div class="row" [formGroupName]="i">
                            <div class="col-12 col-sm-6 col-md-4">
                                <mat-form-field appearance="outline">
                                    <mat-label>Domínio de e-mail permitido</mat-label>
                                    <input matInput type="text" formControlName="emailDomain" (keyup)="validateEmailAllowed(i)" >
                                    <mat-error *ngIf="item.get('emailDomain').invalid">Informe os domínios de email permitidos</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-2 col-sm-2 col-md-3">
                                <button class="side-button" color="primary" mat-mini-fab type="button" (click)="addNext()">
                                    <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
                                </button>
                                <button class="side-button" color="primary" mat-mini-fab type="button" *ngIf="i != 0"
                                    (click)="remove(i)">
                                    <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                <div class="row">
                    <div class="col-md-2">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <a mat-flat-button type="button" routerLink="/master" color="accent" class=" btn-block">
                            Cancelar
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>
    
</div>