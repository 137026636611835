import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { AdministrationTypeEnum, DilutionEnum, FrequencyEnum, PresentationEnum } from 'src/app/shared/enum/prescription.enum';
import { MedicineStruct } from 'src/app/shared/services/structs/pharmacy/medicine.struct';
import { AllergyModel } from 'src/app/shared/services/models/medical-record/allergy.model';
import { StandardPrescriptionStruct } from 'src/app/shared/services/structs/medical-record/standard-prescription.struct';
import { LookupMedicalCareResponse } from 'src/app/shared/services/responses/medical-record/lookup-medical-care-response';
import { MedicConfigSectorStruct } from 'src/app/shared/services/structs/medic/medic-config-sector.struct';
import { ListMedicineResponse } from 'src/app/shared/services/responses/pharmacy/list-medicine.response';

@Component({
  selector: 'app-prescription-modal',
  templateUrl: './prescription-modal.component.html',
  styleUrls: ['./prescription-modal.component.css']
})
export class PrescriptionModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public matDialogRef: MatDialogRef<PrescriptionModalComponent>
  ) { }

  public isLoading: boolean = true;
  public readOnly: boolean;
  public medicalPrescription: StandardPrescriptionStruct = new StandardPrescriptionStruct();
  public prescriptionMedicatedSolutionModel: FormGroup;
  public selectedMedicineList: MedicineStruct[] = [];
  public medicineList: MedicineStruct[] = [];
  public selectedMedicine: MedicineStruct;
  public usageList: any[] = [];
  public administrationTypeList: any[] = [];
  public administrationTypeListDisplay: any[] = [];
  public frequencyList: any[] = [];
  public presentationList: any[] = [];
  public treatmentList: any[] = [];
  public dilutionList: any[] = [];
  public measurementUnitList: any[] = [];
  public dilutionUnitList: any[] = [];
  public administrationTypeOthersEnum: AdministrationTypeEnum = AdministrationTypeEnum.outros;
  public presentationOthersEnum: PresentationEnum = PresentationEnum.outros;
  public dilutionOthersEnum: DilutionEnum = DilutionEnum.outros;

  public idAdministrationType: number;
  public idFrequency: number
  public idTreatmentPeriod: number;
  public idPresentation: number;
  public idDilution: number;
  public bypassPrescription: boolean = false;
  public isFastTrack: boolean = false;
  public justification: string;
  public listAllergy: AllergyModel[] = [];
  public frequencyEnum: FrequencyEnum = FrequencyEnum.now;
  public isFrequency: boolean = false;
  public listSubFrequency: any[];
  public frequencyNow: any;
  public ifNecessary: FrequencyEnum = FrequencyEnum.ifNecessary;
  public isMedicatedSolution: boolean = false;
  public hasNewStandardPrescription: boolean = false;

  public lookupMedicalCareResponse: LookupMedicalCareResponse;
  public medicConfig: MedicConfigSectorStruct;
  public listMedicine: ListMedicineResponse;

  ngOnInit(): void {
    if (this.data.prescription != null)
      this.medicalPrescription = this.data.prescription;
    else {
      this.medicalPrescription.medicatedSolutionStruct = null;
      this.medicalPrescription.readMedicalPrescriptionStruct = null;
    }

    this.isFastTrack = this.data.isFastTrack;
    this.medicineList = this.data.listMedicine.listMedicineStruct;
    this.listAllergy = this.data.listAllergy;
    this.readOnly = this.data.readOnly;
    this.lookupMedicalCareResponse = this.data.lookupMedicalCareResponse;
    this.medicConfig = this.data.medicConfig;
    this.listMedicine = this.data.listMedicine;

    if (this.isFastTrack)
      this.selectedMedicineList = this.data.listMedicine.listMedicineStruct.filter(x => x.medicine.fastTrack == true);
    else
      this.selectedMedicineList = this.data.listMedicine.listMedicineStruct;

    this.populateLookup();

    if (this.data.isMedicatedSolution != null)
      this.isMedicatedSolution = this.data.isMedicatedSolution;

    this.isLoading = false;
  }

  populateLookup() {
    this.usageList = this.data.lookupMedicalCareResponse.listUsage;
    this.administrationTypeList = this.data.lookupMedicalCareResponse.listAdministrationType;
    this.frequencyList = this.data.lookupMedicalCareResponse.listFrequency;
    this.presentationList = this.data.lookupMedicalCareResponse.listPresentation;
    this.treatmentList = this.data.lookupMedicalCareResponse.listTreatmentPeriod;
    this.dilutionList = this.data.lookupMedicalCareResponse.listDilution;
    this.measurementUnitList = this.data.lookupMedicalCareResponse.listMeasurementUnit;
    this.dilutionUnitList = this.data.lookupMedicalCareResponse.listDilutionUnit;
    this.listSubFrequency = this.data.lookupMedicalCareResponse.listSubFrequency;
  }

  close() {
    this.matDialogRef.close();
  }

  submit(request: any) {
    this.medicalPrescription = new StandardPrescriptionStruct()

    if (this.isMedicatedSolution) {
      this.medicalPrescription.readMedicalPrescriptionStruct = null;
      this.medicalPrescription.medicatedSolutionStruct = request.medicatedSolutionStruct;
    }
    else {
      this.medicalPrescription.readMedicalPrescriptionStruct = request.medicalPrescription;
      this.medicalPrescription.medicatedSolutionStruct = null;
    }

    this.matDialogRef.close({ medicalPrescription: this.medicalPrescription, bypassPrescription: request.bypassPrescription });
  }

  changeToMedicatedSolution(event: any) {
    this.isMedicatedSolution = !this.isMedicatedSolution;
  }
}