<div mat-dialog-content>
    <div class="mat-dialog-content">
        <h3>Auditoria - {{idClassificationRiskAudit}}</h3>
        <h2>Histórico de movimentações desse card</h2>
        <div *ngIf="listAuditCardUpdateControl != null">
            <div *ngFor="let item of listAuditCardUpdateControl">
                <div class="container-infos">
                    <span class="text-bold">{{item.userName}}</span>
                    <span class="text-regular"> moveu este card de {{item.initialColumn}}
                        para {{item.finalColumn}}</span>
                    <p class="text-date">{{item.datetimeInclusion | date:'dd/MM/yyyy \'às\' HH:mm'}}</p>
                </div>
            </div>
        </div>
        <app-empty-list [message]="'Não há histórico de movimentação para esta auditoria'" *ngIf="listAuditCardUpdateControl != undefined 
        && listAuditCardUpdateControl.length == 0">
        </app-empty-list>
        <div class="footer-audit-modal-list">
            <button mat-flat-button color="primary" class="btn-block" (click)="clickCancel()">OK</button>
        </div>
    </div>
</div>