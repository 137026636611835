import { ListUserStruct } from "../user/list-user.struct";

export class TotemUserStruct {
    public idUser: number;
    public userName: string;
    public pinUser: number;
    public quickIdentifier: string;

    constructor(userStruct?: ListUserStruct) {
        if (userStruct != null) {
            this.idUser = userStruct.idUser;
            this.userName = userStruct.userName;
        }
    }
}