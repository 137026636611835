<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <h1>Cadastro de especialidades</h1>
        <div class="white-body">
            <div class="row">
                <div class="col-6 col-sm-3">
                    <a mat-flat-button color="primary" class="add-user"
                        routerLink="/digital-prompt-service/patient-center/medical-specialty/register"><mat-icon
                            aria-hidden="false" aria-label="Plus">add</mat-icon>Adicionar especialidade</a>
                </div>
            </div>

            <div class="filters">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Pesquisar</mat-label>
                            <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText"
                                (keyup)="onKeySearch($event)">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="card-list" *ngIf="listMedicalSpecialtyCompleted && listMedicalSpecialtyCompleted.length > 0">
                <div class="row">
                    <div class="col-12 col-sm-6 col-lg-4" *ngFor="let item of listMedicalSpecialtyCompleted">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary">{{item.medicalSpecialty.medicalSpecialtyName}}</span>
                                <span class="secundary"><b>Tipo de Atendimento: </b>&nbsp;
                                    <span>{{getFormatedListNatureOfAttendance(item.listNatureOfAttendance)}}</span>
                                </span>
                                <span class="secundary"><b>Médicos que atendem: </b>&nbsp;
                                    <!-- <span *ngFor="let user of item.listUser">{{user.userName}}</span> -->
                                    <span
                                        matTooltip="{{getFormatedListUserName(item.listUser)}}">{{getFormatedListUserName(item.listUser)}}</span>
                                    <!-- incluir nome dos médicos -->
                                </span>
                                <span class="secundary">
                                    <b>Aceita convênios: </b>&nbsp;
                                    {{item.medicalSpecialty.allowSelectionHealthcareAgreement ? "Sim" : "Não"}}
                                </span>
                            </div>
                            <div class="actions">
                                <a><mat-icon aria-hidden="false" aria-label="Editar"
                                        [routerLink]="['/digital-prompt-service/patient-center/medical-specialty/register', {idMedicalSpecialty: item.medicalSpecialty.idMedicalSpecialty}]">edit</mat-icon>
                                </a>
                                <a><mat-icon aria-hidden="false" aria-label="Editar"
                                        (click)="openModal(item.medicalSpecialty.idMedicalSpecialty)">delete_forever</mat-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <app-empty-list *ngIf="!listMedicalSpecialtyCompleted && listMedicalSpecialtyCompleted.length == 0 && isLoading"></app-empty-list>
            <app-loading-list *ngIf="isLoading"></app-loading-list>
        </div>
    </div>


</div>
