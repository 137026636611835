export class UserPatient {
    public idUserPatient: number;
    public idPatient: number;
    public idHealthUnit: number;
    public cpf: string;
    public idGender: number;
    public datetimeInclusion: Date;
    public password: string;
    public userName: string;
    public motherName: string;
    public isActive: boolean;
    public isDeleted: boolean;
    public userBirthDate: Date;
    public userEmail: string;
    public guid: string;
}