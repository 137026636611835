export class MedicalProcedureResponseModel {
    public idProcedure: number;
    public idEpisode: number;
    public observation: string;
    public isRecurring: boolean;
    public idProcedureStatus: number;
    public idMedicalCare: number;
    public datetimeInclusion: Date;
    public idMedicalProcedure: number;
    public isDeleted: boolean;
    public idProcedureSigtap: number;
}