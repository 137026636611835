import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { HealthUnitTimeResponse } from '../../responses/user/health-unit-time.response';

@Injectable({
  providedIn: 'root'
})
export class HealthUnitTimeService extends CommonService {

  constructor(private httpClient: HttpClient) { super(); }

  public getTime(idHealthUnit: number): Observable<HealthUnitTimeResponse> {
    let uri = `HealthUnitTime/idHealthUnit/${idHealthUnit}`

    return this.httpClient.get<HealthUnitTimeResponse>(environment.urlApiUser + uri, this.addHeaderPanelToken())
      .pipe(catchError(this.handleError));
  }
}