import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { SearchPatientTotemRequest } from 'src/app/shared/services/requests/orchestrator-totem/search-patient-totem.request';
import { SearchPatientPreregisterService } from 'src/app/shared/services/API/orchestrator-patient/search-patient-preregister.service';

@Component({
  selector: 'app-search-modal',
  templateUrl: './search-modal.component.html',
  styleUrls: ['./search-modal.component.css']
})
export class SearchModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<SearchModalComponent>,
    private alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
    private searchPatientService: SearchPatientPreregisterService,
    private maskService: MaskService,) { }

  public searchModel: UntypedFormGroup;
  public isLoading: boolean = false;
  public masks: Masks;
  public cpfCnsMask;
  public cpf: string = null;
  public cns: string = null;
  public ticket: string = '';

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.cpfCnsMask = this.masks.cpfCns;
    if(this.data && this.data.ticket != null && this.data.ticket != '')
      this.ticket = this.data.ticket;
    this.searchModel = this.formBuilder.group({
      cpfCns: ['', Validators.required],
    });
  }

  clickCancel() {
    if (this.searchModel.get('cpfCns').value.replace(/[^0-9]/g, '').replace(' ', '').length <= 11) 
      this.cpf = this.searchModel.get('cpfCns').value.replace(/[^0-9]/g, '').replace(' ', '');
    else
      this.cns = this.searchModel.get('cpfCns').value.replace(/[^0-9]/g, '').replace(' ', '');
    this.matDialogRef.close({cpf:this.cpf, cns:this.cns});
  }
  close() {
    if (this.searchModel.get('cpfCns').value.replace(/[^0-9]/g, '').replace(' ', '').length <= 11) 
      this.cpf = this.searchModel.get('cpfCns').value.replace(/[^0-9]/g, '').replace(' ', '');
    else
      this.cns = this.searchModel.get('cpfCns').value.replace(/[^0-9]/g, '').replace(' ', '');
    this.matDialogRef.close({cpf:this.cpf, cns:this.cns});
  }

  submit() {
    if (this.isLoading || this.searchModel.invalid)
      return;

    this.isLoading = true;
    let request: SearchPatientTotemRequest = new SearchPatientTotemRequest();

    let cns: string = null;
    let cpf: string = null;

    if (this.searchModel.get('cpfCns').value.replace(/[^0-9]/g, '').replace(' ', '').length <= 11) {
      cpf = this.searchModel.get('cpfCns').value.replace(/[^0-9]/g, '').replace(' ', '');
      if (!this.validatorCpf(cpf)) {
        this.alertService.show('Erro', "valor inválido", AlertType.error);
        this.isLoading = false;
        return;
      }
    }
    else {
      let validateCns: number = this.searchModel.get('cpfCns').value.replace(/[^0-9]/g, '').replace(' ', '');
      if (!this.validatorCns(validateCns)) {
        this.alertService.show('Erro', "valor inválido", AlertType.error);
        this.isLoading = false;
        return;
      }
      cns = this.searchModel.get('cpfCns').value.replace(/[^0-9]/g, '').replace(' ', '');
    }


    request.cpf = cpf;
    request.cns = cns;

    this.searchPatientService.searchPatientCpfCns(request).subscribe({
      next: (response) => {
        if (response.isError && response.errorCode == 1)
          this.alertService.show('Atenção! ', response.errorDescription, AlertType.warning);

        else if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (response.listPatient && response.listPatient.length >0)
          this.matDialogRef.close({response: response.listPatient[0]});
        else
          this.matDialogRef.close({cpf:cpf, cns:cns});
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  validatorCns(cns: any) {
    let cnsString = cns.toString();

    var validateSize = (cnsString.length == 15)
    var validateStart = ['1', '2', '7', '8', '9'].includes(cnsString[0])

    if (validateSize && validateStart) {
      //CNS Iniciados em 7, 8 ou 9
      if (['7', '8', '9'].includes(cnsString[0])) {
        var sum = cnsString.split('').reduce((total, cns, index) => total + (cns * (15 - index)), 0);
        if (sum % 11 != 0) {
          return false;
        } else {
          return true;
        }

      } else if (['1', '2'].includes(cnsString[0])) {
        //CNS Iniciados em 1, 2
        var pis = cnsString.substring(0, 11);
        var sum = pis.split('').reduce((total, cns, index) => total + (cns.valueOf() * (15 - index)), 0)

        var rest = sum % 11
        var dv = rest == 0 ? 0 : 11 - rest

        var result = dv == 10 ? `${pis}001${(11 - ((sum + 2) % 11))}` : `${pis}000${dv}`
        if (result != cnsString) {
          return false;
        } else {
          return true;
        }

      } else {
        return false;
      }

    } else {
      return false;
    }
  }

  validatorCpf(cpfString) {
    let isValid: boolean = true;

    if (cpfString.length != 11) {
      return false;
    }

    var Soma;
    var Resto;

    Soma = 0;
    if (cpfString == "00000000000" ||
      cpfString == "11111111111" ||
      cpfString == "22222222222" ||
      cpfString == "33333333333" ||
      cpfString == "44444444444" ||
      cpfString == "55555555555" ||
      cpfString == "66666666666" ||
      cpfString == "77777777777" ||
      cpfString == "88888888888" ||
      cpfString == "99999999999") {
      isValid = false;
    }

    for (let i = 1; i <= 9; i++) {
      Soma = Soma + parseInt(cpfString.substring(i - 1, i)) * (11 - i)
    };

    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) {
      Resto = 0
    };

    if (Resto != parseInt(cpfString.substring(9, 10))) {
      isValid = false;
    };

    Soma = 0;

    for (let i = 1; i <= 10; i++) {
      Soma = Soma + parseInt(cpfString.substring(i - 1, i)) * (12 - i)
    };
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) {
      Resto = 0;
    }

    if (Resto != parseInt(cpfString.substring(10, 11))) {
      isValid = false;
    }

    if (!isValid) {
      return false;
    } else {
      return true;
    }
  }
}