<div mat-dialog-contentl>
    <div class="mat-dialog-content">
        <div class="head-confirm-modal-list">
            <h1><b>Atenção !</b></h1>
            <h2>Deseja {{this.startHygienization ? 'iniciar' : 'finalizar'}} a higienização deste leito?</h2>
        </div>
        <form class="form" (ngSubmit)="submit()">
            <div class="footer-confirm-modal-list">
                <button mat-flat-button color="primary" class="btn-block" type="submit">
                    <span *ngIf="isLoading == false">Confirmar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
                <button mat-flat-button color="accent" class="btn-block" (click)="clickCancel()"
                    type="button">Voltar</button>
            </div>
        </form>
    </div>
</div>