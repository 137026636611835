<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <h1>Convênios e planos</h1>
        <div class="white-body">
            <div class="col-12 col-md-4">
                <a mat-flat-button color="primary" class="add-user"
                    routerLink="/billing/healthcare-agreements-and-plans/register">
                    <mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon>
                    Criar novo</a>
            </div>
            <div class="row display-contents">
                <div class="col-12 padding-box">
                    <div class="row border-box">

                        <div class="filters">
                            <div class="row">
                                <div class="col-12 col-md-5 col-xl-4 col-lg-5 col-sm-5">
                                    <mat-form-field appearance="outline">
                                        <mat-icon matPrefix class="my-icon">search</mat-icon>
                                        <mat-label>Pesquisar</mat-label>
                                        <input matInput type="text" id="searchText" name="searchText"
                                            [(ngModel)]="searchText" (keyup)="onKeySearch($event)">
                                    </mat-form-field>
                                </div>
                                <div class="col-12 col-md-3 col-xl-2 col-lg-3 col-sm-3" style="margin-bottom: 0.5rem;">
                                    <button class="custom-button" [class.active]="isButtonActive('ativos')"
                                        (click)="controlButtonStatus('ativos')">Ativos</button>
                                </div>
                                <div class="col-12 col-md-3 col-xl-2 col-lg-3 col-sm-3" style="margin-bottom: 0.5rem;">
                                    <button class="custom-button" [class.active]="isButtonActive('inativos')"
                                        (click)="controlButtonStatus('inativos')">Inativos</button>
                                </div>
                            </div>
                        </div>
                        <div class="row" style="overflow: auto;">
                            <app-loading-progress-bar *ngIf="isLoading"></app-loading-progress-bar>
                            <table class="col-12">
                                <thead>
                                    <tr>
                                        <th class="column-name">Nome</th>
                                        <th class="column-registration">Número de Registro</th>
                                        <th class="column-phone">Telefone</th>
                                        <th class="column-active">Ativo</th>
                                        <th class="column-action">Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let healthcare of listHealthcareAgreement">
                                        <td>{{ healthcare.nameHealthcareAgreement }}</td>
                                        <td>{{ healthcare.ansRegistrationNumber }}</td>
                                        <td>{{ healthcare.phone }}</td>
                                        <td>
                                            <mat-slide-toggle [(ngModel)]="healthcare.isActive"
                                                (change)="putStatus($event, healthcare.idHealthcareAgreement)"
                                                [labelPosition]="'before'"></mat-slide-toggle>

                                        </td>
                                        <td>
                                            <a class="action-icon">
                                                <mat-icon aria-hidden="false" aria-label="Editar"
                                                    [routerLink]="['/billing/healthcare-agreements-and-plans/register', {idHealthcareAgreement: healthcare.idHealthcareAgreement}]">
                                                    edit
                                                </mat-icon>
                                            </a>

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <app-loading-list *ngIf="isLoading"></app-loading-list>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12">
                                <mat-paginator [length]="fullListSize" #paginator [pageIndex]="pageIndex"
                                    [pageSize]="pageSize" aria-label="Select page" (page)="changePage($event)"
                                    [hidePageSize]="true">
                                </mat-paginator>
                            </div>
                        </div>
                        <app-empty-list
                            *ngIf="listHealthcareAgreement != undefined && listHealthcareAgreement.length == 0 && !this.isLoading"></app-empty-list>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>