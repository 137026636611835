import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { GetStorageReturnResponse } from '../../responses/pharmacy/get-storage-return.response';
import { GetStorageResponse } from '../../responses/pharmacy/get-storage.response';
import { ListStorageResponse } from '../../responses/pharmacy/list-storage.response';

@Injectable({
  providedIn: 'root'
})
export class VerifyStorageService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listMedicineStorage(idMedicine: number): Observable<ListStorageResponse> {
    let uri = `VerifyStorage/idMedicine/${idMedicine}`;

    return this.httpClient.get<ListStorageResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public verifyMedicineStorage(idStorage: number, idMedicine: number): Observable<GetStorageResponse> {
    let uri = `VerifyStorage/idStorage/${idStorage}/idMedicine/${idMedicine}`;

    return this.httpClient.get<GetStorageResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public verifyStorage(idStorage: number): Observable<GetStorageReturnResponse> {
    let uri = `VerifyStorage/idStorage/${idStorage}`;

    return this.httpClient.get<GetStorageReturnResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}