<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <h1>Detalhes</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <form class="form" [formGroup]="model">
                <div class="title-header">
                    <div class="row">
                        <div class="col-6 col-sm-6 col-md-6">
                            <h1>Dados Pessoais</h1>
                        </div>
                        <div class="col-md-3 col-sm-3">
                            <button mat-flat-button color="primary" class=" btn-block"
                                (click)="sendReportModal(idEpisode)" *ngIf="isOnline && this.lastMedicalCare">
                                <span *ngIf="!isLoading">Reenviar documento</span>
                                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                            </button>
                        </div>
                        <div class="col-md-3 col-sm-3">
                            <a mat-flat-button type="button" color="primary" class="btn-block" href="#"
                                (click)="!isLoading ? downloadCompletedReport() : false; false">
                                <span *ngIf="!isLoading">Download Ficha Completa</span>
                                <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                    *ngIf="isLoading == true"></mat-spinner>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-3 col-md-2" *ngIf="this.isUnidentifiedPatient">
                        <mat-form-field appearance="outline">
                            <mat-label>Número Episódio</mat-label>
                            <input matInput type="text" readonly formControlName="idEpisode">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-3 col-md-2" *ngIf="this.isUnidentifiedPatient">
                        <mat-form-field appearance="outline">
                            <mat-label>Senha</mat-label>
                            <input matInput type="text" readonly formControlName="ticket">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3" *ngIf="this.isUnidentifiedPatient">
                        <mat-form-field appearance="outline">
                            <mat-label>Prioridade</mat-label>
                            <mat-select formControlName="listIdPriority" multiple [disabled]="true">
                                <mat-option *ngFor="let item of listOfPriorities" [value]="item.idPriority" disabled>
                                    {{item.priorityName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2" *ngIf="this.isUnidentifiedPatient">
                        <mat-form-field appearance="outline">
                            <mat-label>Data de inclusão</mat-label>
                            <input matInput type="text" readonly formControlName="datetimeInclusion">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6" *ngIf="this.isUnidentifiedPatient">
                        <mat-form-field appearance="outline">
                            <mat-label>Descrição do paciente não identificado</mat-label>
                            <textarea matInput readonly type="text"
                                formControlName="unidentifiedPatientDescription"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="!this.isUnidentifiedPatient">
                    <div class="row">
                        <div class="col-12 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Senha</mat-label>
                                <input matInput type="text" readonly formControlName="ticket">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Número Episódio</mat-label>
                                <input matInput type="text" readonly formControlName="idEpisode">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Nome</mat-label>
                                <input matInput type="text" readonly formControlName="patientName">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Status do Episódio</mat-label>
                                <input matInput type="text" readonly formControlName="statusName">
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Data de Nascimento</mat-label>
                                <input matInput type="text" readonly formControlName="birthDate" [mask]="masks.date">
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline" class="outline-spinner">
                                <mat-label>Idade</mat-label>
                                <input matInput type="text" readonly formControlName="patientAge">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Nome Social</mat-label>
                                <input matInput type="text" readonly formControlName="socialName">
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline" class="outline-spinner">
                                <mat-label>CPF</mat-label>
                                <input matInput type="text" readonly formControlName="cpf" [mask]="masks.cpf">
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline" class="outline-spinner">
                                <mat-label>CNS</mat-label>
                                <input matInput type="text" readonly formControlName="cns">
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>RG</mat-label>
                                <input matInput type="text" readonly formControlName="rg">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Nome da Mãe</mat-label>
                                <input matInput type="text" readonly formControlName="motherName">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Nome do Pai</mat-label>
                                <input matInput type="text" readonly formControlName="fatherName">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline" class="outline-spinner">
                                <mat-label>Gênero</mat-label>
                                <input matInput type="text" readonly formControlName="genderName">
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Raça</mat-label>
                                <input matInput type="text" readonly formControlName="raceName">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Escolaridade</mat-label>
                                <input matInput type="text" readonly formControlName="educationName">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Estado Civil</mat-label>
                                <input matInput type="text" readonly formControlName="maritalStatusName">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Profissão</mat-label>
                                <input matInput type="text" readonly formControlName="profession">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Prioridade</mat-label>
                                <mat-select formControlName="listIdPriority" multiple [disabled]="true">
                                    <mat-option *ngFor="let item of listOfPriorities" [value]="item.idPriority"
                                        disabled>
                                        {{item.priorityName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Nacionalidade</mat-label>
                                <input matInput type="text" readonly formControlName="birthCountry">
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Data de inclusão</mat-label>
                                <input matInput type="text" readonly formControlName="datetimeInclusion">
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Responsável pelo cadastro</mat-label>
                                <input matInput type="text" readonly formControlName="responsibleUserName">
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Email</mat-label>
                                <input matInput type="text" readonly formControlName="email">
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Telefone 1</mat-label>
                                <input matInput type="text" readonly formControlName="phone1">
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Telefone 2</mat-label>
                                <input matInput type="text" readonly formControlName="phone2">
                            </mat-form-field>
                        </div>

                    </div>
                    <div class="row">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12">
                                <app-allergy-tag [disabled]="true" [inputListTags]="allergies" [listTags]="allergiesToSave"
                                    [isError]="allergyError"
                                    *ngIf="!isFirstLoading">
                                </app-allergy-tag>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Comorbidades</mat-label>
                                <textarea matInput readonly formControlName="comorbidities"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-12 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Linha De Cuidado</mat-label>
                                <input matInput type="text" readonly formControlName="careLinePriorityName">
                            </mat-form-field>
                        </div>

                        <div class="col-12 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Cadastrado por</mat-label>
                                <input matInput type="text" readonly formControlName="careLinePriorityUserName">
                            </mat-form-field>
                        </div>

                        <div class="col-12 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Status</mat-label>
                                <input matInput type="text" readonly formControlName="careLinePriorityStatusName">
                            </mat-form-field>
                        </div>

                        <div class="col-12 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Atualizado por</mat-label>
                                <input matInput type="text" readonly formControlName="careLinePriorityStatusUserName">
                            </mat-form-field>
                        </div>

                        <div class="col-12 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Atualizado em</mat-label>
                                <input matInput type="text" readonly formControlName="dateTimeCareLinePriorityStatus">
                            </mat-form-field>
                        </div>

                    </div>
                    <div class="row">

                        <div class="col-12 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Suspeita</mat-label>
                                <input matInput type="text" readonly formControlName="suspicionName">
                            </mat-form-field>
                        </div>

                        <div class="col-12 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Cadastrado por</mat-label>
                                <input matInput type="text" readonly formControlName="suspicionUserName">
                            </mat-form-field>
                        </div>

                        <div class="col-12 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Status</mat-label>
                                <input matInput type="text" readonly formControlName="suspicionStatusName">
                            </mat-form-field>
                        </div>

                        <div class="col-12 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Atualizado por</mat-label>
                                <input matInput type="text" readonly formControlName="userSuspicionEvaluationName">
                            </mat-form-field>
                        </div>

                        <div class="col-12 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Atualizado em</mat-label>
                                <input matInput type="text" readonly formControlName="datetimeSuspicionEvaluation">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Convênio</mat-label>
                                <input matInput type="text" readonly formControlName="nameHealthcareAgreement">
                            </mat-form-field>
                        </div>

                        <div class="col-12 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Plano</mat-label>
                                <input matInput type="text" readonly formControlName="planName">
                            </mat-form-field>
                        </div>

                        <div class="col-12 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Número da carteirinha</mat-label>
                                <input matInput type="text" readonly formControlName="healthcareAgreementCardNumber">
                            </mat-form-field>
                        </div>

                        <div class="col-12 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Data de validade da carteirinha</mat-label>
                                <input matInput type="text" readonly
                                    formControlName="healthcareAgreementExpirationDate">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="title-header">
                        <h1>Endereço</h1>
                    </div>
                    <div class="row">
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline" class="outline-spinner">
                                <mat-label>CEP</mat-label>
                                <input matInput type="text" readonly formControlName="zipCode">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Logradouro</mat-label>
                                <input matInput type="text" readonly formControlName="street">
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Número</mat-label>
                                <input matInput type="number" readonly formControlName="houseNumber">
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Complemento</mat-label>
                                <input matInput type="text" readonly formControlName="apartmentNumber">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Bairro</mat-label>
                                <input matInput type="text" readonly formControlName="neighborhood">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Cidade</mat-label>
                                <input matInput type="text" readonly formControlName="city">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Estado</mat-label>
                                <input matInput type="text" readonly formControlName="state">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>País</mat-label>
                                <input matInput type="text" readonly formControlName="country">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="this.admissionReportName">
                    <div class="col-12 col-sm-6 col-md-2">
                        <button mat-flat-button color="primary" class=" btn-block"
                            (click)="!isLoading ? downloadAdmissionReport() : false">
                            <span *ngIf="!isLoading">Relatório de Admissão</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                        </button>
                    </div>
                </div>

                <div
                    *ngIf="!isLoading && this.listValueMetaDataFrontDeskTriage && this.listValueMetaDataFrontDeskTriage.length > 0">
                    <app-meta-data [listMetaDataLookup]="this.listMetadataLookup"
                        [listValueMetaData]="this.listValueMetaDataFrontDeskTriage" [isReadonly]="true">
                    </app-meta-data>
                </div>
            </form>
            <div *ngIf="patientAvoidanceLogResponse != null && patientAvoidanceLogResponse.patientAvoidanceLog != null">
                <div class="title-header">
                    <h1>Evasão do Paciente no Fluxo</h1>
                </div>
                <cdk-accordion class="profile-register-accordion">
                    <cdk-accordion-item #accordionItemModule="cdkAccordionItem" class="profile-register-accordion-item"
                        role="button" tabindex="0" [attr.id]="'accordion-header-'"
                        [attr.aria-expanded]="accordionItemModule.expanded" [attr.aria-controls]="'accordion-body-'">
                        <div [ngClass]="{'profile-register-accordion-item-header-expanded': accordionItemModule.expanded}"
                            class="profile-register-accordion-item-header" (click)="accordionItemModule.toggle()">
                            ({{patientAvoidanceLogResponse.evasionScreen.screenName}} |
                            {{patientAvoidanceLogResponse.patientAvoidanceLog.datetimeInclusion | date:'dd/MM/yy, H:mm'
                            }})
                            <span
                                [ngClass]="{'profile-register-accordion-item-description-expanded': accordionItemModule.expanded}"
                                class="profile-register-accordion-item-description">
                                Clique para {{ accordionItemModule.expanded ? 'fechar' : 'abrir' }}
                            </span>
                        </div>
                        <div class="profile-register-accordion-item-body triage-body" role="region"
                            [style.display]="accordionItemModule.expanded ? '' : 'none'"
                            [attr.id]="'accordion-body-' + index" [attr.aria-labelledby]="'accordion-header-' + index">
                            <div class="row">
                                <div class="col-12">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Atendimento</mat-label>
                                        <textarea [attr.rows]="this.numberOfLinesInTextPatientExitLog" matInput
                                            readonly>{{patientAvoidanceLogResponse.patientAvoidanceLog.patientExitLog}}</textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </cdk-accordion-item>
                </cdk-accordion>
            </div>
            <div *ngIf="listTriage != null && listTriage.length > 0">
                <div class="title-header">
                    <h1>Histórico de classificações</h1>
                </div>
                <cdk-accordion class="profile-register-accordion">
                    <cdk-accordion-item *ngFor="let item of listTriage; let index = index;"
                        #accordionItemModule="cdkAccordionItem" class="profile-register-accordion-item" role="button"
                        tabindex="0" [attr.id]="'accordion-header-' + index"
                        [attr.aria-expanded]="accordionItemModule.expanded"
                        [attr.aria-controls]="'accordion-body-' + index">
                        <div [ngClass]="{'profile-register-accordion-item-header-expanded': accordionItemModule.expanded}"
                            class="profile-register-accordion-item-header" (click)="accordionItemModule.toggle()">
                            Classificação - ({{ item.userClassificationName }} - {{ item.gravityName }} - {{
                            item.dateTimeInclusion |
                            date:'dd/MM/yy, H:mm' }})
                            <span
                                [ngClass]="{'profile-register-accordion-item-description-expanded': accordionItemModule.expanded}"
                                class="profile-register-accordion-item-description">
                                Clique para {{ accordionItemModule.expanded ? 'fechar' : 'abrir' }}
                            </span>
                        </div>
                        <div class="profile-register-accordion-item-body triage-body" role="region"
                            [style.display]="accordionItemModule.expanded ? '' : 'none'"
                            [attr.id]="'accordion-body-' + index" [attr.aria-labelledby]="'accordion-header-' + index">
                            <app-triage-resume [isHistorical]="true" [triage]="item"></app-triage-resume>
                            <div class="col-12 col-sm-6 col-md-2" *ngIf="item.idGrievanceNotification != null">
                                <button mat-flat-button color="primary" class=" btn-block"
                                    (click)="!isLoading ? getGrievanceNotification(item.idGrievanceNotification) : false">
                                    <span *ngIf="!isLoading">Notificação de agravo</span>
                                    <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                        *ngIf="isLoading"></mat-spinner>
                                </button>
                            </div>
                        </div>
                    </cdk-accordion-item>
                </cdk-accordion>
            </div>
            <div *ngIf="hasGuide">
                <div class="title-header">
                    <h1>Guias TISS</h1>
                </div>
                <div class="row" *ngFor="let item of listAppointmentGuideStruct; let index = index;">
                    <div class="col-12 col-sm-6 col-md-2">
                        <button mat-flat-button color="primary" class=" btn-block"
                            (click)="!isLoading ? OpenAppointmentGuide(index) : false">
                            <span *ngIf="!isLoading">Guia De Consulta</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                        </button>
                    </div>
                </div>
                <div class="row" *ngFor="let item of listFeesGuideStruct; let index = index;">
                    <div class="col-12 col-sm-6 col-md-2">
                        <button mat-flat-button color="primary" class=" btn-block"
                            (click)="!isLoading ? OpenFeesGuide(index) : false">
                            <span *ngIf="!isLoading">Guia De Honorários</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                        </button>
                    </div>
                </div>
                <div class="row" *ngFor="let item of listAdmissionGuideStruct; let index = index;">
                    <div class="col-12 col-sm-6 col-md-2">
                        <button mat-flat-button color="primary" class=" btn-block"
                            (click)="!isLoading ? OpenAdmissionGuide(index) : false">
                            <span *ngIf="!isLoading">Guia De Internação</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                        </button>
                    </div>
                </div>
                <div class="row" *ngFor="let item of listSpSadtGuideStruct; let index = index;">
                    <div class="col-12 col-sm-6 col-md-2">
                        <button mat-flat-button color="primary" class=" btn-block"
                            (click)="!isLoading ? OpenSpSadtGuide(index) : false">
                            <span *ngIf="!isLoading">Guia SP/SADT</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                        </button>
                    </div>
                </div>
            </div>
            <div *ngIf="medicalCare != null  && medicalCare.length > 0 ">
                <div class="title-header">
                    <h1>Histórico de Atendimentos</h1>
                </div>
                <div class="row">
                    <div class="col=12">
                        <cdk-accordion class="profile-register-accordion">
                            <app-medical-care-patient-history [listExamType]="listExamType" [medicalCare]="medicalCare"
                                [lookupMedicalCareResponse]="lookupMedicalCareResponse" [listMedicine]="listMedicine"
                                [listExam]="listLookupExam" [isLoading]="isLoading" [isOnline]="isOnline"
                                [email]="model.get('email').value" [cpf]="model.get('cpf').value">
                            </app-medical-care-patient-history>
                        </cdk-accordion>
                    </div>
                </div>
            </div>
            <div *ngIf="multiProfessionalCare != null  && multiProfessionalCare.length > 0 ">
                <div class="title-header">
                    <h1>Histórico de Atendimentos Multiprofissional</h1>
                </div>
                <div class="row">
                    <div class="col=12">
                        <cdk-accordion class="profile-register-accordion">
                            <app-multi-professional-care-patient-history [listExamType]="listExamType"
                                [multiProfessionalCare]="multiProfessionalCare" [listExam]="listLookupExam"
                                [isLoading]="isLoading">
                            </app-multi-professional-care-patient-history>
                        </cdk-accordion>
                    </div>
                </div>
            </div>
            <div *ngIf="listBedStruct != null && listBedStruct.length > 0">
                <div class="title-header">
                    <h1>Alocação de leito</h1>
                </div>
                <cdk-accordion class="profile-register-accordion">
                    <cdk-accordion-item *ngFor="let item of listBedStruct; let index = index;"
                        #accordionItemModule="cdkAccordionItem" class="profile-register-accordion-item" role="button"
                        tabindex="0" [attr.id]="'accordion-header-' + index"
                        [attr.aria-expanded]="accordionItemModule.expanded"
                        [attr.aria-controls]="'accordion-body-' + index">
                        <div [ngClass]="{'profile-register-accordion-item-header-expanded': accordionItemModule.expanded}"
                            class="profile-register-accordion-item-header" (click)="accordionItemModule.toggle()">
                            Leito - ({{ item.bedName }} - {{
                            item.datetimeInclusion | date:'dd/MM/yy, H:mm' }} - {{
                            item.datetimeOut | date:'dd/MM/yy, H:mm' }})
                            <span
                                [ngClass]="{'profile-register-accordion-item-description-expanded': accordionItemModule.expanded}"
                                class="profile-register-accordion-item-description">
                                Clique para {{ accordionItemModule.expanded ? 'fechar' : 'abrir' }}
                            </span>
                        </div>
                        <div class="profile-register-accordion-item-body triage-body" role="region"
                            [style.display]="accordionItemModule.expanded ? '' : 'none'"
                            [attr.id]="'accordion-body-' + index" [attr.aria-labelledby]="'accordion-header-' + index">
                            <div class="row">
                                <div class="col-6 col-sm-3 col-md-2">
                                    <mat-form-field appearance="outline" class="outline-spinner">
                                        <mat-label>Nome do Leito</mat-label>
                                        <input matInput type="text" readonly [value]="item.bedName">
                                    </mat-form-field>
                                </div>
                                <div class="col-6 col-sm-3 col-md-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Data e hora inicial</mat-label>
                                        <input matInput type="text" readonly
                                            value="{{item.datetimeInclusion | date:'dd/MM/yy, H:mm' }}">
                                    </mat-form-field>
                                </div>
                                <div class="col-12 col-sm-3 col-md-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Data e hora final</mat-label>
                                        <input matInput type="text" readonly
                                            value="{{item.datetimeOut | date:'dd/MM/yy, H:mm' }}">
                                    </mat-form-field>
                                </div>
                                <div class="col-12 col-sm-3 col-md-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Tempo no leito</mat-label>
                                        <input matInput type="text" readonly [value]="item.timeOnBed">
                                    </mat-form-field>
                                </div>
                                <div class="col-12 col-sm-3 col-md-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Tipo do Leito</mat-label>
                                        <input matInput type="text" readonly [value]="item.bedTypeName">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </cdk-accordion-item>
                </cdk-accordion>
            </div>
            <div *ngIf="listNursingConsultation != null && listNursingConsultation.length > 0">
                <div class="title-header">
                    <h1>Condutas de enfermagem</h1>
                </div>
                <cdk-accordion class="profile-register-accordion">
                    <cdk-accordion-item *ngFor="let item of listNursingConsultation; let index = index;"
                        #accordionItemModule="cdkAccordionItem" class="profile-register-accordion-item" role="button"
                        tabindex="0" [attr.id]="'accordion-header-' + index"
                        [attr.aria-expanded]="accordionItemModule.expanded"
                        [attr.aria-controls]="'accordion-body-' + index">
                        <div [ngClass]="{'profile-register-accordion-item-header-expanded': accordionItemModule.expanded}"
                            class="profile-register-accordion-item-header" (click)="accordionItemModule.toggle()">
                            Conduta - ({{ item.userName }} - {{
                            item.datetimeInclusion | date:'dd/MM/yy, H:mm' }})
                            <span
                                [ngClass]="{'profile-register-accordion-item-description-expanded': accordionItemModule.expanded}"
                                class="profile-register-accordion-item-description">
                                Clique para {{ accordionItemModule.expanded ? 'fechar' : 'abrir' }}
                            </span>
                        </div>
                        <div class="profile-register-accordion-item-body" role="region"
                            [style.display]="accordionItemModule.expanded ? '' : 'none'"
                            [attr.id]="'accordion-body-' + index" [attr.aria-labelledby]="'accordion-header-' + index">
                            <app-nursing-history-accordion [listAllergyEpisode]="listAllergyEpisode"
                                [nursingConsultation]="item"></app-nursing-history-accordion>
                        </div>
                    </cdk-accordion-item>
                </cdk-accordion>
                <div class="card-list" *ngIf="listNurseProcedures != null && listNurseProcedures.length > 0">
                    <div class="title-header">
                        <h3>Procedimentos solicitados na observação</h3>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6" *ngFor="let item of listNurseProcedures">
                            <div class="card-item">
                                <div class="infos">
                                    <span class="primary">{{item.procedureName}}</span>
                                    <span class="secundary"><b>Solicitante: </b>{{item.userName}}</span>
                                    <span class="secundary">Observação: {{item.observation}}</span>
                                    <span class="secundary">{{item.dateTimeInclusion | date:'dd/MM/yy H:mm'}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="listSADT != null && listSADT.length > 0">
                <div class="title-header">
                    <h1>Condutas de SADT</h1>
                </div>
                <cdk-accordion class="profile-register-accordion">
                    <cdk-accordion-item *ngFor="let item of listSADT; let index = index;"
                        #accordionItemModule="cdkAccordionItem" class="profile-register-accordion-item" role="button"
                        tabindex="0" [attr.id]="'accordion-header-' + index"
                        [attr.aria-expanded]="accordionItemModule.expanded"
                        [attr.aria-controls]="'accordion-body-' + index">
                        <div [ngClass]="{'profile-register-accordion-item-header-expanded': accordionItemModule.expanded}"
                            class="profile-register-accordion-item-header" (click)="accordionItemModule.toggle()">
                            Conduta - ({{ item.userName }} - {{
                            item.datetimeInclusion | date:'dd/MM/yy, H:mm' }})
                            <span
                                [ngClass]="{'profile-register-accordion-item-description-expanded': accordionItemModule.expanded}"
                                class="profile-register-accordion-item-description">
                                Clique para {{ accordionItemModule.expanded ? 'fechar' : 'abrir' }}
                            </span>
                        </div>
                        <div class="profile-register-accordion-item-body" role="region"
                            [style.display]="accordionItemModule.expanded ? '' : 'none'"
                            [attr.id]="'accordion-body-' + index" [attr.aria-labelledby]="'accordion-header-' + index">
                            <app-sadt-conduct [sadt]="item"></app-sadt-conduct>
                        </div>
                    </cdk-accordion-item>
                </cdk-accordion>
                <div class="card-list" *ngIf="listNurseProcedures != null && listNurseProcedures.length > 0">
                    <div class="title-header">
                        <h3>Procedimentos solicitados na observação</h3>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6" *ngFor="let item of listNurseProcedures">
                            <div class="card-item">
                                <div class="infos">
                                    <span class="primary">{{item.procedureName}}</span>
                                    <span class="secundary"><b>Solicitante: </b>{{item.userName}}</span>
                                    <span class="secundary">Observação: {{item.observation}}</span>
                                    <span class="secundary">{{item.dateTimeInclusion | date:'dd/MM/yy H:mm'}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="listDispensationStruct != null && listDispensationStruct.length > 0">
                <div class="title-header">
                    <h1>Dispensação de Medicamentos Avulsos</h1>
                </div>
                <cdk-accordion class="profile-register-accordion">
                    <cdk-accordion-item *ngFor="let item of listDispensationStruct; let index = index;"
                        #accordionItemModule="cdkAccordionItem" class="profile-register-accordion-item" role="button"
                        tabindex="0" [attr.id]="'accordion-header-' + index"
                        [attr.aria-expanded]="accordionItemModule.expanded"
                        [attr.aria-controls]="'accordion-body-' + index">
                        <div [ngClass]="{'profile-register-accordion-item-header-expanded': accordionItemModule.expanded}"
                            class="profile-register-accordion-item-header" (click)="accordionItemModule.toggle()">
                            Dispensação - ({{ item.releaseUserName }} - {{
                            item.datetimeRelease | date:'dd/MM/yy, H:mm' }})
                            <span
                                [ngClass]="{'profile-register-accordion-item-description-expanded': accordionItemModule.expanded}"
                                class="profile-register-accordion-item-description">
                                Clique para {{ accordionItemModule.expanded ? 'fechar' : 'abrir' }}
                            </span>
                        </div>
                        <div class="profile-register-accordion-item-body" role="region"
                            [style.display]="accordionItemModule.expanded ? '' : 'none'"
                            [attr.id]="'accordion-body-' + index" [attr.aria-labelledby]="'accordion-header-' + index">
                            <app-dispensation-accordion [dispensation]="item"></app-dispensation-accordion>
                        </div>
                    </cdk-accordion-item>
                </cdk-accordion>
            </div>

            <div *ngIf="listSolutionDispensationStruct != null && listSolutionDispensationStruct.length > 0">
                <div class="title-header">
                    <h1>Dispensação de Soluções medicamentosas</h1>
                </div>
                <cdk-accordion class="profile-register-accordion">
                    <cdk-accordion-item *ngFor="let item of listSolutionDispensationStruct; let index = index;"
                        #accordionItemModule="cdkAccordionItem" class="profile-register-accordion-item" role="button"
                        tabindex="0" [attr.id]="'accordion-header-' + index"
                        [attr.aria-expanded]="accordionItemModule.expanded"
                        [attr.aria-controls]="'accordion-body-' + index">
                        <div [ngClass]="{'profile-register-accordion-item-header-expanded': accordionItemModule.expanded}"
                            class="profile-register-accordion-item-header" (click)="accordionItemModule.toggle()">
                            Dispensação - ({{ item.releaseUserName }} - {{
                            item.datetimeRelease | date:'dd/MM/yy, H:mm' }})
                            <span
                                [ngClass]="{'profile-register-accordion-item-description-expanded': accordionItemModule.expanded}"
                                class="profile-register-accordion-item-description">
                                Clique para {{ accordionItemModule.expanded ? 'fechar' : 'abrir' }}
                            </span>
                        </div>
                        <div class="profile-register-accordion-item-body" role="region"
                            [style.display]="accordionItemModule.expanded ? '' : 'none'"
                            [attr.id]="'accordion-body-' + index" [attr.aria-labelledby]="'accordion-header-' + index">
                            <app-solution-dispensation-accordion
                                [solutionDispensation]="item"></app-solution-dispensation-accordion>
                        </div>
                    </cdk-accordion-item>
                </cdk-accordion>
            </div>

            <div *ngIf="listQueue != null && listQueue.length > 0">
                <div class="title-header">
                    <h1>Fluxo do Paciente</h1>
                </div>
                <cdk-accordion class="profile-register-accordion">
                    <cdk-accordion-item *ngFor="let item of listQueue; let index = index;"
                        #accordionItemModule="cdkAccordionItem" class="profile-register-accordion-item" role="button"
                        tabindex="0" [attr.id]="'accordion-header-' + index"
                        [attr.aria-expanded]="accordionItemModule.expanded"
                        [attr.aria-controls]="'accordion-body-' + index">
                        <div [ngClass]="{'profile-register-accordion-item-header-expanded': accordionItemModule.expanded}"
                            class="profile-register-accordion-item-header" (click)="accordionItemModule.toggle()">
                            ({{ item.idTotemButton ? "Porta de entrada - " + item.locationName : item.locationName }} -
                            {{
                            item.datetimeInclusion | date:'dd/MM/yy, H:mm' }})
                            <span
                                [ngClass]="{'profile-register-accordion-item-description-expanded': accordionItemModule.expanded}"
                                class="profile-register-accordion-item-description">
                                Clique para {{ accordionItemModule.expanded ? 'fechar' : 'abrir' }}
                            </span>
                        </div>
                        <div class="profile-register-accordion-item-body triage-body" role="region"
                            [style.display]="accordionItemModule.expanded ? '' : 'none'"
                            [attr.id]="'accordion-body-' + index" [attr.aria-labelledby]="'accordion-header-' + index">
                            <div class="row">
                                <div class="col-6 col-sm-3 col-md-2">
                                    <mat-form-field appearance="outline" class="outline-spinner">
                                        <mat-label>Nome do Fluxo</mat-label>
                                        <input matInput type="text" readonly [value]="item.flowName">
                                    </mat-form-field>
                                </div>
                                <div class="col-6 col-sm-3 col-md-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Nome da Localização</mat-label>
                                        <input matInput type="text" readonly [value]="item.locationName">
                                    </mat-form-field>
                                </div>
                                <div class="col-6 col-sm-3 col-md-2">
                                    <mat-form-field appearance="outline" [ngClass]="{'without-hint': item.idStatusQueue == idStatusQueueEvaded && item.evasionUserName == null && item.conclusionNote != null}">
                                        <mat-label>Status</mat-label>
                                        <input matInput type="text" readonly [value]="item.statusQueueName">
                                        <mat-hint
                                            *ngIf="item.evasionUserName != null && item.evasionUserName != ''">Por:
                                            {{item.evasionUserName}}</mat-hint>
                                    </mat-form-field>
                                    <p class="conclusion-note"
                                        *ngIf="item.idStatusQueue == idStatusQueueEvaded && item.evasionUserName == null && item.conclusionNote != null">{{item.conclusionNote}}</p>
                                </div>
                                <div class="col-6 col-sm-3 col-md-4"
                                    *ngIf="item.idStatusQueue == idStatusQueueEvaded && item.evasionNotes != null">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Motivo da evasão</mat-label>
                                        <textarea rows="3" matInput type="text" readonly
                                            [value]="item.evasionNotes"></textarea>
                                    </mat-form-field>
                                </div>
                                <div class="col-6 col-sm-3 col-md-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{ item.idTotemButton ? "Data e hora retirada da senha" : "Data e
                                            hora
                                            entrada" }} </mat-label>
                                        <input matInput type="text" readonly
                                            value="{{item.datetimeInclusion | date:'dd/MM/yy, H:mm' }}">
                                    </mat-form-field>
                                </div>
                                <div class="col-12 col-sm-3 col-md-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Data e hora final</mat-label>
                                        <input matInput type="text" readonly
                                            value="{{item.datetimeOut | date:'dd/MM/yy, H:mm' }}">
                                    </mat-form-field>
                                </div>
                                <div class="col-12 col-sm-3 col-md-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Tempo de espera</mat-label>
                                        <input matInput type="text" readonly [value]="item.watingTime">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </cdk-accordion-item>
                </cdk-accordion>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-3">
                    <button mat-flat-button type="button" routerLink="/patient-history/list" color="accent"
                        class=" btn-block">
                        <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Voltar
                    </button>
                </div>
                <div *ngIf="showReturnPatientBtn && canReturnToQueue" class="col-12 col-sm-6 col-md-3">
                    <button mat-flat-button type="button" color="accent" class=" btn-block" (click)="openMotiveModal()">
                        <span *ngIf="!isLoading">Retornar paciente para fila</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                    </button>
                </div>
            </div>
        </div>
    </div>

</div>