import { AgeRangeModel } from "../../models/sync-measurer/age-range.model";

export class AgeRangeStruct extends AgeRangeModel {
    public minAge: DeserializedAge;
    public maxAge: DeserializedAge;
}

export class DeserializedAge {
    public years: number;
    public months: number;
    public days: number;
}