import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { EndNpsTotemModalComponent } from './end-nps-totem-modal/end-nps-totem-modal.component';
import { TotemNpsNotes } from 'src/app/shared/services/models/nps/totem-nps-notes.model';
import { ClientTotemNpsNotesService } from 'src/app/shared/services/API/nps/client-totem-nps-notes.service';
@Component({
  selector: 'app-voting-nps-totem',
  templateUrl: './voting-nps-totem.component.html',
  styleUrls: ['./voting-nps-totem.component.css']
})
export class VotingNpsTotemComponent implements OnInit {

  @Input() idTotem: number;
  @Input() idHealthUnit: number;
  @Input() description: string;
  @Input() cpf: string;

  @Output() closeEndModal: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private alertService: AlertService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private router: Router,
    private clientTotemNpsNotesService: ClientTotemNpsNotesService
  ) { }

  public isLoading: boolean;
  public model: FormGroup;
  public arrayIndexNps = new Array(11);
  public totemNpsNotesRequest: TotemNpsNotes;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      nps: [],
    });
  }

  saveNote(note: number) {
    this.totemNpsNotesRequest = new TotemNpsNotes();
    this.totemNpsNotesRequest.idHealthUnit = this.idHealthUnit;
    if(this.cpf) this.totemNpsNotesRequest.cpf = this.cpf;
    this.totemNpsNotesRequest.note = note;

    this.clientTotemNpsNotesService.postNote(this.totemNpsNotesRequest).subscribe({
      next: (response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;
    },
      error:(error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
    this.openEndNpsTotem();
  }

  openEndNpsTotem() {
    const dialogRef = this.dialog.open(EndNpsTotemModalComponent, {
      data: {},
      panelClass: "totem-nps-modal"
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        this.dialog.closeAll();
        this.closeEndModal.emit(true);
      }
    });
  }
}