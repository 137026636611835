import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CalendarEvent, CalendarEventAction } from 'angular-calendar';
import { CalendarSchedulerEvent, CalendarSchedulerEventAction } from 'angular-calendar-scheduler';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ViewScheduledMedicalCareModalComponent } from './view-scheduled-medical-care-modal/view-scheduled-medical-care-modal.component';
import { addMonths, addYears, endOfDay } from 'date-fns';
import { PatientAppointmentService } from 'src/app/shared/services/API/schedule/patient-appointment.service';
import { PatientAppointment } from 'src/app/shared/services/models/schedule/patient-appointment.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UserRoleEnum } from 'src/app/shared/enum/user-role.enum';

@Component({
  selector: 'app-medical-schedule',
  templateUrl: './medical-schedule.component.html',
  styleUrls: ['./medical-schedule.component.css']
})
export class MedicalScheduleComponent implements OnInit {
  
  constructor(
    private alertService: AlertService,
    public dialog: MatDialog,
    private patientAppointmentService: PatientAppointmentService,
    private authService: AuthService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.medic;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.medical_schedule;

  public isFirstLoading: boolean; 
  public isLoading: boolean; 
  public events: CalendarSchedulerEvent[] = []; 
  public viewDate: Date = new Date(); 
  public hasPermissionToCancelAppointment: boolean;

  public idDayOfTimeStart: number;
  public dateTimeStart: Date = new Date();
  public dateTimeEnd: Date = endOfDay(addYears(new Date(), 1));
  public viewCalendar: boolean = false;

  public listPatientAppointment: PatientAppointment[] = [];

  ngOnInit(): void {
    this.isFirstLoading = true;
    this.isLoading = false;
    this.verifyHasPermissionToCancelAppointment();
    this.searchPatientAppointment();
  }

  verifyHasPermissionToCancelAppointment(){
    this.hasPermissionToCancelAppointment = this.authService.haveRolePermission(UserRoleEnum.cancelar_agendamento);    
  }

  searchPatientAppointment() {
    this.isLoading = true;
    this.patientAppointmentService.getPatientAppointmentByIdUser().subscribe({
      next: (response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.isLoading = false;
      if(response.listPatientAppointment && response.listPatientAppointment.length > 0){
        this.listPatientAppointment = response.listPatientAppointment;
        this.newMedicalScheduleEvents();
      } else {
        this.viewCalendar = true;
        this.isFirstLoading = false;
      }
    },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  newMedicalScheduleEvents() {
    let listEvents: CalendarSchedulerEvent[] = [];
    this.listPatientAppointment.forEach((patientAppointment, index) =>{
      
      let calendarEvent = <CalendarSchedulerEvent>{
        id: index.toString(),
        start: new Date(patientAppointment.datetimeStart),
        end: new Date(patientAppointment.datetimeEnd),
        title: '',
        content: 'Horário agendado',
        color: { 
          primary: '#85C443', 
          secondary: '#85C443',
          secondaryText: "#FFFFFF"
        },
        isClickable: true,
        isDisabled: false,
        draggable: false,
      }

      listEvents.push(calendarEvent);
    });
    this.events = listEvents;
    this.viewCalendar = true;
    this.isFirstLoading = false;
  }

  clicked(event): void {
    this.isLoading = true;
    const dialogRef = this.dialog.open(ViewScheduledMedicalCareModalComponent, {
      data: {
        patientAppointment: this.listPatientAppointment[parseInt(event.id)],
        hasPermissionToCancelAppointment: this.hasPermissionToCancelAppointment
        
      },
      panelClass: "view-scheduled-medical-care-modal"
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.cancelAppointment){
        this.alertService.show('Sucesso', "Consulta cancelada com sucesso!", AlertType.success);
        this.searchPatientAppointment();
      }

      this.isLoading = false;
    });
  }
}
