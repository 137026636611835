import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HealthPlan } from 'src/app/shared/services/models/telemedicine/health-plan.model';

@Component({
  selector: 'app-health-plan-list',
  templateUrl: './health-plan-list.component.html',
  styleUrls: ['./health-plan-list.component.css']
})
export class HealthPlanListComponent implements OnInit {

  @Input() formGroup: UntypedFormGroup;
  @Input() formArrayName: string;
  @Input() listHealthPlans: HealthPlan[];

  constructor(
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
  }

  createInput() {
    return this.formBuilder.group({
      healthPlanName: ['', [Validators.required]],
    })
  }

  addNext() {
    (this.formGroup.controls['listHealthPlans'] as UntypedFormArray).push(this.createInput())
  }

  removeButton(index: number) {
    if (index != 0) {
      (this.formGroup.controls['listHealthPlans'] as UntypedFormArray).removeAt(index)
    }
  }
}
