import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { PutAuditCardUpdateControlRequest } from '../../requests/orchestrator-audit/put-audit-card-update-control.request';

@Injectable({
  providedIn: 'root'
})
export class AuditCardUpdateControlService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  public updateAuditStatus(request: PutAuditCardUpdateControlRequest): Observable<ReturnStruct> {
    let uri = "AuditCardUpdateControl";

    return this.httpClient.put<ReturnStruct>(environment.urlApiOrchestratorAudit + uri, request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}