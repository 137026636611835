<div mat-dialog-content>
    <div class="mat-dialog-content">
        <app-loading-list *ngIf="isFirstLoading"></app-loading-list>
        <div id="memed-prescription"></div>
    </div>
</div>
<div class="footer-flow-modal-list">
    <div class="col-11 col-md-5">
        <button mat-flat-button color="accent" class="btn-block" (click)="close()">Cancelar</button>
    </div>
</div>