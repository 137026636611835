<div *ngIf="!onlineOffline || socketDisconneted" class="offline">
    <span>
        <mat-icon class="offline--icon">wifi_off</mat-icon>
        {{
        socketDisconneted?
        "A função de atualização automática está fora do ar.":
        "Você esta sem conexão com a internet no momento."
        }}
    </span>
</div>
<div class="content" [ngClass]="{'content-padding-top': !onlineOffline || socketDisconneted}">
    <div class="main-call">
        <span>
            {{mainClient?.namePacient}}
        </span>
        <div class="main-call__password">
            <span class="padding-bottom-1">
                Senha
            </span>
            <span>
                {{mainClient?.ticket}}
            </span>
        </div>
        <span class="bold">
            {{ mainClient?.nameSector}}
            <br>
            {{ mainClient?.nameRoom}}
        </span>
    </div>
    <div>
        <div *ngFor="let client of historicalList; let i=index">
            <div [ngClass]="{'history-call': i < 3}">
                <div class="history-content">
                    <div class="client-info">
                        <span class="client-info__name bold text-info-color">
                            {{client.namePacient}}
                        </span>
                        <div>
                            <span class="password-label text-info-color">
                                Senha
                            </span>
                            <span class="bold text-info-color">
                                {{client.ticket}}
                            </span>
                        </div>
                    </div>
                    <span class="sector-info bold">
                        {{ client?.nameSector + ' ' + client?.nameRoom}}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
<footer class="col-12">
    <img *ngIf="this.logoString64" class="brand" [src]="this.sanitezedLogo">
    <img class="brand col-1" src="assets/images/logo-branco.png">
    <span *ngIf="showAverageWaitingTime" class="col-6 personalized-waiting-hours"><span class="vertical-line"></span>
        MÉDIA DE ESPERA - TRIAGEM: {{lastHourWaitingTimeAverage}} <span class="text-last-hour">(última
            hora)</span></span>
    <div class="info col-4">
        <span class="date-time">
            <mat-icon>calendar_today</mat-icon>{{date}}
        </span>
        <span class="date-time">
            <mat-icon>schedule</mat-icon>{{time}}
        </span>
    </div>
</footer>