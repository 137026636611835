import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { ReportModalComponent } from 'src/app/shared/components/report-modal/report-modal.component';
import { MedicineTagEnum } from 'src/app/shared/enum/medicine-tag.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { TagPrintConfigService } from 'src/app/shared/services/API/pharmacy/tag-print-config.service';
import { RelationPatientTagReportService } from 'src/app/shared/services/API/report/relation-patient-tag-report.service';
import { MedicineTag } from 'src/app/shared/services/models/pharmacy/medicine-tag.model';
import { TagPrintConfigRequest } from 'src/app/shared/services/requests/pharmacy/tag-dimension.request';
import { RelationPatientTagReportRequest } from 'src/app/shared/services/requests/Report/relation-patient-tag-report.request';
import { RelationPatientStruct } from 'src/app/shared/services/structs/pharmacy/relation-patient.struct';

@Component({
  selector: 'app-pharmacy-config',
  templateUrl: './pharmacy-config.component.html',
  styleUrls: ['./pharmacy-config.component.css']
})
export class PharmacyConfigComponent implements OnInit {

  constructor(private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private tagPrintConfigService: TagPrintConfigService,
    private relationPatientTagReportService: RelationPatientTagReportService,
    public dialog: MatDialog) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.pharmacy;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.pharmacy_prescription;
  public isLoading: boolean = true;
  public model: UntypedFormGroup;
  public listTags: MedicineTag[];
  public isTagActive: boolean;
  public relationPatientTagReportRequest: RelationPatientTagReportRequest;

  ngOnInit(): void {
    this.isLoading = false;

    this.model = this.formBuilder.group({
      idMedicineTag: [{ value: '' }],
      tagWidth: [{ value: '' }],
      tagHeight: [{ value: '' }],
      isActive: [{ value: this.isTagActive }],
      startOfMedicineCodeAutoIncrement: [''],
      startOfItemCodeAutoIncrement: ['']
    });

    this.populateDimensionSelection();
  }

  populateDimensionSelection() {
    if (this.isLoading)
      return;

    this.isLoading = true;

    this.tagPrintConfigService.listTagDimensions().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        
        this.listTags = response.listTags && response.listTags.length > 0 ? [...response.listTags] : [];
        this.isTagActive = response.isActive;

        this.model.get('isActive').setValue(this.isTagActive);
        this.model.get('idMedicineTag').setValue(response.idSelectedDimension);
        this.model.get('startOfMedicineCodeAutoIncrement').setValue(response.pharmacyConfig?.startOfMedicineCodeAutoIncrement);
        this.model.get('startOfItemCodeAutoIncrement').setValue(response.pharmacyConfig?.startOfItemCodeAutoIncrement);

        this.changeDimensionSelect(response.idSelectedDimension);

        if (!this.isTagActive) {
          this.model.get('idMedicineTag').disable();
          this.model.get('tagWidth').disable();
          this.model.get('tagHeight').disable();
        }
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  toggleTag() {
    this.isTagActive = !this.isTagActive;
    this.model.get('isActive').setValue(this.isTagActive);

    if (!this.isTagActive) {
      this.model.get('idMedicineTag').disable();
      this.model.get('tagWidth').disable();
      this.model.get('tagHeight').disable();
    }
    else {
      this.model.get('idMedicineTag').enable();
      let idMedicineTag: number = this.model.get('idMedicineTag').value;
      this.changeDimensionSelect(idMedicineTag);
    }
  }

  updateConfig(dimensionRequest: TagPrintConfigRequest) {
    if (this.isLoading)
      return;

    this.isLoading = true;

    this.tagPrintConfigService.updateConfig(dimensionRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.alertService.show('Sucesso', 'Configurações salvas com sucesso!', AlertType.success);
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  changeDimensionSelect(idMedicineTag: number) {
    this.model.get('tagWidth').setValue(this.listTags.find(tag => tag.idMedicineTag == idMedicineTag).width);
    this.model.get('tagHeight').setValue(this.listTags.find(tag => tag.idMedicineTag == idMedicineTag).height);

    if (idMedicineTag != MedicineTagEnum.Outro) {
      this.model.get('tagWidth').disable();
      this.model.get('tagHeight').disable();
    }
    else {
      this.model.get('tagWidth').enable();
      this.model.get('tagHeight').enable();
    }
  }

  visualizeTemplate() {
    if (this.isLoading)
      return;

    this.isLoading = true;

    if (!this.model.get('tagHeight').value || !this.model.get('tagWidth').value) {
      this.alertService.show('Erro', "Preencha a altura e largura para visualizar a pulseira.", AlertType.error);
      this.isLoading = false;
      return;
    }

    let relationPatientTagReportRequest: RelationPatientTagReportRequest = new RelationPatientTagReportRequest();
    let relationPatient: RelationPatientStruct = new RelationPatientStruct();

    relationPatientTagReportRequest.height = this.model.get('tagHeight').value;
    relationPatientTagReportRequest.width = this.model.get('tagWidth').value;
    relationPatient.patientName = "Nome do Paciente";
    relationPatient.birthDate = "1997-08-07";
    relationPatient.motherName = "Mãe do Paciente"
    relationPatient.dateTimeRelease = "2023-01-01";
    relationPatient.medicineName = "Nome do Medicamento"
    relationPatient.quantity = 2;
    relationPatientTagReportRequest.relationPatient = relationPatient;

    this.relationPatientTagReportService.getTags(relationPatientTagReportRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        let reportDialog = this.dialog.open(ReportModalComponent, {
          data: {
            reportName: response.reportName,
            reportPdf64: response.reportPdf64,
          },
        });

        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  submit() {
    if (this.isLoading)
      return;

    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      this.isLoading = false;
      return;
    }

    let tagDimensionRequest: TagPrintConfigRequest = new TagPrintConfigRequest();

    tagDimensionRequest.height = this.model.get('tagHeight').value;
    tagDimensionRequest.width = this.model.get('tagWidth').value;
    tagDimensionRequest.isActive = this.model.get('isActive').value;
    tagDimensionRequest.pharmacyConfig.startOfMedicineCodeAutoIncrement = this.model.get('startOfMedicineCodeAutoIncrement').value;
    tagDimensionRequest.pharmacyConfig.startOfItemCodeAutoIncrement = this.model.get('startOfItemCodeAutoIncrement').value;

    let idMedicineTag = this.model.get('idMedicineTag').value;

    if (idMedicineTag)
      tagDimensionRequest.idMedicineTag = idMedicineTag;
    else
      tagDimensionRequest.idMedicineTag = MedicineTagEnum.Outro

    this.updateConfig(tagDimensionRequest);
  }
}