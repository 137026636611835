import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-room-duplicated',
  templateUrl: './room-duplicated.component.html',
  styleUrls: ['./room-duplicated.component.css']
})
export class RoomDuplicatedComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<RoomDuplicatedComponent>,
  ) { this.matDialogRef.disableClose = true; }

  public seconds: any = 15;
  public interval: any;

  ngOnInit(): void {
    this.startTime();
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  startTime() {
    this.interval = setInterval(myMethod, 1000);
    var seconds = this.seconds;
    var $this = this;
    function myMethod() {
      if (seconds > 0)
        seconds = seconds - 1;

      if (seconds == 0) {
        clearInterval($this.interval);
        $this.matDialogRef.close({ continueConnected: false });
      }
    }
  }

  clickConfirm() {
    this.matDialogRef.close({ continueConnected: true });
  }

  clickCancel() {
    this.matDialogRef.close({ continueConnected: false });
  }
}