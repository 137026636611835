import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ClassificationData, HealthGuidelineTriageData, UtilsClassificationService } from '../../utils.service';
import { LookupRiskService } from 'src/app/shared/services/API/risk-classification/lookup-risk.service';
import { DiscriminatorTypeEnum } from 'src/app/shared/enum/discriminator-type.enum';
import { TriageRequest } from 'src/app/shared/services/requests/orchestrator-patient/triage.request';
import { ReportModalComponent } from 'src/app/shared/components/report-modal/report-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { FlowSequenceModalComponent } from 'src/app/shared/components/flow-sequence-modal/flow-sequence-modal.component';
import { FlowStruct } from 'src/app/shared/services/structs/flow/flow.struct';
import { AllergyStruct } from 'src/app/shared/services/structs/medical-record/allergy.struct';
import { CareLinePriorityStatusEnum } from 'src/app/shared/enum/care-line-priority/care-line-priority-status.enum';
import { DeviceTypeSectorResponse } from 'src/app/shared/services/responses/risk-classification/device-type-sector.response';
import { PreRegisterFormDefaultComponent } from '../pre-register/pre-register-form-default/pre-register-form-default.component';
import { UtilsMeasurerService } from '../flowchart/protocol-v2/utils-measurer.service';
import { WebsocketAttendanceUtilService } from 'src/app/shared/services/websocket-util/websocket-attendance-util.service';
import { SignatureCredentialsModalComponent } from 'src/app/shared/components/signature-credentials-modal/signature-credentials-modal.component';
import { UserRoleEnum } from 'src/app/shared/enum/user-role.enum';
import { UserLoginResponse } from 'src/app/shared/services/responses/user/auth.response';
import { UtilService } from 'src/app/shared/services/util.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AttendanceOccupiedRemoveModalComponent } from 'src/app/shared/components/attendance-occupied-remove-modal/attendance-occupied-remove-modal.component';
import { DiscriminadorHeartRateSleepy, DiscriminadorHeartRegular, DiscriminadorSaturationAtmosphericAir } from 'src/app/shared/enum/risk-classification/discriminators.enum';
import { TriageService } from 'src/app/shared/services/API/orchestrator-patient/risk-classification.service';

@Component({
  selector: 'app-triage-result',
  templateUrl: './triage-result.component.html',
  styleUrls: ['./triage-result.component.css']
})
export class TriageResultComponent implements OnInit, OnDestroy {

  constructor(
    private alertService: AlertService,
    private utilClassificationService: UtilsClassificationService,
    private utilService: UtilService,
    public router: Router,
    public lookupService: LookupRiskService,
    public dialog: MatDialog,
    private triageService: TriageService,
    public measurerService: UtilsMeasurerService,
    private websocketAttendanceUtilService: WebsocketAttendanceUtilService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
  ) { }

  ngOnDestroy(): void {
    this.measurerService.forceStop();
  }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.classification;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.classification_pre_registration;
  public isFirstLoading: boolean = false;
  public idEpisode: number;
  public idRoom: number;
  public idQueue: number = null;
  public data: ClassificationData;
  public healthGuidelineTriageData: HealthGuidelineTriageData;
  public isLoading: boolean = false;
  public discriminators: any[] = [];
  public allergies: string;
  public triage: TriageRequest = new TriageRequest();
  public specialNecessityName: string = "";
  public pain: number;
  public painType: number;
  public protocolName: string;
  public painColorCode: string[] = [];
  public isFlowSequence: boolean = false;
  public disableShowReport: boolean = false;
  public discriminatorValues: any[] = [];
  public showBtnBack: boolean = true;
  public edited: boolean = false;
  public requireSignature: boolean;
  public userLogin: string;
  public userName: string;
  public isAttendanceRemoval: boolean = false;

  @ViewChild('preRegister') preRegister: PreRegisterFormDefaultComponent;

  ngOnInit(): void {
    //verifica se a tela de dados complementares pode ser aberta
    this.userLogin = this.authService.getTokenMenu().login;
    this.userName = this.authService.getTokenMenu().userName;
    let deviceTypeSectorResponse: DeviceTypeSectorResponse = this.utilClassificationService.getDevicetypeBySector()
    if (deviceTypeSectorResponse.deviceTypeSector.disableScreenSupplementaryData === true) {
      this.showBtnBack = false;
    }
    this.data = this.utilClassificationService.getClassificationData();
    this.healthGuidelineTriageData = this.utilClassificationService.gethealtHGuidelineTriageData();

    this.disableShowReport = this.utilClassificationService.getDisableShowReport();
    this.painColorCode[0] = this.data.painColorCode;
    this.discriminatorValues = this.data.discriminatorValues && this.data.discriminatorValues.length > 0 ? [...this.data.discriminatorValues] : [];
    this.discriminatorValues[DiscriminatorTypeEnum.Temperatura] = this.discriminatorValues[DiscriminatorTypeEnum.Temperatura] ? parseFloat(this.discriminatorValues[DiscriminatorTypeEnum.Temperatura]) : this.data.temperatureComplementaryData;
    this.discriminatorValues[DiscriminatorTypeEnum.FrequenciaRespiratoria] = this.discriminatorValues[DiscriminatorTypeEnum.FrequenciaRespiratoria] ? parseInt(this.discriminatorValues[DiscriminatorTypeEnum.FrequenciaRespiratoria]) : this.data.respiratoryFrequencyComplementaryData;
    this.discriminatorValues[DiscriminatorTypeEnum.FrequenciaCardiaca] = this.discriminatorValues[DiscriminatorTypeEnum.FrequenciaCardiaca] ? parseInt(this.discriminatorValues[DiscriminatorTypeEnum.FrequenciaCardiaca]) : this.data.heartRateComplementaryData;
    this.discriminatorValues[DiscriminatorTypeEnum.Glicemia] = this.discriminatorValues[DiscriminatorTypeEnum.Glicemia] ? parseInt(this.discriminatorValues[DiscriminatorTypeEnum.Glicemia]) : this.data.glucoseComplementaryData;
    this.discriminatorValues[DiscriminatorTypeEnum.PressaoArterial] = (this.discriminatorValues[DiscriminatorTypeEnum.PressaoArterial] ?
      this.discriminatorValues[DiscriminatorTypeEnum.PressaoArterial].toString() :
      (this.data.bloodPressureSystoleComplementaryData && this.data.bloodPressureDiastoleComplementaryData ?
        this.data.bloodPressureSystoleComplementaryData + "/" + this.data.bloodPressureDiastoleComplementaryData : null));
    this.discriminatorValues[DiscriminatorTypeEnum.Saturacao] = this.discriminatorValues[DiscriminatorTypeEnum.Saturacao] ? parseInt(this.discriminatorValues[DiscriminatorTypeEnum.Saturacao]) : this.data.saturationComplementaryData;

    this.idEpisode = this.data.idEpisode;
    this.idRoom = parseInt(this.activatedRoute.snapshot.paramMap.get('idRoom'));
    this.idQueue = parseInt(this.activatedRoute.snapshot.paramMap.get('idQueue'));

    if (Number.isInteger(this.idQueue) && this.idQueue && this.idQueue > 0 && Number.isInteger(this.idEpisode) && this.idEpisode && this.idEpisode > 0 && this.data && this.data.isTelephoneClassification != true)
      this.startWebsocket();

    let allergyString: string = "";
    let allergiesToShow: AllergyStruct[] = this.data.listAllergy.filter(x => !x.isDeleted);
    allergiesToShow.forEach((element, index) => {
      if (!element.isDeleted) {
        if (index == allergiesToShow.length - 1)
          allergyString += element.allergyName;
        else
          allergyString += element.allergyName + ", ";
      }
    });
    this.allergies = allergyString;
    this.isFlowSequence = (this.data.flowSequence && this.data.flowSequence.length > 0);
    this.mapValues();
    this.specialNecessityName = this.data.specialNecessityName;

    let token: UserLoginResponse = this.utilService.getToken();
    this.requireSignature = token.listRole.map(x => x.idRole).includes(UserRoleEnum.requerer_assinatura_classificacao) && !token.isMaster;
  }

  submit(sign: boolean) {
    if (this.isLoading)
      return;

    if (this.triage.bloodPressureDiastole > this.triage.bloodPressureSystole) {
      this.alertService.show('Erro', "A pressão diastólica não pode ser maior que a sistólica!", AlertType.error);
      return;
    }

    if (sign) {
      const dialogRef = this.dialog.open(SignatureCredentialsModalComponent, {
        disableClose: this.requireSignature,
      });

      dialogRef.afterClosed().subscribe({
        next: (result) => {
          if (!result) {
            this.isLoading = false;
            return;
          }
          if (result.sign) {
            this.triage.idSignatureProvider = result.idSignatureProvider;
            this.triage.signatureAuthentication = JSON.stringify(result.signatureAuthentication);
          }
          else {
            this.triage.idSignatureProvider = null;
            this.triage.signatureAuthentication = null;
          }
          this.postTriage();
        }
      });
    }
    else {
      this.triage.idSignatureProvider = null;
      this.triage.signatureAuthentication = null;
      this.postTriage();
    }
  }

  postTriage() {
    this.isLoading = true;
    if (this.edited)
      this.preRegister.submit();
    this.triageService.post(this.triage).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (Number.isInteger(this.idQueue) && this.idQueue && this.idQueue > 0 && Number.isInteger(this.idEpisode) && this.idEpisode && this.idEpisode > 0 && this.websocketAttendanceUtilService.isConnect() && this.data && this.data.isTelephoneClassification != true)
          this.websocketAttendanceUtilService.emitFinalizeAttendance(this.data.idRoom);

        if (this.isFlowSequence)
          this.openFlowSequenceModal(this.triage.idEpisode, this.triage.idQueue, this.data.idRoom, this.data.flowSequence, true);

        if (!this.disableShowReport) {
          let reportDialog = this.dialog.open(ReportModalComponent, {
            data: {
              reportName: response.reportName,
              reportPdf64: response.reportPdf64,
            },
          });
          reportDialog.afterClosed().subscribe({
            next: result => {
              if (response.isActive) {
                let reportDialog = this.dialog.open(ReportModalComponent, {
                  data: {
                    reportName: response.wristbadReportName,
                    reportPdf64: response.wristbandReportPdf64,
                  },
                });
                reportDialog.afterClosed().subscribe({
                  next: result => {
                    if (response.grievanceNotificationReportPdf64 != null) {
                      let reportDialogGrievance = this.dialog.open(ReportModalComponent, {
                        data: {
                          reportName: response.grievanceNotificationReportName,
                          reportPdf64: response.grievanceNotificationReportPdf64,
                        },
                      });
                      reportDialogGrievance.afterClosed().subscribe({
                        next: result => {
                          this.utilClassificationService.removeClassificationData();
                          this.router.navigate(['/classification/classification-patient-list', { idRoom: this.data.idRoom, idSector: this.data.idSector }]);
                        },
                        error: (error) => {
                          console.log(error);
                        }
                      });
                    }
                    else {
                      this.utilClassificationService.removeClassificationData();
                      this.router.navigate(['/classification/classification-patient-list', { idRoom: this.data.idRoom, idSector: this.data.idSector }]);
                    }
                  },
                  error: (error) => {
                    console.log(error);
                  }
                });
              }
              else {
                if (response.grievanceNotificationReportPdf64 != null) {
                  let reportDialogGrievance = this.dialog.open(ReportModalComponent, {
                    data: {
                      reportName: response.grievanceNotificationReportName,
                      reportPdf64: response.grievanceNotificationReportPdf64,
                    },
                  });
                  reportDialogGrievance.afterClosed().subscribe({
                    next: result => {
                      this.utilClassificationService.removeClassificationData();
                      this.alertService.show('Sucesso', 'Triagem salva com sucesso', AlertType.success);
                      this.router.navigate(['/classification/classification-patient-list', { idRoom: this.data.idRoom, idSector: this.data.idSector }]);
                    },
                    error: (error) => {
                      console.log(error);
                    }
                  });
                }
                else {
                  this.utilClassificationService.removeClassificationData();
                  this.alertService.show('Sucesso', 'Triagem salva com sucesso', AlertType.success);
                  this.router.navigate(['/classification/classification-patient-list', { idRoom: this.data.idRoom, idSector: this.data.idSector }]);
                }
              }
            },
            error: (error) => {
              console.log(error);
            }
          });
        }
        else if (this.disableShowReport && response.grievanceNotificationReportPdf64 != null) {
          let reportDialogGrievance = this.dialog.open(ReportModalComponent, {
            data: {
              reportName: response.grievanceNotificationReportName,
              reportPdf64: response.grievanceNotificationReportPdf64,
            },
          });
          reportDialogGrievance.afterClosed().subscribe({
            next: result => {
              this.utilClassificationService.removeClassificationData();
              this.alertService.show('Sucesso', 'Triagem salva com sucesso', AlertType.success);
              this.router.navigate(['/classification/classification-patient-list', { idRoom: this.data.idRoom, idSector: this.data.idSector }]);
            },
            error: (error) => {
              console.log(error);
            }
          });
        }

        else {
          this.alertService.show('Sucesso', 'Triagem salva com sucesso', AlertType.success);
          this.router.navigate(['/classification/classification-patient-list', { idRoom: this.data.idRoom, idSector: this.data.idSector }]);
        }
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro Inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      }
    });
  }

  openFlowSequenceModal(idEpisode: number, idQueue: number, idRoom: number, flowSequence: FlowStruct[], readOnly: boolean = false) {
    const dialogRef = this.dialog.open(FlowSequenceModalComponent, {
      data: {
        idEpisode: idEpisode,
        idQueue: idQueue,
        flowSequence: flowSequence,
        idOriginRoom: idRoom,
        readOnly: readOnly,
      },
    });
  }

  mapValues() {
    if (this.data.flowSequence && this.data.flowSequence.length > 0) {
      this.triage.idFlowCompleted = this.data.flowSequence[0].idFlow;
      if (this.data.flowSequence.length > 1)
        this.triage.idFlow = this.data.flowSequence[1].idFlow;
    }

    this.protocolName = this.data.protocolName;
    let discriminatorValues = this.data.discriminatorValues && this.data.discriminatorValues.length > 0 ? this.data.discriminatorValues : false;
    this.triage.startClassification = this.data.datetimeStartTriage;
    this.triage.isReclassification = this.data.isReclassification;
    this.triage.idEpisode = this.data.idEpisode;
    this.triage.idGravity = this.data.priority.idPriorityColor;
    this.triage.gravityColor = this.data.priority.coloerHex;
    this.triage.temperature = discriminatorValues[DiscriminatorTypeEnum.Temperatura] ? parseFloat(discriminatorValues[DiscriminatorTypeEnum.Temperatura]) : null;;
    this.triage.respiratoryFrequency = discriminatorValues[DiscriminatorTypeEnum.FrequenciaRespiratoria] ? parseInt(discriminatorValues[DiscriminatorTypeEnum.FrequenciaRespiratoria]) : null;;
    this.triage.heartRate = discriminatorValues[DiscriminatorTypeEnum.FrequenciaCardiaca] ? parseInt(discriminatorValues[DiscriminatorTypeEnum.FrequenciaCardiaca]) : null;
    if (!Array.isArray(this.data.discriminatorAllValues))
      this.data.discriminatorAllValues = [];

    this.triage.capillaryFillingTimeValue = this.data.perfusaoCapilarValue;
    let heartRate = this.data.discriminatorAllValues.find(c => c.idDiscriminatorType == DiscriminatorTypeEnum.FrequenciaCardiaca);
    if (heartRate)
      this.triage.heartRateRegular = heartRate.type == DiscriminadorHeartRegular.regular.toString();
    else if (this.data.frequencyPulseType)
      this.triage.heartRateRegular = this.data.frequencyPulseType == DiscriminadorHeartRegular.regular.toString();
    else
      this.triage.heartRateRegular = null;

    this.triage.glucose = discriminatorValues[DiscriminatorTypeEnum.Glicemia] ? parseInt(discriminatorValues[DiscriminatorTypeEnum.Glicemia]) : null;
    this.triage.bloodPressureDiastole = discriminatorValues[DiscriminatorTypeEnum.PressaoArterial] ? parseInt(discriminatorValues[DiscriminatorTypeEnum.PressaoArterial].split("/")[1]) : null;;
    this.triage.bloodPressureSystole = discriminatorValues[DiscriminatorTypeEnum.PressaoArterial] ? parseInt(discriminatorValues[DiscriminatorTypeEnum.PressaoArterial].split("/")[0]) : null;
    this.triage.saturation = discriminatorValues[DiscriminatorTypeEnum.Saturacao] ? parseInt(discriminatorValues[DiscriminatorTypeEnum.Saturacao]) : null;
    let discriminatorPain = this.data.discriminatorAllValues.find(c => c.idDiscriminatorType == DiscriminatorTypeEnum.Dor || c.idDiscriminatorType == DiscriminatorTypeEnum.DorCoceira);
    this.pain = discriminatorPain ? discriminatorPain.value : null;
    this.painType = discriminatorPain ? discriminatorPain.type : null;
    this.triage.idPain = discriminatorPain ? discriminatorPain.type : null;
    this.triage.complaint = this.data.complain;
    this.triage.flowchart = this.data.flowchartName;
    this.triage.idFlowchart = this.data.idFlowchart;
    this.triage.idArrivalReason = this.data.idArrivalReason;
    this.triage.discriminator = !this.data.isPatientWhite ? this.data.discriminator.discriminatorName : this.data.arrivalReason;
    this.triage.idDiscriminator = this.data.discriminator ? this.data.discriminator.idDiscriminator : null;
    this.triage.glasgow = discriminatorValues[DiscriminatorTypeEnum.Glasgow] ? discriminatorValues[DiscriminatorTypeEnum.Glasgow] : null;
    this.triage.glasgowEye = this.data.glasgowEye;
    this.triage.glasgowVerbal = this.data.glasgowVerbal;
    this.triage.glasgowMotor = this.data.glasgowMotor;
    let DiscriminatorSaturation = this.data.discriminatorAllValues.find(c => c.idDiscriminatorType == DiscriminatorTypeEnum.Saturacao);
    this.triage.atmosphericAir = DiscriminatorSaturation ? DiscriminatorSaturation.type == DiscriminadorSaturationAtmosphericAir.atmosphericAir.toString() : null;
    this.triage.capillaryFillingTime = this.data.perfusaoCapilar;
    this.triage.fallRisk = this.data.radioFallRisk;
    this.triage.idSuspicion = this.data.idSuspicion;
    if (this.data.idSuspicion && this.data.isSuspicionEvaluated) {
      this.triage.idSuspicion = this.data.idSuspicion;
      this.triage.isSuspicionEvaluated = this.data.isSuspicionEvaluated;
      this.triage.suspicionEvaluationMotive = this.data.suspicionEvaluationMotive;
      this.triage.idUserSuspicionEvaluation = this.data.idUserSuspicionEvaluation;
      this.triage.datetimeSuspicionEvaluation = this.data.datetimeSuspicionEvaluation;
    }
    this.triage.idGrievanceNotification = this.data.idGrievanceNotification;
    this.triage.diseaseHistory = this.data.diseaseHistory;
    this.triage.comorbidities = this.data.comorbidities;
    this.triage.height = this.data.height ? parseFloat(this.data.height) : null;
    this.triage.weight = this.data.weight;
    this.triage.idFlow = this.data.forward.idFlow;
    this.triage.idQueue = this.data.forward.idLastQueue;
    if(!this.triage.idQueue && this.idQueue) 
      this.triage.idQueue = this.idQueue
    this.triage.idRoom = this.data.idRoom;
    this.triage.isAutistic = this.data.priorityAutistic;
    this.triage.discriminatorSelectionMotive = this.data.discriminatorSelectionMotive;
    this.triage.roomName = this.data.forward.locationName;
    this.triage.idSector = this.data.idSector;
    this.triage.forwardName = this.data.forward.locationName;
    this.triage.idForward = this.data.idRoom;
    this.triage.gravityName = this.data.priority.priorityColorName;
    this.triage.observations = (this.data.observation ? this.data.observation : "") + "" + (this.data.arrivalReasonDesc ? this.data.arrivalReasonDesc : "");
    this.triage.allergies = this.allergies;
    this.triage.billingData = this.data.billingData;
    this.triage.idLastFlowchart = this.data.idLastFlowchart;
    this.triage.lastFlowchart = this.data.lastFlowchart;
    this.triage.idPatient = this.data.idPatient;
    this.triage.temperatureComplementaryData = this.data.temperatureComplementaryData;
    this.triage.heartRateComplementaryData = this.data.heartRateComplementaryData;
    this.triage.bloodPressureDiastoleComplementaryData = this.data.bloodPressureDiastoleComplementaryData;
    this.triage.bloodPressureSystoleComplementaryData = this.data.bloodPressureSystoleComplementaryData;
    this.triage.respiratoryFrequencyComplementaryData = this.data.respiratoryFrequencyComplementaryData;
    this.triage.glucoseComplementaryData = this.data.glucoseComplementaryData;
    this.triage.saturationComplementaryData = this.data.saturationComplementaryData;
    this.triage.listAllergy = this.data.listAllergy;

    if (this.data.idCareLinePriority) {
      this.triage.careLinePriorityStatusStruct.idCareLinePriority = this.data.idCareLinePriority;
      this.triage.careLinePriorityStatusStruct.idCareLinePriorityStatus = CareLinePriorityStatusEnum.suspeita;
      this.triage.careLinePriorityStatusStruct.datetimeRegisterProtocolCareLine = this.data.datetimeRegisterProtocolCareLine;
      this.triage.careLinePriorityStatusStruct.openProtocolTriage = this.data.openProtocolTriage;
      this.triage.careLinePriorityStatusStruct.listNextStepsAfterTriageProtocolDescription = this.data.listNextStepsAfterTriageProtocolDescription;
      this.triage.careLinePriorityStatusStruct.careLineProtocolName = this.data.careLineProtocolName;
    }
    else {
      this.triage.careLinePriorityStatusStruct.idCareLinePriority = null;
      this.triage.careLinePriorityStatusStruct.idCareLinePriorityStatus = null;
      this.triage.careLinePriorityStatusStruct.datetimeRegisterProtocolCareLine = null;
      this.triage.careLinePriorityStatusStruct.openProtocolTriage = false;
      this.triage.careLinePriorityStatusStruct.careLineProtocolName = null;
      this.triage.careLinePriorityStatusStruct.listNextStepsAfterTriageProtocolDescription = null;
    }

    this.triage.listIdComorbodity = this.data.listIdComorbodity;
    this.triage.hasFlowchartIA = this.data.hasFlowchartIA;
    this.triage.iaResult = this.data.iaResult;
    this.triage.idArrivalType = this.data.idArrivalType;
    this.triage.idOrigin = this.data.idOrigin;
    this.triage.recurringReturn = this.data.recurringReturn;
    this.triage.recurringReturnConfiguredTime = this.data.recurringReturnConfiguredTime;

    if (heartRate && heartRate.state)
      this.triage.isHeartRateSleeping = heartRate.state == DiscriminadorHeartRateSleepy.sleepy.toString();
    else if (this.data.frequencyPulseState)
      this.triage.isHeartRateSleeping = this.data.frequencyPulseState == DiscriminadorHeartRateSleepy.sleepy.toString();
    else
      this.triage.isHeartRateSleeping = null;

    if(this.healthGuidelineTriageData) {
      this.triage.idHealthGuideline = this.healthGuidelineTriageData?.healthGuideline.idHealthGuideline;
      this.triage.healthGuidelineName = this.healthGuidelineTriageData.healthGuideline.healthGuidelineName;
      this.triage.idHealthGuidelineCategorization = this.healthGuidelineTriageData.healthGuidelineCategorization.idHealthGuidelineCategorization;
      this.triage.categorizationName = this.healthGuidelineTriageData.healthGuidelineCategorization.categorizationName;
      this.triage.datetimeStartSymptoms = this.healthGuidelineTriageData.datetimeStartSymptom;
    }
  }

  // private keepAliveIntervalId = setInterval(() => {
  //   if (this.data.idRoom) {
  //     this.roomAccessUtilService.postRoomAccessHistory(this.data.idRoom);
  //   }
  // }, this.roomAccessUtilService.roomKeepAliveTime());

  // ngOnDestroy() {
  //   clearInterval(this.keepAliveIntervalId);
  // }

  goBack() {
    this.router.navigate(['/classification/supplementary-data', { idEpisode: this.idEpisode, idRoom: this.idRoom, idQueue: this.idQueue }]);
  }

  changeEdited(event: any) {
    this.edited = event;
  }

  async restartMeasurers() {
    this.alertService.show('Info', "Medidores iniciados!", AlertType.info);
  }

  //Websocket
  startWebsocket() {
    if (!this.websocketAttendanceUtilService.isConnect())
      this.websocketAttendanceUtilService.connectwebsocketRoom(this.idRoom, this.idQueue, this.idEpisode, this, true, false, this.authService.getToken(), this.userLogin, this.userName);

    this.websocketAttendanceUtilService.setContext(this);
    this.websocketAttendanceUtilService.setFunctions(null, null, this.onRemoveAttendant);
  }

  onRemoveAttendant(thisParam, res: any) {
    thisParam.isAttendanceRemoval = false;

    if (res.userLogin != thisParam.userLogin) {
      thisParam.isAttendanceRemoval = true;

      thisParam.websocketAttendanceUtilService.clearSocket();
      let ref = thisParam.dialog.open(AttendanceOccupiedRemoveModalComponent, { data: { ...res } });
      ref.afterClosed().subscribe(() => {
        thisParam.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          thisParam.router.navigate(['/classification/classification-patient-list', { idSector: thisParam.data.idSector, idRoom: thisParam.data.idRoom }]));
      })
    }
  }
}
