import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { UbsConfigRequest } from '../../requests/ubs-health-promotion/ubs-config.request';
import { UbsConfigResponse } from '../../responses/ubs-health-promotion/ubs-config-list.response';
import { UBSScreenConfigResponse } from '../../responses/ubs-health-promotion/ubs-screen-config.response';
import { UbsScreenResponse } from '../../responses/ubs-health-promotion/ubs-screens.response';


@Injectable({
  providedIn: 'root'
})
export class UbsConfigService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getConfig(): Observable<UBSScreenConfigResponse>{

    let uri = `UBSConfig`;

    return this.httpClient.get<UBSScreenConfigResponse>(environment.urlApiUbsHealthPromotion + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }  

  public getConfigList(): Observable<UbsConfigResponse>{

    let uri = `UBSConfig/getAll`;

    return this.httpClient.get<UbsConfigResponse>(environment.urlApiUbsHealthPromotion + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }  
  
  public createConfig(body: UbsConfigRequest): Observable<ReturnStruct>{

    let uri = `UBSConfig`;

    return this.httpClient.post<ReturnStruct>(environment.urlApiUbsHealthPromotion + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public updateConfig(body: UbsConfigRequest): Observable<ReturnStruct>{

    let uri = `UBSConfig`;

    return this.httpClient.put<ReturnStruct>(environment.urlApiUbsHealthPromotion + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}