<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    <div class="container-body">
        <h1>Edição de lotes</h1>
        <div class="white-body">

            <div class="border-box">
                <app-loading-list *ngIf="isLoading"></app-loading-list>
                <div *ngIf="!isLoading" class="row batch-info">
                    <div class="col-12 col-lg-6 batch-info-col">
                        <p class="green-phrase"><b>Lote em edição:</b></p>
                        <p><b>Número: </b>{{ guideBatchStruct.guideBatch?.batchNumber.toString().padStart(12, '0')}} |
                            <b>Convênio: </b> {{guideBatchStruct.nameHealthcareAgreement}}
                        </p>
                        <p><b>Data do faturamento: </b> {{ guideBatchStruct.guideBatch?.datetimeSendGuideBatch | date:
                            'dd/MM/yyy' }}</p>
                    </div>
                    <div class="col-12 col-lg-6 batch-info-col">
                        <p><b>Status do processamento do lote: </b>
                            <span
                                [ngClass]="{'green-phrase': guideBatchStruct.guideBatch?.idGuideBatchStatus == guideBatchStatusEnum.Processado || guideBatchStruct.guideBatch?.idGuideBatchStatus == guideBatchStatusEnum.ProcessadoParcial, 'red-phrase': guideBatchStruct.guideBatch?.idGuideBatchStatus == guideBatchStatusEnum.ErroNoProcessamento, 'blue-phrase': guideBatchStruct.guideBatch?.idGuideBatchStatus == guideBatchStatusEnum.EmProcessamento}">
                                {{guideBatchStruct.guideBatchStatusName}}</span>
                        </p>
                        <p><b>Código do erro: </b> {{guideBatchStruct.errorName}}</p>
                        <p><b>Mensagem: </b> {{guideBatchStruct.errorMessage}}</p>
                    </div>
                </div>
            </div>

            <div class="border-box">
                <div class="row">
                    <div class="col-12 col-lg-6 col-xl-3">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Pesquisar</mat-label>
                            <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText"
                                (keyup)="onKeySearch($event)">
                        </mat-form-field>
                    </div>
                </div>

                <app-loading-progress-bar *ngIf="isLoading"></app-loading-progress-bar>
                <div class="table-block">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Episódio</th>
                                <th>Data de geração da guia</th>
                                <th>Paciente</th>
                                <th>Convênio</th>
                                <th>Tipo Guia</th>
                                <th>Nº Guia na Operadora</th>
                                <th>Valor (R$)</th>
                                <th>Status processamento</th>
                                <th>Açoes</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="listGuideToBatchStruct && listGuideToBatchStruct.length > 0">
                            <tr *ngFor="let guideReportStruct of listGuideToBatchStruct">
                                <td>{{ guideReportStruct.idEpisode }}</td>
                                <td>{{ guideReportStruct.datetimeInclusion | date: 'dd/MM/yyy' }}</td>
                                <td>{{ guideReportStruct.beneficiaryName }}</td>
                                <td>{{ guideReportStruct.nameHealthcareAgreement }}</td>
                                <td>{{ guideReportStruct.guideTypeName }}</td>
                                <td>{{ guideReportStruct.guideNumber }}</td>
                                <td>{{ guideReportStruct.totalValue | currency : 'R$'}}</td>
                                <td><span
                                        [ngClass]="{'green-phrase': guideBatchStruct.guideBatch?.idGuideBatchStatus == guideBatchStatusEnum.Processado || (guideBatchStruct.guideBatch?.idGuideBatchStatus == guideBatchStatusEnum.ProcessadoParcial && !guideReportStruct.guideHasErrorOnProcessing), 'red-phrase': guideReportStruct.guideHasErrorOnProcessing, 'blue-phrase': guideBatchStruct.guideBatch?.idGuideBatchStatus == guideBatchStatusEnum.EmProcessamento}">{{
                                        guideReportStruct.guideInBatchStatusName }}</span></td>
                                <td>
                                    <div class="action-icons">
                                        <mat-icon matTooltip="Visualizar erro"
                                            (click)="openGuideMessage(guideReportStruct.errorName, guideReportStruct.errorMessage)">visibility</mat-icon>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <app-empty-list
                    *ngIf="(!listGuideToBatchStruct || listGuideToBatchStruct.length == 0) && !this.isLoading"></app-empty-list>
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12">
                        <mat-paginator [length]="fullListSize" #paginator [pageIndex]="pageIndex" [pageSize]="pageSize"
                            aria-label="Select page" (page)="changePage($event.pageIndex)" [hidePageSize]="true">
                        </mat-paginator>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-4 col-md-3">
                <button mat-flat-button type="button" color="accent" class="btn-block" (click)="back()">
                    <span *ngIf="isLoading == false">Voltar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
            </div>
        </div>
    </div>
</div>