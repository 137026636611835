<div *ngIf="listDSVariable && completeTableData" class ="table-width">
    <table class="basic-table desktop">
        <thead class="basic-table__head" *ngIf="completeTableData.listTableResult && completeTableData.listTableResult.length > 0">
            <tr>
                <th *ngFor="let item of listDSVariable; let index = index;">{{item.dataSourceVariableName}}</th>
            </tr>
        </thead>
        <tbody class="basic-table__body">
            <tr class="basic-table__body__row" *ngFor="let item of completeTableData.listTableResult; let index = index;">
               <td *ngFor="let cell of item.listTableCell; let j = index;" class="truncate">
                   <span>{{cell.rowValue}}</span>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<app-empty-list *ngIf="listDSVariable != undefined && completeTableData && completeTableData.listTableResult && completeTableData.listTableResult.length == 0 && isLoading == false"></app-empty-list>
<app-loading-list *ngIf="isLoading"></app-loading-list>
