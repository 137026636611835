<div class="feature-content">
    <mat-label>
        <strong>Dispensação: </strong>
        <p>{{dispensation.releaseUserName}} - {{dispensation.datetimeRelease | date:'dd/MM/yyyy HH:mm:ss'}}</p>
    </mat-label>
    <mat-label>
        <strong>Medicamento:</strong>
        <p>{{dispensation.prescription.medicineName}}, {{dispensation.liberatedQuantity}} {{dispensation.prescription.measurementUnitName}}</p>
    </mat-label>    
    <mat-label>
        <strong>Apresentação:</strong>
        <p>{{dispensation.dispensationPresentationTypeName ? dispensation.dispensationPresentationTypeName : 'Não Informado'}}</p>
    </mat-label>   
    <mat-label>
        <strong>Via:</strong>
        <p>{{dispensation.prescription.administrationTypeName ? dispensation.prescription.administrationTypeName : 'Não informado'}}</p>
    </mat-label>  
    <mat-label>
        <strong>Lote:</strong>
        <p>{{dispensation.batch? dispensation.batch : 'Não informado'}}</p>
    </mat-label>  
    <mat-label>
        <strong>Validade:</strong>
        <p>{{dispensation.storageExpirationDate ? (dispensation.storageExpirationDate | date:'dd/MM/yyyy'):'Não informado'}}</p>
    </mat-label>  
</div>
