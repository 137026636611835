export enum UserRoleEnum {
    recepcao_fora_ordem = 6,
    config_sector_visualizar = 81,
    config_sector_alterar = 82,
    cancelar_agendamento = 311,
    requerer_assinatura_classificacao = 322,
    requerer_assinatura_medico = 337,
    requerer_assinatura_medico_gestao_leitos = 351,
    requerer_assinatura_multiprofissional = 352,
    requerer_assinatura_observacao = 353,
    requerer_assinatura_observacao_gestao_leitos = 354,
    requerer_assinatura_recepcao = 355,
}