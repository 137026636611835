<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-totem-modal-list">
            <h2>Configurar totem físico</h2>
         </div>
         
         <div class="body-totem-modal-list">
            <p>Para terminar de fazer a configuração e instalação do totem na unidade necessário fazer a instalação do arquivo de congifuração.</p>
            <p>Para que a impressora funcione corretamente, é necessário que o nome da impressora informado seja igual ao nome da impressora instalada.</p>
            <p>O download do arquivo instalador pode ser feito clicando <a href="https://storage.googleapis.com/tolife-production-public-storage/ToLifeCloud-API-TotemPrinter_SetupV1.msi" target="_blank">aqui</a> ou no Link abaixo. Depois que o download concluir, a instalação será feita clicando duas vezes no arquivo .msi baixado.</p>

            <div class="download-link">
                <a href="https://storage.googleapis.com/tolife-production-public-storage/ToLifeCloud-API-TotemPrinter_SetupV1.msi" target="_blank">Fazer Download</a>
            </div>
         </div>
         <div class="footer-totem-modal-list">
            <button mat-flat-button color="primary" class=""(click)="clickCancel()">Voltar</button>
        </div>
    </div>
</div>
