import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { AttendPatientComponent } from './pages/multi-professional-attend-patient/attend-patient.component';
import { MultiProfessionalConfigListComponent } from './pages/multi-professional-config-list/multi-professional-config-list.component';
import { MultiProfessionalConfigRegisterComponent } from './pages/multi-professional-config-register/multi-professional-config-register.component';
import { MultiProfessionalPatientListComponent } from './pages/multi-professional-patient-list/multi-professional-patient-list.component';
import { MultiProfessionalComponent } from './pages/multi-professional/multi-professional.component';

const routes: Routes = [
  { path: 'multi-professional', component: MultiProfessionalComponent, canActivate: [AuthGuard] },
  { path: 'multi-professional/multi-professional-config', component: MultiProfessionalConfigListComponent, canActivate: [AuthGuard] },
  { path: 'multi-professional/multi-professional-config/register', component: MultiProfessionalConfigRegisterComponent, canActivate: [AuthGuard] },
  { path: 'multi-professional/multi-professional-patient-list', component: MultiProfessionalPatientListComponent, canActivate: [AuthGuard] },
  { path: 'multi-professional/multi-professional-attend-patient', component: AttendPatientComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MultiProfessionalRoutingModule { }
