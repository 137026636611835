import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, retry, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ClockResponse } from '../../responses/orchestrator-queue/clock.response';

@Injectable({
  providedIn: 'root'
})
export class ClockService extends CommonService {

  constructor(private httpClient: HttpClient) { super(); }

  public getDate(): Observable<ClockResponse> {
    let uri = `Clock`
    return this.httpClient.get<ClockResponse>(environment.urlApiOrchestratorQueue + uri)
      .pipe(timeout(environment.requestTimeOut), retry(environment.requestRetry), catchError(this.handleError));
  }
}