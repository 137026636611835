<div mat-dialog-content>
    <div class="mat-dialog-content">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="title-flex">
                <h2>Prescrição</h2>
                <button type="button" mat-flat-button color="accent" class="btn" (click)="openCheckHistoryModal()" 
                    *ngIf="prescription.listMedicalPrescriptionCheck && prescription.listMedicalPrescriptionCheck.length > 0">
                    <span>Ver histórico</span>
                </button>
            </div>
            <div *ngIf="!isMedicatedSolution" class="row row-block">
                <div class="col-12 col-sm-6 col-md-4">
                    <label>Nome medicamento: </label>
                    <p>{{prescription.medicineName}}</p>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <label>Quantidade: </label>
                    <p>{{prescription.quantity}}</p>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <label>Unidade: </label>
                    <p>{{prescription.measurementUnitName}}</p>
                </div>
            </div>
            <div *ngIf="isMedicatedSolution" class="row row-block">
                <ng-container *ngFor="let item of prescription.listMedicine; let index = index;">
                    <div class="col-12 col-sm-6 col-md-4">
                        <label>Nome medicamento: </label>
                        <p>{{item.medicineName}}</p>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <label>Quantidade: </label>
                        <p>{{item.quantity}}</p>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <label>Unidade: </label>
                        <p>{{item.measurementUnitName}}</p>
                    </div>
                </ng-container>
            </div>
            <div class="row row-block">
                <div class="col-12 col-sm-6 col-md-4">
                    <label>Apresentação: </label>
                    <p>{{prescription.presentationName}}</p>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <label>Uso: </label>
                    <p>{{prescription.usage}}</p>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <label>Forma de consumo:</label>
                    <p>{{prescription.administrationTypeName}}</p>
                    <p>{{prescription.administrationType}}</p>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <label>Frequencia: </label>
                    <p>{{prescription.frequencyName}}</p>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <label>Período de tratamento: </label>
                    <p>{{prescription.treatmentPeriodName}}</p>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <label>Diluição: </label>
                    <p>{{prescription.dilution ? prescription.dilution : prescription.dilutionName}}</p>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <label>Observação: </label>
                    <p>{{prescription.observation}}</p>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <label>Unidade de diluição: </label>
                    <p>{{prescription.dilutionUnitName}}</p>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <label>Quantidade de diluição: </label>
                    <p>{{prescription.dilutionQuantity}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">    
                    <div class="body-confirm-modal-list">
                        <div class="title-header">
                            <h1>Checagem</h1>
                        </div>
                        <div class="row" *ngIf="this.isReadonly">
                            <div class="col-md-11">
                                <label>Checado em {{checkDatetimeFulfilled | date:'dd/MM/yyyy HH:mm'}}</label>
                                <p>{{checkProfessionName}} - {{checkUserName}}</p>
                            </div>
                        </div>
                        <ng-container *ngIf="data.actions">
                            <div class="row ">
                                <div class="col-12 col-sm-4 col-md-5">
                                    <mat-checkbox matInput formControlName="checkName" [disabled]="isReadonly"
                                        [checked]="checkedPatient"></mat-checkbox>
                                    <label>Nome do paciente</label>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 mobile-margin">
                                    <label>Data de nascimento</label>
                                </div>
                            </div>
                            <div class="row row-block">
                                <div class="col-12 col-sm-4 col-md-5">
                                    <p class="first-description">{{this.data.patientName}}</p>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 mobile-margin">
                                    <p>{{this.data.birthDate | date:'dd/MM/yyyy'}}</p>
                                </div>
                                <div class="col-12 col-sm-3 col-md-2" *ngIf="!isReadonly">
                                    <button mat-flat-button type="button" color="primary" class="btn-primary read-btn"
                                        (click)="openPatientBarCodeModal()">Ler paciente</button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-3 col-md-5">
                                    <mat-checkbox matInput formControlName="checkMedication" [disabled]="isReadonly"
                                        [checked]="checkedMedicine">
                                    </mat-checkbox>
                                    <label>Medicação</label>
                                </div>
                                <div class="col-12 col-sm-2 col-md-1 mobile-margin">
                                    <label>Qtde.</label>
                                </div>
                                <div class="col-12 col-sm-3 col-md-3 mobile-margin">
                                    <label>Unidade</label>
                                </div>
                            </div>
                            <div *ngIf="!isMedicatedSolution" class="row row-block">
                                <div class="col-12 col-sm-3 col-md-5 mobile-margin">
                                    <p class="first-description">{{prescription.medicineName}}</p>
                                </div>
                                <div class="col-12 col-sm-2 col-md-1 mobile-margin">
                                    <p>{{prescription.quantity}}</p>
                                </div>
                                <div class="col-12 col-sm-3 col-md-3 mobile-margin">
                                    <p>{{prescription.measurementUnitName}}</p>
                                </div>
                                <div class="col-12 col-sm-3 col-md-2" *ngIf="!isReadonly && data.actions">
                                    <button mat-flat-button type="button" color="primary" class="btn-primary read-btn"
                                        (click)="openMedicineBarCodeModal(this.prescription.idMedicine)">Ler medicação</button>
                                </div>
                            </div>
                            <div *ngIf="isMedicatedSolution" class="row-block">
                                <div class="row" *ngFor="let item of prescription.listMedicine; let index = index;">
                                    <div class="col-12 col-sm-3 col-md-5 mobile-margin first-description">
                                        <div class="status-icon">
                                            <mat-icon *ngIf="item.idPrescriptionStatus == nonDispensedStatus" aria-hidden="false" aria-label="não dispensado" class="non-dispensed-status">close</mat-icon>
                                            <mat-icon *ngIf="item.idPrescriptionStatus == releasedStatus" aria-hidden="false" aria-label="não dispensado" [ngClass]="{'opened-status': this.model.get('checkMedication').value == true}">done</mat-icon>
                                            <mat-icon *ngIf="item.idPrescriptionStatus == openedStatus" aria-hidden="false" aria-label="não dispensado" matTooltip="Em aberto">info</mat-icon>
                                        </div>
                                        <p class="">{{item.medicineName}}</p>
                                    </div>
                                    <div class="col-12 col-sm-2 col-md-1 mobile-margin">
                                        <p>{{item.quantity}}</p>
                                    </div>
                                    <div class="col-12 col-sm-3 col-md-3 mobile-margin">
                                        <p>{{item.measurementUnitName}}</p>
                                    </div>
                                    <div class="col-12 col-sm-3 col-md-2" *ngIf="!isReadonly && data.actions">
                                        <button *ngIf="item.idPrescriptionStatus != nonDispensedStatus" mat-flat-button type="button" color="primary" class="btn-primary read-btn"
                                            (click)="openMedicineBarCodeModal(item.idMedicine)">Ler medicação</button>
                                        <span *ngIf="item.idPrescriptionStatus == nonDispensedStatus" class="non-dispensed-status warning-non-dispensed">Não dispensado</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-2">
                                    <mat-checkbox matInput formControlName="checkAdministration"
                                        (change)="checkAdministration($event)" [disabled]="isReadonly">
                                    </mat-checkbox>
                                    <label>Via </label>
                                </div>
                            </div>
                            <div class="row row-block">
                                <div class="col-12 col-sm-2">
                                    <p class="first-description">{{prescription.administrationTypeName}}</p>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <mat-form-field appearance="outline" *ngIf="this.showLocal" [disabled]="isReadonly">
                                        <mat-label>Local: </mat-label>
                                        <input type="text" matInput formControlName="administrationLocal"
                                            [disabled]="isReadonly">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Observações</mat-label>
                                        <mat-select [disabled]="isReadonly" [(ngModel)]="observationSelect"
                                            (selectionChange)="selectionChange()" [ngModelOptions]="{standalone: true}">
                                            <mat-option value="">Selecione</mat-option>
                                            <mat-option value="1">Paciente negou a administração da medicação
                                            </mat-option>
                                            <mat-option value="2">Queixas/Intercorrências</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-12" *ngIf="this.showComplaints">
                                    <mat-form-field appearance="outline" [disabled]="isReadonly">
                                        <mat-label>Queixas/Intercorrências: </mat-label>
                                        <textarea type="text" matInput formControlName="complaints"></textarea>
                                        <mat-error *ngIf="model.get('complaints').invalid">Preencha a queixa!
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="footer-modal-list">
                <div class="row">
                    <div class="col-6">
                        <button mat-flat-button type="button" color="accent" class="btn-block"
                        (click)="close()"><span>Fechar</span></button>
                    </div>
                    <div class="col-6">
                        <button mat-flat-button color="primary" class="btn-block" type="submit" *ngIf="!isReadonly && data.actions && data.prescription.idPrescriptionStatus != nonDispensedStatus">
                            <span *ngIf="isLoading == false">Liberar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>