import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { KeepAliveService } from 'src/app/shared/services/API/panel/keep-alive.service';
import { HealthUnitTimeService } from 'src/app/shared/services/API/user/health-unit-time.service';
import { GetPanelResponse } from 'src/app/shared/services/responses/panel/get-panel.response';
import { UtilsClientService } from '../../utils.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { PanelLoginRequest } from 'src/app/shared/services/requests/panel/panel-login.request';
import { MatDialog } from '@angular/material/dialog';
import { SecurityAlertModalComponent } from '../../../shared/components/security-alert-modal/security-alert-modal.component';
import { VerifyWaitingTimeService } from 'src/app/shared/services/API/queue/verify-waiting-time.service';
import { GetQueueRequest } from 'src/app/shared/services/requests/queue/get-queue.request';

@Component({
  selector: 'app-display',
  templateUrl: './display.component.html',
  styleUrls: ['./display.component.css']
})
export class DisplayComponent implements OnInit, OnDestroy {

  public authenticated: boolean = false;
  public pin: string;
  public idPanel: number;
  public isLoading: boolean = false;
  public showAverageWaitingTime: boolean = false;
  public lastHourWaitingTimeAverage: string = null;
  public panelStruct: GetPanelResponse;
  onlineOffline: boolean = navigator.onLine;
  public panelTime: Date;

  private intervalId = setInterval(() => {
    if (this.onlineOffline !== navigator.onLine)
      this.onlineOffline = navigator.onLine;
  },
    1000);

  ngOnDestroy() {
    clearInterval(this.intervalId);
  }

  constructor(private alertService: AlertService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private KeepAliveService: KeepAliveService,
    private healthUnitTimeService: HealthUnitTimeService,
    private utilService: UtilsClientService,
    private cdref: ChangeDetectorRef,
    private verifyWaitingTimeService: VerifyWaitingTimeService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.idPanel = parseInt(this.activatedRoute.snapshot.paramMap.get('id'));
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  checkPin(event) {
    this.isLoading = true;
    this.pin = event;

    let request: PanelLoginRequest = {
      idPanel: this.idPanel,
      pin: this.pin
    };

    this.authService.signInPanel(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          this.utilService.removePanelPin();
          this.authService.logoutPanel();
          if (response.errorCode === 2) {
            this.openSecurityAlertModal();
          }
          return;
        }
        this.panelStruct = response;
        this.authService.updatePanelToken(response);
        this.getHealthUnitTime();

        let panel = this.panelStruct?.listPanelOrigin?.find(x => x.showAverageWaitingTime);
        if (panel != null) {
          this.showAverageWaitingTime = panel.showAverageWaitingTime;

          let request: GetQueueRequest = new GetQueueRequest();
          request.idRoom = panel.idRoom;
          request.idSector = panel.idSector;
          request.idService = panel.idService;
       
          this.verifyWaitingTime(request);
        }
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.utilService.removePanelPin();
        this.authService.logoutPanel();
      }
    });
  }

  getHealthUnitTime() {
    this.isLoading = true;
    this.healthUnitTimeService.getTime(this.panelStruct.idHealthUnit).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (!this.panelTime) {
          this.alertService.show('Sucesso', 'PIN autenticado com sucesso', AlertType.success);
          this.authenticated = true;
        }
        this.isLoading = false;
        this.panelTime = response.healthUnitTime;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  verifyWaitingTime(request: GetQueueRequest) {
    this.isLoading = true;
    this.verifyWaitingTimeService.getVerifyWaitingTime(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.lastHourWaitingTimeAverage = response.lastHourWaitingTimeAverage;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  keepAlive() {
    this.KeepAliveService.keepAlive().subscribe({
      next: (response) => {
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openSecurityAlertModal() {
    const dialogRef = this.dialog.open(SecurityAlertModalComponent, {
      data: {
        equipment: 'painel'
      },
    });

    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result) {
        }
      },
      error: (error) => {
      }
    });
  }
}