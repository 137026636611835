import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DigitalSignatureFlowEnum } from 'src/app/shared/enum/digital-signature-flow.enum';
import { SignatureProviderEnum } from 'src/app/shared/enum/signature-providers/signature-providers.enum';
import { SafeIDAuthenticatorComponent } from './safeID-authenticator/safeID-authenticator.component';
import { MatDialog } from '@angular/material/dialog';
import { AlertModalComponent } from '../../alert-modal/alert-modal.component';
import { DigitalSignatureUtilService } from 'src/app/shared/services/digital-signature-util.service';
import { DigitalSignatureStruct } from 'src/app/shared/services/structs/digital-signature/digital-signature-struct.struct';
import { SafeIDVidaaSSignatureStruct } from 'src/app/shared/services/structs/digital-signature/safe-id/safe-id-vidaas-signature-struct.struct';
import { VidaaSAuthenticatorComponent } from './vidaas-authenticator/vidaas-authenticator.component';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-signature-manager',
  templateUrl: './signature-manager.component.html',
  styleUrls: ['./signature-manager.component.css']
})
export class SignatureManagerComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private digitalSignatureUtil: DigitalSignatureUtilService,
    private authService: AuthService,
    private alertService: AlertService,
    public dialog: MatDialog,
  ) { }

  @Input() isVisible: boolean;
  @Input() isLoading: boolean;
  @Input() isMenu: boolean;

  @Output() closeManager = new EventEmitter<any>();
  @Output() setManagerIcon = new EventEmitter<string>();
  @Output() submitSignature = new EventEmitter<any>();
  @Output() cancelSignature = new EventEmitter<any>();

  @ViewChild(SafeIDAuthenticatorComponent) safeID: SafeIDAuthenticatorComponent;
  @ViewChild(VidaaSAuthenticatorComponent) vidaaS: VidaaSAuthenticatorComponent;

  public selectedProvider: number = 0;
  public openManager: boolean = true;
  public openSafeID: boolean = false;
  public openVidaaS: boolean = false;
  public signatureProvidersEnum = SignatureProviderEnum;
  public techCertStatusIcon: string = "block";
  public safeIDStatusIcon: string = "block";
  public vidaaSStatusIcon: string = "block";
  public model: FormGroup;
  public techCertHash: string = null;

  ngOnInit(): void {
    let listSignatureStruct = this.digitalSignatureUtil.listSignatureStruct();
    this.selectedProvider = this.digitalSignatureUtil.getSelectedProvider();
    this.techCertHash = this.authService.getTokenMenu()?.signatureHash;

    this.model = this.formBuilder.group({
      selectedProvider: [this.selectedProvider, null]
    });

    for (const [key, value] of Object.entries(listSignatureStruct)) {
      switch (parseInt(key)) {
        case SignatureProviderEnum.safeID:
          this.setSafeIDStatusIcon(value);
          break;
        case SignatureProviderEnum.vidaaS:
          this.setVidaaSStatusIcon(value);
          break;
      }
    }

    this.setTechCertStatusIcon();

    if (!this.isMenu && this.selectedProvider) {
      switch (this.selectedProvider) {
        case SignatureProviderEnum.safeID:
          this.openSafeID = true;
          this.openManager = false;
          this.openVidaaS = false;
          break;
        case SignatureProviderEnum.vidaaS:
          this.openVidaaS = true;
          this.openManager = false;
          this.openSafeID = false;
          break;
        case SignatureProviderEnum.techCert:
          this.openManager = true;
          //this.submitSignature.emit();
          break;
        default:
          this.openManager = true;
          break;
      }
    }
  }

  async selectProvider(event: any) {
    if (event && event.value) {
      this.selectedProvider = event.value;
      this.digitalSignatureUtil.setSelectedProvider(this.selectedProvider);
    }
  }

  openComponent(event: any) {
    if (!event || !event.target)
      return;

    switch (parseInt(event.target.id)) {
      case SignatureProviderEnum.safeID:
        this.openSafeID = true;
        this.openManager = false;
        this.openVidaaS = false;
        break;
      case SignatureProviderEnum.vidaaS:
        this.openVidaaS = true;
        this.openManager = false;
        this.openSafeID = false;
        break;
      case SignatureProviderEnum.techCert:
        this.openManager = true;
        let hasTechCert = true;

        if (!this.techCertHash || this.techCertHash.trim() == "") {
          hasTechCert = false;
          this.alertService.show('Aviso!', "Sua assinatura TECHCERT não está ativa. Entre em contato com o responsável pelo seu cadastro para realizar a ativação.", AlertType.warning);
        }

        if (!this.isMenu && hasTechCert) {
          this.digitalSignatureUtil.setSelectedProvider(SignatureProviderEnum.techCert);
          this.submitSignature.emit();
        }
        break;
      default:
        this.openManager = true;
        break;
    }
  }

  close() {
    if (
      (!this.safeID || this.safeID.state != DigitalSignatureFlowEnum.awatingConfirmation) ||
      (!this.vidaaS || this.vidaaS.state != DigitalSignatureFlowEnum.awatingConfirmation)
    ) {
      this.openSafeID = false;
      this.openVidaaS = false;
      this.openManager = true;
      this.closeManager.emit();
    }
    else {
      const dialogRef = this.dialog.open(AlertModalComponent, {
        data: {
          isTwoButtonsModal: true,
          title: 'Atenção',
          description: 'Se você sair agora terá que relogar para atualizar as informações'
        },
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe({
        next: result => {
          if (result && result.confirm) {
            this.openSafeID = false;
            this.openVidaaS = false;
            this.openManager = true;
            this.closeManager.emit();
          }
        }
      });
    }
  }

  reset(event: number) {
    switch (event) {
      case SignatureProviderEnum.safeID:
        this.openSafeID = false;
        break;
      case SignatureProviderEnum.vidaaS:
        this.openVidaaS = false;
        break;
      case SignatureProviderEnum.techCert:
        break;
    }
    
    this.openManager = true;
  }

  setStatusIcon(event: string, providerId: number) {
    switch (providerId) {
      case SignatureProviderEnum.safeID:
        this.safeIDStatusIcon = event;
        break;
      case SignatureProviderEnum.techCert:
        this.techCertStatusIcon = event;
        break;
      case SignatureProviderEnum.vidaaS:
        this.vidaaSStatusIcon = event;
        break;
    }
  }

  setSafeIDStatusIcon(signatureStruct: SafeIDVidaaSSignatureStruct) {
    if (!signatureStruct ||
      !signatureStruct.client_id ||
      !signatureStruct.client_secret) {
      this.safeIDStatusIcon = 'block';
    }

    else if (!signatureStruct.identifierCA)
      this.safeIDStatusIcon = 'app_blocking';

    else if (!signatureStruct.access_token)
      this.safeIDStatusIcon = 'mobile_friendly';

    else
      this.safeIDStatusIcon = 'verified';
  }

  setVidaaSStatusIcon(signatureStruct: SafeIDVidaaSSignatureStruct) {
    if (!signatureStruct ||
      !signatureStruct.client_id ||
      !signatureStruct.client_secret) {
      this.vidaaSStatusIcon = 'block';
    }

    else if (!signatureStruct.authorizationToken)
      this.vidaaSStatusIcon = 'app_blocking';

    else if (!signatureStruct.access_token)
      this.vidaaSStatusIcon = 'mobile_friendly';

    else
      this.vidaaSStatusIcon = 'verified';
  }

  setTechCertStatusIcon() {
    this.techCertStatusIcon = "block";

    if (this.techCertHash && this.techCertHash.trim() != "") {
      this.techCertStatusIcon = 'verified';
    }   
  }

  submit(event: any) {
    this.submitSignature.emit(event);
  }

  cancelSigning() {
    this.digitalSignatureUtil.removeSelectedProvider();
    this.cancelSignature.emit();
  }
}