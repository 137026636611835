import { Component, OnInit } from '@angular/core';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { CsatService } from 'src/app/shared/services/API/csat/csat.service';
import { CsatConfigModel } from 'src/app/shared/services/models/csat/csat-config.model';
import { CsatModel } from 'src/app/shared/services/models/csat/csat.model';
import { PeriodModel } from 'src/app/shared/services/models/csat/period.model';

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.css']
})

export class AuditComponent implements OnInit {

  constructor(private csatService: CsatService) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.audit;

  public seeButton: boolean = false;

  ngOnInit(): void {
    this.getCsat();
  }

  getCsat() {
    this.csatService.getCsat().subscribe({
      next: (response) => {
        this.seeButton = response.seeButton;
      },
    });
  }
}