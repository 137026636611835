import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { GetSampleRequest } from '../../requests/medical-record/get-sample.request';
import { SearchFlowchartRequest } from '../../requests/risk-classification/search-flowchart.request';
import { GetAuditResponse } from '../../responses/orchestrator-audit/get-audit.response';
import { AuditUserSelectStruct } from '../../structs/audit/audit-user-select.struct';

@Injectable({
  providedIn: 'root'
})
export class CreateAuditService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public createTriageAudit(request: GetSampleRequest): Observable<GetAuditResponse> {
    let uri = `CreateAudit`;
      
    return this.httpClient.post<GetAuditResponse>(environment.urlApiOrchestratorAudit + uri, request, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}