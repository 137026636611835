import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SuccessModalComponent } from "./modals/success-modal/success-modal.component";
import { ValidatorsModalComponent } from "./modals/validators-modal/validators-modal.component";

@Injectable({
    providedIn: 'root'
})
export class UtilsIframeService {
    constructor(private dialog: MatDialog) { }

    openModalError(message: string): void {
        this.dialog.open(ValidatorsModalComponent, {
            disableClose: true,
            data: {
                message
            },
            panelClass: 'custom-modalbox'
        });
    }

    openModalSuccess(message: string): void {
        this.dialog.open(SuccessModalComponent, {
            disableClose: true,
            data: {
                message
            },
            panelClass: 'custom-modalbox'
        });
    }
}

