import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { ClassificationPatientListComponent } from './pages/classification-patient-list/classification-patient-list.component';
import { ClassificationPrintingComponent } from './pages/classification-printing/classification-printing.component';
import { ClassificationComponent } from './pages/classification/classification.component';
import { ConfigSectorProtocolComponent } from './pages/config-sector-protocol/config-sector-protocol.component';
import { CounselingSelectionComponent } from './pages/counseling-selection/counseling-selection.component';
import { FlowchartSelectionComponent } from './pages/flowchart-selection/flowchart-selection.component';
import { FlowchartComponent } from './pages/flowchart/flowchart.component';
import { ForwardSelectComponent } from './pages/forward-select/forward-select.component';
import { PreRegisterComponent } from './pages/pre-register/pre-register.component';
import { ProtocolSelectComponent } from './pages/protocol-select/protocol-select.component';
import { SupplementaryDataComponent } from './pages/supplementary-data/supplementary-data.component';
import { TelephoneClassificationDetailsComponent } from './pages/telephone-classification-details/telephone-classification-details.component';
import { TelephoneClassificationComponent } from './pages/telephone-classification/telephone-classification.component';
import { TelephonePreRegisterComponent } from './pages/telephone-pre-register/telephone-pre-register.component';
import { TriageResultComponent } from './pages/triage-result/triage-result.component';
import { WhitePatientParentComponent } from './pages/white-patient-parent/white-patient-parent.component';

const routes: Routes = [
  { path: 'classification', component: ClassificationComponent, canActivate: [AuthGuard] },
  { path: 'classification/pre-register', component: PreRegisterComponent, canActivate: [AuthGuard] },
  { path: 'classification/protocol-select', component: ProtocolSelectComponent, canActivate: [AuthGuard] },
  { path: 'classification/flowchart-select', component: FlowchartSelectionComponent, canActivate: [AuthGuard] },
  { path: 'classification/flowchart', component: FlowchartComponent, canActivate: [AuthGuard] },
  { path: 'classification/config-sector-protocol', component: ConfigSectorProtocolComponent, canActivate: [AuthGuard] },
  { path: 'classification/white-patient', component: WhitePatientParentComponent, canActivate: [AuthGuard] },
  { path: 'classification/forward', component: ForwardSelectComponent, canActivate: [AuthGuard] },
  { path: 'classification/supplementary-data', component: SupplementaryDataComponent, canActivate: [AuthGuard] },
  { path: 'classification/classification-patient-list', component: ClassificationPatientListComponent, canActivate: [AuthGuard] },
  { path: 'classification/triage-resume', component: TriageResultComponent, canActivate: [AuthGuard] },
  { path: 'classification/telephone-classification', component: TelephoneClassificationComponent, canActivate: [AuthGuard] },
  { path: 'classification/telephone-classification/details', component: TelephoneClassificationDetailsComponent, canActivate: [AuthGuard] },
  { path: 'classification/telephone-classification/register', component: TelephonePreRegisterComponent, canActivate: [AuthGuard] },
  { path: 'classification/counseling-selection', component: CounselingSelectionComponent, canActivate: [AuthGuard] },
  { path: 'classification/classification-printing', component: ClassificationPrintingComponent, canActivate: [AuthGuard] }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClassificationRoutingModule { }
