<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum'></app-menu>
    
    <div class="container-body">
        <div class="white-body">
            <div class="title-header">
                <mat-icon aria-hidden="false" aria-label="Menu apps">manage_accounts</mat-icon>
                <h1>Iframe</h1>
            </div>
            <p>Aqui você pode controlar sua integração via IFRAME.</p>
    
            <h2>URL classificação:</h2>
            <p>
                {{urlBase}}client/iframe/1;token=;idProtocol=;patientName=;patientSocialName=;cpf=;cns=;gender=;birthDate=
            </p>
            <p>
                1. <b>token</b> string <b>(Obrigatório)</b> - Token criada no menu "Chave de Acesso";<br />
                2. <b>idProtocol</b> number <b>(Obrigatório)</b> - Id do protocolo telefônico a ser utilizado;<br />
                3. <b>patientName</b> string <b>(Obrigatório)</b> - Nome do paciente;<br />
                4. <b>patientSocialName</b> string - Nome social do paciente;<br />
                5. <b>cpf</b> string (Obrigatório, se não houver CNS) - CPF do paciente;<br />
                6. <b>cns</b> string (Obrigatório, se não houver CPF) - CNS do paciente;<br />
                7. <b>gender</b> string (M/F) - gênero do paciente <br />
                8. <b>birthDate</b> string <b>(Obrigatório)</b> - Data de nascimento do paciente no formato dd-MM-yyyy;
                <br />
                9. <b>metadata</b> string - Campo de valor livre no tipo (JSON)<br />
            </p>
        </div>
    </div>
    
</div>