<div class="row display-contents">
    <form class="form" [formGroup]="model" (ngSubmit)="submit()">
        <div class="col-12" style="padding: 2rem;">
            <div class="row border-box">
                <div class="col-xl-4 col-lg-4 col-md-12 col-12">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 border-right">
                            <div class="rectangle">
                                <img *ngIf="uploadedLogo && newSrc" class="image" [src]="newSrc">
                                <img *ngIf="oldSrc && haveLogo" class="image" [src]="oldSrc">
                                <img *ngIf="defaultImage && haveLogo == false" src="/assets/icons/upload_file.png"
                                    aria-hidden="false" aria-label="vazio">
                            </div>
                            <input #fileInput type="file" formControlName="requiredfile" style="display: none;"
                                accept="image/jpeg, image/png">
                            <button class="custom-button" type="button" (click)="triggerFileInputClick()">Inserir
                                logo</button>
                        </div>
                    </div>
                </div>
                <div class="col-xl-8 col-lg-7 col-md-7 col-12 organization-fields">
                    <div class="row">
                        <div class="col-10 col-sm-4 col-md-4 col-xl-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Nome do convênio</mat-label>
                                <input matInput type="text" formControlName="nameHealthcareAgreement">
                                <mat-error *ngIf="model.get('nameHealthcareAgreement').invalid">
                                    Informe o nome do convênio
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="row col-10 col-sm-4 col-md-4 col-xl-4">
                            <div class="col-8 col-lg-8 col-xl-9 col-md-8 col-sm-7">
                                <mat-form-field appearance="outline">
                                    <mat-label>Registro ANS</mat-label>
                                    <input matInput type="text" formControlName="ansRegistrationNumber"
                                        (keyup)="onANSKeyup()">
                                    <mat-error *ngIf="model.get('ansRegistrationNumber').hasError('maxlength')">
                                        O número máximo de caracteres permitido é 6.
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-1 info">
                                <div class="custom-icon-interrogation" matTooltipClass="tooltip-class"
                                    matTooltip="Insira o número de cadastro do convênio na ANS ou mantenha o padrão 000000 se o item for 'Particular'">
                                    ?
                                </div>
                            </div>
                        </div>
                        <div class="col-9 col-sm-3 col-md-3 col-xl-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Telefone</mat-label>
                                <input matInput type="text" formControlName="phone" [mask]="masks.phone">
                                <mat-error *ngIf="model.get('phone').invalid">
                                    Número de telefone inválido.
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-1">
                            <mat-label>Particular</mat-label>
                            <mat-slide-toggle formControlName="privateHealthcare"
                                (change)="updateToggle($event)"></mat-slide-toggle>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-10 col-sm-4 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Versão XML TISS</mat-label>
                                <mat-select formControlName="idTissXml">
                                    <mat-option *ngFor="let item of listTissXml" [value]="item.idTissXml">
                                        {{item.tissXmlName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-10 col-sm-4 col-md-8">
                            <mat-form-field appearance="outline">
                                <mat-label>Tabela de procedimentos</mat-label>
                                <mat-select formControlName="idTerminologyOrigin">
                                    <mat-option *ngFor="let item of listTerminologyOrigin"
                                        [value]="item.idTerminologyOrigin">
                                        {{item.nameTerminologyOrigin}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-4 col-sm-2 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Nº próx. lote</mat-label>
                                <input matInput type="text" formControlName="nextBatchNumber"
                                    (input)="inputNumberMinOne('nextBatchNumber')"
                                    oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                            </mat-form-field>
                        </div>
                        <div class="col-4 col-sm-2 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Nº próx. Guia</mat-label>
                                <input matInput type="text" formControlName="nextGuideNumber"
                                    (input)="inputNumberMinOne('nextGuideNumber')"
                                    oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="padding-left: 20px;">
            <div class="col-12 col-lg-6 col-xl-3 col-md-6 col-sm-6">
                <a mat-flat-button type="button" routerLink="/billing/healthcare-agreements-and-plans" color="accent"
                    class=" btn-block">
                    Cancelar
                </a>
            </div>
            <div class="col-12 col-lg-6 col-xl-3 col-md-6 col-sm-6">
                <button mat-flat-button color="primary" type="submit" class="btn-block">
                    <span *ngIf="isLoading == false">Salvar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
            </div>
        </div>
    </form>
</div>