<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <a class="back-link" routerLink="/medic/medic-config">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Configuração médico
        </a>
        <h1>Cadastrar Configuração médico</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <form appFormscroll class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome</mat-label>
                            <input matInput type="text" formControlName="name">
                            <mat-error *ngIf="model.get('name').invalid">Informe o nome</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Descrição</mat-label>
                            <input matInput type="text" formControlName="description">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 block-fast-evasion">
                        <div class="form-field-toggle">
                            <mat-label>Fast Track</mat-label>
                            <mat-slide-toggle formControlName="fastTrack"
                                (change)="toggleFastTrack($event)"></mat-slide-toggle>
                        </div>
                        <div class="form-field-toggle toggle-evasion">
                            <mat-label>Permitir evadir paciente</mat-label>
                            <mat-slide-toggle formControlName="allowEvasion"
                                (change)="toggleAllowEvasion($event)"></mat-slide-toggle>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Setores</mat-label>
                            <mat-select (selectionChange)="populateListOfLinks()" formControlName="listIdSector"
                                multiple>
                                <mat-option *ngFor="let item of listSector" [value]="item.idSector"
                                    [disabled]="item.idModule != this.menuModuleEnum && this.model.get('allowRemote').value">
                                    {{item.serviceName}} - {{item.sectorName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Campos Obrigatórios</mat-label>
                            <mat-select formControlName="listIdRequiredField" multiple>
                                <mat-option *ngFor="let item of listRequiredField" [value]="item.idRequiredField">
                                    {{item.requiredFieldName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 block-fast-evasion">
                        <div class="form-field-toggle">
                            <mat-label>Setor de atendimento remoto</mat-label>
                            <mat-slide-toggle formControlName="allowRemote"
                                (change)="toggleAllowRemote($event)"></mat-slide-toggle>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-slide-toggle class="medicine-list-checkbox" formControlName="toggleAllMedicine"
                            (change)="toggleAllMedicine($event)">Listar todos os medicamentos</mat-slide-toggle>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-slide-toggle formControlName="toggleAllExam" (change)="toggleAllExam($event)">Listar todos
                            os
                            exames</mat-slide-toggle>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <app-medicine-tree [listMedicineType]="listHierarchyMedicineTypeStruct"
                            (sendListIdMedicine)="populateListIdMedicineModel($event)" *ngIf="!isListAllMedicine">
                        </app-medicine-tree>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline" *ngIf="!isListAllExam">
                            <mat-label>Exames</mat-label>
                            <mat-select formControlName="listIdExam" multiple>
                                <input class="inputSearchSelect" type="text" (keyup)="onKey($event.target.value)"
                                    placeholder="Pesquisar" autocomplete="off" (keydown)="$event.stopPropagation()">
                                <mat-option *ngFor="let item of listExamDisplay" [value]="item.idExam">
                                    {{item.examTypeName}} - {{item.examName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="this.model.get('allowRemote').value" class="row">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Tempo de espera para o médico em minutos</mat-label>
                            <input [mask]="this.masks.OneTothreeDigits" matInput type="text"
                                formControlName="watingTime">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Tipo de finalização para o paciente</mat-label>
                            <mat-select (selectionChange)="updateRequirements($event)"
                                formControlName="finalizationType">
                                <mat-option *ngFor="let item of listFinalizationType" [value]="item.idFinalizationType">
                                    {{item.finalizationType}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label> Número máximo de retornos à fila</mat-label>
                            <input formControlName="maxReturns" matInput type="number">
                            <mat-error *ngIf="model.get('maxReturns').invalid">Selecione um valor entre 1 e
                                5</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="this.model.get('allowRemote').value" class="row">
                    <div class="col-12 line-separator"></div>
                </div>
                <div class="card-list" *ngIf="rooms != null">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of rooms;let index = index;">
                            <div class="card-item">
                                <div class="infos">
                                    <span class="primary">{{item.serviceName + " - " + item.sectorName +" - "+
                                        item.roomName}}</span>
                                    <span class="secundary">
                                        <b>URL: </b> <a type="button" class="complete-link"
                                            [href]="urlBase +'S'+item.idSector+'R'+ item.idRoom"
                                            target="_blank">{{urlBase
                                            +'S'+item.idSector+'R'+ item.idRoom}}</a>
                                        <button matTooltip="Copiar" aria-label="Copiar" class="copy-to-clipboard"
                                            type="button"
                                            [cdkCopyToClipboard]="urlBase +'S'+item.idSector+'R'+ item.idRoom"
                                            [cdkCopyToClipboardAttempts]="5" (click)="onCopy('Item copiado')">
                                            <mat-icon class="copy-icons">content_copy</mat-icon>
                                        </button>
                                    </span>
                                    <span class="secundary">
                                        <b>PIN: </b>
                                        <button matTooltip="Copiar" aria-label="Copiar"
                                            class="copy-to-clipboard copy-to-clipboard-gray" type="button"
                                            [cdkCopyToClipboard]="this.model.get('pin').value"
                                            [cdkCopyToClipboardAttempts]="5" (click)="onCopy('Item copiado')">
                                            <label>{{this.model.get('pin').value}}</label>
                                        </button>
                                        <button matTooltip="Copiar" aria-label="Copiar" class="copy-to-clipboard"
                                            type="button" [cdkCopyToClipboard]="this.model.get('pin').value"
                                            [cdkCopyToClipboardAttempts]="5" (click)="onCopy('Item copiado')">
                                            <mat-icon class="copy-icons">content_copy</mat-icon>
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <a mat-flat-button type="button" routerLink="/medic/medic-config" color="accent"
                            class=" btn-block">
                            Cancelar
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>

</div>