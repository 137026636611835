import { ReturnStruct } from "src/app/shared/structs/return.struct";

export class SectorRequest extends ReturnStruct {
    public sectorName: string;
    public description: string;

    public isActive: boolean;

    public idService: number;
    public idSpecialityAttendance: number;
}