<div class="totem">
    <header>
        <span *ngIf="!authenticated">
            <img class="brand" src="assets/images/logo-branco.png">
        </span>

        <h3 *ngIf="authenticated" class="header__h3">{{healthUnitStruct? healthUnitStruct.healthUnitName : null}}</h3>
    </header>
    <div *ngIf="!onlineOffline" class="offline">
        <span><mat-icon class="offline--icon">wifi_off</mat-icon>Você esta sem conexão com a internet no momento.</span>
    </div>
    <app-pin-nps-totem *ngIf="!authenticated" (onAuthenticated)="checkPin($event)"
        [isLoading]="isLoading"></app-pin-nps-totem>
    <app-cpf-nps-totem *ngIf="authenticated && isToRequestCpf && !logged" (findCpf)="checkLogin($event)"
        [isLoading]="isLoading" [idHealthUnit]="idHealthUnit" [idTotem]="idTotem">
    </app-cpf-nps-totem>
    <app-voting-nps-totem *ngIf="(authenticated && logged) || (authenticated && !isToRequestCpf)" [idTotem]="idTotem"
        [description]="this.totemNps.npsText" [idHealthUnit]="idHealthUnit" [cpf]="cpf" (closeEndModal)="checkEnd($event)"></app-voting-nps-totem>
</div>