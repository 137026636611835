import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { LocationByModuleService } from 'src/app/shared/services/API/flow/location-by-module.service';
import { MedicConfigService } from 'src/app/shared/services/API/medic/medic-config.service';
import { SectorStruct } from 'src/app/shared/services/structs/flow/sector.struct';
import { CompleteMedicConfigStruct } from 'src/app/shared/services/structs/medic/complete-medic-config.struct';
import { UtilService } from 'src/app/shared/services/util.service';
import { HealthUnitMedicalProcedureModalComponent } from './health-unit-medical-procedure-modal/health-unit-medical-procedure-modal.component';
import { MedicConfigDeleteModalComponent } from './medic-config-delete-modal/medic-config-delete-modal.component';
import { HealthUnitDischargeOutcomeService } from 'src/app/shared/services/API/medic/health-unit-discharge-outcome.service';
import { HealthUnitMemedPrescriptionService } from 'src/app/shared/services/API/medic/health-unit-memed-prescription.service';
import { HealthUnitDisableExternalRequestService } from 'src/app/shared/services/API/medic/health-unit-external-request.service';
import { HealthUnitBlockAllergicPrescriptionService } from 'src/app/shared/services/API/medic/health-unit-block-allergic-prescription.service';

@Component({
  selector: 'app-medic-config-list',
  templateUrl: './medic-config-list.component.html',
  styleUrls: ['./medic-config-list.component.css']
})
export class MedicConfigListComponent implements OnInit {

  constructor(private medicConfigService: MedicConfigService,
    private router: Router,
    private alertService: AlertService,
    private utilService: UtilService,
    public dialog: MatDialog,
    private healthUnitDischargeOutcomeService: HealthUnitDischargeOutcomeService,
    private locationByModuleService: LocationByModuleService,
    private healthUnitMemedPrescription: HealthUnitMemedPrescriptionService,
    private healthUnitDisableExternalRequestService: HealthUnitDisableExternalRequestService,
    private healthUnitBlockAllergicPrescriptionService: HealthUnitBlockAllergicPrescriptionService, 
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.medic;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.medic_config;

  public listConfig: CompleteMedicConfigStruct[];
  public isLoading: boolean;
  public isActive: boolean;
  public searchText: string;
  public listSector: SectorStruct[];
  public listIdSector: number[];
  public hasProcedure: boolean = false;
  public dischargeOutcome: boolean;
  public memedPrescription: boolean;
  public isDisableExternalRequest: boolean;
  public blockAllergicPrescription: boolean;

  ngOnInit(): void {
    this.isLoading = true;
    this.getSectors();
    this.search();
  }
  search() {
    this.isLoading = true;
    if (this.searchText == "")
      this.searchText = null;

    let jsonListIdSector = null;
    if(this.listIdSector)
       jsonListIdSector = JSON.stringify(this.listIdSector)

    this.medicConfigService.listAllMedicConfig(this.searchText, jsonListIdSector).subscribe({
      next:(response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
  
        this.listConfig = response.listMedicConfig;
        this.hasProcedure = response.hasProcedure;
        this.dischargeOutcome = response.dischargeOutcome;
        this.memedPrescription = response.memedPrescription;
        this.isDisableExternalRequest = response.isDisableExternalRequest;
        this.blockAllergicPrescription = response.blockAllergicPrescription;
  
        this.isLoading = false;
      },
      error:(error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }

  openModal(idMedicConfig){
    const dialogRef = this.dialog.open(MedicConfigDeleteModalComponent, {
      data: {
        idMedicConfig: idMedicConfig
      },
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.deleteMedicConfig){
        this.search();
      }
    });
  }

  getSectors() {
    this.locationByModuleService.listRoomByModule(MenuModuleEnum.medic).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.listSector = response.sectors
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  toggleHasProcedure() {
    const dialogRef = this.dialog.open(HealthUnitMedicalProcedureModalComponent, {
      data: {
        hasProcedure: this.hasProcedure,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if(!result || !result.changeConfig){
        this.hasProcedure = !this.hasProcedure;
      }
    });
  }

  toggleDischargeOutcome(){
    this.isLoading = true;
    this.healthUnitDischargeOutcomeService.postDischargeOutcomeConfig().subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.isLoading = false;
      this.alertService.show('Sucesso', "Configuração alterada com sucesso!", AlertType.success); 
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  toggleMemedPrescription(){
    this.isLoading = true;
    this.healthUnitMemedPrescription.postMemedPrescriptionConfig().subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.isLoading = false;
      this.alertService.show('Sucesso', "Configuração alterada com sucesso!", AlertType.success); 
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  toggleExternalRequest(){
    this.isLoading = true;
    this.healthUnitDisableExternalRequestService.postDisableExternalRequestConfig().subscribe({
      next:(response)=>{
      
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
  
        this.isLoading = false;
        this.alertService.show('Sucesso', "Configuração alterada com sucesso!", AlertType.success); 
      },
      error:(error)=>{
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  toggleAllergicPrescription(){
    this.isLoading = true;
    this.healthUnitBlockAllergicPrescriptionService.postBlockAllergicPrescriptionConfig().subscribe({
      next:(response)=>{
      
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
  
        this.isLoading = false;
        this.alertService.show('Sucesso', "Configuração alterada com sucesso!", AlertType.success); 
      },
      error:(error)=>{
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}
