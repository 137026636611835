import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { PanelService } from 'src/app/shared/services/API/panel/panel.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { Panel } from '../../../shared/services/responses/panel/list-panel.response';
import { PanelDeleteModalComponent } from './panel-delete-modal/panel-delete-modal.component';
import { environment } from 'src/environments/environment';
import { ChangePinConfirmModalComponent } from 'src/app/shared/components/change-pin-confirm-modal/change-pin-confirm-modal.component';

@Component({
  selector: 'app-panel-list',
  templateUrl: './panel-list.component.html',
  styleUrls: ['./panel-list.component.css']
})
export class PanelListComponent implements OnInit {

  constructor(private panelService: PanelService,
    private alertService: AlertService,
    private utilService: UtilService,
    public dialog: MatDialog) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.master;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.master_panel;

  public listPanel: Panel[];
  public isLoading: boolean = true;
  public isActive: boolean;
  public searchText: string;
  ip: string = '192.168.0.2';

  ngOnInit(): void {
    this.search();

    setTimeout(() => { this.getIP(); }, 2000);
  }

  getIP() {
    this.utilService.getIP().subscribe({
      next: (response) => {
        if (response && response.ip) {
          this.ip = response.ip;
        }
      }
    });
  }

  search() {
    this.isLoading = true;
    if (this.searchText == "")
      this.searchText = null;

    this.panelService.listPanel(this.searchText, this.isActive).subscribe({
      next: (response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listPanel = response.listPanel;

        this.listPanel.forEach(x => { x.panelUrl = environment.urlApiBaseUI + "client/display/" + x.idPanel; });

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }

  openModal(idPanel) {
    const dialogRef = this.dialog.open(PanelDeleteModalComponent, {
      data: {
        idPanel: idPanel,
        ip: this.ip
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.deletePanel) {
        this.search();
      }
    });
  }

  onCopy(text) {
    this.alertService.show('Sucesso', text, AlertType.success);
  }

  openUpdatePinModal(idPanel) {
    const dialogRef = this.dialog.open(ChangePinConfirmModalComponent, {
      data: {
        equipment: 'Painel'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.changePin) {
        this.updatePanelPin(idPanel);
      }
    });
  }

  updatePanelPin(idPanel: number) {
    this.isLoading = true;
    this.panelService.updatePanelPin(idPanel, this.ip).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.alertService.show('Sucesso', "PIN Alterado com sucesso!", AlertType.success);
        this.search();
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}