import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { GuideBatchService } from 'src/app/shared/services/API/private-billing/guide-batch.service';
import { LookupService } from 'src/app/shared/services/API/private-billing/lookup.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { HealthcareAgreementStruct } from 'src/app/shared/services/structs/private-billing/healthcare-agreement.struct';
import { FiltersToGuideBatchModalComponent } from './filters-to-guide-batch-modal/filters-to-guide-batch-modal.component';
import { GuideType } from 'src/app/shared/services/models/private-billing/guide-type.model';
import { SelectSendDateModalComponent } from './select-send-date-modal/select-send-date-modal.component';
import { PostGuideBatchRequest } from 'src/app/shared/services/requests/private-billing/post-guide-batch.request';
import { Router } from '@angular/router';
import { GuideToBatchStruct } from 'src/app/shared/services/structs/private-billing/guide-to-batch.struct';

@Component({
  selector: 'app-guide-batch-register',
  templateUrl: './guide-batch-register.component.html',
  styleUrls: ['./guide-batch-register.component.css']
})
export class GuideBatchRegisterComponent implements OnInit {
  constructor(private alertService: AlertService,
    public dialog: MatDialog,
    private guideBatchService: GuideBatchService,
    private lookupService: LookupService,
    private router: Router,) {
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.billing;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.tiss_batch_control;

  public isLoading: boolean = false;

  public beginDate: Date = null;
  public endDate: Date = null;
  public idHealthcareAgreement: number = null;
  public idGuideType: number = null;

  public listHealthcareAgreementStruct: HealthcareAgreementStruct[] = [];
  public listGuideType: GuideType[] = [];

  public listGuideToBatchStruct: GuideToBatchStruct[] = [];

  public listSelectedAllChecked: number[] = [];

  public listIdGuideSelected: Map<number, number> = new Map();
  public dateSendGuideBatch: Date = null;

  public isCheckedAll: boolean = false;
  public isIndeterminateChecked: boolean = false;

  public pageIndex: number = 0;
  public pageSize: number = 50;
  public fullListSize: number;

  ngOnInit(): void {
    this.getLookup();
  }

  submit() {
    if (this.isLoading)
      return;

    if (this.listIdGuideSelected.size == 0) {
      this.alertService.show('Alerta', "É preciso selecionar pelo menos uma guia para gerar o lote.", AlertType.warning);
      this.isLoading = false;
      return;
    }

    let request: PostGuideBatchRequest = new PostGuideBatchRequest();    

    request.datetimeSendGuideBatch = this.dateSendGuideBatch;
    let timeZoneOffset: number = request.datetimeSendGuideBatch.getTimezoneOffset();
    request.datetimeSendGuideBatch.setMinutes(request.datetimeSendGuideBatch.getMinutes() - timeZoneOffset);

    request.idGuideType = this.idGuideType;
    request.idHealthcareAgreement = this.idHealthcareAgreement;   

    this.listIdGuideSelected.forEach((value, key) => {
      request.listIdGuide.push(key);
    });

    this.post(request);
  }

  selectSendDate() {
    if (this.isLoading)
      return;

    const dialogRef = this.dialog.open(SelectSendDateModalComponent, {
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.submit) {
          this.dateSendGuideBatch = result.dateSendGuideBatch;

          this.submit();
        }
      }
    });
  }

  post(request: PostGuideBatchRequest) {
    if (this.isLoading)
      return;

    this.isLoading = true;

    this.guideBatchService.CreateGuideBatch(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.alertService.show('Sucesso', "Lote salvo com sucesso!", AlertType.success);
        this.router.navigate(['/billing/tiss-batch-control']);
        this.isLoading = false;

      },
      error: (error) => {
        this.throwException(error);
        return;
      }
    });
  }

  back(){
    if (this.isLoading)
      return;

    this.router.navigate(['/billing/tiss-batch-control']);
  }

  getLookup() {
    if (this.isLoading)
      return;

    this.isLoading = true;

    this.lookupService.getLookupToGuideBatchList().subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.listGuideType = response.listGuideType;
        this.listHealthcareAgreementStruct = response.listHealthcareAgreementStruct;
        this.isLoading = false;

        this.openFiltersToGuideBatchModal();
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  openFiltersToGuideBatchModal() {
    if (this.isLoading)
      return;

    let isUpdate: boolean = false;

    if (Number.isSafeInteger(this.idGuideType) && Number.isSafeInteger(this.idHealthcareAgreement) && this.beginDate && this.endDate)
      isUpdate = true;

    const dialogRef = this.dialog.open(FiltersToGuideBatchModalComponent, {
      data: {
        idGuideType: this.idGuideType,
        idHealthcareAgreement: this.idHealthcareAgreement,
        beginDate: this.beginDate,
        endDate: this.endDate,
        listGuideType: this.listGuideType,
        listHealthcareAgreementStruct: this.listHealthcareAgreementStruct,
        isUpdate: isUpdate
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.search) {
          this.idGuideType = result.idGuideType;
          this.idHealthcareAgreement = result.idHealthcareAgreement;
          this.beginDate = result.beginDate;
          this.endDate = result.endDate;
          this.pageIndex = 0;

          this.listSelectedAllChecked = [];
          this.listIdGuideSelected.clear();

          this.isIndeterminateChecked = false;
          this.isCheckedAll = false;

          this.search();
        }
      }
    });
  }

  search() {
    if (this.isLoading)
      return;

    this.isLoading = true;

    let beginDateString: string = this.setDateToSendoInSearch(this.beginDate);
    let endDateString: string = this.setDateToSendoInSearch(this.endDate);

    this.guideBatchService.GetAllGuideToGuideBatch(this.idHealthcareAgreement, this.idGuideType, beginDateString, endDateString, this.pageIndex).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.listGuideToBatchStruct = response.listGuideToBatchStruct;
        this.fullListSize = response.pageSize;
        this.isCheckedAll = false;
        this.isIndeterminateChecked = false;

        if (this.listSelectedAllChecked?.includes(this.pageIndex))
          this.isCheckedAll = true;

        this.listGuideToBatchStruct?.forEach(item => {

          if (this.listIdGuideSelected.has(item.idGuide)) {
            item.isSelected = true;
            this.listIdGuideSelected.set(item.idGuide, this.pageIndex);
          }

          else if (this.isCheckedAll) {
            this.isIndeterminateChecked = true;
          }
        });

        this.isLoading = false;

      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  checkOrUncheckAll() {
    if (this.isLoading)
      return;

    if (this.isCheckedAll == true && this.listIdGuideSelected.size >= 100) {
      this.isCheckedAll = false;
      this.alertService.show('Alerta', "Número máximo de guias em um lote atingido.", AlertType.warning);
      return;
    }

    this.isIndeterminateChecked = false;
    let index: number = this.listSelectedAllChecked.indexOf(this.pageIndex);

    if (index > -1 && this.isCheckedAll != true) {
      this.listSelectedAllChecked.splice(index, 1);

      this.listGuideToBatchStruct.forEach(item => {
        this.listIdGuideSelected.delete(item.idGuide);
        item.isSelected = false;
      });
    }

    else if (index === -1 && this.isCheckedAll == true) {
      this.listSelectedAllChecked.push(this.pageIndex);

      for (var i = 0; i < this.listGuideToBatchStruct.length; i++) {
        let item: GuideToBatchStruct = this.listGuideToBatchStruct[i];
        this.listIdGuideSelected.set(item.idGuide, this.pageIndex);
        item.isSelected = true;

        if (this.listIdGuideSelected.size >= 100) {
          this.alertService.show('Alerta', "Número máximo de guias em um lote atingido.", AlertType.warning);
          break;
        }
      }
    }
  }

  checkOrUncheckOne(guideReportStruct: GuideToBatchStruct) {
    if (this.isLoading)
      return;

    if (guideReportStruct.isSelected != true)
      this.listIdGuideSelected.delete(guideReportStruct.idGuide);

    else
      this.listIdGuideSelected.set(guideReportStruct.idGuide, this.pageIndex);

    this.isIndeterminateChecked = false;

    if (this.isCheckedAll) {
      let isAllNotSelected: boolean = this.listGuideToBatchStruct.every(item => !item.isSelected);
      let index: number = this.listSelectedAllChecked.indexOf(this.pageIndex);

      if (isAllNotSelected && index > -1) {
        this.isCheckedAll = false;
        this.listSelectedAllChecked.splice(index, 1);
      }

      else {
        this.listGuideToBatchStruct.forEach(item => {

          if (!this.listIdGuideSelected.has(item.idGuide))
            this.isIndeterminateChecked = true;
        });
      }
    }

    if (this.listIdGuideSelected.size >= 100) {
      this.alertService.show('Alerta', "Número máximo de guias em um lote atingido.", AlertType.warning);
      return;
    }
  }

  setDateToSendoInSearch(selectedDate: Date = null): string {
    if (!selectedDate)
      return null;

    let copyOfDate: Date = new Date(selectedDate);
    let timeZoneOffset: number = copyOfDate.getTimezoneOffset();
    copyOfDate.setMinutes(copyOfDate.getMinutes() - timeZoneOffset);

    return copyOfDate.toISOString();
  }

  changePage(pageIndex: number) {
    this.pageIndex = pageIndex;
    this.search();
  }

  private throwException(error: string) {
    this.alertService.show('Erro inesperado', error, AlertType.error);
    this.isLoading = false;
  }
}