<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <a class="back-link" routerLink="/pharmacy/kit"><mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Kit</a>
        <h1>Cadastrar Kit</h1>
        <app-loading-progress-bar *ngIf="this.loadProgressBarPile?.length > 0"></app-loading-progress-bar>
        <div class="white-body">
            <form class="form" [formGroup]="model"  (ngSubmit)="submit()">
                
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Código do Kit</mat-label>
                            <input matInput type="text" formControlName="kitCode">
                            <mat-error *ngIf="model.get('kitCode').invalid">Informe o nome</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome</mat-label>
                            <input matInput type="text" formControlName="kitName">
                            <mat-error *ngIf="model.get('kitName').invalid">Informe o nome</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Tipo de Kit</mat-label>
                            <mat-select [multiple]="false" formControlName="idKitType">
                                <mat-option *ngFor="let item of listKitTypes" [value]="item.idKitType">
                                    {{item.kitTypeName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="title-header">
                    <h1>Itens: </h1>
                </div>
                <div *ngFor="let item of listItemFields">
                    <app-kit-item-select 
                        [kitItemSelectStruct]="item"
                        (addItemEvent)="addItem($event)" (removeItemEvent)="removeItem($event)">
                    </app-kit-item-select>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span *ngIf="this.loadActionPile?.length == 0">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="this.loadActionPile?.length > 0"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <a mat-flat-button type="button" routerLink="/pharmacy/kit" color="accent" class=" btn-block">
                            Cancelar
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>
    
</div>
