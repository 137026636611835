<div class="container-generic">

    <app-menu [isClassification]="true" [menuModuleEnum]='menuModuleEnum'
        [menuFunctionalityEnum]='menuFunctionalityEnum' [hasSecundaryHeader]="true" [episode]="idEpisode"
        [discriminators]="discriminatorValues" [hasCachedModule]="true" [cachedModuleName]="'classification'"
        [gravityColorCode]="data.priority.coloerHex" [painColorCode]="painColorCode"
        [gravity]="data.priority.priorityColorName" (restartMeasurers)="restartMeasurers()"></app-menu>

    <div [ngClass]="{'container-body-secundary-header': idEpisode !== null, 'container-body': idEpisode === null}">
        <h1>Dados Complementares</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()" [appUppercaseForm]="this.setUppercaseText">
                <div class="row">
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Temperatura</mat-label>
                            <input [mask]="masks.temperature" (change)="temperaturaOnChange($event)"
                                [value]="discriminatorValues[enumTemperatura]" type="text" matInput
                                formControlName="temperature">
                            <span matSuffix>ºC</span>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>FR</mat-label>
                            <input [mask]="masks.twoDigits" (change)="frequenciaRespiratoriaOnChange($event)"
                                type="text" matInput formControlName="respiratoryFrequency">
                            <span matSuffix>irpm</span>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>FC</mat-label>
                            <input [mask]="masks.threeDigits" (change)="frequenciaCardiacaOnChange($event)"
                                [value]="discriminatorValues[enumFrequenciaCardiaca]" type="text" matInput
                                formControlName="heartRate">
                            <span matSuffix>bpm</span>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>GC</mat-label>
                            <input [mask]="masks.threeDigits" (change)="glicemiaOnChange($event)"
                                [value]="discriminatorValues[enumGlicemia]" type="text" matInput
                                formControlName="glucose">
                            <span matSuffix>mg/dL</span>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>PA</mat-label>
                            <input [mask]="masks.pressure" (change)="pressaoArterialOnChange($event)"
                                [value]="discriminatorValues[enumPressaoArterial]" type="text" matInput
                                formControlName="bloodPressure">
                            <span matSuffix>mmHG</span>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>SatO2</mat-label>
                            <input [mask]="masks.threeDigits" (change)="saturacaoOnChange($event)"
                                [value]="discriminatorValues[enumSaturacao]" type="text" matInput
                                formControlName="saturation">
                            <span matSuffix>%</span>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Peso</mat-label>
                            <input matInput type="text" formControlName="weight" [mask]="masks.weight">
                            <span matSuffix>kg</span>
                            <mat-error
                                *ngIf="model.get('weight')?.invalid">{{model.get('weight')?.errors?.invalidWeight}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Altura</mat-label>
                            <input matInput [mask]="masks.height" type="text" formControlName="height">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 row-escolha-triagem ">
                        <span>Risco de Queda</span>
                        <input [value]="true" class="radio-sim" type="radio" id="radioFallRisk-sim"
                            formControlName="radioFallRisk" name="radioFallRisk">
                        <label class="label-radio-sim" for="radioFallRisk-sim"><img
                                src="assets/images/icone-radio-sim.svg" /></label>
                        <input [value]="false" class="radio-nao" type="radio" id="radioFallRisk-nao"
                            formControlName="radioFallRisk" name="radioFallRisk">
                        <label class="label-radio-nao" for="radioFallRisk-nao"><img
                                src="assets/images/icone-radio-nao.svg" /></label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12">
                        <app-allergy-tag [inputListTags]="allergies" [listTags]="allergiesToSave"
                            [isError]="allergyError" (updateAllergiesToSave)="updateAllergiesToSave($event)"
                            *ngIf="!isFirstLoading">
                        </app-allergy-tag>
                    </div>
                </div>
                <div class="row">
                    <!-- <div class="col-6 col-sm-6 col-md-4">
                        <app-select-2 [formControlField]="'listIdAllergies'" (selectedValue)="addItemAllergies($event)"
                            [preSelectedlist]="listPreselectedAllergy" [multiple]="true" [url]="urlListAllergies"
                            [idField]="'idAllergy'" [nameField]="'allergyName'" [labelField]="'Alergias'">
                        </app-select-2>
                    </div> -->
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Suspeitas</mat-label>
                            <mat-select formControlName="idSuspicion" (selectionChange)="suspicionOnChange($event)">
                                <mat-option *ngFor="let item of listSuspicion" [value]="item.idSuspicion">
                                    {{item.suspicionName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Notificação de agravo</mat-label>
                            <mat-select formControlName="idGrievanceNotification">
                                <mat-option [value]="null">Nenhum</mat-option>
                                <mat-option *ngFor="let item of listGrievanceNotification"
                                    [value]="item.idGrievanceNotification">{{item.grievanceNotificationName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Necessidades especiais</mat-label>
                            <mat-select formControlName="idSpecialNecessity"
                                (ngModelChange)="onChangeSpecialNecessity($event)">
                                <mat-option value="-1">Nenhuma</mat-option>
                                <mat-option *ngFor="let item of specialNecessityList" [value]="item.idSpecialNecessity">
                                    {{item.specialNecessityName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 care-line-priority" *ngIf="listCareLinePriority.length > 0">
                        <mat-form-field appearance="outline">
                            <mat-label>Linha de Cuidado em prioridade</mat-label>
                            <mat-select formControlName="idCareLinePriority">
                                <mat-option *ngFor="let item of listCareLinePriority" [value]="item.idCareLinePriority">
                                    {{item.careLinePriorityName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div [class.disabled]="this.careLineIsConfirmedOrDenied" class="row-escolha-care"
                            [class.hovered]="hovered" [class.clicked]="clicked" (mouseover)="hovered = true"
                            (mouseout)="hovered = false" (click)="removeCareLine()"
                            [matTooltip]="this.model.get('idCareLinePriority').value ? 'Remover suspeita' : ''"
                            *ngIf="!isHealthGuidelineCareLine">
                            <label class="label-radio-nao label-care-line-remove"
                                [class.enabled]="this.model.get('idCareLinePriority').value !== null">
                                <img src="assets/images/icone-radio-nao.svg" />
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div *ngIf="idSpecialNecessity == idSpecialNecessityOthers" class="col-12 col-sm-12 col-md-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Descreva a necessidade especial</mat-label>
                            <textarea matInput formControlName="specialNecessity"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Comorbidades</mat-label>
                            <mat-select formControlName="listIdComorbodity" multiple
                                (selectionChange)="checkOutros($event.value)">
                                <mat-option *ngFor="let item of listComorbidity" [value]="item.idComorbidity"
                                    [disabled]="selectedDeniesComorbidities && item.idComorbidity != deniesComorbiditiesEnum">
                                    {{item.comorbidityName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row" *ngIf="hasComorbidityOutros">
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Outras Comorbidades</mat-label>
                            <textarea matInput formControlName="diseaseHistory"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Observação</mat-label>
                            <textarea matInput formControlName="observation"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="offset-6 col-6 offset-md-8 col-md-4">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            Avançar <mat-icon aria-hidden="false" aria-label="Plus">arrow_forward</mat-icon>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>

</div>