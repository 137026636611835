import { MetaDataKeyValueStruct } from "../../structs/meta-data/meta-data-key-value.struct";

export class PatientPatchRequest{
    public idPatient:number;
    public idEpisode:number;
    public patientName:string;
    public cpf:string;
    public birthDate:Date;
    public cns:string;
    public socialName:string;
    public idGender:number;
    public priorities:number[];
    public isUnidentifiedPatient:boolean;
    public unidentifiedPatientDescription:string;
    public idAgeGroup: number;
    public motherName: string;
    public idNatureOfAttendance: number;
    public notMandatoryCpf: boolean;
    public notMandatoryCns: boolean;
    public phone: string;
    public idArrivalType: number;
    public idOrigin: number;
    public metaData: MetaDataKeyValueStruct[] = [];
    public listIdComorbidity: number[] = [];

    //Dados da queue
    public idSector: number;
    public idService: number;
}