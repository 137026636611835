import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { PatientHistoryRoutingModule } from './patient-history-routing.module';
import { PatientHistoryComponent } from './pages/patient-history/patient-history.component';
import { SharedModule } from '../shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { NgxMaskModule } from 'ngx-mask';
import { MatCheckbox, MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatTree, MatTreeModule } from '@angular/material/tree';
import { MatRadioButton, MatRadioModule } from '@angular/material/radio';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { PatientHistoryListComponent } from './pages/patient-history-list/patient-history-list.component';
import { PatientHistoryDetailsComponent } from './pages/patient-history-details/patient-history-details.component';
import { MedicalCareAccordionComponent } from './pages/patient-history-details/medical-care-accordion/medical-care-accordion.component';
import { NursingHistoryAccordionComponent } from './pages/patient-history-details/nursing-history-accordion/nursing-history-accordion.component';
import { ReturnMotiveModalComponent } from './pages/patient-history-details/return-motive-modal/return-motive-modal.component';
import { MultiProfessionalCareAccordionComponent } from './pages/patient-history-details/multi-professional-care-accordion/multi-professional-care-accordion.component';
import { DocumentSendModalComponent } from './pages/patient-history-details/document-send-modal/document-send-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PatientListComponent } from './pages/patient-list/patient-list.component';
import { SadtConductComponent } from './pages/patient-history-details/sadt-conduct/sadt-conduct.component';
import { GrievanceNotificationComponent } from './pages/grievance-notification/grievance-notification.component';
import { GrievanceNotificationGenerateComponent } from './pages/grievance-notification-generate/grievance-notification-generate.component';
import { TissGuideAppointmentHistoryComponent } from './pages/patient-history-details/tiss-guide-appointment-history/tiss-guide-appointment-history.component';
import { TissGuideAdmissionHistoryComponent } from './pages/patient-history-details/tiss-guide-admission-history/tiss-guide-admission-history.component';
import { AdmissionProcedureHistoryComponent } from './pages/patient-history-details/tiss-guide-admission-history/admission-procedure-history/admission-procedure-history.component';
import { TissGuideFeesHistoryComponent } from './pages/patient-history-details/tiss-guide-fees-history/tiss-guide-fees-history.component';
import { FeesProcedureFormHistoryComponent } from './pages/patient-history-details/tiss-guide-fees-history/fees-procedure-form-history/fees-procedure-form-history.component';
import { TissGuideSpSadtHistoryComponent } from './pages/patient-history-details/tiss-guide-sp-sadt-history/tiss-guide-sp-sadt-history.component';
import { SpSadtRequestedProcedureFormHistoryComponent } from './pages/patient-history-details/tiss-guide-sp-sadt-history/sp-sadt-requested-procedure-form-history/sp-sadt-requested-procedure-form-history.component';
import { SpSadtExecutedProcedureTableHistoryComponent } from './pages/patient-history-details/tiss-guide-sp-sadt-history/sp-sadt-executed-procedure-table-history/sp-sadt-executed-procedure-table-history.component';
import { DispensationAccordionComponent } from './pages/patient-history-details/dispensation-accordion/dispensation-accordion.component';
import { SolutionDispensationAccordionComponent } from './pages/patient-history-details/solution-dispensation-accordion/solution-dispensation-accordion.component';
import { MatPaginatorModule } from '@angular/material/paginator';

@NgModule({
  declarations: [
    PatientHistoryComponent,
    PatientHistoryListComponent,
    PatientHistoryDetailsComponent,
    MedicalCareAccordionComponent,
    NursingHistoryAccordionComponent,
    ReturnMotiveModalComponent,
    MultiProfessionalCareAccordionComponent,
    DocumentSendModalComponent,
    PatientListComponent,
    SadtConductComponent,
    GrievanceNotificationComponent,
    GrievanceNotificationGenerateComponent,
    TissGuideAppointmentHistoryComponent,
    TissGuideAdmissionHistoryComponent,
    AdmissionProcedureHistoryComponent,
    TissGuideFeesHistoryComponent,
    FeesProcedureFormHistoryComponent,
    TissGuideSpSadtHistoryComponent,
    SpSadtRequestedProcedureFormHistoryComponent,
    SpSadtExecutedProcedureTableHistoryComponent,
    DispensationAccordionComponent,
    SolutionDispensationAccordionComponent,
  ],
  imports: [
    PatientHistoryRoutingModule,
    CommonModule,
    SharedModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonModule,
    NgxMaskModule.forRoot(),
    MatCheckboxModule,
    MatTreeModule,
    MatRadioModule,
    ClipboardModule,
    MatTooltipModule,
    MatToolbarModule,
    NgxMatFileInputModule,
    CdkAccordionModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatDialogModule,
    MatPaginatorModule,
  ],
  providers: [
    AuthGuard,
    MatCheckbox,
    MatCheckboxChange,
    MatTree,
    MatRadioButton,
    DatePipe
  ],
})
export class PatientHistoryModule { }
