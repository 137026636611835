import { AllergyStruct } from "../../structs/medical-record/allergy.struct";

export class MedicineRequest {
     public idMedicinePresentationType: number;
     public idMedicineAdministrationType: number;
     public idMedicineGroup: number;
     public idMedicineSubgroup: number;
     public medicineDescription: string;
     public medicineCode: string;
     public medicinePresentationOther: string;
     public medicineAdministrationOther: string;
     public isActive: boolean;
     public listIdMedicineAdministrationType: number[];
     public idMeasurementUnit: number;
     public idDilution: number;
     public fastTrack: boolean;
     public listAllergy: AllergyStruct[];
     public minimumAmount: number;
     public idKit: number;
}