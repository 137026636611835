import { BillingDataRequest } from "../billing/billing-data-request";
import { EpisodeCareLineRequest } from "../care-line/episode-care-line.request";
import { EpisodeStatusRequest } from "../medical-record/episode-status.request";
import { MedicalCareRequest } from "../medical-record/medical-care.request";
import { MedicalCareReportRequest } from "./medical-care-report.request";
import { GuideRequest } from "../private-billing/guide.request";

export class MedicalRecordTelemedicineRequest {
    public medicalRecordRequest: MedicalCareRequest;
    public medicalCareReportRequest: MedicalCareReportRequest;
    public billingDataRequest: BillingDataRequest;
    public episodeStatusRequest: EpisodeStatusRequest;
    public episodeCareLineRequest: EpisodeCareLineRequest;
    public guideRequest: GuideRequest;
}