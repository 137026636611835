export enum AihFieldsMaxLengthEnum {
    nameRequesterEstablishment = 83,
    cnesRequesterEstablishment = 20,
    nameExecutingEstablishment = 83,
    cnesExecutingEstablishment = 20,
    patientName = 81,
    patientPromptuary = 21,
    patientRace = 12,
    patientMotherName = 70,
    responsibleName = 70,
    patientAdress = 128,
    patientCity = 90,
    patientIbgeCityCode = 12,
    clinicalSymptoms = 893,
    conditionsForInternment = 223,
    mainDiagnosticResults = 223,
    initialDiagnostic = 44,
    cid10Main = 19,
    cid10Secondary = 19,
    cid10AssociatedCauses = 19,
    procedureRequestedDescription = 204,
    procedureCode = 20,
    procedureClinic = 20,
    procedureInternmentCharacter = 19,
    procedureProfessionalDocument = 41,
    procedureProfessionalName = 52,
    insurerCnpj = 43,
    insurerTicketNumber = 16,
    insurerSeries = 10,
    companyCnpj = 43,
    companyCnae = 16,
    cbor = 10
  }
  