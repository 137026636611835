import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebsocketAttendanceService {
  url: string = environment.urlWebsocket;
  //url: string = 'localhost:8888';
  websocketConnection(): Socket{ 
    return io(`${this.url}`, {
      reconnectionAttempts: 360,
      reconnectionDelay: 10000,
      reconnectionDelayMax: 15000,
      randomizationFactor: 0.1,
      reconnection: true,
      transports: ['websocket', 'polling'],
      path: '/tolifecloud-api-websocket-attendance/socket.io'
    });
  }
}
