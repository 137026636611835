import { Component, OnInit } from '@angular/core';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-integration-iframe',
  templateUrl: './integration-iframe.component.html',
  styleUrls: ['./integration-iframe.component.css']
})
export class IntegrationIframeComponent implements OnInit {

  constructor() { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.integration;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.integration_iframe;
  public urlBase: string = environment.urlApiBaseUI;
  ngOnInit(): void {
  }

}

