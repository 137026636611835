
export class ExpressionRequest{
    public value: string;

    public idConjunction: number;

    public idVariable: number;

    public idLogicalExpression: number;

    public index: number;
}
