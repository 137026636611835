<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <h1>Prescrições e Materiais</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <div class="filters">
                <div class="row" *ngIf="!episode.isUnidentifiedPatient">
                    <div class="col-12 col-md-3">
                        <div class="col-12">
                            <label>Paciente: </label>
                            <p> {{episode.patientSocialName? episode.patientSocialName : episode.patientName}}</p>
                        </div>
                        <div class="col-12">
                            <label>Episódio:</label>
                            <p> {{episode.idEpisode}}</p>
                        </div>
                    </div>
                    <div class="col-12 col-md-3">
                        <div class="col-12">
                            <label>Data de nascimento: </label>
                            <p> {{episode.birthDate | date:'dd/MM/yyyy'}}</p>
                        </div>
                        <div class="col-12" *ngIf="episode.patientAge">
                            <label>Idade: </label>
                            <p> {{episode.patientAge}}</p>
                        </div>
                    </div>
                    <div class="col-12 col-md-3">
                        <label>Alergias: </label>
                        <p> {{episode.allergyGroup? episode.allergyGroup : "Nega alergias"}}</p>
                    </div>
                    <div *ngIf="triage" class="col-12">
                        <app-gravity-tag [gravityName]="triage.gravityName" [idGravity]="triage.idGravity">
                        </app-gravity-tag>
                    </div>
                </div>
                <div class="row" *ngIf="episode.isUnidentifiedPatient">
                    <div class="col-12">
                        <label>Episódio: </label>
                        <p> {{episode.idEpisode}}</p>
                    </div>
                    <div class="col-12">
                        <label>Descrição do paciente: </label>
                        <p> {{episode.unidentifiedPatientDescription}}</p>
                    </div>
                    <div class="col-12" *ngIf="episode.ageGroupName">
                        <label>Faixa etária: </label>
                        <p> {{episode.ageGroupName}}</p>
                    </div>
                    <div class="col-12">
                        <label>Alergias: </label>
                        <p> {{episode.allergyGroup? episode.allergyGroup : "Nega alergias"}}</p>
                    </div>
                    <div class="col-12">
                        <app-gravity-tag [gravityName]="triage.gravityName" [idGravity]="triage.idGravity">
                        </app-gravity-tag>
                    </div>
                </div>
            </div>
            <div *ngIf="listPrescription != null" class="title-header">
                <h1>Prescrições</h1>
                <div class="col-12 col-md-6">
                    <mat-slide-toggle class="indigent-checkbox" [ngModelOptions]="{standalone: true}"
                        (change)="toggleNotDispensedPrescriptions()">
                        {{showNotDispensedPrescriptions ? "Esconder" : "Mostrar"}} prescrições não dispensadas
                    </mat-slide-toggle>
                </div>
            </div>
            <div class="card-list" *ngIf="listPrescription != null">
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <div class="row">
                            <h2>Medicamentos</h2>
                            <div class="col-12 col-md-6" *ngFor="let item of listPrescriptionDisplay">
                                <div class="card-item">
                                    <div class="infos">
                                        <span class="primary">{{item.datetimeInclusion | date:'dd/MM/yyyy HH:mm'}}</span>
                                        <span class="primary">Médico: {{item.userName}}</span>
                                        <span class="secundary">{{item.medicineName}}</span>
                                        <span class="secundary">Quantidade: {{item.quantity}}</span>
                                        <span class="secundary">Via: {{item.administrationTypeName}}</span>
                                        <span class="secundary">Uso: {{item.usage}}</span>
                                        <span class="secundary">Unidade de medida: {{item.measurementUnitName}}</span>
                                        <span *ngIf="item.idFrequency != frequencyEnum.medicCriteria && item.idFrequency != frequencyEnum.ifNecessary" class="secundary">Frequência: {{item.frequencyName}}</span>
                                        <app-generic-display-tag 
                                            [text]="'Frequência: '+item.frequencyName" [color]="genericTagColor"
                                            *ngIf="item.idFrequency == frequencyEnum.medicCriteria || item.idFrequency == frequencyEnum.ifNecessary">
                                        </app-generic-display-tag>
                                        <span class="secundary">Apresentação: {{item.presentationName}}</span>
                                        <span class="secundary">Período: {{item.treatmentPeriodName}}</span>
                                        <span class="secundary">Diluição: {{item.dilutionName}}</span>
                                        <span class="secundary">Observação: {{item.observation}}</span>
                                        <span class="secundary" [ngClass]="{'selected-sector': item.isSelectedSector == true}">
                                            {{item.locationName}}
                                        </span>
                                        <span *ngIf="item.idPrescriptionStatus != prescricaoNaoDispensada"
                                            class="secundary-status last-info">{{item.prescriptionStatus}}</span>
                                        <span *ngIf="item.idPrescriptionStatus == prescricaoNaoDispensada"
                                            class="secundary-status last-info">{{item.prescriptionStatus}}:</span>
                                        <span *ngIf="item.idPrescriptionStatus == prescricaoNaoDispensada"
                                            class="secundary-status last-info">{{item.nonDispensationReason}}</span>
                                    </div>
            
                                    <div class="actions checkbox-actions">
                                        <a *ngIf="item.idPrescriptionStatus == prescricaoEmAberto">
                                            <div class="col-md-6">
                                                <mat-icon aria-hidden="false" aria-label="Editar" (click)="openModal(item)">edit
                                                </mat-icon>
                                            </div>
                                        </a>
                                        <mat-checkbox *ngIf="item.medicalPrescriptionReportName != null && isActive"
                                            [(ngModel)]="item.isChecked" (ngModelChange)="onChangePrescription($event)"
                                            matTooltip="Visualizar medicamento">
                                        </mat-checkbox>
                                        <a *ngIf="item.idPrescriptionStatus == prescricaoEmAberto">
                                            <div class="col-md-6">
                                                <mat-icon aria-hidden="false" aria-label="Não dispensar"
                                                    (click)="openPrescriptionNonDispensationModal(item)">
                                                    do_disturb_alt
                                                </mat-icon>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 divisor">
                        <div class="row">
                            <h2>Soluções Medicamentosas</h2>
                            <div class="col-12 col-md-6" *ngFor="let item of this.listSolutionPrescriptionDisplay">
                                <div class="card-item">
                                    <div class="infos">
                                        <span class="primary">{{item.datetimeInclusion | date:'dd/MM/yyyy HH:mm'}}</span>
                                        <span class="primary">Médico: {{item.userName}}</span>
                                        <span class="secundary" *ngFor="let prescriprtion of item.listPrescription">Medicamento: {{prescriprtion.medicineName}}, {{prescriprtion.quantity}} {{prescriprtion.measurementUnitName}}</span>
                                        <span class="secundary">Via: {{item.administrationTypeName}}</span>
                                        <span class="secundary">Uso: {{item.usage}}</span>
                                        <span *ngIf="item.idFrequency != frequencyEnum.medicCriteria && item.idFrequency != frequencyEnum.ifNecessary" class="secundary">Frequência: {{item.frequencyName}}</span>
                                        <app-generic-display-tag 
                                            [text]="'Frequência: '+item.frequencyName" [color]="genericTagColor"
                                            *ngIf="item.idFrequency == frequencyEnum.medicCriteria || item.idFrequency == frequencyEnum.ifNecessary">
                                        </app-generic-display-tag>
                                        <span class="secundary">Apresentação: {{item.presentationName}}</span>
                                        <span class="secundary">Período: {{item.treatmentPeriodName}}</span>
                                        <span class="secundary">Diluição: {{item.dilutionName}}</span>
                                        <span class="secundary">Observação: {{item.observation}}</span>
                                        <span class="secundary" [ngClass]="{'selected-sector': item.isSelectedSector == true}">
                                            {{item.locationName}}
                                        </span>
                                        <span class="secundary-status last-info">{{item.solutionStatus}}</span>
                                    </div>
            
                                    <div class="actions checkbox-actions">
                                        <a *ngIf="item.idSolutionStatus == prescricaoEmAberto">
                                            <div class="col-md-6">
                                                <mat-icon aria-hidden="false" aria-label="Editar" (click)="openPrescriptionSolutionModal(item)">edit
                                                </mat-icon>
                                            </div>
                                        </a>
                                        <mat-checkbox *ngIf="item.medicalPrescriptionReportName != null && isActive"
                                            [(ngModel)]="item.isChecked" (ngModelChange)="onChangePrescriptionSolution($event)"
                                            matTooltip="Visualizar medicamento">
                                        </mat-checkbox>
                                        <a *ngIf="item.idPrescriptionStatus == prescricaoEmAberto">
                                            <div class="col-md-6">
                                                <mat-icon aria-hidden="false" aria-label="Não dispensar"
                                                    (click)="openPrescriptionNonDispensationModal(item)">
                                                    do_disturb_alt
                                                </mat-icon>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row btn-container">
                    <div class="col-12 col-sm-4 col-md-2" *ngIf="(listPrescriptionReport != null && listPrescriptionReport.length > 0 && isActive && listPrescriptionReportCheck != null) || (listSolutionPrescriptionReport != null && listSolutionPrescriptionReport.length > 0 && isActive && listSolutionPrescriptionDisplayCheck != null)"
                        (click)="openModalReport()">
                        <button mat-flat-button type="button" color="primary" class=" visualize-btn back-btn">
                            <span>Visualizar etiquetas</span>
                        </button>
                    </div>
                    <div class="col-12 col-sm-7 col-lg-4" *ngIf="listPrescriptionReport != null && isActive && listPrescriptionReportCheck != null">
                        <mat-checkbox [ngModelOptions]="{standalone: true}" [(ngModel)]="selectionAll"
                            [checked]="selectionAll" (change)="noneCheck()">
                            Selecionar todos os medicamentos
                        </mat-checkbox>
                    </div>
                </div>
            </div>
            <div *ngIf="requestMaterialList != null" class="title-header">
                <h1>Materiais</h1>
                <div class="col-12 col-md-6">
                    <mat-slide-toggle class="indigent-checkbox" [ngModelOptions]="{standalone: true}"
                        (change)="toggleNotDispensedMaterials()">
                        {{showNotDispensedMaterials ? "Esconder" : "Mostrar"}} materiais não dispensados
                    </mat-slide-toggle>
                </div>
            </div>
            <div class="card-list" *ngIf="requestMaterialList != null">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of requestMaterialListDisplay">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary">{{item.dateTimeInclusion | date:'dd/MM/yyyy HH:mm'}}</span>
                                <span class="primary">Profissional: {{item.userName}}</span>
                                <span class="secundary">Observação: {{item.observation}}</span>
                                <span class="secundary">Procedimento: {{item.nameProcedure}}</span>
                                <span class="secundary">Total de kits: {{item.totalKits}}</span>
                                <span class="secundary">Itens avulsos: {{item.totalMaterial}}</span>
                                <span *ngIf="item.idStatus != materialNaoDispensado"
                                    class="secundary-status last-info">{{item.statusName}}</span>
                                <span *ngIf="item.idStatus == materialNaoDispensado"
                                    class="secundary-status last-info">{{item.statusName}}:</span>
                                <span *ngIf="item.idStatus == materialNaoDispensado"
                                    class="secundary-status last-info">{{item.nonDispensationReason}}</span>
                            </div>
                            <div class="actions" *ngIf="item.idStatus == materialEmAberto">
                                <a>
                                    <div class="col-md-6">
                                        <mat-icon aria-hidden="false" aria-label="Editar" (click)="openMaterialModal(item)">
                                            edit
                                        </mat-icon>
                                    </div>
                                </a>
                                <a>
                                    <div class="col-md-6">
                                        <mat-icon aria-hidden="false" aria-label="Não dispensar"
                                            (click)="openMaterialNonDispensationModal(item)">
                                            do_disturb_alt
                                        </mat-icon>
                                    </div>
                                </a>
    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="row">
                <div class="col-md-2">
                    <button mat-flat-button type="button" routerLink="/pharmacy/prescription"
                        color="accent" class=" btn-block back-btn">
                        Voltar
                    </button>
                </div>
            </div>
        </div>
    
        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>

</div>