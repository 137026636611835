import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ProfessionalProductionAttendmentService } from 'src/app/shared/services/API/billing/professional-production-attendance.service';
import { ProfessionalProductionAttendmentModel } from 'src/app/shared/services/models/billing/professional-production-attendment.model';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-professional-production-attendance-list',
  templateUrl: './professional-production-attendance-list.component.html',
  styleUrls: ['./professional-production-attendance-list.component.css']
})
export class ProfessionalProductionAttendanceListComponent implements OnInit {

  constructor(
    private professionalProductionAttendmentService: ProfessionalProductionAttendmentService,
    private router: Router,
    private alertService: AlertService,
    private utilService: UtilService,
    public dialog: MatDialog
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.billing;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.billing_professionalProductionAttendance;

  public listProfessionalProductionAttendment: ProfessionalProductionAttendmentModel[];
  public isLoading: boolean;
  public isDownloadLoading: boolean;
  public isActive: boolean;
  public searchText: string;
  public idExamType: number;

  ngOnInit(): void {
    this.isLoading = true;
    this.isDownloadLoading = false;
    this.search();
  }
  search(){
    this.isLoading = true;  
    if(this.searchText == "")
    this.searchText = null;
    
    this.professionalProductionAttendmentService.listReportProductionAttendment().subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.listProfessionalProductionAttendment = response.listProfessionalProductionAttendment;  
      this.isLoading = false;    
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  reportExcelDownload(id: number){
    this.isDownloadLoading = true;
    
    this.professionalProductionAttendmentService.getReportProductionAttendment(id).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isDownloadLoading = false;
        return;
      }
      this.downloadFile(response.file64, response.fileName, response.fileMimeType);
    
      this.isDownloadLoading = false;    
    },
    (error)=>{
      console.log(error)
      this.isDownloadLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }
  
  convertBase64ToBlobData(base64Data: string, contentType: string='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', sliceSize=512) {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  downloadFile(file64: string, fileName: string, fileMimeType:string){
    const blobData = this.convertBase64ToBlobData(file64, fileMimeType);
    const blob = new Blob([blobData], { type: fileMimeType });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }
}