import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ProcedureModel } from 'src/app/shared/services/models/medic/procedure-model';
import { MedicalProcedureModel } from 'src/app/shared/services/models/medical-record/medical-procedure.model';
import { MedicalRecordService } from 'src/app/shared/services/API/medical-record/medical-record.service';
import { ProcedureService } from 'src/app/shared/services/API/medic/procedure.service';
import { Router } from '@angular/router';
import { MedicalProcedureResponseModel } from 'src/app/shared/services/models/medical-record/medical-procedure-response.model';
import { ProcedureSigtapService } from 'src/app/shared/services/API/billing/procedure-sigtap.service';
import { ProcedureSigtapModel } from 'src/app/shared/services/models/billing/procedure-sigtap.model';

@Component({
  selector: 'app-bed-management-procedure-modal',
  templateUrl: './bed-management-procedure-modal.component.html',
  styleUrls: ['./bed-management-procedure-modal.component.css']
})
export class BedManagementProcedureModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: ({medicalProcedure: MedicalProcedureModel, procedureList: ProcedureModel[], readOnly: boolean, idEpisode: number}), 
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<BedManagementProcedureModalComponent>,
    private alertService: AlertService,
    private authService: AuthService,
    public medicalRecordService: MedicalRecordService,
    public router:Router,
    private formBuilder: FormBuilder,
    private procedureService: ProcedureService,
    private procedureSigtapService: ProcedureSigtapService,
  ) { }

  public isLoading: boolean;
  public medicalProcedure: MedicalProcedureModel;
  public medicalProcedureResponse: MedicalProcedureResponseModel;
  public procedureModel: FormGroup;
  public procedureList: ProcedureModel[];
  public procedureListDisplay: ProcedureModel[];
  public listProceduresByCbo: ProcedureModel[] = [];
  public idEpisode:number = null;
  public procedureSigtap: ProcedureSigtapModel;

  ngOnInit(): void {
    this.isLoading = false;
    if(this.data.medicalProcedure){
      this.medicalProcedure = this.data.medicalProcedure;
    }
    this.procedureList = this.data.procedureList;
    this.idEpisode = this.data.idEpisode;
    this.listProcedureByCboData();
    this.procedureModel = this.formBuilder.group({
      idProcedure: [{ value: null, disabled: this.data.readOnly }, Validators.required],
      isRecurring: [{ value: '', disabled: this.data.readOnly }, Validators.required],
      observation: [{ value: null, disabled: this.data.readOnly }],
      idProcedureSigtap: [{ value: null, disabled: true }],
    });

    if (this.medicalProcedure) {
      this.populateValues();
    }
  }

  listProcedureByCboData() {
    this.procedureService.getProceduresByCbo().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listProceduresByCbo = response.listProcedure;
      this.procedureListDisplay = this.listProceduresByCbo;
    },
    (error)=>{
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  };
  
  populateValues() {

    this.procedureModel.get('idProcedure').setValue(this.medicalProcedure?.idProcedure);
    this.procedureModel.get('isRecurring').setValue(this.medicalProcedure?.isRecurring);
    this.procedureModel.get('observation').setValue(this.medicalProcedure?.observation);
    if(this.medicalProcedure.idProcedureSigtap)
      this.getProcedure(this.medicalProcedure.idProcedureSigtap);

    if (this.data.readOnly) {
      this.procedureModel.get('idProcedure').disable();
      this.procedureModel.get('isRecurring').disable();
      this.procedureModel.get('observation').disable();
    }
  }

  clickCancel() {
    this.matDialogRef.close();
  }
  close() {
    this.matDialogRef.close();
  }

  onSubmit() {
    if (this.procedureModel.invalid) {
      this.alertService.show('Erro', "Preencha todos os campos obrigatórios!", AlertType.error);
      return;
    }
    let newMedicalProcedure: MedicalProcedureResponseModel = new MedicalProcedureResponseModel();
    newMedicalProcedure.idProcedure = this.procedureModel.get('idProcedure').value;
    newMedicalProcedure.isRecurring = this.procedureModel.get('isRecurring').value;
    newMedicalProcedure.observation = this.procedureModel.get('observation').value;
    if(this.procedureSigtap && this.procedureSigtap.idProcedureSigtap)
      newMedicalProcedure.idProcedureSigtap = this.procedureSigtap.idProcedureSigtap;

    newMedicalProcedure.idEpisode = this.idEpisode;

    if(this.medicalProcedure)
    {
      this.medicalRecordService.putMedicalProcedure(newMedicalProcedure, this.medicalProcedure.idMedicalProcedure).subscribe((response) => {
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.matDialogRef.close(); 
      },
      (error)=>{
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
    }
    else
    {
      this.medicalRecordService.postMedicalProcedure(newMedicalProcedure).subscribe((response) => {
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.matDialogRef.close(); 
      },
      (error)=>{
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
    }
  }

  onKey(value) {
    this.procedureListDisplay = this.search(value);
  }

  search(value: string) {
    if (value){
      let filter = value.toLowerCase();
      return this.listProceduresByCbo.filter(option => option.procedureName.toLowerCase().includes(filter));
    }
    else{
      return this.listProceduresByCbo;
    }
  }

  selectProcedure(procedureSelected: ProcedureModel) {
    if(procedureSelected.idProcedureSigtap){
      this.getProcedure(procedureSelected.idProcedureSigtap)
    } else {
      this.procedureSigtap = new ProcedureSigtapModel();
      this.procedureModel.get('idProcedureSigtap').setValue(null);
    }
  }

  getProcedure(idProcedureSigtap: number) {
    this.procedureSigtapService.getProcedureSigtap(idProcedureSigtap).subscribe({
      next: (response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.procedureSigtap = response.procedureSigtap;
        if(this.procedureSigtap)
          this.procedureModel.get('idProcedureSigtap').setValue(this.procedureSigtap.procedureName);
        else
          this.procedureModel.get('idProcedureSigtap').setValue(null);
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}
