import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CallEpisodePrescriptionService } from 'src/app/shared/services/API/orchestrator-patient/call-episode-prescription.service';
import { MedicinePresentationTypeService } from 'src/app/shared/services/API/pharmacy/medicine-presentation-type.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { LocationModel } from 'src/app/shared/services/models/pharmacy/location.model';
import { ItemSearchSelectStruct } from 'src/app/shared/services/structs/pharmacy/item-search-select.struct';
import { ItemStruct } from 'src/app/shared/services/structs/pharmacy/item.struct';
import { KitItemSelectStruct } from 'src/app/shared/services/structs/pharmacy/kit-item-select.struct';
import { environment } from 'src/environments/environment';
import { LiberateMedicalPrescriptionSolutionModalComponent } from '../liberate-medical-prescription-solution-modal/liberate-medical-prescription-solution-modal.component';
import { EpisodeStruct } from 'src/app/shared/services/structs/medical-record/episode.struct';
import { LiberatePrescriptionRequest } from 'src/app/shared/services/requests/orchestrator-patient/liberate-prescription.request';
import { PrescriptionStatusEnum } from 'src/app/shared/enum/prescription-status.enum';
import { PrescriptionNonDispensationRequest } from 'src/app/shared/services/requests/medical-record/prescription-non-dispensation.request';
import { KitItemIdAndQuantityStruct } from 'src/app/shared/services/structs/pharmacy/kit-item-id-and-quantity.struct';
import { ReportModalComponent } from 'src/app/shared/components/report-modal/report-modal.component';
import { LiberateSolutionPrescriptionRequest } from 'src/app/shared/services/requests/orchestrator-patient/liberate-solution-prescription.request';
import { PrescriptionNonDispensationAllModalComponent } from '../prescription-non-dispensation-all-modal/prescription-non-dispensation-all-modal.component';
import { GetMedicineDataService } from 'src/app/shared/services/API/pharmacy/get-medicine-data.service';
import { MedicineModel } from 'src/app/shared/services/models/pharmacy/medicine.model';

@Component({
  selector: 'app-medical-prescription-solution-modal',
  templateUrl: './medical-prescription-solution-modal.component.html',
  styleUrls: ['./medical-prescription-solution-modal.component.css']
})
export class MedicalPrescriptionSolutionModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<MedicalPrescriptionSolutionModalComponent>,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    public callEpisodePrescriptionService: CallEpisodePrescriptionService,
    public medicinePresentationTypeService: MedicinePresentationTypeService,
    private cdr: ChangeDetectorRef,
    public getMedicineDataService: GetMedicineDataService,
  ) { this.matDialogRef.disableClose = true; }

  public model: FormGroup;
  public episode: EpisodeStruct = new EpisodeStruct();

  public listItemFields: KitItemSelectStruct[] = [];
  public listLiberatePrescription: LiberatePrescriptionRequest[] = [];
  public listPrescriptionNonDispensationRequest: PrescriptionNonDispensationRequest[] = [];
  public listLocation: LocationModel[];
  public listMedicine: MedicineModel[] = [];

  public haveListItem: boolean = false;
  public isInvalid: boolean = false;
  public isLoading: boolean = false;
  public validated: boolean = false;
  public isValid: boolean = false;
  public nonDispenseAll: boolean = false;

  public statusLiberatePrescriptionEnum: PrescriptionStatusEnum = PrescriptionStatusEnum.Liberado.valueOf();
  public statusNotDispensationPrescriptionEnum: PrescriptionStatusEnum = PrescriptionStatusEnum.NaoDispensado.valueOf();
  public statusOpenPrescriptionEnum: PrescriptionStatusEnum = PrescriptionStatusEnum.EmAberto.valueOf();

  public listIdKit: number[];
  public barcodeValue: number;
  public idMedicinePresentationType: number;

  public medicinePresentationOther: string = null;
  public urlListKit: string;

  ngOnInit(): void {
    this.listLocation = this.data.listLocation;

    this.urlListKit = `${environment.urlApiPharmacy}Kit/getAll`
    this.model = this.formBuilder.group({
      hasItemList: [false],
      idLocation: [, [Validators.required]],
      firstChildGroup: this.formBuilder.group({
        listIdKit: []
      })
    });

    this.getListMedicine();
  }

  close() {
    this.matDialogRef.close({ checkMedicine: false });
  }

  submit() {
    this.model.get('idLocation').markAsTouched();

    if (this.isLoading || this.model.invalid)
      return;

    this.isLoading = true;

    let liberateSolutionPrescriptionRequest: LiberateSolutionPrescriptionRequest = new LiberateSolutionPrescriptionRequest();
    liberateSolutionPrescriptionRequest.listLiberatePrescription.push(...this.listLiberatePrescription);
    liberateSolutionPrescriptionRequest.listPrescriptionNonDispensationRequest.push(...this.listPrescriptionNonDispensationRequest);

    if (this.model.get("firstChildGroup").get('listIdKit').value) {
      this.model.get("firstChildGroup").get('listIdKit').value.forEach(item => {
        liberateSolutionPrescriptionRequest.listIdKit.push(item);
      });
    }

    let listKitItem: KitItemIdAndQuantityStruct[] = [];
    this.listItemFields.forEach(element => {
      if (!element.formGroup.valid)
        this.isInvalid = true;

      let kitItem: KitItemIdAndQuantityStruct = new KitItemIdAndQuantityStruct();
      kitItem.idItem = element.formGroup.get('firstChildGroup').get('idItem').value;
      kitItem.amount = element.formGroup.get('amount').value;
      listKitItem.push(kitItem);
    });

    liberateSolutionPrescriptionRequest.listItemAmount.push(...listKitItem);

    if (this.isInvalid) {
      this.alertService.show('Erro', "Selecione um material e uma quantidade!", AlertType.error);
      this.isLoading = false;
      this.isInvalid = false;
      return;
    }

    this.callEpisodePrescriptionService.liberatePrescriptionSolution(liberateSolutionPrescriptionRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.alertService.show('Sucesso', "Prescrições liberadas com sucesso!", AlertType.success);
        this.matDialogRef.close({ checkMedicine: true });

        if (response.isActive == true) {
          let reportDialog = this.dialog.open(ReportModalComponent, {
            data: {
              reportName: response.reportName,
              reportPdf64: response.reportPdf64,
            },
          });
        }
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getListMedicine() {
    if(!this.data.solution.listPrescription)
      return;

    let listIdMedicine: number[] = this.data.solution.listPrescription.map(x => x.idMedicine);

    this.getMedicineDataService.getListMedicine(listIdMedicine).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.listMedicine = response.listMedicine;
        
        if(this.listMedicine && this.listMedicine.length > 0){
          let listId: number[] = this.listMedicine.filter(x => x.idKit != null && x.idKit != undefined).map(x => x.idKit);
          if(listId && listId.length > 0)
            this.model.get("firstChildGroup").get('listIdKit').setValue(listId);
        }

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  clickCancel() {
    this.matDialogRef.close({ checkMedicine: false });
  }

  nonDispensationAllModalComponent(solution) {
    const dialogRef = this.dialog.open(PrescriptionNonDispensationAllModalComponent, {
      data: {
        solution: solution,
        patientName: this.data.episode.patientName,
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result.notDispensed)
          this.matDialogRef.close({ notDispensedAll: true });
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  selectLocation(event: any) {
    if (this.haveListItem) {
      this.listItemFields = [];
      this.addItem(null);
    }
  }

  addItem(event: any) {
    let newItemField: KitItemSelectStruct = new KitItemSelectStruct();
    newItemField.preSelectedItem = [];

    if (this.listItemFields?.length == 0)
      newItemField.idField = 1;
    else if (this.listItemFields?.length >= 0)
      newItemField.idField = Math.max.apply(Math, this.listItemFields.map(l => l.idField)) + 1;

    newItemField.formGroup = this.formBuilder.group({
      firstChildGroup: this.formBuilder.group({ idItem: [null, [Validators.required]] }),
      amount: [null, [Validators.required, Validators.min(0)]],
    });
    this.listItemFields.push(newItemField);
    this.setLoneliness();
  }

  addExistingItem(item: ItemStruct) {
    let newItemField: KitItemSelectStruct = new KitItemSelectStruct();
    let preselectedItem: ItemSearchSelectStruct = new ItemSearchSelectStruct();
    preselectedItem.idItem = item.idItem,
      preselectedItem.codeWithName = item.codeWithName;
    newItemField.preSelectedItem = [];
    newItemField.preSelectedItem.push(preselectedItem);

    if (this.listItemFields?.length == 0)
      newItemField.idField = 1;
    else if (this.listItemFields?.length >= 0)
      newItemField.idField = Math.max.apply(Math, this.listItemFields.map(l => l.idField)) + 1;

    newItemField.formGroup = this.formBuilder.group({
      firstChildGroup: this.formBuilder.group({ idItem: [item.idItem, [Validators.required]] }),
      amount: [item.amount, [Validators.required]],
    });
    this.listItemFields.push(newItemField);
    this.setLoneliness();
  }

  removeItem(event: any) {
    if (this.listItemFields?.length <= 1 ?? true)
      return;

    let index = this.listItemFields?.findIndex(l => l.idField == event);
    if (index > -1)
      this.listItemFields?.splice(index, 1);

    this.setLoneliness();
  }

  setLoneliness() {
    if (this.listItemFields?.length <= 0 ?? true)
      return;

    if (this.listItemFields.length == 1)
      this.listItemFields[0].isSingle = true;
    else if (this.listItemFields.length > 1) {
      this.listItemFields.forEach(element => {
        element.isSingle = false;
      });
    }
  }

  changeItems(event: any) {
    this.listItemFields = [];
    if (event.checked)
      this.addItem(null);
  }

  openPrescriptionSolutionModal(prescription) {
    const dialogRef = this.dialog.open(LiberateMedicalPrescriptionSolutionModalComponent, {
      data: {
        prescription: prescription,
        listLocation: this.listLocation,
        episode: this.data.episode
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result.liberate && !result.nonDispensation && result.liberatePrescriptionRequest != null) {
          this.listLiberatePrescription.push(result.liberatePrescriptionRequest);
          this.data.solution.listPrescription.forEach(x => {
            if (x.idMedicalPrescription == prescription.idMedicalPrescription)
              x.idStatusPrescription = this.statusLiberatePrescriptionEnum;
          });

          this.cdr.detectChanges();
        }
        else if (result.nonDispensation) {
          this.listPrescriptionNonDispensationRequest.push(result.nonDispensationRequest);
          this.data.solution.listPrescription.forEach(x => {
            if (x.idMedicalPrescription == prescription.idMedicalPrescription)
              x.idStatusPrescription = this.statusNotDispensationPrescriptionEnum;
          });

          this.cdr.detectChanges();
        }
        else
          return;

        let listPrescriptionNoCompleteds = this.data.solution.listPrescription.filter(x => x.idStatusPrescription === null || x.idStatusPrescription === this.statusOpenPrescriptionEnum || x.idStatusPrescription === undefined);

        if (listPrescriptionNoCompleteds.length == 0)
          this.validated = true;
        else if (this.data.solution.listPrescription.some(x => x.idStatusPrescription === this.statusLiberatePrescriptionEnum || x.idStatusPrescription === this.statusNotDispensationPrescriptionEnum))
          this.nonDispenseAll = true;
      },
      error: (error) => {
        console.log(error);
      }
    });
  }
}