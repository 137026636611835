import { PatientHealthcareAgreementDetailsStruct } from "../medical-record/patient-healthcare-agreement-details.struct";

export class EpisodeStruct {
    public idEpisode: number;
    public idHealthUnit: number;
    public idPatient: number;
    public idArrivalType: number;
    public idOrigin: number;
    public ticketInitials: string;
    public ticketSequence: number;
    public companionName: string;
    public companinonCpf: string;
    public companionPhone: string;
    public companionParentage: string;
    public observation: string;
    public datetimeFinish: Date;
    public datetimeInclusion: Date;
    public birthDate: Date;
    public patientName: string;
    public patientSocialName: string;
    public patientPhone1: string;
    public patientPhone2: string;
    public patientStreet: string;
    public patientCity: string;
    public patientHouseNumber: number;
    public idStatus: number;
    public statusName: string;
    public originName: string;
    public arrivalTypeName: string;
    public isUnidentifiedPatient: boolean;
    public unidentifiedPatientDescription: string;
    public conclusionNote: string;
    public idProtocol: number;
    public responsibleUserName: string;
    public admissionReportName: string;
    public idEpisodeAdministrativeRelease: number;
    public allergyGroup: string;
    public ageGroupName: string;
    public idAgeGroup: number;
    public idNatureOfAttendance: number;
    public idUserCareLinePriority: number;
    public idCareLinePriority: number;
    public idCareLinePriorityStatus: number;
    public careLinePriorityStatusMotive: string;
    public datetimeCareLinePriorityStatus: Date;
    public idUserCareLinePriorityStatus: number;
    public careLinePriorityStatusUserName: string;
    public careLinePriorityUserName: string;
    public waitingTime: string;
    public serviceTime: string;
    public idSuspicion: number;
    public patientHealthcareAgreementInEpisode: PatientHealthcareAgreementDetailsStruct = new PatientHealthcareAgreementDetailsStruct();
}