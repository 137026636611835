export class HealthcareAgreementRequest {
    public ansRegistrationNumber: string;
    public idGuide: number;

    public nameHealthcareAgreement: string;
    public logoString64: string;
    public phone: string;

    public isActive: boolean;
    public isLogoUploaded: boolean;

    public idTissXml: number;
    public nextBatchNumber: string;
    public nextGuideNumber: string;
    public idTerminologyOrigin: number;
    public privateHealthcare: boolean;
}