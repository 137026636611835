import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { IntegrationLogService } from 'src/app/shared/services/API/integration/integration-log.service';
import { ResendWebhookService } from 'src/app/shared/services/API/orchestrator-integration/resend-webhook.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { ResendWebhookRequest } from 'src/app/shared/services/requests/orchestrator-integration/resend-webhook.request';
import { IntegrationLogStruct } from 'src/app/shared/services/structs/integration/integration-log.struct';
import { WebhookStruct } from 'src/app/shared/services/structs/orchestrator-integration/webhook.struct';
@Component({
  selector: 'app-integration-log-details',
  templateUrl: './integration-log-details.component.html',
  styleUrls: ['./integration-log-details.component.css']
})
export class IntegrationLogDetailsComponent implements OnInit {

  constructor(
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private integrationLogService: IntegrationLogService,
    private resendWebhookService: ResendWebhookService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.integration;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.integration_log;

  public integrationLogStruct: IntegrationLogStruct;
  public webhookStruct: WebhookStruct;
  public masks: Masks;

  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;
  public canResend: boolean = false;

  public idIntegrationLog: number;
  public actualServiceModule: number;

  public requestJson: string;
  public responseJson: string;

  ngOnInit(): void {

    this.integrationLogStruct = new IntegrationLogStruct();

    this.isLoading = false;

    this.masks = this.maskService.getMasks();

    this.idIntegrationLog == null;
    this.isUpdate == false;
    if (this.activatedRoute.snapshot.paramMap.get('idIntegrationLog'))
      this.idIntegrationLog = parseInt(this.activatedRoute.snapshot.paramMap.get('idIntegrationLog'));

    if (this.idIntegrationLog != null) {
      this.isFirstLoading = true;
      this.isUpdate = true;
      this.populateLogData();
    }
  }

  populateLogData() {
    this.integrationLogService.getIntegrationLog(this.idIntegrationLog).subscribe({
      next: (response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.integrationLogStruct = response.integrationLog;
        this.webhookStruct = response.webhookStruct;
        if(this.webhookStruct.canResend != null 
          && this.webhookStruct.canResend != undefined) this.canResend = this.webhookStruct.canResend;

        if (this.isJson(this.integrationLogStruct.jsonRequest)) {
          this.requestJson = JSON.parse(this.integrationLogStruct.jsonRequest);
        } else {
          this.requestJson = this.integrationLogStruct.jsonRequest;
        }

        if (this.isJson(this.integrationLogStruct.jsonResponse)) {
          this.responseJson = JSON.parse(this.integrationLogStruct.jsonResponse);
        } else {
          this.responseJson = this.integrationLogStruct.jsonResponse;
        }

        this.isLoading = false;
        this.isFirstLoading = false;
    },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  isJson(str: string): boolean {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  resendWebhook() {
    if (this.isLoading)
      return;

    this.isLoading = true;
    let request = new ResendWebhookRequest();
    
    request.body = this.integrationLogStruct;
    let webhookRequest = this.webhookStruct;
    request.webhook.push(webhookRequest);

    this.resendWebhookService.resendWebhook(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', 'Webhook reenviado com sucesso', AlertType.success);
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

}

