import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CommonService } from "src/app/shared/services/common-service";
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';
import { catchError } from "rxjs/operators";
import { ReturnStruct } from "src/app/shared/structs/return.struct";
import { MedicalCareSadtRequest } from "../../requests/medical-record/medical-care-sadt.request";
import { MedicalCareSadtResponse } from "../../responses/medical-record/medical-care-sadt.response";

@Injectable({
  providedIn: 'root'
})
export class MedicalCareSadtService extends CommonService {

  constructor(private httpClient: HttpClient) { super(); }

  public updateMedicalCareSadt(idMedicalCareSadtGroup: number, medicalCareSadtRequest: MedicalCareSadtRequest): Observable<ReturnStruct> {

    let uri = `MedicalCareSadt/idSadtGroup/${idMedicalCareSadtGroup}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiMedicalRecord + uri, medicalCareSadtRequest, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public getMedicalCareSadt(idEpisode: number): Observable<MedicalCareSadtResponse> {

    let uri = `MedicalCareSadt/idEpisode/${idEpisode}`

    return this.httpClient.get<MedicalCareSadtResponse>(environment.urlApiMedicalRecord + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}