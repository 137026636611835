<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <h1>Estoque</h1>
        <div class="white-body">
            <a mat-flat-button color="primary" class=" add-user" routerLink="/pharmacy/storage/register"><mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon> Cadastrar Estoque</a>
            <div class="filters">
                <form class="form" [formGroup]="model" (ngSubmit)="buttonSearch()">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Código do produto</mat-label>
                                <input matInput type="text" formControlName="code">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Código de barras</mat-label>
                                <input matInput type="text" formControlName="barcode">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Descrição</mat-label>
                                <input matInput type="text" formControlName="description">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Lote</mat-label>
                                <input matInput type="text" formControlName="batch">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Data de validade inicial</mat-label>
                                <input matInput [mask]="masks.date" type="text" formControlName="dateExpirationInitial">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Data de validade Final</mat-label>
                                <input matInput [mask]="masks.date" type="text" formControlName="dateExpirationFinal">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Localização</mat-label>
                                <mat-select formControlName="idLocation">
                                    <mat-option [value]="">Todos</mat-option>
                                    <mat-option *ngFor="let item of listLocation" value="{{item.idLocation}}">
                                        {{item.locationName}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                            <button mat-flat-button color="primary" class=" btn-block" type="submit">
                                <span *ngIf="!isLoading">Pesquisar</span>
                                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="card-list" *ngIf="listStorage != null">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listStorage">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary">{{item.productCode.product}}</span>
                                <span class="primary">Código: {{item.productCode.code}}</span>
                                <span class="secundary">Codigo de barras: {{item.idStorage}}</span>
                                <span class="secundary">Quantidade Total: {{item.storageAmountLocation?.amountTotal}}</span>
                                <span class="secundary">Quantidade Disponível: {{item.storageAmountLocation?.amountRemaining}}</span>
                                <span class="secundary">validade: {{item.dateExpiration | date : 'dd/MM/yyyy'}}</span>
                            </div>
                            <div class="actions">
                                <mat-spinner [diameter]="30" *ngIf="isLoading"></mat-spinner>
                                <a *ngIf="!isLoading" ><mat-icon aria-hidden="false" aria-label="Editar" (click)="openModalReport(item.idStorage)">print</mat-icon></a>
                                <a *ngIf="!isLoading" ><mat-icon aria-hidden="false" aria-label="Editar" [routerLink] = "['/pharmacy/storage/register', {idStorage: item.idStorage}]">edit</mat-icon> </a>
                                <a *ngIf="!isLoading"><mat-icon aria-hidden="false" aria-label="Editar" (click)="openModal(item.idStorage)">delete_forever</mat-icon> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <app-empty-list *ngIf="!isLoading && listStorage != undefined && listStorage.length == 0"></app-empty-list>
            <div *ngIf="!isLoading" class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-paginator [length]="fullSize" #paginator [pageIndex]="pageIndex" [pageSize]="50" aria-label="Select page"
                        (page)="changePage($event)" [hidePageSize]="true">
                    </mat-paginator>
                </div>
            </div>
        </div>
    
        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>

</div>    
