<app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
<div class="white-body">
    <h1 class="border-green-left-title">Pré Cadastro - Paciente</h1>
    <app-pre-register-form-default 
        #preRegister
        [isPreRegister]="true"
        [idEpisode]="this.idEpisode" 
        [idQueue]="this.idQueue" 
        [idRoom]="this.idRoom"
        [idDeviceType]="this.idDeviceType"
        [idSector]="this.idSector"
        [idService]="this.idService"
        [reclassification]="reclassification"
        [classificationData]="this.classificationData"
        (repopulateHeader)="repopulate()"
        (setIsNext)="setIsNext($event)">
    </app-pre-register-form-default>
</div>