import { EmailDomain } from "../../models/user/email-domain.model";

export class HealthUnitRequest {

    public newHealthUnitLogo: string;

    public haveLogo: boolean;

    public idTimeZone: number;

    public idEvasionTime: number; 

    public idLoginTimeout: number; 

    public isBillingSigtap: boolean;

    public idProcedureSigtapCompetence: number;

    public idPasswordExpirationTime: number;
    
    public idLoginType: number;

    public listEmailDomain: EmailDomain[] = [];
    
    public messageTriggerActive: boolean;

    public idUserExpirationTime: number;
}