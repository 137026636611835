import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MedicalSpecialtyService } from 'src/app/shared/services/API/digital-prompt/medical-specialty.service';
import { MedicalRecordLookupService } from 'src/app/shared/services/API/medical-record/lookup.service';
import { NatureOfAttendance } from 'src/app/shared/services/models/medical-record/nature-of-attendance';
import { ListUserStruct } from 'src/app/shared/services/structs/user/list-user.struct';
import { HealthcareAgreementStruct } from 'src/app/shared/services/structs/private-billing/healthcare-agreement.struct';
import { Plan } from 'src/app/shared/services/models/private-billing/plan.model';
import { HealthcareAgreementService } from 'src/app/shared/services/API/private-billing/healthcare-agreement.service';
import { DigitalPromptLookupService } from 'src/app/shared/services/API/digital-prompt/lookup.service';
import { PlanService } from 'src/app/shared/services/API/private-billing/plan.service';
import { MedicalSpecialtyFederalCouncilMedicine } from 'src/app/shared/services/models/digital-prompt-service/medical-specialty-federal-council-medicine.model';
import { MedicalSpecialtyRequest } from 'src/app/shared/services/requests/digital-prompt-service/medical-specialty.request';
import { MedicalSpecialtyStruct } from 'src/app/shared/services/structs/digital-prompt-service/medical-specialty.struct';
import { UserGetNameService } from 'src/app/shared/services/API/user/user-get-name.service';


@Component({
  selector: 'app-medical-specialty-register',
  templateUrl: './medical-specialty-register.component.html',
  styleUrls: ['./medical-specialty-register.component.css']
})
export class MedicalSpecialtyRegisterComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private medicalSpecialtyService: MedicalSpecialtyService,
    private healthcareAgreementService: HealthcareAgreementService,
    private userGetNameService: UserGetNameService,
    private lookupMedicalRecordService: MedicalRecordLookupService,
    private digitalPromptLookupService: DigitalPromptLookupService,
    private planService: PlanService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.digital_prompt_service;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.medical_specialty;

  public model: FormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;

  public medicalSpecialtyRequest: MedicalSpecialtyRequest;
  public medicalSpecialtyStruct: MedicalSpecialtyStruct;

  public idMedicalSpecialty: number;

  public acceptHealthcareAgreement: boolean = false;

  public listUser: ListUserStruct[] = [];
  public listNatureOfAttendance: NatureOfAttendance[] = [];
  public listHealthcareAgreement: HealthcareAgreementStruct[] = [];
  public listMedicalSpecialtyFederalCouncilMedicine: MedicalSpecialtyFederalCouncilMedicine[] = [];
  public listHealthcareAgreementPlan: Plan[] = []
  
  // public listHealthcareAgreement: any[] = [];

  ngOnInit(): void {

    this.isLoading = false;

    this.model = this.formBuilder.group({
      idMedicalSpecialty: [''],
      name: ['', [Validators.required]],
      idNatureOfAttendance: [[], [Validators.required]],
      idUser: [[], [Validators.required]],
      idMedicalSpecialtyFederalCouncilMedicine: [, [Validators.required]],
      allowSelectionHealthcareAgreement: [false, [Validators.required]],
      idHealthcareAgreement: [[]],
      idHealthcareAgreementPlan:[[]]
    });

    this.medicalSpecialtyStruct = new MedicalSpecialtyStruct();
    this.medicalSpecialtyRequest = new MedicalSpecialtyRequest();
    this.getNatureOfAttendance();
    this.getMedicalUsers();
    this.getLookup();

    this.idMedicalSpecialty = null;
    this.isUpdate = false;

    if (this.activatedRoute.snapshot.paramMap.get('idMedicalSpecialty'))
      this.idMedicalSpecialty = parseInt(this.activatedRoute.snapshot.paramMap.get('idMedicalSpecialty'));

    if (this.idMedicalSpecialty != null) {
      this.isFirstLoading = true;
      this.isUpdate = true;
      this.getMedicalSpecialty();
    }
    else
      this.getHealthcareAgreement(true);
  }

  submit() {
    if(this.isLoading){
      return;
    }

    if(!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }    

    this.isLoading = true;

    this.medicalSpecialtyRequest.medicalSpecialtyName= this.model.get('name').value;
    this.medicalSpecialtyRequest.listNatureOfAttendance = this.model.get('idNatureOfAttendance').value ? this.model.get('idNatureOfAttendance').value : [];
    this.medicalSpecialtyRequest.listUser = this.model.get('idUser').value  ? this.model.get('idUser').value : [];
    this.medicalSpecialtyRequest.allowSelectionHealthcareAgreement = this.model.get('allowSelectionHealthcareAgreement').value;
    this.medicalSpecialtyRequest.idMedicalSpecialtyFederalCouncilMedicine = this.model.get('idMedicalSpecialtyFederalCouncilMedicine').value;

    if(this.medicalSpecialtyRequest.allowSelectionHealthcareAgreement){
      this.medicalSpecialtyRequest.listIdHealthcareAgreement = this.model.get('idHealthcareAgreement').value ? this.model.get('idHealthcareAgreement').value : [];
      this.medicalSpecialtyRequest.listIdHealthcareAgreementPlan = this.model.get('idHealthcareAgreementPlan').value ? this.model.get('idHealthcareAgreementPlan').value : [];
    }

    this.isLoading = false;
    if (this.isUpdate)
      this.updateMedicalSpecialty();
    else
      this.createMedicalSpecialty();
  }
  
  populateMedicalSpecialty() {
    this.model.get('name').setValue(this.medicalSpecialtyStruct.medicalSpecialty.medicalSpecialtyName);
    this.model.get('allowSelectionHealthcareAgreement').setValue(this.medicalSpecialtyStruct.medicalSpecialty.allowSelectionHealthcareAgreement);
    this.model.get('idMedicalSpecialtyFederalCouncilMedicine').setValue(this.medicalSpecialtyStruct.medicalSpecialty.idMedicalSpecialtyFederalCouncilMedicine ? 
                                                                        this.medicalSpecialtyStruct.medicalSpecialty.idMedicalSpecialtyFederalCouncilMedicine.toString() : '');

    if (this.medicalSpecialtyStruct.listNatureOfAttendance && this.medicalSpecialtyStruct.listNatureOfAttendance.length > 0){
      this.model.get('idNatureOfAttendance').setValue(this.medicalSpecialtyStruct.listNatureOfAttendance.map(c => c.toString()));
    }
    if (this.medicalSpecialtyStruct.listUser && this.medicalSpecialtyStruct.listUser.length > 0)
      this.model.get('idUser').setValue(this.medicalSpecialtyStruct.listUser.map(c => c.toString()));
    if(this.medicalSpecialtyStruct.medicalSpecialty.allowSelectionHealthcareAgreement){
      this.acceptHealthcareAgreement = true;
      if (this.medicalSpecialtyStruct.listIdHealthcareAgreement && this.medicalSpecialtyStruct.listIdHealthcareAgreement.length > 0){
        this.model.get('idHealthcareAgreement').setValue(this.medicalSpecialtyStruct.listIdHealthcareAgreement.map(c => c.toString()));
        this.getPlans();
      }
      if (this.medicalSpecialtyStruct.listIdHealthcareAgreementPlan && this.medicalSpecialtyStruct.listIdHealthcareAgreementPlan.length > 0)
        this.model.get('idHealthcareAgreementPlan').setValue(this.medicalSpecialtyStruct.listIdHealthcareAgreementPlan.map(c => c.toString()));
    }
  }

  getMedicalSpecialty() {
    this.medicalSpecialtyService.getMedicalSpecialty(this.idMedicalSpecialty).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.medicalSpecialtyStruct = response.medicalSpecialtyStruct;
        this.getHealthcareAgreement();
       
        this.isLoading = false;
        this.isFirstLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  updateMedicalSpecialty() {
    this.isLoading = true;
    this.medicalSpecialtyService.updateMedicalSpecialty(this.idMedicalSpecialty, this.medicalSpecialtyRequest).subscribe({
      next: (response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;
        this.router.navigate(['/digital-prompt-service/patient-center/medical-specialty']);

      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  createMedicalSpecialty() {
    this.isLoading = true;
    this.medicalSpecialtyService.createMedicalSpecialty(this.medicalSpecialtyRequest).subscribe({
      next: (response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;
        this.router.navigate(['/digital-prompt-service/patient-center/medical-specialty']);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getNatureOfAttendance() {
    this.isLoading = true;
    this.lookupMedicalRecordService.listNatureOfAttendance().subscribe({
      next: (response) => {
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listNatureOfAttendance = response.listNatureOfAttendance;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getMedicalUsers() {
    this.isLoading = true;
    this.userGetNameService.listUserSelect(true, null, '[1]').subscribe({
      next: (response) => {
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        this.listUser = response.listUser;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getHealthcareAgreement(getJustActive?: boolean) {
    this.isLoading = true;
    this.healthcareAgreementService.getAllToSelect(null, null, getJustActive).subscribe({
      next: (response) => {
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        if (this.isUpdate){
          let healthcareAgreementSelected: HealthcareAgreementStruct[] = response.list.filter(item => this.medicalSpecialtyStruct.listIdHealthcareAgreement.includes(item.idHealthcareAgreement) && !item.isActive);
          this.listHealthcareAgreement = response.list.filter(item => item.isActive);
          this.listHealthcareAgreement = this.listHealthcareAgreement.concat(healthcareAgreementSelected);
          this.populateMedicalSpecialty();
        }
        else
          this.listHealthcareAgreement = response.list;

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getPlans() {
    this.isLoading = true;
    let listIdHealthcareAgreement = this.model.get('idHealthcareAgreement').value ? this.model.get('idHealthcareAgreement').value : [];

    this.planService.GetAllToSelectByListId(listIdHealthcareAgreement).subscribe({
      next: (response) => {
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        if (this.isUpdate){
          let healthcareAgreementPlanSelected: Plan[] = response.listPlan.filter(item => this.medicalSpecialtyStruct.listIdHealthcareAgreementPlan.includes(item.idPlan) && item.isDeleted);
          this.listHealthcareAgreementPlan = response.listPlan.filter(item => !item.isDeleted);
          this.listHealthcareAgreementPlan = this.listHealthcareAgreementPlan.concat(healthcareAgreementPlanSelected);
        }
        else
          this.listHealthcareAgreementPlan = response.listPlan.filter(item => !item.isDeleted);

        this.updatePlansForm();
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getLookup() {
    this.isLoading = true;
    this.digitalPromptLookupService.getLookup().subscribe({
      next: (response) => {
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        this.listMedicalSpecialtyFederalCouncilMedicine = response.listMedicalSpecialtyFederalCouncilMedicine;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getFormatePlanNameToSelect(planName: string, idHealthcareAgreement: number){
    let matchingHealthcareAgreement: HealthcareAgreementStruct = this.listHealthcareAgreement.find(item => item.idHealthcareAgreement === idHealthcareAgreement);
    if (matchingHealthcareAgreement)
      return `${matchingHealthcareAgreement.nameHealthcareAgreement} - ${planName}`;    
  }

  verifyIdHealthcareAgreementPlanForm(listSelectedHealthcareAgreement: string[]){
    if (listSelectedHealthcareAgreement.length == 0)
      this.model.get('idHealthcareAgreementPlan').setValue([]);        
    else 
      this.getPlans();
  }

  updatePlansForm(){
    let listSelectedHealthcareAgreement = this.model.get('idHealthcareAgreement').value ? this.model.get('idHealthcareAgreement').value : [];
    if (listSelectedHealthcareAgreement.length > 0 && this.model.get('idHealthcareAgreementPlan').value){
      let listHealthcareAgreementPlanHasId: Plan[] = this.listHealthcareAgreementPlan.filter(item => listSelectedHealthcareAgreement.includes(item.idHealthcareAgreement.toString()))
      let currentValuesPlan: string[] = this.model.get('idHealthcareAgreementPlan').value;
      let updateValuesOfPlan: string[] = currentValuesPlan.filter(item => listHealthcareAgreementPlanHasId.map(plan => plan.idPlan.toString()).includes(item));

      this.model.get('idHealthcareAgreementPlan').setValue(updateValuesOfPlan);
    }
  }

  selectionHealthcareAgreementChangeValidator(event: MatCheckboxChange) {
    if (event.checked) {
      this.model.get('idHealthcareAgreement').setValidators([Validators.required]);
      this.model.get('idHealthcareAgreement').enable();
      this.model.get('idHealthcareAgreement').updateValueAndValidity();
      this.model.get('idHealthcareAgreementPlan').setValidators([Validators.required]);
      this.model.get('idHealthcareAgreementPlan').enable();
      this.model.get('idHealthcareAgreementPlan').updateValueAndValidity();
      this.acceptHealthcareAgreement = true;
    }
    else {
      this.model.get('idHealthcareAgreement').clearValidators();
      this.model.get('idHealthcareAgreement').setValue('');
      this.model.get('idHealthcareAgreement').disable();
      this.model.get('idHealthcareAgreement').updateValueAndValidity();
      this.model.get('idHealthcareAgreementPlan').clearValidators();
      this.model.get('idHealthcareAgreementPlan').setValue('');
      this.model.get('idHealthcareAgreementPlan').disable();
      this.model.get('idHealthcareAgreementPlan').updateValueAndValidity();
      this.acceptHealthcareAgreement = false;
    }
  }

}

