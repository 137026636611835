import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { FrontDeskDeleteModalComponent } from './front-desk-delete-modal/front-desk-delete-modal.component';
import { SectorStruct } from 'src/app/shared/services/structs/flow/sector.struct';
import { FrontDeskService } from 'src/app/shared/services/API/front-desk/front-desk.service';
import { ReceptionService } from 'src/app/shared/services/API/flow/reception.service';
import { ListFrontDeskStruct } from 'src/app/shared/services/structs/front-desk/list-front-desk.struct';

@Component({
  selector: 'app-front-desk-list',
  templateUrl: './front-desk-list.component.html',
  styleUrls: ['./front-desk-list.component.css']
})
export class FrontDeskListComponent implements OnInit {

  constructor(private frontDesklService: FrontDeskService,
    private receptionService: ReceptionService,
    private router: Router,
    private alertService: AlertService,
    private utilService: UtilService,
    public dialog: MatDialog
  ) { 
    
  }
    
    public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.frontdesk;
    public menuFunctionalityEnum:MenuFunctionalityEnum = MenuFunctionalityEnum.master_front_desk_config;
    
    public listFrontDesk: ListFrontDeskStruct[];
    public listSector: SectorStruct[];
    public isLoading: boolean;
    public idSector: number;
    public searchText: string;

  ngOnInit(): void {
    this.isLoading = true;
    this.populateSectorSelect();
    this.search();
  }
  search(){
    this.isLoading = true;  
    if(this.searchText == "")
    this.searchText = null;
    
    this.frontDesklService.listFrontDesk(this.searchText, this.idSector).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.listFrontDesk = response.listFrontDeskStruct  

      this.isLoading = false;    
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }
  

  populateSectorSelect(){
    this.receptionService.LocationReception().subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.listSector = response.sectors;
       
      this.isLoading = false   
      
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  private timeoutKeySearch: any = null;
  
  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }
  
  openModal(idFrontDesk){
    const dialogRef = this.dialog.open(FrontDeskDeleteModalComponent, {
      data: {
        idFrontDesk: idFrontDesk
      },
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.deleteFrontDesk){
        this.search();
      }
    });
  }

  onCopy(text){
    this.alertService.show('Sucesso', text, AlertType.success);
  }
}

