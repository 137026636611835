import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StorageLocationStruct } from 'src/app/shared/services/structs/pharmacy/storage-location.struct';


@Component({
  selector: 'app-storage-accordion',
  templateUrl: './storage-accordion.component.html',
  styleUrls: ['./storage-accordion.component.css']
})
export class StorageAccordionComponent implements OnInit {

  constructor() { }


  
  @Input() storage: StorageLocationStruct;
  @Input() index: number;
  @Output() wasUpdated = new EventEmitter<any>();

  ngOnInit(): void {
  }

  updateWindow(){
    this.wasUpdated.emit(true);
  }
}
