<div class="historyModal">
    <div class="mat-dialog-header">
        <div class="row">
            <div class="col-md-2">
                <a mat-flat-button type="button" color="accent" class=" btn-block back-btn" (click)="close()">
                    <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Voltar
                </a>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h1>Controle de material</h1>
            </div>
        </div>
    </div>
    <form class="form" [formGroup]="requesMaterialModal" (ngSubmit)="onSubmit()">
        <div class="mat-dialog-content">
            <div class="row">
                <div class="col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Procedimento (opcional)</mat-label>
                        <mat-select formControlName="idProcedure">
                            <mat-option *ngFor="let item of listMedicalProcedures" [value]="item.idProcedure">
                                {{item.procedureName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Observação (opcional)</mat-label>
                        <textarea matInput formControlName="observation"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="row" formArrayName="listButtons"
                *ngFor="let item of requesMaterialModal.get('listButtons')['controls']; let i = index">
                <div class="col-12" [formGroupName]="i">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Selecione o material</mat-label>
                                <mat-select formControlName="kitItem">
                                    <hr>
                                    <mat-label><b>&nbsp; &nbsp; Kits &#8628;</b></mat-label>
                                    <hr>
                                    <mat-option *ngFor="let kit of listKits" [value]="kit.idKit+'null'">
                                        {{kit.codeWithName}}
                                    </mat-option>
                                    <hr>
                                    <mat-label><b>&nbsp; &nbsp; Itens &#8628;</b></mat-label>
                                    <hr>
                                    <mat-option *ngFor="let item of listItems" [value]="'null'+item.idItem">
                                        {{item.itemName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-6 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Quantidade</mat-label>
                                <input matInput type="number" formControlName="quantity" min="1"
                                    oninput="validity.valid||(value=null);" />
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-6 col-md-3">
                            <button class="totem-button " color="primary" mat-mini-fab type="button"
                                (click)="addNext()">
                                <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
                            </button>
                            <button class="totem-button " color="primary" mat-mini-fab type="button"
                                (click)="removeButton(i)" *ngIf="i != 0">
                                <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>


            <div class="footer-panel-modal-list">
                <div class="row">
                    <div *ngIf="!this.data.readOnly" class="col-12">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span *ngIf="isLoading == false">Gerar solicitação</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-12">
                        <a *ngIf="this.data.readOnly" mat-flat-button color="primary" class="btn-block"
                            (click)="clickCancel()">Ok</a>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="card-list" *ngIf="listRequestMaterial != null">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-6 col-lg-6" *ngFor="let item of listRequestMaterial">
                <div class="card-item">
                    <div class="infos">
                        <span class="primary">Solicitante: {{item.userName}}, <b> - {{item.professionName}}</b><span
                                class="secundary">{{item.dateTimeInclusion | date:'dd/MM/yyyy HH:mm'}}</span></span>
                        <span class="secundary">Procedimento: {{item.nameProcedure}}</span>
                        <span class="secundary">Observação: {{item.observation}}</span>
                        <span class="secundary"><b>Materiais:</b></span>
                        <div class="col-12 col-sm-12 col-md-12"
                            *ngFor="let itemOrKit of item.listRequestMaterialsItemKit">
                            <span class="secundary">{{itemOrKit.quantity}} x
                                {{itemOrKit.itemName}}{{itemOrKit.kitName}}(s)</span>
                        </div>
                        <span class="secundary-status last-info">{{item.statusName}}</span>
                    </div>
                    <div class="actions">
                        <a *ngIf="item.idStatus == 0"><mat-icon aria-hidden="false" (click)="populateValues(item)"
                                aria-label="Editar">edit</mat-icon> </a>
                        <a *ngIf="item.idStatus == 1"><mat-icon aria-hidden="false"
                                (click)="delete(item.idRequestMaterial)" aria-label="Excluir">delete</mat-icon> </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>