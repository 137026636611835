export class ProfessionalAgreement{    
    public idProfessionalAgreement: number;
    public idHealthcareAgreement: number;
    public numberProfessional: string;
    public idProfessionalCouncil: number;
    public idParticipationDegree: number;
    public idProviderIdentityType: number;
    public idCbo: number;
    public idState: number;
    public codeOperator: string;

    public nameProfessional: string;

    public isActive: boolean = true;
}