import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { MatDialog } from '@angular/material/dialog';
import { ArrivalReasonEnum, SpecialNecessityEnum } from 'src/app/shared/enum/risk-classification.enum';
import { ArrivalReasonModel } from 'src/app/shared/services/models/risk-classification/arrival-reason.mode';
import { SpecialNecessityModel } from 'src/app/shared/services/models/risk-classification/special-necessity.model';
import { LookupRiskService } from 'src/app/shared/services/API/risk-classification/lookup-risk.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { PriorityColorModel } from 'src/app/shared/services/models/risk-classification/priority-color.model';
import { RoomAccessUtilService } from 'src/app/shared/services/room-access-util.service';
import { UtilsClassificationService } from 'src/app/classification/utils.service';

@Component({
  selector: 'app-white-patient',
  templateUrl: './white-patient.component.html',
  styleUrls: ['./white-patient.component.css']
})
export class WhitePatientComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public router: Router,
    public utilClassification: UtilsClassificationService,
    public lookupService: LookupRiskService,
    public alertService: AlertService,
  ) { }
  public model: FormGroup;
  public isLoading: boolean = false;
  public idArrivalReason: number;
  public idSpecialNecessity: number;
  public arrivalReasonList: ArrivalReasonModel[];
  public specialNecessityList: SpecialNecessityModel[];
  public idArrivalReasonOthers: number = ArrivalReasonEnum.outros;
  public idSpecialNecessityOthers: number = SpecialNecessityEnum.outros;
  public idEpisode: number;
  public idRoom: number;
  @Output() selectArrivalReason = new EventEmitter<any>();
  @Output() goBackEmitter = new EventEmitter<any>();
  @Output() firstLoading = new EventEmitter<boolean>();

  ngOnInit(): void {
    let data = this.utilClassification.getClassificationData();
    this.idEpisode = data.idEpisode;
    this.idRoom = data.idRoom;
    this.populateLookup();
    this.model = this.formBuilder.group({
      idArrivalReason: [data.idArrivalReason, [Validators.required]],
      arrivalReason: [data.arrivalReasonDesc],
      idSpecialNecessity: [data.idSpecialNecessity],
      specialNecessity: [data.specialNecessity],
      observation: [data.observation],
    });
  }
  submit() {
    this.isLoading = true;
    if (!this.model.valid) {
      this.isLoading = false;
      this.alertService.show('Erro', "Preencha todos os campos obrigatórios", AlertType.error);
      return;
    }
    let data = this.utilClassification.getClassificationData();
    data.priority = new PriorityColorModel();
    data.priority.coloerHex = "#f5f0f0";
    data.priority.description = "Paciente branco"
    data.priority.idPriorityColor = 7;
    data.priority.idPriorityColorGroup = 1;
    data.priority.priorityColorName = "Branco"
    data.idArrivalReason = this.model.get("idArrivalReason").value;
    data.arrivalReason = this.arrivalReasonList.find(c => c.idArrivalReason == data.idArrivalReason).arrivalReasonName;
    data.arrivalReasonDesc = this.model.get("arrivalReason").value;
    data.idSpecialNecessity = this.model.get("idSpecialNecessity").value;
    data.specialNecessity = this.model.get("specialNecessity").value;
    data.observation = this.model.get("observation").value;
    data.isPatientWhite = true;
    this.utilClassification.updateClassificationData(data);
    this.selectArrivalReason.emit();
  }
  onChangeArrivalReason(event: number) {
    this.idArrivalReason = event;
    if (this.idArrivalReason == this.idArrivalReasonOthers) {
      this.model.get('arrivalReason').setValidators(Validators.required);
    } else {
      this.model.get('arrivalReason').clearValidators();
    }
    this.model.get('arrivalReason').updateValueAndValidity();
  }

  onChangeSpecialNecessity(event: number) {
    this.idSpecialNecessity = event;
    if (this.idSpecialNecessity == this.idSpecialNecessityOthers) {
      this.model.get('specialNecessity').setValidators(Validators.required);
    } else {
      this.model.get('specialNecessity').setValidators(Validators.nullValidator);
    }
    this.model.get('specialNecessity').updateValueAndValidity();
  }

  populateLookup() {
    this.lookupService.getLookup().subscribe((response) => {
      if (response.isError) {
        this.firstLoading.emit(false);
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.firstLoading.emit(false);
      this.arrivalReasonList = response.arrivalReason;
      this.specialNecessityList = response.specialNecessity;
    });
  }
  goBack() {
    this.goBackEmitter.emit();
  }
}
