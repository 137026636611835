import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { LocationService } from 'src/app/shared/services/API/pharmacy/location.service';

@Component({
  selector: 'app-location-delete-modal',
  templateUrl: './location-delete-modal.component.html',
  styleUrls: ['./location-delete-modal.component.css']
})
export class LocationDeleteModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<LocationDeleteModalComponent>,
  private locationService: LocationService,
  private alertService: AlertService) { }

  public isLoading:boolean;  

  ngOnInit(): void {
    this.isLoading = false;
  }
  
  close(){
    this.matDialogRef.close({deleteLocation: false}); 
  }
  
  clickDelete(){
    
    this.isLoading = true;

    this.locationService.deleteLocation(this.data.idLocation).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.alertService.show('Sucesso', "Local excluido com sucesso!", AlertType.success); 
      this.matDialogRef.close({deleteLocation: true}); 
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }
  
  clickCancel(){
    this.matDialogRef.close({deleteLocation: false}); 
  }
}
