import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TriageStruct } from 'src/app/shared/services/structs/medical-record/triage.struct';

@Component({
  selector: 'app-last-triage-details-modal',
  templateUrl: './last-triage-details-modal.component.html',
  styleUrls: ['./last-triage-details-modal.component.css']
})
export class LastTriageDetailsModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<LastTriageDetailsModalComponent>,
  ) { }
  
  public lastTriage: TriageStruct = null;

  public healthUnitNameByLastTriage: string = "";

  public isLoading: boolean = false;
  public isFirstLoading: boolean = true;

  ngOnInit(): void {
    this.lastTriage = this.data.lastTriage;
    this.healthUnitNameByLastTriage = this.data.healthUnitNameByLastTriage;
    this.isFirstLoading = false;
  }

  closeModal() {
    this.matDialogRef.close();
  }

}
