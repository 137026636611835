import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MedicineGroupService } from 'src/app/shared/services/API/pharmacy/medicine-group.service';
import { MedicineSubgroupService } from 'src/app/shared/services/API/pharmacy/medicine-subgroup.service';
import { MedicineTypeService } from 'src/app/shared/services/API/pharmacy/medicine-type.service';
import { MedicineService } from 'src/app/shared/services/API/pharmacy/medicine.service';
import { MedicineGroupTypeStruct } from 'src/app/shared/services/structs/pharmacy/medicine-group-type.struct';
import { MedicineSubgroupStruct } from 'src/app/shared/services/structs/pharmacy/medicine-subgroup.struct';
import { MedicineTypeStruct } from 'src/app/shared/services/structs/pharmacy/medicine-type.struct';
import { MedicineStruct } from 'src/app/shared/services/structs/pharmacy/medicine.struct';
import { MedicineDeleteModalComponent } from './medicine-delete-modal/medicine-delete-modal.component';

@Component({
  selector: 'app-medicine-list',
  templateUrl: './medicine-list.component.html',
  styleUrls: ['./medicine-list.component.css']
})
export class MedicineListComponent implements OnInit {

  constructor(private medicineService: MedicineService,
    private medicineTypeService: MedicineTypeService,
    private medicineGroupService: MedicineGroupService,
    private medicineSubgroupService: MedicineSubgroupService,
    private alertService: AlertService,
    public dialog: MatDialog
  ) { }
    
  public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.pharmacy;
  public menuFunctionalityEnum:MenuFunctionalityEnum = MenuFunctionalityEnum.pharmacy_medicine;
    
  public listMedicineStruct: MedicineStruct[];
  public listTypeStruct: MedicineTypeStruct[];
  public listGroupStruct: MedicineGroupTypeStruct[];
  public listSubgroupStruct: MedicineSubgroupStruct[];
  public isLoading: boolean;
  public searchText: string;
  public listIdType: number[];
  public listIdGroup: number[];
  public listIdSubgroup: number[];
  public isActive: boolean;

  ngOnInit(): void {
    this.isLoading = true;
    this.listIdType = [];
    this.listIdGroup = [];
    this.listIdSubgroup = [];
    this.populateType();
    this.populateGroup();
    this.populateSubgroup();
    this.search();
  }
  search(){
    this.isLoading = true;  
    if(this.searchText == "")
      this.searchText = null;
    
    this.medicineService.listMedicine(this.searchText, this.listIdType, this.listIdGroup, this.listIdSubgroup, this.isActive).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listMedicineStruct = response.listMedicineStruct; 

      this.isLoading = false;    
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  populateType() {
    this.medicineTypeService.listAllMedicineType().subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.listTypeStruct = response.listMedicineType; 

      this.isLoading = false;    
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  populateGroup() {
    this.medicineGroupService.listAllMedicineGroup().subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.listGroupStruct = response.listMedicineGroupTypeStruct; 

      this.isLoading = false;    
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  populateSubgroup() {
    this.medicineSubgroupService.listAllMedicineSubgroup().subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.listSubgroupStruct = response.listMedicineSubgroupStruct; 

      this.isLoading = false;    
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  private timeoutKeySearch: any = null;
  
  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }
  
  openModal(idMedicine){
    const dialogRef = this.dialog.open(MedicineDeleteModalComponent, {
      data: {
        idMedicine: idMedicine
      },
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.deleteMedicine){
        this.search();
      }
    });
  }
}
