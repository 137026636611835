import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { BillingComponent } from './pages/billing/billing.component';
import { PatientProcedureMadeListComponent } from './pages/patient-procedure-made-list/patient-procedure-made-list.component';
import { PatientProcedureMadeRegisterComponent } from './pages/patient-procedure-made-register/patient-procedure-made-register.component';
import { ProfessionalProductionAttendanceListComponent } from './pages/professional-production-attendance-list/professional-production-attendance-list.component';
import { ProfessionalProductionAttendanceRegisterComponent } from './pages/professional-production-attendance-register/professional-production-attendance-register.component';
import { HealthcareAgreementsAndPlansListComponent } from './pages/healthcare-agreements-and-plans-list/healthcare-agreements-and-plans-list.component';
import { HealthcareAgreementsAndPlansRegisterComponent } from './pages/healthcare-agreements-and-plans-register/healthcare-agreements-and-plans-register.component';
import { ProcedureListComponent } from './pages/procedure-list/procedure-list.component';
import { ProcedureRegisterComponent } from './pages/procedure-register/procedure-register.component';
import { TissGuideReportListComponent } from './pages/tiss-guide-report-list/tiss-guide-report-list.component';
import { GuideBatchListComponent } from './pages/guide-batch-list/guide-batch-list.component';
import { GuideBatchRegisterComponent } from './pages/guide-batch-register/guide-batch-register.component';
import { GuideBatchEditComponent } from './pages/guide-batch-edit/guide-batch-edit.component';

const routes: Routes = [
  { path: 'billing', component: BillingComponent, canActivate: [AuthGuard] },
  { path: 'billing/professional-production-attendance', component: ProfessionalProductionAttendanceListComponent, canActivate: [AuthGuard] },
  { path: 'billing/professional-production-attendance/register', component: ProfessionalProductionAttendanceRegisterComponent, canActivate: [AuthGuard] },
  { path: 'billing/patient-procedure-made', component: PatientProcedureMadeListComponent, canActivate: [AuthGuard] },
  { path: 'billing/patient-procedure-made/register', component: PatientProcedureMadeRegisterComponent, canActivate: [AuthGuard] },
  { path: 'billing/healthcare-agreements-and-plans', component: HealthcareAgreementsAndPlansListComponent, canActivate: [AuthGuard] },
  { path: 'billing/healthcare-agreements-and-plans/register', component: HealthcareAgreementsAndPlansRegisterComponent, canActivate: [AuthGuard] },
  { path: 'billing/procedure', component: ProcedureListComponent, canActivate: [AuthGuard] },
  { path: 'billing/procedure/register', component: ProcedureRegisterComponent, canActivate: [AuthGuard] },
  { path: 'billing/tiss-guide-report/list', component: TissGuideReportListComponent, canActivate: [AuthGuard] },
  { path: 'billing/tiss-batch-control', component: GuideBatchListComponent, canActivate: [AuthGuard] },
  { path: 'billing/tiss-batch-control/register', component: GuideBatchRegisterComponent, canActivate: [AuthGuard] },
  { path: 'billing/tiss-batch-control/edit', component: GuideBatchEditComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BillingRoutingModule { }
