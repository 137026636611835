<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body tab-header">
        <div class="row">
            <div class="col-12">
                <div class="row" style="padding-left: 20px;">
                    <div class="col-9">
                        <h1>Novo convênio</h1>
                    </div>
                    <div class="col-3">
                        <a routerLink="/billing/healthcare-agreements-and-plans" mat-flat-button color="accent"
                            class="btn-block">
                            <span>Voltar à lista principal</span>
                        </a>
                    </div>
                </div>
                <div class="white-body">
                    <div class="col-12">
                        <mat-tab-group [selectedIndex]="selected.value"
                            (selectedIndexChange)="selected.setValue($event)">
                            <div class="white-body">
                                <mat-tab label="Dados básicos">
                                    <app-basic-data *ngIf="!isLoading"
                                        [healthcareAgreementStruct]="healthcareAgreementStruct"
                                        (healthcareAgreementFilled)="onHealthcareAgreementFilled($event)"
                                        [isUpdate]="isUpdate">
                                    </app-basic-data>
                                </mat-tab>
                                <mat-tab label="Planos" [disabled]="!healthcareAgreementFieldsFilled">
                                    <app-plan *ngIf="!isLoading" [listPlan]="listPlan"
                                        [nameHealthcareAgreement]="nameHealthcareAgreement"
                                        [idHealthcareAgreement]="idHealthcareAgreement"
                                        (planDataChanged)="onPlanDataChanged($event)"
                                        [fullListSize]="listPlanFullListSize">
                                    </app-plan>
                                </mat-tab>
                                <mat-tab label="Restrições de retorno" [disabled]="!healthcareAgreementFieldsFilled">
                                    <app-return-restrictions *ngIf="!isLoading" [listTypePeriod]="listTypePeriod"
                                        [listReturnRestrictionsStruct]="listReturnRestrictionsStruct"
                                        [listProcedure]="listProcedure"
                                        [nameHealthcareAgreement]="nameHealthcareAgreement"
                                        [idHealthcareAgreement]="idHealthcareAgreement"
                                        [fullListSize]="listReturnRestrictionsFullListSize">
                                    </app-return-restrictions>
                                </mat-tab>
                                <mat-tab label="Valores procedimentos" [disabled]="!healthcareAgreementFieldsFilled">
                                    <div class="row display-contents">
                                        <div class="divid-column">
                                            <div class="col-12 col-md-12 col-lg-6 col-xl-6 col-sm-12">
                                                <app-price-procedure-healthcare *ngIf="!isLoading"
                                                    [listPriceProcedureHealthcareStruct]="listPriceProcedureHealthcareStruct"
                                                    [idHealthcareAgreement]="idHealthcareAgreement"
                                                    [fullListSize]="listPriceProcedureHealthcareFullListSize"
                                                    (changeIndex)="selected.setValue($event)">
                                                </app-price-procedure-healthcare>
                                            </div>
                                            <div class="col-12 col-md-12 col-lg-6 col-xl-6 col-sm-12">
                                                <app-price-procedure-plan *ngIf="!isLoading"
                                                    [listPriceProcedurePlanStruct]="listPriceProcedurePlanStruct"
                                                    (planDataChanged)="onPricePlanDataChanged($event)"
                                                    [idHealthcareAgreement]="idHealthcareAgreement"
                                                    [fullListSize]="listPriceProcedurePlanFullListSize"
                                                    [listProcedure]="listProcedure" [listPlan]="listPlan">
                                                </app-price-procedure-plan>
                                            </div>
                                        </div>
                                    </div>
                                </mat-tab>
                                <mat-tab label="Faturamento TISS" [disabled]="!healthcareAgreementFieldsFilled">
                                    <app-tiss-billing *ngIf="!isLoading" [tissBilling]="tissBilling"
                                        [idHealthcareAgreement]="idHealthcareAgreement"
                                        (changeIndex)="selected.setValue($event)">
                                    </app-tiss-billing>
                                </mat-tab>
                                <mat-tab label="Profissionais" [disabled]="!healthcareAgreementFieldsFilled">
                                    <app-professionals *ngIf="!isLoading"
                                        [listProfessionalCouncil]="listProfessionalCouncil"
                                        [listProviderIdentityType]="listProviderIdentityType"
                                        [listParticipationDegree]="listParticipationDegree" [listCbo]="listCbo"
                                        [listState]="listState" [idHealthcareAgreement]="idHealthcareAgreement"
                                        [listProfessionalAgreementStruct]="listProfessionalAgreementStruct"
                                        (profesisonalDataChanged)="onProfessionalDataChanged($event)"
                                        (changeIndex)="selected.setValue($event)">
                                    </app-professionals>
                                </mat-tab>
                                <mat-tab label="Mais configurações" [disabled]="!healthcareAgreementFieldsFilled">
                                    <app-more-settings *ngIf="!isLoading"
                                        [idHealthcareAgreement]="idHealthcareAgreement"
                                        [listLookupAppointmentRequiredField]="listLookupAppointmentRequiredField"
                                        [listLookupFeesRequiredField]="listLookupFeesRequiredField"
                                        [listLookupSpSadtRequiredField]="listLookupSpSadtRequiredField"
                                        [listLookupAdmissionRequiredField]="listLookupAdmissionRequiredField"
                                        [listSpSadtRequiredField]="listSpSadtRequiredField"
                                        [listAppointmentRequiredField]="listAppointmentRequiredField"
                                        [listAdmissionRequiredField]="listAdmissionRequiredField"
                                        [listFeesRequiredField]="listFeesRequiredField"
                                        (changeIndex)="selected.setValue($event)">
                                    </app-more-settings>
                                </mat-tab>
                            </div>
                        </mat-tab-group>
                    </div>
                    <app-loading-list *ngIf="isLoading"></app-loading-list>
                </div>
            </div>
        </div>
    </div>
</div>