import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PlanService } from 'src/app/shared/services/API/private-billing/plan.service';
import { ProcedureRelationshipService } from 'src/app/shared/services/API/private-billing/procedure-relationship.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Plan } from 'src/app/shared/services/models/private-billing/plan.model';
import { Procedure } from 'src/app/shared/services/models/private-billing/procedure.model';
import { PlanRequest } from 'src/app/shared/services/requests/private-billing/plan.request';
import { ProcedureRelationshipRequest } from 'src/app/shared/services/requests/private-billing/procedure-relationship.request';
import { PlanResponse } from 'src/app/shared/services/responses/private-billing/plan.response';
import { PostPutProcedureRelationshipResponse } from 'src/app/shared/services/responses/private-billing/post-put-procedure-relationship.response';
import { ProcedureRelationshipStruct } from 'src/app/shared/services/structs/private-billing/procedure-relationship.struct';

@Component({
  selector: 'app-procedure-derivate-edit-modal',
  templateUrl: './procedure-derivate-edit-modal.component.html',
  styleUrls: ['./procedure-derivate-edit-modal.component.css']
})
export class ProcedureDerivateEditModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ProcedureDerivateEditModalComponent>,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private procedureRelationshipService: ProcedureRelationshipService,
  ) { this.matDialogRef.disableClose = true; }

  public model: FormGroup;

  public isLoading: boolean = false;
  public procedureRelationshipStruct: ProcedureRelationshipStruct = this.data.procedureRelationshipStruct ?? null;
  public listProcedure: Procedure[] = this.data.listProcedure ?? [];

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      idProcedureChild: [null, [Validators.required]],
      quantity: [1, [Validators.required, Validators.max(9999999), Validators.min(1)]],
    });

    if (this.procedureRelationshipStruct){
      this.model.get('idProcedureChild').setValue(this.procedureRelationshipStruct.idProcedureChild);
      this.model.get('quantity').setValue(this.procedureRelationshipStruct.quantity);
    }      
  }

  submit() {
    if (this.isLoading)
      return;

    if(this.model.get('quantity').value < 1 || this.model.get('quantity').value > 9999999){
      this.alertService.show("Erro", "A quantidade deve estar entre 1 e 9999999!", AlertType.error);
      return;
    }
    if (this.model.invalid) {
      this.alertService.show("Erro", "O procedimento e a quantidade são obrigatórios!", AlertType.error);
      return;
    }  

    let request: ProcedureRelationshipRequest = new ProcedureRelationshipRequest();
    request.idProcedureChild = this.model.get('idProcedureChild').value;
    request.quantity = this.model.get('quantity').value;
    request.idProcedureParent = this.data.idProcedureParent;

    if (!this.procedureRelationshipStruct)
      this.post(request);
    else
      this.put(request, this.procedureRelationshipStruct.idProcedureRelationship);
  }

  clickCancel() {
    if (this.isLoading)
      return;
    
    this.matDialogRef.close({ sucess: false });
  }

  post(request: ProcedureRelationshipRequest) {
    if (this.isLoading)
      return;

    this.isLoading = true;

    this.procedureRelationshipService.Post(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        if (!response.procedureRelationshipStruct) {
          this.throwException("Erro na criação.");
          return;
        }

        this.alertSave("Salvo com sucesso!", response);

      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  put(request: ProcedureRelationshipRequest, idProcedureRelationship: number) {
    if (this.isLoading)
      return;

    this.isLoading = true;

    this.procedureRelationshipService.Put(request, idProcedureRelationship).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        if (!response.procedureRelationshipStruct) {
          this.throwException("Erro na alteração.");
          return;
        }

        this.alertSave("Salvo com sucesso!", response);

      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  private alertSave(text: string, response: PostPutProcedureRelationshipResponse) {
    this.alertService.show('Sucesso', text, AlertType.success);
    this.isLoading = false;
    this.matDialogRef.close({ success: true, procedureRelationshipStruct: response.procedureRelationshipStruct });
  }

  private throwException(error: string) {
    this.alertService.show('Erro inesperado', error, AlertType.error);
    this.isLoading = false;
  }
}