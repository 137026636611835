import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/services/alert.service';
import { SadtGroupStruct } from 'src/app/shared/services/structs/medical-record/sadt-group.struct';

@Component({
  selector: 'app-requested-exams-modal',
  templateUrl: './requested-exams-modal.component.html',
  styleUrls: ['./requested-exams-modal.component.css']
})
export class RequestedExamsModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<RequestedExamsModalComponent>) {
  }

  public isLoading: boolean;

  ngOnInit(): void {
    this.isLoading = false;
  }

  clickOk() {
    this.isLoading = true;
    this.matDialogRef.close();
    this.isLoading = false;
  }
}