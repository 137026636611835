<div mat-dialog-content>
    <div class="mat-dialog-header">
        <h1>Deseja selecionar outro fluxograma?</h1>
    </div>
    <div class="mat-dialog-content">
        <div class="footer-panel-modal-list">
            <div class="row">
                <div class="col-12 col-md-6">
                    <button mat-flat-button color="primary" class="btn-block" (click)="closeYes()">SIM</button>
                </div>
                <div class="col-12 col-md-6">
                    <button mat-flat-button color="secundary" class="btn-block" (click)="closeNo()">NÃO</button>
                </div>
            </div>
        </div>
    </div>
</div>