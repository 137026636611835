<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum'></app-menu>

    <div class="container-body">
        <div class="white-body">
            <a class="back-link"
                [routerLink]="['/medic/medic-favorite-non-standard-prescription', {idSector: this.idSector, idRoom: this.idRoom}]">
                <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon>
            </a>
            <h1 *ngIf="!isUpdate">Cadastro de nova prescrição</h1>
            <h1 *ngIf="isUpdate">Editar prescrição</h1>
            <hr />
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="filters">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Nome da prescrição favorita</mat-label>
                                <input matInput type="text" formControlName="name">
                                <mat-error *ngIf="model.get('name').invalid">Informe o nome</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-2 col-sm-2 col-md-2" *ngIf="viewToggleShareWithMedicalTeam">
                            <mat-slide-toggle formControlName="shareWithMedicalTeam">Compartilhar com a equipe médica</mat-slide-toggle>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-sm-6 col-md-4">
                    <a mat-flat-button type="button" (click)="openMedicineModal(-1, null, false)" color="accent"
                        class=" btn-block">
                        <mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon>
                        Adicionar medicamento
                    </a>
                </div>
                <div class="card-list"
                    *ngIf="listFavoriteNonStandardMedicine != undefined && listFavoriteNonStandardMedicine.length > 0">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-4"
                            *ngFor="let item of listFavoriteNonStandardMedicine; let index = index;">
                            <div class="card-item">
                                <div class="infos">
                                    <span class="primary">{{item.description}}</span>
                                    <span class="secundary">Quantidade: {{item.quantity}}
                                        {{item.measurementUnitName}}</span>
                                    <span class="secundary">Frequência: {{item.frequency}}</span>
                                </div>
                                <div class="actions">
                                    <a>
                                        <mat-icon aria-hidden="false" aria-label="Editar"
                                            (click)="openMedicineModal(index, item, true)">edit</mat-icon>
                                    </a>
                                    <a><mat-icon aria-hidden="false" aria-label="Editar"
                                            (click)="removeMedicine(index)">delete_forever</mat-icon>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <app-loading-list *ngIf="isLoading"></app-loading-list>
                <app-empty-list
                    *ngIf="listFavoriteNonStandardMedicine !== undefined && listFavoriteNonStandardMedicine.length === 0 && !isLoading">
                </app-empty-list>
                <div style="display: flex; align-items: flex-end;">
                    <div class="spacing-button-back col-md-2">
                        <button mat-flat-button type="button"
                            [routerLink]="['/medic/medic-favorite-non-standard-prescription', {idSector: this.idSector, idRoom: this.idRoom}]"
                            color="accent" class="btn-block">
                            <span *ngIf="isLoading == false">Voltar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2"
                        *ngIf="this.model.get('name').value && listFavoriteNonStandardMedicine !== undefined && listFavoriteNonStandardMedicine.length > 0">
                        <button mat-flat-button type="submit" color="primary" class="btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>