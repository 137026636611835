import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { TotemDeleteModalComponent } from './totem-delete-modal/totem-delete-modal.component';
import { TotemService } from 'src/app/shared/services/API/totem/totem.service';
import { TotemStruct } from 'src/app/shared/services/structs/totem/totem.struct';
import { environment } from 'src/environments/environment';
import { ConfigTotemModalComponent } from './config-totem-modal/config-totem-modal.component';
import { ChangePinConfirmModalComponent } from '../../../shared/components/change-pin-confirm-modal/change-pin-confirm-modal.component';

@Component({
  selector: 'app-totem-list',
  templateUrl: './totem-list.component.html',
  styleUrls: ['./totem-list.component.css']
})
export class TotemListComponent implements OnInit {

  constructor(private totemService: TotemService,
    private router: Router,
    private alertService: AlertService,
    private utilService: UtilService,
    public dialog: MatDialog) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.master;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.master_totem;

  public listTotem: TotemStruct[];
  public isLoading: boolean;
  public isActive: boolean;
  public searchText: string;

  ngOnInit(): void {
    this.isLoading = true;
    this.search();
  }
  search() {
    this.isLoading = true;
    if (this.searchText == "")
      this.searchText = null;

    this.totemService.listTotem(this.searchText, this.isActive).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.listTotem = response.listTotem;

      this.listTotem.forEach(x => {
        x.totemUrl = environment.urlApiBaseUI + "client/totem/" + x.idTotem;

      })

      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }

  openModal(idTotem) {
    const dialogRef = this.dialog.open(TotemDeleteModalComponent, {
      data: {
        idTotem: idTotem
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.deleteTotem) {
        this.search();
      }
    });
  }

  openConfigModal() {
    const dialogRef = this.dialog.open(ConfigTotemModalComponent, {
      data: {

      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.deleteTotem) {
        this.search();
      }
    });
  }

  openUpdatePinModal(idTotem) {
    const dialogRef = this.dialog.open(ChangePinConfirmModalComponent, {
      data: {
        equipment: 'Totem'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.changePin) {
        this.updateTotemPin(idTotem);
      }
    });
  }

  updateTotemPin(idTotem: number) {
    this.isLoading = true;
    this.totemService.updateTotemPin(idTotem).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.alertService.show('Sucesso', "PIN Alterado com sucesso!", AlertType.success);
        this.search();
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  onCopy(text) {
    this.alertService.show('Sucesso', text, AlertType.success);
  }
}
