import { Component, OnInit } from '@angular/core';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { NpsTypeEnum } from 'src/app/shared/enum/nps/nps-type.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { LookupNpsService } from 'src/app/shared/services/API/nps/lookup.service';
import { NpsMessageService } from 'src/app/shared/services/API/nps/nps-message.service';
import { MessageFiringTrigger } from 'src/app/shared/services/models/nps/message-firing-trigger.model';
import { GetNpsByHealthUnitResponse } from 'src/app/shared/services/responses/nps/get-nps-by-health-unit.response';
import { NpsCommentCollectionStruct } from 'src/app/shared/services/structs/nps/nps-comment-collection.struct';

@Component({
  selector: 'app-nps-graph',
  templateUrl: './nps-graph.component.html',
  styleUrls: ['./nps-graph.component.css']
})
export class NpsGraphComponent implements OnInit {

  constructor(
    private alertService: AlertService,
    private npsMessageService: NpsMessageService,
    private lookupNpsService: LookupNpsService
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.master;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.result_nps;

  public isLoading: boolean;
  public isFirstLoading: boolean = false;
  public idMessageFiringTrigger: number;
  public listMessageFiringTrigger: MessageFiringTrigger[] = [];
  public idHealthUnit: number;
  public idNpsType: number;
  public typeSMS: NpsTypeEnum = NpsTypeEnum.sms;
  public typeTotem: NpsTypeEnum = NpsTypeEnum.totem;
  public disableFiringTrigger: boolean = false;

  public renderGraph = false;
  public dashboardData: GetNpsByHealthUnitResponse;
  public listCommentCollection: NpsCommentCollectionStruct[];

  public notesGraphVertical = new Array();
  public noteGraphNPS = [
    {
      "name": "NPS",
      "value": 0
    }
  ]
  public notesGraphPierGrid = [
    {
      "name": "Promotor",
      "value": 0
    },
    {
      "name": "Neutro",
      "value": 0
    },
    {
      "name": "Detrator",
      "value": 0
    }
  ];

  public colorGraphNps = {
    domain: ['#7FCCC7']
  };

  colorGraphPierGrid = {
    domain: ['#7FCCC7',
      '#F0D322',
      '#D35E4B']
  }

  public colorGraphVertical = {
    domain: ['#7FCCC7',
      '#7FCCC7',
      '#F0D322',
      '#F0D322',
      '#D35E4B',
      '#D35E4B',
      '#D35E4B',
      '#D35E4B',
      '#D35E4B',
      '#D35E4B']
  };

  ngOnInit(): void {
    this.isFirstLoading = true;
    this.getLookupNps();
    this.searchDashboardData()
  }

  searchDashboardData() {
    this.dashboardData = new GetNpsByHealthUnitResponse();
    this.notesGraphVertical = new Array();
    this.renderGraph = false;
    this.isLoading = true;

    this.npsMessageService.getDashboardDataWithFilter(this.idNpsType, this.idMessageFiringTrigger).subscribe({
      next: (response) => {
        if (response.isError) {
          this.isLoading = false;
          this.isFirstLoading = false;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.dashboardData = response;
        this.listCommentCollection = response.listCommentCollection;

        if (this.dashboardData) {
          for (let i = 10; i > 0; i--) {
            let quantityNote = this.dashboardData.notes.filter(x => x == i).length;

            let noteDashBoard = {
              "name": `${i}`,
              "value": quantityNote
            }
            this.notesGraphVertical.push(noteDashBoard)
          }
        }

        this.noteGraphNPS[0].value = (this.dashboardData.nps < 0) ? this.dashboardData.nps * -1 : this.dashboardData.nps;

        if (this.dashboardData.nps <= 0) this.colorGraphNps.domain[0] = "#ED6F60";
        else if (this.dashboardData.nps <= 50) this.colorGraphNps.domain[0] = "#F6B900";
        else if (this.dashboardData.nps <= 90) this.colorGraphNps.domain[0] = "#4BC268";
        else this.colorGraphNps.domain[0] = "#0076EB";

        this.notesGraphPierGrid[0].value = this.dashboardData.praiser;
        this.notesGraphPierGrid[1].value = this.dashboardData.neutral;
        this.notesGraphPierGrid[2].value = this.dashboardData.detractor;
        this.renderGraph = true;

        this.isFirstLoading = false;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.isFirstLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getLookupNps() {
    this.isLoading = true;
    this.lookupNpsService.listLookup().subscribe({
      next: (response) => {
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listMessageFiringTrigger = response.listMessageFiringTrigger;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  selectTypeNps() {
    if (this.idNpsType == this.typeSMS || this.idNpsType == null)
      this.disableFiringTrigger = false;
    else if (this.idNpsType == this.typeTotem) {
      this.disableFiringTrigger = true;
      this.idMessageFiringTrigger = null;
    }

    this.searchDashboardData();
  }
}