import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { BedResumeReportRequest } from '../../requests/Report/bed-resume-report-request.request';
import { ReportResponse } from '../../responses/orchestrator-patient/report.response';

@Injectable({
  providedIn: 'root'
})
export class BedResumeReportService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  getBedResumeReport(body: BedResumeReportRequest): Observable<ReportResponse> {
    let uri = `BedResumeReport`;

    return this.httpClient.post<ReportResponse>(environment.urlApiOrchestratorPatient + uri, body, this.addHeaderToken())
        .pipe(
          catchError(this.handleError)
        )
  }
}
