import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { VerifyOriginHasTelemedicineRequest } from '../../requests/flow/verify-origin-has-telemedicine.request';
import { VerifyOriginHasTelemedicineResponse } from '../../responses/flow/verify-origin-has-telemedicine.response';

@Injectable({
  providedIn: 'root'
})
export class VerifyHasTelemedicineInFlowService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }
  public verifyHasTelemedicineInOriginFlow(body: VerifyOriginHasTelemedicineRequest): Observable<VerifyOriginHasTelemedicineResponse>{

    let uri = `VerifyHasTelemedicineInFlow/getAllHasTelemedicineInOrigin`

    return this.httpClient.post<VerifyOriginHasTelemedicineResponse>(environment.urlApiFlow + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
