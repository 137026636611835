import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { FlowDeleteModalComponent } from './flow-delete-modal/flow-delete-modal.component';
import { FlowService } from 'src/app/shared/services/API/flow/flow.service';
import { FlowStruct } from 'src/app/shared/services/structs/flow/flow.struct';
import { TotemButtonService } from 'src/app/shared/services/API/totem/totem-button.service';
import { ListTotemButtonResponse } from 'src/app/shared/services/responses/totem/list-totem-button.response';
import { TagStruct } from 'src/app/shared/components/tag/tag.struct';
import { FlowTagService } from 'src/app/shared/services/API/flow/flow-tag.service';

@Component({
  selector: 'app-flow-list',
  templateUrl: './flow-list.component.html',
  styleUrls: ['./flow-list.component.css']
})
export class FlowListComponent implements OnInit {

  constructor(private flowService: FlowService,
    private totemButtonService: TotemButtonService,
    private alertService: AlertService,
    private utilService: UtilService,
    private flowTagService: FlowTagService,
    public dialog: MatDialog) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.master;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.master_flow;

  public listFlow: FlowStruct[];
  public listTotemButtonResponse: ListTotemButtonResponse;
  public isLoading: boolean = true;
  public isActive: boolean;
  public searchText: string;
  public listIdTags: number[];
  public listAllTags: TagStruct[];
  ip: string = '192.168.0.2';

  ngOnInit(): void {
    this.populateTotemNameList();
    this.populateTags()
    this.search();

    setTimeout(() => { this.getIP(); }, 2000);
  }

  getIP() {
    this.utilService.getIP().subscribe({
      next: (response) => {
        if (response && response.ip) {
          this.ip = response.ip;
        }
      }
    });
  }

  search() {
    this.isLoading = true;
    if (this.searchText == "")
      this.searchText = null;

    this.flowService.listFlow(this.searchText, this.isActive, JSON.stringify(this.listIdTags)).subscribe({
      next: (response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listFlow = response.listflow;


        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateTotemNameList() {
    this.totemButtonService.listTotemButton().subscribe({
      next: (response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listTotemButtonResponse = response;

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateTags() {
    this.flowTagService.listTags().subscribe({
      next: (response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        this.listAllTags = response.listTag;
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }

  getTotemName(idTotemButtom: number): string {
    if (this.listTotemButtonResponse && this.listTotemButtonResponse.listTotemButton)
      return idTotemButtom ? this.listTotemButtonResponse.listTotemButton.find(x => x.idTotemButton == idTotemButtom)?.totemName : null;

    return null;
  }

  openModal(idFlow) {
    const dialogRef = this.dialog.open(FlowDeleteModalComponent, {
      data: {
        idFlow: idFlow,
        ip: this.ip
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.deleteFlow) {
        this.search();
      }
    });
  }
}