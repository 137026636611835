<div class="totem">
    <header>
        <span *ngIf="!authenticated">
            <img class="brand" src="assets/images/logo-branco.png">
        </span>

        <h1 *ngIf="authenticated" class="header__h1">{{totemName}}</h1>
        <h3 *ngIf="authenticated" class="header__h3">{{healthUnitStruct? healthUnitStruct.healthUnitName : null}}</h3>
        <button *ngIf="authenticated && logged" mat-flat-button type="button" (click)="logoutModal()">Sair</button>
    </header>
    <div *ngIf="!onlineOffline" class="offline">
        <span><mat-icon class="offline--icon">wifi_off</mat-icon>Você esta sem conexão com a internet no momento.</span>
    </div>
    <app-hygienization-pin-totem *ngIf="!authenticated" (onAuthenticated)="checkPin($event)"
        [isLoading]="isLoading"></app-hygienization-pin-totem>
    <app-login-hygienization-totem *ngIf="authenticated && !logged" (onLogged)="checkLogin($event)"
        [isLoading]="isLoading" [idHealthUnit]="idHealthUnit" [idTotem]="idTotem">
    </app-login-hygienization-totem>
    <app-totem-bed-hygienization *ngIf="authenticated && logged" [idTotem]="idTotem"
        (onLogout)="checkLoggout()"></app-totem-bed-hygienization>
</div>