import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { observable, of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthorizeAccessKeyResponse } from '../../responses/integration/authorize-access-key.response';
@Injectable({
  providedIn: 'root'
})
export class ValidationAccessKeyService {

  constructor(private router: Router, private httpClient: HttpClient) {
  }

  addHeaderAccessKey(token: string) {
    let headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Authorization': token
    }

    let requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return requestOptions;
  }

  public validate(token: string): Observable<AuthorizeAccessKeyResponse> {

    let uri = `AccessKeyAuth`;

    return this.httpClient.get<AuthorizeAccessKeyResponse>(environment.urlApiIntegration + uri, this.addHeaderAccessKey(token))
      .pipe(
        catchError(error => {
          let result: AuthorizeAccessKeyResponse = {
            token: '',
            isError: true,
            errorCode: 0,
            errorDescription: error && error.error && error.error.errorDescription ? error.error.errorDescription : error
          }
          return of(result);
        })
      );
  }
}
