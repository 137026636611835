export class PharmacyTagRequest {
     public idStorage: number;

     public productName: string;

     public dateExpiration: string;

     public batch: string;

     public amountTotal: number;

     public width: number;

     public height: number;
}