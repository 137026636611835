import { Component, Input, OnInit } from '@angular/core';
import { GenerateEpisodeResponse } from 'src/app/shared/services/responses/orchestrator-totem/generate-episode.response';

@Component({
  selector: 'app-totem-success',
  templateUrl: './totem-success.component.html',
  styleUrls: ['./totem-success.component.css']
})
export class TotemSuccessComponent implements OnInit {

  @Input() generateEpisodeResponse: GenerateEpisodeResponse;

  public ticket: string;

  constructor() { }

  ngOnInit(): void {
    this.ticket = this.generateEpisodeResponse.ticketInitials + this.generateEpisodeResponse.ticketSequence
  }
}
