import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Access } from 'src/app/shared/services/models/private-billing/access.model';
import { Technique } from 'src/app/shared/services/models/private-billing/technique.model';
import { FeesProcedureStruct } from 'src/app/shared/services/structs/private-billing/fees-procedure.struct';
import { TerminologyGroup } from 'src/app/shared/services/models/private-billing/terminology-group.model';
import { ParticipationDegree } from 'src/app/shared/services/models/private-billing/participation-degree.model';
import { HealthcareAgreementStruct } from 'src/app/shared/services/structs/private-billing/healthcare-agreement.struct';
import { ProfessionalAgreementStruct } from 'src/app/shared/services/structs/private-billing/professional-agreement.struct';
import { Cbo } from 'src/app/shared/services/models/private-billing/cbo.model';
import { TissBilling } from 'src/app/shared/services/models/private-billing/tiss-billing.model';
import { State } from 'src/app/shared/services/models/private-billing/state.model';
import { ProfessionalCouncil } from 'src/app/shared/services/models/private-billing/professional-council.model';
import { FeesRequiredField } from 'src/app/shared/services/models/private-billing/fees-required-field.model';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { FeesProcedureModalComponent } from 'src/app/shared/components/tiss-guides/tiss-guide-fees-modal/fees-procedure-modal/fees-procedure-modal.component';
@Component({
  selector: 'app-fees-procedure-form-history',
  templateUrl: './fees-procedure-form-history.component.html',
  styleUrls: ['./fees-procedure-form-history.component.css']
})
export class FeesProcedureFormHistoryComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    private alertService: AlertService,
  ) { }

  @Input() idFeesGuide: number = null;
  @Input() listFeesProcedure: FeesProcedureStruct[] = [];
  @Input() accessMap: Map<number, Access>;
  @Input() techniqueMap: Map<number, Technique>;
  @Input() participationDegreeMap: Map<number, ParticipationDegree>;
  @Input() tussTerminologyGroupMap: Map<number, TerminologyGroup>;
  @Input() cboMap: Map<number, Cbo>;
  @Input() healthcareAgreementStruct: HealthcareAgreementStruct;
  @Input() listProfessionalAgreementStruct: ProfessionalAgreementStruct[];
  @Input() tissBilling: TissBilling;
  @Input() disableProcedureEdit: boolean;
  @Input() isEditingAllowed: boolean = null;
  @Input() isComingGuideReport: boolean = null;
  @Input() disableProcedureAdd: boolean;
  @Input() listState: State[] = [];
  @Input() listProfessionalCouncil: ProfessionalCouncil[] = [];
  @Input() listFeesRequiredField: FeesRequiredField[] = [];

  @Output() changeProcedure = new EventEmitter<any>();

  public isLoading: boolean = false;

  ngOnInit(): void {
  }

  ngOnDestroy() {
  }

  submit() {
  }

  newProcedure() {
    const dialogRef = this.dialog.open(FeesProcedureModalComponent, {
      data: {
        accessMap: this.accessMap,
        idFeesGuide: this.idFeesGuide,
        techniqueMap: this.techniqueMap,
        participationDegreeMap: this.participationDegreeMap,
        tussTerminologyGroupMap: this.tussTerminologyGroupMap,
        cboMap: this.cboMap,
        healthcareAgreement: this.healthcareAgreementStruct,
        listProfessionalAgreementStruct: this.listProfessionalAgreementStruct,
        tissBiling: this.tissBilling,
        listState: this.listState,
        listProfessionalCouncil: this.listProfessionalCouncil,
        listFeesRequiredField: this.listFeesRequiredField,
        isEditingAllowed: this.isEditingAllowed,
      },
      panelClass: 'fees-procedure-modal',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.procedureStruct) {
        if (this.idFeesGuide != null)
          result.procedureStruct.idFeesGuide = this.idFeesGuide;
        result.procedureStruct.procedureReferenceIndex = this.listFeesProcedure.length + 1;
        this.listFeesProcedure.push(result.procedureStruct as FeesProcedureStruct);
        this.changeProcedure.emit();
      }
    });
  }

  editProcedure(event: any, index: number) {
    const dialogRef = this.dialog.open(FeesProcedureModalComponent, {
      data: {
        accessMap: this.accessMap,
        idFeesGuide: this.idFeesGuide,
        techniqueMap: this.techniqueMap,
        participationDegreeMap: this.participationDegreeMap,
        tussTerminologyGroupMap: this.tussTerminologyGroupMap,
        cboMap: this.cboMap,
        healthcareAgreement: this.healthcareAgreementStruct,
        listProfessionalAgreementStruct: this.listProfessionalAgreementStruct,
        tissBiling: this.tissBilling,
        feesProcedureStruct: this.listFeesProcedure[index],
        listState: this.listState,
        listProfessionalCouncil: this.listProfessionalCouncil,
        isEditingAllowed: this.isEditingAllowed,
      },
      panelClass: 'fees-procedure-modal',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.procedureStruct) {
        if (this.idFeesGuide != null)
          result.procedureStruct.idFeesGuide = this.idFeesGuide;
        this.listFeesProcedure[index] = result.procedureStruct;
        this.changeProcedure.emit();
      }
    });
  }

  deleteProcedure(event: any, index: number) {
    const feesProcedure = this.listFeesProcedure.find((item, indexOf) => indexOf == index);
    if (!feesProcedure) {
      this.alertService.show('Erro', "Objeto não encontrado na lista", AlertType.error);
      return;
    }      

    this.listFeesProcedure.splice(index, 1);
    this.changeProcedure.emit();
  }

  reset() {
    this.listFeesProcedure = [];
    this.listProfessionalAgreementStruct = [];
  }
}