import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';

@Injectable({
  providedIn: 'root'
})
export class RoomAccessHistoryService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public postRoomAccessHistory(idRoom: number): Observable<ReturnStruct> {
    
    let uri = `RoomAccessHistory/idRoom/${idRoom}`;  
    
    return this.httpClient.post<ReturnStruct>(environment.urlApiFlow + uri, null ,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
