import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CommonService } from "src/app/shared/services/common-service";
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';
import { catchError } from "rxjs/operators";
import { ReturnStruct } from "src/app/shared/structs/return.struct";
;
import { ListPatientHistoryResponse } from "../../responses/medical-record/list-patient-history.response";

@Injectable({
    providedIn: 'root'
})
export class PatientMedicalRecordService extends CommonService {

    constructor(private router: Router, private httpClient: HttpClient) {
        super();
    }


    public Validate(idEpisode: number, cpf: string): Observable<ReturnStruct> {
        let uri = `PatientMedicalRecord/validate/idEpisode/${idEpisode}?cpf=${cpf}`;

        return this.httpClient.get<ReturnStruct>(environment.urlApiMedicalRecord + uri)
            .pipe(
                catchError(this.handleError)
            )
    }
}