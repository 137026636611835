import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { TotemNpsNotes } from '../../models/nps/totem-nps-notes.model';

@Injectable({
  providedIn: 'root'
})
export class ClientTotemNpsNotesService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public postNote(body: TotemNpsNotes): Observable<ReturnStruct>{

    let uri = `ClientTotemNpsNotes`

    return this.httpClient.post<ReturnStruct>(environment.urlApiNps + uri, body)
    .pipe(
      catchError(this.handleError)
    )
  }
}
