<div class="container-img-support">
    <div class="container-body-support">
        <div class="buttons-and-text row">
            <div class="util-buttons row">
                <p>
                    Seja bem-vindo(a) ao <b>Suporte ToLife!</b>
                </p>
                <div *ngFor = "let item of listSupportContact" class = "col-12">
                    <a *ngIf="isMobileVersion && item.idSupportContactType == this.supportContactTypeEnum.telefone" mat-flat-button type="button" href="tel:{{item.contactValue}}"
                        class="button-selection">
                        <mat-icon class="icon" aria-hidden="false"
                            aria-label="call">
                            call
                        </mat-icon>
                        {{item.buttonName}}
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </a>

                    <button *ngIf="!isMobileVersion && item.idSupportContactType == this.supportContactTypeEnum.telefone" mat-flat-button type="button" class="button-selection" (click)="openTelephoneModal(item)">
                        <mat-icon class="icon" aria-hidden="false" aria-label="call">
                            call
                        </mat-icon>
                        {{item.buttonName}} 
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>

                    <button *ngIf=" item.idSupportContactType == this.supportContactTypeEnum.link" mat-flat-button class="button-selection" (click)="openUrlLink(item.contactValue)">
                        <mat-icon class="icon" aria-hidden="false" aria-label="internet">
                            <img src="/assets/images/client-support-consulting/internet-icon.png" alt="Internet icon" class="social-media-icon"/>
                        </mat-icon>
                        {{item.buttonName}}                      
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>

                    <a *ngIf="item.idSupportContactType == this.supportContactTypeEnum.email" mat-flat-button type="button" class="button-selection" (click)="sendEmail(item.contactValue)">
                        <mat-icon class="icon" aria-hidden="false"
                            aria-label="mail">
                            mail
                        </mat-icon>
                        {{item.buttonName}} 
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </a>

                    <button *ngIf="item.idSupportContactType == this.supportContactTypeEnum.whatsapp" mat-flat-button class="button-selection" (click)="openUrlLink(item.contactValue)">
                        <mat-icon class="icon">
                            <img src="/assets/images/client-support-consulting/whatsapp-icon.png" alt="WhatsApp icon" class="social-media-icon" />
                        </mat-icon>                        
                        {{item.buttonName}}  
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
                <div class="information col-12">
                    <p><b>Importante:</b></p>
                    <p>O nosso suporte do WhatsApp é utilizado para tratar tickets abertos previamente via telefone.</p>
                </div> 
                <hr/>
                <div class="follow col-12">
                    <p>Siga a ToLife nas redes sociais:</p>
                    <div class = "follow-icons">
                        <a href="https://www.facebook.com/ToLifeSolucoesInovadoras" target="_blank">
                            <mat-icon class="icon col-3">
                                <img src="/assets/images/client-support-consulting/facebook-icon.png" alt="facebook icon" class="social-media-icon" />
                            </mat-icon> 
                        </a> 
                        <a href="https://www.instagram.com/tolifeoficial/" target="_blank">
                            <mat-icon class="icon col-3">
                                <img src="/assets/images/client-support-consulting/instagram-icon.png" alt="instagram icon" class="social-media-icon" />
                            </mat-icon> 
                        </a> 
                        <a href="https://www.youtube.com/@ToLifeOficial" target="_blank">
                            <mat-icon class="icon col-3">
                                <img src="/assets/images/client-support-consulting/youtube-icon.png" alt="youtube icon" class="social-media-icon" />
                            </mat-icon> 
                        </a> 
                        <a href="https://www.linkedin.com/company/tolife-s-a/" target="_blank">
                            <mat-icon class="icon col-3">
                                <img src="/assets/images/client-support-consulting/linkedin-icon.png" alt="linkedin icon" class="social-media-icon" />
                            </mat-icon> 
                        </a> 
                    </div>
                </div>
            </div>
        </div>
        <div class="logo-and-text">
            <img src="/assets/images/client-support-consulting/logo-toLife.png" alt="tolife" class="logo-tolife" />
        </div>
    </div>   
</div>