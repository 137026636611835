import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { BillingDataService } from 'src/app/shared/services/API/billing/billing-data.service';
import { MedicalCareSadtService } from 'src/app/shared/services/API/medical-record/medical-care-sadt.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { BillingDataRequest } from 'src/app/shared/services/requests/billing/billing-data-request';
import { SadtGroupStruct } from 'src/app/shared/services/structs/medical-record/sadt-group.struct';

@Component({
  selector: 'app-sadt-attach-file-modal',
  templateUrl: './sadt-attach-file-modal.component.html',
  styleUrls: ['./sadt-attach-file-modal.component.css']
})
export class SadtAttachFileModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private alertService: AlertService,
    public matDialogRef: MatDialogRef<SadtAttachFileModalComponent>,
    public medicalCareSadtService: MedicalCareSadtService,
    private billingDataService: BillingDataService,
  ) { }

  public isFirstLoading: boolean = false;
  public isLoading: boolean;
  private fileLinkedLater: boolean = true;

  public listMedicalCareSadt: SadtGroupStruct[];

  private datetimeStartAttendance: Date;

  public selectedEpisode: number;
  public idSadtGroup: number;

  ngOnInit(): void {
    this.listMedicalCareSadt = this.data.listMedicalCareSadt;
    this.isLoading = false;
  }

  clickCancel() {
    this.matDialogRef.close({ listMedicalCareSadt: this.listMedicalCareSadt, selectedEpisode: this.selectedEpisode });
  }

  saveSadt(event) {
    event.sadt.isLoadingSadt = true;
    event.medicalCareSadtRequest.datetimeStartAttendance = this.datetimeStartAttendance;
    this.fileLinkedLater = event.medicalCareSadtRequest.fileLinkedLater;
    this.idSadtGroup = event.medicalCareSadtRequest.idSadtGroup;
    this.selectedEpisode = event.sadt.idEpisode;
    this.medicalCareSadtService.updateMedicalCareSadt(event.medicalCareSadtRequest.idSadtGroup, event.medicalCareSadtRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          event.sadt.isLoadingSadt = false;
          return;
        }

        let billingDataRequest = this.mapToBillingDataRequest(event.idMedicalCareSatd, event.listProcedureSigtap);
        this.postBillingData(billingDataRequest, event.codCbo, event.sadt);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        event.sadt.isLoadingSadt = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  mapToBillingDataRequest(idRelation: number, listProcedureSigtap: any[]): BillingDataRequest {
    let billingDataRequest: BillingDataRequest = new BillingDataRequest();
    billingDataRequest.idRelation = idRelation;
    billingDataRequest.idModule = MenuModuleEnum.sadt;
    billingDataRequest.idEpisode = this.selectedEpisode;
    billingDataRequest.listIdProcedure = listProcedureSigtap.map(a => a.idProcedureSigtap);
    return billingDataRequest;
  }

  postBillingData(billingDataRequest: BillingDataRequest, codCbo: string, sadt) {
    if (codCbo) {
      this.billingDataService.postBillingData(billingDataRequest).subscribe({
        next: (response) => {
          this.isLoading = false;
          if (response.isError) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            return;
          }
        },
        error: (error) => {
          console.log(error);
          this.isLoading = false;
          this.alertService.show('Erro inesperado', error, AlertType.error);
        }
      });
    }

    this.alertService.show('Sucesso', "Pedido de exame atualizado com sucesso", AlertType.success);
    sadt.isLoadingSadt = false;

    if (!this.fileLinkedLater) {
      this.listMedicalCareSadt.forEach(x => {
        if (this.idSadtGroup == x.idSadtGroup)
          x.fileLinkedLater = this.fileLinkedLater;
      });

      this.data.listMedicalCareSadt = this.data.listMedicalCareSadt.filter(x => x.idSadtGroup !== this.idSadtGroup);
    }
  }
}