import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PatientModel } from 'src/app/shared/services/models/medical-record/patient.model';
import { PriorityModel } from 'src/app/shared/services/models/totem/priority.model';
import { TotemButtonStruct } from "src/app/shared/services/structs/totem/totem-button.struct";
import { TotemRequest } from '../../../../../shared/services/requests/orchestrator-totem/orchestrator-totem.request';
import { UtilsClientService } from 'src/app/client/utils.service';

@Component({
  selector: 'app-totem-options',
  templateUrl: './totem-options.component.html',
  styleUrls: ['./totem-options.component.css']
})
export class TotemOptionsComponent implements OnInit {

  @Input() listPriority: PriorityModel[];
  @Input() listTotemPriority: PriorityModel[];
  @Input() birthDate: Date;
  @Input() listTotemButton: TotemButtonStruct[];
  @Input() state: string;
  @Input() isLoading: string;
  @Input() idHealthUnit: number;
  @Input() timeZoneValue: number;

  @Output() onFinish: EventEmitter<any> = new EventEmitter<any>();
  @Output() populatePriorities: EventEmitter<any> = new EventEmitter<any>();

  totemButton: TotemButtonStruct;
  idPatient: number;
  totemRequest: TotemRequest = new TotemRequest();

  constructor(private utilsClientService: UtilsClientService) { }

  ngOnInit(): void {

  }

  selectedOption(idTotemButton: number) {
    this.totemButton = this.listTotemButton.find(x => x.idTotemButton == idTotemButton);
    this.state = 'identifier';
  }

  setIdPatient(patient: PatientModel) {
    if (patient)
      this.idPatient = patient.idPatient; this.birthDate = patient.birthDate;

    this.state = 'priority';
  }

  backToIdPatient() {
    this.idPatient = null;
    this.birthDate = null;
    this.state = 'identifier';

    this.populatePriorities.emit();
  }

  backToOption() {
    this.totemButton = null;
    this.state = 'options';
  }

  printToOption(prioritiesSelected: number[]) {
    this.totemRequest.idTotemButton = this.totemButton.idTotemButton;
    this.totemRequest.listPriority = prioritiesSelected;
    this.totemRequest.listTotemPriority = prioritiesSelected;
    this.totemRequest.birthDate = this.birthDate;
    this.totemRequest.idPatient = this.idPatient;
    this.totemRequest.isSchedule = this.totemButton.isScheduleToggle;
    this.totemRequest.idUserPatient = this.utilsClientService.getIdUserPatientSchedule();
    this.totemRequest.idSchedule = this.utilsClientService.getIdSchedule();

    this.onFinish.emit(this.totemRequest);
  }
}