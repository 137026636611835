import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { FrontDeskComponent } from './pages/front-desk/front-desk.component';
import { PatientRegisterComponent } from './pages/patient-register-master/patient-register/patient-register.component';
import { PatientListComponent } from './pages/patient-list/patient-list.component';
import { FrontDeskListComponent } from './pages/front-desk-list/front-desk-list.component';
import { FrontDeskRegisterComponent } from './pages/front-desk-register/front-desk-register.component';
import { PatientRegisterMasterComponent } from './pages/patient-register-master/patient-register-master.component';

const routes: Routes = [
  { path: 'front-desk', component: FrontDeskComponent, canActivate: [AuthGuard] },
  { path: 'front-desk/patient-register-master', component: PatientRegisterMasterComponent, canActivate: [AuthGuard] },
  { path: 'front-desk/patient-list', component: PatientListComponent, canActivate: [AuthGuard] },
  { path: 'front-desk/front-desk-config', component: FrontDeskListComponent, canActivate: [AuthGuard] },
  { path: 'front-desk/front-desk-config/register', component: FrontDeskRegisterComponent, canActivate: [AuthGuard] },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FrontDeskRoutingModule { }
