<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-confirm-modal-list">
            <h3>Deseja excluir a AIH?</h3>
        </div>
        <form class="form" [formGroup]="model"  (ngSubmit)="submit()">
            <div class="body-confirm-modal-list">     
                <div class="row">   
                    <div class="col-md-12">                   
                        <mat-form-field appearance="outline">
                            <mat-label>Motivo</mat-label>
                            <textarea matInput formControlName="deletedMotive" oninput="this.value = this.value.replace(/[\n\r]/g, '');"></textarea>
                            <mat-error *ngIf="model.get('deletedMotive').invalid">Informe o motivo!</mat-error>
                        </mat-form-field>
                    </div>
                </div>            
            </div>
            <div class="footer-confirm-modal-list">
                <button mat-flat-button color="primary" class="btn-block" type="submit">
                    <span *ngIf="isLoading == false">Excluir</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
                <button mat-flat-button color="accent" class="btn-block"(click)="clickCancel()" type="button">
                    <span *ngIf="isLoading == false">Cancelar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>                
            </div>
        </form> 
    </div>
</div>