import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';

@Component({
  selector: 'app-patient-care-lines-modal',
  templateUrl: './patient-care-lines-modal.component.html',
  styleUrls: ['./patient-care-lines-modal.component.css']
})
export class PatientCareLinesModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<PatientCareLinesModalComponent>,
    private formBuilder: UntypedFormBuilder,
    public maskService: MaskService) { }

  public model: UntypedFormGroup;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      listIdComorbidity: [{ value: this.data.listComorbidity.listIdComorbidity, disabled: true }],
      otherComorbidity: [{ value: this.data.listComorbidity.otherComorbidity, disabled: true }],
      listIdMedicine: [{ value: this.data.listMedication.listIdMedicine, disabled: true }],
      otherMedicine: [{ value: this.data.listMedication.otherMedicine, disabled: true }],
      listIdCareLine: [{ value: this.data.listCareLine.listIdCareLine, disabled: true }],
    });
  }

  close() {
    this.matDialogRef.close();
  }
}