import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs/internal/Observable";
import { catchError } from "rxjs/internal/operators/catchError";
import { environment } from "src/environments/environment";
import { CommonService } from "../../common-service";
import { BillingOverviewMonthResponse } from "../../responses/billing-by-health-unit/billing-overview-month.response";
import { LookupResponse } from "../../responses/billing-by-health-unit/lookup.response";

@Injectable({
  providedIn: 'root'
})
export class BillingOverviewMonthService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  getBillingOverviewPerYear(year: number): Observable<BillingOverviewMonthResponse> {
    let uri = `BillingOverviewMonth/year/${year}`

    return this.httpClient.get<BillingOverviewMonthResponse>(environment.urlApiOrchestratorBillingByHealthUnit + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  listBillingOverview(year: number): Observable<BillingOverviewMonthResponse> {
    let uri = `BillingOverviewMonth/year/${year}`

    return this.httpClient.get<BillingOverviewMonthResponse>(environment.urlApiOrchestratorBillingByHealthUnit + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}