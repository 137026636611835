import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MedicalRecordService } from 'src/app/shared/services/API/medical-record/medical-record.service';


@Component({
  selector: 'app-medical-procedure-delete-modal',
  templateUrl: './medical-procedure-delete-modal.component.html',
  styleUrls: ['./medical-procedure-delete-modal.component.css']
})
export class MedicalProcedureDeleteModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<MedicalProcedureDeleteModalComponent>,
  private medicalRecordService: MedicalRecordService,
  private alertService: AlertService,) { }


  public isLoading:boolean;  
  
  ngOnInit(): void {
    this.isLoading = false;
  }
  
  close(){
    this.matDialogRef.close({deleteProcedure: false}); 
  }
  
  clickDelete(){
    
    this.isLoading = true;

    this.medicalRecordService.deleteMedicalProcedure(this.data.idMedicalProcedure).subscribe((response)=>{
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.alertService.show('Sucesso', "Procedimento excluido com sucesso!", AlertType.success); 
      this.matDialogRef.close({deleteProcedure: true}); 
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }
  
  clickCancel(){
    this.matDialogRef.close({deleteProcedure: false}); 
  }
}