import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DowntimeService } from 'src/app/client/downtime.service';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { BedHygienizationConfigService } from 'src/app/shared/services/API/bed/bed-hygienization-config.service';
import { BedHygienizationService } from 'src/app/shared/services/API/bed/bed-hygienization.service';
import { UserGetNameService } from 'src/app/shared/services/API/user/user-get-name.service';
import { BedHygienizationRequest } from 'src/app/shared/services/requests/bed/bed-hygienization.request';
import { BedStruct } from 'src/app/shared/services/structs/bed/bed.struct';
import { TotemEndHygienizationModalComponent } from './totem-end-hygienization-modal/totem-end-hygienization-modal.component';
import { TotemHygienizationModalComponent } from './totem-hygienization-modal/totem-hygienization-modal.component';

@Component({
  selector: 'app-totem-bed-hygienization',
  templateUrl: './totem-bed-hygienization.component.html',
  styleUrls: ['./totem-bed-hygienization.component.css']
})
export class TotemBedHygienizationComponent implements OnInit {

  @Output() onLogout: EventEmitter<any> = new EventEmitter<any>();
  @Input() idTotem: number;

  constructor(
    private bedHygienizationService: BedHygienizationService,
    private bedHygienizationConfigService: BedHygienizationConfigService,
    private userGetNameService: UserGetNameService,
    private alertService: AlertService,
    private downtimeService: DowntimeService,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.observation;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.observation_bed_hygienization;

  public listHygienizedBed: BedStruct[];
  public listUnhygienizedBed: BedStruct[];
  public listHygienizingBed: BedStruct[];
  public listBedInUse: BedStruct[];
  public filterListUnhygienizedBed: BedStruct[];
  public filterListHygienizedBed: BedStruct[];
  public filterListHygienizingBed: BedStruct[];
  public isLoading: boolean;
  public searchText: string;
  public hygienizationConfig: boolean;
  public model: FormGroup;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      hygienization: [null],
    });
    this.getConfig();
    this.listBed();

    this.downtimeService.startTimer(60000);
    this.downtimeService.watcher().subscribe({
      next: (res) => {
        if (res) {
          if (alert) {
            this.downtimeService.stopTimer();
            this.dialog.closeAll();
            this.onLogout.emit(false);
            let urlBaseTotem: string = this.router.url.substring(1);
            this.router.navigateByUrl(urlBaseTotem);
          }
        }
      }
    });
  }

  listBed() {
    this.isLoading = true;
    this.bedHygienizationService.listBedHygienization().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listUnhygienizedBed = response.listUnhygienizedBed;
        this.listHygienizedBed = response.listHygienizedBed;
        this.listHygienizingBed = response.listHygienizingBed;
        this.listBedInUse = response.listHygienizingBed;
        let listIdUser: number[] = [];

        if ((this.listHygienizingBed) || (this.listHygienizedBed)) {
          if (this.listHygienizingBed.length > 0)
            listIdUser.concat(this.listHygienizingBed.map(x => x.idHygienizationUser));

          if (this.listHygienizedBed.length > 0)
            listIdUser.concat(this.listHygienizedBed.map(x => x.idHygienizationUser));

          this.getUserNames(listIdUser);
        }
        else
          this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getUserNames(listIdUser: number[]) {
    this.isLoading = true;
    this.userGetNameService.listUser(listIdUser).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (this.listHygienizingBed != null && this.listHygienizingBed.length > 0) {
          this.listHygienizingBed.forEach(x => {
            x.hygienizationUserName = response.listUser.find(y => y.idUser == x.idHygienizationUser).userName;
          });
        }

        if (this.listHygienizingBed != null && this.listHygienizingBed.length > 0) {
          this.listHygienizedBed.forEach(x => {
            x.hygienizationUserName = response.listUser.find(y => y.idUser == x.idHygienizationUser).userName;
          });
        }

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }


  getConfig() {
    this.isLoading = true;

    this.bedHygienizationConfigService.getConfig().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.hygienizationConfig = response.hygienization;
        this.model.get('hygienization').setValue(response.hygienization);
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  startHygienization(bed: BedStruct) {
    this.downtimeService.resetTimer();
    let request: BedHygienizationRequest = new BedHygienizationRequest();

    request.idBed = bed.idBed;

    const dialogRef = this.dialog.open(TotemHygienizationModalComponent, {
      data: {
        idBed: bed.idBed,
        startHygienization: true,
      },
    });

    dialogRef.afterClosed().subscribe({
      next: result => {
        this.downtimeService.resetTimer();
        if (result && result.confirm && result.start) {
          this.bedHygienizationService.startBedHygienization(request).subscribe({
            next: (response) => {
              if (response.isError) {
                this.alertService.show('Erro', response.errorDescription, AlertType.error);
                this.isLoading = false;
                return;
              }

              this.isLoading = true;
              this.listBed();
            },
            error: (error) => {
              console.log(error)
              this.isLoading = false;
              this.alertService.show('Erro inesperado', error, AlertType.error);
            }
          });
        }
      }
    });
  }

  finishHygienization(bed: BedStruct) {
    this.downtimeService.resetTimer();

    let request: BedHygienizationRequest = new BedHygienizationRequest();
    request.idBed = bed.idBed;

    const dialogRef = this.dialog.open(TotemHygienizationModalComponent, {
      data: {
        idBed: bed.idBed,
        startHygienization: false,
      },
    });

    dialogRef.afterClosed().subscribe({
      next: result => {
        this.downtimeService.resetTimer();
        if (result && result.confirm && result.finish) {
          this.bedHygienizationService.finishBedHygienization(request).subscribe({
            next: (response) => {
              if (response.isError) {
                let errorString: string = response.errorDescription;
                if (response.errorCode === 1)
                  errorString = bed.hygienizationUserName.concat(errorString)

                this.alertService.show('Erro', errorString, AlertType.error);
                this.isLoading = false;
                return;
              }
            }
          });

          this.totemEndHygienization();
        }
      }
    });
  }

  totemEndHygienization() {
    const dialogRef = this.dialog.open(TotemEndHygienizationModalComponent, {
      data: {
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.evade) {
          this.dialog.closeAll();
          this.onLogout.emit(false);
          let urlBaseTotem: string = this.router.url.substring(1);
          this.router.navigateByUrl(urlBaseTotem);
        }
        else
          this.listBed();

        this.alertService.show('Sucesso', "Leito higienizado com sucesso", AlertType.success);
      }
    });
  }
}