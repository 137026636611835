import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { ProcedureSigtapCBOResponse } from '../../responses/billing/procedure-sigtap-cbo-response';
import { ProcedureSigtapCompetenceResponse } from '../../responses/billing/procedure-sigtap-competence.response';
import { GetProcedureSigtapCBOResponse } from '../../responses/billing/get-procedure-sigtap-cbo-response';

@Injectable({
  providedIn: 'root'
})
export class ProcedureSigtapCBOService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  listProcedureSigtapByCbo(codCbo: string): Observable<ProcedureSigtapCBOResponse>{
    let uri = `ProcedureSigtapCBO/codCbo/${codCbo}`
      
    return this.httpClient.get<ProcedureSigtapCBOResponse>(environment.urlApiBilling + uri,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  listProcedureSigtapCodProcedure(codProcedure:number[], idCompetence:number, codCbo:string): Observable<ProcedureSigtapCBOResponse>{
    let uri = `RelationTriggerProcedureSigtap/idCompetence/${idCompetence}/cbo/${codCbo}?listTriggers=${JSON.stringify(codProcedure)}`;
      
    return this.httpClient.get<ProcedureSigtapCBOResponse>(environment.urlApiBilling + uri,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  getProcedureSigtapByIdSigtap(idProcedureSigtap: number): Observable<GetProcedureSigtapCBOResponse>{
    let uri = `ProcedureSigtapCBO/idProcedureSigtap/${idProcedureSigtap}`
      
    return this.httpClient.get<GetProcedureSigtapCBOResponse>(environment.urlApiBilling + uri,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
