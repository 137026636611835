import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { ReportModalComponent } from 'src/app/shared/components/report-modal/report-modal.component';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ItemService } from 'src/app/shared/services/API/pharmacy/Item.service';
import { LocationService } from 'src/app/shared/services/API/pharmacy/location.service';
import { MedicineService } from 'src/app/shared/services/API/pharmacy/medicine.service';
import { PharmacyMovementService } from 'src/app/shared/services/API/pharmacy/pharmacy-movement.service';
import { StorageService } from 'src/app/shared/services/API/pharmacy/storage.service';
import { PharmacyTagService } from 'src/app/shared/services/API/report/pharmacy-tag.service';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { ItemModel } from 'src/app/shared/services/models/pharmacy/item.model';
import { LocationModel } from 'src/app/shared/services/models/pharmacy/location.model';
import { StorageRequest } from 'src/app/shared/services/requests/pharmacy/pharmacy.request';
import { PharmacyTagRequest } from 'src/app/shared/services/requests/Report/pharmacy-tag.request';
import { ListMedicineResponse } from 'src/app/shared/services/responses/pharmacy/list-medicine.response';
import { MedicineStruct } from 'src/app/shared/services/structs/pharmacy/medicine.struct';
import { StorageLocationStruct } from 'src/app/shared/services/structs/pharmacy/storage-location.struct';


@Component({
  selector: 'app-pharmacy-movement-register',
  templateUrl: './pharmacy-movement-register.component.html',
  styleUrls: ['./pharmacy-movement-register.component.css']
})
export class PharmacyMovementRegisterComponent implements OnInit {

  constructor(private formBuilder: UntypedFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private pharmacyMovementService: PharmacyMovementService,
    public dialog: MatDialog,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.pharmacy;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.pharmacy_movement;

  public isLoading: boolean;
  public isFirstLoading: boolean;  
  public idMedicine:number = null;
  public idItem:number = null;
  public productCode: string = "";
  public totalAmount: number = 0;
  public totalAmountRemaining: number = 0;
  public productDescription: string = "";
  public storage: StorageLocationStruct[];
  public minimumAmount: number;

  ngOnInit(): void {
    this.isFirstLoading = true;
    if (this.activatedRoute.snapshot.paramMap.get('idItem'))
      this.idItem = parseInt(this.activatedRoute.snapshot.paramMap.get('idItem'));

      if (this.activatedRoute.snapshot.paramMap.get('idMedicine'))
      this.idMedicine = parseInt(this.activatedRoute.snapshot.paramMap.get('idMedicine'));

      this.populateStorageData();
  }

  populateStorageData() {
    this.pharmacyMovementService.getStorage(this.idMedicine, this.idItem).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.productCode = response.productCode;
      this.totalAmount = response.totalAmount;
      this.totalAmountRemaining = response.totalAmountRemaining;
      this.productDescription = response.productDescription;
      this.minimumAmount = response.minimumAmount;
      this.storage = response.storage;
      this.isLoading = false;
      this.isFirstLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }
}

