import { MedicalProcedureModel } from "../../models/medical-record/medical-procedure.model";
import { AihStruct } from "../../structs/hospital-admission-authorization/aih.struct";
import { AllergyStruct } from "../../structs/medical-record/allergy.struct";
import { CareLinePriorityStatusStruct } from "../../structs/medical-record/care-line-priority-status.struct";
import { MedicatedSolutionStruct } from "../../structs/medical-record/medicated-solution.struct";
import { MemedCompletePrescriptionStruct } from "../../structs/medical-record/memed-complete-prescription.struct";
import { SadtGroupStruct } from "../../structs/medical-record/sadt-group.struct";
import { SuspicionStatusStruct } from "../../structs/medical-record/suspicion-status.struct";
import { ReadExternalPrescriptionStruct } from "../../structs/orchestrator-patient/read-external-prescription.struct";
import { ReadMedicalPrescriptionStruct } from "../../structs/orchestrator-patient/read-medical-prescription.struct";

export class MedicalCareRequest {

    public idMedicalCare: number;
    public idUser: number;
    public idEpisode: number;
    public temperature: number;
    public respiratoryFrequency: number;
    public heartRate: number;
    public glucose: number;
    public bloodPressureDiastole: number;
    public bloodPressureSystole: number;
    public saturation: number;
    public sickDaysOffAmount: number;
    public idRoomAttendance: number;
    public idStatusEpisode: number;
    public idQueueUsed: number;
    public conclusionType: number;

    public cid: number[];
    public listIdBypass: number[];
    public listIdComorbidity: number[] = [];

    public userName: string;
    public medicalNotes: string;
    public physicalExamination: string;
    public therapeuticPlan: string;
    public diagnosedHypothesis: string;
    public conclusionNote: string;
    public phone: string;

    public datetimeInclusion: Date;
    public datetimeEndIsolation: Date;
    public datetimeStartAttendance: Date;

    public isCidOnSickNote: boolean;
    public administrativeRelease: boolean;
    public remoteAttendance: boolean;
    public hasNewStandardPrescription: boolean;

    public careLinePriorityStatusStruct: CareLinePriorityStatusStruct = new CareLinePriorityStatusStruct();
    public suspicionStatusStruct: SuspicionStatusStruct = new SuspicionStatusStruct();

    public listAllergy: AllergyStruct[];
    public medicalCareSadt: SadtGroupStruct[];
    public medicalPrescription: ReadMedicalPrescriptionStruct[];
    public medicatedSolutionPrescription: MedicatedSolutionStruct[];
    public listExternalPrescription: ReadExternalPrescriptionStruct[];
    public medicalProcedure: MedicalProcedureModel[];
    public aihStruct: AihStruct; //Struct de preenchimetno da AIH, tipar com o struct correto depois
    public listMemedCompletePrescriptionStruct: MemedCompletePrescriptionStruct[];
    public userTokenMemed: string;
    public idSignatureProvider: number;
    public signatureAuthentication: string;
}