import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MedicRoutingModule } from './medic-routing.module';
import { MedicComponent } from './pages/medic/medic.component';
import { MedicPatientListComponent } from './pages/medic-patient-list/medic-patient-list.component';
import { SharedModule } from '../shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckbox, MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatTree, MatTreeModule } from '@angular/material/tree';
import { MatRadioButton, MatRadioModule } from '@angular/material/radio';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgxMaskModule } from 'ngx-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { AttendPatientComponent } from './pages/attend-patient/attend-patient.component';
import { MedicalCareAccordionComponent } from './pages/attend-patient/medical-care-accordion/medical-care-accordion.component';
import { PrescriptionModalComponent } from './pages/attend-patient/prescription-modal/prescription-modal.component';
import { CancelConfirmModalComponent } from './pages/attend-patient/cancel-confirm-modal/cancel-confirm-modal.component';
import { ConclusionNoteModalComponent } from './pages/attend-patient/conclusion-note-modal/conclusion-note-modal.component';
import { MedicBedManagementComponent } from './pages/medic-bed-management/medic-bed-management.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatPaginatorModule } from '@angular/material/paginator';
import { UpdateExternalPrescriptionModalComponent } from './pages/attend-patient/update-external-prescription-modal/update-external-prescription-modal.component';
import { MedicalCareReprintReportModalComponent } from './pages/medic-patient-list/medical-care-reprint-report-modal/medical-care-reprint-report-modal.component';
import { MedicConfigListComponent } from './pages/medic-config-list/medic-config-list.component';
import { MedicConfigDeleteModalComponent } from './pages/medic-config-list/medic-config-delete-modal/medic-config-delete-modal.component';
import { MedicConfigRegisterComponent } from './pages/medic-config-register/medic-config-register.component';
import { MedicNursingConsultationAccordionComponent } from './pages/attend-patient/medic-nursing-consultation-accordion/medic-nursing-consultation-accordion.component';
import { MedicineTreeComponent } from './pages/medic-config-register/medicine-tree/medicine-tree.component';
import { OtherQueuePatientModalComponent } from './pages/medic-patient-list/other-queue-patient-modal/other-queue-patient-modal.component';
import { ProcedureModalComponent } from './pages/attend-patient/procedure-modal/procedure-modal.component';
import { ProcedureListComponent } from './pages/procedure-list/procedure-list.component';
import { ProcedureDeleteModalComponent } from './pages/procedure-list/procedure-delete-modal/procedure-delete-modal.component';
import { ProcedureRegisterComponent } from './pages/procedure-register/procedure-register.component';
import { HealthUnitMedicalProcedureModalComponent } from './pages/medic-config-list/health-unit-medical-procedure-modal/health-unit-medical-procedure-modal.component';
import { MedicalProcedureModalComponent } from './pages/attend-patient/medical-procedure-modal/medical-procedure-modal.component';
import { AttendPatientOnlineComponent } from './pages/attend-patient-online/attend-patient-online.component';
import { PatientLeaveModalComponent } from './pages/attend-patient-online/patient-leave-modal/patient-leave-modal.component';
import { ReportSelectMedicModalComponent } from './pages/attend-patient/report-select-medic-modal/report-select-medic-modal.component';
import { EpisodeModalComponent } from './pages/attend-patient/episode-modal/episode-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { RemoteHoldModalComponent } from './pages/attend-patient/remote-hold-modal/remote-hold-modal.component';
import { PatientLeaveModalRemoteComponent } from './pages/attend-patient/patient-leave-modal/patient-leave-modal-remote.component';
import { AttendPatientOnlineAppointmentComponent } from './pages/attend-patient-online-appointment/attend-patient-online-appointment.component';
import { MedicHoldAppointmentModalComponent } from './pages/attend-patient-online-appointment/medic-hold-modal/medic-hold-appointment-modal.component';
import { MedicalScheduleComponent } from './pages/medical-schedule/medical-schedule.component';
import { ViewScheduledMedicalCareModalComponent } from './pages/medical-schedule/view-scheduled-medical-care-modal/view-scheduled-medical-care-modal.component';
import { ConfirmAllergicPatientModalComponent } from './pages/attend-patient/confirm-allergic-patient-modal/confirm-allergic-patient-modal.component';
import { SaveFavoritePrescriptionModalComponent } from './pages/attend-patient/favorite-prescription-modal/favorite-prescription-modal.component';
import { SelectFavoriteMedicineModalComponent } from './pages/attend-patient/select-favorite-medicine-modal/select-favorite-medicine-modal.component';
import { TimeOverModalComponent } from './pages/attend-patient-online-appointment/time-over-modal/time-over-modal.component';
import { MedicFavoriteStandardPrescriptionComponent } from './pages/medic-favorite-prescription/medic-favorite-standard-prescription/medic-favorite-standard-prescription.component';
import { MedicFavoriteStandardDeleteModalComponent } from './pages/medic-favorite-prescription/medic-favorite-standard-prescription/medic-favorite-standard-delete-modal/medic-favorite-standard-delete-modal.component';
import { FavoriteStandardMedicineComponent } from './pages/medic-favorite-prescription/medic-favorite-standard-prescription/favorite-standard-medicine/favorite-standard-medicine.component';
import { MedicFavoriteNonStandardPrescriptionComponent } from './pages/medic-favorite-prescription/medic-favorite-non-standard-prescription/medic-favorite-non-standard-prescription.component';
import { MedicFavoriteNonStandardDeleteModalComponent } from './pages/medic-favorite-prescription/medic-favorite-non-standard-prescription/medic-favorite-non-standard-delete-modal/medic-favorite-non-standard-delete-modal.component';
import { FavoriteNonStandardMedicineComponent } from './pages/medic-favorite-prescription/medic-favorite-non-standard-prescription/favorite-non-standard-medicine/favorite-non-standard-medicine.component';
import { AddNonstandardMedicineModalComponent } from './pages/medic-favorite-prescription/medic-favorite-non-standard-prescription/favorite-non-standard-medicine/add-nonstandard-medicine-modal/add-nonstandard-medicine-modal.component';
import { AddMedicineModalComponent } from './pages/medic-favorite-prescription/medic-favorite-standard-prescription/favorite-standard-medicine/add-medicine-modal/add-medicine-modal.component';
import { MedicFavoritePrescriptionSelectModalComponent } from './pages/medic-patient-list/medic-favorite-prescription-select-modal/medic-favorite-prescription-select-modal.component';
import { MedicFavoriteExamRequestComponent } from './pages/medic-favorite-prescription/medic-favorite-exam-request/medic-favorite-exam-request.component';
import { MedicFavoriteExamRequestDeleteModalComponent } from './pages/medic-favorite-prescription/medic-favorite-exam-request/medic-favorite-exam-request-delete-modal/medic-favorite-exam-request-delete-modal.component';
import { MedicFavoriteExamRequestRegisterComponent } from './pages/medic-favorite-prescription/medic-favorite-exam-request/medic-favorite-exam-request-register/medic-favorite-exam-request-register.component';
import { MedicFavoriteSadtModalComponent } from './pages/medic-favorite-prescription/medic-favorite-exam-request/medic-favorite-exam-request-register/medic-favorite-sadt-modal/medic-favorite-sadt-modal.component';
import { InvalidPrescriptionModalComponent } from './pages/attend-patient/invalid-prescription-modal/invalid-prescription-modal.component';
import { SelectFavoriteNonStandardMedicineModalComponent } from './pages/attend-patient/select-favorite-non-standard-medicine-modal/select-favorite-non-standard-medicine-modal.component';
import { SelectFavoriteExamPrescriptionModalComponent } from './pages/attend-patient/select-favorite-exam-prescription-modal/select-favorite-exam-prescription-modal.component';
import { ConfirmCancelAppointmentModalComponent } from './pages/medical-schedule/view-scheduled-medical-care-modal/confirm-cancel-appointment-modal/confirm-cancel-appointment-modal.component';
import { SelectOldPrescriptionModalComponent } from './pages/attend-patient/select-old-prescription-modal/select-old-prescription-modal.component';
import { MedicineFormComponent } from './pages/attend-patient/prescription-modal/medicine-form/medicine-form.component';
import { MedicatedSolutionFormComponent } from './pages/attend-patient/prescription-modal/medicated-solution-form/medicated-solution-form.component';
import { AihModalComponent } from './pages/attend-patient/aih-modal/aih-modal.component';
import { AihFillConfigListComponent } from './pages/aih-fill-config-list/aih-fill-config-list.component';
import { AihFillConfigRegisterComponent } from './pages/aih-fill-config-register/aih-fill-config-register.component';
import { AihConfigDeleteModalComponent } from './pages/aih-fill-config-list/aih-config-delete-modal/aih-config-delete-modal.component';
import { AihEditorComponent } from './pages/aih-editor/aih-editor.component';
import { AihPendingListComponent } from './pages/aih-pending-list/aih-pending-list.component';
import { MemedPrescriptionsModalComponent } from './pages/attend-patient/memed-prescriptions-modal/memed-prescriptions-modal.component';
import { MemedPrescriptionDeleteModalComponent } from './pages/attend-patient/memed-prescription-delete-modal/memed-prescription-delete-modal.component';
import { MemedPrescriptionsComponent } from './pages/attend-patient/memed-prescriptions/memed-prescriptions.component';
import { ViewAihComponent } from './pages/aih-editor/view-aih/view-aih.component';
import { AihCompletedListComponent } from './pages/aih-completed-list/aih-completed-list.component';
import { DeleteAihGroupModalComponent } from './pages/aih-pending-list/delete-aih-group-modal/delete-aih-group-modal.component';
import { CancelConfirmPatientOnlineModalComponent } from './pages/attend-patient-online-appointment/cancel-confirm-modal/cancel-confirm-patient-online-modal.component';
import { FavoriteMedicineFormComponent } from './pages/medic-favorite-prescription/medic-favorite-standard-prescription/favorite-standard-medicine/add-medicine-modal/favorite-medicine-form/favorite-medicine-form.component';
import { FavoriteMedicatedSolutionFormComponent } from './pages/medic-favorite-prescription/medic-favorite-standard-prescription/favorite-standard-medicine/add-medicine-modal/favorite-medicated-solution-form/favorite-medicated-solution-form.component';
import { SignedErrorModalComponent } from './pages/attend-patient-online/signed-error-modal/signed-error-modal.component';

@NgModule({
  declarations: [MedicComponent,
    MedicPatientListComponent,
    AttendPatientComponent,
    MedicalCareAccordionComponent,
    PrescriptionModalComponent,
    CancelConfirmModalComponent,
    CancelConfirmPatientOnlineModalComponent,
    ConclusionNoteModalComponent,
    EpisodeModalComponent,
    MedicBedManagementComponent,
    UpdateExternalPrescriptionModalComponent,
    MedicalCareReprintReportModalComponent,
    MedicConfigListComponent,
    MedicConfigDeleteModalComponent,
    MedicConfigRegisterComponent,
    MedicNursingConsultationAccordionComponent,
    MedicineTreeComponent,
    OtherQueuePatientModalComponent,
    ProcedureModalComponent,
    ProcedureListComponent,
    ProcedureDeleteModalComponent,
    ProcedureRegisterComponent,
    HealthUnitMedicalProcedureModalComponent,
    MedicalProcedureModalComponent,
    AttendPatientOnlineComponent,
    PatientLeaveModalComponent,
    ReportSelectMedicModalComponent,
    RemoteHoldModalComponent,
    PatientLeaveModalRemoteComponent,
    AttendPatientOnlineAppointmentComponent,
    MedicHoldAppointmentModalComponent,
    MedicalScheduleComponent,
    ViewScheduledMedicalCareModalComponent,
    TimeOverModalComponent,
    ConfirmAllergicPatientModalComponent,
    SaveFavoritePrescriptionModalComponent,
    SelectFavoriteMedicineModalComponent,
    MedicFavoriteStandardPrescriptionComponent,
    MedicFavoriteStandardDeleteModalComponent,
    FavoriteStandardMedicineComponent,
    AddMedicineModalComponent,
    MedicFavoritePrescriptionSelectModalComponent,
    MedicFavoriteExamRequestComponent,
    MedicFavoriteExamRequestDeleteModalComponent,
    MedicFavoriteExamRequestRegisterComponent,
    MedicFavoriteSadtModalComponent,
    MedicFavoriteNonStandardPrescriptionComponent,
    MedicFavoriteNonStandardDeleteModalComponent,
    FavoriteNonStandardMedicineComponent,
    AddNonstandardMedicineModalComponent,
    AddMedicineModalComponent,
    InvalidPrescriptionModalComponent,
    SelectFavoriteNonStandardMedicineModalComponent,
    SelectFavoriteExamPrescriptionModalComponent,
    ConfirmCancelAppointmentModalComponent,
    SelectOldPrescriptionModalComponent,
    MedicineFormComponent,
    MedicatedSolutionFormComponent,
    AihModalComponent,
    AihFillConfigListComponent,
    AihFillConfigRegisterComponent,
    AihConfigDeleteModalComponent,
    AihEditorComponent,
    AihPendingListComponent,
    AihCompletedListComponent,
    MemedPrescriptionsModalComponent,
    MemedPrescriptionDeleteModalComponent,
    MemedPrescriptionsComponent,
    ViewAihComponent,
    DeleteAihGroupModalComponent,
    FavoriteMedicineFormComponent,
    FavoriteMedicatedSolutionFormComponent,
    SignedErrorModalComponent,
  ],
  imports: [
    CommonModule,
    MedicRoutingModule,
    CommonModule,
    SharedModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTreeModule,
    MatRadioModule,
    MatTooltipModule,
    MatToolbarModule,
    NgxMatFileInputModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    MatSlideToggleModule,
    CdkAccordionModule,
    MatDatepickerModule,
    MatPaginatorModule,
    MatDialogModule,
    ClipboardModule
  ],
  providers: [
    AuthGuard,
    MatCheckbox,
    MatCheckboxChange,
    MatTree,
    MatRadioButton,
  ]
})
export class MedicModule { }
