import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MedicineTypeService } from 'src/app/shared/services/API/pharmacy/medicine-type.service';
import { MaskService } from 'src/app/shared/services/mask.service';
import { MedicineTypeRequest } from 'src/app/shared/services/requests/pharmacy/medicine-type.request';

@Component({
  selector: 'app-medicine-type-register',
  templateUrl: './medicine-type-register.component.html',
  styleUrls: ['./medicine-type-register.component.css']
})
export class MedicineTypeRegisterComponent implements OnInit {

  constructor(private formBuilder: UntypedFormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private medicineTypeService: MedicineTypeService,
  ) { }

  public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.pharmacy;
  public menuFunctionalityEnum:MenuFunctionalityEnum = MenuFunctionalityEnum.pharmacy_medicine_type;

  public model: UntypedFormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;

  public idMedicineType: number;
  public medicineTypeRequest: MedicineTypeRequest;
  
  ngOnInit(): void {
    this.isLoading = false;

    this.model = this.formBuilder.group({
      name: ['', [Validators.required]],
      description: [''],
    });

    this.medicineTypeRequest = new MedicineTypeRequest();

    this.idMedicineType = null;
    this.isUpdate == false;

    if(this.activatedRoute.snapshot.paramMap.get('idMedicineType')){
      this.idMedicineType = parseInt(this.activatedRoute.snapshot.paramMap.get('idMedicineType'));
    }

    if(this.idMedicineType != null){
      this.isFirstLoading = true;
      this.isUpdate = true;
      this.populateMedicineTypeData();
    }
  }

  submit(){
    if(this.isLoading){
      return;
    }

    if(!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    this.isLoading = true;  

    this.medicineTypeRequest.medicineTypeName = this.model.get('name').value;
    this.medicineTypeRequest.medicineTypeDescription = this.model.get('description').value;

    if(this.isUpdate) {
      this.updateMedicineType();
    }
    else {
      this.createMedicineType();
    }

  }

  populateMedicineTypeData() {
    this.medicineTypeService.getMedicineType(this.idMedicineType).subscribe((response)=>{
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
    }

    this.model.get('name').setValue(response.medicineType.medicineTypeName);
    this.model.get('description').setValue(response.medicineType.medicineTypeDescription);

    this.isLoading = false;
    this.isFirstLoading = false
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  updateMedicineType() {
    this.medicineTypeService.updateMedicineType(this.idMedicineType, this.medicineTypeRequest).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false; 
      
      this.router.navigate(['/pharmacy/medicine-type']);
      
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  createMedicineType() {
    this.medicineTypeService.createMedicineType(this.medicineTypeRequest).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false; 
      
      this.router.navigate(['/pharmacy/medicine-type']);
      
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }
}
