import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { ColumnAxisStruct } from 'src/app/shared/services/structs/orchestrator-data-intelligence/column-axis.struct';

@Component({
  selector: 'app-plot-column',
  templateUrl: './plot-column.component.html',
  styleUrls: ['./plot-column.component.css']
})
export class PlotColumnComponent implements OnInit {

  constructor() { }

  @Input() variableName: string;
  @Input() idSize: number;
  @Input() listColumnGraphData: ColumnAxisStruct[]; //[{label:"", value:""}]
  @ViewChildren(BaseChartDirective) charts: QueryList<BaseChartDirective>;
  public chartType: ChartType = 'bar';
  public calcHeight:number = 150;
  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
  };
  public barChartData: ChartData<'bar'>;

  ngOnInit(): void {
    this.calcHeight = 163.243 - (12.8378 * this.idSize);
    this.barChartData = {
      labels: this.listColumnGraphData.map(c => c.label),
      datasets: [
        { data: this.listColumnGraphData.map(c => c.value), label: this.variableName },
      ]
    }
  }
}
