<form class="form" [formGroup]="model" (submit)="submit()">
    <div formArrayName="listMedicine"
        *ngFor="let item of model.get('listMedicine')['controls']; let i = index">
        <div class="row" [formGroupName]="i">
            <div class="col-10">
                <div class="row">
                    <div class="col-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Medicamento</mat-label>
                            <mat-select formControlName="idMedicine" (selectionChange)="onChangeMedicine($event, i)">
                                <input class="inputSearchSelect" type="text" (keyup)="onKey($event.target.value, i)"
                                    placeholder="Pesquisar" autocomplete="off">
                                <mat-option [value]="null"> Selecione... </mat-option>
                                <mat-option *ngFor="let medicineItem of selectedMedicineLists[i]"
                                    value="{{medicineItem.medicine.idMedicine}}"
                                    [disabled]="listIdSelectedMedicine.includes(medicineItem.medicine.idMedicine.toString())">
                                    {{medicineItem.medicine.medicineDescription}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Quantidade</mat-label>
                            <input formControlName="quantity" matInput type="text"
                                oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                            <mat-error *ngIf="item.get('quantity').invalid">A quantidade máxima é
                                32767!</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Unidade</mat-label>
                            <mat-select formControlName="idMeasurementUnit">
                                <mat-option *ngFor="let measurementUnitItem of this.lookupMedicalCareResponse.listMeasurementUnit"
                                    [value]="measurementUnitItem.idMeasurementUnit">
                                    {{measurementUnitItem.measurementUnitName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="col-2">
                <button class="panel-button " color="primary" mat-mini-fab type="button"
                    (click)="addNextMedicineInput()">
                    <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
                </button>
                <button class="panel-button " color="primary" mat-mini-fab type="button"
                    (click)="removeMedicineInput(i)" *ngIf="i != 0 && i != 1">
                    <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-sm-12 col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>Via</mat-label>
                <mat-select formControlName="idAdministrationType"
                    (ngModelChange)="onChangeAdministrationType($event)">
                    <mat-option *ngFor="let item of this.lookupMedicalCareResponse.listAdministrationType"
                        [value]="item.idAdministrationType">
                        {{item.administrationTypeName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 col-sm-12 col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>Frequência</mat-label>
                <mat-select formControlName="idFrequency"
                (ngModelChange)="onChangeFrequency($event)">
                    <mat-option *ngFor="let item of this.lookupMedicalCareResponse.listFrequency"
                        value="{{item.idFrequency}}">
                        {{item.frequencyName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row" *ngIf="isFrequency">
        <div class="col-12 col-sm-12 col-md-12">
            <mat-form-field appearance="outline">
                <mat-label>Defina a frequência</mat-label>
                <mat-select formControlName="idSubFrequency">
                    <mat-option *ngFor="let item of this.lookupMedicalCareResponse.listSubFrequency"
                        value="{{item.idSubFrequency}}">
                        {{item.subFrequencyName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div *ngIf="this.model.get('idAdministrationType') && administrationTypeOthersEnum.valueOf() == this.model.get('idAdministrationType').value"
        class="row">
        <div class="col-12">
            <mat-form-field appearance="outline">
                <mat-label>Descreva a Via</mat-label>
                <textarea matInput formControlName="administrationType"></textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-sm-12 col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>Diluição</mat-label>
                <mat-select (ngModelChange)="onChangeDilution($event)" formControlName="idDilution">
                    <mat-option *ngFor="let item of this.lookupMedicalCareResponse.listDilution"
                        value="{{item.idDilution}}">
                        {{item.dilutionName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 col-sm-12 col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>Apresentação</mat-label>
                <mat-select (ngModelChange)="onChangePresentation($event)" formControlName="idPresentation">
                    <mat-option *ngFor="let item of this.lookupMedicalCareResponse.listPresentation"
                        value="{{item.idPresentation}}">
                        {{item.presentationName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row" *ngIf="undiluted">
        <div class="col-12 col-sm-12 col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>Quantidade</mat-label>
                <input formControlName="dilutionQuantity" matInput type="text" maxlength="5"
                    oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                <mat-error *ngIf="model.get('dilutionQuantity').invalid">A quantidade máxima é 32767!</mat-error>
            </mat-form-field>
        </div>
        <div class="col-12 col-sm-12 col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>Unidade</mat-label>
                <mat-select formControlName="idDilutionUnit">
                    <mat-option *ngFor="let item of this.lookupMedicalCareResponse.listDilutionUnit" [value]="item.idDilutionUnit">
                        {{item.dilutionUnitName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div *ngIf="this.model.get('idDilution') && this.model.get('idDilution').value == dilutionOthersEnum.valueOf()"
        class="row">
        <div class="col-12">
            <mat-form-field appearance="outline">
                <mat-label>Descreva a diluição</mat-label>
                <textarea matInput formControlName="dilution"></textarea>
            </mat-form-field>
        </div>
    </div>
    <div *ngIf="this.model.get('idPresentation') && this.model.get('idPresentation').value == presentationOthersEnum.valueOf()"
        class="row">
        <div class="col-12">
            <mat-form-field appearance="outline">
                <mat-label>Descreva a apresentação</mat-label>
                <textarea matInput formControlName="presentation"></textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-sm-12 col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>Periodo de tratamento</mat-label>
                <mat-select formControlName="idTreatmentPeriod">
                    <mat-option *ngFor="let item of this.lookupMedicalCareResponse.listTreatmentPeriod"
                        value="{{item.idTreatmentPeriod}}">
                        {{item.treatmentPeriodName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="footer-panel-modal-list">
        <div class="row">
            <div class="col-6">
                <a mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Cancelar</a>
            </div>
            <div class="col-6">
                <button mat-flat-button type="submit" color="primary" class="btn-block">
                    <span>Salvar</span>
                </button>
            </div>
        </div>
    </div>
</form>