import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { CalcAgeService } from 'src/app/shared/services/API/medical-record/calc-age.service';
import { PatientHistoryService } from 'src/app/shared/services/API/orchestrator-patient/patient-history.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { CalcAgeResponse } from 'src/app/shared/services/responses/medical-record/calc-age.response';
import { PatientStruct } from 'src/app/shared/services/structs/medical-record/patient.struct';

@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.css']
})
export class PatientListComponent implements OnInit {

  constructor(
    private patientHistoryService: PatientHistoryService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private maskService: MaskService,
    private calcAgeService: CalcAgeService,
  ) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.patient_history;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.patient_list;
  public masks: Masks;
  public isLoading: boolean;
  public model: FormGroup;
  public fullSize: number;
  public pageSize: number;
  public pageIndex: number = 0;
  public CPF: string;
  public idPatient: number;
  public patientName: string;
  public birthdate: Date;
  public birthDateString: string;
  public listPatient: PatientStruct[] = [];

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.pageIndex = 0;
    this.model = this.formBuilder.group({
      idPatient: [''],
      CPF: [''],
      patientName: [''],
      birthdate: [''],
    });
    this.search();
  }

  buttonSearch() {

    this.pageIndex = 0;
    if (this.paginator) this.paginator.firstPage();
    this.listPatient = [];
    this.CPF = this.model.get("CPF").value.replace(/[^0-9]+/g, '');
    this.idPatient = this.model.get("idPatient").value;
    this.patientName = this.model.get("patientName").value;
    this.birthdate = this.maskService.formatStringToDate(this.model.get("birthdate").value);
    this.birthDateString = this.maskService.formatDateToString(this.birthdate, true, false);
    this.search();
  }

  changePage(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.listPatient = [];
    this.model.get("CPF").setValue(this.CPF);
    this.model.get("idPatient").setValue(this.idPatient);
    this.model.get("patientName").setValue(this.patientName);
    this.model.get("birthdate").setValue(this.maskService.formatDateToString(this.birthdate, false));
    this.search();
  }

  search() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.patientHistoryService.listPatient(this.pageIndex, this.idPatient, this.CPF, this.patientName, this.birthDateString).subscribe({
      next: (response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.isLoading = false;
      this.listPatient = response.listPatient;
      this.fullSize = response.fullListSize;
      this.pageSize = response.pageSize;
    },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}