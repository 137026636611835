export enum SupplementaryDataRequiredFieldEnum {
    temperature = 1,
    respiratoryFrequency = 2,
    heartRate = 3,
    glucose = 4,
    bloodPressure = 5,
    satO2 = 6,
    weight = 7,
    height = 8,
    riskFall = 9,
    allergies = 10,
    suspicions = 11,
    specialNecessity = 12,
    comorbidities = 13,
    observation = 14,
}