<div class="container-generic">
    <div mat-dialog-content>
        <div class="mat-dialog-content">
            <form class="form" [formGroup]="model" (ngSubmit)="clickApply()">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12">
                        <mat-form-field appearance="outline">
                            <ngx-mat-file-input formControlName="requiredfile" placeholder="Upload de nova Logo"
                                required [accept]="['.png']" (ngModelChange)="inputChange($event)">
                            </ngx-mat-file-input>
                            <mat-icon matSuffix>folder</mat-icon>
                        </mat-form-field>
                    </div>
                </div>
            </form>
            <div *ngIf="oldSrc" class="logo-div">
                <h3>Logo Atual:</h3>
                <img [src]="oldSrc" />
            </div>
            <div *ngIf="newSrc" class="logo-div">
                <h3>Logo Nova:</h3>
                <img [src]="newSrc" />
            </div>
            <div class="footer-exam-modal-list">
                <div class="row">
                    <div class="col-6 col-sm-6 col-md-6">
                        <button mat-flat-button color="primary" class="btn-block" (click)="clickApply()"
                            *ngIf="this.uploadedLogo">
                            <span *ngIf="isLoading == false">Aplicar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-6 col-sm-6 col-md-6">
                        <button mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">
                            <span *ngIf="this.uploadedLogo">Cancelar</span>
                            <span *ngIf="!this.uploadedLogo">Voltar</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>