import { DailyMedicalSchedule } from "../../models/form/daily-medical-schedule";

export class MedicalScheduleRequest
{
    public listDailyMedicalSchedule: DailyMedicalSchedule[] = [];
    public idUserResponsible: number;
    public startDatetime: Date
    public endDatetime: Date
    public idCbo: number;
    public predictedAmount: number;
    public idShift: number;
    public startPeriodTime: Date
    public endPeriodTime: Date
    public actualAmountPredict: number;
}