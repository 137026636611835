<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <a class="back-link" routerLink="/master/gamification">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Usuários
        </a>
        <h1>Nova Área</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="title-header">
                    <h1>Configuração de gamificação</h1>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome</mat-label>
                            <input matInput type="text" formControlName="name">
                            <mat-error *ngIf="model.get('name').invalid">Informe o nome</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Descrição</mat-label>
                            <input matInput type="text" formControlName="description">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Serviço</mat-label>
                            <mat-select formControlName="listService">
                                <mat-option *ngFor="let item of listService" (click)="selectService(item.idService)"
                                    value="{{item.idService}}">
                                    {{item.serviceName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Setor</mat-label>
                            <mat-select formControlName="listIdSector" multiple>
                                <mat-option *ngFor="let item of listSector" value="{{item.idSector}}">
                                    {{item.sectorName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Status</mat-label>
                            <mat-select formControlName="status">
                                <mat-option value="true">Ativo</mat-option>
                                <mat-option value="false">Inativo</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-slide-toggle [checked]="showNames" (change)="toggleShowNames($event)">Exibir nomes dos usuários
                            no ranking geral
                        </mat-slide-toggle>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4" id="showRanking-padding-bottom">
                        <mat-slide-toggle [checked]="showRanking" (change)="toggleShowRanking($event)">Visualizar ranking
                        </mat-slide-toggle>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <a mat-flat-button type="button" routerLink="/master/gamification"
                            color="accent" class=" btn-block">
                            Cancelar
                        </a>
                    </div>
                </div>
            </form>
            <app-area-ranking *ngIf="idArea != null && listAreaRanking != null" [showNames]="showNames" [listAreaRanking]="listAreaRanking" [datetimeInclusionArea]="datetimeInclusionArea"></app-area-ranking>
        </div>
    </div>
    
</div>