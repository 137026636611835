
import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { MedicineTypeRequest } from '../../requests/pharmacy/medicine-type.request';
import { RequestMaterialRequests } from '../../requests/pharmacy/RequestMaterial.request';
import { GetMedicineTypeResponse } from '../../responses/pharmacy/get-medicine-type-response';
import { ListMedicineTypeResponse } from '../../responses/pharmacy/list-medicine-type-response';
import { PharmacyMovementResponse } from '../../responses/pharmacy/pharmacy-movement.response';

@Injectable({
    providedIn: 'root'
})
export class StorageMaterialLookupService extends CommonService {

    constructor(private router: Router, private httpClient: HttpClient) {
        super();
    }

    public getLookupMaterial(): Observable<PharmacyMovementResponse> {
        let uri = `StorageMaterialLookup/`

        return this.httpClient.get<PharmacyMovementResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }
}