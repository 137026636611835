import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-patient-found',
  templateUrl: './patient-found.component.html',
  styleUrls: ['./patient-found.component.css']
})
export class PatientFoundComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<PatientFoundComponent>) { }

  public isLoading:boolean;  
  patient;

  ngOnInit(): void {
    this.isLoading = false;
    this.patient = this.data;
  }

  confirmPatient(){
    this.matDialogRef.close(true);
  }
  
  close(){
    this.matDialogRef.close(false); 
  }
}