<div class="feature-content">
    
    <mat-label>
        <strong>Dispensação: </strong>
        <p>{{solutionDispensation.releaseUserName}} - {{solutionDispensation.datetimeRelease | date:'dd/MM/yyyy HH:mm:ss'}}</p>
    </mat-label>
                          
    <mat-label>
        <strong>Medicamentos:</strong>
        <p *ngFor="let item of solutionDispensation.listMedicine">{{item.medicineName}} | <b>Qtde.: </b>{{item.liberatedQuantity}} {{item.measurementUnitName}} | <b>Apres.: </b>{{item.dispensationPresentationTypeName ? item.dispensationPresentationTypeName:'Não informado'}} | <b>Lote:</b> {{item.batch ? item.batch : 'Não informado'}} | <b>Valid.: </b>{{item.storageExpirationDate ? (item.storageExpirationDate| date:'dd/MM/yyyy') : 'Não informado'}}</p>
    </mat-label>                          

    <mat-label>
        <strong>Apresentação da prescrição:</strong>
        <p>{{solutionDispensation.presentationName ? solutionDispensation.presentationName : 'Não Informado'}}</p>
    </mat-label>   
    <mat-label>
        <strong>Via:</strong>
        <p>{{solutionDispensation.administrationTypeName ? solutionDispensation.administrationTypeName : 'Não Informado'}}</p>
    </mat-label>  
</div>
