import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StatusQueueEnum } from 'src/app/shared/enum/status-queue.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { UpdateStatusQueueService } from 'src/app/shared/services/API/orchestrator-queue/update-status-queue.service';
import { StatusQueueRequest } from 'src/app/shared/services/requests/queue/status-queue.request';

@Component({
  selector: 'app-patient-leave-modal-remote',
  templateUrl: './patient-leave-modal-remote.component.html',
  styleUrls: ['./patient-leave-modal-remote.component.css']
})
export class PatientLeaveModalRemoteComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<PatientLeaveModalRemoteComponent>,
    public updateStatusQueueService: UpdateStatusQueueService,
    public alertService: AlertService,
  ) {
    this.matDialogRef.disableClose = true;
  }

  public isLoading: boolean = false;

  ngOnInit(): void {
    let time: number;
    if (!this.data.roomIsNotConnected) {
      time = 10000;
    } else {
      time = 20000;
    }
    setTimeout(() => {
      if (!this.data.roomIsNotConnected) {
        if (this.data.disconnected) {
          this.updateStatusPatient()
        } else {
          this.EvadePatient();
        }
      } else {
        if (this.matDialogRef) {
          this.matDialogRef.close();
        }
      }
    }, time);
  }

  EvadePatient() {
    this.isLoading = true;

    let statusQueueRequest: StatusQueueRequest = new StatusQueueRequest();

    statusQueueRequest.conclusionNote = "Paciente evadido após sair do atendimento.";
    statusQueueRequest.idQueue = this.data.idQueue;
    statusQueueRequest.idEpisode = this.data.idEpisode;
    statusQueueRequest.idRoom = this.data.idRoom;
    statusQueueRequest.idStatusQueue = StatusQueueEnum.evadido;
    this.updateStatusQueueService.updateStatus(statusQueueRequest).subscribe({
      next: (response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.alertService.show('Aviso', "Paciente evadido após sair da chamada de vídeo", AlertType.warning);
        this.matDialogRef.close({ isEvade: true });
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  updateStatusPatient() {
    this.matDialogRef.close({ isEvade: false });
  }
}
