<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="title-header">
            <h1>Restrições de retorno</h1>
        </div>
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Procedimento</mat-label>
                                <mat-select formControlName="idProcedure">
                                    <mat-option [value]="">Nenhum</mat-option>
                                    <mat-option *ngFor="let item of listProcedure" [value]="item.idProcedure">
                                        {{item.nameProcedure}}</mat-option>
                                </mat-select>
                                <mat-error *ngIf="model.get('idProcedure').invalid">Selecione ao menos um
                                    procedimento</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row align-column">
                        <div class="col-4">
                            <h4>Período</h4>
                            <mat-button-toggle-group class="button-toggle-style" name="fontStyle"
                                aria-label="Font Style" formControlName="idTypePeriod">
                                <mat-button-toggle *ngFor="let item of listTypePeriod" [value]="item.idTypePeriod"
                                    (change)="getNumberValidators($event.value)">
                                    {{ item.periodName }}
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                        <div class="col-4 custom-position">
                            <mat-form-field appearance="outline">
                                <mat-label>Número de dias</mat-label>
                                <input matInput type="number" formControlName="number" min="0" max="31">
                                <mat-error
                                    *ngIf="model.get('number').invalid || model.get('number').value === this.day">
                                    Máximo: 31 dias
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-4 custom-toggle">
                            <mat-slide-toggle class="custom-toggle" formControlName="returnWithSameProfessional"
                                checked="returnWithSameProfessional">
                                Retorno com o mesmo profissional
                            </mat-slide-toggle>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-6">
                                    <a mat-flat-button type="button" (click)="clickCancel()" color="accent"
                                        class="btn-block">
                                        Cancelar
                                    </a>
                                </div>
                                <div class="col-6">
                                    <button mat-flat-button color="primary" class="btn-block">
                                        <span *ngIf="isLoading == false">Salvar</span>
                                        <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                            *ngIf="isLoading == true"></mat-spinner>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>