import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { environment } from 'src/environments/environment';
import { DigitalPromptLogoModalComponent } from './digital-prompt-logo-modal/digital-prompt-logo-modal.component';
import { DigitalPromptServiceConfigStruct } from 'src/app/shared/services/structs/digital-prompt-service/digital-prompt-service-config.struct';
import { DigitalPromptServiceConfigRequest } from 'src/app/shared/services/requests/digital-prompt-service/digital-prompt-service-config.request';
import { DigitalPromptConfigService } from 'src/app/shared/services/API/digital-prompt/digital-prompt-config.service';
import { PatientAppointmentService } from 'src/app/shared/services/API/schedule/patient-appointment.service';
import { UnableChangeStatusModalComponent } from './unable-change-status-modal/unable-change-status-modal.component';
import { SessionTimeout } from 'src/app/shared/services/models/digital-prompt-service/session-timeout.model';
import { GetSessionTimeoutService } from 'src/app/shared/services/API/digital-prompt/get-session-timeout.service';
import { sessionTimeoutEnum } from 'src/app/shared/enum/digital-prompt/session-timeout.enum';
import { IframePreviewModalComponent } from 'src/app/shared/components/iframe-preview-modal/iframe-preview-modal.component';
import { DigitalPromptServiceConfig } from 'src/app/shared/services/models/digital-prompt-service/digital-prompt-service-config.model';

@Component({
  selector: 'app-digital-prompt-config',
  templateUrl: './digital-prompt-config.component.html',
  styleUrls: ['./digital-prompt-config.component.css']
})
export class DigitalPromptConfigComponent implements OnInit {

  constructor(
    private alertService: AlertService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private digitalPromptConfigService: DigitalPromptConfigService,
    private patientAppointmentService: PatientAppointmentService,
    private getSessionTimeoutService: GetSessionTimeoutService
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.digital_prompt_service;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.digital_prompt_service_config;
  public isLoading: boolean;
  public isFirstLoading: boolean = false;
  public listSessionTimeout: SessionTimeout[];
  public isActive: boolean;
  public searchText: string;
  public model: FormGroup;
  public logo: string;
  public newLogo: string;
  public completeUrl: string;
  public baseUrl: string;
  public urlDigitalPromptService: string = '';
  public validationRegex: RegExp = /^[-a-zA-Z0-9_.,<>;+?@*$%#!&()"'{}_/:'"]+$/;
  public isPatientScheduled = false;
  public config: DigitalPromptServiceConfigStruct;
  public oldStatus: boolean;
  public idSessionTimeoutNoLimit: number = sessionTimeoutEnum.semLimite;
  
  public urlDigitalPrompt: string = '';

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      useColor: [false, [Validators.required]],
      colorCode: [''],
      isLogoUploaded: [false, [Validators.required]],
      isToSendSms: [false, [Validators.required]],
      logoString64: [''],
      allowSelectionHealthcareAgreement: [false, [Validators.required]],
      urlName: ['', [Validators.required]],
      urlStatus: [false, [Validators.required]],
      sessionTimeout: [''],
      url: ['', [Validators.required]],
    });

    this.isLoading = true;

    this.searchPromptServiceConfig();
    this.getListPatientAppointment();
    this.getSessionTimeoutConfig();

    this.isLoading = false;
  }

  ngOnDestroy(): void {
    this.updateLogoNamePreview(this.logo);
  }

  searchPromptServiceConfig() {
    this.isLoading = true;
    this.isFirstLoading = true;

    if (this.searchText == "")
      this.searchText = null;

    this.digitalPromptConfigService.getConfig().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isFirstLoading = false;
          this.isLoading = false;
          return;
        }
        this.config = response.digitalPromptConfig;
        this.model.get('useColor').setValue(response.digitalPromptConfig.useColor);
        this.model.get('colorCode').setValue(response.digitalPromptConfig.colorCode);
        this.model.get('isLogoUploaded').setValue(response.digitalPromptConfig.isLogoUploaded);
        this.model.get('logoString64').setValue(response.digitalPromptConfig.logoString64);
        this.model.get('urlName').setValue(response.digitalPromptConfig.urlName);
        this.model.get('urlStatus').setValue(response.digitalPromptConfig.isActive);
        this.model.get('url').setValue(response.digitalPromptConfig.url);
        this.urlDigitalPrompt = response.digitalPromptConfig.url;
        this.model.get('sessionTimeout').setValue(response.digitalPromptConfig.idSessionTimeout ? response.digitalPromptConfig.idSessionTimeout.toString() : null);
        this.model.get('allowSelectionHealthcareAgreement').setValue(response.digitalPromptConfig.allowSelectionHealthcareAgreement);
        this.logo = response.digitalPromptConfig.logoString64;
        this.urlDigitalPromptService = response.digitalPromptConfig.url;
        this.getCompleteUrl(null);

        this.isLoading = false;
        this.isFirstLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.isFirstLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  submit() {
    if (this.model.invalid || this.isLoading) {
      this.alertService.show('Erro', 'Favor verificar todos os campos', AlertType.error);
      return;
    }

    this.isLoading = true;

    if (this.isPatientScheduled && this.config.isActive && !this.model.get('urlStatus').value) {
      this.unableToChangeStatus();
      return;
    }
    else {
      let request: DigitalPromptServiceConfigRequest = new DigitalPromptServiceConfigRequest();
      request.useColor = this.model.get('useColor').value;
      request.colorCode = request.useColor ? this.model.get('colorCode').value : null;
      request.isLogoUploaded = this.model.get('isLogoUploaded').value;
      request.allowSelectionHealthcareAgreement = this.model.get('allowSelectionHealthcareAgreement').value;
      request.logoString64 = request.isLogoUploaded ? this.model.get('logoString64').value : null;
      request.url = this.model.get('url').value;
      request.isActive = this.model.get('urlStatus').value;
      request.urlName = this.model.get('urlName').value;
      if(this.model.get('sessionTimeout').value != null)
      {
        request.idSessionTimeout = this.model.get('sessionTimeout').value;
      }
      else
      {
        request.idSessionTimeout = null;
      }
      this.updateDigitalPromptServiceConfigConfig(request);
    }
  }

  updateDigitalPromptServiceConfigConfig(request: DigitalPromptServiceConfigRequest) {
    this.digitalPromptConfigService.postConfig(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getListPatientAppointment() {
    this.patientAppointmentService.getListPatientAppointment().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isPatientScheduled = response.isPatientScheduled;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openPanelLogoModal() {
    const dialogRef = this.dialog.open(DigitalPromptLogoModalComponent, {
      data: {
        logo: this.logo,
        newLogo: this.newLogo,
      },
    });
    dialogRef.afterClosed().subscribe({
      next:
        result => {
          if (result && result.newLogo) {
            this.alertService.show('Sucesso', "Upload concluído!", AlertType.success);
            this.newLogo = result.newLogo;

            if (!this.logo) this.logo = this.newLogo;

            this.model.get('logoString64').setValue(result.newLogo);
          }
          if (result && !result.haveLogo)
            this.model.get('isLogoUploaded').setValue(false);
          if(this.model.get('logoString64').value)
            this.updateLogoNamePreview(this.model.get('logoString64').value);
        },
      error: (error) => {
        console.log(error);
      }
    });
  }

  unableToChangeStatus() {
    const dialogRef = this.dialog.open(UnableChangeStatusModalComponent, {
      data: {
      }
    });
    dialogRef.afterClosed().subscribe({
      next:
        result => {
          this.isLoading = false;
        },
      error: (error) => {
        console.log(error);
      }
    });
  }

  onCopy(text) {
    this.alertService.show('Sucesso', text, AlertType.success);
  }

  getCompleteUrl(event) {
    if (event) {
      let result = this.validationRegex.test(event.key);

      if (!result) {
        let uri = this.model.get('url').value.replaceAll(event.key, "");
        this.model.get('url').setValue(uri);
      }
    }

    this.baseUrl = `${environment.urlBasePatientUI}digital-prompt-service/`;

    this.completeUrl = `${environment.urlBasePatientUI}digital-prompt-service/${this.model.get('url').value}`;
  }

  getSessionTimeoutConfig()
  {
    this.getSessionTimeoutService.getConfig().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listSessionTimeout = response.sessionTimeout;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });

  }

  updateLogoNamePreview(logoString64: string) {
    let request: DigitalPromptServiceConfigRequest = new DigitalPromptServiceConfigRequest();
    request.logoString64 = logoString64;

    this.digitalPromptConfigService.putLogoNamePreview(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  updateColorCodePreview() {
    let request: DigitalPromptServiceConfig = new DigitalPromptServiceConfig();
    request.colorCodePreview = this.model.get('colorCode').value;

    this.digitalPromptConfigService.putColorCodePreview(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openPreview() {
    this.dialog.open(IframePreviewModalComponent, {
      data: {
        url: `${this.baseUrl}${this.urlDigitalPrompt}?preview=true`,
        title: 'Central do Paciente'
      },
      panelClass: 'iframe-preview-modal'
    });
  }

}