import { DeserializedAge } from "../../structs/sync-measurer/age-range.struct";

export class CareLineRequest {
    public careLineName: string;
    public careLineDescription: string;
    public isActive: boolean;
    public otherMedication: string;
    public ip: string;
    public otherComorbity: string;
    public otherAgeRangeMin: DeserializedAge;
    public otherAgeRangeMax: DeserializedAge;
    public bloodPressureDiastoleGreenMin: number;
    public bloodPressureDiastoleGreenMax: number;
    public bloodPressureDiastoleYellowMin: number;
    public bloodPressureDiastoleYellowMax: number;
    public bloodPressureDiastoleRedMin: number;
    public bloodPressureDiastoleRedMax: number;
    public bloodPressureSystoleGreenMin: number;
    public bloodPressureSystoleGreenMax: number;
    public bloodPressureSystoleYellowMin: number;
    public bloodPressureSystoleYellowMax: number;
    public bloodPressureSystoleRedMin: number;
    public bloodPressureSystoleRedMax: number;
    public glucoseGreenMin: number;
    public glucoseGreenMax: number;
    public glucoseYellowMin: number;
    public glucoseYellowMax: number;
    public glucoseRedMin: number;
    public glucoseRedMax: number;
    public saturationGreenMin: number;
    public saturationGreenMax: number;
    public saturationYellowMin: number;
    public saturationYellowMax: number;
    public saturationRedMin: number;
    public saturationRedMax: number;
    public heartRateGreenMin: number;
    public heartRateGreenMax: number;
    public heartRateYellowMin: number;
    public heartRateYellowMax: number;
    public heartRateRedMin: number;
    public heartRateRedMax: number;
    public temperatureGreenMin: number;
    public temperatureGreenMax: number;
    public temperatureYellowMin: number;
    public temperatureYellowMax: number;
    public temperatureRedMin: number;
    public temperatureRedMax: number;
    public listIdCareLineType: number[];
    public listIdAgeRange: number[];
    public listIdComorbidity: number[];
    public listIdMedicine: number[];
}