<app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>

<div class="white-body">
    <div class="row resume-triage-orientation">
        <div *ngIf="isSelfTriage" class="col-4 self-triage-button-header">
            <button mat-flat-button color="primary" type="button" class="btn-block"
                (click)="openSelfTriageResumeModal()">
                Resumo Autotriagem
            </button>
        </div>
    </div>
    <h1 class="border-green-left-title">{{this.flowchartName}}</h1>
    <div *ngIf="discriminatorResponse && discriminatorResponse.discriminator.length > 0" class="row">
        <app-protocol-v2 (selectedValue)="selectDiscriminator($event)" [discriminatorResponse]="discriminatorResponse"
            [discriminators]="discriminators" [painColorCode]="painColorCode">
        </app-protocol-v2>
    </div>
</div>