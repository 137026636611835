export class ScheduleDay{
    public idScheduleDay: number;
    public idSchedule: number;
    public idSpecialty: number;
    public idDay: number;
    public idResponsibleUser: number;
    public timeStart: Date;
    public timeEnd: Date;
    public isDeleted: boolean;
    public datetimeInclusion: Date;
}