import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MedicFavoriteStandardPrescriptionService } from 'src/app/shared/services/API/medic/medic-favorite-standard-prescription.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-medic-favorite-prescription-select-modal',
  templateUrl: './medic-favorite-prescription-select-modal.component.html',
  styleUrls: ['./medic-favorite-prescription-select-modal.component.css']
})
export class MedicFavoritePrescriptionSelectModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private router: Router,
    public matDialogRef: MatDialogRef<MedicFavoritePrescriptionSelectModalComponent>,
    private alertService: AlertService,
  ) { }

  public isLoading: boolean;
  public idRoom: number;
  public idSector: number

  ngOnInit(): void {
    this.isLoading = false;
    this.idRoom = this.data.idRoom;
    this.idSector = this.data.idSector;
  }

  close() {
    this.matDialogRef.close();
  }

  goStandardizedPrescription() {
    this.router.navigate(['medic/medic-favorite-standard-prescription', { idSector: this.idSector, idRoom: this.idRoom }]);
    this.close();
  }

  goNonStandardizedPrescription() {
    this.router.navigate(['medic/medic-favorite-non-standard-prescription', { idSector: this.idSector, idRoom: this.idRoom }]);
    this.close();
  }

  goExamRequest() {
    this.router.navigate(['medic/medic-favorite-exam-request', { idSector: this.idSector, idRoom: this.idRoom }]);
    this.close();
  }
}