<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <a class="back-link" routerLink="/form/equipment"><mat-icon aria-hidden="false"
                aria-label="Plus">arrow_back</mat-icon> Equipamentos</a>
        <h1>Cadastrar Equipamento</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome</mat-label>
                            <input matInput type="text" formControlName="name">
                            <mat-error *ngIf="model.get('name').invalid">Informe o nome</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Departamento</mat-label>
                            <input matInput type="text" formControlName="department">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <app-select (selectedValue)="selectChange($event)" [url]="urlList" [idField]="'idEquipmentType'"
                            [preSelectedlist]="preSelectedEquipament" [nameField]="'equipmentTypeName'"
                            [labelField]="'Tipos de equipamento'">
                        </app-select>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Status</mat-label>
                            <mat-select formControlName="status">
                                <mat-option value="true">Ativo</mat-option>
                                <mat-option value="false">Inativo</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Quantidade</mat-label>
                            <input matInput type="number" formControlName="quantity">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <a mat-flat-button type="button" routerLink="/form/equipment" color="accent" class=" btn-block">
                            Cancelar
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>