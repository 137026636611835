<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <a class="back-link" routerLink="/digital-prompt-service/patient-center/medical-specialty">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Módulo PA digital
        </a>
        <h1>Cadastrar especialidade</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="row">                
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome da especialidade</mat-label>
                            <input matInput type="text" formControlName="name">
                            <mat-error *ngIf="model.get('name').invalid">Informe o nome</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Código da especialidade</mat-label>
                            <mat-select formControlName="idMedicalSpecialtyFederalCouncilMedicine">
                                <mat-option *ngFor="let item of listMedicalSpecialtyFederalCouncilMedicine" value="{{item.idMedicalSpecialtyFederalCouncilMedicine}}">
                                    {{item.federalCouncilMedicineCode}} - {{item.medicalSpecialtyName}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idMedicalSpecialtyFederalCouncilMedicine').invalid">Informe o código da especialidade</mat-error>
                        </mat-form-field>     
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Tipo de Atendimento</mat-label>
                            <mat-select formControlName="idNatureOfAttendance" multiple>
                                <mat-option *ngFor="let item of listNatureOfAttendance" value="{{item.idNatureOfAttendance}}">
                                    {{item.natureOfAttendanceName}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idNatureOfAttendance').invalid">Informe o tipo de atendimento</mat-error>
                        </mat-form-field>     
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Médicos que atendem</mat-label>
                            <mat-select formControlName="idUser" multiple>
                                <mat-option *ngFor="let item of listUser" value="{{item.idUser}}">
                                    {{item.userName}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idUser').invalid">Informe o médico da especialidade</mat-error>
                        </mat-form-field>     
                    </div>
                    <div class="col-12 col-md-4 adjust-checkbox">
                        <mat-checkbox (change)='selectionHealthcareAgreementChangeValidator($event)'
                            formControlName="allowSelectionHealthcareAgreement" class="my-checkbox-field">
                            Aceita convênios
                        </mat-checkbox>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngIf="acceptHealthcareAgreement">
                        <mat-form-field appearance="outline">
                            <mat-label>Convênios</mat-label>
                            <mat-select formControlName="idHealthcareAgreement" multiple (selectionChange)="verifyIdHealthcareAgreementPlanForm($event.value)">
                                <mat-option *ngFor="let item of listHealthcareAgreement" value="{{item.idHealthcareAgreement}}" >
                                    {{item.nameHealthcareAgreement}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idHealthcareAgreement').invalid">Informe o convênio aceito</mat-error>
                        </mat-form-field>     
                    </div>
                    <div class="col-12 col-sm-6 col-md-4" *ngIf="acceptHealthcareAgreement">
                        <mat-form-field appearance="outline">
                            <mat-label>Planos</mat-label>
                            <mat-select formControlName="idHealthcareAgreementPlan" multiple>
                                <mat-option *ngFor="let item of listHealthcareAgreementPlan" value="{{item.idPlan}}">
                                    {{getFormatePlanNameToSelect(item.planName, item.idHealthcareAgreement)}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idHealthcareAgreementPlan').invalid">Informe o plano aceito</mat-error>
                        </mat-form-field>     
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-md-2">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <a mat-flat-button type="button" routerLink="/digital-prompt-service/patient-center/medical-specialty" color="accent" class=" btn-block">
                            Cancelar
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>
    
</div>