import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ScheduleStruct } from 'src/app/shared/services/structs/orchestrator-user/schedule.struct';
import { ScheduleDeleteModalComponent } from './schedule-delete-modal/schedule-delete-modal.component';
import { GetScheduleService } from 'src/app/shared/services/API/orchestrator-schedule/get-schedule.service';

@Component({
  selector: 'app-schedule-list',
  templateUrl: './schedule-list.component.html',
  styleUrls: ['./schedule-list.component.css']
})
export class ScheduleListComponent {
  constructor(
    private getScheduleService: GetScheduleService,
    private alertService: AlertService,
    public dialog: MatDialog
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.digital_prompt_service;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.schedule_schedule;

  public listSchedule: ScheduleStruct[];
  public isLoading: boolean;
  public isActive: boolean;
  public searchText: string;

  ngOnInit(): void {
    this.isLoading = true;
    this.search();
  }

  search() {
    this.isLoading = true;

    if (this.searchText == "")
      this.searchText = null;

    this.getScheduleService.listSchedule().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listSchedule = response.listSchedule;

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13)
        $this.search();
    }, 1000);
  }

  openModal(idSchedule) {
    const dialogRef = this.dialog.open(ScheduleDeleteModalComponent, {
      data: {
        idSchedule: idSchedule
      },
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result && result.deleteSchedule){
        this.isLoading = true;
        this.search();
      }
    });
  }
}