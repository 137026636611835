import { ScreenFlowStruct } from "../../structs/ubs-health-promotion/screen-flow.struct";

export class UbsConfigUpdateRequest {
    public listScreen: ScreenFlowStruct[]
    public accessUrl: string;
    public ipDevice: string;
    public configName: string;
    public integrationUrl: string;
    public idHealthUnit: number;
    public isClientTest: boolean;
}