<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <a class="back-link" routerLink="/master/panel">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Usuários
        </a>
        <h1>Cadastrar Painel</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome</mat-label>
                            <input matInput type="text" formControlName="panelName">
                            <mat-error *ngIf="model.get('panelName').invalid">Informe o nome</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Descrição</mat-label>
                            <input matInput type="text" formControlName="description">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Status</mat-label>
                            <mat-select formControlName="isActive">
                                <mat-option value="true">Ativo</mat-option>
                                <mat-option value="false">Inativo</mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('isActive').invalid">Informe o Status</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div *ngIf="this.model.get('layout').value == layoutTypeEnumResult.LayoutPrincipal"
                        class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Layout</mat-label>
                            <mat-select formControlName="layout">
                                <mat-option *ngFor="let item of listLayout"
                                    value="{{item.idLayout}}">{{item.layoutName}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('layout').invalid">Informe o Layout</mat-error>
                        </mat-form-field>
                    </div>
                    <div *ngIf="this.model.get('layout').value != layoutTypeEnumResult.LayoutPrincipal"
                        class="col-10 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Layout</mat-label>
                            <mat-select formControlName="layout">
                                <mat-option *ngFor="let item of listLayout"
                                    value="{{item.idLayout}}">{{item.layoutName}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('layout').invalid">Informe o Layout</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-2 col-sm-2 col-md-1"
                        *ngIf="this.model.get('layout').value == layoutTypeEnumResult.LayoutEsquerda">
                        <div (mouseover)="changeImg=true" (mouseout)="changeImg=false">
                            <span *ngIf="!changeImg">
                                <span><img class="brand-static" src="assets/images/esquerda.png"></span>
                            </span>
                            <span *ngIf="changeImg"><img class="brand" src="assets/images/esquerda.png"></span>
                        </div>
                    </div>
                    <div class="col-2 col-sm-2 col-md-1"
                        *ngIf="this.model.get('layout').value == layoutTypeEnumResult.LayoutDireita">
                        <div (mouseover)="changeImg=true" (mouseout)="changeImg=false">
                            <span *ngIf="!changeImg">
                                <span><img class="brand-static" src="assets/images/direito.png"></span>
                            </span>
                            <span *ngIf="changeImg"><img class="brand" src="assets/images/direito.png"></span>
                        </div>
                    </div>
                    <div *ngIf="this.model.get('layout').value == layoutTypeEnumResult.LayoutEsquerda || this.model.get('layout').value == layoutTypeEnumResult.LayoutDireita"
                        class="col-10 col-sm-4 col-md-4 ngx-personalized">
                        <ngx-mat-file-input formControlName="requiredfile" placeholder="Upload novo vídeo" required
                            [accept]="'.wmv, .asf, .avi, .mp4, .m4v, .mov, .mpg, .mpeg'"
                            (ngModelChange)="inputChange($event)">
                            <mat-icon ngxMatFileInputIcon>folder</mat-icon>
                        </ngx-mat-file-input>
                        <app-loading-progress-bar *ngIf="isLoadingVideo"></app-loading-progress-bar>
                        <div class="personalize-div-video">
                            <div *ngIf="!isLoadingVideo" class="max-size-text">Máximo: 11MB</div>
                            <div *ngIf="videoName" class="text-video-name">{{ this.videoName }}</div>
                            <mat-icon *ngIf="haveVideo" style="width: 30%;" matTooltip="Remover vídeo"
                                aria-hidden="false" aria-label="Plus"
                                (click)="clearFileInput(fileInput)">cancel</mat-icon>
                        </div>
                        <mat-error *ngIf="model.get('requiredfile').hasError('maxSize')">
                            Atenção! O vídeo selecionado é maior que 11MB.
                        </mat-error>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 col-sm-6 col-md-2">
                        <div class="form-field-toggle">
                            <mat-label>Usar Logo</mat-label>
                            <mat-slide-toggle formControlName="haveLogo"></mat-slide-toggle>
                        </div>
                    </div>
                    <div class="col-6 col-sm-6 col-md-4">
                        <div class="form-field-toggle">
                            <mat-label>Chamar somente pela senha?</mat-label>
                            <mat-slide-toggle formControlName="callByPassword"></mat-slide-toggle>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <a class="link-uploaded-logo" (click)="openPanelLogoModal()"
                            *ngIf="this.model.get('haveLogo').value">
                            <mat-icon class="img-uploaded-logo">image</mat-icon>
                            <span *ngIf="this.logo">Visualizar Logo</span>
                            <span *ngIf="!this.logo">Selecionar Logo</span>
                        </a>
                    </div>
                </div>
                <div class="title-header">
                    <h1>Origem Painel</h1>
                </div>
                <div *ngIf="!isFirstLoading && this.listService && this.listSector && this.listRoom">
                    <div formArrayName="listPanelOrigin"
                        *ngFor="let item of model.get('listPanelOrigin')['controls']; let i = index">
                        <app-panel-origin-select [formGroup]="item" [index]="i"
                            [events]="submitInvalidateEventToPanel.asObservable()" [listService]="listService"
                            [listRoom]="listRoom" [listSector]="listSector" (addNextEvent)="addNext($event)"
                            (removeOriginEvent)="removeButton($event)" (selectionChange)="teste($event)">
                        </app-panel-origin-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <a mat-flat-button type="button" routerLink="/master/panel" color="accent" class=" btn-block">
                            Cancelar
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>

</div>