<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="title-header">
            <h1>Histórico de alterações</h1>
        </div>

        <app-loading-list *ngIf="isLoading"></app-loading-list>

        <div *ngIf="listHistoryPriceProcedureHealthcareStruct && listHistoryPriceProcedureHealthcareStruct.length > 0  && !isLoading">
            <div *ngFor="let item of listHistoryPriceProcedureHealthcareStruct">
                <div class="container-infos">
                    <div class="row">
                        <div class="col-12 box">
                            <div class="check-mark">&#10003;</div>
                            <span class="text-date">{{ item.datetimeInclusion | date:'dd/MM/yyyy | HH:mm'}} - </span>

                            <span> Usuário </span>
                            <span class="text-bold"> {{ item.userName }}</span>
                            <span> alterou o valor do procedimento </span>
                            <p> para R$ <span class="text-bold"> {{ item.value }} </span></p>
                        </div>
                    </div>
                </div>
            </div>            
        </div>

        <app-empty-list [message]="'Não há histórico de alterações'" *ngIf="!isLoading && (!listHistoryPriceProcedureHealthcareStruct || listHistoryPriceProcedureHealthcareStruct.length == 0)"></app-empty-list>

        <div class="row">
            <div class="col-12" style="margin-top: 10px;">
                <button mat-flat-button color="primary" class="btn-block" (click)="clickCancel()">
                    OK
                </button>
            </div>
        </div>
    </div>
</div>