import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { DeviceTypeEnum } from 'src/app/shared/enum/device-type.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { DeviceTypeService } from 'src/app/shared/services/API/risk-classification/device-type.service';
import { PreAdmissionRequiredFieldSector } from 'src/app/shared/services/models/risk-classification/pre-admission-required-field-sector.model';
import { ClassificationData, UtilsClassificationService } from '../../utils.service';
import { MenuComponent } from 'src/app/shared/components/menu/menu.component';
import { MetaDataStruct } from 'src/app/shared/services/structs/meta-data/meta-data.struct';
import { UtilsMeasurerService } from '../flowchart/protocol-v2/utils-measurer.service';
import { RoomMeasurerService } from 'src/app/shared/services/API/risk-classification/room-measurer.service';

@Component({
  selector: 'app-pre-register',
  templateUrl: './pre-register.component.html',
  styleUrls: ['./pre-register.component.css']
})
export class PreRegisterComponent implements OnInit, OnDestroy {

  constructor(private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private deviceTypeService: DeviceTypeService,
    private utilsClassificationService: UtilsClassificationService,
    public measurerService: UtilsMeasurerService,
    public utilClassification: UtilsClassificationService,
    private roomMeasurerService: RoomMeasurerService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.classification;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.classification_pre_registration;
  public idEpisode: number;
  public idPatient: number;
  public idRoom: number;
  public idSector: number;
  public idService: number;
  public idDeviceType: number;
  public reclassification: boolean;
  public idDeviceTypeRemote: number = DeviceTypeEnum.Remoto;
  public listSectorRequiredField: PreAdmissionRequiredFieldSector[];
  public isFirstLoading: boolean = true;
  public listMetaDataStruct: MetaDataStruct[] = [];
  public listMetaData: MetaDataStruct[];
  public listIdMetaDataRequired: number[];
  public discriminatorValues: any = [];
  public data: ClassificationData;

  @ViewChild(MenuComponent) menu: MenuComponent;

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.paramMap.get('idEpisode'))
      this.idEpisode = parseInt(this.activatedRoute.snapshot.paramMap.get('idEpisode'));

    this.idSector = parseInt(this.activatedRoute.snapshot.paramMap.get('idSector'));
    this.reclassification = this.activatedRoute.snapshot.paramMap.get('reclassification') == "true";
    this.idRoom = parseInt(this.activatedRoute.snapshot.paramMap.get('idRoom'));
    this.idService = parseInt(this.activatedRoute.snapshot.paramMap.get('idService'));

    this.idDeviceType = parseInt(this.activatedRoute.snapshot.paramMap.get('idDeviceType'));

    this.data = this.utilClassification.getClassificationData();
  
    this.data.idDeviceType = this.idDeviceType;
    if (this.idDeviceTypeRemote != this.idDeviceType) {
      this.data.discriminatorAllValues = [];
      this.data.discriminatorValues = [];
      if (!this.idEpisode) this.repopulateHeaderV2();
    }

    this.getDeviceType()
    this.getRoomMeasurers();
  }

  ngOnDestroy() {
    if (this.data.idDeviceType == DeviceTypeEnum.Trius || this.data.idDeviceType == DeviceTypeEnum.Berco || this.data.idDeviceType == DeviceTypeEnum.Bluetooth)
      this.measurerService.setIndex(-1);
  }

  getDeviceType() {
    this.deviceTypeService.GetDevicetypeBySector(this.idSector).subscribe({
      next: response => {
        this.isFirstLoading = false;
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        if (response.deviceTypeSector)
          this.utilsClassificationService.updateDevicetypeBySector(response);
      },
      error: (error) => {
        console.log(error);
        this.isFirstLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getRoomMeasurers() {
    this.roomMeasurerService.listRoomMeasurer(this.idRoom).subscribe({
      next: response => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        let glucometer = response.listRoomMeasurer.find(c => c.idGlucometer != 0);
        let thermometer = response.listRoomMeasurer.find(c => c.idThermometer != 0);
        let pressureMeasurer = response.listRoomMeasurer.find(c => c.idPressureMeasurer != 0);
        let oximeter = response.listRoomMeasurer.find(c => c.idOximeter != 0);

        this.data.deviceGlucometer = glucometer ? glucometer : null;
        this.data.deviceThermometer = thermometer ? thermometer : null;
        this.data.devicePressure = pressureMeasurer ? pressureMeasurer : null;
        this.data.deviceOximeter = oximeter ? oximeter : null;

        if ((this.data.idDeviceType == DeviceTypeEnum.Trius || this.data.idDeviceType == DeviceTypeEnum.Berco || this.data.idDeviceType == DeviceTypeEnum.Bluetooth) && Array.isArray(this.data.discriminatorAllValues)) {
          let selectedIndex: number = this.data.discriminatorAllValues.findIndex(c => c.idDiscriminator == this.data.discriminator.idDiscriminator);
          selectedIndex = selectedIndex > -1 ? selectedIndex : this.data.discriminatorAllValues.length + 1;
          this.measurerService.setIndex(selectedIndex);
          this.data.discriminatorsPersist = this.data.discriminatorsPersist == undefined ? [] : this.data.discriminatorsPersist;
          this.measurerService.createReference(this.data.discriminatorValues, this.data.discriminatorsPersist, this.data.discriminatorAllValues,
            this.data.deviceThermometer, this.data.deviceGlucometer, this.data.deviceOximeter, this.data.devicePressure);
          this.measurerService.restartMeasurer();
        }
        this.data.datetimeStartTriage = response.datetimeStartTriage;
        this.utilsClassificationService.updateClassificationData(this.data);
      },
      error: error => {
        console.log(error);
      }
    });
  }

  async restartMeasurers() {
    await this.measurerService.restartMeasurer();
    this.alertService.show('Info', "Medidores reiniciados", AlertType.info);
  }

  repopulateHeader() {
    this.menu.reloadSecondaryHeader();
  }

  repopulateHeaderV2() {
    let $this = this;
    let timeout = setTimeout(() => {
      if ($this.menu) $this.menu.reloadSecondaryHeaderV2(this.data.discriminatorValues);
      clearTimeout(timeout);
    }, 1000);
  }
}