import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ScheduleRequest } from '../../requests/schedule/schedule.request';
import { PatientAppointmentResponse } from '../../responses/schedule/patient-appointment.response';
import { ListPatientAppointmentResponse } from '../../responses/schedule/list-patient-appointment.response';
import { PatientAppointmentRequest } from '../../requests/orchestrator-schedule/patient-appointment.request';

@Injectable({
  providedIn: 'root'
})

export class PatientAppointmentService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  public getPatientAppointmentById(idPatientAppointment: number): Observable<PatientAppointmentResponse> {
    let uri = `PatientAppointment/idPatientAppointment/${idPatientAppointment}`;

    return this.httpClient.get<PatientAppointmentResponse>(environment.urlApiSchedule + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }

  public getPatientAppointmentByIdUser(): Observable<ListPatientAppointmentResponse> {
    let uri = `PatientAppointment/getAllByTokenIdUser`;

    return this.httpClient.get<ListPatientAppointmentResponse>(environment.urlApiSchedule + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }

  public postPatientAppointment(request: ScheduleRequest): Observable<ReturnStruct> {
    let uri = `PatientAppointment`;

    return this.httpClient.post<ReturnStruct>(environment.urlApiSchedule + uri, request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }

  public putPatientAppointmentById(idPatientAppointment: number, request: PatientAppointmentRequest): Observable<PatientAppointmentResponse> {
    let uri = `PatientAppointment/idPatientAppointment/${idPatientAppointment}`;

    return this.httpClient.put<PatientAppointmentResponse>(environment.urlApiSchedule + uri, request,this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }

  public deletePatientAppointment(idPatientAppointment: number): Observable<ReturnStruct> {
    let uri = `PatientAppointment/idPatientAppointment/${idPatientAppointment}`;

    return this.httpClient.delete<ReturnStruct>(environment.urlApiSchedule + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }

  public getListPatientAppointment(): Observable<ListPatientAppointmentResponse> {
    let uri = `PatientAppointment/GetListPatientAppointment`;

    return this.httpClient.get<ListPatientAppointmentResponse>(environment.urlApiSchedule + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }
}