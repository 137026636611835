export class OccupiedBedStruct
{
    public idEpisode: number;

    public idBed: number;

    public bedName: string;

    public patientName: string;

    public birthDate: Date;

    public patientAge: string;

    public idStatus: number;

    public statusName: string;

    public isLoadingLeaveBed: boolean;

    public episodeAdministrativeReleaseStatus: string;

    public idEpisodeAdministrativeReleaseStatus: number;

    public idSector: number;

    public procedureName: string;
}