<div>
    <header>
        <h1 class="title-name">Atendimento Remoto</h1>
        <div class="header-logo">
            <div class="header-logo-content">
                <img class="client-brand" src="assets/images/logo-colorido.png">
            </div>
        </div>
    </header>

    <body class="body-container">

        <div class="body-content">
            <div class="white-container">
                <div>
                    <h2 *ngIf="isInvalid" class="pin-intro">Link inválido ou a sala foi fechada.</h2>
                    <h2 *ngIf="isInvalid" class="pin-intro">Feche a sua janela ou procure um responsável!</h2>
                </div>
                <form *ngIf="!isInvalid" class="form" [formGroup]="model" (ngSubmit)="submit()">
                    <span class="pin-intro">Informe o PIN para iniciar o sistema!</span>
                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field appearance="outline">
                                <mat-label>PIN</mat-label>
                                <input matInput type="text" formControlName="pin" required>
                                <mat-error *ngIf="model.get('pin').invalid">Informe o PIN</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <mat-slide-toggle formControlName="rememberPin">Salvar PIN neste
                                dispositivo</mat-slide-toggle>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <button mat-flat-button type="submit" color="primary" class=" btn-block btn-font-size">
                                <span *ngIf="isLoading == false">Confirmar</span>
                                <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                    *ngIf="isLoading == true"></mat-spinner>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    </body>
</div>