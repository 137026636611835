<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="body-audit-modal-warning">        
            <span>Selecione uma prescrição</span>
        </div>
        <div class="card-list" *ngIf="this.listMedicalCareFilterByPrescriptionDate != null">
            <div class="row">
                <div class="col-12" *ngFor="let item of this.listMedicalCareFilterByPrescriptionDate">
                    <button class="prescription-button" mat-flat-button (click)="selectedPrescription(item.medicalPrescription, item.medicatedSolutionPrescription)">
                        <div class="description">
                            <div>
                                <b>{{ item.medicalCare.datetimeInclusion | date:'dd/MM/yyyy, HH:mm' }} - Dr(a).{{item.medicalCare.userName}}</b>
                            </div>
                            <div class="count">
                                <span>{{item.medicalPrescription.length}} medicamento(s)</span>
                                <mat-icon matTooltipClass="tooltip-class" matTooltip="{{item.medicineNamesOfPrescriptionFormatted}}">info</mat-icon>
                            </div>
                            <div class="count">
                                <span>{{item.medicatedSolutionPrescription.length}} solução(ões) medicamentosa(s)</span>
                                <mat-icon matTooltipClass="tooltip-class" matTooltip="{{item.medicineNamesOfSolutionPrescriptionFormatted}}">info</mat-icon>
                            </div>
                        </div>                       
                    </button>
                </div>
            </div>
        </div>
        <div class="footer-audit-modal-warning">
            <button mat-flat-button color="accent" class="btn-block" (click)="close()">
                <span *ngIf="isLoading == false">Voltar</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
        </div>
    </div>
</div>