import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ReportResponse } from '../../responses/orchestrator-patient/report.response';

@Injectable({
  providedIn: 'root'
})
export class BatchDownloadService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public downloadBatchReport(idBatchRequest: number): Observable<ReportResponse>{

    let uri = `DownloadReport/idBatchRequest/${idBatchRequest}`;

    return this.httpClient.get<ReportResponse>(environment.urlApiBatch + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
