import { PanelOriginStruct } from "../../structs/panel/panel-origin.struct";

export class PanelRequest{
    public panelName: string;
    
    public description: string;
    
    public isActive: boolean;

    public callByPassword: boolean;
    
    public idLayout: number;
    
    public panelLogoExtension: string;

    public panelLogo: string;

    public video64: string;
    
    public haveLogo: boolean;

    public videoFormat: string;

    public listPanelOriginStruct: PanelOriginStruct[];
}