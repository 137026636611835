import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ListSupportContactResponse } from '../../responses/admin-user/list-support-contact.response';

@Injectable({
  providedIn: 'root'
})
export class SupportContactUnService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }
  public listAllSupportContact(): Observable<ListSupportContactResponse> {
    
    let uri = 'SupportContactUn/getAll'
    
    return this.httpClient.get<ListSupportContactResponse>(environment.urlApiAdminUser + uri)
    .pipe(
      catchError(this.handleError)
    )
  }
}
