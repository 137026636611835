import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { TypeItemKitEnum } from 'src/app/shared/enum/pharmacy/type-item-kit.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { KitTypeService } from 'src/app/shared/services/API/pharmacy/kit-type.service';
import { KitService } from 'src/app/shared/services/API/pharmacy/kit.service';
import { KitTypeModel } from 'src/app/shared/services/models/pharmacy/kit-type.model';
import { MedicineModel } from 'src/app/shared/services/models/pharmacy/medicine.model';
import { PostPutKitRequest } from 'src/app/shared/services/requests/pharmacy/post-put-kit.request';
import { ItemSearchSelectStruct } from 'src/app/shared/services/structs/pharmacy/item-search-select.struct';
import { ItemStruct } from 'src/app/shared/services/structs/pharmacy/item.struct';
import { KitItemIdAndQuantityStruct } from 'src/app/shared/services/structs/pharmacy/kit-item-id-and-quantity.struct';
import { KitItemSelectStruct } from 'src/app/shared/services/structs/pharmacy/kit-item-select.struct';

@Component({
  selector: 'app-kit-register',
  templateUrl: './kit-register.component.html',
  styleUrls: ['./kit-register.component.css']
})
export class KitRegisterComponent implements OnInit {

  constructor(
    private formBuilder: UntypedFormBuilder,
    private kitService: KitService,
    private kitTypeService: KitTypeService,
    private alertService: AlertService,
    private router: Router,
    private activatedRoute: ActivatedRoute,) { }

  public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.pharmacy;
  public menuFunctionalityEnum:MenuFunctionalityEnum = MenuFunctionalityEnum.pharmacy_kit;
  public typeItemKitEnum: typeof TypeItemKitEnum = TypeItemKitEnum;

  public model: UntypedFormGroup;
  public isLoading: boolean;
  public listItemFields: KitItemSelectStruct[];
  public listKitTypes: KitTypeModel[];
  public idKit: number;

  public loadProgressBarPile: number[];
  public loadActionPile: number[];

  ngOnInit(): void {
    this.loadProgressBarPile = [];
    this.loadActionPile = [];
    this.listItemFields = [];

    this.model = this.formBuilder.group({
      kitCode: ['', [Validators.required]],
      kitName: ['', [Validators.required]],
      idKitType: [null, [Validators.required]],
    });

    this.populateKitTypes();
    if (this.activatedRoute.snapshot.paramMap.get('idKit'))
      this.idKit = parseInt(this.activatedRoute.snapshot.paramMap.get('idKit'));

    if(this.idKit) {
      this.populateKitData();
    }
    else {
      this.addItem(null);
    }
  }

  submit() {
    if(this.checkSubComponentsIsInvalid() || this.loadActionPile.length > 0 || this.model.invalid) {
      return;
    }
    this.addLoadToActionPile(LoadEnum.loadingSubmit);

    let request = new PostPutKitRequest();
    request.idKitType = this.model.get('idKitType').value;
    request.kitCode = this.model.get('kitCode').value;
    request.kitName = this.model.get('kitName').value;
    this.listItemFields.forEach(element => {
      let kitItem: KitItemIdAndQuantityStruct = new KitItemIdAndQuantityStruct();
      kitItem.idItem = element.formGroup.get('firstChildGroup').get('idItem').value;
      kitItem.amount = element.formGroup.get('amount').value;
      if (element.formGroup.get('typeItem').value == this.typeItemKitEnum.Material) 
        request.listKitItems.push(kitItem);
      else 
        request.listKitMedicines.push(kitItem);
    });
    
    if(this.idKit) this.updateKit(request);
    else this.createKit(request);
  }

  createKit(request: PostPutKitRequest) {
    this.kitService.createKit(request).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.removeLoadFromActionPile(LoadEnum.loadingSubmit);
        return;
      }
      this.removeLoadFromActionPile(LoadEnum.loadingSubmit);
      this.alertService.show('Sucesso', "Todas as alterações foram salvas.", AlertType.success);
      this.router.navigate(['/pharmacy/kit']);
    },
      (error) => {
        console.log(error)
        this.removeLoadFromActionPile(LoadEnum.loadingSubmit);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });  
  }

  updateKit(request: PostPutKitRequest) {
    this.kitService.updateKit(this.idKit, request).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.removeLoadFromActionPile(LoadEnum.loadingSubmit);
        return;
      }
      this.removeLoadFromActionPile(LoadEnum.loadingSubmit);
      this.alertService.show('Sucesso', "Todas as alterações foram salvas.", AlertType.success);
      this.router.navigate(['/pharmacy/kit']);
    },
      (error) => {
        console.log(error)
        this.removeLoadFromActionPile(LoadEnum.loadingSubmit);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });  
  }

  checkSubComponentsIsInvalid() : boolean {
    let isInvalid: boolean = false;
    this.listItemFields.forEach(element => {
      element.formGroup.get('firstChildGroup').get('idItem').markAsTouched();
      element.formGroup.get('firstChildGroup').get('idItem').markAsDirty();
      element.formGroup.get('amount').markAsTouched();
      element.formGroup.get('amount').markAsDirty();
      if(element.formGroup.invalid)
        isInvalid = true;
    });

    return isInvalid;
  }

  populateKitData() {
    this.addLoadToActionPile(LoadEnum.loadingKitData);
    this.addLoadToProgressBarPile(LoadEnum.loadingKitData);
    this.kitService.getKit(this.idKit).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.removeLoadFromActionPile(LoadEnum.loadingKitData);
        this.removeLoadFromProgressBarPile(LoadEnum.loadingKitData);
        return;
      }

      this.model.get('idKitType').setValue(response.kit.kit.idKitType);
      this.model.get('kitCode').setValue(response.kit.kit.kitCode);
      this.model.get('kitName').setValue(response.kit.kit.kitName);

      response.kit.items.forEach(element => {
        this.addExistingItem(element, this.typeItemKitEnum.Material);
      });

      response.kit.medicines.forEach(element => {
        this.addExistingItem(element, this.typeItemKitEnum.Medicine);
      });

      this.removeLoadFromActionPile(LoadEnum.loadingKitData);
      this.removeLoadFromProgressBarPile(LoadEnum.loadingKitData);
    },
      (error) => {
        console.log(error)
        this.removeLoadFromActionPile(LoadEnum.loadingKitData);
        this.removeLoadFromProgressBarPile(LoadEnum.loadingKitData);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  populateKitTypes() {
    this.addLoadToProgressBarPile(LoadEnum.loadingKitType);
    this.kitTypeService.listAllKitTypes().subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.removeLoadFromProgressBarPile(LoadEnum.loadingKitType);
        return;
      }
      this.listKitTypes = response.listKitTypes;
      this.removeLoadFromProgressBarPile(LoadEnum.loadingKitType);
    },
      (error) => {
        console.log(error)
        this.removeLoadFromProgressBarPile(LoadEnum.loadingKitType);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  addItem(event: any) {
    let newItemField: KitItemSelectStruct = new KitItemSelectStruct();
    newItemField.preSelectedItem = [];

    if(this.listItemFields?.length == 0)
      newItemField.idField = 1;
    else if(this.listItemFields?.length >= 0)
      newItemField.idField = Math.max.apply(Math, this.listItemFields.map(l => l.idField)) + 1;
    
    newItemField.formGroup = this.formBuilder.group({
      typeItem: [null, [Validators.required]],
      firstChildGroup: this.formBuilder.group({
        idItem: [{value: null, disabled: true}, [Validators.required]]
      }),
      amount: [null, [Validators.required]],
    });
    this.listItemFields.push(newItemField);
    this.setLoneliness();
  }

  addExistingItem(item, idTypeItem: number) {
    let newItemField: KitItemSelectStruct = new KitItemSelectStruct();
    if(idTypeItem == this.typeItemKitEnum.Material) {
      let preselectedItem: ItemSearchSelectStruct = new ItemSearchSelectStruct();
      preselectedItem.idItem = item.idItem,
      preselectedItem.codeWithName = item.codeWithName;
      newItemField.preSelectedItem.push(preselectedItem);
    }
    else {
      let preSelectedMedicine: MedicineModel = new MedicineModel();
      preSelectedMedicine.idMedicine = item.idItem,
      preSelectedMedicine.medicineDescription = item.medicineDescription;
      newItemField.preSelectedMedicine.push(preSelectedMedicine);
    }

    if(this.listItemFields?.length == 0)
      newItemField.idField = 1;
    else if(this.listItemFields?.length >= 0)
      newItemField.idField = Math.max.apply(Math, this.listItemFields.map(l => l.idField)) + 1;
    
    newItemField.formGroup = this.formBuilder.group({
      typeItem: [idTypeItem, [Validators.required]],
      firstChildGroup: this.formBuilder.group({
        idItem: [item.idItem, [Validators.required]]
      }),
      amount: [item.amount, [Validators.required]],
    });
    this.listItemFields.push(newItemField);
    this.setLoneliness();
  }

  removeItem(event: any) {
    if(this.listItemFields?.length <= 1 ?? true)
      return;

    let index = this.listItemFields?.findIndex(l => l.idField == event);
    if(index > -1) 
      this.listItemFields?.splice(index, 1);
    this.setLoneliness();
  }

  setLoneliness() {
    if(this.listItemFields?.length <= 0 ?? true)
      return;

    if(this.listItemFields.length == 1) 
      this.listItemFields[0].isSingle = true;
    else if(this.listItemFields.length > 1) {
      this.listItemFields.forEach(element => {
        element.isSingle = false;
      });
    }
  }

  private addLoadToProgressBarPile(id: number) {
    this.loadProgressBarPile?.push(id);
  }

  private removeLoadFromProgressBarPile(id: number) {
    let index = this.loadProgressBarPile?.indexOf(id);
    if(index > -1) 
      this.loadProgressBarPile?.splice(index, 1);
  }

  private addLoadToActionPile(id: number) {
    this.loadActionPile?.push(id);
  }

  private removeLoadFromActionPile(id: number) {
    let index = this.loadActionPile?.indexOf(id);
    if(index > -1) 
      this.loadActionPile?.splice(index, 1);
  }
}

enum LoadEnum {
  loadingKitType = 1,
  loadingSubmit = 2,
  loadingKitData = 3
}
