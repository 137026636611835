<div mat-dialog-content>
    <div class="mat-dialog-header">
        <h3><b>Confirme ou altere os dados do paciente para o envio dos documentos</b></h3>
    </div>
    <div class="mat-dialog-content">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="row">
                <div class="col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Email</mat-label>
                        <input matInput type="text" formControlName="email">
                        <mat-error *ngIf="model.get('email').invalid">Informe o Email!</mat-error>
                    </mat-form-field>
                    <ul>
                        <li>
                            <mat-checkbox formControlName="allReport" (change)="allSelected()">
                                Todos os documentos
                            </mat-checkbox>
                        </li>
                        <li *ngIf="hasExternalExam && !this.model.get('allReport').value">
                            <mat-checkbox formControlName="examExternal">
                                Exames externo
                            </mat-checkbox>
                        </li>
                        <li *ngIf="hasInternalExam && !this.model.get('allReport').value">
                            <mat-checkbox formControlName="examInternal">
                                Exames interno
                            </mat-checkbox>
                        </li>
                        <li *ngIf="hasExternalPrescription && !this.model.get('allReport').value">
                            <mat-checkbox formControlName="prescriptionExternal">
                                Prescrições Não padronizadas
                            </mat-checkbox>
                        </li>
                        <li *ngIf="hasInternalPrescription && !this.model.get('allReport').value">
                            <mat-checkbox formControlName="prescriptionInternal">
                                Prescrições Padronizadas
                            </mat-checkbox>
                        </li>
                        <li *ngIf="hasProcedure && !this.model.get('allReport').value">
                            <mat-checkbox formControlName="isMedicalProcedureReport">
                                Procedimentos Médicos
                            </mat-checkbox>
                        </li>
                        <li *ngIf="hasMedicalCare && !this.model.get('allReport').value">
                            <mat-checkbox formControlName="medicalCareReport">
                                Atendimento médico
                            </mat-checkbox>
                        </li>
                        <li *ngIf="hasSickNote && !this.model.get('allReport').value">
                            <mat-checkbox formControlName="sickNote">
                                Atestado
                            </mat-checkbox>
                        </li>
                        <li *ngIf="hasIsolation && !this.model.get('allReport').value">
                            <mat-checkbox formControlName="isolation">
                                Notificação de Isolamento
                            </mat-checkbox>
                        </li>
                        <li *ngIf="hasIsolationAknowledge && !this.model.get('allReport').value">
                            <mat-checkbox formControlName="isolationAcknowledge">
                                Declaração de ciência de isolamento
                            </mat-checkbox>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="footer-panel-modal-list">
                <div class="row" >
                    <div class="col-6">
                        <button mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Cancelar</button>
                    </div>
                    <div class="col-6">
                        <button type="submit" mat-flat-button color="primary" class="btn-block">
                            <span *ngIf="!isLoading">Concluir</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>