import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { MatIconModule } from '@angular/material/icon';
import { MatTree, MatTreeModule } from '@angular/material/tree';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { ClassificationComponent } from './pages/classification/classification.component';
import { ClassificationRoutingModule } from './classification-routing.module';
import { SelectPatientModalComponent } from './pages/pre-register/select-patient-modal/select-patient-modal.component';
import { PreRegisterComponent } from './pages/pre-register/pre-register.component';
import { SearchModalComponent } from './pages/pre-register/search-modal/search-modal.component';
import { ProtocolSelectComponent } from './pages/protocol-select/protocol-select.component';
import { FlowchartComponent } from './pages/flowchart/flowchart.component';
import { ConfigSectorProtocolComponent } from './pages/config-sector-protocol/config-sector-protocol.component';
import { ProtocolSectorMultiselectComponent } from './pages/config-sector-protocol/protocol-sector-multiselect/protocol-sector-multiselect.component';
import { WhitePatientParentComponent } from './pages/white-patient-parent/white-patient-parent.component';
import { FlowchartSelectionComponent } from './pages/flowchart-selection/flowchart-selection.component';
import { DiscriminatorComponent } from './pages/flowchart/discriminator/discriminator.component';
import { ProtocolV1Component } from './pages/flowchart/protocol-v1/protocol-v1.component';
import { ProtocolV2Component } from './pages/flowchart/protocol-v2/protocol-v2.component';
import { GlasgowModalComponent } from './pages/flowchart/discriminator/glasgow-modal/glasgow-modal.component';
import { TemperatureModalComponent } from './pages/flowchart/discriminator/temperature-modal/temperature-modal.component';
import { SaturationModalComponent } from './pages/flowchart/discriminator/saturation-modal/saturation-modal.component';
import { HeartRateModalComponent } from './pages/flowchart/discriminator/heart-rate-modal/heart-rate-modal.component';
import { GlucoseModalComponent } from './pages/flowchart/discriminator/glucose-modal/glucose-modal.component';
import { CapillaryGlucoseModalComponent } from './pages/flowchart/discriminator/capillary-glucose-modal/capillary-glucose-modal.component';
import { RespiratoryFrequencyModalComponent } from './pages/flowchart/discriminator/respiratory-frequency-modal/respiratory-frequency-modal.component';
import { PressureModalComponent } from './pages/flowchart/discriminator/pressure-modal/pressure-modal.component';
import { PainModalComponent } from './pages/flowchart/discriminator/pain-modal/pain-modal.component';
import { NumericModalComponent } from './pages/flowchart/discriminator/numeric-modal/numeric-modal.component';
import { SepsisModalComponent } from './pages/flowchart/discriminator/sepsis-modal/sepsis-modal.component';
import { ForwardSelectComponent } from './pages/forward-select/forward-select.component';
import { SupplementaryDataComponent } from './pages/supplementary-data/supplementary-data.component';
import { ClassificationPatientListComponent } from './pages/classification-patient-list/classification-patient-list.component';
import { SubDiscriminatorComponent } from './pages/flowchart/discriminator/sepsis-modal/sub-discriminator/sub-discriminator.component';
import { TriageResultComponent } from './pages/triage-result/triage-result.component';
import { SepsisCriancaModalComponent } from './pages/flowchart/discriminator/sepsis-crianca-modal/sepsis-crianca-modal.component';
import { PreviousStateModalComponent } from './pages/flowchart/discriminator/heart-rate-modal/previous-state-modal/previous-state-modal.component';
import { GreaterGravityModalComponent } from './pages/flowchart/protocol-v2/greater-gravity-modal/greater-gravity-modal.component';
import { AcidosisModalComponent } from './pages/flowchart/discriminator/capillary-glucose-modal/acidosis-modal/acidosis-modal.component';
import { PreRegisterDefaultComponent } from './pages/pre-register/pre-register-default/pre-register-default.component';
import { PreRegisterRemoteComponent } from './pages/pre-register/pre-register-remote/pre-register-remote.component';
import { WithoutDeviceComponent } from './pages/pre-register/pre-register-remote/without-device-modal/without-device.component';
import { SearchRemoteModalComponent } from './pages/pre-register/pre-register-remote/search-remote-modal/search-remote-modal.component';
import { WarnOfflineModalComponent } from './pages/flowchart/protocol-v2/warn-offline-modal/warn-offline-modal.component';
import { DeviceOfflineModalComponent } from './pages/pre-register/pre-register-remote/device-offline-modal/device-offline-modal.component';
import { RoomMeasurerSelectComponent } from './pages/config-sector-protocol/room-measurer-select/room-measurer-select.component';
import { FlowchartSelectionDefaultComponent } from './pages/flowchart-selection/flowchart-selection-default/flowchart-selection-default.component';
import { FlowchartSelectionReclassificationComponent } from './pages/flowchart-selection/flowchart-selection-reclassification/flowchart-selection-reclassification.component';
import { AlertGreaterGravityModalComponent } from './pages/flowchart/protocol-v2/alert-greater-gravity-modal/alert-greater-gravity-modal.component';
import { TelephoneClassificationComponent } from './pages/telephone-classification/telephone-classification.component';
import { TelephoneClassificationDetailsComponent } from './pages/telephone-classification-details/telephone-classification-details.component';
import { TelephonePreRegisterComponent } from './pages/telephone-pre-register/telephone-pre-register.component';
import { CounselingSelectionComponent } from './pages/counseling-selection/counseling-selection.component';
import { PatientDetailsModalComponent } from './pages/telephone-classification-details/patient-details-modal/patient-details-modal.component';
import { ClassificationPrintingComponent } from './pages/classification-printing/classification-printing.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckbox, MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioButton, MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { NgxMaskModule } from 'ngx-mask';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { SelfTriageResumeModalComponent } from './pages/flowchart/self-triage-resume-modal/self-triage-resume-modal.component';
import { ChangeFlowchartModalComponent } from './pages/flowchart/change-flowchart-modal/change-flowchart-modal.component';
import { ModalPreResumeComponent } from './pages/forward-select/modal-pre-resume/modal-pre-resume.component';
import { MultipleVictimPrimaryAssessmentComponent } from './pages/flowchart/multiple-victim-primary-assessment/multiple-victim-primary-assessment.component';
import { MultipleVictimModalComponent } from './pages/flowchart/multiple-victim-primary-assessment/multiple-victim-modal/multiple-victim-modal.component';
import { MultipleVictimNumericModalComponent } from './pages/flowchart/multiple-victim-primary-assessment/multiple-victim-numeric-modal/multiple-victim-numeric-modal.component';
import { MultipleVictimSecondaryAssessmentComponent } from './pages/flowchart/multiple-victim-secondary-assessment/multiple-victim-secondary-assessment.component';
import { CapillaryPerfusionModalComponent } from './pages/flowchart/discriminator/capillary-perfusion-modal/capillary-perfusion-modal.component';
import { ConfirmPatientDataModalComponent } from './pages/pre-register/confirm-patient-data/confirm-patient-data-modal.component';
import { PreRegisterFormDefaultComponent } from './pages/pre-register/pre-register-form-default/pre-register-form-default.component';
import { NextStepsProtolModalComponent } from './pages/supplementary-data/next-steps-protol-modal/next-steps-protol-modal.component';
import { WhitePatientComponent } from './pages/white-patient-parent/white-patient/white-patient.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { HealthGuidelineTriageModalComponent } from './pages/flowchart/protocol-v2/health-guideline-triage-modal/health-guideline-triage-modal.component';
import { LastTriageDetailsModalComponent } from './pages/flowchart-selection/last-triage-details-modal/last-triage-details-modal.component';

@NgModule({
  declarations: [
    ClassificationComponent,
    SelectPatientModalComponent,
    PreRegisterComponent,
    SearchModalComponent,
    ProtocolSelectComponent,
    FlowchartSelectionComponent,
    WhitePatientParentComponent,
    WhitePatientComponent,
    DiscriminatorComponent,
    SubDiscriminatorComponent,
    FlowchartComponent,
    ConfigSectorProtocolComponent,
    ProtocolSectorMultiselectComponent,
    ProtocolV1Component,
    ProtocolV2Component,
    GlasgowModalComponent,
    TemperatureModalComponent,
    SaturationModalComponent,
    HeartRateModalComponent,
    GlucoseModalComponent,
    CapillaryGlucoseModalComponent,
    RespiratoryFrequencyModalComponent,
    PressureModalComponent,
    PainModalComponent,
    NumericModalComponent,
    SepsisModalComponent,
    SepsisCriancaModalComponent,
    ForwardSelectComponent,
    SupplementaryDataComponent,
    PreviousStateModalComponent,
    ClassificationPatientListComponent,
    TriageResultComponent,
    GreaterGravityModalComponent,
    AcidosisModalComponent,
    PreRegisterDefaultComponent,
    PreRegisterFormDefaultComponent,
    PreRegisterRemoteComponent,
    WithoutDeviceComponent,
    SearchRemoteModalComponent,
    DeviceOfflineModalComponent,
    WarnOfflineModalComponent,
    RoomMeasurerSelectComponent,
    FlowchartSelectionDefaultComponent,
    FlowchartSelectionReclassificationComponent,
    AlertGreaterGravityModalComponent,
    TelephoneClassificationComponent,
    TelephoneClassificationDetailsComponent,
    TelephonePreRegisterComponent,
    CounselingSelectionComponent,
    PatientDetailsModalComponent,
    ClassificationPrintingComponent,
    SelfTriageResumeModalComponent,
    ChangeFlowchartModalComponent,
    ModalPreResumeComponent,
    MultipleVictimPrimaryAssessmentComponent,
    MultipleVictimModalComponent,
    MultipleVictimNumericModalComponent,
    MultipleVictimSecondaryAssessmentComponent,
    CapillaryPerfusionModalComponent,
    ConfirmPatientDataModalComponent,
    NextStepsProtolModalComponent,
    HealthGuidelineTriageModalComponent,
    LastTriageDetailsModalComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ClassificationRoutingModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTreeModule,
    MatRadioModule,
    MatTooltipModule,
    MatToolbarModule,
    NgxMatFileInputModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    CdkAccordionModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    SharedModule,
    ClipboardModule,
  ],
  providers: [
    AuthGuard,
    MatCheckbox,
    MatCheckboxChange,
    MatTree,
    MatRadioButton,
  ],
  exports: [
    FlowchartSelectionDefaultComponent,
    WhitePatientComponent,
    ProtocolV2Component,
    ProtocolV1Component
  ]
})
export class ClassificationModule { }
