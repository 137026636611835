import { AllergyStruct } from "../../structs/medical-record/allergy.struct";
import { CareLinePriorityStatusStruct } from "../../structs/medical-record/care-line-priority-status.struct";
import { ExternalPrescriptionStruct } from "../../structs/medical-record/external-prescription.struct";
import { MetaDataKeyValueStruct } from "../../structs/meta-data/meta-data-key-value.struct";
import { SadtGroupStruct } from "../../structs/multi-professional/sadt-group.struct";
import { MultiProfessionalCareReportStruct } from "../../structs/orchestrator-patient/multi-professional-care-report.struct";
import { ReadMultiProfessionalPrescriptionStruct } from "../../structs/orchestrator-patient/read-multi-professional-prescription.struct";
import { EpisodeCareLineRequest } from "../care-line/episode-care-line.request";
import { GuideRequest } from "../private-billing/guide.request";

export class MultiProfessionalCareRequest {
    public multiProfessionalCareSadt: SadtGroupStruct[] = [];
    public listExternalPrescription: ExternalPrescriptionStruct[] = [];
    public listInternalPrescription: ReadMultiProfessionalPrescriptionStruct[] = [];
    public episodeCareLineRequest: EpisodeCareLineRequest ;
    public multiProfessionalCareReportStruct: MultiProfessionalCareReportStruct = new MultiProfessionalCareReportStruct();
    public cid: number[] = [];
    public isCidOnSickNote: boolean;
    public listAllergy: AllergyStruct[] = [];
    public conclusionNote: string;
    public administrativeRelease: boolean;
    public idStatusEpisode: number;
    public idMultiProfessionalCare: number;
    public idUser: number;
    public idEpisode: number;
    public medicalNotes: string;
    public physicalExamination: string;
    public therapeuticPlan: string;
    public diagnosedHypothesis: string;
    public temperature: number;
    public respiratoryFrequency: number;
    public heartRate: number;
    public glucose: number;
    public bloodPressureDiastole: number;
    public bloodPressureSystole: number;
    public saturation: number;
    public datetimeInclusion: Date;
    public patientEvolution: string;
    public idRoomAttendance: number;
    public idQueueUsed: number;
    public datetimeStartAttendance: Date;
    public sickDaysOffAmount: number;
    public multiProfessionalCareReportName: string;
    public sickNoteReportName: string;
    public sadtReportExternalName: string;
    public externalPrescriptionReportName: string;
    public metaData: MetaDataKeyValueStruct[];
    public careLinePriorityStatusStruct: CareLinePriorityStatusStruct = new CareLinePriorityStatusStruct();
    public idSector: number;
    public conclusionType: number;
    public idMedicalCare: number;
    public guideRequest: GuideRequest;
    public hideDataPatientHistory: boolean;
}