export class SigaPatientAddressStruct {
    public originAddress: string; // select tipo origem endereço
    public zipCode: string;
    public residenceCitySus: string; // select cidade
    public admDistrictCodeStruct: string; // select distrito
    public streetType: string; // select tipo logradouro
    public street: string;
    public houseNumber: string;
    public noHouseNumber: boolean;
    public houseComplement: string;
    public neighborhood: string;
    public referencePoint: string;
}