import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EpisodeStatusEnum } from 'src/app/shared/enum/episode-status.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { SelectedLocationRequest } from 'src/app/shared/services/requests/orchestrator-queue/selected-location.request';
import { EpisodeAdministrativeReleaseConfirmModalComponent } from '../episode-administrative-release-confirm-modal/episode-administrative-release-confirm-modal.component';
import { AttendPatientConfirmModalComponent } from './attend-patient-confirm-modal/attend-patient-confirm-modal.component';

@Component({
  selector: 'app-attend-patient-modal',
  templateUrl: './attend-patient-modal.component.html',
  styleUrls: ['./attend-patient-modal.component.css']
})
export class AttendPatientModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<AttendPatientModalComponent>,
    private alertService: AlertService,
    private formBuilder: UntypedFormBuilder) { }

  public isLoading: boolean;
  public model: UntypedFormGroup;
  public selectedLocationRequest: SelectedLocationRequest;
  public isEpisodeAdministrativeRelease: boolean = false;
  public idEpisodeAdministrativeRelease: number;
  public idStatusEpisodeInternar: number = EpisodeStatusEnum.FinalizadoInternar.valueOf();
  public idStatusEpisodeAlta: number = EpisodeStatusEnum.FinalizadoAlta.valueOf();
  public idStatusEpisodeAltaAdministrativa: number = EpisodeStatusEnum.FinalizadoAltaAdministrativa.valueOf();

  ngOnInit(): void {

    this.isLoading = false;
    this.idEpisodeAdministrativeRelease = this.data.idStatus;
    this.isEpisodeAdministrativeRelease = this.data.idStatus == this.idStatusEpisodeInternar || this.data.idStatus == this.idStatusEpisodeAltaAdministrativa;
    this.model = this.formBuilder.group({
      listIdPriority: [''],
      idFlow: [''],
    });

    this.populatePriority();
  }

  clickConfirm() {
    this.matDialogRef.close({ fowardPatient: false });
  }

  clickCancel() {
    this.matDialogRef.close({ fowardPatient: false });
  }
  populatePriority() {
    this.model.get('listIdPriority').setValue(this.data.listPatientPriority.map(c => c.toString()))
  }
  submit() {

    this.selectedLocationRequest = new SelectedLocationRequest();

    this.selectedLocationRequest.idEpisode = this.data.idEpisode;
    this.selectedLocationRequest.idQueue = this.data.idQueue;
    this.selectedLocationRequest.idFlow = this.model.get('idFlow').value;
    this.selectedLocationRequest.idRoomOrigin = this.data.idRoom;
    const dialogRef = this.dialog.open(AttendPatientConfirmModalComponent, {
      data: {
        selectedLocationRequest: this.selectedLocationRequest,
        listIdPriority: this.model.get('listIdPriority').value,
        idEpisode: this.data.idEpisode,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.fowardPatient) {
        this.matDialogRef.close({ fowardPatient: true });
      }
    });
  }

  releaseEpisodeModal() {
    const dialogRef = this.dialog.open(EpisodeAdministrativeReleaseConfirmModalComponent, {
      data: {
        idEpisode: this.data.idEpisode,
        idStatus: this.data.idStatus
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.release) {
        this.matDialogRef.close({ fowardPatient: true });
      }
    });
  }

}