import { Component, OnInit } from '@angular/core';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { PatientStruct } from 'src/app/shared/services/structs/medical-record/patient.struct';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { TriageStruct } from 'src/app/shared/services/structs/orchestrator-patient/triage.struct';
import { TriageService } from 'src/app/shared/services/API/orchestrator-classification/triage.service';
import { ClassificationData, UtilsClassificationService } from '../../utils.service';
import { DiscriminatorTypeEnum } from 'src/app/shared/enum/discriminator-type.enum';
import { CounselingStruct } from 'src/app/shared/services/structs/risk-classification/counseling.struct';
import { PatientRequest } from 'src/app/shared/services/requests/medical-record/patient.request';
import { PatientDetailsModalComponent } from './patient-details-modal/patient-details-modal.component';
import { MedicalRecordLookupService } from 'src/app/shared/services/API/medical-record/lookup.service';
import { Gender } from 'src/app/shared/services/responses/medical-record/lookup.response';
import { TelemedicineSmsService } from 'src/app/shared/services/API/orchestrator-telemedicine/telemedicine-sms.service';
import { PostTelemedicineSmsRequest } from 'src/app/shared/services/requests/srv-email/post-telemedicine-sms.request';
import { UtilService } from 'src/app/shared/services/util.service';
import { TelephonePatientService } from 'src/app/shared/services/API/medical-record/telephone-patient.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DiscriminadorHeartRegular, DiscriminadorSaturationAtmosphericAir } from 'src/app/shared/enum/risk-classification/discriminators.enum';

@Component({
  selector: 'app-telephone-classification-details',
  templateUrl: './telephone-classification-details.component.html',
  styleUrls: ['./telephone-classification-details.component.css']
})
export class TelephoneClassificationDetailsComponent implements OnInit {

  constructor(private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private triageService: TriageService,
    private formBuilder: FormBuilder,
    private maskService: MaskService,
    private dialog: MatDialog,
    private telephonepatientService: TelephonePatientService,
    private utilClassificationService: UtilsClassificationService,
    private utilService: UtilService,
    public router: Router,
    private datepipe: DatePipe,
    public lookupService: MedicalRecordLookupService,
    private telemedicineSmsService: TelemedicineSmsService,
  ) { }

  public model: FormGroup;
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.classification;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.telephone_classification;
  public isFirstLoading: boolean = true;
  public isUnidentifiedPatient: boolean = false;
  public masks: Masks;
  public triage: TriageStruct;
  public patientDetails: PatientStruct;

  public isLoading: boolean = false;
  public hasTriage: boolean = false;
  public data: ClassificationData;
  public discriminators: any[] = [];
  public pain: number;
  public body: PostTelemedicineSmsRequest;
  public baseUrl: string;
  public isCheckedTelemedicine: boolean = false;
  public painType: number;
  public protocolName: string;
  public newTriage: boolean;
  public listCounseling: string = '';
  public listGender: Gender[];

  ngOnInit(): void {
    this.populateLookupSelect();
    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      listIdPriority: [''],
      unidentifiedPatientDescription: [''],
      patientName: [''],
      socialName: [''],
      birthDate: [''],
      patientAge: [{ value: '', disabled: true }],
      genderName: [''],
      cpf: [''],
      cns: [''],
      city: [''],
      street: [''],
      houseNumber: [''],
      apartmentNumber: [''],
      state: [''],
      country: [''],
      neighborhood: [''],
      zipCode: [''],
      advice: [''],
      observation: [''],
      phone1: [''],
      listCounseling: ['']
    });
    this.getCompleteUrl();

    let idTriage = parseInt(this.activatedRoute.snapshot.paramMap.get('idTriage'));
    if (idTriage) {
      this.getTriage(idTriage);
      this.hasTriage = true;
      this.newTriage = false;
    } 
    else {
      this.isFirstLoading = false;
      this.newTriage = true;
      this.data = this.utilClassificationService.getClassificationData();

      if (this.data.listCounseling) {
        this.data.listCounseling.forEach((counseling: CounselingStruct) => {
          this.listCounseling = this.listCounseling + counseling.counselingName + '\n';
        });
      }

      this.mapValues();
      this.patientDetails = this.mapRequestToStruct(this.data.patientData);
    }
  }

  mapValues() {
    this.model.get('patientName').setValue(this.data.patientData.patientName);
    this.model.get('socialName').setValue(this.data.patientData.socialName);
    this.model.get('birthDate').setValue(this.datepipe.transform(this.data.patientData.birthDate, 'dd/MM/yyyy'));
    let patientAge = this.utilService.getAge(this.model.get('birthDate').value);
    this.model.get('patientAge').setValue(`${patientAge.years}a ${patientAge.months}m ${patientAge.days}d`);
    this.model.get('genderName').setValue(this.data.patientData.genderName);
    this.model.get('cpf').setValue(this.data.patientData.cpf);
    this.model.get('cns').setValue(this.data.patientData.cns);
    this.model.get('city').setValue(this.data.patientData.city);
    this.model.get('street').setValue(this.data.patientData.street);
    this.model.get('houseNumber').setValue(this.data.patientData.houseNumber);
    this.model.get('apartmentNumber').setValue(this.data.patientData.apartmentNumber);
    this.model.get('state').setValue(this.data.patientData.state);
    this.model.get('country').setValue(this.data.patientData.country);
    this.model.get('neighborhood').setValue(this.data.patientData.neighborhood);
    this.model.get('zipCode').setValue(this.data.patientData.zipCode);
    this.model.get('phone1').setValue(this.data.patientData.phoneConfirmation);
    this.model.get('observation').setValue(this.data.observation);
    this.model.get('listCounseling').setValue(this.listCounseling);
    this.triage = new TriageStruct();
    this.protocolName = this.data.protocolName;
    let discriminatorValues = this.data.discriminatorValues && this.data.discriminatorValues.length > 0 ? this.data.discriminatorValues : false;
    this.triage.protocolName = this.data.protocolName;
    this.triage.idProtocol = this.data.idProtocol;

    this.triage.patientName = this.data.patientData.patientName;
    this.triage.patientSocialName = this.data.patientData.socialName;
    this.triage.patientBirthDate = this.data.patientData.birthDate;
    this.triage.patientGender = this.data.patientData.genderName;
    this.triage.idPatientGender = this.data.patientData.idGender;
    this.triage.patientCpf = this.data.patientData.cpf;
    this.triage.patientCns = this.data.patientData.cns;

    this.triage.startClassification = this.data.datetimeStartTriage;
    this.triage.idGravity = this.data.priority.idPriorityColor;
    this.triage.gravityColor = this.data.priority.coloerHex;
    this.triage.temperature = discriminatorValues[DiscriminatorTypeEnum.Temperatura] ? parseFloat(discriminatorValues[DiscriminatorTypeEnum.Temperatura]) : null;;
    this.triage.respiratoryFrequency = discriminatorValues[DiscriminatorTypeEnum.FrequenciaRespiratoria] ? parseInt(discriminatorValues[DiscriminatorTypeEnum.FrequenciaRespiratoria]) : null;;
    this.triage.heartRate = discriminatorValues[DiscriminatorTypeEnum.FrequenciaCardiaca] ? parseInt(discriminatorValues[DiscriminatorTypeEnum.FrequenciaCardiaca]) : null;
    if (!Array.isArray(this.data.discriminatorAllValues))
      this.data.discriminatorAllValues = [];

    let heartRate = this.data.discriminatorAllValues.find(c => c.idDiscriminatorType == DiscriminatorTypeEnum.FrequenciaCardiaca);
    this.triage.heartRateRegular = heartRate ? heartRate.type == DiscriminadorHeartRegular.regular.toString() : null;
    this.triage.glucose = discriminatorValues[DiscriminatorTypeEnum.Glicemia] ? parseInt(discriminatorValues[DiscriminatorTypeEnum.Glicemia]) : null;
    this.triage.bloodPressureDiastole = discriminatorValues[DiscriminatorTypeEnum.PressaoArterial] ? parseInt(discriminatorValues[DiscriminatorTypeEnum.PressaoArterial].split("/")[1]) : null;;
    this.triage.bloodPressureSystole = discriminatorValues[DiscriminatorTypeEnum.PressaoArterial] ? parseInt(discriminatorValues[DiscriminatorTypeEnum.PressaoArterial].split("/")[0]) : null;
    this.triage.saturation = discriminatorValues[DiscriminatorTypeEnum.Saturacao] ? parseInt(discriminatorValues[DiscriminatorTypeEnum.Saturacao]) : null;
    let discriminatorPain = this.data.discriminatorAllValues.find(c => c.idDiscriminatorType == DiscriminatorTypeEnum.Dor);
    this.pain = discriminatorPain ? discriminatorPain.value : null;
    this.painType = discriminatorPain ? discriminatorPain.type : null;
    this.triage.idPain = discriminatorPain ? discriminatorPain.type : null;
    this.triage.complaint = this.data.complain;
    this.triage.flowchart = this.data.flowchartName;
    this.triage.idFlowchart = this.data.idFlowchart;
    this.triage.discriminator = !this.data.isPatientWhite ? this.data.discriminator.discriminatorName : this.data.arrivalReason;
    this.triage.idDiscriminator = this.data.discriminator ? this.data.discriminator.idDiscriminator : null;
    this.triage.glasgow = discriminatorValues[DiscriminatorTypeEnum.Glasgow] ? discriminatorValues[DiscriminatorTypeEnum.Glasgow] : null;
    let DiscriminatorSaturation = this.data.discriminatorAllValues.find(c => c.idDiscriminatorType == DiscriminatorTypeEnum.Saturacao);
    this.triage.atmosphericAir = DiscriminatorSaturation ? DiscriminatorSaturation.type == DiscriminadorSaturationAtmosphericAir.atmosphericAir.toString() : null;
    let capillaryFillingTime = this.data.discriminatorAllValues.find(c => c.idDiscriminatorType == DiscriminatorTypeEnum.GlicemiaCapilar);
    this.triage.capillaryFillingTime = capillaryFillingTime && capillaryFillingTime.type == "true" ? true : capillaryFillingTime && capillaryFillingTime.type == "false" ? false : null;
    this.triage.fallRisk = this.data.radioFallRisk;
    this.triage.idSuspicion = this.data.idSuspicion;
    this.triage.diseaseHistory = this.data.diseaseHistory;
    this.triage.height = this.data.height ? parseFloat(this.data.height) : null;
    this.triage.weight = this.data.weight;
    this.triage.gravityName = this.data.priority.priorityColorName;
    this.triage.observation = (this.data.observation ? this.data.observation : "") + "" + (this.data.arrivalReasonDesc ? this.data.arrivalReasonDesc : "");
    this.triage.idPatient = this.data.idPatient;
    this.triage.listCounseling = this.data.listCounseling;
    this.triage.userClassificationName = JSON.parse(sessionStorage.getItem("token")).userName;
    this.triage.userCouncilNumber = JSON.parse(sessionStorage.getItem("token")).council;
  }

  getTriage(idTriage: number) {
    this.triageService.getTriage(idTriage).subscribe({
      next: (response) => {
        this.isFirstLoading = false;
        if (response.isError) {
          this.alertService.show("Erro", response.errorDescription, AlertType.error);
          return;
        }

        this.triage = response.triageStruct;

        response.triageStruct.listCounseling.forEach((counseling: CounselingStruct) => {
          this.listCounseling = this.listCounseling + counseling.counselingName + '\n';
        });

        this.model.get('listCounseling').setValue(this.listCounseling);
        if (!response.patient.isUnidentifiedPatient) {
          this.mapValueToForm(response.patient);
          this.patientDetails = response.patient;
        }
      }, 
      error: (error) => {
        console.log(error);
        this.alertService.show("Erro inesperado", error, AlertType.error);
        this.isFirstLoading = false;
      }
    });
  }

  mapValueToForm(patient: PatientStruct) {
    this.model.get('patientName').setValue(patient.patientName);
    this.model.get('socialName').setValue(patient.socialName);
    this.model.get('birthDate').setValue(this.datepipe.transform(patient.birthDate, 'dd/MM/yyyy'));
    if (patient.birthDate) {
      let patientAge = this.utilService.getAge(this.model.get('birthDate').value);
      this.model.get('patientAge').setValue(`${patientAge.years}a ${patientAge.months}m ${patientAge.days}d`);
    }
    this.model.get('genderName').setValue(patient.genderName);
    this.model.get('cpf').setValue(patient.cpf);
    this.model.get('cns').setValue(patient.cns);
    this.model.get('city').setValue(patient.city);
    this.model.get('street').setValue(patient.street);
    this.model.get('houseNumber').setValue(patient.houseNumber);
    this.model.get('apartmentNumber').setValue(patient.apartmentNumber);
    this.model.get('state').setValue(patient.state);
    this.model.get('country').setValue(patient.country);
    this.model.get('neighborhood').setValue(patient.neighborhood);
    this.model.get('zipCode').setValue(patient.zipCode);
    this.model.get('phone1').setValue(patient.phoneConfirmation);
  }

  goBack() {
    if (this.newTriage) {
      if (!this.data.listCounseling || this.data.listCounseling.length == 0)
        this.router.navigate(['classification/flowchart']);
      else
        this.router.navigate(['classification/counseling-selection']);
    }
    else
      this.router.navigate(['/classification/telephone-classification']);
  }

  submit() {
    if (this.isLoading)
      return;

    this.isLoading = true;
    this.triageService.postTriage(this.triage).subscribe({
      next: (response) => {
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show("Erro", response.errorDescription, AlertType.error);
          return;
        }

        let body: PostTelemedicineSmsRequest = new PostTelemedicineSmsRequest();
        body.baseUrl = this.baseUrl;
        body.phone1 = this.model.get('phone1').value;
        body.patientName = this.model.get('patientName').value;
        body.idPatient = this.data.idPatient;
        body.cpf = this.model.get('cpf').value;
        body.isCheckedTelemedicine = this.data.telemedicineCheck;      
        
        if (this.data.patientData.phoneConfirmation != this.data.patientData.phone1) {
          let patientRequest: PatientRequest = new PatientRequest();
          patientRequest.idPatient = this.data.idPatient;
          patientRequest.cpf = this.model.get('cpf').value;
          patientRequest.phone1 = this.model.get('phone1').value;
          patientRequest.patientName = this.model.get('patientName').value;
          patientRequest.updatePhoneOnly = true;

          this.updatePatient(patientRequest);
        }

        this.sendSms(body);
      },
      error: (error) => {
        console.log(error);
        this.alertService.show("Erro Inesperado", error, AlertType.error);
      }
    });
  }

  updatePatient(body) {
    this.telephonepatientService.savePatient(body).subscribe({
      next: (response) => {
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show("Erro", response.errorDescription, AlertType.error);
          return;
        }
      },
      error: (error) => {
        console.log(error);
        this.alertService.show("Erro Inesperado", error, AlertType.error);
      }
    })
  }

  getCompleteUrl() {
    this.baseUrl = environment.urlBasePatientUI + "telemedicine";
  }


  sendSms(body) {
    this.telemedicineSmsService.smsTelemedicine(body).subscribe({
      next: (response) => {
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show("Erro", response.errorDescription, AlertType.error);
          return;
        }

        this.alertService.show("Sucesso", "Triagem salva com sucesso!", AlertType.success);
        this.router.navigate(['/classification/telephone-classification']);
      },
      error: (error) => {
        console.log(error);
        this.alertService.show("Erro Inesperado", error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  openDetailsModal() {
    const dialogRef = this.dialog.open(PatientDetailsModalComponent, {
      data: {
        patientDetails: this.patientDetails,
      },
    });
  }

  mapRequestToStruct(patientRequest: PatientRequest): PatientStruct {
    let patient: PatientStruct = new PatientStruct();
    patient.patientName = patientRequest.patientName;
    patient.phone1 = patientRequest.phone1;
    patient.phoneConfirmation = patientRequest.phoneConfirmation;
    patient.phone2 = patientRequest.phone2;
    patient.cpf = patientRequest.cpf;
    patient.birthDate = patientRequest.birthDate;
    patient.cns = patientRequest.cns;
    patient.socialName = patientRequest.socialName;
    patient.genderName = patientRequest.genderName;
    patient.zipCode = patientRequest.zipCode;
    patient.street = patientRequest.street;
    patient.houseNumber = patientRequest.houseNumber;
    patient.apartmentNumber = patientRequest.apartmentNumber;
    patient.neighborhood = patientRequest.neighborhood;
    patient.city = patientRequest.city;
    patient.state = patientRequest.state;
    patient.country = patientRequest.country;
    return patient;
  }

  populateLookupSelect() {
    this.lookupService.getLookup().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listGender = response.listGenders;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}