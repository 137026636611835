export class SigaCertificateStruct {
    public newCertificate: NewCretificate = null;
    public oldCertificate: OldCretificate = null;
    public isNewCertificate: boolean = false;
}

export class NewCretificate
{
    public registerNumber: string;
    public emissionDate: Date;
}

export class OldCretificate
{
    public registryName: string;
    public pageNumber: string;
    public bookNumber: string;
    public termNumber: string;
    public emissionDate: Date;
}