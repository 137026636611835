import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { HygienizationStatusEnum } from 'src/app/shared/enum/hygienization-status.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { BedCategoryService } from 'src/app/shared/services/API/bed/bed-category.service';
import { BedStatusService } from 'src/app/shared/services/API/bed/bed-status.service';
import { BedTypeService } from 'src/app/shared/services/API/bed/bed-type.service';
import { BedService } from 'src/app/shared/services/API/bed/bed.service';
import { UserGetNameService } from 'src/app/shared/services/API/user/user-get-name.service';
import { BedCategoryModel } from 'src/app/shared/services/models/bed/bed-category.model';
import { BedStatusModel } from 'src/app/shared/services/models/bed/bed-status.model';
import { BedTypeModel } from 'src/app/shared/services/models/bed/bed-type.model';
import { BedStruct } from 'src/app/shared/services/structs/bed/bed.struct';
import { BedDeleteModalComponent } from './bed-delete-modal/bed-delete-modal.component';

@Component({
  selector: 'app-bed-list',
  templateUrl: './bed-list.component.html',
  styleUrls: ['./bed-list.component.css']
})
export class BedListComponent implements OnInit {

  constructor(
    private bedService: BedService,
    private bedStatusService: BedStatusService,
    private bedTypeService: BedTypeService,
    private router: Router,
    private alertService: AlertService,
    public dialog: MatDialog,
    private bedCategoryService: BedCategoryService,
    private userGetNameService: UserGetNameService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.observation;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.observation_bed;

  public listBed: BedStruct[];
  public listBedType: BedTypeModel[];
  public listBedStatus: BedStatusModel[];
  public listBedCategory: BedCategoryModel[];
  public isLoading: boolean;
  public searchText: string;
  public idBedStatus: number;
  public idBedType: number;
  public idBedCategory: number;
  public hygienization: boolean = false;
  public unhygienizedStatus: number =  HygienizationStatusEnum.nao_higienizado;
  public hygienizingStatus: number = HygienizationStatusEnum.em_higienizacao;
  public hygienizedStatus: number = HygienizationStatusEnum.higienizado;
  public occupiedStatus: number = HygienizationStatusEnum.em_uso;

  ngOnInit(): void {
    this.isLoading = true;
    this.populateBedStatusSelect();
    this.populateBedTypeSelect();
    this.populateBedCategorySelect();
    this.search();
  }

  search() {
    this.isLoading = true;
    if (this.searchText == "")
      this.searchText = null;

    this.bedService.listBed(this.searchText, this.idBedType, this.idBedStatus, this.idBedCategory).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.hygienization = response.hygienizationConfig;
      this.listBed = response.listBedStruct;
      
      this.isLoading = false;
      
      if (this.hygienization){
        let listIdUser = this.listBed.map(bed => bed.idHygienizationUser).filter(x => x != null);
  
        this.getUserNames(listIdUser);
      }
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  getUserNames(listIdUser: number[]) {
    this.isLoading = true;
    this.userGetNameService.listUser(listIdUser).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.listBed.forEach(x => {
        if(x.idHygienizationUser != null){
          x.hygienizationUserName = response.listUser.find(y => y.idUser == x.idHygienizationUser).userName;
        }
      });

      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  populateBedTypeSelect() {
    this.bedTypeService.listBedType().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }

      this.listBedType = response.listBedType;
    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  populateBedStatusSelect() {
    this.bedStatusService.listBedStatus().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }

      this.listBedStatus = response.listBedStatus;
    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  populateBedCategorySelect() {
    this.bedCategoryService.listBedCategory().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }

      this.listBedCategory = response.listBedCategory;
    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }

  openModal(idBed){
    const dialogRef = this.dialog.open(BedDeleteModalComponent, {
      data: {
        idBed: idBed
      },
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.deleteExamType){
        this.search();
      }
    });
  }
}