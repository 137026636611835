import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { PharmacyMovementRequest } from '../../requests/pharmacy/pharmacy-movement.request';
import { ListStorageResponse } from '../../responses/pharmacy/list-storage.response';
import { PharmacyMovementResponse } from '../../responses/pharmacy/pharmacy-movement.response';

@Injectable({
  providedIn: 'root'
})
export class PharmacyMovementDashboardService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listAllExpiredProducts(pageIndex: number, description: string): Observable<ListStorageResponse> {
    let uri = 'PharmacyMovementDashboard/getAllExpired?'

    if (pageIndex) {
      uri = uri + "pageIndex=" + pageIndex + "&";
    }
    if (description) {
      uri = uri + "description=" + description + "&";
    }

    return this.httpClient.get<ListStorageResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public listAllBelowMinimumProducts(pageIndex: number, description: string): Observable<ListStorageResponse> {
    let uri = 'PharmacyMovementDashboard/getAllBelowMinimum?'

    if (pageIndex) {
      uri = uri + "pageIndex=" + pageIndex + "&";
    }
    if (description) {
      uri = uri + "description=" + description + "&";
    }

    return this.httpClient.get<ListStorageResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}