import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DeviceTypeEnum } from 'src/app/shared/enum/device-type.enum';
import { DeviceTypeModel } from 'src/app/shared/services/models/risk-classification/device-type.model';
import { MeasurerModel } from 'src/app/shared/services/models/risk-classification/measurer.model';
import { PreAdmissionRequiredField } from 'src/app/shared/services/models/risk-classification/pre-admission-required-field.model';
import { ProtocolModel } from 'src/app/shared/services/models/risk-classification/protocol.model';
import { SupplementaryDataRequiredField } from 'src/app/shared/services/models/risk-classification/supplementary-data-required-field.model';
import { SectorStruct } from 'src/app/shared/services/structs/flow/sector.struct';
import { MetaDataStruct } from 'src/app/shared/services/structs/meta-data/meta-data.struct';
import { ProtocolSectorStruct } from 'src/app/shared/services/structs/risk-classification/protocol-sector.struct';

@Component({
  selector: 'app-protocol-sector-multiselect',
  templateUrl: './protocol-sector-multiselect.component.html',
  styleUrls: ['./protocol-sector-multiselect.component.css']
})
export class ProtocolSectorMultiselectComponent implements OnInit {

  @Input() idRoom: number;
  @Input() sector: SectorStruct;
  @Input() listProtocol: ProtocolModel[];
  @Input() listDeviceType: DeviceTypeModel[];
  @Input() formGroup: FormGroup;
  @Input() listOximeter: MeasurerModel[];
  @Input() listThermometer: MeasurerModel[];
  @Input() listPressureMeasurer: MeasurerModel[];
  @Input() listGlucometer: MeasurerModel[];
  @Input() listSupplementaryDataRequiredField: SupplementaryDataRequiredField[];
  @Input() listPreAdmissionRequiredField: PreAdmissionRequiredField[];
  @Input() listMetaData: MetaDataStruct[];
  @Input() listIdMetaDataRequired: number[] = [];
  @Output() selectedStatus = new EventEmitter<any>();

  constructor() { }

  public model: FormGroup;
  public listSectorProtocol: ProtocolSectorStruct[];
  public listIdMetaDataRequiredSelected: number[] = [];
  public listMetaDataRequiredSelected: MetaDataStruct[] = [];

  public listIdProtocol = [];
  public isRemoteDevice = false;

  ngOnInit(): void {
    this.model = this.formGroup;

    if (this.model.get('listMetaDataRequiredSelected') && this.model.get('listMetaDataRequiredSelected').value)
      this.listMetaDataRequiredSelected = this.listMetaData.filter((item) => this.model.get('listMetaDataRequiredSelected').value.some((item2: number) => item.metaData.idMetaData == item2));

    this.verifyDeviceType();
  }

  verifyDeviceType() {
    if (this.model.get('idDeviceType').value && this.model.get('idDeviceType').value == DeviceTypeEnum.Remoto)
      this.isRemoteDevice = true;
    else
      this.isRemoteDevice = false;
  }

  selectMetaData(event: any) {
    let listIdMetaDataSelected = this.listMetaDataRequiredSelected.filter(x => x.metaData.idMetaData == event);
    if (listIdMetaDataSelected.length == 0) {
      let idMetaDataSelected = this.listMetaData.filter(x => x.metaData.idMetaData == event);
      idMetaDataSelected.forEach(x => {
        this.listMetaDataRequiredSelected.push(x)
      });
    }
    else
      this.listMetaDataRequiredSelected = this.listMetaDataRequiredSelected.filter(x => x.metaData.idMetaData != event);

    if (this.model.get('listMetaDataRequiredSelected'))
      this.model.get('listMetaDataRequiredSelected').setValue(this.model.get('listMetaDataRequiredSelected').value.filter(x => x != event));
  }

  selectRequiredMetaData(event: any) {
    let listIdMetaData = this.listIdMetaDataRequiredSelected.filter(x => x == event);
    if (listIdMetaData.length == 0) {
      let idMetaData = this.listMetaDataRequiredSelected.filter(x => x.metaData.idMetaData == event);
      idMetaData.forEach(x => {
        this.listIdMetaDataRequiredSelected.push(x.metaData.idMetaData)
      });
    }
    else
      this.listIdMetaDataRequiredSelected = this.listIdMetaDataRequiredSelected.filter(x => x != event);
  }
}