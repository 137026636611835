<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="white-body">
                <div class="title-header">
                    <div class="mat-typography">
                        <h2>
                            Lista Perfis por Relatórios
                        </h2>
                    </div>
                </div>
                <div class="row" formArrayName="profiles"
                    *ngIf="listReportProfile != null  && listReportProfile.length > 0">
                    <div class="col-12 col-md-6" *ngFor="let healthReports of profiles().controls; let empIndex=index">
                        <div [formGroupName]="empIndex" class="row">
                            <div class="col-md-6">
                                <span class="report-name primary">{{healthReports.value.reportName}}</span>
                                <mat-form-field class="invisible">
                                    <input matInput formControlName="idReport">
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field appearance="outline">
                                    <mat-label>Perfil</mat-label>
                                    <mat-select formControlName="idProfile" multiple>
                                        <mat-option *ngFor="let item of listProfile" [value]="item.idProfile">
                                            {{item.profileName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
                <app-empty-list *ngIf="!listReportProfile || listReportProfile.length == 0 && !isFirstLoading">
                </app-empty-list>
                <div *ngIf="isMaster">
                    <div class="title-header" *ngIf="isMaster">
                        <div class="mat-typography">
                            <h2>
                                Perfil exceção acesso
                            </h2>
                        </div>
                    </div>
                    <div class="row" formArrayName="profileExceptions"
                        *ngFor="let item of model.get('profileExceptions')['controls']; let i = index">
                        <div class="row" [formGroupName]="i">
                            <div class="col-4 col-sm-4 col-md-3">
                                <mat-form-field appearance="outline">
                                    <mat-label>Perfil</mat-label>
                                    <mat-select formControlName="idProfile">
                                        <mat-option *ngFor="let profile of listProfile" [value]="profile.idProfile"
                                            (onSelectionChange)="updateRequirements($event, i)">
                                            {{profile.profileName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-4 col-sm-4 col-md-3">
                                <mat-form-field appearance="outline">
                                    <mat-label>Unidades</mat-label>
                                    <mat-select multiple formControlName="listIdHealthUnit">
                                        <mat-option *ngFor="let healthUnit of listHealthUnitGroup"
                                            [value]="healthUnit.idHealthUnit"
                                            (onSelectionChange)="updateRequirements($event, i)">
                                            {{healthUnit.healthUnitName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-2 col-sm-2 col-md-3">
                                <button class="exception-button " color="primary" mat-mini-fab type="button"
                                    (click)="addNext()">
                                    <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
                                </button>
                                <button class="exception-button " color="primary" mat-mini-fab type="button"
                                    (click)="removeException(i)"
                                    *ngIf="model.get('profileExceptions')['controls'].length > 0">
                                    <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>