import { Injectable } from "@angular/core";
import { CommonService } from "../../common-service";
import { HttpClient } from "@angular/common/http";
import { SaveSadtAttendanceRequest } from "../../requests/orchestrator-sadt/save-sadt-attendance.request";
import { Observable, catchError } from "rxjs";
import { environment } from "src/environments/environment";
import { PostSadtAttendanceResponse } from "../../responses/orchestrator-sadt/post-sadt-attendance.response";

@Injectable({
    providedIn: 'root'
  })
  export class SadtAttendanceService extends CommonService {
  
    constructor(private httpClient: HttpClient) { super(); }
  
    public SaveSadtAttendance(request: SaveSadtAttendanceRequest): Observable<PostSadtAttendanceResponse> {
  
      let uri = `SadtAttendance`
  
      return this.httpClient.post<PostSadtAttendanceResponse>(environment.urlApiOrchestratorSADT + uri, request, this.addHeaderToken())
        .pipe(catchError(this.handleError));
    }
  }