<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum' [hasCachedModule]="true"
        [cachedModuleName]="'frontdesk'"></app-menu>
    
    <div class="container-body">
        <a class="back-link" routerLink="/classification/telephone-classification">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Classificação
        </a>
        <h1>Cadastrar Paciente</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="title-header">
                    <div class="row">
                        <div class="col-6 col-sm-6 col-md-6">
                            <h1>Dados Pessoais</h1>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Nome</mat-label>
                                <input matInput type="text" formControlName="patientName">
                                <mat-error *ngIf="model.get('patientName').invalid">Informe o nome do paciente</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Nome Social</mat-label>
                                <input matInput type="text" formControlName="socialName">
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Data de Nascimento</mat-label>
                                <input matInput type="text" (keyup)="onKeySearchAge($event)" formControlName="birthDate"
                                    [mask]="masks.date">
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Idade</mat-label>
                                <input matInput type="text" formControlName="patientAge">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Gênero</mat-label>
                                <mat-select formControlName="idGender">
                                    <mat-option *ngFor="let item of genderList" [value]="item.idGender">{{item.genderName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline" class="outline-spinner">
                                <mat-label>CPF</mat-label>
                                <input matInput type="text" formControlName="cpf" [mask]="masks.cpf"
                                    (keyup)="onKeySearchCPF($event)">
                                <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                    *ngIf="isLoadingCpf == true"></mat-spinner>
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline" class="outline-spinner">
                                <mat-label>CNS</mat-label>
                                <input matInput type="text" formControlName="cns" (keyup)="onKeySearchCNS($event)">
                                <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                    *ngIf="isLoadingCns == true"></mat-spinner>
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Telefone 1</mat-label>
                                <input matInput type="text" formControlName="phone1" [mask]="masks.phone">
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Telefone 2</mat-label>
                                <input matInput type="text" formControlName="phone2" [mask]="masks.phone">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="title-header">
                        <h1>Endereço</h1>
                    </div>
                    <div class="row">
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline" class="outline-spinner">
                                <mat-label>CEP</mat-label>
                                <input matInput type="text" formControlName="zipCode" (keyup)="onKeySearchCEP($event)">
                                <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                    *ngIf="isLoadingCep == true"></mat-spinner>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Logradouro</mat-label>
                                <input matInput type="text" formControlName="street">
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Número</mat-label>
                                <input matInput type="number" formControlName="houseNumber">
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Complemento</mat-label>
                                <input matInput type="text" formControlName="apartmentNumber">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Bairro</mat-label>
                                <input matInput type="text" formControlName="neighborhood">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Cidade</mat-label>
                                <input matInput type="text" formControlName="city">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Estado</mat-label>
                                <input matInput type="text" formControlName="state">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>País</mat-label>
                                <input matInput type="text" formControlName="country">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <a *ngIf="isLoading == false" mat-flat-button type="button"
                            routerLink="/classification/telephone-classification" color="accent" class=" btn-block">
                            Cancelar
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>
    
</div>