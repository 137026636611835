import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MedicineTypeService } from 'src/app/shared/services/API/pharmacy/medicine-type.service';
import { MedicineTypeStruct } from 'src/app/shared/services/structs/pharmacy/medicine-type.struct';
import { UtilService } from 'src/app/shared/services/util.service';
import { MedicineTemplateModalComponent } from './medicine-template-modal/medicine-template-modal.component';
import { MedicineTypeDeleteModalComponent } from './medicine-type-delete-modal/medicine-type-delete-modal.component';

@Component({
  selector: 'app-medicine-type-list',
  templateUrl: './medicine-type-list.component.html',
  styleUrls: ['./medicine-type-list.component.css']
})
export class MedicineTypeListComponent implements OnInit {

  constructor(private medicineTypeService: MedicineTypeService,
    private router: Router,
    private alertService: AlertService,
    private utilService: UtilService,
    public dialog: MatDialog
  ) { }
    
  public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.pharmacy;
  public menuFunctionalityEnum:MenuFunctionalityEnum = MenuFunctionalityEnum.pharmacy_medicine_type;
    
  public listMedicineType: MedicineTypeStruct[];
  public isLoading: boolean;
  public searchText: string;

  ngOnInit(): void {
    this.isLoading = true;
    this.search();
  }
  search(){
    this.isLoading = true;  
    if(this.searchText == "")
    this.searchText = null;
    
    this.medicineTypeService.listMedicineType(this.searchText).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listMedicineType = response.listMedicineType;  
    
      this.isLoading = false;    
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  private timeoutKeySearch: any = null;
  
  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }
  
  openModal(idMedicineType){
    const dialogRef = this.dialog.open(MedicineTypeDeleteModalComponent, {
      data: {
        idMedicineType: idMedicineType
      },
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.deleteMedicineType){
        this.search();
      }
    });
  }

  openModalTemplate(){
    const dialogRef = this.dialog.open(MedicineTemplateModalComponent);
    dialogRef.afterClosed().subscribe(result => {
      if(result.applyTemplate){
        this.search();
      }
    });
  }
}

