<div class="row display-contents">
    <form class="form" [formGroup]="model" (ngSubmit)="submit()">
        <div class="col-12 col-sm-12 col-md-12 padding-box">
            <div class="row border-box">
                <div class="title-header">
                    <h3>DADOS DO PROCEDIMENTO</h3>
                </div>
                <div class="col-12 organization-fields">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Nome do Procedimento</mat-label>
                                <input matInput type="text" formControlName="nameProcedure" maxlength="150">
                                <mat-error *ngIf="model.get('nameProcedure').invalid && model.get('nameProcedure').value.length <= 150">
                                    Informe o nome do Procedimento
                                </mat-error>
                                <mat-error *ngIf="model.get('nameProcedure').value.length > 150">
                                    Quantidade de caracteres máxima ultrapassada!
                                </mat-error>
                                <mat-hint align="end">{{ model.get('nameProcedure').value ?
                                    model.get('nameProcedure').value.length : 0 }}/150</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Orientações</mat-label>
                                <textarea matInput type="text" maxlength="150" formControlName="descriptionProcedure"
                                    oninput="this.value = this.value.replace(/[\n\r]/g, '');"></textarea>
                                <mat-error
                                    *ngIf="model.get('descriptionProcedure').invalid && !model.get('descriptionProcedure').errors?.maxlength">Preenchimento
                                    incorreto</mat-error>
                                <mat-error *ngIf="model.get('descriptionProcedure').errors?.maxlength">Tamanho máximo do
                                    campo excedido</mat-error>
                                <mat-hint align="end">{{ model.get('descriptionProcedure').value ?
                                    model.get('descriptionProcedure').value.length : 0 }}/150</mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Tabela de Procedimentos</mat-label>
                                <mat-select (selectionChange)="changeTerminologyOrigin($event.value)"
                                    [ngModel]="idTerminologyOrigin" [ngModelOptions]="{standalone: true}">
                                    <mat-option [value]="null">Nenhum</mat-option>
                                    <mat-option *ngFor="let item of listTerminologyOrigin"
                                        [value]="item.idTerminologyOrigin">{{item.nameTerminologyOrigin}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Procedimento</mat-label>
                                <mat-select formControlName="idTerminology"
                                    (selectionChange)="changeTerminology($event.value)">
                                    <div class="searchContainer">
                                        <input class="inputSearchSelect" type="text" [(ngModel)]="searchTextTerminology"
                                        [ngModelOptions]="{standalone: true}" (keyup)="onKey($event.target.value)"
                                        placeholder="Pesquisar" autocomplete="off" (keydown)="$event.stopPropagation()">
                                    </div>
                                    <app-loading-progress-bar *ngIf="isLoading"></app-loading-progress-bar>
                                    <mat-option [value]="null">Nenhum</mat-option>
                                    <mat-option *ngFor="let item of listTerminology; let i = index"
                                        [value]="item.idTerminology">
                                        {{item.terminologyCode}} - {{item.terminologyName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="padding-left: 20px;">
            <div class="col-3">
                <button mat-flat-button (click)="backToProcedureList()" color="accent" class="btn-block">
                    <span *ngIf="isLoading == false">Voltar aos procedimentos</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
            </div>
            <div class="col-3">
                <button mat-flat-button type="submit" color="primary" class="btn-block">
                    <span *ngIf="isLoading == false">Salvar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
            </div>
        </div>
    </form>
</div>