import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AllergyModel } from 'src/app/shared/services/models/medical-record/allergy.model';
import { MedicalProcedureStruct } from 'src/app/shared/services/structs/medical-record/medical-procedure.struct';
import { NursingConsultationStruct } from 'src/app/shared/services/structs/medical-record/nursing-consultation.struct';
import { CompleteMedicalCareStruct } from 'src/app/shared/services/structs/orchestrator-patient/complete-medical-care.struct';
import { MedicalProcedureModalComponent } from '../medical-procedure-modal/medical-procedure-modal.component';

@Component({
  selector: 'app-medic-nursing-consultation-accordion',
  templateUrl: './medic-nursing-consultation-accordion.component.html',
  styleUrls: ['./medic-nursing-consultation-accordion.component.css']
})
export class MedicNursingConsultationAccordionComponent implements OnInit {

  constructor(private dialog: MatDialog) { }
  @Input() nursingConsultation: NursingConsultationStruct;
  @Input() medicalCare: CompleteMedicalCareStruct[] = [];
  @Input() listAllergyEpisode: AllergyModel[];
  @Input() idEpisode: number;
  @Input() patientName: string;
  @Input() birthDate: string;

  ngOnInit(): void {
  }

  openProcedureModal(procedure: MedicalProcedureStruct) {
    const dialogRef = this.dialog.open(MedicalProcedureModalComponent, {
      data: {
        procedure: procedure,
        patientName: this.patientName,
        birthDate: this.birthDate,
        idMedicalProcedure: procedure.idMedicalProcedure,
        idEpisode: this.idEpisode,
      },
    });
  }
}