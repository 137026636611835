export class StorageRequest {
     public idMedicine: number;

     public idItem: number;

     public batch: string;

     public dateExpiration: Date;

     public idLocation: number;

     public amountTotal: number;

     public idMedicineTag: number;

     public width: number;

     public height: number;

     public idMedicinePresentationType: number;

     public numberDrops: number;
}