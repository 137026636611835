import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { DigitalPromptComponent } from './pages/digital-prompt/digital-prompt.component';
import { DigitalPromptConfigComponent } from './pages/digital-prompt-config/digital-prompt-config.component';
import { MedicalSpecialtyListComponent } from './pages/medical-specialty-list/medical-specialty-list.component';
import { MedicalSpecialtyRegisterComponent } from './pages/medical-specialty-register/medical-specialty-register.component';
import { ScheduleListComponent } from './pages/schedule-list/schedule-list.component';
import { ScheduleRegisterComponent } from './pages/schedule-register/schedule-register.component';
import { TelemedicineConfigComponent } from './pages/telemedicine-config/telemedicine-config.component';
import { SelfTriageButtonConfigComponent } from './pages/self-triage-button-config/self-triage-button-config.component';
import { SelfTriageConfigComponent } from './pages/self-triage-config/self-triage-config.component';
import { VirtualTriagePatientListComponent } from './pages/virtual-triage-patient-list/virtual-triage-patient-list.component';
import { VirtualTriageConfigRegisterComponent } from './pages/virtual-triage-config-register/virtual-triage-config-register.component';
import { VirtualTriageConfigListComponent } from './pages/virtual-triage-config-list/virtual-triage-config-list.component';
import { VirtualTriageAttendPatientComponent } from './pages/virtual-triage-attend-patient/virtual-triage-attend-patient.component';

const routes: Routes = [
  { path: 'digital-prompt-service', component: DigitalPromptComponent, canActivate: [AuthGuard] },
  { path: 'digital-prompt-service/patient-center/config', component: DigitalPromptConfigComponent, canActivate: [AuthGuard] },
  { path: 'digital-prompt-service/patient-center/medical-specialty', component: MedicalSpecialtyListComponent, canActivate: [AuthGuard] },
  { path: 'digital-prompt-service/patient-center/medical-specialty/register', component: MedicalSpecialtyRegisterComponent, canActivate: [AuthGuard] },
  { path: 'digital-prompt-service/patient-center/schedule/list', component: ScheduleListComponent, canActivate: [AuthGuard] },
  { path: 'digital-prompt-service/patient-center/schedule/register', component: ScheduleRegisterComponent, canActivate: [AuthGuard] },
  { path: 'digital-prompt-service/telemedicine/config', component: TelemedicineConfigComponent, canActivate: [AuthGuard] },
  { path: 'digital-prompt-service/self-triage/config', component: SelfTriageConfigComponent, canActivate: [AuthGuard] },
  { path: 'digital-prompt-service/self-triage/button-config', component: SelfTriageButtonConfigComponent, canActivate: [AuthGuard] },
  { path: 'digital-prompt-service/virtual-triage/virtual-triage-patient-list', component: VirtualTriagePatientListComponent, canActivate: [AuthGuard] },
  { path: 'digital-prompt-service/virtual-triage/virtual-triage-attend-patient', component: VirtualTriageAttendPatientComponent, canActivate: [AuthGuard] },
  { path: 'digital-prompt-service/virtual-triage/virtual-triage-config/register', component: VirtualTriageConfigRegisterComponent, canActivate: [AuthGuard] },
  { path: 'digital-prompt-service/virtual-triage/virtual-triage-config', component: VirtualTriageConfigListComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DigitalPromptRoutingModule { }
