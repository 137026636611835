import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ReadMedicalPrescriptionStruct } from 'src/app/shared/services/structs/orchestrator-patient/read-medical-prescription.struct';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdministrationTypeEnum, DilutionEnum, FrequencyEnum, PresentationEnum, TreatmentPeriodEnum, UsageEnum } from 'src/app/shared/enum/prescription.enum';
import { MedicRequiredFieldEnum } from 'src/app/shared/enum/medic-required-field.enum';
import { MedicineAdministrationTypeEnum } from 'src/app/shared/enum/medicine-administration-type.enum';
import { MedicineStruct } from 'src/app/shared/services/structs/pharmacy/medicine.struct';
import { AllergyModel } from 'src/app/shared/services/models/medical-record/allergy.model';
import { ConfirmAllergicPatientModalComponent } from '../../confirm-allergic-patient-modal/confirm-allergic-patient-modal.component';

@Component({
  selector: 'app-medicine-form',
  templateUrl: './medicine-form.component.html',
  styleUrls: ['./medicine-form.component.css']
})
export class MedicineFormComponent implements OnInit {

  constructor(public dialog: MatDialog,
    private alertService: AlertService,
    private formBuilder: FormBuilder
  ) { }

  @Input() blockAllergicPrescription: boolean;
  @Input() isFastTrack: boolean;
  @Input() readOnly: boolean;
  @Input() medicalPrescription: ReadMedicalPrescriptionStruct;
  @Input() listAllergy: AllergyModel[] = [];
  @Input() selectedMedicineList: MedicineStruct[] = [];
  @Input() medicineList: MedicineStruct[] = [];
  @Input() listSubFrequency: any[];
  @Input() frequencyList: any[] = [];
  @Input() presentationList: any[] = [];
  @Input() treatmentList: any[] = [];
  @Input() dilutionList: any[] = [];
  @Input() measurementUnitList: any[] = [];
  @Input() usageList: any[] = [];
  @Input() dilutionUnitList: any[] = [];
  @Input() administrationTypeList: any[] = [];
  @Input() lookupMedicalCareResponse: any;
  @Input() medicConfig: any;
  @Input() listMedicine: any;
  @Input() hasNewStandardPrescription: boolean = false;

  @Output() save = new EventEmitter<any>();
  @Output() cancel = new EventEmitter<any>();

  public administrationTypeListDisplay: any[] = [];

  public prescriptionModel: FormGroup;
  public selectedMedicine: MedicineStruct;

  public isLoading: boolean;
  public bypassPrescription: boolean = false;
  public undiluted: boolean = false;
  public isFrequency: boolean = false;

  public idAdministrationType: number;
  public idFrequency: number
  public idTreatmentPeriod: number;
  public idPresentation: number;
  public idDilution: number;

  public justification: string;

  public administrationTypeOthersEnum: AdministrationTypeEnum = AdministrationTypeEnum.outros;
  public presentationOthersEnum: PresentationEnum = PresentationEnum.outros;
  public dilutionOthersEnum: DilutionEnum = DilutionEnum.outros;
  public frequencyEnum: FrequencyEnum = FrequencyEnum.now;
  public ifNecessary: FrequencyEnum = FrequencyEnum.ifNecessary;
  public dilutionNoDilutionEnum: DilutionEnum = DilutionEnum.semDiluicao;

  public frequencyNow: any;

  ngOnInit(): void {
    this.isLoading = false;

    this.prescriptionModel = this.formBuilder.group({
      idMedicine: [{ value: null, disabled: this.readOnly }, Validators.required],
      quantity: [{ value: null, disabled: this.readOnly }, Validators.max(32767)],
      idAdministrationType: [{ value: null, disabled: this.readOnly }],
      idFrequency: [{ value: '', disabled: this.readOnly }, Validators.required],
      idPresentation: [{ value: null, disabled: this.readOnly }],
      idDilution: [{ value: null, disabled: this.readOnly }],
      idTreatmentPeriod: [{ value: '', disabled: this.readOnly }, Validators.required],
      presentation: [{ value: '', disabled: this.readOnly }],
      dilution: [{ value: '', disabled: this.readOnly }],
      administrationType: [{ value: '', disabled: this.readOnly }],
      observation: [{ value: '', disabled: this.readOnly }],
      idMeasurementUnit: [{ value: null, disabled: this.readOnly }],
      idSubFrequency: [{ value: '', disabled: this.readOnly }],
      idDilutionUnit: [{ value: '', disabled: this.readOnly }],
      dilutionQuantity: [{ value: '', disabled: this.readOnly }],
    });

    if (this.medicalPrescription) {
      this.prescriptionModel.get('idMedicine').disable();
      this.populateValues();
    }

    this.verifyRequiredFields();
  }

  populateValues() {
    this.selectedMedicine = this.medicineList.find(f => f.medicine.idMedicine == this.medicalPrescription.idMedicine);
    let naoDefinida = this.administrationTypeList.find(f => f.idAdministrationType == MedicineAdministrationTypeEnum.NaoDefinida);
    this.administrationTypeListDisplay = this.administrationTypeList.filter(f => this.selectedMedicine.listIdMedicineAdministrationType.includes(f.idAdministrationType));
    this.administrationTypeListDisplay.unshift(naoDefinida);

    this.prescriptionModel.get('idMedicine').setValue(this.medicalPrescription.idMedicine ? this.medicalPrescription.idMedicine.toString() : null);
    this.prescriptionModel.get('quantity').setValue(this.medicalPrescription.quantity);
    this.prescriptionModel.get('idAdministrationType').setValue(this.medicalPrescription.idAdministrationType ? this.medicalPrescription.idAdministrationType : null);
    this.prescriptionModel.get('idFrequency').setValue(this.medicalPrescription.idFrequency ? this.medicalPrescription.idFrequency.toString() : null);
    this.prescriptionModel.get('idPresentation').setValue(this.medicalPrescription.idPresentation ? this.medicalPrescription.idPresentation.toString() : null);
    this.prescriptionModel.get('idTreatmentPeriod').setValue(this.medicalPrescription.idTreatmentPeriod ? this.medicalPrescription.idTreatmentPeriod.toString() : null);
    this.prescriptionModel.get('observation').setValue(this.medicalPrescription.observation);
    this.prescriptionModel.get('idDilution').setValue(this.medicalPrescription.idDilution ? this.medicalPrescription.idDilution.toString() : null);
    this.prescriptionModel.get('dilution').setValue(this.medicalPrescription.dilution);
    this.prescriptionModel.get('presentation').setValue(this.medicalPrescription.presentation);
    this.prescriptionModel.get('administrationType').setValue(this.medicalPrescription.administrationType);
    this.prescriptionModel.get('idMeasurementUnit').setValue(this.medicalPrescription.idMeasurementUnit);

    if (this.medicalPrescription.idSubFrequency != null) {
      this.isFrequency = true;
      this.prescriptionModel.get('idSubFrequency').setValue(this.medicalPrescription.idSubFrequency.toString());
    }

    if (this.medicalPrescription.idDilutionUnit != null) {
      this.undiluted = true;
      this.prescriptionModel.get('idDilutionUnit').setValue(this.medicalPrescription.idDilutionUnit);
      this.prescriptionModel.get('dilutionQuantity').setValue(this.medicalPrescription.dilutionQuantity);
    }

    if (this.readOnly) {
      this.idAdministrationType = this.medicalPrescription.idAdministrationType;
      this.idFrequency = this.medicalPrescription.idFrequency;
      this.idTreatmentPeriod = this.medicalPrescription.idTreatmentPeriod;
    }
  }

  clickCancel() {
    this.cancel.emit();
  }

  onChangeMedicine(event: any) {
    this.selectedMedicine = this.medicineList.find(f => f.medicine.idMedicine == event.value);
    let listId: number[] = this.selectedMedicine.listIdMedicineAdministrationType;
    let naoDefinida = this.administrationTypeList.find(f => f.idAdministrationType == MedicineAdministrationTypeEnum.NaoDefinida);
    this.administrationTypeListDisplay = this.administrationTypeList.filter(f => listId.includes(f.idAdministrationType));

    if (this.administrationTypeListDisplay && this.administrationTypeListDisplay.length != 0) {
      if (this.administrationTypeListDisplay.length == 1) {

        this.administrationTypeListDisplay.unshift(naoDefinida);
        this.prescriptionModel.get('idAdministrationType').setValue(this.administrationTypeListDisplay[1].idAdministrationType);
        this.prescriptionModel.get('idAdministrationType').enable();
      }
      else {
        this.administrationTypeListDisplay.unshift(naoDefinida);
        this.prescriptionModel.get('idAdministrationType').setValue('');
        this.prescriptionModel.get('idAdministrationType').enable();
      }
    }
    else {
      this.administrationTypeListDisplay = [...this.administrationTypeList];
      this.prescriptionModel.get('idAdministrationType').setValue(naoDefinida.idAdministrationType);
      this.prescriptionModel.get('idAdministrationType').disable();
      this.alertService.show('Aviso', 'Não há nenhuma via cadastrada para este medicamento', AlertType.warning);
    }

    if (this.selectedMedicine.medicine.idDilution)
      this.prescriptionModel.get('idDilution').setValue(this.selectedMedicine.medicine.idDilution.toString());
    else
      this.prescriptionModel.get('idDilution').setValue(null);

    if (this.selectedMedicine.medicine.idMeasurementUnit) {
      this.prescriptionModel.get('idMeasurementUnit').setValue(this.selectedMedicine.medicine.idMeasurementUnit);
      this.prescriptionModel.get('idMeasurementUnit').disable();
    }
    else {
      this.prescriptionModel.get('idMeasurementUnit').setValue(null);
      this.prescriptionModel.get('idMeasurementUnit').enable();
    }

    if (this.selectedMedicine.medicine.idMedicinePresentationType) {
      this.prescriptionModel.get('idPresentation').setValue(this.selectedMedicine.medicine.idMedicinePresentationType.toString());
      this.prescriptionModel.get('idPresentation').disable();
    }
    else {
      this.prescriptionModel.get('idPresentation').setValue(null);
      this.prescriptionModel.get('idPresentation').enable();
    }

    if (this.selectedMedicine.listAllergy && this.selectedMedicine.listAllergy.length > 0) {
      let allergyDetected: boolean = false;

      allergyDetected = this.selectedMedicine.listAllergy.some((it) => this.listAllergy.find(it2 => it2.idAllergy == it));
      if (allergyDetected) {
        const dialogRef = this.dialog.open(ConfirmAllergicPatientModalComponent, {
          data: {blockAllergicPrescription: this.blockAllergicPrescription},
        });
        dialogRef.afterClosed().subscribe({
          next: (result) => {
            if (result && result.cancel) {
              this.prescriptionModel.get('idMedicine').setValue('');
              this.prescriptionModel.get('idAdministrationType').setValue('');
              this.prescriptionModel.get('idAdministrationType').enable();
              this.prescriptionModel.get('idDilution').setValue('');
              this.prescriptionModel.get('idMeasurementUnit').setValue(null);
              this.prescriptionModel.get('idPresentation').setValue(null);
            }
            else if (result && !result.cancel && result.justification)
              this.justification = result.justification;
          }
        });
      }
    }
  }

  onChangePresentation(event: number) {
    this.idPresentation = event;
    if (this.idPresentation == this.presentationOthersEnum.valueOf()) {
      this.prescriptionModel.get('presentation').setValidators(Validators.required);
    }
    else {
      this.prescriptionModel.get('presentation').setValue(null);
      this.prescriptionModel.get('presentation').setValidators(Validators.nullValidator);
    }

    this.prescriptionModel.get('presentation').updateValueAndValidity();
  }

  onChangeAdministrationType(event: number) {
    this.idAdministrationType = event;
    if (this.idAdministrationType == this.administrationTypeOthersEnum.valueOf()) {
      this.prescriptionModel.get('administrationType').setValue(this.selectedMedicine.medicine.medicineAdministrationOther)
      this.prescriptionModel.get('administrationType').setValidators(Validators.required);
    }
    else {
      this.prescriptionModel.get('administrationType').setValue(null);
      this.prescriptionModel.get('administrationType').setValidators(Validators.nullValidator);
    }

    this.prescriptionModel.get('administrationType').updateValueAndValidity();
  }

  onChangeDilution(event: number) {
    this.idDilution = event;

    if (this.idDilution == this.dilutionNoDilutionEnum.valueOf() || this.idDilution == null)
      this.undiluted = false;
    else
      this.undiluted = true;

    if (this.idDilution == this.dilutionOthersEnum.valueOf())
      this.prescriptionModel.get('dilution').setValidators(Validators.required);
    else {
      this.prescriptionModel.get('dilution').setValue(null);
      this.prescriptionModel.get('dilution').setValidators(Validators.nullValidator);
    }

    this.prescriptionModel.get('dilution').updateValueAndValidity();
  }

  onKey(value) {
    this.selectedMedicineList = this.search(value);
  }

  search(value: string) {
    let filter = value.toLowerCase();
    return this.medicineList.filter(option => option.medicine.medicineDescription.toLowerCase().includes(filter));
  }

  onSubmit() {
    if (this.prescriptionModel.invalid) {
      this.alertService.show('Erro', "Preencha todos os campos obrigatórios!", AlertType.error);
      return;
    }

    if (this.hasNewStandardPrescription &&
      this.prescriptionModel.get('idFrequency') && this.prescriptionModel.get('idTreatmentPeriod') &&
      (this.prescriptionModel.get('idFrequency').value == this.frequencyEnum &&
        this.prescriptionModel.get('idTreatmentPeriod').value != TreatmentPeriodEnum.UmDia)
    ) {
      this.alertService.show('Erro', "Para essa frequência, o período de tratamento tem que ser apenas de um dia.", AlertType.error);
      return;
    }

    this.medicalPrescription = new ReadMedicalPrescriptionStruct();
    this.medicalPrescription.idMedicine = this.prescriptionModel.get('idMedicine').value;
    this.medicalPrescription.medicineName = this.medicineList.find(c => c.medicine.idMedicine == this.medicalPrescription.idMedicine).medicine.medicineDescription;
    this.medicalPrescription.idUsage = UsageEnum.interno;
    this.medicalPrescription.usage = this.usageList.find(c => c.idUsage == this.medicalPrescription.idUsage).usageName;
    this.medicalPrescription.idAdministrationType = this.prescriptionModel.get('idAdministrationType').value ? this.prescriptionModel.get('idAdministrationType').value : this.administrationTypeList.find(x => x.idAdministrationType == MedicineAdministrationTypeEnum.NaoDefinida).idAdministrationType;
    this.medicalPrescription.administrationType = this.prescriptionModel.get('administrationType').value;
    this.medicalPrescription.administrationTypeName = this.medicalPrescription.idAdministrationType ? this.administrationTypeList.find(x => x.idAdministrationType == this.medicalPrescription.idAdministrationType).administrationTypeName : null;
    this.medicalPrescription.quantity = this.prescriptionModel.get('quantity').value ? this.prescriptionModel.get('quantity').value : null;
    this.medicalPrescription.idPresentation = this.prescriptionModel.get('idPresentation').value ? this.prescriptionModel.get('idPresentation').value : null;
    this.medicalPrescription.presentationName = this.medicalPrescription.idPresentation ? this.presentationList.find(c => c.idPresentation == this.medicalPrescription.idPresentation).presentationName : null;
    this.medicalPrescription.presentation = this.prescriptionModel.get('presentation').value;
    this.medicalPrescription.idFrequency = this.prescriptionModel.get('idFrequency').value ? this.prescriptionModel.get('idFrequency').value : null;
    this.medicalPrescription.frequencyName = this.medicalPrescription.idFrequency ? this.frequencyList.find(c => c.idFrequency == this.medicalPrescription.idFrequency).frequencyName : null;
    this.medicalPrescription.idTreatmentPeriod = this.prescriptionModel.get('idTreatmentPeriod').value ? this.prescriptionModel.get('idTreatmentPeriod').value : null;
    this.medicalPrescription.treatmentPeriodName = this.medicalPrescription.idTreatmentPeriod ? this.treatmentList.find(c => c.idTreatmentPeriod == this.medicalPrescription.idTreatmentPeriod).treatmentPeriodName : null;
    this.medicalPrescription.idDilution = this.prescriptionModel.get('idDilution').value ? this.prescriptionModel.get('idDilution').value : null;
    this.medicalPrescription.dilutionName = this.dilutionList.find(c => c.idDilution == this.medicalPrescription.idDilution) ? this.dilutionList.find(c => c.idDilution == this.medicalPrescription.idDilution).dilutionName : null;
    this.medicalPrescription.dilution = this.prescriptionModel.get('dilution').value;
    this.medicalPrescription.idMeasurementUnit = this.prescriptionModel.get('idMeasurementUnit').value;
    this.medicalPrescription.observation = this.prescriptionModel.get('observation').value;
    this.medicalPrescription.idSubFrequency = this.prescriptionModel.get('idSubFrequency').value ? parseInt(this.prescriptionModel.get('idSubFrequency').value) : null;
    this.medicalPrescription.idDilutionUnit = this.prescriptionModel.get('idDilutionUnit').value ? this.prescriptionModel.get('idDilutionUnit').value : null;
    this.medicalPrescription.dilutionQuantity = this.prescriptionModel.get('dilutionQuantity').value;

    if (this.medicalPrescription.idSubFrequency != null)
      this.medicalPrescription.subFrequencyName = this.lookupMedicalCareResponse.listSubFrequency.find(x => x.idSubFrequency == this.medicalPrescription.idSubFrequency).subFrequencyName;

    if (this.medicalPrescription.idDilutionUnit != null)
      this.medicalPrescription.dilutionUnitName = this.lookupMedicalCareResponse.listDilutionUnit.find(x => x.idDilutionUnit == this.medicalPrescription.idDilutionUnit).dilutionUnitName;

    if (this.justification)
      this.medicalPrescription.justification = this.justification;

    this.save.emit({ medicalPrescription: this.medicalPrescription, bypassPrescription: this.bypassPrescription });
  }

  verifyRequiredFields() {
    if (this.medicConfig) {
      this.medicConfig.listIdRequiredField.forEach(x => {

        if (x == MedicRequiredFieldEnum.prescriptionQuantity) {
          this.prescriptionModel.get('quantity').setValidators([Validators.required]);
          this.prescriptionModel.get('quantity').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.prescriptionAdministrationType) {
          this.prescriptionModel.get('idAdministrationType').setValidators([Validators.required]);
          this.prescriptionModel.get('idAdministrationType').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.prescriptionDilution) {
          this.prescriptionModel.get('idDilution').setValidators([Validators.required]);
          this.prescriptionModel.get('idDilution').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.prescriptionPresentation) {
          this.prescriptionModel.get('idPresentation').setValidators([Validators.required]);
          this.prescriptionModel.get('idPresentation').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.prescriptionObservation) {
          this.prescriptionModel.get('observation').setValidators([Validators.required]);
          this.prescriptionModel.get('observation').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.prescriptionMeasurementUnit) {
          this.prescriptionModel.get('idMeasurementUnit').setValidators([Validators.required]);
          this.prescriptionModel.get('idMeasurementUnit').updateValueAndValidity();
        }
      });
    }
  }

  toggleBypassPrescription(event: any) {
    if (event.checked) {
      this.bypassPrescription = true;
      this.selectedMedicineList = this.listMedicine.listMedicineStruct;
    }
    else {
      this.bypassPrescription = false;
      this.selectedMedicineList = this.listMedicine.listMedicineStruct.filter(x => x.medicine.fastTrack == true);
      let idMedicine: number = this.prescriptionModel.get('idMedicine').value;
      let medicine: MedicineStruct = this.listMedicine.listMedicineStruct.find(x => x.medicine.idMedicine == idMedicine);
      if (!this.selectedMedicineList.includes(medicine))
        this.prescriptionModel.get('idMedicine').setValue(null);
    }
  }

  onChangeFrequency(event: number) {
    if (event == this.ifNecessary.valueOf())
      this.isFrequency = true;
    else {
      this.isFrequency = false;
      this.prescriptionModel.get('idSubFrequency').setValue(null);
    }

    if (this.hasNewStandardPrescription) {
      if (event == this.frequencyEnum) {
        this.prescriptionModel.get('idTreatmentPeriod').setValue(TreatmentPeriodEnum.UmDia.toString());
        this.prescriptionModel.get('idTreatmentPeriod').disable();
      }
      else
        this.prescriptionModel.get('idTreatmentPeriod').enable();

      this.prescriptionModel.get('idTreatmentPeriod').updateValueAndValidity();
    }
  }
}