import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { PostPutDataSourceRequest } from '../../requests/data-intelligence/post-put-data-source.request';
import { GetDataSourceResponse } from '../../responses/data-intelligence/get-data-source.response';
import { ListDataSourceResponse } from '../../responses/data-intelligence/list-data-source.response';

@Injectable({
  providedIn: 'root'
})
export class DataSourceService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public get(idDataSource: number): Observable<GetDataSourceResponse> {
    
    let uri = `DataSource/idDataSource/${idDataSource}`;

    return this.httpClient.get<GetDataSourceResponse>(environment.urlApiDataIntelligence + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public getAll(searchText: string, body: number[]): Observable<ListDataSourceResponse> {
    
    let uri = `DataSource/getAll`;
    if(searchText != null)
      uri = uri+ `?searchText=${searchText}`;

    return this.httpClient.post<ListDataSourceResponse>(environment.urlApiDataIntelligence + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public create(body: PostPutDataSourceRequest): Observable<ReturnStruct> {
    
    let uri = `DataSource`;

    return this.httpClient.post<ReturnStruct>(environment.urlApiDataIntelligence + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public update(body: PostPutDataSourceRequest, idDataSource: number): Observable<ReturnStruct> {
    
    let uri = `DataSource/idDataSource/${idDataSource}`;

    return this.httpClient.put<ReturnStruct>(environment.urlApiDataIntelligence + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public delete(idDataSource: number): Observable<ReturnStruct> {
    
    let uri = `DataSource/idDataSource/${idDataSource}`;

    return this.httpClient.delete<ReturnStruct>(environment.urlApiDataIntelligence + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}