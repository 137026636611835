import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { EligibilityRulesAgeEnum } from 'src/app/shared/enum/eligibility-rules-age.enum';
import { VariablesTypeFlowEnum } from 'src/app/shared/enum/variables-flow.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';

@Component({
  selector: 'app-eligibility-rules',
  templateUrl: './eligibility-rules.component.html',
  styleUrls: ['./eligibility-rules.component.css']
})
export class EligibilityRulesComponent implements OnInit {

  @Input() formGroup: FormGroup;
  @Input() formArrayName: string;
  @Input() listLogicalExpression: any;
  @Input() listVariable: any;
  @Input() listConjunction: any;
  @Input() arrayVariables: Array<any>

  public selectVariable: VariablesTypeFlowEnum = VariablesTypeFlowEnum.select;
  public ageVariable: VariablesTypeFlowEnum = VariablesTypeFlowEnum.age;
  public numberVariable: VariablesTypeFlowEnum = VariablesTypeFlowEnum.number;
  public stringVariable: VariablesTypeFlowEnum = VariablesTypeFlowEnum.string;
  public dateVariable: VariablesTypeFlowEnum = VariablesTypeFlowEnum.date;
  public masks: Masks;
  public year: EligibilityRulesAgeEnum = EligibilityRulesAgeEnum.Anos;
  public month: EligibilityRulesAgeEnum = EligibilityRulesAgeEnum.Meses;
  public day: EligibilityRulesAgeEnum = EligibilityRulesAgeEnum.Dias

  constructor(
    private formBuilder: FormBuilder,
    private maskService: MaskService,
    private alertService: AlertService,
  ) { }

  ngOnInit(): void {

    this.masks = this.maskService.getMasks();
  }

  changeVariableType(event: any, i: number) {
    let item = this.listVariable.findIndex(c => c.idVariable == event.value);
    this.arrayVariables[i] = { type: this.listVariable[item].idType, index: item };
  }

  createInput() {
    return this.formBuilder.group({
      idConjunction: [''],
      idVariable: [''],
      idLogicalExpression: [''],
      value: [''],
    });
  }

  addNext() {
    this.arrayVariables.push(null);
    (this.formGroup.controls[this.formArrayName] as FormArray).push(this.createInput())
  }

  removeExpression(index: number) {
    this.arrayVariables.splice(index, 1);
    (this.formGroup.controls[this.formArrayName] as FormArray).removeAt(index)
  }

  validaAge(value: string) {
    if (!value) {
      return;
    }
    if (!value.includes("a") || !value.includes("m") || !value.includes("d")) {
      this.alertService.show('Erro!', "Preencha o campo corretamente, informando a idade no formato: 000a00m00d (anos, meses e dias).", AlertType.warning);
      return false;
    }
    let splitYears = value.split("a");
    let splitMonth = splitYears[1].split("m");
    let days: string = splitMonth[1].replace("d", "");
    let month: string = splitMonth[0];
    let years: string = splitYears[0];
    if (parseInt(days) > 29) {
      this.alertService.show('Erro', "O valor máximo de dias do campo idade é 29, após deve ser adicionado no mês!", AlertType.error);
      return false;
    }
    if (parseInt(month) > 11) {
      this.alertService.show('Erro', "O valor máximo de meses do campo idade é 11, após deve ser adicionado no ano!", AlertType.error);
      return false;
    }
    return true;
  }
}
