<div [ngClass]="{'container-body-secundary-header': idEpisode !== null, 'container-body': idEpisode === null}">
    <a class="back-link" routerLink="/frontdesk/patient">
        <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Recepção
    </a>
    <h1>Cadastrar Paciente</h1>
    <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
    <div class="white-body">
        <form class="form" [formGroup]="model" #form>
            <div class="title-header">
                <div class="row">
                    <div class="col-6 col-sm-6 col-md-6">
                        <h1>Dados Pessoais</h1>
                    </div>
                    <div class="col-6 col-sm-6 col-md-6">
                        <mat-slide-toggle class="indigent-checkbox" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="this.isUnidentifiedPatient" (change)="changeIndigent($event)">Paciente não
                            identificado</mat-slide-toggle>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-3" *ngIf="this.isUnidentifiedPatient">
                    <mat-form-field appearance="outline">
                        <mat-label>Prioridade</mat-label>
                        <mat-select formControlName="listIdPriority" multiple #prioritySelect
                            (selectionChange)="validatePriorities($event)">
                            <mat-option *ngFor="let item of listOfPriorities" [value]="item.idPriority"
                                [disabled]="disallowedIdPriorities.includes(item.idPriority)">{{item.priorityName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-6 col-lg-6" *ngIf="this.isUnidentifiedPatient">
                    <mat-form-field appearance="outline">
                        <mat-label>Descrição do paciente não identificado</mat-label>
                        <textarea matInput type="text" formControlName="unidentifiedPatientDescription"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="!this.isUnidentifiedPatient">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome</mat-label>
                            <input matInput type="text" [readonly]="this.isUnidentifiedPatient"
                                formControlName="patientName">
                            <mat-error *ngIf="model.get('patientName').invalid">Informe o nome do paciente</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome Social</mat-label>
                            <input matInput type="text" [readonly]="this.isUnidentifiedPatient"
                                formControlName="socialName">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Data de Nascimento</mat-label>
                            <input matInput type="text" (keyup)="onKeySearchAge($event)"
                                [readonly]="this.isUnidentifiedPatient" formControlName="birthDate" [mask]="masks.date">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <label>Idade:</label>
                        <p>{{patientAge}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Gênero</mat-label>
                            <mat-select [disabled]="this.isUnidentifiedPatient" formControlName="idGender">
                                <mat-option *ngFor="let item of genderList"
                                    value="{{item.idGender}}">{{item.genderName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline" class="outline-spinner">
                            <mat-label>CPF</mat-label>
                            <input matInput type="text" [readonly]="this.isUnidentifiedPatient" formControlName="cpf"
                                [mask]="masks.cpf" (keyup)="onKeySearchCPF($event)">
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoadingCpf == true"></mat-spinner>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline" class="outline-spinner">
                            <mat-label>CNS</mat-label>
                            <input matInput type="text" [readonly]="this.isUnidentifiedPatient" formControlName="cns"
                                (keyup)="onKeySearchCNS($event)" [mask]="masks.cns">
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoadingCns == true"></mat-spinner>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Telefone 1</mat-label>
                            <input matInput type="text" [readonly]="this.isUnidentifiedPatient" formControlName="phone1"
                                [mask]="masks.phone">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Telefone 2</mat-label>
                            <input matInput type="text" [readonly]="this.isUnidentifiedPatient" formControlName="phone2"
                                [mask]="masks.phone">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Raça</mat-label>
                            <mat-select [disabled]="this.isUnidentifiedPatient" formControlName="idRace">
                                <mat-option *ngFor="let item of raceList" value="{{item.idRace}}">{{item.raceName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 col-sm-3 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Escolaridade</mat-label>
                            <mat-select [disabled]="this.isUnidentifiedPatient" formControlName="idEducation">
                                <mat-option *ngFor="let item of educationList"
                                    value="{{item.idEducation}}">{{item.educationName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>RG</mat-label>
                            <input matInput type="text" [readonly]="this.isUnidentifiedPatient" formControlName="rg">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Órgão Expeditor</mat-label>
                            <input matInput type="text" [readonly]="this.isUnidentifiedPatient"
                                formControlName="expeditionIssuer">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Data de Expedição</mat-label>
                            <input matInput type="text" [readonly]="this.isUnidentifiedPatient"
                                formControlName="expeditionDate" [mask]="masks.date">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Estado Civil</mat-label>
                            <mat-select [disabled]="this.isUnidentifiedPatient" formControlName="idMaritalStatus">
                                <mat-option *ngFor="let item of maritalStatusList"
                                    value="{{item.idMaritalStatus}}">{{item.maritalStatusName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome da Mãe</mat-label>
                            <input matInput type="text" [readonly]="this.isUnidentifiedPatient"
                                formControlName="motherName">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome do Pai</mat-label>
                            <input matInput type="text" [readonly]="this.isUnidentifiedPatient"
                                formControlName="fatherName">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Profissão</mat-label>
                            <input matInput type="text" [readonly]="this.isUnidentifiedPatient"
                                formControlName="profession">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Prioridade</mat-label>
                            <mat-select formControlName="listIdPriority" multiple #prioritySelect
                                (selectionChange)="validatePriorities($event)">
                                <mat-option *ngFor="let item of listOfPriorities" [value]="item.idPriority"
                                    [disabled]="disallowedIdPriorities.includes(item.idPriority)">{{item.priorityName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Nacionalidade</mat-label>
                            <input matInput type="text" [readonly]="this.isUnidentifiedPatient"
                                formControlName="birthCountry">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Estado de Origem</mat-label>
                            <input matInput type="text" [readonly]="this.isUnidentifiedPatient"
                                formControlName="birthState">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Cidade de Origem</mat-label>
                            <input matInput type="text" [readonly]="this.isUnidentifiedPatient"
                                formControlName="birthCity">
                        </mat-form-field>
                    </div>
                </div>
                <div class="title-header">
                    <h1>Endereço</h1>
                </div>
                <div class="row">
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline" class="outline-spinner">
                            <mat-label>CEP</mat-label>
                            <input matInput type="text" [readonly]="this.isUnidentifiedPatient"
                                formControlName="zipCode" (keyup)="onKeySearchCEP($event)"
                                [value]="formatCEP(model.get('zipCode').value)" maxlength="9">
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoadingCep == true"></mat-spinner>
                            <mat-error *ngIf="model.get('zipCode').hasError('invalidCep')">CEP inválido</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Logradouro</mat-label>
                            <input matInput type="text" [readonly]="this.isUnidentifiedPatient"
                                formControlName="street">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Número</mat-label>
                            <input matInput type="number" [readonly]="this.isUnidentifiedPatient"
                                formControlName="houseNumber">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Complemento</mat-label>
                            <input matInput type="text" [readonly]="this.isUnidentifiedPatient"
                                formControlName="apartmentNumber">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Bairro</mat-label>
                            <input matInput type="text" [readonly]="this.isUnidentifiedPatient"
                                formControlName="neighborhood">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Cidade</mat-label>
                            <input matInput type="text"
                                [readonly]="(this.isUnidentifiedPatient || this.cepIsValid && this.cityIsPopulated)"
                                formControlName="city">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Estado</mat-label>
                            <input matInput type="text"
                                [readonly]="(this.isUnidentifiedPatient || this.cepIsValid && this.stateIsPopulated)"
                                formControlName="state">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>País</mat-label>
                            <input matInput type="text"
                                [readonly]="(this.isUnidentifiedPatient || this.cepIsValid && this.countryIsPopulated)"
                                formControlName="country">
                        </mat-form-field>
                    </div>
                </div>
                <div class="title-header">
                    <h1>Plano de Saúde</h1>
                </div>
                <div class="row">
                    <app-select-patient-healthcare-agreement
                        [listPatientHealthcareAgreementDetails]="this.listPatientHealthcareAgreementDetails" [scheduledPatientHealthcareAgreement]="this.scheduledPatientHealthcareAgreement">
                    </app-select-patient-healthcare-agreement>
                </div>
                <div class="title-header">
                    <h1>Procedência</h1>
                </div>
                <div class="row">
                    <div class="col-6 col-sm-3 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Origem</mat-label>
                            <mat-select [disabled]="this.isUnidentifiedPatient" formControlName="idOrigin">
                                <mat-option *ngFor="let item of originList" [value]="item.idOrigin">{{item.originName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Tipo de chegada</mat-label>
                            <mat-select [disabled]="this.isUnidentifiedPatient" formControlName="idArrivalType">
                                <mat-option *ngFor="let item of arrivalTypeList"
                                    [value]="item.idArrivalType">{{item.arrivalTypeName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Necessidades do paciente</mat-label>
                            <mat-select formControlName="listIdPatientNecessity" [disabled]="this.isUnidentifiedPatient"
                                multiple>
                                <ng-container *ngFor="let item of listPatientNecessity">
                                    <mat-option value="{{item.idPatientNecessity}}">{{item.necessityName}}</mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="title-header">
                    <h1>Acompanhante</h1>
                </div>
                <div class="row">
                    <div class="col-6 col-sm-3 col-md-3">
                        <mat-form-field appearance="outline" class="outline-spinner">
                            <mat-label>Nome</mat-label>
                            <input matInput type="text" [readonly]="this.isUnidentifiedPatient"
                                formControlName="companionName">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Telefone</mat-label>
                            <input matInput type="text" [readonly]="this.isUnidentifiedPatient"
                                formControlName="companionPhone" [mask]="masks.phone">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-3">
                        <mat-form-field appearance="outline" class="outline-spinner">
                            <mat-label>CPF</mat-label>
                            <input matInput type="text" [readonly]="this.isUnidentifiedPatient"
                                formControlName="companionCpf" [mask]="masks.cpf">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Parentesco</mat-label>
                            <input matInput type="text" [readonly]="this.isUnidentifiedPatient"
                                formControlName="companionParentage">
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="this.listMetaData.length > 0">
                    <div class="title-header">
                        <h1>Dados Adicionais</h1>
                    </div>
                    <div class="row">
                        <div *ngFor="let item of listMetaData" class="col-12 col-sm-6 col-md-4">
                            <mat-form-field *ngIf="item.metaData.idMetaDataType == metaDataTypeEnumResult.Texto"
                                appearance="outline">
                                <mat-label>{{item.metaData.metaDataName}}</mat-label>
                                <input matInput [readonly]="this.isUnidentifiedPatient"
                                    formControlName="{{item.metaData.idMetaData}}" type="text">
                            </mat-form-field>
                            <mat-form-field *ngIf="item.metaData.idMetaDataType == metaDataTypeEnumResult.Numero"
                                appearance="outline">
                                <mat-label>{{item.metaData.metaDataName}}</mat-label>
                                <input matInput [readonly]="this.isUnidentifiedPatient"
                                    formControlName="{{item.metaData.idMetaData}}" type="number">
                            </mat-form-field>
                            <mat-form-field *ngIf="item.metaData.idMetaDataType == metaDataTypeEnumResult.Data"
                                appearance="outline">
                                <mat-label>{{item.metaData.metaDataName}}</mat-label>
                                <input matInput inputmode="text" type="text" [mask]="masks.date"
                                    placeholder="dd/mm/aaaa" [readonly]="this.isUnidentifiedPatient"
                                    [formControlName]="item.metaData.idMetaData"
                                    (change)="verifyDate(item.metaData.idMetaData)">
                                <mat-error
                                    *ngIf="model.get(item.metaData.idMetaData.toString()).hasError('invalidDate')">Data
                                    inválida</mat-error>
                            </mat-form-field>
                            <mat-form-field *ngIf="item.metaData.idMetaDataType == metaDataTypeEnumResult.Select"
                                appearance="outline">
                                <mat-label>{{item.metaData.metaDataName}}</mat-label>
                                <mat-select [disabled]="this.isUnidentifiedPatient"
                                    formControlName="{{item.metaData.idMetaData}}">
                                    <mat-option *ngFor="let itemOption of item.listMetaDataOption"
                                        value="{{itemOption.metaDataOptionName}}">{{itemOption.metaDataOptionName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Observação</mat-label>
                            <textarea matInput type="text" formControlName="observation"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-4 col-lg-3">
                    <button mat-flat-button type="button" (click)="submit(true)"
                        color="{{requireSignature ? 'primary' : 'accent'}}" class="btn-block">
                        <span *ngIf="!isLoading">Salvar com assinatura e Imprimir</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn"
                            *ngIf="isLoading"></mat-spinner>
                    </button>
                </div>
                <div *ngIf="!requireSignature" type="button" class="col-12 col-md-4 col-lg-3">
                    <button mat-flat-button (click)="submit(false)"
                        color="{{requireSignature ? 'accent' : 'primary'}}" class="btn-block">
                        <span *ngIf="!isLoading">Salvar sem assinatura e Imprimir</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn"
                            *ngIf="isLoading"></mat-spinner>
                    </button>
                </div>
                <div class="col-md-2">
                    <a *ngIf="isLoading == false" mat-flat-button type="button" (click)="LeaveRoom()" color="accent"
                        class=" btn-block">
                        Cancelar
                    </a>
                </div>
            </div>
        </form>
    </div>
</div>

<div *ngIf="showTissGuideButtonValidation">
    <app-tiss-guide-management-floating-window 
        [idEpisode]="idEpisode" [isAttendance]="true"
        [idRoom]="this.idRoom" [idSector]="this.idSector"
        [patientSocialName]="this.patientSocialName" [patientName]="this.patientName">
    </app-tiss-guide-management-floating-window>
</div>