import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Masks } from 'src/app/shared/services/mask.service';
import { Day } from 'src/app/shared/services/models/schedule/day.model';
import { Frequency } from 'src/app/shared/services/models/schedule/frequency.model';
import { TimeSpanStruct } from 'src/app/shared/structs/time-span.struct';
import { TimeStruct } from 'src/app/shared/structs/time.struct';

@Component({
  selector: 'app-blocked-days-modal',
  templateUrl: './blocked-days-modal.component.html',
  styleUrls: ['./blocked-days-modal.component.css']
})
export class BlockedDaysModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<BlockedDaysModalComponent>,
    private alertService: AlertService,
    private formBuilder: FormBuilder
  ) { }

  public isLoading: boolean;
  public model: FormGroup;
  public isUpdate: boolean = false;
  public listDay: Day[] = [];
  public listFrequency: Frequency[];
  public masks: Masks;

  ngOnInit(): void {
    if (this.data.isUpdate)
      this.isUpdate = true;

    this.masks = this.data.masks;

    this.listDay = this.data.listDay;
    this.listFrequency = this.data.listFrequency;

    this.model = this.formBuilder.group({
      idDay: [null, [Validators.required]],
      dayName: [''],
      motive: [''],
      repetition: [false],
      repetitionNumber: [null],
      idFrequency: [null],
      frequencyName: [''],
      startTime: [null, [Validators.required]],
      endTime: [null, [Validators.required]],
    }, {
      validators: [this.VerifyTimeRanges()]
    });

    if (this.isUpdate) {
      this.model.get('idDay').setValue(this.data.form.idDay);
      this.model.get('dayName').setValue(this.data.form.dayName);
      this.model.get('startTime').setValue(this.data.form.startTime);
      this.model.get('endTime').setValue(this.data.form.endTime);
      this.model.get('motive').setValue(this.data.form.motive);
      this.model.get('repetition').setValue(this.data.form.repetition);
      this.model.get('repetitionNumber').setValue(this.data.form.repetitionNumber);
      this.model.get('idFrequency').setValue(this.data.form.idFrequency);
      this.model.get('frequencyName').setValue(this.data.form.frequencyName);
    }
  }

  submit() {
    if (this.model.invalid) {
      this.model.markAllAsTouched();
      this.alertService.show('Atenção', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.warning);
      return;
    }

    if (this.model.get('repetition').value) {
      let frequency = this.listFrequency.find(x => x.idFrequency == this.model.get('idFrequency').value);
      this.model.get('frequencyName').setValue(frequency.frequencyName);
    }

    this.matDialogRef.close({
      form: this.model,
      isUpdate: this.isUpdate
    });
  }

  clickCancel() {
    this.matDialogRef.close({});
  }

  VerifyTimeRanges(): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors | null => {
      if (!formGroup.get('startTime').value || !formGroup.get('endTime').value) {
        return null;
      }

      let startTime: TimeStruct = {
        hours: parseInt(formGroup.get('startTime').value.slice(0, 2)),
        minutes: parseInt(formGroup.get('startTime').value.slice(2, 5))
      }

      let endTime: TimeStruct = {
        hours: parseInt(formGroup.get('endTime').value.slice(0, 2)),
        minutes: parseInt(formGroup.get('endTime').value.slice(2, 5))
      }

      let invalidTime: boolean = false;
      let invalidTimeError = { invalidTime: true };

      let invalidRange: boolean = false;
      let invalidRangeError = { invalidRange: true };

      //Validando se o formato de tempo é válido
      invalidTime = false;
      let expression: RegExp = /^([0-1]?[0-9]|2[0-3])[0-5][0-9]$/;

      if (!expression.test(formGroup.get('startTime').value)) {
        formGroup.get('startTime').setErrors(invalidTimeError);
        invalidTime = true;
      }
      else if (!expression.test(formGroup.get('endTime').value)) {
        formGroup.get('endTime').setErrors(invalidTimeError);
        invalidTime = true;
      }

      if (invalidTime)
        return invalidTimeError

      let interval = new TimeSpanStruct(startTime, endTime);

      //Validando se o intervalo faz sentido
      invalidRange = false;

      if (interval.endTime.hours < interval.startTime.hours) {
        invalidRange = true;
        formGroup.get('startTime').setErrors(invalidRangeError);
        formGroup.get('endTime').setErrors(invalidRangeError);
        return invalidRangeError;
      }

      else if (interval.endTime.hours === interval.startTime.hours) {
        if (interval.endTime.minutes <= interval.startTime.minutes) {
          invalidRange = true;
          formGroup.get('startTime').setErrors(invalidRangeError);
          formGroup.get('endTime').setErrors(invalidRangeError);
          return invalidRangeError;
        }
      }

      if (!invalidRange && !invalidTime) {
        formGroup.get('startTime').setErrors(null);
        formGroup.get('endTime').setErrors(null);
      }
      return null;
    }
  };

  setDayName() {
    let idDay = this.model.get('idDay').value;
    let dayName = this.listDay.find(x => x.idDay == idDay).dayName;

    this.model.get('dayName').setValue(dayName);
  }

  setFrequencyName() {
    let idFrequency = this.model.get('idFrequency').value;
    let frequencyName = this.listFrequency.find(x => x.idFrequency == idFrequency).frequencyName;

    this.model.get('frequencyName').setValue(frequencyName);
  }
}