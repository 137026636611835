import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { UserService } from '../../../shared/services/API/user/user.service';
import { ProfessionService } from 'src/app/shared/services/API/user/profession.service';
import { ProfileService } from 'src/app/shared/services/API/user/profile.service';
import { ListProfileStruct } from 'src/app/shared/services/structs/user/list-profile.struct';
import { ProfessionStruct } from 'src/app/shared/services/structs/user/profession.struct';
import { UserRegisterRequest } from 'src/app/shared/services/requests/user/user-register-request';
import { ProfessionEnum } from 'src/app/shared/enum/profession.enum';
import { VerifyPassword } from 'src/app/shared/custom-validators/password.validator';
import { CboService } from 'src/app/shared/services/API/user/cbo.service';
import { environment } from 'src/environments/environment';
import { HealthUnitStateService } from 'src/app/shared/services/API/user/health-unit-state.service';
import { HealthUnitState } from 'src/app/shared/services/models/user/health-unit-state.model';
import { HealthUnitService } from 'src/app/shared/services/API/user/health-unit.service';
import { GetFullHealthUnitResponse } from 'src/app/shared/services/responses/user/get-full-health-unit.response';
import { LoginTypeEnum } from 'src/app/shared/enum/user/login-type.enum';
import { MatDialog } from '@angular/material/dialog';
import { AccessKeyService } from 'src/app/shared/services/API/integration/access-key.service';
import { AlertModalComponent } from 'src/app/shared/components/alert-modal/alert-modal.component';

@Component({
  selector: 'app-user-register',
  templateUrl: './user-register.component.html',
  styleUrls: ['./user-register.component.css']
})
export class UserRegisterComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private userService: UserService,
    private professionService: ProfessionService,
    private cboService: CboService,
    private profileService: ProfileService,
    private healthUnitStateService: HealthUnitStateService,
    private healthUnitService: HealthUnitService,
    public dialog: MatDialog,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.master;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.master_user;
  public listRequiredCouncil: ProfessionEnum[];

  public model: FormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;

  public masks: Masks;
  public idUser: number;
  public isloginAndPassword: boolean;
  public listProfile: ListProfileStruct[];
  public listProfession: ProfessionStruct[];
  public userRegisterRequest: UserRegisterRequest;
  public urlListCbo: string;
  public preSelectCbo: any[];
  public listState: HealthUnitState[];
  public preSelectedState: HealthUnitState[];
  public getFullHealthUnitResponse: GetFullHealthUnitResponse;
  public hasICPBrasilHealthUnit: boolean = false;
  public isMedicProfessional: boolean = false;

  ngOnInit(): void {
    this.isLoading = false;
    this.isloginAndPassword = false
    this.urlListCbo = environment.urlApiUser + "Cbo";
    this.preSelectCbo = [];
    this.listRequiredCouncil = [
      ProfessionEnum.Medico,
      ProfessionEnum.Enfermeiro,
      ProfessionEnum.TecnicoDeEnfermagem
    ];

    this.model = this.formBuilder.group({
      name: ['', [Validators.required]],
      cpf: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      professional: ['', [Validators.required]],
      login: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      temporaryPassword: ['', [Validators.required, VerifyPassword()]],
      perfil: ['', [Validators.required]],
      status: ['', [Validators.required]],
      council: [''],
      haveSignature: [''],
      cns: [''],
      cnpj: [''],
      cbo: [null],
      idStateCouncil: [null],
      hasICPBrasilCertificate: [false], 
      certificateUUID: [null],
      hasIcpBrasilTokenCertificate: [false],
    });

    this.masks = this.maskService.getMasks();
    this.getHealthUnitConfig();
    this.userRegisterRequest = new UserRegisterRequest();    
    this.idUser == null;
    this.isUpdate == false;
    this.model.get('haveSignature').setValue(false);
    if (this.activatedRoute.snapshot.paramMap.get('idUser'))
      this.idUser = parseInt(this.activatedRoute.snapshot.paramMap.get('idUser'));

    if (this.idUser != null) {
      this.isFirstLoading = true;
      this.isUpdate = true;
      this.model.get('cpf').disable();
      this.model.get('temporaryPassword').setValidators([VerifyPassword()]);
      this.model.get('temporaryPassword').updateValueAndValidity();
      this.populateUserData();
    }

    this.populateProfileSelect();
    this.populateSelectProfession();
    this.populateStateCouncilSelect();

  }

  submit() {
    if (this.isLoading) {
      return;
    }

    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    this.isLoading = true;

    this.userRegisterRequest.council = this.model.get('council').value;
    this.userRegisterRequest.cpf = this.model.get('cpf').value.replace(/[^0-9]+/g, '');
    this.userRegisterRequest.email = this.model.get('email').value;
    this.userRegisterRequest.idProfession = this.model.get('professional').value;
    this.userRegisterRequest.isActive = this.model.get('status').value == "true" ? true : false;
    this.userRegisterRequest.login = this.model.get('login').value;
    this.userRegisterRequest.phone = this.maskService.formatPhoneToSave(this.model.get('phone').value);
    this.userRegisterRequest.tempPassword = this.model.get('temporaryPassword').value;
    this.userRegisterRequest.userName = this.model.get('name').value;
    this.userRegisterRequest.listProfile = this.model.get('perfil').value;
    this.userRegisterRequest.haveSignature = this.model.get('haveSignature').value;
    this.userRegisterRequest.cns = this.model.get('cns').value;
    this.userRegisterRequest.cnpj = this.model.get('cnpj').value;
    this.userRegisterRequest.idCbo = this.model.get('cbo').value;
    this.userRegisterRequest.idStateCouncil = this.model.get('idStateCouncil').value;
    this.userRegisterRequest.hasICPBrasilCertificate = this.model.get('hasICPBrasilCertificate').value;
    this.userRegisterRequest.certificateIdentifier = this.model.get('certificateUUID').value;
    this.userRegisterRequest.hasIcpBrasilTokenCertificate = this.model.get('hasIcpBrasilTokenCertificate').value;

    if (this.isUpdate)
      this.updateUser();
    else
      this.createUser();
  }
  populateUserData() {
    this.userService.getUser(this.idUser).subscribe({next:(response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.model.get('name').setValue(response.user.userName);
      this.model.get('cpf').setValue(response.user.cpf);
      this.model.get('phone').setValue(this.maskService.formatPhoneToField(response.user.phone));
      this.model.get('login').setValue(response.user.login);
      this.model.get('email').setValue(response.user.email);
      this.model.get('council').setValue(response.user.council);
      this.model.get('status').setValue(response.user.isActive.toString());
      this.model.get('professional').setValue(response.user.idProfession.toString());
      this.model.get('perfil').setValue(response.listIdProfile.map(c => c.toString()));
      this.model.get('haveSignature').setValue(response.user.haveSignature);
      this.model.get('cns').setValue(response.user.cns);
      this.model.get('cnpj').setValue(response.user.cnpj);
      this.model.get('cbo').setValue(response.user.idCbo);
      if (response.user.idStateCouncil) {
        this.model.get('idStateCouncil').setValue(response.user.idStateCouncil.toString())
      }
      
      if(response.user.idProfession == ProfessionEnum.Medico){
        this.isMedicProfessional = true;
        this.model.get('hasICPBrasilCertificate').setValue(response.user.hasIcpBrasilCertificate);
        this.model.get('certificateUUID').setValue(response.user.certificateIdentifier);
        this.model.get('hasIcpBrasilTokenCertificate').setValue(response.user.hasIcpBrasilTokenCertificate);
      } 

      if (response.user.idCbo) {
        this.cboService.listAllCbo().subscribe({next:(responseCbo) => {

          if (response.isError) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            return;
          }

          let cboObject = responseCbo.list.find(l => l.idCbo == response.user.idCbo);
          this.preSelectCbo = [cboObject];
        },
        error:(error) => {
          console.log(error)
          this.alertService.show('Erro inesperado', error, AlertType.error);
        }});
      }

      this.isLoading = false;
      this.isFirstLoading = false;
    },
    error:(error) => {
      console.log(error)
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    }});
  }

  populateStateCouncilSelect() {
    this.healthUnitStateService.getAllStates().subscribe({next:(response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.listState = response.list;
      this.isLoading = false;

    },
    error:(error) => {
      console.log(error)
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    }});
  }

  populateProfileSelect() {
    this.profileService.listAllProfile().subscribe({next:(response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.listProfile = response.listProfile;
      this.isLoading = false;

    },
    error:(error) => {
      console.log(error)
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    }});
  }

  populateSelectProfession() {
    this.professionService.listProfession().subscribe({next:(response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }

      this.listProfession = response.listProfession;
    },
    error:(error) => {
      console.log(error)
      this.alertService.show('Erro inesperado', error, AlertType.error);
    }});
  }

  updateUser() {
    this.userService.updateUser(this.idUser, this.userRegisterRequest).subscribe({next:(response) => {

      if(response.isError && response.errorCode == 1){
        const dialogRef = this.dialog.open(AlertModalComponent, {
          data: {
            title: "Atenção",
            description: response.errorDescription,
            hasImage: true
          },
        });
        this.isLoading = false;
        return;
      }

      else if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        if (response.errorCode != 2)
          return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/master/user']);

    },
    error:(error) => {
      console.log(error)
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    }});
  }

  createUser() {
    this.userService.createUser(this.userRegisterRequest).subscribe({next:(response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        if (response.errorCode != 2)
          return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/master/user']);

    },
    error:(error) => {
      console.log(error)
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    }});
  }

  addItemCbo(event: any) {
    this.model.get('cbo').setValue(event.idCbo);
    this.model.get('cbo').updateValueAndValidity();
  }


  onProfessionalChange(event: any) {
    if(event == ProfessionEnum.Medico) this.isMedicProfessional = true;
    else {
      this.model.get('certificateUUID').setValidators(null);
      this.model.get('certificateUUID').setValue(null);
      this.model.get('hasICPBrasilCertificate').setValue(false);
      this.model.get('certificateUUID').updateValueAndValidity();
      this.isMedicProfessional = false;
    }

    if (this.listRequiredCouncil.includes(parseInt(event))) {
      this.model.get('council').setValidators([Validators.required]);
      this.model.get('council').updateValueAndValidity();
      this.model.get('idStateCouncil').setValidators([Validators.required]);
      this.model.get('idStateCouncil').updateValueAndValidity();
    }
    else {
      this.model.get('council').setValidators([]);
      this.model.get('council').updateValueAndValidity();
      this.model.get('idStateCouncil').setValidators([]);
      this.model.get('idStateCouncil').updateValueAndValidity();
    }
  }

  passwordValidation(control: AbstractControl) {
    (1)
    let email = control.value; (2)
    if (email && email.indexOf("@") != -1) {
      (3)
      let [_, domain] = email.split("@"); (4)
      if (domain !== "codecraft.tv") {
        (5)
        return {
          emailDomain: {
            parsedDomain: domain
          }
        }
      }
    }
    return null; (6)
  }

  getHealthUnitConfig(){
    this.isLoading = true; 
    this.healthUnitService.getHealthUnitConfig().subscribe({
      next: (response)=>{
      
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        
        this.hasICPBrasilHealthUnit = response.healthUnit.hasICPBrasil;
        this.getFullHealthUnitResponse = response;
        
        this.verifyLoginType(this.getFullHealthUnitResponse.healthUnit.idLoginType);

        this.isLoading = false; 
      },
      error: (error)=>{
        console.log(error)
        this.isLoading = false;    
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  verifyLoginType(idLogin: number){
    if(idLogin == LoginTypeEnum.loginAndPassword){
      this.isloginAndPassword = true
      if(this.isUpdate){
        this.model.get('temporaryPassword').setValidators([VerifyPassword()]);
      }
      else {
        this.model.get('temporaryPassword').setValidators([Validators.required, VerifyPassword()]);
      }
      this.model.get('temporaryPassword').enable();
    }
    else{
      this.isloginAndPassword = false
      this.model.get('temporaryPassword').setValidators([]);
      this.model.get('temporaryPassword').disable();
    }

    this.model.get('temporaryPassword').updateValueAndValidity();
  }
}