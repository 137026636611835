import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ReportAccessService } from 'src/app/shared/services/API/medical-record/report-access.service';

@Component({
  selector: 'app-report-viewer',
  templateUrl: './report-viewer.component.html',
  styleUrls: ['./report-viewer.component.css']
})
export class ReportViewerComponent implements OnInit {
  pdfData: SafeResourceUrl;

  constructor(private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private reportAccessService: ReportAccessService,
    public sanitizer: DomSanitizer
  ) { }

  public checkedLoading: boolean = false;
  public isLoading: boolean = false;
  public guid: string;

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.paramMap.get('guid')) {
      this.guid = this.activatedRoute.snapshot.paramMap.get('guid');
      this.getTelemedicinePatientReportByGuid(this.guid);
    }
  }

  getTelemedicinePatientReportByGuid(guid: string) {
    this.reportAccessService.getReport(guid).subscribe({
      next: (response) => {
        if (response.isError) {
          this.checkedLoading = true;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.guid = null;
          return;
        }
        this.pdfData = this.sanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,' + response.reportPdf64);
        this.checkedLoading = true;
        this.isLoading = true;
      },
      error: (error) => {
        this.checkedLoading = true;
        console.log(error);
        this.guid = null;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}