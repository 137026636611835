<form class="form" [formGroup]="prescriptionModel" (ngSubmit)="onSubmit()">

    <div formArrayName="listMedicine"
        *ngFor="let item of prescriptionModel.get('listMedicine')['controls']; let i = index">
        <div class="row" [formGroupName]="i">
            <div class="col-10">
                <div class="row">
                    <div class="col-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Medicamento</mat-label>
                            <mat-select formControlName="idMedicine" (selectionChange)="onChangeMedicine($event, i)">
                                <input class="inputSearchSelect" type="text" (keyup)="onKey($event.target.value, i)"
                                    placeholder="Pesquisar" autocomplete="off">
                                <mat-option [value]="null"> Selecione... </mat-option>
                                <mat-option *ngFor="let medicineItem of selectedMedicineLists[i]"
                                    value="{{medicineItem.medicine.idMedicine}}"
                                    [disabled]="listIdSelectedMedicine.includes(medicineItem.medicine.idMedicine.toString())">
                                    {{medicineItem.medicine.medicineDescription}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Quantidade</mat-label>
                            <input formControlName="quantity" matInput [readonly]="this.readOnly" type="text"
                                oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                            <mat-error *ngIf="item.get('quantity').invalid">A quantidade máxima é
                                32767!</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Unidade</mat-label>
                            <mat-select formControlName="idMeasurementUnit">
                                <mat-option *ngFor="let measurementUnitItem of measurementUnitList"
                                    [value]="measurementUnitItem.idMeasurementUnit">
                                    {{measurementUnitItem.measurementUnitName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="col-2">
                <button [disabled]="this.readOnly" class="panel-button " color="primary" mat-mini-fab type="button"
                    (click)="addNextMedicineInput()">
                    <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
                </button>
                <button [disabled]="this.readOnly" class="panel-button " color="primary" mat-mini-fab type="button"
                    (click)="removeMedicineInput(i)" *ngIf="i != 0 && i != 1">
                    <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
                </button>
            </div>
        </div>
    </div>

    <div class="checkbox-container" *ngIf="isFastTrack">
        <mat-checkbox [checked]="bypassPrescription" (change)="toggleBypassPrescription($event)">
            Listar todos
        </mat-checkbox>
    </div>
    <div class="row">
        <div class="col-12 col-sm-12 col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>Via</mat-label>
                <mat-select formControlName="idAdministrationType" (ngModelChange)="onChangeAdministrationType($event)">
                    <mat-option *ngFor="let item of administrationTypeList" [value]="item.idAdministrationType">
                        {{item.administrationTypeName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 col-sm-12 col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>Frequência</mat-label>
                <mat-select formControlName="idFrequency" (ngModelChange)="onChangeFrequency($event)">
                    <mat-option *ngFor="let item of frequencyList" value="{{item.idFrequency}}">
                        {{item.frequencyName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row" *ngIf="isFrequency">
        <div class="col-12 col-sm-12 col-md-12">
            <mat-form-field appearance="outline">
                <mat-label>Defina a frequência</mat-label>
                <mat-select formControlName="idSubFrequency">
                    <mat-option *ngFor="let item of this.lookupMedicalCareResponse.listSubFrequency"
                        value="{{item.idSubFrequency}}">
                        {{item.subFrequencyName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div *ngIf="this.prescriptionModel.get('idAdministrationType') && administrationTypeOthersEnum.valueOf() == this.prescriptionModel.get('idAdministrationType').value"
        class="row">
        <div class="col-12">
            <mat-form-field appearance="outline">
                <mat-label>Descreva a Via</mat-label>
                <textarea [readonly]="this.readOnly" matInput formControlName="administrationType"></textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-sm-12 col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>Diluição</mat-label>
                <mat-select (ngModelChange)="onChangeDilution($event)" formControlName="idDilution">
                    <mat-option *ngFor="let item of dilutionList" value="{{item.idDilution}}">
                        {{item.dilutionName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 col-sm-12 col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>Apresentação</mat-label>
                <mat-select (ngModelChange)="onChangePresentation($event)" formControlName="idPresentation">
                    <mat-option *ngFor="let item of presentationList" value="{{item.idPresentation}}">
                        {{item.presentationName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row" *ngIf="undiluted">
        <div class="col-12 col-sm-12 col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>Quantidade</mat-label>
                <input formControlName="dilutionQuantity" matInput [readonly]="this.readOnly" type="text"
                    oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                <mat-error *ngIf="prescriptionModel.get('dilutionQuantity').invalid">A quantidade máxima é
                    32767!</mat-error>
            </mat-form-field>
        </div>
        <div class="col-12 col-sm-12 col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>Unidade</mat-label>
                <mat-select formControlName="idDilutionUnit">
                    <mat-option *ngFor="let item of dilutionUnitList" [value]="item.idDilutionUnit">
                        {{item.dilutionUnitName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div *ngIf="this.prescriptionModel.get('idDilution') && this.prescriptionModel.get('idDilution').value == dilutionOthersEnum.valueOf()"
        class="row">
        <div class="col-12">
            <mat-form-field appearance="outline">
                <mat-label>Descreva a diluição</mat-label>
                <textarea [readonly]="this.readOnly" matInput formControlName="dilution"></textarea>
            </mat-form-field>
        </div>
    </div>
    <div *ngIf="this.prescriptionModel.get('idPresentation') && this.prescriptionModel.get('idPresentation').value == presentationOthersEnum.valueOf()"
        class="row">
        <div class="col-12">
            <mat-form-field appearance="outline">
                <mat-label>Descreva a apresentação</mat-label>
                <textarea [readonly]="this.readOnly" matInput formControlName="presentation"></textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-sm-12 col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>Periodo de tratamento</mat-label>
                <mat-select formControlName="idTreatmentPeriod">
                    <mat-option *ngFor="let item of treatmentList" value="{{item.idTreatmentPeriod}}">
                        {{item.treatmentPeriodName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <mat-form-field appearance="outline">
                <mat-label>Observação</mat-label>
                <textarea [readonly]="this.readOnly" matInput formControlName="observation"></textarea>
            </mat-form-field>
        </div>
    </div>

    <div class="footer-panel-modal-list">
        <div class="row">
            <div *ngIf="!this.readOnly" class="col-6">
                <a mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Cancelar</a>
            </div>
            <div *ngIf="!this.readOnly" class="col-6">
                <button mat-flat-button type="submit" color="primary" class=" btn-block">
                    <span>Salvar</span>
                </button>
            </div>
            <div class="col-12">
                <a *ngIf="this.readOnly" mat-flat-button color="primary" class="btn-block"
                    (click)="clickCancel()">Ok</a>
            </div>
        </div>
    </div>
</form>