import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BillingRoutingModule } from './billing-routing.module';
import { BillingComponent } from './pages/billing/billing.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from '../shared/shared.module';
import { ProfessionalProductionAttendanceListComponent } from './pages/professional-production-attendance-list/professional-production-attendance-list.component';
import { ProfessionalProductionAttendanceRegisterComponent } from './pages/professional-production-attendance-register/professional-production-attendance-register.component';
import { DateRangeModalComponent } from './pages/professional-production-attendance-register/date-range-modal/date-range-modal.component';
import { PatientProcedureMadeListComponent } from './pages/patient-procedure-made-list/patient-procedure-made-list.component';
import { PatientProcedureMadeRegisterComponent } from './pages/patient-procedure-made-register/patient-procedure-made-register.component';
import { PatientDateRangeModalComponent } from './pages/patient-procedure-made-register/patient-date-range-modal/patient-date-range-modal.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { HealthcareAgreementsAndPlansListComponent } from './pages/healthcare-agreements-and-plans-list/healthcare-agreements-and-plans-list.component';
import { HealthcareAgreementsAndPlansRegisterComponent } from './pages/healthcare-agreements-and-plans-register/healthcare-agreements-and-plans-register.component';
import { MatTabsModule } from '@angular/material/tabs';
import { BasicDataComponent } from './pages/healthcare-agreements-and-plans-register/basic-data/basic-data.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { PlanComponent } from './pages/healthcare-agreements-and-plans-register/plan/plan.component';
import { PlanModalComponent } from './pages/healthcare-agreements-and-plans-register/plan/plan-modal/plan-modal.component';
import { ReturnRestrictionsComponent } from './pages/healthcare-agreements-and-plans-register/return-restrictions/return-restrictions.component';
import { ReturnRestrictionsModalComponent } from './pages/healthcare-agreements-and-plans-register/return-restrictions/return-restrictions-modal/return-restrictions-modal.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ProcedureListComponent } from './pages/procedure-list/procedure-list.component';
import { PriceProcedureHealthcareComponent } from './pages/healthcare-agreements-and-plans-register/price-procedure-healthcare/price-procedure-healthcare.component';
import { PriceProcedurePlanComponent } from './pages/healthcare-agreements-and-plans-register/price-procedure-plan/price-procedure-plan.component';
import { PriceChangeHistoryModalComponent } from './pages/healthcare-agreements-and-plans-register/price-procedure-healthcare/price-change-history-modal/price-change-history-modal.component';
import { ProcedureRegisterComponent } from './pages/procedure-register/procedure-register.component';
import { ProcedureBasicDataComponent } from './pages/procedure-register/procedure-basic-data/procedure-basic-data.component';
import { ProcedurePriceByHealthcareComponent } from './pages/procedure-register/procedure-price-by-healthcare/procedure-price-by-healthcare.component';
import { PriceHistoryModalComponent } from './pages/procedure-register/procedure-price-by-healthcare/price-history-modal/price-history-modal.component';
import { ProcedureDerivativesComponent } from './pages/procedure-register/procedure-derivatives/procedure-derivatives.component';
import { ProcedureDerivateEditModalComponent } from './pages/procedure-register/procedure-derivatives/procedure-derivate-edit-modal/procedure-derivate-edit-modal.component';
import { TissBillingComponent } from './pages/healthcare-agreements-and-plans-register/tiss-billing/tiss-billing.component';
import { ProfessionalsComponent } from './pages/healthcare-agreements-and-plans-register/professionals/professionals.component';
import { ViewDataComponent } from './pages/healthcare-agreements-and-plans-register/professionals/view-data/view-data.component';
import { MoreSettingsComponent } from './pages/healthcare-agreements-and-plans-register/more-settings/more-settings.component';
import { TissGuideReportListComponent } from './pages/tiss-guide-report-list/tiss-guide-report-list.component';
import { GuideBatchListComponent } from './pages/guide-batch-list/guide-batch-list.component';
import { GuideBatchRegisterComponent } from './pages/guide-batch-register/guide-batch-register.component';
import { FiltersToGuideBatchModalComponent } from './pages/guide-batch-register/filters-to-guide-batch-modal/filters-to-guide-batch-modal.component';
import { SelectSendDateModalComponent } from './pages/guide-batch-register/select-send-date-modal/select-send-date-modal.component';
import { GuideBatchEditComponent } from './pages/guide-batch-edit/guide-batch-edit.component';

@NgModule({
  declarations: [
    BillingComponent,
    ProfessionalProductionAttendanceListComponent,
    ProfessionalProductionAttendanceRegisterComponent,
    DateRangeModalComponent,
    PatientProcedureMadeListComponent,
    PatientProcedureMadeRegisterComponent,
    PatientDateRangeModalComponent,
    HealthcareAgreementsAndPlansListComponent,
    HealthcareAgreementsAndPlansRegisterComponent,
    BasicDataComponent,
    PlanComponent,
    PlanModalComponent,
    ReturnRestrictionsComponent,
    ReturnRestrictionsModalComponent,
    ProcedureListComponent,
    PriceProcedureHealthcareComponent,
    PriceProcedurePlanComponent,
    PriceChangeHistoryModalComponent,
    ProcedureRegisterComponent,
    ProcedureBasicDataComponent,
    ProcedurePriceByHealthcareComponent,
    PriceHistoryModalComponent,
    ProcedureDerivativesComponent,
    ProcedureDerivateEditModalComponent,
    TissBillingComponent,
    ProfessionalsComponent,
    ViewDataComponent,
    MoreSettingsComponent,
    TissGuideReportListComponent,
    GuideBatchListComponent,
    GuideBatchRegisterComponent,
    FiltersToGuideBatchModalComponent,
    SelectSendDateModalComponent,
    GuideBatchEditComponent,
  ],
  imports: [
    CommonModule,
    MatTabsModule,
    BillingRoutingModule,
    SharedModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTreeModule,
    MatRadioModule,
    MatTooltipModule,
    MatToolbarModule,
    NgxMatFileInputModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    MatSlideToggleModule,
    CdkAccordionModule,
    MatDatepickerModule,
    MatDialogModule,
    MatPaginatorModule,
    MatButtonToggleModule
  ]
})
export class BillingModule { }
