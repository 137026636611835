import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AgeGroup, ArrivalType, Gender, Origin } from 'src/app/shared/services/responses/medical-record/lookup.response';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MedicalRecordLookupService } from 'src/app/shared/services/API/medical-record/lookup.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { MatDialog } from '@angular/material/dialog';
import { ReportService } from 'src/app/shared/services/API/orchestrator-patient/report.service';
import { PriorityModel } from 'src/app/shared/services/models/totem/priority.model';
import { PatientService } from 'src/app/shared/services/API/medical-record/patient.service';
import { PatientStruct } from 'src/app/shared/services/structs/medical-record/patient.struct';
import { EpisodeService } from 'src/app/shared/services/API/medical-record/episode.service';
import { FowardService } from 'src/app/shared/services/API/orchestrator-queue/foward.service';
import { PriorityService } from 'src/app/shared/services/API/totem/priority.service';
import { ClassificationData, Reclassification, UtilsClassificationService } from '../../../utils.service';
import { PatientPatchRequest } from 'src/app/shared/services/requests/medical-record/patient-patch.request';
import { SelectPatientModalComponent } from '../select-patient-modal/select-patient-modal.component';
import { SearchModalComponent } from '../search-modal/search-modal.component';
import { RoomMeasurerService } from 'src/app/shared/services/API/risk-classification/room-measurer.service';
import { RoomMeasurerStruct } from 'src/app/shared/services/structs/risk-classification/room-measurer.struct';
import { NatureOfAttendanceEnum } from 'src/app/shared/enum/medical-record/nature-of-attendance.enum';
import { PreAdmissionRequiredFieldSector } from 'src/app/shared/services/models/risk-classification/pre-admission-required-field-sector.model';
import { PreAdmissionRequirableFieldsEnum } from 'src/app/shared/enum/risk-classification/pre-admission-requirable-fields.enum';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { VerifyCPF } from 'src/app/shared/custom-validators/cpf.validator';
import { VerifyCNS } from 'src/app/shared/custom-validators/cns.validator';
import { UtilService } from 'src/app/shared/services/util.service';
import { PrioritiesStatusEnum } from 'src/app/shared/enum/priorities-status.enum';
import { CalcAgeService } from 'src/app/shared/services/API/medical-record/calc-age.service';
import { CalcAgeResponse } from 'src/app/shared/services/responses/medical-record/calc-age.response';
import { birthdateValidator } from 'src/app/shared/custom-validators/birthdate.validator';
import { AllergyStruct } from 'src/app/shared/services/structs/medical-record/allergy.struct';
import { SearchPatientPreregisterService } from 'src/app/shared/services/API/orchestrator-patient/search-patient-preregister.service';
import { SearchPatientTotemRequest } from 'src/app/shared/services/requests/orchestrator-totem/search-patient-totem.request';
import { VerifyPhoneNumber } from 'src/app/shared/custom-validators/phone.validator';
import { MetaDataStruct } from 'src/app/shared/services/structs/meta-data/meta-data.struct';
import { MetaDataTypeEnum } from 'src/app/shared/enum/meta-data/meta-data-type.enum';
import { MetaDataKeyValueStruct } from 'src/app/shared/services/structs/meta-data/meta-data-key-value.struct';
import { VerifyDate } from 'src/app/shared/custom-validators/date-age.validator';
import { ConfirmPatientDataModalComponent } from '../confirm-patient-data/confirm-patient-data-modal.component';
import { EpisodeStruct } from 'src/app/shared/services/structs/medical-record/episode.struct';
import { PreAdmissionRequiredFieldSectorService } from 'src/app/shared/services/API/risk-classification/pre-admission-required-field-sector.service';
import { MetaDataService } from 'src/app/shared/services/API/meta-data/meta-data.service';
import { GroupOriginService } from 'src/app/shared/services/API/orchestrator-user/group-origin.service';
import { DeviceTypeSectorResponse } from 'src/app/shared/services/responses/risk-classification/device-type-sector.response';
import { PatchPatientResponse } from 'src/app/shared/services/responses/medical-record/patch-patient.response';
import { TriagePreRegisterService } from 'src/app/shared/services/API/orchestrator-patient/triage-pre-register.service';

@Component({
  selector: 'app-pre-register-form-default',
  templateUrl: './pre-register-form-default.component.html',
  styleUrls: ['./pre-register-form-default.component.css']
})
export class PreRegisterFormDefaultComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private patientService: PatientService,
    private episodeService: EpisodeService,
    private priorityService: PriorityService,
    private lookupService: MedicalRecordLookupService,
    private maskService: MaskService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public fowardService: FowardService,
    public reportService: ReportService,
    public router: Router,
    public utilService: UtilService,
    public utilClassification: UtilsClassificationService,
    private roomMeasurerService: RoomMeasurerService,
    private calcAgeService: CalcAgeService,
    private searchPatientService: SearchPatientPreregisterService,
    private preAdmissionRequiredFieldSectorService: PreAdmissionRequiredFieldSectorService,
    private metaDataService: MetaDataService,
    private GroupOriginByOrchestratorService: GroupOriginService,
    private triagePreRegisterService: TriagePreRegisterService,
  ) { }

  @Input() idEpisode: number;
  @Input() idRoom: number;
  @Input() idSector: number;
  @Input() idService: number;
  @Input() idQueue: number;
  @Input() idDeviceType: number;
  @Input() reclassification: boolean;
  @Input() classificationData: ClassificationData;
  @Input() isPreRegister: boolean;
  @Input() edited: boolean = false;
  @Output() repopulateHeader = new EventEmitter<any>();
  @Output() emitEdition = new EventEmitter<any>();
  @Output() setIsNext = new EventEmitter<any>();

  public listIdMetaDataRequired: number[];
  public listSectorRequiredField: PreAdmissionRequiredFieldSector[];
  public listMetaDataStruct: MetaDataStruct[] = [];
  public listMetaData: MetaDataStruct[] = [];
  private ageYear: number;
  private ageMonth: number;
  private ageDay: number;
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.classification;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.classification_pre_registration;
  public model: FormGroup;
  public isLoading: boolean = true;
  public isFirstLoading: boolean = true;
  public genderList: Gender[];
  public listAgeGroup: AgeGroup[];
  public masks: Masks = this.maskService.getMasks();
  public idPatient: number = null;
  public idArrivalType: number = null;
  public idOrigin: number = null;
  public isUnidentifiedPatient: boolean = false;
  public allowAdmissionDataChanging: boolean = false;
  public setUppercaseText: boolean = false;
  public skipIdentifiedPatientPreRegister: boolean = false;
  public listOfPriorities: PriorityModel[];
  public newPatient: boolean = false;
  public showMandatoryCnsCheckbox: boolean = false;
  public showMandatoryCpfCheckbox: boolean = false;
  public roomMeasurerStruct: RoomMeasurerStruct[];
  public listAllergy: AllergyStruct[] = [];
  public validNps: boolean = false;
  public searchByCpfOrCns: boolean = false;
  private deviceThermometer: RoomMeasurerStruct;
  private deviceGlucometer: RoomMeasurerStruct;
  private devicePressure: RoomMeasurerStruct;
  private deviceOximeter: RoomMeasurerStruct;
  public originList: Origin[];
  public arrivalTypeList: ArrivalType[];
  private reclassificationData: Reclassification;

  public disallowedIdPriorities: number[] = [];

  public idCareLinePriority: number;
  public idCareLinePriorityStatus: number;
  public episode: EpisodeStruct;

  ngOnInit(): void {
    let deviceTypeSectorResponse: DeviceTypeSectorResponse = this.utilClassification.getDevicetypeBySector()
    if (deviceTypeSectorResponse && deviceTypeSectorResponse.deviceTypeSector) {
      if (deviceTypeSectorResponse.deviceTypeSector.allowAdmissionDataChanging)
        this.allowAdmissionDataChanging = deviceTypeSectorResponse.deviceTypeSector.allowAdmissionDataChanging;
      if (deviceTypeSectorResponse.deviceTypeSector.setUppercaseText)
        this.setUppercaseText = deviceTypeSectorResponse.deviceTypeSector.setUppercaseText;
      if (deviceTypeSectorResponse.deviceTypeSector.skipIdentifiedPatientPreRegister)
        this.skipIdentifiedPatientPreRegister = deviceTypeSectorResponse.deviceTypeSector.skipIdentifiedPatientPreRegister;
    }

    this.getAllPriorities();
    this.populateLookupSelect();
    this.getArrivalTypeAndOrigin();
    this.getListMetaData();

    this.model = this.formBuilder.group({
      patientName: ['', [Validators.required]],
      socialName: [''],
      cpf: ['', [VerifyCPF()]],
      birthDate: ['', [Validators.required, birthdateValidator()]],
      idGender: [''],
      cns: ['', [VerifyCNS()]],
      listIdPriority: [null],
      unidentifiedPatientDescription: [''],
      ageGroup: [''],
      patientAge: [{ value: '', disabled: true }],
      unidentifiedPatient: [false],
      motherName: [''],
      notMandatoryCpf: [false],
      notMandatoryCns: [false],
      phone: [''],
      observation: [''],
      idArrivalType: [''],
      idOrigin: ['']
    });

    if (this.idEpisode != null)
      setTimeout(() => { this.populatePatientData(this.idEpisode); }, 2000);
    else if (this.isPreRegister) {
      this.newPatient = true;
      this.openModalSearchByCPF();
      this.isFirstLoading = false;
    }
    if (!this.isPreRegister)
      setTimeout(() => { this.controlFormDisable(); }, 2000);

    this.setReclassification();
    
    this.isLoading = false;
  }

  ngOnDestroy() {
  }

  listSectorRequiredFields() {
    this.preAdmissionRequiredFieldSectorService.listBySector(this.idSector, this.listMetaDataStruct).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isFirstLoading = false;
          return;
        }

        this.listSectorRequiredField = response.listSectorRequiredField;
        if (this.listMetaDataStruct.length > 0)
          this.listMetaData = this.listMetaDataStruct.filter((item) => response.listMetaData.some(item2 => item.metaData.idMetaData == item2.idMetaData));
        this.listIdMetaDataRequired = response.listIdMetaDataRequired;
        this.getDeviceType();
        this.verifyRequiredFields();
      },
      error: (error) => {
        console.log(error);
        this.isFirstLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getListMetaData() {
    this.metaDataService.getAllMetaData().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isFirstLoading = false;
          return;
        }

        this.listMetaDataStruct = response.listMetaDataStruct;

        this.listSectorRequiredFields();
      },
      error: (error) => {
        console.log(error);
        this.isFirstLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  setReclassification() {
    if (this.reclassification != true)
      return;

    this.model.get("patientName").disable();
    this.model.get("patientName").clearValidators();

    this.model.get("socialName").disable();
    this.model.get("patientName").clearValidators();

    this.model.get("cpf").disable();
    this.model.get("cpf").clearValidators();

    this.model.get("birthDate").disable();
    this.model.get("birthDate").clearValidators();

    this.model.get("idGender").disable();
    this.model.get("idGender").clearValidators();

    this.model.get("cns").disable();
    this.model.get("cns").clearValidators();

    this.model.get("listIdPriority").disable();
    this.model.get("listIdPriority").clearValidators();

    this.model.get("unidentifiedPatientDescription").disable();
    this.model.get("unidentifiedPatientDescription").clearValidators();

    this.model.get("ageGroup").disable();
    this.model.get("ageGroup").clearValidators();

    this.model.get("patientAge").disable();
    this.model.get("patientAge").clearValidators();

    this.model.get("unidentifiedPatient").disable();
    this.model.get("unidentifiedPatient").clearValidators();

    this.model.get("motherName").disable();
    this.model.get("motherName").clearValidators();

    this.model.get("notMandatoryCpf").disable();
    this.model.get("notMandatoryCpf").clearValidators();

    this.model.get("notMandatoryCns").disable();
    this.model.get("notMandatoryCns").clearValidators();

    this.model.get("phone").disable();
    this.model.get("phone").clearValidators();

    this.model.get("idOrigin").disable();
    this.model.get("idOrigin").clearValidators();

    this.model.get("idArrivalType").disable();
    this.model.get("idArrivalType").clearValidators();
  }

  submit() {
    if (this.isUnidentifiedPatient) {
      if (!this.model.get('unidentifiedPatientDescription').value) {
        this.alertService.show('Erro', 'A descrição e a faixa etária do paciente não identificado são obrigatórios', AlertType.error);
        return;
      }

      let patient = new PatientPatchRequest();
      patient.idEpisode = this.idEpisode;
      patient.isUnidentifiedPatient = this.isUnidentifiedPatient;
      patient.unidentifiedPatientDescription = this.model.get('unidentifiedPatientDescription').value;
      patient.priorities = this.model.get('listIdPriority').value ? this.model.get('listIdPriority').value : null;
      patient.idAgeGroup = this.model.get('ageGroup').value ? this.model.get('ageGroup').value : null;
      patient.idNatureOfAttendance = NatureOfAttendanceEnum.presencial;
      patient.idSector = this.idSector;
      patient.idService = this.idService;

      this.isLoading = true;
      this.triagePreRegisterService.updatePatient(patient).subscribe({
        next: (response) => {
          if (response.isError) {
            this.isLoading = false;
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            return;
          }

          let data: ClassificationData = this.classificationData;
          data.idEpisode = response.idEpisode;
          data.idPatient = response.idPatient;
          data.datetimeStartTriage = response.datetimeStartTriage;
          data.idGender = patient.idGender;
          var ageGroup = this.calculateAgeGroup(this.model.get('ageGroup').value);
          data.ageDay = ageGroup.days;
          data.ageMonth = ageGroup.month;
          data.ageYears = ageGroup.years;
          data.birthDate = this.calcAgeByAgeGroup(this.model.get('ageGroup').value)
          data.idDeviceType = this.idDeviceType;
          data.newPatient = this.newPatient;
          data.reclassification = this.reclassificationData;
          data.isReclassification = this.reclassification;
          data.deviceGlucometer = this.deviceGlucometer;
          data.deviceThermometer = this.deviceThermometer;
          data.listIdPriority = this.model.get('listIdPriority').value;
          data.devicePressure = this.devicePressure;
          data.deviceOximeter = this.deviceOximeter;
          data.listAllergy = this.listAllergy;
          data.idSector = parseInt(this.activatedRoute.snapshot.paramMap.get('idSector'));
          data.idRoom = parseInt(this.activatedRoute.snapshot.paramMap.get('idRoom'));
          data.isUnidentifiedPatient = this.isUnidentifiedPatient;

          if (this.idCareLinePriority) {
            data.idCareLinePriority = this.idCareLinePriority;
            data.idCareLinePriorityStatus = this.idCareLinePriorityStatus;
          }

          if(!this.idQueue && response.idQueue) this.idQueue = response.idQueue;

          this.utilClassification.updateClassificationData(data);
          this.repopulateHeader.emit();
          if (this.isPreRegister) {
            if (!this.reclassification)
              this.router.navigate(['/classification/protocol-select', { idEpisode: this.idEpisode, idRoom: this.idRoom, idQueue: this.idQueue }]);
            else
              this.router.navigate(['/classification/flowchart-select', { idEpisode: this.idEpisode, idRoom: this.idRoom, idQueue: this.idQueue }]);

            this.setIsNext.emit(true);
          }
        },
        error: (error) => {
          console.log(error);
        }
      });
    }
    else {
      if (this.model.invalid) {
        this.alertService.show('Erro', 'Você precisa informar todos os campos obrigatórios', AlertType.error);
        return;
      }

      let patient = new PatientPatchRequest();
      if (Number.isInteger(this.idPatient))
        patient.idPatient = this.idPatient;

      if (Number.isInteger(this.idEpisode))
        patient.idEpisode = this.idEpisode;

      patient.patientName = this.model.get('patientName').value;
      patient.socialName = this.model.get('socialName').value;
      patient.cpf = this.model.get('cpf').value ? this.model.get('cpf').value.replace(/[^0-9]+/g, '') : "";
      patient.birthDate = this.maskService.formatStringToDate(this.model.get('birthDate').value);
      patient.idGender = this.model.get('idGender').value;
      patient.cns = this.model.get('cns').value;
      patient.metaData = [];
      if (this.model.get('idArrivalType'))
        patient.idArrivalType = parseInt(this.model.get('idArrivalType').value);
      if (this.model.get('idOrigin'))
        patient.idOrigin = parseInt(this.model.get('idOrigin').value);
      patient.priorities = this.model.get('listIdPriority').value;
      patient.motherName = this.model.get('motherName').value;
      this.classificationData.observation = this.model.get('observation').value;
      this.classificationData.listIdPriority = this.model.get('listIdPriority').value;
      this.utilClassification.updateClassificationData(this.classificationData);
      patient.phone = this.model.get('phone').value ? this.model.get('phone').value : null;
      if (this.model.get('notMandatoryCns').value)
        patient.notMandatoryCns = this.model.get('notMandatoryCns').value;
      if (this.model.get('notMandatoryCpf').value)
        patient.notMandatoryCpf = this.model.get('notMandatoryCpf').value;
      patient.idNatureOfAttendance = NatureOfAttendanceEnum.presencial;
      for (let item of Object.keys(patient)) {
        patient[item] = patient[item] ? patient[item] : null;
      }

      if (this.listMetaData && this.listMetaData.length > 0) {
        for (let item of this.listMetaData) {
          const formControl = this.model.get(item.metaData.idMetaData.toString());

          if (formControl && formControl.value) {
            let metadataSend = new MetaDataKeyValueStruct();
            metadataSend.key = item.metaData.idMetaData;
            metadataSend.value = item.metaDataType.idMetaDataType === MetaDataTypeEnum.Numero ?
              formControl.value.toString() : formControl.value;
            patient.metaData.push(metadataSend);
          }
        }
      }

      patient.idSector = this.idSector;
      patient.idService = this.idService;
      this.isLoading = true;
      this.triagePreRegisterService.updatePatient(patient).subscribe({
        next: (response: PatchPatientResponse) => {
          if (response.isError) {
            this.isLoading = false;
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            return;
          }

          this.updateClassificationData(patient, response.idEpisode, response.idPatient, response.datetimeStartTriage);
          if(!this.idQueue && response.idQueue) this.idQueue = response.idQueue;
          this.repopulateHeader.emit();
          if (this.isPreRegister) {
            if (!this.reclassification)
              this.router.navigate(['/classification/protocol-select', { idEpisode: this.idEpisode, idRoom: this.idRoom, idQueue: this.idQueue }]);
            else
              this.router.navigate(['/classification/flowchart-select', { idEpisode: this.idEpisode, idRoom: this.idRoom, idQueue: this.idQueue }]);

            this.setIsNext.emit(true);
          }
        },
        error: (error) => {
          console.log(error);
        }
      });
    }
  }

  calcAgeByAgeGroup(ageGroup: number): Date {
    var supposedBithDate: Date = new Date();
    if (ageGroup == 1) {
      //return { years: 0, month: 0, days: 10 };
      supposedBithDate.setDate(supposedBithDate.getDate() - 5);
    } else if (ageGroup == 2) {
      //return { years: 0, month: 10, days: 0 };
      supposedBithDate.setDate(supposedBithDate.getDate() - 30);
    } else if (ageGroup == 3) {
      //return { years: 10, month: 0, days: 0 };
      supposedBithDate.setDate(supposedBithDate.getDate() - 4000);
    } else if (ageGroup == 4) {
      //return { years: 20, month: 0, days: 0 };
      supposedBithDate.setDate(supposedBithDate.getDate() - 7500);
    }

    return supposedBithDate;
  }

  cpfChangeValidator(event: MatCheckboxChange) {
    if (event.checked) {
      this.model.get('cpf').clearValidators();
      this.model.get('cpf').setValue('');
      this.model.get('cpf').disable();
      this.model.get('cpf').updateValueAndValidity();
    }
    else {
      this.model.get('cpf').setValidators([Validators.required, VerifyCPF()]);
      this.model.get('cpf').enable();
      this.model.get('cpf').updateValueAndValidity();
      this.model.get('notMandatoryCpf').setValue(false);
    }
  }

  cnsChangeValidator(event: MatCheckboxChange) {
    if (event.checked) {
      this.model.get('cns').clearValidators();
      this.model.get('cns').setValue('');
      this.model.get('cns').disable();
      this.model.get('cns').updateValueAndValidity();
    }
    else {
      this.model.get('cns').setValidators([Validators.required, VerifyCNS()]);
      this.model.get('cns').enable();
      this.model.get('cns').updateValueAndValidity();
      this.model.get('notMandatoryCns').setValue(false);
    }
  }

  getDeviceType() {
    this.roomMeasurerService.listRoomMeasurer(this.idRoom).subscribe({
      next: response => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        let uriGlucometer = response.listRoomMeasurer.find(c => c.idGlucometer != 0);
        let uriThermometer = response.listRoomMeasurer.find(c => c.idThermometer != 0);
        let uriPressure = response.listRoomMeasurer.find(c => c.idPressureMeasurer != 0);
        let uriOximeter = response.listRoomMeasurer.find(c => c.idOximeter != 0);
        this.deviceGlucometer = uriGlucometer ? uriGlucometer : null;
        this.deviceThermometer = uriThermometer ? uriThermometer : null;
        this.devicePressure = uriPressure ? uriPressure : null;
        this.deviceOximeter = uriOximeter ? uriOximeter : null;
      }
    });
  }

  calculateAgeGroup(ageGroup: number): any {
    if (ageGroup == 1)
      return { years: 0, month: 0, days: 10 };
    else if (ageGroup == 2)
      return { years: 0, month: 10, days: 0 };
    else if (ageGroup == 3)
      return { years: 10, month: 0, days: 0 };
    else if (ageGroup == 4)
      return { years: 20, month: 0, days: 0 };
  }

  populatePatientData(idEpisode: number) {
    this.episodeService.getPatientByEpisode(idEpisode).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        this.episode = response.episodeStruct;
        if (response.episodeStruct.listAllergy && response.episodeStruct.listAllergy.length > 0)
          this.listAllergy = response.episodeStruct.listAllergy;

        if (this.reclassification && response.episodeStruct.listTriage && response.episodeStruct.listTriage.length > 0) {
          this.reclassificationData = new Reclassification();
          this.reclassificationData.flowchart = response.episodeStruct.listTriage[0].flowchart;
          this.reclassificationData.idGravity = response.episodeStruct.listTriage[0].idGravity;
          this.reclassificationData.idFlowchart = response.episodeStruct.listTriage[0].idFlowchart;
          this.reclassificationData.score = response.episodeStruct.listTriage[0].score != null;
        }

        if (response.patient != null && response.patient.idPatient != null) {
          if (!response.episodeStruct.isUnidentifiedPatient) {
            if (this.isPreRegister)
              this.openCofirmDataModal(response.patient, response.episodeStruct);

            this.mapModel(response.patient);
          }
        }
        else if (this.isPreRegister)
          this.openModalSearchByCPF();

        if (response.priorities != null && response.priorities.length > 0)
          this.model.get('listIdPriority').setValue(response.priorities);

        this.model.get('unidentifiedPatient').setValue(response.episodeStruct.isUnidentifiedPatient);
        this.model.get('unidentifiedPatientDescription').setValue(response.episodeStruct.unidentifiedPatientDescription);
        this.model.get('ageGroup').setValue(response.episodeStruct.idAgeGroup);
        this.changeIndigent({ checked: response.episodeStruct.isUnidentifiedPatient });
    
        if (response.episodeStruct.idCareLinePriority) {
          this.idCareLinePriority = response.episodeStruct.idCareLinePriority;
          this.idCareLinePriorityStatus = response.episodeStruct.idCareLinePriorityStatus;
        }

        this.isFirstLoading = false;
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  mapModel(patient: PatientStruct) {
    this.classificationData = this.utilClassification.getClassificationData();

    this.idPatient = patient.idPatient;
    this.model.get('patientName').setValue((this.setUppercaseText && patient.patientName) ? patient.patientName.toUpperCase() : patient.patientName);
    this.model.get('socialName').setValue((this.setUppercaseText && patient.socialName) ? patient.socialName.toUpperCase() : patient.socialName);
    this.model.get('cpf').setValue(patient.cpf);
    this.model.get('observation').setValue((this.setUppercaseText && this.classificationData.observation) ? this.classificationData.observation.toUpperCase() : this.classificationData.observation);
    let birthDate = this.maskService.formatDateToString(patient.birthDate, false);
    this.model.get('birthDate').setValue(birthDate);
    if (birthDate) {
      this.calcAgeService.getAge(birthDate).subscribe({
        next: (calcAgeResponse: CalcAgeResponse) => {
          this.ageYear = calcAgeResponse.year;
          this.ageMonth = calcAgeResponse.months;
          this.ageDay = calcAgeResponse.days;
          this.model.get('patientAge').setValue(`${calcAgeResponse.year}a ${calcAgeResponse.months}m ${calcAgeResponse.days}d`);
        }
      });
    }

    this.model.get('idGender').setValue(patient.idGender ? patient.idGender.toString() : null);
    this.model.get('cns').setValue(patient.cns?.replaceAll(" ", ""));
    this.model.get('motherName').setValue((this.setUppercaseText && patient.motherName) ? patient.motherName.toUpperCase() : patient.motherName);
    this.model.get('phone').setValue(patient.phone1?.replaceAll(" ", "").replaceAll("(", "").replaceAll(")", "").replaceAll("-", ""));
    if (patient.idArrivalType != null)
      this.model.get('idArrivalType').setValue(patient.idArrivalType);
    if (patient.idOrigin != null)
      this.model.get('idOrigin').setValue(patient.idOrigin);

    if (this.listMetaData && this.listMetaData.length > 0 && !this.isPreRegister) {
      for (let item of this.listMetaData) {
        let valuePatient = patient.metaData.find(c => c.key == item.metaData.idMetaData);
        if (valuePatient)
          this.model.get(item.metaData.idMetaData.toString()).setValue((this.setUppercaseText && valuePatient.value) ? valuePatient.value.toUpperCase() : valuePatient.value);
        // else
        //   this.model.get(item.metaData.idMetaData.toString()).setValue(null);
      }
    }
    else
      this.searchByCpfOrCns = false;
  }

  verifyRequiredFields() {
    if (this.listSectorRequiredField != null) {

      let listIdRequiredField = this.listSectorRequiredField.map(x => x.idPreAdmissionRequiredField);

      if (listIdRequiredField.includes(PreAdmissionRequirableFieldsEnum.cpf)) {
        this.model.get('cpf').setValidators([Validators.required, VerifyCPF()]);
        this.model.get('cpf').updateValueAndValidity();
        this.showMandatoryCpfCheckbox = true;
      }

      if (listIdRequiredField.includes(PreAdmissionRequirableFieldsEnum.cns)) {
        this.model.get('cns').setValidators([Validators.required, VerifyCNS()]);
        this.model.get('cns').updateValueAndValidity();
        this.showMandatoryCnsCheckbox = true;
      }

      if (listIdRequiredField.includes(PreAdmissionRequirableFieldsEnum.genero)) {
        this.model.get('idGender').setValidators(Validators.required);
        this.model.get('idGender').updateValueAndValidity();
      }

      if (listIdRequiredField.includes(PreAdmissionRequirableFieldsEnum.nomeMae)) {
        this.model.get('motherName').setValidators(Validators.required);
        this.model.get('motherName').updateValueAndValidity();
      }

      if (listIdRequiredField.includes(PreAdmissionRequirableFieldsEnum.phone)) {
        this.model.get('phone').setValidators([Validators.required, VerifyPhoneNumber()]);
        this.model.get('phone').updateValueAndValidity();
      }

      if (listIdRequiredField.includes(PreAdmissionRequirableFieldsEnum.tipoDeChegada)) {
        this.model.get('idArrivalType').setValidators(Validators.required);
        this.model.get('idArrivalType').updateValueAndValidity();
      }

      if (listIdRequiredField.includes(PreAdmissionRequirableFieldsEnum.origem)) {
        this.model.get('idOrigin').setValidators(Validators.required);
        this.model.get('idOrigin').updateValueAndValidity();
      }
    }

    if (this.listMetaData && this.listMetaData.length > 0) {
      for (let item of this.listMetaData) {
        if (item.metaData.isRequired) {
          this.model.addControl(
            item.metaData.idMetaData.toString(),
            this.formBuilder.control('', [Validators.required])
          );
        }
        else
          this.model.addControl(item.metaData.idMetaData.toString(), this.formBuilder.control(''));
      }
    }
    if (this.listIdMetaDataRequired != undefined && this.listIdMetaDataRequired.length > 0) {
      for (let item of this.listIdMetaDataRequired) {
        if (this.model.controls[item]) { // Check if the control exists before setting validators
          this.model.controls[item].setValidators([Validators.required]);
          this.model.controls[item].updateValueAndValidity();
        }
      }
    }
  }

  populateLookupSelect() {
    this.lookupService.getLookup().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.genderList = response.listGenders;
        this.listAgeGroup = response.listAgeGroup;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getArrivalTypeAndOrigin() {
    this.GroupOriginByOrchestratorService.getGroupOrigin().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        this.originList = response.listOrigin;
        this.arrivalTypeList = response.listArrivalType;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getAllPriorities() {
    this.priorityService.listPriority().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listOfPriorities = response.listPriority;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  searchByCpfCns(cpf: string, cns: string) {
    this.isLoading = true;

    let request: SearchPatientTotemRequest = new SearchPatientTotemRequest();

    if (cpf) request.cpf = cpf.replace(/[^0-9]/g, '').replace(' ', '');
    if (cns) request.cns = cns.replace(/[^0-9]/g, '').replace(' ', '');

    this.searchPatientService.searchPatientCpfCns(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listAllergy = response.listPatient[0].listAllergy;

        let classificationData: ClassificationData = this.classificationData;

        if (classificationData != null)
          classificationData.listAllergy = this.listAllergy;
        else {
          classificationData = new ClassificationData();
          classificationData.listAllergy = this.listAllergy;
        }

        this.utilClassification.updateClassificationData(classificationData);

        this.mapModel(response.listPatient[0]);
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  private timeoutKeySearch: any = null;

  onKeySearchCPF(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    let cpf = event.target.value.replace(/[^0-9]+/g, '');
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13 && cpf.length == 11)
        $this.searchByCpfCns(cpf.replace(/[^0-9]+/g, ''), null);
    }, 1000);
  }

  onKeySearchCNS(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13 && event.target.value.length > 5)
        $this.searchByCpfCns(null, event.target.value);
    }, 1000);
  }

  openModal(patients: PatientStruct[]) {
    const dialogRef = this.dialog.open(SelectPatientModalComponent, {
      data: patients,
    });
    dialogRef.afterClosed().subscribe({
      next: (result: PatientStruct) => {
        if (result) {
          this.mapModel(result);
          this.listAllergy = result.listAllergy;

          let classificationData: ClassificationData = this.classificationData;

          if (classificationData != null)
            classificationData.listAllergy = this.listAllergy;
          else {
            classificationData = new ClassificationData();
            classificationData.listAllergy = this.listAllergy;
          }
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  openModalSearchByCPF() {
    const dialogRef = this.dialog.open(SearchModalComponent, {
      data: {
        ticket: (this.episode && this.episode.ticketInitials) ? this.episode.ticketInitials + this.episode.ticketSequence : null
      }
    });
    dialogRef.afterClosed().subscribe({
      next: (response) => {
        if (response) {
          if (response.response) {
            this.model.get("unidentifiedPatient").setValue(false);
            this.isUnidentifiedPatient = false;
            this.model.get('unidentifiedPatientDescription').setValidators(Validators.nullValidator);
            this.model.get('ageGroup').setValidators(Validators.nullValidator);
            this.model.get('patientName').setValidators(Validators.required);
            this.model.get('birthDate').setValidators([Validators.required, birthdateValidator()]);
            this.model.get('unidentifiedPatientDescription').updateValueAndValidity();
            this.model.get('ageGroup').updateValueAndValidity();
            this.model.get('patientName').updateValueAndValidity();
            this.model.get('birthDate').updateValueAndValidity();
            this.searchByCpfOrCns = true;

            this.mapModel(response.response);

            this.listAllergy = response.response.listAllergy;

            let classificationData: ClassificationData = this.classificationData;

            if (classificationData != null)
              classificationData.listAllergy = this.listAllergy;
            else {
              classificationData = new ClassificationData();
              classificationData.listAllergy = this.listAllergy;
            }

            this.utilClassification.updateClassificationData(classificationData);
          }
          else if (response.cpf) this.model.get('cpf').setValue(response.cpf);
          else if (response.cns) this.model.get('cns').setValue(response.cns?.replaceAll(" ", ""));
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
    return dialogRef;
  }

  onKeySearchAge(event: any) {
    if (this.model.get('birthDate').invalid) return;

    if (event.target.value.length == 10) {
      this.calcAgeService.getAge(event.target.value).subscribe((calcAgeResponse: CalcAgeResponse) => {
        this.ageYear = calcAgeResponse.year;
        this.ageMonth = calcAgeResponse.months;
        this.ageDay = calcAgeResponse.days;
        this.model.get('patientAge').setValue(`${calcAgeResponse.year}a ${calcAgeResponse.months}m ${calcAgeResponse.days}d`);
      });
    }
  }

  changeIndigent(event: any) {
    this.isUnidentifiedPatient = event.checked;
    if (this.isUnidentifiedPatient) {
      this.model.get('unidentifiedPatientDescription').setValidators(Validators.required);
      this.model.get('ageGroup').setValidators(Validators.required);
      this.model.get('patientName').setValidators(Validators.nullValidator);
      this.model.get('birthDate').setValidators(Validators.nullValidator);
    }
    else {
      this.model.get('unidentifiedPatientDescription').setValidators(Validators.nullValidator);
      this.model.get('ageGroup').setValidators(Validators.nullValidator);
      this.model.get('patientName').setValidators(Validators.required);
      this.model.get('birthDate').setValidators([Validators.required, birthdateValidator()]);
    }

    this.model.get('unidentifiedPatientDescription').updateValueAndValidity();
    this.model.get('ageGroup').updateValueAndValidity();
    this.model.get('patientName').updateValueAndValidity();
    this.model.get('birthDate').updateValueAndValidity();
  }

  validatePriorities(priorities: any) {
    let listIdPriority = priorities.value;

    if (listIdPriority.includes(PrioritiesStatusEnum.MaiorDe80Anos.valueOf()) ||
      listIdPriority.includes(PrioritiesStatusEnum.MaiorDe80Anos.valueOf().toString()) ||
      listIdPriority.includes(PrioritiesStatusEnum.MaiorDe60Anos.valueOf()) ||
      listIdPriority.includes(PrioritiesStatusEnum.MaiorDe60Anos.valueOf().toString())) {

      this.disallowedIdPriorities = [PrioritiesStatusEnum.MenorDe1Ano.valueOf()];

      this.model.get('listIdPriority').setValue(listIdPriority.filter((x: { idPriority: number; }) => !this.disallowedIdPriorities.includes(x.idPriority)));
    }
    else if (listIdPriority.includes(PrioritiesStatusEnum.MenorDe1Ano.valueOf()) ||
      listIdPriority.includes(PrioritiesStatusEnum.MenorDe1Ano.valueOf().toString())) {

      this.disallowedIdPriorities = [
        PrioritiesStatusEnum.MaiorDe60Anos.valueOf(),
        PrioritiesStatusEnum.MaiorDe80Anos.valueOf()];

      this.model.get('listIdPriority').setValue(listIdPriority.filter((x: { idPriority: number; }) => !this.disallowedIdPriorities.includes(x.idPriority)));
    }
    else
      this.disallowedIdPriorities = [];
  }

  public get metaDataTypeEnumResult(): typeof MetaDataTypeEnum {
    return MetaDataTypeEnum;
  }

  verifyDate(controlId: number): void {
    const control = this.model.get(controlId.toString()); // Transforme o número em uma string
    const validationError = VerifyDate()(control);

    if (validationError)
      control.setErrors({ ...control.errors, ...validationError });
    else
      control.setErrors(null);
  }

  openCofirmDataModal(patient: PatientStruct, episode: EpisodeStruct) {

    const dialogRef = this.dialog.open(ConfirmPatientDataModalComponent, {
      data: {
        patient: patient,
        episode: episode
      },
      panelClass: "confirm-patient",
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (!result || !result.confirm) {
          this.setIsNext.emit(false);
          this.router.navigate(['/classification/classification-patient-list', { idSector: this.idSector, idRoom: this.idRoom }]);
        }
        else if (result && result.confirm && this.skipIdentifiedPatientPreRegister) {
          this.setIsNext.emit(true);
          this.updateClassificationData(patient, episode.idEpisode, episode.idPatient, new Date());
          if (!this.reclassification)
            this.router.navigate(['/classification/protocol-select', { idEpisode: this.idEpisode, idRoom: this.idRoom, idQueue: this.idQueue }]);
          else
            this.router.navigate(['/classification/flowchart-select', { idEpisode: this.idEpisode, idRoom: this.idRoom, idQueue: this.idQueue }]);
        }
      }
    });
  }

  goBack() {
    this.setIsNext.emit(false);
    this.router.navigate(['/classification/classification-patient-list', { idSector: this.idSector, idRoom: this.idRoom }]);
  }

  controlFormEnable() {
    this.model.get('patientName').enable();
    this.model.get('socialName').enable();
    this.model.get('cpf').enable();
    this.model.get('birthDate').enable();
    this.model.get('idGender').enable();
    this.model.get('cns').enable();
    this.model.get('listIdPriority').enable();
    this.model.get('unidentifiedPatientDescription').enable();
    this.model.get('ageGroup').enable();
    this.model.get('unidentifiedPatient').enable();
    this.model.get('motherName').enable();
    this.model.get('notMandatoryCpf').enable();
    this.model.get('notMandatoryCns').enable();
    this.model.get('phone').enable();
    this.model.get('observation').enable();
    this.model.get('idArrivalType').enable();
    this.model.get('idOrigin').enable();
    if (this.listMetaData && this.listMetaData.length > 0) {
      for (let item of this.listMetaData) {
        this.model.get(item.metaData.idMetaData.toString()).enable();
      }
    }
    this.edited = true;
    this.emitEdition.emit(this.edited);
  }

  controlFormDisable() {
    this.model.get('patientName').disable();
    this.model.get('socialName').disable();
    this.model.get('cpf').disable();
    this.model.get('birthDate').disable();
    this.model.get('idGender').disable();
    this.model.get('cns').disable();
    this.model.get('listIdPriority').disable();
    this.model.get('unidentifiedPatientDescription').disable();
    this.model.get('ageGroup').disable();
    this.model.get('unidentifiedPatient').disable();
    this.model.get('motherName').disable();
    this.model.get('notMandatoryCpf').disable();
    this.model.get('notMandatoryCns').disable();
    this.model.get('phone').disable();
    this.model.get('observation').disable();
    this.model.get('idArrivalType').disable();
    this.model.get('idOrigin').disable();
    if (this.listMetaData && this.listMetaData.length > 0) {
      for (let item of this.listMetaData) {
        this.model.get(item.metaData.idMetaData.toString()).disable();
      }
    }
    this.edited = false;
  }

  updateClassificationData(patient, idEpisode: number, idPatient: number, datetimeStartTriage: Date) {
    let data: ClassificationData = this.classificationData;
    data.reclassification = this.reclassificationData;
    data.isReclassification = this.reclassification;
    data.idEpisode = idEpisode;
    data.idPatient = idPatient;
    data.datetimeStartTriage = datetimeStartTriage;
    data.idGender = patient.idGender;
    data.birthDate = patient.birthDate;
    data.newPatient = this.newPatient;
    data.ageYears = this.ageYear;
    data.ageMonth = this.ageMonth;
    data.ageDay = this.ageDay;
    data.observation = this.model.get('observation').value;
    data.idDeviceType = this.idDeviceType;
    // data.uriGlucometer = this.uriGlucometer;
    // data.uriThermometer = this.uriThermometer;
    // data.uriPressure = this.uriPressure;
    // data.uriOximeter = this.uriOximeter;
    data.deviceGlucometer = this.deviceGlucometer;
    data.deviceThermometer = this.deviceThermometer;
    data.devicePressure = this.devicePressure;
    data.deviceOximeter = this.deviceOximeter;
    data.listAllergy = this.listAllergy;
    data.listIdPriority = this.model.get('listIdPriority').value;
    data.idSector = parseInt(this.activatedRoute.snapshot.paramMap.get('idSector'));
    data.idRoom = parseInt(this.activatedRoute.snapshot.paramMap.get('idRoom'));
    data.idOrigin = patient.idOrigin;
    data.idArrivalType = patient.idArrivalType;
    data.isUnidentifiedPatient = this.isUnidentifiedPatient;
    if (this.idCareLinePriority) {
      data.idCareLinePriority = this.idCareLinePriority;
      data.idCareLinePriorityStatus = this.idCareLinePriorityStatus;
    }

    this.utilClassification.updateClassificationData(data);
  }
}