import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-confirm-change-type-modal',
  templateUrl: './confirm-change-type-modal.component.html',
  styleUrls: ['./confirm-change-type-modal.component.css']
})
export class ConfirmChangeTypeModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<ConfirmChangeTypeModalComponent>,
  private alertService: AlertService) { }


  ngOnInit(): void {
  }
  
  close(){
    this.matDialogRef.close({changeDataSource: false}); 
  }
  
  clickChange(){ 
    this.matDialogRef.close({changeDataSource: true}); 
  }
  
  clickCancel(){
    this.matDialogRef.close({changeDataSource: false}); 
  }
}

