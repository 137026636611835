import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-presentation-type-confirm-modal',
  templateUrl: './presentation-type-confirm-modal.component.html',
  styleUrls: ['./presentation-type-confirm-modal.component.css']
})
export class PresentationTypeConfirmModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<PresentationTypeConfirmModalComponent>,) { }

  ngOnInit(): void {
  }

  clickConfirm() {
    this.matDialogRef.close({ confirm: true });
  }

  clickCancel() {
    this.matDialogRef.close({ confirm: false });
  }
}