import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { UtilsClientService } from '../../utils.service';
import { ClientHealthUnitService } from 'src/app/shared/services/API/user/client-health-unit.service';
import { HealthUnitStruct } from 'src/app/shared/services/structs/user/health-unit.struct';
import { AuthService } from 'src/app/shared/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ClientTotemNpsService } from 'src/app/shared/services/API/nps/client-totem-nps.service';
import { TotemNps } from 'src/app/shared/services/models/nps/totem-nps.model';
import { TotemLoginRequest } from 'src/app/shared/services/requests/totem/totem-login.request';
import { TotemNpsLoginRequest } from 'src/app/shared/services/requests/nps/totem-nps-login.request';
import { SecurityAlertModalComponent } from 'src/app/shared/components/security-alert-modal/security-alert-modal.component';
import { UtilService } from 'src/app/shared/services/util.service';


@Component({
  selector: 'app-nps-totem',
  templateUrl: './nps-totem.component.html',
  styleUrls: ['./nps-totem.component.css']
})
export class NpsTotemComponent implements OnInit, OnDestroy {

  constructor(
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private utilsClientService: UtilsClientService,
    private utilsService: UtilService,
    public dialog: MatDialog,
    private authService: AuthService,
    private cdref: ChangeDetectorRef,
    private clientHealthUnitService: ClientHealthUnitService,
  ) { }

  public authenticated: boolean = false;
  public logged: boolean = false;
  public isLoading: boolean = false;
  public state: string = 'options';
  public ip: string = '192.168.0.2';

  public totemName: string;
  public pin: string;
  public totemNps: TotemNps;
  public idTotem: number;
  public idHealthUnit: number;
  public onlineOffline: boolean = navigator.onLine;
  public healthUnitStruct: HealthUnitStruct;
  public isToRequestCpf: boolean = false;
  public cpf: string;

  ngOnInit(): void {
    this.idTotem = parseInt(this.activatedRoute.snapshot.paramMap.get('id'));

    setTimeout(() => { this.getIP(); }, 2000);
  }

  private intervalId = setInterval(() => {
    if (this.onlineOffline !== navigator.onLine)
      this.onlineOffline = navigator.onLine;
  }, 1000);

  ngOnDestroy() {
    clearInterval(this.intervalId);
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  checkPin(event) {
    this.pin = event;
    this.isLoading = true;

    let request: TotemNpsLoginRequest = new TotemNpsLoginRequest();
    request.idTotemNps = this.idTotem;
    request.pin = this.pin;
    request.ip = this.ip;

    this.authService.signInTotemNps(request).subscribe({
      next: (response) => {
        if (response.isError) {

          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          this.utilsClientService.removeTotemPin();
          if (response.errorCode === 2) {
            this.openSecurityAlertModal();
          }
          return;
        }

        this.totemNps = response.totemNps
        this.authService.updateTotemNpsToken(response);
        this.alertService.show('Sucesso', 'PIN autenticado com sucesso', AlertType.success);
        this.isLoading = false;
        this.getHealthUnit();

        this.totemName = response.totemNps.totemNpsName;
        this.authenticated = true;
        this.idHealthUnit = response.totemNps.idHealthUnit;
        this.isToRequestCpf = response.totemNps.isToRequestCpf;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.utilsClientService.removeTotemPin();
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getIP() {
    this.utilsService.getIP().subscribe({
      next: (response) => {
        if (response && response.ip) {
          this.ip = response.ip;
        }
      }
    });
  }

  checkLogin(event) {
    this.logged = event.find;
    this.cpf = event.cpf;
  }

  checkLoggout() {
    this.logged = false;
    this.authService.logout();
    if (!this.utilsClientService.getTotemPin())
      this.authenticated = false;
  }

  getHealthUnit() {
    this.clientHealthUnitService.getHealthUnit(this.totemNps.idHealthUnit).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.healthUnitStruct = response.healthUnitStruct;
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  checkEnd(event) {
    if (event) {
      this.authenticated = false;
      this.logged = false;
    }
  }

  openSecurityAlertModal() {
    const dialogRef = this.dialog.open(SecurityAlertModalComponent, {
      data: {
        equipment: 'totem'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }
}