import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { VariablesDataSourceEnum } from 'src/app/shared/enum/variables-data-source.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';

@Component({
  selector: 'app-column-rules',
  templateUrl: './column-rules.component.html',
  styleUrls: ['./column-rules.component.css']
})
export class ColumnRulesComponent implements OnInit {

  @Input() formGroup: UntypedFormGroup;
  @Input() formArrayName: string;
  @Input() listLogicalExpression: any;
  @Input() listVariable: any = [];
  @Input() listConjunction: any;
  @Input() arrayVariables: Array<any> = [];

  public numberVariable: VariablesDataSourceEnum = VariablesDataSourceEnum.number;
  public selectVariable: VariablesDataSourceEnum = VariablesDataSourceEnum.select;
  public datetimeVariable: VariablesDataSourceEnum = VariablesDataSourceEnum.datetime;
  public timeVariable: VariablesDataSourceEnum = VariablesDataSourceEnum.time;
  public boolVariable: VariablesDataSourceEnum = VariablesDataSourceEnum.bool;
  public textVariable: VariablesDataSourceEnum = VariablesDataSourceEnum.text;
  public ageVariable: VariablesDataSourceEnum = VariablesDataSourceEnum.age;

  public masks: Masks;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private maskService: MaskService,
    private alertService: AlertService,
  ) { }

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
  }

  changeVariableType(event: any, i: number) {
    let item = this.listVariable.findIndex(c => c.idDataSourceVariable == event.value);
    this.arrayVariables[i] = { type: this.listVariable[item].idVariableType, index: item };
  }

  createInput() {
    return this.formBuilder.group({
      idConjunction: [''],
      idVariable: [''],
      idLogicalExpression: [''],
      value: [''],
    })
  }

  addNext() {
    this.arrayVariables.push(null);
    (this.formGroup.controls[this.formArrayName] as UntypedFormArray).push(this.createInput())
  }

  removeExpression(index: number) {
    this.arrayVariables.splice(index, 1);
    (this.formGroup.controls[this.formArrayName] as UntypedFormArray).removeAt(index)
  }

  validaAge(value) {
    if (!value) {
      return;
    }
    if (!value.includes("a") || !value.includes("m") || !value.includes("d")) {
      this.alertService.show('Erro', "O padrão do campo idade é 010(a)nos 01(m)eses 02(d)ias.", AlertType.warning);
      return false;
    }
    let splitYears = value.split("a");
    let splitMonth = splitYears[1].split("m");
    let days: string = splitMonth[1].replace("d");
    let month: string = splitMonth[0];
    let years: string = splitYears[0];

    if (parseInt(days) > 29) {
      this.alertService.show('Erro', "O valor máximo de dias do campo idade é 29, após deve ser adicionado no mês!", AlertType.error);
      return false;
    }
    if (parseInt(month) > 11) {
      this.alertService.show('Erro', "O valor máximo de meses do campo idade é 11, após deve ser adicionado no ano!", AlertType.error);
      return false;
    }
    return true;
  }
}
