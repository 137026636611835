import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ProcedureStatusEnum } from "src/app/shared/enum/procedure-status.enum";
import { AlertService, AlertType } from "src/app/shared/services/alert.service";
import { UserGetNameService } from "src/app/shared/services/API/user/user-get-name.service";
import { MedicalProcedureStruct } from "src/app/shared/services/structs/medical-record/medical-procedure.struct";
import { MedicalProcedureCheckStruct } from "src/app/shared/services/structs/orchestrator-patient/medical-procedure-check.struct";

@Component({
  selector: 'app-medical-procedure-modal',
  templateUrl: './medical-procedure-modal.component.html',
  styleUrls: ['./medical-procedure-modal.component.css']
})
export class MedicalProcedureModalComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<MedicalProcedureModalComponent>,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    public userGetNameService: UserGetNameService,
  ) { }

  public model: UntypedFormGroup;
  public isLoading: boolean;
  public isReadonly: boolean;
  public checkProcedure: boolean;

  public checkDatetimeFulfilled: Date;
  public checkProfessionName: string;
  public checkUserName: string;
  public procedureStatusClosed: ProcedureStatusEnum.finalizado;

  public procedure: MedicalProcedureStruct;
  public medicalProcedureCheck: MedicalProcedureCheckStruct;

  public closedStatus: ProcedureStatusEnum.finalizado;

  ngOnInit(): void {
    this.isLoading = false;
    this.isReadonly = false;
    this.checkProcedure = false;
    this.procedure = this.data.procedure;
    this.medicalProcedureCheck = this.procedure.medicalProcedureCheck;
    this.model = this.formBuilder.group({checkName:[false], checkProcedure:[false]
    });

    if(this.medicalProcedureCheck){
      this.populateProcedureCheck();
    }
  }

  getProcedureCheckData() {
    this.checkDatetimeFulfilled = this.medicalProcedureCheck.datetimeCheckFulfilled;
    this.checkProfessionName = this.medicalProcedureCheck.professionName;
    this.checkUserName = this.medicalProcedureCheck.userName;
  }

  populateProcedureCheck() {
    this.model.get('checkName').setValue(this.medicalProcedureCheck.checkName);
    this.model.get('checkProcedure').setValue(this.medicalProcedureCheck.checkProcedure);

    this.isReadonly = this.data.procedure.idProcedureStatus == this.procedureStatusClosed;
    if (this.isReadonly) {
      this.model.get('checkName').setValue(true);
      this.model.get('checkProcedure').setValue(true);
      this.model.get('checkName').disable();
      this.model.get('checkProcedure').disable();
    }

    this.getProcedureCheckData();
  }

  close() {
    this.matDialogRef.close();
  }
}