<div class="container-generic">

    <app-menu [isClassification]="true" [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'
        [hasSecundaryHeader]="true" [episode]="idEpisode" [hasCachedModule]="true"
        [cachedModuleName]="'classification'" [discriminators]="this.data.discriminatorValues" (restartMeasurers)="restartMeasurers()"></app-menu>
    
    <div [ngClass]="{'container-body-secundary-header': idEpisode !== null, 'container-body': idEpisode === null}">
        <app-flowchart-selection-default (selectedFlowchart)="selectFlowchart($event)"  *ngIf="!isReclassification || idGravity == idGravityWhite"></app-flowchart-selection-default>
        <app-flowchart-selection-reclassification (selectedFlowchart)="selectFlowchart($event)" *ngIf="isReclassification && idGravity != idGravityWhite"></app-flowchart-selection-reclassification>
    </div>
    
</div>