import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, UntypedFormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { CareLineService } from 'src/app/shared/services/API/care-line/care-line.service';
import { HealthUnitTwilioService } from 'src/app/shared/services/API/care-line/health-unit-twilio.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { CareLineModel } from 'src/app/shared/services/models/care-line/care-line.model';
import { HealthUnitTwilioAccount } from 'src/app/shared/services/models/care-line/health-unit-twilio-account.model';
import { CareLineRequest } from 'src/app/shared/services/requests/care-line/create-care-line.request';
import { HealthUnitTwilioAccountRequest } from 'src/app/shared/services/requests/care-line/health-unit-twilio-account.request';
import { CareLineStruct } from 'src/app/shared/services/structs/care-line/care-line.struct';
import { FlowResourceStruct } from 'src/app/shared/services/structs/care-line/flow-resource.struct';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-care-line-register',
  templateUrl: './care-line-register.component.html',
  styleUrls: ['./care-line-register.component.css']
})
export class CareLineRegisterComponent implements OnInit {
  constructor(
    private router: Router,
    private alertService: AlertService,
    private utilService: UtilService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private careLineService: CareLineService,
    private healthUnitTwilioService: HealthUnitTwilioService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.care_line_monitoring;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.care_line_monitoring_register;
  public isFirstLoading = true;
  public isLoading = false;
  public model: FormGroup;
  public accountModel: FormGroup;
  public isUpdate: boolean = false;
  public listCareLines: CareLineModel[] = [];
  public twilioFlows: FlowResourceStruct[] = [];
  public boundTwilioFlows: string[] = [];
  public boundAccount = false;
  public editTwilioData = false;

  ngOnInit(): void {
    this.accountModel = this.formBuilder.group({
      twilioAccountSid: ['', [Validators.required]],
      twilioAccountToken: ['', [Validators.required]],
      twilioPhoneNumber: [''],
      twilioMessagingServiceSid: [''],
    }, {
      validators: [this.VerifyAccountModel()],
      updateOn: 'change'
    });

    this.model = this.formBuilder.group({
      listCareLine: this.formBuilder.array([]),
    });

    this.populateFlows();
    this.isFirstLoading = false;
  }

  VerifyAccountModel(): ValidatorFn {
    return (accountModel: FormGroup): ValidationErrors | null => {
      if (!accountModel || !accountModel.value || !accountModel.touched) {
        return null;
      }
      // const value = accountModel.value;

      let twilioPhoneNumber: AbstractControl = accountModel.get('twilioPhoneNumber');
      let twilioMessagingServiceSid: AbstractControl = accountModel.get('twilioMessagingServiceSid');

      let isValid: boolean = true;

      let error = { invalidPhoneInfo: true };

      if (!twilioPhoneNumber.value && !twilioMessagingServiceSid.value) {
        isValid = false;
        twilioPhoneNumber.setErrors(error);
        twilioMessagingServiceSid.setErrors(error);
        twilioPhoneNumber.markAsTouched();
        twilioMessagingServiceSid.markAsTouched();
      }
      else {
        isValid = true;
        twilioPhoneNumber.setErrors(null);
        twilioMessagingServiceSid.setErrors(null);
      }

      return isValid ? null : error;
    }
  }

  toggleEditTwilioData() {
    this.editTwilioData = !this.editTwilioData;

    if (this.editTwilioData) {
      this.accountModel.get('twilioAccountSid').enable();
      this.accountModel.get('twilioAccountToken').enable();
      this.accountModel.get('twilioPhoneNumber').enable();
      this.accountModel.get('twilioMessagingServiceSid').enable();
    }
    else {
      this.accountModel.get('twilioAccountSid').disable();
      this.accountModel.get('twilioAccountToken').disable();
      this.accountModel.get('twilioPhoneNumber').disable();
      this.accountModel.get('twilioMessagingServiceSid').disable();
    }
  }

  populateFlows() {
    this.isLoading = true;
    this.model = this.formBuilder.group({
      listCareLine: this.formBuilder.array([]),
    });
    this.healthUnitTwilioService.ListFlows().subscribe({
      next: (response) => {
        if (response.isError) {
          if (response.errorCode === -1) {
            this.alertService.show('Aviso', response.errorDescription, AlertType.warning);
          }
          else {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);

          }
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.twilioFlows = response.listFlow;
        this.boundAccount = response.boundAccount;
        this.editTwilioData = !this.boundAccount;

        if (this.boundAccount) {
          this.populateCareLines();
          this.accountModel.get('twilioAccountSid').setValue(response.twilioAccountSid);
          this.accountModel.get('twilioAccountSid').disable();
          this.accountModel.get('twilioAccountSid').updateValueAndValidity();

          this.accountModel.get('twilioAccountToken').setValue(response.twilioAccountToken);
          this.accountModel.get('twilioAccountToken').disable();
          this.accountModel.get('twilioAccountToken').updateValueAndValidity();

          this.accountModel.get('twilioPhoneNumber').setValue(response.twilioPhoneNumber);
          this.accountModel.get('twilioPhoneNumber').disable();
          this.accountModel.get('twilioPhoneNumber').updateValueAndValidity();

          this.accountModel.get('twilioMessagingServiceSid').setValue(response.twilioMessagingServiceSid);
          this.accountModel.get('twilioMessagingServiceSid').disable();
          this.accountModel.get('twilioMessagingServiceSid').updateValueAndValidity();
        }

      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  createInput(careLine: CareLineModel = null, twilioFlow: FlowResourceStruct = null) {
    if (careLine != null) {
      return this.formBuilder.group({
        idCareLine: [careLine.idCareLine],
        careLineName: [{ value: careLine.careLineName, disabled: !careLine.isActive }, [Validators.required]],
        isActive: [careLine.isActive],
        twilioFlowSid: [careLine.twilioFlowSid],
        twilioFlowName: [{ value: careLine.twilioFlowName, disabled: true }],
      });
    }
    else if (twilioFlow != null) {
      return this.formBuilder.group({
        idCareLine: [null],
        careLineName: { value: null, disabled: true },
        isActive: [false],
        twilioFlowSid: [twilioFlow.sid],
        twilioFlowName: [twilioFlow.friendlyName],
      });
    }

  }

  addNext(index: number, careLine: CareLineModel = null, twilioFlow: FlowResourceStruct = null) {
    if (careLine != null) {
      (this.model.controls['listCareLine'] as FormArray).insert(index + 1, this.createInput(careLine, null))
    }
    else if (twilioFlow != null) {
      (this.model.controls['listCareLine'] as FormArray).insert(index + 1, this.createInput(null, twilioFlow))
    }
    else {
      (this.model.controls['listCareLine'] as FormArray).insert(index + 1, this.createInput())
    }
  }

  remove(index: number) {
    (this.model.controls['listCareLine'] as FormArray).removeAt(index);
  }

  saveTwilioData() {
    if (this.accountModel.invalid) {
      this.alertService.show('Aviso', 'Preencha todos os campos em vermelho', AlertType.warning);
      return;
    }
    let request: HealthUnitTwilioAccountRequest = new HealthUnitTwilioAccountRequest();

    request.twilioAccountSid = this.accountModel.get('twilioAccountSid').value;
    request.twilioAccountToken = this.accountModel.get('twilioAccountToken').value;
    request.twilioPhoneNumber = this.accountModel.get('twilioPhoneNumber').value;
    request.twilioMessagingServiceSid = this.accountModel.get('twilioMessagingServiceSid').value;

    this.healthUnitTwilioService.CreateAccountIntegration(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        this.populateFlows();
        this.alertService.show('Sucesso', 'Dados cadastrados com sucesso!', AlertType.success);
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  submit() {
    if (this.model.invalid) {
      this.alertService.show('Aviso', 'Preencha todos os campos em vermelho', AlertType.warning);
      return;
    }
    let request: CareLineRequest = new CareLineRequest();
    request.listCareLineToCreate = [];
    request.listCareLineToUpdate = [];

    let listForm = this.model.getRawValue().listCareLine;

    let size: number = listForm.length;

    for (let i = 0; i < size; i++) {
      let careLineForm = listForm[i];
      if (careLineForm.idCareLine != null) {
        let careLineModel: CareLineModel = new CareLineModel();

        careLineModel.idCareLine = careLineForm.idCareLine;
        careLineModel.careLineName = careLineForm.careLineName;
        careLineModel.isActive = careLineForm.isActive;
        careLineModel.twilioFlowSid = careLineForm.twilioFlowSid;
        careLineModel.twilioFlowName = careLineForm.twilioFlowName;

        request.listCareLineToUpdate.push(careLineModel);
      }

      else if (careLineForm.isActive) {
        let careLineStruct: CareLineStruct = new CareLineStruct();

        careLineStruct.careLineName = careLineForm.careLineName;
        careLineStruct.isActive = careLineForm.isActive;
        careLineStruct.twilioFlowSid = careLineForm.twilioFlowSid;
        careLineStruct.twilioFlowName = careLineForm.twilioFlowName;

        request.listCareLineToCreate.push(careLineStruct);
      }
    }
    this.PostCareLines(request);
  }

  PostCareLines(request: CareLineRequest) {
    this.isLoading = true;
    this.careLineService.CreateCareLines(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.alertService.show('Sucesso', 'Configurações cadastradas com sucesso!', AlertType.success);
        this.model.reset({ listIdCareLine: this.formBuilder.array([]) });
        this.populateFlows();
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateCareLines() {
    this.isLoading = true;
    this.careLineService.ListCareLines().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.boundTwilioFlows = [];
        this.listCareLines = response.listCareLine;
        let baseIndex = 0;
        if (this.listCareLines && this.listCareLines.length > 0) {
          this.isUpdate = true;
          this.listCareLines.forEach((careLine: CareLineModel, index: number) => {
            this.addNext(index, careLine);
            this.boundTwilioFlows.push(careLine.twilioFlowSid);
            baseIndex = index;
          });
        }

        else {
          this.isUpdate = false;
        }

        let unboundTwilioFlows = this.twilioFlows.filter(x => !this.boundTwilioFlows.includes(x.sid));

        unboundTwilioFlows.forEach((twilioFlow: FlowResourceStruct, index: number) => {
          this.addNext(baseIndex + index, null, twilioFlow);
        })
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  updateRequirements(index: number) {
    let listForm = this.model.get('listCareLine');
    let careLineForm = listForm['controls'][index];

    if (careLineForm.value.isActive) {
      careLineForm.get('careLineName').setValidators([Validators.required]);
      careLineForm.get('careLineName').enable();

    }
    else {
      careLineForm.get('careLineName').setValidators(null);
      careLineForm.get('careLineName').disable();
    }
    careLineForm.get('careLineName').updateValueAndValidity();

  }
}
