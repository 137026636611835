export class MedicalProcedureCheckModel{

    public idMedicalProcedureCheck: number;
    
    public idUser: number;
    
    public datetimeInclusion: Date;

    public idMedicalProcedure: number;

    public idNursingConsultation: number;

    public checkProcedure: boolean;

    public datetimeCheckFulfilled: Date;
    
    public checkName: boolean;
}