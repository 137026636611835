import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { AttendPatientModalComponent } from './attend-patient-modal/attend-patient-modal.component';
import { ForwardResponse } from 'src/app/shared/services/responses/orchestrator-queue/forward.response';
import { FowardRequest } from '../../../shared/services/requests/orchestrator-queue/foward.request';
import { UpdateStatusQueueRequest } from 'src/app/shared/services/requests/queue/update-status-queue.request';
import { FowardService } from 'src/app/shared/services/API/orchestrator-queue/foward.service';
import { PriorityService } from 'src/app/shared/services/API/totem/priority.service';
import { CallPanelService } from 'src/app/shared/services/API/orchestrator-panel/call-panel.service';
import { PatientListService } from 'src/app/shared/services/API/orchestrator-queue/patient-list.service';
import { ListPatientResponse } from 'src/app/shared/services/responses/orchestrator-queue/list-patient.response';
import { PriorityModel } from 'src/app/shared/services/models/totem/priority.model';
import { RoomStruct } from 'src/app/shared/services/structs/flow/room.struct';
import { SelectRoomModalComponent } from 'src/app/shared/components/select-room-modal/select-room-modal.component';
import { AttendPatientService } from 'src/app/shared/services/API/orchestrator-queue/attend-patient.service';
import { StatusQueueEnum } from 'src/app/shared/enum/status-queue.enum';
import { WebsocketQueueService } from 'src/app/shared/services/websocket-queue.service';
import { EvadePatientModalComponent } from 'src/app/shared/components/evade-patient-modal/evade-patient-modal.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { AttendPatientRequest } from 'src/app/shared/services/requests/orchestrator-queue/attend-patient.request';
import { ListPatientStruct } from 'src/app/shared/services/structs/orchestrator-queue/list-patient.struct';
import { ReturnMotiveModalComponent } from 'src/app/patient-history/pages/patient-history-details/return-motive-modal/return-motive-modal.component';
import { ReturnPatientRequest } from 'src/app/shared/services/requests/orchestrator-queue/return-patient.request';
import { QueueHistoryService } from 'src/app/shared/services/API/orchestrator-queue/queue-history.service';
import { CallPanelEpisodeRequest } from 'src/app/shared/services/requests/orchestrator-panel/call-panel-episode.request';
import { PanelCallEpisodeStruct } from 'src/app/shared/services/structs/orchestrator-panel/panel-call-episode.struct';
import { RoomAccessUtilService } from 'src/app/shared/services/room-access-util.service';
import { FlowSequenceModalComponent } from 'src/app/shared/components/flow-sequence-modal/flow-sequence-modal.component';
import { WebsocketRoomUtilService } from 'src/app/shared/services/websocket-util/websocket-room-util.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CareLinePriorityStatusEnum } from 'src/app/shared/enum/care-line-priority/care-line-priority-status.enum';
import { CsatService } from 'src/app/shared/services/API/csat/csat.service';
import { EpisodeStatusEnum } from 'src/app/shared/enum/episode-status.enum';
import { PrioritiesStatusEnum } from 'src/app/shared/enum/priorities-status.enum';
import { WebsocketAttendanceUtilService } from 'src/app/shared/services/websocket-util/websocket-attendance-util.service';
import { CareLinePriorityEnum } from 'src/app/shared/enum/care-line-priority/care-line-priority.enum';
import { PatientListCallConfirmModalComponent } from 'src/app/shared/components/patient-list-call-confirm-modal/patient-list-call-confirm-modal.component';
import { PatientListAlertCallConfirmModalComponent } from 'src/app/shared/components/patient-list-alert-call-confirm-modal/patient-list-alert-call-confirm-modal.component';
import { PatientListLiberateCallConfirmModalComponent } from 'src/app/shared/components/patient-list-liberate-call-confirm-modal/patient-list-liberate-call-confirm-modal.component';
import { PatientListAttendanceConfirmModalComponent } from 'src/app/shared/components/patient-list-attendance-confirm-modal/patient-list-attendance-confirm-modal.component';
import { PatientListLiberateAttendanceModalComponent } from 'src/app/shared/components/patient-list-liberate-attendance-modal/patient-list-liberate-attendance-modal.component';

@Component({
  selector: 'app-generic-call',
  templateUrl: './generic-call.component.html',
  styleUrls: ['./generic-call.component.css']
})
export class GenericCallComponent implements OnInit, OnDestroy {

  constructor(private fowardService: FowardService,
    private priorityService: PriorityService,
    private callPanelService: CallPanelService,
    private patientListService: PatientListService,
    private attendPatientService: AttendPatientService,
    private alertService: AlertService,
    public dialog: MatDialog,
    private utilService: UtilService,
    private websocketqueueService: WebsocketQueueService,
    private queueHistoryService: QueueHistoryService,
    private roomAccessUtilService: RoomAccessUtilService,
    public websocketRoomUtilService: WebsocketRoomUtilService,
    public websocketAttendanceUtilService: WebsocketAttendanceUtilService,
    private authService: AuthService,
    private csatService: CsatService
  ) {
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  public seeButton: boolean = false;
  public idRoom: number;
  public selectedRoom: RoomStruct;
  public idQueue: number;
  public fowardResponse: ForwardResponse;
  public fowardRequest: FowardRequest;
  public listPriority: PriorityModel[];
  public updateStatusRequest: UpdateStatusQueueRequest;
  public listPatientResponse: ListPatientResponse;
  public isLoading: boolean = false;
  private agendados: boolean = false;
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.generic_call;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.generic_call;
  public searchText: string = '';
  selectedEpisode: number = 0;
  public jsonListIdStatusQueue: string;
  private seeOccupiedRoom: boolean = false;
  public careLinePriorityStatusDenied = CareLinePriorityStatusEnum.negado;
  public selectedPatient: ListPatientStruct | null = null;

  public canReturnToQueue: boolean = true;

  //websocket
  socketDisconneted: boolean = false;
  onlineOffline: boolean = navigator.onLine;
  private socket: any;

  public autismEnum: number = PrioritiesStatusEnum.AutistaTEA;
  public overSixtyYearsEnum: number = PrioritiesStatusEnum.MaiorDe60Anos;
  public overEightyYearsEnum: number = PrioritiesStatusEnum.MaiorDe80Anos;
  public dengue: CareLinePriorityEnum = CareLinePriorityEnum.Dengue;

  ngOnInit(): void {
    this.jsonListIdStatusQueue = null;
    this.updateStatusRequest = new UpdateStatusQueueRequest();
    this.fowardRequest = new FowardRequest();
    this.listPatientResponse = new ListPatientResponse();
    this.listPatientResponse.listPatient = [];
    this.canReturnToQueue = this.utilService.getReturnToQueueConfig();
    this.populatePriorityMultiSelect();
    this.selectRoomModal()
    this.getCsat();

  if (this.websocketAttendanceUtilService.isConnect())
    this.websocketAttendanceUtilService.clearSocket();
  }

  getCsat() {
    this.csatService.getCsat().subscribe({
      next: (response) => {
        this.seeButton = response.seeButton;
      },
    });
  }

  search(pageIndex: number) {
    this.isLoading = true;

    if (this.searchText == "")
      this.searchText = null;

    this.patientListService.listPatient(this.searchText, this.idRoom, this.jsonListIdStatusQueue, pageIndex, null, false, this.agendados).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listPatientResponse = response;
      this.listPatientResponse.listPatient.forEach(x => {
        x.isLoading = false;
      });

      if (this.listPatientResponse?.returnEpisodes != null && this.listPatientResponse?.returnEpisodes > 0 && (this.jsonListIdStatusQueue == null || !this.jsonListIdStatusQueue.includes(StatusQueueEnum.retorno.toString())))
          this.alertService.show('Atenção! ', this.listPatientResponse?.returnEpisodes + ' pacientes aguardando reavaliação - retorno', AlertType.warning);

        if (this.listPatientResponse?.scheduleEpisodes != null && this.listPatientResponse?.scheduleEpisodes > 0)
          this.alertService.show('Atenção! ', this.listPatientResponse?.scheduleEpisodes + ' pacientes agendados aguardando atendimento', AlertType.warning);

      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  toggleIsLoading(index: number) {
    this.listPatientResponse.listPatient[index].isLoading = !this.listPatientResponse.listPatient[index].isLoading;
  }

  populatePriorityMultiSelect() {
    this.priorityService.listPriority().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);

        return;
      }

      this.listPriority = response.listPriority;

    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  populateDestinationSelect(idEpisode: number, idQueue: number, index: number, listIdPriority: number[], idEpisodeAdministrativeRelease: number) {
    this.fowardRequest.idEpisode = idEpisode;
    this.fowardRequest.idRoom = this.idRoom;

    this.toggleIsLoading(index);

    this.fowardService.listDestination(this.fowardRequest).subscribe({
      next:(response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.toggleIsLoading(index);
          return;
        }
  
        this.fowardResponse = response;
        this.toggleIsLoading(index);
  
        let idStatus;
  
        if (idEpisodeAdministrativeRelease) {
          idStatus = EpisodeStatusEnum.FinalizadoAltaAdministrativa;
        }
        else {
          idStatus = null;
        }
  
        if (response.flowSequence != null && response.flowSequence.length > 1) {
          const dialogRef = this.dialog.open(FlowSequenceModalComponent, {
            data: {
              idEpisode: idEpisode,
              idQueue: idQueue,
              flowSequence: response.flowSequence,
            },
          });
        }
  
        else {
          const dialogRef = this.dialog.open(AttendPatientModalComponent, {
            data: {
              idRoom: this.idRoom,
              idEpisode: idEpisode,
              idQueue: idQueue,
              listFoward: this.fowardResponse.location,
              listPriority: this.listPriority,
              listPatientPriority: listIdPriority,
              idStatus: idStatus,
            },
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result && result.fowardPatient) {
              this.paginator.firstPage();
              this.search(0);
            }
          });
        }
      },
      error:(error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  callPatient(idEpisode: number, idQueue: number, index: number,) {
    if (this.websocketRoomUtilService.getIsOccupied()) {
      this.alertService.show("Aviso", "Você está em modo de visualização!", AlertType.warning);
      return;
    }
    this.toggleIsLoading(index);

    let request = new CallPanelEpisodeRequest();
    request.episodeData = new PanelCallEpisodeStruct();

    let patient = this.listPatientResponse.listPatient.find(l => l.idEpisode === idEpisode);

    request.episodeData.idEpisode = idEpisode;
    request.episodeData.patientName = patient.patientName;
    request.episodeData.patientSocialName = patient.patientSocialName;
    request.episodeData.ticketInitials = patient.ticketInitials;
    request.episodeData.ticketSequence = patient.ticketSequence;

    this.callPanelService.callPatient(request, idQueue, this.idRoom, true, this.menuModuleEnum).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.toggleIsLoading(index);
        return;
      }
      if (response.errorCode == -1) {
        this.alertService.show('Aviso', response.errorDescription, AlertType.error);
      }

      if (response.isRestricted) {
        this.openRestrictedCallModal(request, response.confirmMessage, idQueue, index);
      }
      else if (response.isAlert) {
        this.openAlertModal(request, response.confirmMessage, idQueue, index);
      }
      else if (response.isConfirm) {
        this.openConfirmModal(request, response.confirmMessage, idQueue, index);
      }
      else {
        this.updateCallCount(index);
      }

      this.toggleIsLoading(index);
    },
      (error) => {
        console.log(error)
        this.toggleIsLoading(index);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
    this.selectedEpisode = idEpisode;
  }

  cancelPatient(idQueue: number, idStatus: number, idEpisode: number, index: number) {
    if (this.websocketRoomUtilService.getIsOccupied()) {
      this.alertService.show("Aviso", "Você está em modo de visualização!", AlertType.warning);
      return;
    }
    this.toggleIsLoading(index);
    this.openEvadePatientModal(idQueue, idStatus, idEpisode, index);
  }

  openEvadePatientModal(idQueue: number, idStatusQueue: number, idEpisode: number, index: number) {
    const dialogRef = this.dialog.open(EvadePatientModalComponent, {
      data: {
        idStatusQueue: idStatusQueue,
        idQueue: idQueue,
        idRoom: this.idRoom,
        idEpisode: idEpisode,
        manualEvasion: true,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (!result || !result.isEvade) {
        this.toggleIsLoading(index);
      }
      else this.alertService.show('Sucesso', "Paciente evadido com sucesso", AlertType.success);
    });
  }

  attendPatient(patient: ListPatientStruct, index: number, isCheckAttendance: boolean = true) {
    if (this.websocketRoomUtilService.getIsOccupied()) {
      this.alertService.show("Aviso", "Você está em modo de visualização!", AlertType.warning);
      return;
    }
    this.toggleIsLoading(index);

    let attendPatientRequest: AttendPatientRequest = new AttendPatientRequest();

    attendPatientRequest.patientQueue = patient;
    attendPatientRequest.idModule = this.menuModuleEnum;
    attendPatientRequest.idRoomSector = this.listPatientResponse.idRoomSector;
    attendPatientRequest.idRoomService = this.listPatientResponse.idRoomService;

    this.attendPatientService.attendPatient(attendPatientRequest, this.idRoom, isCheckAttendance).subscribe({
      next:(response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.toggleIsLoading(index);
          return;
        }
  
        if (response.isRestrictedPermission) {
          this.openRestrictedAttendmentModal(response.confirmMessage, attendPatientRequest, patient, index);
        }
  
        else if (response.isAlertPermission) {
          this.openAlertAttendmentModal(response.confirmMessage, patient, index, response.isAlertPermission);
        }
  
        else if (response.isConfirm) {
          this.openAttendmentConfirmModal(response.confirmMessage, patient, index);
        }
        else {
          this.populateDestinationSelect(patient.idEpisode, patient.idQueue, index, patient.listIdPriority, patient.idEpisodeAdministrativeRelease);
        }
  
        this.toggleIsLoading(index);
      },
      error:(error) => {
        console.log(error)
        this.toggleIsLoading(index);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
    this.selectedEpisode = patient.idEpisode;
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.paginator.firstPage();
        $this.search(0);
      }
    }, 1000);
  }

  openConfirmModal(request: CallPanelEpisodeRequest, confirmMessage: string, idQueue: number, index: number) {
    const dialogRef = this.dialog.open(PatientListCallConfirmModalComponent, {
      data: {
        confirmMessage: confirmMessage,
        idQueue: idQueue,
        idRoom: this.idRoom,
        episodeData: request.episodeData,
        idModule: this.menuModuleEnum,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.isCalled) {
        this.updateCallCount(index);

      }
    });
  }

  openAlertModal(request: CallPanelEpisodeRequest, confirmMessage: string, idQueue: number, index: number) {
    const dialogRef = this.dialog.open(PatientListAlertCallConfirmModalComponent, {
      data: {
        confirmMessage: confirmMessage,
        idQueue: idQueue,
        idRoom: this.idRoom,
        episodeData: request.episodeData,
        idModule: this.menuModuleEnum,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.isCalled) {
        this.updateCallCount(index);

      }
    });
  }

  openRestrictedCallModal(request: CallPanelEpisodeRequest, confirmMessage: string, idQueue: number, index: number) {
    const dialogRef = this.dialog.open(PatientListLiberateCallConfirmModalComponent, {
      data: {
        confirmMessage: confirmMessage,
        idQueue: idQueue,
        idRoom: this.idRoom,
        episodeData: request.episodeData,
        idModule: this.menuModuleEnum,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.isCalled) {
        this.updateCallCount(index);

      }
    });
  }

  openAttendmentConfirmModal(confirmMessage: string, patient: ListPatientStruct, index: number) {
    const dialogRef = this.dialog.open(PatientListAttendanceConfirmModalComponent, {
      data: {
        confirmMessage: confirmMessage,
        idQueue: patient.idQueue,
        idRoom: this.idRoom,
        idEpisode: patient.idEpisode,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.isAttend) {
        this.attendPatient(patient, index, false);
      }
    });
  }

  openAlertAttendmentModal(confirmMessage: string, patient: ListPatientStruct, index: number, isAlertPermission: boolean) {
    const dialogRef = this.dialog.open(PatientListAttendanceConfirmModalComponent, {
      data: {
        confirmMessage: confirmMessage,
        idQueue: patient.idQueue,
        idRoom: this.idRoom,
        idEpisode: patient.idEpisode,
        isAlertPermission: isAlertPermission
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.isAttend) {
        // this.paginator.firstPage();
        // this.search(0);
        this.populateDestinationSelect(patient.idEpisode, patient.idQueue, index, patient.listIdPriority, patient.idEpisodeAdministrativeRelease);
      }
    });
  }

  openRestrictedAttendmentModal(confirmMessage: string, attendPatientRequest: AttendPatientRequest, patient: ListPatientStruct,  index: number) {
    const dialogRef = this.dialog.open(PatientListLiberateAttendanceModalComponent, {
      data: {
        confirmMessage: confirmMessage,
        attendPatientRequest: attendPatientRequest,
        idRoom: this.idRoom,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.isAttend) {
        this.populateDestinationSelect(patient.idEpisode, patient.idQueue, index, patient.listIdPriority, patient.idEpisodeAdministrativeRelease);
      }
    });
  }

  selectRoomModal() {
    const dialogRef = this.dialog.open(SelectRoomModalComponent, {
      data: {
        idModule: this.menuModuleEnum,
      },
    });
    dialogRef.afterClosed().subscribe(({ res, seeOccupiedRoom }) => {
      this.seeOccupiedRoom = seeOccupiedRoom;
      if (res) {
        this.idRoom = res.idRoom;
        this.selectedRoom = res;
        if (this.socket) {
          this.socket.close();
          this.socket = undefined;
        }
        this.websocketRoomUtilService.clearSocket();
        this.startWebsocket();
        this.listPatientResponse.listPatient = [];
        this.paginator.firstPage();
        this.search(0);
      }
    });
  }

  updateCallCount(index: number) {
    this.listPatientResponse.listPatient[index].ticketCallCount = this.listPatientResponse.listPatient[index].ticketCallCount + 1;
  }

  filterListPatient({ selectedItems, agendados }) {
    this.agendados = agendados;

    if (!selectedItems || selectedItems.length == 0)
      this.jsonListIdStatusQueue = null;
    else
      this.jsonListIdStatusQueue = JSON.stringify(selectedItems);

    this.paginator.firstPage();
    this.search(0);
  }

  //Websocket
  startWebsocket() {
    this.socket = this.websocketqueueService.websocketConnection();
    this.socket.emit('join', `idRoom-${this.idRoom}`)
      .on("updateList", (res) => this.updatePatientList(res))
      .io.on("reconnect", (res) => {
        this.socket.emit('join', `idRoom-${this.idRoom}`)
      });
    if (!this.websocketRoomUtilService.isConnect()) {
      this.websocketRoomUtilService.connectwebsocketRoom(this.idRoom, this.authService.getTokenMenu().login
        , this.authService.getTokenMenu().userName, "/generic-call", this.seeOccupiedRoom)
    }
  }

  private idInterval = setInterval(() => {
    if (this.socket) {
      if (this.socket.connected) {
        this.socketDisconneted = false;
      } else {
        this.socketDisconneted = true;
      }
    } else {
      this.socketDisconneted = false;
    }
    if (this.onlineOffline !== navigator.onLine) {
      this.onlineOffline = navigator.onLine;
    }
  }, 1000);

  // private keepAliveIntervalId = setInterval(() => {
  //   if (this.idRoom) {
  //     this.roomAccessUtilService.postRoomAccessHistory(this.idRoom);

  //   }
  // }, this.roomAccessUtilService.roomKeepAliveTime());

  ngOnDestroy() {
    if (this.socket) {
      this.socket.close();
    }
    clearInterval(this.idInterval);
    // clearInterval(this.keepAliveIntervalId);
  }

  updatePatientList(client: any) {
    this.socketDisconneted = false;
    if (client && client.action == "newpatient") {
      this.paginator.firstPage();
      this.search(0);
      return;
    }
    if (!this.listPatientResponse || !this.listPatientResponse.listPatient || this.listPatientResponse.listPatient.length == 0 || !client)
      return;

    let patient = this.listPatientResponse.listPatient.filter(x => x.idEpisode == client.idEpisode);

    let lastPatient = null;
    if (client.idLastEpisode)
      lastPatient = this.listPatientResponse.listPatient.filter(x => x.idEpisode == client.idLastEpisode);

    if (client.action == "changestatus") {
      patient[0].idStatusQueue = client.idStatusQueue;
      patient[0].statusQueueName = client.newStatusQeue;
      patient[0].attendantUserName = client.userName;
      patient[0].idAttendantUser = client.idUser;
      if (lastPatient && lastPatient[0]) {
        lastPatient[0].idStatusQueue = client.idLastStatusQueue;
        lastPatient[0].statusQueueName = client.lastStatusQueueName;
      }
    }
    else if (client.action == "removepatient") {
      let index = this.listPatientResponse.listPatient.indexOf(patient[0]);
      if (index >= 0) {
        let deleted = this.listPatientResponse.listPatient.splice(index, 1);
        if (deleted && deleted.length >= 1) {
          this.listPatientResponse.totalTickets = (this.listPatientResponse.totalTickets - 1);
          this.listPatientResponse.fullListSize = this.listPatientResponse.totalTickets;
        }
      }
    }
  }

  isAttendedOrEvaded(idStatusQueue) {
    if (idStatusQueue == StatusQueueEnum.atendido || idStatusQueue == StatusQueueEnum.evadido) return false;
    else return true;
  }

  changePage(event: PageEvent) {
    this.search(event.pageIndex);
  }

  isEvaded(idStatusQueue) {
    return idStatusQueue == StatusQueueEnum.evadido;
  }

  openMotiveModal(idEpisode: number) {
    if (this.websocketRoomUtilService.getIsOccupied()) {
      this.alertService.show("Aviso", "Você está em modo de visualização!", AlertType.warning);
      return;
    }
    let reportDialog = this.dialog.open(ReturnMotiveModalComponent);
    reportDialog.afterClosed().subscribe((result: ReturnPatientRequest) => {
      if (result && result.motive) {
        this.isLoading = true;
        result.idEpisode = idEpisode;
        this.queueHistoryService.UpdateQueue(result).subscribe((response) => {
          if (response.isError) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            return;
          }
          this.alertService.show('Sucesso', "Paciente retornado com sucesso para fila!", AlertType.success);
          this.search(0);
        });
      }
    });
  }
  
  selectPatient(patient: ListPatientStruct): void {
    this.selectedPatient = patient;
    this.selectedEpisode = patient.idEpisode;
  } 
}
