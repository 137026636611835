<div mat-dialog-content>
    <div class="mat-dialog-content">
        <form class="form" [formGroup]="model">
            <div class="row">
                <div class="col-md-6">
                    <div class="head-profile-modal-list">
                        <h2>Procedimento: </h2>
                    </div>
                    <div class="body-confirm-modal-list">
                        <div class="row">
                            <div class="col-md-12">
                                <label>Nome procedimento: </label>
                                <p>{{procedure.procedureName}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label>Observação: </label>
                                <p>{{procedure.observation}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6" *ngIf="procedure.listMedicalProcedureCheck">
                    <div class="head-profile-modal-list">
                        <h2>Histórico do Procedimento: </h2>
                    </div>
                    <div class="row" *ngFor="let item of procedure.listMedicalProcedureCheck">
                        <div class="col-md-12">
                            <label *ngIf="item.datetimeCheckFulfilled">
                                Checado
                            </label>
                            <p>{{item.datetimeCheckFulfilled | date:'dd/MM/yyyy HH:mm'}}</p>
                            <p>{{item.professionName}} - {{item.userName}}</p>
                        </div>
                    </div>
                </div>
                <div class="footer-modal-list">
                    <button *ngIf="!isReadonly && data.actions" mat-flat-button color="primary" class="btn-block" type="submit">
                        <span *ngIf="isLoading == false">Concluir</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                    <button mat-flat-button type="button" color="primary" class="btn-block"
                        (click)="close()"><span>Fechar</span></button>
                </div>
            </div>
        </form>
    </div>
</div>