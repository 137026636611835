<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <a class="back-link" routerLink="/pharmacy/location">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Estoque
        </a>
        <h1>Manutenção do Estoque</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <div class="row">
                <div class="col-6 col-md-3">
                    <p>Código do produto:</p>
                    <p><b>{{productCode}}</b></p>
                </div>
                <div class="col-6 col-md-3">
                    <p>Descrição do produto:</p>
                    <p><b>{{productDescription}}</b></p>
                </div>
                <div class="col-6 col-md-3">
                    <p>Quantidade Total:</p>
                    <p><b>{{totalAmount}}</b></p>
                </div>
                <div class="col-6 col-md-3">
                    <p>Quantidade restante:</p>
                    <p><b>{{totalAmountRemaining}}</b></p>
                </div>
                <div class="col-6 col-md-3">
                    <p>Estoque mínimo:</p>
                    <p *ngIf="minimumAmount"><b>{{minimumAmount}}</b></p>
                    <p *ngIf="!minimumAmount"><b>-</b></p>
                </div>
            </div>
            <div class="title-header">
                <h1>Itens por estoque</h1>
            </div>
            <div class="row">
                <div *ngFor="let item of storage; let index = index;" class="col-12">
                    <app-storage-accordion (wasUpdated)="populateStorageData()" [storage]="item" [index]="index">
                    </app-storage-accordion>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-2">
                    <a mat-flat-button type="button" routerLink="/pharmacy/movement" color="accent" class=" btn-block">
                        Voltar
                    </a>
                </div>
            </div>
        </div>
    </div>
    
</div>