import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { HealthUnitMemedPrescriptionResponse } from '../../responses/medic/health-unit-memed-prescription-response';

@Injectable({
    providedIn: 'root'
  })
  export class HealthUnitMemedPrescriptionService extends CommonService{
  
    constructor(private router: Router, private httpClient: HttpClient) {
      super();
    }
  
    public postMemedPrescriptionConfig(): Observable<ReturnStruct> {
      
      let uri = 'HealthUnitMemedPrescription'
  
      return this.httpClient.post<ReturnStruct>(environment.urlApiMedic + uri, null, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
    }

    public getMemedPrescriptionConfig(): Observable<HealthUnitMemedPrescriptionResponse> {
        let uri = 'HealthUnitMemedPrescription'
  
      return this.httpClient.get<HealthUnitMemedPrescriptionResponse>(environment.urlApiMedic + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
    }
  }