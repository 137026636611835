import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { FrequencyEnum } from 'src/app/shared/enum/business-intelligence/frequency.enum';
import { DaysMonth } from 'src/app/shared/services/models/business-intelligence/days-month.model';
import { DaysWeek } from 'src/app/shared/services/models/business-intelligence/days-week.model';
import { Frequency } from 'src/app/shared/services/models/business-intelligence/frequency.model';
import { BusinessIntelligenceStruct } from 'src/app/shared/services/structs/business-intelligence/business-intelligence.struct';
import { ListUserStruct } from 'src/app/shared/services/structs/user/list-user.struct';

@Component({
  selector: 'app-create-rule',
  templateUrl: './create-rule.component.html',
  styleUrls: ['./create-rule.component.css']
})
export class CreateRuleComponent implements OnInit {

  @Input() formGroup: FormGroup;
  @Input() formArrayName: string;
  @Input() arrayVariables: Array<any>
  @Input() listUser: ListUserStruct[];
  @Input() listFrequency: Frequency[];
  @Input() listDaysMonth: DaysMonth[];
  @Input() listDaysWeek: DaysWeek[];
  @Input() listReport: BusinessIntelligenceStruct[];

  public monthly: number = FrequencyEnum.Mensal;
  public weekly: number = FrequencyEnum.Semanal;
  public daily: number = FrequencyEnum.Diario;

  constructor(private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
  }

  createInput() {
    return this.formBuilder.group({
      idReportFunctionHealthUnit: [''],
      idFrequency: [''],
      idSendReportRule: [null],
      value: [''],
      idUser: [''],
      idDaysMonth: [null],
      idDaysWeek: [null]
    });
  }

  addNext() {
    this.arrayVariables.push(null);
    (this.formGroup.controls[this.formArrayName] as FormArray).push(this.createInput())
  }

  removeExpression(index: number) {
    this.arrayVariables.splice(index, 1);
    (this.formGroup.controls[this.formArrayName] as FormArray).removeAt(index)
  }
}