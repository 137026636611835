import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { SupportContactUnService } from 'src/app/shared/services/API/admin-user/support-contact-un.service';
import { SupportContact } from 'src/app/shared/services/models/admin-user/support-contact.model';
import { SupportContactTypeEnum } from 'src/app/shared/enum/admin-user/support-contact-type.enum';
import { PhoneModalComponent } from './phone-modal/phone-modal.component';


@Component({
  selector: 'app-support-consulting',
  templateUrl: './support-consulting.component.html',
  styleUrls: ['./support-consulting.component.css']
})
export class SupportPageComponent implements OnInit {

  constructor(
    private alertService: AlertService,
    public dialog: MatDialog,
    private supportContactUnService: SupportContactUnService,
  ) { }
  
  public isLoading: boolean = false;
  public isMobileVersion: boolean;
  public listSupportContact: SupportContact[];

  public supportContactTypeEnum: typeof SupportContactTypeEnum = SupportContactTypeEnum;

  ngOnInit(): void {
    this.isMobile();
    this.getListSupportContact();
    
  }

  isMobile() {
    const userAgent: string = navigator.userAgent.toLowerCase();

    let isMobile: boolean = /iphone|android/i.test(navigator.userAgent);

    let isTablet: boolean = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);

    if (isMobile || isTablet || screen.orientation.type == "portrait-primary") {
      this.isMobileVersion = true;
    }
    else {
      this.isMobileVersion = false;
    }
  }

  getListSupportContact() {
    this.isLoading = true;
    this.supportContactUnService.listAllSupportContact().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        else if (!response.supportContact || response.supportContact.length == 0) {
          this.alertService.show('Erro', "A lista de contatos do suporte está vazia", AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listSupportContact = response.supportContact; 
        
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
  openTelephoneModal(item: SupportContact) {
    const dialogRef = this.dialog.open(PhoneModalComponent, {
      panelClass: 'body-phone-modal',
      data: {
        buttonName:  item.buttonName,
        contactValue: item.contactValue,
      },
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
      }
    });
  }

  openUrlLink(link: string) {   
      window.open(link, "_blank");     
  }

  sendEmail(email: string) {
    window.location.href = `mailto:${email}`;
  }
}