<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <h1>Paciente x Procedimento Realizado</h1>
        <div class="white-body">
            <a mat-flat-button color="primary" class=" add-user"
                routerLink="/billing/patient-procedure-made/register"><mat-icon aria-hidden="false"
                    aria-label="Plus">add</mat-icon> Gerar Relatório</a>
            <div class="card-list" *ngIf="listPatientProcedureMade != null">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listPatientProcedureMade">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary">{{item.datetimeInclusion | date:'dd/MM/yyyy'}}</span>
                                <span class="secundary">{{item.datetimeStart | date:'MMMM' | titlecase}}, {{item.datetimeStart | date:'d'}} a {{item.datetimeEnd | date:'d'}}</span>
                                <span class="secundary">{{item.userName}}</span>
                            </div>
                            <div class="actions">
                                <a *ngIf="isDownloadLoading == false">
                                    <mat-icon aria-hidden="false" aria-label="Editar"
                                        (click)="reportExcelDownload(item.idPatientProcedureMade)">file_download</mat-icon>
                                </a>
                                <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                    *ngIf="isDownloadLoading == true"></mat-spinner>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <app-empty-list
                *ngIf="listPatientProcedureMade != undefined && listPatientProcedureMade.length == 0"></app-empty-list>
        </div>

        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>
</div>