import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ExamTypeService } from 'src/app/shared/services/API/SADT/exam-type.service';

@Component({
  selector: 'app-exam-type-delete-modal',
  templateUrl: './exam-type-delete-modal.component.html',
  styleUrls: ['./exam-type-delete-modal.component.css']
})
export class ExamTypeDeleteModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<ExamTypeDeleteModalComponent>,
  private examTypeService: ExamTypeService,
  private alertService: AlertService) { }

  public isLoading:boolean;  

  ngOnInit(): void {
    this.isLoading = false;
  }
  
  close(){
    this.matDialogRef.close({deleteExamType: false}); 
  }
  
  clickDelete(){
    
    this.isLoading = true;

    this.examTypeService.deleteExamType(this.data.idExamType).subscribe({
      next: (response)=>{
      
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        
        this.alertService.show('Sucesso', "Tipo de exame excluido com sucesso!", AlertType.success); 
        this.matDialogRef.close({deleteExamType: true}); 
      },
      error: (error)=>{
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
  
  clickCancel(){
    this.matDialogRef.close({deleteExamType: false}); 
  }
}

