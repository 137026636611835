import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { BedListComponent } from './pages/bed-list/bed-list.component';
import { BedManagementDetailsComponent } from './pages/bed-management-details/bed-management-details.component';
import { BedManagementComponent } from './pages/bed-management/bed-management.component';
import { BedRegisterComponent } from './pages/bed-register/bed-register.component';
import { ObsertavionPatientListComponent } from './pages/obsertavion-patient-list/obsertavion-patient-list.component';
import { ObservationComponent } from './pages/observation/observation.component';
import { ObservationConfigListComponent } from './pages/observation-config-list/observation-config-list.component';
import { ObservationConfigRegisterComponent } from './pages/observation-config-register/observation-config-register.component';
import { BedHygienizationComponent } from './pages/bed-hygienization/bed-hygienization.component';
import { HygienizationTotemListComponent } from './pages/hygienization-totem-list/hygienization-totem-list.component';
import { HygienizationTotemRegisterComponent } from './pages/hygienization-totem-register/hygienization-totem-register.component';


const routes: Routes = [
  { path: 'observation', component: ObservationComponent, canActivate: [AuthGuard] },
  { path: 'observation/bed', component: BedListComponent, canActivate: [AuthGuard] },
  { path: 'observation/bed/register', component: BedRegisterComponent, canActivate: [AuthGuard] },
  { path: 'observation/patient-list', component: ObsertavionPatientListComponent, canActivate: [AuthGuard] },
  { path: 'observation/bed-management', component: BedManagementComponent, canActivate: [AuthGuard] },
  { path: 'observation/bed-management/details', component: BedManagementDetailsComponent, canActivate: [AuthGuard] },
  { path: 'observation/observation-config', component: ObservationConfigListComponent, canActivate: [AuthGuard] },
  { path: 'observation/observation-config/register', component: ObservationConfigRegisterComponent, canActivate: [AuthGuard] },
  { path: 'observation/bed-hygienization', component: BedHygienizationComponent, canActivate: [AuthGuard] },
  { path: 'observation/hygienization-totem', component: HygienizationTotemListComponent, canActivate: [AuthGuard] },
  { path: 'observation/hygienization-totem/register', component: HygienizationTotemRegisterComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ObservationRoutingModule { }
