<div class="border-list">
    <div class="title-list">
        <div class="row">
            <div class="col-6 col-sm-6 col-md-4">
                <span class="subtitle">Pedidos de Exames</span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6 col-sm-6 col-md-4">
            <a mat-flat-button type="button" (click)="openSadtModal(null, false, -1)"
                color="accent" class=" btn-block">
                <mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon> Adicionar Pedido de Exame
            </a>
        </div>
    </div>
    <div class="card-list" *ngIf="listSADT != null && listSADT.length > 0">
        <div class="row row-list">
            <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listSADT; let index = index;">
                <div class="card-item">
                    <div class="infos">
                        <span class="primary">{{item.typeSadtName}}</span>
                        <span class="secundary">Uso: {{item.placeName}}</span>
                        <span class="secundary">Status: Pedido</span>
                    </div>
                    <div class="actions">
                        <a>
                            <mat-icon aria-hidden="false" aria-label="Editar"
                                (click)="openSadtModal(item, false, index)">edit</mat-icon>
                        </a>
                        <a>
                            <mat-icon aria-hidden="false" aria-label="Excluir" (click)="deleteSadt(index)">
                                delete</mat-icon>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>