import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ListIdentificationResponse } from '../../responses/front-desk/list-identification.response';

@Injectable({
  providedIn: 'root'
})
export class IdentificationService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listIdentification(): Observable<ListIdentificationResponse>{
    let uri = `Identification`;  
    
    return this.httpClient.get<ListIdentificationResponse>(environment.urlApiFrontDesk + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public postIdentification(idField: number): Observable<ReturnStruct>{
    let uri = `Identification/idField/${idField}`;  
    
    return this.httpClient.post<ReturnStruct>(environment.urlApiFrontDesk + uri, idField, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}