import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ExternalPrescriptionService } from 'src/app/shared/services/API/medical-record/external-prescription.service';
import { ReadExternalPrescriptionStruct } from 'src/app/shared/services/structs/orchestrator-patient/read-external-prescription.struct';

@Component({
  selector: 'app-update-external-prescription-modal',
  templateUrl: './update-external-prescription-modal.component.html',
  styleUrls: ['./update-external-prescription-modal.component.css']
})
export class UpdateExternalPrescriptionModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<UpdateExternalPrescriptionModalComponent>,
    private alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
    private medicalPrescriptionService: ExternalPrescriptionService,
  ) { }

  public isLoading: boolean;
  public medicalPrescription: ReadExternalPrescriptionStruct;
  public prescriptionModel: UntypedFormGroup;

  ngOnInit(): void {
    this.medicalPrescription = this.data.prescription;
    this.isLoading = false;
    this.prescriptionModel = this.formBuilder.group({
      description: ['', Validators.required],
      quantity: ['', Validators.required],
      frequency: ['', Validators.required],
      observation: [''],
    });
    if (this.medicalPrescription) {
      this.populateValues();
    }
  }

  populateValues() {
    this.prescriptionModel.get('quantity').setValue(this.medicalPrescription.quantity);
    this.prescriptionModel.get('frequency').setValue(this.medicalPrescription.frequency);
    this.prescriptionModel.get('observation').setValue(this.medicalPrescription.observation);
    this.prescriptionModel.get('description').setValue(this.medicalPrescription.description);
  }

  clickCancel() {
    if (!this.isLoading) {
      this.matDialogRef.close();
    }
  }
  close() {
    if (!this.isLoading) {
      this.matDialogRef.close();
    }
  }

  onSubmit() {
    if (this.prescriptionModel.invalid) {
      this.alertService.show('Erro', "Preencha todos os campos obrigatórios!", AlertType.error);
      return;
    }
    this.isLoading = true;
    let newExternalPrescription: ReadExternalPrescriptionStruct = new ReadExternalPrescriptionStruct();
    newExternalPrescription.frequency = this.prescriptionModel.get('frequency').value;
    newExternalPrescription.description = this.prescriptionModel.get('description').value;
    newExternalPrescription.quantity = this.prescriptionModel.get('quantity').value;
    newExternalPrescription.observation = this.prescriptionModel.get('observation').value;

    if (this.medicalPrescription) {
      newExternalPrescription.idExternalPrescription = this.medicalPrescription.idExternalPrescription;
      this.medicalPrescriptionService.updatePresciption(newExternalPrescription).subscribe((response) => {
        this.isLoading = false;
        if (response.isError) {
          this.alertService.show("Erro", response.errorDescription, AlertType.error);
          return;
        }
        this.alertService.show("Erro", "Sucesso ao atualizar a prescrição!", AlertType.success);
        this.matDialogRef.close({ newExternalPrescription: newExternalPrescription });
      }, (err) => {
        this.alertService.show("Erro Inesperado", err, AlertType.error);
        this.isLoading = false;
        return;
      });
    } else {
      this.medicalPrescriptionService.createPresciption(this.data.idMedicalCare, newExternalPrescription).subscribe((response) => {
        this.isLoading = false;
        if (response.isError) {
          this.alertService.show("Erro", response.errorDescription, AlertType.error);
          return;
        }
        this.alertService.show("Erro", "Sucesso ao atualizar a prescrição!", AlertType.success);
        newExternalPrescription.idExternalPrescription = response.idExternalPrescription;
        this.matDialogRef.close({ newMedicalPrescription: newExternalPrescription });
      }, (err) => {
        this.alertService.show("Erro Inesperado", err, AlertType.error);
        this.isLoading = false;
        return;
      });
    }
  }
}