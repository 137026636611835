<form class="form" [formGroup]="searchModel">
    <div class="title-header">
        <div class="row">
            <div class="col-6 col-sm-6">
                <h1>Buscar por Dados Complementares</h1>
            </div>
        </div>
    </div>
    <span class="pin-intro">Informe aqui o nome</span>
    <div class="row">
        <div class="col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>Nome</mat-label>
                <input matInput type="text" formControlName="name">
                <mat-error *ngIf="searchModel.get('name').invalid">Informe o nome</mat-error>
            </mat-form-field>
        </div>
    </div>

    <span class="pin-intro">Informe aqui o nome da mãe</span>
    <div class="row">
        <div class="col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>Nome da Mãe</mat-label>
                <input matInput type="text" formControlName="motherName">
                <mat-error *ngIf="searchModel.get('motherName').invalid">Informe o nome da mãe</mat-error>
            </mat-form-field>
        </div>
    </div>

    <span class="pin-intro">Informe aqui a data de nascimento</span>
    <div class="row">
        <div class="col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>Data de nascimento</mat-label>
                <input matInput type="text" formControlName="birthDate" [mask]="masks.date">
                <mat-error *ngIf="searchModel.get('birthDate').invalid">Informe a data de nascimento</mat-error>
            </mat-form-field>
        </div>
    </div>
    
    <div class="row">
        <div class="col-12 col-sm-3">
            <button mat-flat-button (click)="searchByFullTerms()" color="primary" class=" btn-block">
                <span *ngIf="isLoading == false">Buscar</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
        </div>
    </div>
</form>