import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';

@Injectable({
  providedIn: 'root'
})
export class NpsScreenConfigService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  public updateToggleCommentCollection(commentCollectionActive: boolean, idHealthUnit: number): Observable<ReturnStruct> {
    let uri = `NpsScreenConfig/commentCollectionActive/${commentCollectionActive}/idHealthUnit/${idHealthUnit}`;
    
    return this.httpClient.put<ReturnStruct>(environment.urlApiNps + uri, null, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}