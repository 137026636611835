import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { CallSyncMeasurerService } from 'src/app/shared/services/API/orchestrator-patient/call-sync-measurer.service';
import { ListSyncMeasurerStruct } from 'src/app/shared/services/structs/orchestrator-patient/list-sync-measurer.struct';
import { UtilService } from 'src/app/shared/services/util.service';
import { SyncMeasurerDeleteModalComponent } from './sync-measurer-delete-modal/sync-measurer-delete-modal.component';

@Component({
  selector: 'app-sync-measurer-list',
  templateUrl: './sync-measurer-list.component.html',
  styleUrls: ['./sync-measurer-list.component.css']
})
export class SyncMeasurerListComponent implements OnInit {

  constructor(
    private callSyncMeasurerService: CallSyncMeasurerService,
    private router: Router,
    private alertService: AlertService,
    private utilService: UtilService,
    public dialog: MatDialog
  ) { }
    
  public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.remote_monitoring;
  public menuFunctionalityEnum:MenuFunctionalityEnum = MenuFunctionalityEnum.remoteMonitoring_syncMeasurer;
    
  public listSyncMeasurer: ListSyncMeasurerStruct[];
  public isLoading: boolean;
  public isActive: boolean;
  public patientName: string;
  public syncMeasurerHash: string;
  public idExamType: number;
  public beginPeriod: Date;
  public endPeriod: Date;

  ngOnInit(): void {
    this.isLoading = true;
    this.search();
  }

  search(){
    if ((!this.beginPeriod && this.endPeriod)
      ||(this.beginPeriod && !this.endPeriod)) {
      return;
    }

    this.isLoading = true;  

    if (this.beginPeriod){
      this.beginPeriod.setHours(0);
    }
      
    if (this.endPeriod) {
      this.endPeriod.setHours(23);
      this.endPeriod.setMinutes(59);
    }

    if(!this.patientName) this.patientName = null;
    if(!this.syncMeasurerHash) this.syncMeasurerHash = null;
    if(!this.isActive) this.isActive = null;

    this.callSyncMeasurerService.listSyncMeasurer(this.patientName, this.syncMeasurerHash, this.isActive, this.beginPeriod, this.endPeriod).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
     
      this.listSyncMeasurer = response.listSyncMeasurer;  
    
      this.isLoading = false;    
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  private timeoutKeySearch: any = null;
  
  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }
  
  openModal(idSyncMeasurer){
    const dialogRef = this.dialog.open(SyncMeasurerDeleteModalComponent, {
      data: {
        idSyncMeasurer: idSyncMeasurer
      },
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.deleteExam){
        this.search();
      }
    });
  }
}
