export class FormPatientRequest
{
    public idPatient: number;
    public patientName: string;
    public socialName: string;
    public cpf: string;
    public cns: string;
    public birthDate: Date;
    public idGender: number;
    public zipCode: string;
    public street: string;
    public neighborhood: string;
    public city: string;
    public state: string;
    public country: string;
    public houseNumber: number;
    public apartmentNumber: string;
}