import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PharmacyRoutingModule } from './pharmacy-routing.module';
import { PharmacyComponent } from './pages/pharmacy/pharmacy.component';
import { LocationListComponent } from './pages/location-list/location-list.component';
import { LocationDeleteModalComponent } from './pages/location-list/location-delete-modal/location-delete-modal.component';
import { LocationRegisterComponent } from './pages/location-register/location-register.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { HttpClient } from '@angular/common/http';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckbox, MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioButton, MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTree, MatTreeModule } from '@angular/material/tree';
import { NgxMaskModule } from 'ngx-mask';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { SharedModule } from '../shared/shared.module';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { MedicineListComponent } from './pages/medicine-list/medicine-list.component';
import { MedicineDeleteModalComponent } from './pages/medicine-list/medicine-delete-modal/medicine-delete-modal.component';
import { MedicineRegisterComponent } from './pages/medicine-register/medicine-register.component';
import { MedicineTypeListComponent } from './pages/medicine-type-list/medicine-type-list.component';
import { MedicineTemplateModalComponent } from './pages/medicine-type-list/medicine-template-modal/medicine-template-modal.component';
import { MedicineTypeDeleteModalComponent } from './pages/medicine-type-list/medicine-type-delete-modal/medicine-type-delete-modal.component';
import { MedicineTypeRegisterComponent } from './pages/medicine-type-register/medicine-type-register.component';
import { TemplateConfirmModalComponent } from './pages/medicine-type-list/medicine-template-modal/template-confirm-modal/template-confirm-modal.component';
import { MedicineGroupListComponent } from './pages/medicine-group-list/medicine-group-list.component';
import { MedicineGroupDeleteModalComponent } from './pages/medicine-group-list/medicine-group-delete-modal/medicine-group-delete-modal.component';
import { MedicineGroupRegisterComponent } from './pages/medicine-group-register/medicine-group-register.component';
import { MedicineSubgroupListComponent } from './pages/medicine-subgroup-list/medicine-subgroup-list.component';
import { MedicineSubgroupDeleteModalComponent } from './pages/medicine-subgroup-list/medicine-subgroup-delete-modal/medicine-subgroup-delete-modal.component';
import { MedicineSubgroupRegisterComponent } from './pages/medicine-subgroup-register/medicine-subgroup-register.component';
import { ItemListComponent } from './pages/item-list/item-list.component';
import { ItemRegisterComponent } from './pages/item-register/item-register.component';
import { ItemDeleteModalComponent } from './pages/item-list/item-delete-modal/item-delete-modal.component';
import { StorageListComponent } from './pages/storage-list/storage-list.component';
import { StorageRegisterComponent } from './pages/storage-register/storage-register.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { StorageDeleteModalComponent } from './pages/storage-list/storage-delete-modal/storage-delete-modal.component';
import { PharmacyMovementListComponent } from './pages/pharmacy-movement-list/pharmacy-movement-list.component';
import { PharmacyMovementRegisterComponent } from './pages/pharmacy-movement-register/pharmacy-movement-register.component';
import { StorageAccordionComponent } from './pages/pharmacy-movement-register/storage-accordion/storage-accordion.component';
import { LocationAccordionComponent } from './pages/pharmacy-movement-register/location-accordion/location-accordion.component';
import { CorrectionModalComponent } from './pages/pharmacy-movement-register/correction-modal/correction-modal.component';
import { ReturnMedicineItemComponent } from './pages/return-medicine-item/return-medicine-item.component';
import { PrescriptionListComponent } from './pages/prescription-list/prescription-list.component';
import { EpisodePrescriptionComponent } from './pages/episode-prescription/episode-prescription.component';
import { EpisodePrescriptionModalComponent } from './pages/episode-prescription/episode-prescription-modal/episode-prescription-modal.component';
import { MedicineBarCodeModalComponent } from './pages/episode-prescription/medicine-bar-code-modal/medicine-bar-code-modal.component';
import { KitListComponent } from './pages/kit-list/kit-list.component';
import { KitRegisterComponent } from './pages/kit-register/kit-register.component';
import { KitDeleteModalComponent } from './pages/kit-list/kit-delete-modal/kit-delete-modal.component';
import { KitItemSelectComponent } from './pages/kit-register/kit-item-select/kit-item-select.component';
import { BarCodeModalComponent } from './pages/return-medicine-item/bar-code-modal/bar-code-modal.component';
import { PresentationTypeConfirmModalComponent } from './pages/episode-prescription/presentation-type-confirm-modal/presentation-type-confirm-modal.component';
import { PrescriptionModalItemSelectComponent } from './pages/episode-prescription/episode-prescription-modal/prescription-modal-item-select/prescription-modal-item-select.component';
import { EpisodeMaterialModalComponent } from './pages/episode-prescription/episode-material-modal/episode-material-modal.component';
import { StorageLocationItemComponent } from './pages/episode-prescription/episode-material-modal/storage-location-item/storage-location-item.component';
import { ConfirmSaveModalComponent } from './pages/episode-prescription/episode-material-modal/confirm-save-modal/confirm-save-modal.component';
import { PrescriptionNonDispensationModalComponent } from './pages/episode-prescription/prescription-non-dispensation-modal/prescription-non-dispensation-modal.component';
import { MaterialNonDispensationModalComponent } from './pages/episode-prescription/material-non-dispensation-modal/material-non-dispensation-modal.component';
import { PharmacyPrintingComponent } from './pages/pharmacy-printing/pharmacy-printing.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MedicalPrescriptionSolutionModalComponent } from './pages/episode-prescription/medical-prescription-solution-modal/medical-prescription-solution-modal.component';
import { LiberateMedicalPrescriptionSolutionModalComponent } from './pages/episode-prescription/liberate-medical-prescription-solution-modal/liberate-medical-prescription-solution-modal.component';
import { PrescriptionNonDispensationAllModalComponent } from './pages/episode-prescription/prescription-non-dispensation-all-modal/prescription-non-dispensation-all-modal.component';

@NgModule({
  declarations: [
    PharmacyComponent, 
    LocationListComponent,
    LocationDeleteModalComponent, 
    LocationRegisterComponent, 
    MedicineListComponent, 
    MedicineDeleteModalComponent, 
    MedicineRegisterComponent, 
    MedicineTypeListComponent, 
    MedicineTemplateModalComponent, 
    MedicineTypeDeleteModalComponent, 
    MedicineTypeRegisterComponent, 
    TemplateConfirmModalComponent, 
    MedicineGroupListComponent, 
    MedicineGroupDeleteModalComponent, 
    MedicineGroupRegisterComponent, 
    MedicineSubgroupListComponent, 
    MedicineSubgroupDeleteModalComponent, 
    MedicineSubgroupRegisterComponent, 
    ItemListComponent, 
    ItemDeleteModalComponent,
    ItemRegisterComponent,
    StorageListComponent,
    StorageRegisterComponent,
    StorageDeleteModalComponent,
    PharmacyMovementListComponent,
    PharmacyMovementRegisterComponent,
    StorageAccordionComponent,
    LocationAccordionComponent,
    CorrectionModalComponent,
    ReturnMedicineItemComponent,
    PrescriptionListComponent,
    EpisodePrescriptionComponent,
    EpisodePrescriptionModalComponent,
    MedicineBarCodeModalComponent,
    PresentationTypeConfirmModalComponent,
    KitListComponent,
    KitRegisterComponent,
    KitDeleteModalComponent,
    KitItemSelectComponent,
    BarCodeModalComponent,
    PrescriptionModalItemSelectComponent,
    EpisodeMaterialModalComponent,
    StorageLocationItemComponent,
    ConfirmSaveModalComponent,
    PrescriptionNonDispensationModalComponent,
    MaterialNonDispensationModalComponent,
    PharmacyPrintingComponent,
    MedicalPrescriptionSolutionModalComponent,
    LiberateMedicalPrescriptionSolutionModalComponent,
    PrescriptionNonDispensationAllModalComponent,
  ],
  imports: [
    CommonModule,
    PharmacyRoutingModule,
    SharedModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonModule,
    NgxMaskModule.forRoot(),
    MatCheckboxModule,
    MatTreeModule,
    MatRadioModule,
    ClipboardModule,
    MatTooltipModule,
    MatToolbarModule,
    NgxMatFileInputModule,
    CdkAccordionModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatDialogModule,
  ],
  providers: [
    AuthGuard,
    MatCheckbox,
    MatCheckboxChange,
    MatTree,
    MatRadioButton,
  ],
})
export class PharmacyModule { }
