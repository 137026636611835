import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EpisodeStruct } from 'src/app/shared/services/structs/medical-record/episode.struct';
import { PatientStruct } from 'src/app/shared/services/structs/medical-record/patient.struct';

@Component({
  selector: 'app-confirm-patient-data-modal',
  templateUrl: './confirm-patient-data-modal.component.html',
  styleUrls: ['./confirm-patient-data-modal.component.css']
})
export class ConfirmPatientDataModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ConfirmPatientDataModalComponent>,
  ) { }

  public isLoading: boolean = false;
  public patientData: PatientStruct;
  public episodeData: EpisodeStruct;

  ngOnInit(): void {
    this.patientData = this.data.patient;
    this.episodeData = this.data.episode;
  }

  clickCancel() {
    this.matDialogRef.close({confirm: false});
  }

  clickConfirm() {
    this.matDialogRef.close({confirm: true});
  }
}