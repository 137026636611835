import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { UtilsClientService } from 'src/app/client/utils.service';
import { TwilioVideoComponent } from 'src/app/shared/components/twilio-video/twilio-video.component';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { WebsocketRemoteUtilService } from 'src/app/shared/services/websocket-util/websocket-remote-util.service';
import { WarnPatientEvadeComponent } from '../../modals/warn-patient-evade/warn-patient-evade.component';

@Component({
  selector: 'app-attend-patient-video',
  templateUrl: './attend-patient-video.component.html',
  styleUrls: ['./attend-patient-video.component.css']
})
export class AttendPatientVideoComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    private alertService: AlertService,
    private utilsService: UtilsClientService,
    private router: Router,
    private websocketRemoteUtilService: WebsocketRemoteUtilService,
    private dialog: MatDialog,
  ) { }

  public timeout: any;
  public isMobileVersion: boolean;
  public isFinished: boolean = false;
  public tamanho: number;
  public attendantUserName: string;
  private onLeave: boolean = false;
  @ViewChild('twiliovideo') twiliovideo: TwilioVideoComponent;

  ngOnInit(): void {
    let roomSector = this.utilsService.getSectorRoomRemote();
    if (!roomSector) {
      this.router.navigate(['client/remote-attendance/inv']);
      return;
    }
    let { idRoom } = JSON.parse(roomSector);
    if (!this.websocketRemoteUtilService.isConnect()) {
      this.websocketRemoteUtilService.connectwebsocketRoom(idRoom);
    }
  }

  ngAfterViewInit(): void {
    this.websocketRemoteUtilService.emitVerifying();
    this.timeout = setTimeout(() => this.startCall(), 1000);
  }

  onLeaveRoom() {
    this.onLeaveRoomAction();
  }

  onLeaveRoomAction() {
    if (!this.onLeave) {
      this.dialog.open(WarnPatientEvadeComponent);
      this.websocketRemoteUtilService.emitPatientEvaded();
      this.onLeave = true;
    }
  }
  startCall() {
    clearTimeout(this.timeout);
    if (sessionStorage.getItem("videoToken")) {
      this.twiliovideo.onRoomChanged(sessionStorage.getItem("videoToken"));
    } else {
      this.alertService.show("Erro", "Houve um imprevisto tente novamente em alguns minutos!", AlertType.error);
      this.router.navigate(['client/remote-attendance/welcome']);
    }
  }

  ngOnDestroy() {
    if (!this.onLeave && !this.websocketRemoteUtilService.getMedicLeft()) {
      this.websocketRemoteUtilService.emitPatientEvaded();
    }
  }
}
