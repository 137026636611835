import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { BiLookupService } from 'src/app/shared/services/API/business-intelligence/bi-lookup.service';
import { BiPanelService } from 'src/app/shared/services/API/business-intelligence/bi-panel.service';
import { ReportHealthUnitService } from 'src/app/shared/services/API/business-intelligence/report-health-unit.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { VisualizationTime } from 'src/app/shared/services/models/business-intelligence/visualization-time.model';
import { BiPanelRequest } from 'src/app/shared/services/requests/business-intelligence/bi-panel.request';
import { BusinessIntelligenceStruct } from 'src/app/shared/services/structs/business-intelligence/business-intelligence.struct';
import { PanelReportVisualizationStruct } from 'src/app/shared/services/structs/business-intelligence/panel-report-visualization.struct';

@Component({
  selector: 'app-bi-panel-register',
  templateUrl: './bi-panel-register.component.html',
  styleUrls: ['./bi-panel-register.component.css']
})
export class BiPanelRegisterComponent implements OnInit {

  constructor(private formBuilder: UntypedFormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private biPanelService: BiPanelService,
    private biLookupService: BiLookupService,
    private reportHealthUnitService: ReportHealthUnitService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.business_intelligence;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.bi_biPanel;

  public model: UntypedFormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;

  public masks: Masks;
  public idBiPanel: number;
  public biPanelRequest: BiPanelRequest;
  public listReport: BusinessIntelligenceStruct[];
  public listVisualizationTime: VisualizationTime[];

  ngOnInit(): void {
    this.isLoading = false;

    this.masks = this.maskService.getMasks();

    this.biPanelRequest = new BiPanelRequest();
    this.populateVisualizationTimeSelect();
    this.populateReportSelect();
    this.idBiPanel == null;
    this.isUpdate == false;
    if (this.activatedRoute.snapshot.paramMap.get('idBiPanel'))
      this.idBiPanel = parseInt(this.activatedRoute.snapshot.paramMap.get('idBiPanel'));

    this.model = this.formBuilder.group({
      biPanelName: ['', [Validators.required]],
      description: [''],
      listReport: this.formBuilder.array([])
    }); 

    if (this.idBiPanel != null) {
      this.isFirstLoading = true;
      this.isUpdate = true;
      this.populateBiPanelData();
    }
    else {
      this.addNext();
    }
  }

  submit() {
    if (this.isLoading) {
      return;
    }
    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }
    this.isLoading = true;
    this.biPanelRequest.listPanelReportTime = [];
    this.biPanelRequest.panelName = this.model.get('biPanelName').value;
    this.biPanelRequest.description = this.model.get('description').value;

    this.model.get('listReport').value.forEach(x => {
     
      var relation = new PanelReportVisualizationStruct();

      relation.idVisualizationTime = x.idVisualizationTime;
      relation.idReportFunctionHealthUnit = x.idReportFunctionHealthUnit;

      this.biPanelRequest.listPanelReportTime.push(relation);
      
    });
    if (this.isUpdate)
      this.updateBiPanel();
    else
      this.createBiPanel();
  }

  populateBiPanelData() {
    this.biPanelService.getBiPanel(this.idBiPanel).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.model.get('description').setValue(response.biPanel.description);
      this.model.get('biPanelName').setValue(response.biPanel.biPanelName);
      response.biPanel.listPanelReportVisualizationTime.forEach(x => {
        (this.model.controls['listReport'] as UntypedFormArray).push(
          this.formBuilder.group({
            id: [],
            idReportFunctionHealthUnit: [x.idReportFunctionHealthUnit.toString()],
            idVisualizationTime: [x.idVisualizationTime.toString()],
          })
        )
      })

      this.isLoading = false;
      this.isFirstLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  populateVisualizationTimeSelect(){
    this.biLookupService.listTime().subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listVisualizationTime = response.listVisualizationTime;            
    },
    (error)=>{
      console.log(error)
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  populateReportSelect(){
    this.reportHealthUnitService.getReports().subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.listReport = [];
      response.healthUnitReport.forEach((report)=>{
        report.reports.forEach((element)=>{
          this.listReport.push(element);
        })
      });          
    
    },
    (error)=>{
      console.log(error)
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  updateBiPanel() {
    this.biPanelService.updateBiPanel(this.idBiPanel, this.biPanelRequest).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/bi/bi-panel']);
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  createBiPanel() {
    this.biPanelService.createBiPanel(this.biPanelRequest).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/bi/bi-panel']);

    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  createInput() {
    return this.formBuilder.group({
      id: [''],
      idVisualizationTime: [, [Validators.required]],
      idReportFunctionHealthUnit: [, [Validators.required]],
    })
  }

  addNext() {
    (this.model.controls['listReport'] as UntypedFormArray).push(this.createInput())
  }

  remove(index: number) {
    if(index != 0) {
      (this.model.controls['listReport'] as UntypedFormArray).removeAt(index)
    }
  }
}
