import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { ReportModalComponent } from 'src/app/shared/components/report-modal/report-modal.component';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { BatchDownloadService } from 'src/app/shared/services/API/batch/batch-download.service';
import { BatchLookupService } from 'src/app/shared/services/API/batch/batch-lookup.service';
import { BatchRequestService } from 'src/app/shared/services/API/batch/batch-request.service';
import { BatchType } from 'src/app/shared/services/models/batch/batch-type.model';
import { ListBatchRequestsStruct } from 'src/app/shared/services/structs/batch/list-batch-requests.struct';

@Component({
  selector: 'app-batch-list',
  templateUrl: './batch-list.component.html',
  styleUrls: ['./batch-list.component.css']
})
export class BatchListComponent implements OnInit {
  constructor(
    private batchRequestService: BatchRequestService,
    private batchLookupService: BatchLookupService,
    private batchDownloadService: BatchDownloadService,
    private alertService: AlertService,
    public dialog: MatDialog,
    ) { }
  
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.master;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.master_batch;

  public isLoading: boolean;
  public idBatchType: number;
  public listBatchRequests: ListBatchRequestsStruct[];
  public listBatchType: BatchType[];

  ngOnInit(): void {
    this.isLoading = true;
    this.getLookUpData();
    this.search();
  }

  search() {
    this.isLoading = true;
    this.listBatchRequest();
  }

  listBatchRequest() {
    this.batchRequestService.listBatch(this.idBatchType).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }

      this.listBatchRequests = response.listBatchRequest?.listBatchRequestsStruct;

      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  getLookUpData() {
    this.batchLookupService.getBatchLookup().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }

      this.listBatchType = response.listBatchType;

      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  downloadFile(event: any) {
    if (this.isLoading)
      return;

    this.isLoading = true;
    this.batchDownloadService.downloadBatchReport(event).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      let nameArray = response.reportName.split(".");
      let fileName = response.reportName.replace(/\s/g, "_");
      let today = new Date();
      let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
      let time = today.getHours() + "-" + today.getMinutes() + "-" + today.getSeconds();
      let dateTime = date + '_' + time;
      fileName = fileName + "_" + dateTime;

      let blob = this.base64ToBlob(response.reportPdf64, `application/octet-stream`);
      let url = URL.createObjectURL(blob);
      let link = document.createElement('a');
      link.href = url;
      link.download = `${fileName}.${nameArray[1]}`;
      link.click();
      this.isLoading = false;
    },
      (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      });
  }

  public base64ToBlob(b64Data, contentType = '', sliceSize = 512) {
    b64Data = b64Data.replace(/\s/g, ''); //IE compatibility...
    let byteCharacters = atob(b64Data);
    let byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

}
