export enum VisibleFieldEnum {
    TodosOsCampos = 1,
    AtendimentoAlergias = 2,
    AtendimentoAnotaçõesMédicas = 3,
    AtendimentoExameFísica = 4,
    AtendimentoCID = 5,
    AtendimentoPlanoTerapêutico = 6,
    AtendimentoHipóteseDiagnóstica = 7,
    AtendimentoTemperatura = 8,
    AtendimentoFR = 9,
    AtendimentoFC = 10,
    AtendimentoGC = 11,
    AtendimentoPA = 12,
    AtendimentoSatO2 = 13,
    EvolucaoPaciente = 14,
    AtendimentoLinhaDeCuidadoEmPrioridade = 15
}