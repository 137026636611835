import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { KitItemSelectStruct } from 'src/app/shared/services/structs/pharmacy/kit-item-select.struct';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-prescription-modal-item-select',
  templateUrl: './prescription-modal-item-select.component.html',
  styleUrls: ['./prescription-modal-item-select.component.css']
})
export class PrescriptionModalItemSelectComponent implements OnInit {

  constructor() { }

  public amount: number;

  @Input() kitItemSelectStruct: KitItemSelectStruct;
  @Input() location: number;
  @Output() addItemEvent = new EventEmitter<void>();
  @Output() removeItemEvent = new EventEmitter<number>();

  public urlListAllItem: string;

  ngOnInit(): void {
    this.urlListAllItem = environment.urlApiPharmacy + `ItemSearchSelect?idLocation=${this.location.toString()}&`;
  }

  setItemId(event: any) {
    this.kitItemSelectStruct.formGroup.get('firstChildGroup').get('idItem').setValue(event.idItem);

    this.changeMaxAmount(event.amountAvaiable);
  }

  add() {
    this.addItemEvent.emit();
  }
  remove() {
    this.removeItemEvent.emit(this.kitItemSelectStruct.idField);
  }

  changeMaxAmount(maxValue: number) {
    this.amount = maxValue;
    this.kitItemSelectStruct.formGroup.get('amount').setValidators([Validators.required, Validators.min(0), Validators.max(maxValue)]);
    this.kitItemSelectStruct.formGroup.get('amount').updateValueAndValidity();
  }
}
