import { TotemUserStruct } from "./totem-user-struct";

export class HygienizationTotemStruct {
    public idHygienizationTotem: number;

    public hygienizationTotemName: string;

    public hygienizationTotemUrl: string;

    public pin: string;

    public pinUser: number;

    public isActive: boolean; 

    public idHealthUnit: number;

    public totemUsers: TotemUserStruct[];
}
