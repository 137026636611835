import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { ServiceTypeEnum } from 'src/app/shared/enum/service-type.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { SelfTriageConfigService } from 'src/app/shared/services/API/self-triage/self-triage-config.service';
import { SelfTriageLookupService } from 'src/app/shared/services/API/self-triage/self-triage-lookup.service';
import { GetTelemedicineConfigService } from 'src/app/shared/services/API/telemedicine/get-telemedicine-config.service';
import { MaskService } from 'src/app/shared/services/mask.service';
import { DirectionButton } from 'src/app/shared/services/models/self-triage/direction-button.model';
import { ServiceType } from 'src/app/shared/services/models/self-triage/service-type.model';
import { SelfTriageButtonConfigRequest } from 'src/app/shared/services/requests/self-triage/self-triage-button-config.request';
import { DirectionButtonStruct } from 'src/app/shared/services/structs/self-triage/direction-button.struct';
import { TelemedicineConfigStruct } from 'src/app/shared/services/structs/telemedicine/telemedicine-config.struct';

@Component({
  selector: 'app-self-triage-button-config',
  templateUrl: './self-triage-button-config.component.html',
  styleUrls: ['./self-triage-button-config.component.css']
})
export class SelfTriageButtonConfigComponent implements OnInit {

  constructor(
    private alertService: AlertService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private selfTriageConfigService: SelfTriageConfigService,
    private selfTriageLookupService: SelfTriageLookupService,
    private getTelemedicineConfigService: GetTelemedicineConfigService,
    private maskService: MaskService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.digital_prompt_service;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.self_triage_button_config;
  public model: FormGroup;

  public isLoading: boolean = true;
  public isFirstLoading: boolean = true;
  public isTelemedicineActive: boolean;

  public listDirectionButton: DirectionButton[];
  public listServiceType: ServiceType[];
  public telemedicineConfig: TelemedicineConfigStruct;

  public validationRegex: RegExp = /^[-a-zA-Z0-9_.,<>;+?@*$%#!&()"'{}_/:'"]+$/;
  
  public serviceTypeTelemedicine: number = ServiceTypeEnum.telemedicine;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      listButtons: this.formBuilder.array([]),
    });

    this.getSelfTriageConfig();
    this.getTelemedicineConfig();
  }

  submit() {
    if (this.isLoading)
      return

    this.isLoading = true;

    if (this.model.invalid) {
      this.model.get('listButtons')['controls'].forEach(x => {
        x.get('buttonName').markAsTouched();
        x.get('description').markAsTouched();
        x.get('buttonType').markAsTouched();
        x.get('buttonRedirection').markAsTouched();
      });
      this.alertDynamic('Erro', "Todos os campos em vermelho são de preenchimento obrigatório", AlertType.error);
      return;
    }

    let telemedicineButtons = this.model.get('listButtons').value.filter(x => x.buttonType == this.serviceTypeTelemedicine);
    if (telemedicineButtons && telemedicineButtons.length > 1) {
      this.alertDynamic('Erro', "Atenção! Já existe um direcionamento cadastrado com este tipo de botão.", AlertType.error);
      return;
    }

    let request: SelfTriageButtonConfigRequest = new SelfTriageButtonConfigRequest();
    this.model.get('listButtons').value.forEach(x => {
      var button = new DirectionButtonStruct();

      button.idDirectionButton = (x.idButton != '') ? x.idButton : 0;
      button.buttonName = x.buttonName;
      button.buttonDescription = x.description;
      button.idServiceType = x.buttonType;

      if (button.idServiceType == ServiceTypeEnum.Telefone.valueOf())
        button.directionAddress = this.maskService.formatPhoneToSave(x.buttonRedirection);
      else
        button.directionAddress = x.buttonRedirection;

      request.listButtons.push(button);
    });

    this.updateSelfTriageConfig(request);
  }

  updateSelfTriageConfig(request: SelfTriageButtonConfigRequest) {
    this.selfTriageConfigService.putButtonConfig(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.alertDynamic('Sucesso', "Informações salvas com sucesso", AlertType.success);
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getSelfTriageConfig() {
    this.selfTriageConfigService.getConfig().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listDirectionButton = response.selfTriageConfig.listButtons;

        if (!response.selfTriageConfig.listButtons || response.selfTriageConfig.listButtons.length <= 0)
          this.addNext();

        response.selfTriageConfig.listButtons.forEach(x => {
          (this.model.controls['listButtons'] as FormArray).push(
            this.formBuilder.group({
              idButton: [x.idDirectionButton],
              buttonName: [x.buttonName],
              description: [x.buttonDescription],
              buttonType: [x.idServiceType],
              buttonRedirection: [x.idServiceType == ServiceTypeEnum.Telefone.valueOf() ? this.maskService.formatPhoneToField(x.directionAddress) : x.directionAddress],
            })
          )
        });

        this.endLoading();
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getSelfTriageLookup() {
    this.selfTriageLookupService.getSelfTriageLookup().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listServiceType = response.listServiceType;

        if (!this.telemedicineConfig.isActive) {
          let telemedicineService = this.listServiceType.find(x => x.idServiceType == this.serviceTypeTelemedicine)
          telemedicineService.disabled = true;
        }

        this.endLoading();
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    });
  }

  createInput() {
    return this.formBuilder.group({
      buttonName: ['', [Validators.required]],
      description: [''],
      buttonType: [, [Validators.required]],
      buttonRedirection: ['', [Validators.required]],
    });
  }

  addNext() {
    (this.model.controls['listButtons'] as FormArray).push(this.createInput());
  }

  getTelemedicineConfig() {
    this.getTelemedicineConfigService.getConfig().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.telemedicineConfig = response.telemedicineConfig;
        if (this.telemedicineConfig && this.telemedicineConfig.isActive)
          this.isTelemedicineActive = true;

        this.getSelfTriageLookup();
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    });
  }

  alertDynamic(alertTypeDescription: string, alertDescription: string, alertType: AlertType) {
    if (alertType && alertType.valueOf() == AlertType.error)
      console.log(alertDescription);

    this.alertService.show(alertTypeDescription, alertDescription, alertType ? alertType.valueOf() : AlertType.error);
    this.endLoading();
  }

  endLoading() {
    this.isFirstLoading = false;
    this.isLoading = false;
  }
}