<div mat-dialog-content>
    <div class="mat-dialog-header">
        <h3>Reativar o paciente na unidade?</h3>
    </div>
    <div class="mat-dialog-content">
        <form class="form" [formGroup]="motiveModel" (ngSubmit)="clickOK()">
            <div class="row">
                <div class="col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Motivo</mat-label>
                        <textarea matInput formControlName="motive"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="footer-panel-modal-list">
                <div class="row">
                    <div class="col-6">
                        <button type="submit" mat-flat-button color="primary" class="btn-block" *ngIf="isLoading == false">Sim</button>
                    </div>
                    <div class="col-6">
                        <button mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Não</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>