import { KeyValue } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { CsatService } from 'src/app/shared/services/API/csat/csat.service';
import { FunctionalityStruct } from 'src/app/shared/services/structs/user/funcionality.struct';
import { ListModuleGroupStruct } from 'src/app/shared/services/structs/user/list-module-group.struct';
import { ModuleStruct } from 'src/app/shared/services/structs/user/module.struct';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.css']
})
export class MasterComponent implements OnInit {

  constructor(
    private util: UtilService,
    private router: Router,
    private csatService: CsatService
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.master;

  public isLoading: boolean = false;
  public listModuleGroupStruct: ListModuleGroupStruct[];
  public listModuleStruct: ModuleStruct[];
  public moduleStruct: ModuleStruct;

  public groupedFunctionalityStructs: Array<FunctionalityStruct[]> = [];
  public groupIndices: Object = new Object();
  public standaloneFunctionalityStructs: FunctionalityStruct[] = [];

  public seeButton: boolean = false;

  ngOnInit(): void {
    this.getCsat();
  }

  getCsat() {
    this.csatService.getCsat().subscribe({
      next: (response) => {
        this.seeButton = response.seeButton;
      },
    });
  }
}