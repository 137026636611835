import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LayoutEnum } from 'src/app/shared/enum/panel/layout.enum';

@Component({
  selector: 'app-display-options',
  templateUrl: './display-options.component.html',
  styleUrls: ['./display-options.component.css']
})
export class DisplayOptionsComponent implements OnInit {
  selectedTemplate: number;
  @Input() idPanel: number;
  @Input() idLayout: number;
  @Input() video64: string;
  @Input() videoFormat: string;
  @Input() logoString64: string;
  @Input() panelTime: Date;
  @Input() showAverageWaitingTime: boolean = false;
  @Input() lastHourWaitingTimeAverage: string = null;
  @Output() keepAlive: EventEmitter<any> = new EventEmitter();
  @Output() getDate: EventEmitter<any> = new EventEmitter();

  public layoutPrincipal: LayoutEnum = LayoutEnum.LayoutPrincipal;
  public layoutEsquerda: LayoutEnum = LayoutEnum.LayoutEsquerda;
  public layoutDireita: LayoutEnum = LayoutEnum.LayoutDireita;
  public layoutPrincipalV2: LayoutEnum = LayoutEnum.LayoutPrincipalV2;

  constructor() { }

  ngOnInit(): void {
    if (this.idLayout == LayoutEnum.LayoutEsquerda && this.video64 != null)
      this.setTemplate(LayoutEnum.LayoutEsquerda);
    else if (this.idLayout == LayoutEnum.LayoutDireita && this.video64 != null)
      this.setTemplate(LayoutEnum.LayoutDireita);
    else if (this.idLayout == LayoutEnum.LayoutPrincipalV2)
      this.setTemplate(LayoutEnum.LayoutPrincipalV2);
    else
      this.setTemplate(LayoutEnum.LayoutPrincipal);
  }

  setTemplate(template: number) {
    this.selectedTemplate = template;
  }

  keepPanelAlive() {
    this.keepAlive.emit();
  }

  getServerDate() {
    this.getDate.emit();
  }
}