<app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
<div class="white-body">
    <form class="form" [formGroup]="model" (ngSubmit)="selectFlowchart()" [appUppercaseForm]="this.setUppercaseText">
        <div class="row">
            <div class="col-12">
                <div class="row title-flowchart">
                    <div class="title col-6">
                        <h1 class="border-green-left-title">Seleção de Fluxograma</h1>
                        <div *ngIf="this.lastTriage && this.alertTimeDescription" class="last-triage" (click)="openLastTriageDetails()">
                            <span>Este paciente retornou em menos de {{this.alertTimeDescription}}</span>
                        </div>
                    </div>
                    <div class="col-2">
                        <mat-slide-toggle class="priorityAutistic" formControlName="priorityAutistic">
                            Paciente AUTISTA - TEA
                        </mat-slide-toggle>
                    </div>
                </div>
                <div class="row">
                    <div *ngIf="isSelfTriage" class="col-12 self-triage-button-header">
                        <button mat-flat-button color="primary" type="button" class="btn-block"
                            (click)="openSelfTriageResumeModal()">
                            Resumo Autotriagem
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row default-margin-bottom">
            <div class="col-12">
                <mat-form-field appearance="outline">
                    <mat-label>Queixas</mat-label>
                    <textarea matInput formControlName="complain"
                        (keyup)="this.listAIisClear ? null : onKeyChangeAi(); this.listAIisClear = true;"></textarea>
                    <mat-error *ngIf="model.get('complain').invalid">Informe a queixa do paciente</mat-error>
                </mat-form-field>
                <div class="col-12 col-sm-3" *ngIf="this.isAIEnabled">
                    <button mat-flat-button color="primary" type="button" class="btn-block button-search-ai"
                        (click)="searchAI()">
                        <span *ngIf="!isLoadingAi">
                            Ver fluxogramas sugeridos
                        </span>
                        <mat-icon class="my-icon" *ngIf="!isLoadingAi">search</mat-icon>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoadingAi"></mat-spinner>
                    </button>
                </div>
                <div class="flowchart-ai" *ngIf="isAIEnabled">
                    <p *ngIf="!noAiResult && (listAiFlowchart == undefined || listAiFlowchart.length == 0)">Por favor,
                        preencha o campo de queixas para receber sugestões de Fluxogramas.</p>
                    <p *ngIf="noAiResult">Nenhuma recomendação de Fluxograma foi encontrada para as queixas descritas.
                    </p>
                    <p *ngIf="listAiFlowchart != undefined && listAiFlowchart.length > 0">Fluxogramas Sugeridos</p>
                    <mat-spinner *ngIf="isLoadingAi" [diameter]="20" class="small"
                        style="display: inline-block;"></mat-spinner>
                    <div *ngIf="!isLoadingAi">
                        <div *ngFor="let item of listAiFlowchart">
                            <button mat-flat-button type="submit" (click)="idFlowchar=item.idFlowchart"
                                class="fluxograma-button fluxograma-button-small">
                                {{item.flowchartName}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="row" *ngIf="isAIEnabled">
            <div class="title-header">
                <div class="col-12 col-sm-8 col-md-10">
                    <h1>Fluxogramas Sugeridos</h1>
                </div>
            </div>
            <app-loading-list ></app-loading-list>
            <ng-container *ngIf="!isLoadingAi">
                <div *ngFor="let item of listAiFlowchart" class="col-12 col-sm-6 col-md-4">
                    <button mat-flat-button type="submit" (click)="idFlowchar=item.idFlowchart" class="fluxograma-button">
                        {{item.flowchartName}}
                    </button>
                </div>
                <div *ngIf="!noAiResult && (listAiFlowchart == undefined || listAiFlowchart.length == 0)">
                    <h3>Por favor, preencha o campo de queixas para receber sugestões de Fluxogramas.</h3>
                </div>
                <div *ngIf="noAiResult">
                    <h3>Nenhuma recomendação de Fluxograma foi encontrada para as queixas descritas.</h3>
                </div>
            </ng-container>
        </div> -->
        <div *ngIf="!isTelephone" class="row default-margin-bottom">
            <div class="title-header">
                <div class="col-12 col-sm-8 col-md-10">
                    <h1>Fluxogramas</h1>
                </div>
            </div>
            <div class="col-12 col-sm-4 col-md-2">
                <button mat-flat-button type="submit" (click)="idFlowchar=0" color="accent" class=" btn-block">
                    Branco
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-6 col-md-4 default-margin-bottom">
                <mat-form-field appearance="outline">
                    <mat-icon matPrefix class="my-icon">search</mat-icon>
                    <mat-label>Pesquisar</mat-label>
                    <input matInput type="text" (keyup)="onKeySearch($event)">
                </mat-form-field>
            </div>
            <div class="col-12 col-sm-6 col-md-4">
                <mat-form-field appearance="outline">
                    <mat-label>Grupos de Fluxograma</mat-label>
                    <mat-select (selectionChange)="onChangeSelect($event)" multiple>
                        <mat-option *ngFor="let item of listFlowchartGroup" [value]="item.idFlowchartGroup">
                            {{item.flowchartGroupName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div *ngFor="let item of listFilterFlowchart" class="col-12 col-sm-6 col-md-4">
                <button mat-flat-button type="submit" (click)="idFlowchar=item.idFlowchart" class="fluxograma-button">
                    {{item.flowchartName}}
                    <span class="icon-ask" [matTooltipPosition]="'above'" matTooltip="{{item.description}}"
                        matTooltipClass="tooltip-flochart"><mat-icon aria-hidden="false"
                            aria-label="Plus">help_outline</mat-icon></span>
                </button>
            </div>
        </div>
    </form>
</div>