<div [ngClass]="{'container-body-secundary-header': idEpisode !== null, 'container-body': idEpisode === null}">
    <a class="back-link" routerLink="/frontdesk/patient">
        <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Usuários
    </a>
    <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
    <div class="white-body">
        <div class="content" *ngIf="!findPatient">
            <div class="row newPatient">
                <h1 class="border-green-left-title col-6">Busca por paciente no siga</h1>
                <div class="col-12 col-sm-4 col-md-3">
                    <button mat-flat-button (click)="newPatient()" color="primary" class=" btn-block">
                        <span *ngIf="isLoading == false">Cadastrar paciente SIGA</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
            </div>
            <app-siga-search-patient-cpf [findPatient]="findPatient"
                (emitFindPatient)="getFindPatient($event)" (emitPatientData)="getPatientData($event)">
            </app-siga-search-patient-cpf>
            <app-siga-search-patient-cns [findPatient]="findPatient"
                (emitFindPatient)="getFindPatient($event)" (emitPatientData)="getPatientData($event)">
            </app-siga-search-patient-cns>
            <app-siga-search-patient-full-term [findPatient]="findPatient"
                (emitFindPatient)="getFindPatient($event)" (emitPatientData)="getPatientData($event)">
            </app-siga-search-patient-full-term>
        </div>
        <app-siga-patient-register-data *ngIf="findPatient" [patientData]="patientData"
            (emitFindPatient)="getFindPatient($event)" (clearPatientEvent)="clearPatientEvent()" (emitIsAttended)="setIsAttended($event)">
        </app-siga-patient-register-data>
    </div>
</div>