import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { OrchestratorRequestMaterialService } from 'src/app/shared/services/API/orchestrator-patient/orchestrator-request-material.service';
import { EpisodeStruct } from 'src/app/shared/services/structs/medical-record/episode.struct';
import { MedicalPrescriptionEpisodeStruct } from 'src/app/shared/services/structs/medical-record/medical-prescription-episode.struct';
import { MedicalPrescriptionStruct } from 'src/app/shared/services/structs/medical-record/medical-prescription.struct';
import { TriageStruct } from 'src/app/shared/services/structs/orchestrator-patient/triage.struct';
import { RequestMaterialStruct } from 'src/app/shared/services/models/pharmacy/request-material.model';
import { ItemModel } from 'src/app/shared/services/models/pharmacy/item.model';
import { ItemService } from 'src/app/shared/services/API/pharmacy/Item.service';
import { KitService } from 'src/app/shared/services/API/pharmacy/kit.service';
import { ProcedureModel } from 'src/app/shared/services/models/medic/procedure-model';
import { MedicalProcedureModel, MedicalProcedureWithName } from 'src/app/shared/services/models/medical-record/medical-procedure.model';
import { MedicalProcedureResponseModel } from 'src/app/shared/services/models/medical-record/medical-procedure-response.model';
import { MedicalRecordService } from 'src/app/shared/services/API/medical-record/medical-record.service';
import { MedicalProceduresService } from 'src/app/shared/services/API/orchestrator-patient/medical-procedures.service';
import { MaterialItemKitRequests, RequestMaterialRequests, RequestMaterialValidateStruct } from 'src/app/shared/services/requests/pharmacy/RequestMaterial.request';
import { RequestMaterialService } from 'src/app/shared/services/API/pharmacy/request-material.service';
import { BedManagementMaterialHistoryDeleteModal } from './bed-management-material-history-delete-modal.component/bed-management-material-history-delete-modal.component';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BedManagementMaterialValidateModal } from './bed-management-material-validate-modal.component/bed-management-material-validate-modal.component';
import { KitMultiselectStruct } from 'src/app/shared/services/structs/pharmacy/kit-multiselect.struct';
import { MedicineModel } from 'src/app/shared/services/models/pharmacy/medicine.model';
import { MedicineService } from 'src/app/shared/services/API/pharmacy/medicine.service';

@Component({
  selector: 'app-bed-management-material-history-modal',
  templateUrl: './bed-management-material-history-modal.component.html',
  styleUrls: ['./bed-management-material-history-modal.component.css']
})
export class BedManagementMaterialHistoryModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: ({ listMedicalProcedures: MedicalProcedureWithName[], medicalProcedure: MedicalProcedureModel, procedureList: ProcedureModel[], readOnly: boolean, idEpisode: number }),
    public matDialogRef: MatDialogRef<BedManagementMaterialHistoryModalComponent>,
    private orchestratorRequestMaterialService: OrchestratorRequestMaterialService,
    public requestMaterialService: RequestMaterialService,
    private alertService: AlertService,
    public medicalProceduresService: MedicalProceduresService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    public medicalRecordService: MedicalRecordService,
    public itemService: ItemService,
    public kitService: KitService,
    private medicineService: MedicineService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.pharmacy;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.pharmacy_prescription;

  public medicalPrescriptionEpisode: MedicalPrescriptionEpisodeStruct;
  public episode: EpisodeStruct;
  public listPrescription: MedicalPrescriptionStruct[];
  public listRequestMaterial: RequestMaterialStruct[];
  public RequestMaterialStruct: FormGroup;
  public requesMaterialModal: FormGroup;
  public isLoading: boolean;
  public idEpisode: number;
  public idSector: number;
  public triage: TriageStruct;
  public medicalProcedure: MedicalProcedureModel;
  public medicalProcedureResponse: MedicalProcedureResponseModel;
  public procedureList: ProcedureModel[];
  public selectedProcedureList: ProcedureModel[];
  public listProceduresByCbo: ProcedureModel[] = [];
  public listMedicalProcedures: MedicalProcedureWithName[];
  public listItems: ItemModel[] = [];
  public listMedicines: MedicineModel[] = [];
  public listKits: KitMultiselectStruct[] = [];

  ngOnInit(): void {
    this.listPrescription = [];
    this.idEpisode = this.data.idEpisode;
    this.isLoading = true;
    this.listMedicalProcedures = this.data.listMedicalProcedures.filter(y => y.idProcedureStatus == 1)
    this.search();
    this.populateKitList();
    this.populateItemList();
    this.populateMedicineList();
    this.listButtonReload();
    this.addNext();
  }

  listButtonReload() {
    this.requesMaterialModal = this.formBuilder.group({
      idRequestMaterial: [null],
      idProcedure: [null],
      observation: [],
      dateTimeInclusion: [null],
      listButtons: this.formBuilder.array([]),
    });
  }

  populateValues(requestMaterial: RequestMaterialStruct) {
    this.listButtonReload();
    this.requesMaterialModal.get('idRequestMaterial').setValue(requestMaterial.idRequestMaterial);
    this.requesMaterialModal.get('idProcedure').setValue(requestMaterial.idProcedure);
    this.requesMaterialModal.get('observation').setValue(requestMaterial.observation);
    this.requesMaterialModal.get('dateTimeInclusion').setValue(requestMaterial.dateTimeInclusion);
    requestMaterial.listRequestMaterialsItemKit.forEach(x => {
      let kitItem = x.idKit ? x.idKit + 'null' : 'null' + x.idItem;
      (this.requesMaterialModal.controls['listButtons'] as FormArray).push(
        this.formBuilder.group({
          kitItem: [kitItem],
          quantity: [x.quantity],
          idRequestMaterialItemKit: [x.idRequestMaterialItemKit],
          idRequestMaterial: [x.idRequestMaterial]
        })
      )
    })
  }

  populateItemList() {
    this.itemService.listAllItem().subscribe({
      next: (response) => {
        this.isLoading = false;
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        this.listItems = response.listItem;
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateMedicineList() {
    this.medicineService.listAllForKit(null).subscribe({
      next: (response) => {
        this.isLoading = false;
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        this.listMedicines = response.list;
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateKitList() {
    this.kitService.listAllKit().subscribe({
      next: (response) => {
        this.isLoading = false;
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        
        if(response.list && response.list.length > 0) {
          this.listKits = response.list.filter(x => !x.hasMedicines);
        }
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }


  populateMedicalProcedure(idEpisode: number) {
    this.medicalProceduresService.getObservationMedicalProcedure(idEpisode).subscribe({
      next: (response) => {
        this.isLoading = false;
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        this.listMedicalProcedures = response.listProcedures;
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  search() {
    this.isLoading = true;

    this.orchestratorRequestMaterialService.getAllRequestsByEpisode(this.idEpisode).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.listRequestMaterial = response.listRequestMaterials;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  clickCancel() {
    this.matDialogRef.close();
  }

  onSubmit() {

    if (this.isLoading) return;
    if (!this.requesMaterialModal.valid) {
      this.isLoading = false;
      this.alertService.show('Erro', "Preencha todos os campos obrigatórios!", AlertType.error);
      return;
    }
    this.isLoading = true;
    let request: RequestMaterialRequests = new RequestMaterialRequests();
    request.idRequestMaterial = this.requesMaterialModal.get('idRequestMaterial').value;
    request.idProcedure = this.requesMaterialModal.get('idProcedure').value;
    request.observation = this.requesMaterialModal.get('observation').value;
    request.dateTimeInclusion = null;
    request.idEpisode = this.idEpisode;
    request.isDeleted = false;
    request.idStatus = 0;
    request.validateQuantity = true;
    request.listRequestMaterialsItemKit = [];
    this.requesMaterialModal.get('listButtons').value.forEach(x => {
      let requestItemKit = new MaterialItemKitRequests;
      requestItemKit.idRequestMaterialItemKit = x.idRequestMaterialItemKit;
      requestItemKit.idRequestMaterial = x.idRequestMaterial;
      let kitItem = x.kitItem.split('null')
      requestItemKit.idKit = kitItem[0] ? parseInt(kitItem[0]) : null;
      requestItemKit.idItem = kitItem[1] ? parseInt(kitItem[1]) : null;
      requestItemKit.quantity = x.quantity;
      if (requestItemKit.quantity <= 0) {
        this.alertService.show('Erro', "É necessário adicionar ao menos um item em cada material", AlertType.error);
        this.isLoading = false;
        return;
      }
      request.listRequestMaterialsItemKit.push(requestItemKit);
    });

    if (request.idRequestMaterial > 0) {
      this.updateMaterialRequest(request);
    }
    else {
      this.createMaterialRequest(request);
    }
  }

  createMaterialRequest(request: RequestMaterialRequests) {
    this.requestMaterialService.postNewMaterial(request).subscribe({
      next: (response) => {
        this.isLoading = false;
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        if (response.listRequestMaterials.some(c => !c.hasEnough))
          this.openValidateModal(response.listRequestMaterials, request.listRequestMaterialsItemKit);
        else {
          this.listButtonReload();
          this.addNext();
          this.search();
          this.alertService.show('Sucesso', "Material solicitado com sucesso!", AlertType.success);
        }
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openValidateModal(listRequestMaterials: RequestMaterialValidateStruct[], listRequestMaterialsItemKit: MaterialItemKitRequests[]) {
    const dialogRef = this.dialog.open(BedManagementMaterialValidateModal, {
      maxWidth: '700px',
      data: {
        listRequestMaterials,
        listKits: this.listKits,
        listItems: this.listItems,
        listMedicines: this.listMedicines,
        listRequestMaterialsItemKit,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.result) {
        this.listButtonReload();
        listRequestMaterials.forEach(x => {
          let quantity = x.quantityNeeded > x.quantityHaving ? x.quantityHaving : x.quantityNeeded;
          if (quantity > 0) {
            (this.requesMaterialModal.controls['listButtons'] as FormArray).push(
              this.formBuilder.group({
                kitItem: ['null' + x.idItem],
                quantity: [quantity],
              })
            )
          }
        })
      }
    });
  }

  compareFN(optionValue: number[], selectionValue: number[]) {
    // compare two arrays
    return (
      optionValue.length === selectionValue.length &&
      optionValue.every((value, index) => value === selectionValue[index])
    );
  }

  updateMaterialRequest(request: RequestMaterialRequests) {
    request.dateTimeInclusion = this.requesMaterialModal.get('dateTimeInclusion').value;
    this.requestMaterialService.updateExistingMaterial(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.listButtonReload();
        this.addNext();
        this.search();
        this.alertService.show('Sucesso', "Material solicitado com sucesso!", AlertType.success);
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  close() {
    this.matDialogRef.close();
  }

  delete(idRequestMaterial: number) {
    const dialogRef = this.dialog.open(BedManagementMaterialHistoryDeleteModal, {
      data: {
        idRequestMaterial: idRequestMaterial
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      this.search();
    });
  }

  onSelect() {
    console.log(this.requesMaterialModal.get('kitItem').value);
  }

  createInput() {
    return this.formBuilder.group({
      kitItem: [null, [Validators.required]],
      quantity: [null, [Validators.required]],
      idRequestMaterialItemKit: [null],
      idRequestMaterial: [null]
    })
  }

  addNext() {
    (this.requesMaterialModal.controls['listButtons'] as FormArray).push(this.createInput())
  }

  removeButton(index: number) {
    if (index != 0) {
      (this.requesMaterialModal.controls['listButtons'] as FormArray).removeAt(index)
    }
  }
}

