import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CompletePatientDeviceRequest } from '../../requests/orchestrator-patient/complete-patient-device.request';
import { PostSyncMeasurerRequest } from '../../requests/orchestrator-patient/post-sync-measurer.request';
import { GetOneSyncMeasurerResponse } from '../../responses/orchestrator-patient/get-one-sync-measurer.response';
import { GetPatientByCpfResponse } from '../../responses/orchestrator-patient/get-patient-by-cpf.response';
import { ListPatientDeviceResponse } from '../../responses/orchestrator-patient/list-patient-device.response';
import { ListSyncMeasurerResponse } from '../../responses/orchestrator-patient/list-sync-measurer.response';

@Injectable({
  providedIn: 'root'
})
export class ListSyncMeasurerPatientService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listPatient(completePatientDeviceRequest: CompletePatientDeviceRequest): Observable<ListPatientDeviceResponse>{
    let uri = `ListSyncMeasurerPatient?`;

    return this.httpClient.post<ListPatientDeviceResponse>(environment.urlApiOrchestratorPatient + uri, completePatientDeviceRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
