<div [formGroup]="model" class="measurer-select">
    <h3 id="roomScroll">Medidores {{room.roomName}}</h3>
    <div class="row">
        <div class="col-6 col-md-3">
            <mat-form-field appearance="outline">
                <mat-label>Oxímetros</mat-label>
                <mat-select formControlName="idOximeter"
                    (selectionChange)="checkBluetooth($event.value, oximeterMeasurer)">
                    <mat-option *ngFor="let item of listOximeter" [value]="item.idMeasurer">
                        {{item.measurerName}}</mat-option>
                </mat-select>
                <mat-error *ngIf="model.get('idOximeter').invalid">Selecione o oxímetro</mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="oximeterDeviceBT != null" appearance="outline">
                <mat-label>Endereço MAC</mat-label>
                <input readonly matInput type="text" id="oximeterDevice" formControlName="addressOximeter">
            </mat-form-field>
        </div>
        <div class="col-6 col-md-3">
            <mat-form-field appearance="outline">
                <mat-label>Termômetros</mat-label>
                <mat-select formControlName="idThermometer"
                    (selectionChange)="checkBluetooth($event.value, thermometerMeasurer)">
                    <mat-option *ngFor="let item of listThermometer" [value]="item.idMeasurer">
                        {{item.measurerName}}</mat-option>
                </mat-select>
                <mat-error *ngIf="model.get('idThermometer').invalid">Selecione o termômetro</mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="thermometerDeviceBT != null" appearance="outline">
                <mat-label>Endereço MAC</mat-label>
                <input readonly matInput id="thermometerDevice" formControlName="addressThermometer">
                <mat-error *ngIf="model.get('addressThermometer').errors?.intersection">Endereço
                    duplicado</mat-error>
            </mat-form-field>
        </div>
        <div class="col-6 col-md-3">
            <mat-form-field appearance="outline">
                <mat-label>Medidores de Pressão</mat-label>
                <mat-select formControlName="idPressureMeasurer"
                    (selectionChange)="checkBluetooth($event.value, paMeasurer)">
                    <mat-option *ngFor="let item of listPressureMeasurer" [value]="item.idMeasurer">
                        {{item.measurerName}}</mat-option>
                </mat-select>
                <mat-error *ngIf="model.get('idPressureMeasurer').invalid">Selecione o medidor de pressão
                    arterial</mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="paDeviceBT != null" appearance="outline">
                <mat-label>Endereço MAC</mat-label>
                <input readonly matInput type="text" id="paDevice" formControlName="addressPressureMeasurer">
            </mat-form-field>
        </div>
        <div class="col-6 col-md-3">
            <mat-form-field appearance="outline">
                <mat-label>Glicosímetros</mat-label>
                <mat-select formControlName="idGlucometer"
                    (selectionChange)="checkBluetooth($event.value, glucometerMeasurer)">
                    <mat-option *ngFor="let item of listGlucometer" [value]="item.idMeasurer">
                        {{item.measurerName}}</mat-option>
                </mat-select>
                <mat-error *ngIf="model.get('idGlucometer').invalid">Selecione o glicosímetro</mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="glucometerDeviceBT != null" appearance="outline">
                <mat-label>Endereço MAC</mat-label>
                <input readonly matInput type="text" id="glucometerDevice" formControlName="addressGlucometer">
            </mat-form-field>
        </div>
    </div>
</div>