import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { ProcedureService } from 'src/app/shared/services/API/private-billing/procedure.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Procedure } from 'src/app/shared/services/models/private-billing/procedure.model';

@Component({
  selector: 'app-procedure-list',
  templateUrl: './procedure-list.component.html',
  styleUrls: ['./procedure-list.component.css']
})
export class ProcedureListComponent implements OnInit {
  constructor(private alertService: AlertService,
    public dialog: MatDialog,
    private procedureService: ProcedureService) {
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.billing;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.billing_procedure;

  public listProcedure: Procedure[] = [];
  public listProcedureBackup: Procedure[] = [];
  public isLoading: boolean = true;

  public buttonSelected: 'ativos' | 'inativos' | null = null;

  public searchText: string = null;
  public isActiveProcedures: boolean = null;

  public pageIndex: number = 0;
  public pageSize: number = 50;
  public fullListSize: number;

  ngOnInit(): void {
    this.controlButtonStatus('ativos');
  }

  search() {
    this.isLoading = true;

    this.procedureService.GetAll(this.searchText, this.pageIndex, this.isActiveProcedures).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.listProcedure = response.listProcedure;
        this.fullListSize = response.fullsize;

        this.isLoading = false;
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);    
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13){
        $this.pageIndex = 0;
        $this.search();
      }        
    },
      1000);
  }

  controlButtonStatus(buttonType: 'ativos' | 'inativos') {
    this.pageIndex = 0;

    if (this.buttonSelected === buttonType) {
      this.buttonSelected = null;   
      this.isActiveProcedures = null;   
      this.search();
    }
    else {
      this.buttonSelected = buttonType;

      if (this.buttonSelected == 'ativos'){
        this.isActiveProcedures = true;
        this.search();
      }        
      else if (this.buttonSelected == 'inativos'){
        this.isActiveProcedures = false;
        this.search();
      }        
    }
  }

  isButtonActive(buttonType: 'ativos' | 'inativos'): boolean {
    return buttonType === this.buttonSelected;
  }

  changePage(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.search();
  }

  putStatus(event: any, idProcedure: number) {
    this.procedureService.PutStatus(idProcedure, event.checked).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.isLoading = false;
        const procedureToUpdate = this.listProcedure.find(x => x.idProcedure === idProcedure);

        if (procedureToUpdate)
          procedureToUpdate.isActive = event.checked;

        this.alertService.show('Sucesso', 'Status do Procedimento: ' + procedureToUpdate.nameProcedure + ' atualizado com sucesso.', AlertType.success);
      },
      error: (error) => {
        this.throwException(error);
        this.search();
      }
    });
  }

  private throwException(error: string) {
    console.log(error);
    this.alertService.show('Erro inesperado', error, AlertType.error);
    this.isLoading = false;
  }

}