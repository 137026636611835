import { Component, OnInit } from '@angular/core';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { CsatService } from 'src/app/shared/services/API/csat/csat.service';

@Component({
  selector: 'app-integration',
  templateUrl: './integration.component.html',
  styleUrls: ['./integration.component.css']
})
export class IntegrationComponent implements OnInit {

  constructor(private csatService: CsatService) { }

  public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.integration;
  
  public seeButton: boolean = false;

  ngOnInit(): void {
    this.getCsat();
  }

  getCsat() {
    this.csatService.getCsat().subscribe({
      next: (response) => {
        this.seeButton = response.seeButton;
      },
    });
  }
}