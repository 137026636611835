import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CommonService } from "src/app/shared/services/common-service";
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';
import { catchError } from "rxjs/operators";
import { ReturnStruct } from "src/app/shared/structs/return.struct";
import { NursingConsultationRequest } from "../../requests/medical-record/nursing-consultation.request";
import { NursingConsultationResponse } from "../../responses/medical-record/nursing-consultation.response";
import { ListPatientHistoryResponse } from "../../responses/medical-record/list-patient-history.response";

@Injectable({
    providedIn: 'root'
})
export class PatientHistoryService extends CommonService {

    constructor(private router: Router, private httpClient: HttpClient) {
        super();
    }


    public getHistory(pageIndex: number, CPF: string, CNS: string, patientName: string, idEpisode: number, initialDate: string, finalDate: string, birthdate: string, idGravity: number, idOutcome: number, evaded: boolean): Observable<ListPatientHistoryResponse> {
        let uri = `PatientHistory?`

        if (pageIndex) {
            uri = uri + "pageIndex=" + pageIndex + "&";
        }

        if (CPF) {
            uri = uri + "CPF=" + CPF.replace(".", "").replace(".", "").replace("-", "") + "&";
        }

        if (CNS) {
            uri = uri + "CNS=" + CNS + "&";
        }

        if (patientName) {
            uri = uri + "patientName=" + patientName + "&";
        }

        if (idEpisode) {
            uri = uri + "idEpisode=" + idEpisode + "&";
        }

        if (initialDate) {
            uri = uri + "initialDate=" + initialDate + "&";
        }

        if (finalDate) {
            uri = uri + "finalDate=" + finalDate + "&";
        }

        if (birthdate) {
            uri = uri + "birthdate=" + birthdate + "&";
        }

        if (idGravity) {
            uri = uri + "idGravity=" + idGravity + "&";
        }

        if (idOutcome) {
            uri = uri + "idOutcome=" + idOutcome + "&";
        }

        if (evaded) {
            uri = uri + "evaded=" + evaded;
        }

        return this.httpClient.get<ListPatientHistoryResponse>(environment.urlApiMedicalRecord + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }
}