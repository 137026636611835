import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UtilsClientService } from 'src/app/client/utils.service';

@Component({
  selector: 'app-hygienization-pin-totem',
  templateUrl: './hygienization-pin-totem.component.html',
  styleUrls: ['./hygienization-pin-totem.component.css']
})
export class PinHygienizationTotemComponent implements OnInit {

  @Input() isLoading: boolean;

  @Output() onAuthenticated: EventEmitter<string> = new EventEmitter<string>();

  constructor( 
    private formBuilder: UntypedFormBuilder,
    private utilService: UtilsClientService,
  ) { }

  public model: UntypedFormGroup;
  public savePin: boolean = false;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      pin: ['', [Validators.required]],
    });

    this.verifyLogin();
  }

  submit(){
    if(this.model.invalid || this.isLoading == true)
      return;

    let pin = this.model.get('pin').value;

    if(this.savePin){
      this.utilService.updateTotemPin(pin.toString());
    }

    this.onAuthenticated.emit(pin);        
  }

  verifyLogin(){
    let pin = this.utilService.getTotemPin();

    if(pin) this.onAuthenticated.emit(pin);
  }
}
    