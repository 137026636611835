import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReportModalComponent } from 'src/app/shared/components/report-modal/report-modal.component';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { DownloadReportService } from 'src/app/shared/services/API/medical-record/download-report.service';
import { NursingConsultationStruct } from 'src/app/shared/services/structs/medical-record/nursing-consultation.struct';
import { DispensationStruct } from 'src/app/shared/services/structs/orchestrator-patient/dispensation.struct';

@Component({
  selector: 'app-dispensation-accordion',
  templateUrl: './dispensation-accordion.component.html',
  styleUrls: ['./dispensation-accordion.component.css']
})
export class DispensationAccordionComponent implements OnInit {

  @Input() dispensation: DispensationStruct;

  constructor(
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    
  }

  
}
