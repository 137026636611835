import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { SelfTriageConfig } from '../../models/self-triage/self-triage-config.model';
import { SelfTriageButtonConfigRequest } from '../../requests/self-triage/self-triage-button-config.request';
import { SelfTriageConfigRequest } from '../../requests/self-triage/self-triage-config.request';
import { GetConfigResponse } from '../../responses/self-triage/get-config.response';

@Injectable({
  providedIn: 'root'
})
export class SelfTriageConfigService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getConfig(): Observable<GetConfigResponse>{

    let uri = `SelfTriageConfig`;

    return this.httpClient.get<GetConfigResponse>(environment.urlApiSelfTriage + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public postConfig(request: SelfTriageConfigRequest): Observable<ReturnStruct>{

    let uri = `SelfTriageConfig`;

    return this.httpClient.post<ReturnStruct>(environment.urlApiSelfTriage + uri, request, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public putColorCodePreview(request: SelfTriageConfig): Observable<ReturnStruct>{

    let uri = `SelfTriageConfig/UpdateColorCodePreview`;

    return this.httpClient.put<ReturnStruct>(environment.urlApiSelfTriage + uri, request, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public putLogoNamePreview(request: SelfTriageConfigRequest): Observable<ReturnStruct>{

    let uri = `SelfTriageConfig/UpdateLogoNamePreview`;

    return this.httpClient.put<ReturnStruct>(environment.urlApiSelfTriage + uri, request, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public putButtonConfig(request: SelfTriageButtonConfigRequest): Observable<ReturnStruct>{

    let uri = `SelfTriageConfig`;

    return this.httpClient.put<ReturnStruct>(environment.urlApiSelfTriage + uri, request, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}