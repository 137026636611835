import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { PostPutConfigPanelLeanRequest } from '../../requests/panel-lean-config/post-put-config-panel-lean.request';
import { GetConfigPanelLeanResponse } from '../../responses/panel-lean-config/get-config-panel-lean.response';

@Injectable({
  providedIn: 'root'
})
export class ConfigPanelLeanService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public GetConfigPanelLeanByHealthUnit(): Observable<GetConfigPanelLeanResponse> {

    let uri = `ConfigPanelLean/getCompleteConfigByHealthUnit`;

    return this.httpClient.get<GetConfigPanelLeanResponse>(environment.urlApiPanelLeanConfig + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public PostPutConfigPanelLean(body: PostPutConfigPanelLeanRequest): Observable<ReturnStruct> {
    
    let uri = `ConfigPanelLean`;
    
    return this.httpClient.post<ReturnStruct>(environment.urlApiPanelLeanConfig + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
