import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilsClassificationService } from 'src/app/classification/utils.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { DiscriminatorModel } from 'src/app/shared/services/models/risk-classification/discriminator.model';
import { PreviousStateModalComponent } from './previous-state-modal/previous-state-modal.component';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { DiscriminatorTypeEnum } from 'src/app/shared/enum/discriminator-type.enum';
import { UtilsMeasurerService } from '../../protocol-v2/utils-measurer.service';
import { DeviceTypeSectorResponse } from 'src/app/shared/services/responses/risk-classification/device-type-sector.response';
import { DiscriminadorHeartRateSleepy, DiscriminadorHeartRegular } from 'src/app/shared/enum/risk-classification/discriminators.enum';

@Component({
  selector: 'app-heart-rate-modal',
  templateUrl: './heart-rate-modal.component.html',
  styleUrls: ['./heart-rate-modal.component.css']
})
export class HeartRateModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<HeartRateModalComponent>,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService, private utilService: UtilsClassificationService,
    private maskService: MaskService,
    public measurerService: UtilsMeasurerService) { }

  public model: UntypedFormGroup;
  public discriminator: DiscriminatorModel;
  public isChild: boolean = false;
  public isAfterSubmit: boolean = false;
  public masks: Masks;
  public initValue: any = null;
  public discriminatorType: DiscriminatorTypeEnum = DiscriminatorTypeEnum.FrequenciaCardiaca;
  public autisticChecked: boolean = false;
  public setUppercaseText: boolean = false;
  public awakeEnum = DiscriminadorHeartRateSleepy.awake;
  public sleepyEnum = DiscriminadorHeartRateSleepy.sleepy;
  public regularEnum = DiscriminadorHeartRegular.regular;
  public irregularEnum = DiscriminadorHeartRegular.irregular;

  ngOnInit(): void {
    let deviceTypeSectorResponse: DeviceTypeSectorResponse = this.utilService.getDevicetypeBySector()
    if (deviceTypeSectorResponse && deviceTypeSectorResponse.deviceTypeSector) {
      if (deviceTypeSectorResponse.deviceTypeSector.setUppercaseText)
        this.setUppercaseText = deviceTypeSectorResponse.deviceTypeSector.setUppercaseText;
    }

    this.measurerService.setModal(this.discriminatorType);
    this.masks = this.maskService.getMasks();
    this.discriminator = this.data.discriminator;
    this.isChild = this.data.age <= 140000;
    this.initValue = this.data.discriminatorsValues[this.discriminatorType];
    this.model = this.formBuilder.group({
      heartRate: [this.data.discriminatorsValues[this.discriminatorType], Validators.required],
      type: [this.data.description ? this.data.description : null, Validators.required],
      typeSomnolence: [this.data.state],
      discriminatorSelectionMotive: [],
      priorityAutistic: [false]
    });
    this.setSomnolenceType();
  }

  setSomnolenceType() {
    if (this.isChild) {
      this.model.get('typeSomnolence').setValidators(Validators.required);
    } else {
      this.model.get('typeSomnolence').setValidators(Validators.nullValidator);
    }
  }

  changePriorityAutistic(event: any) {
    this.model.get('heartRate').setValue(null);
    this.model.get('type').setValue(null);
    this.model.get('typeSomnolence').setValue(null);
    this.autisticChecked = event.checked
    if (event.checked) {
      this.model.get('discriminatorSelectionMotive').setValidators([Validators.required]);
      this.model.get('heartRate').disable();
      this.model.get('type').disable();
      this.model.get('typeSomnolence').disable();
    } else {
      this.model.get('discriminatorSelectionMotive').setValidators(Validators.nullValidator);
      this.model.get('heartRate').enable();
      this.model.get('type').enable();
      this.model.get('typeSomnolence').enable();
    }
    this.model.get('discriminatorSelectionMotive').updateValueAndValidity();
  }

  close() {
    this.measurerService.setModal(null);
    this.matDialogRef.close();
  }

  submit() {
    if (this.data.discriminatorsValues[this.discriminatorType] && (this.initValue == this.model.get("heartRate").value || (this.initValue != this.data.discriminatorsValues[this.discriminatorType] && !this.model.get("heartRate").value))) {
      this.model.get("heartRate").setValue(this.data.discriminatorsValues[this.discriminatorType]);
    }
    this.isAfterSubmit = true;
    if (this.model.invalid) {
      if (this.model.get('priorityAutistic').value) {
        this.alertService.show('Erro', "Preencha o motivo!", AlertType.error);
      } else {
        this.alertService.show('Erro', "Preencha os campos obrigatórios!", AlertType.error);
      }
      return;
    }
    if (this.model.get('priorityAutistic').value) {
      this.matDialogRef.close({ value: null, select: true, motive: this.model.get('discriminatorSelectionMotive').value });
    } else {
      let value = this.model.get("heartRate").value.replace("_", "");;
      let description = this.model.get("type").value;
      let state = this.model.get("typeSomnolence").value;
      let select = this.utilService.verifySelectHeartRate(this.discriminator, value, state, true);
      this.measurerService.setModal(null);
      if (this.data.isSepse) {
        this.matDialogRef.close({ value, select: false, description, state });
        return;
      } else if (select || description == DiscriminadorHeartRegular.irregular.valueOf().toString()) {
        const dialogRef = this.dialog.open(PreviousStateModalComponent);
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            select = result.response;
            this.matDialogRef.close({ value, select, description, state });
          }
        });
        return;
      } else {
        select = false;
      }
      this.matDialogRef.close({ value, select, description, state });
    }
  }
}

