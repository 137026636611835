import { RelationKpiLeanFieldHealthUnitModel } from "../../models/panel-lean-config/relation-kpi-lean-field-health-unit.model";

export class PostPutConfigPanelLeanRequest {
    public timeAverageMedicDoor: string;

    public maxTimeMedicDoor: string;

    public timeAverageMedicDecision: string;

    public maxTimeMedicDecision: string;

    public timeAverageDecisionExit: string;

    public maxTimeDecisionExit: string;

    public listRelationKpiLeanFieldHealthUnit: RelationKpiLeanFieldHealthUnitModel[] = []
}