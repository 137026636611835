import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { MedicalCareSadtRequest, SadtFile } from 'src/app/shared/services/requests/medical-record/medical-care-sadt.request';
import { SadtGroupStruct } from 'src/app/shared/services/structs/medical-record/sadt-group.struct';
import { environment } from 'src/environments/environment';
import { ConfirmConductSaveModalComponent } from '../confirm-conduct-save-modal/confirm-conduct-save-modal.component';
import { MedicalCareSadtFileService } from 'src/app/shared/services/API/medical-record/medical-care-sadt-file.service';

@Component({
  selector: 'app-sadt-accordion',
  templateUrl: './sadt-accordion.component.html',
  styleUrls: ['./sadt-accordion.component.css']
})
export class SadtAccordionComponent implements OnInit {

  @Input() sadt: SadtGroupStruct;
  @Input() isLoading: boolean;
  @Output() saveSadtData = new EventEmitter<any>();

  constructor(private alertService: AlertService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    public dialog: MatDialog,
    private medicalCareSadtFileService: MedicalCareSadtFileService,
  ) { }

  public model: FormGroup;
  public medicalCareSadtRequest: MedicalCareSadtRequest;
  public listProcedureSigtap: any[] = [];
  public urlListProcedure: string;
  public codCbo: string;
  public idCompetence: number;
  public isLoadingFile: boolean;

  ngOnInit(): void {
    this.medicalCareSadtRequest = new MedicalCareSadtRequest;
    this.medicalCareSadtRequest.listSadtFile = [];
    this.codCbo = this.authService.getTokenMenu().codCbo;
    this.idCompetence = this.authService.getTokenMenu().healthUnit.idProcedureSigtapCompetence;
    if (this.codCbo && this.idCompetence) {
      let url = `ProcedureSigtapCBO/codCbo/${this.codCbo}/idCompetence/${this.idCompetence}`
      this.urlListProcedure = environment.urlApiBilling + url;
    }

    this.model = this.formBuilder.group({
      observation: [''],
      observationMedic: [{ value: this.sadt.observationMedic ? this.sadt.observationMedic : "Nenhuma observação feita pelo médico!", disabled: true }],
      sadtResult: [''],
      sadtFiles: [null],
      fileLinkedLater: [true],
    });

    this.populateModel();

    this.isLoadingFile = false;
  }

  populateModel() {
    this.model.get('observation').setValue(this.sadt.observation);
    this.model.get('sadtResult').setValue(this.sadt.sadtResult);
    this.model.get('observationMedic').setValue(this.sadt.observationMedic);
    this.model.get('sadtFiles').setValue(this.sadt.sadtResultFile);
    this.model.get('fileLinkedLater').setValue(this.sadt.fileLinkedLater);
    if (this.model.get('fileLinkedLater').value == false && (this.model.get('sadtFiles').value != null || this.model.get('sadtResult').value != null)) {
      this.model.get('observation').disable();
      this.model.get('observationMedic').disable();
      this.model.get('sadtResult').disable();
      this.model.get('sadtFiles').disable();
      this.model.get('fileLinkedLater').disable();
      this.sadt.isSaved = true;
    }
  }

  addItemProcedure(event: any) {
    this.listProcedureSigtap = event;
  }

  submit() {
    if ((this.model.get('sadtResult') && this.model.get('sadtResult').value && this.model.get('sadtResult').value.trim().length == 0) && this.model.get('sadtFiles') && this.model.get('sadtFiles').value && this.model.get('sadtFiles').value.length === 0) {
      this.model.get('sadtResult').setValue(null);
      this.alertService.show('Erro', "Preencha pelo menos um dos campos obrigatórios para concluir a ação.", AlertType.error);
    }
    else if (this.model.get('sadtResult') && this.model.get('sadtResult').value && this.model.get('sadtResult').value.trim().length == 0) {
      this.model.get('sadtResult').setValidators(null);
      this.model.get('sadtResult').clearValidators();
      this.model.get('sadtResult').updateValueAndValidity();
    }
    else if (this.model.get('sadtFiles') && this.model.get('sadtFiles').value && this.model.get('sadtFiles').value.length === 0) {
      this.model.get('sadtFiles').setValidators(null);
      this.model.get('sadtFiles').clearValidators();
      this.model.get('sadtFiles').updateValueAndValidity();
    }

    if (!this.model.valid || this.sadt.isLoadingSadt)
      return;

    const dialogRef = this.dialog.open(ConfirmConductSaveModalComponent, {
      data: {
      },
      panelClass: "border-radius-box"
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result) {
          this.sadt.isLoadingSadt = true;
          this.sadt.isSaved = true;

          this.medicalCareSadtRequest.observation = this.model.get('observation').value;
          this.medicalCareSadtRequest.sadtResult = this.model.get('sadtResult').value;
          this.medicalCareSadtRequest.idSadtGroup = this.sadt.idSadtGroup;
          this.medicalCareSadtRequest.fileLinkedLater = this.model.get('fileLinkedLater').value;

          let eventBody = {
            medicalCareSadtRequest: this.medicalCareSadtRequest,
            listProcedureSigtap: this.listProcedureSigtap,
            codCbo: this.codCbo,
            sadt: this.sadt
          }

          this.saveSadtData.emit(eventBody);
          this.sadt.isLoadingSadt = false;
        }
        else
          return;
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  inputChange() {
    this.medicalCareSadtRequest.listSadtFile = [];
    var reader = new FileReader();

    if (!this.model.get('sadtFiles').value)
      return;

    for (let file of this.model.get('sadtFiles').value) {
      var reader = new FileReader();

      let fileExtension = file.name.split('.').pop();

      reader.onload = this._handleReaderLoaded.bind(this, [reader, fileExtension]);
      reader.readAsDataURL(file);
    };
  }

  _handleReaderLoaded(e) {
    let fileExtension = e[1];
    var fileResult = e[0].result;
    var string64 = fileResult.split("base64,", 2)[1];

    let sadtFile: SadtFile = new SadtFile();
    sadtFile.sadtFile64 = string64;
    sadtFile.fileExtension = fileExtension;
    this.medicalCareSadtRequest.listSadtFile.push(sadtFile);
    this.model.get('fileLinkedLater').setValue(false);
  }

  changeFileLinkedLater() {
    if (this.model.get('fileLinkedLater').value == true) {
      this.model.get('sadtResult').setValidators(null);
      this.model.get('sadtResult').clearValidators();
      this.model.get('sadtFiles').setValidators(null);
      this.model.get('sadtFiles').clearValidators();
    }
    else {
      this.model.get('sadtResult').setValidators(Validators.required);
      this.model.get('sadtFiles').setValidators(Validators.required);
    }

    this.model.get('sadtResult').updateValueAndValidity();
    this.model.get('sadtFiles').updateValueAndValidity();
  }

  isSubmitDisabled(): boolean {
    return this.sadt.isSaved;
  }

  alertCannotSave() {
    if (this.sadt.isSaved)
      return this.alertService.show('Alerta', "Atenção! Você já salvou esta conduta anteriormente.", AlertType.warning);
  }

  downloadFile(idSadtResultFile: number) {
    if (this.isLoadingFile)
      return;

    this.isLoadingFile = true;
    this.medicalCareSadtFileService.getMedicalCareSadtFile(idSadtResultFile).subscribe({
      next: (response) => {
        if (response.isError) {
          this.showAlert('Erro', response.errorDescription, AlertType.error);
          this.isLoadingFile = false;
          return;
        }
        else {
          const fileSadt = response.fileSadt;
          if (!fileSadt)
            this.showAlert('Erro', 'O arquivo não está disponível.', AlertType.error);
          else {
            const fileExtension = this.getFileExtension(fileSadt.fileNameSadt);
            if (this.isImageExtension(fileExtension))
              this.openFileInNewTab(fileSadt.sadtFile64, `image/${fileExtension}`);
            else if (fileExtension === 'pdf')
              this.openFileInNewTab(fileSadt.sadtFile64, 'application/pdf');
            else
              this.downloadFileBlob(fileSadt.sadtFile64, fileExtension, fileSadt.fileNameSadt);
          }
        }

        this.isLoadingFile = false;
      },
      error: (error) => {
        console.log(error);
        this.showAlert('Erro inesperado', error, AlertType.error);
        this.isLoadingFile = false;
      }
    });
  }

  private showAlert(title: string, message: string, alertType: AlertType) {
    this.alertService.show(title, message, alertType);
  }

  private getFileExtension(fileName: string): string {
    const nameArray = fileName.split(".");
    return nameArray[nameArray.length - 1].toLowerCase();
  }

  private isImageExtension(extension: string): boolean {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
    return imageExtensions.includes(extension);
  }

  private openFileInNewTab(base64Data: string, contentType: string) {
    const blob = this.base64ToBlob(base64Data, contentType);
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank');
  }

  private base64ToBlob(b64Data: string, contentType = '', sliceSize = 512): Blob {
    b64Data = b64Data.replace(/\s/g, ''); // IE compatibility...
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  private downloadFileBlob(base64Data: string, fileExtension: string, fileName: string) {
    const blob = this.base64ToBlob(base64Data, `application/${fileExtension}`);
    const blobUrl = URL.createObjectURL(blob);

    const anchor = document.createElement('a');
    anchor.href = blobUrl;
    anchor.download = fileName;
    anchor.style.display = 'none';

    document.body.appendChild(anchor);
    anchor.click();

    // Clean up
    document.body.removeChild(anchor);
    URL.revokeObjectURL(blobUrl);
  }
}