import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { KpiService } from 'src/app/shared/services/API/flow/kpi.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { KpiStruct } from 'src/app/shared/services/structs/flow/kpi.struct';
import { KpiConfigDeleteModalComponent } from './kpi-config-delete-modal/kpi-config-delete-modal.component';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-kpi-config-list',
  templateUrl: './kpi-config-list.component.html',
  styleUrls: ['./kpi-config-list.component.css']
})
export class KpiConfigListComponent implements OnInit {
  constructor(
    private alertService: AlertService,
    private kpiService: KpiService,
    private utilService: UtilService,
    public dialog: MatDialog,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.flow_management;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.flow_management_kpi_config;

  public isLoading: boolean = true;

  public listKpi: KpiStruct[] = [];
  public searchText: string = "";

  ngOnInit(): void {
    this.search();
  }

  search() {
    this.isLoading = true;
    this.kpiService.listKpi(this.searchText).subscribe({
      next: (response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listKpi = response.listKpi;

        if (this.listKpi) {
          let listIdSectors = this.listKpi.map(x => x.idSector);
          this.utilService.setKpiSectors(listIdSectors);
        }

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }

  openModal(idSector: number) {
    const dialogRef = this.dialog.open(KpiConfigDeleteModalComponent, {
      data: {
        idSector: idSector
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.confirm) {
        this.deleteConfig(idSector);
      }
    });
  }

  deleteConfig(idSector: number) {
    this.isLoading = true;
    this.kpiService.deleteKpi(idSector).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.alertService.show('Sucesso!', 'Regra excluída com sucesso', AlertType.success);
        this.search();
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }
}
