import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { LocationByModuleService } from 'src/app/shared/services/API/flow/location-by-module.service';
import { MedicConfigService } from 'src/app/shared/services/API/medic/medic-config.service';
import { MedicLookupService } from 'src/app/shared/services/API/medic/medic-lookup.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { RequiredFieldModel } from 'src/app/shared/services/models/medic/required-field.model';
import { MedicConfigRequest } from 'src/app/shared/services/requests/medic/medic-config.request';
import { SectorStruct } from 'src/app/shared/services/structs/flow/sector.struct';
import { MedicineHierarchyService } from 'src/app/shared/services/API/pharmacy/medicine-hierarchy.service';
import { HierarchyMedicineTypeStruct } from 'src/app/shared/services/structs/pharmacy/hierarchy-medicine-type-struct';
import { ExamService } from 'src/app/shared/services/API/SADT/exam.service';
import { ExamStruct } from 'src/app/shared/services/structs/SADT/exam.struct';
import { GetRoomService } from 'src/app/shared/services/API/flow/get-room.service';
import { RoomStruct } from 'src/app/shared/services/structs/flow/room.struct';
import { environment } from 'src/environments/environment';
import { FinalizationTypeModel } from 'src/app/shared/services/models/medic/finalization-type.model';
import { PatientRedirectTypeEnum } from 'src/app/shared/enum/medic/patient-redirect-type.enum';
import { CompleteMedicConfigStruct } from 'src/app/shared/services/structs/medic/complete-medic-config.struct';
import { VerifyHasTelemedicineInFlowService } from 'src/app/shared/services/API/flow/verif-has-telemedicine-in-flow.service';
import { GenericErrorModalComponent } from 'src/app/shared/components/generic-error-modal/generic-error-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { VerifyOriginHasTelemedicineRequest } from 'src/app/shared/services/requests/flow/verify-origin-has-telemedicine.request';

@Component({
  selector: 'app-medic-config-register',
  templateUrl: './medic-config-register.component.html',
  styleUrls: ['./medic-config-register.component.css']
})
export class MedicConfigRegisterComponent implements OnInit {

  constructor(private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private medicConfigService: MedicConfigService,
    private medicLookupService: MedicLookupService,
    private locationByModuleService: LocationByModuleService,
    private medicineHierarchyService: MedicineHierarchyService,
    private examService: ExamService,
    private getRoomService: GetRoomService,
    private verifyHasTelemedicineInFlowService: VerifyHasTelemedicineInFlowService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.medic;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.medic_config;

  public model: UntypedFormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;
  public actualServiceModule: number;

  public masks: Masks;
  public idMedicConfig: number;
  public medicConfigRequest: MedicConfigRequest;
  public listRequiredField: RequiredFieldModel[];
  public listFinalizationType: FinalizationTypeModel[];
  public listSector: SectorStruct[];
  public listHierarchyMedicineTypeStruct: HierarchyMedicineTypeStruct[];
  public isListAllMedicine: boolean = true;
  public listIdMedicine: number[] = [];
  public fastTrack: boolean = false;
  public allowEvasion: boolean = false;
  public isListAllExam: boolean = true;
  public listExam: ExamStruct[];
  public listExamDisplay: ExamStruct[];
  private timeoutKeySearch: any = null;
  public rooms: RoomStruct[] = [];
  public urlBase: string = environment.urlApiBaseUI + 'client/remote-attendance/';
  public config: CompleteMedicConfigStruct;
  public redirectToQueue = PatientRedirectTypeEnum.retorno_a_fila;
  public evade = PatientRedirectTypeEnum.evasao;
  public hasOriginFlowInTelemedicine: boolean = false;
  public listFlowNameHasTelemedicineInOrigin: string[];

  ngOnInit(): void {
    this.isLoading = false;
    this.model = this.formBuilder.group({
      name: ['', [Validators.required]],
      description: [''],
      listIdSector: [, [Validators.required]],
      listIdRequiredField: [],
      toggleAllMedicine: [true],
      toggleAllExam: [true],
      listIdMedicine: [],
      listIdExam: [],
      fastTrack: [false, [Validators.required]],
      allowEvasion: [true, [Validators.required]],
      allowRemote: [false],
      pin: [''],
      watingTime: [],
      finalizationType: [],
      maxReturns: [{ value: null, disabled: true }]
    });

    this.masks = this.maskService.getMasks();
    this.getMedicLookup();
    this.getSectors();
    this.medicConfigRequest = new MedicConfigRequest();

    this.idMedicConfig == null;
    this.isUpdate == false;
    if (this.activatedRoute.snapshot.paramMap.get('idMedicConfig'))
      this.idMedicConfig = parseInt(this.activatedRoute.snapshot.paramMap.get('idMedicConfig'));

    if (this.idMedicConfig != null) {
      this.isFirstLoading = true;
      this.isUpdate = true;
      this.populateMedicConfigData();
    }
    else {
      this.listAllMedicine();
      this.listAllExams();
    }
  }

  submit() {
    if (this.isLoading)
      return;

    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    if (this.model.value.idFinalizationType == this.redirectToQueue) {
      this.model.get('maxReturns').enable();
      this.model.get('maxReturns').updateValueAndValidity();
    }

    this.isLoading = true;

    this.medicConfigRequest.medicConfigDescription = this.model.get('description').value;
    this.medicConfigRequest.medicConfigName = this.model.get('name').value;
    this.medicConfigRequest.listIdSector = this.model.get('listIdSector').value;
    this.medicConfigRequest.listIdRequiredField = this.model.get('listIdRequiredField').value;
    this.medicConfigRequest.fastTrack = this.model.get('fastTrack').value;
    this.medicConfigRequest.allowEvasion = this.model.get('allowEvasion').value;
    this.medicConfigRequest.toggleAllMedicine = this.model.get('toggleAllMedicine').value;
    this.medicConfigRequest.toggleAllExam = this.model.get('toggleAllExam').value;
    this.medicConfigRequest.remoteAttendance = this.model.get('allowRemote').value;
    this.medicConfigRequest.pin = this.model.get('pin').value;
    this.medicConfigRequest.watingTime = this.model.get('watingTime').value;
    this.medicConfigRequest.idFinalizationType = this.model.get('finalizationType').value;
    this.medicConfigRequest.maxReturns = this.model.get('maxReturns').value;
    
    
    let listSectorSelected = this.listSector.filter(sector => this.medicConfigRequest.listIdSector.includes(sector.idSector));
    let notMedicSector: SectorStruct= listSectorSelected.find(sector => sector.idModule != this.menuModuleEnum);
    if(notMedicSector && this.medicConfigRequest.remoteAttendance){
      this.alertService.show('Erro', "Apenas setores do serviço médico podem ser habilitados para o atendimento remoto.", AlertType.error);
      this.isLoading = false;
      return;
    }

    if (this.model.get('toggleAllMedicine').value)
      this.medicConfigRequest.listIdMedicine = null;
    else
      this.medicConfigRequest.listIdMedicine = this.model.get('listIdMedicine').value;

    if (this.model.get('toggleAllExam').value)
      this.medicConfigRequest.listIdExam = null;
    else
      this.medicConfigRequest.listIdExam = this.model.get('listIdExam').value;

    if (this.medicConfigRequest.remoteAttendance == true){
      this.VerifyHasTelemedicineInSectorOrRoomFlow(this.medicConfigRequest.listIdSector);
    }
    else{
      if (this.isUpdate)
        this.updateMedicConfig();
      else
        this.createMedicConfig();
    }
    
  }

  populateMedicConfigData() {
    this.medicConfigService.getMedicConfig(this.idMedicConfig).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.config = response.medicConfig;
      this.listIdMedicine = response.medicConfig.medicConfigMedicines.map(x => x.idMedicine);
      this.isListAllMedicine = response.medicConfig.toggleAllMedicine;
      this.isListAllExam = response.medicConfig.toggleAllExam;
      this.model.get('listIdMedicine').setValue(response.medicConfig.medicConfigMedicines.map(x => x.idMedicine.toString()));
      this.model.get('name').setValue(response.medicConfig.medicConfigName);
      this.model.get('description').setValue(response.medicConfig.medicConfigDescription);
      this.model.get('listIdSector').setValue(response.medicConfig.medicConfigSectors.map(x => x.idSector));
      this.model.get('listIdRequiredField').setValue(response.medicConfig.medicConfigRequiredFields.map(x => x.idRequiredField));
      this.model.get('toggleAllMedicine').setValue(response.medicConfig.toggleAllMedicine);
      this.model.get('toggleAllExam').setValue(response.medicConfig.toggleAllExam);
      this.model.get('allowRemote').setValue(response.medicConfig.remoteAttendance);
      this.model.get('pin').setValue(response.medicConfig.pin);
      this.model.get('watingTime').setValue(response.medicConfig.watingTime);
      this.model.get('finalizationType').setValue(response.medicConfig.idFinalizationType);
      this.model.get('maxReturns').setValue(response.medicConfig.maxReturns);

      if (response.medicConfig.idFinalizationType == this.redirectToQueue) {
        this.model.get('maxReturns').enable();
        this.model.get('maxReturns').setValidators([Validators.required, Validators.max(5), Validators.min(1)]);
        this.model.get('maxReturns').updateValueAndValidity();
      }

      if (response.medicConfig.remoteAttendance)
        this.populateListOfLinks();

      if (!response.medicConfig.toggleAllExam && response.medicConfig.medicConfigExams)
        this.model.get('listIdExam').setValue(response.medicConfig.medicConfigExams.map(x => x.idExam));

      this.model.get('fastTrack').setValue(response.medicConfig.fastTrack);
      this.model.get('allowEvasion').setValue(response.medicConfig.allowEvasion);
      this.listAllMedicine();
      this.listAllExams();
      this.isLoading = false;
      this.isFirstLoading = false;
    },
      (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  updateMedicConfig() {
    this.medicConfigService.updateMedicConfig(this.idMedicConfig, this.medicConfigRequest).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/medic/medic-config']);
    },
      (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  createMedicConfig() {
    this.medicConfigService.createMedicConfig(this.medicConfigRequest).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/medic/medic-config']);
    },
      (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  getMedicLookup() {
    this.medicLookupService.getMedicLookup().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listRequiredField = response.listRequiredField;
        this.listFinalizationType = response.listFinalizationType;
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getSectors() {
    let listId: number[] = [];
    listId.push(MenuModuleEnum.medic);
    listId.push(MenuModuleEnum.observation);

    let jsonList = JSON.stringify(listId);

    this.locationByModuleService.listSectorByModule(jsonList).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.listSector = response.listSectorStruct;
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  listAllMedicine() {
    this.medicineHierarchyService.listAllMedicine().subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listHierarchyMedicineTypeStruct = response.listMedicineType;

      if (this.idMedicConfig)
        this.setIsChecked();
    },
      (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  listAllExams() {
    this.examService.listAllExam(null).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.listExam = response.listExam;
      this.listExamDisplay = [...this.listExam];
    },
      (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  VerifyHasTelemedicineInSectorOrRoomFlow(listSector: number[]) {
    let verifyOriginHasTelemedicineRequest: VerifyOriginHasTelemedicineRequest  = new VerifyOriginHasTelemedicineRequest;
    verifyOriginHasTelemedicineRequest.listIdSector = listSector;
    this.verifyHasTelemedicineInFlowService.verifyHasTelemedicineInOriginFlow(verifyOriginHasTelemedicineRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.hasOriginFlowInTelemedicine = response.hasOriginInTelemedicine;
        this.listFlowNameHasTelemedicineInOrigin = response.listFlowNameHasTelemedicineInOrigin;

        if (this.hasOriginFlowInTelemedicine){
          let bodyErros: string = "Não é possível salvar, pois um ou mais dos setores selecionados possuem fluxo(s) com origem na telemedicina. O(s) fluxo(s) em questão é(são): ";
          for (let i = 0; i < this.listFlowNameHasTelemedicineInOrigin.length; i++) {            
            bodyErros += this.listFlowNameHasTelemedicineInOrigin[i] + ", ";            
          }
          
          this.openHasTelemedicineErrorMessageModal(bodyErros);
          return;
        }
        else{
          if (this.isUpdate)
            this.updateMedicConfig();
          else
            this.createMedicConfig();
        }
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openHasTelemedicineErrorMessageModal(listSectorNameWithFlowOriginInTelemedicine: string){
    const dialogRef = this.dialog.open(GenericErrorModalComponent, {
      data: {
        body: listSectorNameWithFlowOriginInTelemedicine
      },
      panelClass: 'generic-error',
    });
    
    dialogRef.afterClosed().subscribe(result => {
      this.isLoading = false;
    });
  }

  setIsChecked() {
    this.listHierarchyMedicineTypeStruct.forEach(medicineType => {

      medicineType.listMedicineGroup.forEach(medicineGroup => {

        medicineGroup.listMedicine.forEach(medicine => {

          this.listIdMedicine.forEach(medicineConfig => {
            if (medicineConfig == medicine.idMedicine) {
              medicineGroup.isChecked = true;
              medicine.isChecked = true;
            }
          })
        });

        medicineGroup.listMedicineSubgroup.forEach(medicineSubgroup => {

          medicineSubgroup.listMedicine.forEach(medicine => {
            this.listIdMedicine.forEach(medicineConfig => {
              if (medicineConfig == medicine.idMedicine) {
                medicineSubgroup.isChecked = true;
                medicine.isChecked = true;
              }
            })

          });

          if (medicineSubgroup.isChecked)
            medicineGroup.isChecked = true;
        });

        if (medicineGroup.isChecked)
          medicineType.isChecked = true;
      });
    });
  }

  toggleAllMedicine(event: any) {
    this.model.get('listIdMedicine').setValue(null);
    if (!event.checked) {
      this.model.get('listIdMedicine').setValidators([Validators.required])
      this.model.get('listIdMedicine').updateValueAndValidity();
    }
    else {
      this.model.get('listIdMedicine').setValidators([])
      this.model.get('listIdMedicine').updateValueAndValidity();
    }

    this.model.get('toggleAllMedicine').setValue(event.checked);
    this.isListAllMedicine = event.checked;
  }

  toggleAllExam(event: any) {
    if (!event.checked) {
      this.model.get('listIdExam').setValidators([Validators.required]);
      this.model.get('listIdExam').updateValueAndValidity();
    }
    else {
      this.model.get('listIdExam').setValidators([]);
      this.model.get('listIdExam').updateValueAndValidity();
    }

    this.model.get('toggleAllExam').setValue(event.checked);
    this.isListAllExam = event.checked;
  }

  toggleFastTrack(event: any) {
    if (event.checked)
      this.model.get('fastTrack').setValue(true);
    else
      this.model.get('fastTrack').setValue(false);
  }

  toggleAllowEvasion(event: any) {
    if (event.checked)
      this.model.get('allowEvasion').setValue(true);
    else
      this.model.get('allowEvasion').setValue(false);
  }


  toggleAllowRemote(event: any) {
    let listIdSectorSelected: number[] = this.model.get('listIdSector').value;   
    let listSectorSelected = this.listSector?.filter(sector => listIdSectorSelected?.includes(sector.idSector));
    let notMedicSector: SectorStruct= listSectorSelected.find(sector => sector.idModule != this.menuModuleEnum);

    if(notMedicSector && this.model.get("allowRemote").value){
      this.model.get("allowRemote").setValue(false);
      this.model.get('allowRemote').updateValueAndValidity();
      this.alertService.show('Erro', "Apenas setores do serviço médico podem ser habilitados para o atendimento remoto.", AlertType.error);
      return;
    }
    
    if (event.checked) {
      this.model.get("watingTime").setValidators([Validators.required]);
      this.model.get("finalizationType").setValidators([Validators.required]);
      this.populateListOfLinks();
      this.model.get('pin').setValue((Math.random() + 1).toString(36).substring(2));
    } else {
      this.rooms = [];
      this.model.get('pin').setValue(null);
      this.model.get("watingTime").setValidators([]);
      this.model.get("watingTime").setValue(null);
      this.model.get('maxReturns').setValue(null);
      this.model.get('maxReturns').setValidators(null);
      this.model.get('maxReturns').updateValueAndValidity();
      this.model.get("finalizationType").setValidators([]);
      this.model.get("finalizationType").setValue(null);

      let returnType = this.model.get('finalizationType');

      this.updateRequirements(returnType);
    }

    this.model.get('watingTime').updateValueAndValidity();
    this.model.get('finalizationType').updateValueAndValidity();
  }

  populateListOfLinks() {
    let idSectors: number[] = this.model.get('listIdSector').value;
    let remote = this.model.get('allowRemote').value;
    if ((!idSectors || idSectors.length == 0) || !remote) {
      this.rooms = [];
      return;
    }

    this.getRoomService.getRoomsBySector(JSON.stringify(idSectors)).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.rooms = response.roomStruct;
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    })
  }

  populateListIdMedicineModel(event) {
    this.model.get('listIdMedicine').setValue(event);
  }

  onKey(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        let searchable: string = event.toLowerCase().trim();
        $this.listExamDisplay = $this.listExam.filter(x => x.examName.toLowerCase().trim().includes(searchable) ||
          x.examTypeName.toLowerCase().trim().includes(searchable) ||
          x.description.toLowerCase().trim().includes(searchable))
      }
    }, 1);
  }

  onCopy(text) {
    this.alertService.show('Sucesso', text, AlertType.success);
  }

  updateRequirements(event: any) {
    if (event.value == this.redirectToQueue) {
      this.model.get('maxReturns').enable();
      this.model.get('maxReturns').setValidators([Validators.required, Validators.max(5), Validators.min(1)]);
      this.model.get('maxReturns').updateValueAndValidity();
    }
    else {
      this.model.get('maxReturns').setValue(null);
      this.model.get('maxReturns').disable();
      this.model.get('maxReturns').setValidators(null);
      this.model.get('maxReturns').updateValueAndValidity();
    }
  }
}