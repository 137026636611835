<div class="content">
    <a class="back-link" routerLink="/frontdesk/patient">
        <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Usuários
    </a>
    <div class="row restart">
        <h1 class="border-green-left-title col-6">Cadastrar Paciente</h1>
        <div class="col-12 col-sm-4 col-md-3">
            <button mat-flat-button (click)="restart()" color="primary" class=" btn-block">
                <span *ngIf="isLoading == false">Recomeçar</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
        </div>
    </div>
    <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
    <div *ngIf="!isFirstLoading" class="white-body">
        <form class="form" [formGroup]="model">
            <div class="title-header">
                <div class="row">
                    <div class="col-6 col-sm-6 col-md-6">
                        <h1>Dados Pessoais</h1>
                    </div>
                </div>
            </div>
            <div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome</mat-label>
                            <input matInput type="text" maxlength="70" formControlName="patientName">
                            <mat-error *ngIf="model.get('patientName').invalid">Informe o nome do paciente</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome Social</mat-label>
                            <input matInput type="text" maxlength="70" formControlName="socialName">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Data de Nascimento</mat-label>
                            <input matInput type="text" (keyup)="onKeySearchAge($event)" formControlName="birthDate"
                                [mask]="masks.date">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Idade</mat-label>
                            <input matInput type="text" formControlName="patientAge">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-6 col-md-2">
                        <mat-slide-toggle class="priority-toggle"
                            formControlName="isPriority">Prioritário</mat-slide-toggle>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline" class="outline-spinner">
                            <mat-label>CPF</mat-label>
                            <input matInput type="text" formControlName="cpf" [mask]="masks.cpf"
                                (keyup)="onKeySearchCPF($event)">
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoadingCpf == true"></mat-spinner>
                            <mat-error *ngIf="model.get('cpf').invalid">Insira o CPF ou justificativa!</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <app-select-2 *ngIf="findLookup" [formControlField]="'cpfJustification'"
                            [list]="listJustification" (selectedValue)="cpfJustificationSelect($event)"
                            [multiple]="false" [idField]="'idJustificationSiga'" [nameField]="'justificationName'"
                            [labelField]="'Justificativa'" [hasNullSelectField]="true">
                        </app-select-2>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline" class="outline-spinner">
                            <mat-label>CNS</mat-label>
                            <input matInput type="text" formControlName="cns" [mask]="masks.cns"
                                (keyup)="onKeySearchCNS($event)">
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoadingCns == true"></mat-spinner>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <app-select-2 *ngIf="findLookup" [formControlField]="'idGender'" [list]="listSex"
                            [multiple]="false" [idField]="'idSexSiga'" [nameField]="'genderName'"
                            [labelField]="'Gênero'">
                        </app-select-2>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <app-select-2 *ngIf="findLookup" [formControlField]="'idRace'" [list]="listRaces"
                            [multiple]="false" [idField]="'idRaceSiga'" [nameField]="'raceName'" [labelField]="'Raça'">
                        </app-select-2>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <app-select-2 *ngIf="findLookup" [formControlField]="'idEthnicity'" [list]="listEthnicity"
                            [multiple]="false" [idField]="'idEthnicitySiga'" [nameField]="'ethnicityName'"
                            [labelField]="'Etnia'" [hasNullSelectField]="true">
                        </app-select-2>
                    </div>
                    <div class="row">
                        <div class="col-6 col-sm-3 col-md-2">
                            <app-select-2 *ngIf="findLookup" [formControlField]="'idFamilySituation'"
                                [list]="listFamilySituation" [multiple]="false" [idField]="'idFamilySituationSiga'"
                                [nameField]="'familySituationName'" [labelField]="'Situação Familiar'"
                                [hasNullSelectField]="true">
                            </app-select-2>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                            <app-select-2 *ngIf="findLookup" [formControlField]="'idEducation'" [list]="listEducation"
                                [multiple]="false" [idField]="'idEducationSiga'" [nameField]="'educationName'"
                                [labelField]="'Escolaridade'" [hasNullSelectField]="true">
                                <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                    *ngIf="isLoading == true"></mat-spinner>
                            </app-select-2>
                        </div>
                        <div class="col-6 col-sm-6 col-md-2 toggle">
                            <mat-slide-toggle class="frequents-school-toggle"
                                formControlName="frequentsSchool">Frequenta Escola</mat-slide-toggle>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                            <app-select-2 *ngIf="findLookup" [formControlField]="'idOccupation'"
                                [url]="urlListOccupation" [multiple]="false" [idField]="'idOccupationSiga'"
                                [nameField]="'occupationName'" [labelField]="'Ocupação'" [hasNullSelectField]="true">
                            </app-select-2>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 col-sm-6 col-md-2 toggle">
                        <mat-slide-toggle class="unknown-parents-toggle" (change)="changeUnknownMother($event)"
                            formControlName="unknownMother">Mãe desconhecida</mat-slide-toggle>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome da Mãe</mat-label>
                            <input matInput type="text" maxlength="70" formControlName="motherName">
                        </mat-form-field>
                    </div>

                    <div class="col-6 col-sm-6 col-md-2 toggle">
                        <mat-slide-toggle class="unknown-parents-toggle" (change)="changeUnknownFather($event)"
                            formControlName="unknownFather">Pai desconhecido</mat-slide-toggle>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome do Pai</mat-label>
                            <input matInput type="text" maxlength="70" formControlName="fatherName">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>RG</mat-label>
                            <input matInput type="text" maxlength="15" formControlName="rg">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-3">
                        <app-select-2 *ngIf="findLookup" [formControlField]="'idExpeditionIssuer'"
                            [list]="listEmissorEntity" [multiple]="false" [idField]="'idEmissorEntitySiga'"
                            [nameField]="'emissorEntityName'" [labelField]="'Órgão Expeditor'"
                            [hasNullSelectField]="true">
                        </app-select-2>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Data de Expedição</mat-label>
                            <input matInput type="text" formControlName="expeditionDate" [mask]="masks.date">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <app-select-2 *ngIf="findLookup" [formControlField]="'idExpeditionUf'" [list]="listState"
                            [multiple]="false" [idField]="'idStateSiga'" [nameField]="'stateInitials'"
                            [labelField]="'UF de Expedição'" [hasNullSelectField]="true">
                        </app-select-2>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <app-select-2 *ngIf="findLookup" [formControlField]="'idNationalityType'"
                            [list]="listNationalityType" [multiple]="false" [idField]="'idNationalitTypeSiga'"
                            [nameField]="'nationalityTypeName'" [labelField]="'Nacionalidade'">
                        </app-select-2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 col-sm-3 col-md-2">
                        <app-select-2 *ngIf="findLookup" [formControlField]="'idBirthCountry'" [list]="listNationality"
                            [multiple]="false" [idField]="'idNationalitySiga'" [nameField]="'nationalityName'"
                            [labelField]="'País de Nascimento'" [hasNullSelectField]="true">
                        </app-select-2>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <app-select-2 *ngIf="findLookup" [formControlField]="'idBirthCity'" [url]="urlListCity"
                            [multiple]="false" [idField]="'idCitySiga'" [nameField]="'cityName'"
                            [labelField]="'Cidade de Origem'" [hasNullSelectField]="true">
                        </app-select-2>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>DNV</mat-label>
                            <input matInput type="text" maxlength="15" formControlName="dnv">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Data de Entrada no País</mat-label>
                            <input matInput type="text" formControlName="countryArrivalDate" [mask]="masks.date">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Naturalização</mat-label>
                            <input matInput type="text" maxlength="50" formControlName="naturalizationOrdinance">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Data da Naturalização</mat-label>
                            <input matInput type="text" formControlName="naturalizationDate" [mask]="masks.date">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Protocolo de Cadastro</mat-label>
                            <input matInput type="text" maxlength="15" formControlName="registerProtocol">
                        </mat-form-field>
                    </div>
                </div>
                <div class="title-header">
                    <h1>Informações para Contato</h1>
                </div>
                <div class="row">
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Telefone Residencial</mat-label>
                            <input matInput type="text" formControlName="residentialPhone" [mask]="masks.phone">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2 toggle cell-phone-toggle">
                        <mat-slide-toggle formControlName="noCellPhone" (change)="changeCellPhone($event)">Não tem
                            celular</mat-slide-toggle>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Celular</mat-label>
                            <input matInput type="text" formControlName="cellPhone" [mask]="masks.phone">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Telefone Comercial</mat-label>
                            <input matInput type="text" formControlName="comercialPhone" [mask]="masks.phone">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Telefone para contato</mat-label>
                            <input matInput type="text" formControlName="contactPhone" [mask]="masks.phone">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>E-mail</mat-label>
                            <input matInput type="email" maxlength="100" formControlName="email">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome do contato</mat-label>
                            <input matInput type="text" maxlength="50" formControlName="contactName">
                        </mat-form-field>
                    </div>
                </div>
                <div class="title-header">
                    <h1>Identidade Estrangeira</h1>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Número da Identidade Estrangeira</mat-label>
                            <input matInput type="text" maxlength="15" formControlName="foreignIdentity">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Data de Expiração</mat-label>
                            <input matInput type="text" formControlName="foreignExpirationDate" [mask]="masks.date">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <app-select-2 *ngIf="findLookup" [formControlField]="'idForeignNaturality'"
                            [list]="listNationality" [multiple]="false" [idField]="'idNationalitySiga'"
                            [nameField]="'nationalityName'" [labelField]="'Naturalidade'" [hasNullSelectField]="true">
                        </app-select-2>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <app-select-2 *ngIf="findLookup" [formControlField]="'idForeignEmissor'"
                            [list]="listEmissorEntity" [multiple]="false" [idField]="'idEmissorEntitySiga'"
                            [nameField]="'emissorEntityName'" [labelField]="'Órgão Emissor'"
                            [hasNullSelectField]="true">
                        </app-select-2>
                    </div>
                </div>
                <div class="title-header">
                    <h1>Título de Eleitor</h1>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Número do Título de Eleitor</mat-label>
                            <input matInput type="text" maxlength="8" formControlName="voterRegistration">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Data de Emissão</mat-label>
                            <input matInput type="text" formControlName="voterEmissionDate" [mask]="masks.date">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Zona</mat-label>
                            <input matInput type="text" maxlength="8" formControlName="voterZone">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Sessão</mat-label>
                            <input matInput type="text" maxlength="8" formControlName="voterSection">
                        </mat-form-field>
                    </div>
                </div>
                <div class="title-header">
                    <h1>Carteira de Trabalho</h1>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Número da Carteira de Trabalho</mat-label>
                            <input matInput type="text" maxlength="15" formControlName="ctps">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Data de Emissão</mat-label>
                            <input matInput type="text" formControlName="ctpsEmissionDate" [mask]="masks.date">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <app-select-2 *ngIf="findLookup" [formControlField]="'idUfCtps'" [list]="listState"
                            [multiple]="false" [idField]="'idStateSiga'" [nameField]="'stateInitials'"
                            [labelField]="'UF'" [hasNullSelectField]="true">
                        </app-select-2>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Série</mat-label>
                            <input matInput type="text" maxlength="5" formControlName="ctpsSeries">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>PIS/PASEP</mat-label>
                            <input matInput type="text" maxlength="11" formControlName="pisPasep">
                        </mat-form-field>
                    </div>
                </div>
                <div class="title-header">
                    <h1>Carteira de Habilitação</h1>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>CNH</mat-label>
                            <input matInput type="text" maxlength="15" formControlName="cnh">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Data de Emissão</mat-label>
                            <input matInput type="text" formControlName="cnhEmissionDate" [mask]="masks.date">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Data de Expiração</mat-label>
                            <input matInput type="text" formControlName="cnhExpirationDate" [mask]="masks.date">
                        </mat-form-field>
                    </div>
                </div>
                <!-- <div class="title-header">
                    <h1>Certidões</h1>
                </div>

                <h3>Certidão de Nascimento</h3>
                <mat-slide-toggle class="health-insurance-toggle"
                    formControlName="isNewBirthCertificate">{{this.model.get('isNewBirthCertificate').value ? 'Certidão
                    Nova': 'Certidão Antiga'}}</mat-slide-toggle>
                <ngContainer *ngIf="this.model.get('isNewBirthCertificate').value">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Registro da Certidão de Nascimento</mat-label>
                                <input matInput type="text" maxlength="32" formControlName="newBirthCertificate">
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Data de Emissão</mat-label>
                                <input matInput type="text" formControlName="newBirthCertificateEmissionDate"
                                    [mask]="masks.date">
                            </mat-form-field>
                        </div>
                    </div>
                </ngContainer>
                <ngContainer *ngIf="!this.model.get('isNewBirthCertificate').value">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Nome do Cartório</mat-label>
                                <input matInput type="text" maxlength="15" formControlName="oldBirthCertificate">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Número da Folha</mat-label>
                                <input matInput type="text" maxlength="15"
                                    formControlName="oldBirthCertificatePageNumber">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Número do Livro</mat-label>
                                <input matInput type="text" maxlength="15"
                                    formControlName="oldBirthCertificateBookNumber">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Número da Termo</mat-label>
                                <input matInput type="text" maxlength="15"
                                    formControlName="oldBirthCertificateTermNumber">
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Data de Emissão</mat-label>
                                <input matInput type="text" formControlName="oldBirthCertificateEmissionDate"
                                    [mask]="masks.date">
                            </mat-form-field>
                        </div>
                    </div>
                </ngContainer>

                <h3>Certidão de Casamento</h3>
                <mat-slide-toggle class="health-insurance-toggle"
                    formControlName="isNewMarriageCertificate">{{this.model.get('isNewMarriageCertificate').value ?
                    'Certidão Nova': 'Certidão Antiga'}}</mat-slide-toggle>
                <ngContainer *ngIf="this.model.get('isNewMarriageCertificate').value">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Registro da Certidão de Casamento</mat-label>
                                <input matInput type="text" maxlength="32" formControlName="newMarriageCertificate">
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Data de Emissão</mat-label>
                                <input matInput type="text" formControlName="newMarriageCertificateEmissionDate"
                                    [mask]="masks.date">
                            </mat-form-field>
                        </div>
                    </div>
                </ngContainer>
                <ngContainer *ngIf="!this.model.get('isNewMarriageCertificate').value">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Nome do Cartório</mat-label>
                                <input matInput type="text" maxlength="15" formControlName="oldMarriageCertificate">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Número da Folha</mat-label>
                                <input matInput type="text" maxlength="15"
                                    formControlName="oldMarriageCertificatePageNumber">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Número do Livro</mat-label>
                                <input matInput type="text" maxlength="15"
                                    formControlName="oldMarriageCertificateBookNumber">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Número da Termo</mat-label>
                                <input matInput type="text" maxlength="15"
                                    formControlName="oldMarriageCertificateTermNumber">
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Data de Emissão</mat-label>
                                <input matInput type="text" formControlName="oldMarriageCertificateEmissionDate"
                                    [mask]="masks.date">
                            </mat-form-field>
                        </div>
                    </div>
                </ngContainer>

                <h3>Certidão de Divórcio</h3>
                <mat-slide-toggle class="health-insurance-toggle"
                    formControlName="isNewDivorceCertificate">{{this.model.get('isNewDivorceCertificate').value ?
                    'Certidão Nova': 'Certidão Antiga'}}</mat-slide-toggle>
                <ngContainer *ngIf="this.model.get('isNewDivorceCertificate').value">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Registro da Certidão de Divórcio</mat-label>
                                <input matInput type="text" maxlength="32" formControlName="newDivorceCertificate">
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Data de Emissão</mat-label>
                                <input matInput type="text" formControlName="newDivorceCertificateEmissionDate"
                                    [mask]="masks.date">
                            </mat-form-field>
                        </div>
                    </div>
                </ngContainer>
                <ngContainer *ngIf="!this.model.get('isNewDivorceCertificate').value">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Nome do Cartório</mat-label>
                                <input matInput type="text" maxlength="15" formControlName="oldDivorceCertificate">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Número da Folha</mat-label>
                                <input matInput type="text" maxlength="15"
                                    formControlName="oldDivorceCertificatePageNumber">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Número do Livro</mat-label>
                                <input matInput type="text" maxlength="15"
                                    formControlName="oldDivorceCertificateBookNumber">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Número da Termo</mat-label>
                                <input matInput type="text" maxlength="15"
                                    formControlName="oldDivorceCertificateTermNumber">
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Data de Emissão</mat-label>
                                <input matInput type="text" formControlName="oldDivorceCertificateEmissionDate"
                                    [mask]="masks.date">
                            </mat-form-field>
                        </div>
                    </div>
                </ngContainer>

                <h3>Registro Administrativo de Nascimento Indígena</h3>
                <mat-slide-toggle class="health-insurance-toggle"
                    formControlName="isNewIndigenousCertificate">{{this.model.get('isNewIndigenousCertificate').value ?
                    'Certidão Nova': 'Certidão Antiga'}}</mat-slide-toggle>
                <ngContainer *ngIf="this.model.get('isNewIndigenousCertificate').value">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>RANI</mat-label>
                                <input matInput type="text" maxlength="32"
                                    formControlName="newIndigenousAdmCertificate">
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Data de Emissão</mat-label>
                                <input matInput type="text" formControlName="newIndigenousAdmCertificateEmissionDate"
                                    [mask]="masks.date">
                            </mat-form-field>
                        </div>
                    </div>
                </ngContainer>
                <ngContainer *ngIf="!this.model.get('isNewIndigenousCertificate').value">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Nome do Cartório</mat-label>
                                <input matInput type="text" maxlength="15"
                                    formControlName="oldIndigenousAdmCertificate">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Número da Folha</mat-label>
                                <input matInput type="text" maxlength="15"
                                    formControlName="oldIndigenousAdmCertificatePageNumber">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Número do Livro</mat-label>
                                <input matInput type="text" maxlength="15"
                                    formControlName="oldIndigenousAdmCertificateBookNumber">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Número da Termo</mat-label>
                                <input matInput type="text" maxlength="15"
                                    formControlName="oldIndigenousAdmCertificateTermNumber">
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Data de Emissão</mat-label>
                                <input matInput type="text" formControlName="oldIndigenousAdmCertificateEmissionDate"
                                    [mask]="masks.date">
                            </mat-form-field>
                        </div>
                    </div>
                </ngContainer> -->

                <div class="title-header">
                    <h1>Passaporte</h1>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Passaporte</mat-label>
                            <input matInput type="text" maxlength="8" formControlName="passport">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <app-select-2 *ngIf="findLookup" [formControlField]="'idPassportEmissionCountry'"
                            [list]="listNationality" [multiple]="false" [idField]="'idNationalitySiga'"
                            [nameField]="'nationalityName'" [labelField]="'País de Emissão'"
                            [hasNullSelectField]="true">
                        </app-select-2>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Data de Emissão</mat-label>
                            <input matInput type="text" formControlName="passportEmissionDate" [mask]="masks.date">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Data de Expiração</mat-label>
                            <input matInput type="text" formControlName="passportExpirationDate" [mask]="masks.date">
                        </mat-form-field>
                    </div>
                </div>
                <div class="title-header">
                    <h1>Endereço</h1>
                </div>
                <div class="row">
                    <div class="col-6 col-sm-3 col-md-2">
                        <app-select-2 *ngIf="findLookup" [formControlField]="'idOriginAddress'"
                            [list]="listAddressOrigin" [multiple]="false" [idField]="'idAddressOriginSiga'"
                            [nameField]="'addressOriginName'" [labelField]="'Origem do Endereço'">
                        </app-select-2>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline" class="outline-spinner">
                            <mat-label>CEP</mat-label>
                            <input matInput type="text" maxlength="9" formControlName="zipCode"
                                (keyup)="onKeySearchCEP($event)">
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoadingCep == true"></mat-spinner>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <app-select-2 *ngIf="findLookup" [formControlField]="'idStreet'" [list]="listStreetType"
                            [multiple]="false" [idField]="'idStreetTypeSiga'" [nameField]="'streetTypeName'"
                            [labelField]="'Tipo do Logradouro'" [hasNullSelectField]="true">
                        </app-select-2>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Logradouro</mat-label>
                            <input matInput type="text" maxlength="50" formControlName="street">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-6 col-md-2 toggle house-number-toggle">
                        <mat-slide-toggle (change)="changeHasHouseNumber($event)" formControlName="noHouseNumber">Sem
                            número</mat-slide-toggle>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Número</mat-label>
                            <input matInput type="number" maxlength="8" formControlName="houseNumber">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Complemento</mat-label>
                            <input matInput type="text" maxlength="15" formControlName="houseComplement">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Ponto de Referência</mat-label>
                            <input matInput type="text" maxlength="80" formControlName="referencePoint">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Bairro</mat-label>
                            <input matInput type="text" maxlength="30" formControlName="neighborhood">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-3">
                        <app-select-2 *ngIf="findLookup" [formControlField]="'idCity'" [url]="urlListCity"
                            [multiple]="false" [idField]="'idCitySiga'" [nameField]="'cityName'" [labelField]="'Cidade'"
                            [hasNullSelectField]="true">
                        </app-select-2>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <app-select-2 *ngIf="findLookup" [formControlField]="'idAdmDistrict'" [list]="listDistrict"
                            [multiple]="false" [idField]="'idDistrictSiga'" [nameField]="'districtName'"
                            [labelField]="'Distrito Administrativo'" [hasNullSelectField]="true">
                        </app-select-2>
                    </div>
                </div>
                <div class="title-header">
                    <h1>Plano de Saúde</h1>
                </div>
                <div class="row">
                    <div class="col-6 col-sm-6 col-md-2 toggle">
                        <mat-slide-toggle class="health-insurance-toggle" (change)="changeHealthInsurance($event)"
                            formControlName="hasSusHealthInsurance">Possui Plano de Saúde</mat-slide-toggle>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <app-select-2 *ngIf="findLookup" [formControlField]="'idHealthInsurance'"
                            [url]="urlListHealthInsurance" [multiple]="false" [idField]="'idSigaHealthInsurance'"
                            [nameField]="'healthInsuranceName'" [labelField]="'Operadora'" [hasNullSelectField]="true">
                        </app-select-2>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Estabelecimento de Cadastro</mat-label>
                            <input matInput type="text" formControlName="cnesEstablishmentRegistrationCode">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Estabelecimento de Vínculo</mat-label>
                            <input matInput type="text" formControlName="cnesEstablishmentBondCode">
                        </mat-form-field>
                    </div>
                </div>
                <div class="title-header">
                    <h1>Procedência</h1>
                </div>
                <div class="row">
                    <div class="col-6 col-sm-3 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Origem</mat-label>
                            <mat-select formControlName="origin">
                                <mat-option ngValue="null">Selecione</mat-option>
                                <mat-option *ngFor="let item of originList"
                                    value="{{item.idOrigin}}">{{item.originName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Tipo de chegada</mat-label>
                            <mat-select formControlName="arrivalType">
                                <mat-option ngValue="null">Selecione</mat-option>
                                <mat-option *ngFor="let item of arrivalTypeList"
                                    value="{{item.idArrivalType}}">{{item.arrivalTypeName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Necessidades do paciente</mat-label>
                            <mat-select formControlName="listIdPatientNecessity" multiple>
                                <ng-container *ngFor="let item of listPatientNecessity">
                                    <mat-option value="{{item.idPatientNecessity}}">{{item.necessityName}}</mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Observação</mat-label>
                            <textarea matInput type="text" maxlength="300" formControlName="observation"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="title-header">
                    <h1>Acompanhante</h1>
                </div>
                <div class="row">
                    <div class="col-6 col-sm-3 col-md-3">
                        <mat-form-field appearance="outline" class="outline-spinner">
                            <mat-label>Nome</mat-label>
                            <input matInput type="text" formControlName="companionName">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Telefone</mat-label>
                            <input matInput type="text" formControlName="companionPhone" [mask]="masks.phone">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-3">
                        <mat-form-field appearance="outline" class="outline-spinner">
                            <mat-label>CPF</mat-label>
                            <input matInput type="text" formControlName="companionCpf" [mask]="masks.cpf">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Parentesco</mat-label>
                            <input matInput type="text" formControlName="companionParentage">
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="this.listMetaData.length > 0">
                    <div class="title-header">
                        <h1>Dados Adicionais</h1>
                    </div>
                    <div class="row">
                        <div *ngFor="let item of listMetaData" class="col-12 col-sm-6 col-md-4">
                            <mat-form-field *ngIf="item.metaData.idMetaDataType == metaDataTypeEnumResult.Texto"
                                appearance="outline">
                                <mat-label>{{item.metaData.metaDataName}}</mat-label>
                                <input matInput [readonly]="this.isUnidentifiedPatient"
                                    formControlName="{{item.metaData.idMetaData}}" type="text">
                            </mat-form-field>
                            <mat-form-field *ngIf="item.metaData.idMetaDataType == metaDataTypeEnumResult.Numero"
                                appearance="outline">
                                <mat-label>{{item.metaData.metaDataName}}</mat-label>
                                <input matInput [readonly]="this.isUnidentifiedPatient"
                                    formControlName="{{item.metaData.idMetaData}}" type="number">
                            </mat-form-field>
                            <mat-form-field *ngIf="item.metaData.idMetaDataType == metaDataTypeEnumResult.Data"
                                appearance="outline">
                                <mat-label>{{item.metaData.metaDataName}}</mat-label>
                                <input matInput [readonly]="this.isUnidentifiedPatient"
                                    formControlName="{{item.metaData.idMetaData}}" type="text" [mask]="masks.date">
                            </mat-form-field>
                            <mat-form-field *ngIf="item.metaData.idMetaDataType == metaDataTypeEnumResult.Select"
                                appearance="outline">
                                <mat-label>{{item.metaData.metaDataName}}</mat-label>
                                <mat-select [disabled]="this.isUnidentifiedPatient"
                                    formControlName="{{item.metaData.idMetaData}}">
                                    <mat-option *ngFor="let itemOption of item.listMetaDataOption"
                                        value="{{itemOption.metaDataOptionName}}">{{itemOption.metaDataOptionName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Observação</mat-label>
                                <textarea matInput type="text" formControlName="observation"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <button mat-flat-button type="button" (click)="submit(true)"
                        color="{{requireSignature ? 'primary' : 'accent'}}" class="btn-block">
                        <span *ngIf="!isLoading">Salvar com assinatura e Imprimir</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn"
                            *ngIf="isLoading"></mat-spinner>
                    </button>
                </div>
                <div *ngIf="!requireSignature" type="button" class="col-md-2">
                    <button mat-flat-button (click)="submit(false)"
                        color="{{requireSignature ? 'accent' : 'primary'}}" class="btn-block">
                        <span *ngIf="!isLoading">Salvar sem assinatura e Imprimir</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn"
                            *ngIf="isLoading"></mat-spinner>
                    </button>
                </div>
                <div class="col-md-2">
                    <a *ngIf="isLoading == false" mat-flat-button type="button"
                        [routerLink]="['/front-desk/patient-list', {idSector:idSector, idRoom: idRoom}]" color="accent"
                        class=" btn-block">
                        Cancelar
                    </a>
                </div>
            </div>
        </form>
    </div>
</div>