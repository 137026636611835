import { Socket } from 'socket.io-client';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { WebsocketAttendanceService } from '../websocket-attendance.service';
import { QueueService } from '../API/queue/queue.service';
import { StatusQueueEnumEnum } from '../../enum/queue/status-queue.enum';

@Injectable({
    providedIn: 'root'
})
export class WebsocketAttendanceUtilService {

    constructor(private websocketAttendanceService: WebsocketAttendanceService,
        private queueService: QueueService,
        private router: Router,) { }
    socket: Socket;
    idRoom: number;
    onClientAttendance: Function;
    onEmitAction: Function;
    onRemoveAttendant: Function;
    thisParam: any;
    isClassificationAttendance: boolean = false;


    isConnect(): boolean {
        return this.socket && this.socket.connected;
    }

    blankFunction() {

    }

    setFunctions(onClientAttendance: Function, onEmitAction: Function, onRemoveAttendant: Function) {
        this.onClientAttendance = onClientAttendance ? onClientAttendance : this.blankFunction;

        this.onEmitAction = onEmitAction ? onEmitAction : this.blankFunction;
        this.onRemoveAttendant = onRemoveAttendant ? onRemoveAttendant : this.blankFunction;
    }

    clearSocket(verifyIsClassificationAttendance: boolean = false): void {
        if (!verifyIsClassificationAttendance || this.isClassificationAttendance) {
            if (this.socket && this.socket.connected)
                this.socket.disconnect();

            this.isClassificationAttendance = false;
            this.socket = null;
        }
    }

    setContext(thisParam: any) {
        this.thisParam = thisParam;
    }

    emitFinalizeAttendance(idRoom) {
        if (this.socket && this.socket.connected)
            this.socket.emit('finalizeAttendance', `websocketAttendanceIdRoom-${idRoom}`)
    }

    emitMemedPrescriprtionSave(idRoom, userTokenMemed: string, listIdMemedPrescription: number[]) {
        if (this.socket && this.socket.connected)
            this.socket.emit('memedPrescriprtionSave', { room: `websocketAttendanceIdRoom-${idRoom}`, userTokenMemed: userTokenMemed, listIdMemedPrescription: listIdMemedPrescription })
    }

    emitRemoveAttendance(idRoom, idEpisode: number, idUser: number) {
        if (this.socket && this.socket.connected)
            this.socket.emit('removeAttendant', { room: `websocketAttendanceIdRoom-${idRoom}`, idEpisode: idEpisode, idUser: idUser })
    }

    connectwebsocketRoom(idRoom: number, idQueue: number, idEpisode: number, thisParam: any, isClassificationAttendance: boolean = false,
        isBedManagement: boolean = false, token: string = null, userLogin: string, userName: string): void {
        this.idRoom = idRoom;
        this.thisParam = thisParam;
        this.isClassificationAttendance = isClassificationAttendance;

        this.socket = this.websocketAttendanceService.websocketConnection();
        this.socket.emit('join', { room: `websocketAttendanceIdRoom-${this.idRoom}`, token: token, idQueue: idQueue, isBedManagement: isBedManagement, idEpisode: idEpisode, userLogin: userLogin, userName: userName })
            .on('onClientAttendance', (res) => {
                this.onClientAttendance(res, this.thisParam)
            })
            .on('clearClientSocket', (res) => {
                this.clearSocket()
            })
            .on('removeAttendant', (res) => {
                if (res.userLogin != userLogin && idEpisode == res.idEpisode) {
                    this.socket.emit("removeAttendant", { room: `websocketAttendanceIdRoom-${this.idRoom}` });
                    this.onRemoveAttendant(this.thisParam, res)
                }
            })
            .io.on("reconnect", (res) => {
                this.socket.emit('join', { room: `websocketAttendanceIdRoom-${this.idRoom}`, token: token, idQueue: idQueue, isBedManagement: isBedManagement, idEpisode: idEpisode, userLogin: userLogin, userName: userName })
            })

    }
}