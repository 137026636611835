import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MedicalRecordLookupService } from 'src/app/shared/services/API/medical-record/lookup.service';
import { CareLineLookupService } from 'src/app/shared/services/API/sync-measurer/lookup.service';
import { CareLineService } from 'src/app/shared/services/API/sync-measurer/care-line.service';
import { ListSyncMeasurerPatientService } from 'src/app/shared/services/API/orchestrator-patient/list-sync-measurer-patient.service';
import { CareLineTypeModel } from 'src/app/shared/services/models/sync-measurer/care-line-type.model';
import { CompletePatientDeviceRequest } from 'src/app/shared/services/requests/orchestrator-patient/complete-patient-device.request';
import { Gender } from 'src/app/shared/services/responses/medical-record/lookup.response';
import { ListPatientDeviceStruct } from 'src/app/shared/services/structs/orchestrator-patient/list-patient-device.struct';
import { UtilService } from 'src/app/shared/services/util.service';
import { ListedCareLineStruct } from 'src/app/shared/services/structs/sync-measurer/list-care-line.struct';
import { MatDialog } from '@angular/material/dialog';
import { UrgencyAlertModalComponent } from './urgency-alert-modal/urgency-alert-modal.component';

@Component({
  selector: 'app-remote-monitoring-patient-list',
  templateUrl: './remote-monitoring-patient-list.component.html',
  styleUrls: ['./remote-monitoring-patient-list.component.css']
})
export class RemoteMonitoringPatientListComponent implements OnInit {

  constructor(private listSyncMeasurerPatientService: ListSyncMeasurerPatientService,
    private router: Router,
    private alertService: AlertService,
    private utilService: UtilService,
    public lookupService: MedicalRecordLookupService,
    public careLineLookupService: CareLineLookupService,
    public careLineService: CareLineService,
    public dialog: MatDialog
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.remote_monitoring;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.master_exam;

  public listPatientDeviceStructs: ListPatientDeviceStruct[];
  public listRedPatientDeviceStructs: ListPatientDeviceStruct[];
  public listYellowPatientDeviceStructs: ListPatientDeviceStruct[];
  public isLoading: boolean;
  public isActive: boolean;
  public searchText: string;
  public listIdGender: number[];
  public listIdCareLine: number[];
  public listIdCareLineType: number[];
  public listGender: Gender[];
  public listCareLine: ListedCareLineStruct[];
  public listCareLineType: CareLineTypeModel[];


  ngOnInit(): void {
    this.isLoading = true;
    this.populateGenderSelect();
    this.populateCareLineSelect();
    this.populateCareLineTypeSelect();
  }
  search() {
    this.isLoading = true;
    if (this.searchText == "")
      this.searchText = null;

    let completePatientDeviceRequest: CompletePatientDeviceRequest = new CompletePatientDeviceRequest();
    completePatientDeviceRequest.searchText = this.searchText;
    completePatientDeviceRequest.listIdCareLineType = this.listIdCareLineType;
    completePatientDeviceRequest.listIdCareLine = this.listIdCareLine;
    completePatientDeviceRequest.listIdGender = this.listIdGender;

    this.listSyncMeasurerPatientService.listPatient(completePatientDeviceRequest).subscribe({next:(response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.listPatientDeviceStructs = response.listPatientDeviceStructs;
      this.listRedPatientDeviceStructs = response.listRedPatientDeviceStructs;
      this.listYellowPatientDeviceStructs = response.listYellowPatientDeviceStructs;
      this.populateGenderName(this.listPatientDeviceStructs);
      this.populateGenderName(this.listRedPatientDeviceStructs);
      this.isLoading = false;    
      this.populateGenderName(this.listYellowPatientDeviceStructs);
      if ((this.listRedPatientDeviceStructs && this.listRedPatientDeviceStructs.length > 0)
        || (this.listYellowPatientDeviceStructs && this.listYellowPatientDeviceStructs.length > 0)) {
        this.openModal()
      }

      this.isLoading = false;
    },
    error:(error) => {
      console.log(error)
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    }});
  }

  populateGenderSelect() {
    this.lookupService.getLookup().subscribe({next:(response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);

        return;
      }

      this.listGender = response.listGenders;
      this.search();
    },
    error:(error) => {
      console.log(error)
      this.alertService.show('Erro inesperado', error, AlertType.error);
    }});
  }

  populateCareLineSelect() {
    this.careLineService.listCareLineSelect().subscribe({next:(response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);

        return;
      }

      this.listCareLine = response.listCareLineStruct;
    },
    error:(error) => {
      console.log(error)
      this.alertService.show('Erro inesperado', error, AlertType.error);
    }});
  }

  populateCareLineTypeSelect() {
    this.careLineLookupService.listLookup().subscribe({next:(response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);

        return;
      }

      this.listCareLineType = response.listCareLineType;
    },
    error:(error) => {
      console.log(error)
      this.alertService.show('Erro inesperado', error, AlertType.error);
    }});
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }

  populateGenderName(listPatientDeviceStruct: ListPatientDeviceStruct[]) {
    for (var item of listPatientDeviceStruct) {
      for (var gender of this.listGender) {
        if (item.idGender == gender.idGender) {
          item.genderName = gender.genderName;
        }
      }
    }
  }
  openModal() {
    const dialogRef = this.dialog.open(UrgencyAlertModalComponent, {
      data: {
      },
    });
  }
}