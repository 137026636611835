export class PatientAvoidanceLog {
    public idPatientAvoidanceLog: number;
    public idPatient: number;
    public idHealthUnit: number;
    public idUser: number;
    public idEvasionScreen: number;
    public idEpisode: number;

    public patientExitLog: string;

    public datetimeInclusion: Date;
}