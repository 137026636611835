import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { TagStruct } from 'src/app/shared/components/tag/tag.struct';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { DashboardTagService } from 'src/app/shared/services/API/data-intelligente/dashboard-tag.service';
import { DashboardService } from 'src/app/shared/services/API/data-intelligente/dashboard.service';
import { DataSourceService } from 'src/app/shared/services/API/data-intelligente/data-source.service';
import { LookupDataService } from 'src/app/shared/services/API/data-intelligente/lookup-data.service';
import { LookupGraphService } from 'src/app/shared/services/API/data-intelligente/lookup-graph.service';
import { GetDashboardViewDataService } from 'src/app/shared/services/API/orchestrator-data-intelligence/get-dashboard-view-data.service';
import { AggregationModel } from 'src/app/shared/services/models/data-intelligence/aggregation.model';
import { ClassificationModel } from 'src/app/shared/services/models/data-intelligence/classification.model';
import { DashboardModel } from 'src/app/shared/services/models/data-intelligence/dashboard.model';
import { DataSourceVariableModel } from 'src/app/shared/services/models/data-intelligence/data-source-variable.model';
import { GraphTypeModel } from 'src/app/shared/services/models/data-intelligence/graph-type.model';
import { GraphWidthModel } from 'src/app/shared/services/models/data-intelligence/graph-width.model';
import { DashboardRequest } from 'src/app/shared/services/requests/data-intelligence/dashboard-request';
import { DashboardGraphsResponse } from 'src/app/shared/services/responses/orchestrator-data-intelligence/dashboard-graphs.response';
import { ListDataSourceStruct } from 'src/app/shared/services/structs/data-intelligence/list-data-source.struct';
import { GraphConfigModalComponent } from './graph-config-modal/graph-config-modal.component';
import { GraphDeleteModalComponent } from './graph-delete-modal/graph-delete-modal.component';

@Component({
  selector: 'app-graph-register',
  templateUrl: './graph-register.component.html',
  styleUrls: ['./graph-register.component.css']
})
export class GraphRegisterComponent implements OnInit, OnDestroy {

  constructor(
    private dashboardService: GetDashboardViewDataService,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private lookupGraphService: LookupGraphService,
    private dataSourceService: DataSourceService,
    private lookupService: LookupDataService,
  ) { }

  public dashboard: DashboardModel;
  public isFirstLoading: boolean;
  public isLoading: boolean;
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.dashboard;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.dashboard_dashboards;
  public idDashboard: number;
  public dashboardResponse: DashboardGraphsResponse = null;
  public isReload: boolean;

  private listGraphWidth: GraphWidthModel[];
  private listClassification: ClassificationModel[];
  private listAggregation: AggregationModel[];
  private listGraphType: GraphTypeModel[];
  private listDataSource: ListDataSourceStruct[];
  private listDataSourceVariable: DataSourceVariableModel[];
  private intervalOnlineId: any;

  ngOnInit(): void {
    this.isFirstLoading = true;
    this.idDashboard = parseInt(this.activatedRoute.snapshot.paramMap.get('idDashboard'));
    this.getDashboard(false);
    this.getLookup();
    this.populateDataSourceLookup();
    this.searchDataSource();
  }

  ngOnDestroy() {
    clearInterval(this.intervalOnlineId);
  }

  startInterval() {
    this.intervalOnlineId = setInterval(() => { this.getDashboard(true) }, 4000)
  }

  getDashboard(isReload: boolean) {
    if (this.isLoading) {
      return;
    }
    this.isReload = isReload;
    this.isLoading = true;
    this.dashboardResponse = null;
    this.dashboardService.getDashboardData(this.idDashboard).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.dashboard = response.dashboard;
      this.dashboardResponse = response;
      this.isLoading = false;
      if (this.isFirstLoading) {
        //this.startInterval();
      }
      this.isFirstLoading = false;
    },
      (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  makeFullscreen() {

  }

  getLookup() {
    this.lookupGraphService.get().subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.listGraphWidth = response.graphWidth;
      this.listClassification = response.classification;
      this.listAggregation = response.aggregation;
      this.listGraphType = response.graphType;
    },
      (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  searchDataSource() {
    this.dataSourceService.getAll(null, []).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.listDataSource = response.listDataSource;
    },
      (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  openModal(idGraph: number) {
    const dialogRef = this.dialog.open(GraphConfigModalComponent, {
      disableClose: true,
      data: {
        idGraph,
        idDashboard: this.idDashboard,
        listGraphWidth: this.listGraphWidth,
        listClassification: this.listClassification,
        listAggregation: this.listAggregation,
        listGraphType: this.listGraphType,
        listDataSource: this.listDataSource,
        listDataSourceVariable: this.listDataSourceVariable,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.register) {
        this.getDashboard(false);
      }
    });
  }

  openModalExclude(idGraph: number) {
    const dialogRef = this.dialog.open(GraphDeleteModalComponent, {
      disableClose: true,
      data: {
        idGraph,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.graphDeleted) {
        this.getDashboard(false);
      }
    });
  }

  populateDataSourceLookup() {
    this.lookupService.listLookup(true).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.listDataSourceVariable = response.listDataSourceVariable;

      this.isLoading = false;
    },
      (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      });
  }
}
