import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ComorbidityEnum } from 'src/app/shared/enum/comorbidity.enum';
import { MedicineEnum } from 'src/app/shared/enum/medicine.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ViaCepService } from 'src/app/shared/services/API/integration/via-cep.service';
import { GetPatientByCpfService } from 'src/app/shared/services/API/medical-record/get-patient-by-cpf.service';
import { ListPatientByCnsService } from 'src/app/shared/services/API/medical-record/list-patient-by-cns.service';
import { MedicalRecordLookupService } from 'src/app/shared/services/API/medical-record/lookup.service';
import { MedicineService } from 'src/app/shared/services/API/pharmacy/medicine.service';
import { CareLineService } from 'src/app/shared/services/API/sync-measurer/care-line.service';
import { CareLineLookupService } from 'src/app/shared/services/API/sync-measurer/lookup.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { Comorbidity, Gender } from 'src/app/shared/services/responses/medical-record/lookup.response';
import { PatientStruct } from 'src/app/shared/services/structs/medical-record/patient.struct';
import { MedicineStruct } from 'src/app/shared/services/structs/pharmacy/medicine.struct';
import { ListedCareLineStruct } from 'src/app/shared/services/structs/sync-measurer/list-care-line.struct';
import { CnsPatientModalComponent } from '../cns-patient-modal/cns-patient-modal.component';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-link-patient',
  templateUrl: './link-patient.component.html',
  styleUrls: ['./link-patient.component.css']
})
export class LinkPatientComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<LinkPatientComponent>,
    private alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
    private utilService: UtilService,
    public maskService: MaskService,
    public getPatientByCpfService: GetPatientByCpfService,
    private listPatientByCnsService: ListPatientByCnsService,
    public viaCepService: ViaCepService,
    public lookupService: MedicalRecordLookupService,
    public careLineService: CareLineService,
    private medicineService: MedicineService,
    private careLineLookupService: CareLineLookupService,
    private authService: AuthService,
  ) { }

  public model: UntypedFormGroup;
  public isUpdate: boolean;
  public isLoading: boolean;
  public isLoadingCpf: boolean;
  public isLoadingCns: boolean;
  public isLoadingCep: boolean;
  public masks: Masks;
  public patientAge: string = null;
  public idPatient: number;
  public genderList: Gender[];
  public listComorbidity: Comorbidity[];
  public listCareLine: ListedCareLineStruct[];
  public listMedication: MedicineStruct[];
  public hasComorbidityOutros: boolean;
  public idMedicineOutros: number = -1;
  public hasMedicationOutros: boolean;

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.isUpdate = false;
    this.isLoading = false;
    this.isLoadingCpf = false;
    this.isLoadingCns = false;
    this.isLoadingCep = false;

    this.model = this.formBuilder.group({
      idPatient: [null],
      idHealthUnitCreator: [null],
      birthDate: ['', [Validators.required]],
      patientName: ['', [Validators.required]],
      cpf: ['', [Validators.required]],
      cns: [''],
      idGender: [''],
      zipCode: [''],
      street: ['', [Validators.required]],
      neighborhood: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      country: ['', [Validators.required]],
      houseNumber: ['', [Validators.required]],
      apartmentNumber: [''],
      listIdComorbidity: [],
      otherComorbidity: [''],
      listIdMedicine: [],
      otherMedicine: [''],
      listIdCareLine: [],
      phoneNumber: [''],

      healthUnitName: [{ value: this.authService.getTokenMenu().healthUnit.healthUnitName, disabled: true }],
      idPatientEmployee: [''],
      unitBranch: [''],
      sectorEmployee: [''],
      roleEmployee: [''],
      registryEmployee: ['']
    });

    this.populateComorbiditySelect();
    this.populateLookupSelect();
    this.populateCareLineSelect();
    this.populateMedicineSelect();

    if (this.data.patient != null) {
      this.isUpdate = true;
      this.populatePatient();
    }
  }

  clickCancel() {
    this.matDialogRef.close();
  }

  submit() {
    if (!this.model.valid || this.isLoading)
      return;

    if (!this.isUpdate)
      this.data.patient = new PatientStruct;

    this.data.patient.idPatient = this.model.get('idPatient').value;
    this.data.patient.idHealthUnitCreator = this.model.get('idHealthUnitCreator').value;
    this.data.patient.idHealthUnitCreator = this.authService.getTokenMenu().healthUnit.idHealthUnit;
    this.data.patient.patientName = this.model.get('patientName').value;
    this.data.patient.cpf = this.model.get('cpf').value.replace(/[^0-9]+/g, '');
    this.data.patient.birthDate = this.maskService.formatStringToDate(this.model.get('birthDate').value);
    this.data.patient.cns = this.model.get('cns').value?.replace(/[^0-9]+/g, '').replace(" ", '');
    this.data.patient.idGender = this.model.get('idGender').value ? this.model.get('idGender').value : null;
    this.data.patient.zipCode = this.model.get('zipCode').value;
    this.data.patient.street = this.model.get('street').value;
    this.data.patient.neighborhood = this.model.get('neighborhood').value;
    this.data.patient.city = this.model.get('city').value;
    this.data.patient.state = this.model.get('state').value;
    this.data.patient.country = this.model.get('country').value;
    this.data.patient.houseNumber = this.model.get('houseNumber').value;
    this.data.patient.apartmentNumber = this.model.get('apartmentNumber').value;
    this.data.patient.phone1 = this.maskService.formatPhoneToSave(this.model.get('phoneNumber').value);
    this.data.patient.isUnidentifiedPatient = false;

    //Relações paciente
    this.data.patient.listIdMedicine = this.model.get('listIdMedicine').value;
    this.data.patient.otherMedicine = this.model.get('otherMedicine').value;
    this.data.patient.listIdComorbidity = this.model.get('listIdComorbidity').value;
    this.data.patient.otherComorbidity = this.model.get('otherComorbidity').value;
    this.data.patient.listIdCareLine = this.model.get('listIdCareLine').value;

    //Dados de funcionário
    this.data.patient.unitBranch = this.model.get('unitBranch').value;
    this.data.patient.sectorEmployee = this.model.get('sectorEmployee').value;
    this.data.patient.roleEmployee = this.model.get('roleEmployee').value;
    this.data.patient.registryEmployee = this.model.get('registryEmployee').value;

    this.matDialogRef.close({
      patient: this.data.patient,
      isUpdate: this.isUpdate,
    });
  }

  populatePatient() {
    this.model.get('idPatient').setValue(this.data.patient.idPatient);
    this.model.get('idHealthUnitCreator').setValue(this.authService.getTokenMenu().healthUnit.idHealthUnit);
    this.model.get('patientName').setValue(this.data.patient.patientName);
    let birthDate = this.maskService.formatDateToString(this.data.patient.birthDate, false);
    this.model.get('birthDate').setValue(birthDate);
    this.patientAge = this.utilService.getAgeString(birthDate);
    this.model.get('cpf').setValue(this.data.patient.cpf);
    this.model.get('cns').setValue(this.data.patient.cns ? this.data.patient.cns : '');
    if (this.data.patient.idGender)
      this.model.get('idGender').setValue(this.data.patient.idGender.toString());

    this.model.get('zipCode').setValue(this.data.patient.zipCode);
    this.model.get('street').setValue(this.data.patient.street);
    this.model.get('neighborhood').setValue(this.data.patient.neighborhood);
    this.model.get('city').setValue(this.data.patient.city);
    this.model.get('state').setValue(this.data.patient.state);
    this.model.get('country').setValue(this.data.patient.country);
    this.model.get('houseNumber').setValue(this.data.patient.houseNumber);
    this.model.get('apartmentNumber').setValue(this.data.patient.apartmentNumber);

    if (this.data.patient.listIdMedicine != null)
      this.model.get('listIdMedicine').setValue(this.data.patient.listIdMedicine.map(x => x.toString()));
    this.model.get('otherMedicine').setValue(this.data.patient.otherMedicine);
    if (this.data.patient.listIdComorbidity != null)
      this.model.get('listIdComorbidity').setValue(this.data.patient.listIdComorbidity.map(x => x.toString()));
    this.model.get('otherComorbidity').setValue(this.data.patient.otherComorbidity);
    if (this.data.patient.listIdCareLine != null)
      this.model.get('listIdCareLine').setValue(this.data.patient.listIdCareLine.map(x => x.toString()));
    this.model.get('phoneNumber').setValue(this.maskService.formatPhoneToField(this.data.patient.phone1));
    this.checkOutros();
    this.checkMedicineOutros();
    if (birthDate)
      this.utilService.getAge(birthDate);

    //Dados de funcionário
    this.model.get('idPatientEmployee').setValue(this.data.patient.idPatientEmployee);
    this.model.get('unitBranch').setValue(this.data.patient.unitBranch);
    this.model.get('sectorEmployee').setValue(this.data.patient.sectorEmployee);
    this.model.get('roleEmployee').setValue(this.data.patient.roleEmployee);
    this.model.get('registryEmployee').setValue(this.data.patient.registryEmployee);
  }

  searchByCPF(cpf: string) {
    this.isLoading = true;
    this.isLoadingCpf = true;

    this.getPatientByCpfService.getPatientByCPF(cpf.replace(/[^0-9]+/g, '')).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          this.isLoadingCpf = false;
          return;
        }

        this.mapModel(response.patient);

        this.isLoading = false;
        this.isLoadingCpf = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  searchByCNS(cns: string) {
    this.isLoading = true;
    this.isLoadingCns = true;
    this.listPatientByCnsService.getPatientByCNS(cns.replace(/[^0-9]+/g, '').replace(' ', '')).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          this.isLoadingCns = false;
          return;
        }

        if (response.listPatient.length == 1)
          this.mapModel(response.listPatient[0]);
        else
          this.openModal(response.listPatient);

        this.isLoading = false;
        this.isLoadingCns = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  searchByCEP(cep: string) {
    this.isLoadingCep = true;
    this.isLoading = true;

    this.viaCepService.getAddressByCep(cep).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoadingCep = false;
          this.isLoading = false;
          return;
        }

        this.model.get('zipCode').setValue(response.cep);
        this.model.get('street').setValue(response.logradouro);
        this.model.get('neighborhood').setValue(response.bairro);
        this.model.get('city').setValue(response.localidade);
        this.model.get('state').setValue(response.uf);
        this.model.get('country').setValue("Brasil");
        this.isLoadingCep = false;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  private timeoutKeySearch: any = null;
  onKeySearchCPF(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    let cpf = event.target.value.replace(/[^0-9]+/g, '');

    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13 && cpf.length == 11)
        $this.searchByCPF(cpf.replace(/[^0-9]+/g, ''));
    }, 1000);
  }

  onKeySearchCNS(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13 && event.target.value.length > 5)
        $this.searchByCNS(event.target.value);
    }, 1000);
  }

  onKeySearchCEP(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13 && event.target.value.length > 5)
        $this.searchByCEP(event.target.value);
    }, 1000);
  }


  onKeySearchAge(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13 && event.target.value.length > 5)
        $this.patientAge = $this.utilService.getAgeString($this.maskService.formatDateToString(event.target.value));
    }, 1000);
  }

  mapModel(patient: PatientStruct) {
    this.model.get('idPatient').setValue(patient.idPatient);
    this.model.get('patientName').setValue(patient.patientName);
    this.model.get('cpf').setValue(patient.cpf);
    let birthDate = this.maskService.formatDateToString(patient.birthDate, false);
    this.model.get('birthDate').setValue(birthDate);
    this.model.get('idGender').setValue(patient.idGender ? patient.idGender.toString() : null);
    this.model.get('cns').setValue(patient.cns);
    this.model.get('zipCode').setValue(patient.zipCode);
    this.model.get('street').setValue(patient.street);
    this.model.get('neighborhood').setValue(patient.neighborhood);
    this.model.get('city').setValue(patient.city);
    this.model.get('state').setValue(patient.state);
    this.model.get('country').setValue(patient.country);
    this.model.get('houseNumber').setValue(patient.houseNumber);
    this.model.get('apartmentNumber').setValue(patient.apartmentNumber);
    this.model.get('phoneNumber').setValue(this.maskService.formatPhoneToField(patient.phone1));
    if (this.model.get('birthDate').value)
      this.utilService.getAge(this.model.get('birthDate').value);
  }

  openModal(patients: PatientStruct[]) {
    const dialogRef = this.dialog.open(CnsPatientModalComponent, {
      data: patients,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.mapModel(result);
    });
  }

  populateLookupSelect() {
    this.lookupService.getLookup().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.genderList = response.listGenders;
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateCareLineSelect() {
    this.careLineService.listCareLineSelect().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        this.listCareLine = response.listCareLineStruct;
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateMedicineSelect() {
    this.medicineService.listAllMedicine().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listMedication = response.listMedicineStruct;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  checkOutros() {
    var listId = this.model.get("listIdComorbidity").value ? this.model.get("listIdComorbidity").value : [];
    if (listId.filter(x => x == ComorbidityEnum.outros).length > 0) {
      this.hasComorbidityOutros = true;
      this.model.get("otherComorbidity").setValidators([Validators.required]);
      this.model.get("otherComorbidity").updateValueAndValidity();
    }
    else {
      this.hasComorbidityOutros = false;
      this.model.get("otherComorbidity").setValue('');
      this.model.get("otherComorbidity").setValidators(null);
      this.model.get("otherComorbidity").updateValueAndValidity();
    }
  }

  checkMedicineOutros() {
    var listId = this.model.get("listIdMedicine").value ? this.model.get("listIdMedicine").value : [];
    if (listId.filter(x => x == MedicineEnum.otherMedicine).length > 0) {
      this.hasMedicationOutros = true;
      this.model.get("otherMedicine").setValidators([Validators.required]);
      this.model.get("otherMedicine").updateValueAndValidity();
    }
    else {
      this.model.get("otherMedicine").setValue('');
      this.model.get("otherMedicine").setValidators(null);
      this.model.get("otherMedicine").updateValueAndValidity();
      this.hasMedicationOutros = false;
    }
  }

  populateComorbiditySelect() {
    this.careLineLookupService.listLookup().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listComorbidity = response.listComorbidity;
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}