import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { BiPanelService } from 'src/app/shared/services/API/business-intelligence/bi-panel.service';
import { BiPanel } from 'src/app/shared/services/models/business-intelligence/bi-panel.model';
import { UtilService } from 'src/app/shared/services/util.service';
import { environment } from 'src/environments/environment';
import { BiPanelDeleteModalComponent } from './bi-panel-delete-modal/bi-panel-delete-modal.component';

@Component({
  selector: 'app-bi-panel-list',
  templateUrl: './bi-panel-list.component.html',
  styleUrls: ['./bi-panel-list.component.css']
})
export class BiPanelListComponent implements OnInit {

  constructor(
    private biPanelService: BiPanelService,
    private router: Router,
    private alertService: AlertService,
    private utilService: UtilService,
    public dialog: MatDialog
  ) { }
    
    public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.business_intelligence;
    public menuFunctionalityEnum:MenuFunctionalityEnum = MenuFunctionalityEnum.bi_biPanel;
    
    public listBiPanel: BiPanel[];
    public isLoading: boolean;
    public isActive: boolean;
    public searchText: string;
    public idExamType: number;

  ngOnInit(): void {
    this.isLoading = true;
    this.search();
  }
  search(){
    this.isLoading = true;  
    if(this.searchText == "")
    this.searchText = null;
    
    this.biPanelService.listBiPanel(this.searchText).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.listBiPanel = response.listBiPanel;  
      this.listBiPanel.forEach(x =>{
        x.panelUrl =  environment.urlApiBaseUI + "client/bi-panel/" + x.idBiPanel;
      })
      
      this.isLoading = false;    
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  private timeoutKeySearch: any = null;
  
  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }
  
  openModal(idBiPanel){
    const dialogRef = this.dialog.open(BiPanelDeleteModalComponent, {
      data: {
        idBiPanel: idBiPanel
      },
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.deletePanel){
        this.search();
      }
    });
  }

  onCopy(text){
    this.alertService.show('Sucesso', text, AlertType.success);
  }
}
