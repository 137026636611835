import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ObservationConfigService } from 'src/app/shared/services/API/bed/observation-config.service';
import { ObservationProcedureToggleService } from 'src/app/shared/services/API/bed/observation-procedure-toggle.service';
import { LocationByModuleService } from 'src/app/shared/services/API/flow/location-by-module.service';
import { SectorService } from 'src/app/shared/services/API/flow/sector.service';
import { ListCompleteObservationConfigStruct } from 'src/app/shared/services/structs/bed/list-complete-observation-config.struct';
import { SectorStruct } from 'src/app/shared/services/structs/flow/sector.struct';
import { ObservationConfigDeleteModalComponent } from './observation-config-delete-modal/observation-config-delete-modal.component';
import { ObservationProceduresModalComponent } from './observation-procedures-modal/observation-procedures-modal.component';

@Component({
  selector: 'app-observation-config-list',
  templateUrl: './observation-config-list.component.html',
  styleUrls: ['./observation-config-list.component.css']
})
export class ObservationConfigListComponent implements OnInit {

  constructor(
    private observationConfigService: ObservationConfigService,
    private locationByModuleService: LocationByModuleService,
    private router: Router,
    private alertService: AlertService,
    public dialog: MatDialog,
    public observationProcedureToggleService: ObservationProcedureToggleService
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.observation;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.observation_config;

  public listObsConfig: ListCompleteObservationConfigStruct[];
  public listSector: SectorStruct[];
  public isLoading: boolean;
  public searchText: string;
  public listIdSector: number[];
  public hasObservationProcedure: boolean;
  public evolutionFillingIsRequired: boolean;

  ngOnInit(): void {
    this.isLoading = true;
    this.listIdSector = [];
    this.populateSectorSelect();
    this.search();
  }
  search() {
    this.isLoading = true;
    if (this.searchText == "")
      this.searchText = null;

    this.observationConfigService.listObsConfig(this.searchText, this.listIdSector).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.listObsConfig = response.listObservationConfig;

      this.hasObservationProcedure = response.hasObservationProcedure;
      this.evolutionFillingIsRequired = (response.evolutionFillingIsRequired == null) ? false : response.evolutionFillingIsRequired;
      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  populateSectorSelect() {
    this.locationByModuleService.listRoomByModule(MenuModuleEnum.observation).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }

      this.listSector = response.sectors;
    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }

  openModal(idObservationConfig){
    const dialogRef = this.dialog.open(ObservationConfigDeleteModalComponent, {
      data: {
        idObservationConfig: idObservationConfig
      },
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.deleteObservationConfig){
        this.search();
      }
    });
  }

  getSectorName(idSector: number) : string {
    if(!idSector || !this.listSector)
      return null;

    return this.listSector.find(l => l.idSector == idSector)?.sectorName;
  }

  listSectorName(listSectorId: number[]) : string {
    if(!listSectorId || !this.listSector)
      return null;

    let listName = [];
    
    listSectorId.forEach(element => {
      listName.push(this.listSector.find(l => l.idSector == element)?.sectorName)
    });

    return listName.join(", ");
  }

  toggleHasObservationProcedure()
  {
    const dialogRef = this.dialog.open(ObservationProceduresModalComponent, {
      data: {
        hasObservationProcedure: this.hasObservationProcedure,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if(!result || !result.changeConfig)
      {
        this.hasObservationProcedure = !this.hasObservationProcedure;
        // this.observationProcedureToggleService.postProcedureConfig();
      }
    });
  }

  toggleEvolutionFillingIsRequired (){
    this.isLoading = true;

    this.observationProcedureToggleService.postChangeEvolutionFilling().subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.alertService.show('Sucesso', "Configuração alterada com sucesso!", AlertType.success);
      this.isLoading = false;
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }
}