<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <h1>Paciente x Procedimento Realizado</h1>
        <div class="white-body">
            <div class="row">
                <div class="col-3">
                    <a mat-flat-button color="primary" class=" add-user" (click)="openModal()">
                        <span *ngIf="isLoading == false">Selecionar Data</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </a>
                </div>
                <div class="col-9 generate-report">
                    <button type="button" mat-flat-button color="primary" class=" add-user" (click)="generateReport()">
                        <span *ngIf="isLoading == false">Gerar Relatório</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
            </div>
            <div class="filters">
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-3">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Pesquisar na tabela</mat-label>
                            <input matInput type="text" [(ngModel)]="searchTextInTable"
                                (keyup)="onKeySearch($event)">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-6 col-lg-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Código</mat-label>
                            <mat-select [disabled]="!this.listColumnToDisplay.includes(this.columns.Codigo)" [(ngModel)]="procedureSigtapCodeToFilter" (selectionChange)="search(procedureSigtapNameToFilter, procedureSigtapCodeToFilter)">
                                <mat-option class = "option" [value]="">Nenhum</mat-option>
                                <mat-option class = "option" *ngFor="let item of listProcedureSigtapCode" [value]="item">{{item}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-6 col-lg-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Procedimento</mat-label>
                            <mat-select [disabled]="!this.listColumnToDisplay.includes(this.columns.Procedimento)" [(ngModel)]="procedureSigtapNameToFilter" (selectionChange)="search(procedureSigtapNameToFilter, procedureSigtapCodeToFilter)">
                                <mat-option class = "option" [value]="">Nenhum</mat-option>
                                <mat-option class = "option" *ngFor="let item of listProcedureSigtapName"
                                    [value]="item">{{item}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-6 col-lg-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Colunas a serem exibidas </mat-label>                            
                            <mat-select [multiple]="true" [(ngModel)]="listColumnToDisplay">
                                <input class="inputSearchSelect" type="text"
                                (keyup)="onKeySelectColumns($event.target.value)" placeholder="Pesquisar" autocomplete="off" (keydown)="$event.stopPropagation()">
                                <mat-option *ngFor="let item of listColumnNames; let index = index;" (click)="onChangeDisplayColumn(index)" [ngClass]="{'hidden-option': item.hidden}" [value]="index + 1" >
                                    {{item.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="table-responsive table-height" *ngIf="listConsolidatedDataToDisplay && listConsolidatedDataToDisplay.length > 0">
                <table class="table">
                    <thead>
                        <tr>
                            <th *ngIf="this.listColumnToDisplay.includes(columns.Coordenadoria)" class="billing-cell-width">Coordenadoria</th>
                            <th *ngIf="this.listColumnToDisplay.includes(columns.Supervisao)" class="billing-cell-width">Supervisão</th>
                            <th *ngIf="this.listColumnToDisplay.includes(columns.CNESEstabelecimento)" class="billing-cell-width">CNES</th>
                            <th *ngIf="this.listColumnToDisplay.includes(columns.Estabelecimento)" class="billing-cell-width">Estabelecimento</th>
                            <th *ngIf="this.listColumnToDisplay.includes(columns.CompetenciaBPA)" class="billing-cell-width">Competência BPA</th>
                            <th *ngIf="this.listColumnToDisplay.includes(columns.CNSProfissional)" class="billing-cell-width">CNS Profissional</th>
                            <th *ngIf="this.listColumnToDisplay.includes(columns.NomeProfissional)" class="billing-cell-width">Nome Profissional</th>
                            <th *ngIf="this.listColumnToDisplay.includes(columns.NomePaciente)" class="billing-cell-width">Paciente</th>
                            <th *ngIf="this.listColumnToDisplay.includes(columns.DataDeNascimento)" class="billing-cell-width">Nascimento</th>
                            <th *ngIf="this.listColumnToDisplay.includes(columns.CNSPaciente)" class="billing-cell-width">CNS Paciente</th>
                            <th *ngIf="this.listColumnToDisplay.includes(columns.CID)" class="billing-cell-width">CID</th>
                            <th *ngIf="this.listColumnToDisplay.includes(columns.Codigo)" class="billing-cell-width">Código</th>
                            <th *ngIf="this.listColumnToDisplay.includes(columns.Procedimento)" class="billing-cell-width">Procedimento</th>
                            <th *ngIf="this.listColumnToDisplay.includes(columns.CodigoCBO)" class="billing-cell-width">Código CBO</th>
                            <th *ngIf="this.listColumnToDisplay.includes(columns.CBO)" class="billing-cell-width">CBO</th>
                            <th *ngIf="this.listColumnToDisplay.includes(columns.Quantidade)" class="billing-cell-width">Quantidade</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of listConsolidatedDataToDisplay; let index = index;"
                            [style.background-color]="item.hasInconsistency? '#ffdbdb':'transparent'">
                            <td *ngIf="this.listColumnToDisplay.includes(columns.Coordenadoria)" class="billing-cell-width" matTooltip="{{item.reducedCoordination}}">
                                <span>{{item.reducedCoordination? item.reducedCoordination :item.coordination}}</span>
                            </td>
                            <td *ngIf="this.listColumnToDisplay.includes(columns.Supervisao)" class="billing-cell-width" matTooltip="{{item.supervision}}">
                                <span>{{item.reducedSupervision? item.reducedSupervision: item.supervision}}</span></td>
                            <td *ngIf="this.listColumnToDisplay.includes(columns.CNESEstabelecimento)" class="billing-cell-width"><span>{{item.cnes}}</span></td>
                            <td *ngIf="this.listColumnToDisplay.includes(columns.Estabelecimento)" class="billing-cell-width" matTooltip="{{item.establishment}}">
                                <span>{{item.reducedEstablishment? item.reducedEstablishment:
                                    item.establishment}}</span></td>
                            <td *ngIf="this.listColumnToDisplay.includes(columns.CompetenciaBPA)" class="billing-cell-width"><span>{{item.competenceDate | date: 'MM/yyyy'}}</span></td>
                            <td *ngIf="this.listColumnToDisplay.includes(columns.CNSProfissional)" class="billing-cell-width"><span>{{item.cns}}</span></td>
                            <td *ngIf="this.listColumnToDisplay.includes(columns.NomeProfissional)" class="billing-cell-width" matTooltip="{{item.userName}}"><span>{{item.reducedUserName ?
                                    item.reducedUserName : item.userName}}</span></td>
                            <td *ngIf="this.listColumnToDisplay.includes(columns.NomePaciente)" class="billing-cell-width" matTooltip="{{item.patientName}}">
                                <span>{{item.reducedPatientName ? item.reducedPatientName : item.patientName}}</span>
                            </td>
                            <td *ngIf="this.listColumnToDisplay.includes(columns.DataDeNascimento)" class="billing-cell-width"><span>{{item.birthDate | date:'dd/MM/yyyy'}}</span></td>
                            <td *ngIf="this.listColumnToDisplay.includes(columns.CNSPaciente)" class="billing-cell-width" matTooltip="{{item.patientCns}}">
                                <span>{{item.reducedPatientCns? item.reducedPatientCns : item.patientCns}}</span></td>
                            <td *ngIf="this.listColumnToDisplay.includes(columns.CID)" class="billing-cell-width" matTooltip="{{item.cid}}"><span>{{item.cid? item.cid :
                                    item.cid}}</span></td>
                            <td *ngIf="this.listColumnToDisplay.includes(columns.Codigo)" class="billing-cell-width"><span>{{item.codProcedureSigtap}}</span></td>
                            <td *ngIf="this.listColumnToDisplay.includes(columns.Procedimento)" class="billing-cell-width" matTooltip="{{item.procedureSigtapName}}">
                                <span>{{item.reducedProcedureName? item.reducedProcedureName :
                                    item.procedureSigtapName}}</span></td>
                            <td *ngIf="this.listColumnToDisplay.includes(columns.CodigoCBO)" class="billing-cell-width"><span>{{item.codCbo}}</span></td>
                            <td *ngIf="this.listColumnToDisplay.includes(columns.CBO)" class="billing-cell-width" matTooltip="{{item.cboName}}"><span>{{item.reducedCboName?
                                    item.reducedCboName : item.cboName}}</span></td>
                            <td *ngIf="this.listColumnToDisplay.includes(columns.Quantidade)" class="billing-cell-width"><span>{{item.quantity}}</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <app-empty-list
                *ngIf="listConsolidatedDataToDisplay != undefined && listConsolidatedDataToDisplay.length == 0"></app-empty-list>
            <app-loading-list *ngIf="isLoading"></app-loading-list>
        </div>
    </div>
</div>