import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { AccessKeyRequest } from '../../requests/integration/access-key.request';
import { GetAccessKeyResponse } from '../../responses/integration/get-access-key.response';
import { GetIntegrationLogResponse } from '../../responses/integration/get-integration-log.response';
import { ListAccessKeyResponse } from '../../responses/integration/list-access-key.response';
import { ListIntegrationLogResponse } from '../../responses/integration/list-integration-log.response';

@Injectable({
  providedIn: 'root'
})
export class IntegrationLogService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listIntegrationLog(idUri: number, idLogType: number, datetimeInitRequest: string, pageIndex: number, searchText: string, idEpisode: number): Observable<ListIntegrationLogResponse> {

    let uri = 'IntegrationLog?'

    if (idUri != null)
      uri = uri + `idUri=${idUri}&`;

    if (idLogType != null)
      uri = uri + `idLogType=${idLogType}&`

    if (datetimeInitRequest != null)
      uri = uri + `datetimeInitRequest=${datetimeInitRequest}&`

    if (pageIndex)
      uri = uri + `&pageIndex=${pageIndex}`;

    if (searchText)
      uri = uri + `&searchText=${searchText}`;

    if (idEpisode != null)
      uri = uri + `&idEpisode=${idEpisode}`;

    return this.httpClient.get<ListIntegrationLogResponse>(environment.urlApiIntegration + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getIntegrationLog(idIntegrationLog: number): Observable<GetIntegrationLogResponse> {

    let uri = `IntegrationLog/idIntegrationLog/${idIntegrationLog}`;

    return this.httpClient.get<GetIntegrationLogResponse>(environment.urlApiIntegration + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
