import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { OrchestratorMedicalScheduleService } from 'src/app/shared/services/API/orchestrator-form/orchestrator-medical-schedule.service';
import { CboService } from 'src/app/shared/services/API/user/cbo.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { CboModel } from 'src/app/shared/services/models/user/cbo.model';
import { OrchestratorMedicalScheduleRequest } from 'src/app/shared/services/requests/orchestrator-form/orchestrator-medical-schedule.request';
import { ListMedicalScheduleStruct } from 'src/app/shared/services/structs/orchestrator-form/list-medical-schedule.struct';
import { environment } from 'src/environments/environment';
import { FormMedicalScheduleListDeleteModalComponent } from './form-medical-schedule-list-delete-modal/form-medical-schedule-list-delete-modal.component';

@Component({
  selector: 'app-form-medical-schedule-list',
  templateUrl: './form-medical-schedule-list.component.html',
  styleUrls: ['./form-medical-schedule-list.component.css']
})
export class FormMedicalScheduleListComponent implements OnInit {
  

  constructor(private alertService: AlertService,
    private orchestratorMedicalScheduleService: OrchestratorMedicalScheduleService,
    private cboService: CboService,
    private dialog: MatDialog,
    private maskService: MaskService,
  ) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.form;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.form_medical_schedule;

  public listMedicalSchedule: ListMedicalScheduleStruct[];
  public isLoading: boolean;
  public masks: Masks;
  
  public searchText: string;
  public date: Date;
  public listCboId: number[];

  public urlListCbo: string;

  public fullListSize: number;

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.urlListCbo = environment.urlApiUser + "Cbo";
    this.search(0);
  }

  search(pageIndex: number) {
    this.isLoading = true;
    let request = new OrchestratorMedicalScheduleRequest();

    if (this.searchText == "")
      this.searchText = null;

    request.searchText = this.searchText;
    request.pageIndex = pageIndex;

    if (this.date) {
      this.date.setHours(0);
      this.date.setMinutes(0);
      request.date = this.date.toISOString();
    }

    request.listIdCbo = this.listCboId;

    this.orchestratorMedicalScheduleService.listMedicalSchedule(request).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.listMedicalSchedule = response.listMedicalSchedule;
      this.fullListSize = response.itemCount;
      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  openModal(idMedicalSchedule){
    const dialogRef = this.dialog.open(FormMedicalScheduleListDeleteModalComponent, {
      data: {
        idMedicalSchedule: idMedicalSchedule
      },
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.deleteMedicalSchedule){
        this.search(0);
      }
    });
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.paginator.firstPage();
        $this.search(0);
      }
    }, 1000);
  }

  changePage(event: PageEvent) {
    this.search(event.pageIndex);
  }

  addItemCbo(event: any) {
    this.listCboId = event.map(l => l.idCbo);
    this.search(0);
  }
}
