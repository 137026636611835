import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { GetHealthUnitIAResponse } from '../../responses/user/get-health-unit-ia.response';

@Injectable({
  providedIn: 'root'
})
export class IaFlowchartService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public checkIfIsAIEnabled(): Observable<GetHealthUnitIAResponse> {

    let uri = 'IAFlowchart'

    return this.httpClient.get<GetHealthUnitIAResponse>(environment.urlApiUser + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}