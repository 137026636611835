import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SelectedLocationRequest } from 'src/app/shared/services/requests/orchestrator-queue/selected-location.request';
import { ForwardPatientSadtConfirmModalComponent } from './forward-patient-sadt-confirm-modal/forward-patient-sadt-confirm-modal.component';

@Component({
  selector: 'app-forward-patient-sadt-modal',
  templateUrl: './forward-patient-sadt-modal.component.html',
  styleUrls: ['./forward-patient-sadt-modal.component.css']
})
export class ForwardPatientSadtModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ForwardPatientSadtModalComponent>,
    public router: Router) { }

  public isLoading: boolean;
  public selectedLocationRequest: SelectedLocationRequest;
  public isFlowSequence: boolean;
  public filteredListforward: any[] = [];
  public listForward: any[] = [];
  public searchText: string;
  public medicineNamesOpen: string = this.data.medicineNamesOpen;

  ngOnInit(): void {
    this.isLoading = false;
    this.isFlowSequence = (this.data.isFlowSequence);
    if (this.isFlowSequence && this.data.flowSequence.length > 1) {
      var destinyRoomName = this.data.flowSequence[1].destinyRoomName ? this.data.flowSequence[1].destinyRoomName : "";
      var destinySectorName = this.data.flowSequence[1].destinySectorName ? this.data.flowSequence[1].destinySectorName + " " : "";
      var destinyServiceName = this.data.flowSequence[1].destinyServiceName ? this.data.flowSequence[1].destinyServiceName : " ";
      this.listForward = [{ idFlow: this.data.flowSequence[1].idFlow, locationName: destinyServiceName + destinySectorName + destinyRoomName }];
      this.filteredListforward = [...this.listForward]
    }
    else if (this.data.listFoward) {
      this.listForward = this.data.listFoward;
      this.filteredListforward = [...this.data.listFoward];
    }
  }

  clickConfirm() {
    this.matDialogRef.close({ fowardPatient: false });
  }

  clickCancel() {
    this.matDialogRef.close({ fowardPatient: false });
  }

  close() {
    this.clickCancel();
  }

  onKeySearch() {
    setTimeout(() => {
      if (this.searchText) {
        this.filteredListforward = [...this.listForward.filter((item: any) => {
          return item.locationName.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1;
        })];
      }
      else
        this.filteredListforward = [...this.listForward];
    });
  }

  selectItem(idFlow: number) {
    this.selectedLocationRequest = new SelectedLocationRequest();
    this.selectedLocationRequest.idEpisode = this.data.idEpisode;
    this.selectedLocationRequest.idQueue = this.data.idQueue;
    this.selectedLocationRequest.idRoomOrigin = this.data.idRoom;
    this.selectedLocationRequest.idFlow = idFlow;

    if (this.isFlowSequence)
      this.selectedLocationRequest.idFlowCompleted = this.data.flowSequence[0].idFlow;

    //let roomName = this.data.listFoward.find(c => c.idFlow == this.selectedLocationRequest.idFlow).locationName;
    const dialogRef = this.dialog.open(ForwardPatientSadtConfirmModalComponent, {
      data: {
        idHealthUnit: this.data.idHealthUnit,
        selectedLocationRequest: this.selectedLocationRequest,
        idEpisode: this.data.idEpisode,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.fowardPatient)
          this.matDialogRef.close({ fowardPatient: true });
      },
      error: (error) => {
        console.log(error);
      }
    });
  }
}