import { Component, Inject, Input, OnInit,  } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-list-of-completed-audit-triage-modal',
  templateUrl: './list-of-completed-audit-triage-modal.component.html',
  styleUrls: ['./list-of-completed-audit-triage-modal.component.css']
})
export class ListOfCompletedAuditTriageModalComponent implements OnInit {

  @Input() idModule: number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  private alertService: AlertService,
  public matDialogRef: MatDialogRef<ListOfCompletedAuditTriageModalComponent>,  
  ) { }  

  public isLoading:boolean;  

  ngOnInit(): void {
  }

  close(){
    this.matDialogRef.close(); 
  }
}
