import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { ProfileRequest } from '../../requests/user/profile.request';
import { ListProfileResponse } from '../../responses/user/list-profile.response';
import { ProfileResponse } from '../../responses/user/profile.response';

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listProfile(searchText: string): Observable<ListProfileResponse> {
    
    let uri = 'Profile?'
    
    if(searchText != null)
    uri = uri+ `searchText=${searchText}&`;
    
    return this.httpClient.get<ListProfileResponse>(environment.urlApiUser + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public getProfileRoles(idProfile: number): Observable<ProfileResponse> {
    
    let uri = `Profile/idProfile/${idProfile}`;
    
    return this.httpClient.get<ProfileResponse>(environment.urlApiUser + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public listAllProfile():  Observable<ListProfileResponse>{

    let uri = `Profile/getAll`;

    return this.httpClient.get<ListProfileResponse>(environment.urlApiUser + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public createProfileRoles(body: ProfileRequest): Observable<ProfileResponse> {
    
    let uri = `Profile`;
    
    return this.httpClient.post<ProfileResponse>(environment.urlApiUser + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public editProfileRoles(idProfile: number, body: ProfileRequest): Observable<ProfileResponse> {
    
    let uri = `Profile/idProfile/${idProfile}`;
    
    return this.httpClient.put<ProfileResponse>(environment.urlApiUser + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public deleteProfile(idProfile: number): Observable<ReturnStruct>{
    return this.httpClient.delete<ReturnStruct>(environment.urlApiUser + `Profile/idProfile/${idProfile}`, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
