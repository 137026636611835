import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { ListBedStatusResponse } from '../../responses/bed/list-bed-status-response.response';

@Injectable({
  providedIn: 'root'
})
export class BedStatusService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listBedStatus(): Observable<ListBedStatusResponse>{

    let uri = `BedStatus/getAll`

    return this.httpClient.get<ListBedStatusResponse>(environment.urlApiBed + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
