import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { LoginTimeoutEnum } from 'src/app/shared/enum/login-timeout.enum';
import { HealthUnitLoginTypeEnum } from 'src/app/shared/enum/user/health-unit-login-type.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { BillingLookupService } from 'src/app/shared/services/API/billing/billing-lookup.service';
import { CompetenceSelectService } from 'src/app/shared/services/API/billing/competence-select.service';
import { EvasionTimeService } from 'src/app/shared/services/API/user/evasion-time.service';
import { HealthUnitService } from 'src/app/shared/services/API/user/health-unit.service';
import { LoginTimeoutService } from 'src/app/shared/services/API/user/login-timeout.service';
import { LookupService } from 'src/app/shared/services/API/user/lookup.service';
import { PasswordExpirationTimeService } from 'src/app/shared/services/API/user/password-expiration-time.service';
import { TimeZoneService } from 'src/app/shared/services/API/user/time-zone.service';
import { Coordination } from 'src/app/shared/services/models/billing/coordination.model';
import { Supervision } from 'src/app/shared/services/models/billing/supervision.model';
import { EmailDomain } from 'src/app/shared/services/models/user/email-domain.model';
import { EvasionTimeModel } from 'src/app/shared/services/models/user/evasion-time.model';
import { LoginTimeout } from 'src/app/shared/services/models/user/login-timeout.model';
import { LoginType } from 'src/app/shared/services/models/user/login-type.model';
import { PasswordExpirationTimeModel } from 'src/app/shared/services/models/user/password-expiration-time.model';
import { TimeZone } from 'src/app/shared/services/models/user/time-zone.model';
import { HealthUnitRequest } from 'src/app/shared/services/requests/user/health-unit.request';
import { CompetenceSelectStruct } from 'src/app/shared/services/structs/billing/competence-select.struct';
import { HealthUnitLogoModalComponent } from './health-unit-logo-modal/health-unit-logo-modal.component';
import { UserExpirationTimeModel } from 'src/app/shared/services/models/user/user-expiration-time.model';

@Component({
  selector: 'app-health-unit-config',
  templateUrl: './health-unit-config.component.html',
  styleUrls: ['./health-unit-config.component.css']
})
export class HealthUnitConfigComponent implements OnInit {

  constructor(private formBuilder: UntypedFormBuilder,
    private router: Router,
    private alertService: AlertService,
    private healthUnitService: HealthUnitService,
    private timeZoneService: TimeZoneService,
    private loginTimeoutService: LoginTimeoutService,
    private evasionTimeService: EvasionTimeService,
    public dialog: MatDialog,
    private competenceSelectService: CompetenceSelectService,
    private billingLookupService: BillingLookupService,
    private passwordExpirationTimeService: PasswordExpirationTimeService,
    private userLookupService: LookupService
  ) { }


  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.master;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.master_health_unit;

  public model: UntypedFormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;
  public actualServiceModule: number;
  public listTimeZone: TimeZone[];
  public listLoginTimeout: LoginTimeout[];
  public listEvasionTime: EvasionTimeModel[];
  public healthUnitRequest: HealthUnitRequest;
  public listCompetenceSelectStruct: CompetenceSelectStruct[];
  public listCoordination: Coordination[];
  public listSupervision: Supervision[];
  public idMaxValue: number = LoginTimeoutEnum.MaxValue;
  public listPasswordExpirationTime: PasswordExpirationTimeModel[] = [];
  public logo: string;
  public newLogo: string;
  public listLoginType: LoginType[] = [];
  public listUserExpirationTime: UserExpirationTimeModel[] = [];
  public canAddTissGuide: boolean = false;

  ngOnInit(): void {
    this.isLoading = false;
    this.isFirstLoading = true;
    this.getPasswordExpirationTime();
    this.model = this.formBuilder.group({
      name: ['', [Validators.required]],
      idTimeZone: ['', [Validators.required]],
      idEvasionTime: ['', [Validators.required]],
      idLoginTimeout: ['', [Validators.required]],
      haveLogo: ['', [Validators.required]],
      isBillingSigtap: [''],
      idProcedureSigtapCompetence: [''],
      cnes: [''],
      supervision: [''],
      coordination: [''],
      idPasswordExpirationTime: ['', [Validators.required]],
      idLoginType: ['', [Validators.required]],
      loginConfig: this.formBuilder.array([]),
      idUserExpirationTime: [null],
    });

    this.healthUnitRequest = new HealthUnitRequest();
    this.model.get('name').disable();
    this.model.get('cnes').disable();
    this.model.get('supervision').disable();
    this.model.get('coordination').disable();
    this.model.get('isBillingSigtap').disable();
    this.populateHealthUnitData();
    this.populateTimeZoneSelect();
    this.populateEvasionTimeSelect();
    this.populateLoginTimeoutSelect();
    this.populateBillingSigtapSelect();
    this.populateLookupSelect();
    this.populatUserLookupSelect();
  }

  populateTimeZoneSelect() {
    this.timeZoneService.listTimeZone().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listTimeZone = response.listTimeZone;
    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }
  
  populateLoginTimeoutSelect() {
    this.loginTimeoutService.listLoginTimeout().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listLoginTimeout = response.listLoginTimeout;
    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  populateEvasionTimeSelect() {
    this.evasionTimeService.listEvasionTime().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listEvasionTime = response.listEvasionTime;
    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  populateHealthUnitData() {
    this.healthUnitService.getHealthUnit().subscribe({
      next: (response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.model.get('name').setValue(response.healthUnit.healthUnitName);
      this.model.get('idTimeZone').setValue(response.healthUnit.idTimeZone.toString());
      this.model.get('idEvasionTime').setValue(response.healthUnit.idEvasionTime.toString());
      this.model.get('idLoginTimeout').setValue(response.healthUnit.idLoginTimeout.toString());
      this.model.get('haveLogo').setValue(response.healthUnit.isLogoUploaded);
      this.model.get('isBillingSigtap').setValue(response.healthUnit.isBillingSigtap);
      this.model.get('cnes').setValue(response.healthUnit.cnes);
      this.model.get('idPasswordExpirationTime').setValue(response.healthUnit.idPasswordExpirationTime.toString());
      this.model.get('coordination').setValue(response.healthUnit.idCoordination? response.healthUnit.idCoordination.toString() : null);
      this.model.get('supervision').setValue(response.healthUnit.idSupervision? response.healthUnit.idSupervision.toString(): null);
      this.model.get('idLoginType').setValue(response.healthUnit.idLoginType.toString());
      this.model.get('idUserExpirationTime').setValue(response.healthUnit.idUserExpirationTime);

      if(response.healthUnit.canAddTissGuide != null && response.healthUnit.canAddTissGuide != undefined)
        this.canAddTissGuide = response.healthUnit.canAddTissGuide;

      if(response.listEmailDomain.length > 0){
        response.listEmailDomain.forEach(x => {
          (this.model.controls['loginConfig'] as UntypedFormArray).push(
            this.formBuilder.group({
              emailDomain: [x.domainName],
            })
          )
        })
      }

      if(response.healthUnit.idProcedureSigtapCompetence)
        this.model.get('idProcedureSigtapCompetence').setValue(response.healthUnit.idProcedureSigtapCompetence.toString());
        
      this.logo = response.healthUnitLogo;
      
      this.isLoading = false;
      this.isFirstLoading = false;
    },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateBillingSigtapSelect() {
    this.competenceSelectService.listProcedureSigtapCompetence().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listCompetenceSelectStruct = response.procedureSigtapCompetences;
    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  submit() {
    if (this.isLoading || !this.model.valid) {
      return;
    }

    this.isLoading = true;
    this.healthUnitRequest.haveLogo = this.model.get('haveLogo').value;
    this.healthUnitRequest.idTimeZone = this.model.get('idTimeZone').value;
    this.healthUnitRequest.idEvasionTime = this.model.get('idEvasionTime').value;
    this.healthUnitRequest.idLoginTimeout = this.model.get('idLoginTimeout').value;
    this.healthUnitRequest.idPasswordExpirationTime = this.model.get('idPasswordExpirationTime').value;
    this.healthUnitRequest.isBillingSigtap = this.model.get('isBillingSigtap').value ? this.model.get('isBillingSigtap').value: false;
    this.healthUnitRequest.idProcedureSigtapCompetence = this.healthUnitRequest.isBillingSigtap? this.model.get('idProcedureSigtapCompetence').value : null;
    this.healthUnitRequest.idLoginType = parseInt(this.model.get('idLoginType').value);
    this.healthUnitRequest.idUserExpirationTime = this.model.get('idUserExpirationTime').value;

    if (this.newLogo && this.model.get('haveLogo').value) {
      this.healthUnitRequest.newHealthUnitLogo = this.newLogo;
    }

    this.model.get('loginConfig').value.forEach(x => {
      let email = new EmailDomain();
      email.domainName = x.emailDomain;
      this.healthUnitRequest.listEmailDomain.push(email);
    });

    this.updateHealthUnit()
  }

  updateHealthUnit(){
    this.healthUnitService.updateHealthUnit(this.healthUnitRequest).subscribe({
      next: (response)=>{
      
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        
        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false; 
        
        this.router.navigate(['/master']);
      },
      error: (error)=>{
        console.log(error)
        this.isLoading = false;    
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openModal() {
    const dialogRef = this.dialog.open(HealthUnitLogoModalComponent, {
      data: {
        logo: this.logo,
        newLogo: this.newLogo,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.newLogo) {
        this.newLogo = result.newLogo;
      }
      if (result && !result.haveLogo){
        this.model.get('haveLogo').setValue(false);
      }
    });
  }

  populateLookupSelect() {
    this.billingLookupService.listLookup().subscribe({
      next: (response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        
        this.listCoordination = response.listCoordination;
        this.listSupervision = response.listSupervision;
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getPasswordExpirationTime() {
    this.passwordExpirationTimeService.listPasswordExpirationTime().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listPasswordExpirationTime = response.listPasswordExpirationTime;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populatUserLookupSelect() {
    this.userLookupService.listAllLookup().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        if (!response.listLoginType || response.listLoginType.length == 0 || !response.listUserExpirationTime || response.listUserExpirationTime.length == 0) {
          this.alertService.show('Erro', "Erro na busca da lista de tipos de login ou na lista de tempo de expiração do usuário", AlertType.error);
          return;
        }
        
        this.listLoginType = response.listLoginType;
        this.listUserExpirationTime = response.listUserExpirationTime;
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  verifyRequiredDomain() {
    let idLoginType = this.model.get('idLoginType').value;
    if(idLoginType == HealthUnitLoginTypeEnum.user){
      this.removeEmailsDomains();
    } else {
      this.addNext();
    }
  }

  createInput() {
    return this.formBuilder.group({
      emailDomain: ['', [Validators.required]],
    })
  }

  addNext() {
    (this.model.controls['loginConfig'] as UntypedFormArray).push(this.createInput())
  }

  remove(index: number) {
    if (index != 0) {
      (this.model.controls['loginConfig'] as UntypedFormArray).removeAt(index)
    }
  }

  removeEmailsDomains(){
    (this.model.controls['loginConfig'] as UntypedFormArray).clear();
  }

  private timeoutKeySearch: any = null;

  validateEmailAllowed(index: number) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (!$this.model.get('loginConfig').value[index]) {
        return;
      }

      if (/([\w-]+\.)+[\w-]{2,20}$/.test($this.model.get('loginConfig').value[index])) {
        return (true);
      }

      $this.alertService.show('Aviso', "Endereço de email inválido", AlertType.warning);
      return (false);
    }, 1000);
  }

  changecanAddTissGuide(){
    this.isLoading = true;
    this.healthUnitService.updatecanAddTissGuide(this.canAddTissGuide).subscribe({
      next: (response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.isLoading = false;
      this.alertService.show('Sucesso', "Configuração alterada com sucesso!", AlertType.success); 
      },
      error: (error)=>{
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}

