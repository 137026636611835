import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CommonService } from "src/app/shared/services/common-service";
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';
import { catchError } from "rxjs/operators";
import { ReportResponse } from "../../responses/orchestrator-patient/report.response";
import { ReportObservationRequest } from "../../requests/orchestrator-patient/report-observation.request";
import { NursingConsultationRequest } from "../../requests/medical-record/nursing-consultation.request";
import { PostNursingConsultationResponse } from "../../responses/medical-record/post-nursing-consultation.response";

@Injectable({
    providedIn: 'root'
  })
  export class ObservationReportService extends CommonService{
  
    constructor(private router: Router, private httpClient: HttpClient) {
      super();
    }


    public postNursingConsultation(body: NursingConsultationRequest): Observable<PostNursingConsultationResponse>{
      let uri = `ObservationReport/postNursingConsultation`
  
      return this.httpClient.post<PostNursingConsultationResponse>(environment.urlApiOrchestratorPatient + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
    }

    public generateObservationReport(ReportObservationRequest:ReportObservationRequest, idEpisode: number): Observable<ReportResponse>{
      let uri = `ObservationReport/idEpisode/${idEpisode}`
  
      return this.httpClient.post<ReportResponse>(environment.urlApiOrchestratorPatient + uri, ReportObservationRequest, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
    }
}