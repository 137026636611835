import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PriceProcedureHealthcareService } from 'src/app/shared/services/API/private-billing/price-procedure-healthcare.service';
import { UserGetNameService } from 'src/app/shared/services/API/user/user-get-name.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { HistoryPriceProcedureHealthcareStruct } from 'src/app/shared/services/structs/private-billing/history-price-procedure-healthcare.struct';
import { PriceProcedureHealthcareStruct } from 'src/app/shared/services/structs/private-billing/price-procedure-healthcare.struct';
import { ListUserStruct } from 'src/app/shared/services/structs/user/list-user.struct';

@Component({
  selector: 'app-price-change-history-modal',
  templateUrl: './price-change-history-modal.component.html',
  styleUrls: ['./price-change-history-modal.component.css']
})
export class PriceChangeHistoryModalComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<PriceChangeHistoryModalComponent>,
    private alertService: AlertService,
    private priceProcedureHealthcareService: PriceProcedureHealthcareService,
    private userGetNameService: UserGetNameService
  ) { }

  public isLoading: boolean = true;
  public idProcedureHealthcare: number = this.data.idProcedureHealthcare;
  public listHistoryPriceProcedureHealthcareStruct: HistoryPriceProcedureHealthcareStruct[] = [];

  ngOnInit(): void {
    this.getHistory(this.idProcedureHealthcare);
  }

  clickCancel() {
    this.matDialogRef.close();
  }

  getHistory(idProcedureHealthcare: number) {
    this.priceProcedureHealthcareService.getHistoryPrice(idProcedureHealthcare).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listHistoryPriceProcedureHealthcareStruct = response.listHistoryPriceProcedureHealthcareStruct;
        this.getUserName(response.listIdUser);
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getUserName(listIdUser) {
    this.userGetNameService.listUser(listIdUser).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.insertNameInList(response.listUser);
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  insertNameInList(listUser: ListUserStruct[]) {
    this.listHistoryPriceProcedureHealthcareStruct.forEach(x => {
      x.userName = listUser.find(x => x.idUser == x.idUser).userName;
    });

    this.isLoading = false;
  }
}