<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-confirm-modal-list">
            <h3><b>Deseja {{this.startHygienization ? 'iniciar' : 'concluir'}} a higienização deste leito?</b></h3>
        </div>
        <form class="form"   (ngSubmit)="submit()">
            <div class="footer-confirm-modal-list">
                <button mat-flat-button color="primary" class="btn-block" type="submit">
                    <span *ngIf="isLoading == false">{{this.startHygienization ? 'Iniciar' : 'Concluir'}} higienização</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
                <button mat-flat-button color="accent" class="btn-block"(click)="clickCancel()" type="button">Retornar</button>
            </div>
        </form> 
    </div>
</div>