import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { MedicineTypeRequest } from '../../requests/pharmacy/medicine-type.request';
import { GetMedicineTypeResponse } from '../../responses/pharmacy/get-medicine-type-response';
import { ListMedicineTypeResponse } from '../../responses/pharmacy/list-medicine-type-response';

@Injectable({
  providedIn: 'root'
})
export class MedicineTypeService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listAllMedicineType(): Observable<ListMedicineTypeResponse> {
    let uri = "MedicineType/getAll";

    return this.httpClient.get<ListMedicineTypeResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())

    .pipe(
      catchError(this.handleError)
    )
  }

  public listMedicineType(searchText: string): Observable<ListMedicineTypeResponse> {
    
    let uri = 'MedicineType?'
    
    if(searchText != null)
      uri = uri+ `searchText=${searchText}&`;

    return this.httpClient.get<ListMedicineTypeResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public getMedicineType(idMedicineType: number): Observable<GetMedicineTypeResponse> {
    let uri = `MedicineType/idMedicineType/${idMedicineType}`;
   
    return this.httpClient.get<GetMedicineTypeResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public createMedicineType(body: MedicineTypeRequest): Observable<ReturnStruct>{

    let uri = `MedicineType`

    return this.httpClient.post<ReturnStruct>(environment.urlApiPharmacy + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public updateMedicineType(idMedicineType: number, body: MedicineTypeRequest): Observable<ReturnStruct>{

    let uri = `MedicineType/idMedicineType/${idMedicineType}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiPharmacy + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public deleteMedicineType(idMedicineType: number): Observable<ReturnStruct>{

    let uri = `MedicineType/idMedicineType/${idMedicineType}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiPharmacy + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
