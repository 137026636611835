import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ServiceService } from 'src/app/shared/services/API/flow/service.service';

@Component({
  selector: 'app-service-register-confirm-modal',
  templateUrl: './service-register-confirm-modal.component.html',
  styleUrls: ['./service-register-confirm-modal.component.css']
})
export class ServiceRegisterConfirmModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<ServiceRegisterConfirmModalComponent>,
  private serviceService: ServiceService,
  private alertService: AlertService,) { }

  public isLoading:boolean;  

  ngOnInit(): void {
    this.isLoading = false;
  }
  
  close(){
    this.matDialogRef.close({saveService: false}); 
  }
  
  clickDelete(){
    
    this.isLoading = true;

    if(this.data.isUpdate){
      this.updateService();
    }
    else{
      this.createService();
    }
  }
  
  clickCancel(){
    this.matDialogRef.close({saveService: false}); 
  }

  updateService(){
    this.serviceService.updateService(this.data.serviceRegisterRequest, this.data.idService).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false; 
      
      this.matDialogRef.close({saveService: true}); 
      
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }
  
  createService(){
    this.serviceService.createService(this.data.serviceRegisterRequest).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;    
      
      this.matDialogRef.close({saveService: true}); 
      
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }
}
