<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <a class="back-link" routerLink="/classification">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Classificação
        </a>
        <h1>Configurar Setor</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div formArrayName="listSelect" *ngFor="let item of model.get('listSelect')['controls']; let i = index">
                <app-protocol-sector-multiselect [formGroup]="item" [index]="i" [listProtocol]="listProtocol"
                    [listSupplementaryDataRequiredField]="listSupplementaryDataRequiredField"
                    [listPreAdmissionRequiredField]="listPreAdmissionRequiredField" [listDeviceType]="listDeviceType"
                    [sector]="listSector[i]" [listOximeter]="listOximeter" [listThermometer]="listThermometer"
                    [listPressureMeasurer]="listPressureMeasurer" [listGlucometer]="listGlucometer"
                    [listMetaData]="listMetaData" [idRoom]="idRoom">
                </app-protocol-sector-multiselect>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <button *ngIf="!hasConfigSectorVisualizarRole" mat-flat-button type="submit" color="primary" class="btn-block">
                        <span *ngIf="isLoading == false">Salvar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                    <button *ngIf="hasConfigSectorVisualizarRole" mat-flat-button type="submit" color="primary" class="btn-block" 
                        [disabled]="hasConfigSectorVisualizarRole" matTooltip="Você não tem permissão para salvar.">
                        <span *ngIf="isLoading == false">Salvar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
                <div class="col-md-2">
                    <a mat-flat-button type="button" routerLink="/classification" color="accent" class=" btn-block">
                        Cancelar
                    </a>
                </div>
            </div>
        </form>
    </div>
</div>