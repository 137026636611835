import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { BusinessIntelligenceReportResponse } from '../../responses/business-intelligence/business-intelligence-report.response';

@Injectable({
  providedIn: 'root'
})
export class OrchestratorBiService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

public showReportInternal(idReportFunctionHealthUnit: number, idBiPanel:number, isFirst:boolean): Observable<BusinessIntelligenceReportResponse> {
    let uri = `ShowReportInternal/idReportFunctionHealthUnit/${idReportFunctionHealthUnit}/idBiPanel/${idBiPanel}/isFirst/${isFirst}`

    return this.httpClient.get<BusinessIntelligenceReportResponse>(environment.urlApiOrchestratorBusinessIntelligence + uri, this.addHeaderHashBusinessIntelligence())
    .pipe(
      catchError(this.handleError)
    )
  }

  public showReport(idReportFunctionHealthUnit: number): Observable<BusinessIntelligenceReportResponse> {
      let uri = `ShowReport/idReportFunctionHealthUnit/${idReportFunctionHealthUnit}`
      return this.httpClient.get<BusinessIntelligenceReportResponse>(environment.urlApiOrchestratorBusinessIntelligence + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
    }
}