import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { VerifyCNPJ } from 'src/app/shared/custom-validators/cnpj.validator';
import { VerifyCPF } from 'src/app/shared/custom-validators/cpf.validator';
import { AdmissionGuideFieldsMaxLengthEnum } from 'src/app/shared/enum/private-billing/admission-guide-fields-max-length.enum';
import { AdmissionProcedureFieldsMaxLengthEnum } from 'src/app/shared/enum/private-billing/admission-procedure-fields-max-length.enum';
import { GuideStatusEnum } from 'src/app/shared/enum/private-billing/guide-status.enum';
import { GuideTypeEnum } from 'src/app/shared/enum/private-billing/guide-type.enum';
import { LookupAdmissionRequiredFieldEnum } from 'src/app/shared/enum/private-billing/lookup-admission-required-field.enum';
import { ProviderIdentityTypeEnum } from 'src/app/shared/enum/private-billing/provider-identity-type.enum';
import { HealthcareAgreementService } from 'src/app/shared/services/API/private-billing/healthcare-agreement.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { AccidentIndication } from 'src/app/shared/services/models/private-billing/accident-indication.model';
import { AccommodationType } from 'src/app/shared/services/models/private-billing/accommodation-type.model';
import { AdmissionProcedure } from 'src/app/shared/services/models/private-billing/admission-procedure.model';
import { AdmissionRequiredField } from 'src/app/shared/services/models/private-billing/admission-required-field.model';
import { AttendanceAspect } from 'src/app/shared/services/models/private-billing/attendance-aspect.model';
import { Cbo } from 'src/app/shared/services/models/private-billing/cbo.model';
import { GuideStatus } from 'src/app/shared/services/models/private-billing/guide-status.model';
import { HospitalizationRegime } from 'src/app/shared/services/models/private-billing/hospitalization-regime.model';
import { HospitalizationType } from 'src/app/shared/services/models/private-billing/hospitalization-type.model';
import { ProfessionalCouncil } from 'src/app/shared/services/models/private-billing/professional-council.model';
import { ProviderIdentityType } from 'src/app/shared/services/models/private-billing/provider-identity-type.model';
import { State } from 'src/app/shared/services/models/private-billing/state.model';
import { TerminologyGroup } from 'src/app/shared/services/models/private-billing/terminology-group.model';
import { TissBilling } from 'src/app/shared/services/models/private-billing/tiss-billing.model';
import { AdmissionGuideRequest } from 'src/app/shared/services/requests/private-billing/admission-guide.request';
import { AdmissionGuideStruct } from 'src/app/shared/services/structs/private-billing/admission-guide.struct';
import { AdmissionProcedureStruct } from 'src/app/shared/services/structs/private-billing/admission-procedure.struct';
import { HealthcareAgreementStruct } from 'src/app/shared/services/structs/private-billing/healthcare-agreement.struct';
import { ProcedureStruct } from 'src/app/shared/services/structs/private-billing/procedure.struct';
import { AdmissionGuideService } from 'src/app/shared/services/API/private-billing/admission-guide.service';
import { ProfessionalAgreementStruct } from 'src/app/shared/services/structs/private-billing/professional-agreement.struct';
import { environment } from 'src/environments/environment';
import { GuideRequest } from 'src/app/shared/services/requests/private-billing/guide.request';
import { OrchestratorPatientGuideService } from 'src/app/shared/services/API/orchestrator-patient/orchestrator-patient-guide.service';
import { GuideService } from 'src/app/shared/services/API/private-billing/guide.service';
import { DateValidator } from 'src/app/shared/custom-validators/date.validator';
import { GuideReportResponse } from 'src/app/shared/services/responses/orchestrator-patient/guide-report.response';
import { TissGuideAdmissionModalComponent } from 'src/app/shared/components/tiss-guides/tiss-guide-admission-modal/tiss-guide-admission-modal.component';
import { AdmissionProcedureComponent } from 'src/app/shared/components/tiss-guides/tiss-guide-admission-modal/admission-procedure/admission-procedure.component';
import { ReportModalComponent } from 'src/app/shared/components/report-modal/report-modal.component';
import { AlertModalComponent } from 'src/app/shared/components/alert-modal/alert-modal.component';
import { LookupResponse } from 'src/app/shared/services/responses/private-billing/lookup.response';
@Component({
  selector: 'app-tiss-guide-admission-history',
  templateUrl: './tiss-guide-admission-history.component.html',
  styleUrls: ['./tiss-guide-admission-history.component.css']
})
export class TissGuideAdmissionHistoryComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<TissGuideAdmissionModalComponent>,
    private formBuilder: FormBuilder,
    private maskService: MaskService,
    private alertService: AlertService,
    private healthcareAgreementService: HealthcareAgreementService,
    private admissionGuideService: AdmissionGuideService,
    private orchestratorPatientGuideService: OrchestratorPatientGuideService,
    private guideService: GuideService,
  ) { matDialogRef.disableClose = true; }

  @ViewChild('admissionProcedureComponent', { static: false }) admissionProcedureComponent: AdmissionProcedureComponent;

  public model: FormGroup;
  public modelProcedure: FormGroup;
  public masks: Masks = this.maskService.getMasks();
  public guideType: number = GuideTypeEnum.internacao;
  public guideNumber: string = null;
  public idHealthcareAgreement: number = null;
  public idAdmissionGuide: number = null;
  public idEpisode: number = null;
  public idGuide: number = null;
  public requesterCodeMask: string = '';
  public requesterCodeLength: number = AdmissionGuideFieldsMaxLengthEnum.requesterCodeOperatorCnpj;
  public requesterCodeMaxLength: number = AdmissionGuideFieldsMaxLengthEnum.requesterCodeOperator;

  public admissionGuideMaxLength: typeof AdmissionGuideFieldsMaxLengthEnum = AdmissionGuideFieldsMaxLengthEnum;
  public admissionProcedureMaxLength: typeof AdmissionProcedureFieldsMaxLengthEnum = AdmissionProcedureFieldsMaxLengthEnum;

  public isFirstLoading: boolean = true;
  public isLoading: boolean = true;
  public urlListAnsRegistration: string = environment.urlApiPrivateBilling + 'HealthcareAgreement/getAllByHealthUnit';
  public listProfessionalAgreementStruct: ProfessionalAgreementStruct[] = [];
  public listAdmissionProcedure: AdmissionProcedureStruct[] = [];
  public admissionGuideStruct: AdmissionGuideStruct = new AdmissionGuideStruct();
  public listAccidentIndication: AccidentIndication[] = this.data.lookups.listAccidentIndication;;
  public listGuideStatus: GuideStatus[] = this.data.lookups.listGuideStatus;
  public listAttendanceAspect: AttendanceAspect[] = this.data.lookups.listAttendanceAspect;
  public listProviderIdentityType: ProviderIdentityType[] = this.data.lookups.listProviderIdentityType;
  public listProfessionalCouncil: ProfessionalCouncil[] = this.data.lookups.listProfessionalCouncil;
  public listCbo: Cbo[] = this.data.lookups.listCbo;
  public listState: State[] = this.data.lookups.listState;
  public filteredList: any[] = [];
  public listHospitalizationRegime: HospitalizationRegime[] = this.data.lookups.listHospitalizationRegime;
  public listHospitalizationType: HospitalizationType[] = this.data.lookups.listHospitalizationType;
  public listAccommodationType: AccommodationType[] = this.data.lookups.listAccommodationType;
  public listAdmissionRequiredField: AdmissionRequiredField[] = [];
  public healthcareAgreementStruct: HealthcareAgreementStruct = null;
  public tissBilling: TissBilling = new TissBilling();
  public newProcedure: AdmissionProcedure = new AdmissionProcedure();
  public listTussTerminologyGroup: TerminologyGroup[] = [];
  public tussTerminologyGroupMap: Map<number, TerminologyGroup> = this.generateTerminologyGroupMap(this.data.lookups.listTussTerminologyGroup);
  public listProcedure: ProcedureStruct[] = [];

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      firstChildGroup: this.formBuilder.group({
        ansRegistration: [this.idHealthcareAgreement, [Validators.required, Validators.maxLength(AdmissionGuideFieldsMaxLengthEnum.ansRegistration)]],
        idProfessionalAgreement: ['', [Validators.required]],
      }),
      guideNumber: ['', [Validators.required]],
      idGuideStatus: [null, [Validators.required]],
      providerGuideNumber: [null, Validators.maxLength(AdmissionGuideFieldsMaxLengthEnum.providerGuideNumber)],
      datetimeAuthorization: ['', [Validators.required, DateValidator()]],
      ticket: ['', [Validators.maxLength(AdmissionGuideFieldsMaxLengthEnum.ticket)]],
      datetimeExpirationTicket: ['', [DateValidator()]],
      beneficiaryDocumentNumber: ['', [Validators.required, Validators.maxLength(AdmissionGuideFieldsMaxLengthEnum.beneficiaryDocumentNumber)]],

      datetimeExpirationDocumentNumber: ['', [DateValidator()]],
      newbornAttendanceIndicator: [false, [Validators.required]],
      beneficiarySocialName: ['', [Validators.maxLength(AdmissionGuideFieldsMaxLengthEnum.beneficiarySocialName)]],
      beneficiaryName: ['', [Validators.required, Validators.maxLength(AdmissionGuideFieldsMaxLengthEnum.beneficiaryName)]],
      idProviderIdentityType: ['', [Validators.required]],
      requesterCodeOperator: ['', [Validators.required, Validators.maxLength(AdmissionGuideFieldsMaxLengthEnum.requesterCodeOperatorCnpj)]],
      requesterName: ['', [Validators.required, Validators.maxLength(AdmissionGuideFieldsMaxLengthEnum.requesterName)]],

      requesterProfessionalName: [''],
      idProfessionalCouncil: [null, [Validators.required]],
      requesterProfessionalNumberCouncil: ['', [Validators.required, Validators.maxLength(AdmissionGuideFieldsMaxLengthEnum.requesterProfessionalNumberCouncil)]],
      idState: [null, [Validators.required]],
      idCbo: [null, [Validators.required]],
      requesterHospitalCode: ['', [Validators.required, Validators.maxLength(AdmissionGuideFieldsMaxLengthEnum.requesterHospitalCode)]],
      requesterHospitalName: ['', [Validators.required, Validators.maxLength(AdmissionGuideFieldsMaxLengthEnum.requesterHospitalName)]],

      datetimeSuggestedAdmission: ['', [Validators.required, DateValidator()]],
      idAttendanceAspect: [null, [Validators.required]],
      idHospitalizationType: [null, [Validators.required]],
      idHospitalizationRegime: [null, [Validators.required]],
      requesterHospitalDailyCount: [null, [Validators.required, Validators.maxLength(AdmissionGuideFieldsMaxLengthEnum.requesterHospitalDailyCount)]],
      opmeUsageForecast: [false, [Validators.required]],
      predictionChemotherapyUse: [false, [Validators.required]],
      clinicalIndication: ['', [Validators.required, Validators.maxLength(AdmissionGuideFieldsMaxLengthEnum.clinicalIndication)]],

      cidTenPrimary: ['', [Validators.maxLength(AdmissionGuideFieldsMaxLengthEnum.cidTenPrimary)]],
      cidTenSecondary: ['', [Validators.maxLength(AdmissionGuideFieldsMaxLengthEnum.cidTenSecondary)]],
      cidTenTertiary: ['', [Validators.maxLength(AdmissionGuideFieldsMaxLengthEnum.cidTenTertiary)]],
      cidTenQuaternary: ['', [Validators.maxLength(AdmissionGuideFieldsMaxLengthEnum.cidTenQuaternary)]],
      idAccidentIndication: [null, [Validators.required]],

      datetimeHospitalAuthorization: ['', [DateValidator()]],
      authorizedHospitalDailyCount: [null, [Validators.maxLength(AdmissionGuideFieldsMaxLengthEnum.authorizedHospitalDailyCount)]],
      idAccommodationType: [null],
      requesterCodeOperatorAuthorized: [null, [Validators.maxLength(AdmissionGuideFieldsMaxLengthEnum.requesterCodeOperatorAuthorized)]],
      requesterHospitalNameAuthorized: [null, [Validators.maxLength(AdmissionGuideFieldsMaxLengthEnum.requesterHospitalNameAuthorized)]],
      codeCnes: [null, [Validators.maxLength(AdmissionGuideFieldsMaxLengthEnum.codeCnes)]],
      observationJustification: ['', [Validators.maxLength(AdmissionGuideFieldsMaxLengthEnum.observationJustification)]],
      datetimeRequest: ['', [Validators.required, DateValidator()]],
    });

    this.mapperGuide();
  }

  mapperGuide() {
    this.idEpisode = this.data.idEpisode;
    this.admissionGuideStruct = this.data.guide;
    this.idAdmissionGuide = this.admissionGuideStruct.admissionGuide.idAdmissionGuide;
    this.populateData();
  }

  close() {
    this.matDialogRef.close({ confirm: false });
  }

  submit() {
    if (this.isLoading)
      return;

    if (this.model.invalid) {
      this.alertService.show('Erro', "Preencha corretamente os campos obrigatórios", AlertType.error);
      return;
    }

    if (!this.listAdmissionProcedure || this.listAdmissionProcedure.length == 0) {
      this.alertService.show('Erro', "Preencha pelo menos um procedimento.", AlertType.error);
      return;
    }

    this.isLoading = true;

    let request = new AdmissionGuideRequest();
    request.admissionGuideStruct = new AdmissionGuideStruct();

    if (this.healthcareAgreementStruct != null) {
      request.admissionGuideStruct.guide.ansCode = this.healthcareAgreementStruct.ansRegistrationNumber;
      request.admissionGuideStruct.guide.idHealthcareAgreement = this.healthcareAgreementStruct.idHealthcareAgreement;
    }

    request.admissionGuideStruct.guide.guideNumber = this.model.get('guideNumber').value ? parseInt(this.model.get('guideNumber').value) : null;
    request.admissionGuideStruct.guideNumber = this.model.get('guideNumber').value ? this.model.get('guideNumber').value : null;
    request.admissionGuideStruct.guide.idGuideStatus = this.model.get('idGuideStatus').value;
    request.admissionGuideStruct.guide.patientName = this.model.get('beneficiaryName').value;
    request.admissionGuideStruct.guide.patientSocialName = this.model.get('beneficiarySocialName').value;

    request.admissionGuideStruct.guide.idEpisode = this.idEpisode ? this.idEpisode : 0;
    request.admissionGuideStruct.guide.idGuide = this.idGuide ? this.idGuide : 0;
    request.admissionGuideStruct.admissionGuide.idAdmissionGuide = this.idAdmissionGuide ? this.idAdmissionGuide : 0;

    if (this.data.idRoom !== undefined && this.data.idRoom !== null)
      request.admissionGuideStruct.guide.idRoom = this.data.idRoom;

    if (this.data.idSector !== undefined && this.data.idSector !== null)
      request.admissionGuideStruct.guide.idSector = this.data.idSector;

    if (this.admissionGuideStruct) {
      if (this.healthcareAgreementStruct == null) {
        request.admissionGuideStruct.guide.ansCode = this.admissionGuideStruct.guide.ansCode;
        request.admissionGuideStruct.guide.idHealthcareAgreement = this.admissionGuideStruct.guide.idHealthcareAgreement;
      }
    }

    if (this.model.get('datetimeAuthorization'))
      request.admissionGuideStruct.admissionGuide.datetimeAuthorization = this.maskService.formatStringToDate(this.model.get('datetimeAuthorization').value);

    if (this.model.get('datetimeExpirationTicket'))
      request.admissionGuideStruct.admissionGuide.datetimeExpirationTicket = this.maskService.formatStringToDate(this.model.get('datetimeExpirationTicket').value);

    if (this.model.get('datetimeExpirationDocumentNumber'))
      request.admissionGuideStruct.admissionGuide.datetimeExpirationDocumentNumber = this.maskService.formatStringToDate(this.model.get('datetimeExpirationDocumentNumber').value);

    if (this.model.get('datetimeSuggestedAdmission'))
      request.admissionGuideStruct.admissionGuide.datetimeSuggestedAdmission = this.maskService.formatStringToDate(this.model.get('datetimeSuggestedAdmission').value);

    if (this.model.get('datetimeHospitalAuthorization'))
      request.admissionGuideStruct.admissionGuide.datetimeHospitalAuthorization = this.maskService.formatStringToDate(this.model.get('datetimeHospitalAuthorization').value);

    if (this.model.get('datetimeRequest'))
      request.admissionGuideStruct.admissionGuide.datetimeRequest = this.maskService.formatStringToDate(this.model.get('datetimeRequest').value);

    request.admissionGuideStruct.admissionGuide.beneficiaryDocumentNumber = this.model.get('beneficiaryDocumentNumber').value;
    request.admissionGuideStruct.admissionGuide.providerGuideNumber = this.model.get('providerGuideNumber').value ? this.model.get('providerGuideNumber').value.toString() : null;
    request.admissionGuideStruct.guideNumber = this.model.get('providerGuideNumber').value ? this.model.get('providerGuideNumber').value.toString() : null;
    request.admissionGuideStruct.admissionGuide.ticket = this.model.get('ticket').value;
    request.admissionGuideStruct.admissionGuide.newbornAttendance = this.model.get('newbornAttendanceIndicator').value;
    request.admissionGuideStruct.admissionGuide.idProviderIdentityType = this.model.get('idProviderIdentityType').value;
    request.admissionGuideStruct.admissionGuide.requesterCodeOperator = this.model.get('requesterCodeOperator').value;
    request.admissionGuideStruct.admissionGuide.requesterName = this.model.get('requesterName').value;
    request.admissionGuideStruct.admissionGuide.requesterName = this.model.get('requesterName').value;
    request.admissionGuideStruct.admissionGuide.idProfessionalAgreement = this.model.get('firstChildGroup').get('idProfessionalAgreement').value;
    request.admissionGuideStruct.admissionGuide.idProfessionalCouncil = this.model.get('idProfessionalCouncil').value;
    request.admissionGuideStruct.admissionGuide.requesterProfessionalNumberCouncil = this.model.get('requesterProfessionalNumberCouncil').value ? this.model.get('requesterProfessionalNumberCouncil').value.toString() : null;
    request.admissionGuideStruct.admissionGuide.idState = this.model.get('idState').value;
    request.admissionGuideStruct.admissionGuide.idCbo = this.model.get('idCbo').value;
    request.admissionGuideStruct.admissionGuide.requesterHospitalCode = this.model.get('requesterHospitalCode').value;
    request.admissionGuideStruct.admissionGuide.requesterHospitalName = this.model.get('requesterHospitalName').value;
    request.admissionGuideStruct.admissionGuide.idAttendanceAspect = this.model.get('idAttendanceAspect').value;
    request.admissionGuideStruct.admissionGuide.idHospitalizationType = this.model.get('idHospitalizationType').value;
    request.admissionGuideStruct.admissionGuide.idHospitalizationRegime = this.model.get('idHospitalizationRegime').value;
    request.admissionGuideStruct.admissionGuide.requesterHospitalDailyCount = this.model.get('requesterHospitalDailyCount').value;
    request.admissionGuideStruct.admissionGuide.opmeUsageForecast = this.model.get('opmeUsageForecast').value ? 'S' : 'N';
    request.admissionGuideStruct.admissionGuide.predictionChemotherapyUse = this.model.get('predictionChemotherapyUse').value ? 'S' : 'N';
    request.admissionGuideStruct.admissionGuide.clinicalIndication = this.model.get('clinicalIndication').value;
    request.admissionGuideStruct.admissionGuide.cidTenPrimary = this.model.get('cidTenPrimary').value;
    request.admissionGuideStruct.admissionGuide.cidTenSecondary = this.model.get('cidTenSecondary').value;
    request.admissionGuideStruct.admissionGuide.cidTenTertiary = this.model.get('cidTenTertiary').value;
    request.admissionGuideStruct.admissionGuide.cidTenQuaternary = this.model.get('cidTenQuaternary').value;
    request.admissionGuideStruct.admissionGuide.idAccidentIndication = this.model.get('idAccidentIndication').value;
    request.admissionGuideStruct.admissionGuide.authorizedHospitalDailyCount = this.model.get('authorizedHospitalDailyCount').value;
    request.admissionGuideStruct.admissionGuide.idAccommodationType = this.model.get('idAccommodationType').value;
    request.admissionGuideStruct.admissionGuide.requesterCodeOperatorAuthorized = this.model.get('requesterCodeOperatorAuthorized').value;
    request.admissionGuideStruct.admissionGuide.requesterHospitalNameAuthorized = this.model.get('requesterHospitalNameAuthorized').value;
    request.admissionGuideStruct.admissionGuide.codeCnes = this.model.get('codeCnes').value;
    request.admissionGuideStruct.admissionGuide.observationJustification = this.model.get('observationJustification').value;
    request.admissionGuideStruct.listAdmissionProcedure = this.listAdmissionProcedure;

    this.isLoading = false;

    if (!this.data.isAttendance) {
      let guideRequest: GuideRequest = new GuideRequest();
      guideRequest.admissionGuideStruct = request.admissionGuideStruct;

      this.post(guideRequest);
    }
    else {
      request.admissionGuideStruct.hasAlteration = true;
      this.validateRequest(request);
    }
  }

  populateData() {
    this.idGuide = this.admissionGuideStruct.guide.idGuide;
    this.listAdmissionProcedure = [...this.admissionGuideStruct.listAdmissionProcedure];
    this.idAdmissionGuide = this.admissionGuideStruct.admissionGuide.idAdmissionGuide;
    this.model.get('firstChildGroup').get('ansRegistration').setValue(this.admissionGuideStruct.guide.idHealthcareAgreement);

    this.guideNumber = this.admissionGuideStruct.guideNumber;
    this.model.get('guideNumber').setValue(this.admissionGuideStruct.guideNumber);
    this.model.get('idGuideStatus').setValue(this.admissionGuideStruct.guide.idGuideStatus);
    this.model.get('providerGuideNumber').setValue(this.admissionGuideStruct.admissionGuide.providerGuideNumber);
    this.model.get('datetimeAuthorization').setValue(this.maskService.formatDateToString(this.admissionGuideStruct.admissionGuide.datetimeAuthorization, false));
    this.model.get('ticket').setValue(this.admissionGuideStruct.admissionGuide.ticket);

    if (this.admissionGuideStruct.admissionGuide.datetimeExpirationTicket != null)
      this.model.get('datetimeExpirationTicket').setValue(this.maskService.formatDateToString(this.admissionGuideStruct.admissionGuide.datetimeExpirationTicket, false));

    if (this.admissionGuideStruct.admissionGuide.datetimeExpirationDocumentNumber != null)
      this.model.get('datetimeExpirationDocumentNumber').setValue(this.maskService.formatDateToString(this.admissionGuideStruct.admissionGuide.datetimeExpirationDocumentNumber, false));

    this.model.get('beneficiaryDocumentNumber').setValue(this.admissionGuideStruct.admissionGuide.beneficiaryDocumentNumber);
    this.model.get('newbornAttendanceIndicator').setValue(this.admissionGuideStruct.admissionGuide.newbornAttendance);
    this.model.get('beneficiarySocialName').setValue(this.admissionGuideStruct.guide.patientSocialName);
    this.model.get('beneficiaryName').setValue(this.admissionGuideStruct.guide.patientName);
    this.model.get('idProviderIdentityType').setValue(this.admissionGuideStruct.admissionGuide.idProviderIdentityType);
    this.model.get('requesterCodeOperator').setValue(this.admissionGuideStruct.admissionGuide.requesterCodeOperator);
    this.model.get('requesterName').setValue(this.admissionGuideStruct.admissionGuide.requesterName);
    this.model.get('firstChildGroup').get('idProfessionalAgreement').setValue(this.admissionGuideStruct.admissionGuide.idProfessionalAgreement);
    this.model.get('requesterProfessionalName').setValue(this.admissionGuideStruct.admissionGuide.requesterProfessionalName);
    this.model.get('idProfessionalCouncil').setValue(this.admissionGuideStruct.admissionGuide.idProfessionalCouncil);
    this.model.get('requesterProfessionalNumberCouncil').setValue(this.admissionGuideStruct.admissionGuide.requesterProfessionalNumberCouncil);
    this.model.get('idState').setValue(this.admissionGuideStruct.admissionGuide.idState);
    this.model.get('idCbo').setValue(this.admissionGuideStruct.admissionGuide.idCbo);
    this.model.get('requesterHospitalCode').setValue(this.admissionGuideStruct.admissionGuide.requesterHospitalCode);
    this.model.get('requesterHospitalName').setValue(this.admissionGuideStruct.admissionGuide.requesterHospitalName);
    this.model.get('datetimeSuggestedAdmission').setValue(this.maskService.formatDateToString(this.admissionGuideStruct.admissionGuide.datetimeSuggestedAdmission, false));
    this.model.get('idAttendanceAspect').setValue(this.admissionGuideStruct.admissionGuide.idAttendanceAspect);
    this.model.get('idHospitalizationType').setValue(this.admissionGuideStruct.admissionGuide.idHospitalizationType);
    this.model.get('idHospitalizationRegime').setValue(this.admissionGuideStruct.admissionGuide.idHospitalizationRegime);
    this.model.get('requesterHospitalDailyCount').setValue(this.admissionGuideStruct.admissionGuide.requesterHospitalDailyCount);
    let opme = this.admissionGuideStruct.admissionGuide.opmeUsageForecast == "S";
    this.model.get('opmeUsageForecast').setValue(opme);
    let predictionChemotherapy = this.admissionGuideStruct.admissionGuide.predictionChemotherapyUse == "S";
    this.model.get('predictionChemotherapyUse').setValue(predictionChemotherapy);
    this.model.get('clinicalIndication').setValue(this.admissionGuideStruct.admissionGuide.clinicalIndication);
    this.model.get('cidTenPrimary').setValue(this.admissionGuideStruct.admissionGuide.cidTenPrimary);
    this.model.get('cidTenSecondary').setValue(this.admissionGuideStruct.admissionGuide.cidTenSecondary);
    this.model.get('cidTenTertiary').setValue(this.admissionGuideStruct.admissionGuide.cidTenTertiary);
    this.model.get('cidTenQuaternary').setValue(this.admissionGuideStruct.admissionGuide.cidTenQuaternary);
    this.model.get('idAccidentIndication').setValue(this.admissionGuideStruct.admissionGuide.idAccidentIndication);

    if (this.admissionGuideStruct.admissionGuide.datetimeHospitalAuthorization != null)
      this.model.get('datetimeHospitalAuthorization').setValue(this.maskService.formatDateToString(this.admissionGuideStruct.admissionGuide.datetimeHospitalAuthorization, false));

    this.model.get('authorizedHospitalDailyCount').setValue(this.admissionGuideStruct.admissionGuide.authorizedHospitalDailyCount);
    this.model.get('idAccommodationType').setValue(this.admissionGuideStruct.admissionGuide.idAccommodationType);
    this.model.get('requesterCodeOperatorAuthorized').setValue(this.admissionGuideStruct.admissionGuide.requesterCodeOperatorAuthorized);
    this.model.get('requesterHospitalNameAuthorized').setValue(this.admissionGuideStruct.admissionGuide.requesterHospitalNameAuthorized);
    this.model.get('codeCnes').setValue(this.admissionGuideStruct.admissionGuide.codeCnes);
    this.model.get('observationJustification').setValue(this.admissionGuideStruct.admissionGuide.observationJustification);
    this.model.get('datetimeRequest').setValue(this.maskService.formatDateToString(this.admissionGuideStruct.admissionGuide.datetimeRequest, false));
    this.idHealthcareAgreement = this.admissionGuideStruct.guide.idHealthcareAgreement;

    if (this.admissionGuideStruct && this.admissionGuideStruct.listAdmissionProcedure) {
      this.admissionGuideStruct.listAdmissionProcedure.forEach(x => {
        if (x.procedure && x.procedure.terminology && this.tussTerminologyGroupMap.has(x.procedure.terminology.idTerminologyGroup)) {
          const terminologyGroup = this.data.lookups.listTussTerminologyGroup.find(y => y.idTerminologyGroup == x.procedure.terminology.idTerminologyGroup);
          x.terminologyGroupCode = terminologyGroup.terminologyGroupCode;
          x.idTerminologyGroup = terminologyGroup.idTerminologyGroup;
        }
      });

      this.listProcedure = this.admissionGuideStruct.listAdmissionProcedure.map(x => x.procedure);
    }
    this.model.disable();
    this.populateAnsSelects();
  }
  populateAnsSelects() {
    this.idHealthcareAgreement = this.model.get('firstChildGroup').get('ansRegistration').value;

    this.isLoading = true;

    this.healthcareAgreementService.getAllSelect(this.idHealthcareAgreement, this.guideType).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.healthcareAgreementStruct = new HealthcareAgreementStruct();
        this.healthcareAgreementStruct = response.healthcareAgreementStruct;
        this.tissBilling = response.tissBilling;
        this.listAdmissionRequiredField = response.listAdmissionRequiredField;

        this.listProfessionalAgreementStruct = response.listProfessionalAgreementStruct.filter(x => x.isActive || x.idProfessionalAgreement == this.admissionGuideStruct.admissionGuide.idProfessionalAgreement);
        if (!this.listProfessionalAgreementStruct || this.listProfessionalAgreementStruct.length == 0)
          this.openAlertModal();        

        this.guideNumber = response.guideNumber;

        if (response && response.listAdmissionRequiredField)
          this.verifyRequiredFields();

        this.isLoading = false;
        this.isFirstLoading = false;
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  populateConfig() {
    this.model.get('idProviderIdentityType').setValue(this.tissBilling?.idProviderIdentityType);
    this.model.get('requesterCodeOperator').setValue(this.tissBilling?.providerCode);
    this.model.get('idAttendanceAspect').setValue(this.tissBilling?.idAttendanceAspect);
    this.model.get('codeCnes').setValue(this.tissBilling?.cnes);
    this.model.get('idAccidentIndication').setValue(this.tissBilling?.idAccidentIndication);
    this.model.get('requesterName').setValue(this.tissBilling?.providerName);
    this.model.get('beneficiaryName').setValue(this.data.patientName);
    this.model.get('beneficiarySocialName').setValue(this.data.patientSocialName);
    this.model.get('idGuideStatus').setValue(this.data?.idGuideStatus ? this.data.idGuideStatus : GuideStatusEnum.NovaGuia);

    this.model.enable();
   
    if (this.guideNumber != null || this.healthcareAgreementStruct?.nextGuideNumber != null)
      this.model.get('guideNumber').setValue(this.guideNumber != null ? this.guideNumber : this.healthcareAgreementStruct.nextGuideNumber);
    if (this.model.get('idProviderIdentityType'))
      this.checkProviderIdentityType(this.tissBilling?.idProviderIdentityType);

    this.disableFormFields();
  }

  verifyRequiredFields() {
    this.listAdmissionRequiredField.forEach(x => {
      if (x.idLookupAdmissionRequiredField == LookupAdmissionRequiredFieldEnum.NGuiaNaOperadora) {
        this.model.get('providerGuideNumber').setValidators([Validators.required]);
        this.model.get('providerGuideNumber').updateValueAndValidity();
      }
      else if (x.idLookupAdmissionRequiredField == LookupAdmissionRequiredFieldEnum.SenhaDeAutorizacao) {
        this.model.get('datetimeAuthorization').setValidators([Validators.required, DateValidator()]);
        this.model.get('datetimeAuthorization').updateValueAndValidity();
      }
      else if (x.idLookupAdmissionRequiredField == LookupAdmissionRequiredFieldEnum.ValidadeDaSenha) {
        this.model.get('datetimeExpirationTicket').setValidators([Validators.required, DateValidator()]);
        this.model.get('datetimeExpirationTicket').updateValueAndValidity();
      }
      else if (x.idLookupAdmissionRequiredField == LookupAdmissionRequiredFieldEnum.ValidadeDaCarteira) {
        this.model.get('datetimeExpirationDocumentNumber').setValidators([Validators.required, DateValidator()]);
        this.model.get('datetimeExpirationDocumentNumber').updateValueAndValidity();
      }
      else if (x.idLookupAdmissionRequiredField == LookupAdmissionRequiredFieldEnum.DiagnosticoPrincipal) {
        this.model.get('cidTenPrimary').setValidators([Validators.required]);
        this.model.get('cidTenPrimary').updateValueAndValidity();
      }
      else if (x.idLookupAdmissionRequiredField == LookupAdmissionRequiredFieldEnum.DiagnosticoSecundario) {
        this.model.get('cidTenSecondary').setValidators([Validators.required]);
        this.model.get('cidTenSecondary').updateValueAndValidity();
      }
      else if (x.idLookupAdmissionRequiredField == LookupAdmissionRequiredFieldEnum.TerceiroDiagnostico) {
        this.model.get('cidTenTertiary').setValidators([Validators.required]);
        this.model.get('cidTenTertiary').updateValueAndValidity();
      }
      else if (x.idLookupAdmissionRequiredField == LookupAdmissionRequiredFieldEnum.QuartoDiagnostico) {
        this.model.get('cidTenQuaternary').setValidators([Validators.required]);
        this.model.get('cidTenQuaternary').updateValueAndValidity();
      }
      else if (x.idLookupAdmissionRequiredField == LookupAdmissionRequiredFieldEnum.ObservacaoJustificativa) {
        this.model.get('observationJustification').setValidators([Validators.required]);
        this.model.get('observationJustification').updateValueAndValidity();
      }
    });
  }

  generateTerminologyGroupMap(listTussTerminologyGroup: any[]): Map<number, TerminologyGroup> {
    const map = new Map();
    listTussTerminologyGroup.forEach(x => {
      map.set(x.idTerminologyGroup, x);
    });

    return map;
  }

  onCreateDataChanged(data: any) {
    this.listAdmissionProcedure = [...data.listAdmissionProcedure];
  }

  checkProviderIdentityType(idProviderIdentityType: number) {
    if (idProviderIdentityType == ProviderIdentityTypeEnum.cpf) {
      this.requesterCodeMask = this.masks.cpf;
      this.requesterCodeMaxLength = null;
      this.model.get('requesterCodeOperator').setValidators([Validators.required, Validators.maxLength(AdmissionGuideFieldsMaxLengthEnum.requesterCodeOperatorCpf), VerifyCPF()]);
    }
    else if (idProviderIdentityType == ProviderIdentityTypeEnum.cnpj) {
      this.requesterCodeMask = this.masks.cnpj;
      this.requesterCodeMaxLength = null;
      this.model.get('requesterCodeOperator').setValidators([Validators.required, Validators.maxLength(AdmissionGuideFieldsMaxLengthEnum.requesterCodeOperatorCnpj), VerifyCNPJ()]);
    }
    else if (idProviderIdentityType == ProviderIdentityTypeEnum.providerCode) {
      this.requesterCodeMask = '';
      this.requesterCodeMaxLength = AdmissionGuideFieldsMaxLengthEnum.requesterCodeOperator;
      this.model.get('requesterCodeOperator').setValidators([Validators.required, Validators.maxLength(AdmissionGuideFieldsMaxLengthEnum.requesterCodeOperatorCnpj)])

      if (this.model.get('requesterCodeOperator') && this.model.get('requesterCodeOperator').value != null) {
        let cleanedValue = this.model.get('requesterCodeOperator').value.replace(/[.\-/]/g, '');
        this.model.get('requesterCodeOperator').setValue(cleanedValue);
      }

      this.model.get('requesterCodeOperator').updateValueAndValidity();
    }
  }

  print() {
    if (this.isLoading || !this.admissionGuideStruct || !this.admissionGuideStruct.guide || !this.admissionGuideStruct.guide.idGuide)
      return;

    this.isLoading = true;

    this.guideService.GetGuideReportName(this.admissionGuideStruct.guide.idGuide).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        let reportDialog = this.dialog.open(ReportModalComponent, {
          data: {
            reportPdf64: response.reportPdf64,
          },
        });

        this.isLoading = false;
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  post(request: GuideRequest) {
    this.isLoading = true;

    this.orchestratorPatientGuideService.PostAdmissionGuide(request).subscribe({
      next: (response: GuideReportResponse) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.alertService.show('Sucesso', "Guia salva com sucesso!", AlertType.success);
        this.isLoading = false;

        const dialogRef = this.dialog.open(ReportModalComponent, {
          data: {
            reportPdf64: response.admissionGuideString64,
          },
        });
        dialogRef.afterClosed().subscribe({
          next: result => {
            this.matDialogRef.close({ confirm: true, admissionGuideStruct: response.admissionGuideStruct, isAttendance: this.data.isAttendance });
          }
        });
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      }
    });
  }


  private throwException(error: string) {
    this.alertService.show('Erro inesperado', error, AlertType.error);
    this.isFirstLoading = false;
    this.isLoading = false;
  }

  validateRequest(request: AdmissionGuideRequest) {
    this.isLoading = true;

    this.admissionGuideService.ValidationRequest(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.alertService.show('Sucesso', "Guia validada com sucesso!", AlertType.success);
        this.matDialogRef.close({ confirm: true, admissionGuideStruct: request.admissionGuideStruct, isAttendance: this.data.isAttendance });
        this.isLoading = false;
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  onProfessionalChange(professional: ProfessionalAgreementStruct) {
    this.model.get('requesterProfessionalName').setValue(professional.nameProfessional);
    this.model.get('idProfessionalCouncil').setValue(professional.idProfessionalCouncil);
    this.model.get('requesterProfessionalNumberCouncil').setValue(professional.numberProfessional);
    this.model.get('idState').setValue(professional.idState);
    this.model.get('idCbo').setValue(professional.idCbo);
  }

  disableFormFields() {
    this.model.get('idProfessionalCouncil').disable();
    this.model.get('requesterProfessionalNumberCouncil').disable();
    this.model.get('idState').disable();
    this.model.get('idCbo').disable();
    this.model.get('guideNumber').disable();
  }

  resetChildComponent() {
    if (this.admissionProcedureComponent && typeof this.admissionProcedureComponent.reset === 'function')
      this.admissionProcedureComponent.reset();
  }

  openAlertModal() {
    let reportDialog = this.dialog.open(AlertModalComponent, {
      data: {
        title: "Atenção!",
        description: "Este convênio não possui profissionais vinculados. Execute o cadastro para salvar a guia corretamente.",
      },
    });
  }

  inputNumber(formName: string) {
    let value = this.model.get(formName).value ? this.model.get(formName).value : '';
    if (value) {
      value = value.replace(/[^0-9]/g, '');
      value = value.replace(/(\..*)\./g, '$1');
      this.model.get(formName).setValue(value);
    }
  }
}