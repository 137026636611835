import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { LiberatePrescriptionRequest } from '../../requests/orchestrator-patient/liberate-prescription.request';
import { ListMedicalPrescriptionEpisodeResponse } from '../../responses/medical-record/list-medical-prescription-episode.response';
import { EpisodePrescriptionResponse } from '../../responses/orchestrator-patient/episode-prescription.response';
import { LiberateSolutionPrescriptionRequest } from '../../requests/orchestrator-patient/liberate-solution-prescription.request';

@Injectable({
  providedIn: 'root'
})
export class CallEpisodePrescriptionService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  public listEpisodePrescription(searchText: string, idPrescriptionStatus: number, idEpisode: number, idSector: number, idCareLinePriority: number): Observable<ListMedicalPrescriptionEpisodeResponse> {
    let uri = `CallEpisodePrescription/idPrescriptionStatus/${idPrescriptionStatus}?`;

    if (searchText) uri = uri + `searchText=${searchText}&`;

    if (idEpisode) uri = uri + `idEpisode=${idEpisode}&`;

    if (idSector) uri = uri + `idSector=${idSector}&`;

    if (idCareLinePriority) uri = uri + `idCareLinePriority=${idCareLinePriority}&`;

    return this.httpClient.get<ListMedicalPrescriptionEpisodeResponse>(environment.urlApiOrchestratorPatient + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public listPrescription(idEpisode: number): Observable<EpisodePrescriptionResponse> {
    let uri = `CallEpisodePrescription/idEpisode/${idEpisode}`;

    return this.httpClient.get<EpisodePrescriptionResponse>(environment.urlApiOrchestratorPatient + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public liberatePrescription(liberatePrescriptionRequest: LiberatePrescriptionRequest): Observable<ListMedicalPrescriptionEpisodeResponse> {
    let uri = `CallEpisodePrescription`;

    return this.httpClient.post<ListMedicalPrescriptionEpisodeResponse>(environment.urlApiOrchestratorPatient + uri, liberatePrescriptionRequest, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public liberatePrescriptionSolution(liberateSolutionPrescriptionRequest: LiberateSolutionPrescriptionRequest): Observable<ListMedicalPrescriptionEpisodeResponse> {
    let uri = `CallEpisodePrescription/medicalPrescriptionSolution`;

    return this.httpClient.post<ListMedicalPrescriptionEpisodeResponse>(environment.urlApiOrchestratorPatient + uri, liberateSolutionPrescriptionRequest, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}