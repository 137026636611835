<div [formGroup] = "model">
    <div class="title-header">
        <h1>Atestado</h1>
    </div>
    <div class="row">
        <div class="col-2 col-sm-2 col-md-2">
            <mat-form-field appearance="outline">
                <mat-label>Dias de atestado</mat-label>
                <input matInput type="number" formControlName="sickDaysOffAmount">
                <span matSuffix>dias</span>
            </mat-form-field>
        </div>
        <div class="col-2 col-sm-2 col-md-2">
            <mat-slide-toggle class="indigent-checkbox" formControlName="isCidOnSickNote">Imprimir CID no atestado</mat-slide-toggle>
        </div>
    </div>
</div>