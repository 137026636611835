<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-exam-modal-list">
            <h2>Baixa no estoque</h2>
        </div>
        <div class="body-exam-modal-list">
            Escaneie o código de barras ou escolha um estoque para dar baixa.
        </div>
        <div class="body-exam-modal-list">
            <div class="row">
                <div class="col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Codigo de barra</mat-label>
                        <input autofocus matInput type="text" id="idStorage" [(ngModel)]="idStorage"
                            (keyup)="onKeySearch($event)" (ngModelChange)="onKeySearch($event)" [disabled]="disabledInupt">
                    </mat-form-field>
                </div>
            </div>
            <div class="row" *ngIf="listStorage != undefined">
                <div class="col-12">
                    <p><label class="label-storage">Estoque recomendado:
                            {{listStorage[0].idStorage}}
                        </label>
                    </p>
                    <mat-form-field appearance="outline">
                        <mat-label>Selecione o estoque</mat-label>
                        <mat-select [(ngModel)]="selectedStorage" (selectionChange)="selectionChange($event)"
                            [disabled]="disabledSelect">
                            <mat-option value="">Selecione</mat-option>
                            <mat-option *ngFor="let item of listStorage" value="{{item.idStorage}}">{{item.idStorage}} -
                                Lot.: {{item.batch}} - {{item.dateExpiration | date: 'dd/MM/yyyy'}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="footer-exam-modal-list">
            <button mat-flat-button color="primary" class="btn-block" (click)="clickConfirm()" [disabled]='!isValid'>
                <span *ngIf="isLoading == false">Confirmar</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
            <button mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Cancelar</button>
        </div>
    </div>
</div>