<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-confirm-modal-list">
            <h2>Encaminhar Paciente </h2>
        </div>
        <form [formGroup]="model" (ngSubmit)="submit()">
            <div class="body-confirm-modal-list">
                <mat-form-field appearance="outline">
                    <mat-label>Encaminhamento</mat-label>
                    <mat-select formControlName="idFlow">
                        <mat-option *ngFor="let item of data.listFoward" value="{{item.idFlow}}">{{item.locationName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Prioridades</mat-label>
                    <mat-select formControlName="listIdPriority" multiple>
                        <mat-option *ngFor="let item of data.listPriority" value="{{item.idPriority}}">
                            {{item.priorityName}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="footer-confirm-modal-list">
                <button mat-flat-button color="primary" class="btn-block" type="submit">
                    <span *ngIf="isLoading == false">Encaminhar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
                <button mat-flat-button color="accent" class="btn-block" *ngIf="isEpisodeAdministrativeRelease"
                    (click)="releaseEpisodeModal(idEpisodeAdministrativeRelease)" mat-flat-button type="button">
                    <span
                        *ngIf="idEpisodeAdministrativeRelease == idStatusEpisodeAltaAdministrativa && isLoading == false">Alta
                        administrativa</span>
                    <span
                        *ngIf="idEpisodeAdministrativeRelease == idStatusEpisodeInternar && isLoading == false">Internação
                        administrativa</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
                <button type="button" mat-flat-button color="accent" class="btn-block"
                    (click)="clickCancel()">Cancelar</button>
            </div>
        </form>
    </div>
</div>