<div class="container-generic">
    <div mat-dialog-content>
        <div class="mat-dialog-content">
            <!-- <div class="white-body"> -->
            <cdk-accordion class="profile-register-accordion">
                <cdk-accordion-item *ngFor="let sadt of this.data.listMedicalCareSadt; let index = index;"
                    #accordionItemModule="cdkAccordionItem" class="profile-register-accordion-item" role="button"
                    tabindex="0" [attr.id]="'accordion-header-' + index"
                    [attr.aria-expanded]="accordionItemModule.expanded"
                    [attr.aria-controls]="'accordion-body-' + index">
                    <div [ngClass]="{'profile-register-accordion-item-header-expanded': accordionItemModule.expanded}"
                        class="profile-register-accordion-item-header" (click)="accordionItemModule.toggle()">
                        Tipo - ({{ sadt.typeSadtName }} - {{
                        sadt.datetimeInclusion | date:'dd/MM/yy, H:mm' }})
                        <span
                            [ngClass]="{'profile-register-accordion-item-description-expanded': accordionItemModule.expanded}"
                            class="profile-register-accordion-item-description">
                            Clique para {{ accordionItemModule.expanded ? 'fechar' : 'abrir' }}
                        </span>
                    </div>
                    <div class="profile-register-accordion-item-body" role="region"
                        [style.display]="accordionItemModule.expanded ? '' : 'none'"
                        [attr.id]="'accordion-body-' + index" [attr.aria-labelledby]="'accordion-header-' + index">
                        <app-sadt-accordion [sadt]="sadt" [isLoading]="isLoading"
                            (saveSadtData)="saveSadt($event)"></app-sadt-accordion>
                    </div>
                </cdk-accordion-item>
            </cdk-accordion>
            <p
                *ngIf="this.data.listMedicalCareSadt != undefined && this.data.listMedicalCareSadt.length == 0 && !isFirstLoading">
                Não
                há pedidos de exame para
                esse paciente!
            </p>
            <button mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Fechar</button>
        </div>
    </div>
</div>