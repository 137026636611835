import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PrescriptionStatusEnum } from 'src/app/shared/enum/prescription-status.enum';
import { PresentationEnum } from 'src/app/shared/enum/prescription.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { CallEpisodePrescriptionService } from 'src/app/shared/services/API/orchestrator-patient/call-episode-prescription.service';
import { LocationService } from 'src/app/shared/services/API/pharmacy/location.service';
import { MedicinePresentationTypeService } from 'src/app/shared/services/API/pharmacy/medicine-presentation-type.service';
import { VerifyStorageService } from 'src/app/shared/services/API/pharmacy/verify-storage.service';
import { LocationModel } from 'src/app/shared/services/models/pharmacy/location.model';
import { MedicinePresentationTypeModel } from 'src/app/shared/services/models/pharmacy/medicine-presentation-type.model';
import { LiberatePrescriptionRequest } from 'src/app/shared/services/requests/orchestrator-patient/liberate-prescription.request';
import { ItemSearchSelectStruct } from 'src/app/shared/services/structs/pharmacy/item-search-select.struct';
import { ItemStruct } from 'src/app/shared/services/structs/pharmacy/item.struct';
import { KitItemIdAndQuantityStruct } from 'src/app/shared/services/structs/pharmacy/kit-item-id-and-quantity.struct';
import { KitItemSelectStruct } from 'src/app/shared/services/structs/pharmacy/kit-item-select.struct';
import { StorageStruct } from 'src/app/shared/services/structs/pharmacy/storage.struct';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-bed-management-material-create-modal',
  templateUrl: './bed-management-material-create-modal.component.html',
  styleUrls: ['./bed-management-material-create-modal.component.css']
})
export class BedManagementMaterialCreateModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<BedManagementMaterialCreateModalComponent>,
    public verifyStorageService: VerifyStorageService,
    private alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
    public locationService: LocationService,
    public callEpisodePrescriptionService: CallEpisodePrescriptionService,
    public medicinePresentationTypeService: MedicinePresentationTypeService,
  ) { }

  public isLoading: boolean;
  public barcodeValue: number;
  public storage: StorageStruct;
  public model: UntypedFormGroup;
  public listLocation: LocationModel[];
  public urlListKit: string;
  public validated: boolean;
  public listIdKit: number[];
  public presentationList: MedicinePresentationTypeModel[];
  public presentationOthersEnum: PresentationEnum = PresentationEnum.outros.valueOf();
  public idMedicinePresentationType: number;
  public medicinePresentationOther: string = null;
  public listItemFields: KitItemSelectStruct[];
  public haveListItem: boolean = false;

  ngOnInit(): void {
    this.listItemFields = [];
    this.validated = false;
    this.storageSuggestion();
    this.populateLocationSelect();
    this.populatePresentationType();
    this.isLoading = false;
    this.urlListKit = `${environment.urlApiPharmacy}Kit/getAll`
    this.model = this.formBuilder.group({
      presentationTypeDoctor: [{ value: '', disabled: true }],
      quantityDoctor: [{ value: this.data.prescription.quantity ? this.data.prescription.quantity : "Não informado", disabled: true }],
      idLocation: [, [Validators.required]],
      quantity: [, [Validators.required]],
      hasItemList: [false],
      idPresentationType: [{ value: '', disabled: true }]
    });

    if (this.data.prescription.quantity) {
      this.model.get('quantity').setValue(this.data.prescription.quantity);
    }
  }

  close() {
    this.matDialogRef.close({ checkMedicine: false });
  }

  submit() {
    if (this.isLoading || this.model.invalid)
      return;

    this.isLoading = true;

    let liberatePrescriptionRequest: LiberatePrescriptionRequest = new LiberatePrescriptionRequest();

    liberatePrescriptionRequest.idStorage = this.barcodeValue;
    liberatePrescriptionRequest.idMedicine = this.data.prescription.idMedicine;
    liberatePrescriptionRequest.quantity = this.model.get('quantity').value;
    liberatePrescriptionRequest.idLocation = this.model.get('idLocation').value;
    liberatePrescriptionRequest.listIdKit = [];
    liberatePrescriptionRequest.idStatusPrescription = PrescriptionStatusEnum.Liberado;
    liberatePrescriptionRequest.idMedicalPrescription = this.data.prescription.idMedicalPrescription;
    if (this.model.get('listIdKit').value) {
      this.model.get('listIdKit').value.forEach(item => {
        liberatePrescriptionRequest.listIdKit.push(item.idKit);
      });
    }

    let listKitItem: KitItemIdAndQuantityStruct[] = [];

    this.listItemFields.forEach(element => {
      let kitItem: KitItemIdAndQuantityStruct = new KitItemIdAndQuantityStruct();
      kitItem.idItem = element.formGroup.get('firstChildGroup').get('idItem').value;
      kitItem.amount = element.formGroup.get('amount').value;
      listKitItem.push(kitItem);
    });

    liberatePrescriptionRequest.listIdItemAmount = listKitItem;

    this.callEpisodePrescriptionService.liberatePrescription(liberatePrescriptionRequest).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.isLoading = false;
      this.alertService.show('Sucesso', "Prescrição liberada com sucesso!", AlertType.success);
      this.matDialogRef.close({ checkMedicine: true });
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  clickCancel() {
    this.matDialogRef.close({ checkMedicine: false });
  }

  storageSuggestion() {
    this.verifyStorageService.listMedicineStorage(this.data.prescription.idMedicine).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.isLoading = false;
      this.storage = response.listStorage[0];
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  populateLocationSelect() {
    this.locationService.readActive().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.isLoading = false;
      this.listLocation = response.listLocation;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }


  populatePresentationType() {
    this.medicinePresentationTypeService.listMedicinePresentationType().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.isLoading = false;
      this.presentationList = response.listMedicinePresentationType;

      this.model.get('presentationTypeDoctor').setValue(this.data.prescription.idPresentation ? this.data.prescription.presentationName : "Não informado");
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  selectedValue(event) {
    this.model.get('listIdKit').setValue(event);
  }

  selectLocation(event: any) {
    if(this.haveListItem) {
      this.listItemFields = [];
      this.addItem(null);
    }
  }

  addItem(event: any) {
    let newItemField: KitItemSelectStruct = new KitItemSelectStruct();
    newItemField.preSelectedItem = [];

    if(this.listItemFields?.length == 0)
      newItemField.idField = 1;
    else if(this.listItemFields?.length >= 0)
      newItemField.idField = Math.max.apply(Math, this.listItemFields.map(l => l.idField)) + 1;
    
    newItemField.formGroup = this.formBuilder.group({
      firstChildGroup: this.formBuilder.group({idItem: [null, [Validators.required]]}),
      amount: [null, [Validators.required, Validators.min(0)]],
    });
    this.listItemFields.push(newItemField);
    this.setLoneliness();
  }

  addExistingItem(item: ItemStruct) {
    let newItemField: KitItemSelectStruct = new KitItemSelectStruct();
    let preselectedItem: ItemSearchSelectStruct = new ItemSearchSelectStruct();
    preselectedItem.idItem = item.idItem,
    preselectedItem.codeWithName = item.codeWithName;
    newItemField.preSelectedItem = [];
    newItemField.preSelectedItem.push(preselectedItem);

    if(this.listItemFields?.length == 0)
      newItemField.idField = 1;
    else if(this.listItemFields?.length >= 0)
      newItemField.idField = Math.max.apply(Math, this.listItemFields.map(l => l.idField)) + 1;
    
    newItemField.formGroup = this.formBuilder.group({
      firstChildGroup: this.formBuilder.group({idItem: [item.idItem, [Validators.required]]}),
      amount: [item.amount, [Validators.required]],
    });
    this.listItemFields.push(newItemField);
    this.setLoneliness();
  }

  removeItem(event: any) {
    if(this.listItemFields?.length <= 1 ?? true)
      return;

    let index = this.listItemFields?.findIndex(l => l.idField == event);
    if(index > -1) 
      this.listItemFields?.splice(index, 1);
    this.setLoneliness();
  }

  setLoneliness() {
    if(this.listItemFields?.length <= 0 ?? true)
      return;

    if(this.listItemFields.length == 1) 
      this.listItemFields[0].isSingle = true;
    else if(this.listItemFields.length > 1) {
      this.listItemFields.forEach(element => {
        element.isSingle = false;
      });
    }
  }

  changeItems(event: any) {
    this.listItemFields = [];
    if(event.checked)
      this.addItem(null);
  }
}