<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="header-modal">
            <h1>Guia de honorários</h1>
        </div>
        <div class="body-modal">
            <form appFormscroll class="form" [formGroup]="model" id="fees-form">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-3">
                        <app-select-2 *ngIf="listHealthcareAgreement" [formControlField]="'ansCode'"
                            [multiple]="false" [url]="urlListAnsRegistration"
                            [idField]="'idHealthcareAgreement'" [nameField]="'ansWithName'"
                            [labelField]="'1 - Registro ANS'"
                            [preSelectedBlock]="this.idFeesGuide != null ? true : false">
                        </app-select-2>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>2 - Nº da guia no prestador</mat-label>
                            <input matInput type="text" formControlName="guideNumber"
                                [maxlength]="feesFieldsMaxLengthEnum.guideNumber"
                                oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                            <mat-error *ngIf="model.get('guideNumber').invalid">Preenchimento incorreto</mat-error>
                            <mat-hint align="end">{{ model.get('guideNumber').value ?
                                this.model.get('guideNumber').value.length : 0
                                }}/{{feesFieldsMaxLengthEnum.guideNumber}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>3 - Guia de Internação</mat-label>
                            <mat-select *ngIf="!manualAdmissionGuide" formControlName="admissionGuide"
                                (selectionChange)="onAdmissionGuideChange($event.value)">
                                <mat-option *ngFor="let item of listAdmissionGuide" [value]="item">
                                    {{ item.guide.guideNumber }}: <b>{{ item.admissionGuide.datetimeSuggestedAdmission |
                                        date:'shortDate'}}</b>
                                    -
                                    <b>{{item.expectedEndDate | date:'shortDate'}}</b>
                                </mat-option>
                            </mat-select>
                            <input *ngIf="manualAdmissionGuide" matInput type="text"
                                [maxlength]="15" formControlName="admissionGuide"
                                oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                            <mat-error *ngIf="model.get('admissionGuide').invalid">Preenchimento
                                incorreto</mat-error>
                            <mat-hint *ngIf="manualAdmissionGuide" align="end">{{ model.get('admissionGuide').value ?
                                model.get('admissionGuide').value.length : 0
                                }}/{{feesFieldsMaxLengthEnum.admissionGuideNumber}}</mat-hint>
                        </mat-form-field>
                        <mat-checkbox [(ngModel)]="manualAdmissionGuide" [ngModelOptions]="{standalone:true}"
                            (change)="onManualAdmissionSelect($event)" [disabled]="true">
                            Inserir manualmente
                        </mat-checkbox>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Status da guia</mat-label>
                            <mat-select formControlName="idGuideStatus">
                                <mat-option *ngFor="let item of listGuideStatus" [value]="item.idGuideStatus">
                                    <span class="color-dot" [style.background-color]="item.color"></span> {{item.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idGuideStatus').invalid">Selecione um status</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>4 - Senha</mat-label>
                            <input matInput type="text" formControlName="ticket"
                                [maxlength]="feesFieldsMaxLengthEnum.ticket">
                            <mat-error *ngIf="model.get('ticket').invalid">Preenchimento incorreto</mat-error>
                            <mat-hint align="end">{{ model.get('ticket').value ?
                                this.model.get('ticket').value.length : 0
                                }}/{{feesFieldsMaxLengthEnum.ticket}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>5 - N° da Guia na Operadora</mat-label>
                            <input matInput type="text" [maxlength]="feesFieldsMaxLengthEnum.providerGuideNumber"
                                formControlName="providerGuideNumber"
                                oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                            <mat-error *ngIf="model.get('providerGuideNumber').invalid">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ model.get('providerGuideNumber').value ?
                                model.get('providerGuideNumber').value.length : 0
                                }}/{{feesFieldsMaxLengthEnum.providerGuideNumber}}</mat-hint>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="title-header">
                        <h3>Dados do Beneficiário</h3>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>6 - N° da carteira</mat-label>
                            <input matInput type="text" [maxlength]="feesFieldsMaxLengthEnum.patientDocumentNumber"
                                formControlName="patientDocumentNumber"
                                oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                            <mat-error *ngIf="model.get('patientDocumentNumber').invalid">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ model.get('patientDocumentNumber').value ?
                                model.get('patientDocumentNumber').value.length : 0
                                }}/{{feesFieldsMaxLengthEnum.patientDocumentNumber}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6">
                        <mat-form-field appearance="outline">
                            <mat-label>7 - Nome do Beneficiário</mat-label>
                            <input matInput type="text" [maxlength]="feesFieldsMaxLengthEnum.patientName"
                                formControlName="patientName">
                            <mat-error *ngIf="model.get('patientName').invalid">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ model.get('patientName').value ?
                                model.get('patientName').value.length : 0
                                }}/{{feesFieldsMaxLengthEnum.patientName}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6">
                        <mat-form-field appearance="outline">
                            <mat-label>41 - Nome Social</mat-label>
                            <input matInput type="text" [maxlength]="feesFieldsMaxLengthEnum.patientSocialName"
                                formControlName="patientSocialName">
                            <mat-error *ngIf="model.get('patientSocialName').invalid">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ model.get('patientSocialName').value ?
                                model.get('patientSocialName').value.length : 0
                                }}/{{feesFieldsMaxLengthEnum.patientSocialName}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>8 - Atendimento a Recém-Nascido</mat-label>
                            <mat-select formControlName="newbornAttendance">
                                <mat-option [value]="true">Sim</mat-option>
                                <mat-option [value]="false">Não</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="title-header">
                        <h3>Dados do Contratado (onde foi executado o procedimento)</h3>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Identificação no prestador</mat-label>
                            <mat-select matInput type="text" formControlName="idexecutionStieProviderIdentityType"
                                (selectionChange)="checkExecutantProviderIdentityType($event.value)">
                                <mat-option *ngFor="let item of listProviderIdentityType"
                                    [value]="item.idProviderIdentityType">{{item.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idProviderIdentityType').invalid">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>9 - Código na Operadora</mat-label>
                            <input matInput type="text" [maxlength]="executantSiteCodeMaxLength"
                                formControlName="executionSiteProviderCode" [mask]="executerCodeMask"
                                (input)="inputNumber('executionSiteProviderCode')">
                            <mat-error *ngIf="model.get('executionSiteProviderCode').invalid">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ model.get('executionSiteProviderCode').value ?
                                model.get('executionSiteProviderCode').value.length : 0
                                }}/{{feesFieldsMaxLengthEnum.requesterCodeOperator}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>10 - Nome do Hospital/Local</mat-label>
                            <input matInput type="text" [maxlength]="feesFieldsMaxLengthEnum.executionSiteName"
                                formControlName="executionSiteName">
                            <mat-error *ngIf="model.get('executionSiteName').invalid">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ model.get('executionSiteName').value ?
                                model.get('executionSiteName').value.length : 0
                                }}/{{feesFieldsMaxLengthEnum.executionSiteName}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>11 - Código CNES</mat-label>
                            <input matInput type="text" [maxlength]="feesFieldsMaxLengthEnum.executionSiteCnesCode"
                                formControlName="executionSiteCnesCode" (input)="inputNumber('executionSiteCnesCode')">
                            <mat-error *ngIf="model.get('executionSiteCnesCode').invalid">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ model.get('executionSiteCnesCode').value ?
                                model.get('executionSiteCnesCode').value.length : 0
                                }}/{{feesFieldsMaxLengthEnum.executionSiteCnesCode}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <div class="title-header">
                            <h3>Dados do Contratado Executante</h3>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Identificação no prestador</mat-label>
                                <mat-select matInput type="text" formControlName="idProviderIdentityType"
                                    (selectionChange)="checkProviderIdentityType($event.value)">
                                    <mat-option *ngFor="let item of listProviderIdentityType"
                                        [value]="item.idProviderIdentityType">{{item.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="model.get('idProviderIdentityType').invalid">Preenchimento
                                    incorreto</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>12 - Código na Operadora</mat-label>
                                <input matInput type="text" [maxlength]="requesterCodeMaxLength"
                                    formControlName="executantProviderCode" [mask]="requesterCodeMask"
                                    (input)="inputNumber('executantProviderCode')">
                                <mat-error *ngIf="model.get('executantProviderCode').invalid">Preenchimento
                                    incorreto</mat-error>
                                <mat-hint align="end">{{ model.get('executantProviderCode').value ?
                                    model.get('executantProviderCode').value.length : 0
                                    }}/{{requesterCodeMaxLength}}</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>13 - Nome do Contratado</mat-label>
                                <input matInput type="text" [maxlength]="feesFieldsMaxLengthEnum.executantName"
                                    formControlName="executantName">
                                <mat-error *ngIf="model.get('executantName').invalid">Preenchimento
                                    incorreto</mat-error>
                                <mat-hint align="end">{{ model.get('executantName').value ?
                                    model.get('executantName').value.length : 0
                                    }}/{{feesFieldsMaxLengthEnum.executantName}}</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>14 - Código CNES</mat-label>
                                <input matInput type="text" [maxlength]="feesFieldsMaxLengthEnum.executantCnesCode"
                                    formControlName="executantCnesCode" (input)="inputNumber('executantCnesCode')">
                                <mat-error *ngIf="model.get('executantCnesCode').invalid">Preenchimento
                                    incorreto</mat-error>
                                <mat-hint align="end">{{ model.get('executantCnesCode').value ?
                                    model.get('executantCnesCode').value.length : 0
                                    }}/{{feesFieldsMaxLengthEnum.executantCnesCode}}</mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="title-header">
                            <h3>Dados da Internação</h3>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>15 - Data do Início do Faturamento</mat-label>
                                <input matInput type="text" [mask]="masks.date" formControlName="billingStartDatetime">
                                <mat-error *ngIf="model.get('billingStartDatetime').invalid">Data inválida</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>16 - Data do Fim do Faturamento</mat-label>
                                <input matInput type="text" [mask]="masks.date" formControlName="billingEndDatetime">
                                <mat-error *ngIf="model.get('billingEndDatetime').invalid">Data inválida</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="title-header">
                            <h3>Procedimentos realizados</h3>
                        </div>
                        <app-fees-procedure-form-history #feesProcedureFormComponent
                            (changeProcedure)="calculateTotalFeesValue()" [idFeesGuide]="idFeesGuide"
                            [disableProcedureEdit]="disableProcedureEdit" [disableProcedureAdd]="disableProcedureAdd"
                            [accessMap]="accessMap" [techniqueMap]="techniqueMap" [isEditingAllowed]="false"
                            [participationDegreeMap]="participationDegreeMap"
                            [healthcareAgreementStruct]="healthcareAgreementStruct"
                            [listProfessionalAgreementStruct]="listProfessionalAgreementStruct"
                            [tussTerminologyGroupMap]="tussTerminologyGroupMap" [listFeesProcedure]="listFeesProcedure"
                            [cboMap]="cboMap" [tissBilling]="tissBilling" [listState]="this.listState"
                            [listProfessionalCouncil]="this.listProfessionalCouncil"
                            [listFeesRequiredField]="this.listFeesRequiredField">
                        </app-fees-procedure-form-history>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>37 - Observação/Justificativa</mat-label>
                            <textarea matInput type="text" [maxlength]="feesFieldsMaxLengthEnum.observations"
                                formControlName="observations"></textarea>
                            <mat-error
                                *ngIf="model.get('observations').invalid && !model.get('observations').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ model.get('observations').value ?
                                model.get('observations').value.length : 0
                                }}/{{feesFieldsMaxLengthEnum.observations}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>38 - Valor Total dos Honorários (R$)</mat-label>
                            <input matInput type="text" formControlName="feesTotalValue">
                            <mat-error *ngIf="model.get('feesTotalValue').invalid">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>39 - Data de emissão</mat-label>
                            <input matInput type="text" [mask]="masks.date" formControlName="datetimeIssue">
                            <mat-error *ngIf="model.get('datetimeIssue').invalid">Data inválida</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div mat-dialog-footer class="footer-modal">
                    <button mat-flat-button color="accent" class="btn-block" (click)="close()" type="button">
                        <span *ngIf="!isLoading">Fechar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>