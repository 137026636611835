<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <a class="back-link" routerLink="/medic">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Módulo Médico
        </a>
        <h1>Agenda médica</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <div class="body-calendar">
                <app-calendar-schedule *ngIf="this.viewCalendar" 
                    [minDate]="this.dateTimeStart"
                    [maxDate]="this.dateTimeEnd"
                    [isMedicalScheduleComponent]="true"
                    [events]="this.events"
                    [viewDate]="this.viewDate"
                    [startsWithToday]="true"
                    (clicked)="clicked($event)">
                </app-calendar-schedule>
            </div>
        </div>
    </div>
    
</div>
