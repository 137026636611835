import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { ListTextTemplateResponse } from '../../responses/meta-data/list-text-template.response';
import { TextTemplateResponse } from '../../responses/meta-data/text-template.response';
import { TextTemplateRequest } from '../../requests/meta-data/text-template.request';

@Injectable({
  providedIn: 'root'
})

export class TextTemplateService extends CommonService {
  constructor(private httpClient: HttpClient) {
    super();
  }

  public getBySearchTextTemplate(searchText: string): Observable<ListTextTemplateResponse> {
    let uri = `TextTemplate?`

    if (searchText != null)
      uri = uri + `searchText=${searchText}&`;

    return this.httpClient.get<ListTextTemplateResponse>(environment.urlApiMetaData + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }

  public getByIdTextTemplate(idTextTemplate: number): Observable<TextTemplateResponse> {
    let uri = `TextTemplate/idTextTemplate/${idTextTemplate}`;

    return this.httpClient.get<TextTemplateResponse>(environment.urlApiMetaData + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }

  public getByListIdTextTemplate(listIdTextTemplate: number[] = []): Observable<ListTextTemplateResponse> {
    let uri = `TextTemplate/getByListId`;

    return this.httpClient.post<ListTextTemplateResponse>(environment.urlApiMetaData + uri, listIdTextTemplate,this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }

  public createTextTemplate(body: TextTemplateRequest): Observable<ReturnStruct> {
    let uri = `TextTemplate`

    return this.httpClient.post<ReturnStruct>(environment.urlApiMetaData + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }

  public updateTextTemplate(idTextTemplate: number, body: TextTemplateRequest): Observable<ReturnStruct> {
    let uri = `TextTemplate/idTextTemplate/${idTextTemplate}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiMetaData + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }

  public deleteTextTemplate(idTextTemplate: number): Observable<ReturnStruct> {
    let uri = `TextTemplate/idTextTemplate/${idTextTemplate}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiMetaData + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }
}