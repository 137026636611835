<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="title-header">
            <h1>Visualizar registro</h1>
        </div>
        <div class="body-modal">
            <mat-form-field appearance="outline">
                <textarea [attr.rows]="this.numberOfLinesInTextPatientExitLog" matInput
                    readonly>{{this.data.changelog.updateLogAudit}}</textarea>
            </mat-form-field>
        </div>

        <div class="btn-container">
            <button mat-flat-button color="accent" class="button-close-modal-other" (click)="back()">Voltar</button>
        </div>
    </div>
</div>