import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-nps-logo-modal',
  templateUrl: './nps-logo-modal.component.html',
  styleUrls: ['./nps-logo-modal.component.css']
})
export class NpsLogoModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<NpsLogoModalComponent>,
    public sanitizer: DomSanitizer,
    private formBuilder: FormBuilder
  ) { }

  public oldSrc: any;
  public newSrc: any;

  public uploadedLogo: string;

  public haveLogo: boolean;

  public isLoading: boolean;
  public model: FormGroup;

  ngOnInit(): void {
    this.isLoading = true;

    this.model = this.formBuilder.group({
      requiredfile: ['']
    });

    if (this.data.logo) {
      this.oldSrc = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${this.data.logo}`);
      this.haveLogo = true;
    }
    if (this.data.newLogo) {
      this.newSrc = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${this.data.newLogo}`);
      this.haveLogo = true;
    }

    this.isLoading = false;
  }

  inputChange(event: any) {
    const file: File = event;
    const reader = new FileReader();

    reader.onload = this._handleReaderLoaded.bind(this);
    if (file)
      reader.readAsDataURL(file);
  }

  _handleReaderLoaded(e) {
    let reader = e.target;
    var testSrc = reader.result;
    var stringArray = testSrc.split("base64,", 2);
    this.isLoading = false;
    this.newSrc = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${stringArray[1]}`);
    this.uploadedLogo = stringArray[1];
    this.haveLogo = true;
  }

  clickApply() {
    this.matDialogRef.close({
        newLogo: this.uploadedLogo,
        haveLogo: this.haveLogo
      });
  }

  clickCancel() {
    this.matDialogRef.close({
        haveLogo: this.haveLogo,
      });
  }
}