<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <a class="back-link" routerLink="/digital-prompt-service">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Módulo PA Digital
        </a>
        <h1>Cadastrar agenda</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="title-header">
                    <h1>Configuração da agenda</h1>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Descrição</mat-label>
                            <input matInput type="text" formControlName="description">
                            <mat-error *ngIf="model.get('description').invalid">Informe a descrição</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Usuário</mat-label>
                            <mat-select (selectionChange)="validateUser()" formControlName="idUser">
                                <mat-option *ngFor="let item of listUser"
                                    [value]="item.idUser">{{item.userName}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idUser').invalid">Informe o usuário</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Tempo de atendimento</mat-label>
                            <input matInput (keyup)="validateTimeInputs()" type="number"
                                formControlName="appointmentTime">
                            <span matTextSuffix>min</span>
                            <mat-error *ngIf="!model.get('appointmentTime').value">Informe o tempo de
                                atendimento</mat-error>
                            <mat-error *ngIf="model.get('appointmentTime').value && model.get('appointmentTime').value < 10">O tempo de duração não pode ser menor que 10 min.</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline"
                            matTooltip="{{model.get('idUser').value ? '' : 'Selecione um usuário'}}">
                            <mat-label>Intervalo de tempo</mat-label>
                            <mat-date-range-input [disabled]="inEffect" [min]="minDate" [rangePicker]="rangePicker">
                                <input (dateChange)="verifyDateRange()" matStartDate formControlName="startDate"
                                    placeholder="Início">
                                <input disabled="false" (dateChange)="verifyDateRange()" matEndDate
                                    formControlName="expirationDate" placeholder="Fim">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
                            <mat-date-range-picker #rangePicker></mat-date-range-picker>
                            <mat-error *ngIf="model.get('startDate').errors?.invalidDateRange">Intervalo
                                inválido</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="title-header">
                    <h1>Configuração de horários</h1>
                </div>
                <p>Defina o intervalo de horários disponíveis na sua agenda, por dia da semana. O intervalo selecionado
                    irá considerar o tempo de atendimento predefinido na configuração da agenda.
                </p>
                <div class="btn-visualization">
                    <button mat-flat-button type="button" color="primary" class="button-preview"
                        (click)="openPreview()">
                        <span *ngIf="isLoading == false">PRÉ-VISUALIZAR AGENDA</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
                <div formArrayName="listWeekDay"
                    *ngFor="let item of model.get('listWeekDay')['controls']; let i = index">
                    <div class="row" [formGroupName]="i">
                        <div class="col-2 col-sm-2 col-md-2" *ngIf="listDay && listDay.length > 0">
                            <mat-form-field appearance="outline">
                                <mat-label>Dias</mat-label>
                                <input readonly matInput formControlName="dayName">
                            </mat-form-field>
                        </div>
                        <div class="col-10 col-sm-10 col-md-10">
                            <div formArrayName="listEvent"
                                *ngFor="let event of item.get('listEvent')['controls']; let j = index"
                                [disabled]="!model.get('idUser').value">
                                <div class="row" [formGroupName]="j">
                                    <div class="col-12 col-sm-6 col-md-2">
                                        <mat-form-field appearance="outline"
                                            matTooltip="{{model.get('appointmentTime').value ? '' : 'Selecione um tempo de atendimento'}}">
                                            <mat-label>Início</mat-label>
                                            <input type="text" minlength="5" maxlength="5" [mask]="masks.timeUnit.mask"
                                                pattern="masks.timeUnit.pattern" placeholder="00:00" aria-label="Number"
                                                matInput formControlName="startTime">
                                            <mat-error
                                                *ngIf="event.controls['startTime'].errors?.intersection">Intervalo
                                                sobreposto</mat-error>
                                            <mat-error
                                                *ngIf="event.controls['startTime'].errors?.inadequateRange">Intervalo
                                                incompatível</mat-error>
                                            <mat-error *ngIf="event.controls['startTime'].errors?.missing">Informe o
                                                início
                                            </mat-error>
                                            <mat-error
                                                *ngIf="event.controls['startTime'].errors?.invalidRange">Intervalo
                                                inválido
                                            </mat-error>
                                            <mat-error *ngIf="event.controls['startTime'].errors?.invalidTime">Formato
                                                inválido</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-2">
                                        <mat-form-field appearance="outline"
                                            matTooltip="{{model.get('appointmentTime').value ? '' : 'Selecione um tempo de atendimento'}}">
                                            <mat-label>Fim</mat-label>
                                            <input type="text" minlength="5" maxlength="5" [mask]="masks.time"
                                                placeholder="00:00" aria-label="Number" matInput
                                                formControlName="endTime">
                                            <mat-error *ngIf="event.controls['endTime'].errors?.intersection">Intervalo
                                                sobreposto</mat-error>
                                            <mat-error *ngIf="event.controls['endTime'].errors?.missing">Informe o
                                                fim
                                            </mat-error>
                                            <mat-error
                                                *ngIf="event.controls['endTime'].errors?.inadequateRange">Intervalo
                                                incompatível</mat-error>
                                            <mat-error *ngIf="event.controls['endTime'].errors?.invalidRange">Intervalo
                                                inválido
                                            </mat-error>
                                            <mat-error *ngIf="event.controls['endTime'].errors?.invalidTime">Formato
                                                inválido</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-2">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Especialidade</mat-label>
                                            <mat-select formControlName="idSpecialty">
                                                <mat-option [value]="item.medicalSpecialty.idMedicalSpecialty"
                                                    *ngFor="let item of listMedicalSpecialty">{{item.medicalSpecialty.medicalSpecialtyName}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-4 col-sm-2 col-md-2">
                                        <button class="totem-button " color="primary" mat-mini-fab type="button"
                                            (click)="addNext(i)">
                                            <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
                                        </button>
                                        <button class="totem-button " color="primary" mat-mini-fab type="button"
                                            [disabled]="inEffect && event.get('idScheduleDay').value"
                                            (click)="removeButton(i, j)">
                                            <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="title-header">
                    <h1>Configuração de bloqueios</h1>
                </div>
                <div class="card-list">
                    <div class="col-4 col-sm-2 col-md-2">
                        <button class="totem-button " color="primary" mat-mini-fab type="button"
                            (click)="openBlockedDaysModal()">
                            <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
                        </button>
                    </div>
                    <div class="row row-list">
                        <div class="col-12 col-sm-6 col-md-4" formArrayName="listBlockedDays"
                            *ngFor="let item of model.get('listBlockedDays')['controls']; let k = index">
                            <div class="card-item">
                                <div class="infos">
                                    <span class="primary">
                                        {{item.get('dayName').value}}
                                    </span>
                                    <span class="secundary">Início:
                                        {{item.get('startTime').value.slice(0,2)}}:{{item.get('startTime').value.slice(2,5)}}</span>
                                    <span class="secundary">Fim:
                                        {{item.get('endTime').value.slice(0,2)}}:{{item.get('endTime').value.slice(2,5)}}</span>
                                    <span *ngIf="item.get('repetition').value" class="secundary">Repetições:
                                        {{item.get('repetitionNumber').value}}</span>
                                    <span *ngIf="item.get('repetition').value" class="secundary">Frequência:
                                        {{item.get('frequencyName').value}}</span>
                                    <span class="secundary" matTooltip="{{item.get('motive').value}}">Motivo: {{item.get('motive').value}}</span>
                                </div>
                                <div class="actions">
                                    <a>
                                        <mat-icon aria-hidden="false" aria-label="Editar"
                                            (click)="openBlockedDaysModal(k)">edit</mat-icon>
                                    </a>
                                    <a>
                                        <mat-icon aria-hidden="false" aria-label="Excluir"
                                            (click)="openDeleteBlockedDay(k)">delete</mat-icon>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <button mat-flat-button type="submit" color="primary" class="btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <a mat-flat-button type="button" routerLink="/digital-prompt-service/patient-center/schedule/list" color="accent" class=" btn-block">
                            Cancelar
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>