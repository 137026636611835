<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-confirm-modal-list">
            <h4>Selecione o Leito que o paciente irá ocupar</h4>
        </div>
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="body-confirm-modal-list">
                <mat-form-field appearance="outline">
                    <mat-label>Leito</mat-label>
                    <mat-select #bed formControlName="idBed" (selectionChange)="checkHygienization($event)">
                        <mat-select-trigger *ngIf="selectedBed && hygienization">
                            {{selectedBed.bedName}}
                            <div class="bullet" [style.background-color]="'#045bbf'"
                                *ngIf="selectedBed.idHygienizationStatus == hygienizedStatus && hygienization"
                                matTooltip="Higienizado">
                            </div>
                            <div class="bullet" [style.background-color]="'#db1f67'"
                                *ngIf="selectedBed.idHygienizationStatus == unhygienizedStatus && hygienization"
                                matTooltip="Não higienizado">
                            </div>
                            <div class="bullet" [style.background-color]="'#e89323'"
                                *ngIf="selectedBed.idHygienizationStatus == hygienizingStatus && hygienization"
                                matTooltip="Em higienização">
                            </div>
                        </mat-select-trigger>
                        <mat-option [style.display]="'block'" *ngFor="let item of listFreeBed" [value]="item.idBed">
                            <span>
                                {{item.bedName}}
                            </span>
                            <div class="bullet" [style.background-color]="'#045bbf'"
                                *ngIf="item.idHygienizationStatus == hygienizedStatus && hygienization"
                                matTooltip="Higienizado">
                            </div>
                            <div class="bullet" [style.background-color]="'#db1f67'"
                                *ngIf="item.idHygienizationStatus == unhygienizedStatus && hygienization"
                                matTooltip="Não higienizado">
                            </div>
                            <div class="bullet" [style.background-color]="'#e89323'"
                                *ngIf="item.idHygienizationStatus == hygienizingStatus && hygienization"
                                matTooltip="Em higienização">
                            </div>
                        </mat-option>
                    </mat-select>
                    <!-- <mat-error *ngIf="model.get('idBed').invalid">Informe o leito</mat-error> -->
                </mat-form-field>
                <mat-slide-toggle formControlName="unhygienizedAllocation" *ngIf="unhygienizedAllocationConfirm || oldStatusHygienization != null" [checked]="unhygienizedAllocationConfirm" (change)="toggleConfirmation($event)">
                    <span>
                        Eu confirmo que o leito foi higienizado antes da alocação
                    </span>
                </mat-slide-toggle>
            </div>
            <div class="footer-confirm-modal-list">
                <button mat-flat-button color="primary" class="btn-block" type="submit" [disabled]="!model.valid">
                    <span *ngIf="isLoading == false">Atender</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
                <button type="button" mat-flat-button color="accent" class="btn-block"
                    (click)="clickCancel()">Cancelar</button>
            </div>
        </form>
    </div>
</div>