export class ProcedureRequest
{
    public procedureName: string;

    public procedureCode: string;

    public description: string;

    public listCbos: number[];

    public isActive: boolean;
    
    public idProcedureSigtap: number;
}