import { KeyValue } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DigitalSignatureFlowEnum } from 'src/app/shared/enum/digital-signature-flow.enum';
import { SignatureProviderEnum } from 'src/app/shared/enum/signature-providers/signature-providers.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { AlertModalComponent } from '../../../alert-modal/alert-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { DigitalSignatureService } from 'src/app/shared/services/API/digital-signature/digital-signature.service';
import { GetUserService } from 'src/app/shared/services/API/user/get-user.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DigitalSignatureUtilService } from 'src/app/shared/services/digital-signature-util.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { WebsocketDigitalSignatureService } from 'src/app/shared/services/websocket-digital-signature.service';
import { FormMetaData } from 'src/app/shared/structs/form-meta-data.struct';
import { environment } from 'src/environments/environment';
import { SafeIDVidaaSSignatureStruct } from 'src/app/shared/services/structs/digital-signature/safe-id/safe-id-vidaas-signature-struct.struct';
import { VidaaSSignatureService } from 'src/app/shared/services/API/digital-signature/vidaas.service';
import { VidaaSClientRequest } from 'src/app/shared/services/requests/digital-signature/vidaas/vidaas-client.request';
import { VidaaSCellPhoneNotificationRequest } from 'src/app/shared/services/requests/digital-signature/vidaas/vidaas-cell-phone-notification.request';
import { VidaaSCredentialsRequest } from 'src/app/shared/services/requests/digital-signature/vidaas/vidaas-credentials.request';
import { VidaaSCredentialsResponse } from 'src/app/shared/services/responses/digital-signature/safe-id/vidaas-credentials.response';
import { ProfessionEnum } from 'src/app/shared/enum/profession.enum';

@Component({
  selector: 'app-vidaas-authenticator',
  templateUrl: './vidaas-authenticator.component.html',
  styleUrls: ['./vidaas-authenticator.component.css']
})
export class VidaaSAuthenticatorComponent implements OnInit, OnDestroy {
  constructor(
    private formBuilder: FormBuilder,
    private vidaaSSignatureService: VidaaSSignatureService,
    public dialog: MatDialog,
    private digiatlSignatureService: DigitalSignatureService,
    private getUserService: GetUserService,
    private utilService: UtilService,
    private signatureUtilService: DigitalSignatureUtilService,
    private alertService: AlertService,
    private websocketDigitalSignatureService: WebsocketDigitalSignatureService,
    private authService: AuthService) { }

  public state: number;
  public userCpf: string;
  public professionMedic: number = ProfessionEnum.Medico;
  public idUserProfession: number = null;
  public model: FormGroup;
  public stateEnum = DigitalSignatureFlowEnum;
  public formMetaData = new Map<string, FormMetaData>();
  public title: string = 'Cadastro';
  public description: string = '';
  public ready: boolean = false;

  @Input() isLoading: boolean;
  @Input() isMenu: boolean;
  @Output() goBack = new EventEmitter<number>();
  @Output() setStatusIcon = new EventEmitter<string>();
  @Output() credentialsRequest = new EventEmitter<VidaaSCredentialsResponse>();

  private socket: any;

  ngOnInit(): void {
    let signatureStruct = this.signatureUtilService.getSignatureStruct(SignatureProviderEnum.vidaaS);

    this.getUser();

    if (!signatureStruct ||
      !signatureStruct.client_id ||
      !signatureStruct.client_secret) {
      this.state = DigitalSignatureFlowEnum.uninitiated;
    }
    else if (!signatureStruct.authorizationToken)
      this.state = DigitalSignatureFlowEnum.registered;
    else if (!signatureStruct.access_token)
      this.state = DigitalSignatureFlowEnum.authenticated;
    else
      this.state = DigitalSignatureFlowEnum.signable;

    this.startWebsocket();
    this.changeState(this.state);
  }

  ngOnDestroy(): void {
    if (this.socket)
      this.socket.close();
  }

  //Websocket
  startWebsocket() {
    let login = this.authService.getTokenMenu().login
    this.socket = this.websocketDigitalSignatureService.websocketConnection();
    this.socket.emit('join', `login-${login}`)
      .on("clientAppAuth", (res: SafeIDVidaaSSignatureStruct) => this.authenticateApp(res))
      .io.on("reconnect", () => {
        this.socket.emit('join', `login-${login}`);
      });
  }

  back() {
    this.goBack.emit(SignatureProviderEnum.vidaaS);
  }

  async changeState(state: number) {
    this.ready = false;
    this.state = state;
    this.model = this.formBuilder.group({});

    switch (state) {
      case DigitalSignatureFlowEnum.uninitiated:
        this.title = 'Cadastro de aplicação - ToLife';

        this.formMetaData = VidaaSClientRequest.metadata();
        if (this.formMetaData)
          this.buildForm();

        this.setStatusIcon.emit('block');
        break;

      case DigitalSignatureFlowEnum.registered:
        this.title = 'Autenticar aplicação';
        this.description = '';

        this.formMetaData = VidaaSCellPhoneNotificationRequest.metadata();
        if (this.formMetaData)
          this.buildForm();

        this.setForm();
        this.setStatusIcon.emit('app_blocking');
        break;

      case DigitalSignatureFlowEnum.awatingConfirmation:
        this.title = 'Aceitar aplicação confiável';
        this.description = 'Clique na notificação que o aplicativo VidaaS enviou para o seu celular e confirme a aplicação ToLife como confiável. Importante: você precisa clicar na notificação. Caso contrário, não conseguirá visualizar a tela para liberar a ToLife como aplicativo confiável.';

        this.formMetaData = null;
        if (this.formMetaData)
          this.buildForm();

        this.setForm();
        this.setStatusIcon.emit('pending_actions');

        this.pollForConfirmation();
        break;

      case DigitalSignatureFlowEnum.authenticated:
        if (this.isMenu) {
          this.title = 'Aplicação autenticada com sucesso!';

          this.formMetaData = null;
          if (this.formMetaData)
            this.buildForm();
        }
        else {
          this.title = 'Autenticar credenciais';

          this.formMetaData = VidaaSCredentialsRequest.metadata();
          if (this.formMetaData)
            this.buildForm();

          this.getUser();
        }

        this.description = '';
        this.setStatusIcon.emit('mobile_friendly');
        break;

      case DigitalSignatureFlowEnum.signable:
        this.title = 'Tudo pronto!';
        this.description = '';
        this.formMetaData = null;
        this.setStatusIcon.emit('verified');
        break;

      default:
        break;
    }

    this.ready = true;
  }

  getUser() {
    this.isLoading = true;
    this.getUserService.getUser().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.userCpf = response.userInfos.cpf;
        this.idUserProfession = response.userInfos.idProfession;

        if (this.idUserProfession != this.professionMedic) {
          this.alertDynamic('Erro!', ' Você precisa ser um profissional médico para utilizar este certificado. Se você achar que isto é um erro, entre em contato com o Master da sua unidade.', AlertType.error);
          this.back();
          return;
        }

        if (this.model.contains('loginHint'))
          this.setForm();

        this.isLoading = false;
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    });
  }

  authenticateApp(response: SafeIDVidaaSSignatureStruct) {
    this.signatureUtilService.setSignatureStruct(SignatureProviderEnum.vidaaS, response);
    this.changeState(DigitalSignatureFlowEnum.authenticated);
    this.alertDynamic('Sucesso!', 'Aplicação autenticada com sucesso', AlertType.success);
  }

  toggleVisibility(key: string) {
    this.formMetaData[key].hide = !this.formMetaData[key].hide;
  }

  submit() {
    if (this.model.invalid)
      return;
 
    switch (this.state) {
      case DigitalSignatureFlowEnum.uninitiated:
        this.GenerateClientApp();
        break;
      case DigitalSignatureFlowEnum.registered:
        this.CellPhoneNotification();
        break;
      case DigitalSignatureFlowEnum.authenticated:
        this.AuthenticateCredentials();
        break;
    }
  }

  //Requests
  GenerateClientApp() {
    this.isLoading = true;

    let request = new VidaaSClientRequest();
    request = this.readForm(request);

    this.vidaaSSignatureService.createClient(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
          return;
        }

        let signatureStruct = this.signatureUtilService.getSignatureStruct(SignatureProviderEnum.vidaaS);

        if (signatureStruct == null)
          signatureStruct = new SafeIDVidaaSSignatureStruct();

        signatureStruct.client_id = response.client_id;
        signatureStruct.client_secret = response.client_secret;

        this.signatureUtilService.setSignatureStruct(SignatureProviderEnum.vidaaS, signatureStruct);

        this.changeState(DigitalSignatureFlowEnum.registered);
        this.isLoading = false;
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    });
  }

  CellPhoneNotification() {
    this.isLoading = true;

    let request = new VidaaSCellPhoneNotificationRequest();
    request = this.readForm(request);

    this.signatureUtilService.setSelectedProvider(SignatureProviderEnum.vidaaS);
    let signatureStruct: SafeIDVidaaSSignatureStruct = this.signatureUtilService.getSignatureStruct(SignatureProviderEnum.vidaaS);

    let token = this.utilService.getToken();
    request.state = `${token.login};${environment.authorizationHash}`;
    request.clientId = signatureStruct.client_id;

    this.vidaaSSignatureService.cellPhoneNotification(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
          return;
        }

        let signatureStruct = this.signatureUtilService.getSignatureStruct(SignatureProviderEnum.vidaaS);

        if (signatureStruct == null)
          signatureStruct = new SafeIDVidaaSSignatureStruct();

        signatureStruct.client_id = signatureStruct.client_id;
        signatureStruct.client_secret = signatureStruct.client_secret;
        signatureStruct.code = response.code;
        signatureStruct.codeChallenge = response.codeChallenge;

        this.signatureUtilService.setSignatureStruct(SignatureProviderEnum.vidaaS, signatureStruct);

        this.changeState(DigitalSignatureFlowEnum.awatingConfirmation);
        this.isLoading = false;
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    });
  }

  AuthenticateCredentials() {
    this.isLoading = true;

    let request = new VidaaSCredentialsRequest();
    request = this.readForm(request);

    this.signatureUtilService.setSelectedProvider(SignatureProviderEnum.vidaaS);
    let signatureStruct: SafeIDVidaaSSignatureStruct = this.signatureUtilService.getSignatureStruct(SignatureProviderEnum.vidaaS);
    request.client_id = signatureStruct.client_id;
    request.client_secret = signatureStruct.client_secret;
    request.grant_type = "authorization_code";
    request.code_verifier = signatureStruct.codeChallenge;
    request.code = signatureStruct.authorizationToken;

    this.vidaaSSignatureService.authenticateCredentials(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.isLoading = false;
        this.credentialsRequest.emit(response);
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    });
  }

  confirmDeleteSignature() {
    const dialogRef = this.dialog.open(AlertModalComponent, {
      data: {
        title: "Deseja remover seus dados cadastrados?",
        description: "Essa ação é irreversível.",
        isTwoButtonsModal: true
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result && result.confirm)
          this.deleteSignature();
      }
    });
  }

  deleteSignature() {
    this.isLoading = true;
    this.digiatlSignatureService.deleteIntegration(SignatureProviderEnum.vidaaS).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.signatureUtilService.clear();
        this.changeState(DigitalSignatureFlowEnum.uninitiated);
        this.alertDynamic('Sucesso', "Assinatura deletada com sucesso", AlertType.success);
        this.stopPolling();
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    });
  }

  // Order by descending property key
  keyDescOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return a.key > b.key ? -1 : (b.key > a.key ? 1 : 0);
  }

  readForm(request: any): any {
    let rawModel = this.model.getRawValue();
    for (let key of Object.keys(this.model.controls)) {
      if (Object.hasOwn(request, key)) {
        if (Array.isArray(request[key]))
          request[key].push(rawModel[key])
        else
          request[key] = rawModel[key];
      }
    }
    return request;
  }

  buildForm() {
    for (let key of Object.keys(this.formMetaData)) {
      this.model.addControl(key, new FormControl(null, this.formMetaData[key].validators));
      if (this.formMetaData[key].disable)
        this.model.get(key).disable();
    }
  }

  setForm() {
    if (this.model.contains('loginHint') && this.userCpf) {
      this.model.get('loginHint').setValue(this.userCpf);
      this.model.get('loginHint').disable();
    }
    else
      this.getUser();
  }

  private pollInterval: number = 15000; // (15 segundos)
  private maxPollingTime: number = 120000; // (2 minutos)
  private pollingActive: boolean = false;

  private async pollForConfirmation() {
    this.pollingActive = true;
    const startTime = Date.now();

    while (this.pollingActive) {
      const currentTime = Date.now();
      const elapsedTime = currentTime - startTime;

      if (elapsedTime >= this.maxPollingTime) {
        this.alertDynamic('Erro', 'Tempo máximo de 02 minutos atingido. Tente novamente.', AlertType.error);
        this.stopPolling();
        break;
      }

      try {
        await this.checkConfirmationStatus();
        await this.delay(this.pollInterval);
      }
      catch (error) {
        console.error('Erro ao verificar confirmação:', error);
        await this.delay(this.pollInterval);
      }
    }
  }

  private async checkConfirmationStatus() {
    this.isLoading = true;
    let signatureStruct = this.signatureUtilService.getSignatureStruct(SignatureProviderEnum.vidaaS);

    return new Promise<void>((resolve, reject) => {
      this.vidaaSSignatureService.VerifyAuthentication(signatureStruct.code).subscribe({
        next: (response) => {
          this.isLoading = false;
          if (response.isError) {
            this.alertDynamic('Erro', response.errorDescription, AlertType.error);
            reject(response.errorDescription);
          }
          else {
            if (response.authorizationToken)
              this.stopPolling();

            resolve();
          }
        },
        error: (error) => {
          this.isLoading = false;
          this.alertDynamic('Erro inesperado', error, AlertType.error);
          reject(error);
        }
      });
    });
  }

  private delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  private stopPolling() {
    this.pollingActive = false;
    this.back();
  }


  alertDynamic(alertTypeDescription: string, alertDescription: string, alertType: AlertType) {
    if (alertType && alertType.valueOf() == AlertType.error)
      console.log(alertDescription);

    this.alertService.show(alertTypeDescription, alertDescription, alertType ? alertType.valueOf() : AlertType.error);
    this.endLoading();
  }

  endLoading() {
    this.isLoading = false;
  }
}