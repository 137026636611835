<div class="feature-content">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12">
            <mat-label>
                <strong>Evolução do paciente: </strong>
                <p  [innerHTML]="nursingConsultation.patientEvolution"></p>
            </mat-label>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12">                                
            <mat-label>
                <strong>Sinais vitais :</strong>
            </mat-label>                          
        </div>
    </div>
    <div class="row">
        <div class="col-6 col-sm-3 col-md-2">
            <mat-form-field appearance="outline">
                <mat-label>Temperatura</mat-label>
                <input type="number" readonly matInput value="{{nursingConsultation.temperature}}">
                <span matSuffix>ºC</span>
            </mat-form-field>
        </div>
        <div class="col-6 col-sm-3 col-md-2">
            <mat-form-field appearance="outline">
                <mat-label>FR</mat-label>
                <input type="number" readonly matInput value="{{nursingConsultation.respiratoryFrequency}}">
                <span matSuffix>irpm</span>
            </mat-form-field>
        </div>
        <div class="col-6 col-sm-3 col-md-2">
            <mat-form-field appearance="outline">
                <mat-label>FC</mat-label>
                <input type="number" readonly matInput value="{{nursingConsultation.heartRate}}">
                <span matSuffix>bpm</span>
            </mat-form-field>
        </div>
        <div class="col-6 col-sm-3 col-md-2">
            <mat-form-field appearance="outline">
                <mat-label>GC</mat-label>
                <input type="number" readonly matInput value="{{nursingConsultation.glucose}}">
                <span matSuffix>mg/dL</span>
            </mat-form-field>
        </div>
        <div class="col-6 col-sm-3 col-md-2">
            <mat-form-field appearance="outline">
                <mat-label>PA</mat-label>
                <input type="text" readonly matInput value="{{nursingConsultation.bloodPressureSystole}}/{{nursingConsultation.bloodPressureDiastole}}">
                <span matSuffix>mmHG</span>
            </mat-form-field>
        </div>
        <div class="col-6 col-sm-3 col-md-2">
            <mat-form-field appearance="outline">
                <mat-label>SatO2</mat-label>
                <input type="number" readonly matInput value="{{nursingConsultation.saturation}}">
                <span matSuffix>%</span>
            </mat-form-field>
        </div>
    </div>
    <div class="row" *ngIf="this.nursingConsultation.evolutionReportName">
        <div class="col-12 col-sm-6 col-md-2">
            <button mat-flat-button color="primary" class=" btn-block" (click)="downloadEvolutionReport()">
                <span *ngIf="!isLoading">Relatório de Evolução</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
            </button>
        </div>
    </div>
    <div class="sub-section" *ngIf="this.nursingConsultation.medicalPrescription != null && this.nursingConsultation.medicalPrescription.length > 0">
        <h3>Medicamentos</h3>
    </div>
    <div class="card-list" *ngIf="this.nursingConsultation.medicalPrescription != null && this.nursingConsultation.medicalPrescription.length > 0">
        <div class="row row-list">
            <ng-container *ngFor="let item of this.nursingConsultation.medicalPrescription; let index = index;">
                <div class="col-12 col-sm-6 col-md-4">
                    <div class="card-item">
                        <div class="infos">
                            <div class="card-header">
                                <span class="primary">{{item.medicineName}}</span>
                                <span *ngIf="item.idPrescriptionStatus == nonDispensedStatus" class="status">Não
                                    dispensada</span>
                            </div>
                            <span class="secundary">Quantidade: {{item.quantity}}</span>
                            <span class="secundary">Uso: {{item.usage}}</span>
                            <span class="secundary">Via: {{item.administrationTypeName}}</span>
                            <span class="secundary">Apresentação: {{item.presentationName}}</span>
                            <span class="secundary">Frequência:{{item.frequencyName}} {{item.frequency}}</span>
                            <span class="secundary">Periodo trat. :{{item.treatmentPeriodName}} {{item.treatmentPeriod}}</span>
                            <span class="secundary">Diluição: {{item.dilution? item.dilution : item.dilutionName}}</span>
                            <span *ngIf="item.idPrescriptionStatus == nonDispensedStatus" class="secundary">Motivo: {{item.nonDispensationReason}}</span>
                            <span class="secundary-status">{{item.prescriptionCheckStatus}}</span>
                            <span *ngIf="item.observation" class="secundary">Observação: {{item.observation}}</span>
                            <span *ngIf="item.tempCheck" class="checked-tag">
                                <mat-icon class="material-symbols-outlined check-icon">done_all</mat-icon>
                                Checado
                            </span>
                        </div>
                        <div class="actions">
                            <a>
                                <mat-icon aria-hidden="false" aria-label="Editar"
                                    (click)="openPrescriptionModal(item, false)">visibility
                                </mat-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    
    <div class="sub-section"
        *ngIf="this.nursingConsultation.medicatedSolutionPrescription != null && this.nursingConsultation.medicatedSolutionPrescription.length > 0">
        <h3>Solução medicamentosa</h3>
    </div>
    <div class="card-list"
        *ngIf="this.nursingConsultation.medicatedSolutionPrescription != null && this.nursingConsultation.medicatedSolutionPrescription.length > 0">
        <div class="row row-list">
            <ng-container *ngFor="let item of this.nursingConsultation.medicatedSolutionPrescription; let index = index;">
                <div class="col-12 col-sm-6">
                    <div class="card-item">
                        <div class="infos">
                            <ng-container *ngFor="let medicine of item.listMedicine; let medicineIndex = index;">
                                <div class="card-header">
                                    <span class="col-6 primary">{{medicine.medicineName}}</span>
                                    <span class="col-3 secundary"><b class="bold">Qtde.:</b> {{medicine.quantity}}</span>
                                    <span class="col-3 secundary"><b class="bold">Unidade:</b>
                                        {{medicine.measurementUnitName}}</span>
                                    
                                </div>
                            </ng-container>
                            <div class="card-content">
                                <div class="col-6">
                                    <span class="secundary"><b class="bold">Apresentação:</b>
                                        {{item.presentationName}}</span>
                                    <span class="secundary"><b class="bold">Via:</b> {{item.administrationTypeName}}</span>
                                    <span class="secundary"><b class="bold">Periodo trat.:</b> {{item.treatmentPeriodName}}
                                        {{item.treatmentPeriod}}</span>
                                </div>
                                <div class="col-6">
                                    <span class="secundary"><b class="bold">Uso:</b> {{item.usage}}</span>
                                    <span class="secundary"><b class="bold">Frequência:</b> {{item.frequencyName}}
                                        {{item.frequency}}</span>
                                    <span class="secundary"><b class="bold">Diluição:</b> {{item.dilution? item.dilution :
                                        item.dilutionName}}</span>
                                    <span class="secundary-status">{{item.prescriptionCheckStatus}}</span>
                                    
                                </div>
                            </div>
                            <span *ngIf="item.tempCheck" class="checked-tag">
                                <mat-icon class="material-symbols-outlined check-icon">done_all</mat-icon>
                                Checado
                            </span>
                        </div>
                        <div class="actions">
                            <a>
                                <mat-icon aria-hidden="false" aria-label="Editar"
                                    (click)="openPrescriptionModal(item, true)">visibility
                                </mat-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="card-list" *ngIf="nursingConsultation.medicalProcedures != null && nursingConsultation.medicalProcedures.length > 0">
        <div class="title-header">
            <h3>Procedimentos Realizados</h3>
        </div>
        <div class="row">
            <div class="col-12 col-sm-4 col-md-4" *ngFor="let item of nursingConsultation.medicalProcedures">
                <div class="card-item">
                    <div class="infos">
                        <span class="primary">{{item.procedureName}}</span>
                        <span class="secundary"><b>Solicitante: </b>{{item.userName}}</span>
                        <span class="secundary">Observação: {{item.observation}}</span>
                        <span class="secundary">{{item.dateTimeInclusion | date:'dd/MM/yy H:mm'}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>      
</div>
