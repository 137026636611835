import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { TagStruct } from 'src/app/shared/components/tag/tag.struct';
import { DashboardDeleteModalComponent } from './dashboard-delete-modal/dashboard-delete-modal.component';
import { DashboardStruct } from 'src/app/shared/services/structs/data-intelligence/dashboard.struct';
import { DashboardTagService } from 'src/app/shared/services/API/data-intelligente/dashboard-tag.service';
import { DashboardService } from 'src/app/shared/services/API/data-intelligente/dashboard.service';
import { UserGetNameService } from 'src/app/shared/services/API/user/user-get-name.service';
import { FavoritesService } from 'src/app/shared/services/API/data-intelligente/favorite.service';


@Component({
  selector: 'app-dashboard-list',
  templateUrl: './dashboard-list.component.html',
  styleUrls: ['./dashboard-list.component.css']
})
export class DashboardListComponent implements OnInit {

  constructor(private dashboardService: DashboardService,
    private router: Router,
    private alertService: AlertService,
    private dashboardTagService: DashboardTagService,
    private userGetNameService: UserGetNameService,
    private favoritesService: FavoritesService,
    public dialog: MatDialog) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.dashboard;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.dashboard_dashboards;

  public listDashboard: DashboardStruct[];
  public isLoading: boolean;
  public searchText: string;
  public listIdTags: number[];
  public listAllTags: TagStruct[];

  ngOnInit(): void {
    this.isLoading = true;
    this.populateTags()
    this.search();
  }

  search() {
    this.isLoading = true;
    if (this.searchText == "")
      this.searchText = null;

    this.dashboardService.getAll(this.searchText, JSON.stringify(this.listIdTags)).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      if (response.dashboards) {
        this.userGetNameService.listUser(response.dashboards.map(c => c.idUser)).subscribe((userResponse) => {
          response.dashboards.forEach(c => {
            c.tagConcat = c.tags.map(a => a.tagName).join(", ");
            let user = userResponse.listUser.find(x => x.idUser == c.idUser);
            c.userName = user.userName;
          });
          this.listDashboard = response.dashboards;
          this.isLoading = false;
        });
      } else {
        this.isLoading = false;
      }
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  populateTags() {
    this.dashboardTagService.listTags().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.listAllTags = response.listTag;
    },
      (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }

  buttonFavorite(idDashboard: number, isFavorite: boolean) {
    if (this.isLoading)
      return;

    this.isLoading = true;
    if (!isFavorite) {
      this.favorite(idDashboard);
    } else {
      this.unfavorite(idDashboard);
    }
  }

  favorite(idDashboard: number) {
    this.favoritesService.favorite(idDashboard).subscribe((response) => {
      if (response.isError) {
        this.isLoading = false;
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.alertService.show('Sucesso',"Favoritos salvos com sucesso!" , AlertType.success);
      this.listDashboard.forEach(c => {
        if (idDashboard == c.idDashboard) {
          c.isFavorite = true;
        }
      })
      this.listDashboard = [...this.listDashboard];
      this.isLoading = false;
    });
  }

  unfavorite(idDashboard: number) {
    this.favoritesService.unfavorite(idDashboard).subscribe((response) => {
      if (response.isError) {
        this.isLoading = false;
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.alertService.show('Sucesso',"Favoritos salvos com sucesso!" , AlertType.success);
      this.listDashboard.forEach(c => {
        if (idDashboard == c.idDashboard) {
          c.isFavorite = false;
        }
      })
      this.listDashboard = [...this.listDashboard];
      this.isLoading = false;
    });
  }

  openModal(idDashboard) {
    const dialogRef = this.dialog.open(DashboardDeleteModalComponent, {
      data: {
        idDashboard: idDashboard
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.deleteDashboard) {
        this.search();
      }
    });
  }
}
