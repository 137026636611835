import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { MedicineRequest } from '../../requests/pharmacy/medicine.request';
import { GetMedicineResponse } from '../../responses/pharmacy/get-medicine.response';
import { ListMedicineResponse } from '../../responses/pharmacy/list-medicine.response';
import { GetListMedicineToSelectResponse } from '../../responses/pharmacy/get-list-medicine-to-select.response';

@Injectable({
  providedIn: 'root'
})
export class MedicineService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getMedicine(idMedicine: number): Observable<GetMedicineResponse> {
    let uri = `Medicine/idMedicine/${idMedicine}`;

    return this.httpClient.get<GetMedicineResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public listAllMedicine(hasStorage:boolean = false): Observable<ListMedicineResponse> {

    let uri = 'Medicine/getAll?'

    if(hasStorage) uri += `hasStorage=${hasStorage}`;

    return this.httpClient.get<ListMedicineResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public listMedicine(searchText: string, listIdType: number[], listIdGroup: number[], listIdSubgroup: number[], isActive?: boolean): Observable<ListMedicineResponse> {

    let uri = 'Medicine?'

    if (searchText)
      uri = uri + `searchText=${searchText}&`;
    if (listIdSubgroup)
      uri = uri + `jsonListIdSubgroup=${JSON.stringify(listIdSubgroup)}&`;
    if (listIdGroup)
      uri = uri + `jsonListIdGroup=${JSON.stringify(listIdGroup)}&`;
    if (listIdType)
      uri = uri + `jsonListIdType=${JSON.stringify(listIdType)}&`;
    if (isActive)
      uri = uri + `isActive=${isActive}&`;

    return this.httpClient.get<ListMedicineResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public listAllForKit(searchText: string): Observable<GetListMedicineToSelectResponse> {

    let uri = 'Medicine/getAllForKit?'

    if (searchText)
      uri = uri + `searchText=${searchText}`;

    return this.httpClient.get<GetListMedicineToSelectResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public createMedicine(body: MedicineRequest): Observable<ReturnStruct> {

    let uri = `Medicine`

    return this.httpClient.post<ReturnStruct>(environment.urlApiPharmacy + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public updateMedicine(idMedicine: number, body: MedicineRequest): Observable<ReturnStruct> {

    let uri = `Medicine/idMedicine/${idMedicine}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiPharmacy + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
  public deleteMedicine(idMedicine: number): Observable<ReturnStruct> {

    let uri = `Medicine/idMedicine/${idMedicine}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiPharmacy + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}