import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PatientRequest } from 'src/app/shared/services/requests/medical-record/patient.request';

@Component({
  selector: 'app-patient-details-modal',
  templateUrl: './patient-details-modal.component.html',
  styleUrls: ['./patient-details-modal.component.css']
})
export class PatientDetailsModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<PatientDetailsModalComponent>,
    private formBuilder: UntypedFormBuilder,
    private datepipe: DatePipe) { }

  public isLoading: boolean;
  public model: UntypedFormGroup;
  // public patientDetails: PatientRequest;

  ngOnInit(): void {
    this.isLoading = false;
    this.model = this.formBuilder.group({
      patientName: [''],
      birthDate: [''],
      age: [''],
      genderName: [''],
      cpf: [''],
      cns: [''],
      phone1: [''],
      phone2: [''],
      zipCode: [''],
      street: [''],
      houseNumber: [''],
      apartmentNumber: [''],
      neighborhood: [''],
      city: [''],
      state: [''],
      country: ['']
    });
    this.populatePatientDetails();

  }

  populatePatientDetails() {
    this.model.get('patientName').setValue(this.data.patientDetails.patientName);
    this.model.get('cpf').setValue(this.data.patientDetails.cpf);
    this.model.get('birthDate').setValue(this.datepipe.transform(this.data.patientDetails.birthDate, 'dd/MM/yyyy'));
    this.model.get('age').setValue(this.getAge(this.data.patientDetails.birthDate));
    this.model.get('genderName').setValue(this.data.patientDetails.genderName);
    this.model.get('cns').setValue(this.data.patientDetails.cns);
    this.model.get('phone1').setValue(this.data.patientDetails.phoneConfirmation);
    this.model.get('phone2').setValue(this.data.patientDetails.phone2);
    this.model.get('zipCode').setValue(this.data.patientDetails.zipCode);
    this.model.get('street').setValue(this.data.patientDetails.street);
    this.model.get('houseNumber').setValue(this.data.patientDetails.houseNumber);
    this.model.get('apartmentNumber').setValue(this.data.patientDetails.apartmentNumber);
    this.model.get('neighborhood').setValue(this.data.patientDetails.neighborhood);
    this.model.get('city').setValue(this.data.patientDetails.city);
    this.model.get('state').setValue(this.data.patientDetails.state);
    this.model.get('country').setValue(this.data.patientDetails.country);
  }

  close() {
    this.matDialogRef.close();
  }

  getAge(dateString): string {
    var daysInMonth = 30.436875; // Days in a month on average.
    var dob = new Date(dateString);
    var aad;
    aad = new Date();
    var yearAad = aad.getFullYear();
    var yearDob = dob.getFullYear();
    var years = yearAad - yearDob; // Get age in years.
    dob.setFullYear(yearAad); // Set birthday for this year.
    var aadMillis = aad.getTime();
    var dobMillis = dob.getTime();
    if (aadMillis < dobMillis) {
      --years;
      dob.setFullYear(yearAad - 1); // Set to previous year's birthday
      dobMillis = dob.getTime();
    }
    var days = (aadMillis - dobMillis) / 86400000;
    var monthsDec = days / daysInMonth; // Months with remainder.
    var months = Math.floor(monthsDec); // Remove fraction from month.
    days = Math.floor(daysInMonth * (monthsDec - months));
    return `${years}a ${months}m ${days}d`;
  }
}
