import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MedicalCareRequest } from 'src/app/shared/services/requests/medical-record/medical-care.request';
import { GetCompleteMultiProfessionalConfigStruct } from 'src/app/shared/services/structs/multi-professional/get-complete-multi-professional-config.struct';

@Component({
  selector: 'app-sick-note-block',
  templateUrl: './sick-note-block.component.html',
  styleUrls: ['./sick-note-block.component.css']
})
export class SickNoteBlockComponent implements OnInit {

  @Input() model: UntypedFormGroup;
  @Input() completeMultiprofessionalConfig: GetCompleteMultiProfessionalConfigStruct;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public router: Router,
  ) { }

  ngOnInit(): void {
  }

}