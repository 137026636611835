import { ReturnStruct } from "src/app/shared/structs/return.struct";
import { ClassificationAuditResultStruct } from "../../structs/orchestrator-audit/classification-audit-result.struct";
import { GravityAmountWaitTimeStruct } from "../../structs/orchestrator-audit/gravity-amount-wait-time.struct";
import { GravityAmountStruct } from "../../structs/orchestrator-audit/gravity-amount.struct";
import { IncorrectTriageEpisodeStruct } from "../../structs/orchestrator-audit/incorrect-triage-episode.struct";

export class AuditMonthlyReportRequest extends ReturnStruct {
    public listGravityAmount: GravityAmountStruct[];
    public listGravityEpisodeAmount: GravityAmountStruct[];
    public listGravityWaitTime: GravityAmountWaitTimeStruct[];
    public amountOfAdmissions: number;
    public avarageWaitTimeUntilClassification: number;
    public avarageClassificationDuration: number;
    public userClassificatorsAmount: number;
    public userDoctorAmount: number;
    public userOthersAmount: number;
    public amountOfAuditedClassifications: number;
    public amountOfAuditedClassificationsPercent: number;
    public healthUnitName: string;
    public datetimeBeginPeriod: Date;
    public datetimeEndPeriod: Date;
    public street: string
    public houseNumber: number;
    public addressSupplement: string
    public neighborhood: string
    public zipCode: string
    public cnes: string
    public country: string
    public healthUnitCityName: string
    public healthUnitStateName: string
    public datetimeInclusion: Date;
    public listUserAudit: string[];
    public listClassificationAuditResultStruct: ClassificationAuditResultStruct[];
}