<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-flow-modal-list">
            <h2>Montar os gráficos?</h2>
        </div>
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="body-flow-modal-list">
                <div class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome</mat-label>
                            <input matInput type="text" formControlName="graphName">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Fonte dos dados</mat-label>
                            <mat-select formControlName="idDataSource"
                                (selectionChange)="datasourceSelection($event.value, false)">
                                <mat-option *ngFor="let item of listDataSource" [value]="item.idDataSource">
                                    {{item.dataSourceName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Tipo do Gráfico</mat-label>
                            <mat-select (selectionChange)="graphTypeConfig($event.value, false)"
                                formControlName="idGraphType">
                                <mat-option *ngFor="let item of listGraphType" [value]="item.idGraphType">
                                    {{item.graphTypeName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Largura</mat-label>
                            <mat-select formControlName="idGraphWidth">
                                <mat-option *ngFor="let item of listGraphWidth" [value]="item.idGraphWidth">
                                    {{item.graphWidthName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Altura</mat-label>
                            <input matInput type="text" formControlName="height">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Eixo X</mat-label>
                            <mat-select formControlName="idDataSourceVariableX" (selectionChange)="classificationType()"
                                *ngIf=" isAxisXMultiple" multiple>
                                <mat-option *ngFor="let item of listDataSourceVariableX"
                                    [value]="item.idDataSourceVariable">
                                    {{item.dataSourceVariableName}}
                                </mat-option>
                            </mat-select>
                            <mat-select formControlName="idDataSourceVariableX" *ngIf="!isAxisXMultiple">
                                <mat-option *ngFor="let item of listDataSourceVariableX"
                                    [value]="item.idDataSourceVariable">
                                    {{item.dataSourceVariableName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="hasAxisY" class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Eixo Y</mat-label>
                            <mat-select formControlName="idDataSourceVariableY" *ngIf="isAxisYMultiple" multiple>
                                <mat-option *ngFor="let item of listDataSourceVariableY"
                                    [value]="item.idDataSourceVariable">
                                    {{item.dataSourceVariableName}}
                                </mat-option>
                            </mat-select>
                            <mat-select formControlName="idDataSourceVariableY" *ngIf="!isAxisYMultiple">
                                <mat-option *ngFor="let item of listDataSourceVariableY"
                                    [value]="item.idDataSourceVariable">
                                    {{item.dataSourceVariableName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="hasClassification" class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Classificação</mat-label>
                            <mat-select formControlName="idClassification" (selectionChange)="classificationType()">
                                <mat-option *ngFor="let item of listFilteredClassification"
                                    [value]="item.idClassification">
                                    {{item.classificationName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="hasClassificationField" class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Campo para ordenação</mat-label>
                            <mat-select formControlName="classificationField">
                                <mat-option *ngFor="let item of listclassificationField"
                                    [value]="item.idDataSourceVariable">
                                    {{item.dataSourceVariableName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="hasAggreation" class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Agregação</mat-label>
                            <mat-select formControlName="idAggregation" *ngIf="isAggregationMultiple" multiple>
                                <mat-option *ngFor="let item of listAggregation" [value]="item.idAggregation">
                                    {{item.aggregationName}}
                                </mat-option>
                            </mat-select>
                            <mat-select formControlName="idAggregation" *ngIf="!isAggregationMultiple">
                                <mat-option *ngFor="let item of listAggregation" [value]="item.idAggregation">
                                    {{item.aggregationName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Ordem</mat-label>
                            <input matInput type="number" formControlName="order">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="footer-flow-modal-list">
                <div class="row">
                    <div class="col-12">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true">
                            </mat-spinner>
                        </button>
                    </div>
                    <div class="col-12">
                        <button mat-flat-button type="button" color="accent" class=" btn-block" (click)="clickCancel()">
                            Cancelar
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>