<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <div class="page-title">
            <div>
                <a class="back-link" routerLink="/digital-prompt-service">
                    <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Módulo PA Digital
                </a>
                <h1>Configurações gerais</h1>
            </div>
            <div class="col-12 col-md-2">
                <button mat-flat-button type="submit" color="primary" class="btn-block" (click)="submit()">
                    <span *ngIf="isLoading == false">
                        Salvar
                    </span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
            </div>
        </div>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <form class="form" [formGroup]="model">
            <div class="white-body">
                <div class="title-header">
                    <h1>Configuração da página</h1>
                </div>
                <div class="identity-body">
                    <div class="customize-identity">
                        <div class="identity">
                            <div class="slider-checkbox">
                                <mat-slide-toggle formControlName="isLogoUploaded" checked="isLogoUploaded">
                                    Usar logo no topo da página
                                </mat-slide-toggle>
                            </div>
                            <div class="choice-identity">
                                <a class="link-uploaded-logo" (click)="openPanelLogoModal()"
                                    *ngIf="this.model.get('isLogoUploaded').value">
                                    <mat-icon class="img-uploaded-logo">
                                        image
                                    </mat-icon>
                                    <span *ngIf="this.logo">
                                        Visualizar Logo
                                    </span>
                                    <span *ngIf="!this.logo">
                                        Selecionar Logo
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div class="identity">
                            <div class="slider-checkbox">
                                <mat-slide-toggle formControlName="useColor" checked="useColor">
                                    Alterar a cor padrão da página de Agendamentos
                                </mat-slide-toggle>
                            </div>
                            <div class="choice-identity" *ngIf="this.model.get('useColor').value">
                                <ngx-colors class="color-picker-div" ngx-colors-trigger formControlName="colorCode"
                                    [acceptLabel]="'Aceitar'" [cancelLabel]="'Cancelar'"
                                    (change)="updateColorCodePreview()"></ngx-colors>
                                <span class="color-picker-span">
                                    Selecionar cor
                                </span>
                            </div>
                        </div>
                        <div class="identity">
                            <div class="slider-checkbox">
                                <mat-slide-toggle formControlName="allowSelectionHealthcareAgreement" checked="allowSelectionHealthcareAgreement">
                                    Permitir a seleção de convênios
                                </mat-slide-toggle>
                            </div>
                        </div>
                        <div class="identity">
                            <mat-form-field appearance="outline">
                                <mat-label>Tempo de sessão</mat-label>
                                <mat-select formControlName="sessionTimeout">
                                    <mat-option *ngFor="let item of listSessionTimeout" value="{{item.idSessionTimeout}}">
                                        {{item.sessionTimeoutName}} <span style="font-weight: bold;">{{item.idSessionTimeout ==
                                            idSessionTimeoutNoLimit ? "Sem Limite" : item.timeoutValue+"h"}}</span>
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="model.get('sessionTimeout').invalid">Informe o tempo de sessão ativa</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="button-preview-margin">
                    <button mat-flat-button color="primary" class="button-preview" (click)="openPreview()">
                        <span *ngIf="isLoading == false">
                            VISUALIZAR ALTERAÇÕES NA PÁGINA
                        </span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
            </div>
                
            <div class="white-body">
                <div class="title-header">
                    <h1>URL da página</h1>
                </div>
                <div class="row">
                    <div class="col-9">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome</mat-label>
                            <input matInput type="text" formControlName="urlName">
                            <mat-error *ngIf="model.get('urlName').invalid">Informe o Nome</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-9">
                        <mat-form-field appearance="outline">
                            <mat-label>Status</mat-label>
                            <mat-select formControlName="urlStatus">
                                <mat-option [value]="true">Ativo</mat-option>
                                <mat-option [value]="false">Inativo</mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('urlStatus').invalid">Informe o Status</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Url</mat-label>
                            <input matInput type="text" formControlName="url" (keyup)="getCompleteUrl($event)">
                            <mat-error *ngIf="model.get('url').invalid">Informe a url</mat-error>
                        </mat-form-field>
                        <span *ngIf="this.model.get('url').value">
                            <a type="button" class="complete-link" [href]="completeUrl" target="_blank">
                                {{completeUrl}}
                            </a>
                            <button matTooltip="Copiar" aria-label="Copiar" class="copy-to-clipboard" type="button"
                                [cdkCopyToClipboard]="completeUrl" [cdkCopyToClipboardAttempts]="5"
                                (click)="onCopy('item copiado')">
                                <mat-icon class="copy-icons">content_copy</mat-icon>
                            </button>
                        </span>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>