<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="alert-img">
            <img src="assets/images/alert.png" aria-hidden="false" aria-label="alert" />
        </div>
        <h1>Salvar a conduta?</h1>
        <div class="body-audit-modal-warning">
            Ao continuar com a ação, você não poderá salvar essa conduta novamente. Se ainda houver exames a serem
            anexados, ative a opção de vincular o arquivo posteriormente.
        </div>
        <div class="separate-button">
            <button mat-flat-button color="accent" class="btn-block button-margin-right" (click)="close()">
                <span *ngIf="isLoading == false">CANCELAR</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
            <button mat-flat-button color="primary" class="btn-block button-margin-left" (click)="continue()">
                <span *ngIf="isLoading == false">CONFIRMAR</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
        </div>
    </div>
</div>