import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TypeItemKitEnum } from 'src/app/shared/enum/pharmacy/type-item-kit.enum';
import { KitItemSelectStruct } from 'src/app/shared/services/structs/pharmacy/kit-item-select.struct';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-kit-item-select',
  templateUrl: './kit-item-select.component.html',
  styleUrls: ['./kit-item-select.component.css']
})
export class KitItemSelectComponent implements OnInit {

  constructor() { }

  @Input() kitItemSelectStruct: KitItemSelectStruct;
  @Output() addItemEvent = new EventEmitter<void>();
  @Output() removeItemEvent = new EventEmitter<number>();

  public urlListAllItem: string = environment.urlApiPharmacy + "ItemSearchSelect";
  public urlListAllMedicine: string = environment.urlApiPharmacy + "Medicine/getAllForKit";
  
  public typeItemKitEnum: typeof TypeItemKitEnum = TypeItemKitEnum;

  ngOnInit(): void {

  }

  setItemId(event: any) {
    this.kitItemSelectStruct.formGroup.get('firstChildGroup').get('idItem').setValue(event.idItem);
  }

  setMedicineId(event: any) {
    this.kitItemSelectStruct.formGroup.get('firstChildGroup').get('idItem').setValue(event.idMedicine);
  }

  selectTypeItem(event: any) {
    this.kitItemSelectStruct.formGroup.get('firstChildGroup').get('idItem').enable();
  }

  add() {
    this.addItemEvent.emit();
  }
  remove() {
    this.removeItemEvent.emit(this.kitItemSelectStruct.idField);
  }
}
