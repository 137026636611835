import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PresentationEnum } from 'src/app/shared/enum/prescription.enum';
import { VerifyStorageService } from 'src/app/shared/services/API/pharmacy/verify-storage.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { LocationModel } from 'src/app/shared/services/models/pharmacy/location.model';
import { MedicinePresentationTypeModel } from 'src/app/shared/services/models/pharmacy/medicine-presentation-type.model';
import { StorageStruct } from 'src/app/shared/services/structs/pharmacy/storage.struct';
import { PrescriptionNonDispensationModalComponent } from '../prescription-non-dispensation-modal/prescription-non-dispensation-modal.component';
import { MedicineBarCodeModalComponent } from '../medicine-bar-code-modal/medicine-bar-code-modal.component';
import { PresentationTypeConfirmModalComponent } from '../presentation-type-confirm-modal/presentation-type-confirm-modal.component';
import { EpisodeStruct } from 'src/app/shared/services/structs/medical-record/episode.struct';
import { MedicinePresentationTypeService } from 'src/app/shared/services/API/pharmacy/medicine-presentation-type.service';
import { PrescriptionStatusEnum } from 'src/app/shared/enum/prescription-status.enum';
import { LiberatePrescriptionRequest } from 'src/app/shared/services/requests/orchestrator-patient/liberate-prescription.request';
import { AlertModalComponent } from 'src/app/shared/components/alert-modal/alert-modal.component';

@Component({
  selector: 'app-liberate-medical-prescription-solution-modal',
  templateUrl: './liberate-medical-prescription-solution-modal.component.html',
  styleUrls: ['./liberate-medical-prescription-solution-modal.component.css']
})
export class LiberateMedicalPrescriptionSolutionModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<LiberateMedicalPrescriptionSolutionModalComponent>,
    public verifyStorageService: VerifyStorageService,
    public medicinePresentationTypeService: MedicinePresentationTypeService,
    private alertService: AlertService,
    private formBuilder: FormBuilder) {
      this.matDialogRef.disableClose = true;
  }

  public model: FormGroup;

  public listLocation: LocationModel[];
  public listStorage: StorageStruct[];
  public presentationList: MedicinePresentationTypeModel[];
  public storage: StorageStruct = new StorageStruct;
  public episode: EpisodeStruct = new EpisodeStruct();

  public presentationOthersEnum: PresentationEnum = PresentationEnum.outros.valueOf();
  public idMedicinePresentationType: number;
  public barcodeValue: number;

  public medicinePresentationOther: string = null;

  public haveListItem: boolean = false;
  public isInvalid: boolean = false;
  public isValid: boolean = false;
  public isLoading: boolean = false;
  public validated: boolean = false;

  ngOnInit(): void {
    this.listLocation = this.data.listLocation;
    this.episode = new EpisodeStruct();
    this.model = this.formBuilder.group({
      presentationTypeDoctor: [{ value: '', disabled: true }],
      quantityDoctor: [{ value: this.data.prescription.quantity ? this.data.prescription.quantity : "Não informado", disabled: true }],
      idLocation: [, [Validators.required]],
      quantity: [this.data.prescription.quantity ? this.data.prescription.quantity : null, [Validators.required, Validators.min(1)]],
      idPresentationType: [{ value: '', disabled: true }],
    });

    this.storageSuggestion();
    this.populatePresentationType();
  }

  storageSuggestion() {
    this.verifyStorageService.listMedicineStorage(this.data.prescription.idMedicine).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        if (response.listStorage) {
          this.listStorage = response.listStorage;
          this.storage = this.listStorage[0];
          this.isValid = true;
        }
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populatePresentationType() {
    this.medicinePresentationTypeService.listMedicinePresentationType().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.presentationList = response.listMedicinePresentationType;

        this.model.get('presentationTypeDoctor').setValue(this.data.prescription.idPresentation ? this.data.prescription.presentationName : "Não informado");
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openPrescriptionNonDispensationModalFromModal() {
    const dialogRef = this.dialog.open(PrescriptionNonDispensationModalComponent, {
      data: {
        prescription: this.data.prescription,
        patientName: this.data.episode.patientName,
        solution: true
      },
    });

    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.notDispensed)
          this.notDispensation(result.nonDispensationRequest)
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  clickCancel() {
    this.matDialogRef.close({ cancel: true });
  }

  notDispensation(nonDispensationRequest) {
    this.matDialogRef.close({ nonDispensation: true, nonDispensationRequest: nonDispensationRequest });
  }

  submit() {
    if(!this.data.prescription.patientHasAllergy){
      this.liberatePrescription();
    }
    else{
      const dialogRef = this.dialog.open(AlertModalComponent, {
        data: {
          hasImage: true,
          isTwoButtonsModal: true,
          title: 'Atenção!',
          description: `O paciente é alérgico a um dos princípios ativos deste medicamento. \nDeseja prosseguir com a liberação?`
        },
      });
      dialogRef.afterClosed().subscribe({
        next: result => {
          if (result && result.confirm) {
            this.liberatePrescription();
          }
          this.isLoading = false;
        }
      });
    }
    
  }

  liberatePrescription(){
    this.model.get('idLocation').markAsTouched();
    this.model.get('quantity').markAsTouched();

    if (this.isLoading || this.model.invalid)
      return;
    this.isLoading = true;

    let liberatePrescriptionRequest: LiberatePrescriptionRequest = new LiberatePrescriptionRequest();

    liberatePrescriptionRequest.idStorage = this.barcodeValue;
    liberatePrescriptionRequest.idMedicine = this.data.prescription.idMedicine;
    liberatePrescriptionRequest.quantity = this.model.get('quantity').value;
    liberatePrescriptionRequest.idLocation = this.model.get('idLocation').value;
    liberatePrescriptionRequest.listIdKit = [];
    liberatePrescriptionRequest.dilutionName = this.data.prescription.dilutionName;
    liberatePrescriptionRequest.medicineName = this.data.prescription.medicineName;
    liberatePrescriptionRequest.administrationTypeName = this.data.prescription.administrationTypeName;
    liberatePrescriptionRequest.patientName = this.data.episode.patientName;
    liberatePrescriptionRequest.motherName = this.data.episode.motherName;
    liberatePrescriptionRequest.birthDate = this.data.episode.birthDate;
    liberatePrescriptionRequest.idEpisode = this.data.episode.idEpisode;
    liberatePrescriptionRequest.idStatusPrescription = PrescriptionStatusEnum.Liberado;
    liberatePrescriptionRequest.idMedicalPrescription = this.data.prescription.idMedicalPrescription;

    this.isLoading = false;
    this.matDialogRef.close({ liberatePrescriptionRequest: liberatePrescriptionRequest, liberate: true });
  }

  openBarCodeModal() {
    const dialogRef = this.dialog.open(MedicineBarCodeModalComponent, {
      data: {
        idMedicine: this.data.prescription.idMedicine,
        listStorage: this.listStorage,
      },
    });

    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.barcodeValue) {
          this.barcodeValue = result.barcodeValue;
          this.medicinePresentationOther = result.medicine.medicinePresentationOther;
          this.idMedicinePresentationType = result.medicine.idMedicinePresentationType;
          this.model.get('idPresentationType').setValue(result.medicine.idMedicinePresentationType);
          this.model.get('idLocation').setValue(result.idLocation);
          this.model.get('idLocation').disable();
          if (this.data.prescription.idPresentation && this.idMedicinePresentationType != this.data.prescription.idPresentation)
            this.openConfirmModal();

          this.validated = true;
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  openConfirmModal() {
    const dialogRef = this.dialog.open(PresentationTypeConfirmModalComponent);

    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.confirm == false)
          this.openBarCodeModal();
      },
      error: (error) => {
        console.log(error);
      }
    });
  }
}