import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-kpi-config-delete-modal',
  templateUrl: './kpi-config-delete-modal.component.html',
  styleUrls: ['./kpi-config-delete-modal.component.css']
})

export class KpiConfigDeleteModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<KpiConfigDeleteModalComponent>) { }

  clickDelete() {
    this.matDialogRef.close({ confirm: true });
  }

  clickCancel() {
    this.matDialogRef.close({ confirm: false });
  }
}
