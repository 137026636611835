<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <a class="back-link" routerLink="/observation/observation-config">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Configurações
        </a>
        <h1>Nova Configuração</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome</mat-label>
                            <input matInput type="text" formControlName="observationConfigName">
                            <mat-error *ngIf="model.get('observationConfigName').invalid">Informe o nome</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Descrição</mat-label>
                            <input matInput type="text" formControlName="observationConfigDescription">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Setor</mat-label>
                            <mat-select formControlName="listSector" multiple>
                                <mat-option *ngFor="let sector of listSector" [value]="sector.idSector">
                                    {{sector.sectorName}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('listSector').invalid">Informe ao menos um setor</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Templates</mat-label>
                            <mat-select formControlName="listIdTextTemplate" multiple>
                                <mat-option *ngFor="let item of listTextTemplate" [value]="item.idTextTemplate">
                                    {{item.textTemplateName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <div class="form-field-toggle">
                            <mat-label>Alocar paciente a um leito para atendimento</mat-label>
                            <mat-slide-toggle formControlName="allocateToBed" [checked]="isAllocateToBed"
                                (change)="allocateToBed($event)"></mat-slide-toggle>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <div class="form-field-toggle">
                            <mat-label>Possibilidade de Liberação/Alta do paciente</mat-label>
                            <mat-slide-toggle formControlName="isReleasable"></mat-slide-toggle>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <div class="form-field-toggle">
                            <mat-label>Possibilidade de criar pedido de dispensação para farmácia</mat-label>
                            <mat-slide-toggle formControlName="isReleasablePharmacy"></mat-slide-toggle>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <div class="form-field-toggle" *ngIf="isAllocateToBed">
                            <mat-label>Obrigatoriedade de alocação do paciente ao leito para atendimento</mat-label>
                            <mat-slide-toggle formControlName="isMandatory" [checked]="isMandatoryActive"
                            (change)="isMandatory($event)"></mat-slide-toggle>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <div class="form-field-toggle">
                            <mat-label>Permitir evadir paciente</mat-label>
                            <mat-slide-toggle formControlName="allowEvasion"></mat-slide-toggle>
                        </div>
                    </div>
                </div>            
                <div class="row">
                    <div class="col-md-2">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <a mat-flat-button type="button" routerLink="/observation/observation-config"
                            color="accent" class=" btn-block">
                            Cancelar
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>
    
</div>