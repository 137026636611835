import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { DeleteServiceModalComponent } from './delete-service-modal/delete-service-modal.component';
import { ListServiceStruct } from '../../../shared/services/responses/flow/list-service-response';
import { ServiceService } from 'src/app/shared/services/API/flow/service.service';
import { ModuleService } from 'src/app/shared/services/API/user/module.service';
import { ModuleStruct } from 'src/app/shared/services/structs/user/module.struct';

@Component({
  selector: 'app-service-list',
  templateUrl: './service-list.component.html',
  styleUrls: ['./service-list.component.css']
})
export class ServiceListComponent implements OnInit {

  constructor(private serviceService: ServiceService,
    private moduleService: ModuleService,
    private alertService: AlertService,
    private utilService: UtilService,
    public dialog: MatDialog) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.master;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.master_service;

  public listService: ListServiceStruct[];
  public listModule: ModuleStruct[];
  public isLoading: boolean = true;
  public isActive: boolean;
  public searchText: string;
  ip: string = '192.168.0.2';

  ngOnInit(): void {
    this.search();

    setTimeout(() => { this.getIP(); }, 2000);
  }

  search() {
    this.isLoading = true;
    if (this.searchText == "")
      this.searchText = null;

    this.serviceService.listService(this.searchText, this.isActive).subscribe({
      next: (response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listService = response.listService;

        this.moduleService.listAllModule().subscribe({
          next: (response) => {

            if (response.isError) {
              this.alertService.show('Erro', response.errorDescription, AlertType.error);
              return;
            }

            this.listModule = response.listModuleStruct;

            this.listService = this.listService.map(x => ({
              ...this.listModule.find(c => (c.idModule === x.idModule) && c.nameModule),
              ...x
            }));

          },
          error: (error) => {
            console.log(error);
            this.alertService.show('Erro inesperado', error, AlertType.error);
          }
        });

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }

  openModal(idService) {

    const dialogRef = this.dialog.open(DeleteServiceModalComponent, {
      data: {
        idService: idService,
        ip: this.ip
      },
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result && result.deleteService) {
        this.search();
      }
    });
  }

  getIP() {
    this.utilService.getIP().subscribe({
      next: (response) => {
        if (response && response.ip) {
          this.ip = response.ip;
        }
      }
    });
  }
}