import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { HealthcareAgreementService } from 'src/app/shared/services/API/private-billing/healthcare-agreement.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { HealthcareAgreementStruct } from 'src/app/shared/services/structs/private-billing/healthcare-agreement.struct';

@Component({
  selector: 'app-healthcare-agreements-and-plans-list',
  templateUrl: './healthcare-agreements-and-plans-list.component.html',
  styleUrls: ['./healthcare-agreements-and-plans-list.component.css']
})
export class HealthcareAgreementsAndPlansListComponent implements OnInit {
  constructor(private alertService: AlertService,
    public dialog: MatDialog,
    private healthcareAgreementService: HealthcareAgreementService) {
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.billing;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.healthcare_agreements_and_plans;

  public listHealthcareAgreement: HealthcareAgreementStruct[] = [];
  public listHealthcareAgreementBackup: HealthcareAgreementStruct[] = [];
  
  public isLoading: boolean = true;
  public isActiveHealthcare: boolean = null;
  public buttonSelected: 'ativos' | 'inativos' | null = null;

  public searchText: string = null;

  public idExamType: number;
  public pageIndex: number = 0;
  public pageSize: number = 50;
  public fullListSize: number;

  ngOnInit(): void {
    this.controlButtonStatus('ativos');
  }

  search() {
    this.isLoading = true;

    this.healthcareAgreementService.GetAll(this.searchText, this.pageIndex, this.isActiveHealthcare).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.listHealthcareAgreement = response.list;
        this.listHealthcareAgreementBackup = response.list;
        this.fullListSize = response.fullListSize;
        this.pageSize = response.pageSize;
        this.isLoading = false;
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13)
        $this.pageIndex = 0;
      if ($this.paginator)
        $this.paginator.firstPage();

      $this.search();
    },
      1000);
  }

  controlButtonStatus(buttonType: 'ativos' | 'inativos') {
    if (this.paginator)
      this.paginator.firstPage();
    if (this.buttonSelected === buttonType) {
      this.buttonSelected = null;
      this.isActiveHealthcare = null;
      this.search();
    }
    else {
      this.buttonSelected = buttonType;

      if (this.buttonSelected == 'ativos') {
        this.isActiveHealthcare = true;
        this.search();
      }
      else if (this.buttonSelected == 'inativos') {
        this.isActiveHealthcare = false;
        this.search();
      }
    }
  }

  isButtonActive(buttonType: 'ativos' | 'inativos'): boolean {
    return buttonType === this.buttonSelected;
  }

  changePage(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.search();
  }

  putStatus(event: any, idHealthcareAgreement: number) {
    this.isLoading = true;

    this.healthcareAgreementService.PutStatus(idHealthcareAgreement, event.checked).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.isLoading = false;
        const agreementToUpdate = this.listHealthcareAgreement.find(x => x.idHealthcareAgreement === idHealthcareAgreement);

        if (agreementToUpdate)
          agreementToUpdate.isActive = event.checked;

        this.alertService.show('Sucesso', 'Status do Convênio: ' + agreementToUpdate.nameHealthcareAgreement + ' atualizado com sucesso.', AlertType.success);
      },
      error: (error) => {
        this.throwException(error);
        this.search();
      }
    });
  }

  private throwException(error: string) {
    console.log(error);
    this.alertService.show('Erro inesperado', error, AlertType.error);
    this.isLoading = false;
  }
}