import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { SelectRoomModalComponent } from 'src/app/shared/components/select-room-modal/select-room-modal.component';
import { EpisodeStatusEnum } from 'src/app/shared/enum/episode-status.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ListMedicalPrescriptionPendingService } from 'src/app/shared/services/API/medical-record/list-medical-prescription-pending.service';
import { ListMedicalProcedureCheckService } from 'src/app/shared/services/API/medical-record/list-medical-procedure-check.service';
import { OccupiedBedService } from 'src/app/shared/services/API/orchestrator-patient/occupied-bed.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { LiberateBedRequest } from 'src/app/shared/services/requests/bed/liberate-bed.request';
import { ListMedicalPrescriptionPendingResponse } from 'src/app/shared/services/responses/medical-record/list-medical-prescription-pending.response';
import { ListMedicalProcedureCheckResponse } from 'src/app/shared/services/responses/medical-record/list-medical-procedure-check.response';
import { RoomStruct } from 'src/app/shared/services/structs/flow/room.struct';
import { OccupiedBedStruct } from 'src/app/shared/services/structs/orchestrator-patient/occupied-bed.struct';
import { ObservationProcedureToggleService } from 'src/app/shared/services/API/bed/observation-procedure-toggle.service';
import { GetRoomService } from 'src/app/shared/services/API/flow/get-room.service';
import { HealthUnitService } from 'src/app/shared/services/API/user/health-unit.service';

@Component({
  selector: 'app-bed-management',
  templateUrl: './bed-management.component.html',
  styleUrls: ['./bed-management.component.css']
})
export class BedManagementComponent implements OnInit, OnDestroy {

  constructor(
    private occupiedBedService: OccupiedBedService,
    private router: Router,
    private alertService: AlertService,
    public dialog: MatDialog,
    private maskService: MaskService,
    private listMedicalPrescriptionPendingService: ListMedicalPrescriptionPendingService,
    private listMedicalProcedureCheckService: ListMedicalProcedureCheckService,
    private observationProcedureToggleService: ObservationProcedureToggleService,
    private activatedRoute: ActivatedRoute,
    private getRoomService: GetRoomService,
    private healthUnitService: HealthUnitService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.observation;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.observation_bedManagement;

  public listOccupiedBed: OccupiedBedStruct[];
  public isLoading: boolean;
  public isLoadingLeaveBed: boolean;
  public searchText: string;
  public birthDate: string;
  public birthDateForm: string;
  public masks: Masks;
  public isValidDate: boolean;
  public idRoom: number;
  public idSector: number
  public idService: number
  public selectedRoom: RoomStruct;

  public initial: EpisodeStatusEnum = EpisodeStatusEnum.Iniciado;
  public endRealease: EpisodeStatusEnum = EpisodeStatusEnum.FinalizadoAlta;
  public endReleaseAdministrative = EpisodeStatusEnum.FinalizadoAltaAdministrativa;
  public endDeath: EpisodeStatusEnum = EpisodeStatusEnum.FinalizadoObito;
  public endInternation: EpisodeStatusEnum = EpisodeStatusEnum.FinalizadoInternar;
  public endEvasion: EpisodeStatusEnum = EpisodeStatusEnum.FinalizadoEvasao;

  public listMedicalPrescriptionPendingResponse: ListMedicalPrescriptionPendingResponse;
  public listMedicalProcedureCheckResponse: ListMedicalProcedureCheckResponse;
  public isActivate: boolean;
  public hasObservationProcedure: boolean = false;
  public showTissGuideButton: boolean = false;

  ngOnInit(): void {
    this.isActivate = true;
    this.masks = this.maskService.getMasks();
    //this.isLoading = true;
    this.isLoadingLeaveBed = false;
    this.listMedicalPrescriptionPendingResponse = new ListMedicalPrescriptionPendingResponse();
    this.listMedicalProcedureCheckResponse = new ListMedicalProcedureCheckResponse();
    if (this.activatedRoute.snapshot.paramMap.get('idRoom')) {
      this.idRoom = parseInt(this.activatedRoute.snapshot.paramMap.get('idRoom'));
    }
    if (this.activatedRoute.snapshot.paramMap.get('idSector')) {
      this.idSector = parseInt(this.activatedRoute.snapshot.paramMap.get('idSector'));
    }
    if (this.activatedRoute.snapshot.paramMap.get('idService')) {
      this.idService = parseInt(this.activatedRoute.snapshot.paramMap.get('idService'));
    }
    if (this.idRoom && this.idSector) {
      this.getRoomUnAuth();
    } else {
      this.selectRoomModal();
    }
    this.activateInterval();
    this.getProcedureConfig();
    this.getHealthUnitConfig();
  }

  getRoomUnAuth() {
    this.isLoading = true;
    this.getRoomService.getRoom(this.idRoom).subscribe((response) => {
      this.isLoading = false;
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.selectedRoom = response.roomStruct;
      this.search();
      this.listPrescriptionPending();
      this.listProcedureCheck();
    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      });
  }

  ngOnDestroy() {
    this.isActivate = false;
  }

  search() {
    if (this.isLoading || !this.idSector)
      return;

    this.isLoading = true;
    if (this.searchText == "")
      this.searchText = null;

    if (!this.isValidDate)
      this.birthDateForm = "";

    this.occupiedBedService.listOccupiedBed(this.searchText, this.birthDateForm, this.idSector).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listOccupiedBed = response.listOccupiedBedStruct;
      for (var item of this.listOccupiedBed) {
        item.isLoadingLeaveBed = false;
      }

      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  private timeoutKeySearch: any = null;

  dateChage() {
    this.isValidDate = this.birthDate && this.birthDate.length == 8;
    if (this.isValidDate) {
      this.birthDateForm = this.maskService.formatDateToString(this.maskService.formatStringToDate(this.birthDate), null, true);
      this.search();
    } else if (!this.birthDate || this.birthDate && this.birthDate.length == 0) {
      this.birthDateForm = null;
      this.search();
    }
  }

  onKeySearch(event: any, field: string) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (field == 'birthDate') {
        $this.dateChage();
      } else {
        $this.search();
      }
    }, 1000);
  }

  leaveBed(bed) {
    bed.isLoadingLeaveBed = true;
    let liberateBedRequest: LiberateBedRequest = new LiberateBedRequest();

    liberateBedRequest.idEpisode = bed.idEpisode;

    this.occupiedBedService.liberateBed(liberateBedRequest).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        bed.isLoadingLeaveBed = false;
        return;
      }
      this.alertService.show('Sucesso', "Leito liberado com sucesso!", AlertType.success);
      bed.isLoadingLeaveBed = false;
      this.search();
    },
      (error) => {
        bed.isLoadingLeaveBed = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  listPrescriptionPending() {
    this.listMedicalPrescriptionPendingResponse.listMedicalPrescriptionPendingLate = [];
    this.listMedicalPrescriptionPendingResponse.listMedicalPrescriptionPendingNear = [];
    this.listMedicalPrescriptionPendingResponse.listMedicalPrescriptionPendingUnchecked = [];
    this.isLoading = true;
    this.listMedicalPrescriptionPendingService.listMedicalPrescriptionPending().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listMedicalPrescriptionPendingResponse = response;

      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }
  listProcedureCheck() {
    this.listMedicalProcedureCheckResponse.listProcedureWaiting = [];

    this.isLoading = true;
    this.listMedicalProcedureCheckService.listMedicalProcedureCheck().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listMedicalProcedureCheckResponse = response;

      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  activateInterval() {
    var $this = this;
    var interval = setInterval(function () {
      $this.listPrescriptionPending();
      $this.listProcedureCheck();
      if (!$this.isActivate) clearInterval(interval);
    }, 60000);
  }

  selectRoomModal() {
    const dialogRef = this.dialog.open(SelectRoomModalComponent, {
      data: {
        idModule: this.menuModuleEnum,
      },
    });
    dialogRef.afterClosed().subscribe(({ res, seeOccupiedRoom }) => {
      if (res) {
        this.idRoom = res.idRoom;
        this.idSector = res.idSector
        this.idService = res.idService
        this.selectedRoom = res;
        this.search();
        this.listPrescriptionPending();
        this.listProcedureCheck();
      }
    });
  }

  getProcedureConfig() {
    this.observationProcedureToggleService.getProcedureConfig().subscribe((response) => {
      this.isLoading = false;
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.hasObservationProcedure = response.hasObservationProcedure;
    },
      (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  getHealthUnitConfig() {
    this.healthUnitService.getHealthUnitConfig().subscribe({
      next: (response) => {
        if (response.isError) {
          console.log(response.isError);
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
        }
        this.showTissGuideButton = response.healthUnit.canAddTissGuide;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }
}