<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <h1>Alertas de Fila</h1>
        <div class="white-body">
            <form appFormscroll class="form" [formGroup]="model" (ngSubmit)="search()">
                <a mat-flat-button color="primary" class=" add-user"
                    routerLink="/master/alert-policy/register"><mat-icon aria-hidden="false"
                        aria-label="Plus">add</mat-icon> Cadastrar Alerta de Fila</a>

                <div class="row filters" *ngIf="!isLoading">
                    <div class="col-12 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Palavra chave</mat-label>
                            <input matInput type="text" id="searchText" formControlName="searchText"
                                (keyup)="keyWordFilter($event)">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Serviço</mat-label>
                            <mat-select (selectionChange)="serviceFilter()" formControlName="listIdService"
                                [multiple]="true">
                                <div class="clear-selection">
                                    <a mat-flat-button color="primary" class="add-user" (click)="clearServices()">
                                        Limpar seleção</a>

                                </div>
                                <mat-option *ngFor="let item of serviceMap | keyvalue"
                                    [value]="item.key">{{item.value.serviceName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <!-- <div class="col-6 col-md-2">
                        <button mat-flat-button type="submit" color="primary" class="btn-block">
                            <span *ngIf="isLoading == false">Pesquisar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div> -->
                </div>
                <div class="card-list" *ngIf="listAlertPolicyDisplay != null && listAlertPolicyDisplay.length > 0">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listAlertPolicyDisplay">
                            <div class="card-item">
                                <div class="infos">
                                    <span class="primary">{{serviceMap.get(item.idService).serviceName}}</span>
                                    <span class="secundary">Tempo máximo de espera em fila: {{item.minTime}} </span>
                                    <span class="secundary">Qtde. mínima de pacientes aguardando: {{item.minQueueCount}} </span>
                                </div>
                                <div class="actions">
                                    <a><mat-icon aria-hidden="false" aria-label="Editar"
                                            [routerLink]="['/master/alert-policy/register', {idAlertPolicy: item.idAlertPolicy}]">edit</mat-icon>
                                    </a>
                                    <a><mat-icon aria-hidden="false" aria-label="Editar"
                                            (click)="openDeleteModal(item.idAlertPolicy)">delete_forever</mat-icon> </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <app-empty-list *ngIf="!listAlertPolicyDisplay"></app-empty-list>
            </form>
        </div>
        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>

</div>