import { NgModule } from '@angular/core';
import { TotemCustomListComponent } from './totem-custom-list.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';



@NgModule({
  declarations: [TotemCustomListComponent],
  exports: [TotemCustomListComponent],
  imports: [
    CommonModule,
    SharedModule,
    MatButtonModule,
    MatDialogModule
  ]
})
export class TotemCustomListModule { }
