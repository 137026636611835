import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { ClientRoutingModule } from './client-routing.module';
import { BiPanelComponent } from './pages/bi-panel/bi-panel.component';
import { PinBiPanelComponent } from './pages/bi-panel/pin-bi-panel/pin-bi-panel.component';
import { DisplayBiPanelComponent } from './pages/bi-panel/display-bi-panel/display-bi-panel.component';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IframeTelemedicineComponent } from './pages/iframe/pages/iframe-telemedicine/iframe-telemedicine.component';
import { ClassificationModule } from '../classification/classification.module';
import { IframeComponent } from './pages/iframe/iframe.component';
import { IframeFlowchartComponent } from './pages/iframe/pages/iframe-telemedicine/flowchart/iframe-flowchart.component';
import { IframeCounselingSelectionComponent } from './pages/iframe/pages/iframe-telemedicine/counseling-selection/counseling-selection.component';
import { IframeClassificationDetailsComponent } from './pages/iframe/pages/iframe-telemedicine/iframe-classification-details/iframe-classification-details.component';
import { ValidatorsModalComponent } from './pages/iframe/modals/validators-modal/validators-modal.component';
import { SuccessModalComponent } from './pages/iframe/modals/success-modal/success-modal.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxMaskModule } from 'ngx-mask';
import { MatDialogModule } from '@angular/material/dialog';
import { HygienizationTotemComponent } from './pages/hygienization-totem/hygienization-totem.component';
import { TotemBedHygienizationComponent } from './pages/hygienization-totem/pages/totem-bed-hygienization/totem-bed-hygienization.component';
import { TotemHygienizationModalComponent } from './pages/hygienization-totem/pages/totem-bed-hygienization/totem-hygienization-modal/totem-hygienization-modal.component';
import { LoginHygienizationTotemComponent } from './pages/hygienization-totem/pages/login-hygienization-totem/login-hygienization-totem.component';
import { PinHygienizationTotemComponent } from './pages/hygienization-totem/pages/hygienization-pin-totem/hygienization-pin-totem.component';
import { NotLoggedInModalComponent } from './pages/hygienization-totem/pages/login-hygienization-totem/not-logged-in-modal/not-logged-in-modal.component';
import { TelemedicineReportComponent } from './pages/telemedicine-report/telemedicine-report.component';
import { TotemModule } from './pages/totem/totem.module';
import { DisplayModule } from './pages/display/display.module';
import { ReportViewerComponent } from './pages/report-viewer/report-viewer.component';
import { TotemExitModalComponent } from './pages/hygienization-totem/pages/totem-bed-hygienization/totem-exit-modal/totem-exit-modal.component';
import { TotemEndHygienizationModalComponent } from './pages/hygienization-totem/pages/totem-bed-hygienization/totem-end-hygienization-modal/totem-end-hygienization-modal.component';
import { MatSelectModule } from '@angular/material/select';
import { NpsTotemComponent } from './pages/nps-totem/nps-totem.component';
import { VotingNpsTotemComponent } from './pages/nps-totem/pages/voting-nps-totem/voting-nps-totem.component';
import { EndNpsTotemModalComponent } from './pages/nps-totem/pages/voting-nps-totem/end-nps-totem-modal/end-nps-totem-modal.component';
import { PinNpsTotemComponent } from './pages/nps-totem/pages/pin-nps-totem/pin-nps-totem.component';
import { CpfNpsTotemComponent } from './pages/nps-totem/pages/cpf-nps-totem/cpf-nps-totem.component';
import { CpfNotFoundModalComponent } from './pages/nps-totem/pages/cpf-nps-totem/cpf-not-found-modal/cpf-not-found-modal.component';
import { PinRemoteAttendanceComponent } from './pages/remote-attendace/pages/pin-remote-attendance/pin-remote-attendance.component';
import { WelcomeRemoteAttendanceComponent } from './pages/remote-attendace/pages/welcome-remote-attendance/welcome-remote-attendance.component';
import { CpfValidityRemoteAttendanceComponent } from './pages/remote-attendace/pages/cpf-validity-remote-attendance/cpf-validity-remote-attendance.component';
import { InvalidModalComponent } from './pages/remote-attendace/modals/invalid-modal/invalid-modal.component';
import { WarnPatientDoctorEvadeComponent } from './pages/remote-attendace/modals/warn-patient-doctor-evade/warn-patient-doctor-evade.component';
import { AttendPatientVideoComponent } from './pages/remote-attendace/pages/attend-patient-video/attend-patient-video.component';
import { WarnPatientEvadeComponent } from './pages/remote-attendace/modals/warn-patient-evade/warn-patient-evade.component';
import { EndedQueryComponent } from './pages/remote-attendace/pages/ended-query/ended-query.component';
import { RemoteAttendanceReportViewerComponent } from './pages/remote-attendace/pages/remote-attendance-report-viewer/remote-attendance-report-viewer.component';
import { RoomDuplicatedComponent } from './pages/remote-attendace/modals/room-duplicated/room-duplicated.component';
import { RoomClosedComponent } from './pages/remote-attendace/modals/room-closed/room-closed.component';
import { SupportPageComponent } from './pages/support-page/support-consulting.component';
import { PhoneModalComponent } from './pages/support-page/phone-modal/phone-modal.component';

@NgModule({
  declarations: [
    BiPanelComponent,
    PinBiPanelComponent,
    DisplayBiPanelComponent,
    IframeComponent,
    IframeTelemedicineComponent,
    ValidatorsModalComponent,
    IframeFlowchartComponent,
    IframeCounselingSelectionComponent,
    IframeClassificationDetailsComponent,
    SuccessModalComponent,
    HygienizationTotemComponent,
    TotemBedHygienizationComponent,
    TotemHygienizationModalComponent,
    LoginHygienizationTotemComponent,
    PinHygienizationTotemComponent,
    NotLoggedInModalComponent,
    TelemedicineReportComponent,
    ReportViewerComponent,
    TotemExitModalComponent,
    TotemEndHygienizationModalComponent,
    NpsTotemComponent,
    VotingNpsTotemComponent,
    EndNpsTotemModalComponent,
    PinNpsTotemComponent,
    CpfNpsTotemComponent,
    CpfNotFoundModalComponent,
    PinRemoteAttendanceComponent,
    WelcomeRemoteAttendanceComponent,
    CpfValidityRemoteAttendanceComponent,
    InvalidModalComponent,
    WarnPatientDoctorEvadeComponent,
    AttendPatientVideoComponent,
    WarnPatientEvadeComponent,
    EndedQueryComponent,
    RemoteAttendanceReportViewerComponent,
    RoomDuplicatedComponent,
    RoomClosedComponent,
    SupportPageComponent,
    PhoneModalComponent
  ],
  imports: [
    CommonModule,
    ClientRoutingModule,
    NgxMaskModule.forRoot(),
    MatProgressSpinnerModule,
    MatInputModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatSlideToggleModule,
    ClassificationModule,
    MatCheckboxModule,
    MatDialogModule,
    TotemModule,
    DisplayModule,
    SharedModule
  ]
})
export class ClientModule { }
