import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ErrorInScheduleChangeEnum } from 'src/app/shared/enum/schedule/error-in-schedule-change.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MedicatedSolutionStruct } from 'src/app/shared/services/structs/medical-record/medicated-solution.struct';
import { CompleteMedicalCareStruct } from 'src/app/shared/services/structs/orchestrator-patient/complete-medical-care.struct';
import { ReadMedicalPrescriptionStruct } from 'src/app/shared/services/structs/orchestrator-patient/read-medical-prescription.struct';

@Component({
  selector: 'app-select-old-prescription-modal',
  templateUrl: './select-old-prescription-modal.component.html',
  styleUrls: ['./select-old-prescription-modal.component.css']
})
export class SelectOldPrescriptionModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<SelectOldPrescriptionModalComponent>,
    private alertService: AlertService,
  ) { }

  public isLoading: boolean = false;
  public errorInScheduleChangeEnum: typeof ErrorInScheduleChangeEnum = ErrorInScheduleChangeEnum;
  public listMedicalCareFilterByPrescriptionDate: CompleteMedicalCareStruct[] = [];

  ngOnInit(): void {
    if (!this.data || !this.data.medicalCareFilterByPrescriptionDate || this.data.medicalCareFilterByPrescriptionDate.length == 0){
      this.alertService.show('Erro inesperado', "A lista de atendimentos anteriores está vazia.", AlertType.error);
      this.close();
      return;
    }

    this.listMedicalCareFilterByPrescriptionDate = this.data.medicalCareFilterByPrescriptionDate;
    this.formatTooltipMedicineListName();
  }

  selectedPrescription(listMedicalPrescription: ReadMedicalPrescriptionStruct[], listMedicatedSolution: MedicatedSolutionStruct[]) {
    this.matDialogRef.close({listPrescription: listMedicalPrescription, listMedicatedSolution: listMedicatedSolution});
  }

  close() {
    this.matDialogRef.close();
  }

  formatTooltipMedicineListName(){
    this.listMedicalCareFilterByPrescriptionDate.forEach(item => {
      item.medicineNamesOfPrescriptionFormatted = item.medicalPrescription.map((item, index) => (index + 1).toString() + "- " + item.medicineName).join('\n');
      item.medicineNamesOfSolutionPrescriptionFormatted = item.medicatedSolutionPrescription.map((item, index) => (index + 1).toString() + "- " + item.listMedicine.map(x => x.medicineName).join("\n    ")).join('\n');
    })
  }
}