import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { VirtualTriageConfigResponse } from '../../responses/virtual-triage/virtual-triage-config.response';
import { ListVirtualTriageConfigResponse } from '../../responses/virtual-triage/list-virtual-triage-config.response';
import { VirtualTriageConfigRequest } from '../../requests/virtual-triage/virtual-triage-config.request';

@Injectable({
  providedIn: 'root'
})
export class VirtualTriageConfigService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getVirtualTriageConfig(idVirtualTriageConfig: number): Observable<VirtualTriageConfigResponse> {
    
    let uri = `VirtualTriageConfig/idVirtualTriageConfig/${idVirtualTriageConfig}`;
    
    return this.httpClient.get<VirtualTriageConfigResponse>(environment.urlApiVirtualTriage + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public listAllVirtualTriageConfig(searchText: string): Observable<ListVirtualTriageConfigResponse> {
    
    let uri = 'VirtualTriageConfig?';

    if(searchText) uri += `searchText=${searchText}&`;
    
    return this.httpClient.get<ListVirtualTriageConfigResponse>(environment.urlApiVirtualTriage + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public getVirtualTriageConfigBySector(idSector: number): Observable<VirtualTriageConfigResponse> {
    
    let uri = `VirtualTriageConfig/idSector/${idSector}`;
    
    return this.httpClient.get<VirtualTriageConfigResponse>(environment.urlApiVirtualTriage + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public getVirtualTriageConfigBySectorWithoutPermission(idSector: number): Observable<VirtualTriageConfigResponse> {
    
    let uri = `VirtualTriageConfig/getWithoutPermission/idSector/${idSector}`;
    
    return this.httpClient.get<VirtualTriageConfigResponse>(environment.urlApiVirtualTriage + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public createVirtualTriageConfig(request: VirtualTriageConfigRequest): Observable<ReturnStruct> {
    
    let uri = 'VirtualTriageConfig'

    return this.httpClient.post<ReturnStruct>(environment.urlApiVirtualTriage + uri, request, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public updateVirtualTriageConfig(idVirtualTriageConfig: number, request: VirtualTriageConfigRequest): Observable<ReturnStruct> {
    
    let uri = `VirtualTriageConfig/idVirtualTriageConfig/${idVirtualTriageConfig}`;

    return this.httpClient.put<ReturnStruct>(environment.urlApiVirtualTriage + uri, request, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public deleteVirtualTriageConfig(idVirtualTriageConfig: number): Observable<ReturnStruct>{

    let uri = `VirtualTriageConfig/idVirtualTriageConfig/${idVirtualTriageConfig}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiVirtualTriage + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
