import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { PatientPatchRequest } from '../../requests/medical-record/patient-patch.request';
import { PatchPatientResponse } from '../../responses/medical-record/patch-patient.response';

@Injectable({
  providedIn: 'root'
})
export class TriagePreRegisterService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  public updatePatient(body: PatientPatchRequest): Observable<PatchPatientResponse>{
    let uri = `TriagePreRegister`

    return this.httpClient.post<PatchPatientResponse>(environment.urlApiOrchestratorPatient + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    );
  }
}