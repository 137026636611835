<div class="card-list" *ngIf="listMemedPrescriptionStruct != null && listMemedPrescriptionStruct.length > 0">
    <div class="row row-list">
        <div class="col-12 col-sm-6 col-md-5"
            *ngFor="let item of listMemedPrescriptionStruct; let index = index;">
            <div class="card-item">
                <div class="infos">
                    <div class="memed-prescription-info" *ngFor="let medicine of item.listMemedMedicine; let indexMedicine = index;">
                        <span class="primary">{{indexMedicine + 1}} - {{medicine.name}}</span>
                        <span class="secundary">Quantidade: {{medicine.quantity}}</span>
                        <span class="secundary">Unidade: {{medicine.unit}}</span>
                    </div>
                </div>
                <!-- <div class="actions">
                    <a>
                        
                        
                    </a>
                </div> -->
            </div>
        </div>
    </div>
</div>
<div *ngIf="listMemedPrescriptionStruct == null || listMemedPrescriptionStruct.length == 0">
    <p>Este atendimento não possui prescrições memed!</p>
</div>