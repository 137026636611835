<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-confirm-modal-list totem-cpf-title">
            <h2>Confira seu nome antes de continuar</h2>
         </div>
         
         <div class="body-confirm-modal-list">
            <p><strong>Nome: </strong>{{patient.patientSocialName ? patient.patientSocialName : patient.patientName}}</p>
            <p><strong>Data de Nascimento: </strong>{{patient.birthDate | date:'dd/MM/yyyy'}}</p>
            <p><strong>Nome da Mãe: </strong>{{patient.motherName}}</p>
         </div>

         <div class="footer-confirm-modal-list">
            <button mat-flat-button color="primary" class="btn-block" (click)="confirmPatient()">Confirmar</button>
            <button mat-flat-button color="accent" class="btn-block" (click)="close()">Não sou eu</button>
        </div>
    </div>
</div>
