import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { DataSourceService } from 'src/app/shared/services/API/data-intelligente/data-source.service';
import { LookupDataService } from 'src/app/shared/services/API/data-intelligente/lookup-data.service';
import { DataSourceTypeModel } from 'src/app/shared/services/models/data-intelligence/data-source-type.model';
import { ListDataSourceStruct } from 'src/app/shared/services/structs/data-intelligence/list-data-source.struct';
import { DataSourceDeleteModalComponent } from './data-source-delete-modal/data-source-delete-modal.component';

@Component({
  selector: 'app-data-source-list',
  templateUrl: './data-source-list.component.html',
  styleUrls: ['./data-source-list.component.css']
})
export class DataSourceListComponent implements OnInit {

  constructor(private dataSourceService: DataSourceService,
    private lookupService: LookupDataService,
    private router: Router,
    private alertService: AlertService,
    public dialog: MatDialog) { }
    
    public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.dashboard;
    public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.data_source;
    
    public listDataSource: ListDataSourceStruct[];
    public listDataSourceType: DataSourceTypeModel[];
    public isLoading: boolean;
    public isActive: boolean;
    public searchText: string;
    public idDataSourceType: number[];

  ngOnInit(): void {
    this.isLoading = true;
    this.idDataSourceType = [];
    this.populateDataSourceTypeSelect();
    this.search();
  }

  search(){
    this.isLoading = true;  
    if(this.searchText == "")
    this.searchText = null;
    
    this.dataSourceService.getAll(this.searchText, this.idDataSourceType).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.listDataSource = response.listDataSource;  
    
      this.isLoading = false;    
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  populateDataSourceTypeSelect(){
    this.lookupService.listLookup(false).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        
        return;
      }
      
      this.listDataSourceType = response.listDataSourceType;     
    },
    (error)=>{
      console.log(error)    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  private timeoutKeySearch: any = null;
  
  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }
  
  openModal(idDataSource){
    const dialogRef = this.dialog.open(DataSourceDeleteModalComponent, {
      data: {
        idDataSource: idDataSource
      },
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.deleteDataSource){
        this.search();
      }
    });
  }
}
