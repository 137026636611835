import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { BusinessIntelligenceReportResponse } from '../../responses/business-intelligence/business-intelligence-report.response';
import { BusinessIntelligenceResponse } from '../../responses/business-intelligence/business-intelligence.response';

@Injectable({
  providedIn: 'root'
})
export class BusinessIntelligenceService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getReports(jsonHealthUnit:number[]): Observable<BusinessIntelligenceResponse> {
    let uri = `ReportHealthUnit`

    if(jsonHealthUnit){
      uri = uri + `?jsonHealthUnit=${JSON.stringify(jsonHealthUnit)}`
    }

    return this.httpClient.get<BusinessIntelligenceResponse>(environment.urlApiBusinessIntelligence + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
  public getReport(idReportFunctionHealthUnit:number): Observable<BusinessIntelligenceReportResponse> {
    let uri = `ReportHealthUnit/idReportFunctionHealthUnit/${idReportFunctionHealthUnit}`
    return this.httpClient.get<BusinessIntelligenceReportResponse>(environment.urlApiBusinessIntelligence + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
