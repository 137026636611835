import { MetaDataStruct } from "../../structs/meta-data/meta-data.struct";
import { LinkDeviceTypeSectorStruct } from "../../structs/risk-classification/link-device-type-sector.struct";
import { LinkProtocolSectorStruct } from "../../structs/risk-classification/link-protocol-sector.struct";
import { LinkRoomMeasurerStruct } from "../../structs/risk-classification/link-room-measurer.struct";

export class ProtocolSectorRequest{
    public listProtocolSector: LinkProtocolSectorStruct[];
    public listDeviceTypeSector: LinkDeviceTypeSectorStruct[];
    public listRoomMeasurer: LinkRoomMeasurerStruct[];
    public listMetaData: MetaDataStruct[];
}