import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { MedicineRequest } from '../../requests/pharmacy/medicine.request';
import { GetMedicineResponse } from '../../responses/pharmacy/get-medicine.response';
import { ListMedicineResponse } from '../../responses/pharmacy/list-medicine.response';
import { GrievanceNotificationStruct } from '../../structs/hospital-document/grievance-notification.struct';
import { GrievanceNotificationResponse } from '../../responses/hospital-document/grievance-notification.response';
import { GrievanceReportResponse } from '../../responses/hospital-document/grievance-report.response';

@Injectable({
  providedIn: 'root'
})
export class HospitalDocumentService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getGrievanceNotification(): Observable<GrievanceNotificationResponse> {
    let uri = `GrievanceNotification/`;

    return this.httpClient.get<GrievanceNotificationResponse>(environment.urlApiHospitalDocument + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getAll(): Observable<GrievanceNotificationResponse> {
    let uri = `GrievanceNotification/getAll`;

    return this.httpClient.get<GrievanceNotificationResponse>(environment.urlApiHospitalDocument + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getGrievanceReport(idGrievanceNotification: number, isPermission?: boolean): Observable<GrievanceReportResponse> {
    let uri = `GrievanceNotification/idGrievanceNotification/${idGrievanceNotification}?`;
    if(isPermission)
    uri = uri + `isPermission=${isPermission}&`;

    return this.httpClient.post<GrievanceReportResponse>(environment.urlApiHospitalDocument + uri, null, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}