import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { DeviceTypeEnum } from 'src/app/shared/enum/device-type.enum';
import { PriorityColorEnum } from 'src/app/shared/enum/priority-color.enum';
import { RoomAccessUtilService } from 'src/app/shared/services/room-access-util.service';
import {  UtilsClassificationService } from '../../utils.service';
import { UtilsMeasurerService } from '../flowchart/protocol-v2/utils-measurer.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { WebsocketAttendanceUtilService } from 'src/app/shared/services/websocket-util/websocket-attendance-util.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AttendanceOccupiedRemoveModalComponent } from 'src/app/shared/components/attendance-occupied-remove-modal/attendance-occupied-remove-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { TriageService } from 'src/app/shared/services/API/orchestrator-patient/risk-classification.service';
import { TriageStruct } from 'src/app/shared/services/structs/medical-record/triage.struct';

@Component({
  selector: 'app-flowchart-selection',
  templateUrl: './flowchart-selection.component.html',
  styleUrls: ['./flowchart-selection.component.css']
})
export class FlowchartSelectionComponent implements OnInit, OnDestroy {

  constructor(private utilService: UtilsClassificationService,
    public router: Router,
    private roomAccessUtilService: RoomAccessUtilService,
    private activatedRoute: ActivatedRoute,
    public measurerService: UtilsMeasurerService,
    public alertService: AlertService,
    public websocketAttendanceUtilService: WebsocketAttendanceUtilService,
    private authService: AuthService,
    private dialog: MatDialog,
    private triageService: TriageService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.classification;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.classification_pre_registration;
  public idEpisode: number;
  public idRoom: number;
  public idSector: number;
  public idQueue: number = null;
  public idGravityWhite: PriorityColorEnum = PriorityColorEnum.Branco;
  public idGravity: PriorityColorEnum;
  public isReclassification: boolean;
  public idDeviceType: number;
  public data: any;
  public isNext: boolean = false;
  public isLoading: boolean = false;
  public userLogin: string;
  public userName: string;
  public isAttendanceRemoval: boolean = false;
  public lastTriage: TriageStruct = new TriageStruct();
  public alertTimeDescription: string;
  public healthUnitNameByLastTriage: string;

  ngOnInit(): void {
    this.userLogin = this.authService.getTokenMenu().login;
    this.userName = this.authService.getTokenMenu().userName;
    this.data = this.utilService.getClassificationData();
    if(this.data.idPatient)
      this.getLastTriageOnAlertTime();
    
    if(!this.data.discriminatorAllValues) this.data.discriminatorAllValues = [];
    if(!this.data.discriminatorValues) this.data.discriminatorValues = [];
    this.isReclassification = this.data.reclassification != null;
    this.idGravity = this.data.reclassification != null ? this.data.reclassification.idGravity : null;
    this.idEpisode = this.data.idEpisode;
    this.idDeviceType = this.data.idDeviceType;
    if (!this.data.discriminatorValues && !Array.isArray(this.data.discriminatorValues)) {
      this.data.discriminatorValues = [];
    }

    if ((this.data.idDeviceType == DeviceTypeEnum.Trius || this.data.idDeviceType == DeviceTypeEnum.Berco || this.data.idDeviceType == DeviceTypeEnum.Bluetooth) && Array.isArray(this.data.discriminatorAllValues)) {
      let selectedIndex: number = this.data.discriminatorAllValues.findIndex(c => c.idDiscriminator == this.data.discriminator.idDiscriminator);
      selectedIndex = selectedIndex > -1 ? selectedIndex : this.data.discriminatorAllValues.length + 1;
      this.measurerService.setIndex(selectedIndex);
      this.data.discriminatorsPersist = this.data.discriminatorsPersist == undefined ? [] : this.data.discriminatorsPersist;
      this.measurerService.createReference(this.data.discriminatorValues, this.data.discriminatorsPersist, this.data.discriminatorAllValues, 
        this.data.deviceThermometer, this.data.deviceGlucometer, this.data.deviceOximeter, this.data.devicePressure);
      this.measurerService.restartMeasurer();
    }

    this.idRoom = parseInt(this.activatedRoute.snapshot.paramMap.get('idRoom'));
    this.idSector = parseInt(this.activatedRoute.snapshot.paramMap.get('idSector'));
    this.idQueue = parseInt(this.activatedRoute.snapshot.paramMap.get('idQueue'));  

    if (Number.isInteger(this.idQueue) &&  this.idQueue && this.idQueue > 0 && Number.isInteger(this.idEpisode) &&  this.idEpisode && this.idEpisode > 0 && this.data && this.data.isTelephoneClassification != true) 
      this.startWebsocket();   

  }

  ngOnDestroy() {
    if (this.data.idDeviceType == DeviceTypeEnum.Trius || this.data.idDeviceType == DeviceTypeEnum.Berco || this.data.idDeviceType == DeviceTypeEnum.Bluetooth) {
      this.measurerService.setIndex(-1);
    }
    // clearInterval(this.keepAliveIntervalId);
    let data = this.utilService.getClassificationData();
    data.discriminatorValues = this.data.discriminatorValues;
    this.utilService.updateClassificationData(data);
  }

  getLastTriageOnAlertTime() {
    this.isLoading = true;
    this.triageService.getLastTriageOnAlertTime(this.data.idPatient).subscribe({
      next: (response) => {
        if (response.isError) {
          console.log("error: ", response.errorDescription);
          this.isLoading = false;
          return;
        }
        if(response.triage) {
          this.lastTriage = response.triage;
          this.alertTimeDescription = response.alertTimeDescription;
          this.healthUnitNameByLastTriage = response.healthUnitNameByLastTriage;
          this.data.recurringReturn = true;
          this.data.recurringReturnConfiguredTime = this.alertTimeDescription;
          this.utilService.updateClassificationData(this.data);
        }
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
      }
    });
  }

  selectFlowchart(idFlowchar) {
    this.isNext = true;
    
    if (idFlowchar != 0) {
      this.router.navigate(['/classification/flowchart', { idEpisode: this.idEpisode, idRoom: this.idRoom, idQueue: this.idQueue}]);
    } else {
      this.router.navigate(['/classification/white-patient', { idEpisode: this.idEpisode, idRoom: this.idRoom, idQueue: this.idQueue}]);
    }    
  }

  async restartMeasurers() {
    await this.measurerService.restartMeasurer();
    this.alertService.show('Info', "Medidores reiniciados", AlertType.info);
  }

  //Websocket
  startWebsocket() {
    if (!this.websocketAttendanceUtilService.isConnect())
      this.websocketAttendanceUtilService.connectwebsocketRoom(this.idRoom, this.idQueue, this.idEpisode, this, true, false, this.authService.getToken(), this.userLogin, this.userName);

    this.websocketAttendanceUtilService.setContext(this);
    this.websocketAttendanceUtilService.setFunctions(null, null, this.onRemoveAttendant);         
  }

  onRemoveAttendant(thisParam, res: any){
    thisParam.isAttendanceRemoval = false;
    
    if(res.userLogin != thisParam.userLogin){
      thisParam.isAttendanceRemoval = true;
      
      thisParam.websocketAttendanceUtilService.clearSocket();
      let ref = thisParam.dialog.open(AttendanceOccupiedRemoveModalComponent, { data: { ...res } });
      ref.afterClosed().subscribe(() => {
        thisParam.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          thisParam.router.navigate(['/classification/classification-patient-list', { idSector: thisParam.idSector, idRoom: thisParam.idRoom }]));
      })
    }
  }
}
