import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CallPatientModalComponent } from 'src/app/shared/components/call-patient-modal/call-patient-modal.component';
import { WebsocketService } from 'src/app/shared/services/websocket.service';

@Component({
  selector: 'app-display-standart-template-v2',
  templateUrl: './display-standart-template-v2.component.html',
  styleUrls: ['./display-standart-template-v2.component.css']
})

export class DisplayStandartTemplateComponentV2 implements OnInit, AfterViewInit, OnDestroy {

  constructor(private websocketService: WebsocketService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    public sanitizer: DomSanitizer,
  ) { }

  public date: string;
  public time: string;
  public mainClient: any;
  @Input() public logoString64: string;
  @Input() public panelTime: Date;
  @Input() showAverageWaitingTime: boolean = false;
  @Input() lastHourWaitingTimeAverage: string = null;
  @Output() public keepAlive: EventEmitter<any> = new EventEmitter();
  @Output() public getServerDate: EventEmitter<any> = new EventEmitter();
  public minutesCount: number;
  public sanitezedLogo: any;
  public idDisplay: number;

  public onlineOffline: boolean = navigator.onLine;
  public socketDisconneted: boolean = false;
  private socket: any;
  private timming: boolean = true;
  public clientList: Array<any> = new Array<any>();
  public clientListToCall: Array<any> = new Array<any>();
  public historicalList: Array<any> = new Array<any>();
  public resClientString: string;
  public mainClientString: string;
  public timeIntervalId1;
  public timeIntervalId2;
  public serverTimeId;

  ngOnInit() {
    this.minutesCount = 0;
    this.idDisplay = parseInt(this.activatedRoute.snapshot.paramMap.get('id'));
    this.date = this.getDate();
    this.time = this.getTime();
    this.sanitezedLogo = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${this.logoString64}`);
    this.setMainClient();
    this.setHistoricalList();
    this.timeIntervalId1 = setInterval(() => { this.timeCounter() }, 59000);
    this.timeIntervalId2 = setInterval(() => { this.loopOfCall(); }, 500);
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.startWebsocket();
  }

  ngOnDestroy() {
    if (this.timeIntervalId1)
      clearInterval(this.timeIntervalId2);

    if (this.timeIntervalId1)
      clearInterval(this.timeIntervalId2);

    if (this.socket)
      this.socket.close();

    clearInterval(this.idInterval);
  }

  getTime() {
    return new Date(this.panelTime).getHours() + ':' + (new Date(this.panelTime).getMinutes() < 10 ? '0' : '') + new Date(this.panelTime).getMinutes();
  }

  getDate() {
    return new Date(this.panelTime).getDate() + '/' + (new Date(this.panelTime).getMonth() + 1 < 10 ? '0' : '') + (new Date(this.panelTime).getMonth() + 1) + '/' + new Date(this.panelTime).getFullYear();
  }

  setMainClient() {
    this.mainClient = this.clientList[0];
  }

  setHistoricalList() {
    this.historicalList = this.clientList.filter((c, i) => i !== 0);
  }

  startWebsocket() {
    this.socket = this.websocketService.websocketConnection();
    this.socket.emit('join', `panelId-${this.idDisplay}`)
      .on("callPanel", (res) => this.clientListToCall.push(res))
      .io.on("reconnect", (res) => {
        this.socket.emit('join', `panelId-${this.idDisplay}`)
      });
  }

  private idInterval = setInterval(() => {
    if (this.socket) {
      if (this.socket.connected)
        this.socketDisconneted = false;
      else
        this.socketDisconneted = true;
    }
    else
      this.socketDisconneted = false;

  }, 1000);

  loopOfCall() {
    if (this.clientListToCall.length > 0 && this.timming) {
      this.setNewClient(this.clientListToCall.shift());
      this.timming = false;
    }
  }

  setNewClient(client: any) {
    this.socketDisconneted = false;
    this.resClientString = client.namePacient + ' ' + client.nameRoom + ' ' + client.nameSector + ' ' + client.ticket;
    this.mainClientString = this.mainClient ? this.mainClient.namePacient + ' ' + this.mainClient.nameRoom + ' ' + this.mainClient.nameSector + ' ' + this.mainClient.ticket : '';
    if (this.resClientString !== this.mainClientString) {
      if (this.clientList.length === 5)
        this.clientList.splice(-1, 1);

      this.clientList.unshift({
        idPanel: client.idPanel,
        namePacient: client.namePacient,
        nameRoom: client.nameRoom,
        nameSector: client.nameSector,
        ticket: client.ticket
      });

      this.setMainClient();
      this.setHistoricalList();
    }

    this.openCallPatient(client.namePacient, client.nameRoom, client.nameSector, client.ticket, client.idPanel, client.voiceStringBase64);
  }

  alivePanel() {
    this.keepAlive.emit()
  }

  timeCounter() {
    this.minutesCount++;
    let hours: number = new Date(this.panelTime).getHours();
    let minutes: number = new Date(this.panelTime).getMinutes() + this.minutesCount;
    if (minutes == 60) {
      minutes = 0;
      this.minutesCount = 0;
      this.getServerDate.emit();
      this.date = this.getDate();
      this.time = this.getTime();
    }
    else
      this.time = hours + ':' + (minutes < 10 ? '0' : '') + minutes;

    if (this.onlineOffline !== navigator.onLine)
      this.onlineOffline = navigator.onLine;
  }

  openCallPatient(namePacient, nameRoom, nameSector, ticket, idPanel, voiceStringBase64) {
    let modal = this.dialog.open(CallPatientModalComponent, {
      width: "100%",
      maxWidth: "100%",
      height: "100%",
      data: {
        nameRoom: nameRoom,
        nameSector: nameSector,
        ticket: ticket,
        namePacient: namePacient,
        idPanel: idPanel,
        voiceStringBase64: voiceStringBase64
      },
    });
    modal.afterClosed().subscribe((result) => {
      this.timming = true;
    });
  }
}