import { MedicalPrescriptionCheckStruct } from "./medical-prescription-check.struct";

export class ReadMedicalPrescriptionStruct {
    public idMedicalPrescription: number
    public idMedicine: number;
    public idUsage: number;
    public idAdministrationType: number;
    public quantity: number;
    public idPresentation: number;
    public idFrequency: number;
    public idTreatmentPeriod: number;
    public idDilution: number;
    public idMeasurementUnit: number;
    public idFavoriteMedicine: number;
    public idSubFrequency: number;

    public medicineName: string;
    public usage: string;
    public administrationTypeName: string;
    public administrationType: string;
    public presentationName: string;
    public presentation: string;
    public frequencyName: string;
    public treatmentPeriodName: string;
    public dilutionName: string;
    public dilution: string;
    public observation: string;
    public prescriptionCheckStatus: string;
    public prescriptionEstimativeStatus: string;
    public justification: string;
    public subFrequencyName: string;
    public idDilutionUnit: number;
    public dilutionQuantity: string;
    public dilutionUnitName: string;
    // public numberDrops: number;
    
    public isExecutable: boolean;
    
    public datetimeInclusion: Date;

    public listMedicalPrescriptionCheck: MedicalPrescriptionCheckStruct[];
}