import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReportModalComponent } from 'src/app/shared/components/report-modal/report-modal.component';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-medical-care-reprint-report-modal',
  templateUrl: './medical-care-reprint-report-modal.component.html',
  styleUrls: ['./medical-care-reprint-report-modal.component.css']
})
export class MedicalCareReprintReportModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<MedicalCareReprintReportModalComponent>,
  private alertService: AlertService) { }

  public medicalCareReportString64: string;
  public sickNoteReportString64: string;
  public sadtReportString64: string;
  public prescriptionReportString64: string;
  public externalPrescriptionReportString64: string;
  public isolationReportString64: string;
  public isolationAcknowledgeReportString64: string;


  ngOnInit(): void {
    this.medicalCareReportString64 = this.data.medicalCareReportString64;
    this.sickNoteReportString64 = this.data.sickNoteReportString64;
    this.sadtReportString64 = this.data.sadtReportString64;
    this.prescriptionReportString64 = this.data.prescriptionReportString64;
    this.externalPrescriptionReportString64 = this.data.externalPrescriptionReportString64;
    this.isolationReportString64 = this.data.isolationReportString64;
    this.isolationAcknowledgeReportString64 = this.data.isolationAcknowledgeReportString64;
  }

  clickReport(string64: string) {
    let reportDialog = this.dialog.open(ReportModalComponent, {
      data: {
        reportPdf64: string64,
      },
    });
  }

  clickCancel() {
    this.matDialogRef.close(); 
  }

  close(){
    this.matDialogRef.close(); 
  }
}
