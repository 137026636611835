import { Component, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ReportService } from 'src/app/shared/services/API/orchestrator-patient/report.service';
import { SigaForwardPatientConfirmModalComponent } from './siga-forwar-patient-confirm-modal/siga-forward-patient-confirm-modal.component';

@Component({
  selector: 'app-siga-forward-patient-modal',
  templateUrl: './siga-forward-patient-modal.component.html',
  styleUrls: ['./siga-forward-patient-modal.component.css']
})
export class SigaForwardPatientModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<SigaForwardPatientModalComponent>,
    public reportService: ReportService,
    public router: Router
  ) { }

  public isLoading: boolean;
  public model: UntypedFormGroup;
  public fowardPatient: boolean = false;
  public isFlowSequence: boolean = false;
  public searchText: string;

  public filteredListforward: any[] = [];
  public listForward: any[] = [];

  ngOnInit(): void {
    this.isLoading = false;
    this.isFlowSequence = this.data.isFlowSequence;

    if (this.isFlowSequence && this.data.flowSequence.length > 1) {
      var destinyRoomName = this.data.flowSequence[1].destinyRoomName ? this.data.flowSequence[1].destinyRoomName : "";
      var destinySectorName = this.data.flowSequence[1].destinySectorName ? this.data.flowSequence[1].destinySectorName + " " : "";
      var destinyServiceName = this.data.flowSequence[1].destinyServiceName ? this.data.flowSequence[1].destinyServiceName : " ";
      this.listForward = [{ idFlow: this.data.flowSequence[1].idFlow, locationName: destinyServiceName + destinySectorName + destinyRoomName }];
      this.filteredListforward = [...this.listForward]
    } 
    
    else if (this.data.listFoward) {
      this.listForward = this.data.listFoward;
      this.filteredListforward = [...this.data.listFoward];
    }
  }

  onKeySearch() {
    if (this.isLoading) 
      return;

    setTimeout(() => {
      if (this.searchText) {
        this.filteredListforward = [...this.listForward.filter((item: any) => {
          return item.locationName.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1;
        })];
      } 
      
      else {
        this.filteredListforward = [...this.listForward];
      }
    });
  }

  clickCancel() {
    this.matDialogRef.close({ fowardPatient: this.fowardPatient });
  }

  selectItem(idFlow: number) {

    if (this.isLoading) 
      return;

    this.isLoading = true;  

    this.ConfirmForward(idFlow);

  }

  ConfirmForward(idFlow: number){
    const dialogRef = this.dialog.open(SigaForwardPatientConfirmModalComponent, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.fowardPatient) {
        this.fowardPatient = true;

        this.isLoading = false;

        this.matDialogRef.close({ fowardPatient: true, idFlow: idFlow});

      }

      else {
        this.isLoading = false;
      }

    });
  }
}
