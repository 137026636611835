<cdk-accordion-item *ngFor="let itemMedicalCare of medicalCare; let index = index;"
    #accordionItemModule="cdkAccordionItem" class="profile-register-accordion-item" role="button" tabindex="0"
    [attr.id]="'accordion-header-' + index" [attr.aria-expanded]="accordionItemModule.expanded"
    [attr.aria-controls]="'accordion-body-' + index">
    <div [ngClass]="{'profile-register-accordion-item-header-expanded': accordionItemModule.expanded}"
        class="profile-register-accordion-item-header" (click)="accordionItemModule.toggle()">
        Atendimento Médico - ({{ itemMedicalCare.medicalCare.userName }} - {{
        itemMedicalCare.medicalCare.datetimeInclusion | date:'dd/MM/yy, H:mm' }})
        <span [ngClass]="{'profile-register-accordion-item-description-expanded': accordionItemModule.expanded}"
            class="profile-register-accordion-item-description">
            Clique para {{ accordionItemModule.expanded ? 'fechar' : 'abrir' }}
        </span>
    </div>
    <div class="profile-register-accordion-item-body" role="region"
        [style.display]="accordionItemModule.expanded ? '' : 'none'" [attr.id]="'accordion-body-' + index"
        [attr.aria-labelledby]="'accordion-header-' + index">
        <div class="feature-content">
            <div *ngIf="itemMedicalCare.medicalEvolution" class="row">
                <div class="col-12">
                    <mat-form-field appearance="outline" class="outline-spinner">
                        <mat-label>CID</mat-label>
                        <textarea matInput readonly [value]="itemMedicalCare.cid.join(', ')"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="itemMedicalCare.medicalEvolution" class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Anotações médicas (queixas, história da doença atual, medicamentos em uso, história
                            pregressa)</mat-label>
                        <textarea matInput readonly value="{{itemMedicalCare.medicalCare.medicalNotes}}"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="itemMedicalCare.medicalEvolution" class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Exame físico</mat-label>
                        <textarea matInput readonly
                            value="{{itemMedicalCare.medicalCare.physicalExamination}}"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="itemMedicalCare.medicalEvolution" class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Plano Terapêutico</mat-label>
                        <textarea matInput readonly value="{{itemMedicalCare.medicalCare.therapeuticPlan}}"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="itemMedicalCare.medicalEvolution" class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Hipótese Diagnóstica</mat-label>
                        <textarea matInput readonly
                            value="{{itemMedicalCare.medicalCare.diagnosedHypothesis}}"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-6 col-sm-3 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Temperatura</mat-label>
                        <input type="number" matInput readonly value="{{itemMedicalCare.medicalCare.temperature}}">
                        <span matSuffix>ºC</span>
                    </mat-form-field>
                </div>
                <div class="col-6 col-sm-3 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>FR</mat-label>
                        <input type="number" matInput readonly
                            value="{{itemMedicalCare.medicalCare.respiratoryFrequency}}">
                        <span matSuffix>irpm</span>
                    </mat-form-field>
                </div>
                <div class="col-6 col-sm-3 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>FC</mat-label>
                        <input type="number" matInput readonly value="{{itemMedicalCare.medicalCare.heartRate}}">
                        <span matSuffix>bpm</span>
                    </mat-form-field>
                </div>
                <div class="col-6 col-sm-3 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>GC</mat-label>
                        <input type="number" matInput readonly value="{{itemMedicalCare.medicalCare.glucose}}">
                        <span matSuffix>mg/dL</span>
                    </mat-form-field>
                </div>
                <div class="col-6 col-sm-3 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>PA</mat-label>
                        <input type="text" matInput readonly
                            value="{{itemMedicalCare.medicalCare.bloodPressureSystole && itemMedicalCare.medicalCare.bloodPressureDiastole ? itemMedicalCare.medicalCare.bloodPressureSystole + '/' + itemMedicalCare.medicalCare.bloodPressureDiastole : null}}">
                        <span matSuffix>mmHG</span>
                    </mat-form-field>
                </div>
                <div class="col-6 col-sm-3 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>SatO2</mat-label>
                        <input type="number" matInput readonly value="{{itemMedicalCare.medicalCare.saturation}}">
                        <span matSuffix>%</span>
                    </mat-form-field>
                </div>
            </div>
            <div class="title-header">
                <h4>Isolamento e Atestado</h4>
            </div>
            <div class="row">
                <div class="col-2 col-sm-2 col-md-2">
                    <mat-slide-toggle [disabled]="true" class="indigent-checkbox" [ngModelOptions]="{standalone: true}" [checked]="itemMedicalCare.medicalCare.datetimeEndIsolation">Isolamento</mat-slide-toggle>
                </div>
                <div class="col-6 col-sm-3 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Último dia de Isolamento</mat-label>
                        <input matInput readonly [matDatepicker]="isolationDatePicker" value="{{itemMedicalCare.medicalCare.datetimeEndIsolation}}">
                    </mat-form-field>
                </div>
                <div class="col-6 col-sm-3 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Dias de atestado</mat-label>
                        <input type="number" matInput readonly value="{{itemMedicalCare.medicalCare.sickDaysOffAmount}}">
                        <span matSuffix>dias</span>
                    </mat-form-field>
                </div>
            </div>
            <div class="card-list" *ngIf="itemMedicalCare.medicalPrescription != null && itemMedicalCare.medicalPrescription.length > 0">
                <div class="title-header">
                    <h4>Lista de Prescrições Internas</h4>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of itemMedicalCare.medicalPrescription">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary">{{item.medicineName}}</span>
                                <span class="secundary">Uso: {{item.usage}}</span>
                                <span class="secundary">Via: {{item.administrationTypeName}}</span>
                                <span class="secundary">Apresentação: {{item.presentationName}}</span>
                                <span class="secundary">Frequência: {{item.frequencyName}}</span>
                                <span class="secundary">Periodo trat.: {{item.treatmentPeriodName}}</span>
                            </div>
                            <div class="actions">
                                <a>
                                    <mat-icon aria-hidden="false" aria-label="Ver mais"
                                        (click)="openPrecriptionModalWithMedicine(item)">visibility</mat-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="sub-section"
                *ngIf="itemMedicalCare.medicalEvolution && itemMedicalCare.medicatedSolutionPrescription != null && itemMedicalCare.medicatedSolutionPrescription.length > 0">
                <div class="title-header">
                    <h4>Solução medicamentosa</h4>
                </div>
            </div>
            <div class="card-list"
                *ngIf="itemMedicalCare.medicalEvolution && itemMedicalCare.medicatedSolutionPrescription != null && itemMedicalCare.medicatedSolutionPrescription.length > 0">
                <div class="row row-list">
                    <div class="col-12 col-sm-6 col-md-4"
                        *ngFor="let item of itemMedicalCare.medicatedSolutionPrescription; let index = index;">
                        <div class="card-item">
                            <div class="infos">
                                <div *ngFor="let medicine of item.listMedicine; let indexMedicine = index;">
                                    <span class="primary">{{medicine.medicineName}}</span>
                                </div>
                                <span class="secundary">Uso: {{item.usage}}</span>
                                <span class="secundary">Via: {{item.administrationTypeName}}</span>
                                <span class="secundary">Apresentação: {{item.presentationName}}</span>
                                <span class="secundary">Frequência: {{item.frequencyName}}</span>
                                <span class="secundary">Periodo trat.:
                                    {{item.treatmentPeriodName}}</span>
                            </div>
                            <div class="actions">
                                <a>
                                    <mat-icon aria-hidden="false" aria-label="Ver mais"
                                        (click)="openPrecriptionModalWithMedicatedSolution(item)">visibility</mat-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-list" *ngIf="itemMedicalCare.listExternalPrescription != null && itemMedicalCare.listExternalPrescription.length > 0">
                <div class="title-header">
                    <h4>Lista de Prescrições Externas</h4>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of itemMedicalCare.listExternalPrescription">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary">{{item.description}}</span>
                                <span class="secundary">Quantidade: {{item.quantity}}</span>
                                <span class="secundary">Frequência: {{item.frequency}}</span>
                                <span class="secundary">Observação: {{item.observation}}</span>
                            </div>
                            <div class="actions">
                                <a>
                                    <mat-icon aria-hidden="false" aria-label="Ver mais"
                                        (click)="openExternalPrescriptionModal(item)">visibility</mat-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-list" *ngIf="itemMedicalCare.listMemedPrescriptionStruct != null && itemMedicalCare.listMemedPrescriptionStruct.length > 0">
                <div class="title-header">
                    <h4>Lista de Prescrições Memed</h4>
                </div>
                <div class="row row-list">
                    <div class="col-12 col-sm-6 col-md-5"
                        *ngFor="let item of itemMedicalCare.listMemedPrescriptionStruct; let index = index;">
                        <div class="card-item">
                            <div class="infos">
                                <div class="memed-prescription-info" *ngFor="let medicine of item.listMemedMedicine; let indexMedicine = index;">
                                    <span class="primary">{{indexMedicine + 1}} - {{medicine.name}}</span>
                                    <span class="secundary">Quantidade: {{medicine.quantity}}</span>
                                    <span class="secundary">Unidade: {{medicine.unit}}</span>
                                </div>
                            </div>
                            <!-- <div class="actions">
                                <a>
                                    
                                    
                                </a>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-list" *ngIf="itemMedicalCare.medicalProcedure != null && itemMedicalCare.medicalProcedure.length > 0">
                <div class="title-header">
                    <h4>Lista de Procedimentos </h4>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of itemMedicalCare.medicalProcedure">
                        <div class="card-item" *ngIf="item.idMedicalCare != null">
                            <div class="infos">
                                <span class="primary">{{item.procedureName}}</span>
                                <span class="secundary">Observação do médico: {{item.observation}}</span>
                                <span class="secundary">Solicitação: {{item.userName}}</span>
                            </div>
                            <div class="actions">
                                <a>
                                    <mat-icon aria-hidden="false" aria-label="Ver mais"
                                        (click)="openProcedureModal(item)">visibility</mat-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-list" *ngIf="itemMedicalCare.medicalCareSadt != null && itemMedicalCare.medicalCareSadt.length > 0">
                <div class="title-header">
                    <h3>Lista de Exames</h3>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of itemMedicalCare.medicalCareSadt">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary">{{item.typeSadtName}}</span>
                                <span class="secundary" [ngClass]="{'exame-entregue': item.idStatus == 2}">{{item.statusName}}</span>
                                <span class="secundary">{{item.placeName}}</span>
                                <span class="secundary">{{item.datetimeInclusion | date:'dd/MM/yy, H:mm'}}</span>
                            </div>
                            <div class="actions">
                                <a>
                                    <mat-icon aria-hidden="false" aria-label="Ver mais"
                                        (click)="openSadtModal(item)">visibility</mat-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</cdk-accordion-item>