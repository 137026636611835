import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ScheduleService } from 'src/app/shared/services/API/schedule/schedule.service';

@Component({
  selector: 'app-schedule-delete-modal',
  templateUrl: './schedule-delete-modal.component.html',
  styleUrls: ['./schedule-delete-modal.component.css']
})
export class ScheduleDeleteModalComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ScheduleDeleteModalComponent>,
    private scheduleService: ScheduleService,
    private alertService: AlertService
  ) { }

  public isLoading: boolean = false;

  ngOnInit(): void {
  }

  close() {
    this.matDialogRef.close({ deleteSchedule: false });
  }

  clickDelete() {
    this.isLoading = true;

    this.scheduleService.deleteSchedule(this.data.idSchedule).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Agenda excluído com sucesso!", AlertType.success);
        this.matDialogRef.close({ deleteSchedule: true });
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  clickCancel() {
    this.matDialogRef.close({ deleteSchedule: false });
  }
}