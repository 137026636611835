export class ReadExternalPrescriptionStruct
{   
    public idExternalPrescription: number

    public description: string;

    public quantity: number;
    
    public frequency: string;

    public observation: string;

    public idMeasurementUnit: number;

    public measurementUnitName: string;
}