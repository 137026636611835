import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { BatchLookupService } from 'src/app/shared/services/API/batch/batch-lookup.service';
import { BatchRequestService } from 'src/app/shared/services/API/batch/batch-request.service';
import { BatchType } from 'src/app/shared/services/models/batch/batch-type.model';
import { CreateBatchRequest } from 'src/app/shared/services/requests/batch/create-batch.request';
import { GetHealthUnitIbgeService } from 'src/app/shared/services/API/medical-record/get-health-unit-ibge.service';
import { HealthUnitLocationStruct } from 'src/app/shared/services/structs/medical-record/health-unit-location.struct';

@Component({
  selector: 'app-batch-register',
  templateUrl: './batch-register.component.html',
  styleUrls: ['./batch-register.component.css']
})
export class BatchRegisterComponent implements OnInit {

  constructor(
    private batchRequestService: BatchRequestService,
    private batchLookupService: BatchLookupService,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private router: Router,
    private getHealthUnitIbgeService: GetHealthUnitIbgeService,
  ) { }

  public isFirstLoading: boolean;
  public isLoading: boolean;
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.master;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.master_batch;

  public listBatchType: BatchType[];
  public model: UntypedFormGroup;
  public healthUnitLocationStruct: HealthUnitLocationStruct;

  ngOnInit(): void {
    this.isLoading = true;
    this.isFirstLoading = true;
    this.getLookUpData();
    this.getHealthUnit();

    this.model = this.formBuilder.group({
      datetimeBatchBegin: [, [Validators.required]],
      datetimeBatchEnd: [, [Validators.required]],
      idBatchType: [, [Validators.required]],
      description: ['']
    });
  }

  submit() {
    if(this.model.invalid || this.isLoading)
      return;

    if(!this.healthUnitLocationStruct.codIbge){
      this.alertService.show('Aviso', "Essa uniade não tem um código IBGE especificado para a cidade, entre em contato com a Tolife para fazer a configuração.", AlertType.warning);
      return;
    }

    this.isLoading = true;

    let datetimeBatchBegin: Date = this.model.get('datetimeBatchBegin').value;
    let datetimeBatchEnd: Date = this.model.get('datetimeBatchEnd').value;
    let request: CreateBatchRequest = new CreateBatchRequest();

    request.datetimeBatchBegin = datetimeBatchBegin?.toISOString();
    request.datetimeBatchEnd = datetimeBatchEnd?.toISOString();
    request.idBatchType = this.model.get('idBatchType').value;
    request.description = this.model.get('description').value;

    this.createBatchRequest(request);
  }

  createBatchRequest(request: CreateBatchRequest) {
    this.batchRequestService.createBatch(request).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.isLoading = false;
      this.router.navigate(['/master/batch']);
    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  getLookUpData() {
    this.batchLookupService.getBatchLookup().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }

      this.listBatchType = response.listBatchType;
      this.isFirstLoading = false;
      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  getHealthUnit() {
    this.getHealthUnitIbgeService.getHealthUnit().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.healthUnitLocationStruct = response.healthUnitLocationStruct;
    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

}
