import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SpSadtFieldsMaxLengthEnum } from 'src/app/shared/enum/private-billing/sp-sadt-fields-max-length.enum';
import { ProcedureService } from 'src/app/shared/services/API/private-billing/procedure.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { AttendanceAspect } from 'src/app/shared/services/models/private-billing/attendance-aspect.model';
import { TerminologyGroup } from 'src/app/shared/services/models/private-billing/terminology-group.model';
import { GetProceduresRequest } from 'src/app/shared/services/requests/private-billing/get-procedures.request';
import { HealthcareAgreementStruct } from 'src/app/shared/services/structs/private-billing/healthcare-agreement.struct';
import { ProcedureStruct } from 'src/app/shared/services/structs/private-billing/procedure.struct';
import { SpSadtRequestedProcedureStruct } from 'src/app/shared/services/structs/private-billing/sp-sadt-requested-procedure.struct';
@Component({
  selector: 'app-sp-sadt-requested-procedure-form-history',
  templateUrl: './sp-sadt-requested-procedure-form-history.component.html',
  styleUrls: ['./sp-sadt-requested-procedure-form-history.component.css']
})
export class SpSadtRequestedProcedureFormHistoryComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    public procedureService: ProcedureService,
    private alertService: AlertService,
  ) { }

  @Input() listSpSadtRequestedProcedure: SpSadtRequestedProcedureStruct[] = [];
  @Input() tussTerminologyGroupMap: Map<number, TerminologyGroup>;
  @Input() editingIndex: number;
  @Input() idSpSadtGuide: number = null;
  @Input() isEditingAllowed: boolean = null;
  @Input() listProcedure: ProcedureStruct[] = [];
  @Input() healthcareAgreementStruct: HealthcareAgreementStruct;
  @Output() changeSpSadtRequestedProcedure = new EventEmitter<any>();

  public model: FormGroup;
  public newProcedure: SpSadtRequestedProcedureStruct = new SpSadtRequestedProcedureStruct();
  public editSpSadtRequestedProcedure: SpSadtRequestedProcedureStruct = new SpSadtRequestedProcedureStruct();

  public spSadtFieldsMaxLengthEnum: typeof SpSadtFieldsMaxLengthEnum = SpSadtFieldsMaxLengthEnum;

  public isLoading: boolean = false;
  public showAdditionalFields: boolean = false;
  public isEditing: boolean = false;
  public addRequestedProcedure: boolean = false;//talvez não
  public alreadyPopulate: boolean = false;

  public listSpecialCoverage: AttendanceAspect[] = [];

  private timeoutKeySearch: any = null;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      idTerminologyGroup: ['', [Validators.required]],
      procedure: [{ value: '', disabled: true }, [Validators.required]],
      procedureDescription: [{ value: '', disabled: true }],
      requestedQuantityProcedureServiceItem: ['', [Validators.required, Validators.maxLength(SpSadtFieldsMaxLengthEnum.requestedQuantityProcedureServiceItem)]],
      authorizedQuantityProcedureServiceItem: ['', [Validators.required, Validators.maxLength(SpSadtFieldsMaxLengthEnum.authorizedQuantityProcedureServiceItem)]],
      //24 - 25 - 26 - 27 - 28
    });
  }

  ngOnDestroy() {
  }

  submit() {
    this.addRequestedProcedure = false;
    if (this.isValidNewRule()) {
      if (this.editingIndex > -1) {
        const editedItem = this.listSpSadtRequestedProcedure[this.editingIndex];
        editedItem.idTerminologyGroup = this.model.get('idTerminologyGroup').value;
        editedItem.terminologyGroupCode = this.tussTerminologyGroupMap.get(this.model.get('idTerminologyGroup').value).terminologyGroupCode;
        editedItem.procedure = this.model.get('procedure').value;
        editedItem.requestedQuantityProcedureServiceItem = this.model.get('requestedQuantityProcedureServiceItem').value;
        editedItem.authorizedQuantityProcedureServiceItem = this.model.get('authorizedQuantityProcedureServiceItem').value;
        editedItem.idPriceProcedureHealthcare = this.model.get('procedure').value.idLastPriceProcedureHealthcare;
        this.editingIndex = -1;
      }
      else {
        const newSpSadtRequestedProcedure = new SpSadtRequestedProcedureStruct();
        newSpSadtRequestedProcedure.idTerminologyGroup = this.model.get('idTerminologyGroup').value;
        newSpSadtRequestedProcedure.terminologyGroupCode = this.tussTerminologyGroupMap.get(this.model.get('idTerminologyGroup').value).terminologyGroupCode;
        newSpSadtRequestedProcedure.procedure = this.model.get('procedure').value;
        newSpSadtRequestedProcedure.idPriceProcedureHealthcare = this.model.get('procedure').value.idLastPriceProcedureHealthcare;
        newSpSadtRequestedProcedure.requestedQuantityProcedureServiceItem = this.model.get('requestedQuantityProcedureServiceItem').value;
        newSpSadtRequestedProcedure.authorizedQuantityProcedureServiceItem = this.model.get('authorizedQuantityProcedureServiceItem').value;

        this.listSpSadtRequestedProcedure.push(newSpSadtRequestedProcedure);
      }

      this.model.get('procedure').disable();
      this.listProcedure = null;
      this.isEditing = false;
      this.reset();

      this.onCreateOrUpdate();
    }
  }

  onCreateOrUpdate() {
    this.showAdditionalFields = false;
    this.changeSpSadtRequestedProcedure.emit({ listSpSadtRequestedProcedure: this.listSpSadtRequestedProcedure });
  }

  isValidNewRule(): boolean {
    return this.model.get('idTerminologyGroup').value &&
      this.model.get('procedure').value &&
      this.model.get('procedureDescription').value &&
      this.model.get('requestedQuantityProcedureServiceItem').value &&
      this.model.get('authorizedQuantityProcedureServiceItem').value;
  }

  async selectTable(event: any) {
    let idTerminologyGroup = this.model.get('idTerminologyGroup').value;
    await this.getProcedures("", idTerminologyGroup);
  }

  async getProcedures(key: string, idTerminologyGroup: number, idProcedure: number = null) {
    this.isLoading = true;
    let request: GetProceduresRequest = {
      key: key,
      idHealthcareAgreement: this.healthcareAgreementStruct.idHealthcareAgreement,
      idTerminologyGroup: idTerminologyGroup,
      isCreate: this.idSpSadtGuide == null ? true : false,
      listIdProcedure: Number.isSafeInteger(idProcedure) ?  [idProcedure] : []
    };

    this.procedureService.GetByTerminologyGroup(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listProcedure = response.listProcedureStruct;
        if (this.isEditing && !this.alreadyPopulate)
          this.populateForm();
        this.model.get('procedure').enable();
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  cancelEdit() {
    this.editingIndex = -1;
    this.reset();
    this.showAdditionalFields = false;
  }

  deleteItem(spSadtRequestedProcedure: SpSadtRequestedProcedureStruct) {
    const index = this.listSpSadtRequestedProcedure.indexOf(spSadtRequestedProcedure);
    if (index == -1) {
      console.error("Objeto não encontrado na lista:", spSadtRequestedProcedure);
    }

    if (index === this.editingIndex)
      this.cancelEdit();

    this.listSpSadtRequestedProcedure.splice(index, 1);
    this.onCreateOrUpdate();
  }

  editItem(spSadtRequestedProcedure: SpSadtRequestedProcedureStruct) {
    const index = this.listSpSadtRequestedProcedure.indexOf(spSadtRequestedProcedure);

    if (index === -1)
      console.error("Objeto não encontrado na lista:", spSadtRequestedProcedure);

    this.editSpSadtRequestedProcedure = spSadtRequestedProcedure;
    this.editingIndex = index;
    this.isEditing = true;
    this.alreadyPopulate = false;
    this.getProcedures('', spSadtRequestedProcedure.idTerminologyGroup, this.editSpSadtRequestedProcedure.procedure?.idProcedure);
    this.showAdditionalFields = true;
  }

  populateForm() {
    this.model.get('idTerminologyGroup').setValue(this.editSpSadtRequestedProcedure.idTerminologyGroup);
    this.model.get('procedure').setValue(this.listProcedure.find(x => x.idProcedure == this.editSpSadtRequestedProcedure.procedure.idProcedure));
    this.model.get('requestedQuantityProcedureServiceItem').setValue(this.editSpSadtRequestedProcedure.requestedQuantityProcedureServiceItem);
    this.model.get('authorizedQuantityProcedureServiceItem').setValue(this.editSpSadtRequestedProcedure.authorizedQuantityProcedureServiceItem);
    this.model.get('procedureDescription').setValue(this.editSpSadtRequestedProcedure.procedure.terminology.terminologyNameTuss);
    this.alreadyPopulate = true;
  }

  selectProcedure(event: any) {
    if (event.descriptionProcedure !== null)
      this.model.get('procedureDescription').setValue(event.terminology.terminologyNameTuss);
  }

  onKey(event: any) {
    let idTerminologyGroup = this.model.get('idTerminologyGroup').value;
    clearTimeout(this.timeoutKeySearch);

    if (event) {
      var $this = this;
      this.timeoutKeySearch = setTimeout(function () {
        if (event.keyCode != 13)
          $this.getProcedures(event, idTerminologyGroup,  $this.model.get('procedure').value?.idProcedure);
      },
        1000);
    }
  }

  reset() {
    this.listProcedure = []
    this.model.reset();
  }
}