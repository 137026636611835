<div class="feature-content">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12">
            <mat-label>
                <strong>Prescrições internas: </strong>
                <app-presciption-list [listPrescription]="completeMedicalCare.medicalPrescription" 
                [patientName]="patientName" [birthDate]="birthDate" [actions]="false"></app-presciption-list>
            </mat-label>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12">
            <mat-label>
                <strong>Prescrições externas: </strong>
                <app-external-prescription-list [listPrescription]="completeMedicalCare.listExternalPrescription" ></app-external-prescription-list>
            </mat-label>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12">
            <mat-label>
                <strong>Prescrições externas Memed: </strong>
                <app-memed-prescription-list [listMemedPrescriptionStruct]="completeMedicalCare.listMemedPrescriptionStruct"></app-memed-prescription-list>
            </mat-label>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12">
            <mat-label>
                <strong>Procedimentos: </strong>
                <app-procedure-list [listProcedure]="completeMedicalCare.listMedicalProcedure" 
                [patientName]="patientName" [birthDate]="birthDate" [actions]="false"></app-procedure-list>
            </mat-label>
        </div>
    </div>
    <div *ngIf="medicalEvolution" class="row">
        <div class="col-12 col-sm-12 col-md-12">                                
            <mat-label>
                <strong>Anotações medicas:</strong>
                <p>{{medicalCare.medicalNotes}}</p> 
            </mat-label>                          
        </div>
    </div>
    <div *ngIf="medicalEvolution" class="row">
        <div class="col-12 col-sm-12 col-md-12">
            <mat-label>
                <strong>Exame físico: </strong>
                <p>{{medicalCare.physicalExamination}}</p>
            </mat-label>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12">                                
            <mat-label>
                <strong>Sinais vitais :</strong>
            </mat-label>                          
        </div>
    </div>
    <div class="row">
        <div class="col-6 col-sm-3 col-md-2">
            <mat-form-field appearance="outline">
                <mat-label>Temperatura</mat-label>
                <input type="number" readonly matInput value="{{medicalCare.temperature}}">
                <span matSuffix>ºC</span>
            </mat-form-field>
        </div>
        <div class="col-6 col-sm-3 col-md-2">
            <mat-form-field appearance="outline">
                <mat-label>FR</mat-label>
                <input type="number" readonly matInput value="{{medicalCare.respiratoryFrequency}}">
                <span matSuffix>irpm</span>
            </mat-form-field>
        </div>
        <div class="col-6 col-sm-3 col-md-2">
            <mat-form-field appearance="outline">
                <mat-label>FC</mat-label>
                <input type="number" readonly matInput value="{{medicalCare.heartRate}}">
                <span matSuffix>bpm</span>
            </mat-form-field>
        </div>
        <div class="col-6 col-sm-3 col-md-2">
            <mat-form-field appearance="outline">
                <mat-label>GC</mat-label>
                <input type="number" readonly matInput value="{{medicalCare.glucose}}">
                <span matSuffix>mg/dL</span>
            </mat-form-field>
        </div>
        <div class="col-6 col-sm-3 col-md-2">
            <mat-form-field appearance="outline">
                <mat-label>PA</mat-label>
                <input type="text" readonly matInput value="{{medicalCare.bloodPressureDiastole}}/{{medicalCare.bloodPressureSystole}}">
                <span matSuffix>mmHG</span>
            </mat-form-field>
        </div>
        <div class="col-6 col-sm-3 col-md-2">
            <mat-form-field appearance="outline">
                <mat-label>SatO2</mat-label>
                <input type="number" readonly matInput value="{{medicalCare.saturation}}">
                <span matSuffix>%</span>
            </mat-form-field>
        </div>
    </div>
</div>          

