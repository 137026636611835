<app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>

<div class="white-body">
    <h1 class="border-green-left-title">Seleção de Protocolo</h1>
    <div class="row">
        <div *ngFor="let array of matrixProtocol" class="col-md-12">
            <div class="row">
                <div *ngFor="let item of array" class="col-md-12">
                    <a mat-button (click)="selectProtocol(item.idProtocol)" type="button" class="fluxograma-button">
                        {{item.protocolName}}
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>