<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-confirm-modal-list">
            <h2 *ngIf="this.data.patient.idEpisodeStatus == activeEpisodeStatus">Tem certeza que deseja buscar o paciente {{this.data.patient.patientName}}, episódio {{this.data.patient.idEpisode}}? </h2>
            <h2 *ngIf="this.data.patient.idEpisodeStatus != activeEpisodeStatus">Tem certeza que deseja buscar o paciente {{this.data.patient.patientName}}, episódio {{this.data.patient.idEpisode}} e ativar novamente esse episódio?</h2>
         </div>
         <div class="body-confirm-modal-list" >
            <strong *ngIf="this.data.patient.idEpisodeStatus == activeEpisodeStatus">Caso o paciente esteja em atendimento ou aguardando para ser atendido em outra fila, <span style="color: red">o paciente sairá da fila onde se encontra e poderá perder o atendimeto.</span></strong>
            <strong *ngIf="this.data.patient.idEpisodeStatus != activeEpisodeStatus">Caso o paciente seja puxado novamente, ele será adicionado a fila de atendimento.</strong>
         </div>
         <div class="col-12">
            <mat-slide-toggle *ngIf="this.data.patient.idEpisodeStatus == activeEpisodeStatus"
                class="toggle-check" 
                [ngModelOptions]="{standalone: true}" 
                [(ngModel)]="this.isCheckToggle">
                <strong>Tenho absoluta certeza que quero puxar o paciente que está ativo em outra fila.</strong>
            </mat-slide-toggle>
            <mat-slide-toggle *ngIf="this.data.patient.idEpisodeStatus != activeEpisodeStatus"
                class="toggle-check" 
                [ngModelOptions]="{standalone: true}" 
                [(ngModel)]="this.isCheckToggle">
                <strong>Tenho absoluta certeza que quero reativar o paciente inativo/finalizado.</strong>
            </mat-slide-toggle>
        </div>
         <div class="footer-confirm-modal-list">
            <button mat-flat-button color="primary" class="btn-block" (click)="clickConfirm()" [disabled]="!isCheckToggle">
                <span *ngIf="isLoading == false">Confirmar Atendimento</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
            <button mat-flat-button color="accent" class="btn-block"(click)="clickCancel()">Cancelar</button>
        </div>
    </div>
</div>