import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { CompleteMedicalCareStruct } from 'src/app/shared/services/structs/orchestrator-patient/complete-medical-care.struct';
import { ReadMedicalPrescriptionStruct } from 'src/app/shared/services/structs/orchestrator-patient/read-medical-prescription.struct';
import { LookupMedicalCareResponse } from 'src/app/shared/services/responses/medical-record/lookup-medical-care-response';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { MedicalCareModel } from 'src/app/shared/services/models/medical-record/medical-care.model';
import { MedicalPrescriptionService } from 'src/app/shared/services/API/medical-record/medical-prescription.service';
import { ExternalPrescriptionModalComponent } from 'src/app/shared/components/external-prescription-modal/external-prescription-modal.component';
import { ReadExternalPrescriptionStruct } from 'src/app/shared/services/structs/orchestrator-patient/read-external-prescription.struct';
import { UpdateExternalPrescriptionModalComponent } from './update-external-prescription-modal/update-external-prescription-modal.component';
import { GetCompleteMultiProfessionalConfigStruct } from 'src/app/shared/services/structs/multi-professional/get-complete-multi-professional-config.struct';
import { LookupMedicalCareService } from 'src/app/shared/services/API/medical-record/lookup-medical-care.service';
import { ExternalPrescriptionStruct } from 'src/app/shared/services/structs/medical-record/external-prescription.struct';


@Component({
  selector: 'app-non-standard-prescriptions-block',
  templateUrl: './non-standard-prescriptions-block.component.html',
  styleUrls: ['./non-standard-prescriptions-block.component.css']
})
export class NonStandardPrescriptionsBlockComponent implements OnInit {

  @Input() completeMultiprofessionalConfig: GetCompleteMultiProfessionalConfigStruct;
  @Input() listExternalPrescription: ExternalPrescriptionStruct[];
  @Output() changeExternalPrescription = new EventEmitter<any>();
  
  constructor(
    private alertService: AlertService,
    private dialog: MatDialog,
    private maskService: MaskService,
    private medicalPrescriptionService: MedicalPrescriptionService,
    public lookupMedicalCareService: LookupMedicalCareService,
  ) { }

  public isLoading: boolean = false;
  public isFirstLoading: boolean;
  
  public lookupMedicalCareResponse: LookupMedicalCareResponse;
  public newMedicalCare: MedicalCareModel;
  public listPrescription: ReadMedicalPrescriptionStruct[] = [];
 
  public masks: Masks;
  
  public readonly: boolean = false;
  public idMedicalCare: number;

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
   
    this.isFirstLoading = true;
    this.getLookupMedicalCare();
  }

  openExternalPrescriptionModal(index: number, prescription: ReadExternalPrescriptionStruct, readOnly: boolean) {
    if (this.readonly) {
      this.openUpdateExternalPrescriptionModal(index, prescription);
    } else {
      this.openDefaultExternalPrescriptionModal(prescription, readOnly);
    }
  }

  openUpdateExternalPrescriptionModal(index: number, prescription: ReadExternalPrescriptionStruct) {
    const dialogRef = this.dialog.open(UpdateExternalPrescriptionModalComponent, {
      data: {
        prescription,
        idMedicalCare: this.idMedicalCare,
        lookupMedicalCareResponse: this.lookupMedicalCareResponse,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (index >= 0) {
          this.listExternalPrescription.splice(index, 1);
        }
        this.listExternalPrescription.push(result.newMedicalPrescription);
        this.changeExternalPrescription.emit(this.listExternalPrescription);
      }
      this.isFirstLoading = false;
    });
  }

  openDefaultExternalPrescriptionModal(prescription: ReadExternalPrescriptionStruct, readOnly: boolean) {
    const dialogRef = this.dialog.open(ExternalPrescriptionModalComponent, {
      data: {
        prescription,
        readOnly,
        lookupMedicalCareResponse: this.lookupMedicalCareResponse,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.newExternalPrescription) {
        let newExternalPrescriptionStruct: ExternalPrescriptionStruct = new ExternalPrescriptionStruct();
        newExternalPrescriptionStruct.frequency = result.newExternalPrescription.frequency;
        newExternalPrescriptionStruct.description = result.newExternalPrescription.description;
        newExternalPrescriptionStruct.quantity = result.newExternalPrescription.quantity;
        newExternalPrescriptionStruct.observation = result.newExternalPrescription.observation;
        newExternalPrescriptionStruct.idMeasurementUnit = result.newExternalPrescription.idMeasurementUnit;
        newExternalPrescriptionStruct.measurementUnitName = result.newExternalPrescription.measurementUnitName;

        this.listExternalPrescription.push(newExternalPrescriptionStruct);
        this.changeExternalPrescription.emit(this.listExternalPrescription);
      }
      this.isFirstLoading = false;
    });
  }

  deletePrescription(index: number) {
    if (this.readonly) {

      if (this.isLoading)
        return;

      this.isLoading = true;
      this.medicalPrescriptionService.deletePresciption(this.listPrescription[index].idMedicalPrescription).subscribe((response) => {
        this.isLoading = false;
        if (response.isError) {
          this.alertService.show("Erro", response.errorDescription, AlertType.error);
          return;
        }
        this.alertService.show("Sucesso", "Prescrição deletada com sucesso", AlertType.success);
        this.listPrescription.splice(index, 1);
      });
    } else {
      this.listPrescription.splice(index, 1);
    }
  }

  deleteExternalPrescription(index: number) {
    if (this.readonly) {

      if (this.isLoading)
        return;

      this.isLoading = true;
      this.medicalPrescriptionService.deletePresciption(this.listExternalPrescription[index].idExternalPrescription).subscribe((response) => {
        this.isLoading = false;
        if (response.isError) {
          this.alertService.show("Erro", response.errorDescription, AlertType.error);
          return;
        }
        this.alertService.show("Sucesso", "Prescrição deletada com sucesso", AlertType.success);
        this.listExternalPrescription.splice(index, 1);
      });
      this.changeExternalPrescription.emit(this.listExternalPrescription);
    } else {
      this.listExternalPrescription.splice(index, 1);
    }
  }

  getLookupMedicalCare() {
    this.lookupMedicalCareService.getLookupMedicalCare().subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }

      this.lookupMedicalCareResponse = response;
    },
      (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }
}