<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <a class="back-link" routerLink="/master/user">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Usuários
        </a>
        <h1>Cadastrar usuários</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <form appFormscroll class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="title-header">
                    <h1>Dados pessoais</h1>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome</mat-label>
                            <input matInput type="text" formControlName="name">
                            <mat-error *ngIf="model.get('name').invalid">Informe o nome</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>CPF</mat-label>
                            <input [readonly]="this.isUpdate" matInput type="text" formControlName="cpf"
                                [mask]="masks.cpf">
                            <mat-error *ngIf="model.get('cpf').invalid">Informe o CPF</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Telefone</mat-label>
                            <input matInput type="text" formControlName="phone" [mask]="masks.phone">
                            <mat-error *ngIf="model.get('phone').invalid">Informe o Telefone</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Profissão</mat-label>
                            <mat-select formControlName="professional"
                                (selectionChange)="onProfessionalChange($event.value)">
                                <mat-option *ngFor="let item of listProfession" value="{{item.idProfession}}">
                                    {{item.professionName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <div class="row">
                            <div class="col-6">
                                <mat-form-field appearance="outline">
                                    <mat-label>Número do Conselho</mat-label>
                                    <input matInput type="text" formControlName="council">
                                    <mat-error *ngIf="model.get('council').invalid">Informe o número do
                                        Conselho</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field appearance="outline">
                                    <mat-label>UF do conselho</mat-label>
                                    <mat-select formControlName="idStateCouncil">
                                        <mat-option *ngFor="let item of listState" value="{{item.idState}}">
                                            {{item.stateInitials}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="model.get('idStateCouncil').invalid">Informe a UF do número do
                                        Conselho</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <div class="form-field-toggle">
                            <mat-label>Assinatura Digital</mat-label>
                            <mat-slide-toggle formControlName="haveSignature"></mat-slide-toggle>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 col-sm-3 col-md-4">
                        <app-select [preSelectedlist]="preSelectCbo" (selectedValue)="addItemCbo($event)"
                            [multiple]="false" [url]="urlListCbo" [idField]="'idCbo'" [nameField]="'codeWithName'"
                            [labelField]="'CBO'"></app-select>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>CNS</mat-label>
                            <input matInput type="text" formControlName="cns">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>CNPJ</mat-label>
                            <input matInput type="text" formControlName="cnpj" [mask]="masks.cnpj">
                        </mat-form-field>
                    </div>
                </div>

                <ng-container *ngIf="hasICPBrasilHealthUnit && isMedicProfessional">
                    <div class="title-header">
                        <h1>Assinatura digital ICP-Brasil</h1>
                    </div>
                    <div class="row">
                        <div class="col-6 col-sm-6 col-md-3">
                            <div class="form-field-toggle">
                                <mat-label>Usar assinatura digital em nuvem</mat-label>
                                <mat-slide-toggle formControlName="hasICPBrasilCertificate"
                                    (change)="checkICPBrasilCloud($event)"></mat-slide-toggle>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3" *ngIf="model.get('hasICPBrasilCertificate').value">
                            <mat-form-field appearance="outline">
                                <mat-label>Identificador Público certificado A3</mat-label>
                                <input matInput type="text" formControlName="certificateUUID">
                                <mat-error *ngIf="model.get('certificateUUID').invalid">Informe o identificador publico
                                    do seu certificado A3.</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 col-sm-6 col-md-3">
                            <div class="form-field-toggle">
                                <mat-label>Usar assinatura digital via token físico</mat-label>
                                <mat-slide-toggle formControlName="hasIcpBrasilTokenCertificate"
                                    (change)="checkICPBrasilToken($event)"></mat-slide-toggle>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <div class="title-header">
                    <h1>Dados para acesso</h1>
                </div>

                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Matrícula</mat-label>
                            <input matInput type="text" formControlName="login">
                            <mat-error *ngIf="model.get('login').invalid">Informe a matrícula</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Perfil</mat-label>
                            <mat-select formControlName="perfil" multiple>
                                <mat-option *ngFor="let item of listProfile" value="{{item.idProfile}}">
                                    {{item.profileName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Email</mat-label>
                            <input matInput type="email" formControlName="email">
                            <mat-error *ngIf="model.get('email').invalid">Informe a e-mail</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Status</mat-label>
                            <mat-select formControlName="status">
                                <mat-option value="true">Ativo</mat-option>
                                <mat-option value="false">Inativo</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">

                        <mat-form-field appearance="outline">
                            <mat-label>Senha Temporária</mat-label>
                            <input [disabled]="!isloginAndPassword" matInput type="password"
                                formControlName="temporaryPassword">
                            <mat-error *ngIf="model.get('temporaryPassword').invalid">Informe a Senha
                                Temporária</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <p [ngStyle]="{ 'color': model.get('temporaryPassword').invalid ? '#fc0303' : '#000000' }">
                            Use entre oito ou mais caracteres, com uma combinação de letras maiúsculas, letras
                            minúsculas, números e caracteres especiais.</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-2">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <a mat-flat-button type="button" routerLink="/master/user" color="accent" class=" btn-block">
                            Cancelar
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>

</div>