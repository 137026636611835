import { NgModule } from '@angular/core';
import { TotemOptionsComponent } from './totem-options.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TotemCustomListModule } from './components/totem-custom-list/totem-custom-list.module';
import { TotemPriorityListModule } from './components/totem-priority-list/totem-priority-list.module';
import { CommonModule } from '@angular/common';
import { CpfTotemModule } from './components/cpf-totem/cpf-totem.module';
import { MatDialogModule } from '@angular/material/dialog';



@NgModule({
  declarations: [TotemOptionsComponent],
  exports: [TotemOptionsComponent],
  imports: [
    CommonModule,
    SharedModule,
    TotemCustomListModule,
    TotemPriorityListModule,
    CpfTotemModule,
    MatDialogModule
  ]
})
export class TotemOptionsModule { }
