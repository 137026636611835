import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { AccidentIndication } from 'src/app/shared/services/models/private-billing/accident-indication.model';
import { AppointmentType } from 'src/app/shared/services/models/private-billing/appointment-type.model';
import { AttendanceAspect } from 'src/app/shared/services/models/private-billing/attendance-aspect.model';
import { AttendanceType } from 'src/app/shared/services/models/private-billing/attendance-type.model';
import { ProviderIdentityType } from 'src/app/shared/services/models/private-billing/provider-identity-type.model';
import { TissBillingRequest } from 'src/app/shared/services/requests/private-billing/tiss-billing.request';
import { TissBillingService } from 'src/app/shared/services/API/private-billing/tiss-billing.service';
import { TissXml } from 'src/app/shared/services/models/private-billing/tiss-xml.model';
import { TissBilling } from 'src/app/shared/services/models/private-billing/tiss-billing.model';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { ProviderIdentityTypeEnum } from 'src/app/shared/enum/private-billing/provider-identity-type.enum';
import { TerminologyOrigin } from 'src/app/shared/services/models/private-billing/terminology-origin.model';
import { LookupService } from 'src/app/shared/services/API/private-billing/lookup.service';
import { LookupResponse } from 'src/app/shared/services/responses/private-billing/lookup.response';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { VerifyCNPJ } from 'src/app/shared/custom-validators/cnpj.validator';
import { VerifyCPF } from 'src/app/shared/custom-validators/cpf.validator';
import { ProviderFieldLengthEnum } from 'src/app/shared/enum/private-billing/provider-field-length.enum';

@Component({
  selector: 'app-tiss-billing',
  templateUrl: './tiss-billing.component.html',
  styleUrls: ['./tiss-billing.component.css']
})
export class TissBillingComponent implements OnInit {

  @Output() changeIndex = new EventEmitter();

  constructor(private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    public dialog: MatDialog,
    private privateBillingLookupService: LookupService,
    private tissBillingService: TissBillingService,
    private maskService: MaskService,
    private cdr: ChangeDetectorRef,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.master;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.master_health_unit;

  public model: UntypedFormGroup;
  public isLoading: boolean = true;
  public isFirstLoading: boolean = false;
  public isUpdate: boolean = false;
  public useTissBilling: boolean = false;
  public listAccidentIndication: AccidentIndication[] = [];
  public listAppointmentType: AppointmentType[] = [];
  public listAttendanceAspect: AttendanceAspect[] = [];
  public listAttendanceType: AttendanceType[] = [];
  public listProviderIdentityType: ProviderIdentityType[] = [];
  public listTissXml: TissXml[] = []
  public listTerminologyOrigin: TerminologyOrigin[] = []
  public tissBillingRequest: TissBillingRequest;
  public masks: Masks = this.maskService.getMasks();
  public providerIdentityTypeEnum = ProviderIdentityTypeEnum;

  public providerCodeMask: string = '';
  public providerCodeOperatorCnpj: number = 14;
  public providerCodeOperatorCpf: number = 11;
  public providerCodeOperator: number = ProviderFieldLengthEnum.providerCodeMaxLength;
  public providerCode: number = 16;
  public providerCodeLength: number = this.providerCodeOperatorCnpj;
  public providerCodeMaxLength: number = this.providerCodeOperator;
  public providerNameMaxLength: number = ProviderFieldLengthEnum.providerNameMaxLength;

  @Input() idHealthcareAgreement: number;
  @Input() tissBilling: TissBilling;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      idProviderIdentityType: [null],
      providerCode: [null, [Validators.maxLength(14)]],
      providerName: [null, [Validators.maxLength(70)]],
      cnes: ['9999999'],
      idAttendanceAspect: [null],
      idAttendanceType: [null],
      idAccidentIndication: [null],
      idAppointmentType: [null],

      honoraryBilateral: [170, Validators.required],
      honorarySameGuide: [50, Validators.required],
      honoraryDifferentGuide: [70, Validators.required],

      operationalCostBilateral: [170, Validators.required],
      operationalCostSameGuide: [70, Validators.required],
      operationalCostDifferentGuide: [70, Validators.required],

      m2FilmBilateral: [170, Validators.required],
      m2FilmSameGuide: [70, Validators.required],
      m2FilmDifferentGuide: [70, Validators.required],

      otherUrgency: [130, Validators.required],
      otherDhe: [200, Validators.required],
      otherApartment: [200, Validators.required],

      participationSurgeon: [0, Validators.required],
      participationFirstAux: [0, Validators.required],
      participationSecondAux: [0, Validators.required],
      participationThirdAux: [0, Validators.required],
      participationForthAux: [0, Validators.required],
      participationAuxiliarRoom: [0, Validators.required],
      participationScrubNurse: [0, Validators.required],
      participationAnesthetist: [0, Validators.required],
      participationAnesthetistAuxiliar: [0, Validators.required],
      participationConsultant: [0, Validators.required],
      participationPerfusionist: [0, Validators.required],
      participationBirthRoomPediatrician: [0, Validators.required],
      participationSadtAuxiliar: [0, Validators.required],
      participationClinic: [0, Validators.required],
      participationIntensivist: [0, Validators.required]
    });

    if (this.tissBilling && this.tissBilling.idTissBilling) {
      this.isUpdate = true;
      this.useTissBilling = this.tissBilling.isActive;
    }

    this.tissBillingRequest = new TissBillingRequest();
    if (this.isUpdate)
      this.setFormValues();

    this.populateLookupSelect();
  }

  submit() {
    if (this.model.invalid) {
      this.alertService.show('Erro', "Preencha corretamente os campos obrigatórios", AlertType.error);
      return;
    }

    if (this.isLoading)
      return;

    this.isLoading = true;
    this.mapFormGroupToRequest();

    if (this.isUpdate)
      this.updateTissBilling();
    else
      this.createTissBilling();
  }

  updateTissBilling() {
    this.isLoading = true;

    this.tissBillingService.update(this.tissBilling.idTissBilling, this.tissBillingRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  createTissBilling() {
    this.isLoading = true;

    this.tissBillingService.create(this.tissBillingRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.tissBilling = response.tissBilling;
        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isUpdate = true;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateLookupSelect() {
    this.privateBillingLookupService.listAllLookups().subscribe({
      next: (response: LookupResponse) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listAccidentIndication = response.listAccidentIndication;
        this.listAppointmentType = response.listAppointmentType;
        this.listAttendanceAspect = response.listAttendanceAspect;
        this.listAttendanceType = response.listAttendanceType;
        this.listProviderIdentityType = response.listProviderIdentityType;
        this.listTissXml = response.listTissXml;
        this.listTerminologyOrigin = response.listTerminologyOrigin;

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  updateStatusTissBilling() {
    this.isLoading = true;

    if (this.tissBilling && this.tissBilling != null) {
      this.tissBillingService.updateStatusTissBilling(this.tissBilling.idTissBilling, this.useTissBilling).subscribe({
        next: (response) => {
          if (response.isError) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            this.isLoading = false;
            return;
          }

          this.tissBilling = response.tissBilling;
          this.setFormValues();
          this.alertService.show('Sucesso', `Status alterado com sucesso.`, AlertType.success);
          this.isLoading = false;
        },
        error: (error) => {
          console.log(error);
          this.isLoading = false;
          this.alertService.show('Erro inesperado', error, AlertType.error);
        }
      });
    }
    else if (!this.useTissBilling) {
      this.model.patchValue({
        idProviderIdentityType: null,
        providerCode: null,
        providerName: null,
        cnes: '9999999'
      });
      this.isLoading = false;
    }

    this.isLoading = false;
  }

  setFormValues() {
    this.model.setValue({
      idProviderIdentityType: this.tissBilling.idProviderIdentityType,
      providerCode: this.tissBilling.providerCode,
      providerName: this.tissBilling.providerName,
      cnes: this.tissBilling.cnes,
      idAttendanceAspect: this.tissBilling.idAttendanceAspect,
      idAttendanceType: this.tissBilling.idAttendanceType,
      idAccidentIndication: this.tissBilling.idAccidentIndication,
      idAppointmentType: this.tissBilling.idAppointmentType,
      honoraryBilateral: this.tissBilling.honoraryBilateral,
      honorarySameGuide: this.tissBilling.honorarySameGuide,
      honoraryDifferentGuide: this.tissBilling.honoraryDifferentGuide,
      operationalCostBilateral: this.tissBilling.operationalCostBilateral,
      operationalCostSameGuide: this.tissBilling.operationalCostSameGuide,
      operationalCostDifferentGuide: this.tissBilling.operationalCostDifferentGuide,
      m2FilmBilateral: this.tissBilling.m2FilmBilateral,
      m2FilmSameGuide: this.tissBilling.m2FilmSameGuide,
      m2FilmDifferentGuide: this.tissBilling.m2FilmDifferentGuide,
      otherUrgency: this.tissBilling.otherUrgency,
      otherDhe: this.tissBilling.otherDhe,
      otherApartment: this.tissBilling.otherApartment,
      participationSurgeon: this.tissBilling.participationSurgeon,
      participationFirstAux: this.tissBilling.participationFirstAux,
      participationSecondAux: this.tissBilling.participationSecondAux,
      participationThirdAux: this.tissBilling.participationThirdAux,
      participationForthAux: this.tissBilling.participationForthAux,
      participationAuxiliarRoom: this.tissBilling.participationAuxiliarRoom,
      participationScrubNurse: this.tissBilling.participationScrubNurse,
      participationAnesthetist: this.tissBilling.participationAnesthetist,
      participationAnesthetistAuxiliar: this.tissBilling.participationAnesthetistAuxiliar,
      participationConsultant: this.tissBilling.participationConsultant,
      participationPerfusionist: this.tissBilling.participationPerfusionist,
      participationBirthRoomPediatrician: this.tissBilling.participationBirthRoomPediatrician,
      participationSadtAuxiliar: this.tissBilling.participationSadtAuxiliar,
      participationClinic: this.tissBilling.participationClinic,
      participationIntensivist: this.tissBilling.participationIntensivist
    });
  }

  mapFormGroupToRequest() {
    this.tissBillingRequest.idHealthcareAgreement = this.idHealthcareAgreement;
    this.tissBillingRequest.idProviderIdentityType = this.model.get('idProviderIdentityType').value;
    this.tissBillingRequest.providerCode = this.model.get('providerCode').value;
    this.tissBillingRequest.providerName = this.model.get('providerName').value;
    this.tissBillingRequest.cnes = this.model.get('cnes').value;
    this.tissBillingRequest.idAttendanceAspect = this.model.get('idAttendanceAspect').value;
    this.tissBillingRequest.idAttendanceType = this.model.get('idAttendanceType').value;
    this.tissBillingRequest.idAccidentIndication = this.model.get('idAccidentIndication').value;
    this.tissBillingRequest.idAppointmentType = this.model.get('idAppointmentType').value;
    this.tissBillingRequest.honoraryBilateral = this.model.get('honoraryBilateral').value;
    this.tissBillingRequest.honorarySameGuide = this.model.get('honorarySameGuide').value;
    this.tissBillingRequest.honoraryDifferentGuide = this.model.get('honoraryDifferentGuide').value;
    this.tissBillingRequest.operationalCostBilateral = this.model.get('operationalCostBilateral').value;
    this.tissBillingRequest.operationalCostSameGuide = this.model.get('operationalCostSameGuide').value;
    this.tissBillingRequest.operationalCostDifferentGuide = this.model.get('operationalCostDifferentGuide').value;
    this.tissBillingRequest.m2FilmBilateral = this.model.get('m2FilmBilateral').value;
    this.tissBillingRequest.m2FilmSameGuide = this.model.get('m2FilmSameGuide').value;
    this.tissBillingRequest.m2FilmDifferentGuide = this.model.get('m2FilmDifferentGuide').value;
    this.tissBillingRequest.otherUrgency = this.model.get('otherUrgency').value;
    this.tissBillingRequest.otherDhe = this.model.get('otherDhe').value;
    this.tissBillingRequest.otherApartment = this.model.get('otherApartment').value;
    this.tissBillingRequest.participationSurgeon = this.model.get('participationSurgeon').value;
    this.tissBillingRequest.participationFirstAux = this.model.get('participationFirstAux').value;
    this.tissBillingRequest.participationSecondAux = this.model.get('participationSecondAux').value;
    this.tissBillingRequest.participationThirdAux = this.model.get('participationThirdAux').value;
    this.tissBillingRequest.participationForthAux = this.model.get('participationForthAux').value;
    this.tissBillingRequest.participationAuxiliarRoom = this.model.get('participationAuxiliarRoom').value;
    this.tissBillingRequest.participationScrubNurse = this.model.get('participationScrubNurse').value;
    this.tissBillingRequest.participationAnesthetist = this.model.get('participationAnesthetist').value;
    this.tissBillingRequest.participationAnesthetistAuxiliar = this.model.get('participationAnesthetistAuxiliar').value;
    this.tissBillingRequest.participationConsultant = this.model.get('participationConsultant').value;
    this.tissBillingRequest.participationPerfusionist = this.model.get('participationPerfusionist').value;
    this.tissBillingRequest.participationBirthRoomPediatrician = this.model.get('participationBirthRoomPediatrician').value;
    this.tissBillingRequest.participationSadtAuxiliar = this.model.get('participationSadtAuxiliar').value;
    this.tissBillingRequest.participationClinic = this.model.get('participationClinic').value;
    this.tissBillingRequest.participationIntensivist = this.model.get('participationIntensivist').value;
    this.tissBillingRequest.isActive = this.useTissBilling;
  }

  onChangeProviderType(event) {
    if (event == ProviderIdentityTypeEnum.cpf) {
      this.providerCodeMask = this.masks.cpf;
      this.providerCodeLength = this.providerCodeOperatorCpf;
      this.providerCodeMaxLength = this.providerCode;
      this.model.get('providerCode').setValidators([Validators.maxLength(this.providerCodeOperatorCpf), VerifyCPF()]);
    }
    else if (event == ProviderIdentityTypeEnum.cnpj) {
      this.providerCodeMask = this.masks.cnpj;
      this.providerCodeLength = this.providerCodeOperatorCnpj;
      this.providerCodeMaxLength = this.providerCodeOperator;
      this.model.get('providerCode').setValidators([Validators.maxLength(this.providerCodeOperatorCnpj), VerifyCNPJ()]);
    }
    else if (event == ProviderIdentityTypeEnum.providerCode) {
      this.providerCodeMask = '';
      this.providerCodeLength = this.providerCodeOperatorCnpj;
      this.providerCodeMaxLength = this.providerCodeOperatorCnpj;
      this.model.get('providerCode').setValidators(Validators.maxLength(this.providerCodeOperatorCnpj))

      if (this.model.get('providerCode') && this.model.get('providerCode').value != null) {
        let cleanedValue = this.model.get('providerCode').value.replace(/[.\-/]/g, '');
        this.model.get('providerCode').setValue(cleanedValue);
      }

      this.model.get('providerCode').updateValueAndValidity();
    }
  }

  cancel() {
    let reportDialog = this.dialog.open(ConfirmModalComponent, {
      data: {
        title: "Aviso!",
        message: "Você realmente deseja cancelar? Qualquer alteração realizada pode não ter sido salva.",
        btnPrimary: "Sim, cancelar",
        btnAccent: "Não, continuar editando"
      },
    });
    reportDialog.afterClosed().subscribe(result => {
      if (result && result.clickPrimary) {
        this.isLoading = false;
        this.changeIndex.emit(0);
        this.cdr.detectChanges();
      }
    });
  }

  inputNumberMax999(formName: string) {
    let value = this.model.get(formName).value ? this.model.get(formName).value : '';
    if (value) {
      value = value.replace(/[^0-9]/g, '');
      value = value.replace(/(\..*)\./g, '$1');
      if(parseInt(value) > 999){
        this.alertService.show('Atenção', 'O valor não pode ser maior que 999!', AlertType.warning);
        value = '999';
      }
      this.model.get(formName).setValue(value);
    }
  }
}