import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FrontDeskRoutingModule } from './front-desk-routing.module';
import { PatientListComponent } from './pages/patient-list/patient-list.component';
import { SharedModule } from '../shared/shared.module';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { MatIconModule } from '@angular/material/icon';
import { MatTree, MatTreeModule } from '@angular/material/tree';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PatientRegisterComponent } from './pages/patient-register-master/patient-register/patient-register.component';
import { FrontDeskComponent } from './pages/front-desk/front-desk.component';
import { SelectPatientModalComponent } from './pages/patient-register-master/patient-register/select-patient-modal/select-patient-modal.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { ForwardPatientModalComponent } from './pages/patient-register-master/patient-register/forward-patient-modal/forward-patient-modal.component';
import { ReportModalComponent } from '../shared/components/report-modal/report-modal.component';
import { ReportSelectModalComponent } from './pages/patient-register-master/patient-register/report-select-modal/report-select-modal.component';
import { FrontDeskListComponent } from './pages/front-desk-list/front-desk-list.component';
import { FrontDeskDeleteModalComponent } from './pages/front-desk-list/front-desk-delete-modal/front-desk-delete-modal.component';
import { FrontDeskRegisterComponent } from './pages/front-desk-register/front-desk-register.component';
import { PatientRegisterMasterComponent } from './pages/patient-register-master/patient-register-master.component';
import { SigaPatientRegisterComponent } from './pages/patient-register-master/siga-patient-register/siga-patient-register.component';
import { SigaSelectPatientModalComponent } from './pages/patient-register-master/siga-patient-register/siga-select-patient-modal/siga-select-patient-modal.component';
import { SigaPatientRegisterDataComponent } from './pages/patient-register-master/siga-patient-register/siga-patient-register-data/siga-patient-register-data.component';
import { SigaSearchPatientCpfComponent } from './pages/patient-register-master/siga-patient-register/siga-search-patient-cpf/siga-search-patient-cpf.component';
import { SigaSearchPatientCnsComponent } from './pages/patient-register-master/siga-patient-register/siga-search-patient-cns/siga-search-patient-cns.component';
import { SigaSearchPatientFullTermComponent } from './pages/patient-register-master/siga-patient-register/siga-search-patient-full-term/siga-search-patient-full-term.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckbox, MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioButton, MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { NgxMaskModule } from 'ngx-mask';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { ForwardPatientConfirmModalComponent } from './pages/patient-register-master/patient-register/forward-patient-modal/forward-patient-confirm-modal/forward-patient-confirm-modal.component';
import { SigaForwardPatientModalComponent } from './pages/patient-register-master/siga-patient-register/siga-forward-patient-modal/siga-forward-patient-modal.component';
import { SigaForwardPatientConfirmModalComponent } from './pages/patient-register-master/siga-patient-register/siga-forward-patient-modal/siga-forwar-patient-confirm-modal/siga-forward-patient-confirm-modal.component';
import { SearchModalComponent } from './pages/patient-register-master/patient-register/search-modal/search-modal.component';
import { SelectPatientHealthcareAgreementComponent } from './pages/patient-register-master/patient-register/select-patient-healthcare-agreement/select-patient-healthcare-agreement.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ScheduledAgreementPlanConfirmModalComponent } from './pages/patient-register-master/patient-register/select-patient-healthcare-agreement/scheduled-agreement-plan-confirm-modal/scheduled-agreement-plan-confirm-modal.component';

@NgModule({
  declarations: [
    PatientRegisterComponent,
    FrontDeskComponent,
    SelectPatientModalComponent,
    PatientListComponent,
    ForwardPatientModalComponent,
    ForwardPatientConfirmModalComponent,
    ReportModalComponent,
    SearchModalComponent,
    ReportSelectModalComponent,
    FrontDeskListComponent,
    FrontDeskDeleteModalComponent,
    FrontDeskRegisterComponent,
    PatientRegisterMasterComponent,
    SigaPatientRegisterComponent,
    SigaSelectPatientModalComponent,
    SigaSearchPatientCpfComponent,
    SigaSearchPatientCnsComponent,
    SigaSearchPatientFullTermComponent,
    SigaPatientRegisterDataComponent,
    SigaForwardPatientModalComponent,
    SigaForwardPatientConfirmModalComponent,
    SelectPatientHealthcareAgreementComponent,
    ScheduledAgreementPlanConfirmModalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FrontDeskRoutingModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTreeModule,
    MatRadioModule,
    MatTooltipModule,
    MatToolbarModule,
    NgxMatFileInputModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    MatSlideToggleModule,
    CdkAccordionModule,
    MatPaginatorModule,
    MatDialogModule,
    MatDatepickerModule,
  ],
  providers: [
    AuthGuard,
    MatCheckbox,
    MatCheckboxChange,
    MatTree,
    MatRadioButton,
  ]
})
export class FrontDeskModule { }
