<div class="container-generic">
    <div class="card-list" *ngIf="listPatient!= null">
        <div class="row">
            <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listPatient; let index = index;">
                <div class="card-item">
                    <div class="infos">
                        <span class="primary">{{item.patientName}}</span>
                        <span class="secundary">{{item.cpf}}</span>
                        <span class="secundary">{{item.birthDate | date: 'dd/MM/yyyy'}}</span>
                    </div>
                    <div class="actions">
                        <a><mat-icon aria-hidden="false" aria-label="Editar" (click)="details(index)">edit</mat-icon>
                        </a>
                        <a><mat-icon aria-hidden="false" aria-label="Editar"
                                (click)="remove(index)">delete_forever</mat-icon> </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>