import { AllergyStruct } from "../../structs/medical-record/allergy.struct";
import { CareLinePriorityStatusStruct } from "../../structs/medical-record/care-line-priority-status.struct";
import { MedicalPrescriptionCheckRequest } from "./medical-prescription-check.request";
import { MedicalProcedureCheckRequest } from "./medical-procedure-check.request";
import { NursingConsultationStruct } from "../../structs/medical-record/nursing-consultation.struct";
import { CompleteMedicalCareStruct } from "../../structs/orchestrator-patient/complete-medical-care.struct";
import { GuideRequest } from "../private-billing/guide.request";

export class NursingConsultationRequest {
    
    public idEpisode: number;

    public patientEvolution: string;
    
    public temperature: number;
    
    public respiratoryFrequency: number;
    
    public heartRate: number;
    
    public glucose: number;
    
    public bloodPressureDiastole: number;
    
    public bloodPressureSystole: number;
    
    public saturation: number;

    public listAllergy: AllergyStruct[];

    public datetimeStartAttendance: Date;

    public listMedicalPrescriptionCheck: MedicalPrescriptionCheckRequest[];

    public listMedicalProcedureCheck : MedicalProcedureCheckRequest[];
    
    public careLinePriorityStatusStruct: CareLinePriorityStatusStruct = new CareLinePriorityStatusStruct();

    public idQueueUsed: number;

    public idRoomAttendance: number;
    public medicalCareHistorie: CompleteMedicalCareStruct[];
    
    public guideRequest: GuideRequest;
}