export class RoomRequest{
    public roomName: string;

    public isActive: boolean;    

    public description: string;
    public ip: string;

    public idSector: number;

    public isEmergency: boolean;
}