import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { UpdateAuditReponse } from '../../responses/audit/update-audit.response';
import { AuditConfigResponse } from '../../responses/audit/audit-config.response';
import { AuditConfigRequest } from '../../requests/audit/audit-config.request';

@Injectable({
  providedIn: 'root'
})
export class AuditConfigService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getAuditConfig(): Observable<AuditConfigResponse> {
    
    let uri = `AuditConfig`;
    
    return this.httpClient.get<AuditConfigResponse>(environment.urlApiAudit + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public createAuditConfig(request: AuditConfigRequest): Observable<ReturnStruct> {
    
    let uri = 'AuditConfig'

    return this.httpClient.post<ReturnStruct>(environment.urlApiAudit + uri, request, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public updateAuditConfig(request: AuditConfigRequest): Observable<UpdateAuditReponse> {
    
    let uri = 'AuditConfig'

    return this.httpClient.put<UpdateAuditReponse>(environment.urlApiAudit + uri, request, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
