
import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { StorageMaterialRequest } from '../../requests/pharmacy/storage-material.request';
import { StorageMaterialValidateResponse } from '../../responses/pharmacy/storage-material-validate.response';
import { StorageMaterialResponse } from '../../responses/pharmacy/storage-material.response';

@Injectable({
    providedIn: 'root'
})
export class StorageMaterialService extends CommonService {

    constructor(private router: Router, private httpClient: HttpClient) {
        super();
    }

    public getSugestionList(idRequestMaterial: number): Observable<StorageMaterialResponse> {
        let uri = `StorageMaterial/idRequestMaterial/${idRequestMaterial}`

        return this.httpClient.get<StorageMaterialResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }

    public validateMaterial(idRequestMaterial: number, body: StorageMaterialRequest): Observable<StorageMaterialValidateResponse> {
        let uri = `StorageMaterial/idRequestMaterial/${idRequestMaterial}`

        return this.httpClient.patch<StorageMaterialValidateResponse>(environment.urlApiPharmacy + uri, body, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }

    public saveMaterial(idRequestMaterial: number, body: StorageMaterialRequest): Observable<ReturnStruct> {
        let uri = `StorageMaterial/idRequestMaterial/${idRequestMaterial}`

        return this.httpClient.post<ReturnStruct>(environment.urlApiPharmacy + uri, body, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }
}