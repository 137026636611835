import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { GetUserResponse } from 'src/app/shared/services/responses/user/get-user.response';
import { ListUserResponse } from '../../responses/user/list-user.response';
import { ProfessionResponse } from '../../responses/user/profession.response';
import { UserRegisterRequest } from '../../requests/user/user-register-request';

@Injectable({
  providedIn: 'root'
})
export class UserService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  public listUser(isActive: boolean, searchText: string, jsonListIdProfession: string, jsonListIdProfile?: string): Observable<ListUserResponse> {

    let uri = 'User?'

    if (isActive != null)
      uri = uri + `isActive=${isActive}&`;

    if (searchText != null)
      uri = uri + `searchText=${searchText}&`;

    if (jsonListIdProfession != null)
      uri = uri + `jsonListIdProfession=${jsonListIdProfession}&`;

    if (jsonListIdProfile != null)
      uri = uri + `jsonListIdProfile=${jsonListIdProfile}&`;

    return this.httpClient.get<ListUserResponse>(environment.urlApiUser + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public listProfession(): Observable<ProfessionResponse> {

    return this.httpClient.get<ProfessionResponse>(environment.urlApiUser + "Profession", this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public deleteUser(idUser: number, ip: string): Observable<ReturnStruct> {
    return this.httpClient.delete<ReturnStruct>(environment.urlApiOrchestratorUser + `User/idUser/${idUser}/ip/${ip}`, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getUser(idUser: number): Observable<GetUserResponse> {
    return this.httpClient.get<GetUserResponse>(environment.urlApiUser + `User/idUser/${idUser}`, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public updateUser(idUser: number, updateUserRequest: UserRegisterRequest): Observable<ReturnStruct> {
    return this.httpClient.put<ReturnStruct>(environment.urlApiOrchestratorUser + `User/idUser/${idUser}`, updateUserRequest, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public createUser(createUserRequest: UserRegisterRequest): Observable<ReturnStruct> {
    return this.httpClient.post<ReturnStruct>(environment.urlApiUser + `User`, createUserRequest, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}