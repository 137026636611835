import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { GetProtocolResponse } from '../../responses/risk-classification/get-protocol.response';
import { ListProtocolResponse } from '../../responses/risk-classification/list-protocol.response';

@Injectable({
  providedIn: 'root'
})
export class ProtocolSelectService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  protocol(idProtocol: number): Observable<GetProtocolResponse> {
    let uri = `ProtocolSelect/idProtocol/${idProtocol}`;

    return this.httpClient.get<GetProtocolResponse>(environment.urlApiRiskClassification + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
