<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <div class="row">
            <div class="col-md-6">
                <h1>Gestão de Leitos</h1>
                <div class="white-body">
                    <div class="generic-call__info__container">
                        <div class="row">
                            <div class="col-md-9">
                                <span class="generic-call__info__container__selected-room"
                                    *ngIf="selectedRoom">{{selectedRoom.sectorName}} - {{selectedRoom.roomName}}</span>
                                <span class="generic-call__info__container__selected-room" *ngIf="!selectedRoom">Selecionar
                                    sala</span>
                                <mat-icon (click)="selectRoomModal()" class="generic-call__info__container__edit-room">edit
                                </mat-icon>
                            </div>
                        </div>
                    </div>
                    <div class="filters">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <mat-form-field appearance="outline">
                                    <mat-icon matPrefix class="my-icon">search</mat-icon>
                                    <mat-label>Pesquisar</mat-label>
                                    <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText"
                                        (keyup)="onKeySearch($event, 'searchText')">
                                </mat-form-field>
                            </div>
                            <div class="col-12 col-md-6">
                                <mat-form-field appearance="outline">
                                    <mat-label>Data de Nascimento</mat-label>
                                    <input matInput type="text" id="birthDate" name="birthDate" [(ngModel)]="birthDate"
                                        (keyup)="onKeySearch($event, 'birthDate')" [mask]="masks.date">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="card-list" *ngIf="listOccupiedBed != null">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-6" *ngFor="let item of listOccupiedBed">
                                <div class="card-item">
                                    <div class="infos">
                                        <span class="primary">{{item.bedName}}</span>
                                        <span class="secundary patient-name">{{item.patientName}}</span>
                                        <span class="secundary">Episódio: {{item.idEpisode}}</span>
                                        <span class="secundary">{{item.birthDate | date : 'dd/MM/yyyy'}}</span>
                                        <span class="secundary">{{item.patientAge}}</span>
                                        <app-patient-status-tag [patientStatus]="item.statusName" [idStatus]="item.idStatus"
                                            [patientAdmStatus]="item.episodeAdministrativeReleaseStatus"
                                            [idAdmStatus]="item.idEpisodeAdministrativeReleaseStatus">
                                        </app-patient-status-tag>
                                    </div>
                                    <div class="actions">
                                        <ng-container *ngIf="!item.isLoadingLeaveBed">
                                            <a *ngIf="item.idStatus != initial" (click)="leaveBed(item)"
                                                matTooltip="Liberar leito">
                                                <mat-icon aria-hidden="false" aria-label="Detalhes">logout</mat-icon>
                                            </a>
                                            <a *ngIf="item.idStatus != endRealease && item.idStatus != endDeath && item.idStatus != endEvasion">
                                                <mat-icon aria-hidden="false" aria-label="Detalhes"
                                                    [routerLink]="['/observation/bed-management/details', {idEpisode: item.idEpisode, idSector: item.idSector,  idService: idService, idRoom: this.idRoom, idBed: item.idBed, isBedManagement: true, showTissGuideButton: this.showTissGuideButton}]">
                                                    edit</mat-icon>
                                            </a>
                                        </ng-container>
                                        <app-loading-list *ngIf="item.isLoadingLeaveBed"></app-loading-list>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <app-empty-list *ngIf="selectedRoom && listOccupiedBed != undefined && listOccupiedBed.length == 0">
                    </app-empty-list>
                    <app-not-seleted-room *ngIf="!selectedRoom"></app-not-seleted-room>
                </div>
    
                <app-loading-list *ngIf="isLoading"></app-loading-list>
            </div>
    
            <div class="col-md-6">
                <h1 *ngIf="hasObservationProcedure">Medicamentos e Procedimentos Pendentes</h1>
                <h1 *ngIf="!hasObservationProcedure">Medicamentos Pendentes</h1>
                <div class="white-body">
                    <mat-tab-group>
                        <mat-tab label="Medicamentos">
                            <app-bed-management-prescription-tab
                                [listMedicalPrescriptionPendingResponse]="listMedicalPrescriptionPendingResponse"
                                [idSector]="idSector" [idService]="idService" [idRoom]="idRoom">
                            </app-bed-management-prescription-tab>
                        </mat-tab>
                        <mat-tab *ngIf="hasObservationProcedure" label="Procedimentos">
                            <app-bed-management-procedure-tab
                                [listMedicalProcedureCheckResponse]="listMedicalProcedureCheckResponse"
                                [idSector]="idSector" [idService]="idService" [idRoom]="idRoom">
                            </app-bed-management-procedure-tab>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </div>
    </div>
    
</div>