import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { GetFrontDeskBySectorResponse } from '../../responses/front-desk/get-front-desk-by-sector.response';
import { MetaDataStruct } from '../../structs/meta-data/meta-data.struct';

@Injectable({
  providedIn: 'root'
})
export class GetFrontDeskBySectorService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  public getMetaDataFrontDesk(idSector: number, listMetaDataStruct: MetaDataStruct[]): Observable<GetFrontDeskBySectorResponse> {
    let uri = `GetFrontDeskBySector/idSector/${idSector}`
    return this.httpClient.post<GetFrontDeskBySectorResponse>(environment.urlApiFrontDesk + uri, listMetaDataStruct, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }
}