import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ExternalPrescriptionModalComponent } from 'src/app/shared/components/external-prescription-modal/external-prescription-modal.component';
import { LookupMedicalCareService } from 'src/app/shared/services/API/medical-record/lookup-medical-care.service';
import { MedicalPrescriptionService } from 'src/app/shared/services/API/medical-record/medical-prescription.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { MedicalCareModel } from 'src/app/shared/services/models/medical-record/medical-care.model';
import { LookupMedicalCareResponse } from 'src/app/shared/services/responses/medical-record/lookup-medical-care-response';
import { ExternalPrescriptionStruct } from 'src/app/shared/services/structs/medical-record/external-prescription.struct';
import { GetCompleteMultiProfessionalConfigStruct } from 'src/app/shared/services/structs/multi-professional/get-complete-multi-professional-config.struct';
import { ReadExternalPrescriptionStruct } from 'src/app/shared/services/structs/orchestrator-patient/read-external-prescription.struct';
import { ReadMedicalPrescriptionStruct } from 'src/app/shared/services/structs/orchestrator-patient/read-medical-prescription.struct';
import { UpdateExternalPrescriptionModalComponent } from '../non-standard-prescriptions-block/update-external-prescription-modal/update-external-prescription-modal.component';
import { ListMedicineResponse } from 'src/app/shared/services/responses/pharmacy/list-medicine.response';
import { MedicineService } from 'src/app/shared/services/API/pharmacy/medicine.service';
import { AllergyModel } from 'src/app/shared/services/models/medical-record/allergy.model';
import { ReadMultiProfessionalPrescriptionStruct } from 'src/app/shared/services/structs/orchestrator-patient/read-multi-professional-prescription.struct';
import { PrescriptionModalComponent } from './prescription-modal/prescription-modal.component';

@Component({
  selector: 'app-standart-prescriptions-block',
  templateUrl: './standart-prescriptions-block.component.html',
  styleUrls: ['./standart-prescriptions-block.component.css']
})
export class StandartPrescriptionsBlockComponent {

  @Input() completeMultiprofessionalConfig: GetCompleteMultiProfessionalConfigStruct;
  @Input() listPrescription: ReadMultiProfessionalPrescriptionStruct[]=[];
  @Input() allergiesToSave: AllergyModel[];
  @Output() changeStandartPrescription = new EventEmitter<any>();

  constructor(
    private alertService: AlertService,
    private dialog: MatDialog,
    private maskService: MaskService,
    private medicalPrescriptionService: MedicalPrescriptionService,
    public lookupMedicalCareService: LookupMedicalCareService,
    public medicineService: MedicineService,
  ) { }

  public isLoading: boolean = false;
  public isFirstLoading: boolean;
  
  public lookupMedicalCareResponse: LookupMedicalCareResponse;
 
  public masks: Masks;
  
  public readonly: boolean = false;
  public idMultiProfessionalCare: number;
  public listMedicine: ListMedicineResponse = new ListMedicineResponse();

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
   
    this.isFirstLoading = true;
    this.getLookupMedicalCare();
    this.getLookupMedicine();
  }

  openPrescriptionModal(index: number, prescription: ReadMultiProfessionalPrescriptionStruct, readOnly: boolean) {
    if (readOnly) {
      this.openUpdatePrescriptionModal(index, prescription);
    } else {
      this.openDefaultPrescriptionModal(prescription);
    }
  }

  openUpdatePrescriptionModal(index: number, prescription: ReadMultiProfessionalPrescriptionStruct) {
    const dialogRef = this.dialog.open(PrescriptionModalComponent, {
      data: {
        prescription,
        idMultiProfessionalCare: this.idMultiProfessionalCare,
        lookupMedicalCareResponse: this.lookupMedicalCareResponse,
        listMedicine: this.listMedicine,
        listAllergy: this.allergiesToSave,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (index >= 0) {
          this.listPrescription.splice(index, 1);
        }
        this.listPrescription.push(result.newPrescription);
        this.changeStandartPrescription.emit(this.listPrescription);
      }
      this.isFirstLoading = false;
    });
  }

  openDefaultPrescriptionModal(prescription: ReadMultiProfessionalPrescriptionStruct) {
    const dialogRef = this.dialog.open(PrescriptionModalComponent, {
      data: {
        prescription,
        readOnly: false,
        lookupMedicalCareResponse: this.lookupMedicalCareResponse,
        listMedicine: this.listMedicine,
        // isFastTrack: this.isFastTrack,
        listAllergy: this.allergiesToSave,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.newPrescription) {
        this.listPrescription.push(result.newPrescription)
        this.changeStandartPrescription.emit(this.listPrescription);
      }
      this.isFirstLoading = false;
    });
  }

  deleteStandartPrescription(index: number) {
    if (this.readonly) {

      if (this.isLoading)
        return;

      this.isLoading = true;
      this.medicalPrescriptionService.deletePresciption(this.listPrescription[index].idMultiProfessionalPrescription).subscribe((response) => {
        this.isLoading = false;
        if (response.isError) {
          this.alertService.show("Erro", response.errorDescription, AlertType.error);
          return;
        }
        this.alertService.show("Sucesso", "Prescrição deletada com sucesso", AlertType.success);
        this.listPrescription.splice(index, 1);
      });
      this.changeStandartPrescription.emit(this.listPrescription);
    } else {
      this.listPrescription.splice(index, 1);
    }
  }

  getLookupMedicalCare() {
    this.lookupMedicalCareService.getLookupMedicalCare().subscribe({
      next:(response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
  
        this.lookupMedicalCareResponse = response;
      },
      error:(error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  
  getLookupMedicine() {
    this.medicineService.listAllMedicine().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listMedicine = response;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}
