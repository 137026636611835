<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum'></app-menu>

    <div class="container-body">
        <div class="white-body">
            <div class="welcome-header">
                <div class="title-header">
                    <mat-icon aria-hidden="false" aria-label="Menu apps">manage_accounts</mat-icon>
                    <h1>Módulo de Recepção</h1>
                </div>
                <p>Bem vindo ao seu módulo de recepção! Aqui você controla os atendimentos dos pacientes.</p>
            </div>
            <div class="welcome-img">
                <img src="assets/images/default-img.png" aria-hidden="false" aria-label="welcome" />
            </div>    
        </div>
        <hr/>
        <section>
            <app-module-functionality [menuModuleEnum]="menuModuleEnum"></app-module-functionality>
        </section>
    </div>

</div>

<div *ngIf="seeButton">
    <app-floating-button [menuModuleEnum]='menuModuleEnum'></app-floating-button>
</div>