<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <h1>Subgrupo de Medicamento</h1>
        <div class="white-body">
            <a mat-flat-button color="primary" class=" add-user" routerLink="/pharmacy/medicine-subgroup/register"><mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon> Cadastrar Subgrupo de Medicamento</a>
            <div class="filters">
                <div class="row">   
                    <div class="col-12 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Pesquisar</mat-label>
                            <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText" (keyup)="onKeySearch($event)">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="card-list" *ngIf="listMedicineSubgroup != null">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listMedicineSubgroup">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary">{{item.medicineSubgroup.medicineSubgroupName}}</span>
                                <span class="secundary">{{item.medicineSubgroup.medicineSubgroupDescription}}</span>
                                <span class="secundary">{{item.medicineGroupName}}</span>
                                <span class="secundary">{{item.medicineTypeName}}</span>
                            </div>
                            <div class="actions">
                                <a><mat-icon aria-hidden="false" aria-label="Editar" [routerLink] = "['/pharmacy/medicine-subgroup/register', {idMedicineSubgroup: item.medicineSubgroup.idMedicineSubgroup}]">edit</mat-icon> </a>
                                <a><mat-icon aria-hidden="false" aria-label="Editar" (click)="openModal(item.medicineSubgroup.idMedicineSubgroup)">delete_forever</mat-icon> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <app-empty-list *ngIf="listMedicineSubgroup != undefined && listMedicineSubgroup.length == 0"></app-empty-list>
        </div>
    
        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>
    
</div>