<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-user-modal-list">
            <h2>Deseja excluir o usuário?</h2>
         </div>
         
         <div class="body-user-modal-list">
             Ao fazer a exclusão, o usuário perderá todos os acessos e o procedimento é irreversível
         </div>
         <div class="footer-user-modal-list">
            <button mat-flat-button color="primary" class="btn-block" (click)="clickDelete()">
                <span *ngIf="isLoading == false">Sim, excluir</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
            <button mat-flat-button color="accent" class="btn-block"(click)="clickCancel()">Cancelar</button>
        </div>
    </div>
</div>
