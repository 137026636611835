import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ReportProfileRequest } from '../../requests/business-intelligence/report-profile.request';
import { BusinessIntelligenceResponse } from '../../responses/business-intelligence/business-intelligence.response';
import { ReportProfileResponse } from '../../responses/business-intelligence/report-profile.response';

@Injectable({
  providedIn: 'root'
})
export class BIProfileService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public GetConfig(): Observable<ReportProfileResponse> {
    let uri = `ReportProfile`

    return this.httpClient.get<ReportProfileResponse>(environment.urlApiBusinessIntelligence + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public SaveConfig(request: ReportProfileRequest): Observable<ReturnStruct> {
    let uri = `ReportProfile`
    return this.httpClient.post<ReturnStruct>(environment.urlApiBusinessIntelligence + uri, request,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  
}
