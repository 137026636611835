<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="title-preview">
            <h1>Deseja confirmar o cancelamento desta consulta?</h1>
        </div>
        <div class="btn-container">
            <button mat-flat-button color="primary" class="button-attend-patient" (click)="cancelAppointment()">
                <span>Sim</span>
            </button>
            <button mat-flat-button color="accent" class="btn-block button-attend-patient" (click)="close()">Não</button>
        </div>
    </div>
</div>
