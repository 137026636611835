import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AuditRoutingModule } from './audit-routing.module';
import { AuditComponent } from './pages/audit/audit.component';
import { AuditListComponent } from './pages/audit-list/audit-list.component';
import { SharedModule } from '../shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { MatTree, MatTreeModule } from '@angular/material/tree';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { AuditDeleteModalComponent } from './pages/audit-list/audit-delete-modal/audit-delete-modal.component';
import { AuditRegisterComponent } from './pages/audit-register/audit-register.component';
import { AuditUserSelectComponent } from './pages/audit-register/audit-user-select/audit-user-select.component';
import { ExecuteAuditComponent } from './pages/execute-audit/execute-audit.component';
import { ExecuteAuditTriageComponent } from './pages/execute-audit/execute-audit-triage/execute-audit-triage.component';
import { IndividualAuditReportComponent } from './pages/individual-audit-report/individual-audit-report.component';
import { DivergentAuditReportComponent } from './pages/divergent-audit-report/divergent-audit-report.component';
import { MonthlyReportComponent } from './pages/monthly-report/monthly-report.component';
import { MonthlyReportRegisterComponent } from './pages/monthly-report-register/monthly-report-register.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckbox, MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioButton, MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { MatDialogModule } from '@angular/material/dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AuditHistoryCardModalComponent } from './pages/audit-list/audit-history-card-modal/audit-history-card-modal.component';
import { AuditConfigComponent } from './pages/audit-config/audit-config.component';
import { ExecuteAuditWarningModalComponent } from './pages/execute-audit/execute-audit-warning-modal/execute-audit-warning-modal/execute-audit-warning-modal.component';
import { ListOfCompletedAuditTriageModalComponent } from './pages/execute-audit/execute-audit-triage/list-of-completed-audit-triage-modal/list-of-completed-audit-triage-modal.component';
import { AuditRemovePriorReasonModalComponent } from './pages/audit-config/audit-remove-prior-reason-modal/audit-remove-prior-reason-modal.component';

@NgModule({
  declarations: [
    AuditComponent, 
    AuditListComponent, 
    AuditDeleteModalComponent, 
    AuditRegisterComponent, 
    AuditUserSelectComponent, 
    ExecuteAuditComponent, 
    ExecuteAuditTriageComponent, 
    IndividualAuditReportComponent, 
    DivergentAuditReportComponent, 
    MonthlyReportComponent, 
    MonthlyReportRegisterComponent, 
    AuditHistoryCardModalComponent,
    AuditConfigComponent,
    ExecuteAuditWarningModalComponent,
    ListOfCompletedAuditTriageModalComponent,
    AuditRemovePriorReasonModalComponent
  ],
  imports: [
    CommonModule,
    AuditRoutingModule,
    CommonModule,
    SharedModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTreeModule,
    MatRadioModule,
    MatTooltipModule,
    MatToolbarModule,
    NgxMatFileInputModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    MatSlideToggleModule,
    CdkAccordionModule,
    MatDatepickerModule,
    MatDialogModule,
    DragDropModule,
  ],
  providers: [
    AuthGuard,
    MatCheckbox,
    MatCheckboxChange,
    MatTree,
    MatRadioButton,
  ]
})
export class AuditModule { }
