export class BedRequest{
    
    public bedName: string;
    
    public idBedStatus: number;
    
    public idBedType: number;

    public idBedCategory: number;
    
    public isActive: boolean;

    public idSector: number;

    public idHygienizationStatus: number;
}