import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-report-select-modal',
  templateUrl: './report-select-modal.component.html',
  styleUrls: ['./report-select-modal.component.css']
})
export class ReportSelectModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ReportSelectModalComponent>,
    private formBuilder: UntypedFormBuilder,
  ) { }
  public reportModel: UntypedFormGroup;


  ngOnInit(): void {
    this.reportModel = this.formBuilder.group({
      admissionReport: [false],
    });
  }
  
  clickOK() {
    this.matDialogRef.close({
      admissionReport: this.reportModel.get('admissionReport').value}); 
  }

  close() {
    this.matDialogRef.close(); 
  }
}

