import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CommonService } from "src/app/shared/services/common-service";
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';
import { catchError } from "rxjs/operators";
import { LookupResponse } from "../../responses/multi-professional/lookup.response";

@Injectable({
    providedIn: 'root'
  })
  export class LookupService extends CommonService{
  
    constructor(private router: Router, private httpClient: HttpClient) {
      super();
    }

    public getLookup(): Observable<LookupResponse>{
      let uri = `Lookup`
  
      return this.httpClient.get<LookupResponse>(environment.urlApiMultiProfessional + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}