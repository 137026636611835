export class FrontDeskPatientReportRequest {
    public idEpisode: number;
    public generateWristband: boolean;
    public blankMedicalCareReport: boolean = false;
    public wristbandWidth: number;
    public wristbandHeight: number;
    public admissionRoomName: string;
    public identification: string;
    public idIdentification: number;
    public idSector: number;
    public idSignatureProvider: number;     
    public signatureAuthentication: string; 
}