<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-audit-modal-warning">
            <h1><b>Atenção!</b></h1>
        </div>
        <div class="alert-img">
            <img src="assets/images/alert.png" aria-hidden="false" aria-label="alert" />
        </div>
        <div class="body-audit-modal-warning">        
            <span *ngIf="this.data.errorCode == errorInScheduleChangeEnum.ErroNaAlteracaoDoDia">Não foi possível <strong>alterar o horário</strong> de atendimento ou a especialidade da sua agenda, pois já existe(m) agendamento(s).</span>
            <span *ngIf="this.data.errorCode == errorInScheduleChangeEnum.ErroNoTempoDeAtendimento">Existe(m) agendamento(s) marcado(s), por isso, não foi possível alterar a duração da consulta.</span>   
            <span>Para prosseguir com a alteração, é necessário ajustar a agenda ou cancelar o(s) agendamento(s)</span>
        </div>
        <div class="card-list" *ngIf="this.data.listAffectedPatient != null">
            <div class="row">
                <div class="col-12" *ngFor="let item of this.data.listAffectedPatient">
                    <div class="card-item">
                        <div class="infos">
                            <div class="card-modal-patient">
                                <div class="card-align-icon">
                                    <mat-icon class="icon-schedule" aria-hidden="false"
                                        aria-label="Plus">event_available</mat-icon>
                                </div>
                                <div class="margin-icon">
                                    <span class="primary">
                                        Nome do Paciente: <small>{{ item.userName }}</small>
                                    </span>
                                    <span class="primary">
                                        Data e hora da consulta:
                                        <small>{{ item.datetimeStart | date:'dd/MM/yyyy \'às\' HH:mm' }}</small>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-audit-modal-warning">
            <button mat-flat-button color="accent" class="btn-block" (click)="openCancelConfirmModal()">
                <span *ngIf="isLoading == false">Cancelar todos os agendamentos</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
            <button mat-flat-button color="primary" class="btn-block" (click)="close()">
                <span *ngIf="isLoading == false">Fechar</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
        </div>
    </div>
</div>