import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-plot-table',
  templateUrl: './plot-table.component.html',
  styleUrls: ['./plot-table.component.css']
})
export class PlotTableComponent implements OnInit {

  constructor() { }

  @Input() listData: string[][] = [[]];
  @Input() variableName: string[];

  ngOnInit(): void {
  }
}
