import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { AccessKeyService } from 'src/app/shared/services/API/integration/access-key.service';
import { UserByHealthUnitService } from 'src/app/shared/services/API/user/user-by-health-uint.service';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { AccessKeyModel } from 'src/app/shared/services/models/integration/access-key.model';
import { AccessKeyRequest } from 'src/app/shared/services/requests/integration/access-key.request';
import { ListUserStruct } from 'src/app/shared/services/structs/user/list-user.struct';

@Component({
  selector: 'app-access-key-register',
  templateUrl: './access-key-register.component.html',
  styleUrls: ['./access-key-register.component.css']
})
export class AccessKeyRegisterComponent implements OnInit {

  constructor(private formBuilder: UntypedFormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private accessKeyService: AccessKeyService,
    private userByHealthUnitService: UserByHealthUnitService,
  ) { }
    
    public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.integration;
    public menuFunctionalityEnum:MenuFunctionalityEnum = MenuFunctionalityEnum.integration_key_list;
    
    public model: UntypedFormGroup;
    public isLoading: boolean;
    public isFirstLoading: boolean;
    public isUpdate: boolean;
    public actualServiceModule: number;
    
    public masks:Masks;
    public idAccessKey: number;
    public listUser: ListUserStruct[];
    public accessKey: AccessKeyModel;
    public accessKeyRequest: AccessKeyRequest;
    
    ngOnInit(): void {
      
      this.isLoading = false;
      
      this.model = this.formBuilder.group({
        name: ['', [Validators.required]],
        status: ['', [Validators.required]],
        description: [''],
        idUser: ['', Validators.required],
      });
      
      this.masks = this.maskService.getMasks();
      
      this.accessKeyRequest = new AccessKeyRequest();
      
      this.populateUserSelect();
      
      this.idAccessKey == null;
      this.isUpdate == false;
      if(this.activatedRoute.snapshot.paramMap.get('idAccessKey'))
      this.idAccessKey = parseInt(this.activatedRoute.snapshot.paramMap.get('idAccessKey'));
      
      if(this.idAccessKey != null){
        this.isFirstLoading = true;
        this.isUpdate = true;
        this.populateAccessKeyData();
      }
    }
    
    submit(){
      if(this.isLoading){
        return;
      }

      if(!this.model.valid) {
        this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
        return;
      }
      this.isLoading = true;   
      
      this.accessKeyRequest.description = this.model.get('description').value;
      this.accessKeyRequest.idUser = parseInt(this.model.get('idUser').value);

      if (this.model.get('status').value === "true") {
        this.accessKeyRequest.isActive = true;
      }
      if (this.model.get('status').value === "false") {
        this.accessKeyRequest.isActive = false;
      }
      
      this.accessKeyRequest.accessKeyName = this.model.get('name').value;
      
      if(this.isUpdate)
      this.updateAccessKey();
      else
      this.createAccessKey();
    }
    
    populateAccessKeyData(){
      this.accessKeyService.getAccessKey(this.idAccessKey).subscribe((response)=>{
        
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.model.get('name').setValue(response.accessKey.accessKeyName);
        this.model.get('status').setValue(response.accessKey.isActive.toString());
        this.model.get('idUser').setValue(response.accessKey.idUser.toString());
        this.model.get('description').setValue(response.accessKey.description);
        
        if(this.isUpdate) {
          this.model.get('idUser').disable();
        }

        this.isLoading = false; 
        this.isFirstLoading = false;
      },
      (error)=>{
        console.log(error)
        this.isLoading = false;    
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
    }
    
    populateUserSelect(){
      this.userByHealthUnitService.listUser().subscribe((response)=>{
        
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.listUser = response.listUser;      
      },
      (error)=>{
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
    }
    
    updateAccessKey(){
      this.accessKeyService.updateAccessKey(this.idAccessKey, this.accessKeyRequest).subscribe((response)=>{
        
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        
        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false; 
        
        this.router.navigate(['/integration/access-key-list']);
        
      },
      (error)=>{
        console.log(error)
        this.isLoading = false;    
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
    }
    
    createAccessKey(){
      this.accessKeyService.createAccessKey(this.accessKeyRequest).subscribe((response)=>{
        
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        
        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;    
        
        this.router.navigate(['/integration/access-key-list']);       
      },
      (error)=>{
        console.log(error)
        this.isLoading = false;    
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
    }
  }
  

