import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { CareLineMonitoringComponent } from './pages/care-line-monitoring/care-line-monitoring.component';
import { CareLineRegisterComponent } from './pages/care-line-register/care-line-register.component';

const routes: Routes = [
  { path: 'care-line-monitoring', component: CareLineMonitoringComponent, canActivate: [AuthGuard] },
  { path: 'care-line-monitoring/care-line-register', component: CareLineRegisterComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CareLineMonitoringRoutingModule { }
