import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { TissGuideAdmissionModalComponent } from 'src/app/shared/components/tiss-guides/tiss-guide-admission-modal/tiss-guide-admission-modal.component';
import { TissGuideAppointmentComponent } from 'src/app/shared/components/tiss-guides/tiss-guide-appointment/tiss-guide-appointment.component';
import { TissGuideFeesModalComponent } from 'src/app/shared/components/tiss-guides/tiss-guide-fees-modal/tiss-guide-fees-modal.component';
import { TissGuideSpSadtModalComponent } from 'src/app/shared/components/tiss-guides/tiss-guide-sp-sadt-modal/tiss-guide-sp-sadt-modal.component';
import { GuideTypeEnum } from 'src/app/shared/enum/private-billing/guide-type.enum';
import { AdmissionGuideService } from 'src/app/shared/services/API/private-billing/admission-guide.service';
import { AppointmentGuideService } from 'src/app/shared/services/API/private-billing/appointment-guide.service';
import { FeesGuideService } from 'src/app/shared/services/API/private-billing/fees-guide.service';
import { GuideService } from 'src/app/shared/services/API/private-billing/guide.service';
import { LookupService } from 'src/app/shared/services/API/private-billing/lookup.service';
import { SpSadtService } from 'src/app/shared/services/API/private-billing/sp-sadt.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Access } from 'src/app/shared/services/models/private-billing/access.model';
import { GuideStatus } from 'src/app/shared/services/models/private-billing/guide-status.model';
import { GuideType } from 'src/app/shared/services/models/private-billing/guide-type.model';
import { LookupResponse } from 'src/app/shared/services/responses/private-billing/lookup.response';
import { HealthcareAgreementStruct } from 'src/app/shared/services/structs/private-billing/healthcare-agreement.struct';
import { ListTissGuideReportStruct } from 'src/app/shared/services/structs/private-billing/list-tiss-guide-report.struct';
import { ProfessionalAgreementStruct } from 'src/app/shared/services/structs/private-billing/professional-agreement.struct';

@Component({
  selector: 'app-tiss-guide-report-list',
  templateUrl: './tiss-guide-report-list.component.html',
  styleUrls: ['./tiss-guide-report-list.component.css']
})
export class TissGuideReportListComponent implements OnInit {
  constructor(private alertService: AlertService,
    public dialog: MatDialog,
    private lookupService: LookupService,
    private admissionGuideService: AdmissionGuideService,
    private spSadtService: SpSadtService,
    private appointmentGuideService: AppointmentGuideService,
    private feesGuideService: FeesGuideService,
    private guideService: GuideService
  ) { }

  @ViewChild(MatPaginator) paginator: MatPaginator
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.billing;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.tiss_guide_report_list;

  public startOfAttendance: Date = null;
  public endOfAttendance: Date = null;

  public fullListSize: number;
  public pageIndex: number = 0;
  public pageSize: number = 50;
  public idEpisode: number = null;
  public idProfessionalAgreement: number = null;
  public idHealthcareAgreement: number = null;
  public idGuideStatus: number = null;
  public idGuideType: number = null;
  public isLoadingEdit: boolean = false;

  public listGuideType: GuideType[] = [];
  public listGuideStatus: GuideStatus[] = [];
  public listHealthcareAgreementStruct: HealthcareAgreementStruct[] = [];
  public listProfessionalAgreementStruct: ProfessionalAgreementStruct[] = [];
  public listTissGuideReportStruct: ListTissGuideReportStruct[] = [];
  public listAccess: Access[] = [];
  public lookups: LookupResponse;

  public isLoading: boolean = true;
  public isUpdateListProfessional: boolean;

  ngOnInit(): void {
    this.getLookup();
  }

  getLookup() {
    this.lookupService.listAllLookups().subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.listGuideStatus = response.listGuideStatus;
        this.listHealthcareAgreementStruct = response.listHealthcareAgreementStruct;
        this.listGuideType = response.listGuideType;
        this.lookups = response;
        this.listAccess = response.listAccess;

        this.search();
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  search() {
    this.isLoading = true;

    if (this.startOfAttendance)
      this.startOfAttendance.setHours(0);

    if (this.endOfAttendance) {
      this.endOfAttendance.setHours(23);
      this.endOfAttendance.setMinutes(59);
    }

    this.guideService.GetAllByIdHealthUnit(this.startOfAttendance, this.endOfAttendance,
      this.idEpisode, this.idHealthcareAgreement,
      this.idGuideType, this.idGuideStatus,
      this.idProfessionalAgreement, this.pageIndex).subscribe({
        next: (response) => {
          if (response.isError) {
            this.throwException(response.errorDescription);
            return;
          }

          this.listTissGuideReportStruct = response.listTissGuideReportStruct;
          this.fullListSize = response.itemCount;
          this.pageSize = response.pageSize;
          this.isLoading = false;
        },
        error: (error) => {
          this.throwException(error);
        }
      });
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13)
        $this.pageIndex = 0;
      if ($this.paginator)
        $this.paginator.firstPage();

      $this.search();
    },
      1000);
  }

  changePage(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.search();
  }

  cancel() {
    this.idEpisode = null;
    this.idProfessionalAgreement = null;
    this.idHealthcareAgreement = null;
    this.idGuideStatus = null;
    this.idGuideType = null;
    this.startOfAttendance = null;
    this.endOfAttendance = null;

    this.search();
  }

  selectGuide(tissGuideReport: ListTissGuideReportStruct) {
    this.isLoadingEdit = true;

    if (tissGuideReport.idGuideType == GuideTypeEnum.consulta)
      this.getAppointmentGuide(tissGuideReport);
    if (tissGuideReport.idGuideType == GuideTypeEnum.honorarios)
      this.getFeesGuide(tissGuideReport);
    if (tissGuideReport.idGuideType == GuideTypeEnum.spSadt)
      this.getSpSadtGuide(tissGuideReport);
    if (tissGuideReport.idGuideType == GuideTypeEnum.internacao)
      this.getAdmissionGuide(tissGuideReport);
  }

  getAppointmentGuide(tissGuideReport: ListTissGuideReportStruct) {
    this.appointmentGuideService.GetAppointmentGuideByIdEpisode(tissGuideReport.idEpisode).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        let appointmentGuideStruct = response.listAppointmentGuideStruct[0];
        if (appointmentGuideStruct && appointmentGuideStruct != null) {
          const dialogRef = this.dialog.open(TissGuideAppointmentComponent, {
            data: {
              lookups: this.lookups,
              idEpisode: tissGuideReport.idEpisode,
              guide: appointmentGuideStruct,
              isAttendance: false,
              patientName: null,
              patientSocialName: null,
              idSector: null,
              idRoom: null,
              isComingGuideReport: true
            },
            panelClass: 'sp-sadt-tiss-guide-modal',
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result && result.confirm)
              this.search();

            this.isLoadingEdit = false;
          });
        }
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  getFeesGuide(tissGuideReport: ListTissGuideReportStruct) {
    this.feesGuideService.GetFeesGuideByIdEpisode(tissGuideReport.idEpisode).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        let feesGuideStruct = response.listFeesGuideStruct[0];
        if (feesGuideStruct && feesGuideStruct != null) {
          const dialogRef = this.dialog.open(TissGuideFeesModalComponent, {
            data: {
              lookups: this.lookups,
              idEpisode: this.idEpisode,
              guide: feesGuideStruct,
              isAttendance: false,
              patientName: null,
              patientSocialName: null,
              idSector: null,
              idRoom: null,
              isComingGuideReport: true
            },
            panelClass: 'fees-tiss-guide-modal',
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result && result.confirm)
              this.search();

            this.isLoadingEdit = false;
          });
        }
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  getSpSadtGuide(tissGuideReport: ListTissGuideReportStruct) {
    this.spSadtService.GetSpSadtGuideByIdEpisode(tissGuideReport.idEpisode).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        let spSadtGuideStruct = response.listSpSadtGuideStruct[0];
        if (spSadtGuideStruct && spSadtGuideStruct != null) {
          const dialogRef = this.dialog.open(TissGuideSpSadtModalComponent, {
            data: {
              lookups: this.lookups,
              idEpisode: this.idEpisode,
              guide: spSadtGuideStruct,
              isAttendance: false,
              patientName: null,
              patientSocialName: null,
              idSector: null,
              idRoom: null,
              isComingGuideReport: true
            },
            panelClass: 'sp-sadt-tiss-guide-modal',
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result && result.confirm)
              this.search();

            this.isLoadingEdit = false;
          });
        }
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  getAdmissionGuide(tissGuideReport: ListTissGuideReportStruct) {
    this.admissionGuideService.GetAdmissionGuideByIdEpisode(tissGuideReport.idEpisode).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        let admissionGuideStruct = response.listAdmissionGuideStruct[0];
        if (admissionGuideStruct && admissionGuideStruct != null) {
          const dialogRef = this.dialog.open(TissGuideAdmissionModalComponent, {
            data: {
              lookups: this.lookups,
              idEpisode: this.idEpisode,
              guide: admissionGuideStruct,
              isAttendance: false,
              patientName: null,
              patientSocialName: null,
              idSector: null,
              idRoom: null,
              isComingGuideReport: true
            },
            panelClass: 'admission-tiss-guide-modal',
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result && result.confirm)
              this.search();

            this.isLoadingEdit = false;
          });
        }
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  private throwException(error: string) {
    console.log(error);
    this.alertService.show('Erro inesperado', error, AlertType.error);
    this.isLoading = false;
  }
}