export class ReSendEpisodReportRequest {
    public idEpisode: number;
    public patientName: string;
    public userName: string;
    public email: string;
    public cpf: string;
    public allReport: boolean;
    public medicalCareReport: boolean;
    public examExternal: boolean;
    public examInternal: boolean;
    public prescriptionExternal: boolean;
    public prescriptionInternal: boolean;
    public sickNote: boolean;
    public isMedicalProcedureReport: boolean;
    public isolation: boolean;
    public isolationAcknowledge: boolean;
}