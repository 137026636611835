export class EpisodeCareLineRequest {
    public idEpisode: number;

    public listIdCareLine: number[];

    public idUser: number;

    public idMedicalCare: number;

    public idMultiprofessionalCare: number;

    public phoneNumber: string;
}
