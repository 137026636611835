import { AfterViewInit, Component, NgZone, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult, PopupRequest, SilentRequest } from '@azure/msal-browser';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { LoginTypeEnum } from 'src/app/shared/enum/user/login-type.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { UserloginService } from 'src/app/shared/services/API/user/user-login.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UserLoginResponse } from 'src/app/shared/services/responses/user/auth.response';
import { HealthUnitStruct } from 'src/app/shared/services/structs/user/health-unit.struct';
import { environment } from 'src/environments/environment';
import { HealthUnitListModalComponent } from '../health-unit-list-modal/health-unit-list-modal.component';
import { FirstAccessModalComponent } from './first-access-modal/first-access-modal.component';
import { HealthUnitConfirmModalComponent } from './health-unit-confirm-modal/health-unit-confirm-modal.component';
import { StayConnected, UtilService } from 'src/app/shared/services/util.service';
declare var google: any;

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit, AfterViewInit {

  constructor(private alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private userloginService: UserloginService,
    private msalService: MsalService,
    private utilService: UtilService,
    private zone: NgZone
  ) {
  }

  public model: UntypedFormGroup;
  public isLoading: boolean;
  public listHealthUnit: HealthUnitStruct[];
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.auth;
  public idHealthUnit: number;
  public state: boolean;
  public biRedirect: string;
  public ip: string = '192.168.0.2';
  public canStayConnected: boolean = false;
  public idUser: number;
  public stayConnected: StayConnected;
  public browser: string = "";
  public device: string = "desconhecido";
  public isAutomaticLogin: boolean = false;
  public automaticLoginLoading: boolean = true;
  public isMobileVersion: boolean = false;

  ngAfterViewInit(): void {
    // @ts-ignore   
    window.onGoogleLibraryLoad = this.googleRenderButton.bind(this);
    this.googleRenderButton();
  }

  googleRenderButton() {
    google.accounts.id.initialize({
      client_id: environment.googleClientId,
      callback: this.handleCredentialResponse.bind(this)
    });
    google.accounts.id.renderButton(
      document.getElementById("googleButtonDiv"),
      { theme: "outline", size: "large", width: "330" }  // customization attributes
    );
    google.accounts.id.prompt(); // also display the One Tap dialog
  }

  ngOnInit(): void {
    //this.alertService.show("Titulo", "Production: " + environment.production + " - Staging: " + environment.staging , AlertType.success);
    let token = this.activatedRoute.snapshot.paramMap.get('token');
    let tokenPowerBILogin = this.activatedRoute.snapshot.paramMap.get('tokenPowerBILogin');
    this.biRedirect = this.activatedRoute.snapshot.paramMap.get('biRedirect');
    if (token) {
      this.singinToken(token);
    } else {
      this.isLoading = false;
    }

    if (tokenPowerBILogin) {
      this.singinTokenPowerBILogin(tokenPowerBILogin);
    }
    else {
      this.isLoading = false;
    }

    this.stayConnected = new StayConnected();
    this.stayConnected = this.utilService.getGuidStayConnected();
    if (this.stayConnected && this.stayConnected.guid) {
      this.isAutomaticLogin = true;
      this.getAutomaticLoginByGuid();
    }

    this.model = this.formBuilder.group({
      login: ['', [Validators.required]],
      password: ['', [Validators.required]],
      idHealthUnit: ['', [Validators.required]],
      stayConnected: [false, [Validators.required]]
    });

    this.state = true;

    if ('userAgentData' in navigator) {
      let brands = (window.navigator as any).userAgentData.brands;
      if (brands.length > 0) {
        this.browser = brands[1]['brand'];
        if (this.browser.includes('Brand'))
          this.browser = brands[2]['brand'];
      }
      if (window.navigator['userAgentData'].platform != null && window.navigator['userAgentData'].platform != '')
        this.device = window.navigator['userAgentData'].platform;
    }

    this.isMobile();

    setTimeout(() => { this.getIP(); }, 2000);

  }

  isMobile() {
    const userAgent: string = navigator.userAgent.toLowerCase();

    let isMobile: boolean = /iphone|android/i.test(navigator.userAgent);

    let isTablet: boolean = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);

    if (isMobile || isTablet || window.innerWidth < 768)
      this.isMobileVersion = true;
    else
      this.isMobileVersion = false;

    if (this.isMobile) {
      if (userAgent.includes('android')) {
        this.device = 'android';
      } else if (userAgent.includes('iphone')) {
        this.device = 'iphone';
      } else if (userAgent.includes('ipad')) {
        this.device = 'ipad';
      }
    }

  }

  getIP() {
    this.utilService.getIP().subscribe({
      next: (response) => {
        if (response && response.ip) {
          this.ip = response.ip;
        }
      }
    });
  }

  async handleCredentialResponse(response: any) {
    await this.signInOAuth(response.credential, LoginTypeEnum.google)
  }

  singinToken(token: string) {
    this.isLoading = true;

    this.authService.signInSuperUser(token, this.ip)
      .subscribe({
        next: (response) => {

          if (response.isError) {
            this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
            this.isLoading = false;
            return;
          }

          else {
            response.canStayConnected = this.canStayConnected;
            this.authService.updateToken(response);

            this.isLoading = false;

            if (response.idDefaultModule && response.uriDefaultModule)
              this.router.navigate([response.uriDefaultModule])
            else
              this.router.navigate(['/welcome'])
          }
        },
        error: (error) => {
          this.alertService.show("Erro inesperado", error, AlertType.error);
          this.isLoading = false;
        }
      });
  }

  singinTokenPowerBILogin(tokenPowerBILogi: string) {
    this.isLoading = true;
    this.authService.singinTokenPowerBILogin(tokenPowerBILogi)
      .subscribe({
        next: (response) => {

          if (response.isError) {
            this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
            this.isLoading = false;
            return;
          }

          response.canStayConnected = this.canStayConnected;
          this.authService.updateToken(response);
          this.isLoading = false;
          this.router.navigate(['/bi/list'])
          // if (response.idDefaultModule && response.uriDefaultModule)
          //   this.router.navigate([response.uriDefaultModule])
          // else
          //   this.router.navigate(['/welcome'])
        },
        error: (error) => {
          this.alertService.show("Erro inesperado", error, AlertType.error);
          this.isLoading = false;
        }
      });
  }

  checkLogin() {
    if (!this.model.get('login').value || this.isLoading == true) {
      this.alertService.show("Erro", 'O campo de matrícula ou CPF é obrigatório', AlertType.error);
      return;
    }


    this.isLoading = true;

    this.userloginService.listUserHealthUnit(this.model.get('login').value).subscribe({
      next: (response) => {

        if (response.isError) {
          this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.listHealthUnit = response.listHealthUnit;
        this.idUser = response.idUser;

        if (response.listHealthUnit.length == 1) {
          this.model.get('idHealthUnit').setValue(response.listHealthUnit[0].idHealthUnit.toString());
          if (response.listHealthUnit[0].canStayConnected !== undefined)
            this.canStayConnected = response.listHealthUnit[0].canStayConnected;
        }

        this.isLoading = false;
        this.state = false;
      },
      error: (error) => {
        this.alertService.show("Erro inesperado", error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  signin() {
    if (this.model.invalid || this.isLoading)
      return;

    this.isLoading = true;

    this.idHealthUnit = this.model.get('idHealthUnit').value;

    let userAuthRequest = {
      login: this.model.get('login').value,
      password: this.model.get('password').value,
      ip: this.ip,
      idHealthUnit: this.idHealthUnit,
      idLoginType: LoginTypeEnum.loginAndPassword,
      stayConnected: this.model.get('stayConnected').value,
      browser: this.browser,
      device: this.device,
      isMobile: this.isMobileVersion
    };

    this.authService.signIn(userAuthRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (response.isFirstAccess || response.isExpiredPassword) {
          this.openModal(this.model.get('login').value, response.isExpiredPassword);
          return;
        }

        else {
          this.openHealthUnitConfirmModal(response);
        }
      },
      error: (error) => {
        this.alertService.show("Erro inesperado", error, AlertType.error);
        this.isLoading = false;
        this.changeState();
      }
    });
  }

  forgotPassword() {
    if (this.isLoading)
      return;

    this.router.navigate(['/forgot-password']);

  }

  openModal(userLogin: string, isExpiredPassword: boolean) {
    this.isLoading = true;
    const dialogRef = this.dialog.open(FirstAccessModalComponent, {
      data: {
        userLogin: userLogin,
        idHealthUnit: this.idHealthUnit,
        isExpiredPassword: isExpiredPassword
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      this.isLoading = false;
      this.model.get('password').setValue('');
      return;
    });
  }

  openHealthUnitConfirmModal(response: UserLoginResponse) {
    this.isLoading = true;
    this.zone.run(x => {
      const dialogRef = this.dialog.open(HealthUnitConfirmModalComponent, {
        data: {
          haveMultipleHealthUnit: response.haveMultipleHealthUnit,
          healthUnit: response.healthUnit.healthUnitName,
        },
        panelClass: 'health-unit-confirm',
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result && !result.confirmLogin) {
          this.state = true;
          this.isLoading = false;
          this.listHealthUnit = null;
          this.model.get('login').setValue('');
          this.model.get('password').setValue('');
          this.model.get('idHealthUnit').setValue('');
          return;
        }
        else {
          response.canStayConnected = this.canStayConnected;
          this.authService.updateToken(response);
          if (this.model.get('stayConnected').value && response.guid != null) {
            this.stayConnected = new StayConnected();
            this.stayConnected.guid = response.guid;
            let userName = response.userName.split(" ");
            if (userName[0].length < 4)
              this.stayConnected.name = userName[0] + " " + userName[1];
            else
              this.stayConnected.name = userName[0];
            this.stayConnected.healthUnit = response.healthUnit.healthUnitName;
            this.utilService.setGuidStayConnected(this.stayConnected);
          }

          this.isLoading = false;

          if (response.idDefaultModule && response.uriDefaultModule) {
            this.router.navigate([response.uriDefaultModule])
          }
          else if (this.biRedirect) {
            this.router.navigate(['/bi/details', { idReportFunctionHealthUnit: this.biRedirect }])
          }
          else
            this.router.navigate(['/welcome'])
        }
      });
    });
  }

  changeState() {
    this.state = true;
    this.listHealthUnit = null;
    this.model.get('idHealthUnit').setValue('');
  }

  async msalLogin() {
    let loginRequest = { prompt: "select_account", scopes: ["openid", "profile", "User.Read"] };

    let accInfo = this.msalService.instance.getActiveAccount();
    if (accInfo) {
      this.msalService.logoutPopup({ account: accInfo }).subscribe({
        next: result => {
          sessionStorage.clear();
          this.msalService.loginPopup(loginRequest).subscribe({
            next: (response: AuthenticationResult) => {
              this.msalService.instance.setActiveAccount(response.account);
              this.signInOAuth(response.accessToken, LoginTypeEnum.microsoft);
            },
            error: error => {
              console.log(error)
              this.alertService.show("Erro inesperado", error, AlertType.error);
              this.isLoading = false;
            }
          });
        }
      });
    }
    else {
      sessionStorage.clear();
      this.msalService.loginPopup(loginRequest).subscribe({
        next: (response: AuthenticationResult) => {
          this.msalService.instance.setActiveAccount(response.account);
          this.signInOAuth(response.accessToken, LoginTypeEnum.microsoft);
        },
        error: error => {
          console.log(error)
          this.isLoading = false;
        }
      });
    }
  }

  async isLoggedInMsal(): Promise<void> {
    let accInfo = this.msalService.instance.getActiveAccount();
    if (accInfo) {
      this.msalService.logoutPopup({ account: accInfo });
    }
  }

  signInOAuth(token: string, idLoginType: number, idHealthUnit?: number) {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    this.authService.signInOAuth(token, this.ip, idLoginType, idHealthUnit).subscribe({
      next: response => {
        if (response.isError) {
          this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        if (response.listHealthUnit && response.listHealthUnit.length > 1) {
          this.openHealthUnitListModal(response, token, idLoginType);
          this.isLoading = false;
          return;
        }

        else {
          response.canStayConnected = this.canStayConnected;
          this.authService.updateToken(response);
          this.openHealthUnitConfirmModal(response);
        }
      },
      error: error => {
        console.log(error);
        this.alertService.show("Erro inesperado", error, AlertType.error);
        this.isLoading = false;
      }
    })
  }

  openHealthUnitListModal(response: UserLoginResponse, accessToken: string, idLoginType: number) {
    this.zone.run(x => {
      const dialogRef = this.dialog.open(HealthUnitListModalComponent, {
        data: {
          listHealthUnit: response.listHealthUnit,
          accessToken: accessToken,
          idLoginType: idLoginType,
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.confirmLogin) {
          response.canStayConnected = this.canStayConnected;
          this.authService.updateToken(result.response);
          this.openHealthUnitConfirmModal(result.response);
        }
      });
    })
  }

  getAutomaticLoginByGuid() {
    if (this.isLoading) {
      return;
    }
    this.automaticLoginLoading = true;
    this.isLoading = true;

    this.authService.automaticSignIn(this.stayConnected.guid, this.ip).subscribe({
      next: response => {
        if (response.isError) {
          this.isLoading = false;
          this.automaticLoginLoading = false;
          return;
        }

        response.canStayConnected = true;
        this.authService.updateToken(response);

        if (response.idDefaultModule && response.uriDefaultModule) {
          this.router.navigate([response.uriDefaultModule])
        }
        else if (this.biRedirect) {
          this.router.navigate(['/bi/details', { idReportFunctionHealthUnit: this.biRedirect }])
        }
        else
          this.router.navigate(['/welcome'])
        this.isLoading = false;
        this.automaticLoginLoading = false;

      },
      error: error => {
        console.log(error);
        this.alertService.show("Erro inesperado", error, AlertType.error);
        this.isLoading = false;
        this.automaticLoginLoading = false;
      }
    });
  }

  returnLoginScreen() {
    this.isLoading = true;
    this.state = true;
    this.isAutomaticLogin = false;
    this.automaticLoginLoading = false;
    this.listHealthUnit = null;
    this.model.get('login').setValue('');
    this.model.get('password').setValue('');
    this.model.get('idHealthUnit').setValue('');
    this.model.get('stayConnected').setValue(false);
    this.isLoading = false;
  }

  setCanStayConnected(event: any) {
    this.canStayConnected = event
  }
}
