<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <h1>Leitos</h1>
        <div class="white-body">
            <a mat-flat-button color="primary" class=" add-user" [routerLink] = "['/observation/bed/register', {hygienization: hygienization}]"><mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon> Cadastrar Leito</a>
            <div class="filters">
                <div class="row">   
                    <div class="col-12 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Pesquisar</mat-label>
                            <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText" (keyup)="onKeySearch($event)">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-md-2"> 
                        <mat-form-field appearance="outline">
                          <mat-label>Status</mat-label>
                          <mat-select [(ngModel)]="idBedStatus" (selectionChange)="search()">
                            <mat-option value="">Todos</mat-option> 
                            <mat-option *ngFor="let bedStatus of listBedStatus" value="{{bedStatus.idBedStatus}}">{{bedStatus.bedStatusName}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-md-2"> 
                        <mat-form-field appearance="outline">
                          <mat-label>Tipo do Leito</mat-label>
                          <mat-select [(ngModel)]="idBedType" (selectionChange)="search()">
                            <mat-option value="">Todos</mat-option> 
                            <mat-option *ngFor="let bedType of listBedType" value="{{bedType.idBedType}}">{{bedType.bedTypeName}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Categoria do Leito</mat-label>
                            <mat-select [(ngModel)]="idBedCategory" (selectionChange)="search()">
                              <mat-option value="">Todos</mat-option> 
                              <mat-option *ngFor="let item of listBedCategory" value="{{item.idBedCategory}}">{{item.bedCategoryName}}</mat-option> 
                            </mat-select>
                        </mat-form-field>
                    </div>    
                </div>
            </div>
            <div class="card-list" *ngIf="listBed != null">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listBed">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary">{{item.bedName}}</span>
                                <span class="secundary">{{item.bedTypeName}}</span>
                                <span class="secundary">{{item.bedStatusName}}</span>
                                <span class="secundary">{{item.bedCategoryName}}</span>
                                <span *ngIf="hygienization && (item.idHygienizationStatus == unhygienizedStatus)" class="secundary bed-status-unhygienized">{{item.hygienizationStatusName}}</span>
                                <span *ngIf="hygienization && (item.idHygienizationStatus == hygienizingStatus)" class="secundary bed-status-hygienizing">{{item.hygienizationStatusName}}</span>
                                <span *ngIf="hygienization && (item.idHygienizationStatus == hygienizedStatus)" class="secundary bed-status-hygienized">{{item.hygienizationStatusName}}</span>
                                <span *ngIf="hygienization && (item.idHygienizationStatus == occupiedStatus)" class="secundary bed-status-occupied">{{item.hygienizationStatusName}}</span>
                                <span *ngIf="hygienization" class="secundary">{{item.hygienizationUserName}}</span>
                            </div>
                            <div class="actions">
                                <a><mat-icon aria-hidden="false" aria-label="Editar" [routerLink] = "['/observation/bed/register', {idBed: item.idBed, hygienization: hygienization}]">edit</mat-icon> </a>
                                <a><mat-icon aria-hidden="false" aria-label="Editar" (click)="openModal(item.idBed)">delete_forever</mat-icon> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <app-empty-list *ngIf="listBed != undefined && listBed.length == 0"></app-empty-list>
        </div>
    
        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>
    
</div>