import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EpisodeStatusRequest } from 'src/app/shared/services/requests/medical-record/episode-status.request';
import { EpisodeStatusService } from 'src/app/shared/services/API/medical-record/episode-status.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { EpisodeStatusEnum } from 'src/app/shared/enum/episode-status.enum';
import { QueueHistoryService } from 'src/app/shared/services/API/orchestrator-queue/queue-history.service';
import { ReturnPatientRequest } from 'src/app/shared/services/requests/orchestrator-queue/return-patient.request';

@Component({
  selector: 'app-return-motive-modal',
  templateUrl: './return-motive-modal.component.html',
  styleUrls: ['./return-motive-modal.component.css']
})
export class ReturnMotiveModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ReturnMotiveModalComponent>,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    public queueService: QueueHistoryService) { }

  public motiveModel: UntypedFormGroup;
  public isLoading: boolean = false;
  
  ngOnInit(): void {
    this.motiveModel = this.formBuilder.group({
      motive: ['', Validators.required],
    });
  }


  clickCancel() {
    this.matDialogRef.close();
  }

  clickOK() {
    if(this.motiveModel.invalid){
      this.alertService.show("Erro", "O campo motivo é obrigatório!", AlertType.error);
      return;
    }
    this.isLoading = true;
    var body:ReturnPatientRequest = new ReturnPatientRequest();
    body.motive = this.motiveModel.get("motive").value;
    this.matDialogRef.close(body);
  }
}

