import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { GetFlowResponse } from '../../responses/flow/get-flow.response';
import { OrganizationResponse } from '../../responses/flow/organization.response';


@Injectable({
  providedIn: 'root'
})
export class OrganizationService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public GetOrganization(): Observable<OrganizationResponse>{

    let uri = `Organization/getAll`

    return this.httpClient.get<OrganizationResponse>(environment.urlApiFlow + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public getTelemedicineFlow(): Observable<GetFlowResponse>{

    let uri = `Organization`

    return this.httpClient.get<GetFlowResponse>(environment.urlApiFlow + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
