import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ClientBiPanelService } from 'src/app/shared/services/API/business-intelligence/client-bi-panel.service';
import { GetBiPanelResponse } from 'src/app/shared/services/responses/business-intelligence/get-bi-panel.response';
import { UtilsClientService } from '../../utils.service';

@Component({
  selector: 'app-bi-panel',
  templateUrl: './bi-panel.component.html',
  styleUrls: ['./bi-panel.component.css']
})
export class BiPanelComponent implements OnInit, OnDestroy {

  authenticated: boolean = false;
  pin: string;
  public idBiPanel: number;
  public isLoading: boolean = false;
  public panelStruct: GetBiPanelResponse;
  onlineOffline: boolean = navigator.onLine;
  public panelTime: Date;
  public timeoutIntervalId;
  public panelIndex:number;

  private intervalId = setInterval(() => {
    if (this.onlineOffline !== navigator.onLine) {
      this.onlineOffline = navigator.onLine;
    }
  }, 1000);

  ngOnDestroy() {
    clearInterval(this.intervalId);
    clearTimeout(this.timeoutIntervalId);
  }

  constructor(
    private alertService: AlertService,
    private clientBiPanelService: ClientBiPanelService,
    private activatedRoute: ActivatedRoute,
    private utilService: UtilsClientService,
    private cdref: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.idBiPanel = parseInt(this.activatedRoute.snapshot.paramMap.get('id'));
    if(parseInt(this.utilService.getBiPanelIndex(this.idBiPanel)) >= 0){
      this.panelIndex = parseInt(this.utilService.getBiPanelIndex(this.idBiPanel));
    }
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  checkPin(event) {
    try {
      if (!event || event == null) {
        throw "PIN não pode estar vazio";
      }

      this.pin = event;
      this.isLoading = true;
      this.clientBiPanelService.verifyPin(this.idBiPanel, event).subscribe({next:(response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          this.utilService.removeBiPanelPin(this.idBiPanel);
          this.utilService.removeBiPanelIndex(this.idBiPanel);
          return;
        }

        this.panelStruct = response;
        if(this.panelIndex == null || this.panelIndex== undefined){
          this.alertService.show('Sucesso', 'PIN autenticado com sucesso', AlertType.success);
        }
        this.authenticated = true;
        this.isLoading = false;
      },
      error:(error) => {
        console.log(error)
        this.isLoading = false;
        this.utilService.removeBiPanelPin(this.idBiPanel);
        this.utilService.removeBiPanelIndex(this.idBiPanel);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.timeoutIntervalId = setTimeout(() => window.location.reload(), 8000);
      }});
    }
    catch (error) {
      console.log(error)
      this.isLoading = false;
      this.utilService.removeBiPanelPin(this.idBiPanel);
      this.utilService.removeBiPanelIndex(this.idBiPanel);
      this.alertService.show('Erro inesperado', error, AlertType.error);
      this.timeoutIntervalId = setTimeout(() => window.location.reload(), 8000);
    }
  }
}
