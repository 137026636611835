import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { TotemNpsService } from 'src/app/shared/services/API/nps/totem-nps.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { TotemNpsRequest } from 'src/app/shared/services/requests/nps/totem-nps.request';
import { UserLoginResponse } from 'src/app/shared/services/responses/user/auth.response';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-totem-nps-register',
  templateUrl: './totem-nps-register.component.html',
  styleUrls: ['./totem-nps-register.component.css']
})
export class TotemNpsRegisterComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private totemNpsService: TotemNpsService,
    private util: UtilService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.master;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.master_totem_nps;

  public model: FormGroup;
  public isLoading: boolean = false;
  public isFirstLoading: boolean;
  public isUpdate: boolean;
  public useQrCode: boolean = false;
  public masks: Masks = this.maskService.getMasks();
  public idTotemNps: number;
  public totemNpsRequest: TotemNpsRequest;
  ip: string = '192.168.0.2';

  ngOnInit(): void {
    this.idTotemNps == null;
    this.isUpdate == false;

    if (this.activatedRoute.snapshot.paramMap.get('idTotemNps'))
      this.idTotemNps = parseInt(this.activatedRoute.snapshot.paramMap.get('idTotemNps'));

    this.model = this.formBuilder.group({
      totemNpsName: ['', [Validators.required]],
      npsText: [''],
      isActive: ['', [Validators.required]],
      isToRequestCpf: [false, [Validators.required]],
    });

    if (this.idTotemNps != null) {
      this.isFirstLoading = true;
      this.isUpdate = true;
      this.populateTotemData();
    }

    setTimeout(() => { this.getIP(); }, 2000);
  }

  getIP() {
    this.util.getIP().subscribe({
      next: (response) => {
        if (response && response.ip) {
          this.ip = response.ip;
        }
      }
    });
  }

  submit() {
    if (this.isLoading) {
      return;
    }
    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }
    this.totemNpsRequest = new TotemNpsRequest();
    this.isLoading = true;

    this.totemNpsRequest.totemNpsName = this.model.get('totemNpsName').value;
    this.totemNpsRequest.npsText = this.model.get('npsText').value;
    this.totemNpsRequest.isToRequestCpf = this.model.get('isToRequestCpf').value;
    this.totemNpsRequest.isActive = this.model.get('isActive').value === "true" ? true : false;
    this.totemNpsRequest.ip = this.ip;
    let token: UserLoginResponse = this.util.getToken();

    if (token == null) {
      this.alertService.show('Erro', "Erro ao capturar informações da unidade de saúde", AlertType.error);
      this.isLoading = false;
      return;
    }
    this.totemNpsRequest.healthUnitName = token.healthUnit.healthUnitName;

    if (this.isUpdate)
      this.updateTotem();
    else
      this.createTotem();
  }

  populateTotemData() {
    this.totemNpsService.getTotem(this.idTotemNps).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.model.get('isActive').setValue(response.totemNps.isActive.toString());
        this.model.get('isToRequestCpf').setValue(response.totemNps.isToRequestCpf);
        this.model.get('totemNpsName').setValue(response.totemNps.totemNpsName);
        this.model.get('npsText').setValue(response.totemNps.npsText);

        this.isLoading = false;
        this.isFirstLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  updateTotem() {
    this.totemNpsService.updateTotem(this.idTotemNps, this.totemNpsRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;

        this.router.navigate(['/master/totem-nps']);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  createTotem() {
    this.totemNpsService.createTotem(this.totemNpsRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;

        this.router.navigate(['/master/totem-nps']);

      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}