<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <a class="back-link" routerLink="/remote-monitoring/sync-measurer"><mat-icon aria-hidden="false"
                aria-label="Plus">arrow_back</mat-icon> Equipamentos</a>
        <h1>Cadastrar Equipamento</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-6">
                        <mat-form-field appearance="outline">
                            <mat-label>ID Equipamento</mat-label>
                            <input matInput type="text" formControlName="syncMeasurerHash">
                            <mat-error *ngIf="model.get('syncMeasurerHash').invalid">Informe o identificador do
                                equipamento</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Status</mat-label>
                            <mat-select formControlName="status">
                                <mat-option value="true">Ativo</mat-option>
                                <mat-option value="false">Inativo</mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('status').invalid">Informe o Status</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="title-header">
                    <h1>Pacientes</h1>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <button mat-flat-button color="accent" class="" type="button" (click)="openModal(null)">
                            <mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon>
                            Cadastrar paciente
                        </button>
                    </div>
                    <app-list-patient [listPatient]="listPatient" (patientDetails)="patientDetails($event)"
                        (deletePatient)="deletePatient($event)"></app-list-patient>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <a mat-flat-button type="button" routerLink="/remote-monitoring/sync-measurer" color="accent"
                            class=" btn-block">
                            Cancelar
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>