<div mat-dialog-content>
    <div class="mat-dialog-header">
        <h3>{{title}}</h3>
    </div>
    <div class="mat-dialog-content">
        <form class="form" [formGroup]="conclusionModel">
            <div class="row">
                <div class="col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Conclusão</mat-label>
                        <textarea matInput formControlName="conclusionNote"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </form>
        <div class="footer-panel-modal-list">
            <div class="row">
                <div class="col-6">
                    <button mat-flat-button color="primary" class="btn-block" *ngIf="isLoading == false" (click)="clickOK()">Ok</button>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </div>
                <div class="col-6">
                    <button mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Cancelar</button>
                </div>
            </div>
        </div>
    </div>
</div>