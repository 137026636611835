import { Component, EventEmitter, Inject, LOCALE_ID, OnInit, Output } from '@angular/core';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { PatientStruct } from 'src/app/shared/services/structs/medical-record/patient.struct';
import { TriageStruct } from 'src/app/shared/services/structs/orchestrator-patient/triage.struct';
import { TriageService } from 'src/app/shared/services/API/orchestrator-classification/triage.service';
import { DiscriminatorTypeEnum } from 'src/app/shared/enum/discriminator-type.enum';
import { CounselingStruct } from 'src/app/shared/services/structs/risk-classification/counseling.struct';
import { PatientRequest } from 'src/app/shared/services/requests/medical-record/patient.request';
import { MedicalRecordLookupService } from 'src/app/shared/services/API/medical-record/lookup.service';
import { Gender } from 'src/app/shared/services/responses/medical-record/lookup.response';
import { ClassificationData, UtilsClassificationService } from 'src/app/classification/utils.service';
import { UtilsIframeService } from '../../../util-iframe.service';
import { ClockService } from 'src/app/shared/services/API/orchestrator-queue/clock.service';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-iframe-classification-details',
  templateUrl: './iframe-classification-details.component.html',
  styleUrls: ['./iframe-classification-details.component.css']
})
export class IframeClassificationDetailsComponent implements OnInit {

  constructor(private alertService: AlertService,
    private triageService: TriageService,
    private utilService: UtilService,
    private formBuilder: UntypedFormBuilder,
    private maskService: MaskService,
    private utilClassificationService: UtilsClassificationService,
    public router: Router,
    public lookupService: MedicalRecordLookupService,
    public utilsIframeService: UtilsIframeService,
    private clockService: ClockService) { }

  @Output() previousPage = new EventEmitter<any>();

  public model: UntypedFormGroup;
  public masks: Masks;
  public triage: TriageStruct;
  public patientDetails: PatientStruct;
  public isLoading: boolean = true;
  public isFirstLoading: boolean = true;
  public data: ClassificationData;
  public discriminators: any[] = [];
  public pain: number;
  public painType: number;
  public protocolName: string;
  public listCounseling: string = '';
  public listGender: Gender[];
  public endClassification: Date;

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      listIdPriority: [''],
      patientName: [''],
      socialName: [''],
      birthDate: [''],
      patientAge: [{ value: '', disabled: true }],
      genderName: [''],
      cpf: [''],
      cns: [''],
      city: [''],
      street: [''],
      houseNumber: [''],
      apartmentNumber: [''],
      state: [''],
      country: [''],
      neighborhood: [''],
      zipCode: [''],
      advice: [''],
      observation: [''],
      listCounseling: ['']
    });
    this.data = this.utilClassificationService.getClassificationData();
    if (this.data.listCounseling) {
      this.data.listCounseling.forEach((counseling: CounselingStruct) => {
        this.listCounseling = this.listCounseling + counseling.counselingName + '\n';
      })
    }
    this.getDateTime();
    this.patientDetails = this.mapRequestToStruct(this.data.patientData);
  }

  getDateTime() {
    this.clockService.getDate().subscribe((response) => {
      this.endClassification = response.date;
      this.mapValues();
    });
  }

  mapValues() {
    this.model.get('patientName').setValue(this.data.patientData.patientName);
    this.model.get('socialName').setValue(this.data.patientData.socialName);
    this.model.get('birthDate').setValue(this.maskService.formatDateToString(this.data.patientData.birthDate, false));
    let patientAge = this.utilService.getAge(this.model.get('birthDate').value);
    this.model.get('patientAge').setValue(`${patientAge.years}a ${patientAge.months}m ${patientAge.days}d`);
    this.model.get('genderName').setValue(this.data.patientData.genderName);
    this.model.get('cpf').setValue(this.data.patientData.cpf);
    this.model.get('cns').setValue(this.data.patientData.cns);
    this.model.get('city').setValue(this.data.patientData.city);
    this.model.get('street').setValue(this.data.patientData.street);
    this.model.get('houseNumber').setValue(this.data.patientData.houseNumber);
    this.model.get('apartmentNumber').setValue(this.data.patientData.apartmentNumber);
    this.model.get('state').setValue(this.data.patientData.state);
    this.model.get('country').setValue(this.data.patientData.country);
    this.model.get('neighborhood').setValue(this.data.patientData.neighborhood);
    this.model.get('zipCode').setValue(this.data.patientData.zipCode);
    this.model.get('observation').setValue(this.data.observation);
    this.model.get('listCounseling').setValue(this.listCounseling);
    this.triage = new TriageStruct();
    this.protocolName = this.data.protocolName;
    let discriminatorValues = this.data.discriminatorValues && this.data.discriminatorValues.length > 0 ? this.data.discriminatorValues : false;
    this.triage.protocolName = this.data.protocolName;
    this.triage.idProtocol = this.data.idProtocol;

    this.triage.metadata = this.data.metadata;
    this.triage.patientName = this.data.patientData.patientName;
    this.triage.patientSocialName = this.data.patientData.socialName;
    this.triage.patientBirthDate = this.data.patientData.birthDate;
    this.triage.patientGender = this.data.patientData.genderName;
    this.triage.idPatientGender = this.data.patientData.idGender;
    this.triage.patientCpf = this.data.patientData.cpf;
    this.triage.patientCns = this.data.patientData.cns;
    this.triage.dateTimeInclusion = this.endClassification;
    this.triage.startClassification = this.data.datetimeStartTriage;
    this.triage.idGravity = this.data.priority.idPriorityColor;
    this.triage.gravityColor = this.data.priority.coloerHex;
    this.triage.temperature = discriminatorValues[DiscriminatorTypeEnum.Temperatura] ? parseFloat(discriminatorValues[DiscriminatorTypeEnum.Temperatura]) : null;;
    this.triage.respiratoryFrequency = discriminatorValues[DiscriminatorTypeEnum.FrequenciaRespiratoria] ? parseInt(discriminatorValues[DiscriminatorTypeEnum.FrequenciaRespiratoria]) : null;;
    this.triage.heartRate = discriminatorValues[DiscriminatorTypeEnum.FrequenciaCardiaca] ? parseInt(discriminatorValues[DiscriminatorTypeEnum.FrequenciaCardiaca]) : null;
    if (!Array.isArray(this.data.discriminatorAllValues))
      this.data.discriminatorAllValues = [];

    let heartRate = this.data.discriminatorAllValues.find(c => c.idDiscriminatorType == DiscriminatorTypeEnum.FrequenciaCardiaca);
    this.triage.heartRateRegular = heartRate ? heartRate.type == 'regular' : null;
    this.triage.glucose = discriminatorValues[DiscriminatorTypeEnum.Glicemia] ? parseInt(discriminatorValues[DiscriminatorTypeEnum.Glicemia]) : null;
    this.triage.bloodPressureDiastole = discriminatorValues[DiscriminatorTypeEnum.PressaoArterial] ? parseInt(discriminatorValues[DiscriminatorTypeEnum.PressaoArterial].split("/")[1]) : null;;
    this.triage.bloodPressureSystole = discriminatorValues[DiscriminatorTypeEnum.PressaoArterial] ? parseInt(discriminatorValues[DiscriminatorTypeEnum.PressaoArterial].split("/")[0]) : null;
    this.triage.saturation = discriminatorValues[DiscriminatorTypeEnum.Saturacao] ? parseInt(discriminatorValues[DiscriminatorTypeEnum.Saturacao]) : null;
    let discriminatorPain = this.data.discriminatorAllValues.find(c => c.idDiscriminatorType == DiscriminatorTypeEnum.Dor);
    this.pain = discriminatorPain ? discriminatorPain.value : null;
    this.painType = discriminatorPain ? discriminatorPain.type : null;
    this.triage.idPain = discriminatorPain ? discriminatorPain.type : null;
    this.triage.complaint = this.data.complain;
    this.triage.flowchart = this.data.flowchartName;
    this.triage.idFlowchart = this.data.idFlowchart;
    this.triage.discriminator = !this.data.isPatientWhite ? this.data.discriminator.discriminatorName : this.data.arrivalReason;
    this.triage.idDiscriminator = this.data.discriminator ? this.data.discriminator.idDiscriminator : null;
    this.triage.glasgow = discriminatorValues[DiscriminatorTypeEnum.Glasgow] ? discriminatorValues[DiscriminatorTypeEnum.Glasgow] : null;
    let DiscriminatorSaturation = this.data.discriminatorAllValues.find(c => c.idDiscriminatorType == DiscriminatorTypeEnum.Saturacao);
    this.triage.atmosphericAir = DiscriminatorSaturation ? DiscriminatorSaturation.type == "atmospheric" : null;
    let capillaryFillingTime = this.data.discriminatorAllValues.find(c => c.idDiscriminatorType == DiscriminatorTypeEnum.GlicemiaCapilar);
    this.triage.capillaryFillingTime = capillaryFillingTime && capillaryFillingTime.type == "true" ? true : capillaryFillingTime && capillaryFillingTime.type == "false" ? false : null;
    this.triage.fallRisk = this.data.radioFallRisk;
    this.triage.idSuspicion = this.data.idSuspicion;
    this.triage.diseaseHistory = this.data.diseaseHistory;
    this.triage.height = this.data.height ? parseFloat(this.data.height) : null;
    this.triage.weight = this.data.weight;
    this.triage.gravityName = this.data.priority.priorityColorName;
    this.triage.observation = (this.data.observation ? this.data.observation : "") + "" + (this.data.arrivalReasonDesc ? this.data.arrivalReasonDesc : "");
    this.triage.idPatient = this.data.idPatient;
    this.triage.listCounseling = this.data.listCounseling;
    this.triage.userClassificationName = JSON.parse(sessionStorage.getItem("token")).userClassificationName;
    this.triage.userCouncilNumber = JSON.parse(sessionStorage.getItem("token")).userCouncilNumber;
    this.isFirstLoading = false;
    this.isLoading = false;
  }

  goBack() {
    this.previousPage.emit();
  }

  submit() {
    if (this.isLoading)
      return;

    this.isLoading = true;
    this.triageService.postTriage(this.triage).subscribe({
      next: (response) => {
        this.isLoading = false;
        if (response.isError) {
          this.alertService.show("Erro", response.errorDescription, AlertType.error);
          return;
        }

        this.utilsIframeService.openModalSuccess("Classificação salva com sucesso!");
      }, error: (error) => {
        this.alertService.show("Erro Inesperado", error, AlertType.error);
        this.isLoading = false;
      }
    })
  }


  mapRequestToStruct(patientRequest: PatientRequest): PatientStruct {
    let patient: PatientStruct = new PatientStruct();
    patient.patientName = patientRequest.patientName;
    patient.phone1 = patientRequest.phone1;
    patient.phone2 = patientRequest.phone2;
    patient.cpf = patientRequest.cpf;
    patient.birthDate = patientRequest.birthDate;
    patient.cns = patientRequest.cns;
    patient.socialName = patientRequest.socialName;
    patient.genderName = patientRequest.genderName;
    patient.zipCode = patientRequest.zipCode;
    patient.street = patientRequest.street;
    patient.houseNumber = patientRequest.houseNumber;
    patient.apartmentNumber = patientRequest.apartmentNumber;
    patient.neighborhood = patientRequest.neighborhood;
    patient.city = patientRequest.city;
    patient.state = patientRequest.state;
    patient.country = patientRequest.country;
    return patient;
  }
}