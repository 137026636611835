import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';


@Component({
  selector: 'app-validators-modal',
  templateUrl: './validators-modal.component.html',
  styleUrls: ['./validators-modal.component.css']
})
export class ValidatorsModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<ValidatorsModalComponent>) { }

  ngOnInit(): void {
  }
}