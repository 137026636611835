import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { IframePreviewModalComponent } from 'src/app/shared/components/iframe-preview-modal/iframe-preview-modal.component';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { ServiceTypeEnum } from 'src/app/shared/enum/service-type.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { SelfTriageConfigService } from 'src/app/shared/services/API/self-triage/self-triage-config.service';
import { GetTelemedicineConfigService } from 'src/app/shared/services/API/telemedicine/get-telemedicine-config.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { DirectionButton } from 'src/app/shared/services/models/self-triage/direction-button.model';
import { ExternalSearchField } from 'src/app/shared/services/models/self-triage/external-search-field.model';
import { SelfTriageConfig } from 'src/app/shared/services/models/self-triage/self-triage-config.model';
import { ServiceType } from 'src/app/shared/services/models/self-triage/service-type.model';
import { SelfTriageConfigRequest } from 'src/app/shared/services/requests/self-triage/self-triage-config.request';
import { CounselingDirectionStruct } from 'src/app/shared/services/structs/self-triage/counseling-direction.struct';
import { SelfTriageCounselingStruct } from 'src/app/shared/services/structs/self-triage/self-triage-counseling.struct';
import { TelemedicineConfigStruct } from 'src/app/shared/services/structs/telemedicine/telemedicine-config.struct';
import { environment } from 'src/environments/environment';
import { SelfTriageLogoModalComponent } from './self-triage-logo-modal/self-triage-logo-modal.component';
import { GetSelfTriageConfigService } from 'src/app/shared/services/API/orchestrator-patient/get-self-triage-config.service';

@Component({
  selector: 'app-self-triage-config',
  templateUrl: './self-triage-config.component.html',
  styleUrls: ['./self-triage-config.component.css']
})
export class SelfTriageConfigComponent implements OnInit, OnDestroy {

  constructor(private alertService: AlertService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private selfTriageConfigService: SelfTriageConfigService,
    private getSelfTriageConfigService: GetSelfTriageConfigService,
    private maskService: MaskService,
    private getTelemedicineConfigService: GetTelemedicineConfigService,
  ) { }

  public model: FormGroup;
  public masks: Masks = this.maskService.getMasks();
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.digital_prompt_service;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.self_triage_selfTriageConfig;
  public validationRegex: RegExp = /^[-a-zA-Z0-9_.,<>;+?@*$%#!&()"'{}_/:'"]+$/;

  public healthUnitHasPatientCenter: boolean = false;
  public isFirstLoading: boolean = false;
  public isTelemedicineActive: boolean = false;
  public isActive: boolean;
  public isLoading: boolean = false;
  public useTolifeLogo: boolean = true;

  public listCounseling: SelfTriageCounselingStruct[] = [];
  public listSelectedCounselings: SelfTriageCounselingStruct[] = [];
  public listField: ExternalSearchField[] = [];
  public listDirectionButton: DirectionButton[];
  public listServiceType: ServiceType[];
  public telemedicineConfig: TelemedicineConfigStruct;

  public serviceTypeTelemedicine: number = ServiceTypeEnum.telemedicine;
  public countText: number = 0;
  public idSelfTriageConfig: number = 0;

  public urlSelfTriage: string = '';
  public transitionDescription: string = null;
  public transitionSymptoms: string = null;
  public transactionTitle: string = null;
  public transactionEmergency: string = null;
  public transition: string = null;
  public patternTitle: string;
  public patternDescription: string;
  public patternEmergency: string;
  public patternSymptoms: string;
  public logo: string;
  public searchText: string;
  public newLogo: string;
  public completeUrl: string;
  public baseUrl: string

  ngOnInit(): void {
    this.search();
    this.getTelemedicineConfig();

    this.model = this.formBuilder.group({
      useColor: [false, [Validators.required]],
      colorCode: [''],
      isLogoUploaded: [false, [Validators.required]],
      logoString64: [''],
      urlName: ['', [Validators.required]],
      urlStatus: [false, [Validators.required]],
      url: ['', [Validators.required]],
      isExternalSearchEnabled: [false, [Validators.required]],
      idExternalSearchField: [null],
      externalSearchUrl: [''],
      externalSearchTitle: [''],
      externalSearchKey: [''],
      titlePage: ['', [Validators.required]],
      descriptionPage: ['', [Validators.required]],
      emergency: ['', [Validators.required]],
      symptoms: ['', [Validators.required]],
      listCounselingDirection: this.formBuilder.array([]),
      useSelfTriageInPatientCenter: [false, [Validators.required]]
    });
  }

  ngOnDestroy(): void {
    this.updateLogoNamePreview(this.logo);
  }

  search() {
    this.isLoading = true;
    this.isFirstLoading = true;

    if (this.searchText == "")
      this.searchText = null;

    this.patternTitle = "Faça sua avaliação e receba orientações adequadas para o seu bem-estar";
    this.transactionTitle = this.patternTitle;
    this.patternDescription = "Ao final da autotriagem, você receberá aconselhamentos específicos que foram preparados pela equipe médica da sua Unidade de Saúde de referência.\nEm casos muito urgentes, como dificuldade para respirar, sangramento intenso e dores muito fortes, procure uma Unidade de Pronto Atendimento o mais rápido possível."
    this.transitionDescription = this.patternDescription;
    this.patternEmergency = "Procure atendimento presencial urgente se você apresentar um destes sintomas:";
    this.transactionEmergency = this.patternEmergency;
    this.patternSymptoms = "● Chiado, falta de ar ou dificuldade em respirar\n● Dor no peito\n● Fraturas deslocadas ou expostas\n● Desmaios ou tonturas\n● Dormência ou fraqueza repentina\n● Sangramento que não pode ser parado\n● Dor abdominal - especialmente dor intensa localizada\n● Febre com convulsões ou qualquer febre em crianças menores de 3 meses\n● Confusão ou alterações no estado mental\n● Tosse ou vômito com sangue\n● Dor de cabeça severa ou lesão na cabeça, especialmente se o indivíduo estiver usando aspirina ou anticoagulantes\n● Sangue na urina ou diarreia com sangue\n● Súbita incapacidade de falar, ver, andar e se mover";
    this.transitionSymptoms = this.patternSymptoms;

    this.getSelfTriageConfigService.getConfig().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.useTolifeLogo = response.selfTriageConfig.useTolifeLogo;
        this.idSelfTriageConfig = response.selfTriageConfig.idSelfTriageConfig;
        this.healthUnitHasPatientCenter = response.selfTriageConfig.healthUnitHasPatientCenter;
        this.listField = response.listField;
        this.listCounseling = response.listCounseling;
        this.listDirectionButton = response.selfTriageConfig.listButtons;
        this.model.get('useColor').setValue(response.selfTriageConfig.useColor);
        this.model.get('colorCode').setValue(response.selfTriageConfig.colorCode);
        this.model.get('isLogoUploaded').setValue(response.selfTriageConfig.isLogoUploaded);
        this.model.get('logoString64').setValue(response.selfTriageConfig.logoName);
        this.model.get('urlName').setValue(response.selfTriageConfig.urlName);
        this.model.get('urlStatus').setValue(response.selfTriageConfig.isActive);
        this.model.get('url').setValue(response.selfTriageConfig.url);
        this.urlSelfTriage = response.selfTriageConfig.url;
        this.model.get('isExternalSearchEnabled').setValue(response.selfTriageConfig.isExternalSearchEnabled);
        this.model.get('idExternalSearchField').setValue(response.selfTriageConfig.idExternalSearchField);
        this.model.get('externalSearchUrl').setValue(response.selfTriageConfig.externalSearchUrl);
        this.model.get('externalSearchTitle').setValue(response.selfTriageConfig.externalSearchTitle);
        this.model.get('externalSearchKey').setValue(response.selfTriageConfig.externalSearchKey);
        this.model.get('useSelfTriageInPatientCenter').setValue(response.selfTriageConfig.useSelfTriageInPatientCenter);
        this.model.get('titlePage').setValue(this.patternTitle);
        this.model.get('descriptionPage').setValue(this.patternDescription);
        this.model.get('emergency').setValue(this.patternEmergency);
        this.model.get('symptoms').setValue(this.patternSymptoms);

        if (response.selfTriageConfig.titlePage != undefined && response.selfTriageConfig.titlePage != null) {
          this.model.get('titlePage').setValue(response.selfTriageConfig.titlePage);
          this.patternTitle = response.selfTriageConfig.titlePage;
        }

        if (response.selfTriageConfig.descriptionPage != undefined && response.selfTriageConfig.descriptionPage != null) {
          this.model.get('descriptionPage').setValue(response.selfTriageConfig.descriptionPage);
          this.patternDescription = response.selfTriageConfig.descriptionPage;
        }

        if (response.selfTriageConfig.emergency != undefined && response.selfTriageConfig.emergency != null) {
          this.model.get('emergency').setValue(response.selfTriageConfig.emergency);
          this.patternEmergency = response.selfTriageConfig.emergency;
        }

        if (response.selfTriageConfig.symptoms != undefined && response.selfTriageConfig.symptoms != null) {
          this.model.get('symptoms').setValue(response.selfTriageConfig.symptoms);
          this.patternSymptoms = response.selfTriageConfig.symptoms;
        }

        this.logo = response.selfTriageConfig.logoString64;
        this.getCompleteUrl(null);
        if (!response.selfTriageConfig.listButtons || response.selfTriageConfig.listButtons.length <= 0)
          this.addNext();

        if (response.selfTriageConfig.listCounselingDirection && response.selfTriageConfig.listCounselingDirection.length > 0) {
          response.selfTriageConfig.listCounselingDirection.forEach(x => {
            this.addNext(x);
          });
        }
        else
          this.addNext();

        this.setAvailableCounselings();
        this.endLoading();
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    });
  }

  submit() {
    this.transition = this.model.get("titlePage").value;

    if (this.transition.length == 0)
      this.model.get("titlePage").setValue(this.transactionTitle);
    else if (this.transition.length < 29) {
      this.alertDynamic('Erro', "O campo Titulo precisa ter no minimo 30 caracteres", AlertType.error);
      return;
    }

    this.transition = this.model.get("emergency").value;
    if (this.transition.length == 0)
      this.model.get("emergency").setValue(this.transactionEmergency);
    else if (this.transition.length < 29) {
      this.alertDynamic('Erro', "O campo Veja se não é uma emergência precisa ter no minimo 30 caracteres", AlertType.error);
      return;
    }

    this.transition = this.model.get("descriptionPage").value;
    if (this.transition.length == 0)
      this.model.get("descriptionPage").setValue(this.transitionDescription);
    else if (this.transition.length < 29) {
      this.alertDynamic('Erro', "O campo descrição precisa ter no minimo 30 caracteres", AlertType.error);
      return;
    }

    this.transition = this.model.get("symptoms").value;
    if (this.transition.length == 0)
      this.model.get("symptoms").setValue(this.transitionSymptoms);
    else if (this.transition.length < 29) {
      this.alertDynamic('Erro', "O campo sintomas precisa ter no minimo 30 caracteres", AlertType.error);
      return;
    }

    this.transition = null;
    if (this.isLoading)
      return;

    if (this.model.invalid) {
      this.alertDynamic('Erro', "Todos os campos em vermelho são de preenchimento obrigatório", AlertType.error);
      return;
    }

    this.isLoading = true;

    let request: SelfTriageConfigRequest = new SelfTriageConfigRequest();
    request.useColor = this.model.get('useColor').value;
    request.colorCode = request.useColor ? this.model.get('colorCode').value : null;
    request.isLogoUploaded = this.model.get('isLogoUploaded').value;
    request.logoString64 = request.isLogoUploaded ? this.model.get('logoString64').value : null;
    request.url = this.model.get('url').value;
    request.isActive = this.model.get('urlStatus').value;
    request.urlName = this.model.get('urlName').value;
    request.useSelfTriageInPatientCenter = this.model.get('useSelfTriageInPatientCenter').value;

    request.isExternalSearchEnabled = this.model.get('isExternalSearchEnabled').value;
    request.idExternalSearchField = this.model.get('idExternalSearchField').value;
    request.externalSearchUrl = this.model.get('externalSearchUrl').value;
    request.externalSearchTitle = this.model.get('externalSearchTitle').value;
    request.externalSearchKey = this.model.get('externalSearchKey').value;
    request.titlePage = this.model.get('titlePage').value;
    request.descriptionPage = this.model.get('descriptionPage').value;
    request.emergency = this.model.get('emergency').value;
    request.symptoms = this.model.get('symptoms').value;
    request.useTolifeLogo = this.useTolifeLogo;

    let listCounselingDirectionStruct = this.model.get('listCounselingDirection').value;

    listCounselingDirectionStruct.forEach(x => {
      if (x.idSelfTriageCounseling != null && x.listIdDirectionButton != null && x.listIdDirectionButton.length > 0) {
        let counselingDirection: CounselingDirectionStruct = new CounselingDirectionStruct();

        counselingDirection.idSelfTriageCounseling = x.idSelfTriageCounseling;
        counselingDirection.listIdDirectionButton = x.listIdDirectionButton;

        request.listCounselingDirection.push(counselingDirection);
      }
    });

    this.updateSelfTriageConfig(request);
  }

  updateSelfTriageConfig(request: SelfTriageConfigRequest) {
    this.selfTriageConfigService.postConfig(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.alertDynamic('Sucesso', "Informações salvas com sucesso", AlertType.success);
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    });
  }

  updateColorCodePreview() {
    if (this.idSelfTriageConfig && this.idSelfTriageConfig != 0) {
      let request: SelfTriageConfig = new SelfTriageConfig();
      request.colorCodePreview = this.model.get('colorCode').value;

      this.selfTriageConfigService.putColorCodePreview(request).subscribe({
        next: (response) => {
          if (response.isError) {
            this.alertDynamic('Erro', response.errorDescription, AlertType.error);
            return;
          }

          this.endLoading();
        },
        error: (error) => {
          this.alertDynamic('Erro inesperado', error, AlertType.error);
        }
      });
    }
  }

  updateLogoNamePreview(logoString64: string) {
    if (this.idSelfTriageConfig && this.idSelfTriageConfig != 0) {
      let request: SelfTriageConfigRequest = new SelfTriageConfigRequest();
      request.logoString64 = logoString64;
      request.useTolifeLogo = this.useTolifeLogo;

      this.selfTriageConfigService.putLogoNamePreview(request).subscribe({
        next: (response) => {
          if (response.isError) {
            this.alertDynamic('Erro', response.errorDescription, AlertType.error);
            return;
          }

          this.isLoading = false;
        },
        error: (error) => {
          this.alertDynamic('Erro inesperado', error, AlertType.error);
        }
      });
    }
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13)
        $this.search();
    },
      1000);
  }

  openPanelLogoModal() {
    const dialogRef = this.dialog.open(SelfTriageLogoModalComponent, {
      data: {
        logo: this.logo,
        newLogo: this.newLogo,
        useTolifeLogo: this.useTolifeLogo
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        
        if(!result || (result && result.cancel))
          return;

        if (result && result.newLogo) {
          this.alertService.show('Sucesso', "Upload concluído!", AlertType.success);
          this.newLogo = result.newLogo;

          if (!this.logo)
            this.logo = this.newLogo;

          this.model.get('logoString64').setValue(result.newLogo);
        }

        if (result && !result.haveLogo)
          this.model.get('isLogoUploaded').setValue(false);

        this.useTolifeLogo = result.useTolifeLogo;
        this.updateLogoNamePreview(this.model.get('logoString64').value);
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  onCopy(text) {
    this.alertService.show('Sucesso', text, AlertType.success);
  }

  createInput(counselingDirection?: CounselingDirectionStruct) {
    if (counselingDirection != null) {
      return this.formBuilder.group({
        idSelfTriageCounseling: [counselingDirection.idSelfTriageCounseling],
        listIdDirectionButton: [counselingDirection.listIdDirectionButton],
      });
    }
    else {
      return this.formBuilder.group({
        idSelfTriageCounseling: [null],
        listIdDirectionButton: [null],
      });
    }
  }

  addNext(counselingDirection?: CounselingDirectionStruct) {
    if (counselingDirection != null)
      (this.model.controls['listCounselingDirection'] as FormArray).push(this.createInput(counselingDirection));
    else
      (this.model.controls['listCounselingDirection'] as FormArray).push(this.createInput());
  }

  counselingDirections(): FormArray {
    return this.model.get('listCounselingDirection') as FormArray;
  }

  removeCounselingDirection(index: number) {
    (this.model.controls['listCounselingDirection'] as FormArray).removeAt(index);
    if (this.model.get('listCounselingDirection')['controls'].length == 0)
      this.addNext();

    this.setAvailableCounselings();
  }

  setAvailableCounselings() {
    let counselings = this.model.get('listCounselingDirection')['controls'];
    this.listSelectedCounselings = counselings.map(x => x.value.idSelfTriageCounseling);
  }

  getCompleteUrl(event) {
    if (event) {
      let result = this.validationRegex.test(event.key);

      if (!result) {
        let uri = this.model.get('url').value.replaceAll(event.key, "");
        this.model.get('url').setValue(uri);
      }
    }

    this.baseUrl = `${environment.urlBasePatientUI}self-triage/`;

    this.completeUrl = this.baseUrl + `${this.model.get('url').value}`;
  }

  toggleExternalSearch() {
    if (this.model.get('isExternalSearchEnabled').value) {
      this.model.get('externalSearchUrl').setValidators(Validators.required);
      this.model.get('externalSearchTitle').setValidators(Validators.required);
      this.model.get('externalSearchKey').setValidators(Validators.required);
    }
    else {
      this.model.get('externalSearchUrl').setValidators(null);
      this.model.get('externalSearchTitle').setValidators(null);
      this.model.get('externalSearchKey').setValidators(null);
    }

    this.model.get('externalSearchUrl').updateValueAndValidity();
    this.model.get('externalSearchTitle').updateValueAndValidity();
    this.model.get('externalSearchKey').updateValueAndValidity();
  }

  getTelemedicineConfig() {
    this.getTelemedicineConfigService.getConfig().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.telemedicineConfig = response.telemedicineConfig;
        if (this.telemedicineConfig && this.telemedicineConfig.isActive)
          this.isTelemedicineActive = true;
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openPreview() {
    this.dialog.open(IframePreviewModalComponent, {
      data: {
        url: `${this.baseUrl}${this.urlSelfTriage}?preview=true`,
        title: 'Autotriagem'
      },
      panelClass: 'iframe-preview-modal'
    });
  }

  alertDynamic(alertTypeDescription: string, alertDescription: string, alertType: AlertType) {
    if (alertType && alertType.valueOf() == AlertType.error)
      console.log(alertDescription);

    this.alertService.show(alertTypeDescription, alertDescription, alertType ? alertType.valueOf() : AlertType.error);
    this.endLoading();
  }

  endLoading() {
    this.isFirstLoading = false;
    this.isLoading = false;
  }
}