import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-device-offline-modal',
  templateUrl: './device-offline-modal.component.html',
  styleUrls: ['./device-offline-modal.component.css']
})
export class DeviceOfflineModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<DeviceOfflineModalComponent>) { }

  ngOnInit(): void {
  }

  clickCancel(value: boolean) {
    this.matDialogRef.close(value);
  }
}

