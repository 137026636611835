import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { SharedModule } from '../shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { MasterRoutingModule } from './master-routing.module';
import { UserListComponent } from './pages/user-list/user-list.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MasterComponent } from './pages/master/master.component';
import { MatSelectModule } from '@angular/material/select';
import { UserRegisterComponent } from './pages/user-register/user-register.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { NgxMaskModule } from 'ngx-mask';
import { UserDeleteModalComponent } from './pages/user-list/user-delete-modal/user-delete-modal.component';
import { ProfileListComponent } from './pages/profile-list/profile-list.component';
import { ProfileDeleteModalComponent } from './pages/profile-list/profile-delete-modal/profile-delete-modal.component';
import { ServiceListComponent } from './pages/service-list/service-list.component';
import { DeleteServiceModalComponent } from './pages/service-list/delete-service-modal/delete-service-modal.component';
import { ServiceRegisterComponent } from './pages/service-register/service-register.component';
import { SectorListComponent } from './pages/sector-list/sector-list.component';
import { SectorDeleteModalComponent } from './pages/sector-list/sector-delete-modal/sector-delete-modal.component';
import { ProfileRegisterComponent } from './pages/profile-register/profile-register.component';
import { MatTree, MatTreeModule } from '@angular/material/tree';
import { TotemListComponent } from './pages/totem-list/totem-list.component';
import { TotemDeleteModalComponent } from './pages/totem-list/totem-delete-modal/totem-delete-modal.component';
import { TotemRegisterComponent } from './pages/totem-register/totem-register.component';
import { SectorRegisterComponent } from './pages/sector-register/sector-register.component';
import { RoomListComponent } from './pages/room-list/room-list.component';
import { RoomDeleteModalComponent } from './pages/room-list/room-delete-modal/room-delete-modal.component';
import { RoomRegisterComponent } from './pages/room-register/room-register.component';
import { FlowListComponent } from './pages/flow-list/flow-list.component';
import { FlowDeleteModalComponent } from './pages/flow-list/flow-delete-modal/flow-delete-modal.component';
import { FlowRegisterComponent } from './pages/flow-register/flow-register.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { PanelListComponent } from './pages/panel-list/panel-list.component';
import { PanelDeleteModalComponent } from './pages/panel-list/panel-delete-modal/panel-delete-modal.component';
import { PanelRegisterComponent } from './pages/panel-register/panel-register.component';
import { PanelOriginSelectComponent } from './pages/panel-register/panel-origin-select/panel-origin-select.component';
import { PanelLogoModalComponent } from './pages/panel-register/panel-logo-modal/panel-logo-modal.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { ExamRegisterComponent } from '../sadt/pages/exam/exam-register/exam-register.component';
import { HealthUnitConfigComponent } from './pages/health-unit-config/health-unit-config.component';
import { HealthUnitLogoModalComponent } from './pages/health-unit-config/health-unit-logo-modal/health-unit-logo-modal.component';
import { ServiceRegisterConfirmModalComponent } from './pages/service-register/service-register-confirm-modal/service-register-confirm-modal.component';
import { MatNativeDateModule } from '@angular/material/core';
import { EligibilityRulesComponent } from './pages/flow-register/eligibility-rules/eligibility-rules.component';
import { ConfigTotemModalComponent } from './pages/totem-list/config-totem-modal/config-totem-modal.component';
import { CareLineListComponent } from './pages/care-line-list/care-line-list.component';
import { CareLineRegisterComponent } from './pages/care-line-register/care-line-register.component';
import { CareLineDeleteModalComponent } from './pages/care-line-list/care-line-delete-modal/care-line-delete-modal.component';
import { BatchListComponent } from './pages/batch-list/batch-list.component';
import { BatchRegisterComponent } from './pages/batch-register/batch-register.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxColorsModule } from 'ngx-colors';
import { MetaDataListComponent } from './pages/meta-data-list/meta-data-list.component';
import { MetaDataRegisterComponent } from './pages/meta-data-register/meta-data-register.component';
import { MetaDataDeleteModalComponent } from './pages/meta-data-list/meta-data-delete-modal/meta-data-delete-modal.component';
import { GamificationListComponent } from './pages/gamification-list/gamification-list.component';
import { GamificationRegisterComponent } from './pages/gamification-register/gamification-register.component';
import { AreaDeleteModalComponent } from './pages/gamification-list/area-delete-modal/area-delete-modal.component';
import { AreaRankingComponent } from './pages/gamification-register/area-ranking/area-ranking.component';
import { MatRadioButton, MatRadioModule } from '@angular/material/radio';
import { MatCheckbox, MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { BillingHealthUnitComponent } from './pages/billing-health-unit/billing-health-unit.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BillingAlertRegisterComponent } from './pages/billing-alert-register/billing-alert-register.component';
import { CreateRuleComponent } from './pages/billing-alert-register/create-rule/create-rule.component';
import { NpsComponent } from './pages/nps/nps.component';
import { NpsGraphComponent } from './pages/nps-graph/nps-graph.component';
import { TotemNpsListComponent } from './pages/totem-nps-list/totem-nps-list.component';
import { TotemNpsDeleteModalComponent } from './pages/totem-nps-list/totem-nps-delete-modal/totem-nps-delete-modal.component';
import { TotemNpsRegisterComponent } from './pages/totem-nps-register/totem-nps-register.component';
import { NpsLogoModalComponent } from './pages/nps/nps-logo-modal/nps-logo-modal.component';
import { RoomSetupModalComponent } from './pages/room-register/room-setup-modal/room-setup-modal.component';
import { AlertPolicyListComponent } from './pages/alert-policy-list/alert-policy-list.component';
import { AlertPolicyRegisterComponent } from './pages/alert-policy-register/alert-policy-register.component';
import { LoginHistoryModalComponent } from './pages/user-list/login-history-modal/login-history-modal.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TextTemplateDeleteModalComponent } from './pages/text-template-list/text-template-delete-modal/text-template-delete-modal.component';
import { TextTemplateListComponent } from './pages/text-template-list/text-template-list.component';
import { TextTemplateRegisterComponent } from './pages/text-template-register/text-template-register.component';
import { QuillModule } from 'ngx-quill';
import { AlertSmsSpModalComponent } from './pages/user-register/alert-sms-sp-modal/alert-sms-sp-modal.component';
import { ChangelogComponent } from './pages/changelog/changelog.component';
import { ChangelogDetailsModalComponent } from './pages/changelog/changelog-details-modal/changelog-details-modal.component';

@NgModule({
  declarations: [
    UserListComponent,
    UserRegisterComponent,
    MasterComponent,
    UserDeleteModalComponent,
    ProfileListComponent,
    ProfileDeleteModalComponent,
    ServiceListComponent,
    DeleteServiceModalComponent,
    ServiceRegisterComponent,
    SectorListComponent,
    SectorDeleteModalComponent,
    ProfileRegisterComponent,
    TotemListComponent,
    TotemDeleteModalComponent,
    TotemRegisterComponent,
    SectorRegisterComponent,
    RoomListComponent,
    RoomDeleteModalComponent,
    RoomRegisterComponent,
    FlowListComponent,
    FlowDeleteModalComponent,
    FlowRegisterComponent,
    PanelListComponent,
    PanelDeleteModalComponent,
    PanelRegisterComponent,
    PanelOriginSelectComponent,
    PanelLogoModalComponent,
    ExamRegisterComponent,
    HealthUnitConfigComponent,
    HealthUnitLogoModalComponent,
    ServiceRegisterConfirmModalComponent,
    EligibilityRulesComponent,
    ConfigTotemModalComponent,
    CareLineListComponent,
    CareLineRegisterComponent,
    CareLineDeleteModalComponent,
    BatchListComponent,
    BatchRegisterComponent,
    MetaDataRegisterComponent,
    MetaDataListComponent,
    MetaDataDeleteModalComponent,
    GamificationListComponent,
    GamificationRegisterComponent,
    AreaDeleteModalComponent,
    AreaRankingComponent,
    BillingHealthUnitComponent,
    BillingAlertRegisterComponent,
    CreateRuleComponent,
    NpsComponent,
    NpsGraphComponent,
    TotemNpsListComponent,
    TotemNpsDeleteModalComponent,
    TotemNpsRegisterComponent,
    NpsLogoModalComponent,
    RoomSetupModalComponent,
    AlertPolicyListComponent,
    AlertPolicyRegisterComponent,
    LoginHistoryModalComponent,
    TextTemplateDeleteModalComponent,
    TextTemplateListComponent,
    TextTemplateRegisterComponent,
    AlertSmsSpModalComponent,
    ChangelogComponent,
    ChangelogDetailsModalComponent,
  ],
  imports: [
    CommonModule,
    MasterRoutingModule,
    SharedModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonModule,
    NgxMaskModule.forRoot(),
    MatCheckboxModule,
    MatTreeModule,
    MatRadioModule,
    ClipboardModule,
    MatTooltipModule,
    MatToolbarModule,
    NgxMatFileInputModule,
    CdkAccordionModule,
    MatNativeDateModule,
    MatDatepickerModule,
    NgxColorsModule,
    MatTableModule,
    MatCardModule,
    NgxMaskModule,
    NgxChartsModule,
    MatDialogModule,
    MatPaginatorModule,
    QuillModule.forRoot(),
  ],
  providers: [
    AuthGuard,
    MatCheckbox,
    MatCheckboxChange,
    MatTree,
    MatRadioButton,
  ],
})
export class MasterModule { }