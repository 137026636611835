import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { BiLookupResponse } from '../../responses/business-intelligence/bi-lookup.response';
import { LookupSendReportResponse } from '../../responses/business-intelligence/lookup-send-report.response';

@Injectable({
  providedIn: 'root'
})
export class BiLookupService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listTime(): Observable<BiLookupResponse> {
    
    let uri = 'Lookup'
    
    return this.httpClient.get<BiLookupResponse>(environment.urlApiBusinessIntelligence + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
  
  public listLookupSendReport(): Observable<LookupSendReportResponse>{

    let uri = `Lookup/LookupSendReport`

    return this.httpClient.get<LookupSendReportResponse>(environment.urlApiBusinessIntelligence + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}