import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-patient-data-modal',
  templateUrl: './patient-data-modal.component.html',
  styleUrls: ['./patient-data-modal.component.css']
})
export class PatientDataModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<PatientDataModalComponent>,
    private formBuilder: UntypedFormBuilder,
    public maskService: MaskService,
    private utilService: UtilService) { }

  public model: UntypedFormGroup;
  public masks: Masks;

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      idPatient: [{ value: this.data.patient.idPatient, disabled: true }],
      birthDate: [{ value: null, disabled: true }],
      patientName: [{ value: this.data.patient.patientName, disabled: true }],
      phone1: [{ value: this.data.patient.phone1, disabled: true }],
      cpf: [{ value: this.data.patient.cpf, disabled: true }],
      cns: [{ value: this.data.patient.cns, disabled: true }],
      idGender: [{ value: this.data.patient.idGender, disabled: true }],
      zipCode: [{ value: this.data.patient.zipCode, disabled: true }],
      street: [{ value: this.data.patient.street, disabled: true }],
      neighborhood: [{ value: this.data.patient.neighborhood, disabled: true }],
      city: [{ value: this.data.patient.city, disabled: true }],
      state: [{ value: this.data.patient.state, disabled: true }],
      country: [{ value: this.data.patient.country, disabled: true }],
      sectorEmployee: [{ value: this.data.patient.sectorEmployee, disabled: true }],
      registryEmployee: [{ value: this.data.patient.registryEmployee, disabled: true }],
      unitBranch: [{ value: this.data.patient.unitBranch, disabled: true }],
      roleEmployee: [{ value: this.data.patient.roleEmployee, disabled: true }],
      houseNumber: [{ value: this.data.patient.houseNumber, disabled: true }],
      patientAge: [{ value: '', disabled: true }],
      apartmentNumber: [{ value: this.data.patient.apartmentNumber, disabled: true }],
    });

    this.populatePatient();
  }
  populatePatient() {
    let birthDate = this.maskService.formatDateToString(this.data.patient.birthDate, false);
    this.model.get('birthDate').setValue(birthDate);
    if (birthDate) {
      let patientAge = this.utilService.getAge(this.model.get('birthDate').value);
      this.model.get('patientAge').setValue(`${patientAge.years}a ${patientAge.months}m ${patientAge.days}d`);
    }
  }

  close() {
    this.matDialogRef.close();
  }
}