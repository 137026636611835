<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <h1>Lista de AIH Preenchidas</h1>
        <div class="white-body">
            <form class="form" [formGroup]="model" (ngSubmit)="buttonSearch()">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Número do Episódio</mat-label>
                            <input matInput type="text" formControlName="idEpisode" oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome do Paciente</mat-label>
                            <input matInput type="text" formControlName="patientName">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome do criador da AIH</mat-label>
                            <input matInput type="text" formControlName="userCreatorName">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Data da AIH</mat-label>
                            <mat-date-range-input [rangePicker]="rangePicker">
                                <input matStartDate formControlName="initialDate" placeholder="Início">
                                <input disabled="false" matEndDate formControlName="endDate" placeholder="Fim">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
                            <mat-date-range-picker #rangePicker></mat-date-range-picker>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <button mat-flat-button color="primary" class=" btn-block" type="submit">
                            <span *ngIf="!isLoading">Pesquisar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                        </button>
                    </div>
                </div>
            </form>
            <div class="card-list" *ngIf="listAihGroupStruct && listAihGroupStruct.length > 0">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listAihGroupStruct">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary">{{item.idEpisode}}</span>
                                <span class="secundary">Paciente: {{item.patientName}}</span>
                                <span class="secundary">Leito: {{item.bedName}}</span>
                                <span class="secundary">Data de nascimento: {{item.patientBirthDate | date:'dd/MM/yyyy'}}</span>
                                <span class="secundary">Nome mãe: {{item.patientMotherName}}</span>
                                <span class="secundary">Data de geração da AIH: {{item.datetimeInclusion | date:'dd/MM/yyyy'}}</span>
                                <span class="secundary">Criador da AIH: {{item.userCreatorName}}</span>                               
                            </div>
                            <div class="actions">
                                <a><mat-icon aria-hidden="false" aria-label="Editar" (click)="openAih(item)">edit</mat-icon> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <app-empty-list *ngIf="!listAihGroupStruct || listAihGroupStruct.length == 0 && !isLoading"></app-empty-list>
            <div *ngIf="!isLoading" class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-paginator [length]="fullSize" #paginator [pageIndex]="pageIndex" [pageSize]="50" aria-label="Select page"
                        (page)="changePage($event)" [hidePageSize]="true">
                    </mat-paginator>
                </div>
            </div>
        </div>
    
        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>
    
</div>