import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ListStatusQueueResponse } from '../../responses/queue/list-status-queue.response';
import { UpdateStatusQueueResponse } from '../../responses/queue/update-status-queue.response';

@Injectable({
  providedIn: 'root'
})
export class StatusQueueService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listStatusQueue(): Observable<ListStatusQueueResponse> {
    
    let uri = `StatusQueue/getAll`
      
    return this.httpClient.get<ListStatusQueueResponse>(environment.urlApiQueue + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public UpdateStatusQueue(idEpisode: number, idSector: number = null, idRoom: number = null): Observable<UpdateStatusQueueResponse> {
    
    let uri = `StatusQueue/idEpisode/${idEpisode}?`

    return this.httpClient.put<UpdateStatusQueueResponse>(environment.urlApiQueue + uri, null, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
