<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <a class="back-link" routerLink="/front-desk/front-desk-config">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Configurações
        </a>
        <h1>Cadastrar Recepção</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="title-header">
                    <h1>Dados da Recepção</h1>
                </div>

                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome</mat-label>
                            <input matInput type="text" formControlName="frontDeskName">
                            <mat-error *ngIf="model.get('frontDeskName').invalid">Informe o nome</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Descrição</mat-label>
                            <input matInput type="text" formControlName="description">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Setores</mat-label>
                            <mat-select formControlName="listSector" multiple>
                                <mat-option [value]="sector.idSector" *ngFor="let sector of listSector">
                                    {{sector.sectorName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-4 col-md-3">
                        <mat-slide-toggle class="wristband-checkbox" [checked]="isWristbandActive"
                            (change)="toggleWristband($event)">Pulseira
                        </mat-slide-toggle>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 col-sm-4 col-md-2" *ngIf="isWristbandActive">
                        <mat-form-field appearance="outline">
                            <mat-label>Dimensão</mat-label>
                            <mat-select formControlName="idWristbandDimensions"
                                (selectionChange)="changeDimensionsSelect($event)">
                                <mat-option [value]="dimensions.idWristbandDimensions"
                                    *ngFor="let dimensions of listWristbandDimensions">
                                    {{dimensions.wristbandDimensionsDescription}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-4 col-sm-4 col-md-2" *ngIf="isWristbandActive && isOtherDimensionsSelected">
                        <mat-form-field appearance="outline">
                            <mat-label>Largura</mat-label>
                            <input matInput type="number" formControlName="customWristbandWidth">
                            <mat-error *ngIf="model.get('customWristbandWidth').value < 70">O valor mínimo é
                                70mm</mat-error>
                            <mat-error
                                *ngIf="model.get('customWristbandWidth').invalid && model.get('customWristbandWidth').value >= 70">Informe
                                a Largura</mat-error>
                            <span matSuffix>mm</span>
                        </mat-form-field>
                    </div>
                    <div class="col-4 col-sm-4 col-md-2" *ngIf="isWristbandActive && isOtherDimensionsSelected">
                        <mat-form-field appearance="outline">
                            <mat-label>Altura</mat-label>
                            <input matInput type="number" minlength="20" formControlName="customWristbandHeight">
                            <mat-error *ngIf="model.get('customWristbandHeight').value < 20">O valor mínimo é
                                20mm</mat-error>
                            <mat-error
                                *ngIf="model.get('customWristbandHeight').invalid && model.get('customWristbandHeight').value >= 20">Informe
                                a Altura</mat-error>
                            <span matSuffix>mm</span>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 col-sm-4 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Identificação padrão</mat-label>
                            <mat-select formControlName="identification"
                                (selectionChange)="changeIdentificationSelect($event)">
                                <mat-option [value]="identification.idIdentification"
                                    *ngFor="let identification of listIdentification">
                                    {{identification.identificationName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row" *ngIf="isWristbandActive && isOtherDimensionsSelected">
                    <div class="col-md-2">
                        <button mat-flat-button type="button" color="primary" class=" btn-block"
                            (click)="visualizeTemplate()">
                            <span *ngIf="isVisualizeLoading == false">Visualizar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isVisualizeLoading == true">
                            </mat-spinner>
                        </button>
                    </div>
                </div>

                <div class="title-header">
                    <h1>Campos Obrigatórios</h1>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Campos Obrigatórios</mat-label>
                            <mat-select formControlName="listIdRequiredField" multiple>
                                <mat-option (click)="changeRequired(matOption.value)" #matOption [value]="field.idField"
                                    *ngFor="let field of listField"
                                    [disabled]="field.idField == identificationFrontDesk.idField || field.idField == identificationClassification.idField "
                                    (click)="verifyRequiredFields(matOption)">
                                    {{field.fieldName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="title-header">
                    <h1>Metadados</h1>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Selecionar metadados</mat-label>
                            <mat-select formControlName="listMetadata" multiple>
                                <mat-option *ngFor="let item of listMetaData"
                                    (click)="selectMetaData(item.metaData.idMetaData)"
                                    [value]="item.metaData.idMetaData">
                                    {{ item.metaData.metaDataName }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Metadados obrigatórios</mat-label>
                            <mat-select formControlName="listMetaDataRequiredSelected" multiple>
                                <mat-option *ngFor="let item of listMetaDataRequiredSelected"
                                    (click)="selectRequiredMetaData(item.metaData.idMetaData)"
                                    [value]="item.metaData.idMetaData">
                                    {{ item.metaData.metaDataName }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <a mat-flat-button type="button" routerLink="/front-desk/front-desk-config" color="accent"
                            class=" btn-block">
                            Cancelar
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>

</div>