<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-confirm-modal-list">
            <div class="img-head">
                <img src="assets/images/exclamation-circle.png" />
            </div>
            <div>
                <h1>Atenção!</h1>
            </div>
            <div>
                <h2>Você tentou gerar uma senha para pacientes agendados, mas você não possui nenhum agendamento. Por
                    favor, clique em "voltar" e selecione outra categoria de atendimento.
                </h2>
            </div>
        </div>
        <div class="footer-confirm-modal-list">
            <button mat-flat-button color="primary" class="btn-block" (click)="return()">
                <span *ngIf="isLoading == false">Voltar</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
        </div>
    </div>
</div>