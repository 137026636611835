import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { TriageRequest } from '../../requests/orchestrator-patient/triage.request';
import { ReportResponse } from '../../responses/orchestrator-patient/report.response';
import { LastTriageOnAlertTimeResponse } from '../../responses/orchestrator-patient/last-triage-on-alert-time.response';

@Injectable({
  providedIn: 'root'
})
export class TriageService extends CommonService {

  constructor(private httpClient: HttpClient) { super(); }

  public post(triage: TriageRequest): Observable<ReportResponse> {
    let uri = `RiskClassification`
    return this.httpClient.post<ReportResponse>(environment.urlApiOrchestratorPatient + uri, triage, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public getLastTriageOnAlertTime(idPatient: number): Observable<LastTriageOnAlertTimeResponse> {
    let uri = `RiskClassification/lastTriageOnAlertTime/idPatient/${idPatient}`

    return this.httpClient.get<LastTriageOnAlertTimeResponse>(environment.urlApiOrchestratorPatient + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}