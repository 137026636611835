import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { AccessKeyService } from 'src/app/shared/services/API/integration/access-key.service';
import { ManageWebhookService } from 'src/app/shared/services/API/integration/manage-webhook.service';
import { WebhookTypeService } from 'src/app/shared/services/API/integration/webhook-type.service';
import { MaskService } from 'src/app/shared/services/mask.service';
import { AccessKeyModel } from 'src/app/shared/services/models/integration/access-key.model';
import { WebhookType } from 'src/app/shared/services/models/integration/webhook-type.model';
import { PostWebhookRequest } from 'src/app/shared/services/requests/integration/post-webhook.request';
import { PutWebhookRequest } from 'src/app/shared/services/requests/integration/put-webhook.request';

@Component({
  selector: 'app-webhook-register',
  templateUrl: './webhook-register.component.html',
  styleUrls: ['./webhook-register.component.css']
})
export class WebhookRegisterComponent implements OnInit {

  constructor(private formBuilder: UntypedFormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private accessKeyService: AccessKeyService,
    private manageWebhookService: ManageWebhookService,
    private webhookTypeService: WebhookTypeService

  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.integration;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.webhook_list;

  public model: UntypedFormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;
  public actualServiceModule: number;

  public idWebhook: number = null;
  public listAccessKey: AccessKeyModel[];
  public listWebhookType: WebhookType[];

  public createWebhookRequest: PostWebhookRequest;
  public updateWebhookRequest: PutWebhookRequest;


  ngOnInit(): void {
    this.isLoading = false;

    this.model = this.formBuilder.group({
      name: ['', [Validators.required]],
      status: ['', [Validators.required]],
      token: [''],
      url: ['', Validators.required],
      idAccessKey: ['', Validators.required],
      idWebhookType: ['', Validators.required],
    });

    this.updateWebhookRequest = new PutWebhookRequest();
    this.createWebhookRequest = new PostWebhookRequest();

    if (this.activatedRoute.snapshot.paramMap.get('idWebhook'))
      this.idWebhook = parseInt(this.activatedRoute.snapshot.paramMap.get('idWebhook'));

    this.populateAccessKeySelect();
    this.populateWebhookTypeSelect();

    if (this.idWebhook) {
      this.isFirstLoading = true;
      this.isUpdate = true;
      this.populateWebhookData();
    }
  }

  submit() {
    if (this.isLoading) {
      return;
    }
    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }
    this.isLoading = true;

    if (this.idWebhook) {
      this.updateWebhookRequest.name = this.model.get('name').value;
      this.updateWebhookRequest.idAccessKey = this.model.get('idAccessKey').value;
      this.updateWebhookRequest.isActive = this.model.get('status').value;
      this.updateWebhookRequest.token = this.model.get('token').value;
      this.updateWebhookRequest.url = this.model.get('url').value;
    }
    else {
      this.createWebhookRequest.name = this.model.get('name').value;
      this.createWebhookRequest.idAccessKey = this.model.get('idAccessKey').value;
      this.createWebhookRequest.isActive = this.model.get('status').value;
      this.createWebhookRequest.token = this.model.get('token').value;
      this.createWebhookRequest.url = this.model.get('url').value;
      this.createWebhookRequest.idWebhookType = this.model.get('idWebhookType').value;
    }

    if (this.isUpdate)
      this.updateWebhook();
    else
      this.createWebhook();
  }

  populateWebhookData() {
    this.manageWebhookService.getWebhook(this.idWebhook).subscribe({
      next: (response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.model.get('name').setValue(response.webhook.name)
        this.model.get('idAccessKey').setValue(response.webhook.idAccessKey)
        this.model.get('status').setValue(response.webhook.isActive)
        this.model.get('token').setValue(response.webhook.token)
        this.model.get('url').setValue(response.webhook.url)
        this.model.get('idWebhookType').setValue(response.webhook.idWebhookType)

        if (this.isUpdate) {
          this.model.get('idWebhookType').disable();
        }

        this.isLoading = false;
        this.isFirstLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateAccessKeySelect() {
    this.accessKeyService.listAllAccessKey().subscribe({
      next: (response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.listAccessKey = response.listAccessKey;
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateWebhookTypeSelect() {
    this.webhookTypeService.listWebhookType(this.idWebhook).subscribe({
      next: (response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.listWebhookType = response.listWebhookType;
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  updateWebhook() {
    this.manageWebhookService.updateWebhook(this.updateWebhookRequest, this.idWebhook).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/integration/webhook-list']);

    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  createWebhook() {
    this.manageWebhookService.createWebhook(this.createWebhookRequest).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/integration/webhook-list']);
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }
}

