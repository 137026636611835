import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { FormGroupDirective, FormControl, FormGroup } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { AllergyModel } from '../../services/models/medical-record/allergy.model';
import { SelectService } from '../select/select.service';
import { environment } from 'src/environments/environment';
import { AlertService, AlertType } from '../../services/alert.service';
import { RemoveAllergyModalComponent } from './remove-allergy-modal/remove-allergy-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Set } from 'typescript';
import { StatusBulletComponent } from '../status-bullet/status-bullet.component';
import { constructorParametersDownlevelTransform } from '@angular/compiler-cli';

@Component({
  selector: 'app-allergy-tag',
  templateUrl: './allergy-tag.component.html',
  styleUrls: ['./allergy-tag.component.css']
})
export class AllergyTagComponent implements OnInit, OnChanges {

  constructor(private selectService: SelectService,
    private alertService: AlertService,
    private dialog: MatDialog) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inputListTags && !changes.inputListTags.firstChange && changes.inputListTags.currentValue != changes.inputListTags.previousValue) {
      this.originalListTags = JSON.parse(JSON.stringify(changes.inputListTags.currentValue));
      this.idsAll = new Set(this.originalListTags.map(d => d.idAllergy));
      this.idsDeleted = new Set(this.originalListTags.filter(c => !c.isDeleted).map(d => d.idAllergy));
      this.filteredTags = [...this.filteredTags.filter(d => !this.idsDeleted.has(d.idAllergy))];
      this.listTags = changes.inputListTags.currentValue;
    }
  }

  @Input() inputListTags: AllergyModel[];
  @Input() listTags: AllergyModel[];
  @Input() isError: boolean = false;
  @Input() disabled: boolean = false;
  @Output() updateAllergiesToSave: EventEmitter<AllergyModel[]> = new EventEmitter<AllergyModel[]>();
  private originalListTags: AllergyModel[];

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;

  private idsAll: Set<number>;
  private idsDeleted: Set<number>;
  private readonly denyAllergies: number = -1;
  public filteredTags: AllergyModel[] = [];
  public selectable = true;
  public removable = true;
  public addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  ngOnInit(): void {
    this.originalListTags = JSON.parse(JSON.stringify(this.inputListTags));
    this.inputListTags.forEach((x) => {
      this.listTags.push(x);
    });
    this.idsAll = new Set(this.originalListTags.map(d => d.idAllergy));

    this.populateSelect(null);
  }

  remove(tag: AllergyModel): void {
    if (this.disabled) {
      this.alertService.show("Aviso", "Campo de alergia desabilitado para alterações!", AlertType.warning);
      return;
    }
    const index = this.listTags.indexOf(tag);
    if (index >= 0) {
      let originalTag = this.originalListTags.findIndex(c => c.idAllergy == tag.idAllergy);

      if (originalTag == -1 || tag.idAllergy == this.denyAllergies) {
        this.filteredTags.push(tag);
        this.listTags.splice(index, 1);
        this.filteredTags.sort((a, b) => this.orderAllergy(a, b));
        this.tagInput.nativeElement.value = null;
        this.updateAllergiesToSave.emit(this.listTags);
      }

      else {
        let reportDialog = this.dialog.open(RemoveAllergyModalComponent);
        reportDialog.afterClosed().subscribe((response) => {
          if (response && response.isRemoved) {
            this.listTags[index].isDeleted = true;

            if (originalTag >= 0 && this.originalListTags[originalTag].isDeleted) {
              this.listTags[index].motive = this.originalListTags[originalTag].motive;
            }

            else {
              this.listTags[index].motive = response.motive;
            }
            this.filteredTags.push(tag);
            this.filteredTags.sort((a, b) => this.orderAllergy(a, b));
            this.tagInput.nativeElement.value = null;
            this.updateAllergiesToSave.emit(this.listTags);
          }
        });
      }
    }
  }

  reintroduce(event: any) {
    if (this.disabled) {
      this.alertService.show("Aviso", "Campo de alergia desabilitado para alterações!", AlertType.warning);
      return;
    }
    let tag = this.listTags.find(x => x.idAllergy == event.idAllergy);
    tag.isDeleted = false;
    tag.motive = null;

    let indexDeny = this.listTags.findIndex(c => c.idAllergy == this.denyAllergies);

    if (indexDeny >= 0) {
      this.filteredTags.push(this.listTags[indexDeny]);
      this.listTags.splice(indexDeny, 1);
    }

    this.filteredTags.sort((a, b) => this.orderAllergy(a, b));
    this.updateAllergiesToSave.emit(this.listTags);
  }

  removeOldAndNewTags(tagIndex: number, ids: Set<number>, tag: AllergyModel) {
    let reportDialog = this.dialog.open(RemoveAllergyModalComponent, { data: { deniesAllergies: true } });
    reportDialog.afterClosed().subscribe((response) => {
      if (response && response.isRemoved) {
        this.filteredTags.splice(tagIndex, 1);
        this.listTags.forEach((x) => {
          if (ids.has(x.idAllergy)) {
            let oldTag = this.originalListTags.find(y => y.idAllergy == x.idAllergy);
            if (oldTag.isDeleted) {
              x.isDeleted = true;
              x.motive = oldTag.motive;
              x.idUser = oldTag.idUser;
              x.userName = oldTag.userName;
              x.dateTimeInclusion = oldTag.dateTimeInclusion;
              x.dateTimeDelete = oldTag.dateTimeDelete;
            } else {
              x.isDeleted = true;
              x.motive = response.motive;
              x.dateTimeInclusion = oldTag.dateTimeInclusion;
            }
          }
          if (!this.filteredTags.find(y => y.idAllergy == x.idAllergy)) {
            this.filteredTags.push(x);
          }
        });
        this.listTags = this.listTags.filter(x => ids.has(x.idAllergy));
        this.filteredTags.sort((a, b) => this.orderAllergy(a, b));
        this.tagInput.nativeElement.value = null;
        this.listTags.push(tag);
        this.updateAllergiesToSave.emit(this.listTags);
      }
    });
  }

  removeOnlyNewTags(tagIndex: number, tag: AllergyModel) {
    this.filteredTags.splice(tagIndex, 1);
    this.listTags.forEach((x) => {
      if (!x.isDeleted) {
        this.filteredTags.push(x);
      }
    });
    this.listTags = new Array();
    this.filteredTags.sort((a, b) => this.orderAllergy(a, b));
    this.tagInput.nativeElement.value = null;
    this.listTags.push(tag);
    this.updateAllergiesToSave.emit(this.listTags);
  }

  returnOldTagToList(tagIndex: number, tag: AllergyModel) {
    let indexDeny = this.listTags.findIndex(c => c.idAllergy == this.denyAllergies);
    if (indexDeny >= 0) {
      this.filteredTags.push(this.listTags[indexDeny]);
      this.listTags.splice(indexDeny, 1)
    }
    this.filteredTags.splice(tagIndex, 1)
    let indexList = this.listTags.findIndex(c => c.idAllergy == tag.idAllergy);
    this.listTags[indexList].isDeleted = false;
    this.listTags[indexList].motive = null;
    this.updateAllergiesToSave.emit(this.listTags);
  }

  selectNewTag(tagIndex: number, tag: AllergyModel) {
    let indexDeny = this.listTags.findIndex(c => c.idAllergy == this.denyAllergies);
    if (indexDeny >= 0) {
      this.filteredTags.push(this.listTags[indexDeny]);
      this.listTags.splice(indexDeny, 1);
    }
    this.filteredTags.splice(tagIndex, 1)
    this.listTags.push(tag);
    this.tagInput.nativeElement.value = null;
    this.filteredTags.sort((a, b) => this.orderAllergy(a, b));
    this.updateAllergiesToSave.emit(this.listTags);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    let tag: AllergyModel = {
      idAllergy: event.option.value.idAllergy,
      allergyName: event.option.value.allergyName,
      isDeleted: false,
      motive: null,
      idUser: null,
      userName: null,
      dateTimeInclusion: null,
      dateTimeDelete: null,
    }
    let tagIndex = this.filteredTags.findIndex(l => l.idAllergy == tag.idAllergy);

    if (tagIndex >= 0) {
      if (tag.idAllergy == this.denyAllergies && this.listTags.some(x => this.idsAll.has(x.idAllergy))) {
        this.removeOldAndNewTags(tagIndex, this.idsAll, tag);
      }
      else if (tag.idAllergy == this.denyAllergies && this.listTags.every(x => !this.idsAll.has(x.idAllergy))) {
        this.removeOnlyNewTags(tagIndex, tag);
      }
      else if (this.idsAll.has(tag.idAllergy)) {
        this.returnOldTagToList(tagIndex, tag);
      }
      else {
        this.selectNewTag(tagIndex, tag);
      }
    }
    this.updateAllergiesToSave.emit(this.listTags);
  }

  orderAllergy(a, b): number {
    return a.idAllergy == this.denyAllergies ? -1 : (b.idAllergy == this.denyAllergies ? 1 : a.allergyName.localeCompare(b.allergyName, undefined, { numeric: true }))
  };

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.populateSelect(event.target.value);
      }
    }, 1000);
  }

  populateSelect(searchText: string): AllergyModel[] {
    this.selectService.getSelectValues(environment.urlApiMedicalRecord + "Allergy", searchText).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        if (Array.isArray(this.listTags) && this.listTags.length > 0) {
          var ids = new Set(this.listTags.map(d => d.idAllergy));
          this.filteredTags = [...response.list.filter(d => !ids.has(d.idAllergy))];
        }
      }, error: (err) => {
        this.alertService.show('Erro Inesperado', err, AlertType.error);
        return [];
      }
    });
    return [];
  }
}
