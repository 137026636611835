<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <a class="back-link" routerLink="/observation/bed"><mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Leitos</a>
        <h1>{{this.isUpdate? 'Alterar' : 'Cadastrar'}} Leito</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">       
                <div class="row">   
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome</mat-label>
                            <input matInput type="text" formControlName="name">
                            <mat-error *ngIf="model.get('name').invalid">Informe o nome</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Status</mat-label>
                            <mat-select formControlName="idBedStatus">
                                <mat-option *ngFor="let item of listBedStatus" value="{{item.idBedStatus}}">{{item.bedStatusName}}</mat-option> 
                            </mat-select>
                            <mat-error *ngIf="model.get('idBedStatus').invalid">Informe o status</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Setor</mat-label>
                            <mat-select formControlName="idSector">
                                <mat-option *ngFor="let sector of listSector" [value]="sector.idSector">
                                    {{sector.sectorName}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idSector').invalid">Informe o setor</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Tipo do Leito</mat-label>
                            <mat-select formControlName="idBedType">
                              <mat-option *ngFor="let item of listBedType" value="{{item.idBedType}}">{{item.bedTypeName}}</mat-option> 
                            </mat-select>
                            <mat-error *ngIf="model.get('idBedType').invalid">Informe o tipo do leito</mat-error>
                        </mat-form-field>
                    </div>    
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Categoria do Leito</mat-label>
                            <mat-select formControlName="idBedCategory">
                              <mat-option *ngFor="let item of listBedCategory" value="{{item.idBedCategory}}">{{item.bedCategoryName}}</mat-option> 
                            </mat-select>
                            <mat-error *ngIf="model.get('idBedCategory').invalid">Informe a categoria do leito</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4" *ngIf="hygienization">
                        <mat-form-field appearance="outline">
                            <mat-label>Higienização do Leito</mat-label>
                            <mat-select formControlName="idHygienizationStatus">
                              <mat-option *ngFor="let item of listHygienizationStatus" [value]="item.idHygienizationStatus">{{item.hygienizationStatusName}}</mat-option> 
                            </mat-select>
                            <mat-error *ngIf="model.get('idHygienizationStatus').invalid">Informe a higienização do leito</mat-error>
                        </mat-form-field>
                    </div>    
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <a mat-flat-button type="button" routerLink="/observation/bed" color="accent" class=" btn-block">
                            Cancelar
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>
    
</div>