import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ListAllAuditMonthlyReportResponse } from '../../responses/audit/list-all-audit-monthly-report.response';
import { ReportResponse } from '../../responses/orchestrator-patient/report.response';

@Injectable({
  providedIn: 'root'
})
export class ApiAuditMonthlyReportService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listAllAuditMonthlyReport(idAuditMonthlyReport: number, yearMonth: Date): Observable<ListAllAuditMonthlyReportResponse> {
    let uri = `AuditMonthlyReport?`
    
    uri += yearMonth != null ? `yearMonth=${yearMonth.toISOString()}` : ``;
    uri += idAuditMonthlyReport != null ? `&idAuditMonthlyReport=${idAuditMonthlyReport}` : ``;
      
    return this.httpClient.get<ListAllAuditMonthlyReportResponse>(environment.urlApiAudit + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public getAuditMonthlyReport(idAuditMonthlyReport: number): Observable<ReportResponse> {
    let uri = `AuditMonthlyReport/idAuditMonthlyReport/${idAuditMonthlyReport}`  
      
    return this.httpClient.get<ReportResponse>(environment.urlApiAudit + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}