import { ConsolidatedDataStruct } from "../../structs/billing/consonlidated-data-struct";

export class ProfessionalProductionAttendmentRequest{

    public listConsolidatedData: ConsolidatedDataStruct[];

    public listColumnToDisplay: number[];
    
    public datetimeStart: Date;
    
    public datetimeEnd: Date;
}