<div>
    <header>
        <h1 class="title-name">Atendimento Remoto</h1>
        <div class="header-logo">
            <div class="header-logo-content">
                <img class="client-brand" src="assets/images/logo-colorido.png">
            </div>
        </div>
    </header>
    <body class="body-container">

        <div class="body-content">
            <div class="white-container">
                <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                    <span class="welcome-intro">Digite seu CPF para começar:</span>
                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field appearance="outline">
                                <mat-label>CPF</mat-label>
                                <input matInput [mask]="masks.cpf" type="text" formControlName="cpf" required>
                                <mat-error *ngIf="model.get('cpf').invalid">cpf inválido.</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <button mat-flat-button type="submit" color="primary" class=" btn-block enter-button">
                                <span *ngIf="isLoading == false">Confirmar</span>
                                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        
    </body>
</div>