<div class="sub-section" *ngIf="listPrescription != null && listPrescription.length > 0">
    <h3>Medicamentos</h3>
</div>
<div class="card-list" *ngIf="listPrescription != null && listPrescription.length > 0">
    <div class="row row-list">
        <ng-container *ngFor="let item of listPrescription; let index = index;">
            <div class="col-12 col-sm-6 col-md-4">
                <div class="card-item">
                    <div class="infos">
                        <div class="card-header">
                            <span class="primary">{{item.medicineName}}</span>
                            <span *ngIf="item.idPrescriptionStatus == nonDispensedStatus" class="status">Não
                                dispensada</span>
                        </div>
                        <span class="secundary">Quantidade: {{item.quantity}}</span>
                        <span class="secundary">Uso: {{item.usage}}</span>
                        <span class="secundary">Via: {{item.administrationTypeName}}</span>
                        <span class="secundary">Apresentação: {{item.presentationName}}</span>
                        <span class="secundary">Frequência:{{item.frequencyName}} {{item.frequency}}</span>
                        <span class="secundary">Periodo trat. :{{item.treatmentPeriodName}} {{item.treatmentPeriod}}</span>
                        <span class="secundary">Diluição: {{item.dilution? item.dilution : item.dilutionName}}</span>
                        <span *ngIf="item.idPrescriptionStatus == nonDispensedStatus" class="secundary">Motivo: {{item.nonDispensationReason}}</span>
                        <span class="secundary-status">{{item.prescriptionCheckStatus}}</span>
                        <span *ngIf="item.observation" class="secundary">Observação: {{item.observation}}</span>
                        <span class="secundary-status last-info">{{statusIdToText(item.thisMedicalPrescriptionCheck?.medicalPrescriptionStatus)}}</span>
                        <span *ngIf="item.tempCheck" class="checked-tag">
                            <mat-icon class="material-symbols-outlined check-icon">done_all</mat-icon>
                            Checado
                        </span>
                    </div>
                    <div class="actions">
                        <a>
                            <mat-icon aria-hidden="false" aria-label="Editar"
                                (click)="openPrescriptionModal(item, false)">visibility
                            </mat-icon>
                        </a>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<div class="sub-section"
    *ngIf="listPrescriptionMedicatedSolution != null && listPrescriptionMedicatedSolution.length > 0">
    <h3>Solução medicamentosa</h3>
</div>
<div class="card-list"
    *ngIf="listPrescriptionMedicatedSolution != null && listPrescriptionMedicatedSolution.length > 0">
    <div class="row row-list">
        <ng-container *ngFor="let item of listPrescriptionMedicatedSolution; let index = index;">
            <div class="col-12 col-sm-6">
                <div class="card-item">
                    <div class="infos">
                        <ng-container *ngFor="let medicine of item.listMedicine; let medicineIndex = index;">
                            <div class="card-header">
                                <span class="col-6 secundary">{{medicine.medicineName}}</span>
                                <span class="col-3 secundary"><b class="bold">Qtde.:</b> {{medicine.quantity}}</span>
                                <span class="col-3 secundary"><b class="bold">Unidade:</b>
                                    {{medicine.measurementUnitName}}</span>
                                
                            </div>
                        </ng-container>
                        <div class="card-content">
                            <div class="col-6">
                                <span class="secundary"><b class="bold">Apresentação:</b>
                                    {{item.presentationName}}</span>
                                <span class="secundary"><b class="bold">Via:</b> {{item.administrationTypeName}}</span>
                                <span class="secundary"><b class="bold">Periodo trat.:</b> {{item.treatmentPeriodName}}
                                    {{item.treatmentPeriod}}</span>
                            </div>
                            <div class="col-6">
                                <span class="secundary"><b class="bold">Uso:</b> {{item.usage}}</span>
                                <span class="secundary"><b class="bold">Frequência:</b> {{item.frequencyName}}
                                    {{item.frequency}}</span>
                                <span class="secundary"><b class="bold">Diluição:</b> {{item.dilution? item.dilution :
                                    item.dilutionName}}</span>
                                <span class="secundary-status">{{item.prescriptionCheckStatus}}</span>
                                <span
                                    class="secundary-status last-info">{{statusIdToText(item.thisMedicalPrescriptionCheck?.medicalPrescriptionStatus)}}</span>
                            </div>
                        </div>
                        <span *ngIf="item.tempCheck" class="checked-tag">
                            <mat-icon class="material-symbols-outlined check-icon">done_all</mat-icon>
                            Checado
                        </span>
                    </div>
                    <div class="actions">
                        <a>
                            <mat-icon aria-hidden="false" aria-label="Editar"
                                (click)="openPrescriptionModal(item, true)">visibility
                            </mat-icon>
                        </a>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<div
    *ngIf="(listPrescription == null || listPrescription.length == 0) && (listPrescriptionMedicatedSolution == null || listPrescriptionMedicatedSolution.length == 0)">
    <p>Este atendimento não possui prescrições internas!</p>
</div>