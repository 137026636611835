<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <h1>Perfil</h1>
        <div class="white-body">
            <a mat-flat-button color="primary" class=" add-user" routerLink="/master/profile/register"><mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon> Cadastrar perfil</a>
            <div class="filters">
                <div class="row">   
                    <div class="col-12 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Pesquisar</mat-label>
                            <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText" (keyup)="onKeySearch($event)">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="card-list" *ngIf="listProfile != null">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listProfile">
                        <div class="card-item">
                            <div class="infos">
                                <div class="primary-info">
                                    <span class="primary">{{item.profileName}}</span>
                                    <div *ngIf="item.canStayConnected" class="stay-connected-icon"
                                        matTooltip="Perfil com permissão para manter login conectado."
                                        matTooltipPosition="right">
                                        <mat-icon aria-hidden="false" aria-label="bookmark">bookmark</mat-icon>
                                    </div>
                                </div>
                                <div *ngIf="item.canStayConnected" class="stay-connected">
                                    <div class="info-icon">
                                        <mat-icon aria-hidden="false" aria-label="info">info</mat-icon>
                                    </div>
                                    <span class="secundary">
                                        Perfil com permissão para manter login conectado
                                    </span>
                                </div>
                                <span class="secundary">{{item.description}}</span><span class="secundary"
                                    matTooltip="{{getFormatedListUserName(item.listUserName)}}"><b>Usuários:</b> {{getFormatedListUserName(item.listUserName)}}</span>
                            </div>
                            <div class="actions">
                                <a><mat-icon aria-hidden="false" aria-label="Editar" [routerLink] = "['/master/profile/register', {idProfile: item.idProfile}]">edit</mat-icon> </a>
                                <a><mat-icon aria-hidden="false" aria-label="Editar" (click)="openModal(item.idProfile)">delete_forever</mat-icon> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <app-empty-list *ngIf="listProfile != undefined && listProfile.length == 0"></app-empty-list>
        </div>
    
        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>
    
</div>