import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ListMedicineTagResponse } from '../../responses/pharmacy/list-medicine-tag.response';

@Injectable({
  providedIn: 'root'
})
export class MedicineTagService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listMedicineTag(): Observable<ListMedicineTagResponse> {
    let uri = 'MedicineTag'
    
    return this.httpClient.get<ListMedicineTagResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
