<div>
    <header>
        <h1 class="title-name">Atendimento Remoto</h1>
        <div class="header-logo">
            <div class="header-logo-content">
                <img class="client-brand" src="assets/images/logo-colorido.png">
            </div>
        </div>
    </header>
    <body class="body-container">
        <app-twilio-video #twiliovideo (leaveRoom)="onLeaveRoom()" [isPatient]="true"></app-twilio-video>
    </body>
</div>