import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ListMedicConfigResponse } from '../../responses/medic/list-medic-config.response';
import { MedicConfigResponse } from '../../responses/medic/medic-config.response';
import { MedicConfigRequest } from '../../requests/medic/medic-config.request';

@Injectable({
  providedIn: 'root'
})
export class MedicConfigService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getMedicConfig(idMedicConfig: number): Observable<MedicConfigResponse> {
    
    let uri = `MedicConfig/idMedicConfig/${idMedicConfig}`;
    
    return this.httpClient.get<MedicConfigResponse>(environment.urlApiMedic + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public listAllMedicConfig(searchText: string, jsonListIdSector: string): Observable<ListMedicConfigResponse> {
    
    let uri = 'MedicConfig?';

    if(searchText) uri += `searchText=${searchText}&`;

    if(jsonListIdSector) uri += `jsonListIdSector=${jsonListIdSector}&`;
    
    return this.httpClient.get<ListMedicConfigResponse>(environment.urlApiMedic + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public createMedicConfig(request: MedicConfigRequest): Observable<ReturnStruct> {
    
    let uri = 'MedicConfig'

    return this.httpClient.post<ReturnStruct>(environment.urlApiMedic + uri, request, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public updateMedicConfig(idMedicConfig: number, request: MedicConfigRequest): Observable<ReturnStruct> {
    
    let uri = `MedicConfig/idMedicConfig/${idMedicConfig}`;

    return this.httpClient.put<ReturnStruct>(environment.urlApiMedic + uri, request, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public deleteMedicConfig(idMedicConfig: number): Observable<ReturnStruct>{

    let uri = `MedicConfig/idMedicConfig/${idMedicConfig}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiMedic + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
