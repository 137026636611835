import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { AuditListComponent } from './pages/audit-list/audit-list.component';
import { AuditRegisterComponent } from './pages/audit-register/audit-register.component';
import { AuditComponent } from './pages/audit/audit.component';
import { ExecuteAuditComponent } from './pages/execute-audit/execute-audit.component';
import { IndividualAuditReportComponent } from './pages/individual-audit-report/individual-audit-report.component';
import { DivergentAuditReportComponent } from './pages/divergent-audit-report/divergent-audit-report.component';
import { MonthlyReportComponent } from './pages/monthly-report/monthly-report.component';
import { MonthlyReportRegisterComponent } from './pages/monthly-report-register/monthly-report-register.component';
import { AuditConfigComponent } from './pages/audit-config/audit-config.component';


const routes: Routes = [
  { path: 'audit', component: AuditComponent, canActivate: [AuthGuard] },
  { path: 'audit/audit', component: AuditListComponent, canActivate: [AuthGuard] },
  { path: 'audit/audit/register', component: AuditRegisterComponent, canActivate: [AuthGuard] },
  { path: 'audit/audit/execute', component: ExecuteAuditComponent, canActivate: [AuthGuard] },
  { path: 'audit/individual-audit-report', component: IndividualAuditReportComponent, canActivate: [AuthGuard] },
  { path: 'audit/divergent-audit-report', component: DivergentAuditReportComponent, canActivate: [AuthGuard] },
  { path: 'audit/audit-monthly-report', component: MonthlyReportComponent, canActivate: [AuthGuard] },
  { path: 'audit/audit-monthly-report/register', component: MonthlyReportRegisterComponent, canActivate: [AuthGuard] },
  { path: 'audit/audit-config', component: AuditConfigComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuditRoutingModule { }
