<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <a class="back-link" routerLink="/master/care-line">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Linhas de Cuidado
        </a>
        <h1>Cadastrar Linha de Cuidado</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome</mat-label>
                            <input matInput type="text" formControlName="careLineName">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Descrição</mat-label>
                            <input matInput type="text" formControlName="careLineDescription">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
    
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Status</mat-label>
                            <mat-select formControlName="isActive">
                                <mat-option [value]="true">Ativo</mat-option>
                                <mat-option [value]="false">Inativo</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Tipo</mat-label>
                            <mat-select formControlName="listIdCareLineType" multiple>
                                <mat-option *ngFor="let item of listCareLineType" value="{{item.idCareLineType}}">
                                    {{item.careLineTypeName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Faixa etária</mat-label>
                            <mat-select formControlName="listIdAgeRange" multiple
                                (selectionChange)="checkOutros(['AgeRangeMin','AgeRangeMax'])">
                                <mat-option *ngFor="let item of listAgeRange" value="{{item.idAgeRange}}">
                                    {{item.ageRangeName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Doenças</mat-label>
                            <mat-select formControlName="listIdComorbidity" multiple
                                (selectionChange)="checkOutros(['Comorbidity'])">
                                <mat-option *ngFor="let item of listComorbidity" value="{{item.idComorbidity}}">
                                    {{item.comorbidityName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Medicamentos</mat-label>
                            <mat-select formControlName="listIdMedication" multiple
                                (selectionChange)="checkOutros(['Medication'])">
                                <mat-option value="{{idMedicineOutros}}">OUTROS</mat-option>
                                <mat-option *ngFor="let item of listMedicine" value="{{item.medicine.idMedicine}}">
                                    {{item.medicine.medicineDescription}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline" *ngIf="isOtherAgeRange">
                            <mat-label>Idade mínima</mat-label>
                            <input matInput (blur)="validateAge($event.target.value)" type="text"
                                formControlName="otherAgeRangeMin" [mask]="masks.age.mask" [pattern]="masks.age.pattern">
                                <mat-hint>013a11m29d</mat-hint>
                        </mat-form-field>
                        <mat-form-field appearance="outline" *ngIf="isOtherAgeRange">
                            <mat-label>Idade máxima</mat-label>
                            <input matInput (blur)="validateAge($event.target.value)" type="text"
                                formControlName="otherAgeRangeMax" [mask]="masks.age.mask" [pattern]="masks.age.pattern">
                                <mat-hint>013a11m29d</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline" *ngIf="isOtherComorbidity">
                            <mat-label>Outros Comorbidades</mat-label>
                            <textarea matInput type="text" formControlName="otherComorbidity"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline" *ngIf="isOtherMedicine">
                            <mat-label>Outros Medicamentos</mat-label>
                            <textarea matInput type="text" formControlName="otherMedication"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="title-header">
                    <h1>Pressão Arterial</h1>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Mínimo</mat-label>
                            <input [mask]="masks.pressure" matInput type="text" formControlName="bloodPressureGreenMin">
                            <span matSuffix>mmHg</span>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Mínimo Amarelo</mat-label>
                            <input [mask]="masks.pressure" matInput type="text" formControlName="bloodPressureYellowMin">
                            <span matSuffix>mmHg</span>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Mínimo Vermelho</mat-label>
                            <input [mask]="masks.pressure" matInput type="text" formControlName="bloodPressureRedMin">
                            <span matSuffix>mmHg</span>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Máximo</mat-label>
                            <input [mask]="masks.pressure" matInput type="text" formControlName="bloodPressureGreenMax">
                            <span matSuffix>mmHg</span>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Máximo Amarelo</mat-label>
                            <input [mask]="masks.pressure" matInput type="text" formControlName="bloodPressureYellowMax">
                            <span matSuffix>mmHg</span>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Máximo Vermelho</mat-label>
                            <input [mask]="masks.pressure" matInput type="text" formControlName="bloodPressureRedMax">
                            <span matSuffix>mmHg</span>
                        </mat-form-field>
                    </div>
                </div>
                <div class="title-header">
                    <h1>Glicose</h1>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Mínimo</mat-label>
                            <input [mask]="masks.threeDigits" matInput type="text" formControlName="glucoseGreenMin">
                            <span matSuffix>mg/dL</span>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Mínimo Amarelo</mat-label>
                            <input [mask]="masks.threeDigits" matInput type="text" formControlName="glucoseYellowMin">
                            <span matSuffix>mg/dL</span>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Mínimo Vermelho</mat-label>
                            <input [mask]="masks.threeDigits" matInput type="text" formControlName="glucoseRedMin">
                            <span matSuffix>mg/dL</span>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Máximo</mat-label>
                            <input [mask]="masks.threeDigits" matInput type="text" formControlName="glucoseGreenMax">
                            <span matSuffix>mg/dL</span>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Máximo Amarelo</mat-label>
                            <input [mask]="masks.threeDigits" matInput type="text" formControlName="glucoseYellowMax">
                            <span matSuffix>mg/dL</span>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Máximo Vermelho</mat-label>
                            <input [mask]="masks.threeDigits" matInput type="text" formControlName="glucoseRedMax">
                            <span matSuffix>mg/dL</span>
                        </mat-form-field>
                    </div>
                </div>
                <div class="title-header">
                    <h1>Saturação</h1>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Mínimo</mat-label>
                            <input [mask]="masks.threeDigits" matInput type="text" formControlName="saturationGreenMin">
                            <span matSuffix>%</span>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Mínimo Amarelo</mat-label>
                            <input [mask]="masks.threeDigits" matInput type="text" formControlName="saturationYellowMin">
                            <span matSuffix>%</span>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Mínimo Vermelho</mat-label>
                            <input [mask]="masks.threeDigits" matInput type="text" formControlName="saturationRedMin">
                            <span matSuffix>%</span>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Máximo</mat-label>
                            <input [mask]="masks.threeDigits" matInput type="text" formControlName="saturationGreenMax">
                            <span matSuffix>%</span>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Máximo Amarelo</mat-label>
                            <input [mask]="masks.threeDigits" matInput type="text" formControlName="saturationYellowMax">
                            <span matSuffix>%</span>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Máximo Vermelho</mat-label>
                            <input [mask]="masks.threeDigits" matInput type="text" formControlName="saturationRedMax">
                            <span matSuffix>%</span>
                        </mat-form-field>
                    </div>
                </div>
                <div class="title-header">
                    <h1>Frequência Cardiaca</h1>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Mínimo</mat-label>
                            <input [mask]="masks.threeDigits" matInput type="text" formControlName="heartRateGreenMin">
                            <span matSuffix>bpm</span>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Mínimo Amarelo</mat-label>
                            <input [mask]="masks.threeDigits" matInput type="text" formControlName="heartRateYellowMin">
                            <span matSuffix>bpm</span>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Mínimo Vermelho</mat-label>
                            <input [mask]="masks.threeDigits" matInput type="text" formControlName="heartRateRedMin">
                            <span matSuffix>bpm</span>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Máximo</mat-label>
                            <input [mask]="masks.threeDigits" matInput type="text" formControlName="heartRateGreenMax">
                            <span matSuffix>bpm</span>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Máximo Amarelo</mat-label>
                            <input [mask]="masks.threeDigits" matInput type="text" formControlName="heartRateYellowMax">
                            <span matSuffix>bpm</span>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Máximo Vermelho</mat-label>
                            <input [mask]="masks.threeDigits" matInput type="text" formControlName="heartRateRedMax">
                            <span matSuffix>bpm</span>
                        </mat-form-field>
                    </div>
                </div>
                <div class="title-header">
                    <h1>Temperatura</h1>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Mínimo</mat-label>
                            <input [mask]="masks.temperature" matInput type="text" formControlName="temperatureGreenMin">
                            <span matSuffix>°C</span>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Mínimo Amarelo</mat-label>
                            <input [mask]="masks.temperature" matInput type="text" formControlName="temperatureYellowMin">
                            <span matSuffix>°C</span>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Mínimo Vermelho</mat-label>
                            <input [mask]="masks.temperature" matInput type="text" formControlName="temperatureRedMin">
                            <span matSuffix>°C</span>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Máximo</mat-label>
                            <input [mask]="masks.temperature" matInput type="text" formControlName="temperatureGreenMax">
                            <span matSuffix>°C</span>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Máximo Amarelo</mat-label>
                            <input [mask]="masks.temperature" matInput type="text" formControlName="temperatureYellowMax">
                            <span matSuffix>°C</span>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Máximo Vermelho</mat-label>
                            <input [mask]="masks.temperature" matInput type="text" formControlName="temperatureRedMax">
                            <span matSuffix>°C</span>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <a mat-flat-button type="button" routerLink="/master/care-line" color="accent" class=" btn-block">
                            Cancelar
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>
    
</div>