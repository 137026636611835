<div *ngIf="!isLoading" class="content">
    <form class="form" [formGroup]="model">
        <span class="voting-title">
            {{description}}
        </span>
        <div class="row">
            <div class="form-nps">
                <div *ngFor="let item of arrayIndexNps; let i = index">
                    <div class="input-radio-nps">
                        <input type="radio" id="radio-{{i}}" [value]="i" formControlName="nps" (click)="saveNote(i)">
                        <label class="radio-{{i}}" for="radio-{{i}}">{{i}}</label>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <app-loading-list *ngIf="isLoading"></app-loading-list>
</div>