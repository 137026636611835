import { PriceProcedureHealthcareStruct } from "../../structs/private-billing/price-procedure-healthcare.struct";

export class PriceProcedureHealthcareRequest {
    public listPriceProcedureHealthcareStruct: PriceProcedureHealthcareStruct[] = [];
    public idHealthcareAgreement: number;
    public idProcedure: number;
    public pageSize: number;
    public pageIndex: number;
    public isActivePriceProcedureHealthcare: boolean;
    public searchText: string;
}