import { Injectable } from '@angular/core';
import { AlertService, AlertType } from './alert.service';
import { RoomAccessHistoryService } from './API/flow/room-access-history.service';

@Injectable({
  providedIn: 'root'
})
export class RoomAccessUtilService {

  constructor(
    private roomAccessHistoryService: RoomAccessHistoryService,
    private alertService: AlertService,
  ) { }

  postRoomAccessHistory(idRoom: number) {
    this.roomAccessHistoryService.postRoomAccessHistory(idRoom).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);

        return;
      }
    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  roomKeepAliveTime() : number{
    let time = 120000
    return time;
  }
}
