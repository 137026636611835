import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { BedHygienizationService } from 'src/app/shared/services/API/bed/bed-hygienization.service';
import { BedHygienizationRequest } from 'src/app/shared/services/requests/bed/bed-hygienization.request';
import { BedStruct } from 'src/app/shared/services/structs/bed/bed.struct';

@Component({
  selector: 'app-cpf-not-found-modal',
  templateUrl: './cpf-not-found-modal.component.html',
  styleUrls: ['./cpf-not-found-modal.component.css']
})
export class CpfNotFoundModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<CpfNotFoundModalComponent>,
  ) {
    this.matDialogRef.disableClose = true;
  }

  public isLoading: boolean;

  ngOnInit(): void {
    this.isLoading = false;
  }

  clickTryAgain() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.matDialogRef.close(true);
  }

  clickCancel() {
    this.matDialogRef.close();
  }
}