import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { ReportModalComponent } from 'src/app/shared/components/report-modal/report-modal.component';
import { PrescriptionStatusEnum } from 'src/app/shared/enum/prescription-status.enum';
import { RequestMaterialStatusEnum } from 'src/app/shared/enum/request-material-status.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MedicalPrescriptionService } from 'src/app/shared/services/API/medical-record/medical-prescription.service';
import { CallEpisodePrescriptionService } from 'src/app/shared/services/API/orchestrator-patient/call-episode-prescription.service';
import { ItemService } from 'src/app/shared/services/API/pharmacy/Item.service';
import { StorageMaterialLookupService } from 'src/app/shared/services/API/pharmacy/storage-material-lookup.service';
import { ItemModel } from 'src/app/shared/services/models/pharmacy/item.model';
import { RequestMaterialStruct } from 'src/app/shared/services/models/pharmacy/request-material.model';
import { EpisodeStruct } from 'src/app/shared/services/structs/medical-record/episode.struct';
import { MedicalPrescriptionEpisodeStruct } from 'src/app/shared/services/structs/medical-record/medical-prescription-episode.struct';
import { MedicalPrescriptionStruct } from 'src/app/shared/services/structs/medical-record/medical-prescription.struct';
import { TriageStruct } from 'src/app/shared/services/structs/orchestrator-patient/triage.struct';
import { StorageLocationStruct } from 'src/app/shared/services/structs/pharmacy/storage-location.struct';
import { EpisodeMaterialModalComponent } from './episode-material-modal/episode-material-modal.component';
import { EpisodePrescriptionModalComponent } from './episode-prescription-modal/episode-prescription-modal.component';
import { MaterialNonDispensationModalComponent } from './material-non-dispensation-modal/material-non-dispensation-modal.component';
import { PrescriptionNonDispensationModalComponent } from './prescription-non-dispensation-modal/prescription-non-dispensation-modal.component';
import { FrequencyEnum } from 'src/app/shared/enum/prescription.enum';
import { MedicalPrescriptionSolutionStruct } from 'src/app/shared/services/structs/medical-record/medical-prescription-solution.struct';
import { MedicalPrescriptionSolutionModalComponent } from './medical-prescription-solution-modal/medical-prescription-solution-modal.component';
import { LocationModel } from 'src/app/shared/services/models/pharmacy/location.model';
import { LocationService } from 'src/app/shared/services/API/pharmacy/location.service';
import { ViewMedicineRequest } from 'src/app/shared/services/requests/medical-record/view-medicine.request';
import { EpisodePrescriptionResponse } from 'src/app/shared/services/responses/orchestrator-patient/episode-prescription.response';
import { MedicineService } from 'src/app/shared/services/API/pharmacy/medicine.service';
import { MedicineModel } from 'src/app/shared/services/models/pharmacy/medicine.model';

@Component({
  selector: 'app-episode-prescription',
  templateUrl: './episode-prescription.component.html',
  styleUrls: ['./episode-prescription.component.css']
})
export class EpisodePrescriptionComponent implements OnInit {

  constructor(
    private callEpisodePrescriptionService: CallEpisodePrescriptionService,
    private medicalPrescriptionService: MedicalPrescriptionService,
    private alertService: AlertService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private itemService: ItemService,
    private storageMaterialLookupService: StorageMaterialLookupService,
    private formBuilder: UntypedFormBuilder,
    private locationService: LocationService,
    private medicineService: MedicineService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.pharmacy;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.pharmacy_prescription;

  public materialLiberado: RequestMaterialStatusEnum = RequestMaterialStatusEnum.concluded;
  public materialNaoDispensado: RequestMaterialStatusEnum = RequestMaterialStatusEnum.notDispensed;
  public materialEmAberto: RequestMaterialStatusEnum = RequestMaterialStatusEnum.waiting;

  public prescricaoLiberado: PrescriptionStatusEnum = PrescriptionStatusEnum.Liberado;
  public prescricaoNaoDispensada: PrescriptionStatusEnum = PrescriptionStatusEnum.NaoDispensado;
  public prescricaoEmAberto: PrescriptionStatusEnum = PrescriptionStatusEnum.EmAberto;

  public medicalPrescriptionEpisode: MedicalPrescriptionEpisodeStruct;
  public episode: EpisodeStruct;
  public selectionAll: boolean = false;
  public listSolutionPrescription: MedicalPrescriptionSolutionStruct[];
  public listSolutionPrescriptionReport: MedicalPrescriptionSolutionStruct[];
  public listSolutionPrescriptionDisplay: MedicalPrescriptionSolutionStruct[];
  public listSolutionPrescriptionDisplayCheck: MedicalPrescriptionSolutionStruct[];
  public listPrescription: MedicalPrescriptionStruct[];
  public listPrescriptionReport: MedicalPrescriptionStruct[];
  public listPrescriptionReportCheck: MedicalPrescriptionStruct[];
  public listPrescriptionDisplay: MedicalPrescriptionStruct[];
  public listLocation: LocationModel[];

  public isLoading: boolean;
  public idEpisode: number;
  public listIdMedicalPrescription: number[];
  public idSector: number;
  public triage: TriageStruct;
  public requestMaterialList: RequestMaterialStruct[];
  public requestMaterialListDisplay: RequestMaterialStruct[];
  public listItems: ItemModel[];
  public listMedicines: MedicineModel[] = [];
  public reportModel: UntypedFormGroup;
  public listStorage: StorageLocationStruct[];
  public isActive: boolean;
  public isFirstLoading: boolean;
  public showNotDispensedPrescriptions: boolean = false;
  public showNotDispensedMaterials: boolean = false;
  public frequencyEnum = FrequencyEnum;
  public genericTagColor: string = "#85C443";

  ngOnInit(): void {
    this.episode = new EpisodeStruct();
    this.triage = new TriageStruct();
    this.listPrescription = [];
    if (this.activatedRoute.snapshot.paramMap.get('idEpisode'))
      this.idEpisode = parseInt(this.activatedRoute.snapshot.paramMap.get('idEpisode'));

    if (this.activatedRoute.snapshot.paramMap.get('idSector'))
      this.idSector = parseInt(this.activatedRoute.snapshot.paramMap.get('idSector'));

    this.isLoading = true;
    this.reportModel = this.formBuilder.group({
      medicinesReport: [false],
    });

    this.search();
    this.populateItemList();
    this.populateMedicineList();
    this.populateListStorage();
    this.populateLocationSelect();
  }

  search() {
    this.isLoading = true;
    this.isFirstLoading = true;
    this.callEpisodePrescriptionService.listPrescription(this.idEpisode).subscribe({
      next: (response: EpisodePrescriptionResponse) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          this.isFirstLoading = false;
          return;
        }
        this.isActive = response.isActive;
        this.episode = response.episodePrescription.episode;
        this.triage = response.episodePrescription.triage;
        this.listSolutionPrescription = response.episodePrescription.listMedicalSolutionPrescription;
        this.listPrescription = response.episodePrescription.medicalPrescription;
        this.listPrescriptionReport = this.listPrescription.filter(x => x.idPrescriptionStatus == PrescriptionStatusEnum.Liberado);
        this.listPrescriptionReport.forEach(c => {
          c.isChecked = false
        });

        this.listSolutionPrescriptionReport = [...this.listSolutionPrescription.filter(presc =>
          presc.listPrescription.filter(x => x.idPrescriptionStatus == PrescriptionStatusEnum.Liberado))];
        this.listSolutionPrescriptionReport.forEach(c => {
          c.isChecked = false
        });

        this.requestMaterialList = response.requestMaterial;
        this.listPrescription.forEach(element => {
          if (element.idSector == this.idSector)
            element.isSelectedSector = true;
        });

        this.listPrescription.sort((a, b) => {
          if (a.idPrescriptionStatus == b.idPrescriptionStatus)
            return 0;

          else if (a.idPrescriptionStatus == PrescriptionStatusEnum.EmAberto)
            return -1;

          else if (a.idPrescriptionStatus == PrescriptionStatusEnum.Liberado) {
            if (b.idPrescriptionStatus == PrescriptionStatusEnum.EmAberto)
              return 1;

            if (b.idPrescriptionStatus == PrescriptionStatusEnum.NaoDispensado)
              return -1;
          }
          else if (a.idPrescriptionStatus == PrescriptionStatusEnum.NaoDispensado)
            return 1;
        });
        this.requestMaterialList.sort((a, b) => {
          if (a.idStatus == b.idStatus)
            return 0;

          else if (a.idStatus == RequestMaterialStatusEnum.waiting)
            return -1;

          else if (a.idStatus == RequestMaterialStatusEnum.concluded) {
            if (b.idStatus == RequestMaterialStatusEnum.waiting)
              return 1;

            if (b.idStatus == RequestMaterialStatusEnum.notDispensed)
              return -1;
          }
          else if (a.idStatus == RequestMaterialStatusEnum.concluded)
            return 1;
        });

        this.listSolutionPrescription.forEach(x => {
          if (x.listPrescription.some(c => c.idPrescriptionStatus == PrescriptionStatusEnum.Liberado)) {
            x.idSolutionStatus = PrescriptionStatusEnum.Liberado;
            x.solutionStatus = "Liberado";
          }
          else if (x.listPrescription.some(c => c.idPrescriptionStatus == PrescriptionStatusEnum.EmAberto)) {
            x.idSolutionStatus = PrescriptionStatusEnum.EmAberto;
            x.solutionStatus = "Em aberto";
          }
          else if (x.listPrescription.every(c => c.idPrescriptionStatus == PrescriptionStatusEnum.NaoDispensado)) {
            x.idSolutionStatus = PrescriptionStatusEnum.NaoDispensado;
            x.solutionStatus = "Não dispensado";
          }
        });
        this.listPrescriptionDisplay = [...this.listPrescription.filter(presc => presc.idPrescriptionStatus != PrescriptionStatusEnum.NaoDispensado)];

        this.listSolutionPrescriptionDisplay = [...this.listSolutionPrescription.filter(presc =>
          presc.listPrescription.filter(x => x.idPrescriptionStatus != PrescriptionStatusEnum.NaoDispensado))];

        this.requestMaterialListDisplay = [...this.requestMaterialList];

        this.isLoading = false;
        this.isFirstLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.isFirstLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openModal(prescription) {
    const dialogRef = this.dialog.open(EpisodePrescriptionModalComponent, {
      data: {
        prescription: prescription,
        episode: this.episode,
        listLocation: this.listLocation
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        this.search();
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  openPrescriptionSolutionModal(solution: MedicalPrescriptionSolutionStruct) {
    const dialogRef = this.dialog.open(MedicalPrescriptionSolutionModalComponent, {
      data: {
        solution: solution,
        episode: this.episode,
        listLocation: this.listLocation
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        this.search();
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  openPrescriptionNonDispensationModal(prescription: MedicalPrescriptionStruct) {
    const dialogRef = this.dialog.open(PrescriptionNonDispensationModalComponent, {
      data: {
        prescription: prescription,
        patientName: this.episode.patientName,
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        this.search();
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  toggleNotDispensedPrescriptions() {
    this.showNotDispensedPrescriptions = !this.showNotDispensedPrescriptions;
    if (this.showNotDispensedPrescriptions)
      this.listPrescriptionDisplay = this.listPrescription;
    else
      this.listPrescriptionDisplay = this.listPrescription.filter(presc => presc.idPrescriptionStatus != PrescriptionStatusEnum.NaoDispensado);
  }

  toggleNotDispensedMaterials() {
    this.showNotDispensedMaterials = !this.showNotDispensedMaterials;
    if (this.showNotDispensedMaterials)
      this.requestMaterialListDisplay = this.requestMaterialList;
    else
      this.requestMaterialListDisplay = this.requestMaterialList.filter(mat => mat.idStatus != RequestMaterialStatusEnum.notDispensed);
  }

  openMaterialModal(requestMaterial: RequestMaterialStruct) {
    const dialogRef = this.dialog.open(EpisodeMaterialModalComponent, {
      data: {
        requestMaterial: requestMaterial,
        episode: this.episode,
        listItems: this.listItems,
        listMedicines: this.listMedicines,
        listStorage: this.listStorage
      },
      panelClass: "episode-material-modal"
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result && result.save)
          this.search();
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  openMaterialNonDispensationModal(requestMaterial: RequestMaterialStruct) {
    const dialogRef = this.dialog.open(MaterialNonDispensationModalComponent, {
      data: {
        material: requestMaterial,
        patientName: this.episode.patientName,
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result.notDispensed)
          this.search();
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  populateItemList() {
    this.itemService.listAllItem().subscribe({
      next: (response) => {
        this.isLoading = false;
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        this.listItems = response.listItem;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateMedicineList() {
    this.medicineService.listAllForKit(null).subscribe({
      next: (response) => {
        this.isLoading = false;
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        this.listMedicines = response.list;
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateListStorage() {
    this.storageMaterialLookupService.getLookupMaterial().subscribe({
      next: (response) => {
        this.isLoading = false;
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        this.listStorage = response.storage;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  noneCheck(): void {
    if (this.selectionAll == true) {
      if (this.listPrescription) {
        this.listPrescription.forEach((c: MedicalPrescriptionStruct) => {
          if (c.isChecked == false)
            c.isChecked = true
        });
      }
      if (this.listSolutionPrescription) {
        this.listSolutionPrescription.forEach(x => {
          if (x.isChecked == false)
            x.isChecked = true
        });
      }
    }
    else {
      if (this.listPrescription) {
        this.listPrescription.forEach((c: MedicalPrescriptionStruct) => {
          if (c.isChecked == true)
            c.isChecked = false;
        });
      }
      if (this.listSolutionPrescription) {
        this.listSolutionPrescription.forEach(x => {
          if (x.isChecked == true)
            x.isChecked = false
        });
      }
    }
  }

  onChangePrescription(event: any) {
    if (event)
      this.listPrescriptionReportCheck = this.listPrescription.filter(x => x.idPrescriptionStatus == PrescriptionStatusEnum.Liberado && x.isChecked);
  }

  onChangePrescriptionSolution(event: any) {
    if (event)
      this.listSolutionPrescriptionDisplayCheck = this.listSolutionPrescription.filter(x => x.isChecked);
  }

  openModalReport() {
    this.isLoading = true;

    let viewMedicineRequest: ViewMedicineRequest = new ViewMedicineRequest();
    viewMedicineRequest.listIdMedicalPrescription = this.listPrescription.filter(x => x.isChecked == true).map(x => x.idMedicalPrescription);
    viewMedicineRequest.listIdMedicalPrescription.push(
      ...this.listSolutionPrescription
        .filter(x => x.isChecked)
        .flatMap(x => x.listPrescription.slice(0, 1).map(y => y.idMedicalPrescription))
    );

    if (viewMedicineRequest != null && viewMedicineRequest.listIdMedicalPrescription.length < 1) {
      this.alertService.show('Aviso', 'Nenhum medicamento selecionado', AlertType.warning);
      return
    }

    this.medicalPrescriptionService.getPresciptionReports(viewMedicineRequest).subscribe({
      next: (response) => {
        this.isLoading = false;
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        let reportDialog = this.dialog.open(ReportModalComponent, {
          data: {
            reportName: response.reportName,
            reportPdf64: response.reportPdf64,
          },
        });
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateLocationSelect() {
    this.locationService.readActive().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.listLocation = response.listLocation;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}