import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ScheduleLookupResponse } from '../../responses/schedule/schedule-lookup.response';

@Injectable({
  providedIn: 'root'
})
export class ScheduleLookupService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listLookup(): Observable<ScheduleLookupResponse>{

    let uri = `Lookup`;

    return this.httpClient.get<ScheduleLookupResponse>(environment.urlApiSchedule + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}