<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <a class="back-link" routerLink="/dashboard/data-source">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Fontes de Dados
        </a>
        <h1>Cadastrar Fonte de Dados</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome</mat-label>
                            <input matInput type="text" formControlName="dataSourceName">
                            <mat-error *ngIf="model.get('dataSourceName').invalid">Informe o nome</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Descrição</mat-label>
                            <input matInput type="text" formControlName="description">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Data de corte mínima</mat-label>
                            <mat-select formControlName="idCutOffDate" (selectionChange)="clearVariableResult()">
                                <mat-option *ngFor="let item of lookupResponse.listCutOffDate"
                                    [value]="item.idCutOffDate">
                                    {{item.cutOffDateName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <footer class="long-time-message" *ngIf="isLongTime">
                            Tempos maiores que uma hora podem causar lentidão no carregamento do dashboard
                        </footer>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Tipo de Fonte de Dados</mat-label>
                            <mat-select formControlName="idDataSourceType" (selectionChange)="userChangeType($event)">
                                <mat-option *ngFor="let item of lookupResponse.listDataSourceType"
                                    [value]="item.idDataSourceType">{{item.dataSourceTypeName}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>
                </div>
                <div class="row">
                    <ng-container *ngIf="this.filteredList && this.filteredList.length">
                        <app-column-rules [formGroup]="this.model" [formArrayName]="'expressions'"
                            [listLogicalExpression]="lookupResponse.listLogicalExpression" [listVariable]="filteredList"
                            [listConjunction]="lookupResponse.listConjunction" [arrayVariables]="arrayVariables">
                        </app-column-rules>
                    </ng-container>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <a mat-flat-button type="button" routerLink="/dashboard/data-source" color="accent"
                            class=" btn-block">
                            Cancelar
                        </a>
                    </div>
                    <div class="col-md-2">
                        <button color="accent" class=" btn-block" mat-flat-button type="button"
                            (click)="showVariableResult()">
                            <span *ngIf="isLoading == false">Visualizar Dados</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-12">
            <app-data-source-result-table [listDSVariable]="listDSVariable" [isLoading]="isLoading"
                [completeTableData]="completeTableData">
            </app-data-source-result-table>
        </div>
    </div>
</div>