import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CommonService } from "src/app/shared/services/common-service";
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';
import { catchError } from "rxjs/operators";
import { ListOccupiedBedResponse } from "../../responses/orchestrator-patient/list-occupied-bed.response";
import { ReturnStruct } from "src/app/shared/structs/return.struct";
import { LiberateBedRequest } from "../../requests/bed/liberate-bed.request";
import { GetOccupiedBedResponse } from "../../responses/orchestrator-patient/get-occupied-bed.response";
import { EpisodeReleaseRequest } from "../../requests/orchestrator-patient/episode-release.request";

  @Injectable({
    providedIn: 'root'
  })
  export class OccupiedBedService extends CommonService{
  
    constructor(private router: Router, private httpClient: HttpClient) {
      super();
    }

    public listOccupiedBed(searchText: string, birthDate: string, idSector: number): Observable<ListOccupiedBedResponse>{

      let uri = 'OccupiedBed?'
    
      if(searchText)
        uri = uri+ `searchText=${searchText}&`; 

      if(birthDate)
        uri = uri + `birthDate=${birthDate}&`

      if(idSector)
        uri = uri + `idSector=${idSector}&`
  
      return this.httpClient.get<ListOccupiedBedResponse>(environment.urlApiOrchestratorPatient + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
    }

    public getOccupiedBed(idEpisode: number, idBed: number): Observable<GetOccupiedBedResponse>{

      let uri = `OccupiedBed/idEpisode/${idEpisode}?`;

      if(idBed) uri += `idBed=${idBed}&`;
  
      return this.httpClient.get<GetOccupiedBedResponse>(environment.urlApiOrchestratorPatient + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
    }

    public liberateBed(liberateBedRequest: LiberateBedRequest): Observable<ReturnStruct>{

      let uri = 'OccupiedBed?'
  
      return this.httpClient.put<ReturnStruct>(environment.urlApiOrchestratorPatient + uri, liberateBedRequest,this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
    }

    public episodeAdministrativeRelease(episodeReleaseRequest: EpisodeReleaseRequest): Observable<ReturnStruct>{

      let uri = 'OccupiedBed?'
  
      return this.httpClient.post<ReturnStruct>(environment.urlApiOrchestratorPatient + uri, episodeReleaseRequest,this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
    }
  }