import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilsClassificationService } from 'src/app/classification/utils.service';
import { DiscriminatorTypeEnum } from 'src/app/shared/enum/discriminator-type.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { DiscriminatorModel } from 'src/app/shared/services/models/risk-classification/discriminator.model';
import { UtilsMeasurerService } from '../../protocol-v2/utils-measurer.service';
import { DeviceTypeSectorResponse } from 'src/app/shared/services/responses/risk-classification/device-type-sector.response';
import { Console } from 'console';
import { DiscriminadorSaturationAtmosphericAir } from 'src/app/shared/enum/risk-classification/discriminators.enum';

@Component({
  selector: 'app-saturation-modal',
  templateUrl: './saturation-modal.component.html',
  styleUrls: ['./saturation-modal.component.css']
})
export class SaturationModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<SaturationModalComponent>,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private utilService: UtilsClassificationService,
    private maskService: MaskService,
    public measurerService: UtilsMeasurerService,
  ) { }

  public model: UntypedFormGroup;
  public discriminator: DiscriminatorModel;
  public isAfterSubmit: boolean = false;
  public masks: Masks;
  public initValue: any = null;
  public discriminatorType: DiscriminatorTypeEnum = DiscriminatorTypeEnum.Saturacao;
  public autisticChecked: boolean = false;
  public setUppercaseText: boolean = false;
  public atmosphericAir = DiscriminadorSaturationAtmosphericAir.atmosphericAir;
  public therapyO2 = DiscriminadorSaturationAtmosphericAir.therapyO2;
  
  ngOnInit(): void {
    let deviceTypeSectorResponse: DeviceTypeSectorResponse = this.utilService.getDevicetypeBySector()
    if (deviceTypeSectorResponse && deviceTypeSectorResponse.deviceTypeSector) {
      if (deviceTypeSectorResponse.deviceTypeSector.setUppercaseText)
        this.setUppercaseText = deviceTypeSectorResponse.deviceTypeSector.setUppercaseText;
    }
    console.log(this.data.discriminatorsValues);
    this.measurerService.setModal(this.discriminatorType);
    this.masks = this.maskService.getMasks();
    this.discriminator = this.data.discriminator;
    this.initValue = this.data.discriminatorsValues[this.discriminatorType];
    this.model = this.formBuilder.group({
      saturation: [this.data.discriminatorsValues[this.discriminatorType], Validators.required],
      type: [this.data.description, Validators.required],
      priorityAutistic: [false],
      discriminatorSelectionMotive: []
    });
  }

  changePriorityAutistic(event: any) {
    this.model.get('saturation').setValue(null);
    this.model.get('type').setValue(null);
    this.autisticChecked = event.checked
    if (event.checked) {
      this.model.get('discriminatorSelectionMotive').setValidators([Validators.required]);
      this.model.get('saturation').disable();
      this.model.get('type').disable();
    } else {
      this.model.get('discriminatorSelectionMotive').setValidators(Validators.nullValidator);
      this.model.get('saturation').enable();
      this.model.get('type').enable();
    }
    this.model.get('discriminatorSelectionMotive').updateValueAndValidity();
  }

  close() {
    this.measurerService.setModal(null);
    this.matDialogRef.close();
  }

  submit() {
    if (this.data.discriminatorsValues[this.discriminatorType] && (this.initValue == this.model.get("saturation").value || (this.initValue != this.data.discriminatorsValues[this.discriminatorType] && !this.model.get("saturation").value))) {
      this.model.get("saturation").setValue(this.data.discriminatorsValues[this.discriminatorType]);
    }
    this.isAfterSubmit = true;
    if (this.model.invalid) {
      if (this.model.get('priorityAutistic').value) {
        this.alertService.show('Erro', "Preencha o motivo!", AlertType.error);
      } else {
        this.alertService.show('Erro', "Preencha o valor da saturação e selecione o tipo!", AlertType.error);
      }
      return;
    }

    if (this.model.get('priorityAutistic').value) {
      this.matDialogRef.close({ value: null, select: true, motive: this.model.get('discriminatorSelectionMotive').value });
    } else {
      let value = this.model.get("saturation").value.replace("_", "");
      if (value < 0 || value > 100) {
        this.alertService.show('Erro', "O valor da saturação deve ser de 0 a 100", AlertType.error);
        return;
      }
      let description = this.model.get("type").value.toLowerCase();
      let select = this.utilService.verifySelectSaturation(this.discriminator, value, description);
      this.measurerService.setModal(null);
      this.matDialogRef.close({ value, select, description });
    }
  }
}

