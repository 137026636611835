import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { AlertVariable } from 'src/app/shared/services/models/billing-by-health-unit/alert-variable.model';
import { ChannelType } from 'src/app/shared/services/models/billing-by-health-unit/channel-type.model';
import { TypeService } from 'src/app/shared/services/models/billing-by-health-unit/type-service.model';

@Component({
  selector: 'app-create-rule',
  templateUrl: './create-rule.component.html',
  styleUrls: ['./create-rule.component.css']
})
export class CreateRuleComponent implements OnInit {

  @Input() formGroup: FormGroup;
  @Input() formArrayName: string;
  @Input() arrayVariables: Array<any>
  @Input() listTypeService: TypeService[];
  @Input() listAlertVariable: AlertVariable[];
  @Input() listChannelType: ChannelType[];

  public masks: Masks;

  constructor(
    private formBuilder: FormBuilder,
    private maskService: MaskService,
  ) { }

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();  
  }

  createInput() {
    return this.formBuilder.group({
      idTypeService: [''],
      idBillingAlertRule: [null],
      idChannelType: [''],
      value: [''],
      idAlertVariable: [''],
    });
  }

  addNext() {
    this.arrayVariables.push(null);
    (this.formGroup.controls[this.formArrayName] as FormArray).push(this.createInput())
  }

  removeExpression(index: number) {
    this.arrayVariables.splice(index, 1);
    (this.formGroup.controls[this.formArrayName] as FormArray).removeAt(index)
  }
}