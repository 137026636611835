<form class="form" [formGroup]="searchModel">
    <div class="title-header">
        <div class="row">
            <div class="col-6 col-sm-6">
                <h1>Buscar por CPF</h1>
            </div>
        </div>
    </div>
    <span class="pin-intro">Informe aqui o CPF</span>
    <div class="row">
        <div class="col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>CPF</mat-label>
                <input matInput type="text" formControlName="cpf" [mask]="masks.cpf">
                <mat-error *ngIf="searchModel.get('cpf').invalid">Informe o CPF</mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-sm-3">
            <button mat-flat-button (click)="searchByCPF()" color="primary" class=" btn-block">
                <span *ngIf="isLoading == false">Buscar</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
        </div>
    </div>
</form>