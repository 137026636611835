import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { PatientHistoryDetailsComponent } from './pages/patient-history-details/patient-history-details.component';
import { PatientHistoryListComponent } from './pages/patient-history-list/patient-history-list.component';
import { PatientHistoryComponent } from './pages/patient-history/patient-history.component';
import { PatientListComponent } from './pages/patient-list/patient-list.component';
import { GrievanceNotificationComponent } from './pages/grievance-notification/grievance-notification.component';
import { GrievanceNotificationGenerateComponent } from './pages/grievance-notification-generate/grievance-notification-generate.component';

const routes: Routes = [
  { path: 'patient-history', component: PatientHistoryComponent, canActivate: [AuthGuard] },
  { path: 'patient-history/list', component: PatientHistoryListComponent, canActivate: [AuthGuard] },
  { path: 'patient-history/details', component: PatientHistoryDetailsComponent, canActivate: [AuthGuard] },
  { path: 'patient-history/patient-list', component: PatientListComponent, canActivate: [AuthGuard] },
  { path: 'patient-history/grievance-notification', component: GrievanceNotificationComponent, canActivate: [AuthGuard] },
  { path: 'patient-history/grievance-notification-generate', component: GrievanceNotificationGenerateComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PatientHistoryRoutingModule { }
