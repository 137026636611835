
import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { MedicineTypeRequest } from '../../requests/pharmacy/medicine-type.request';
import { PostRequestMaterialRequests, RequestMaterialRequests } from '../../requests/pharmacy/RequestMaterial.request';
import { GetMedicineTypeResponse } from '../../responses/pharmacy/get-medicine-type-response';
import { ListMedicineTypeResponse } from '../../responses/pharmacy/list-medicine-type-response';

@Injectable({
  providedIn: 'root'
})
export class RequestMaterialService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }


  public postNewMaterial(newMaterialRequest: RequestMaterialRequests): Observable<PostRequestMaterialRequests> {
    let uri = `RequestMaterial/`

    return this.httpClient.post<PostRequestMaterialRequests>(environment.urlApiPharmacy + uri, newMaterialRequest, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }


  public updateExistingMaterial(newMaterialRequest: RequestMaterialRequests): Observable<ReturnStruct> {
    let uri = `RequestMaterial/`

    return this.httpClient.put<ReturnStruct>(environment.urlApiPharmacy + uri, newMaterialRequest, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }


  public updateToDelete(idRequestMaterial: number): Observable<ReturnStruct> {
    let uri = `RequestMaterial/${idRequestMaterial}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiPharmacy + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

}