<span class="pin-intro">Digite seu CPF {{scheduled ? '' : 'ou CNS'}} para verificar se você tem cadastro!</span>
<div class="content">
    <form class="form" [formGroup]="model" (ngSubmit)="submit()">
        <div class="row">
            <div class="col-md-12">
                <mat-form-field appearance="outline">
                    <mat-label>CPF {{scheduled ? '' : 'ou CNS'}}</mat-label>
                    <input (keyup)="logModel()" #cpfInput matInput type="text" formControlName="cpfCns"
                        [mask]="scheduled ? cpfMask : cpfCnsMask">
                    <mat-error *ngIf="model.controls['cpfCns'].errors?.required">Informe o CPF {{scheduled ? '' : 'ou CNS'}}</mat-error>
                    <mat-error *ngIf="model.controls['cpfCns'].errors?.mask || model.controls['cpfCns'].errors?.invalidCPF">CPF {{scheduled ? '' : 'ou CNS'}} inválido</mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <button mat-flat-button type="submit" color="primary" class=" btn-block">
                    <span *ngIf="isLoading == false">Continuar com meu CPF {{scheduled ? '' : 'ou CNS'}}</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
                <button *ngIf="!scheduled" [disabled]="isLoading" mat-flat-button (click)="fowardWithoutCPF()"
                    color="accent" class=" btn-block">
                    <span *ngIf="isLoading == false">Seguir sem CPF {{scheduled ? '' : 'ou CNS'}}</span>
                </button>
                <button [disabled]="isLoading" mat-flat-button (click)="back()" color="accent" class=" btn-block">
                    <span *ngIf="isLoading == false">Voltar</span>
                </button>
            </div>
        </div>
    </form>
</div>