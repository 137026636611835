import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, retry, timeout } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { SectorRequest } from '../../requests/flow/sector.request';
import { GetSectorResponse } from '../../responses/flow/get-sector.response';
import { ListSectorResponse } from '../../responses/flow/list-sector.response';

@Injectable({
  providedIn: 'root'
})
export class SectorService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  public listSector(searchText: string, isActive: boolean): Observable<ListSectorResponse> {
    let uri = 'Sector?'

    if (searchText != null)
      uri = uri + `searchText=${searchText}&`;

    if (isActive != null)
      uri = uri + `isActive=${isActive}&`

    return this.httpClient.get<ListSectorResponse>(environment.urlApiFlow + uri, this.addHeaderToken())
      .pipe(timeout(environment.requestTimeOut), retry(environment.requestRetry), catchError(this.handleError));
  }

  public listSectorByIdService(listIdService?: number[]): Observable<ListSectorResponse> {
    let uri = 'Sector/getByListIdService?'

    if (listIdService)
      uri = uri + `jsonListIdService=${JSON.stringify(listIdService)}&`;

    return this.httpClient.get<ListSectorResponse>(environment.urlApiFlow + uri, this.addHeaderToken())
      .pipe(timeout(environment.requestTimeOut), retry(environment.requestRetry), catchError(this.handleError));
  }

  public getSector(idSector: number): Observable<GetSectorResponse> {
    let uri = `Sector/idSector/${idSector}`;

    return this.httpClient.get<GetSectorResponse>(environment.urlApiFlow + uri, this.addHeaderToken())
      .pipe(timeout(environment.requestTimeOut), retry(environment.requestRetry), catchError(this.handleError));
  }

  public createSector(body: SectorRequest): Observable<ReturnStruct> {
    let uri = `Sector`

    return this.httpClient.post<ReturnStruct>(environment.urlApiFlow + uri, body, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public updateSector(idSector: number, body: SectorRequest): Observable<ReturnStruct> {
    let uri = `Sector/idSector/${idSector}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiFlow + uri, body, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public deleteSector(idSector: number, ip: string): Observable<ReturnStruct> {
    let uri = `Sector/idSector/${idSector}/ip/${ip}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiFlow + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public listAllSector(): Observable<ListSectorResponse> {
    let uri = `Sector/getAll`

    return this.httpClient.get<ListSectorResponse>(environment.urlApiFlow + uri, this.addHeaderToken())
      .pipe(timeout(environment.requestTimeOut), retry(environment.requestRetry), catchError(this.handleError));
  }
}