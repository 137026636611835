import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { AttendRequest } from '../../requests/sync-measurer/attend.request';
import { DeviceOnlineResponse } from '../../responses/sync-measurer/device-online.response';
import { HistoryMeasurerResponse } from '../../responses/sync-measurer/history-measurer.response';
import { ListCareLineResponse } from '../../responses/sync-measurer/list-care-line.response';

@Injectable({
  providedIn: 'root'
})
export class HistoryMeasurerService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listHistoryMeasurer(idPatient: number): Observable<HistoryMeasurerResponse> {

    let uri = `Attend/idPatient/${idPatient}`

    return this.httpClient.get<HistoryMeasurerResponse>(environment.urlApiSyncMeasurer + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public saveAlertAttend(body: AttendRequest): Observable<ReturnStruct> {

    let uri = `Attend`

    return this.httpClient.post<ReturnStruct>(environment.urlApiSyncMeasurer + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}