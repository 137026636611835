import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { GetAuditInfoResponse } from '../../responses/orchestrator-audit/get-audit-info.response';

@Injectable({
  providedIn: 'root'
})
export class ExecuteAuditService extends CommonService{

  constructor(private httpClient: HttpClient) {
    super();
  }

  public getAuditInfo(idAudit: number): Observable<GetAuditInfoResponse> {
    let uri = `ExecuteAudit/idAudit/${idAudit}`
      
    return this.httpClient.get<GetAuditInfoResponse>(environment.urlApiOrchestratorAudit + uri, this.addHeaderToken())
    .pipe(catchError(this.handleError));
  }
}