import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ItemService } from 'src/app/shared/services/API/pharmacy/Item.service';
import { ItemModel } from 'src/app/shared/services/models/pharmacy/item.model';
import { ItemDeleteModalComponent } from './item-delete-modal/item-delete-modal.component';

@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.css']
})
export class ItemListComponent implements OnInit {

  constructor(private itemService: ItemService,
    private alertService: AlertService,
    public dialog: MatDialog
  ) { }
    
  public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.pharmacy;
  public menuFunctionalityEnum:MenuFunctionalityEnum = MenuFunctionalityEnum.pharmacy_item;
    
  public listItem: ItemModel[];
  public isLoading: boolean;
  public isActive: boolean;
  public searchText: string;

  ngOnInit(): void {
    this.isLoading = true;
    this.search();
  }

  search(){
    this.isLoading = true;  
    if(this.searchText == "")
    this.searchText = null;
    
    this.itemService.listItem(this.searchText).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.listItem = response.listItem;  
    
      this.isLoading = false;    
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  private timeoutKeySearch: any = null;
  
  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }
  
  openModal(idItem){
    const dialogRef = this.dialog.open(ItemDeleteModalComponent, {
      data: {
        idItem: idItem
      },
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.deleteItem){
        this.search();
      }
    });
  }
}
