<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <h1>Pacientes Remotos</h1>
        <div class="white-body">
            <div class="filters">
                <div class="row">
                    <div class="col-12 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Pesquisar</mat-label>
                            <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText"
                                (keyup)="onKeySearch($event)">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Gênero</mat-label>
                            <mat-select [(ngModel)]="listIdGender" (selectionChange)="search()" multiple>
                                <mat-option *ngFor="let item of listGender"
                                    value="{{item.idGender}}">{{item.genderName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Linhas de Cuidado</mat-label>
                            <mat-select [(ngModel)]="listIdCareLine" (selectionChange)="search()" multiple>
                                <mat-option *ngFor="let item of listCareLine" value="{{item.idCareLine}}">
                                    {{item.careLineName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Tipo</mat-label>
                            <mat-select [(ngModel)]="listIdCareLineType" (selectionChange)="search()" multiple>
                                <mat-option *ngFor="let item of listCareLineType" value="{{item.idCareLineType}}">
                                    {{item.careLineTypeName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-sm-4">
                    <div class="title-header">
                        <h1>Pacientes em alerta Vermelho</h1>
                    </div>
                    <div class="card-list" *ngIf="listRedPatientDeviceStructs != null">
                        <div class="row">
                            <div class="col-12" *ngFor="let item of listRedPatientDeviceStructs">
                                <div class="card-item">
                                    <div class="infos">
                                        <span class="primary">{{item.patientSocialName? item.patientSocialName :
                                            item.patientName}}</span>
                                        <span class="secundary">{{item.patientAge}}</span>
                                        <span class="secundary">{{item.genderName}}</span>
                                        <span class="secundary">{{item.careLineNames}}</span>
                                        <span class="secundary">{{item.careLineTypeNames}}</span>
                                        <span class="secundary">Matrícula: {{item.registryEmployee}}</span>
                                        <span class="secundary">CPF: {{item.cpfFormated}}</span>
                                    </div>
                                    <div class="actions">
                                        <a>
                                            <mat-icon aria-hidden="false" aria-label="Editar"
                                                [routerLink]="['/remote-monitoring/patient', {idPatient: item.idPatient}]">
                                                visibility</mat-icon>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <app-empty-list
                        *ngIf="listRedPatientDeviceStructs != undefined && listRedPatientDeviceStructs.length == 0">
                    </app-empty-list>
                </div>
                <div class="col-12 col-sm-4">
                    <div class="title-header">
                        <h1>Pacientes em alerta Amarelo</h1>
                    </div>
                    <div class="card-list" *ngIf="listYellowPatientDeviceStructs != null">
                        <div class="row">
                            <div class="col-12" *ngFor="let item of listYellowPatientDeviceStructs">
                                <div class="card-item">
                                    <div class="infos">
                                        <span class="primary">{{item.patientSocialName? item.patientSocialName :
                                            item.patientName}}</span>
                                        <span class="secundary">{{item.patientAge}}</span>
                                        <span class="secundary">{{item.genderName}}</span>
                                        <span class="secundary">{{item.careLineNames}}</span>
                                        <span class="secundary">{{item.careLineTypeNames}}</span>
                                        <span class="secundary">Matrícula: {{item.registryEmployee}}</span>
                                        <span class="secundary">CPF: {{item.cpfFormated}}</span>
                                    </div>
                                    <div class="actions">
                                        <a>
                                            <mat-icon aria-hidden="false" aria-label="Editar"
                                                [routerLink]="['/remote-monitoring/patient', {idPatient: item.idPatient}]">
                                                visibility</mat-icon>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <app-empty-list
                        *ngIf="listYellowPatientDeviceStructs != undefined && listYellowPatientDeviceStructs.length == 0">
                    </app-empty-list>
                </div>
                <div class="col-12 col-sm-4">
                    <div class="title-header">
                        <h1>Pessoas sem alertas</h1>
                    </div>
                    <div class="card-list" *ngIf="listPatientDeviceStructs != null">
                        <div class="row">
                            <div class="col-6" *ngFor="let item of listPatientDeviceStructs">
                                <div class="card-item">
                                    <div class="infos">
                                        <span class="primary">{{item.patientSocialName? item.patientSocialName :
                                            item.patientName}}</span>
                                        <span class="secundary">{{item.patientAge}}</span>
                                        <span class="secundary">{{item.genderName}}</span>
                                        <span class="secundary">{{item.careLineNames}}</span>
                                        <span class="secundary">{{item.careLineTypeNames}}</span>
                                        <span class="secundary">Matrícula: {{item.registryEmployee}}</span>
                                        <span class="secundary">CPF: {{item.cpfFormated}}</span>
                                    </div>
                                    <div class="actions">
                                        <a>
                                            <mat-icon aria-hidden="false" aria-label="Editar"
                                                [routerLink]="['/remote-monitoring/patient', {idPatient: item.idPatient}]">
                                                visibility</mat-icon>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <app-empty-list
                        *ngIf="listPatientDeviceStructs != undefined && listPatientDeviceStructs.length == 0">
                    </app-empty-list>
                </div>
                <app-loading-list *ngIf="isLoading"></app-loading-list>
            </div>
        </div>
    </div>
</div>