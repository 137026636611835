import { MultiProfessionalCareSadt } from "../../models/multi-professional/multi-professional-care-sadt.model";

export class SadtGroupStruct {

    public placeName: string;
    public statusName: string;
    public observation: string;
    public observationMedic: string;
    public typeSadtName: string;
    public sadtResult: string;
    public sadtDescription: string;
    public sadtScheduleName: string;

    public idStatus: number;
    public idEpisode: number;
    public idUser: number;
    public idSadtGroup: number;
    public idMultiProfessionalCare: number;
    public idPlace: number;
    public idTypeSadt: number;
    public idSadtSchedule: number;
    
    public datetimeStartAttendance: Date;

    public listMultiProfessionalCareSadt: MultiProfessionalCareSadt[] = [];
}