<div mat-dialog-content>
    <div class="mat-dialog-content">
        <h2>Solicitações médicas</h2>
        <hr>
        <div class="align-sub-title">
            <h3>Origem do Paciente: </h3><span class="spacing-sub-title">{{this.data.lastQueueName}}</span>
        </div>
        <div class="align-sub-title">
            <h3>Leito: </h3><span class="spacing-sub-title">{{this.data.bedName}}</span>
        </div>
        <div *ngIf="this.data.listMedicalCareSadt != null">
            <div *ngFor="let item of this.data.listMedicalCareSadt">
                <div class="container-infos">
                    <span class="text-bold">{{item.typeSadtName}}:</span>
                    <ul>
                        <li *ngFor="let careSadt of item.medicalCareSadt">
                            <span class="text-regular">{{careSadt.sadtName}}</span>
                        </li>
                    </ul>
                    <h3>Horário: </h3><span class="spacing-sub-title">{{item.sadtScheduleName}}</span>
                </div>
            </div>
        </div>
    </div>
    <button mat-flat-button color="primary" class="btn-block" (click)="clickOk()">
        <span *ngIf="isLoading == false">Ok</span>
        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
    </button>
</div>