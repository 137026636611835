<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum' [hasSecundaryHeader]="idEpisode !== null" [episode]="idEpisode" [hasCachedModule]="true" [cachedModuleName]="'frontdesk'"></app-menu>
    <div *ngIf="isLoading" class="container-body-secundary-header" >
        <app-loading-list ></app-loading-list>
    </div>
    
    
    <app-patient-register *ngIf="hasSiga == false" [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum' [episode]="idEpisode" [cachedModuleName]="'frontdesk'" [showTissGuideButton]="showTissGuideButton"></app-patient-register>
    <app-siga-patient-register *ngIf="hasSiga == true" [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum' [episode]="idEpisode" [cachedModuleName]="'frontdesk'" [ticket]="ticket"></app-siga-patient-register>
    
</div>