<div [formGroup]="auditUserSelectStruct.formGroup">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-4">
            <span class="audit-select-user-name">{{auditUserSelectStruct.userName}}</span>
        </div>
        <div class="col-12 col-sm-12 col-md-3">
            <span>Classificações: <strong>{{auditUserSelectStruct.classificationAmount}}</strong></span>
        </div>
        <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field appearance="outline">
                <mat-label>Quantidade</mat-label>
                <input matInput type="number" formControlName="sampleAmount" min="0" step="1">
                <mat-error *ngIf="!auditUserSelectStruct.formGroup.get('sampleAmount').value 
                    || auditUserSelectStruct.formGroup.get('sampleAmount').value == 0">Informe a Quantidade
                </mat-error>
                <mat-error *ngIf="auditUserSelectStruct.formGroup.get('sampleAmount').value 
                    && auditUserSelectStruct.classificationAmount > 10 
                    && auditUserSelectStruct.formGroup.get('sampleAmount').value < 10
                    && auditUserSelectStruct.classificationNotAuditedAmount > 10">A quantidade deve ser no mínimo
                    10
                </mat-error>
                <mat-error
                    *ngIf="auditUserSelectStruct.formGroup.get('sampleAmount').value 
                    && auditUserSelectStruct.formGroup.get('sampleAmount').value > auditUserSelectStruct.classificationAmount">
                    A quantidade não pode ser maior que o total
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-6 col-sm-6 col-md-2">
            <div class="form-field-toggle">
                <mat-slide-toggle formControlName="isFixedSampleSize" (change)="changeFixedSampleSize($event.checked)">
                    2% do Total</mat-slide-toggle>
            </div>
        </div>
        <div class="col-6 col-sm-6 col-md-2">
            <div class="form-field-toggle">
                <mat-slide-toggle formControlName="isAudited" (change)="changeAudit($event.checked)">Auditar
                </mat-slide-toggle>
            </div>
        </div>
    </div>
</div>