<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <h1>Atendimentos</h1>
        <div class="white-body">
            <a mat-flat-button color="primary" class=" add-user" routerLink="/form/form-episode-list/register"><mat-icon
                    aria-hidden="false" aria-label="Plus">add</mat-icon> Cadastrar Atendimento</a>
            <div class="filters">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Paciente</mat-label>
                            <input matInput type="text" id="patientSearchText" name="patientSearchText"
                                [(ngModel)]="patientSearchText" (keyup)="onKeySearch($event)">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Protocolo</mat-label>
                            <input matInput type="text" id="protocolSearchText" name="protocolSearchText"
                                [(ngModel)]="protocolSearchText" (keyup)="onKeySearch($event)">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Data de Nascimento</mat-label>
                            <input matInput [(ngModel)]="birthDate" [matDatepicker]="birthDatePicker"
                                (dateChange)="search(0)">
                            <mat-datepicker-toggle matSuffix [for]="birthDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #birthDatePicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Período do Atendimento</mat-label>
                            <mat-date-range-input [rangePicker]="picker">
                                <input matStartDate [(ngModel)]="startOfAttendance" placeholder="Início">
                                <input matEndDate [(ngModel)]="endOfAttendance" placeholder="Fim"
                                    (dateChange)="search(0)">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker></mat-date-range-picker>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <button mat-flat-button color="primary" class=" btn-block" (click)="buttonSearch()">
                            <span *ngIf="!isLoading">Pesquisar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-list" *ngIf="listFormEpisode != null">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listFormEpisode">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary"> {{item.formEpisode.idEpisode}}</span>
                                <span class="secundary">{{item.patientName}}</span>
                                <span class="secundary">{{item.protocolName}}</span>
                                <span class="secundary-status last-info">{{item.formEpisode.datetimeStartAttendance |
                                    date:'dd/MM/yyyy HH:mm:ss'}}</span>
                            </div>
                            <div class="actions">
                                <a><mat-icon aria-hidden="false" aria-label="Editar"
                                        [routerLink]="['/form/form-episode-list/register', {idEpisode: item.formEpisode.idEpisode}]">edit</mat-icon>
                                </a>
                                <a><mat-icon aria-hidden="false" aria-label="Editar"
                                        (click)="openModal(item.formEpisode.idEpisode)">delete_forever</mat-icon> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-paginator [length]="this.fullListSize" #paginator [pageIndex]="0" [pageSize]="30"
                        aria-label="Select page" (page)="changePage($event)" [hidePageSize]="true">
                    </mat-paginator>
                </div>
            </div>

            <app-empty-list *ngIf="listFormEpisode != undefined && listFormEpisode.length == 0"></app-empty-list>
        </div>

        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>
</div>