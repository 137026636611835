import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MedicineGroupService } from 'src/app/shared/services/API/pharmacy/medicine-group.service';
import { MedicineTypeService } from 'src/app/shared/services/API/pharmacy/medicine-type.service';
import { MedicineGroupRequest } from 'src/app/shared/services/requests/pharmacy/medicine-group.request';
import { MedicineTypeStruct } from 'src/app/shared/services/structs/pharmacy/medicine-type.struct';

@Component({
  selector: 'app-medicine-group-register',
  templateUrl: './medicine-group-register.component.html',
  styleUrls: ['./medicine-group-register.component.css']
})
export class MedicineGroupRegisterComponent implements OnInit {

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private medicineGroupService: MedicineGroupService,
    private medicineTypeService: MedicineTypeService,
    ) { }
  
  public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.pharmacy;
  public menuFunctionalityEnum:MenuFunctionalityEnum = MenuFunctionalityEnum.pharmacy_medicine_group;
  
  public model: UntypedFormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;

  public idMedicineGroup: number;
  public listMedicineType: MedicineTypeStruct[];
  public medicineGroupRequest: MedicineGroupRequest;

  
  ngOnInit(): void {
    this.isLoading = true;

    this.model = this.formBuilder.group({
      name: ['', [Validators.required]],
      description: [''],
      idMedicineType: ['', [Validators.required]],
      canUseOnKit: [false],
    });

    this.medicineGroupRequest = new MedicineGroupRequest();

    this.populateMedicineTypeSelect();

    this.idMedicineGroup == null;
    this.isUpdate == false;

    if(this.activatedRoute.snapshot.paramMap.get('idMedicineGroup'))
      this.idMedicineGroup = parseInt(this.activatedRoute.snapshot.paramMap.get('idMedicineGroup'));
      
      if(this.idMedicineGroup != null){
        this.isFirstLoading = true;
        this.isUpdate = true;
        this.populateMedicineGroupData();
      }
  }

  submit(){
    if(this.isLoading){
      return;
    }

    if(!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }
    this.isLoading = true;   
    
    this.medicineGroupRequest.medicineGroupDescription = this.model.get('description').value;
    this.medicineGroupRequest.idMedicineType = parseInt(this.model.get('idMedicineType').value);
    this.medicineGroupRequest.medicineGroupName = this.model.get('name').value;
    this.medicineGroupRequest.canUseOnKit = this.model.get('canUseOnKit').value;
    
    if(this.isUpdate) {
      this.updateMedicineGroup();
    }
    else {
      this.createMedicineGroup();
    }
  }

  populateMedicineGroupData(){
    this.medicineGroupService.getMedicineGroup(this.idMedicineGroup).subscribe((response)=>{
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.model.get('name').setValue(response.medicineGroup.medicineGroupName);
      this.model.get('idMedicineType').setValue(response.medicineGroup.idMedicineType.toString());
      this.model.get('description').setValue(response.medicineGroup.medicineGroupDescription);
      this.model.get('canUseOnKit').setValue(response.medicineGroup.canUseOnKit != null ? response.medicineGroup.canUseOnKit : false);
      
      this.isLoading = false;    
      this.isFirstLoading = false;
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  populateMedicineTypeSelect(){
    this.medicineTypeService.listAllMedicineType().subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      
      this.listMedicineType = response.listMedicineType;
       
      this.isLoading = false   
      
    },
    (error)=>{
      console.log(error)    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }
  updateMedicineGroup(){
    this.medicineGroupService.updateMedicineGroup(this.idMedicineGroup, this.medicineGroupRequest).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false; 
      
      this.router.navigate(['/pharmacy/medicine-group']);
      
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }
  
  createMedicineGroup(){
    this.medicineGroupService.createMedicineGroup(this.medicineGroupRequest).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;    
      
      this.router.navigate(['/pharmacy/medicine-group']);
      
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }
}
