<div class="container-generic">

    <app-menu [isClassification]="true" [menuModuleEnum]='menuModuleEnum'
        [menuFunctionalityEnum]='menuFunctionalityEnum' [hasSecundaryHeader]="true" [episode]="idEpisode"
        [discriminators]="discriminatorValues" [hasCachedModule]="true" [cachedModuleName]="'classification'"
        [painColorCode]="painColorCode" [gravityColorCode]="data.priority.coloerHex"
        [gravity]="data.priority.priorityColorName" (restartMeasurers)="restartMeasurers()"></app-menu>

    <div [ngClass]="{'container-body-secundary-header': idEpisode !== null, 'container-body': idEpisode === null}">
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <h1 class="border-green-left-title">Resumo</h1>
            <app-triage-resume [protocolName]="protocolName" [pain]="pain" [painType]="painType" [allergy]="allergies"
                [specialNecessityName]="specialNecessityName" [triage]="triage"></app-triage-resume>
            <app-pre-register-form-default *ngIf="!this.data.isUnidentifiedPatient" #preRegister [isPreRegister]="false"
                [edited]="edited" [idEpisode]="this.idEpisode" [idRoom]="this.data.idRoom"
                [idDeviceType]="this.data.idDeviceType" [idSector]="this.data.idSector"
                [idDeviceType]="this.data.idDeviceType" [classificationData]="this.data"
                (emitEdition)="changeEdited($event)">
            </app-pre-register-form-default>
            <div class="button-container row">
                <div class="col-6 col-md-4">
                    <button *ngIf="showBtnBack" mat-flat-button type="button" color="accent" class=" btn-block"
                        (click)="goBack()">
                        <span *ngIf="isLoading == false">Voltar</span>
                    </button>
                </div>
                <div class="col-6 col-md-4">
                    <button mat-flat-button (click)="submit(true)" color="{{requireSignature ? 'primary' : 'accent'}}"
                        class=" btn-block">
                        <span *ngIf="isLoading == false">Salvar com assinatura</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
                <div *ngIf="!requireSignature" class="col-6 col-md-4">
                    <button mat-flat-button (click)="submit(false)" color="{{requireSignature ? 'accent' : 'primary'}}"
                        class=" btn-block">
                        <span *ngIf="isLoading == false">Salvar sem assinatura</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
            </div>
        </div>
    </div>

</div>