import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CommonService } from "src/app/shared/services/common-service";
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';
import { catchError } from "rxjs/operators";
import { MedicalRecordResponse } from "../../responses/orchestrator-patient/medical-record.response";
import { MedicalProcedureResponseModel } from "../../models/medical-record/medical-procedure-response.model";
import { ReturnStruct } from "src/app/shared/structs/return.struct";

@Injectable({
  providedIn: 'root'
})
export class MedicalRecordService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getMedicalRecord(idEpisode: number): Observable<MedicalRecordResponse> {
    let uri = `MedicalRecord/idEpisode/${idEpisode}`

    return this.httpClient.get<MedicalRecordResponse>(environment.urlApiMedicalRecord + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }  
  
  public postMedicalProcedure(medicalProcedureRequest: MedicalProcedureResponseModel): Observable<ReturnStruct>{
    let uri = `MedicalProcedure`

    return this.httpClient.post<ReturnStruct>(environment.urlApiMedicalRecord + uri, medicalProcedureRequest, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }  
  
  public putMedicalProcedure(medicalProcedureRequest: MedicalProcedureResponseModel, idMedicalProcedure: number): Observable<ReturnStruct> {
    let uri = `MedicalProcedure/idMedicalProcedure/${idMedicalProcedure}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiMedicalRecord + uri, medicalProcedureRequest, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
  
  public deleteMedicalProcedure(idMedicalProcedure: number): Observable<ReturnStruct> {
    let uri = `MedicalProcedure/idMedicalProcedure/${idMedicalProcedure}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiMedicalRecord + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}