import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-self-triage-resume-modal',
  templateUrl: './self-triage-resume-modal.component.html',
  styleUrls: ['./self-triage-resume-modal.component.css']
})
export class SelfTriageResumeModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) private data: any, public dialog: MatDialog, public matDialogRef: MatDialogRef<SelfTriageResumeModalComponent>) { }
  public selfTriage: any = {}

  ngOnInit(): void {
    this.selfTriage = this.data;
  }

  close() {
    this.matDialogRef.close();
  }
}
