import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MedicalSpecialtyService } from 'src/app/shared/services/API/digital-prompt/medical-specialty.service';
import { MedicalSpecialtyDeleteModalComponent } from './medical-specialty-delete-modal/medical-specialty-delete-modal.component';
import { UserService } from 'src/app/shared/services/API/user/user.service';
import { MedicalRecordLookupService } from 'src/app/shared/services/API/medical-record/lookup.service';
import { ListUserStruct } from 'src/app/shared/services/structs/user/list-user.struct';
import { NatureOfAttendance } from 'src/app/shared/services/models/medical-record/nature-of-attendance';
import { MedicalSpecialtyStruct } from 'src/app/shared/services/structs/digital-prompt-service/medical-specialty.struct';
import { MedicalSpecialtyCompletedStruct } from 'src/app/shared/structs/digital-prompt-service/medical-specialty-completed.struct';
import { UserGetNameService } from 'src/app/shared/services/API/user/user-get-name.service';

@Component({
  selector: 'app-medical-specialty-list',
  templateUrl: './medical-specialty-list.component.html',
  styleUrls: ['./medical-specialty-list.component.css']
})
export class MedicalSpecialtyListComponent implements OnInit {

  constructor(
    private medicalSpecialtyService: MedicalSpecialtyService,
    private alertService: AlertService,
    private userGetNameService: UserGetNameService,
    private lookupMedicalRecordService: MedicalRecordLookupService,
    public dialog: MatDialog) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.digital_prompt_service;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.medical_specialty;

  public listMedicalSpecialty: MedicalSpecialtyStruct[] = [];
  public listMedicalSpecialtyCompleted: MedicalSpecialtyCompletedStruct[] = [];
  public isLoading: boolean;
  public searchText: string;
  public listUser: ListUserStruct[] = [];
  public listNatureOfAttendance: NatureOfAttendance[] = [];

  ngOnInit(): void {
    this.isLoading = true;
    this.getNatureOfAttendance();      
  }

  searchMedicalSpecialty() {
    this.isLoading = true;
    this.listMedicalSpecialty = [];
    if (this.searchText == "")
      this.searchText = null;

    this.medicalSpecialtyService.listMedicalSpecialty(this.searchText).subscribe({
      next: (response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listMedicalSpecialty = response.listMedicalSpecialty;
      this.mapToCompletedStruct();
      this.isLoading = false;
    },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.searchMedicalSpecialty();
      }
    }, 1000);
  }

  openModal(idMedicalSpecialty) {
    const dialogRef = this.dialog.open(MedicalSpecialtyDeleteModalComponent, {
      data: {
        idMedicalSpecialty: idMedicalSpecialty
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.deleteMedicalSpecialty) {
        this.searchMedicalSpecialty();
      }
    });
  }

  getNatureOfAttendance() {
    this.isLoading = true;
    this.lookupMedicalRecordService.listNatureOfAttendance().subscribe({
      next: (response) => {
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listNatureOfAttendance = response.listNatureOfAttendance;
        this.getMedicalUsers();
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getMedicalUsers() {
    this.isLoading = true;
    this.userGetNameService.listUserSelect(true, null, '[1]').subscribe({
      next: (response) => {
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        this.listUser = response.listUser;
        this.searchMedicalSpecialty();
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  mapToCompletedStruct() {
    this.listMedicalSpecialtyCompleted = [];
    this.listMedicalSpecialty.forEach((item) => {
      let medicalSpecialtyCompleted = new MedicalSpecialtyCompletedStruct();
      medicalSpecialtyCompleted.medicalSpecialty = item.medicalSpecialty;
      medicalSpecialtyCompleted.listUser = this.listUser.filter((item1) => item.listUser.some(item2 => item1.idUser == item2));
      medicalSpecialtyCompleted.listNatureOfAttendance = this.listNatureOfAttendance.filter((item1) => item.listNatureOfAttendance.some(item2 => item1.idNatureOfAttendance == item2));
      
      this.listMedicalSpecialtyCompleted.push(medicalSpecialtyCompleted);
    });
  }

  getFormatedListNatureOfAttendance(listName: NatureOfAttendance[]){
    return listName.map(nameStruct => nameStruct.natureOfAttendanceName).join(', ');
  }

  getFormatedListUserName(listName: ListUserStruct[]){
    return listName.map(nameStruct => nameStruct.userName).join(', ');
  }
}
