import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { RoomService } from 'src/app/shared/services/API/flow/room.service';
import { SectorService } from 'src/app/shared/services/API/flow/sector.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { SectorStruct } from 'src/app/shared/services/structs/flow/sector.struct';
import { RoomRequest } from '../../../shared/services/requests/flow/room.request';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { RoomSetupModalComponent } from './room-setup-modal/room-setup-modal.component';
import { UtilService } from 'src/app/shared/services/util.service';
import { UserRoleEnum } from 'src/app/shared/enum/user-role.enum';
import { SpecialityAttendance } from 'src/app/shared/services/models/flow/speciality-attendance.model';

@Component({
  selector: 'app-room-register',
  templateUrl: './room-register.component.html',
  styleUrls: ['./room-register.component.css']
})
export class RoomRegisterComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private roomService: RoomService,
    private utilService: UtilService,
    private sectorService: SectorService,
    public dialog: MatDialog) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.master;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.master_room;

  public model: FormGroup;
  public masks: Masks;

  public isLoading: boolean = false;
  public isFirstLoading: boolean;
  public isUpdate: boolean = false;

  public actualServiceModule: number;
  public idRoom: number = null;

  public listSpecialityAttendance: SpecialityAttendance[];
  public listSector: SectorStruct[];
  public roomRequest: RoomRequest = new RoomRequest();
  ip: string = '192.168.0.2';

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      name: ['', [Validators.required]],
      status: ['', [Validators.required]],
      description: [''],
      idSector: ['', [Validators.required]],
      isEmergency: [false, Validators.required],
      idSpecialityAttendance: [{ value: '', disabled: true }]
    });

    this.masks = this.maskService.getMasks();

    this.populateSectorSelect();

    if (this.activatedRoute.snapshot.paramMap.get('idRoom'))
      this.idRoom = parseInt(this.activatedRoute.snapshot.paramMap.get('idRoom'));

    if (this.idRoom != null) {
      this.isFirstLoading = true;
      this.isUpdate = true;
      this.populateRoomData();
    }

    setTimeout(() => { this.getIP(); }, 2000);
  }

  getIP() {
    this.utilService.getIP().subscribe({
      next: (response) => {
        if (response && response.ip) {
          this.ip = response.ip;
        }
      }
    });
  }

  submit() {
    if (this.isLoading)
      return;

    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    this.isLoading = true;

    this.roomRequest.description = this.model.get('description').value;
    this.roomRequest.idSector = parseInt(this.model.get('idSector').value);
    this.roomRequest.isActive = this.model.get('status').value == "true" ? true : false;
    this.roomRequest.roomName = this.model.get('name').value;
    this.roomRequest.isEmergency = this.model.get('isEmergency').value;
    this.roomRequest.ip = this.ip;

    if (this.isUpdate)
      this.updateRoom();
    else
      this.createRoom();
  }

  populateRoomData() {
    this.roomService.getRoom(this.idRoom).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.model.get('name').setValue(response.roomStruct.roomName);
        this.model.get('status').setValue(response.roomStruct.isActive.toString());
        this.model.get('idSector').setValue(response.roomStruct.idSector.toString());
        this.model.get('description').setValue(response.roomStruct.description);
        this.model.get('isEmergency').setValue(response.roomStruct.isEmergency);
        if (response.roomStruct.idSpecialityAttendance)
          this.model.get('idSpecialityAttendance').setValue(response.roomStruct.idSpecialityAttendance);

        this.isLoading = false;
        this.isFirstLoading = false;
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  populateSectorSelect() {
    this.sectorService.listAllSector().subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.listSector = response.listSectorStruct;
        this.listSpecialityAttendance = response.listSpecialityAttendance;
        this.isLoading = false;
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  onSector() {
    let idSpecialityAttendance = this.listSector.find(x => x.idSector == this.model.get('idSector').value)?.idSpecialityAttendance || null;

    if (idSpecialityAttendance !== null)
      this.model.get('idSpecialityAttendance').setValue(idSpecialityAttendance);
    else
      this.model.get('idSpecialityAttendance').setValue(null);
  }

  updateRoom() {
    this.roomService.updateRoom(this.idRoom, this.roomRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }
        else
          this.alertSave(this.idRoom);
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  createRoom() {
    this.roomService.createRoom(this.roomRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }
        else
          this.alertSave(this.idRoom);
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  private openRoomSetupModal(idRoom: number) {
    const dialogRef = this.dialog.open(RoomSetupModalComponent, {
      panelClass: 'border-radius-box'
    });

    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result) {
          let listRole = this.utilService.getRoles();
          if (listRole.some(x => x.idRole === UserRoleEnum.config_sector_visualizar || x.idRole === UserRoleEnum.config_sector_alterar))
            this.router.navigate(['/classification/config-sector-protocol', { idRoom: idRoom }]);
          else
            this.alertService.show('Atenção!', "Usuário sem permissão para configurar Classificação de Risco.", AlertType.warning);
        }
        else
          this.router.navigate(['/master/room']);
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  private alertSave(idRoom: number) {
    this.alertService.show('Sucesso', 'Informações salvas com sucesso', AlertType.success);
    this.isLoading = false;

    if (this.listSector && this.roomRequest) {
      const filteredSectors = this.listSector.filter(sector => sector.idModule === MenuModuleEnum.classification && sector.isActive);

      if (filteredSectors.some(sector => sector.idSector === this.roomRequest.idSector))
        this.openRoomSetupModal(idRoom);
      else
        this.router.navigate(['/master/room']);
    }
  }

  private throwException(error: string) {
    console.log(error);
    this.isLoading = false;
    this.alertService.show('Erro inesperado', error, AlertType.error);
  }
}