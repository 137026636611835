import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { ListPriceProcedurePlanResponse } from '../../responses/private-billing/list-price-procedure-plan.response';
import { HistoryPriceProcedurePlanResponse } from '../../responses/private-billing/history-price-procedure-plan.response';
import { PriceProcedurePlanRequest } from '../../requests/private-billing/price-procedure-plan.request';
import { PostPriceProcedurePlanResponse } from '../../responses/private-billing/post-price-procedure-plan.response';

@Injectable({
  providedIn: 'root'
})
export class PriceProcedurePlanService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  public Put(body: PriceProcedurePlanRequest): Observable<PostPriceProcedurePlanResponse> {
    let uri = `PriceProcedurePlan`

    return this.httpClient.put<PostPriceProcedurePlanResponse>(environment.urlApiPrivateBilling + uri, body, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public Post(body: PriceProcedurePlanRequest): Observable<PostPriceProcedurePlanResponse> {
    let uri = `PriceProcedurePlan`

    return this.httpClient.post<PostPriceProcedurePlanResponse>(environment.urlApiPrivateBilling + uri, body, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public delete(idPlan: number, idProcedure: number): Observable<ReturnStruct> {
    let uri = `PriceProcedurePlan/idPlan/${idPlan}/idProcedure/${idProcedure}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public GetAll(idHealthcareAgreement: number, searchText: string, pageIndex: number): Observable<ListPriceProcedurePlanResponse> {
    let uri = `PriceProcedurePlan?idHealthcareAgreement=${idHealthcareAgreement}&`;

    if (searchText)
      uri = uri + `searchText=${searchText}&`

    if (pageIndex)
      uri = uri + `pageIndex=${pageIndex}`;

    return this.httpClient.get<ListPriceProcedurePlanResponse>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public getHistoryPrice(idProcedurePlan: number): Observable<HistoryPriceProcedurePlanResponse> {
    let uri = `PriceProcedurePlan/idProcedurePlan/${idProcedurePlan}`;

    return this.httpClient.get<HistoryPriceProcedurePlanResponse>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError))
  }
}