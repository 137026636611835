import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { NatureOfAttendanceEnum } from 'src/app/shared/enum/medical-record/nature-of-attendance.enum';
import { MessageFiringTriggerEnum } from 'src/app/shared/enum/nps/message-firing-trigger.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MedicalRecordLookupService } from 'src/app/shared/services/API/medical-record/lookup.service';
import { LookupNpsService } from 'src/app/shared/services/API/nps/lookup.service';
import { MessageFiringRulesService } from 'src/app/shared/services/API/nps/message-firing-rules.service';
import { NpsMessageService } from 'src/app/shared/services/API/nps/nps-message.service';
import { HealthUnitService } from 'src/app/shared/services/API/user/health-unit.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { NatureOfAttendance } from 'src/app/shared/services/models/medical-record/nature-of-attendance';
import { MessageFiringRules } from 'src/app/shared/services/models/nps/message-firing-rules.model';
import { MessageFiringTrigger } from 'src/app/shared/services/models/nps/message-firing-trigger.model';
import { ListMessageFiringRulesRequest } from 'src/app/shared/services/requests/nps/list-message-firing-rules.request';
import { NpsLogoModalComponent } from './nps-logo-modal/nps-logo-modal.component';
import { NpsScreenConfig } from 'src/app/shared/services/models/nps/nps-message-trigger.model';
import { NpsScreenConfigService } from 'src/app/shared/services/API/nps/nps-screen-config.service';

@Component({
  selector: 'app-nps',
  templateUrl: './nps.component.html',
  styleUrls: ['./nps.component.css']
})

export class NpsComponent implements OnInit {

  constructor(
    private alertService: AlertService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private maskService: MaskService,
    public messageFiringRulesService: MessageFiringRulesService,
    public npsMessageService: NpsMessageService,
    public lookupNpsService: LookupNpsService,
    public lookupMedicalRecordService: MedicalRecordLookupService,
    private healthUnitService: HealthUnitService,
    private npsScreenConfigService: NpsScreenConfigService
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.master;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.nps;

  public masks: Masks;
  public isLoading: boolean;
  public isFirstLoading: boolean = false;
  public logo: string;
  public newLogo: string;
  public model: FormGroup;
  public listMessageFiringRules: MessageFiringRules[] = [];
  public listSelectedMessageFiringRules: MessageFiringRules[] = [];
  public listMessageFiringTrigger: MessageFiringTrigger[] = [];
  public listNatureOfAttendance: NatureOfAttendance[] = [];
  public messageTriggerActive: boolean = false;
  public commentCollectionActive: boolean = false;
  public healthUnitName: string = "";
  public idHealthUnit: number;
  public verifyTimeOfTrigger: boolean = false;

  public renderGraph: boolean = false;

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      useColor: [false, [Validators.required]],
      isLogoUploaded: [false, [Validators.required]],
      colorCode: [''],
      logoString64: [''],
      listMessageFiringRules: this.formBuilder.array([]),
    });

    this.getHealthUnitConfig();
    this.getLookupNps();
    this.getNatureOfAttendance();
    this.getMessageFiringRules();
  }

  getMessageFiringRules() {
    this.isFirstLoading = true;
    this.isLoading = true;
    this.messageFiringRulesService.getListMessageFiringRules().subscribe({
      next: (response) => {
        if (response.isError) {
          this.isLoading = false;
          this.isFirstLoading = false;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listMessageFiringRules = response.listMessageFiringRules;

        if (response.npsScreenConfig != null) {
          this.model.get('useColor').setValue(response.npsScreenConfig.useColor);
          this.model.get('colorCode').setValue(response.npsScreenConfig.colorCode);
          this.model.get('isLogoUploaded').setValue(response.npsScreenConfig.isLogoUploaded);
          this.model.get('logoString64').setValue(response.logoString64);
          this.commentCollectionActive = response.npsScreenConfig.commentCollectionActive;
          this.logo = response.logoString64;
        }

        if (this.listMessageFiringRules.length > 0)
          this.populateNPS();
        else {
          this.addNext();
          this.isLoading = false;
          this.isFirstLoading = false;
        }
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.isFirstLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getLookupNps() {
    this.isLoading = true;
    this.lookupNpsService.listLookup().subscribe({
      next: (response) => {
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listMessageFiringTrigger = response.listMessageFiringTrigger;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getNatureOfAttendance() {
    this.isLoading = true;
    this.lookupMedicalRecordService.listNatureOfAttendance().subscribe({
      next: (response) => {
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listNatureOfAttendance = response.listNatureOfAttendance;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getHealthUnitConfig() {
    this.isLoading = true;
    this.healthUnitService.getHealthUnitConfig().subscribe({
      next: (response) => {
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.messageTriggerActive = response.healthUnit.messageTriggerActive;
        this.healthUnitName = response.healthUnit.healthUnitName;
        this.idHealthUnit = response.healthUnit.idHealthUnit;
        this.isLoading = false;
        this.renderGraph = true;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateNPS() {
    this.listMessageFiringRules.forEach((x, index) => {
      (this.model.controls['listMessageFiringRules'] as FormArray).push(
        this.formBuilder.group({
          idMessageFiringRules: [x.idMessageFiringRules],
          idMessageFiringTrigger: [x.idMessageFiringTrigger, [Validators.required]],
          idNatureOfAttendance: [x.idNatureOfAttendance, [Validators.required]],
          frequency: [x.frequency, [Validators.required]],
          timeOfTrigger: [x.timeOfTrigger, [Validators.required]],
          descriptionOfMessage: [x.descriptionOfMessage, [Validators.required]],
        })
      )
      if (this.model.get('listMessageFiringRules')['controls'][index].get('idMessageFiringTrigger').value == MessageFiringTriggerEnum.fimDaClassificacao) {
        this.model.get('listMessageFiringRules')['controls'][index].get('idNatureOfAttendance').setValue(NatureOfAttendanceEnum.presencial);
        this.model.get('listMessageFiringRules')['controls'][index].get('idNatureOfAttendance').disable();
      }

      if(this.model.get('listMessageFiringRules')['controls'][index].get('frequency').value.toString() !== "Hora" && this.model.get('listMessageFiringRules')['controls'][index].get('frequency').value.toString() !== "Dia")
      {
        this.model.get('listMessageFiringRules')['controls'][index].get('frequency').setValue(null);
      }
    });

    this.setSelectedMessageFiringTrigger();
    this.isLoading = false;
    this.isFirstLoading = false;
  }

  submit() {
    if (this.isLoading)
      return

    if (this.model.invalid) {
      this.model.get('listMessageFiringRules')['controls'].forEach(x => {
        x.get('idMessageFiringTrigger').markAsTouched();
        x.get('idNatureOfAttendance').markAsTouched();
        x.get('frequency').markAsTouched();
        x.get('timeOfTrigger').markAsTouched();
        x.get('descriptionOfMessage').markAsTouched();
      });

      this.alertService.show('Erro', "Todos os campos em vermelho são de preenchimento obrigatório", AlertType.error);
      this.isLoading = false;
      return;
    }

    this.isLoading = true;

    let request: ListMessageFiringRulesRequest = new ListMessageFiringRulesRequest();

    this.model.get('listMessageFiringRules').value.forEach(x => {
      var messageFiringRules = new MessageFiringRules();
      messageFiringRules.idMessageFiringRules = (x.idMessageFiringRules != '') ? x.idMessageFiringRules : 0;
      messageFiringRules.idMessageFiringTrigger = x.idMessageFiringTrigger;
      messageFiringRules.idNatureOfAttendance = x.idNatureOfAttendance;
      messageFiringRules.frequency = x.frequency;
      messageFiringRules.timeOfTrigger = x.timeOfTrigger;
      if(messageFiringRules.frequency.toString() === "Hora" && Number(messageFiringRules.timeOfTrigger) < 2)
      {
        this.verifyTimeOfTrigger = true;
      }
      messageFiringRules.descriptionOfMessage = x.descriptionOfMessage;

      request.listMessageFiringRules.push(messageFiringRules);
    });

    if(this.verifyTimeOfTrigger == true)
    {
      this.verifyTimeOfTrigger = false;
      this.alertService.show('Erro', "O tempo de disparo não pode ser menor que 2 horas", AlertType.error);
      this.isLoading = false;
      return;
    }

    request.npsScreenConfig = new NpsScreenConfig();
    request.npsScreenConfig.useColor = this.model.get('useColor').value;
    request.npsScreenConfig.colorCode = !request.npsScreenConfig.colorCode ? this.model.get('colorCode').value : null;
    request.npsScreenConfig.isLogoUploaded = this.model.get('isLogoUploaded').value;
    request.npsScreenConfig.commentCollectionActive = this.commentCollectionActive;
    request.logoString64 = request.npsScreenConfig.isLogoUploaded ? this.model.get('logoString64').value : null;

    this.messageFiringRulesService.createMessageFiringRules(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  changeMessageTriggerActive() {
    this.messageTriggerActive = !this.messageTriggerActive;
    this.isLoading = true;
    this.healthUnitService.updateChoiceIsActiveMessageTrigger(this.messageTriggerActive).subscribe({
      next: (response) => {
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  changeCommentCollectionActive() {
    this.commentCollectionActive = !this.commentCollectionActive;
    this.isLoading = true;

    this.npsScreenConfigService.updateToggleCommentCollection(this.commentCollectionActive, this.idHealthUnit).subscribe({
      next: (response) => {
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  createInput() {
    return this.formBuilder.group({
      idMessageFiringRules: [''],
      idMessageFiringTrigger: ['', [Validators.required]],
      idNatureOfAttendance: [, [Validators.required]],
      frequency: ['', [Validators.required]],
      timeOfTrigger: ['', [Validators.required]],
      descriptionOfMessage: [`Em uma escala de 0 a 10, o quanto você recomendaria o atendimento na ${this.healthUnitName} a um amigo ou familiar?`, [Validators.required]],
    });
  }

  addNext() {
    (this.model.controls['listMessageFiringRules'] as FormArray).push(this.createInput())
  }

  removeField(index: number) {
    if (index != 0)
      (this.model.controls['listMessageFiringRules'] as FormArray).removeAt(index)

    this.setSelectedMessageFiringTrigger();
  }

  setMessageFiringTrigger(index) {
    if (this.model.get('listMessageFiringRules')['controls'][index].get('idMessageFiringTrigger').value == MessageFiringTriggerEnum.fimDaClassificacao) {
      this.model.get('listMessageFiringRules')['controls'][index].get('idNatureOfAttendance').setValue(NatureOfAttendanceEnum.presencial);
      this.model.get('listMessageFiringRules')['controls'][index].get('idNatureOfAttendance').disable();
    }

    if (this.model.get('listMessageFiringRules')['controls'][index].get('idMessageFiringTrigger').value == MessageFiringTriggerEnum.desfecho) {
      this.model.get('listMessageFiringRules')['controls'][index].get('idNatureOfAttendance').setValue();
      this.model.get('listMessageFiringRules')['controls'][index].get('idNatureOfAttendance').enable();
    }

    this.setSelectedMessageFiringTrigger();
  }

  setSelectedMessageFiringTrigger() {
    let messageFiringRules = this.model.get('listMessageFiringRules')['controls'];
    this.listSelectedMessageFiringRules = messageFiringRules.map(x => x.value.idMessageFiringTrigger);
  }

  openPanelLogoModal() {
    const dialogRef = this.dialog.open(NpsLogoModalComponent, {
      data: {
        logo: this.logo,
        newLogo: this.newLogo,
      },
    });

    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.newLogo) {
          this.alertService.show('Sucesso', "Upload concluído!", AlertType.success);
          this.newLogo = result.newLogo;

          if (!this.logo)
            this.logo = this.newLogo;

          this.model.get('logoString64').setValue(result.newLogo);
        }
        if (result && !result.haveLogo)
          this.model.get('isLogoUploaded').setValue(false);
      }
    });
  }
}