import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UtilsClassificationService } from 'src/app/classification/utils.service';
import { DeviceTypeEnum } from 'src/app/shared/enum/device-type.enum';
import { DiscriminatorTypeEnum } from 'src/app/shared/enum/discriminator-type.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { DiscriminatorStruct } from 'src/app/shared/services/structs/risk-classification/discriminator.struct';
import { GlasgowModalComponent } from '../discriminator/glasgow-modal/glasgow-modal.component';
import { PressureModalComponent } from '../discriminator/pressure-modal/pressure-modal.component';
import { RespiratoryFrequencyModalComponent } from '../discriminator/respiratory-frequency-modal/respiratory-frequency-modal.component';
import { UtilsMeasurerRemoteService } from '../protocol-v2/utils-measurer-remote.service';
import { UtilsMeasurerService } from '../protocol-v2/utils-measurer.service';

@Component({
  selector: 'app-multiple-victim-secondary',
  templateUrl: './multiple-victim-secondary-assessment.component.html',
  styleUrls: ['./multiple-victim-secondary-assessment.component.css'],
})
export class MultipleVictimSecondaryAssessmentComponent implements OnInit, OnDestroy {

  constructor(private alertService: AlertService,
    private dialog: MatDialog,
    private utilService: UtilsClassificationService,
    public router: Router,
    private formBuilder: UntypedFormBuilder,
    private measurerService: UtilsMeasurerService,
    private measurerRemote: UtilsMeasurerRemoteService) { }

  @Input() discriminators: any[] = [];
  @Output() selectedValue = new EventEmitter<any>();
  @Output() goNextToFlowchartSelect = new EventEmitter<any>();

  public model: FormGroup;
  public trtsDescription: string = "Escore revisado de triagem do trauma.";
  
  ngOnInit(): void {
    this.model = this.formBuilder.group({
      "radio-trt": [{ value: null, disabled: true }],
    });
    let data = this.utilService.getClassificationData();
    if (data.idDeviceType == DeviceTypeEnum.Trius || data.idDeviceType == DeviceTypeEnum.Berco || data.idDeviceType == DeviceTypeEnum.Bluetooth) {
      this.measurerService.setIndex(-1);
      this.measurerService.createReference(this.discriminators, null, null, data.deviceThermometer, data.deviceGlucometer, data.deviceOximeter, data.devicePressure);
      this.measurerService.restartMeasurer();
    } else if (data.idDeviceType == DeviceTypeEnum.Remoto) {
      this.measurerRemote.isOnline(data.device.idDevice);
      this.measurerRemote.createReference(this.discriminators, null, null);
      this.measurerRemote.startWebsocket(data.device.deviceHash);
    }
  }

  ngOnDestroy(): void {
    let data = this.utilService.getClassificationData();
    if (data.idDeviceType == DeviceTypeEnum.Trius || data.idDeviceType == DeviceTypeEnum.Berco || data.idDeviceType == DeviceTypeEnum.Bluetooth) {
      this.measurerService.setIndex(-1);
    } else if (data.idDeviceType == DeviceTypeEnum.Remoto) {
      this.measurerRemote.clearAllInterval();
    }
  }

  previous() {
    let data = this.utilService.getClassificationData();
    data.isPatientWhite = false;
    data.discriminatorAllValues = [];
    data.discriminatorsPersist = [];
    data.idFlowchart = null;
    this.utilService.updateClassificationData(data);
    this.goNextToFlowchartSelect.emit();
  }

  setValuetrt() {
    const dialogRef = this.dialog.open(RespiratoryFrequencyModalComponent, {
      data: {
        discriminator: new DiscriminatorStruct(), value: this.discriminators[DiscriminatorTypeEnum.FrequenciaRespiratoria]
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && (result.value || result.value === 0)) {
        this.discriminators[DiscriminatorTypeEnum.FrequenciaRespiratoria] = result.value;
        this.openModalPressure();
      }
    });
  }

  openModalPressure() {
    const dialogRef = this.dialog.open(PressureModalComponent, {
      data: {
        discriminator: new DiscriminatorStruct(), discriminatorsValues: this.discriminators
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && (result.value || result.value === 0)) {
        this.discriminators[DiscriminatorTypeEnum.PressaoArterial] = result.value;
        this.openModalGlasgow();
      }
    });
  }

  openModalGlasgow() {
    let classificationData = this.utilService.getClassificationData()
    let age = (classificationData.ageYears * 10000) + (classificationData.ageMonth * 100) + classificationData.ageDay;
    const dialogRef = this.dialog.open(GlasgowModalComponent, { data: { age, noShowLastPage: true } });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && (result.value || result.value === 0)) {
        this.discriminators[DiscriminatorTypeEnum.Glasgow] = result.value;
        let data = this.utilService.getClassificationData();
        let dis: DiscriminatorStruct = new DiscriminatorStruct;
        dis.idDiscriminatorType = 10;
        dis.idFlowchart = data.idFlowchart;
        dis.idDiscriminator = 1508;
        this.selectedValue.emit({ item: dis, discriminatorsValues: null })
      }
    });
  }
}
