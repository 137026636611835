import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { PanelRequest } from '../../requests/panel/panel.request';
import { GetPanelResponse } from '../../responses/panel/get-panel.response';
import { ListPanelResponse } from '../../responses/panel/list-panel.response';

@Injectable({
  providedIn: 'root'
})
export class PanelService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }


  public listPanel(searchText: string, isActive: boolean): Observable<ListPanelResponse> {

    let uri = 'panel?'

    if (searchText != null)
      uri = uri + `searchText=${searchText}&`;

    if (isActive != null)
      uri = uri + `isActive=${isActive}&`

    return this.httpClient.get<ListPanelResponse>(environment.urlApiPanel + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getPanel(idPanel: number): Observable<GetPanelResponse> {

    let uri = `Panel/idPanel/${idPanel}`;

    return this.httpClient.get<GetPanelResponse>(environment.urlApiPanel + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public createPanel(body: PanelRequest): Observable<ReturnStruct> {

    let uri = `Panel`

    return this.httpClient.post<ReturnStruct>(environment.urlApiPanel + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public updatePanel(idPanel: number, body: PanelRequest): Observable<ReturnStruct> {

    let uri = `Panel/idPanel/${idPanel}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiPanel + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public updatePanelPin(idPanel: number): Observable<ReturnStruct> {

    let uri = `Panel/updatePin/idPanel/${idPanel}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiPanel + uri, null, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public deletePanel(idPanel: number): Observable<ReturnStruct> {

    let uri = `panel/idPanel/${idPanel}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiPanel + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}