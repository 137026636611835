import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { GetPatientResponse } from '../../responses/orchestrator-patient/get-patient.response';

@Injectable({
  providedIn: 'root'
})
export class EpisodeService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getPatientByEpisode(idEpisode: number): Observable<GetPatientResponse> {
    let uri = `PatientEpisode/getPatientEpisode/idEpisode/${idEpisode}`

    return this.httpClient.get<GetPatientResponse>(environment.urlApiOrchestratorPatient + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getPatientEpisodeAndHealthcareAgreementByEpisode(idEpisode: number, idQueue: number = null): Observable<GetPatientResponse> {
    let uri = `PatientEpisode/getPatientEpisodeWithPlanAndHealthcareAgreement/idEpisode/${idEpisode}?`

    if (idQueue)
      uri = uri + `idQueue=${idQueue}`;

    return this.httpClient.get<GetPatientResponse>(environment.urlApiOrchestratorPatient + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
