import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ClientTelemedicineAccessResponse } from '../../responses/telemedicine/client-telemedicine-access.response';
import { TelemedicineAccessRequest } from '../../requests/telemedicine/telemedicine-access.request';
import { TelemedicineAccessResponse } from '../../responses/telemedicine/telemedicine-access.response';

@Injectable({
  providedIn: 'root'
})
export class TelemedicineAccessService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public generateGuid(body: TelemedicineAccessRequest): Observable<TelemedicineAccessResponse>{

    let uri = `TelemedicineAccess`;

    return this.httpClient.post<TelemedicineAccessResponse>(environment.urlApiTelemedicine + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}