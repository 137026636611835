import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FilterSelectModel, ItemSelectModel } from 'src/app/shared/components/filter-select/filter-select.model';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { UserDeleteModalComponent } from './user-delete-modal/user-delete-modal.component';
import { UserService } from '../../../shared/services/API/user/user.service';
import { ProfessionService } from 'src/app/shared/services/API/user/profession.service';
import { ListUserStruct } from 'src/app/shared/services/structs/user/list-user.struct';
import { ProfileService } from 'src/app/shared/services/API/user/profile.service';
import { LoginHistoryModalComponent } from './login-history-modal/login-history-modal.component';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

  constructor(
    private userService: UserService,
    private professionService: ProfessionService,
    private profileService: ProfileService,
    private alertService: AlertService,
    private utilService: UtilService,
    public dialog: MatDialog
  ) { }

  public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.master;
  public menuFunctionalityEnum:MenuFunctionalityEnum = MenuFunctionalityEnum.master_user;

  public listUser: ListUserStruct[];
  public filterSelectModelProfession: FilterSelectModel;
  public filterSelectModelStatus: FilterSelectModel;
  public filterSelectModelProfile: FilterSelectModel;

  public searchText: string;

  public isActive: boolean;
  public isLoading: boolean;


  ngOnInit(): void {
    this.populateScrean();

    this.listUser == null;
    this.isLoading = true;
    this.isActive = null;
    this.searchText = null;
  }

  populateScrean(){    
    //populando select profissões
    this.populateSelectProfession()
    this.populateSelectProfile()

    //populando select de status
    this.filterSelectModelStatus = new FilterSelectModel();
    this.filterSelectModelStatus.itemSelectModel.push(new ItemSelectModel("true", "Ativo", false))
    this.filterSelectModelStatus.itemSelectModel.push(new ItemSelectModel("false", "Inativo", false))
    this.filterSelectModelStatus.itemSelectModel.push(new ItemSelectModel("null", "Ambos", false))
    
    //Listando usuários
    this.search();
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }

  populateSelectProfession(){
    this.filterSelectModelProfession = new FilterSelectModel();
    this.professionService.listProfession().subscribe({
      next: (response)=>{
           
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        response.listProfession.forEach(element => {
          this.filterSelectModelProfession.itemSelectModel
          .push(new ItemSelectModel(element.idProfession.toString(), element.professionName, false));
        });
      }, 
      error: (error)=>{
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateSelectProfile(){
    this.filterSelectModelProfile = new FilterSelectModel();
    this.profileService.listAllProfile().subscribe({
      next: (response)=>{
           
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        response.listProfile.forEach(element => {
          this.filterSelectModelProfile.itemSelectModel
          .push(new ItemSelectModel(element.idProfile.toString(), element.profileName, false));
        });
      }, 
      error: (error)=>{
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  search(){

    let jsonListIdProfession = this.utilService.getKeySelectedModel(this.filterSelectModelProfession);
    let jsonListIdProfile = this.utilService.getKeySelectedModel(this.filterSelectModelProfile);

    if(this.searchText == "")
      this.searchText = null;

    this.isLoading = true;
    this.userService.listUser(this.isActive ,this.searchText, jsonListIdProfession, jsonListIdProfile).subscribe({
      next:(response)=>{
      
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.listUser = response.listUser;
        this.isLoading = false;    
      },
      error:(error)=>{
        console.log(error)
        this.isLoading = false;    
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openModal(idUser){
    const dialogRef = this.dialog.open(UserDeleteModalComponent, {
      data: {
        idUser: idUser
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.deleteUser){
        this.search();
      }
    });
  }

  getFormatedListProfileName(listName: string[]){
    if(listName.length > 0)
      return listName.map(name => name).join(', ');
    else 
      return "Não há perfis atrelados";
  }

  openLogHistoryModal(idUser){
    this.dialog.open(LoginHistoryModalComponent, {
      data: {
        idUser: idUser
      },
    });
  }
}
