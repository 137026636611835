<div class="container-generic">

    <app-menu [isClassification]="true" [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'
        [hasSecundaryHeader]="true" [episode]="idEpisode" [hasCachedModule]="true" [cachedModuleName]="'classification'"></app-menu>
    
    <div [ngClass]="{'container-body-secundary-header': idEpisode !== null, 'container-body': idEpisode === null}">
        <a class="back-link" routerLink="/classification">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Usuários
        </a>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <app-white-patient (goBackEmitter)="goBack()" (firstLoading)="firstLoading($event)" (selectArrivalReason)="selectArrivalReason()"></app-white-patient>
    </div>
    
</div>

