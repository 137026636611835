export class GetAllAuditRequest {
    public listIdUser: number[];

    public idAudit: number;

    public idStatus: number;

    public year: number;
    
    public month: number;
}