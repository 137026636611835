import { Component, Inject, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-cancel-appointment-modal',
  templateUrl: './confirm-cancel-appointment-modal.component.html',
  styleUrls: ['./confirm-cancel-appointment-modal.component.css']
})
export class ConfirmCancelAppointmentModalComponent implements OnInit {
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<ConfirmCancelAppointmentModalComponent>,
  ) { }


  ngOnInit(): void {
  }

  close(){
    this.matDialogRef.close(); 
  }


  cancelAppointment(){
    this.matDialogRef.close({cancel: true}); 
  }
}
