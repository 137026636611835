<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <a class="back-link" routerLink="/master/room">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back
            </mat-icon> Salas
        </a>
        <h1>Cadastrar Sala</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="title-header">
                    <h1>Dados da Sala</h1>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome</mat-label>
                            <input matInput type="text" formControlName="name">
                            <mat-error *ngIf="model.get('name').invalid">Informe o Nome</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Descrição</mat-label>
                            <input matInput type="text" formControlName="description">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Setor</mat-label>
                            <mat-select formControlName="idSector" [disabled]="this.isUpdate"
                                (selectionChange)="onSector()">
                                <mat-option *ngFor="let item of listSector"
                                    value="{{item.idSector}}">{{item.sectorName}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idSector').invalid">Informe o Setor</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-4 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Especialidade de atendimento</mat-label>
                            <mat-select formControlName="idSpecialityAttendance">
                                <mat-option [value]="null">Selecione</mat-option>
                                <mat-option *ngFor="let item of listSpecialityAttendance"
                                    [value]="item.idSpecialityAttendance">
                                    {{item.specialityAttendanceName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Status</mat-label>
                            <mat-select formControlName="status">
                                <mat-option value="true">Ativo</mat-option>
                                <mat-option value="false">Inativo</mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('status').invalid">Informe o Status</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2 col-sm-2 col-md-2 space-bottom">
                        <mat-slide-toggle formControlName="isEmergency">Emergência</mat-slide-toggle>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <a mat-flat-button type="button" routerLink="/master/room" color="accent" class=" btn-block">
                            Cancelar
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>