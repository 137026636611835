<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-confirm-modal-list">
            <h2>Diferença no tipo da apresentação dos medicamentos!</h2>
        </div>

        <div class="body-confirm-modal-list">
            A <b>apresentação do medicamento</b> do lote lido é diferente da solicitada pelo médico. Tem certeza que
            deseja utilizar esse lote?
        </div>
        <div class="footer-confirm-modal-list">
            <button mat-flat-button color="primary" class="btn-block" (click)="clickConfirm()">
                <span>Sim</span>
            </button>
            <button mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Não</button>
        </div>
    </div>
</div>