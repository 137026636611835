import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VerifyDate } from 'src/app/shared/custom-validators/date-age.validator';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { SigaSearchPatientService } from 'src/app/shared/services/API/orchestrator-patient/siga-search-patient.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { SearchPersonFilterRequest } from 'src/app/shared/services/requests/integration-siga/search-person-filter.request';
import { SigaPatientStruct } from 'src/app/shared/services/structs/siga-front-desk/siga-patient.struct';
import { SigaSelectPatientModalComponent } from '../siga-select-patient-modal/siga-select-patient-modal.component';

@Component({
  selector: 'app-siga-search-patient-full-term',
  templateUrl: './siga-search-patient-full-term.component.html',
  styleUrls: ['./siga-search-patient-full-term.component.css']
})
export class SigaSearchPatientFullTermComponent implements OnInit {
  
  @Input() findPatient: boolean;
  @Output() emitFindPatient = new EventEmitter<any>();
  @Output() emitPatientData = new EventEmitter<any>();

  constructor(
    private alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
    private sigaSearchPatientService: SigaSearchPatientService,
    private maskService: MaskService,
    public dialog: MatDialog,
    ) { }

  public searchModel: UntypedFormGroup;
  public isLoading: boolean = false;
  public masks: Masks;
  public patientData: SigaPatientStruct;

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.patientData = new SigaPatientStruct();
    this.searchModel = this.formBuilder.group({
      name: ['', [Validators.required]],
      motherName: ['', [Validators.required]],
      birthDate: ['', [Validators.required, VerifyDate()]],
    });

  }

  searchByFullTerms() {
    if(this.isLoading || this.searchModel.invalid)
      return;
      
    this.isLoading = true;
    let request: SearchPersonFilterRequest = new SearchPersonFilterRequest();

    request.completeName = this.searchModel.get('name').value;
    request.motherName = this.searchModel.get('motherName').value;
    request.birthDate = this.maskService.formatStringToDate(this.searchModel.get('birthDate').value);
    
    this.sigaSearchPatientService.searchPatientFullTerms(request).subscribe({next:(response)=>{
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      if(response.listSigaPatientStruct.length == 1){
        this.patientData = response.listSigaPatientStruct[0];
      } else if(response.listSigaPatientStruct.length > 1) {
        this.openSelectPatientModal(response.listSigaPatientStruct);
        return;
      } else {
        this.alertService.show('Erro', "Paciente não encontrado na base de dados!", AlertType.error);
        this.isLoading = false;
        return;
      }
      this.emitFindPatient.emit(true);
      this.emitPatientData.emit(this.patientData);
    },
    error:(error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    }});
  }

  openSelectPatientModal(patients: SigaPatientStruct[]) {

    const dialogRef = this.dialog.open(SigaSelectPatientModalComponent, {
      data: patients,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.patientData = result;
      }
      this.emitFindPatient.emit(true);
      this.emitPatientData.emit(this.patientData);
    });
  }
}