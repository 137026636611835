<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <h1>Procedimentos</h1>
        <div class="white-body">
            <div class="row">
                <div class="col-12 col-md-4">
                    <a mat-flat-button color="primary" class="add-user"
                        routerLink="/billing/procedure/register">
                        <mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon>
                        Criar novo</a>
                </div>
            </div>            
            <div class="filters">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Pesquisar</mat-label>
                            <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText"
                                (keyup)="onKeySearch($event)">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-2">
                        <button class="custom-button" [class.active]="isButtonActive('ativos')"
                            (click)="controlButtonStatus('ativos')">Ativos</button>
                    </div>
                    <div class="col-12 col-md-2">
                        <button class="custom-button" [class.active]="isButtonActive('inativos')"
                            (click)="controlButtonStatus('inativos')">Inativos</button>
                    </div>
                </div>
            </div>
            <table class="table">
                <thead>
                    <tr>
                        <th class="column-name">Nome</th>
                        <th class="column-code">TUSS</th>
                        <th class="column-description">Orientações</th>
                        <th class="column-active">Ativo</th>
                        <th class="column-action">Ações</th>
                    </tr>
                </thead>
                <tbody *ngIf="listProcedure && listProcedure.length > 0 && !this.isLoading">
                    <tr *ngFor="let procedure of listProcedure">
                        <td>{{ procedure.nameProcedure }}</td>
                        <td>{{ procedure.terminology &&  procedure.terminology.idTerminology != 0 ? procedure.terminology.terminologyCodeTuss + ' - ' + procedure.terminology.terminologyNameTuss : '-'}}</td>
                        <td>{{ procedure.descriptionProcedure }}</td>
                        <td>
                            <mat-slide-toggle [(ngModel)]="procedure.isActive"
                                (change)="putStatus($event, procedure.idProcedure)"
                                [labelPosition]="'before'"></mat-slide-toggle>

                        </td>
                        <td>
                            <a class="action-icon">
                                <mat-icon aria-hidden="false" aria-label="Editar"
                                    [routerLink]="['/billing/procedure/register', {idProcedure: procedure.idProcedure}]">
                                    edit
                                </mat-icon>
                            </a>

                        </td>
                    </tr>
                </tbody>
            </table>

            <app-empty-list *ngIf="(!listProcedure || listProcedure.length == 0) && !this.isLoading"></app-empty-list>

            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-paginator [length]="fullListSize" #paginator [pageIndex]="pageIndex" [pageSize]="pageSize"
                        aria-label="Select page" (page)="changePage($event)" [hidePageSize]="true">
                    </mat-paginator>
                </div>
            </div>
            
        </div>

        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>
</div>