import { ReturnStruct } from "src/app/shared/structs/return.struct";

export class MedicalProcedureModel {
    public idMedicalProcedure: number;
    public idProcedure: number;
    public idEpisode: number;
    public idMedicalCare: number;
    public observation: string;
    public isRecurring: boolean;
    public isDeleted: boolean;
    public datetimeInclusion: Date;
    public idProcedureStatus: number;
    public idUser: number;
    public idProcedureSigtap: number;
}

export class MedicalProcedureWithName extends MedicalProcedureModel{
    public procedureName: string;
    public userName: string;
}

export class MedicalProcedureWithNameResponse extends ReturnStruct {
    public listProcedures: MedicalProcedureWithName[];
    public listProceduresNursing: MedicalProcedureWithName[];
    public listProceduresMedic: MedicalProcedureWithName[];
}