import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ManageWebhookService } from 'src/app/shared/services/API/integration/manage-webhook.service';
import { ListWebhookStruct } from 'src/app/shared/services/structs/integration/list-webhook.struct';
import { UtilService } from 'src/app/shared/services/util.service';
import { WebhookDeleteModalComponent } from './webhook-delete-modal/webhook-delete-modal.component';
import { TasksQueuesModalComponent } from 'src/app/shared/components/tasks-queues-modal/tasks-queues-modal.component';
import { TasksQueuesModel } from 'src/app/shared/services/models/integration/tasks-queues.model';

@Component({
  selector: 'app-webhook-list',
  templateUrl: './webhook-list.component.html',
  styleUrls: ['./webhook-list.component.css']
})
export class WebhookListComponent implements OnInit {

  constructor(private manageWebhookService: ManageWebhookService,
    private router: Router,
    private alertService: AlertService,
    private utilService: UtilService,
    public dialog: MatDialog) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.integration;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.webhook_list;

  public listWebhook: ListWebhookStruct[];
  public isLoading: boolean;
  public isActive: boolean;
  public searchText: string;
  public idExamType: number;
  public canUseQueue: boolean = false;
  public tasksQueues: TasksQueuesModel;
  ngOnInit(): void {
    this.isLoading = true;
    this.search();
  }
  search() {
    this.isLoading = true;
    if (this.searchText == "")
      this.searchText = null;

    this.manageWebhookService.listWebhook(this.searchText, this.isActive).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listWebhook = response.listWebhook;
        this.canUseQueue = this.listWebhook.some(c => c.canUseQueue)
        if (this.canUseQueue) {
          this.tasksQueues = response.tasksQueues;
        }
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }

  openModal(idWebhook) {
    const dialogRef = this.dialog.open(WebhookDeleteModalComponent, {
      data: {
        idWebhook: idWebhook
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.deleteWebhook) {
        this.search();
      }
    });
  }

  openQueueModal() {
    const dialogRef = this.dialog.open(TasksQueuesModalComponent, {
      data: {
        ...this.tasksQueues
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        window.location.reload();
      }
    });
  }
}
