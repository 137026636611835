<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <h1>Auditorias</h1>
        <div class="white-body">
            <a mat-flat-button color="primary" class=" add-user" routerLink="/audit/audit/register">
                <mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon> Cadastrar Auditoria
            </a>
            <div class="filters">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Identificador Auditoria</mat-label>
                            <input matInput type="number" id="searchText" [(ngModel)]="idAudit"
                                (keyup)="onKeySearch($event)">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Mês/Ano</mat-label>
                            <mat-select [(ngModel)]="searchDate" (selectionChange)="search()">
                                <mat-option [value]="">Todos</mat-option>
                                <mat-option *ngFor="let item of listDate" [value]="item">{{item | date:"MMM/yyyy"}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Status</mat-label>
                            <mat-select [(ngModel)]="idStatus" (selectionChange)="search()">
                                <mat-option [value]="">Todos</mat-option>
                                <mat-option *ngFor="let item of listAuditStatus"
                                    [value]="item.idClassificationRiskAuditStatus">{{item.description}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-md-2">
                        <app-filter-select [filterSelectModel]="filterSelectModelUser" [name]="'Usuário'"
                            (seachEvent)="search()"></app-filter-select>
                    </div>
                </div>
            </div>
            <div class="container-card">
                <div class="container-box">
                    <div class="card-column" cdkDropList #firstAudit="cdkDropList"
                        [cdkDropListData]="listAuditFirstAuditors" [cdkDropListConnectedTo]="[secondAudit]"
                        (cdkDropListDropped)="onDropFirstColumn($event)">
                        <h2>1ª AUDITORIA</h2>
                        <div class="card-list" *ngIf="listAuditFirstAuditors != null">
                            <div class="row">
                                <div class="col-12" *ngFor="let item of listAuditFirstAuditors">
                                    <div class="card-item" cdkDrag>
                                        <div class="actions more-horiz">
                                            <mat-icon aria-hidden="false" aria-label="Plus"
                                                (click)="openHistoryCardModal(item.classificationRiskAudit.idClassificationRiskAudit)">more_horiz</mat-icon>
                                        </div>
                                        <div class="infos">
                                            <span class="primary">
                                                {{item.classificationRiskAudit.idClassificationRiskAudit}} -
                                                {{item.classificationRiskAudit.datetimeInclusion | date:
                                                'dd/MM/yyyy'}}
                                            </span>
                                            <span class="secundary">
                                                <span
                                                    matTooltip="{{item.firstAuditorName}} - {{item.firstAuditorProfession}}">
                                                    {{item.firstAuditorName}} - {{item.firstAuditorProfession}}
                                                </span>
                                            </span>
                                            <span class="secundary">
                                                <span
                                                    matTooltip="{{item.secondAuditorName}} - {{item.secondAuditorProfession}}">
                                                    {{item.secondAuditorName}} - {{item.secondAuditorProfession}}
                                                </span>
                                            </span>
                                            <span class="secundary">
                                                {{item.classificationRiskAudit.datetimeStart | date: 'MMM/yyyy'}}
                                                de {{item.classificationRiskAudit.datetimeStart | date: 'dd'}}
                                                à {{item.classificationRiskAudit.datetimeEnd| date: 'dd'}}
                                            </span>
                                            <span class="secundary">
                                                {{item.amount}} Classificações
                                            </span>
                                            <span class="secundary-status last-info">
                                                {{getStatusName(item.classificationRiskAudit.idStatus,
                                                item.firstAuditorName, item.secondAuditorName,
                                                item.otherUserAuditorName, item.datetimeConclusionFormated,
                                                item.classificationRiskAudit.idClassificationRiskAudit,
                                                item.numberOtherStatusAudit)}}
                                            </span>
                                        </div>
                                        <div class="actions">
                                            <a>
                                                <mat-icon aria-hidden="false" aria-label="Editar"
                                                    [routerLink]="['/audit/audit/execute', {idAudit: item.classificationRiskAudit.idClassificationRiskAudit}]">
                                                    edit</mat-icon>
                                            </a>
                                            <a *ngIf="item.classificationRiskAudit.idStatus != concludedEnum">
                                                <mat-icon aria-hidden="false" aria-label="Editar"
                                                    (click)="openModal(item.classificationRiskAudit.idClassificationRiskAudit)">
                                                    delete_forever</mat-icon>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <app-loading-list *ngIf="isLoading"></app-loading-list>
                        <app-empty-list [message]="'Não há auditoria nesta coluna'" *ngIf="listAuditFirstAuditors != undefined 
                        && listAuditFirstAuditors.length == 0">
                        </app-empty-list>
                    </div>
                    <div class=" card-column" cdkDropList #secondAudit="cdkDropList"
                        [cdkDropListConnectedTo]="[otherOpinion, concluded, firstAudit]"
                        [cdkDropListData]="listAuditSecondAuditors" (cdkDropListDropped)="onDropSecondColumn($event)">
                        <h2>2ª AUDITORIA</h2>
                        <div class="card-list" *ngIf="listAuditSecondAuditors != null">
                            <div class="row">
                                <div class="col-12" *ngFor="let item of listAuditSecondAuditors">
                                    <div class="card-item" cdkDrag>
                                        <div class="actions more-horiz">
                                            <mat-icon aria-hidden="false" aria-label="Plus"
                                                (click)="openHistoryCardModal(item.classificationRiskAudit.idClassificationRiskAudit)">more_horiz</mat-icon>
                                        </div>
                                        <div class="infos">
                                            <span class="primary">
                                                {{item.classificationRiskAudit.idClassificationRiskAudit}} -
                                                {{item.classificationRiskAudit.datetimeInclusion | date:
                                                'dd/MM/yyyy'}}
                                            </span>
                                            <span class="secundary">
                                                <span
                                                    matTooltip="{{item.firstAuditorName}} - {{item.firstAuditorProfession}}">
                                                    {{item.firstAuditorName}} - {{item.firstAuditorProfession}}
                                                </span>
                                            </span>
                                            <span class="secundary">
                                                <span
                                                    matTooltip="{{item.secondAuditorName}} - {{item.secondAuditorProfession}}">
                                                    {{item.secondAuditorName}} - {{item.secondAuditorProfession}}
                                                </span>
                                            </span>
                                            <span class="secundary">
                                                {{item.classificationRiskAudit.datetimeStart | date: 'MMM/yyyy'}}
                                                de {{item.classificationRiskAudit.datetimeStart | date: 'dd'}}
                                                à {{item.classificationRiskAudit.datetimeEnd| date: 'dd'}}
                                            </span>
                                            <span class="secundary">
                                                {{item.amount}} Classificações
                                            </span> <span class="secundary-status last-info">
                                                {{getStatusName(item.classificationRiskAudit.idStatus,
                                                item.firstAuditorName, item.secondAuditorName,
                                                item.otherUserAuditorName, item.datetimeConclusionFormated,
                                                item.classificationRiskAudit.idClassificationRiskAudit,
                                                item.numberOtherStatusAudit)}}</span>
                                        </div>
                                        <div class="actions">
                                            <a>
                                                <mat-icon aria-hidden="false" aria-label="Editar"
                                                    [routerLink]="['/audit/audit/execute', {idAudit: item.classificationRiskAudit.idClassificationRiskAudit, idStatus: item.classificationRiskAudit.idStatus}]">
                                                    edit</mat-icon>
                                            </a>
                                            <a *ngIf="item.classificationRiskAudit.idStatus != concludedEnum">
                                                <mat-icon aria-hidden="false" aria-label="Editar"
                                                    (click)="openModal(item.classificationRiskAudit.idClassificationRiskAudit)">
                                                    delete_forever</mat-icon>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <app-loading-list *ngIf="isLoading"></app-loading-list>
                        <app-empty-list [message]="'Não há auditoria nesta coluna'" *ngIf="listAuditSecondAuditors != undefined 
                        && listAuditSecondAuditors.length == 0">
                        </app-empty-list>
                    </div>
                    <div class="card-column" cdkDropList #otherOpinion="cdkDropList"
                        [cdkDropListConnectedTo]="[divergence, concluded, secondAudit]"
                        [cdkDropListData]="listAuditOpinion" (cdkDropListDropped)="onDropThirdColumn($event)">
                        <h2>OUTRA OPINIÃO</h2>
                        <div class="card-list" *ngIf="listAuditOpinion != null">
                            <div class="row">
                                <div class="col-12" *ngFor="let item of listAuditOpinion">
                                    <div class="card-item" cdkDrag>
                                        <div class="actions more-horiz">
                                            <mat-icon aria-hidden="false" aria-label="Plus"
                                                (click)="openHistoryCardModal(item.classificationRiskAudit.idClassificationRiskAudit)">more_horiz</mat-icon>
                                        </div>
                                        <div class="infos">
                                            <span class="primary">
                                                {{item.classificationRiskAudit.idClassificationRiskAudit}} -
                                                {{item.classificationRiskAudit.datetimeInclusion | date: 'dd/MM/yyyy'}}
                                            </span>
                                            <span class="secundary">
                                                <span
                                                    matTooltip="{{item.firstAuditorName}} - {{item.firstAuditorProfession}}">
                                                    {{item.firstAuditorName}} - {{item.firstAuditorProfession}}
                                                </span>
                                            </span>
                                            <span class="secundary">
                                                <span
                                                    matTooltip="{{item.secondAuditorName}} - {{item.secondAuditorProfession}}">
                                                    {{item.secondAuditorName}} - {{item.secondAuditorProfession}}
                                                </span>
                                            </span>
                                            <div *ngIf="item.listClassificationRiskAuditOthers != null">
                                                <div *ngFor="let others of item.listClassificationRiskAuditOthers">
                                                    <span class="secundary"
                                                        *ngIf="others.idClassificationRiskAudit == item.classificationRiskAudit.idClassificationRiskAudit 
                                                        && others.userName != undefined && others.professionName != undefined">
                                                        <span
                                                            matTooltip="{{others.userName}} - {{others.professionName}}">
                                                            {{others.userName}} - {{others.professionName}},
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                            <span class="secundary">
                                                {{item.classificationRiskAudit.datetimeStart | date: 'MMM/yyyy'}}
                                                de {{item.classificationRiskAudit.datetimeStart | date: 'dd'}}
                                                à {{item.classificationRiskAudit.datetimeEnd| date: 'dd'}}
                                            </span>
                                            <span class="secundary">
                                                {{item.amount}} Classificações
                                            </span>
                                            <span class="secundary-status last-info">
                                                {{getStatusName(item.classificationRiskAudit.idStatus,
                                                item.firstAuditorName, item.secondAuditorName,
                                                item.otherUserAuditorName, item.datetimeConclusionFormated,
                                                item.classificationRiskAudit.idClassificationRiskAudit,
                                                item.numberOtherStatusAudit)}}
                                            </span>
                                        </div>
                                        <div class="actions">
                                            <a>
                                                <mat-icon aria-hidden="false" aria-label="Editar"
                                                    [routerLink]="['/audit/audit/execute', {idAudit: item.classificationRiskAudit.idClassificationRiskAudit}]">
                                                    edit</mat-icon>
                                            </a>
                                            <a *ngIf="item.classificationRiskAudit.idStatus != concludedEnum">
                                                <mat-icon aria-hidden="false" aria-label="Editar"
                                                    (click)="openModal(item.classificationRiskAudit.idClassificationRiskAudit)">
                                                    delete_forever</mat-icon>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <app-loading-list *ngIf="isLoading"></app-loading-list>
                        <app-empty-list [message]="'Não há auditoria nesta coluna'" *ngIf="listAuditOpinion != undefined 
                        && listAuditOpinion.length == 0">
                        </app-empty-list>
                    </div>
                    <div class="card-column" cdkDropList #divergence="cdkDropList"
                        [cdkDropListConnectedTo]="[otherOpinion, concluded]" [cdkDropListData]="listAuditDivergence"
                        (cdkDropListDropped)="onDropFourthColumn($event)">
                        <h2>DIVERGÊNCIA</h2>
                        <div class="card-list" *ngIf="listAuditDivergence != null">
                            <div class="row">
                                <div class="col-12" *ngFor="let item of listAuditDivergence">
                                    <div class="card-item" cdkDrag>
                                        <div class="actions more-horiz">
                                            <mat-icon aria-hidden="false" aria-label="Plus"
                                                (click)="openHistoryCardModal(item.classificationRiskAudit.idClassificationRiskAudit)">more_horiz</mat-icon>
                                        </div>
                                        <div class="infos">
                                            <span class="primary">
                                                {{item.classificationRiskAudit.idClassificationRiskAudit}} -
                                                {{item.classificationRiskAudit.datetimeInclusion | date: 'dd/MM/yyyy'}}
                                            </span>
                                            <span class="secundary">
                                                <span
                                                    matTooltip="{{item.firstAuditorName}} - {{item.firstAuditorProfession}}">
                                                    {{item.firstAuditorName}} - {{item.firstAuditorProfession}}
                                                </span>
                                            </span>
                                            <span class="secundary">
                                                <span
                                                    matTooltip="{{item.secondAuditorName}} - {{item.secondAuditorProfession}}">
                                                    {{item.secondAuditorName}} - {{item.secondAuditorProfession}}
                                                </span>
                                            </span>
                                            <div *ngIf="item.listClassificationRiskAuditOthers != null">
                                                <div *ngFor="let others of item.listClassificationRiskAuditOthers">
                                                    <span class="secundary"
                                                        *ngIf="others.idClassificationRiskAudit == item.classificationRiskAudit.idClassificationRiskAudit 
                                                        && others.userName != undefined && others.professionName != undefined">
                                                        <span
                                                            matTooltip="{{others.userName}} - {{others.professionName}}">
                                                            {{others.userName}} - {{others.professionName}},
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                            <span class="secundary">
                                                {{item.classificationRiskAudit.datetimeStart | date: 'MMM/yyyy'}}
                                                de {{item.classificationRiskAudit.datetimeStart | date: 'dd'}}
                                                à {{item.classificationRiskAudit.datetimeEnd| date: 'dd'}}
                                            </span>
                                            <span class="secundary">
                                                {{item.amount}} Classificações
                                            </span>
                                            <span class="secundary-status last-info">
                                                {{getStatusName(item.classificationRiskAudit.idStatus,
                                                item.firstAuditorName, item.secondAuditorName,
                                                item.otherUserAuditorName, item.datetimeConclusionFormated,
                                                item.classificationRiskAudit.idClassificationRiskAudit,
                                                item.numberOtherStatusAudit)}}
                                            </span>
                                        </div>
                                        <div class="actions">
                                            <a>
                                                <mat-icon aria-hidden="false" aria-label="Editar"
                                                    [routerLink]="['/audit/audit/execute', {idAudit: item.classificationRiskAudit.idClassificationRiskAudit}]">
                                                    edit</mat-icon>
                                            </a>
                                            <a *ngIf="item.classificationRiskAudit.idStatus != concludedEnum">
                                                <mat-icon aria-hidden="false" aria-label="Editar"
                                                    (click)="openModal(item.classificationRiskAudit.idClassificationRiskAudit)">
                                                    delete_forever</mat-icon>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <app-loading-list *ngIf="isLoading"></app-loading-list>
                        <app-empty-list [message]="'Não há auditoria nesta coluna'" *ngIf="listAuditDivergence != undefined 
                        && listAuditDivergence.length == 0">
                        </app-empty-list>
                    </div>
                    <div class="card-column" cdkDropList #concluded="cdkDropList" [cdkDropListData]="listAuditConcluded"
                        (cdkDropListDropped)="onDropFifthColumn($event)">
                        <h2>CONCLUÍDO</h2>
                        <div class="card-list" *ngIf="listAuditConcluded != null">
                            <div class="row">
                                <div class="col-12" *ngFor="let item of listAuditConcluded">
                                    <div class="card-item">
                                        <div class="actions more-horiz">
                                            <mat-icon aria-hidden="false" aria-label="Plus"
                                                (click)="openHistoryCardModal(item.classificationRiskAudit.idClassificationRiskAudit)">more_horiz</mat-icon>
                                        </div>
                                        <div class="infos">
                                            <span class="primary">
                                                {{item.classificationRiskAudit.idClassificationRiskAudit}} -
                                                {{item.classificationRiskAudit.datetimeInclusion | date: 'dd/MM/yyyy'}}
                                            </span>
                                            <span class="secundary">
                                                <span
                                                    matTooltip="{{item.firstAuditorName}} - {{item.firstAuditorProfession}}">
                                                    {{item.firstAuditorName}} - {{item.firstAuditorProfession}}
                                                </span>
                                            </span>
                                            <span class="secundary">
                                                <span
                                                    matTooltip="{{item.secondAuditorName}} - {{item.secondAuditorProfession}}">
                                                    {{item.secondAuditorName}} - {{item.secondAuditorProfession}}
                                                </span>
                                            </span>
                                            <div *ngIf="item.listClassificationRiskAuditOthers != null">
                                                <div *ngFor="let others of item.listClassificationRiskAuditOthers">
                                                    <span class="secundary"
                                                        *ngIf="others.idClassificationRiskAudit == item.classificationRiskAudit.idClassificationRiskAudit 
                                                        && others.userName != undefined && others.professionName != undefined">
                                                        <span
                                                            matTooltip="{{others.userName}} - {{others.professionName}}">
                                                            {{others.userName}} - {{others.professionName}},
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                            <span class="secundary">
                                                {{item.classificationRiskAudit.datetimeStart | date: 'MMM/yyyy'}}
                                                de {{item.classificationRiskAudit.datetimeStart | date: 'dd'}}
                                                à {{item.classificationRiskAudit.datetimeEnd| date: 'dd'}}
                                            </span>
                                            <span class="secundary">
                                                {{item.amount}} Classificações
                                            </span>
                                            <span class="secundary-status last-info"
                                                *ngIf="item.datetimeConclusionFormated != null">
                                                {{getStatusName(item.classificationRiskAudit.idStatus,
                                                item.firstAuditorName, item.secondAuditorName,
                                                item.otherUserAuditorName, item.datetimeConclusionFormated,
                                                item.classificationRiskAudit.idClassificationRiskAudit,
                                                item.numberOtherStatusAudit)}}
                                            </span>
                                            <span class="secundary-status last-info"
                                                *ngIf="item.datetimeConclusionFormated == null">
                                                {{getStatusName(item.classificationRiskAudit.idStatus,
                                                item.firstAuditorName, item.secondAuditorName,
                                                item.otherUserAuditorName,
                                                item.classificationRiskAudit.datetimeConclusion | date: 'dd/MM/yyyy',
                                                item.classificationRiskAudit.idClassificationRiskAudit,
                                                item.numberOtherStatusAudit)}}
                                            </span>
                                        </div>
                                        <div class="actions">
                                            <a>
                                                <mat-icon aria-hidden="false" aria-label="Editar"
                                                    [routerLink]="['/audit/audit/execute', {idAudit: item.classificationRiskAudit.idClassificationRiskAudit}]">
                                                    edit</mat-icon>
                                            </a>
                                            <a *ngIf="item.classificationRiskAudit.idStatus != concludedEnum">
                                                <mat-icon aria-hidden="false" aria-label="Editar"
                                                    (click)="openModal(item.classificationRiskAudit.idClassificationRiskAudit)">
                                                    delete_forever</mat-icon>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <app-loading-list *ngIf="isLoading"></app-loading-list>
                        <app-empty-list [message]="'Não há auditoria nesta coluna'" *ngIf="listAuditConcluded != undefined 
                        && listAuditConcluded.length == 0">
                        </app-empty-list>
                    </div>
                </div>
                <app-loading-list *ngIf="isLoading"></app-loading-list>
            </div>
        </div>
    </div>
</div>