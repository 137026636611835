<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <a class="back-link" routerLink="/master/profile">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Usuários
        </a>
        <h1>Cadastrar Totem</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="title-header">
                    <h1>Dados do totem</h1>
                </div>

                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome</mat-label>
                            <input matInput type="text" formControlName="totemName">
                            <mat-error *ngIf="model.get('totemName').invalid">Informe o Nome</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Descrição</mat-label>
                            <input matInput type="text" formControlName="description">
                        </mat-form-field>
                    </div>
                    <div class="box-toggle col-12 col-sm-6 col-md-4">
                        <mat-slide-toggle formControlName="useQrCode" checked="useQrCode">Imprimir QR Code
                        </mat-slide-toggle>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome da Impressora</mat-label>
                            <input matInput type="text" formControlName="printerName">
                            <mat-error *ngIf="model.get('printerName').invalid">Informe o Nome da Impressora</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Status</mat-label>
                            <mat-select formControlName="isActive">
                                <mat-option value="true">Ativo</mat-option>
                                <mat-option value="false">Inativo</mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('isActive').invalid">Informe o Status</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Prioridades exibidas</mat-label>
                            <mat-select formControlName="listIdPriority" multiple>
                                <mat-option value="{{item.idPriority}}" *ngFor="let item of listPriority">
                                    {{item.priorityName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="title-header">
                    <h1>Botões Totem</h1>
                </div>
                <div formArrayName="listButtons"
                    *ngFor="let item of model.get('listButtons')['controls']; let i = index">
                    <div class="row" [formGroupName]="i">
                        <div class="col-2 col-sm-2 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Nome do Botão</mat-label>
                                <input matInput type="text" formControlName="buttonName">
                                <mat-error *ngIf="item.get('buttonName').invalid">Informe o Nome do Botão</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-1 col-sm-1 col-md-1">
                            <mat-form-field appearance="outline">
                                <mat-label>Sigla</mat-label>
                                <input matInput type="text" formControlName="initials">
                                <mat-error *ngIf="item.get('initials').invalid">Informe a Sigla</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-2 col-sm-2 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Sigla Prioritária</mat-label>
                                <input matInput type="text" formControlName="priorityInitials">
                                <mat-error *ngIf="item.get('priorityInitials').invalid">Informe a Sigla Prioritária
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-3 col-sm-3 col-md-3 care-line-priority"
                            *ngIf="this.listCareLinePriority.length > 0">
                            <mat-form-field appearance="outline">
                                <mat-label>Linha de Cuidado em prioridade</mat-label>
                                <mat-select formControlName="idCareLinePriority">
                                    <mat-option>Nenhum</mat-option>
                                    <mat-option *ngFor="let item of listCareLinePriority"
                                        [value]="item.idCareLinePriority">
                                        {{item.careLinePriorityName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-3 col-sm-2 col-md-2">
                            <span>É para pacientes agendados?<mat-slide-toggle formControlName="isScheduleToggle"
                                    checked="isScheduleToggle">
                                </mat-slide-toggle></span>
                        </div>
                        <div class="col-2 col-sm-2 col-md-2">
                            <button class="totem-button " color="primary" mat-mini-fab type="button"
                                (click)="addNext()">
                                <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
                            </button>
                            <button class="totem-button " color="primary" mat-mini-fab type="button"
                                (click)="removeButton(i)" *ngIf="i != 0">
                                <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <a mat-flat-button type="button" routerLink="/master/totem" color="accent" class=" btn-block">
                            Cancelar
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>

</div>