import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EpisodeStatusEnum } from 'src/app/shared/enum/episode-status.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { EpisodeReleaseService } from 'src/app/shared/services/API/orchestrator-patient/episode-release.service';
import { PresenceReportService } from 'src/app/shared/services/API/orchestrator-patient/presence-report.service';
import { PostPresenceReportRequest } from 'src/app/shared/services/requests/orchestrator-patient/post-presence-report.request';
import { ReleaseEpisodeRequest } from 'src/app/shared/services/requests/orchestrator-patient/release-episode.request';
import { ReleaseBedEpisodeModalComponent } from '../release-bed-episode-modal/release-bed-episode-modal.component';

@Component({
  selector: 'app-episode-release-confirm-modal',
  templateUrl: './episode-release-confirm-modal.component.html',
  styleUrls: ['./episode-release-confirm-modal.component.css']
})
export class EpisodeReleaseConfirmModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<EpisodeReleaseConfirmModalComponent>,
  private alertService: AlertService,
  private presenceReportService: PresenceReportService,
  private episodeReleaseService: EpisodeReleaseService,
  ) { }

  public idStatusEpisodeInternar:number = EpisodeStatusEnum.FinalizadoInternar.valueOf();
  public idStatusEpisodeAlta:number = EpisodeStatusEnum.FinalizadoAlta.valueOf();
  public idStatusEpisodeAltaAdministrativa:number = EpisodeStatusEnum.FinalizadoAltaAdministrativa.valueOf();
  public isLoading:boolean;

  ngOnInit(): void {
    this.isLoading = false;
  }
  
  close(){
    this.matDialogRef.close(); 
  }
  
  clickConfirm(){

    this.isLoading = true;

    if(!this.data.observationConfigSector.observationConfig.isReleasable){
      this.alertService.show('Erro', "Este setor não tem permissão para liberar pacientes.", AlertType.error);
        this.isLoading = false;
        return;
    }

    if(this.data.idBed){
      this.releaseBedModal();
    }
    else{
      this.episodeRelease();
    }
  }
  
  clickCancel(){
    this.matDialogRef.close(); 
  }

  releaseBedModal(){
    const dialogRef = this.dialog.open(ReleaseBedEpisodeModalComponent, {
      data: {
        episode: this.data.episode,
        idStatus: EpisodeStatusEnum.FinalizadoAlta.valueOf(),
        observationConfigSector: this.data.observationConfigSector,
        idService: this.data.idService,
        idBed: this.data.idBed,
        idSector: this.data.idSector,
      },
    });    
    dialogRef.afterClosed().subscribe(result => {
      this.isLoading = false;
      if(result && result.release){
        this.matDialogRef.close({release: true});
      }
    });
  }

  episodeRelease(){
    this.isLoading = true;   
    let releaseEpisodeRequest: ReleaseEpisodeRequest = new ReleaseEpisodeRequest();

    releaseEpisodeRequest.idEpisode = this.data.episode.idEpisode;
    releaseEpisodeRequest.idSector = this.data.idSector;
    releaseEpisodeRequest.idService = this.data.idService;
    releaseEpisodeRequest.idEpisodeAdministrativeRelease = this.data.episode.idEpisodeAdministrativeRelease;
    releaseEpisodeRequest.isLiberateBed = false;

    this.episodeReleaseService.episodeRelease(releaseEpisodeRequest).subscribe((response)=>{      
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.alertService.show('Sucesso', "Episódio liberado com sucesso!", AlertType.success);

      let postPresenceReportRequest = new PostPresenceReportRequest()
      if (this.data.episode != null) {
        postPresenceReportRequest.idStatus = this.data.episode.idStatus;
        postPresenceReportRequest.idEpisode = this.data.episode.idEpisode;

        this.openCreateStatementOfAttendance(postPresenceReportRequest);
      }
      
      this.isLoading = false;
      this.matDialogRef.close({release: true}); 
    },
    (error)=>{
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  openCreateStatementOfAttendance(postPresenceReportRequest: PostPresenceReportRequest) {
    this.presenceReportService.postReportPDF(postPresenceReportRequest).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      });
  }
}