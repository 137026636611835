import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { PrescriptionNonDispensationService } from 'src/app/shared/services/API/medical-record/prescription-non-dispensation.service';
import { CallEpisodePrescriptionService } from 'src/app/shared/services/API/orchestrator-patient/call-episode-prescription.service';
import { LocationService } from 'src/app/shared/services/API/pharmacy/location.service';
import { MaterialNonDispensationService } from 'src/app/shared/services/API/pharmacy/material-non-dispensation.service';
import { MedicinePresentationTypeService } from 'src/app/shared/services/API/pharmacy/medicine-presentation-type.service';
import { VerifyStorageService } from 'src/app/shared/services/API/pharmacy/verify-storage.service';
import { RequestMaterialStruct } from 'src/app/shared/services/models/pharmacy/request-material.model';
import { MaterialNonDispensationRequest } from 'src/app/shared/services/requests/pharmacy/material-non-dispensation.request';
import { EpisodePrescriptionModalComponent } from '../episode-prescription-modal/episode-prescription-modal.component';

@Component({
  selector: 'app-material-non-dispensation-modal',
  templateUrl: './material-non-dispensation-modal.component.html',
  styleUrls: ['./material-non-dispensation-modal.component.css']
})
export class MaterialNonDispensationModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialog: MatDialog,
  public matDialogRef: MatDialogRef<EpisodePrescriptionModalComponent>,
  public verifyStorageService: VerifyStorageService,
  private alertService: AlertService,
  private formBuilder: UntypedFormBuilder,
  public locationService: LocationService,
  public callEpisodePrescriptionService: CallEpisodePrescriptionService,
  public medicinePresentationTypeService: MedicinePresentationTypeService,
  public materialNonDispensationService: MaterialNonDispensationService,
  
  ) { }

  public model: UntypedFormGroup;
  public isLoading: boolean;
  public idRequestMaterial: number;

  ngOnInit(): void {
    this.isLoading = true;
    this.idRequestMaterial = this.data.material.idRequestMaterial;

    this.model = this.formBuilder.group({
      patientName:[{value: this.data.patientName, disabled: true}],
      nonDispensationReason: ['', [Validators.required]],
    })
    this.isLoading = false;
  }

  submit() {
    if (this.isLoading || this.model.invalid){
      return;
    }
    let nonDispensationRequest: MaterialNonDispensationRequest = new MaterialNonDispensationRequest();
    
    nonDispensationRequest.idRequestMaterial = this.idRequestMaterial;
    nonDispensationRequest.nonDispensationReason = this.model.get('nonDispensationReason').value;

    this.materialNonDispensationService.nonDispensation(nonDispensationRequest).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.isLoading = false;
      this.alertService.show('Sucesso', "Pedido não dispensado!", AlertType.success);
      this.matDialogRef.close({notDispensed: true});
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
    }
    clickCancel() {
      this.matDialogRef.close({notDispensed: false});
    }
}
