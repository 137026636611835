import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { IframeTypeEnum } from 'src/app/shared/enum/iframe-type.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { AccessKeyService } from 'src/app/shared/services/API/integration/access-key.service';
import { ValidationAccessKeyService } from 'src/app/shared/services/API/integration/validation-access-key.service';
import { ClientPanelService } from 'src/app/shared/services/API/panel/client-panel.service';
import { KeepAliveService } from 'src/app/shared/services/API/panel/keep-alive.service';
import { HealthUnitTimeService } from 'src/app/shared/services/API/user/health-unit-time.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { GetPanelResponse } from 'src/app/shared/services/responses/panel/get-panel.response';
import { UserLoginResponse } from 'src/app/shared/services/responses/user/auth.response';
import { UtilsClientService } from '../../utils.service';
import { ValidatorsModalComponent } from './modals/validators-modal/validators-modal.component';
import { UtilsIframeService } from './util-iframe.service';

@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.css']
})
export class IframeComponent implements OnInit {


  constructor(private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private utilsIframeService: UtilsIframeService,
    private validationAccessKeyService: ValidationAccessKeyService,
    private authService: AuthService) { }

  public telemedicineEnum: IframeTypeEnum = IframeTypeEnum.telemedicine;
  public type: number;

  validateType(type: number): boolean {
    if (Number.isNaN(type)) {
      return false;
    }
    const arr = Object.keys(IframeTypeEnum).map((name) => {
      return {
        name,
        value: IframeTypeEnum[name as keyof typeof IframeTypeEnum],
      };
    });
    return arr.findIndex(c => c.value == type) >= 0;
  }

  ngOnInit(): Promise<void> {
    let type = parseInt(this.activatedRoute.snapshot.paramMap.get('type'));
    if (!this.validateType(type)) {
      this.utilsIframeService.openModalError("Tipo de iframe selecionado não existe!");
      return;
    }
    let token = this.activatedRoute.snapshot.paramMap.get('token');
    if (!token) {
      this.utilsIframeService.openModalError("É obrigatório passar a token como parâmetro!");
      return;
    }
    this.validateToken(token, type);
  }

  validateToken(token: string, type: number) {
    this.validationAccessKeyService.validate(token).subscribe({
      next: (response) => {
        if (response.isError) {
          this.utilsIframeService.openModalError(`Erro ao validar a token: ${response.errorDescription}`);
          return;
        }
        this.type = type;
        let result: UserLoginResponse = new UserLoginResponse();
        result.token = response.token;
        this.authService.updateToken(result);
      }, error: (error) => {
        this.utilsIframeService.openModalError("Erro inesperado: " + error);
        return;
      }
    });
  }
}