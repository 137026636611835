import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { BiLookupResponse } from '../../responses/business-intelligence/bi-lookup.response';
import { LookupSendReportResponse } from '../../responses/business-intelligence/lookup-send-report.response';
import { FavoriteHealthUnitSelectionResponse } from '../../responses/business-intelligence/favorite-health-unit.response';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { FavoriteHealthUnitSelectionRequest } from '../../requests/business-intelligence/favorite-health-unit.request';
import { CreateFavoriteResponse } from '../../responses/business-intelligence/create-favorite-health-unit.response';

@Injectable({
    providedIn: 'root'
})
export class FavoriteHealthUnitService extends CommonService {

    constructor(private router: Router, private httpClient: HttpClient) {
        super();
    }

    public listFavorite(): Observable<FavoriteHealthUnitSelectionResponse> {

        let uri = 'FavoriteHealthUnit'

        return this.httpClient.get<FavoriteHealthUnitSelectionResponse>(environment.urlApiBusinessIntelligence + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }

    public createFavorite(request: FavoriteHealthUnitSelectionRequest): Observable<CreateFavoriteResponse> {
    

        let uri = 'FavoriteHealthUnit'

        return this.httpClient.post<CreateFavoriteResponse>(environment.urlApiBusinessIntelligence + uri, request, this.addHeaderToken())
        
            .pipe(
                catchError(this.handleError)
            )
    }

    public deleteFavorite(idFavorite: number): Observable<ReturnStruct> {

        let uri = `FavoriteHealthUnit/idFavoriteHealthUnitSelection/${idFavorite}`

        return this.httpClient.delete<ReturnStruct>(environment.urlApiBusinessIntelligence + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }
}