<div mat-dialog-content>
    <div class="mat-dialog-header">
        <h3>Pedido de exame</h3>
    </div>
    <form class="form" [formGroup]="sadtModel" (ngSubmit)="onSubmit()">
        <div class="mat-dialog-content">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Local</mat-label>
                        <mat-select (selectionChange)="onSelectPlaceExternal()" formControlName="idPlace">
                            <mat-option *ngFor="let item of listPlace" [value]="item.idPlace">{{item.placeName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Tipo de Exame</mat-label>
                        <mat-select (selectionChange)="onSelectExamType()" formControlName="idTypeSadt">
                            <mat-option *ngFor="let item of listExamTypeDisplay" [value]="item.idExamType">
                                {{item.examTypeName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="this.isInternal" class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Horário</mat-label>
                        <mat-select formControlName="idSadtSchedule">
                            <mat-option *ngFor="let item of listSadtSchedule" [value]="item.idSadtSchedule">{{item.sadtScheduleName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="this.idSadt !== this.sadtOthers && listExamDisplay.length > 0" class="row">
                <div class="col-9 col-sm-9 col-md-9">
                    <app-select-2 [formControlField]="'idSadt'" [multiple]="true" [list]="listExamDisplay"
                        [idField]="'idExam'" [nameField]="'examName'" [labelField]="'Exame'"
                        [preSelectedlist]="this.listSelectedExamDisplay" (selectedValue)="selectedValue($event)">
                    </app-select-2>
                </div>
                <div class="col-3 col-sm-3 col-md-3">
                    <div class="form-field-toggle">
                        <mat-label>Todos</mat-label>
                        <mat-slide-toggle [(ngModel)]="this.isAllExams" (change)="onToggleChange($event)"
                            [ngModelOptions]="{standalone: true}"></mat-slide-toggle>
                    </div>
                </div>
            </div>
            <div *ngIf="this.idSadt === this.sadtOthers" class="row">
                <div class="col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Descreva o exame</mat-label>
                        <textarea [readonly]="this.data.readOnly" matInput formControlName="sadtDescription"></textarea>
                    </mat-form-field>
                </div>
            </div>

            <div class="footer-panel-modal-list">
                <div class="row">
                    <div class="col-6">
                        <a *ngIf="!this.data.readOnly" mat-flat-button color="accent" class="btn-block"
                            (click)="clickCancel()">Cancelar</a>
                    </div>
                    <div *ngIf="!this.data.readOnly" class="col-6">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span>Salvar</span>
                        </button>
                    </div>
                    <div class="col-12">
                        <a *ngIf="this.data.readOnly" mat-flat-button color="primary" class="btn-block"
                            (click)="clickCancel()">Ok</a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>