<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <div class="page-title">
            <div>
                <a class="back-link" routerLink="/master">
                    <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Módulo Master
                </a>
                <h1>Resultado das avaliações</h1>
            </div>
        </div>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>

        <div class="white-body" *ngIf="!isFirstLoading">
            <div class="row">
                <div class="col-12 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Exibir resultados de</mat-label>
                        <mat-select [(ngModel)]="idNpsType" (selectionChange)="selectTypeNps()">
                            <mat-option (ngValue)="null">Todos</mat-option>
                            <mat-option [value]="typeSMS">SMS</mat-option>
                            <mat-option [value]="typeTotem">Totem</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-12 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Gatilho de disparo</mat-label>
                        <mat-select [(ngModel)]="idMessageFiringTrigger" (selectionChange)="searchDashboardData()"
                            [disabled]="disableFiringTrigger">
                            <mat-option (ngValue)="null">Todos</mat-option>
                            <mat-option *ngFor="let item of listMessageFiringTrigger"
                                [value]="item.idMessageFiringTrigger">
                                {{item.messageFiringTriggerName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <section>
                <div class="graphs row">
                    <div class="graph-nps">
                        <div class="chart-linear">
                            <div class="title-charts-nps" *ngIf="!isLoading">
                                <h2>NPS</h2>
                                <h1 [ngStyle]="{'color': this.colorGraphNps.domain[0]}">{{this.dashboardData.nps}}</h1>
                            </div>

                            <!-- <ngx-charts-linear-gauge *ngIf="this.renderGraph"
                                [scheme]="colorGraphPromoter"
                                min="-100"
                                max="100"
                                previousValue="0"
                                [value]="this.dashboardData.nps"
                                units="NPS">
                            </ngx-charts-linear-gauge> -->

                            <!-- <ngx-charts-pie-grid *ngIf="this.renderGraph"
                                [scheme]="colorGraphNps"
                                [results]="noteGraphNPS"
                                [designatedTotal]="100">
                            </ngx-charts-pie-grid> -->

                            <!-- <ngx-charts-bar-vertical
                                [scheme]="colorGraphPierGrid"
                                [results]="noteNPS"
                                [gradient]="gradient"
                                [xAxis]="true"
                                [yAxis]="true"
                                [legend]="false"
                                [showXAxisLabel]="false"
                                [showYAxisLabel]="false"
                                showDataLabel="true"
                                [showGridLines]="true"
                                [roundEdges]="true"
                                tooltipDisabled="true"
                                [noBarWhenZero]="false"
                                yScaleMax="100"
                                yScaleMin="-100">
                            </ngx-charts-bar-vertical> -->
                            <app-loading-list *ngIf="isLoading"></app-loading-list>
                        </div>
                        <div class="legend-nps">
                            <ul>
                                <li class="critical-zone">
                                    Zona Crítica | <span>-100 a 0</span>
                                </li>
                                <li class="neutral-zone">
                                    Zona de Aperfeiçoamento | <span>1 a 50</span>
                                </li>
                                <li class="good-zone">
                                    Zona de Qualidade | <span>51 a 75</span>
                                </li>
                                <li class="good-zone">
                                    Zona de Excelência | <span>76 a 90</span>
                                </li>
                                <li class="excellent-zone">
                                    Zona de Encatamento | <span>91 a 100</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="charts-horizontal line-left">
                        <ngx-charts-pie-grid *ngIf="this.renderGraph" [scheme]="colorGraphPierGrid"
                            [results]="notesGraphPierGrid">
                        </ngx-charts-pie-grid>
                        <app-loading-list *ngIf="isLoading"></app-loading-list>

                        <p>NPS = %Promotores - %Detratores</p>
                    </div>
                    <div class="chart-vertical line-left">
                        <ngx-charts-bar-vertical *ngIf="this.renderGraph" [scheme]="colorGraphVertical"
                            [results]="notesGraphVertical" [xAxis]="true" [yAxis]="false" [legend]="false"
                            showXAxisLabel="false" showYAxisLabel="false" showDataLabel="true" [showGridLines]="false"
                            [roundEdges]="true" tooltipDisabled="true" [noBarWhenZero]="false">
                        </ngx-charts-bar-vertical>
                        <app-loading-list *ngIf="isLoading"></app-loading-list>
                    </div>
                </div>
            </section>
            <section>

                <div class="nps-description row">
                    <div class="nps-description-single" [ngClass]="{'invisible': this.idNpsType == this.typeTotem}">
                        <div class="nps-description-img">
                            <img src="assets/images/nps/msg.png" />
                        </div>
                        <div class="nps-description-txt">
                            <h3>Total de disparos</h3>
                            <h1>{{this.dashboardData.totalShooting}}</h1>
                        </div>
                    </div>
                    <div class="nps-description-single line-left">
                        <div class="nps-description-img">
                            <img src="assets/images/nps/group.png" />
                        </div>
                        <div class="nps-description-txt">
                            <h3>Respostas</h3>
                            <h1>{{this.dashboardData.totalAnswer}}</h1>
                        </div>
                    </div>
                    <div class="nps-description-single line-left">
                        <div class="nps-description-img">
                            <img src="assets/images/nps/smile.png" />
                        </div>
                        <div class="nps-description-txt">
                            <h3>Promotores</h3>
                            <p>{{this.dashboardData.praiser}}</p>
                            <p><b>{{this.dashboardData.praiserByPercent}}%</b></p>
                        </div>
                    </div>
                    <div class="nps-description-single line-left">
                        <div class="nps-description-img">
                            <img src="assets/images/nps/neutral.png" />
                        </div>
                        <div class="nps-description-txt">
                            <h3>Neutros</h3>
                            <p>{{this.dashboardData.neutral}}</p>
                            <p><b>{{this.dashboardData.neutralByPercent}}%</b></p>
                        </div>
                    </div>
                    <div class="nps-description-single line-left">
                        <div class="nps-description-img">
                            <img src="assets/images/nps/sad.png" />
                        </div>
                        <div class="nps-description-txt">
                            <h3>Detratores</h3>
                            <p>{{this.dashboardData.detractor}}</p>
                            <p><b>{{this.dashboardData.detractorByPercent}}%</b></p>
                        </div>
                    </div>
                    <div class="nps-description-single line-left">
                        <div class="nps-description-img">
                            <img src="assets/images/nps/graph.png" />
                        </div>
                        <div class="nps-description-txt">
                            <h3>NPS</h3>
                            <h1>{{this.dashboardData.nps}}</h1>
                        </div>
                    </div>

                </div>
            </section>
        </div>

        <div class="page-title">
            <h1>Comentários</h1>
        </div>
        <div class="second-container">
            <div class="row">
                <div class="col-12">
                    <div class="card-list"
                        *ngIf="listCommentCollection && listCommentCollection.length > 0 && listCommentCollection != null ">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listCommentCollection">
                                <div class="card-item">
                                    <div class="infos">
                                        <span class="primary">{{item.note}}</span>
                                        <span class="secundary">{{item.commentsCollection}}</span>
                                        <span class="secundary last-item">{{item.datetimeAnswer | date: 'dd/MMM/yyyy
                                            HH:mm:ss'}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>