import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-delete-aih-group-modal',
  templateUrl: './delete-aih-group-modal.component.html',
  styleUrls: ['./delete-aih-group-modal.component.css']
})
export class DeleteAihGroupModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<DeleteAihGroupModalComponent>,
    private alertService: AlertService,
    private formBuilder: UntypedFormBuilder,) { }

  public isLoading: boolean;
  public model: UntypedFormGroup;

  ngOnInit(): void {
    this.isLoading = false;

    this.model = this.formBuilder.group({
      deletedMotive: ['', [Validators.required]]
    });
  }

  close() {
    if (!this.isLoading)
      this.matDialogRef.close();
  }

  submit() {
    if (this.isLoading) {
      return;
    }
    if(!this.model.valid){
      this.alertService.show('Erro', 'A razão deve ser preenchida.', AlertType.error);
      return;
    }
    
    this.isLoading = true;

    let deletedMotive = this.model.get('deletedMotive').value;

    this.matDialogRef.close({ isDeleted: true,  deletedMotive: deletedMotive});
  }

  clickCancel() {
    if (!this.isLoading)
      this.matDialogRef.close();
  }
}

