import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MedicFavoriteNonStandardPrescriptionService } from "src/app/shared/services/API/medic/medic-favorite-non-standard-prescription.service";
import { AlertService, AlertType } from "src/app/shared/services/alert.service";

@Component({
  selector: 'app-medic-favorite-non-standard-delete-modal',
  templateUrl: './medic-favorite-non-standard-delete-modal.component.html',
  styleUrls: ['./medic-favorite-non-standard-delete-modal.component.css']
})
export class MedicFavoriteNonStandardDeleteModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<MedicFavoriteNonStandardDeleteModalComponent>,
    private medicFavoriteNonStandardPrescriptionService: MedicFavoriteNonStandardPrescriptionService,
    private alertService: AlertService,
  ) { }

  public isLoading: boolean = false;

  ngOnInit(): void {
  }

  close() {
    this.matDialogRef.close({ isDeleted: false });
  }

  clickDelete() {
    this.isLoading = true;

    this.medicFavoriteNonStandardPrescriptionService.deleteMedicFavoriteNonStandardPrescription(this.data.idMedicFavoriteNonStandardPrescription).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Favorito excluído com sucesso!", AlertType.success);
        this.matDialogRef.close({ isDeleted: true });
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  clickCancel() {
    this.matDialogRef.close({ isDeleted: false });
  }
}