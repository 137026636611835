import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { FowardService } from 'src/app/shared/services/API/orchestrator-queue/foward.service';
import { ObservationForwardService } from 'src/app/shared/services/API/orchestrator-queue/observation-foward.service';
import { GenerateQueueRequest } from 'src/app/shared/services/requests/orchestrator-queue/generate-queue.request';
import { SelectedLocationRequest } from 'src/app/shared/services/requests/orchestrator-queue/selected-location.request';

@Component({
  selector: 'app-observation-forward-bed-confirm-modal',
  templateUrl: './observation-forward-bed-confirm-modal.component.html',
  styleUrls: ['./observation-forward-bed-confirm-modal.component.css']
})
export class ObservationForwardBedConfirmModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ObservationForwardBedConfirmModalComponent>,
    private alertService: AlertService,
    public fowardService: FowardService,
    public observationForwardService: ObservationForwardService,
  ) { }

  public isLoading: boolean;
  public selectedLocationRequest: SelectedLocationRequest
  public idHealthUnit: number;
  public idEpisode: number;

  ngOnInit(): void {
    this.isLoading = false;
    this.selectedLocationRequest = new SelectedLocationRequest();
    this.idEpisode = this.data.idEpisode;
    this.selectedLocationRequest = this.data.selectedLocationRequest;
  }

  close() {
    this.matDialogRef.close({ fowardPatient: false });
  }

  clickConfirm(isLiberateBed: boolean = null) {

    if(isLiberateBed){
      this.selectedLocationRequest.isLiberateBed = true;
    }
    
    this.fowardPatient(this.selectedLocationRequest);
  }

  clickCancel() {
    this.matDialogRef.close({ fowardPatient: false });
  }

  fowardPatient(body: SelectedLocationRequest) {
    if(this.isLoading)
      return;
    this.isLoading = true;
    if (body.idQueue) {
      this.observationForwardService.fowardPatient(body).subscribe((response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;

        if(response.errorCode == 4){
          this.alertService.show('Aviso', response.errorDescription, AlertType.warning);
        }
        else{
          this.alertService.show('Sucesso', "Paciente encaminhado com sucesso", AlertType.success);
        }

        this.matDialogRef.close({ fowardPatient: true });
      },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
    } 
  }
}

