import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { RequestMaterialService } from 'src/app/shared/services/API/pharmacy/request-material.service';


@Component({
  selector: 'app-bed-management-material-history-delete-modal.component',
  templateUrl: './bed-management-material-history-delete-modal.component.html',
  styleUrls: ['./bed-management-material-history-delete-modal.component.css']
})
export class BedManagementMaterialHistoryDeleteModal  implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<BedManagementMaterialHistoryDeleteModal>,
  public requestMaterialService: RequestMaterialService,
  private alertService: AlertService,) { }


  public isLoading:boolean;  
  
  ngOnInit(): void {
    this.isLoading = false;
  }
  
  close(){
    this.matDialogRef.close(); 
  }
  
  clickDelete(){
    this.requestMaterialService.updateToDelete(this.data.idRequestMaterial).subscribe((response)=>{
      
      this.alertService.show('Sucesso', "Solicitação excluida com sucesso!", AlertType.success); 
      },
      (error)=>{
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }); 
      this.matDialogRef.close(); 

  }
  
  clickCancel(){
    this.matDialogRef.close(); 
  }
}