<div class="mat-dialog-content">
    <div mat-dialog-title>
        <div class="head-select-room-modal-list">
            <h2>Selecione um favorito</h2>
            <mat-icon (click)="clickCancel()" class="close-modal">close</mat-icon>
        </div>
    </div>
    <div mat-dialog-content>
        <div class="body-select-room-modal-list">
            <div class="filters">
                <div class="row">
                    <mat-form-field appearance="outline">
                        <mat-icon matPrefix class="my-icon">search</mat-icon>
                        <mat-label>Pesquisar</mat-label>
                        <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText"
                            (keyup)="onKeySearch()">
                    </mat-form-field>
                </div>
            </div>
            <app-loading-list *ngIf="isLoading"></app-loading-list>
            <div class="body-select-room-modal-list__options">
                <div mat-flat-button color="accent" class="body-select-room-modal-list__options--buttons"
                    [ngClass]="{'selected': item.idFavoriteHealthUnitSelection === idCurrentFavorite}"
                    (click)="submit(item)" *ngFor="let item of listFavoriteDisplay; let i = index">
                    <span class="option-name truncate">{{item.favoriteName}}</span>
                    <div class="priority-click">
                        <mat-icon matTooltip="Excluir favorito" (click)="deleteFavorite(item, $event)"
                            class="option-icon">close</mat-icon>
                        <mat-icon *ngIf="false" (mouseenter)="populateNames(i)" class="hoverable-icon">list</mat-icon>
                        <div *ngIf="false" (click)="stop($event)" class="health-unit-display">
                            <ul class="name-list">
                                <li *ngFor="let item of listHealthUnitDisplay; let j = index">
                                    {{item.healthUnitName}} - {{j}}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>