<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <h1>Classificações por Telefone</h1>
        <div class="white-body">
            <form class="form" [formGroup]="model" (ngSubmit)="buttonSearch()">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Data Inicial</mat-label>
                            <input matInput [mask]="masks.date" type="text" formControlName="initialDate">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Data Final</mat-label>
                            <input matInput [mask]="masks.date" type="text" formControlName="finalDate">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Gravidade</mat-label>
                            <mat-select formControlName="idGravity">
                                <mat-option></mat-option>
                                <mat-option *ngFor="let item of listGravity" [value]="item.idGravity">
                                    {{item.gravityName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <button mat-flat-button color="primary" class=" btn-block" type="submit">
                            <span *ngIf="!isLoading">Pesquisar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <a mat-flat-button type="button" routerLink="/classification/telephone-classification/register"
                            color="primary" class=" btn-block">
                            Nova Classificação
                        </a>
                    </div>
                </div>
            </form>
            <div class="card-list" *ngIf="listTriage != null">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listTriage">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary">{{item.patient?item.patient.patientName:"Paciente
                                    Desconhecido"}}</span>
                                <span class="secundary">Data de Início: {{item.startClassification | date:'dd/MM/yyyy
                                    HH:mm:ss'}}</span>
                                <span class="secundary">Data de fim: {{item.endClassification | date:'dd/MM/yyyy
                                    HH:mm:ss'}}</span>
                                <div class="status-caller" [ngStyle]="{'background-color': item.gravityColorCode }">
                                    <span class="text-bold">{{ item.gravityName }} {{item.gravityColorCode}}</span>
                                </div>
                            </div>
                            <div class="actions">
                                <a>
                                    <mat-icon aria-hidden="false" aria-label="Visualizar"
                                        [routerLink]="['/classification/telephone-classification/details', {idTriage: item.idTriage}]">
                                        visibility
                                    </mat-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <app-empty-list *ngIf="listTriage != undefined && listTriage.length == 0 && !isLoading"></app-empty-list>
            <div *ngIf="!isLoading" class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-paginator [length]="fullSize" #paginator [pageIndex]="pageIndex" [pageSize]="50"
                        aria-label="Select page" (page)="changePage($event)" [hidePageSize]="true">
                    </mat-paginator>
                </div>
            </div>
        </div>
    
        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>
    
</div>