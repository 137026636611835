import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { GravityEnum } from 'src/app/shared/enum/gravity.enum';
import { LeanTypeAlertEnum } from 'src/app/shared/enum/orchestrator-data-intelligence/lean-type-alert.enum';
import { LeanPanelService } from 'src/app/shared/services/API/orchestrator-data-intelligence/lean-panel.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { GraphStruct } from 'src/app/shared/services/structs/graph.struct';
import { GlobalDataStruct } from 'src/app/shared/services/structs/orchestrator-data-intelligence/global-data.struct';
import { LeanInputFlowStruct } from 'src/app/shared/services/structs/orchestrator-data-intelligence/lean-input-flow.struct';
import { LeanOutputFlowStruct } from 'src/app/shared/services/structs/orchestrator-data-intelligence/lean-output-flow.struct';
import { LeanPassFlowStruct } from 'src/app/shared/services/structs/orchestrator-data-intelligence/lean-pass-flow.struct';
import { ModuleStruct } from 'src/app/shared/services/structs/user/module.struct';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-lean-panel',
  templateUrl: './lean-panel.component.html',
  styleUrls: ['./lean-panel.component.css']
})
export class LeanPanelComponent implements OnInit, AfterViewInit {

  constructor(
    private leanPanelService: LeanPanelService,
    private alertService: AlertService,
    private utilService: UtilService,
  ) { }

  public isLoading: boolean = true;
  public isFirstLoading: boolean = true;
  public isMobile: boolean = false;
  public isCallError: boolean = false;
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.flow_management;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.flow_management_lean_panel;
  public gravityEnum: GravityEnum;

  public moduleList: ModuleStruct[];

  public tooltipSonet: string = 'Indicador de Superlotação: Normal (verde): 0-100 pontos; Alerta de superlotação (laranja): 101-140 pontos; Superlotação severa (vermelho): Acima de 141 pontos';

  //Variáveis Globais
  public globalDataStruct: GlobalDataStruct;

  //Variáveis entrada
  public leanInputFlowStruct: LeanInputFlowStruct;

  //Variáveis passagem
  public leanPassFlowStruct: LeanPassFlowStruct;

  //Variáveis saída
  public leanOutputFlowStruct: LeanOutputFlowStruct;

  public normalAlert: LeanTypeAlertEnum = LeanTypeAlertEnum.normal;
  public criticalAlert: LeanTypeAlertEnum = LeanTypeAlertEnum.critical;
  public warningAlert: LeanTypeAlertEnum = LeanTypeAlertEnum.alert;

  //Gráficos
  public colorSchemeBar = {
    domain: ['#D36E23']
  };
  public view: any[] = [];

  public barResults: GraphStruct[] = [];

  public showXAxis = true;
  public showYAxis = true;
  public gradient = false;
  public showLegend: boolean = false;
  public legendTitle = "Legenda";
  public legendPosition: string = 'right';
  public showXAxisLabel = false;
  public xAxisLabel = 'Horário';
  public showYAxisLabel = false;
  public yAxisLabel = 'Número de pacientes';
  public yScaleMax = 0;
  public yScaleMin = 0;

  //gauge chart
  public flowInputLimits: string[] = ['04:00:00', '08:00:00', '12:00:00'];
  public flowPassLimits: string[] = ['04:00:00', '08:00:00', '12:00:00'];
  public flowOutputLimits: string[] = ['04:00:00', '08:00:00', '12:00:00'];

  //slider - mobile
  
  // get our elements
  public slider: any;
  public slides: any;

  // set up our state
  public isDragging = false;
  public startPos = 0;
  public currentTranslate = 0;
  public prevTranslate = 0;
  public animationID = 0;
  public currentIndex = 0;

  async ngOnInit() {
    this.isFirstLoading = true;
    if ('userAgentData' in navigator) {
      this.isMobile = window.navigator['userAgentData'].mobile;
    }
    this.moduleList = this.utilService.getModules();
    this.identifiesMobile();
    this.search();
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  private interval = setInterval(() => {
    this.search()
  }, 300000);

  ngAfterViewInit(): void {
    if(this.isMobile)
      this.initSlide();
  }

  identifiesMobile() {
    const userAgent: string = navigator.userAgent.toLowerCase();

    let isMobile: boolean = /iphone|android/i.test(navigator.userAgent);

    let isTablet: boolean = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);

    if (isMobile || isTablet)
      this.isMobile = true;
    else
      this.isMobile = false;
  }

  async search() {
    this.isLoading = true;

    this.barResults = [];

    this.leanPanelService.getLeanPanelData().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          this.isFirstLoading = false;
          this.isCallError = true;
          return;
        }

        this.globalDataStruct = response.globalDataStruct;
        this.leanInputFlowStruct = response.leanInputFlowStruct;
        this.leanPassFlowStruct = response.leanPassFlowStruct;
        this.leanOutputFlowStruct = response.leanOutputFlowStruct;

        if(this.leanInputFlowStruct.speedometerGraphStruct){
          this.flowInputLimits = [];
          this.flowInputLimits.push(this.leanInputFlowStruct.speedometerGraphStruct.stringTimeMaxGreen);
          this.flowInputLimits.push(this.leanInputFlowStruct.speedometerGraphStruct.stringTimeMaxYellow);
          this.flowInputLimits.push(this.leanInputFlowStruct.speedometerGraphStruct.stringTimeMaxRed);
        }
        if(this.leanPassFlowStruct.speedometerGraphStruct){
          this.flowPassLimits = [];
          this.flowPassLimits.push(this.leanPassFlowStruct.speedometerGraphStruct.stringTimeMaxGreen);
          this.flowPassLimits.push(this.leanPassFlowStruct.speedometerGraphStruct.stringTimeMaxYellow);
          this.flowPassLimits.push(this.leanPassFlowStruct.speedometerGraphStruct.stringTimeMaxRed);
        }
        if(this.leanOutputFlowStruct.speedometerGraphStruct){
          this.flowOutputLimits = [];
          this.flowOutputLimits.push(this.leanOutputFlowStruct.speedometerGraphStruct.stringTimeMaxGreen);
          this.flowOutputLimits.push(this.leanOutputFlowStruct.speedometerGraphStruct.stringTimeMaxYellow);
          this.flowOutputLimits.push(this.leanOutputFlowStruct.speedometerGraphStruct.stringTimeMaxRed);
        }

        if(response.leanOutputFlowStruct.dischargeOutputStruct.outputOnDayStruct.length > 0){
          response.leanOutputFlowStruct.dischargeOutputStruct.outputOnDayStruct.forEach((item) => {
  
            let graphStruct = new GraphStruct(item.timeInterval, item.hourlyCount);
  
            this.barResults.push(graphStruct);
          })
        }

        this.isLoading = false;
        this.isFirstLoading = false;
        this.isCallError = false;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        this.isFirstLoading = false;
        this.isCallError = true;
      }
    });
  }

  initSlide() {
    this.slider = document.querySelector('.slider-container');
    this.slides = Array.from(document.querySelectorAll('.slide'));

    this.slides.forEach((slide, index) => {
      // slide.addEventListener('dragstart', (e) => e.preventDefault());
    
      // Touch events
      slide.addEventListener('touchstart', this.touchStart(index));
      slide.addEventListener('touchend', () => {this.touchEnd()});
      slide.addEventListener('touchmove', (ev) => {this.touchMove(ev)});
    
      // Mouse events
      slide.addEventListener('mousedown', this.touchStart(index));
      slide.addEventListener('mouseup', () => {this.touchEnd()});
      slide.addEventListener('mouseleave', () => {this.touchEnd()});
      slide.addEventListener('mousemove', (ev) => {this.touchMove(ev)});

      // pointer events
      slide.addEventListener('pointerdown', this.touchStart(index));
      slide.addEventListener('pointerup', () => {this.touchEnd()});
      slide.addEventListener('pointerleave', () => {this.touchEnd()});
      slide.addEventListener('pointermove', (ev) => {this.touchMove(ev)});
    });
    
    // make responsive to viewport changes
    window.addEventListener('resize', () => {this.setPositionByIndex()});

    // Disable context menu
    window.oncontextmenu = (event) => {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
  }

  touchStart(index) {
    return (event) => {
      this.currentIndex = index;
      this.startPos = this.getPositionX(event);
      // this.startPos = event.clientX;
      this.isDragging = true;
  
      // https://css-tricks.com/using-requestanimationframe/
      this.animationID = window.requestAnimationFrame(() => {this.animation()});
      this.slider.classList.add('grabbing');
    }
  }
  
  touchEnd() {
    this.isDragging = false;
    window.cancelAnimationFrame(this.animationID);
  
    const movedBy = this.currentTranslate - this.prevTranslate;
  
    if (movedBy < -9 && this.currentIndex < this.slides.length - 1) this.currentIndex += 1;
  
    if (movedBy > 9 && this.currentIndex > 0) this.currentIndex -= 1;
  
    this.setPositionByIndex();
  
    this.slider.classList.remove('grabbing');
  }
  
  touchMove(event) {
    if (this.isDragging) {
      const currentPosition = this.getPositionX(event);
      this.currentTranslate = this.prevTranslate + currentPosition - this.startPos;
    }
  }
  
  getPositionX(event) {
    return (event.type.includes('mouse') || event.type.includes('pointer')) ? event.clientX : event.touches[0].clientX;
  }
  
  animation() {
    this.setSliderPosition();
    if (this.isDragging) window.requestAnimationFrame(() => {this.animation()});
  }
  
  setSliderPosition() {
    this.slider.style.transform = `translateX(${this.currentTranslate}px)`;
  }
  
  setPositionByIndex() {
    this.currentTranslate = this.currentIndex * (-window.innerWidth);
    this.prevTranslate = this.currentTranslate;
    this.setSliderPosition();
  }
}