import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { catchError, retry, timeout } from 'rxjs/operators';
import { LookupResponse } from '../../responses/medical-record/lookup.response';
import { ListNatureOfAttendanceResponse } from '../../responses/medical-record/list-nature-of-attendance-response';
import { ListPainResponse } from '../../responses/medical-record/list-pain.response';


@Injectable({
  providedIn: 'root'
})
export class MedicalRecordLookupService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient) {
    super();
  }

  public getLookup(): Observable<LookupResponse> {

    let uri = `Lookup`

    return this.httpClient.get<LookupResponse>(environment.urlApiMedicalRecord + uri, this.addHeaderToken())
      .pipe(
        timeout(environment.requestTimeOut),
        retry(environment.requestRetry),
        catchError(this.handleError)
      )
  }

  public listNatureOfAttendance(): Observable<ListNatureOfAttendanceResponse> {

    let uri = `Lookup/GetNatureOfAttendance`

    return this.httpClient.get<ListNatureOfAttendanceResponse>(environment.urlApiMedicalRecord + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public listPain(): Observable<ListPainResponse> {

    let uri = `Lookup/GetPainLevels`

    return this.httpClient.get<ListPainResponse>(environment.urlApiMedicalRecord + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}