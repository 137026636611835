import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { FavoriteNonStandardMedicineService } from 'src/app/shared/services/API/medic/favorite-non-standard-medicine.service';
import { MedicFavoriteNonStandardPrescriptionService } from 'src/app/shared/services/API/medic/medic-favorite-non-standard-prescription.service';
import { LookupMedicalCareService } from 'src/app/shared/services/API/medical-record/lookup-medical-care.service';
import { MedicineService } from 'src/app/shared/services/API/pharmacy/medicine.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { FavoriteNonStandardMedicineModel } from 'src/app/shared/services/models/medic/favorite-non-standard-medicine.model';
import { MedicFavoriteNonStandardPrescriptionRequest } from 'src/app/shared/services/requests/medic/medic-favorite-non-standard-prescription-request';
import { LookupMedicalCareResponse } from 'src/app/shared/services/responses/medical-record/lookup-medical-care-response';
import { ListMedicineResponse } from 'src/app/shared/services/responses/pharmacy/list-medicine.response';
import { ReadMedicalPrescriptionStruct } from 'src/app/shared/services/structs/orchestrator-patient/read-medical-prescription.struct';
import { AddNonstandardMedicineModalComponent } from './add-nonstandard-medicine-modal/add-nonstandard-medicine-modal.component';

@Component({
  selector: 'app-favorite-non-standard-medicine',
  templateUrl: './favorite-non-standard-medicine.component.html',
  styleUrls: ['./favorite-non-standard-medicine.component.css']
})
export class FavoriteNonStandardMedicineComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public medicineService: MedicineService,
    public lookupMedicalCareService: LookupMedicalCareService,
    private favoriteNonStandardMedicineService: FavoriteNonStandardMedicineService,
    private medicFavoriteNonStandardPrescriptionService: MedicFavoriteNonStandardPrescriptionService
  ) { }

  public model: FormGroup;
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.medic;

  public isLoading: boolean;
  public isUpdate: boolean;
  public idMedicFavoriteNonStandardPrescription: number;
  public idRoom: number;
  public idSector: number;

  public listFavoriteNonStandardMedicine: FavoriteNonStandardMedicineModel[] = [];
  public lookupMedicalCareResponse: LookupMedicalCareResponse;
  public listMedicine: ListMedicineResponse = new ListMedicineResponse();
  public viewToggleShareWithMedicalTeam: boolean = false;

  ngOnInit(): void {
    this.isLoading = true;    

    this.model = this.formBuilder.group({
      name: ['', [Validators.required]],
      shareWithMedicalTeam: [false, [Validators.required]],
    });

    if (this.activatedRoute.snapshot.paramMap.get('idRoom'))
      this.idRoom = parseInt(this.activatedRoute.snapshot.paramMap.get('idRoom'));

    if (this.activatedRoute.snapshot.paramMap.get('idSector'))
      this.idSector = parseInt(this.activatedRoute.snapshot.paramMap.get('idSector'));

    if (this.activatedRoute.snapshot.paramMap.get('idMedicFavoriteNonStandardPrescription'))
      this.idMedicFavoriteNonStandardPrescription = parseInt(this.activatedRoute.snapshot.paramMap.get('idMedicFavoriteNonStandardPrescription'));

    this.getLookupMedicalCare();   
  }

  submit() {
    if (this.isLoading)
      return;

    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      this.isLoading = false;
      return;
    }

    if (this.listFavoriteNonStandardMedicine.length === 0) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      this.isLoading = false;
      return;
    }

    this.isLoading = true;

    let request = new MedicFavoriteNonStandardPrescriptionRequest();
    request.listFavoriteNonStandardMedicine = this.listFavoriteNonStandardMedicine;
    request.favoriteMedicineName = this.model.get('name').value;
    request.shareWithMedicalTeam = this.model.get('shareWithMedicalTeam').value;
    if (this.isUpdate) {
      request.idMedicFavoriteNonStandardPrescription = this.idMedicFavoriteNonStandardPrescription;
      this.putFavoritePrescription(request);
    }
    else
      this.postFavoritePrescription(request);
  }

  postFavoritePrescription(request: MedicFavoriteNonStandardPrescriptionRequest) {
    this.medicFavoriteNonStandardPrescriptionService.postMedicFavoriteNonStandarPrescription(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;
        this.router.navigate(['/medic/medic-favorite-non-standard-prescription', { idSector: this.idSector, idRoom: this.idRoom }]);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
        return;
      }
    });
  }

  putFavoritePrescription(request: MedicFavoriteNonStandardPrescriptionRequest) {
    this.medicFavoriteNonStandardPrescriptionService.putMedicFavoriteStandarPrescription(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações atualizadas com sucesso", AlertType.success);
        this.isLoading = false;
        this.router.navigate(['/medic/medic-favorite-non-standard-prescription', { idSector: this.idSector, idRoom: this.idRoom }]);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
        return;
      }
    });
  }

  getLookupMedicalCare() {
    this.lookupMedicalCareService.getLookupMedicalCare().subscribe({
      next: (response) => {
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.lookupMedicalCareResponse = response;
        this.viewToggleShareWithMedicalTeam = this.lookupMedicalCareResponse.viewToggleShareWithMedicalTeam;

        if (this.idMedicFavoriteNonStandardPrescription != null)
          this.populateCardList();
        else
          this.isLoading = false;

      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateCardList() {
    this.favoriteNonStandardMedicineService.getFavoriteNonStandardMedicine(this.idMedicFavoriteNonStandardPrescription).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listFavoriteNonStandardMedicine = response.listFavoriteNonStandardMedicine;
        if (this.listFavoriteNonStandardMedicine.length > 0 && this.lookupMedicalCareResponse && this.lookupMedicalCareResponse.listMeasurementUnit.length > 0)
          this.listFavoriteNonStandardMedicine.forEach(x => {
            x.measurementUnitName = x.measurementUnitName ? x.measurementUnitName : this.lookupMedicalCareResponse.listMeasurementUnit.find(item => x.idMeasurementUnit == item.idMeasurementUnit).measurementUnitName;
          });

        this.model.get('name').setValue(response.medicFavoriteNonStandardPrescription.favoritePrescriptionName);
        this.model.get('shareWithMedicalTeam').setValue(response.medicFavoriteNonStandardPrescription.shareWithMedicalTeam);
        this.isLoading = false;
        this.isUpdate = true;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openMedicineModal(index: number, prescription: ReadMedicalPrescriptionStruct, isUpdate: boolean) {
    if (isUpdate)
      this.openUpdatePrescriptionModal(index, prescription);
    else
      this.openFavoriteStandardMedicine(prescription);
  }

  openUpdatePrescriptionModal(index: number, prescription: ReadMedicalPrescriptionStruct) {
    const dialogRef = this.dialog.open(AddNonstandardMedicineModalComponent, {
      data: {
        prescription,
        lookupMedicalCareResponse: this.lookupMedicalCareResponse
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result) {
          if (index >= 0)
            this.listFavoriteNonStandardMedicine.splice(index, 1);

          this.listFavoriteNonStandardMedicine.push(result.favoriteMedicineModel);
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  openFavoriteStandardMedicine(prescription: ReadMedicalPrescriptionStruct) {
    const dialogRef = this.dialog.open(AddNonstandardMedicineModalComponent, {
      data: {
        prescription,
        lookupMedicalCareResponse: this.lookupMedicalCareResponse,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result)
          this.listFavoriteNonStandardMedicine.push(result.favoriteMedicineModel);
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  removeMedicine(index: number) {
    this.listFavoriteNonStandardMedicine.splice(index, 1);
  }
}