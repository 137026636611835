import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { TelemedicineConfigService } from 'src/app/shared/services/API/telemedicine/telemedicine-config.service';
import { TelemedicineLookupService } from 'src/app/shared/services/API/telemedicine/telemedicine-lookup.service';
import { HealthPlan } from 'src/app/shared/services/models/telemedicine/health-plan.model';
import { TelemedicineConfig } from 'src/app/shared/services/models/telemedicine/telemedicine-config.model';
import { HealthIdentification } from 'src/app/shared/services/models/telemedicine/health-identification.model';
import { TelemedicineConfigRequest } from 'src/app/shared/services/requests/telemedicine/telemedicine-config.request';
import { environment } from 'src/environments/environment';
import { TelemedicineLogoModalComponent } from './telemedicine-logo-modal/telemedicine-logo-modal.component';
import { HealthIdentificationEnum } from 'src/app/shared/enum/telemedicine/health-identification.enum';
import { OrganizationService } from 'src/app/shared/services/API/flow/organization.service';
import { FlowStruct } from 'src/app/shared/services/structs/flow/flow.struct';
import { IframePreviewModalComponent } from 'src/app/shared/components/iframe-preview-modal/iframe-preview-modal.component';
import { TelemedicinePatientRedirectType } from 'src/app/shared/services/models/telemedicine/telemedicine-patient-redirect-type.model';
import { PatientRedirectTypeEnum } from 'src/app/shared/enum/telemedicine/patient-redirect-type.enum';
import { TelemedicineConfigStruct } from 'src/app/shared/services/structs/telemedicine/telemedicine-config.struct';
import { OrchestratorTelemedicineConfigService } from 'src/app/shared/services/API/orchestrator-telemedicine/orchestrator-telemedicine-config.service';
import { ManageWebhookService } from 'src/app/shared/services/API/integration/manage-webhook.service';
import { ListWebhookStruct } from 'src/app/shared/services/structs/integration/list-webhook.struct';
import { TelemedicineExternalSearchField } from 'src/app/shared/services/models/telemedicine/telemedicine-external-search-field.model';

@Component({
  selector: 'app-telemedicine-config',
  templateUrl: './telemedicine-config.component.html',
  styleUrls: ['./telemedicine-config.component.css']
})
export class TelemedicineConfigComponent implements OnInit {

  constructor(
    private alertService: AlertService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private telemedicineConfigService: TelemedicineConfigService,
    private orchestratorTelemedicineConfig: OrchestratorTelemedicineConfigService,
    private telemedicineLookupService: TelemedicineLookupService,
    private organizationService: OrganizationService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.digital_prompt_service;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.telemedicine_config;
  public isLoading: boolean;
  public isFirstLoading: boolean = false;
  public healthUnitHasPatientCenter: boolean = false;
  public useTolifeLogo: boolean = true;
  public isActive: boolean;
  public searchText: string;
  public model: FormGroup;
  public logo: string;
  public newLogo: string;
  public listHealthPlan: HealthPlan[];
  public listHealthIdentification: HealthIdentification[];
  public listTelemedicinePatientRedirectType: TelemedicinePatientRedirectType[];
  public listExternalSearchField: TelemedicineExternalSearchField[];
  public completeUrl: string;
  public baseUrl: string;
  public healthIdentificationEnum: HealthIdentificationEnum = HealthIdentificationEnum.healthPlan;
  public telemedicineFlow: FlowStruct;
  public urlTelemedicine: string = '';
  public validationRegex: RegExp = /^[-a-zA-Z0-9_.,<>;+?@*$%#!&()"'{}_/:'"]+$/;

  public config: TelemedicineConfigStruct;
  public patternSymptoms: string;
  public transition: string = null;
  public listWebhook: ListWebhookStruct[];

  //Tratativas de não comparecimento
  public redirectToQueue = PatientRedirectTypeEnum.retorno_a_fila;
  public evade = PatientRedirectTypeEnum.retorno_a_fila;
  public idTelemedicineConfig: number = null;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      useColor: [false, [Validators.required]],
      colorCode: [''],
      isLogoUploaded: [false, [Validators.required]],
      isToSendSms: [false, [Validators.required]],
      useTelemedicineInPatientCenter: [false, [Validators.required]],
      logoString64: [''],
      urlName: ['', [Validators.required]],
      urlStatus: [false, [Validators.required]],
      url: ['', [Validators.required]],
      healthIdentification: ['', [Validators.required]],
      listHealthPlans: this.formBuilder.array([]),
      flowName: [null],
      orientationsTitle: [''],
      orientationsDescription: [''],
      idTelemedicinePatientRedirectType: [null, [Validators.required]],
      waitingTimePatientDoctorInSeconds: [null, [Validators.required]],
      maxReturns: [{ value: null, disabled: true }],
      isExternalSearchEnabled: [false],
      idExternalSearchField: [null],
    });

    this.search();
    this.listLookUpTelemedicine();
    this.getTelemedicineFlow();

    this.isLoading = false;
  }

  search() {
    this.isLoading = true;
    this.isFirstLoading = true;
    if (this.searchText == "")
      this.searchText = null;

    this.patternSymptoms = "● Chiado, falta de ar ou dificuldade em respirar\n● Dor no peito\n● Fraturas deslocadas ou expostas\n● Desmaios ou tonturas\n● Dormência ou fraqueza repentina\n● Sangramento que não pode ser parado\n● Dor abdominal - especialmente dor intensa localizada\n● Febre com convulsões ou qualquer febre em crianças menores de 3 meses\n● Confusão ou alterações no estado mental\n● Tosse ou vômito com sangue\n● Dor de cabeça severa ou lesão na cabeça, especialmente se o indivíduo estiver usando aspirina ou anticoagulantes\n● Sangue na urina ou diarreia com sangue\n● Súbita incapacidade de falar, ver, andar e se mover";

    this.orchestratorTelemedicineConfig.getConfig().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isFirstLoading = false;
          this.isLoading = false;
          return;
        }

        this.healthUnitHasPatientCenter = response.telemedicineConfig.healthUnitHasPatientCenter;
        this.config = response.telemedicineConfig;
        this.idTelemedicineConfig = response.telemedicineConfig.idTelemedicineConfig;
        
        this.useTolifeLogo = response.telemedicineConfig.useTolifeLogo;
        this.listHealthPlan = response.telemedicineConfig.listHealthPlans;
        this.model.get('useColor').setValue(response.telemedicineConfig.useColor);
        this.model.get('colorCode').setValue(response.telemedicineConfig.colorCode);
        this.model.get('isLogoUploaded').setValue(response.telemedicineConfig.isLogoUploaded);
        this.model.get('isToSendSms').setValue(response.telemedicineConfig.isToSendSms);
        this.model.get('logoString64').setValue(response.telemedicineConfig.logoString64);
        this.model.get('urlName').setValue(response.telemedicineConfig.urlName);
        this.model.get('urlStatus').setValue(response.telemedicineConfig.isActive);
        this.model.get('url').setValue(response.telemedicineConfig.url);
        this.model.get('healthIdentification').setValue(response.telemedicineConfig.idHealthIdentification);
        this.model.get('orientationsTitle').setValue(response.telemedicineConfig.orientationsTitle);
        this.model.get('idTelemedicinePatientRedirectType').setValue(response.telemedicineConfig.idTelemedicinePatientRedirectType);
        this.model.get('waitingTimePatientDoctorInSeconds').setValue(response.telemedicineConfig.waitingTimePatientDoctorInSeconds);
        this.model.get('maxReturns').setValue(response.telemedicineConfig.maxReturns);
        this.model.get('useTelemedicineInPatientCenter').setValue(response.telemedicineConfig.useTelemedicineInPatientCenter);
        this.model.get('isExternalSearchEnabled').setValue(response.telemedicineConfig.isExternalSearchEnabled);
        this.model.get('idExternalSearchField').setValue(response.telemedicineConfig.idExternalSearchField);
        if (response.telemedicineConfig.orientationsDescription != undefined && response.telemedicineConfig.orientationsDescription != null)
          this.model.get('orientationsDescription').setValue(response.telemedicineConfig.orientationsDescription);
        else
          this.model.get('orientationsDescription').setValue(this.patternSymptoms);

        if (response.telemedicineConfig.idTelemedicinePatientRedirectType == this.redirectToQueue) {
          this.model.get('maxReturns').enable();
          this.model.get('maxReturns').updateValueAndValidity();
        }

        this.logo = response.telemedicineConfig.logoString64;
        this.urlTelemedicine = response.telemedicineConfig.url;
        this.getCompleteUrl(null);
        if (!response.telemedicineConfig.listHealthPlans || response.telemedicineConfig.listHealthPlans.length <= 0)
          this.addNext();

        response.telemedicineConfig.listHealthPlans.forEach(x => {
          (this.model.controls['listHealthPlans'] as FormArray).push(
            this.formBuilder.group({
              healthPlanName: [x.healthPlanName],
            })
          )
        });

        this.isLoading = false;
        this.isFirstLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.isFirstLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  listLookUpTelemedicine() {
    this.telemedicineLookupService.getTelemedicineLookup().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isFirstLoading = false;
          this.isLoading = false;
          return;
        }

        this.listHealthIdentification = response.listHealthIdentification;
        this.listTelemedicinePatientRedirectType = response.listTelemedicinePatientRedirectType
        this.listExternalSearchField = response.listExternalSearchField;

        this.isLoading = false;
        this.isFirstLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.isFirstLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  submit() {
    if (this.isLoading)
      return;

    if (this.model.invalid) {
      this.alertService.show('Atenção', 'Favor verificar todos os campos', AlertType.warning);
      return;
    }

    let orientations = this.model.get("orientationsTitle");

    if (orientations.touched) {
      if (orientations.value && orientations.value.length <= 29) {
        orientations.updateValueAndValidity();
        orientations.markAsTouched();
        this.alertService.show('Atenção', "O campo 'Veja se não é uma emergência' precisa ter no minimo 30 caracteres", AlertType.warning);
        return;
      }
    }

    orientations = this.model.get("orientationsDescription");

    if (orientations.touched) {
      if (orientations.value && orientations.value.length <= 29) {
        orientations.updateValueAndValidity();
        orientations.markAsTouched();
        this.alertService.show('Atenção', "O campo 'Sintomas' precisa ter no minimo 30 caracteres", AlertType.warning);
        return;
      }
    }

    this.model.get('listHealthPlans')['controls'].forEach(control => {
      control.get('healthPlanName').markAsTouched();
      control.get('healthPlanName').markAsDirty();
    });

    this.isLoading = true;

    let request: TelemedicineConfigRequest = new TelemedicineConfigRequest();
    request.useColor = this.model.get('useColor').value;
    request.colorCode = request.useColor ? this.model.get('colorCode').value : null;
    request.isLogoUploaded = this.model.get('isLogoUploaded').value;
    request.isToSendSms = this.model.get('isToSendSms').value;
    request.logoString64 = request.isLogoUploaded ? this.model.get('logoString64').value : null;
    request.url = this.model.get('url').value;
    request.isActive = this.model.get('urlStatus').value;
    request.urlName = this.model.get('urlName').value;
    request.idHealthIdentification = this.model.get('healthIdentification').value;
    request.orientationsTitle = this.model.get('orientationsTitle').value == '' ? null : this.model.get('orientationsTitle').value;
    request.orientationsDescription = this.model.get('orientationsDescription').value == '' ? null : this.model.get('orientationsDescription').value;
    request.idTelemedicinePatientRedirectType = this.model.get('idTelemedicinePatientRedirectType').value;
    request.waitingTimePatientDoctorInSeconds = this.model.get('waitingTimePatientDoctorInSeconds').value;
    request.maxReturns = this.model.get('maxReturns').value;
    request.useTelemedicineInPatientCenter = this.model.get('useTelemedicineInPatientCenter').value;
    request.isExternalSearchEnabled = this.model.get('isExternalSearchEnabled').value;
    request.idExternalSearchField = this.model.get('idExternalSearchField').value;
    request.useTolifeLogo = this.useTolifeLogo;

    this.model.get('listHealthPlans').value.forEach(x => {
      var healthPlan = new HealthPlan();

      healthPlan.healthPlanName = x.healthPlanName;

      request.listHealthPlans.push(healthPlan);
    });

    this.updateTelemedicineConfig(request);
  }

  setOrientationsValidator(formControlName: string) {
    let formControl = this.model.get(formControlName);

    if (formControl.value && formControl.value.length >= 0)
      formControl.setValidators([Validators.minLength(30)]);
    else
      formControl.setValidators(null);
  }

  updateTelemedicineConfig(request: TelemedicineConfigRequest) {
    this.orchestratorTelemedicineConfig.postConfig(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        let statusString = "";

        switch (response.errorCode) {
          case AlertType.error:
            statusString = "Erro";
            break
          case AlertType.warning:
            statusString = "Aviso";
            break
          case AlertType.success:
            statusString = "Sucesso";
            break
          case AlertType.info:
            statusString = "Informação";
            break
        }

        this.alertService.show(statusString, response.errorDescription, response.errorCode);
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13)
        $this.search();
    },
      1000);
  }

  openPanelLogoModal() {
    const dialogRef = this.dialog.open(TelemedicineLogoModalComponent, {
      data: {
        logo: this.logo,
        newLogo: this.newLogo,
        useTolifeLogo: this.useTolifeLogo
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if(!result || (result && result.cancel))
          return;

        if (result && result.newLogo) {
          this.alertService.show('Sucesso', "Upload concluído!", AlertType.success);
          this.newLogo = result.newLogo;

          if (!this.logo)
            this.logo = this.newLogo;

          this.model.get('logoString64').setValue(result.newLogo);
        }
        
        if (result && !result.haveLogo)
          this.model.get('isLogoUploaded').setValue(false);

        this.useTolifeLogo = result.useTolifeLogo;

        this.updateLogoNamePreview(this.model.get('logoString64').value);
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  onCopy(text) {
    this.alertService.show('Sucesso', text, AlertType.success);
  }

  createInput() {
    return this.formBuilder.group({
      healthPlanName: [''],
    });
  }

  addNext() {
    (this.model.controls['listHealthPlans'] as FormArray).push(this.createInput());
    this.model.controls['listHealthPlans'].updateValueAndValidity();
  }

  getCompleteUrl(event) {
    if (event) {
      let result = this.validationRegex.test(event.key);

      if (!result) {
        let uri = this.model.get('url').value.replaceAll(event.key, "");
        this.model.get('url').setValue(uri);
      }
    }
    this.baseUrl = `${environment.urlBasePatientUI}telemedicine/`;
    this.completeUrl = `${environment.urlBasePatientUI}telemedicine/${this.model.get('url').value}`;
  }

  updateFormArrayValidation() {
    this.model.get('listHealthPlans')['controls'].forEach(control => {
      if (this.model.get('healthIdentification').value == HealthIdentificationEnum.healthPlan)
        control.get('healthPlanName').setValidators([Validators.required]);
      else
        control.get('healthPlanName').setValidators(null);

      control.get('healthPlanName').updateValueAndValidity();
    });
  }

  getTelemedicineFlow() {
    this.organizationService.getTelemedicineFlow().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.telemedicineFlow = response.flow;
        this.model.get('flowName').setValue(this.telemedicineFlow ? this.telemedicineFlow.flowName : null);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openPreview() {
    this.dialog.open(IframePreviewModalComponent, {
      data: {
        url: `${this.baseUrl}${this.urlTelemedicine}?preview=true`,
        title: 'Telemedicina'
      },
      panelClass: 'iframe-preview-modal'
    });
  }

  updateColorCodePreview() {
    if (this.idTelemedicineConfig && this.idTelemedicineConfig != 0) {
      let request: TelemedicineConfig = new TelemedicineConfig();
      request.colorCodePreview = this.model.get('colorCode').value;

      this.telemedicineConfigService.putColorCodePreview(request).subscribe({
        next: (response) => {
          if (response.isError) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            this.isLoading = false;
            return;
          }

          this.isLoading = false;
        },
        error: (error) => {
          console.log(error);
          this.isLoading = false;
          this.alertService.show('Erro inesperado', error, AlertType.error);
        }
      });
    }
  }

  updateLogoNamePreview(logoString64: string) {
    if (this.idTelemedicineConfig && this.idTelemedicineConfig != 0) {
      let request: TelemedicineConfigRequest = new TelemedicineConfigRequest();
      request.logoString64 = logoString64;
      request.useTolifeLogo = this.useTolifeLogo;

      this.telemedicineConfigService.putLogoNamePreview(request).subscribe({
        next: (response) => {
          if (response.isError) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            this.isLoading = false;
            return;
          }

          this.isLoading = false;
        },
        error: (error) => {
          console.log(error);
          this.isLoading = false;
          this.alertService.show('Erro inesperado', error, AlertType.error);
        }
      });
    }
  }

  updateRequirements() {
    this.model.get('maxReturns').setValue(this.config.maxReturns);

    let config = this.model.value;

    if (config.idTelemedicinePatientRedirectType == this.redirectToQueue) {
      this.model.get('maxReturns').enable();
      this.model.get('maxReturns').updateValueAndValidity();
    }
    else {
      this.model.get('maxReturns').disable();
      this.model.get('maxReturns').updateValueAndValidity();
    }
  }

  toggleExternalSearch() {
    if (this.model.get('isExternalSearchEnabled').value) {
      this.model.get('idExternalSearchField').setValidators(Validators.required);
    }
    else {
      this.model.get('idExternalSearchField').setValidators(null);
    }
    this.model.get('idExternalSearchField').updateValueAndValidity();
  }
}