<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-confirm-modal-list">
            <div class="img-head">
                <img src="assets/images/exclamation-circle.png" />
            </div>
            <div *ngIf="this.arrived == this.arrivedEarly">
                <h1>Só é possível emitir a senha 2h antes do atendimento agendado.</h1>
            </div>
            <div *ngIf="this.arrived == this.arrivedLate">
                <h1>Não é possível emitir uma senha de agendamento com atraso. Por favor, faça o reagendamento do seu
                    atendimento na Central do Paciente ou gere uma senha para atendimento regular na unidade.
                </h1>
            </div>
        </div>
        <div class="footer-confirm-modal-list">
            <button mat-flat-button color="primary" class="btn-block" (click)="return()">
                <span *ngIf="isLoading == false">Voltar</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
        </div>
    </div>
</div>