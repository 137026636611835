import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { GetConfigResponse } from '../../responses/digital-prompt-service/get-config.response';
import { DigitalPromptServiceConfigRequest } from '../../requests/digital-prompt-service/digital-prompt-service-config.request';
import { DigitalPromptServiceConfig } from '../../models/digital-prompt-service/digital-prompt-service-config.model';

@Injectable({
  providedIn: 'root'
})
export class DigitalPromptConfigService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getConfig(): Observable<GetConfigResponse>{

    let uri = `DigitalPromptConfig`;

    return this.httpClient.get<GetConfigResponse>(environment.urlApiDigitalPrompt + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public postConfig(request: DigitalPromptServiceConfigRequest): Observable<ReturnStruct>{

    let uri = `DigitalPromptConfig`;

    return this.httpClient.post<ReturnStruct>(environment.urlApiDigitalPrompt + uri, request, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public putColorCodePreview(request: DigitalPromptServiceConfig): Observable<ReturnStruct>{

    let uri = `DigitalPromptConfig/UpdateColorCodePreview`;

    return this.httpClient.put<ReturnStruct>(environment.urlApiDigitalPrompt + uri, request, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public putLogoNamePreview(request: DigitalPromptServiceConfigRequest): Observable<ReturnStruct>{

    let uri = `DigitalPromptConfig/UpdateLogoNamePreview`;

    return this.httpClient.put<ReturnStruct>(environment.urlApiDigitalPrompt + uri, request, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}