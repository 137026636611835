<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <a class="back-link" routerLink="/master/batch">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Lista de Pedidos de Processamento
        </a>
        <h1>Fazer novo Pedido de Relatório</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="row">
                    <div class="col-6 col-md-2"> 
                        <mat-form-field appearance="outline">
                          <mat-label>Tipo de Pedido</mat-label>
                          <mat-select formControlName="idBatchType">
                            <mat-option *ngFor="let item of listBatchType" [value]="item.idBatchType">{{item.batchTypeName}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Período compreendido no relatório</mat-label>
                            <mat-date-range-input [rangePicker]="picker">
                                <input matStartDate formControlName="datetimeBatchBegin" placeholder="Início">
                                <input matEndDate formControlName="datetimeBatchEnd" placeholder="Fim">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker></mat-date-range-picker>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Descrição</mat-label>
                            <textarea matInput type="text" formControlName="description"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <a mat-flat-button type="button" routerLink="/master/batch" color="accent" class=" btn-block">
                            Cancelar
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>
    
</div>