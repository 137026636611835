import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ServiceModuleService } from 'src/app/shared/services/API/flow/service-module.service';
import { ServiceService } from 'src/app/shared/services/API/flow/service.service';
import { ModuleService } from 'src/app/shared/services/API/user/module.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { ServiceRequest } from 'src/app/shared/services/requests/flow/service.request';
import { ModuleStruct } from 'src/app/shared/services/structs/user/module.struct';
import { ServiceRegisterConfirmModalComponent } from './service-register-confirm-modal/service-register-confirm-modal.component';
import { ModuleWithDigitalPromptServiceStruct } from 'src/app/shared/services/structs/user/module.with.digital.prompt.service.struct';

@Component({
  selector: 'app-service-register',
  templateUrl: './service-register.component.html',
  styleUrls: ['./service-register.component.css']
})
export class ServiceRegisterComponent implements OnInit {

  constructor(private formBuilder: UntypedFormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private serviceService: ServiceService,
    private moduleService: ModuleService,
    private serviceModuleService: ServiceModuleService,
    public dialog: MatDialog,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.master;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.master_service;

  public model: UntypedFormGroup;
  public isLoading: boolean = false;
  public isFirstLoading: boolean;
  public isUpdate: boolean = false;
  public isDigitalPromptService: boolean = false;
  public actualServiceModule: number;
  public actualFunctionalityGroup: number = null;
  public isDisabledSelect: boolean = false;

  public masks: Masks = this.maskService.getMasks();
  public idService: number = null;
  public listModule: ModuleStruct[];
  public serviceRegisterRequest: ServiceRequest = new ServiceRequest();
  public listModuleWithDigitalPromptServiceStruct: ModuleWithDigitalPromptServiceStruct[] = [];

  ngOnInit(): void {
    this.populateModuleSelect();

    this.model = this.formBuilder.group({
      name: ['', [Validators.required]],
      status: ['', [Validators.required]],
      description: [''],
      module: [''],
      idFunctionalityGroup: [''],
    });

    if (this.activatedRoute.snapshot.paramMap.get('idService'))
      this.idService = parseInt(this.activatedRoute.snapshot.paramMap.get('idService'));

    if (this.idService != null) {
      this.isFirstLoading = true;
      this.isUpdate = true;
      this.populateServiceData();
    }
  }

  submit() {
    if (this.isLoading)
      return;

    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    this.isLoading = true;

    this.serviceRegisterRequest.description = this.model.get('description').value;
    this.serviceRegisterRequest.idModule = parseInt(this.model.get('module').value);
    this.serviceRegisterRequest.idFunctionalityGroup = parseInt(this.model.get('idFunctionalityGroup').value);

    if (this.model.get('status').value === "true")
      this.serviceRegisterRequest.isActive = true;
    if (this.model.get('status').value === "false")
      this.serviceRegisterRequest.isActive = false;

    this.serviceRegisterRequest.serviceName = this.model.get('name').value;

    if (!this.serviceRegisterRequest.idModule && this.listModule.length > 0) {
      this.openModal();
      this.isLoading = false;
      return;
    }

    if (this.isUpdate)
      this.updateService();
    else
      this.createService();
  }

  populateServiceData() {
    this.serviceService.getServiceData(this.idService).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.model.get('name').setValue(response.serviceName);
        this.model.get('status').setValue(response.isActive.toString());
        this.model.get('description').setValue(response.description);
        if (response.idModule) {
          this.model.get('module').setValue(response.idModule);
          if (response.idModule == MenuModuleEnum.digital_prompt_service)
            this.changeSelectModule(response.idModule);
        }

        if (response.idFunctionalityGroup) {
          this.actualFunctionalityGroup = response.idFunctionalityGroup;
          this.model.get('idFunctionalityGroup').setValue(response.idFunctionalityGroup);
        }

        this.actualServiceModule = response.idModule;

        this.isLoading = false;
        this.isFirstLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateModuleSelect() {
    this.moduleService.listAllModule().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listModule = response.listModuleStruct;
        response.listModuleStruct.forEach(module => {
          if (module.listModuleWithDigitalPromptServiceStruct && module.listModuleWithDigitalPromptServiceStruct.length > 0)
            this.listModuleWithDigitalPromptServiceStruct.push(...module.listModuleWithDigitalPromptServiceStruct);
        });

        this.serviceModuleService.listUsedModule().subscribe({
          next: (response) => {
            if (response.isError) {
              this.alertService.show('Erro', response.errorDescription, AlertType.error);
              this.isLoading = false;
              return;
            }

            var listIdModule = response.listIdModule.filter(x => x != this.actualServiceModule)
            var listIdFunctionalityGroup = response.listIdFunctionalityGroup.filter(x => x != this.actualFunctionalityGroup);

            if (listIdFunctionalityGroup && listIdFunctionalityGroup.length > 0) {
              this.listModule = this.listModule.filter(module => {
                if (module.idModule === MenuModuleEnum.digital_prompt_service) {
                  const allFunctionalityGroupsInList = module.listModuleWithDigitalPromptServiceStruct.every(subModule =>
                    listIdFunctionalityGroup.includes(subModule.idFunctionalityGroup)
                  );

                  this.listModuleWithDigitalPromptServiceStruct = this.listModuleWithDigitalPromptServiceStruct.filter(subModule =>
                    !listIdFunctionalityGroup.includes(subModule.idFunctionalityGroup)
                  );
                  return !allFunctionalityGroupsInList;
                }

                if (listIdModule.includes(module.idModule))
                  return false;

                return true;
              });
            }
            else
              this.listModule = this.listModule.filter(x => !listIdModule.includes(x.idModule))

            if (!this.listModule || !this.listModule.length)
              this.isDisabledSelect = true;
            else if (this.listModule.length == 1 && this.listModule[0].idModule == this.actualServiceModule)
              this.isDisabledSelect = true;
          },
          error: (error) => {
            console.log(error);
            this.alertService.show('Erro inesperado', error, AlertType.error);
          }
        });

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  updateService() {
    this.serviceService.updateService(this.serviceRegisterRequest, this.idService).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;

        this.router.navigate(['/master/service']);

      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  createService() {
    this.serviceService.createService(this.serviceRegisterRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;

        this.router.navigate(['/master/service']);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openModal() {
    const dialogRef = this.dialog.open(ServiceRegisterConfirmModalComponent, {
      data: {
        serviceRegisterRequest: this.serviceRegisterRequest,
        isUpdate: this.isUpdate,
        idService: this.idService
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.saveService)
        this.router.navigate(['/master/service']);
    });
  }

  changeSelectModule(event) {
    if (event == MenuModuleEnum.digital_prompt_service)
      this.isDigitalPromptService = true;
    else
      this.isDigitalPromptService = false;
  }
}