import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { KitService } from 'src/app/shared/services/API/pharmacy/kit.service';

@Component({
  selector: 'app-confirm-save-modal',
  templateUrl: './confirm-save-modal.component.html',
  styleUrls: ['./confirm-save-modal.component.css']
})
export class ConfirmSaveModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ConfirmSaveModalComponent>,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService) { }

  public model: UntypedFormGroup;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      observation: ['', [Validators.required]],
    });
  }

  close() {
    this.matDialogRef.close({ confirm: false });
  }

  clickConfirm() {
    if (this.model.invalid) {
      this.alertService.show("Erro", "Preencha o campo de observação!", AlertType.error);
      return;
    }
    let observation = this.model.get("observation").value;
    this.matDialogRef.close({ confirm: true, observation });
  }

  clickCancel() {
    this.matDialogRef.close({ confirm: false });
  }
}
