<div class="container-generic">
    <div [formGroup]="formGroup">
        <div formArrayName="listButtons" *ngFor="let item of formGroup.get('listButtons')['controls']; let i = index">
            <div class="row" [formGroupName]="i">
                <div class="col-9 listButtons">
                    <div class="idButton">
                        <mat-form-field appearance="outline">
                            <mat-label>Id do botão</mat-label>
                            <input matInput type="number" formControlName="idButton">
                        </mat-form-field>
                    </div>
                    <div class="col-3 inputField">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome do Botão</mat-label>
                            <input matInput type="text" formControlName="buttonName">
                            <mat-error *ngIf="item.get('buttonName').invalid">Informe o Nome do Botão</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-3 inputField">
                        <mat-form-field appearance="outline">
                            <mat-label>Descrição</mat-label>
                            <textarea matInput rows="1" type="text" formControlName="description">
                            </textarea>
                            <mat-error *ngIf="item.get('description').invalid">Informe a descrição do Botão</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-3 inputField">
                        <mat-form-field appearance="outline">
                            <mat-label>Tipo do botão</mat-label>
                            <mat-select formControlName="buttonType" (selectionChange)="setUrl(i)">
                                <mat-option *ngFor="let item of listServiceType" [value]="item.idServiceType"
                                    [disabled]="item.disabled">{{item.serviceTypeName}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="item.get('buttonType').invalid">Informe o tipo</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-3 inputField" *ngIf="buttonTypeLink == item.get('buttonType').value">
                        <mat-form-field appearance="outline">
                            <mat-label>URL</mat-label>
                            <input matInput type="text" formControlName="buttonRedirection"
                                (keyup)="validateUri($event, i)">
                            <mat-error *ngIf="item.get('buttonRedirection').invalid">Informe a URL</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-3 inputField" *ngIf="buttonTypeTelephone == item.get('buttonType').value">
                        <mat-form-field appearance="outline">
                            <mat-label>Número de telefone</mat-label>
                            <input matInput type="text" formControlName="buttonRedirection" [mask]="masks.phone">
                            <mat-error *ngIf="item.get('buttonRedirection').invalid">Informe o número de telefone
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-3">
                        <button class="side-button " color="primary" mat-mini-fab type="button" (click)="addNext()">
                            <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
                        </button>
                        <button class="side-button " color="primary" mat-mini-fab type="button"
                            (click)="removeButton(i)" *ngIf="i != 0">
                            <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>