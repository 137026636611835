import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReadExternalPrescriptionStruct } from 'src/app/shared/services/structs/orchestrator-patient/read-external-prescription.struct';
import { ExternalPrescriptionModalComponent } from './external-prescription-modal/external-prescription-modal.component';

@Component({
  selector: 'app-external-prescription-list',
  templateUrl: './external-prescription-list.component.html',
  styleUrls: ['./external-prescription-list.component.css']
})
export class ExternalPrescriptionListComponent implements OnInit {

  @Input() listPrescription: ReadExternalPrescriptionStruct[];

  constructor(
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    
  }

  openPrescriptionModal(prescription: ReadExternalPrescriptionStruct) {
    const dialogRef = this.dialog.open(ExternalPrescriptionModalComponent, {
      data: {
        prescription,
        readOnly: true,
      },
    });
    
  }
}

