import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { HygienizationTotemService } from 'src/app/shared/services/API/bed/hygienization-totem.service';
import { HygienizationUserService } from 'src/app/shared/services/API/user/hygienization-user.service';
import { Masks } from 'src/app/shared/services/mask.service';
import { HygienizationTotemRequest } from 'src/app/shared/services/requests/bed/hygienization-totem.request';
import { QuickIdentifierRequest } from 'src/app/shared/services/requests/bed/quick-identifier.request';
import { HygienizationTotemStruct } from 'src/app/shared/services/structs/bed/hygienization-totem.struct';
import { TotemUserStruct } from 'src/app/shared/services/structs/bed/totem-user-struct';
import { SectorStruct } from 'src/app/shared/services/structs/flow/sector.struct';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-hygienization-totem-register',
  templateUrl: './hygienization-totem-register.component.html',
  styleUrls: ['./hygienization-totem-register.component.css']
})

export class HygienizationTotemRegisterComponent implements OnInit {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private hygienizationTotemService: HygienizationTotemService,
    private hygienizationUserService: HygienizationUserService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.observation;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.observation_hygienization_totem;

  public urlApiBaseUI: string = environment.urlApiBaseUI;

  public model: UntypedFormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;
  public listSector: SectorStruct[] = [];
  public masks: Masks;
  public idHygienizationTotem: number;
  public request: HygienizationTotemRequest;
  public hygienizationTotem: HygienizationTotemStruct;
  public listUser: TotemUserStruct[] = [];
  public listUserDisplay: TotemUserStruct[] = [];
  public selectedUsers: number[] = [];

  ngOnInit(): void {
    this.isUpdate = false;

    this.model = this.formBuilder.group({
      hygienizationTotemName: ['', [Validators.required]],
      status: [false, [Validators.required]],
      pin: [{ value: '', disabled: true }],
      url: [{ value: '', disabled: true }],
      listUser: this.formBuilder.array([], [Validators.required]),
    });

    if (this.activatedRoute.snapshot.paramMap.get('idHygienizationTotem'))
      this.idHygienizationTotem = parseInt(this.activatedRoute.snapshot.paramMap.get('idHygienizationTotem'));

    if (this.idHygienizationTotem != null) {
      this.isFirstLoading = true;
      this.isUpdate = true;
      this.getTotem(this.idHygienizationTotem);
    }

    this.getUsers();

    if (!this.isUpdate)
      this.addNext();
  }

  getTotem(idHygienizationTotem) {
    this.isLoading = true;

    this.hygienizationTotemService.getHygienizationTotem(idHygienizationTotem).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          this.isFirstLoading = false;
          return;
        }

        this.hygienizationTotem = response.totemStruct;

        this.hygienizationTotem.hygienizationTotemUrl = environment.urlApiBaseUI + "client/hygienization-totem/" + this.hygienizationTotem.idHygienizationTotem;

        this.model.get('hygienizationTotemName').setValue(this.hygienizationTotem.hygienizationTotemName);
        this.model.get('status').setValue(this.hygienizationTotem.isActive);
        this.model.get('pin').setValue(this.hygienizationTotem.pin);
        this.model.get('url').setValue("client/hygienization-totem/" + this.hygienizationTotem.idHygienizationTotem);
        this.hygienizationTotem.totemUsers.forEach(x => this.addNext(x));
        this.updateAvailableUsers();

        this.isLoading = false;
        this.isFirstLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.isFirstLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      },
    });
  }

  getUsers() {
    this.isLoading = true;

    this.hygienizationUserService.listUser().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.listUser = response.listUser.map(user => new TotemUserStruct(user));
        this.listUserDisplay = this.listUser;

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      },
    });
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any, idUser: number) {
    let numericIdentifier = event.target.value.replace(/\D/g, '');

    if (numericIdentifier == null)
      return;

    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13)
        $this.validateIdentifier(numericIdentifier, idUser);
    }, 500);
  }

  filterUsers(event: any) {
    this.listUserDisplay = this.listUser.filter(user => user.userName.includes(event.target.value));
  }

  validateIdentifier(identifier: string, idUser: number) {
    this.isLoading = true;
    let user: FormGroup = this.model.get('listUser')['controls'].find(x => x.get('idUser').value == idUser);
    user.get('notValidating').setValue(false);

    if (identifier == null || identifier == "") {
      user.get('notValidating').setValue(true);
      this.isLoading = false;
      return;
    }

    this.hygienizationTotemService.validateIdentifier(new QuickIdentifierRequest(identifier, idUser)).subscribe({
      next: (response) => {
        if (response.isError && response.errorCode !== 1) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          user.get('notValidating').setValue(true);
          user['controls'].quickIdentifier.setErrors(null);
          this.isLoading = false;
          return;
        }
        else if (response.isError && response.errorCode === 1) {
          user.get('notValidating').setValue(true);
          user['controls'].quickIdentifier.setErrors({ 'duplicate': true });
          user['controls'].quickIdentifier.markAsTouched();
          this.isLoading = false;
        }
        else {
          user.get('notValidating').setValue(true);
          user['controls'].quickIdentifier.setErrors(null);
          this.isLoading = false;
        }
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
        user.get('notValidating').setValue(true);
      },
    });
  }

  createInput(user?: TotemUserStruct) {
    if (user == null) {
      return this.formBuilder.group({
        idUser: [null, [Validators.required]],
        userName: [''],
        pinUser: [, [Validators.required]],
        quickIdentifier: [{ value: null, disabled: true }],
        notValidating: [true, [Validators.requiredTrue]],
      });
    }
    else {
      return this.formBuilder.group({
        idUser: [user.idUser],
        userName: [user.userName],
        pinUser: [user.pinUser, [Validators.required]],
        quickIdentifier: [user.quickIdentifier],
        notValidating: [true, [Validators.requiredTrue]],
      });
    }
  }

  addNext(user?: TotemUserStruct) {
    if (user == null)
      (this.model.controls['listUser'] as UntypedFormArray).push(this.createInput());
    else
      (this.model.controls['listUser'] as UntypedFormArray).push(this.createInput(user));
  }

  removeUser(index: number) {
    (this.model.controls['listUser'] as UntypedFormArray).removeAt(index);
    if (this.model.get('listUser')['controls'].length == 0)
      this.addNext();

    let users = this.model.get('listUser')['controls'];
    this.selectedUsers = users.map(x => x.value.idUser);
  }

  updateAvailableUsers(index?: number) {
    this.listUserDisplay = this.listUser;
    let users = this.model.get('listUser')['controls'];
    this.selectedUsers = users.map(x => x.value.idUser);

    if (index != null) {
      users[index].get('quickIdentifier').enable();
      users[index].get('pinUser').enable();
    }
  }

  submit() {
    this.isLoading = true;

    if (this.model.invalid) {
      this.isLoading = false;
      return;
    }

    let request: HygienizationTotemRequest = new HygienizationTotemRequest();
    let totemStruct: HygienizationTotemStruct = new HygienizationTotemStruct();

    totemStruct.hygienizationTotemName = this.model.get('hygienizationTotemName').value;
    totemStruct.isActive = this.model.get('status').value;
    totemStruct.totemUsers = [];
    this.model.get('listUser')['controls'].forEach(user => {
      let totemUser: TotemUserStruct = new TotemUserStruct();
      totemUser.pinUser = user.get('pinUser').value;
      totemUser.idUser = user.get('idUser').value;
      totemUser.quickIdentifier = user.get('quickIdentifier').value;
      totemStruct.totemUsers.push(totemUser);
    });

    if (this.isUpdate)
      totemStruct.idHygienizationTotem = this.hygienizationTotem.idHygienizationTotem;

    request.totemStruct = totemStruct;

    if (this.isUpdate)
      this.updateTotem(request);
    else
      this.createTotem(request);
  }

  createTotem(request: HygienizationTotemRequest) {
    this.hygienizationTotemService.createHygienizationTotem(request).subscribe({
      next: (response) => {
        if (response.isError && response.errorCode !== 1) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;

        this.router.navigate(['/observation/hygienization-totem']);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      },
    });
  }

  updateTotem(request: HygienizationTotemRequest) {
    this.hygienizationTotemService.updateHygienizationTotem(request).subscribe({
      next: (response) => {
        if (response.isError && response.errorCode !== 1) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;

        this.router.navigate(['/observation/hygienization-totem']);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      },
    });
  }
}