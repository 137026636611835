export enum MedicineAdministrationTypeEnum {
    Oral = 1,
    Parental = 2,
    Subcutanea = 3,
    Nasal = 4,
    Outros = 5,
    Retal = 6,
    IntaVesical = 7,
    Nebulizacao = 8,
    Ocular = 9,
    Sublingual = 10,
    Intramuscular = 11,
    NaoDefinida = 12,
}