<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum' [hasSecundaryHeader]="true"
        [episode]="selectedEpisode">
    </app-menu>
    <div class="fab" [ngClass]="{'have-guide-button': showTissGuideButtonValidation}">
        <button class="main">
        </button>
        <ul>
            <li *ngIf="hasObservationProcedure">
                <button (click)="bedProcedureModal()">
                    + Procedimento
                </button>
            </li>
            <li *ngIf="this.isReleasablePharmacy">
                <button (click)="requestMaterialHistory()">
                    + Material
                </button>
            </li>
        </ul>
    </div>
    <div class="container-body-secundary-header">
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <div class="row">
                <div class="col-6">
                    <h1 *ngIf="!isFirstLoading && idBed">{{bedStruct.bedName}}</h1>
                    <mat-icon *ngIf="!isFirstLoading && idBed" class="change-bed" aria-hidden="false" aria-label="Editar"
                        (click)="changeBedPatient()">swap_horiz</mat-icon>
                </div>
                <div class="col-6 leave-button" *ngIf="isFinalized">
                    <button (click)="leaveBed()" mat-flat-button color="primary" class="" type="button">
                        <span *ngIf="isLoading == false">Liberar Leito</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                    <button (click)="generateObservationReport()" mat-flat-button color="primary"
                        class=" generate-report-btn" type="button">
                        <span *ngIf="isLoading == false">Gerar Relatório</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
                <div class="col-6 leave-button"
                    *ngIf="idEpisodeAdministrativeRelease == idStatusEpisodeAltaAdministrativa || idEpisodeAdministrativeRelease == idStatusEpisodeInternar">
                    <button (click)="releaseEpisodeModal(idEpisodeAdministrativeRelease)" mat-flat-button color="primary"
                        class="" type="button">
                        <span
                            *ngIf="idEpisodeAdministrativeRelease == idStatusEpisodeAltaAdministrativa && isLoading == false">Alta
                            administrativa</span>
                        <span
                            *ngIf="idEpisodeAdministrativeRelease == idStatusEpisodeInternar && isLoading == false">Internação
                            administrativa</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
            </div>
            <div class="title-header">
                <h1>Evolução do paciente</h1>
            </div>
            <cdk-accordion class="profile-register-accordion">
                <cdk-accordion-item *ngIf="!isFirstLoading" #accordionItemModule="cdkAccordionItem"
                    class="profile-register-accordion-item" role="button" tabindex="0" [attr.id]="'accordion-header-'"
                    [attr.aria-expanded]="accordionItemModule.expanded" [attr.aria-controls]="'accordion-body-'">
                    <div [ngClass]="{'profile-register-accordion-item-header-expanded': accordionItemModule.expanded}"
                        class="profile-register-accordion-item-header" (click)="accordionItemModule.toggle()">
                        Nova Conduta - Em Andamento
                        <span
                            [ngClass]="{'profile-register-accordion-item-description-expanded': accordionItemModule.expanded}"
                            class="profile-register-accordion-item-description">
                            Clique para {{ accordionItemModule.expanded ? 'fechar' : 'abrir' }}
                        </span>
                    </div>
                    <div class="profile-register-accordion-item-body" role="region"
                        [style.display]="accordionItemModule.expanded ? '' : 'none'" [attr.id]="'accordion-body-'"
                        [attr.aria-labelledby]="'accordion-header-'">
                        <app-new-nursing-consultation [datetimeStartAttendance]="datetimeStartAttendance"
                            [listAllergyEpisode]="listAllergyEpisode" [idEpisode]="selectedEpisode"
                            [listPrescription]="listPrescription" [listProceduresNursing]="listProceduresNursing"
                            [listExternalPrescription]="this.listOpenExternalPrescription"
                            [listPrescriptionMedicatedSolution]="listPrescriptionMedicatedSolution"
                            [listProcedure]="listOpenProcedureStructs" [patientName]="bedStruct.patientName"
                            [birthDate]="bedStruct.birthDate" (saveNursingConsultation)="redirect()"
                            [episodeStruct]="episodeStruct" [observationConfigSector]="observationConfigSector"
                            [idBed]="idBed" [idSector]="idSector" [idService]="idService" [isReleasable]="isReleasable"
                            [isBedManagement]="isBedManagement" [idRoom]="idRoom" [listMedicalCare]="this.listMedicalCare"
                            [hasObservationProcedure]="hasObservationProcedure"
                            [evolutionFillingIsRequired]="evolutionFillingIsRequired" [idQueue]="idLastQueue"
                            [showTissGuideButton]="showTissGuideButton" [childComponent]="childComponent"
                            [listTextTemplate]="listTextTemplate">
                        </app-new-nursing-consultation>
                    </div>
                </cdk-accordion-item>
                <cdk-accordion-item *ngFor="let item of listNursingConsultation; let index = index;"
                    #accordionItemModule="cdkAccordionItem" class="profile-register-accordion-item" role="button"
                    tabindex="0" [attr.id]="'accordion-header-' + index" [attr.aria-expanded]="accordionItemModule.expanded"
                    [attr.aria-controls]="'accordion-body-' + index">
                    <div [ngClass]="{'profile-register-accordion-item-header-expanded': accordionItemModule.expanded}"
                        class="profile-register-accordion-item-header" (click)="accordionItemModule.toggle()">
                        Conduta - ({{ item.professionName }}, {{ item.userName }} - {{
                        item.datetimeInclusion | date:'dd/MM/yy, H:mm' }})
                        <span
                            [ngClass]="{'profile-register-accordion-item-description-expanded': accordionItemModule.expanded}"
                            class="profile-register-accordion-item-description">
                            Clique para {{ accordionItemModule.expanded ? 'fechar' : 'abrir' }}
                        </span>
                    </div>
                    <div class="profile-register-accordion-item-body" role="region"
                        [style.display]="accordionItemModule.expanded ? '' : 'none'" [attr.id]="'accordion-body-' + index"
                        [attr.aria-labelledby]="'accordion-header-' + index">
                        <app-nursing-consultation-accordion [listAllergyEpisode]="listAllergyEpisode"
                            [nursingConsultation]="item" [idEpisode]="selectedEpisode"
                            [listProcedure]="listClosedProcedureStructs" [patientName]="bedStruct.patientName"
                            [birthDate]="bedStruct.birthDate" [hasObservationProcedure]="hasObservationProcedure">
                        </app-nursing-consultation-accordion>
                    </div>
                </cdk-accordion-item>
            </cdk-accordion>
            <div class="row">
                <div class="col-md-2">
                    <button mat-flat-button color="primary" class="btn-block" (click)="forwardPatient()"
                        type="button">
                        <span *ngIf="isLoadingForward == false">Encaminhar Paciente</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoadingForward == true"></mat-spinner>
                    </button>
                </div>
            </div>
            <div class="title-header">
                <h1>Atendimentos Médicos</h1>
            </div>
            <cdk-accordion class="profile-register-accordion">
                <cdk-accordion-item *ngFor="let item of listMedicalCare; let index = index;"
                    #accordionItemModule="cdkAccordionItem" class="profile-register-accordion-item" role="button"
                    tabindex="0" [attr.id]="'accordion-header-' + index" [attr.aria-expanded]="accordionItemModule.expanded"
                    [attr.aria-controls]="'accordion-body-' + index">
                    <div [ngClass]="{'profile-register-accordion-item-header-expanded': accordionItemModule.expanded}"
                        class="profile-register-accordion-item-header" (click)="accordionItemModule.toggle()">
                        Atendimento - ({{ item.medicalCare.userName }} - {{item.medicalCare.datetimeInclusion |
                        date:'dd/MM/yy, H:mm' }})
                        <span
                            [ngClass]="{'profile-register-accordion-item-description-expanded': accordionItemModule.expanded}"
                            class="profile-register-accordion-item-description">
                            Clique para {{ accordionItemModule.expanded ? 'fechar' : 'abrir' }}
                        </span>
                    </div>
                    <div class="profile-register-accordion-item-body" role="region"
                        [style.display]="accordionItemModule.expanded ? '' : 'none'" [attr.id]="'accordion-body-' + index"
                        [attr.aria-labelledby]="'accordion-header-' + index">
                        <app-medical-care-accordion [completeMedicalCare]="item" [patientName]="bedStruct.patientName"
                            [birthDate]="bedStruct.birthDate"></app-medical-care-accordion>
                    </div>
                </cdk-accordion-item>
            </cdk-accordion>
            <ngContainer *ngIf="hasObservationProcedure">
                <div class="title-header">
                    <h1>Procedimentos solicitados pela enfermagem</h1>
                </div>
                <div class="card-list" *ngIf="listProceduresNursing != null">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listProceduresNursing">
                            <div class="card-item">
                                <div class="infos">
                                    <span class="primary">{{item.procedureName}}</span>
                                    <span class="secundary"><b>Nome: </b>{{item.userName}}</span>
                                    <span class="secundary">{{item.observation}}</span>
                                </div>
                                <div class="actions">
                                    <!-- <a>
                                        <mat-icon aria-hidden="false" aria-label="Editar" (click)="bedProcedureModal(item)">
                                            editar</mat-icon>
                                    </a> -->
                                    <a>
                                        <mat-icon aria-hidden="false" aria-label="Excluir"
                                            (click)="deleteProcedure(item.idMedicalProcedure)">delete</mat-icon>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ngContainer>
    
            <div class="col-md-2">
                <a mat-flat-button type="button" (click)="goBack()" color="accent" class=" btn-block">
                    Voltar
                </a>
            </div>
        </div>
    </div>
    
</div>

<div *ngIf="showTissGuideButton">
    <app-tiss-guide-management-floating-window [idEpisode]="selectedEpisode" [isAttendance]="true"></app-tiss-guide-management-floating-window>
</div>