import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ClassificationData, UtilsClassificationService } from 'src/app/classification/utils.service';
import { PatientLeaveModalComponent } from 'src/app/medic/pages/attend-patient-online/patient-leave-modal/patient-leave-modal.component';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { TwilioVideoComponent } from 'src/app/shared/components/twilio-video/twilio-video.component';
import { CareLinePriorityStatusEnum } from 'src/app/shared/enum/care-line-priority/care-line-priority-status.enum';
import { DiscriminatorTypeEnum } from 'src/app/shared/enum/discriminator-type.enum';
import { EvasionScreenEnum } from 'src/app/shared/enum/evasion-screen.enum';
import { NatureOfAttendanceEnum } from 'src/app/shared/enum/medical-record/nature-of-attendance.enum';
import { StatusQueueEnum } from 'src/app/shared/enum/status-queue.enum';
import { FlowControlEnum } from 'src/app/shared/enum/virtual-triage/flow-control.enum';
import { WebsocketTelemedicineActionEnum } from 'src/app/shared/enum/websocket-telemedicine-action.enum';
import { MedicalRecordService } from 'src/app/shared/services/API/orchestrator-patient/medical-record.service';
import { TriageService } from 'src/app/shared/services/API/orchestrator-patient/risk-classification.service';
import { FowardService } from 'src/app/shared/services/API/orchestrator-queue/foward.service';
import { UpdateStatusQueueService } from 'src/app/shared/services/API/orchestrator-queue/update-status-queue.service';
import { PatientAvoidanceLogService } from 'src/app/shared/services/API/srv-log/patient-avoidance-log.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { TriageRequest } from 'src/app/shared/services/requests/orchestrator-patient/triage.request';
import { StatusQueueRequest } from 'src/app/shared/services/requests/queue/status-queue.request';
import { PatientAvoidanceLogRequest } from 'src/app/shared/services/requests/srv-log/patient-avoidance-log.request';
import { AllergyStruct } from 'src/app/shared/services/structs/medical-record/allergy.struct';
import { EpisodePreEvaluationStruct } from 'src/app/shared/services/structs/medical-record/episode-pre-evaluation.struct';
import { WebsocketTelemedicineUtilService } from 'src/app/shared/services/websocket-util/websocket-telemedicine-util.service';

@Component({
  selector: 'app-virtual-triage-attend-patient',
  templateUrl: './virtual-triage-attend-patient.component.html',
  styleUrls: ['./virtual-triage-attend-patient.component.css']
})

export class VirtualTriageAttendPatientComponent implements OnInit, OnDestroy {
  @ViewChild('twiliovideo') twiliovideo: TwilioVideoComponent;

  constructor(private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    public medicalRecordService: MedicalRecordService,
    private alertService: AlertService,
    public router: Router,
    public fowardService: FowardService,
    public triageService: TriageService,
    public updateStatusQueueService: UpdateStatusQueueService,
    public websocketTelemedicineUtilService: WebsocketTelemedicineUtilService,
    public utilClassification: UtilsClassificationService,
    public maskService: MaskService,
    public patientAvoidanceLogService: PatientAvoidanceLogService,
  ) {
  }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.digital_prompt_service;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.virtual_triage_patient_list;

  public isLoading: boolean = true;

  public masks: Masks = this.maskService.getMasks();

  public idEpisode: number;
  public idQueue: number;
  public idRoom: number;
  public idSector: number;
  public idService: number;

  public episodePreEvaluation: EpisodePreEvaluationStruct;
  public classificationData: ClassificationData;
  public patientForm: FormGroup;
  public triage: TriageRequest = new TriageRequest();
  public pain: number;
  public painType: number;
  public painColorCode: string[] = [];
  public allergies: string;
  public discriminators: any[] = [];

  //Twilio
  public twilioTimeout: any;

  //Variáveis de fluxo
  public isFinished = false;
  public flowControl: number = 1;
  public preRegister: number = FlowControlEnum.pre_register;
  public protocolSelect: number = FlowControlEnum.protocol_select;
  public flowchartSelect: number = FlowControlEnum.flowchart_select;
  public discriminatorSelect: number = FlowControlEnum.discriminator_select;
  public counselingSelect: number = FlowControlEnum.counseling_select;
  public forwardEpisode: number = FlowControlEnum.forward_episode;
  public triageResume: number = FlowControlEnum.triage_resume;

  ngOnInit(): void {
    this.patientForm = this.formBuilder.group({
      patientName: [''],
      birthDate: [''],
      patientAge: [''],
    });

    if (parseInt(this.activatedRoute.snapshot.paramMap.get('idEpisode')))
      this.idEpisode = parseInt(this.activatedRoute.snapshot.paramMap.get('idEpisode'));

    if (parseInt(this.activatedRoute.snapshot.paramMap.get('idQueue')))
      this.idQueue = parseInt(this.activatedRoute.snapshot.paramMap.get('idQueue'));

    if (parseInt(this.activatedRoute.snapshot.paramMap.get('idRoom')))
      this.idRoom = parseInt(this.activatedRoute.snapshot.paramMap.get('idRoom'));

    if (parseInt(this.activatedRoute.snapshot.paramMap.get('idSector')))
      this.idSector = parseInt(this.activatedRoute.snapshot.paramMap.get('idSector'));

    if (parseInt(this.activatedRoute.snapshot.paramMap.get('idService')))
      this.idService = parseInt(this.activatedRoute.snapshot.paramMap.get('idService'));

    this.startWebsocket();

    this.twilioTimeout = setTimeout(() => this.startCall(), 1000);

    this.flowControl = this.utilClassification.getFlowControl();
    this.classificationData = this.utilClassification.getClassificationData();
    this.painColorCode[0] = this.classificationData ? this.classificationData.painColorCode : "";
    this.classificationData.discriminatorValues = this.classificationData.discriminatorValues && this.classificationData.discriminatorValues.length > 0 ? this.classificationData.discriminatorValues : [];
    let allergyString: string = "";
    if (this.classificationData.listAllergy) {
      let allergiesToShow: AllergyStruct[] = this.classificationData.listAllergy.filter(x => !x.isDeleted);
      allergiesToShow.forEach((element, index) => {

        if (!element.isDeleted) {
          if (index == allergiesToShow.length - 1) {
            allergyString += element.allergyName;
          }
          else {
            allergyString += element.allergyName + ", ";
          }
        }
      });
    }
    this.allergies = allergyString;

    if (this.flowControl == this.triageResume) {
      this.mapToTriageStruct();
    }
  }

  ngOnDestroy() {
    if (this && !this.isFinished) {
      this.cancelAttendance();
      this.utilClassification.removeFlowControl();
      this.utilClassification.removeClassificationData();
    }
    else {
      this.twiliovideo.onLeaveRoom(true);
      this.twiliovideo = null;
      this.websocketTelemedicineUtilService.clearSocket();
      this.utilClassification.removeFlowControl();
      this.utilClassification.removeClassificationData();
    }
  }

  cancelAttendanceBtn() {
    this.isFinished = true;
    this.cancelAttendance();
  }

  async cancelAttendance() {
    let statusQueueRequest: StatusQueueRequest = new StatusQueueRequest();
    statusQueueRequest.conclusionNote = "O atendimento foi cancelado antes de ser iniciado";
    statusQueueRequest.idQueue = this.idQueue;
    statusQueueRequest.idEpisode = this.idEpisode;
    statusQueueRequest.idRoom = this.idRoom;
    statusQueueRequest.idStatusQueue = StatusQueueEnum.nao_atendido;
    let action = WebsocketTelemedicineActionEnum.medicEvasion;
    this.updateStatusQueueService.updateStatus(statusQueueRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.isLoading = false;

        this.websocketTelemedicineUtilService.emitAction(action, this.idEpisode);
        this.websocketTelemedicineUtilService.clearSocket();
        this.alertService.show("Sucesso", "Fila do episódio atualizada com sucesso", AlertType.success);
        this.router.navigate(['/digital-prompt-service/virtual-triage/virtual-triage-patient-list', { idSector: this.idSector, idRoom: this.idRoom }]);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  toggleLoading() {
    this.isLoading = !this.isLoading;
  }

  startCall() {
    clearTimeout(this.twilioTimeout);
    if (sessionStorage.getItem("videoToken"))
      this.twiliovideo.onRoomChanged(sessionStorage.getItem("videoToken")).then(result => {
        this.isLoading = false;
      });
  }

  //Websocket
  startWebsocket() {
    if (!this.websocketTelemedicineUtilService.isConnect())
      this.websocketTelemedicineUtilService.connectwebsocketRoom(this.idEpisode, this);

    this.websocketTelemedicineUtilService.setContext(this);
    this.websocketTelemedicineUtilService.setFunctions(this.verifyTrigger, null, null, this.patientinternetDown);
    this.websocketTelemedicineUtilService.emitVerifying(this.idEpisode);
  }

  patientinternetDown() {
    this.alertDynamic("Aviso", "A chamada do paciente caiu, ele será retornado para fila!", AlertType.warning);
    this.router.navigate(['/digital-prompt-service/virtual-triage/virtual-triage-patient-list', { idSector: this.idSector, idRoom: this.idRoom }]);
  }

  verifyTrigger(client: any) {
    if (client.action == WebsocketTelemedicineActionEnum.patientEvasion) {
      this.websocketTelemedicineUtilService.clearSocket();
      this.openPatientLeaveModal();
    }
  }

  openPatientLeaveModal() {
    const dialogRef = this.dialog.open(PatientLeaveModalComponent, {
      data: {
        time: false,
        idQueue: this.idQueue,
        idEpisode: this.idEpisode,
        idRoom: this.idRoom,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        this.patientAvoidance();
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  onLeaveRoom() {
  }

  mapToTriageStruct(): void {
    this.classificationData = this.utilClassification.getClassificationData();
    this.triage.conclusionNotes = this.classificationData.conclusionNotes;
    this.triage.listCounseling = this.classificationData.listCounseling ? this.classificationData.listCounseling : [];
    let discriminatorValues = this.classificationData.discriminatorValues && this.classificationData.discriminatorValues.length > 0 ? this.classificationData.discriminatorValues : false;
    this.triage.idNatureOfAttendance = NatureOfAttendanceEnum.online;
    this.triage.startClassification = this.classificationData.datetimeStartTriage;
    this.triage.idEpisode = this.classificationData.idEpisode;
    this.triage.idGravity = this.classificationData.priority.idPriorityColor;
    this.triage.gravityColor = this.classificationData.priority.coloerHex;
    this.triage.temperature = discriminatorValues[DiscriminatorTypeEnum.Temperatura] ? parseFloat(discriminatorValues[DiscriminatorTypeEnum.Temperatura]) : null;
    this.triage.respiratoryFrequency = discriminatorValues[DiscriminatorTypeEnum.FrequenciaRespiratoria] ? parseInt(discriminatorValues[DiscriminatorTypeEnum.FrequenciaRespiratoria]) : null;
    this.triage.heartRate = discriminatorValues[DiscriminatorTypeEnum.FrequenciaCardiaca] ? parseInt(discriminatorValues[DiscriminatorTypeEnum.FrequenciaCardiaca]) : null;
    if (!Array.isArray(this.classificationData.discriminatorAllValues))
      this.classificationData.discriminatorAllValues = [];

    this.triage.capillaryFillingTimeValue = this.classificationData.perfusaoCapilarValue;
    let heartRate = this.classificationData.discriminatorAllValues.find(c => c.idDiscriminatorType == DiscriminatorTypeEnum.FrequenciaCardiaca);
    if (heartRate)
      this.triage.heartRateRegular = heartRate.type == 'regular';
    else if (this.classificationData.frequencyPulseType)
      this.triage.heartRateRegular = this.classificationData.frequencyPulseType == 'regular';
    else
      this.triage.heartRateRegular = null;

    this.triage.glucose = discriminatorValues[DiscriminatorTypeEnum.Glicemia] ? parseInt(discriminatorValues[DiscriminatorTypeEnum.Glicemia]) : null;
    this.triage.bloodPressureDiastole = discriminatorValues[DiscriminatorTypeEnum.PressaoArterial] ? parseInt(discriminatorValues[DiscriminatorTypeEnum.PressaoArterial].split("/")[1]) : null;;
    this.triage.bloodPressureSystole = discriminatorValues[DiscriminatorTypeEnum.PressaoArterial] ? parseInt(discriminatorValues[DiscriminatorTypeEnum.PressaoArterial].split("/")[0]) : null;
    this.triage.saturation = discriminatorValues[DiscriminatorTypeEnum.Saturacao] ? parseInt(discriminatorValues[DiscriminatorTypeEnum.Saturacao]) : null;
    let discriminatorPain = this.classificationData.discriminatorAllValues.find(c => c.idDiscriminatorType == DiscriminatorTypeEnum.Dor || c.idDiscriminatorType == DiscriminatorTypeEnum.DorCoceira);
    this.pain = discriminatorPain ? discriminatorPain.value : null;
    this.painType = discriminatorPain ? discriminatorPain.type : null;
    this.triage.idPain = discriminatorPain ? discriminatorPain.type : null;
    this.triage.complaint = this.classificationData.complain;
    this.triage.flowchart = this.classificationData.flowchartName;
    this.triage.idFlowchart = this.classificationData.idFlowchart;
    this.triage.idArrivalReason = this.classificationData.idArrivalReason;
    this.triage.discriminator = !this.classificationData.isPatientWhite ? this.classificationData.discriminator.discriminatorName : this.classificationData.arrivalReason;
    this.triage.idDiscriminator = this.classificationData.discriminator ? this.classificationData.discriminator.idDiscriminator : null;
    this.triage.glasgow = discriminatorValues[DiscriminatorTypeEnum.Glasgow] ? discriminatorValues[DiscriminatorTypeEnum.Glasgow] : null;
    this.triage.glasgowEye = this.classificationData.glasgowEye;
    this.triage.glasgowVerbal = this.classificationData.glasgowVerbal;
    this.triage.glasgowMotor = this.classificationData.glasgowMotor;
    let DiscriminatorSaturation = this.classificationData.discriminatorAllValues.find(c => c.idDiscriminatorType == DiscriminatorTypeEnum.Saturacao);
    this.triage.atmosphericAir = DiscriminatorSaturation ? DiscriminatorSaturation.type == "atmospheric" : null;
    this.triage.capillaryFillingTime = this.classificationData.perfusaoCapilar;
    this.triage.fallRisk = this.classificationData.radioFallRisk ? true : false;
    this.triage.idSuspicion = this.classificationData.idSuspicion;
    this.triage.diseaseHistory = this.classificationData.diseaseHistory;
    this.triage.comorbidities = this.classificationData.comorbidities;
    this.triage.height = this.classificationData.height ? parseFloat(this.classificationData.height) : null;
    this.triage.weight = this.classificationData.weight;
    this.triage.idFlow = this.classificationData.forward.idFlow;
    this.triage.idQueue = this.classificationData.forward.idLastQueue;
    this.triage.idRoom = this.classificationData.idRoom;
    this.triage.roomName = this.classificationData.forward.locationName;
    this.triage.idSector = this.classificationData.idSector;
    this.triage.forwardName = this.classificationData.forward.locationName;
    this.triage.idForward = this.classificationData.idRoom;
    this.triage.gravityName = this.classificationData.priority.priorityColorName;
    this.triage.observations = (this.classificationData.observation ? this.classificationData.observation : "") + "" + (this.classificationData.arrivalReasonDesc ? this.classificationData.arrivalReasonDesc : "");
    this.triage.allergies = this.allergies;
    this.triage.billingData = this.classificationData.billingData;
    this.triage.idLastFlowchart = this.classificationData.idLastFlowchart;
    this.triage.lastFlowchart = this.classificationData.lastFlowchart;
    this.triage.idPatient = this.classificationData.idPatient;
    this.triage.temperatureComplementaryData = this.classificationData.temperatureComplementaryData;
    this.triage.heartRateComplementaryData = this.classificationData.heartRateComplementaryData;
    this.triage.bloodPressureDiastoleComplementaryData = this.classificationData.bloodPressureDiastoleComplementaryData;
    this.triage.bloodPressureSystoleComplementaryData = this.classificationData.bloodPressureSystoleComplementaryData;
    this.triage.respiratoryFrequencyComplementaryData = this.classificationData.respiratoryFrequencyComplementaryData;
    this.triage.glucoseComplementaryData = this.classificationData.glucoseComplementaryData;
    this.triage.saturationComplementaryData = this.classificationData.saturationComplementaryData;

    this.triage.listAllergy = this.classificationData.listAllergy;
    if (this.classificationData.idCareLinePriority) {
      this.triage.careLinePriorityStatusStruct.idCareLinePriority = this.classificationData.idCareLinePriority;
      this.triage.careLinePriorityStatusStruct.idCareLinePriorityStatus = CareLinePriorityStatusEnum.suspeita;
    }

    this.triage.listIdComorbodity = this.classificationData.listIdComorbodity;
    this.triage.hasFlowchartIA = this.classificationData.hasFlowchartIA;
    this.triage.iaResult = this.classificationData.iaResult;
    this.triage.idArrivalType = this.classificationData.idArrivalType;
    this.triage.idOrigin = this.classificationData.idOrigin;

    if (heartRate && heartRate.state)
      this.triage.isHeartRateSleeping = heartRate.state == 'sleepy';
    else if (this.classificationData.frequencyPulseState && this.classificationData.frequencyPulseState)
      this.triage.isHeartRateSleeping = this.classificationData.frequencyPulseState == 'sleepy';
    else
      this.triage.isHeartRateSleeping = null;
  }

  next() {
    this.classificationData = this.utilClassification.getClassificationData();

    this.patientForm.get('patientName').setValue(this.classificationData.patientData.patientName);
    let birthDate = this.maskService.formatDateToString(this.classificationData.birthDate, false);
    this.patientForm.get('birthDate').setValue(birthDate);
    this.patientForm.get('patientAge').setValue(
      this.classificationData.ageYears.toString() + 'a ' + this.classificationData.ageMonth.toString() + 'm ' + this.classificationData.ageDay.toString() + 'd');

    if (this.flowControl != this.triageResume) {
      this.flowControl += 1;
    }
    else {
      this.submitTriage();
    }
    this.utilClassification.updateFlowControl(this.flowControl);
  }

  back() {
    this.flowControl -= 1;
  }

  submitTriage() {
    this.isLoading = true;
    this.triageService.post(this.triage).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        let classificationData = this.utilClassification.getClassificationData();
        if (classificationData.forward && classificationData.forward.idFlow != null) {
          this.websocketTelemedicineUtilService.emitAction(WebsocketTelemedicineActionEnum.forward, this.idEpisode);
        }

        this.alertDynamic('Sucesso', 'Triagem salva com sucesso', AlertType.success)
        this.isFinished = true;
        this.twiliovideo.onLeaveRoom(true);
        this.router.navigate(['/digital-prompt-service/virtual-triage/virtual-triage-patient-list', { idRoom: this.classificationData.idRoom, idSector: this.classificationData.idSector }]);

      }, error: (err) => {
        this.alertService.show('Erro Inesperado', err, AlertType.error);
        this.isLoading = false;
        return;
      }
    });
  }

  alertDynamic(alertTypeDescription: string, alertDescription: string, alertType: AlertType) {
    if (alertType && alertType.valueOf() == AlertType.error)
      console.log(alertDescription);

    this.alertService.show(alertTypeDescription, alertDescription, alertType ? alertType.valueOf() : AlertType.error);
    this.endLoading();
  }

  endLoading() {
    this.isLoading = false;
  }

  patientAvoidance() {
    let request: PatientAvoidanceLogRequest = new PatientAvoidanceLogRequest();
    request.patientAvoidanceLog.idEpisode = this.idEpisode;
    request.patientAvoidanceLog.idEvasionScreen = this.flowControl;
    request.patientAvoidanceLog.idPatient = this.utilClassification.getEpisodeData().idPatient;
    request.patientAvoidanceLog.patientExitLog = this.patientEvasionMapper();

    this.patientAvoidanceLogService.post(request).subscribe({
      next: (response) => {
        // if (response.isError) {
        //   this.alertService.show('Erro', response.errorDescription, AlertType.error);
        //   this.isLoading = false;
        //   return;
        // }

        this.isFinished = true;
        this.isLoading = false;
        this.twiliovideo.onLeaveRoom(true);
        this.router.navigate(['/digital-prompt-service/virtual-triage/virtual-triage-patient-list', { idSector: this.idSector, idRoom: this.idRoom }]);
      },
      error: (error) => {
        this.alertService.show('Erro Inesperado', error, AlertType.error);
        this.isLoading = false;
        this.isLoading = false;
        this.twiliovideo.onLeaveRoom(true);
        this.router.navigate(['/digital-prompt-service/virtual-triage/virtual-triage-patient-list', { idSector: this.idSector, idRoom: this.idRoom }]);
      }
    });
  }

  patientEvasionMapper(): any {
    this.classificationData = this.utilClassification.getClassificationData();

    let patientAvoidanceLog: string[] = [];
    patientAvoidanceLog.push(this.patientAvoidancePreRegisterMapper(this.classificationData));

    switch (this.flowControl) {
      case EvasionScreenEnum.TriagemVirtualPreRegistro:
        break;
      case EvasionScreenEnum.TriagemVirtualProtocolo:
        patientAvoidanceLog.push(this.patientAvoidanceProtocolMapper(this.classificationData));
        break;
      case EvasionScreenEnum.TriagemVirtualFluxograma:
        patientAvoidanceLog.push(this.patientAvoidanceProtocolMapper(this.classificationData));
        patientAvoidanceLog.push(this.patientAvoidanceFlowchartMapper(this.classificationData));
        break;
      case EvasionScreenEnum.TriagemVirtualDiscriminador:
        patientAvoidanceLog.push(this.patientAvoidanceProtocolMapper(this.classificationData));
        patientAvoidanceLog.push(this.patientAvoidanceFlowchartMapper(this.classificationData));
        patientAvoidanceLog.push(this.patientAvoidanceDiscriminatorMapper(this.classificationData));
        break;
      case EvasionScreenEnum.TriagemVirtualAconselhamento:
        patientAvoidanceLog.push(this.patientAvoidanceProtocolMapper(this.classificationData));
        patientAvoidanceLog.push(this.patientAvoidanceFlowchartMapper(this.classificationData));
        patientAvoidanceLog.push(this.patientAvoidanceDiscriminatorMapper(this.classificationData));
        patientAvoidanceLog.push(this.patientAvoidanceCounselingMapper(this.classificationData));
        break;
      case EvasionScreenEnum.TriagemVirtualEncaminhamento:
        patientAvoidanceLog.push(this.patientAvoidanceProtocolMapper(this.classificationData));
        patientAvoidanceLog.push(this.patientAvoidanceFlowchartMapper(this.classificationData));
        patientAvoidanceLog.push(this.patientAvoidanceDiscriminatorMapper(this.classificationData));
        patientAvoidanceLog.push(this.patientAvoidanceCounselingMapper(this.classificationData));
        patientAvoidanceLog.push(this.patientAvoidanceForwardMapper(this.classificationData));
        break;
      case EvasionScreenEnum.TriagemVirtualResumo:
        patientAvoidanceLog.push(this.patientAvoidanceProtocolMapper(this.classificationData));
        patientAvoidanceLog.push(this.patientAvoidanceFlowchartMapper(this.classificationData));
        patientAvoidanceLog.push(this.patientAvoidanceDiscriminatorMapper(this.classificationData));
        patientAvoidanceLog.push(this.patientAvoidanceCounselingMapper(this.classificationData));
        patientAvoidanceLog.push(this.patientAvoidanceForwardMapper(this.classificationData));
        patientAvoidanceLog.push(this.patientAvoidanceTriageResumeMapper(this.classificationData));
        break;
    }

    return patientAvoidanceLog.join('\n');
  }

  patientAvoidancePreRegisterMapper(classificationData: ClassificationData): any {
    const infoParts: string[] = [];
    const patientData = classificationData?.patientData;

    if (patientData) {
      if (patientData.patientName) infoParts.push(`Nome: ${patientData.patientName}`);
      if (patientData.socialName) infoParts.push(`Nome Social: ${patientData.socialName}`);
      if (patientData.birthDate) infoParts.push(`Data de Nascimento: ${this.maskService.formatDateToString(patientData.birthDate)}`);
      if (patientData.patientAge) infoParts.push(`Idade: ${patientData.patientAge}`);
      if (patientData.genderName) infoParts.push(`Gênero: ${patientData.genderName}`);
      if (patientData.cpf) infoParts.push(`CPF: ${patientData.cpf}`);
      if (patientData.cns) infoParts.push(`CNS: ${patientData.cns}`);
      if (patientData.phone1) infoParts.push(`Telefone 1: ${patientData.phone1}`);
      if (patientData.phone2) infoParts.push(`Telefone 2: ${patientData.phone2}`);
    }

    return infoParts.length
      ? `Pré - Registro\n${infoParts.join(', ')}\n`
      : '';
  }

  patientAvoidanceProtocolMapper(classificationData: ClassificationData): any {
    const infoParts: string[] = [];

    if (classificationData) {
      if (classificationData.protocolName) infoParts.push(`Seleção de Protocolo: ${classificationData.protocolName}`);
    }

    return infoParts.length
      ? `Protocolo\n${infoParts.join('. ')}\n`
      : '';
  }

  patientAvoidanceFlowchartMapper(classificationData: ClassificationData): any {
    const infoParts: string[] = [];

    if (classificationData) {
      if (classificationData.complain) infoParts.push(`Queixas: ${classificationData.complain}`);
      if (classificationData.flowchartName) infoParts.push(`Fluxogramas: ${classificationData.flowchartName}`);
    }

    return infoParts.length
      ? `Fluxograma\n${infoParts.join(', ')}\n`
      : '';
  }

  patientAvoidanceDiscriminatorMapper(classificationData: ClassificationData): any {
    const infoParts: string[] = [];

    if (classificationData) {
      if (classificationData.discriminator?.discriminatorName) infoParts.push(`Discriminador: ${classificationData.discriminator.discriminatorName}`);
      if (classificationData.priority) infoParts.push(`Prioridade: ${classificationData.priority.priorityColorName}`);
    }

    return infoParts.length
      ? `Discriminador\n${infoParts.join(', ')}\n`
      : '';
  }

  patientAvoidanceCounselingMapper(classificationData: ClassificationData): any {
    const infoParts: string[] = [];

    if (classificationData) {
      if (classificationData.listCounseling) {
        let listCounselingName = classificationData.listCounseling.map(x => x.counselingName);
        if (listCounselingName) {
          let counselingNameGrouped = listCounselingName.join(', ');
          infoParts.push(`Aconselhamentos: ${counselingNameGrouped}`);
        }
      }
      if (classificationData.observation) infoParts.push(`Observação: ${classificationData.observation}`);
      if (classificationData.patientData?.phoneConfirmation) infoParts.push(`Confirmar telefone: ${classificationData.patientData.phoneConfirmation}`);
    }

    return infoParts.length
      ? `Aconselhamento\n${infoParts.join(', ')}\n`
      : '';
  }

  patientAvoidanceForwardMapper(classificationData: ClassificationData): any {
    const infoParts: string[] = [];

    if (classificationData) {
      if (classificationData.forward?.locationName) infoParts.push(`Próximo destino do paciente: ${classificationData.forward.locationName}`);
      if (classificationData.specialNecessityName) infoParts.push(`Necessidades especiais: ${classificationData.specialNecessityName}`);
      if (classificationData.observation) infoParts.push(`Observação: ${classificationData.observation}`);
      if (classificationData.patientData?.phoneConfirmation) infoParts.push(`Conclusão: ${classificationData.conclusionNotes}`);
    }

    return infoParts.length
      ? `Encaminhamento\n${infoParts.join(', ')}\n`
      : '';
  }

  patientAvoidanceTriageResumeMapper(classificationData: ClassificationData): any {
    const infoParts: string[] = [];

    if (classificationData) {
      if (classificationData.priority) infoParts.push(`Prioridade: ${classificationData.priority.priorityColorName}`);
      if (classificationData.complain) infoParts.push(`Queixa/Motivo: ${classificationData.complain}`);
      if (classificationData.protocolName) infoParts.push(`Protocolo: ${classificationData.protocolName}`);
      if (classificationData.flowchartName) infoParts.push(`Fluxograma: ${classificationData.flowchartName}`);
      if (classificationData.discriminator?.discriminatorName) infoParts.push(`Discriminador: ${classificationData.discriminator.discriminatorName}`);
      if (classificationData.radioFallRisk) infoParts.push(`Risco de queda: ${classificationData.radioFallRisk ? 'SIM' : 'NÃO'}`);
      if (classificationData.listCounseling) {
        let listCounselingName = classificationData.listCounseling.map(x => x.counselingName);
        if (listCounselingName) {
          let counselingNameGrouped = listCounselingName.join(', ');
          infoParts.push(`Aconselhamentos: ${counselingNameGrouped}`);
        }
      }
      if (classificationData.patientData?.patientName) infoParts.push(`Protocolo: ${classificationData.patientData.patientName}`);
      if (classificationData.patientData?.birthDate) infoParts.push(`Data de Nascimento: ${this.maskService.formatDateToString(classificationData.patientData.birthDate)}`);
      if (classificationData.patientData?.patientAge) infoParts.push(`Idade: ${classificationData.patientData.patientAge}`);
    }

    return infoParts.length
      ? `Resumo\n${infoParts.join(', ')}`
      : '';
  }
}