import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-scheduled-agreement-plan-confirm-modal',
  templateUrl: './scheduled-agreement-plan-confirm-modal.component.html',
  styleUrls: ['./scheduled-agreement-plan-confirm-modal.component.css']
})
export class ScheduledAgreementPlanConfirmModalComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ScheduledAgreementPlanConfirmModalComponent>,
  ) { }


  ngOnInit(): void {
    if (!this.data.scheduledPatientHealthcareAgreement)
        this.matDialogRef.close(); 
  } 

  confirm(){
    this.matDialogRef.close({confirm: true}); 
  }

  cancel() {
    this.matDialogRef.close({confirm: false}); 
  }

}
