import { BillingDataStruct } from "../../structs/billing/billing-data.struct";
import { BillingDataRequest } from "../billing/billing-data-request";
import { FlowSequenceUpdateRequest } from "../flow/flow-sequence-update.request";
import { FlowSequenceRequest } from "../flow/flow-sequence.request";
import { EpisodeCareLineRequest } from "../care-line/episode-care-line.request";
import { EpisodeStatusRequest } from "../medical-record/episode-status.request";
import { MedicalCareReportRequest } from "./medical-care-report.request";
import { PatientAppointmentCareRequest } from "../medical-record/patient-appointment-care.request";

export class CompletePatientAppointmentCareRequest {
    public patientAppointmentCareRequest: PatientAppointmentCareRequest;
    
    public medicalCareReportRequest: MedicalCareReportRequest;
    
    public billingDataRequest: BillingDataRequest;
    
    public episodeStatusRequest: EpisodeStatusRequest;
    
    public episodeCareLineRequest: EpisodeCareLineRequest;
    
    public conclusionType: number;
}