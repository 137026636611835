<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <div class="page-title">
            <div>
                <a class="back-link" routerLink="/digital-prompt-service"><mat-icon aria-hidden="false"
                        aria-label="Plus">arrow_back</mat-icon> Módulo PA Digital</a>
                <h1>Configuração de Botões da Autotriagem</h1>
            </div>
            <div class="col-12 col-md-2">
                <button mat-flat-button type="submit" color="primary" class=" btn-block" (click)="submit()">
                    <span *ngIf="isLoading == false">Salvar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn"
                        *ngIf="isLoading == true"></mat-spinner>
                </button>
            </div>
        </div>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="title-header">
                    <h1>Botões de Direcionamento</h1>
                </div>

                <app-direction-button-list [formGroup]="this.model" [formArrayName]="'listButtons'"
                    [listButtons]="listDirectionButton" [listServiceType]="listServiceType"
                    [validationRegex]="validationRegex" [telemedicineConfig]="telemedicineConfig">
                </app-direction-button-list>

            </form>
        </div>
    </div>
</div>