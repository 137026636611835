import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DigitalPromptRoutingModule } from './digital-prompt-routing.module';
import { DigitalPromptComponent } from './pages/digital-prompt/digital-prompt.component';
import { DigitalPromptLogoModalComponent } from './pages/digital-prompt-config/digital-prompt-logo-modal/digital-prompt-logo-modal.component';
import { DigitalPromptConfigComponent } from './pages/digital-prompt-config/digital-prompt-config.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { NgxMaskModule } from 'ngx-mask';
import { NgxColorsModule } from 'ngx-colors';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { SharedModule } from '../shared/shared.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MedicalSpecialtyListComponent } from './pages/medical-specialty-list/medical-specialty-list.component';
import { MedicalSpecialtyDeleteModalComponent } from './pages/medical-specialty-list/medical-specialty-delete-modal/medical-specialty-delete-modal.component';
import { MedicalSpecialtyRegisterComponent } from './pages/medical-specialty-register/medical-specialty-register.component';
import { UnableChangeStatusModalComponent } from './pages/digital-prompt-config/unable-change-status-modal/unable-change-status-modal.component';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ScheduleListComponent } from './pages/schedule-list/schedule-list.component';
import { ScheduleRegisterComponent } from './pages/schedule-register/schedule-register.component';
import { ScheduleDeleteModalComponent } from './pages/schedule-list/schedule-delete-modal/schedule-delete-modal.component';
import { ViewCalendarScheduleModalComponent } from './pages/schedule-register/view-calendar-schedule-modal/view-calendar-schedule-modal.component';
import { BlockedDaysModalComponent } from './pages/schedule-register/blocked-days-modal/blocked-days-modal.component';
import { BlockedDaysDeleteModalComponent } from './pages/schedule-register/blocked-days-delete-modal/blocked-days-delete-modal.component';
import { PreventedChangingTimeCalendarModalComponent } from './pages/schedule-register/prevented-changing-time-calendar-modal/prevented-changing-time-calendar-modal.component';
import { CancelAppointmentsConfirmModalComponent } from './pages/schedule-register/prevented-changing-time-calendar-modal/cancel-appointments-confirm-modal/cancel-appointments-confirm-modal.component';
import { TelemedicineConfigComponent } from './pages/telemedicine-config/telemedicine-config.component';
import { TelemedicineLogoModalComponent } from './pages/telemedicine-config/telemedicine-logo-modal/telemedicine-logo-modal.component';
import { HealthPlanListComponent } from './pages/telemedicine-config/health-plan-list/health-plan-list.component';
import { DirectionButtonListComponent } from './pages/self-triage-button-config/direction-button-list/direction-button-list.component';
import { SelfTriageButtonConfigComponent } from './pages/self-triage-button-config/self-triage-button-config.component';
import { SelfTriageConfigComponent } from './pages/self-triage-config/self-triage-config.component';
import { SelfTriageLogoModalComponent } from './pages/self-triage-config/self-triage-logo-modal/self-triage-logo-modal.component';
import { VirtualTriagePatientListComponent } from './pages/virtual-triage-patient-list/virtual-triage-patient-list.component';
import { VirtualTriageAttendPatientComponent } from './pages/virtual-triage-attend-patient/virtual-triage-attend-patient.component';
import { DiscriminatorSelectComponent } from './pages/virtual-triage-attend-patient/discriminator-select/discriminator-select.component';
import { ForwardSelectComponent } from './pages/virtual-triage-attend-patient/forward-select/forward-select.component';
import { CounselingSelectionComponent } from './pages/virtual-triage-attend-patient/counseling-selection/counseling-selection.component';
import { FlowchartSelectionComponent } from './pages/virtual-triage-attend-patient/flowchart-selection/flowchart-selection.component';
import { ProtocolSelectComponent } from './pages/virtual-triage-attend-patient/protocol-select/protocol-select.component';
import { PreRegisterComponent } from './pages/virtual-triage-attend-patient/pre-register/pre-register.component';
import { ClassificationModule } from '../classification/classification.module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { VirtualTriageConfigRegisterComponent } from './pages/virtual-triage-config-register/virtual-triage-config-register.component';
import { VirtualTriageConfigListComponent } from './pages/virtual-triage-config-list/virtual-triage-config-list.component';
import { VirtualTriageConfigDeleteModalComponent } from './pages/virtual-triage-config-list/virtual-triage-config-delete-modal/virtual-triage-config-delete-modal.component';

@NgModule({
  declarations: [
    DigitalPromptComponent,
    DigitalPromptConfigComponent,
    DigitalPromptLogoModalComponent,
    MedicalSpecialtyListComponent,
    MedicalSpecialtyDeleteModalComponent,
    MedicalSpecialtyRegisterComponent,
    UnableChangeStatusModalComponent,
    ScheduleListComponent,
    ScheduleRegisterComponent,
    ScheduleDeleteModalComponent,
    ViewCalendarScheduleModalComponent,
    BlockedDaysModalComponent,
    BlockedDaysDeleteModalComponent,
    PreventedChangingTimeCalendarModalComponent,
    CancelAppointmentsConfirmModalComponent,
    TelemedicineConfigComponent,
    TelemedicineLogoModalComponent,
    HealthPlanListComponent,
    SelfTriageConfigComponent,
    SelfTriageButtonConfigComponent,
    SelfTriageLogoModalComponent,
    DirectionButtonListComponent,
    VirtualTriagePatientListComponent,
    VirtualTriageAttendPatientComponent,
    PreRegisterComponent,
    ProtocolSelectComponent,
    FlowchartSelectionComponent,
    CounselingSelectionComponent,
    ForwardSelectComponent,
    DiscriminatorSelectComponent,
    VirtualTriageConfigRegisterComponent,
    VirtualTriageConfigListComponent,
    VirtualTriageConfigDeleteModalComponent,
  ],
  imports: [
    CommonModule,
    ClassificationModule,
    DigitalPromptRoutingModule,
    SharedModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonModule,
    NgxMaskModule.forRoot(),
    MatCheckboxModule,
    MatTreeModule,
    MatRadioModule,
    ClipboardModule,
    MatTooltipModule,
    MatToolbarModule,
    NgxMatFileInputModule,
    CdkAccordionModule,
    MatNativeDateModule,
    MatDatepickerModule,
    NgxColorsModule,
    MatDialogModule,
    MatTableModule,
    MatCardModule,
    NgxMaskModule,
    NgxChartsModule,
    MatPaginatorModule,
  ]
})
export class DigitalPromptModule { }
