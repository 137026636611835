import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-room-setup-modal',
  templateUrl: './room-setup-modal.component.html',
  styleUrls: ['./room-setup-modal.component.css']
})
export class RoomSetupModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<RoomSetupModalComponent>) {
  }

  public isLoading: boolean;

  ngOnInit(): void {
    this.isLoading = false;
  }

  close() {
    this.matDialogRef.close(false);
  }

  continue() {
    this.matDialogRef.close(true);
  }
}