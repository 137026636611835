export class ClassificationRiskAudit {
    public idClassificationRiskAudit: number;

    public idUserFirstAuditor: number;

    public idUserSecondAuditor: number;

    public idStatus: number;

    public isDivergence: boolean;

    public idProtocol: number;

    public datetimeStart: Date;

    public datetimeEnd: Date;

    public datetimeInclusion: Date;

    public datetimeConclusion: Date;

    public idHealthUnit: number;
}