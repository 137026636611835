import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ListWebhookTypeResponse } from '../../responses/integration/list-webhook-type.response';

@Injectable({
  providedIn: 'root'
})
export class WebhookTypeService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listWebhookType(idWebhook: number = null): Observable<ListWebhookTypeResponse> {

    let uri = `WebhookType/getAllUnused?`;

    if(idWebhook) uri += `idWebhook=${idWebhook}`

    return this.httpClient.get<ListWebhookTypeResponse>(environment.urlApiIntegration + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}