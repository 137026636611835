import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { EpisodeStatusRequest } from 'src/app/shared/services/requests/medical-record/episode-status.request';
import { EpisodeStatusService } from 'src/app/shared/services/API/medical-record/episode-status.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { EpisodeStatusEnum } from 'src/app/shared/enum/episode-status.enum';
import { GetCompleteMultiProfessionalConfigStruct } from 'src/app/shared/services/structs/multi-professional/get-complete-multi-professional-config.struct';

@Component({
  selector: 'app-conclusion-note-modal',
  templateUrl: './conclusion-note-modal.component.html',
  styleUrls: ['./conclusion-note-modal.component.css']
})
export class ConclusionNoteModalComponent implements OnInit {

  @Input() completeMultiprofessionalConfig: GetCompleteMultiProfessionalConfigStruct;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ConclusionNoteModalComponent>,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    public episodeStatusService: EpisodeStatusService) { }

  public conclusionModel: UntypedFormGroup;
  public episodeStatusAltaEnum: EpisodeStatusEnum = EpisodeStatusEnum.FinalizadoAlta;
  public episodeStatusAltaAdministrativaEnum: EpisodeStatusEnum = EpisodeStatusEnum.FinalizadoAltaAdministrativa;
  public title: string;
  public isLoading: boolean = false;

  ngOnInit(): void {
    if (this.data.statusEpisode == this.episodeStatusAltaEnum) {
      this.title = "FINALIZAR - ALTA"
    }
    this.conclusionModel = this.formBuilder.group({
      conclusionNote: [''],
    });
  }


  clickCancel() {
    this.matDialogRef.close();
  }

  clickOK() {
    this.isLoading = true;
    let episodeStatusRequest: EpisodeStatusRequest = new EpisodeStatusRequest();
    episodeStatusRequest.conclusionNote = this.conclusionModel.get("conclusionNote").value;
    episodeStatusRequest.idStatus = this.data.statusEpisode.valueOf();
    episodeStatusRequest.isBedManagement = this.data.isBedManagement;
    this.episodeStatusService.updateEpisodeStatus(this.data.selectedEpisode, episodeStatusRequest).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.matDialogRef.close({ conclusionNote: false });
        return;
      }
      this.matDialogRef.close({ conclusionNote: true });
    });
  }
}

