import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { HygienizationStatusEnum } from 'src/app/shared/enum/hygienization-status.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { BedCategoryService } from 'src/app/shared/services/API/bed/bed-category.service';
import { BedHygienizationConfigService } from 'src/app/shared/services/API/bed/bed-hygienization-config.service';
import { BedStatusService } from 'src/app/shared/services/API/bed/bed-status.service';
import { BedTypeService } from 'src/app/shared/services/API/bed/bed-type.service';
import { BedService } from 'src/app/shared/services/API/bed/bed.service';
import { LocationByModuleService } from 'src/app/shared/services/API/flow/location-by-module.service';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { BedCategoryModel } from 'src/app/shared/services/models/bed/bed-category.model';
import { BedHygienizationStatusModel } from 'src/app/shared/services/models/bed/bed-hygienization-status.model';
import { BedStatusModel } from 'src/app/shared/services/models/bed/bed-status.model';
import { BedTypeModel } from 'src/app/shared/services/models/bed/bed-type.model';
import { BedRequest } from 'src/app/shared/services/requests/bed/bed.request';
import { SectorStruct } from 'src/app/shared/services/structs/flow/sector.struct';

@Component({
  selector: 'app-bed-register',
  templateUrl: './bed-register.component.html',
  styleUrls: ['./bed-register.component.css']
})
export class BedRegisterComponent implements OnInit {

  constructor(private formBuilder: UntypedFormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private bedService: BedService,
    private bedStatusService: BedStatusService,
    private bedTypeService: BedTypeService,
    private bedCategoryService: BedCategoryService,
    private bedHygienizationConfigService: BedHygienizationConfigService,
    private locationByModuleService: LocationByModuleService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.observation;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.observation_bed;

  public model: UntypedFormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;
  public actualServiceModule: number;
  public listBedType: BedTypeModel[];
  public listBedCategory: BedCategoryModel[];
  public listBedStatus: BedStatusModel[];
  public listHygienizationStatus: BedHygienizationStatusModel[];
  public listSector: SectorStruct[];
  public hygienization: boolean = false;
  public hygienizingStatus: number = HygienizationStatusEnum.em_higienizacao;
  public occupiedStatus: number = HygienizationStatusEnum.em_uso;

  public masks: Masks;
  public idBed: number;
  public bedRequest: BedRequest;

  ngOnInit(): void {

    this.isLoading = false;

    this.model = this.formBuilder.group({
      name: ['', [Validators.required]],
      idBedStatus: ['', [Validators.required]],
      idBedType: ['', [Validators.required]],
      idBedCategory: ['', [Validators.required]],
      idSector: [null, [Validators.required]],
      idHygienizationStatus: [null],
    });

    this.masks = this.maskService.getMasks();

    this.bedRequest = new BedRequest();

    this.idBed = null;
    this.isUpdate = false;
    this.populateBedStatusSelect();
    this.populateBedTypeSelect();
    this.populateBedCategorySelect();
    this.populateSectorSelect();
    if (this.activatedRoute.snapshot.paramMap.get('idBed')) {
      this.idBed = parseInt(this.activatedRoute.snapshot.paramMap.get('idBed'));
    }

    if (this.activatedRoute.snapshot.paramMap.get('hygienization') === 'true') {
      this.hygienization = true;
      this.model.get('idHygienizationStatus').setValidators([Validators.required]);
      this.model.get('idHygienizationStatus').updateValueAndValidity();
      this.populateHygienizationStatusSelect();
    }

    else {
      this.hygienization = false;
    }

    if (this.idBed != null) {
      this.isFirstLoading = true;
      this.isUpdate = true;
      this.populateBedData();
    }
  }

  submit() {
    if (this.isLoading) {
      return;
    }

    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    this.isLoading = true;

    this.bedRequest.bedName = this.model.get('name').value;
    this.bedRequest.idBedStatus = this.model.get('idBedStatus').value;
    this.bedRequest.idBedType = this.model.get('idBedType').value;
    this.bedRequest.idBedCategory = this.model.get('idBedCategory').value;
    this.bedRequest.idSector = this.model.get('idSector').value;

    if (this.hygienization) {
      this.bedRequest.idHygienizationStatus = this.model.get('idHygienizationStatus').value;
    }

    if (this.isUpdate)
      this.updateBed();
    else
      this.createBed();
  }

  populateBedData() {
    this.bedService.getBed(this.idBed).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.model.get('name').setValue(response.bedStruct.bedName);
      this.model.get('idBedStatus').setValue(response.bedStruct.idBedStatus.toString());
      this.model.get('idBedType').setValue(response.bedStruct.idBedType.toString());
      this.model.get('idBedCategory').setValue(response.bedStruct.idBedCategory.toString());
      this.model.get('idSector').setValue(response.bedStruct.idSector);

      if (this.hygienization && response.bedStruct.idHygienizationStatus != null) {
        this.model.get('idHygienizationStatus').setValue(response.bedStruct.idHygienizationStatus);
        this.model.get('idHygienizationStatus').disable();
      }

      this.isLoading = false;
      this.isFirstLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  populateBedTypeSelect() {
    this.bedTypeService.listBedType().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }

      this.listBedType = response.listBedType;
    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  populateBedCategorySelect() {
    this.bedCategoryService.listBedCategory().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }

      this.listBedCategory = response.listBedCategory;
    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  populateBedStatusSelect() {
    this.bedStatusService.listBedStatus().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }

      this.listBedStatus = response.listBedStatus;
    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  populateHygienizationStatusSelect() {
    this.bedHygienizationConfigService.lookupStatus().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }

      this.listHygienizationStatus = response.listHygienizationStatus.filter(x =>
        x.idHygienizationStatus != this.occupiedStatus &&
        x.idHygienizationStatus != this.hygienizingStatus
      );

    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  populateSectorSelect() {
    this.locationByModuleService.listRoomByModule(MenuModuleEnum.observation).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }

      this.listSector = response.sectors;
    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  updateBed() {
    this.bedService.updateBed(this.idBed, this.bedRequest).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/observation/bed']);

    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  createBed() {
    this.bedService.createBed(this.bedRequest).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/observation/bed']);
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }
}
