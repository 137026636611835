<div class="row" *ngFor="let item of storageAmountLocation; let index = index;">
    <div class="col-6 col-md-3">
        <p>Local: </p>
        <p><b>{{item.location}}</b></p>
    </div>
    <div class="col-6 col-md-3">
        <p>Quantidade Total: </p>
        <p><b>{{item.amountTotal}}</b></p>
    </div>
    <div class="col-6 col-md-3">
        <p>Quantidade Usado: </p>
        <p><b>{{item.amountUsed}}</b></p>
    </div>
    <div class="col-6 col-md-3">
        <a (click)="openCorrectionModal(item)" mat-flat-button type="button" color="primary" class=" btn-block">
            Corrigir
        </a>
    </div>
</div>