import { ValidatorFn, AbstractControl } from "@angular/forms";

export function weightValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control || !control.value)
      return null;

    if (!parseFloat(control.value))
      return { invalidWeight: 'Valor inválido!' };

    let stringvalue = control.value.toString();

    if (stringvalue.length == 2)
      stringvalue = stringvalue.substring(0, 1) + "." + stringvalue.substring(1)
    else if (stringvalue.length == 3)
      stringvalue = stringvalue.substring(0, 2) + "." + stringvalue.substring(2)
    else
      stringvalue = stringvalue.substring(0, 3) + "." + stringvalue.substring(3)

    let value: number = parseFloat(stringvalue);

    const MIN_WEIGHT = 1.5;
    const MAX_WEIGHT = 600.0;

    if (value < MIN_WEIGHT) 
      return { invalidWeight: 'Peso mínimo 1,5kg' };
    else if (value > MAX_WEIGHT) 
      return { invalidWeight: 'Peso máximo 600,0kg' };

    return null;
  }
}