import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';


@Component({
  selector: 'app-invalid-modal',
  templateUrl: './invalid-modal.component.html',
  styleUrls: ['./invalid-modal.component.css']
})
export class InvalidModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<InvalidModalComponent>) { }

  ngOnInit(): void {
  }

  clickConfirm() {
    this.matDialogRef.close();
  }

  clickCancel() {
    this.matDialogRef.close({ cancel: true });
  }
}