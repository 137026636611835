import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CommonService } from "src/app/shared/services/common-service";
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';
import { catchError } from "rxjs/operators";
import { MultiProfessionalCareRequest } from "../../requests/multi-professional/multi-professional-care.request";
import { PostMultiProfessionalCareResponse } from "../../responses/orchestrator-patient/post-multi-professional-care.response";

@Injectable({
  providedIn: 'root'
})
export class MultiProfessionalCareService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public postMedicalCare(multiProfessionalCareRequest: MultiProfessionalCareRequest): Observable<PostMultiProfessionalCareResponse> {
    let uri = `MultiProfessionalCare`

    return this.httpClient.post<PostMultiProfessionalCareResponse>(environment.urlApiOrchestratorPatient + uri, multiProfessionalCareRequest, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}