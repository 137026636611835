import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { FlowRequest } from '../../requests/flow/flow.request';
import { GetFlowResponse } from '../../responses/flow/get-flow.response';
import { ListFlowResponse } from '../../responses/flow/list-flow.response';
import { KpiSectorConfigRequest } from '../../requests/flow/kpi-config.request';
import { request } from 'http';
import { GetKpiResponse } from '../../responses/flow/get-kpi.response';
import { ListKpiResponse } from '../../responses/flow/list-kpi.response';

@Injectable({
    providedIn: 'root'
})
export class KpiService extends CommonService {

    constructor(private router: Router, private httpClient: HttpClient) {
        super();
    }

    public getKpi(idSector: number): Observable<GetKpiResponse> {

        let uri = `Kpi/idSector/${idSector}`;

        return this.httpClient.get<GetKpiResponse>(environment.urlApiFlow + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }

    public listKpi(searchText: string): Observable<ListKpiResponse> {

        let uri = `Kpi/getAll?searchText=${searchText}`;

        return this.httpClient.get<ListKpiResponse>(environment.urlApiFlow + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }

    public createKpi(body: KpiSectorConfigRequest): Observable<ReturnStruct> {

        let uri = `Kpi`

        return this.httpClient.post<ReturnStruct>(environment.urlApiFlow + uri, body, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }

    public updateKpi(body: KpiSectorConfigRequest): Observable<ReturnStruct> {
        let idSector = body.idSector;
        let uri = `Kpi/idSector/${idSector}`

        return this.httpClient.put<ReturnStruct>(environment.urlApiFlow + uri, body, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }

    public deleteKpi(idSector: number): Observable<ReturnStruct> {

        let uri = `Kpi/idSector/${idSector}`

        return this.httpClient.delete<ReturnStruct>(environment.urlApiFlow + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }
}
