import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { HealthUnitDischargeOutcomeResponse } from '../../responses/medic/health-unit-discharge-outcome-response';

@Injectable({
    providedIn: 'root'
  })
  export class HealthUnitDischargeOutcomeService extends CommonService{
  
    constructor(private router: Router, private httpClient: HttpClient) {
      super();
    }
  
    public postDischargeOutcomeConfig(): Observable<ReturnStruct> {
      
      let uri = 'HealthUnitDischargeOutcome'
  
      return this.httpClient.post<ReturnStruct>(environment.urlApiMedic + uri, null, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
    }

    public getDischargeOutcome(): Observable<HealthUnitDischargeOutcomeResponse> {
        let uri = 'HealthUnitDischargeOutcome'
  
      return this.httpClient.get<HealthUnitDischargeOutcomeResponse>(environment.urlApiMedic + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
    }
  }