import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { SyncMeasurerService } from 'src/app/shared/services/API/sync-measurer/sync-measurer.service';

@Component({
  selector: 'app-sync-measurer-delete-modal',
  templateUrl: './sync-measurer-delete-modal.component.html',
  styleUrls: ['./sync-measurer-delete-modal.component.css']
})
export class SyncMeasurerDeleteModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<SyncMeasurerDeleteModalComponent>,
  private syncMeasurerService: SyncMeasurerService,
  private alertService: AlertService) { }

  public isLoading:boolean;  

  ngOnInit(): void {
    this.isLoading = false;
  }
  
  close(){
    this.matDialogRef.close({deleteExam: false}); 
  }
  
  clickDelete(){
    
    this.isLoading = true;

    this.syncMeasurerService.deleteSyncMeasurer(this.data.idSyncMeasurer).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.alertService.show('Sucesso', "Equipamento excluido com sucesso!", AlertType.success); 
      this.matDialogRef.close({deleteExam: true}); 
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }
  
  clickCancel(){
    this.matDialogRef.close({deleteExam: false}); 
  }
}
