import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { OrchestratorPatientAppointmentService } from 'src/app/shared/services/API/orchestrator-schedule/orchestrator-patient-appointment.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ListPatientAppointmentCancelRequest } from 'src/app/shared/services/requests/orchestrator-schedule/list-patient-appointment-cancel.request';

@Component({
  selector: 'app-cancel-appointments-confirm-modal',
  templateUrl: './cancel-appointments-confirm-modal.component.html',
  styleUrls: ['./cancel-appointments-confirm-modal.component.css']
})
export class CancelAppointmentsConfirmModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<CancelAppointmentsConfirmModalComponent>,
    public router:Router,
    private alertService: AlertService,
    public orchestratorPatientAppointmentService: OrchestratorPatientAppointmentService,
  ) { }
  public isLoading: boolean = false;

  ngOnInit(): void {
    
  }

  close(){
    this.matDialogRef.close(); 
  }

  clickCancel(){
    this.close(); 
  }

  
  cancelAllAppointments() {
    this.isLoading = true;
    let request = new ListPatientAppointmentCancelRequest();
    if(this.data.listAffectedPatient.length > 0){
      request.listIdPatientAppointment = this.data.listAffectedPatient.map(x => x.idPatientAppointment);
      request.listIdUserPatient = this.data.listAffectedPatient.map(x => x.idUserPatient);
    }
    request.idUser = this.data.idUser;

    this.orchestratorPatientAppointmentService.deleteAppointments(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show('Erro inesperado', response.errorDescription, AlertType.error);
          return;
        }
        this.alertService.show('Sucesso', 'Agendamentos cancelados com sucesso!', AlertType.success);
        this.isLoading = false;
        this.matDialogRef.close({ confirm: true});
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }
}

