import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AlertModalComponent } from 'src/app/shared/components/alert-modal/alert-modal.component';
import { MenuComponent } from 'src/app/shared/components/menu/menu.component';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { SelectRoomModalComponent } from 'src/app/shared/components/select-room-modal/select-room-modal.component';
import { EpisodeStatusEnum } from 'src/app/shared/enum/episode-status.enum';
import { SignatureProviderEnum } from 'src/app/shared/enum/signature-providers/signature-providers.enum';
import { UserRoleEnum } from 'src/app/shared/enum/user-role.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { GetRoomService } from 'src/app/shared/services/API/flow/get-room.service';
import { OccupiedBedService } from 'src/app/shared/services/API/orchestrator-patient/occupied-bed.service';
import { DigitalSignatureUtilService } from 'src/app/shared/services/digital-signature-util.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { UserLoginResponse } from 'src/app/shared/services/responses/user/auth.response';
import { RoomStruct } from 'src/app/shared/services/structs/flow/room.struct';
import { OccupiedBedStruct } from 'src/app/shared/services/structs/orchestrator-patient/occupied-bed.struct';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-medic-bed-management',
  templateUrl: './medic-bed-management.component.html',
  styleUrls: ['./medic-bed-management.component.css']
})
export class MedicBedManagementComponent implements OnInit {

  constructor(
    private occupiedBedService: OccupiedBedService,
    private alertService: AlertService,
    public dialog: MatDialog,
    private maskService: MaskService,
    private activatedRoute: ActivatedRoute,
    private getRoomService: GetRoomService,
    private signatureUtilService: DigitalSignatureUtilService,
    private utilsService: UtilService,
  ) { }

  @ViewChild(MenuComponent) menu: MenuComponent;

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.medic;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.medic_bedManagement;

  public listOccupiedBed: OccupiedBedStruct[];
  public isLoading: boolean;
  public searchText: string;
  public birthDate: string;
  public masks: Masks;
  public birthDateForm: string;
  public idRoom: number;
  public idSector: number
  public idService: number
  public selectedRoom: RoomStruct;
  public endRealease: EpisodeStatusEnum = EpisodeStatusEnum.FinalizadoAlta;

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();

    if (this.activatedRoute.snapshot.paramMap.get('idRoom')) {
      this.idRoom = parseInt(this.activatedRoute.snapshot.paramMap.get('idRoom'));
    }
    if (this.activatedRoute.snapshot.paramMap.get('idSector')) {
      this.idSector = parseInt(this.activatedRoute.snapshot.paramMap.get('idSector'));
    }
    if (this.activatedRoute.snapshot.paramMap.get('idService')) {
      this.idService = parseInt(this.activatedRoute.snapshot.paramMap.get('idService'));
    }

    if (this.idRoom && this.idSector && this.idService) {
      this.getRoomUnAuth();
    }
    else {
      this.selectRoomModal();
    }
  }

  getRoomUnAuth() {
    this.getRoomService.getRoom(this.idRoom).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.selectedRoom = response.roomStruct;
      this.search();
    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      });
  }

  search() {
    if (this.isLoading || !this.idSector)
      return;

    this.isLoading = true;
    if (this.searchText == "")
      this.searchText = null;

    this.occupiedBedService.listOccupiedBed(this.searchText, this.birthDateForm, this.idSector).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.listOccupiedBed = response.listOccupiedBedStruct;

      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  private timeoutKeySearch: any = null;

  dateChage() {
    let isValidDate = this.birthDate && this.birthDate.length == 8;
    if (isValidDate) {
      this.birthDateForm = this.maskService.formatDateToString(this.maskService.formatStringToDate(this.birthDate), null, true);
      this.search();
    } else if (!this.birthDate || this.birthDate && this.birthDate.length == 0) {
      this.birthDateForm = null;
      this.search();
    }
  }

  onKeySearch(event: any, field: string) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (field == 'birthDate') {
        $this.dateChage();
      } else {
        $this.search();
      }
    }, 1000);
  }

  selectRoomModal() {
    const dialogRef = this.dialog.open(SelectRoomModalComponent, {
      data: {
        idModule: MenuModuleEnum.observation,
      },
    });
    dialogRef.afterClosed().subscribe(({ res, seeOccupiedRoom }) => {
      if (res) {
        this.idRoom = res.idRoom;
        this.idSector = res.idSector
        this.idService = res.idService
        this.selectedRoom = res;
        this.search();
      }

      this.checkSigningResponsibility();
      
    });
  }

  checkSigningResponsibility() {
    let token: UserLoginResponse = this.utilsService.getToken();
    let signatureMap: Object = this.signatureUtilService.listSignatureStruct();

    let requireSignature = token.listRole.map(x => x.idRole).includes(UserRoleEnum.requerer_assinatura_medico_gestao_leitos) && !token.isMaster;
    let signatureReady = signatureMap != null && ((signatureMap[SignatureProviderEnum.safeID] != null && signatureMap[SignatureProviderEnum.safeID].identifierCA != null) ||
      (signatureMap[SignatureProviderEnum.vidaaS] != null && signatureMap[SignatureProviderEnum.safeID].authorizationToken != null));
   
    if (requireSignature && !signatureReady) {
      const dialogRef = this.dialog.open(AlertModalComponent, {
        data: {
          isTwoButtonsModal: false,
          title: 'Atenção',
          description: 'Seu perfil foi marcado com obrigatoriedade de assinatura digital de documentos. Escolha um provedor e siga o processo para ativar sua assinatura digital no sistema.'
        },
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe({
        next: result => {
          this.menu.openSignatureManager();
        }
      });
    }
  }

  goMedic(bed: any) {
    window.location.replace(`/medic/attend-patient;idEpisode=${bed.idEpisode};isBedManagement=true;idSector=${bed.idSector};idService=${this.idService};idRoom=${this.idRoom};idBed=${bed.idBed}`);
  }

}
