<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'
        [hasSecundaryHeader]="true" [episode]="selectedEpisode" [hasCachedModule]="true"
        [cachedModuleName]="'medic'"></app-menu>

    <div class="container-body-secundary-header">
        <h1>Atendimento Médico</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body-online-attend">
            <div class="row">
                <div *ngIf="this.episodePreEvaluation != null" class="column col-12 col-sm-9">
                    <div class="title-header">
                        <h1>Relato do paciente</h1>
                    </div>
                    <div class="row">
                        <div class="col-8 col-sm-6 col-md-6">
                            <span class="patient-related-title">Qual é o principal sintoma relatado pelo
                                paciente:</span>
                            <h2>
                                {{this.episodePreEvaluation.symptomDescription}}</h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-8 col-sm-6 col-md-6">
                            <span class="patient-related-title">Há quanto tempo sente esses sintomas?</span>
                            <h2>
                                {{this.episodePreEvaluation.symptomDurationDescription}}</h2>
                        </div>
                    </div>
                </div>
                <div class="margin-video fixed-video col-12 col-sm-3" id="videoDiv">
                    <app-twilio-video #twiliovideo (leaveRoom)="onLeaveRoom()"></app-twilio-video>
                </div>
            </div>
            <div class="row">
                <div class="column col-12 col-sm-9">
                    <form class="form" [formGroup]="model">
                        <div class="title-header">
                            <h1>Novo Atendimento</h1>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12">
                                <app-allergy-tag [inputListTags]="allergies" [listTags]="allergiesToSave"
                                    (updateAllergiesToSave)="updateAllergiesToSave($event)">
                                </app-allergy-tag>
                            </div>
                        </div>
                        <div *ngIf="medicalEvolution" class="row">
                            <div class="col-12 col-sm-12 col-md-12">
                                <mat-form-field appearance="outline">
                                    <mat-label>Anotações médicas (queixas, história da doença atual, medicamentos em
                                        uso,
                                        história
                                        pregressa)</mat-label>
                                    <textarea matInput formControlName="medicalNotes"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                        <div *ngIf="medicalEvolution" class="row">
                            <div class="col-12 col-sm-12 col-md-12">
                                <mat-form-field appearance="outline">
                                    <mat-label>Exame físico</mat-label>
                                    <textarea matInput formControlName="physicalExamination"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                        <div *ngIf="medicalEvolution" class="row">
                            <div class="col-12 col-sm-12 col-md-12">
                                <app-select-2 [formControlField]="'cid'" (selectedValue)="addItemCid($event)"
                                    [multiple]="true" [url]="urlListCid" [idField]="'idCid'" [nameField]="'cidName'"
                                    [labelField]="'CID'">
                                </app-select-2>
                            </div>
                        </div>
                        <div *ngIf="medicalEvolution" class="row">
                            <div class="col-12 col-sm-12 col-md-12">
                                <mat-form-field appearance="outline">
                                    <mat-label>Plano Terapêutico</mat-label>
                                    <textarea matInput formControlName="therapeuticPlan"></textarea>
                                </mat-form-field>
                            </div>
                        </div>

                        <div *ngIf="medicalEvolution" class="row">
                            <div class="col-12 col-sm-12 col-md-12">
                                <mat-form-field appearance="outline">
                                    <mat-label>Hipótese Diagnóstica</mat-label>
                                    <textarea matInput formControlName="diagnosedHypothesis"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                        <div *ngIf="!readonly" class="row">
                            <div class="col-12">
                                <mat-form-field appearance="outline">
                                    <mat-label>Conclusão</mat-label>
                                    <textarea matInput formControlName="conclusionNote"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-3 col-md-2">
                                <mat-form-field appearance="outline">
                                    <mat-label>Temperatura</mat-label>
                                    <!-- <input (blur)="onBlurTemperature()" [mask]="masks.temperature" type="text" matInput
                                        formControlName="temperature"> -->
                                    <input [mask]="masks.temperature" type="text" matInput
                                        formControlName="temperature">
                                    <span matSuffix>ºC</span>
                                </mat-form-field>
                            </div>
                            <div class="col-6 col-sm-3 col-md-2">
                                <mat-form-field appearance="outline">
                                    <mat-label>FR</mat-label>
                                    <input [mask]="masks.threeDigits" type="text" matInput
                                        formControlName="respiratoryFrequency">
                                    <span matSuffix>irpm</span>
                                </mat-form-field>
                            </div>
                            <div class="col-6 col-sm-3 col-md-2">
                                <mat-form-field appearance="outline">
                                    <mat-label>FC</mat-label>
                                    <input [mask]="masks.threeDigits" type="text" matInput formControlName="heartRate">
                                    <span matSuffix>bpm</span>
                                </mat-form-field>
                            </div>
                            <div class="col-6 col-sm-3 col-md-2">
                                <mat-form-field appearance="outline">
                                    <mat-label>GC</mat-label>
                                    <!-- <input (blur)="onBlurGlucose()" [mask]="masks.threeDigits" type="text" matInput
                                        formControlName="glucose"> -->
                                    <input [mask]="masks.threeDigits" type="text" matInput formControlName="glucose">
                                    <span matSuffix>mg/dL</span>
                                </mat-form-field>
                            </div>
                            <div class="col-6 col-sm-3 col-md-2">
                                <mat-form-field appearance="outline">
                                    <mat-label>PA</mat-label>
                                    <!-- <input (blur)="onBlurPressure()" [mask]="masks.pressure" type="text" matInput
                                        formControlName="bloodPressure"> -->
                                    <input [mask]="masks.pressure" type="text" matInput formControlName="bloodPressure">
                                    <span matSuffix>mmHG</span>
                                </mat-form-field>
                            </div>
                            <div class="col-6 col-sm-3 col-md-2">
                                <mat-form-field appearance="outline">
                                    <mat-label>SatO2</mat-label>
                                    <input [mask]="masks.threeDigits" type="text" matInput formControlName="saturation">
                                    <span matSuffix>%</span>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="border-list">
                            <div class="title-list">
                                <div class="row">
                                    <div class="col-6 col-sm-6 col-md-4">
                                        <span class="subtitle">Prescrições Não padronizadas</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-4">
                                    <a mat-flat-button type="button"
                                        (click)="openDefaultExternalPrescriptionModal(-1, null, false)" color="accent"
                                        class=" btn-block">
                                        <mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon> Adicionar
                                        Prescrição
                                        Não
                                        Padronizada
                                    </a>
                                </div>
                            </div>
                            <div class="card-list"
                                *ngIf="listExternalPrescription != null && listExternalPrescription.length > 0">
                                <div class="row row-list">
                                    <div class="col-12 col-sm-6 col-md-4"
                                        *ngFor="let item of listExternalPrescription; let index = index;">
                                        <div class="card-item">
                                            <div class="infos">
                                                <span class="primary">{{item.description}}</span>
                                                <span class="secundary">Quantidade: {{item.quantity}}
                                                    {{item.measurementUnitName}}</span>
                                                <span class="secundary">Frequência: {{item.frequency}}</span>
                                                <span class="secundary">Observação: {{item.observation}}</span>
                                            </div>
                                            <div class="actions">
                                                <a>
                                                    <mat-icon aria-hidden="false" aria-label="Editar"
                                                        (click)="openDefaultExternalPrescriptionModal(index, item, false)">edit</mat-icon>
                                                </a>
                                                <a>
                                                    <mat-icon aria-hidden="false" aria-label="Excluir"
                                                        (click)="deleteExternalPrescription(index)">delete</mat-icon>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="border-list">
                            <div class="title-list">
                                <div class="row">
                                    <div class="col-6 col-sm-6 col-md-4">
                                        <span class="subtitle">Pedidos de Exames</span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!readonly" class="row">
                                <div class="col-6 col-sm-6 col-md-4">
                                    <a mat-flat-button type="button" (click)="openSadtModal(null, false, -1)"
                                        color="accent" class=" btn-block" *ngIf="!isFastTrack || bypassExam">
                                        <mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon> Adicionar Pedido
                                        de
                                        Exame
                                    </a>
                                </div>
                            </div>
                            <div class="card-list" *ngIf="listSADT != null && listSADT.length > 0">
                                <div class="row row-list">
                                    <div class="col-12 col-sm-6 col-md-4"
                                        *ngFor="let item of listSADT; let index = index;">
                                        <div class="card-item">
                                            <div class="infos">
                                                <span class="primary">{{item.typeSadtName}}</span>
                                                <span class="secundary">Uso: {{item.placeName}}</span>
                                                <span class="secundary">Observação: {{item.observationMedic}}</span>
                                                <span class="secundary">Status: Pedido</span>
                                            </div>
                                            <div *ngIf="!readonly" class="actions">
                                                <a>
                                                    <mat-icon aria-hidden="false" aria-label="Editar"
                                                        (click)="openSadtModal(item, false, index)">edit</mat-icon>
                                                </a>
                                                <a>
                                                    <mat-icon aria-hidden="false" aria-label="Excluir"
                                                        (click)="deleteSadt(index)">
                                                        delete</mat-icon>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="border-list" *ngIf="isMemedPrescription">
                            <div class="title-list">
                                <div class="row">
                                    <div class="col-6 col-sm-6 col-md-4">
                                        <span class="subtitle">Prescrições e Exames externos via Memed</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-sm-6 col-md-4">
                                <a mat-flat-button type="button" (click)="openMemedPrescription()" color="accent"
                                    [disabled]="this.isFirstLoading || this.userTokenMemed == undefined"
                                    class=" btn-block">
                                    <mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon>
                                    Nova prescrição
                                </a>
                            </div>
                            <div class="card-list"
                                *ngIf="listMemedPrescriptionStruct != null && listMemedPrescriptionStruct.length > 0">
                                <div class="row row-list">
                                    <div class="col-12 col-sm-6 col-md-5"
                                        *ngFor="let item of listMemedPrescriptionStruct; let index = index;">
                                        <div class="card-item">
                                            <div class="infos">
                                                <div class="memed-prescription-info"
                                                    *ngFor="let medicine of item.listMemedMedicine; let indexMedicine = index;">
                                                    <span class="primary">{{indexMedicine + 1}} -
                                                        {{medicine.name}}</span>
                                                    <span class="secundary">Quantidade: {{medicine.quantity}}</span>
                                                    <span class="secundary">Unidade: {{medicine.unit}}</span>
                                                </div>
                                            </div>
                                            <div class="actions">
                                                <a>
                                                    <mat-icon aria-hidden="false" aria-label="Excluir"
                                                        (click)="deleteMemedPrescription(index)">delete</mat-icon>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="memed-modal-back"
                                [ngClass]="{'memed-modal-visible': this.showMemed, 'memed-modal-hide': !this.showMemed}">
                                <div class="memed-modal">
                                    <!-- <mat-icon class="close-icon" mat-icon aria-hidden="false" aria-label="close"
                                        (click)="closeMemedModal()">close</mat-icon> -->
                                    <app-memed-prescriptions
                                        *ngIf="!this.isFirstLoading && this.userTokenMemed  && patientCellPhone"
                                        [episodeStruct]="this.episodeStruct" [cpfPatient]="this.cpf"
                                        [userToken]="this.userTokenMemed" [patientPhoneNumber]="this.patientCellPhone"
                                        (newPrescription)="newMemedPrescription($event)">
                                    </app-memed-prescriptions>
                                    <div class="footer-memed-modal">
                                        <div class="col-12 col-md-5">
                                            <button type="button" mat-flat-button color="accent" class="btn-block"
                                                (click)="closeMemedModal()">Fechar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="border-list">
                            <div class="title-list">
                                <div class="row">
                                    <div class="col-6 col-sm-6 col-md-4">
                                        <span class="subtitle">Linhas de Cuidado</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-4">
                                    <a mat-flat-button type="button" (click)="openCareLineModal(listCareLine)"
                                        color="accent" class=" btn-block">
                                        <mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon> Adicionar Linha
                                        de
                                        Cuidado
                                    </a>
                                </div>
                            </div>
                            <div class="card-list"
                                *ngIf="listCurrentEpisodeCareLine != null && listCurrentEpisodeCareLine.length > 0">
                                <div class="row row-list">
                                    <div class="col-12 col-sm-6 col-md-4"
                                        *ngFor="let item of listCurrentEpisodeCareLine; let index = index;">
                                        <div class="card-item">
                                            <div class="infos">
                                                <span class="primary">{{item.careLineName}}</span>
                                                <span *ngIf="item.userName" class="secundary">Alocado por:
                                                    {{item.userName}}</span>
                                                <span *ngIf="item.datetimeInclusion"
                                                    class="secundary">{{item.datetimeInclusion}}</span>
                                            </div>
                                            <div class="actions">
                                                <a *ngIf="listNewEpisodeCareLine.includes(item)">
                                                    <mat-icon aria-hidden="false" aria-label="Excluir"
                                                        (click)="removeCareLine(index)">delete</mat-icon>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-list"
                                *ngIf="listPastEpisodeCareLine != null && listPastEpisodeCareLine.length > 0">
                                <div class="row row-list">
                                    <div class="col-12 col-sm-6 col-md-4"
                                        *ngFor="let item of listPastEpisodeCareLine; let index = index;">
                                        <div class="card-item">
                                            <div class="infos">
                                                <span class="primary">{{item.careLineName}}</span>
                                                <span class="secundary">Alocado por: {{item.userName}}</span>
                                                <span class="secundary">{{item.datetimeInclusion}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="title-header">
                            <h1>Isolamento e Atestado</h1>
                        </div>
                        <div class="row">
                            <div class="col-2 col-sm-2 col-md-2">
                                <mat-slide-toggle class="indigent-checkbox" [ngModelOptions]="{standalone: true}"
                                    (change)="changeIsolation($event)"
                                    [(ngModel)]="this.isIsolation">Isolamento</mat-slide-toggle>
                            </div>
                            <div class="col-2 col-md-2" *ngIf="this.isIsolation">
                                <mat-form-field appearance="outline">
                                    <mat-label>Último dia de Isolamento</mat-label>
                                    <input matInput formControlName="datetimeEndIsolation"
                                        [matDatepicker]="isolationDatePicker">
                                    <mat-datepicker-toggle matSuffix
                                        [for]="isolationDatePicker"></mat-datepicker-toggle>
                                    <mat-datepicker #isolationDatePicker></mat-datepicker>
                                    <mat-error *ngIf="model.get('datetimeEndIsolation').errors">
                                        {{model.get('datetimeEndIsolation').errors['invalidDate']}}</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-2 col-sm-2 col-md-2">
                                <mat-form-field appearance="outline">
                                    <mat-label>Dias de atestado</mat-label>
                                    <input matInput type="number" formControlName="sickDaysOffAmount">
                                    <span matSuffix>dias</span>
                                </mat-form-field>
                            </div>
                            <div class="col-2 col-sm-2 col-md-2">
                                <mat-slide-toggle class="indigent-checkbox" formControlName="isCidOnSickNote">Imprimir
                                    CID no atestado</mat-slide-toggle>
                            </div>
                        </div>
                        <div class="row" *ngIf="showForward">
                            <mat-radio-group aria-label="" formControlName="idFlow">
                                <mat-radio-button value="{{item.idFlow}}"
                                    *ngFor="let item of listFoward">{{item.locationName}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="row">
                            <div class="col-md-3 col-sm-3"></div>
                            <div *ngIf="this.readonly" class="col-md-3 col-sm-3"></div>
                            <div class="col-md-3 col-sm-3">
                                <a mat-flat-button type="button" (click)="openCancelConfirmModal()" color="accent"
                                    class=" btn-block">
                                    Cancelar
                                </a>
                            </div>
                            <div *ngIf="!readonly" class="col-md-3 col-sm-3">
                                <button mat-flat-button (click)="submit(true)"
                                    color="{{requireSignature ? 'primary' : 'accent'}}" class="btn-block">
                                    <span *ngIf="!isLoading">Salvar com assinatura e Imprimir</span>
                                    <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                        *ngIf="isLoading"></mat-spinner>
                                </button>
                            </div>
                            <div *ngIf="!readonly && !requireSignature" class="col-md-3 col-sm-3">
                                <button mat-flat-button (click)="submit(false)"
                                    color="{{requireSignature ? 'accent' : 'primary'}}" class="btn-block">
                                    <span *ngIf="!isLoading">Salvar sem assinatura e Imprimir</span>
                                    <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                        *ngIf="isLoading"></mat-spinner>
                                </button>
                            </div>
                        </div>
                    </form>
                    <div *ngIf="medicalCare != null  && medicalCare.length > 0 ">
                        <div class="title-header">
                            <h1>Histórico de Atendimentos</h1>
                        </div>
                        <div class="row">
                            <div class="col=12">
                                <cdk-accordion class="profile-register-accordion">
                                    <app-medical-care-accordion [medicalCare]="medicalCare"
                                        [lookupMedicalCareResponse]="lookupMedicalCareResponse"
                                        [listMedicine]="listMedicine" [listExam]="listExamType">
                                    </app-medical-care-accordion>
                                </cdk-accordion>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="triage != null">
                        <div class="title-header title-margin">
                            <h1>Classificação de Risco</h1>
                        </div>
                        <app-triage-resume [triage]="triage"></app-triage-resume>
                    </div>
                    <div *ngIf="episodeHistorie && episodeHistorie.length >0">
                        <div class="title-header title-margin">
                            <h1>Histórico de Episódio</h1>
                        </div>
                        <table class="basic-table desktop">
                            <thead class="basic-table__head">
                                <tr>
                                    <th>Unidade</th>
                                    <th>Médicos</th>
                                    <th>CID</th>
                                    <th>Gravidade</th>
                                    <th>Data</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody class="basic-table__body">
                                <tr class="basic-table__body__row"
                                    *ngFor="let episode of episodeHistorie; let index = index;">
                                    <td class="truncate"><span>{{episode.healthUnitName}}</span></td>
                                    <td class="truncate"><span>{{episode.userMedicalCare.join(", ")}}</span></td>
                                    <td class="truncate"><span>{{episode.cid.join(", ")}}</span></td>
                                    <td class="truncate">
                                        <div class="status-caller"
                                            [ngStyle]="{'background-color': episode.gravityColor}">
                                            <span class="text-bold">{{episode.gravityName}}</span>
                                        </div>
                                    </td>
                                    <td class="truncate"><span>{{episode.datetimeInclusion |
                                            date:'dd/MM/yyyy'}}</span></td>

                                    <td>
                                        <div class="basic-table__body__row__action">
                                            <mat-icon matTooltip="Visualizar"
                                                (click)="openEpisodeModal(episode.idEpisode)">
                                                visibility</mat-icon>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <cdk-accordion class="accordion mobile">
                            <cdk-accordion-item *ngFor="let episode of episodeHistorie; let index = index;"
                                #accordionItem="cdkAccordionItem" class="accordion-item" role="button" tabindex="0"
                                [attr.id]="'accordion-header-' + index" [attr.aria-expanded]="accordionItem.expanded"
                                [attr.aria-controls]="'accordion-body-' + index">
                                <div [ngClass]="{'accordion-item-header-expanded': accordionItem.expanded}"
                                    class="accordion-item-header" (click)="accordionItem.toggle()">
                                    <div class="flex-space-between">
                                        <span class="truncate bold padding-right-0">Unidade</span>
                                        <span class="truncate">{{episode.healthUnitName}}</span>
                                    </div>
                                    <div class="flex-space-between">
                                        <span class="truncate bold padding-right-0">Gravidade</span>
                                        <div class="status-caller"
                                            [ngStyle]="{'background-color': episode.gravityColor}">
                                            <span class="text-bold">{{episode.gravityName}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item-body" role="region"
                                    [style.display]="accordionItem.expanded ? '' : 'none'"
                                    [attr.id]="'accordion-body-' + index"
                                    [attr.aria-labelledby]="'accordion-header-' + index">
                                    <div class="feature-content">
                                        <div class="padding-x">

                                            <div class="flex-space-between padding-y"><span
                                                    class="truncate bold padding-right-0">Médico:</span> <span
                                                    class="truncate">{{episode.userMedicalCare.join(", ")}}</span></div>
                                            <div class="flex-space-between padding-y"><span
                                                    class="truncate bold padding-right-0">CID:</span> <span
                                                    class="truncate">{{episode.cid.join(", ")}}</span></div>
                                            <div class="flex-space-between padding-y"><span
                                                    class="truncate bold padding-right-0">Data:</span> <span
                                                    class="truncate">{{episode.datetimeInclusion |
                                                    date:'dd/MM/yyyy'}}</span></div>
                                        </div>
                                        <div class="basic-table__body__row__action">
                                            <mat-icon matTooltip="Visualizar"
                                                (click)="openEpisodeModal(episode.idEpisode)">
                                                visibility
                                            </mat-icon>
                                        </div>
                                    </div>
                                </div>
                            </cdk-accordion-item>
                        </cdk-accordion>
                    </div>
                    <ng-container *ngIf="lastSelfTriage">
                        <div class="title-header title-margin">
                            <h1>Última Autotriagem</h1>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-3">
                                <p><b>Data:</b> {{lastSelfTriage.patientFlowchart.datetimeInclusion |
                                    date:'dd/MM/yyyy'}}</p>
                                <p><b>Fluxograma:</b> {{lastSelfTriage.patientFlowchart.flowchart}}</p>
                                <p><b>Aconselhamento:</b> {{lastSelfTriage.patientFlowchart.counseling}}</p>
                            </div>
                            <div class="col-12 col-sm-6 col-md-3">
                                <button mat-flat-button type="button" color="primary" class=" btn-primary"
                                    (click)="showSelfTtriageResume()">
                                    <span>Ver Resumo</span>
                                </button>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="lastTelephoneTriage">
                        <div class="title-header title-margin">
                            <h1>Última Classificação Telefônica</h1>
                        </div>
                        <app-triage-resume [triage]="lastTelephoneTriage"
                            [isTelephonceClassification]="true"></app-triage-resume>
                    </ng-container>
                </div>
            </div>
        </div>
        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>
</div>

<div *ngIf="showTissGuideButton">
    <app-tiss-guide-management-floating-window [idEpisode]="selectedEpisode" [isAttendance]="true"
        [idRoom]="this.idRoom" [idSector]="this.idSector">
    </app-tiss-guide-management-floating-window>
</div>