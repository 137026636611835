<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="header-modal">
            <h1>Guia de Consulta</h1>
        </div>
        <app-loading-progress-bar *ngIf="isLoading"></app-loading-progress-bar>
        <div class="body-modal">
            <form class="form" [formGroup]="model" appFormscroll>
                <div class="row margin-section">
                    <div class="col-12 col-sm-6 col-md-3">
                        <app-select-2 [formControlField]="'idHealthcareAgreement'"
                            [multiple]="false" [url]="urlListAnsRegistration" [idField]="'idHealthcareAgreement'"
                            [nameField]="'ansWithName'" [labelField]="'1 - Registro ANS'"
                            [preSelectedBlock]="this.idAppointmentGuide != null ? true : false">
                        </app-select-2>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>2 - N° da guia no prestador</mat-label>
                            <input matInput type="text" formControlName="guideNumber"
                                [maxlength]="appointmentFieldsMaxLengthEnum.guideNumber"
                                oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                            <mat-error
                                *ngIf="model.get('guideNumber').invalid && !model.get('guideNumber').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ model.get('guideNumber').value ?
                                model.get('guideNumber').value.length : 0
                                }}/{{appointmentFieldsMaxLengthEnum.guideNumber}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>3 - N° da guia operadora</mat-label>
                            <input matInput type="text" formControlName="guideOperatorNumber"
                                [maxlength]="appointmentFieldsMaxLengthEnum.guideOperatorNumber"
                                oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                            <mat-error
                                *ngIf="model.get('guideOperatorNumber').invalid && !model.get('guideOperatorNumber').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ model.get('guideOperatorNumber').value ?
                                model.get('guideOperatorNumber').value.length : 0
                                }}/{{appointmentFieldsMaxLengthEnum.guideOperatorNumber}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Status da guia</mat-label>
                            <mat-select formControlName="idGuideStatus">
                                <mat-option *ngFor="let item of listGuideStatus" [value]="item.idGuideStatus">
                                    <span class="color-dot" [style.background-color]="item.color"></span>
                                    {{item.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idGuideStatus').invalid">Selecione um status</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="title-header">
                    <h1>Dados do beneficiário (Paciente)</h1>
                </div>
                <div class="row margin-section">
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>4 - N° carteira do beneficiário</mat-label>
                            <input matInput type="text"
                                [maxlength]="appointmentFieldsMaxLengthEnum.beneficiaryCardNumber"
                                formControlName="beneficiaryCardNumber"
                                oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                            <mat-error *ngIf="model.get('beneficiaryCardNumber').invalid">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ model.get('beneficiaryCardNumber').value ?
                                model.get('beneficiaryCardNumber').value.length : 0
                                }}/{{appointmentFieldsMaxLengthEnum.beneficiaryCardNumber}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>5 - Validade da carteira</mat-label>
                            <input matInput [mask]="masks.date" type="text" formControlName="walletBeneficiaryValidity">
                            <mat-error *ngIf="model.get('walletBeneficiaryValidity').invalid">Data inválida</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>6 - Atendimento a Recém-Nascido</mat-label>
                            <mat-select formControlName="newborn">
                                <mat-option [value]="true">Sim</mat-option>
                                <mat-option [value]="false">Não</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-6">
                        <mat-form-field appearance="outline">
                            <mat-label>7 - Nome do beneficiário</mat-label>
                            <input matInput type="text" [maxlength]="appointmentFieldsMaxLengthEnum.beneficiaryName"
                                formControlName="beneficiaryName">
                            <mat-error
                                *ngIf="model.get('beneficiaryName').invalid && !model.get('beneficiaryName').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ model.get('beneficiaryName').value ?
                                model.get('beneficiaryName').value.length : 0
                                }}/{{appointmentFieldsMaxLengthEnum.beneficiaryName}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6">
                        <mat-form-field appearance="outline">
                            <mat-label>26 - Nome Social do beneficiário</mat-label>
                            <input matInput type="text"
                                [maxlength]="appointmentFieldsMaxLengthEnum.beneficiarySocialName"
                                formControlName="beneficiarySocialName">
                            <mat-hint align="end">{{ model.get('beneficiarySocialName').value ?
                                model.get('beneficiarySocialName').value.length : 0
                                }}/{{appointmentFieldsMaxLengthEnum.beneficiarySocialName}}</mat-hint>
                        </mat-form-field>
                    </div>
                </div>
                <div class="title-header">
                    <h1>Dados do contratado</h1>
                </div>
                <div class="row margin-section">
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Identificação no prestador</mat-label>
                            <mat-select formControlName="idProviderIdentityType"
                                (selectionChange)="checkProviderIdentityType($event.value)">
                                <mat-option *ngFor="let item of listProviderIdentityType"
                                    [value]="item.idProviderIdentityType">
                                    {{item.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idProviderIdentityType').invalid">Selecione um
                                status</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>9 - Código do contratado</mat-label>
                            <input matInput type="text" [maxlength]="requesterCodeMaxLength"
                                formControlName="executionContractorOperatorCode" [mask]="requesterCodeMask"
                                (input)="inputNumber('executionContractorOperatorCode')">
                            <mat-error
                                *ngIf="model.get('executionContractorOperatorCode').invalid && !model.get('executionContractorOperatorCode').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ model.get('executionContractorOperatorCode').value ?
                                model.get('executionContractorOperatorCode').value.length : 0 }} /
                                {{ appointmentFieldsMaxLengthEnum.requesterCodeOperator }}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6">
                        <mat-form-field appearance="outline">
                            <mat-label>10 - Nome do contratado</mat-label>
                            <input matInput type="text" [maxlength]="appointmentFieldsMaxLengthEnum.contractorName"
                                formControlName="contractorName">
                            <mat-error
                                *ngIf="model.get('contractorName').invalid && !model.get('contractorName').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ model.get('contractorName').value ?
                                model.get('contractorName').value.length : 0
                                }}/{{appointmentFieldsMaxLengthEnum.contractorName}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>11 - CNES</mat-label>
                            <input matInput [mask]="masks.cnes" type="text"
                                [maxlength]="appointmentFieldsMaxLengthEnum.contractorCNES"
                                formControlName="contractorCNES">
                            <mat-error
                                *ngIf="model.get('contractorCNES').invalid && !model.get('contractorCNES').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ model.get('contractorCNES').value ?
                                model.get('contractorCNES').value.length : 0
                                }}/{{appointmentFieldsMaxLengthEnum.contractorCNES}}</mat-hint>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-3">
                        <app-select-2
                            *ngIf="listProfessionalAgreementStruct && listProfessionalAgreementStruct.length > 0"
                            [formControlField]="'professional'" [list]="this.listProfessionalAgreementStruct"
                            [multiple]="false" [idField]="'idProfessionalAgreement'"
                            (selectedValue)="onProfessionalChange($event)" [nameField]="'nameProfessional'"
                            [labelField]="'12 - Nome do Profissional Executante'">
                        </app-select-2>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>13 - Conselho Profissional</mat-label>
                            <mat-select formControlName="idProfessionalCounseling">
                                <mat-option *ngFor="let item of listProfessionalCouncil"
                                    [value]="item.idProfessionalCouncil">
                                    {{ item.termCode }} - {{ item.councilName }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>14 - N° do conselho Profissional</mat-label>
                            <input matInput type="text" formControlName="counselingNumber"
                                oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                            <mat-error *ngIf="model.get('counselingNumber').invalid">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>15 - UF</mat-label>
                            <mat-select formControlName="idProfessionalUf">
                                <mat-option *ngFor="let item of listState" [value]="item.idState">
                                    {{ item.stateCode }} - {{ item.stateName }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>16 - CBO</mat-label>
                            <mat-select formControlName="idProfessionalCbo">
                                <mat-option *ngFor="let item of listCbo" [value]="item.idCbo">
                                    {{ item.cboCode }} - {{ item.cboName }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="title-header margin-section">
                    <h1>Dados do Atendimento/Procedimento realizado</h1>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>17 - Indicação de acidente</mat-label>
                            <mat-select formControlName="idAccidentIndication">
                                <mat-option *ngFor="let item of listAccidentIndication"
                                    [value]="item.idAccidentIndication">
                                    {{item.name}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idAccidentIndication').invalid">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>18 - Data de realização</mat-label>
                            <input matInput [mask]="masks.date" type="text" formControlName="completionDate">
                            <mat-error *ngIf="model.get('completionDate').invalid">Data inválida</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>19 - Tipo de consulta</mat-label>
                            <mat-select formControlName="idAppointmentType">
                                <mat-option *ngFor="let item of listAppointmentType" [value]="item.idAppointmentType">
                                    {{item.name}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idAppointmentType').invalid">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>27 - Cobertura Especial</mat-label>
                            <mat-select formControlName="idSpecialCoverage">
                                <mat-option *ngFor="let item of listSpecialCoverage" [value]="item.idSpecialCoverage">
                                    {{ item.termCode }} - {{item.specialCoverageDescription}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idSpecialCoverage').invalid">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>28 - Regime De Atendimento</mat-label>
                            <mat-select formControlName="idCareRegime">
                                <mat-option *ngFor="let item of listCareRegime" [value]="item.idCareRegime">
                                    {{ item.termCode }} - {{item.careRegimeDescription}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idCareRegime').invalid">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>29 - Saúde Ocupacional</mat-label>
                            <mat-select formControlName="idOccupationalHealth">
                                <mat-option *ngFor="let item of listOccupationalHealth"
                                    [value]="item.idOccupationalHealth">
                                    {{ item.termCode }} - {{item.occupationalHealthName}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idOccupationalHealth').invalid">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>20 - Tabela</mat-label>
                            <mat-select formControlName="idTerminologyGroup" (selectionChange)="selectTable()">
                                <mat-option *ngFor="let item of listTussTerminologyGroup"
                                    [value]="item.idTerminologyGroup">
                                    {{item.terminologyGroupCode}} - {{item.nameTerminologyGroup}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idTerminologyGroup').invalid">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6">
                        <mat-form-field appearance="outline">
                            <mat-label>21 - Código do procedimento</mat-label>
                            <mat-select formControlName="idProcedure"
                                (selectionChange)="onProcedureChange($event.value)">
                                <mat-option *ngFor="let item of listProcedureCode" [value]="item.idProcedure">
                                    {{item.terminology.terminologyCode}} -
                                    {{item.terminology.terminologyName}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idProcedure').invalid">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>22 - Valor procedimento</mat-label>
                            <input matInput type="text" formControlName="procedureValue">
                            <mat-error *ngIf="model.get('procedureValue').invalid">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12">
                        <mat-form-field appearance="outline">
                            <mat-label>23 - Observação / Justificativa</mat-label>
                            <textarea matInput type="text" [maxlength]="appointmentFieldsMaxLengthEnum.justification"
                                formControlName="justification"></textarea>
                            <mat-error
                                *ngIf="model.get('justification').invalid && !model.get('justification').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ model.get('justification').value ?
                                model.get('justification').value.length : 0
                                }}/{{appointmentFieldsMaxLengthEnum.justification}}</mat-hint>
                        </mat-form-field>
                    </div>
                </div>
                <div mat-dialog-footer class="footer-modal">
                    <button mat-flat-button color="accent" class="btn-block" (click)="close()" type="button">
                        <span *ngIf="!isLoading">Fechar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>