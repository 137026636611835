
<form appFormscroll class="form" [formGroup]="model" (ngSubmit)="submit()">   
   
    <app-loading-progress-bar *ngIf="isLoading"></app-loading-progress-bar>

    <div>
        <div class="row">
            <div class="col-12" [ngClass]="{'aih-section-white-body': isPending}">
                <h2>Identificação do Estabelecimento de Saúde</h2>
                <div class="row">
                    <div class="field col-12 col-lg-8">
                        <mat-form-field appearance="outline">
                            <mat-label>1 - NOME DO ESTABELECIMENTO SOLICITANTE</mat-label>
                            <textarea [readonly]="!isEdit" matInput type="text" formControlName="nameRequesterEstablishment" oninput="this.value = this.value.replace(/[\n\r]/g, '');"></textarea>
                            <mat-error *ngIf="model.get('nameRequesterEstablishment').invalid && !model.get('nameRequesterEstablishment').errors?.maxlength">Preenchimento incorreto</mat-error>
                            <mat-error *ngIf="model.get('nameRequesterEstablishment').errors?.maxlength">Tamanho máximo do campo excedido</mat-error>
                            <mat-hint align="end">{{ model.get('nameRequesterEstablishment').value ? model.get('nameRequesterEstablishment').value.length : 0 }}/{{aihFieldsMaxLengthEnum.nameRequesterEstablishment}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="field col-12 col-lg-4">
                        <mat-form-field appearance="outline">
                            <mat-label>2 - CNES DO ESTABELECIMENTO SOLICITANTE</mat-label>
                            <input [readonly]="!isEdit" matInput type="text" formControlName="cnesRequesterEstablishment" [mask]="masks.cnes">
                            <mat-error *ngIf="model.get('cnesRequesterEstablishment').invalid">Preenchimento incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="field col-12 col-lg-8">
                        <mat-form-field appearance="outline">
                            <mat-label>3 - NOME DO ESTABELECIMENTO EXECUTANTE</mat-label>
                            <textarea [readonly]="!isEdit" matInput type="text" formControlName="nameExecutingEstablishment" oninput="this.value = this.value.replace(/[\n\r]/g, '');"></textarea>
                            <mat-error *ngIf="model.get('nameExecutingEstablishment').invalid && !model.get('nameExecutingEstablishment').errors?.maxlength">Preenchimento incorreto</mat-error>
                            <mat-error *ngIf="model.get('nameExecutingEstablishment').errors?.maxlength">Tamanho máximo do campo excedido</mat-error>
                            <mat-hint align="end">{{ model.get('nameExecutingEstablishment').value ? model.get('nameExecutingEstablishment').value.length : 0 }}/{{aihFieldsMaxLengthEnum.nameExecutingEstablishment}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="field col-12 col-lg-4">
                        <mat-form-field appearance="outline">
                            <mat-label>4 - CNES DO ESTABELECIMENTO EXECUTANTE</mat-label>
                            <input [readonly]="!isEdit" matInput type="text" formControlName="cnesExecutingEstablishment" [mask]="masks.cnes">
                            <mat-error *ngIf="model.get('cnesExecutingEstablishment').invalid">Preenchimento incorreto</mat-error>
                        </mat-form-field>
                    </div>   
                </div> 
            </div>
            
            <div class="col-12" [ngClass]="{'aih-section-white-body': isPending}">
                <h2>Identificação do Paciente</h2>
                <div class="row">
                    <div class="field col-12 col-lg-8">
                        <mat-form-field appearance="outline">
                            <mat-label>5 - NOME DO PACIENTE</mat-label>
                            <textarea [readonly]="!isEdit" matInput type="text" formControlName="patientName" oninput="this.value = this.value.replace(/[\n\r]/g, '');"></textarea>
                            <mat-error *ngIf="model.get('patientName').invalid && !model.get('patientName').errors?.maxlength">Preenchimento incorreto</mat-error>
                            <mat-error *ngIf="model.get('patientName').errors?.maxlength">Tamanho máximo do campo excedido</mat-error>
                            <mat-hint align="end">{{ model.get('patientName').value ? model.get('patientName').value.length : 0 }}/{{aihFieldsMaxLengthEnum.patientName}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="field col-12 col-lg-4">
                        <mat-form-field appearance="outline">
                            <mat-label>6 - Nº DO PRONTUÁRIO</mat-label>
                            <textarea [readonly]="!isEdit" matInput type="text" formControlName="patientPromptuary" oninput="this.value = this.value.replace(/[\n\r]/g, ''); this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');"></textarea>
                            <mat-error *ngIf="model.get('patientPromptuary').invalid && !model.get('patientPromptuary').errors?.maxlength">Preenchimento incorreto</mat-error>
                            <mat-error *ngIf="model.get('patientPromptuary').errors?.maxlength">Tamanho máximo do campo excedido</mat-error>
                            <mat-hint align="end">{{ model.get('patientPromptuary').value ? model.get('patientPromptuary').value.length : 0 }}/{{aihFieldsMaxLengthEnum.patientPromptuary}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="field col-12 col-md-6 col-lg-3">
                        <mat-form-field appearance="outline">
                            <mat-label>7 - CARTÃO NACIONAL DE SAÚDE (CNS)</mat-label>
                            <input [readonly]="!isEdit" matInput type="text" formControlName="patientCns" [mask]="masks.cns">
                            <mat-error *ngIf="model.get('patientCns').invalid">Preenchimento incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="field col-12 col-md-6 col-lg-3">
                        <mat-form-field appearance="outline">
                            <mat-label>8 - DATA DE NASCIMENTO</mat-label>
                            <input [readonly]="!isEdit" matInput type="text" formControlName="patientBirthDate" [mask]="masks.date">
                            <mat-error *ngIf="model.get('patientBirthDate').invalid && model.get('patientBirthDate').errors && !model.get('patientBirthDate').errors.invalidDate">Preenchimento incorreto</mat-error>
                            <mat-error *ngIf="model.get('patientBirthDate').invalid && model.get('patientBirthDate').errors && model.get('patientBirthDate').errors.invalidDate">{{model.get('patientBirthDate').errors.invalidDate}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="field col-12 col-md-6 col-lg-3">
                        <mat-form-field appearance="outline">
                            <mat-label>9 - SEXO</mat-label>
                            <mat-select formControlName="patientIdGender" >
                                <mat-option *ngFor="let item of listAihGender" [value]="item.idAihGender">{{item.aihGenderName}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('patientIdGender').invalid">Preenchimento incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="field col-12 col-md-6 col-lg-3">
                        <mat-form-field appearance="outline">
                            <mat-label>10 - RAÇA/COR</mat-label>
                            <input [readonly]="!isEdit" matInput type="text" formControlName="patientRace">
                            <mat-error *ngIf="model.get('patientRace').invalid && !model.get('patientRace').errors?.maxlength">Preenchimento incorreto</mat-error>
                            <mat-error *ngIf="model.get('patientRace').errors?.maxlength">Tamanho máximo do campo excedido</mat-error>
                            <mat-hint align="end">{{ model.get('patientRace').value ? model.get('patientRace').value.length : 0 }}/{{aihFieldsMaxLengthEnum.patientRace}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="field col-12 col-lg-8">
                        <mat-form-field appearance="outline">
                            <mat-label>11 - NOME DA MÃE</mat-label>
                            <textarea [readonly]="!isEdit" matInput type="text" formControlName="patientMotherName" oninput="this.value = this.value.replace(/[\n\r]/g, '');"></textarea>
                            <mat-error *ngIf="model.get('patientMotherName').invalid && !model.get('patientMotherName').errors?.maxlength">Preenchimento incorreto</mat-error>
                            <mat-error *ngIf="model.get('patientMotherName').errors?.maxlength">Tamanho máximo do campo excedido</mat-error>
                            <mat-hint align="end">{{ model.get('patientMotherName').value ? model.get('patientMotherName').value.length : 0 }}/{{aihFieldsMaxLengthEnum.patientMotherName}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="field col-12 col-lg-4">
                        <mat-form-field appearance="outline">
                            <mat-label>12 - TELEFONE DE CONTATO</mat-label>
                            <input [readonly]="!isEdit" matInput type="text" formControlName="patientMotherPhone" [mask]="masks.phone">
                            <mat-error *ngIf="model.get('patientMotherPhone').invalid">Informe o Telefone</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="field col-12 col-lg-8">
                        <mat-form-field appearance="outline">
                            <mat-label>13 - NOME DO RESPONSÁVEL</mat-label>
                            <textarea [readonly]="!isEdit" matInput type="text" formControlName="responsibleName" oninput="this.value = this.value.replace(/[\n\r]/g, '');"></textarea>
                            <mat-error *ngIf="model.get('responsibleName').invalid && !model.get('responsibleName').errors?.maxlength">Preenchimento incorreto</mat-error>
                            <mat-error *ngIf="model.get('responsibleName').errors?.maxlength">Tamanho máximo do campo excedido</mat-error>
                            <mat-hint align="end">{{ model.get('responsibleName').value ? model.get('responsibleName').value.length : 0 }}/{{aihFieldsMaxLengthEnum.responsibleName}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="field col-12 col-lg-4">
                        <mat-form-field appearance="outline">
                            <mat-label>14 - TELEFONE DE CONTATO</mat-label>
                            <input [readonly]="!isEdit" matInput type="text" formControlName="responsiblePhone" [mask]="masks.phone">
                            <mat-error *ngIf="model.get('responsiblePhone').invalid">Informe o Telefone</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="field col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>15 - ENDEREÇO (RUA, Nº, BAIRRO)</mat-label>
                            <textarea [readonly]="!isEdit" matInput type="text" formControlName="patientAdress" oninput="this.value = this.value.replace(/[\n\r]/g, '');"></textarea>
                            <mat-error *ngIf="model.get('patientAdress').invalid && !model.get('patientAdress').errors?.maxlength">Preenchimento incorreto</mat-error>
                            <mat-error *ngIf="model.get('patientAdress').errors?.maxlength">Tamanho máximo do campo excedido</mat-error>
                            <mat-hint align="end">{{ model.get('patientAdress').value ? model.get('patientAdress').value.length : 0 }}/{{aihFieldsMaxLengthEnum.patientAdress}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="field col-12 col-lg-6 col-xl-3">
                        <mat-form-field appearance="outline">
                            <mat-label>16 - MUNICÍPIO DE RESIDÊNCIA</mat-label>
                            <textarea [readonly]="!isEdit" matInput type="text" formControlName="patientCity" oninput="this.value = this.value.replace(/[\n\r]/g, '');"></textarea>
                            <mat-error *ngIf="model.get('patientCity').invalid && !model.get('patientCity').errors?.maxlength">Preenchimento incorreto</mat-error>
                            <mat-error *ngIf="model.get('patientCity').errors?.maxlength">Tamanho máximo do campo excedido</mat-error>
                            <mat-hint align="end">{{ model.get('patientCity').value ? model.get('patientCity').value.length : 0 }}/{{aihFieldsMaxLengthEnum.patientCity}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="field col-12 col-lg-6 col-xl-3">
                        <mat-form-field appearance="outline">
                            <mat-label>17 - CÓD. IBGE MUNICÍPIO</mat-label>
                            <textarea [readonly]="!isEdit" matInput type="text" formControlName="patientIbgeCityCode" oninput="this.value = this.value.replace(/[\n\r]/g, ''); this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');"></textarea>
                            <mat-error *ngIf="model.get('patientIbgeCityCode').invalid && !model.get('patientIbgeCityCode').errors?.maxlength">Preenchimento incorreto</mat-error>
                            <mat-error *ngIf="model.get('patientIbgeCityCode').errors?.maxlength">Tamanho máximo do campo excedido</mat-error>
                            <mat-hint align="end">{{ model.get('patientIbgeCityCode').value ? model.get('patientIbgeCityCode').value.length : 0 }}/{{aihFieldsMaxLengthEnum.patientIbgeCityCode}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="field col-12 col-lg-6 col-xl-3">
                        <mat-form-field appearance="outline">
                            <mat-label>18 - UF</mat-label>
                            <input [readonly]="!isEdit" matInput type="text" formControlName="patientState" maxlength="2" oninput="this.value = this.value.replace(/[\n\r]/g, ''); this.value = this.value.replace(/[^a-zA-Z]/g, '').toUpperCase();">
                            <mat-error *ngIf="model.get('patientState').invalid">Preenchimento incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="field col-12 col-lg-6 col-xl-3">
                        <mat-form-field appearance="outline">
                            <mat-label>19 - CEP</mat-label>
                            <input [readonly]="!isEdit" matInput type="text" formControlName="patientZipCode"  maxlength="8" oninput="this.value = this.value.replace(/[\n\r]/g, ''); this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                            <mat-error *ngIf="model.get('patientZipCode').invalid">Preenchimento incorreto</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>  
            
            <div class="col-12" [ngClass]="{'aih-section-white-body': isPending}">
                <h2>Justificativa da Internação</h2>
                <div class="row">
                    <div class="field col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>20 - PRINCIPAIS SINAIS E SINTOMAS CLÍNICOS</mat-label>
                            <textarea [readonly]="!isEdit" matInput type="text" formControlName="clinicalSymptoms" oninput="this.value = this.value.replace(/[\n\r]/g, '');"></textarea>
                            <mat-error *ngIf="model.get('clinicalSymptoms').invalid && !model.get('clinicalSymptoms').errors?.maxlength">Preenchimento incorreto</mat-error>
                            <mat-error *ngIf="model.get('clinicalSymptoms').errors?.maxlength">Tamanho máximo do campo excedido</mat-error>
                            <mat-hint align="end">{{ model.get('clinicalSymptoms').value ? model.get('clinicalSymptoms').value.length : 0 }}/{{aihFieldsMaxLengthEnum.clinicalSymptoms}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="field col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>21 - CONDIÇÕES QUE JUSTIFICAM A INTERNAÇÃO</mat-label>
                            <textarea [readonly]="!isEdit" matInput type="text" formControlName="conditionsForInternment" oninput="this.value = this.value.replace(/[\n\r]/g, '');"></textarea>
                            <mat-error *ngIf="model.get('conditionsForInternment').invalid && !model.get('conditionsForInternment').errors?.maxlength">Preenchimento incorreto</mat-error>
                            <mat-error *ngIf="model.get('conditionsForInternment').errors?.maxlength">Tamanho máximo do campo excedido</mat-error>
                            <mat-hint align="end">{{ model.get('conditionsForInternment').value ? model.get('conditionsForInternment').value.length : 0 }}/{{aihFieldsMaxLengthEnum.conditionsForInternment}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="field col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>22 - PRINCIPAIS RESULTADOS DE PROVAS DIAGNÓSTICAS (RESULTADOS DE EXAMES REALIZADOS)</mat-label>
                            <textarea [readonly]="!isEdit" matInput type="text" formControlName="mainDiagnosticResults" oninput="this.value = this.value.replace(/[\n\r]/g, '');"></textarea>
                            <mat-error *ngIf="model.get('mainDiagnosticResults').invalid && !model.get('mainDiagnosticResults').errors?.maxlength">Preenchimento incorreto</mat-error>
                            <mat-error *ngIf="model.get('mainDiagnosticResults').errors?.maxlength">Tamanho máximo do campo excedido</mat-error>
                            <mat-hint align="end">{{ model.get('mainDiagnosticResults').value ? model.get('mainDiagnosticResults').value.length : 0 }}/{{aihFieldsMaxLengthEnum.mainDiagnosticResults}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="field col-12 col-lg-6 col-xl-3">
                        <mat-form-field appearance="outline">
                            <mat-label>23 - DIAGNÓSTICO INICIAL</mat-label>
                            <textarea [readonly]="!isEdit" matInput type="text" formControlName="initialDiagnostic" oninput="this.value = this.value.replace(/[\n\r]/g, '');"></textarea>
                            <mat-error *ngIf="model.get('initialDiagnostic').invalid && !model.get('initialDiagnostic').errors?.maxlength">Preenchimento incorreto</mat-error>
                            <mat-error *ngIf="model.get('initialDiagnostic').errors?.maxlength">Tamanho máximo do campo excedido</mat-error>
                            <mat-hint align="end">{{ model.get('initialDiagnostic').value ? model.get('initialDiagnostic').value.length : 0 }}/{{aihFieldsMaxLengthEnum.initialDiagnostic}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="field col-12 col-lg-6 col-xl-3" *ngIf="aih && (aih.cid10Main != null && aih.idCid10Main == null)">
                        <mat-form-field appearance="outline">
                            <mat-label>24 - CID 10 PRINCIPAL</mat-label>
                            <textarea [readonly]="!isEdit" matInput type="text" formControlName="cid10Main" oninput="this.value = this.value.replace(/[\n\r]/g, '');"></textarea>
                            <mat-error *ngIf="model.get('cid10Main').invalid && !model.get('cid10Main').errors?.maxlength">Preenchimento incorreto</mat-error>
                            <mat-error *ngIf="model.get('cid10Main').errors?.maxlength">Tamanho máximo do campo excedido</mat-error>
                            <mat-hint align="end">{{ model.get('cid10Main').value ? model.get('cid10Main').value.length : 0 }}/{{aihFieldsMaxLengthEnum.cid10Main}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="field col-12 col-lg-6 col-xl-3" *ngIf="!aih || aih.idCid10Main != null || (aih.cid10Main == null && aih.idCid10Main == null)">
                        <app-select-2 [formControlField]="'idCid10Main'" (selectedValue)="selectCid($event, 'cid10Main')"
                            [multiple]="false" [url]="urlListCid" [idField]="'idCid'"
                            [nameField]="'cidName'" [labelField]="'24 - CID 10 PRINCIPAL'">
                        </app-select-2>
                    </div>
                    <div class="field col-12 col-lg-6 col-xl-3" *ngIf="aih && (aih.cid10Secondary != null && aih.idCid10Secondary == null)">
                        <mat-form-field appearance="outline">
                            <mat-label>25 - CID 10 SECUNDÁRIO</mat-label>
                            <textarea [readonly]="!isEdit" matInput type="text" formControlName="cid10Secondary" oninput="this.value = this.value.replace(/[\n\r]/g, '');"></textarea>
                            <mat-error *ngIf="model.get('cid10Secondary').invalid && !model.get('cid10Secondary').errors?.maxlength">Preenchimento incorreto</mat-error>
                            <mat-error *ngIf="model.get('cid10Secondary').errors?.maxlength">Tamanho máximo do campo excedido</mat-error>
                            <mat-hint align="end">{{ model.get('cid10Secondary').value ? model.get('cid10Secondary').value.length : 0 }}/{{aihFieldsMaxLengthEnum.cid10Secondary}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="field col-12 col-lg-6 col-xl-3" *ngIf="!aih || aih.idCid10Secondary != null || (aih.cid10Secondary == null && aih.idCid10Secondary == null)">
                        <app-select-2 [formControlField]="'idCid10Secondary'" (selectedValue)="selectCid($event, 'cid10Secondary')"
                            [multiple]="false" [url]="urlListCid" [idField]="'idCid'"
                            [nameField]="'cidName'" [labelField]="'25 - CID 10 SECUNDÁRIO'" [hasNullSelectField]="true">
                        </app-select-2>
                    </div>
                    <div class="field col-12 col-lg-6 col-xl-3" *ngIf="aih && (aih.cid10AssociatedCauses != null && aih.idCid10AssociatedCauses == null)">
                        <mat-form-field appearance="outline">
                            <mat-label>26 - CID 10 CAUSAS ASSOCIADAS</mat-label>
                            <textarea [readonly]="!isEdit" matInput type="text" formControlName="cid10AssociatedCauses" oninput="this.value = this.value.replace(/[\n\r]/g, '');"></textarea>
                            <mat-error *ngIf="model.get('cid10AssociatedCauses').invalid && !model.get('cid10AssociatedCauses').errors?.maxlength">Preenchimento incorreto</mat-error>
                            <mat-error *ngIf="model.get('cid10AssociatedCauses').errors?.maxlength">Tamanho máximo do campo excedido</mat-error>
                            <mat-hint align="end">{{ model.get('cid10AssociatedCauses').value ? model.get('cid10AssociatedCauses').value.length : 0 }}/{{aihFieldsMaxLengthEnum.cid10AssociatedCauses}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="field col-12 col-lg-6 col-xl-3" *ngIf="!aih || aih.idCid10AssociatedCauses != null || (aih.cid10AssociatedCauses == null && aih.idCid10AssociatedCauses == null)">
                        <app-select-2 [formControlField]="'idCid10AssociatedCauses'" (selectedValue)="selectCid($event, 'cid10AssociatedCauses')"
                            [multiple]="false" [url]="urlListCid" [idField]="'idCid'"
                            [nameField]="'cidName'" [labelField]="'26 - CID 10 CAUSAS ASSOCIADAS'" [hasNullSelectField]="true">
                        </app-select-2>
                    </div>
                </div>
            </div>
            
            <div class="col-12" [ngClass]="{'aih-section-white-body': isPending}">
                <h2>Procedimento Solicitado</h2>
                <div class="row">
                    <div class="field col-12 col-lg-8">
                        <app-autocomplete [formControlField]="'procedureRequestedDescription'" [isDisabled]="!isEdit"
                            [url]="urlApiBilling" [nameField]="'procedureName'" [labelField]="'27 - DESCRIÇÃO DO PROCEDIMENTO SOLICITADO'"
                            (selectedValue)="selectProcedureSigtap($event)">
                        </app-autocomplete>
                    </div>
                    <div class="field col-12 col-lg-4">
                        <mat-form-field appearance="outline">
                            <mat-label>28 - CÓDIGO DO PROCEDIMENTO</mat-label>
                            <textarea [readonly]="!isEdit" matInput type="text" formControlName="procedureCode" oninput="this.value = this.value.replace(/[\n\r]/g, '');"></textarea>
                            <mat-error *ngIf="model.get('procedureCode').invalid && !model.get('procedureCode').errors?.maxlength">Preenchimento incorreto</mat-error>
                            <mat-error *ngIf="model.get('procedureCode').errors?.maxlength">Tamanho máximo do campo excedido</mat-error>
                            <mat-hint align="end">{{ model.get('procedureCode').value ? model.get('procedureCode').value.length : 0 }}/{{aihFieldsMaxLengthEnum.procedureCode}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="field col-12 col-lg-6 col-xl-3" *ngIf="aih && (aih.procedureClinic != null && aih.idProcedureClinic == null)">
                        <mat-form-field appearance="outline">
                            <mat-label>29 - CLÍNICA</mat-label>
                            <textarea [readonly]="!isEdit" matInput type="text" formControlName="procedureClinic" oninput="this.value = this.value.replace(/[\n\r]/g, '');"></textarea>
                            <mat-error *ngIf="model.get('procedureClinic').invalid && !model.get('procedureClinic').errors?.maxlength">Preenchimento incorreto</mat-error>
                            <mat-error *ngIf="model.get('procedureClinic').errors?.maxlength">Tamanho máximo do campo excedido</mat-error>
                            <mat-hint align="end">{{ model.get('procedureClinic').value ? model.get('procedureClinic').value.length : 0 }}/{{aihFieldsMaxLengthEnum.procedureClinic}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="field col-12 col-lg-6 col-xl-3" *ngIf="!aih || aih.idProcedureClinic != null || (aih.procedureClinic == null && aih.idProcedureClinic == null)">
                        <mat-form-field appearance="outline">
                            <mat-label>29 - CLÍNICA</mat-label>
                            <mat-select formControlName="idProcedureClinic" (selectionChange)="selectProcedureClinic($event.value)">
                                <mat-option [value]="null">Selecione...</mat-option>
                                <mat-option *ngFor="let item of listClinicType" [value]="item.idClinicType">{{item.clinicTypeName}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idProcedureClinic').invalid">Preenchimento incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="field col-12 col-lg-6 col-xl-3">
                        <mat-form-field appearance="outline">
                            <mat-label>30 - CARÁTER DA INTERNAÇÃO</mat-label>
                            <textarea [readonly]="!isEdit" matInput type="text" formControlName="procedureInternmentCharacter" oninput="this.value = this.value.replace(/[\n\r]/g, '');"></textarea>
                            <mat-error *ngIf="model.get('procedureInternmentCharacter').invalid && !model.get('procedureInternmentCharacter').errors?.maxlength">Preenchimento incorreto</mat-error>
                            <mat-error *ngIf="model.get('procedureInternmentCharacter').errors?.maxlength">Tamanho máximo do campo excedido</mat-error>
                            <mat-hint align="end">{{ model.get('procedureInternmentCharacter').value ? model.get('procedureInternmentCharacter').value.length : 0 }}/{{aihFieldsMaxLengthEnum.procedureInternmentCharacter}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="field col-12 col-lg-6 col-xl-3">
                        <mat-form-field appearance="outline">
                            <mat-label>31 - DOCUMENTO</mat-label>
                            <mat-select formControlName="procedureProfessionalIdDocumentType" (selectionChange)="selectDocumentTypeChange($event.value)" >
                                <mat-option [value]="null"></mat-option>
                                <mat-option *ngFor="let item of listDocumentType" [value]="item.idDocumentType">{{item.documentTypeName}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('procedureProfessionalIdDocumentType').invalid">Preenchimento incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="field col-12 col-lg-6 col-xl-3">
                        <mat-form-field appearance="outline">
                            <mat-label>32 - Nº DOCUMENTO (CNS/CPF) DO PROFISSIONAL SOLICITANTE/ASSISTENTE</mat-label>
                            <input [readonly]="!isEdit" matInput type="text" formControlName="procedureProfessionalDocument" maxlength="41" [mask]="maskToProcedureProfessionalDocument">
                            <mat-error *ngIf="model.get('procedureProfessionalDocument').invalid">Preenchimento incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="field col-lg-12 col-xl-4">
                        <mat-form-field appearance="outline">
                            <mat-label>33 - NOME DO PROFISSIONAL SOLICITANTE/ASSISTENTE</mat-label>
                            <textarea [readonly]="!isEdit" matInput type="text" formControlName="procedureProfessionalName" oninput="this.value = this.value.replace(/[\n\r]/g, '');"></textarea>
                            <mat-error *ngIf="model.get('procedureProfessionalName').invalid && !model.get('procedureProfessionalName').errors?.maxlength">Preenchimento incorreto</mat-error>
                            <mat-error *ngIf="model.get('procedureProfessionalName').errors?.maxlength">Tamanho máximo do campo excedido</mat-error>
                            <mat-hint align="end">{{ model.get('procedureProfessionalName').value ? model.get('procedureProfessionalName').value.length : 0 }}/{{aihFieldsMaxLengthEnum.procedureProfessionalName}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="field col-12 col-lg-6 col-xl-4">
                        <mat-form-field appearance="outline">
                            <mat-label>34 - DATA DA SOLICITAÇÃO</mat-label>
                            <input [readonly]="!isEdit" matInput type="text" formControlName="procedureRequestedDate" [mask]="masks.date">
                            <mat-error *ngIf="model.get('procedureRequestedDate').invalid && model.get('procedureRequestedDate').errors && !model.get('procedureRequestedDate').errors.invalidDate">Preenchimento incorreto</mat-error>
                            <mat-error *ngIf="model.get('procedureRequestedDate').invalid && model.get('procedureRequestedDate').errors && model.get('procedureRequestedDate').errors.invalidDate">{{model.get('procedureRequestedDate').errors.invalidDate}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            
            <div class="col-12" [ngClass]="{'aih-section-white-body': isPending}">
                <h2>Preencher em caso de causas externas (acidentes ou violências)</h2>
                <div class="row">
                    <div class="col-12 col-lg-3">
                        <div class="col-12 form-field-toggle">
                            <mat-label>36 - ACIDENTE DE TRÂNSITO</mat-label>
                            <mat-slide-toggle  formControlName="isTrafficAccident" (change)="setAccidentType($event, 'isTrafficAccident')"></mat-slide-toggle>
                        </div>
                        <div class="col-12 form-field-toggle">
                            <mat-label>37 - ACIDENTE TRABALHO TÍPICO</mat-label>
                            <mat-slide-toggle  formControlName="isTypicalWorkAccident" (change)="setAccidentType($event, 'isTypicalWorkAccident')"></mat-slide-toggle>
                        </div>
                        <div class="col-12 form-field-toggle">
                            <mat-label>38 - ACIDENTE TRABALHO TRAJETO</mat-label>
                            <mat-slide-toggle  formControlName="isRouteWorkAccident" (change)="setAccidentType($event, 'isRouteWorkAccident')"></mat-slide-toggle>
                        </div>
                    </div>
                    <div class="col-12 col-lg-9">
                        <div class="row">
                            <div class="field col-12 col-lg-6 col-xl-4">
                                <mat-form-field appearance="outline">
                                    <mat-label>39 - CNPJ DA SEGURADORA</mat-label>
                                    <input [readonly]="!isEdit" matInput type="text" formControlName="insurerCnpj" [mask]="masks.cnpj">
                                    <mat-error *ngIf="model.get('insurerCnpj').invalid">Preenchimento incorreto</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="field col-12 col-lg-6 col-xl-4">
                                <mat-form-field appearance="outline">
                                    <mat-label>40 - Nº DO BILHETE</mat-label>
                                    <textarea [readonly]="!isEdit" matInput type="text" formControlName="insurerTicketNumber" oninput="this.value = this.value.replace(/[\n\r]/g, '');"></textarea>
                                    <mat-error *ngIf="model.get('insurerTicketNumber').invalid && !model.get('insurerTicketNumber').errors?.maxlength">Preenchimento incorreto</mat-error>
                                    <mat-error *ngIf="model.get('insurerTicketNumber').errors?.maxlength">Tamanho máximo do campo excedido</mat-error>
                                    <mat-hint align="end">{{ model.get('insurerTicketNumber').value ? model.get('insurerTicketNumber').value.length : 0 }}/{{aihFieldsMaxLengthEnum.insurerTicketNumber}}</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="field col-12 col-lg-6 col-xl-4">
                                <mat-form-field appearance="outline">
                                    <mat-label>41 - SÉRIE</mat-label>
                                    <textarea [readonly]="!isEdit" matInput type="text" formControlName="insurerSeries" oninput="this.value = this.value.replace(/[\n\r]/g, '');"></textarea>
                                    <mat-error *ngIf="model.get('insurerSeries').invalid && !model.get('insurerSeries').errors?.maxlength">Preenchimento incorreto</mat-error>
                                    <mat-error *ngIf="model.get('insurerSeries').errors?.maxlength">Tamanho máximo do campo excedido</mat-error>
                                    <mat-hint align="end">{{ model.get('insurerSeries').value ? model.get('insurerSeries').value.length : 0 }}/{{aihFieldsMaxLengthEnum.insurerSeries}}</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="field col-12 col-lg-6 col-xl-4">
                                <mat-form-field appearance="outline">
                                    <mat-label>42 - CNPJ EMPRESA</mat-label>
                                    <input [readonly]="!isEdit" matInput type="text" formControlName="companyCnpj" [mask]="masks.cnpj">
                                    <mat-error *ngIf="model.get('companyCnpj').invalid">Preenchimento incorreto</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="field col-12 col-lg-6 col-xl-4">
                                <mat-form-field appearance="outline">
                                    <mat-label>43 - CNAE DA EMPRESA</mat-label>
                                    <textarea [readonly]="!isEdit" matInput type="text" formControlName="companyCnae" oninput="this.value = this.value.replace(/[\n\r]/g, '');"></textarea>
                                    <mat-error *ngIf="model.get('companyCnae').invalid && !model.get('companyCnae').errors?.maxlength">Preenchimento incorreto</mat-error>
                                    <mat-error *ngIf="model.get('companyCnae').errors?.maxlength">Tamanho máximo do campo excedido</mat-error>
                                    <mat-hint align="end">{{ model.get('companyCnae').value ? model.get('companyCnae').value.length : 0 }}/{{aihFieldsMaxLengthEnum.companyCnae}}</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="field col-12 col-lg-6 col-xl-4">
                                <mat-form-field appearance="outline">
                                    <mat-label>44 - CBOR</mat-label>
                                    <textarea [readonly]="!isEdit" matInput type="text" formControlName="cbor" oninput="this.value = this.value.replace(/[\n\r]/g, '');"></textarea>
                                    <mat-error *ngIf="model.get('cbor').invalid && !model.get('cbor').errors?.maxlength">Preenchimento incorreto</mat-error>
                                    <mat-error *ngIf="model.get('cbor').errors?.maxlength">Tamanho máximo do campo excedido</mat-error>
                                    <mat-hint align="end">{{ model.get('cbor').value ? model.get('cbor').value.length : 0 }}/{{aihFieldsMaxLengthEnum.cbor}}</mat-hint>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="field col-12 col-lg-3">
                        <mat-form-field appearance="outline">
                            <mat-label>45 - VÍNCULO COM A PREVIDÊNCIA</mat-label>
                            <mat-select formControlName="idRelationshipWithSocialSecurity">
                                <mat-option [value]="null"></mat-option>
                                <mat-option *ngFor="let item of listRelationshipWithSocialSecurity" [value]="item.idRelationshipWithSocialSecurity">{{item.relationshipWithSocialSecurityName}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idRelationshipWithSocialSecurity').invalid">Preenchimento incorreto</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>  
        
        
        <div *ngIf="isEdit" class="row footer">
            <div class="col-md-2 save">
                <button mat-flat-button type="submit" color="primary" class=" btn-block save">
                    <span *ngIf="isLoading == false">Salvar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
            </div>
            <div *ngIf="isPending" class="col-md-2 save">
                <button mat-flat-button type="button" color="accent" class=" btn-block save" (click)="cancelEdit()">
                    <span *ngIf="isLoading == false">Cancelar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
            </div>
        </div>
    </div>
</form>
