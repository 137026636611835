<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum'></app-menu>
    <div class="container-body">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <h1>Configuração de acesso</h1>
            <div class="white-body">
                <app-loading-list *ngIf="isLoading"></app-loading-list>
                <div class="title-header">
                    <div class="mat-typography">
                        <h2>
                            Identificação
                        </h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-6">
                        <mat-form-field appearance="outline" *ngIf="isLoading != true">
                            <mat-label>Nome</mat-label>
                            <input matInput type="text" formControlName="name">
                            <mat-error *ngIf="model.get('accessUrl').invalid">Informe o nome</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6">
                        <mat-form-field appearance="outline" *ngIf="isLoading != true">
                            <mat-label>URL de integração</mat-label>
                            <input matInput type="text" formControlName="integrationUrl"
                                (keyup)="getCompleteIntegrationUrl()">
                            <mat-error *ngIf="model.get('integrationUrl').invalid">Informe a URL de
                                integração</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-6">
                        <mat-form-field appearance="outline" *ngIf="isLoading != true">
                            <mat-label>URL de acesso</mat-label>
                            <input matInput type="text" formControlName="accessUrl" (keyup)="getCompleteAccessUrl()">
                            <mat-error *ngIf="model.get('accessUrl').invalid">Informe a URL de acesso</mat-error>
                        </mat-form-field>
                        <span *ngIf="model.get('accessUrl').value">
                            <a type="button" class="complete-link" [href]="completeAccessUrl"
                                target="_blank">{{completeAccessUrl}}</a>
                            <button matTooltip="Copiar" aria-label="Copiar" class="copy-to-clipboard" type="button"
                                [cdkCopyToClipboard]="completeAccessUrl" [cdkCopyToClipboardAttempts]="5"
                                (click)="onCopy('Item copiado')">
                                <mat-icon class="copy-icons">content_copy</mat-icon>
                            </button>
                        </span>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6">
                        <mat-form-field appearance="outline" *ngIf="isLoading != true">
                            <mat-label>IP do dispositivo</mat-label>
                            <input matInput type="text" inputmode="numeric" formControlName="ipDevice">
                            <mat-error *ngIf="model.get('ipDevice').invalid">Informe o IP do dispositivo</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-slide-toggle class="test-checkbox" formControlName="isClientTest">
                            Modo teste {{model.get('isClientTest').value ? 'ativado' : 'desativado'}}
                        </mat-slide-toggle>
                    </div>
                </div>
                <div class="title-header">
                    <div class="mat-typography">
                        <h2>
                            Ordenação de telas
                        </h2>
                    </div>
                </div>
                <app-loading-list *ngIf="isLoading"></app-loading-list>
                <div *ngIf="!isLoading">
                    <div class="row" formArrayName="screenFlow"
                        *ngFor="let item of model.get('screenFlow')['controls']; let i = index">
                        <div class="row" [formGroupName]="i">
                            <div class="col-4 col-sm-4 col-md-3">
                                <mat-form-field appearance="outline">
                                    <mat-label>Tela</mat-label>
                                    <mat-select formControlName="idUBSScreen"
                                        (selectionChange)="setScreenData($event, i)">
                                        <mat-option *ngFor="let screen of listScreen" [value]="screen.idUBSScreen"
                                            [disabled]="listSelectedScreens.includes(screen.idUBSScreen)">
                                            {{screen.screenDescription}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-2 col-sm-2 col-md-2">
                                <mat-form-field appearance="outline">
                                    <mat-label>Ordem</mat-label>
                                    <input matInput readonly formControlName="order">
                                </mat-form-field>
                            </div>
                            <div class="col-2 col-sm-2 col-md-3">
                                <button class="exception-button " color="primary" mat-mini-fab type="button"
                                    *ngIf="i < (listScreen.length - 1)" (click)="addNext()">
                                    <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
                                </button>
                                <button class="exception-button " color="primary" mat-mini-fab type="button"
                                    *ngIf="i > 0" (click)="removeScreen(i)">
                                    <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <a mat-flat-button type="button" routerLink="/ubs-monitoring" color="accent" class=" btn-block">
                            Cancelar
                        </a>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>