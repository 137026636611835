<div class="container-generic">
    
    <app-menu [menuModuleEnum]='menuModuleEnum'></app-menu>

    <div class="container-body">
        <div *ngIf="isPending">
            <div class="row title">
                <h1 class="bold">Autorização de internação hospitalar</h1>
                <hr/> 
            </div>

            <app-loading-list *ngIf="isLoading"></app-loading-list> 

            <app-view-aih *ngIf="!isLoading" 
            [idAihGroup]="idAihGroup"
            [idEpisode]="idEpisode"
            [listDocumentType]="listDocumentType"
            [listClinicType]="listClinicType"
            [listAihGender]="listAihGender"
            [listRelationAihConfigFieldRequired]="listRelationAihConfigFieldRequired"
            [listLookupHospitalizationReport]="listLookupHospitalizationReport"
            [listRelationshipWithSocialSecurity]="listRelationshipWithSocialSecurity"
            [aih]="aihToPending"
            [isEdit]="true"
            [isPending]="true"
            (back)="backToLastComponent()"></app-view-aih>
        </div>
        
        <div *ngIf="!isPending">
            <h1>Detalhes do AIH do episódio</h1>
            <div class="white-body">           
                <div class="title-header">
                    <div class="row">
                        <div class="col-9">
                            <h1>Histórico</h1>
                        </div>
                        <div class="col-3">
                            <button mat-flat-button type="button" color="primary" class=" btn-block"
                                (click)="!isLoading ? downloadReport() : false">
                                <span *ngIf="!isLoading">Download da última AIH</span>
                                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true">
                                </mat-spinner>
                            </button>
                        </div>
                    </div>
                </div>
    
                <app-loading-list *ngIf="isLoading"></app-loading-list> 

                <div *ngIf="!isLoading">
                    <div class="row">
                        <div class="col-12 form-field-toggle">
                            <mat-slide-toggle [(ngModel)]="toggleAllowedToEdit" (change)="disableEditToggle($event.checked)">{{allowedToEdit? "Desabilitar" : "Habilitar"}} edição da AIH mais recente</mat-slide-toggle>
                        </div>
                    </div>              
                    
                    <cdk-accordion class="aih-editor-accordion">
                        <cdk-accordion-item *ngFor="let itemAihStruct of listAihStruct; let index = index;"
                            #accordionItemModule="cdkAccordionItem" class="aih-editor-accordion-item" role="button"
                        tabindex="0" [attr.id]="'accordion-header-' + index" [attr.aria-expanded]="accordionItemModule.expanded"
                        [attr.aria-controls]="'accordion-body-' + index">
                            <div [ngClass]="{'aih-editor-accordion-item-header-expanded': accordionItemModule.expanded}"
                            class="aih-editor-accordion-item-header" (click)="accordionItemModule.toggle()">
                    
                                Data de criação: ({{ itemAihStruct.aih.datetimeInclusion | date:'dd/MM/yy, H:mm' }} | Criador: {{itemAihStruct.userName }})
                    
                                <span [ngClass]="{'aih-editor-accordion-item-description-expanded': accordionItemModule.expanded}"
                                class="aih-editor-accordion-item-description">
                                    Clique para {{ accordionItemModule.expanded ? 'fechar' : 'abrir' }}
                                </span>
                            </div>
                            <div class="aih-editor-accordion-item-body" role="region"
                            [style.display]="accordionItemModule.expanded ? '' : 'none'" [attr.id]="'accordion-body-' + index"
                            [attr.aria-labelledby]="'accordion-header-' + index">     
                                <div class="white-body">
                                    <app-view-aih 
                                    [idAihGroup]="idAihGroup"
                                    [idEpisode]="idEpisode"
                                    [listDocumentType]="listDocumentType"
                                    [listClinicType]="listClinicType"
                                    [listAihGender]="listAihGender"
                                    [listRelationAihConfigFieldRequired]="listRelationAihConfigFieldRequired"
                                    [listLookupHospitalizationReport]="listLookupHospitalizationReport"
                                    [listRelationshipWithSocialSecurity]="listRelationshipWithSocialSecurity"
                                    [aih]="itemAihStruct.aih"
                                    [isEdit]="allowedToEdit && index == 0"
                                    [isPending]="false"
                                    (reload)="reloadList()"></app-view-aih>
                                </div>   
                            </div>
                        </cdk-accordion-item>
                    </cdk-accordion>  
                </div>               
    
                <div class="row footer">
                    <div class="col-md-2 save">
                        <button mat-flat-button type="button" color="accent" class=" btn-block save" (click)="buttonBack()">
                            <span *ngIf="isLoading == false">Voltar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                </div>
            </div>
        </div>         
    </div>
</div>








