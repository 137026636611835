import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { StorageService } from 'src/app/shared/services/API/pharmacy/storage.service';
import { StorageStruct } from 'src/app/shared/services/structs/pharmacy/storage.struct';
import { StorageDeleteModalComponent } from './storage-delete-modal/storage-delete-modal.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { LocationService } from 'src/app/shared/services/API/pharmacy/location.service';
import { LocationModel } from 'src/app/shared/services/models/pharmacy/location.model';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { PharmacyTagRequest } from 'src/app/shared/services/requests/Report/pharmacy-tag.request';
import { ReportModalComponent } from 'src/app/shared/components/report-modal/report-modal.component';
import { PharmacyTagService } from 'src/app/shared/services/API/report/pharmacy-tag.service';
import { MedicineService } from 'src/app/shared/services/API/pharmacy/medicine.service';
import { ItemService } from 'src/app/shared/services/API/pharmacy/Item.service';
import { MedicineTagUserService } from 'src/app/shared/services/API/pharmacy/medicine-tag-user.service';
import { MedicineTagUser } from 'src/app/shared/services/models/pharmacy/medicine-tag-user.model';
import { MedicineStruct } from 'src/app/shared/services/structs/pharmacy/medicine.struct';
import { ItemModel } from 'src/app/shared/services/models/pharmacy/item.model';
import { TagUserEnum } from 'src/app/shared/enum/pharmacy/tag-user.enum';
import { MedicineTagEnum } from 'src/app/shared/enum/medicine-tag.enum';


@Component({
  selector: 'app-storage-list',
  templateUrl: './storage-list.component.html',
  styleUrls: ['./storage-list.component.css']
})
export class StorageListComponent implements OnInit {

  constructor(private storageService: StorageService,
    private alertService: AlertService,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private locationService: LocationService,
    private pharmacyTagService: PharmacyTagService,
    private medicineService: MedicineService,
    private itemService: ItemService,
    private medicineTagUserService: MedicineTagUserService,
    private maskService: MaskService
  ) { }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.pharmacy;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.pharmacy_storage;

  public listStorage: StorageStruct[];
  public isLoading: boolean;
  public isActive: boolean;
  public model: UntypedFormGroup;
  public pageIndex: number;
  public code: string;
  public batch: string;
  public idStorage: number;
  public description: string;
  public initalExpirationDateString: string;
  public finalExpirationDateString: string;
  public initalExpirationDate: Date;
  public finalExpirationDate: Date;
  public idLocation: number;
  public fullSize: number;
  public listLocation: LocationModel[];
  public masks: Masks;
  public productName: string;
  public medicineTagUser: MedicineTagUser;
  public listMedicine: MedicineStruct[] = [];
  public listItem: ItemModel[];
  public tagUserEnumWidth: TagUserEnum = TagUserEnum.Largura;
  public tagUserEnumHeight: TagUserEnum = TagUserEnum.Altura;

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      code: [''],
      barcode: [''],
      description: [''],
      batch: [''],
      dateExpirationInitial: [''],
      dateExpirationFinal: [''],
      idLocation: [''],
    });
    this.isLoading = true;
    this.search();
    this.getLocations();
    this.getMedicine();
    this.getItem();
    this.getMedicineTagUser();
  }

  search() {
    this.isLoading = true;
    this.storageService.listAllStorage(this.pageIndex, this.code, this.batch, this.idStorage, this.description, this.initalExpirationDateString, this.finalExpirationDateString, this.idLocation).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.listStorage = response.listStorage;
      this.fullSize = response.fullsize;
      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }


  buttonSearch() {
    this.pageIndex = 0;
    this.listStorage = [];
    this.code = this.model.get("code").value;
    this.batch = this.model.get("batch").value;
    this.idStorage = this.model.get("barcode").value;
    this.description = this.model.get("description").value;
    this.initalExpirationDate = this.maskService.formatStringToDate(this.model.get("dateExpirationInitial").value);
    this.initalExpirationDateString = this.maskService.formatDateToString(this.initalExpirationDate, null, true);
    this.finalExpirationDate = this.maskService.formatStringToDate(this.model.get("dateExpirationFinal").value);
    this.finalExpirationDateString = this.maskService.formatDateToString(this.finalExpirationDate, null, true);
    this.idLocation = this.model.get("idLocation").value;
    this.search();
  }

  changePage(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.listStorage = [];
    this.model.get("code").setValue(this.code);
    this.model.get("batch").setValue(this.batch);
    this.model.get("barcode").setValue(this.idStorage);
    this.model.get("description").setValue(this.description);
    this.model.get("dateExpirationInitial").setValue(this.maskService.formatDateToString(this.initalExpirationDate, false));
    this.model.get("dateExpirationFinal").setValue(this.maskService.formatDateToString(this.finalExpirationDate, false));
    this.model.get("idLocation").setValue(this.idLocation);
    this.search();
  }


  getLocations() {
    this.locationService.readActive(null).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.listLocation = response.listLocation;
    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  openModal(idStorage) {
    const dialogRef = this.dialog.open(StorageDeleteModalComponent, {
      data: {
        idStorage: idStorage
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.deleteStorage) {
        this.search();
      }
    });
  }

  openModalReport(idStorage: number) {
    this.isLoading = true;
    let request: PharmacyTagRequest = new PharmacyTagRequest();
    request.idStorage = idStorage;
    let storage: StorageStruct = new StorageStruct();
    storage = this.listStorage.find(x => x.idStorage == idStorage);
    if (storage.idMedicine)
    request.productName = this.listMedicine.find(c => c.medicine.idMedicine == storage.idMedicine).medicine.medicineDescription;
    else
    request.productName = this.listItem.find(c => c.idItem == storage.idItem).itemName;

    let dateExpiration = this.maskService.formatDateToString(storage.dateExpiration, null, true);
    request.dateExpiration = dateExpiration;
    request.batch = storage.batch.toString();
    request.amountTotal = storage.storageAmountLocation.amountTotal;
    if(this.medicineTagUser.idMedicineTag != MedicineTagEnum.Outro || (this.medicineTagUser.width == null && this.medicineTagUser.height == null))
    {
      request.height = this.tagUserEnumHeight.valueOf();
      request.width = this.tagUserEnumWidth.valueOf();
    }
    else
    {
      request.height = this.medicineTagUser.height;
      request.width = this.medicineTagUser.width;
    }

    this.pharmacyTagService.getTags(request, 1).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        let reportDialog = this.dialog.open(ReportModalComponent, {
          data: {
            reportName: response.reportName,
            reportPdf64: response.reportPdf64,
          },
        });
        reportDialog.afterClosed().subscribe(result => {
          this.isLoading = false;
        });
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getMedicine() {
    this.medicineService.listAllMedicine().subscribe({
      next: (response) => {
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        this.listMedicine = response.listMedicineStruct;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getItem() {
    this.itemService.listItem(null).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.listItem = response.listItem;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getMedicineTagUser() {
    this.medicineTagUserService.getMedicineTagUser().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.medicineTagUser = response.medicineTagUser;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}
