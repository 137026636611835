import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { CallEpisodePrescriptionService } from 'src/app/shared/services/API/orchestrator-patient/call-episode-prescription.service';
import { LocationService } from 'src/app/shared/services/API/pharmacy/location.service';
import { MedicinePresentationTypeService } from 'src/app/shared/services/API/pharmacy/medicine-presentation-type.service';
import { VerifyStorageService } from 'src/app/shared/services/API/pharmacy/verify-storage.service';
import { PrescriptionNonDispensationRequest } from 'src/app/shared/services/requests/medical-record/prescription-non-dispensation.request';
import { EpisodePrescriptionModalComponent } from '../episode-prescription-modal/episode-prescription-modal.component';
import { PrescriptionNonDispensationService } from 'src/app/shared/services/API/medical-record/prescription-non-dispensation.service';

@Component({
  selector: 'app-prescription-non-dispensation-modal',
  templateUrl: './prescription-non-dispensation-modal.component.html',
  styleUrls: ['./prescription-non-dispensation-modal.component.css']
})
export class PrescriptionNonDispensationModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<EpisodePrescriptionModalComponent>,
    public verifyStorageService: VerifyStorageService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    public locationService: LocationService,
    public callEpisodePrescriptionService: CallEpisodePrescriptionService,
    public medicinePresentationTypeService: MedicinePresentationTypeService,
    public prescriptionNonDispensationService: PrescriptionNonDispensationService,
  ) { this.matDialogRef.disableClose = true; }

  public model: FormGroup;
  public isLoading: boolean;
  public idMedicalPrescription: number;

  ngOnInit(): void {
    this.isLoading = true;
    this.idMedicalPrescription = this.data.prescription.idMedicalPrescription;

    this.model = this.formBuilder.group({
      patientName: [{ value: this.data.patientName, disabled: true }],
      medicineName: [{ value: this.data.prescription.medicineName, disabled: true }, [Validators.required]],
      nonDispensationReason: ['', [Validators.required]],
    });

    this.isLoading = false;
  }

  submit() {
    if (this.isLoading || this.model.invalid)
      return;

    let nonDispensationRequest: PrescriptionNonDispensationRequest = new PrescriptionNonDispensationRequest();
    nonDispensationRequest.idMedicalPrescription = this.idMedicalPrescription;
    nonDispensationRequest.nonDispensationReason = this.model.get('nonDispensationReason').value;

    if (this.data.solution != undefined && this.data.solution)
      return this.matDialogRef.close({ notDispensed: true, nonDispensationRequest: nonDispensationRequest });


    this.prescriptionNonDispensationService.nonDispensation(nonDispensationRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.alertService.show('Sucesso', "Prescrição não dispensada!", AlertType.success);
        this.matDialogRef.close({ notDispensed: true });
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  clickCancel() {
    this.matDialogRef.close({ notDispensed: false });
  }
}