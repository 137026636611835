import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import {
  MatTree,
  MatTreeModule
} from '@angular/material/tree';
import { MatToolbarModule } from '@angular/material/toolbar';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { FormRoutingModule } from './form-routing.module';
import { FormComponent } from './pages/form/form.component';
import { FormEpisodeListComponent } from './pages/form-episode-list/form-episode-list.component';
import { FormEpisodeListDeleteModalComponent } from './pages/form-episode-list/form-episode-list-delete-modal/form-episode-list-delete-modal.component';
import { FormSelectPatientModalComponent } from './pages/form-register/form-select-patient-modal/form-select-patient-modal.component';
import { FormRegisterComponent } from './pages/form-register/form-register.component';
import { EquipmentListComponent } from './pages/equipment-list/equipment-list.component';
import { EquipmentDeleteModalComponent } from './pages/equipment-list/equipment-delete-modal/equipment-delete-modal.component';
import { EquipmentRegisterComponent } from './pages/equipment-register/equipment-register.component';
import { FormMedicalScheduleListComponent } from './pages/form-medical-schedule-list/form-medical-schedule-list.component';
import { FormMedicalScheduleListDeleteModalComponent } from './pages/form-medical-schedule-list/form-medical-schedule-list-delete-modal/form-medical-schedule-list-delete-modal.component';
import { DateAdapter } from '@angular/material/core';
import { ptBR } from '../shared/data-adapters/pt-BR';
import { FormMedicalScheduleRegisterComponent } from './pages/form-medical-schedule-register/form-medical-schedule-register.component';
import { SearchPatientModalComponent } from './pages/form-register/search-patient-modal/search-patient-modal.component';
import { MatStepperModule } from '@angular/material/stepper'
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckbox, MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioButton, MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { NgxMaskModule } from 'ngx-mask';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';


@NgModule({
  declarations: [FormComponent,
    FormEpisodeListComponent,
    FormEpisodeListDeleteModalComponent,
    FormRegisterComponent,
    FormSelectPatientModalComponent,
    EquipmentListComponent,
    EquipmentDeleteModalComponent,
    EquipmentRegisterComponent,
    FormMedicalScheduleListComponent,
    FormMedicalScheduleListDeleteModalComponent,
    FormMedicalScheduleRegisterComponent,
    SearchPatientModalComponent
  ],
  imports: [
    CommonModule,
    FormRoutingModule,
    CommonModule,
    SharedModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTreeModule,
    MatRadioModule,
    MatTooltipModule,
    MatToolbarModule,
    NgxMatFileInputModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    MatSlideToggleModule,
    CdkAccordionModule,
    MatDatepickerModule,
    MatPaginatorModule,
    MatStepperModule,
    MatDialogModule
  ],
  providers: [
    AuthGuard,
    MatCheckbox,
    MatCheckboxChange,
    MatTree,
    MatRadioButton,
    {
      provide: DateAdapter,
      useClass: ptBR
    }
  ]
})
export class FormModule { }
