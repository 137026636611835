import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { PostPutFrontDeskRequest } from '../../../shared/services/requests/front-desk/post-put-front-desk.request';
import { SectorStruct } from 'src/app/shared/services/structs/flow/sector.struct';
import { FrontDeskService } from 'src/app/shared/services/API/front-desk/front-desk.service';
import { FieldService } from 'src/app/shared/services/API/front-desk/field.service';
import { ReceptionService } from 'src/app/shared/services/API/flow/reception.service';
import { FieldModel } from 'src/app/shared/services/models/front-desk/field.model';
import { WristbandDimensions } from 'src/app/shared/services/models/front-desk/wristband-dimensions.model';
import { WristbandDimensionsService } from 'src/app/shared/services/API/front-desk/wristband-dimensions.service';
import { WristbandReportRequest } from 'src/app/shared/services/requests/Report/wristband-report.request';
import { WristbandReportService } from 'src/app/shared/services/API/report/wristband-report.service';
import { ReportModalComponent } from 'src/app/shared/components/report-modal/report-modal.component';
import { ClassificationPrintingToggleService } from 'src/app/shared/services/API/risk-classification/classification-printing-toggle.service';
import { IdentificationService } from 'src/app/shared/services/API/front-desk/identification.service';
import { IdentificationModel } from 'src/app/shared/services/models/front-desk/identification.model';
import { FrontDeskIdentificationEnum } from 'src/app/shared/enum/front-desk-identification.enum';
import { LookupService } from 'src/app/shared/services/API/meta-data/lookup.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MetaDataService } from 'src/app/shared/services/API/meta-data/meta-data.service';
import { MetaDataStruct } from 'src/app/shared/services/structs/meta-data/meta-data.struct';
import { MetaDataModel } from 'src/app/shared/services/models/meta-data/meta-data.model';
import { MetaDataTypeModel } from 'src/app/shared/services/models/meta-data/meta-data-type.model';

@Component({
  selector: 'app-front-desk-register',
  templateUrl: './front-desk-register.component.html',
  styleUrls: ['./front-desk-register.component.css']
})
export class FrontDeskRegisterComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private frontDeskService: FrontDeskService,
    private fieldService: FieldService,
    private metaDataService: MetaDataService,
    private receptionService: ReceptionService,
    private wristbandDimensionsService: WristbandDimensionsService,
    public dialog: MatDialog,
    public wristbandReportService: WristbandReportService,
    public classificationPrintingToggleService: ClassificationPrintingToggleService,
    private identificationService: IdentificationService,
    private lookupService: LookupService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.frontdesk;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.master_front_desk_config;

  public model: FormGroup;
  public isLoading: boolean = false;
  public isFirstLoading: boolean = false;
  public isUpdate: boolean = false;
  public listSector: SectorStruct[];
  public listIdentification: IdentificationModel[];
  public idIdentification: number;
  public idFieldClassification: number;
  public listWristbandDimensions: WristbandDimensions[];
  public listMetaData: MetaDataStruct[];
  public listMetaDataRequiredSelected: MetaDataStruct[] = [];
  public listIdMetaDataRequiredSelected: number[] = [];
  public listField: FieldModel[];
  public listIdRequiredField: number[];
  public masks: Masks;
  public idFrontDesk: number = null;
  public frontDeskRequest: PostPutFrontDeskRequest = new PostPutFrontDeskRequest();
  public isWristbandActive: boolean;
  public isOtherDimensionsSelected: boolean;
  public isVisualizeLoading: boolean = false;
  public identificationFrontDesk: IdentificationModel = new IdentificationModel();
  public identificationClassification: IdentificationModel = new IdentificationModel();
  public hasGravityTag: boolean;

  ngOnInit(): void {
    this.getAllMetaData();
    this.populateIdentificationSelect();
    this.populateSectorMultiSelect();
    this.populateFieldMultiSelect();
    this.populateDimensionsSelect();

    this.masks = this.maskService.getMasks();

    this.model = this.formBuilder.group({
      frontDeskName: ['', [Validators.required]],
      description: [''],
      listSector: [],
      listIdRequiredField: [],
      idWristbandDimensions: [null],
      customWristbandWidth: [null],
      customWristbandHeight: [null],
      identification: [FrontDeskIdentificationEnum.Episodio, [Validators.required]],
      listMetadata: [],
      listMetaDataRequiredSelected: []
    });
  }

  submit() {
    if (this.isLoading)
      return;

    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    this.isLoading = true;
    this.frontDeskRequest.listIdSector = [];
    this.frontDeskRequest.frontDeskName = this.model.get('frontDeskName').value;
    this.frontDeskRequest.frontDeskDescription = this.model.get('description').value;
    this.frontDeskRequest.idWristbandDimensions = this.model.get('idWristbandDimensions').value;
    this.frontDeskRequest.customWristbandWidth = this.model.get('customWristbandWidth').value;
    this.frontDeskRequest.customWristbandHeight = this.model.get('customWristbandHeight').value;
    this.frontDeskRequest.idIdentification = this.model.get('identification').value;
    this.frontDeskRequest.listMetaData = this.listMetaData;

    if (this.model.get('listMetadata').value)
      this.frontDeskRequest.listIdMetaData = this.model.get('listMetadata').value;

    if (this.model.get('listMetaDataRequiredSelected').value)
      this.frontDeskRequest.listIdRequiredMetaData = this.model.get('listMetaDataRequiredSelected').value

    if (this.model.get('listSector').value)
      this.frontDeskRequest.listIdSector = this.model.get('listSector').value.map(c => parseInt(c));

    if (this.model.get('listIdRequiredField').value)
      this.frontDeskRequest.listIdFieldRequired = this.model.get('listIdRequiredField').value.map(c => parseInt(c));

    if (this.isUpdate)
      this.updateFrontDesk();
    else
      this.createFrontDesk();
  }

  populateFrontDeskData(listMetaData: MetaDataStruct[]) {
    this.frontDeskService.getFrontDesk(listMetaData, this.idFrontDesk).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (response.frontDesk.idWristbandDimensions)
          this.toggleWristband({ checked: true })

        if (response.frontDesk.idWristbandDimensions == 1)
          this.changeDimensionsSelect({ value: 1 })

        this.identificationFrontDesk = this.listIdentification.find(f => f.idIdentification == response.frontDesk.idIdentification);

        this.model.get('description').setValue(response.frontDesk.description);
        this.model.get('frontDeskName').setValue(response.frontDesk.frontDeskName);
        this.model.get('idWristbandDimensions').setValue(response.frontDesk.idWristbandDimensions);
        this.model.get('customWristbandWidth').setValue(response.frontDesk.customWristbandWidth);
        this.model.get('customWristbandHeight').setValue(response.frontDesk.customWristbandHeight);
        this.model.get('listSector').setValue(response.listFrontDeskSectors.map(c => c.idSector));
        this.model.get('listIdRequiredField').setValue(response.listIdRequiredFields.map(c => c));
        this.model.get('identification').setValue(response.frontDesk.idIdentification);
        this.model.get('listMetaDataRequiredSelected').setValue(response.listIdMetaDataRequired);
        this.model.get('listMetadata').setValue(response.listIdMetaData);

        this.listMetaDataRequiredSelected = response.listMetaData.map((metaData: MetaDataModel) => {
          const metaDataStruct = new MetaDataStruct();
          metaDataStruct.metaData = metaData;
          return metaDataStruct;
        });

        this.isLoading = false;
        this.isFirstLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  changeRequired(event: any) {
    if (event == this.idFieldClassification)
      this.alertService.show('Aviso:', 'Esse campo está como Identificação Padrão na Classificação', AlertType.warning);
  }

  updateFrontDesk() {
    this.frontDeskService.updateFrontDesk(this.idFrontDesk, this.frontDeskRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;
        this.router.navigate(['/front-desk/front-desk-config']);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  createFrontDesk() {
    this.frontDeskService.createFrontDesk(this.frontDeskRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;

        this.router.navigate(['/front-desk/front-desk-config']);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateSectorMultiSelect() {
    this.receptionService.LocationReception().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listSector = response.sectors;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateFieldMultiSelect() {
    this.fieldService.listField().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listField = response.listFields;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateIdentificationSelect() {
    this.identificationService.listIdentification().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listIdentification = response.listIdentification;
        this.identificationFrontDesk = response.listIdentification.find(f => f.idIdentification == FrontDeskIdentificationEnum.Episodio);

        this.getWristbandConfig();
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateDimensionsSelect() {
    this.wristbandDimensionsService.listWristbandDimensions().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listWristbandDimensions = response.listWristbandDimensions;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  toggleWristband(event: any) {
    if (event?.checked ?? false) {
      this.isWristbandActive = true;
      this.model.get('idWristbandDimensions').setValue(null);
      this.model.get('idWristbandDimensions').setValidators([Validators.required]);
    }
    else {
      this.isWristbandActive = false;
      this.model.get('idWristbandDimensions').setValue(null);
      this.model.get('idWristbandDimensions').setValidators([]);
    }

    this.model.get('idWristbandDimensions').updateValueAndValidity();
    this.changeDimensionsSelect(null);
  }

  toggleGravity() {
    this.hasGravityTag = !this.hasGravityTag
  }

  changeDimensionsSelect(event: any) {
    if (event?.value == 1 ?? false) {
      this.isOtherDimensionsSelected = true;
      this.model.get('customWristbandWidth').setValue(null);
      this.model.get('customWristbandHeight').setValue(null);
      this.model.get('customWristbandWidth').setValidators([Validators.required, Validators.min(70)]);
      this.model.get('customWristbandHeight').setValidators([Validators.required, Validators.min(20)]);
    }
    else {
      this.isOtherDimensionsSelected = false;
      this.model.get('customWristbandWidth').setValue(null);
      this.model.get('customWristbandHeight').setValue(null);
      this.model.get('customWristbandWidth').setValidators([]);
      this.model.get('customWristbandHeight').setValidators([]);
    }

    this.model.get('customWristbandWidth').updateValueAndValidity();
    this.model.get('customWristbandHeight').updateValueAndValidity();
  }

  changeIdentificationSelect(event: any) {
    this.model.get('identification').setValue(event.value);
    this.identificationFrontDesk = this.listIdentification.find(f => f.idIdentification == event.value);

    let listId: number[] = (this.model.get('listIdRequiredField').value) ? this.model.get('listIdRequiredField').value : [];
    let hasId = (listId.length > 0) ? listId.find(f => f == this.identificationFrontDesk.idField) : undefined;
    let update = (this.identificationFrontDesk && this.identificationFrontDesk.idField && !hasId);
    if (update) {
      listId.push(this.identificationFrontDesk.idField);
      this.model.get('listIdRequiredField').patchValue(listId);
    }
  }

  verifyRequiredFields(event) {
    if (event.value == this.identificationFrontDesk.idField)
      this.alertService.show('Aviso:', 'este campo é a Identificação Padrão da recepção', AlertType.warning);
  }

  visualizeTemplate() {
    this.isVisualizeLoading = true;

    if (!this.model.get('customWristbandHeight').value || !this.model.get('customWristbandWidth').value) {
      this.alertService.show('Erro', "Preencha a altura e largura para visualizar a pulseira.", AlertType.error);
      this.isVisualizeLoading = false;
      return;
    }

    let wristbandReportRequest: WristbandReportRequest = new WristbandReportRequest();
    wristbandReportRequest.height = this.model.get('customWristbandHeight').value;
    wristbandReportRequest.width = this.model.get('customWristbandWidth').value;
    wristbandReportRequest.idEpisode = 9999999;
    wristbandReportRequest.patientName = "Nome paciente";
    wristbandReportRequest.motherName = "Nome mãe";
    wristbandReportRequest.patientBirthDate = new Date(2000, 1, 10, 17, 30, 0);

    if (this.hasGravityTag) {
      wristbandReportRequest.idGravity = 1;
      wristbandReportRequest.gravityColor = "#ff0000";
      wristbandReportRequest.gravityName = "Emergência";
    }

    let idIdentification = this.model.get('identification').value;
    wristbandReportRequest.idIdentification = idIdentification;

    if (idIdentification == FrontDeskIdentificationEnum.CNS)
      wristbandReportRequest.identification = "número CNS";
    else if (idIdentification == FrontDeskIdentificationEnum.PlanoDeSaude)
      wristbandReportRequest.identification = "operadora";
    else if (idIdentification == FrontDeskIdentificationEnum.Episodio)
      wristbandReportRequest.identification = "número do episódio";

    this.wristbandReportService.getReport(wristbandReportRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isVisualizeLoading = false;
          return;
        }

        this.isVisualizeLoading = false;
        let reportDialog = this.dialog.open(ReportModalComponent, {
          data: {
            reportName: response.reportName,
            reportPdf64: response.reportPdf64,
          }
        });
      },
      error: (error) => {
        console.log(error);
        this.isVisualizeLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getWristbandConfig() {
    this.classificationPrintingToggleService.getWristbandConfig().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        if (response.idIdentification != null)
          this.identificationClassification = this.listIdentification.find(f => f.idIdentification == response.idIdentification);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getIdentification() {
    this.identificationService.listIdentification().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listIdentification = response.listIdentification;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getAllMetaData() {
    this.metaDataService.getAllMetaData().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listMetaData = response.listMetaDataStruct;

        if (this.activatedRoute.snapshot.paramMap.get('idFrontDesk'))
          this.idFrontDesk = parseInt(this.activatedRoute.snapshot.paramMap.get('idFrontDesk'));

        if (this.idFrontDesk != null) {
          this.isFirstLoading = true;
          this.isUpdate = true;
          this.populateFrontDeskData(this.listMetaData);
        }
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  selectMetaData(event: any) {
    let listIdMetaDataSelected = this.listMetaDataRequiredSelected.filter(x => x.metaData.idMetaData == event);
    if (listIdMetaDataSelected.length == 0) {
      let idMetaDataSelected = this.listMetaData.filter(x => x.metaData.idMetaData == event);
      idMetaDataSelected.forEach(x => {
        this.listMetaDataRequiredSelected.push(x)
      });
    }
    else
      this.listMetaDataRequiredSelected = this.listMetaDataRequiredSelected.filter(x => x.metaData.idMetaData != event);

    if (this.model.get('listMetaDataRequiredSelected'))
      this.model.get('listMetaDataRequiredSelected').setValue(this.model.get('listMetaDataRequiredSelected').value.filter(x => x != event));
  }

  selectRequiredMetaData(event: any) {
    let listIdMetaData = this.listIdMetaDataRequiredSelected.filter(x => x == event);
    if (listIdMetaData.length == 0) {
      let idMetaData = this.listMetaDataRequiredSelected.filter(x => x.metaData.idMetaData == event)
      idMetaData.forEach(x => {
        this.listIdMetaDataRequiredSelected.push(x.metaData.idMetaData)
      });
    }
    else
      this.listIdMetaDataRequiredSelected = this.listIdMetaDataRequiredSelected.filter(x => x != event)
  }
}