import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';


@Injectable({
  providedIn: 'root'
})
export class ProcedureHealthcareService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

    public PutStatus(idProcedureHealthcare: number, isActive: boolean): Observable<ReturnStruct> {
        let uri = `ProcedureHealthcare/idProcedureHealthcare/${idProcedureHealthcare}/isActive/${isActive}`

        return this.httpClient.put<ReturnStruct>(environment.urlApiPrivateBilling + uri, null, this.addHeaderToken())
        .pipe(catchError(this.handleError));
    }
}