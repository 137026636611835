import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MedicFavoriteStandardPrescriptionService } from 'src/app/shared/services/API/medic/medic-favorite-standard-prescription.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-medic-favorite-standard-delete-modal',
  templateUrl: './medic-favorite-standard-delete-modal.component.html',
  styleUrls: ['./medic-favorite-standard-delete-modal.component.css']
})
export class MedicFavoriteStandardDeleteModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<MedicFavoriteStandardDeleteModalComponent>,
    private medicFavoriteStandardPrescriptionService: MedicFavoriteStandardPrescriptionService,
    private alertService: AlertService,
  ) { }

  public isLoading: boolean;

  ngOnInit(): void {
    this.isLoading = false;
  }

  close() {
    this.matDialogRef.close({ isDeleted: false });
  }

  clickDelete() {
    this.isLoading = true;

    this.medicFavoriteStandardPrescriptionService.deleteMedicFavoriteStandardPrescription(this.data.idMedicFavoriteStandardPrescription).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Favorito excluído com sucesso!", AlertType.success);
        this.matDialogRef.close({ isDeleted: true });
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  clickCancel() {
    this.matDialogRef.close({ isDeleted: false });
  }
}