import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ServiceRequest } from '../../requests/flow/service.request';
import { GetServiceResponse } from '../../responses/flow/get-service.response';
import { ListServiceResponse } from '../../responses/flow/list-service-response';

@Injectable({
  providedIn: 'root'
})
export class ServiceGetService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getServiceData(idService: number, listId: Number[]): Observable<GetServiceResponse>{

    let uri = `ServiceGet/idService/${idService}`

    return this.httpClient.post<GetServiceResponse>(environment.urlApiFlow + uri, listId, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}