<div [formGroup]="formGroup">
    <div class="row" [formArrayName]="formArrayName"
        *ngFor="let item of formGroup.get(formArrayName)['controls']; let i = index;">
        <div [formGroupName]="i" class="col-12 col-sm-6 col-md-3">
            <mat-form-field appearance="outline">
                <mat-label>Tipo de Item</mat-label>
                <mat-select formControlName="typeItem" (selectionChange)="selectTypeItem($event.value, i)">
                    <mat-option [value]="this.typeItemKitEnum.Material">Material</mat-option>
                    <mat-option [value]="this.typeItemKitEnum.Medicine">Medicamento</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="formGroup.get(formArrayName)['controls'][i].get('typeItem').value == this.typeItemKitEnum.Material 
            || formGroup.get(formArrayName)['controls'][i].get('typeItem').value == null" class="col-6 col-sm-5 col-lg-5">
            <div [formGroupName]="i">
                <mat-form-field appearance="outline">
                    <mat-label>Item</mat-label>
                    <mat-select formControlName="idItem" (selectionChange)="changeItem($event, i)">
                        <mat-option *ngFor="let item of listItem" [value]="item.idItem">
                            {{item.itemName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div *ngIf="formGroup.get(formArrayName)['controls'][i].get('typeItem').value == this.typeItemKitEnum.Medicine" 
            class="col-6 col-sm-5 col-lg-5">
            <div [formGroupName]="i">
                <mat-form-field appearance="outline">
                    <mat-label>Medicamento</mat-label>
                    <mat-select formControlName="idItem" (selectionChange)="changeItem($event, i)">
                        <mat-option *ngFor="let item of listMedicines" [value]="item.idMedicine">
                            {{item.medicineDescription}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="col-6 col-sm-3 col-lg-4">
            <div [formGroupName]="i">
                <mat-form-field appearance="outline">
                    <mat-label>Estoque</mat-label>
                    <mat-select formControlName="idStorage" (selectionChange)="changeStorage($event, i)">
                        <mat-option *ngFor="let item of arrayLocationItem[i].listStorage" [value]="item.idStorage">
                            {{item.idStorage}} - {{item.batch}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="col-6 col-sm-3 col-lg-3">
            <div [formGroupName]="i">
                <mat-form-field appearance="outline">
                    <mat-label>Localização</mat-label>
                    <mat-select formControlName="idLocation" (selectionChange)="changeLocation($event, i)">
                        <mat-option *ngFor="let item of arrayLocationItem[i].listLocations" [value]="item.idLocation">
                            {{item.location}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="col-6 col-sm-3 col-lg-2">
            <div [formGroupName]="i">
                <mat-form-field appearance="outline">
                    <mat-label>Quantidade</mat-label>
                    <input matInput type="number" formControlName="value">
                    <span matSuffix>{{arrayLocationItem[i].location ? arrayLocationItem[i].location.amountRemaining:""}}</span>
                </mat-form-field>
            </div>
        </div>
        <div class="col-12 col-sm-3 col-lg-2 action-buttons">
            <button class="panel-origin-button " color="primary" mat-mini-fab type="button" (click)="addNext()">
                <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
            </button>
            <button class="panel-origin-button " color="primary" mat-mini-fab type="button" (click)="removeLine(i)">
                <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
            </button>
        </div>
    </div>
</div>

<button *ngIf="formGroup.get(formArrayName)['controls'].length == 0" color="accent" class="" mat-flat-button
    type="button" (click)="addNext()">
    <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
    Adicionar Item
</button>