import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GetCompleteMultiProfessionalConfigStruct } from 'src/app/shared/services/structs/multi-professional/get-complete-multi-professional-config.struct';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { MetaDataStruct } from 'src/app/shared/services/structs/meta-data/meta-data.struct';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MetaDataTypeEnum } from 'src/app/shared/enum/meta-data/meta-data-type.enum';
import { MetaDataService } from 'src/app/shared/services/API/meta-data/meta-data.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-meta-data-block',
  templateUrl: './meta-data-block.component.html',
  styleUrls: ['./meta-data-block.component.css']
})
export class MetaDataBlockComponent implements OnInit {

  @Input() model: FormGroup;
  @Input() completeMultiprofessionalConfig: GetCompleteMultiProfessionalConfigStruct;

  constructor(
    private formBuilder: FormBuilder,
    public router: Router,
    public maskService: MaskService,
    private metaDataService: MetaDataService,
    private alertService: AlertService,
  ) { }

  public listMetaData: MetaDataStruct[] = [];
  public masks: Masks;
  public isLoading: boolean;
  public listMetaDataStruct: MetaDataStruct[] = [];

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();

    this.getListMetaData();
  }

  getListMetaData() {
    this.metaDataService.getAllMetaData().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listMetaDataStruct = response.listMetaDataStruct;

        this.initMetaDataField(this.listMetaDataStruct)
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  initMetaDataField(listMetaDataStruct: MetaDataStruct[]) {
    this.listMetaData = listMetaDataStruct.filter((item) => this.completeMultiprofessionalConfig.listMetaData.some(item2 => item.metaData.idMetaData == item2.idMetaData));

    if (this.listMetaData && this.listMetaData.length > 0) {
      for (let item of this.listMetaData) {
        if (item.metaData.isRequired)
          this.model.addControl(item.metaData.idMetaData.toString(), this.formBuilder.control('', [Validators.required]));
        else
          this.model.addControl(item.metaData.idMetaData.toString(), this.formBuilder.control(''));
      }
    }

    if (this.completeMultiprofessionalConfig.listIdMetaDataRequired != undefined && this.completeMultiprofessionalConfig.listIdMetaDataRequired.length > 0) {
      for (let item of this.completeMultiprofessionalConfig.listIdMetaDataRequired) {
        this.model.controls[item].setValidators([Validators.required]);
        this.model.controls[item].updateValueAndValidity();
      }
    }
  }

  public get metaDataTypeEnumResult(): typeof MetaDataTypeEnum {
    return MetaDataTypeEnum;
  }
}