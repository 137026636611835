import { SigaPatientStruct } from "../../structs/siga-front-desk/siga-patient.struct";

export class PatientSigaRequest {
    public sigaPatientStruct: SigaPatientStruct = new SigaPatientStruct();
    public datetimeStartRegister: Date;
    public idFlow: number;
    public idQueue: number;
    public idRoom: number;
    public idFlowCompleted: number;
    public newPatient: boolean;
    public isUpdateFrontdesk: boolean;
    public idSignatureProvider: number;     
    public signatureAuthentication: string; 
}