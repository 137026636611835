<div class="row display-contents">
    <div class="col-12 col-sm-12 col-md-12 padding-box">
        <div class="row border-box">
            <div class="title-header">
                <h3>VALORES POR CONVÊNIO </h3>
            </div>             
            <div class="filters">
                <div class="row">
                    <div class="col-12 col-md-4 search-correction">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Pesquisar</mat-label>
                            <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText"
                                (keyup)="onKeySearch($event)">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-2">
                        <button class="custom-button" [class.active]="isButtonActive('ativos')"
                            (click)="controlButtonStatus('ativos')">Ativos</button>
                    </div>
                    <div class="col-12 col-md-2">
                        <button class="custom-button" [class.active]="isButtonActive('inativos')"
                            (click)="controlButtonStatus('inativos')">Inativos</button>
                    </div>
                </div>
            </div>
            <div [ngClass]="{'table-height': listPriceProcedureHealthcareStruct?.length > 0}">
                <app-loading-progress-bar *ngIf="isLoading"></app-loading-progress-bar>
                <div class="row">
                    <table class="col-12 table">
                        <thead>
                            <tr>
                                <th class="column-procedure">Convênio</th>
                                <th class="column-active">Ativo</th>
                                <th class="column-value">Valor (R$)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let priceProcedureHealthcare of listPriceProcedureHealthcareStruct">
                                <td>{{ priceProcedureHealthcare.nameHealthcareAgreement }}</td>
                                <td>
                                    <mat-slide-toggle [(ngModel)]="priceProcedureHealthcare.isActive"
                                        (change)="putStatus($event, priceProcedureHealthcare.idProcedureHealthcare)"
                                        [labelPosition]="'before'">
                                    </mat-slide-toggle>
                                </td>
                                <td class="value-customize">
                                    <mat-form-field appearance="outline" floatLabel="always"
                                        class="display-none control-space-field">
                                        <input matInput type="text" [(ngModel)]="priceProcedureHealthcare.valueFront"
                                        class="example-right-align" placeholder="0,00"
                                        (input)="formatCurrency($event, priceProcedureHealthcare)" [ngModelOptions]="{standalone: true}">
                                    <span matTextPrefix>R$&nbsp;</span>
                                    </mat-form-field>
                                    <div class="ball-control">
                                        <div class="ball" matTooltip="Histórico de alteração"
                                            (click)="openHistoryCardModal(priceProcedureHealthcare.idProcedureHealthcare)">
                                            i
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <app-empty-list
                *ngIf="!listPriceProcedureHealthcareStruct || listPriceProcedureHealthcareStruct.length == 0 && !this.isLoading">
            </app-empty-list>
        </div>
    </div>
    <div class="row" style="padding-left: 20px;">
        <div class="col-3">
            <button mat-flat-button (click)="backToProcedureList()" color="accent" class="btn-block">
                <span *ngIf="isLoading == false">Voltar aos procedimentos</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
        </div>
        <div class="col-3">
            <button mat-flat-button (click)="submit()" color="primary" class="btn-block">
                <span *ngIf="isLoading == false">Salvar</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
        </div>
    </div>
</div>