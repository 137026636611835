import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AihConfigService } from 'src/app/shared/services/API/hospital-document/aih-config.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-aih-config-delete-modal',
  templateUrl: './aih-config-delete-modal.component.html',
  styleUrls: ['./aih-config-delete-modal.component.css']
})
export class AihConfigDeleteModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<AihConfigDeleteModalComponent>,
    private aihConfigService: AihConfigService,
    private alertService: AlertService,
  ) { }

  public isLoading: boolean;

  ngOnInit(): void {
    this.isLoading = false;
  }

  close() {
    this.matDialogRef.close({ deleteMedicConfig: false });
  }

  clickDelete() {
    this.isLoading = true;

    this.aihConfigService.deleteAihConfig(this.data.idAihConfig).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.alertService.show('Sucesso', "Configuração excluida com sucesso!", AlertType.success);
        this.matDialogRef.close({ deleteAihConfig: true });
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  clickCancel() {
    this.matDialogRef.close({ deleteAihConfig: false });
  }

  throwException(error: string) {
    console.log(error);
    this.isLoading = false;
    this.alertService.show('Erro inesperado', error, AlertType.error);
  }
}