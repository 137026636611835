import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { ChannelTypeEnum } from 'src/app/shared/enum/billing-by-health-unit/channel-type.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { BillingAlertService } from 'src/app/shared/services/API/billing-by-health-unit/billing-alert.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { AlertVariable } from 'src/app/shared/services/models/billing-by-health-unit/alert-variable.model';
import { ChannelType } from 'src/app/shared/services/models/billing-by-health-unit/channel-type.model';
import { TypeService } from 'src/app/shared/services/models/billing-by-health-unit/type-service.model';
import { BillingAlertRequest } from 'src/app/shared/services/requests/billing-by-health-unit/billing-alert.request';
import { BillingAlertStruct } from 'src/app/shared/services/structs/billing-by-health-unit/billing-alert.struct';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-billing-alert-register',
  templateUrl: './billing-alert-register.component.html',
  styleUrls: ['./billing-alert-register.component.css']
})
export class BillingAlertRegisterComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private maskService: MaskService,
    private billingAlertService: BillingAlertService,
    private utilService: UtilService,
  ) { }

  public isLoading: boolean;
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.master;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.master_billing_alert;
  public isFirstLoading: boolean;
  public model: FormGroup;
  public idEmail: ChannelTypeEnum = ChannelTypeEnum.Email;
  public idTelefone: ChannelTypeEnum = ChannelTypeEnum.Telefone;
  public masks: Masks;
  public billingAlertRequest = new BillingAlertRequest();
  public arrayVariables: any[] = [];
  public listTypeService: TypeService[];
  public listAlertVariable: AlertVariable[];
  public listChannelType: ChannelType[];
  public isUpdate: boolean;

  ip: string = '192.168.0.2';

  ngOnInit(): void {
    this.isLoading = false;
    this.isUpdate == false;
    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      email: [, [Validators.email]],
      billingTelephone: [,],
      expressions: this.formBuilder.array([])
    });
    this.billingAlertRequest = new BillingAlertRequest();

    this.getBillingAlertLookup();

    setTimeout(() => { this.getIP(); }, 2000);
  }

  getIP() {
    this.utilService.getIP().subscribe({
      next: (response) => {
        if (response && response.ip) {
          this.ip = response.ip;
        }
      }
    });
  }

  submit() {
    this.billingAlertRequest.listBillingAlertRule = [];

    if (this.model.get('email') && this.model.get('email').value != null)
      this.billingAlertRequest.billingEmail = this.model.get('email').value;

    if (this.model.get('billingTelephone') && this.model.get('billingTelephone').value != null)
      this.billingAlertRequest.billingTelephone = this.model.get('billingTelephone').value;

    for (let i = 0; i < this.model.get('expressions').value.length; i++) {
      if (this.model.get('expressions').value[i].idAlertVariable == "" || this.model.get('expressions').value[i].idTypeService == "" || this.model.get('expressions').value[i].value == ""
        || this.model.get('expressions').value[i].idChannelType == "") {
        this.alertService.show('Erro', "É necessário preencher todos os campos.", AlertType.error)
        this.isLoading = false;
        return;
      }
    }

    this.model.get('expressions').value.forEach((x) => {
      var expression = new BillingAlertStruct();
      expression.idAlertVariable = x.idAlertVariable;
      expression.idTypeService = x.idTypeService;
      expression.idChannelType = x.idChannelType;
      expression.value = x.value;
      expression.idBillingAlertRule = x.idBillingAlertRule;
      this.billingAlertRequest.listBillingAlertRule.push(expression);
    });

    if (this.billingAlertRequest.listBillingAlertRule.find(x => x.idChannelType.find(x => x == this.idEmail))) {
      if (this.model.get('email').value == null || this.model.get('email').value == '') {
        this.model.get('email').setValidators([Validators.required]);
        this.model.get('email').updateValueAndValidity();
        this.alertService.show('Erro', "É necessário preencher um E-mail.", AlertType.error)
        this.isLoading = false;
        return;
      }
    }
    else {
      this.model.get('email').updateValueAndValidity();
      this.model.get('email').setValidators([]);
    }

    if (this.billingAlertRequest.listBillingAlertRule.find(x => x.idChannelType.find(x => x == this.idTelefone))) {
      if (this.model.get('billingTelephone').value == null || this.model.get('billingTelephone').value == '') {
        this.model.get('billingTelephone').setValidators([Validators.required]);
        this.model.get('billingTelephone').updateValueAndValidity();
        this.alertService.show('Erro', "É necessário preencher um Telefone.", AlertType.error)
        this.isLoading = false;
        return;
      }
    }
    else {
      this.model.get('billingTelephone').setValidators([]);
      this.model.get('billingTelephone').updateValueAndValidity();
      this.model.get('billingTelephone').setValue(null);
    }

    if (this.model.get('billingTelephone').value == null && this.model.get('email').value == null) {
      this.alertService.show('Erro', "É necessário inserir um E-mail ou um Telefone.", AlertType.error)
      this.isLoading = false;
      return;
    }

    if (this.isLoading)
      return;

    else if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      this.isLoading = false;
      return;
    }
    this.isLoading = true;

    this.billingAlertRequest.ip = this.ip;

    if (this.isUpdate)
      this.putBillingAlertData(this.billingAlertRequest);
    else
      this.postBillingAlertData(this.billingAlertRequest);
  }

  getBillingAlertLookup() {
    this.billingAlertService.listBillingTypeService().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isFirstLoading = false;
          this.isLoading = false;
          return;
        }

        this.listTypeService = response.listTypeService;
        this.listAlertVariable = response.listAlertVariable;
        this.listChannelType = response.listChannelType;

        this.populateBillingAlertData();
      },
      error: (error) => {
        this.isLoading = false;
        this.isFirstLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateBillingAlertData() {
    this.billingAlertService.getBillingAlert().subscribe({
      next: (response) => {
        if (response.isError) {
          this.isFirstLoading = false;
          this.isLoading = false;
          return;
        }
        this.model.get('email').setValue(response.billingAlert.billingEmail);
        this.model.get('billingTelephone').setValue(response.billingAlert.billingTelephone);

        this.isUpdate = true;
        response.listBillingAlertRule.forEach((x) => {
          (this.model.controls['expressions'] as FormArray).push(
            this.formBuilder.group({
              idAlertVariable: [x.idAlertVariable ? x.idAlertVariable.toString() : null],
              idTypeService: [x.idTypeService.toString()],
              value: [x.value],
              idChannelType: [response.listBillingAlertChannel.filter(p => p.idBillingAlertRule == x.idBillingAlertRule).map(c => c.idChannelType.toString())],
              idBillingAlertRule: [x.idBillingAlertRule],
            }))
        });

        this.isLoading = false;
        this.isFirstLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        this.isFirstLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  postBillingAlertData(billingAlertRequest: BillingAlertRequest) {
    this.billingAlertService.postBillingAlert(billingAlertRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isFirstLoading = false;
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;

        this.ngOnInit();
      },
      error: (error) => {
        this.isLoading = false;
        this.isFirstLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  putBillingAlertData(billingAlertRequest: BillingAlertRequest) {
    this.billingAlertService.putBillingAlert(billingAlertRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isFirstLoading = false;
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;

        this.ngOnInit();
      },
      error: (error) => {
        this.isLoading = false;
        this.isFirstLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}