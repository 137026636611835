import { Component, OnInit } from '@angular/core';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ActivatedRoute } from '@angular/router';
import { PatientHistoryService } from 'src/app/shared/services/API/orchestrator-patient/patient-history.service';
import { CompleteMedicalCareStruct } from 'src/app/shared/services/structs/orchestrator-patient/complete-medical-care.struct';
import { ListMedicineResponse } from 'src/app/shared/services/responses/pharmacy/list-medicine.response';
import { ExamStruct } from 'src/app/shared/services/structs/SADT/exam.struct';
import { LookupMedicalCareResponse } from 'src/app/shared/services/responses/medical-record/lookup-medical-care-response';
import { LookupMedicalCareService } from 'src/app/shared/services/API/medical-record/lookup-medical-care.service';
import { ExamService } from 'src/app/shared/services/API/SADT/exam.service';
import { MedicineService } from 'src/app/shared/services/API/pharmacy/medicine.service';
import { PriorityModel } from 'src/app/shared/services/models/totem/priority.model';
import { PriorityService } from 'src/app/shared/services/API/totem/priority.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { PatientStruct } from 'src/app/shared/services/structs/medical-record/patient.struct';
import { NursingConsultationStruct } from 'src/app/shared/services/structs/medical-record/nursing-consultation.struct';
import { DatePipe } from '@angular/common';
import { TriageStruct } from 'src/app/shared/services/structs/orchestrator-patient/triage.struct';
import { BedStruct } from 'src/app/shared/services/structs/bed/bed.struct';
import { QueueHistoryService } from 'src/app/shared/services/API/orchestrator-queue/queue-history.service';
import { QueueStruct } from 'src/app/shared/services/structs/orchestrator-queue/queue.struct';
import { EpisodeStatusEnum } from 'src/app/shared/enum/episode-status.enum';
import { DownloadReportService } from 'src/app/shared/services/API/medical-record/download-report.service';
import { MatDialog } from '@angular/material/dialog';
import { ReportModalComponent } from 'src/app/shared/components/report-modal/report-modal.component';
import { ReturnPatientRequest } from 'src/app/shared/services/requests/orchestrator-queue/return-patient.request';
import { ReturnMotiveModalComponent } from './return-motive-modal/return-motive-modal.component';
import { ExamTypeService } from 'src/app/shared/services/API/SADT/exam-type.service';
import { ExamTypeModel } from 'src/app/shared/services/models/SADT/exam-type.model';
import { MedicalProcedureWithName } from 'src/app/shared/services/models/medical-record/medical-procedure.model';
import { MedicalProceduresService } from 'src/app/shared/services/API/orchestrator-patient/medical-procedures.service';
import { PatientHistoryResponse } from 'src/app/shared/services/responses/orchestrator-patient/patient-history.response';
import { MultiProfessionalCareCompleteStruct } from 'src/app/shared/services/structs/multi-professional/multi-professional-care-complete.struct';
import { NatureOfAttendanceEnum } from 'src/app/shared/enum/medical-record/nature-of-attendance.enum';
import { DocumentSendModalComponent } from './document-send-modal/document-send-modal.component';
import { UtilService } from 'src/app/shared/services/util.service';
import { StatusQueueEnum } from 'src/app/shared/enum/status-queue.enum';
import { MetaDataService } from 'src/app/shared/services/API/meta-data/meta-data.service';
import { MetaDataStruct } from 'src/app/shared/services/structs/meta-data/meta-data.struct';
import { MedicalCareStruct } from 'src/app/shared/services/structs/orchestrator-patient/medical-care.struct';
import { SadtGroupStruct } from 'src/app/shared/services/structs/medical-record/sadt-group.struct';
import { HospitalDocumentService } from 'src/app/shared/services/API/hospital-document/hospital-document.service';
import { AdmissionGuideStruct } from 'src/app/shared/services/structs/private-billing/admission-guide.struct';
import { SpSadtGuideStruct } from 'src/app/shared/services/structs/private-billing/sp-sadt-guide.struct';
import { AppointmentGuideStruct } from 'src/app/shared/services/structs/private-billing/appointment-guide.struct';
import { FeesGuideStruct } from 'src/app/shared/services/structs/private-billing/fees-guide.struct';
import { LookupResponse } from 'src/app/shared/services/responses/private-billing/lookup.response';
import { LookupService } from 'src/app/shared/services/API/private-billing/lookup.service';
import { TissGuideAppointmentHistoryComponent } from './tiss-guide-appointment-history/tiss-guide-appointment-history.component';
import { TissGuideFeesHistoryComponent } from './tiss-guide-fees-history/tiss-guide-fees-history.component';
import { TissGuideAdmissionHistoryComponent } from './tiss-guide-admission-history/tiss-guide-admission-history.component';
import { TissGuideSpSadtHistoryComponent } from './tiss-guide-sp-sadt-history/tiss-guide-sp-sadt-history.component';
import { MetaDataTypeEnum } from 'src/app/shared/enum/meta-data/meta-data-type.enum';
import { RegisteredMetaDataModel } from 'src/app/shared/services/models/medical-record/registered-meta-data.model';
import { VerifyDate } from 'src/app/shared/custom-validators/date-age.validator';
import { MetaDataKeyValueStruct } from 'src/app/shared/services/structs/meta-data/meta-data-key-value.struct';
import { DispensationStruct } from 'src/app/shared/services/structs/orchestrator-patient/dispensation.struct';
import { SolutionDispensationStruct } from 'src/app/shared/services/structs/orchestrator-patient/solution-dispensation.struct';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PatientAvoidanceLogResponse } from 'src/app/shared/services/responses/srv-log/patient-avoidance-log.response';

@Component({
  selector: 'app-patient-history-details',
  templateUrl: './patient-history-details.component.html',
  styleUrls: ['./patient-history-details.component.css']
})
export class PatientHistoryDetailsComponent implements OnInit {

  constructor(private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private patientHistoryService: PatientHistoryService,
    public lookupMedicalCareService: LookupMedicalCareService,
    public examService: ExamService,
    private utilService: UtilService,
    public medicineService: MedicineService,
    private formBuilder: FormBuilder,
    public medicalProceduresService: MedicalProceduresService,
    private priorityService: PriorityService,
    private maskService: MaskService,
    private queueService: QueueHistoryService,
    private downloadReportService: DownloadReportService,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private metaDataService: MetaDataService,
    private examTypeService: ExamTypeService,
    private hospitalDocumentService: HospitalDocumentService,
    private privateBillingLookupService: LookupService
  ) { }

  public model: FormGroup;
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.patient_history;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.patient_history_list;
  public isOnline: boolean = false;
  public isFirstLoading: boolean = true;
  public medicalCare: CompleteMedicalCareStruct[] = [];
  public multiProfessionalCare: MultiProfessionalCareCompleteStruct[] = [];
  public listMedicine: ListMedicineResponse = new ListMedicineResponse();
  public patientAvoidanceLogResponse: PatientAvoidanceLogResponse;
  public listLookupExam: ExamStruct[] = [];
  public lookupMedicalCareResponse: LookupMedicalCareResponse;
  public satdOthers: number = 0;
  public listOfPriorities: PriorityModel[];
  public isUnidentifiedPatient: boolean = false;
  public masks: Masks = this.maskService.getMasks();
  public listNursingConsultation: NursingConsultationStruct[] = [];
  public listSADT: SadtGroupStruct[] = [];
  public listTriage: TriageStruct[] = [];
  public listBedStruct: BedStruct[] = [];
  public listQueue: QueueStruct[];
  public listDispensationStruct: DispensationStruct[];
  public listSolutionDispensationStruct: SolutionDispensationStruct[];
  public evasionNotes: string;
  public evasionUserName: string;
  public idStatusQueueEvaded: StatusQueueEnum = StatusQueueEnum.evadido;
  public idStatusEvaded: EpisodeStatusEnum = EpisodeStatusEnum.FinalizadoEvasao;
  public idStatusEpisode: number;
  public idEpisode: number;
  public numberOfLinesInTextPatientExitLog: number;
  public isLoading: boolean = false;
  public admissionReportName: string;
  public showReturnPatientBtn: boolean = false;
  public listExamType: ExamTypeModel[];
  public listNurseProcedures: MedicalProcedureWithName[] = [];
  public listMetadataLookup: MetaDataStruct[] = [];
  public listMetaData: MetaDataStruct[] = [];
  public listRegisterMetadataFrontDeskTriage: RegisteredMetaDataModel[] = [];
  public listValueMetaDataFrontDeskTriage: MetaDataKeyValueStruct[] = [];

  downloadLink: string | undefined;

  public canReturnToQueue: boolean = true;
  public lastMedicalCare: MedicalCareStruct;
  public listAdmissionGuideStruct: AdmissionGuideStruct[] = [];
  public listSpSadtGuideStruct: SpSadtGuideStruct[] = [];
  public listAppointmentGuideStruct: AppointmentGuideStruct[] = [];
  public listFeesGuideStruct: FeesGuideStruct[] = [];
  public lookups: LookupResponse;
  public hasGuide: boolean = false;

  ngOnInit(): void {
    this.idEpisode = parseInt(this.activatedRoute.snapshot.paramMap.get('idEpisode'));
    this.canReturnToQueue = this.utilService.getReturnToQueueConfig();
    this.getListMetaData();
    this.populateValuesHistory(this.idEpisode);
    this.populateValuesQueueHistory(this.idEpisode);
    this.getLookupMedicine();
    this.getLookupMedicalCare();
    this.getLookupSadt();
    this.getAllPriorities();
    this.getLookupSadtType();
    this.getGuideLookup();
    this.model = this.formBuilder.group({
      listIdPriority: [''],
      unidentifiedPatientDescription: [''],
      idEpisode: [this.idEpisode],
      statusName: [''],
      patientName: [''],
      socialName: [''],
      birthDate: [''],
      patientAge: [{ value: '', disabled: true }],
      genderName: [''],
      cpf: [''],
      cns: [''],
      email: [''],
      raceName: [''],
      educationName: [''],
      rg: [''],
      maritalStatusName: [''],
      motherName: [''],
      fatherName: [''],
      profession: [''],
      birthCountry: [''],
      datetimeInclusion: [''],
      ticket: [''],
      allergies: [''],
      city: [''],
      street: [''],
      houseNumber: [''],
      apartmentNumber: [''],
      state: [''],
      country: [''],
      neighborhood: [''],
      zipCode: [''],
      responsibleUserName: [''],
      careLinePriorityName: [''],
      careLinePriorityStatusName: [''],
      careLinePriorityUserName: [''],
      careLinePriorityStatusUserName: [''],
      dateTimeCareLinePriorityStatus: [''],
      phone1: [''],
      phone2: [''],
      suspicionName: [''],
      suspicionUserName: [''],
      suspicionStatusName: [''],
      userSuspicionEvaluationName: [''],
      datetimeSuspicionEvaluation: [''],
      nameHealthcareAgreement: [''],
      planName: [''],
      healthcareAgreementExpirationDate: [''],
      healthcareAgreementCardNumber: [''],
    });
  }

  populateValuesQueueHistory(idEpisode: number) {
    this.queueService.listQueueHistory(idEpisode).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show("Erro", response.errorDescription, AlertType.error);
          return;
        }

        this.listQueue = response.listQueueStruct;
      }
    });
  }

  populateMedicalProcedure(idEpisode: number, responsePatientHistory: PatientHistoryResponse) {
    this.medicalProceduresService.getObservationMedicalProcedure(idEpisode).subscribe({
      next: (response) => {
        this.isLoading = false;
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.showReturnPatientBtn = responsePatientHistory.showReturnPatientBtn;
        this.medicalCare = responsePatientHistory.medicalCareHistorie;
        this.listSADT = responsePatientHistory.listMedicalCareSadt;
        this.multiProfessionalCare = responsePatientHistory.multiProfessionalCareHistorie;
        this.listDispensationStruct = responsePatientHistory.listDispensationStruct;
        this.listSolutionDispensationStruct = responsePatientHistory.listSolutionDispensationStruct;
        this.lastMedicalCare = this.medicalCare.length > 0 ? this.medicalCare[0].medicalCare : null;
        this.medicalCare.forEach(function (value) {
          value.listMedicalProcedure = response.listProcedures.filter(procedures => {
            return procedures.idMedicalCare == value.medicalCare.idMedicalCare;
          });
        });
        this.listNurseProcedures = response.listProcedures.filter(procedures => {
          return procedures.idMedicalCare == null;
        });
        this.listNursingConsultation = responsePatientHistory.listNursingConsultation;
        this.listBedStruct = responsePatientHistory.listBedStruct;
        this.listTriage = responsePatientHistory.triage;
        this.listAppointmentGuideStruct = responsePatientHistory.listAppointmentGuideStruct;
        this.listFeesGuideStruct = responsePatientHistory.listFeesGuideStruct;
        this.listAdmissionGuideStruct = responsePatientHistory.listAdmissionGuideStruct;
        this.listSpSadtGuideStruct = responsePatientHistory.listSpSadtGuideStruct;

        if (
          (this.listAppointmentGuideStruct != null && this.listAppointmentGuideStruct.length > 0) ||
          (this.listFeesGuideStruct != null && this.listFeesGuideStruct.length > 0) ||
          (this.listAdmissionGuideStruct != null && this.listAdmissionGuideStruct.length > 0) ||
          (this.listSpSadtGuideStruct != null && this.listSpSadtGuideStruct.length > 0)
        ) { this.hasGuide = true; }

        this.idStatusEpisode = responsePatientHistory.episode.idStatus;
        this.admissionReportName = responsePatientHistory.episode.admissionReportName;
        this.model.get('listIdPriority').setValue(responsePatientHistory.priority);
        this.model.get('ticket').setValue(responsePatientHistory.episode.ticketInitials + "" + responsePatientHistory.episode.ticketSequence);
        this.model.get('allergies').setValue(responsePatientHistory.allergies.join(', '));
        this.model.get('statusName').setValue(responsePatientHistory.episode.statusName);
        this.model.get('responsibleUserName').setValue(responsePatientHistory.episode.responsibleUserName);
        this.model.get('careLinePriorityName').setValue(responsePatientHistory.patientCareLinePriorityStruct.careLinePriorityName);
        this.model.get('careLinePriorityStatusName').setValue(responsePatientHistory.patientCareLinePriorityStruct.careLinePriorityStatusName);
        this.model.get('careLinePriorityStatusUserName').setValue(responsePatientHistory.patientCareLinePriorityStruct.careLinePriorityStatusUserName);
        this.model.get('careLinePriorityUserName').setValue(responsePatientHistory.patientCareLinePriorityStruct.careLinePriorityUserName);
        this.model.get('dateTimeCareLinePriorityStatus').setValue(this.datepipe.transform(responsePatientHistory.patientCareLinePriorityStruct.datetimeCareLinePriorityStatus, 'dd/MM/yyyy, H:mm'));

        if (responsePatientHistory.suspicionEvaluation) {
          this.model.get('suspicionName').setValue(responsePatientHistory.suspicionEvaluation.suspicionName)
          this.model.get('suspicionUserName').setValue(responsePatientHistory.suspicionEvaluation.userName)
          this.model.get('suspicionStatusName').setValue(responsePatientHistory.suspicionEvaluation.isSuspicionEvaluated ? "Confirmado" : (responsePatientHistory.suspicionEvaluation.isSuspicionEvaluated === false ? "Negado" : "Suspeita"))
          this.model.get('userSuspicionEvaluationName').setValue(responsePatientHistory.suspicionEvaluation.userSuspicionEvaluationName)
          this.model.get('datetimeSuspicionEvaluation').setValue(this.datepipe.transform(!responsePatientHistory.suspicionEvaluation.datetimeSuspicionEvaluation ? responsePatientHistory.suspicionEvaluation.datetimeInclusion : responsePatientHistory.suspicionEvaluation.datetimeSuspicionEvaluation));
        }

        let idPatientHealthcareAgreement: number = responsePatientHistory.episode.patientHealthcareAgreementInEpisode?.patientHealthcareAgreement?.idPatientHealthcareAgreement;

        if (idPatientHealthcareAgreement && idPatientHealthcareAgreement > 0) {
          this.model.get('nameHealthcareAgreement').setValue(responsePatientHistory.episode.patientHealthcareAgreementInEpisode.nameHealthcareAgreement);
          this.model.get('planName').setValue(responsePatientHistory.episode.patientHealthcareAgreementInEpisode.planName);
          this.model.get('healthcareAgreementCardNumber').setValue(responsePatientHistory.episode.patientHealthcareAgreementInEpisode.patientHealthcareAgreement.healthcareAgreementCardNumber);
          this.model.get('healthcareAgreementExpirationDate').setValue(this.datepipe.transform(responsePatientHistory.episode.patientHealthcareAgreementInEpisode.patientHealthcareAgreement.healthcareAgreementExpirationDate, 'dd/MM/yyyy'));
        }

        if (responsePatientHistory.hasPatient)
          this.mapValueToForm(responsePatientHistory.patient);
        else if (responsePatientHistory.episode.isUnidentifiedPatient) {
          this.isUnidentifiedPatient = responsePatientHistory.episode.isUnidentifiedPatient;
          this.model.get('unidentifiedPatientDescription').setValue(responsePatientHistory.episode.unidentifiedPatientDescription);
          this.model.get('datetimeInclusion').setValue(this.datepipe.transform(responsePatientHistory.episode.datetimeInclusion, 'dd/MM/yyyy, H:mm'));
        }
        else
          this.model.get('datetimeInclusion').setValue(this.datepipe.transform(responsePatientHistory.episode.datetimeInclusion, 'dd/MM/yyyy, H:mm'));
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateValuesHistory(idEpisode: number) {
    this.patientHistoryService.getPatientHistoryDetails(idEpisode).subscribe({
      next: (response: PatientHistoryResponse) => {
        this.isFirstLoading = false;
        if (response.isError) {
          this.alertService.show("Erro", response.errorDescription, AlertType.error);
          return;
        }

        if (this.listMetadataLookup && this.listMetadataLookup.length > 0) {
          if (response.multiProfessionalCareHistorie) {
            response.multiProfessionalCareHistorie.forEach(x => {
              x.valueMetaData = x.valueMetaData.map(y => {
                const metaData = this.listMetadataLookup.find(z => z.metaData.idMetaData === y.idMetaData)?.metaData;
                if (metaData)
                  y.metaDataName = metaData.metaDataName;
                return y;
              });
            });
          }
        }

        if (response) {
          this.listValueMetaDataFrontDeskTriage = response.patient?.metaData;

          if (response.patientAvoidanceLogResponse?.patientAvoidanceLog?.patientExitLog) {
            this.numberOfLinesInTextPatientExitLog = this.countLines(response.patientAvoidanceLogResponse.patientAvoidanceLog.patientExitLog);

            this.patientAvoidanceLogResponse = new PatientAvoidanceLogResponse();
            this.patientAvoidanceLogResponse = response.patientAvoidanceLogResponse;
          }
        }

        if (response.episode.idNatureOfAttendance == NatureOfAttendanceEnum.online)
          this.isOnline = true;

        this.populateMedicalProcedure(this.idEpisode, response);
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  countLines(text: string): number {
    if (!text || text.trim() == ""){
      return 0;
    }

    const normalizedText = text.replace(/\r\n/g, '\n');
    return normalizedText.split('\n').length;
  }

  mapValueToForm(patient: PatientStruct) {
    this.model.get('patientName').setValue(patient.patientName);
    this.model.get('socialName').setValue(patient.socialName);
    this.model.get('birthDate').setValue(this.datepipe.transform(patient.birthDate, 'ddMMyyyy'));
    if (this.model.get('birthDate').value) {
      let patientAge = this.utilService.getAge(this.model.get('birthDate').value);
      this.model.get('patientAge').setValue(`${patientAge.years}a ${patientAge.months}m ${patientAge.days}d`);
    }
    this.model.get('genderName').setValue(patient.genderName);
    this.model.get('cpf').setValue(patient.cpf);
    this.model.get('cns').setValue(patient.cns);
    this.model.get('email').setValue(patient.email);
    this.model.get('raceName').setValue(patient.raceName);
    this.model.get('educationName').setValue(patient.educationName);
    this.model.get('rg').setValue(patient.rg);
    this.model.get('maritalStatusName').setValue(patient.maritalStatusName);
    this.model.get('motherName').setValue(patient.motherName);
    this.model.get('fatherName').setValue(patient.fatherName);
    this.model.get('profession').setValue(patient.profession);
    this.model.get('birthCountry').setValue(patient.birthCountry);
    this.model.get('datetimeInclusion').setValue(this.datepipe.transform(patient.datetimeInclusion, 'dd/MM/yyyy, H:mm'));
    this.model.get('city').setValue(patient.city);
    this.model.get('street').setValue(patient.street);
    this.model.get('houseNumber').setValue(patient.houseNumber);
    this.model.get('apartmentNumber').setValue(patient.apartmentNumber);
    this.model.get('state').setValue(patient.state);
    this.model.get('country').setValue(patient.country);
    this.model.get('neighborhood').setValue(patient.neighborhood);
    this.model.get('zipCode').setValue(patient.zipCode);
    this.model.get('phone1').setValue(patient.phone1);
    this.model.get('phone2').setValue(patient.phone2);
  }

  getLookupMedicine() {
    this.medicineService.listAllMedicine().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listMedicine = response;
      }
    });
  }

  getLookupSadtType() {
    this.examTypeService.listAllExamType().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listExamType = response.listExamType;
      }
    });
  }

  getLookupSadt() {
    this.examService.listAllExam(null).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listLookupExam = response.listExam;
        let othes: ExamStruct = {
          'idExam': this.satdOthers, 'examName': 'Outros',
          idExamType: 0,
          referential: 0,
          examTypeName: '',
          description: '',
          isActive: true,
          isDeleted: true,
          datetimeInclusion: new Date()
        };
        this.listLookupExam.push(othes);
      }
    });
  }

  getLookupMedicalCare() {
    this.lookupMedicalCareService.getLookupMedicalCare().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.lookupMedicalCareResponse = response;
      }
    });
  }

  getAllPriorities() {
    this.priorityService.listPriority().subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.listOfPriorities = response.listPriority;
    });
  }

  openMotiveModal() {
    let reportDialog = this.dialog.open(ReturnMotiveModalComponent);
    reportDialog.afterClosed().subscribe({
      next: (result: ReturnPatientRequest) => {
        if (result && result.motive) {
          this.isLoading = true;
          result.idEpisode = this.idEpisode;
          this.queueService.UpdateQueue(result).subscribe({
            next: (response) => {
              if (response.isError) {
                this.alertService.show('Erro', response.errorDescription, AlertType.error);
                return;
              }

              this.ngOnInit();
            }
          });
        }
      }
    });
  }

  downloadAdmissionReport() {
    this.isLoading = true;
    this.downloadReportService.downloadReport(this.idEpisode, this.admissionReportName).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.dialog.open(ReportModalComponent, {
          data: {
            reportName: response.reportName,
            reportPdf64: response.reportPdf64,
          },
        });
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  downloadCompletedReport() {
    if (this.isLoading)
      return;

    this.isLoading = true;

    this.patientHistoryService.downloadCompleteReport(this.idEpisode).subscribe({
      next: (response) => {
        if (response.isError && response.errorCode == 1) {
          this.alertService.show('Aviso', response.errorDescription, AlertType.warning);
          this.isLoading = false;
        }
        else if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
        }
        else {
          const downloadData = `data:application/zip;base64,${response.zipFileBase64}`;

          const downloadLink = encodeURI(downloadData);

          if (downloadLink)
            this.triggerDownload(downloadLink);
        }
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  triggerDownload(downloadLink: string) {
    const anchor = document.createElement('a');
    anchor.href = downloadLink;
    anchor.download = `ficha_completa_tolife_epi_${this.idEpisode}.zip`
    anchor.style.display = 'none';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }

  sendReportModal(idEpisode: number) {
    if (this.isFirstLoading) return;

    let reportDialog = this.dialog.open(DocumentSendModalComponent, {
      data: {
        idEpisode: idEpisode,
        responsbileUserName: this.lastMedicalCare.userName,
        lastMedicalCare: this.lastMedicalCare,
        cpf: this.model.get('cpf').value,
        email: this.model.get('email').value,
        patientName: this.model.get('patientName').value,
      },
    });
    reportDialog.afterClosed().subscribe({
      next: result => {
        if (result.sent)
          this.alertService.show('Sucesso!', 'Documento enviado com sucesso', AlertType.success);
      }
    });
  }

  getListMetaData() {
    this.metaDataService.getAllMetaData().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isFirstLoading = false;
          return;
        }

        this.listMetadataLookup = response.listMetaDataStruct;
      },
      error: (error) => {
        console.log(error);
        this.isFirstLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getGrievanceNotification(idGrievanceNotification: number) {
    this.isLoading = true;
    this.hospitalDocumentService.getGrievanceReport(idGrievanceNotification, true).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (response.grievanceReportString64 != null) {
          let reportDialog = this.dialog.open(ReportModalComponent, {
            data: {
              reportName: response.reportName,
              reportPdf64: response.grievanceReportString64,
            },
          });
        }
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
  getGuideLookup() {
    this.privateBillingLookupService.listAllLookups().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show("Houve um erro", response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.lookups = response;
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show("Houve um erro", error, AlertType.error);
      }
    });
  }
  OpenAppointmentGuide(index: number) {
    const dialogRef = this.dialog.open(TissGuideAppointmentHistoryComponent, {
      data: {
        lookups: this.lookups,
        guide: this.listAppointmentGuideStruct[index],
        idEpisode: this.idEpisode,
      },
      panelClass: 'appointment-tiss-guide-modal',
    });
  }
  OpenFeesGuide(index: number) {
    const dialogRef = this.dialog.open(TissGuideFeesHistoryComponent, {
      data: {
        lookups: this.lookups,
        guide: this.listFeesGuideStruct[index],
        idEpisode: this.idEpisode,
      },
      panelClass: 'fees-tiss-guide-modal',
    });
  }
  OpenAdmissionGuide(index: number) {
    const dialogRef = this.dialog.open(TissGuideAdmissionHistoryComponent, {
      data: {
        lookups: this.lookups,
        guide: this.listAdmissionGuideStruct[index],
        idEpisode: this.idEpisode,
      },
      panelClass: 'admission-tiss-guide-modal',
    });
  }
  OpenSpSadtGuide(index: number) {
    const dialogRef = this.dialog.open(TissGuideSpSadtHistoryComponent, {
      data: {
        lookups: this.lookups,
        guide: this.listSpSadtGuideStruct[index],
        idEpisode: this.idEpisode,
      },
      panelClass: 'sp-sadt-tiss-guide-modal',
    });
  }

  public get metaDataTypeEnumResult(): typeof MetaDataTypeEnum {
    return MetaDataTypeEnum;
  }

  verifyDate(controlId: number): void {
    const control = this.model.get(controlId.toString()); // Transforme o número em uma string
    const validationError = VerifyDate()(control);

    if (validationError)
      control.setErrors({ ...control.errors, ...validationError });
    else
      control.setErrors(null);
  }
}