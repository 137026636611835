<div class="feature-content">
    <form class="form" [formGroup]="model" (ngSubmit)="submit(false)">
        <!-- <div class="row">
            <div class="col-12 col-sm-12 col-md-12">
                <app-select (selectedValue)="addItemAllergies($event)" [multiple]="true" [url]="urlListAllergies"
                    [idField]="'idAllergy'" [nameField]="'allergyName'" [labelField]="'Alergias'"
                    [preSelectedlist]="listAllergyEpisode" [preSelectedBlock]="true"></app-select>
            </div>
        </div> -->
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12">
                <app-allergy-tag [inputListTags]="allergies" [listTags]="allergiesToSave"
                    (updateAllergiesToSave)="updateAllergiesToSave($event)">
                </app-allergy-tag>
            </div>
        </div>
        <div class="col-12 care-line-priority" *ngIf="this.listCareLinePriority.length > 0">
            <mat-form-field appearance="outline">
                <mat-label>Linha de Cuidado em prioridade</mat-label>
                <mat-select formControlName="idCareLinePriority">
                    <mat-option *ngFor="let item of listCareLinePriority" [value]="item.idCareLinePriority">
                        {{item.careLinePriorityName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <mat-label>
            <strong>Checar medicamentos prescritos: </strong>
            <app-presciption-list [listPrescription]="listPrescription"
                [listPrescriptionMedicatedSolution]="listPrescriptionMedicatedSolution" [patientName]="patientName"
                [birthDate]="birthDate" [idEpisode]="idEpisode" [actions]="true"
                [listCheckedMedication]="listCheckedMedication">
            </app-presciption-list>
            <app-external-prescription-list
                [listPrescription]="listExternalPrescription"
            ></app-external-prescription-list>
        </mat-label>
        <mat-label *ngIf="hasObservationProcedure">
            <strong>Checar procedimentos prescritos: </strong>
            <app-procedure-list [listProcedure]="listProcedure" [listProceduresNursing]="listProceduresNursing"
                [patientName]="patientName" [birthDate]="birthDate" [idEpisode]="idEpisode" [actions]="true"
                [listCheckedProcedure]="listCheckedProcedure"
                (procedureSigtapSelected)="onCheckProcedure($event)">
            </app-procedure-list>
        </mat-label>

        <div class="row">
            <p><b>Evolução do Paciente</b></p>
            <div class="col-12 col-sm-6 col-md-4" *ngIf="listTextTemplate && listTextTemplate.length > 0">
                <mat-form-field appearance="outline">
                    <mat-label>Templates</mat-label>
                    <mat-select>
                        <mat-option [value]="null" (click)="selectTemplate(null)">Selecione...</mat-option>
                        <mat-option *ngFor="let item of listTextTemplate" (click)="selectTemplate(item)" [value]="item">
                            {{item.textTemplateName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <mat-error *ngIf="model.get('patientEvolution').invalid && this.trySubmit && this.evolutionFillingIsRequired">Informe a evolução do paciente.</mat-error>
            <div class="col-12 col-sm-12 col-md-12">
                <!-- <mat-form-field appearance="outline">
                    <mat-label>Evolução do Paciente</mat-label>
                    <textarea matInput formControlName="patientEvolution"></textarea>
                </mat-form-field> -->
                <quill-editor class="quill-input" [modules]="quillConfiguration" formControlName="patientEvolution" [placeholder]="'Evolução do Paciente'" 
                    [styles]="{'max-height': '300px', 'min-height': '70px', 'overflow': 'auto',}">
                </quill-editor>
            </div>
        </div>
        <div class="row">
            <div class="col-6 col-sm-3 col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>Temperatura</mat-label>
                    <input (blur)="onBlurTemperature()" type="text" [mask]="masks.temperature" matInput
                        formControlName="temperature">
                    <span matSuffix>ºC</span>
                </mat-form-field>
            </div>
            <div class="col-6 col-sm-3 col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>FR</mat-label>
                    <input type="text" [mask]="masks.threeDigits" matInput formControlName="respiratoryFrequency">
                    <span matSuffix>irpm</span>
                </mat-form-field>
            </div>
            <div class="col-6 col-sm-3 col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>FC</mat-label>
                    <input type="text" [mask]="masks.threeDigits" matInput formControlName="heartRate">
                    <span matSuffix>bpm</span>
                </mat-form-field>
            </div>
            <div class="col-6 col-sm-3 col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>GC</mat-label>
                    <input (blur)="onBlurGlucose()" type="text" [mask]="masks.threeDigits" matInput
                        formControlName="glucose">
                    <span matSuffix>mg/dL</span>
                </mat-form-field>
            </div>
            <div class="col-6 col-sm-3 col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>PA</mat-label>
                    <input (blur)="onBlurPressure()" type="text" [mask]="masks.pressure" matInput
                        formControlName="bloodPressure">
                    <span matSuffix>mmHG</span>
                </mat-form-field>
            </div>
            <div class="col-6 col-sm-3 col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>SatO2</mat-label>
                    <input type="text" [mask]="masks.threeDigits" matInput formControlName="saturation">
                    <span matSuffix>%</span>
                </mat-form-field>
            </div>
        </div>
        <div class="row" *ngIf="codCbo">
            <div class="col-12 col-sm-12 col-md-12">
                <app-select [preSelectedlist]="listPreselectedPRocedure" (selectedValue)="addItemProcedure($event)"
                    [multiple]="true" [url]="urlListProcedure" [idField]="'idProcedureSigtap'"
                    [nameField]="'completeProcedureName'" [labelField]="'Procedimentos Sigtap'"></app-select>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <button mat-flat-button color="primary" class="" type="submit">
                    <span *ngIf="isLoading == false">Salvar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
            </div>
            <div class="col-6" *ngIf="isReleasable && !episodeStruct.idEpisodeAdministrativeRelease">
                <button (click)="releasePatient()" mat-flat-button color="primary" class=" release-button"
                    type="button">
                    <span *ngIf="isLoading == false">Salvar e Liberar Paciente</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
            </div>
        </div>
    </form>
</div>