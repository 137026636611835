export class SigaPatientContactStruct {
    public dddResidential: string;
    public residentialPhone: string;
    public dddCellPhone: string;
    public cellPhone: string;
    public noCellPhone: boolean;
    public dddComercial: string;
    public comercialPhone: string;
    public dddContact: string;
    public contactPhone: string;
    public email: string;
    public contactName: string;
}