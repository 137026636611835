<div class="display">
    <header>
        <div class="row header__h1">
            <div class="col-1"><img class="brand" src="assets/images/logo-branco.png"></div>
            <div class="col-11">
                <h1>{{panelStruct? panelStruct.biPanel.biPanelName : null}}</h1>
            </div>
        </div>
    </header>
    <div *ngIf="!authenticated && !onlineOffline" class="offline">
        <span><mat-icon class="offline--icon">wifi_off</mat-icon>Você esta sem conexão com a internet no momento.</span>
    </div>
    <app-pin-bi-panel *ngIf="!authenticated" (onAuthenticated)="checkPin($event)"
        [isLoading]="isLoading" [idBiPanel]="idBiPanel" [panelIndex]="panelIndex"></app-pin-bi-panel>
    <app-display-bi-panel *ngIf="authenticated" [idBiPanel]="idBiPanel" [panelStruct]="panelStruct"></app-display-bi-panel>
</div>