import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { ListTotemNpsResponse } from '../../responses/nps/list-totem-nps.response';
import { GetTotemNpsResponse } from '../../responses/nps/get-totem-nps.response';
import { TotemNpsRequest } from '../../requests/nps/totem-nps.request';

@Injectable({
  providedIn: 'root'
})
export class TotemNpsService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }


  public listTotemNps(searchText: string, isActive: boolean): Observable<ListTotemNpsResponse> {
    
    let uri = 'TotemNps?'
    
    if(searchText != null)
      uri = uri+ `searchText=${searchText}&`;
    
    if(isActive !=null)
      uri = uri + `isActive=${isActive}&`  
    
    return this.httpClient.get<ListTotemNpsResponse>(environment.urlApiNps + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public deleteTotemNps(idTotemNps: number): Observable<ReturnStruct>{

    let uri = `TotemNps/idTotemNps/${idTotemNps}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiNps + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public getTotem(idTotemNps: number): Observable<GetTotemNpsResponse> {
    
    let uri = `TotemNps/idTotemNps/${idTotemNps}`;  
    
    return this.httpClient.get<GetTotemNpsResponse>(environment.urlApiNps + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public createTotem(body: TotemNpsRequest): Observable<ReturnStruct>{

    let uri = `TotemNps`

    return this.httpClient.post<ReturnStruct>(environment.urlApiNps + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public updateTotem(idTotemNps: number, body: TotemNpsRequest): Observable<ReturnStruct>{

    let uri = `TotemNps/idTotemNps/${idTotemNps}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiNps + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
