import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { AreaRankingService } from 'src/app/shared/services/API/gamification/area-ranking.service';
import { AreaRankingModel } from 'src/app/shared/services/models/gamification/area-ranking.model';
import { TableRankingModel } from 'src/app/shared/services/models/gamification/table-ranking.model';

@Component({
  selector: 'app-area-ranking',
  templateUrl: './area-ranking.component.html',
  styleUrls: ['./area-ranking.component.css']
})
export class AreaRankingComponent implements OnInit {

  @Input() public listAreaRanking: AreaRankingModel[];
  @Input() public datetimeInclusionArea: Date;
  @Input() public showNames: boolean;
  @Input() public lastDateValidate: Date;

  constructor(private formBuilder: UntypedFormBuilder,
    private areaRankingService: AreaRankingService,
    private alertService: AlertService,) {
  }

  public model: UntypedFormGroup;
  displayedColumns = ['user', 'ranking', 'point', 'averageTime'];

  public isLoading: boolean;
  public idArea: number;
  public areaRanking: TableRankingModel[];

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      matStartDate: [, [Validators.required]],
      matEndDate: [, [Validators.required]],
    });
  }

  populateAreaRanking(matStartDate: Date, matEndDate: Date) {
    let beginPeriod = matStartDate;
    let endPeriod = matEndDate;

    this.areaRankingService.getAreaRankingByPeriod(beginPeriod, endPeriod).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.listAreaRanking = response.listAreaRanking;
      this.lastDateValidate = response.lastDateValidate;
      this.listAreaRanking.forEach(x => {
        let averageTimeSecond = new Date(x.averageTimeSecond * 1000);
        let hours = averageTimeSecond.getUTCHours();
        let minutes = averageTimeSecond.getUTCMinutes();
        let seconds = averageTimeSecond.getSeconds();

        x.averageTime = hours.toString().padStart(2, '0') + ':' +
          minutes.toString().padStart(2, '0') + ':' +
          seconds.toString().padStart(2, '0');
      });
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  change() {
    this.model.get('matStartDate').value;
    this.model.get('matEndDate').value;
    this.populateAreaRanking(this.model.get('matStartDate').value, this.model.get('matEndDate').value)
  }
}