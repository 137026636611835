import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { FrequencyEnum } from 'src/app/shared/enum/business-intelligence/frequency.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { BiLookupService } from 'src/app/shared/services/API/business-intelligence/bi-lookup.service';
import { BusinessIntelligenceService } from 'src/app/shared/services/API/business-intelligence/business-intelligence.service';
import { ReportHealthUnitService } from 'src/app/shared/services/API/business-intelligence/report-health-unit.service';
import { SendReportService } from 'src/app/shared/services/API/business-intelligence/send-report.service';
import { GetAllUserPermissionBiService } from 'src/app/shared/services/API/user/get-all-user-permission-bi.service';
import { DaysMonth } from 'src/app/shared/services/models/business-intelligence/days-month.model';
import { DaysWeek } from 'src/app/shared/services/models/business-intelligence/days-week.model';
import { Frequency } from 'src/app/shared/services/models/business-intelligence/frequency.model';
import { SendReportRequest } from 'src/app/shared/services/requests/business-intelligence/send-report.request';
import { BusinessIntelligenceStruct } from 'src/app/shared/services/structs/business-intelligence/business-intelligence.struct';
import { SendReportStruct } from 'src/app/shared/services/structs/business-intelligence/send-report.struct';
import { ListUserStruct } from 'src/app/shared/services/structs/user/list-user.struct';

@Component({
  selector: 'app-business-intelligence-send-report',
  templateUrl: './business-intelligence-send-report.component.html',
  styleUrls: ['./business-intelligence-send-report.component.css']
})
export class BusinessIntelligenceSendReportComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private alertService: AlertService,
    private router: Router,
    private biLookupService: BiLookupService,
    private sendReportService: SendReportService,
    private getAllUserPermissionBiService: GetAllUserPermissionBiService,
    private businessIntelligenceService: BusinessIntelligenceService,
    private reportHealthUnitService: ReportHealthUnitService
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.business_intelligence;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.bi_send_report;

  public listUser: ListUserStruct[];
  public isFirstLoading: boolean = false;
  public isLoading: boolean;
  public model: FormGroup;
  public arrayVariables: any[] = [];
  public isUpdate: boolean;
  public sendReportRequest = new SendReportRequest();
  public listFrequency: Frequency[];
  public listDaysMonth: DaysMonth[];
  public listDaysWeek: DaysWeek[];
  public listReport: BusinessIntelligenceStruct[];
  public monthly: number = FrequencyEnum.Mensal;
  public weekly: number = FrequencyEnum.Semanal;

  ngOnInit(): void {
    this.isLoading = false;
    this.isUpdate == false;
    this.isFirstLoading = true;

    this.model = this.formBuilder.group({
      expressions: this.formBuilder.array([])
    });

    this.sendReportRequest = new SendReportRequest();

    this.populateReportSelect();
    this.populateUserSelect();
    this.populateLookup();
  }

  submit() {
    if (this.isLoading)
      return;

    else if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      this.isLoading = false;
      return;
    }

    this.isLoading = true;

    this.sendReportRequest.listSendReportStruct = [];

    for (let i = 0; i < this.model.get('expressions').value.length; i++) {
      if (this.model.get('expressions').value[i].idReportFunctionHealthUnit == "" || this.model.get('expressions').value[i].idUser == "" || this.model.get('expressions').value[i].value == ""
        || this.model.get('expressions').value[i].idFrequency == "") {
        this.alertService.show('Erro', "É necessário preencher todos os campos.", AlertType.error)
        this.isLoading = false;
        return;
      }
      if ((this.model.get('expressions').value[i].idFrequency == this.monthly && this.model.get('expressions').value[i].idDaysMonth == null)
        || (this.model.get('expressions').value[i].idFrequency == this.weekly && this.model.get('expressions').value[i].idDaysWeek == null)) {
        this.alertService.show('Erro', "É necessário preencher todos os campos.", AlertType.error)
        this.isLoading = false;
        return;
      }
    }
    
    this.model.get('expressions').value.forEach((x) => {
      var expression = new SendReportStruct();
      expression.idReportFunctionHealthUnit = x.idReportFunctionHealthUnit;
      expression.idFrequency = x.idFrequency;
      expression.listIdUser = x.idUser;
      expression.value = x.value;
      expression.idDaysMonth = x.idDaysMonth;
      expression.idDaysWeek = x.idDaysWeek;
      expression.idSendReportRule = x.idSendReportRule;
      this.sendReportRequest.listSendReportStruct.push(expression);
    });

    if (this.isUpdate)
      this.putSendReportData(this.sendReportRequest);
    else
      this.postSendReportData(this.sendReportRequest);
  }

  populateUserSelect() {
    this.getAllUserPermissionBiService.getAllUserPermissionBi().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listUser = response.listUserStruct;

        this.isFirstLoading = false;
        this.isLoading = false;
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateLookup() {
    this.biLookupService.listLookupSendReport().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listFrequency = response.listFrequency;
        this.listDaysWeek = response.listDaysWeek;
        this.listDaysMonth = response.listDaysMonth;

        this.populateSendReportData();
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateSendReportData() {
    this.sendReportService.getSendReport().subscribe({
      next: (response) => {
        if (response.isError) {
          this.isFirstLoading = false;
          this.isLoading = false;
          return;
        }

        if (response.listSendReportRule.length > 0) {
          response.listSendReportRule.forEach((x) => {
            (this.model.controls['expressions'] as FormArray).push(
              this.formBuilder.group({
                idReportFunctionHealthUnit: [x.idReportFunctionHealthUnit ? x.idReportFunctionHealthUnit.toString() : null],
                idFrequency: [x.idFrequency.toString()],
                value: [x.shootingTime],
                idDaysMonth: [x.idDaysMonth ? x.idDaysMonth.toString() : null],
                idDaysWeek: [x.idDaysWeek ? x.idDaysWeek.toString() : null],
                idUser: [response.listSendReportUser.filter(p => p.idSendReportRule == x.idSendReportRule).map(c => c.idUser.toString())],
                idSendReportRule: [x.idSendReportRule]
              }))
          });

          this.isUpdate = true;
        }
        else
          this.isUpdate = false;

        this.isLoading = false;
        this.isFirstLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        this.isFirstLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateReportSelect() {
    this.businessIntelligenceService.getReports(null).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listReport = [];
        response.healthUnitReport.forEach((report) => {
          report.reports.forEach((element) => {
            this.listReport.push(element);
          })
        });
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  postSendReportData(sendReportRequest: SendReportRequest) {
    this.sendReportService.postSendReport(sendReportRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isFirstLoading = false;
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);

        this.ngOnInit();
        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        this.isFirstLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  putSendReportData(sendReportRequest: SendReportRequest) {
    this.sendReportService.putSendReport(sendReportRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isFirstLoading = false;
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações atualizadas com sucesso", AlertType.success);
        this.ngOnInit();
        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        this.isFirstLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}