import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { ReturnMotiveModalComponent } from 'src/app/patient-history/pages/patient-history-details/return-motive-modal/return-motive-modal.component';
import { EvadePatientModalComponent } from 'src/app/shared/components/evade-patient-modal/evade-patient-modal.component';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { PatientListReprintReportModalComponent } from 'src/app/shared/components/patient-list-reprint-report-modal/patient-list-reprint-report-modal.component';
import { SelectRoomModalComponent } from 'src/app/shared/components/select-room-modal/select-room-modal.component';
import { EpisodeStatusEnum } from 'src/app/shared/enum/episode-status.enum';
import { StatusQueueEnum } from 'src/app/shared/enum/status-queue.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { GetRoomService } from 'src/app/shared/services/API/flow/get-room.service';
import { GetFrontdeskReportService } from 'src/app/shared/services/API/medical-record/get-frontdesk-report.service';
import { CallPanelService } from 'src/app/shared/services/API/orchestrator-panel/call-panel.service';
import { AttendPatientService } from 'src/app/shared/services/API/orchestrator-queue/attend-patient.service';
import { PatientListService } from 'src/app/shared/services/API/orchestrator-queue/patient-list.service';
import { QueueHistoryService } from 'src/app/shared/services/API/orchestrator-queue/queue-history.service';
import { DeviceTypeService } from 'src/app/shared/services/API/risk-classification/device-type.service';
import { PriorityService } from 'src/app/shared/services/API/totem/priority.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { PriorityModel } from 'src/app/shared/services/models/totem/priority.model';
import { CallPanelEpisodeRequest } from 'src/app/shared/services/requests/orchestrator-panel/call-panel-episode.request';
import { PostPresenceReportRequest } from 'src/app/shared/services/requests/orchestrator-patient/post-presence-report.request';
import { AttendPatientRequest } from 'src/app/shared/services/requests/orchestrator-queue/attend-patient.request';
import { ReturnPatientRequest } from 'src/app/shared/services/requests/orchestrator-queue/return-patient.request';
import { UpdateStatusQueueRequest } from 'src/app/shared/services/requests/queue/update-status-queue.request';
import { ListPatientResponse } from 'src/app/shared/services/responses/orchestrator-queue/list-patient.response';
import { RoomStruct } from 'src/app/shared/services/structs/flow/room.struct';
import { PanelCallEpisodeStruct } from 'src/app/shared/services/structs/orchestrator-panel/panel-call-episode.struct';
import { ListPatientStruct } from 'src/app/shared/services/structs/orchestrator-queue/list-patient.struct';
import { UtilService } from 'src/app/shared/services/util.service';
import { WebsocketQueueService } from 'src/app/shared/services/websocket-queue.service';
import { WebsocketRoomUtilService } from 'src/app/shared/services/websocket-util/websocket-room-util.service';
import { CareLinePriorityStatusEnum } from 'src/app/shared/enum/care-line-priority/care-line-priority-status.enum';
import { CsatService } from 'src/app/shared/services/API/csat/csat.service';
import { UtilsClassificationService } from '../../utils.service';
import { PrioritiesStatusEnum } from 'src/app/shared/enum/priorities-status.enum';
import { WebsocketAttendanceUtilService } from 'src/app/shared/services/websocket-util/websocket-attendance-util.service';
import { AlertModalComponent } from 'src/app/shared/components/alert-modal/alert-modal.component';
import { SignatureProviderEnum } from 'src/app/shared/enum/signature-providers/signature-providers.enum';
import { UserRoleEnum } from 'src/app/shared/enum/user-role.enum';
import { UserLoginResponse } from 'src/app/shared/services/responses/user/auth.response';
import { DigitalSignatureUtilService } from 'src/app/shared/services/digital-signature-util.service';
import { MenuComponent } from 'src/app/shared/components/menu/menu.component';
import { HealthUnitService } from 'src/app/shared/services/API/user/health-unit.service';
import { TissGuideManagementModalComponent } from 'src/app/shared/components/tiss-guides/tiss-guide-management-modal/tiss-guide-management-modal.component';
import { DeviceTypeEnum } from 'src/app/shared/enum/device-type.enum';
import { UtilsMeasurerService } from '../flowchart/protocol-v2/utils-measurer.service';
import { CareLinePriorityEnum } from 'src/app/shared/enum/care-line-priority/care-line-priority.enum';
import { PatientListAttendanceConfirmModalComponent } from 'src/app/shared/components/patient-list-attendance-confirm-modal/patient-list-attendance-confirm-modal.component';
import { PatientListCallConfirmModalComponent } from 'src/app/shared/components/patient-list-call-confirm-modal/patient-list-call-confirm-modal.component';
import { PatientListAlertCallConfirmModalComponent } from 'src/app/shared/components/patient-list-alert-call-confirm-modal/patient-list-alert-call-confirm-modal.component';
import { PatientListLiberateCallConfirmModalComponent } from 'src/app/shared/components/patient-list-liberate-call-confirm-modal/patient-list-liberate-call-confirm-modal.component';
import { PatientListLiberateAttendanceModalComponent } from 'src/app/shared/components/patient-list-liberate-attendance-modal/patient-list-liberate-attendance-modal.component';

@Component({
  selector: 'app-classification-patient-list',
  templateUrl: './classification-patient-list.component.html',
  styleUrls: ['./classification-patient-list.component.css']
})
export class ClassificationPatientListComponent implements OnInit, OnDestroy {

  constructor(
    private priorityService: PriorityService,
    private callPanelService: CallPanelService,
    private patientListService: PatientListService,
    private attendPatientService: AttendPatientService,
    private router: Router,
    private alertService: AlertService,
    public dialog: MatDialog,
    private utilsService: UtilService,
    private signatureUtilService: DigitalSignatureUtilService,
    private websocketqueueService: WebsocketQueueService,
    private getRoomService: GetRoomService,
    private activatedRoute: ActivatedRoute,
    private getFrontdeskReportService: GetFrontdeskReportService,
    private deviceTypeService: DeviceTypeService,
    private queueHistoryService: QueueHistoryService,
    public websocketRoomUtilService: WebsocketRoomUtilService,
    public websocketAttendanceUtilService: WebsocketAttendanceUtilService,
    private authService: AuthService,
    private csatService: CsatService,
    private utilsClassificationService: UtilsClassificationService,
    private healthUnitService: HealthUnitService,
    private measurerService: UtilsMeasurerService,
  ) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MenuComponent) menu: MenuComponent;
  public seeButton: boolean = false;
  public idRoom: number;
  public idSector: number;
  public idService: number;
  public sectorName: string;
  public serviceName: string;
  public selectedRoom: RoomStruct;
  public idQueue: number;
  public listPriority: PriorityModel[];
  public updateStatusRequest: UpdateStatusQueueRequest;
  public listPatientResponse: ListPatientResponse;
  public isLoading: boolean = false;
  public reprintLoading: boolean = false;
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.classification;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.classification_patient_list;
  public searchText: string = '';
  selectedEpisode: number = 0;
  public jsonListIdStatusQueue: string;
  public idDeviceType: number;
  public careLinePriorityStatusDenied = CareLinePriorityStatusEnum.negado;
  private isActionLoading: boolean = false;
  public canReturnToQueue: boolean = true;
  private agendados: boolean = false;
  private seeOccupiedRoom: boolean = false;
  public selectedPatient: ListPatientStruct | null = null;
  //websocket
  socketDisconneted: boolean = false;
  onlineOffline: boolean = window.navigator.onLine;
  private socket: any;

  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;

  subscriptions: Subscription[] = [];

  connectionStatusMessage: string;
  connectionStatus: string;

  public autismEnum: number = PrioritiesStatusEnum.AutistaTEA;
  public overSixtyYearsEnum: number = PrioritiesStatusEnum.MaiorDe60Anos;
  public overEightyYearsEnum: number = PrioritiesStatusEnum.MaiorDe80Anos;

  public showTissGuideButton: boolean = false;
  
  public dengue: CareLinePriorityEnum = CareLinePriorityEnum.Dengue;


  ngOnInit(): void {
    this.dialog.closeAll();
    this.utilsService.cleanSecundaryHeaderModuleConfig('classification');
    this.utilsClassificationService.removeClassificationData();
    this.idService = this.utilsClassificationService.getIdService();
    this.jsonListIdStatusQueue = null;
    this.updateStatusRequest = new UpdateStatusQueueRequest();
    this.listPatientResponse = new ListPatientResponse();
    this.listPatientResponse.listPatient = [];
    this.canReturnToQueue = this.utilsService.getReturnToQueueConfig();

    if (this.activatedRoute.snapshot.paramMap.get('idRoom'))
      this.idRoom = parseInt(this.activatedRoute.snapshot.paramMap.get('idRoom'));

    if (this.activatedRoute.snapshot.paramMap.get('idSector'))
      this.idSector = parseInt(this.activatedRoute.snapshot.paramMap.get('idSector'));

    if (this.idRoom && this.idSector) {
      if (this.websocketRoomUtilService.getIdRoom() != this.idRoom)
        this.websocketRoomUtilService.clearSocket();

      this.getRoomUnAuth();
      this.startWebsocket();
      this.getDeviceType();
    }
    else
      this.selectRoomModal();

    if (this.websocketAttendanceUtilService.isConnect())
      this.websocketAttendanceUtilService.clearSocket();

    this.getCsat();
    this.populatePriorityMultiSelect();
    this.getHealthUnitConfig();
  }

  getCsat() {
    this.csatService.getCsat().subscribe({
      next: (response) => {
        this.seeButton = response.seeButton;
      },
    });
  }

  search(pageIndex: number) {
    this.isLoading = true;
    if (this.searchText == "")
      this.searchText = null;
    this.patientListService.listPatient(this.searchText, this.idRoom, this.jsonListIdStatusQueue, pageIndex, null, false, this.agendados).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.listPatientResponse = response;
        this.listPatientResponse.listPatient.forEach(x => {
          x.isLoading = false;
        });

        if (this.listPatientResponse?.returnEpisodes != null && this.listPatientResponse?.returnEpisodes > 0 && (this.jsonListIdStatusQueue == null || !this.jsonListIdStatusQueue.includes(StatusQueueEnum.retorno.toString())))
          this.alertService.show('Atenção! ', this.listPatientResponse?.returnEpisodes + ' pacientes aguardando reavaliação - retorno', AlertType.warning);

        if (this.listPatientResponse?.scheduleEpisodes != null && this.listPatientResponse?.scheduleEpisodes > 0)
          this.alertService.show('Atenção! ', this.listPatientResponse?.scheduleEpisodes + ' pacientes agendados aguardando atendimento', AlertType.warning);

        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  toggleIsLoading(index: number) {
    this.isActionLoading = !this.isActionLoading;
    this.listPatientResponse.listPatient[index].isLoading = !this.listPatientResponse.listPatient[index].isLoading;
  }

  populatePriorityMultiSelect() {
    this.priorityService.listPriority().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listPriority = response.listPriority;
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  callPatient(idEpisode: number, idQueue: number, index: number,) {

    if (this.websocketRoomUtilService.getIsOccupied()) {
      this.alertService.show("Aviso", "Você está em modo de visualização!", AlertType.warning);
      return;
    }

    if (this.isActionLoading) return;

    this.toggleIsLoading(index);

    let request = new CallPanelEpisodeRequest();
    request.episodeData = new PanelCallEpisodeStruct();

    let patient = this.listPatientResponse.listPatient.find(l => l.idEpisode === idEpisode);

    request.episodeData.idEpisode = idEpisode;
    request.episodeData.patientName = patient.patientName;
    request.episodeData.patientSocialName = patient.patientSocialName;
    request.episodeData.ticketInitials = patient.ticketInitials;
    request.episodeData.ticketSequence = patient.ticketSequence;

    this.callPanelService.callPatient(request, idQueue, this.idRoom, true, this.menuModuleEnum).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.toggleIsLoading(index);
          return;
        }

        if (response.errorCode == -1)
          this.alertService.show('Aviso', response.errorDescription, AlertType.error);
        if (response.isRestricted)
          this.openRestrictedCallModal(request, response.confirmMessage, idQueue, index);
        else if (response.isAlert)
          this.openAlertModal(request, response.confirmMessage, idQueue, index);
        else if (response.isConfirm)
          this.openConfirmModal(request, response.confirmMessage, idQueue, index);
        else
          this.updateCallCount(index);

        this.toggleIsLoading(index);
      },
      error: (error) => {
        this.toggleIsLoading(index);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
    this.selectedEpisode = idEpisode;
  }

  cancelPatient(idQueue: number, idStatus: number, idEpisode: number, index: number) {
    if (this.websocketRoomUtilService.getIsOccupied()) {
      this.alertService.show("Aviso", "Você está em modo de visualização!", AlertType.warning);
      return;
    }
    if (this.isActionLoading) return;
    this.toggleIsLoading(index);
    this.openEvadePatientModal(idQueue, idStatus, idEpisode, index);
  }

  openEvadePatientModal(idQueue: number, idStatusQueue: number, idEpisode: number, index: number) {
    const dialogRef = this.dialog.open(EvadePatientModalComponent, {
      data: {
        idStatusQueue: idStatusQueue,
        idQueue: idQueue,
        idRoom: this.idRoom,
        idEpisode: idEpisode,
        manualEvasion: true,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (!result || !result.isEvade)
          this.toggleIsLoading(index);
        else {
          this.isActionLoading = !this.isActionLoading;
          this.alertService.show('Sucesso', "Paciente evadido com sucesso", AlertType.success);
        }
      }
    });
  }

  attendPatient(patient: ListPatientStruct, index: number, reclassification: boolean, isCheckAttendance: boolean = true) {
    if (this.websocketRoomUtilService.getIsOccupied()) {
      this.alertService.show("Aviso", "Você está em modo de visualização!", AlertType.warning);
      return;
    }
    if (this.isActionLoading) return;

    this.toggleIsLoading(index);

    let attendPatientRequest: AttendPatientRequest = new AttendPatientRequest();
    attendPatientRequest.patientQueue = patient;
    attendPatientRequest.idModule = this.menuModuleEnum;
    attendPatientRequest.idRoomSector = this.listPatientResponse.idRoomSector;
    attendPatientRequest.idRoomService = this.listPatientResponse.idRoomService;

    this.attendPatientService.attendPatient(attendPatientRequest, this.idRoom, isCheckAttendance, reclassification).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.toggleIsLoading(index);
          return;
        }

        if (response.isRestrictedPermission)
          this.openRestrictedAttendmentModal(response.confirmMessage, attendPatientRequest);
        else if (response.isAlertPermission)
          this.openAlertAttendmentModal(response.confirmMessage, patient, response.isAlertPermission);
        else if (response.isConfirm)
          this.openAttendmentConfirmModal(response.confirmMessage, patient, index, reclassification);
        else
          this.router.navigate(['/classification/pre-register', { idEpisode: patient.idEpisode, idSector: this.idSector, idRoom: this.idRoom, idQueue: patient.idQueue, idDeviceType: this.idDeviceType, reclassification, showTissGuideButton: this.showTissGuideButton, idService: this.idService }]);

        this.toggleIsLoading(index);
      },
      error: (error) => {
        this.toggleIsLoading(index);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });

    this.selectedEpisode = patient.idEpisode;
  }

  newPatient() {
    if (this.websocketRoomUtilService.getIsOccupied()) {
      this.alertService.show("Aviso", "Você está em modo de visualização!", AlertType.warning);
      return;
    }
    this.router.navigate(['/classification/pre-register', { idSector: this.idSector, idRoom: this.idRoom, idDeviceType: this.idDeviceType, showTissGuideButton: this.showTissGuideButton, idService: this.idService }]);
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.paginator.firstPage();
        $this.search(0);
      }
    }, 1000);
  }

  openConfirmModal(request: CallPanelEpisodeRequest, confirmMessage: string, idQueue: number, index: number) {
    const dialogRef = this.dialog.open(PatientListCallConfirmModalComponent, {
      data: {
        confirmMessage: confirmMessage,
        idQueue: idQueue,
        idRoom: this.idRoom,
        episodeData: request.episodeData,
        idModule: this.menuModuleEnum,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.isCalled)
          this.updateCallCount(index);
      }
    });
  }

  openAlertModal(request: CallPanelEpisodeRequest, confirmMessage: string, idQueue: number, index: number) {
    const dialogRef = this.dialog.open(PatientListAlertCallConfirmModalComponent, {
      data: {
        confirmMessage: confirmMessage,
        idQueue: idQueue,
        idRoom: this.idRoom,
        episodeData: request.episodeData,
        idModule: this.menuModuleEnum,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.isCalled)
          this.updateCallCount(index);
      }
    });
  }

  openRestrictedCallModal(request: CallPanelEpisodeRequest, confirmMessage: string, idQueue: number, index: number) {
    const dialogRef = this.dialog.open(PatientListLiberateCallConfirmModalComponent, {
      data: {
        confirmMessage: confirmMessage,
        idQueue: idQueue,
        idRoom: this.idRoom,
        episodeData: request.episodeData,
        idModule: this.menuModuleEnum,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.isCalled)
          this.updateCallCount(index);
      }
    });
  }

  openAttendmentConfirmModal(confirmMessage: string, patient: ListPatientStruct, index: number, isReclassification: boolean) {
    const dialogRef = this.dialog.open(PatientListAttendanceConfirmModalComponent, {
      data: {
        confirmMessage: confirmMessage,
        idQueue: patient.idQueue,
        idRoom: this.idRoom,
        idEpisode: patient.idEpisode,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.isAttend){
          this.attendPatient(patient, index, isReclassification, false);
        }
      }
    });
  }

  openAlertAttendmentModal(confirmMessage: string, patient: ListPatientStruct, isAlertPermission: boolean = false) {
    const dialogRef = this.dialog.open(PatientListAttendanceConfirmModalComponent, {
      data: {
        confirmMessage: confirmMessage,
        idQueue: patient.idQueue,
        idRoom: this.idRoom,
        idEpisode: patient.idEpisode,
        isAlertPermission: isAlertPermission,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.isAttend)
          this.router.navigate(['/classification/pre-register', { idEpisode: patient.idEpisode, idSector: this.idSector, idRoom: this.idRoom, idQueue: patient.idQueue, idDeviceType: this.idDeviceType, showTissGuideButton: this.showTissGuideButton, idService: this.idService }]);
      }
    });
  }

  openRestrictedAttendmentModal(confirmMessage: string, attendPatientRequest: AttendPatientRequest) {
    const dialogRef = this.dialog.open(PatientListLiberateAttendanceModalComponent, {
      data: {
        confirmMessage: confirmMessage,
        attendPatientRequest: attendPatientRequest,
        idRoom: this.idRoom,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.isAttend)
          this.router.navigate(['/classification/pre-register', { idEpisode: attendPatientRequest.patientQueue.idEpisode, idSector: this.idSector, idRoom: this.idRoom, idQueue: attendPatientRequest.patientQueue.idQueue, idDeviceType: this.idDeviceType, showTissGuideButton: this.showTissGuideButton, idService: this.idService }]);
      }
    });
  }

  selectRoomModal() {
    const dialogRef = this.dialog.open(SelectRoomModalComponent, {
      data: {
        idModule: this.menuModuleEnum,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result) {
          this.seeOccupiedRoom = result.seeOccupiedRoom;
          if (result.res) {
            this.idRoom = result.res.idRoom;
            this.idService = result.res.idService;
            this.utilsClassificationService.updateIdService(this.idService);
            this.idSector = result.res.idSector;
            this.serviceName = result.res.serviceName
            this.sectorName = result.res.sectorName;
            this.selectedRoom = result.res;
            this.websocketRoomUtilService.clearSocket();
            if (this.socket) {
              this.socket.close();
              this.socket = undefined;
            }
            this.startWebsocket();
            this.listPatientResponse.listPatient = [];
            this.paginator.firstPage();
            this.search(0);
            this.getDeviceType();
            this.utilsClassificationService.removeClassificationData();
          }
        }
        this.checkSigningResponsibility();
      }
    });
  }

  getDeviceType() {
    this.deviceTypeService.GetDevicetypeBySector(this.idSector).subscribe({
      next: response => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        if (response.deviceTypeSector) {
          this.idDeviceType = response.deviceTypeSector.idDeviceType;
          if ((this.idDeviceType == DeviceTypeEnum.Trius || this.idDeviceType == DeviceTypeEnum.Berco || this.idDeviceType == DeviceTypeEnum.Bluetooth)) {
            this.measurerService.clearAllInterval();
          }
        }

      }
    });
  }

  updateCallCount(index: number) {
    this.listPatientResponse.listPatient[index].ticketCallCount = this.listPatientResponse.listPatient[index].ticketCallCount + 1;
  }

  filterListPatient({ selectedItems, agendados }) {
    this.agendados = agendados;

    if (!selectedItems || selectedItems.length == 0)
      this.jsonListIdStatusQueue = null;
    else
      this.jsonListIdStatusQueue = JSON.stringify(selectedItems);

    this.paginator.firstPage();
    this.search(0);
  }

  //Websocket
  startWebsocket() {
    this.socket = this.websocketqueueService.websocketConnection();
    this.socket.emit('join', `idRoom-${this.idRoom}`)
      .on("updateList", (res) => this.updatePatientList(res))
      .io.on("reconnect", (res) => {
        this.socket.emit('join', `idRoom-${this.idRoom}`);
      });
    if (!this.websocketRoomUtilService.isConnect()) {
      this.websocketRoomUtilService.connectwebsocketRoom(this.idRoom, this.authService.getTokenMenu().login
        , this.authService.getTokenMenu().userName, "/classification/classification-patient-list", this.seeOccupiedRoom)
    }
  }

  private idInterval = setInterval(() => {
    if (this.socket) {
      if (this.socket.connected)
        this.socketDisconneted = false;
      else
        this.socketDisconneted = true;
    } else
      this.socketDisconneted = false;

    if (this.onlineOffline !== navigator.onLine)
      this.onlineOffline = navigator.onLine;

  }, 1000);

  // private keepAliveIntervalId = setInterval(() => {
  //   if (this.idRoom)
  //     this.roomAccessUtilService.postRoomAccessHistory(this.idRoom);

  // }, this.roomAccessUtilService.roomKeepAliveTime());

  ngOnDestroy() {
    if (this.socket)
      this.socket.close();

    clearInterval(this.idInterval);
    // clearInterval(this.keepAliveIntervalId);
  }

  updatePatientList(client: any) {
    this.socketDisconneted = false;
    if (client && client.action == "newpatient") {
      this.paginator.firstPage();
      this.search(0);
      return;
    }
    if (!this.listPatientResponse || !this.listPatientResponse.listPatient || this.listPatientResponse.listPatient.length == 0 || !client)
      return;
    let patient = this.listPatientResponse.listPatient.filter(x => x.idEpisode == client.idEpisode);
    let lastPatient = null;
    if (client.idLastEpisode)
      lastPatient = this.listPatientResponse.listPatient.filter(x => x.idEpisode == client.idLastEpisode);
    if (client.action == "changestatus") {
      patient[0].idStatusQueue = client.idStatusQueue;
      patient[0].statusQueueName = client.newStatusQeue;
      patient[0].attendantUserName = client.userName;
      patient[0].idAttendantUser = client.idUser;
      if (lastPatient && lastPatient[0]) {
        lastPatient[0].idStatusQueue = client.idLastStatusQueue;
        lastPatient[0].statusQueueName = client.lastStatusQueueName;
      }
    }
    else if (client.action == "removepatient") {
      let index = this.listPatientResponse.listPatient.indexOf(patient[0]);
      if (index >= 0) {
        let deleted = this.listPatientResponse.listPatient.splice(index, 1);
        if (deleted && deleted.length >= 1) {
          this.listPatientResponse.totalTickets = (this.listPatientResponse.totalTickets - 1);
          this.listPatientResponse.fullListSize = this.listPatientResponse.totalTickets;
        }
      }
    }
  }

  getRoomUnAuth() {
    this.isLoading = true;
    this.getRoomService.getRoom(this.idRoom).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.selectedRoom = response.roomStruct;
        this.paginator.firstPage();
        this.search(0);
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  isAttendedOrEvaded(idStatusQueue) {
    return (idStatusQueue == StatusQueueEnum.atendido || idStatusQueue == StatusQueueEnum.evadido);
  }

  isAttended(idStatusQueue) {
    return (idStatusQueue == StatusQueueEnum.atendido);
  }

  isInitiated(idEpisodeStatus) {
    return (idEpisodeStatus == EpisodeStatusEnum.Iniciado);
  }

  changePage(event: PageEvent) {
    this.search(event.pageIndex);
  }

  isEvaded(idStatusQueue) {
    return idStatusQueue == StatusQueueEnum.evadido;
  }

  openMotiveModal(idEpisode: number) {
    if (this.websocketRoomUtilService.getIsOccupied()) {
      this.alertService.show("Aviso", "Você está em modo de visualização!", AlertType.warning);
      return;
    }
    let reportDialog = this.dialog.open(ReturnMotiveModalComponent);
    reportDialog.afterClosed().subscribe({
      next: (result: ReturnPatientRequest) => {
        if (result && result.motive) {
          this.isLoading = true;
          result.idEpisode = idEpisode;

          this.queueHistoryService.UpdateQueue(result).subscribe({
            next: (response) => {
              if (response.isError) {
                this.alertService.show('Erro', response.errorDescription, AlertType.error);
                return;
              }

              this.alertService.show('Sucesso', "Paciente retornado com sucesso para fila!", AlertType.success);
              this.search(0);
            }
          });
        }
      }
    });
  }

  reprintReports(idEpisode: number) {
    if (this.reprintLoading)
      return;
    this.reprintLoading = true;

    this.getFrontdeskReportService.getReport(idEpisode).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.reprintLoading = false;
          return;
        }

        let postPresenceReportRequest = new PostPresenceReportRequest();
        if (idEpisode != null) {
          postPresenceReportRequest.idStatus = response.idStatus;
          postPresenceReportRequest.idEpisode = idEpisode;
        }
        const dialogRef = this.dialog.open(PatientListReprintReportModalComponent, {
          data: {
            postPresenceReportRequest: postPresenceReportRequest,
            triageWristbandString64: response.triageWristbandReportString64,
            triageString64: response.triageReportString64
          },
        });

        this.reprintLoading = false;
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.reprintLoading = false;
      }
    });
  }

  checkSigningResponsibility() {
    let token: UserLoginResponse = this.utilsService.getToken();
    let signatureMap: Object = this.signatureUtilService.listSignatureStruct();

    let requireSignature = token.listRole.map(x => x.idRole).includes(UserRoleEnum.requerer_assinatura_classificacao) && !token.isMaster;
    let signatureReady = signatureMap != null && ((signatureMap[SignatureProviderEnum.safeID] != null && signatureMap[SignatureProviderEnum.safeID].identifierCA != null) ||
      (signatureMap[SignatureProviderEnum.vidaaS] != null && signatureMap[SignatureProviderEnum.safeID].authorizationToken != null));

    if (requireSignature && !signatureReady) {
      const dialogRef = this.dialog.open(AlertModalComponent, {
        data: {
          isTwoButtonsModal: false,
          title: 'Atenção',
          description: 'Seu perfil foi marcado com obrigatoriedade de assinatura digital de documentos. Escolha um provedor e siga o processo para ativar sua assinatura digital no sistema.'
        },
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe({
        next: result => {
          this.menu.openSignatureManager();
        }
      });
    }
  }

  getHealthUnitConfig() {
    this.healthUnitService.getHealthUnitConfig().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
        }
        this.showTissGuideButton = response.healthUnit.canAddTissGuide;
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  openModalTiss(patientStruct: ListPatientStruct) {
    this.dialog.open(TissGuideManagementModalComponent, {
      data: {
        idEpisode: patientStruct.idEpisode,
        patientName: patientStruct.patientName,
        patientSocialName: patientStruct.patientSocialName,
        idRoom: this.idRoom,
        idSector: this.idSector,
      },
      panelClass: "tiss-guide-management-modal"
    });
  }

  selectPatient(patient: ListPatientStruct): void {
    this.selectedPatient = patient;
    this.selectedEpisode = patient.idEpisode;
  }
}