<div mat-dialog-content>
    <div class="mat-dialog-header">
        <h3>Prescrição Não Padronizada</h3>
    </div>
    <form class="form" [formGroup]="model" (submit)="submit()">
        <div class="mat-dialog-content">
            <div class="row">
                <div class="col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Descrição</mat-label>
                        <input matInput formControlName="description">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Quantidade</mat-label>
                        <input formControlName="quantity" matInput type="text"
                            oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');"
                            maxlength="5">
                        <mat-error *ngIf="model.get('quantity').invalid">A quantidade máxima é 32767!</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-12 col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Unidade</mat-label>
                        <mat-select formControlName="idMeasurementUnit">
                            <mat-option *ngFor="let item of this.data.lookupMedicalCareResponse.listMeasurementUnit"
                                [value]="item.idMeasurementUnit">
                                {{item.measurementUnitName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Frequência</mat-label>
                        <input matInput formControlName="frequency">
                    </mat-form-field>
                </div>
            </div>
            <div class="footer-panel-modal-list">
                <div class="row">
                    <div class="col-6">
                        <a mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Cancelar</a>
                    </div>
                    <div class="col-6">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span>Salvar</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>