import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MedicalPrescriptionCheckRequest } from 'src/app/shared/services/requests/medical-record/medical-prescription-check.request';
import { MedicalPrescriptionStruct } from 'src/app/shared/services/structs/medical-record/medical-prescription.struct';
import { ReadMedicalPrescriptionStruct } from 'src/app/shared/services/structs/orchestrator-patient/read-medical-prescription.struct';
import { MedicalPrescriptionModalComponent } from './medical-prescription-modal/medical-prescription-modal.component';
import { PrescriptionStatusEnum } from 'src/app/shared/enum/prescription-status.enum';
import { MedicatedSolutionStruct } from 'src/app/shared/services/structs/medical-record/medicated-solution.struct';

@Component({
  selector: 'app-presciption-list',
  templateUrl: './presciption-list.component.html',
  styleUrls: ['./presciption-list.component.css']
})
export class PresciptionListComponent implements OnInit {

  @Input() listPrescription: MedicalPrescriptionStruct[];
  @Input() listPrescriptionMedicatedSolution: MedicatedSolutionStruct[];
  @Input() listCheckedMedication: MedicalPrescriptionCheckRequest[];
  @Input() patientName: string;
  @Input() birthDate: Date;
  @Input() idEpisode: number;
  @Input() actions: boolean;
  @Input() idNursingConsultation: number;

  public listDisplay: number[];
  public nonDispensedStatus = PrescriptionStatusEnum.NaoDispensado;

  constructor(
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.listDisplay = [];

  }

  openPrescriptionModal(prescription: any, isMedicatedSolution) {
    const dialogRef = this.dialog.open(MedicalPrescriptionModalComponent, {
      data: {
        prescription: prescription,
        patientName: this.patientName,
        birthDate: this.birthDate,
        actions: this.actions,
        idEpisode: this.idEpisode,
        isMedicatedSolution: isMedicatedSolution,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if(isMedicatedSolution){
          prescription.listMedicine.forEach(item => {
            
            let medicalPrescriptionCheck: MedicalPrescriptionCheckRequest = {...result.medicalPrescriptionCheckRequest};
            medicalPrescriptionCheck.idMedicalPrescription = item.idMedicalPrescription;
            medicalPrescriptionCheck.idMedicalPrescriptionCheck = item.idMedicalPrescriptionCheck ? item.idMedicalPrescriptionCheck : null;

            let index = this.listCheckedMedication.findIndex(l => l.idMedicalPrescription == medicalPrescriptionCheck.idMedicalPrescription);
            if (index >= 0)
              this.listCheckedMedication[index] = medicalPrescriptionCheck;
            else
              this.listCheckedMedication.push(medicalPrescriptionCheck);
          });
        }  else {
          result.medicalPrescriptionCheckRequest.idMedicalPrescription = prescription.idMedicalPrescription;
          let index = this.listCheckedMedication.findIndex(l => l.idMedicalPrescription == result.medicalPrescriptionCheckRequest.idMedicalPrescription);
          if (index >= 0)
            this.listCheckedMedication[index] = result.medicalPrescriptionCheckRequest;
          else
            this.listCheckedMedication.push(result.medicalPrescriptionCheckRequest);
        }
      }
    });
  }

  statusIdToText(status: number): string {
    if (status == 1)
      return "Medicação Próxima";
    else if (status == 2)
      return "Medicação Adiantada";
    else if (status == 3)
      return "Medicação Atrasada";
    else if (status == 4)
      return "Concluída";
  }
}
