import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cancel-confirm',
  templateUrl: './cancel-confirm-modal.component.html',
  styleUrls: ['./cancel-confirm-modal.component.css']
})
export class CancelConfirmModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<CancelConfirmModalComponent>,
    public router:Router
  ) { }

  public guideWasUpdate: boolean = false;

  ngOnInit(): void {
    if(this.data.guideWasUpdate != undefined && this.data.guideWasUpdate != null)
      this.guideWasUpdate = this.data.guideWasUpdate;
  }
  
  clickConfirm(){
    this.matDialogRef.close();
    this.router.navigate(['/multi-professional/multi-professional-patient-list',{idSector: this.data.idSector, idRoom: this.data.idRoom}])
  }

  close(){
    this.matDialogRef.close(); 
  }

  clickCancel(){
    this.matDialogRef.close(); 
  }
}

