import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EpisodeStatusEnum } from 'src/app/shared/enum/episode-status.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { OccupiedBedService } from 'src/app/shared/services/API/orchestrator-patient/occupied-bed.service';
import { PresenceReportService } from 'src/app/shared/services/API/orchestrator-patient/presence-report.service';
import { AdministrativeReleaseService } from 'src/app/shared/services/API/orchestrator-queue/administrative-release.service';
import { EpisodeReleaseRequest } from 'src/app/shared/services/requests/orchestrator-patient/episode-release.request';
import { PostPresenceReportRequest } from 'src/app/shared/services/requests/orchestrator-patient/post-presence-report.request';

@Component({
  selector: 'app-episode-administrative-release-confirm-modal',
  templateUrl: './episode-administrative-release-confirm-modal.component.html',
  styleUrls: ['./episode-administrative-release-confirm-modal.component.css']
})
export class EpisodeAdministrativeReleaseConfirmModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<EpisodeAdministrativeReleaseConfirmModalComponent>,
    private alertService: AlertService,
    private presenceReportService: PresenceReportService,
    private administrativeReleaseService: AdministrativeReleaseService,
  ) { }

  public idStatusEpisodeInternar: number = EpisodeStatusEnum.FinalizadoInternar.valueOf();
  public idStatusEpisodeAlta: number = EpisodeStatusEnum.FinalizadoAlta.valueOf();
  public idStatusEpisodeAltaAdministrativa: number = EpisodeStatusEnum.FinalizadoAltaAdministrativa.valueOf();
  public isLoading: boolean;
  public release: boolean = false;

  ngOnInit(): void {
    this.isLoading = false;
  }

  close() {
    this.matDialogRef.close({ release: this.release });
  }

  clickCancel() {
    this.matDialogRef.close({ release: this.release });
  }

  episodeRelease() {
    this.isLoading = true;
    this.release = true;
    this.administrativeReleaseService.releasePatient(this.data.idEpisode).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      let postPresenceReportRequest = new PostPresenceReportRequest()
      if (this.data.episode != null) {
        postPresenceReportRequest.idStatus = this.data.idStatus;
        postPresenceReportRequest.idEpisode = this.data.idEpisode;

        this.openCreateStatementOfAttendance(postPresenceReportRequest);
      }

      this.alertService.show('Sucesso', "Episódio liberado com sucesso!", AlertType.success);
      this.isLoading = false;
      this.matDialogRef.close({ release: this.release });
    },
      (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  openCreateStatementOfAttendance(postPresenceReportRequest: PostPresenceReportRequest) {
    this.presenceReportService.postReportPDF(postPresenceReportRequest).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      });
  }
}
