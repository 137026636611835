import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { GetLookupGraphResponse } from '../../responses/data-intelligence/get-lookup-graph.response';


@Injectable({
    providedIn: 'root'
})
export class LookupGraphService extends CommonService {

    constructor(private router: Router, private httpClient: HttpClient) {
        super();
    }

    public get(): Observable<GetLookupGraphResponse> {

        let uri = `LookupGraph`;

        return this.httpClient.get<GetLookupGraphResponse>(environment.urlApiDataIntelligence + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }
}