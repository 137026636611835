<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-modal-list">
            <h2>O paciente evadiu do atendimento!</h2>
        </div>

        <div class="body-modal-list">
            O paciente saiu do atendimento. Ele será evadido automaticamente e você será redirecionado para a tela de
            pacientes. Os dados gerados até aqui serão salvos no histórico do episódio.
        </div>
        <div class="footer-modal-list txtCenter">
            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
        </div>
    </div>
</div>