import { AllergyStruct } from "../../structs/medical-record/allergy.struct";
import { CareLinePriorityStatusStruct } from "../../structs/medical-record/care-line-priority-status.struct";
import { MedicalPrescriptionStruct } from "../../structs/medical-record/medical-prescription.struct";
import { MedicalProcedureStruct } from "../../structs/medical-record/medical-procedure.struct";
import { MemedCompletePrescriptionStruct } from "../../structs/medical-record/memed-complete-prescription.struct";
import { SadtGroupStruct } from "../../structs/medical-record/sadt-group.struct";
import { ReadExternalPrescriptionStruct } from "../../structs/orchestrator-patient/read-external-prescription.struct";
import { GuideRequest } from "../private-billing/guide.request";
import { PatientRequest } from "./patient.request";

export class PatientAppointmentCareRequest {
    public patientRequest: PatientRequest;
    public guideRequest: GuideRequest;

    public medicalPrescription: MedicalPrescriptionStruct[];

    public medicalProcedure: MedicalProcedureStruct[];

    public medicalCareSadt: SadtGroupStruct[];

    public listExternalPrescription: ReadExternalPrescriptionStruct[];

    public cid: number[];

    public isCidOnSickNote: boolean;

    public listAllergy: AllergyStruct[];

    public conclusionNote: string;

    public conclusionType: number;

    public administrativeRelease: boolean;

    public idStatusEpisode: number;

    public idPatientAppointment: number;

    public listIdBypass: number[];

    public isOnline: boolean;

    public remoteAttendance: boolean;

    public careLinePriorityStatusStruct: CareLinePriorityStatusStruct;

    public listMemedCompletePrescriptionStruct: MemedCompletePrescriptionStruct[];

    public userTokenMemed: string;

    public idMedicalCare: number;
    public idUser: number;
    public userName: string;
    public idEpisode: number;
    public medicalNotes: string;
    public physicalExamination: string;
    public therapeuticPlan: string;
    public diagnosedHypothesis: string;
    public temperature: number;
    public respiratoryFrequency: number;
    public heartRate: number;
    public glucose: number;
    public bloodPressureDiastole: number;
    public bloodPressureSystole: number;
    public saturation: number;
    public datetimeInclusion: Date;
    public datetimeEndIsolation: Date;
    public datetimeStartAttendance: Date;
    public sickDaysOffAmount: number;
    public idRoomAttendance: number;
    public idSignatureProvider: number;
    public signatureAuthentication: string;
}