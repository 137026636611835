<div [formGroup] = "model">
    <div class="title-header">
        <h1>Desfecho assistencial</h1>
    </div>
    <div class="row">
        <div class="col-12 radio-component">

            <label>
                <input *ngIf="hasAlta" type="radio" formControlName="conclusionType" value="{{this.destinationTypeAlta}}"
                    class="destination" (change)="changeConclusionType($event)">
                <a mat-flat-button type="button"
                    [color]="destinationTypeAltaColor"
                    class="btn-block">
                    Alta
                </a>
            </label>
            <label *ngIf="this.dischargeOutcome">
                <input type="radio" formControlName="conclusionType"
                    value="{{this.destinationTypeAltaEncaminhamento}}" class="destination"
                    (change)="changeConclusionType($event)">
                <a mat-flat-button type="button"
                    [color]="destinationTypeAltaEncaminhamentoColor"
                    class="btn-block">
                    Alta após Encaminhamento
                </a>
            </label>
            <label>
                <input type="radio" formControlName="conclusionType"
                    value="{{this.destinationTypeEncaminhamento}}" class="destination"
                    (change)="changeConclusionType($event)">
                <a mat-flat-button type="button"
                    [color]="destinationTypeEncaminhamentoColor"
                    class="btn-block">
                    Encaminhar
                </a>
            </label>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <mat-form-field appearance="outline"
                *ngIf="this.destinationType != this.destinationTypeEncaminhamento">
                <mat-label>Conclusão</mat-label>
                <textarea matInput formControlName="conclusionNote"></textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="row" *ngIf="showFoward">
        <mat-radio-group aria-label="" formControlName="idFlow">
            <mat-radio-button value="{{item.idFlow}}" *ngFor="let item of listFoward">{{item.locationName}}
            </mat-radio-button>
        </mat-radio-group>
    </div>
</div>