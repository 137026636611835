import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IntegrationRoutingModule } from './integration-routing.module';
import { IntegrationComponent } from './pages/integration/integration.component';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule, MatTree } from '@angular/material/tree';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { AccessKeyListComponent } from './pages/access-key-list/access-key-list.component';
import { AccessKeyDeleteModalComponent } from './pages/access-key-list/access-key-delete-modal/access-key-delete-modal.component';
import { SharedModule } from '../shared/shared.module';
import { AccessKeyRegisterComponent } from './pages/access-key-register/access-key-register.component';
import { IntegrationLogComponent } from './pages/integration-log/integration-log.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { IntegrationLogDetailsComponent } from './pages/integration-log-details/integration-log-details.component';
import { WebhookListComponent } from './pages/webhook-list/webhook-list.component';
import { WebhookDeleteModalComponent } from './pages/webhook-list/webhook-delete-modal/webhook-delete-modal.component';
import { WebhookRegisterComponent } from './pages/webhook-register/webhook-register.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { IntegrationIframeComponent } from './pages/integration-iframe/integration-iframe.component';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckbox, MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioButton, MatRadioModule } from '@angular/material/radio';
import { NgxMaskModule } from 'ngx-mask';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [
    IntegrationComponent,
    AccessKeyListComponent,
    AccessKeyDeleteModalComponent,
    AccessKeyRegisterComponent,
    IntegrationLogComponent,
    IntegrationLogDetailsComponent,
    WebhookListComponent,
    WebhookDeleteModalComponent,
    WebhookRegisterComponent,
    IntegrationIframeComponent,
  ],
  imports: [
    CommonModule,
    IntegrationRoutingModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonModule,
    NgxMaskModule.forRoot(),
    MatCheckboxModule,
    MatTreeModule,
    MatRadioModule,
    ClipboardModule,
    MatTooltipModule,
    MatToolbarModule,
    NgxMatFileInputModule,
    CdkAccordionModule,
    SharedModule,
    MatDatepickerModule,
    MatPaginatorModule,
    MatDialogModule
  ],
  providers: [
    AuthGuard,
    MatCheckbox,
    MatCheckboxChange,
    MatTree,
    MatRadioButton,
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
  ]
})
export class IntegrationModule { }
