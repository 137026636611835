<div class="content" *ngIf="this.panelIndex == null || this.panelIndex == undefined">
    <form class="form" [formGroup]="model"  (ngSubmit)="submit()">
        <span class="pin-intro">Informe o PIN para iniciar o sistema</span>
        <div class="row">   
            <div class="col-md-12">
                <mat-form-field appearance="outline">
                    <mat-label>PIN</mat-label>
                    <input matInput type="text" formControlName="pin" required>
                    <mat-error *ngIf="model.get('pin').invalid">Informe o PIN</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">   
            <div class="col-md-12">
                <mat-slide-toggle ngDefaultControl class="save-pin-checkbox" [ngModelOptions]="{standalone: true}" [(ngModel)]="this.savePin">Salvar PIN neste dispositivo</mat-slide-toggle>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <button mat-flat-button type="submit" color="primary" class=" btn-block">
                    <span *ngIf="isLoading == false">Confirmar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
            </div>
        </div>
    </form>
</div>