<div *ngIf="listMedicalPrescriptionPendingResponse">
    <ng-container *ngIf="listMedicalPrescriptionPendingResponse.listMedicalPrescriptionPendingLate?.length > 0">
        <div class="title-header">
            <h1>Atrasados</h1>
        </div>
        <app-bed-management-list-pending-checks
            [listPrescriptionPending]="listMedicalPrescriptionPendingResponse.listMedicalPrescriptionPendingLate"
            [idSector]="idSector" [idService]="idService" [idRoom]="idRoom">
        </app-bed-management-list-pending-checks>
    </ng-container>
    <ng-container *ngIf="listMedicalPrescriptionPendingResponse.listMedicalPrescriptionPendingNear?.length > 0">
        <div class="title-header title-header-margin-bottom">
            <h1>Próximos</h1>
        </div>
        <app-bed-management-list-pending-checks
            [listPrescriptionPending]="listMedicalPrescriptionPendingResponse.listMedicalPrescriptionPendingNear"
            [idSector]="this.idSector" [idService]="idService" [idRoom]="idRoom">
        </app-bed-management-list-pending-checks>
    </ng-container>
    <ng-container *ngIf="listMedicalPrescriptionPendingResponse.listMedicalPrescriptionPendingUnchecked?.length > 0">
        <div class="title-header title-header-margin-bottom">
            <h1>Aguardando</h1>
        </div>
        <app-bed-management-list-pending-checks
            [listPrescriptionPending]="listMedicalPrescriptionPendingResponse.listMedicalPrescriptionPendingUnchecked"
            [idSector]="this.idSector" [idService]="idService" [idRoom]="idRoom">
        </app-bed-management-list-pending-checks>
    </ng-container>
</div>