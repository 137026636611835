
import { AttendanceSadtGroupStruct } from "../../structs/medical-record/attendance-sadt-group.struct";
import { BillingDataStruct } from "../../structs/orchestrator-sadt/billing-data.struct";
import { GuideRequest } from "../private-billing/guide.request";

export class SaveSadtAttendanceRequest{
    public idEpisode: number;
    public idRoomAttendance: number;
    public idQueueUsed: number;
    public datetimeStartAttendance: Date;
    public listAttendanceSadtGroupStruct: AttendanceSadtGroupStruct[] = [];
    public billingDataStruct: BillingDataStruct;
    public guideRequest: GuideRequest;
}