<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <h1>Cadastrar Dashboard</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="row">
                    <div class="col-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome</mat-label>
                            <input matInput type="text" formControlName="dashboardName">
                            <mat-error *ngIf="model.get('dashboardName').invalid">Informe o nome do
                                dashboard</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-md-4">
                        <app-tag [listTags]="this.listTags" [allTags]="this.listAllTags"
                            *ngIf="this.listAllTags && this.listTags"></app-tag>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-8">
                        <mat-form-field appearance="outline">
                            <mat-label>Descrição</mat-label>
                            <textarea matInput type="text" formControlName="description"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <a mat-flat-button type="button" (click)="openCancelModal()" color="accent" class=" btn-block">
                            Cancelar
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>