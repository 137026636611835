import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { LookupMedicalCareService } from 'src/app/shared/services/API/medical-record/lookup-medical-care.service';
import { LookupMedicalCareResponse } from 'src/app/shared/services/responses/medical-record/lookup-medical-care-response';
import { MedicineService } from 'src/app/shared/services/API/pharmacy/medicine.service';
import { MedicFavoriteSadtModalComponent } from './medic-favorite-sadt-modal/medic-favorite-sadt-modal.component';
import { ExamTypeModel } from 'src/app/shared/services/models/SADT/exam-type.model';
import { ExamTypeService } from 'src/app/shared/services/API/SADT/exam-type.service';
import { FavoriteExamPrescriptionService } from 'src/app/shared/services/API/medic/favorite-exam-prescription.service';
import { FavoriteGroupExamPrescriptionRequest } from 'src/app/shared/services/requests/medic/favorite-group-exam-prescription.request';
import { FavoriteExamPrescriptionStruct } from 'src/app/shared/services/structs/medic/favorite-exam-prescription.struct';
import { MedicFavoriteExamPrescriptionStruct } from 'src/app/shared/structs/medic/medic-favorite-exam-prescription.struct';

@Component({
  selector: 'app-medic-favorite-exam-request-register',
  templateUrl: './medic-favorite-exam-request-register.component.html',
  styleUrls: ['./medic-favorite-exam-request-register.component.css']
})
export class MedicFavoriteExamRequestRegisterComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public medicineService: MedicineService,
    public lookupMedicalCareService: LookupMedicalCareService,
    private examTypeService: ExamTypeService,
    private favoriteExamPrescriptionService: FavoriteExamPrescriptionService
  ) { }

  public model: FormGroup;
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.medic;

  public isLoading: boolean = false;
  public isUpdate: boolean = false;
  public idDoctorFavoriteGroupExamPrescription: number;

  public lookupMedicalCareResponse: LookupMedicalCareResponse;
  private listExamType: ExamTypeModel[] = [];
  public listIdExam: number[] = [];
  public isAllExams: boolean;
  public listExamToRequest: FavoriteExamPrescriptionStruct[] = [];
  public listExam: MedicFavoriteExamPrescriptionStruct[] = [];
  public blockBypassExam: boolean = false;
  public idRoom: number;
  public idSector: number;
  public viewToggleShareWithMedicalTeam: boolean = false;

  ngOnInit(): void {
    this.isLoading = true;
    this.getLookupMedicalCare();
    this.getLookupSadtType();

    this.model = this.formBuilder.group({
      name: ['', [Validators.required]],
      shareWithMedicalTeam: [false, [Validators.required]],
    });

    if (this.activatedRoute.snapshot.paramMap.get('idRoom'))
      this.idRoom = parseInt(this.activatedRoute.snapshot.paramMap.get('idRoom'));

    if (this.activatedRoute.snapshot.paramMap.get('idSector'))
      this.idSector = parseInt(this.activatedRoute.snapshot.paramMap.get('idSector'));

    if (this.activatedRoute.snapshot.paramMap.get('idDoctorFavoriteGroupExamPrescription'))
      this.idDoctorFavoriteGroupExamPrescription = parseInt(this.activatedRoute.snapshot.paramMap.get('idDoctorFavoriteGroupExamPrescription'));

    if (this.idDoctorFavoriteGroupExamPrescription != null) {
      this.isUpdate = true;
      setTimeout(() => {
        this.populateCardList();
      }, 2000);
    } else {
      this.isLoading = false;
    }
  }

  submit() {
    if (this.isLoading)
      return;

    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      this.isLoading = false;
      return;
    }

    if (this.listExam.length === 0) {
      this.alertService.show('Erro', "Adicione pelo menos um pedido de exame.", AlertType.error);
      this.isLoading = false;
      return;
    }

    this.isLoading = true;

    let request = new FavoriteGroupExamPrescriptionRequest();
    
    this.listExam.forEach((exam) => {
      let newExam = new FavoriteExamPrescriptionStruct();
      newExam.favoriteExamPrescription.idExamType = exam.idExamType;
      newExam.favoriteExamPrescription.idPlace = exam.idPlace;
      newExam.favoriteExamPrescription.description = exam.description;
      newExam.listIdExam = exam.listIdExam;
      newExam.favoriteExamPrescription.idSadtSchedule = exam.idSadtSchedule;
      request.listFavoriteExamPrescriptionStruct.push(newExam);
    });

    request.doctorFavoriteGroupExamPrescriptionName = this.model.get('name').value;
    request.shareWithMedicalTeam = this.model.get('shareWithMedicalTeam').value;

    if (this.isUpdate) {
      request.idDoctorFavoriteGroupExamPrescription = this.idDoctorFavoriteGroupExamPrescription;
      this.putFavoritePrescription(request);
    }
    else
      this.postFavoritePrescription(request);
  }

  postFavoritePrescription(request: FavoriteGroupExamPrescriptionRequest) {
    this.favoriteExamPrescriptionService.postFavoriteGroupExamPrescription(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;
        this.router.navigate(['/medic/medic-favorite-exam-request', {idSector: this.idSector, idRoom: this.idRoom}]);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
        return;
      }
    });
  }

  putFavoritePrescription(request: FavoriteGroupExamPrescriptionRequest) {
    this.favoriteExamPrescriptionService.putFavoriteGroupExamPrescription(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações atualizadas com sucesso", AlertType.success);
        this.isLoading = false;
        this.router.navigate(['/medic/medic-favorite-exam-request', {idSector: this.idSector, idRoom: this.idRoom}]);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
        return;
      }
    });
  }

  getLookupMedicalCare() {
    this.lookupMedicalCareService.getLookupMedicalCare().subscribe({
      next: (response) => {
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.lookupMedicalCareResponse = response;
        this.viewToggleShareWithMedicalTeam = this.lookupMedicalCareResponse.viewToggleShareWithMedicalTeam;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getLookupSadtType() {
    this.examTypeService.listAllExamTypeWithExams().subscribe({
      next: (response) => {
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listExamType = response.listExamType;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateCardList() {
    this.isLoading = true;
    this.favoriteExamPrescriptionService.getFavoriteGroupExamPrescription(this.idDoctorFavoriteGroupExamPrescription).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        if(response.listFavoriteExamPrescriptionStruct.length > 0) {
          this.listExam = [];
          response.listFavoriteExamPrescriptionStruct.forEach((exam) => {
            let newExam = new MedicFavoriteExamPrescriptionStruct();
            newExam.idExamType = exam.favoriteExamPrescription.idExamType;
            newExam.examTypeName = this.listExamType.find(c => c.idExamType == newExam.idExamType)? this.listExamType.find(c => c.idExamType == newExam.idExamType).examTypeName : "Outros";
            newExam.idPlace = exam.favoriteExamPrescription.idPlace;
            newExam.placeName = this.lookupMedicalCareResponse.listPlace.find(c => c.idPlace == newExam.idPlace).placeName;
            newExam.description = exam.favoriteExamPrescription.description;
            newExam.listIdExam = exam.listIdExam;
            newExam.idSadtSchedule = exam.favoriteExamPrescription.idSadtSchedule;
            if(exam.favoriteExamPrescription.idSadtSchedule != null)
            {
              newExam.idSadtSchedule = exam.favoriteExamPrescription.idSadtSchedule;
              newExam.sadtScheduleName = this.lookupMedicalCareResponse.listSadtSchedule.find(x => x.idSadtSchedule == newExam.idSadtSchedule).sadtScheduleName;
            }
            this.listExam.push(newExam);
          })
        }

        this.model.get('name').setValue(response.favoriteGroupExamPrescription.doctorFavoriteGroupExamPrescriptionName);
        this.model.get('shareWithMedicalTeam').setValue(response.favoriteGroupExamPrescription.shareWithMedicalTeam);
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openSadtModal(sadt: MedicFavoriteExamPrescriptionStruct, readOnly: boolean, index: number) {
    const dialogRef = this.dialog.open(MedicFavoriteSadtModalComponent, {
      data: {
        sadt,
        readOnly,
        listPlace: this.lookupMedicalCareResponse.listPlace,
        listSadtSchedule: this.lookupMedicalCareResponse.listSadtSchedule,
        listExamType: this.listExamType,
        listIdExam: this.listIdExam,
        isAllExams: this.isAllExams,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result) {
          if (index >= 0)
            this.listExam.splice(index, 1);

          this.listExam.push(result.newSadt);
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  deleteSadt(index: number) {
    this.listExam.splice(index, 1);
  }
}