<div class="material-container">
    <form class="form" [formGroup]="model" (ngSubmit)="submit()">
        <div class="head-exam-modal-list">
            <h2>Liberar Material</h2>
        </div>
        <div class="body-exam-modal-list">
            <div class="row separator">
                <div class="col-12">
                    <label><b>Profissional responsável pelo Pedido: </b></label>
                    <span>{{data.requestMaterial.userName}}</span>
                </div>
            </div>
            <div class="row separator">
                <div class="col-12">
                    <label><b>Observação do Pedido: </b></label>
                    <span>{{data.requestMaterial.observation}}</span>
                </div>
            </div>
            <div class="row separator">
                <div class="col-12">
                    <label><b>Paciente: </b></label>
                    <span>{{data.episode.patientSocialName? data.episode.patientSocialName :
                        data.episode.patientName}}</span>
                </div>
            </div>
            <div class="container-itens">
                <div class="row separator">
                    <div class="row">
                        <div class="col-8"><b>Materiais e Kits:</b></div>
                        <div class="col-4"><b>Quantidade:</b></div>
                    </div>
                    <div class="kits-itens-container">
                        <div *ngFor="let item of this.data.requestMaterial.listRequestMaterialsItemKit; let i=index;" class="row">
                            <div class="col-8"><label><b>{{i+1}}. </b>{{item.kitName? "Kit: " + item.kitName :"Item: " + item.itemName}}</label></div>
                            <div class="col-4"><span>{{item.quantity}}</span></div>
                        </div>
                    </div>
                </div>
                <div class="row separator">
                    <div class="col-12">
                        <label><b>Procedimento: </b></label>
                        <span>{{data.requestMaterial.nameProcedure?data.requestMaterial.nameProcedure:"Não há
                            procedimento associado a solicitação de material"}}</span>
                    </div>
                </div>
            </div>
            <div class="container-itens">
                <div class="row separator">
                    <div class="row">
                        <div class="col-8"><b>Total de itens geral:</b></div>
                        <div class="col-4"><b>Quantidade:</b></div>
                    </div>
                    <div class="kits-itens-container">
                        <div *ngFor="let item of this.itemTotals; let i=index;" class="row">
                            <div class="col-8"><label><b>{{i+1}}. </b>{{item.idItemName ? item.idItemName : item.medicineName}}</label></div>
                            <div class="col-4"><span>{{item.quantity}}</span></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row separator">
                <h3><b>Itens e quantidades liberadas:</b></h3>
                <div *ngIf="!isLoading" class="col-md-12">
                    <app-storage-location-item [formGroup]="this.model" [listItem]="listItem" [listMedicines]="listMedicines"
                        [listStorage]="listStorage" [arrayLocationItem]="arrayLocationItem">
                    </app-storage-location-item>
                </div>
            </div>
        </div>
        <div class="footer-exam-modal-list">
            <div class=" row">
                <div class="col-12 col-md-4">
                    <button mat-flat-button type="button" color="accent" class="btn-block" (click)="clickCancel()">
                        <span *ngIf="isLoading == false">Cancelar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
                <div class="col-12 col-md-4">
                    <button mat-flat-button type="button" color="accent" class="btn-block" (click)="openMaterialNonDispensationModalFromModal()">
                        <span *ngIf="isLoading == false">Não dispensar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
                <div class="col-12 col-md-4">
                    <button mat-flat-button type="submit" color="primary" class="btn-block">
                        <span *ngIf="isLoading == false">Liberar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>