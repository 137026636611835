import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CpfTotemComponent } from './cpf-totem.component';
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PatientNotFoundComponent } from './components/patient-not-found/patient-not-found.component';
import { PatientFoundComponent } from './components/patient-found/patient-found.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PatientArrivedEarlyLateModalComponent } from './components/patient-arrived-early-late-modal/patient-arrived-early-late-modal.component';
import { PatientWithoutSchedulingModalComponent } from './components/patient-without-scheduling-modal/patient-without-scheduling-modal.component';

@NgModule({
  declarations: [CpfTotemComponent, PatientNotFoundComponent, PatientFoundComponent, PatientArrivedEarlyLateModalComponent, PatientWithoutSchedulingModalComponent],
  exports: [CpfTotemComponent, PatientNotFoundComponent, PatientFoundComponent, PatientArrivedEarlyLateModalComponent],
  imports: [
    CommonModule,
    NgxMaskModule.forRoot(),
    SharedModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule
  ]
})
export class CpfTotemModule { }
