import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { PostSyncMeasurerRequest } from '../../requests/orchestrator-patient/post-sync-measurer.request';
import { GetOneSyncMeasurerResponse } from '../../responses/orchestrator-patient/get-one-sync-measurer.response';
import { GetPatientByCpfResponse } from '../../responses/orchestrator-patient/get-patient-by-cpf.response';
import { ListSyncMeasurerResponse } from '../../responses/orchestrator-patient/list-sync-measurer.response';

@Injectable({
  providedIn: 'root'
})
export class CallSyncMeasurerService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listSyncMeasurer(patientName: string, syncMeasurerHash: string, isActive: boolean, beginPeriod: Date, endPeriod: Date): Observable<ListSyncMeasurerResponse>{
    let uri = `CallSyncMeasurer?`;

    if(patientName) uri = uri +`patientName=${patientName}&`;
    if(syncMeasurerHash) uri = uri +`syncMeasurerHash=${syncMeasurerHash}&`;
    if(isActive) uri = uri +`isActive=${isActive}&`;
    if(beginPeriod) uri = uri +`beginPeriod=${beginPeriod.toISOString()}&`;
    if(endPeriod) uri = uri +`endPeriod=${endPeriod.toISOString()}&`;

    return this.httpClient.get<ListSyncMeasurerResponse>(environment.urlApiOrchestratorPatient + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public getSyncMeasurer(idSyncMeasurer: number): Observable<GetOneSyncMeasurerResponse>{
    let uri = `CallSyncMeasurer/idSyncMeasurer/${idSyncMeasurer}`;

    return this.httpClient.get<GetOneSyncMeasurerResponse>(environment.urlApiOrchestratorPatient + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public createSyncMeasurer(postSyncMeasurerRequest: PostSyncMeasurerRequest): Observable<ReturnStruct>{
    let uri = `CallSyncMeasurer`;

    return this.httpClient.post<ReturnStruct>(environment.urlApiOrchestratorPatient + uri, postSyncMeasurerRequest,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public updateSyncMeasurer(idSyncMeasurer: number, postSyncMeasurerRequest: PostSyncMeasurerRequest): Observable<ReturnStruct>{
    let uri = `CallSyncMeasurer/idSyncMeasurer/${idSyncMeasurer}`;

    return this.httpClient.put<ReturnStruct>(environment.urlApiOrchestratorPatient + uri, postSyncMeasurerRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
