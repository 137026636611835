import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PatientStruct } from 'src/app/shared/services/structs/medical-record/patient.struct';
import { SigaPatientStruct } from 'src/app/shared/services/structs/siga-front-desk/siga-patient.struct';

@Component({
  selector: 'app-siga-select-patient-modal',
  templateUrl: './siga-select-patient-modal.component.html',
  styleUrls: ['./siga-select-patient-modal.component.css']
})
export class SigaSelectPatientModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<SigaSelectPatientModalComponent>
  ) { }

  public isLoading:boolean;

  ngOnInit(): void {
    this.isLoading = false;
  }
  
  close(){
    this.matDialogRef.close(); 
  }
  
  clickSelect(patient:SigaPatientStruct){
    this.matDialogRef.close(patient);
  }
  
  clickCancel(){
    this.matDialogRef.close(); 
  }
}

