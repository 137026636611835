<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <h1>{{dashboard != null ? dashboard.dashboardName : ""}}</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <div class="row">
                <div class="col-md-6">
                    <mat-spinner [diameter]="20" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </div>
                <div class="col-md-2">
                    <a (click)="getDashboard(true)" class="graph-icons">
                        <mat-icon aria-hidden="false" aria-label="Recarregar">loop</mat-icon>
                        <span>Recarregar</span>
                    </a>
                </div>
                <div class="col-md-2">
                    <button mat-flat-button color="primary" class="btn-block" (click)="openModal(null)">
                        <span>Adicionar gráfico</span>
                    </button>
                </div>
                <div class="col-md-2">
                    <!-- <a (click)="makeFullscreen()" class="graph-icons">
                    <mat-icon aria-hidden="false" aria-label="Tela cheia">
                        fullscreen</mat-icon>
                    <span>Tela Cheia</span>
                </a> -->
                </div>
            </div>
            <div class="graph-body">
                <app-plot-graph (openModalExclude)="openModalExclude($event)" (openModal)="openModal($event)"
                    *ngIf="(!isFirstLoading && dashboardResponse && !isLoading || isReload)"
                    [dashboardResponse]="dashboardResponse" class="graph-content">
                </app-plot-graph>
            </div>
        </div>
    </div>
</div>