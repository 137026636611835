import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { AuditCardUpdateControlService } from 'src/app/shared/services/API/audit/audit-card-update-control.service';
import { AuditCardUpdateControl } from 'src/app/shared/services/models/audit/audit-card-update-controle.model';

@Component({
  selector: 'app-audit-history-card-modal',
  templateUrl: './audit-history-card-modal.component.html',
  styleUrls: ['./audit-history-card-modal.component.css']
})
export class AuditHistoryCardModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private auditCardUpdateControlService: AuditCardUpdateControlService,
    public matDialogRef: MatDialogRef<AuditHistoryCardModalComponent>,
    private alertService: AlertService,
  ) { }

  public isLoading: boolean;
  public idClassificationRiskAudit: number;
  public listAuditCardUpdateControl: AuditCardUpdateControl[];

  ngOnInit(): void {
    this.idClassificationRiskAudit = this.data.idClassificationRiskAudit;
    this.isLoading = false;

    this.getHistory(this.idClassificationRiskAudit);
  }

  clickCancel() {
    this.matDialogRef.close();
  }

  getHistory(idClassificationRiskAudit: number) {
    this.auditCardUpdateControlService.getAuditCardUpdateControl(idClassificationRiskAudit).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listAuditCardUpdateControl = response.listAuditCardUpdateControl;
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}