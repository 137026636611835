<div mat-dialog-content>
    <div class="mat-dialog-header">
        <h3>Prescrição Não Padronizada</h3>
    </div>
    <form class="form" [formGroup]="prescriptionModel" (ngSubmit)="onSubmit()">
        <div class="mat-dialog-content">
            <div class="row">
                <div class="col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Descrição</mat-label>
                        <input [readonly]="this.data.readOnly" matInput formControlName="description">
                    </mat-form-field>
                </div>  
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Quantidade</mat-label>
                        <input formControlName="quantity" matInput [readonly]="this.data.readOnly" type="text">
                    </mat-form-field>
                </div> 
                <div class="col-12 col-sm-12 col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Unidade</mat-label>
                        <mat-select formControlName="idMeasurementUnit">
                            <mat-option *ngFor="let item of measurementUnitList" [value]="item.idMeasurementUnit">
                                {{item.measurementUnitName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>       
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Frequência</mat-label>
                        <input [readonly]="this.data.readOnly" matInput formControlName="frequency">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Observação</mat-label>
                        <textarea [readonly]="this.data.readOnly" matInput formControlName="observation"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="footer-panel-modal-list">
                <div class="row">
                    <div *ngIf="!this.data.readOnly" class="col-6">
                        <a mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Cancelar</a>
                    </div>
                    <div *ngIf="!this.data.readOnly" class="col-6">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span>Salvar</span>
                        </button>
                    </div>
                    <div class="col-12">
                        <a *ngIf="this.data.readOnly" mat-flat-button color="primary" class="btn-block"
                            (click)="clickCancel()">Ok</a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>