import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ClinicalObservationPatientService } from 'src/app/shared/services/API/orchestrator-patient/clinical-observation.service';
import { ClinicalObservationService } from 'src/app/shared/services/API/sync-measurer/clinical-observation.service';
import {  MaskService } from 'src/app/shared/services/mask.service';
import { ClinicalObservationRequest } from 'src/app/shared/services/requests/orchestrator-patient/clinical-observation.request';
import { ClinicalObservationStruct } from 'src/app/shared/services/structs/orchestrator-patient/clinical-observation.struct';

@Component({
  selector: 'app-patient-clinical-observation-modal',
  templateUrl: './patient-clinical-observation-modal.component.html',
  styleUrls: ['./patient-clinical-observation-modal.component.css']
})
export class PatientClinicalObservationModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<PatientClinicalObservationModalComponent>,
    private formBuilder: UntypedFormBuilder,
    public maskService: MaskService,
    private clinicalObservationService: ClinicalObservationService,
    public clinicaObservation: ClinicalObservationPatientService,
    private alertService: AlertService) { }

  public model: UntypedFormGroup;
  public code: string;
  public observation: string;
  public idPatient: number;
  public isLoading: boolean;
  public attendanceObservation: string;
  public listObservation: ClinicalObservationStruct[];
  public clinicalObservationRequest: ClinicalObservationRequest = new ClinicalObservationRequest();

  ngOnInit(): void {
    this.isLoading = false;
    this.model = this.formBuilder.group({
      observation: ['', [Validators.required]],
    });
    this.populateClinicalObservation();
  }
  close() {
    this.matDialogRef.close();
  }


  populateClinicalObservation(){
    this.isLoading = true;
    this.clinicaObservation.get(this.data.patient.idPatient).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.listObservation = response.listObservation;
      this.isLoading = false; 
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  submit() {
    if (this.isLoading) {
      return;
    }
    
    this.isLoading = true;
    this.clinicalObservationRequest.observation = this.model.get("observation").value;
    this.clinicalObservationRequest.idPatient = this.data.patient.idPatient;
    
    this.clinicalObservationService.post(this.clinicalObservationRequest).subscribe((response) => {
      if (response.isError) {
        this.isLoading = false;
        this.alertService.show("Erro", response.errorDescription, AlertType.error);
        return;
      }
      
      this.isLoading = false;
      this.alertService.show("Sucesso", "Observação salva com sucesso!", AlertType.success);
      this.matDialogRef.close();
      
    }, (error) => {
      this.alertService.show("Erro Inesperado", error, AlertType.error);
      this.isLoading = false;
      return;
    })
  }
}