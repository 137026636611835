import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CommonService } from "src/app/shared/services/common-service";
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';
import { catchError } from "rxjs/operators";
import { ReturnStruct } from "src/app/shared/structs/return.struct";
import { ReadMedicalPrescriptionStruct } from "../../structs/orchestrator-patient/read-medical-prescription.struct";
import { NewPrescriptionResponse } from "../../responses/medical-record/new-prescription.response";
import { ListPrescriptionResponse } from "../../responses/medical-record/list-prescription.response";
import { ReportResponse } from "../../responses/orchestrator-patient/report.response";
import { ViewMedicineRequest } from "../../requests/medical-record/view-medicine.request";
import { LiberateSolutionPrescriptionRequest } from "../../requests/orchestrator-patient/liberate-solution-prescription.request";
import { GetMedicalPrescriptionResponse } from "../../responses/medical-record/get-medical-prescription.response";

@Injectable({
  providedIn: 'root'
})
export class MedicalPrescriptionService extends CommonService {
  constructor(private httpClient: HttpClient) { super(); }

  public listPresciption(idEpisode: number, isPrescriptionChecked: boolean): Observable<ListPrescriptionResponse> {
    let uri = `MedicalPrescription/idEpisode/${idEpisode}`

    if (isPrescriptionChecked != null)
      uri += `?isPrescriptionChecked=${isPrescriptionChecked}&`

    return this.httpClient.get<ListPrescriptionResponse>(environment.urlApiMedicalRecord + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public getPresciptionReports(viewMedicineRequest: ViewMedicineRequest): Observable<ReportResponse> {
    let uri = `MedicalPrescription/viewMedicine`

    return this.httpClient.post<ReportResponse>(environment.urlApiMedicalRecord + uri, viewMedicineRequest, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
  public createPresciption(idMedicalCare: number, body: ReadMedicalPrescriptionStruct): Observable<NewPrescriptionResponse> {
    let uri = `MedicalPrescription/idMedicalCare/${idMedicalCare}`

    return this.httpClient.post<NewPrescriptionResponse>(environment.urlApiMedicalRecord + uri, body, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public updatePresciption(body: ReadMedicalPrescriptionStruct): Observable<ReturnStruct> {
    let uri = `MedicalPrescription`

    return this.httpClient.put<ReturnStruct>(environment.urlApiMedicalRecord + uri, body, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public deletePresciption(idMedicalPrescription: number): Observable<ReturnStruct> {
    let uri = `MedicalPrescription/idMedicalPrescription/${idMedicalPrescription}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiMedicalRecord + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public deleteMedicatedSolution(listIdMedicalPrescription: number[]): Observable<ReportResponse> {
    let uri = `MedicalPrescription/listIdMedicalPrescription/${JSON.stringify(listIdMedicalPrescription)}`

    return this.httpClient.get<ReportResponse>(environment.urlApiMedicalRecord + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public getLastPresciptions(idEpisode: number): Observable<ListPrescriptionResponse> {
    let uri = `MedicalPrescription/getPrescriptions/idEpisode/${idEpisode}`

    return this.httpClient.get<ListPrescriptionResponse>(environment.urlApiMedicalRecord + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public updatePrescriptionSolution(body: LiberateSolutionPrescriptionRequest): Observable<ReturnStruct> {
    let uri = `MedicalPrescription/prescriptionSolution`

    return this.httpClient.put<ReturnStruct>(environment.urlApiMedicalRecord + uri, body, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public GetListPrescriptionInternalOpenByIdEpisode(idEpisode: number): Observable<GetMedicalPrescriptionResponse> {
    let uri = `MedicalPrescription/PrescriptionInternal/idEpisode/${idEpisode}`

    return this.httpClient.get<GetMedicalPrescriptionResponse>(environment.urlApiMedicalRecord + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}