<div mat-dialog-content>
    
    <div class="mat-dialog-header">
        <h3>Prescrições Padronizadas</h3>
        <mat-slide-toggle [disabled]="this.readOnly" class="medicated_solution" [checked]="isMedicatedSolution" (change)="changeToMedicatedSolution($event)">
            Montar solução
        </mat-slide-toggle>
    </div>

    <div class="mat-dialog-content">
        <app-medicine-form *ngIf="!isMedicatedSolution"
            [blockAllergicPrescription]="this.data.blockAllergicPrescription"
            [isFastTrack]="this.isFastTrack"
            [readOnly]="this.readOnly"
            [medicalPrescription]="this.medicalPrescription?.readMedicalPrescriptionStruct"
            [listAllergy]="this.listAllergy"
            [selectedMedicineList]="this.selectedMedicineList"
            [medicineList]="this.medicineList"
            [listSubFrequency]="this.listSubFrequency"
            [frequencyList]="this.frequencyList"
            [presentationList]="this.presentationList"
            [treatmentList]="this.treatmentList"
            [dilutionList]="this.dilutionList"
            [measurementUnitList]="this.measurementUnitList"
            [usageList]="this.usageList"
            [administrationTypeList]="this.administrationTypeList"
            [lookupMedicalCareResponse]="this.lookupMedicalCareResponse"
            [medicConfig]="this.medicConfig"
            [listMedicine]="this.listMedicine"
            [dilutionUnitList]="this.dilutionUnitList"
            (save)="submit($event)"
            (cancel)="close()"
            [hasNewStandardPrescription]="this.data.hasNewStandardPrescription">
        </app-medicine-form>
        <app-medicated-solution-form *ngIf="isMedicatedSolution"
            [blockAllergicPrescription]="this.data.blockAllergicPrescription"
            [isFastTrack]="this.isFastTrack"
            [readOnly]="this.readOnly"
            [medicatedSolutionStruct]="this.medicalPrescription.medicatedSolutionStruct"
            [listAllergy]="this.listAllergy"
            [selectedMedicineList]="this.selectedMedicineList"
            [medicineList]="this.medicineList"
            [listSubFrequency]="this.listSubFrequency"
            [frequencyList]="this.frequencyList"
            [presentationList]="this.presentationList"
            [treatmentList]="this.treatmentList"
            [dilutionList]="this.dilutionList"
            [measurementUnitList]="this.measurementUnitList"
            [usageList]="this.usageList"
            [administrationTypeList]="this.administrationTypeList"
            [lookupMedicalCareResponse]="this.lookupMedicalCareResponse"
            [medicConfig]="this.medicConfig"
            [listMedicine]="this.listMedicine"
            [dilutionUnitList]="this.dilutionUnitList"
            (save)="submit($event)"
            (cancel)="close()"
            [hasNewStandardPrescription]="this.data.hasNewStandardPrescription">
        </app-medicated-solution-form>
    </div>

</div>