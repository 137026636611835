<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-flow-modal-list">
            <h2>Deseja cancelar este atendimento?</h2>
        </div>

        <div class="body-flow-modal-list row">
            <div class="col-md-12">
                Você realmente deseja cancelar este Atendimento? Você perderá todas as alterações realizadas e essa
                opção é irreversível.
            </div>
            <div class="col-md-12" *ngIf="data.guideWasUpdate">
                <b>Atenção: </b>As alterações realizadas nas guias também serão perdidas.
            </div>
        </div>
        <div class="footer-flow-modal-list">
            <button mat-flat-button color="primary" class="btn-block" (click)="clickConfirm()">
                <span>Sim, cancelar</span>
            </button>
            <button mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Não</button>
        </div>
    </div>
</div>