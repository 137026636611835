<div class="priority-info">
    <h2 class="header__h2">Você tem prioridade prevista em Lei?</h2>
    <h4 class="header__h4">Selecione as opções</h4>
</div>
<div *ngIf="listFilteredTotemPriority.length < 1"
    [ngClass]="{'priority-list-two-columns': listPriority.length >= 5, 'priority-list-simple': listPriority.length < 5}">
    <button mat-flat-button (click)="selectOption(priority)" color="{{priority.selected?'primary':'accent'}}"
        class="btn-totem-priority" *ngFor="let priority of listPriority">
        <mat-icon *ngIf="priority.idPriority !== 1 && priority.idPriority !== 2 && priority.idPriority !== priorityAutism">{{priority.icon}}</mat-icon>
        <mat-icon class="icon-autism" *ngIf="priority.idPriority == priorityAutism"><img src="/assets/images/autism-symbol.png" aria-hidden="false" aria-label="autista - TEA"></mat-icon>
        <span class="icon" *ngIf="priority.idPriority === 1 || priority.idPriority === 2">{{priority.icon}}</span>
        <span class="text">{{priority.priorityName}}</span>
    </button>
</div>
<div *ngIf="listFilteredTotemPriority.length >= 1"
    [ngClass]="{'priority-list-two-columns': listFilteredTotemPriority.length >= 5, 'priority-list-simple': listFilteredTotemPriority.length < 5}">
    <button mat-flat-button (click)="selectOption(priority)" color="{{priority.selected?'primary':'accent'}}"
        class="btn-totem-priority" *ngFor="let priority of listFilteredTotemPriority" [id]="priority.idPriority">
        <mat-icon *ngIf="priority.idPriority !== 1 && priority.idPriority !== 2 && priority.idPriority !== priorityAutism">{{priority.icon}}</mat-icon>
        <mat-icon class="icon-autism" *ngIf="priority.idPriority == priorityAutism"><img src="/assets/images/autism-symbol.png" aria-hidden="false" aria-label="autista - TEA"></mat-icon>
        <span class="icon" *ngIf="priority.idPriority === 1 || priority.idPriority === 2">{{priority.icon}}</span>
        <span class="text">{{priority.priorityName}}</span>
    </button>
</div>
<div class="footer">
    <div class="row">
        <div class="col-6 col-md-6">
            <button mat-flat-button (click)="back()" color="accent" class="btn-block">
                <span>Voltar</span>
            </button>
        </div>
        <div class="col-6 col-md-6">
            <button mat-flat-button (click)="print()" color="primary" class="btn-block">
                <span *ngIf="isLoading == false">Imprimir senha</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
        </div>
    </div>
</div>