<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <h1>Histórico</h1>
        <div class="white-body">
            <form class="form" [formGroup]="model" (ngSubmit)="buttonSearch()">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Número do Episódio</mat-label>
                            <input matInput type="text" formControlName="idEpisode" oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>CPF</mat-label>
                            <input matInput [mask]="masks.cpf" type="text" formControlName="CPF">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>CNS</mat-label>
                            <input matInput type="text" formControlName="CNS">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome do Paciente</mat-label>
                            <input matInput type="text" formControlName="patientName">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Data de Nascimento</mat-label>
                            <input matInput [mask]="masks.date" type="text" formControlName="birthdate">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Data Inicial</mat-label>
                            <input matInput [mask]="masks.date" type="text" formControlName="initialDate">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Data Final</mat-label>
                            <input matInput [mask]="masks.date" type="text" formControlName="finalDate">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Gravidade</mat-label>
                            <mat-select formControlName="idGravity">
                                <mat-option></mat-option>
                                <mat-option *ngFor="let item of listGravity" value="{{item.idGravity}}">
                                    {{item.gravityName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Status</mat-label>
                            <mat-select formControlName="idOutcome">
                                <mat-option></mat-option>
                                <mat-option *ngFor="let item of listOutcome" value="{{item.idStatus}}">
                                    {{item.statusName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <button mat-flat-button color="primary" class=" btn-block" type="submit">
                            <span *ngIf="!isLoading">Pesquisar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                        </button>
                    </div>
                </div>
            </form>
            <div class="card-list" *ngIf="listHistory != null">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listHistory">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary">{{item.idEpisode}} - {{item.patientName}}</span>
                                <span class="secundary">Data de inclusão: {{item.datetimeInclusion | date:'dd/MM/yyyy HH:mm:ss'}}</span>
                                <span class="secundary">Data de nascimento: {{item.birthdate | date:'dd/MM/yyyy'}}</span>
                            </div>
                            <div class="actions">
                                <a>
                                    <mat-icon aria-hidden="false" aria-label="Visualizar"
                                        [routerLink]="['/patient-history/details', {idEpisode: item.idEpisode}]">
                                        visibility
                                    </mat-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <app-empty-list *ngIf="listHistory != undefined && listHistory.length == 0 && !isLoading"></app-empty-list>
            <div *ngIf="!isLoading" class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-paginator [length]="fullSize" #paginator [pageIndex]="pageIndex" [pageSize]="50" aria-label="Select page"
                        (page)="changePage($event)" [hidePageSize]="true">
                    </mat-paginator>
                </div>
            </div>
        </div>
    
        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>
    
</div>