import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { LocationByModuleService } from 'src/app/shared/services/API/flow/location-by-module.service';
import { ServiceService } from 'src/app/shared/services/API/flow/service.service';
import { AreaRankingService } from 'src/app/shared/services/API/gamification/area-ranking.service';
import { AreaService } from 'src/app/shared/services/API/gamification/area.service';
import { GamificationService } from 'src/app/shared/services/API/orchestrator-patient/gamification.service';
import { MaskService } from 'src/app/shared/services/mask.service';
import { AreaRankingModel } from 'src/app/shared/services/models/gamification/area-ranking.model';
import { AreaRequest } from 'src/app/shared/services/requests/gamification/area.request';
import { SectorStruct } from 'src/app/shared/services/structs/flow/sector.struct';
import { ServiceStruct } from 'src/app/shared/services/structs/flow/service.struct';

@Component({
  selector: 'app-gamification-register',
  templateUrl: './gamification-register.component.html',
  styleUrls: ['./gamification-register.component.css']
})
export class GamificationRegisterComponent implements OnInit {

  constructor(private formBuilder: UntypedFormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private areaService: AreaService,
    private serviceService: ServiceService,
    private locationByModuleService: LocationByModuleService,
    private areaRankingService: AreaRankingService,
    private gamificationService: GamificationService) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.master;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.master_gamification_register;
  public model: UntypedFormGroup;

  public isLoading: boolean;
  public isFirstLoading: boolean;
  public showNames: boolean;
  public showRanking: boolean;
  public isUpdate: boolean;
  public isFirstSelect: boolean;
  public idService: number;

  public idArea: number;
  public datetimeInclusionArea: Date;
  public lastDateValidate: Date;

  public listService: ServiceStruct[];
  public listSector: SectorStruct[];
  public listIdSector: number[];
  public listSectorByService: SectorStruct[];
  public listAreaRanking: AreaRankingModel[];

  ngOnInit(): void {
    this.isLoading = false;

    this.model = this.formBuilder.group({
      name: ['', [Validators.required]],
      description: [''],
      listService: ['', [Validators.required]],
      status: ['', [Validators.required]],
      listIdSector: [''],
    });

    this.idArea == null;
    this.isUpdate == false;

    this.populateServiceSelect();

    if (this.activatedRoute.snapshot.paramMap.get('idArea')) {
      this.idArea = parseInt(this.activatedRoute.snapshot.paramMap.get('idArea'));
      this.populateAreaRanking();
    }
  }

  submit() {
    if (this.isLoading)
      return;


    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    this.isLoading = true;

    let areaRegisterRequest: AreaRequest = new AreaRequest();

    areaRegisterRequest.idService = this.model.get('listService').value;
    areaRegisterRequest.showNames = this.showNames;
    areaRegisterRequest.showRanking = this.showRanking;
    areaRegisterRequest.isActive = this.model.get('status').value == "true" ? true : false;
    areaRegisterRequest.areaDescription = this.model.get('description').value;
    areaRegisterRequest.areaName = this.model.get('name').value;
    areaRegisterRequest.listIdSector = this.model.get('listIdSector').value;

    if (this.isUpdate)
      this.updateArea(areaRegisterRequest);
    else
      this.createArea(areaRegisterRequest);
  }

  populateServiceSelect() {
    this.serviceService.listAllService().subscribe({
      next:(response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        if (response.listService && response.listService.length > 0)
          this.listService = response.listService.filter(x => x.idModule == MenuModuleEnum.classification);
  
        this.populateSectorSelect();
        this.isLoading = false;
      },
      error:(error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateSectorSelect() {
    this.locationByModuleService.listRoomByModule(MenuModuleEnum.classification).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listSectorByService = response.sectors;
      if (this.idArea != null) {
        this.isFirstLoading = true;
        this.isUpdate = true;
        this.populateAreaData();
      }
      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  populateAreaRanking() {
    this.areaRankingService.getAreaRanking(this.idArea).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.listAreaRanking = response.listAreaRanking;
      this.lastDateValidate = response.lastDateValidate;
      this.listAreaRanking.forEach(x => {
        let averageTimeSecond = new Date(x.averageTimeSecond * 1000);
        let hours = averageTimeSecond.getUTCHours();
        let minutes = averageTimeSecond.getUTCMinutes();
        let seconds = averageTimeSecond.getSeconds();

        x.averageTime = hours.toString().padStart(2, '0') + ':' +
          minutes.toString().padStart(2, '0') + ':' +
          seconds.toString().padStart(2, '0');
      });
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  updateArea(areaRequest: AreaRequest) {
    this.areaService.updateArea(this.idArea, areaRequest).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/master/gamification']);
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  createArea(areaRequest: AreaRequest) {
    this.areaService.createArea(areaRequest).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/master/gamification']);
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  selectService(event: any) {
    if (event != null) {
      this.isFirstSelect = true;
      this.listSector = this.listSectorByService.filter(x => x.idService)
    }
  }

  populateAreaData() {
    this.areaService.getAreaSector(this.idArea).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.model.get('status').setValue(response.isActive.toString());
      this.model.get('description').setValue(response.areaDescription);
      this.model.get('name').setValue(response.areaName);
      this.showNames = response.showNames;
      this.showRanking = response.showRanking;
      this.model.get('listService').setValue(response.idService.toString());
      if (response.idService)
        this.selectService(response.idService)
      this.model.get('listIdSector').setValue(response.listIdSector.map(c => c.toString()));

      this.datetimeInclusionArea = response.datetimeInclusion;

      this.isLoading = false;
      this.isFirstLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  toggleShowRanking(event: any) {
    if (event?.checked ?? false)
      this.showRanking = true;
    else
      this.showRanking = false;
  }

  toggleShowNames(event: any) {
    if (event?.checked ?? false)
      this.showNames = true;
    else
      this.showNames = false;
  }
}