import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-blocked-days-delete-modal',
  templateUrl: './blocked-days-delete-modal.component.html',
  styleUrls: ['./blocked-days-delete-modal.component.css']
})
export class BlockedDaysDeleteModalComponent {
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<BlockedDaysDeleteModalComponent>,
  ) { }

  public isLoading:boolean;  

  ngOnInit(): void {
    this.isLoading = false;
  }
  
  close(){
    this.matDialogRef.close({deleteBlockedDay: false}); 
  }
  
  clickDelete(){
    this.matDialogRef.close({deleteBlockedDay: true});
  }
  
  clickCancel(){
    this.matDialogRef.close({deleteBlockedDay: false}); 
  }
}
