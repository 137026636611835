import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { GetAllAuditRequest } from '../../requests/audit/get-all-audit.request';
import { PostClassificationRiskAuditRequest } from '../../requests/audit/post-classification-risk-audit.request';
import { PutClassificationRiskAuditTriageRequest } from '../../requests/audit/put-classification-risk-audit-triage.request';
import { PutClassificationRiskAuditRequest } from '../../requests/audit/put-classification-risk-audit.request';
import { GetAuditReponse } from '../../responses/audit/get-audit.response';
import { ListAllAuditsResponse } from '../../responses/audit/list-all-audits.response';
import { UpdateAuditReponse } from '../../responses/audit/update-audit.response';

@Injectable({
  providedIn: 'root'
})
export class AuditService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getAudit(idAudit: number): Observable<GetAuditReponse> {
    
    let uri = `Audit/idAudit/${idAudit}`;
    
    return this.httpClient.get<GetAuditReponse>(environment.urlApiAudit + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public listAllAudit(request: GetAllAuditRequest): Observable<ListAllAuditsResponse> {
    
    let uri = 'Audit/getAll'
    
    return this.httpClient.post<ListAllAuditsResponse>(environment.urlApiAudit + uri, request, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public createAudit(request: PostClassificationRiskAuditRequest): Observable<ReturnStruct> {
    
    let uri = 'Audit'

    return this.httpClient.post<ReturnStruct>(environment.urlApiAudit + uri, request, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public updateAudit(request: PutClassificationRiskAuditTriageRequest): Observable<UpdateAuditReponse> {
    
    let uri = 'Audit'

    return this.httpClient.put<UpdateAuditReponse>(environment.urlApiAudit + uri, request, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public updateAuditStatus(request: PutClassificationRiskAuditRequest): Observable<UpdateAuditReponse> {
    
    let uri = 'Audit/updateStatus'

    return this.httpClient.put<UpdateAuditReponse>(environment.urlApiAudit + uri, request, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public deleteAudit(idAudit: number): Observable<ReturnStruct>{

    let uri = `Audit/idAudit/${idAudit}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiAudit + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
