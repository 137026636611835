import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { VirtualTriageConfigDeleteModalComponent } from './virtual-triage-config-delete-modal/virtual-triage-config-delete-modal.component';
import { VirtualTriageConfig } from 'src/app/shared/services/models/virtual-triage/virtual-triage-config.model';
import { VirtualTriageConfigService } from 'src/app/shared/services/API/virtual-triage/virtual-triage-config.service';

@Component({
  selector: 'app-virtual-triage-config-list',
  templateUrl: './virtual-triage-config-list.component.html',
  styleUrls: ['./virtual-triage-config-list.component.css']
})
export class VirtualTriageConfigListComponent implements OnInit {

  constructor(
    private virtualTriageConfigService: VirtualTriageConfigService,
    private alertService: AlertService,
    public dialog: MatDialog
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.digital_prompt_service;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.virtual_triage_config;

  public listVirtualTriageConfig: VirtualTriageConfig[];
  
  public isLoading: boolean;
  public isActive: boolean;

  public searchText: string;

  ngOnInit(): void {
    this.isLoading = true;
    this.search();
  }
  search() {
    this.isLoading = true;
    if (this.searchText == "")
      this.searchText = null;

    this.virtualTriageConfigService.listAllVirtualTriageConfig(this.searchText).subscribe({
      next: (response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.listVirtualTriageConfig = response.list;
      this.isLoading = false;
    },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }

  openModal(idVirtualTriageConfig) {
    const dialogRef = this.dialog.open(VirtualTriageConfigDeleteModalComponent, {
      data: {
        idVirtualTriageConfig: idVirtualTriageConfig
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.deleteVirtualTriageConfig) {
        this.search();
      }
    });
  }

}
