import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { GetAllUserEvenDeletedResponse } from '../../responses/user/get-all-user-even-deleted.response';

@Injectable({
  providedIn: 'root'
})
export class GetAllUserEvenDeletedService extends CommonService{
  
  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getAllUserEvenDeleted(): Observable<GetAllUserEvenDeletedResponse> {

    let uri = `GetAllUserEvenDeleted/getAll`;

    return this.httpClient.get<GetAllUserEvenDeletedResponse>(environment.urlApiUser + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getAllUserAuditorEvenDeleted(): Observable<GetAllUserEvenDeletedResponse> {

    let uri = `GetAllAuditor`;

    return this.httpClient.get<GetAllUserEvenDeletedResponse>(environment.urlApiUser + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
