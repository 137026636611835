export class PostTelemedicineSmsRequest{
    
    public phone1: string;

    public url: string;

    public patientName: string;
    
    public socialName: string;
    
    public token: string;

    public genderName: string;

    public cpf: string;

    public cns: string;

    public city: string;

    public street: string;

    public houseNumber: number;    
    
    public birthDate: Date;

    public apartmentNumber: number;

    public state: string;

    public country: string;

    public neighborhood: string;
    
    public zipCode: string;

    public baseUrl: string;

    public idPatient: number;

    public isCheckedTelemedicine: boolean;
}