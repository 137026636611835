import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ProcedureResponse } from '../../responses/medic/procedure-response';
import { ListProcedureResponse } from '../../responses/medic/list-procedure-response';
import { ProcedureRequest } from '../../requests/medic/procedure-request';

@Injectable({
  providedIn: 'root'
})
export class ProcedureService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getProcedure(idProcedure: number): Observable<ProcedureResponse> {
    
    let uri = `Procedure/idProcedure/${idProcedure}`;
    
    return this.httpClient.get<ProcedureResponse>(environment.urlApiMedic + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public listProcedure(searchText: string, isActive: boolean): Observable<ListProcedureResponse> {
    
    let uri = 'Procedure?';

    if(searchText) uri += `searchText=${searchText}&`;

    if(isActive) uri += `isActive=${isActive}&`;
    
    return this.httpClient.get<ListProcedureResponse>(environment.urlApiMedic + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public listAllProcedure(): Observable<ListProcedureResponse> {
    
    let uri = 'Procedure/getAll';
    
    return this.httpClient.get<ListProcedureResponse>(environment.urlApiMedic + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public createProcedure(request: ProcedureRequest): Observable<ReturnStruct> {
    
    let uri = 'Procedure'

    return this.httpClient.post<ReturnStruct>(environment.urlApiMedic + uri, request, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public updateProcedure(idProcedure: number, request: ProcedureRequest): Observable<ReturnStruct> {
    
    let uri = `Procedure/idProcedure/${idProcedure}`;

    return this.httpClient.put<ReturnStruct>(environment.urlApiMedic + uri, request, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public deleteProcedure(idProcedure: number): Observable<ReturnStruct>{

    let uri = `Procedure/idProcedure/${idProcedure}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiMedic + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public getProceduresByCbo(): Observable<ListProcedureResponse>{
    let uri = `ListProcedureByCbo`

    return this.httpClient.get<ListProcedureResponse>(environment.urlApiMedic + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
}
}