<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <a class="back-link" routerLink="/flow-management/kpi-config-list">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> KPIs
        </a>
        <h1>Configurações de regras</h1>
        <app-loading-progress-bar *ngIf="isLoading"></app-loading-progress-bar>
        <div class="white-body">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="title-header">
                    <h1>Cadastro de regras</h1>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field *ngIf="listSector" appearance="outline">
                            <mat-label>Setor</mat-label>
                            <mat-select formControlName="idSector">
                                <mat-option *ngFor="let item of listSector" [value]="item.idSector"
                                    [disabled]="listBlockedSectors.includes(item.idSector)" (click)="verifySector(item)">{{item.sectorName}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idSector').invalid">Informe o setor</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-slide-toggle (ngModelChange)="setRequirements()" formControlName="isGravitySpecific">
                            Usar gravidade
                        </mat-slide-toggle>
                    </div>
                </div>
                <div class="title-header">
                    <h1>Metas</h1>
                </div>
                <div class="info">
                    <mat-icon class="material-icons material-icons-outlined">info</mat-icon>
                    <span [ngStyle]="{'margin': '0px 0px 0px 10px'}">
                        Defina quanto tempo antes da meta o paciente entrará na seção de "Alerta" no menu "Gestão
                        de Fluxo".
                    </span>
                </div>
                <div *ngIf="model.get('isGravitySpecific').value">
                    <div formArrayName="listGravityKpi"
                        *ngFor="let gravity of model.get('listGravityKpi')['controls']; let i = index">
                        <div class="row" [formGroupName]="i">
                            <div class="col-2 col-sm-2 col-md-2" *ngIf="listGravity && listGravity.length > 0">
                                <mat-form-field appearance="outline">
                                    <mat-label>Gravidade</mat-label>
                                    <div class="gravity">
                                        <div class="bullet" [style.background-color]="gravity.get('rgb').value"></div>
                                        <input readonly matInput formControlName="gravityName">
                                    </div>
                                </mat-form-field>
                            </div>
                            <div class="col-6 col-sm-6 col-md-3">
                                <mat-form-field *ngIf="listTimes" appearance="outline">
                                    <mat-label>Meta</mat-label>
                                    <mat-select (selectionChange)="setGravityAlerts(i)"
                                        formControlName="gravityKpiGoal">
                                        <mat-option *ngFor="let time of listTimes"
                                            [value]="time.idKpiTimeLookup">{{time.description}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="gravity.get('gravityKpiGoal').invalid">Informe o tempo</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-6 col-sm-6 col-md-3">
                                <mat-form-field *ngIf="listTimes" appearance="outline">
                                    <mat-label>Alerta</mat-label>
                                    <mat-select formControlName="gravityKpiAlert">
                                        <mat-option *ngFor="let time of listTimes" [value]="time.idKpiTimeLookup"
                                            [disabled]="alertTimesControl.get(gravity.value.gravityName) && alertTimesControl.get(gravity.value.gravityName).includes(time.idKpiTimeLookup)">
                                            {{time.description}} antes</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="gravity.get('gravityKpiAlert').invalid">Informe o
                                        tempo</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!model.get('isGravitySpecific').value">
                    <div class="row">
                        <div class="col-2 col-sm-2 col-md-2" *ngIf="listGravity && listGravity.length > 0">
                            <mat-form-field appearance="outline">
                                <mat-label>Gravidade</mat-label>
                                <input readonly matInput formControlName="globalGravity">
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-6 col-md-3">
                            <mat-form-field *ngIf="listTimes" appearance="outline">
                                <mat-label>Meta Global</mat-label>
                                <mat-select (selectionChange)="setGlobalAlerts()" formControlName="globalKpiGoal">
                                    <mat-option *ngFor="let time of listTimes"
                                        [value]="time.idKpiTimeLookup">{{time.description}}</mat-option>
                                </mat-select>
                                <mat-error *ngIf="model.get('globalKpiGoal').invalid">Informe o tempo</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-6 col-md-3">
                            <mat-form-field *ngIf="listTimes" appearance="outline">
                                <mat-label>Alerta Global</mat-label>
                                <mat-select formControlName="globalKpiAlert">
                                    <mat-option *ngFor="let time of listTimes" [value]="time.idKpiTimeLookup"
                                        [disabled]="alertTimesControl.get('Global') && alertTimesControl.get('Global').includes(time.idKpiTimeLookup)">
                                        {{time.description}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="model.get('globalKpiAlert').invalid">Informe o
                                    tempo</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <button mat-flat-button type="submit" color="primary" class="btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <a mat-flat-button type="button" routerLink="/flow-management/kpi-config-list" color="accent"
                            class=" btn-block">
                            Cancelar
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>