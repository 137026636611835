<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="img-head-modal">
            <img src="assets/images/exclamation-circle.png" />
        </div>
        <div class="title-modal">
            <h1><b>Tem certeza que deseja prosseguir?</b></h1>
        </div>
         
        <div class="body-modal">
            <p>Os motivos excluidos ainda continuarão sendo exibidos para auditorias já criadas e não concluidas.</p>
        </div>
        <div class="buttons-modal">
            <button mat-flat-button color="accent" class="btn-block btn-confirm" (click)="confirmDelete()">
                <span>SIM, CONTINUAR</span>
            </button>
            <button mat-flat-button color="primary" class="btn-block btn-cancel" (click)="close()">
                <span>NÃO, VOLTAR</span>
            </button>
        </div>
        <div class="footer-modal">
            <input type="checkbox" id="checkbox" name="checkbox" class="checkbox" (click)="clickCheckbox()">
            <label for="checkbox" class="label">Não mostrar essa mensagem novamente.</label>
        </div>
    </div>
</div>

