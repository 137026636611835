<div class="white-body">
    <form class="form" [formGroup]="model">
        <div class="row">
            <div id="config-result" class="col-md-4">Resultados</div>
            <div class="col-md-8">
                <mat-form-field appearance="fill">
                    <mat-label>Insira um intervalo de datas</mat-label>
                    <mat-date-range-input [min]="datetimeInclusionArea" [max]="lastDateValidate" [rangePicker]="picker">
                        <input matInput formControlName="matStartDate" matStartDate
                            placeholder="Data inicial">
                        <mat-error *ngIf="model.get('matStartDate').invalid">Selecione o Dia Inicial</mat-error>
                        <input matInput formControlName="matEndDate" matEndDate
                            placeholder="Data final">
                        <mat-error *ngIf="model.get('matEndDate').invalid">Selecione o Dia Final</mat-error>
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker (closed)="change()" #picker></mat-date-range-picker>
                </mat-form-field>
            </div>
        </div>
        <table mat-table [dataSource]="listAreaRanking" class="mat-elevation-z8">
            <ng-container matColumnDef="user">
                <th mat-header-cell *matHeaderCellDef id="font-size-columns"><b> Usuário </b></th>
                <td mat-cell *matCellDef="let item"><span *ngIf="showNames">{{item.userName}}</span></td>
            </ng-container>
            <ng-container matColumnDef="ranking">
                <th mat-header-cell *matHeaderCellDef id="font-size-columns"><b> Ranking </b></th>
                <td mat-cell *matCellDef="let item">{{item.ranking}}</td>
            </ng-container>
            <ng-container matColumnDef="point">
                <th mat-header-cell *matHeaderCellDef id="font-size-columns"><b> Pontos </b></th>
                <td mat-cell *matCellDef="let item">{{item.point}}</td>
            </ng-container>
            <ng-container matColumnDef="averageTime">
                <th mat-header-cell *matHeaderCellDef id="font-size-columns"><b> Progressão </b></th>
                <td mat-cell *matCellDef="let item">{{item.averageTime}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </form>
</div>