import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FilterSelectModel, ItemSelectModel } from 'src/app/shared/components/filter-select/filter-select.model';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AuditStatusEnum } from 'src/app/shared/enum/audit-status.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { AuditStatusService } from 'src/app/shared/services/API/audit/audit-status.service';
import { GetAllUserEvenDeletedService } from 'src/app/shared/services/API/user/get-all-user-even-deleted.service';
import { ClassificationRiskAuditStatus } from 'src/app/shared/services/models/audit/classification-risk-audit-status.model';
import { GetAllAuditRequest } from 'src/app/shared/services/requests/audit/get-all-audit.request';
import { AuditAuditorNames } from 'src/app/shared/services/structs/audit/audit-auditor-names.struct';
import { ListAllUserStruct } from 'src/app/shared/services/structs/user/list-all-user.struct';
import { AuditDeleteModalComponent } from './audit-delete-modal/audit-delete-modal.component';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop'
import { ClassificationRiskAudit } from 'src/app/shared/services/models/audit/classification-risk-audit.model';
import { AuditHistoryCardModalComponent } from './audit-history-card-modal/audit-history-card-modal.component';
import { AuditCardUpdateControlService } from 'src/app/shared/services/API/orchestrator-audit/audit-card-update-control.service';
import { PutAuditCardUpdateControlRequest } from 'src/app/shared/services/requests/orchestrator-audit/put-audit-card-update-control.request';
import { GetAuditService } from 'src/app/shared/services/API/orchestrator-audit/get-audit.service';
import { WebsocketAuditService } from 'src/app/shared/services/websocket-audit.service';
import { WebsocketAuditUtilService } from 'src/app/shared/services/websocket-util/websocket-audit-util.service';

@Component({
  selector: 'app-audit-list',
  templateUrl: './audit-list.component.html',
  styleUrls: ['./audit-list.component.css']
})
export class AuditListComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(
    private auditCardUpdateControlService: AuditCardUpdateControlService,
    private auditStatusService: AuditStatusService,
    private getAllUserService: GetAllUserEvenDeletedService,
    private getAuditService: GetAuditService,
    private alertService: AlertService,
    public dialog: MatDialog,
    public websocketAuditService: WebsocketAuditService,
    public websocketAuditUtilService: WebsocketAuditUtilService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.audit;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.audit_list;
  public concludedEnum: AuditStatusEnum = AuditStatusEnum.Concluido;

  public listAuditFirstAuditors: AuditAuditorNames[];
  public listAuditSecondAuditors: AuditAuditorNames[];
  public listAuditOpinion: AuditAuditorNames[];
  public listAuditDivergence: AuditAuditorNames[];
  public listAuditConcluded: AuditAuditorNames[];

  public listAuditStatus: ClassificationRiskAuditStatus[];
  public listDate: Date[];
  public filterSelectModelUser: FilterSelectModel;
  public listAllUserStruct: ListAllUserStruct[];

  public getRequest: GetAllAuditRequest;

  public listIdUser: number[];
  public idAudit: number;
  public isLoading: boolean;
  public idStatus: number;
  public searchDate: Date;
  public idHealthUnit: number;

  //websocket
  socketDisconneted: boolean = false;
  onlineOffline: boolean = navigator.onLine;
  private socket: any;

  ngOnInit(): void {
    this.isLoading = true;
    document.documentElement.style.setProperty('--heightSize', `${document.body.scrollHeight - 657}px`)
    this.populateStatusSelect();
    this.populateDateSelect();
    this.populateUserSelect();
    this.search();
  }

  ngOnDestroy() {
    if (this.socket)
      this.socket.disconnect();
  }

  ngAfterViewInit(): void {
    document.documentElement.style.setProperty('--heightSize', `${document.body.clientHeight - 300}px`)
  }

  search() {
    this.isLoading = true;
    this.getRequest = new GetAllAuditRequest();
    this.getRequest.listIdUser = [];

    this.filterSelectModelUser.itemSelectModel.forEach(element => {
      if (element.checked)
        this.getRequest.listIdUser.push(parseInt(element.key))
    });

    if (this.getRequest.listIdUser.length == 0)
      this.getRequest.listIdUser = null;

    this.getRequest.idAudit = this.idAudit;
    this.getRequest.idStatus = this.idStatus;

    if (this.searchDate != undefined && this.searchDate != null) {
      this.getRequest.year = this.searchDate.getFullYear();
      this.getRequest.month = this.searchDate.getMonth() + 1; //Jan é 0
    }
    else {
      this.getRequest.year = null;
      this.getRequest.month = null;
    }

    this.getAuditService.listAllAudit(this.getRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listAuditFirstAuditors = response.listAuditFirstAuditors;
        this.listAuditSecondAuditors = response.listAuditSecondAuditors;
        this.listAuditOpinion = response.listAuditOpinion;
        this.listAuditDivergence = response.listAuditDivergence
        this.listAuditConcluded = response.listAuditConcluded;
        this.idHealthUnit = response.idHealthUnit;

        if (!this.socket) this.startWebsocket();

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateUserSelect() {
    this.filterSelectModelUser = new FilterSelectModel();

    this.getAllUserService.getAllUserAuditorEvenDeleted().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listAllUserStruct = response.listAllUserStruct;
        response.listAllUserStruct.forEach(element => {
          this.filterSelectModelUser.itemSelectModel
            .push(new ItemSelectModel(element.idUser.toString(), element.userName, false));
        });
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getStatusName(idStatus: number, firstAuditorName: string, secondAuditorName: string, otherUserAuditorName: string, datetimeConclusion: Date, idClassificationRiskAudit: number, numberOtherStatusAudit: number): string {
    let description: string = "";

    if (this.listAuditStatus) {
      if (AuditStatusEnum.PrimeiroAuditando == idStatus || AuditStatusEnum.PrimeiroAuditado == idStatus)
        description = this.listAuditStatus.find(x => x.idClassificationRiskAuditStatus == idStatus).description + " - " + firstAuditorName;

      else if (AuditStatusEnum.SegundoAuditando == idStatus)
        description = this.listAuditStatus.find(x => x.idClassificationRiskAuditStatus == idStatus).description + " - " + secondAuditorName;

      else if (AuditStatusEnum.Auditando == idStatus
        && this.listAuditOpinion.some(c => c.listClassificationRiskAuditOthers && c.listClassificationRiskAuditOthers.length > 0)) {
        description = numberOtherStatusAudit?.toString() + `º ` + this.listAuditStatus.find(x => x.idClassificationRiskAuditStatus == idStatus).description + " - " + otherUserAuditorName;
      }

      else if (AuditStatusEnum.SegundoAuditado == idStatus)
        description = this.listAuditStatus.find(x => x.idClassificationRiskAuditStatus == idStatus).description + " - " + secondAuditorName;

      else if (AuditStatusEnum.Auditado == idStatus
        && this.listAuditOpinion.some(c => c.listClassificationRiskAuditOthers && c.listClassificationRiskAuditOthers.length > 0)) {
        description = numberOtherStatusAudit?.toString() + `º ` + this.listAuditStatus.find(x => x.idClassificationRiskAuditStatus == idStatus).description + " - " + otherUserAuditorName;
      }

      else if (AuditStatusEnum.Concluido == idStatus)
        description = this.listAuditStatus.find(x => x.idClassificationRiskAuditStatus == idStatus).description + ` em ${datetimeConclusion}`;

      else if (AuditStatusEnum.OpiniãoAguardando == idStatus) {
        description = numberOtherStatusAudit != 0 ? ((numberOtherStatusAudit + 1).toString() + `º `) : '3ª ';
        description += this.listAuditStatus.find(x => x.idClassificationRiskAuditStatus == idStatus).description;
      }

      else if (this.listAuditStatus.find(x => x.idClassificationRiskAuditStatus == idStatus))
        description = this.listAuditStatus.find(x => x.idClassificationRiskAuditStatus == idStatus).description;
    }

    return description;
  }

  populateDateSelect() {
    this.listDate = [];

    var todayDate = new Date();
    var year = todayDate.getFullYear();
    var month = todayDate.getMonth();

    for (var _i = 0; _i < 60; _i++) {
      this.listDate.push(new Date(year, month - _i));
    }
  }

  populateStatusSelect() {
    this.auditStatusService.listAllAuditStatus().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listAuditStatus = response.listClassificationRiskAuditStatuse;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;

    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13)
        $this.search();
    }, 1000);
  }

  openModal(idAudit) {
    const dialogRef = this.dialog.open(AuditDeleteModalComponent, {
      data: {
        idAudit: idAudit
      },
    });

    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.deleteAudit)
          this.search();
      }
    });
  }

  onDropFirstColumn(event: CdkDragDrop<string[]>) {
    const draggedItem = event.previousContainer.data[event.previousIndex] as unknown as AuditAuditorNames;
    const idStatus = draggedItem.classificationRiskAudit.idStatus;

    if (idStatus == AuditStatusEnum.Aguardando2ªAuditoria || idStatus == AuditStatusEnum.SegundoAuditado
      || idStatus == AuditStatusEnum.SegundoAuditando)
      return this.alertService.show('Aviso:', 'Não é possível retornar auditorias já finalizadas nesta coluna.', AlertType.warning);

    if (event.previousContainer !== event.container) {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  onDropSecondColumn(event: CdkDragDrop<string[]>) {
    const draggedItem = event.previousContainer.data[event.previousIndex] as unknown as AuditAuditorNames;
    const idStatus = draggedItem.classificationRiskAudit.idStatus;

    if (idStatus == AuditStatusEnum.OpiniãoAguardando || idStatus == AuditStatusEnum.Auditando
      || idStatus == AuditStatusEnum.Auditado)
      return this.alertService.show('Aviso:', 'Não é possível retornar auditorias já finalizadas nesta coluna.', AlertType.warning);

    if (event.previousContainer !== event.container) {
      if (idStatus == AuditStatusEnum.PrimeiroAuditado) {
        let request = new PutAuditCardUpdateControlRequest();
        request.classificationRiskAudit = new ClassificationRiskAudit();
        request.classificationRiskAudit = draggedItem.classificationRiskAudit;
        request.initialColumn = "1ª AUDITORIA";
        request.finalColumn = "2ª AUDITORIA";

        draggedItem.classificationRiskAudit.idStatus = AuditStatusEnum.Aguardando2ªAuditoria;

        this.updateStatus(request);
      }
      else
        return this.alertService.show('Aviso:', 'Você só pode arrastar para a segunda coluna os cards com status "1º auditado".', AlertType.warning);

      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  onDropThirdColumn(event: CdkDragDrop<string[]>) {
    const draggedItem = event.previousContainer.data[event.previousIndex] as unknown as AuditAuditorNames;
    const idStatus = draggedItem.classificationRiskAudit.idStatus;

    if (event.previousContainer !== event.container) {
      let request = new PutAuditCardUpdateControlRequest();
      request.classificationRiskAudit = new ClassificationRiskAudit();
      request.classificationRiskAudit = draggedItem.classificationRiskAudit;
      request.finalColumn = "OUTRA OPINIÃO";

      if (idStatus == AuditStatusEnum.SegundoAuditado)
        request.initialColumn = "2ª AUDITORIA";
      else if (idStatus == AuditStatusEnum.Divergência)
        request.initialColumn = "DIVERGÊNCIA";
      else if (idStatus == AuditStatusEnum.DivergênciaResolvida)
        request.initialColumn = "Divergência resolvida";
      else
        return this.alertService.show('Aviso:', 'Você só pode arrastar para a terceira coluna os cards com status "2º auditado", "Divergência" ou com "Divergência resolvida".', AlertType.warning);

      draggedItem.classificationRiskAudit.idStatus = AuditStatusEnum.OpiniãoAguardando;
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );

      this.updateStatus(request);
    }
  }

  onDropFourthColumn(event: CdkDragDrop<string[]>) {
    const draggedItem = event.previousContainer.data[event.previousIndex] as unknown as AuditAuditorNames;
    const idStatus = draggedItem.classificationRiskAudit.idStatus;
    if (event.previousContainer !== event.container && ((idStatus == AuditStatusEnum.Auditado) || (idStatus == AuditStatusEnum.OpiniãoAguardando)) && draggedItem.classificationRiskAudit.isDivergence == false) {
      let request = new PutAuditCardUpdateControlRequest();
      request.classificationRiskAudit = new ClassificationRiskAudit();
      request.classificationRiskAudit = draggedItem.classificationRiskAudit;
      request.classificationRiskAudit.idStatus = AuditStatusEnum.DivergênciaResolvida;
      request.initialColumn = "OUTRA OPINIÃO";
      request.finalColumn = "Divergência resolvida";

      draggedItem.classificationRiskAudit.idStatus = AuditStatusEnum.DivergênciaResolvida;
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );

      this.updateStatus(request);
    }
    else if (event.previousContainer !== event.container && ((idStatus == AuditStatusEnum.Auditado) || (idStatus == AuditStatusEnum.OpiniãoAguardando))) {
      let request = new PutAuditCardUpdateControlRequest();
      request.classificationRiskAudit = new ClassificationRiskAudit();
      request.classificationRiskAudit = draggedItem.classificationRiskAudit;
      request.initialColumn = "OUTRA OPINIÃO";
      request.finalColumn = "DIVERGÊNCIA";

      draggedItem.classificationRiskAudit.idStatus = AuditStatusEnum.Divergência;
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );

      this.updateStatus(request);
    }
    else
      return this.alertService.show('Aviso:', ' Você só pode arrastar para a quarta coluna os cards com status "Outra Opinião".', AlertType.warning);
  }

  onDropFifthColumn(event: CdkDragDrop<string[]>) {
    const draggedItem = event.previousContainer.data[event.previousIndex] as unknown as AuditAuditorNames;
    const idStatus = draggedItem.classificationRiskAudit.idStatus;
    const isDivergence = draggedItem.classificationRiskAudit.isDivergence;

    if (event.previousContainer !== event.container
      && (idStatus == AuditStatusEnum.SegundoAuditado || idStatus == AuditStatusEnum.Auditado || idStatus == AuditStatusEnum.OpiniãoAguardando
        || idStatus == AuditStatusEnum.DivergênciaResolvida || idStatus == AuditStatusEnum.Divergência)) {

      if (isDivergence && (idStatus == AuditStatusEnum.Divergência || idStatus == AuditStatusEnum.OpiniãoAguardando || idStatus == AuditStatusEnum.Auditado))
        return this.alertService.show('Aviso:', 'Não é possível concluir auditoria(s) com divergência(s).', AlertType.warning);

      let request = new PutAuditCardUpdateControlRequest();
      request.classificationRiskAudit = new ClassificationRiskAudit();
      request.classificationRiskAudit = draggedItem.classificationRiskAudit;
      if (idStatus == AuditStatusEnum.SegundoAuditado)
        request.initialColumn = "2ª AUDITORIA";
      else if (idStatus == AuditStatusEnum.Auditado)
        request.initialColumn = "OUTRA OPINIÃO";
      else if (idStatus == AuditStatusEnum.OpiniãoAguardando)
        request.initialColumn = "Aguardando Outra Opinião";
      else if (idStatus == AuditStatusEnum.DivergênciaResolvida)
        request.initialColumn = "Divergência resolvida";

      request.finalColumn = "CONCLUÍDO";
      draggedItem.classificationRiskAudit.idStatus = AuditStatusEnum.Concluido;
      draggedItem.classificationRiskAudit.datetimeConclusion = new Date;
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );

      this.updateStatus(request);
    }
    else
      return this.alertService.show('Aviso:', 'Você só pode concluir auditorias que foram auditadas por, pelo menos, 2 auditores.', AlertType.warning);
  }

  updateStatus(request: PutAuditCardUpdateControlRequest) {
    this.auditCardUpdateControlService.updateAuditStatus(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.alertService.show('Sucesso', "Status atualizado!", AlertType.success);
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openHistoryCardModal(idClassificationRiskAudit: number) {
    const dialogRef = this.dialog.open(AuditHistoryCardModalComponent, {
      data: {
        idClassificationRiskAudit: idClassificationRiskAudit
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  formatDate(date?: Date): string | null {
    if (!date || !(date instanceof Date))
      return null;

    const dateToFormat = date;
    const year = dateToFormat.getFullYear();
    const month = (dateToFormat.getMonth() + 1).toString().padStart(2, '0');
    const day = dateToFormat.getDate().toString().padStart(2, '0');

    return `${day}/${month}/${year}`;
  }

  startWebsocket() {
    if (!this.websocketAuditUtilService.isConnect())
      this.websocketAuditUtilService.connectwebsocketRoom(this.idHealthUnit, this, null, null);

    this.websocketAuditUtilService.setThisParam(this);
    this.websocketAuditUtilService.setFunctions(this.verifyTrigger);
  }

  verifyTrigger(client: any, thisParam: any) {
    if (client.action == 'updatestatus')
      thisParam.search();
  }
}