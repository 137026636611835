export class IntegrationLog {
    
    public idIntegrationLog: number;
    
    public idHealthUnit: number;
    
    public idUri: number;
    
    public guid: string;
    
    public datetimeBeginRequest: Date;
    
    public jsonRequest: string;
    
    public ipRequest: string;
    
    public datetimeEndRequest: Date;
    
    public jsonResponse: string;
    
    public idLogType: number;

    public idEpisode: number;
}