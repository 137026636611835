import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { AlertService, AlertType } from "src/app/shared/services/alert.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { Router } from '@angular/router';
import { NotLoggedInModalComponent } from "./not-logged-in-modal/not-logged-in-modal.component";
import { hygienizationTotemCodeErrorsEnum } from "src/app/shared/enum/observation/hygienization-totem-code-errors.enum";

@Component({
  selector: 'app-login-hygienization-totem',
  templateUrl: './login-hygienization-totem.component.html',
  styleUrls: ['./login-hygienization-totem.component.css']
})
export class LoginHygienizationTotemComponent implements OnInit {
  @Input() isLoading: boolean;
  @Input() idHealthUnit: number;
  @Input() idTotem: number;

  @Output() onLogged: EventEmitter<any> = new EventEmitter<any>();

  constructor(private alertService: AlertService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    public dialog: MatDialog) { }

  public hide: boolean = false;
  public model: FormGroup;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      login: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  submit() {
    if (this.model.invalid || this.isLoading)
      return;
    this.isLoading = true;

    this.authService.signInTotemHygienizationBed(this.model.get('login').value, this.model.get('password').value, '192.168.0.2', this.idHealthUnit, this.idTotem).subscribe({
      next: (response) => {
        if (response.isError) {
          if (response.errorCode == hygienizationTotemCodeErrorsEnum.UserNotRegisteredInTotem) {
            let modalTitle: string = response.errorDescription;
            let modalBody: string = "Procure o responsável pelo registro de usuários no totem";
            this.openModalNotLogged(modalTitle, modalBody);
            return;
          } else if (response.errorCode == hygienizationTotemCodeErrorsEnum.UserNotRegisteredInBedHygienization) {
            let modalTitle: string = response.errorDescription;
            let modalBody: string = "Por favor, entre em contato com o responsável pelo cadastro";
            this.openModalNotLogged(modalTitle, modalBody);
            return;
          } else if (response.errorCode == hygienizationTotemCodeErrorsEnum.UserDisabled) {
            let modalTitle: string = response.errorDescription;
            let modalBody: string = "Procure o responsável pela habilitação de usuários";
            this.openModalNotLogged(modalTitle, modalBody);
            return;
          } else if (response.errorCode == hygienizationTotemCodeErrorsEnum.ErrorInLoginInformation) {
            let modalTitle: string = "Usuário não encontrado!";
            let modalBody: string = "Por favor, verifique sua matrícula, CPF ou senha.";
            this.openModalNotLogged(modalTitle, modalBody);
            return;
          } else {
            this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
            this.isLoading = false;
            return;
          }
        }

        this.authService.updateToken(response);
        this.isLoading = false;
        this.onLogged.emit(true);
      },
      error: (error) => {
        this.alertService.show("Erro inesperado", error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  openModalNotLogged(modalTitle: string, modalBody: string) {
    const dialogRef = this.dialog.open(NotLoggedInModalComponent, {
      data: {
        modalTitle: modalTitle,
        modalBody: modalBody,
      },
    });
    this.isLoading = false;
    this.onLogged.emit(false);
  }
}