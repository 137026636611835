<div *ngIf="!isLoading" class="content">
    <div class="row">
        <div class="col-6">
            <div class="bed-white-body">
                <div class="section-header" [style.border-left-color]="'#db1f67'">
                    <span>
                        Leitos não higienizados
                    </span>
                </div>
                <div class="card-list">
                    <div class="row">
                        <div class="col-12 col-xl-6 col-lg-12 col-md-12" *ngFor="let item of listUnhygienizedBed">
                            <div class="card-item" *ngIf="!isLoading">
                                <div class="infos">
                                    <span class="primary">{{item.bedName}}</span>
                                    <span class="secundary">{{item.bedTypeName}}</span>
                                    <span class="secundary">{{item.bedStatusName}}</span>
                                    <span class="secundary">{{item.bedCategoryName}}</span>
                                    <span class="secundary bed-status"
                                        [style.background-color]="'#db1f67'">{{item.hygienizationStatusName}}</span>
                                </div>
                                <div class="actions">
                                    <a>
                                        <mat-icon aria-hidden="false" aria-label="Higienizar"
                                            (click)="startHygienization(item)" matTooltip="Começar higienização">
                                            cleaning_services
                                        </mat-icon>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="bed-white-body">
                <div class="section-header" [style.border-left-color]="'#e89323'">
                    <span>
                        Leitos em higienização
                    </span>
                </div>
                <div class="card-list" *ngIf="model.get('hygienization').value">
                    <div class="row">
                        <div class="col-12 col-xl-6 col-lg-12 col-md-12" *ngFor="let item of listHygienizingBed">
                            <div class="card-item" *ngIf="!isLoading">
                                <div class="infos">
                                    <span class="primary">{{item.bedName}}</span>
                                    <span class="secundary">{{item.bedTypeName}}</span>
                                    <span class="secundary">{{item.bedStatusName}}</span>
                                    <span class="secundary">{{item.bedCategoryName}}</span>
                                    <span class="secundary bed-status"
                                        [style.background-color]="'#e89323'">{{item.hygienizationStatusName}}</span>
                                    <span class="secundary">{{item.hygienizationUserName}}</span>
                                </div>
                                <div class="actions">
                                    <a>
                                        <mat-icon aria-hidden="false" aria-label="Editar"
                                            (click)="finishHygienization(item)" matTooltip="Finalizar higienização">
                                            sanitizer
                                        </mat-icon>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>
</div>