export enum ColumnsPatientProcedureMadeEnum {
    Coordenadoria = 1,
    Supervisao = 2,
    CNESEstabelecimento = 3,
    Estabelecimento = 4,
    CompetenciaBPA = 5,
    CNSProfissional = 6,
    NomeProfissional = 7,
    NomePaciente = 8,
    DataDeNascimento = 9,
    CNSPaciente = 10,
    CID = 11,
    Codigo = 12,
    Procedimento = 13,
    CodigoCBO = 14,
    CBO= 15,
    Quantidade=16
}