import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { FlowListComponent } from './pages/flow-list/flow-list.component';
import { FlowRegisterComponent } from './pages/flow-register/flow-register.component';
import { MasterComponent } from './pages/master/master.component';
import { PanelListComponent } from './pages/panel-list/panel-list.component';
import { PanelRegisterComponent } from './pages/panel-register/panel-register.component';
import { ProfileListComponent } from './pages/profile-list/profile-list.component';
import { ProfileRegisterComponent } from './pages/profile-register/profile-register.component';
import { RoomListComponent } from './pages/room-list/room-list.component';
import { RoomRegisterComponent } from './pages/room-register/room-register.component';
import { SectorListComponent } from './pages/sector-list/sector-list.component';
import { SectorRegisterComponent } from './pages/sector-register/sector-register.component';
import { ServiceListComponent } from './pages/service-list/service-list.component';
import { ServiceRegisterComponent } from './pages/service-register/service-register.component';
import { TotemListComponent } from './pages/totem-list/totem-list.component';
import { TotemRegisterComponent } from './pages/totem-register/totem-register.component';
import { UserListComponent } from './pages/user-list/user-list.component';
import { UserRegisterComponent } from './pages/user-register/user-register.component';
import { HealthUnitConfigComponent } from './pages/health-unit-config/health-unit-config.component';
import { CareLineListComponent } from './pages/care-line-list/care-line-list.component';
import { CareLineRegisterComponent } from './pages/care-line-register/care-line-register.component';
import { BatchListComponent } from './pages/batch-list/batch-list.component';
import { BatchRegisterComponent } from './pages/batch-register/batch-register.component';
import { MetaDataListComponent } from './pages/meta-data-list/meta-data-list.component';
import { MetaDataRegisterComponent } from './pages/meta-data-register/meta-data-register.component';
import { GamificationListComponent } from './pages/gamification-list/gamification-list.component';
import { GamificationRegisterComponent } from './pages/gamification-register/gamification-register.component';
import { BillingHealthUnitComponent } from './pages/billing-health-unit/billing-health-unit.component';
import { BillingAlertRegisterComponent } from './pages/billing-alert-register/billing-alert-register.component';
import { NpsComponent } from './pages/nps/nps.component';
import { TotemNpsListComponent } from './pages/totem-nps-list/totem-nps-list.component';
import { TotemNpsRegisterComponent } from './pages/totem-nps-register/totem-nps-register.component';
import { NpsGraphComponent } from './pages/nps-graph/nps-graph.component';
import { AlertPolicyListComponent } from './pages/alert-policy-list/alert-policy-list.component';
import { AlertPolicyRegisterComponent } from './pages/alert-policy-register/alert-policy-register.component';
import { TextTemplateListComponent } from './pages/text-template-list/text-template-list.component';
import { TextTemplateRegisterComponent } from './pages/text-template-register/text-template-register.component';

const routes: Routes = [
  { path: 'master', component: MasterComponent, canActivate: [AuthGuard] },
  { path: 'master/user', component: UserListComponent, canActivate: [AuthGuard] },
  { path: 'master/user/register', component: UserRegisterComponent, canActivate: [AuthGuard] },
  { path: 'master/profile', component: ProfileListComponent, canActivate: [AuthGuard] },
  { path: 'master/profile/register', component: ProfileRegisterComponent, canActivate: [AuthGuard] },
  { path: 'master/service', component: ServiceListComponent, canActivate: [AuthGuard] },
  { path: 'master/service/register', component: ServiceRegisterComponent, canActivate: [AuthGuard] },
  { path: 'master/sector', component: SectorListComponent, canActivate: [AuthGuard] },
  { path: 'master/sector/register', component: SectorRegisterComponent, canActivate: [AuthGuard] },
  { path: 'master/room', component: RoomListComponent, canActivate: [AuthGuard] },
  { path: 'master/room/register', component: RoomRegisterComponent, canActivate: [AuthGuard] },
  { path: 'master/totem', component: TotemListComponent, canActivate: [AuthGuard] },
  { path: 'master/totem/register', component: TotemRegisterComponent, canActivate: [AuthGuard] },
  { path: 'master/flow', component: FlowListComponent, canActivate: [AuthGuard] },
  { path: 'master/flow/register', component: FlowRegisterComponent, canActivate: [AuthGuard] },
  { path: 'master/panel', component: PanelListComponent, canActivate: [AuthGuard] },
  { path: 'master/panel/register', component: PanelRegisterComponent, canActivate: [AuthGuard] },
  { path: 'master/health-unit/config', component: HealthUnitConfigComponent, canActivate: [AuthGuard] },
  { path: 'master/care-line', component: CareLineListComponent, canActivate: [AuthGuard] },
  { path: 'master/care-line/register', component: CareLineRegisterComponent, canActivate: [AuthGuard] },
  { path: 'master/batch', component: BatchListComponent, canActivate: [AuthGuard] },
  { path: 'master/batch/register', component: BatchRegisterComponent, canActivate: [AuthGuard] },
  { path: 'master/meta-data', component: MetaDataListComponent, canActivate: [AuthGuard] },
  { path: 'master/meta-data/register', component: MetaDataRegisterComponent, canActivate: [AuthGuard] },
  { path: 'master/gamification', component: GamificationListComponent, canActivate: [AuthGuard] },
  { path: 'master/gamification/register', component: GamificationRegisterComponent, canActivate: [AuthGuard] },
  { path: 'master/billing-health-unit', component: BillingHealthUnitComponent, canActivate: [AuthGuard] },
  { path: 'master/billing-alert', component: BillingAlertRegisterComponent, canActivate: [AuthGuard] },
  { path: 'master/nps', component: NpsComponent, canActivate: [AuthGuard] },
  { path: 'master/totem-nps', component: TotemNpsListComponent, canActivate: [AuthGuard] },
  { path: 'master/totem-nps/register', component: TotemNpsRegisterComponent, canActivate: [AuthGuard] },
  { path: 'master/result-nps', component: NpsGraphComponent, canActivate: [AuthGuard] },
  { path: 'master/alert-policy/list', component: AlertPolicyListComponent, canActivate: [AuthGuard] },
  { path: 'master/alert-policy/register', component: AlertPolicyRegisterComponent, canActivate: [AuthGuard] },
  { path: 'master/text-template', component: TextTemplateListComponent, canActivate: [AuthGuard] },
  { path: 'master/text-template/register', component: TextTemplateRegisterComponent, canActivate: [AuthGuard] },

];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    BrowserAnimationsModule,
    FormsModule,
    MatInputModule
  ],
  exports: [RouterModule]
})
export class MasterRoutingModule { }
