import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { OrchestratorFormEpisodeService } from 'src/app/shared/services/API/orchestrator-form/form-episode/orchestrator-form-episode.service';
import { FormEpisodeStruct } from 'src/app/shared/services/structs/orchestrator-form/form-episode.struct';
import { FormEpisodeListDeleteModalComponent } from './form-episode-list-delete-modal/form-episode-list-delete-modal.component';

@Component({
  selector: 'app-form-episode-list',
  templateUrl: './form-episode-list.component.html',
  styleUrls: ['./form-episode-list.component.css']
})
export class FormEpisodeListComponent implements OnInit {
  

  constructor(private alertService: AlertService,
    private formEpisodeService: OrchestratorFormEpisodeService,
    public dialog: MatDialog
  ) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.form;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.form_episode_list;

  public isLoading: boolean;
  
  public patientSearchText: string;
  public birthDate: Date;
  public protocolSearchText: string;
  public startOfAttendance: Date;
  public endOfAttendance: Date;

  public fullListSize: number;

  public listFormEpisode: FormEpisodeStruct[];

  ngOnInit(): void {
    this.search(0);
  }

  search(pageIndex: number) {
    this.isLoading = true;

    if (this.patientSearchText == "")
      this.patientSearchText = null;

    if (this.protocolSearchText == "")
      this.protocolSearchText = null;

    if (this.birthDate)
      this.birthDate.setHours(0);

    if (this.startOfAttendance)
      this.startOfAttendance.setHours(0);

    if (this.endOfAttendance) {
      this.endOfAttendance.setHours(23);
      this.endOfAttendance.setMinutes(59);
    }
   
    this.formEpisodeService.listFormEpisode(this.patientSearchText, this.birthDate, this.protocolSearchText, this.startOfAttendance, this.endOfAttendance, pageIndex).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.fullListSize = response.listFormEpisodeStruct.itemCount;
      this.listFormEpisode = response.listFormEpisodeStruct.listFormEpisode;

      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  changePage(event: PageEvent) {
    this.search(event.pageIndex);
  }

  buttonSearch() {
    if (this.isLoading)
      return;

    this.paginator.firstPage();
    this.search(0);
  }

  private timeoutKeySearch: any = null;
  
  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search(0);
      }
    }, 1000);
  }

  openModal(idEpisode){
    const dialogRef = this.dialog.open(FormEpisodeListDeleteModalComponent, {
      data: {
        idEpisode: idEpisode
      },
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.deleteEpisode){
        this.search(0);
      }
    });
  }
}
