<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div *ngIf="this.data.disconnected && !this.data.roomIsNotConnected" class="head-modal-list">
            <h2>O atendimento do paciente caiu!</h2>
        </div>
        <div *ngIf="!this.data.disconnected && !this.data.roomIsNotConnected" class="head-modal-list">
            <h2>Parece que o paciente evadiu do atendimento!</h2>
        </div>
        <div *ngIf="this.data.roomIsNotConnected" class="head-modal-list">
            <h2>A sala da unidade não foi conectada!</h2>
        </div>
        <div *ngIf=" this.data.roomIsNotConnected" class="body-modal-list">
            Entre em contato com a unidade para conectar a sala.
        </div>
        <div *ngIf=" this.data.roomIsNotConnected" class="body-modal-list">
            Ou, se não foi sua inteção entrar em uma sala de atendimento remoto, escolha a sala de outro setor!
        </div>
        <div *ngIf="this.data.disconnected || this.data.roomIsNotConnected" class="body-modal-list">
            O paciente será retornado automaticamente para fila e você será redirecionado para a tela de pacientes,
            aguarde.
        </div>
        <div *ngIf="!this.data.disconnected && !this.data.roomIsNotConnected" class="body-modal-list">
            O paciente saiu do atendimento. Ele será evadido automaticamente e você será redirecionado para a tela de
            pacientes, aguarde.
        </div>
        <div class="footer-modal-list txtCenter">
            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
        </div>
    </div>
</div>