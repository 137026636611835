import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { DataIntVariableRequest } from '../../requests/orchestrator-data-intelligence/data-int-variable.request';
import { DashboardGraphsResponse } from '../../responses/orchestrator-data-intelligence/dashboard-graphs.response';
import { EpisodeDataIntResponse } from '../../responses/orchestrator-data-intelligence/episode-data-int.response';

@Injectable({
    providedIn: 'root'
})
export class GetDashboardViewDataService extends CommonService {

    constructor(private router: Router, private httpClient: HttpClient) {
        super();
    }

    public getDashboardData(idDashboard: number): Observable<DashboardGraphsResponse> {

        let uri = `GetDashboardViewData/idDashboard/${idDashboard}`;

        return this.httpClient.get<DashboardGraphsResponse>(environment.urlApiOrchestratorDataIntelligence + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }
}