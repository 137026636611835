import { MedicalPrescriptionCheckStruct } from "./medical-prescription-check.struct";

export class ReadMultiProfessionalPrescriptionStruct {
    public idMultiProfessionalPrescription: number
    public idMedicine: number;
    public medicineName: string;
    public idUsage: number;
    public usage: string;
    public idAdministrationType: number;
    public administrationTypeName: string;
    public administrationType: string;
    public quantity: number;
    public idPresentation: number;
    public presentationName: string;
    public presentation: string;
    public idFrequency: number;
    public frequencyName: string;
    public idTreatmentPeriod: number;
    public treatmentPeriodName: string;
    public idDilution: number;
    public dilutionName: string;
    public dilution: string;
    public idMeasurementUnit: number;
    public idFavoriteMedicine: number;
    public observation: string;
    //public listMultiProfessionalPrescriptionCheck: MedicalPrescriptionCheckStruct[];
    public prescriptionCheckStatus: string;
    public isExecutable: boolean;
    public prescriptionEstimativeStatus: string;
    public justification: string;
    public datetimeInclusion: Date;
    public idSubFrequency: number;
    public subFrequencyName: string;
    // public numberDrops: number;
}