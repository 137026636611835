<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <a class="back-link" routerLink="/patient-history/grievance-notification">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Notificações de agravo
        </a>
        <h1>Gerar notificação de agravo</h1>
        <div class="white-body">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Notificação de agravo</mat-label>
                            <mat-select formControlName="idGrievanceNotification">
                                <mat-option *ngFor="let item of listGrievanceNotification"
                                    [value]="item.idGrievanceNotification">{{item.grievanceNotificationName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span *ngIf="isLoading == false">Gerar Notificação</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <a mat-flat-button type="button" routerLink="/patient-history/grievance-notification" color="accent"
                            class=" btn-block">
                            Voltar
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>

</div>