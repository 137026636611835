<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-confirm-modal-list">
            <h2>Aviso</h2>
        </div>
        <div class="body-confirm-modal-list">
            <p>O Dispositivo do paciente está desligado!</p>
            <p>Peça para ele ligar e tente novamente!</p>
        </div>
        <div class="footer-confirm-modal-list">
            <a mat-flat-button type="button" color="accent" class="btn-block" (click)="clickCancel(false)">
                Tentar novamente
            </a>
            <a mat-flat-button type="button" color="accent" class="btn-block" (click)="clickCancel(true)">
                Buscar por outro CPF
            </a>
        </div>
    </div>
</div>