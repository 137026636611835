import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsClientService } from 'src/app/client/utils.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { RoomService } from 'src/app/shared/services/API/flow/room.service';
import { PinValidityService } from 'src/app/shared/services/API/medic/pin-validity.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { InvalidModalComponent } from '../../modals/invalid-modal/invalid-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { WarnPatientDoctorEvadeComponent } from '../../modals/warn-patient-doctor-evade/warn-patient-doctor-evade.component';
import { WebsocketRemoteUtilService } from 'src/app/shared/services/websocket-util/websocket-remote-util.service';
import { PatientMedicalRecordService } from 'src/app/shared/services/API/medical-record/patient-medical-record.service';
import { error } from 'console';

@Component({
  selector: 'app-cpf-validity-remote-attendance',
  templateUrl: './cpf-validity-remote-attendance.component.html',
  styleUrls: ['./cpf-validity-remote-attendance.component.css']
})
export class CpfValidityRemoteAttendanceComponent implements OnInit {
  constructor(
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private router: Router,
    private utilsService: UtilsClientService,
    private maskService: MaskService,
    private dialog: MatDialog,
    private websocketRemoteUtilService: WebsocketRemoteUtilService,
    private patientMedicalRecordService: PatientMedicalRecordService
  ) { }

  public model: FormGroup;
  public isLoading: boolean = false;
  public masks: Masks;

  ngOnInit(): void {
    let roomSector = this.utilsService.getSectorRoomRemote();
    if (!roomSector) {
      this.router.navigate(['client/remote-attendance/inv']);
      return;
    }

    let { idRoom } = JSON.parse(roomSector);

    if (!this.websocketRemoteUtilService.isConnect()) {
      this.websocketRemoteUtilService.connectwebsocketRoom(idRoom);
    }

    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      cpf: ['', [Validators.required]]
    });
  }

  submit() {
    if (this.isLoading) {
      return;
    }
    if (this.model.invalid) {
      this.alertService.show("Erro", "Preencha o campo do CPF para continuar.", AlertType.error);
      return;
    }
    let cpf: string = this.model.get("cpf").value;
    if (cpf && !this.validatorCpf(cpf.replaceAll(".", "").replace("-", ""))) {
      this.model.get("cpf").setErrors({ 'incorrect': true });
      this.alertService.show("Erro", "Preencha o campo com um CPF válido para continuar.", AlertType.error);
      return;
    }
    let idEpisode = this.websocketRemoteUtilService.getIdEpisode();
    if (!idEpisode) {
      this.alertService.show("Aviso", "O médico não iniciou o atendimento, aguarde alguns minutos e tente novamente!.", AlertType.warning);
      return;
    }
    this.isLoading = true;
    this.patientMedicalRecordService.Validate(idEpisode, cpf.replaceAll(".", "").replace("-", "")).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show("Erro", response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        } else if (response.errorCode == 1) {
          this.openInvalidModal();
          return;
        }
        this.websocketRemoteUtilService.emitPatientIsConnecting();
        this.alertService.show("Sucesso", "CPF válidado com sucesso!", AlertType.success);
        this.router.navigate(['client/remote-attendance/attend-patient-video']);
      },
      error: (error) => {
        this.alertService.show("Erro", error, AlertType.error);
        this.isLoading = false;
      }
    })
  }

  openInvalidModal() {
    let dialogRef = this.dialog.open(InvalidModalComponent, {
      width: '50vh'
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response && response.cancel) {
        this.router.navigate(['client/remote-attendance/welcome']);
        return;
      } else {
        this.isLoading = false;
      }
    });
  }

  validatorCpf(cpfString) {
    let isValid: boolean = true;

    if (cpfString.length != 11) {
      return false;
    }

    var Soma;
    var Resto;

    Soma = 0;
    if (cpfString == "00000000000" ||
      cpfString == "11111111111" ||
      cpfString == "22222222222" ||
      cpfString == "33333333333" ||
      cpfString == "44444444444" ||
      cpfString == "55555555555" ||
      cpfString == "66666666666" ||
      cpfString == "77777777777" ||
      cpfString == "88888888888" ||
      cpfString == "99999999999") {
      isValid = false;
    }

    for (let i = 1; i <= 9; i++) {
      Soma = Soma + parseInt(cpfString.substring(i - 1, i)) * (11 - i)
    };

    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) {
      Resto = 0
    };

    if (Resto != parseInt(cpfString.substring(9, 10))) {
      isValid = false;
    };

    Soma = 0;

    for (let i = 1; i <= 10; i++) {
      Soma = Soma + parseInt(cpfString.substring(i - 1, i)) * (12 - i)
    };
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) {
      Resto = 0;
    }

    if (Resto != parseInt(cpfString.substring(10, 11))) {
      isValid = false;
    }

    if (!isValid) {
      return false;
    } else {
      return true;
    }
  }

}
