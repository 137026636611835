import { DiscriminatorModel } from "../../models/risk-classification/discriminator.model";
import { SubDiscriminatorModel } from "../../models/risk-classification/subDiscriminator.model";

export class DiscriminatorStruct extends DiscriminatorModel
{
    public subDiscriminator: SubDiscriminatorModel[];
    public value: any;
    public listCounseling: number[];
    public type: any;
    public state: any;
    public hasValue: boolean = false;
}