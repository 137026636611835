import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DateValidator } from 'src/app/shared/custom-validators/date.validator';
import { datesInCrescentOrderValidator } from 'src/app/shared/custom-validators/date-tiss-guide.validator';
import { GuideTypeEnum } from 'src/app/shared/enum/private-billing/guide-type.enum';
import { SpSadtFieldsMaxLengthEnum } from 'src/app/shared/enum/private-billing/sp-sadt-fields-max-length.enum';
import { HealthcareAgreementService } from 'src/app/shared/services/API/private-billing/healthcare-agreement.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { AccidentIndication } from 'src/app/shared/services/models/private-billing/accident-indication.model';
import { AppointmentType } from 'src/app/shared/services/models/private-billing/appointment-type.model';
import { AttendanceAspect } from 'src/app/shared/services/models/private-billing/attendance-aspect.model';
import { AttendanceType } from 'src/app/shared/services/models/private-billing/attendance-type.model';
import { Cbo } from 'src/app/shared/services/models/private-billing/cbo.model';
import { GuideStatus } from 'src/app/shared/services/models/private-billing/guide-status.model';
import { HealthcareAgreement } from 'src/app/shared/services/models/private-billing/healthcare-agreement.model';
import { ProfessionalCouncil } from 'src/app/shared/services/models/private-billing/professional-council.model';
import { ProviderIdentityType } from 'src/app/shared/services/models/private-billing/provider-identity-type.model';
import { SpSadtRequiredField } from 'src/app/shared/services/models/private-billing/sp-sadt-required-field.model';
import { State } from 'src/app/shared/services/models/private-billing/state.model';
import { TissBilling } from 'src/app/shared/services/models/private-billing/tiss-billing.model';
import { HealthcareAgreementStruct } from 'src/app/shared/services/structs/private-billing/healthcare-agreement.struct';
import { SpSadtGuideStruct } from 'src/app/shared/services/structs/private-billing/sp-sadt-guide.struct';
import { LookupSpSadtRequiredFieldEnum } from 'src/app/shared/enum/private-billing/lookup-sp-sadt-required-field.enum';
import { SpSadtService } from 'src/app/shared/services/API/private-billing/sp-sadt.service';
import { TerminologyGroup } from 'src/app/shared/services/models/private-billing/terminology-group.model';
import { SpSadtRequestedProcedureStruct } from 'src/app/shared/services/structs/private-billing/sp-sadt-requested-procedure.struct';
import { Access } from 'src/app/shared/services/models/private-billing/access.model';
import { LookupResponse } from 'src/app/shared/services/responses/private-billing/lookup.response';
import { Technique } from 'src/app/shared/services/models/private-billing/technique.model';
import { ParticipationDegree } from 'src/app/shared/services/models/private-billing/participation-degree.model';
import { ProfessionalAgreementStruct } from 'src/app/shared/services/structs/private-billing/professional-agreement.struct';
import { SpSadtExecutedProcedureStruct } from 'src/app/shared/services/structs/private-billing/sp-sadt-executed-procedure.struct';
import { GuideStatusEnum } from 'src/app/shared/enum/private-billing/guide-status.enum';
import { ProviderIdentityTypeEnum } from 'src/app/shared/enum/private-billing/provider-identity-type.enum';
import { VerifyCNPJ } from 'src/app/shared/custom-validators/cnpj.validator';
import { VerifyCPF } from 'src/app/shared/custom-validators/cpf.validator';
import { SpSadtGuideRequest } from 'src/app/shared/services/requests/private-billing/spsadt-guide-request';
import { SpSadtSerialProcedure } from 'src/app/shared/services/models/private-billing/sp-sadt-serial-procedure.model';
import { Guide } from 'src/app/shared/services/models/private-billing/guide.model';
import { SpecialCoverage } from 'src/app/shared/services/models/private-billing/special-coverage.model';
import { OccupationalHealth } from 'src/app/shared/services/models/private-billing/occupational-health.model';
import { CareRegime } from 'src/app/shared/services/models/private-billing/care-regime.model';
import { TerminationReason } from 'src/app/shared/services/models/private-billing/termination-reason.model';
import { TerminologyGroupEnum } from 'src/app/shared/enum/private-billing/terminology-group.enum';
import { ProcedureStruct } from 'src/app/shared/services/structs/private-billing/procedure.struct';
import { environment } from 'src/environments/environment';
import { GuideRequest } from 'src/app/shared/services/requests/private-billing/guide.request';
import { OrchestratorPatientGuideService } from 'src/app/shared/services/API/orchestrator-patient/orchestrator-patient-guide.service';
import { GuideService } from 'src/app/shared/services/API/private-billing/guide.service';
import { GuideReportResponse } from 'src/app/shared/services/responses/orchestrator-patient/guide-report.response';
import { SpSadtExecutedProcedureTableComponent } from 'src/app/shared/components/tiss-guides/tiss-guide-sp-sadt-modal/sp-sadt-executed-procedure-table/sp-sadt-executed-procedure-table.component';
import { TissGuideSpSadtModalComponent } from 'src/app/shared/components/tiss-guides/tiss-guide-sp-sadt-modal/tiss-guide-sp-sadt-modal.component';
import { SpSadtRequestedProcedureFormComponent } from 'src/app/shared/components/tiss-guides/tiss-guide-sp-sadt-modal/sp-sadt-requested-procedure-form/sp-sadt-requested-procedure-form.component';
import { ReportModalComponent } from 'src/app/shared/components/report-modal/report-modal.component';
import { AlertModalComponent } from 'src/app/shared/components/alert-modal/alert-modal.component';
@Component({
  selector: 'app-tiss-guide-sp-sadt-history',
  templateUrl: './tiss-guide-sp-sadt-history.component.html',
  styleUrls: ['./tiss-guide-sp-sadt-history.component.css']
})
export class TissGuideSpSadtHistoryComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<TissGuideSpSadtModalComponent>,
    private formBuilder: FormBuilder,
    private maskService: MaskService,
    private alertService: AlertService,
    private healthcareAgreementService: HealthcareAgreementService,
    private spSadtService: SpSadtService,
    private orchestratorPatientGuideService: OrchestratorPatientGuideService,
    private guideService: GuideService,
  ) { matDialogRef.disableClose = true; }

  @ViewChild('spSadtRequestedProcedureFormComponent', { static: false }) spSadtRequestedProcedureFormComponent: SpSadtRequestedProcedureFormComponent;
  @ViewChild('spSadtExecutedProcedureTable', { static: false }) spSadtExecutedProcedureTable: SpSadtExecutedProcedureTableComponent;

  public masks: Masks = this.maskService.getMasks();
  public mainModel: FormGroup;
  public healthcareAgreementStruct: HealthcareAgreementStruct = new HealthcareAgreementStruct();
  public spSadtGuideStruct: SpSadtGuideStruct;
  public tissBilling: TissBilling = new TissBilling();
  public lookups: LookupResponse = this.data.lookups;

  public listGuideStatus: GuideStatus[] = this.lookups.listGuideStatus;
  public listProfessionalCouncil: ProfessionalCouncil[] = [];
  public listState: State[] = [];
  public listCbo: Cbo[] = [];
  public listAttendanceAspect: AttendanceAspect[] = [];
  public listSpecialCoverage: SpecialCoverage[] = [];
  public listProviderIdentityType: ProviderIdentityType[] = [];
  public listAttendanceType: AttendanceType[] = [];
  public listAccidentIndication: AccidentIndication[] = [];
  public listAppointmentType: AppointmentType[] = [];
  public listOccupationalHealth: OccupationalHealth[] = [];
  public listCareRegime: CareRegime[] = [];
  public listTerminationReason: TerminationReason[] = [];
  public listProcedure: ProcedureStruct[] = [];
  public listMainGuide: Guide[] = [];
  public listSpSadtRequestedProcedure: SpSadtRequestedProcedureStruct[] = [];
  public listSpSadtRequiredField: SpSadtRequiredField[] = [];
  public listHealthcareAgreement: HealthcareAgreement[] = [];
  public listProfessionalAgreementStruct: ProfessionalAgreementStruct[] = [];
  public listSpSadtExecutedProcedure: SpSadtExecutedProcedureStruct[] = [];

  public spSadtFieldsMaxLengthEnum: typeof SpSadtFieldsMaxLengthEnum = SpSadtFieldsMaxLengthEnum;
  public guideType: number = GuideTypeEnum.spSadt;

  public tussTerminologyGroupMap: Map<number, TerminologyGroup>;
  public accessMap: Map<number, Access>;
  public techniqueMap: Map<number, Technique>;
  public participationDegreeMap: Map<number, ParticipationDegree>;
  public cboMap: Map<number, Cbo>;

  public urlListAnsRegistration: string = environment.urlApiPrivateBilling + 'HealthcareAgreement/getAllByHealthUnit';
  public urlListProfessionalAgreement: string = null;
  public requesterCodeMask: string = '';
  public executantCodeMask: string = '';

  public guideNumber: string = null;
  public formArraySize: number = 0;
  public idGuide: number;
  public idHealthcareAgreement: number = null;
  public requesterCodeMaxLength: number = SpSadtFieldsMaxLengthEnum.requesterCodeOperator;
  public executantCodeMaxLength: number = SpSadtFieldsMaxLengthEnum.requesterCodeOperator;
  public idSpSadtGuide: number = null;
  public idEpisode: number = this.data.idEpisode;;

  public isFirstLoading: boolean = true;
  public manualMainGuide: boolean = false;
  public isLoading: boolean = true;
  public choseAns: boolean = false;

  ngOnInit(): void {
    this.accessMap = this.generateAccessMap(this.lookups.listAccess);
    this.techniqueMap = this.generateTechniqueMap(this.lookups.listTechnique);
    this.tussTerminologyGroupMap = this.generateTerminologyGroupMap(this.lookups.listTussTerminologyGroup);
    this.cboMap = this.generateCboMap(this.lookups.listCbo);
    this.participationDegreeMap = this.generateParticipationDegreeMap(this.lookups.listParticipationDegree);

    this.mainModel = this.formBuilder.group({
      firstChildGroup: this.formBuilder.group({
        ansRegistration: ['', [Validators.required, Validators.maxLength(SpSadtFieldsMaxLengthEnum.ansRegistration)]],// campo 1
        idProfessionalAgreement: [null, [Validators.required]],
      }),
      guideNumber: ['', [Validators.required, Validators.maxLength(this.spSadtFieldsMaxLengthEnum.guideNumber)]],// campo 2
      mainGuideNumber: [null, [Validators.maxLength(SpSadtFieldsMaxLengthEnum.mainGuideNumber)]],// campo 3
      idGuideStatus: ['', [Validators.required]],
      authorizationDate: ['', [DateValidator()]],// campo 4
      password: ['', [Validators.maxLength(SpSadtFieldsMaxLengthEnum.password)]],// campo 5
      passwordExpirationDate: ['', [DateValidator()]],// campo 6
      guideNumberAssignedByTheOperator: ['', [Validators.maxLength(SpSadtFieldsMaxLengthEnum.guideNumberAssignedByTheOperator)]],// campo 7

      beneficiaryDocumentNumber: ['', [Validators.required, Validators.maxLength(SpSadtFieldsMaxLengthEnum.beneficiaryDocumentNumber)]],// campo 8
      documentExpirationDate: ['', [DateValidator()]],// campo 9
      beneficiarySocialName: ['', [Validators.maxLength(SpSadtFieldsMaxLengthEnum.beneficiarySocialName)]],// campo 89
      beneficiaryName: ['', [Validators.required, Validators.maxLength(SpSadtFieldsMaxLengthEnum.beneficiaryName)]],// campo 10
      newbornAttendanceIndicator: [false, [Validators.required]],// campo 12

      idRequesterProviderIdentityType: [null, [Validators.required]],
      requesterContractorOperatorCode: ['', [Validators.required, Validators.maxLength(SpSadtFieldsMaxLengthEnum.requesterContractorOperatorCode)]],// campo 13
      requesterContractorName: ['', [Validators.required, Validators.maxLength(SpSadtFieldsMaxLengthEnum.requesterContractorName)]],// campo 14
      requesterProfessionalName: ['', [Validators.maxLength(SpSadtFieldsMaxLengthEnum.requesterProfessionalName)]],// campo 15
      idRequesterProfessionalCouncil: ['', [Validators.required]],// campo 16
      requesterProfessionalCouncilNumber: ['', [Validators.required, Validators.maxLength(SpSadtFieldsMaxLengthEnum.requesterProfessionalCouncilNumber)]],// campo 17
      idRequesterProfessionalCouncilState: ['', [Validators.required]],// campo 18
      idRequesterProfessionalCbo: ['', [Validators.required]],// campo 19

      idAttendanceAspect: [null, [Validators.required]],// campo 21
      requestDate: ['', [DateValidator()]],// campo 22
      clinicalIndication: ['', [Validators.maxLength(SpSadtFieldsMaxLengthEnum.clinicalIndication)]],// campo 23
      idSpecialCoverage: [null],// campo 90

      idProviderIdentityType: [null, [Validators.required]],
      executantContractorOperatorCode: ['', [Validators.required, Validators.maxLength(SpSadtFieldsMaxLengthEnum.executantContractorOperatorCode)]],// campo 29
      executantContractorName: ['', [Validators.required, Validators.maxLength(SpSadtFieldsMaxLengthEnum.executantContractorName)]],// campo 30
      executantCnes: ['', [Validators.required, Validators.maxLength(SpSadtFieldsMaxLengthEnum.executantCnes)]],// campo 31

      idAttendanceType: [null, [Validators.required]],// campo 32
      idAccidentIndication: [null, [Validators.required]],// campo 33
      idAppointmentType: [null],// campo 34
      idTerminationReason: [null],// campo 35
      idCareRegime: [null, [Validators.required]],// campo 91
      idOccupationalHealth: [null],// campo 92

      listDateSerialProcedure: this.formBuilder.array([], { validators: datesInCrescentOrderValidator }),

      observationJustification: ['', [Validators.maxLength(SpSadtFieldsMaxLengthEnum.observationJustification)]],// campo 58

      totalProcedureValue: [0],// campo 59
      totalFeesRentValue: [0],// campo 60
      totalMaterialValue: [0],// campo 61
      totalOpmeValue: [0],// campo 62
      totalMedicationValue: [0],// campo 63
      totalMedicinalGasValue: [0],// campo 64
      totalValue: [{ value: 0, disabled: true }, [Validators.required]],// campo 65
    });
    this.mainModel.disable();
    this.mainModel.get('firstChildGroup').get('ansRegistration').enable();
    this.maplookups();
    this.spSadtGuideStruct = this.data.guide;
    this.idGuide = this.spSadtGuideStruct.guide.idGuide;
    this.idHealthcareAgreement = this.spSadtGuideStruct.guide.idHealthcareAgreement;
    this.populate();
  }

  close() {
    this.matDialogRef.close();
  }

  validateRequest(request: SpSadtGuideRequest) {
    this.isLoading = true;
    this.spSadtService.validatePost(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.alertService.show('Sucesso', "Guia validada com sucesso!", AlertType.success);
        this.isLoading = false;
        this.matDialogRef.close({ confirm: true, spSadtGuideStruct: request.spSadtGuideStruct, isAttendance: this.data.isAttendance });
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  maplookups() {
    this.lookups = this.data.lookups;
    this.listGuideStatus = this.lookups.listGuideStatus;
    this.listProfessionalCouncil = this.lookups.listProfessionalCouncil;
    this.listState = this.lookups.listState;
    this.listCbo = this.lookups.listCbo;
    this.listAttendanceAspect = this.lookups.listAttendanceAspect;
    this.listProviderIdentityType = this.lookups.listProviderIdentityType;
    this.listSpecialCoverage = this.lookups.listSpecialCoverage;
    this.listAttendanceType = this.lookups.listAttendanceType;
    this.listAccidentIndication = this.lookups.listAccidentIndication;
    this.listAppointmentType = this.lookups.listAppointmentType;
    this.listOccupationalHealth = this.lookups.listOccupationalHealth;
    this.listTerminationReason = this.lookups.listTerminationReason;
    this.listCareRegime = this.lookups.listCareRegime;
    this.tussTerminologyGroupMap = this.generateTerminologyGroupMap(this.lookups.listTussTerminologyGroup);
    this.accessMap = this.generateAccessMap(this.lookups.listAccess);
    this.techniqueMap = this.generateTechniqueMap(this.lookups.listTechnique);
    this.participationDegreeMap = this.generateParticipationDegreeMap(this.lookups.listParticipationDegree);
    this.cboMap = this.generateCboMap(this.lookups.listCbo);
  }

  getMainGuides() {
    this.spSadtService.getMainGuides(this.data.idEpisode, this.idHealthcareAgreement).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.idEpisode = this.data.idEpisode;
        this.listMainGuide = response.listMainGuide;
        this.isLoading = false;
        this.isFirstLoading = false;
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  populate() {

    if (Number.isInteger(this.spSadtGuideStruct?.spSadtGuide?.idMainGuide)){
      this.manualMainGuide = false;
      this.mainModel.get('mainGuideNumber').setValue(this.spSadtGuideStruct.spSadtGuide.idMainGuide);
    }
    else if (Number.isInteger(this.spSadtGuideStruct?.spSadtGuide?.mainGuideNumber)){
      this.manualMainGuide = true;
      this.mainModel.get('mainGuideNumber').setValue(this.spSadtGuideStruct.spSadtGuide.mainGuideNumber.toString());    }     

    this.mainModel.get('firstChildGroup').get('ansRegistration').setValue(this.spSadtGuideStruct.guide.idHealthcareAgreement);

    this.guideNumber = this.spSadtGuideStruct.guideNumber;
    this.mainModel.get('guideNumber').setValue(this.spSadtGuideStruct.guideNumber);

    this.mainModel.get('idGuideStatus').setValue(this.spSadtGuideStruct.guide.idGuideStatus);

    this.mainModel.get('authorizationDate').setValue(this.maskService.formatDateToString(this.spSadtGuideStruct.spSadtGuide.authorizationDate, false));
    this.mainModel.get('password').setValue(this.spSadtGuideStruct.spSadtGuide.password);
    this.mainModel.get('passwordExpirationDate').setValue(this.maskService.formatDateToString(this.spSadtGuideStruct.spSadtGuide.passwordExpirationDate, false));
    this.mainModel.get('guideNumberAssignedByTheOperator').setValue(this.spSadtGuideStruct.spSadtGuide.guideNumberAssignedByTheOperator);
    this.mainModel.get('beneficiaryDocumentNumber').setValue(this.spSadtGuideStruct.spSadtGuide.beneficiaryDocumentNumber);
    this.mainModel.get('documentExpirationDate').setValue(this.maskService.formatDateToString(this.spSadtGuideStruct.spSadtGuide.documentExpirationDate, false));
    this.mainModel.get('beneficiarySocialName').setValue(this.spSadtGuideStruct.spSadtGuide.beneficiarySocialName);
    this.mainModel.get('beneficiaryName').setValue(this.spSadtGuideStruct.spSadtGuide.beneficiaryName);
    this.mainModel.get('newbornAttendanceIndicator').setValue(this.spSadtGuideStruct.spSadtGuide.newbornAttendanceIndicator);
    this.mainModel.get('requesterContractorOperatorCode').setValue(this.spSadtGuideStruct.spSadtGuide.requesterContractorOperatorCode);
    this.mainModel.get('requesterContractorName').setValue(this.spSadtGuideStruct.spSadtGuide.requesterContractorName);
    this.mainModel.get('idAttendanceAspect').setValue(this.spSadtGuideStruct.spSadtGuide.idAttendanceAspect);
    this.mainModel.get('requestDate').setValue(this.maskService.formatDateToString(this.spSadtGuideStruct.spSadtGuide.requestDate, false));
    this.mainModel.get('clinicalIndication').setValue(this.spSadtGuideStruct.spSadtGuide.clinicalIndication);
    this.mainModel.get('idSpecialCoverage').setValue(this.spSadtGuideStruct.spSadtGuide.idSpecialCoverage);
    this.mainModel.get('idProviderIdentityType').setValue(this.spSadtGuideStruct.spSadtGuide.idProviderIdentityType);
    this.mainModel.get('idRequesterProviderIdentityType').setValue(this.spSadtGuideStruct.spSadtGuide.idRequesterProviderIdentityType);
    this.mainModel.get('idAttendanceType').setValue(this.spSadtGuideStruct.spSadtGuide.idAttendanceType);
    this.mainModel.get('idAccidentIndication').setValue(this.spSadtGuideStruct.spSadtGuide.idAccidentIndication);
    this.mainModel.get('idAppointmentType').setValue(this.spSadtGuideStruct.spSadtGuide.idAppointmentType);
    this.mainModel.get('idTerminationReason').setValue(this.spSadtGuideStruct.spSadtGuide.idTerminationReason);
    this.mainModel.get('idCareRegime').setValue(this.spSadtGuideStruct.spSadtGuide.idCareRegime);
    this.mainModel.get('idOccupationalHealth').setValue(this.spSadtGuideStruct.spSadtGuide.idOccupationalHealth);
    this.mainModel.get('observationJustification').setValue(this.spSadtGuideStruct.spSadtGuide.observationJustification);
    this.mainModel.get('executantContractorOperatorCode').setValue(this.spSadtGuideStruct.spSadtGuide.executantContractorOperatorCode);
    this.mainModel.get('executantContractorName').setValue(this.spSadtGuideStruct.spSadtGuide.executantContractorName);
    this.mainModel.get('executantCnes').setValue(this.spSadtGuideStruct.spSadtGuide.executantCnes);
    this.mainModel.get('firstChildGroup').get('idProfessionalAgreement').setValue(this.spSadtGuideStruct.spSadtGuide.idProfessionalAgreement);
    this.mainModel.get('requesterProfessionalName').setValue(this.spSadtGuideStruct.spSadtGuide.requesterProfessionalName);
    this.mainModel.get('idRequesterProfessionalCouncil').setValue(this.spSadtGuideStruct.spSadtGuide.idRequesterProfessionalCouncil);
    this.mainModel.get('firstChildGroup').get('idProfessionalAgreement').setValue(this.spSadtGuideStruct.spSadtGuide.idProfessionalAgreement);
    this.mainModel.get('requesterProfessionalCouncilNumber').setValue(this.spSadtGuideStruct.spSadtGuide.requesterProfessionalCouncilNumber);
    this.mainModel.get('idRequesterProfessionalCouncilState').setValue(this.spSadtGuideStruct.spSadtGuide.idRequesterProfessionalCouncilState);
    this.mainModel.get('idRequesterProfessionalCbo').setValue(this.spSadtGuideStruct.spSadtGuide.idRequesterProfessionalCbo);

    //Valores
    this.mainModel.get('totalProcedureValue').setValue(this.spSadtGuideStruct.spSadtGuide.totalProceduresValue);
    this.mainModel.get('totalFeesRentValue').setValue(this.spSadtGuideStruct.spSadtGuide.totalFeesRentalsValue);
    this.mainModel.get('totalMaterialValue').setValue(this.spSadtGuideStruct.spSadtGuide.totalMaterialsValue);
    this.mainModel.get('totalOpmeValue').setValue(this.spSadtGuideStruct.spSadtGuide.totalOpmeValue);
    this.mainModel.get('totalMedicationValue').setValue(this.spSadtGuideStruct.spSadtGuide.totalMedicationsValue);
    this.mainModel.get('totalMedicinalGasValue').setValue(this.spSadtGuideStruct.spSadtGuide.totalMedicinalGasesValue);
    this.mainModel.get('totalValue').setValue(this.spSadtGuideStruct.spSadtGuide.totalGeneralValue);
    this.listSpSadtExecutedProcedure = [...this.spSadtGuideStruct.listSpSadtExecutedProcedure];
    this.listSpSadtRequestedProcedure = [...this.spSadtGuideStruct.listSpSadtRequestedProcedure];
    this.spSadtGuideStruct.listSpSadtSerialProcedure.forEach(x => {
      (this.mainModel.controls['listDateSerialProcedure'] as FormArray).push(
        this.formBuilder.group({
          idSpSadtSerialProcedure: [x.idSpSadtSerialProcedure],
          idSpSadtGuide: [x.idSpSadtGuide],
          date: [this.maskService.formatDateToString(x.executionDate), [Validators.required, DateValidator()]],
        })
      );
    });

    this.mainModel.get('listDateSerialProcedure').updateValueAndValidity();

    this.formArraySize = this.spSadtGuideStruct.listSpSadtSerialProcedure.length;

    if (this.spSadtGuideStruct && this.spSadtGuideStruct.listSpSadtRequestedProcedure) {
      this.spSadtGuideStruct.listSpSadtRequestedProcedure.forEach(x => {
        if (x.procedure && x.procedure.terminology && this.tussTerminologyGroupMap.has(x.procedure.terminology.idTerminologyGroup)) {
          const terminologyGroup = this.data.lookups.listTussTerminologyGroup.find(y => y.idTerminologyGroup == x.procedure.terminology.idTerminologyGroup);
          x.terminologyGroupCode = terminologyGroup.terminologyGroupCode;
          x.idTerminologyGroup = terminologyGroup.idTerminologyGroup;
        }
      });

      this.listProcedure = this.spSadtGuideStruct.listSpSadtRequestedProcedure.map(x => x.procedure);
    }

    this.idHealthcareAgreement = this.healthcareAgreementStruct.idHealthcareAgreement;
    this.idSpSadtGuide = this.spSadtGuideStruct.spSadtGuide.idSpSadtGuide;

    this.mainModel.disable();

    this.disableField();
    this.populateAnsSelects();
  }

  populateAnsSelects() {
    this.isLoading = true;

    let idHealthcareAgreementForSearch = this.mainModel.get('firstChildGroup').get('ansRegistration').value;
    this.urlListProfessionalAgreement = null;

    this.healthcareAgreementService.getAllSelect(idHealthcareAgreementForSearch, this.guideType).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.healthcareAgreementStruct = response.healthcareAgreementStruct;
        this.idHealthcareAgreement = response.healthcareAgreementStruct.idHealthcareAgreement;
        this.tissBilling = response.tissBilling;
        this.listSpSadtRequiredField = response.listSpSadtRequiredField;

        this.guideNumber = response.guideNumber;

        this.listProfessionalAgreementStruct = response.listProfessionalAgreementStruct.filter(x => {
          if (x.isActive || x.idProfessionalAgreement == this.spSadtGuideStruct.spSadtGuide.idProfessionalAgreement)
            return true;
          else if (this.spSadtGuideStruct.listSpSadtExecutedProcedure.find(y => y.listProfessional.find(p => p.idProfessionalAgreement == x.idProfessionalAgreement)))
            return true;
        });

        if (!this.listProfessionalAgreementStruct || this.listProfessionalAgreementStruct.length == 0)
          this.openAlertModal();

        if (response) {
          this.disableField();

          if (response.listSpSadtRequiredField)
            this.verifyRequiredFields();
        }

        if (this.idEpisode && idHealthcareAgreementForSearch)
          this.getMainGuides();

        this.isLoading = false;
        this.isFirstLoading = false;
        this.choseAns = true;

        if (response.healthcareAgreementStruct) {
          let url = `ProfessionalAgreement/idHealthcareAgreement/${response.healthcareAgreementStruct.idHealthcareAgreement}`

          this.urlListProfessionalAgreement = environment.urlApiPrivateBilling + url;
        }

        if (this.data.isComingGuideReport) {
          if (this.spSadtGuideStruct.isEditingAllowed)
            this.mainModel.enable();
          else if (!this.spSadtGuideStruct.isEditingAllowed)
            this.mainModel.disable();
        }
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  enableFields() {
    if (this.tissBilling) {
      this.mainModel.get('idAttendanceType').setValue(this.tissBilling?.idAttendanceType);
      this.mainModel.get('executantCnes').setValue(this.tissBilling?.cnes ? this.tissBilling.cnes : '9999999');
      this.mainModel.get('idProviderIdentityType').setValue(this.tissBilling?.idProviderIdentityType);
      this.mainModel.get('executantContractorName').setValue(this.tissBilling?.providerName);
      this.mainModel.get('executantContractorOperatorCode').setValue(this.tissBilling?.providerCode);
      this.mainModel.get('idAttendanceAspect').setValue(this.tissBilling?.idAttendanceAspect);
      this.mainModel.get('idAccidentIndication').setValue(this.tissBilling?.idAccidentIndication);
      this.mainModel.get('idAppointmentType').setValue(this.tissBilling?.idAppointmentType);

      if (this.mainModel.get('idProviderIdentityType'))
        this.checkProviderIdentityType(this.tissBilling?.idProviderIdentityType);
    }

    this.mainModel.get('idGuideStatus').setValue(GuideStatusEnum.NovaGuia);
    this.mainModel.get('beneficiaryName').setValue(this.data.patientName);
    this.mainModel.get('beneficiarySocialName').setValue(this.data.patientSocialName);

    this.mainModel.enable();

    if (this.guideNumber != null || this.healthcareAgreementStruct?.nextGuideNumber != null)
      this.mainModel.get('guideNumber').setValue(this.guideNumber != null ? this.guideNumber : this.healthcareAgreementStruct.nextGuideNumber);
      this.disableField();
  }

  verifyRequiredFields() {
    this.listSpSadtRequiredField.forEach(x => {
      if (x.idLookupSpSadtRequiredField == LookupSpSadtRequiredFieldEnum.NGuiaPrincipal) {
        this.mainModel.get('mainGuideNumber').setValidators([Validators.required]);
        this.mainModel.get('mainGuideNumber').updateValueAndValidity();
      }
      else if (x.idLookupSpSadtRequiredField == LookupSpSadtRequiredFieldEnum.DataAutorizacao) {
        this.mainModel.get('authorizationDate').setValidators([Validators.required, DateValidator()]);
        this.mainModel.get('authorizationDate').updateValueAndValidity();
      }
      else if (x.idLookupSpSadtRequiredField == LookupSpSadtRequiredFieldEnum.SenhaAutorizacao) {
        this.mainModel.get('password').setValidators([Validators.required]);
        this.mainModel.get('password').updateValueAndValidity();
      }
      else if (x.idLookupSpSadtRequiredField == LookupSpSadtRequiredFieldEnum.DataValidadeSenha) {
        this.mainModel.get('passwordExpirationDate').setValidators([Validators.required, DateValidator()]);
        this.mainModel.get('passwordExpirationDate').updateValueAndValidity();
      }
      else if (x.idLookupSpSadtRequiredField == LookupSpSadtRequiredFieldEnum.NGuiaOperadora) {
        this.mainModel.get('guideNumberAssignedByTheOperator').setValidators([Validators.required]);
        this.mainModel.get('guideNumberAssignedByTheOperator').updateValueAndValidity();
      }
      else if (x.idLookupSpSadtRequiredField == LookupSpSadtRequiredFieldEnum.ValidadeCarteira) {
        this.mainModel.get('documentExpirationDate').setValidators([Validators.required, DateValidator()]);
        this.mainModel.get('documentExpirationDate').updateValueAndValidity();
      }
      else if (x.idLookupSpSadtRequiredField == LookupSpSadtRequiredFieldEnum.NomeProfissionalSolicitante) {
        this.mainModel.get('requesterProfessionalName').setValidators([Validators.required]);
        this.mainModel.get('requesterProfessionalName').updateValueAndValidity();
      }
      else if (x.idLookupSpSadtRequiredField == LookupSpSadtRequiredFieldEnum.DataSolicitacao) {
        this.mainModel.get('requestDate').setValidators([Validators.required, DateValidator()]);
        this.mainModel.get('requestDate').updateValueAndValidity();
      }
      else if (x.idLookupSpSadtRequiredField == LookupSpSadtRequiredFieldEnum.IndicacaoClinica) {
        this.mainModel.get('clinicalIndication').setValidators([Validators.required]);
        this.mainModel.get('clinicalIndication').updateValueAndValidity();
      }
      else if (x.idLookupSpSadtRequiredField == LookupSpSadtRequiredFieldEnum.ObservacaoJustificativa) {
        this.mainModel.get('observationJustification').setValidators([Validators.required]);
        this.mainModel.get('observationJustification').updateValueAndValidity();
      }
      else if (x.idLookupSpSadtRequiredField == LookupSpSadtRequiredFieldEnum.CoberturaEspecial) {
        this.mainModel.get('idSpecialCoverage').setValidators([Validators.required]);
        this.mainModel.get('idSpecialCoverage').updateValueAndValidity;
      }
      else if (x.idLookupSpSadtRequiredField == LookupSpSadtRequiredFieldEnum.SaudeOcupacional) {
        this.mainModel.get('idOccupationalHealth').setValidators([Validators.required]);
        this.mainModel.get('idOccupationalHealth').updateValueAndValidity();
      }
    });
  }

  checkProviderIdentityType(idProviderIdentityType: number) {
    if (idProviderIdentityType == ProviderIdentityTypeEnum.cpf) {
      this.executantCodeMask = this.masks.cpf;
      this.executantCodeMaxLength = null;
      this.mainModel.get('executantContractorOperatorCode').setValidators([Validators.required, Validators.maxLength(SpSadtFieldsMaxLengthEnum.requesterCodeOperatorCpf), VerifyCPF()]);
    }
    else if (idProviderIdentityType == ProviderIdentityTypeEnum.cnpj) {
      this.executantCodeMask = this.masks.cnpj;
      this.executantCodeMaxLength = null;
      this.mainModel.get('executantContractorOperatorCode').setValidators([Validators.required, Validators.maxLength(SpSadtFieldsMaxLengthEnum.requesterCodeOperatorCnpj), VerifyCNPJ()]);
    }
    else if (idProviderIdentityType == ProviderIdentityTypeEnum.providerCode) {
      this.executantCodeMask = '';
      this.executantCodeMaxLength = SpSadtFieldsMaxLengthEnum.requesterCodeOperator;
      this.mainModel.get('executantContractorOperatorCode').setValidators([Validators.required, Validators.maxLength(SpSadtFieldsMaxLengthEnum.requesterCodeOperatorCnpj)])
    }

    if (this.spSadtGuideStruct && this.spSadtGuideStruct.spSadtGuide) {
      this.mainModel.get('executantContractorOperatorCode').setValue(this.spSadtGuideStruct?.spSadtGuide?.executantContractorOperatorCode);
      this.mainModel.get('executantContractorName').setValue(this.spSadtGuideStruct?.spSadtGuide?.executantContractorName);
      this.mainModel.get('executantCnes').setValue(this.spSadtGuideStruct?.spSadtGuide?.executantCnes);
    }
  }

  checkRequesterProviderIdentityType(idRequesterProviderIdentityType: number) {
    if (idRequesterProviderIdentityType == ProviderIdentityTypeEnum.cpf) {
      this.requesterCodeMask = this.masks.cpf;
      this.requesterCodeMaxLength = null;
      this.mainModel.get('requesterContractorOperatorCode').setValidators([Validators.required, Validators.maxLength(SpSadtFieldsMaxLengthEnum.requesterContractorOperatorCode), VerifyCPF()]);
    }
    else if (idRequesterProviderIdentityType == ProviderIdentityTypeEnum.cnpj) {
      this.requesterCodeMask = this.masks.cnpj;
      this.requesterCodeMaxLength = null;
      this.mainModel.get('requesterContractorOperatorCode').setValidators([Validators.required, Validators.maxLength(SpSadtFieldsMaxLengthEnum.requesterContractorOperatorCode), VerifyCNPJ()]);
    }
    else if (idRequesterProviderIdentityType == ProviderIdentityTypeEnum.providerCode) {
      this.requesterCodeMask = '';
      this.requesterCodeMaxLength = SpSadtFieldsMaxLengthEnum.requesterCodeOperator;
      this.mainModel.get('requesterContractorOperatorCode').setValidators([Validators.required, Validators.maxLength(SpSadtFieldsMaxLengthEnum.requesterContractorOperatorCode)])
    }
  }

  createInput() {
    return this.formBuilder.group({
      idSpSadtSerialProcedure: [0],
      idSpSadtGuide: [0],
      date: ['', [Validators.required, DateValidator()]],
    });
  }

  addNext() {
    const listDateSerialProcedure = this.mainModel.get('listDateSerialProcedure') as FormArray;
    listDateSerialProcedure.push(this.createInput());

    this.formArraySize = listDateSerialProcedure.length;
  }

  removeLast() {
    if (this.formArraySize != 0)
      (this.mainModel.controls['listDateSerialProcedure'] as FormArray).removeAt(this.formArraySize - 1);

    this.formArraySize = (this.mainModel.controls['listDateSerialProcedure'] as FormArray).length;
  }

  attSpSadtRequestedProcedure(event: any) {
    this.listSpSadtRequestedProcedure = [...event.listSpSadtRequestedProcedure];
  }

  attSpSadtExecutedProcedure(event: any) {
    this.listSpSadtExecutedProcedure = [...event.listSpSadtExecutedProcedure];
    this.calculateTotalProcedureMedicineValue();
  }

  generateTerminologyGroupMap(listTussTerminologyGroup: any[]): Map<number, TerminologyGroup> {
    const map = new Map();
    listTussTerminologyGroup.forEach(x => {
      map.set(x.idTerminologyGroup, x);
    });

    return map;
  }

  generateAccessMap(listAccess: any[]): Map<number, Access> {
    const map = new Map();
    listAccess.forEach(x => {
      map.set(x.idAccessLookup, x);
    });

    return map;
  }

  generateTechniqueMap(listTechnique: Technique[]): Map<number, Technique> {
    const map = new Map();
    listTechnique.forEach(x => {
      map.set(x.idTechniqueLookup, x);
    });

    return map;
  }

  generateParticipationDegreeMap(listParticipationDegree: any[]): Map<number, ParticipationDegree> {
    const map = new Map();
    listParticipationDegree.forEach(x => {
      map.set(x.idParticipationDegreeLookup, x);
    });

    return map;
  }

  generateCboMap(listCbo: any[]): Map<number, Cbo> {
    const map = new Map();
    listCbo.forEach(x => {
      map.set(x.idCbo, x);
    });

    return map;
  }

  onProfessionalChange(professionalStruct: any) {
    this.mainModel.get('firstChildGroup').get('idProfessionalAgreement').setValue(professionalStruct.idProfessionalAgreement);

    let professional = this.listProfessionalAgreementStruct.find(x => x.idProfessionalAgreement == professionalStruct.idProfessionalAgreement);
    this.mainModel.get('requesterProfessionalName').setValue(professional.nameProfessional);
    this.mainModel.get('idRequesterProfessionalCouncil').setValue(professional.idProfessionalCouncil);
    this.mainModel.get('firstChildGroup').get('idProfessionalAgreement').setValue(professional.idProfessionalAgreement);
    this.mainModel.get('requesterProfessionalCouncilNumber').setValue(professional.numberProfessional.toString());
    this.mainModel.get('idRequesterProfessionalCouncilState').setValue(professional.idState);
    this.mainModel.get('idRequesterProfessionalCbo').setValue(professional.idCbo);
  }

  disableField() {
    this.mainModel.get('idRequesterProfessionalCbo').disable();
    this.mainModel.get('idRequesterProfessionalCouncilState').disable();
    this.mainModel.get('requesterProfessionalCouncilNumber').disable();
    this.mainModel.get('idRequesterProfessionalCouncil').disable();
    this.mainModel.get('requesterProfessionalName').disable();
    this.mainModel.get('totalValue').disable();
    this.mainModel.get('guideNumber').disable();
  }

  onManualAdmissionSelect(event: any) {
    this.mainModel.get('mainGuideNumber').setValue(null);
  }

  private throwException(error: string) {
    console.log(error);
    this.alertService.show('Erro inesperado', error, AlertType.error);
    this.isLoading = false;
  }

  calculateTotalProcedureMedicineValue() {
    let totalProcedure = 0;
    let totalMedicine = 0;

    this.listSpSadtExecutedProcedure.forEach(x => {
      if (x.idTerminologyGroup == TerminologyGroupEnum.medicamentos)
        totalMedicine += x.procedureTotalValue
      else if (x.idTerminologyGroup == TerminologyGroupEnum.procedimentos)
        totalProcedure += x.procedureTotalValue
    });

    this.mainModel.get("totalProcedureValue").setValue(totalProcedure);
    this.mainModel.get("totalMedicationValue").setValue(totalMedicine);

    this.calculateTotal();
  }

  calculateTotal() {
    let total: number = parseFloat(this.mainModel.get("totalProcedureValue").value) +
      parseFloat(this.mainModel.get("totalFeesRentValue").value) +
      parseFloat(this.mainModel.get("totalMaterialValue").value) +
      parseFloat(this.mainModel.get("totalOpmeValue").value) +
      parseFloat(this.mainModel.get("totalMedicationValue").value) +
      parseFloat(this.mainModel.get("totalMedicinalGasValue").value);

    this.mainModel.get("totalValue").setValue(total);
  }

  resetChildComponent() {
    if (this.spSadtRequestedProcedureFormComponent && typeof this.spSadtRequestedProcedureFormComponent.reset === 'function')
      this.spSadtRequestedProcedureFormComponent.reset();

    if (this.spSadtExecutedProcedureTable && typeof this.spSadtExecutedProcedureTable.reset === 'function')
      this.spSadtExecutedProcedureTable.reset();
  }

  openAlertModal() {
    let reportDialog = this.dialog.open(AlertModalComponent, {
      data: {
        title: "Atenção!",
        description: "Este convênio não possui profissionais vinculados. Execute o cadastro para salvar a guia corretamente.",
      },
    });
  }

  inputNumber(formName: string) {
    let value = this.mainModel.get(formName).value ? this.mainModel.get(formName).value : '';
    if (value) {
      value = value.replace(/[^0-9]/g, '');
      value = value.replace(/(\..*)\./g, '$1');
      this.mainModel.get(formName).setValue(value);
    }
  }
}