import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdministrationTypeEnum, DilutionEnum, FrequencyEnum, PresentationEnum, UsageEnum } from 'src/app/shared/enum/prescription.enum';
import { MedicineAdministrationTypeEnum } from 'src/app/shared/enum/medicine-administration-type.enum';
import { MedicineStruct } from 'src/app/shared/services/structs/pharmacy/medicine.struct';
import { FavoriteStandardMedicine } from 'src/app/shared/services/models/medic/favorite-standard-medicine.model';
import { FavoriteMedicatedSolutionStruct } from 'src/app/shared/services/structs/medic/favorite-medicated-solution.struct';
import { FavoriteMedicineToFavoriteStandardMedicineStruct } from 'src/app/shared/services/structs/medic/favorite-medicine-to-favorite-standard-medicine.struct';

@Component({
  selector: 'app-favorite-medicated-solution-form',
  templateUrl: './favorite-medicated-solution-form.component.html',
  styleUrls: ['./favorite-medicated-solution-form.component.css']
})
export class FavoriteMedicatedSolutionFormComponent implements OnInit {

  constructor(public dialog: MatDialog,
    private alertService: AlertService,
    private formBuilder: FormBuilder
  ) { }

  @Input() medicatedSolutionStruct: FavoriteMedicatedSolutionStruct;
  @Input() selectedMedicineList: MedicineStruct[] = [];
  @Input() listSubFrequency: any[];
  @Input() lookupMedicalCareResponse: any;
  @Input() listMedicine: MedicineStruct[] = [];

  @Output() save = new EventEmitter<any>();
  @Output() cancel = new EventEmitter<any>();

  public administrationTypeListDisplay: any[] = [];

  public selectedMedicineLists: any[] = [];
  
  public listIdSelectedMedicine: FavoriteStandardMedicine[] = [];

  public model: FormGroup;
  public selectedMedicine: MedicineStruct;

  public isLoading: boolean;
  public undiluted: boolean = false;
  public isFrequency: boolean = false;

  public idAdministrationType: number;
  public idFrequency: number;
  public idTreatmentPeriod: number;
  public idPresentation: number;
  public idDilution: number;

  public administrationTypeOthersEnum: AdministrationTypeEnum = AdministrationTypeEnum.outros;
  public presentationOthersEnum: PresentationEnum = PresentationEnum.outros;
  public dilutionOthersEnum: DilutionEnum = DilutionEnum.outros;
  public ifNecessary: FrequencyEnum = FrequencyEnum.ifNecessary;
  public dilutionNoDilutionEnum: DilutionEnum = DilutionEnum.semDiluicao;

  public frequencyNow: any;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      listMedicine: this.formBuilder.array([]),
      idAdministrationType: [],
      idFrequency: [, Validators.required],
      idPresentation: [],
      idDilution: [],
      idTreatmentPeriod: [, Validators.required],
      presentation: [],
      dilution: [],
      administrationType: [],
      observation: [],
      idSubFrequency: [],
      idDilutionUnit: [],
      dilutionQuantity: [],
    });
    if (this.medicatedSolutionStruct) 
      this.populateValues();
    else {
      this.addNextMedicineInput();
      this.addNextMedicineInput();
    }
  }

  submit() {
    if (this.model.invalid) {
      this.alertService.show('Erro', "Preencha todos os campos obrigatórios!", AlertType.error);
      return;
    }

    let medicatedSolutionStruct: FavoriteMedicatedSolutionStruct = new FavoriteMedicatedSolutionStruct();

    this.model.get('listMedicine').value.forEach(x => {

      var medicine = new FavoriteMedicineToFavoriteStandardMedicineStruct();
      medicine.idMedicine = x.idMedicine;
      medicine.quantity = x.quantity ? x.quantity : null;
      medicine.idMeasurementUnit = x.idMeasurementUnit ? x.idMeasurementUnit : null;
      medicine.medicineName = this.listMedicine.find(c => c.medicine.idMedicine == medicine.idMedicine).medicine.medicineDescription;

      medicatedSolutionStruct.listMedicine.push(medicine);
    });

    medicatedSolutionStruct.idUsage = UsageEnum.interno;
    medicatedSolutionStruct.usage = this.lookupMedicalCareResponse.listUsage.find(c => c.idUsage == medicatedSolutionStruct.idUsage).usageName;
    medicatedSolutionStruct.idAdministrationType = this.model.get('idAdministrationType').value ? this.model.get('idAdministrationType').value : this.lookupMedicalCareResponse.listAdministrationType.find(x => x.idAdministrationType == MedicineAdministrationTypeEnum.NaoDefinida).idAdministrationType;
    medicatedSolutionStruct.administrationType = this.model.get('administrationType').value;
    medicatedSolutionStruct.administrationTypeName = medicatedSolutionStruct.idAdministrationType ? this.lookupMedicalCareResponse.listAdministrationType.find(x => x.idAdministrationType == medicatedSolutionStruct.idAdministrationType).administrationTypeName : null;
    medicatedSolutionStruct.idPresentation = this.model.get('idPresentation').value ? this.model.get('idPresentation').value : null;
    medicatedSolutionStruct.presentationName = medicatedSolutionStruct.idPresentation ? this.lookupMedicalCareResponse.listPresentation.find(c => c.idPresentation == medicatedSolutionStruct.idPresentation).presentationName : null;
    medicatedSolutionStruct.presentation = this.model.get('presentation').value;
    medicatedSolutionStruct.idFrequency = this.model.get('idFrequency').value ? this.model.get('idFrequency').value : null;
    medicatedSolutionStruct.frequencyName = medicatedSolutionStruct.idFrequency ? this.lookupMedicalCareResponse.listFrequency.find(c => c.idFrequency == medicatedSolutionStruct.idFrequency).frequencyName : null;
    medicatedSolutionStruct.idTreatmentPeriod = this.model.get('idTreatmentPeriod').value ? this.model.get('idTreatmentPeriod').value : null;
    medicatedSolutionStruct.treatmentPeriodName = medicatedSolutionStruct.idTreatmentPeriod ? this.lookupMedicalCareResponse.listTreatmentPeriod.find(c => c.idTreatmentPeriod == medicatedSolutionStruct.idTreatmentPeriod).treatmentPeriodName : null;
    medicatedSolutionStruct.idDilution = this.model.get('idDilution').value ? this.model.get('idDilution').value : null;
    medicatedSolutionStruct.dilutionName = this.lookupMedicalCareResponse.listDilution.find(c => c.idDilution == medicatedSolutionStruct.idDilution) ? this.lookupMedicalCareResponse.listDilution.find(c => c.idDilution == medicatedSolutionStruct.idDilution).dilutionName : null;
    medicatedSolutionStruct.dilution = this.model.get('dilution').value;
    medicatedSolutionStruct.idSubFrequency = this.model.get('idSubFrequency').value;
    medicatedSolutionStruct.idDilutionUnit = this.model.get('idDilutionUnit').value;
    medicatedSolutionStruct.dilutionQuantity = this.model.get('dilutionQuantity').value;

    if(medicatedSolutionStruct.idSubFrequency != null) 
      medicatedSolutionStruct.subFrequencyName = this.lookupMedicalCareResponse.listSubFrequency.find(x => x.idSubFrequency == medicatedSolutionStruct.idSubFrequency).subFrequencyName;
    
    if(medicatedSolutionStruct.idDilutionUnit != null) 
      medicatedSolutionStruct.dilutionUnitName = this.lookupMedicalCareResponse.listDilutionUnit.find(x => x.idDilutionUnit == medicatedSolutionStruct.idDilutionUnit).dilutionUnitName;
    
    this.save.emit({ medicatedSolutionStruct: medicatedSolutionStruct });
  }

  clickCancel() {
    this.cancel.emit();
  }

  onChangeMedicine(event: any, index: number) {
    this.setSelectedMedicines();
    this.selectedMedicine = this.listMedicine.find(f => f.medicine.idMedicine == event.value);

    if (this.selectedMedicine && this.selectedMedicine.medicine.idMeasurementUnit) 
      (this.model.controls['listMedicine'] as FormArray).at(index).get('idMeasurementUnit').setValue(this.selectedMedicine.medicine.idMeasurementUnit);
    else 
      (this.model.controls['listMedicine'] as FormArray).at(index).get('idMeasurementUnit').setValue(null);

  }

  onKey(value: string, index: number) {
    this.selectedMedicineLists[index]  = this.search(value);
  }

  search(value: string) {
    let filter = value.toLowerCase();
    return [...this.listMedicine.filter(option => option.medicine.medicineDescription.toLowerCase().includes(filter))];
  }

  onChangeDilution(event: number) {
    this.idDilution = event;

    if(this.idDilution == this.dilutionNoDilutionEnum.valueOf() || this.idDilution == null)
      this.undiluted = false;
    else
      this.undiluted = true;
    
    if (this.idDilution === this.dilutionOthersEnum.valueOf())
      this.model.get('dilution').setValidators(Validators.required);
    else {
      this.model.get('dilution').setValue(null);
      this.model.get('dilution').setValidators(Validators.nullValidator);
    }

    this.model.get('dilution').updateValueAndValidity();
  }

  onChangePresentation(event: number) {
    if (event === this.presentationOthersEnum.valueOf())
      this.model.get('presentation').setValidators(Validators.required);
    else {
      this.model.get('presentation').setValue(null);
      this.model.get('presentation').setValidators(Validators.nullValidator);
    }

    this.model.get('presentation').updateValueAndValidity();
  }

  onChangeAdministrationType(event: number) {
    this.idAdministrationType = event;
    if (event === this.administrationTypeOthersEnum.valueOf()) {
      this.model.get('administrationType').setValue(this.selectedMedicine.medicine.medicineAdministrationOther)
      this.model.get('administrationType').setValidators(Validators.required);
    }
    else {
      this.model.get('administrationType').setValue(null);
      this.model.get('administrationType').setValidators(Validators.nullValidator);
    }

    this.model.get('administrationType').updateValueAndValidity();
  }

  populateValues() {

    let naoDefinida = this.lookupMedicalCareResponse.listAdministrationType.find(f => f.idAdministrationType == MedicineAdministrationTypeEnum.NaoDefinida);
    // this.administrationTypeListDisplay = this.lookupMedicalCareResponse.listAdministrationType.filter(f => this.selectedMedicine.listIdMedicineAdministrationType.includes(f.idAdministrationType));
    // this.administrationTypeListDisplay.unshift(naoDefinida);
    
    this.medicatedSolutionStruct.listMedicine.forEach((x, index) => {
      (this.model.controls['listMedicine'] as FormArray).push(
        this.formBuilder.group({
          idMedicine: [x.idMedicine.toString(), Validators.required],
          quantity: [x.quantity],
          idMeasurementUnit: [x.idMeasurementUnit],
        })
      );
    });

    const arrayLength = (this.model.controls['listMedicine'] as FormArray).length;
    for (let i = 0; i < arrayLength; i++) {
        this.selectedMedicineLists[i] = this.selectedMedicineList;
    }

    this.model.get('idAdministrationType').setValue(this.medicatedSolutionStruct.idAdministrationType ? this.medicatedSolutionStruct.idAdministrationType : null);
    this.model.get('idFrequency').setValue(this.medicatedSolutionStruct.idFrequency ? this.medicatedSolutionStruct.idFrequency.toString() : null);
    this.model.get('idPresentation').setValue(this.medicatedSolutionStruct.idPresentation ? this.medicatedSolutionStruct.idPresentation.toString() : null);
    this.model.get('idTreatmentPeriod').setValue(this.medicatedSolutionStruct.idTreatmentPeriod ? this.medicatedSolutionStruct.idTreatmentPeriod.toString() : null);
    this.model.get('idDilution').setValue(this.medicatedSolutionStruct.idDilution ? this.medicatedSolutionStruct.idDilution.toString() : null);
    this.model.get('dilution').setValue(this.medicatedSolutionStruct.dilution);
    this.model.get('presentation').setValue(this.medicatedSolutionStruct.presentation);
    this.model.get('administrationType').setValue(this.medicatedSolutionStruct.administrationType);
    if(this.medicatedSolutionStruct.idSubFrequency != null)
    {
      this.isFrequency = true;
      this.model.get('idSubFrequency').setValue(this.medicatedSolutionStruct.idSubFrequency.toString());
    }
    if(this.medicatedSolutionStruct.idDilutionUnit != null)
    {
      this.undiluted = true;
      this.model.get('idDilutionUnit').setValue(this.medicatedSolutionStruct.idDilutionUnit);
      this.model.get('dilutionQuantity').setValue(this.medicatedSolutionStruct.dilutionQuantity);
    }

    this.setSelectedMedicines();
  }

  onChangeFrequency(event: number) {
    if (event == this.ifNecessary.valueOf()) {
      this.isFrequency = true;
    }
    else {
      this.isFrequency = false;
      this.model.get('idSubFrequency').setValue(null);
    }
  }

  createMedicineInput() {
    return this.formBuilder.group({
      idMedicine: ['', [Validators.required]],
      quantity: [],
      idMeasurementUnit: [],
    });
  }

  addNextMedicineInput() {
    const index = (this.model.controls['listMedicine'] as FormArray).length;
    (this.model.controls['listMedicine'] as FormArray).push(this.createMedicineInput());
    this.selectedMedicineLists[index] = this.selectedMedicineList;
  }

  removeMedicineInput(index: number) {
    if (index != 0) 
      (this.model.controls['listMedicine'] as FormArray).removeAt(index);
    
    this.setSelectedMedicines();
  }

  setSelectedMedicines() {
    let medicines = this.model.get('listMedicine')['controls'];
    this.listIdSelectedMedicine = medicines.map(x => x.value.idMedicine);
  }

}