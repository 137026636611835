import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ProfileService } from 'src/app/shared/services/API/user/profile.service';
import { ModuleProfileService } from 'src/app/shared/services/API/user/module-profile.service';
import { ModuleProfileStruct } from 'src/app/shared/services/structs/user/module-profile.struct';
import { ProfileRequest } from 'src/app/shared/services/requests/user/profile.request';
import { RoleStruct } from 'src/app/shared/services/structs/user/role.struct';
import { UpgradeModalComponent } from 'src/app/shared/components/upgrade-modal/upgrade-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UtilService } from 'src/app/shared/services/util.service';

export interface Task {
  name: string;
  completed: boolean;
  color: ThemePalette;
  subtasks?: Task[];
}

@Component({
  selector: 'app-profile-register',
  templateUrl: './profile-register.component.html',
  styleUrls: ['./profile-register.component.css']
})
export class ProfileRegisterComponent implements OnInit {

  expandedIndex: number = 0;
  menuModuleEnum: MenuModuleEnum = MenuModuleEnum.master;
  menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.master_profile;
  model: FormGroup;
  isLoading: boolean = false;
  isFirstLoading: boolean
  isUpdate: boolean;
  listModuleProfileStruct: Array<ModuleProfileStruct> = new Array<ModuleProfileStruct>();
  idProfile: number;
  isMaster: boolean;
  ip: string = '192.168.0.2';

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private profileService: ProfileService,
    private moduleProfileService: ModuleProfileService,
    public dialog: MatDialog,
    private authService: AuthService,
    private utilService: UtilService,
  ) {
    this.listModule();
  }

  ngOnInit(): void {
    this.isMaster = this.authService.getTokenMenu().isMaster;
    this.model = this.formBuilder.group({
      name: ['', [Validators.required]],
      description: [''],
      canStayConnected: [false, [Validators.required]]
    });

    this.idProfile == null;
    this.isUpdate == false;
    if (this.activatedRoute.snapshot.paramMap.get('idProfile'))
      this.idProfile = parseInt(this.activatedRoute.snapshot.paramMap.get('idProfile'));

    setTimeout(() => { this.getIP(); }, 2000);
  }

  submit(): void {
    if (this.isLoading) {
      return;
    }

    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    this.isLoading = true;
    const selectedRoles: Array<RoleStruct> = this.getSelectedRoles();
    const selectedRolesIds: Array<number> = selectedRoles.map((role: RoleStruct) => role.idRole);
    const profile: ProfileRequest = {
      ip: this.ip,
      description: this.model.value.description,
      profileName: this.model.value.name,
      canStayConnected: this.model.value.canStayConnected,
      listIdRole: selectedRolesIds
    }
    let profileSubmitProfile;
    if (this.isUpdate) {
      profileSubmitProfile = this.profileService.editProfileRoles(this.idProfile, profile);
    } else {
      profileSubmitProfile = this.profileService.createProfileRoles(profile);
    }

    profileSubmitProfile.subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.alertService.show('Sucesso', 'Perfil salvo com sucesso', AlertType.success);
        this.isLoading = false;
        this.router.navigate(['/master/profile']);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });

    return;
  }

  getSelectedRoles(): Array<RoleStruct> {
    let selectedRoles: Array<RoleStruct> = new Array<RoleStruct>();
    this.listModuleProfileStruct.forEach((module) => {
      module.listFunctionality.forEach((feature) => {
        let selected = feature.listRole.filter((role) => role.selected);
        selectedRoles = selectedRoles.concat(selected);
      });
    });

    return selectedRoles;
  }

  setProfileRoles(rolesList: Array<number>) {
    this.listModuleProfileStruct.forEach((module) => {
      module.listFunctionality.forEach((feature) => {
        feature.listRole.forEach((role) => {
          role.selected = rolesList.indexOf(role.idRole) > -1;
        });
      });
    });
  }

  populateProfileData() {
    this.profileService.getProfileRoles(this.idProfile).subscribe({
      next: (response) => {
        this.setProfileRoles(response.listIdRole);
        this.model.setValue({
          name: response.profileName,
          description: response.description,
          canStayConnected: (response.canStayConnected != null) ? response.canStayConnected : false
        });

        this.isFirstLoading = false;

      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  listModule() {
    this.moduleProfileService.listModule().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listModuleProfileStruct = response.listModuleStruct;

        if (this.idProfile != null) {
          this.isFirstLoading = true;
          this.isUpdate = true;
          this.populateProfileData();
        }
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openModal() {
    const dialogRef = this.dialog.open(UpgradeModalComponent, {
      data: {},
    });
  }

  getIP() {
    this.utilService.getIP().subscribe({
      next: (response) => {
        if (response && response.ip) {
          this.ip = response.ip;
        }
      }
    });
  }
}