<div *ngIf="!onlineOffline || socketDisconneted" class="offline">
    <span>
        <mat-icon class="offline--icon">wifi_off</mat-icon>
        {{
        socketDisconneted?
        "A função de atualização automática está fora do ar.":
        "Você esta sem conexão com a internet no momento."
        }}
    </span>
</div>
<div *ngIf="!mainClient" class="wating-password">
    AGUARDANDO UMA SENHA SER CHAMADA...
</div>
<div *ngIf="mainClient" class="content" [ngClass]="{'content-padding-top': !onlineOffline || socketDisconneted}">

    <div class="main-call">
        <div class="main-call__password">
            <div class="password_label">
                SENHA
            </div>
            <div class="main-call__ticket">
                {{mainClient.ticket}}
            </div>
        </div>
        <div class="main-call__location">
            {{ mainClient.nameSector + " " + mainClient.nameRoom}}
        </div>
        <div class="main-call__patient">
            {{mainClient.namePacient}}
        </div>
    </div>
    <div class="history-main">
        <div class="title-history-content">ÚLTIMOS CHAMADOS:</div>
        <div *ngFor="let client of historicalList; let i=index">
            <div [ngClass]="{'history-call': i < 3}">
                <div class="history-content">
                    <div class="client-info">
                        <span class="text-info-color">
                            Senha
                        </span>
                        <span class="bold text-info-color font-size-1-8">
                            {{client.ticket}}
                        </span>
                        <span *ngIf="client.namePacient" class="text-info-color font-size-1-8">
                            |
                        </span>
                        <span *ngIf="client.namePacient" class="text-info-color">
                            {{client.namePacient}}
                        </span>
                    </div>
                    <span class="sector-info">
                        {{ client?.nameSector + ' ' + client?.nameRoom}}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
<footer class="col-12">
    <img *ngIf="this.logoString64" class="brand" [src]="this.sanitezedLogo">
    <img class="brand col-1" src="assets/images/logo-branco.png">
    <span *ngIf="showAverageWaitingTime" class="col-6 personalized-waiting-hours"><span class="vertical-line"></span>
        MÉDIA DE ESPERA - TRIAGEM: {{lastHourWaitingTimeAverage}} <span class="text-last-hour">(última
            hora)</span></span>
    <div class="info col-4">
        <span class="date-time">
            <mat-icon>calendar_today</mat-icon>{{date}}
        </span>
        <span class="date-time">
            <mat-icon>schedule</mat-icon>{{time}}
        </span>
    </div>
</footer>