export class TotemRequest{
    public idTotemButton: number;
    
    public idPatient: number;

    public idUserPatient: number;

    public listPriority: number[];

    public listTotemPriority: number[];
    
    public birthDate: Date;

    public isSchedule: boolean;

    public idSchedule: number;
}