import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-execute-audit-warning-modal',
  templateUrl: './execute-audit-warning-modal.component.html',
  styleUrls: ['./execute-audit-warning-modal.component.css']
})
export class ExecuteAuditWarningModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog,
  private router: Router,
  public matDialogRef: MatDialogRef<ExecuteAuditWarningModalComponent>,
  private alertService: AlertService,) { }

  public isLoading:boolean;  

  ngOnInit(): void {
    this.isLoading = false;
  }
  
  close(){
    this.matDialogRef.close(); 
  }
}
