<div class="content">
    <form class="form" [formGroup]="model" (ngSubmit)="submit()">
        <span class="pin-intro">Informe o seu CPF para continuar!</span>
        <div class="row">   
            <div class="col-md-12">
                <mat-form-field appearance="outline">
                    <mat-label>CPF</mat-label>
                    <input matInput type="text" formControlName="cpf" [mask]="this.masks.cpf" autofocus>
                    <mat-error *ngIf="model.get('cpf').invalid">Informe o CPF</mat-error>
                </mat-form-field>
            </div>
        </div>
        
        <div class="row">
            <div class="col-md-12" >
                <button mat-flat-button color="primary" type="submit" class="btn-block btn-font-size">
                    <span *ngIf="isLoading == false">Continuar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn"
                    *ngIf="isLoading == true"></mat-spinner>
                </button>
            </div>
        </div>
    </form>
</div>
