import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ReadExternalPrescriptionStruct } from 'src/app/shared/services/structs/orchestrator-patient/read-external-prescription.struct';

@Component({
  selector: 'app-external-prescription-modal',
  templateUrl: './external-prescription-modal.component.html',
  styleUrls: ['./external-prescription-modal.component.css']
})
export class ExternalPrescriptionModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ExternalPrescriptionModalComponent>,
    private alertService: AlertService,
    private formBuilder: UntypedFormBuilder
  ) { }

  public isLoading: boolean;
  public medicalPrescription: ReadExternalPrescriptionStruct;
  public prescriptionModel: UntypedFormGroup;

  ngOnInit(): void {
    this.medicalPrescription = this.data.prescription;
    this.isLoading = false;
    this.prescriptionModel = this.formBuilder.group({
      quantity: [{ value: '', disabled: true }, Validators.required],
      description: [{ value: '', disabled: true }, Validators.required],
      observation: [{ value: '', disabled: true }],
      frequency: [{ value: '', disabled: true }, Validators.required],
    });
    if (this.medicalPrescription) {
      this.populateValues();
    }
  }

  populateValues() {
    this.prescriptionModel.get('frequency').setValue(this.medicalPrescription.frequency);
    this.prescriptionModel.get('description').setValue(this.medicalPrescription.description);
    this.prescriptionModel.get('quantity').setValue(this.medicalPrescription.quantity);
    this.prescriptionModel.get('observation').setValue(this.medicalPrescription.observation);
  }

  clickCancel() {
    this.matDialogRef.close();
  }
  close() {
    this.matDialogRef.close();
  }

  onSubmit() {
    this.matDialogRef.close();
  }
}