export class DigitalPromptServiceConfigRequest {
    public useColor: boolean;
    public colorCode: string;
    public isLogoUploaded: boolean;
    public logoString64: string;
    public allowSelectionHealthcareAgreement: boolean;
    public url: string;
    public urlName: string;
    public isActive: boolean;
    public idSessionTimeout: number;
}