import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ExamTypeService } from 'src/app/shared/services/API/SADT/exam-type.service';
import { ExamService } from 'src/app/shared/services/API/SADT/exam.service';
import { ExamTypeModel } from 'src/app/shared/services/models/SADT/exam-type.model';
import { ExamStruct } from 'src/app/shared/services/structs/SADT/exam.struct';
import { UtilService } from 'src/app/shared/services/util.service';
import { ExamDeleteModalComponent } from './exam-delete-modal/exam-delete-modal.component';

@Component({
  selector: 'app-exam-list',
  templateUrl: './exam-list.component.html',
  styleUrls: ['./exam-list.component.css']
})
export class ExamListComponent implements OnInit {

  constructor(private examService: ExamService,
    private examTypeService: ExamTypeService,
    private router: Router,
    private alertService: AlertService,
    private utilService: UtilService,
    public dialog: MatDialog) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.sadt;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.master_exam;

  public listExam: ExamStruct[];
  public listExamType: ExamTypeModel[];
  public isLoading: boolean;
  public isActive: boolean;
  public searchText: string;
  public idExamType: number;

  ngOnInit(): void {
    this.isLoading = true;
    this.populateExamTypeSelect();
    this.search();
  }
  search() {
    this.isLoading = true;
    if (this.searchText == "")
      this.searchText = null;

    this.examService.listExam(this.searchText, this.isActive, this.idExamType).subscribe({
      next: (response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.listExam = response.listExam;

      this.isLoading = false;
    },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateExamTypeSelect() {
    this.examTypeService.listAllExamType().subscribe({
      next: (response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);

        return;
      }

      this.listExamType = response.listExamType;
    },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }

  openModal(idExam) {
    const dialogRef = this.dialog.open(ExamDeleteModalComponent, {
      data: {
        idExam: idExam
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.deleteExam) {
        this.search();
      }
    });
  }
}