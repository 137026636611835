<div class="container-generic">
    <form class="form" [formGroup]="model">
        <div class="feature-content">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-label>
                        <strong>Nome Medico: </strong>
                        <p>{{sadt.userName}}</p>
                    </mat-label>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Observações do Médico</mat-label>
                        <textarea matInput formControlName="observationMedic"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-label>
                        <strong>Exames: </strong>
                        <p *ngFor="let exam of sadt.medicalCareSadt">- {{exam.sadtName}}</p>
                    </mat-label>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Observações Exame</mat-label>
                        <textarea matInput formControlName="observation"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Resultados do exame</mat-label>
                        <textarea matInput formControlName="sadtResult" maxlength="1000"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-8" *ngIf="this.sadt.sadtResultFile.length > 0">
                    <div *ngFor="let item of this.sadt.sadtResultFile" class="col-12" class="outline-spinner">
                        <a (click)="downloadFile(item.idSadtResultFile)" class="download">
                            <mat-icon *ngIf="!isLoadingFile">file_download</mat-icon> Ver arquivo
                            {{item.sadtResultFileName}}
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoadingFile"></mat-spinner>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>