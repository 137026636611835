import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { DashboardListComponent } from './pages/dashboard-list/dashboard-list.component';
import { DashboardRegisterComponent } from './pages/dashboard-register/dashboard-register.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DataSourceListComponent } from './pages/data-source-list/data-source-list.component';
import { DataSourceRegisterComponent } from './pages/data-source-register/data-source-register.component';
import { GraphRegisterComponent } from './pages/graph-register/graph-register.component';

const routes: Routes = [
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/data-source', component: DataSourceListComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/data-source/register', component: DataSourceRegisterComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/list', component: DashboardListComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/register', component: DashboardRegisterComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/graph/register', component: GraphRegisterComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
