<div class="mat-dialog">
    <div mat-dialog-title>
        <h1>Histórico de preenchimento de auditorias</h1>
        <hr />
    </div>    
    <div mat-dialog-content class="content">
        <ng-container>
            <p><b>1º auditor:</b> {{this.data.userFirstAuditor}}</p>
            <mat-radio-group aria-label="" [(ngModel)]="this.data.firstAuditorAnswer" [disabled]="true">
                <mat-radio-button [value]="true">Correto</mat-radio-button>
                <mat-radio-button [value]="false">Incorreto</mat-radio-button>
            </mat-radio-group>
            <mat-form-field *ngIf="this.data.firstAuditorAnswer == false" appearance="outline">
                <mat-label>Motivo</mat-label>
                <mat-select [(ngModel)]="this.data.firstAuditorIdIncorrectClassificationReason" [disabled]="true">
                    <mat-option *ngFor="let item of this.data.listAllIncorrectClassificationReason"
                        [value]="item.idIncorrectClassificationReason">{{item.description}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="this.data.firstAuditorIdIncorrectClassificationReason == 3" appearance="outline">
                <mat-label>Observação</mat-label>
                <textarea matInput type="text" [(ngModel)]="this.data.firstAuditorIncorrectObservation" [disabled]="true"></textarea>
            </mat-form-field>

            <mat-form-field *ngIf="this.data.firstAuditorAnswer == true" appearance="outline">
                <mat-label>Motivo</mat-label>
                <mat-select [(ngModel)]="this.data.firstAuditorIdCorrectClassificationReason" [disabled]="true">
                    <mat-option *ngFor="let item of this.data.listAllCorrectClassificationReason"
                        [value]="item.idCorrectClassificationReason">{{item.description}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="this.data.firstAuditorIdCorrectClassificationReason == 1" appearance="outline">
                <mat-label>Observação</mat-label>
                <textarea matInput type="text" [(ngModel)]="this.data.firstAuditorCorrectObservation" [disabled]="true"></textarea>
            </mat-form-field>

        </ng-container>
        <hr />   
        <ng-container>
            <p><b>2º auditor: </b> {{this.data.userSecondAuditor}}</p>
            <mat-radio-group aria-label="" [(ngModel)]="this.data.secondAuditorAnswer" [disabled]="true">
                <mat-radio-button [value]="true">Correto</mat-radio-button>
                <mat-radio-button [value]="false">Incorreto</mat-radio-button>
            </mat-radio-group>
            <mat-form-field *ngIf="this.data.secondAuditorAnswer == false" appearance="outline">
                <mat-label>Motivo</mat-label>
                <mat-select [(ngModel)]="this.data.secondAuditorIdIncorrectClassificationReason" [disabled]="true">
                    <mat-option *ngFor="let item of this.data.listAllIncorrectClassificationReason"
                        [value]="item.idIncorrectClassificationReason">{{item.description}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="this.data.secondAuditorIdIncorrectClassificationReason == 3" appearance="outline">
                <mat-label>Observação</mat-label>
                <textarea matInput type="text" [(ngModel)]="this.data.secondAuditorIncorrectObservation" [disabled]="true"></textarea>
            </mat-form-field>

            <mat-form-field *ngIf="this.data.secondAuditorAnswer == true" appearance="outline">
                <mat-label>Motivo</mat-label>
                <mat-select [(ngModel)]="this.data.secondAuditorIdCorrectClassificationReason" [disabled]="true">
                    <mat-option *ngFor="let item of this.data.listAllCorrectClassificationReason"
                        [value]="item.idCorrectClassificationReason">{{item.description}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="this.data.secondAuditorIdCorrectClassificationReason == 1" appearance="outline">
                <mat-label>Observação</mat-label>
                <textarea matInput type="text" [(ngModel)]="this.data.secondAuditorCorrectObservation" [disabled]="true"></textarea>
            </mat-form-field>

        </ng-container>
        <hr /> 
        <div  *ngFor="let item of this.data.listClassificationRiskAuditOthers" class="col-12">
            <p><b>{{item.numberOtherStatusAudit}}º auditor:</b> {{item.userName}}</p>
            <mat-radio-group aria-label=""  [value]="item.isCorrectAuditor" [disabled]="true">
                <mat-radio-button [value]="true">Correto</mat-radio-button>
                <mat-radio-button [value]="false">Incorreto</mat-radio-button>
            </mat-radio-group>
            <mat-form-field *ngIf="item.isCorrectAuditor == false" appearance="outline">
                <mat-label>Motivo</mat-label>
                <mat-select [value]="item.idIncorrectClassificationReason" [disabled]="true">
                    <mat-option *ngFor="let item2 of this.data.listAllIncorrectClassificationReason"
                        [value]="item2.idIncorrectClassificationReason">{{item2.description}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="item.idIncorrectClassificationReason == 3" appearance="outline">
                <mat-label>Observação</mat-label>
                <textarea [disabled]="true"  matInput type="text" [value]="item.observation"></textarea>
            </mat-form-field>

            <mat-form-field *ngIf="item.isCorrectAuditor == true" appearance="outline">
                <mat-label>Motivo</mat-label>
                <mat-select [value]="item.idCorrectClassificationReason" [disabled]="true">
                    <mat-option *ngFor="let item2 of this.data.listAllCorrectClassificationReason"
                        [value]="item2.idCorrectClassificationReason">{{item2.description}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="item.idCorrectClassificationReason == 1" appearance="outline">
                <mat-label>Observação</mat-label>
                <textarea [disabled]="true"  matInput type="text" [value]="item.correctObservationReason"></textarea>
            </mat-form-field>

            <hr />
        </div>   
    </div>
    <div mat-dialog-close>
        <button mat-flat-button color="accent" class="btn-block"(click)="close()">
            Fechar
        </button>
    </div>
</div>