import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { PriceProcedurePlanService } from 'src/app/shared/services/API/private-billing/price-procedure-plan.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Plan } from 'src/app/shared/services/models/private-billing/plan.model';
import { ProcedurePlan } from 'src/app/shared/services/models/private-billing/procedure-plan.model';
import { Procedure } from 'src/app/shared/services/models/private-billing/procedure.model';
import { PriceProcedurePlanStruct } from 'src/app/shared/services/structs/private-billing/price-procedure-plan.struct';
import { UtilsHealthcareAgreements } from '../utils-healthcare-agreements.service';
import { PriceProcedurePlanRequest } from 'src/app/shared/services/requests/private-billing/price-procedure-plan.request';
import { PostPriceProcedurePlanResponse } from 'src/app/shared/services/responses/private-billing/post-price-procedure-plan.response';

@Component({
  selector: 'app-price-procedure-plan',
  templateUrl: './price-procedure-plan.component.html',
  styleUrls: ['./price-procedure-plan.component.css']
})
export class PriceProcedurePlanComponent implements OnInit {
  constructor(
    private cdr: ChangeDetectorRef,
    private alertService: AlertService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private priceProcedurePlanService: PriceProcedurePlanService,
    private utilsHealthcareAgreements: UtilsHealthcareAgreements) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() fullListSize: number;
  @Input() listPriceProcedurePlanStruct: PriceProcedurePlanStruct[] = [];
  @Input() idHealthcareAgreement: number;
  @Input() listProcedure: Procedure[] = [];
  @Input() listPlan: Plan[] = [];
  @Input() listProcedurePlan: ProcedurePlan[] = [];
  @Output() planDataChanged = new EventEmitter<any>();

  public isLoading: boolean = false;
  public showAdditionalFields: boolean = false;
  public model: FormGroup;

  public searchText: string = null;

  public pageIndex: number = 0;
  public pageSize: number = 50;
  public editingIndex: number = -1;

  public newRule: PriceProcedurePlanStruct = new PriceProcedurePlanStruct();
  public listProcedurePlanFiltered: PriceProcedurePlanStruct[] = [];

  ngOnInit(): void {
    if (this.listPriceProcedurePlanStruct && this.listPriceProcedurePlanStruct.length > 0) {
      this.listPriceProcedurePlanStruct.forEach(x => {
        x.valueFront = this.utilsHealthcareAgreements.formatCurrencyBackToFront(x.value).trim();
      });
    }

    this.model = this.formBuilder.group({
      idPlan: [''],
      idProcedure: [''],
      value: [''],
    });
  }

  private timeoutKeySearch: any = null;
  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.pageIndex = 0;
        $this.search();
      }
    },
      1000);
  }

  changePage(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.search();
  }

  search() {
    if (this.idHealthcareAgreement) {
      this.isLoading = true;

      this.priceProcedurePlanService.GetAll(this.idHealthcareAgreement, this.searchText, this.pageIndex).subscribe({
        next: (response) => {
          if (response.isError) {
            this.throwException(response.errorDescription);
            return;
          }

          this.listPriceProcedurePlanStruct = response.listPriceProcedurePlanStruct;
          if (this.listPriceProcedurePlanStruct && this.listPriceProcedurePlanStruct.length > 0) {
            this.listPriceProcedurePlanStruct.forEach(x => {
              x.valueFront = this.utilsHealthcareAgreements.formatCurrencyBackToFront(x.value).trim();
            });
          }

          this.fullListSize = response.fullListSize;
          this.pageSize = response.pageSize;
          this.cdr.detectChanges();
          this.isLoading = false;
        },
        error: (error) => {
          this.throwException(error);
        }
      });
    }
  }

  editItem(priceProcedurePlan: PriceProcedurePlanStruct) {
    const index = this.listPriceProcedurePlanStruct.indexOf(priceProcedurePlan);
    if (index !== -1) {
      this.editingIndex = index;
      this.model.patchValue({
        idPlan: priceProcedurePlan.idPlan,
        idProcedure: priceProcedurePlan.idProcedure,
        value: priceProcedurePlan.valueFront,
      });

      this.onPlanChange(priceProcedurePlan.idPlan, priceProcedurePlan.idProcedure);
      this.showAdditionalFields = true;
    }
  }

  deleteItem(priceProcedurePlan: PriceProcedurePlanStruct) {
    this.isLoading = true;

    const index = this.listPriceProcedurePlanStruct.indexOf(priceProcedurePlan);
    if (index !== -1) {
      if (index === this.editingIndex)
        this.cancelEdit();

      this.priceProcedurePlanService.delete(priceProcedurePlan.idPlan, priceProcedurePlan.idProcedure).subscribe({
        next: (response) => {
          if (response.isError) {
            this.throwException(response.errorDescription);
            return;
          }
          this.fullListSize -= 1;
          this.listPriceProcedurePlanStruct.splice(index, 1);
          this.isLoading = false;
          this.alertService.show('Sucesso', 'Procedimento deletado com sucesso!', AlertType.success);
        },
        error: (error) => {
          this.throwException(error);
        }
      });
    }
    else
      console.error("Objeto não encontrado na lista:", priceProcedurePlan);
  }

  cancelEdit() {
    this.editingIndex = -1;
    this.model.reset();
    this.showAdditionalFields = false;
  }

  isValidNewRule(): boolean {
    return this.model.get('idPlan').value && this.model.get('idProcedure').value && this.model.get('value').value !== null;
  }

  addNewRule() {
    if (this.isValidNewRule()) {
      if (this.editingIndex > -1) {
        const editedItem = this.listPriceProcedurePlanStruct[this.editingIndex];
        editedItem.idPlan = this.model.get('idPlan').value;
        editedItem.idProcedure = this.model.get('idProcedure').value;
        editedItem.valueFront = this.model.get('value').value;
        editedItem.value = this.utilsHealthcareAgreements.formatCurrencyFrontToBack(this.model.get('value').value);

        this.isLoading = true;
        this.put(editedItem);
      }
      else {
        const newRule = new PriceProcedurePlanStruct();
        newRule.planName = this.listPlan.find(x => x.idPlan === this.model.get('idPlan').value).planName;
        newRule.idPlan = this.model.get('idPlan').value;
        newRule.nameProcedure = this.listProcedure.find(x => x.idProcedure === this.model.get('idProcedure').value).nameProcedure;
        newRule.idProcedure = this.model.get('idProcedure').value;
        newRule.value = this.utilsHealthcareAgreements.formatCurrencyFrontToBack(this.model.get('value').value);;
        newRule.valueFront = this.model.get('value').value;
        newRule.isActive = true;

        this.isLoading = true;
        this.savePriceProcedurePlan(newRule);
      }
    }
  }

  private throwException(error: string) {
    console.log(error);
    this.alertService.show('Erro inesperado', error, AlertType.error);
    this.isLoading = false;
  }

  onPlanChange(idPlan: any, idProcedure: number = null): void {
    this.listProcedurePlanFiltered = [];
    let listIdProcedure = this.listPriceProcedurePlanStruct.filter(x => x.idPlan === idPlan).map(x => x.idProcedure);

    if (this.listProcedurePlanFiltered.length === 0) {
      let listProcedurePlanFiltered = this.listProcedure
        .filter(x => !listIdProcedure.includes(x.idProcedure));

      if (idProcedure != null) {
        listProcedurePlanFiltered.unshift(this.listProcedure.find(x => x.idProcedure == idProcedure));
      }

      this.listProcedurePlanFiltered = listProcedurePlanFiltered.map(y => ({
        idProcedure: y.idProcedure,
        nameProcedure: y.nameProcedure,
        isActive: true,
        idPriceProcedurePlan: null,
        idProcedurePlan: null,
        idPlan: idPlan,
        idUser: null,
        planName: this.listPlan.find(z => z.idPlan == idPlan)?.planName,
        value: null,
        valueFront: null
      }));
    }
    else
      this.listProcedurePlanFiltered = [];
  }

  formatCurrency(event: any) {
    if (isNaN(parseFloat(event.target.value)))
      event.target.value = "0,00";

    let value = this.utilsHealthcareAgreements.formatCurrencyToString(event).trim();
    this.model.get('value').setValue(value);
    this.model.get('value').updateValueAndValidity();
  }

  formatCurrencyBackToFront(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 }).replace('R$', '');
  }

  savePriceProcedurePlan(newRule: PriceProcedurePlanStruct) {
    this.showAdditionalFields = false;

    let request: PriceProcedurePlanRequest = new PriceProcedurePlanRequest();

    request.priceProcedurePlan = newRule;
    this.priceProcedurePlanService.Post(request).subscribe({
      next: (response: PostPriceProcedurePlanResponse) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.model.reset();
        this.cdr.detectChanges();
        this.isLoading = false;
        this.fullListSize += 1;
        response.priceProcedurePlanStruct.valueFront = this.formatCurrencyBackToFront(response.priceProcedurePlanStruct.value).trim()
        this.listPriceProcedurePlanStruct.push(response.priceProcedurePlanStruct);
        this.alertService.show('Sucesso', 'Procedimento salvo com sucesso!', AlertType.success);
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  put(priceProcedurePlan: PriceProcedurePlanStruct) {
    this.showAdditionalFields = false;

    let request: PriceProcedurePlanRequest = new PriceProcedurePlanRequest();
    request.priceProcedurePlan = priceProcedurePlan;
    this.priceProcedurePlanService.Put(request).subscribe({
      next: (response: PostPriceProcedurePlanResponse) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.model.reset();
        this.isLoading = false;

        this.listPriceProcedurePlanStruct[this.editingIndex] = response.priceProcedurePlanStruct;
        this.listPriceProcedurePlanStruct[this.editingIndex].valueFront = this.formatCurrencyBackToFront(response.priceProcedurePlanStruct.value).trim()
        this.listPriceProcedurePlanStruct[this.editingIndex].planName = this.listPlan.find(x => x.idPlan === response.priceProcedurePlanStruct.idPlan).planName;
        this.listPriceProcedurePlanStruct[this.editingIndex].nameProcedure = this.listProcedure.find(x => x.idProcedure === response.priceProcedurePlanStruct.idProcedure).nameProcedure;
        this.editingIndex = -1;
        this.alertService.show('Sucesso', 'Procedimento atualizado com sucesso!', AlertType.success);
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }
}