<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <h1>Logs de configuração</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <div class="filters">
                <div class="row">
                    <div class="col-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Módulo</mat-label>
                            <mat-select [(ngModel)]="idModule">
                                <mat-option *ngFor="let item of listModuleProfileStruct" [value]="item.idModule">
                                    {{ item.nameModule }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Período</mat-label>
                            <mat-select [disabled]="idModule === null" [(ngModel)]="selectedPeriod"
                                (selectionChange)="change()">
                                <mat-option [value]="selectedPeriodLastHour">Última hora</mat-option>
                                <mat-option [value]="selectedPeriodLast4Hours">Últimas 4 horas</mat-option>
                                <mat-option [value]="selectedPeriodLast12Hours">Últimas 12 horas</mat-option>
                                <mat-option [value]="selectedPeriodLast24Hours">Últimas 24 horas</mat-option>
                                <mat-option [value]="selectedPeriodLast7Days">Últimos 7 dias</mat-option>
                                <mat-option [value]="selectedPeriodLast30Days">Últimos 30 dias</mat-option>
                                <mat-option [value]="selectedPeriodCustom">Customizado</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field *ngIf="selectedPeriod === selectedPeriodCustom" appearance="outline">
                            <mat-label>Intervalo personalizado</mat-label>
                            <mat-date-range-input [min]="minDate" [max]="maxDate" [rangePicker]="seletor"
                                [disabled]="idModule === null">
                                <input matStartDate [(ngModel)]="startOfAttendance" placeholder="Início">
                                <input matEndDate [(ngModel)]="endOfAttendance" placeholder="Fim">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="seletor"></mat-datepicker-toggle>
                            <mat-date-range-picker #seletor></mat-date-range-picker>
                            <mat-error *ngIf="dateRangeError">{{ dateRangeError }}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Menu alterado</mat-label>
                            <mat-select [(ngModel)]="listMenuNameRequest" multiple [disabled]="idModule === null">
                                <mat-option *ngFor="let item of listMenuNameResponse"
                                    [value]="item.label">{{item.label}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Usuário</mat-label>
                            <mat-select [(ngModel)]="listIdUserRequest" multiple [disabled]="idModule === null">
                                <mat-option *ngFor="let item of listUser" [value]="item.idUser">
                                    {{ item.userName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-3">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Pesquisar</mat-label>
                            <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText"
                                [disabled]="idModule === null">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-6 button-filter-clean">
                        <div class="button-filter col-md-3">
                            <button mat-flat-button color="primary" class="btn-block organize-button"
                                (click)="search(idModule)">Filtrar
                                <mat-icon>filter_alt</mat-icon>
                            </button>
                        </div>
                        <div class="button-clean col-3">
                            <button type="button" mat-flat-button color="accent" class="btn-block organize-button"
                                (click)="cancel()">Cancelar
                                <mat-icon>cleaning_services</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="table-padding">
                <app-table [columns]="columns" [list]="changelogList"
                    (actionClicked)="changelogDetails($event)"></app-table>
            </div>
            <app-empty-list *ngIf="changelogList && changelogList.length == 0 && !isLoading"></app-empty-list>
            <app-loading-list *ngIf="isLoading"></app-loading-list>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-paginator [length]="fullListSize" #paginator [pageIndex]="pageIndex" [pageSize]="pageSize"
                        aria-label="Select page" (page)="changePage($event.pageIndex)" [hidePageSize]="true">
                    </mat-paginator>
                </div>
            </div>
        </div>
    </div>
</div>