<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-confirm-modal-list">
            <h2>Paciente não encontrado</h2>
         </div>
         
         <div class="body-confirm-modal-list">
            Você ainda não possui cadastro. Verifique seu CPF ou continue sem identificação.
         </div>
         
         <div class="footer-confirm-modal-list">
            <button mat-flat-button color="accent" class="btn-block"(click)="close()">Ok</button>
        </div>
    </div>
</div>
