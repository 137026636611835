import { PatientConsolidatedDataStruct } from "../../structs/billing/patient-consonlidated-data.struct";

export class PatientProcedureMadeRequest{

    public listConsolidatedData: PatientConsolidatedDataStruct[];

    public listColumnToDisplay: number[];
    
    public datetimeStart: Date;
    
    public datetimeEnd: Date;
}