import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { ListCareLineResponse } from '../../responses/care-line/list-care-line.response';
import { CareLineRequest } from '../../requests/care-line/create-care-line.request';


@Injectable({
  providedIn: 'root'
})
export class CareLineService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public ListCareLines(): Observable<ListCareLineResponse>{
    let uri = `CareLine`;

    return this.httpClient.get<ListCareLineResponse>(environment.urlApiCareLine + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
  
  public CreateCareLines(request: CareLineRequest): Observable<ReturnStruct>{
    let uri = `CareLine`
    return this.httpClient.post<ReturnStruct>(environment.urlApiCareLine + uri, request, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
