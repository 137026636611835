import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CommonService } from "src/app/shared/services/common-service";
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';
import { catchError } from "rxjs/operators";
import { PatientHistoryResponse } from "../../responses/orchestrator-patient/patient-history.response";
import { ReportResponse } from "../../responses/orchestrator-patient/report.response";
import { ListPatientStructResponse } from "../../responses/medical-record/list-patient-struct-response";

@Injectable({
  providedIn: 'root'
})
export class PatientHistoryService extends CommonService {

  constructor(private httpClient: HttpClient) { super(); }

  public getPatientHistoryDetails(idEpisode: number): Observable<PatientHistoryResponse> {
    let uri = `PatientHistory/idEpisode/${idEpisode}`

    return this.httpClient.get<PatientHistoryResponse>(environment.urlApiOrchestratorPatient + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public listPatient(pageIndex: number, idPatient?: number, CPF?: string, patientName?: string, birthDate?: string): Observable<ListPatientStructResponse> {
    let uri = `PatientHistory/allPatients?`

    if (pageIndex != null)
      uri = uri + `pageIndex=${pageIndex}&`;

    if (idPatient != null)
      uri = uri + `idPatient=${idPatient}&`;

    if (CPF)
      uri = uri + `CPF=${CPF}&`;

    if (patientName)
      uri = uri + `patientName=${patientName}&`;

    if (birthDate)
      uri = uri + `birthDate=${birthDate}&`;

    return this.httpClient.get<ListPatientStructResponse>(environment.urlApiOrchestratorPatient + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public downloadCompleteReport(idEpisode: number): Observable<ReportResponse> {
    let uri = `PatientHistory/downloadCompleteReports/idEpisode/${idEpisode}`;

    return this.httpClient.get<ReportResponse>(environment.urlApiOrchestratorPatient + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}