import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MedicalRecordService } from 'src/app/shared/services/API/orchestrator-patient/medical-record.service';
import { LookupMedicalCareResponse } from 'src/app/shared/services/responses/medical-record/lookup-medical-care-response';
import { ListMedicineResponse } from 'src/app/shared/services/responses/pharmacy/list-medicine.response';
import { ListExamResponse } from 'src/app/shared/services/responses/SADT/list-exam.response';
import { CompleteMedicalCareStruct } from 'src/app/shared/services/structs/orchestrator-patient/complete-medical-care.struct';
import { TriageStruct } from 'src/app/shared/services/structs/orchestrator-patient/triage.struct';
import { MedicalCareAccordionComponent } from '../medical-care-accordion/medical-care-accordion.component';

@Component({
  selector: 'app-episode-modal',
  templateUrl: './episode-modal.component.html',
  styleUrls: ['./episode-modal.component.css']
})
export class EpisodeModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<EpisodeModalComponent>,
  public medicalRecordService:MedicalRecordService,
  private alertService: AlertService) { }

  public isLoading:boolean;
  public triage: TriageStruct;
  public medicalCare: CompleteMedicalCareStruct[] = [];
  public lookupMedicalCareResponse: LookupMedicalCareResponse;
  public listMedicine: ListMedicineResponse[] = [];
  public listExam: ListExamResponse;
  ngOnInit(): void {
    this.populateAttendPatient();
    this.lookupMedicalCareResponse = this.data.lookupMedicalCareResponse;
    this.listMedicine = this.data.listMedicine;
    this.listExam = this.data.listExam;
  }
  
  clickCancel(){
    this.matDialogRef.close(); 
  }
  close(){
    this.matDialogRef.close(); 
  }
  populateAttendPatient(){
    this.isLoading = true;
    this.medicalRecordService.getMedicalRecord(this.data.idEpisode).subscribe((response)=>{
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.triage=response.triage;
      this.medicalCare = response.medicalCareHistorie;
      this.isLoading = false;
    });
  }
}

