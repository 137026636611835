import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ClassificationData, UtilsClassificationService } from 'src/app/classification/utils.service';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { DeviceTypeEnum } from 'src/app/shared/enum/device-type.enum';
import { DiscriminatorTypeEnum } from 'src/app/shared/enum/discriminator-type.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MeasurerService } from 'src/app/shared/services/API/measurer/measurer.service';
import { CounselingService } from 'src/app/shared/services/API/risk-classification/counseling.service';
import { DiscriminatorService } from 'src/app/shared/services/API/risk-classification/discriminator.service';
import { PunctuationSystemService } from 'src/app/shared/services/API/risk-classification/PunctuationSystem.service';
import { DiscriminatorResponse } from 'src/app/shared/services/responses/risk-classification/discriminator.response';
import { RoomAccessUtilService } from 'src/app/shared/services/room-access-util.service';
import { DiscriminatorStruct } from 'src/app/shared/services/structs/risk-classification/discriminator.struct';


@Component({
  selector: 'app-iframe-flowchart',
  templateUrl: './iframe-flowchart.component.html',
  styleUrls: ['./iframe-flowchart.component.css']
})
export class IframeFlowchartComponent implements OnInit {

  constructor(private alertService: AlertService,
    private utilService: UtilsClassificationService,
    public router: Router,
    private discriminatorService: DiscriminatorService,
    private counselingService: CounselingService) { }

  @Output() previousPage = new EventEmitter<any>();
  @Output() selectedDiscriminator = new EventEmitter<any>();

  public discriminatorResponse: DiscriminatorResponse;
  public flowchartName: string;
  public outOfOrder: boolean;
  public discriminators: any[] = [];
  private data: ClassificationData;
  public isLoading: boolean = false;

  ngOnInit(): void {
    this.data = this.utilService.getClassificationData();
    if (this.data && this.data.discriminatorValues) {
      this.discriminators = this.data.discriminatorValues;
    }
    this.flowchartName = this.data.flowchartName;
    this.outOfOrder = this.data.outOfOrder;
    this.getDiscriminator(this.data.idFlowchart, this.data.patientData.birthDate);
  }

  getDiscriminator(idFlowchart: number, birthDate: Date) {
    this.isLoading = true;
    this.discriminatorService.getDiscriminator(idFlowchart, birthDate, null).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.isLoading = false;
      this.discriminatorResponse = response;
    });
  }



  selectDiscriminator(event: any) {
    if (event.item.idDiscriminatorType == DiscriminatorTypeEnum.LastDiscriminator) {
      this.alertService.show('Aviso', "Todos os discriminadores negados, selecione outro FLUXOGRAMA!", AlertType.warning);
      this.previousPage.emit();
      return;
    }
    let data = this.utilService.getClassificationData();
    data.isPatientWhite = false;
    data.discriminator = event.item;
    data.discriminatorValues = this.discriminators;
    data.discriminatorAllValues = event.discriminatorsValues;
    data.priority = this.discriminatorResponse.priorityColor.find(c => c.idPriorityColor == data.discriminator.idPriorityColor);
    this.getCounseling(data);
  }

  previousFirstPage() {
    this.previousPage.emit();
  }

  getCounseling(data: ClassificationData) {
    this.isLoading = true;
    this.counselingService.getCounseling(data.discriminator.idDiscriminator).subscribe({
      next: (response) => {
        if (response.isError) {
          this.utilService.updateClassificationData(data);
          this.selectedDiscriminator.emit(false);
          return;
        }
        data.AllListCounseling = response.listCounseling;
        this.utilService.updateClassificationData(data);
        this.isLoading = false;
        this.selectedDiscriminator.emit(true);
      }, error: (error) => {
        this.utilService.updateClassificationData(data);
        this.selectedDiscriminator.emit(false);
      }
    });
  }
}
