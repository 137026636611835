<div mat-dialog-content>
    <div class="mat-dialog-header">
        <h3>Prescrições Não padronizadas</h3>
    </div>
    <form class="form" [formGroup]="prescriptionModel" (ngSubmit)="onSubmit()">
        <div class="mat-dialog-content">
            <div class="row">
                <div class="col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Descrição</mat-label>
                        <input formControlName="description" matInput [readonly]="this.data.readOnly" type="text">
                    </mat-form-field>
                </div>              
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Quantidade</mat-label>
                        <input formControlName="quantity" matInput [readonly]="this.data.readOnly" type="text">
                    </mat-form-field>
                </div> 
                <div class="col-12 col-sm-12 col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Frequência</mat-label>
                        <input formControlName="frequency" matInput [readonly]="this.data.readOnly" type="text">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Observação</mat-label>
                        <textarea formControlName="observation" [readonly]="this.data.readOnly" matInput></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="footer-panel-modal-list">
                <div class="row">
                    <div class="col-6">
                        <a mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Cancelar</a>
                    </div>
                    <div class="col-6">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span *ngIf="!isLoading" >Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>