import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericCallRoutingModule } from './generic-call-routing.module';
import { GenericCallComponent } from './pages/generic-call/generic-call.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '../shared/shared.module';
import { AttendPatientModalComponent } from './pages/generic-call/attend-patient-modal/attend-patient-modal.component';
import { AttendPatientConfirmModalComponent } from './pages/generic-call/attend-patient-modal/attend-patient-confirm-modal/attend-patient-confirm-modal.component';
import { EpisodeAdministrativeReleaseConfirmModalComponent } from './pages/generic-call/episode-administrative-release-confirm-modal/episode-administrative-release-confirm-modal.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [GenericCallComponent,
    AttendPatientModalComponent,
    AttendPatientConfirmModalComponent,
    EpisodeAdministrativeReleaseConfirmModalComponent
  ],
  imports: [
    CommonModule,
    GenericCallRoutingModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatToolbarModule,
    CdkAccordionModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonModule,
    FormsModule,
    MatIconModule,
    SharedModule,
    MatPaginatorModule,
    MatDialogModule
  ]
})
export class GenericCallModule { }
