<div class="prescription-size" mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-exam-modal-list">
            <h2>{{data.prescription.medicineName}}</h2>
        </div>

        <div class="body-exam-modal-list">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="row">
                    <div class="col-12">
                        <label>Paciente: </label>
                        <span> {{data.episode.patientSocialName? data.episode.patientSocialName :
                            data.episode.patientName}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <label class="label-doctor">Dados selecionado pelo médico: </label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Quantidade</mat-label>
                            <input matInput formControlName="quantityDoctor">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Apresentação escolhida</mat-label>
                            <input matInput formControlName="presentationTypeDoctor">
                        </mat-form-field>
                    </div>
                </div>
                <div class="division-line"></div>
                <div class="row">
                    <div *ngIf="storage" class="col-12">
                        <p><label class="label-storage">Estoque recomendado:
                                {{storage.idStorage}}</label><span>Validade:
                                {{storage.dateExpiration |
                                date:'dd/MM/yy'}}</span>
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Local</mat-label>
                            <mat-select formControlName="idLocation" (selectionChange)="selectLocation($event)">
                                <mat-option *ngFor="let item of listLocation" [value]="item.idLocation"> 
                                    {{item.locationName}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idLocation').invalid">Informe o local</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Quantidade</mat-label>
                            <input matInput type="number" formControlName="quantity">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Apresentação</mat-label>
                            <mat-select formControlName="idPresentationType">
                                <mat-option *ngFor="let item of presentationList"
                                    [value]="item.idMedicinePresentationType">
                                    {{item.medicinePresentationTypeName}}
                                </mat-option>
                            </mat-select>
                            <mat-hint *ngIf="presentationOthersEnum == idMedicinePresentationType">
                                {{medicinePresentationOther}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-6">
                        <app-select [multiple]="true" [url]="urlListKit" (selectedValue)="selectedValue($event)" 
                            [idField]="'idKit'" [nameField]="'codeWithName'" [labelField]="'Kit'"></app-select>
                    </div>
                    <div class="col-12 col-md-6">
                        <mat-slide-toggle [disabled]="model.get('idLocation').invalid" class="indigent-checkbox" [ngModelOptions]="{standalone: true}" [(ngModel)]="this.haveListItem" (change)="changeItems($event)">Materiais e quantidades Adicionais</mat-slide-toggle>
                    </div>
                    <ng-container *ngIf="this.haveListItem">
                        <div *ngFor="let item of listItemFields">
                            <app-prescription-modal-item-select
                                [kitItemSelectStruct]="item"
                                [location]="this.model.get('idLocation').value"
                                (addItemEvent)="addItem($event)" (removeItemEvent)="removeItem($event)">
                            </app-prescription-modal-item-select>
                        </div>
                    </ng-container>
                </div>
            </form>
        </div>
        <div class="footer-exam-modal-list row">
            <div class="col-12 col-md-6">
                <button mat-flat-button color="primary" class="btn-block" (click)="submit()" [disabled]="!validated">
                    <span *ngIf="isLoading == false">Liberar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
            </div>
            <div class="col-12 col-md-6">
                <button mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Cancelar</button>
            </div>
        </div>
    </div>
</div>