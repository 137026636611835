<div *ngFor="let medicineType of listMedicineType" class="node1">
    <mat-checkbox [(ngModel)]="medicineType.isChecked" (change)="onCheckChange(medicineType.idMedicineType, null, null)">
        {{medicineType.medicineTypeName}}
    </mat-checkbox> 
    
    <div *ngFor="let medicineGroup of medicineType.listMedicineGroup" class="node2" >
        <mat-checkbox [(ngModel)]="medicineGroup.isChecked" (change)="onCheckChange(null, medicineGroup.idMedicineGroup, null)">
            {{medicineGroup.medicineGroupName}}
        </mat-checkbox>

        <div *ngFor="let medicine of medicineGroup.listMedicine" class="node3">
            <mat-checkbox [(ngModel)]="medicine.isChecked" (change)="onCheckChange(null, null, null)">
                {{medicine.medicineDescription}}
            </mat-checkbox>
        </div>
        
        <div *ngFor="let medicineSubGroup of medicineGroup.listMedicineSubgroup" class="node3">
            <mat-checkbox [(ngModel)]="medicineSubGroup.isChecked" (change)="onCheckChange(null, null, medicineSubGroup.idMedicineSubgroup)">
                {{medicineSubGroup.medicineSubgroupName}}
            </mat-checkbox>
        
            <div *ngFor="let medicine of medicineSubGroup.listMedicine" class="node4">
                <mat-checkbox  [(ngModel)]="medicine.isChecked" (change)="onCheckChange(null, null, null)">
                    {{medicine.medicineDescription}}
                </mat-checkbox>
            </div>
        </div>
    </div>
</div>