import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { GetConfigResponse } from '../../responses/self-triage/get-config.response';

@Injectable({
  providedIn: 'root'
})
export class GetSelfTriageConfigService extends CommonService {
  constructor(private httpClient: HttpClient) { super(); }

  public getConfig(): Observable<GetConfigResponse> {
    let uri = `GetSelfTriageConfig`
    return this.httpClient.get<GetConfigResponse>(environment.urlApiOrchestratorPatient + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}