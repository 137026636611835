<div mat-dialog-content>
    <h1>Gerar lote</h1>
    <hr>
    <form class="form" [formGroup]="model" (ngSubmit)="submit()">
        <div class="row">
            <div class="col-12">
                <mat-form-field>
                    <mat-label>Data do envio</mat-label>
                    <input matInput [matDatepicker]="pickerOne" formControlName="dateSendGuideBatch">
                    <mat-datepicker-toggle matIconSuffix [for]="pickerOne"></mat-datepicker-toggle>
                    <mat-datepicker #pickerOne></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <div class="row footer-buttons">
            <div class="col-12 col-sm-6">
                <a mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Cancelar</a>
            </div>
            <div class="col-12 col-sm-6">
                <button mat-flat-button type="submit" color="primary" class=" btn-block">
                    <span>Gerar Lote</span>
                </button>
            </div>
        </div>
    </form>
</div>