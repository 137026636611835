import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { GetConfigResponse } from '../../responses/telemedicine/get-config.response';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { TelemedicineConfigRequest } from '../../requests/telemedicine/telemedicine-config.request';

@Injectable({
  providedIn: 'root'
})
export class OrchestratorTelemedicineConfigService extends CommonService {

  constructor(private httpClient: HttpClient) { super(); }

  public getConfig(): Observable<GetConfigResponse> {
    let uri = `TelemedicineConfig`;

    return this.httpClient.get<GetConfigResponse>(environment.urlApiOrchestratorTelemedicine + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public postConfig(request: TelemedicineConfigRequest): Observable<ReturnStruct> {
    let uri = `TelemedicineConfig`;

    return this.httpClient.post<ReturnStruct>(environment.urlApiOrchestratorTelemedicine + uri, request, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}