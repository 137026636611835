import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TotemNpsService } from 'src/app/shared/services/API/nps/totem-nps.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
@Component({
  selector: 'app-totem-nps-delete-modal',
  templateUrl: './totem-nps-delete-modal.component.html',
  styleUrls: ['./totem-nps-delete-modal.component.css']
})
export class TotemNpsDeleteModalComponent implements OnInit {
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<TotemNpsDeleteModalComponent>,
  private totemNpsService: TotemNpsService,
  private alertService: AlertService,) { }
  
  public isLoading:boolean;  
  
  ngOnInit(): void {
    this.isLoading = false;
  }
  
  close(){
    this.matDialogRef.close({deleteTotemNps: false}); 
  }
  
  clickDelete(){
    
    this.isLoading = true;
    
    this.totemNpsService.deleteTotemNps(this.data.idTotemNps).subscribe({
      next: (response)=>{
        
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        
        this.alertService.show('Sucesso', "Totem de NPS excluido com sucesso!", AlertType.success); 
        this.matDialogRef.close({deleteTotemNps: true}); 
      },
      error: (error)=>{
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
  
  clickCancel(){
    this.matDialogRef.close({deleteTotemNps: false}); 
  }
}

