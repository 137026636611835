<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <a class="back-link" routerLink="/master/profile"><mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Perfis</a>
        <h1>Cadastrar Perfil</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <form class="form" [formGroup]="model"  (ngSubmit)="submit()">
                <div class="title-header">
                    <h1>Dados do perfil</h1>
                </div>
    
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome</mat-label>
                            <input matInput type="text" formControlName="name">
                            <mat-error *ngIf="model.get('name').invalid">Informe o nome</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Descrição</mat-label>
                            <input matInput type="text" formControlName="description">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 allow-stay-connected">
                        <mat-slide-toggle formControlName="canStayConnected">
                            Permitir "Permanecer conectado"
                        </mat-slide-toggle>
                        <div class="help"
                        matTooltip="Ao ativar esta opção, os usuários com este perfil poderão habilitar a lembrança de matrícula e senha durante o login nesta Unidade."
                        matTooltipPosition="right">
                            <mat-icon aria-hidden="false" aria-label="question_mark">question_mark</mat-icon>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <cdk-accordion class="profile-register-accordion">
                        <ngContainer *ngFor="let itemModule of listModuleProfileStruct; let index = index;">
                            <cdk-accordion-item
                        *ngIf="itemModule.isModulePermitted || this.isMaster"
                          #accordionItemModule="cdkAccordionItem"
                          class="profile-register-accordion-item"
                          role="button"
                          tabindex="0"
                          [attr.id]="'accordion-header-' + index"
                          [attr.aria-expanded]="accordionItemModule.expanded"
                          [attr.aria-controls]="'accordion-body-' + index">
                          <div [ngClass]="{'profile-register-accordion-item-header-expanded' : accordionItemModule.expanded, 'disabled-module' : !itemModule.isModulePermitted}"
                          class="profile-register-accordion-item-header" (click)=" !itemModule.isModulePermitted ? openModal(): accordionItemModule.toggle()">
                            {{ itemModule.nameModule }}
                            <span *ngIf="itemModule.isModulePermitted" [ngClass]="{'profile-register-accordion-item-description-expanded': accordionItemModule.expanded}"
                            class="profile-register-accordion-item-description">
                              Clique para  {{ accordionItemModule.expanded ? 'fechar' : 'abrir' }}
                            </span>
                          </div>
                          <div
                            class="profile-register-accordion-item-body"
                            role="region"
                            [style.display]="accordionItemModule.expanded ? '' : 'none'"
                            [attr.id]="'accordion-body-' + index"
                            [attr.aria-labelledby]="'accordion-header-' + index">
                            <div class="feature-content" *ngFor="let itemFeature of itemModule.listFunctionality; let indexFeature = index;"
                            [ngClass]="{'feature-border': indexFeature + 1 < itemModule.listFunctionality.length}">
                                <span class="feature-content-header">{{itemFeature.functionalityName}}</span>
                                <div class="feature-content-roles">
                                    <mat-checkbox class="role-checkbox" *ngFor="let itemRole of itemFeature.listRole"
                                    [checked]="itemRole.selected" (change)="itemRole.selected = !itemRole.selected">
                                        {{itemRole.roleName}}
                                    </mat-checkbox>
                                </div>
                            </div>
                          </div>
                        </cdk-accordion-item>
                        </ngContainer>
                    </cdk-accordion>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <a mat-flat-button type="button" routerLink="/master/profile" color="accent" class=" btn-block">
                            Cancelar
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>

</div>    
