<div class="content">
    <form class="form" [formGroup]="model" (ngSubmit)="submit()">
        <span class="pin-intro">Digite os seus dados de identificação para começar</span>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field appearance="outline">
                    <mat-label>Matrícula ou identificador</mat-label>
                    <input matInput type="text" formControlName="login" required>
                    <mat-error *ngIf="model.get('login').invalid">Informe a Matricula ou identificador</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Senha ou Pin Usuário</mat-label>
                        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                        <input matInput #password type="password" [type]="hide ? 'text' : 'password'" formControlName="password" required>
                        <mat-error *ngIf="model.get('password').invalid">Informe a senha</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        
        <div class="row">
            <div class="col-md-12" >
                <button mat-flat-button color="primary" type="submit" class="btn btn-primary btn-sm">
                    <span *ngIf="isLoading == false">Login</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn"
                    *ngIf="isLoading == true"></mat-spinner>
                </button>
            </div>
        </div>
    </form>
</div>
