import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { MultiProfessionalSectionFillConfigResponse } from '../../responses/multi-professional/multi_professional-section-fill-config.response ';

@Injectable({
  providedIn: 'root'
})
export class MultiProfessionalSectionFillConfigService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listMultiProfessionalSectionFillConfig(): Observable<MultiProfessionalSectionFillConfigResponse> {
    
    let uri = `MultiProfessionalSectionFillConfig`
    
    return this.httpClient.get<MultiProfessionalSectionFillConfigResponse>(environment.urlApiMultiProfessional + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}