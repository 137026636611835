import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { HierarchyMedicineTypeStruct } from 'src/app/shared/services/structs/pharmacy/hierarchy-medicine-type-struct';
import { MedicineStruct } from 'src/app/shared/services/structs/pharmacy/medicine.struct';

@Component({
  selector: 'app-medicine-tree',
  templateUrl: './medicine-tree.component.html',
  styleUrls: ['./medicine-tree.component.css']
})
export class MedicineTreeComponent implements OnInit {

  constructor() { }

  @Input() listMedicineType: HierarchyMedicineTypeStruct[];
  @Output() sendListIdMedicine = new EventEmitter<number[]>();

  nestedDataSource = new MatTreeNestedDataSource<MedicineStruct>();


  ngOnInit(): void {
  }

  onCheckChange(idMedicineType:number, idMedicineGroup:number, idMedicineSubgroup:number) {
    let listIdMedicine: number[] = [];

    let isAlldeletedGroup:boolean = true;
    this.listMedicineType.forEach(medicineType => {
      
      medicineType.listMedicineGroup.forEach(medicineGroup => {

        if(idMedicineType == medicineType.idMedicineType){
          medicineGroup.isChecked = medicineType.isChecked;
        }

        let isAlldeletedMedicine1:boolean = true;
        medicineGroup.listMedicine.forEach(medicine => {

          if(idMedicineGroup == medicineGroup.idMedicineGroup || idMedicineType == medicineType.idMedicineType){
            medicine.isChecked = medicineGroup.isChecked;
          }

          if(medicine.isChecked){
            medicineGroup.isChecked = true;
            listIdMedicine.push(medicine.idMedicine);
            isAlldeletedMedicine1 = false;
          }
        });

        //Se todas os medicamentos forem false
        if(isAlldeletedMedicine1 == true){
          medicineGroup.isChecked = false;
        }

        medicineGroup.listMedicineSubgroup.forEach(medicineSubgroup => {

          if(idMedicineGroup == medicineGroup.idMedicineGroup || idMedicineType == medicineType.idMedicineType){
            medicineSubgroup.isChecked = medicineGroup.isChecked;
          }

          let isAlldeletedMedicine2:boolean = true;
          medicineSubgroup.listMedicine.forEach(medicine => {

            if(medicineSubgroup.idMedicineSubgroup ==  idMedicineSubgroup||idMedicineGroup == medicineGroup.idMedicineGroup || idMedicineType == medicineType.idMedicineType){
              medicine.isChecked = medicineSubgroup.isChecked;
            }

            if(medicine.isChecked){
              medicineSubgroup.isChecked = true;
              listIdMedicine.push(medicine.idMedicine);
              isAlldeletedMedicine2 = false;
            }           
          });

          //Se todas os medicamentos forem false
          if(isAlldeletedMedicine2 == true){
            medicineSubgroup.isChecked = false;
          }

          if(medicineSubgroup.isChecked){
            medicineGroup.isChecked = true;
          }
        });
       
        if(medicineGroup.isChecked){
          medicineType.isChecked = true;
          isAlldeletedGroup = false;
        }
         
      });

      if(isAlldeletedGroup == true)
        medicineType.isChecked = false

    });

    this.sendListIdMedicine.emit(listIdMedicine);
  }

  // setAll(event) {
  //   console.log(event)
  //   let listIdMedicine: number[] = [];
  //   this.listMedicineType.forEach(medicineType => {

  //     medicineType.isChecked = event;

  //     medicineType.listMedicineGroup.forEach(medicineGroup => {

  //       medicineGroup.isChecked = event;

  //       medicineGroup.listMedicine.forEach(medicine => {
  //         medicineGroup.isChecked = event;
  //         if(medicine.isChecked){
  //           listIdMedicine.push(medicine.idMedicine);
  //         }
  //       });

  //       medicineGroup.listMedicineSubgroup.forEach(medicineSubgroup => {
  //         medicineSubgroup.isChecked = event;
  //         medicineSubgroup.listMedicine.forEach(medicine => {
  //           medicineSubgroup.isChecked = event;
  //           if(medicine.isChecked){
  //             listIdMedicine.push(medicine.idMedicine);
  //           }           
  //         });         
  //       });             
  //     });
  //   });

  //   this.sendListIdMedicine.emit(listIdMedicine);
  // }
}
