import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ChangelogLookupResponse } from '../../responses/user/changelog-lookup.response';

@Injectable({
  providedIn: 'root'
})
export class ChangeLogService extends CommonService {

  constructor(private httpClient: HttpClient) { super(); }

  public GetLookups(idModule: number): Observable<ChangelogLookupResponse> {
    let uri = `Changelog/GetLookups/${idModule}`;

    return this.httpClient.get<ChangelogLookupResponse>(environment.urlApiOrchestratorUser + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}