<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="title-modal">
            <h1>Alerta</h1>
        </div>
        <div class="body-modal">
            Você deseja utilizar os seguintes dados de convênio preenchidos pelo paciente no agendamento?
            <ul>
                <li><strong>Nome do Convênio:</strong>
                    {{this.data.scheduledPatientHealthcareAgreement.nameHealthcareAgreement}}</li>
                <li><strong>Nome do Plano:</strong> {{this.data.scheduledPatientHealthcareAgreement.planName}}</li>
                <li><strong>Data de Expiração:</strong>
                    {{this.data.scheduledPatientHealthcareAgreement.healthcareAgreementExpirationDate |
                    date:'dd/MM/yyyy'}}</li>
                <li><strong>Número da Carteirinha:</strong>
                    {{this.data.scheduledPatientHealthcareAgreement.healthcareAgreementCardNumber}}</li>
            </ul>
        </div>
        <div class="div-buttons">
            <button mat-flat-button color="primary" class="button-close-modal" (click)="confirm()">Utilizar
            </button>
            <button mat-flat-button color="accent" class="button-close-modal" (click)="cancel()">Não utilizar
            </button>
        </div>

    </div>
</div>