import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NextStepsAfterTriageProtocol } from 'src/app/shared/services/models/care-line-priority/next-steps-after-triage-protocol.model';

@Component({
  selector: 'app-next-steps-protol-modal',
  templateUrl: './next-steps-protol-modal.component.html',
  styleUrls: ['./next-steps-protol-modal.component.css']
})
export class NextStepsProtolModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<NextStepsProtolModalComponent>) { }

  public listNextStepsAfterTriageProtocol: NextStepsAfterTriageProtocol[] = this.data.nextSteps;

  ngOnInit(): void {
  }

  clickPrimary() {
    this.matDialogRef.close();
  }
}