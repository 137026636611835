import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { GetPatientByCpfService } from 'src/app/shared/services/API/medical-record/get-patient-by-cpf.service';
import { ListPatientByCnsService } from 'src/app/shared/services/API/medical-record/list-patient-by-cns.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';

@Component({
  selector: 'app-search-patient-modal',
  templateUrl: './search-patient-modal.component.html',
  styleUrls: ['./search-patient-modal.component.css']
})
export class SearchPatientModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private getPatientByCpfService: GetPatientByCpfService,
    private listPatientByCnsService: ListPatientByCnsService,
    private alertService: AlertService,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<SearchPatientModalComponent>,
    private formBuilder: UntypedFormBuilder,
    public router: Router,
    private maskService: MaskService,
  ) { }

  public isLoading: boolean;
  public model: UntypedFormGroup;
  public masks: Masks;
  public cpfCnsMask: any;

  ngOnInit(): void {
    this.isLoading = false;
    this.masks = this.maskService.getMasks();
    this.cpfCnsMask = this.masks.cpfCns;
    this.model = this.formBuilder.group({
      cpfCns: ['', [Validators.required]],
    });
  }

  clickCancel() {
    this.matDialogRef.close({ fowardPatient: false });
  }

  submit() {
    if (this.model.invalid || this.isLoading == true)
      return;

    if (this.model.get('cpfCns').value.replace(/[^0-9]/g, '').replace(' ', '').length <= 11) {
      this.searchByCPF(this.model.get('cpfCns').value.replace(/[^0-9]/g, '').replace(' ', ''));
    }
    else {
      this.searchByCNS(this.model.get('cpfCns').value.replace(/[^0-9]/g, '').replace(' ', ''));
    }
  }

  searchByCPF(cpf: string) {
    this.isLoading = true;
    this.getPatientByCpfService.getPatientByCPF(cpf).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.isLoading = false;
      let listPatient = [];
      listPatient.push(response.patient);
      this.matDialogRef.close(listPatient);
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  searchByCNS(cns: string) {
    this.isLoading = true;
    this.listPatientByCnsService.getPatientByCNS(cns).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.isLoading = false;

      this.matDialogRef.close(response.listPatient);
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }
}
