<div class="white-body">
    <form class="form" [formGroup]="model" (ngSubmit)="submit()">
        <div class="title-header">
            <h1 class="border-green-left-title">Classificação Branco</h1>
        </div>
        <div class="row justify-content-md-center">
            <div class="col-12 col-12 col-md-4">
                <div class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Razão de Entrada</mat-label>
                            <mat-select formControlName="idArrivalReason"
                                (ngModelChange)="onChangeArrivalReason($event)">
                                <mat-option *ngFor="let item of arrivalReasonList" [value]="item.idArrivalReason">
                                    {{item.arrivalReasonName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="idArrivalReasonOthers == idArrivalReason" class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Descreva a razão de entrada</mat-label>
                            <textarea matInput formControlName="arrivalReason"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Necessidades especiais</mat-label>
                            <mat-select formControlName="idSpecialNecessity"
                                (ngModelChange)="onChangeSpecialNecessity($event)">
                                <mat-option>Nenhuma</mat-option>
                                <mat-option *ngFor="let item of specialNecessityList"
                                    [value]="item.idSpecialNecessity">{{item.specialNecessityName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="idSpecialNecessity == idSpecialNecessityOthers" class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Descreva a necessidade especial</mat-label>
                            <textarea matInput formControlName="specialNecessity"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Observação</mat-label>
                            <textarea matInput formControlName="observation"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-6">
                        <a mat-flat-button type="button" color="accent" class=" btn-block" (click)="goBack()">
                            Voltar
                        </a>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span *ngIf="isLoading == false">Avançar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>