<div class="container-generic">
    <form class="form" [formGroup]="model" (ngSubmit)="submit()">
        <div class="feature-content">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-label>
                        <strong>Nome Medico: </strong>
                        <p>{{sadt.userName}}</p>
                    </mat-label>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Observações do Médico</mat-label>
                        <textarea matInput formControlName="observationMedic"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-label>
                        <strong>Exames: </strong>
                        <p *ngFor="let exam of sadt.medicalCareSadt">- {{exam.sadtName}}</p>
                    </mat-label>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Observações Exame</mat-label>
                        <textarea matInput formControlName="observation"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Resultados do exame</mat-label>
                        <textarea matInput formControlName="sadtResult" maxlength="1000"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="row" *ngIf="codCbo">
                <div class="col-12 col-sm-12 col-md-12">
                    <app-select (selectedValue)="addItemProcedure($event)" [multiple]="true" [url]="urlListProcedure"
                        [idField]="'idProcedureSigtap'" [nameField]="'completeProcedureName'"
                        [labelField]="'Procedimentos Sigtap'"></app-select>
                </div>
            </div>
            <div class="row">
                <div class="col-7">
                    <div class="col-12"
                        *ngIf="this.sadt && (this.sadt.idStatus == sadtStatusAguardando.valueOf() || this.sadt.idStatus == sadtStatusPedido.valueOf()) && (this.model.get('fileLinkedLater').value || !this.sadt.isSaved || this.model.get('sadtFiles').value != null)">
                        <mat-form-field>
                            <ngx-mat-file-input formControlName="sadtFiles" [multiple]="true"
                                placeholder="Arquivos do exame" (change)="inputChange()">
                                <mat-icon ngxMatFileInputIcon>folder</mat-icon>
                            </ngx-mat-file-input>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-8"
                        *ngIf="((this.sadt && this.sadt.idStatus == sadtStatusEntregue.valueOf()) || (this.sadt.sadtResultFile.length > 0) || (this.sadt.isSaved))">
                        <div *ngFor="let item of this.sadt.sadtResultFile" class="col-12" class="outline-spinner">
                            <a (click)="downloadFile(item.idSadtResultFile)" class="download">
                                <mat-icon *ngIf="!isLoadingFile">file_download</mat-icon> Ver arquivo
                                {{item.sadtResultFileName}}
                                <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                    *ngIf="isLoadingFile"></mat-spinner>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-5 col-sm-3 col-md-5 align-item">
                    <mat-slide-toggle formControlName="fileLinkedLater" checked="fileLinkedLater"
                        (change)="changeFileLinkedLater()">Arquivo será vinculado posteriormente
                    </mat-slide-toggle>
                </div>
            </div>
            <div (click)="sadt.isSaved ? alertCannotSave() : null">
                <button [disabled]="isSubmitDisabled()" mat-flat-button color="primary" class="" type="submit">
                    <span *ngIf="!sadt.isLoadingSadt">Salvar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="sadt.isLoadingSadt"></mat-spinner>
                </button>
            </div>
        </div>
    </form>
</div>