<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-modal-list">
            <h2>Dados do paciente</h2>
        </div>

        <div class="body-modal-list">
            <form class="form" [formGroup]="model">
                
                <div class="row">
                    <div class="col-md-3 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome</mat-label>
                            <input matInput type="text" readonly formControlName="patientName">
                        </mat-form-field>
                    </div>
                    <div class="col-md-3 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Data de nascimento</mat-label>
                            <input matInput type="text" readonly formControlName="birthDate">
                        </mat-form-field>
                    </div>
                    <div class="col-md-3 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Gênero</mat-label>
                            <input matInput type="text" readonly formControlName="genderName">
                        </mat-form-field>
                    </div>
                    <div class="col-md-3 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>CPF</mat-label>
                            <input matInput type="text" readonly formControlName="cpf">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Telefone 1</mat-label>
                            <input matInput type="text" readonly formControlName="phone1">
                        </mat-form-field>
                    </div>
                    <div class="col-md-4 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Telefone 2</mat-label>
                            <input matInput type="text" readonly formControlName="phone2">
                        </mat-form-field>
                    </div>
                    <div class="col-md-4 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>CNS</mat-label>
                            <input matInput type="text" readonly formControlName="cns">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>CEP</mat-label>
                            <input matInput type="text" readonly formControlName="zipCode">
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Logradouro</mat-label>
                            <input matInput type="text" readonly formControlName="street">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Número</mat-label>
                            <input matInput type="text" readonly formControlName="houseNumber">
                        </mat-form-field>
                    </div>
                    <div class="col-md-4 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Complemento</mat-label>
                            <input matInput type="text" readonly formControlName="apartmentNumber">
                        </mat-form-field>
                    </div>
                    <div class="col-md-4 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Bairro</mat-label>
                            <input matInput type="text" readonly formControlName="neighborhood">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Cidade</mat-label>
                            <input matInput type="text" readonly formControlName="city">
                        </mat-form-field>
                    </div>
                    <div class="col-md-4 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Estado</mat-label>
                            <input matInput type="text" readonly formControlName="state">
                        </mat-form-field>
                    </div>
                    <div class="col-md-4 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>País</mat-label>
                            <input matInput type="text" readonly formControlName="country">
                        </mat-form-field>
                    </div>
                </div>
                
                <button mat-flat-button type="button" color="primary" class="btn-block" (click)="close()"><span>Fechar</span></button>
            </form>
        </div>
    </div>
</div>