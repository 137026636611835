import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { PostPutFrontDeskRequest } from '../../requests/front-desk/post-put-front-desk.request';
import { GetFrontDeskResponse } from '../../responses/front-desk/get-front-desk.response';
import { ListFrontDeskResponse } from '../../responses/front-desk/list-front-desk.response';
import { MetaDataStruct } from '../../structs/meta-data/meta-data.struct';

@Injectable({
  providedIn: 'root'
})
export class FrontDeskService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  public listFrontDesk(searchText: string, idSector: number): Observable<ListFrontDeskResponse> {
    let uri = `FrontDesk?`

    if (searchText != null)
      uri = uri + `searchText=${searchText}&`;

    if (idSector != null)
      uri = uri + `idSector=${idSector}&`

    return this.httpClient.get<ListFrontDeskResponse>(environment.urlApiFrontDesk + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }

  public getFrontDesk(listMetaData: MetaDataStruct[], idFrontDesk: number): Observable<GetFrontDeskResponse> {
    let uri = `FrontDesk/idFrontDesk/${idFrontDesk}`;

    return this.httpClient.post<GetFrontDeskResponse>(
      environment.urlApiFrontDesk + uri,
      listMetaData,
      this.addHeaderToken()
    ).pipe(
      catchError(this.handleError)
    );
  }

  public createFrontDesk(body: PostPutFrontDeskRequest): Observable<ReturnStruct> {
    let uri = `FrontDesk`

    return this.httpClient.post<ReturnStruct>(environment.urlApiFrontDesk + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }

  public updateFrontDesk(idFrontDesk: number, body: PostPutFrontDeskRequest): Observable<ReturnStruct> {
    let uri = `FrontDesk/idFrontDesk/${idFrontDesk}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiFrontDesk + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }

  public deleteFrontDesk(idFrontDesk: number): Observable<ReturnStruct> {
    let uri = `FrontDesk/idFrontDesk/${idFrontDesk}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiFrontDesk + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }
}