import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { GetUserPatientResponse } from '../../responses/patient-center/get-user-patient.response';
import { GetUserNamePatientAppointmentResponse } from '../../responses/patient-center/get-user-name-patient-appointment.response';
import { PatientAppointment } from '../../models/schedule/patient-appointment.model';

@Injectable({
  providedIn: 'root'
})
export class UserPatientService extends CommonService{

  constructor(private httpClient: HttpClient) {
    super();
  }

  public GetByIdsPatientUser(listAffectedPatientsList: PatientAppointment[]): Observable<GetUserNamePatientAppointmentResponse> {
    let uri = `UserPatient/listPatientAppointmentStruct`
    
    return this.httpClient.post<GetUserNamePatientAppointmentResponse>(environment.urlApiPatientCenter + uri, listAffectedPatientsList, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    );
  }
}