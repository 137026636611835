import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { VerifyCNPJ } from 'src/app/shared/custom-validators/cnpj.validator';
import { VerifyCPF } from 'src/app/shared/custom-validators/cpf.validator';
import { ProviderFieldLengthEnum } from 'src/app/shared/enum/private-billing/provider-field-length.enum';
import { ProviderIdentityTypeEnum } from 'src/app/shared/enum/private-billing/provider-identity-type.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { Cbo } from 'src/app/shared/services/models/private-billing/cbo.model';
import { ParticipationDegree } from 'src/app/shared/services/models/private-billing/participation-degree.model';
import { ProfessionalCouncil } from 'src/app/shared/services/models/private-billing/professional-council.model';
import { ProviderIdentityType } from 'src/app/shared/services/models/private-billing/provider-identity-type.model';
import { State } from 'src/app/shared/services/models/private-billing/state.model';
import { ProfessionalAgreementStruct } from 'src/app/shared/services/structs/private-billing/professional-agreement.struct';

@Component({
  selector: 'app-view-data',
  templateUrl: './view-data.component.html',
  styleUrls: ['./view-data.component.css']
})
export class ViewDataComponent implements OnInit, OnDestroy {

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private maskService: MaskService,
    private dialog: MatDialog,
  ) { }
  
  @Output() onDataChanged = new EventEmitter<{ professionalAgreementStruct: ProfessionalAgreementStruct }>();
  @Output() professionalAgreementDataChanged = new EventEmitter<{ professionalAgreementStruct: ProfessionalAgreementStruct, index: number }>();
  @Output() fieldChanged = new EventEmitter<void>();
  @Output() cancelNewProfessional = new EventEmitter<void>();
  @Input() listProfessionalCouncil: ProfessionalCouncil[] = [];
  @Input() listParticipationDegree: ParticipationDegree[] = [];
  @Input() listProviderIdentityType: ProviderIdentityType[] = [];
  @Input() listCbo: Cbo[] = [];
  @Input() listState: State[] = [];
  @Input() professionalAgreementStruct: ProfessionalAgreementStruct;
  @Input() idHealthcareAgreement: number;
  @Input() showAdditionalFields: boolean;
  @Input() index: number;
  public masks: Masks;
  public providerTypeMask;
  public model: FormGroup;
  public isLoading: boolean = false;
  public professionalNumberMaxLength: number = ProviderFieldLengthEnum.professionalNumberMaxLength;
  public operatorCodeMaxLength: number = ProviderFieldLengthEnum.operatorCodeMaxLength;
  public professionalNameMaxLength: number = ProviderFieldLengthEnum.providerNameMaxLength
  public inputTimeout;
  public isEdit: boolean = false;

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      idState: [null, [Validators.required]],
      idParticipationDegree: [null, [Validators.required]],
      idProviderIdentityType: [null, [Validators.required]],
      idProfessionalCouncil: [null, [Validators.required]],
      codeOperator: [null, [Validators.required]],
      numberProfessional: [null, [Validators.required]],
      nameProfessional: ['', [Validators.required]],
      idCbo: ['', [Validators.required]],
      isActive:[true, [Validators.required]],
    });
    this.listState.sort((a, b) => a.stateCode.localeCompare(b.stateCode))
    this.mapperData();
  }

  ngOnDestroy(){
    clearTimeout(this.inputTimeout);
  }

  mapperData() {
    this.model.get('idCbo').setValue(this.professionalAgreementStruct.idCbo);
    this.model.get('idState').setValue(this.professionalAgreementStruct.idState)
    this.model.get('idParticipationDegree').setValue(this.professionalAgreementStruct.idParticipationDegree)
    this.model.get('idProviderIdentityType').setValue(this.professionalAgreementStruct.idProviderIdentityType)
    this.model.get('idProfessionalCouncil').setValue(this.professionalAgreementStruct.idProfessionalCouncil);
    this.model.get('codeOperator').setValue(this.professionalAgreementStruct.codeOperator);
    this.model.get('numberProfessional').setValue(this.professionalAgreementStruct.numberProfessional);
    this.model.get('nameProfessional').setValue(this.professionalAgreementStruct.nameProfessional);
    this.model.get('isActive').setValue(this.professionalAgreementStruct.isActive);
    if(this.professionalAgreementStruct.idProviderIdentityType == ProviderIdentityTypeEnum.cpf){
      this.providerTypeMask = this.masks.cpf
      this.model.get('codeOperator').setValidators([Validators.required, VerifyCPF()]);
      this.operatorCodeMaxLength = ProviderFieldLengthEnum.cpfMaxLength;
    }
    else if(this.professionalAgreementStruct.idProviderIdentityType == ProviderIdentityTypeEnum.cnpj){
      this.providerTypeMask = this.masks.cnpj
      this.model.get('codeOperator').setValidators([Validators.required, VerifyCNPJ()]);
      this.operatorCodeMaxLength = ProviderFieldLengthEnum.cnpjMaxLength;
    }
    else{
      this.providerTypeMask = null;
      this.model.get('codeOperator').setValidators([Validators.required]);
      this.operatorCodeMaxLength = ProviderFieldLengthEnum.operatorCodeMaxLength;
    }

    if(this.professionalAgreementStruct.idProfessionalAgreement){
      this.model.get('idCbo').disable();
      this.model.get('idState').disable();
      this.model.get('idParticipationDegree').disable();
      this.model.get('idProfessionalCouncil').disable();
      this.model.get('idProviderIdentityType').disable();
      this.model.get('numberProfessional').disable();
      this.model.get('nameProfessional').disable();
      this.model.get('codeOperator').disable();
      this.model.get("isActive").disable();
    }
  }

  submit() {
    if (!this.model.valid) {
      this.alertService.show('Erro', "Preencha todos os campos obrigatórios.", AlertType.error);
      this.model.markAllAsTouched();
      return;
    }

    if (this.isLoading)
      return;

    this.isLoading = true;

    let professionalAgreementStruct = new ProfessionalAgreementStruct();
    professionalAgreementStruct.idCbo = this.model.get('idCbo').value;;
    professionalAgreementStruct.idState = this.model.get('idState').value;
    professionalAgreementStruct.idParticipationDegree = this.model.get('idParticipationDegree').value;
    professionalAgreementStruct.idProfessionalCouncil = this.model.get('idProfessionalCouncil').value;
    professionalAgreementStruct.idProviderIdentityType = this.model.get('idProviderIdentityType').value;
    professionalAgreementStruct.numberProfessional = this.model.get('numberProfessional').value;
    professionalAgreementStruct.nameProfessional = this.model.get('nameProfessional').value;
    professionalAgreementStruct.idHealthcareAgreement = this.idHealthcareAgreement;
    professionalAgreementStruct.codeOperator = this.model.get('codeOperator').value;
    professionalAgreementStruct.isActive = this.model.get("isActive").value;
    this.onCreateOrUpdate(professionalAgreementStruct);
  }

  onCreateOrUpdate(professionalAgreementStruct: ProfessionalAgreementStruct) {
    this.showAdditionalFields = false;
    this.onDataChanged.emit({ professionalAgreementStruct });
  }

  editData() {
    if (!this.model.valid) {
      this.alertService.show('Erro', "Preencha corretamente todos os campos obrigatórios para alterar o profissional.", AlertType.error);
      this.model.markAllAsTouched();
      return;
    }
    const idCbo = this.model.get('idCbo').value;
    const idState = this.model.get('idState').value;
    const idParticipationDegree = this.model.get('idParticipationDegree').value;
    const idProviderIdentityType = this.model.get('idProviderIdentityType').value;
    const idProfessionalCouncil = this.model.get('idProfessionalCouncil').value;
    const codeOperator = this.model.get('codeOperator').value;
    const numberProfessional = this.model.get('numberProfessional').value;
    const nameProfessional = this.model.get('nameProfessional').value;
    const isActive = this.model.get('isActive').value;

    if (idCbo !== null && idCbo !== undefined)
      this.professionalAgreementStruct.idCbo = idCbo;

    if (idState !== null && idState !== undefined)
      this.professionalAgreementStruct.idState = idState;

    if (idParticipationDegree !== null && idParticipationDegree !== undefined)
      this.professionalAgreementStruct.idParticipationDegree = idParticipationDegree;

    if (idProviderIdentityType !== null && idProviderIdentityType !== undefined)
      this.professionalAgreementStruct.idProviderIdentityType = idProviderIdentityType;

    if (idProfessionalCouncil !== null && idProfessionalCouncil !== undefined)
      this.professionalAgreementStruct.idProfessionalCouncil = idProfessionalCouncil;

    if (codeOperator !== null && codeOperator !== undefined)
      this.professionalAgreementStruct.codeOperator = codeOperator;

    if (numberProfessional !== null && numberProfessional !== undefined)
      this.professionalAgreementStruct.numberProfessional = numberProfessional;

    if (nameProfessional !== null && nameProfessional !== undefined)
      this.professionalAgreementStruct.nameProfessional = nameProfessional;

    if (isActive !== null && isActive !== undefined)
      this.professionalAgreementStruct.isActive = isActive;


    this.professionalAgreementStruct.isEdited = true;
    this.emitDataChanged();

    this.isEdit = false;
  }

  emitDataChanged() {
    this.professionalAgreementDataChanged.emit({ professionalAgreementStruct: this.professionalAgreementStruct, index: this.index });
    this.model.get('idCbo').disable();
    this.model.get('idState').disable();
    this.model.get('idParticipationDegree').disable();
    this.model.get('idProfessionalCouncil').disable();
    this.model.get('idProviderIdentityType').disable();
    this.model.get('numberProfessional').disable();
    this.model.get('nameProfessional').disable();
    this.model.get('codeOperator').disable();
    this.model.get("isActive").disable();
  }

  onChangeProviderType(event){
    if(event.value == ProviderIdentityTypeEnum.cpf){
      this.providerTypeMask = this.masks.cpf
      this.model.get('codeOperator').setValidators([Validators.required, VerifyCPF()]);
      this.operatorCodeMaxLength = ProviderFieldLengthEnum.cpfMaxLength;
    }
    else if(event.value == ProviderIdentityTypeEnum.cnpj){
      this.providerTypeMask = this.masks.cnpj
      this.model.get('codeOperator').setValidators([Validators.required, VerifyCNPJ()]);
      this.operatorCodeMaxLength = ProviderFieldLengthEnum.cnpjMaxLength;
    }
    else{
      this.providerTypeMask = null;
      this.model.get('codeOperator').setValidators( [Validators.required]);
      this.operatorCodeMaxLength = ProviderFieldLengthEnum.operatorCodeMaxLength;
    }
    this.model.get('codeOperator').setValue(null);
  }

  cancelCreate(){
    let reportDialog = this.dialog.open(ConfirmModalComponent, {
      data: {
        title: "Aviso!",
        message: "Você realmente deseja cancelar? Qualquer alteração realizada pode não ter sido salva.",
        btnPrimary: "Sim, cancelar",
        btnAccent: "Não, continuar editando"
      },
    });
    reportDialog.afterClosed().subscribe(result => {
      if (result && result.clickPrimary) {
        this.isLoading = true;
        if(!this.isEdit){
          this.cancelNewProfessional.emit();
        }
        else {
          this.isEdit = false;
          this.mapperData();
        }
      }
    });
  }

  editProfessional(){
    this.isEdit = true;
    this.model.get('idCbo').enable();
    this.model.get('idState').enable();
    this.model.get('idParticipationDegree').enable();
    this.model.get('idProfessionalCouncil').enable();
    this.model.get('idProviderIdentityType').enable();
    this.model.get('numberProfessional').enable();
    this.model.get('nameProfessional').enable();
    this.model.get('codeOperator').enable();
    this.model.get("isActive").enable();
  }
}