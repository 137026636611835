<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <a class="back-link" routerLink="/audit/audit">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Auditorias
        </a>
        <h1>Cadastrar Auditoria</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="title-header">
                    <h1>Dados da Auditoria</h1>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Mês/Ano</mat-label>
                            <mat-select formControlName="yearMonth" (selectionChange)="setDays($event.value)">
                                <mat-option *ngFor="let item of listDate" [value]="item">{{item | date:"MMM/yyyy"}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('yearMonth').invalid">Selecione o Ano e Mês</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Dia Inicial</mat-label>
                            <mat-select formControlName="initialDate"
                                (selectionChange)="clearFinalDayValue($event.value)">
                                <mat-option *ngFor="let item of listDays" [value]="item">{{item}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('initialDate').invalid">Selecione o Dia Inicial</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Dia Final</mat-label>
                            <mat-select formControlName="finalDate">
                                <ng-container *ngFor="let item of listDays">
                                    <mat-option [value]="item" *ngIf="item >= model.get('initialDate').value">{{item}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                            <mat-error *ngIf="model.get('finalDate').invalid">Selecione o Dia Final</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Protocolo</mat-label>
                            <mat-select formControlName="idProtocol">
                                <mat-option *ngFor="let item of listProtocol"
                                    [value]="item.idProtocol">{{item.protocolName}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idProtocol').invalid">Selecione o Protocolo</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-3">
                        <button mat-flat-button type="button" color="primary" class=" btn-block"
                            (click)="getAllUserEvenDeletedAndTriageAudited()">
                            <span *ngIf="isLoading == false">Pesquisar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                </div>
                <ngContainer *ngIf="showSave">
                    <div class="title-header">
                        <h1>Usuários Classificadores</h1>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-8">
                            <ng-container *ngFor="let item of listUserSelectStruct">
                                <app-audit-user-select [auditUserSelectStruct]="item" [allowAudited]="allowAudited"></app-audit-user-select>
                            </ng-container>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2">
                            <button mat-flat-button type="submit" color="primary" class=" btn-block">
                                <span *ngIf="isLoading == false">Salvar</span>
                                <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                    *ngIf="isLoading == true"></mat-spinner>
                            </button>
                        </div>
                        <div class="col-md-2">
                            <a mat-flat-button type="button" routerLink="/audit/audit" color="accent"
                                class=" btn-block">
                                Cancelar
                            </a>
                        </div>
                    </div>
                </ngContainer>
            </form>
        </div>
    </div>
</div>