<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <h1>Escala Médica</h1>
        <div class="white-body">
            <a mat-flat-button color="primary" class=" add-user" routerLink="/form/medical-schedule/register"><mat-icon
                    aria-hidden="false" aria-label="Plus">add</mat-icon> Cadastrar Escala Médica</a>
            <div class="filters">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Usuário Responsável</mat-label>
                            <input matInput type="text" id="searchText" name="searchText" (keyup)="onKeySearch($event)"
                                [(ngModel)]="searchText">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Data da Escala</mat-label>
                            <input matInput [(ngModel)]="date" [matDatepicker]="datePicker"
                                (dateChange)="this.search(0)">
                            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                            <mat-datepicker #datePicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <app-select (selectedValue)="addItemCbo($event)" [multiple]="true" [url]="urlListCbo"
                            [idField]="'idCbo'" [nameField]="'codeWithName'" [labelField]="'CBO'"></app-select>
                    </div>
                </div>
            </div>
            <div class="card-list" *ngIf="listMedicalSchedule != null">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listMedicalSchedule">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary"> {{item.cboName}}</span>
                                <span class="secundary">{{item.medicalSchedule.startDatetime |
                                    date:'dd/MM/yyyy'}}</span>
                                <span class="secundary">{{item.medicalSchedule.endDatetime | date:'dd/MM/yyyy'}}</span>
                                <span class="secundary-status last-info">{{item.userName}}</span>
                            </div>
                            <div class="actions">
                                <a><mat-icon aria-hidden="false" aria-label="Editar"
                                        [routerLink]="['/form/medical-schedule/register', {idMedicalSchedule: item.medicalSchedule.idMedicalSchedule}]">edit</mat-icon>
                                </a>
                                <a><mat-icon aria-hidden="false" aria-label="Editar"
                                        (click)="openModal(item.medicalSchedule.idMedicalSchedule)">delete_forever</mat-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-paginator [length]="this.fullListSize" #paginator [pageIndex]="0" [pageSize]="30"
                        aria-label="Select page" (page)="changePage($event)" [hidePageSize]="true">
                    </mat-paginator>
                </div>
            </div>

            <app-empty-list
                *ngIf="listMedicalSchedule != undefined && listMedicalSchedule.length == 0"></app-empty-list>
        </div>

        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>
</div>