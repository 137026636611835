<cdk-accordion-item #accordionItemModule="cdkAccordionItem" class="profile-register-accordion-item" role="button"
    tabindex="0" [attr.id]="'accordion-header-' + index" [attr.aria-expanded]="accordionItemModule.expanded"
    [attr.aria-controls]="'accordion-body-' + index">
    <div [ngClass]="{'profile-register-accordion-item-header-expanded': accordionItemModule.expanded}"
        class="profile-register-accordion-item-header" (click)="accordionItemModule.toggle()">
        Estoque - Lote: {{ this.storage.batch }} | Validade: {{ storage.dateExpiration | date:'dd/MM/yy' }} | Quantidade
        total: {{storage.totalAmount}} | Quantidade restante: {{storage.totalAmountRemaining}}
        <span [ngClass]="{'profile-register-accordion-item-description-expanded': accordionItemModule.expanded}"
            class="profile-register-accordion-item-description">
            Clique para {{ accordionItemModule.expanded ? 'fechar' : 'abrir' }}
        </span>
    </div>
    <div class="profile-register-accordion-item-body" role="region"
        [style.display]="accordionItemModule.expanded ? '' : 'none'" [attr.id]="'accordion-body-' + index"
        [attr.aria-labelledby]="'accordion-header-' + index">
        <div class="feature-content">
            <div class="row">
                <div class="col-6 col-sm-3 col-md-2">
                    <p>Lote:</p>
                    <p><b>{{storage.batch}}</b></p>
                </div>
                <div class="col-6 col-sm-3 col-md-2">
                    <p>Validade:</p>
                    <p><b>{{ storage.dateExpiration | date:'dd/MM/yy' }}</b></p>
                </div>
                <div class="col-6 col-sm-3 col-md-2">
                    <p>Data do cadastro:</p>
                    <p><b>{{ storage.dateTimeInclusion | date:'dd/MM/yy, HH:mm' }}</b></p>
                </div>
                <div class="col-12 col-sm-3 col-md-2">
                    <p>Quantidade Total:</p>
                    <p><b>{{storage.totalAmount}}</b></p>
                </div>
                <div class="col-12 col-sm-3 col-md-2">
                    <p>Quantidade restante:</p>
                    <p><b>{{storage.totalAmountRemaining}}</b></p>
                </div>
            </div>
            <div class="title-header">
                <h1>Itens por local</h1>
            </div>
            <app-location-accordion (wasUpdated)="updateWindow()"
                [storageAmountLocation]="storage.storageAmountLocation"></app-location-accordion>
        </div>
    </div>
</cdk-accordion-item>