import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MedicalSpecialtyService } from 'src/app/shared/services/API/digital-prompt/medical-specialty.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
@Component({
  selector: 'app-medical-specialty-delete-modal',
  templateUrl: './medical-specialty-delete-modal.component.html',
  styleUrls: ['./medical-specialty-delete-modal.component.css']
})
export class MedicalSpecialtyDeleteModalComponent implements OnInit {
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<MedicalSpecialtyDeleteModalComponent>,
    private medicalSpecialtyService: MedicalSpecialtyService,
    private alertService: AlertService,
  ) { }
  
  public isLoading:boolean;  
  
  ngOnInit(): void {
    this.isLoading = false;
  }
  
  close(){
    this.matDialogRef.close({deleteMedicalSpecialty: false}); 
  }
  
  clickDelete(){
    
    this.isLoading = true;
    
    this.medicalSpecialtyService.deleteMedicalSpecialty(this.data.idMedicalSpecialty).subscribe({
      next: (response)=>{
        
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        
        this.alertService.show('Sucesso', "Especialidade médica excluida com sucesso!", AlertType.success); 
        this.matDialogRef.close({deleteMedicalSpecialty: true}); 
      },
      error: (error)=>{
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
  
  clickCancel(){
    this.matDialogRef.close({deleteMedicalSpecialty: false}); 
  }
}

