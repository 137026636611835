import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder as FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AuditStatusEnum } from 'src/app/shared/enum/audit-status.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ExecuteAuditWarningModalComponent } from './execute-audit-warning-modal/execute-audit-warning-modal/execute-audit-warning-modal.component';
import { CorrectAndIncorrectClassificationReasonService } from 'src/app/shared/services/API/audit/correct-and-incorrect-classification-reason.service';
import { MedicalRecordLookupService } from 'src/app/shared/services/API/medical-record/lookup.service';
import { AuditStatusService } from 'src/app/shared/services/API/orchestrator-audit/audit-status.service';
import { ExecuteAuditService } from 'src/app/shared/services/API/orchestrator-audit/execute-audit.service';
import { LookupRiskService } from 'src/app/shared/services/API/risk-classification/lookup-risk.service';
import { ClassificationRiskAuditOthers } from 'src/app/shared/services/models/audit/classification-risk-audit-other.model';
import { ClassificationRiskAuditTriage } from 'src/app/shared/services/models/audit/classification-risk-audit-triage.model';
import { IncorrectClassificationReason } from 'src/app/shared/services/models/audit/incorrect-classification-reason.model';
import { PriorityColorModel } from 'src/app/shared/services/models/risk-classification/priority-color.model';
import { PutClassificationRiskAuditTriageRequest } from 'src/app/shared/services/requests/audit/put-classification-risk-audit-triage.request';
import { Pain } from 'src/app/shared/services/responses/medical-record/lookup.response';
import { AuditTriageInfoStruct } from 'src/app/shared/services/structs/audit/audit-triage-info.struct';
import { AuditConfigService } from 'src/app/shared/services/API/audit/audit-config.service';
import { CorrectClassificationReason } from 'src/app/shared/services/models/audit/correct-classification-reason.model';
import { WebsocketAuditUtilService } from 'src/app/shared/services/websocket-util/websocket-audit-util.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-execute-audit',
  templateUrl: './execute-audit.component.html',
  styleUrls: ['./execute-audit.component.css']
})
export class ExecuteAuditComponent implements OnInit, OnDestroy {

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private executeAuditService: ExecuteAuditService,
    private riskLokupService: LookupRiskService,
    private medicalRecordLokupService: MedicalRecordLookupService,
    private correctAndIncorrectClassificationReasonService: CorrectAndIncorrectClassificationReasonService,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private authService: AuthService,
    public dialog: MatDialog,
    private auditStatusService: AuditStatusService,
    private auditConfigService: AuditConfigService,
    public websocketAuditUtilService: WebsocketAuditUtilService,
  ) { }

  public model: FormGroup;

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.audit;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.audit_list;

  public idAudit: number;
  public idStatus: number;
  public idAuditStatus: number;
  public sumTotalClassificationAmount: number;
  public sumAuditedUserAmount: number;
  public sumTotalAuditedClassificationAmount: number;
  public numberOtherStatusAudit: number;

  public isFirstLoading: boolean = true;
  private isStartWebsocket: boolean = false;
  public isLoading: boolean;
  public isLocked: boolean = false;;
  public viewTimeTag: boolean;
  public showFirst: boolean;
  public showSecond: boolean;
  public showOther: boolean;
  public displayCarePriorityInRiskClassification: boolean;

  public sampleAmount: string;
  public userNameFirstAuditor: string;
  public userNameSecondAuditor: string;
  public userNameOtherAuditor: string;
  public datetimeInclusionClassificationRiskAudit: Date;

  public submitInvalidateEventToAudtiTriage: Subject<void> = new Subject<void>();

  public listGravityColors: PriorityColorModel[];
  public listPain: Pain[];
  public listAllIncorrectClassificationReason: IncorrectClassificationReason[];
  public listAllCorrectClassificationReason: CorrectClassificationReason[];
  public listAuditTriageInfo: AuditTriageInfoStruct[] = [];

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.paramMap.get('idAudit'))
      this.idAudit = parseInt(this.activatedRoute.snapshot.paramMap.get('idAudit'));
    if (this.activatedRoute.snapshot.paramMap.get('idStatus'))
      this.idStatus = parseInt(this.activatedRoute.snapshot.paramMap.get('idStatus'));

    this.populateCorrectAndIncorrectClassificationReasonList();
    this.populatePainColors();
    this.getConfig();

    this.model = this.formBuilder.group({
      listTriage: this.formBuilder.array([])
    });
  }

  getConfig() {
    this.auditConfigService.getAuditConfig().subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException('Atenção', response.errorDescription, AlertType.warning);
          return;
        }

        if (response.auditConfig)
          this.viewTimeTag = response.auditConfig.viewTimeTag;
        else
          this.viewTimeTag = true;

        this.isLoading = false;
      },
      error: (error) => {
        this.throwException('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populatePainColors() {
    this.isLoading = true;
    this.isFirstLoading = true;
    this.medicalRecordLokupService.listPain().subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException('Atenção', response.errorDescription, AlertType.warning);
          return;
        }

        this.listPain = response.listPain;
        this.populateGravityColors();
      },
      error: (error) => {
        this.throwException('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateCorrectAndIncorrectClassificationReasonList() {
    this.isLoading = true;
    this.correctAndIncorrectClassificationReasonService.listAllCorrectAndIncorrectReasonIncludingDeleted().subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException('Atenção', response.errorDescription, AlertType.warning);
          return;
        }

        this.listAllIncorrectClassificationReason = response.listAllIncorrectClassificationReason;
        this.listAllCorrectClassificationReason = response.listAllCorrectClassificationReason;
        this.isLoading = false;
      },
      error: (error) => {
        this.throwException('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateGravityColors() {
    this.isLoading = true;
    this.isFirstLoading = true;
    this.riskLokupService.getLookup().subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException('Atenção', response.errorDescription, AlertType.warning);
          return;
        }

        this.listGravityColors = response.priorityColors;
        this.populateListAuditTriageInfo();
      },
      error: (error) => {
        this.throwException('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateListAuditTriageInfo() {
    this.executeAuditService.getAuditInfo(this.idAudit).subscribe({
      next: (response) => {
        if (response.isError && response.errorCode == 1) {
          this.throwException('Atenção', response.errorDescription, AlertType.warning)
          this.isFirstLoading = false;
          this.isLocked = true;
          this.router.navigate(['/audit/audit']);
          return;
        }
        if (response.isError) {
          this.throwException('Atenção', response.errorDescription, AlertType.warning)
          this.isFirstLoading = false;
          this.isLocked = true;
          return;
        }

        this.showFirst = response.showFirstAuditorPanel;
        this.showSecond = response.showSecondAuditorPanel;
        this.showOther = response.showOther;
        this.idAuditStatus = response.idStatus;

        let listIdAuditor: number[] = response.listAuditTriageInfo
          .map(x => x.listClassificationRiskAuditOthers.map(y => y.idUser))
          .flat();
        listIdAuditor.push(response.idUserFirstAuditor);
        listIdAuditor.push(response.idUserSecondAuditor);

        let isDifferent = listIdAuditor.includes(response.idUserActual);

        if (this.idAuditStatus == AuditStatusEnum.Auditado ||
            this.idAuditStatus == AuditStatusEnum.PrimeiroAuditado ||
            this.idAuditStatus == AuditStatusEnum.OpiniãoAguardando ||
            this.idAuditStatus == AuditStatusEnum.Aguardando2ªAuditoria ||
            ((this.idAuditStatus == AuditStatusEnum.DivergênciaResolvida || this.idAuditStatus == AuditStatusEnum.Divergência) && !isDifferent)
          ) {
          this.startWebsocket(response.idHealthUnit);
        }

        this.userNameFirstAuditor = response.userNameFirstAuditor;
        this.userNameSecondAuditor = response.userNameSecondAuditor;
        this.userNameOtherAuditor = response.userNameOtherAuditor;
        this.numberOtherStatusAudit = response.numberOtherStatusAudit;
        this.sumAuditedUserAmount = response.sumAuditedUserAmount;
        this.sumTotalClassificationAmount = response.sumTotalClassificationAmount;
        this.sumTotalAuditedClassificationAmount = response.sumTotalAuditedClassificationAmount;
        this.sampleAmount = ((this.sumTotalAuditedClassificationAmount / this.sumTotalClassificationAmount) * 100).toFixed(2);
        this.datetimeInclusionClassificationRiskAudit = response.datetimeInclusionClassificationRiskAudit;
        if (response.auditConfig)
          this.displayCarePriorityInRiskClassification = response.auditConfig.displayCarePriorityInRiskClassification;

        response.listAuditTriageInfo.forEach((value, index) => {
          if (value.idGravity != null)
            value.gravityColorCode = this.listGravityColors.find(x => x.idPriorityColor == value.idGravity).coloerHex;

          if (value.idPain != null)
            value.painColorCode = this.listPain.find(x => x.idPain == value.idPain).rgb;

          this.listAuditTriageInfo.push(value);
        });

        if (response.idStatus == AuditStatusEnum.Concluido)
          this.isLocked = true;
        else if (response.idStatus == AuditStatusEnum.PrimeiroAuditado
          && response.showFirstAuditorPanel) {
          this.isLocked = true;
        }

        this.listAuditTriageInfo.forEach(element => {
          element.formGroup = this.formBuilder.group({
            firstAuditorAnswer: [element.classificationRiskAuditTriage.isCorrectFirstAuditor],
            firstAuditorIncorrectObservation: [element.classificationRiskAuditTriage.observationFirstAuditor],
            firstAuditorIdIncorrectClassificationReason: [element.classificationRiskAuditTriage.idIncorrectClassificationReasonFirstAuditor],
            firstAuditorCorrectObservation: [element.classificationRiskAuditTriage.correctObservationReasonFirstAuditor],
            firstAuditorIdCorrectClassificationReason: [element.classificationRiskAuditTriage.idCorrectClassificationReasonFirstAuditor],

            secondAuditorAnswer: [element.classificationRiskAuditTriage.isCorrectSecondAuditor],
            secondAuditorIncorrectObservation: [element.classificationRiskAuditTriage.observationSecondAuditor],
            secondAuditorIdIncorrectClassificationReason: [element.classificationRiskAuditTriage.idIncorrectClassificationReasonSecondAuditor],
            secondAuditorCorrectObservation: [element.classificationRiskAuditTriage.correctObservationReasonSecondAuditor],
            secondAuditorIdCorrectClassificationReason: [element.classificationRiskAuditTriage.idCorrectClassificationReasonSecondAuditor],

            otherAuditorAnswer: [element.classificationRiskAuditOthers ? element.classificationRiskAuditOthers.isCorrectAuditor : null],
            otherAuditorIncorrectObservation: [element.classificationRiskAuditOthers ? element.classificationRiskAuditOthers.observation : ""],
            otherAuditorIdIncorrectClassificationReason: [element.classificationRiskAuditOthers ? element.classificationRiskAuditOthers.idIncorrectClassificationReason : null],
            otherAuditorCorrectObservation: [element.classificationRiskAuditOthers ? element.classificationRiskAuditOthers.correctObservationReason : ""],
            otherAuditorIdCorrectClassificationReason: [element.classificationRiskAuditOthers ? element.classificationRiskAuditOthers.idCorrectClassificationReason : null]
          });
        });

        this.isFirstLoading = false;
        this.isLoading = false;
      },
      error: (error) => {
        this.throwException('Erro inesperado', error, AlertType.error);
      }
    });
  }

  submit() {
    this.isLoading = true;
    let isAuditTriageValid = true;

    let requestExecuteAudit = new PutClassificationRiskAuditTriageRequest;
    requestExecuteAudit.idClassificationRiskAudit = this.idAudit;
    requestExecuteAudit.listClassificationRiskAuditTriage = [];
    requestExecuteAudit.listClassificationRiskAuditOthers = [];

    this.listAuditTriageInfo.forEach(element => {
      if (element.formGroup.valid) {
        let classificationRiskAuditTriage = new ClassificationRiskAuditTriage;
        classificationRiskAuditTriage.idClassificationRiskAudit = this.idAudit;
        classificationRiskAuditTriage.idClassificationRiskAuditTriage = element.classificationRiskAuditTriage.idClassificationRiskAuditTriage;
        classificationRiskAuditTriage.isCorrectFirstAuditor = element.formGroup.get('firstAuditorAnswer').value;
        classificationRiskAuditTriage.idIncorrectClassificationReasonFirstAuditor = element.formGroup.get('firstAuditorIdIncorrectClassificationReason').value;
        classificationRiskAuditTriage.observationFirstAuditor = element.formGroup.get('firstAuditorIncorrectObservation').value;
        classificationRiskAuditTriage.idCorrectClassificationReasonFirstAuditor = element.formGroup.get('firstAuditorIdCorrectClassificationReason').value;
        classificationRiskAuditTriage.correctObservationReasonFirstAuditor = element.formGroup.get('firstAuditorCorrectObservation').value;
        classificationRiskAuditTriage.isCorrectSecondAuditor = element.formGroup.get('secondAuditorAnswer').value;
        classificationRiskAuditTriage.idIncorrectClassificationReasonSecondAuditor = element.formGroup.get('secondAuditorIdIncorrectClassificationReason').value;
        classificationRiskAuditTriage.observationSecondAuditor = element.formGroup.get('secondAuditorIncorrectObservation').value;
        classificationRiskAuditTriage.idCorrectClassificationReasonSecondAuditor = element.formGroup.get('secondAuditorIdCorrectClassificationReason').value;
        classificationRiskAuditTriage.correctObservationReasonSecondAuditor = element.formGroup.get('secondAuditorCorrectObservation').value;

        let classificationRiskAuditOthers = new ClassificationRiskAuditOthers;
        classificationRiskAuditOthers.idClassificationRiskAudit = this.idAudit;
        classificationRiskAuditOthers.idClassificationRiskAuditTriage = element.classificationRiskAuditTriage.idClassificationRiskAuditTriage;
        classificationRiskAuditOthers.idIncorrectClassificationReason = element.formGroup.get('otherAuditorIdIncorrectClassificationReason').value;
        classificationRiskAuditOthers.observation = element.formGroup.get('otherAuditorIncorrectObservation').value;
        classificationRiskAuditOthers.idCorrectClassificationReason = element.formGroup.get('otherAuditorIdCorrectClassificationReason').value;
        classificationRiskAuditOthers.correctObservationReason = element.formGroup.get('otherAuditorCorrectObservation').value;
        classificationRiskAuditOthers.isCorrectAuditor = element.formGroup.get('otherAuditorAnswer').value;

        requestExecuteAudit.listClassificationRiskAuditTriage.push(classificationRiskAuditTriage);
        requestExecuteAudit.listClassificationRiskAuditOthers.push(classificationRiskAuditOthers);
      }
      else {
        element.formGroup.get('firstAuditorIncorrectObservation').markAsTouched();
        element.formGroup.get('firstAuditorIncorrectObservation').markAsDirty();
        element.formGroup.get('firstAuditorIdIncorrectClassificationReason').markAsTouched();
        element.formGroup.get('firstAuditorIdIncorrectClassificationReason').markAsDirty();
        element.formGroup.get('secondAuditorIncorrectObservation').markAsTouched();
        element.formGroup.get('secondAuditorIncorrectObservation').markAsDirty();
        element.formGroup.get('secondAuditorIdIncorrectClassificationReason').markAsTouched();
        element.formGroup.get('secondAuditorIdIncorrectClassificationReason').markAsDirty();
        element.formGroup.get('otherAuditorIncorrectObservation').markAsTouched();
        element.formGroup.get('otherAuditorIncorrectObservation').markAsDirty();
        element.formGroup.get('otherAuditorIdIncorrectClassificationReason').markAsTouched();
        element.formGroup.get('otherAuditorIdIncorrectClassificationReason').markAsDirty();
        this.submitInvalidateEventToAudtiTriage.next();
        isAuditTriageValid = false;
      }
    });

    if (!isAuditTriageValid) {
      this.throwException('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error)
      return;
    }

    this.update(requestExecuteAudit);
  }

  update(request: PutClassificationRiskAuditTriageRequest) {
    this.auditStatusService.updateAuditStatus(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException('Atenção', response.errorDescription, AlertType.warning)
          return;
        }

        this.isLoading = false;
        if (response.status == AuditStatusEnum.PrimeiroAuditando) {
          const dialogRef = this.dialog.open(ExecuteAuditWarningModalComponent, {
          });
          dialogRef.afterClosed().subscribe({
            next: result => {
              this.router.navigate(['/audit/audit']);
            },
            error: (error) => {
              console.log(error);
            }
          });
        }
        else if (response.status == AuditStatusEnum.PrimeiroAuditado)
          this.alertSave("Sua auditoria foi salva com sucesso e está disponível para um segundo auditor iniciar o processo.");
        else if (response.status == AuditStatusEnum.SegundoAuditando) {
          const dialogRef = this.dialog.open(ExecuteAuditWarningModalComponent, {
          });
          dialogRef.afterClosed().subscribe({
            next: result => {
              this.router.navigate(['/audit/audit']);
            },
            error: (error) => {
              console.log(error);
            }
          });
        }
        else if (response.status == AuditStatusEnum.Concluido)
          this.alertSave("Sua auditoria foi salva e concluída sem divergência.");
        else if (response.status == AuditStatusEnum.SegundoAuditado)
          this.alertSave("Sua auditoria foi salva e o status foi para Segundo Auditado sem divergência.");
        else if (response.status == AuditStatusEnum.Auditado)
          this.alertSave("Sua auditoria foi salva com sucesso.");
        else if (response.status == AuditStatusEnum.DivergênciaResolvida)
          this.alertSave("Sua auditoria foi salva com sucesso e não há mais divergência(s).");
        else if (response.status == AuditStatusEnum.Auditando) {
          const dialogRef = this.dialog.open(ExecuteAuditWarningModalComponent, {
          });
          dialogRef.afterClosed().subscribe({
            next: result => {
              this.router.navigate(['/audit/audit']);
            },
            error: (error) => {
              console.log(error);
            }
          });
        }
        else if (response.status == AuditStatusEnum.OpiniãoAguardando)
          this.alertSave("Sua auditoria foi salva com sucesso, porém, há divergência(s) entre episódio(s).");
        else
          this.alertSave("Sua auditoria foi salva com sucesso, porém, há divergência(s) entre episódio(s).");
      },
      error: (error) => {
        this.throwException('Erro inesperado', error, AlertType.error);
      }
    });
  }

  public get auditStatusEnum(): typeof AuditStatusEnum {
    return AuditStatusEnum;
  }

  startWebsocket(idHealthUnit: number) {
    if (!this.websocketAuditUtilService.isConnect())
      this.websocketAuditUtilService.connectwebsocketRoom(idHealthUnit, this, this.idAudit, this.authService.getTokenMenu().login);

    this.websocketAuditUtilService.setThisParam(this);
    this.websocketAuditUtilService.setVariables(this.idAudit, this.authService.getTokenMenu().login, idHealthUnit);
    this.isStartWebsocket = true;
  }

  throwException(alertTypeDescription: string, error: string, alertType: AlertType) {
    this.alertService.show(alertTypeDescription, error, alertType.valueOf());
    this.isLoading = false;
    console.log(error);
  }

  alertSave(message: string) {
    this.alertService.show('Sucesso', message, AlertType.success);
    this.router.navigate(['/audit/audit']);
  }

  ngOnDestroy() {
    if (this.isStartWebsocket)
      this.websocketAuditUtilService.clearVariables();
  }
}