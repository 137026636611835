<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-profile-modal-list">
            <h2>Você deseja confirmar a liberação do Leito?</h2>
        </div>

        <div class="body-modal-list text-div">
            O paciente será liberado o que você deseja fazer com o leito dele?
        </div>
        <div class="footer-modal-list">
            <button mat-flat-button color="primary" class="btn-block" (click)="clickConfirm(true)">
                <span *ngIf="isLoading == false">Remover do leito</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
            <button mat-flat-button color="primary" class="btn-block" (click)="clickConfirm(false)">
                <span *ngIf="isLoading == false">Manter o leito ocupado com o atendimento dele</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
            <button mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Cancelar</button>
        </div>
    </div>
</div>