export class FowardRequest{
    public idEpisode: number;
    
    public idRoom: number;
    
    public birthDate: Date;

    public idFlowchart: number;

    public idPriorityColor: number;

    public isBedManagement: boolean;
}