<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <h1>Relatório de Guias</h1>
        <div class="white-body">
            <div class="filters">
                <div class="row">
                    <div class="col-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Período</mat-label>
                            <mat-date-range-input [rangePicker]="picker">
                                <input matStartDate [(ngModel)]="startOfAttendance" placeholder="Início">
                                <input matEndDate [(ngModel)]="endOfAttendance" placeholder="Fim">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker></mat-date-range-picker>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Episódio</mat-label>
                            <input matInput type="number" id="idEpisode" [(ngModel)]="idEpisode" inputmode="numeric"
                                min="0">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Tipo de guia</mat-label>
                            <mat-select [(ngModel)]="idGuideType">
                                <mat-option [value]="null">Nenhum</mat-option>
                                <mat-option *ngFor="let item of listGuideType" [value]="item.idGuideType">
                                    {{item.guideName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Status da guia</mat-label>
                            <mat-select [(ngModel)]="idGuideStatus">
                                <mat-option [value]="null">Nenhum</mat-option>
                                <mat-option *ngFor="let item of listGuideStatus" [value]="item.idGuideStatus">
                                    <span class="color-dot" [style.background-color]="item.color"></span>
                                    {{item.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Convênio</mat-label>
                            <mat-select [(ngModel)]="idHealthcareAgreement">
                                <mat-option [value]="null">Nenhum</mat-option>
                                <mat-option *ngFor="let item of listHealthcareAgreementStruct"
                                    [value]="item.idHealthcareAgreement">
                                    {{item.ansWithName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <!-- <div class="col-6 col-sm-3 col-md-4">
                        <app-loading-progress-bar *ngIf="isUpdateListProfessional"></app-loading-progress-bar>
                        <mat-form-field appearance="outline">
                            <mat-label matTooltip="Profissional executante">Profissional executante</mat-label>
                            <mat-select [(ngModel)]="idProfessionalAgreement"
                                [disabled]="listProfessionalAgreementStruct && listProfessionalAgreementStruct.length == 0">
                                <mat-option [value]="null">Nenhum</mat-option>
                                <mat-option *ngFor="let item of listProfessionalAgreementStruct"
                                    [value]="item.idProfessionalAgreement">
                                    {{item.nameProfessional}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div> -->
                </div>
                <div class="col-12 col-md-6 button-filter-clean">
                    <div class="button-filter col-3">
                        <button mat-flat-button color="primary" class="btn-block organize-button"
                            (click)="search()">Filtrar
                            <mat-icon>filter_alt</mat-icon>
                        </button>
                    </div>
                    <div class="button-clean col-3">
                        <button type="button" mat-flat-button color="accent" class="btn-block organize-button"
                            (click)="cancel()">Cancelar
                            <mat-icon>cleaning_services</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-12 padding-box">
                <div class="row border-box">
                    <app-loading-progress-bar *ngIf="isLoading"></app-loading-progress-bar>
                    <table class="col-12">
                        <thead>
                            <tr>
                                <th class="column-episode">Episódio</th>
                                <th class="column-date">Data Atendimento</th>
                                <th class="column-patient">Paciente</th>
                                <th class="column-healthcare">Convênio</th>
                                <th class="column-professional">Profissional Executante</th>
                                <th class="column-guide-type">Tipo Guia</th>
                                <th class="column-guide-number">Nº Guia na Operadora</th>
                                <th class="column-status">Status</th>
                                <th class="column-action">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let tissGuideReport of listTissGuideReportStruct">
                                <td>{{ tissGuideReport.idEpisode }}</td>
                                <td>{{ tissGuideReport.datetimeInclusion | date: 'dd/MM/yyy' }}</td>

                                <td class="text-description" matTooltip="{{tissGuideReport.beneficiaryName}}">
                                    {{ tissGuideReport.beneficiaryName }}</td>

                                <td class="text-align"><span class="format-text">{{
                                        tissGuideReport.nameHealthcareAgreement }}</span></td>

                                <td class="text-description"
                                    matTooltip="{{tissGuideReport.listNameExecutantProfessional}}">
                                    {{ tissGuideReport.nameExecutantProfessional }}</td>

                                <td>{{ tissGuideReport.guideTypeName }}</td>
                                <td class="text-align"><span>{{ tissGuideReport.guideNumber }}</span></td>
                                <td class="text-align">
                                    <span class="format-text text-description"
                                        matTooltip="{{tissGuideReport.guideStatusName}}"
                                        [ngStyle]="{'background-color': tissGuideReport.guideStatusColor}">
                                        {{ tissGuideReport.guideStatusName }}
                                    </span>
                                </td>
                                <td>
                                    <a class="action-icon">
                                        <app-loading-list *ngIf="isLoadingEdit"></app-loading-list>
                                        <mat-icon *ngIf="!isLoadingEdit" aria-hidden="false" aria-label="Editar"
                                            (click)="selectGuide(tissGuideReport);">
                                            edit
                                        </mat-icon>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <app-loading-list *ngIf="isLoading"></app-loading-list>
                </div>
            </div>
            <app-empty-list
                *ngIf="listTissGuideReportStruct && listTissGuideReportStruct.length == 0 && !isLoading"></app-empty-list>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-paginator [length]="fullListSize" #paginator [pageIndex]="pageIndex" [pageSize]="pageSize"
                        aria-label="Select page" (page)="changePage($event)" [hidePageSize]="true">
                    </mat-paginator>
                </div>
            </div>
        </div>
    </div>
</div>