export class ExternalPrescriptionStruct {
    public idExternalPrescription: number;
    public idMedicalCare: number;
    public description: string;
    public quantity: string;
    public frequency: string;
    public observation: string;
    public datetimeInclusion: Date;
    public idMeasurementUnit: number;
    public measurementUnitName: string;
}