export class BillingDataRequest{
    public listIdProcedure: number[];
    
    public listCid: string[];
    
    public idModule: number;
    
    public idRelation: number;
    
    public idEpisode: number;
}