<div class="display-contents padding-box">
    <div class="form-field-toggle use-tiss-toggle">
        <mat-label>Utilizar faturamento TISS neste convênio</mat-label>
        <mat-slide-toggle [(ngModel)]="useTissBilling" (change)="updateStatusTissBilling()"></mat-slide-toggle>
    </div>

    <ng-container *ngIf="useTissBilling">
        <app-loading-progress-bar *ngIf="isLoading"></app-loading-progress-bar>
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="title-header">
                <h1>Configurações dos prestadores</h1>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Chave de identificação do prestador</mat-label>
                        <mat-select formControlName="idProviderIdentityType"
                            (selectionChange)="onChangeProviderType($event.value)">
                            <mat-option>Nenhum</mat-option>
                            <mat-option *ngFor="let item of listProviderIdentityType"
                                [value]="item.idProviderIdentityType">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>9 - Código do contratado executante na operadora</mat-label>
                        <input matInput type="text" formControlName="providerCode" [maxlength]="providerCodeMaxLength"
                            [mask]="providerCodeMask">
                        <mat-error
                            *ngIf="model.get('providerCode').invalid && !model.get('providerCode').errors?.maxlength">Preenchimento
                            incorreto</mat-error>
                        <mat-error *ngIf="model.get('providerCode').errors?.maxlength">Tamanho máximo do campo
                            excedido</mat-error>
                        <mat-hint align="end">{{ model.get('providerCode').value ?
                            model.get('providerCode').value.length : 0 }}/{{ providerCodeLength }}</mat-hint>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>10 - Nome do contratado executante</mat-label>
                        <input matInput type="text" formControlName="providerName" [maxlength]="providerNameMaxLength">
                        <mat-error
                            *ngIf="model.get('providerName').invalid && !model.get('providerName').errors?.maxlength">Preenchimento
                            incorreto</mat-error>
                        <mat-error *ngIf="model.get('providerName').errors?.maxlength">Tamanho máximo do campo
                            excedido</mat-error>
                        <mat-hint align="end">{{ model.get('providerName').value ?
                            model.get('providerName').value.length : 0 }}/70</mat-hint>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>11 - CNES</mat-label>
                        <input matInput type="text" formControlName="cnes" [mask]="masks.cnes">
                    </mat-form-field>
                </div>
            </div>

            <div class="title-header">
                <h1>Configurações gerais de preenchimento - SP/SADT</h1>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>21 - Caráter de atendimento</mat-label>
                        <mat-select formControlName="idAttendanceAspect">
                            <mat-option>Nenhum</mat-option>
                            <mat-option *ngFor="let item of listAttendanceAspect" [value]="item.idAttendanceAspect">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>32 - Tipo de atendimento</mat-label>
                        <mat-select formControlName="idAttendanceType">
                            <mat-option>Nenhum</mat-option>
                            <mat-option *ngFor="let item of listAttendanceType" [value]="item.idAttendanceType">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>33 - Indicação de acidente</mat-label>
                        <mat-select formControlName="idAccidentIndication">
                            <mat-option>Nenhum</mat-option>
                            <mat-option *ngFor="let item of listAccidentIndication" [value]="item.idAccidentIndication">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>34 - Tipo de consulta</mat-label>
                        <mat-select formControlName="idAppointmentType">
                            <mat-option>Nenhum</mat-option>
                            <mat-option *ngFor="let item of listAppointmentType" [value]="item.idAppointmentType">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>


            <div class="title-header">
                <h1>Casos especiais</h1>
            </div>

            <div class="row">
                <div class="col-12 col-sm-6 col-md-4 special-cases-box">
                    <h3>Honorários</h3>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>% Bilateral</mat-label>
                                <input matInput type="text" formControlName="honoraryBilateral" (input)="inputNumberMax999('honoraryBilateral')">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>% Mesma via</mat-label>
                                <input matInput type="text" formControlName="honorarySameGuide" (input)="inputNumberMax999('honorarySameGuide')">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>% Via diferente</mat-label>
                                <input matInput type="text" formControlName="honoraryDifferentGuide" (input)="inputNumberMax999('honoraryDifferentGuide')">
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-sm-6 col-md-4 special-cases-box">
                    <h3>Custo operacional</h3>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>% Bilateral</mat-label>
                                <input matInput type="text" formControlName="operationalCostBilateral" (input)="inputNumberMax999('operationalCostBilateral')">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>% Mesma via</mat-label>
                                <input matInput type="text" formControlName="operationalCostSameGuide" (input)="inputNumberMax999('operationalCostSameGuide')">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>% Via diferente</mat-label>
                                <input matInput type="text" formControlName="operationalCostDifferentGuide" (input)="inputNumberMax999('operationalCostDifferentGuide')">
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-sm-6 col-md-4 special-cases-box">
                    <h3>M² Filme</h3>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>% Bilateral</mat-label>
                                <input matInput type="text" formControlName="m2FilmBilateral" (input)="inputNumberMax999('m2FilmBilateral')">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>% Mesma via</mat-label>
                                <input matInput type="text" formControlName="m2FilmSameGuide" (input)="inputNumberMax999('m2FilmSameGuide')">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>% Via diferente</mat-label>
                                <input matInput type="text" formControlName="m2FilmDifferentGuide" (input)="inputNumberMax999('m2FilmDifferentGuide')">
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-sm-6 col-md-4 special-cases-boxW">
                    <h3>Outros</h3>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>% Urgência</mat-label>
                                <input matInput type="text" formControlName="otherUrgency" (input)="inputNumberMax999('otherUrgency')">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>% D.H.E</mat-label>
                                <input matInput type="text" formControlName="otherDhe" (input)="inputNumberMax999('otherDhe')">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>% Apartamento</mat-label>
                                <input matInput type="text" formControlName="otherApartment" (input)="inputNumberMax999('otherApartment')">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="title-header">
                <h1>Distribuição de participação</h1>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>00 - Cirurgião</mat-label>
                        <input matInput type="text" formControlName="participationSurgeon" (input)="inputNumberMax999('participationSurgeon')">
                        <span matSuffix>%</span>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>01 - Primeiro Auxiliar</mat-label>
                        <input matInput type="text" formControlName="participationFirstAux" (input)="inputNumberMax999('participationFirstAux')">
                        <span matSuffix>%</span>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>02 - Segundo Auxiliar</mat-label>
                        <input matInput type="text" formControlName="participationSecondAux" (input)="inputNumberMax999('participationSecondAux')">
                        <span matSuffix>%</span>
                    </mat-form-field>
                </div>

                <div class="col-12 col-sm-6 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>03 - Terceiro Auxiliar</mat-label>
                        <input matInput type="text" formControlName="participationThirdAux" (input)="inputNumberMax999('participationThirdAux')">
                        <span matSuffix>%</span>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>04 - Quarto Auxiliar</mat-label>
                        <input matInput type="text" formControlName="participationForthAux" (input)="inputNumberMax999('participationForthAux')">
                        <span matSuffix>%</span>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>05 - Instrumentador</mat-label>
                        <input matInput type="text" formControlName="participationScrubNurse" (input)="inputNumberMax999('participationScrubNurse')">
                        <span matSuffix>%</span>
                    </mat-form-field>
                </div>


                <div class="col-12 col-sm-6 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>06 - Anestesista</mat-label>
                        <input matInput type="text" formControlName="participationAnesthetist" (input)="inputNumberMax999('participationAnesthetist')">
                        <span matSuffix>%</span>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>07 - Auxiliar de anestesista</mat-label>
                        <input matInput type="text" formControlName="participationAnesthetistAuxiliar" (input)="inputNumberMax999('participationAnesthetistAuxiliar')">
                        <span matSuffix>%</span>
                    </mat-form-field>
                </div>

                <div class="col-12 col-sm-6 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>08 - Consultor</mat-label>
                        <input matInput type="text" formControlName="participationConsultant" (input)="inputNumberMax999('participationConsultant')">
                        <span matSuffix>%</span>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>09 - Perfusionista</mat-label>
                        <input matInput type="text" formControlName="participationPerfusionist" (input)="inputNumberMax999('participationPerfusionist')">
                        <span matSuffix>%</span>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>10 - Pediatra na sala de parto</mat-label>
                        <input matInput type="text" formControlName="participationBirthRoomPediatrician" (input)="inputNumberMax999('participationBirthRoomPediatrician')">
                        <span matSuffix>%</span>
                    </mat-form-field>
                </div>

                <div class="col-12 col-sm-6 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>11 - Auxiliar SADT</mat-label>
                        <input matInput type="text" formControlName="participationSadtAuxiliar" (input)="inputNumberMax999('participationSadtAuxiliar')">
                        <span matSuffix>%</span>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>12 - Clínico</mat-label>
                        <input matInput type="text" formControlName="participationClinic" (input)="inputNumberMax999('participationClinic')">
                        <span matSuffix>%</span>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>13 - Intensivista</mat-label>
                        <input matInput type="text" formControlName="participationIntensivist" (input)="inputNumberMax999('participationIntensivist')">
                        <span matSuffix>%</span>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <a mat-flat-button type="button" (click)="cancel()" color="accent" class=" btn-block">
                        Cancelar
                    </a>
                </div>
                <div class="col-md-2">
                    <button mat-flat-button type="submit" color="primary" class=" btn-block">
                        <span *ngIf="isLoading == false">Salvar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
            </div>
        </form>
    </ng-container>
</div>