import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MoreSettingsAgreementService } from 'src/app/shared/services/API/private-billing/more-settings-agreement.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { AdmissionRequiredField } from 'src/app/shared/services/models/private-billing/admission-required-field.model';
import { AppointmentRequiredField } from 'src/app/shared/services/models/private-billing/appointment-required-field.model';
import { FeesRequiredField } from 'src/app/shared/services/models/private-billing/fees-required-field.model';
import { LookupAdmissionRequiredField } from 'src/app/shared/services/models/private-billing/lookup-admission-required-field.model';
import { LookupAppointmentRequiredField } from 'src/app/shared/services/models/private-billing/lookup-appointment-required-field.model';
import { LookupFeesRequiredField } from 'src/app/shared/services/models/private-billing/lookup-fees-required-field.model';
import { LookupSpSadtRequiredField } from 'src/app/shared/services/models/private-billing/lookup-sp-sadt-required-field.model';
import { SpSadtRequiredField } from 'src/app/shared/services/models/private-billing/sp-sadt-required-field.model';
import { MoreSettingsAgreementRequest } from 'src/app/shared/services/requests/private-billing/more-settings-agreement.request';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-more-settings',
  templateUrl: './more-settings.component.html',
  styleUrls: ['./more-settings.component.css']
})
export class MoreSettingsComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private moreSettingsAgreementService: MoreSettingsAgreementService,
    private alertService: AlertService,
    private dialog: MatDialog
  ) { }

  public model: FormGroup;

  @Output() changeIndex = new EventEmitter();

  @Input() listLookupAdmissionRequiredField: LookupAdmissionRequiredField[] = [];
  @Input() listLookupAppointmentRequiredField: LookupAppointmentRequiredField[] = [];
  @Input() listLookupFeesRequiredField: LookupFeesRequiredField[] = [];
  @Input() listLookupSpSadtRequiredField: LookupSpSadtRequiredField[] = [];
  @Input() listFeesRequiredField: FeesRequiredField[] = [];
  @Input() listAdmissionRequiredField: AdmissionRequiredField[] = [];
  @Input() listAppointmentRequiredField: AppointmentRequiredField[] = [];
  @Input() listSpSadtRequiredField: SpSadtRequiredField[] = [];
  @Input() idHealthcareAgreement: number;

  public isLoading: boolean = false;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      idLookupAppointmentRequiredField: [[], []],
      idLookupSpSadtRequiredField: [[], []],
      idLookupFeesRequiredField: [[], []],
      idLookupAdmissionRequiredField: [[], []]
    });

    if (this.listSpSadtRequiredField.length > 0 ||
      this.listAppointmentRequiredField.length > 0 ||
      this.listAdmissionRequiredField.length > 0 ||
      this.listFeesRequiredField.length > 0)
      this.mapperData();
  }

  submit() {
    if (this.isLoading)
      return;

    this.isLoading = true;
    let request: MoreSettingsAgreementRequest = {
      idHealthcareAgreement: this.idHealthcareAgreement,
      listIdLookupAppointmentRequiredField: this.model.get('idLookupAppointmentRequiredField').value,
      listIdLookupAdmissionRequiredField: this.model.get('idLookupAdmissionRequiredField').value,
      listIdLookupSpSadtRequiredField: this.model.get('idLookupSpSadtRequiredField').value,
      listIdLookupFeesRequiredField: this.model.get('idLookupFeesRequiredField').value,
    }

    this.moreSettingsAgreementService.PostPut(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.alertSave("Salvamento concluído com sucesso!");
        this.isLoading = false;
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  mapperData() {
    if (this.listFeesRequiredField.length > 0)
      this.model.get('idLookupFeesRequiredField').setValue(this.listFeesRequiredField.map(x => x.idLookupFeesRequiredField));

    if (this.listSpSadtRequiredField.length > 0)
      this.model.get('idLookupSpSadtRequiredField').setValue(this.listSpSadtRequiredField.map(x => x.idLookupSpSadtRequiredField));

    if (this.listAdmissionRequiredField.length > 0)
      this.model.get('idLookupAdmissionRequiredField').setValue(this.listAdmissionRequiredField.map(x => x.idLookupAdmissionRequiredField));

    if (this.listAppointmentRequiredField.length > 0)
      this.model.get('idLookupAppointmentRequiredField').setValue(this.listAppointmentRequiredField.map(x => x.idLookupAppointmentRequiredField));
  }

  private throwException(error: string) {
    console.log(error);
    this.alertService.show('Erro inesperado', error, AlertType.error);
    this.isLoading = false;
  }

  onCancelClick() {
    let reportDialog = this.dialog.open(ConfirmModalComponent, {
      data: {
        title: "Aviso!",
        message: "Você realmente deseja cancelar? Qualquer alteração realizada pode não ter sido salva.",
        btnPrimary: "Sim, cancelar",
        btnAccent: "Não, continuar nesta aba"
      },
    });
    reportDialog.afterClosed().subscribe(result => {
      if (result && result.clickPrimary) {
        this.changeIndex.emit(0);
      }
    });
  }

  private alertSave(text: string) {
    this.alertService.show('Sucesso', text, AlertType.success);
    this.isLoading = false;
  }
}