import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WebBluetoothModule } from '@manekinekko/angular-web-bluetooth';


const routes: Routes = [
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
  }),
  WebBluetoothModule.forRoot({
    enableTracing: true // or false, this will enable logs in the browser's console
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
