import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-patient-not-found',
  templateUrl: './patient-not-found.component.html',
  styleUrls: ['./patient-not-found.component.css']
})
export class PatientNotFoundComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<PatientNotFoundComponent>) { }

  public isLoading:boolean;  

  ngOnInit(): void {
    this.isLoading = false;
    setTimeout(() => {this.close();}, 10000);
  }
  
  close(){
    this.matDialogRef.close(); 
  }

}
