import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserMemedService } from 'src/app/shared/services/API/orchestrator-patient/user-memed.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MemedMedicine } from 'src/app/shared/services/models/medical-record/memed-medicine.model';
import { MemedPrescriptionStruct } from 'src/app/shared/services/structs/medical-record/memed-prescription.struct';
import { EpisodeStruct } from 'src/app/shared/services/structs/orchestrator-patient/episode.struct';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-memed-prescriptions',
  templateUrl: './memed-prescriptions.component.html',
  styleUrls: ['./memed-prescriptions.component.css']
})
export class MemedPrescriptionsComponent implements OnInit {

  constructor(
    public dialog: MatDialog, 
    private renderer2: Renderer2,
    @Inject (DOCUMENT) private document: Document,
    private alertService: AlertService,
    private userMemedService: UserMemedService,
  ) { }

  @Input() episodeStruct: EpisodeStruct;
  @Input() cpfPatient: string;
  @Input() userToken: string;
  @Input() patientPhoneNumber: string;
  @Output() newPrescription = new EventEmitter();

  public memedPrescriptionStruct: MemedPrescriptionStruct;
  
  public isFirstLoading: boolean = true;
  public urlScriptMemed: string = environment.urlScriptMemed;

  ngOnInit(): void {
    this.initMemed();
  }

  initMemed() {

    let script = this.renderer2.createElement('script');
    script.id = 'memed';
    script.src = this.urlScriptMemed;
    script.dataset.token = this.userToken;

    script.dataset.container = "memed-prescription";
    script.dataset.color = "#85C443";

    script.addEventListener("load", () => {
      
      if(!('MdSinapsePrescricao' in window)) {
        throw Error('MdSinapsePrescricao was not initialized.');
      }

      (window['MdSinapsePrescricao'] as any).event.add("core:moduleInit", async (module) => {
        if(!('MdHub' in window)) {
          throw Error('MdHub was not initialized.');
        }
        if(module.name === "plataforma.prescricao") {
          await (window['MdHub'] as any).command.send('plataforma.prescricao', 'setFeatureToggle', {
            deletePatient: false,
            removePatient: false,
            editPatient: false,
            historyPrescription: false,
            optionsPrescription: false,
            removePrescription: false,
            setPatientAllergy: false,
            autocompleteIndustrialized: true,
            autocompleteManipulated: true,
            autocompleteCompositions: true,
            autocompletePeripherals: true,
            copyMedicalRecords: false,
            buttonClose: false,
            newFormula: true,
            allowShareModal: false,
            guidesOnboarding: false,
            conclusionModalEdit: true,
            dropdownSync: false,
            showProtocol: true,
            showHelpMenu: true,
            editIdentification: false,
          });
          
          let userBirthDate = new Date(this.episodeStruct.birthDate);
          let userBirthDateFormated = userBirthDate.toLocaleDateString('pt-BR');

          await (window['MdHub'] as any).command.send("plataforma.prescricao", "setAdditionalData", {
            header: [
              {
                "Data de Nasc.": userBirthDateFormated,
              },
            ],
          });

          let patientAddres: string = "";
          patientAddres = this.episodeStruct.patientStreet ? this.episodeStruct.patientStreet : "";
          if(this.episodeStruct.patientHouseNumber != null && this.episodeStruct.patientHouseNumber != undefined)
            patientAddres = patientAddres + ", n° " + this.episodeStruct.patientHouseNumber.toString();
          await (window['MdHub'] as any).command.send("plataforma.prescricao", "setPaciente", {
            idExterno: this.episodeStruct.idPatient + this.cpfPatient,
            nome: this.episodeStruct.patientName,
            telefone: this.patientPhoneNumber,
            cpf: this.cpfPatient,
            nome_social: this.episodeStruct.patientSocialName ? this.episodeStruct.patientSocialName : "",
            data_nascimento: userBirthDateFormated.toString(),
            endereco: patientAddres,
            cidade: this.episodeStruct.patientCity ? this.episodeStruct.patientCity : "",
            numero: this.episodeStruct.patientHouseNumber ? this.episodeStruct.patientHouseNumber : "",
          });

          (window['MdHub'] as any).event.add('prescricaoImpressa', (prescriptionData) => {
            this.memedPrescriptionStruct = new MemedPrescriptionStruct();
            this.memedPrescriptionStruct.idMemedPrescription = prescriptionData.prescricao.id;
            this.memedPrescriptionStruct.listMemedMedicine = (prescriptionData.prescricao.medicamentos) ? this.mapMemedMedicine(prescriptionData.prescricao.medicamentos) : [];
            this.newPrescription.emit({memedPrescriptionStruct: this.memedPrescriptionStruct, completePrescriptionStruct: prescriptionData});
          });
          
          await (window['MdHub'] as any).module.show("plataforma.prescricao");

          this.isFirstLoading = false;
        }
      });
    });
    this.renderer2.appendChild(this.document.body, script);
  }

  mapMemedMedicine(medicine: any[]): MemedMedicine[] {
    let listMemedMap: MemedMedicine[] = [];
    medicine.forEach(item => {
      let newMemedMap = new MemedMedicine();

      newMemedMap.idMemed = item.id;
      newMemedMap.name = item.nome;
      newMemedMap.description = item.descricao;
      newMemedMap.quantity = item.quantidade;
      newMemedMap.unit = item.unit;
      newMemedMap.controleEspecial = item.controle_especial;
      newMemedMap.altoCusto = item.alto_custo;
      newMemedMap.anamnese = item.anamnese;
      newMemedMap.dataSolicitacao = item.data_solicitacao;
      newMemedMap.receituario = item.receituario;
      newMemedMap.tipoExameSelecionado = item.tipo_exame_selecionado;
      newMemedMap.tarja = item.tarja;
      newMemedMap.tipo = item.tipo;
      newMemedMap.sanitizedPosology = item.sanitized_posology;
      newMemedMap.via = item.via;
      newMemedMap.origem = item.origem;
      newMemedMap.subtitulo = item.subtitulo;
      newMemedMap.titulo = item.titulo;

      listMemedMap.push(newMemedMap);
    });
    return listMemedMap;
  }
}

