import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertModalComponent } from 'src/app/shared/components/alert-modal/alert-modal.component';
import { TypePeriodEnum } from 'src/app/shared/enum/private-billing/type-period.enum';
import { ReturnRestrictionsService } from 'src/app/shared/services/API/private-billing/return-restrictions.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Procedure } from 'src/app/shared/services/models/private-billing/procedure.model';
import { TypePeriod } from 'src/app/shared/services/models/private-billing/type-period.model';
import { ReturnRestrictionsRequest } from 'src/app/shared/services/requests/private-billing/return-restrictions.request';
import { ReturnRestrictionsResponse } from 'src/app/shared/services/responses/private-billing/return-restrictions.response';
import { ReturnRestrictionsStruct } from 'src/app/shared/services/structs/private-billing/return-restrictions.struct';

@Component({
  selector: 'app-return-restrictions-modal',
  templateUrl: './return-restrictions-modal.component.html',
  styleUrls: ['./return-restrictions-modal.component.css']
})
export class ReturnRestrictionsModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ReturnRestrictionsModalComponent>,
    private formBuilder: FormBuilder,
    private returnRestrictionsService: ReturnRestrictionsService,
    private alertService: AlertService,
  ) { this.matDialogRef.disableClose = true; }

  public model: FormGroup;

  public listTypePeriod: TypePeriod[] = this.data.listTypePeriod;
  public listProcedure: Procedure[] = this.data.listProcedure;
  public isLoading: boolean = false;
  public isFirstLoading: boolean = true;
  public nameHealthcareAgreement: string = this.data.nameHealthcareAgreement;
  public idHealthcareAgreement: number = this.data.idHealthcareAgreement;
  public returnRestrictions: ReturnRestrictionsStruct = this.data.returnRestrictions ?? null;
  public selectedOption: TypePeriod | null = null;
  public day: number = TypePeriodEnum.Dia;
  public month: number = TypePeriodEnum.Mensal;
  public errorCodeIsAlreadyARestriction: number = 1;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      number: [, [Validators.max(31)]],
      returnWithSameProfessional: [false],
      idTypePeriod: [this.day, [Validators.required]],
      idProcedure: [, [Validators.required]]
    });

    if (this.returnRestrictions != null) {
      this.model.get('idTypePeriod').setValue(this.returnRestrictions.idTypePeriod);
      this.model.get('number').setValue(this.returnRestrictions.number);
      this.model.get('returnWithSameProfessional').setValue(this.returnRestrictions.returnWithSameProfessional);
      this.model.get('idProcedure').setValue(this.returnRestrictions.idProcedure);
      this.getNumberValidators(this.model.get('idTypePeriod').value);
    }
    else
      this.getNumberValidators(this.model.get('idTypePeriod').value);

    this.isFirstLoading = false;
  }

  submit() {
    this.isLoading = true;
    if (this.model.invalid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      this.isLoading = false;
      return;
    }

    let request: ReturnRestrictionsRequest = new ReturnRestrictionsRequest();
    request.idHealthcareAgreement = this.idHealthcareAgreement;
    request.idTypePeriod = this.model.get('idTypePeriod').value;
    request.idProcedure = this.model.get('idProcedure').value;
    request.returnWithSameProfessional = this.model.get('returnWithSameProfessional').value;
    request.number = this.model.get('number').value;

    if (this.returnRestrictions == null)
      this.post(request);
    else
      this.put(request);
  }

  post(request: ReturnRestrictionsRequest) {
    this.isLoading = true;
    this.returnRestrictionsService.Post(request).subscribe({
      next: (response) => {
        if (response.isError && response.errorCode == this.errorCodeIsAlreadyARestriction) {
          this.openAlertModal();
          return;
        }
        else if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.mapperResponse(response, "Restrição de retorno atualizada com sucesso!");
        this.isLoading = false;
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  put(request: ReturnRestrictionsRequest) {
    this.isLoading = true;

    request.idProcedureHealthcare = this.returnRestrictions.idProcedureHealthcare;
    this.returnRestrictionsService.Put(request, this.returnRestrictions.idReturnRestrictions).subscribe({
      next: (response) => {
        if (response.isError && response.errorCode == this.errorCodeIsAlreadyARestriction) {
          this.openAlertModal();
          return;
        }
        else if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.mapperResponse(response, "Restrição de retorno atualizada com sucesso!");
        this.isLoading = false;
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  clickCancel() {
    this.matDialogRef.close({ sucess: false });
  }

  getNumberValidators(event: any) {
    if (event == this.day) {
      this.model.get('number').setValidators([Validators.required, Validators.max(31)]);
      this.model.get('number').enable();
    }
    else {
      this.model.get('number').setValidators([]);
      this.model.get('number').setValue(null);
      this.model.get('number').disable();
    }

    this.model.get('number').updateValueAndValidity();
  }

  openAlertModal() {
    const dialogRef = this.dialog.open(AlertModalComponent, {
      data: {
        isTwoButtonsModal: false,
        title: 'Atenção!',
        description: 'Não é possível restringir o mesmo procedimento duas vezes.',
        hasImage: true
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        this.isLoading = false;
      }
    });
  }

  mapperResponse(response: ReturnRestrictionsResponse, text: string) {
    let returnRestrictions: ReturnRestrictionsStruct = new ReturnRestrictionsStruct();
    returnRestrictions.idReturnRestrictions = response.returnRestrictions.idReturnRestrictions;
    returnRestrictions.idProcedureHealthcare = response.returnRestrictions.idProcedureHealthcare;
    returnRestrictions.idProcedure = this.model.get('idProcedure').value;
    returnRestrictions.number = response.returnRestrictions.number;
    returnRestrictions.idTypePeriod = response.returnRestrictions.idTypePeriod;

    returnRestrictions.periodName = this.listTypePeriod.find(x => x.idTypePeriod == this.model.get('idTypePeriod').value).periodName;
    returnRestrictions.nameProcedure = this.listProcedure.find(x => x.idProcedure == this.model.get('idProcedure').value).nameProcedure;
    returnRestrictions.returnWithSameProfessional = response.returnRestrictions.returnWithSameProfessional;
    returnRestrictions.isDeleted = response.returnRestrictions.isDeleted;
    
    this.alertSave(text, returnRestrictions);
  }

  private alertSave(text: string, returnRestrictions: ReturnRestrictionsStruct) {
    this.alertService.show('Sucesso', text, AlertType.success);
    this.isLoading = false;
    this.matDialogRef.close({ success: true, returnRestrictions: returnRestrictions });
  }

  private throwException(error: string) {
    console.log(error);
    this.alertService.show('Erro inesperado', error, AlertType.error);
    this.isLoading = false;
  }
}