<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div *ngIf="listMedicalPrescriptionCheck">
            <div class="head-profile-modal-list">
                <h2>Histórico de dispensação:</h2>
            </div>
            <div class="row" *ngFor="let item of listMedicalPrescriptionCheck">
                <div class="col-md-12">
                    <label *ngIf="item.datetimeCheckFulfilled">
                        Checado
                    </label>
                    <label *ngIf="!item.datetimeCheckFulfilled">
                        Pendente
                    </label>
                    <p *ngIf="item.medicalPrescriptionStatus == 2" class="early-green">{{item.datetimeCheckEstimative | date:'dd/MM/yyyy HH:mm'}}</p>
                    <p *ngIf="item.medicalPrescriptionStatus == 1" class="near-yellow">{{item.datetimeCheckEstimative | date:'dd/MM/yyyy HH:mm'}}</p>
                    <p *ngIf="item.medicalPrescriptionStatus == 3" class="late-red">{{item.datetimeCheckEstimative | date:'dd/MM/yyyy HH:mm'}}</p>
                </div>
            </div>
        </div>
        <div class="footer-modal-list">               
            <button mat-flat-button type="button" color="accent" class="btn-block"
                (click)="close()"><span>Voltar</span></button>
        </div>
    </div>
</div>