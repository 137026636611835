<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'
        hasSecundaryHeader="true" [episode]="idEpisode" [hasCachedModule]="true" [cachedModuleName]="'sadt'">
    </app-menu>
    <div class="container-body-secundary-header">
        <h1>Pedidos de Exame</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <cdk-accordion class="profile-register-accordion">
                <cdk-accordion-item *ngFor="let sadt of listSADT; let index = index;"
                    #accordionItemModule="cdkAccordionItem" class="profile-register-accordion-item" role="button"
                    tabindex="0" [attr.id]="'accordion-header-' + index"
                    [attr.aria-expanded]="accordionItemModule.expanded"
                    [attr.aria-controls]="'accordion-body-' + index">
                    <div [ngClass]="{'profile-register-accordion-item-header-expanded': accordionItemModule.expanded}"
                        class="profile-register-accordion-item-header" (click)="accordionItemModule.toggle()">
                        Tipo - ({{ sadt.typeSadtName }} - {{
                        sadt.datetimeInclusion | date:'dd/MM/yy, H:mm' }})
                        <span
                            [ngClass]="{'profile-register-accordion-item-description-expanded': accordionItemModule.expanded}"
                            class="profile-register-accordion-item-description">
                            Clique para {{ accordionItemModule.expanded ? 'fechar' : 'abrir' }}
                        </span>
                    </div>
                    <div class="profile-register-accordion-item-body" role="region"
                        [style.display]="accordionItemModule.expanded ? '' : 'none'"
                        [attr.id]="'accordion-body-' + index" [attr.aria-labelledby]="'accordion-header-' + index">
                        <app-sadt-accordion [sadt]="sadt" [isLoading]="isLoading"
                            (saveSadtData)="saveSadt($event)"></app-sadt-accordion>
                    </div>
                </cdk-accordion-item>
            </cdk-accordion>
            <p *ngIf="listSADT != undefined && listSADT.length == 0 && !isFirstLoading"> Não há pedidos de exame para
                esse paciente!</p>
            <div class="row">
                <div class="col-md-2">
                    <button mat-flat-button color="primary" class="btn-block" (click)="submit()">
                        <span *ngIf="isLoading == false">Encaminhar Paciente</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
                <div class="col-md-2">
                    <button mat-flat-button color="accent" class="btn-block" (click)="cancelSadtAttendance()">
                        <span *ngIf="isLoading == false">Cancelar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="showTissGuideButtonValidation">
    <app-tiss-guide-management-floating-window [idEpisode]="selectedEpisode" [isAttendance]="true"></app-tiss-guide-management-floating-window>
</div>