import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BusinessIntelligenceRoutingModule } from './business-intelligence-routing.module';
import { SharedModule } from '../shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { MatTree, MatTreeModule } from '@angular/material/tree';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { BusinessIntelligenceComponent } from './pages/business-intelligence/business-intelligence.component';
import { BusinessIntelligenceListComponent } from './pages/business-intelligence-list/business-intelligence-list.component';
import { BusinessIntelligenceDetailsComponent } from './pages/business-intelligence-details/business-intelligence-details.component';
import { BiPanelListComponent } from './pages/bi-panel-list/bi-panel-list.component';
import { BiPanelDeleteModalComponent } from './pages/bi-panel-list/bi-panel-delete-modal/bi-panel-delete-modal.component';
import { BiPanelRegisterComponent } from './pages/bi-panel-register/bi-panel-register.component';
import { BusinessIntelligenceConfigComponent } from './pages/business-intelligence-config/business-intelligence-config.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckbox, MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioButton, MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { BusinessIntelligenceSendReportComponent } from './pages/business-intelligence-send-report/business-intelligence-send-report.component';
import { CreateRuleComponent } from './pages/business-intelligence-send-report/create-rule/create-rule.component';
import { FavoriteSelectModalComponent } from './pages/business-intelligence-list/favorite-select-modal/favorite-select-modal.component';

@NgModule({
  declarations: [
    BusinessIntelligenceComponent,
    BusinessIntelligenceListComponent,
    BusinessIntelligenceDetailsComponent,
    BiPanelListComponent,
    BiPanelDeleteModalComponent,
    BiPanelRegisterComponent,
    BusinessIntelligenceConfigComponent,
    BusinessIntelligenceSendReportComponent,
    CreateRuleComponent,
    FavoriteSelectModalComponent
  ],
  imports: [
    BusinessIntelligenceRoutingModule,
    CommonModule,
    SharedModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonModule,
    NgxMaskModule.forRoot(),
    MatCheckboxModule,
    MatTreeModule,
    MatRadioModule,
    ClipboardModule,
    MatTooltipModule,
    MatToolbarModule,
    NgxMatFileInputModule,
    CdkAccordionModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatPaginatorModule,
    MatDialogModule
  ],
  providers: [
    AuthGuard,
    MatCheckbox,
    MatCheckboxChange,
    MatTree,
    MatRadioButton,
  ],
})
export class BusinessIntelligenceModule { }
