import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsClientService } from 'src/app/client/utils.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { RoomService } from 'src/app/shared/services/API/flow/room.service';
import { PinValidityService } from 'src/app/shared/services/API/medic/pin-validity.service';
import { WebsocketRemoteUtilService } from 'src/app/shared/services/websocket-util/websocket-remote-util.service';

@Component({
  selector: 'app-pin-remote-attendance',
  templateUrl: './pin-remote-attendance.component.html',
  styleUrls: ['./pin-remote-attendance.component.css']
})
export class PinRemoteAttendanceComponent implements OnInit {
  constructor(
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private pinValidityService: PinValidityService,
    private activatedRoute: ActivatedRoute,
    private roomService: RoomService,
    private router: Router,
    private utilsService: UtilsClientService,
    private websocketRemoteUtilService: WebsocketRemoteUtilService
  ) { }

  public model: FormGroup;
  public isLoading: boolean = false;
  private idRoom: number;
  private idSector: number;
  public isInvalid: boolean = false;
  ngOnInit(): void {
    let id = this.activatedRoute.snapshot.paramMap.get('id');
    this.model = this.formBuilder.group({
      pin: ['', [Validators.required]],
      rememberPin: [false]
    });
    if (!id.match(/(S([1-9][0-9]*)R([1-9][0-9]*))/g)) {
      this.isInvalid = true;
      if(this.websocketRemoteUtilService.isConnect()){
        this.websocketRemoteUtilService.clearSocket();
      }
      return;
    }
    this.idRoom = parseInt(id.split("R")[1]);
    this.idSector = parseInt(id.split("R")[0].replace("S", ""));

    let pin = this.utilsService.getPinRemote();
    let roomSector = this.utilsService.getSectorRoomRemote();
    if (pin && roomSector) {
      let roomSectorObj: any = JSON.parse(roomSector);
      if (roomSectorObj.idSector != this.idSector) {
        this.utilsService.removeRemoteMemory();
        if (this.websocketRemoteUtilService.isConnect())
          this.websocketRemoteUtilService.clearSocket();
        return;
      }

      if (roomSectorObj.idRoom != this.idRoom)
        if (this.websocketRemoteUtilService.isConnect())
          this.websocketRemoteUtilService.clearSocket();

      this.model.get("pin").setValue(pin);
      this.model.get("rememberPin").setValue(true);
      this.submit();
    }
  }

  submit() {
    if (this.model.invalid) {
      this.alertService.show("Erro", "Preencha o campo do PIN para continuar", AlertType.error);
      return;
    }
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    let pin: string = this.model.get("pin").value;
    this.pinValidityService.validate(this.idSector, pin).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show("Erro", response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        } else if (response.errorCode == 1) {
          this.alertService.show("Aviso", response.errorDescription, AlertType.warning);
          this.isLoading = false;
          return;
        }
        this.ValidateActiveRoom();
      },
      error: (error) => {
        this.alertService.show("Erro", error, AlertType.error);
        this.isLoading = false;
      }
    })
  }

  ValidateActiveRoom() {
    this.roomService.ValidateRoom(this.idRoom, this.idSector).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show("Erro", response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        } else if (response.errorCode == 1) {
          this.alertService.show("Aviso", response.errorDescription, AlertType.warning);
          this.isLoading = false;
          return;
        }
        this.alertService.show("Sucesso", "PIN validado com sucesso!", AlertType.success);

        if (this.model.get("rememberPin").value) {
          this.utilsService.updatePinRemote(this.model.get("pin").value);
        }
        this.utilsService.updateSectorRoomRemote(JSON.stringify({ idRoom: this.idRoom, idSector: this.idSector }));

        this.router.navigate(['client/remote-attendance/welcome']);
      },
      error: (error) => {
        this.alertService.show("Erro", error, AlertType.error);
        this.isLoading = false;
      }
    })
  }
}
