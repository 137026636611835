import { CounselingDirectionStruct } from "../../structs/self-triage/counseling-direction.struct";

export class SelfTriageConfigRequest {
    public listCounselingDirection: CounselingDirectionStruct[] = [];

    public idIbgeState: number;
    public idExternalSearchField: number;

    public logoString64: string;
    public url: string;
    public urlName: string;
    public colorCode: string;
    public externalSearchUrl: string;
    public externalSearchTitle: string;
    public externalSearchKey: string;
    public titlePage: string;
    public descriptionPage: string;
    public stateInitials: string;
    public emergency: string;
    public symptoms: string;

    public isExternalSearchEnabled: boolean;
    public isActiveTitleDescriptionAlteration: boolean;
    public useSelfTriageInPatientCenter: boolean;
    public isActive: boolean;
    public useColor: boolean;
    public isLogoUploaded: boolean;
    public useTolifeLogo: boolean;
}