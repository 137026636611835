<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <a class="back-link" routerLink="/medic/aih-fill-config">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Configuração de Preenchimento
        </a>
        <h1>Cadastrar configuração AIH</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <form appFormscroll class="form" [formGroup]="model" (ngSubmit)="submit()">
                <app-loading-list *ngIf="isLoading"></app-loading-list>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome</mat-label>
                            <input matInput type="text" formControlName="name">
                            <mat-error *ngIf="model.get('name').invalid">Informe o nome</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Setores</mat-label>
                            <mat-select formControlName="idSector">
                                <mat-option *ngFor="let item of listSector" [value]="item.idSector">
                                    {{item.serviceName}} - {{item.sectorName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Campos Laudo</mat-label>
                            <mat-select formControlName="listIdLookup" multiple>
                                <mat-option *ngFor="let item of listLookupHospitalizationReport"
                                    [value]="item.idLookupHospitalizationReport">
                                    {{item.fieldName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <a mat-flat-button type="button" routerLink="/medic/aih-fill-config" color="accent"
                            class=" btn-block">
                            Cancelar
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>