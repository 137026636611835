import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ListTotemButtonResponse } from '../../responses/totem/list-totem-button.response';

@Injectable({
  providedIn: 'root'
})
export class ClientTotemButtonService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listButton(pin: string, idTotem: number): Observable<ListTotemButtonResponse> {
    
    let uri = `ClientTotemButton/idTotem/${idTotem}/pin/${pin}`
    
    let requestOptions = {
      headers: new HttpHeaders({
        'Authorization': environment.authorizationHash
      }),
    };
    
    return this.httpClient.get<ListTotemButtonResponse>(environment.urlApiTotem + uri, requestOptions)
    .pipe(
      catchError(this.handleError)
    )
  }
}
