import { RelationPatientStruct } from "../../structs/pharmacy/relation-patient.struct";

export class RelationPatientTagReportRequest {
     public relationPatient: RelationPatientStruct;
     public height: number;
     public width: number;
     public patientName: string;
     public birthDate: string;
     public motherName: string;
     public dateTimeRelease: string;
     public medicineName: string;
     public quantity: number;
}