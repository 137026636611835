import { AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MedicalRecordService } from 'src/app/shared/services/API/orchestrator-patient/medical-record.service';
import { TriageStruct } from 'src/app/shared/services/structs/orchestrator-patient/triage.struct';
import { CompleteMedicalCareStruct } from 'src/app/shared/services/structs/orchestrator-patient/complete-medical-care.struct';
import { EpisodeHistorieStruct } from 'src/app/shared/services/structs/orchestrator-patient/episodeHistorie.struct';
import { EpisodeModalComponent } from 'src/app/medic/pages/attend-patient/episode-modal/episode-modal.component';
import { PrescriptionModalComponent } from './prescription-modal/prescription-modal.component';
import { ReadMedicalPrescriptionStruct } from 'src/app/shared/services/structs/orchestrator-patient/read-medical-prescription.struct';
import { MedicineService } from 'src/app/shared/services/API/pharmacy/medicine.service';
import { ListMedicineResponse } from 'src/app/shared/services/responses/pharmacy/list-medicine.response';
import { SadtModalComponent } from 'src/app/shared/components/sadt-modal/sadt-modal.component';
import { ExamService } from 'src/app/shared/services/API/SADT/exam.service';
import { ExamStruct } from 'src/app/shared/services/structs/SADT/exam.struct';
import { MedicalCareService } from 'src/app/shared/services/API/medical-record/medical-care.service';
import { MedicalCareRequest } from 'src/app/shared/services/requests/medical-record/medical-care.request';
import { EpisodeStatusEnum } from 'src/app/shared/enum/episode-status.enum';
import { LookupMedicalCareService } from 'src/app/shared/services/API/medical-record/lookup-medical-care.service';
import { LookupMedicalCareResponse } from 'src/app/shared/services/responses/medical-record/lookup-medical-care-response';
import { FowardRequest } from 'src/app/shared/services/requests/orchestrator-queue/foward.request';
import { FowardService } from 'src/app/shared/services/API/orchestrator-queue/foward.service';
import { environment } from 'src/environments/environment';
import { CancelConfirmModalComponent } from './cancel-confirm-modal/cancel-confirm-modal.component';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { AllergyModel } from 'src/app/shared/services/models/medical-record/allergy.model';
import { ClockService } from 'src/app/shared/services/API/orchestrator-queue/clock.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { BillingDataRequest } from 'src/app/shared/services/requests/billing/billing-data-request';
import { LocationStruct } from 'src/app/shared/services/structs/orchestrator-queue/location.struct';
import { SelectedLocationRequest } from 'src/app/shared/services/requests/orchestrator-queue/selected-location.request';
import { FowardDestinationEnum } from 'src/app/shared/enum/foward-destination.enum';
import { EpisodeStatusRequest } from 'src/app/shared/services/requests/medical-record/episode-status.request';
import { MedicalPrescriptionService } from 'src/app/shared/services/API/medical-record/medical-prescription.service';
import { DateAfterTodayValidator } from 'src/app/shared/custom-validators/date.validator';
import { ProcedureTriggersEnum } from 'src/app/shared/enum/procedure-triggers.enum';
import { ProcedureSigtapCBOService } from 'src/app/shared/services/API/billing/procedure-sigtap-cbo.service';
import { ProcedureSigtapModel } from 'src/app/shared/services/models/billing/procedure-sigtap.model';
import { ExternalPrescriptionModalComponent } from 'src/app/shared/components/external-prescription-modal/external-prescription-modal.component';
import { ReadExternalPrescriptionStruct } from 'src/app/shared/services/structs/orchestrator-patient/read-external-prescription.struct';
import { ExamTypeService } from 'src/app/shared/services/API/SADT/exam-type.service';
import { ExamTypeModel } from 'src/app/shared/services/models/SADT/exam-type.model';
import { SadtGroupStruct } from 'src/app/shared/services/structs/medical-record/sadt-group.struct';
import { ForwardResponse } from 'src/app/shared/services/responses/orchestrator-queue/forward.response';
import { ObservationForwardModalComponent } from 'src/app/shared/components/observation-forward-modal/observation-forward-modal.component';
import { RoomAccessUtilService } from 'src/app/shared/services/room-access-util.service';
import { ObservationForwardService } from 'src/app/shared/services/API/orchestrator-queue/observation-foward.service';
import { BedQueueService } from 'src/app/shared/services/API/queue/bed-queue.service';
import { MedicConfigSectorService } from 'src/app/shared/services/API/medic/medic-config-sector.service';
import { MedicConfigSectorStruct } from 'src/app/shared/services/structs/medic/medic-config-sector.struct';
import { MedicRequiredFieldEnum } from 'src/app/shared/enum/medic-required-field.enum';
import { NursingConsultationStruct } from 'src/app/shared/services/structs/medical-record/nursing-consultation.struct';
import { EpisodeStruct } from 'src/app/shared/services/structs/orchestrator-patient/episode.struct';
import { MedicineStruct } from 'src/app/shared/services/structs/pharmacy/medicine.struct';
import { ProcedureModalComponent } from './procedure-modal/procedure-modal.component';
import { ProcedureService } from 'src/app/shared/services/API/medic/procedure.service';
import { MedicalProcedureModel, MedicalProcedureWithName } from 'src/app/shared/services/models/medical-record/medical-procedure.model';
import { ProcedureModel } from 'src/app/shared/services/models/medic/procedure-model';
import { BypassEnum } from 'src/app/shared/enum/bypass.enum';
import { FlowStruct } from 'src/app/shared/services/structs/flow/flow.struct';
import { ForwardService } from 'src/app/shared/services/API/flow/forward.service';
import { AvailableFlowsRequest } from 'src/app/shared/services/requests/flow/available-flows.request';
import { PriorityColorEnum } from 'src/app/shared/enum/priority-color.enum';
import { FlowSequenceRequest } from 'src/app/shared/services/requests/flow/flow-sequence.request';
import { FlowSequenceService } from 'src/app/shared/services/API/flow/flow-sequence.service';
import { PresenceReportService } from 'src/app/shared/services/API/orchestrator-patient/presence-report.service';
import { PostPresenceReportRequest } from 'src/app/shared/services/requests/orchestrator-patient/post-presence-report.request';
import { OrganizationService } from 'src/app/shared/services/API/flow/organization.service';
import { RoomStruct } from 'src/app/shared/services/structs/flow/room.struct';
import { SectorStruct } from 'src/app/shared/services/structs/flow/sector.struct';
import { ServiceStruct } from 'src/app/shared/services/structs/flow/service.struct';
import { FlowSequenceUpdateRequest } from 'src/app/shared/services/requests/flow/flow-sequence-update.request';
import { CidStruct } from 'src/app/shared/services/structs/medical-record/cid.struct';
import { SaveCompletedMedicalRecordRequest } from 'src/app/shared/services/requests/orchestrator-patient/save-completed-medical-care-record.request';
import { MedicalCareReportRequest } from 'src/app/shared/services/requests/orchestrator-patient/medical-care-report.request';
import { AllergyStruct } from 'src/app/shared/services/structs/medical-record/allergy.struct';
import { UpdateStatusQueueService } from 'src/app/shared/services/API/orchestrator-queue/update-status-queue.service';
import { StatusQueueRequest } from 'src/app/shared/services/requests/queue/status-queue.request';
import { LastSelfTriageStruct } from 'src/app/shared/services/structs/orchestrator-patient/last-self-triage.struct';
import { TelephoneTriageStruct } from 'src/app/shared/services/structs/orchestrator-patient/telephone-triage.struct';
import { SelfTriageResumeModalComponent } from 'src/app/shared/components/self-triage-resume-modal/self-triage-resume-modal.component';
import { PinCertificateModalComponent } from 'src/app/shared/components/pin-certificate-modal/pin-certificate-modal.component';
import { StatusQueueEnum } from 'src/app/shared/enum/status-queue.enum';
import { EpisodeCareLineStruct } from 'src/app/shared/services/structs/care-line/episode-care-line.struct';
import { CareLineModel } from 'src/app/shared/services/models/care-line/care-line.model';
import { CareLineModalComponent } from 'src/app/shared/components/care-line-modal/care-line-modal.component';
import { EpisodeCareLineRequest } from 'src/app/shared/services/requests/care-line/episode-care-line.request';
import { CareLinePriority } from 'src/app/shared/services/models/care-line-priority/care-line-priority.model';
import { GetCareLinePriorityService } from 'src/app/shared/services/API/care-line-priority/get-care-line-priority.service';
import { CareLinePriorityStatusEnum } from 'src/app/shared/enum/care-line-priority/care-line-priority-status.enum';
import { RemoteHoldModalComponent } from './remote-hold-modal/remote-hold-modal.component';
import { WebsocketRemoteService } from 'src/app/shared/services/websocket-remote.service';
import { TwilioVideoComponent } from 'src/app/shared/components/twilio-video/twilio-video.component';
import { ValidateCareLinePriorityModalComponent } from 'src/app/shared/components/validate-care-line-priority-modal/validate-care-line-priority-modal.component';
import { CareLinePriorityStruct } from 'src/app/shared/services/structs/medical-record/care-line-priority-validated.struct';
import { CareLinePriorityStatusResponse } from 'src/app/shared/services/responses/medical-record/care-line-priority-status.response';
import { UtilsSecundaryHeaderService } from 'src/app/shared/components/secundary-header/utils.service';
import { MenuComponent } from 'src/app/shared/components/menu/menu.component';
import { PatientService } from 'src/app/shared/services/API/medical-record/patient.service';
import { EmailConfirmationEndMedicalCareModalComponent } from 'src/app/shared/components/email-confirmation-end-medical-care-modal/email-confirmation-end-medical-care-modal.component';
import { UpdateEmailPatientRequest } from 'src/app/shared/services/requests/medical-record/update-email-patient.request';
import { FinalizationTypeEnum } from 'src/app/shared/enum/finalization-type.enum';
import { PatientLeaveModalRemoteComponent } from './patient-leave-modal/patient-leave-modal-remote.component';
import { ReturnToQueueService } from 'src/app/shared/services/API/queue/return-to-queue.service';
import { ReturnToQueueRequest } from 'src/app/shared/services/requests/queue/return-to-queue.request';
import { SaveFavoritePrescriptionModalComponent } from './favorite-prescription-modal/favorite-prescription-modal.component';
import { SelectFavoriteMedicineModalComponent } from './select-favorite-medicine-modal/select-favorite-medicine-modal.component';
import { ValidateSuspicionModalComponent } from 'src/app/shared/components/validate-suspicion-modal/validate-suspicion-modal.component';
import { Suspicion } from 'src/app/shared/services/responses/medical-record/lookup.response';
import { SuspicionValidatedStruct } from 'src/app/shared/services/structs/medical-record/suspicion-validated.struct';
import { SuspicionStatusResponse } from 'src/app/shared/services/responses/medical-record/suspicion-status.response';
import { FavoriteTypeEnum } from 'src/app/shared/enum/favorite.enum';
import { FavoriteStandardMedicine } from 'src/app/shared/services/models/medic/favorite-standard-medicine.model';
import { InvalidPrescriptionModalComponent } from './invalid-prescription-modal/invalid-prescription-modal.component';
import { SelectFavoriteNonStandardMedicineModalComponent } from './select-favorite-non-standard-medicine-modal/select-favorite-non-standard-medicine-modal.component';
import { SelectFavoriteExamPrescriptionModalComponent } from './select-favorite-exam-prescription-modal/select-favorite-exam-prescription-modal.component';
import { MedicalCareSadtStruct } from 'src/app/shared/services/structs/medical-record/medical-care-sadt.struct';
import { SelectOldPrescriptionModalComponent } from './select-old-prescription-modal/select-old-prescription-modal.component';
import { SadtPlaceEnum } from 'src/app/shared/enum/sadt-place.enum';
import { SadtStatusEnum } from 'src/app/shared/enum/sadt-status.enum';
import { HealthUnitDischargeOutcomeService } from 'src/app/shared/services/API/medic/health-unit-discharge-outcome.service';
import { WebsocketAttendanceUtilService } from 'src/app/shared/services/websocket-util/websocket-attendance-util.service';
import { MedicatedSolutionStruct } from 'src/app/shared/services/structs/medical-record/medicated-solution.struct';
import { StandardPrescriptionStruct } from 'src/app/shared/services/structs/medical-record/standard-prescription.struct';
import { AihModalComponent } from './aih-modal/aih-modal.component';
import { HealthUnitMemedPrescriptionService } from 'src/app/shared/services/API/medic/health-unit-memed-prescription.service';
import { MemedPrescriptionStruct } from 'src/app/shared/services/structs/medical-record/memed-prescription.struct';
import { UserMemedService } from 'src/app/shared/services/API/orchestrator-patient/user-memed.service';
import { MemedCompletePrescriptionStruct } from 'src/app/shared/services/structs/medical-record/memed-complete-prescription.struct';
import { MemedPrescriptionDeleteModalComponent } from './memed-prescription-delete-modal/memed-prescription-delete-modal.component';
import { DeleteMemedPrescriptionsRequest } from 'src/app/shared/services/requests/orchestrator-integration/delete-memed-prescriptions.request';
import { MemedPrescriptionSession, UtilService } from 'src/app/shared/services/util.service';
import { GetPhoneModalComponent } from 'src/app/shared/components/get-phone-modal/get-phone-modal.component';
import { AihStatusEnum } from 'src/app/shared/enum/aih/aih-status.enum';
import { SignatureCredentialsModalComponent } from 'src/app/shared/components/signature-credentials-modal/signature-credentials-modal.component';
import { ReportViewSelectModalComponent } from 'src/app/shared/components/report-view-select-modal/report-view-select-modal.component';
import { NewReportResponse } from 'src/app/shared/services/responses/orchestrator-patient/new-report.response';
import { UserLoginResponse } from 'src/app/shared/services/responses/user/auth.response';
import { UserRoleEnum } from 'src/app/shared/enum/user-role.enum';
import { TissGuideManagementFloatingWindowComponent } from 'src/app/shared/components/tiss-guides/tiss-guide-management-floating-window/tiss-guide-management-floating-window.component';
import { AllPrivateBillingGuideReportStruct } from 'src/app/shared/services/structs/orchestrator-patient/all-private-billing-guide-report.struct';
import { AttendanceOccupiedRemoveModalComponent } from 'src/app/shared/components/attendance-occupied-remove-modal/attendance-occupied-remove-modal.component';
import { lastValueFrom } from 'rxjs';
import { AlertModalComponent } from 'src/app/shared/components/alert-modal/alert-modal.component';

@Component({
  selector: 'app-attend-patient',
  templateUrl: './attend-patient.component.html',
  styleUrls: ['./attend-patient.component.css']
})
export class AttendPatientComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private utilsService: UtilService,
    private dialog: MatDialog,
    public returnToQueueService: ReturnToQueueService,
    public procedureService: ProcedureService,
    public medicalRecordService: MedicalRecordService,
    public medicineService: MedicineService,
    public lookupMedicalCareService: LookupMedicalCareService,
    public examService: ExamService,
    public medicalCareService: MedicalCareService,
    public router: Router,
    public fowardService: FowardService,
    public forwardFlowService: ForwardService,
    public flowSequenceService: FlowSequenceService,
    public organizationService: OrganizationService,
    private maskService: MaskService,
    private clockService: ClockService,
    private authService: AuthService,
    private medicalPrescriptionService: MedicalPrescriptionService,
    private procedureSigtapCBOService: ProcedureSigtapCBOService,
    private examTypeService: ExamTypeService,
    public observationForwardService: ObservationForwardService,
    public presenceReportService: PresenceReportService,
    public bedQueueService: BedQueueService,
    public updateStatusQueueService: UpdateStatusQueueService,
    public medicConfigSectorService: MedicConfigSectorService,
    private getCareLinePriorityService: GetCareLinePriorityService,
    private websocketRemoteService: WebsocketRemoteService,
    public websocketAttendanceUtilService: WebsocketAttendanceUtilService,
    private utilHeaderService: UtilsSecundaryHeaderService,
    private patientService: PatientService,
    private healthUnitDischargeOutcomeService: HealthUnitDischargeOutcomeService,
    private healthUnitMemedPrescriptionService: HealthUnitMemedPrescriptionService,
    private userMemedService: UserMemedService,
    private utilService: UtilService,
  ) { }

  @ViewChild('twiliovideo') twiliovideo: TwilioVideoComponent;
  @ViewChild(TissGuideManagementFloatingWindowComponent) childComponent: TissGuideManagementFloatingWindowComponent;

  private showTissGuideButton: boolean = false;
  public showTissGuideButtonValidation: boolean = false;
  public allPrivateBillingGuideReportStruct: AllPrivateBillingGuideReportStruct;

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.medic;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.medic_patient_list;

  public episodeStatusAltaEnum: EpisodeStatusEnum = EpisodeStatusEnum.FinalizadoAlta;
  public episodeStatusAltaAdministrativaEnum: EpisodeStatusEnum = EpisodeStatusEnum.FinalizadoAltaAdministrativa;
  public episodeStatusObitoEnum: EpisodeStatusEnum = EpisodeStatusEnum.FinalizadoObito;
  public episodeStatusInternarEnum: EpisodeStatusEnum = EpisodeStatusEnum.FinalizadoInternar;

  private socket: any;
  public isLoading: boolean = false;
  public isLoadingCancelar: boolean = false;
  public isFirstLoading: boolean;
  public isIsolation: boolean;
  public selectedEpisode: number;
  public triage: TriageStruct = null;
  public medicalCare: CompleteMedicalCareStruct[] = [];
  public episodeHistorie: EpisodeHistorieStruct[] = [];
  public model: FormGroup;
  public lookupMedicalCareResponse: LookupMedicalCareResponse;
  public listPrescription: ReadMedicalPrescriptionStruct[] = [];
  public listPrescriptionMedicatedSolution: MedicatedSolutionStruct[] = [];
  public listExternalPrescription: ReadExternalPrescriptionStruct[] = [];
  public listMedicalProcedure: MedicalProcedureModel[] = [];
  public listSADT: SadtGroupStruct[] = [];
  public listLookupExam: ExamStruct[] = [];
  public listMedicine: ListMedicineResponse = new ListMedicineResponse();
  public wasSaved: boolean = false;
  public urlListCid: string = environment.urlApiMedicalRecord + 'cid';
  public listCid: any[] = [];
  public idSector: number = null;
  public idRoom: number = null;
  public masks: Masks;
  public satdOthers: number = 0;
  public isBedManagement: boolean;
  public idBed: number = null;
  public allergies: AllergyModel[] = [];
  public allergiesToSave: AllergyModel[] = [];
  public datetimeStartAttendance: Date;
  public listProcedureSigtap: any[] = [];
  public urlListProcedure: string;
  public codCbo: string;
  public idCompetence: number;
  public listFoward: LocationStruct[];
  public idLastQueue: number;
  public destinationType: number;
  public destinationTypeAltaEncaminhamento: number = FowardDestinationEnum.Alta_Encaminhamento;
  public destinationTypeInternacaoEncaminhamento: number = FowardDestinationEnum.Internacao_Encaminhamento;
  public destinationTypeEncaminhamento: number = FowardDestinationEnum.Encaminhamento;
  public destinationTypeManter: number = FowardDestinationEnum.Manter_em_obervacao;
  public destinationTypeAlta: number = FowardDestinationEnum.Alta;
  public destinationTypeInternacao: number = FowardDestinationEnum.Internacao;
  public destinationTypeObito: number = FowardDestinationEnum.Obito;
  public priorityColorEnum: PriorityColorEnum;
  public readonly: boolean = false;
  public idMedicalCare: number;
  public stringValidator: string;
  public showFoward: boolean;
  public medicalEvolution: boolean = true;
  public isLoadingLasPrescriptions: boolean = false;
  public listPreselectedPRocedure: ProcedureSigtapModel[] = null;
  public listTriggersProcedure: ProcedureSigtapModel[] = [];
  private listExamType: ExamTypeModel[];
  public listProceduresNursing: MedicalProcedureWithName[];
  public idService: number;
  public medicConfigSector: MedicConfigSectorStruct;
  public listNursingConsultation: NursingConsultationStruct[];
  public episodeStruct: EpisodeStruct;
  public listConfigIdMedicine: number[];
  public listMedicineAux: MedicineStruct[];
  public listProcedure: ProcedureModel[];
  public hasProcedure: boolean = false;
  public isFastTrack: boolean = false;
  public bypassExam: boolean = false;
  public blockBypassExam: boolean = false;
  public bypassPrecription: boolean = false;
  public listIdBypass: number[] = [];
  public listIdExam: number[];
  public isAllExams: boolean;
  public isLoadingFlows: boolean = true;
  public listAvailableFlows: Array<FlowStruct[]> = [];
  public selectedFlows: string[] = [];
  public selectedFlow: FlowStruct;
  public idFlowCompleted: number;
  public listRoom: RoomStruct[];
  public listSector: SectorStruct[];
  public listService: ServiceStruct[];
  public cidSelect: CidStruct[] = [];
  public destinationTypeManterColor: string = "accent";
  public destinationTypeAltaColor: string = "accent";
  public destinationTypeAltaEncaminhamentoColor: string = "accent";
  public destinationTypeEncaminhamentoColor: string = "accent";
  public destinationTypeInternacaoEncaminhamentoColor: string = "accent";
  public destinationTypeInternacaoColor: string = "accent";
  public destinationTypeObitoColor: string = "accent";
  public isAttended: boolean = false;
  public isEvaded: boolean = false;
  public lastSelfTriage: LastSelfTriageStruct;
  public lastTelephoneTriage: TelephoneTriageStruct;
  public certificatePin: string = null;
  public isIcpBrailSigned: boolean = false;
  public isUserIcpBrasilSignature: boolean = false;
  public hasIcpBrasilTokenCertificate: boolean = false;
  public isIcpBrailTokenSigned: boolean = false;
  public listCareLine: CareLineModel[] = [];
  public listPastEpisodeCareLine: EpisodeCareLineStruct[] = [];
  public listCurrentEpisodeCareLine: EpisodeCareLineStruct[] = [];
  public listNewEpisodeCareLine: EpisodeCareLineStruct[] = [];
  public listSuspicion: Suspicion[] = [];
  public patientPhoneNumber: string = "";
  public countryCode: string = "+55";

  public allergyError: boolean = false;
  public canReturnToQueue: boolean = false;
  public listCareLinePriority: CareLinePriority[] = [];
  public listAllCareLinePriority: CareLinePriority[] = [];
  private dialogRefRemoteHoldModal: MatDialogRef<RemoteHoldModalComponent>;
  private idTimeout: NodeJS.Timeout;
  public remoteAttendance: boolean = false;
  public careLinePriorityStatusConfirmed: number = CareLinePriorityStatusEnum.confirmado;
  public careLinePriorityStatusDenied: number = CareLinePriorityStatusEnum.negado;
  public careLinePriorityStatusSuspicion: number = CareLinePriorityStatusEnum.suspeita;
  public isSuspicionEvaluatedSuspicion: boolean = null;
  public isSuspicionEvaluatedConfirmed: boolean = true;
  public isSuspicionEvaluatedDenied: boolean = false;
  public careLinePriorityStruct: CareLinePriorityStruct = new CareLinePriorityStruct();
  public suspicionValidatedStruct: SuspicionValidatedStruct = new SuspicionValidatedStruct();
  public idQueue: number;
  @ViewChild(MenuComponent) menu: MenuComponent;

  public patientEmail: string;
  public newEmail: string;
  public idPatient: number;
  public idFinalizationType: number;
  private onlyIntheRoom: boolean = false;
  public favoriteStandardMedicine: boolean = false;
  public favoriteNonStandardMedicine: boolean = false;
  public favoriteExameRequest: boolean = false;
  public dischargeOutcome: boolean = true;
  public isMemedPrescription: boolean = false;
  public datetimeInclusionDIscharge: Date;
  public aihModalResult: any; //ALTERAR PARA O NOME CORRETO DEPOIS
  public isAihRedirect: boolean = false;
  public cpfPatient: string;

  public listMemedPrescriptionStruct: MemedPrescriptionStruct[] = [];
  public listMemedCompletePrescriptionStruct: MemedCompletePrescriptionStruct[] = [];
  public userTokenMemed: string;
  public showMemed: boolean = false;
  public patientCellPhone: string;
  public patientHasPhone: boolean = true;
  public isUnidentifiedPatient: boolean = false;
  public hasNewStandardPrescription: boolean = false;
  public userLogin: string;
  public userName: string;
  public isAttendanceRemoval: boolean = false;
  //Assinatura digital
  public sign: boolean;
  public requireSignature: boolean;
  public blockAllergicPrescription: boolean;
  public isDisableExternalRequest: boolean;
  public isDisableSession: boolean = false;
  public disabelSessionTimeout;
  public listFlow: FlowStruct[] = [];

  ngOnInit(): void {
    this.userLogin = this.authService.getTokenMenu().login;
    this.userName = this.authService.getTokenMenu().userName;
    this.requireSignature = this.checkSigningResponsibility();
    this.masks = this.maskService.getMasks();
    this.listFoward = [];
    this.listProceduresNursing = [];
    this.selectedEpisode = parseInt(this.activatedRoute.snapshot.paramMap.get('idEpisode'));
    this.getLookupMedicine();
    if (this.selectedEpisode) {
      this.isFirstLoading = true;
      this.populateAttendPatient(this.selectedEpisode);
    }
    else {
      this.getMemedPrescription();
      this.getDate();
    }

    if (this.activatedRoute.snapshot.paramMap.get('readonly'))
      this.readonly = this.activatedRoute.snapshot.paramMap.get('readonly') === "true";

    this.idSector = parseInt(this.activatedRoute.snapshot.paramMap.get('idSector'));
    this.idRoom = parseInt(this.activatedRoute.snapshot.paramMap.get('idRoom'));

    if (parseInt(this.activatedRoute.snapshot.paramMap.get('idBed')))
      this.idBed = parseInt(this.activatedRoute.snapshot.paramMap.get('idBed'));

    if (parseInt(this.activatedRoute.snapshot.paramMap.get('idService')))
      this.idService = parseInt(this.activatedRoute.snapshot.paramMap.get('idService'));

    if (parseInt(this.activatedRoute.snapshot.paramMap.get('idQueue')))
      this.idQueue = parseInt(this.activatedRoute.snapshot.paramMap.get('idQueue'));

    if (this.activatedRoute.snapshot.paramMap.get('showTissGuideButton'))
      this.showTissGuideButton = this.activatedRoute.snapshot.paramMap.get('showTissGuideButton') == "true" ? true : false;

    this.isBedManagement = Boolean(this.activatedRoute.snapshot.paramMap.get('isBedManagement'));
    this.getLookupSadtType();
    this.getLookupMedicalCare();
    this.getListProcedures();
    this.getDischargeOutcome();
    this.populateCareLinePrioritySelect();
    //this.getMemedPrescription();

    if (!this.readonly)
      this.populateFowardSelect();

    this.codCbo = this.authService.getTokenMenu().codCbo;
    this.idCompetence = this.authService.getTokenMenu().healthUnit.idProcedureSigtapCompetence;

    if (this.codCbo && this.idCompetence) {
      let url = `ProcedureSigtapCBO/codCbo/${this.codCbo}/idCompetence/${this.idCompetence}`
      this.urlListProcedure = environment.urlApiBilling + url;
    }

    this.model = this.formBuilder.group({
      medicalNotes: [{ value: '', disabled: this.wasSaved }],
      physicalExamination: [{ value: '', disabled: this.wasSaved }],
      therapeuticPlan: [{ value: '', disabled: this.wasSaved }],
      diagnosedHypothesis: [{ value: '', disabled: this.wasSaved }],
      temperature: [{ value: '', disabled: this.wasSaved }],
      respiratoryFrequency: [{ value: '', disabled: this.wasSaved }],
      heartRate: [{ value: '', disabled: this.wasSaved }],
      glucose: [{ value: '', disabled: this.wasSaved }],
      bloodPressure: [{ value: '', disabled: this.wasSaved }],
      saturation: [{ value: '', disabled: this.wasSaved }],
      conclusionNote: [{ value: '', disabled: this.wasSaved }],
      idFlow: [{ value: '', disabled: this.wasSaved }],
      conclusionType: [{ value: this.isBedManagement ? this.destinationTypeManter.toString() : this.destinationTypeAlta.toString(), disabled: this.wasSaved }],
      datetimeEndIsolation: [null],
      sickDaysOffAmount: [0, [Validators.min(0)]],
      isCidOnSickNote: [{ value: false, disabled: true }, [Validators.required]],
      firstChildGroup: this.formBuilder.group({
        cid: [this.listCid.map(a => a.idCid)],
        listIdAllergies: [],
      }),
      flowSequence: this.formBuilder.array([]),
      idCareLinePriority: [],
      idSuspicion: []
    });

    this.changeConclusionType(null);

    this.getMedicSectorConfiguration();
    this.getOrganization();

    let memedSession: MemedPrescriptionSession = new MemedPrescriptionSession();
    memedSession = this.utilService.getMemedPrescriptionSession();
    if (memedSession) {
      this.listMemedPrescriptionStruct = memedSession.listMemedPrescriptionStruct;
      this.listMemedCompletePrescriptionStruct = memedSession.listMemedCompletePrescriptionStruct;
    }
  }

  ngAfterViewInit() {
    if (this.codCbo && this.idCompetence)
      this.getPreselectedProcedures();
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: any): void {
    event.returnValue = true;
  }

  addItemCid(event: any) {
    this.listCid = event;
    if (!this.listCid || this.listCid.length == 0) {
      this.model.get('isCidOnSickNote').setValue(false);
      this.model.get('isCidOnSickNote').disable();
    }
    else
      this.model.get('isCidOnSickNote').enable();
  }

  addItemProcedure(event: any) {
    this.listProcedureSigtap = event;
    this.listPreselectedPRocedure = this.listProcedureSigtap;
  }

  populateAttendPatient(selectedEpisode: number) {
    this.medicalRecordService.getMedicalRecord(selectedEpisode).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isFirstLoading = false;
          return;
        }
        this.cpfPatient = response.cpf;
        this.isUserIcpBrasilSignature = response.isActualUserIcpBrasilSignature;
        this.hasIcpBrasilTokenCertificate = response.hasIcpBrasilTokenCertificate;
        this.lastSelfTriage = response.lastSelfTriage;
        this.lastTelephoneTriage = response.lastTelephoneTriage;
        this.datetimeStartAttendance = response.datetimeStartAttendance;
        this.listSuspicion = response.listSuspicion;
        this.triage = response.triage;
        this.idPatient = response.episode.idPatient;
        this.patientEmail = response.email;
        if (!this.readonly) {
          if (response.medicalCareHistorie != null && response.medicalCareHistorie.length > 0 && !this.isBedManagement)
            this.populateLastMedicalCare(true, response.medicalCareHistorie[response.medicalCareHistorie.length - 1])
        }
        else {
          if (response.medicalCareHistorie != null && response.medicalCareHistorie.length > 0) {
            this.populateLastMedicalCare(false, response.medicalCareHistorie[response.medicalCareHistorie.length - 1])
            response.medicalCareHistorie.pop()
          }
        }
        this.medicalCare = response.medicalCareHistorie;
        this.episodeStruct = response.episode;
        this.isUnidentifiedPatient = this.episodeStruct.isUnidentifiedPatient;
        this.showTissGuideButtonValidation = this.showTissGuideButton && !this.isUnidentifiedPatient;
        this.patientCellPhone = this.episodeStruct.patientPhone1 ? this.episodeStruct.patientPhone1 : this.episodeStruct.patientPhone2;
        if (!this.patientCellPhone) this.patientHasPhone = false;

        if (this.datetimeInclusionDIscharge != null && this.episodeStruct.datetimeInclusion != null) {
          const dischargeComparsion = new Date(this.datetimeInclusionDIscharge);
          const episodeDateComparsion = new Date(this.episodeStruct.datetimeInclusion);
          if (episodeDateComparsion.getTime() < dischargeComparsion.getTime()) {
            this.dischargeOutcome = !this.dischargeOutcome;
          }
        }

        if (this.episodeStruct?.idCareLinePriorityStatus) {
          this.careLinePriorityStruct.idCareLinePriority = this.episodeStruct.idCareLinePriority;
          this.careLinePriorityStruct.careLinePriorityName = this.listAllCareLinePriority?.find(x => x.idCareLinePriority === this.episodeStruct.idCareLinePriority)?.careLinePriorityName;
          this.careLinePriorityStruct.careLinePriorityStatusMotive = this.episodeStruct.careLinePriorityStatusMotive;
          this.careLinePriorityStruct.idCareLinePriorityStatus = this.episodeStruct.idCareLinePriorityStatus;
          this.careLinePriorityStruct.careLinePriorityStatusUserName = this.episodeStruct.careLinePriorityStatusUserName;
          this.careLinePriorityStruct.datetimeCareLinePriorityStatus = this.episodeStruct.datetimeCareLinePriorityStatus;
          this.model.get('idCareLinePriority').setValue(this.episodeStruct.idCareLinePriority);
          this.model.get('idCareLinePriority').disable();
          this.listCareLinePriority = this.listAllCareLinePriority;
        }

        this.episodeHistorie = response.episodeHistorie;

        this.allergies = response.listAllergy;
        this.allergiesToSave = response.listAllergy;
        this.model.get('firstChildGroup').get('listIdAllergies').setValue(this.allergies.map(x => x.idAllergy));
        if (this.episodeStruct.idCareLinePriority != null) {

        }
        if (this.triage && this.triage.idSuspicion) {
          this.mapSuspicion();
          this.model.get('idSuspicion').setValue(this.triage.idSuspicion);
          this.model.get('idSuspicion').disable();
        }
        this.listNursingConsultation = response.nursingConsultationHistory;

        this.listPastEpisodeCareLine = response.listPastEpisodeCareLine;
        this.listCurrentEpisodeCareLine = response.listCurrentEpisodeCareLine
        this.listCareLine = response.listCareLine;
        if (!this.isUnidentifiedPatient)
          this.getMemedPrescription();

        this.isFirstLoading = false;

        this.disableSession();
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  updateAllergiesToSave(allergies: AllergyModel[]) {
    this.allergiesToSave = allergies;
    this.model.get("firstChildGroup").get("listIdAllergies").setValue(this.allergiesToSave.map(x => x.idAllergy));
  }

  getLookupSadtType() {
    this.examTypeService.listAllExamTypeWithExams().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listExamType = response.listExamType;
      }
    });
  }

  populateLastMedicalCare(isNew: boolean, medicalCare: CompleteMedicalCareStruct) {
    this.model.get("medicalNotes").setValue(medicalCare.medicalCare.medicalNotes);
    this.model.get("physicalExamination").setValue(medicalCare.medicalCare.physicalExamination);
    this.model.get("therapeuticPlan").setValue(medicalCare.medicalCare.therapeuticPlan);
    this.model.get("diagnosedHypothesis").setValue(medicalCare.medicalCare.diagnosedHypothesis);
    this.cidSelect = medicalCare.cidSelect;
    this.listCid = [...medicalCare.cidSelect];
    if (this.listCid && this.listCid.length > 0)
      this.model.get('isCidOnSickNote').enable();

    this.model.get('firstChildGroup').get('cid').setValue(medicalCare.cidSelect.map(x => x.idCid));
    if (!isNew) {
      this.model.get("temperature").setValue(medicalCare.medicalCare.temperature ? medicalCare.medicalCare.temperature.toString().replace(".", "") : null);
      this.model.get("respiratoryFrequency").setValue(medicalCare.medicalCare.respiratoryFrequency);
      this.model.get("heartRate").setValue(medicalCare.medicalCare.heartRate);
      this.model.get("glucose").setValue(medicalCare.medicalCare.glucose);
      this.model.get("bloodPressure").setValue(medicalCare.medicalCare.bloodPressureSystole + medicalCare.medicalCare.bloodPressureDiastole);
      this.model.get("saturation").setValue(medicalCare.medicalCare.saturation);
      this.listPrescription = medicalCare.medicalPrescription && medicalCare.medicalPrescription.length > 0 ? medicalCare.medicalPrescription : [];
      this.listPrescriptionMedicatedSolution = medicalCare.medicatedSolutionPrescription && medicalCare.medicatedSolutionPrescription.length > 0 ? medicalCare.medicatedSolutionPrescription : [];
      this.listExternalPrescription = medicalCare.listExternalPrescription && medicalCare.listExternalPrescription.length > 0 ? medicalCare.listExternalPrescription : [];
      this.listSADT = medicalCare.medicalCareSadt && medicalCare.medicalCareSadt.length > 0 ? medicalCare.medicalCareSadt : [];
      this.idMedicalCare = medicalCare.medicalCare.idMedicalCare;
      this.medicalEvolution = medicalCare.medicalEvolution;
      this.listMedicalProcedure = medicalCare.listMedicalProcedure;
      if (!this.medicalEvolution)
        this.alertService.show('AVISO', "Seu usuário não tem permissão para ver as evoluções médicas!", AlertType.warning);
    }
  }

  clickIsLoading() {
    this.alertService.show('Aviso', "Aguarde o atendimento médico está sendo salvo!", AlertType.warning);
  }

  openAIHModal() {
    const dialogRef = this.dialog.open(AihModalComponent, {
      data: {
        idEpisode: this.selectedEpisode,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result) {
          this.aihModalResult = result.aihStruct; //TODO:  ADICIONAR RESULTADO DO PREENCHIMENTO DA AIH NO REQUEST PARA SALVAR MEDICAL CARE.
          this.isAihRedirect = result.isAihRedirect;
          this.saveMedicalCare();
        }
      },
      error: (error) => {
      }
    });
  }

  submit(sign: boolean) {
    if (this.isLoading)
      return;

    this.sign = sign;

    if (
      this.model.get('conclusionType').value == this.destinationTypeInternacao ||
      this.model.get('conclusionType').value == this.destinationTypeInternacaoEncaminhamento
    ) { this.openAIHModal(); }
    else
      this.saveMedicalCare();
  }

  saveMedicalCare() {
    let careLineValidation = this.checkCareLineValidation();
    if (this.model.invalid || careLineValidation) {
      this.model.get('firstChildGroup').get('listIdAllergies').markAsTouched();
      this.allergyError = this.model.get('firstChildGroup').get('listIdAllergies').invalid && this.model.get('firstChildGroup').get('listIdAllergies').touched;
      if (this.model.get('idFlow').invalid) {
        this.model.get('idFlow').markAsTouched();
        this.alertService.show('Erro', "Preencha para onde o paciente será encaminhado!", AlertType.error);
        return;
      }
      this.alertService.show('Erro', "Preencha todos os campos em vermelho!", AlertType.error);
      this.isLoading = false;
      return;
    }

    this.isLoading = true;

    if (this.model.get('bloodPressure').value) {
      let pa: string = this.model.get("bloodPressure").value.split("/")[0].split("");
      if (pa.length < 4) {
        this.alertService.show('Erro', "Preencha o valor da pressão arterial corretamente", AlertType.error);
        this.isLoading = false;
        return;
      }
      let value1 = pa.length == 4 ? pa[0] + pa[1] : pa[0] + pa[1] + pa[2];
      let value2 = pa.length == 4 ? pa[2] + pa[3] : pa.length == 5 ? pa[3] + pa[4] : pa[3] + pa[4] + pa[5];
      if (parseInt(value2) > parseInt(value1)) {
        this.alertService.show('Erro', "A pressão diastólica não pode ser maior do que a sistólica!", AlertType.error);
        this.isLoading = false;
        this.model.get("bloodPressure").setValue("");
        return;
      }
    }

    let medicalCareRequest = this.mapToRequest();
    if (medicalCareRequest.saturation < 0 || medicalCareRequest.saturation > 100) {
      this.alertService.show('Erro', "O valor da saturação deve ser de 0 a 100", AlertType.error);
      this.isLoading = false;
      return;
    }

    let destinationName: string;
    let missFlowObservation: boolean = false;
    let missFlowSadt: boolean = false;

    if (this.model.get('conclusionType').value == this.destinationTypeEncaminhamento ||
      this.model.get('conclusionType').value == this.destinationTypeAltaEncaminhamento ||
      this.model.get('conclusionType').value == this.destinationTypeInternacaoEncaminhamento) {

      let idFlow = this.model.get('flowSequence')['controls'][0].get('idFlow').value;
      destinationName = this.listFoward.find(l => l.idFlow == idFlow)?.locationName;

      let flowSequence = this.model.get('flowSequence')['controls'];
      if (!flowSequence || flowSequence.length < 1) {
        this.makeCallPost(this.mapMedicalCareReportRequest());
        return;
      }

      this.listFlow = [];
      flowSequence.forEach((x, i) => {
        let flow: FlowStruct = this.listAvailableFlows[i].find(flow => ((flow.idFlow == x.get('idFlow').value) || (flow.idFlow == this.model.get('idFlow').value)));
        if (!flow) {
          this.alertService.show("Error inesperado", "Preencha corretamente os destinos do paciente", AlertType.error);
          this.isLoading = false;
          return;
        }
        flow.order = i;
        this.listFlow.push(flow);
      });

      if (((this.listPrescription && this.listPrescription.length > 0) || (this.listPrescriptionMedicatedSolution && this.listPrescriptionMedicatedSolution.length > 0)) 
          && !this.listFlow.find(x => x.idDestinationModule == MenuModuleEnum.observation))
        missFlowObservation = true;
      if ((this.listSADT && this.listSADT.length > 0) && !this.listFlow.find(x => x.idDestinationModule == MenuModuleEnum.sadt))
        missFlowSadt = true;

      if (missFlowObservation || missFlowSadt)
        this.openAlertFlowSequence(missFlowObservation, missFlowSadt);
      else
        this.makeCallPost(this.mapMedicalCareReportRequest());

    } else {
      destinationName = null;
      if (((this.listPrescription && this.listPrescription.length > 0) 
          || (this.listPrescriptionMedicatedSolution && this.listPrescriptionMedicatedSolution.length > 0))
          && this.model.get('conclusionType').value != this.destinationTypeManter)
        missFlowObservation = true;
      if (this.listSADT && this.listSADT.length > 0)
        missFlowSadt = true;

      if (missFlowObservation || missFlowSadt)
        this.openAlertFlowSequence(missFlowObservation, missFlowSadt);
      else
        this.makeCallPost(this.mapMedicalCareReportRequest());
    }
  }

  openAlertFlowSequence(missFlowObservation: boolean, missFlowSadt: boolean) {
    let descriptionModal = ``;
    if (missFlowObservation && missFlowSadt)
      descriptionModal = `Você prescreveu exame e medicamento interno, mas não direcionou o paciente para uma sala, setor ou serviço dos módulos 'Observação' ou 'SADT'. Quer concluir o atendimento mesmo assim?`;
    else if (missFlowObservation)
      descriptionModal = `Você prescreveu um medicamento interno, mas não direcionou o paciente para uma sala, setor ou serviço do módulo 'Observação'. Quer concluir o atendimento mesmo assim?`;
    else if (missFlowSadt)
      descriptionModal = `Você prescreveu um exame interno, mas não direcionou o paciente para uma sala, setor ou serviço do módulo 'SADT'. Quer concluir o atendimento mesmo assim?`;
    
    const flowDialogRef = this.dialog.open(AlertModalComponent, {
      disableClose: true,
      data: {
        description: descriptionModal,
        isTwoButtonsModal: true,
        hasImage: true,
        textButtonConfirm: `Sim, concluir`,
        textButtonCancel: `Voltar`,
        
      },
    });
    flowDialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result && (result.confirm != null && !result.confirm)) {
            this.isLoading = false;
            return;
        } else {
          this.makeCallPost(this.mapMedicalCareReportRequest());
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  async makeCallPost(medicalCareReportRequest: MedicalCareReportRequest) {
    let saveCompletedMedicalRecord: SaveCompletedMedicalRecordRequest = new SaveCompletedMedicalRecordRequest();
    saveCompletedMedicalRecord.medicalCareReportRequest = medicalCareReportRequest;
    saveCompletedMedicalRecord.medicalCareReportRequest.isICPBrasilSigned = this.isIcpBrailSigned;
    saveCompletedMedicalRecord.medicalCareReportRequest.certificatePin = this.certificatePin;
    saveCompletedMedicalRecord.medicalCareReportRequest.isIcpBrailTokenSigned = this.isIcpBrailTokenSigned;

    if (this.newEmail && this.newEmail != null)
      saveCompletedMedicalRecord.medicalCareReportRequest.email = this.newEmail;
    else
      saveCompletedMedicalRecord.medicalCareReportRequest.email = this.patientEmail;

    let medicalCareRequest = this.mapToRequest();

    if (this.sign) {
      const dialogRef = this.dialog.open(SignatureCredentialsModalComponent, {
        disableClose: this.requireSignature,
      });

      dialogRef.afterClosed().subscribe({
        next: (result) => {
          if (!result) {
            this.isLoading = false;
            return;
          }

          if (result.sign) {
            medicalCareRequest.idSignatureProvider = result.idSignatureProvider;
            medicalCareRequest.signatureAuthentication = JSON.stringify(result.signatureAuthentication);
          }
          else {
            medicalCareRequest.idSignatureProvider = null;
            medicalCareRequest.signatureAuthentication = null;
          }

          let flowSequence = this.model.get('flowSequence')['controls'];

          medicalCareRequest.idRoomAttendance = this.idRoom;
          medicalCareRequest.idQueueUsed = this.idQueue;
          medicalCareRequest.listIdBypass = this.listIdBypass;

          //Adicionando linhas de cuidado ao request
          let episodeCareLineRequest = new EpisodeCareLineRequest();
          episodeCareLineRequest.listIdCareLine = this.listNewEpisodeCareLine.map(x => x.idCareLine);
          episodeCareLineRequest.idEpisode = this.selectedEpisode;
          episodeCareLineRequest.phoneNumber = this.countryCode + this.patientPhoneNumber;
          saveCompletedMedicalRecord.episodeCareLineRequest = episodeCareLineRequest;

          if (!this.listSADT || this.listSADT.length == 0) {
            let index: number = medicalCareRequest.listIdBypass.indexOf(BypassEnum.Exames);
            medicalCareRequest.listIdBypass.splice(index, 1);
          }

          saveCompletedMedicalRecord.billingDataRequest = this.mapToBillingDataRequest();
          saveCompletedMedicalRecord.conclusionType = this.model.get('conclusionType').value;

          if (this.destinationTypeInternacao == this.model.get('conclusionType').value) {
            medicalCareRequest.idStatusEpisode = EpisodeStatusEnum.FinalizadoInternar;
            medicalCareRequest.conclusionNote = this.model.get("conclusionNote").value;
            medicalCareRequest.administrativeRelease = true;
          }
          else if (this.model.get('conclusionType').value == this.destinationTypeEncaminhamento ||
            this.model.get('conclusionType').value == this.destinationTypeAltaEncaminhamento ||
            this.model.get('conclusionType').value == this.destinationTypeInternacaoEncaminhamento) {

            if (flowSequence && flowSequence.length > 1) {
              let flowSequenceRequest: FlowSequenceRequest = new FlowSequenceRequest();
              flowSequenceRequest.listFlow = [];
              flowSequence.forEach((x, i) => {
                let flow: FlowStruct = this.listAvailableFlows[i].find(flow => flow.idFlow == x.get('idFlow').value);
                if (!flow) {
                  this.alertService.show("Error inesperado", "Preencha corretamente os destinos do paciente", AlertType.error);
                  this.isLoading = false;
                  return;
                }
                flow.order = i;
                flowSequenceRequest.listFlow.push(flow);
              });
              flowSequenceRequest.idEpisode = this.selectedEpisode;
              saveCompletedMedicalRecord.flowSequence = flowSequenceRequest;
            }

            if (this.destinationTypeAltaEncaminhamento == this.model.get('conclusionType').value) {
              medicalCareRequest.administrativeRelease = true;
              medicalCareRequest.idStatusEpisode = EpisodeStatusEnum.FinalizadoAltaAdministrativa;
            }
            else if (this.destinationTypeInternacaoEncaminhamento == this.model.get('conclusionType').value) {
              medicalCareRequest.administrativeRelease = true;
              medicalCareRequest.idStatusEpisode = EpisodeStatusEnum.FinalizadoInternar;
            }

            medicalCareRequest.conclusionNote = this.model.get("conclusionNote").value;
            saveCompletedMedicalRecord.selectedLocationRequest = this.forwardPatient();
          }
          else {
            medicalCareRequest.administrativeRelease = false;
            if (this.idFlowCompleted) {
              let sequenceUpdate: FlowSequenceUpdateRequest = new FlowSequenceUpdateRequest();
              sequenceUpdate.idEpisode = this.selectedEpisode;
              sequenceUpdate.idFlow = this.idFlowCompleted;
              saveCompletedMedicalRecord.flowSequenceUpdateRequest = sequenceUpdate;
            }
            if (this.model.get('conclusionType').value == this.destinationTypeAlta || this.model.get('conclusionType').value == this.destinationTypeObito) {
              let episodeStatus = this.model.get('conclusionType').value == this.destinationTypeAlta ? this.episodeStatusAltaEnum : this.episodeStatusObitoEnum;
              saveCompletedMedicalRecord.episodeStatusRequest = this.startConclusion(episodeStatus);
              let postPresenceReportRequest = new PostPresenceReportRequest()
              postPresenceReportRequest.idStatus = this.episodeStatusObitoEnum;
              postPresenceReportRequest.idEpisode = this.selectedEpisode;
              saveCompletedMedicalRecord.postPresenceReportRequest = postPresenceReportRequest;
            }
          }
          saveCompletedMedicalRecord.medicalRecordRequest = medicalCareRequest;
          if (!this.patientHasPhone)
            saveCompletedMedicalRecord.medicalRecordRequest.phone = this.patientCellPhone;

          if (this.showTissGuideButtonValidation) {
            let guide = this.childComponent.getGuidRequest();
            if (guide)
              saveCompletedMedicalRecord.guideRequest = guide;
          }

          this.isAttended = true;

          this.medicalRecordService.postMedicalRecord(saveCompletedMedicalRecord).subscribe({
            next: (response) => {
              if (response.isError && response.errorCode == 5) {
                this.alertService.show("Error", response.errorDescription, AlertType.error);
                this.isLoading = false;
                return;
              }
              else if (response.isError) {
                let alertType = response.errorCode === -1 ? AlertType.warning : AlertType.error;
                this.alertService.show("Error", response.errorDescription, alertType);
                this.isLoading = false;
                return;
              }

              if (response.errorCode == 11)
                this.alertService.show("Erro", response.errorDescription, AlertType.error);

              if (response.allPrivateBillingGuideReportStruct) {
                this.allPrivateBillingGuideReportStruct = new AllPrivateBillingGuideReportStruct();
                this.allPrivateBillingGuideReportStruct = response.allPrivateBillingGuideReportStruct;
              }

              if (this.hasReport(response))
                this.openReportViewSelectModal(response);
              else {
                if (this.isBedManagement) {
                  if (this.isAihRedirect) {
                    let isPending: boolean = (AihStatusEnum.pendente == response.idAihStatus);
                    this.router.navigate(['/medic/aih-editor', { idSector: this.idSector, idRoom: this.idRoom, idAihGroup: response.idAihGroup, isPending: isPending, idEpisode: this.selectedEpisode, isBedManagement: this.isBedManagement }])
                  }
                  else
                    this.router.navigate(['/medic/medic-bed-management', { idSector: this.idSector, idRoom: this.idRoom, idService: this.idService }])
                }

                else if (this.isAihRedirect) {
                  let isPending: boolean = (AihStatusEnum.pendente == response.idAihStatus);
                  this.router.navigate(['/medic/aih-editor', { idSector: this.idSector, idRoom: this.idRoom, idAihGroup: response.idAihGroup, isPending: isPending, idEpisode: this.selectedEpisode, isMedic: true }])
                }
                else
                  this.router.navigate(['/medic/medic-patient-list', { idSector: this.idSector, idRoom: this.idRoom, idService: this.idService }])
              }
            },
            error: (error) => {
              this.alertService.show("Error inesperado", error, AlertType.error);
              this.isLoading = false;
              return;
            }
          });
        }
      });
    }
    else {
      medicalCareRequest.idSignatureProvider = null;
      medicalCareRequest.signatureAuthentication = null;

      let flowSequence = this.model.get('flowSequence')['controls'];

      medicalCareRequest.idRoomAttendance = this.idRoom;
      medicalCareRequest.idQueueUsed = this.idQueue;
      medicalCareRequest.listIdBypass = this.listIdBypass;

      //Adicionando linhas de cuidado ao request
      let episodeCareLineRequest = new EpisodeCareLineRequest();
      episodeCareLineRequest.listIdCareLine = this.listNewEpisodeCareLine.map(x => x.idCareLine);
      episodeCareLineRequest.idEpisode = this.selectedEpisode;
      episodeCareLineRequest.phoneNumber = this.countryCode + this.patientPhoneNumber;
      saveCompletedMedicalRecord.episodeCareLineRequest = episodeCareLineRequest;

      if (!this.listSADT || this.listSADT.length == 0) {
        let index: number = medicalCareRequest.listIdBypass.indexOf(BypassEnum.Exames);
        medicalCareRequest.listIdBypass.splice(index, 1);
      }

      saveCompletedMedicalRecord.billingDataRequest = this.mapToBillingDataRequest();
      saveCompletedMedicalRecord.conclusionType = this.model.get('conclusionType').value;
      if (this.destinationTypeInternacao == this.model.get('conclusionType').value) {
        medicalCareRequest.idStatusEpisode = EpisodeStatusEnum.FinalizadoInternar;
        medicalCareRequest.conclusionNote = this.model.get("conclusionNote").value;
        medicalCareRequest.administrativeRelease = true;
      }
      else if (this.model.get('conclusionType').value == this.destinationTypeEncaminhamento ||
        this.model.get('conclusionType').value == this.destinationTypeAltaEncaminhamento ||
        this.model.get('conclusionType').value == this.destinationTypeInternacaoEncaminhamento) {

        if (flowSequence && flowSequence.length > 1) {
          saveCompletedMedicalRecord.flowSequence = new FlowSequenceRequest();
          saveCompletedMedicalRecord.flowSequence.idEpisode = this.selectedEpisode;
          saveCompletedMedicalRecord.flowSequence.listFlow = this.listFlow;
        }

        if (this.destinationTypeAltaEncaminhamento == this.model.get('conclusionType').value) {
          medicalCareRequest.administrativeRelease = true;
          medicalCareRequest.idStatusEpisode = EpisodeStatusEnum.FinalizadoAltaAdministrativa;
        }
        else if (this.destinationTypeInternacaoEncaminhamento == this.model.get('conclusionType').value) {
          medicalCareRequest.administrativeRelease = true;
          medicalCareRequest.idStatusEpisode = EpisodeStatusEnum.FinalizadoInternar;
        }

        medicalCareRequest.conclusionNote = this.model.get("conclusionNote").value;
        saveCompletedMedicalRecord.selectedLocationRequest = this.forwardPatient();
      }
      else {
        medicalCareRequest.administrativeRelease = false;
        if (this.idFlowCompleted) {
          let sequenceUpdate: FlowSequenceUpdateRequest = new FlowSequenceUpdateRequest();
          sequenceUpdate.idEpisode = this.selectedEpisode;
          sequenceUpdate.idFlow = this.idFlowCompleted;
          saveCompletedMedicalRecord.flowSequenceUpdateRequest = sequenceUpdate;
        }
        if (this.model.get('conclusionType').value == this.destinationTypeAlta || this.model.get('conclusionType').value == this.destinationTypeObito) {
          let episodeStatus = this.model.get('conclusionType').value == this.destinationTypeAlta ? this.episodeStatusAltaEnum : this.episodeStatusObitoEnum;
          saveCompletedMedicalRecord.episodeStatusRequest = this.startConclusion(episodeStatus);
          let postPresenceReportRequest = new PostPresenceReportRequest()
          postPresenceReportRequest.idStatus = this.episodeStatusObitoEnum;
          postPresenceReportRequest.idEpisode = this.selectedEpisode;
          saveCompletedMedicalRecord.postPresenceReportRequest = postPresenceReportRequest;
        }
      }
      saveCompletedMedicalRecord.medicalRecordRequest = medicalCareRequest;
      if (!this.patientHasPhone)
        saveCompletedMedicalRecord.medicalRecordRequest.phone = this.patientCellPhone;

      this.isAttended = true;

      if (this.showTissGuideButtonValidation) {
        let guide = this.childComponent.getGuidRequest();
        if (guide)
          saveCompletedMedicalRecord.guideRequest = guide;
      }

      this.medicalRecordService.postMedicalRecord(saveCompletedMedicalRecord).subscribe({
        next: (response) => {
          if (response.isError) {
            let alertType = response.errorCode === -1 ? AlertType.warning : AlertType.error;
            this.alertService.show("Error", response.errorDescription, alertType);
            this.isLoading = false;
            return;
          }

          if (response.allPrivateBillingGuideReportStruct) {
            this.allPrivateBillingGuideReportStruct = new AllPrivateBillingGuideReportStruct();
            this.allPrivateBillingGuideReportStruct = response.allPrivateBillingGuideReportStruct;
          }

          if (this.hasReport(response))
            this.openReportViewSelectModal(response);
          else
            this.redirectAfterSaveMedicalCare(response);
        },
        error: (error) => {
          this.alertService.show("Error inesperado", error, AlertType.error);
          this.isLoading = false;
          return;
        }
      });
    }
  }

  redirectAfterSaveMedicalCare(postMedicalRecordResponse: NewReportResponse) {
    if (this.isBedManagement && this.isAihRedirect) {
      let isPending: boolean = (AihStatusEnum.pendente == postMedicalRecordResponse.idAihStatus);
      this.router.navigate(['/medic/aih-editor', { idSector: this.idSector, idRoom: this.idRoom, idAihGroup: postMedicalRecordResponse.idAihGroup, isPending: isPending, idEpisode: this.selectedEpisode, isBedManagement: this.isBedManagement }]);
    }

    else if (this.isBedManagement)
      this.router.navigate(['/medic/medic-bed-management', { idSector: this.idSector, idRoom: this.idRoom, idService: this.idService }]);

    else if (this.isAihRedirect) {
      let isPending: boolean = (AihStatusEnum.pendente == postMedicalRecordResponse.idAihStatus);
      this.router.navigate(['/medic/aih-editor', { idSector: this.idSector, idRoom: this.idRoom, idAihGroup: postMedicalRecordResponse.idAihGroup, isPending: isPending, idEpisode: this.selectedEpisode, isMedic: true }]);
    }

    else
      this.router.navigate(['/medic/medic-patient-list', { idSector: this.idSector, idRoom: this.idRoom, idService: this.idService }]);
  }

  mapToRequest(): MedicalCareRequest {
    let medicalCareRequest: MedicalCareRequest = new MedicalCareRequest();
    medicalCareRequest.medicalPrescription = this.listPrescription;
    medicalCareRequest.medicatedSolutionPrescription = this.listPrescriptionMedicatedSolution;
    medicalCareRequest.listExternalPrescription = this.listExternalPrescription;
    medicalCareRequest.medicalProcedure = this.listMedicalProcedure;
    medicalCareRequest.medicalCareSadt = this.listSADT;
    medicalCareRequest.idEpisode = this.selectedEpisode;
    medicalCareRequest.medicalNotes = this.model.get('medicalNotes').value;
    medicalCareRequest.physicalExamination = this.model.get('physicalExamination').value;
    medicalCareRequest.therapeuticPlan = this.model.get('therapeuticPlan').value;
    medicalCareRequest.diagnosedHypothesis = this.model.get('diagnosedHypothesis').value;
    let temperature = this.model.get('temperature').value ? this.model.get('temperature').value.toString().split("") : null;
    medicalCareRequest.temperature = temperature ? parseFloat(temperature[0] + temperature[1] + "." + temperature[2]) : null;
    medicalCareRequest.respiratoryFrequency = parseInt(this.model.get('respiratoryFrequency').value);
    medicalCareRequest.heartRate = parseInt(this.model.get('heartRate').value);
    medicalCareRequest.glucose = parseInt(this.model.get('glucose').value);

    if (this.model.get('idCareLinePriority').value) {
      medicalCareRequest.careLinePriorityStatusStruct.idCareLinePriority = parseInt(this.model.get('idCareLinePriority').value);
      medicalCareRequest.careLinePriorityStatusStruct.idCareLinePriorityStatus = this.careLinePriorityStruct.idCareLinePriorityStatus;
    }

    if (this.model.get('idSuspicion').value) {
      medicalCareRequest.suspicionStatusStruct.idSuspicion = parseInt(this.model.get('idSuspicion').value);
      medicalCareRequest.suspicionStatusStruct.isSuspicionEvaluated = this.triage && this.triage.isSuspicionEvaluated ? this.triage.isSuspicionEvaluated : false;
      medicalCareRequest.suspicionStatusStruct.listSuspicion = this.listSuspicion;
    }

    let value1;
    let value2;
    if (this.model.get('bloodPressure').value) {
      let pa: string = this.model.get("bloodPressure").value.split("/")[0].split("");
      value1 = pa.length == 4 ? pa[0] + pa[1] : pa[0] + pa[1] + pa[2];
      value2 = pa.length == 4 ? pa[2] + pa[3] : pa.length == 5 ? pa[3] + pa[4] : pa[3] + pa[4] + pa[5];
    }

    medicalCareRequest.bloodPressureDiastole = value2;
    medicalCareRequest.bloodPressureSystole = value1;

    medicalCareRequest.saturation = parseInt(this.model.get('saturation').value);
    medicalCareRequest.cid = this.listCid.map(a => a.idCid);
    medicalCareRequest.isCidOnSickNote = this.model.get('isCidOnSickNote').value;
    medicalCareRequest.listAllergy = this.allergiesToSave.map(a => new AllergyStruct(a, this.selectedEpisode));

    medicalCareRequest.datetimeStartAttendance = this.datetimeStartAttendance;
    medicalCareRequest.datetimeEndIsolation = this.model.get('datetimeEndIsolation').value?.toISOString();
    medicalCareRequest.sickDaysOffAmount = this.model.get('sickDaysOffAmount').value;
    medicalCareRequest.remoteAttendance = this.remoteAttendance;

    medicalCareRequest.aihStruct = this.aihModalResult; //TODO: MAPEAR TIPO CORRETO DEPOIS

    medicalCareRequest.listMemedCompletePrescriptionStruct = this.listMemedCompletePrescriptionStruct;
    medicalCareRequest.userTokenMemed = this.userTokenMemed;
    medicalCareRequest.hasNewStandardPrescription = this.hasNewStandardPrescription;

    return medicalCareRequest;
  }

  mapToBillingDataRequest(): BillingDataRequest {
    let billingDataRequest: BillingDataRequest = new BillingDataRequest();
    billingDataRequest.listCid = this.listCid.map(x => x.code);
    billingDataRequest.idModule = MenuModuleEnum.medic;
    billingDataRequest.idEpisode = this.selectedEpisode;
    billingDataRequest.listIdProcedure = this.listProcedureSigtap.map(a => a.idProcedureSigtap);
    return billingDataRequest;
  }

  getLookupMedicalCare() {
    this.lookupMedicalCareService.getLookupMedicalCare().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.lookupMedicalCareResponse = response;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getListProcedures() {
    this.procedureService.listAllProcedure().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listProcedure = response.listProcedure;
        this.hasProcedure = response.hasProcedure;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getDate() {
    this.clockService.getDate().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.datetimeStartAttendance = response.date;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getLookupMedicine() {
    this.medicineService.listAllMedicine(true).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listMedicine = response;
        if (!this.isBedManagement)
          this.getMedicConfigBySector();
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openEpisodeModal(idEpisode: number) {
    const dialogRef = this.dialog.open(EpisodeModalComponent, {
      data: {
        idEpisode: idEpisode,
        lookupMedicalCareResponse: this.lookupMedicalCareResponse,
        listMedicine: this.listMedicine,
        listExam: this.listLookupExam,
        room: { idSector: this.idSector, idRoom: this.idRoom }
      },
    });
  }

  startConclusion(statusEpisode: EpisodeStatusEnum): EpisodeStatusRequest {
    let episodeStatusRequest: EpisodeStatusRequest = new EpisodeStatusRequest();
    episodeStatusRequest.conclusionNote = this.model.get("conclusionNote").value;
    episodeStatusRequest.idStatus = statusEpisode.valueOf();
    episodeStatusRequest.isBedManagement = this.isBedManagement;
    return episodeStatusRequest;
  }

  openCancelConfirmModal() {
    if (this.isLoadingCancelar)
      return;

    this.isLoadingCancelar = true;

    if (this.readonly) {
      this.router.navigate(['/medic/medic-patient-list', { idSector: this.idSector, idRoom: this.idRoom }]);
      return;
    }

    if (this.isBedManagement) {
      this.router.navigate(['/medic/medic-bed-management', { idSector: this.idSector, idRoom: this.idRoom, idService: this.idService }]);
      return;
    }

    let guideWasUpdate = false;
    if (this.showTissGuideButtonValidation) {
      let guideRequest = this.childComponent.getGuidRequest();
      if ((guideRequest.admissionGuideStruct ||
        guideRequest.appointmentGuideStruct ||
        guideRequest.feesGuideStruct ||
        guideRequest.spSadtGuideStruct)) {
        guideWasUpdate = true;
      }
    }

    const dialogRef = this.dialog.open(CancelConfirmModalComponent, {
      data: {
        idRoom: this.idRoom,
        idSector: this.idSector,
        isBedManagement: this.isBedManagement,
        guideWasUpdate
      },
    });
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result && result.confirm) {
          this.updateStatusQueueService.updateQueueToOldStatus(this.idLastQueue, this.selectedEpisode).subscribe({
            next: (response) => {
              if (response.isError) {
                this.alertService.show("Error", response.errorDescription, AlertType.error);
                this.isLoading = false;
                this.isLoadingCancelar = false;
                return;
              }

              if (this.socket)
                this.socket.emit('medicLeft', { idRoom: this.idRoom });

              this.router.navigate(['/medic/medic-patient-list', { idSector: this.idSector, idRoom: this.idRoom }])
            },
            error: (error) => {
              console.log(error);
              this.alertService.show('Erro inesperado', error, AlertType.error);
            }
          });
        }
        else
          this.isLoadingCancelar = false;
      },
      error: (error) => {
        console.log(error);
      }
    });
  }


  openProcedureModal(procedure: MedicalProcedureModel, readOnly: boolean, index: number) {
    const dialogRef = this.dialog.open(ProcedureModalComponent, {
      data: {
        medicalProcedure: procedure,
        readOnly: readOnly,
        procedureList: this.listProcedure,
        idEpisode: this.selectedEpisode,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result) {
          if (index >= 0)
            this.listMedicalProcedure.splice(index, 1);

          this.listMedicalProcedure.push(result.newMedicalProcedure);
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  openCareLineModal(listCareLine: CareLineModel[]) {
    const dialogRef = this.dialog.open(CareLineModalComponent, {
      data: {
        idEpisode: this.selectedEpisode,
        listCareLine: listCareLine,
        listPastEpisodeCareLine: this.listPastEpisodeCareLine,
        listCurrentEpisodeCareLine: this.listCurrentEpisodeCareLine,
        patientPhoneNumber: this.patientPhoneNumber,
        countryCode: this.countryCode,
        masks: this.masks,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.newEpisodeCareLines && result.patientPhoneNumber) {
          this.listNewEpisodeCareLine = this.listNewEpisodeCareLine.concat(result.newEpisodeCareLines);
          this.listCurrentEpisodeCareLine = this.listCurrentEpisodeCareLine.concat(result.newEpisodeCareLines);
          this.countryCode = result.countryCode;
          this.patientPhoneNumber = result.patientPhoneNumber;
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  removeCareLine(index: number, idCareLine: number) {
    this.listCurrentEpisodeCareLine.splice(index, 1);
    let newIndex = this.listNewEpisodeCareLine.findIndex(x => x.idCareLine == idCareLine);
    if (newIndex !== -1)
      this.listNewEpisodeCareLine.splice(newIndex, 1);
  }


  openPrecriptionModalWithMedicine(index: number, medicalPrescription: ReadMedicalPrescriptionStruct, isUpdate: boolean) {
    let prescription = new StandardPrescriptionStruct();
    prescription.readMedicalPrescriptionStruct = medicalPrescription;
    this.openPrescriptionModal(index, prescription, isUpdate, false);
  }

  openPrecriptionModalWithMedicatedSolution(index: number, medicalPrescription: MedicatedSolutionStruct, isUpdate: boolean) {
    let prescription = new StandardPrescriptionStruct();
    prescription.medicatedSolutionStruct = medicalPrescription;
    this.openPrescriptionModal(index, prescription, isUpdate, true);
  }

  openPrescriptionModal(index: number, prescription: StandardPrescriptionStruct, isUpdate: boolean, isMedicatedSolution: boolean) {
    if (isUpdate)
      this.openUpdatePrescriptionModal(index, prescription, isMedicatedSolution);
    else
      this.openDefaultPrescriptionModal(prescription);
  }

  openUpdatePrescriptionModal(index: number, prescription: StandardPrescriptionStruct, isMedicatedSolution: boolean) {
    const dialogRef = this.dialog.open(PrescriptionModalComponent, {
      data: {
        prescription,
        idMedicalCare: this.idMedicalCare,
        lookupMedicalCareResponse: this.lookupMedicalCareResponse,
        listMedicine: this.listMedicine,
        medicConfig: this.medicConfigSector,
        listConfigIdMedicine: this.listConfigIdMedicine,
        listAllergy: this.allergiesToSave,
        isMedicatedSolution: isMedicatedSolution,
        hasNewStandardPrescription: this.hasNewStandardPrescription,
        blockAllergicPrescription: this.blockAllergicPrescription,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.medicalPrescription && result.medicalPrescription.readMedicalPrescriptionStruct) {
          if (index >= 0)
            this.listPrescription.splice(index, 1);

          this.listPrescription.push(result.medicalPrescription.readMedicalPrescriptionStruct);
          this.favoriteStandardMedicine = false;
        }
        else if (result && result.medicalPrescription && result.medicalPrescription.medicatedSolutionStruct) {
          if (index >= 0)
            this.listPrescriptionMedicatedSolution.splice(index, 1);

          this.listPrescriptionMedicatedSolution.push(result.medicalPrescription.medicatedSolutionStruct);
          this.favoriteStandardMedicine = false;
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  openDefaultPrescriptionModal(prescription: StandardPrescriptionStruct) {
    const dialogRef = this.dialog.open(PrescriptionModalComponent, {
      data: {
        prescription,
        readOnly: false,
        lookupMedicalCareResponse: this.lookupMedicalCareResponse,
        listMedicine: this.listMedicine,
        medicConfig: this.medicConfigSector,
        listConfigIdMedicine: this.listConfigIdMedicine,
        isFastTrack: this.isFastTrack,
        listAllergy: this.allergiesToSave,
        isMedicatedSolution: null,
        hasNewStandardPrescription: true,
        blockAllergicPrescription: this.blockAllergicPrescription,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.medicalPrescription && result.medicalPrescription.readMedicalPrescriptionStruct) {
          this.listPrescription.push(result.medicalPrescription.readMedicalPrescriptionStruct);
          if (result.bypassPrescription && !this.listIdBypass.includes(BypassEnum.Prescricoes))
            this.listIdBypass.push(BypassEnum.Prescricoes);

          this.favoriteStandardMedicine = false;
        }
        else if (result && result.medicalPrescription && result.medicalPrescription.medicatedSolutionStruct) {
          this.listPrescriptionMedicatedSolution.push(result.medicalPrescription.medicatedSolutionStruct);
          if (result.bypassPrescription && !this.listIdBypass.includes(BypassEnum.Prescricoes))
            this.listIdBypass.push(BypassEnum.Prescricoes);

          this.favoriteStandardMedicine = false;
        }

        this.hasNewStandardPrescription = true;
        this.isFirstLoading = false;
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  openDefaultExternalPrescriptionModal(prescription: ReadExternalPrescriptionStruct, readOnly: boolean, index: number) {
    const dialogRef = this.dialog.open(ExternalPrescriptionModalComponent, {
      data: {
        prescription,
        readOnly,
        lookupMedicalCareResponse: this.lookupMedicalCareResponse,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result) {
          if (index >= 0)
            this.listExternalPrescription.splice(index, 1);

          this.listExternalPrescription.push(result.newExternalPrescription);
          this.favoriteNonStandardMedicine = false;

        }
        this.isFirstLoading = false;
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  openSadtModal(sadt: MedicalCareSadtStruct, readOnly: boolean, index: number) {
    const dialogRef = this.dialog.open(SadtModalComponent, {
      data: {
        sadt,
        readOnly,
        listPlace: this.lookupMedicalCareResponse.listPlace,
        listSadtSchedule: this.lookupMedicalCareResponse.listSadtSchedule,
        listExamType: this.listExamType,
        listIdExam: this.listIdExam,
        isAllExams: this.isAllExams,
        isMemedPrescription: this.isMemedPrescription,
        isDisableSession: this.isDisableSession,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result) {
          if (index >= 0)
            this.listSADT.splice(index, 1);

          this.listSADT.push(result.newSadt);
          if (this.listSADT && this.listSADT.length > 0)
            this.blockBypassExam = true;

          this.favoriteExameRequest = false;
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  deletePrescription(index: number) {
    if (this.readonly) {
      if (this.isLoading)
        return;

      this.isLoading = true;
      this.medicalPrescriptionService.deletePresciption(this.listPrescription[index].idMedicalPrescription).subscribe({
        next: (response) => {
          this.isLoading = false;
          if (response.isError) {
            this.alertService.show("Erro", response.errorDescription, AlertType.error);
            return;
          }

          this.alertService.show("Sucesso", "Prescrição deletada com sucesso", AlertType.success);
          this.listPrescription.splice(index, 1);
        },
        error: (error) => {
          console.log(error);
        }
      });
    }
    else {
      this.listPrescription.splice(index, 1);
      this.favoriteStandardMedicine = false;
    }
  }

  deletePrescriptionMedicatedSolution(index: number) {
    if (this.readonly) {
      if (this.isLoading)
        return;

      this.isLoading = true;
      let listIdMedicalPrescription: number[] = this.listPrescriptionMedicatedSolution[index].listMedicine.map(x => x.idMedicalPrescription);
      this.medicalPrescriptionService.deleteMedicatedSolution(listIdMedicalPrescription).subscribe({
        next: (response) => {
          this.isLoading = false;
          if (response.isError) {
            this.alertService.show("Erro", response.errorDescription, AlertType.error);
            return;
          }

          this.alertService.show("Sucesso", "Prescrição de solução medicamentosa deletada com sucesso", AlertType.success);
          this.listPrescriptionMedicatedSolution.splice(index, 1);
        },
        error: (error) => {
          console.log(error);
        }
      });
    }
    else {
      this.listPrescriptionMedicatedSolution.splice(index, 1);
      this.favoriteStandardMedicine = false;
    }
  }

  deleteExternalPrescription(index: number) {
    if (this.readonly) {
      if (this.isLoading)
        return;

      this.isLoading = true;
      this.medicalPrescriptionService.deletePresciption(this.listExternalPrescription[index].idExternalPrescription).subscribe({
        next: (response) => {
          this.isLoading = false;
          if (response.isError) {
            this.alertService.show("Erro", response.errorDescription, AlertType.error);
            return;
          }
          this.alertService.show("Sucesso", "Prescrição deletada com sucesso", AlertType.success);
          this.listExternalPrescription.splice(index, 1);
        },
        error: (error) => {
          console.log(error);
        }
      });
    }
    else {
      this.listExternalPrescription.splice(index, 1);
      this.favoriteNonStandardMedicine = false;
    }
  }

  deleteSadt(index: number) {
    this.listSADT.splice(index, 1);

    if (!this.listSADT || this.listSADT.length == 0)
      this.blockBypassExam = false;

    this.favoriteExameRequest = false;

    if (!this.hasInternalExamRequest()) {

      let listFlow = this.retrieveFlowSequence();
      let removalIndex: number = null;
      
      if (listFlow) {
        for (let index = 0; index < listFlow.length; index++) {
          const flow = listFlow[index];
          if (flow.idDestinationModule == MenuModuleEnum.sadt) {
            removalIndex = index;
            break;
          }
        }

        if (removalIndex !== null) {
          let i = this.model.get('flowSequence')['controls'].length - 1
          while (i >= removalIndex) {
            this.removeFlow(i);
            i -= 1;
          }
          if (removalIndex === 0) {
            this.model.get('idFlow').setValue(null);
            this.model.get('idFlow').updateValueAndValidity();
          }
          this.alertService.show('Aviso!', 'Encaminhamentos para o módulo de SADT foram removidos', AlertType.warning);
        }
      }
    }
  }

  deleteProcedure(index: number) {
    this.listMedicalProcedure.splice(index, 1);
  }

  populateFowardSelect() {
    let fowardRequest = new FowardRequest();
    fowardRequest.idEpisode = this.selectedEpisode;
    fowardRequest.idRoom = parseInt(this.activatedRoute.snapshot.paramMap.get('idRoom'));
    fowardRequest.isBedManagement = this.isBedManagement;
    this.fowardService.listDestination(fowardRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        if (response.isAutomaticFoward) {
          this.model.get('idFlow').disable();
          let automaticFoward: LocationStruct = new LocationStruct();
          automaticFoward.idFlow = -1;
          automaticFoward.locationName = 'Automático';
          this.listFoward.push(automaticFoward);
          return;
        }

        if (this.isBedManagement && response.listFlow) {
          let listFlowFiltered = response.listFlow.filter(x => (x.idDestinationModule == MenuModuleEnum.sadt && x.idOriginModule == MenuModuleEnum.observation) || (x.idDestinationModule == MenuModuleEnum.observation && x.idOriginModule == MenuModuleEnum.sadt));
          this.listAvailableFlows.push(listFlowFiltered);
        }
        else
          this.listAvailableFlows.push(response.listFlow);

        this.addNext();
        if (response.flowSequence && response.flowSequence.length > 0)
          this.idFlowCompleted = response.flowSequence[0].idFlow;

        this.idLastQueue = response.idLastQueue;
        this.listFoward = response.location;
        this.isLoadingFlows = false;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateFlowSequenceSelect() {
    let fowardRequest: FowardRequest = new FowardRequest();
    fowardRequest.idEpisode = this.selectedEpisode;
    fowardRequest.idRoom = this.idRoom;

    this.fowardService.listDestination(fowardRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listAvailableFlows.push(response.listFlow);
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  forwardPatient(): SelectedLocationRequest {
    let selectedLocationRequest = new SelectedLocationRequest();
    selectedLocationRequest.idEpisode = this.selectedEpisode;
    selectedLocationRequest.idQueue = this.idLastQueue;
    selectedLocationRequest.isBedManagement = this.isBedManagement;
    selectedLocationRequest.idParentService = this.idService;

    let isFlowSequence = this.model.get('flowSequence')['controls'][0].get('idFlow').value != undefined;
    if (isFlowSequence)
      selectedLocationRequest.idFlow = this.model.get('flowSequence')['controls'][0].get('idFlow').value;
    else
      selectedLocationRequest.idFlow = this.model.get('idFlow').value;

    selectedLocationRequest.idRoomOrigin = this.idRoom;

    if (this.idFlowCompleted)
      selectedLocationRequest.idFlowCompleted = this.idFlowCompleted;

    return selectedLocationRequest;
  }

  addItemAllergies(event: any) {
    this.allergies = event;
  }

  changeConclusionType(event: any) {
    this.destinationType = this.model.get('conclusionType').value;
    this.stringValidator = "";
    this.showFoward = false;
    this.changeButtonColor();

    if (this.destinationType == this.destinationTypeAltaEncaminhamento || this.destinationTypeInternacaoEncaminhamento == this.destinationType) {
      this.stringValidator = " e para onde será encaminhado";
      this.model.get('conclusionNote').setValidators([Validators.required]);
      this.model.get('idFlow').setValidators([Validators.required]);
      this.showFoward = true;
      this.model.get('conclusionNote').updateValueAndValidity();
      this.model.get('idFlow').updateValueAndValidity();

    }
    else if (this.destinationType == this.destinationTypeEncaminhamento) {
      this.stringValidator = " e para onde será encaminhado";
      this.model.get('conclusionNote').setValidators([]);
      this.model.get('idFlow').setValidators([Validators.required]);
      this.showFoward = true;
      this.model.get('conclusionNote').updateValueAndValidity();
      this.model.get('idFlow').updateValueAndValidity();
    }
    else if (this.destinationType == this.destinationTypeManter) {
      this.model.get('conclusionNote').setValidators([]);
      this.model.get('idFlow').setValidators([]);
      this.model.get('idFlow').setValue(null);
      this.model.get('conclusionNote').updateValueAndValidity();
      this.model.get('idFlow').updateValueAndValidity();
      this.resetFlowSequence();
    }
    else {
      this.model.get('conclusionNote').setValidators([Validators.required]);
      this.model.get('idFlow').setValidators([]);
      this.model.get('idFlow').setValue(null);
      this.model.get('conclusionNote').updateValueAndValidity();
      this.model.get('idFlow').updateValueAndValidity();
      this.resetFlowSequence();
    }

    if (!(this.medicConfigSector) ||
      !(this.medicConfigSector.listIdRequiredField) ||
      this.medicConfigSector.listIdRequiredField.findIndex(c => c == MedicRequiredFieldEnum.conclusion) == -1) {
      this.model.get('conclusionNote').setValidators([]);
      this.model.get('conclusionNote').updateValueAndValidity();
    }

    this.checkCareLineValidation();
  }

  checkCareLineValidation() {
    let careLineValidation = (this.careLinePriorityStruct != null &&
      this.careLinePriorityStruct.idCareLinePriorityStatus == CareLinePriorityStatusEnum.suspeita &&
      (
        this.destinationType == this.destinationTypeAlta ||
        this.destinationType == this.destinationTypeAltaEncaminhamento ||
        this.destinationType == this.destinationTypeObito ||
        this.medicalCare.length > 0
      ));

    if (careLineValidation) {
      this.model.get('idCareLinePriority').setErrors({ 'careLineValidation': true });
      let element = document.getElementById('validateCareLinePriority')
      element.scrollIntoView(false);
    }
    else
      this.model.get('idCareLinePriority').setErrors(null);

    return careLineValidation;
  }

  updateCareLinePriorityStruct() {
    this.careLinePriorityStruct.idCareLinePriority = this.model.get('idCareLinePriority').value;
    this.careLinePriorityStruct.careLinePriorityName = this.listCareLinePriority.find(x => x.idCareLinePriority === this.careLinePriorityStruct.idCareLinePriority).careLinePriorityName;
    this.careLinePriorityStruct.idCareLinePriorityStatus = this.episodeStruct.idCareLinePriorityStatus ? this.episodeStruct.idCareLinePriorityStatus : CareLinePriorityStatusEnum.suspeita;
  }

  changeIsolation(event: any) {
    if (event.checked) {
      this.model.get('datetimeEndIsolation').setValidators([DateAfterTodayValidator(), Validators.required]);
      this.model.get('datetimeEndIsolation').setValue(new Date());
    }
    else {
      this.model.get('datetimeEndIsolation').setValidators([]);
      this.model.get('datetimeEndIsolation').setValue(null);
    }
    this.model.get('datetimeEndIsolation').updateValueAndValidity();
  }

  getPreselectedProcedures() {
    let idsTriggers: number[] = [];
    idsTriggers.push(ProcedureTriggersEnum.afericao_pressao_arterial.valueOf());
    idsTriggers.push(ProcedureTriggersEnum.glicemia_capilar.valueOf());
    idsTriggers.push(ProcedureTriggersEnum.afericao_temperatura.valueOf());
    idsTriggers.push(ProcedureTriggersEnum.atendimento_urgencia_observacao.valueOf());

    this.procedureSigtapCBOService.listProcedureSigtapCodProcedure(idsTriggers, this.idCompetence, this.codCbo).subscribe({
      next: response => {
        if (response.isError) {
          this.alertService.show("Erro", response.errorDescription, AlertType.error);
          return;
        }

        this.listTriggersProcedure = response.list;
        let trigger = this.listTriggersProcedure.find(c => c.idTriggersProcedureSigtap == ProcedureTriggersEnum.atendimento_urgencia_observacao.valueOf())
        if (trigger) {
          this.listPreselectedPRocedure = null;
          this.listPreselectedPRocedure = [trigger];
          this.listProcedureSigtap = this.listPreselectedPRocedure;
        }
        else
          this.listPreselectedPRocedure = [];
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  onLeaveRoom() {
  }

  onBlurPressure() {
    var bloodPressure = this.model.get("bloodPressure").value;
    if (bloodPressure.length >= 4)
      this.setPreselectTrigger(ProcedureTriggersEnum.afericao_pressao_arterial);
    else
      this.removePreselectTrigger(ProcedureTriggersEnum.afericao_pressao_arterial);
  }

  onBlurTemperature() {
    var temperature = this.model.get("temperature").value;
    if (temperature.length == 3)
      this.setPreselectTrigger(ProcedureTriggersEnum.afericao_temperatura);
    else
      this.removePreselectTrigger(ProcedureTriggersEnum.afericao_temperatura);
  }

  onBlurGlucose() {
    var glucose = this.model.get("glucose").value;
    if (glucose.length >= 1)
      this.setPreselectTrigger(ProcedureTriggersEnum.glicemia_capilar);
    else
      this.removePreselectTrigger(ProcedureTriggersEnum.glicemia_capilar);
  }

  setPreselectTrigger(idTrigger: ProcedureTriggersEnum) {
    if (!this.listPreselectedPRocedure) return;
    let listPreselectedPRocedure = [...this.listPreselectedPRocedure];
    this.listPreselectedPRocedure = null;

    let trigger = this.listTriggersProcedure.find(c => c.idTriggersProcedureSigtap == idTrigger.valueOf())
    var indexOf = listPreselectedPRocedure.findIndex(c => c.idTriggersProcedureSigtap == idTrigger.valueOf());
    if (trigger && indexOf < 0)
      listPreselectedPRocedure.push(trigger);
    this.listPreselectedPRocedure = [...listPreselectedPRocedure];
    this.listProcedureSigtap = this.listPreselectedPRocedure;
  }

  removePreselectTrigger(idTrigger: ProcedureTriggersEnum) {
    if (!this.listPreselectedPRocedure) return;
    let listPreselectedPRocedure = [...this.listPreselectedPRocedure];
    this.listPreselectedPRocedure = null;

    var indexOf = listPreselectedPRocedure.findIndex(c => c.idTriggersProcedureSigtap == idTrigger.valueOf());
    if (indexOf >= 0)
      listPreselectedPRocedure.splice(indexOf, 1);

    this.listPreselectedPRocedure = [...listPreselectedPRocedure];
    this.listProcedureSigtap = this.listPreselectedPRocedure;
  }

  ngOnDestroy() {
    clearTimeout(this.disabelSessionTimeout);
    let memedSession: MemedPrescriptionSession = new MemedPrescriptionSession();
    memedSession = this.utilService.getMemedPrescriptionSession();
    if (memedSession) {
      this.listMemedPrescriptionStruct = memedSession.listMemedPrescriptionStruct;
      this.listMemedCompletePrescriptionStruct = memedSession.listMemedCompletePrescriptionStruct;
      this.utilService.cleanMemedPrescriptionSession();
    }

    if (this.websocketAttendanceUtilService.isConnect())
      this.websocketAttendanceUtilService.emitFinalizeAttendance(this.idRoom);

    if (this.isAttended == false && !this.isEvaded && !this.readonly) {
      if (this.userTokenMemed && this.listMemedCompletePrescriptionStruct && this.listMemedCompletePrescriptionStruct.length > 0)
        this.deleteAllMemedPrescriptionOnDestroy();

      if (!this.isBedManagement && !this.isAttendanceRemoval)
        this.updateStatusOnDestroy();
    }

    if (this.socket && this.socket.connected)
      this.socket.disconnect();
  }

  populateDestinationSelect() {
    let fowardRequest = new FowardRequest();
    fowardRequest.idEpisode = this.selectedEpisode;
    fowardRequest.idRoom = this.idRoom;

    this.observationForwardService.listDestination(fowardRequest).subscribe({
      next: (response) => {
        this.openModalForward(response, fowardRequest.idRoom);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openModalForward(response: ForwardResponse, idRoom: number) {
    if (response.isError) {
      this.alertService.show('Erro', response.errorDescription, AlertType.error);
      return;
    }
    else if (response.isAutomaticFoward) {
      this.alertService.show('Aviso', response.automaticFoward, AlertType.warning);
      return;
    }
    const dialogRef = this.dialog.open(ObservationForwardModalComponent, {
      data: {
        idRoom: idRoom,
        idService: this.idService,
        actualIdSector: this.idSector,
        actualIdRoom: this.idRoom,
        idEpisode: this.selectedEpisode,
        idQueue: response.idLastQueue,
        listFoward: response.location,
        datetimeStartRegister: null,
        idBed: this.idBed,
        isMedicBedManagement: true,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result && result.fowardPatient)
          this.router.navigate(['/medic/medic-bed-management', { idSector: this.idSector, idRoom, idService: this.idService }]);
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  getMedicSectorConfiguration() {
    this.medicConfigSectorService.listMedicConfigSector(this.idSector).subscribe({
      next: response => {
        if (response.isError) {
          this.alertService.show("Erro", response.errorDescription, AlertType.error);
          return;
        }

        if (response.medicConfigSectorStruct && response.medicConfigSectorStruct.length > 0) {
          this.isDisableExternalRequest = response.isDisableExternalRequest;
          this.blockAllergicPrescription = response.blockAllergicPrescription;
          this.medicConfigSector = response.medicConfigSectorStruct[0];
          this.isFastTrack = this.medicConfigSector.medicConfig.fastTrack;
          this.listIdExam = this.medicConfigSector.listIdExam;
          this.isAllExams = this.medicConfigSector.medicConfig.toggleAllExam;
          if (this.medicConfigSector.medicConfig.remoteAttendance) {
            if (this.medicConfigSector.medicConfig.idFinalizationType == FinalizationTypeEnum.returnToQueue)
              this.medicConfigSector.medicConfig.maxReturns = this.medicConfigSector.medicConfig.maxReturns != null ? this.medicConfigSector.medicConfig.maxReturns : 1;
            this.remoteAttendance = true;
            this.idFinalizationType = this.medicConfigSector.medicConfig.idFinalizationType;
            this.idTimeout = setTimeout(() => this.startCall(this.medicConfigSector.medicConfig.watingTime), 1000);
            this.startWebsocketRemote(this.selectedEpisode);
            //Ao pegar os dados necessários abro o modal de contagem e entro no websocket
          }
          else
            this.startWebsocketLocalAttendance();
        }
        else
          this.alertService.show('Aviso', 'Configurações não encontradas, verifique se há configurações cadastradas para este setor', AlertType.warning);

        this.verifyRequiredFields();
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  startCall(watingTime: number) {
    clearTimeout(this.idTimeout);

    if (sessionStorage.getItem("videoToken"))
      this.twiliovideo.onRoomChanged(sessionStorage.getItem("videoToken")).then(result => {
        let room = result;
        if (room.participants.size === 0 && !this.onlyIntheRoom)
          this.openHoldModal(watingTime);
      });
  }

  openHoldModal(watingTime: number) {
    this.dialogRefRemoteHoldModal = this.dialog.open(RemoteHoldModalComponent, {
      disableClose: true,
      panelClass: 'medic-hold-modal',
      data: {
        watingTime
      },
    });
    this.dialogRefRemoteHoldModal.afterClosed().subscribe({
      next: result => {
        if (result && !result.websocket) {
          if (result.isCancel == true)
            this.router.navigate(['/medic/medic-patient-list', { idSector: this.idSector, idRoom: this.idRoom }]);
          else {
            if (this.idFinalizationType == FinalizationTypeEnum.evasion)
              this.patientLeavedRoom(false);
            else if (this.idFinalizationType == FinalizationTypeEnum.returnToQueue)
              this.checkReturnability(this.selectedEpisode, this.medicConfigSector.medicConfig.maxReturns);
            else
              this.router.navigate(['/medic/medic-patient-list', { idSector: this.idSector, idRoom: this.idRoom }]);
          }
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  startWebsocketRemote(idEpisode: number) {
    if (this.socket)
      this.socket.disconnect();

    this.socket = this.websocketRemoteService.websocketConnection();
    this.socket.emit('join', { room: `idRoom-${this.idRoom}`, medicSide: true })
      .on('patientConnected', (res) => { this.verifyTrigger(res) })
      .on('emitPatientEvaded', (res) => {
        if (this.dialogRefRemoteHoldModal) {
          this.isEvaded = true;
          this.dialogRefRemoteHoldModal.close({ websocket: true });
        }
        this.patientLeavedRoom(false);
      })
      .on('onlyMedicConnected', () => {
        this.onlyIntheRoom = true;
        this.roomIsNotConnected();
      }).on('someoneDisconnected', () => {
        setTimeout(() => {
          this.socket.emit('verifying', `idRoom-${this.idRoom}`);
        }, 20000);
        this.alertService.show("Aviso", "O paciente está com instabilidade, aguarde alguns segundos!", AlertType.warning);
      })
      .on('personsInRoom', (res) => {
        if (!Number.isNaN(res) && res == 1) {
          if (this.dialogRefRemoteHoldModal)
            this.dialogRefRemoteHoldModal.close({ websocket: true });

          this.patientLeavedRoom(true);
        }
      })
      .io.on("reconnect", () => {
        this.socket.emit('join', { room: `idRoom-${this.idRoom}`, medicSide: true });
      });
  }

  //Websocket
  startWebsocketLocalAttendance() {
    if (!this.websocketAttendanceUtilService.isConnect())
      this.websocketAttendanceUtilService.connectwebsocketRoom(this.idRoom, this.idQueue, this.selectedEpisode, this, false, this.isBedManagement, this.authService.getToken(), this.userLogin, this.userName);

    this.websocketAttendanceUtilService.setContext(this);
    this.websocketAttendanceUtilService.setFunctions(null, null, this.onRemoveAttendant);
  }

  patientLeavedRoom(disconnected: boolean) {
    const dialogRef = this.dialog.open(PatientLeaveModalRemoteComponent, {
      data: {
        disconnected,
        time: false,
        idQueue: this.idQueue,
        idEpisode: this.selectedEpisode,
        idRoom: this.idRoom,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        this.router.navigate(['/medic/medic-patient-list', { idSector: this.idSector, idRoom: this.idRoom }]);
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  updateStatusPatient() {
    this.isLoading = true;

    let statusQueueRequest: StatusQueueRequest = new StatusQueueRequest();
    statusQueueRequest.conclusionNote = "Paciente evadido após já ter retornado o número máximo de vezes configurado na Unidade.";
    statusQueueRequest.idQueue = this.idQueue;
    statusQueueRequest.idEpisode = this.selectedEpisode;
    statusQueueRequest.idRoom = this.idRoom;
    statusQueueRequest.idStatusQueue = StatusQueueEnum.evadido;

    this.updateStatusQueueService.updateStatus(statusQueueRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isEvaded = true;
        this.isLoading = false;
        this.alertService.show('Aviso', "Paciente evadido após já ter retornado o número máximo de vezes configurado na Unidade.", AlertType.warning);

        this.router.navigate(['/medic/medic-patient-list', { idSector: this.idSector, idRoom: this.idRoom }]);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  roomIsNotConnected() {
    const dialogRef = this.dialog.open(PatientLeaveModalRemoteComponent, {
      data: {
        roomIsNotConnected: true,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        this.router.navigate(['/medic/medic-patient-list', { idSector: this.idSector, idRoom: this.idRoom }]);
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  verifyTrigger(value: any) {
    if (this.dialogRefRemoteHoldModal)
      this.dialogRefRemoteHoldModal.close();
  }

  verifyRequiredFields() {
    if (this.medicConfigSector) {
      this.medicConfigSector.listIdRequiredField.forEach(x => {

        //sinais vitais
        if (x == MedicRequiredFieldEnum.temperature) {
          this.model.get('temperature').setValidators([Validators.required]);
          this.model.get('temperature').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.respiratoryFrequency) {
          this.model.get('respiratoryFrequency').setValidators([Validators.required]);
          this.model.get('respiratoryFrequency').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.heartRate) {
          this.model.get('heartRate').setValidators([Validators.required]);
          this.model.get('heartRate').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.glucose) {
          this.model.get('glucose').setValidators([Validators.required]);
          this.model.get('glucose').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.satO2) {
          this.model.get('saturation').setValidators([Validators.required]);
          this.model.get('saturation').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.bloodPressure) {
          this.model.get('bloodPressure').setValidators([Validators.required]);
          this.model.get('bloodPressure').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.allergies) {
          this.model.get('firstChildGroup').get('listIdAllergies').setValidators([Validators.required]);
          this.model.get('firstChildGroup').get('listIdAllergies').updateValueAndValidity();
        }
        //outros campos
        else if (x == MedicRequiredFieldEnum.medicalNotes) {
          this.model.get('medicalNotes').setValidators([Validators.required]);
          this.model.get('medicalNotes').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.physicalExamination) {
          this.model.get('physicalExamination').setValidators([Validators.required]);
          this.model.get('physicalExamination').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.cid) {
          this.model.get('firstChildGroup').get('cid').setValidators([Validators.required]);
          this.model.get('firstChildGroup').get('cid').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.conclusion) {
          this.model.get('conclusionNote').setValidators([Validators.required]);
          this.model.get('conclusionNote').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.diagnosedHypothesis) {
          this.model.get('diagnosedHypothesis').setValidators([Validators.required]);
          this.model.get('diagnosedHypothesis').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.therapeuticPlan) {
          this.model.get('therapeuticPlan').setValidators([Validators.required]);
          this.model.get('therapeuticPlan').updateValueAndValidity();
        }
      });
      if (this.medicConfigSector.listIdRequiredField.findIndex(c => c == MedicRequiredFieldEnum.conclusion) == -1) {
        this.model.get('conclusionNote').setValidators([]);
        this.model.get('conclusionNote').updateValueAndValidity();
      }
    }
    else {
      this.model.get('conclusionNote').setValidators([]);
      this.model.get('conclusionNote').updateValueAndValidity();
    }
  }

  getMedicConfigBySector() {
    this.medicConfigSectorService.listMedicConfigSector(this.idSector).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (response.medicConfigSectorStruct && response.medicConfigSectorStruct[0])
          this.listConfigIdMedicine = response.medicConfigSectorStruct[0].listIdMedicine;
        else
          this.listConfigIdMedicine = [];

        this.isDisableExternalRequest = response.isDisableExternalRequest;
        this.isLoading = false;
        this.filterConfigMedicine();
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  filterConfigMedicine() {
    if (this.listConfigIdMedicine && this.listConfigIdMedicine.length > 0) {
      this.listMedicineAux = this.listMedicine.listMedicineStruct.filter(item => this.listConfigIdMedicine.includes(item.medicine.idMedicine));
      this.listMedicine.listMedicineStruct = this.listMedicineAux;
    }
  }

  getProcedureName(idProcedure: number): string {
    return this.listProcedure?.find(l => l.idProcedure == idProcedure)?.procedureName;
  }

  getRecurranceName(isRecurrent: boolean): string {
    return isRecurrent ? "Recorrente" : "Única";
  }

  toggleBypassExam(event: any) {
    if (event.checked) {
      this.bypassExam = true;
      if (!this.listIdBypass.includes(BypassEnum.Exames))
        this.listIdBypass.push(BypassEnum.Exames);
    }
    else {
      this.bypassExam = false;
      if (this.listIdBypass.includes(BypassEnum.Exames)) {
        let index = this.listIdBypass.indexOf(BypassEnum.Exames);
        this.listIdBypass.splice(index, 1);
      }
    }
  }

  flowSequence(): FormArray {
    return this.model.get('flowSequence') as FormArray;
  }

  createInput() {
    return this.formBuilder.group({
      idFlow: [null],
    });
  }

  addNext() {
    let sequence = this.model.get('flowSequence')['controls'];
    let size = sequence.length;

    if (size != 0) {
      let idLastFlow = sequence[size - 1].value.idFlow;
      let lastFlowStruct: FlowStruct = this.listAvailableFlows[size - 1].find(x => x.idFlow == idLastFlow);
      let idDestinationModule: number = null;
      let idDestinationService: number = null;
      if (lastFlowStruct.idDestinationRoom)
        idDestinationService = this.listRoom.find(x => x.idRoom == lastFlowStruct.idDestinationRoom).idService;
      else if (lastFlowStruct.idDestinationSector)
        idDestinationService = this.listSector.find(x => x.idSector == lastFlowStruct.idDestinationSector).idService;

      if (lastFlowStruct.idDestinationService)
        idDestinationService = lastFlowStruct.idDestinationService;

      idDestinationModule = this.listService.find(x => x.idService == idDestinationService).idModule;

      if (idDestinationModule == MenuModuleEnum.medic) {
        this.alertService.show('Aviso', 'Não é possível continuar a sequência após selecionar um destino no módulo Médico!', AlertType.warning);
        return;
      }
    }

    (this.model.controls['flowSequence'] as FormArray).push(this.createInput());
    sequence = this.model.get('flowSequence')['controls'];
    size = sequence.length;
    if (size > 1) {
      let lastStep = sequence[size - 2];
      lastStep.disable();

      let flow = this.listAvailableFlows[size - 2].find(x => x.idFlow == lastStep.value.idFlow);
      let episodeData = JSON.parse(sessionStorage.getItem("medic")).episodeData;
      let request: AvailableFlowsRequest = new AvailableFlowsRequest();

      if (this.triage) {
        request.idFlowchart = this.triage.idFlowchart;
        request.idPriorityColor = this.triage.idGravity;
      }

      request.idEpisode = this.selectedEpisode;
      request.birthDate = new Date(episodeData.birthDate);
      request.idRoom = flow.idDestinationRoom;
      request.idSector = flow.idDestinationSector;
      request.idService = flow.idDestinationService;

      this.upadteAvailableFlows(request);
    }

    this.selectedFlows.push("");
  }

  removeFlow(index: number) {
    if (index == 0){
      this.model.get('idFlow').reset();
    }   
    (this.model.controls['flowSequence'] as FormArray).removeAt(index);
    if (this.model.get('flowSequence')['controls'].length == 0)
      this.addNext();

    let sequence = this.model.get('flowSequence')['controls'] ? this.model.get('flowSequence')['controls'] : null;
    if (sequence != null && sequence.length > 0) {
      if (this.listAvailableFlows.length > 1)
        this.listAvailableFlows.splice(index, 1);

      let lastStep = sequence[sequence.length - 1];
      lastStep.enable();
    }

    this.selectedFlows.splice(index, 1);
  }

  resetFlowSequence() {
    let i = this.model.get('flowSequence')['controls'].length - 1;

    while (i >= 0) {
      this.removeFlow(i);
      i -= 1;
    }

    this.model.get('flowSequence').reset();
    this.model.get('idFlow').reset();
  }

  flowSequenceSelect(event: any, index: number) {
    if (index == 0)
      this.model.get('idFlow').setValue(event.value);

    this.selectedFlow = this.listAvailableFlows[index].find(x => x.idFlow == event.value);
    this.selectedFlows[index] = (this.selectedFlow.originRoomName ? this.selectedFlow.originRoomName : (this.selectedFlow.originSectorName ? this.selectedFlow.originSectorName : this.selectedFlow.originServiceName)) + " - " +
      (this.selectedFlow.destinyRoomName ? this.selectedFlow.destinyRoomName : (this.selectedFlow.destinySectorName ? this.selectedFlow.destinySectorName : this.selectedFlow.destinyServiceName));
  }

  upadteAvailableFlows(request: AvailableFlowsRequest) {
    this.isLoadingFlows = true;
    this.forwardFlowService.getAvailableFlows(request).subscribe({
      next: response => {
        if (response.isError) {
          this.alertService.show("Erro", response.errorDescription, AlertType.error);
          this.isLoadingFlows = false;
          return;
        }

        if (this.isBedManagement && response.flows) {
          let listFlowFiltered = response.flows.filter(x => (x.idDestinationModule == MenuModuleEnum.sadt && (x.idOriginModule == MenuModuleEnum.observation || x.idOriginModule == null)) || (x.idDestinationModule == MenuModuleEnum.observation && (x.idOriginModule == MenuModuleEnum.sadt || x.idOriginModule == null)));
          this.listAvailableFlows.push(listFlowFiltered);
        }
        else
          this.listAvailableFlows.push(response.flows);

        this.isLoadingFlows = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getOrganization() {
    this.organizationService.GetOrganization().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listRoom = response.listRoom;
        this.listSector = response.listSector;
        this.listService = response.listService;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  updateFlowSequence(request: FlowSequenceUpdateRequest) {
    this.flowSequenceService.updateFlowSequence(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  changeButtonColor() {
    this.destinationTypeManterColor = this.destinationTypeManter == this.destinationType ? "primary" : "accent";
    this.destinationTypeAltaColor = this.destinationTypeAlta == this.destinationType ? "primary" : "accent";
    this.destinationTypeAltaEncaminhamentoColor = this.destinationTypeAltaEncaminhamento == this.destinationType ? "primary" : "accent";
    this.destinationTypeEncaminhamentoColor = this.destinationTypeEncaminhamento == this.destinationType ? "primary" : "accent";
    this.destinationTypeInternacaoEncaminhamentoColor = this.destinationTypeInternacaoEncaminhamento == this.destinationType ? "primary" : "accent";
    this.destinationTypeInternacaoColor = this.destinationTypeInternacao == this.destinationType ? "primary" : "accent";
    this.destinationTypeObitoColor = this.destinationTypeObito == this.destinationType ? "primary" : "accent";
  }

  showSelfTtriageResume() {
    const dialogRef = this.dialog.open(SelfTriageResumeModalComponent, {
      data: {
        selfTriage: this.lastSelfTriage,
      },
    });
  }

  openCertificatePinModal(destinationName: string, medicalCareRequest: MedicalCareRequest) {
    const dialogRef = this.dialog.open(PinCertificateModalComponent, {
      data: {
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.isICPSigned) {
          this.certificatePin = result.pin;
          this.isIcpBrailSigned = result.isICPSigned;
        }

        this.makeCallPost(this.mapMedicalCareReportRequest());
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  openReportViewSelectModal(response: NewReportResponse) {
    let reportDialog = this.dialog.open(ReportViewSelectModalComponent, {
      data: {
        medicalCareReport: response.medicalCareReport64,
        examInternalReport: response.sadtInternalReport64,
        examExternalReport: response.sadtExternalReport64,
        internalPrescriptionReport: response.prescriptionReport64,
        externalPrescriptionReport: response.externalPrescriptionReport64,
        procedureReport: response.procedureReport64,
        sickNoteReport: response.sickNoteReport64,
        isolationReport: response.isolationReport64,
        isolationAcknowledgeReport: response.isolationAcknowledgeReport64,
        appointmentGuideReport: this.allPrivateBillingGuideReportStruct?.appointmentGuideReport64,
        admissionGuideReport: this.allPrivateBillingGuideReportStruct?.admissionGuideReport64,
        feesGuideReport: this.allPrivateBillingGuideReportStruct?.feesGuideReport64,
        spSadtGuideReport: this.allPrivateBillingGuideReportStruct?.spSadtGuideReport64,
        listMemedPrescriptionPdf: response.listMemedPrescriptionPdf64,
        allReportsConcat: response.allReportsConcat64,
        sign: this.sign
      },
    });
    reportDialog.afterClosed().subscribe({
      next: () => {
        this.redirectAfterSaveMedicalCare(response);
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  populateCareLinePrioritySelect() {
    this.getCareLinePriorityService.getAllByIdHealthUnit().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listCareLinePriority = response.listCareLinePriority;
        this.listAllCareLinePriority = response.listAllCareLinePriority;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  validateCareLinePriority() {
    let careLinePriority = this.careLinePriorityStruct;

    const dialogRef = this.dialog.open(ValidateCareLinePriorityModalComponent, {
      data: {
        idEpisode: this.episodeStruct.idEpisode,
        careLinePriority: careLinePriority,
        listAllCareLine: this.listAllCareLinePriority
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.careLinePriorityStatusResponse) {

          let response: CareLinePriorityStatusResponse = result.careLinePriorityStatusResponse;

          this.episodeStruct.idCareLinePriorityStatus = response.idCareLinePriorityStatus;
          this.episodeStruct.idUserCareLinePriority = response.idUserCareLinePriority;
          this.episodeStruct.idUserCareLinePriority = response.idUserCareLinePriority;

          this.careLinePriorityStruct.idCareLinePriority = response.idCareLinePriority;
          this.careLinePriorityStruct.careLinePriorityName = this.listCareLinePriority?.find(x => x.idCareLinePriority === response.idCareLinePriority).careLinePriorityName;
          this.careLinePriorityStruct.idCareLinePriorityStatus = response.idCareLinePriorityStatus;
          this.careLinePriorityStruct.careLinePriorityStatusMotive = response.careLinePriorityStatusMotive;
          this.careLinePriorityStruct.careLinePriorityStatusUserName = response.careLinePriorityUserName;
          this.careLinePriorityStruct.datetimeCareLinePriorityStatus = response.datetimeCareLinePriorityStatus;

          let episodeData = this.utilHeaderService.getSessionEpisodeData();
          episodeData.patientCareLinePriorityStruct.careLinePriorityName = this.careLinePriorityStruct.careLinePriorityName;
          episodeData.patientCareLinePriorityStruct.careLinePriorityStatusUserName = this.careLinePriorityStruct.careLinePriorityStatusUserName;
          episodeData.patientCareLinePriorityStruct.datetimeCareLinePriorityStatus = this.careLinePriorityStruct.datetimeCareLinePriorityStatus;

          let statusName = "";

          if (this.careLinePriorityStruct.idCareLinePriorityStatus === CareLinePriorityStatusEnum.confirmado)
            statusName = "Confirmada";
          else if (this.careLinePriorityStruct.idCareLinePriorityStatus === CareLinePriorityStatusEnum.negado)
            statusName = "Negada";

          this.model.get('idCareLinePriority').setErrors(null);

          episodeData.patientCareLinePriorityStruct.careLinePriorityStatusName = statusName;

          this.utilHeaderService.createSessionEpisodeData(episodeData);

          this.menu.populateSecundaryHeader();
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  validateSuspicionModal() {
    let suspicion = this.listSuspicion.find(x => x.idSuspicion == this.triage.idSuspicion);

    const dialogRef = this.dialog.open(ValidateSuspicionModalComponent, {
      data: {
        idEpisode: this.episodeStruct.idEpisode,
        suspicion: suspicion
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.suspicionStatusResponse) {
          let response: SuspicionStatusResponse = result.suspicionStatusResponse;
          this.triage.isSuspicionEvaluated = response.isSuspicionEvaluated;
          this.triage.idUserSuspicionEvaluation = response.idUserSuspicionEvaluation;

          this.suspicionValidatedStruct.idSuspicion = response.idSuspicion;
          this.suspicionValidatedStruct.suspicionName = this.listSuspicion.find(x => x.idSuspicion === response.idSuspicion).suspicionName;
          this.suspicionValidatedStruct.isSuspicionEvaluated = response.isSuspicionEvaluated;
          this.suspicionValidatedStruct.suspicionEvaluationMotive = response.suspicionEvaluationMotive;
          this.suspicionValidatedStruct.suspicionUserName = response.suspicionUserName;
          this.suspicionValidatedStruct.datetimeSuspicionEvaluation = response.datetimeSuspicionEvaluation;

          let episodeData = this.utilHeaderService.getSessionEpisodeData();
          episodeData.suspicionName = this.suspicionValidatedStruct.suspicionName;
          episodeData.isSuspicionEvaluated = this.suspicionValidatedStruct.isSuspicionEvaluated;
          episodeData.suspicionUserName = this.suspicionValidatedStruct.suspicionUserName;
          episodeData.datetimeSuspicionEvaluation = this.suspicionValidatedStruct.datetimeSuspicionEvaluation;

          let statusName = "";
          if (this.suspicionValidatedStruct.isSuspicionEvaluated === this.isSuspicionEvaluatedSuspicion)
            statusName = "Suspeita";
          else if (this.suspicionValidatedStruct.isSuspicionEvaluated === this.isSuspicionEvaluatedConfirmed)
            statusName = "Confirmada";
          else if (this.suspicionValidatedStruct.isSuspicionEvaluated === this.isSuspicionEvaluatedDenied)
            statusName = "Negada";

          episodeData.suspicionStatusName = statusName;
          this.utilHeaderService.createSessionEpisodeData(episodeData);

          this.menu.populateSecundaryHeader();
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  openEmailConfirmationModal(medicalCareReportRequest: MedicalCareReportRequest,) {
    const dialogRef = this.dialog.open(EmailConfirmationEndMedicalCareModalComponent, {
      disableClose: true,
      data: {
        email: this.patientEmail,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result.confirm && result.email) {
          this.newEmail = result.email;
          this.updateEmailPatient();
          this.makeCallPost(medicalCareReportRequest);
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  updateEmailPatient() {
    let updateEmail = new UpdateEmailPatientRequest();
    updateEmail.email = this.newEmail;
    updateEmail.idPatient = this.idPatient;

    this.patientService.updateEmailPatient(updateEmail).subscribe({
      next: response => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  checkReturnability(idEpisode: number, maxReturns: number) {
    this.returnToQueueService.checkReturnability(idEpisode, maxReturns).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (response.canReturn)
          this.returnToQueue();
        else
          this.updateStatusPatient();
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  returnToQueue() {
    let statusQueueRequest: StatusQueueRequest = new StatusQueueRequest();
    statusQueueRequest.conclusionNote = "Paciente retornado automaticamente à fila.";
    statusQueueRequest.idQueue = this.idQueue;
    statusQueueRequest.idEpisode = this.selectedEpisode;
    statusQueueRequest.idRoom = this.idRoom;
    statusQueueRequest.idStatusQueue = StatusQueueEnum.nao_atendido;

    let returnToQueueRequest: ReturnToQueueRequest = new ReturnToQueueRequest();
    returnToQueueRequest.idEpisode = this.selectedEpisode;
    returnToQueueRequest.idQueue = this.idQueue;

    this.updateStatusQueueService.updateStatus(statusQueueRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.createReturnToQueue(returnToQueueRequest);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  createReturnToQueue(request: ReturnToQueueRequest) {
    this.returnToQueueService.create(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;

        this.alertService.show('Aviso', "Paciente retornado à fila automaticamente.", AlertType.warning);

        this.router.navigate(['/medic/medic-patient-list', { idSector: this.idSector, idRoom: this.idRoom }]);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getLastPrescriptions() {
    if (this.isLoadingLasPrescriptions) return;

    this.isLoadingLasPrescriptions = true;
    let copyMedicalCare = [...this.medicalCare]
    let medicalCareFilterByPrescription: CompleteMedicalCareStruct[] = copyMedicalCare.filter(item => ((item.medicalPrescription && item.medicalPrescription.length > 0) || (item.medicatedSolutionPrescription && item.medicatedSolutionPrescription.length > 0)));

    let medicalCareFilterByPrescriptionDate: CompleteMedicalCareStruct[] = [];
    if (medicalCareFilterByPrescription && medicalCareFilterByPrescription.length > 0) {
      medicalCareFilterByPrescription.forEach(item => {
        item.medicalPrescription = item.medicalPrescription.filter(prescription => this.checkIfPrescriptionIsSeventyTwoHoursOld(prescription.datetimeInclusion));
        item.medicatedSolutionPrescription = item.medicatedSolutionPrescription.filter(prescription => this.checkIfPrescriptionIsSeventyTwoHoursOld(prescription.datetimeInclusion));

        if (item.medicalPrescription && item.medicalPrescription.length > 0) {
          item.medicalPrescription.forEach(prescription => {
            prescription.idMedicalPrescription = 0;
          });
        }

        if ((item.medicalPrescription && item.medicalPrescription.length > 0) ||
          (item.medicatedSolutionPrescription && item.medicatedSolutionPrescription.length > 0)) {

          medicalCareFilterByPrescriptionDate.push(item);
        }
      });
    }

    if (!medicalCareFilterByPrescriptionDate || medicalCareFilterByPrescriptionDate.length == 0) {
      this.alertService.show('Aviso!', "Atenção! Não foram encontradas prescrições anteriores para serem replicadas.", AlertType.warning);
      this.isLoadingLasPrescriptions = false;
      return;
    }
    else if (medicalCareFilterByPrescriptionDate.length > 1) {
      const dialogRef = this.dialog.open(SelectOldPrescriptionModalComponent, {
        data: {
          medicalCareFilterByPrescriptionDate: medicalCareFilterByPrescriptionDate,
        },
        autoFocus: false,
        panelClass: 'select-old-prescription-modal',
      });
      dialogRef.afterClosed().subscribe({
        next: (result) => {
          if (result && result.listPrescription)
            this.listPrescription = [...this.listPrescription, ...result.listPrescription];

          if (result && result.listMedicatedSolution)
            this.listPrescriptionMedicatedSolution = [...this.listPrescriptionMedicatedSolution, ...result.listMedicatedSolution];

          this.isLoadingLasPrescriptions = false;
        },
        error: (error) => {
          console.log(error);
          this.isLoadingLasPrescriptions = false;
        }
      });
    }
    else {
      this.listPrescription = medicalCareFilterByPrescriptionDate[0].medicalPrescription;
      this.listPrescriptionMedicatedSolution = medicalCareFilterByPrescriptionDate[0].medicatedSolutionPrescription;
      this.isLoadingLasPrescriptions = false;
    }
  }

  checkIfPrescriptionIsSeventyTwoHoursOld(datetimeToCompareInUtc: Date) {
    let todayDateLocal: Date = new Date();
    let todayDateUtc: Date = new Date(todayDateLocal.getTime() + (todayDateLocal.getTimezoneOffset() * 60000));

    let dateToCompare: Date = new Date(datetimeToCompareInUtc);

    var diff = (todayDateUtc.getTime() - dateToCompare.getTime()) / 3600000;
    return diff <= 72;
  }

  favoriteMedicineModal() {
    // if (this.listPrescriptionMedicatedSolution != null && this.listPrescriptionMedicatedSolution.length > 0) {
    //   this.alertService.show('Error', 'Não há como favoritar prescrições com soluções medicamentosas', AlertType.error);
    //   return
    // }

    if ((!this.listPrescription || this.listPrescription.length === 0) &&
      (!this.listPrescriptionMedicatedSolution || this.listPrescriptionMedicatedSolution.length === 0)) {
      this.alertService.show('Error', 'Não há prescrições a serem favoritadas', AlertType.error);
      return
    }

    const dialogRef = this.dialog.open(SaveFavoritePrescriptionModalComponent, {
      disableClose: true,
      data: {
        favoriteType: FavoriteTypeEnum.StandardMedicine,
        listPrescription: this.listPrescription,
        listPrescriptionMedicatedSolution: this.listPrescriptionMedicatedSolution
      },
    });
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result.save) {
          this.alertService.show("Sucesso", "As informações foram salvas!", AlertType.success);
          this.favoriteStandardMedicine = true;
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  favoriteNonStandardMedicineModal() {
    const dialogRef = this.dialog.open(SaveFavoritePrescriptionModalComponent, {
      disableClose: true,
      data: {
        favoriteType: FavoriteTypeEnum.NonStandardMedicine,
        listPrescription: this.listExternalPrescription
      },
    });
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result.save) {
          this.alertService.show("Sucesso", "As informações foram salvas!", AlertType.success);
          this.favoriteNonStandardMedicine = true;
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  favoriteExamPrescriptionModal() {
    const dialogRef = this.dialog.open(SaveFavoritePrescriptionModalComponent, {
      disableClose: true,
      data: {
        favoriteType: FavoriteTypeEnum.Exam,
        listPrescription: this.listSADT
      },
    });
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result.save) {
          this.alertService.show("Sucesso", "As informações foram salvas!", AlertType.success);
          this.favoriteExameRequest = true;
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  selectFavoriteMedicineModal() {
    const dialogRef = this.dialog.open(SelectFavoriteMedicineModalComponent, {
      data: {
        idSector: this.idSector,
        listMedicine: this.listMedicine.listMedicineStruct,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result && result.selected) {
          if ((result.listInvalidMedicine && result.listInvalidMedicine.length > 0)
            || result.listInvalidMedicatedSolution && result.listInvalidMedicatedSolution.length > 0) {
            this.favoriteStandardMedicine = false;
            const dialogRef = this.dialog.open(InvalidPrescriptionModalComponent, {
              data: {
                listInvalidMedicine: result.listInvalidMedicine,
                listInvalidMedicatedSolution: result.listInvalidMedicatedSolution,
                description: "Os seguintes medicamentos não podem ser prescritos neste setor:"
              },
              disableClose: true
            }).afterClosed().subscribe({
              next: (ok) => {
                if (ok.confirm) {
                  this.favoriteStandardMedicine = false;
                  this.listPrescription.push(...result.listValidMedicine);
                  this.listPrescriptionMedicatedSolution.push(...result.listValidMedicatedSolution);
                }
              },
              error: (error) => {
                console.log(error);
              }
            });
          }
          else {
            if (this.listPrescription.length == 0 && this.listPrescriptionMedicatedSolution.length == 0)
              this.favoriteStandardMedicine = true;
            else
              this.favoriteStandardMedicine = false;

            this.listPrescription.push(...result.listValidMedicine);
            this.listPrescriptionMedicatedSolution.push(...result.listValidMedicatedSolution);
          }
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  selectFavoriteNonStandardMedicineModal() {
    const dialogRef = this.dialog.open(SelectFavoriteNonStandardMedicineModalComponent);
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result && result.selected) {
          if (this.listExternalPrescription && this.listExternalPrescription.length > 0) {
            this.listExternalPrescription.push(...result.listMedicine);
            this.favoriteNonStandardMedicine = false;
          }
          else {
            this.listExternalPrescription = [...result.listMedicine];
            this.favoriteNonStandardMedicine = true;
          }

          if (this.listExternalPrescription.length > 0 && this.lookupMedicalCareResponse && this.lookupMedicalCareResponse.listMeasurementUnit) {
            this.listExternalPrescription.forEach(item => {
              item.measurementUnitName = item.measurementUnitName && item.measurementUnitName.trim() != "" ?
                item.measurementUnitName : this.lookupMedicalCareResponse.listMeasurementUnit.find(itemMeasurementUnit => itemMeasurementUnit.idMeasurementUnit == item.idMeasurementUnit)?.measurementUnitName;
            });
          }
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  selectFavoriteExamPrescriptionModal() {
    const dialogRef = this.dialog.open(SelectFavoriteExamPrescriptionModalComponent);
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result && result.selected) {
          if (result.listInvalidWithExam && result.listInvalidWithExam.length > 0 ||
            result.listInvalidWithExamType && result.listInvalidWithExamType.length > 0) {
            let description: string;

            if (result.listInvalidWithExam && result.listInvalidWithExamType) {
              if (result.listInvalidWithExam.length > 0 && result.listInvalidWithExamType.length > 0)
                description = "Os seguintes tipos de exames e exames estão desativados/inativados:";
              else if (result.listInvalidWithExam.length > 0)
                description = "Os seguintes exames estão desativados/inativados:";
              else if (result.listInvalidWithExamType.length > 0)
                description = "Os seguintes tipos de exames estão desativados/inativados:";
            }

            this.favoriteExameRequest = false;
            const dialogRef = this.dialog.open(InvalidPrescriptionModalComponent, {
              data: {
                listInvalidWithExam: result.listInvalidWithExam,
                listInvalidWithExamType: result.listInvalidWithExamType,
                description: description
              },
              disableClose: true
            }).afterClosed().subscribe({
              next: (ok) => {
                if (ok && ok.confirm)
                  this.handleFavoriteExamSadtList(result.listSADT);
              },
              error: (error) => {
                console.log(error);
              }
            });
          }
          else
            this.handleFavoriteExamSadtList(result.listSADT);
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  handleFavoriteExamSadtList(listSADT: any[]) {
    if (listSADT && listSADT.length > 0) {
      if (this.listSADT && this.listSADT.length > 0)
        this.favoriteExameRequest = false;
      else
        this.favoriteExameRequest = true;

      this.listSADT.push(...listSADT);
    }
    else
      this.favoriteExameRequest = false;
  }

  openInvalidMedicineModal(listInvalidMedicine: FavoriteStandardMedicine[]) {
    const dialogRef = this.dialog.open(InvalidPrescriptionModalComponent, {
      data: {
        listInvalidMedicine: listInvalidMedicine,
        description: "Os seguintes medicamentos não podem ser prescritos neste setor:"
      },
    });
  }

  mapSuspicion() {
    this.suspicionValidatedStruct.idSuspicion = this.triage.idSuspicion;
    this.suspicionValidatedStruct.suspicionEvaluationMotive = this.triage.suspicionEvaluationMotive;
    this.suspicionValidatedStruct.isSuspicionEvaluated = this.triage.isSuspicionEvaluated;
    this.suspicionValidatedStruct.suspicionUserName = this.triage.suspicionUserName;
    this.suspicionValidatedStruct.datetimeSuspicionEvaluation = this.triage.datetimeSuspicionEvaluation;

    let suspicion = this.listSuspicion.find(x => x.idSuspicion === this.triage.idSuspicion);
    if (suspicion)
      this.suspicionValidatedStruct.suspicionName = suspicion.suspicionName;
  }

  retrieveFlowSequence(): FlowStruct[] {
    let listFlow: FlowStruct[] = [];

    let flowSequence = this.model.get('flowSequence')['controls'];
    flowSequence.forEach((x, i) => {
      let flow: FlowStruct = this.listAvailableFlows[i].find(flow => flow.idFlow == x.get('idFlow').value);
      if (!flow) {
        this.isLoading = false;
        return;
      }
      flow.order = i;
      listFlow.push(flow);
    });

    return listFlow;
  }

  hasInternalExamRequest(): boolean {
    if (this.listSADT != null && this.listSADT.length > 0) {
      let internalExams: SadtGroupStruct[] = this.listSADT.filter(x => x.idPlace == SadtPlaceEnum.Interno && x.idStatus == SadtStatusEnum.Pedido);
      if (internalExams.length > 0)
        return true;
    }
    if (this.medicalCare != null && this.medicalCare.length > 0) {
      for (let index = 0; index < this.medicalCare.length; index++) {
        const element = this.medicalCare[index];

        let internalExams: SadtGroupStruct[] = element.medicalCareSadt.filter(x => x.idPlace == SadtPlaceEnum.Interno && x.idStatus == SadtStatusEnum.Pedido);
        if (internalExams.length > 0)
          return true;
      }
    }
    return false;
  }

  verifySADTFlow(item: FlowStruct, index: number) {
    if (item.idDestinationModule == MenuModuleEnum.sadt)
      return !this.hasInternalExamRequest();
    else
      return false;
  }

  getDischargeOutcome() {
    this.healthUnitDischargeOutcomeService.getDischargeOutcome().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (response.dischargeOutcome != null)
          this.dischargeOutcome = response.dischargeOutcome;

        this.datetimeInclusionDIscharge = response.datetimeInclusionDischarge;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getMemedPrescription() {
    this.healthUnitMemedPrescriptionService.getMemedPrescriptionConfig().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (response.memedPrescription) {
          this.isMemedPrescription = response.memedPrescription;
          this.searchTokenMemed();
        }
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  searchTokenMemed() {
    this.userMemedService.getUserTokenMemed().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isFirstLoading = false;
          this.isMemedPrescription = false;
          return;
        }

        this.userTokenMemed = response.userTokenMemed;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isMemedPrescription = false;
      }
    });
  }

  openMemedPrescription() {
    if (!this.patientCellPhone)
      this.openGetPhoneModal();
    else
      this.showMemed = true;
  }

  closeMemedModal() {
    this.showMemed = false;
  }

  deleteMemedPrescription(index: number) {
    let listIdMemed: number[] = [];
    listIdMemed.push(this.listMemedPrescriptionStruct[index].idMemedPrescription)
    const dialogRef = this.dialog.open(MemedPrescriptionDeleteModalComponent, {
      data: {
        listIdMemed: listIdMemed,
        userTokenMemed: this.userTokenMemed,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result && result.delete) {
          this.listMemedPrescriptionStruct.splice(index, 1);
          let memedSession: MemedPrescriptionSession = new MemedPrescriptionSession();
          memedSession.listMemedPrescriptionStruct = this.listMemedPrescriptionStruct;
          memedSession.listMemedCompletePrescriptionStruct = this.listMemedCompletePrescriptionStruct;
          this.utilService.setMemedPrescriptionSession(memedSession);
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  deleteAllMemedPrescriptionOnDestroy() {
    let deleteMemedPrescriptionsRequest: DeleteMemedPrescriptionsRequest = new DeleteMemedPrescriptionsRequest()
    deleteMemedPrescriptionsRequest.userToken = this.userTokenMemed;
    deleteMemedPrescriptionsRequest.listIdPrescriptionMemed = this.listMemedPrescriptionStruct.map(x => x.idMemedPrescription);

    this.userMemedService.deletePrescriptionMemed(deleteMemedPrescriptionsRequest).subscribe({
      next: (response) => {
        this.isLoading = false;
        if (response.isError) {
          this.alertService.show("Erro", response.errorDescription, AlertType.error);
          return;
        }

        this.utilService.cleanMemedPrescriptionSession();
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  newMemedPrescription(event: any) {
    if (event && event.memedPrescriptionStruct) {
      let hasPrescription = this.listMemedPrescriptionStruct.find(x => x.idMemedPrescription == event.memedPrescriptionStruct.idMemedPrescription)
      if (!hasPrescription) {
        this.listMemedPrescriptionStruct.push(event.memedPrescriptionStruct);

        this.listMemedCompletePrescriptionStruct.push(event.completePrescriptionStruct)
        let listidMemedPrescription = this.listMemedPrescriptionStruct.map(x => x.idMemedPrescription);
        if (this.websocketAttendanceUtilService.isConnect() && this.userTokenMemed)
          this.websocketAttendanceUtilService.emitMemedPrescriprtionSave(this.idRoom, this.userTokenMemed, listidMemedPrescription);

        let memedSession: MemedPrescriptionSession = new MemedPrescriptionSession();
        memedSession.listMemedPrescriptionStruct = this.listMemedPrescriptionStruct;
        memedSession.listMemedCompletePrescriptionStruct = this.listMemedCompletePrescriptionStruct;
        this.utilService.setMemedPrescriptionSession(memedSession);
      }
    }
  }

  updateStatusOnDestroy() {
    if (this.isLoadingCancelar)
      return;

    this.updateStatusQueueService.updateQueueToOldStatus(this.idLastQueue, this.selectedEpisode).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show("Error", response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  openGetPhoneModal() {
    const dialogRef = this.dialog.open(GetPhoneModalComponent, {});
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result && result.phone) {
          this.patientCellPhone = result.phone;
          this.showMemed = true;
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  hasReport(response: NewReportResponse) {
    return Boolean(
      response.wristbandReportPdf64 ||
      response.medicalCareReport64 ||
      response.sadtInternalReport64 ||
      response.sadtExternalReport64 ||
      response.prescriptionReport64 ||
      response.externalPrescriptionReport64 ||
      response.sickNoteReport64 ||
      response.isolationReport64 ||
      response.isolationAcknowledgeReport64 ||
      response.procedureReport64 ||
      response.allReportsConcat64 ||
      this.allPrivateBillingGuideReportStruct?.appointmentGuideReport64 ||
      this.allPrivateBillingGuideReportStruct?.admissionGuideReport64 ||
      this.allPrivateBillingGuideReportStruct?.feesGuideReport64 ||
      this.allPrivateBillingGuideReportStruct?.spSadtGuideReport64
    )
  }

  mapMedicalCareReportRequest(): MedicalCareReportRequest {
    let medicalCareReportRequest = new MedicalCareReportRequest();
    medicalCareReportRequest.isSickNoteReport = true;
    medicalCareReportRequest.isCompleteMedicalCareReport = true;
    medicalCareReportRequest.isExamExternalReport = true;
    medicalCareReportRequest.isExamInternalReport = true;
    medicalCareReportRequest.isPrescriptionReport = true;
    medicalCareReportRequest.isPrescriptionMemedReport = true;
    medicalCareReportRequest.isMedicalProcedureReport = true;
    medicalCareReportRequest.isIsolationReport = true;
    medicalCareReportRequest.isIsolationAcknowledgeReport = true;

    medicalCareReportRequest.isICPBrasilSigned = this.isIcpBrailSigned;
    medicalCareReportRequest.certificatePin = this.certificatePin;
    medicalCareReportRequest.idSector = this.idSector;
    medicalCareReportRequest.idBed = this.idBed;
    medicalCareReportRequest.isOnline = false;
    medicalCareReportRequest.email = this.patientEmail;

    return medicalCareReportRequest;
  }

  checkSigningResponsibility(): boolean {
    let token: UserLoginResponse = this.utilsService.getToken();
    return (token.listRole.map(x => x.idRole).includes(UserRoleEnum.requerer_assinatura_medico) && !token.isMaster);
  }

  onRemoveAttendant(thisParam, res: any) {
    thisParam.isAttendanceRemoval = false;

    if (res.userLogin != thisParam.userLogin) {
      thisParam.isAttendanceRemoval = true;

      thisParam.websocketAttendanceUtilService.clearSocket();
      let ref = thisParam.dialog.open(AttendanceOccupiedRemoveModalComponent, { data: { ...res } });
      ref.afterClosed().subscribe(() => {
        thisParam.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          if (this.isBedManagement)
            thisParam.router.navigate(['/medic/medic-bed-management', { idSector: thisParam.idSector, idRoom: thisParam.idRoom, idService: thisParam.idService }]);

          else
            thisParam.router.navigate(['/medic/medic-patient-list', { idSector: thisParam.idSector, idRoom: thisParam.idRoom, idService: thisParam.idService }]);
        }
        )
      })
    }
  }

  disableSession() {
    let $this = this;
    this.disabelSessionTimeout = setTimeout(() => {
      if (($this.isDisableExternalRequest && $this.isUnidentifiedPatient)) {
        $this.isDisableSession = true;
      }
      clearTimeout($this.disabelSessionTimeout);
    }, 700)
  }
}