<div class="prescription-size" mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-exam-modal-list">
            <span class="bold-name">Paciente: </span>
            <span> {{data.episode.patientSocialName? data.episode.patientSocialName :
                data.episode.patientName}}
            </span>
        </div>

        <div class="body-exam-modal-list">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="row">
                    <div class="col-12 column-name-title" *ngFor="let prescription of this.data.solution.listPrescription">
                        <div class="col-4 column-name">
                            <span class="bold-name">Nome do medicamento:</span>
                            <span>{{prescription.medicineName}}</span>
                        </div>
                        <div class="col-3 column-name">
                            <span class="bold-name">Quantidade:</span>
                            <span>{{prescription.quantity}}</span>
                        </div>
                        <div class="col-3 column-name">
                            <span class="bold-name">Unidade:</span>
                            <span>{{prescription.measurementUnitName}}</span>
                        </div>
                        <div class="col-2 column-button">
                            <div *ngIf="prescription.idStatusPrescription == statusOpenPrescriptionEnum || prescription.idStatusPrescription == null || prescription.idStatusPrescription == undefined">
                                <button class="custom-button" mat-flat-button color="primary" type="button"
                                    (click)="openPrescriptionSolutionModal(prescription)">Liberar</button>
                            </div>
                            <div *ngIf="prescription.idStatusPrescription == statusLiberatePrescriptionEnum">
                                <span class="type-dispensing">Dispensado</span>
                            </div>
                            <div *ngIf="prescription.idStatusPrescription == statusNotDispensationPrescriptionEnum">
                                <span class="type-non-dispensing">Não dispensado</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 column-name-title">
                        <div class="col-4 column-name">
                            <span class="bold-name">Apresentação:</span>
                            <span>{{this.data.solution.presentationName}}</span>
                        </div>
                        <div class="col-3 column-name">
                            <span class="bold-name">Uso:</span>
                            <span>{{this.data.solution.usage}}</span>
                        </div>
                        <div class="col-3 column-name">
                            <span class="bold-name">Forma de consumo:</span>
                            <span>{{this.data.solution.administrationTypeName}}</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 column-name-title">
                        <div class="col-4 column-name">
                            <span class="bold-name">Frequência:</span>
                            <span>{{this.data.solution.frequencyName}}</span>
                        </div>
                        <div class="col-3 column-name">
                            <span class="bold-name">Período de tratamento:</span>
                            <span>{{this.data.solution.treatmentPeriodName}}</span>
                        </div>
                        <div class="col-3 column-name">
                            <span class="bold-name">Diluição:</span>
                            <span>{{this.data.solution.dilutionName}}</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 column-name-title">
                        <div class="col-4 column-name">
                            <span class="bold-name">Observação:</span>
                            <span>{{this.data.solution.observation}}</span>
                        </div>
                        <div class="col-3 column-name">
                            <span class="bold-name">Unidade de diluição:</span>
                            <span>{{this.data.solution.dilutionUnitName}}</span>
                        </div>
                        <div class="col-3 column-name">
                            <span class="bold-name">Quantidade de diluição:</span>
                            <span>{{this.data.solution.dilutionQuantity}}</span>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-md-6">
                        <app-select-2 [formControlField]="'listIdKit'" [multiple]="true" [url]="urlListKit"
                            [idField]="'idKit'" [nameField]="'codeWithName'" [labelField]="'Kit'">
                        </app-select-2>
                    </div>
                    <div class="col-12 col-md-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Local</mat-label>
                            <mat-select formControlName="idLocation" (selectionChange)="selectLocation($event)">
                                <mat-option *ngFor="let item of listLocation" [value]="item.idLocation">
                                    {{item.locationName}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idLocation').invalid">Informe o local</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6 toggle">
                        <mat-slide-toggle [disabled]="model.get('idLocation').invalid" class="indigent-checkbox"
                            [ngModelOptions]="{standalone: true}" [(ngModel)]="this.haveListItem"
                            (change)="changeItems($event)">Materiais e quantidades Adicionais</mat-slide-toggle>
                    </div>
                </div>
                <ng-container *ngIf="this.haveListItem">
                    <div *ngFor="let item of listItemFields">
                        <app-prescription-modal-item-select [kitItemSelectStruct]="item"
                            [location]="this.model.get('idLocation').value" (addItemEvent)="addItem($event)"
                            (removeItemEvent)="removeItem($event)">
                        </app-prescription-modal-item-select>
                    </div>
                </ng-container>
            </form>
        </div>
        <div class="footer-exam-modal-list row">
            <div class="col-8 col-md-4 col-sm-3">
                <button mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">
                    <span>Fechar</span>
                </button>
            </div>
            <div class="col-8 col-md-4 col-sm-3">
                <button class="button-non-dispense" mat-flat-button color="primary" class="btn-block"
                    (click)="nonDispensationAllModalComponent(this.data.solution.listPrescription)" [disabled]="nonDispenseAll">
                    <span style="font-size: 13.7px;">Não dispensar todos</span>
                </button>
            </div>
            <div class="col-8 col-md-4 col-sm-3">
                <button mat-flat-button color="primary" class="btn-block" (click)="submit()" [disabled]="!validated">
                    <span *ngIf="isLoading == false">Liberar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
            </div>
        </div>
    </div>
</div>