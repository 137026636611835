import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { HealthUnitRequest } from '../../requests/user/health-unit.request';
import { GetFullHealthUnitResponse } from '../../responses/user/get-full-health-unit.response';

@Injectable({
  providedIn: 'root'
})
export class HealthUnitService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getHealthUnit(): Observable<GetFullHealthUnitResponse> {

    let uri = `HealthUnit`;

    return this.httpClient.get<GetFullHealthUnitResponse>(environment.urlApiUser + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getHealthUnitConfig(): Observable<GetFullHealthUnitResponse> {

    let uri = `HealthUnit/getHealthUnit`;

    return this.httpClient.get<GetFullHealthUnitResponse>(environment.urlApiUser + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }


  public updateHealthUnit(body: HealthUnitRequest): Observable<ReturnStruct> {
    
    let uri = `HealthUnit`;
    
    return this.httpClient.put<ReturnStruct>(environment.urlApiUser + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public updateChoiceIsActiveMessageTrigger(messageTriggerActive: boolean): Observable<ReturnStruct> {
    
    let uri = `HealthUnit/messageTriggerActive/${messageTriggerActive}`;
    return this.httpClient.put<ReturnStruct>(environment.urlApiUser + uri, null, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public updatecanAddTissGuide(canAddTissGuide: boolean): Observable<ReturnStruct> {
    
    let uri = `HealthUnit/TissGuide/${canAddTissGuide}`;
    return this.httpClient.put<ReturnStruct>(environment.urlApiUser + uri, null, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
