import { Socket } from 'socket.io-client';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WebsocketAuditService } from '../websocket-audit.service';
import { AlertService, AlertType } from '../alert.service';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })

export class WebsocketAuditUtilService {

    constructor(private websocketAuditService: WebsocketAuditService,
        public dialog: MatDialog,
        private alertService: AlertService,
        private router: Router) { }
    socket: Socket;
    idHealthUnit: number = null;
    idAudit: number = null;
    login: string = null;
    seeOccupiedRoom: boolean;
    onClientAudit: Function;
    thisParam: any;

    getIdRoom(): number {
        return this.idHealthUnit;
    }


    returnSocket() {
        return this.socket;
    }

    isConnect(): boolean {
        return this.socket && this.socket.connected;
    }

    clearSocket(): void {
        if (this.socket && this.socket.connected)
            this.socket.disconnect();

        this.socket = null;
        this.idHealthUnit = null;
        this.seeOccupiedRoom = null;
    }

    connectwebsocketRoom(idHealthUnit: number, thisParam: any, idAudit: number, login: string): void {
        this.login = login;
        this.idAudit = idAudit;
        this.idHealthUnit = idHealthUnit;

        this.socket = this.websocketAuditService.websocketConnection();
        this.socket.emit('join', idHealthUnit)
            .on('clientAudit', (res) => {
                this.onClientAudit(res, thisParam)
            })
            .on("someoneIsConnecting", (res) => {
                if (res.login != this.login && this.idAudit === res.idAudit)
                    this.socket.emit('someoneIsConnect', { idAudit: this.idAudit, login: this.login, idHealthUnit: this.idHealthUnit})
            })
            .on("someoneIsConnect", (res) => {
                if (res.login != this.login && this.idAudit === res.idAudit) {
                    this.login = null;
                    this.idAudit = null;
                    this.alertService.show('Erro', "Não é possível acessar, pois outro usuário está auditando esta amostra.", AlertType.warning);
                    this.router.navigate(['/audit/audit']);
                }
            })
            .io.on("reconnect", (res) => {
                this.socket.emit('join', idHealthUnit);
            });
    }

    setThisParam(thisParam: any) {
        this.thisParam = thisParam;
    }

    setVariables(idAudit: number, login: string, idHealthUnit: number) {
        this.login = login;
        this.idAudit = idAudit;
        this.idHealthUnit = idHealthUnit;

        this.socket.emit('someoneIsConnecting', { idAudit, login, idHealthUnit });
    }

    clearVariables() {
        this.login = null;
        this.idAudit = null;
        this.idHealthUnit = null;
    }

    setFunctions(onClientAppointment: Function) {
        this.onClientAudit = onClientAppointment || this.blankFunction;
    }

    blankFunction() {

    }
}