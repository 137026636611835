import { ElementRef, ViewChild } from '@angular/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ClientGetPatientByCpfTotemService } from 'src/app/shared/services/API/orchestrator-totem/client-get-patient-by-cpf-totem.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { SearchPatientTotemRequest } from 'src/app/shared/services/requests/orchestrator-totem/search-patient-totem.request';
import { PatientFoundComponent } from './components/patient-found/patient-found.component';
import { PatientNotFoundComponent } from './components/patient-not-found/patient-not-found.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PatientArrivedEarlyLateModalComponent } from './components/patient-arrived-early-late-modal/patient-arrived-early-late-modal.component';
import { PatientWithoutSchedulingModalComponent } from './components/patient-without-scheduling-modal/patient-without-scheduling-modal.component';
import { UtilsClientService } from 'src/app/client/utils.service';
import { VerifyCPF } from 'src/app/shared/custom-validators/cpf.validator';

@Component({
  selector: 'app-cpf-totem',
  templateUrl: './cpf-totem.component.html',
  styleUrls: ['./cpf-totem.component.css']
})
export class CpfTotemComponent implements OnInit {

  @Input() idHealthUnit: number;
  @Input() timeZoneValue: number;
  @Input() scheduled: boolean = false;

  @Output() onCpfDefined: EventEmitter<string | number> = new EventEmitter<string | number>();
  @Output() onBack: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild("cpfInput", { static: true }) cpfInput: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    private maskService: MaskService,
    private clientGetPatientByCpfTotemService: ClientGetPatientByCpfTotemService,
    private utilsClientService: UtilsClientService,
    private alertService: AlertService,
    public dialog: MatDialog
  ) { }

  public model: FormGroup;
  public masks: Masks;
  public cpfCnsMask;
  public cpfMask;
  isLoading: boolean = false;
  public patientWithoutRegistration: number = 2;
  public arrivedEarly: number = 3;
  public arrivedLate: number = 4;

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.cpfCnsMask = this.masks.cpfCns;
    this.cpfMask = this.masks.cpf;
    this.model = this.formBuilder.group({
      cpfCns: ['', [Validators.required]],
    });
    this.setFocusOnCpfInput();

    if (this.scheduled) {
      this.model.get('cpfCns').setValidators([VerifyCPF(), Validators.required]);
      this.model.get('cpfCns').updateValueAndValidity();
    }
  }

  fowardWithoutCPF() {
    this.model.setValue({ cpfCns: '' });
    this.onCpfDefined.emit('');
  }

  back() {
    this.onBack.emit();
  }

  submit() {
    if (this.model.invalid || this.isLoading == true)
      return;

    let cns: string = null;
    let cpf: string = null;

    if (this.model.get('cpfCns').value.replace(/[^0-9]/g, '').replace(' ', '').length <= 11)
      cpf = this.model.get('cpfCns').value.replace(/[^0-9]/g, '').replace(' ', '');
    else
      cns = this.model.get('cpfCns').value.replace(/[^0-9]/g, '').replace(' ', '');

    if (!this.scheduled)
      this.searchByCpfCns(cpf, cns);
    else
      this.searchByCpfSchedule(cpf ?? cns);
  }

  searchByCpfCns(cpf: string, cns: string) {
    this.isLoading = true;
    let request: SearchPatientTotemRequest = new SearchPatientTotemRequest();
    request.cpf = cpf;
    request.cns = cns;

    this.clientGetPatientByCpfTotemService.getClientPatientByCPF(this.idHealthUnit, request).subscribe({
      next: (response) => {
        if (response.isError && response.errorCode == 1) {
          this.openPatientNotFoundModal();
        }
        else if (response.isError && response.errorCode == 2) {
          this.alertService.show('Atenção! ', response.errorDescription, AlertType.warning);
          this.openPatientNotFoundModal();
        }

        else if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
        }
        else
          this.openPatientFoundModal(response);

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  searchByCpfSchedule(cpf: string) {
    this.isLoading = true;
    let request: SearchPatientTotemRequest = new SearchPatientTotemRequest();
    request.cpf = cpf;

    this.clientGetPatientByCpfTotemService.getClientPatientScheduleByCPF(this.idHealthUnit, this.timeZoneValue, request).subscribe({
      next: (response) => {
        if (response.isError && response.errorCode == 1) {
          this.openPatientNotFoundModal();
        }

        else if (response.isError && response.errorCode == this.patientWithoutRegistration)
          this.openPatientWithoutSchedulingModal();

        else if (response.isError && response.errorCode == this.arrivedEarly)
          this.openPatientArrivedEarlyOrLateModal(this.arrivedEarly);

        else if (response.isError && response.errorCode == this.arrivedLate)
          this.openPatientArrivedEarlyOrLateModal(this.arrivedLate);

        else if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
        }
        else
          this.openPatientFoundModal(response);

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openPatientNotFoundModal() {
    const dialogRef = this.dialog.open(PatientNotFoundComponent, {
    });
  }

  openPatientFoundModal(patient) {
    this.utilsClientService.setIdUserPatientSchedule(patient.idUserPatient);
    this.utilsClientService.setIdSchedule(patient.idSchedule);
    const dialogRef = this.dialog.open(PatientFoundComponent, {
      data: patient
    });
    dialogRef.afterClosed().subscribe({
      next: (res) => {
        if (res)
          this.onCpfDefined.emit(patient);
        else {
          this.model.setValue({ cpfCns: '' });
          this.setFocusOnCpfInput();
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  openPatientArrivedEarlyOrLateModal(arrived: number) {
    const dialogRef = this.dialog.open(PatientArrivedEarlyLateModalComponent, {
      data: {
        arrived: arrived,
      },
      panelClass: "totem-modal",
    });
    dialogRef.afterClosed().subscribe({
      next: (res) => {
        this.back();
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  openPatientWithoutSchedulingModal() {
    const dialogRef = this.dialog.open(PatientWithoutSchedulingModalComponent, {
      panelClass: "totem-nps-modal",
    });
    dialogRef.afterClosed().subscribe({
      next: (res) => {
        this.back();
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  private setFocusOnCpfInput() {
    setTimeout(() => {
      this.cpfInput.nativeElement.focus();
    }, 0);
  }

  logModel() {
    console.log(this.model)
  }
}