import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { environment } from 'src/environments/environment';
import { TotemNpsService } from 'src/app/shared/services/API/nps/totem-nps.service';
import { TotemNps } from 'src/app/shared/services/models/nps/totem-nps.model';
import { TotemNpsDeleteModalComponent } from './totem-nps-delete-modal/totem-nps-delete-modal.component';

@Component({
  selector: 'app-totem-nps-list',
  templateUrl: './totem-nps-list.component.html',
  styleUrls: ['./totem-nps-list.component.css']
})
export class TotemNpsListComponent implements OnInit {

  constructor(private totemNpsService: TotemNpsService,
    private alertService: AlertService,
    private utilService: UtilService,
    public dialog: MatDialog) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.master;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.master_totem_nps;

  public listTotemNps: TotemNps[];
  public isLoading: boolean = true;
  public isActive: boolean;
  public searchText: string;
  ip: string = '192.168.0.2';

  ngOnInit(): void {
    this.search();

    setTimeout(() => { this.getIP(); }, 2000);
  }

  getIP() {
    this.utilService.getIP().subscribe({
      next: (response) => {
        if (response && response.ip) {
          this.ip = response.ip;
        }
      }
    });
  }

  search() {
    this.isLoading = true;
    if (this.searchText == "")
      this.searchText = null;

    this.totemNpsService.listTotemNps(this.searchText, this.isActive).subscribe({
      next: (response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listTotemNps = response.listTotemNps;

        this.listTotemNps.forEach(x => {
          x.totemNpsUrl = environment.urlApiBaseUI + "client/nps-totem/" + x.idTotemNps;
        });

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }

  openModal(idTotemNps) {
    const dialogRef = this.dialog.open(TotemNpsDeleteModalComponent, {
      data: {
        idTotemNps: idTotemNps,
        ip: this.ip
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.deleteTotemNps) {
        this.search();
      }
    });
  }

  onCopy(text) {
    this.alertService.show('Sucesso', text, AlertType.success);
  }
}