import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { ListLayoutResponse } from '../../responses/panel/list-layout.response';

@Injectable({
  providedIn: 'root'
})
export class LayoutService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listLayout(): Observable<ListLayoutResponse> {
    
    let uri = `Layout/getAll`;  
    
    return this.httpClient.get<ListLayoutResponse>(environment.urlApiPanel + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
