<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <h1>Dashboards</h1>
        <div class="white-body">
            <a mat-flat-button color="primary" class=" add-user" routerLink="/dashboard/register">
                <mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon> Cadastrar Dashboard
            </a>
            <div class="filters">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Pesquisar</mat-label>
                            <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText"
                                (keyup)="onKeySearch($event)">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Tags</mat-label>
                            <mat-select [(ngModel)]="listIdTags" multiple (selectionChange)="search()">
                                <mat-option *ngFor="let item of listAllTags" value="{{item.idTag}}">
                                    {{item.tagName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="card-list" *ngIf="listDashboard != null">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listDashboard">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary">{{item.dashboardName}}</span>
                                <span class="secundary">Última atulização: {{item.datetimeUpdate | date: 'dd/MM/yyyy
                                    HH:mm'}}</span>
                                <span class="secundary">Atulizado por: {{item.userName}}</span>
                                <span class="secundary">Criado: {{item.datetimeInclusion | date: 'dd/MM/yyyy
                                    HH:mm'}}</span>
                                <span class="secundary">{{item.tagConcat}}</span>
                            </div>
                            <div class="actions">
                                <a>
                                    <mat-icon aria-hidden="false" aria-label="Gráficos"
                                        [routerLink]="['/dashboard/graph/register', {idDashboard: item.idDashboard}]">
                                        bar_chart</mat-icon>
                                </a>
                                <a>
                                    <mat-icon aria-hidden="false" aria-label="Editar"
                                        (click)="buttonFavorite(item.idDashboard,item.isFavorite)">
                                        {{item.isFavorite ? 'bookmark':'bookmark_border'}}</mat-icon>
                                </a>
                                <a>
                                    <mat-icon aria-hidden="false" aria-label="Editar"
                                        [routerLink]="['/dashboard/register', {idDashboard: item.idDashboard}]">edit
                                    </mat-icon>
                                </a>
                                <a>
                                    <mat-icon aria-hidden="false" aria-label="Editar"
                                        (click)="openModal(item.idDashboard)">
                                        delete_forever</mat-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <app-empty-list *ngIf="listDashboard != undefined && listDashboard.length == 0"></app-empty-list>
        </div>

        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>
</div>