import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UpgradeModalComponent } from 'src/app/shared/components/upgrade-modal/upgrade-modal.component';
import { ModuleStruct } from 'src/app/shared/services/structs/user/module.struct';

@Component({
  selector: 'app-module-modal',
  templateUrl: './module-modal.component.html',
  styleUrls: ['./module-modal.component.css']
})
export class ModuleModalComponent implements OnInit, OnDestroy {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ModuleModalComponent>,
    private router: Router,
  ) {}

  public isLoading: boolean;
  public filteredListModuleStruct: ModuleStruct[];
  public nameModuleGroup: string;
  public iconClass: string;

  ngOnInit(): void {
    this.nameModuleGroup = this.data.nameModuleGroup;
    this.iconClass = this.data.iconClass;
    this.filteredListModuleStruct = this.data.filteredListModuleStruct;
  }

  ngOnDestroy() {
    this.closeModal();
  }

  closeModal() {
    this.matDialogRef.close();
  }

  redirect(module: ModuleStruct) {
    if (module.isModulePermitted) {
      this.router.navigate([module.uri]);
      this.closeModal();
    }
    else
      this.openModal();
  }

  openModal() {
    const dialogRef = this.dialog.open(UpgradeModalComponent, {
      data: {},
    });
  }
}