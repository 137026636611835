export class NewAttendanceBlockStruct {
    public listIdAllergies: number[];
    public evolution: string;
    public medicalNotes: string;
    public physicalExamination: string;
    public cid: number[];
    public therapeuticPlan: string;
    public diagnosedHypothesis: string;
    public temperature: number;
    public respiratoryFrequency: number;
    public heartRate: number;
    public glucose: number;
    public bloodPressure: number;
    public saturation: number;
}