<div class="border-list">
    <div class="title-list">
        <div class="row">
            <div class="col-6 col-sm-6 col-md-4">
                <span class="subtitle">Prescrições Não padronizadas</span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6 col-sm-6 col-md-4">
            <a mat-flat-button type="button" (click)="openExternalPrescriptionModal(-1, null, false)"
                color="accent" class=" btn-block">
                <mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon> Adicionar Prescrição Não
                Padronizada
            </a>
        </div>
    </div>
    <div class="card-list" *ngIf="listExternalPrescription != null && listExternalPrescription.length > 0">
        <div class="row row-list">
            <div class="col-12 col-sm-6 col-md-4"
                *ngFor="let item of listExternalPrescription; let index = index;">
                <div class="card-item">
                    <div class="infos">
                        <span class="primary">{{item.description}}</span>
                        <span class="secundary">Quantidade: {{item.quantity}}
                            {{item.measurementUnitName}}</span>
                        <span class="secundary">Frequência: {{item.frequency}}</span>
                        <span class="secundary">Observação: {{item.observation}}</span>
                    </div>
                    <div class="actions">
                        <a>
                            <mat-icon aria-hidden="false" aria-label="Editar"
                                (click)="openExternalPrescriptionModal(index, item, true)">edit</mat-icon>
                        </a>
                        <a>
                            <mat-icon aria-hidden="false" aria-label="Excluir"
                                (click)="deleteExternalPrescription(index)">delete</mat-icon>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>