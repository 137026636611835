import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { MetaDataStruct } from '../../structs/meta-data/meta-data.struct';
import { MultiProfessionalConfigMedicalAttendanceResponse } from '../../responses/multi-professional/get-multi-professional-config-medical-attendance.response';

@Injectable({
  providedIn: 'root'
})
export class MultiProfessionalConfigMedicalServiceService extends CommonService {

  constructor(private httpClient: HttpClient) { super(); }

  public GetMultiProfessionalConfigMedicalService(listMetaData: MetaDataStruct[], idSector: number): Observable<MultiProfessionalConfigMedicalAttendanceResponse> {
    let uri = `MultiProfessionalConfigMedicalService/idSector/${idSector}`;

    return this.httpClient.post<MultiProfessionalConfigMedicalAttendanceResponse>(environment.urlApiMultiProfessional + uri, listMetaData, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }
}