export class MemedMedicine {
    public idMemedMedicine: number;
    public idMemedPrescription: number;
    public idMemed: number;
    public name: string;
    public description: boolean;
    public quantity: number;
    public unit: string;
    public controleEspecial: number;
    public altoCusto: boolean;
    public anamnese: string;
    public dataSolicitacao: Date;
    public receituario: string;
    public tipoExameSelecionado: string;
    public tarja: string;
    public tipo: string;
    public sanitizedPosology: string;
    public via: string;
    public origem: string;
    public subtitulo: string;
    public titulo: string;
    public datetimeInclusion: Date;
}