<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-audit-modal-warning">
            <h1><b>Atenção!</b></h1>
         </div>
         <div class="alert-img">
            <img src="assets/images/alert.png" aria-hidden="false" aria-label="alert" />
        </div>
         <div class="body-audit-modal-warning">
            As classificações auditadas foram salvas com sucesso, porém, ainda existem outras a serem auditadas.
         </div>
         <div class="footer-audit-modal-warning">
            <button mat-flat-button color="primary" class="btn-block" (click)="close()">
                <span *ngIf="isLoading == false">OK</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
         </div>          
    </div>
</div>