<div class="display">
    <header *ngIf="!authenticated">
        <img class="brand" src="assets/images/logo-branco.png">
    </header>
    <div *ngIf="!authenticated && !onlineOffline" class="offline">
        <span><mat-icon class="offline--icon">wifi_off</mat-icon>Você esta sem conexão com a internet no momento.</span>
    </div>
    <app-pin-display *ngIf="!authenticated" (onAuthenticated)="checkPin($event)" [isLoading]="isLoading">
    </app-pin-display>
    <app-display-options *ngIf="authenticated" [idPanel]="idPanel" [logoString64]="panelStruct.imgString64"
        [idLayout]="panelStruct.idLayout" [video64]="panelStruct.video64" [videoFormat]="panelStruct.videoFormat"
        [lastHourWaitingTimeAverage]="lastHourWaitingTimeAverage" [showAverageWaitingTime]="showAverageWaitingTime"
        [panelTime]="panelTime" (keepAlive)="keepAlive()" (getDate)="getHealthUnitTime()"></app-display-options>
</div>