import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { UpdateStatusQueueService } from 'src/app/shared/services/API/orchestrator-queue/update-status-queue.service';
import { WebsocketTelemedicineService } from 'src/app/shared/services/websocket-telemedicine.service';
import { Router } from '@angular/router';
import { ReturnToQueueService } from 'src/app/shared/services/API/queue/return-to-queue.service';
import { WebsocketAppointmentUtilService } from 'src/app/shared/services/websocket-util/websocket-appointment-util.service';
import { WebsocketAppointmentActionEnum } from 'src/app/shared/enum/websocket-appointment-action.enum';


@Component({
  selector: 'app-medic-hold-appointment-modal',
  templateUrl: './medic-hold-appointment-modal.component.html',
  styleUrls: ['./medic-hold-appointment-modal.component.css']
})
export class MedicHoldAppointmentModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<MedicHoldAppointmentModalComponent>,
    public updateStatusQueueService: UpdateStatusQueueService,
    public returnToQueueService: ReturnToQueueService,
    public alertService: AlertService,
    public websocketTelemedicineService: WebsocketTelemedicineService,
    public router: Router,
    public websocketAppointmentUtilService: WebsocketAppointmentUtilService,
  ) {
    this.matDialogRef.disableClose = true;
  }

  public seconds: any;
  public interval: any;
  public mockTime: string = ":00"
  public timePassed: number = 0;
  public notShowPatient: boolean = false;
  public idPatientEpisode: number;

  ngOnInit(): void {
    this.mockTime = this.data.appointmentDuration + this.mockTime;
    this.seconds = this.data.appointmentDuration * 60;
    this.startTime();
    this.startWebsocket();
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  //Websocket
  startWebsocket() {
    if (!this.websocketAppointmentUtilService.isConnect()) {
      this.websocketAppointmentUtilService.connectwebsocketRoom(this.idPatientEpisode, this);
    }

    this.websocketAppointmentUtilService.setThisParam(this);
    this.websocketAppointmentUtilService.setFunctions(this.verifyTrigger, null, null, null, null);
  }

  verifyTrigger(client: any, thisParam: any) {
    thisParam.socketDisconneted = false;
    if (client.action == WebsocketAppointmentActionEnum.patientEnter) {
      thisParam.confirmAttend();
    }
  }

  confirmAttend() {
    clearInterval(this.interval);
    this.matDialogRef.close();
  }

  startTime() {
    this.interval = setInterval(myMethod, 1000);

    let duration = this.seconds;
    let timePassed = 0;
    this.mockTime = null;
    var $this = this;
    function myMethod() {
      if (duration > 0) {
        duration = duration - 1;
        $this.timePassed += 1;
        if ($this.timePassed / 60 >= 10)
          $this.notShowPatient = true;
      }

      let minutes = String(Math.trunc(duration / 60));
      let seconds = String(duration % 60);

      seconds = seconds.length === 1 ? "0" + seconds : seconds;
      let time: string = minutes + " min " + seconds + "s";

      if (document.getElementById('segundo') != null) {
        document.getElementById('segundo').innerHTML = time;
      }

      if (duration == 0) {
        clearInterval(this.interval);
        $this.matDialogRef.close({ isCancel: false })
        $this.matDialogRef.close({ timeIsOver: true })
      }
    }
  }

  cancelAttendance() {
    this.matDialogRef.close({ isCancel: true })
  }
}