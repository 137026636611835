export class AihStruct {
    public idAih: number;
    public idAihStatus: number;
    public idUser: number;

    //Identificação estabelecimento de saúd
    public idHealthUnit: number;
    public requesterHealthUnit: string;
    public requesterCnes: string;
    public executantHealthUnit: string;
    public executantcnes: string;

    //Identificação pacient
    public patientName: string;
    public idEpisode: number;
    public cns: string;
    public birthDate: string;
    public gender: string;
    public race: string;
    public motherName: string;
    public motherPhone: string;
    public guardianName: string;
    public guardianPhone: string;

    //Rua,Numero,bairro
    public completeAddress: string;

    public city: string;
    public ibgeCode: string;
    public uf: string;
    public zipCode: string;

    //Justificativa Internação
    public principaisSintomas: string;
    public conditions: string;
    public examResults: string;
    public initialDiagnostic: string;
    public mainCid: string;
    public secondaryCid: string;
    public associatedCid: string;


    //Procedimento Solicitado
    public procedureDescription: string;
    public procedureCode: string;
    public clinic: string;
    public caraterDaInternacao: string;
    public documentType: string;
    public documentNumber: string;
    public userName: string;
    public requestDate: string;
    public signature: string;

    //Preencher em caso de causas externas (acidentes ou violências)
    public acctidentType: string;
    public insuranceCnpj: string;
    public ticketNumber: string;
    public series: string;
    public companyCnpj: string;
    public companyCnae: string;
    public companyCbor: string;
    public socialSecurityLink: string;

    public datetimeInclusion: Date;
}