<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-data-source-modal-list">
            <h2>Deseja alterar o tipo da Fonte de Dados?</h2>
         </div>
         
         <div class="body-data-source-modal-list">
            Ao alterar a seleção do tipo de fonte de dados, você perderá toda a alteração desta fonte de dados já realizada. Você realmente deseja alterar?
         </div>
         <div class="footer-data-source-modal-list">
            <button mat-flat-button color="primary" class="btn-block" (click)="clickChange()">
                <span>Sim, alterar</span>
            </button>
            <button mat-flat-button color="accent" class="btn-block"(click)="clickCancel()">Cancelar</button>
        </div>
    </div>
</div>

