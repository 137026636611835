import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { IntegrationLogService } from 'src/app/shared/services/API/integration/integration-log.service';
import { LogTypeService } from 'src/app/shared/services/API/integration/log-type.service';
import { UriService } from 'src/app/shared/services/API/integration/uri.service';
import { LogTypeModel } from 'src/app/shared/services/models/integration/log-type.model';
import { UriModel } from 'src/app/shared/services/models/integration/uri.model';
import { IntegrationLogStruct } from 'src/app/shared/services/structs/integration/integration-log.struct';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-integration-log',
  templateUrl: './integration-log.component.html',
  styleUrls: ['./integration-log.component.css']
})
export class IntegrationLogComponent implements OnInit {

  constructor(private integrationLogService: IntegrationLogService,
    private uriService: UriService,
    private logTypeService: LogTypeService,
    private router: Router,
    private alertService: AlertService,
    private utilService: UtilService,
    public dialog: MatDialog) { }
    
    public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.integration;
    public menuFunctionalityEnum:MenuFunctionalityEnum = MenuFunctionalityEnum.integration_log;
    
    public listUri: UriModel[];
    public listLogType: LogTypeModel[];
    public listIntegrationLog: IntegrationLogStruct[];
    public isLoading: boolean;
    public idLogType: number;
    public idUri: number;
    public searchText: string;
    public dateTimeInitRequest: string;
    public fullListSize: number;
    public idEpisode: number = null;

  ngOnInit(): void {
    this.isLoading = true;
    this.fullListSize = 0;
    this.populateLogTypeSelect();
    this.PopulateUriSelect();
    this.search(0);
  }

  search(pageIndex: number){
    this.isLoading = true;  
    
    this.integrationLogService.listIntegrationLog(this.idUri, this.idLogType, this.dateTimeInitRequest, pageIndex, this.searchText, this.idEpisode).subscribe({
      next:(response)=>{
      
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.listIntegrationLog = response.listIntegrationLog.paginatedIntegrationLog; 
        this.fullListSize = response.listIntegrationLog.fullListSize;
        this.isLoading = false;
      },
      error:(error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  PopulateUriSelect() {
    this.uriService.listUri().subscribe({
      next:(response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
  
          return;
        }
  
        this.listUri = response.listUri;
      },
      error:(error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  dateChage(event) {
    if (event.value == null) {
      this.dateTimeInitRequest = null;
      this.search(0);
      return;
    }

    var date = new Date(Date.parse(event.value));

    var dateComponents = date.toLocaleString().split(" ")[0].split("/");

    this.dateTimeInitRequest = dateComponents[2].replaceAll(",","") + "-" + dateComponents[1] + "-" + dateComponents[0];

    this.search(0);
  }

  populateLogTypeSelect() {
    this.logTypeService.listLogType().subscribe({
      next:(response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
  
          return;
        }
  
        this.listLogType = response.listLogType;
      },
      error:(error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search(0);
      }
    }, 1000);
  }

  changePage(event: PageEvent) {
    this.search(event.pageIndex);
  }
}

