import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ListCboResponse } from '../../responses/user/list-cbo.response';

@Injectable({
  providedIn: 'root'
})
export class CboService  extends CommonService{
  
  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }
    
  public listCbo(): Observable<ListCboResponse> {
      
    return this.httpClient.get<ListCboResponse>(environment.urlApiUser + "Cbo", this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public listAllCbo(): Observable<ListCboResponse> {
      
    return this.httpClient.get<ListCboResponse>(environment.urlApiUser + "Cbo/getAll", this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}