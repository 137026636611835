import { Component, EventEmitter, Input, OnInit, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { GraphTypeEnum } from 'src/app/shared/enum/graph-type.enum';
import { DashboardGraphsResponse } from 'src/app/shared/services/responses/orchestrator-data-intelligence/dashboard-graphs.response';

@Component({
  selector: 'app-plot-graph',
  templateUrl: './plot-graph.component.html',
  styleUrls: ['./plot-graph.component.css']
})
export class PlotGraphComponent implements OnInit {

  constructor() { }

  @Input() dashboardResponse: DashboardGraphsResponse;
  @Output("openModal") openModal: EventEmitter<any> = new EventEmitter();
  @Output("openModalExclude") openModalExclude: EventEmitter<any> = new EventEmitter();


  public listGraphsOrder: any[] = [];
  public graphTypeCounter: number = GraphTypeEnum.counter.valueOf();
  public graphTypeColumns: number = GraphTypeEnum.columns.valueOf();
  public graphTypeLines: number = GraphTypeEnum.lines.valueOf();
  public graphTypePie: number = GraphTypeEnum.pie.valueOf();
  public graphTypeTable: number = GraphTypeEnum.table.valueOf();


  public size: number = 1;
  ngOnInit(): void {
    let totalList = [...this.getArrayOrderWithType()];
    this.listGraphsOrder = [...this.getGraphsOrdered(totalList)];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dashboardResponse) {
      if (!changes.dashboardResponse.currentValue) return;
      if (changes.dashboardResponse.currentValue !== changes.dashboardResponse.previousValue) {
        let totalList = [...this.getArrayOrderWithType()];
        this.listGraphsOrder = [...this.getGraphsOrdered(totalList)];
      }
    }
  }


  getArrayOrderWithType(): any[] {

    let listColumn: any[] = this.dashboardResponse.listColumnGraphData && this.dashboardResponse.listColumnGraphData.length ?
      this.dashboardResponse.listColumnGraphData.reduce((acc, cur) => {
        let { order, idGraphType } = cur;
        acc.push({ order, idGraphType });
        return acc;
      }, []) : [];

    let listCount = this.dashboardResponse.listCountGraphData && this.dashboardResponse.listCountGraphData.length ?
      this.dashboardResponse.listCountGraphData.reduce((acc, cur) => {
        let { order, idGraphType } = cur;
        acc.push({ order, idGraphType });
        return acc;
      }, []) : [];
    let listLine = this.dashboardResponse.listLineGraphData && this.dashboardResponse.listLineGraphData.length ?
      this.dashboardResponse.listLineGraphData.reduce((acc, cur) => {
        let { order, idGraphType } = cur;
        acc.push({ order, idGraphType });
        return acc;
      }, []) : [];
    let listPizza = this.dashboardResponse.listPizzaGraphData && this.dashboardResponse.listPizzaGraphData.length ?
      this.dashboardResponse.listPizzaGraphData.reduce((acc, cur) => {
        let { order, idGraphType } = cur;
        acc.push({ order, idGraphType });
        return acc;
      }, []) : [];
    let listTable = this.dashboardResponse.listTableGraphData && this.dashboardResponse.listTableGraphData.length ?
      this.dashboardResponse.listTableGraphData.reduce((acc, cur) => {
        let { order, idGraphType } = cur;
        acc.push({ order, idGraphType });
        return acc;
      }, []) : [];

    let totalList: any[] = [...listColumn, ...listCount, ...listLine, ...listPizza, ...listTable];

    totalList.sort((a, b) => a.order - b.order);

    return totalList;
  }

  getGraphsOrdered(totalList: any[]): any[] {
    let graphOrdered: any[] = [];
    totalList.forEach((c) => {
      if (c.idGraphType == this.graphTypeColumns) {
        graphOrdered.push(this.dashboardResponse.listColumnGraphData.find(x => x.order == c.order));
      } else if (c.idGraphType == this.graphTypeCounter) {
        graphOrdered.push(this.dashboardResponse.listCountGraphData.find(x => x.order == c.order));
      } else if (c.idGraphType == this.graphTypeLines) {
        graphOrdered.push(this.dashboardResponse.listLineGraphData.find(x => x.order == c.order));
      } else if (c.idGraphType == this.graphTypePie) {
        graphOrdered.push(this.dashboardResponse.listPizzaGraphData.find(x => x.order == c.order));
      } else if (c.idGraphType == this.graphTypeTable) {
        graphOrdered.push(this.dashboardResponse.listTableGraphData.find(x => x.order == c.order));
      }
    });
    return graphOrdered;
  }

  clickSettigns(idGraph: number) {
    this.openModal.emit(idGraph);
  }
  openExclude(idGraph: number) {
    this.openModalExclude.emit(idGraph);
  }
}
