import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { TasksQueuesRequest } from '../../requests/orchestrator-integration/tasks-queues.request';
import { ResendWebhookRequest } from '../../requests/orchestrator-integration/resend-webhook.request';

@Injectable({
  providedIn: 'root'
})
export class ResendWebhookService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public resendWebhook(resendWebhookRequest: ResendWebhookRequest): Observable<ReturnStruct> {

    let uri = `api/v1/ResendWebhook`;

    return this.httpClient.post<ReturnStruct>(environment.urlApiOrchestratorIntegration + uri, resendWebhookRequest, this.addHeaderHashWebhook(resendWebhookRequest.body.guid))
      .pipe(
        catchError(this.handleError)
      )
  }
}