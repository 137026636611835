import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ExamStruct } from 'src/app/shared/services/structs/SADT/exam.struct';
import { ExamService } from 'src/app/shared/services/API/SADT/exam.service';
import { ExamTypeModel } from 'src/app/shared/services/models/SADT/exam-type.model';
import { SadtPlaceEnum } from 'src/app/shared/enum/sadt-place.enum';
import { MedicFavoriteExamPrescriptionStruct } from 'src/app/shared/structs/medic/medic-favorite-exam-prescription.struct';
import { SadtTypeEnum } from 'src/app/shared/enum/sadt-type.enum';

@Component({
  selector: 'app-medic-favorite-sadt-modal',
  templateUrl: './medic-favorite-sadt-modal.component.html',
  styleUrls: ['./medic-favorite-sadt-modal.component.css']
})
export class MedicFavoriteSadtModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<MedicFavoriteSadtModalComponent>,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private examService: ExamService,
  ) { }

  public medicalSadt: MedicFavoriteExamPrescriptionStruct;
  public sadtModel: FormGroup;
  public selectedSadtList: any[] = [];
  public listLookupExam: ExamStruct[] = [];
  public listExamDisplay: ExamStruct[] = [];
  public listSelectedExamDisplay: ExamStruct[] = [];
  public listPlace: any[] = [];
  public idAction: number;
  public idFrequency: number
  public idTreatmentPeriod: number;
  public idSadt: number;
  public sadtOthers: number = 0;
  public fileUrl;
  public isLoading: boolean;
  public listExamType: ExamTypeModel[] = [];
  public listFilteredExamType: ExamTypeModel[] = [];
  public listExamTypeDisplay: ExamTypeModel[] = [];
  public externalPlace: number = 2;
  public examLocked: boolean;
  public isAllExams: boolean;
  public isExternal: boolean = false;
  public isInternal: boolean = false;
  public listSadtSchedule: any[] = [];

  ngOnInit(): void {
    this.medicalSadt = this.data.sadt;
    this.listExamType = this.data.listExamType;
    this.listFilteredExamType = this.listExamType.filter(x => x.idExamType != SadtTypeEnum.Outros);
    this.listExamTypeDisplay = this.listFilteredExamType;
    this.isAllExams = this.data.isAllExams;
    this.listPlace = this.data.listPlace;
    this.listSadtSchedule = this.data.listSadtSchedule;
    this.sadtModel = this.formBuilder.group({
      idTypeSadt: [{ value: null, disabled: this.data.readOnly }, Validators.required],
      firstChildGroup: this.formBuilder.group({
        idSadt: [{ value: '', disabled: this.data.readOnly }, Validators.required],
      }),
      idPlace: [{ value: null, disabled: this.data.readOnly }, Validators.required],
      sadtDescription: [{ value: '', disabled: this.data.readOnly }],
      idSadtSchedule: [{ value: null, disabled: this.data.readOnly }],
    });

    if (this.medicalSadt)
      this.populateValues();

    if (this.data.isExternal) {
      this.isExternal = true;
      this.sadtModel.get('idPlace').setValue(SadtPlaceEnum.Externo);
      this.sadtModel.get('idPlace').disable();
      this.onSelectPlaceExternal();
      this.onSelectExamType();
    }

    this.isLoading = false;
  }

  populateValues() {
    this.sadtModel.get('idPlace').setValue(this.medicalSadt.idPlace);
    this.onSelectPlaceExternal();
    this.getLookupSadt(this.medicalSadt.idExamType);

    if (this.medicalSadt.listIdExam && this.medicalSadt.listIdExam.length >= 0)
      this.sadtModel.get('firstChildGroup').get('idSadt').setValue(this.medicalSadt.listIdExam);

    if (this.medicalSadt.idSadtSchedule != null)
      this.sadtModel.get('idSadtSchedule').setValue(this.medicalSadt.idSadtSchedule);

    this.sadtModel.get('idTypeSadt').setValue(this.medicalSadt.idExamType);
    this.sadtModel.get('sadtDescription').setValue(this.medicalSadt.description);

    this.onSelectExamType();
  }

  getLookupSadt(idExamType: number) {
    this.examService.listAllExam(idExamType).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.isAllExams = false;
        this.listLookupExam = response.listExam;
        this.listExamDisplay = this.listLookupExam;
        if (this.medicalSadt)
          this.listSelectedExamDisplay = this.listExamDisplay.filter(c => this.medicalSadt.listIdExam.find(x => x == c.idExam));
      }
    });
  }

  onSelectExamType() {
    this.listLookupExam = [];
    this.listExamDisplay = [];
    this.sadtModel.get('firstChildGroup').get('idSadt').setValue([]);
    this.onChangeSadt(this.sadtModel.get('idTypeSadt').value);
    if (this.sadtModel.get('idTypeSadt').value && this.sadtModel.get('idTypeSadt').value != this.sadtOthers)
      this.getLookupSadt(this.sadtModel.get('idTypeSadt').value);
  }

  onSelectPlaceExternal() {
    if (this.sadtModel.get('idPlace').value == this.externalPlace) {
      this.listExamTypeDisplay = this.listExamType;
      this.isInternal = false;
      this.sadtModel.get('idSadtSchedule').setValue(null);
    }
    else {
      this.sadtModel.get('idTypeSadt').setValue(null);
      this.isInternal = true;
      this.listExamTypeDisplay = this.listFilteredExamType;
    }
  }

  clickCancel() {
    this.matDialogRef.close();
  }

  close() {
    this.matDialogRef.close();
  }

  onSubmit() {
    if (this.sadtModel.invalid) {
      this.alertService.show('Erro', "Preencha todos os campos obrigatórios!", AlertType.error);
      return;
    }

    let newSadt: MedicFavoriteExamPrescriptionStruct = new MedicFavoriteExamPrescriptionStruct();
    let idSadts: number[] = this.sadtModel.get('firstChildGroup').get('idSadt').value;

    if (idSadts && idSadts.length >= 1) 
      newSadt.listIdExam = idSadts;
    
    newSadt.idExamType = this.sadtModel.get('idTypeSadt').value;
    newSadt.examTypeName = this.listExamType.find(c => c.idExamType == newSadt.idExamType).examTypeName;
    newSadt.idPlace = this.sadtModel.get('idPlace').value;
    newSadt.placeName = this.listPlace.find(c => c.idPlace == newSadt.idPlace).placeName;
    newSadt.description = this.sadtModel.get('sadtDescription').value;
    newSadt.idSadtSchedule = this.sadtModel.get('idSadtSchedule').value;

    if (newSadt.idSadtSchedule != null) 
      newSadt.sadtScheduleName = this.listSadtSchedule.find(x => x.idSadtSchedule === this.sadtModel.get('idSadtSchedule').value).sadtScheduleName;
    
    this.matDialogRef.close({ newSadt });
  }

  onChangeSadt(event: number) {
    this.idSadt = event;

    if (this.idSadt == this.sadtOthers) {
      this.sadtModel.get('sadtDescription').setValidators(Validators.required);
      this.sadtModel.get('firstChildGroup').get('idSadt').setValidators(Validators.nullValidator);
    } 
    else {
      this.sadtModel.get('sadtDescription').setValidators(Validators.nullValidator);
      this.sadtModel.get('firstChildGroup').get('idSadt').setValidators(Validators.required);
    }

    this.sadtModel.get('sadtDescription').updateValueAndValidity();
    this.sadtModel.get('firstChildGroup').get('idSadt').updateValueAndValidity();
  }

  onToggleChange(event: any) {
    if (event.checked)
      this.sadtModel.get('firstChildGroup').get('idSadt').setValue(this.listLookupExam.map(l => l.idExam));
    else
      this.sadtModel.get('firstChildGroup').get('idSadt').setValue([]);

    this.sadtModel.get('firstChildGroup').get('idSadt').updateValueAndValidity();
  }

  selectedValue(event: any) {
    if (event.length == this.listLookupExam.length)
      this.isAllExams = true;
    else
      this.isAllExams = false;
  }
}