import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ListBedResponse } from '../../responses/bed/list-bed-response.response';
import { BedRequest } from '../../requests/bed/bed.request';
import { GetBedResponse } from '../../responses/bed/get-bed-response.response';

@Injectable({
  providedIn: 'root'
})
export class BedService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listBed(searchText: string, idBedType: number, idBedStatus: number, idBedCategory: number): Observable<ListBedResponse> {
    
    let uri = 'Bed?'
    
    if(searchText != null)
      uri = uri+ `searchText=${searchText}&`; 

    if(idBedType !=null)
      uri = uri + `idBedType=${idBedType}&`

    if(idBedStatus !=null)
      uri = uri + `idBedStatus=${idBedStatus}&`

    if(idBedCategory !=null)
      uri = uri + `idBedCategory=${idBedCategory}&`
    
    return this.httpClient.get<ListBedResponse>(environment.urlApiBed + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public listBedGetAll(): Observable<ListBedResponse> {
    
    let uri = 'Bed/getAll'
    
    return this.httpClient.get<ListBedResponse>(environment.urlApiBed + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public getBed(idBed: number): Observable<GetBedResponse> {
    
    let uri = `Bed/idBed/${idBed}`;  
    
    return this.httpClient.get<GetBedResponse>(environment.urlApiBed + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public createBed(body: BedRequest): Observable<ReturnStruct>{

    let uri = `Bed`

    return this.httpClient.post<ReturnStruct>(environment.urlApiBed + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public updateBed(idBed: number, body: BedRequest): Observable<ReturnStruct>{

    let uri = `Bed/idBed/${idBed}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiBed + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public deleteBed(idBed: number): Observable<ReturnStruct>{

    let uri = `Bed/idBed/${idBed}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiBed + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public listAllBed(): Observable<ListBedResponse>{

    let uri = `Bed/getAll`

    return this.httpClient.get<ListBedResponse>(environment.urlApiBed + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
