<div [formGroup] = "model">
    <div class="title-header">
        <h1>Dados Adicionais</h1>
    </div>
    <div class="row">
        <div *ngFor="let item of listMetaData" class="col-12 col-sm-6 col-md-4">
            <mat-form-field *ngIf="item.metaData.idMetaDataType == metaDataTypeEnumResult.Texto" appearance="outline">
                <mat-label>{{item.metaData.metaDataName}}</mat-label>
                <input matInput formControlName="{{item.metaData.idMetaData}}" type="text">
            </mat-form-field>
            <mat-form-field *ngIf ="item.metaData.idMetaDataType == metaDataTypeEnumResult.Numero" appearance="outline">
                <mat-label>{{item.metaData.metaDataName}}</mat-label>
                <input matInput formControlName="{{item.metaData.idMetaData}}" type="number">
            </mat-form-field>
            <mat-form-field *ngIf ="item.metaData.idMetaDataType == metaDataTypeEnumResult.Data" appearance="outline">
                <mat-label>{{item.metaData.metaDataName}}</mat-label>
                <input matInput formControlName="{{item.metaData.idMetaData}}" type="text" [mask]="masks.date">
            </mat-form-field>
            <mat-form-field *ngIf ="item.metaData.idMetaDataType == metaDataTypeEnumResult.Select" appearance="outline">
                <mat-label>{{item.metaData.metaDataName}}</mat-label>
                <mat-select formControlName="{{item.metaData.idMetaData}}">
                    <mat-option *ngFor="let itemOption of item.listMetaDataOption" value="{{itemOption.metaDataOptionName}}">{{itemOption.metaDataOptionName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</div>