import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-signed-error-modal',
  templateUrl: './signed-error-modal.component.html',
  styleUrls: ['./signed-error-modal.component.css']
})
export class SignedErrorModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<SignedErrorModalComponent>) { }

  public listSignedHasError: string[] = this.data.listSignedHasError;

  ngOnInit(): void {
  }

  clickConfirm() {
    this.matDialogRef.close({ confirm: true });
  }

  clickCancel() {
    this.matDialogRef.close({ confirm: false });
  }
}
