export class TelemedicineConfig {
    public idTelemedicineConfig: number;
    public idHealthUnit: number;
    public useColor: boolean;
    public colorCode: string;
    public colorCodePreview: string;
    public isLogoUploaded: boolean;
    public logoName: string;
    public url: string;
    public urlName: string;
    public isActive: boolean;
    public isToSendSms: boolean;
    public idHealthIdentification: number;
    public datetimeInclusion: Date;
    public orientationsTitle: string;
    public orientationsDescription: string;
    public idTelemedicinePatientRedirectType: number;
    public waitingTimePatientDoctorInSeconds: number;
    public maxReturns: number;
    public useTelemedicineInPatientCenter: boolean;
    public isExternalSearchEnabled: boolean;
    public useTolifeLogo: boolean;
    public idExternalSearchField: number;
}
