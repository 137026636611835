import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FavoriteStandardMedicineService } from 'src/app/shared/services/API/medic/favorite-standard-medicine.service';
import { MedicFavoriteStandardPrescriptionService } from 'src/app/shared/services/API/medic/medic-favorite-standard-prescription.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { RelationFavoriteMedicineModel } from 'src/app/shared/services/models/pharmacy/relation-favorite-medicine.model';
import { MedicFavoriteStandardPrescriptionStruct } from 'src/app/shared/services/structs/medic/medic-favorite-standard-prescription.struct';
import { FavoriteMedicineStruct } from 'src/app/shared/services/structs/orchestrator-patient/favorite-medicine.struct';
import { MedicineStruct } from 'src/app/shared/services/structs/pharmacy/medicine.struct';

@Component({
  selector: 'app-select-favorite-medicine-modal',
  templateUrl: './select-favorite-medicine-modal.component.html',
  styleUrls: ['./select-favorite-medicine-modal.component.css']
})
export class SelectFavoriteMedicineModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<SelectFavoriteMedicineModalComponent>,
    private alertService: AlertService,
    private favoritePrescriptionService: MedicFavoriteStandardPrescriptionService,
    private favoriteMedicineService: FavoriteStandardMedicineService,
    private formBuilder: FormBuilder
  ) { }

  public model: FormGroup;
  public listMedicFavoritePrescription: MedicFavoriteStandardPrescriptionStruct[] = [];
  public loading: boolean = false;
  public selected: boolean = false;
  public idSector: number;
  public listMedicine: MedicineStruct[] = [];

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      idMedicFavoriteStandardPrescription: ['', [Validators.required]]
    });
    this.idSector = this.data.idSector
    this.listMedicine = this.data.listMedicine
    this.getAllFavoriteMedicine();
  }

  getAllFavoriteMedicine() {
    this.loading = true;
    this.favoriteMedicineService.listValidatedFavorites(this.idSector).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.loading = false;
          return;
        }
        this.listMedicFavoritePrescription = response.listMedicFavoriteStandardPrescriptionStruct;

        if (!this.listMedicFavoritePrescription || this.listMedicFavoritePrescription.length === 0) {
          this.alertService.show('Aviso', 'Não há prescrições favoritadas', AlertType.warning);
        }
        this.loading = false;
      },
      error: (error: string) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.loading = false;
      }
    });
  }

  loadMedicine(event: any) {
    if (!event.value || event.value == 0) {
      return;
    }
    else {
      this.loading = true;
      let listIdMedicine = this.listMedicine.map(x => x.medicine.idMedicine);

      this.favoriteMedicineService.getValidatedFavoriteStandardMedicine(event.value, this.idSector, listIdMedicine).subscribe({
        next: (response) => {
          if (response.isError) {
            console.log(response.isError);
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            this.loading = false;
            return;
          }
          this.selected = true;
          this.loading = false;
          this.matDialogRef.close({
            selected: this.selected,
            listValidMedicine: response.listValidMedicine,
            listInvalidMedicine: response.listInvalidMedicine,
            listValidMedicatedSolution: response.listValidMedicatedSolution,
            listInvalidMedicatedSolution: response.listInvalidMedicatedSolution,
          });
        },
        error: (error) => {
          console.log(error)
          this.alertService.show('Erro inesperado', error, AlertType.error);
          this.loading = false;
        }
      })
    }
  }

  checkPrescriptionValidity(item: MedicFavoriteStandardPrescriptionStruct) {
    if (item.invalid) {
      this.alertService.show('Atenção!', 'Os medicamentos dessa prescrição não podem ser usados neste setor.', AlertType.warning);
    }
  }

  clickCancel() {
    this.matDialogRef.close({});
  }

  removeMedicine(event: any) {
    this.loading = true;

    this.favoritePrescriptionService.deleteMedicFavoriteStandardPrescription(event).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        const index = this.listMedicFavoritePrescription.findIndex(item => item.idMedicFavoriteStandardPrescription === event);
        if (index !== -1)
          this.listMedicFavoritePrescription.splice(index, 1);

        this.loading = false;
      },
      error: (error: string) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.loading = false;
      }
    });
  }

  favoriteMedicineName(idMedicFavoriteStandardPrescription: number): string | undefined {
    const item = this.listMedicFavoritePrescription.find(item => item.idMedicFavoriteStandardPrescription === idMedicFavoriteStandardPrescription);
    return item ? item.favoritePrescriptionName : undefined;
  }
}