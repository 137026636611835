import { ProfessionalAgreement } from "../../models/private-billing/professional-agreement.model";

export class ProfessionalAgreementStruct extends ProfessionalAgreement {
    public participationDegreeName: string;
    public participationDegreeCode: string;
    public name: string;
    public stateInitials: string;
    public cboName: string;
    public councilName: string;

    public isEdited: boolean = false;
}