import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { GraphRequest } from '../../requests/data-intelligence/graph.request';
import { GetGraphResponse } from '../../responses/data-intelligence/get-graph.response';


@Injectable({
    providedIn: 'root'
})
export class GraphService extends CommonService {

    constructor(private router: Router, private httpClient: HttpClient) {
        super();
    }

    public get(idGraph: number): Observable<GetGraphResponse> {

        let uri = `Graph/idGraph/${idGraph}`;

        return this.httpClient.get<GetGraphResponse>(environment.urlApiDataIntelligence + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }

    public create(body: GraphRequest): Observable<ReturnStruct> {

        let uri = `Graph`;

        return this.httpClient.post<ReturnStruct>(environment.urlApiDataIntelligence + uri, body, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }

    public update(body: GraphRequest, idGraph: number): Observable<ReturnStruct> {

        let uri = `Graph/idGraph/${idGraph}`;

        return this.httpClient.put<ReturnStruct>(environment.urlApiDataIntelligence + uri, body, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }

    public delete(idGraph: number): Observable<ReturnStruct> {

        let uri = `Graph/idGraph/${idGraph}`;

        return this.httpClient.delete<ReturnStruct>(environment.urlApiDataIntelligence + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }
}