import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { PrescriptionStatusEnum } from 'src/app/shared/enum/prescription-status.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { GetCareLinePriorityService } from 'src/app/shared/services/API/care-line-priority/get-care-line-priority.service';
import { LocationByModuleService } from 'src/app/shared/services/API/flow/location-by-module.service';
import { CallEpisodePrescriptionService } from 'src/app/shared/services/API/orchestrator-patient/call-episode-prescription.service';
import { CareLinePriority } from 'src/app/shared/services/models/care-line-priority/care-line-priority.model';
import { SectorStruct } from 'src/app/shared/services/structs/flow/sector.struct';
import { MedicalPrescriptionEpisodeStruct } from 'src/app/shared/services/structs/medical-record/medical-prescription-episode.struct';

@Component({
  selector: 'app-prescription-list',
  templateUrl: './prescription-list.component.html',
  styleUrls: ['./prescription-list.component.css']
})
export class PrescriptionListComponent implements OnInit, OnDestroy {

  constructor(
    private callEpisodePrescriptionService: CallEpisodePrescriptionService,
    private alertService: AlertService,
    public dialog: MatDialog,
    public locationByModuleService: LocationByModuleService,
    public getCareLinePriorityService: GetCareLinePriorityService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.pharmacy;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.pharmacy_prescription;

  public listMedicalPrescriptionEpisode: MedicalPrescriptionEpisodeStruct[];
  public isLoading: boolean;
  public searchText: string;
  public idPrescriptionStatus: number = PrescriptionStatusEnum.EmAberto;
  public isCheckedPrescription: boolean = false;
  public idEpisode: number;
  public idSector: number;
  public idCareLinePriority: number;
  public timeIntervalId;
  public idMedicModule: MenuModuleEnum = MenuModuleEnum.medic;
  public listSector: SectorStruct[];
  public listCareLinePriority: CareLinePriority[];

  ngOnInit(): void {
    this.getMedicSectors();
    this.getCareLines();

    this.isLoading = true;

    this.search();
    this.timeIntervalId = setInterval(() => { this.search() }, 30000)
  }

  ngOnDestroy() {
    clearInterval(this.timeIntervalId);
  }

  search() {
    this.isLoading = true;

    if (this.searchText == "")
      this.searchText = null;

    if (!this.idEpisode)
      this.idEpisode = null;

    if (!this.idSector)
      this.idSector = null;

    this.callEpisodePrescriptionService.listEpisodePrescription(this.searchText, this.idPrescriptionStatus, this.idEpisode, this.idSector, this.idCareLinePriority).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.listMedicalPrescriptionEpisode = response.listEpisodePrescription;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getMedicSectors() {
    this.locationByModuleService.listRoomByModule(this.idMedicModule).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listSector = response.sectors;
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getCareLines() {
    this.isLoading = true;
    this.getCareLinePriorityService.getAllByIdHealthUnit().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        this.listCareLinePriority = response.listCareLinePriority;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13)
        $this.search();
    }, 1000);
  }

  onKeyEpisodeSearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13)
        $this.search();
    }, 1000);
  }

  checked(event) {
    if (event.checked)
      this.idPrescriptionStatus = PrescriptionStatusEnum.Liberado;
    else
      this.idPrescriptionStatus = PrescriptionStatusEnum.EmAberto;

    this.search();
  }

  nonDispensed(event) {
    if (event.checked)
      this.idPrescriptionStatus = PrescriptionStatusEnum.NaoDispensado;
    else
      this.idPrescriptionStatus = PrescriptionStatusEnum.EmAberto;

    this.search();
  }
}