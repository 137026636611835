<div mat-dialog-content>
    <div class="mat-dialog-content">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="head-confirm-modal-list">
                <h3 class="title"><b>Deseja {{this.hygienization ? 'Desativar' : 'Ativar'}} a higienização automática?</b>
                </h3>
                <span class="body" *ngIf="!isLoading && hygienization">
                    Ao desativar a configuração, todo o histórico de higienização será perdido
                </span>
                <mat-checkbox *ngIf="!isLoading && hygienization" formControlName="printDocument" class="my-checkbox-field">
                    Desejo visualizar/imprimir o documento exibindo o status da higienização até esse momento
                </mat-checkbox>
                <span class="body" *ngIf="!isLoading && !hygienization">
                    Ao ativar a configuração, todos os leitos não alocados serão categorizados como não higienizados
                </span>
            </div>            
            <div class="footer-confirm-modal-list">
                <button mat-flat-button type="submit" color="primary" class="btn-block">
                    <span *ngIf="isLoading == false">Confirmar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
                <button mat-flat-button color="accent" class="btn-block" (click)="clickCancel()"
                type="button">Retornar</button>
            </div>
        </form>  
    </div>
</div>