import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MedicalRecordLookupService } from 'src/app/shared/services/API/medical-record/lookup.service';
import { PatientService } from 'src/app/shared/services/API/medical-record/patient.service';
import { MedicineService } from 'src/app/shared/services/API/pharmacy/medicine.service';
import { HistoryMeasurerService } from 'src/app/shared/services/API/sync-measurer/attend.service';
import { CareLineService } from 'src/app/shared/services/API/sync-measurer/care-line.service';
import { PatientDeviceService } from 'src/app/shared/services/API/sync-measurer/patient-device.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { AttendRequest } from 'src/app/shared/services/requests/sync-measurer/attend.request';
import { Comorbidity, Gender } from 'src/app/shared/services/responses/medical-record/lookup.response';
import { PatientStruct } from 'src/app/shared/services/structs/medical-record/patient.struct';
import { MedicineStruct } from 'src/app/shared/services/structs/pharmacy/medicine.struct';
import { HistoryMeasurerStruct } from 'src/app/shared/services/structs/sync-measurer/history-measurer.struct';
import { ListedCareLineStruct } from 'src/app/shared/services/structs/sync-measurer/list-care-line.struct';
import { WebsocketMeasurerService } from 'src/app/shared/services/websocket-measurer.service';
import { PatientDataModalComponent } from './patient-data-modal/patient-data-modal.component';
import { PatientCareLinesModalComponent } from './patient-care-lines-modal/patient-care-lines-modal.component';
import { PatientClinicalObservationModalComponent } from './patient-clinical-observation-modal/patient-clinical-observation-modal.component';
import { SyncMeasurerPatientService } from 'src/app/shared/services/API/sync-measurer/sync-measurer-patient.service';
import { BaseChartDirective } from 'ng2-charts';
import { ChartType } from 'chart.js';
import { ChartHistoryMeasurerStruct } from 'src/app/shared/services/structs/sync-measurer/chart-history-measurer.struct';
import { DatePipe } from '@angular/common';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-remote-monitoring-patient',
  templateUrl: './remote-monitoring-patient.component.html',
  styleUrls: ['./remote-monitoring-patient.component.css']
})
export class RemoteMonitoringPatientComponent implements OnInit, OnDestroy {

  constructor(private formBuilder: UntypedFormBuilder,
    private maskService: MaskService,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private patientService: PatientService,
    private utilService: UtilService,
    public dialog: MatDialog,
    private lookupService: MedicalRecordLookupService,
    private careLineService: CareLineService,
    private medicineService: MedicineService,
    private historyMeasurerService: HistoryMeasurerService,
    private syncMeasurerPatientService: SyncMeasurerPatientService,
    private websocketService: WebsocketMeasurerService,
    private patientDeviceService: PatientDeviceService
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.remote_monitoring;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.remoteMonitoring_syncMeasurer;

  public model: UntypedFormGroup;
  public isLoading: boolean;
  public masks: Masks;
  public patientAge: string = null;
  public idPatient: number;
  public genderList: Gender[];
  public listComorbidity: Comorbidity[];
  public listCareLine: ListedCareLineStruct[];
  public listMedication: MedicineStruct[];
  public patientStruct: PatientStruct;
  public idMedicineOutros: number = -1;
  public isFirstLoading: boolean = true;
  public history: HistoryMeasurerStruct[];

  public temperatureLabel: string[] = [];
  public glucoseLabel: string[] = [];
  public saturationLabel: string[] = [];
  public bloodPressureLabel: string[] = [];
  public heartRateLabel: string[] = [];

  public listHeartRate: number[] = [];
  public listBloodPressureSystole: number[] = [];
  public listBloodPressureDiastole: number[] = [];
  public listSaturation: number[] = [];
  public listTemperature: number[] = [];
  public listGlucose: number[] = [];

  public numberOfItemsChart: number = 15;
  public glucoseHistoryLenght: number;
  public heartRateHistoryLenght: number;
  public bloodPressureHistoryLenght: number;
  public saturationHistoryLenght: number;
  public temperatureHistoryLenght: number;

  public glucosePagination: number = 0;
  public heartRatePagination: number = 0;
  public bloodPressurePagination: number = 0;
  public saturationPagination: number = 0;
  public temperaturePagination: number = 0;

  public saturationChartData: ChartHistoryMeasurerStruct[] = [];
  public temperatureChartData: ChartHistoryMeasurerStruct[] = [];
  public heartRateChartData: ChartHistoryMeasurerStruct[] = [];
  public glucoseChartData: ChartHistoryMeasurerStruct[] = [];
  public bloodPressureChartData: ChartHistoryMeasurerStruct[] = [];

  public hasRedAlert: boolean = false;
  public socketDisconneted: boolean = false;
  private socket: any;
  public onlineOffline: boolean = navigator.onLine;
  public calcHeight: number = 100;
  public chartType: ChartType = 'line';

  public saturationChartOptions = {
    scales: {
      y: {
        type: 'linear',
        grace: '5%',
        suggestedMin: 40,
        max: 100,
      },
      x: {
        display: false,
      }
    },
    plugins: {
      legend: {
        x: {
          ticks: {
            // This more specific font property overrides the global property
            fontsize: 24
          }
        }
      }
    }
  };

  public temperatureChartOptions = {
    scales: {
      y: {
        type: 'linear',
        grace: '5%',
        suggestedMin: 30,
        suggestedMax: 44,
      },
      x: {
        display: false,
      }
    },
    plugins: {
      legend: false
    }
  };

  public heartRateChartOptions = {
    scales: {
      y: {
        type: 'linear',
        grace: '5%',
        suggestedMin: 40,
        suggestedMax: 160,
      },
      x: {
        display: false,
      }
    },
    plugins: {
      legend: false
    }
  };

  // Campos da PatientEmployeeModel
  public idPatientEmployee: number;
  public idHealthUnit: number;
  public datetimeInclusion: Date;
  public unitBranch: string;
  public roleEmployee: string;
  public registryEmployee: string;
  public sectorEmployee: string;

  public glucoseChartOptions = {
    scales: {
      y: {
        type: 'linear',
        grace: '5%',
        suggestedMin: 0,
        suggestedMax: 200,
      },
      x: {
        display: false,
      }
    },
    plugins: {
      legend: false
    }
  };

  public bloodPressureChartOptions = {
    scales: {
      y: {
        suggestedMin: 40,
        suggestedMax: 180,
      },
      x: {
        display: false,
      }
    }
  };

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.isLoading = false;
    if (this.activatedRoute.snapshot.paramMap.get('idPatient'))
      this.idPatient = parseInt(this.activatedRoute.snapshot.paramMap.get('idPatient'));
    else
      return;

    this.model = this.formBuilder.group({
      idPatient: [{ value: this.idPatient, disabled: true }],
      birthDate: [{ value: '', disabled: true }],
      patientAge: [{ value: '', disabled: true }],
      patientName: [{ value: '', disabled: true }],
      cpf: [{ value: '', disabled: true }],
      cns: [{ value: '', disabled: true }],
      idGender: [{ value: '', disabled: true }],
      zipCode: [{ value: '', disabled: true }],
      street: [{ value: '', disabled: true }],
      neighborhood: [{ value: '', disabled: true }],
      city: [{ value: '', disabled: true }],
      state: [{ value: '', disabled: true }],
      country: [{ value: '', disabled: true }],
      houseNumber: [{ value: '', disabled: true }],
      apartmentNumber: [{ value: '', disabled: true }],
      listIdComorbidity: [{ value: '', disabled: true }],
      otherComorbidity: [{ value: '', disabled: true }],
      listIdMedicine: [{ value: '', disabled: true }],
      otherMedicine: [{ value: '', disabled: true }],
      listIdCareLine: [{ value: '', disabled: true }],
      phone1: [{ value: '', disabled: true }],
      listQuestionAnswer: [{ value: '', disabled: true }],
      observation: ['', [Validators.required]],
      unitBranch: [''],
      roleEmployee: [''],
      registryEmployee: [''],
      sectorEmployee: [''],
      idPatientEmployee: [],
    });

    this.getDeviceHash();
    this.populateLookupSelect();
    this.populateCareLineSelect();
    this.populateMedicineSelect();
    this.populatePatient();
    this.populateHistory();
    this.GetHistoryLenght();
  }

  openModal() {
    const dialogRef = this.dialog.open(PatientDataModalComponent, {
      data: {
        patient: this.patientStruct
      },
    });
  }

  openModalCareLines() {
    const dialogRef = this.dialog.open(PatientCareLinesModalComponent, {
      data: {
        listComorbidity: this.listComorbidity,
        listMedication: this.listMedication,
        listCareLine: this.listCareLine,
        history: this.history,
      },
    });
  }

  openModalClinicalObservation() {
    const dialogRef = this.dialog.open(PatientClinicalObservationModalComponent, {
      data: {
        history: this.history,
        patient: this.patientStruct
      },
    });
  }

  populatePatient() {
    this.patientService.get([this.idPatient]).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show("Erro", response.errorDescription, AlertType.error);
          this.isFirstLoading = false;
          return;
        }

        this.isFirstLoading = false;
        this.patientStruct = response.listPatient[0];
        this.model.get('idPatient').setValue(response.listPatient[0].idPatient);
        this.model.get('patientName').setValue(response.listPatient[0].patientName);
        let birthDate = this.maskService.formatDateToString(response.listPatient[0].birthDate, false);
        this.model.get('birthDate').setValue(birthDate);
        if (birthDate) {
          let patientAge = this.utilService.getAge(this.model.get('birthDate').value);
          this.model.get('patientAge').setValue(`${patientAge.years}a ${patientAge.months}m ${patientAge.days}d`);
        }
        this.model.get('cpf').setValue(response.listPatient[0].cpf ? response.listPatient[0].cpf.toString() : null);
        this.model.get('cns').setValue(response.listPatient[0].cns ? response.listPatient[0].cns.toString() : null);
        this.model.get('idGender').setValue(response.listPatient[0].idGender ? response.listPatient[0].idGender.toString() : null);
        this.model.get('zipCode').setValue(response.listPatient[0].zipCode);
        this.model.get('street').setValue(response.listPatient[0].street);
        this.model.get('neighborhood').setValue(response.listPatient[0].neighborhood);
        this.model.get('city').setValue(response.listPatient[0].city);
        this.model.get('phone1').setValue(response.listPatient[0].phone1);
        this.model.get('state').setValue(response.listPatient[0].state);
        this.model.get('country').setValue(response.listPatient[0].country);
        this.model.get('registryEmployee').setValue(response.listPatient[0].registryEmployee);
        this.model.get('sectorEmployee').setValue(response.listPatient[0].sectorEmployee);
        this.model.get('roleEmployee').setValue(response.listPatient[0].roleEmployee);
        this.model.get('unitBranch').setValue(response.listPatient[0].unitBranch);
        this.model.get('houseNumber').setValue(response.listPatient[0].houseNumber);
        this.model.get('idPatientEmployee').setValue(response.listPatient[0].idPatientEmployee);
      }
    });
  }

  previous(measurer: string) {
    if (measurer == 'glucose') { this.glucosePagination += 1; this.GlucoseHistory() };
    if (measurer == 'heartRate') { this.heartRatePagination += 1; this.HeartRateHistory() };
    if (measurer == 'saturation') { this.saturationPagination += 1; this.SaturationHistory() };
    if (measurer == 'temperature') { this.temperaturePagination += 1; this.TemperatureHistory() };
    if (measurer == 'bloodPressure') { this.bloodPressurePagination += 1; this.BloodPressureHistory() };
  }

  next(measurer: string) {
    if (measurer == 'glucose') { this.glucosePagination -= 1; this.GlucoseHistory() };
    if (measurer == 'heartRate') { this.heartRatePagination -= 1; this.HeartRateHistory() };
    if (measurer == 'saturation') { this.saturationPagination -= 1; this.SaturationHistory() };
    if (measurer == 'temperature') { this.temperaturePagination -= 1; this.TemperatureHistory() };
    if (measurer == 'bloodPressure') { this.bloodPressurePagination -= 1; this.BloodPressureHistory() };
  }

  formatDateTime(dateTime: Date) {
    var datePipe = new DatePipe('en-US');
    return datePipe.transform(dateTime, 'dd/MM/yyyy hh:mm');
  }

  GetHistoryLenght() {
    this.syncMeasurerPatientService.GetHistoryLenght(this.idPatient).subscribe({
      next: (response) => {
        this.isFirstLoading = false;
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.glucoseHistoryLenght = response.glucoseHistoryLenght;
        this.heartRateHistoryLenght = response.heartRateHistoryLenght;
        this.bloodPressureHistoryLenght = response.bloodPressureHistoryLenght;
        this.saturationHistoryLenght = response.saturationHistoryLenght;
        this.temperatureHistoryLenght = response.temperatureHistoryLenght;

        this.HeartRateHistory();
        this.GlucoseHistory();
        this.BloodPressureHistory();
        this.SaturationHistory();
        this.TemperatureHistory();
      }
    });
  }

  GlucoseHistory() {
    if (this.glucosePagination * this.numberOfItemsChart < this.glucoseHistoryLenght) {
      this.syncMeasurerPatientService.GlucoseHistory(this.idPatient, this.glucosePagination, this.numberOfItemsChart).subscribe({
        next: (response) => {
          this.isFirstLoading = false;
          if (response.isError) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            return;
          }

          this.listGlucose = [];
          this.glucoseLabel = [];
          response.glucoseHistory.forEach(value => {
            this.listGlucose.push(value.glucose);
            this.glucoseLabel.push(this.formatDateTime(value.datetimeInclusion))
          });

          this.glucoseChartData = [{
            data: this.listGlucose,
            label: 'Glicose',
            pointRadius: 5,
          },]
        }
      });
    }
  }

  HeartRateHistory() {
    if (this.heartRatePagination * this.numberOfItemsChart < this.heartRateHistoryLenght) {
      this.syncMeasurerPatientService.HeartRateHistory(this.idPatient, this.heartRatePagination, this.numberOfItemsChart).subscribe({
        next: (response) => {
          this.isFirstLoading = false;
          if (response.isError) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            return;
          }

          this.listHeartRate = [];
          this.heartRateLabel = [];
          response.heartRateHistory.forEach(value => {
            this.listHeartRate.push(value.heartRate);
            this.heartRateLabel.push(this.formatDateTime(value.datetimeInclusion))
          });

          this.heartRateChartData = [{
            data: this.listHeartRate,
            label: 'Frequência cardíaca',
            pointRadius: 5,
          },]
        }
      });
    }
  }

  BloodPressureHistory() {
    if (this.bloodPressurePagination * this.numberOfItemsChart < this.bloodPressureHistoryLenght) {
      this.syncMeasurerPatientService.BloodPressureHistory(this.idPatient, this.bloodPressurePagination, this.numberOfItemsChart).subscribe({
        next: (response) => {
          this.isFirstLoading = false;
          if (response.isError) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            return;
          }

          this.listBloodPressureSystole = [];
          this.listBloodPressureDiastole = [];
          this.bloodPressureLabel = [];
          response.bloodPressureHistory.forEach(value => {
            this.listBloodPressureSystole.push(value.bloodPressureSystole);
            this.listBloodPressureDiastole.push(value.bloodPressureDiastole);
            this.bloodPressureLabel.push(this.formatDateTime(value.datetimeInclusion))
          });
          let maxPressure: number = 140;
          let minPressure: number = 60;
          let upLimit: number[] = [];
          let downLimit: number[] = [];
          let n: number;
          n = this.bloodPressureLabel.length
          while (n > 0) {
            upLimit.push(maxPressure);
            downLimit.push(minPressure)
            n -= 1;
          }
          this.bloodPressureChartData = [{
            data: this.listBloodPressureSystole,
            label: 'Sistólica',
            pointRadius: 5,
            borderColor: "#8DDBE0",
            color: "#8DDBE0",
            backgroundColor: "#8DDBE0",
            pointBackgroundColor: "#8DDBE0"
          },
          {
            data: this.listBloodPressureDiastole,
            label: 'Diastólica',
            pointRadius: 5,
            color: "#8DDBE0",
            backgroundColor: "#8DDBE0",
            pointColor: "rgba(230, 61, 48, 0.9)"
          },
          {
            data: upLimit,
            label: 'Limitrofe superior',
            pointRadius: -10,
            color: "#084de0",
            borderColor: "rgba(230, 61, 48, 0.8)",
            backgroundColor: "rgba(230, 61, 48, 0.8)"
          },
          {
            data: downLimit,
            label: 'Limitrofe Inferior',
            pointRadius: -10,
            borderColor: "rgba(230, 61, 48, 0.8)",
            backgroundColor: "rgba(230, 61, 48, 0.8)"
          },]
        }
      });
    }
  }

  SaturationHistory() {
    if (this.saturationPagination * this.numberOfItemsChart < this.saturationHistoryLenght) {
      this.syncMeasurerPatientService.SaturationHistory(this.idPatient, this.saturationPagination, this.numberOfItemsChart).subscribe({
        next: (response) => {
          this.isFirstLoading = false;
          if (response.isError) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            return;
          }

          this.listSaturation = [];
          this.saturationLabel = [];
          response.saturationHistory.forEach(value => {
            this.listSaturation.push(value.saturation);
            this.saturationLabel.push(this.formatDateTime(value.datetimeInclusion))
          });

          this.saturationChartData = [{
            data: this.listSaturation,
            label: 'Saturação',
            pointRadius: 5,
          },]
        }
      });
    }
  }
  TemperatureHistory() {
    if (this.temperaturePagination * this.numberOfItemsChart < this.temperatureHistoryLenght) {
      this.syncMeasurerPatientService.TemperatureHistory(this.idPatient, this.temperaturePagination, this.numberOfItemsChart).subscribe({
        next: (response) => {
          this.isFirstLoading = false;
          if (response.isError) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            return;
          }

          this.listTemperature = [];
          this.temperatureLabel = [];
          response.temperatureHistory.forEach(value => {
            this.listTemperature.push(value.temperature);
            this.temperatureLabel.push(this.formatDateTime(value.datetimeInclusion))
          });

          this.temperatureChartData = [{
            data: this.listTemperature,
            label: 'Temperatura',
            pointRadius: 5,
          },]
        }
      });
    }
  }

  populateHistory() {
    this.historyMeasurerService.listHistoryMeasurer(this.idPatient).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.history = response.listHistory;
        this.hasRedAlert = response.hasRedAlert;
        this.model.get('listIdComorbidity').setValue(response.patientRelationStruct.listIdComorbidity.map(x => x.toString()))
        this.model.get('otherComorbidity').setValue(response.patientRelationStruct.otherComorbidity)
        this.model.get('listIdMedicine').setValue(response.patientRelationStruct.listIdMedicine.map(x => x.toString()))
        this.model.get('otherMedicine').setValue(response.patientRelationStruct.otherMedicine)
        this.model.get('listIdCareLine').setValue(response.patientRelationStruct.listIdCareLine.map(x => x.toString()))
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }


  populateLookupSelect() {
    this.lookupService.getLookup().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        this.genderList = response.listGenders;
        this.listComorbidity = response.listComorbidity;
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateCareLineSelect() {
    this.careLineService.listCareLineSelect().subscribe({
      next: (response) => {
        if (response.isError)
          return;

        this.listCareLine = response.listCareLineStruct;
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateMedicineSelect() {
    this.medicineService.listAllMedicine().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listMedication = response.listMedicineStruct;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  submit() {
    if (this.model.invalid) {
      this.alertService.show('Erro', "Preencha a observação!", AlertType.error);
      return;
    }

    this.isLoading = true;
    let body: AttendRequest = new AttendRequest();
    body.idPatient = this.idPatient;
    body.attendantObservation = this.model.get("observation").value;

    this.historyMeasurerService.saveAlertAttend(body).subscribe({
      next: (response) => {
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        location.reload();
      }
    });
  }

  startWebsocket(hash: string) {
    if (this.socket)
      this.socket.disconnect();

    this.socket = this.websocketService.websocketConnection();
    this.socket.emit('join', `syncMeasurerhash-${hash}`)
      .on('measurer', (res) => { this.updateMeasurerValues(res) })
      .io.on("reconnect", (res) => {
        this.socket.emit('join', `syncMeasurerhash-${hash}`);
      });
  }

  updateMeasurerValues(values: any) {
    if (values && ((values.bloodPressureDiastole && values.bloodPressureSystole)
      || (values.glucose)
      || (values.temperature)
      || (values.heartRate && values.saturation))) {
      this.populateHistory();
    }

    this.socketDisconneted = false;
  }

  getDeviceHash() {
    this.patientDeviceService.getDeviceByPatient(this.idPatient).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.startWebsocket(response.patientDevice.deviceHash);
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  private idInterval = setInterval(() => {
    if (this.socket) {
      if (this.socket.connected)
        this.socketDisconneted = false;
      else
        this.socketDisconneted = true;
    }
    else
      this.socketDisconneted = false;

    if (this.onlineOffline !== navigator.onLine)
      this.onlineOffline = navigator.onLine;
  }, 1000);

  ngOnDestroy() {
    if (this.socket)
      this.socket.disconnect(true);

    clearInterval(this.idInterval);
  }
}