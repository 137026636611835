<h1 mat-dialog-title>{{this.data.discriminatorName}}</h1>
<div mat-dialog-content>
    <div class="modal-body text-center">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="row">
                <div class="col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Valor</mat-label>
                        <input [mask]="masks.OneTothreeDigits" type="text" matInput formControlName="value">
                    </mat-form-field>
                </div>
            </div>
            <div class="footer-panel-modal-list">
                <div class="row">
                    <div class="col-12">
                        <button type="submit" mat-flat-button color="primary" class="btn-block">Concluir</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>