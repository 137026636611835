import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { BiPanelListComponent } from './pages/bi-panel-list/bi-panel-list.component';
import { BiPanelRegisterComponent } from './pages/bi-panel-register/bi-panel-register.component';
import { BusinessIntelligenceConfigComponent } from './pages/business-intelligence-config/business-intelligence-config.component';
import { BusinessIntelligenceDetailsComponent } from './pages/business-intelligence-details/business-intelligence-details.component';
import { BusinessIntelligenceListComponent } from './pages/business-intelligence-list/business-intelligence-list.component';
import { BusinessIntelligenceSendReportComponent } from './pages/business-intelligence-send-report/business-intelligence-send-report.component';
import { BusinessIntelligenceComponent } from './pages/business-intelligence/business-intelligence.component';

const routes: Routes = [
  { path: 'bi', component: BusinessIntelligenceComponent, canActivate: [AuthGuard] },
  { path: 'bi/list', component: BusinessIntelligenceListComponent, canActivate: [AuthGuard] },
  { path: 'bi/details', component: BusinessIntelligenceDetailsComponent, canActivate: [AuthGuard] },
  { path: 'bi/bi-panel', component: BiPanelListComponent, canActivate: [AuthGuard] },
  { path: 'bi/bi-panel/register', component: BiPanelRegisterComponent, canActivate: [AuthGuard] },
  { path: 'bi/config', component: BusinessIntelligenceConfigComponent, canActivate: [AuthGuard] },
  { path: 'bi/send-report', component: BusinessIntelligenceSendReportComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BusinessIntelligenceRoutingModule { }