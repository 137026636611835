export class KpiSectorGravityModel {
    public idKpiSectorGravity: number;

    public idKpiSectorConfig: number;

    public idGravity: number;

    public idKpiGoal: number;

    public idKpiAlert: number;

    public datetimeInclusion: Date;
}