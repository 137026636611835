<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <a class="back-link" routerLink="/multi-professional/multi-professional-config">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon>
        </a>
        <h1>Nova Configuração</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="row">
                    <div class="title-header">
                        <h1>Configuração de atendimento multiprofissional por setor</h1>
                    </div>
                    <div class="col-12">
                        <mat-slide-toggle class="toggle toggle-right-margin" formControlName="dischargeOutcome"
                            [disabled]="isLoading">Desfecho de alta após encaminhamento</mat-slide-toggle>
                        <mat-slide-toggle class="toggle toggle-padding" formControlName="hideDataPatientHistory"
                            [disabled]="isLoading">Ocultar dados no Histórico do Paciente</mat-slide-toggle>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome</mat-label>
                            <input matInput type="text" formControlName="multiProfessionalConfigName">
                            <mat-error *ngIf="model.get('multiProfessionalConfigName').invalid">Informe o
                                nome</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Setor</mat-label>
                            <mat-select formControlName="listSector" multiple>
                                <mat-option *ngFor="let sector of listSector" [value]="sector.idSector">
                                    {{sector.sectorName}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('listSector').invalid">Informe ao menos um setor</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Seção para preenchimento</mat-label>
                            <mat-select formControlName="listSectionFill" multiple>
                                <mat-option *ngFor="let section of listSectionFill"
                                    (click)="selectSectionFill(section.idSectionFill)" [value]="section.idSectionFill">
                                    {{section.sectionFillName}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('listSectionFill').invalid">Informe ao menos uma
                                seção</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4" *ngIf="showSectionFillSelect">
                        <mat-form-field appearance="outline">
                            <mat-label>Campos visíveis</mat-label>
                            <mat-select formControlName="listSectionFieldSelected" multiple>
                                <mat-option *ngFor="let field of listVisibleField"
                                    (click)="selectField(field.idVisibleField)" [value]="field.idVisibleField">
                                    {{field.visibleFieldName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4" *ngIf="showSectionFillSelect">
                        <mat-form-field appearance="outline">
                            <mat-label>Campos obrigatórios</mat-label>
                            <mat-select formControlName="listRequiredFieldSelected" multiple>
                                <mat-option *ngFor="let field of listRequiredFieldSelected"
                                    (click)="selectRequiredField(field.idVisibleField)" [value]="field.idVisibleField">
                                    {{field.visibleFieldName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="title-header">
                    <h1>Metadados</h1>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Selecionar metadados</mat-label>
                            <mat-select formControlName="listMetadata" multiple>
                                <mat-option *ngFor="let item of listMetaData"
                                    (click)="selectMetaData(item.metaData.idMetaData)"
                                    [value]="item.metaData.idMetaData">
                                    {{ item.metaData.metaDataName }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Metadados obrigatórios</mat-label>
                            <mat-select formControlName="listMetaDataRequiredSelected" multiple>
                                <mat-option *ngFor="let item of listMetaDataRequiredSelected"
                                    (click)="selectRequiredMetaData(item.metaData.idMetaData)"
                                    [value]="item.metaData.idMetaData">
                                    {{ item.metaData.metaDataName }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <a mat-flat-button type="button" routerLink="/multi-professional/multi-professional-config"
                            color="accent" class=" btn-block">
                            Cancelar
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>

</div>