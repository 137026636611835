import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { UtilsClientService } from '../../utils.service';
import { ClientHealthUnitService } from 'src/app/shared/services/API/user/client-health-unit.service';
import { HealthUnitStruct } from 'src/app/shared/services/structs/user/health-unit.struct';
import { HygienizationTotemService } from 'src/app/shared/services/API/bed/hygienization-totem.service';
import { HygienizationTotemStruct } from 'src/app/shared/services/structs/bed/hygienization-totem.struct';
import { AuthService } from 'src/app/shared/services/auth.service';
import { TotemExitModalComponent } from './pages/totem-bed-hygienization/totem-exit-modal/totem-exit-modal.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-hygienization-totem',
  templateUrl: './hygienization-totem.component.html',
  styleUrls: ['./hygienization-totem.component.css']
})
export class HygienizationTotemComponent implements OnInit, OnDestroy {

  authenticated: boolean = false;
  logged: boolean = false;
  public isLoading: boolean = false;
  state: string = 'options';

  totemName: string;
  pin: string;
  public totemStruct: HygienizationTotemStruct;
  public idTotem: number;
  public idHealthUnit: number;
  onlineOffline: boolean = navigator.onLine;
  public healthUnitStruct: HealthUnitStruct;

  constructor(
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private utilService: UtilsClientService,
    public dialog: MatDialog,
    private authService: AuthService,
    private cdref: ChangeDetectorRef,
    private clientHealthUnitService: ClientHealthUnitService,
    private hygienizationTotemService: HygienizationTotemService,
  ) { }

  ngOnInit(): void {
    this.idTotem = parseInt(this.activatedRoute.snapshot.paramMap.get('id'));
  }

  private intervalId = setInterval(() => {
    if (this.onlineOffline !== navigator.onLine)
      this.onlineOffline = navigator.onLine;
  }, 1000);

  ngOnDestroy() {
    clearInterval(this.intervalId);
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  checkPin(event) {
    this.pin = event;
    this.isLoading = true;
    this.hygienizationTotemService.verifyPinHygienizationTotem(event, this.idTotem).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          this.utilService.removeTotemPin();
          return;
        }

        this.totemStruct = response.totemStruct;

        this.alertService.show('Sucesso', 'PIN autenticado com sucesso', AlertType.success);
        this.isLoading = false;

        this.getHealthUnit();

        this.totemName = response.totemStruct.hygienizationTotemName;
        this.authenticated = true;
        this.idHealthUnit = response.totemStruct.idHealthUnit;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.utilService.removeTotemPin();
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  checkLogin(event) {
    this.logged = event;
  }

  checkLoggout() {
    this.logged = false;
    this.authService.logout();
    if (!this.utilService.getTotemPin())
      this.authenticated = false;
  }

  getHealthUnit() {
    this.clientHealthUnitService.getHealthUnit(this.totemStruct.idHealthUnit).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.healthUnitStruct = response.healthUnitStruct;
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  logoutModal() {
    const dialogRef = this.dialog.open(TotemExitModalComponent, {
      data: {
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.confirm) {
          this.logged = false;
          this.authenticated = false;
        }
      }
    });
  }
}