import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { GuideBatchService } from 'src/app/shared/services/API/private-billing/guide-batch.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { HealthcareAgreementStruct } from 'src/app/shared/services/structs/private-billing/healthcare-agreement.struct';
import { ActivatedRoute, Router } from '@angular/router';
import { GuideToBatchStruct } from 'src/app/shared/services/structs/private-billing/guide-to-batch.struct';
import { GuideBatchStruct } from 'src/app/shared/services/structs/private-billing/guide-batch.struct';
import { AlertModalComponent } from 'src/app/shared/components/alert-modal/alert-modal.component';
import { GuideBatchStatusEnum } from 'src/app/shared/enum/private-billing/guide-batch-status.enum';

@Component({
  selector: 'app-guide-batch-edit',
  templateUrl: './guide-batch-edit.component.html',
  styleUrls: ['./guide-batch-edit.component.css']
})
export class GuideBatchEditComponent implements OnInit {
  constructor(private alertService: AlertService,
    public dialog: MatDialog,
    private guideBatchService: GuideBatchService,
    private activatedRoute: ActivatedRoute,
    private router: Router,) {
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.billing;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.tiss_batch_control;

  public isLoading: boolean = false;

  public searchText: string = null;
  public idGuideBatch: number = null;

  public listGuideToBatchStruct: GuideToBatchStruct[] = [];
  public guideBatchStruct: GuideBatchStruct;

  public guideBatchStatusEnum: typeof GuideBatchStatusEnum = GuideBatchStatusEnum;

  public pageIndex: number = 0;
  public pageSize: number = 50;
  public fullListSize: number;

  ngOnInit(): void {
    this.idGuideBatch = parseInt(this.activatedRoute.snapshot.paramMap.get('idGuideBatch'));
    this.search();
  }

  back() {
    if (this.isLoading)
      return;

    this.router.navigate(['/billing/tiss-batch-control']);
  }

  openGuideMessage(errorName: string, message: string) {
    if (this.isLoading)
      return;

    if (!errorName || errorName.trim() == "" || !message || message.trim() == ""){
      this.alertService.show('Alerta', "Não existe erro nesse processamento", AlertType.warning);
      return;
    }     

    const dialogRef = this.dialog.open(AlertModalComponent, {
      data: {
        isTwoButtonsModal: false,
        title: `${errorName}`,
        description: `${message}`
      },
    });
  }

  search() {
    if (this.isLoading)
      return;

    this.isLoading = true;

    this.guideBatchService.GetByIdGuideBatch(this.idGuideBatch, this.searchText, this.pageIndex).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.listGuideToBatchStruct = [...response.listGuideToBatchStruct];
        this.guideBatchStruct = response.guideBatchStruct;

        if (!this.guideBatchStruct || !this.guideBatchStruct.guideBatch){
          this.alertService.show('Erro', "Lote não foi encontrado", AlertType.error);
          this.router.navigate(['/billing/tiss-batch-control']);
        }

        this.fullListSize = response.fullSize;
        this.isLoading = false;

      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  private timeoutKeySearch: any = null;
  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.pageIndex = 0;
        $this.search();
      }
    },
      1000);
  }

  changePage(pageIndex: number) {
    this.pageIndex = pageIndex;
    this.search();
  }

  private throwException(error: string) {
    this.alertService.show('Erro inesperado', error, AlertType.error);
    this.isLoading = false;
  }
}