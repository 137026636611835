import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityGroupEnum } from 'src/app/shared/components/menu/menu.functionality-group.enum';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { LocationByModuleService } from 'src/app/shared/services/API/flow/location-by-module.service';
import { MetaDataService } from 'src/app/shared/services/API/meta-data/meta-data.service';
import { VirtualTriageConfigService } from 'src/app/shared/services/API/virtual-triage/virtual-triage-config.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { VirtualTriageConfigRequest } from 'src/app/shared/services/requests/virtual-triage/virtual-triage-config.request';
import { SectorStruct } from 'src/app/shared/services/structs/flow/sector.struct';
import { MetaDataStruct } from 'src/app/shared/services/structs/meta-data/meta-data.struct';
import { VirtualTriageConfigStruct } from 'src/app/shared/services/structs/virtual-triage/virtual-triage-config.struct';

@Component({
  selector: 'app-virtual-triage-config-register',
  templateUrl: './virtual-triage-config-register.component.html',
  styleUrls: ['./virtual-triage-config-register.component.css']
})
export class VirtualTriageConfigRegisterComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private virtualTriageConfigService: VirtualTriageConfigService,
    private metaDataService: MetaDataService,
    private locationByModuleService: LocationByModuleService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.digital_prompt_service;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.virtual_triage_config;
  public menuFunctionalityGroupEnum: MenuFunctionalityGroupEnum = MenuFunctionalityGroupEnum.triagem_virtual;

  public model: FormGroup;
  public masks: Masks;
  public request: VirtualTriageConfigRequest;
  public virtualTriageConfigStruct: VirtualTriageConfigStruct = new VirtualTriageConfigStruct();

  public listMetaData: MetaDataStruct[];
  public listSector: SectorStruct[];
  public listMetaDataRequiredSelected: MetaDataStruct[] = [];

  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;

  public idVirtualTriageConfig: number;

  ngOnInit(): void {
    this.isLoading = false;

    this.masks = this.maskService.getMasks();
    this.getSectors();

    this.isUpdate == false;
    if (this.activatedRoute.snapshot.paramMap.get('idVirtualTriageConfig'))
      this.idVirtualTriageConfig = parseInt(this.activatedRoute.snapshot.paramMap.get('idVirtualTriageConfig'));

    this.model = this.formBuilder.group({
      name: ['', [Validators.required]],
      listIdSector: [, [Validators.required]],
      listMetadata: [],
      listMetaDataRequiredSelected: [],
    });

    if (this.idVirtualTriageConfig != null) {
      this.isFirstLoading = true;
      this.isUpdate = true;
      this.populateVirtualTriageConfigData();
    }
    else
      this.getAllMetaData();
  }

  clickCancel() {
    this.router.navigate(['/digital-prompt-service/virtual-triage/virtual-triage-config']);
  }

  submit() {
    if (this.isLoading) {
      return;
    }
    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }
    this.isLoading = true;
    this.request = new VirtualTriageConfigRequest();
    this.request.virtualTriageConfigStruct.virtualTriageConfigName = this.model.get("name").value;
    this.request.virtualTriageConfigStruct.listIdSector = this.model.get('listIdSector').value;
    this.request.virtualTriageConfigStruct.listIdMetaData = this.model.get('listMetadata').value;
    this.request.virtualTriageConfigStruct.listIdRequiredMetaData = this.model.get('listMetaDataRequiredSelected').value;

    if (this.isUpdate)
      this.updateVirtualTriageConfig();
    else
      this.createVirtualTriageConfig();
  }

  populateVirtualTriageConfigData() {
    this.virtualTriageConfigService.getVirtualTriageConfig(this.idVirtualTriageConfig).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.virtualTriageConfigStruct = response.virtualTriageConfigStruct;

        this.model.get('name').setValue(this.virtualTriageConfigStruct.virtualTriageConfigName);
        this.model.get('listIdSector').setValue(this.virtualTriageConfigStruct.listIdSector);
        this.model.get('listMetadata').setValue(this.virtualTriageConfigStruct.listIdMetaData);
        this.model.get('listMetaDataRequiredSelected').setValue(this.virtualTriageConfigStruct.listIdRequiredMetaData);

        this.getAllMetaData();

        this.isLoading = false;
        this.isFirstLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  updateVirtualTriageConfig() {
    this.virtualTriageConfigService.updateVirtualTriageConfig(this.idVirtualTriageConfig, this.request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;

        this.router.navigate(['/digital-prompt-service/virtual-triage/virtual-triage-config']);
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  createVirtualTriageConfig() {
    this.virtualTriageConfigService.createVirtualTriageConfig(this.request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;

        this.router.navigate(['/digital-prompt-service/virtual-triage/virtual-triage-config']);

      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getAllMetaData() {
    this.metaDataService.getAllMetaData().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listMetaData = response.listMetaDataStruct;
        if (this.isUpdate)
          this.listMetaDataRequiredSelected = this.listMetaData.filter(x => this.virtualTriageConfigStruct.listIdMetaData.find(y => y == x.metaData.idMetaData))
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getSectors() {
    let listId: number[] = [];
    let listIdFunctionalityGroup: number[] = [];
    listId.push(this.menuModuleEnum);
    listIdFunctionalityGroup.push(this.menuFunctionalityGroupEnum);

    let jsonList = JSON.stringify(listId);
    let jsonListIdFunctionalityGroup = JSON.stringify(listIdFunctionalityGroup);

    this.locationByModuleService.listSectorByModule(jsonList, jsonListIdFunctionalityGroup).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.listSector = response.listSectorStruct;
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  selectMetaData(event: any) {
    let listIdMetaDataSelected = this.listMetaDataRequiredSelected.length > 0 ? this.listMetaDataRequiredSelected.filter(x => x.metaData.idMetaData == event) : [];
    if (listIdMetaDataSelected.length == 0) {
      let idMetaDataSelected = this.listMetaData.filter(x => x.metaData.idMetaData == event);
      idMetaDataSelected.forEach(x => {
        this.listMetaDataRequiredSelected.push(x)
      });
    }
    else
      this.listMetaDataRequiredSelected = this.listMetaDataRequiredSelected.filter(x => x.metaData.idMetaData != event);

    if (this.model.get('listMetaDataRequiredSelected').value)
      this.model.get('listMetaDataRequiredSelected').setValue(this.model.get('listMetaDataRequiredSelected').value.filter(x => x != event));
  }
}