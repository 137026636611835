import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { TagStruct } from 'src/app/shared/components/tag/tag.struct';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { DashboardTagService } from 'src/app/shared/services/API/data-intelligente/dashboard-tag.service';
import { DashboardService } from 'src/app/shared/services/API/data-intelligente/dashboard.service';
import { DashboardRequest } from 'src/app/shared/services/requests/data-intelligence/dashboard-request';
import { CancelRegisterModalComponent } from './cancel-register-modal/cancel-register-modal.component';
import { GraphRegisterModalComponent } from './graph-register-modal/graph-register-modal.component';

@Component({
  selector: 'app-dashboard-register',
  templateUrl: './dashboard-register.component.html',
  styleUrls: ['./dashboard-register.component.css']
})
export class DashboardRegisterComponent implements OnInit {

  constructor(
    private dashboardService: DashboardService,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private router: Router,
    private dashboardTagService: DashboardTagService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog
  ) { }

  public isFirstLoading: boolean;
  public isLoading: boolean;
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.dashboard;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.dashboard_dashboards;
  public listAllTags: TagStruct[];
  public model: UntypedFormGroup;
  public idDashboard: number;
  public isUpdate: boolean = false;
  public listTags: TagStruct[];

  ngOnInit(): void {
    this.isLoading = true;
    this.isFirstLoading = true;
    this.populateTags();
    this.model = this.formBuilder.group({
      dashboardName: [, [Validators.required]],
      description: ['']
    });

    if (this.activatedRoute.snapshot.paramMap.get('idDashboard')) {
      this.idDashboard = parseInt(this.activatedRoute.snapshot.paramMap.get('idDashboard'));
      this.isUpdate = true;
      this.getDashboard(this.idDashboard)
    } else {
      this.isFirstLoading = false;
      this.isLoading = false;
      this.listTags = [];
    }
  }

  populateTags() {
    this.dashboardTagService.listTags().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.listAllTags = response.listTag;
    },
      (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  submit() {
    if (this.model.invalid || this.isLoading)
      return;

    this.isLoading = true;
    let request: DashboardRequest = new DashboardRequest();
    request.dashboardName = this.model.get("dashboardName").value;
    request.description = this.model.get("description").value;
    request.listTags = this.listTags;
    if (this.isUpdate) {
      this.updateDashboardRequest(request, this.idDashboard);
    } else {
      this.createDashboardRequest(request);
    }
  }

  createDashboardRequest(request: DashboardRequest) {
    this.dashboardService.create(request).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.openGraphRegisterModal(response.idDashboard);
      this.isLoading = false;
    },
      (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      });
  }

  updateDashboardRequest(request: DashboardRequest, idDashboard: number) {
    this.dashboardService.update(request, idDashboard).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.openGraphRegisterModal(this.idDashboard);
      this.isLoading = false;
    },
      (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      });
  }

  getDashboard(idDashboard: number) {
    this.dashboardService.get(idDashboard).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      if (response.listTags) {
        this.listTags = response.listTags;
      } else {
        this.listTags = [];
      }
      this.model.get("dashboardName").setValue(response.dashboard.dashboardName);
      this.model.get("description").setValue(response.dashboard.description);
      this.isLoading = false;
      this.isFirstLoading = false;
    },
      (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      });
  }


  openCancelModal() {
    const dialogRef = this.dialog.open(CancelRegisterModalComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.cancel) {
        this.router.navigate(['/dashboard/list']);
      }
    });
  }

  openGraphRegisterModal(idDashboard: number) {
    const dialogRef = this.dialog.open(GraphRegisterModalComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.register) {
        this.router.navigate(['dashboard/graph/register',{ idDashboard: idDashboard }]);
      } else {
        this.router.navigate(['/dashboard/list']);
      }
    });
  }

}
