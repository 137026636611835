export class WristbandClassificationReportRequest {
     public idEpisode: number;
     public patientName: string;
     public motherName: string;
     public patientBirthDate: string;
     public width: number;
     public height: number;
     public idGravity: number;
     public gravityName: string;
     public identificationName: string;
     public identificationDescription: string;
     public gravityColor: string;
}