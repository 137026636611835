import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ReturnRestrictionsService } from 'src/app/shared/services/API/private-billing/return-restrictions.service';
import { ReturnRestrictionsStruct } from 'src/app/shared/services/structs/private-billing/return-restrictions.struct';
import { TypePeriod } from 'src/app/shared/services/models/private-billing/type-period.model';
import { Procedure } from 'src/app/shared/services/models/private-billing/procedure.model';
import { ReturnRestrictionsModalComponent } from './return-restrictions-modal/return-restrictions-modal.component';
import { AlertModalComponent } from 'src/app/shared/components/alert-modal/alert-modal.component';

@Component({
  selector: 'app-return-restrictions',
  templateUrl: './return-restrictions.component.html',
  styleUrls: ['./return-restrictions.component.css']
})
export class ReturnRestrictionsComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    private returnRestrictionsService: ReturnRestrictionsService,
    private cdr: ChangeDetectorRef,
    private alertService: AlertService,
  ) { }

  @Input() listReturnRestrictionsStruct: ReturnRestrictionsStruct[] = [];
  @Input() listTypePeriod: TypePeriod[] = [];
  @Input() listProcedure: Procedure[] = [];
  @Input() nameHealthcareAgreement: string;
  @Input() idHealthcareAgreement: number;
  @Input() fullListSize: number;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public searchText: string = null;
  public isLoading: boolean = false;
  public pageIndex: number = 0;
  public pageSize: number = 50;

  ngOnInit(): void {
  }

  changePage(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.search();
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.pageIndex = 0;
        $this.search();
      }
    },
      1000);
  }

  search() {
    this.isLoading = true;

    this.returnRestrictionsService.GetAll(this.idHealthcareAgreement, this.searchText, this.pageIndex).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.listReturnRestrictionsStruct = response.listReturnRestrictionsStruct;
        this.fullListSize = response.fullListSize;
        this.pageSize = response.pageSize;
        this.cdr.detectChanges();
        this.isLoading = false;
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  deleteReturnRestriction(returnRestrictions: ReturnRestrictionsStruct) {
    this.isLoading = true;
    this.returnRestrictionsService.delete(returnRestrictions.idReturnRestrictions).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        let existingPlanIndex = this.listReturnRestrictionsStruct.findIndex(x => x.idReturnRestrictions === returnRestrictions.idReturnRestrictions);
        if (existingPlanIndex !== -1) {
          this.listReturnRestrictionsStruct.splice(existingPlanIndex, 1);
          this.cdr.detectChanges();
        }
        this.search();
        this.alertService.show('Sucesso', "Restrição de retorno excluída com sucesso!", AlertType.success);
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  openModal(returnRestrictions: ReturnRestrictionsStruct) {
    const dialogRef = this.dialog.open(ReturnRestrictionsModalComponent, {
      data: {
        nameHealthcareAgreement: this.nameHealthcareAgreement,
        idHealthcareAgreement: this.idHealthcareAgreement,
        returnRestrictions: returnRestrictions,
        listTypePeriod: this.listTypePeriod,
        listProcedure: this.listProcedure,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.success) {
          const existingReturnRestrictionsIndex = returnRestrictions !== null ? this.listReturnRestrictionsStruct.findIndex(x => x.idReturnRestrictions === returnRestrictions.idReturnRestrictions) : -1;

          if (existingReturnRestrictionsIndex !== -1)
            this.listReturnRestrictionsStruct[existingReturnRestrictionsIndex] = result.returnRestrictions;
          else
            this.listReturnRestrictionsStruct.push(...[result.returnRestrictions]);
          this.fullListSize += 1;
          this.cdr.detectChanges();
        }
      }
    });
  }

  private throwException(error: string) {
    this.alertService.show('Erro inesperado', error, AlertType.error);
    this.isLoading = false;
  }

  openAlertModal(returnRestrictions: ReturnRestrictionsStruct) {
    let description: string = 'Tem certeza que deseja excluir esta restrição de retorno?';
    const dialogRef = this.dialog.open(AlertModalComponent, {
      data: {
        isTwoButtonsModal: true,
        title: 'Atenção!',
        description: description,
        hasImage: true
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.confirm == true) {
          this.deleteReturnRestriction(returnRestrictions);
        }
      }
    });
  }
}