import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GuideTypeEnum } from 'src/app/shared/enum/private-billing/guide-type.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { GuideType } from 'src/app/shared/services/models/private-billing/guide-type.model';
import { HealthcareAgreementStruct } from 'src/app/shared/services/structs/private-billing/healthcare-agreement.struct';

@Component({
  selector: 'app-filters-to-guide-batch-modal',
  templateUrl: './filters-to-guide-batch-modal.component.html',
  styleUrls: ['./filters-to-guide-batch-modal.component.css']
})
export class FiltersToGuideBatchModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<FiltersToGuideBatchModalComponent>,
    private alertService: AlertService,
    private formBuilder: FormBuilder
  ) { }

  public model: FormGroup;
  public isUpdate: boolean = false;

  public guideStatusName: string = "Finalizada";

  public listHealthcareAgreementStruct: HealthcareAgreementStruct[] = [];
  public listGuideType: GuideType[] = [];

  ngOnInit(): void {
    if (this.data.isUpdate)
      this.isUpdate = true;

    if (this.data && this.data.listGuideType)
      this.listGuideType = this.data.listGuideType.filter(x => x.idGuideType != GuideTypeEnum.internacao);

    this.listHealthcareAgreementStruct = this.data.listHealthcareAgreementStruct;

    this.model = this.formBuilder.group({
      idGuideType: [null, [Validators.required]],
      idHealthcareAgreement: [null, [Validators.required]],
      beginDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
    });

    if (this.isUpdate) {
      this.model.get('idGuideType').setValue(this.data.idGuideType);
      this.model.get('idHealthcareAgreement').setValue(this.data.idHealthcareAgreement);
      this.model.get('beginDate').setValue(this.data.beginDate);
      this.model.get('endDate').setValue(this.data.endDate);
    }
  }

  submit() {
    if (this.model.invalid) {
      this.model.markAllAsTouched();
      this.alertService.show('Atenção', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.warning);
      return;
    }

    this.matDialogRef.close({
      search: true,
      idGuideType: this.model.get('idGuideType').value,
      idHealthcareAgreement: this.model.get('idHealthcareAgreement').value,
      beginDate: this.model.get('beginDate').value,
      endDate: this.model.get('endDate').value,
    });
  }

  clickCancel() {
    this.matDialogRef.close({});
  }

}