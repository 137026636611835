import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { BedHygienizationConfigService } from 'src/app/shared/services/API/bed/bed-hygienization-config.service';
import { BedHygienizationService } from 'src/app/shared/services/API/bed/bed-hygienization.service';
import { UserGetNameService } from 'src/app/shared/services/API/user/user-get-name.service';
import { BedHygienizationConfigRequest } from 'src/app/shared/services/requests/bed/bed-hygienization-config.request';
import { BedHygienizationRequest } from 'src/app/shared/services/requests/bed/bed-hygienization.request';
import { BedStruct } from 'src/app/shared/services/structs/bed/bed.struct';
import { HygienizationConfirmModalComponent } from './hygienization-confirm-modal/hygienization-confirm-modal.component';
import { HygienizationModalComponent } from './hygienization-modal/hygienization-modal.component';

@Component({
  selector: 'app-bed-hygienization',
  templateUrl: './bed-hygienization.component.html',
  styleUrls: ['./bed-hygienization.component.css']
})
export class BedHygienizationComponent implements OnInit {

  constructor(
    private bedHygienizationService: BedHygienizationService,
    private bedHygienizationConfigService: BedHygienizationConfigService,
    private userGetNameService: UserGetNameService,
    private alertService: AlertService,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.observation;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.observation_bed_hygienization;

  public listHygienizedBed: BedStruct[];
  public listUnhygienizedBed: BedStruct[];
  public listHygienizingBed: BedStruct[];
  public listBedInUse: BedStruct[];
  public filterListUnhygienizedBed: BedStruct[];
  public filterListHygienizedBed: BedStruct[];
  public filterListHygienizingBed: BedStruct[];
  public isLoading: boolean;
  public searchText: string;
  public hygienizationConfig: boolean;
  public model: FormGroup;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      hygienization: [null],
    });

    this.getConfig();
    this.listBed();
  }

  listBed(isFilter: boolean = false) {
    this.isLoading = true;
    if (isFilter) {
      this.filterListBed(this.listUnhygienizedBed, this.listHygienizedBed, this.listHygienizingBed, isFilter);
      this.isLoading = false;
    }
    else {
      this.bedHygienizationService.listBedHygienization().subscribe({
        next: (response) => {
          if (response.isError) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            this.isLoading = false;
            return;
          }

          this.listUnhygienizedBed = response.listUnhygienizedBed;
          this.listHygienizedBed = response.listHygienizedBed;
          this.listHygienizingBed = response.listHygienizingBed;
          this.listBedInUse = response.listBedInUse;

          this.filterListBed(response.listUnhygienizedBed, response.listHygienizedBed, response.listHygienizingBed, false);

          let listIdUser: number[] = [];

          if ((this.listHygienizingBed) || (this.listHygienizedBed)) {
            if (this.listHygienizingBed.length > 0)
              listIdUser.concat(this.listHygienizingBed.map(x => x.idHygienizationUser));

            if (this.listHygienizedBed.length > 0)
              listIdUser.concat(this.listHygienizedBed.map(x => x.idHygienizationUser));

            this.getUserNames(listIdUser);
          }
          else
            this.isLoading = false;
        },
        error: (error) => {
          console.log(error)
          this.isLoading = false;
          this.alertService.show('Erro inesperado', error, AlertType.error);
        }
      });
    }
  }

  getUserNames(listIdUser: number[]) {
    this.isLoading = true;
    this.userGetNameService.listUser(listIdUser).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (this.listHygienizingBed != null && this.listHygienizingBed.length > 0) {
          this.listHygienizingBed.forEach(x => {
            x.hygienizationUserName = response.listUser.find(y => y.idUser == x.idHygienizationUser).userName;
          });
        }

        if (this.listHygienizedBed != null && this.listHygienizedBed.length > 0) {
          this.listHygienizedBed.forEach(x => {
            x.hygienizationUserName = response.listUser.find(y => y.idUser == x.idHygienizationUser).userName;
          });
        }

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getConfig() {
    this.isLoading = true;

    this.bedHygienizationConfigService.getConfig().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.hygienizationConfig = response.hygienization;
        this.model.get('hygienization').setValue(response.hygienization);
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  submit() {
    this.isLoading = true;

    let value: boolean = this.model.get('hygienization').value;
    this.hygienizationConfig = value;

    let request: BedHygienizationConfigRequest = new BedHygienizationConfigRequest();
    request.hygienization = value;

    this.bedHygienizationConfigService.switchConfig(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.listBed();
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  startHygienization(bed: BedStruct) {
    let request: BedHygienizationRequest = new BedHygienizationRequest();
    request.idBed = bed.idBed;

    const dialogRef = this.dialog.open(HygienizationModalComponent, {
      data: {
        idBed: bed.idBed,
        startHygienization: true,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.confirm && result.start) {
        this.bedHygienizationService.startBedHygienization(request).subscribe({
          next: (response) => {
            if (response.isError) {
              this.alertService.show('Erro', response.errorDescription, AlertType.error);
              this.isLoading = false;
              return;
            }

            this.listBed();
          },
          error: (error) => {
            console.log(error)
            this.isLoading = false;
            this.alertService.show('Erro inesperado', error, AlertType.error);
          }
        });
      }
    });
  }

  finishHygienization(bed: BedStruct) {
    let request: BedHygienizationRequest = new BedHygienizationRequest();
    request.idBed = bed.idBed;

    const dialogRef = this.dialog.open(HygienizationModalComponent, {
      data: {
        idBed: bed.idBed,
        startHygienization: false,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.confirm && result.finish) {
        this.bedHygienizationService.finishBedHygienization(request).subscribe({
          next: (response) => {
            if (response.isError) {
              let errorString: string = response.errorDescription;

              if (response.errorCode === 1)
                errorString = bed.hygienizationUserName.concat(errorString)

              this.alertService.show('Erro', errorString, AlertType.error);
              this.isLoading = false;
              return;
            }
            else {
              this.listBed();
              this.alertService.show('Sucesso', "Leito higienizado com sucesso", AlertType.success);
            }
          }
        });
      }
    });
  }

  openConfirmModal() {
    let value: boolean = this.hygienizationConfig;
    const dialogRef = this.dialog.open(HygienizationConfirmModalComponent, {
      data: {
        hygienization: value,
        listUnhygienizedBed: this.listUnhygienizedBed,
        listHygienizedBed: this.listHygienizedBed,
        listHygienizingBed: this.listHygienizingBed,
        listBedInUse: this.listBedInUse,
      },
    });

    dialogRef.afterClosed().subscribe({
      next: result => {
        if (!result || !result.confirm) {
          this.model.get('hygienization').setValue(value);
          return;
        }
        else if (result.confirm) {
          this.model.get('hygienization').setValue(!value);
          this.hygienizationConfig = this.model.get('hygienization').value;
          this.submit();
        }
      }
    });
  }

  private timeoutKeySearch: any = null;
  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13)
        $this.listBed(true);
        
    }, 1000);
  }

  filterListBed(listUnhygienizedBed: BedStruct[], listHygienizedBed: BedStruct[], listHygienizingBed: BedStruct[], isFilter: boolean) {
    if (isFilter) {
      this.filterListUnhygienizedBed = listUnhygienizedBed.filter(element => element.bedName.toLocaleLowerCase().includes(this.searchText.toLocaleLowerCase()) || element.bedTypeName.toLocaleLowerCase().includes(this.searchText.toLocaleLowerCase()) || element.bedCategoryName.toLocaleLowerCase().includes(this.searchText.toLocaleLowerCase()));
      this.filterListHygienizedBed = listHygienizedBed.filter(element => element.bedName.toLocaleLowerCase().includes(this.searchText.toLocaleLowerCase()) || element.bedTypeName.toLocaleLowerCase().includes(this.searchText.toLocaleLowerCase()) || element.bedCategoryName.toLocaleLowerCase().includes(this.searchText.toLocaleLowerCase()) || element.hygienizationUserName.includes(this.searchText));
      this.filterListHygienizingBed = listHygienizingBed.filter(element => element.bedName.toLocaleLowerCase().includes(this.searchText.toLocaleLowerCase()) || element.bedTypeName.toLocaleLowerCase().includes(this.searchText.toLocaleLowerCase()) || element.bedCategoryName.toLocaleLowerCase().includes(this.searchText.toLocaleLowerCase()) || element.hygienizationUserName.includes(this.searchText));
    }
    else {
      this.filterListUnhygienizedBed = listUnhygienizedBed;
      this.filterListHygienizedBed = listHygienizedBed;
      this.filterListHygienizingBed = listHygienizingBed;
    }
  }
}