<div class="container-generic">

    <app-menu [isClassification]="true" [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'
        [hasSecundaryHeader]="true" [episode]="idEpisode"  [hasCachedModule]="true" [cachedModuleName]="'classification'"
        [discriminators]="this.data.discriminatorValues" (restartMeasurers)="restartMeasurers()"></app-menu>
    
    <div [ngClass]="{'container-body-secundary-header': idEpisode !== null, 'container-body': idEpisode === null}">
        <a class="back-link" (click)="goBack()">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Usuários
        </a>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <h1 class="border-green-left-title">Seleção de Protocolo</h1>
            <div class="row">
                <div *ngFor="let array of matrixProtocol" class="col-md-3">
                    <div class="row">
                        <div *ngFor="let item of array" class="col-md-12">
                            <a mat-button (click)="selectProtocol(item.idProtocol)" type="button" class="fluxograma-button">
                                {{item.protocolName}}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</div>