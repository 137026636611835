import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { MenuComponent } from '../shared/components/menu/menu.component';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { SharedModule } from '../shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { DataSourceListComponent } from './pages/data-source-list/data-source-list.component';
import { DataSourceRegisterComponent } from './pages/data-source-register/data-source-register.component';
import { DataSourceDeleteModalComponent } from './pages/data-source-list/data-source-delete-modal/data-source-delete-modal.component';
import { ConfirmChangeTypeModalComponent } from './pages/data-source-register/confirm-change-type-modal/confirm-change-type-modal.component';
import { ColumnRulesComponent } from './pages/data-source-register/column-rules/column-rules.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTreeModule } from '@angular/material/tree';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DataSourceResultTableComponent } from './pages/data-source-register/data-source-result-table/data-source-result-table.component';
import { DashboardListComponent } from './pages/dashboard-list/dashboard-list.component';
import { DashboardDeleteModalComponent } from './pages/dashboard-list/dashboard-delete-modal/dashboard-delete-modal.component';
import { DashboardRegisterComponent } from './pages/dashboard-register/dashboard-register.component';
import { GraphRegisterModalComponent } from './pages/dashboard-register/graph-register-modal/graph-register-modal.component';
import { CancelRegisterModalComponent } from './pages/dashboard-register/cancel-register-modal/cancel-register-modal.component';
import { GraphRegisterComponent } from './pages/graph-register/graph-register.component';
import { GraphConfigModalComponent } from './pages/graph-register/graph-config-modal/graph-config-modal.component';
import { PlotGraphComponent } from './pages/graph-register/plot-graph/plot-graph.component';
import { PlotCounterComponent } from './pages/graph-register/plot-graph/plot-counter/plot-counter.component';
import { PlotTableComponent } from './pages/graph-register/plot-graph/plot-table/plot-table.component';
import { PlotColumnComponent } from './pages/graph-register/plot-graph/plot-column/plot-column.component';
import { PlotLineComponent } from './pages/graph-register/plot-graph/plot-line/plot-line.component';
import { PlotPieComponent } from './pages/graph-register/plot-graph/plot-pie/plot-pie.component';
import { NgChartsModule } from 'ng2-charts';
import { GraphDeleteModalComponent } from './pages/graph-register/graph-delete-modal/graph-delete-modal.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { NgxMaskModule } from 'ngx-mask';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [
    DashboardComponent,
    DataSourceListComponent,
    DataSourceRegisterComponent,
    DataSourceDeleteModalComponent,
    ConfirmChangeTypeModalComponent,
    ColumnRulesComponent,
    DataSourceResultTableComponent,
    DashboardListComponent,
    DashboardDeleteModalComponent,
    DashboardRegisterComponent,
    GraphRegisterModalComponent,
    CancelRegisterModalComponent,
    GraphRegisterComponent,
    GraphConfigModalComponent,
    PlotGraphComponent,
    PlotCounterComponent,
    PlotTableComponent,
    PlotColumnComponent,
    PlotLineComponent,
    PlotPieComponent,
    GraphDeleteModalComponent
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    SharedModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonModule,
    NgxMaskModule.forRoot(),
    MatCheckboxModule,
    MatTreeModule,
    MatRadioModule,
    ClipboardModule,
    MatTooltipModule,
    MatToolbarModule,
    NgxMatFileInputModule,
    CdkAccordionModule,
    MatNativeDateModule,
    MatDatepickerModule,
    NgChartsModule,
    MatDialogModule,
  ],
  providers: [
    AuthGuard
  ],
})
export class DashboardModule { }
