import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CommonService } from "src/app/shared/services/common-service";
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';
import { catchError } from "rxjs/operators";
import { SaveCompletedMedicalRecordRequest } from "../../requests/orchestrator-patient/save-completed-medical-care-record.request";
import { NewReportResponse } from "../../responses/orchestrator-patient/new-report.response";
import { MedicalRecordTelemedicineRequest } from "../../requests/orchestrator-patient/medical-record-telemedicine.request";
import { MedicalRecordTelemedicineRetrySignedRequest } from "../../requests/orchestrator-patient/medical-record-telemedicine-retry-signed.request";

@Injectable({
  providedIn: 'root'
})
export class MedicalRecordTelemedicineService extends CommonService {
  constructor(private httpClient: HttpClient) { super(); }

  public postMedicalRecordTelemedicine(content: MedicalRecordTelemedicineRequest): Observable<NewReportResponse> {
    let uri = 'MedicalRecordTelemedicine/'

    return this.httpClient.post<NewReportResponse>(environment.urlApiOrchestratorPatient + uri, content, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public retrySigned(content: MedicalRecordTelemedicineRetrySignedRequest): Observable<NewReportResponse> {
    let uri = 'MedicalRecordTelemedicine/RetrySigned'

    return this.httpClient.post<NewReportResponse>(environment.urlApiOrchestratorPatient + uri, content, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}