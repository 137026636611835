import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ReadExternalPrescriptionStruct } from 'src/app/shared/services/structs/orchestrator-patient/read-external-prescription.struct';
import { ReadMedicalPrescriptionStruct } from 'src/app/shared/services/structs/orchestrator-patient/read-medical-prescription.struct';

@Component({
  selector: 'app-external-prescription-modal',
  templateUrl: './external-prescription-modal.component.html',
  styleUrls: ['./external-prescription-modal.component.css']
})
export class ExternalPrescriptionModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ExternalPrescriptionModalComponent>,
    private alertService: AlertService,
    private formBuilder: UntypedFormBuilder
  ) { }

  public isLoading: boolean;
  public medicalPrescription: ReadExternalPrescriptionStruct;
  public prescriptionModel: UntypedFormGroup;
  public measurementUnitList: any[] = [];

  ngOnInit(): void {
    this.medicalPrescription = this.data.prescription;
    this.isLoading = false;
    this.prescriptionModel = this.formBuilder.group({
      quantity: [{ value: '', disabled: this.data.readOnly }, Validators.required],
      description: [{ value: '', disabled: this.data.readOnly }, Validators.required],
      observation: [{ value: '', disabled: this.data.readOnly }],
      frequency: [{ value: '', disabled: this.data.readOnly }, Validators.required],
      idMeasurementUnit: [{value: null,  disabled: this.data.readOnly }],
    });
    if (this.medicalPrescription) {
      this.populateValues();
    }
    this.populateLookup();
  }

  populateLookup() {
    this.measurementUnitList = this.data.lookupMedicalCareResponse.listMeasurementUnit
  }

  populateValues() {
    this.prescriptionModel.get('frequency').setValue(this.medicalPrescription.frequency);
    this.prescriptionModel.get('description').setValue(this.medicalPrescription.description);
    this.prescriptionModel.get('quantity').setValue(this.medicalPrescription.quantity);
    this.prescriptionModel.get('observation').setValue(this.medicalPrescription.observation);
    this.prescriptionModel.get('idMeasurementUnit').setValue(this.medicalPrescription.idMeasurementUnit);
  }

  clickCancel() {
    this.matDialogRef.close();
  }
  close() {
    this.matDialogRef.close();
  }

  onSubmit() {
    if (this.prescriptionModel.invalid) {
      this.alertService.show('Erro', "Preencha todos os campos obrigatórios!", AlertType.error);
      return;
    }
    let newExternalPrescription: ReadExternalPrescriptionStruct = new ReadExternalPrescriptionStruct();
    newExternalPrescription.frequency = this.prescriptionModel.get('frequency').value;
    newExternalPrescription.description = this.prescriptionModel.get('description').value;
    newExternalPrescription.quantity = this.prescriptionModel.get('quantity').value;
    newExternalPrescription.observation = this.prescriptionModel.get('observation').value;
    newExternalPrescription.idMeasurementUnit = this.prescriptionModel.get('idMeasurementUnit').value;
    newExternalPrescription.measurementUnitName =this.prescriptionModel.get('idMeasurementUnit').value? this.measurementUnitList.find(x => x.idMeasurementUnit == this.prescriptionModel.get('idMeasurementUnit').value).measurementUnitName : null;
    this.matDialogRef.close({ newExternalPrescription: newExternalPrescription });
  }
}