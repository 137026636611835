import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs/internal/Observable";
import { catchError } from "rxjs/internal/operators/catchError";
import { environment } from "src/environments/environment";
import { CommonService } from "../../common-service";
import { LookupResponse } from "../../responses/billing-by-health-unit/lookup.response";

@Injectable({
  providedIn: 'root'
})
export class LookupService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  listBillingYear(): Observable<LookupResponse> {
    let uri = `Lookup`

    return this.httpClient.get<LookupResponse>(environment.urlApiBillingByHealthUnit + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}