import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { EquipmentRequest } from '../../requests/form/equipment.request';
import { GetEquipmentResponse } from '../../responses/form/get-equipment.response';
import { ListEquipmentResponse } from '../../responses/form/list-equipment.response';


@Injectable({
  providedIn: 'root'
})
export class EquipmentService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listEquipment(searchText: string, isActive: boolean, pageIndex:number): Observable<ListEquipmentResponse> {
    
    let uri = 'Equipment?'
    
    if(searchText != null)
      uri = uri+ `searchText=${searchText}&`; 

    if(isActive !=null)
      uri = uri + `isActive=${isActive}&`

    if(pageIndex !=null)
      uri = uri + `pageIndex=${pageIndex}&`

    return this.httpClient.get<ListEquipmentResponse>(environment.urlApiForm + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public getEquipment(idEquipment: number): Observable<GetEquipmentResponse> {
    
    let uri = `Equipment/idEquipment/${idEquipment}`;  
    
    return this.httpClient.get<GetEquipmentResponse>(environment.urlApiForm + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public createEquipment(body: EquipmentRequest): Observable<ReturnStruct>{

    let uri = `Equipment`

    return this.httpClient.post<ReturnStruct>(environment.urlApiForm + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public updateEquipment(idEquipment: number, body: EquipmentRequest): Observable<ReturnStruct>{

    let uri = `Equipment/idEquipment/${idEquipment}`;

    return this.httpClient.put<ReturnStruct>(environment.urlApiForm + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public deleteEquipment(idEquipment: number): Observable<ReturnStruct>{

    let uri = `Equipment/idEquipment/${idEquipment}`;

    return this.httpClient.delete<ReturnStruct>(environment.urlApiForm + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}