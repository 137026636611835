<div [formGroup]="model" class="col-12 accordeon-padding">
    <div class="row">
        <div class="col-3 ajust-field toggle-margin">
            <mat-slide-toggle formControlName="isActive" ></mat-slide-toggle>
            <mat-label>{{this.model.get("isActive").value? 'Ativo':'Inativo'}}</mat-label>
        </div>
    </div>
    <div class="row">
        <div class="col-4 ajust-field">
            <mat-form-field appearance="outline">
                <mat-label>49 - Grau</mat-label>
                <mat-select formControlName="idParticipationDegree" >
                    <mat-option *ngFor="let participationDegree of listParticipationDegree"
                        [value]="participationDegree.idParticipationDegreeLookup">
                        {{ participationDegree.participationDegreeLookupCode }} - {{
                        participationDegree.participationDegreeDescription }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="model.get('idParticipationDegree').invalid">
                    O campo Grau é obrigatório
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-2 ajust-field">
            <mat-form-field appearance="outline">
                <mat-label>Tipo Código</mat-label>
                <mat-select formControlName="idProviderIdentityType" (selectionChange)="onChangeProviderType($event)">
                    <mat-option *ngFor="let item of listProviderIdentityType" [value]="item.idProviderIdentityType">
                        {{ item.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="model.get('idProviderIdentityType').invalid">
                    O campo Tipo Código é obrigatório
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-2">
            <mat-form-field appearance="outline">
                <mat-label>50 - Código Operadora</mat-label>
                <input matInput type="text" formControlName="codeOperator" [mask]="providerTypeMask"  [maxlength]="operatorCodeMaxLength">
                <mat-error *ngIf="model.get('codeOperator').invalid">
                    O campo é obrigatório
                </mat-error>
                <mat-hint align="end">{{ model.get('codeOperator').value ?
                    model.get('codeOperator').value.length : 0 }}/15</mat-hint>
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field appearance="outline">
                <mat-label>12 - Nome do profissional</mat-label>
                <input matInput type="text" formControlName="nameProfessional"  [maxlength]="professionalNameMaxLength">
                <mat-error *ngIf="model.get('nameProfessional').invalid">
                    O campo Nome do profissional é obrigatório
                </mat-error>
                <mat-hint align="end">{{ model.get('nameProfessional').value ?
                    model.get('nameProfessional').value.length : 0 }}/{{this.professionalNameMaxLength}}</mat-hint>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4 ajust-field">
            <mat-form-field appearance="outline">
                <mat-label>13 - Conselho</mat-label>
                <mat-select formControlName="idProfessionalCouncil" 
                    >
                    <mat-option *ngFor="let item of listProfessionalCouncil" [value]="item.idProfessionalCouncil">
                        {{item.termCode}} {{ item.councilName }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="model.get('idProfessionalCouncil').invalid">
                    O campo conselho é obrigatório
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-2">
            <mat-form-field appearance="outline">
                <mat-label>14 - Número do profissional</mat-label>
                <input matInput type="text" formControlName="numberProfessional"  [maxlength]="professionalNumberMaxLength" oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                <mat-error
                    *ngIf="model.get('numberProfessional').invalid || model.get('numberProfessional').value === undefined">
                    O campo número do profissional é obrigatório
                </mat-error>
                <mat-hint align="end">{{ model.get('numberProfessional').value ?
                    model.get('numberProfessional').value.length : 0 }}/14</mat-hint>
            </mat-form-field>
        </div>
        <div class="col-3 ajust-field">
            <mat-form-field appearance="outline">
                <mat-label>15 - UF</mat-label>
                <mat-select formControlName="idState" >
                    <mat-option *ngFor="let item of listState" [value]="item.idState">
                        {{item.stateCode}} - {{item.stateInitials}} / {{item.stateName}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="model.get('idState').invalid">
                    O campo estado é obrigatório
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-3 ajust-field">
            <mat-form-field appearance="outline">
                <mat-label>16 - CBO</mat-label>
                <mat-select formControlName="idCbo" >
                    <mat-option *ngFor="let item of listCbo" [value]="item.idCbo">
                        {{item.cboCode}} - {{item.cboName}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="model.get('idCbo').invalid">
                    O campo CBO é obrigatório
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="button-add col-6 col-sm-2" *ngIf="showAdditionalFields && !this.isEdit">
            <button color="primary" class="btn-block" mat-flat-button type="button" (click)="submit()">
                Incluir +
            </button>
        </div>
        <div class="button-add col-6 col-sm-2" *ngIf="this.isEdit">
            <button color="primary" class="btn-block" mat-flat-button type="button" (click)="editData()">
                Alterar
            </button>
        </div>
        <div class="col-6 col-sm-2" *ngIf="showAdditionalFields || this.isEdit">
            <button color="accent" class="btn-block" mat-flat-button type="button" (click)="cancelCreate()">
                Cancelar
            </button>
        </div>
        <div class="button-add col-6 col-sm-2" *ngIf="!showAdditionalFields && !this.isEdit">
            <button color="primary" class="btn-block" mat-flat-button type="button" (click)="editProfessional()">
                Editar
            </button>
        </div>
    </div>
</div>


<!--  -->