import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { DeviceTypeSectorResponse } from '../../responses/risk-classification/device-type-sector.response';
import { ListDeviceTypeResponse } from '../../responses/risk-classification/list-device-type.response';
import { retry, timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DeviceTypeService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  listDevicetype(): Observable<ListDeviceTypeResponse> {
    let uri = `DeviceType`;

    return this.httpClient.get<ListDeviceTypeResponse>(environment.urlApiRiskClassification + uri, this.addHeaderToken())
      .pipe(timeout(environment.requestTimeOut), retry(environment.requestRetry), catchError(this.handleError));
  }

  GetDevicetypeBySector(idSector: number): Observable<DeviceTypeSectorResponse> {
    let uri = `DeviceTypeSector/idSector/${idSector}`;

    return this.httpClient.get<DeviceTypeSectorResponse>(environment.urlApiRiskClassification + uri, this.addHeaderToken())
      .pipe(timeout(environment.requestTimeOut), retry(environment.requestRetry), catchError(this.handleError));
  }
}
