import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { BiPanelService } from 'src/app/shared/services/API/business-intelligence/bi-panel.service';

@Component({
  selector: 'app-bi-panel-delete-modal',
  templateUrl: './bi-panel-delete-modal.component.html',
  styleUrls: ['./bi-panel-delete-modal.component.css']
})
export class BiPanelDeleteModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<BiPanelDeleteModalComponent>,
    private biPanelService: BiPanelService,
    private alertService: AlertService
  ) { }

  public isLoading:boolean;  

  ngOnInit(): void {
    this.isLoading = false;
  }
  
  close(){
    this.matDialogRef.close({deletePanel: false}); 
  }
  
  clickDelete(){
    
    this.isLoading = true;

    this.biPanelService.deleteBiPanel(this.data.idBiPanel).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.alertService.show('Sucesso', "Painel excluido com sucesso!", AlertType.success); 
      this.matDialogRef.close({deletePanel: true}); 
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }
  
  clickCancel(){
    this.matDialogRef.close({deletePanel: false}); 
  }
}
