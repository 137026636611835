import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-time-over-modal',
  templateUrl: './time-over-modal.component.html',
  styleUrls: ['./time-over-modal.component.css']
})
export class TimeOverModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<TimeOverModalComponent>,
  ) { }

  public isLoading: boolean = false;

  ngOnInit(): void {
  }

  close() {
    this.isLoading = true;
    this.matDialogRef.close();
  }
}