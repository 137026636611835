import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { ClientModule } from './client/client.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { FrontDeskModule } from './front-desk/front-desk.module';
import { GenericCallModule } from './generic-call/generic-call.module';
import { MasterModule } from './master/master.module';
import { MedicModule } from './medic/medic.module';
import { AuthGuard } from './shared/services/auth-guard.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SADTModule } from './sadt/sadt.module';
import { AuditModule } from './audit/audit.module';
import { ClassificationModule } from './classification/classification.module';
import { IntegrationModule } from './integration/integration.module';
import { WelcomeModule } from './welcome/welcome.module';
import { ObservationModule } from './observation/observation.module';
import { PatientHistoryModule } from './patient-history/patient-history.module';
import { FormModule } from './form/form.module';
import { BillingModule } from './billing/billing.module';
import { RemoteMonitoringModule } from './remote-monitoring/remote-monitoring.module';
import { BusinessIntelligenceModule } from './business-intelligence/business-intelligence.module';
import { PharmacyModule } from './pharmacy/pharmacy.module';
import { NgChartsModule } from 'ng2-charts';
import { FlowManagementModule } from './flow-management/flow-management.module';
import { MultiProfessionalModule } from './multi-professional/multi-professional.module';
import { UbsMonitoringModule } from './ubs-monitoring/ubs-monitoring.module';
import { NgxMaskModule } from 'ngx-mask';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CareLineMonitoringModule } from './care-line-monitoring/care-line-monitoring.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DigitalPromptModule } from './digital-prompt-service/digital-prompt.module';

@NgModule({
  declarations: [

  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    DragDropModule,
    AuthModule,
    DashboardModule,
    MasterModule,
    ClientModule,
    GenericCallModule,
    ClassificationModule,
    FrontDeskModule,
    MedicModule,
    SADTModule,
    IntegrationModule,
    AuditModule,
    WelcomeModule,
    ObservationModule,
    PatientHistoryModule,
    FormModule,
    BillingModule,
    RemoteMonitoringModule,
    BusinessIntelligenceModule,
    PharmacyModule,
    NgChartsModule,
    NgxMaskModule,
    NgxChartsModule,
    FlowManagementModule,
    MultiProfessionalModule,
    UbsMonitoringModule,
    CareLineMonitoringModule,
    DigitalPromptModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      //enabled: false,
      enabled: environment.production || environment.staging
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      //registrationStrategy: 'registerWhenStable:30000'
    }),
    BrowserAnimationsModule,
  ],
  providers: [
    AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
