import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { FormComponent } from './pages/form/form.component';
import { FormEpisodeListComponent } from './pages/form-episode-list/form-episode-list.component';
import { FormRegisterComponent } from './pages/form-register/form-register.component';
import { EquipmentListComponent } from './pages/equipment-list/equipment-list.component';
import { EquipmentRegisterComponent } from './pages/equipment-register/equipment-register.component';
import { FormMedicalScheduleListComponent } from './pages/form-medical-schedule-list/form-medical-schedule-list.component';
import { FormMedicalScheduleRegisterComponent } from './pages/form-medical-schedule-register/form-medical-schedule-register.component';

const routes: Routes = [
  { path: 'form', component: FormComponent, canActivate: [AuthGuard] },
  { path: 'form/form-episode-list', component: FormEpisodeListComponent, canActivate: [AuthGuard] },
  { path: 'form/form-episode-list/register', component: FormRegisterComponent, canActivate: [AuthGuard] },
  { path: 'form/equipment', component: EquipmentListComponent, canActivate: [AuthGuard] },
  { path: 'form/equipment/register', component: EquipmentRegisterComponent, canActivate: [AuthGuard] },
  { path: 'form/medical-schedule', component: FormMedicalScheduleListComponent, canActivate: [AuthGuard] },
  { path: 'form/medical-schedule/register', component: FormMedicalScheduleRegisterComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FormRoutingModule { }
