import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { PlanRequest } from '../../requests/private-billing/plan.request';
import { PlanResponse } from '../../responses/private-billing/plan.response';
import { ListPlanResponse } from '../../responses/private-billing/list-plan.response';

@Injectable({
  providedIn: 'root'
})
export class PlanService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  public GetAll(idHealthcareAgreement: number, searchText: string, pageIndex: number): Observable<ListPlanResponse> {
    let uri = `Plan?idHealthcareAgreement=${idHealthcareAgreement}&`;

    if (searchText)
      uri = uri + `searchText=${searchText}&`

    if (pageIndex)
      uri = uri + `pageIndex=${pageIndex}`;

    return this.httpClient.get<ListPlanResponse>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public GetAllToSelectByListId(body: number[] = []): Observable<ListPlanResponse> {
    let uri = `Plan/getAllByListIdHealthcareAgreement`

    return this.httpClient.post<ListPlanResponse>(environment.urlApiPrivateBilling + uri, body, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public GetAllToSelectById(idHealthcareAgreement: number, searchText?: string, maxSizeSelect?: number): Observable<ListPlanResponse> {
    let uri = `plan/getAllByIdHealthcareAgreement?idHealthcareAgreement=${idHealthcareAgreement}&`

    if (searchText)
      uri = uri + `searchText=${searchText}&`

    if (maxSizeSelect)
      uri = uri + `maxSizeSelect=${maxSizeSelect}`;

    return this.httpClient.get<ListPlanResponse>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public Post(body: PlanRequest): Observable<PlanResponse> {
    let uri = `Plan`

    return this.httpClient.post<PlanResponse>(environment.urlApiPrivateBilling + uri, body, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public Put(body: PlanRequest, idPlan: number): Observable<PlanResponse> {
    let uri = `Plan/idPlan/${idPlan}`

    return this.httpClient.put<PlanResponse>(environment.urlApiPrivateBilling + uri, body, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public delete(idPlan: number): Observable<ReturnStruct> {
    let uri = `Plan/idPlan/${idPlan}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}