import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilsClassificationService } from 'src/app/classification/utils.service';
import { DiscriminatorTypeEnum } from 'src/app/shared/enum/discriminator-type.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { DiscriminatorModel } from 'src/app/shared/services/models/risk-classification/discriminator.model';
import { UtilsMeasurerService } from '../../protocol-v2/utils-measurer.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { HumanizedHeaderResponse } from 'src/app/shared/services/responses/orchestrator-patient/humanized-header.response';
import { DeviceTypeSectorResponse } from 'src/app/shared/services/responses/risk-classification/device-type-sector.response';


@Component({
  selector: 'app-temperature-modal',
  templateUrl: './temperature-modal.component.html',
  styleUrls: ['./temperature-modal.component.css']
})
export class TemperatureModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<TemperatureModalComponent>,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private utilClassificationService: UtilsClassificationService,
    private utilService: UtilService,
    private maskService: MaskService,
    public measurerService: UtilsMeasurerService) { }


  public model: UntypedFormGroup;
  public discriminator: DiscriminatorModel;
  public masks: Masks;
  public initValue: any = null;
  public discriminatorType: DiscriminatorTypeEnum = DiscriminatorTypeEnum.Temperatura;
  public classification: any;
  public autisticChecked: boolean = false;
  public setUppercaseText: boolean = false;

  ngOnInit(): void {
    let deviceTypeSectorResponse: DeviceTypeSectorResponse = this.utilClassificationService.getDevicetypeBySector()
    if (deviceTypeSectorResponse && deviceTypeSectorResponse.deviceTypeSector) {
      if (deviceTypeSectorResponse.deviceTypeSector.setUppercaseText)
        this.setUppercaseText = deviceTypeSectorResponse.deviceTypeSector.setUppercaseText;
    }

    this.classification = this.utilService.getSecundaryHeaderModuleConfig(this.data.cachedModuleName);
    this.measurerService.setModal(this.discriminatorType);
    this.masks = this.maskService.getMasks();
    this.discriminator = this.data.discriminator;
    this.initValue = this.data.discriminatorsValues[this.discriminatorType];
    this.model = this.formBuilder.group({
      temperature: [this.data.discriminatorsValues[this.discriminatorType], Validators.required],
      discriminatorSelectionMotive: [],
      priorityAutistic: [false]
    });
  }

  close() {
    this.measurerService.setModal(null);
    this.matDialogRef.close();
  }

  changePriorityAutistic(event: any) {
    this.model.get('temperature').setValue(null);
    this.autisticChecked = event.checked
    if (event.checked) {
      this.model.get('discriminatorSelectionMotive').setValidators([Validators.required]);
      this.model.get('temperature').disable();
    } else {
      this.model.get('discriminatorSelectionMotive').setValidators(Validators.nullValidator);
      this.model.get('temperature').enable();
    }
    this.model.get('discriminatorSelectionMotive').updateValueAndValidity();
  }

  submit() {
    if (this.data.discriminatorsValues[this.discriminatorType] && (this.initValue == this.model.get("temperature").value || (this.initValue != this.data.discriminatorsValues[this.discriminatorType] && !this.model.get("temperature").value))) {
      this.model.get("temperature").setValue(this.data.discriminatorsValues[this.discriminatorType]);
    }
    if (this.model.invalid) {
      if (this.model.get('priorityAutistic').value) {
        this.alertService.show('Erro', "Preencha o motivo!", AlertType.error);
      } else {
        this.alertService.show('Erro', "Preencha o campo de temperatura corretamente!", AlertType.error);
      }
      return;
    }
    if (this.model.get('priorityAutistic').value) {
      this.matDialogRef.close({ value: null, select: true, motive: this.model.get('discriminatorSelectionMotive').value });
    } else {
      let value: string = this.model.get("temperature").value;
      value = value.match(/\d+/g).join('')
      let valueNumber = this.maskService.formatTemperature(value);
      let select = this.utilClassificationService.verifySelectGeneric(this.discriminator.minValueAllowed, this.discriminator.maxValueAllowed, valueNumber);
      this.measurerService.setModal(null);
      select = !this.data.isSepse && select;
      this.matDialogRef.close({ value: value[0] + value[1] + "." + value[2], select });
    }
  }
}

