<div class="white-body">
    <div class="title-header">
        <h1>{{flowchartName}}</h1>
    </div>
    <div *ngIf="discriminatorResponse && discriminatorResponse.discriminator.length > 0" class="row">
        <app-protocol-v2 *ngIf="!outOfOrder" (previousPage)="previousFirstPage($event)"
            (selectedValue)="selectDiscriminator($event)" [discriminatorResponse]="discriminatorResponse"
            [discriminators]="discriminators">
        </app-protocol-v2>
        <app-protocol-v1 *ngIf="outOfOrder" (previousPage)="previousFirstPage($event)"
            (selectedValue)="selectDiscriminator($event)" [discriminatorResponse]="discriminatorResponse"
            [discriminators]="discriminators">
        </app-protocol-v1>
    </div>
    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
</div>