import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CommonService } from "src/app/shared/services/common-service";
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';
import { catchError } from "rxjs/operators";
import { MedicalRecordResponse } from "../../responses/orchestrator-patient/medical-record.response";
import { MedicalProcedureResponseModel } from "../../models/medical-record/medical-procedure-response.model";
import { ReturnStruct } from "src/app/shared/structs/return.struct";
import { MaterialNonDispensationRequest } from "../../requests/pharmacy/material-non-dispensation.request";

@Injectable({
  providedIn: 'root'
})
export class MaterialNonDispensationService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }  
  
  public nonDispensation(body: MaterialNonDispensationRequest): Observable<ReturnStruct> {
    let uri = `MaterialNonDispensation/`

    return this.httpClient.put<ReturnStruct>(environment.urlApiPharmacy + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}