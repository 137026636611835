<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <a class="back-link" routerLink="/care-line-monitoring">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon>
            Módulo Linhas de Cuidado
        </a>
        <h1>Configurações de Linhas de Cuidado</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <form class="form" [formGroup]="accountModel" (ngSubmit)="saveTwilioData()" *ngIf="!isLoading">
                <div class="title-header">
                    <h1>Dados Twilio</h1>
                    <a *ngIf="boundAccount" (click)="toggleEditTwilioData()">
                        <mat-icon *ngIf="!editTwilioData" matTooltip="Editar" class="edit-icon">edit</mat-icon>
                        <mat-icon *ngIf="editTwilioData" matTooltip="Bloquear" class="edit-icon">edit_off</mat-icon>
                    </a>
                </div>
                <div class="row">
                    <div class="col-4 col-sm-4 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Identificador da conta</mat-label>
                            <input matInput type="text" formControlName="twilioAccountSid">
                        </mat-form-field>
                    </div>
                    <div class="col-4 col-sm-4 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Token de autenticação</mat-label>
                            <input matInput type="text" formControlName="twilioAccountToken">
                        </mat-form-field>
                    </div>
                    <div class="col-4 col-sm-4 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Número de telefone</mat-label>
                            <input matInput type="text" formControlName="twilioPhoneNumber">
                            <mat-error *ngIf="accountModel.controls['twilioPhoneNumber'].errors?.invalidPhoneInfo">
                                Preencha um desses campos
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-4 col-sm-4 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>SID do serviço de mensagem</mat-label>
                            <input matInput type="text" formControlName="twilioMessagingServiceSid">
                            <mat-error
                                *ngIf="accountModel.controls['twilioMessagingServiceSid'].errors?.invalidPhoneInfo">
                                Preencha um desses campos
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-2">
                        <button
                            matTooltip="{{accountModel.errors ?.invalidPhoneInfo ? 'Preencha um dos campos em vermelho' : 'teste'}}"
                            [disabled]="accountModel.pristine || accountModel.invalid" mat-flat-button type="submit"
                            color="primary" class=" btn-block">
                            <span *ngIf="isLoading == false">Salvar dados da conta</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                </div>
            </form>
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="title-header">
                    <h1>Linhas de Cuidado</h1>
                </div>
                <div *ngIf="!isLoading && boundAccount">
                    <div class="row" formArrayName="listCareLine"
                        *ngFor="let item of model.get('listCareLine')['controls']; let i = index">
                        <div class="row" [formGroupName]="i">
                            <div class="col-4 col-sm-4 col-md-4">
                                <mat-form-field appearance="outline">
                                    <mat-label>Informe o nome</mat-label>
                                    <input matInput formControlName="careLineName">
                                </mat-form-field>
                            </div>
                            <div class="col-4 col-sm-4 col-md-4">
                                <mat-form-field appearance="outline">
                                    <mat-label>Fluxo Twilio</mat-label>
                                    <input matInput formControlName="twilioFlowName">
                                </mat-form-field>
                            </div>
                            <div class="col-2 col-sm-2 col-md-2">
                                <mat-slide-toggle formControlName="isActive" (change)="updateRequirements(i)">
                                    {{model.controls['listCareLine'].controls[i].get('isActive').value ? 'Ativa' :
                                    'Inativa'}}
                                </mat-slide-toggle>
                            </div>
                            <!-- <div class="col-2 col-sm-2 col-md-2">
                                <button class="exception-button " color="primary" mat-mini-fab type="button"
                                    (click)="addNext(i)">
                                    <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
                                </button>
                                <button
                                    *ngIf="model.controls['listCareLine'].controls[i].get('idCareLine').value == null"
                                    class="exception-button " color="primary" mat-mini-fab type="button"
                                    (click)="remove(i)">
                                    <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
                                </button>
                                <button
                                    *ngIf="model.controls['listCareLine'].controls[i].get('idCareLine').value != null"
                                    class="exception-button " color="primary" mat-mini-fab type="button"
                                    (click)="remove(i)" matTooltip="Deletar permanentemente">
                                    <mat-icon aria-hidden="false" aria-label="Menu apps">delete</mat-icon>
                                </button>
                            </div> -->
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-2">
                        <button [disabled]="editTwilioData" mat-flat-button type="submit" color="primary"
                            class=" btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>