export class PriceProcedurePlanStruct {
    public idPriceProcedurePlan: number;
    public idProcedurePlan: number;
    public idPlan: number;
    public idProcedure: number;
    public idUser: number;
    public value: number;
    public valueFront: string;

    public nameProcedure: string;
    public planName: string;

    public isActive: boolean;
}