import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-patient-arrived-early-late-modal',
  templateUrl: './patient-arrived-early-late-modal.component.html',
  styleUrls: ['./patient-arrived-early-late-modal.component.css']
})
export class PatientArrivedEarlyLateModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<PatientArrivedEarlyLateModalComponent>
  ) { this.matDialogRef.disableClose = true; }

  isLoading: boolean;
  arrivedEarly: number = 3;
  arrivedLate: number = 4;
  arrived: number;
  ngOnInit(): void {
    this.arrived = this.data.arrived;
    this.isLoading = false;
  }

  return() {
    if (this.isLoading)
      return;

    this.isLoading = true;
    this.matDialogRef.close(true);
  }
}