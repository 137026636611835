import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-totem-end-hygienization-modal',
  templateUrl: './totem-end-hygienization-modal.component.html',
  styleUrls: ['./totem-end-hygienization-modal.component.css']
})
export class TotemEndHygienizationModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<TotemEndHygienizationModalComponent>,
  ) {
    this.matDialogRef.disableClose = true;
  }

  public isLoading: boolean;

  ngOnInit(): void {
    this.isLoading = false;
  }

  submit() {
    if (this.isLoading)
      return;

    this.isLoading = true;
    this.matDialogRef.close({ evade: true });
  }

  clickCancel() {
    this.matDialogRef.close({ evade: false });
  }
}