<div class="white-body">
    <h1>Dados do paciente</h1>
    <div class="row">
        <mat-label>
            Gravidade: {{this.data.priority.priorityColorName}}
        </mat-label>
    </div>
    <div class="row">
        <mat-label>
            Nome: {{this.data.patientData.patientName}}
        </mat-label>
    </div>
    <div class="row">
        <mat-label>
            Idade: {{this.patientAge}}
        </mat-label>
    </div>
    <h1>Aconselhamentos</h1>
    <form class="form" [formGroup]="model" (ngSubmit)="submit()">
        <div class="row">
            <div class="col-md-6 col-separador">
                <div class="row">
                    <div class="col-md-9">
                        <div *ngFor="let counseling of listCounseling" class="row">
                            <mat-checkbox [ngModelOptions]="{standalone: true}" [(ngModel)]="counseling.isChecked"
                                [checked]="counseling.isChecked" [disabled]="noneOfTheAbove.isChecked? true : null">
                                {{counseling.counselingName}}
                            </mat-checkbox>
                        </div>
                        <div class="row">
                            <mat-checkbox [ngModelOptions]="{standalone: true}" [(ngModel)]="noneOfTheAbove.isChecked"
                                [checked]="noneOfTheAbove.isChecked" (change)="noneCheck()">
                                {{noneOfTheAbove.counselingName}}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Observação</mat-label>
                            <textarea matInput formControlName="observation"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <a mat-flat-button type="button" (click)="goBack()" color="accent" class=" btn-block">
                            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Voltar
                        </a>
                    </div>
                    <div class="col-6">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            Avançar <mat-icon aria-hidden="false" aria-label="Plus">arrow_forward</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>