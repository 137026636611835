<div class="container-generic">
    <div mat-dialog-content>
        <div class="mat-dialog-content">
            <div class="head-front-desk-modal-list">
                <h2>Cadastro de Paciente</h2>
            </div>
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <mat-form-field appearance="outline" class="outline-spinner">
                            <mat-label>CPF</mat-label>
                            <input matInput type="text" formControlName="cpf" [mask]="masks.cpf"
                                (keyup)="onKeySearchCPF($event)">
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoadingCpf == true"></mat-spinner>
                            <mat-error *ngIf="model.get('cpf').invalid">Informe o cpf</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6">
                        <mat-form-field appearance="outline" class="outline-spinner">
                            <mat-label>CNS</mat-label>
                            <input matInput type="text" formControlName="cns" [mask]="masks.cns"
                                (keyup)="onKeySearchCNS($event)">
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoadingCns == true"></mat-spinner>
                            <mat-error *ngIf="model.get('cns').invalid">Informe o cpf</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Patient Name</mat-label>
                            <input matInput type="text" formControlName="patientName">
                            <mat-error *ngIf="model.get('patientName').invalid">Informe o nome do paciente</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Data de nascimento</mat-label>
                            <input matInput type="text" formControlName="birthDate" [mask]="masks.date"
                                (keyup)="onKeySearchAge($event)">
                            <mat-error *ngIf="model.get('birthDate').invalid">Informe a data de nascimento</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-2">
                        <label>Idade:</label>
                        <p>{{patientAge}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Gênero</mat-label>
                            <mat-select formControlName="idGender">
                                <mat-option *ngFor="let item of genderList"
                                    value="{{item.idGender}}">{{item.genderName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6">
                        <mat-form-field appearance="outline" class="outline-spinner">
                            <mat-label>Telefone</mat-label>
                            <input matInput type="text" formControlName="phoneNumber" [mask]="masks.phone">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-sm-6">
                        <mat-form-field appearance="outline" class="outline-spinner">
                            <mat-label>Empresa</mat-label>
                            <input matInput type="text" formControlName="healthUnitName">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6">
                        <mat-form-field appearance="outline" class="outline-spinner">
                            <mat-label>Cargo</mat-label>
                            <input matInput type="text" formControlName="roleEmployee">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-sm-6">
                        <mat-form-field appearance="outline" class="outline-spinner">
                            <mat-label>Filial/Unidade</mat-label>
                            <input matInput type="text" formControlName="unitBranch">
                        </mat-form-field>
                    </div>

                    <div class="col-12 col-sm-6">
                        <mat-form-field appearance="outline" class="outline-spinner">
                            <mat-label>Setor</mat-label>
                            <input matInput type="text" formControlName="sectorEmployee">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-sm-6">
                        <mat-form-field appearance="outline" class="outline-spinner">
                            <mat-label>Matrícula</mat-label>
                            <input matInput type="text" formControlName="registryEmployee">
                        </mat-form-field>
                    </div>
                </div>

                <div class="title-header">
                    <h1>Endereço</h1>
                </div>
                <div class="row">
                    <div class="col-6 col-sm-3 col-md-3">
                        <mat-form-field appearance="outline" class="outline-spinner">
                            <mat-label>CEP</mat-label>
                            <input matInput type="text" formControlName="zipCode" (keyup)="onKeySearchCEP($event)">
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoadingCep == true"></mat-spinner>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Logradouro</mat-label>
                            <input matInput type="text" formControlName="street">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Número</mat-label>
                            <input matInput type="number" formControlName="houseNumber">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Complemento</mat-label>
                            <input matInput type="text" formControlName="apartmentNumber">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Bairro</mat-label>
                            <input matInput type="text" formControlName="neighborhood">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Cidade</mat-label>
                            <input matInput type="text" formControlName="city">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Estado</mat-label>
                            <input matInput type="text" formControlName="state">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>País</mat-label>
                            <input matInput type="text" formControlName="country">
                        </mat-form-field>
                    </div>
                </div>
                <div class="title-header">
                    <h1>Informações para acompanhamento</h1>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Linhas de cuidado</mat-label>
                            <mat-select formControlName="listIdCareLine" multiple>
                                <mat-option *ngFor="let item of listCareLine" value="{{item.idCareLine}}">
                                    {{item.careLineName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Doenças</mat-label>
                            <mat-select formControlName="listIdComorbidity" multiple (selectionChange)="checkOutros()">
                                <mat-option *ngFor="let item of listComorbidity" value="{{item.idComorbidity}}">
                                    {{item.comorbidityName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row" *ngIf="hasComorbidityOutros">
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Outras Comorbidades</mat-label>
                            <textarea matInput formControlName="otherComorbidity"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Medicação</mat-label>
                            <mat-select formControlName="listIdMedicine" multiple
                                (selectionChange)="checkMedicineOutros()">
                                <mat-option value="{{idMedicineOutros}}">OUTROS</mat-option>
                                <mat-option *ngFor="let item of listMedication"
                                    value="{{item.medicine.idMedicine}}">{{item.medicine.medicineDescription}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row" *ngIf="hasMedicationOutros">
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Outras Medicações</mat-label>
                            <textarea matInput formControlName="otherMedicine"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="footer-front-desk-modal-list">
                    <button mat-flat-button type="submit" color="primary" class=" btn-block">
                        <span *ngIf="isLoading == false">Salvar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                    <button type="button" mat-flat-button color="accent" class="btn-block"
                        (click)="clickCancel()">Cancelar</button>
                </div>
            </form>
        </div>
    </div>
</div>