<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <a class="back-link" routerLink="/form/form-episode-list">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Atendimentos
        </a>
        <h1>Cadastrar Atendimento</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="row">
                    <div class="col-6 col-sm-6 col-md-6">
                        <h1>Dados Pessoais</h1>
                        <mat-slide-toggle *ngIf="this.isEditable" class="indigent-checkbox toggle"
                            [ngModelOptions]="{standalone: true}" (change)="changeIndigent($event)"
                            [(ngModel)]="this.isUnidentifiedPatient">Paciente não
                            identificado</mat-slide-toggle>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Nome</mat-label>
                                <input matInput type="text" formControlName="patientName">
                                <mat-error *ngIf="model.get('patientName').invalid">Informe o nome do
                                    paciente</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Nome Social</mat-label>
                                <input matInput type="text" formControlName="socialName">
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Data de Nascimento</mat-label>
                                <input matInput type="text" (keyup)="onKeySearchAge($event)" formControlName="birthDate"
                                    [mask]="masks.date">
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                            <label>Idade:</label>
                            <p>{{patientAge}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Gênero</mat-label>
                                <mat-select formControlName="idGender">
                                    <mat-option *ngFor="let item of genderList" value="{{item.idGender}}">
                                        {{item.genderName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline" class="outline-spinner">
                                <mat-label>CPF</mat-label>
                                <input matInput type="text" formControlName="cpf" [mask]="masks.cpf"
                                    (keyup)="onKeySearchCPF($event)">
                                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoadingCpf == true">
                                </mat-spinner>
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline" class="outline-spinner">
                                <mat-label>CNS</mat-label>
                                <input matInput type="text" formControlName="cns" (keyup)="onKeySearchCNS($event)">
                                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoadingCns == true">
                                </mat-spinner>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="title-header-form">
                        <h1>Endereço</h1>
                    </div>
                    <div class="row">
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline" class="outline-spinner">
                                <mat-label>CEP</mat-label>
                                <input matInput type="text" formControlName="zipCode" (keyup)="onKeySearchCEP($event)">
                                <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                    *ngIf="isLoadingCep == true"></mat-spinner>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Logradouro</mat-label>
                                <input matInput type="text" formControlName="street">
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Número</mat-label>
                                <input matInput type="number" formControlName="houseNumber">
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Complemento</mat-label>
                                <input matInput type="text" formControlName="apartmentNumber">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Bairro</mat-label>
                                <input matInput type="text" formControlName="neighborhood">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Cidade</mat-label>
                                <input matInput type="text" formControlName="city">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Estado</mat-label>
                                <input matInput type="text" formControlName="state">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>País</mat-label>
                                <input matInput type="text" formControlName="country">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="title-header-form">
                        <h1>Dados do episódio</h1>
                    </div>
                    <div class="row">
                        <div class="col-6 col-sm-6 col-md-6 toggle">
                            <mat-slide-toggle *ngIf="this.isEditable" class="indigent-checkbox"
                                [ngModelOptions]="{standalone: true}" [(ngModel)]="this.isPriority">Senha
                                prioritária</mat-slide-toggle>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Data inicial do episódio</mat-label>
                                <input matInput formControlName="datetimeStartEpisode"
                                    [matDatepicker]="datetimeStartEpisodePicker">
                                <mat-datepicker-toggle matSuffix
                                    [for]="datetimeStartEpisodePicker"></mat-datepicker-toggle>
                                <mat-datepicker #datetimeStartEpisodePicker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Hora inicial do episódio</mat-label>
                                <input matInput type="text" formControlName="hourStartEpisode" [mask]="masks.time">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Data final do episódio</mat-label>
                                <input matInput formControlName="datetimeFinishEpisode"
                                    [matDatepicker]="datetimeFinishEpisodePicker">
                                <mat-datepicker-toggle matSuffix
                                    [for]="datetimeFinishEpisodePicker"></mat-datepicker-toggle>
                                <mat-datepicker #datetimeFinishEpisodePicker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Hora final do episódio</mat-label>
                                <input matInput type="text" formControlName="hourFinishEpisode" [mask]="masks.time">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="title-header-form">
                        <h1>Dados da Recepção</h1>
                    </div>
                    <div class="row">

                        <div class="col-12 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Data inicial de recepção</mat-label>
                                <input matInput formControlName="datetimeStartReception"
                                    [matDatepicker]="datetimeStartReceptionPicker">
                                <mat-datepicker-toggle matSuffix
                                    [for]="datetimeStartReceptionPicker"></mat-datepicker-toggle>
                                <mat-datepicker #datetimeStartReceptionPicker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Hora inicial de recepção</mat-label>
                                <input matInput type="text" formControlName="hourStartReception" [mask]="masks.time">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Data final de recepção</mat-label>
                                <input matInput formControlName="datetimeFinishReception"
                                    [matDatepicker]="datetimeFinishReceptionPicker">
                                <mat-datepicker-toggle matSuffix
                                    [for]="datetimeFinishReceptionPicker"></mat-datepicker-toggle>
                                <mat-datepicker #datetimeFinishReceptionPicker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Hora final de recepção</mat-label>
                                <input matInput type="text" formControlName="hourFinishReception" [mask]="masks.time">
                            </mat-form-field>
                        </div>

                    </div>
                    <div class="title-header-form">
                        <h1>Dados da Classificação</h1>
                    </div>
                    <div class="row">

                        <div class="col-6 col-sm-6 col-md-6" style="padding-bottom:10px">
                            <mat-slide-toggle *ngIf="this.isEditable" class="indigent-checkbox"
                                [ngModelOptions]="{standalone: true}" [(ngModel)]="this.isCovidSuspect">Suspeita
                                Covid</mat-slide-toggle>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-12 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Data inicial de classificação</mat-label>
                                <input matInput formControlName="datetimeStartClassification"
                                    [matDatepicker]="datetimeStartClassificationPicker">
                                <mat-datepicker-toggle matSuffix
                                    [for]="datetimeStartClassificationPicker"></mat-datepicker-toggle>
                                <mat-datepicker #datetimeStartClassificationPicker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Hora inicial de classificação</mat-label>
                                <input matInput type="text" formControlName="hourStartClassification"
                                    [mask]="masks.time">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Data final de classificação</mat-label>
                                <input matInput formControlName="datetimeFinishClassification"
                                    [matDatepicker]="datetimeFinishClassificationPicker">
                                <mat-datepicker-toggle matSuffix
                                    [for]="datetimeFinishClassificationPicker"></mat-datepicker-toggle>
                                <mat-datepicker #datetimeFinishClassificationPicker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Hora final de classificação</mat-label>
                                <input matInput type="text" formControlName="hourFinishClassification"
                                    [mask]="masks.time">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 col-sm-3 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Sala de Classificação</mat-label>
                                <mat-select formControlName="idClassificationRoom">
                                    <mat-option *ngFor="let item of classificationRoomList" value="{{item.idRoom}}">
                                        {{item.roomName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Origem</mat-label>
                                <mat-select formControlName="idOrigin">
                                    <mat-option value="1"> Domicilio </mat-option>
                                    <mat-option value="2"> Hospital </mat-option>
                                    <mat-option value="3"> UBS </mat-option>
                                    <mat-option value="4"> UPA </mat-option>
                                    <mat-option value="5"> Outro </mat-option>
                                    <mat-option value="10006"> Pacto Regional </mat-option>
                                    <mat-option value="10007"> Serviços de privação da liberdade </mat-option>
                                    <mat-option value="10008"> Espontâneo </mat-option>
                                    <mat-option value="10009"> Regulação CRUE / CROSS </mat-option>
                                    <mat-option value="10010"> Outro Município </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Tipo de Chegada</mat-label>
                                <mat-select formControlName="idArrivalType">
                                    <mat-option value="1"> Espontânea </mat-option>
                                    <mat-option value="2"> Ambulância </mat-option>
                                    <mat-option value="3"> Corpo de bombeiros </mat-option>
                                    <mat-option value="4"> Outro </mat-option>
                                    <mat-option value="10005"> Polícia Militar </mat-option>
                                    <mat-option value="10006"> Polícia Civil </mat-option>
                                    <mat-option value="10007"> Polícia Federal </mat-option>
                                    <mat-option value="10008"> Transporte Aéreo </mat-option>
                                    <mat-option value="10009"> Expontânea </mat-option>
                                    <mat-option value="10010"> Grau </mat-option>
                                    <mat-option value="10011"> Pacto Regional </mat-option>
                                    <mat-option value="10012"> Viatura </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Razão de Chegada</mat-label>
                                <mat-select formControlName="idArrivalReason">
                                    <mat-option value="1"> Cirurgia Eletiva </mat-option>
                                    <mat-option value="2"> Consulta de retorno </mat-option>
                                    <mat-option value="3"> Realização de exames </mat-option>
                                    <mat-option value="4"> Tratamento </mat-option>
                                    <mat-option value="5"> Realização de procedimentos </mat-option>
                                    <mat-option value="6"> Transplante </mat-option>
                                    <mat-option value="7"> Outros </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">

                        <!--<div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Origem</mat-label>
                            <input matInput type="text"
                                formControlName="origin">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Tipo de Chegada</mat-label>
                            <input matInput type="text"
                                formControlName="arrivalType">
                        </mat-form-field>
                    </div>-->
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Protocolo</mat-label>
                                <mat-select formControlName="idProtocol"
                                    (selectionChange)="changeProtocol($event.value)">
                                    <mat-option *ngFor="let item of protocolList" value="{{item.idProtocol}}">
                                        {{item.protocolName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Fluxograma</mat-label>
                                <mat-select formControlName="idFlowchart"
                                    (selectionChange)="changeFlowchartOrGravity()">
                                    <mat-option *ngFor="let item of flowchartList" value="{{item.idFlowchart}}">
                                        {{item.flowchartName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Gravidade</mat-label>
                                <mat-select formControlName="idGravity" (selectionChange)="changeFlowchartOrGravity()">
                                    <mat-option *ngFor="let item of priorityColorsList"
                                        value="{{item.idPriorityColor}}">
                                        {{item.priorityColorName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Discriminador</mat-label>
                                <mat-select formControlName="idDiscriminator">
                                    <mat-option *ngFor="let item of discriminatorList" value="{{item.idDiscriminator}}">
                                        {{item.discriminatorName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Data inicial de reclassificação </mat-label>
                                <input matInput formControlName="datetimeStartReclassification"
                                    [matDatepicker]="datetimeStartReclassificationPicker">
                                <mat-datepicker-toggle matSuffix
                                    [for]="datetimeStartReclassificationPicker"></mat-datepicker-toggle>
                                <mat-datepicker #datetimeStartReclassificationPicker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Hora inicial de reclassificação</mat-label>
                                <input matInput type="text" formControlName="hourStartReclassification"
                                    [mask]="masks.time">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Data final de reclassificação</mat-label>
                                <input matInput formControlName="datetimeFinishReclassification"
                                    [matDatepicker]="datetimeFinishReclassificationPicker">
                                <mat-datepicker-toggle matSuffix
                                    [for]="datetimeFinishReclassificationPicker"></mat-datepicker-toggle>
                                <mat-datepicker #datetimeFinishReclassificationPicker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Hora final de reclassificação</mat-label>
                                <input matInput type="text" formControlName="hourFinishReclassification"
                                    [mask]="masks.time">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="title-header-form">
                        <h1>Dados do Médico</h1>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Data inicial de atendimento médico</mat-label>
                                <input matInput formControlName="datetimeStartMedicalAtt"
                                    [matDatepicker]="datetimeStartMedicalAttPicker">
                                <mat-datepicker-toggle matSuffix
                                    [for]="datetimeStartMedicalAttPicker"></mat-datepicker-toggle>
                                <mat-datepicker #datetimeStartMedicalAttPicker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Hora inicial de atendimento médico</mat-label>
                                <input matInput type="text" formControlName="hourStartMedicalAtt" [mask]="masks.time">
                            </mat-form-field>
                        </div>

                        <div class="col-12 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Data final de atendimento médico</mat-label>
                                <input matInput formControlName="datetimeFinishMedicalAtt"
                                    [matDatepicker]="datetimeFinishMedicalAttPicker">
                                <mat-datepicker-toggle matSuffix
                                    [for]="datetimeFinishMedicalAttPicker"></mat-datepicker-toggle>
                                <mat-datepicker #datetimeFinishMedicalAttPicker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Hora final de atendimento médico</mat-label>
                                <input matInput type="text" formControlName="hourFinishMedicalAtt" [mask]="masks.time">
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Sala do atendimento médico</mat-label>
                                <mat-select formControlName="idMedicRoom">
                                    <mat-option *ngFor="let item of medicRoomList" value="{{item.idRoom}}">
                                        {{item.roomName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Status</mat-label>
                                <mat-select formControlName="listStatus" multiple>
                                    <mat-option *ngFor="let item of statusList" value="{{item.idStatus}}">
                                        {{item.statusName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Exames</mat-label>
                                <mat-select formControlName="listExam" multiple>
                                    <mat-option *ngFor="let item of examList" value="{{item.idExam}}">
                                        {{item.examName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Encaminhamento</mat-label>
                                <mat-select formControlName="idDestination">
                                    <mat-option *ngFor="let item of allDestinationList" value="{{item.idDestination}}">
                                        {{item.destinationName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-4 col-md-4" id="getCid">
                            <app-select-2 [formControlField]="'idCid'" value="" (selectedValue)="addItemCid($event)"
                                [url]="urlListCid" [idField]="'idCid'" [nameField]="'cidName'" [labelField]="'CID'">
                            </app-select-2>
                        </div>
                        <div class="col-6 col-sm-3 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Desfecho Assistencial</mat-label>
                                <mat-select formControlName="idConclusionType">
                                    <mat-option value="3">
                                        Alta
                                    </mat-option>
                                    <mat-option value="5">
                                        Internação
                                    </mat-option>
                                    <mat-option value="4">
                                        Óbito
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Data inicial de reavaliação médica</mat-label>
                                <input matInput formControlName="datetimeStartReturnMedicalAtt"
                                    [matDatepicker]="datetimeStartReturnMedicalAttPicker">
                                <mat-datepicker-toggle matSuffix
                                    [for]="datetimeStartReturnMedicalAttPicker"></mat-datepicker-toggle>
                                <mat-datepicker #datetimeStartReturnMedicalAttPicker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Hora inicial de reavaliação médica</mat-label>
                                <input matInput type="text" formControlName="hourStartReturnMedicalAtt"
                                    [mask]="masks.time">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Data final de reavaliação médica</mat-label>
                                <input matInput formControlName="datetimeFinishReturnMedicalAtt"
                                    [matDatepicker]="datetimeFinishReturnMedicalAttPicker">
                                <mat-datepicker-toggle matSuffix
                                    [for]="datetimeFinishReturnMedicalAttPicker"></mat-datepicker-toggle>
                                <mat-datepicker #datetimeFinishReturnMedicalAttPicker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Hora final de reavaliação médica</mat-label>
                                <input matInput type="text" formControlName="hourFinishReturnMedicalAtt"
                                    [mask]="masks.time">
                            </mat-form-field>
                        </div>


                        <div class="title-header-form">
                            <h1>Dados da Observação</h1>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-2">
                                <mat-form-field appearance="outline">
                                    <mat-label>Dias em Observação</mat-label>
                                    <input matInput type="number" formControlName="observationDays">
                                </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-6 col-md-2">
                                <mat-form-field appearance="outline">
                                    <mat-label>Horas em Observação</mat-label>
                                    <input matInput type="text" formControlName="observationHours" [mask]="masks.time">
                                </mat-form-field>
                            </div>
                            <div class="col-6 col-sm-3 col-md-3">
                                <mat-form-field appearance="outline">
                                    <mat-label>Leito</mat-label>
                                    <mat-select formControlName="idBed">
                                        <mat-option *ngFor="let item of listBed" value="{{item.idBed}}">
                                            {{item.bedName}} {{item.bedCategoryName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-4">
                                <mat-form-field appearance="outline">
                                    <mat-label>Data inicial de estadia no leito</mat-label>
                                    <input matInput formControlName="datetimeStartBed"
                                        [matDatepicker]="datetimeStartBedPicker">
                                    <mat-datepicker-toggle matSuffix
                                        [for]="datetimeStartBedPicker"></mat-datepicker-toggle>
                                    <mat-datepicker #datetimeStartBedPicker></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-6 col-md-2">
                                <mat-form-field appearance="outline">
                                    <mat-label>Hora inicial de estadia no leito</mat-label>
                                    <input matInput type="text" formControlName="hourStartBed" [mask]="masks.time">
                                </mat-form-field>
                            </div>
                            <div class="col-12 col-md-4">
                                <mat-form-field appearance="outline">
                                    <mat-label>Data final de estadia no leito</mat-label>
                                    <input matInput formControlName="datetimeFinishBed"
                                        [matDatepicker]="datetimeFinishBedPicker">
                                    <mat-datepicker-toggle matSuffix
                                        [for]="datetimeFinishBedPicker"></mat-datepicker-toggle>
                                    <mat-datepicker #datetimeFinishBedPicker></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-6 col-md-2">
                                <mat-form-field appearance="outline">
                                    <mat-label>Hora final de estadia no leito</mat-label>
                                    <input matInput type="text" formControlName="hourFinishBed" [mask]="masks.time">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>


                    <div class="title-header-form">
                        <h1>Dados da evasão (se houver)</h1>
                    </div>
                    <div class="row">
                        <div class="col-6 col-sm-3 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Local de evasão</mat-label>
                                <mat-select formControlName="idModuleEvasion">
                                    <mat-option value="0"> Nenhum </mat-option>
                                    <mat-option value="7"> Recepção </mat-option>
                                    <mat-option value="9"> Classificação de risco </mat-option>
                                    <mat-option value="4"> Médico </mat-option>
                                    <mat-option value="12"> Observação </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="col-12 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Data de evasão</mat-label>
                                <input matInput formControlName="datetimeEvasion"
                                    [matDatepicker]="datetimeEvasionPicker">
                                <mat-datepicker-toggle matSuffix [for]="datetimeEvasionPicker"></mat-datepicker-toggle>
                                <mat-datepicker #datetimeEvasionPicker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Hora da evasão</mat-label>
                                <input matInput type="text" formControlName="hourEvasion" [mask]="masks.time">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row title-header-form">
                        <div class="col-md-2">
                            <button mat-flat-button type="submit" color="primary" class=" btn-block"
                                *ngIf="this.isEditable">
                                <span *ngIf="isLoading == false">Salvar</span>
                                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true">
                                </mat-spinner>
                            </button>
                        </div>
                        <div class="col-md-2">
                            <a mat-flat-button type="button" routerLink="/form/form-episode-list" color="accent"
                                class=" btn-block">
                                Cancelar
                            </a>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>