import { PatientNecessity } from "../../responses/medical-record/lookup.response";
import { MetaDataKeyValueStruct } from "../meta-data/meta-data-key-value.struct";
import { PatientAnamnesisStruct } from "../sync-measurer/patient-anamnesis-struct";
import { AllergyStruct } from "./allergy.struct";
import { PatientHealthcareAgreementDetailsStruct } from "./patient-healthcare-agreement-details.struct";

export class PatientStruct{
    public idPatient:number;
    public idUserPatient:number;
    public idPatientSyncMeasurer:number;
    public idEpisode:number;
    public listAllergy: AllergyStruct[];
    public idHealthUnitCreator:number;
    public patientName:string;
    public cpf:string;
    public patientAge:string;
    public birthDate:Date;
    public datetimeInclusion:Date;
    public cns:string;
    public rg:string;
    public expeditionIssuer:string;
    public expeditionDate:Date;
    public email: string;
    public socialName:string;
    public motherName:string;
    public fatherName:string;
    public idRace:number;
    public idEducation:number;
    public profession:string;
    public idMaritalStatus:number;
    public idGender:number;
    public zipCode:string;
    public street:string;
    public neighborhood:string;
    public city:string;
    public state:string;
    public country:string;
    public phone1:string;
    public phone2:string;
    public phoneConfirmation:string;
    public houseNumber:string;
    public apartmentNumber:string;
    public healthInsurance:string;
    public healthInsuranceRegistration:string;
    public healthInsuranceExpirationDate:Date;
    public healthInsuranceSecurityCode:string;
    public healthInsuranceHolderName:string;
    public healthInsuranceGracePeriod:Date;
    public healthInsuranceProcedure:string;
    public healthInsuranceAuthorization:string;
    public birthCountry:string;
    public birthState:string;
    public birthCity:string;
    public metaData:MetaDataKeyValueStruct[];
    public priorities:number[];
    public isUnidentifiedPatient:boolean;
    public unidentifiedPatientDescription:string;
    
    public observation: string;
    public idArrivalType:number;
    public arrivalTypeName:string;
    public idOrigin:number;
    public originName:string;
    public companionName:string;
    public companionPhone:string;
    public companionCpf:string;
    public companionParentage: string;
    public patientNecessities: PatientNecessity[];
    public listPatientNecessities: PatientNecessity[] = [];
    public genderName:string;
    public raceName:string;
    public educationName:string;
    public maritalStatusName:string;


    public listIdComorbidity: number[];

    public otherComorbidity: string;

    public listIdMedicine: number[];

    public otherMedicine: string;

    public listIdCareLine: number[];

    public listQuestionAnswer: PatientAnamnesisStruct;
    //Dados de funcionário
    public idPatientEmployee: number;
    public unitBranch: string;
    public sectorEmployee: string;
    public roleEmployee: string;
    public registryEmployee: string;

    //Dados dos convenios e planos do paciente
    public listPatientHealthcareAgreementDetails: PatientHealthcareAgreementDetailsStruct[] = [];
}