import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-urgency-alert-modal',
  templateUrl: './urgency-alert-modal.component.html',
  styleUrls: ['./urgency-alert-modal.component.css']
})
export class UrgencyAlertModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<UrgencyAlertModalComponent>
  ) { }

  public isLoading: boolean;

  ngOnInit(): void {
  }
  close() {
    this.matDialogRef.close();
  }
}
