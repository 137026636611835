import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonService } from './common-service';
import { catchError, retry } from 'rxjs/operators';
import { ReturnStruct } from '../structs/return.struct';
import { UserLoginResponse } from './responses/user/auth.response';
import { ModuleStruct } from './structs/user/module.struct';
import { WebsocketRoomUtilService } from './websocket-util/websocket-room-util.service';
import { GetTotemResponse } from './responses/totem/get-totem.response';
import { TotemLoginRequest } from './requests/totem/totem-login.request';
import { GetPanelResponse } from './responses/panel/get-panel.response';
import { PanelLoginRequest } from './requests/panel/panel-login.request';
import { WebsocketAttendanceUtilService } from './websocket-util/websocket-attendance-util.service';
import { TotemNpsLoginRequest } from './requests/nps/totem-nps-login.request';
import { GetTotemNpsResponse } from './responses/nps/get-totem-nps.response';


@Injectable({
  providedIn: 'root'
})
export class AuthService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient,
    private websocketRoomUtilService: WebsocketRoomUtilService,
    private websocketAttendanceUtilService: WebsocketAttendanceUtilService,) {
    super();
  }

  public signIn(userAuthRequest): Observable<UserLoginResponse> {

    return this.httpClient.post<UserLoginResponse>(environment.urlApiAuth + "GenerateToken", userAuthRequest)
      .pipe(
        catchError(this.handleError)
      )
  }

  public automaticSignIn(guid: string, ip: string): Observable<UserLoginResponse> {

    let uri = `GenerateToken/getByGuid?`;
    if (guid) uri += `guid=${guid}&`;
    if (ip) uri += `ip=${ip}`;

    return this.httpClient.get<UserLoginResponse>(environment.urlApiAuth + uri)
      .pipe(
        catchError(this.handleError)
      )
  }

  public signInSuperUser(token: string, ip: string): Observable<UserLoginResponse> {

    let userAuthRequest = {
      token: token,
      ip: ip
    };

    return this.httpClient.post<UserLoginResponse>(environment.urlApiAuth + "GenerateTokenSuperAccess", userAuthRequest)
      .pipe(
        catchError(this.handleError)
      )
  }

  public singinTokenPowerBILogin(tokenEmail: string): Observable<UserLoginResponse> {

    let superUserEmailLoginRequest = {
      tokenEmail: tokenEmail
    };

    return this.httpClient.post<UserLoginResponse>(environment.urlApiAuth + "GenerateTokenSuperAccessEmail", superUserEmailLoginRequest)
      .pipe(
        catchError(this.handleError)
      )
  }

  public signInOAuth(token: string, ip: string, idLoginType: number, idHealthUnit?: number): Observable<UserLoginResponse> {

    let userAuthRequest = {
      token: token,
      ip: ip,
      idHealthUnit: idHealthUnit,
      idLoginType: idLoginType,
    };

    return this.httpClient.post<UserLoginResponse>(environment.urlApiAuth + "GenerateToken/email", userAuthRequest)
      .pipe(
        catchError(this.handleError)
      )
  }

  updateToken(userLoginResponse: UserLoginResponse) {
    sessionStorage.setItem("token", JSON.stringify(userLoginResponse));
  }

  updateDefaultModule(newDefaultModule: ModuleStruct) {
    let data = JSON.parse(sessionStorage.getItem("token"));
    if (newDefaultModule != undefined && newDefaultModule != null) {
      data.idDefaultModule = newDefaultModule.idModule;
      data.uriDefaultModule = newDefaultModule.uri;
      data.defaultModuleName = newDefaultModule.nameModule;
    }
    else {
      data.idDefaultModule = null;
      data.uriDefaultModule = null;
      data.defaultModuleName = null;
    }
    sessionStorage.setItem("token", JSON.stringify(data));
  }

  logout() {
    this.websocketRoomUtilService.clearSocket();
    this.websocketAttendanceUtilService.clearSocket();
    sessionStorage.removeItem("token");
    this.router.navigate(['/'])
  }

  getToken() {
    let userLoginResponse: UserLoginResponse = JSON.parse(sessionStorage.getItem("token"))
    return userLoginResponse.token;
  }


  getTokenMenu(): UserLoginResponse {
    let userLoginResponse: UserLoginResponse = JSON.parse(sessionStorage.getItem("token"));
    return userLoginResponse;
  }

  isAuthenticated(): boolean {
    return sessionStorage.getItem("token") == null ? false : true;
  }

  haveRolePermission(idRole: number): boolean {
    let hasRole = false;
    let userLoginResponse: UserLoginResponse = JSON.parse(sessionStorage.getItem("token"));

    userLoginResponse.listRole.forEach(element => {
      if (element.idRole == idRole) {
        hasRole = true;
      }
    });

    return hasRole;
  }

  public signInTotemHygienizationBed(login: string, password: string, ip: string, idHealthUnit: number, idTotem: number): Observable<UserLoginResponse> {

    let userAuthRequest = {
      login: login,
      password: password,
      ip: ip,
      idHealthUnit: idHealthUnit,
      idTotem: idTotem,
    };

    return this.httpClient.post<UserLoginResponse>(environment.urlApiOrchestratorUser + "LoginUserTotemHygienization", userAuthRequest)
      .pipe(
        catchError(this.handleError)
      )
  }

  public signInTotem(totemLoginRequest: TotemLoginRequest): Observable<GetTotemResponse> {

    return this.httpClient.post<GetTotemResponse>(environment.urlApiAuth + "GenerateEquipmentToken/Totem", totemLoginRequest)
      .pipe(
        catchError(this.handleError)
      )
  }

  public signInTotemNps(request: TotemNpsLoginRequest): Observable<GetTotemNpsResponse> {

    return this.httpClient.post<GetTotemNpsResponse>(environment.urlApiAuth + "GenerateEquipmentToken/TotemNps", request)
      .pipe(
        catchError(this.handleError)
      )
  }

  updateTotemToken(getTotemResponse: GetTotemResponse) {
    sessionStorage.setItem("totemToken", JSON.stringify(getTotemResponse));
  }

  updateTotemNpsToken(response: GetTotemNpsResponse) {
    sessionStorage.setItem("totemTokenNps", JSON.stringify(response));
  }

  getTotemToken(): GetTotemResponse {
    let getTotemResponse: GetTotemResponse = JSON.parse(sessionStorage.getItem("totemToken"));
    return getTotemResponse;
  }

  getTotemNpsToken(): GetTotemNpsResponse {
    let response: GetTotemNpsResponse = JSON.parse(sessionStorage.getItem("totemTokenNps"));
    return response;
  }

  logoutTotem() {
    sessionStorage.removeItem("totemToken");
  }

  logoutTotemNps() {
    sessionStorage.removeItem("totemTokenNps");
  }

  public signInPanel(panelLoginRequest: PanelLoginRequest): Observable<GetPanelResponse> {

    return this.httpClient.post<GetPanelResponse>(environment.urlApiAuth + "GenerateEquipmentToken/Panel", panelLoginRequest)
      .pipe(
        catchError(this.handleError)
      )
  }

  updatePanelToken(getPanelResponse: GetPanelResponse) {
    let token: GetPanelResponse = {
      isError: getPanelResponse.isError,
      errorCode: getPanelResponse.errorCode,
      errorDescription: getPanelResponse.errorDescription,
      idPanel: getPanelResponse.idPanel,
      panelName: getPanelResponse.panelName,
      description: getPanelResponse.description,
      isActive: getPanelResponse.isActive,
      idLayout: getPanelResponse.idLayout,
      pin: getPanelResponse.pin,
      isLogoUploaded: getPanelResponse.isLogoUploaded,
      callByPassword: getPanelResponse.callByPassword,
      imgString64: getPanelResponse.imgString64,
      idHealthUnit: getPanelResponse.idHealthUnit,
      listPanelOrigin: getPanelResponse.listPanelOrigin,
      panelVideoName: getPanelResponse.panelVideoName,
      incorrectPinCount: getPanelResponse.incorrectPinCount,
      token: getPanelResponse.token,
      video64: "",
    };

    sessionStorage.setItem("panelToken", JSON.stringify(token));
  }

  getPanelToken(): GetPanelResponse {
    let getPanelResponse: GetPanelResponse = JSON.parse(sessionStorage.getItem("panelToken"));
    return getPanelResponse;
  }

  logoutPanel() {
    sessionStorage.removeItem("panelToken");
  }
}
