<div class="row display-contents">
    <div class="col-12 padding-box">
        <div class="row border-box">
            <div class="title-header col-12 divid-column">
                <div class="col-5">
                    <h3>Profissionais que atendem esta operadora</h3>
                </div>
                <div class="button-add col-3" *ngIf="!showAdditionalFields">
                    <button color="accent" color="primary" mat-flat-button type="button"
                        (click)="showAdditionalFields = true; addNewRule()">
                        Adicionar +
                    </button>
                </div>
            </div>
            <div class="filters">
                <div class="row">
                    <div class="col-12 col-md-5 col-xl-5 col-lg-5 col-sm-5 search-correction">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Pesquisar</mat-label>
                            <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText"
                                (keyup)="onKeySearch($event)">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-3 col-xl-3 col-lg-3 col-sm-3">
                        <button class="custom-button" [class.active]="isButtonActive('ativos')"
                            (click)="controlButtonStatus('ativos')">Ativos</button>
                    </div>
                    <div class="col-12 col-md-3 col-xl-3 col-lg-3 col-sm-3">
                        <button class="custom-button" [class.active]="isButtonActive('inativos')"
                            (click)="controlButtonStatus('inativos')">Inativos</button>
                    </div>
                </div>
            </div>
            <div class="filters" *ngIf="isLoading == false">
                <app-loading-progress-bar *ngIf="isLoading"></app-loading-progress-bar>
                <div class="col-12 accordeon-padding">
                    <cdk-accordion class="profile-register-accordion" style="overflow: auto;">
                        <cdk-accordion-item *ngFor="let item of listProfessionalAgreementStruct; let index = index;"
                            #accordionItemModule="cdkAccordionItem" class="profile-register-accordion-item"
                            role="button" tabindex="0" [attr.id]="'accordion-header-' + index"
                            [attr.aria-expanded]="accordionItemModule.expanded"
                            [attr.aria-controls]="'accordion-body-' + index">
                            <div [ngClass]="{'profile-register-accordion-item-header-expanded': accordionItemModule.expanded}"
                                class="profile-register-accordion-item-header" (click)="accordionItemModule.toggle()">
                                {{ item.nameProfessional }}{{item.isEdited ? ' - Alterações Pendentes': !item.idProfessionalAgreement ? " - Salvamento pendente":""}}
                                <span
                                    [ngClass]="{'profile-register-accordion-item-description-expanded': accordionItemModule.expanded}"
                                    class="profile-register-accordion-item-description">
                                    Clique para {{ accordionItemModule.expanded ? 'fechar' : 'abrir' }}
                                </span>
                            </div>
                            <div class="profile-register-accordion-item-body" role="region"
                                [style.display]="accordionItemModule.expanded ? '' : 'none'"
                                [attr.id]="'accordion-body-' + index"
                                [attr.aria-labelledby]="'accordion-header-' + index">
                                <app-view-data [professionalAgreementStruct]="item"
                                    [listProfessionalCouncil]="listProfessionalCouncil"
                                    [listProviderIdentityType]="listProviderIdentityType"
                                    [listParticipationDegree]="listParticipationDegree" [listCbo]="listCbo"
                                    [listState]="listState" [idHealthcareAgreement]="idHealthcareAgreement"
                                    [isUpdate]="isUpdate" [index]="index"
                                    (professionalAgreementDataChanged)="onProfessionalAgreementDataChanged($event, index)"
                                    (cancelNewProfessional)="cancelNew()">
                                </app-view-data>
                            </div>
                        </cdk-accordion-item>
                        <div *ngIf="listNewProfessionalAgreementStruct.length > 0">
                            <cdk-accordion-item
                                *ngFor="let item of listNewProfessionalAgreementStruct; let index = index;"
                                #accordionItemModule="cdkAccordionItem" class="profile-register-accordion-item"
                                role="button" tabindex="0" [attr.id]="'accordion-header-' + index"
                                [attr.aria-expanded]="accordionItemModule.expanded"
                                [attr.aria-controls]="'accordion-body-' + index"
                                [expanded]="true">
                                <div [ngClass]="{'profile-register-accordion-item-header-expanded': accordionItemModule.expanded}"
                                    class="profile-register-accordion-item-header"
                                    (click)="accordionItemModule.toggle()">
                                    {{ item.nameProfessional }}
                                    <span
                                        [ngClass]="{'profile-register-accordion-item-description-expanded': accordionItemModule.expanded}"
                                        class="profile-register-accordion-item-description">
                                        Clique para {{ accordionItemModule.expanded ? 'fechar' : 'abrir' }}
                                    </span>
                                </div>
                                <div class="profile-register-accordion-item-body" role="region"
                                    [style.display]="accordionItemModule.expanded ? '' : 'none'"
                                    [attr.id]="'accordion-body-' + index"
                                    [attr.aria-labelledby]="'accordion-header-' + index">
                                    <app-view-data [professionalAgreementStruct]="item"
                                        [listProfessionalCouncil]="listProfessionalCouncil"
                                        [listProviderIdentityType]="listProviderIdentityType"
                                        [listParticipationDegree]="listParticipationDegree" [listCbo]="listCbo"
                                        [listState]="listState" [idHealthcareAgreement]="idHealthcareAgreement"
                                        (onDataChanged)="onCreateDataChanged($event)"
                                        [showAdditionalFields]="showAdditionalFields"
                                        (cancelNewProfessional)="cancelNew()">
                                    </app-view-data>
                                </div>
                            </cdk-accordion-item>
                        </div>
                    </cdk-accordion>
                </div>
            </div>

            <app-empty-list
                *ngIf="listProfessionalAgreementStruct != undefined && listProfessionalAgreementStruct.length == 0 && !this.isLoading">
            </app-empty-list>
        </div>
        <div class="row col-12 padding-button">
            <div class="col-12 col-lg-6 col-xl-3 col-md-6 col-sm-6">
                <a mat-flat-button type="button" color="accent" class=" btn-block" (click)="cancel()">
                    Cancelar
                </a>
            </div>
            <div class="col-12 col-lg-6 col-xl-3 col-md-6 col-sm-6">
                <button mat-flat-button color="primary" class="btn-block" type="submit" (click)="submit()">
                    <span *ngIf="isLoading == false">Salvar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
            </div>
        </div>
    </div>
</div>