import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ProfileService } from 'src/app/shared/services/API/user/profile.service';
import { ListProfileStruct } from 'src/app/shared/services/structs/user/list-profile.struct';
import { ProfileDeleteModalComponent } from './profile-delete-modal/profile-delete-modal.component';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-profile-list',
  templateUrl: './profile-list.component.html',
  styleUrls: ['./profile-list.component.css']
})
export class ProfileListComponent implements OnInit {

  constructor(
    private profileService: ProfileService,
    private alertService: AlertService,
    public dialog: MatDialog,
    private utilService: UtilService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.master;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.master_profile;

  public listProfile: ListProfileStruct[];
  public isLoading: boolean = true;
  public searchText: string;
  ip: string = '192.168.0.2';

  ngOnInit(): void {
    this.populateScreen();

    setTimeout(() => { this.getIP(); }, 2000);
  }

  populateScreen() {
    //Listando perfis
    this.search();
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }

  search() {
    this.isLoading = true;

    if (this.searchText == "")
      this.searchText = null;

    this.profileService.listProfile(this.searchText).subscribe({
      next: (response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listProfile = response.listProfile;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openModal(idProfile) {
    const dialogRef = this.dialog.open(ProfileDeleteModalComponent, {
      data: {
        idProfile: idProfile,
        ip: this.ip
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.deleteUser) {
        this.search();
      }
    });
  }

  getFormatedListUserName(listName: string[]) {
    if (listName.length > 0)
      return listName.map(name => name).join(', ');
    else
      return "Não há usuários atrelados";
  }

  getIP() {
    this.utilService.getIP().subscribe({
      next: (response) => {
        if (response && response.ip) {
          this.ip = response.ip;
        }
      }
    });
  }
}  