import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { PutClassificationRiskAuditTriageRequest } from '../../requests/audit/put-classification-risk-audit-triage.request';
import { UpdateAuditReponse } from '../../responses/audit/update-audit.response';

@Injectable({
  providedIn: 'root'
})
export class AuditStatusService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  public updateAuditStatus(request: PutClassificationRiskAuditTriageRequest): Observable<UpdateAuditReponse> {
    let uri = "AuditStatus";

    return this.httpClient.put<UpdateAuditReponse>(environment.urlApiOrchestratorAudit + uri, request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}