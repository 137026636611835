import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MedicineGroupService } from 'src/app/shared/services/API/pharmacy/medicine-group.service';
import { MedicineGroupTypeStruct } from 'src/app/shared/services/structs/pharmacy/medicine-group-type.struct';
import { UtilService } from 'src/app/shared/services/util.service';
import { MedicineGroupDeleteModalComponent } from './medicine-group-delete-modal/medicine-group-delete-modal.component';

@Component({
  selector: 'app-medicine-group-list',
  templateUrl: './medicine-group-list.component.html',
  styleUrls: ['./medicine-group-list.component.css']
})
export class MedicineGroupListComponent implements OnInit {

  constructor(private medicineGroupService: MedicineGroupService,
    private router: Router,
    private alertService: AlertService,
    private utilService: UtilService,
    public dialog: MatDialog) { }
    
    public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.pharmacy;
    public menuFunctionalityEnum:MenuFunctionalityEnum = MenuFunctionalityEnum.pharmacy_medicine_group;
    
    public listMedicineGroup: MedicineGroupTypeStruct[];
    public isLoading: boolean;
    public searchText: string;
    public idMedicineType: number;

  ngOnInit(): void {
    this.isLoading = true;
    this.search();
  }
  search(){
    this.isLoading = true;  
    if(this.searchText == "")
      this.searchText = null;
    
    this.medicineGroupService.listMedicineGroup(this.searchText).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.listMedicineGroup = response.listMedicineGroupTypeStruct; 

      this.isLoading = false;    
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  private timeoutKeySearch: any = null;
  
  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }
  
  openModal(idMedicineGroup){
    const dialogRef = this.dialog.open(MedicineGroupDeleteModalComponent, {
      data: {
        idMedicineGroup: idMedicineGroup
      },
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.deleteMedicineGroup){
        this.search();
      }
    });
  }
}