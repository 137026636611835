import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { ReportModalComponent } from 'src/app/shared/components/report-modal/report-modal.component';
import { HospitalDocumentService } from 'src/app/shared/services/API/hospital-document/hospital-document.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { GrievanceNotificationStruct } from 'src/app/shared/services/structs/hospital-document/grievance-notification.struct';

@Component({
  selector: 'app-grievance-notification-generate',
  templateUrl: './grievance-notification-generate.component.html',
  styleUrls: ['./grievance-notification-generate.component.css']
})
export class GrievanceNotificationGenerateComponent implements OnInit{
  constructor(
    private hospitalDocumentService: HospitalDocumentService,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    public dialog: MatDialog,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.patient_history;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.grievance_notification;
  public model: FormGroup;
  public isLoading: boolean;
  public listGrievanceNotification: GrievanceNotificationStruct[] = [];
  public idGrievanceNotification : number;
  public report64: string;

  ngOnInit(): void {
    this.isLoading = true;

    this.model = this.formBuilder.group({
      idGrievanceNotification: [null, [Validators.required]]
    });

    this.getListGrievanceNotification()
  }

  submit() {
    if (this.isLoading)
      return;

    if (!this.model.valid) {
      this.alertService.show('Erro', "Preencha todos em vermelho", AlertType.error);
      this.isLoading = false;
      return;
    }

    this.isLoading = true;

    this.idGrievanceNotification = this.model.get('idGrievanceNotification').value;

    this.getGrievanceReport(this.idGrievanceNotification);
  }
  
  getListGrievanceNotification()
  {
    this.hospitalDocumentService.getGrievanceNotification().subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listGrievanceNotification = response.listGrievanceNotification; 
      this.isLoading = false;    
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  getGrievanceReport(idGrievanceNotification: number)
  {
    this.hospitalDocumentService.getGrievanceReport(idGrievanceNotification, false).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.isLoading = false; 
      this.report64 = response.grievanceReportString64;

      if(this.report64 != null)
    {
      let reportDialog = this.dialog.open(ReportModalComponent, {
        data: {
          reportName: response.reportName,
          reportPdf64: this.report64,
        },
      });
    }
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }
}
