import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdmissionProcedureFieldsMaxLengthEnum } from 'src/app/shared/enum/private-billing/admission-procedure-fields-max-length.enum';
import { ProcedureService } from 'src/app/shared/services/API/private-billing/procedure.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { TerminologyGroup } from 'src/app/shared/services/models/private-billing/terminology-group.model';
import { GetProceduresRequest } from 'src/app/shared/services/requests/private-billing/get-procedures.request';
import { AdmissionProcedureStruct } from 'src/app/shared/services/structs/private-billing/admission-procedure.struct';
import { ProcedureStruct } from 'src/app/shared/services/structs/private-billing/procedure.struct';
@Component({
  selector: 'app-admission-procedure-history',
  templateUrl: './admission-procedure-history.component.html',
  styleUrls: ['./admission-procedure-history.component.css']
})
export class AdmissionProcedureHistoryComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    public procedureService: ProcedureService,
    public alertService: AlertService,
  ) { }

  @Input() listAdmissionProcedure: AdmissionProcedureStruct[] = [];
  @Input() tussTerminologyGroupMap: Map<number, TerminologyGroup>;
  @Input() editingIndex: number;
  @Input() isEditingAllowed: boolean = null;
  @Input() idAdmissionGuide: number = null;
  @Input() listProcedure: ProcedureStruct[] = [];
  @Input() idHealthcareAgreement: number = null;
  @Output() onDataChanged = new EventEmitter<any>();

  public isLoading: boolean = false;
  public showAdditionalFields: boolean = false;
  public isEditing: boolean = false;
  public alreadyPopulate: boolean = false;

  public model: FormGroup;
  public admissionMaxLength: typeof AdmissionProcedureFieldsMaxLengthEnum = AdmissionProcedureFieldsMaxLengthEnum;
  public newProcedure: AdmissionProcedureStruct = new AdmissionProcedureStruct();
  public editAdmissionProcedure: AdmissionProcedureStruct = new AdmissionProcedureStruct();

  private timeoutKeySearch: any = null;
  private idAdmissionProcedure: number = null;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      idTerminologyGroup: [null, [Validators.required]],
      procedure: [{value: '', disabled: true}, [Validators.required, Validators.maxLength(AdmissionProcedureFieldsMaxLengthEnum.procedureCount)]],
      procedureCount: ['0', [Validators.required, Validators.maxLength(AdmissionProcedureFieldsMaxLengthEnum.procedureCount)]],
      procedureCountAuthorized: ['0', [Validators.required, Validators.maxLength(AdmissionProcedureFieldsMaxLengthEnum.procedureCountAuthorized)]],
    });
  }
  async getProcedures(key: string, idTerminologyGroup: number, idProcedure: number = null) {
    this.isLoading = true;
    let request: GetProceduresRequest = {
      key: key,
      idHealthcareAgreement: this.idHealthcareAgreement,
      idTerminologyGroup: idTerminologyGroup,
      isCreate: this.idAdmissionGuide == null ? true : false,
      listIdProcedure: Number.isSafeInteger(idProcedure) ?  [idProcedure] : []
    }

    this.procedureService.GetByTerminologyGroup(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listProcedure = response.listProcedureStruct;
        if (this.isEditing && !this.alreadyPopulate) {
          this.populateForm();
        }

        this.model.get('procedure').enable();
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  populateForm() {
    this.model.get('procedure').setValue(this.listProcedure.find(x => x.idProcedure == this.editAdmissionProcedure.procedure.idProcedure));
    this.model.get('procedureCount').setValue(this.editAdmissionProcedure.procedureCount);
    this.model.get('procedureCountAuthorized').setValue(this.editAdmissionProcedure.procedureCountAuthorized);
    this.model.get('idTerminologyGroup').setValue(this.editAdmissionProcedure.procedure.terminology.idTerminologyGroup);
    this.alreadyPopulate = true;
  }

  onKey(event: any) {
    let idTerminologyGroup = this.model.get('idTerminologyGroup').value;
    let idProcedure: number = this.model.get('procedure').value ? this.model.get('procedure').value.idProcedure : null;
    clearTimeout(this.timeoutKeySearch);

    if (event) {
      var $this = this;
      this.timeoutKeySearch = setTimeout(function () {
        if (event.keyCode != 13)
          $this.getProcedures(event, idTerminologyGroup, idProcedure);
      },
        1000);
    }
  }
}