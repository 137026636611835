import { Component, Inject, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { VerifyStorageService } from 'src/app/shared/services/API/pharmacy/verify-storage.service';
import { StorageStruct } from 'src/app/shared/services/structs/pharmacy/storage.struct';

@Component({
  selector: 'app-medicine-bar-code-modal',
  templateUrl: './medicine-bar-code-modal.component.html',
  styleUrls: ['./medicine-bar-code-modal.component.css']
})
export class MedicineBarCodeModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<MedicineBarCodeModalComponent>,
    public verifyStorageService: VerifyStorageService,
    private alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
  ) { }

  public isLoading: boolean;
  public barcodeValue: string;
  public idStorage: number;
  public selectedStorage: number;
  public storage: StorageStruct;
  public idLocation: number;
  public listStorage: StorageStruct[];
  public isValid: boolean;
  public disabledInupt: boolean = false;
  public disabledSelect: boolean = false;
  public model: UntypedFormGroup;

  ngOnInit(): void {
    this.isLoading = false;
    this.isValid = false;
    this.listStorage = this.data.listStorage;

    this.model = this.formBuilder.group({
      idStorage: [, [Validators.required]],
      selectedStorage: [, [Validators.required]],
    });
  }

  close() {
    this.matDialogRef.close({ barcodeValue: null });
  }

  clickConfirm() {
    if (!this.storage.idStorage)
      return;

    this.matDialogRef.close({ barcodeValue: this.storage.idStorage, medicine: this.storage.medicine, idLocation: this.idLocation });
  }

  clickCancel() {
    this.matDialogRef.close({ barcodeValue: null });
  }

  checkMedicine(idStorage: number) {
    if (this.isLoading)
      return;

    idStorage = parseInt(idStorage.toString());
    this.isLoading = true;
    this.verifyStorageService.verifyMedicineStorage(idStorage, this.data.idMedicine).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.isLoading = false;
      this.isValid = true;
      if (response.storage) {
        this.storage = response.storage;
        this.idLocation = this.storage.storageAmountLocation.idLocation;
      }
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }
  
  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    if (this.idStorage) {
      this.disabledSelect = true;
      this.selectedStorage = null
    }
    else {
      this.disabledSelect = false;
      this.isValid = false;
    }
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if ($this.idStorage)
        $this.checkMedicine($this.idStorage);
    }, 1000);
  }

  selectionChange(event) {
    if (event.value) {
      this.disabledInupt = true;
      this.idStorage = null;
      if (this.selectedStorage) {
        this.checkMedicine(this.selectedStorage);
      }
    }
    else {
      this.disabledInupt = false;
      this.selectedStorage = null;
      this.isValid = false;
    }
  }
}
