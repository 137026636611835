import { AllergyModel } from "../../models/medical-record/allergy.model";
import { PriorityEpisodeModel } from "../../models/medical-record/priority-episode.model";
import { TriageStruct } from "../orchestrator-patient/triage.struct";
import { AllergyStruct } from "./allergy.struct";
import { PatientHealthcareAgreementDetailsStruct } from "./patient-healthcare-agreement-details.struct";
import { ScheduledPatientHealthcareAgreementStruct } from "./scheduled-patient-healthcare-agreement.struct";

export class EpisodeStruct
{
    public idEpisode: number;
    public idHealthUnit: number;
    public idPatient: number;
    public ticketInitials: string;
    public ticketSequence: number;
    public fullTicket: string;
    public datetimeFinish: Date;
    public idHealthUnitCreator: number;
    public patientName: string;
    public cpf: string;
    public birthDate: Date;
    public ticketDatetimeInclusion: Date;
    public listPriorityEpisode: PriorityEpisodeModel[] = [];
    public unidentifiedPatientDescription: string;
    public isUnidentifiedPatient: boolean;
    public listTriage: TriageStruct[] = [];
    public internalPrescriptionCount: number;
    public episodeLocation: string;
    public patientSocialName: string;
    public idAgeGroup: number;
    public ageGroupName: string;
    public allergyGroup: string;
    public listAllergy: AllergyStruct[] = [];
    public patientAge: string;
    public idCareLinePriority: number;

    public careLinePriorityName: string;

    public idCareLinePriorityStatus: number;
    public datetimeStartRegister: Date;
    public datetimeEndRegister: Date;
    public waitingTime: string;
    public serviceTime: string;
    public companionName: string;

    public patientHealthcareAgreementInEpisode: PatientHealthcareAgreementDetailsStruct = new PatientHealthcareAgreementDetailsStruct();
    public scheduledPatientHealthcareAgreement: ScheduledPatientHealthcareAgreementStruct = new ScheduledPatientHealthcareAgreementStruct();
}