<div class="material-size" mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-exam-modal-list">
            <h2>Deseja realmente não dispensar o pedido de material selecionado?</h2>
        </div>
        <div class="body-exam-modal-list">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="row">
                    <mat-form-field appearance="outline">
                        <mat-label>Paciente</mat-label>
                        <input matInput type="text" formControlName="patientName">
                    </mat-form-field>
                </div>
                <div class="row">
                    <div class="col-12"><b>Materiais e Kits:</b></div>
                    <div *ngFor="let item of this.data.material.listRequestMaterialsItemKit; let i=index;"
                        class="col-12 kits-itens-container">
                        <label><b>{{i+1}} - {{item.kitName? "Kit: " + item.kitName :"Item: " +
                                item.itemName }}</b></label>
                        <span> Quantidade: {{item.quantity}}</span>
                    </div>
                </div>
                <div class="row">
                    <mat-form-field appearance="outline">
                        <mat-label>Motivo da não dispensação</mat-label>
                        <textarea matInput type="text" formControlName="nonDispensationReason"></textarea>
                        </mat-form-field>
                </div>
            </form>
        </div>
        <div class="footer-exam-modal-list row">
            <div class="col-12 col-md-6">
                <button mat-flat-button color="primary" class="btn-block" (click)="submit()">
                    <span *ngIf="isLoading == false">Não dispensar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
            </div>
            <div class="col-12 col-md-6">
                <button mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Cancelar</button>
            </div>
        </div>
    </div>
</div>