import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ListAllAuditStatusResponse } from '../../responses/audit/list-all-audit-status.response';

@Injectable({
  providedIn: 'root'
})
export class AuditStatusService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listAllAuditStatus(): Observable<ListAllAuditStatusResponse> {
    
    let uri = 'AuditStatus/getAll'
    
    return this.httpClient.get<ListAllAuditStatusResponse>(environment.urlApiAudit + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
