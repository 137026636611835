import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { LookupResponse } from '../../responses/nps/lookup.response';

@Injectable({
    providedIn: 'root'
  })
  
  export class LookupNpsService extends CommonService{
  
      constructor(private router: Router, private httpClient: HttpClient) {
        super();
      }

      public listLookup(): Observable<LookupResponse> {
    
        let uri = `Lookup?`
        
        return this.httpClient.get<LookupResponse>(environment.urlApiNps + uri, this.addHeaderToken())
        .pipe(
          catchError(this.handleError)
        )
    }
}