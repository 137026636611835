import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { GetUserPatientResponse } from '../../responses/patient-center/get-user-patient.response';

@Injectable({
  providedIn: 'root'
})
export class ClientUserPatientService extends CommonService{

  constructor(private httpClient: HttpClient) {
    super();
  }

  public getUserPatient(idPatientUser: number): Observable<GetUserPatientResponse> {
    let uri = `ClientUserPatient/idPatientUser/${idPatientUser}`
    
    return this.httpClient.get<GetUserPatientResponse>(environment.urlApiPatientCenter + uri)
    .pipe(
      catchError(this.handleError)
    );
  }
}