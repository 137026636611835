import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsClientService } from 'src/app/client/utils.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ClientBiPanelService } from 'src/app/shared/services/API/business-intelligence/client-bi-panel.service';
import { OrchestratorBiService } from 'src/app/shared/services/API/business-intelligence/orchestrator-bi.service';
import { GetBiPanelResponse } from 'src/app/shared/services/responses/business-intelligence/get-bi-panel.response';
declare var powerbi: any;

@Component({
  selector: 'app-display-bi-panel',
  templateUrl: './display-bi-panel.component.html',
  styleUrls: ['./display-bi-panel.component.css']
})
export class DisplayBiPanelComponent implements OnInit, OnDestroy {

  constructor(
    private clientBiPanelService: ClientBiPanelService,
    private alertService: AlertService,
    private utilService: UtilsClientService,
    private orchestratorBiService: OrchestratorBiService,
  ) { }

  @Input() panelStruct: GetBiPanelResponse;
  @Input() idBiPanel: number;
  public isFirstLoading: boolean = true;
  public isFirst: boolean = true;
  public isReload: boolean = false;
  public index: number = 0;
  public reportName: string;
  public lastIdReport: number;
  public config = {
    type: 'report',
    tokenType: 1,
    accessToken: undefined,
    embedUrl: undefined,
    id: undefined,
    permissions: 0,
    viewMode: 0,
    settings: {
      filterPaneEnabled: false,
      navContentPaneEnabled: false
    }
  };
  public intervalId;
  public timeoutIntervalId;
  public onlineOffline: boolean = navigator.onLine;
  public visualizationTime: number;

  ngOnInit(): void {
    this.isFirst = true;
    if(this.utilService.getBiPanelIndex(this.idBiPanel)){
      
      this.index = parseInt(this.utilService.getBiPanelIndex(this.idBiPanel));
      this.isFirst = false;
    }
    else this.utilService.updateBiPanelIndex(this.idBiPanel, 0)
    this.getReport();
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    clearTimeout(this.timeoutIntervalId);
    clearInterval(this.timeoutIntervalId);
  }

  getReport() {
    try {
      clearTimeout(this.timeoutIntervalId);
      clearInterval(this.timeoutIntervalId);
      this.orchestratorBiService.showReportInternal(this.panelStruct.biPanel.listPanelReportVisualizationTime[this.index].idReportFunctionHealthUnit, this.panelStruct.biPanel.idBiPanel, this.isFirst).subscribe((response) => {
        this.isFirstLoading = false;
        if (response.isError) {
          this.alertService.show("Erro", response.errorDescription, AlertType.error);
          this.reloadFunction();
          return;
        }

        this.isFirst = false;
        this.config.embedUrl = response.report.reportLink;
        this.config.id = response.report.biReport;
        this.config.accessToken = response.report.token;
        this.reportName = response.report.reportName;
        let divToEmbbed = document.getElementById("reportDiv");

        if (this.lastIdReport != this.panelStruct.biPanel.listPanelReportVisualizationTime[this.index].idReportFunctionHealthUnit) {
          let report = powerbi.embed(divToEmbbed, this.config);
          report.on('loaded', function () {
            report.getPages().then(function (pages) {
                let page = pages[parseInt(response.report.page)-1];
                if(page) page.setActive();
            });
          });
        }

        this.panelStruct.biPanel.listPanelReportVisualizationTime = response.listReportPanel;
        this.visualizationTime = response.report.visualizationTime * 1000;
        //this.visualizationTime = 15 * 1000; //APENAS TESTE

        this.lastIdReport = this.panelStruct.biPanel.listPanelReportVisualizationTime[this.index].idReportFunctionHealthUnit;

        if (this.index + 1 >= this.panelStruct.biPanel.listPanelReportVisualizationTime.length) {
          this.index = 0;
          this.isReload = true;
        } else {
          this.index++;
        }
        this.utilService.updateBiPanelIndex(this.idBiPanel, this.index);
        this.intervalId = setTimeout(() => {
          //if (this.isReload) {
            //this.isFirst = true;
            window.location.reload();
          //}
          // else {
          //   this.getReport()
          // }
        }, this.visualizationTime);
      },
        (error) => {
          console.log(error)
          this.alertService.show('Erro inesperado', "Erro ao buscar dados, confira a sua conexão de internet.", AlertType.error);
          this.reloadFunction();
        });
    }
    catch (error) {
      console.log(error)
      this.alertService.show('Erro inesperado', "Erro ao buscar dados, confira a sua conexão de internet.", AlertType.error);
      this.reloadFunction();
    }
  }

  reloadFunction() {
    this.timeoutIntervalId = setTimeout(() => {
      if (this.index + 1 >= this.panelStruct.biPanel.listPanelReportVisualizationTime.length) {
        this.utilService.removeBiPanelIndex(this.idBiPanel);
        window.location.reload();
      }
      else {
        this.getReport()
      }
    }, 10000);
  }
}
