import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { MatDialog } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { AuditConfigService } from 'src/app/shared/services/API/audit/audit-config.service';
import { AuditConfig } from 'src/app/shared/services/models/audit/audit-config.model';
import { CorrectClassificationReason } from 'src/app/shared/services/models/audit/correct-classification-reason.model';
import { IncorrectClassificationReason } from 'src/app/shared/services/models/audit/incorrect-classification-reason.model';
import { AuditConfigRequest } from 'src/app/shared/services/requests/audit/audit-config.request';
import { CorrectClassificationReasonStruct } from 'src/app/shared/services/structs/audit/correct-classification-reason.struct';
import { IncorrectClassificationReasonStruct } from 'src/app/shared/services/structs/audit/incorrect-classification-reason.struct';
import { AuditDeleteModalComponent } from '../audit-list/audit-delete-modal/audit-delete-modal.component';
import { AuditRemovePriorReasonModalComponent } from './audit-remove-prior-reason-modal/audit-remove-prior-reason-modal.component';

@Component({
  selector: 'app-audit-config',
  templateUrl: './audit-config.component.html',
  styleUrls: ['./audit-config.component.css']
})
export class AuditConfigComponent implements OnInit {

  constructor(
    private formBuilder: UntypedFormBuilder,
    private auditConfigService: AuditConfigService,
    private router: Router,
    public dialog: MatDialog,
    private alertService: AlertService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.audit;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.audit_config;
  public relationUserRemovePriorReasonModal: boolean = false;
  public model: UntypedFormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean = false;
  public auditConfig: AuditConfig;
  public listIncorrectClassificationReasonDefault: IncorrectClassificationReason[] = [];
  public listCorrectClassificationReasonDefault: CorrectClassificationReason[] = [];
  public listIncorrectClassificationReasonNotDefaultDeleted: AbstractControl[] = [];
  public listCorrectClassificationReasonNotDefaultDeleted: AbstractControl[] = [];

  ngOnInit(): void {
    this.isLoading = false;
    this.isFirstLoading = true;
    this.model = this.formBuilder.group({
      allowAudited: [true],
      viewTimeTag: [true, [Validators.required]],
      displayCarePriorityInRiskClassification: [false, [Validators.required]],
      listIncorrectClassificationReasonNotDefault: this.formBuilder.array([]),
      listCorrectClassificationReasonNotDefault: this.formBuilder.array([])
    });
    this.getConfig();
  }

  submit() {
    if (this.isLoading) {
      return;
    }
    else if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      this.isLoading = false;
      return;
    }
    this.isLoading = true;
    let auditConfigRequest = new AuditConfigRequest();
    auditConfigRequest.allowAudited = this.model.get('allowAudited').value;
    auditConfigRequest.viewTimeTag = this.model.get('viewTimeTag').value;
    auditConfigRequest.displayCarePriorityInRiskClassification = this.model.get('displayCarePriorityInRiskClassification').value;
    (this.model.controls['listIncorrectClassificationReasonNotDefault'] as FormArray).controls.forEach(control => {
      let incorrectClassificationReasonStruct = new IncorrectClassificationReasonStruct();
      incorrectClassificationReasonStruct.incorrectClassificationReason.idIncorrectClassificationReason = control.value.idIncorrectClassificationReason;
      incorrectClassificationReasonStruct.incorrectClassificationReason.description = control.value.description;
      incorrectClassificationReasonStruct.incorrectClassificationReason.isDeleted = control.value.isDeleted;
      incorrectClassificationReasonStruct.incorrectClassificationReason.idHealthUnit = control.value.idHealthUnit;
      incorrectClassificationReasonStruct.incorrectClassificationReason.datetimeInclusion = control.value.datetimeInclusion;
      incorrectClassificationReasonStruct.isCreate = control.value.isCreate;

      auditConfigRequest.listIncorrectClassificationReasonNotDefault.push(incorrectClassificationReasonStruct);
    });

    this.listIncorrectClassificationReasonNotDefaultDeleted.forEach(control => {
      let incorrectClassificationReasonStruct = new IncorrectClassificationReasonStruct();

      incorrectClassificationReasonStruct.incorrectClassificationReason.idIncorrectClassificationReason = control.value.idIncorrectClassificationReason;
      incorrectClassificationReasonStruct.incorrectClassificationReason.description = control.value.description;
      incorrectClassificationReasonStruct.incorrectClassificationReason.isDeleted = control.value.isDeleted;
      incorrectClassificationReasonStruct.incorrectClassificationReason.idHealthUnit = control.value.idHealthUnit;
      incorrectClassificationReasonStruct.incorrectClassificationReason.datetimeInclusion = control.value.datetimeInclusion;
      incorrectClassificationReasonStruct.isCreate = control.value.isCreate;

      auditConfigRequest.listIncorrectClassificationReasonNotDefault.push(incorrectClassificationReasonStruct);
    });

    (this.model.controls['listCorrectClassificationReasonNotDefault'] as FormArray).controls.forEach(control => {
      let correctClassificationReasonStruct = new CorrectClassificationReasonStruct();

      correctClassificationReasonStruct.correctClassificationReason.idCorrectClassificationReason = control.value.idCorrectClassificationReason;
      correctClassificationReasonStruct.correctClassificationReason.description = control.value.description;
      correctClassificationReasonStruct.correctClassificationReason.isDeleted = control.value.isDeleted;
      correctClassificationReasonStruct.correctClassificationReason.idHealthUnit = control.value.idHealthUnit;
      correctClassificationReasonStruct.correctClassificationReason.datetimeInclusion = control.value.datetimeInclusion;
      correctClassificationReasonStruct.isCreate = control.value.isCreate;

      auditConfigRequest.listCorrectClassificationReasonNotDefault.push(correctClassificationReasonStruct);
    });

    this.listCorrectClassificationReasonNotDefaultDeleted.forEach(control => {
      let correctClassificationReasonStruct = new CorrectClassificationReasonStruct();

      correctClassificationReasonStruct.correctClassificationReason.idCorrectClassificationReason = control.value.idCorrectClassificationReason;
      correctClassificationReasonStruct.correctClassificationReason.description = control.value.description;
      correctClassificationReasonStruct.correctClassificationReason.isDeleted = control.value.isDeleted;
      correctClassificationReasonStruct.correctClassificationReason.idHealthUnit = control.value.idHealthUnit;
      correctClassificationReasonStruct.correctClassificationReason.datetimeInclusion = control.value.datetimeInclusion;
      correctClassificationReasonStruct.isCreate = control.value.isCreate;

      auditConfigRequest.listCorrectClassificationReasonNotDefault.push(correctClassificationReasonStruct);
    });


    auditConfigRequest.relationUserRemovePriorReasonModal = this.relationUserRemovePriorReasonModal;

    if (this.isUpdate)
      this.updateAudit(auditConfigRequest);
    else
      this.createAudit(auditConfigRequest);
  }

  createAudit(auditConfigRequest: AuditConfigRequest) {
    this.auditConfigService.createAuditConfig(auditConfigRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;
        this.router.navigate(['/audit']);

      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    }
    );
  }

  updateAudit(auditConfigRequest: AuditConfigRequest) {
    this.auditConfigService.updateAuditConfig(auditConfigRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;
        this.router.navigate(['/audit']);

      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    }
    );
  }

  getConfig() {
    this.auditConfigService.getAuditConfig().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.auditConfig = response.auditConfig;
        if (this.auditConfig) {
          this.model.get('allowAudited').setValue(this.auditConfig.allowAudited);
          if (this.auditConfig.viewTimeTag != null)
            this.model.get('viewTimeTag').setValue(this.auditConfig.viewTimeTag);
          if (this.auditConfig.displayCarePriorityInRiskClassification != null)
            this.model.get('displayCarePriorityInRiskClassification').setValue(this.auditConfig.displayCarePriorityInRiskClassification);

          this.isUpdate = true;
        }

        this.listIncorrectClassificationReasonDefault.push(...response.listIncorrectClassificationReasonDefault);
        this.listCorrectClassificationReasonDefault.push(...response.listCorrectClassificationReasonDefault);

        if (response.relationUserRemovePriorReasonModal)
          this.relationUserRemovePriorReasonModal = response.relationUserRemovePriorReasonModal;

        if (response.listIncorrectClassificationReasonNotDefault && response.listIncorrectClassificationReasonNotDefault.length > 0) {
          response.listIncorrectClassificationReasonNotDefault.forEach(incorrectClassificationReasonNotDefault => {
            (this.model.controls['listIncorrectClassificationReasonNotDefault'] as FormArray).push(
              this.createAndUpdateIncorrectReason(incorrectClassificationReasonNotDefault)
            )
          })
        }

        if (response.listCorrectClassificationReasonNotDefault && response.listCorrectClassificationReasonNotDefault.length > 0) {
          response.listCorrectClassificationReasonNotDefault.forEach(correctClassificationReasonNotDefault => {
            (this.model.controls['listCorrectClassificationReasonNotDefault'] as FormArray).push(
              this.createAndUpdateCorrectReason(correctClassificationReasonNotDefault)
            )
          })
        }

        this.isLoading = false;
        this.isFirstLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openRemovePriorReasonModal(index: number, isCorrectReason: boolean, isIncorrectReason: boolean) {
    if (!this.relationUserRemovePriorReasonModal) {
      const dialogRef = this.dialog.open(AuditRemovePriorReasonModalComponent);
      dialogRef.afterClosed().subscribe({
        next: result => {
          if (result && result.checked)
            this.relationUserRemovePriorReasonModal = true;

          if (result && result.confirmDelete) {
            if (isIncorrectReason)
              this.removeIncorrectReason(index);
            else if (isCorrectReason)
              this.removeCorrectReason(index);
          }
        }
      });
    } else {
      if (isIncorrectReason)
        this.removeIncorrectReason(index);
      else if (isCorrectReason)
        this.removeCorrectReason(index);
    }
  }

  createAndUpdateIncorrectReason(incorrectClassificationReason: IncorrectClassificationReason = null) {
    if (incorrectClassificationReason) {
      return this.formBuilder.group({
        idIncorrectClassificationReason: new FormControl(incorrectClassificationReason.idIncorrectClassificationReason, Validators.required),
        description: new FormControl(incorrectClassificationReason.description, Validators.required),
        isDeleted: new FormControl(incorrectClassificationReason.isDeleted, Validators.required),
        idHealthUnit: new FormControl(incorrectClassificationReason.idHealthUnit, Validators.required),
        isCreate: new FormControl(false, Validators.required),
        datetimeInclusion: new FormControl(incorrectClassificationReason.datetimeInclusion, Validators.required),
      });
    } else {
      return this.formBuilder.group({
        description: new FormControl('', Validators.required),
        isCreate: new FormControl(true, Validators.required),
      });
    }
  }


  createAndUpdateCorrectReason(correctClassificationReason: CorrectClassificationReason = null) {
    if (correctClassificationReason) {
      return this.formBuilder.group({
        idCorrectClassificationReason: new FormControl(correctClassificationReason.idCorrectClassificationReason, Validators.required),
        description: new FormControl(correctClassificationReason.description, Validators.required),
        isDeleted: new FormControl(correctClassificationReason.isDeleted, Validators.required),
        idHealthUnit: new FormControl(correctClassificationReason.idHealthUnit, Validators.required),
        isCreate: new FormControl(false, Validators.required),
        datetimeInclusion: new FormControl(correctClassificationReason.datetimeInclusion, Validators.required),
      });
    } else {
      return this.formBuilder.group({
        description: new FormControl('', Validators.required),
        isCreate: new FormControl(true, Validators.required),
      });
    }
  }

  addNextIncorrectReason() {
    (this.model.controls['listIncorrectClassificationReasonNotDefault'] as FormArray).push(this.createAndUpdateIncorrectReason())
  }

  addNextCorrectReason() {
    (this.model.controls['listCorrectClassificationReasonNotDefault'] as FormArray).push(this.createAndUpdateCorrectReason())
  }

  removeIncorrectReason(index: number) {
    var item: AbstractControl = (this.model.controls['listIncorrectClassificationReasonNotDefault'] as FormArray).at(index);
    if (!item.value.isCreate) {
      (this.model.controls['listIncorrectClassificationReasonNotDefault'] as FormArray).at(index).value.isDeleted = true;
      this.listIncorrectClassificationReasonNotDefaultDeleted.push((this.model.controls['listIncorrectClassificationReasonNotDefault'] as FormArray).at(index));
    }

    (this.model.controls['listIncorrectClassificationReasonNotDefault'] as FormArray).removeAt(index);
  }

  removeCorrectReason(index: number) {
    var item: AbstractControl = (this.model.controls['listCorrectClassificationReasonNotDefault'] as FormArray).at(index);
    if (!item.value.isCreate) {
      (this.model.controls['listCorrectClassificationReasonNotDefault'] as FormArray).at(index).value.isDeleted = true;
      this.listCorrectClassificationReasonNotDefaultDeleted.push((this.model.controls['listCorrectClassificationReasonNotDefault'] as FormArray).at(index));
    }

    (this.model.controls['listCorrectClassificationReasonNotDefault'] as FormArray).removeAt(index);
  }
}