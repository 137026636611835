import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { ListServiceStruct } from '../../../shared/services/responses/flow/list-service-response';
import { SectorRequest } from 'src/app/shared/services/requests/flow/sector.request';
import { SectorService } from 'src/app/shared/services/API/flow/sector.service';
import { ServiceService } from 'src/app/shared/services/API/flow/service.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SpecialityAttendance } from 'src/app/shared/services/models/flow/speciality-attendance.model';

@Component({
  selector: 'app-sector-register',
  templateUrl: './sector-register.component.html',
  styleUrls: ['./sector-register.component.css']
})
export class SectorRegisterComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private sectorService: SectorService,
    private serviceService: ServiceService,) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.master;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.master_sector;

  public masks: Masks;
  public model: FormGroup;

  public isLoading: boolean = false;
  public isFirstLoading: boolean;
  public isUpdate: boolean;

  public actualServiceModule: number;
  public idSector: number;

  public listService: ListServiceStruct[];
  public listSpecialityAttendance: SpecialityAttendance[];
  public sectorRequest: SectorRequest = new SectorRequest();

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      name: ['', [Validators.required]],
      status: ['', [Validators.required]],
      description: [''],
      idService: ['', [Validators.required]],
      idSpecialityAttendance: [''],
    });

    this.masks = this.maskService.getMasks();

    this.populateServiceSelect();

    this.idSector == null;
    this.isUpdate == false;
    if (this.activatedRoute.snapshot.paramMap.get('idSector'))
      this.idSector = parseInt(this.activatedRoute.snapshot.paramMap.get('idSector'));

    if (this.idSector != null) {
      this.isFirstLoading = true;
      this.isUpdate = true;
      this.model.get('idService').disable();
      this.populateSectorData();
    }
  }

  submit() {
    if (this.isLoading)
      return;

    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    this.isLoading = true;

    this.sectorRequest.description = this.model.get('description').value;
    this.sectorRequest.idService = parseInt(this.model.get('idService').value);
    if (this.model.get('idSpecialityAttendance').value)
      this.sectorRequest.idSpecialityAttendance = parseInt(this.model.get('idSpecialityAttendance').value);
    if (this.model.get('status').value === "true")
      this.sectorRequest.isActive = true;
    if (this.model.get('status').value === "false")
      this.sectorRequest.isActive = false;

    this.sectorRequest.sectorName = this.model.get('name').value;

    if (this.isUpdate)
      this.updateSector();
    else
      this.createSector();
  }

  populateSectorData() {
    this.sectorService.getSector(this.idSector).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.model.get('name').setValue(response.sectorStruct.sectorName);
        this.model.get('status').setValue(response.sectorStruct.isActive.toString());
        this.model.get('idService').setValue(response.sectorStruct.idService.toString());
        this.model.get('description').setValue(response.sectorStruct.description);
        if (response.sectorStruct.idSpecialityAttendance != null)
          this.model.get('idSpecialityAttendance').setValue(response.sectorStruct.idSpecialityAttendance.toString());

        this.isLoading = false;
        this.isFirstLoading = false;
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  populateServiceSelect() {
    this.serviceService.listAllService().subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.listService = response.listService;
        this.listSpecialityAttendance = response.listSpecialityAttendance;

        this.isLoading = false;
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  updateSector() {
    this.sectorService.updateSector(this.idSector, this.sectorRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.alertSave();
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  createSector() {
    this.sectorService.createSector(this.sectorRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.alertSave();
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  private throwException(error: string) {
    console.log(error);
    this.isLoading = false;
    this.alertService.show('Erro inesperado', error, AlertType.error);
  }

  private alertSave() {
    this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
    this.isLoading = false;
    this.router.navigate(['/master/sector']);
  }
}