import {  Component, OnInit } from '@angular/core';
import { FormBuilder} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertModalComponent } from 'src/app/shared/components/alert-modal/alert-modal.component';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { ReportModalComponent } from 'src/app/shared/components/report-modal/report-modal.component';
import { DownloadReportService } from 'src/app/shared/services/API/medical-record/download-report.service';
import { AihService } from "src/app/shared/services/API/orchestrator-patient/aih.service";
import { AlertService, AlertType } from "src/app/shared/services/alert.service";
import { AuthService } from 'src/app/shared/services/auth.service';
import { AihGenderModel } from 'src/app/shared/services/models/hospital-document/aih-gender.model';
import { AihModel } from 'src/app/shared/services/models/hospital-document/aih.model';
import { ClinicType } from 'src/app/shared/services/models/hospital-document/clinic-type.model';
import { DocumentTypeModel } from 'src/app/shared/services/models/hospital-document/document-type.model';
import { LookupHospitalizationReportModel } from "src/app/shared/services/models/hospital-document/lookup_hospitalization-report.model";
import { RelationAihConfigFieldRequiredModel } from "src/app/shared/services/models/hospital-document/relation_aih_config_field_required.model";
import { RelationshipWithSocialSecurityModel } from 'src/app/shared/services/models/hospital-document/relationship-with-social-security.model';
import { AihStruct } from 'src/app/shared/services/structs/hospital-document/aih.struct';
import { WebsocketAihUtilService } from 'src/app/shared/services/websocket-util/websocket-aih-util.service';


@Component({
  selector: 'app-aih-editor',
  templateUrl: './aih-editor.component.html',
  styleUrls: ['./aih-editor.component.css']
})
export class AihEditorComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private alertService: AlertService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private aihService: AihService, 
    private websocketAihUtilService: WebsocketAihUtilService,
    private authService: AuthService,
    private downloadReportService: DownloadReportService ) {
  }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.medic;

  public isLoading: boolean = false;
  public isFirstLoading: boolean = true;   

  public idAihGroup: number = null;
  public idEpisode: number = null;
  public idService: number = null;
  public idSector: number = null;
  public idRoom: number = null;
  public isPending: boolean = false;
  public isMedic: boolean = false;
  public isBedManagement: boolean = false;

  public listDocumentType: DocumentTypeModel[] = [];
  public listClinicType: ClinicType[] = [];
  public listAihGender: AihGenderModel[] = [];
  public listRelationAihConfigFieldRequired: RelationAihConfigFieldRequiredModel[] = [];
  public listLookupHospitalizationReport: LookupHospitalizationReportModel[] = [];
  public listRelationshipWithSocialSecurity: RelationshipWithSocialSecurityModel[] = [];
  public listAihStruct: AihStruct[] = [];

  public aihToPending: AihModel;
  public allowedToEdit: boolean = false;
  public toggleAllowedToEdit: boolean = false;


  //PROJETO ATUAL
  ngOnInit(): void {
    this.isLoading = true;
    this.isFirstLoading = true;

    this.idAihGroup = parseInt(this.activatedRoute.snapshot.paramMap.get('idAihGroup'));
    this.idEpisode = parseInt(this.activatedRoute.snapshot.paramMap.get('idEpisode'));
    this.isPending = this.activatedRoute.snapshot.paramMap.get('isPending') == "true" ? true : false;

    if (!Number.isInteger(this.idAihGroup) || this.idAihGroup <= 0 
    || !Number.isInteger(this.idEpisode) || this.idEpisode <= 0){
      this.isLoading = false;
      this.isFirstLoading = false;
      this.alertService.show('Erro', "Erro ao receber dados obrigatórios para a busca do AIH.", AlertType.error);
      return;
    }

    this.idSector = parseInt(this.activatedRoute.snapshot.paramMap.get('idSector'));
    this.idRoom = parseInt(this.activatedRoute.snapshot.paramMap.get('idRoom'));
    this.idService = parseInt(this.activatedRoute.snapshot.paramMap.get('idService'));
    
    this.isMedic = this.activatedRoute.snapshot.paramMap.get('isMedic') == "true" ? true : false;
    this.isBedManagement = this.activatedRoute.snapshot.paramMap.get('isBedManagement') == "true" ? true : false;

    if (this.isPending == true)
      this.startWebsocket();

    this.getAihGroup(this.idAihGroup, this.idEpisode, this.isPending);
  }

  ngOnDestroy () {
    if (this.websocketAihUtilService.isConnect())
      this.websocketAihUtilService.clearSocket();
  }

  buttonBack(){
    if (this.isLoading)
      return;         

    if (this.allowedToEdit == true && this.isPending == false) {
      const dialogRef = this.dialog.open(AlertModalComponent, {
        data: {
          isTwoButtonsModal: false,
          title: 'Aviso',
          description: 'Para voltar desabilite o botão de edição'
        },
      });
    }
    else  {
      this.backToLastComponent();
    }  
  }

  backToLastComponent() {
    let hasBedOrMedicRouterVariables: boolean = Number.isInteger(this.idSector) && Number.isInteger(this.idRoom) && Number.isInteger(this.idAihGroup);
    if (hasBedOrMedicRouterVariables && this.isMedic == true)
      this.router.navigate(['/medic/medic-patient-list', { idSector: this.idSector, idRoom: this.idRoom}]);
    
    else if (hasBedOrMedicRouterVariables && this.isBedManagement == true)
      this.router.navigate(['/medic/medic-bed-management', { idSector: this.idSector, idRoom: this.idRoom, idService: this.idService}]);

    else if (this.isPending == true)
      this.router.navigate(['/medic/list-aih-pending'])

    else
      this.router.navigate(['/medic/list-aih-completed'])
  }

  reloadList(){
    this.toggleAllowedToEdit = false;
    this.allowedToEdit = false;
    this.getAihGroup(this.idAihGroup, this.idEpisode, this.isPending); 
  }

  disableEditToggle(event: boolean) {
    if (this.isLoading){
      this.toggleAllowedToEdit = this.allowedToEdit;
      return;
    }     

    if (event == false) {      
      const dialogRef = this.dialog.open(AlertModalComponent, {
        data: {
          isTwoButtonsModal: true,
          title: 'Aviso',
          description: 'Após desabilitar a edição, todos os dados alterados serão perdidos. Deseja confirmar a ação?'
        },
      });
      dialogRef.afterClosed().subscribe({
        next: result => {
          if (result && result.confirm) {
            this.allowedToEdit = false;

            if (this.websocketAihUtilService.isConnect())
              this.websocketAihUtilService.clearSocket();   
          }        
          else  this.toggleAllowedToEdit = true;
        }
      });
    }  

    else if (event == true) {
      this.allowedToEdit = true;
      this.startWebsocket();
    }      
  }

  getAihGroup(idAihGroup: number, idEpisode: number, isPending: boolean = null) {
    this.isLoading = true;
    
    this.aihService.getAihGroup(idAihGroup, idEpisode, isPending).subscribe({
      next: (response) => {
        if (response.isError && response.errorCode == 1) {
          const dialogRef = this.dialog.open(AlertModalComponent, {
            data: {
              isTwoButtonsModal: false,
              title: 'Aviso',
              description: 'Você não tem permissão para editar esta AIH. Certifique-se de ter atendido a este episódio ou solicite permissão de edição ao responsável.'
            },
          });
          dialogRef.afterClosed().subscribe({
            next: result => {
              this.isFirstLoading = false;
              this.isLoading = false;
              this. backToLastComponent();
            }
          });

          return;

        }

        else if (response.isError) {
          this.isFirstLoading = false;
          this.isLoading = false;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);

          return;
        }

        else {
          this.listLookupHospitalizationReport = response.listLookupHospitalizationReport && response.listLookupHospitalizationReport.length > 0 ? response.listLookupHospitalizationReport : [];
          this.listRelationAihConfigFieldRequired = response.listRelationAihConfigFieldRequired && response.listRelationAihConfigFieldRequired.length > 0 ? response.listRelationAihConfigFieldRequired : [];
          this.listDocumentType = response.listDocumentType && response.listDocumentType.length > 0 ? response.listDocumentType : [];
          this.listClinicType = response.listClinicType && response.listClinicType.length > 0 ? response.listClinicType : [];
          this.listAihGender = response.listAihGender && response.listAihGender.length > 0 ? response.listAihGender : [];
          this.listRelationshipWithSocialSecurity = response.listRelationshipWithSocialSecurity && response.listRelationshipWithSocialSecurity.length > 0 ? response.listRelationshipWithSocialSecurity : [];
          this.listAihStruct = response.listAihStruct && response.listAihStruct.length > 0 ? response.listAihStruct : [];
          
          if (this.isPending == true && response.listAihStruct && response.listAihStruct.length > 0)
            this.aihToPending = response.listAihStruct[0].aih;          
  
          this.isFirstLoading = false;
          this.isLoading = false;
        }       

      },
      error: (error) => {
        this.isFirstLoading = false;
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  downloadReport(){
    this.isLoading = true;
    this.downloadReportService.downloadAihReport(this.idEpisode).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.openReportModal(response.reportName, response.reportPdf64);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openReportModal(reportName, reportPdf64) {
    this.isLoading = true;

    let reportDialog = this.dialog.open(ReportModalComponent, {
      data: {
        reportName: reportName,
        reportPdf64: reportPdf64,
      },
    });

    reportDialog.afterClosed().subscribe({
      next: () => {
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  //websocket
  startWebsocket() {
    if (!this.websocketAihUtilService.isConnect())
      this.websocketAihUtilService.connectWebsocketRoom(this, this.idAihGroup, this.authService.getTokenMenu().login, this.authService.getTokenMenu().userName);

    this.websocketAihUtilService.setVariables(this.idAihGroup, this.authService.getTokenMenu().login, this.authService.getTokenMenu().userName);
    this.websocketAihUtilService.setFunctions(this.someoneIsConectedOnEditAih);
   
  }
  
  someoneIsConectedOnEditAih() {
    if (this.isPending == true)
      this.backToLastComponent();

    else {
      this.allowedToEdit = false;
      this.toggleAllowedToEdit = false;

      if (this.websocketAihUtilService.isConnect())
        this.websocketAihUtilService.clearSocket();   
    }
  }
}