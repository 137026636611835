import { PriorityModel } from "../../models/totem/priority.model";
import { TotemButtonStruct } from "../../structs/totem/totem-button.struct";

export class TotemRequest{
    public totemName: string;
    
    public printerName: string;

    public description: string;
    
    public isActive: boolean;

    public useQrCode: boolean;
    
    public listButtons: TotemButtonStruct[];
    
    public listIdPriorityRequired: number[];

    public listIdPriority: number[];
}