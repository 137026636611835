import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ClassificationData, UtilsClassificationService } from 'src/app/classification/utils.service';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { SpecialNecessityEnum } from 'src/app/shared/enum/risk-classification.enum';
import { FowardService } from 'src/app/shared/services/API/orchestrator-queue/foward.service';
import { LookupRiskService } from 'src/app/shared/services/API/risk-classification/lookup-risk.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { SpecialNecessityModel } from 'src/app/shared/services/models/risk-classification/special-necessity.model';
import { BillingDataRequest } from 'src/app/shared/services/requests/billing/billing-data-request';
import { FowardRequest } from 'src/app/shared/services/requests/orchestrator-queue/foward.request';
import { FlowStruct } from 'src/app/shared/services/structs/flow/flow.struct';
import { LocationStruct } from 'src/app/shared/services/structs/orchestrator-queue/location.struct';

@Component({
  selector: 'app-forward-select',
  templateUrl: './forward-select.component.html',
  styleUrls: ['./forward-select.component.css']
})
export class ForwardSelectComponent implements OnInit {
  constructor(
    private forwardService: FowardService,
    public lookupService: LookupRiskService,
    public utilClassification: UtilsClassificationService,
    private alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
  ) { }


  public model: UntypedFormGroup;
  public classificationData: ClassificationData;
  public idLastQueue: number;
  public isFirstLoading: boolean = true;
  public isAutomaticFoward: boolean = false;
  public isFlowSequence: boolean = false;
  public flowSequence: FlowStruct[] = [];
  public location: LocationStruct[] = [];
  public listProcedureSigtap: any[] = [];
  public specialNecessityList: SpecialNecessityModel[];
  public idSpecialNecessity: number;
  public idSpecialNecessityOthers: number = SpecialNecessityEnum.outros;

  @Input() idEpisode: number;
  @Input() idRoom: number;
  @Input() idSector: number;
  @Output() next = new EventEmitter<any>();
  @Output() back = new EventEmitter<any>();
  @Output() mapValues = new EventEmitter<any>();

  ngOnInit(): void {
    this.classificationData = this.utilClassification.getClassificationData();
    this.model = this.formBuilder.group({
      forward: [null, [Validators.required]],
      specialNecessity: [this.classificationData.specialNecessity],
      idSpecialNecessity: [this.classificationData.idSpecialNecessity],
      observation: [this.classificationData.observation],
      conclusionNotes: []
    });
    this.getAllDestination();
    this.populateLookup();
  }

  getAllDestination() {
    let fowardRequest: FowardRequest = new FowardRequest();
    fowardRequest.idEpisode = this.idEpisode;
    fowardRequest.idRoom = this.classificationData.idRoom;
    fowardRequest.birthDate = this.classificationData.birthDate;
    fowardRequest.idPriorityColor = this.classificationData.priority.idPriorityColor;
    fowardRequest.idFlowchart = this.classificationData.idFlowchart;
    if (!this.classificationData.newPatient) {
      this.forwardService.listDestination(fowardRequest).subscribe((response) => {
        this.isFirstLoading = false;
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        } else if (response.isAutomaticFoward) {
          this.isAutomaticFoward = true;
          this.alertService.show('Aviso', response.automaticFoward.toString(), AlertType.warning);
          return;
        }
        this.isFlowSequence = (response.flowSequence && response.flowSequence.length > 0);
        this.flowSequence = response.flowSequence;
        if (this.isFlowSequence) {
          this.model.get('forward').setValue(this.flowSequence[1].idFlow);
        }
        this.location = response.location;
        this.idLastQueue = response.idLastQueue;
      });
    }
    else {
      this.forwardService.listEpisodeDestination(fowardRequest).subscribe((response) => {
        this.isFirstLoading = false;
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        } else if (response.isAutomaticFoward) {
          this.isAutomaticFoward = true;
          this.alertService.show('Aviso', response.automaticFoward.toString(), AlertType.warning);
          return;
        }
        this.isFlowSequence = (response.flowSequence && response.flowSequence.length > 0);
        this.flowSequence = response.flowSequence;
        this.location = response.location;
      });
    }
  }

  populateLookup() {
    this.lookupService.getLookup().subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.specialNecessityList = response.specialNecessity;
    });
  }

  submit() {
    if (this.model.invalid && !this.isAutomaticFoward) {
      this.alertService.show('Erro', "Nenhum Encaminhamento foi selecionado. ", AlertType.error);
      return;
    }

    if (this.model.get('forward').value !== 0) {
      this.classificationData.forward = {
        'idFlow': this.model.get('forward').value,
        'idLastQueue': this.idLastQueue,
        'locationName': this.location.length > 0 ? (this.location.find(c => c.idFlow == this.model.get('forward').value).locationName) : null,
      };
    }
    else {
      this.classificationData.forward = {
        'idFlow': null,
        'idLastQueue': this.idLastQueue,
        'locationName': null,
      };
    }

    this.classificationData.flowSequence = this.flowSequence;
    this.classificationData.specialNecessity = this.model.get('specialNecessity').value;
    this.classificationData.idSpecialNecessity = this.model.get('idSpecialNecessity').value;
    this.classificationData.specialNecessityName = this.model.get('idSpecialNecessity') ? this.specialNecessityList?.find(x => x.idSpecialNecessity == this.model.get('idSpecialNecessity').value)?.specialNecessityName : null;
    this.classificationData.observation = this.model.get('observation').value;
    this.classificationData.conclusionNotes = this.model.get('conclusionNotes').value;
    this.utilClassification.updateClassificationData(this.classificationData);
    this.mapValues.emit();
    this.next.emit();
  }

  finishEpisode() {
    this.model.get('forward').setValue(0);
    this.submit();
  }

  mapToBillingDataRequest(): BillingDataRequest {
    let billingDataRequest: BillingDataRequest = new BillingDataRequest();
    billingDataRequest.idModule = MenuModuleEnum.classification;
    billingDataRequest.idEpisode = this.idEpisode;
    billingDataRequest.listIdProcedure = this.listProcedureSigtap.map(a => a.idProcedureSigtap);
    return billingDataRequest;
  }

  onChangeSpecialNecessity(event: number) {
    this.idSpecialNecessity = event;
    if (this.idSpecialNecessity == this.idSpecialNecessityOthers) {
      this.model.get('specialNecessity').setValidators(Validators.required);
    } else {
      this.model.get('specialNecessity').setValidators(Validators.nullValidator);
    }
  }

  return() {
    this.back.emit();
  }
}
