import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserMemedService } from 'src/app/shared/services/API/orchestrator-patient/user-memed.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MemedMedicine } from 'src/app/shared/services/models/medical-record/memed-medicine.model';
import { MemedCompletePrescriptionStruct } from 'src/app/shared/services/structs/medical-record/memed-complete-prescription.struct';
import { MemedPrescriptionStruct } from 'src/app/shared/services/structs/medical-record/memed-prescription.struct';
import { EpisodeStruct } from 'src/app/shared/services/structs/orchestrator-patient/episode.struct';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-memed-prescriptions-modal',
  templateUrl: './memed-prescriptions-modal.component.html',
  styleUrls: ['./memed-prescriptions-modal.component.css']
})
export class MemedPrescriptionsModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<MemedPrescriptionsModalComponent>,
    private alertService: AlertService,
    private userMemedService: UserMemedService,
  ) { }

  public episodeStruct: EpisodeStruct;
  public memedPrescriptionStruct: MemedPrescriptionStruct;
  public memedCompletePrescriptionStruct: MemedCompletePrescriptionStruct;
  
  public isFirstLoading: boolean = true;
  
  public urlScriptMemed: string = environment.urlScriptMemed;;
  public cpfPatient: string;
  public userToken: string;

  ngOnInit(): void {
    this.episodeStruct = this.data.episodeStruct;
    this.cpfPatient = this.data.cpfPatient;
    this.userToken = this.data.userTokenMemed;
    this.initMemed();
  }
  
  clickConfirm(){
    this.matDialogRef.close({});
  }

  close(){
    if(this.memedPrescriptionStruct && this.memedPrescriptionStruct != null)
      this.matDialogRef.close({memedPrescriptionStruct: this.memedPrescriptionStruct, completePrescription: this.memedCompletePrescriptionStruct}); 
    else 
      this.matDialogRef.close(); 
  }

  initMemed() {

    let script = document.createElement('script');
    script.id = 'memed';
    script.src = this.urlScriptMemed;
    script.dataset.token = this.userToken;

    script.dataset.container = "memed-prescription";
    script.dataset.color = "#85C443";

    script.addEventListener("load", () => {
      
      if(!('MdSinapsePrescricao' in window)) {
        throw Error('MdSinapsePrescricao was not initialized.');
      }

      (window['MdSinapsePrescricao'] as any).event.add("core:moduleInit", async (module) => {
        if(!('MdHub' in window)) {
          throw Error('MdHub was not initialized.');
        }
        if(module.name === "plataforma.prescricao") {
          await (window['MdHub'] as any).command.send('plataforma.prescricao', 'setFeatureToggle', {
            deletePatient: false,
            removePatient: false,
            editPatient: false,
            historyPrescription: true,
            optionsPrescription: false,
            removePrescription: false,
            setPatientAllergy: false,
            autocompleteIndustrialized: true,
            autocompleteManipulated: true,
            autocompleteCompositions: true,
            autocompletePeripherals: true,
            copyMedicalRecords: true,
            buttonClose: false,
            newFormula: true,
            allowShareModal: false,
            guidesOnboarding: false,
            conclusionModalEdit: true,
            dropdownSync: false,
            showProtocol: true,
            showHelpMenu: true,
            editIdentification: false,
          });

          let userBirthDate = new Date(this.episodeStruct.birthDate);
          let userBirthDateFormated = userBirthDate.toLocaleDateString('pt-BR');

          await (window['MdHub'] as any).command.send("plataforma.prescricao", "setPaciente", {
            idExterno: this.episodeStruct.idPatient + this.cpfPatient,
            nome: this.episodeStruct.patientName,
            cpf: this.cpfPatient,
            nome_social: this.episodeStruct.patientSocialName ? this.episodeStruct.patientSocialName : "",
            data_nascimento: userBirthDateFormated.toString(),
          });

          (window['MdHub'] as any).event.add('prescricaoImpressa', (prescriptionData) => {
            this.memedPrescriptionStruct = new MemedPrescriptionStruct();
            this.memedPrescriptionStruct.idMemedPrescription = prescriptionData.prescricao.id;
            this.memedPrescriptionStruct.listMemedMedicine = (prescriptionData.prescricao.medicamentos) ? this.mapMemedMedicine(prescriptionData.prescricao.medicamentos) : [];
            this.memedCompletePrescriptionStruct = prescriptionData; 
          });
          
          await (window['MdHub'] as any).module.show("plataforma.prescricao");

          this.isFirstLoading = false;
        }
      });
      (window['MdSinapsePrescricao'] as any).event.add("core:moduleHide", async (module) => {
        if(!('MdHub' in window)) {
          throw Error('MdHub was not initialized.');
        }
      });
    });
    document.body.appendChild(script);
  }

  mapMemedMedicine(medicine: any[]): MemedMedicine[] {
    let listMemedMap: MemedMedicine[] = [];
    medicine.forEach(item => {
      let newMemedMap = new MemedMedicine();

      newMemedMap.idMemed = item.id;
      newMemedMap.name = item.nome;
      newMemedMap.description = item.descricao;
      newMemedMap.quantity = item.quantidade;
      newMemedMap.unit = item.unit;
      newMemedMap.controleEspecial = item.controle_especial;
      newMemedMap.altoCusto = item.alto_custo;
      newMemedMap.anamnese = item.anamnese;
      newMemedMap.dataSolicitacao = item.data_solicitacao;
      newMemedMap.receituario = item.receituario;
      newMemedMap.tipoExameSelecionado = item.tipo_exame_selecionado;
      newMemedMap.tarja = item.tarja;
      newMemedMap.tipo = item.tipo;
      newMemedMap.sanitizedPosology = item.sanitized_posology;
      newMemedMap.via = item.via;
      newMemedMap.origem = item.origem;
      newMemedMap.subtitulo = item.subtitulo;
      newMemedMap.titulo = item.titulo;

      listMemedMap.push(newMemedMap);
    });
    return listMemedMap;
  }
}

