export class DigitalPromptServiceConfig  {
    
    public idSelfTriageConfig: number;
    public idHealthUnit: number;
    public idSessionTimeout: number;
    
    public useColor: boolean;
    public isLogoUploaded: boolean;
    public allowSelectionHealthcareAgreement: boolean;
    public isActive: boolean;
    
    public colorCode: string;
    public colorCodePreview: string;
    public logoName: string;
    public logoNamePreview: string;
    public url: string;
    public urlName: string;

    public datetimeInclusion: Date;

}
