export class DirectionButton  {
    public idDirectionButton: number;
    
    public idSelfTriageConfig: number;
    
    public idServiceType: number;
    
    public buttonName: string;

    public buttonDescription: string;
    
    public description: string;
    
    public directionAddress: string;
    
    public datetimeInclusion: Date;
}