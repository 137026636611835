import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ClassificationData, UtilsClassificationService } from 'src/app/classification/utils.service';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { DeviceTypeEnum } from 'src/app/shared/enum/device-type.enum';
import { ProtocolEnum } from 'src/app/shared/enum/protocol.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MeasurerService } from 'src/app/shared/services/API/measurer/measurer.service';
import { AiComplaintToFlowchartService } from 'src/app/shared/services/API/orchestrator-classification/ai-complaint-to-flowchart.service';
import { FlowchartGroupService } from 'src/app/shared/services/API/risk-classification/flowchart-group.service';
import { FlowchartService } from 'src/app/shared/services/API/risk-classification/flowchart.service';
import { IaFlowchartService } from 'src/app/shared/services/API/user/ia-flowchart.service';
import { FlowchartGroupModel } from 'src/app/shared/services/models/risk-classification/flowchart-group.model';
import { FlowchartModel } from 'src/app/shared/services/models/risk-classification/flowchart.model';
import { ComplaintToFlowchartRequest } from 'src/app/shared/services/requests/orchestrator-classification/complaint-to-flowchart.request';
import { SearchFlowchartRequest } from 'src/app/shared/services/requests/risk-classification/search-flowchart.request';
import { FlowchartStruct } from 'src/app/shared/services/structs/risk-classification/flowchart.struct';
import { SelfTriageResumeModalComponent } from 'src/app/classification/pages/flowchart/self-triage-resume-modal/self-triage-resume-modal.component';
import { SelfTriageResponse } from 'src/app/shared/services/responses/self-triage/self-triage.response';
import { DeviceTypeSectorResponse } from 'src/app/shared/services/responses/risk-classification/device-type-sector.response';
import { PriorityEnum } from 'src/app/shared/enum/totem/priority.enum';
import { TriageStruct } from 'src/app/shared/services/structs/medical-record/triage.struct';
import { LastTriageDetailsModalComponent } from '../last-triage-details-modal/last-triage-details-modal.component';


@Component({
  selector: 'app-flowchart-selection-default',
  templateUrl: './flowchart-selection-default.component.html',
  styleUrls: ['./flowchart-selection-default.component.css']
})
export class FlowchartSelectionDefaultComponent implements OnInit {

  constructor(private alertService: AlertService,
    private dialog: MatDialog,
    private iaFlowchartService: IaFlowchartService,
    private aiComplaintToFlochartService: AiComplaintToFlowchartService,
    public router: Router,
    private formBuilder: UntypedFormBuilder,
    private flowchartService: FlowchartService,
    private flowchartGroupService: FlowchartGroupService,
    public utilClassification: UtilsClassificationService,
  ) { }

  @Input() lastTriage: TriageStruct = null;
  @Input() alertTimeDescription: string = '';
  @Input() healthUnitNameByLastTriage: string = '';

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.classification;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.classification_pre_registration;
  public model: UntypedFormGroup;
  public isFirstLoading: boolean = true;
  public isLoadingAi: boolean = false;
  public listFlowchartGroup: FlowchartGroupModel[];
  public listFlowchart: FlowchartModel[];
  public listAiFlowchart: FlowchartModel[];
  public listFilterFlowchart: FlowchartModel[];
  public idEpisode: number;
  public idFlowchar: number;
  public isTelephone: boolean;
  public isAIEnabled: boolean = false;
  public noAiResult: boolean = false;
  public isSelfTriage: boolean;
  public selfTriage: SelfTriageResponse;
  @Output() selectedFlowchart = new EventEmitter<any>();
  public listAIisClear: boolean = true;
  public classificationData: ClassificationData;
  public setUppercaseText: boolean = false;

  ngOnInit(): void {
    this.classificationData = this.utilClassification.getClassificationData();
    this.isTelephone = this.classificationData.isTelephoneClassification;
    this.idEpisode = this.classificationData.idEpisode;
    this.getAllFlowcharts(this.classificationData);
    this.getAllFlowchartsGroup();
    if (this.classificationData.idProtocol == ProtocolEnum.Manchester) {
      this.getIfIsAIEnabled();
    }
    let deviceTypeSectorResponse: DeviceTypeSectorResponse = this.utilClassification.getDevicetypeBySector()
    if (deviceTypeSectorResponse && deviceTypeSectorResponse.deviceTypeSector && deviceTypeSectorResponse.deviceTypeSector.setUppercaseText)
      this.setUppercaseText = deviceTypeSectorResponse.deviceTypeSector.setUppercaseText;
    let isAutistic = Array.isArray(this.classificationData.listIdPriority) ? this.classificationData.listIdPriority.some(c => c == PriorityEnum.Autistic.valueOf()) : false;

    this.model = this.formBuilder.group({
      complain: [this.classificationData.complain, [Validators.required]],
      priorityAutistic: [isAutistic]
    });
    this.isSelfTriage = this.classificationData.selfTriage && this.classificationData.selfTriage.listQuestionAnswer && this.classificationData.selfTriage.listQuestionAnswer.length > 0;
    this.selfTriage = this.classificationData.selfTriage;
  }

  getAllFlowcharts(data: ClassificationData) {
    let body: SearchFlowchartRequest = new SearchFlowchartRequest();
    body.idProtocol = data.idProtocol;
    if (!body.idProtocol || body.idProtocol == 0) {
      body.idProtocol = ProtocolEnum.Manchester.valueOf();
    }
    if (data.patientData) {
      body.birthdate = data.patientData.birthDate;
      body.idGender = data.patientData.idGender;
      body.searchText = null;
      body.flowchartGroup = null;
    } else {
      body.birthdate = data.birthDate;
      body.idGender = data.idGender;
      body.searchText = null;
      body.flowchartGroup = null;
    }

    this.flowchartService.getFlowchart(body).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        this.isFirstLoading = false;
        this.listFlowchart = response.flowcharts;

        this.listFilterFlowchart = response.flowcharts;
      }
    });
  }

  openSelfTriageResumeModal() {
    let data: any = {};
    data = this.selfTriage;
    const dialogRef = this.dialog.open(SelfTriageResumeModalComponent, { data });
  }

  getAllFlowchartsGroup() {
    this.flowchartGroupService.getFlowchartGroup().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        this.listFlowchartGroup = response.flowchartsGroup;
      }
    });
  }

  onChangeSelect(event: any) {
    if (event.value.length == 0) {
      this.listFilterFlowchart = this.listFlowchart;
    } else {
      this.listFilterFlowchart = this.listFlowchart.filter(c => event.value.some(x => c.flowchartGroup.includes(parseInt(x))))
    }
  }

  selectFlowchart() {

    if (this.model.invalid) {
      this.alertService.show('Erro', "Preencha a queixa!", AlertType.error);
      return;
    }

    let values = this.utilClassification.getClassificationData();
    values.complain = this.model.get("complain").value;
    values.hasFlowchartIA = this.isAIEnabled;
    values.priorityAutistic = this.model.get('priorityAutistic').value;
    values.listIdPriority = Array.isArray(values.listIdPriority) ? values.listIdPriority : [];
    if (!(values.listIdPriority.some(c => c == PriorityEnum.Autistic.valueOf())) && values.priorityAutistic) {
      values.listIdPriority.push(PriorityEnum.Autistic.valueOf());
    }
    else if ((values.listIdPriority.some(c => c == PriorityEnum.Autistic.valueOf())) && !values.priorityAutistic) {
      let autisticIndex = values.listIdPriority.indexOf(PriorityEnum.Autistic.valueOf());
      values.listIdPriority.splice(autisticIndex, 1);
    }
    if (this.listAiFlowchart) {
      values.iaResult = JSON.stringify(this.listAiFlowchart.map(l => l.idFlowchart));
    }

    if (this.idFlowchar != 0) {
      values.idFlowchart = this.idFlowchar;
      values.flowchartName = this.listFlowchart.find(c => c.idFlowchart == this.idFlowchar).flowchartName;
      values.isPunctuationSystem = this.listFlowchart.find(c => c.idFlowchart == this.idFlowchar).isPunctuationSystem;
      values.idFlowchartType = this.listFlowchart.find(c => c.idFlowchart == this.idFlowchar).idFlowchartType;
      this.utilClassification.updateClassificationData(values);
    } else {
      this.utilClassification.updateClassificationData(values);
    }
    this.selectedFlowchart.emit(this.idFlowchar);
  }

  onKeySearch(event: any) {
    if (!event.target.value) {
      this.listFilterFlowchart = this.listFlowchart;
    } else {
      this.listFilterFlowchart = this.listFlowchart.filter(c => c.flowchartName.toLocaleLowerCase().search(event.target.value.toLocaleLowerCase()) >= 0)
    }
  }

  private timeoutKeySearch: any = null;

  onKeyChangeAi() {
    if (!this.isAIEnabled)
      return null;
    this.listAiFlowchart = [];
  }

  searchAI() {
    this.listAIisClear = false;
    this.getAIFlowchart(this.model.get("complain").value)
  }

  getIfIsAIEnabled() {
    this.isLoadingAi = true;
    this.iaFlowchartService.checkIfIsAIEnabled().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoadingAi = false;
          return;
        }
        this.isAIEnabled = response.hasFlowchartIA;
        this.isLoadingAi = false;
      },
      error: (error) => {
        this.isLoadingAi = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getAIFlowchart(complaint: string) {
    if (!complaint) {
      this.alertService.show('Aviso', "Preencha a queixa para visualizar os fluxogramas sugeridos!", AlertType.warning);
      return
    }
    this.isLoadingAi = true;
    let request: ComplaintToFlowchartRequest = {
      complaint: complaint,
      birthDate: this.classificationData.birthDate ? new Date(this.classificationData.birthDate) : null
    };
    this.aiComplaintToFlochartService.getAIFlowcharts(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoadingAi = false;
          return;
        }
        if ((response.flowcharts?.length > 0 ?? false) && response.flowcharts[0].idFlowchart == -1) {
          this.noAiResult = true;
          this.isLoadingAi = false;
          this.listAiFlowchart = [];
          return;
        }
        this.listAiFlowchart = response.flowcharts;
        this.isLoadingAi = false;
        this.noAiResult = false;
      },
      error: (error) => {
        this.isLoadingAi = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openLastTriageDetails() {
    this.dialog.open(LastTriageDetailsModalComponent, {
      data: {
        lastTriage: this.lastTriage,
        healthUnitNameByLastTriage: this.healthUnitNameByLastTriage,
      },
    });
  }
}
