import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { HealthUnitHasProcedureService } from 'src/app/shared/services/API/medic/health-unit-has-procedure.service';

@Component({
  selector: 'app-health-unit-medical-procedure-modal',
  templateUrl: './health-unit-medical-procedure-modal.component.html',
  styleUrls: ['./health-unit-medical-procedure-modal.component.css']
})
export class HealthUnitMedicalProcedureModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<HealthUnitMedicalProcedureModalComponent>,
    private healthUnitHasProcedureService: HealthUnitHasProcedureService,
    private alertService: AlertService,
  ) { }

  public isLoading:boolean;  
  public hasProcedure:boolean;  

  ngOnInit(): void {
    this.hasProcedure = this.data.hasProcedure;
    this.isLoading = false;
  }
  
  close(){
    this.matDialogRef.close({changeConfig: false}); 
  }
  
  clickConfirm(){
    
    this.isLoading = true;

    this.healthUnitHasProcedureService.postProcedureConfig().subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.alertService.show('Sucesso', "Configuração alterada com sucesso!", AlertType.success); 
      this.matDialogRef.close({changeConfig: true}); 
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }
  
  clickCancel(){
    this.matDialogRef.close({changeConfig: false}); 
  }
}
