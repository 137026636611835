import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PriceProcedureHealthcareService } from 'src/app/shared/services/API/private-billing/price-procedure-healthcare.service';
import { UserGetNameService } from 'src/app/shared/services/API/user/user-get-name.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { HistoryPriceProcedureHealthcareStruct } from 'src/app/shared/services/structs/private-billing/history-price-procedure-healthcare.struct';
import { ListUserStruct } from 'src/app/shared/services/structs/user/list-user.struct';

@Component({
  selector: 'app-price-history-modal',
  templateUrl: './price-history-modal.component.html',
  styleUrls: ['./price-history-modal.component.css']
})
export class PriceHistoryModalComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<PriceHistoryModalComponent>,
    private alertService: AlertService,
    private priceProcedureHealthcareService: PriceProcedureHealthcareService,
    private userGetNameService: UserGetNameService
  ) { }

  public isLoading: boolean = false;
  public idProcedureHealthcare: number = this.data.idProcedureHealthcare;
  public listHistoryPriceProcedureHealthcareStruct: HistoryPriceProcedureHealthcareStruct[] = [];

  ngOnInit(): void {
    this.getHistory(this.idProcedureHealthcare);
  }

  clickCancel() {
    this.matDialogRef.close();
  }

  getHistory(idProcedureHealthcare: number) {
    if (this.isLoading)
      return;

    this.isLoading = true;

    this.priceProcedureHealthcareService.getHistoryPrice(idProcedureHealthcare).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.listHistoryPriceProcedureHealthcareStruct = response.listHistoryPriceProcedureHealthcareStruct;

        if (this.listHistoryPriceProcedureHealthcareStruct && this.listHistoryPriceProcedureHealthcareStruct.length > 0 
        && response.listIdUser && response.listIdUser.length > 0){
            this.getUserName(response.listIdUser);
        }
        
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getUserName(listIdUser) {
    if (this.isLoading)
      return;

    this.isLoading = true;

    this.userGetNameService.listUser(listIdUser).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.insertNameInList(response.listUser);

        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  insertNameInList(listUser: ListUserStruct[]) {
    this.listHistoryPriceProcedureHealthcareStruct.forEach(x => {
      x.userName = listUser.find(y => y.idUser == x.idUser).userName;
    });
  }
}