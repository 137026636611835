import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { AccessKeyRequest } from '../../requests/integration/access-key.request';
import { AuthorizeAccessKeyResponse } from '../../responses/integration/authorize-access-key.response';
import { GetAccessKeyResponse } from '../../responses/integration/get-access-key.response';
import { ListAccessKeyResponse } from '../../responses/integration/list-access-key.response';

@Injectable({
  providedIn: 'root'
})
export class AccessKeyService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listAccessKey(searchText: string, isActive: boolean): Observable<ListAccessKeyResponse> {
    
    let uri = 'AccessKey?'
    
    if(searchText != null)
      uri = uri+ `searchText=${searchText}&`;
    
    if(isActive !=null)
      uri = uri + `isActive=${isActive}&`  
    
    return this.httpClient.get<ListAccessKeyResponse>(environment.urlApiIntegration + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public listAllAccessKey(): Observable<ListAccessKeyResponse> {
    
    let uri = 'AccessKey/getAll'

    return this.httpClient.get<ListAccessKeyResponse>(environment.urlApiIntegration + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
  
  public deleteAccessKey(idAccessKey: number): Observable<ReturnStruct>{

    let uri = `AccessKey/idAccessKey/${idAccessKey}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiIntegration + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public getAccessKey(idAccessKey: number): Observable<GetAccessKeyResponse> {
    
    let uri = `AccessKey/idAccessKey/${idAccessKey}`;  
    
    return this.httpClient.get<GetAccessKeyResponse>(environment.urlApiIntegration + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public createAccessKey(body: AccessKeyRequest): Observable<ReturnStruct>{

    let uri = `AccessKey`

    return this.httpClient.post<ReturnStruct>(environment.urlApiIntegration + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public updateAccessKey(idAccessKey: number, body: AccessKeyRequest): Observable<ReturnStruct>{

    let uri = `AccessKey/idAccessKey/${idAccessKey}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiIntegration + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public listAccessKeyByUser(idUser: number): Observable<ListAccessKeyResponse> {
    
    let uri = `AccessKey/idUser/${idUser}`
         
    return this.httpClient.get<ListAccessKeyResponse>(environment.urlApiIntegration + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
