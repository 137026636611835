import { MetaDataStruct } from "../../structs/meta-data/meta-data.struct";

export class PostPutFrontDeskRequest{
    public frontDeskName: string;
    public frontDeskDescription: string;
    public listIdFieldRequired: number[];
    public listIdSector: number[];
    public idWristbandDimensions: number;
    public customWristbandWidth: number;
    public customWristbandHeight: number;
    public idIdentification: number;
    public listIdRequiredMetaData: number[];
    public listIdMetaData: number[];
    public listMetaData: MetaDataStruct[];
}