import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { GetPatientByCpfService } from 'src/app/shared/services/API/orchestrator-patient/get-patient-by-cpf.service';

@Component({
  selector: 'app-search-remote-modal',
  templateUrl: './search-remote-modal.component.html',
  styleUrls: ['./search-remote-modal.component.css']
})
export class SearchRemoteModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<SearchRemoteModalComponent>,
    private alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
    private getPatientByCpfService: GetPatientByCpfService,
    private maskService: MaskService,) { }

  public searchModel: UntypedFormGroup;
  public isLoading: boolean = false;
  public masks: Masks;

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.searchModel = this.formBuilder.group({
      cpf: ['', Validators.required],
    });

  }

  clickCancel() {
    this.matDialogRef.close();
  }
  close() {
    this.matDialogRef.close();
  }

  clickPesquisar() {
    this.isLoading = true;
    let cpf = this.searchModel.get("cpf").value.replace(/\D+/g, '').replace(/[^0-9]+/g, '');
    this.getPatientByCpfService.getPatientByCPF(cpf.replace(/[^0-9]+/g, '')).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.matDialogRef.close(response);
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }
}

