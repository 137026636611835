<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-flow-modal-list">
            <h2>Montar os gráficos?</h2>
        </div>
        <div class="body-flow-modal-list">
            Gostaria de ir para a página de criação dos dashboards.
        </div>
        <div class="footer-flow-modal-list">
            <button mat-flat-button color="primary" class="btn-block" (click)="clickGo()">Sim</button>
            <button mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Não</button>
        </div>
    </div>
</div>