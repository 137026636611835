import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MedicFavoriteExamRequestDeleteModalComponent } from './medic-favorite-exam-request-delete-modal/medic-favorite-exam-request-delete-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FavoriteExamPrescriptionService } from 'src/app/shared/services/API/medic/favorite-exam-prescription.service';
import { DoctorFavoriteGroupExamPrescriptionStruct } from 'src/app/shared/services/structs/medic/doctor-favorite-group-exam-prescription.struc';

@Component({
  selector: 'app-medic-favorite-exam-request',
  templateUrl: './medic-favorite-exam-request.component.html',
  styleUrls: ['./medic-favorite-exam-request.component.css']
})
export class MedicFavoriteExamRequestComponent implements OnInit {

  constructor(private alertService: AlertService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private favoriteExamPrescriptionService: FavoriteExamPrescriptionService
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.medic;
  public isLoading: boolean = true;
  public listFavoriteGroupExamPrescription: DoctorFavoriteGroupExamPrescriptionStruct[] = [];
  public idRoom: number;
  public idSector: number;

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.paramMap.get('idRoom'))
      this.idRoom = parseInt(this.activatedRoute.snapshot.paramMap.get('idRoom'));

    if (this.activatedRoute.snapshot.paramMap.get('idSector'))
      this.idSector = parseInt(this.activatedRoute.snapshot.paramMap.get('idSector'));

    this.populateCardList();
  }

  populateCardList() {
    this.favoriteExamPrescriptionService.listFavoriteGroupExamPrescription().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listFavoriteGroupExamPrescription = response.listFavoriteGroupExamPrescription;        
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openDeleteModal(idDoctorFavoriteGroupExamPrescription: number) {
    const dialogRef = this.dialog.open(MedicFavoriteExamRequestDeleteModalComponent, {
      data: {
        idDoctorFavoriteGroupExamPrescription: idDoctorFavoriteGroupExamPrescription
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.isDeleted)
          this.ngOnInit();
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  clickBack() {
    this.router.navigate(['/medic/medic-patient-list', {idSector: this.idSector, idRoom: this.idRoom}]);
  }
}