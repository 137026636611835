import { MedicalPrescriptionCheckModel } from "../../models/medical-record/medical-prescription-check.model";

export class MedicalPrescriptionCheckStruct extends MedicalPrescriptionCheckModel
{
    public userName: string;

    public professionName: string;

    public linkToNursingConsulting: boolean;

    public prescriptionEstimativeStatus: string;

    public medicalPrescriptionStatus: number;
}