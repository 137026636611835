<div class="body-container">
    <app-menu [menuModuleEnum]="menuModuleEnum"></app-menu>
    <div class="container-body">
        <div>
            <div class="white-body">
                <div class="welcome-header">
                    <div class="title-header">
                        <h1>Seja bem-vindo(a) à plataforma ToLife</h1>
                    </div>
                    <p>Selecione, abaixo, a funcionalidade que você deseja acessar e aproveite todas as possibilidades do sistema Celerus.</p>
                </div>
                <div class="welcome-img">
                    <img src="assets/images/welcome-img.png" aria-hidden="false" aria-label="welcome" />
                </div>
            </div>
            <hr/>
            <section>
                <div class="card-container row">
                    <app-loading-list *ngIf="isLoading"></app-loading-list>
                    <button class="card-iten col-12" *ngFor="let item of listModuleGroupStruct" (click)="openModuleModal(item)" [disabled]="haveAccessModule(item)">
                        <mat-icon class="card-icon" aria-hidden="false" aria-label="Menu grupo">{{item.iconClass}}</mat-icon>
                        <p class="module-names">{{item.nameModuleGroup}}</p>
                        <br>
                    </button>
                </div>
            </section>
        </div>
    </div> 
</div>