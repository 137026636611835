import { ReturnStruct } from "src/app/shared/structs/return.struct";
import { HealthPlan } from "../../models/telemedicine/health-plan.model";

export class TelemedicineConfigRequest extends ReturnStruct {
    public useColor: boolean;
    public colorCode: string;
    public isLogoUploaded: boolean;
    public logoString64: string;
    public url: string;
    public urlName: string;
    public isActive: boolean;
    public isToSendSms: boolean;
    public idHealthIdentification: number;
    public listHealthPlans: HealthPlan[] = [];
    public orientationsTitle: string;
    public orientationsDescription: string;
    public idTelemedicinePatientRedirectType: number;
    public waitingTimePatientDoctorInSeconds: number;
    public maxReturns: number;
    public useTelemedicineInPatientCenter: boolean;
    public useTolifeLogo: boolean;
    public isExternalSearchEnabled: boolean;
    public idExternalSearchField: number;
}