import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { AlertModalComponent } from 'src/app/shared/components/alert-modal/alert-modal.component';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { GuideBatchService } from 'src/app/shared/services/API/private-billing/guide-batch.service';
import { LookupService } from 'src/app/shared/services/API/private-billing/lookup.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { GuideBatch } from 'src/app/shared/services/models/private-billing/guide-batch.model';
import { SendGuideBatchStatus } from 'src/app/shared/services/models/private-billing/send-guide-batch-status.model';
import { GuideBatchStruct } from 'src/app/shared/services/structs/private-billing/guide-batch.struct';
import { HealthcareAgreementStruct } from 'src/app/shared/services/structs/private-billing/healthcare-agreement.struct';

@Component({
  selector: 'app-guide-batch-list',
  templateUrl: './guide-batch-list.component.html',
  styleUrls: ['./guide-batch-list.component.css']
})
export class GuideBatchListComponent implements OnInit {
  constructor(private alertService: AlertService,
    public dialog: MatDialog,
    private guideBatchService: GuideBatchService,
    private lookupService: LookupService,
    private router: Router,) {
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.billing;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.tiss_batch_control;

  public isLoading: boolean = false;


  public searchText: string = null;
  public beginDatetimeSendGuideBatch: Date = null;
  public endDatetimeSendGuideBatch: Date = null;
  public datetimeProcessingEnd: Date = null;
  public idHealthcareAgreement: number = null;

  public listHealthcareAgreementStruct: HealthcareAgreementStruct[] = [];
  public listSendGuideBatchStatus: SendGuideBatchStatus[] = [];
  public listGuideBatchStruct: GuideBatchStruct[] = [];

  public pageIndex: number = 0;
  public pageSize: number = 50;
  public fullListSize: number;

  ngOnInit(): void {
    this.getLookup();
  }

  createNewBatch() {
    if (this.isLoading)
      return;

    this.router.navigate(['/billing/tiss-batch-control/register']);
  }

  openBatch(idGuideBatch: number){
    if (this.isLoading)
      return;
    
    this.router.navigate(['/billing/tiss-batch-control/edit', {idGuideBatch: idGuideBatch}]);
  }

  getLookup() {
    if (this.isLoading)
      return;

    this.isLoading = true;

    this.lookupService.getLookupToGuideBatchList().subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.listSendGuideBatchStatus = response.listSendGuideBatchStatus;
        this.listHealthcareAgreementStruct = response.listHealthcareAgreementStruct;
        this.isLoading = false;

        this.search();
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  search() {
    if (this.isLoading)
      return;

    this.isLoading = true;

    let dateStringProcessingEnd: string = this.setDateToSendoInSearch(this.datetimeProcessingEnd);
    let beginDateStringSendGuideBatch: string = this.setDateToSendoInSearch(this.beginDatetimeSendGuideBatch);
    let endDateStringSendGuideBatch: string = this.setDateToSendoInSearch(this.endDatetimeSendGuideBatch);

    this.guideBatchService.GetAll(this.pageIndex, this.searchText, this.idHealthcareAgreement, dateStringProcessingEnd, beginDateStringSendGuideBatch, endDateStringSendGuideBatch).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.listGuideBatchStruct = response.listGuideBatchStruct;
        this.fullListSize = response.fullSize;
        this.isLoading = false;
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  setDateToSendoInSearch(selectedDate: Date = null): string {
    if (!selectedDate)
      return null;

    let copyOfDate: Date = new Date(selectedDate);
    let timeZoneOffset: number = copyOfDate.getTimezoneOffset();
    copyOfDate.setMinutes(copyOfDate.getMinutes() - timeZoneOffset);

    return copyOfDate.toISOString();
  }

  changePage(pageIndex: number) {
    this.pageIndex = pageIndex;
    this.search();
  }

  putStatus(guideBatchStruct: GuideBatchStruct,) {
    if (this.isLoading)
      return;

    this.isLoading = true;

    this.guideBatchService.PutSendStatus(guideBatchStruct.guideBatch.idGuideBatch, guideBatchStruct.guideBatch.idSendGuideBatchStatus).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.isLoading = false;
        this.alertService.show('Sucesso', 'Status do lote: ' + guideBatchStruct.guideBatch.batchNumber + ' atualizado com sucesso.', AlertType.success);
      },
      error: (error) => {
        this.throwException(error);
        this.search();
      }
    });
  }

  downloadPdfGuidesFromBatch(idGuideBatch: number) {
    if (this.isLoading)
      return;

    this.isLoading = true;

    this.guideBatchService.DownloadPdfGuidesFromBatch(idGuideBatch).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.isLoading = false;
        const downloadData = `data:application/zip;base64,${response.zipFileBase64}`;
        const downloadLink = encodeURI(downloadData);

        if (downloadLink)
          this.triggerDownload(downloadLink, idGuideBatch);

      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  downloadXmlGuidesFromBatch(idGuideBatch: number) {
    if (this.isLoading)
      return;

    this.isLoading = true;

    this.guideBatchService.DownloadXmlGuidesFromBatch(idGuideBatch).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.isLoading = false;
        const downloadData = `data:application/zip;base64,${response.zipFileBase64}`;
        const downloadLink = encodeURI(downloadData);

        if (downloadLink)
          this.triggerDownload(downloadLink, idGuideBatch);

      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  openModalDelete(guideBatch: GuideBatch) {
    if (this.isLoading)
      return;

    const dialogRef = this.dialog.open(AlertModalComponent, {
      data: {
        isTwoButtonsModal: true,
        title: 'Aviso',
        description: `A exclusão do lote  ${guideBatch.batchNumber.toString().padStart(12, '0')} é irreversível. Deseja confirmar a ação?`
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.confirm)
          this.deleteGuideBatch(guideBatch.idGuideBatch);
      }
    });
  }

  deleteGuideBatch(idGuideBatch: number) {
    if (this.isLoading)
      return;

    this.isLoading = true;

    this.guideBatchService.DeleteGuideBatch(idGuideBatch).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        let index: number = this.listGuideBatchStruct.findIndex(item => item.guideBatch.idGuideBatch == idGuideBatch);

        if (Number.isInteger(index) && index >= 0)
          this.listGuideBatchStruct.splice(index, 1);

        this.isLoading = false;
        this.alertService.show('Sucesso', "Item deletado.", AlertType.success);
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  triggerDownload(downloadLink: string, idGuideBatch: number) {
    const anchor = document.createElement('a');
    anchor.href = downloadLink;
    anchor.download = `guias_lote_${idGuideBatch}.zip`
    anchor.style.display = 'none';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }

  private throwException(error: string) {
    this.alertService.show('Erro inesperado', error, AlertType.error);
    this.isLoading = false;
  }

}