import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { GetHealthUnitResponse } from '../../responses/user/get-health-unit.response';

@Injectable({
  providedIn: 'root'
})
export class ClientHealthUnitService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getHealthUnit(idHealthUnit: number): Observable<GetHealthUnitResponse> {

    let uri = `ClientHealthUnit/idHealthUnit/${idHealthUnit}`;

    return this.httpClient.get<GetHealthUnitResponse>(environment.urlApiUser + uri)
      .pipe(
        catchError(this.handleError)
      )
  }
}
