import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { GetPatientByCpfResponse } from '../../responses/orchestrator-patient/get-patient-by-cpf.response';

@Injectable({
  providedIn: 'root'
})
export class GetPatientByCpfService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getPatientByCPF(cpf:string): Observable<GetPatientByCpfResponse>{
    let uri = `PatientEpisode?cpf=${cpf}`
    return this.httpClient.get<GetPatientByCpfResponse>(environment.urlApiOrchestratorPatient + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
}
}
