import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GraphTypeEnum } from 'src/app/shared/enum/graph-type.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { GraphService } from 'src/app/shared/services/API/data-intelligente/graph.service';
import { AggregationModel } from 'src/app/shared/services/models/data-intelligence/aggregation.model';
import { ClassificationModel } from 'src/app/shared/services/models/data-intelligence/classification.model';
import { GraphWidthModel } from 'src/app/shared/services/models/data-intelligence/graph-width.model';
import { GraphTypeModel } from 'src/app/shared/services/models/data-intelligence/graph-type.model';
import { GraphRequest } from 'src/app/shared/services/requests/data-intelligence/graph.request';
import { DataSourceModel } from 'src/app/shared/services/models/data-intelligence/data-source.model';
import { DataSourceVariableModel } from 'src/app/shared/services/models/data-intelligence/data-source-variable.model';
import { LookupDataService } from 'src/app/shared/services/API/data-intelligente/lookup-data.service';
import { VariablesDataSourceEnum } from 'src/app/shared/enum/variables-data-source.enum';


@Component({
  selector: 'app-graph-config-modal',
  templateUrl: './graph-config-modal.component.html',
  styleUrls: ['./graph-config-modal.component.css']
})
export class GraphConfigModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<GraphConfigModalComponent>,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private graphService: GraphService) { }


  public model: UntypedFormGroup;
  public listGraphWidth: GraphWidthModel[] = [];
  public listClassification: ClassificationModel[] = [];
  public listFilteredClassification: ClassificationModel[] = [];
  public listAggregation: AggregationModel[] = [];
  public listGraphType: GraphTypeModel[] = [];
  public isLoading: boolean = false;
  public listDataSource: DataSourceModel[] = [];
  public selectedIdDataSource: number;
  public listDataSourceVariable: DataSourceVariableModel[];

  public listclassificationField: DataSourceVariableModel[] = [];
  public listDataSourceVariableX: DataSourceVariableModel[];
  public listDataSourceVariableY: DataSourceVariableModel[];

  public hasAxisY: boolean = true;;
  public isAxisXMultiple: boolean = false;
  public isAxisYMultiple: boolean = false;
  public hasClassification: boolean = true;;
  public isAggregationMultiple: boolean = false;
  public hasAggreation: boolean = true;;
  public isCounter: boolean = false;
  public hasClassificationField: boolean = false;
  private selectedIdGraphType: number = null;
  private tableClassificationWithoutOrder: number = 6;

  ngOnInit(): void {
    if (this.data.idGraph) {
      this.readGraph();
    }
    this.listGraphWidth = this.data.listGraphWidth;
    this.listClassification = this.data.listClassification;
    this.listAggregation = this.data.listAggregation;
    this.listGraphType = this.data.listGraphType;
    this.listDataSource = this.data.listDataSource;
    this.listDataSourceVariable = this.data.listDataSourceVariable;

    this.model = this.formBuilder.group({
      idAggregation: ['', [Validators.required]],
      idGraphType: ['', [Validators.required]],
      idDataSourceVariableX: ['', [Validators.required]],
      idDataSourceVariableY: ['', [Validators.required]],
      idClassification: ['', [Validators.required]],
      idGraphWidth: ['', [Validators.required]],
      idDataSource: ['', [Validators.required]],
      order: ['', [Validators.required]],
      height: [{ value: '2 Quadrantes', disabled: true }],
      graphName: ['', [Validators.required]],
      classificationField: ['', [Validators.required]],
    });
  }

  close() {
    this.matDialogRef.close({ register: false });
  }

  submit() {
    if (this.isLoading) {
      return;
    } else if (this.model.invalid) {
      this.alertService.show("Erro", "Preencha todos os campos em vermelho!", AlertType.error);
      return;
    }
    this.isLoading = true;
    let graph: GraphRequest = new GraphRequest;
    graph.graphName = this.model.get("graphName").value;
    graph.idClassification = this.model.get("idClassification").value;
    graph.idDataSource = this.model.get("idDataSource").value;
    graph.idGraphWidth = this.model.get("idGraphWidth").value;
    graph.listAxisX = Array.isArray(this.model.get("idDataSourceVariableX").value) ? this.model.get("idDataSourceVariableX").value : this.model.get("idDataSourceVariableX").value ? [this.model.get("idDataSourceVariableX").value] : [];
    graph.listAxisY = Array.isArray(this.model.get("idDataSourceVariableY").value) ? this.model.get("idDataSourceVariableY").value : this.model.get("idDataSourceVariableY").value ? [this.model.get("idDataSourceVariableY").value] : [];
    graph.idGraphType = this.model.get("idGraphType").value;
    graph.listAggregation = Array.isArray(this.model.get("idAggregation").value) ? this.model.get("idAggregation").value : this.model.get("idAggregation").value ? [this.model.get("idAggregation").value] : [];
    graph.order = this.model.get("order").value;
    graph.height = 2;
    graph.idDashboard = this.data.idDashboard;
    graph.idDataSourceVariable = this.model.get("classificationField").value ? this.model.get("classificationField").value : null;
    if (this.data.idGraph) {
      this.updateGraph(this.data.idGraph, graph);
    } else {
      this.createGraph(graph);
    }

  }

  updateGraph(idGraph: number, graph: GraphRequest) {
    this.graphService.update(graph, idGraph).subscribe((response) => {
      if (response.isError) {
        this.alertService.show("Erro", response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.alertService.show("Sucesso", "Gráfico atualizado com sucesso!", AlertType.success);
      this.matDialogRef.close({ register: true });
      this.isLoading = false;
    }, (error) => {
      this.alertService.show("Erro inesperado", error, AlertType.error);
      this.isLoading = false;
    });
  }

  createGraph(graph: GraphRequest) {
    this.graphService.create(graph).subscribe((response) => {
      if (response.isError) {
        this.alertService.show("Erro", response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.alertService.show("Sucesso", "Gráfico criado com sucesso!", AlertType.success);
      this.matDialogRef.close({ register: true });
      this.isLoading = false;
    }, (error) => {
      this.alertService.show("Erro inesperado", error, AlertType.error);
      this.isLoading = false;
    });
  }

  readGraph() {
    this.graphService.get(this.data.idGraph).subscribe((response) => {
      if (response.isError) {
        this.alertService.show("Erro", response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.model.get("idDataSource").setValue(response.graph.idDataSource);
      this.selectedIdDataSource = response.graph.idDataSource;
      this.graphTypeConfig(response.graph.idGraphType, true);
      this.model.get("graphName").setValue(response.graph.graphName);
      this.model.get("idClassification").setValue(response.graph.idClassification);
      this.model.get("idGraphWidth").setValue(response.graph.idGraphWidth);
      this.model.get("idGraphType").setValue(response.graph.idGraphType);
      this.model.get("order").setValue(response.graph.order);

      if (response.graph.idGraphType == GraphTypeEnum.table.valueOf()) {

        this.model.get("idDataSourceVariableX").setValue(response.listAxisX.map(c => c.idDataSourceVariable));
        this.classificationType();
        this.model.get("classificationField").setValue(response.graph.idDataSourceVariable);
      } else {
        this.model.get("idDataSourceVariableX").setValue(response.listAxisX.length ? response.listAxisX[0].idDataSourceVariable : null);
      }
      this.model.get("idDataSourceVariableY").setValue(response.listAxisY.length ? response.listAxisY[0].idDataSourceVariable : null);
      this.model.get("idAggregation").setValue(response.listAggregation.length ? response.listAggregation[0].idAggregation : null);

      this.isLoading = false;
    }, (error) => {
      this.alertService.show("Erro inesperado", error, AlertType.error);
      this.isLoading = false;
    });
  }


  classificationType() {
    let variables: number[] = this.model.get("idDataSourceVariableX").value;
    if (this.selectedIdGraphType == GraphTypeEnum.table.valueOf() && this.model.get("idClassification").value != this.tableClassificationWithoutOrder && variables.length) {
      this.listclassificationField = [...this.listDataSourceVariableX.filter(c => variables.find(x => x == c.idDataSourceVariable))];

      if (this.listclassificationField.findIndex(c => c.idDataSourceVariable == this.model.get("classificationField").value) == -1) {
        this.model.get("classificationField").setValue(null);
      }
      this.hasClassificationField = true;
      this.model.get("classificationField").setValidators([Validators.required]);
      this.model.get("classificationField").updateValueAndValidity();
    } else {
      this.model.get("classificationField").setValue(null);
      this.hasClassificationField = false;
      this.model.get("classificationField").setValidators([]);
      this.model.get("classificationField").updateValueAndValidity();
    }
  }

  clickCancel() {
    this.matDialogRef.close({ register: false });
  }

  datasourceSelection(idDataSource: number, isFromGraphType: boolean) {
    let idDatasourceType = this.listDataSource.find(c => c.idDataSource == idDataSource).idDataSourceType;
    this.selectedIdDataSource = idDataSource;

    if (this.selectedIdGraphType == GraphTypeEnum.lines.valueOf()) {

      this.listDataSourceVariableX = this.listDataSourceVariable.filter(c => c.idDataSourceType == idDatasourceType &&
        (
          c.idVariableType == VariablesDataSourceEnum.datetime.valueOf()
        ));

      this.listDataSourceVariableY = this.listDataSourceVariable.filter(c => c.idDataSourceType == idDatasourceType &&
        (
          c.idVariableType == VariablesDataSourceEnum.text.valueOf() ||
          c.idVariableType == VariablesDataSourceEnum.select.valueOf()
        ));
      if ((this.listDataSourceVariableX.length == 0 || this.listDataSourceVariableY.length == 0) && !isFromGraphType) {
        this.alertService.show("Aviso", "Fonte de dados escolhidos não é compatível com este tipo de gráfico", AlertType.warning);
      }
    } else if (this.selectedIdGraphType == GraphTypeEnum.counter.valueOf()) {
      this.listDataSourceVariableX = this.listDataSourceVariable.filter(c => c.idDataSourceType == idDatasourceType);
      if ((this.listDataSourceVariableX.length == 0) && !isFromGraphType) {
        this.alertService.show("Aviso", "Fonte de dados escolhidos não é compatível com este tipo de gráfico", AlertType.warning);
      }
    } else {
      this.listDataSourceVariableX = this.listDataSourceVariable.filter(c => c.idDataSourceType == idDatasourceType &&
        (
          c.idVariableType == VariablesDataSourceEnum.select.valueOf() ||
          c.idVariableType == VariablesDataSourceEnum.text.valueOf() ||
          c.idVariableType == VariablesDataSourceEnum.datetime.valueOf()
        ));
      this.listDataSourceVariableY = this.listDataSourceVariable.filter(c => c.idDataSourceType == idDatasourceType &&
        (
          c.idVariableType == VariablesDataSourceEnum.number.valueOf() ||
          c.idVariableType == VariablesDataSourceEnum.time.valueOf()
        ));
      if ((this.listDataSourceVariableX.length == 0 || this.listDataSourceVariableY.length == 0) && !isFromGraphType && this.selectedIdGraphType != null) {
        this.alertService.show("Aviso", "Fonte de dados escolhidos não é compatível com este tipo de gráfico", AlertType.warning);
      }
    }
  }

  graphTypeConfig(idGraphType: number, isUpdate: boolean) {
    if (!isUpdate) {
      this.model.get("idClassification").setValue(null);
      this.model.get("idGraphWidth").setValue(null);
      this.model.get("idDataSourceVariableX").setValue(null);
      this.model.get("idDataSourceVariableY").setValue(null);
      this.model.get("idAggregation").setValue(null);
    }
    this.model.get("idAggregation").enable();
    this.model.get("idGraphWidth").enable();
    this.isCounter = false;
    this.listFilteredClassification = [];
    this.selectedIdGraphType = idGraphType;
    this.hasClassificationField = false;
    if (idGraphType == GraphTypeEnum.columns.valueOf()) {
      this.graphConfigColumn();
    } else if (idGraphType == GraphTypeEnum.counter.valueOf()) {
      this.graphConfigCounter();
    } else if (idGraphType == GraphTypeEnum.lines.valueOf()) {
      this.graphConfigLines();
    } else if (idGraphType == GraphTypeEnum.pie.valueOf()) {
      this.graphConfigPizza();
    } else if (idGraphType == GraphTypeEnum.table.valueOf()) {
      this.graphConfigTable();
    }
  }

  //Altura sempre é dois quadrantes para todos
  //A largura é um select padrão, a menos se for contador que ai é um quadrante só.

  graphConfigColumn() {
    this.datasourceSelection(this.selectedIdDataSource, true);

    this.model.get("idAggregation").setValidators([Validators.required]);
    this.model.get("idAggregation").updateValueAndValidity();
    this.model.get("idDataSourceVariableY").setValidators([Validators.required]);
    this.model.get("idDataSourceVariableY").updateValueAndValidity();
    this.model.get("idClassification").setValidators([Validators.required]);
    this.model.get("idClassification").updateValueAndValidity();
    this.model.get("idGraphWidth").setValidators([Validators.required]);
    this.model.get("idGraphWidth").updateValueAndValidity();
    this.model.get("classificationField").setValidators([]);
    this.model.get("classificationField").updateValueAndValidity();

    this.listFilteredClassification = this.listClassification.filter(c => c.idGraphType == GraphTypeEnum.columns.valueOf());
    if (this.listDataSourceVariableX.length == 0 || this.listDataSourceVariableY.length == 0) {
      this.alertService.show("Aviso", "Fonte de dados escolhidos não é compatível com este tipo de gráfico", AlertType.warning);
    }
    this.isAxisYMultiple = false;
    this.hasAxisY = true;
    this.hasClassification = true;
    this.isAggregationMultiple = false;
    this.hasAggreation = true;
    this.isAxisXMultiple = false;
  }

  graphConfigLines() {
    this.datasourceSelection(this.selectedIdDataSource, true);
    this.model.get("idAggregation").setValidators([Validators.required]);
    this.model.get("idAggregation").updateValueAndValidity();
    this.model.get("idDataSourceVariableY").setValidators([Validators.required]);
    this.model.get("idDataSourceVariableY").updateValueAndValidity();
    this.model.get("idClassification").setValidators([]);
    this.model.get("idClassification").updateValueAndValidity();
    this.model.get("classificationField").setValidators([]);
    this.model.get("classificationField").updateValueAndValidity();
    if (this.listDataSourceVariableX.length == 0 || this.listDataSourceVariableY.length == 0) {
      this.alertService.show("Aviso", "Fonte de dados escolhidos não é compatível com este tipo de gráfico", AlertType.warning);
    }
    this.hasAxisY = true;
    this.isAxisXMultiple = false;
    this.isAxisYMultiple = false;
    this.hasClassification = false;
    this.isAggregationMultiple = false;
    this.hasAggreation = true;
    this.model.get("idAggregation").setValue(1);
    this.model.get("idAggregation").disable();
  }

  graphConfigCounter() {
    this.datasourceSelection(this.selectedIdDataSource, true);
    this.model.get("idAggregation").setValidators([Validators.required]);
    this.model.get("idAggregation").updateValueAndValidity();
    this.model.get("idDataSourceVariableY").setValidators([]);
    this.model.get("idDataSourceVariableY").updateValueAndValidity();
    this.model.get("idClassification").setValidators([]);
    this.model.get("idClassification").updateValueAndValidity();
    this.model.get("classificationField").setValidators([]);
    this.model.get("classificationField").updateValueAndValidity();
    if (this.listDataSourceVariableX.length == 0) {
      this.alertService.show("Aviso", "Fonte de dados escolhidos não é compatível com este tipo de gráfico", AlertType.warning);
    }
    this.isAxisXMultiple = false;
    this.hasAxisY = false;
    this.hasClassification = false;
    this.isAggregationMultiple = false;
    this.hasAggreation = true;

    this.model.get("idGraphWidth").setValue(1);
    this.model.get("idGraphWidth").disable();
  }

  graphConfigTable() {
    this.datasourceSelection(this.selectedIdDataSource, true);
    this.model.get("idAggregation").setValidators([]);
    this.model.get("idAggregation").updateValueAndValidity();
    this.model.get("idDataSourceVariableY").setValidators([]);
    this.model.get("idDataSourceVariableY").updateValueAndValidity();
    this.model.get("idClassification").setValidators([Validators.required]);
    this.model.get("idClassification").updateValueAndValidity();
    this.model.get("classificationField").setValidators([Validators.required]);
    this.model.get("classificationField").updateValueAndValidity();

    this.hasClassificationField = true;
    this.isAxisXMultiple = true;
    this.hasAxisY = false;
    this.hasClassification = true;
    this.listFilteredClassification = this.listClassification.filter(c => c.idGraphType == GraphTypeEnum.table.valueOf());
    this.hasAggreation = false;
  }

  graphConfigPizza() {
    this.datasourceSelection(this.selectedIdDataSource, true);
    this.model.get("idAggregation").setValidators([Validators.required]);
    this.model.get("idAggregation").updateValueAndValidity();
    this.model.get("idDataSourceVariableY").setValidators([Validators.required]);
    this.model.get("idDataSourceVariableY").updateValueAndValidity();
    this.model.get("idClassification").setValidators([Validators.required]);
    this.model.get("idClassification").updateValueAndValidity();
    this.model.get("idGraphWidth").setValidators([Validators.required]);
    this.model.get("idGraphWidth").updateValueAndValidity();
    this.model.get("classificationField").setValidators([]);
    this.model.get("classificationField").updateValueAndValidity();
    this.listFilteredClassification = this.listClassification.filter(c => c.idGraphType == GraphTypeEnum.pie.valueOf());

    this.isAxisYMultiple = false;
    this.hasAxisY = true;
    this.hasClassification = true;
    this.isAggregationMultiple = false;
    this.hasAggreation = true;
    this.isAxisXMultiple = false;
  }
}

