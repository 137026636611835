import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ReturnStruct } from '../../../structs/return.struct';
import { CommonService } from '../../common-service';
import { EquipmentTypeResponse } from '../../responses/form/equipment-type.response';

@Injectable({
  providedIn: 'root'
})
export class EquipmentTypeService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listEquipmentType(searchText: string) : Observable<EquipmentTypeResponse> {
    let uri = `EquipmentType?`

    if(searchText != null)
          uri = uri+ `searchText=${searchText}`;
    
    return this.httpClient.get<EquipmentTypeResponse>(environment.urlApiForm + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}

