<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <div class="page-title">
            <div>
                <a class="back-link" routerLink="/master">
                    <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Módulo Master
                </a>
                <h1>Cadastro de Alerta de Fila</h1>
            </div>
            <div class="col-12 col-md-4">
                <div class="btn-container">
                    <button mat-flat-button type="button" color="accent" class=" btn-block" (click)="back()">
                        <span *ngIf="isLoading == false">Voltar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                    <button form="alert-form" mat-flat-button type="submit" color="primary" class=" btn-block">
                        <span *ngIf="isLoading == false">Salvar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
            </div>
        </div>
        <app-loading-progress-bar *ngIf="isLoading"></app-loading-progress-bar>
        <form appFormscroll *ngIf="!isLoading" class="form" [formGroup]="model" (ngSubmit)="submit()" id="alert-form">
            <div class="white-body">
                <div class="title-header">
                    <h1>Dados do alerta</h1>
                </div>
                <div class="row">
                    <div class="col-6 col-md-4">
                        <mat-form-field *ngIf="!isLoading" appearance="outline">
                            <mat-label>Serviço</mat-label>
                            <mat-select [disabled]="isUpdate" formControlName="idService">
                                <mat-option *ngFor="let item of listService"
                                    [disabled]="unavailableServices.has(item.idService)" [value]="item.idService"
                                    matTooltip="{{unavailableServices.has(item.idService) ? 'Serviço já cadastrado': ''}}">
                                    {{item.serviceName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-md-4">
                        <mat-form-field *ngIf="!isLoading" appearance="outline">
                            <mat-label>Usuários</mat-label>
                            <mat-select [multiple]="true" formControlName="listIdUser">
                                <mat-option *ngFor="let item of listUser"
                                    [value]="item.idUser">{{item.userName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 col-md-4 min-patient-alert">
                        <mat-form-field *ngIf="!isLoading" appearance="outline">
                            <mat-label>Qtde. mínima de pacientes aguardando</mat-label>
                            <mat-icon class="tooltip-frequency" aria-hidden="false" aria-label="question_mark"
                                matTooltip="O alerta será disparado quando o volume de pacientes estiver aguardando mais do que o tempo máximo permitido."
                                matTooltipPosition="above">
                                question_mark</mat-icon>
                            <input matInput placeholder="0" type="number" formControlName="minQueueCount" min="1"
                                step="1">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-md-4 max-time-alert">
                        <mat-form-field *ngIf="!isLoading" appearance="outline">
                            <mat-label>Tempo máximo de espera em fila</mat-label>
                            <span matSuffix>minutos</span>
                            <input placeholder="0" matInput type="number" formControlName="minQueueTime" min="0"
                                step="1">
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>