<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <h1>
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <mat-slide-toggle #hygienization class="indigent-checkbox" (change)="openConfirmModal()"
                    formControlName="hygienization" [disabled]="isLoading">
                    Usar ferramenta de controle de higienização
                </mat-slide-toggle>
            </form>
        </h1>
        <div class=" filters">
            <div class="row">
                <div class="col-12 col-md-4">
                    <mat-form-field class="search-outline" appearance="outline">
                        <mat-icon matPrefix class="my-icon">search</mat-icon>
                        <mat-label>Pesquisar</mat-label>
                        <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText"
                            (keyup)="onKeySearch($event)">
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-4 col-sm-12">
                <div class="bed-white-body">
                    <div class="section-header" [style.border-left-color]="'#db1f67'">
                        <span>
                            Leitos não higienizados
                        </span>
                    </div>
                    <div class="card-list" *ngIf="model.get('hygienization').value">
                        <div class="row">
                            <div class="col-12" *ngFor="let item of filterListUnhygienizedBed">
                                <div class="card-item" *ngIf="!isLoading">
                                    <div class="infos">
                                        <span class="primary">{{item.bedName}}</span>
                                        <span class="secundary">{{item.bedTypeName}}</span>
                                        <span class="secundary">{{item.bedStatusName}}</span>
                                        <span class="secundary">{{item.bedCategoryName}}</span>
                                        <span class="secundary bed-status"
                                            [style.background-color]="'#db1f67'">{{item.hygienizationStatusName}}</span>
                                    </div>
                                    <div class="actions">
                                        <a>
                                            <mat-icon aria-hidden="false" aria-label="Higienizar"
                                                (click)="startHygienization(item)" matTooltip="Começar higienização">
                                                cleaning_services
                                            </mat-icon>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4 col-sm-12">
                <div class="bed-white-body">
                    <div class="section-header" [style.border-left-color]="'#e89323'">
                        <span>
                            Leitos em higienização
                        </span>
                    </div>
                    <div class="card-list" *ngIf="model.get('hygienization').value">
                        <div class="row">
                            <div class="col-12" *ngFor="let item of filterListHygienizingBed">
                                <div class="card-item" *ngIf="!isLoading">
                                    <div class="infos">
                                        <span class="primary">{{item.bedName}}</span>
                                        <span class="secundary">{{item.bedTypeName}}</span>
                                        <span class="secundary">{{item.bedStatusName}}</span>
                                        <span class="secundary">{{item.bedCategoryName}}</span>
                                        <span class="secundary bed-status"
                                            [style.background-color]="'#e89323'">{{item.hygienizationStatusName}}</span>
                                        <span class="secundary">{{item.hygienizationUserName}}</span>
                                    </div>
                                    <div class="actions">
                                        <a>
                                            <mat-icon aria-hidden="false" aria-label="Editar"
                                                (click)="finishHygienization(item)" matTooltip="Finalizar higienização">
                                                sanitizer
                                            </mat-icon>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4 col-sm-12">
                <div class="bed-white-body">
                    <div class="section-header" [style.border-left-color]="'#045bbf'">
                        <span>
                            Leitos higienizados
                        </span>
                    </div>
                    <div class="card-list" *ngIf="model.get('hygienization').value">
                        <div class="row">
                            <div class="col-12" *ngFor="let item of filterListHygienizedBed">
                                <div class="card-item" *ngIf="!isLoading">
                                    <div class="infos">
                                        <span class="primary">{{item.bedName}}</span>
                                        <span class="secundary">{{item.bedTypeName}}</span>
                                        <span class="secundary">{{item.bedStatusName}}</span>
                                        <span class="secundary">{{item.bedCategoryName}}</span>
                                        <span class="secundary bed-status"
                                            [style.background-color]="'#045bbf'">{{item.hygienizationStatusName}}</span>
                                        <span class="secundary">{{item.hygienizationUserName}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>
    
</div>