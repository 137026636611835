import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, retry, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { LookupResponse } from '../../responses/hospital-document/lookup.response';

@Injectable({
  providedIn: 'root'
})
export class LookupService extends CommonService {

  constructor(private httpClient: HttpClient) { super(); }

  public getLookup(): Observable<LookupResponse> {
    let uri = `Lookup`

    return this.httpClient.get<LookupResponse>(
      environment.urlApiHospitalDocument + uri, this.addHeaderToken()
    )
      .pipe(timeout(environment.requestTimeOut), retry(environment.requestRetry), catchError(this.handleError));
  }
}