<div mat-dialog-content>
    <div class="modal-body text-center">
        <form class="form" [formGroup]="model2">
            <div class="row">
                <div class="col-md-12 row-escolha-triagem">
                    <div class="descricao descricao-com-info">
                        <span class="texto">
                            {{frequenciaRespiratoriaNome}}
                            <a><img src="assets/images/icode-radio-ask.svg" /></a>
                        </span>
                        <span (click)="abrirModal(frequenciaRespiratoriaEnum, frequenciaRespiratoriaNome)"
                            class="info">{{frequenciaRespiratoria||this.data.discriminators[frequenciaRespiratoriaEnum]}}</span>
                    </div>
                    <input (click)="setValueFrequenciaRespiratoriaSim(true)" [value]="true" class="radio-sim"
                        type="radio" id="radio-frequencia-respiratoria-sim"
                        formControlName="radioFrequenciaRespiratoria" name="radioFrequenciaRespiratoria">
                    <label (click)="setValueFrequenciaRespiratoriaSim(false)" class="label-radio-sim"
                        for="radio-frequencia-respiratoria-sim"><img src="assets/images/icone-radio-sim.svg" /></label>
                    <input (click)="setValueFrequenciaRespiratoriaNao(true)" [value]="false" class="radio-nao"
                        type="radio" id="radio-frequencia-respiratoria-nao"
                        formControlName="radioFrequenciaRespiratoria" name="radioFrequenciaRespiratoria">
                    <label (click)="setValueFrequenciaRespiratoriaNao(false)" class="label-radio-nao"
                        for="radio-frequencia-respiratoria-nao"><img src="assets/images/icone-radio-nao.svg" /></label>
                </div>
                <div class="col-md-12 row-escolha-triagem">
                    <div class="descricao descricao-com-info">
                        <span class="texto">
                            {{perfusaoCapilarNome}}
                            <a><img src="assets/images/icode-radio-ask.svg" /></a>
                        </span>
                        <span (click)="abrirModal(perfusaoCapilarEnum,perfusaoCapilarNome)"
                            class="info">{{this.data.discriminators[perfusaoCapilarEnum] || perfusaoCapilar}}</span>
                    </div>
                    <input (click)="setValueperfusaoCapilarSim(true)" [value]="true" class="radio-sim" type="radio"
                        id="radio-perfusao-capilar-sim" formControlName="radio-perfusao-capilar"
                        name="radio-perfusao-capilar">
                    <label (click)="setValueperfusaoCapilarSim(false)" class="label-radio-sim"
                        for="radio-perfusao-capilar-sim"><img src="assets/images/icone-radio-sim.svg" /></label>
                    <input (click)="setValueperfusaoCapilarNao(true)" [value]="false" class="radio-nao" type="radio"
                        id="radio-perfusao-capilar-nao" formControlName="radio-perfusao-capilar"
                        name="radio-perfusao-capilar">
                    <label (click)="setValueperfusaoCapilarNao(false)" class="label-radio-nao"
                        for="radio-perfusao-capilar-nao"><img src="assets/images/icone-radio-nao.svg" /></label>
                </div>
                <div class="col-md-12 row-escolha-triagem">
                    <div class="descricao descricao-com-info">
                        <span class="texto">
                            {{frequenciaPulsoNome}}
                            <a><img src="assets/images/icode-radio-ask.svg" /></a>
                        </span>
                        <span (click)="abrirModal(frequenciaPulsoEnum,frequenciaPulsoNome)"
                            class="info">{{frequenciaPulso ||
                            this.data.discriminators[frequenciaPulsoEnum]}}</span>
                    </div>
                    <input (click)="setValueFrequenciaPulsoSim(true)" [value]="true" class="radio-sim" type="radio"
                        id="radio-frequencia-pulso-sim" formControlName="radio-frequencia-pulso"
                        name="radio-frequencia-pulso">
                    <label (click)="setValueFrequenciaPulsoSim(false)" class="label-radio-sim"
                        for="radio-frequencia-pulso-sim"><img src="assets/images/icone-radio-sim.svg" /></label>
                    <input (click)="setValueFrequenciaPulsoNao(true)" [value]="false" class="radio-nao" type="radio"
                        id="radio-frequencia-pulso-nao" formControlName="radio-frequencia-pulso"
                        name="radio-frequencia-pulso">
                    <label (click)="setValueFrequenciaPulsoNao(false)" class="label-radio-nao"
                        for="radio-frequencia-pulso-nao"><img src="assets/images/icone-radio-nao.svg" /></label>
                </div>
            </div>
        </form>
    </div>
</div>