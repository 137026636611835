<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <a class="back-link" routerLink="/digital-prompt-service/virtual-triage/virtual-triage-config">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Listagem de Configurações de Triagem Virtual
        </a>
        <h1>Cadastrar Configuração Triagem Virtual</h1>

        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        
        <div class="white-body">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome</mat-label>
                            <input matInput type="text" formControlName="name">
                            <mat-error *ngIf="model.get('name').invalid">Informe o Nome</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Setores</mat-label>
                            <mat-select formControlName="listIdSector" multiple>
                                <mat-option *ngFor="let item of listSector" [value]="item.idSector"
                                    [disabled]="item.idModule != this.menuModuleEnum">
                                    {{item.serviceName}} - {{item.sectorName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 metaData">
                        <mat-form-field appearance="outline">
                            <mat-label>Selecionar metadados</mat-label>
                            <mat-select formControlName="listMetadata" multiple>
                                <mat-option *ngFor="let item of listMetaData" (click)="selectMetaData(item.metaData.idMetaData)"
                                    [value]="item.metaData.idMetaData">
                                    {{ item.metaData.metaDataName }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 selectMetaData">
                        <mat-form-field appearance="outline">
                            <mat-label>Metadados obrigatórios</mat-label>
                            <mat-select formControlName="listMetaDataRequiredSelected" multiple>
                                <mat-option *ngFor="let item of listMetaDataRequiredSelected"
                                    [value]="item.metaData.idMetaData">
                                    {{ item.metaData.metaDataName }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <button mat-flat-button type="button" color="accent" class=" btn-block" (click)="clickCancel()">
                            <span *ngIf="isLoading == false">Cancelar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    
</div>