<form class="form" [formGroup]="model" (ngSubmit)="submit()" [appUppercaseForm]="this.setUppercaseText">
    <div class="title-header">
        <div class="row">
            <div class="col-6 col-sm-6 col-md-6">
                <h1>Dados Pessoais</h1>
            </div>
            <div class="col-6 col-sm-6 col-md-6" *ngIf="this.isPreRegister">
                <mat-slide-toggle class="indigent-checkbox" formControlName="unidentifiedPatient"
                    checked="isUnidentifiedPatient" (change)="changeIndigent($event)">Paciente não
                    identificado</mat-slide-toggle>
            </div>
        </div>
    </div>
    <div *ngIf="this.episode && this.episode.ticketInitials && this.isPreRegister" class="col-12 ticket-info">
        <h1><span>Senha: </span>{{this.episode.ticketInitials}}{{this.episode.ticketSequence}}</h1>
    </div>
    <div *ngIf="!this.isPreRegister && this.allowAdmissionDataChanging" class="button-edit col-6 col-md-4">
        <button mat-flat-button type="button" color="accent" class=" btn-block" (click)="controlFormEnable()">
            <span *ngIf="isLoading == false">Editar</span>
        </button>
    </div>
    <div class="row">
        <div class="col-12 col-sm-6 col-md-3" *ngIf="this.isUnidentifiedPatient">
            <mat-form-field appearance="outline">
                <mat-label>Prioridade</mat-label>
                <mat-select formControlName="listIdPriority" multiple #prioritySelect
                    (selectionChange)="validatePriorities($event)">
                    <mat-option *ngFor="let item of listOfPriorities" [value]="item.idPriority"
                        [disabled]="disallowedIdPriorities.includes(item.idPriority)">{{item.priorityName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 col-sm-6 col-md-3" *ngIf="this.isUnidentifiedPatient">
            <mat-form-field appearance="outline">
                <mat-label>Faixa etária</mat-label>
                <mat-select formControlName="ageGroup">
                    <mat-option *ngFor="let item of listAgeGroup"
                        [value]="item.idAgeGroup">{{item.ageGroupName}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-sm-6 col-md-6 col-lg-6" *ngIf="this.isUnidentifiedPatient">
            <mat-form-field appearance="outline">
                <mat-label>Descrição do paciente não identificado</mat-label>
                <textarea matInput type="text" formControlName="unidentifiedPatientDescription"></textarea>
            </mat-form-field>
        </div>
    </div>
    <div *ngIf="!this.isUnidentifiedPatient">
        <div class="row">
            <div class="col-12 col-sm-6 col-md-3">
                <mat-form-field appearance="outline">
                    <mat-label>Nome</mat-label>
                    <input matInput type="text" formControlName="patientName">
                    <mat-error *ngIf="model.get('patientName').invalid">Informe o nome do paciente</mat-error>
                </mat-form-field>
            </div>
            <div class="col-12 col-sm-6 col-md-3">
                <mat-form-field appearance="outline">
                    <mat-label>Nome Social</mat-label>
                    <input matInput type="text" formControlName="socialName">
                </mat-form-field>
            </div>
            <div class="col-6 col-sm-3 col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>Data de Nascimento</mat-label>
                    <input matInput type="text" (blur)="onKeySearchAge($event)" formControlName="birthDate"
                        [mask]="masks.date">
                    <mat-error
                        *ngIf="model.get('birthDate').invalid && model.get('birthDate').errors && !model.get('birthDate').errors.invalidDate">Data
                        é obrigatória</mat-error>
                    <mat-error
                        *ngIf="model.get('birthDate').invalid && model.get('birthDate').errors && model.get('birthDate').errors.invalidDate">{{model.get('birthDate').errors.invalidDate}}</mat-error>
                </mat-form-field>
            </div>

            <div class="col-6 col-sm-3 col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>Idade</mat-label>
                    <input matInput type="text" formControlName="patientAge">
                </mat-form-field>
            </div>
            <div class="col-6 col-sm-3 col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>Genêro</mat-label>
                    <mat-select formControlName="idGender">
                        <mat-option *ngFor="let item of genderList" [value]="item.idGender">
                            {{item.genderName}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="model.get('idGender').invalid">Informe o gênero</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-6 col-md-3">
                <mat-form-field appearance="outline">
                    <mat-label>Nome da mãe</mat-label>
                    <input matInput type="text" formControlName="motherName">
                    <mat-error *ngIf="model.get('motherName').invalid">Informe o nome da mãe</mat-error>
                </mat-form-field>
            </div>
            <div class="col-6 col-sm-3 col-md-2">
                <mat-form-field appearance="outline" class="my-form-field">
                    <mat-label>CPF</mat-label>
                    <input matInput type="text" formControlName="cpf" [mask]="masks.cpf"
                        (keyup)="onKeySearchCPF($event)">
                    <mat-error *ngIf="model.get('cpf').invalid">Informe o CPF</mat-error>
                </mat-form-field>
                <div class="adjust-checkbox">
                    <mat-checkbox *ngIf="showMandatoryCpfCheckbox" (change)='cpfChangeValidator($event)'
                        formControlName="notMandatoryCpf" class="my-checkbox-field">
                        CPF não informado
                    </mat-checkbox>
                </div>
            </div>
            <div class="col-6 col-sm-3 col-md-2">
                <mat-form-field appearance="outline" class="my-form-field">
                    <mat-label>CNS</mat-label>
                    <input matInput type="text" formControlName="cns" (keyup)="onKeySearchCNS($event)"
                        [mask]="masks.cns">
                    <mat-error *ngIf="model.get('cns').invalid">CNS Inválido</mat-error>
                </mat-form-field>
                <div class="adjust-checkbox">
                    <mat-checkbox *ngIf="showMandatoryCnsCheckbox" (change)='cnsChangeValidator($event)'
                        formControlName="notMandatoryCns" class="my-checkbox-field">
                        CNS não informado
                    </mat-checkbox>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-3">
                <mat-form-field appearance="outline">
                    <mat-label>Prioridade</mat-label>
                    <mat-select formControlName="listIdPriority" multiple #prioritySelect
                        (selectionChange)="validatePriorities($event)">
                        <mat-option *ngFor="let item of listOfPriorities" [value]="item.idPriority"
                            [disabled]="disallowedIdPriorities.includes(item.idPriority)">{{item.priorityName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-6 col-sm-3 col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>Telefone</mat-label>
                    <input matInput type="text" formControlName="phone" [mask]="masks.phone">
                    <mat-error *ngIf="model.get('phone').invalid">Informe o Telefone</mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="title-header">
        <h1>Procedência</h1>
    </div>
    <div class="row">
        <div class="col-6 col-sm-3 col-md-4">
            <mat-form-field appearance="outline">
                <mat-label>Origem</mat-label>
                <mat-select formControlName="idOrigin">
                    <mat-option *ngFor="let item of originList" [value]="item.idOrigin">{{item.originName}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="model.get('idOrigin').invalid">Informe a Origem</mat-error>
            </mat-form-field>
        </div>
        <div class="col-6 col-sm-3 col-md-4">
            <mat-form-field appearance="outline">
                <mat-label>Tipo de chegada</mat-label>
                <mat-select formControlName="idArrivalType">
                    <mat-option *ngFor="let item of arrivalTypeList"
                        [value]="item.idArrivalType">{{item.arrivalTypeName}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="model.get('idArrivalType').invalid">Informe o Tipo de chegada</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div *ngIf="this.listMetaData != undefined && this.listMetaData.length > 0">
        <div class="title-header">
            <h1>Dados Adicionais</h1>
        </div>
        <div class="row">
            <div *ngFor="let item of listMetaData" class="col-12 col-sm-6 col-md-4">
                <mat-form-field *ngIf="item.metaData.idMetaDataType == metaDataTypeEnumResult.Texto"
                    appearance="outline">
                    <mat-label>{{item.metaData.metaDataName}}</mat-label>
                    <input matInput [readonly]="this.isUnidentifiedPatient"
                        formControlName="{{item.metaData.idMetaData}}" type="text">
                </mat-form-field>
                <mat-form-field *ngIf="item.metaData.idMetaDataType == metaDataTypeEnumResult.Numero"
                    appearance="outline">
                    <mat-label>{{item.metaData.metaDataName}}</mat-label>
                    <input matInput [readonly]="this.isUnidentifiedPatient"
                        formControlName="{{item.metaData.idMetaData}}" type="number">
                </mat-form-field>
                <mat-form-field *ngIf="item.metaData.idMetaDataType == metaDataTypeEnumResult.Data"
                    appearance="outline">
                    <mat-label>{{item.metaData.metaDataName}}</mat-label>
                    <input matInput inputmode="text" type="text" [mask]="masks.date" placeholder="dd/mm/aaaa"
                        [readonly]="this.isUnidentifiedPatient" [formControlName]="item.metaData.idMetaData"
                        (change)="verifyDate(item.metaData.idMetaData)">
                    <mat-error *ngIf="model.get(item.metaData.idMetaData.toString()).hasError('invalidDate')">Data
                        inválida</mat-error>
                </mat-form-field>
                <mat-form-field *ngIf="item.metaData.idMetaDataType == metaDataTypeEnumResult.Select"
                    appearance="outline">
                    <mat-label>{{item.metaData.metaDataName}}</mat-label>
                    <mat-select [disabled]="this.isUnidentifiedPatient" formControlName="{{item.metaData.idMetaData}}">
                        <mat-option *ngFor="let itemOption of item.listMetaDataOption"
                            [value]="itemOption.metaDataOptionName">{{itemOption.metaDataOptionName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <mat-form-field appearance="outline">
                    <mat-label>Observação</mat-label>
                    <textarea matInput type="text" formControlName="observation"></textarea>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="title-header">
    </div>
    <div class="row" *ngIf="this.isPreRegister">
        <div class="col-12 col-sm-6 col-md-8">
        </div>
        <div class="col-12 col-sm-3 col-md-2">
            <a mat-flat-button type="button" (click)="goBack()" color="accent" class=" btn-block">
                Voltar
            </a>
        </div>
        <div class="col-12 col-sm-3 col-md-2">
            <button mat-flat-button type="submit" color="primary" class=" btn-block">
                <span *ngIf="isLoading == false">Salvar</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
        </div>
    </div>
</form>