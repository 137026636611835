import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { ReportModalComponent } from 'src/app/shared/components/report-modal/report-modal.component';
import { MedicineTagEnum } from 'src/app/shared/enum/medicine-tag.enum';
import { MedicinePresentationTypeEnum } from 'src/app/shared/enum/pharmacy/medicine-presentation-type.enum';
import { TagUserEnum } from 'src/app/shared/enum/pharmacy/tag-user.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ItemService } from 'src/app/shared/services/API/pharmacy/Item.service';
import { LocationService } from 'src/app/shared/services/API/pharmacy/location.service';
import { MedicinePresentationTypeService } from 'src/app/shared/services/API/pharmacy/medicine-presentation-type.service';
import { MedicineTagUserService } from 'src/app/shared/services/API/pharmacy/medicine-tag-user.service';
import { MedicineTagService } from 'src/app/shared/services/API/pharmacy/medicine-tag.service';
import { MedicineService } from 'src/app/shared/services/API/pharmacy/medicine.service';
import { StorageService } from 'src/app/shared/services/API/pharmacy/storage.service';
import { PharmacyTagService } from 'src/app/shared/services/API/report/pharmacy-tag.service';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { ItemModel } from 'src/app/shared/services/models/pharmacy/item.model';
import { LocationModel } from 'src/app/shared/services/models/pharmacy/location.model';
import { MedicinePresentationTypeModel } from 'src/app/shared/services/models/pharmacy/medicine-presentation-type.model';
import { MedicineTagUser } from 'src/app/shared/services/models/pharmacy/medicine-tag-user.model';
import { MedicineTag } from 'src/app/shared/services/models/pharmacy/medicine-tag.model';
import { StorageRequest } from 'src/app/shared/services/requests/pharmacy/pharmacy.request';
import { PharmacyTagRequest } from 'src/app/shared/services/requests/Report/pharmacy-tag.request';
import { MedicineStruct } from 'src/app/shared/services/structs/pharmacy/medicine.struct';

@Component({
  selector: 'app-storage-register',
  templateUrl: './storage-register.component.html',
  styleUrls: ['./storage-register.component.css']
})
export class StorageRegisterComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private storageService: StorageService,
    private locationService: LocationService,
    private medicineService: MedicineService,
    private itemService: ItemService,
    public dialog: MatDialog,
    private pharmacyTagService: PharmacyTagService,
    private medicineTagUserService: MedicineTagUserService,
    private medicineTagService: MedicineTagService,
    private medicinePresetationTypeService: MedicinePresentationTypeService
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.pharmacy;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.pharmacy_storage;

  public model: FormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public listLocation: LocationModel[];
  public masks: Masks;
  public idStorage: number;
  public storageRequest: StorageRequest;
  public idStorageAmountLocation: number;
  public listMedicine: MedicineStruct[] = [];
  public selectedMedicineList: MedicineStruct[] = [];
  public selectedMedicine: MedicineStruct;
  public listItem: ItemModel[];
  public productType: number = null;
  public isUsedStorage: boolean;
  public medicineTagUser: MedicineTagUser;
  public listMedicineTag: MedicineTag[];
  public isOtherDimensionsSelected: boolean = false;
  public isVisualizeLoading: boolean = false;
  public listPresentationType: MedicinePresentationTypeModel[];
  public bottleEnum: MedicinePresentationTypeEnum = MedicinePresentationTypeEnum.Frasco;
  public isBottle: boolean = false;
  public tagUserEnumWidth: TagUserEnum = TagUserEnum.Largura;
  public tagUserEnumHeight: TagUserEnum = TagUserEnum.Altura;

  ngOnInit(): void {
    this.getMedicineTag();
    this.getLookupMedicine();
    this.getLocations();
    this.getItens();
    this.getLookupPresentationType();
    this.isLoading = false;
    this.storageRequest = new StorageRequest();

    this.model = this.formBuilder.group({
      amountTotal: ['', [Validators.required]],
      batch: ['', [Validators.required]],
      idItem: [''],
      idLocation: ['', [Validators.required]],
      dateExpiration: ['', [Validators.required]],
      idMedicine: [''],
      productType: ['', [Validators.required]],
      amountUsed: [],
      idMedicineTag: [, [Validators.required]],
      idMedicinePresentationType: [, [Validators.required]],
      width: [],
      height: [],
      numberDrops: [],
    });

    this.masks = this.maskService.getMasks();

    this.idStorage == null;
    if (this.activatedRoute.snapshot.paramMap.get('idStorage'))
      this.idStorage = parseInt(this.activatedRoute.snapshot.paramMap.get('idStorage'));

    if (this.idStorage != null) {
      this.isFirstLoading = true;
      this.populateStorageData();
    }

    this.getMedicineTagUser();
  }

  submit() {
    if (this.isLoading)
      return;

    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    if (this.isUsedStorage) {
      this.alertService.show('Erro', "Este estoque já sofreu movimentações e não pode ser atualizado.", AlertType.error);
      return;
    }

    this.isLoading = true;

    this.storageRequest.amountTotal = this.model.get('amountTotal').value;
    this.storageRequest.batch = this.model.get('batch').value;
    this.storageRequest.idItem = this.model.get('idItem').value;
    this.storageRequest.idLocation = this.model.get('idLocation').value;
    this.storageRequest.dateExpiration = this.maskService.formatStringToDate(this.model.get('dateExpiration').value);

    this.storageRequest.idMedicine = this.model.get('idMedicine').value;
    this.storageRequest.idMedicineTag = this.model.get('idMedicineTag').value;
    this.storageRequest.idMedicinePresentationType = this.model.get('idMedicinePresentationType').value;
    this.storageRequest.width = this.model.get('width').value;
    this.storageRequest.height = this.model.get('height').value;

    if (this.isBottle) {
      const amountTotal = this.model.get('amountTotal').value;
      const numberDropsControl = this.model.get('numberDrops');

      this.storageRequest.numberDrops = numberDropsControl.value;

      if (numberDropsControl.value < amountTotal)
        numberDropsControl.setErrors({ customError: true });
      else
        numberDropsControl.setErrors(null);
    }

    if (this.idStorage != null)
      this.updateStorage();
    else
      this.createStorage();
  }

  getLookupMedicine() {
    this.medicineService.listAllMedicine().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listMedicine = response.listMedicineStruct;
        this.selectedMedicineList = this.listMedicine;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getLookupPresentationType() {
    this.medicinePresetationTypeService.listMedicinePresentationType().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listPresentationType = response.listMedicinePresentationType;
        if (this.idStorage == null || this.idStorage == undefined)
          this.removePresentationType();
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  onChangeSelect({ value }) {
    let medicinePresentationTypeName = this.listPresentationType.find(c => c.idMedicinePresentationType == value).medicinePresentationTypeName;
    this.alertService.show("Aviso", `Você selecionou a a opção "${medicinePresentationTypeName}". A quantidade cadastrada deve ser realizada conforme a apresentação do medicamento selecionada.`, AlertType.warning);

    if (value == this.bottleEnum) {
      this.isBottle = true;
      this.model.get('numberDrops').setValidators([Validators.required]);
    }
    else {
      this.isBottle = false;
      this.model.get('numberDrops').clearValidators();
    }

    this.model.get('numberDrops').updateValueAndValidity();
  }

  populateStorageData() {
    this.storageService.getStorage(this.idStorage).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.idStorageAmountLocation = response.storage.storageAmountLocation?.idStorageAmountLocation;
        this.model.get('amountTotal').setValue(response.storage.storageAmountLocation?.amountTotal);
        this.model.get('batch').setValue(response.storage.batch);
        this.model.get('idItem').setValue(response.storage.idItem);
        this.model.get('idLocation').setValue(response.storage.storageAmountLocation?.idLocation);
        let dateExpiration = this.maskService.formatDateToString(response.storage.dateExpiration, false);
        this.model.get('dateExpiration').setValue(dateExpiration);
        this.model.get('idMedicine').setValue(response.storage.idMedicine);
        this.model.get('idMedicinePresentationType').setValue(response.storage.idMedicinePresentationType);
        this.model.get('productType').setValue(response.storage.idMedicine ? "1" : "2");
        this.isUsedStorage = response.storage.storageAmountLocation?.amountUsed != 0 ? true : false;
        this.onChangeType(response.storage.idMedicine ? 1 : 2);
        this.isLoading = false;
        this.isFirstLoading = false;

        if (this.bottleEnum === response.storage.idMedicinePresentationType) {
          if (response.storage.storageAmountLocation?.numberDrops !== null) {
            this.model.get('numberDrops').setValue(response.storage.storageAmountLocation?.numberDrops);
            this.model.get('numberDrops').setValidators([Validators.required]);
            this.model.get('numberDrops').updateValueAndValidity();
            this.isBottle = true;
          }
        }

        if (this.model.get('idMedicinePresentationType').value !== null)
          this.model.get('idMedicinePresentationType').disable();

        if (this.isUsedStorage) {
          this.model.get('amountUsed').setValue(response.storage.storageAmountLocation?.amountUsed);
          this.model.get('amountTotal').disable();
          this.model.get('batch').disable();
          this.model.get('dateExpiration').disable();
          this.model.get('amountUsed').disable();
        }
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  updateStorage() {
    this.storageService.updateStorage(this.idStorage, this.idStorageAmountLocation, this.storageRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.openModalReport(this.idStorage);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  createStorage() {
    this.storageService.createStorage(this.storageRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.openModalReport(response.storage.idStorage);
        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openModalReport(idStorage: number) {
    let request: PharmacyTagRequest = new PharmacyTagRequest();
    request.idStorage = idStorage;
    if (this.storageRequest.idMedicine)
      request.productName = this.listMedicine.find(c => c.medicine.idMedicine == this.storageRequest.idMedicine).medicine.medicineDescription;
    else
      request.productName = this.listItem.find(c => c.idItem == this.storageRequest.idItem).itemName;

    let dateExpiration = this.maskService.formatDateToString(this.storageRequest.dateExpiration, null, true);
    request.dateExpiration = dateExpiration;
    request.batch = this.storageRequest.batch;
    request.amountTotal = this.storageRequest.amountTotal;
    if(this.isOtherDimensionsSelected)
    {
      request.height = this.model.get('height').value;
      request.width = this.model.get('width').value;
    }
    else
    {
      request.height = this.tagUserEnumHeight;
      request.width = this.tagUserEnumWidth;
    }

    this.pharmacyTagService.getTags(request, 1).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        let reportDialog = this.dialog.open(ReportModalComponent, {
          data: {
            reportName: response.reportName,
            reportPdf64: response.reportPdf64,
          },
        });

        reportDialog.afterClosed().subscribe({
          next: result => {
            this.router.navigate(['/pharmacy/storage']);
          },
          error: (error) => {
            console.log(error);
          }
        });
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getLocations() {
    this.locationService.readActive(null).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listLocation = response.listLocation;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  onKey(value: any) {
    this.selectedMedicineList = this.search(value);
  }

  search(value: string) {
    let filter = value.toLowerCase();
    return this.listMedicine.filter(option => option.medicine.medicineDescription.toLowerCase().includes(filter));
  }

  searchMedicine() {
    this.model.get("idMedicinePresentationType").enable();
    this.selectedMedicine = this.listMedicine.find(option => option.medicine.idMedicine == this.model.get("idMedicine").value)
    if (this.selectedMedicine != null) {
      if (this.selectedMedicine != null) {
        this.model.get("idMedicinePresentationType").setValue(this.selectedMedicine.medicine.idMedicinePresentationType);
        this.model.get("idMedicinePresentationType").disable();

        if (this.model.get("idMedicinePresentationType").value === this.bottleEnum) {
          this.isBottle = true;
          this.model.get('numberDrops').setValidators([Validators.required]);
        }
        else {
          this.isBottle = false;
          this.model.get('numberDrops').clearValidators();
        }

        this.model.get('numberDrops').updateValueAndValidity();

        if (this.model.get("idMedicinePresentationType").value === this.bottleEnum) {
          this.isBottle = true;
          this.model.get('numberDrops').setValidators([Validators.required]);
        }
        else {
          this.isBottle = false;
          this.model.get('numberDrops').clearValidators();
        }

        this.model.get('numberDrops').updateValueAndValidity();
      }
    }
  }

  verifyListMedicine() {
    if (!this.selectedMedicineList || this.selectedMedicineList.length == 0)
      this.selectedMedicineList = this.listMedicine;
  }

  getItens() {
    this.itemService.listItem(null).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listItem = response.listItem;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getMedicineTagUser() {
    this.medicineTagUserService.getMedicineTagUser().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.medicineTagUser = response.medicineTagUser;

        if (response.medicineTagUser) {
          this.model.get('idMedicineTag').setValue(response.medicineTagUser.idMedicineTag);
          this.model.get('width').setValue(response.medicineTagUser.width);
          this.model.get('height').setValue(response.medicineTagUser.height);

          if (response.medicineTagUser.idMedicineTag == MedicineTagEnum.Outro)
            this.isOtherDimensionsSelected = true;
        }
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getMedicineTag() {
    this.medicineTagService.listMedicineTag().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listMedicineTag = response.listMedicineTag;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  onChangeType(event: number) {
    this.productType = event;
    if (this.productType == 1) {
      this.model.get('idMedicine').setValidators(Validators.required);
      this.model.get('idMedicinePresentationType').setValidators(Validators.required);
      this.model.get('idItem').setValidators(Validators.nullValidator);
      this.model.get('idItem').setValue(null);
    }
    else if (this.productType == 2) {
      this.model.get('idMedicine').setValue(null);
      this.model.get('idMedicinePresentationType').setValue(null);
      this.model.get('idItem').setValidators(Validators.required);
      this.model.get('idMedicine').setValidators(Validators.nullValidator);
      this.model.get('idMedicinePresentationType').setValidators(Validators.nullValidator);
    }

    this.model.get('idItem').updateValueAndValidity();
    this.model.get('idMedicine').updateValueAndValidity();
    this.model.get('idMedicinePresentationType').updateValueAndValidity();
  }

  changeDimensionsSelect(event) {
    if (event.value == MedicineTagEnum.Outro) {
      this.isOtherDimensionsSelected = true;
      this.model.get('width').setValidators(Validators.required);
      this.model.get('width').updateValueAndValidity();
      this.model.get('height').setValidators(Validators.required);
      this.model.get('height').updateValueAndValidity();
    }
    else {
      this.isOtherDimensionsSelected = false;
      this.model.get('width').setValidators(null);
      this.model.get('width').updateValueAndValidity();
      this.model.get('height').setValidators(null);
      this.model.get('height').updateValueAndValidity();
    }
  }

  visualizeTemplate() {
    this.isVisualizeLoading = true;
    if (!this.model.get('height').value || !this.model.get('width').value) {
      this.alertService.show('Erro', "Preencha a altura e largura para visualizar a pulseira.", AlertType.error);
      this.isVisualizeLoading = false;
      return;
    }

    let pharmacyTagRequest: PharmacyTagRequest = new PharmacyTagRequest();
    pharmacyTagRequest.height = this.model.get('height').value;
    pharmacyTagRequest.width = this.model.get('width').value;
    pharmacyTagRequest.idStorage = 999;
    pharmacyTagRequest.productName = "Etiqueta de teste";
    pharmacyTagRequest.dateExpiration = "2023-01-01";
    pharmacyTagRequest.batch = "30";
    pharmacyTagRequest.amountTotal = 30;

    this.pharmacyTagService.getTags(pharmacyTagRequest, 1).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isVisualizeLoading = false;
          return;
        }

        this.isVisualizeLoading = false;
        let reportDialog = this.dialog.open(ReportModalComponent, {
          data: {
            reportName: response.reportName,
            reportPdf64: response.reportPdf64,
          },
        });
      },
      error: (error) => {
        console.log(error);
        this.isVisualizeLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  removePresentationType() {
    this.listPresentationType = this.listPresentationType.filter(item => {
      return !(item.idMedicinePresentationType === MedicinePresentationTypeEnum.Gotas);
    });
  }
}