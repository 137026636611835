import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AgeRangeEnum } from 'src/app/shared/enum/age-range.enum';
import { ComorbidityEnum } from 'src/app/shared/enum/comorbidity.enum';
import { MedicineEnum } from 'src/app/shared/enum/medicine.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MedicineService } from 'src/app/shared/services/API/pharmacy/medicine.service';
import { CareLineService } from 'src/app/shared/services/API/sync-measurer/care-line.service';
import { CareLineLookupService } from 'src/app/shared/services/API/sync-measurer/lookup.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { CareLineRequest } from 'src/app/shared/services/requests/sync-measurer/care-line.request';
import { DeserializedAge } from 'src/app/shared/services/structs/sync-measurer/age-range.struct';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-care-line-register',
  templateUrl: './care-line-register.component.html',
  styleUrls: ['./care-line-register.component.css']
})
export class CareLineRegisterComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private medicineService: MedicineService,
    private careLineLookupService: CareLineLookupService,
    private careLineService: CareLineService,
    private utilService: UtilService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.master;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.master_care_line;

  public model: FormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;
  public actualServiceModule: number;

  public listCareLineType: any[];
  public listAgeRange: any[];
  public listMedicine: any[];
  public listComorbidity: any[];
  public isOtherAgeRange: boolean;
  public isOtherMedicine: boolean;
  public isOtherComorbidity: boolean;
  public idMedicineOutros: number;
  ip: string = '192.168.0.2';

  public masks: Masks;
  public idCareLine: number;
  public careLineRequest: CareLineRequest;

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.isLoading = false;
    this.isFirstLoading = true;
    this.listCareLineType = [];
    this.listAgeRange = [];
    this.listMedicine = [];
    this.listComorbidity = [];
    this.isOtherAgeRange = false;
    this.isOtherMedicine = false;
    this.isOtherComorbidity = false;
    this.idMedicineOutros = -1;
    this.model = this.formBuilder.group({
      careLineName: ['', [Validators.required]],
      careLineDescription: [''],
      isActive: [true, [Validators.required]],
      otherMedication: [''],
      otherComorbidity: [''],
      otherAgeRangeMin: [''],
      otherAgeRangeMax: [''],
      bloodPressureGreenMin: [null],
      bloodPressureGreenMax: [null],
      bloodPressureYellowMin: [null],
      bloodPressureYellowMax: [null],
      bloodPressureRedMin: [null],
      bloodPressureRedMax: [null],
      glucoseGreenMin: [null],
      glucoseGreenMax: [null],
      glucoseYellowMin: [null],
      glucoseYellowMax: [null],
      glucoseRedMin: [null],
      glucoseRedMax: [null],
      saturationGreenMin: [null],
      saturationGreenMax: [null],
      saturationYellowMin: [null],
      saturationYellowMax: [null],
      saturationRedMin: [null],
      saturationRedMax: [null],
      heartRateGreenMin: [null],
      heartRateGreenMax: [null],
      heartRateYellowMin: [null],
      heartRateYellowMax: [null],
      heartRateRedMin: [null],
      heartRateRedMax: [null],
      temperatureGreenMin: [null],
      temperatureGreenMax: [null],
      temperatureYellowMin: [null],
      temperatureYellowMax: [null],
      temperatureRedMin: [null],
      temperatureRedMax: [null],
      listIdCareLineType: [[], [Validators.required]],
      listIdAgeRange: [[], [Validators.required]],
      listIdMedication: [[]],
      listIdComorbidity: [[]],
    });

    this.populateMedicineSelect();
    this.populateCareLineTypeSelect();

    if (this.activatedRoute.snapshot.paramMap.get('idCareLine'))
      this.idCareLine = parseInt(this.activatedRoute.snapshot.paramMap.get('idCareLine'));

    if (this.idCareLine)
      this.populateCareLineData();

    setTimeout(() => { this.getIP(); }, 2000);
  }

  getIP() {
    this.utilService.getIP().subscribe({
      next: (response) => {
        if (response && response.ip) {
          this.ip = response.ip;
        }
      }
    });
  }

  submit() {
    this.validateFields();
    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    this.careLineRequest = new CareLineRequest();

    this.careLineRequest.careLineName = this.model.get('careLineName').value;
    this.careLineRequest.careLineDescription = this.model.get('careLineDescription').value;
    this.careLineRequest.isActive = this.model.get('isActive').value;
    this.careLineRequest.otherMedication = this.model.get('otherMedication').value;
    this.careLineRequest.otherComorbity = this.model.get('otherComorbidity').value;

    this.careLineRequest.otherAgeRangeMin = new DeserializedAge();
    if (this.model.get('otherAgeRangeMin').value) {
      let splitYearsMin = this.model.get('otherAgeRangeMin').value.split("a");
      let splitMonthMin = splitYearsMin[1].split("m");
      this.careLineRequest.otherAgeRangeMin.days = parseInt(splitMonthMin[1].replace("d"));
      this.careLineRequest.otherAgeRangeMin.months = parseInt(splitMonthMin[0]);
      this.careLineRequest.otherAgeRangeMin.years = parseInt(splitYearsMin[0]);
    }

    this.careLineRequest.otherAgeRangeMax = new DeserializedAge();
    if (this.model.get('otherAgeRangeMax').value) {
      let splitYearsMax = this.model.get('otherAgeRangeMax').value.split("a");
      let splitMonthMax = splitYearsMax[1].split("m");
      this.careLineRequest.otherAgeRangeMax.days = parseInt(splitMonthMax[1].replace("d"));
      this.careLineRequest.otherAgeRangeMax.months = parseInt(splitMonthMax[0]);
      this.careLineRequest.otherAgeRangeMax.years = parseInt(splitYearsMax[0]);
    }

    if (this.model.get('bloodPressureGreenMin').value
      && this.model.get('bloodPressureGreenMax').value
      && this.model.get('bloodPressureYellowMin').value
      && this.model.get('bloodPressureYellowMax').value
      && this.model.get('bloodPressureRedMin').value
      && this.model.get('bloodPressureRedMax').value) {
      let bloodPressureDiastoleGreenMin = this.formatBloodPressure(this.model.get('bloodPressureGreenMin').value)[1];
      let bloodPressureDiastoleGreenMax = this.formatBloodPressure(this.model.get('bloodPressureGreenMax').value)[1];
      let bloodPressureDiastoleYellowMin = this.formatBloodPressure(this.model.get('bloodPressureYellowMin').value)[1];
      let bloodPressureDiastoleYellowMax = this.formatBloodPressure(this.model.get('bloodPressureYellowMax').value)[1];
      let bloodPressureDiastoleRedMin = this.formatBloodPressure(this.model.get('bloodPressureRedMin').value)[1];
      let bloodPressureDiastoleRedMax = this.formatBloodPressure(this.model.get('bloodPressureRedMax').value)[1];
      let bloodPressureSystoleGreenMin = this.formatBloodPressure(this.model.get('bloodPressureGreenMin').value)[0];
      let bloodPressureSystoleGreenMax = this.formatBloodPressure(this.model.get('bloodPressureGreenMax').value)[0];
      let bloodPressureSystoleYellowMin = this.formatBloodPressure(this.model.get('bloodPressureYellowMin').value)[0];
      let bloodPressureSystoleYellowMax = this.formatBloodPressure(this.model.get('bloodPressureYellowMax').value)[0];
      let bloodPressureSystoleRedMin = this.formatBloodPressure(this.model.get('bloodPressureRedMin').value)[0];
      let bloodPressureSystoleRedMax = this.formatBloodPressure(this.model.get('bloodPressureRedMax').value)[0];
      this.careLineRequest.bloodPressureDiastoleGreenMin = bloodPressureDiastoleGreenMin;
      this.careLineRequest.bloodPressureDiastoleGreenMax = bloodPressureDiastoleGreenMax;
      this.careLineRequest.bloodPressureDiastoleYellowMin = bloodPressureDiastoleYellowMin;
      this.careLineRequest.bloodPressureDiastoleYellowMax = bloodPressureDiastoleYellowMax;
      this.careLineRequest.bloodPressureDiastoleRedMin = bloodPressureDiastoleRedMin;
      this.careLineRequest.bloodPressureDiastoleRedMax = bloodPressureDiastoleRedMax;
      this.careLineRequest.bloodPressureSystoleGreenMin = bloodPressureSystoleGreenMin;
      this.careLineRequest.bloodPressureSystoleGreenMax = bloodPressureSystoleGreenMax;
      this.careLineRequest.bloodPressureSystoleYellowMin = bloodPressureSystoleYellowMin;
      this.careLineRequest.bloodPressureSystoleYellowMax = bloodPressureSystoleYellowMax;
      this.careLineRequest.bloodPressureSystoleRedMin = bloodPressureSystoleRedMin;
      this.careLineRequest.bloodPressureSystoleRedMax = bloodPressureSystoleRedMax;
    }

    this.careLineRequest.glucoseGreenMin = parseFloat(this.model.get('glucoseGreenMin').value);
    this.careLineRequest.glucoseGreenMax = parseFloat(this.model.get('glucoseGreenMax').value);
    this.careLineRequest.glucoseYellowMin = parseFloat(this.model.get('glucoseYellowMin').value);
    this.careLineRequest.glucoseYellowMax = parseFloat(this.model.get('glucoseYellowMax').value);
    this.careLineRequest.glucoseRedMin = parseFloat(this.model.get('glucoseRedMin').value);
    this.careLineRequest.glucoseRedMax = parseFloat(this.model.get('glucoseRedMax').value);
    this.careLineRequest.saturationGreenMin = parseFloat(this.model.get('saturationGreenMin').value);
    this.careLineRequest.saturationGreenMax = parseFloat(this.model.get('saturationGreenMax').value);
    this.careLineRequest.saturationYellowMin = parseFloat(this.model.get('saturationYellowMin').value);
    this.careLineRequest.saturationYellowMax = parseFloat(this.model.get('saturationYellowMax').value);
    this.careLineRequest.saturationRedMin = parseFloat(this.model.get('saturationRedMin').value);
    this.careLineRequest.saturationRedMax = parseFloat(this.model.get('saturationRedMax').value);
    this.careLineRequest.heartRateGreenMin = parseFloat(this.model.get('heartRateGreenMin').value);
    this.careLineRequest.heartRateGreenMax = parseFloat(this.model.get('heartRateGreenMax').value);
    this.careLineRequest.heartRateYellowMin = parseFloat(this.model.get('heartRateYellowMin').value);
    this.careLineRequest.heartRateYellowMax = parseFloat(this.model.get('heartRateYellowMax').value);
    this.careLineRequest.heartRateRedMin = parseFloat(this.model.get('heartRateRedMin').value);
    this.careLineRequest.heartRateRedMax = parseFloat(this.model.get('heartRateRedMax').value);
    this.careLineRequest.temperatureGreenMin = this.formatTemperature(this.model.get('temperatureGreenMin').value);
    this.careLineRequest.temperatureGreenMax = this.formatTemperature(this.model.get('temperatureGreenMax').value);
    this.careLineRequest.temperatureYellowMin = this.formatTemperature(this.model.get('temperatureYellowMin').value);
    this.careLineRequest.temperatureYellowMax = this.formatTemperature(this.model.get('temperatureYellowMax').value);
    this.careLineRequest.temperatureRedMin = this.formatTemperature(this.model.get('temperatureRedMin').value);
    this.careLineRequest.temperatureRedMax = this.formatTemperature(this.model.get('temperatureRedMax').value);

    this.careLineRequest.listIdCareLineType = this.model.get('listIdCareLineType').value;
    this.careLineRequest.listIdAgeRange = this.model.get('listIdAgeRange').value;
    this.careLineRequest.listIdMedicine = this.model.get('listIdMedication').value;
    this.careLineRequest.listIdComorbidity = this.model.get('listIdComorbidity').value;

    this.careLineRequest.ip = this.ip;

    if (!this.idCareLine)
      this.createCareLine();
    else
      this.updateCareLine();
  }

  formatBloodPressure(bloodPressure: string): number[] {
    if (bloodPressure) {
      let pa: string[] = bloodPressure.split("/")[0].split("");
      if (pa.length < 4) {
        return [null, null]
      }
      let bloodPressureSystole = pa.length == 4 ? parseInt(pa[0] + pa[1]) : parseInt(pa[0] + pa[1] + pa[2]);
      let bloodPressureDiastole = pa.length == 4 ? parseInt(pa[2] + pa[3]) : pa.length == 5 ? parseInt(pa[3] + pa[4]) : parseInt(pa[3] + pa[4] + pa[5]);
      return [bloodPressureSystole, bloodPressureDiastole];
    }
    return [null, null]
  }

  formatTemperature(temperatura: string): number {
    return temperatura ? parseFloat(temperatura.split("")[0] + temperatura.split("")[1] + "." + temperatura.split("")[2]) : null;
  }

  validateFields() {
    if (this.model.get('bloodPressureGreenMin').value || this.model.get('bloodPressureGreenMax').value) {
      this.model.get('bloodPressureGreenMin').setValidators([Validators.required]);
      this.model.get('bloodPressureGreenMax').setValidators([Validators.required]);
      this.model.get('bloodPressureYellowMin').setValidators([Validators.required]);
      this.model.get('bloodPressureYellowMax').setValidators([Validators.required]);
      this.model.get('bloodPressureRedMin').setValidators([Validators.required]);
      this.model.get('bloodPressureRedMax').setValidators([Validators.required]);

      this.model.get('bloodPressureGreenMin').updateValueAndValidity();
      this.model.get('bloodPressureGreenMax').updateValueAndValidity();
      this.model.get('bloodPressureYellowMin').updateValueAndValidity();
      this.model.get('bloodPressureYellowMax').updateValueAndValidity();
      this.model.get('bloodPressureRedMin').updateValueAndValidity();
      this.model.get('bloodPressureRedMax').updateValueAndValidity();
    }
    else if (!this.model.get('bloodPressureGreenMin').value && !this.model.get('bloodPressureGreenMax').value) {
      this.model.get('bloodPressureGreenMin').setValidators(null);
      this.model.get('bloodPressureGreenMin').setValue(null);
      this.model.get('bloodPressureGreenMax').setValidators(null);
      this.model.get('bloodPressureGreenMax').setValue(null);
      this.model.get('bloodPressureYellowMin').setValidators(null);
      this.model.get('bloodPressureYellowMin').setValue(null);
      this.model.get('bloodPressureYellowMax').setValidators(null);
      this.model.get('bloodPressureYellowMax').setValue(null);
      this.model.get('bloodPressureRedMin').setValidators(null);
      this.model.get('bloodPressureRedMin').setValue(null);
      this.model.get('bloodPressureRedMax').setValidators(null);
      this.model.get('bloodPressureRedMax').setValue(null);

      this.model.get('bloodPressureGreenMin').updateValueAndValidity();
      this.model.get('bloodPressureGreenMax').updateValueAndValidity();
      this.model.get('bloodPressureYellowMin').updateValueAndValidity();
      this.model.get('bloodPressureYellowMax').updateValueAndValidity();
      this.model.get('bloodPressureRedMin').updateValueAndValidity();
      this.model.get('bloodPressureRedMax').updateValueAndValidity();
    }

    if (this.model.get('glucoseGreenMin').value || this.model.get('glucoseGreenMax').value) {
      this.model.get('glucoseGreenMin').setValidators([Validators.required]);
      this.model.get('glucoseGreenMax').setValidators([Validators.required]);
      this.model.get('glucoseYellowMin').setValidators([Validators.required]);
      this.model.get('glucoseYellowMax').setValidators([Validators.required]);
      this.model.get('glucoseRedMin').setValidators([Validators.required]);
      this.model.get('glucoseRedMax').setValidators([Validators.required]);

      this.model.get('glucoseGreenMin').updateValueAndValidity();
      this.model.get('glucoseGreenMax').updateValueAndValidity();
      this.model.get('glucoseYellowMin').updateValueAndValidity();
      this.model.get('glucoseYellowMax').updateValueAndValidity();
      this.model.get('glucoseRedMin').updateValueAndValidity();
      this.model.get('glucoseRedMax').updateValueAndValidity();
    }
    else if (!this.model.get('glucoseGreenMin').value && !this.model.get('glucoseGreenMax').value) {
      this.model.get('glucoseGreenMin').setValidators(null);
      this.model.get('glucoseGreenMin').setValue(null);
      this.model.get('glucoseGreenMax').setValidators(null);
      this.model.get('glucoseGreenMax').setValue(null);
      this.model.get('glucoseYellowMin').setValidators(null);
      this.model.get('glucoseYellowMin').setValue(null);
      this.model.get('glucoseYellowMax').setValidators(null);
      this.model.get('glucoseYellowMax').setValue(null);
      this.model.get('glucoseRedMin').setValidators(null);
      this.model.get('glucoseRedMin').setValue(null);
      this.model.get('glucoseRedMax').setValidators(null);
      this.model.get('glucoseRedMax').setValue(null);

      this.model.get('glucoseGreenMin').updateValueAndValidity();
      this.model.get('glucoseGreenMax').updateValueAndValidity();
      this.model.get('glucoseYellowMin').updateValueAndValidity();
      this.model.get('glucoseYellowMax').updateValueAndValidity();
      this.model.get('glucoseRedMin').updateValueAndValidity();
      this.model.get('glucoseRedMax').updateValueAndValidity();
    }

    if (this.model.get('saturationGreenMin').value || this.model.get('saturationGreenMax').value) {
      this.model.get('saturationGreenMin').setValidators([Validators.required]);
      this.model.get('saturationGreenMax').setValidators([Validators.required]);
      this.model.get('saturationYellowMin').setValidators([Validators.required]);
      this.model.get('saturationYellowMax').setValidators([Validators.required]);
      this.model.get('saturationRedMin').setValidators([Validators.required]);
      this.model.get('saturationRedMax').setValidators([Validators.required]);

      this.model.get('saturationGreenMin').updateValueAndValidity();
      this.model.get('saturationGreenMax').updateValueAndValidity();
      this.model.get('saturationYellowMin').updateValueAndValidity();
      this.model.get('saturationYellowMax').updateValueAndValidity();
      this.model.get('saturationRedMin').updateValueAndValidity();
      this.model.get('saturationRedMax').updateValueAndValidity();
    }
    else if (!this.model.get('saturationGreenMin').value && !this.model.get('saturationGreenMax').value) {
      this.model.get('saturationGreenMin').setValidators(null);
      this.model.get('saturationGreenMin').setValue(null);
      this.model.get('saturationGreenMax').setValidators(null);
      this.model.get('saturationGreenMax').setValue(null);
      this.model.get('saturationYellowMin').setValidators(null);
      this.model.get('saturationYellowMin').setValue(null);
      this.model.get('saturationYellowMax').setValidators(null);
      this.model.get('saturationYellowMax').setValue(null);
      this.model.get('saturationRedMin').setValidators(null);
      this.model.get('saturationRedMin').setValue(null);
      this.model.get('saturationRedMax').setValidators(null);
      this.model.get('saturationRedMax').setValue(null);

      this.model.get('saturationGreenMin').updateValueAndValidity();
      this.model.get('saturationGreenMax').updateValueAndValidity();
      this.model.get('saturationYellowMin').updateValueAndValidity();
      this.model.get('saturationYellowMax').updateValueAndValidity();
      this.model.get('saturationRedMin').updateValueAndValidity();
      this.model.get('saturationRedMax').updateValueAndValidity();
    }

    if (this.model.get('heartRateGreenMin').value || this.model.get('heartRateGreenMax').value) {
      this.model.get('heartRateGreenMin').setValidators([Validators.required]);
      this.model.get('heartRateGreenMax').setValidators([Validators.required]);
      this.model.get('heartRateYellowMin').setValidators([Validators.required]);
      this.model.get('heartRateYellowMax').setValidators([Validators.required]);
      this.model.get('heartRateRedMin').setValidators([Validators.required]);
      this.model.get('heartRateRedMax').setValidators([Validators.required]);

      this.model.get('heartRateGreenMin').updateValueAndValidity();
      this.model.get('heartRateGreenMax').updateValueAndValidity();
      this.model.get('heartRateYellowMin').updateValueAndValidity();
      this.model.get('heartRateYellowMax').updateValueAndValidity();
      this.model.get('heartRateRedMin').updateValueAndValidity();
      this.model.get('heartRateRedMax').updateValueAndValidity();
    }
    else if (!this.model.get('heartRateGreenMin').value && !this.model.get('heartRateGreenMax').value) {
      this.model.get('heartRateGreenMin').setValidators(null);
      this.model.get('heartRateGreenMin').setValue(null);
      this.model.get('heartRateGreenMax').setValidators(null);
      this.model.get('heartRateGreenMax').setValue(null);
      this.model.get('heartRateYellowMin').setValidators(null);
      this.model.get('heartRateYellowMin').setValue(null);
      this.model.get('heartRateYellowMax').setValidators(null);
      this.model.get('heartRateYellowMax').setValue(null);
      this.model.get('heartRateRedMin').setValidators(null);
      this.model.get('heartRateRedMin').setValue(null);
      this.model.get('heartRateRedMax').setValidators(null);
      this.model.get('heartRateRedMax').setValue(null);

      this.model.get('heartRateGreenMin').updateValueAndValidity();
      this.model.get('heartRateGreenMax').updateValueAndValidity();
      this.model.get('heartRateYellowMin').updateValueAndValidity();
      this.model.get('heartRateYellowMax').updateValueAndValidity();
      this.model.get('heartRateRedMin').updateValueAndValidity();
      this.model.get('heartRateRedMax').updateValueAndValidity();
    }

    if (this.model.get('temperatureGreenMin').value || this.model.get('temperatureGreenMax').value) {
      this.model.get('temperatureGreenMin').setValidators([Validators.required]);
      this.model.get('temperatureGreenMax').setValidators([Validators.required]);
      this.model.get('temperatureYellowMin').setValidators([Validators.required]);
      this.model.get('temperatureYellowMax').setValidators([Validators.required]);
      this.model.get('temperatureRedMin').setValidators([Validators.required]);
      this.model.get('temperatureRedMax').setValidators([Validators.required]);

      this.model.get('temperatureGreenMin').updateValueAndValidity();
      this.model.get('temperatureGreenMax').updateValueAndValidity();
      this.model.get('temperatureYellowMin').updateValueAndValidity();
      this.model.get('temperatureYellowMax').updateValueAndValidity();
      this.model.get('temperatureRedMin').updateValueAndValidity();
      this.model.get('temperatureRedMax').updateValueAndValidity();
    }
    else if (!this.model.get('temperatureGreenMin').value && !this.model.get('temperatureGreenMax').value) {
      this.model.get('temperatureGreenMin').setValidators(null);
      this.model.get('temperatureGreenMin').setValue(null);
      this.model.get('temperatureGreenMax').setValidators(null);
      this.model.get('temperatureGreenMax').setValue(null);
      this.model.get('temperatureYellowMin').setValidators(null);
      this.model.get('temperatureYellowMin').setValue(null);
      this.model.get('temperatureYellowMax').setValidators(null);
      this.model.get('temperatureYellowMax').setValue(null);
      this.model.get('temperatureRedMin').setValidators(null);
      this.model.get('temperatureRedMin').setValue(null);
      this.model.get('temperatureRedMax').setValidators(null);
      this.model.get('temperatureRedMax').setValue(null);

      this.model.get('temperatureGreenMin').updateValueAndValidity();
      this.model.get('temperatureGreenMax').updateValueAndValidity();
      this.model.get('temperatureYellowMin').updateValueAndValidity();
      this.model.get('temperatureYellowMax').updateValueAndValidity();
      this.model.get('temperatureRedMin').updateValueAndValidity();
      this.model.get('temperatureRedMax').updateValueAndValidity();
    }
  }

  createCareLine() {
    this.isLoading = true;
    this.careLineService.createCareLine(this.careLineRequest).subscribe({next:(response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.isLoading = false;
      this.router.navigate(['/master/care-line']);
    },
      error:(error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }});
  }

  updateCareLine() {
    this.isLoading = true;
    this.careLineService.updateCareLine(this.careLineRequest, this.idCareLine).subscribe({next:(response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.isLoading = false;
      this.router.navigate(['/master/care-line']);
    },
      error:(error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
  }});
  }

  populateCareLineData() {
    this.careLineService.getCareLine(this.idCareLine).subscribe({next:(response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.model.get('careLineName').setValue(response.getCareLineStruct.careLine.careLineName);
      this.model.get('careLineDescription').setValue(response.getCareLineStruct.careLine.careLineDescription);
      this.model.get('isActive').setValue(response.getCareLineStruct.careLine.isActive);
      this.model.get('otherMedication').setValue(response.getCareLineStruct.careLine.otherMedication);
      this.model.get('otherComorbidity').setValue(response.getCareLineStruct.careLine.otherComorbity);

      let minAge: DeserializedAge = response.getCareLineStruct.listAgeRange?.find(l => l.idAgeRange == AgeRangeEnum.Outro)?.minAge;
      let maxAge: DeserializedAge = response.getCareLineStruct.listAgeRange?.find(l => l.idAgeRange == AgeRangeEnum.Outro)?.maxAge;

      this.model.get('otherAgeRangeMin').setValue(this.calculaAge(minAge));
      this.model.get('otherAgeRangeMax').setValue(this.calculaAge(maxAge));

      this.model.get('bloodPressureGreenMin').setValue(response.getCareLineStruct.careLine.bloodPressureSystoleGreenMin?.toString().padStart(3, "0") + response.getCareLineStruct.careLine.bloodPressureDiastoleGreenMin?.toString().padStart(3, "0"));
      this.model.get('bloodPressureGreenMax').setValue(response.getCareLineStruct.careLine.bloodPressureSystoleGreenMax?.toString().padStart(3, "0") + response.getCareLineStruct.careLine.bloodPressureDiastoleGreenMax?.toString().padStart(3, "0"));
      this.model.get('bloodPressureYellowMin').setValue(response.getCareLineStruct.careLine.bloodPressureSystoleYellowMin?.toString().padStart(3, "0") + response.getCareLineStruct.careLine.bloodPressureDiastoleYellowMin?.toString().padStart(3, "0"));
      this.model.get('bloodPressureYellowMax').setValue(response.getCareLineStruct.careLine.bloodPressureSystoleYellowMax?.toString().padStart(3, "0") + response.getCareLineStruct.careLine.bloodPressureDiastoleYellowMax?.toString().padStart(3, "0"));
      this.model.get('bloodPressureRedMin').setValue(response.getCareLineStruct.careLine.bloodPressureSystoleRedMin?.toString().padStart(3, "0") + response.getCareLineStruct.careLine.bloodPressureDiastoleRedMin?.toString().padStart(3, "0"));
      this.model.get('bloodPressureRedMax').setValue(response.getCareLineStruct.careLine.bloodPressureSystoleRedMax?.toString().padStart(3, "0") + response.getCareLineStruct.careLine.bloodPressureDiastoleRedMax?.toString().padStart(3, "0"));
      this.model.get('glucoseGreenMin').setValue(response.getCareLineStruct.careLine.glucoseGreenMin);
      this.model.get('glucoseGreenMax').setValue(response.getCareLineStruct.careLine.glucoseGreenMax);
      this.model.get('glucoseYellowMin').setValue(response.getCareLineStruct.careLine.glucoseYellowMin);
      this.model.get('glucoseYellowMax').setValue(response.getCareLineStruct.careLine.glucoseYellowMax);
      this.model.get('glucoseRedMin').setValue(response.getCareLineStruct.careLine.glucoseRedMin);
      this.model.get('glucoseRedMax').setValue(response.getCareLineStruct.careLine.glucoseRedMax);
      this.model.get('saturationGreenMin').setValue(response.getCareLineStruct.careLine.saturationGreenMin);
      this.model.get('saturationGreenMax').setValue(response.getCareLineStruct.careLine.saturationGreenMax);
      this.model.get('saturationYellowMin').setValue(response.getCareLineStruct.careLine.saturationYellowMin);
      this.model.get('saturationYellowMax').setValue(response.getCareLineStruct.careLine.saturationYellowMax);
      this.model.get('saturationRedMin').setValue(response.getCareLineStruct.careLine.saturationRedMin);
      this.model.get('saturationRedMax').setValue(response.getCareLineStruct.careLine.saturationRedMax);
      this.model.get('heartRateGreenMin').setValue(response.getCareLineStruct.careLine.heartRateGreenMin);
      this.model.get('heartRateGreenMax').setValue(response.getCareLineStruct.careLine.heartRateGreenMax);
      this.model.get('heartRateYellowMin').setValue(response.getCareLineStruct.careLine.heartRateYellowMin);
      this.model.get('heartRateYellowMax').setValue(response.getCareLineStruct.careLine.heartRateYellowMax);
      this.model.get('heartRateRedMin').setValue(response.getCareLineStruct.careLine.heartRateRedMin);
      this.model.get('heartRateRedMax').setValue(response.getCareLineStruct.careLine.heartRateRedMax);
      this.model.get('temperatureGreenMin').setValue(response.getCareLineStruct.careLine.temperatureGreenMin ? response.getCareLineStruct.careLine.temperatureGreenMin.toString().replace(".", "") : null);
      this.model.get('temperatureGreenMax').setValue(response.getCareLineStruct.careLine.temperatureGreenMax ? response.getCareLineStruct.careLine.temperatureGreenMax.toString().replace(".", "") : null);
      this.model.get('temperatureYellowMin').setValue(response.getCareLineStruct.careLine.temperatureYellowMin ? response.getCareLineStruct.careLine.temperatureYellowMin.toString().replace(".", "") : null);
      this.model.get('temperatureYellowMax').setValue(response.getCareLineStruct.careLine.temperatureYellowMax ? response.getCareLineStruct.careLine.temperatureYellowMax.toString().replace(".", "") : null);
      this.model.get('temperatureRedMin').setValue(response.getCareLineStruct.careLine.temperatureRedMin ? response.getCareLineStruct.careLine.temperatureRedMin.toString().replace(".", "") : null);
      this.model.get('temperatureRedMax').setValue(response.getCareLineStruct.careLine.temperatureRedMax ? response.getCareLineStruct.careLine.temperatureRedMax.toString().replace(".", "") : null);

      this.model.get('listIdCareLineType').setValue(response.getCareLineStruct.listCareLineType.map(l => l.idCareLineType.toString()));
      this.model.get('listIdAgeRange').setValue(response.getCareLineStruct.listAgeRange.map(l => l.idAgeRange.toString()));
      this.model.get('listIdComorbidity').setValue(response.getCareLineStruct.listIdCareLineComorbity.map(l => l.idComorbidity.toString()));
      this.model.get('listIdMedication').setValue(response.getCareLineStruct.listIdCareLineMedicine.map(l => l.idMedicine.toString()));

      this.checkOutros(['AgeRangeMin']);
      this.checkOutros(['AgeRangeMax']);
      this.checkOutros(['Medication']);
      this.checkOutros(['Comorbidity']);
    },
      error:(error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }});
  }

  calculaAge(age: DeserializedAge): string {
    if (!age)
      return "";
    let years = age.years.toString().padStart(3, '0');
    let months = age.months.toString().padStart(2, '0');
    let days = age.days.toString().padStart(2, '0');

    return years + "a" + months + "m" + days + "d";
  }

  populateMedicineSelect() {
    this.medicineService.listAllMedicine().subscribe({next:(response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }

      this.listMedicine = response.listMedicineStruct;
      this.isFirstLoading = false;
    },
      error:(error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }});
  }

  populateCareLineTypeSelect() {
    this.careLineLookupService.listLookup().subscribe({next:(response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);

        return;
      }

      this.listCareLineType = response.listCareLineType;
      this.listAgeRange = response.listAgeRange;
      this.listComorbidity = response.listComorbidity;
    },
      error:(error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }});
  }

  checkOutros(name: string[]) {
    name.forEach(element => {
      let haveOther: boolean;
      let singleElement = (element == 'AgeRangeMin' || element == 'AgeRangeMax') ? 'AgeRange' : element;

      let listId = this.model.get(`listId${singleElement}`)?.value ?? [];

      if (element == 'Comorbidity')
        haveOther = listId.filter(x => x == ComorbidityEnum.outros).length > 0;

      if (element == 'AgeRangeMin' || element == 'AgeRangeMax')
        haveOther = listId.filter(x => x == AgeRangeEnum.Outro).length > 0;

      if (element == 'Medication')
        haveOther = listId.filter(x => x == MedicineEnum.otherMedicine).length > 0;

      if (haveOther) {
        this.model.get(`other${element}`)?.setValidators([Validators.required]);
        this.model.get(`other${element}`)?.updateValueAndValidity();
      }
      else {
        this.model.get(`other${element}`)?.setValue('');
        this.model.get(`other${element}`)?.setValidators(null);
        this.model.get(`other${element}`)?.updateValueAndValidity();
      }

      if (element == 'Comorbidity')
        this.isOtherComorbidity = haveOther;
      if (element == 'AgeRangeMin' || element == 'AgeRangeMax')
        this.isOtherAgeRange = haveOther;
      if (element == 'Medication')
        this.isOtherMedicine = haveOther;
    });
  }

  validateAge(value) {
    if (!value) {
      return;
    }
    if (!value.includes("a") || !value.includes("m") || !value.includes("d")) {
      this.alertService.show('Erro', "O padrão do campo idade é 010(a)nos 01(m)eses 02(d)ias.", AlertType.warning);
      return false;
    }
    let splitYears = value.split("a");
    let splitMonth = splitYears[1].split("m");
    let days: string = splitMonth[1].replace("d");
    let month: string = splitMonth[0];
    let years: string = splitYears[0];
    if (parseInt(days) > 29) {
      this.alertService.show('Erro', "O valor máximo de dias do campo idade é 29, após deve ser adicionado no mês!", AlertType.error);
      return false;
    }
    if (parseInt(month) > 11) {
      this.alertService.show('Erro', "O valor máximo de meses do campo idade é 11, após deve ser adicionado no ano!", AlertType.error);
      return false;
    }
    return true;
  }
}