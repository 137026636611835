import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { SearchPatientTotemRequest } from '../../requests/orchestrator-totem/search-patient-totem.request';
import { ListPatientStructResponse } from '../../responses/medical-record/list-patient-struct-response';

@Injectable({
  providedIn: 'root'
})
export class SearchPatientFrontdeskService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public searchPatientCpfCns(request: SearchPatientTotemRequest): Observable<ListPatientStructResponse> {
    let uri = `SearchPatientFrontdesk`
    return this.httpClient.post<ListPatientStructResponse>(environment.urlApiOrchestratorPatient + uri, request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
