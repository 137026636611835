import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ListWristbandDimensionsResponse } from '../../responses/front-desk/list-wristband-dimensions.reponse';

@Injectable({
  providedIn: 'root'
})
export class WristbandDimensionsService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listWristbandDimensions(): Observable<ListWristbandDimensionsResponse>{
    let uri = `WristbandDimensions`;  
    
    return this.httpClient.get<ListWristbandDimensionsResponse>(environment.urlApiFrontDesk + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
