import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { AuditCardUpdateControlResponse } from '../../responses/audit/audit-card-update-control.response';

@Injectable({
  providedIn: 'root'
})
export class AuditCardUpdateControlService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  public getAuditCardUpdateControl(idClassificationRiskAudit: number): Observable<AuditCardUpdateControlResponse> {

    let uri = `AuditCardUpdateControl/idClassificationRiskAudit/${idClassificationRiskAudit}`;

    return this.httpClient.get<AuditCardUpdateControlResponse>(environment.urlApiAudit + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}