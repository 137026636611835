<div *ngIf="!onlineOffline || socketDisconneted" class="offline">

    <span>
        <mat-icon class="offline--icon">wifi_off</mat-icon>
        {{socketDisconneted?"A função de atualização automática está fora do ar.":"Você esta sem conexão com a internet
        no momento."}}
    </span>
</div>
<div class="row" [ngClass]="{'content-padding-top': !onlineOffline || socketDisconneted}">
    <div class="col-5">
        <div><span class="title-txt">ÚLTIMOS CHAMADOS:</span>
        </div>
        <div *ngIf="mainClient != null" class="history-content">
            <div class="client-info">
                <span *ngIf="mainClient.namePacient == null" class="client-info__name">
                    Senha <b>{{mainClient.ticket}}</b>
                </span>
                 <span *ngIf="mainClient.namePacient != null" class="client-info__name">
                    Senha <b>{{mainClient.ticket}}</b>
                 </span>
                 <span *ngIf="mainClient.namePacient != null" class="client-info__bar">
                    |
                 </span>
                 <span *ngIf="mainClient.namePacient != null" class="text-info-color">
                    {{mainClient.namePacient}}
                 </span>
            </div>
            <span class="sector-info">
                {{ mainClient?.nameSector + ' ' + mainClient?.nameRoom}}
            </span>
        </div>
        <div *ngFor="let client of historicalList; let i=index" class="call">
            <div [ngClass]="{'history-call': i < 4}">
                <div *ngIf="client != null" class="history-content-client">
                    <div class="client-info">
                        <span *ngIf="client.namePacient == null" class="client-info__name">
                            Senha <b>{{client.ticket}}</b>
                        </span>
                         <span *ngIf="client.namePacient != null" class="client-info__name">
                            Senha <b>{{client.ticket}}</b>
                         </span>
                         <span *ngIf="client.namePacient != null" class="client-info__bar">
                            |
                         </span>
                         <span *ngIf="client.namePacient != null" class="text-info-color">
                            {{client.namePacient}}
                         </span>
                    </div>
                    <span class="sector-info">
                        {{ client?.nameSector + ' ' + client?.nameRoom}}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="col-7 overflow-screen">
        <div *ngIf="videoDisplay">
            <video id="video" [muted]="'muted'" autoplay loop width="100%" height="100%">
                <source type="video/mp4" [src]="srcVideo">
            </video>
        </div>
    </div>
</div>
<footer class="col-12">
    <img *ngIf="this.logoString64" class="brand" [src]="this.sanitezedLogo">
    <img class="brand col-1" src="assets/images/logo-branco.png">
    <span *ngIf="showAverageWaitingTime" class="col-6 personalized-waiting-hours"><span class="vertical-line"></span>
        MÉDIA DE ESPERA - TRIAGEM: {{lastHourWaitingTimeAverage}} <span class="text-last-hour">(última
            hora)</span></span>
    <div class="info col-4">
        <span class="date-time">
            <mat-icon>calendar_today</mat-icon>{{date}}
        </span>
        <span class="date-time">
            <mat-icon>schedule</mat-icon>{{time}}
        </span>
    </div>
</footer>