import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { MedicalScheduleRequest } from '../../requests/form/medical-schedule.request';
import { GetMedicalScheduleResponse } from '../../responses/form/get-medical-schedule.response';

@Injectable({
  providedIn: 'root'
})
export class MedicalScheduleService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getMedicalSchedule(idMedicalSchedule: number) : Observable<GetMedicalScheduleResponse>{
    let uri = `MedicalSchedule/idMedicalSchedule/${idMedicalSchedule}`
    
    return this.httpClient.get<GetMedicalScheduleResponse>(environment.urlApiForm + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public deleteMedicalSchedule(idMedicalSchedule: number) : Observable<ReturnStruct>{
    let uri = `MedicalSchedule/idMedicalSchedule/${idMedicalSchedule}`
    
    return this.httpClient.delete<ReturnStruct>(environment.urlApiForm + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public createMedicalSchedule(medicalScheduleRequest: MedicalScheduleRequest) : Observable<ReturnStruct>{
    let uri = `MedicalSchedule`
    
    return this.httpClient.post<ReturnStruct>(environment.urlApiForm + uri, medicalScheduleRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public updateMedicalSchedule(medicalScheduleRequest: MedicalScheduleRequest, idMedicalSchedule: number) : Observable<ReturnStruct>{
    let uri = `MedicalSchedule/idMedicalSchedule/${idMedicalSchedule}`
    
    return this.httpClient.put<ReturnStruct>(environment.urlApiForm + uri, medicalScheduleRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}

