import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { AlertService, AlertType } from "src/app/shared/services/alert.service";
import { Router } from '@angular/router';
import { VerifyCPF } from "src/app/shared/custom-validators/cpf.validator";
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { CpfNotFoundModalComponent } from "./cpf-not-found-modal/cpf-not-found-modal.component";
import { SearchPatientTotemRequest } from "src/app/shared/services/requests/orchestrator-totem/search-patient-totem.request";
import { SearchPatientFrontdeskService } from "src/app/shared/services/API/orchestrator-patient/search-patient-frontdesk.service";
import { ClientGetPatientByCpfTotemService } from "src/app/shared/services/API/orchestrator-totem/client-get-patient-by-cpf-totem.service";

@Component({
  selector: 'app-cpf-nps-totem',
  templateUrl: './cpf-nps-totem.component.html',
  styleUrls: ['./cpf-nps-totem.component.css']
})
export class CpfNpsTotemComponent implements OnInit {
  @Input() isLoading: boolean;
  @Input() idHealthUnit: number;
  @Input() idTotem: number;

  @Output() findCpf: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    private maskService: MaskService,
    private clientGetPatientByCpfTotemService: ClientGetPatientByCpfTotemService,
  ) { }

  public model: FormGroup;
  public masks: Masks;

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      cpf: ['', [Validators.required, VerifyCPF()]],
    });
  }

  submit() {

    if (this.isLoading)
      return;

    if(this.model.invalid) {
      this.alertService.show('Erro', "Preencha os campos!", AlertType.error);
      return;
    }
      
    this.isLoading = true;
      
    let request: SearchPatientTotemRequest = new SearchPatientTotemRequest();
    let cpf = this.model.get('cpf').value.replace(/[^0-9]/g, '').replace(' ', '');

    request.cpf = cpf;
    this.clientGetPatientByCpfTotemService.getClientPatientByCPFNps(this.idHealthUnit, request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.openCpfNotFound();
        }
        else {
          this.findCpf.emit({find: true, cpf: cpf});
        }
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
    

  openCpfNotFound() {
    const dialogRef = this.dialog.open(CpfNotFoundModalComponent, {
      data: {},
      panelClass: "totem-nps-modal"
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if(!result) {
          let urlBaseTotem: string = this.router.url.substring(1);
          this.router.navigateByUrl(urlBaseTotem);
        }

      }
    });
    this.isLoading = false;
  }
}