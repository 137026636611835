
import { MedicineModel } from "../../models/pharmacy/medicine.model";
import { ProductCodeStruct } from "./product-code.struct";
import { StorageAmountLocationStruct } from "./storage-amount-location.struct";

export class StorageStruct {

    public idStorage: number;

    public idMedicine: number;

    public idItem: number;

    public batch: number;

    public dateExpiration: Date;

    public dateTimeInclusion: Date;

    public idHealthUnit: number;

    public isDeleted: boolean;

    public storageAmountLocation: StorageAmountLocationStruct;

    public productCode: ProductCodeStruct;

    public idMedicinePresentationType: number;
    
    public medicine: MedicineModel;
}