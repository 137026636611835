<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-confirm-modal-list">
            <h2>Relatórios:</h2>
        </div>
        <div class="footer-confirm-modal-list">
            <div class="row" *ngIf="medicalCareReportString64">
                <button mat-flat-button color="primary" class="btn-block" (click)="clickReport(medicalCareReportString64)">Atendimento Médico</button>
            </div>
            <div class="row" *ngIf="sickNoteReportString64">
                <button mat-flat-button color="primary" class="btn-block" (click)="clickReport(sickNoteReportString64)">Atestado</button>
            </div>
            <div class="row" *ngIf="sadtReportString64">
                <button mat-flat-button color="primary" class="btn-block" (click)="clickReport(sadtReportString64)">Exames</button>
            </div>
            <div class="row" *ngIf="prescriptionReportString64">
                <button mat-flat-button color="primary" class="btn-block" (click)="clickReport(prescriptionReportString64)">Prescrições Internas</button>
            </div>
            <div class="row" *ngIf="externalPrescriptionReportString64">
                <button mat-flat-button color="primary" class="btn-block" (click)="clickReport(externalPrescriptionReportString64)">Prescrições Externas</button>
            </div>
            <div class="row" *ngIf="isolationReportString64">
                <button mat-flat-button color="primary" class="btn-block" (click)="clickReport(isolationReportString64)">Isolamento</button>
            </div>
            <div class="row" *ngIf="isolationAcknowledgeReportString64">
                <button mat-flat-button color="primary" class="btn-block" (click)="clickReport(isolationAcknowledgeReportString64)">Ciência de Isolamento</button>
            </div>
            <div class="row">
                <button mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Fechar</button>
            </div>
        </div>
    </div>
</div>