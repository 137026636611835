import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-prescricption-check-history-modal',
  templateUrl: './prescricption-check-history-modal.component.html',
  styleUrls: ['./prescricption-check-history-modal.component.css']
})
export class PrescricptionCheckHistoryModalComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<PrescricptionCheckHistoryModalComponent>,
    private formBuilder: UntypedFormBuilder,
  ) { }

  public listMedicalPrescriptionCheck: [];

  ngOnInit(): void {
    this.listMedicalPrescriptionCheck = this.data.listMedicalPrescriptionCheck;
  }

  close() {
    this.matDialogRef.close();
  }
}
