import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { SadtAttendPatientComponent } from './pages/sadt-attend-patient/sadt-attend-patient.component';
import { SadtPatientListComponent } from './pages/sadt-patient-list/sadt-patient-list.component';
import { SadtComponent } from './pages/sadt/sadt.component';
import { ExamListComponent } from './pages/exam/exam-list/exam-list.component';
import { ExamRegisterComponent } from './pages/exam/exam-register/exam-register.component';
import { ExamTypeListComponent } from './pages/exam/exam-type-list/exam-type-list.component';
import { ExamTypeRegisterComponent } from './pages/exam/exam-type-register/exam-type-register.component';

const routes: Routes = [
  { path: 'sadt', component: SadtComponent, canActivate: [AuthGuard] },
  { path: 'sadt/sadt-patient-list', component: SadtPatientListComponent, canActivate: [AuthGuard] },
  { path: 'sadt/sadt-attend-patient', component: SadtAttendPatientComponent, canActivate: [AuthGuard] },
  { path: 'sadt/exam', component: ExamListComponent, canActivate: [AuthGuard] },
  { path: 'sadt/exam/register', component: ExamRegisterComponent, canActivate: [AuthGuard] },
  { path: 'sadt/exam-type', component: ExamTypeListComponent, canActivate: [AuthGuard] },
  { path: 'sadt/exam-type/register', component: ExamTypeRegisterComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SADTRoutingModule { }
