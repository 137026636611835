import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { AuditMonthlyReportService } from 'src/app/shared/services/API/orchestrator-audit/audit-monthly-report.service';
import { AuditMonthlyReportRequest } from 'src/app/shared/services/requests/orchestrator-audit/audit-monthly-report.request';
import { AuditMonthlyReportResponse } from 'src/app/shared/services/responses/orchestrator-audit/get-audit-monthly-report.response';

@Component({
  selector: 'app-monthly-report-register',
  templateUrl: './monthly-report-register.component.html',
  styleUrls: ['./monthly-report-register.component.css']
})

export class MonthlyReportRegisterComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private auditMonthlyReportService: AuditMonthlyReportService,
    private alertService: AlertService,
    private router: Router) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.audit;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.audit_monthly_report;
  public listDate: Date[];
  public listDays: number[];

  public isMonthSelected: boolean;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;
  public generateReportRequest: AuditMonthlyReportRequest;

  public monthlyReport: AuditMonthlyReportResponse;

  public model: FormGroup;

  ngOnInit(): void {
    this.isLoading = false;
    this.populateDateSelect();
    this.generateReportRequest = new AuditMonthlyReportRequest;
    this.model = this.formBuilder.group({
      yearMonth: [, [Validators.required]],
      initialDate: [, [Validators.required]],
      finalDate: [, [Validators.required]],
      commentary: [''],
      conclusion: ['']
    });
  }

  submit() {
    if (this.isLoading)
      return;

    this.isLoading = true;

    this.isLoading = true;
    let year = this.model.get('yearMonth').value.getFullYear();
    let month = this.model.get('yearMonth').value.getMonth();

    let beginPeriod = new Date(Date.UTC(year, month, this.model.get('initialDate').value, 0, 0, 1));
    let endPeriod = new Date(Date.UTC(year, month, this.model.get('finalDate').value, 23, 59, 59));

    this.auditMonthlyReportService.getMonthlyReport(beginPeriod, endPeriod).subscribe({
      next: (response) => {
        this.isLoading = false;
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.alertService.show('Sucesso', "Relatório gerado com sucesso!", AlertType.success);
        this.router.navigate(['/audit/audit-monthly-report']);
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  clearFinalDayValue(initialDay: number) {
    if (this.model.get('finalDate').value <= initialDay) {
      this.model.get('finalDate').setValue(null);
      this.model.get('finalDate').markAsUntouched();
      this.model.get('finalDate').markAsPristine();
    }
  }

  populateDateSelect() {
    this.listDate = [];
    let todayDate = new Date();
    let year = todayDate.getFullYear();
    let month = todayDate.getMonth();
    for (let _i = 0; _i < 60; _i++) {
      this.listDate.push(new Date(year, month - _i));
    }
  }

  setDays(yearMonth: Date) {
    this.model.get('finalDate').setValue(null);
    this.model.get('finalDate').markAsUntouched();
    this.model.get('finalDate').markAsPristine();
    this.model.get('initialDate').setValue(null);
    this.model.get('initialDate').markAsUntouched();
    this.model.get('initialDate').markAsPristine();

    this.listDays = [];
    
    let month: number = new Date(yearMonth).getMonth();
    let year: number = new Date(yearMonth).getFullYear();
    let longMonth: number[] = [0, 2, 4, 6, 7, 9, 11];
    let shortMonth: number[] = [3, 5, 8, 10];

    if (longMonth.indexOf(month) > -1)
      this.listDays = Array(31).fill(0).map((x, i) => i + 1);
    else if (shortMonth.indexOf(month) > -1)
      this.listDays = Array(30).fill(0).map((x, i) => i + 1);
    else
      this.listDays = ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0) ? Array(29).fill(0).map((x, i) => i + 1) : Array(28).fill(0).map((x, i) => i + 1);

    this.isMonthSelected = true;
  }
}