import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AihStatusEnum } from 'src/app/shared/enum/aih/aih-status.enum';
import { AihStruct } from 'src/app/shared/services/structs/hospital-admission-authorization/aih.struct';

@Component({
  selector: 'app-aih-modal',
  templateUrl: './aih-modal.component.html',
  styleUrls: ['./aih-modal.component.css']
})
export class AihModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<AihModalComponent>,
  ) {
    this.matDialogRef.disableClose = true;
  }
  public confirm: boolean = false;
  public aihStruct: AihStruct;
  ngOnInit(): void {
    this.mapToDefaultAIHStruct();
  }
  
  clickConfirm(){
    this.matDialogRef.close({ isAihRedirect: true, aihStruct: this.aihStruct});
    return;
  }

  clickCancel(){
    this.matDialogRef.close({ isAihRedirect: false, aihStruct: this.aihStruct}); 
  }

  mapToDefaultAIHStruct(){
    this.aihStruct = new AihStruct();
    this.aihStruct.idAihStatus = AihStatusEnum.pendente;
    this.aihStruct.idEpisode = this.data.idEpisode;
  }
}