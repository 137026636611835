import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LookupMedicalCareService } from 'src/app/shared/services/API/medical-record/lookup-medical-care.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { FavoriteNonStandardMedicineModel } from 'src/app/shared/services/models/medic/favorite-non-standard-medicine.model';
import { ReadExternalPrescriptionStruct } from 'src/app/shared/services/structs/orchestrator-patient/read-external-prescription.struct';

@Component({
  selector: 'app-add-nonstandard-medicine-modal',
  templateUrl: './add-nonstandard-medicine-modal.component.html',
  styleUrls: ['./add-nonstandard-medicine-modal.component.css']
})
export class AddNonstandardMedicineModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<AddNonstandardMedicineModalComponent>,
    private alertService: AlertService,
    public lookupMedicalCareService: LookupMedicalCareService,
    private formBuilder: FormBuilder,
  ) { }

  public model: FormGroup;

  public isLoading: boolean;
  public medicalPrescription: ReadExternalPrescriptionStruct;

  ngOnInit(): void {
    this.medicalPrescription = this.data.prescription;

    this.model = this.formBuilder.group({
      quantity: [, Validators.required],
      description: [, Validators.required],
      observation: [],
      frequency: [, Validators.required],
      idMeasurementUnit: [],
    });

    if (this.medicalPrescription)
      this.populateValues();
  }

  submit() {
    if (this.model.invalid) {
      this.alertService.show('Erro', "Preencha todos os campos obrigatórios!", AlertType.error);
      return;
    }

    let favoriteMedicineModel: FavoriteNonStandardMedicineModel = new FavoriteNonStandardMedicineModel();
    favoriteMedicineModel.frequency = this.model.get('frequency').value;
    favoriteMedicineModel.description = this.model.get('description').value;
    favoriteMedicineModel.quantity = this.model.get('quantity').value;
    favoriteMedicineModel.idMeasurementUnit = this.model.get('idMeasurementUnit').value;
    favoriteMedicineModel.measurementUnitName = this.model.get('idMeasurementUnit').value ? this.data.lookupMedicalCareResponse.listMeasurementUnit.find(x => x.idMeasurementUnit == this.model.get('idMeasurementUnit').value).measurementUnitName : null;
    this.matDialogRef.close({ favoriteMedicineModel: favoriteMedicineModel });
  }

  populateValues() {
    this.model.get('frequency').setValue(this.medicalPrescription.frequency);
    this.model.get('description').setValue(this.medicalPrescription.description);
    this.model.get('quantity').setValue(this.medicalPrescription.quantity);
    this.model.get('observation').setValue(this.medicalPrescription.observation);
    this.model.get('idMeasurementUnit').setValue(this.medicalPrescription.idMeasurementUnit);
  }

  clickCancel() {
    this.matDialogRef.close();
  }
}