import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { DateAfterTodayValidator } from 'src/app/shared/custom-validators/date.validator';
import { EpisodeStatusEnum } from 'src/app/shared/enum/episode-status.enum';
import { FowardDestinationEnum } from 'src/app/shared/enum/foward-destination.enum';
import { MedicRequiredFieldEnum } from 'src/app/shared/enum/medic-required-field.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MedicConfigSectorService } from 'src/app/shared/services/API/medic/medic-config-sector.service';
import { LookupMedicalCareService } from 'src/app/shared/services/API/medical-record/lookup-medical-care.service';
import { MedicalRecordService } from 'src/app/shared/services/API/orchestrator-patient/medical-record.service';
import { ClockService } from 'src/app/shared/services/API/orchestrator-queue/clock.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { AllergyModel } from 'src/app/shared/services/models/medical-record/allergy.model';
import { ExamTypeModel } from 'src/app/shared/services/models/SADT/exam-type.model';
import { LookupMedicalCareResponse } from 'src/app/shared/services/responses/medical-record/lookup-medical-care-response';
import { MedicConfigSectorStruct } from 'src/app/shared/services/structs/medic/medic-config-sector.struct';
import { SadtGroupStruct } from 'src/app/shared/services/structs/medical-record/sadt-group.struct';
import { CompleteMedicalCareStruct } from 'src/app/shared/services/structs/orchestrator-patient/complete-medical-care.struct';
import { EpisodeHistorieStruct } from 'src/app/shared/services/structs/orchestrator-patient/episodeHistorie.struct';
import { ReadExternalPrescriptionStruct } from 'src/app/shared/services/structs/orchestrator-patient/read-external-prescription.struct';
import { TriageStruct } from 'src/app/shared/services/structs/orchestrator-patient/triage.struct';
import { ExamStruct } from 'src/app/shared/services/structs/SADT/exam.struct';
import { UpdateExternalPrescriptionModalComponent } from '../attend-patient/update-external-prescription-modal/update-external-prescription-modal.component';
import { ExternalPrescriptionModalComponent } from 'src/app/shared/components/external-prescription-modal/external-prescription-modal.component';
import { SadtModalComponent } from 'src/app/shared/components/sadt-modal/sadt-modal.component';
import { EpisodeModalComponent } from 'src/app/medic/pages/attend-patient/episode-modal/episode-modal.component';
import { EpisodeStatusRequest } from 'src/app/shared/services/requests/medical-record/episode-status.request';
import { TwilioVideoComponent } from 'src/app/shared/components/twilio-video/twilio-video.component';
import { WebsocketAppointmentActionEnum } from 'src/app/shared/enum/websocket-appointment-action.enum';
import { PatientLeaveModalComponent } from './patient-leave-modal/patient-leave-modal.component';
import { MedicalCareReportRequest } from 'src/app/shared/services/requests/orchestrator-patient/medical-care-report.request';
import { PatientService } from 'src/app/shared/services/API/medical-record/patient.service';
import { UpdateEmailPatientRequest } from 'src/app/shared/services/requests/medical-record/update-email-patient.request';
import { ReportModalComponent } from 'src/app/shared/components/report-modal/report-modal.component';
import { AllergyStruct } from 'src/app/shared/services/structs/medical-record/allergy.struct';
import { EpisodePreEvaluationStruct } from 'src/app/shared/services/structs/medical-record/episode-pre-evaluation.struct';
import { PinCertificateModalComponent } from 'src/app/shared/components/pin-certificate-modal/pin-certificate-modal.component';
import { CareLineModel } from 'src/app/shared/services/models/care-line/care-line.model';
import { EpisodeCareLineStruct } from 'src/app/shared/services/structs/care-line/episode-care-line.struct';
import { CareLineModalComponent } from 'src/app/shared/components/care-line-modal/care-line-modal.component';
import { EpisodeCareLineRequest } from 'src/app/shared/services/requests/care-line/episode-care-line.request';
import { EmailConfirmationEndMedicalCareModalComponent } from 'src/app/shared/components/email-confirmation-end-medical-care-modal/email-confirmation-end-medical-care-modal.component';
import { OrchestratorPatientAppointmentService } from 'src/app/shared/services/API/orchestrator-schedule/orchestrator-patient-appointment.service';
import { MedicHoldAppointmentModalComponent } from './medic-hold-modal/medic-hold-appointment-modal.component';
import { environment } from 'src/environments/environment';
import { CompletePatientAppointmentCareRequest } from 'src/app/shared/services/requests/orchestrator-patient/complete-patient-appointment-care.request';
import { PatientAppointmentCareRequest } from 'src/app/shared/services/requests/medical-record/patient-appointment-care.request';
import { PatientRequest } from 'src/app/shared/services/requests/medical-record/patient.request';
import { PatientStruct } from 'src/app/shared/services/structs/medical-record/patient.struct';
import { NatureOfAttendanceEnum } from 'src/app/shared/enum/medical-record/nature-of-attendance.enum';
import { WebsocketAppointmentUtilService } from 'src/app/shared/services/websocket-util/websocket-appointment-util.service';
import { CalcAgeService } from 'src/app/shared/services/API/medical-record/calc-age.service';
import { CalcAgeResponse } from 'src/app/shared/services/responses/medical-record/calc-age.response';
import { formatDate } from '@angular/common';
import { BillingDataRequest } from 'src/app/shared/services/requests/billing/billing-data-request';
import { TimeOverModalComponent } from './time-over-modal/time-over-modal.component';
import { MedicalCareSadtStruct } from 'src/app/shared/services/structs/medical-record/medical-care-sadt.struct';
import { PatientAppointmentRequest } from 'src/app/shared/services/requests/orchestrator-schedule/patient-appointment.request';
import { PatientAppointmentService } from 'src/app/shared/services/API/schedule/patient-appointment.service';
import { AppointmentStatusEnum } from 'src/app/shared/enum/schedule/appointment-status.enum';
import { MemedCompletePrescriptionStruct } from 'src/app/shared/services/structs/medical-record/memed-complete-prescription.struct';
import { MemedPrescriptionStruct } from 'src/app/shared/services/structs/medical-record/memed-prescription.struct';
import { GetPhoneModalComponent } from 'src/app/shared/components/get-phone-modal/get-phone-modal.component';
import { DeleteMemedPrescriptionsRequest } from 'src/app/shared/services/requests/orchestrator-integration/delete-memed-prescriptions.request';
import { UtilService, MemedPrescriptionSession } from 'src/app/shared/services/util.service';
import { WebsocketAttendanceUtilService } from 'src/app/shared/services/websocket-util/websocket-attendance-util.service';
import { MemedPrescriptionDeleteModalComponent } from '../attend-patient/memed-prescription-delete-modal/memed-prescription-delete-modal.component';
import { HealthUnitMemedPrescriptionService } from 'src/app/shared/services/API/medic/health-unit-memed-prescription.service';
import { UserMemedService } from 'src/app/shared/services/API/orchestrator-patient/user-memed.service';
import { EpisodeStruct } from 'src/app/shared/services/structs/orchestrator-patient/episode.struct';
import { UserRoleEnum } from 'src/app/shared/enum/user-role.enum';
import { NewReportResponse } from 'src/app/shared/services/responses/orchestrator-patient/new-report.response';
import { UserLoginResponse } from 'src/app/shared/services/responses/user/auth.response';
import { ReportViewSelectModalComponent } from 'src/app/shared/components/report-view-select-modal/report-view-select-modal.component';
import { SignatureCredentialsModalComponent } from 'src/app/shared/components/signature-credentials-modal/signature-credentials-modal.component';
import { ExamTypeService } from 'src/app/shared/services/API/SADT/exam-type.service';
import { TissGuideManagementFloatingWindowComponent } from 'src/app/shared/components/tiss-guides/tiss-guide-management-floating-window/tiss-guide-management-floating-window.component';
import { AllPrivateBillingGuideReportStruct } from 'src/app/shared/services/structs/orchestrator-patient/all-private-billing-guide-report.struct';
import { HealthUnitService } from 'src/app/shared/services/API/user/health-unit.service';
import { CancelConfirmPatientOnlineModalComponent } from './cancel-confirm-modal/cancel-confirm-patient-online-modal.component';

@Component({
  selector: 'app-attend-patient-online-appointment',
  templateUrl: './attend-patient-online-appointment.component.html',
  styleUrls: ['./attend-patient-online-appointment.component.css']
})
export class AttendPatientOnlineAppointmentComponent implements OnInit, OnDestroy {

  @ViewChild('twiliovideo') twiliovideo: TwilioVideoComponent;

  constructor(private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private dialog: MatDialog,
    private utilService: UtilService,
    public websocketAttendanceUtilService: WebsocketAttendanceUtilService,
    private medicalRecordService: MedicalRecordService,
    private lookupMedicalCareService: LookupMedicalCareService,
    private router: Router,
    private maskService: MaskService,
    private clockService: ClockService,
    private medicConfigSectorService: MedicConfigSectorService,
    private calcAgeService: CalcAgeService,
    private websocketAppointmentUtilService: WebsocketAppointmentUtilService,
    private patientService: PatientService,
    private orchestratorPatientAppointmentService: OrchestratorPatientAppointmentService,
    private patientAppointmentService: PatientAppointmentService,
    private authService: AuthService,
    private healthUnitMemedPrescriptionService: HealthUnitMemedPrescriptionService,
    private healthUnitService: HealthUnitService,
    private userMemedService: UserMemedService,
    private examTypeService: ExamTypeService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.medic;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.medic_patient_list;

  public isLoading: boolean = false;
  public isFirstLoading: boolean;
  public isIsolation: boolean;
  public triage: TriageStruct = null;
  public medicalCare: CompleteMedicalCareStruct[] = [];
  public episodeStruct: EpisodeStruct;
  public episodeHistorie: EpisodeHistorieStruct[] = [];
  public model: UntypedFormGroup;
  public listExternalPrescription: ReadExternalPrescriptionStruct[] = [];
  public listSADT: SadtGroupStruct[] = [];
  public masks: Masks;
  public showForward: boolean;
  public medicalEvolution: boolean = true;
  public episodePreEvaluation: EpisodePreEvaluationStruct;
  public allergies: AllergyModel[] = [];
  public allergiesToSave: AllergyModel[] = [];

  //Linhas de cuidado
  public listPastEpisodeCareLine: EpisodeCareLineStruct[] = [];
  public listCareLine: CareLineModel[] = [];

  public name: string;
  public socialName: string;
  public birthDate: Date;
  public formatedBirth: string;
  public age: string;

  public urlListAllergies: string = environment.urlApiMedicalRecord + "Allergy";
  public urlListCid: string = environment.urlApiMedicalRecord + 'cid';
  private idPatientAppointment: number;
  private idPatient: number;
  public cpf: string;
  private patientEmail: string;
  private lookupMedicalCareResponse: LookupMedicalCareResponse;
  private listLookupExam: ExamStruct[];
  private listCid: any[] = [];
  private idSector: number = null;
  private idRoom: number = null;
  private datetimeStartAttendance: Date;
  private idMedicalCare: number;
  private stringValidator: string;
  private listExamType: ExamTypeModel[];
  private idService: number;
  private medicConfigSector: MedicConfigSectorStruct;
  private medicalCareReportRequest: MedicalCareReportRequest = new MedicalCareReportRequest();
  private listIdExam: number[];
  private isAllExams: boolean;
  private newEmail: string;
  private bloodPressure: string[] = [];
  private idTimeout: any;
  private certificatePin: string = null;
  private isIcpBrailSigned: boolean = false;
  private isUserIcpBrasilSignature: boolean = false;
  private hasIcpBrasilTokenCertificate: boolean = false;
  private listNewEpisodeCareLine: EpisodeCareLineStruct[] = [];
  private patientPhoneNumber: string = "";
  public isIcpBrailTokenSigned: boolean = false;
  private appointmentDuration: number;
  private patientStruct: PatientStruct;
  private countryCode: string = "+55";
  public listCurrentEpisodeCareLine: EpisodeCareLineStruct[] = [];
  public urlListProcedure: string;
  public codCbo: string;
  public idCompetence: number;

  public patientCellPhone: string = "";

  //Memed
  public isMemedPrescription: boolean = false;
  public userTokenMemed: string;
  public showMemed: boolean = false;
  public listMemedPrescriptionStruct: MemedPrescriptionStruct[] = [];
  public listMemedCompletePrescriptionStruct: MemedCompletePrescriptionStruct[] = [];

  //Assinatura digital  
  public sign: boolean;
  public requireSignature: boolean;

  //tiss guide
  @ViewChild(TissGuideManagementFloatingWindowComponent) childComponent: TissGuideManagementFloatingWindowComponent;

  private showTissGuideButton: boolean = false;
  public showTissGuideButtonValidation: boolean = false;
  public allPrivateBillingGuideReportStruct: AllPrivateBillingGuideReportStruct;


  ngOnInit(): void {
    this.requireSignature = this.checkSigningResponsibility();
    this.masks = this.maskService.getMasks();
    this.getLookupSadtType();
    this.model = this.formBuilder.group({
      medicalNotes: [''],
      physicalExamination: [''],
      therapeuticPlan: [''],
      diagnosedHypothesis: [''],
      temperature: [''],
      respiratoryFrequency: [''],
      heartRate: [''],
      glucose: [''],
      bloodPressure: [''],
      saturation: [''],
      conclusionNote: [''],
      idFlow: [''],
      datetimeEndIsolation: [null],
      sickDaysOffAmount: [0, [Validators.min(0)]],
      isCidOnSickNote: [{ value: false, disabled: true }, [Validators.required]],
      firstChildGroup: this.formBuilder.group({
        listIdAllergies: [this.allergies.map(x => x.idAllergy)],
        cid: [this.listCid.map(a => a.idCid)],
      })
    });

    if (this.activatedRoute.snapshot.paramMap.get('id_patient_appointment')) {
      this.idPatientAppointment = parseInt(this.activatedRoute.snapshot.paramMap.get('id_patient_appointment'));
    }

    this.appointmentDuration = parseInt(sessionStorage.getItem('appointmentDuration'));

    this.codCbo = this.authService.getTokenMenu().codCbo;
    this.idCompetence = this.authService.getTokenMenu().healthUnit.idProcedureSigtapCompetence;

    if (this.codCbo && this.idCompetence) {
      let url = `ProcedureSigtapCBO/codCbo/${this.codCbo}/idCompetence/${this.idCompetence}`
      this.urlListProcedure = environment.urlApiBilling + url;
    }

    this.isFirstLoading = true;
    this.populateAttendPatient();
    this.startWebsocket();
    this.getMemedPrescription();
    this.getLookupMedicalCare();
    this.getHealthUnitConfig();
    this.idTimeout = setTimeout(() => this.startCall(), 1000);
  }

  updateAllergiesToSave(allergies: AllergyModel[]) {
    this.allergiesToSave = allergies;
  }

  submit() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    if (this.model.invalid) {
      this.alertService.show('Erro', "Preencha todos em vermelho" + this.stringValidator + "!", AlertType.error);
      this.isLoading = false;
      return;
    }

    if (this.model.get('bloodPressure').value) {
      let pa: string = this.model.get("bloodPressure").value.split("/")[0].split("");
      if (pa.length < 4) {
        this.alertService.show('Erro', "Preencha o valor da pressão arterial corretamente", AlertType.error);
        this.isLoading = false;
        return;
      }
      let value1 = pa.length == 4 ? pa[0] + pa[1] : pa[0] + pa[1] + pa[2];
      let value2 = pa.length == 4 ? pa[2] + pa[3] : pa.length == 5 ? pa[3] + pa[4] : pa[3] + pa[4] + pa[5];
      if (parseInt(value2) > parseInt(value1)) {
        this.alertService.show('Erro', "A pressão diastólica não pode ser maior do que a sistólica!", AlertType.error);
        this.model.get("bloodPressure").setValue("");
        this.isLoading = false;
        return;
      }
      this.bloodPressure.push(value1);
      this.bloodPressure.push(value2);
    }

    let medicalCareRequest = this.mapToRequest();

    if (medicalCareRequest.saturation < 0 || medicalCareRequest.saturation > 100) {
      this.alertService.show('Erro', "O valor da saturação deve ser de 0 a 100", AlertType.error);
      this.isLoading = false;
      return;
    }

    medicalCareRequest.idRoomAttendance = this.idRoom;

    if (this.isUserIcpBrasilSignature) this.openCertificatePinModal();
    else this.makeCallPost();
  }

  startConclusion(): EpisodeStatusRequest {
    let episodeStatusRequest: EpisodeStatusRequest = new EpisodeStatusRequest();
    episodeStatusRequest.conclusionNote = this.model.get("conclusionNote").value;
    episodeStatusRequest.idStatus = EpisodeStatusEnum.FinalizadoAlta;
    episodeStatusRequest.isBedManagement = false;
    return episodeStatusRequest;
  }

  async openConclusionModal(sign: boolean) {
    this.sign = sign;
    const dialogRef = this.dialog.open(EmailConfirmationEndMedicalCareModalComponent, {
      disableClose: true,
      data: {
        email: this.patientEmail,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: async (result) => {
        if (result.confirm && result.email && this.idPatient) {
          this.newEmail = result.email;
          await this.updateEmailPatient();
          this.submit();
        }
        else {
          this.submit();
        }
      }
    });
  }

  mapToRequest(): PatientAppointmentCareRequest {
    let medicalCareRequest: PatientAppointmentCareRequest = new PatientAppointmentCareRequest();
    medicalCareRequest.listExternalPrescription = this.listExternalPrescription;
    medicalCareRequest.medicalCareSadt = this.listSADT;
    medicalCareRequest.medicalNotes = this.model.get('medicalNotes').value;
    medicalCareRequest.physicalExamination = this.model.get('physicalExamination').value;
    medicalCareRequest.therapeuticPlan = this.model.get('therapeuticPlan').value;
    medicalCareRequest.diagnosedHypothesis = this.model.get('diagnosedHypothesis').value;
    medicalCareRequest.temperature = this.model.get('temperature').value ? parseFloat(this.model.get('temperature').value.replace(",", ".").replace("_", "")) : null;
    medicalCareRequest.respiratoryFrequency = this.model.get('respiratoryFrequency').value ? parseInt(this.model.get('respiratoryFrequency').value.replace("_", "")) : null;
    medicalCareRequest.heartRate = this.model.get('respiratoryFrequency').value ? parseInt(this.model.get('heartRate').value.replace("_", "")) : null;
    medicalCareRequest.glucose = this.model.get('respiratoryFrequency').value ? parseInt(this.model.get('glucose').value.replace("_", "")) : null;
    medicalCareRequest.bloodPressureDiastole = this.bloodPressure && this.bloodPressure[1] ? parseInt(this.bloodPressure[1].replace("_", "")) : null;
    medicalCareRequest.bloodPressureSystole = this.bloodPressure && this.bloodPressure[0] ? parseInt(this.bloodPressure[0].replace("_", "")) : null;
    medicalCareRequest.saturation = this.model.get('saturation').value ? parseInt(this.model.get('saturation').value.replace("_", "")) : null;
    medicalCareRequest.cid = this.listCid.map(a => a.idCid);
    medicalCareRequest.isCidOnSickNote = this.model.get('isCidOnSickNote').value;
    medicalCareRequest.listAllergy = this.allergiesToSave.map(a => new AllergyStruct(a, null));
    medicalCareRequest.datetimeStartAttendance = this.datetimeStartAttendance;
    medicalCareRequest.datetimeEndIsolation = this.model.get('datetimeEndIsolation').value?.toISOString();
    medicalCareRequest.sickDaysOffAmount = this.model.get('sickDaysOffAmount').value;
    medicalCareRequest.conclusionNote = this.model.get('conclusionNote').value;
    medicalCareRequest.patientRequest = this.mapToPatientRequest();
    medicalCareRequest.listMemedCompletePrescriptionStruct = this.listMemedCompletePrescriptionStruct;
    medicalCareRequest.userTokenMemed = this.userTokenMemed;

    if (this.showTissGuideButtonValidation) {
      let guide = this.childComponent.getGuidRequest();
      if (guide) {
        medicalCareRequest.guideRequest = guide;
      }
    }

    return medicalCareRequest;
  }

  mapToPatientRequest(): PatientRequest {
    let patientRequest: PatientRequest = new PatientRequest();
    patientRequest.apartmentNumber = this.patientStruct.apartmentNumber;
    patientRequest.birthCity = this.patientStruct.birthCity;
    patientRequest.birthCountry = this.patientStruct.birthCountry;
    patientRequest.birthDate = this.patientStruct.birthDate;
    patientRequest.birthState = this.patientStruct.birthState;
    patientRequest.city = this.patientStruct.city;
    patientRequest.cns = this.patientStruct.cns;
    patientRequest.companionCpf = this.patientStruct.companionCpf;
    patientRequest.companionName = this.patientStruct.companionName;
    patientRequest.companionParentage = this.patientStruct.companionParentage;
    patientRequest.companionPhone = this.patientStruct.companionPhone;
    patientRequest.country = this.patientStruct.country;
    patientRequest.cpf = this.patientStruct.cpf;
    patientRequest.email = this.patientStruct.email;
    patientRequest.expeditionDate = this.patientStruct.expeditionDate;
    patientRequest.expeditionIssuer = this.patientStruct.expeditionIssuer;
    patientRequest.fatherName = this.patientStruct.fatherName;
    patientRequest.genderName = this.patientStruct.genderName;
    patientRequest.houseNumber = this.patientStruct.houseNumber;
    patientRequest.idEducation = this.patientStruct.idEducation;
    patientRequest.idGender = this.patientStruct.idGender;
    patientRequest.idHealthUnitCreator = this.patientStruct.idHealthUnitCreator;
    patientRequest.idMaritalStatus = this.patientStruct.idMaritalStatus;
    patientRequest.idNatureOfAttendance = NatureOfAttendanceEnum.online;
    patientRequest.idPatient = this.patientStruct.idPatient;
    patientRequest.idRace = this.patientStruct.idRace;
    patientRequest.motherName = this.patientStruct.motherName;
    patientRequest.phone1 = this.patientStruct.phone1;
    patientRequest.phone2 = this.patientStruct.phone2;
    patientRequest.zipCode = this.patientStruct.zipCode;
    patientRequest.neighborhood = this.patientStruct.neighborhood;
    patientRequest.street = this.patientStruct.street;
    patientRequest.state = this.patientStruct.state;
    patientRequest.patientName = this.patientStruct.patientName;
    patientRequest.socialName = this.patientStruct.socialName;
    patientRequest.profession = this.patientStruct.profession;
    patientRequest.priorities = this.patientStruct.priorities;

    return patientRequest;
  }

  getLookupMedicalCare() {
    this.lookupMedicalCareService.getLookupMedicalCare().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.lookupMedicalCareResponse = response;
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateAttendPatient() {
    this.orchestratorPatientAppointmentService.getAppointment(this.idPatientAppointment).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isFirstLoading = false;
          return;
        }
        this.isUserIcpBrasilSignature = response.isActualUserIcpBrasilSignature;
        this.hasIcpBrasilTokenCertificate = response.hasIcpBrasilTokenCertificate;
        this.datetimeStartAttendance = response.datetimeStartAttendance;
        this.idPatient = response.patient.idPatient;
        this.birthDate = response.patient.birthDate;
        this.formatedBirth = formatDate(response.patient.birthDate, 'dd/MM/YYYY', 'en-US');
        this.searchAge(this.birthDate);
        this.name = response.patient.patientName;
        this.socialName = response.patient.socialName;
        this.cpf = response.cpf;
        this.patientEmail = response.email ? response.email : response.patient.email;
        this.patientCellPhone = response.patient.phone1 ? response.patient.phone1 : response.patient.phone2;
        this.appointmentDuration = response.appointmentDuration;
        this.patientStruct = response.patient;

        this.episodeHistorie = response.episodeHistorie;
        this.allergies = response.listAllergy;
        this.listPastEpisodeCareLine = response.listPastEpisodeCareLine;
        this.listCareLine = response.listCareLine;

        this.episodeStruct = new EpisodeStruct();
        this.episodeStruct.birthDate = this.birthDate;
        this.episodeStruct.patientName = response.patient.patientName;

        this.isFirstLoading = false;
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getDate() {
    this.clockService.getDate().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        this.datetimeStartAttendance = response.date;
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openCareLineModal(listCareLine: CareLineModel[]) {
    const dialogRef = this.dialog.open(CareLineModalComponent, {
      data: {
        listCareLine: listCareLine,
        listPastEpisodeCareLine: this.listPastEpisodeCareLine,
        listCurrentEpisodeCareLine: this.listCurrentEpisodeCareLine,
        patientPhoneNumber: this.patientStruct.phone1 ? this.patientStruct.phone1.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll(" ", "") : this.patientStruct.phone2 ? this.patientStruct.phone2.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll(" ", "") : null,
        masks: this.masks,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.newEpisodeCareLines && result.patientPhoneNumber) {
          this.listNewEpisodeCareLine = this.listNewEpisodeCareLine.concat(result.newEpisodeCareLines);
          this.listCurrentEpisodeCareLine = this.listCurrentEpisodeCareLine.concat(result.newEpisodeCareLines);
          this.countryCode = result.countryCode;
          this.patientPhoneNumber = result.patientPhoneNumber;
        }
      }
    });
  }

  getMedicSectorConfiguration() {
    this.medicConfigSectorService.listMedicConfigSector(this.idSector).subscribe({
      next: response => {
        if (response.isError) {
          this.alertService.show("Erro", response.errorDescription, AlertType.error);
          return;
        }

        if (response.medicConfigSectorStruct && response.medicConfigSectorStruct.length > 0) {
          this.medicConfigSector = response.medicConfigSectorStruct[0];
        }
        else {
          this.alertService.show('Aviso', 'Configurações não encontradas, verifique se há configurações cadastradas para este setor', AlertType.warning);
        }

        this.verifyRequiredFields();
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  verifyRequiredFields() {
    if (this.medicConfigSector) {
      this.medicConfigSector.listIdRequiredField.forEach(x => {

        //sinais vitais
        if (x == MedicRequiredFieldEnum.temperature) {
          this.model.get('temperature').setValidators([Validators.required]);
          this.model.get('temperature').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.respiratoryFrequency) {
          this.model.get('respiratoryFrequency').setValidators([Validators.required]);
          this.model.get('respiratoryFrequency').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.heartRate) {
          this.model.get('heartRate').setValidators([Validators.required]);
          this.model.get('heartRate').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.glucose) {
          this.model.get('glucose').setValidators([Validators.required]);
          this.model.get('glucose').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.satO2) {
          this.model.get('saturation').setValidators([Validators.required]);
          this.model.get('saturation').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.bloodPressure) {
          this.model.get('bloodPressure').setValidators([Validators.required]);
          this.model.get('bloodPressure').updateValueAndValidity();
        }

        //outros campos
        else if (x == MedicRequiredFieldEnum.medicalNotes) {
          this.model.get('medicalNotes').setValidators([Validators.required]);
          this.model.get('medicalNotes').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.physicalExamination) {
          this.model.get('physicalExamination').setValidators([Validators.required]);
          this.model.get('physicalExamination').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.cid) {
          this.model.get('firstChildGroup').get('cid').setValidators([Validators.required]);
          this.model.get('firstChildGroup').get('cid').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.allergies) {
          this.model.get('firstChildGroup').get('listIdAllergies').setValidators([Validators.required]);
          this.model.get('firstChildGroup').get('listIdAllergies').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.conclusion) {
          this.model.get('conclusionNote').setValidators([Validators.required]);
          this.model.get('conclusionNote').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.diagnosedHypothesis) {
          this.model.get('diagnosedHypothesis').setValidators([Validators.required]);
          this.model.get('diagnosedHypothesis').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.therapeuticPlan) {
          this.model.get('therapeuticPlan').setValidators([Validators.required]);
          this.model.get('therapeuticPlan').updateValueAndValidity();
        }
      });
      if (this.medicConfigSector.listIdRequiredField.findIndex(c => c == MedicRequiredFieldEnum.conclusion) == -1) {
        this.model.get('conclusionNote').setValidators([]);
        this.model.get('conclusionNote').updateValueAndValidity();
      }
    }
    else {
      this.model.get('conclusionNote').setValidators([]);
      this.model.get('conclusionNote').updateValueAndValidity();
    }
  }

  openUpdateExternalPrescriptionModal(index: number, prescription: ReadExternalPrescriptionStruct) {
    const dialogRef = this.dialog.open(UpdateExternalPrescriptionModalComponent, {
      data: {
        prescription,
        idMedicalCare: this.idMedicalCare,
        lookupMedicalCareResponse: this.lookupMedicalCareResponse,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result) {
          if (index >= 0) {
            this.listExternalPrescription.splice(index, 1);
          }
          this.listExternalPrescription.push(result.newMedicalPrescription);
        }
        this.isFirstLoading = false;
      }
    });
  }

  openDefaultExternalPrescriptionModal(prescription: ReadExternalPrescriptionStruct, readOnly: boolean, index: number) {
    const dialogRef = this.dialog.open(ExternalPrescriptionModalComponent, {
      data: {
        prescription,
        readOnly: readOnly,
        lookupMedicalCareResponse: this.lookupMedicalCareResponse,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result) {
          if (index >= 0) {
            this.listExternalPrescription.splice(index, 1);
          }
          this.listExternalPrescription.push(result.newExternalPrescription);
        }
        this.isFirstLoading = false;
      }
    });
  }


  openSadtModal(sadt: MedicalCareSadtStruct, readOnly: boolean, index: number) {
    const dialogRef = this.dialog.open(SadtModalComponent, {
      data: {
        sadt,
        readOnly,
        listPlace: this.lookupMedicalCareResponse.listPlace,
        listExamType: this.listExamType,
        listIdExam: this.listIdExam,
        isAllExams: this.isAllExams,
        isExternal: true,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result) {
          if (index >= 0) {
            this.listSADT.splice(index, 1);
          }
          this.listSADT.push(result.newSadt);
        }
      }
    });
  }

  deleteExternalPrescription(index: number) {
    this.listExternalPrescription.splice(index, 1);
  }

  deleteSadt(index: number) {
    this.listSADT.splice(index, 1);
  }

  addItemAllergies(event: any) {
    this.allergies = event;
  }

  addItemCid(event: any) {
    this.listCid = event;
    if (!this.listCid || this.listCid.length == 0) {
      this.model.get('isCidOnSickNote').setValue(false);
      this.model.get('isCidOnSickNote').disable();
    }
    else
      this.model.get('isCidOnSickNote').enable();
  }

  changeIsolation(event: any) {
    if (event.checked) {
      this.model.get('datetimeEndIsolation').setValidators([DateAfterTodayValidator(), Validators.required]);
      this.model.get('datetimeEndIsolation').setValue(new Date());
    }
    else {
      this.model.get('datetimeEndIsolation').setValidators([]);
      this.model.get('datetimeEndIsolation').setValue(null);
    }
    this.model.get('datetimeEndIsolation').updateValueAndValidity();
  }

  openCancelConfirmModal() {
    let guideWasUpdate = false;
    if (this.showTissGuideButtonValidation) {
      let guideRequest = this.childComponent.getGuidRequest();
      if ((guideRequest.admissionGuideStruct ||
        guideRequest.appointmentGuideStruct ||
        guideRequest.feesGuideStruct ||
        guideRequest.spSadtGuideStruct)) {
        guideWasUpdate = true;
      }
    }
    const dialogRef = this.dialog.open(CancelConfirmPatientOnlineModalComponent, {
      data: {
        guideWasUpdate
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.isCancel) {
          this.websocketAppointmentUtilService.emitAction(this.idPatientAppointment, WebsocketAppointmentActionEnum.medicEvasion);
          this.twiliovideo.onLeaveRoom(true);
        }
      }
    });

  }

  openEpisodeModal(idEpisode: number) {
    const dialogRef = this.dialog.open(EpisodeModalComponent, {
      data: {
        idEpisode: idEpisode,
        lookupMedicalCareResponse: this.lookupMedicalCareResponse,
        listExam: this.listLookupExam,
        room: { idSector: this.idSector, idRoom: this.idRoom }
      },
    });
  }

  openHoldModal() {
    let room = this.twiliovideo.activeRoom;
    const dialogRef = this.dialog.open(MedicHoldAppointmentModalComponent, {
      panelClass: 'medic-hold-modal',
      data: {
        time: false,
        appointmentDuration: this.appointmentDuration,
        idPatientEpisode: this.idPatientAppointment,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        this.websocketAppointmentUtilService.setThisParam(this);
        this.websocketAppointmentUtilService.setFunctions(this.verifyTrigger, null, null, null, null);
        if (result && result.isCancel) {
          this.twiliovideo.onLeaveRoom(true);
          this.cancelAppointment(AppointmentStatusEnum.CanceladoPeloMedico);
        }
        else if (result && result.timeIsOver) {
          this.openModalTimeOver();
        }
      }
    });
  }

  openPatientLeaveModal() {
    const dialogRef = this.dialog.open(PatientLeaveModalComponent, {
      data: {
        time: false,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        this.twiliovideo.onLeaveRoom(true);
        this.cancelAppointment(AppointmentStatusEnum.Evadiu);
      }
    });
  }

  openModalTimeOver() {
    const dialogRef = this.dialog.open(TimeOverModalComponent, {
      data: {
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        this.twiliovideo.onLeaveRoom(true);
        this.cancelAppointment(AppointmentStatusEnum.NaoCompareceu);
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  //Websocket
  startWebsocket() {

    if (!this.websocketAppointmentUtilService.isConnect()) {
      this.websocketAppointmentUtilService.connectwebsocketRoom(this.idPatientAppointment, this);
    }
    this.websocketAppointmentUtilService.setThisParam(this);
    this.websocketAppointmentUtilService.setFunctions(this.verifyTrigger, null, null, null, null);
  }

  ngOnDestroy() {
    this.websocketAppointmentUtilService.clearSocket();
    clearTimeout(this.idTimeout);
  }

  verifyTrigger(client: any, thisParam: any) {
    thisParam.socketDisconneted = false;

    if (client.action == WebsocketAppointmentActionEnum.patientEvasion) {
      thisParam.openPatientLeaveModal();
    }

    if (client.action == WebsocketAppointmentActionEnum.endMedicalCare) {
      sessionStorage.removeItem("videoAppointmentToken");
      thisParam.router.navigate(['/medic/medical-schedule']);
    }

    if (client.action == WebsocketAppointmentActionEnum.medicEvasion) {
      thisParam.cancelAppointment(AppointmentStatusEnum.MedicoEvadiu);
    }
  }

  startCall() {
    clearTimeout(this.idTimeout);

    if (sessionStorage.getItem("videoAppointmentToken"))
      this.twiliovideo.onRoomChanged(sessionStorage.getItem("videoAppointmentToken")).then(result => {
        let room = result;
        if (room.participants.size === 0) {
          this.openHoldModal();
        }
      });
  }

  selectReports(): MedicalCareReportRequest {
    this.medicalCareReportRequest.externalPrescriptionPrintCount = this.listExternalPrescription.length > 0 ? 1 : 0;
    this.medicalCareReportRequest.isSickNoteReport = true;
    this.medicalCareReportRequest.isCompleteMedicalCareReport = true;
    this.medicalCareReportRequest.isExamExternalReport = true;
    this.medicalCareReportRequest.isExamInternalReport = true;
    this.medicalCareReportRequest.isPrescriptionReport = true;
    this.medicalCareReportRequest.isPrescriptionMemedReport = true;
    this.medicalCareReportRequest.isMedicalProcedureReport = true;
    this.medicalCareReportRequest.isIsolationReport = true;
    this.medicalCareReportRequest.isIsolationAcknowledgeReport = true;

    this.medicalCareReportRequest.isBedManagement = false;
    this.medicalCareReportRequest.idBed = null;
    this.medicalCareReportRequest.isOnline = true;
    this.medicalCareReportRequest.idSector = this.idSector;

    if (this.newEmail && this.newEmail != null)
      this.medicalCareReportRequest.email = this.newEmail;
    else
      this.medicalCareReportRequest.email = this.patientEmail;

    return this.medicalCareReportRequest;
  }

  async updateEmailPatient(): Promise<void> {
    return new Promise((resolve) => {
      let updateEmail = new UpdateEmailPatientRequest();
      updateEmail.email = this.newEmail;
      updateEmail.idPatient = this.idPatient;

      this.patientService.updateEmailPatient(updateEmail).subscribe({
        next: response => {
          if (response.isError) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            this.isLoading = false;
            resolve();
          }
          resolve();
        },
        error: error => {
          console.log(error);
          this.isLoading = false;
          this.alertService.show('Erro inesperado', error, AlertType.error);
          resolve();
        }
      });
    })
  }


  makeCallPost() { // TODO: alterar para post correto na finalização do episódio
    let patientAppointmentCareRequest = this.mapToRequest();
    patientAppointmentCareRequest.idRoomAttendance = this.idRoom;
    patientAppointmentCareRequest.idPatientAppointment = this.idPatientAppointment;
    patientAppointmentCareRequest.administrativeRelease = false;

    //Adicionando linhas de cuidado ao request
    let episodeCareLineRequest = new EpisodeCareLineRequest();
    episodeCareLineRequest.listIdCareLine = this.listNewEpisodeCareLine.map(x => x.idCareLine);
    episodeCareLineRequest.phoneNumber = this.patientPhoneNumber;

    let saveCompletedMedicalRecord: CompletePatientAppointmentCareRequest = new CompletePatientAppointmentCareRequest();
    saveCompletedMedicalRecord.medicalCareReportRequest = this.selectReports();
    saveCompletedMedicalRecord.billingDataRequest = this.mapToBillingDataRequest();
    saveCompletedMedicalRecord.episodeStatusRequest = this.startConclusion();

    saveCompletedMedicalRecord.conclusionType = FowardDestinationEnum.Alta;

    saveCompletedMedicalRecord.patientAppointmentCareRequest = patientAppointmentCareRequest;
    saveCompletedMedicalRecord.episodeCareLineRequest = episodeCareLineRequest;

    if (this.sign) {
      const dialogRef = this.dialog.open(SignatureCredentialsModalComponent, {
        disableClose: this.requireSignature,
      });

      dialogRef.afterClosed().subscribe({
        next: (result) => {
          if (!result) {
            this.isLoading = false;
            return;
          }
          if (result.sign) {
            saveCompletedMedicalRecord.patientAppointmentCareRequest.idSignatureProvider = result.idSignatureProvider;
            saveCompletedMedicalRecord.patientAppointmentCareRequest.signatureAuthentication = JSON.stringify(result.signatureAuthentication);
            this.finalizeAttendance(saveCompletedMedicalRecord);
          }
          else {
            saveCompletedMedicalRecord.patientAppointmentCareRequest.idSignatureProvider = null;
            saveCompletedMedicalRecord.patientAppointmentCareRequest.signatureAuthentication = null;
            this.finalizeAttendance(saveCompletedMedicalRecord);
          }
        }
      });
    }
    else {
      saveCompletedMedicalRecord.patientAppointmentCareRequest.idSignatureProvider = null;
      saveCompletedMedicalRecord.patientAppointmentCareRequest.signatureAuthentication = null;
      this.finalizeAttendance(saveCompletedMedicalRecord);
    }
  }

  openCertificatePinModal() {
    const dialogRef = this.dialog.open(PinCertificateModalComponent, {
      data: {
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.isICPSigned) {
          this.certificatePin = result.pin;
          this.isIcpBrailSigned = result.isICPSigned;
        }

        this.makeCallPost();
      }
    });
  }

  openReportModal(reportName, reportPdf64) {
    let reportDialog = this.dialog.open(ReportModalComponent, {
      data: {
        reportName: reportName,
        reportPdf64: reportPdf64,
      },
    });
    reportDialog.afterClosed().subscribe(() => {
      this.twiliovideo.onLeaveRoom(true);
      this.router.navigate(['/medic/medical-schedule'])
    });
  }

  removeCareLine(index: number, idCareLine: number) {
    this.listCurrentEpisodeCareLine.splice(index, 1);
    let newIndex = this.listNewEpisodeCareLine.findIndex(x => x.idCareLine == idCareLine);
    if (newIndex !== -1)
      this.listNewEpisodeCareLine.splice(newIndex, 1);
  }

  searchAge(birthDate: Date) {
    if (!birthDate) return;

    let formattedDate = formatDate(birthDate, 'dd/MM/YYYY', 'en-US');
    this.calcAgeService.getAge(formattedDate).subscribe({
      next: (calcAgeResponse: CalcAgeResponse) => {
        let ageYear = calcAgeResponse.year;
        let ageMonth = calcAgeResponse.months;
        let ageDay = calcAgeResponse.days;
        this.age = (`${calcAgeResponse.year}a ${calcAgeResponse.months}m ${calcAgeResponse.days}d`);
      },
      error: error => {
        console.log(error);
      }
    });
  }

  mapToBillingDataRequest(): BillingDataRequest {
    let billingDataRequest: BillingDataRequest = new BillingDataRequest();
    billingDataRequest.listCid = this.listCid.map(x => x.code);
    billingDataRequest.idModule = MenuModuleEnum.medic;
    return billingDataRequest;
  }

  async cancelAppointment(idAppointmentStatus: number) {
    let request = new PatientAppointmentRequest();
    request.isCancellation = true;
    request.idAppointmentStatus = idAppointmentStatus;

    this.patientAppointmentService.putPatientAppointmentById(this.idPatientAppointment, request).subscribe({
      next: response => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.router.navigate(['/medic/medical-schedule']);
      },
      error: error => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getMemedPrescription() {
    this.healthUnitMemedPrescriptionService.getMemedPrescriptionConfig().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        if (response.memedPrescription) {
          this.isMemedPrescription = response.memedPrescription;
          this.searchTokenMemed();
        }
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getHealthUnitConfig() {
    this.healthUnitService.getHealthUnitConfig().subscribe({
      next: (response) => {
        if (response.isError) {
          console.log(response.isError);
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
        }
        this.showTissGuideButton = response.healthUnit.canAddTissGuide;
        this.showTissGuideButtonValidation = this.showTissGuideButton;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  searchTokenMemed() {
    this.userMemedService.getUserTokenMemed().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isFirstLoading = false;
          this.isMemedPrescription = false;
          return;
        }
        this.userTokenMemed = response.userTokenMemed;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isMemedPrescription = false;
      }
    });
  }

  openMemedPrescription() {
    if (!this.patientCellPhone)
      this.openGetPhoneModal();
    else
      this.showMemed = true;
  }

  closeMemedModal() {
    this.showMemed = false;
  }

  openGetPhoneModal() {
    const dialogRef = this.dialog.open(GetPhoneModalComponent, {});
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result && result.phone) {
          this.patientCellPhone = result.phone;
          this.showMemed = true;
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  deleteMemedPrescription(index: number) {
    let listIdMemed: number[] = [];
    listIdMemed.push(this.listMemedPrescriptionStruct[index].idMemedPrescription)
    const dialogRef = this.dialog.open(MemedPrescriptionDeleteModalComponent, {
      data: {
        listIdMemed: listIdMemed,
        userTokenMemed: this.userTokenMemed,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result && result.delete) {
          let indexComplete = this.listMemedCompletePrescriptionStruct.findIndex(x => x.prescricao.id == this.listMemedPrescriptionStruct[index].idMemedPrescription);
          this.listMemedPrescriptionStruct.splice(index, 1);
          this.listMemedCompletePrescriptionStruct.splice(indexComplete, 1);
          let memedSession: MemedPrescriptionSession = new MemedPrescriptionSession();
          memedSession.listMemedPrescriptionStruct = this.listMemedPrescriptionStruct;
          memedSession.listMemedCompletePrescriptionStruct = this.listMemedCompletePrescriptionStruct;
          this.utilService.setMemedPrescriptionSession(memedSession);
        }
      },
      error: (error) => {
        console.log(error);
      }
    });

  }

  deleteAllMemedPrescriptionOnDestroy() {

    let deleteMemedPrescriptionsRequest: DeleteMemedPrescriptionsRequest = new DeleteMemedPrescriptionsRequest()
    deleteMemedPrescriptionsRequest.userToken = this.userTokenMemed;
    deleteMemedPrescriptionsRequest.listIdPrescriptionMemed = this.listMemedPrescriptionStruct.map(x => x.idMemedPrescription);

    this.userMemedService.deletePrescriptionMemed(deleteMemedPrescriptionsRequest).subscribe({
      next: (response) => {
        this.isLoading = false;
        if (response.isError) {
          this.alertService.show("Erro", response.errorDescription, AlertType.error);
          return;
        }
        this.utilService.cleanMemedPrescriptionSession();
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  newMemedPrescription(event: any) {
    if (event && event.memedPrescriptionStruct) {
      let hasPrescription = this.listMemedPrescriptionStruct.find(x => x.idMemedPrescription == event.memedPrescriptionStruct.idMemedPrescription)
      if (!hasPrescription) {
        this.listMemedPrescriptionStruct.push(event.memedPrescriptionStruct);

        this.listMemedCompletePrescriptionStruct.push(event.completePrescriptionStruct)
        let listidMemedPrescription = this.listMemedPrescriptionStruct.map(x => x.idMemedPrescription);
        if (this.websocketAttendanceUtilService.isConnect() && this.userTokenMemed) {
          this.websocketAttendanceUtilService.emitMemedPrescriprtionSave(this.idRoom, this.userTokenMemed, listidMemedPrescription);
        }
        let memedSession: MemedPrescriptionSession = new MemedPrescriptionSession();
        memedSession.listMemedPrescriptionStruct = this.listMemedPrescriptionStruct;
        memedSession.listMemedCompletePrescriptionStruct = this.listMemedCompletePrescriptionStruct;
        this.utilService.setMemedPrescriptionSession(memedSession);
      }
    }
  }

  hasReport(response: NewReportResponse) {
    return Boolean(
      response.wristbandReportPdf64 ||
      response.medicalCareReport64 ||
      response.sadtInternalReport64 ||
      response.sadtExternalReport64 ||
      response.prescriptionReport64 ||
      response.externalPrescriptionReport64 ||
      response.sickNoteReport64 ||
      response.isolationReport64 ||
      response.isolationAcknowledgeReport64 ||
      response.procedureReport64 ||
      response.allReportsConcat64 ||
      this.allPrivateBillingGuideReportStruct?.appointmentGuideReport64 ||
      this.allPrivateBillingGuideReportStruct?.admissionGuideReport64 ||
      this.allPrivateBillingGuideReportStruct?.feesGuideReport64 ||
      this.allPrivateBillingGuideReportStruct?.spSadtGuideReport64
    )
  }

  checkSigningResponsibility(): boolean {
    let token: UserLoginResponse = this.utilService.getToken();
    return (token.listRole.map(x => x.idRole).includes(UserRoleEnum.requerer_assinatura_medico) && !token.isMaster);
  }

  finalizeAttendance(saveCompletedMedicalRecord: CompletePatientAppointmentCareRequest) {
    this.medicalRecordService.postPatientAppointmentCare(saveCompletedMedicalRecord).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show("Error", response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        if (response.errorCode == 11)
          this.alertService.show("Erro", response.errorDescription, AlertType.error);

        if (response.allPrivateBillingGuideReportStruct){
          this.allPrivateBillingGuideReportStruct = new AllPrivateBillingGuideReportStruct();
          this.allPrivateBillingGuideReportStruct = response.allPrivateBillingGuideReportStruct;          
        }

        if (this.hasReport(response)) {
          let reportDialog = this.dialog.open(ReportViewSelectModalComponent, {
            data: {
              medicalCareReport: response.medicalCareReport64,
              examInternalReport: response.sadtInternalReport64,
              examExternalReport: response.sadtExternalReport64,
              internalPrescriptionReport: response.prescriptionReport64,
              externalPrescriptionReport: response.externalPrescriptionReport64,
              procedureReport: response.procedureReport64,
              sickNoteReport: response.sickNoteReport64,
              isolationReport: response.isolationReport64,
              isolationAcknowledgeReport: response.isolationAcknowledgeReport64,              
              appointmentGuideReport: this.allPrivateBillingGuideReportStruct?.appointmentGuideReport64,
              admissionGuideReport: this.allPrivateBillingGuideReportStruct?.admissionGuideReport64,
              feesGuideReport: this.allPrivateBillingGuideReportStruct?.feesGuideReport64,
              spSadtGuideReport: this.allPrivateBillingGuideReportStruct?.spSadtGuideReport64,
              listMemedPrescriptionPdf: response.listMemedPrescriptionPdf64,
              allReportsConcat: response.allReportsConcat64,
              sign: this.sign
            },
          });
          reportDialog.afterClosed().subscribe(() => {
            this.twiliovideo.onLeaveRoom(true);
            this.websocketAppointmentUtilService.emitAction(this.idPatientAppointment, WebsocketAppointmentActionEnum.endMedicalCare);
          });
        }
        else {
          this.twiliovideo.onLeaveRoom(true);
          this.websocketAppointmentUtilService.emitAction(this.idPatientAppointment, WebsocketAppointmentActionEnum.endMedicalCare);
        }
        this.isLoading = false;
      },
      error: (error) => {
        this.alertService.show("Error inesperado", error, AlertType.error);
        this.isLoading = false;
        return;
      }
    });
  }

  getLookupSadtType() {
    this.examTypeService.listAllExamTypeWithExams().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listExamType = response.listExamType;
      }
    });
  }
  openReportDialog(reportName, reportPdf64) {
    if (reportPdf64 && reportPdf64.trim() !== "") {
      this.dialog.open(ReportModalComponent, {
        data: {
          reportName: reportName,
          reportPdf64: reportPdf64,
        },
      });
    }
  }
}
