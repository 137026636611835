<div class="border-list">
    <div class="title-list">
        <div class="row">
            <div class="col-6 col-sm-6 col-md-4">
                <span class="subtitle">Prescrições Padronizadas</span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="prescription">
            <div class="col-6 col-sm-6 col-md-4">
                <a mat-flat-button type="button"
                    (click)="openPrescriptionModal(-1, null, false)" color="accent"
                    class=" btn-block">
                    <mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon>
                    Prescrições
                    Padronizadas
                </a>
            </div>
            <!-- <div class="col-1 favorite-icon" *ngIf="!favoriteStandardMedicine">
                <i class="material-icons" matTooltip="Adicionar aos favoritos"
                    matTooltipPosition="above" (click)="favoriteMedicineModal()">star_border</i>
            </div>
            <div class="col-1 favorite-icon" *ngIf="favoriteStandardMedicine">
                <i class="material-icons" matTooltip="Adicionar aos favoritos"
                    matTooltipPosition="above">star</i>
            </div>
            <div class="col-4 col-sm-2 col-lg-1">
                <a mat-flat-button color="primary" (click)="selectFavoriteMedicineModal()"
                    class="btn-block">
                    <div
                        style="display: flex; align-items: baseline; flex-direction: row-reverse;">
                        <div class="favorite-icon-button">
                            <i class="material-icons">star_border</i>
                        </div>
                        <span style="padding-top: 2px;">Favoritos</span>
                    </div>
                </a>
            </div> -->
        </div>
        <!-- <div class="col-6 col-sm-6 col-md-3">
            <a *ngIf="!isLoadingLasPrescriptions" mat-flat-button type="button"
                (click)="getLastPrescriptions()" color="primary" class="btn-block">
                <mat-icon aria-hidden="false" aria-label="Plus">history</mat-icon> Resgatar
                prescrição anterior
            </a>
            <a *ngIf="isLoadingLasPrescriptions" mat-flat-button type="button" color="primary"
                class="btn-block">
                <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
            </a>
        </div> -->
    </div>
    <div class="card-list" *ngIf="listPrescription != null && listPrescription.length > 0">
        <div class="row row-list">
            <div class="col-12 col-sm-6 col-md-4"
                *ngFor="let item of listPrescription; let index = index;">
                <div class="card-item">
                    <div class="infos">
                        <span class="primary">{{item.medicineName}}</span>
                        <span class="secundary">Uso: {{item.usage}}</span>
                        <span class="secundary">Via: {{item.administrationTypeName}}</span>
                        <span class="secundary">Apresentação: {{item.presentationName}}</span>
                        <span class="secundary">Frequência: {{item.frequencyName}}</span>
                        <span class="secundary">Periodo trat.:
                            {{item.treatmentPeriodName}}</span>
                    </div>
                    <div class="actions">
                        <a>
                            <mat-icon aria-hidden="false" aria-label="Editar"
                                (click)="openPrescriptionModal(index, item, true)">edit</mat-icon>
                        </a>
                        <a>
                            <mat-icon aria-hidden="false" aria-label="Excluir"
                                (click)="deleteStandartPrescription(index)">delete</mat-icon>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>