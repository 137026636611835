import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-changelog-details-modal',
  templateUrl: './changelog-details-modal.component.html',
  styleUrls: ['./changelog-details-modal.component.css']
})
export class ChangelogDetailsModalComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ChangelogDetailsModalComponent>,
  ) { }

  public numberOfLinesInTextPatientExitLog: number = this.data?.numberOfLinesInTextPatientExitLog;

  ngOnInit(): void {
  }

  back() {
    this.matDialogRef.close();
  }
}