import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { VerifyWaitingTimeResponse } from '../../responses/queue/verify-waiting-time.response';
import { GetQueueRequest } from '../../requests/queue/get-queue.request';

@Injectable({
  providedIn: 'root'
})
export class VerifyWaitingTimeService extends CommonService {
  constructor(private httpClient: HttpClient) { super(); }

  public getVerifyWaitingTime(body: GetQueueRequest): Observable<VerifyWaitingTimeResponse> {
    let uri = `VerifyWaitingTime`

    return this.httpClient.post<VerifyWaitingTimeResponse>(environment.urlApiQueue + uri, body, this.addHeaderPanelToken())
      .pipe(catchError(this.handleError));
  }
}