import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { EvadePatientModalComponent } from 'src/app/shared/components/evade-patient-modal/evade-patient-modal.component';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { SelectRoomModalComponent } from 'src/app/shared/components/select-room-modal/select-room-modal.component';
import { StatusQueueEnum } from 'src/app/shared/enum/status-queue.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { GetRoomService } from 'src/app/shared/services/API/flow/get-room.service';
import { RoomService } from 'src/app/shared/services/API/flow/room.service';
import { CallPanelService } from 'src/app/shared/services/API/orchestrator-panel/call-panel.service';
import { AttendPatientService } from 'src/app/shared/services/API/orchestrator-queue/attend-patient.service';
import { PatientListService } from 'src/app/shared/services/API/orchestrator-queue/patient-list.service';
import { PriorityService } from 'src/app/shared/services/API/totem/priority.service';
import { PriorityModel } from 'src/app/shared/services/models/totem/priority.model';
import { AttendPatientRequest } from 'src/app/shared/services/requests/orchestrator-queue/attend-patient.request';
import { UpdateStatusQueueRequest } from 'src/app/shared/services/requests/queue/update-status-queue.request';
import { ListPatientResponse } from 'src/app/shared/services/responses/orchestrator-queue/list-patient.response';
import { RoomStruct } from 'src/app/shared/services/structs/flow/room.struct';
import { ListPatientStruct } from 'src/app/shared/services/structs/orchestrator-queue/list-patient.struct';
import { UtilService } from 'src/app/shared/services/util.service';
import { WebsocketQueueService } from 'src/app/shared/services/websocket-queue.service';
import { ReturnMotiveModalComponent } from 'src/app/patient-history/pages/patient-history-details/return-motive-modal/return-motive-modal.component';
import { ReturnPatientRequest } from 'src/app/shared/services/requests/orchestrator-queue/return-patient.request';
import { QueueHistoryService } from 'src/app/shared/services/API/orchestrator-queue/queue-history.service';
import { CallPanelEpisodeRequest } from 'src/app/shared/services/requests/orchestrator-panel/call-panel-episode.request';
import { PanelCallEpisodeStruct } from 'src/app/shared/services/structs/orchestrator-panel/panel-call-episode.struct';
import { PostPresenceReportRequest } from 'src/app/shared/services/requests/orchestrator-patient/post-presence-report.request';
import { WebsocketRoomUtilService } from 'src/app/shared/services/websocket-util/websocket-room-util.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CareLinePriorityStatusEnum } from 'src/app/shared/enum/care-line-priority/care-line-priority-status.enum';
import { PatientListAttendanceConfirmModalComponent } from 'src/app/shared/components/patient-list-attendance-confirm-modal/patient-list-attendance-confirm-modal.component';
import { NatureOfAttendanceEnum } from 'src/app/shared/enum/medical-record/nature-of-attendance.enum';
import { CsatService } from 'src/app/shared/services/API/csat/csat.service';
import { PrioritiesStatusEnum } from 'src/app/shared/enum/priorities-status.enum';
import { WebsocketAttendanceUtilService } from 'src/app/shared/services/websocket-util/websocket-attendance-util.service';
import { HealthUnitService } from 'src/app/shared/services/API/user/health-unit.service';
import { TissGuideManagementModalComponent } from 'src/app/shared/components/tiss-guides/tiss-guide-management-modal/tiss-guide-management-modal.component';
import { UserLoginResponse } from 'src/app/shared/services/responses/user/auth.response';
import { SignatureProviderEnum } from 'src/app/shared/enum/signature-providers/signature-providers.enum';
import { UserRoleEnum } from 'src/app/shared/enum/user-role.enum';
import { AlertModalComponent } from 'src/app/shared/components/alert-modal/alert-modal.component';
import { DigitalSignatureUtilService } from 'src/app/shared/services/digital-signature-util.service';
import { MenuComponent } from 'src/app/shared/components/menu/menu.component';
import { CareLinePriorityEnum } from 'src/app/shared/enum/care-line-priority/care-line-priority.enum';
import { PatientListCallConfirmModalComponent } from 'src/app/shared/components/patient-list-call-confirm-modal/patient-list-call-confirm-modal.component';
import { PatientListAlertCallConfirmModalComponent } from 'src/app/shared/components/patient-list-alert-call-confirm-modal/patient-list-alert-call-confirm-modal.component';
import { PatientListLiberateCallConfirmModalComponent } from 'src/app/shared/components/patient-list-liberate-call-confirm-modal/patient-list-liberate-call-confirm-modal.component';
import { PatientListLiberateAttendanceModalComponent } from 'src/app/shared/components/patient-list-liberate-attendance-modal/patient-list-liberate-attendance-modal.component';

@Component({
  selector: 'app-multi-professional-patient-list',
  templateUrl: './multi-professional-patient-list.component.html',
  styleUrls: ['./multi-professional-patient-list.component.css']
})
export class MultiProfessionalPatientListComponent implements OnInit, OnDestroy {

  constructor(
    private priorityService: PriorityService,
    private callPanelService: CallPanelService,
    private patientListService: PatientListService,
    private attendPatientService: AttendPatientService,
    private router: Router,
    private alertService: AlertService,
    private utilService: UtilService,
    public dialog: MatDialog,
    private websocketqueueService: WebsocketQueueService,
    private activatedRoute: ActivatedRoute,
    private roomService: RoomService,
    private getRoomService: GetRoomService,
    private queueHistoryService: QueueHistoryService,
    public websocketRoomUtilService: WebsocketRoomUtilService,
    public websocketAttendanceUtilService: WebsocketAttendanceUtilService,
    private authService: AuthService,
    private csatService: CsatService,
    private healthUnitService: HealthUnitService,
    private utilsService: UtilService,
    private signatureUtilService: DigitalSignatureUtilService,

  ) {
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MenuComponent) menu: MenuComponent;

  public seeButton: boolean = false;
  public idRoom: number;
  public idSector: number;
  public selectedRoom: RoomStruct;
  public idQueue: number;
  public postPresenceReportRequest: PostPresenceReportRequest;
  public listPriority: PriorityModel[];
  public updateStatusRequest: UpdateStatusQueueRequest;
  public listPatientResponse: ListPatientResponse;
  public isLoading: boolean = false;
  private agendados: boolean = false;
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.multi_professional;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.multi_professional_patient_list;
  public searchText: string = '';
  selectedEpisode: number = 0;
  public jsonListIdStatusQueue: string;
  public selectedPatient: ListPatientStruct | null = null;

  public attendancePresential: NatureOfAttendanceEnum = NatureOfAttendanceEnum.presencial;
  public attendanceOnline: NatureOfAttendanceEnum = NatureOfAttendanceEnum.online;

  public careLinePriorityStatusDenied = CareLinePriorityStatusEnum.negado;

  public canReturnToQueue: boolean = true;
  private seeOccupiedRoom: boolean = false;
  //websocket
  socketDisconneted: boolean = false;
  onlineOffline: boolean = navigator.onLine;
  private socket: any;

  public autismEnum: number = PrioritiesStatusEnum.AutistaTEA;
  public overSixtyYearsEnum: number = PrioritiesStatusEnum.MaiorDe60Anos;
  public overEightyYearsEnum: number = PrioritiesStatusEnum.MaiorDe80Anos;
  public attendedStatusQueueEnum: number = StatusQueueEnum.atendido;
  public showTissGuideButton: boolean = false;
  public dengue: CareLinePriorityEnum = CareLinePriorityEnum.Dengue;

  ngOnInit(): void {
    this.jsonListIdStatusQueue = null;
    this.updateStatusRequest = new UpdateStatusQueueRequest();
    this.listPatientResponse = new ListPatientResponse();
    this.listPatientResponse.listPatient = [];
    this.canReturnToQueue = this.utilService.getReturnToQueueConfig();
    this.utilService.cleanSecundaryHeaderModuleConfig('multiprofessional');

    if (this.activatedRoute.snapshot.paramMap.get('idRoom')) {
      this.idRoom = parseInt(this.activatedRoute.snapshot.paramMap.get('idRoom'));
    }
    if (this.activatedRoute.snapshot.paramMap.get('idSector')) {
      this.idSector = parseInt(this.activatedRoute.snapshot.paramMap.get('idSector'));
    }

    if (this.idRoom && this.idSector) {
      if (this.websocketRoomUtilService.getIdRoom() != this.idRoom) {
        this.websocketRoomUtilService.clearSocket();
      }
      this.getRoomUnAuth();
      this.startWebsocket();
    }
    else
      this.selectRoomModal()

    if (this.websocketAttendanceUtilService.isConnect())
      this.websocketAttendanceUtilService.clearSocket();

    this.populatePriorityMultiSelect();
    this.getCsat();
    this.getHealthUnitConfig();
  }

  getCsat() {
    this.csatService.getCsat().subscribe({
      next: (response) => {
        this.seeButton = response.seeButton;
      },
    });
  }

  search(pageIndex: number) {
    this.isLoading = true;
    if (this.searchText == "")
      this.searchText = null;

    this.patientListService.listPatient(this.searchText, this.idRoom, this.jsonListIdStatusQueue, pageIndex, this.menuModuleEnum, false, this.agendados).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.listPatientResponse = response;
        this.listPatientResponse.listPatient.forEach(x => {
          x.isLoading = false;
        })

        if (this.listPatientResponse?.returnEpisodes != null && this.listPatientResponse?.returnEpisodes > 0 && (this.jsonListIdStatusQueue == null || !this.jsonListIdStatusQueue.includes(StatusQueueEnum.retorno.toString())))
          this.alertService.show('Atenção! ', this.listPatientResponse?.returnEpisodes + ' pacientes aguardando reavaliação - retorno', AlertType.warning);

        if (this.listPatientResponse?.scheduleEpisodes != null && this.listPatientResponse?.scheduleEpisodes > 0)
          this.alertService.show('Atenção! ', this.listPatientResponse?.scheduleEpisodes + ' pacientes agendados aguardando atendimento', AlertType.warning);

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  toggleIsLoading(index: number) {
    this.listPatientResponse.listPatient[index].isLoading = !this.listPatientResponse.listPatient[index].isLoading;
  }

  populatePriorityMultiSelect() {
    this.priorityService.listPriority().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);

        return;
      }

      this.listPriority = response.listPriority;

    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  callPatient(idEpisode: number, idQueue: number, index: number,) {
    if (this.websocketRoomUtilService.getIsOccupied()) {
      this.alertService.show("Aviso", "Você está em modo de visualização!", AlertType.warning);
      return;
    }
    this.toggleIsLoading(index);

    let request = new CallPanelEpisodeRequest();
    request.episodeData = new PanelCallEpisodeStruct();

    let patient = this.listPatientResponse.listPatient.find(l => l.idEpisode === idEpisode);

    request.episodeData.idEpisode = idEpisode;
    request.episodeData.patientName = patient.patientName;
    request.episodeData.patientSocialName = patient.patientSocialName;
    request.episodeData.ticketInitials = patient.ticketInitials;
    request.episodeData.ticketSequence = patient.ticketSequence;
    request.jsonListIdStatusQueue = this.jsonListIdStatusQueue;


    this.callPanelService.callPatient(request, idQueue, this.idRoom, true, this.menuModuleEnum).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.toggleIsLoading(index);
        return;
      }
      if (response.errorCode == -1) {
        this.alertService.show('Aviso', response.errorDescription, AlertType.error);
      }
      if (response.isRestricted) {
        this.openRestrictedCallModal(request, response.confirmMessage, idQueue, index);
      }
      else if (response.isAlert) {
        this.openAlertModal(request, response.confirmMessage, idQueue, index);
      }
      else if (response.isConfirm) {
        this.openConfirmModal(request, response.confirmMessage, idQueue, index);
      }
      else {
        this.updateCallCount(index);
      }

      this.toggleIsLoading(index);
    },
      (error) => {
        console.log(error)
        this.toggleIsLoading(index);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
    this.selectedEpisode = idEpisode;
  }

  cancelPatient(idQueue: number, idStatus: number, idEpisode: number, index: number) {
    if (this.websocketRoomUtilService.getIsOccupied()) {
      this.alertService.show("Aviso", "Você está em modo de visualização!", AlertType.warning);
      return;
    }
    this.toggleIsLoading(index);
    this.openEvadePatientModal(idQueue, idStatus, idEpisode, index);
  }

  openEvadePatientModal(idQueue: number, idStatusQueue: number, idEpisode: number, index: number) {
    let manualEvasion: boolean = false;
    let patientStruct: ListPatientStruct = this.listPatientResponse.listPatient.find(x => x.idEpisode == idEpisode && x.idQueue == idQueue);

    if (patientStruct.idNatureOfAttendance == this.attendancePresential) {
      manualEvasion = true;
    }

    const dialogRef = this.dialog.open(EvadePatientModalComponent, {
      data: {
        idStatusQueue: idStatusQueue,
        idQueue: idQueue,
        idRoom: this.idRoom,
        idEpisode: idEpisode,
        manualEvasion: manualEvasion,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (!result || !result.isEvade) {
        this.toggleIsLoading(index);
      }
      else this.alertService.show('Sucesso', "Paciente evadido com sucesso", AlertType.success);
    });
  }

  attendPatient(patient: ListPatientStruct, index: number, isUpdateFrontdesk: boolean, isCheckAttendance: boolean = true) {
    if (this.websocketRoomUtilService.getIsOccupied()) {
      this.alertService.show("Aviso", "Você está em modo de visualização!", AlertType.warning);
      return;
    }
    this.toggleIsLoading(index);
    let attendPatientRequest: AttendPatientRequest = new AttendPatientRequest();
    
    attendPatientRequest.patientQueue = patient;
    attendPatientRequest.idModule = this.menuModuleEnum;
    attendPatientRequest.idRoomSector = this.listPatientResponse.idRoomSector;
    attendPatientRequest.idRoomService = this.listPatientResponse.idRoomService;
    attendPatientRequest.isUpdateFrontdesk = isUpdateFrontdesk;

    this.attendPatientService.attendPatient(attendPatientRequest, this.idRoom, isCheckAttendance).subscribe({
      next:(response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.toggleIsLoading(index);
          return;
        }
  
        if (response.isRestrictedPermission) {
          this.openRestrictedAttendmentModal(response.confirmMessage, attendPatientRequest, patient.idQueue);
        }
  
        else if (response.isAlertPermission) {
          this.openAlertAttendmentModal(response.confirmMessage, patient, response.isAlertPermission);
        }
  
        else if (response.isConfirm) {
          this.openAttendmentConfirmModal(response.confirmMessage, patient, index);
        }
        else {
          //redirect to register page
          this.router.navigate(['/multi-professional/multi-professional-attend-patient', { idEpisode: patient.idEpisode, idSector: this.idSector, idRoom: this.idRoom, idQueue: patient.idQueue, showTissGuideButton: this.showTissGuideButton }]);
        }
  
        this.toggleIsLoading(index);
      },
      error: (error) => {
        console.log(error)
        this.toggleIsLoading(index);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
    this.selectedEpisode = patient.idEpisode;
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.paginator.firstPage();
        $this.search(0);
      }
    }, 1000);
  }

  openConfirmModal(request: CallPanelEpisodeRequest, confirmMessage: string, idQueue: number, index: number) {
    const dialogRef = this.dialog.open(PatientListCallConfirmModalComponent, {
      data: {
        confirmMessage: confirmMessage,
        idQueue: idQueue,
        idRoom: this.idRoom,
        episodeData: request.episodeData,
        idModule: this.menuModuleEnum,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.isCalled) {
        this.updateCallCount(index);
      }
    });
  }

  openAlertModal(request: CallPanelEpisodeRequest, confirmMessage: string, idQueue: number, index: number) {
    const dialogRef = this.dialog.open(PatientListAlertCallConfirmModalComponent, {
      data: {
        confirmMessage: confirmMessage,
        idQueue: idQueue,
        idRoom: this.idRoom,
        episodeData: request.episodeData,
        idModule: this.menuModuleEnum,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.isCalled) {
        this.updateCallCount(index);
      }
    });
  }

  openRestrictedCallModal(request: CallPanelEpisodeRequest, confirmMessage: string, idQueue: number, index: number) {
    const dialogRef = this.dialog.open(PatientListLiberateCallConfirmModalComponent, {
      data: {
        confirmMessage: confirmMessage,
        idQueue: idQueue,
        idRoom: this.idRoom,
        episodeData: request.episodeData,
        idModule: this.menuModuleEnum,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.isCalled) {
        this.updateCallCount(index);
      }
    });
  }

  openAttendmentConfirmModal(confirmMessage: string, patient: ListPatientStruct, index: number) {
    const dialogRef = this.dialog.open(PatientListAttendanceConfirmModalComponent, {
      data: {
        confirmMessage: confirmMessage,
        idQueue: patient.idQueue,
        idRoom: this.idRoom,
        idEpisode: patient.idEpisode,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.isAttend) {
        this.attendPatient(patient, index, false, false);
      }
    });
  }

  openAlertAttendmentModal(confirmMessage: string, patient: ListPatientStruct, isAlertPermission: boolean) {
    const dialogRef = this.dialog.open(PatientListAttendanceConfirmModalComponent, {
      data: {
        confirmMessage: confirmMessage,
        idQueue: patient.idQueue,
        idRoom: this.idRoom,
        idEpisode: patient.idEpisode,
        isAlertPermission: isAlertPermission,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.isAttend) {
        this.router.navigate(['/multi-professional/multi-professional-attend-patient', { idEpisode: patient.idEpisode, idSector: this.idSector, idRoom: this.idRoom, idQueue: patient.idQueue, showTissGuideButton: this.showTissGuideButton }]);
      }
    });
  }

  openRestrictedAttendmentModal(confirmMessage: string, attendPatientRequest: AttendPatientRequest, idQueue: number) {
    const dialogRef = this.dialog.open(PatientListLiberateAttendanceModalComponent, {
      data: {
        confirmMessage: confirmMessage,
        attendPatientRequest: attendPatientRequest,
        idRoom: this.idRoom,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.isAttend) {
        this.router.navigate(['/multi-professional/multi-professional-attend-patient', { idEpisode: attendPatientRequest.patientQueue.idEpisode, idSector: this.idSector, idRoom: this.idRoom, idQueue: idQueue, showTissGuideButton: this.showTissGuideButton }]);
      }
    });
  }

  selectRoomModal() {
    const dialogRef = this.dialog.open(SelectRoomModalComponent, {
      data: {
        idModule: this.menuModuleEnum,
      },
    });
    dialogRef.afterClosed().subscribe(({ res, seeOccupiedRoom }) => {
      this.seeOccupiedRoom = seeOccupiedRoom;
      if (res) {
        this.idRoom = res.idRoom;
        this.idSector = res.idSector
        this.selectedRoom = res;
        if (this.socket) {
          this.socket.close();
          this.socket = undefined;
        }
        this.websocketRoomUtilService.clearSocket();

        this.startWebsocket();
        this.listPatientResponse.listPatient = [];
        this.paginator.firstPage();
        this.search(0);
      }

      this.checkSigningResponsibility();
    });
  }

  checkSigningResponsibility() {
    let token: UserLoginResponse = this.utilsService.getToken();
    let signatureMap: Object = this.signatureUtilService.listSignatureStruct();

    let requireSignature = token.listRole.map(x => x.idRole).includes(UserRoleEnum.requerer_assinatura_multiprofissional) && !token.isMaster;
    let signatureReady = signatureMap != null && ((signatureMap[SignatureProviderEnum.safeID] != null && signatureMap[SignatureProviderEnum.safeID].identifierCA != null) ||
      (signatureMap[SignatureProviderEnum.vidaaS] != null && signatureMap[SignatureProviderEnum.safeID].authorizationToken != null));
    
    if (requireSignature && !signatureReady) {
      const dialogRef = this.dialog.open(AlertModalComponent, {
        data: {
          isTwoButtonsModal: false,
          title: 'Atenção',
          description: 'Seu perfil foi marcado com obrigatoriedade de assinatura digital de documentos. Escolha um provedor e siga o processo para ativar sua assinatura digital no sistema.'
        },
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe({
        next: result => {
          this.menu.openSignatureManager();
        }
      });
    }
  }

  updateCallCount(index: number) {
    this.listPatientResponse.listPatient[index].ticketCallCount = this.listPatientResponse.listPatient[index].ticketCallCount + 1;
  }

  filterListPatient({ selectedItems, agendados }) {
    this.agendados = agendados;

    if (!selectedItems || selectedItems.length == 0)
      this.jsonListIdStatusQueue = null;
    else
      this.jsonListIdStatusQueue = JSON.stringify(selectedItems);

    this.paginator.firstPage();
    this.search(0);
  }

  //Websocket
  startWebsocket() {
    this.socket = this.websocketqueueService.websocketConnection();
    this.socket.emit('join', `idRoom-${this.idRoom}`)
      .on("updateList", (res) => this.updatePatientList(res))
      .io.on("reconnect", (res) => {
        this.socket.emit('join', `idRoom-${this.idRoom}`)
      });
    if (!this.websocketRoomUtilService.isConnect()) {
      this.websocketRoomUtilService.connectwebsocketRoom(this.idRoom, this.authService.getTokenMenu().login
        , this.authService.getTokenMenu().userName, "/multi-professional/multi-professional-patient-list", this.seeOccupiedRoom)
    }
  }

  private idInterval = setInterval(() => {
    if (this.socket) {
      if (this.socket.connected) {
        this.socketDisconneted = false;
      } else {
        this.socketDisconneted = true;
      }
    } else {
      this.socketDisconneted = false;
    }
    if (this.onlineOffline !== navigator.onLine) {
      this.onlineOffline = navigator.onLine;
    }
  }, 1000);

  // private keepAliveIntervalId = setInterval(() => {
  //   if (this.idRoom) {
  //     this.roomAccessUtilService.postRoomAccessHistory(this.idRoom);

  //   }
  // }, this.roomAccessUtilService.roomKeepAliveTime());

  ngOnDestroy() {
    if (this.socket) {
      this.socket.close();
    }
    clearInterval(this.idInterval);
    // clearInterval(this.keepAliveIntervalId);
  }

  updatePatientList(client: any) {
    this.socketDisconneted = false;
    if (client && client.action == "newpatient") {
      this.paginator.firstPage();
      this.search(0);
      return;
    }
    if (!this.listPatientResponse || !this.listPatientResponse.listPatient || this.listPatientResponse.listPatient.length == 0 || !client)
      return;

    let patient = this.listPatientResponse.listPatient.filter(x => x.idEpisode == client.idEpisode);

    let lastPatient = null;
    if (client.idLastEpisode)
      lastPatient = this.listPatientResponse.listPatient.filter(x => x.idEpisode == client.idLastEpisode);

    if (client.action == "changestatus") {
      patient[0].idStatusQueue = client.idStatusQueue;
      patient[0].statusQueueName = client.newStatusQeue;
      patient[0].attendantUserName = client.userName;
      patient[0].idAttendantUser = client.idUser;
      if (lastPatient && lastPatient[0]) {
        lastPatient[0].idStatusQueue = client.idLastStatusQueue;
        lastPatient[0].statusQueueName = client.lastStatusQueueName;
      }
    }
    else if (client.action == "removepatient") {
      let index = this.listPatientResponse.listPatient.indexOf(patient[0]);
      if (index >= 0) {
        let deleted = this.listPatientResponse.listPatient.splice(index, 1);
        if (deleted && deleted.length >= 1) {
          this.listPatientResponse.totalTickets = (this.listPatientResponse.totalTickets - 1);
          this.listPatientResponse.fullListSize = this.listPatientResponse.totalTickets;
        }
      }
    }
    else if (client.action == "newpatient") {
      this.paginator.firstPage();
      this.search(0);
    }
  }

  getRoom() {
    this.isLoading = true;
    this.roomService.getRoom(this.idRoom).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.selectedRoom = response.roomStruct;
      this.paginator.firstPage();
      this.search(0);
    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      });
  }

  getRoomUnAuth() {
    this.isLoading = true;
    this.getRoomService.getRoom(this.idRoom).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.selectedRoom = response.roomStruct;
      this.paginator.firstPage();
      this.search(0);
    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      });
  }

  isAttendedOrEvaded(idStatusQueue) {
    if (idStatusQueue == StatusQueueEnum.atendido || idStatusQueue == StatusQueueEnum.evadido) return false;
    else return true;
  }

  changePage(event: PageEvent) {
    this.search(event.pageIndex);
  }

  isEvaded(idStatusQueue) {
    return idStatusQueue == StatusQueueEnum.evadido;
  }

  openMotiveModal(idEpisode: number) {
    if (this.websocketRoomUtilService.getIsOccupied()) {
      this.alertService.show("Aviso", "Você está em modo de visualização!", AlertType.warning);
      return;
    }
    let reportDialog = this.dialog.open(ReturnMotiveModalComponent);
    reportDialog.afterClosed().subscribe((result: ReturnPatientRequest) => {
      if (result && result.motive) {
        this.isLoading = true;
        result.idEpisode = idEpisode;
        this.queueHistoryService.UpdateQueue(result).subscribe((response) => {
          if (response.isError) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            return;
          }
          this.alertService.show('Sucesso', "Paciente retornado com sucesso para fila!", AlertType.success);
          this.search(0);
        });
      }
    });
  }

  getHealthUnitConfig() {
    this.healthUnitService.getHealthUnitConfig().subscribe({
      next: (response) => {
        if (response.isError) {
          console.log(response.isError);
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
        }
        this.showTissGuideButton = response.healthUnit.canAddTissGuide;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  openModalTiss(patientStruct: ListPatientStruct) {
    this.dialog.open(TissGuideManagementModalComponent, {
      data: {
        idEpisode: patientStruct.idEpisode,
        patientName: patientStruct.patientName,
        patientSocialName: patientStruct.patientSocialName,
        idRoom: this.idRoom,
        idSector: this.idSector,
        idPatient: patientStruct.idPatient
      },
      panelClass: "tiss-guide-management-modal"
    });
  }
  
  selectPatient(patient: ListPatientStruct): void {
    this.selectedPatient = patient;
    this.selectedEpisode = patient.idEpisode;
  }
}
