<div class="row display-contents">
    <div class="col-12 padding-box">
        <div class="row border-box">
            <div class="title-header">
                <h3>Valores dos procedimentos por planos da operadora (não TISS)</h3>
            </div>
            <div class="filters">
                <div class="row">
                    <div class="col-xl-7 col-lg-7 col-sm-12 col-md-12 search-correction">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Pesquisar</mat-label>
                            <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText"
                                (keyup)="onKeySearch($event)">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <!--  -->
            <div class="col-12 table-height">
                <app-loading-progress-bar *ngIf="isLoading"></app-loading-progress-bar>
                <form [formGroup]="model" (ngSubmit)="addNewRule()">
                    <table class="col-12">
                        <thead>
                            <tr>
                                <th class="column-plan">Plano</th>
                                <th class="column-procedure">Procedimento</th>
                                <th class="column-value">Valor</th>
                                <th class="column-action">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let priceProcedurePlan of listPriceProcedurePlanStruct; let index = index;">
                                <td>{{ priceProcedurePlan.planName }}</td>
                                <td>{{ priceProcedurePlan.nameProcedure }}</td>
                                <td>
                                    <span matTextPrefix>R$&nbsp; {{priceProcedurePlan.valueFront}}</span>
                                </td>
                                <td>
                                    <a class="action-icon" (click)="editItem(priceProcedurePlan)">
                                        <mat-icon aria-hidden="false" aria-label="Editar">edit</mat-icon>
                                    </a>
                                    <a class="action-icon" (click)="deleteItem(priceProcedurePlan)">
                                        <mat-icon aria-hidden="false" aria-label="Excluir">delete</mat-icon>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div [formGroup]="model" class="col-12" style="padding-top: 10px;">
                        <div class="organize-mat-form-table" *ngIf="showAdditionalFields">
                            <div class="col-3 ajust-field">
                                <mat-form-field appearance="outline">
                                    <mat-label>Plano</mat-label>
                                    <mat-select formControlName="idPlan" (selectionChange)="onPlanChange($event.value)">
                                        <mat-option *ngFor="let item of listPlan" [value]="item.idPlan">
                                            {{item.planName}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="model.get('idPlan').invalid">Selecione ao menos um
                                        plano</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-4 ajust-field">
                                <mat-form-field appearance="outline">
                                    <mat-label>Procedimento</mat-label>
                                    <mat-select formControlName="idProcedure">
                                        <mat-option *ngFor="let procedure of listProcedurePlanFiltered"
                                            [value]="procedure.idProcedure">
                                            {{ procedure.nameProcedure }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="model.get('idProcedure').invalid">Selecione ao menos um
                                        procedimento</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-2 ajust-field">
                                <mat-form-field appearance="outline" floatLabel="always"
                                    class="display-none control-space-price-plan">
                                    <input matInput type="text" formControlName="value" class="example-right-align"
                                        placeholder="0,00" (input)="formatCurrency($event, value)"
                                        [ngModelOptions]="{standalone: true}">
                                    <span matTextPrefix>R$&nbsp;</span>
                                </mat-form-field>
                            </div>
                            <div class="col-2 button-included">
                                <button color="accent" class="btn-block" mat-flat-button type="button"
                                    [disabled]="!isValidNewRule() || isLoading" (click)="addNewRule()">
                                    Incluir +
                                </button>
                            </div>
                            <div class="col-1 button-included">
                                <button mat-flat-button type="button" class="btn-cancel" (click)="cancelEdit()">
                                    <span *ngIf="!isLoading">X</span>
                                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                                </button>
                            </div>
                        </div>
                        <div class="button-add col-12" *ngIf="!showAdditionalFields">
                            <button color="accent" color="primary" mat-flat-button type="button"
                                (click)="showAdditionalFields = true" [disabled]="isLoading">
                                Adicionar +
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="row">
                <div class="col-12">
                    <mat-paginator [length]="fullListSize" #paginator [pageIndex]="pageIndex" [pageSize]="pageSize"
                        aria-label="Select page" (page)="changePage($event)" [hidePageSize]="true">
                    </mat-paginator>
                </div>
            </div>
        </div>
        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>
</div>