import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { DataIntVariableRequest } from '../../requests/orchestrator-data-intelligence/data-int-variable.request';
import { QueueDataIntResponse } from '../../responses/orchestrator-data-intelligence/queue-data-int.response';

@Injectable({
  providedIn: 'root'
})
export class QueueDataIntService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getQueueData(idSourceData: number): Observable<QueueDataIntResponse> {
    
    let uri = `Queue/idSourceData/${idSourceData}`;

    return this.httpClient.get<QueueDataIntResponse>(environment.urlApiOrchestratorDataIntelligence + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public getVariableResult(variableRequest: DataIntVariableRequest): Observable<QueueDataIntResponse> {
    
    let uri = `Queue`;

    return this.httpClient.post<QueueDataIntResponse>(environment.urlApiOrchestratorDataIntelligence + uri, variableRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}