import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdministrationTypeEnum, DilutionEnum, FrequencyEnum, PresentationEnum, TreatmentPeriodEnum, UsageEnum } from 'src/app/shared/enum/prescription.enum';
import { MedicineAdministrationTypeEnum } from 'src/app/shared/enum/medicine-administration-type.enum';
import { MedicineStruct } from 'src/app/shared/services/structs/pharmacy/medicine.struct';
import { FavoriteStandardMedicine } from 'src/app/shared/services/models/medic/favorite-standard-medicine.model';

@Component({
  selector: 'app-favorite-medicine-form',
  templateUrl: './favorite-medicine-form.component.html',
  styleUrls: ['./favorite-medicine-form.component.css']
})
export class FavoriteMedicineFormComponent implements OnInit {

  constructor(public dialog: MatDialog,
    private alertService: AlertService,
    private formBuilder: FormBuilder
  ) { }

  @Input() medicalPrescription: FavoriteStandardMedicine;
  @Input() selectedMedicineList: MedicineStruct[] = [];
  @Input() listSubFrequency: any[];
  @Input() lookupMedicalCareResponse: any;
  @Input() listMedicine: any;

  @Output() save = new EventEmitter<any>();
  @Output() cancel = new EventEmitter<any>();

  public administrationTypeListDisplay: any[] = [];

  public model: FormGroup;
  public selectedMedicine: MedicineStruct;

  public isLoading: boolean;
  public undiluted: boolean = false;
  public isFrequency: boolean = false;

  public idAdministrationType: number;
  public idFrequency: number;
  public idTreatmentPeriod: number;
  public idPresentation: number;
  public idDilution: number;

  public administrationTypeOthersEnum: AdministrationTypeEnum = AdministrationTypeEnum.outros;
  public presentationOthersEnum: PresentationEnum = PresentationEnum.outros;
  public dilutionOthersEnum: DilutionEnum = DilutionEnum.outros;
  public ifNecessary: FrequencyEnum = FrequencyEnum.ifNecessary;
  public dilutionNoDilutionEnum: DilutionEnum = DilutionEnum.semDiluicao;

  public frequencyNow: any;

  ngOnInit(): void {

    this.model = this.formBuilder.group({
      idMedicine: [, Validators.required],
      quantity: [, Validators.max(32767)],
      idAdministrationType: [],
      idFrequency: [, Validators.required],
      idPresentation: [],
      idDilution: [],
      idTreatmentPeriod: [, Validators.required],
      presentation: [],
      dilution: [],
      administrationType: [],
      observation: [],
      idMeasurementUnit: [],
      idSubFrequency: [],
      idDilutionUnit: [],
      dilutionQuantity: [],
    });

    if (this.medicalPrescription) {
      this.model.get('idMedicine').disable();
      this.populateValues();
    }
  }

  submit() {
    if (this.model.invalid) {
      this.alertService.show('Erro', "Preencha todos os campos obrigatórios!", AlertType.error);
      return;
    }

    let favoriteMedicineModel: FavoriteStandardMedicine = new FavoriteStandardMedicine();
    favoriteMedicineModel.idMedicine = this.model.get('idMedicine').value;
    favoriteMedicineModel.medicineName = this.listMedicine.find(c => c.medicine.idMedicine == favoriteMedicineModel.idMedicine).medicine.medicineDescription;
    favoriteMedicineModel.idUsage = UsageEnum.interno;
    favoriteMedicineModel.usage = this.lookupMedicalCareResponse.listUsage.find(c => c.idUsage == favoriteMedicineModel.idUsage).usageName;
    favoriteMedicineModel.idAdministrationType = this.model.get('idAdministrationType').value ? this.model.get('idAdministrationType').value : this.lookupMedicalCareResponse.listAdministrationType.find(x => x.idAdministrationType == MedicineAdministrationTypeEnum.NaoDefinida).idAdministrationType;
    favoriteMedicineModel.administrationType = this.model.get('administrationType').value;
    favoriteMedicineModel.administrationTypeName = favoriteMedicineModel.idAdministrationType ? this.lookupMedicalCareResponse.listAdministrationType.find(x => x.idAdministrationType == favoriteMedicineModel.idAdministrationType).administrationTypeName : null;
    favoriteMedicineModel.quantity = this.model.get('quantity').value ? this.model.get('quantity').value : null;
    favoriteMedicineModel.idPresentation = this.model.get('idPresentation').value ? this.model.get('idPresentation').value : null;
    favoriteMedicineModel.presentationName = favoriteMedicineModel.idPresentation ? this.lookupMedicalCareResponse.listPresentation.find(c => c.idPresentation == favoriteMedicineModel.idPresentation).presentationName : null;
    favoriteMedicineModel.presentation = this.model.get('presentation').value;
    favoriteMedicineModel.idFrequency = this.model.get('idFrequency').value ? this.model.get('idFrequency').value : null;
    favoriteMedicineModel.frequencyName = favoriteMedicineModel.idFrequency ? this.lookupMedicalCareResponse.listFrequency.find(c => c.idFrequency == favoriteMedicineModel.idFrequency).frequencyName : null;
    favoriteMedicineModel.idTreatmentPeriod = this.model.get('idTreatmentPeriod').value ? this.model.get('idTreatmentPeriod').value : null;
    favoriteMedicineModel.treatmentPeriodName = favoriteMedicineModel.idTreatmentPeriod ? this.lookupMedicalCareResponse.listTreatmentPeriod.find(c => c.idTreatmentPeriod == favoriteMedicineModel.idTreatmentPeriod).treatmentPeriodName : null;
    favoriteMedicineModel.idDilution = this.model.get('idDilution').value ? this.model.get('idDilution').value : null;
    favoriteMedicineModel.dilutionName = this.lookupMedicalCareResponse.listDilution.find(c => c.idDilution == favoriteMedicineModel.idDilution) ? this.lookupMedicalCareResponse.listDilution.find(c => c.idDilution == favoriteMedicineModel.idDilution).dilutionName : null;
    favoriteMedicineModel.dilution = this.model.get('dilution').value;
    favoriteMedicineModel.idMeasurementUnit = this.model.get('idMeasurementUnit').value;
    favoriteMedicineModel.idSubFrequency = this.model.get('idSubFrequency').value;
    favoriteMedicineModel.idDilutionUnit = this.model.get('idDilutionUnit').value;
    favoriteMedicineModel.dilutionQuantity = this.model.get('dilutionQuantity').value;

    if(favoriteMedicineModel.idSubFrequency != null) 
      favoriteMedicineModel.subFrequencyName = this.lookupMedicalCareResponse.listSubFrequency.find(x => x.idSubFrequency == favoriteMedicineModel.idSubFrequency).subFrequencyName;
    
    if(favoriteMedicineModel.idDilutionUnit != null) 
      favoriteMedicineModel.dilutionUnitName = this.lookupMedicalCareResponse.listDilutionUnit.find(x => x.idDilutionUnit == favoriteMedicineModel.idDilutionUnit).dilutionUnitName;
    
    this.save.emit({ favoriteMedicineModel: favoriteMedicineModel });
  }

  clickCancel() {
    this.cancel.emit();
  }

  onChangeMedicine(event: any) {
    this.selectedMedicine = this.listMedicine.find(f => f.medicine.idMedicine == event.value);
    let listId: number[] = this.selectedMedicine.listIdMedicineAdministrationType;
    let naoDefinida = this.lookupMedicalCareResponse.listAdministrationType.find(f => f.idAdministrationType == MedicineAdministrationTypeEnum.NaoDefinida);
    this.administrationTypeListDisplay = this.lookupMedicalCareResponse.listAdministrationType
      .filter(f => listId.includes(f.idAdministrationType));

    if (this.administrationTypeListDisplay && this.administrationTypeListDisplay.length != 0) {
      if (this.administrationTypeListDisplay.length == 1) {
        this.administrationTypeListDisplay.unshift(naoDefinida);
        this.model.get('idAdministrationType').setValue(this.administrationTypeListDisplay[1].idAdministrationType);
        this.model.get('idAdministrationType').enable();
      }
      else {
        this.administrationTypeListDisplay.unshift(naoDefinida);
        this.model.get('idAdministrationType').setValue('');
        this.model.get('idAdministrationType').enable();
      }
    }
    else {
      this.administrationTypeListDisplay = [...this.lookupMedicalCareResponse.listAdministrationType];
      this.model.get('idAdministrationType').setValue(naoDefinida.idAdministrationType);
      this.model.get('idAdministrationType').disable();
      this.alertService.show('Aviso', 'Não há nenhuma via cadastrada para este medicamento', AlertType.warning);
    }

    if (this.selectedMedicine.medicine.idDilution)
      this.model.get('idDilution').setValue(this.selectedMedicine.medicine.idDilution.toString());
    else
      this.model.get('idDilution').setValue(null);

    if (this.selectedMedicine.medicine.idMeasurementUnit) {
      this.model.get('idMeasurementUnit').setValue(this.selectedMedicine.medicine.idMeasurementUnit);
      this.model.get('idMeasurementUnit').disable();
    }
    else {
      this.model.get('idMeasurementUnit').setValue(null);
      this.model.get('idMeasurementUnit').enable();
    }

    if (this.selectedMedicine.medicine.idMedicinePresentationType) {
      this.model.get('idPresentation').setValue(this.selectedMedicine.medicine.idMedicinePresentationType.toString());
      this.model.get('idPresentation').disable();
    }
    else {
      this.model.get('idPresentation').setValue(null);
      this.model.get('idPresentation').enable();
    }
  }

  onKey(value) {
    this.selectedMedicineList = this.search(value);
  }

  search(value: string) {
    let filter = value.toLowerCase();
    return this.listMedicine.filter(option => option.medicine.medicineDescription.toLowerCase().includes(filter));
  }

  onChangeDilution(event: number) {
    this.idDilution = event;
    if(this.idDilution == this.dilutionNoDilutionEnum.valueOf() || this.idDilution == null)
    {
      this.undiluted = false;
    }
    else
    {
      this.undiluted = true;
    }
    if (event === this.dilutionOthersEnum.valueOf())
      this.model.get('dilution').setValidators(Validators.required);
    else {
      this.model.get('dilution').setValue(null);
      this.model.get('dilution').setValidators(Validators.nullValidator);
    }

    this.model.get('dilution').updateValueAndValidity();
  }

  onChangePresentation(event: number) {
    if (event === this.presentationOthersEnum.valueOf())
      this.model.get('presentation').setValidators(Validators.required);
    else {
      this.model.get('presentation').setValue(null);
      this.model.get('presentation').setValidators(Validators.nullValidator);
    }

    this.model.get('presentation').updateValueAndValidity();
  }

  onChangeAdministrationType(event: number) {
    if (event === this.administrationTypeOthersEnum.valueOf()) {
      this.model.get('administrationType').setValue(this.selectedMedicine.medicine.medicineAdministrationOther)
      this.model.get('administrationType').setValidators(Validators.required);
    }
    else {
      this.model.get('administrationType').setValue(null);
      this.model.get('administrationType').setValidators(Validators.nullValidator);
    }

    this.model.get('administrationType').updateValueAndValidity();
  }

  populateValues() {
    this.selectedMedicine = this.listMedicine.find(f => f.medicine.idMedicine == this.medicalPrescription.idMedicine);
    let naoDefinida = this.lookupMedicalCareResponse.listAdministrationType.find(f => f.idAdministrationType == MedicineAdministrationTypeEnum.NaoDefinida);
    this.administrationTypeListDisplay = this.lookupMedicalCareResponse.listAdministrationType.filter(f => this.selectedMedicine.listIdMedicineAdministrationType.includes(f.idAdministrationType));
    this.administrationTypeListDisplay.unshift(naoDefinida);

    this.model.get('idMedicine').setValue(this.medicalPrescription.idMedicine ? this.medicalPrescription.idMedicine.toString() : null);
    this.model.get('quantity').setValue(this.medicalPrescription.quantity);
    // this.model.get('numberDrops').setValue(this.medicalPrescription.numberDrops);
    this.model.get('idAdministrationType').setValue(this.medicalPrescription.idAdministrationType ? this.medicalPrescription.idAdministrationType : null);
    this.model.get('idFrequency').setValue(this.medicalPrescription.idFrequency ? this.medicalPrescription.idFrequency.toString() : null);
    this.model.get('idPresentation').setValue(this.medicalPrescription.idPresentation ? this.medicalPrescription.idPresentation.toString() : null);
    this.model.get('idTreatmentPeriod').setValue(this.medicalPrescription.idTreatmentPeriod ? this.medicalPrescription.idTreatmentPeriod.toString() : null);
    this.model.get('idDilution').setValue(this.medicalPrescription.idDilution ? this.medicalPrescription.idDilution.toString() : null);
    this.model.get('dilution').setValue(this.medicalPrescription.dilution);
    this.model.get('presentation').setValue(this.medicalPrescription.presentation);
    this.model.get('administrationType').setValue(this.medicalPrescription.administrationType);
    this.model.get('idMeasurementUnit').setValue(this.medicalPrescription.idMeasurementUnit);
    if(this.medicalPrescription.idSubFrequency != null)
    {
      this.isFrequency = true;
      this.model.get('idSubFrequency').setValue(this.medicalPrescription.idSubFrequency.toString());
    }
    if(this.medicalPrescription.idDilutionUnit != null)
    {
      this.undiluted = true;
      this.model.get('idDilutionUnit').setValue(this.medicalPrescription.idDilutionUnit);
      this.model.get('dilutionQuantity').setValue(this.medicalPrescription.dilutionQuantity);
    }

    // if (this.model.get('idPresentation').value != null)
    //   this.model.get('idPresentation').disable();
  }

  onChangeFrequency(event: number) {
    if (event == this.ifNecessary.valueOf()) {
      this.isFrequency = true;
    }
    else {
      this.isFrequency = false;
      this.model.get('idSubFrequency').setValue(null);
    }
  }
}