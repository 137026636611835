<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    <div class="container-body">
        <h1>Gerar novo lote</h1>
        <div class="white-body">

            <div class="sub-title-and-buttons">
                <h2>Guias encontradas na busca</h2>

                <div class="search-and-submit-button">
                    <button mat-flat-button type="button" color="accent" class="btn-block"
                        (click)="openFiltersToGuideBatchModal()">
                        <span *ngIf="isLoading == false">Filtrar guias</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>

                    <button mat-flat-button type="button" color="primary" class="btn-block" (click)="selectSendDate()">
                        <span *ngIf="isLoading == false">Gerar lote</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
            </div>

            <div class="border-box">
                <app-loading-progress-bar *ngIf="isLoading"></app-loading-progress-bar>
                <div class="table-block">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="check-element-table">
                                    <mat-checkbox class="select-all-check" (click)="checkOrUncheckAll()"
                                        [indeterminate]="isIndeterminateChecked" [(ngModel)]="isCheckedAll">
                                        Selecionar todas
                                    </mat-checkbox>
                                </th>
                                <th>Episódio</th>
                                <th>Data de geração da guia</th>
                                <th>Paciente</th>
                                <th>Convênio</th>
                                <th>Tipo Guia</th>
                                <th>Nº Guia na Operadora</th>
                                <th>Valor (R$)</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="listGuideToBatchStruct && listGuideToBatchStruct.length > 0">
                            <tr *ngFor="let guideReportStruct of listGuideToBatchStruct">
                                <td class="check-element-table">
                                    <mat-checkbox (click)="checkOrUncheckOne(guideReportStruct)"
                                        [(ngModel)]="guideReportStruct.isSelected"
                                        [disabled]="this.listIdGuideSelected.size >= 100 && !guideReportStruct.isSelected">
                                    </mat-checkbox>
                                </td>
                                <td>{{ guideReportStruct.idEpisode }}</td>
                                <td>{{ guideReportStruct.datetimeInclusion | date: 'dd/MM/yyy' }}</td>
                                <td>{{ guideReportStruct.beneficiaryName }}</td>
                                <td>{{ guideReportStruct.nameHealthcareAgreement }}</td>
                                <td>{{ guideReportStruct.guideTypeName }}</td>
                                <td>{{ guideReportStruct.guideNumber }}</td>
                                <td>{{ guideReportStruct.totalValue | currency : 'R$'}}</td>
                                <td><span>{{ guideReportStruct.guideStatusName }}</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <app-empty-list
                    *ngIf="(!listGuideToBatchStruct || listGuideToBatchStruct.length == 0) && !this.isLoading"></app-empty-list>
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12">
                        <mat-paginator [length]="fullListSize" #paginator [pageIndex]="pageIndex" [pageSize]="pageSize"
                            aria-label="Select page" (page)="changePage($event.pageIndex)" [hidePageSize]="true">
                        </mat-paginator>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-4 col-md-3">
                <button mat-flat-button type="button" color="accent" class="btn-block" (click)="back()">
                    <span *ngIf="isLoading == false">Cancelar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
            </div>
        </div>        
    </div>
</div>