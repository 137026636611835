import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AllergyModel } from 'src/app/shared/services/models/medical-record/allergy.model';
import { NursingConsultationStruct } from 'src/app/shared/services/structs/medical-record/nursing-consultation.struct';

@Component({
  selector: 'app-nursing-consultation-accordion',
  templateUrl: './nursing-consultation-accordion.component.html',
  styleUrls: ['./nursing-consultation-accordion.component.css']
})
export class NursingConsultationAccordionComponent implements OnInit {

  @Input() nursingConsultation: NursingConsultationStruct;
  @Input() listAllergyEpisode: AllergyModel[];
  @Input() idEpisode: number;
  @Input() patientName: string;
  @Input() birthDate: string;
  @Input() hasObservationProcedure: boolean;
  constructor() { }

  ngOnInit(): void {
  }
}