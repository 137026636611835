import { Component, Input, OnInit } from '@angular/core';
import { ListMedicalProcedureCheckResponse } from 'src/app/shared/services/responses/medical-record/list-medical-procedure-check.response';

@Component({
  selector: 'app-bed-management-procedure-tab',
  templateUrl: './bed-management-procedure-tab.component.html',
  styleUrls: ['./bed-management-procedure-tab.component.css']
})
export class BedManagementProcedureTabComponent implements OnInit {

  constructor() { }

  @Input() listMedicalProcedureCheckResponse: ListMedicalProcedureCheckResponse;
  @Input() idSector: number;
  @Input() idService: number;
  @Input() idRoom: number;

  public isLoading: boolean;

  ngOnInit(): void {
  }
}