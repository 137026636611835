import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ErrorInScheduleChangeEnum } from 'src/app/shared/enum/schedule/error-in-schedule-change.enum';
import { PatientAppointmentStruct } from 'src/app/shared/services/structs/schedule/patientment-appointment.struct';
import { CancelAppointmentsConfirmModalComponent } from './cancel-appointments-confirm-modal/cancel-appointments-confirm-modal.component';

@Component({
  selector: 'app-prevented-changing-time-calendar-modal',
  templateUrl: './prevented-changing-time-calendar-modal.component.html',
  styleUrls: ['./prevented-changing-time-calendar-modal.component.css']
})
export class PreventedChangingTimeCalendarModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<PreventedChangingTimeCalendarModalComponent>,
  ) { }

  public isLoading: boolean = false;
  public errorInScheduleChangeEnum: typeof ErrorInScheduleChangeEnum = ErrorInScheduleChangeEnum;
  public listAffectedPatient: PatientAppointmentStruct[] = [];
  public idUser: number;

  ngOnInit(): void {
    this.listAffectedPatient = this.data.listAffectedPatient;
    this.idUser = this.data.idUser;
  }

  close() {
    this.matDialogRef.close();
  }

  openCancelConfirmModal() {
    this.isLoading = true;
    const dialogRef = this.dialog.open(CancelAppointmentsConfirmModalComponent, {
      data: {
        listAffectedPatient: this.listAffectedPatient,
        idUser: this.idUser,
      },
      panelClass: "confirm-cancel-appointment-modal"
    });

    dialogRef.beforeClosed().subscribe(result => {
      if(result && result.confirm){
        this.close();
      } 
      else{
        this.isLoading = false;
      }
    });
  }
}