import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UtilsClassificationService } from 'src/app/classification/utils.service';
import { DiscriminatorTypeEnum } from 'src/app/shared/enum/discriminator-type.enum';
import { PriorityColorEnum } from 'src/app/shared/enum/priority-color.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { DiscriminatorStruct } from 'src/app/shared/services/structs/risk-classification/discriminator.struct';
import { MultipleVictimNumericModalComponent } from '../multiple-victim-numeric-modal/multiple-victim-numeric-modal.component';

@Component({
  selector: 'app-multiple-victim-modal',
  templateUrl: './multiple-victim-modal.component.html',
  styleUrls: ['./multiple-victim-modal.component.css'],
})
export class MultipleVictimModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<MultipleVictimModalComponent>,
    private alertService: AlertService,
    private utilService: UtilsClassificationService,
    public router: Router,
    private formBuilder: FormBuilder) { }

  public model2: FormGroup;
  public frequenciaRespiratoria: number;
  public frequenciaPulso: number;
  public perfusaoCapilar: number;
  public frequenciaRespiratoriaEnum: number = DiscriminatorTypeEnum.FrequenciaRespiratoria;
  public frequenciaPulsoEnum: number = DiscriminatorTypeEnum.FrequenciaCardiaca;
  public perfusaoCapilarEnum: number = DiscriminatorTypeEnum.PerfusaoCapilar;
  public frequenciaPulsoNome: string = "Frequência de pulso anormal (maior de 120 bpm/min)";
  public frequenciaRespiratoriaNome: string = "Frequência respiratória anormal (menor que 10 ou maior que 29 ipm)";
  public perfusaoCapilarNome: string = "Perfusão capilar anormal (maior que 2 seg.)";

  ngOnInit(): void {
    this.model2 = this.formBuilder.group({
      radioFrequenciaRespiratoria: this.formBuilder.control({ value: null, disabled: true }),
      "radio-perfusao-capilar": this.formBuilder.control({ value: null, disabled: true }),
      "radio-frequencia-pulso": this.formBuilder.control({ value: null, disabled: true }),
    });
  }

  previous() {
    let data = this.utilService.getClassificationData();
    data.isPatientWhite = false;
    data.discriminatorAllValues = [];
    data.discriminatorsPersist = [];
    data.idFlowchart = null;
    this.utilService.updateClassificationData(data);
    this.router.navigate(['/classification/flowchart-select']);
  }

  setValueFrequenciaRespiratoriaSim(isInput: boolean) {
    if (!this.frequenciaRespiratoria) {
      this.abrirModal(this.frequenciaRespiratoriaEnum, this.frequenciaRespiratoriaNome);
      return;
    } else if (this.frequenciaRespiratoria >= 10 && this.frequenciaRespiratoria <= 29 && isInput) {
      this.alertService.show('Aviso', "O valor inserido não se enquadra no parâmetro definido para este discriminador!", AlertType.warning);
      this.model2.get("radioFrequenciaRespiratoria").setValue(null);
      return;
    } else if (!isInput) return;
  }

  setValueFrequenciaRespiratoriaNao(isInput: boolean) {
    if (!this.frequenciaRespiratoria) {
      this.abrirModal(this.frequenciaRespiratoriaEnum, this.frequenciaRespiratoriaNome);
      this.model2.get("radioFrequenciaRespiratoria").setValue(null);
      return;
    } else if (this.frequenciaRespiratoria < 10 || this.frequenciaRespiratoria > 29 && isInput) {
      this.setValueFrequenciaRespiratoriaSim(isInput);
      return;
    } else if (!isInput) return;
  }

  setValueperfusaoCapilarSim(isInput: boolean) {
    if (this.model2.get('radioFrequenciaRespiratoria').value === null) {
      this.alertService.show('Aviso', "É necessário negar todos os discriminadores anteriores!", AlertType.warning);
      return;
    }
    if (!this.perfusaoCapilar) {
      this.abrirModal(this.perfusaoCapilarEnum, this.perfusaoCapilarNome);
      return;
    } else if (this.perfusaoCapilar < 3 && isInput) {
      this.alertService.show('Aviso', "O valor inserido não se enquadra no parâmetro definido para este discriminador!", AlertType.warning);
      this.model2.get("radio-perfusao-capilar").setValue(null);
      return;
    } else if (!isInput) return;
  }

  setValueperfusaoCapilarNao(isInput: boolean) {
    if (this.model2.get('radioFrequenciaRespiratoria').value === null) {
      this.alertService.show('Aviso', "É necessário negar todos os discriminadores anteriores!", AlertType.warning);
      return;
    }
    if (!this.perfusaoCapilar) {
      this.abrirModal(this.perfusaoCapilarEnum, this.perfusaoCapilarNome);
      this.model2.get("radio-perfusao-capilar").setValue(null);
      return;
    } else if (this.perfusaoCapilar >= 3 && isInput) {
      this.setValueperfusaoCapilarSim(isInput);
      return;
    } else if (!isInput) return;
  }

  setValueFrequenciaPulsoSim(isInput: boolean) {
    if (this.model2.get('radio-perfusao-capilar').value === null) {
      this.alertService.show('Aviso', "É necessário negar todos os discriminadores anteriores!", AlertType.warning);
      return;
    }
    if (!this.frequenciaPulso) {
      this.abrirModal(this.frequenciaPulsoEnum, this.frequenciaPulsoNome);
      return;
    } else if (this.frequenciaPulso <= 120 && isInput) {
      this.alertService.show('Aviso', "O valor inserido não se enquadra no parâmetro definido para este discriminador!", AlertType.warning);
      this.model2.get("radio-frequencia-pulso").setValue(null);
      return;
    } else if (!isInput) return;
  }

  setValueFrequenciaPulsoNao(isInput: boolean) {
    if (this.model2.get('radio-perfusao-capilar').value === null) {
      this.alertService.show('Aviso', "É necessário negar todos os discriminadores anteriores!", AlertType.warning);
      return;
    }
    if (!this.frequenciaPulso) {
      this.abrirModal(this.frequenciaPulsoEnum, this.frequenciaPulsoNome);
      this.model2.get('radio-frequencia-pulso').setValue(null);
      return;
    } else if (this.frequenciaPulso > 120 && isInput) {
      this.setValueFrequenciaPulsoSim(isInput);
      return;
    } else if (!isInput) return;
    let data = this.utilService.getClassificationData();
    let result: DiscriminatorStruct = new DiscriminatorStruct;
    result.discriminatorName = "Frequência de pulso anormal (maior que 120/min) (Não)";
    result.idDiscriminatorType = 10;
    result.idFlowchart = data.idFlowchart;
    result.idDiscriminator = 1516;
    result.idPriorityColor = PriorityColorEnum.Urgente.valueOf();
    this.utilService.updateClassificationData(data);
    this.matDialogRef.close({ result });
  }

  abrirModal(discriminatorType: Number, discriminatorName: string) {
    if (discriminatorType == this.frequenciaPulsoEnum && this.model2.get('radio-perfusao-capilar').value === null) return;
    if (discriminatorType == this.perfusaoCapilarEnum && this.model2.get('radioFrequenciaRespiratoria').value === null) return;

    let value;

    if (discriminatorType == DiscriminatorTypeEnum.FrequenciaCardiaca) {
      value = this.data.discriminators[DiscriminatorTypeEnum.FrequenciaCardiaca]
    } else if (discriminatorType == DiscriminatorTypeEnum.PerfusaoCapilar) {
      value = this.data.discriminators[DiscriminatorTypeEnum.PerfusaoCapilar]
    } else if (discriminatorType == DiscriminatorTypeEnum.FrequenciaRespiratoria) {
      value = this.data.discriminators[DiscriminatorTypeEnum.FrequenciaRespiratoria]
    }

    const dialogRef = this.dialog.open(MultipleVictimNumericModalComponent, {
      data: {
        idDiscriminatorType: discriminatorType,
        discriminatorName,
        value,
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.value) {
        if (discriminatorType == DiscriminatorTypeEnum.FrequenciaCardiaca) {
          this.frequenciaPulso = result.value;
          this.data.discriminators[DiscriminatorTypeEnum.FrequenciaCardiaca] = result.value;
          this.model2.get("radio-frequencia-pulso").enable();
          if (result.value > 120) {
            let data = this.utilService.getClassificationData();
            let dis: DiscriminatorStruct = new DiscriminatorStruct;
            dis.discriminatorName = "Frequência de pulso anormal (maior que 120/min)";
            dis.idDiscriminatorType = DiscriminatorTypeEnum.FrequenciaCardiaca.valueOf();
            dis.idFlowchart = data.idFlowchart;
            dis.idDiscriminator = 1517;
            dis.idPriorityColor = PriorityColorEnum.Emergencia.valueOf();
            this.utilService.updateClassificationData(data);
            this.matDialogRef.close({ result: dis });
          }
        } else if (discriminatorType == DiscriminatorTypeEnum.FrequenciaRespiratoria) {
          this.frequenciaRespiratoria = result.value;
          this.data.discriminators[DiscriminatorTypeEnum.FrequenciaRespiratoria] = result.value;
          this.model2.get("radioFrequenciaRespiratoria").enable();
          if (this.frequenciaRespiratoria < 10 || this.frequenciaRespiratoria > 29) {
            let data = this.utilService.getClassificationData();
            let dis: DiscriminatorStruct = new DiscriminatorStruct;
            dis.discriminatorName = "Frequência respiratória anormal" + (this.frequenciaRespiratoria < 10 ? "(menor que 10" : "(maior que 29") + "irpm)";
            dis.idDiscriminatorType = 10;
            dis.idFlowchart = data.idFlowchart;
            dis.idDiscriminator = 1515;
            dis.idPriorityColor = PriorityColorEnum.Emergencia.valueOf();
            this.matDialogRef.close({ result: dis });
          }
        } else if (discriminatorType == DiscriminatorTypeEnum.PerfusaoCapilar) {
          this.perfusaoCapilar = result.value;
          this.data.discriminators[DiscriminatorTypeEnum.PerfusaoCapilar] = result.value;
          this.model2.get("radio-perfusao-capilar").enable();
          let data = this.utilService.getClassificationData();
          data.perfusaoCapilar = true;
          data.perfusaoCapilarValue = this.perfusaoCapilar;
          this.utilService.updateClassificationData(data);
          if (this.perfusaoCapilar >= 3) {
            let dis: DiscriminatorStruct = new DiscriminatorStruct;
            dis.discriminatorName = this.perfusaoCapilarNome;
            dis.idDiscriminatorType = DiscriminatorTypeEnum.PerfusaoCapilar.valueOf();
            dis.idFlowchart = data.idFlowchart;
            dis.idDiscriminator = 1518;
            dis.idPriorityColor = PriorityColorEnum.Emergencia.valueOf();
            this.matDialogRef.close({ result: dis });
          }
        }
      }
    });
  }
}
