import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { StorageRequest } from '../../requests/pharmacy/pharmacy.request';
import { GetStorageResponse } from '../../responses/pharmacy/get-storage.response';
import { ListStorageResponse } from '../../responses/pharmacy/list-storage.response';

@Injectable({
  providedIn: 'root'
})
export class StorageService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getStorage(idStorage: number): Observable<GetStorageResponse> {
    let uri = `Storage/idStorage/${idStorage}`;

    return this.httpClient.get<GetStorageResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public listAllStorage(pageIndex: number, code: string, batch: string, idStorage: number, description: string, initalExpirationDate: string, finalExpirationDate: string, idLocation: number): Observable<ListStorageResponse> {
    let uri = 'Storage/getAll?'

    if (pageIndex) {
      uri = uri + "pageIndex=" + pageIndex + "&";
    }
    if (code) {
      uri = uri + "code=" + code + "&";
    }
    if (batch) {
      uri = uri + "batch=" + batch + "&";
    }
    if (idStorage) {
      uri = uri + "idStorage=" + idStorage + "&";
    }
    if (description) {
      uri = uri + "description=" + description + "&";
    }
    if (initalExpirationDate) {
      uri = uri + "initalExpirationDate=" + initalExpirationDate + "&";
    }
    if (finalExpirationDate) {
      uri = uri + "finalExpirationDate=" + finalExpirationDate + "&";
    }
    if (idLocation) {
      uri = uri + "idLocation=" + idLocation + "&";
    }

    return this.httpClient.get<ListStorageResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public createStorage(body: StorageRequest): Observable<GetStorageResponse> {

    let uri = `Storage`

    return this.httpClient.post<GetStorageResponse>(environment.urlApiPharmacy + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public updateStorage(idStorage: number, idStorageAmountLocation: number, body: StorageRequest): Observable<ReturnStruct> {

    let uri = `Storage/idStorage/${idStorage}/idStorageAmountLocation/${idStorageAmountLocation}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiPharmacy + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public deleteStorage(idStorage: number): Observable<ReturnStruct> {

    let uri = `Storage/idStorage/${idStorage}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiPharmacy + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}