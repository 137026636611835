import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { ListServiceStruct } from '../../../shared/services/responses/flow/list-service-response';
import { Layout } from '../../../shared/services/responses/panel/list-layout.response';
import { PanelLogoModalComponent } from './panel-logo-modal/panel-logo-modal.component';
import { PanelRequest } from '../../../shared/services/requests/panel/panel.request';
import { RoomStruct } from 'src/app/shared/services/structs/flow/room.struct';
import { SectorStruct } from 'src/app/shared/services/structs/flow/sector.struct';
import { PanelService } from 'src/app/shared/services/API/panel/panel.service';
import { RoomService } from 'src/app/shared/services/API/flow/room.service';
import { SectorService } from 'src/app/shared/services/API/flow/sector.service';
import { ServiceService } from 'src/app/shared/services/API/flow/service.service';
import { LayoutService } from 'src/app/shared/services/API/panel/layout.service';
import { PanelOriginStruct } from 'src/app/shared/services/structs/panel/panel-origin.struct';
import { Subject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { LayoutEnum } from 'src/app/shared/enum/panel/layout.enum';
import { MaxSizeValidator } from '@angular-material-components/file-input';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-panel-register',
  templateUrl: './panel-register.component.html',
  styleUrls: ['./panel-register.component.css']
})
export class PanelRegisterComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private panelService: PanelService,
    public sanitizer: DomSanitizer,
    private roomService: RoomService,
    private sectorService: SectorService,
    private serviceService: ServiceService,
    private layoutService: LayoutService,
    public dialog: MatDialog,) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.master;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.master_panel;

  public model: FormGroup;
  public isLoading: boolean = true;
  public isFirstLoading: boolean = null;
  public isUpdate: boolean;
  public isLoadingVideo: boolean = false;
  public oldSrc: any;
  public newSrc: any;
  public listColors = ['primary', 'accent', 'warn'];
  color: ThemePalette = 'accent';

  public uploadedLogo: string;

  public haveLogo: boolean;
  public masks: Masks = this.maskService.getMasks();
  public idPanel: number = null;
  public idModuleEnumClassification: number = MenuModuleEnum.classification;

  public panelRequest: PanelRequest = new PanelRequest();
  public listService: ListServiceStruct[];
  public listSector: SectorStruct[];
  public listRoom: RoomStruct[];
  public listLayout: Layout[];
  public isDeletedModal: boolean = false;
  public logo: string;
  public video: string;
  public newLogo: string;
  public newVideo: string;
  public haveFormat: string;
  public videoName: string;
  public videoFormat: string;
  public uploadedVideo: string;
  readonly maxSize = 11534336;
  public haveVideo: boolean;
  public img1: string = `../assets/images/direito.png`;
  public img2: string = `../assets/images/esquerda.png`;

  public submitInvalidateEventToPanel: Subject<void> = new Subject<void>()

  ngOnInit(): void {
    this.populateServiceSelect();
    this.populateSectorSelect();
    this.populateRoomSelect();
    this.populateLayoutSelect();

    if (this.activatedRoute.snapshot.paramMap.get('idPanel'))
      this.idPanel = parseInt(this.activatedRoute.snapshot.paramMap.get('idPanel'));

    this.model = this.formBuilder.group({
      panelName: ['', [Validators.required]],
      description: [''],
      isActive: ['', [Validators.required]],
      layout: ['', [Validators.required]],
      haveLogo: [''],
      insertVideo: [''],
      callByPassword: [''],
      requiredfile: [null, [MaxSizeValidator(this.maxSize)]],
      listPanelOrigin: this.formBuilder.array([]),
    });

    if (this.idPanel != null) {
      this.isFirstLoading = true;
      this.isUpdate = true;
      this.populatePanelData();
    }
    else
      this.addNext();
  }

  submit() {
    if (this.isLoading)
      return;

    this.isLoading = true;

    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      this.submitInvalidateEventToPanel.next();
      this.isLoading = false;
      return;
    }

    this.panelRequest.listPanelOriginStruct = [];
    this.panelRequest.panelName = this.model.get('panelName').value;
    this.panelRequest.callByPassword = this.model.get('callByPassword').value ? true : false;
    this.panelRequest.haveLogo = this.model.get('haveLogo').value ? true : false;
    this.panelRequest.description = this.model.get('description').value;
    this.panelRequest.isActive = this.model.get('isActive').value === "true" ? true : false;
    this.panelRequest.idLayout = parseInt(this.model.get('layout').value);

    this.model.get('listPanelOrigin').value.forEach(x => {
      var panelOrigin = new PanelOriginStruct();

      panelOrigin.idRoom = x.idRoom ? parseInt(x.idRoom) : null;
      panelOrigin.idSector = x.idSector ? parseInt(x.idSector) : null;
      panelOrigin.idService = x.idService ? parseInt(x.idService) : null;
      panelOrigin.showAverageWaitingTime = x.showAverageWaitingTime ?? false;

      this.panelRequest.listPanelOriginStruct.push(panelOrigin);
    });

    if (this.newLogo && this.model.get('haveLogo').value)
      this.panelRequest.panelLogo = this.newLogo;

    if (this.newVideo) {
      this.panelRequest.video64 = this.uploadedVideo ?? this.newVideo;
      this.panelRequest.videoFormat = this.haveFormat;
    }

    if (this.isUpdate)
      this.updatePanel();
    else
      this.createPanel();
  }

  populatePanelData() {
    this.panelService.getPanel(this.idPanel).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.model.get('isActive').setValue(response.isActive.toString());
        this.model.get('description').setValue(response.description);
        this.model.get('panelName').setValue(response.panelName);
        this.model.get('layout').setValue(response.idLayout.toString());
        this.model.get('callByPassword').setValue(response.callByPassword);
        this.model.get('haveLogo').setValue(response.isLogoUploaded);
        this.model.get('requiredfile').setValue(response.video64);
        this.newVideo = response.video64;
        this.videoName = response.panelVideoName;

        if (this.videoName != null) {
          this.haveFormat = "." + this.videoName.split('.')[1];
          this.haveVideo = true;
        }

        this.logo = response.imgString64;

        for (let index = 0; index < response.listPanelOrigin.length; index++) {
          const element = response.listPanelOrigin[index];

          (this.model.controls['listPanelOrigin'] as FormArray).push(
            this.formBuilder.group({
              idRoom: [element.idRoom != null ? element.idRoom.toString() : null],
              idSector: [element.idSector != null ? element.idSector.toString() : null],
              idService: [element.idService != null ? element.idService.toString() : null],
              showAverageWaitingTime: [element.showAverageWaitingTime ?? false]
            })
          );
        }

        this.isLoading = false;
        this.isFirstLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateServiceSelect() {
    this.serviceService.listAllService().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listService = response.listService;

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateSectorSelect() {
    this.sectorService.listAllSector().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listSector = response.listSectorStruct;

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateRoomSelect() {
    this.roomService.listAllRoom().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listRoom = response.listRoomStruct;

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateLayoutSelect() {
    this.layoutService.listLayout().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listLayout = response.listLayout;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  updatePanel() {
    this.panelService.updatePanel(this.idPanel, this.panelRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;

        this.router.navigate(['/master/panel']);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  createPanel() {
    this.panelService.createPanel(this.panelRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;

        this.router.navigate(['/master/panel']);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  createInput() {
    return this.formBuilder.group({
      idRoom: [null],
      idSector: [null],
      idService: [null],
      showAverageWaitingTime: [false]
    });
  }

  addNext() {
    (this.model.controls['listPanelOrigin'] as FormArray).push(this.createInput());
  }

  removeButton(index: number) {
    (this.model.controls['listPanelOrigin'] as FormArray).removeAt(index);
  }

  openPanelLogoModal() {
    const dialogRef = this.dialog.open(PanelLogoModalComponent, {
      data: {
        logo: this.logo,
        newLogo: this.newLogo,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.newLogo)
        this.newLogo = result.newLogo;

      if (result && !result.haveLogo)
        this.model.get('haveLogo').setValue(false);
    });
  }

  inputChange(event) {
    this.isLoadingVideo = true;

    if (event == null) {
      this.isLoadingVideo = false;
      return;
    }
    else if (event.type.includes('/'))
      this.videoFormat = "." + event.type.split('/')[1];
    else
      this.videoFormat = "." + event.type;

    var reader = new FileReader();
    reader.onload = this.handleReaderLoaded.bind(this);
    reader.readAsDataURL(event);
    this.videoName = event.name;
  }

  handleReaderLoaded(e) {
    let reader = e.target;
    var readerResult = reader.result;
    var stringArray = readerResult.split("base64,", 2);
    this.newSrc = this.sanitizer.bypassSecurityTrustResourceUrl(`data:video/mp4;base64, ${stringArray[1]}`);
    this.uploadedVideo = stringArray[1];
    this.haveVideo = true;
    this.isLoadingVideo = false;
  }

  clearFileInput() {
    this.model.controls['requiredfile'].reset();

    this.videoName = "";
    this.haveVideo = false;
    this.newSrc = null;
    this.uploadedVideo = "";
  }

  public get layoutTypeEnumResult(): typeof LayoutEnum {
    return LayoutEnum;
  }
}