import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { StorageAmountLocationStruct } from 'src/app/shared/services/structs/pharmacy/storage-amount-location.struct';
import { CorrectionModalComponent } from '../correction-modal/correction-modal.component';


@Component({
  selector: 'app-location-accordion',
  templateUrl: './location-accordion.component.html',
  styleUrls: ['./location-accordion.component.css']
})
export class LocationAccordionComponent implements OnInit {

  constructor(private dialog: MatDialog, private alertService: AlertService) { }


  @Input() storageAmountLocation: StorageAmountLocationStruct[]
  @Output() wasUpdated = new EventEmitter<any>();

  ngOnInit(): void {
  }

  openCorrectionModal(item: StorageAmountLocationStruct) {
    const dialogRef = this.dialog.open(CorrectionModalComponent, {
      data: {
        ...item
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.updated) {
        this.wasUpdated.emit(true);
      }
    });
  }

}
