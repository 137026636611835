import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { BusinessIntelligenceService } from 'src/app/shared/services/API/business-intelligence/business-intelligence.service';
import { HealthUnitGroupService } from 'src/app/shared/services/API/user/health-unit-group.service';
import { HealthUnitStruct } from 'src/app/shared/services/structs/user/health-unit.struct';
import { HealthUnitReportStruct } from 'src/app/shared/services/structs/business-intelligence/health-unit-report.struct';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UserLoginResponse } from 'src/app/shared/services/responses/user/auth.response';
import { FavoriteHealthUnitService } from 'src/app/shared/services/API/business-intelligence/favorite-health-unit.service';
import { FavoriteHealthUnitSelectionRequest } from 'src/app/shared/services/requests/business-intelligence/favorite-health-unit.request';
import { FavoriteHealthUnitStruct } from 'src/app/shared/services/structs/business-intelligence/favorite-health-unit.struct';
import { FavoriteNameModalComponent } from 'src/app/shared/components/favorite-name-modal/favorite-name-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { SelectComponent } from 'src/app/shared/components/select/select.component';
import { FavoriteSelectModalComponent } from './favorite-select-modal/favorite-select-modal.component';

@Component({
  selector: 'app-business-intelligence-list',
  templateUrl: './business-intelligence-list.component.html',
  styleUrls: ['./business-intelligence-list.component.css']
})
export class BusinessIntelligenceListComponent implements OnInit {

  constructor(
    private bIService: BusinessIntelligenceService,
    private alertService: AlertService,
    private healthUnitGroupService: HealthUnitGroupService,
    private favoriteHealthUnitService: FavoriteHealthUnitService,
    private authService: AuthService,
    public dialog: MatDialog,) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.business_intelligence;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.bi_lista_relatórios;
  public isFirstLoading: boolean = false;
  public isLoading: boolean = false;
  public filterHealthUnitReport: HealthUnitReportStruct[] = [];
  public listHealthUnit: HealthUnitStruct[];
  public healthUnitReport: HealthUnitReportStruct[] = [];
  public idHealthUnit: number;
  public preselectedList: any[] = [];
  public isMaster: boolean;
  public thisHealthUnitName: string;
  private listHealthUnitGroup: HealthUnitStruct[];
  public isFavorite: boolean = false;
  public idCurrentFavorite: number = null;
  public currentFavorite: FavoriteHealthUnitStruct = null;
  public listFavorite: FavoriteHealthUnitStruct[] = [];
  public fieldLabel = 'Unidades';

  @ViewChild(SelectComponent) appSelect: SelectComponent;

  ngOnInit(): void {
    let userLoginResponse: UserLoginResponse = this.authService.getTokenMenu();
    this.isMaster = userLoginResponse.isHealthUnitGroupMaster;
    this.listHealthUnitGroup = JSON.parse(JSON.stringify(userLoginResponse.listHealthUnitGroup));
    this.listHealthUnitGroup.sort((a, b) => a.healthUnitName.localeCompare(b.healthUnitName));
    this.listHealthUnitGroup.forEach(x => x.healthUnitName = x.healthUnitName.toUpperCase());
    this.isFirstLoading = true;
    this.thisHealthUnitName = this.authService.getTokenMenu()?.healthUnit?.healthUnitName;

    let listHealthUnitGroup = sessionStorage.getItem("listIdsHealthUnitBI");

    if (listHealthUnitGroup == null) {
      this.search(null);
    }

    else {
      this.search(JSON.parse(listHealthUnitGroup));
    }

    this.searchFavorites();
  }

  search(listIds: number[]) {
    sessionStorage.setItem("listIdsHealthUnitBI", JSON.stringify(listIds));

    this.isLoading = true;
    this.bIService.getReports(listIds).subscribe({
      next: (response) => {
        this.isFirstLoading = false;
        this.isLoading = false;
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        if (response.listHealthUnitException && response.listHealthUnitException.length > 0) {
          this.listHealthUnit = this.listHealthUnitGroup.filter(c => !response.listHealthUnitException.includes(c.idHealthUnit));
        } else {
          this.listHealthUnit = this.listHealthUnitGroup;
        }

        this.idHealthUnit = response.idHealthUnit;

        if (listIds == null || listIds.length == 0) {
          if (listIds == null || listIds.length == 0) {
            listIds = [];
            listIds.push(this.idHealthUnit);
          }

          listIds.forEach(idHealthUnit => {
            this.preselectedList.push({ idHealthUnit: idHealthUnit });
          });
        }

        this.healthUnitReport = [];
        this.filterHealthUnitReport = [];

        response.healthUnitReport.forEach((c, index) => {
          this.healthUnitReport[index] = new HealthUnitReportStruct();
          this.filterHealthUnitReport[index] = new HealthUnitReportStruct();
          this.healthUnitReport[index].reports = c.reports.slice();
          this.filterHealthUnitReport[index].reports = c.reports.slice();
          this.filterHealthUnitReport[index].healthUnitName = this.listHealthUnit?.find(x => x.idHealthUnit == c.idHealthUnit)?.healthUnitName ?? this.thisHealthUnitName;
          this.healthUnitReport[index].healthUnitName = this.listHealthUnit?.find(x => x.idHealthUnit == c.idHealthUnit)?.healthUnitName ?? this.thisHealthUnitName;
        });
      },
      error: (error) => {
        this.isLoading = false;
        this.isFirstLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getSelectHealthUnit() {
    this.healthUnitGroupService.getSelectHealthUnit().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        this.search(null);
      },
      error: (error) => {
        this.isFirstLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  selectChange(values) {
    this.isFavorite = false;
    this.idCurrentFavorite = null;
    this.setFavoriteLabel();
    this.search(values.map(x => x.idHealthUnit));
  }

  openFavoriteNameModal() {
    let listIds = this.appSelect.readValues().map(x => x.idHealthUnit);
    if (listIds && listIds.length > 0) {
      const dialogRef = this.dialog.open(FavoriteNameModalComponent);
      dialogRef.afterClosed().subscribe({
        next: result => {
          if (result && result.name) {
            let request = new FavoriteHealthUnitSelectionRequest();
            request.favoriteName = result.name;
            request.listIdHealthUnit = listIds
            this.createFavorite(request);
          }
        }
      });
    }
  }

  loadFavorite() {
    const dialogRef = this.dialog.open(FavoriteSelectModalComponent, {
      data: {
        listHealthUnit: this.listHealthUnit,
        listFavorite: this.listFavorite,
        idCurrentFavorite: this.idCurrentFavorite
      }
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.favorite as FavoriteHealthUnitStruct) {
          if (!result.delete) {
            let listIds = result.favorite.listIdHealthUnit;
            this.preselectedList = this.listHealthUnit.filter(x => listIds.includes(x.idHealthUnit));
            this.search(listIds);
            this.idCurrentFavorite = result.favorite.idFavoriteHealthUnitSelection;
            this.isFavorite = true;
            this.setFavoriteLabel();
          }
          else {
            this.removeFavorite(result.favorite.idFavoriteHealthUnitSelection);
          }
        }
      }
    });
  }

  createFavorite(request: FavoriteHealthUnitSelectionRequest) {
    this.isLoading = true;
    this.favoriteHealthUnitService.createFavorite(request).subscribe({
      next: (response) => {
        if (response.isError) {
          let alertType = response.errorCode === -1 ? AlertType.warning : AlertType.error;
          let alertMessage = response.errorCode === -1 ? 'Aviso' : 'Erro';
          this.alertService.show(alertMessage, response.errorDescription, alertType);
          this.isLoading = false;
          return;
        }
        this.isFavorite = true;
        this.idCurrentFavorite = response.idFavorite;
        this.isLoading = false;
        this.alertService.show("Sucesso!", "Favorito criado com sucesso", AlertType.success);
        this.searchFavorites();
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  removeFavorite(idFavorite?: number) {
    if (!idFavorite && !this.idCurrentFavorite) {
      return;
    }
    this.isLoading = true;
    idFavorite = idFavorite ? idFavorite : this.idCurrentFavorite;

    if (!idFavorite) {
      return;
    }
    this.favoriteHealthUnitService.deleteFavorite(idFavorite).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.isFavorite = false;
        this.idCurrentFavorite = null;
        this.isLoading = false;
        this.searchFavorites()
        this.setFavoriteLabel();
        this.alertService.show("Sucesso!", "Favorito removido com sucesso", AlertType.success);
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  searchFavorites() {
    this.isLoading = true;
    this.favoriteHealthUnitService.listFavorite().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (response.listFavorite) {
          this.listFavorite = response.listFavorite;
          this.setFavoriteLabel();
        }

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  setFavoriteLabel() {
    if (this.isFavorite && this.idCurrentFavorite) {
      let favorite = this.listFavorite.find(x => x.idFavoriteHealthUnitSelection == this.idCurrentFavorite);
      if (favorite != undefined) {
        this.currentFavorite = favorite
        this.fieldLabel = `Unidades - ${this.currentFavorite.favoriteName}`;
      }
    }
    else {
      this.fieldLabel = `Unidades`;
    }
  }

  onKeySearch(event: any) {
    this.healthUnitReport.forEach((x, index) => {
      this.filterHealthUnitReport[index] = new HealthUnitReportStruct();
      this.filterHealthUnitReport[index].reports = this.healthUnitReport[index].reports.slice();
      this.filterHealthUnitReport[index].healthUnitName = this.healthUnitReport[index].healthUnitName;
    });
    if (event.target.value) {
      this.filterHealthUnitReport.forEach((x) => {
        x.reports = x.reports.filter(c => c.reportName.toLocaleLowerCase().search(event.target.value.toLocaleLowerCase()) >= 0)
      });
      this.filterHealthUnitReport = this.filterHealthUnitReport.filter(c => c.reports.length > 0);
    }
  }
}