<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <a class="back-link" routerLink="/master/profile">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Usuários
        </a>
        <h1>Cadastrar Totem de NPS</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="title-header">
                    <h1>Dados do totem de NPS</h1>
                </div>
    
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome</mat-label>
                            <input matInput type="text" formControlName="totemNpsName">
                            <mat-error *ngIf="model.get('totemNpsName').invalid">Informe o Nome</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Status</mat-label>
                            <mat-select formControlName="isActive">
                                <mat-option value="true">Ativo</mat-option>
                                <mat-option value="false">Inativo</mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('isActive').invalid">Informe o Status</mat-error>
                        </mat-form-field>
                    </div>                    
                    <div class="box-toggle col-12 col-sm-6 col-md-4">
                        <mat-slide-toggle formControlName="isToRequestCpf">Identificação obrigatória do paciente
                        </mat-slide-toggle>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Texto do NPS</mat-label>
                            <textarea matInput type="text" maxlength="160" formControlName="npsText"></textarea>
                            <mat-error *ngIf="model.get('npsText').invalid">Informe o campo "texto do NPS"</mat-error>
                        </mat-form-field>
                        <span class="nps-text-count">
                            {{ model.get('npsText').value.length ? model.get('npsText').value.length : 0 }}/160 caracteres
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <a mat-flat-button type="button" routerLink="/master/totem-nps" color="accent" class=" btn-block">
                            Cancelar
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>
    
</div>