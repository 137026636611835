import { Component, Input, OnInit } from '@angular/core';
import { ListMedicalProcedureCheckStruct } from 'src/app/shared/services/structs/medical-record/list-medical-procedure-check.struct';

@Component({
  selector: 'app-bed-management-list-procedure-checks',
  templateUrl: './bed-management-list-procedure-checks.component.html',
  styleUrls: ['./bed-management-list-procedure-checks.component.css']
})
export class BedManagementListProcedureChecksComponent implements OnInit {

  constructor() { }

  @Input() listProcedureCheck: ListMedicalProcedureCheckStruct[];
  @Input() idSector: number;
  @Input() idService: number;
  @Input() idRoom: number;

  public isLoading: boolean;

  ngOnInit(): void {
    this.isLoading = false;
  }

  statusIdToText(status: number) : string {
    if(status == 1)
      return "Em andamento";
    else(status == 2)
      return "Finalizado";
  }
}