import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { PreAdmissionRequiredFieldSectorResponse } from '../../responses/risk-classification/pre-admission-required-field-sector.reponse';
import { MetaDataStruct } from '../../structs/meta-data/meta-data.struct';

@Injectable({
  providedIn: 'root'
})
export class PreAdmissionRequiredFieldSectorService extends CommonService{
  constructor(private httpClient: HttpClient) {
    super();
  }

  listBySector(idSector: number, listMetaDataStruct: MetaDataStruct[]): Observable<PreAdmissionRequiredFieldSectorResponse>{
    let uri = `PreAdmissionRequiredFieldSector/idSector/${idSector}`;
      
    return this.httpClient.post<PreAdmissionRequiredFieldSectorResponse>(environment.urlApiRiskClassification + uri, listMetaDataStruct, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    );
  }
}