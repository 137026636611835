<div>
    <header>
        <h1 class="title-name">Atendimento Remoto</h1>
        <div class="header-logo">
            <div class="header-logo-content">
                <img class="client-brand" src="assets/images/logo-colorido.png">
            </div>
        </div>
    </header>
    <div class="body-container">
        <div class="body-content">
            <div class="white-container">
                <div class="section-title">
                    <h1>A consulta foi finalizada!</h1>

                    <div class="margin-img">
                        <img *ngIf="isMobileVersion" class="img-class-mobile" src="assets/images/ended-query.png" />
                        <img *ngIf="!isMobileVersion" class="img-class" src="assets/images/ended-query.png" />
                    </div>

                    <div class="box-internal">
                        <p class="description-box-internal">Resumo do paciente:</p>
                        <p *ngIf="forwardName" class="patient-referral">DESTINO - {{forwardName}}</p>
                    </div>
                </div>
                <div class="section-sub-title">
                    <p class="description">Você receberá, por e-mail, um documento com todas as prescrições relacionadas
                        ao
                        seu atendimento.</p>
                </div>
                <button class="forward-button" mat-flat-button color="primary" (click)="endedQuery()">
                    <span>VOLTAR AO INÍCIO</span>
                </button>
            </div>
        </div>
    </div>
</div>