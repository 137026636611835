import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-cancel-register-modal',
  templateUrl: './cancel-register-modal.component.html',
  styleUrls: ['./cancel-register-modal.component.css']
})
export class CancelRegisterModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<CancelRegisterModalComponent>) { }

  ngOnInit(): void {
  }

  close() {
    this.matDialogRef.close({ cancel: false });
  }

  clickStay() {
    this.matDialogRef.close({ cancel: false });
  }

  clickCancel() {
    this.matDialogRef.close({ cancel: true });
  }
}

