<table class="basic-table desktop">
    <thead class="basic-table__head">
        <tr>
            <th>Nome Paciente</th>
            <th>Status</th>
            <th>Atender</th>
        </tr>
    </thead>
    <tbody class="basic-table__body">
        <tr class="basic-table__body__row" *ngFor="let item of listProcedureCheck">
            <td class="truncate"><span>{{item.patientName}}</span></td>
            <td class="truncate"><span>{{item.statusName}}</span></td>
            <td>
                <div class="basic-table__body__row__action" *ngIf="isLoading == false">
                    <a>
                        <mat-icon aria-hidden="false" aria-label="Detalhes"
                            [routerLink]="['/observation/bed-management/details', {idEpisode: item.idEpisode, idSector: this.idSector, idService: this.idService, idRoom: this.idRoom, idBed: this.idBed}]">
                            vaccines</mat-icon>
                    </a>
                </div>
                <div>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="item.isLoading == true"></mat-spinner>
                </div>
            </td>
        </tr>
    </tbody>
</table>