import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { GetPharmacyConfigResponse } from '../../responses/pharmacy/get-pharmacy-config.response';

@Injectable({
  providedIn: 'root'
})
export class PharmacyConfigService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getConfigByHealthUnit(isToGetItemAutoIncrement: boolean = false, isToGetMedicineAutoIncrement: boolean = false): Observable<GetPharmacyConfigResponse> {
    let uri = 'PharmacyConfig/getConfigByHealthUnit?';

    if(isToGetItemAutoIncrement){
        uri = uri+`isToGetItemAutoIncrement=${isToGetItemAutoIncrement}&`
    } 
      
    if(isToGetMedicineAutoIncrement){
        uri = uri+`isToGetMedicineAutoIncrement=${isToGetMedicineAutoIncrement}`
    }

    return this.httpClient.get<GetPharmacyConfigResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
