<div mat-dialog-content class="next-steps-proto">
    <div class="mat-dialog-content next-steps-proto">
        <div class="head-confirm-modal-list">
            <div class="img-head">
                <img src="assets/images/icon-alert-green.png" style="width: 5rem;" />
            </div>
            <div class="title-modal">
                <h1>PROTOCOLO REGISTRADO COM SUCESSO!</h1>
            </div>
        </div>
        <div class="body-confirm-modal-list">
            <div class="row">
                <div class="body-modal">
                    <h3>Este protocolo determina que você precisa:</h3>
                </div>
                <ul *ngFor="let item of listNextStepsAfterTriageProtocol">
                    <li>{{item.nextStepsAfterTriageProtocolDescription}}</li>
                </ul>
            </div>
        </div>
        <div class="footer-confirm-modal-list">
            <button mat-flat-button color="primary" class="btn-block" (click)="clickPrimary()" type="button">
                <span>OK</span>
            </button>
        </div>
    </div>
</div>