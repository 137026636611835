import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { PostWebhookRequest } from '../../requests/integration/post-webhook.request';
import { PutWebhookRequest } from '../../requests/integration/put-webhook.request';
import { GetWebhookResponse } from '../../responses/integration/get-webhook.response';
import { ListWebhookResponse } from '../../responses/integration/list-webhook.response';

@Injectable({
  providedIn: 'root'
})
export class ManageWebhookService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getWebhook(idWebhook: number): Observable<GetWebhookResponse> {
    
    let uri = `ManageWebhook/idWebhook/${idWebhook}`
    
    return this.httpClient.get<GetWebhookResponse>(environment.urlApiIntegration + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public listWebhook(searchText: string, isActive: boolean): Observable<ListWebhookResponse> {
    
    let uri = `ManageWebhook?`

    if(searchText != null)
      uri = uri+ `searchText=${searchText}&`;
    
    if(isActive !=null)
      uri = uri + `isActive=${isActive}&`  
    
    return this.httpClient.get<ListWebhookResponse>(environment.urlApiIntegration + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public createWebhook(request: PostWebhookRequest): Observable<ReturnStruct> {
    
    let uri = `ManageWebhook`
    
    return this.httpClient.post<ReturnStruct>(environment.urlApiIntegration + uri, request, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public updateWebhook(request: PutWebhookRequest, idWebhook: number): Observable<ReturnStruct> {
    
    let uri = `ManageWebhook/idWebhook/${idWebhook}`
    
    return this.httpClient.put<ReturnStruct>(environment.urlApiIntegration + uri, request, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public deleteWebhook(idWebhook: number): Observable<ReturnStruct> {
    
    let uri = `ManageWebhook/idWebhook/${idWebhook}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiIntegration + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
