import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { ListServiceStruct } from '../../../shared/services/responses/flow/list-service-response';
import { FlowRequest } from '../../../shared/services/requests/flow/flow.request';
import { ExpressionRequest } from 'src/app/shared/services/requests/flow/expression.request';
import { VariableRequest } from 'src/app/shared/services/requests/flow/variable.request';
import { RoomStruct } from 'src/app/shared/services/structs/flow/room.struct';
import { FlowService } from 'src/app/shared/services/API/flow/flow.service';
import { RoomService } from 'src/app/shared/services/API/flow/room.service';
import { SectorService } from 'src/app/shared/services/API/flow/sector.service';
import { ServiceService } from 'src/app/shared/services/API/flow/service.service';
import { VariableService } from 'src/app/shared/services/API/flow/variable.service';
import { TotemButtonService } from 'src/app/shared/services/API/totem/totem-button.service';
import { ReceptionService } from 'src/app/shared/services/API/flow/reception.service';
import { FlowLookupService } from 'src/app/shared/services/API/flow/lookup.service';
import { SectorStruct } from 'src/app/shared/services/structs/flow/sector.struct';
import { VariableModel } from 'src/app/shared/services/models/flow/variable.model';
import { LogicalExpressionModel } from 'src/app/shared/services/models/flow/logical-expresion.model';
import { ConjunctionModel } from 'src/app/shared/services/models/flow/conjunction.model';
import { TotemButtonStruct } from 'src/app/shared/services/structs/totem/totem-button.struct';
import { VariablesTypeFlowEnum } from 'src/app/shared/enum/variables-flow.enum';
import { FlowTagService } from 'src/app/shared/services/API/flow/flow-tag.service';
import { TagStruct } from 'src/app/shared/components/tag/tag.struct';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModuleService } from 'src/app/shared/services/API/user/module.service';
import { ModuleWithDigitalPromptServiceStruct } from 'src/app/shared/services/structs/user/module.with.digital.prompt.service.struct';

@Component({
  selector: 'app-flow-register',
  templateUrl: './flow-register.component.html',
  styleUrls: ['./flow-register.component.css']
})
export class FlowRegisterComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private flowService: FlowService,
    private flowTagService: FlowTagService,
    private roomService: RoomService,
    private sectorService: SectorService,
    private serviceService: ServiceService,
    private variableService: VariableService,
    private totemButtonService: TotemButtonService,
    private receptionService: ReceptionService,
    private lookupService: FlowLookupService,
    private moduleService: ModuleService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.master;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.master_flow;

  public model: FormGroup;
  public isLoading: boolean = false;
  public isFirstLoading: boolean;
  public isUpdate: boolean = false;
  public actualServiceModule: number;
  public masks: Masks = this.maskService.getMasks();
  public idFlow: number = null;
  public listService: ListServiceStruct[];
  public listServiceTelemedicine: ListServiceStruct[];
  public listSector: SectorStruct[];
  public listRoom: RoomStruct[];
  public listTotemButton: TotemButtonStruct[];
  public flowRequest: FlowRequest = new FlowRequest();
  public listVariable: VariableModel[];
  public listLogicalExpression: LogicalExpressionModel[];
  public listConjunction: ConjunctionModel[];
  public listTags: TagStruct[];
  public listAllTags: TagStruct[];

  public listServiceReception: ListServiceStruct[];
  public listSectorReception: SectorStruct[];
  public listRoomReception: RoomStruct[];

  public listDestinationService: ListServiceStruct[];
  public listDestinationSector: SectorStruct[];
  public listDestinationRoom: RoomStruct[];

  public isOriginService: boolean = true;
  public isOriginSector: boolean = false;
  public isOriginRoom: boolean = false;
  public isOriginTotemButton: boolean = false;
  public isOriginTelemedicine: boolean = false;
  public isOriginDigitalPromptService: boolean = false;

  public isDestinyService: boolean = true;
  public isDestinySector: boolean = false;
  public isDestinyRoom: boolean = false;
  public isDestinyDigitalPromptService: boolean = false;

  public isIntermediaryService: boolean = true;
  public isIntermediarySector: boolean = false;
  public isIntermediaryRoom: boolean = false;

  public isEligible: boolean = false;
  public isExpressionsInvalid: boolean = false;
  public isExpressionsAutoInvalid: boolean = false;

  public expressionMessage: string;
  public showExpressionMessage: boolean = false;

  public showIntermediaryLocation: boolean = false;
  public checkIntermediaryLocation: boolean = false;
  public isTelemedicineNotValid: boolean = false;

  public selectVariable: VariablesTypeFlowEnum = VariablesTypeFlowEnum.select;
  public ageVariable: VariablesTypeFlowEnum = VariablesTypeFlowEnum.age;
  public numberVariable: VariablesTypeFlowEnum = VariablesTypeFlowEnum.number;
  public stringVariable: VariablesTypeFlowEnum = VariablesTypeFlowEnum.string;
  public dateVariable: VariablesTypeFlowEnum = VariablesTypeFlowEnum.date;
  public arrayVariables: any[] = [];
  public arrayAutoVariables: any[] = [];
  public listOriginModuleWithDigitalPromptServiceStruct: ModuleWithDigitalPromptServiceStruct[] = [];
  public listDestinyModuleWithDigitalPromptServiceStruct: ModuleWithDigitalPromptServiceStruct[] = [];

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      name: ['', [Validators.required]],
      status: ['', [Validators.required]],
      description: [''],
      idOriginService: ['', [Validators.required]],
      idOriginSector: [''],
      idOriginRoom: [''],
      idOriginTotemButton: [''],
      idOriginTelemedicine: [''],
      idDestinyService: ['', [Validators.required]],
      idDestinySector: [''],
      idDestinyRoom: [''],
      idIntermediaryService: ['', [Validators.required]],
      idIntermediarySector: [''],
      idIntermediaryRoom: [''],
      priority: ['', [Validators.required]],
      expressions: this.formBuilder.array([]),
      expressionsAuto: this.formBuilder.array([]),
    });

    this.populateTags();
    this.populateServiceSelect();
    this.populateSectorSelect();
    this.populateRoomSelect();
    this.populateEligibleSectionSelect();
    this.populateTotemButtonSelect();
    this.populateIntermediaryLocation();

    if (this.activatedRoute.snapshot.paramMap.get('idFlow'))
      this.idFlow = parseInt(this.activatedRoute.snapshot.paramMap.get('idFlow'));

    if (this.idFlow != null) {
      this.isFirstLoading = true;
      this.isUpdate = true;
      this.model.get('idOriginService').setValidators([]);
      this.model.get('idDestinyService').setValidators([]);
      this.model.get('idIntermediaryService').setValidators([]);
      this.isOriginService = false;
      this.isDestinyService = false;
      this.isIntermediaryService = false;
      this.populateFlowData();
    }
    else {
      this.listTags = [];
    }
  }

  submit() {
    if (this.isLoading || this.model.invalid || this.validaExpressoes()) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    this.isLoading = true;
    this.flowRequest.description = this.model.get('description').value;

    this.flowRequest.idOriginService = this.model.get('idOriginService').value ? this.model.get('idOriginService').value : null;

    this.flowRequest.idDestinationService = this.model.get('idDestinyService').value ? this.model.get('idDestinyService').value : null;

    this.flowRequest.idOriginSector = this.model.get('idOriginSector').value ? this.model.get('idOriginSector').value : null;
    this.flowRequest.idDestinationSector = this.model.get('idDestinySector').value ? this.model.get('idDestinySector').value : null;

    this.flowRequest.idOriginRoom = this.model.get('idOriginRoom').value ? this.model.get('idOriginRoom').value : null;
    this.flowRequest.idDestinationRoom = this.model.get('idDestinyRoom').value ? this.model.get('idDestinyRoom').value : null;

    if (this.checkIntermediaryLocation) {
      this.flowRequest.idIntermediaryService = this.model.get('idIntermediaryService').value ? this.model.get('idIntermediaryService').value : null;
      this.flowRequest.idIntermediarySector = this.model.get('idIntermediarySector').value ? this.model.get('idIntermediarySector').value : null;
      this.flowRequest.idIntermediaryRoom = this.model.get('idIntermediaryRoom').value ? this.model.get('idIntermediaryRoom').value : null;
    }

    this.flowRequest.idOriginTotemButton = this.model.get('idOriginTotemButton').value ? this.model.get('idOriginTotemButton').value : null;

    this.flowRequest.priority = this.model.get('priority').value;

    this.flowRequest.isActive = this.model.get('status').value;
    this.flowRequest.flowName = this.model.get('name').value;
    this.flowRequest.expressions = [];
    this.flowRequest.expressionsAuto = [];
    this.model.get('expressions').value.forEach((x, i) => {
      var expression = new ExpressionRequest();
      expression.idConjunction = x.idConjunction ? parseInt(x.idConjunction) : null;
      expression.idVariable = x.idVariable;
      expression.idLogicalExpression = x.idLogicalExpression;
      expression.value = x.value;
      expression.index = i;
      this.flowRequest.expressions.push(expression);
    });
    this.model.get('expressionsAuto').value.forEach((x, i) => {
      var expressionAuto = new ExpressionRequest();
      expressionAuto.idConjunction = x.idConjunction ? parseInt(x.idConjunction) : null;
      expressionAuto.idVariable = x.idVariable;
      expressionAuto.idLogicalExpression = x.idLogicalExpression;
      expressionAuto.value = x.value;
      expressionAuto.index = i;
      this.flowRequest.expressionsAuto.push(expressionAuto);
    });

    this.flowRequest.listTags = this.listTags;

    if (this.isUpdate)
      this.updateFlow();
    else
      this.createFlow();
  }

  populateTags() {
    this.flowTagService.listTags().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.listAllTags = response.listTag;
    },
      (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  populateFlowData() {
    var variableRequest = new VariableRequest();
    this.flowService.getFlow(this.idFlow).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        this.model.get('name').setValue(response.flow.flowName);
        this.model.get('status').setValue(response.flow.isActive);
        this.model.get('priority').setValue(response.flow.priority);
        this.model.get('description').setValue(response.flow.description);
        var getVariables = false;
        if (response.flow.idOriginService) {
          this.isOriginService = true
          this.model.get('idOriginService').setValue(response.flow.idOriginService);
          this.model.get('idOriginService').disable();
          variableRequest.idService = response.flow.idOriginService;
          getVariables = true;
        }
        if (response.flow.idDestinationService) {
          this.isDestinyService = true;
          this.model.get('idDestinyService').setValue(response.flow.idDestinationService);
          this.model.get('idDestinyService').disable();
        }
        if (response.flow.idOriginSector) {
          this.isOriginSector = true;
          getVariables = true;
          this.model.get('idOriginSector').setValue(response.flow.idOriginSector);
          this.model.get('idOriginSector').disable();
          variableRequest.idSector = response.flow.idOriginSector;
        }
        if (response.flow.idDestinationSector) {
          this.isDestinySector = true
          this.model.get('idDestinySector').setValue(response.flow.idDestinationSector);
          this.model.get('idDestinySector').disable();
        }
        if (response.flow.idOriginRoom) {
          getVariables = true;
          this.isOriginRoom = true
          this.model.get('idOriginRoom').setValue(response.flow.idOriginRoom);
          this.model.get('idOriginRoom').disable();
          variableRequest.idRoom = response.flow.idOriginRoom;
        }
        if (response.flow.idDestinationRoom) {
          this.isDestinyRoom = true
          this.model.get('idDestinyRoom').setValue(response.flow.idDestinationRoom);
          this.model.get('idDestinyRoom').disable();
        }
        if (response.flow.idOriginTotemButton) {
          this.isOriginTotemButton = true
          this.model.get('idOriginTotemButton').setValue(response.flow.idOriginTotemButton);
          this.model.get('idOriginTotemButton').disable();
        }
        if (response.flow.idIntermediaryService) {
          this.isIntermediaryService = true;
          this.showIntermediaryLocation = true;
          this.checkIntermediaryLocation = true;
          this.model.get('idIntermediaryService').setValue(response.flow.idIntermediaryService);
          this.model.get('idIntermediaryService').disable();
        }
        if (response.flow.idIntermediarySector) {
          this.isIntermediarySector = true;
          this.showIntermediaryLocation = true;
          this.checkIntermediaryLocation = true;
          this.model.get('idIntermediarySector').setValue(response.flow.idIntermediarySector);
          this.model.get('idIntermediarySector').disable();
        }
        if (response.flow.idIntermediaryRoom) {
          this.isIntermediaryRoom = true;
          this.showIntermediaryLocation = true;
          this.checkIntermediaryLocation = true;
          this.model.get('idIntermediaryRoom').setValue(response.flow.idIntermediaryRoom);
          this.model.get('idIntermediaryRoom').disable();
        }

        response.expressions.forEach((x) => {
          (this.model.controls['expressions'] as FormArray).push(
            this.formBuilder.group({
              idConjunction: [x.idConjunction ? x.idConjunction.toString() : null],
              idLogicalExpression: [x.idLogicalExpression.toString()],
              idVariable: [x.idVariable.toString()],
              value: [x.value]
            })
          )
        });
        response.expressionsAuto.forEach(x => {
          (this.model.controls['expressionsAuto'] as FormArray).push(
            this.formBuilder.group({
              idConjunction: [x.idConjunction ? x.idConjunction.toString() : null],
              idLogicalExpression: [x.idLogicalExpression.toString()],
              idVariable: [x.idVariable.toString()],
              value: [x.value],
            })
          )
        });
        if (getVariables) {
          this.populateSelectVariables(variableRequest);
        }

        this.listTags = response.listTags;

        this.isFirstLoading = false;
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateServiceSelect() {
    this.serviceService.listAllService().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.listService = [...response.listService];
      this.listDestinationService = [...response.listService];
    },
      (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  getModuleWithDigitalPromptService() {
    this.moduleService.GetModuleWithDigitalPromptService().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listOriginModuleWithDigitalPromptServiceStruct = response.listModuleWithDigitalPromptServiceStruct;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateSectorSelect() {
    this.sectorService.listAllSector().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }

      this.listSector = [...response.listSectorStruct];
      this.listDestinationSector = [...response.listSectorStruct];
    },
      (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  populateRoomSelect() {
    this.roomService.listAllRoom().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }

      this.listRoom = [...response.listRoomStruct];
      this.listDestinationRoom = [...response.listRoomStruct];
    },
      (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  populateEligibleSectionSelect() {
    this.lookupService.listConjunction().subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.listConjunction = response.listConjunction;
    });
    this.lookupService.listLogicalExpression().subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.listLogicalExpression = response.listLogicalExpression;
    });
  }

  populateIntermediaryLocation() {
    this.receptionService.LocationReception().subscribe({
      next: (response) => {
        if (response.isError) {
          this.showIntermediaryLocation = false;
          this.changeRadioIntermediary({ value: null })
          if (response.errorCode == 1)
            this.alertService.show('Aviso', response.errorDescription, AlertType.warning);
          else
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        this.showIntermediaryLocation = true;
        this.listServiceReception = [];
        this.listServiceReception.push(response.services)
        this.listSectorReception = response.sectors;
        this.listRoomReception = response.rooms;
        if (!this.isUpdate) {
          this.model.get('idIntermediaryService').setValue(this.listServiceReception[0].idService.toString());
          this.model.get('idIntermediaryService').disable();
        }
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateTotemButtonSelect() {
    this.totemButtonService.listTotemButton().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listTotemButton = response.listTotemButton;
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  updateFlow() {
    this.flowService.updateFlow(this.idFlow, this.flowRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          console.log(response.isError)
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;

        this.router.navigate(['/master/flow']);

      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  createFlow() {
    this.flowService.createFlow(this.flowRequest).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/master/flow']);

    },
      (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  changeRadioOrigin(event) {
    this.cleanSelectionsOriginsDestiny(1);
    var origin = event.value;
    this.isEligible = false;
    this.isOriginService = false
    this.isOriginSector = false
    this.isOriginRoom = false
    this.isOriginTotemButton = false
    // this.isOriginTelemedicine = false
    this.isTelemedicineNotValid = false;
    this.isOriginDigitalPromptService = false;

    this.model.get('idOriginService').setValidators([]);
    this.model.get('idOriginSector').setValidators([]);
    this.model.get('idOriginRoom').setValidators([]);
    this.model.get('idOriginTotemButton').setValidators([]);
    this.model.get('idOriginTelemedicine').setValidators([]);

    if (origin == 1) {
      this.isOriginService = true;
      this.model.get('idOriginService').setValidators([Validators.required]);
    }
    else if (origin == 2) {
      this.isOriginSector = true;
      this.model.get('idOriginSector').setValidators([Validators.required]);
    }
    else if (origin == 3) {
      this.isOriginRoom = true;
      this.model.get('idOriginRoom').setValidators([Validators.required]);
    }
    else if (origin == 4) {
      this.isOriginTotemButton = true;
      this.model.get('idOriginTotemButton').setValidators([Validators.required]);
    }

    this.model.get('idOriginService').updateValueAndValidity();
    this.model.get('idOriginSector').updateValueAndValidity();
    this.model.get('idOriginRoom').updateValueAndValidity();
    this.model.get('idOriginTotemButton').updateValueAndValidity();
    this.model.get('idOriginTelemedicine').updateValueAndValidity();
  }

  filterDigitalPromptServiceDestinations(idFunctionalityGroup: number) {
    this.listDestinationService = this.listService.filter(
      x => (x.idModule == MenuModuleEnum.digital_prompt_service && x.idFunctionalityGroup != idFunctionalityGroup)
        || x.idModule == MenuModuleEnum.medic);

    this.listDestinationSector = this.listSector.filter(
      x => (x.idModule == MenuModuleEnum.digital_prompt_service && x.idFunctionalityGroup != idFunctionalityGroup)
        || x.idModule == MenuModuleEnum.medic);

    this.listDestinationRoom = this.listRoom.filter(
      x => (x.idModule == MenuModuleEnum.digital_prompt_service && x.idFunctionalityGroup != idFunctionalityGroup)
        || x.idModule == MenuModuleEnum.medic);
  }

  changeSelectOrigin(event) {
    var variableRequest = new VariableRequest();
    this.isEligible = false;
    var getVariables = false;
    if (event.source.ngControl.name == "idOriginSector") {
      variableRequest.idSector = event.value;
      getVariables = true;
      let origin = this.listSector.find(x => x.idSector == event.value);
      if (origin.idModule == MenuModuleEnum.digital_prompt_service) {
        this.isOriginDigitalPromptService = true;
        this.filterDigitalPromptServiceDestinations(origin.idFunctionalityGroup);
      }
      else {
        this.isOriginTelemedicine = false;
        this.isOriginDigitalPromptService = false;
        this.listDestinationService = this.listService;
        this.listDestinationSector = this.listSector;
        this.listDestinationRoom = this.listRoom;
      }
    } else if (event.source.ngControl.name == "idOriginRoom") {
      variableRequest.idRoom = event.value;
      getVariables = true;
      let origin = this.listRoom.find(x => x.idRoom == event.value);
      if (origin.idModule == MenuModuleEnum.digital_prompt_service) {
        this.isOriginDigitalPromptService = true;
        this.filterDigitalPromptServiceDestinations(origin.idFunctionalityGroup);
      }
      else {
        this.isOriginTelemedicine = false;
        this.isOriginDigitalPromptService = false;
        this.listDestinationService = this.listService;
        this.listDestinationSector = this.listSector;
        this.listDestinationRoom = this.listRoom;
      }
    } else if (event.source.ngControl.name == "idOriginService") {
      variableRequest.idService = event.value;
      getVariables = true;
      let origin = this.listService.find(x => x.idService == event.value);
      if (origin.idModule == MenuModuleEnum.digital_prompt_service) {
        this.isOriginDigitalPromptService = true;
        this.filterDigitalPromptServiceDestinations(origin.idFunctionalityGroup);
      }
      else {
        this.isOriginTelemedicine = false;
        this.isOriginDigitalPromptService = false;
        this.listDestinationService = this.listService;
        this.listDestinationSector = this.listSector;
        this.listDestinationRoom = this.listRoom;
      }
    }

    if (getVariables)
      this.populateSelectVariables(variableRequest);
  }

  populateSelectVariables(variableRequest: VariableRequest) {
    this.variableService.getVariables(variableRequest).subscribe((response) => {
      if (response.isError) {
        this.showExpressionMessage = true;
        this.expressionMessage = 'Não é possível criar regras: ' + response.errorDescription;
        return;
      }
      this.isEligible = true;
      this.showExpressionMessage = false;
      this.listVariable = response.listVariable;
      this.listVariable.forEach((c) => {
        if (c.idType == VariablesTypeFlowEnum.select) {
          this.variableService.getValuesVariables(c.url).subscribe((response) => {
            if (response.isError) {
              this.alertService.show('Erro', "Ao buscar os valores da variável:" + c.variableName, AlertType.error);
              return
            }
            c.listValues = response.list;
          });
        }
      })
      if (this.idFlow) {
        this.model.controls['expressions']['controls'].forEach((x) => {
          let item = this.listVariable.findIndex(c => c.idVariable == x.value.idVariable);
          this.arrayVariables.push({ type: this.listVariable[item].idType, index: item });
        });
        this.model.controls['expressionsAuto']['controls'].forEach(x => {
          let item = this.listVariable.findIndex(c => c.idVariable == x.value.idVariable);
          this.arrayAutoVariables.push({ type: this.listVariable[item].idType, index: item });
        });
      }
    });
  }

  changeRadioDestiny(event) {
    this.cleanSelectionsOriginsDestiny(2);
    var destiny = event.value;
    this.isDestinyService = false
    this.isDestinySector = false
    this.isDestinyRoom = false
    this.isDestinyDigitalPromptService = false
    this.model.get('idDestinyService').setValidators([]);
    this.model.get('idDestinySector').setValidators([]);
    this.model.get('idDestinyRoom').setValidators([]);
    if (destiny == 1) {
      this.isDestinyService = true
      this.model.get('idDestinyService').setValidators([Validators.required]);
    }
    else if (destiny == 2) {
      this.isDestinySector = true
      this.model.get('idDestinySector').setValidators([Validators.required]);
    }
    else if (destiny == 3) {
      this.isDestinyRoom = true
      this.model.get('idDestinyRoom').setValidators([Validators.required]);
    }

    this.model.get('idDestinyService').updateValueAndValidity();
    this.model.get('idDestinySector').updateValueAndValidity();
    this.model.get('idDestinyRoom').updateValueAndValidity();
  }

  changeRadioIntermediary(event) {
    this.cleanSelectionsOriginsDestiny(3);
    var Intermediary = event.value;
    this.isIntermediaryService = false;
    this.isIntermediarySector = false;
    this.isIntermediaryRoom = false;
    this.model.get('idIntermediaryService').setValidators([]);
    this.model.get('idIntermediarySector').setValidators([]);
    this.model.get('idIntermediaryRoom').setValidators([]);
    if (Intermediary == 1) {
      this.isIntermediaryService = true
      this.model.get('idIntermediaryService').setValue(this.listServiceReception[0].idService);
      this.model.get('idIntermediaryService').setValidators([Validators.required]);
    } else if (Intermediary == 2) {
      this.isIntermediarySector = true
      this.model.get('idIntermediarySector').setValidators([Validators.required]);
    } else if (Intermediary == 3) {
      this.isIntermediaryRoom = true
      this.model.get('idIntermediaryRoom').setValidators([Validators.required]);
    }
    this.model.get('idIntermediaryService').updateValueAndValidity();
    this.model.get('idIntermediarySector').updateValueAndValidity();
    this.model.get('idIntermediaryRoom').updateValueAndValidity();
  }

  cleanSelectionsOriginsDestiny(selection: number) {
    if (selection == 1) {
      this.model.get('idOriginService').setValue(null);
      this.model.get('idOriginSector').setValue(null);
      this.model.get('idOriginRoom').setValue(null);
      this.model.get('idOriginTotemButton').setValue(null);
      this.model.get('idOriginTelemedicine').setValue(null);
    } else if (selection == 2) {
      this.model.get('idDestinyService').setValue(null);
      this.model.get('idDestinySector').setValue(null);
      this.model.get('idDestinyRoom').setValue(null);
    } else {
      this.model.get('idIntermediaryService').setValue(null);
      this.model.get('idIntermediarySector').setValue(null);
      this.model.get('idIntermediaryRoom').setValue(null);
    }
  }

  changeCheckIntermediary(event) {
    this.model.get('idIntermediaryService').setValue(null);
    this.model.get('idIntermediarySector').setValue(null);
    this.model.get('idIntermediaryRoom').setValue(null);
    this.model.get('idIntermediaryService').setValidators([]);
    this.model.get('idIntermediarySector').setValidators([]);
    this.model.get('idIntermediaryRoom').setValidators([]);
    if (this.isIntermediaryService) {
      this.model.get('idIntermediaryService').setValidators([Validators.required]);
      this.model.get('idIntermediaryService').setValue(this.listServiceReception[0].idService);
    }
    else if (this.isIntermediarySector) {
      this.model.get('idIntermediarySector').setValidators([Validators.required]);
    }
    else if (this.isIntermediaryRoom) {
      this.model.get('idIntermediaryRoom').setValidators([Validators.required]);
    }
    this.model.get('idIntermediaryService').updateValueAndValidity();
    this.model.get('idIntermediarySector').updateValueAndValidity();
    this.model.get('idIntermediaryRoom').updateValueAndValidity();
    this.checkIntermediaryLocation = event.checked;
  }

  validaAge(value) {
    if (!value) {
      return;
    }
    let splitYears = value.split("a");
    let splitMonth = splitYears[1].split("m");
    let days: string = splitMonth[1].replace("d");
    let month: string = splitMonth[0];
    let years: string = splitYears[0];
    if (years.includes("_") || days.includes("_") || month.includes("_")) {
      this.alertService.show('Erro', "O padrão do campo idade é 010anos 01meses 02dias.", AlertType.error);
      return false;
    }
    if (parseInt(days) > 29) {
      this.alertService.show('Erro', "O valor máximo de dias do campo idade é 29, após deve ser adicionado no mês!", AlertType.error);
      return false;
    }
    if (parseInt(month) > 11) {
      this.alertService.show('Erro', "O valor máximo de meses do campo idade é 11, após deve ser adicionado no ano!", AlertType.error);
      return false;
    }
    return true;
  }

  validaExpressoes() {
    this.isExpressionsAutoInvalid = false;
    this.isExpressionsInvalid = false;
    this.model.get('expressions').value.forEach((x, i) => {
      let teste = true;
      if (i == 0) {
        teste = x.idVariable || x.value || x.idLogicalExpression;
      } else {
        teste = x.idConjunction || x.idVariable || x.value || x.idLogicalExpression;
      }
      if (x.idVariable == this.ageVariable && !this.validaAge(x.value)) {
        teste = false;
      }
      if (!teste) {
        this.isExpressionsInvalid = true;
        return;
      }
    });
    this.model.get('expressionsAuto').value.forEach((x, i) => {
      let teste = true;
      if (i == 0) {
        teste = x.idVariable || x.value || x.idLogicalExpression;
      } else {
        teste = x.idConjunction || x.idVariable || x.value || x.idLogicalExpression;
      }
      if (x.idVariable == this.ageVariable && !this.validaAge(x.value)) {
        teste = false;
      }
      if (!teste) {
        this.isExpressionsAutoInvalid = true;
        return;
      }
    });
    return this.isExpressionsAutoInvalid || this.isExpressionsInvalid;
  }
}