import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilsClassificationService } from 'src/app/classification/utils.service';
import { DiscriminatorTypeEnum } from 'src/app/shared/enum/discriminator-type.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { DiscriminatorModel } from 'src/app/shared/services/models/risk-classification/discriminator.model';

@Component({
  selector: 'app-multiple-victim-numeric-modal',
  templateUrl: './multiple-victim-numeric-modal.component.html',
  styleUrls: ['./multiple-victim-numeric-modal.component.css']
})
export class MultipleVictimNumericModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<MultipleVictimNumericModalComponent>,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private maskService: MaskService) { }

  public model: FormGroup;
  public discriminator: DiscriminatorModel;
  public masks: Masks;

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      value: [this.data.value, Validators.required],
    });
  }

  close() {
    this.matDialogRef.close();
  }

  submit() {
    if (this.model.invalid) {
      this.alertService.show('Erro', "Preencha o campo!", AlertType.error);
      return;
    }
    let value = this.model.get("value").value;
    this.matDialogRef.close({ value });
  }
}

