import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { CounselingStruct } from 'src/app/shared/services/structs/risk-classification/counseling.struct';
import { ClassificationData, UtilsClassificationService } from 'src/app/classification/utils.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { MaskService } from 'src/app/shared/services/mask.service';

@Component({
  selector: 'app-iframe-counseling-selection',
  templateUrl: './counseling-selection.component.html',
  styleUrls: ['./counseling-selection.component.css']
})

export class IframeCounselingSelectionComponent implements OnInit {

  constructor(
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    private maskService: MaskService,
    private utilClassificationService: UtilsClassificationService,
    private utilService: UtilService,
  ) { }

  @Output() previousPage = new EventEmitter<any>();
  @Output() selectedCounseling = new EventEmitter<any>();

  public isFirstLoading: boolean = true;
  public idEpisode: number;
  public flowchartName: string;
  public outOfOrder: boolean;
  public discriminators: any[] = [];
  public data: ClassificationData;
  public model: UntypedFormGroup;
  public gravity: string;
  public noneOfTheAbove: CounselingStruct;
  public listCounseling: CounselingStruct[];
  public noOptions: boolean;
  public patientAge: string;

  ngOnInit(): void {

    this.model = this.formBuilder.group({
      observation: [''],
    });

    this.data = this.utilClassificationService.getClassificationData();
    this.listCounseling = this.data.AllListCounseling;
    this.noneOfTheAbove = new CounselingStruct();
    this.noneOfTheAbove.counselingName = "Nenhuma das opcões acima";
    this.noneOfTheAbove.isChecked = false;
    this.idEpisode = this.data.idEpisode;
    this.flowchartName = this.data.flowchartName;
    this.outOfOrder = this.data.outOfOrder;
    this.patientAge = this.utilService.getAgeString(this.maskService.formatDateToString(this.data.patientData.birthDate));
  }

  submit() {
    if (this.listCounseling)
      this.data.listCounseling = this.listCounseling.filter(c => c.isChecked === true);

    this.noOptions = (!this.data.listCounseling || this.data.listCounseling.length == 0) && (this.noneOfTheAbove.isChecked != true);

    if (this.noOptions) {
      this.alertService.show('Erro', 'Selecione algum aconselhamento ou o campo "Nenhuma das opções acima" ', AlertType.error);
      return;
    }

    this.data.observation = this.model.get('observation').value;

    if (this.noneOfTheAbove.isChecked && !this.data.observation) {
      this.alertService.show('Erro', 'Se nenhum aconselhamento for selecionado, o campo "Observação" é obrigatório', AlertType.error);
      return;
    }

    this.utilClassificationService.updateClassificationData(this.data);
    this.selectedCounseling.emit();
  };

  goBack() {
    this.previousPage.emit();
  }

  noneCheck(): void {
    if (this.noneOfTheAbove.isChecked == true) {
      this.model.get('observation').setValidators(Validators.required);
      if (this.listCounseling) {
        this.listCounseling.forEach((c: CounselingStruct) => {
          c.isChecked = false;
        });
      }
    }
  }
}