<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <h1>Auditorias</h1>
        <div class="white-body">
            <div class="filters">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Identificador Auditoria</mat-label>
                            <input matInput type="number" id="searchText" [(ngModel)]="idAudit"
                                (keyup)="onKeySearch($event)">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Mês/Ano</mat-label>
                            <mat-select [(ngModel)]="yearMonth" (selectionChange)="setDays($event.value)">
                                <mat-option>Todos</mat-option>
                                <mat-option *ngFor="let item of listDate" [value]="item">{{item | date:"MMM/yyyy"}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Dia Inicial</mat-label>
                            <mat-select [(ngModel)]="initialDate" [disabled]="!isMonthSelected"
                                (selectionChange)="clearFinalDayValue($event.value); searchByDate()">
                                <mat-option>Todos</mat-option>
                                <mat-option *ngFor="let item of listDays" [value]="item">{{item}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Dia Final</mat-label>
                            <mat-select [(ngModel)]="finalDate" [disabled]="!isMonthSelected"
                                (selectionChange)="searchByDate()">
                                <mat-option>Todos</mat-option>
                                <ng-container *ngFor="let item of listDays">
                                    <mat-option [value]="item" *ngIf="item >= this.initialDate">{{item}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="card-list" *ngIf="listClassificationAudit != null">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listClassificationAudit; let index = index;">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary">{{item.classificationAudit.idClassificationRiskAudit}} -
                                    {{item.classificationAudit.datetimeInclusion | date: 'dd/MM/yyyy'}}</span>
                                <span class="secundary">1° - {{item.firstAuditorName}} </span>
                                <span class="secundary">2° - {{item.secondAuditorName}} </span>
                            </div>
                            <div class="actions">
                                <a *ngIf="!isGeneratingReport"><mat-icon aria-hidden="false"
                                        aria-label="Gerar Relatório"
                                        (click)="openModalReport(item.classificationAudit.idClassificationRiskAudit, index)">summarize</mat-icon></a>
                                <mat-spinner [diameter]="30" class="mat-spinner" *ngIf="item.isGenerating"></mat-spinner>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <app-empty-list
                *ngIf="listClassificationAudit != undefined && listClassificationAudit.length == 0"></app-empty-list>
        </div>
        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>
</div>