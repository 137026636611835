<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-exam-type-modal-list">
            <h2>Deseja excluir o Configuração?</h2>
        </div>

        <div class="body-exam-type-modal-list">
            Você realmente deseja excluir esta configuração? Se sim, todos os campos obrigatórios pertencentes a este(s)
            setores não estarão visíveis na próxima impressão de Laudo, até que uma outra configuração vincule os
            setores novamente. </div>
        <div class="footer-exam-type-modal-list">
            <button mat-flat-button color="primary" class="btn-block" (click)="clickDelete()">
                <span *ngIf="isLoading == false">Sim, excluir</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
            <button mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Cancelar</button>
        </div>
    </div>
</div>