export class TotemNpsRequest{
    public totemNpsName: string;
    
    public npsText: string;

    public healthUnitName: string;
    
    public isActive: boolean;

    public isToRequestCpf: boolean;
}