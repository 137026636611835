<div mat-dialog-content>
    <ngContainer *ngIf="!isLoading">
        <div class="mat-dialog-container-medic-hold-modal">
            <div class="text-and-count">
                <h1>Aguardando o paciente entrar na consulta!</h1>
                <h2>Ainda restam:</h2>
                <div class="countdown-el">
                    <p id="segundo">{{this.mockTime}}</p>
                </div>
                
                <h2>Para o início do atendimento</h2>
            </div>
            <div class="orientation-image">
                <img  src="assets/images/medic-hold-modal-image.png">                
                <p>
                    <mat-icon>warning</mat-icon>
                    Mantenha-se no centro da tela para que o seu paciente te veja corretamente!
                </p>
            </div>            
        </div>
    </ngContainer>
    <ng-container *ngIf="isLoading">
        <div class="mat-dialog-container-medic-hold-modal-after">
            <h1>O paciente saiu da fila!</h1>
        </div>
    </ng-container>
</div>