<app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
<div class="white-body">
    <h1 class="border-green-left-title">Pré Cadastro - Paciente Remoto</h1>
    <form class="form" [formGroup]="model" (ngSubmit)="submit()">
        <div class="title-header">
            <div class="row">
                <div class="col-6 col-sm-6 col-md-6">
                    <h1>Dados Pessoais</h1>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-6 col-md-3">
                <mat-form-field appearance="outline">
                    <mat-label>Nome</mat-label>
                    <input matInput type="text" formControlName="patientName">
                    <mat-error *ngIf="model.get('patientName').invalid">Informe o nome do paciente</mat-error>
                </mat-form-field>
            </div>
            <div class="col-12 col-sm-6 col-md-3">
                <mat-form-field appearance="outline">
                    <mat-label>Nome Social</mat-label>
                    <input matInput type="text" formControlName="socialName">
                </mat-form-field>
            </div>
            <div class="col-6 col-sm-3 col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>Data de Nascimento</mat-label>
                    <input matInput type="text" (blur)="onKeySearchAge($event)" formControlName="birthDate"
                        [mask]="masks.date">
                </mat-form-field>
            </div>

            <div class="col-6 col-sm-3 col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>Idade</mat-label>
                    <input matInput type="text" formControlName="patientAge">
                </mat-form-field>
            </div>
            <div class="col-6 col-sm-3 col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>Genêro</mat-label>
                    <mat-select formControlName="idGender">
                        <mat-option *ngFor="let item of genderList" [value]="item.idGender">
                            {{item.genderName}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="model.get('idGender').invalid">Informe o gênero</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-6 col-sm-3 col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>CPF</mat-label>
                    <input matInput type="text" formControlName="cpf" [mask]="masks.cpf"
                        (keyup)="onKeySearchCPF($event)">
                    <mat-error *ngIf="model.get('cpf').invalid">Informe o CPF</mat-error>
                </mat-form-field>
            </div>
            <div class="col-6 col-sm-3 col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>CNS</mat-label>
                    <input matInput type="text" formControlName="cns">
                    <mat-error *ngIf="model.get('cns').invalid">Informe o CNS</mat-error>
                </mat-form-field>
            </div>
            <div class="col-12 col-sm-6 col-md-3">
                <mat-form-field appearance="outline">
                    <mat-label>Prioridade</mat-label>
                    <mat-select formControlName="listIdPriority" multiple #prioritySelect (selectionChange)="validatePriorities($event)">
                        <mat-option *ngFor="let item of listOfPriorities" [value]="item.idPriority"
                            [disabled]="disallowedIdPriorities.includes(item.idPriority)">{{item.priorityName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="title-header">
        </div>
        <div class="row">
            <div class="col-12 col-sm-6 col-md-8">
            </div>
            <div class="col-12 col-sm-3 col-md-2">
                <a mat-flat-button type="button"
                    [routerLink]="['/classification/classification-patient-list', {idSector:idSector, idRoom: idRoom}]"
                    color="accent" class=" btn-block">
                    Voltar
                </a>
            </div>
            <div class="col-12 col-sm-3 col-md-2">
                <button mat-flat-button type="submit" color="primary" class=" btn-block">
                    <span *ngIf="isLoading == false">Salvar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
            </div>
        </div>
    </form>
</div>