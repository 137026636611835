<div class="counter-body">
    <div class="title">
        {{label}}
    </div>
    <div class="value">
        <div class="vertical-aling-out">
            <div class="vertical-aling-inner">
                {{ value | number : '1.0-2'}}
            </div>
        </div>
    </div>
</div>
