import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ReadMedicalPrescriptionStruct } from 'src/app/shared/services/structs/orchestrator-patient/read-medical-prescription.struct';
import { FormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AdministrationTypeEnum, DilutionEnum, FrequencyEnum, PresentationEnum, TreatmentPeriodEnum, UsageEnum } from 'src/app/shared/enum/prescription.enum';
import { MedicRequiredFieldEnum } from 'src/app/shared/enum/medic-required-field.enum';
import { MedicineAdministrationTypeEnum } from 'src/app/shared/enum/medicine-administration-type.enum';
import { MedicineStruct } from 'src/app/shared/services/structs/pharmacy/medicine.struct';
import { AllergyModel } from 'src/app/shared/services/models/medical-record/allergy.model';
import { AllergyStruct } from 'src/app/shared/services/structs/medical-record/allergy.struct';
import { MedicinePresentationTypeEnum } from 'src/app/shared/enum/pharmacy/medicine-presentation-type.enum';
import { ReadMultiProfessionalPrescriptionStruct } from 'src/app/shared/services/structs/orchestrator-patient/read-multi-professional-prescription.struct';
import { ConfirmAllergicPatientModalComponent } from '../confirm-allergic-patient-modal/confirm-allergic-patient-modal.component';

@Component({
  selector: 'app-prescription-modal',
  templateUrl: './prescription-modal.component.html',
  styleUrls: ['./prescription-modal.component.css']
})
export class PrescriptionModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<PrescriptionModalComponent>,
    private alertService: AlertService,
    private formBuilder: UntypedFormBuilder
  ) { }

  public isLoading: boolean;
  public medicalPrescription: ReadMedicalPrescriptionStruct;
  public prescriptionModel: UntypedFormGroup;
  public selectedMedicineList: MedicineStruct[] = [];
  public medicineList: MedicineStruct[] = [];
  public selectedMedicine: MedicineStruct;
  public usageList: any[] = [];
  public administrationTypeList: any[] = [];
  public administrationTypeListDisplay: any[] = [];
  public frequencyList: any[] = [];
  public presentationList: any[] = [];
  public treatmentList: any[] = [];
  public dilutionList: any[] = [];
  public measurementUnitList: any[] = [];
  public administrationTypeOthersEnum: AdministrationTypeEnum = AdministrationTypeEnum.outros;
  public presentationOthersEnum: PresentationEnum = PresentationEnum.outros;
  public dilutionOthersEnum: DilutionEnum = DilutionEnum.outros;

  public idAdministrationType: number;
  public idFrequency: number
  public idTreatmentPeriod: number;
  public idPresentation: number;
  public idDilution: number;
  public bypassPrescription: boolean = false;
  public isFastTrack: boolean = false;
  public justification: string;
  public listAllergy: AllergyModel[] = [];
  public frequencyEnum: FrequencyEnum = FrequencyEnum.now;
  public isFrequency: boolean = false;
  public listSubFrequency: any[];
  public frequencyNow: any;
  public ifNecessary: FrequencyEnum = FrequencyEnum.ifNecessary;
  // public isBottle: boolean = false;
  // public bottleEnum: MedicinePresentationTypeEnum = MedicinePresentationTypeEnum.Frasco;

  ngOnInit(): void {
    this.medicalPrescription = this.data.prescription;
    this.isFastTrack = this.data.isFastTrack;
    this.isLoading = false;
    this.medicineList = this.data.listMedicine.listMedicineStruct;
    this.listAllergy = this.data.listAllergy;
    if (this.isFastTrack) {
      this.selectedMedicineList = this.data.listMedicine.listMedicineStruct.filter(x => x.medicine.fastTrack == true);
    }
    else {
      this.selectedMedicineList = this.data.listMedicine.listMedicineStruct;
    }
    this.populateLookup();
    this.prescriptionModel = this.formBuilder.group({
      idMedicine: [{ value: null, disabled: this.data.readOnly }, Validators.required],
      quantity: [{ value: null, disabled: this.data.readOnly }, Validators.max(32767)],
      idAdministrationType: [{ value: null, disabled: this.data.readOnly }],
      idFrequency: [{ value: '', disabled: this.data.readOnly }, Validators.required],
      idPresentation: [{ value: null, disabled: this.data.readOnly }],
      idDilution: [{ value: null, disabled: this.data.readOnly }],
      idTreatmentPeriod: [{ value: '', disabled: this.data.readOnly }, Validators.required],
      presentation: [{ value: '', disabled: this.data.readOnly }],
      dilution: [{ value: '', disabled: this.data.readOnly }],
      administrationType: [{ value: '', disabled: this.data.readOnly }],
      observation: [{ value: '', disabled: this.data.readOnly }],
      idMeasurementUnit: [{ value: null, disabled: this.data.readOnly }],
      idSubFrequency: [{ value: '', disabled: this.data.readOnly }],
      // numberDrops: [{ value: null, disabled: this.data.readOnly }],
    });
    if (this.medicalPrescription) {
      this.prescriptionModel.get('idMedicine').disable();
      this.populateValues();
    }

    this.verifyRequiredFields();
  }
  populateLookup() {
    this.usageList = this.data.lookupMedicalCareResponse.listUsage;
    this.administrationTypeList = this.data.lookupMedicalCareResponse.listAdministrationType;
    this.frequencyList = this.data.lookupMedicalCareResponse.listFrequency;
    this.presentationList = this.data.lookupMedicalCareResponse.listPresentation;
    this.treatmentList = this.data.lookupMedicalCareResponse.listTreatmentPeriod;
    this.dilutionList = this.data.lookupMedicalCareResponse.listDilution;
    this.measurementUnitList = this.data.lookupMedicalCareResponse.listMeasurementUnit;
    this.listSubFrequency = this.data.lookupMedicalCareResponse.listSubFrequency;
    if(this.frequencyList)
    {
      this.organizeList()
    }
  }

  populateValues() {
    this.selectedMedicine = this.medicineList.find(f => f.medicine.idMedicine == this.medicalPrescription.idMedicine);
    let naoDefinida = this.administrationTypeList.find(f => f.idAdministrationType == MedicineAdministrationTypeEnum.NaoDefinida);
    this.administrationTypeListDisplay = this.administrationTypeList.filter(f => this.selectedMedicine.listIdMedicineAdministrationType.includes(f.idAdministrationType));
    this.administrationTypeListDisplay.unshift(naoDefinida);
    
    this.prescriptionModel.get('idMedicine').setValue(this.medicalPrescription.idMedicine ? this.medicalPrescription.idMedicine.toString() : null);
    this.prescriptionModel.get('quantity').setValue(this.medicalPrescription.quantity);
    // this.prescriptionModel.get('numberDrops').setValue(this.medicalPrescription.numberDrops);
    this.prescriptionModel.get('idAdministrationType').setValue(this.medicalPrescription.idAdministrationType ? this.medicalPrescription.idAdministrationType : null);
    this.prescriptionModel.get('idFrequency').setValue(this.medicalPrescription.idFrequency ? this.medicalPrescription.idFrequency.toString() : null);
    this.prescriptionModel.get('idPresentation').setValue(this.medicalPrescription.idPresentation ? this.medicalPrescription.idPresentation.toString() : null);
    this.prescriptionModel.get('idTreatmentPeriod').setValue(this.medicalPrescription.idTreatmentPeriod ? this.medicalPrescription.idTreatmentPeriod.toString() : null);
    this.prescriptionModel.get('observation').setValue(this.medicalPrescription.observation);
    this.prescriptionModel.get('idDilution').setValue(this.medicalPrescription.idDilution ? this.medicalPrescription.idDilution.toString() : null);
    this.prescriptionModel.get('dilution').setValue(this.medicalPrescription.dilution);
    this.prescriptionModel.get('presentation').setValue(this.medicalPrescription.presentation);
    this.prescriptionModel.get('administrationType').setValue(this.medicalPrescription.administrationType);
    this.prescriptionModel.get('idMeasurementUnit').setValue(this.medicalPrescription.idMeasurementUnit);
    this.prescriptionModel.get('idSubFrequency').setValue(this.medicalPrescription.idSubFrequency ? this.medicalPrescription.idSubFrequency.toString() : null);

    if (this.data.readOnly) {
      this.idAdministrationType = this.medicalPrescription.idAdministrationType;
      this.idFrequency = this.medicalPrescription.idFrequency;
      this.idTreatmentPeriod = this.medicalPrescription.idTreatmentPeriod;
    }
  }

  clickCancel() {
    this.matDialogRef.close();
  }
  close() {
    this.matDialogRef.close();
  }

  onChangeMedicine(event: any) {
    this.selectedMedicine = this.medicineList.find(f => f.medicine.idMedicine == event.value);
    let listId: number[] = this.selectedMedicine.listIdMedicineAdministrationType;
    let naoDefinida = this.administrationTypeList.find(f => f.idAdministrationType == MedicineAdministrationTypeEnum.NaoDefinida);
    this.administrationTypeListDisplay = this.administrationTypeList.filter(f => listId.includes(f.idAdministrationType));

    if (this.administrationTypeListDisplay && this.administrationTypeListDisplay.length != 0) {
      if (this.administrationTypeListDisplay.length == 1) {

        this.administrationTypeListDisplay.unshift(naoDefinida);
        this.prescriptionModel.get('idAdministrationType').setValue(this.administrationTypeListDisplay[1].idAdministrationType);
        this.prescriptionModel.get('idAdministrationType').enable();
      }
      else {
        this.administrationTypeListDisplay.unshift(naoDefinida);
        this.prescriptionModel.get('idAdministrationType').setValue('');
        this.prescriptionModel.get('idAdministrationType').enable();
      }
    }
    else {
      this.administrationTypeListDisplay = [...this.administrationTypeList];
      this.prescriptionModel.get('idAdministrationType').setValue(naoDefinida.idAdministrationType);
      this.prescriptionModel.get('idAdministrationType').disable();
      this.alertService.show('Aviso', 'Não há nenhuma via cadastrada para este medicamento', AlertType.warning);
    }
    if (this.selectedMedicine.medicine.idDilution) {
      this.prescriptionModel.get('idDilution').setValue(this.selectedMedicine.medicine.idDilution.toString());
    }
    else {
      this.prescriptionModel.get('idDilution').setValue(null);
    }
    if (this.selectedMedicine.medicine.idMeasurementUnit) {
      this.prescriptionModel.get('idMeasurementUnit').setValue(this.selectedMedicine.medicine.idMeasurementUnit);
      this.prescriptionModel.get('idMeasurementUnit').disable();
    }
    else{
      this.prescriptionModel.get('idMeasurementUnit').setValue( null);
      this.prescriptionModel.get('idMeasurementUnit').enable();
    }
    if(this.selectedMedicine.medicine.idMedicinePresentationType)
    {
      this.prescriptionModel.get('idPresentation').setValue(this.selectedMedicine.medicine.idMedicinePresentationType.toString());
      this.prescriptionModel.get('idPresentation').disable();
    }
    else
    {
      this.prescriptionModel.get('idPresentation').setValue( null);
      this.prescriptionModel.get('idPresentation').enable();
    }
    
    if (this.selectedMedicine.listAllergy && this.selectedMedicine.listAllergy.length > 0) {
      let allergyDetected: boolean = false;

      allergyDetected = this.selectedMedicine.listAllergy.some((it) => this.listAllergy.find(it2 => it2.idAllergy == it));
      if (allergyDetected) {
        const dialogRef = this.dialog.open(ConfirmAllergicPatientModalComponent, {
          data: {},
        });
        dialogRef.afterClosed().subscribe({
          next: (result) => {
            if (result && result.cancel) {
              this.prescriptionModel.get('idMedicine').setValue('');
              this.prescriptionModel.get('idAdministrationType').setValue('');
              this.prescriptionModel.get('idAdministrationType').enable();
              this.prescriptionModel.get('idDilution').setValue('');
              this.prescriptionModel.get('idMeasurementUnit').setValue(null);
              this.prescriptionModel.get('idPresentation').setValue( null);
            } else if (result && !result.cancel && result.justification) {
              this.justification = result.justification;
            }
          }
        });
      }
    }
  }

  onChangePresentation(event: number) {
    this.idPresentation = event;
    if (this.idPresentation == this.presentationOthersEnum.valueOf()) {
      this.prescriptionModel.get('presentation').setValidators(Validators.required);
    } else {
      this.prescriptionModel.get('presentation').setValue(null);
      this.prescriptionModel.get('presentation').setValidators(Validators.nullValidator);
    }
    this.prescriptionModel.get('presentation').updateValueAndValidity();

    // if (event == this.bottleEnum)
    //   this.isBottle = true;
    // else
    //   this.isBottle = false;
  }

  onChangeAdministrationType(event: number) {
    this.idAdministrationType = event;
    if (this.idAdministrationType == this.administrationTypeOthersEnum.valueOf()) {
      this.prescriptionModel.get('administrationType').setValue(this.selectedMedicine.medicine.medicineAdministrationOther)
      this.prescriptionModel.get('administrationType').setValidators(Validators.required);
    } else {
      this.prescriptionModel.get('administrationType').setValue(null);
      this.prescriptionModel.get('administrationType').setValidators(Validators.nullValidator);
    }
    this.prescriptionModel.get('administrationType').updateValueAndValidity();
  }

  onChangeDilution(event: number) {
    this.idDilution = event;
    if (this.idDilution == this.dilutionOthersEnum.valueOf()) {
      this.prescriptionModel.get('dilution').setValidators(Validators.required);
    } else {
      this.prescriptionModel.get('dilution').setValue(null);
      this.prescriptionModel.get('dilution').setValidators(Validators.nullValidator);
    }
    this.prescriptionModel.get('dilution').updateValueAndValidity();
  }

  onKey(value) {
    this.selectedMedicineList = this.search(value);
  }

  search(value: string) {
    let filter = value.toLowerCase();
    return this.medicineList.filter(option => option.medicine.medicineDescription.toLowerCase().includes(filter));
  }
  onSubmit() {
    if (this.prescriptionModel.invalid) {
      this.alertService.show('Erro', "Preencha todos os campos obrigatórios!", AlertType.error);
      return;
    }
    let newPrescription: ReadMultiProfessionalPrescriptionStruct = new ReadMultiProfessionalPrescriptionStruct();
    newPrescription.idMedicine = this.prescriptionModel.get('idMedicine').value;
    newPrescription.medicineName = this.medicineList.find(c => c.medicine.idMedicine == newPrescription.idMedicine).medicine.medicineDescription;
    newPrescription.idUsage = UsageEnum.interno;
    newPrescription.usage = this.usageList.find(c => c.idUsage == newPrescription.idUsage).usageName;
    newPrescription.idAdministrationType = this.prescriptionModel.get('idAdministrationType').value ? this.prescriptionModel.get('idAdministrationType').value : this.administrationTypeList.find(x => x.idAdministrationType == MedicineAdministrationTypeEnum.NaoDefinida).idAdministrationType;
    newPrescription.administrationType = this.prescriptionModel.get('administrationType').value;
    newPrescription.administrationTypeName = newPrescription.idAdministrationType ? this.administrationTypeList.find(x => x.idAdministrationType == newPrescription.idAdministrationType).administrationTypeName : null;
    newPrescription.quantity = this.prescriptionModel.get('quantity').value ? this.prescriptionModel.get('quantity').value : null;
    newPrescription.idPresentation = this.prescriptionModel.get('idPresentation').value ? this.prescriptionModel.get('idPresentation').value : null;
    newPrescription.presentationName = newPrescription.idPresentation ? this.presentationList.find(c => c.idPresentation == newPrescription.idPresentation).presentationName : null;
    newPrescription.presentation = this.prescriptionModel.get('presentation').value;
    newPrescription.idFrequency = this.prescriptionModel.get('idFrequency').value ? this.prescriptionModel.get('idFrequency').value : null;
    newPrescription.frequencyName = newPrescription.idFrequency ? this.frequencyList.find(c => c.idFrequency == newPrescription.idFrequency).frequencyName : null;
    newPrescription.idTreatmentPeriod = this.prescriptionModel.get('idTreatmentPeriod').value ? this.prescriptionModel.get('idTreatmentPeriod').value : null;
    newPrescription.treatmentPeriodName = newPrescription.idTreatmentPeriod ? this.treatmentList.find(c => c.idTreatmentPeriod == newPrescription.idTreatmentPeriod).treatmentPeriodName : null;
    newPrescription.idDilution = this.prescriptionModel.get('idDilution').value ? this.prescriptionModel.get('idDilution').value : null;
    newPrescription.dilutionName = this.dilutionList.find(c => c.idDilution == newPrescription.idDilution) ? this.dilutionList.find(c => c.idDilution == newPrescription.idDilution).dilutionName : null;
    newPrescription.dilution = this.prescriptionModel.get('dilution').value;
    newPrescription.idMeasurementUnit = this.prescriptionModel.get('idMeasurementUnit').value;
    newPrescription.observation = this.prescriptionModel.get('observation').value;
    newPrescription.idSubFrequency = this.prescriptionModel.get('idSubFrequency').value;
    if(newPrescription.idSubFrequency)
    {
      newPrescription.subFrequencyName = this.data.lookupMedicalCareResponse.listSubFrequency.find(x => x.idSubFrequency == newPrescription.idSubFrequency).subFrequencyName;
    }
    if (this.justification)
      newPrescription.justification = this.justification;
    
    this.matDialogRef.close({ newPrescription: newPrescription, bypassPrescription: this.bypassPrescription });
  }

  verifyRequiredFields() {
    if (this.data.medicConfig) {
      this.data.medicConfig.listIdRequiredField.forEach(x => {

        if (x == MedicRequiredFieldEnum.prescriptionQuantity) {
          this.prescriptionModel.get('quantity').setValidators([Validators.required]);
          this.prescriptionModel.get('quantity').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.prescriptionAdministrationType) {
          this.prescriptionModel.get('idAdministrationType').setValidators([Validators.required]);
          this.prescriptionModel.get('idAdministrationType').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.prescriptionDilution) {
          this.prescriptionModel.get('idDilution').setValidators([Validators.required]);
          this.prescriptionModel.get('idDilution').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.prescriptionPresentation) {
          this.prescriptionModel.get('idPresentation').setValidators([Validators.required]);
          this.prescriptionModel.get('idPresentation').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.prescriptionObservation) {
          this.prescriptionModel.get('observation').setValidators([Validators.required]);
          this.prescriptionModel.get('observation').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.prescriptionMeasurementUnit) {
          this.prescriptionModel.get('idMeasurementUnit').setValidators([Validators.required]);
          this.prescriptionModel.get('idMeasurementUnit').updateValueAndValidity();
        }
      });
    }
  }


  toggleBypassPrescription(event: any) {
    if (event.checked) {
      this.bypassPrescription = true;
      this.selectedMedicineList = this.data.listMedicine.listMedicineStruct;
    }
    else {
      this.bypassPrescription = false;
      this.selectedMedicineList = this.data.listMedicine.listMedicineStruct.filter(x => x.medicine.fastTrack == true);
      let idMedicine: number = this.prescriptionModel.get('idMedicine').value;
      let medicine: MedicineStruct = this.data.listMedicine.listMedicineStruct.find(x => x.medicine.idMedicine == idMedicine);
      if (!this.selectedMedicineList.includes(medicine)) {
        this.prescriptionModel.get('idMedicine').setValue(null);
      }
    }
  }

  onChangeFrequency(event: number) {
    if (event == FrequencyEnum.ifNecessary) {
      this.isFrequency = true;
    }
    else {
      this.isFrequency = false;
      this.prescriptionModel.get('idSubFrequency').setValue(null);
    }
  }

  organizeList()
  {
    this.frequencyNow = this.frequencyList.find(x => x.idFrequency == this.frequencyEnum.valueOf());
    this.frequencyList.splice(this.frequencyList.findIndex(x => x.idFrequency == this.frequencyEnum.valueOf()), 1);
    this.frequencyList.unshift(this.frequencyNow);
  }
}