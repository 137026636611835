import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DiscriminatorTypeEnum } from 'src/app/shared/enum/discriminator-type.enum';
import { DiscriminatorModel } from 'src/app/shared/services/models/risk-classification/discriminator.model';
import { GlasgowModalComponent } from './glasgow-modal/glasgow-modal.component';
import { TemperatureModalComponent } from './temperature-modal/temperature-modal.component';
import { SaturationModalComponent } from './saturation-modal/saturation-modal.component';
import { HeartRateModalComponent } from './heart-rate-modal/heart-rate-modal.component';
import { ClassificationData, UtilsClassificationService } from 'src/app/classification/utils.service';
import { GlucoseModalComponent } from './glucose-modal/glucose-modal.component';
import { CapillaryGlucoseModalComponent } from './capillary-glucose-modal/capillary-glucose-modal.component';
import { RespiratoryFrequencyModalComponent } from './respiratory-frequency-modal/respiratory-frequency-modal.component';
import { PressureModalComponent } from './pressure-modal/pressure-modal.component';
import { PainModalComponent } from './pain-modal/pain-modal.component';
import { NumericModalComponent } from './numeric-modal/numeric-modal.component';
import { SepsisModalComponent } from './sepsis-modal/sepsis-modal.component';
import { DiscriminatorStruct } from 'src/app/shared/services/structs/risk-classification/discriminator.struct';
import { SepsisCriancaModalComponent } from './sepsis-crianca-modal/sepsis-crianca-modal.component';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-discriminator',
  templateUrl: './discriminator.component.html',
  styleUrls: ['./discriminator.component.css']
})
export class DiscriminatorComponent implements OnInit, OnChanges {

  constructor(private alertService: AlertService, public dialog: MatDialog, public util: UtilsClassificationService) { }

  @Input() discriminatorsValues: any[] = [];
  @Input() discriminator: DiscriminatorStruct;
  @Input() openModal: boolean;
  @Input() forceOpenModal: boolean;
  @Input() list: DiscriminatorStruct[];
  @Output() discriminatorValues = new EventEmitter<any>();

  public discriminatorTypeBoolean: number = DiscriminatorTypeEnum.Boleano.valueOf();

  public openModalImpl: boolean = false;
  public index: number;
  private dialogRef: any;


  ngOnInit(): void {
    this.openModalImpl = this.openModal;
    this.index = this.list.findIndex(c => c.idDiscriminator == this.discriminator.idDiscriminator);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.openModal) {
      if (changes.openModal.previousValue != null && changes.openModal.currentValue !== changes.openModal.previousValue) {
        this.openModalImpl = changes.openModal.currentValue;
      }
    } else if (changes.forceOpenModal) {
      this.abrirModal();
    }
  }

  abrirModal(): void {
    if (this.openModalImpl) {
      this.alertService.show('Aviso', "É necessário negar todos os discriminadores anteriores!", AlertType.warning);
      return;
    }
    let choosenModal;
    let data: any = {};
    data.classificationData = this.util.getClassificationData();
    data.discriminator = this.discriminator;
    data.discriminatorsValues = this.discriminatorsValues;
    data.description = this.list[this.index].type;
    data.state = this.list[this.index].state;
    data.age = (data.classificationData.ageYears * 10000) + (data.classificationData.ageMonth * 100) + data.classificationData.ageDay;
    switch (this.discriminator.idDiscriminatorType) {
      case DiscriminatorTypeEnum.Glasgow:
        choosenModal = GlasgowModalComponent;
        break;
      case DiscriminatorTypeEnum.Temperatura:
        choosenModal = TemperatureModalComponent;
        break;
      case DiscriminatorTypeEnum.Saturacao:
        choosenModal = SaturationModalComponent;
        break;
      case DiscriminatorTypeEnum.FrequenciaCardiaca:
        choosenModal = HeartRateModalComponent;
        break;
      case DiscriminatorTypeEnum.Glicemia:
        choosenModal = GlucoseModalComponent;
        break;
      case DiscriminatorTypeEnum.GlicemiaCapilar:
        choosenModal = CapillaryGlucoseModalComponent;
        break;
      case DiscriminatorTypeEnum.FrequenciaRespiratoria:
        choosenModal = RespiratoryFrequencyModalComponent;
        break;
      case DiscriminatorTypeEnum.PressaoArterial:
        choosenModal = PressureModalComponent;
        break;
      case DiscriminatorTypeEnum.DorCoceira:
      case DiscriminatorTypeEnum.Dor:
        choosenModal = PainModalComponent;
        break;
      case DiscriminatorTypeEnum.Numerico:
        choosenModal = NumericModalComponent;
        break;
      case DiscriminatorTypeEnum.SepsePossivel:
        data.list = this.list;
        data.discriminatorsValues = this.discriminatorsValues;
        choosenModal = SepsisModalComponent;
        break;
      case DiscriminatorTypeEnum.SepsePossivelCrianca:
        data.list = this.list;
        data.discriminatorsValues = this.discriminatorsValues;
        choosenModal = SepsisCriancaModalComponent;
        break;
      default:
        return;
    }
    const dialogRef = this.dialog.open(choosenModal, { data: data, maxWidth: '100vw' });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && (result.value || result.value === 0)) {
        this.list[this.index].value = (result.value === true ? '' : result.value);
        this.list[this.index].hasValue = true;
        this.list[this.index].type = result.description;
        this.list[this.index].state = result.state;
        if (DiscriminatorTypeEnum.SepsePossivel == this.discriminator.idDiscriminatorType || DiscriminatorTypeEnum.SepsePossivelCrianca == this.discriminator.idDiscriminatorType) {
          this.list = result.list;
          this.discriminatorsValues = result.discriminatorsValues;

        } else if (this.discriminator.idDiscriminatorType == DiscriminatorTypeEnum.Dor || this.discriminator.idDiscriminatorType == DiscriminatorTypeEnum.DorCoceira) {
          data.classificationData.painColorCode = result.painColorCode;
          this.util.updateClassificationData(data.classificationData);
          this.discriminatorValues.emit({
            select: result.select,
            painColorCode: result.painColorCode,
            index: this.index,
          });
          return;
        } else if (this.discriminator.idDiscriminatorType == DiscriminatorTypeEnum.Glasgow) {
          data.classificationData.glasgowMotor = result.glasgowMotor;
          data.classificationData.glasgowVerbal = result.glasgowVerbal;
          data.classificationData.glasgowEye = result.glasgowEye;
          this.util.updateClassificationData(data.classificationData);
        }
        this.discriminatorValues.emit({
          select: result.select,
          index: this.index,
          motive: result.motive,
        });
      } else if (result && result.value === null && result.select && result.motive) {
        this.discriminatorValues.emit({
          select: result.select,
          motive: result.motive,
          index: this.index,
        });
      }
      else if (this.list[this.index].hasValue) {
        this.discriminatorValues.emit({
          select: false,
          index: this.index,
        })
      }
    });
  }
}
