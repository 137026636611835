import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { TelemedicineConfigRequest } from '../../requests/telemedicine/telemedicine-config.request';
import { GetConfigResponse } from '../../responses/telemedicine/get-config.response';

@Injectable({
  providedIn: 'root'
})
export class GetTelemedicineConfigService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getConfig(): Observable<GetConfigResponse>{

    let uri = `GetTelemedicineConfig`;

    return this.httpClient.get<GetConfigResponse>(environment.urlApiTelemedicine + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  } 
}