import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { CallSyncMeasurerService } from 'src/app/shared/services/API/orchestrator-patient/call-sync-measurer.service';
import { SyncMeasurerService } from 'src/app/shared/services/API/sync-measurer/sync-measurer.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { PostSyncMeasurerRequest } from 'src/app/shared/services/requests/orchestrator-patient/post-sync-measurer.request';
import { PatientStruct } from 'src/app/shared/services/structs/medical-record/patient.struct';
import { LinkPatientComponent } from './link-patient/link-patient.component';

@Component({
  selector: 'app-sync-measurer-register',
  templateUrl: './sync-measurer-register.component.html',
  styleUrls: ['./sync-measurer-register.component.css']
})
export class SyncMeasurerRegisterComponent implements OnInit {

  constructor(private formBuilder: UntypedFormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private syncMeasurerService: SyncMeasurerService,
    private callSyncMeasurerService: CallSyncMeasurerService,
    public dialog: MatDialog
  ) { }
    
  public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.remote_monitoring;
  public menuFunctionalityEnum:MenuFunctionalityEnum = MenuFunctionalityEnum.remoteMonitoring_syncMeasurer;
    
  public model: UntypedFormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;
  public actualServiceModule: number;
    
  public masks:Masks;
  public idSyncMeasurer: number;
  public listPatient: PatientStruct[];
  public postSyncMeasurerRequest: PostSyncMeasurerRequest;
  public listIdCareLine: number[];
  public listIdComorbidity: number[];
  public listIdMedicine: number[];
  public otherComorbidity: string;
  public otherMedicine: string;
    
    ngOnInit(): void {
      
      this.isLoading = false;
      
      this.model = this.formBuilder.group({
        syncMeasurerHash: ['', [Validators.required]],
        status: ['', [Validators.required]],
        listPatient: []
      });
      
      this.masks = this.maskService.getMasks();
      
      this.postSyncMeasurerRequest = new PostSyncMeasurerRequest();
    
      this.idSyncMeasurer = null;
      this.isUpdate = false;

      if(this.activatedRoute.snapshot.paramMap.get('idSyncMeasurer'))
        this.idSyncMeasurer = parseInt(this.activatedRoute.snapshot.paramMap.get('idSyncMeasurer'));
      
      if(this.idSyncMeasurer != null){
        this.isFirstLoading = true;
        this.isUpdate = true;
        this.model.get('syncMeasurerHash').disable();
        this.populateSyncMeasurerData();
      }
      else{
        this.listPatient = [];
      }
    }
    
    submit(){
      if(this.isLoading){
        return;
      } 

      if(!this.model.valid) {
        this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
        return;
      }
      this.isLoading = true;   
      
      this.postSyncMeasurerRequest.syncMeasurerHash = this.model.get('syncMeasurerHash').value;
      this.postSyncMeasurerRequest.isActive = this.model.get('status').value == "true"? true: false;
      this.postSyncMeasurerRequest.listPatient = this.listPatient;

      this.postSyncMeasurerRequest.listPatient.forEach(element => {
        if(!element.idPatient)
          element.idPatient = null;
      });
      
      if(this.isUpdate)
        this.updateSyncMeasurer();
      else
        this.createSyncMeasurer();
    }
    
    populateSyncMeasurerData(){
      this.callSyncMeasurerService.getSyncMeasurer(this.idSyncMeasurer).subscribe((response)=>{
        
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.model.get('syncMeasurerHash').setValue(response.syncMeasurer.syncMeasurerHash);
        this.model.get('status').setValue(response.syncMeasurer.isActive.toString());
        this.listPatient = response.syncMeasurer.listPatient;
        
        this.isLoading = false; 
        this.isFirstLoading = false;
      },
      (error)=>{
        console.log(error)
        this.isLoading = false;    
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
    }
    
    updateSyncMeasurer(){
      this.callSyncMeasurerService.updateSyncMeasurer(this.idSyncMeasurer, this.postSyncMeasurerRequest).subscribe((response)=>{
        
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        
        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false; 
        
        this.router.navigate(['/remote-monitoring/sync-measurer']);
        
      },
      (error)=>{
        console.log(error)
        this.isLoading = false;    
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
    }
    
    createSyncMeasurer(){
      this.callSyncMeasurerService.createSyncMeasurer(this.postSyncMeasurerRequest).subscribe((response)=>{
        
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        
        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;    
        
        this.router.navigate(['/remote-monitoring/sync-measurer']);       
      },
      (error)=>{
        console.log(error)
        this.isLoading = false;    
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
    }

    patientDetails(event){
      this.openModal(event);   
    }
    deletePatient(event){   
      this.listPatient.splice(event, 1);
    }
  
    openModal(index){
      let patient = null;
      patient = this.listPatient[index];
  
      const dialogRef = this.dialog.open(LinkPatientComponent, {
        data: {
          patient: patient,
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result && !result.isUpdate){
          this.listPatient.push(result.patient);
        }
      });
    }
  }