<div class="container-generic">

    <app-menu [isClassification]="true" [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'
        [hasSecundaryHeader]="true" [discriminators]="data.discriminatorValues" [hasCachedModule]="true"
        [cachedModuleName]="'classification'" [gravityColorCode]="data.priority.coloerHex"
        [gravity]="data.priority.priorityColorName"></app-menu>
    
    <div [ngClass]="{'container-body-secundary-header': idEpisode !== null, 'container-body': idEpisode === null}">
        <h1>Dados do paciente</h1>
        <div class="white-body">
            <div class="row">
                <div class="col-12 col-sm-6">
                    <div class="row">
                        <mat-label>
                            Gravidade: {{this.data.priority.priorityColorName}}
                        </mat-label>
                    </div>
                    <div class="row">
                        <mat-label>
                            Nome: {{this.data.patientData.patientName}}
                        </mat-label>
                    </div>
                    <div class="row">
                        <mat-label>
                            Idade: {{this.patientAge}}
                        </mat-label>
                    </div>
                </div>
                <div class="col-12 col-sm-6" *ngIf="this.isTelemedicineConfigActive">
                    <button mat-flat-button type="button" color="primary" 
                        class="telemedicine-link-btn" (click)="generateTelemedicineLink()"
                        *ngIf="!this.telemedicineLink">
                        <span *ngIf="isLoading == false">Gerar link telemedicina</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                    <span *ngIf="this.telemedicineLink" class="telemedicine-link-btn">
                        <button matTooltip="Copiar" aria-label="Copiar" class="copy-to-clipboard"
                            type="button" [cdkCopyToClipboard]="this.telemedicineLink"
                            [cdkCopyToClipboardAttempts]="5" (click)="onCopy('Link copiado')">
                            <span>Copiar link de acesso à telemedicina <mat-icon class="copy-icons">content_copy</mat-icon> </span>
                        </button>
                    </span>
                </div>
            </div>
        </div>
    
        <h1>Aconselhamentos</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="row">
                    <div class="col-md-6 col-separador">
                        
                        <div *ngFor="let counseling of listCounseling">           
                            <mat-checkbox [ngModelOptions]="{standalone: true}" [(ngModel)]="counseling.isChecked"
                                [checked]="counseling.isChecked" (click)="check(counseling.idCounselingType)" [disabled]="noneOfTheAbove.isChecked? true : null" class="row">
                            
                                <span>{{counseling.counselingName}}</span>   
                            </mat-checkbox>
                        </div>
    
                        <mat-checkbox [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="noneOfTheAbove.isChecked" [checked]="noneOfTheAbove.isChecked"
                            (change)="noneCheck()">
                            {{noneOfTheAbove.counselingName}}
                        </mat-checkbox>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div *ngFor="let counseling of listCounseling" class="col-6">
                                <mat-form-field *ngIf="counseling.idCounselingType == this.telemedicineConselingEnum && counseling.isChecked" appearance="outline">
                                    <mat-label>Confirmar telefone</mat-label>
                                    <input matInput type="text" formControlName="phone1" [mask]="masks.phone">
                                    <mat-error *ngIf="model.get('phone1').invalid">Confirme o número do paciente</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <mat-form-field appearance="outline">
                                    <mat-label>Observação</mat-label>
                                    <textarea matInput formControlName="observation"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <button mat-flat-button type="button" routerLink="/classification/flowchart"
                                    color="accent" class=" btn-block">
                                    <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Voltar
                                </button>
                            </div>
                            <div class="col-6">
                                <button mat-flat-button type="submit" color="primary" class=" btn-block">
                                    Avançar <mat-icon aria-hidden="false" aria-label="Plus">arrow_forward</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    
</div>