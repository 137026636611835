import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-telemedicine-logo-modal',
  templateUrl: './telemedicine-logo-modal.component.html',
  styleUrls: ['./telemedicine-logo-modal.component.css']
})
export class TelemedicineLogoModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private alertService: AlertService,
    public matDialogRef: MatDialogRef<TelemedicineLogoModalComponent>,
    public sanitizer: DomSanitizer,
    private formBuilder: FormBuilder
  ) { }

  public oldSrc: any;
  public newSrc: any;

  public uploadedLogo: string;
  readonly maxSize = 2000000;

  public haveLogo: boolean = false;

  public isLoading: boolean = true;
  public useTolifeLogo: boolean = this.data.useTolifeLogo;
  public model: FormGroup;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      requiredfile: [''],
    });
    if (this.data.logo) {
      this.oldSrc = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${this.data.logo}`);
      this.haveLogo = true;
    }
    if (this.data.newLogo) {
      this.newSrc = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${this.data.newLogo}`);
      this.haveLogo = true;
    }

    this.isLoading = false;
  }

  inputChange(event: any) {
    if (event && event.size > this.maxSize) {
      this.alertService.show('Erro:', 'Este arquivo é maior do que o suportado. Insira uma imagem com até 2mb de tamanho.', AlertType.error);
      this.model.controls['requiredfile'].reset();
      this.isLoading = false;
      return;
    }

    const file: File = event;
    const reader = new FileReader();

    reader.onload = this._handleReaderLoaded.bind(this);
    if (file)
      reader.readAsDataURL(file);
  }

  _handleReaderLoaded(e) {
    let reader = e.target;
    var testSrc = reader.result;
    var stringArray = testSrc.split("base64,", 2);
    this.isLoading = false;
    this.newSrc = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${stringArray[1]}`);
    this.uploadedLogo = stringArray[1];
    this.haveLogo = true;
  }

  clickApply() {
    this.matDialogRef.close(
      {
        newLogo: this.uploadedLogo ? this.uploadedLogo : null,
        haveLogo: this.haveLogo,
        useTolifeLogo: this.useTolifeLogo
      });
  }

  clickCancel() {
    this.matDialogRef.close(
      {
        cancel: true
      });
  }
}