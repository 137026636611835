import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { PostPutObservationConfigRequest } from '../../requests/bed/post-put-observation-config.request';
import { GetObservationConfigResponse } from '../../responses/bed/get-observation-config.response';
import { ListObservationConfigResponse } from '../../responses/bed/list-observation-config.response';

@Injectable({
  providedIn: 'root'
})
export class ObservationConfigService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listObsConfig(searchText: string, body: number[]): Observable<ListObservationConfigResponse> {
    
    let uri = 'ObservationConfig/GetAll?'
    
    if(searchText != null)
      uri = uri+ `searchText=${searchText}`;
    
    return this.httpClient.post<ListObservationConfigResponse>(environment.urlApiBed + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public getObsConfig(idObservationConfig: number): Observable<GetObservationConfigResponse> {
    
    let uri = `ObservationConfig/idObservationConfig/${idObservationConfig}`;  
    
    return this.httpClient.get<GetObservationConfigResponse>(environment.urlApiBed + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public createObsConfig(body: PostPutObservationConfigRequest): Observable<ReturnStruct>{

    let uri = `ObservationConfig`

    return this.httpClient.post<ReturnStruct>(environment.urlApiBed + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public updateObsConfig(idObservationConfig: number, body: PostPutObservationConfigRequest): Observable<ReturnStruct>{

    let uri = `ObservationConfig/idObservationConfig/${idObservationConfig}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiBed + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public deleteObsConfig(idObservationConfig: number): Observable<ReturnStruct>{

    let uri = `ObservationConfig/idObservationConfig/${idObservationConfig}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiBed + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}