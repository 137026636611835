export class ClassificationRiskAuditTriage {
    public idClassificationRiskAuditTriage: number;

    public idClassificationRiskAudit: number;

    public idTriage: number;

    public idUser: number;

    public isCorrectFirstAuditor: boolean;

    public isCorrectSecondAuditor: boolean;

    public observationFirstAuditor: string;

    public observationSecondAuditor: string;

    public idIncorrectClassificationReasonFirstAuditor: number;

    public idIncorrectClassificationReasonSecondAuditor: number;

    public timeLongerThreeMinutes: boolean;

    public correctObservationReasonFirstAuditor: string;

    public idCorrectClassificationReasonFirstAuditor: number;

    public correctObservationReasonSecondAuditor: string;

    public idCorrectClassificationReasonSecondAuditor: number;
}