import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { PriorityEpisodeService } from 'src/app/shared/services/API/medical-record/priority-episode.service';
import { FowardService } from 'src/app/shared/services/API/orchestrator-queue/foward.service';
import { PriorityEpisodeRequest } from 'src/app/shared/services/requests/medical-record/priority-episode.request';
import { SelectedLocationRequest } from 'src/app/shared/services/requests/orchestrator-queue/selected-location.request';

@Component({
  selector: 'app-attend-patient-confirm-modal',
  templateUrl: './attend-patient-confirm-modal.component.html',
  styleUrls: ['./attend-patient-confirm-modal.component.css']
})
export class AttendPatientConfirmModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<AttendPatientConfirmModalComponent>,
  private alertService: AlertService,
  public priorityEpisodeService: PriorityEpisodeService,
  public fowardService: FowardService,
  ) { }

  public isLoading:boolean;  
  public selectedLocationRequest: SelectedLocationRequest
  public priorityEpisodeRequest: PriorityEpisodeRequest
  public idEpisode: number;

  ngOnInit(): void {
    this.isLoading = false;
    this.priorityEpisodeRequest = new PriorityEpisodeRequest();
    this.selectedLocationRequest = new SelectedLocationRequest();
    this.idEpisode = this.data.idEpisode;
    this.priorityEpisodeRequest.listIdPriority = [];
    this.selectedLocationRequest = this.data.selectedLocationRequest;
    this.priorityEpisodeRequest.listIdPriority = this.data.listIdPriority;
  }

  close(){
    this.matDialogRef.close({fowardPatient: false}); 
  }
  
  clickConfirm(){

    this.updatePriority(this.idEpisode, this.priorityEpisodeRequest);

    this.fowardPatient(this.selectedLocationRequest);
  }
  
  clickCancel(){
    this.matDialogRef.close({fowardPatient: false}); 
  }

  fowardPatient(body: SelectedLocationRequest){
    this.isLoading = true;
    this.fowardService.fowardPatient(body).subscribe((response)=>{
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.isLoading = false;   

      if(response.errorCode == 4){
        this.alertService.show('Aviso', response.errorDescription, AlertType.warning);
      }
      else{
        this.alertService.show('Sucesso', "Paciente encaminhado com sucesso", AlertType.success);
      }

      this.matDialogRef.close({fowardPatient: true}); 
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  updatePriority(idEpisode: number, body: PriorityEpisodeRequest){

    this.priorityEpisodeService.updatePriority(idEpisode, body).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);  
        return;
      }
    },
    (error)=>{
      console.log(error)    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }
}
