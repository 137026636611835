import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { ListPatientStructResponse } from '../../responses/medical-record/list-patient-struct-response';

@Injectable({
  providedIn: 'root'
})
export class ListPatientByCnsService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getPatientByCNS(cns:string): Observable<ListPatientStructResponse>{
    let uri = `ListPatientByCns?cns=${cns}`
    return this.httpClient.get<ListPatientStructResponse>(environment.urlApiMedicalRecord + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
