<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <h1>Configuração de canais</h1>
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>E-mail</mat-label>
                            <input matInput type="email" formControlName="email" />
                            <mat-error *ngIf="model.get('email').invalid">O email é inválido</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Telefone</mat-label>
                            <input matInput type="text" formControlName="billingTelephone" [mask]="masks.phone">
                            <mat-error *ngIf="model.get('billingTelephone').invalid">Preencha um telefone</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div>
                    <div class="title-header">
                        <h1>Alertas de utilização do sistema</h1>
                    </div>
                    <app-create-rule [formGroup]="this.model" [formArrayName]="'expressions'"
                        [arrayVariables]="arrayVariables" [listTypeService]="listTypeService"
                        [listAlertVariable]="listAlertVariable" [listChannelType]="listChannelType">
                    </app-create-rule>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <button mat-flat-button type="submit" color="primary" class="btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <a mat-flat-button type="button" routerLink="/master" color="accent" class=" btn-block">
                            Cancelar
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>
    
</div>