import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SelectedLocationRequest } from 'src/app/shared/services/requests/orchestrator-queue/selected-location.request';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { FowardService } from 'src/app/shared/services/API/orchestrator-queue/foward.service';
import { GenerateQueueRequest } from 'src/app/shared/services/requests/orchestrator-queue/generate-queue.request';


@Component({
  selector: 'app-forward-patient-confirm-modal',
  templateUrl: './forward-patient-confirm-modal.component.html',
  styleUrls: ['./forward-patient-confirm-modal.component.css']
})
export class ForwardPatientConfirmModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ForwardPatientConfirmModalComponent>,
    private alertService: AlertService,
    public fowardService: FowardService,
  ) { }

  public isLoading: boolean;
  public selectedLocationRequest: SelectedLocationRequest
  public idHealthUnit: number;
  public idEpisode: number;
  public fowardPatient: boolean = false;

  ngOnInit(): void {
    this.isLoading = false;
    this.selectedLocationRequest = new SelectedLocationRequest();
    this.idEpisode = this.data.idEpisode;
    this.selectedLocationRequest = this.data.selectedLocationRequest;
  }

  clickCancel() {
    if (this.isLoading)
      return;
    
    this.isLoading = true;
    this.matDialogRef.close({ fowardPatient: this.fowardPatient });
  }

  clickConfirm() {
    if (this.isLoading)
      return;
    this.isLoading = true;
    this.fowardPatient = true;
    if (this.selectedLocationRequest.idQueue) {
      this.fowardService.fowardPatient(this.selectedLocationRequest).subscribe({
        next: (response) => {
          if (response.isError && response.errorCode != 4) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            this.fowardPatient = false;
            this.isLoading = false;
            return;
          }

          this.isLoading = false;

          if (response.errorCode == 4) {
            this.alertService.show('Aviso', response.errorDescription, AlertType.warning);
          }
          else {
            this.alertService.show('Sucesso', "Paciente encaminhado com sucesso", AlertType.success);
          }

          this.matDialogRef.close({ fowardPatient: this.fowardPatient, idQueue: this.selectedLocationRequest.idQueue });
        },
        error: (error) => {
          console.log(error)
          this.isLoading = false;
          this.fowardPatient = false;
          this.alertService.show('Erro inesperado', error, AlertType.error);
        }
      });
    } else {
      let generateQueue = new GenerateQueueRequest();
      generateQueue.idEpisode = this.idEpisode;
      generateQueue.idRoom = this.data.idRoom;
      generateQueue.dateTimeStartRegister = this.data.datetimeStartRegister;
      this.fowardService.genarateQueue(generateQueue).subscribe({
        next: (response1) => {
          if (response1.isError) {
            this.alertService.show('Erro', response1.errorDescription, AlertType.error);
            this.fowardPatient = false;
            this.isLoading = false;
            return;
          }
          this.fowardService.episodeFowardPatient(this.selectedLocationRequest).subscribe({
            next: (response) => {
              if (response.isError) {
                this.alertService.show('Erro', response.errorDescription, AlertType.error);
                this.fowardPatient = false;
                this.isLoading = false;
                return;
              }

              this.isLoading = false;

              this.alertService.show('Sucesso', "Paciente encaminhado com sucesso", AlertType.success);

              this.matDialogRef.close({ fowardPatient: this.fowardPatient, idQueue: response1.idQueue });
            },
            error: (error) => {
              console.log(error)
              this.isLoading = false;
              this.fowardPatient = false;
              this.alertService.show('Erro inesperado', error, AlertType.error);
            }
          });
        },
        error: (error) => {
          console.log(error)
          this.isLoading = false;
          this.fowardPatient = false;
          this.alertService.show('Erro inesperado', error, AlertType.error);
        }
      });
    }
  }
}
