import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { SpecialNecessityEnum } from 'src/app/shared/enum/risk-classification.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { SpecialNecessityModel } from 'src/app/shared/services/models/risk-classification/special-necessity.model';
import { LocationStruct } from 'src/app/shared/services/structs/orchestrator-queue/location.struct';
import { ClassificationData, UtilsClassificationService } from '../../utils.service';
import { LookupRiskService } from 'src/app/shared/services/API/risk-classification/lookup-risk.service';
import { DiscriminatorTypeEnum } from 'src/app/shared/enum/discriminator-type.enum';
import { MedicalRecordLookupService } from 'src/app/shared/services/API/medical-record/lookup.service';
import { Comorbidity, Suspicion } from 'src/app/shared/services/responses/medical-record/lookup.response';
import { TriageByEpisodeService } from 'src/app/shared/services/API/medical-record/triage-by-episode.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { ComorbidityEnum } from 'src/app/shared/enum/comorbidity.enum';
import { DeviceTypeEnum } from 'src/app/shared/enum/device-type.enum';
import { UtilsMeasurerService } from '../flowchart/protocol-v2/utils-measurer.service';
import { SupplementaryDataRequiredField } from 'src/app/shared/services/models/risk-classification/supplementary-data-required-field.model';
import { SupplementaryDataRequiredFieldSectorService } from 'src/app/shared/services/API/risk-classification/supplmentary-data-required-field-sector.service';
import { SupplementaryDataRequiredFieldSector } from 'src/app/shared/services/models/risk-classification/supplementary-data-required-field-sector.model';
import { SupplementaryDataRequiredFieldEnum } from 'src/app/shared/enum/supplementary-data-required-field.enum';
import { FlowStruct } from 'src/app/shared/services/structs/flow/flow.struct';
import { AllergyModel } from 'src/app/shared/services/models/medical-record/allergy.model';
import { AllergyStruct } from 'src/app/shared/services/structs/medical-record/allergy.struct';
import { UserGetNameService } from 'src/app/shared/services/API/user/user-get-name.service';
import { DeviceTypeSectorResponse } from 'src/app/shared/services/responses/risk-classification/device-type-sector.response';
import { GetCareLinePriorityService } from 'src/app/shared/services/API/care-line-priority/get-care-line-priority.service';
import { CareLinePriority } from 'src/app/shared/services/models/care-line-priority/care-line-priority.model';
import { UtilsSecundaryHeaderService } from 'src/app/shared/components/secundary-header/utils.service';
import { MenuComponent } from 'src/app/shared/components/menu/menu.component';
import { SuspicionValidatedStruct } from 'src/app/shared/services/structs/medical-record/suspicion-validated.struct';
import { weightValidator } from 'src/app/shared/custom-validators/weight.validator';
import { WebsocketAttendanceUtilService } from 'src/app/shared/services/websocket-util/websocket-attendance-util.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { HospitalDocumentService } from 'src/app/shared/services/API/hospital-document/hospital-document.service';
import { GrievanceNotificationStruct } from 'src/app/shared/services/structs/hospital-document/grievance-notification.struct';
import { AttendanceOccupiedRemoveModalComponent } from 'src/app/shared/components/attendance-occupied-remove-modal/attendance-occupied-remove-modal.component';
import { CareLinePriorityStatusEnum } from 'src/app/shared/enum/care-line-priority/care-line-priority-status.enum';
import { CareLinePriorityStruct } from 'src/app/shared/services/structs/care-line-priority/list-key.struct';
import { AlertModalComponent } from 'src/app/shared/components/alert-modal/alert-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { NextStepsProtolModalComponent } from './next-steps-protol-modal/next-steps-protol-modal.component';
import { ClockService } from 'src/app/shared/services/API/orchestrator-queue/clock.service';

@Component({
  selector: 'app-supplementary-data',
  templateUrl: './supplementary-data.component.html',
  styleUrls: ['./supplementary-data.component.css']
})
export class SupplementaryDataComponent implements OnInit, OnDestroy {

  constructor(private alertService: AlertService,
    private utilService: UtilsClassificationService,
    public router: Router,
    private formBuilder: FormBuilder,
    public lookupService: LookupRiskService,
    private utilClassification: UtilsClassificationService,
    private LookupSuspicion: MedicalRecordLookupService,
    private triageService: TriageByEpisodeService,
    private maskService: MaskService,
    public measurerService: UtilsMeasurerService,
    private supplementaryDataRequiredFieldSectorService: SupplementaryDataRequiredFieldSectorService,
    private userGetNameService: UserGetNameService,
    private getCareLinePriorityService: GetCareLinePriorityService,
    private utilHeaderService: UtilsSecundaryHeaderService,
    private websocketAttendanceUtilService: WebsocketAttendanceUtilService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private hospitalDocumentService: HospitalDocumentService,
    public clockService: ClockService,
    public dialog: MatDialog,
    private cdRef: ChangeDetectorRef
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.classification;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.classification_pre_registration;
  public isFirstLoading: boolean = true;
  public careLineIsConfirmedOrDenied: boolean = false;
  squareColor = '#FFFFFF';
  public idEpisode: number;
  public idRoom: number;
  public idQueue: number = null;
  public idSector: number;
  public data: ClassificationData = this.utilService.getClassificationData();
  public model: FormGroup;
  public location: LocationStruct[] = [];
  public idSpecialNecessityOthers: number = SpecialNecessityEnum.outros;
  public idSpecialNecessity: number;
  public specialNecessityList: SpecialNecessityModel[];
  public listRequiredField: SupplementaryDataRequiredField[];
  public listSectorRequiredField: SupplementaryDataRequiredFieldSector[];
  public listSuspicion: Suspicion[];
  public allergies: AllergyModel[] = [];
  public allergiesToSave: AllergyModel[] = [];
  public listComorbidity: Comorbidity[];
  public listAllComorbidity: Comorbidity[];
  public masks: Masks = this.maskService.getMasks();
  public selectedDeniesComorbidities: boolean = false;
  public isWhitePatient: boolean;
  public hasComorbidityOutros: boolean = false;
  isCareLinePrioritySelected: boolean = false;
  public suspicionSelected: Suspicion;
  public suspicionOfRetriage: SuspicionValidatedStruct = new SuspicionValidatedStruct();
  @ViewChild(MenuComponent) menu: MenuComponent;

  public enumTemperatura: DiscriminatorTypeEnum = DiscriminatorTypeEnum.Temperatura;
  public enumFrequenciaCardiaca: DiscriminatorTypeEnum = DiscriminatorTypeEnum.FrequenciaCardiaca;
  public enumGlicemia: DiscriminatorTypeEnum = DiscriminatorTypeEnum.Glicemia;
  public enumPressaoArterial: DiscriminatorTypeEnum = DiscriminatorTypeEnum.PressaoArterial;
  public enumSaturacao: DiscriminatorTypeEnum = DiscriminatorTypeEnum.Saturacao;

  public deniesComorbiditiesEnum: ComorbidityEnum = ComorbidityEnum.negaComorbidades.valueOf();
  public listPreselectedAllergy: any[] = [];
  public painColorCode: string[] = [];
  public flowSequence: FlowStruct[];
  public allergyError: boolean = false;
  public hideChildSepsisTemperature: boolean = false;

  public listCareLinePriority: CareLinePriority[] = [];
  public listCareLinePriorityStruct: CareLinePriorityStruct[] = [];

  public temperaturaWasMarked: boolean;
  public sepsePossivelCriancaWasMarked: boolean;
  public frequenciaCardiacaWasMarked: boolean;
  public glicemiaWasMarked: boolean;
  public pressaoArterialWasMarked: boolean;
  public sepsePossivelWasMarked: boolean;
  public saturacaoWasMarked: boolean;
  public freqRespWasMarked: boolean;
  public discriminatorValues: any[] = [];
  public isNext: boolean = false;
  public listGrievanceNotification: GrievanceNotificationStruct[] = [];
  public setUppercaseText: boolean = false;
  public userLogin: string = this.authService.getTokenMenu().login;
  public userName: string = this.authService.getTokenMenu().userName;
  public isAttendanceRemoval: boolean = false;

  ngOnInit(): void {
    let deviceTypeSectorResponse: DeviceTypeSectorResponse = this.utilClassification.getDevicetypeBySector();
    if (deviceTypeSectorResponse.deviceTypeSector.setUppercaseText)
      this.setUppercaseText = deviceTypeSectorResponse.deviceTypeSector.setUppercaseText;

    this.painColorCode[0] = this.data.painColorCode;
    this.isWhitePatient = this.data.isPatientWhite;
    this.idEpisode = this.data.idEpisode;
    this.idSector = this.data.idSector;
    this.hideChildSepsisTemperature = this.utilService.getHideTemperatureChildSepsis();
    this.discriminatorValues = Array.isArray(this.data.discriminatorValues) ? [...this.data.discriminatorValues] : [];
    this.idRoom = parseInt(this.activatedRoute.snapshot.paramMap.get('idRoom'));
    this.idQueue = parseInt(this.activatedRoute.snapshot.paramMap.get('idQueue'));

    if (Number.isInteger(this.idQueue) && this.idQueue && this.idQueue > 0 && Number.isInteger(this.idEpisode) && this.idEpisode && this.idEpisode > 0 && this.data && this.data.isTelephoneClassification != true)
      this.startWebsocket();

    let initTemperatura = this.discriminatorValues[DiscriminatorTypeEnum.Temperatura] ? parseFloat(this.discriminatorValues[DiscriminatorTypeEnum.Temperatura]) : this.data.temperatureComplementaryData ? this.data.temperatureComplementaryData : null;
    let initFrequenciaRespiratoria = this.discriminatorValues[DiscriminatorTypeEnum.FrequenciaRespiratoria] ? parseInt(this.discriminatorValues[DiscriminatorTypeEnum.FrequenciaRespiratoria]) : this.data.respiratoryFrequencyComplementaryData ? this.data.respiratoryFrequencyComplementaryData : null;
    let initFrequenciaCardiaca = this.discriminatorValues[DiscriminatorTypeEnum.FrequenciaCardiaca] ? parseInt(this.discriminatorValues[DiscriminatorTypeEnum.FrequenciaCardiaca]) : this.data.heartRateComplementaryData ? this.data.heartRateComplementaryData : null;
    let initGlicemia = this.discriminatorValues[DiscriminatorTypeEnum.Glicemia] ? parseInt(this.discriminatorValues[DiscriminatorTypeEnum.Glicemia]) : this.data.glucoseComplementaryData ? this.data.glucoseComplementaryData : null;
    let initPressaoArterial = this.discriminatorValues[DiscriminatorTypeEnum.PressaoArterial] ? this.discriminatorValues[DiscriminatorTypeEnum.PressaoArterial].toString() : this.joinBloodPressures(this.data.bloodPressureSystoleComplementaryData, this.data.bloodPressureDiastoleComplementaryData) ? this.joinBloodPressures(this.data.bloodPressureSystoleComplementaryData, this.data.bloodPressureDiastoleComplementaryData) : null;
    let initSaturacao = this.discriminatorValues[DiscriminatorTypeEnum.Saturacao] ? parseInt(this.discriminatorValues[DiscriminatorTypeEnum.Saturacao]) : this.data.saturationComplementaryData ? this.data.saturationComplementaryData : null;

    this.allergies = this.data.listAllergy.map(allergyStruct => new AllergyModel(allergyStruct));
    if (this.allergies.length > 0) {
      let listIdUser: number[] = this.allergies.filter(x => x.isDeleted && x.idUser != null).map(x => x.idUser);
      this.getUserNames(listIdUser);
    }
    let selectedIndex: number = -1;
    if (Array.isArray(this.data.discriminatorAllValues)) {
      let temperaturaIndex: number = this.data.discriminatorAllValues.findIndex(c => c.idDiscriminatorType == DiscriminatorTypeEnum.Temperatura);
      let sepsePossivelCriancaIndex: number = this.data.discriminatorAllValues.findIndex(c => c.idDiscriminatorType == DiscriminatorTypeEnum.SepsePossivelCrianca);
      let frequenciaCardiacaIndex: number = this.data.discriminatorAllValues.findIndex(c => c.idDiscriminatorType == DiscriminatorTypeEnum.FrequenciaCardiaca);
      let glicemiaIndex: number = this.data.discriminatorAllValues.findIndex(c => c.idDiscriminatorType == DiscriminatorTypeEnum.Glicemia);
      let pressaoArterialIndex: number = this.data.discriminatorAllValues.findIndex(c => c.idDiscriminatorType == DiscriminatorTypeEnum.PressaoArterial);
      let sepsePossivelIndex: number = this.data.discriminatorAllValues.findIndex(c => c.idDiscriminatorType == DiscriminatorTypeEnum.SepsePossivel);
      let saturacaoIndex: number = this.data.discriminatorAllValues.findIndex(c => c.idDiscriminatorType == DiscriminatorTypeEnum.Saturacao);
      let freqRespIndex: number = this.data.discriminatorAllValues.findIndex(c => c.idDiscriminatorType == DiscriminatorTypeEnum.FrequenciaRespiratoria);
      selectedIndex = this.data.discriminatorAllValues.findIndex(c => c.idDiscriminator == this.data.discriminator.idDiscriminator);
      selectedIndex = selectedIndex == -1 ? this.data.discriminatorAllValues.length + 1 : selectedIndex;

      this.temperaturaWasMarked = temperaturaIndex > -1 && temperaturaIndex <= selectedIndex;
      this.sepsePossivelCriancaWasMarked = sepsePossivelCriancaIndex > -1 && sepsePossivelCriancaIndex <= selectedIndex;
      this.frequenciaCardiacaWasMarked = frequenciaCardiacaIndex > -1 && frequenciaCardiacaIndex <= selectedIndex;
      this.glicemiaWasMarked = glicemiaIndex > -1 && glicemiaIndex <= selectedIndex;
      this.pressaoArterialWasMarked = pressaoArterialIndex > -1 && pressaoArterialIndex <= selectedIndex;
      this.sepsePossivelWasMarked = sepsePossivelIndex > -1 && sepsePossivelIndex <= selectedIndex;
      this.saturacaoWasMarked = saturacaoIndex > -1 && saturacaoIndex <= selectedIndex;
      this.freqRespWasMarked = freqRespIndex > -1 && freqRespIndex <= selectedIndex;

      this.cleanComplementaryData();
    }
    else {
      this.temperaturaWasMarked = false;
      this.sepsePossivelCriancaWasMarked = false;
      this.frequenciaCardiacaWasMarked = false;
      this.glicemiaWasMarked = false;
      this.pressaoArterialWasMarked = false;
      this.sepsePossivelWasMarked = false;
      this.saturacaoWasMarked = false;
      this.freqRespWasMarked = false;
    }
    
    this.model = this.formBuilder.group({
      specialNecessity: [this.data.specialNecessity],
      idSpecialNecessity: [this.data.idSpecialNecessity],
      idCareLinePriority: [this.data.idCareLinePriority],
      observation: [(this.setUppercaseText && this.data.observation) ? this.data.observation.toUpperCase() : this.data.observation],
      diseaseHistory: [this.data.diseaseHistory],
      radioFallRisk: [this.data.radioFallRisk],
      weight: [this.data.weight ? this.data.weight.toFixed(1) : null, [weightValidator()]],
      height: [this.data.height ? this.data.height.replace(".", "") : null],
      idSuspicion: [this.data.idSuspicion],
      listIdComorbodity: [this.data.listIdComorbodity],
      temperature: [{ value: initTemperatura, disabled: this.temperaturaWasMarked || (this.sepsePossivelCriancaWasMarked && !this.hideChildSepsisTemperature) }],
      respiratoryFrequency: [{ value: initFrequenciaRespiratoria, disabled: this.freqRespWasMarked || this.sepsePossivelWasMarked }],
      heartRate: [{ value: initFrequenciaCardiaca, disabled: this.frequenciaCardiacaWasMarked || this.sepsePossivelCriancaWasMarked }],
      glucose: [{ value: initGlicemia, disabled: this.glicemiaWasMarked }],
      bloodPressure: [{ value: initPressaoArterial ? initPressaoArterial.replace("/", "") : null, disabled: this.pressaoArterialWasMarked || this.sepsePossivelWasMarked }],
      saturation: [{ value: initSaturacao, disabled: this.saturacaoWasMarked }],
      idGrievanceNotification: [this.data.idGrievanceNotification],
      firstChildGroup: this.formBuilder.group({
        listIdAllergies: [this.data.listAllergy ? this.data.listAllergy.map(x => x.idAllergy) : []],
      })
    });

    this.careLineIsConfirmedOrDenied = this.data.idCareLinePriority && this.data.idCareLinePriorityStatus &&
      (
        this.data.idCareLinePriorityStatus == CareLinePriorityStatusEnum.negado ||
        this.data.idCareLinePriorityStatus == CareLinePriorityStatusEnum.confirmado
      );

    if (this.careLineIsConfirmedOrDenied)
      this.model.get('idCareLinePriority').disable();

    if (this.data.idSpecialNecessity)
      this.onChangeSpecialNecessity(this.data.idSpecialNecessity);

    this.checkOutros(null);
    this.getRetriage();
    this.populateLookup();
    this.populateLookupSuspicion();
    this.getListGrievanceNotification();
    this.listAllSectorRequiredFields();
    this.populateCareLinePrioritySelect();

    if (
      (
        this.data.idDeviceType == DeviceTypeEnum.Trius ||
        this.data.idDeviceType == DeviceTypeEnum.Berco ||
        this.data.idDeviceType == DeviceTypeEnum.Bluetooth
      ) &&
      Array.isArray(this.data.discriminatorAllValues)
    ) {
      this.measurerService.setIndex(selectedIndex);
      this.data.discriminatorsPersist = this.data.discriminatorsPersist == undefined ? [] : this.data.discriminatorsPersist;
      this.measurerService.createReference(this.discriminatorValues, this.data.discriminatorsPersist, this.data.discriminatorAllValues, this.data.deviceThermometer, this.data.deviceGlucometer, this.data.deviceOximeter, this.data.devicePressure);
      this.measurerService.restartMeasurer();
    }

    //verifica se a tela pode ser aberta
    if (deviceTypeSectorResponse.deviceTypeSector.disableScreenSupplementaryData == true)
      this.submit();

    this.endLoading();
  }

  getUserNames(listIdUser: number[]) {
    this.isFirstLoading = true;
    this.userGetNameService.listUser(listIdUser).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error, true)
          return;
        }

        this.allergies.forEach(allergy => {
          if (allergy.isDeleted && allergy.idUser != null)
            allergy.userName = response.listUser.find(x => x.idUser == allergy.idUser).userName;
        });

        this.isFirstLoading = false;
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error, true)
      }
    });
  }

  updateAllergiesToSave(allergies: AllergyModel[]) {
    this.allergiesToSave = allergies;
    this.model.get("firstChildGroup").get("listIdAllergies").setValue(this.allergiesToSave.map(x => x.idAllergy));
  }

  onChangeSpecialNecessity(event: number) {
    this.idSpecialNecessity = event;
    if (this.idSpecialNecessity == this.idSpecialNecessityOthers)
      this.model.get('specialNecessity').setValidators(Validators.required);
    else
      this.model.get('specialNecessity').setValidators(Validators.nullValidator);

    this.model.get('specialNecessity').updateValueAndValidity();
  }

  getRetriage() {
    this.triageService.get(this.idEpisode).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error, true)
          return;
        }

        if (response.triage == null)
          return;
        if (this.model.get('radioFallRisk').value == null || this.model.get('radioFallRisk').value == undefined)
          this.model.get('radioFallRisk').setValue(response.triage.fallRisk);
        if (!this.model.get('weight').value)
          this.model.get('weight').setValue(response.triage.weight);
        if (!this.model.get('height').value)
          this.model.get('height').setValue(response.triage.height);
        if (!this.model.get('idSuspicion').value && response.triage.idSuspicion) {
          this.suspicionOfRetriage.idSuspicion = response.triage.idSuspicion;
          this.suspicionOfRetriage.isSuspicionEvaluated = response.triage.isSuspicionEvaluated;
          this.suspicionOfRetriage.suspicionEvaluationMotive = response.triage.suspicionEvaluationMotive;
          this.suspicionOfRetriage.idUserSuspicionEvaluation = response.triage.idUserSuspicionEvaluation;
          this.suspicionOfRetriage.datetimeSuspicionEvaluation = response.triage.datetimeSuspicionEvaluation;
          this.model.get('idSuspicion').setValue(response.triage.idSuspicion);
          if (this.suspicionOfRetriage.isSuspicionEvaluated || this.suspicionOfRetriage.isSuspicionEvaluated == null)
            this.model.get('idSuspicion').disable();
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  populateLookup() {
    this.lookupService.getLookup().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.specialNecessityList = response.specialNecessity;
        this.listRequiredField = response.listSupplementaryDataRequiredField;
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  populateLookupSuspicion() {
    this.LookupSuspicion.getLookup().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listSuspicion = response.listSuspicion;
        this.listComorbidity = response.listComorbidity;
        this.listAllComorbidity = response.listComorbidity;
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  getListGrievanceNotification() {
    this.hospitalDocumentService.getAll().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listGrievanceNotification = response.listGrievanceNotification;
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    });
  }

  submit() {
    if (Array.isArray(this.discriminatorValues) && this.discriminatorValues[DiscriminatorTypeEnum.Temperatura])
      this.model.get('temperature').setValue(this.discriminatorValues[DiscriminatorTypeEnum.Temperatura]);

    if (this.model.invalid) {
      this.model.get('firstChildGroup').get('listIdAllergies').markAsTouched();
      this.allergyError = this.model.get('firstChildGroup').get('listIdAllergies').invalid && this.model.get('firstChildGroup').get('listIdAllergies').touched;
      this.alertDynamic('Erro', "Preencha os valores obrigatórios!", AlertType.error)
      return;
    }

    if (Array.isArray(this.discriminatorValues) && this.discriminatorValues[DiscriminatorTypeEnum.PressaoArterial]) {
      let pa: string = this.discriminatorValues[DiscriminatorTypeEnum.PressaoArterial].replace("/", "").split("");
      if (pa.length < 4) {
        this.alertDynamic('Erro', "Preencha o valor da pressão arterial corretamente", AlertType.error)
        return;
      }
      let value1 = pa.length == 4 ? pa[0] + pa[1] : pa[0] + pa[1] + pa[2];
      let value2 = pa.length == 4 ? pa[2] + pa[3] : pa.length == 5 ? pa[3] + pa[4] : pa[3] + pa[4] + pa[5];
      if (parseInt(value2) > parseInt(value1)) {
        this.alertDynamic('Erro', "A pressão diastólica não pode ser maior do que a sistólica!", AlertType.error)
        this.model.get("bloodPressure").setValue("");
        return;
      }
    }

    let temperatura = Array.isArray(this.discriminatorValues) && this.discriminatorValues[DiscriminatorTypeEnum.Temperatura] ? this.discriminatorValues[DiscriminatorTypeEnum.Temperatura].toString().replace(".", "").replace(",", "") : null;
    temperatura = temperatura ? temperatura.split("")[0] + temperatura.split("")[1] + "." + temperatura.split("")[2] : null;
    let frequenciaRespiratoria = Array.isArray(this.discriminatorValues) && this.discriminatorValues[DiscriminatorTypeEnum.FrequenciaRespiratoria] ? this.discriminatorValues[DiscriminatorTypeEnum.FrequenciaRespiratoria].toString() : '';
    let frequenciaCardiaca = Array.isArray(this.discriminatorValues) && this.discriminatorValues[DiscriminatorTypeEnum.FrequenciaCardiaca] ? this.discriminatorValues[DiscriminatorTypeEnum.FrequenciaCardiaca].toString() : '';
    let glicemia = Array.isArray(this.discriminatorValues) && this.discriminatorValues[DiscriminatorTypeEnum.Glicemia] ? this.discriminatorValues[DiscriminatorTypeEnum.Glicemia].toString() : '';

    let pressaoArterial;
    if (Array.isArray(this.discriminatorValues) && this.discriminatorValues[DiscriminatorTypeEnum.PressaoArterial]) {
      let pa: string = this.discriminatorValues[DiscriminatorTypeEnum.PressaoArterial].replace("/", "").split("");
      let value1 = pa.length == 4 ? pa[0] + pa[1] : pa[0] + pa[1] + pa[2];
      let value2 = pa.length == 4 ? pa[2] + pa[3] : pa.length == 5 ? pa[3] + pa[4] : pa[3] + pa[4] + pa[5];
      pressaoArterial = value1 + "/" + value2;
    }
    let saturacao = Array.isArray(this.discriminatorValues) && this.discriminatorValues[DiscriminatorTypeEnum.Saturacao] ? this.discriminatorValues[DiscriminatorTypeEnum.Saturacao].toString() : '';

    temperatura = parseFloat(temperatura);
    frequenciaRespiratoria = parseInt(frequenciaRespiratoria);
    frequenciaCardiaca = parseInt(frequenciaCardiaca);
    glicemia = parseInt(glicemia);
    saturacao = parseInt(saturacao);
    pressaoArterial = pressaoArterial ? pressaoArterial : null;

    if (saturacao && (saturacao < 0 || saturacao > 100)) {
      this.alertDynamic('Erro', "O valor da saturação deve ser de 0 a 100", AlertType.error)
      return;
    }

    if (
      this.data.idDeviceType == DeviceTypeEnum.Trius ||
      this.data.idDeviceType == DeviceTypeEnum.Berco ||
      this.data.idDeviceType == DeviceTypeEnum.Bluetooth
    ) {
      this.measurerService.setIndex(-1);
    }

    let data = this.utilClassification.getClassificationData();
    data.observation = this.model.get('observation').value;
    data.diseaseHistory = this.model.get('diseaseHistory').value;
    data.radioFallRisk = this.model.get('radioFallRisk').value;
    data.weight = this.setCoomaLastDigit(this.model.get('weight').value);

    let height = this.model.get('height').value ? this.model.get('height').value.toString().replace(".", "") : null;
    data.height = height ? height.split("")[0] + "." + height.split("")[1] + height.split("")[2] : null;

    data.idSuspicion = this.model.get('idSuspicion').value == 0 ? null : this.model.get('idSuspicion').value;
    data.idGrievanceNotification = this.model.get('idGrievanceNotification').value == 0 ? null : this.model.get('idGrievanceNotification').value;
    this.suspicionSelected = this.listSuspicion ? this.listSuspicion.find(c => c.idSuspicion == data.idSuspicion) : null;
    data.suspicion = this.suspicionSelected ? this.suspicionSelected.suspicionName : null;
    if (data.idSuspicion && this.suspicionOfRetriage.isSuspicionEvaluated) {
      data.idSuspicion = this.suspicionOfRetriage.idSuspicion;
      data.isSuspicionEvaluated = this.suspicionOfRetriage.isSuspicionEvaluated;
      data.suspicionEvaluationMotive = this.suspicionOfRetriage.suspicionEvaluationMotive;
      data.idUserSuspicionEvaluation = this.suspicionOfRetriage.idUserSuspicionEvaluation;
      data.datetimeSuspicionEvaluation = this.suspicionOfRetriage.datetimeSuspicionEvaluation;
    }

    data.listIdComorbodity = this.model.get('listIdComorbodity').value ? this.model.get('listIdComorbodity').value : [];
    let comorbidities = [];
    data.listIdComorbodity.forEach(x => {
      comorbidities.push(this.listComorbidity.find(c => c.idComorbidity == x).comorbidityName);
    });

    if (this.model.get('diseaseHistory').value)
      comorbidities.push(this.model.get('diseaseHistory').value);

    data.comorbidities = comorbidities.join(", ");
    data.hasComorbidityOutros = this.hasComorbidityOutros;

    data.listAllergy = this.allergiesToSave.map(allergy => new AllergyStruct(allergy, this.idEpisode));

    data.specialNecessity = this.model.get('specialNecessity').value;
    data.idSpecialNecessity = this.model.get('idSpecialNecessity').value;

    data.idCareLinePriority = this.model.get('idCareLinePriority').value;

    if (this.specialNecessityList) {
      let specialNecessityName = this.specialNecessityList.find(c => c.idSpecialNecessity == data.idSpecialNecessity)
      data.specialNecessityName = specialNecessityName ? specialNecessityName.specialNecessityName : null;
    }

    if (!Array.isArray(data.discriminatorValues))
      data.discriminatorValues = [];

    if (!(this.temperaturaWasMarked || this.sepsePossivelCriancaWasMarked) && temperatura) {
      data.discriminatorValues[DiscriminatorTypeEnum.Temperatura] = null;
      data.temperatureComplementaryData = temperatura;
    }
    // else{
    //   data.temperatureComplementaryData = null;
    // }

    if (!(this.frequenciaCardiacaWasMarked || this.sepsePossivelCriancaWasMarked) && frequenciaCardiaca) {
      data.discriminatorValues[DiscriminatorTypeEnum.FrequenciaCardiaca] = null;
      data.heartRateComplementaryData = frequenciaCardiaca;
    }
    // else
    //   data.heartRateComplementaryData = null;

    if (!(this.pressaoArterialWasMarked || this.sepsePossivelWasMarked) && pressaoArterial) {
      data.bloodPressureSystoleComplementaryData = parseInt(pressaoArterial.split("/")[0]);
      data.bloodPressureDiastoleComplementaryData = parseInt(pressaoArterial.split("/")[1]);
      data.discriminatorValues[DiscriminatorTypeEnum.PressaoArterial] = null;
    }
    // else {
    //   data.bloodPressureDiastoleComplementaryData = null;
    //   data.bloodPressureSystoleComplementaryData = null;
    // }

    if (!(this.freqRespWasMarked || this.sepsePossivelWasMarked) && frequenciaRespiratoria) {
      data.discriminatorValues[DiscriminatorTypeEnum.FrequenciaRespiratoria] = null;
      data.respiratoryFrequencyComplementaryData = frequenciaRespiratoria;
    }
    // else
    //   data.respiratoryFrequencyComplementaryData = null;

    if (!this.glicemiaWasMarked && glicemia) {
      data.discriminatorValues[DiscriminatorTypeEnum.Glicemia] = null;
      data.glucoseComplementaryData = glicemia;
    }
    // else
    //   data.glucoseComplementaryData = null;

    if (!this.saturacaoWasMarked && saturacao) {
      data.saturationComplementaryData = saturacao;
      data.discriminatorValues[DiscriminatorTypeEnum.Saturacao] = null;
    }
    // else
    //   data.saturationComplementaryData = null;

    if (this.careLineIsConfirmedOrDenied) {
      data.openProtocolTriage = false;
      this.redirectAndSaveSession(data);
    }
    else {
      let careLineWithProtocolTriage = this.listCareLinePriorityStruct.find(x => {
        return this.model.get('idCareLinePriority') && this.model.get('idCareLinePriority').value == x.careLinePriority.idCareLinePriority &&
          x.careLinePriority.openProtocolTriage === true;
      });

      if (careLineWithProtocolTriage)
        this.openAlertModal(careLineWithProtocolTriage, data);
      else {
        data.openProtocolTriage = false;
        this.redirectAndSaveSession(data);
      }
    }
  }

  temperaturaOnChange(event: any) {
    if (event.target.value && !this.model.get("temperature").invalid)
      this.discriminatorValues[DiscriminatorTypeEnum.Temperatura] = event.target.value;
  }

  frequenciaCardiacaOnChange(event: any) {
    if (event.target.value && !this.model.get("heartRate").invalid)
      this.discriminatorValues[DiscriminatorTypeEnum.FrequenciaCardiaca] = event.target.value;
  }

  pressaoArterialOnChange(event: any) {
    if (event.target.value && !this.model.get("bloodPressure").invalid)
      this.discriminatorValues[DiscriminatorTypeEnum.PressaoArterial] = event.target.value;
  }

  frequenciaRespiratoriaOnChange(event: any) {
    if (event.target.value && !this.model.get("respiratoryFrequency").invalid)
      this.discriminatorValues[DiscriminatorTypeEnum.FrequenciaRespiratoria] = event.target.value;
  }

  glicemiaOnChange(event: any) {
    if (event.target.value && !this.model.get("glucose").invalid)
      this.discriminatorValues[DiscriminatorTypeEnum.Glicemia] = event.target.value;
  }

  saturacaoOnChange(event: any) {
    if (event.target.value && !this.model.get("saturation").invalid)
      this.discriminatorValues[DiscriminatorTypeEnum.Saturacao] = event.target.value;
  }

  suspicionOnChange(event: any) {
    if (event?.value && !this.model.get("idSuspicion").invalid) {
      this.suspicionSelected = this.listSuspicion ? this.listSuspicion.find(c => c.idSuspicion == event.value) : null;
      let episodeData = this.utilHeaderService.getSessionEpisodeData();
      episodeData.suspicionName = this.suspicionSelected.suspicionName;
      if (episodeData.isSuspicionEvaluated == false) {
        episodeData.suspicionEvaluationMotive = null;
        episodeData.isSuspicionEvaluated = null;
        episodeData.suspicionStatusName = null;
        episodeData.datetimeSuspicionEvaluation = null;
      }
      this.utilHeaderService.createSessionEpisodeData(episodeData);
      this.menu.populateSecundaryHeader();
    }
  }

  checkOutros(event: number[]) {
    var listId = this.model.get("listIdComorbodity").value ? this.model.get("listIdComorbodity").value : [];
    if (listId.filter(x => x == ComorbidityEnum.outros).length > 0)
      this.hasComorbidityOutros = true;
    else {
      this.model.get("diseaseHistory").setValue('');
      this.hasComorbidityOutros = false;
    }
    if (event != null) {
      if (event.includes(ComorbidityEnum.negaComorbidades) && !this.selectedDeniesComorbidities) {
        this.model.get("listIdComorbodity").setValue([ComorbidityEnum.negaComorbidades])
        this.selectedDeniesComorbidities = true;
      }
      else if (this.selectedDeniesComorbidities)
        this.selectedDeniesComorbidities = false;
    }
  }

  // private keepAliveIntervalId = setInterval(() => {
  //   if (this.data.idRoom)
  //     this.roomAccessUtilService.postRoomAccessHistory(this.data.idRoom);
  // },
  //   this.roomAccessUtilService.roomKeepAliveTime());

  ngOnDestroy() {
    // clearInterval(this.keepAliveIntervalId);
    if (this.data.idDeviceType == DeviceTypeEnum.Trius || this.data.idDeviceType == DeviceTypeEnum.Berco || this.data.idDeviceType == DeviceTypeEnum.Bluetooth) {
      this.measurerService.setIndex(-1);
    }
  }

  listAllSectorRequiredFields() {
    this.supplementaryDataRequiredFieldSectorService.listBySector(this.idSector).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
          return;
        }
        this.listSectorRequiredField = response.listSectorRequiredField;

        this.verifyRequiredFields();
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    });
  }

  verifyRequiredFields() {
    this.listSectorRequiredField.forEach(x => {

      //sinais vitais
      if (x.idSupplementaryDataRequiredField == SupplementaryDataRequiredFieldEnum.temperature) {
        this.model.get('temperature').setValidators([Validators.required]);
        this.model.get('temperature').updateValueAndValidity();
      }
      else if (x.idSupplementaryDataRequiredField == SupplementaryDataRequiredFieldEnum.respiratoryFrequency) {
        this.model.get('respiratoryFrequency').setValidators([Validators.required]);
        this.model.get('respiratoryFrequency').updateValueAndValidity();
      }
      else if (x.idSupplementaryDataRequiredField == SupplementaryDataRequiredFieldEnum.heartRate) {
        this.model.get('heartRate').setValidators([Validators.required]);
        this.model.get('heartRate').updateValueAndValidity();
      }
      else if (x.idSupplementaryDataRequiredField == SupplementaryDataRequiredFieldEnum.glucose) {
        this.model.get('glucose').setValidators([Validators.required]);
        this.model.get('glucose').updateValueAndValidity();
      }
      else if (x.idSupplementaryDataRequiredField == SupplementaryDataRequiredFieldEnum.satO2) {
        this.model.get('saturation').setValidators([Validators.required]);
        this.model.get('saturation').updateValueAndValidity();
      }
      else if (x.idSupplementaryDataRequiredField == SupplementaryDataRequiredFieldEnum.bloodPressure) {
        this.model.get('bloodPressure').setValidators([Validators.required]);
        this.model.get('bloodPressure').updateValueAndValidity();
      }

      //outros campos
      else if (x.idSupplementaryDataRequiredField == SupplementaryDataRequiredFieldEnum.weight) {
        this.model.get('weight').setValidators([Validators.required, weightValidator()]);
        this.model.get('weight').updateValueAndValidity();
      }
      else if (x.idSupplementaryDataRequiredField == SupplementaryDataRequiredFieldEnum.height) {
        this.model.get('height').setValidators([Validators.required]);
        this.model.get('height').updateValueAndValidity();
      }
      else if (x.idSupplementaryDataRequiredField == SupplementaryDataRequiredFieldEnum.riskFall) {
        this.model.get('radioFallRisk').setValidators([Validators.required]);
        this.model.get('radioFallRisk').updateValueAndValidity();
      }
      else if (x.idSupplementaryDataRequiredField == SupplementaryDataRequiredFieldEnum.allergies) {
        this.model.get('firstChildGroup').get('listIdAllergies').setValidators([Validators.required]);
        this.model.get('firstChildGroup').get('listIdAllergies').updateValueAndValidity();
      }
      else if (x.idSupplementaryDataRequiredField == SupplementaryDataRequiredFieldEnum.suspicions) {
        this.model.get('idSuspicion').setValidators([Validators.required]);
        this.model.get('idSuspicion').updateValueAndValidity();
      }
      else if (x.idSupplementaryDataRequiredField == SupplementaryDataRequiredFieldEnum.specialNecessity) {
        this.model.get('idSpecialNecessity').setValidators([Validators.required]);
        this.model.get('idSpecialNecessity').updateValueAndValidity();
      }
      else if (x.idSupplementaryDataRequiredField == SupplementaryDataRequiredFieldEnum.comorbidities) {
        this.model.get('listIdComorbodity').setValidators([Validators.required]);
        this.model.get('listIdComorbodity').updateValueAndValidity();
      }
      else if (x.idSupplementaryDataRequiredField == SupplementaryDataRequiredFieldEnum.observation) {
        this.model.get('observation').setValidators([Validators.required]);
        this.model.get('observation').updateValueAndValidity();
      }
    });
  }

  populateCareLinePrioritySelect() {
    this.getCareLinePriorityService.getAllByIdHealthUnit().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listCareLinePriority = response.listCareLinePriority;
        this.listCareLinePriorityStruct = response.listCareLinePriorityStruct;
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    });
  }

  setCoomaLastDigit(number: number): number {
    if (!number)
      return;

    let numberString: string = number.toString();
    numberString = numberString.substring(0, numberString.length - 1) + "." + numberString.slice(-1)
    let result = parseFloat(numberString);
    return result;
  }

  joinBloodPressures(bloodPressureSystole, bloodPressureDiastole) {
    return `${bloodPressureSystole}/${bloodPressureDiastole}`;
  }

  cleanComplementaryData() {
    let temperatura = Array.isArray(this.discriminatorValues) && this.discriminatorValues[DiscriminatorTypeEnum.Temperatura] ? this.discriminatorValues[DiscriminatorTypeEnum.Temperatura].toString().replace(".", "").replace(",", "") : null;
    temperatura = temperatura ? temperatura.split("")[0] + temperatura.split("")[1] + "." + temperatura.split("")[2] : null;
    let frequenciaRespiratoria = Array.isArray(this.discriminatorValues) && this.discriminatorValues[DiscriminatorTypeEnum.FrequenciaRespiratoria] ? this.discriminatorValues[DiscriminatorTypeEnum.FrequenciaRespiratoria].toString() : '';
    let frequenciaCardiaca = Array.isArray(this.discriminatorValues) && this.discriminatorValues[DiscriminatorTypeEnum.FrequenciaCardiaca] ? this.discriminatorValues[DiscriminatorTypeEnum.FrequenciaCardiaca].toString() : '';
    let glicemia = Array.isArray(this.discriminatorValues) && this.discriminatorValues[DiscriminatorTypeEnum.Glicemia] ? this.discriminatorValues[DiscriminatorTypeEnum.Glicemia].toString() : '';

    let pressaoArterial;
    if (Array.isArray(this.discriminatorValues) && this.discriminatorValues[DiscriminatorTypeEnum.PressaoArterial]) {
      let pa: string = this.discriminatorValues[DiscriminatorTypeEnum.PressaoArterial].replace("/", "").split("");
      let value1 = pa.length == 4 ? pa[0] + pa[1] : pa[0] + pa[1] + pa[2];
      let value2 = pa.length == 4 ? pa[2] + pa[3] : pa.length == 5 ? pa[3] + pa[4] : pa[3] + pa[4] + pa[5];
      pressaoArterial = value1 + "/" + value2;
    }

    let saturacao = Array.isArray(this.discriminatorValues) && this.discriminatorValues[DiscriminatorTypeEnum.Saturacao] ? this.discriminatorValues[DiscriminatorTypeEnum.Saturacao].toString() : '';

    if ((this.temperaturaWasMarked || this.sepsePossivelCriancaWasMarked) && temperatura)
      this.data.temperatureComplementaryData = null;

    if ((this.frequenciaCardiacaWasMarked || this.sepsePossivelCriancaWasMarked) && frequenciaCardiaca)
      this.data.heartRateComplementaryData = null;

    if ((this.pressaoArterialWasMarked || this.sepsePossivelWasMarked) && pressaoArterial) {
      this.data.bloodPressureSystoleComplementaryData = null;
      this.data.bloodPressureDiastoleComplementaryData = null;
    }

    if ((this.freqRespWasMarked || this.sepsePossivelWasMarked) && frequenciaRespiratoria)
      this.data.respiratoryFrequencyComplementaryData = null;

    if (this.glicemiaWasMarked && glicemia)
      this.data.glucoseComplementaryData = null;

    if (this.saturacaoWasMarked && saturacao)
      this.data.saturationComplementaryData = null;

    this.utilClassification.updateClassificationData(this.data);
  }

  async restartMeasurers() {
    await this.measurerService.restartMeasurer();
    this.alertService.show('Info', "Medidores reiniciados", AlertType.info);
  }

  //Websocket
  startWebsocket() {
    if (!this.websocketAttendanceUtilService.isConnect())
      this.websocketAttendanceUtilService.connectwebsocketRoom(this.idRoom, this.idQueue, this.idEpisode, this, true, false, this.authService.getToken(), this.userLogin, this.userName);

    this.websocketAttendanceUtilService.setContext(this);
    this.websocketAttendanceUtilService.setFunctions(null, null, this.onRemoveAttendant);
  }

  onRemoveAttendant(thisParam, res: any) {
    thisParam.isAttendanceRemoval = false;

    if (res.userLogin != thisParam.userLogin) {
      thisParam.isAttendanceRemoval = true;

      thisParam.websocketAttendanceUtilService.clearSocket();
      let ref = thisParam.dialog.open(AttendanceOccupiedRemoveModalComponent, { data: { ...res } });
      ref.afterClosed().subscribe(() => {
        thisParam.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          thisParam.router.navigate(['/classification/classification-patient-list', { idSector: thisParam.idSector, idRoom: thisParam.idRoom }]));
      });
    }
  }

  openAlertModal(careLineWithProtocolTriage: CareLinePriorityStruct, data: ClassificationData) {
    let description = `Ao confirmar a linha de cuidado, você também registrará a abertura de procotolo para: 
    ${careLineWithProtocolTriage.careLinePriority.protocolName}.
     A data e hora do registro será associada a este atendimento.`
    const dialogRef = this.dialog.open(AlertModalComponent, {
      data: {
        isTwoButtonsModal: true,
        title: 'Atenção!',
        description: description,
        hasImage: true,
        textButtonConfirm: "Voltar",
        textButtonCancel: "Registrar abertura"
      },
    });
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (!result.confirm)
          this.getDateTimeStart(careLineWithProtocolTriage, data);
        else {
          data.openProtocolTriage = false;
          return;
        }
      },
      error: (error) => {
        data.openProtocolTriage = false;
        console.log(error);
      }
    });
  }

  getDateTimeStart(careLineWithProtocolTriage: CareLinePriorityStruct, data: ClassificationData) {
    this.clockService.getDate().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          data.openProtocolTriage = false;
          return;
        }

        this.openNextStepsProtocolModal(careLineWithProtocolTriage, data, response.date);
      },
      error: (error) => {
        data.openProtocolTriage = false;
        console.log(error);
      }
    });
  }

  openNextStepsProtocolModal(careLineWithProtocolTriage: CareLinePriorityStruct, data: ClassificationData, dateStart: Date) {
    let nextSteps = careLineWithProtocolTriage.listNextStepsAfterTriageProtocol
      .filter(y => y.idCareLinePriority === this.model.get('idCareLinePriority').value);
    const dialogRef = this.dialog.open(NextStepsProtolModalComponent, {
      data: {
        nextSteps: nextSteps,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        data.datetimeRegisterProtocolCareLine = dateStart;
        data.careLineProtocolName = careLineWithProtocolTriage.careLinePriority.protocolName;
        data.listNextStepsAfterTriageProtocolDescription = nextSteps.map(x => x.nextStepsAfterTriageProtocolDescription);
        data.openProtocolTriage = true;
        this.redirectAndSaveSession(data);
      },
      error: (error) => {
        data.openProtocolTriage = false;
        console.log(error);
      }
    });
  }

  redirectAndSaveSession(data: ClassificationData) {
    this.utilClassification.updateClassificationData(data);
    this.isNext = true;
    this.router.navigate(['/classification/triage-resume', { idEpisode: this.idEpisode, idRoom: this.idRoom, idQueue: this.idQueue }]);
  }

  removeCareLine() {
    if (!this.isCareLinePrioritySelected) {
      const idCareLinePriority = this.model.get('idCareLinePriority');
      if (idCareLinePriority)
        idCareLinePriority.setValue(null);
    }
  }

  alertDynamic(alertTypeDescription: string, alertDescription: string, alertType: AlertType, endLoading: boolean = false) {
    if (alertType && alertType.valueOf() == AlertType.error)
      console.log(alertDescription);

    this.alertService.show(alertTypeDescription, alertDescription, alertType ? alertType.valueOf() : AlertType.error);
    if (endLoading)
      this.endLoading();
  }

  endLoading() {
    this.isFirstLoading = false;
  }
}