import { Component, OnInit } from '@angular/core';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { CsatService } from 'src/app/shared/services/API/csat/csat.service';

@Component({
  selector: 'app-observation',
  templateUrl: './observation.component.html',
  styleUrls: ['./observation.component.css']
})
export class ObservationComponent implements OnInit {

  constructor(private csatService: CsatService) { }

  public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.observation;

  public seeButton: boolean = false;

  ngOnInit(): void {
    this.getCsat();
  }

  getCsat() {
    this.csatService.getCsat().subscribe({
      next: (response) => {
        this.seeButton = response.seeButton;
      },
    });
  }
}
