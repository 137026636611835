<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <h1>Logs de Requisição</h1>
        <div class="white-body">
            <div class="filters">
                <div class="row">   
                    <div class="col-4 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Pesquisa</mat-label>
                            <input matInput type="text" [(ngModel)]="searchText" (keyup)="onKeySearch($event)">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Requisição</mat-label>
                            <mat-select [(ngModel)]="idUri" (selectionChange)="search(0)">
                              <mat-option value="">Todos</mat-option> 
                              <mat-option *ngFor="let item of listUri" value="{{item.idUri}}">{{item.httpVerb}} {{item.description}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-2 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Episódio</mat-label>
                            <input matInput type="text" [(ngModel)]="idEpisode" oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');" (keyup)="onKeySearch($event)">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-md-2"> 
                        <mat-form-field appearance="outline">
                          <mat-label>Status</mat-label>
                          <mat-select [(ngModel)]="idLogType" (selectionChange)="search(0)">
                            <mat-option value="">Todos</mat-option> 
                            <mat-option *ngFor="let item of listLogType" value="{{item.idLogType}}">{{item.description}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-3 col-lg-2"> 
                        <mat-form-field appearance="outline">
                            <mat-label>Data da Requisição</mat-label>
                            <input matInput [matDatepicker]="picker"  (dateChange)="dateChage($event)">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="card-list" *ngIf="listIntegrationLog != null">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listIntegrationLog">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary">{{item.httpVerb}} {{item.uriDescrption}}</span>
                                <span class="secundary">{{item.logType}}</span>
                                <span class="secundary-status last-info">{{item.datetimeBeginRequest | date: 'dd/MM/yyyy HH:mm'}}</span>
                            </div>
                            <div class="actions">
                                <a><mat-icon aria-hidden="false" aria-label="Editar" [routerLink] = "['/integration/integration-log/details', {idIntegrationLog: item.idIntegrationLog}]">edit</mat-icon></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-paginator [length]="this.fullListSize" #paginator [pageIndex]="0" [pageSize]="30" aria-label="Select page" (page)="changePage($event)">
                    </mat-paginator>
                </div>
            </div>
    
            <app-empty-list *ngIf="listIntegrationLog != undefined && listIntegrationLog.length == 0"></app-empty-list>
        </div>
    
        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>

</div>
