<div class="container-generic">

    <div *ngIf="isLoading" (click)="clickIsLoading()" class="block-window"></div>
    <app-menu #menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'
        [hasSecundaryHeader]="true" [episode]="selectedEpisode" [hasCachedModule]="true"
        [cachedModuleName]="'medic'"></app-menu>

    <div class="container-body-secundary-header">
        <h1>Atendimento Médico</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div [ngClass]="{'white-body-online-attend': remoteAttendance, 'white-body': !remoteAttendance}">
            <div class="row">
                <div *ngIf="remoteAttendance" class="fixed-video col-12 col-sm-3" id="videoDiv">
                    <app-twilio-video #twiliovideo (leaveRoom)="onLeaveRoom()"></app-twilio-video>
                </div>
                <div [ngClass]="{'col-sm-9': remoteAttendance}" class="col-12">
                    <form class="form" [formGroup]="model">
                        <div class="title-header">
                            <h1>Novo Atendimento</h1>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12">
                                <app-allergy-tag [inputListTags]="allergies" [listTags]="allergiesToSave"
                                    [isError]="allergyError" (updateAllergiesToSave)="updateAllergiesToSave($event)">
                                </app-allergy-tag>
                            </div>
                        </div>
                        <div class="col-12 care-line-priority" *ngIf="this.listCareLinePriority.length > 0">
                            <mat-form-field *ngIf="episodeStruct" appearance="outline">
                                <mat-label>Linha de Cuidado em prioridade</mat-label>
                                <mat-select
                                    [ngClass]="{'hide':episodeStruct && (episodeStruct.idCareLinePriorityStatus === careLinePriorityStatusConfirmed || episodeStruct.idCareLinePriorityStatus === careLinePriorityStatusDenied)}"
                                    formControlName="idCareLinePriority"
                                    (ngModelChange)="updateCareLinePriorityStruct()">
                                    <mat-option *ngFor="let item of listCareLinePriority"
                                        [value]="item.idCareLinePriority">
                                        {{item.careLinePriorityName}}
                                    </mat-option>
                                </mat-select>
                                <div class="validated-care-line-priority"
                                    *ngIf="(episodeStruct.idCareLinePriorityStatus === careLinePriorityStatusConfirmed || episodeStruct.idCareLinePriorityStatus === careLinePriorityStatusDenied)">
                                    <span class="field">
                                        <h2>Linha de cuidado: </h2>
                                        {{careLinePriorityStruct.careLinePriorityName}}
                                    </span>
                                    <span class="field">
                                        <h2>Usuário responsável: </h2>
                                        {{careLinePriorityStruct.careLinePriorityStatusUserName}}
                                    </span>
                                    <span class="field">
                                        <h2>Data de {{episodeStruct.idCareLinePriorityStatus ===
                                            careLinePriorityStatusConfirmed
                                            ? 'confirmação' : 'negação'}}: </h2>
                                        {{careLinePriorityStruct.datetimeCareLinePriorityStatus | date:'d/MM/yH:mm'}}
                                    </span>
                                    <span class="field">
                                        <h2>Conclusão: </h2>
                                        {{careLinePriorityStruct.idCareLinePriorityStatus ===
                                        careLinePriorityStatusConfirmed ? 'Confirmada' : 'Negada'}}
                                    </span>
                                    <span class="field">
                                        <h2>Motivo: </h2>
                                        <span class="motive">
                                            {{careLinePriorityStruct.careLinePriorityStatusMotive}}

                                        </span>
                                    </span>
                                </div>
                            </mat-form-field>
                            <div *ngIf="episodeStruct" class="row">
                                <div class="col-6 col-sm-6 col-md-4 validate-care-line-priority-button">
                                    <a *ngIf="careLinePriorityStruct && careLinePriorityStruct.idCareLinePriorityStatus === careLinePriorityStatusSuspicion"
                                        id="validateCareLinePriority" mat-flat-button type="button"
                                        (click)="validateCareLinePriority()" color="accent"
                                        class="btn-block validate-care-line-priority-button"
                                        [ngClass]="{'error':model.controls['idCareLinePriority'].errors?.careLineValidation}">
                                        <mat-icon aria-hidden="false" aria-label="Plus">fact_check</mat-icon> Validar
                                        suspeita
                                    </a>
                                    <span class="error error-message"
                                        *ngIf="model.controls['idCareLinePriority'].errors?.careLineValidation">Suspeita
                                        de linha de cuidado deve ser validada
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 care-line-priority"
                            *ngIf="this.listSuspicion && this.listSuspicion.length > 0 && this.triage && this.triage.idSuspicion">
                            <mat-form-field appearance="outline">
                                <mat-label>Suspeita</mat-label>
                                <mat-select
                                    [ngClass]="{'hide':triage && (triage.isSuspicionEvaluated === isSuspicionEvaluatedConfirmed || triage.isSuspicionEvaluated === isSuspicionEvaluatedDenied)}"
                                    formControlName="idSuspicion" readonly>
                                    <mat-option *ngFor="let item of listSuspicion" [value]="item.idSuspicion">
                                        {{item.suspicionName}}
                                    </mat-option>
                                </mat-select>
                                <div class="validated-care-line-priority"
                                    *ngIf="triage && (triage.isSuspicionEvaluated === isSuspicionEvaluatedConfirmed || triage.isSuspicionEvaluated === isSuspicionEvaluatedDenied)">
                                    <span class="field">
                                        <h2>Suspeita: </h2>
                                        {{suspicionValidatedStruct.suspicionName}}
                                    </span>
                                    <span class="field">
                                        <h2>Usuário responsável: </h2>
                                        {{suspicionValidatedStruct.suspicionUserName}}
                                    </span>
                                    <span class="field">
                                        <h2>Data de {{triage.isSuspicionEvaluated ===
                                            isSuspicionEvaluatedConfirmed
                                            ? 'confirmação' : 'negação'}}: </h2>
                                        {{suspicionValidatedStruct.datetimeSuspicionEvaluation | date:'d/MM/y
                                        H:mm'}}
                                    </span>
                                    <span class="field">
                                        <h2>Conclusão: </h2>
                                        {{suspicionValidatedStruct.isSuspicionEvaluated ===
                                        isSuspicionEvaluatedConfirmed ? 'Confirmada' :
                                        suspicionValidatedStruct.isSuspicionEvaluated === isSuspicionEvaluatedDenied ?
                                        'Negada' : 'Aguardando validação'}}
                                    </span>
                                    <span class="field">
                                        <h2>Motivo: </h2>
                                        <span class="motive">
                                            {{suspicionValidatedStruct.suspicionEvaluationMotive}}
                                        </span>
                                    </span>
                                </div>
                            </mat-form-field>
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-4">
                                    <a *ngIf="triage && triage.isSuspicionEvaluated === isSuspicionEvaluatedSuspicion && triage.idSuspicion != null"
                                        mat-flat-button type="button" (click)="validateSuspicionModal()" color="accent"
                                        class="btn-block">
                                        <mat-icon aria-hidden="false" aria-label="Plus">fact_check</mat-icon> Validar
                                        suspeita
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="medicalEvolution" class="row">
                            <div class="col-12 col-sm-12 col-md-12">
                                <mat-form-field appearance="outline">
                                    <mat-label>Anotações médicas (queixas, história da doença atual, medicamentos em
                                        uso,
                                        história
                                        pregressa)</mat-label>
                                    <textarea matInput formControlName="medicalNotes"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                        <div *ngIf="medicalEvolution" class="row">
                            <div class="col-12 col-sm-12 col-md-12">
                                <mat-form-field appearance="outline">
                                    <mat-label>Exame físico</mat-label>
                                    <textarea matInput formControlName="physicalExamination"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                        <div *ngIf="medicalEvolution" class="row">
                            <div class="col-12 col-sm-12 col-md-12">
                                <app-select-2 [formControlField]="'cid'" (selectedValue)="addItemCid($event)"
                                    [multiple]="true" [url]="urlListCid" [idField]="'idCid'"
                                    [preSelectedlist]="cidSelect" [nameField]="'cidName'" [labelField]="'CID'">
                                </app-select-2>
                            </div>
                        </div>
                        <div *ngIf="medicalEvolution" class="row">
                            <div class="col-12 col-sm-12 col-md-12">
                                <mat-form-field appearance="outline">
                                    <mat-label>Plano Terapêutico</mat-label>
                                    <textarea matInput formControlName="therapeuticPlan"></textarea>
                                </mat-form-field>
                            </div>
                        </div>

                        <div *ngIf="medicalEvolution" class="row">
                            <div class="col-12 col-sm-12 col-md-12">
                                <mat-form-field appearance="outline">
                                    <mat-label>Hipótese Diagnóstica</mat-label>
                                    <textarea matInput formControlName="diagnosedHypothesis"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-3 col-md-2">
                                <mat-form-field appearance="outline">
                                    <mat-label>Temperatura</mat-label>
                                    <input (blur)="onBlurTemperature()" [mask]="masks.temperature" type="text" matInput
                                        formControlName="temperature">
                                    <span matSuffix>ºC</span>
                                </mat-form-field>
                            </div>
                            <div class="col-6 col-sm-3 col-md-2">
                                <mat-form-field appearance="outline">
                                    <mat-label>FR</mat-label>
                                    <input [mask]="masks.threeDigits" type="text" matInput
                                        formControlName="respiratoryFrequency">
                                    <span matSuffix>irpm</span>
                                </mat-form-field>
                            </div>
                            <div class="col-6 col-sm-3 col-md-2">
                                <mat-form-field appearance="outline">
                                    <mat-label>FC</mat-label>
                                    <input [mask]="masks.threeDigits" type="text" matInput formControlName="heartRate">
                                    <span matSuffix>bpm</span>
                                </mat-form-field>
                            </div>
                            <div class="col-6 col-sm-3 col-md-2">
                                <mat-form-field appearance="outline">
                                    <mat-label>GC</mat-label>
                                    <input (blur)="onBlurGlucose()" [mask]="masks.threeDigits" type="text" matInput
                                        formControlName="glucose">
                                    <span matSuffix>mg/dL</span>
                                </mat-form-field>
                            </div>
                            <div class="col-6 col-sm-3 col-md-2">
                                <mat-form-field appearance="outline">
                                    <mat-label>PA</mat-label>
                                    <input (blur)="onBlurPressure()" [mask]="masks.pressure" type="text" matInput
                                        formControlName="bloodPressure">
                                    <span matSuffix>mmHG</span>
                                </mat-form-field>
                            </div>
                            <div class="col-6 col-sm-3 col-md-2">
                                <mat-form-field appearance="outline">
                                    <mat-label>SatO2</mat-label>
                                    <input [mask]="masks.threeDigits" type="text" matInput formControlName="saturation">
                                    <span matSuffix>%</span>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="border-list">
                            <div class="title-list">
                                <div class="row">
                                    <div class="col-6 col-sm-6 col-md-4">
                                        <span class="subtitle">Prescrições Padronizadas</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="prescription">
                                    <div class="col-6 col-sm-6 col-md-4">
                                        <a mat-flat-button type="button"
                                            (click)="openPrescriptionModal(-1, null, false, null)" color="accent"
                                            class=" btn-block">
                                            <mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon>
                                            Prescrições
                                            Padronizadas
                                        </a>
                                    </div>
                                    <div class="col-1 favorite-icon" *ngIf="!favoriteStandardMedicine">
                                        <i class="material-icons" matTooltip="Adicionar aos favoritos"
                                            matTooltipPosition="above" (click)="favoriteMedicineModal()">star_border</i>
                                    </div>
                                    <div class="col-1 favorite-icon" *ngIf="favoriteStandardMedicine">
                                        <i class="material-icons" matTooltip="Adicionar aos favoritos"
                                            matTooltipPosition="above">star</i>
                                    </div>
                                    <div class="col-4 col-sm-2 col-lg-1">
                                        <a mat-flat-button color="primary" (click)="selectFavoriteMedicineModal()"
                                            class="btn-block">
                                            <div
                                                style="display: flex; align-items: baseline; flex-direction: row-reverse;">
                                                <div class="favorite-icon-button">
                                                    <i class="material-icons">star_border</i>
                                                </div>
                                                <span style="padding-top: 2px;">Favoritos</span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-6 col-sm-6 col-md-3" *ngIf="idBed">
                                    <a *ngIf="!isLoadingLasPrescriptions" mat-flat-button type="button"
                                        (click)="getLastPrescriptions()" color="primary" class="btn-block">
                                        <mat-icon aria-hidden="false" aria-label="Plus">history</mat-icon> Resgatar
                                        prescrição anterior
                                    </a>
                                    <a *ngIf="isLoadingLasPrescriptions" mat-flat-button type="button" color="primary"
                                        class="btn-block">
                                        <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                                    </a>
                                </div>
                            </div>
                            <div class="sub-section" *ngIf="listPrescription != null && listPrescription.length > 0">
                                <h3>Medicamentos</h3>
                            </div>
                            <div class="card-list" *ngIf="listPrescription != null && listPrescription.length > 0">
                                <div class="row row-list">
                                    <div class="col-12 col-sm-6 col-md-4"
                                        *ngFor="let item of listPrescription; let index = index;">
                                        <div class="card-item">
                                            <div class="infos">
                                                <span class="primary">{{item.medicineName}}</span>
                                                <span class="secundary">Uso: {{item.usage}}</span>
                                                <span class="secundary">Via: {{item.administrationTypeName}}</span>
                                                <span class="secundary">Apresentação: {{item.presentationName}}</span>
                                                <span class="secundary">Frequência: {{item.frequencyName}}</span>
                                                <span class="secundary">Periodo trat.:
                                                    {{item.treatmentPeriodName}}</span>
                                            </div>
                                            <div class="actions">
                                                <a>
                                                    <mat-icon aria-hidden="false" aria-label="Editar"
                                                        (click)="openPrecriptionModalWithMedicine(index, item, true)">edit</mat-icon>
                                                </a>
                                                <a>
                                                    <mat-icon aria-hidden="false" aria-label="Excluir"
                                                        (click)="deletePrescription(index)">delete</mat-icon>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="sub-section"
                                *ngIf="listPrescriptionMedicatedSolution != null && listPrescriptionMedicatedSolution.length > 0">
                                <h3>Solução medicamentosa</h3>
                            </div>
                            <div class="card-list"
                                *ngIf="listPrescriptionMedicatedSolution != null && listPrescriptionMedicatedSolution.length > 0">
                                <div class="row row-list">
                                    <div class="col-12 col-sm-6 col-md-4"
                                        *ngFor="let item of listPrescriptionMedicatedSolution; let index = index;">
                                        <div class="card-item">
                                            <div class="infos">
                                                <div
                                                    *ngFor="let medicine of item.listMedicine; let indexMedicine = index;">
                                                    <span class="primary">{{medicine.medicineName}}</span>
                                                </div>
                                                <span class="secundary">Uso: {{item.usage}}</span>
                                                <span class="secundary">Via: {{item.administrationTypeName}}</span>
                                                <span class="secundary">Apresentação: {{item.presentationName}}</span>
                                                <span class="secundary">Frequência: {{item.frequencyName}}</span>
                                                <span class="secundary">Periodo trat.:
                                                    {{item.treatmentPeriodName}}</span>
                                            </div>
                                            <div class="actions">
                                                <a>
                                                    <mat-icon aria-hidden="false" aria-label="Editar"
                                                        (click)="openPrecriptionModalWithMedicatedSolution(index, item, true)">edit</mat-icon>
                                                </a>
                                                <a>
                                                    <mat-icon aria-hidden="false" aria-label="Excluir"
                                                        (click)="deletePrescriptionMedicatedSolution(index)">delete</mat-icon>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="border-list" *ngIf="!isMemedPrescription && !this.isDisableSession">
                            <div class="title-list">
                                <div class="row">
                                    <div class="col-6 col-sm-6 col-md-4">
                                        <span class="subtitle">Prescrições Não padronizadas</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="prescription">
                                    <div class="col-6 col-sm-6 col-md-4">
                                        <a mat-flat-button type="button"
                                            (click)="openDefaultExternalPrescriptionModal(null, false, -1)"
                                            color="accent" class=" btn-block">
                                            <mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon> Adicionar
                                            Prescrição Não
                                            Padronizada
                                        </a>
                                    </div>
                                    <div class="col-1 favorite-icon" *ngIf="!favoriteNonStandardMedicine">
                                        <i class="material-icons" matTooltip="Adicionar aos favoritos"
                                            matTooltipPosition="above"
                                            (click)="favoriteNonStandardMedicineModal()">star_border</i>
                                    </div>
                                    <div class="col-1 favorite-icon" *ngIf="favoriteNonStandardMedicine">
                                        <i class="material-icons" matTooltip="Adicionar aos favoritos"
                                            matTooltipPosition="above">star</i>
                                    </div>
                                    <div class="col-4 col-sm-2 col-lg-1">
                                        <a mat-flat-button color="primary"
                                            (click)="selectFavoriteNonStandardMedicineModal()" class="btn-block">
                                            <div
                                                style="display: flex; align-items: baseline; flex-direction: row-reverse;">
                                                <div class="favorite-icon-button">
                                                    <i class="material-icons">star_border</i>
                                                </div>
                                                <span style="padding-top: 2px;">Favoritos</span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="card-list"
                                *ngIf="listExternalPrescription != null && listExternalPrescription.length > 0">
                                <div class="row row-list">
                                    <div class="col-12 col-sm-6 col-md-4"
                                        *ngFor="let item of listExternalPrescription; let index = index;">
                                        <div class="card-item">
                                            <div class="infos">
                                                <span class="primary">{{item.description}}</span>
                                                <span class="secundary">Quantidade: {{item.quantity}}
                                                    {{item.measurementUnitName}}</span>
                                                <span class="secundary">Frequência: {{item.frequency}}</span>
                                                <span class="secundary">Observação: {{item.observation}}</span>
                                            </div>
                                            <div class="actions">
                                                <a>
                                                    <mat-icon aria-hidden="false" aria-label="Editar"
                                                        (click)="openDefaultExternalPrescriptionModal(item, false, index)">edit</mat-icon>
                                                </a>
                                                <a>
                                                    <mat-icon aria-hidden="false" aria-label="Excluir"
                                                        (click)="deleteExternalPrescription(index)">delete</mat-icon>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="border-list">
                            <div class="title-list">
                                <div class="row">
                                    <div class="col-6 col-sm-6 col-md-4">
                                        <span class="subtitle">Pedidos de Exames</span>
                                    </div>
                                </div>
                            </div>
                            <div class="checkbox-container" *ngIf="isFastTrack">
                                <mat-checkbox [checked]="bypassExam" (change)="toggleBypassExam($event)"
                                    [disabled]="blockBypassExam">
                                    Solicitar exames
                                </mat-checkbox>
                            </div>
                            <div class="row">
                                <div class="prescription">
                                    <div *ngIf="!readonly" class="col-6 col-sm-6 col-md-4">
                                        <a mat-flat-button type="button" (click)="openSadtModal(null, false, -1)"
                                            color="accent" class=" btn-block" *ngIf="!isFastTrack || bypassExam">
                                            <mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon> 
                                            Adicionar Pedido de Exame
                                        </a>
                                    </div>
                                    <div *ngIf="!isFastTrack || bypassExam">
                                        <div class="col-1 favorite-icon" *ngIf="!favoriteExameRequest">
                                            <i class="material-icons" matTooltip="Adicionar aos favoritos"
                                                matTooltipPosition="above"
                                                (click)="favoriteExamPrescriptionModal()">star_border</i>
                                        </div>
                                        <div class="col-1 favorite-icon" *ngIf="favoriteExameRequest">
                                            <i class="material-icons" matTooltip="Adicionar aos favoritos"
                                                matTooltipPosition="above">star</i>
                                        </div>
                                    </div>
                                    <div class="col-4 col-sm-2 col-lg-1"
                                        *ngIf="(!isFastTrack || bypassExam)">
                                        <a mat-flat-button color="primary" class="btn-block"
                                            (click)="selectFavoriteExamPrescriptionModal()">
                                            <div
                                                style="display: flex; align-items: baseline; flex-direction: row-reverse;">
                                                <div class="favorite-icon-button">
                                                    <i class="material-icons">star_border</i>
                                                </div>
                                                <span style="padding-top: 2px;">Favoritos</span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="card-list" *ngIf="listSADT != null && listSADT.length > 0">
                                <div class="row row-list">
                                    <div class="col-12 col-sm-6 col-md-4"
                                        *ngFor="let item of listSADT; let index = index;">
                                        <div class="card-item">
                                            <div class="infos">
                                                <span class="primary">{{item.typeSadtName}}</span>
                                                <span class="secundary">Uso: {{item.placeName}}</span>
                                                <span class="secundary">Status: Pedido</span>
                                            </div>
                                            <div *ngIf="!readonly" class="actions">
                                                <a>
                                                    <mat-icon aria-hidden="false" aria-label="Editar"
                                                        (click)="openSadtModal(item, false, index)">edit</mat-icon>
                                                </a>
                                                <a>
                                                    <mat-icon aria-hidden="false" aria-label="Excluir"
                                                        (click)="deleteSadt(index)">
                                                        delete</mat-icon>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="border-list" *ngIf="isMemedPrescription&& !this.isDisableSession">
                            <div class="title-list">
                                <div class="row">
                                    <div class="col-6 col-sm-6 col-md-4">
                                        <span class="subtitle">Prescrições e Exames externos via Memed</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-sm-6 col-md-4">
                                <a mat-flat-button type="button" (click)="openMemedPrescription()" color="accent"
                                    [disabled]="this.isFirstLoading || this.userTokenMemed == undefined"
                                    class=" btn-block">
                                    <mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon>
                                    Nova prescrição
                                </a>
                            </div>
                            <div class="card-list"
                                *ngIf="listMemedPrescriptionStruct != null && listMemedPrescriptionStruct.length > 0">
                                <div class="row row-list">
                                    <div class="col-12 col-sm-6 col-md-5"
                                        *ngFor="let item of listMemedPrescriptionStruct; let index = index;">
                                        <div class="card-item">
                                            <div class="infos">
                                                <div class="memed-prescription-info"
                                                    *ngFor="let medicine of item.listMemedMedicine; let indexMedicine = index;">
                                                    <span class="primary">{{indexMedicine + 1}} -
                                                        {{medicine.name}}</span>
                                                    <span class="secundary">Quantidade: {{medicine.quantity}}</span>
                                                    <span class="secundary">Unidade: {{medicine.unit}}</span>
                                                </div>
                                            </div>
                                            <div class="actions">
                                                <a>
                                                    <mat-icon aria-hidden="false" aria-label="Excluir"
                                                        (click)="deleteMemedPrescription(index)">delete</mat-icon>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="memed-modal-back"
                                [ngClass]="{'memed-modal-visible': this.showMemed, 'memed-modal-hide': !this.showMemed}">
                                <div class="memed-modal">
                                    <!-- <mat-icon class="close-icon" mat-icon aria-hidden="false" aria-label="close"
                                        (click)="closeMemedModal()">close</mat-icon> -->
                                    <app-memed-prescriptions
                                        *ngIf="!this.isFirstLoading && this.userTokenMemed  && patientCellPhone"
                                        [episodeStruct]="this.episodeStruct" [cpfPatient]="this.cpfPatient"
                                        [userToken]="this.userTokenMemed" [patientPhoneNumber]="this.patientCellPhone"
                                        (newPrescription)="newMemedPrescription($event)">
                                    </app-memed-prescriptions>
                                    <div class="footer-memed-modal">
                                        <div class="col-12 col-md-5">
                                            <button type="button" mat-flat-button color="accent" class="btn-block"
                                                (click)="closeMemedModal()">Fechar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="border-list" *ngIf="hasProcedure">
                            <div class="title-list">
                                <div class="row">
                                    <div class="col-6 col-sm-6 col-md-4">
                                        <span class="subtitle">Procedimentos</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-4">
                                    <a mat-flat-button type="button" (click)="openProcedureModal(null, false, -1)"
                                        color="accent" class=" btn-block">
                                        <mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon> Adicionar
                                        Procedimento
                                    </a>
                                </div>
                            </div>
                            <div class="card-list"
                                *ngIf="listMedicalProcedure != null && listMedicalProcedure.length > 0">
                                <div class="row row-list">
                                    <div class="col-12 col-sm-6 col-md-4"
                                        *ngFor="let item of listMedicalProcedure; let index = index;">
                                        <div class="card-item">
                                            <div class="infos">
                                                <span class="primary">{{getProcedureName(item.idProcedure)}}</span>
                                                <span class="secundary">Frequência:
                                                    {{getRecurranceName(item.isRecurring)}}</span>
                                                <span class="secundary">Observação: {{item.observation}}</span>
                                            </div>
                                            <div class="actions">
                                                <a>
                                                    <mat-icon aria-hidden="false" aria-label="Editar"
                                                        (click)="openProcedureModal(item, false, index)">edit</mat-icon>
                                                </a>
                                                <a>
                                                    <mat-icon aria-hidden="false" aria-label="Excluir"
                                                        (click)="deleteProcedure(index)">delete</mat-icon>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="border-list">
                            <div class="title-list">
                                <div class="row">
                                    <div class="col-6 col-sm-6 col-md-4">
                                        <span class="subtitle">Linhas de Cuidado</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-4">
                                    <a mat-flat-button type="button" (click)="openCareLineModal(listCareLine)"
                                        color="accent" class=" btn-block">
                                        <mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon> Adicionar Linha
                                        de
                                        Cuidado
                                    </a>
                                </div>
                            </div>
                            <div class="sub-section">
                                <h3>Atuais</h3>
                            </div>
                            <div class="card-list"
                                *ngIf="listCurrentEpisodeCareLine != null && listCurrentEpisodeCareLine.length > 0">
                                <div class="row row-list">
                                    <div class="col-12 col-sm-6 col-md-4"
                                        *ngFor="let item of listCurrentEpisodeCareLine; let index = index;">
                                        <div class="card-item">
                                            <div class="infos">
                                                <span class="primary">{{item.careLineName}}</span>
                                                <span *ngIf="item.userName" class="secundary">Alocado por:
                                                    {{item.userName}}</span>
                                                <span *ngIf="item.datetimeInclusion"
                                                    class="secundary">{{item.datetimeInclusion
                                                    | date:'dd/MM/yyyy \'às\' HH:mm'}}</span>
                                            </div>
                                            <div class="actions">
                                                <a *ngIf="listNewEpisodeCareLine.includes(item)">
                                                    <mat-icon aria-hidden="false" aria-label="Excluir"
                                                        (click)="removeCareLine(index, item.idCareLine)">delete</mat-icon>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="sub-section">
                                <h3>Antigas</h3>
                            </div>
                            <div class="card-list"
                                *ngIf="listPastEpisodeCareLine != null && listPastEpisodeCareLine.length > 0">
                                <div class="row row-list">
                                    <div class="col-12 col-sm-6 col-md-4"
                                        *ngFor="let item of listPastEpisodeCareLine; let index = index;">
                                        <div class="card-item">
                                            <div class="infos">
                                                <span class="primary">{{item.careLineName}}</span>
                                                <span class="secundary">Alocado por: {{item.userName}}</span>
                                                <span *ngIf="item.datetimeInclusion"
                                                    class="secundary">{{item.datetimeInclusion
                                                    | date:'dd/MM/yyyy \'às\' HH:mm'}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="title-header" *ngIf="!this.isDisableSession">
                            <h1>Isolamento e Atestado</h1>
                        </div>
                        <div class="row" *ngIf="!this.isDisableSession">
                            <div class="col-2 col-sm-2 col-md-2">
                                <mat-slide-toggle class="indigent-checkbox" [ngModelOptions]="{standalone: true}"
                                    (change)="changeIsolation($event)"
                                    [(ngModel)]="this.isIsolation">Isolamento</mat-slide-toggle>
                            </div>
                            <div class="col-2 col-md-2" *ngIf="this.isIsolation">
                                <mat-form-field appearance="outline">
                                    <mat-label>Último dia de Isolamento</mat-label>
                                    <input matInput formControlName="datetimeEndIsolation"
                                        [matDatepicker]="isolationDatePicker">
                                    <mat-datepicker-toggle matSuffix
                                        [for]="isolationDatePicker"></mat-datepicker-toggle>
                                    <mat-datepicker #isolationDatePicker></mat-datepicker>
                                    <mat-error *ngIf="model.get('datetimeEndIsolation').errors">
                                        {{model.get('datetimeEndIsolation').errors['invalidDate']}}</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-2 col-sm-2 col-md-2">
                                <mat-form-field appearance="outline">
                                    <mat-label>Dias de atestado</mat-label>
                                    <input matInput type="number" formControlName="sickDaysOffAmount">
                                    <span matSuffix>dias</span>
                                </mat-form-field>
                            </div>
                            <div class="col-2 col-sm-2 col-md-2">
                                <mat-slide-toggle class="indigent-checkbox" formControlName="isCidOnSickNote">Imprimir
                                    CID no atestado</mat-slide-toggle>
                            </div>
                        </div>
                        <div *ngIf="codCbo && !readonly && idCompetence" class="title-header">
                            <h1>Procedimentos Efetuados neste atendimento</h1>
                        </div>
                        <div class="row" *ngIf="codCbo && !readonly && idCompetence">
                            <div class="col-12 col-sm-12 col-md-6">
                                <app-select [preSelectedlist]="listPreselectedPRocedure"
                                    (selectedValue)="addItemProcedure($event)" [multiple]="true"
                                    [url]="urlListProcedure" [idField]="'idProcedureSigtap'"
                                    [nameField]="'completeProcedureName'"
                                    [labelField]="'Procedimentos Sigtap'"></app-select>
                            </div>
                        </div>
                        <div *ngIf="!this.readonly" class="title-header">
                            <h1>Evolução</h1>
                        </div>
                        <div *ngIf="!this.readonly" class="row">
                            <div class="col-12 radio-component">

                                <label *ngIf="this.isBedManagement">
                                    <input type="radio" formControlName="conclusionType"
                                        value="{{this.destinationTypeManter}}" class="destination"
                                        (change)="changeConclusionType($event)">
                                    <a mat-flat-button type="button" [color]="destinationTypeManterColor"
                                        class="btn-block">
                                        Manter em Observação
                                    </a>
                                </label>

                                <label *ngIf="!this.readonly && this.isBedManagement">
                                    <input type="radio" formControlName="conclusionType" class="destination"
                                        value="{{this.destinationTypeEncaminhamento}}"
                                        (change)="changeConclusionType($event)">
                                    <a mat-flat-button type="button" [color]="destinationTypeEncaminhamentoColor"
                                        class="btn-block">
                                        Encaminhar Paciente
                                    </a>
                                </label>

                                <label>
                                    <input type="radio" formControlName="conclusionType"
                                        value="{{this.destinationTypeAlta}}" class="destination"
                                        (change)="changeConclusionType($event)">
                                    <a mat-flat-button type="button" [color]="destinationTypeAltaColor"
                                        class="btn-block">
                                        Alta
                                    </a>
                                </label>


                                <label *ngIf="!this.isBedManagement && this.dischargeOutcome">
                                    <input type="radio" formControlName="conclusionType"
                                        value="{{this.destinationTypeAltaEncaminhamento}}" class="destination"
                                        (change)="changeConclusionType($event)">
                                    <a mat-flat-button type="button" [color]="destinationTypeAltaEncaminhamentoColor"
                                        class="btn-block">
                                        Alta após Encaminhamento
                                    </a>
                                </label>


                                <label *ngIf="!this.isBedManagement">
                                    <input type="radio" formControlName="conclusionType"
                                        value="{{this.destinationTypeEncaminhamento}}" class="destination"
                                        (change)="changeConclusionType($event)">
                                    <a mat-flat-button type="button" [color]="destinationTypeEncaminhamentoColor"
                                        class="btn-block">
                                        Encaminhar
                                    </a>
                                </label>

                                <label *ngIf="!this.isBedManagement">
                                    <input type="radio" formControlName="conclusionType"
                                        value="{{this.destinationTypeInternacaoEncaminhamento}}" class="destination"
                                        (change)="changeConclusionType($event)">
                                    <a mat-flat-button type="button"
                                        [color]="destinationTypeInternacaoEncaminhamentoColor" class="btn-block">
                                        Internar
                                    </a>
                                </label>

                                <label *ngIf="this.isBedManagement">
                                    <input type="radio" formControlName="conclusionType"
                                        value="{{this.destinationTypeInternacao}}" class="destination"
                                        (change)="changeConclusionType($event)">
                                    <a mat-flat-button type="button" [color]="destinationTypeInternacaoColor"
                                        class="btn-block">
                                        Internar
                                    </a>
                                </label>

                                <label>
                                    <input type="radio" formControlName="conclusionType"
                                        value="{{this.destinationTypeObito}}" class="destination"
                                        (change)="changeConclusionType($event)">
                                    <a mat-flat-button type="button" [color]="destinationTypeObitoColor"
                                        class="btn-block">
                                        Óbito
                                    </a>
                                </label>
                            </div>
                        </div>
                        <div class="row" formArrayName="flowSequence"
                            *ngFor="let item of model.get('flowSequence')['controls']; let i = index">
                            <div class="row" [formGroupName]="i"
                                *ngIf="destinationType == destinationTypeAltaEncaminhamento || destinationType == destinationTypeEncaminhamento">
                                <div class="col-6 col-md-6 col-sm-3">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Destino #{{i + 1}}</mat-label>
                                        <mat-select formControlName="idFlow">
                                            <mat-select-trigger>
                                                {{selectedFlows[i]}}
                                            </mat-select-trigger>
                                            <mat-option *ngFor="let flow of listAvailableFlows[i]" [value]="flow.idFlow"
                                                (click)="flowSequenceSelect(option, i)" appearance="outline"
                                                [disabled]="verifySADTFlow(flow, i)" #option>
                                                {{flow.originRoomName ?
                                                flow.originRoomName :
                                                (flow.originSectorName ?
                                                flow.originSectorName :
                                                (flow.originServiceName))}}
                                                -
                                                <mat-icon>arrow_right_alt</mat-icon>
                                                {{flow.destinyRoomName ?
                                                flow.destinyRoomName :
                                                (flow.destinySectorName ?
                                                flow.destinySectorName :
                                                (flow.destinyServiceName))}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                            *ngIf="isLoadingFlows && i == listAvailableFlows.length"></mat-spinner>
                                    </mat-form-field>
                                </div>
                                <div class="col-2 col-sm-2 col-md-3">
                                    <button
                                        *ngIf="model.get('flowSequence')['controls'][i].get('idFlow').value != null && i == model.get('flowSequence')['controls'].length - 1"
                                        class="exception-button " color="primary" mat-mini-fab type="button"
                                        (click)="addNext()">
                                        <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
                                    </button>
                                    <button class="exception-button " color="primary" mat-mini-fab type="button"
                                        (click)="removeFlow(i)"
                                        *ngIf="model.get('flowSequence')['controls'].length > 0 && i == model.get('flowSequence')['controls'].length - 1">
                                        <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!readonly" class="row">
                            <div class="col-12">
                                <mat-form-field appearance="outline"
                                    *ngIf="destinationType != destinationTypeEncaminhamento && destinationType != destinationTypeManter">
                                    <mat-label>Conclusão</mat-label>
                                    <textarea matInput formControlName="conclusionNote"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row"
                            *ngIf="destinationType == destinationTypeInternacao || destinationType == destinationTypeInternacaoEncaminhamento">
                            <mat-radio-group aria-label="" formControlName="idFlow">
                                <mat-radio-button [value]="item.idFlow"
                                    *ngFor="let item of listFoward">{{item.locationName}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="row">
                            <div class="col-md-3 col-sm-3"></div>
                            <div *ngIf="readonly" class="col-md-3 col-sm-3"></div>
                            <div class="col-md-3 col-sm-3">
                                <a mat-flat-button type="button" (click)="openCancelConfirmModal()" color="accent"
                                    class=" btn-block">
                                    <span *ngIf="!isLoadingCancelar">Cancelar</span>
                                    <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                        *ngIf="isLoadingCancelar"></mat-spinner>
                                </a>
                            </div>
                            <div *ngIf="!readonly" class="col-md-3 col-sm-3">
                                <button mat-flat-button (click)="submit(true)"
                                    color="{{requireSignature ? 'primary' : 'accent'}}" class="btn-block">
                                    <span *ngIf="!isLoading">Salvar com assinatura e Imprimir</span>
                                    <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                        *ngIf="isLoading"></mat-spinner>
                                </button>
                            </div>
                            <div *ngIf="!readonly && !requireSignature" class="col-md-3 col-sm-3">
                                <button mat-flat-button (click)="submit(false)"
                                    color="{{requireSignature ? 'accent' : 'primary'}}" class="btn-block">
                                    <span *ngIf="!isLoading">Salvar sem assinatura e Imprimir</span>
                                    <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                        *ngIf="isLoading"></mat-spinner>
                                </button>
                            </div>
                        </div>
                    </form>
                    <div *ngIf="medicalCare != null  && medicalCare.length > 0 ">
                        <div class="title-header">
                            <h1>Histórico de Atendimentos</h1>
                        </div>
                        <div class="row">
                            <div class="col=12">
                                <cdk-accordion class="profile-register-accordion">
                                    <app-medical-care-accordion [medicalCare]="medicalCare"
                                        [lookupMedicalCareResponse]="lookupMedicalCareResponse"
                                        [listMedicine]="listMedicine" [listExam]="listExamType">
                                    </app-medical-care-accordion>
                                </cdk-accordion>
                            </div>
                        </div>
                    </div>
                    <div class="title-header">
                        <h1>Evolução do paciente</h1>
                    </div>
                    <cdk-accordion-item *ngFor="let item of listNursingConsultation; let index = index;"
                        #accordionItemModule="cdkAccordionItem" class="profile-register-accordion-item" role="button"
                        tabindex="0" [attr.id]="'accordion-header-' + index"
                        [attr.aria-expanded]="accordionItemModule.expanded"
                        [attr.aria-controls]="'accordion-body-' + index">
                        <div [ngClass]="{'profile-register-accordion-item-header-expanded': accordionItemModule.expanded}"
                            class="profile-register-accordion-item-header" (click)="accordionItemModule.toggle()">
                            Evolução multiprofissional - ({{ item.professionName }}, {{ item.userName }} - {{
                            item.datetimeInclusion | date:'dd/MM/yy, H:mm' }})
                            <span
                                [ngClass]="{'profile-register-accordion-item-description-expanded': accordionItemModule.expanded}"
                                class="profile-register-accordion-item-description">
                                Clique para {{ accordionItemModule.expanded ? 'fechar' : 'abrir' }}
                            </span>
                        </div>
                        <div class="profile-register-accordion-item-body" role="region"
                            [style.display]="accordionItemModule.expanded ? '' : 'none'"
                            [attr.id]="'accordion-body-' + index" [attr.aria-labelledby]="'accordion-header-' + index">
                            <app-medic-nursing-consultation-accordion [listAllergyEpisode]="allergies"
                                [nursingConsultation]="item" [medicalCare]="medicalCare" [idEpisode]="selectedEpisode"
                                [patientName]="episodeStruct.patientName" [birthDate]="episodeStruct.birthDate">
                            </app-medic-nursing-consultation-accordion>
                        </div>
                    </cdk-accordion-item>

                    <div *ngIf="triage != null">
                        <div class="title-header title-margin">
                            <h1>Classificação de Risco</h1>
                        </div>
                        <app-triage-resume [triage]="triage"></app-triage-resume>
                    </div>
                    <div *ngIf="episodeHistorie && episodeHistorie.length >0">
                        <div class="title-header title-margin">
                            <h1>Histórico de Episódio</h1>
                        </div>
                        <table class="basic-table desktop">
                            <thead class="basic-table__head">
                                <tr>
                                    <th>Unidade</th>
                                    <th>Médicos</th>
                                    <th>CID</th>
                                    <th>Gravidade</th>
                                    <th>Data</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody class="basic-table__body">
                                <tr class="basic-table__body__row"
                                    *ngFor="let episode of episodeHistorie; let index = index;">
                                    <td class="truncate"><span>{{episode.healthUnitName}}</span></td>
                                    <td class="truncate"><span>{{episode.userMedicalCare.join(", ")}}</span></td>
                                    <td class="truncate"><span>{{episode.cid.join(", ")}}</span></td>
                                    <td class="truncate">
                                        <div class="status-caller"
                                            [ngStyle]="{'background-color': episode.gravityColor}">
                                            <span class="text-bold">{{episode.gravityName}}</span>
                                        </div>
                                    </td>
                                    <td class="truncate"><span>{{episode.datetimeInclusion |
                                            date:'dd/MM/yyyy'}}</span></td>

                                    <td>
                                        <div class="basic-table__body__row__action">
                                            <mat-icon matTooltip="Visualizar"
                                                (click)="openEpisodeModal(episode.idEpisode)">
                                                visibility</mat-icon>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <cdk-accordion class="accordion mobile">
                            <cdk-accordion-item *ngFor="let episode of episodeHistorie; let index = index;"
                                #accordionItem="cdkAccordionItem" class="accordion-item" role="button" tabindex="0"
                                [attr.id]="'accordion-header-' + index" [attr.aria-expanded]="accordionItem.expanded"
                                [attr.aria-controls]="'accordion-body-' + index">
                                <div [ngClass]="{'accordion-item-header-expanded': accordionItem.expanded}"
                                    class="accordion-item-header" (click)="accordionItem.toggle()">
                                    <div class="flex-space-between">
                                        <span class="truncate bold padding-right-0">Unidade</span>
                                        <span class="truncate">{{episode.healthUnitName}}</span>
                                    </div>
                                    <div class="flex-space-between">
                                        <span class="truncate bold padding-right-0">Gravidade</span>
                                        <div class="status-caller"
                                            [ngStyle]="{'background-color': episode.gravityColor}">
                                            <span class="text-bold">{{episode.gravityName}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item-body" role="region"
                                    [style.display]="accordionItem.expanded ? '' : 'none'"
                                    [attr.id]="'accordion-body-' + index"
                                    [attr.aria-labelledby]="'accordion-header-' + index">
                                    <div class="feature-content">
                                        <div class="padding-x">

                                            <div class="flex-space-between padding-y"><span
                                                    class="truncate bold padding-right-0">Médico:</span> <span
                                                    class="truncate">{{episode.userMedicalCare.join(", ")}}</span></div>
                                            <div class="flex-space-between padding-y"><span
                                                    class="truncate bold padding-right-0">CID:</span> <span
                                                    class="truncate">{{episode.cid.join(", ")}}</span></div>
                                            <div class="flex-space-between padding-y"><span
                                                    class="truncate bold padding-right-0">Data:</span> <span
                                                    class="truncate">{{episode.datetimeInclusion |
                                                    date:'dd/MM/yyyy'}}</span></div>
                                        </div>
                                        <div class="basic-table__body__row__action">
                                            <mat-icon matTooltip="Visualizar"
                                                (click)="openEpisodeModal(episode.idEpisode)">
                                                visibility
                                            </mat-icon>
                                        </div>
                                    </div>
                                </div>
                            </cdk-accordion-item>
                        </cdk-accordion>
                    </div>

                    <ng-container *ngIf="lastSelfTriage">
                        <div class="title-header title-margin">
                            <h1>Última Autotriagem</h1>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-3">
                                <p><b>Data:</b> {{lastSelfTriage.patientFlowchart.datetimeInclusion |
                                    date:'dd/MM/yyyy'}}</p>
                                <p><b>Fluxograma:</b> {{lastSelfTriage.patientFlowchart.flowchart}}</p>
                                <p><b>Aconselhamento:</b> {{lastSelfTriage.patientFlowchart.counseling}}</p>
                            </div>
                            <div class="col-12 col-sm-6 col-md-3">
                                <button mat-flat-button type="button" color="primary" class=" btn-primary"
                                    (click)="showSelfTtriageResume()">
                                    <span>Ver Resumo</span>
                                </button>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="lastTelephoneTriage">
                        <div class="title-header title-margin">
                            <h1>Última Classificação Telefônica</h1>
                        </div>
                        <app-triage-resume [triage]="lastTelephoneTriage"
                            [isTelephonceClassification]="true"></app-triage-resume>
                    </ng-container>

                </div>
            </div>
        </div>
        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>
</div>

<div *ngIf="showTissGuideButtonValidation">
    <app-tiss-guide-management-floating-window 
        [idEpisode]="selectedEpisode" [isAttendance]="true"
        [idRoom]="this.idRoom" [idSector]="this.idSector"
        [idPatient]="this.idPatient">
    </app-tiss-guide-management-floating-window>
</div>