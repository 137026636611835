<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <h1>Medicamento</h1>
        <div class="white-body">
            <a mat-flat-button color="primary" class=" add-user" routerLink="/pharmacy/medicine/register"><mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon> Cadastrar Medicamento</a>
            <div class="filters">
                <div class="row">   
                    <div class="col-12 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Pesquisar</mat-label>
                            <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText" (keyup)="onKeySearch($event)">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Tipo de Medicamento</mat-label>
                            <mat-select (selectionChange)="search()" [multiple]="true" [(ngModel)]="listIdType">
                                <mat-option *ngFor="let item of listTypeStruct" value="{{item.idMedicineType}}">
                                    {{item.medicineTypeName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Grupo de Medicamento</mat-label>
                            <mat-select (selectionChange)="search()" [multiple]="true" [(ngModel)]="listIdGroup">
                                <mat-option *ngFor="let item of listGroupStruct" value="{{item.medicineGroup.idMedicineGroup}}">
                                    {{item.medicineGroup.medicineGroupName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Subgrupo de Medicamento</mat-label>
                            <mat-select (selectionChange)="search()" [multiple]="true" [(ngModel)]="listIdSubgroup">
                                <mat-option *ngFor="let item of listSubgroupStruct" value="{{item.medicineSubgroup.idMedicineSubgroup}}">
                                    {{item.medicineSubgroup.medicineSubgroupName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4"> 
                        <mat-form-field appearance="outline">
                          <mat-label>Status</mat-label>
                          <mat-select [(ngModel)]="isActive" (selectionChange)="search()">
                            <mat-option value="">Todos</mat-option> 
                            <mat-option value="true">Ativo</mat-option>
                            <mat-option value="false">Inativo</mat-option>
                          </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="card-list" *ngIf="listMedicineStruct != null">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listMedicineStruct">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary">{{item.medicine.medicineDescription}}</span>  
                                <span matTooltip="Grupo do Medicamento" class="secundary parent-name" *ngIf="item.medicine.idMedicineGroup">{{item.medicineGroupName}}</span>
                                <span matTooltip="Subgrupo do Medicamento" class="secundary parent-name" *ngIf="item.medicine.idMedicineSubgroup">{{item.medicineSubgroupName}}</span>
                                <span class="secundary-status last-info">{{item.medicine.isActive? "Ativo":"Inativo"}}</span>
                            </div>
                            <div class="actions">
                                <a><mat-icon aria-hidden="false" aria-label="Editar" [routerLink] = "['/pharmacy/medicine/register', {idMedicine: item.medicine.idMedicine}]">edit</mat-icon> </a>
                                <a><mat-icon aria-hidden="false" aria-label="Editar" (click)="openModal(item.medicine.idMedicine)">delete_forever</mat-icon> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <app-empty-list *ngIf="listMedicineStruct != undefined && listMedicineStruct.length == 0"></app-empty-list>
        </div>
    
        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>
    
</div>