import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { Gender } from 'src/app/shared/services/responses/medical-record/lookup.response';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MedicalRecordLookupService } from 'src/app/shared/services/API/medical-record/lookup.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { MatDialog } from '@angular/material/dialog';
import { ReportService } from 'src/app/shared/services/API/orchestrator-patient/report.service';
import { PriorityModel } from 'src/app/shared/services/models/totem/priority.model';
import { PatientService } from 'src/app/shared/services/API/medical-record/patient.service';
import { PatientStruct } from 'src/app/shared/services/structs/medical-record/patient.struct';
import { FowardService } from 'src/app/shared/services/API/orchestrator-queue/foward.service';
import { PriorityService } from 'src/app/shared/services/API/totem/priority.service';
import { PatientPatchRequest } from 'src/app/shared/services/requests/medical-record/patient-patch.request';
import { ClassificationData, Reclassification, UtilsClassificationService } from 'src/app/classification/utils.service';
import { GetPatientByCpfService } from 'src/app/shared/services/API/orchestrator-patient/get-patient-by-cpf.service';
import { EpisodeService } from 'src/app/shared/services/API/orchestrator-patient/episode.service';
import { WithoutDeviceComponent } from './without-device-modal/without-device.component';
import { PatientDeviceStruct } from 'src/app/shared/services/structs/sync-measurer/patient-device.struct';
import { SearchRemoteModalComponent } from './search-remote-modal/search-remote-modal.component';
import { PatientDeviceService } from 'src/app/shared/services/API/sync-measurer/patient-device.service';
import { DeviceOfflineModalComponent } from './device-offline-modal/device-offline-modal.component';
import { UtilService } from 'src/app/shared/services/util.service';
import { PrioritiesStatusEnum } from 'src/app/shared/enum/priorities-status.enum';

@Component({
  selector: 'app-pre-register-remote',
  templateUrl: './pre-register-remote.component.html',
  styleUrls: ['./pre-register-remote.component.css']
})
export class PreRegisterRemoteComponent implements OnInit {

  constructor(
    //@Inject(MAT_DIALOG_DATA) public data: any, 
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private patientService: PatientService,
    private getPatientByCpfService: GetPatientByCpfService,
    private episodeService: EpisodeService,
    private priorityService: PriorityService,
    public utilService: UtilService,
    private lookupService: MedicalRecordLookupService,
    private maskService: MaskService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public fowardService: FowardService,
    public reportService: ReportService,
    public router: Router,
    public utilClassification: UtilsClassificationService,
    private patientDeviceService: PatientDeviceService) { }

  @Input() idEpisode: number;
  @Input() idRoom: number;
  @Input() idSector: number;
  @Input() idDeviceType: number;
  @Input() reclassification: boolean;
  @Input() isFirstLoading: boolean;

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.classification;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.classification_pre_registration;
  public model: UntypedFormGroup;
  public isLoading: boolean;
  public genderList: Gender[];
  public masks: Masks;
  public idPatient: number = null;
  public listOfPriorities: PriorityModel[];
  public newPatient: boolean = false;
  public device: PatientDeviceStruct;
  public isDeviceOnline: boolean = true;

  private reclassificationData: Reclassification;
  
  public disallowedIdPriorities: number[] = [];

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.isLoading = false;
    this.model = this.formBuilder.group({
      patientName: ['', [Validators.required]],
      socialName: [''],
      cpf: ['', [Validators.required]],
      birthDate: ['', [Validators.required]],
      idGender: [''],
      cns: [''],
      listIdPriority: [''],
      unidentifiedPatientDescription: [''],
      ageGroup: [''],
      patientAge: [{ value: '', disabled: true }],
      unidentifiedPatient: [false]
    });

    this.getAllPriorities();
    this.populateLookupSelect();

    if (this.idEpisode != null)
      this.populatePatientData(this.idEpisode);
    else {
      this.newPatient = true;
      this.openModalSearchByCPF();
    }
  }

  submit() {
    if (this.model.invalid) {
      this.alertService.show('Erro', 'Você precisa informar todos os campos obrigatórios', AlertType.error);
      return;
    }

    if (!this.device) {
      this.openModalConfirm();
      return;
    }
    if (!this.isDeviceOnline) {
      this.getDeviceIsOnline(this.device.idDevice);
      return;
    }
    let patient = new PatientPatchRequest();
    if (Number.isInteger(this.idPatient))
      patient.idPatient = this.idPatient;

    if (Number.isInteger(this.idEpisode))
      patient.idEpisode = this.idEpisode;

    patient.patientName = this.model.get('patientName').value;
    patient.socialName = this.model.get('socialName').value;
    patient.cpf = this.model.get('cpf').value.replace(/[^0-9]+/g, '');
    patient.birthDate = this.maskService.formatStringToDate(this.model.get('birthDate').value);
    patient.idGender = this.model.get('idGender').value;
    patient.cns = this.model.get('cns').value;
    patient.priorities = this.model.get('listIdPriority').value;

    for (let item of Object.keys(patient)) {
      patient[item] = patient[item] ? patient[item] : null;
    }
    this.isLoading = true;
    this.patientService.updatePatient(patient).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        let data: ClassificationData = new ClassificationData();
        data.idEpisode = response.idEpisode;
        data.idPatient = response.idPatient;
        data.datetimeStartTriage = response.datetimeStartTriage;
        data.idGender = patient.idGender;
        data.birthDate = patient.birthDate;
        data.newPatient = this.newPatient;
        let ageObject = this.utilService.getAge(this.maskService.formatDateToString(patient.birthDate));
        data.ageYears = ageObject.years;
        data.ageMonth = ageObject.months;
        data.ageDay = ageObject.days;
        data.device = this.device;
        data.idDeviceType = this.idDeviceType;
        data.reclassification = this.reclassificationData;
        data.idSector = parseInt(this.activatedRoute.snapshot.paramMap.get('idSector'));
        data.idRoom = parseInt(this.activatedRoute.snapshot.paramMap.get('idRoom'));
        this.utilClassification.updateClassificationData(data);
        if (!this.reclassification)
          this.router.navigate(['/classification/protocol-select']);
        else
          this.router.navigate(['/classification/flowchart-select']);
      }
    });
  }

  calculateAgeGroup(ageGroup: number): Date {
    let todate = new Date();
    if (ageGroup == 1) {
      todate.setDate(todate.getDate() - 10);
      return todate;
    } else if (ageGroup == 2) {
      todate.setDate(todate.getDate() - 100);
      return todate;
    } else if (ageGroup == 3) {
      todate.setFullYear(todate.getFullYear() - 10);
      return todate;
    } else if (ageGroup == 4) {
      todate.setFullYear(todate.getFullYear() - 18);
      return todate;
    }
  }

  populatePatientData(idEpisode: number) {
    this.episodeService.getPatientByEpisode(idEpisode).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        if (this.reclassification && response.episodeStruct.listTriage && response.episodeStruct.listTriage.length > 0) {
          this.reclassificationData = new Reclassification();
          this.reclassificationData.flowchart = response.episodeStruct.listTriage[0].flowchart;
          this.reclassificationData.idGravity = response.episodeStruct.listTriage[0].idGravity;
          this.reclassificationData.idFlowchart = response.episodeStruct.listTriage[0].idFlowchart;
          this.reclassificationData.score = response.episodeStruct.listTriage[0].score != null;
        }

        if (!response.patientDeviceStruct) {
          this.device = null;
          this.openModalConfirm();
          return;
        } else if (response.patient != null && response.patient.idPatient != null) {
          if (!response.episodeStruct.isUnidentifiedPatient)
            this.mapModel(response.patient);
          else {
            this.alertService.show('Erro', "Para o módulo de classificação remota não pode ser um paciente não identificado!", AlertType.error);
            this.openModalSearchByCPF();
          }
        } else
          this.openModalSearchByCPF();

        this.device = response.patientDeviceStruct;
        this.getDeviceIsOnline(response.patientDeviceStruct.idDevice);
        if (response.priorities != null && response.priorities.length > 0)
          this.model.get('listIdPriority').setValue(response.priorities.map(c => c.toString()));
      }
    });
  }

  mapModel(patient: PatientStruct) {
    this.idPatient = patient.idPatient;
    this.model.get('patientName').setValue(patient.patientName);
    this.model.get('socialName').setValue(patient.socialName);
    this.model.get('cpf').setValue(patient.cpf);
    let birthDate = this.maskService.formatDateToString(patient.birthDate, false);
    this.model.get('birthDate').setValue(birthDate);
    this.utilService.getAge(birthDate);
    this.model.get('idGender').setValue(patient.idGender ? patient.idGender.toString() : null);
    this.model.get('cns').setValue(patient.cns);
  }

  getDeviceIsOnline(idSyncMeasurer: number) {
    this.isLoading = true;
    this.patientDeviceService.isOnline(idSyncMeasurer).subscribe({
      next: (response) => {
        this.isLoading = false;
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        if (!response.isDeviceOnline) {
          this.isDeviceOnline = false;
          let dialogRef = this.dialog.open(DeviceOfflineModalComponent);
          dialogRef.afterClosed().subscribe({
            next: (response) => {
              if (response)
                this.openModalSearchByCPF();
              else
                this.getDeviceIsOnline(idSyncMeasurer);
            }
          });
        } else
          this.isDeviceOnline = true;
      }
    });
  }

  populateLookupSelect() {
    this.lookupService.getLookup().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.genderList = response.listGenders;
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getAllPriorities() {
    this.priorityService.listPriority().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listOfPriorities = response.listPriority;
      }
    });
  }

  searchByCPF(cpf: string) {
    this.isLoading = true;
    this.getPatientByCpfService.getPatientByCPF(cpf).subscribe({
      next: (response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        if (!response.patientDeviceStruct) {
          this.device = null;
          this.openModalConfirm();
          return;
        }
        this.device = response.patientDeviceStruct;
        this.getDeviceIsOnline(response.patientDeviceStruct.idDevice);
        this.mapModel(response.patient);
        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  private timeoutKeySearch: any = null;

  onKeySearchCPF(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    let cpf = event.target.value.replace(/[^0-9]+/g, '');
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13 && cpf.length == 11)
        $this.searchByCPF(cpf);
    }, 1000);
  }

  openModalSearchByCPF() {
    const dialogRef = this.dialog.open(SearchRemoteModalComponent);
    dialogRef.afterClosed().subscribe({
      next: (response) => {
        if (response) {
          if (!response.patientDeviceStruct) {
            this.openModalConfirm();
            return;
          }

          this.mapModel(response.patient);
          this.device = response.patientDeviceStruct;
          this.getDeviceIsOnline(response.patientDeviceStruct.idDevice);
        }
      }
    });
    return dialogRef;
  }

  openModalConfirm() {
    const dialogRef = this.dialog.open(WithoutDeviceComponent);
    dialogRef.afterClosed().subscribe({
      next: (response) => {
        if (response && response.value == 1)
          this.router.navigate(['/classification/classification-patient-list']);
        else
          this.openModalSearchByCPF();
      }
    });
  }

  onKeySearchAge(event: any) {
    if (event.target.value.length == 10)
      this.utilService.getAge(event.target.value);
  }

  validatePriorities(priorities: any) {
    let listIdPriority = priorities.value;

    if (listIdPriority.includes(PrioritiesStatusEnum.MaiorDe80Anos.valueOf()) ||
      listIdPriority.includes(PrioritiesStatusEnum.MaiorDe80Anos.valueOf().toString()) ||
      listIdPriority.includes(PrioritiesStatusEnum.MaiorDe60Anos.valueOf()) ||
      listIdPriority.includes(PrioritiesStatusEnum.MaiorDe60Anos.valueOf().toString())) {

      this.disallowedIdPriorities = [PrioritiesStatusEnum.MenorDe1Ano.valueOf()];
      
      this.model.get('listIdPriority').setValue(listIdPriority.filter(x => !this.disallowedIdPriorities.includes(x.idPriority)));
    }

    else if (listIdPriority.includes(PrioritiesStatusEnum.MenorDe1Ano.valueOf()) ||
      listIdPriority.includes(PrioritiesStatusEnum.MenorDe1Ano.valueOf().toString())) {

      this.disallowedIdPriorities = [
        PrioritiesStatusEnum.MaiorDe60Anos.valueOf(),
        PrioritiesStatusEnum.MaiorDe80Anos.valueOf()];

      this.model.get('listIdPriority').setValue(listIdPriority.filter(x => !this.disallowedIdPriorities.includes(x.idPriority)));
    }

    else {
      this.disallowedIdPriorities = [];
    }
  }
}