<div mat-dialog-content>
    <div class="mat-dialog-content">

        <div class="head-modal">
            <div class="img-head">
                <img style="width: 5rem;" src="assets/images/exclamation-circle.png" />
            </div>
            <div class="title-modal">
                <h1>Atenção!</h1>
            </div>
         </div>
         
         <div class="body-modal">
            <p>
                Em cumprimento à <b>portaria SMS-SP 82/2024</b>, é <b>pré-requisito</b> que o 
                enfermeiro possua a <b>capacitação SMCR 2ª edição</b>, para acesso a este 
                sistema. O enfermeiro que será cadastrado possui a capacitação exigida?
            </p> 
            <mat-radio-group [(ngModel)]="certifiedManchester">
                <mat-radio-button [value]="true"><b>Sim</b>, conferi e o enfermeiro possui a capacitação <b>SMCR 2ª edição</b></mat-radio-button>
                <mat-radio-button [value]="false"><b>Não</b>, o enfermeiro ainda não possui a capacitação <b>SMCR 2ª edição</b></mat-radio-button>
            </mat-radio-group>

         </div>

         <div class="footer-modal">
            <button mat-flat-button color="primary" class="btn-block" (click)="clickConfirm()" [disabled]="!(certifiedManchester != null && certifiedManchester != undefined)">
                <span *ngIf="isLoading == false">Continuar</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
        </div>
    </div>
</div>
