<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <h1>Templates</h1>
        <div class="white-body">
            <a mat-flat-button color="primary" class=" add-user" routerLink="/master/text-template/register"><mat-icon
                    aria-hidden="false" aria-label="Plus">add</mat-icon> Cadastrar template</a>
            <div class="filters">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Pesquisar</mat-label>
                            <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText"
                                (keyup)="onKeySearch($event)">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="card-list" *ngIf="listTextTemplate != null">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listTextTemplate">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary">{{item.textTemplateName}}</span>
                            </div>
                            <div class="actions">
                                <a><mat-icon aria-hidden="false" aria-label="Editar"
                                        [routerLink]="['/master/text-template/register', {idTextTemplate: item.idTextTemplate}]">edit</mat-icon>
                                </a>
                                <a><mat-icon aria-hidden="false" aria-label="Editar"
                                        (click)="openModal(item.idTextTemplate)">delete_forever</mat-icon> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <app-empty-list
                *ngIf="listTextTemplate != undefined && listTextTemplate.length == 0"></app-empty-list>
        </div>

        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>

</div>