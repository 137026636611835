import { Component, EventEmitter, OnInit, Output, Input} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UtilsClientService } from 'src/app/client/utils.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-pin-bi-panel',
  templateUrl: './pin-bi-panel.component.html',
  styleUrls: ['./pin-bi-panel.component.css']
})
export class PinBiPanelComponent implements OnInit {

  @Input() isLoading: boolean;
  @Input() idBiPanel: number;
  @Input() panelIndex: number;

  @Output() onAuthenticated: EventEmitter<string> = new EventEmitter<string>();

  constructor(private formBuilder: UntypedFormBuilder,
    private utilService: UtilsClientService,
    private alertService: AlertService,
  ) { }

  public model: UntypedFormGroup;
  public savePin: boolean = false;
  
  ngOnInit(): void {
    this.model = this.formBuilder.group({
      pin: ['', [Validators.required]],
    });
    this.verifyLogin();
  }

  submit(){
    try{
      if(this.model.invalid || this.isLoading == true)
      return;

      let pin = this.model.get('pin').value;

      if(this.savePin){
        this.utilService.updateBiPanelPin(pin.toString(), this.idBiPanel);
      }
      else{
        this.utilService.updateSessionBiPanelPin(pin.toString());
      }

      this.onAuthenticated.emit(pin);    
    }
    catch(error){
      console.log(error)  
      this.alertService.show('Erro inesperado', "Erro verificar pin", AlertType.error);   
      this.onAuthenticated.emit(null);
    }
  }

  verifyLogin(){
    try{
      let pin;
      if(this.utilService.getBiPanelPin(this.idBiPanel)) 
        pin = this.utilService.getBiPanelPin(this.idBiPanel);
      else if(this.utilService.getSessionBiPanelPin())
        pin = this.utilService.getSessionBiPanelPin();
      
      if(pin) this.onAuthenticated.emit(pin);
    }
    catch(error){
      console.log(error)
      this.alertService.show('Erro inesperado', "Erro verificar pin", AlertType.error);
      this.onAuthenticated.emit(null);
    }
  }
}
