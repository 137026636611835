<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <a class="back-link" routerLink="/bi/bi-panel"><mat-icon aria-hidden="false"
                aria-label="Plus">arrow_back</mat-icon> Painéis</a>
        <h1>Cadastrar Painel</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="title-header">
                    <h1>Dados do painel</h1>
                </div>

                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome</mat-label>
                            <input matInput type="text" formControlName="biPanelName">
                            <mat-error *ngIf="model.get('biPanelName').invalid">Informe o Nome</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Descrição</mat-label>
                            <input matInput type="text" formControlName="description">
                        </mat-form-field>
                    </div>
                </div>
                <div class="title-header">
                    <h1>Relatórios</h1>
                </div>
                <div formArrayName="listReport" *ngFor="let item of model.get('listReport')['controls']; let i = index">
                    <div class="row" [formGroupName]="i">
                        <div class="col-4 col-sm-4 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Relatório</mat-label>
                                <mat-select formControlName="idReportFunctionHealthUnit">
                                    <mat-option *ngFor="let item of listReport"
                                        value="{{item.idReportFunctionHealthUnit}}">{{item.healthUnitName}} -
                                        {{item.reportName}}</mat-option>
                                </mat-select>
                                <mat-error *ngIf="item.get('idReportFunctionHealthUnit').invalid">Informe o
                                    relatório</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-4 col-sm-4 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Tempo de Visualização</mat-label>
                                <mat-select formControlName="idVisualizationTime">
                                    <mat-option *ngFor="let item of listVisualizationTime"
                                        value="{{item.idVisualizationTime}}">{{item.visualizationTime}}
                                        segundos</mat-option>
                                </mat-select>
                                <mat-error *ngIf="item.get('idVisualizationTime').invalid">Informe o tempo</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-2 col-sm-2 col-md-3">
                            <button class="panel-button " color="primary" mat-mini-fab type="button"
                                (click)="addNext()">
                                <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
                            </button>
                            <button class="panel-button " color="primary" mat-mini-fab type="button" (click)="remove(i)"
                                *ngIf="i != 0">
                                <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <a mat-flat-button type="button" routerLink="/bi/bi-panel" color="accent" class=" btn-block">
                            Cancelar
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>