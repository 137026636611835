import { FormGroup, FormControl, ValidatorFn, ValidationErrors, AbstractControl, FormArray } from "@angular/forms";

// To validate password and confirm password
export function DateAfterTodayValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const today = new Date().getDate();

    if(!(control && control.value)) {
      // if there's no control or no value, that's ok
      return null;
    }

    // return null if there's no errors
    return control.value.getDate() < today
      ? {invalidDate: 'Data final não pode ser anterior à data de hoje' }
      : null;
  }
}

export function DateStringNotBeforeToday(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control || !control.value) {
      return null; // Valor vazio, não precisa validar
    }

    let dateString = control.value.toString().replaceAll("/", "");
    let darr = dateString.split("");//["2", "9", "0", "1", "2", "0", "1", "6"]
    let selectedDate = new Date(parseInt(darr[4] + darr[5] + darr[6] + darr[7]), parseInt(darr[2] + darr[3]) - 1, parseInt(darr[0] + darr[1]));
    selectedDate.setHours(0, 0, 0, 0);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (selectedDate < today) {
      return { 'dateBeforeToday': true };
    }

    return null;
  };
}

export function DateValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    if (!control || !control.value) {
      return null; // Valor vazio, não precisa validar
    }
    const value = control.value.toString().replaceAll("/", "");
  
    // Verifica se o valor é uma string no formato DD/MM/YYYY
    const DATE_REGEXP = /^\d{2}\d{2}\d{4}$/;
    
    if (!DATE_REGEXP.test(value)) {
      return { 'invalidFormat': true }; // Formato inválido
    }
  
    // Verifica se a data é válida
    const day = parseInt(value.substring(0, 2), 10);
    const month = parseInt(value.substring(2, 4), 10) - 1; // Mês é base zero
    const year = parseInt(value.substring(4), 10);
    const date = new Date(year, month, day);
    if (
      date.getDate() !== day ||
      date.getMonth() !== month ||
      date.getFullYear() !== year ||
      (date.getFullYear() === year && date.getFullYear() < 1800)
    ) {
      return { 'invalidDate': true }; // Data inválida
    }
  
    return null; // Data válida
  }
}