import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ListScheduleResponse } from '../../responses/schedule/list-schedule.response';

@Injectable({
  providedIn: 'root'
})
export class GetScheduleService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listSchedule(): Observable<ListScheduleResponse> {

    let uri = `GetSchedule`;

    return this.httpClient.get<ListScheduleResponse>(environment.urlApiOrchestratorSchedule + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}