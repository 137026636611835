<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    <div class="container-body">
        <div class="white-body" [ngClass]="{'white-body-mobile': isMobile}">
            <section *ngIf="!isCallError" class="head-lean-panel">
                <section class="head-data" [ngClass]="{'head-data-mobile': isMobile}">
                    <h1>Lean</h1>
                    <ngx-skeleton-loader *ngIf="isFirstLoading && !isMobile"
                        animation="progress-dark"
                        [theme]="{ 
                            width: '190px', 
                            height: '40px',
                            'margin-bottom': '0px' }">
                    </ngx-skeleton-loader>
                    <ngx-skeleton-loader *ngIf="isFirstLoading && !isMobile"
                        animation="progress-dark"
                        [theme]="{ 
                            width: '190px', 
                            height: '40px',
                            'margin-bottom': '0px' }">
                    </ngx-skeleton-loader>
                    <ngx-skeleton-loader *ngIf="isFirstLoading && !isMobile"
                        animation="progress-dark"
                        [theme]="{ 
                            width: '190px', 
                            height: '40px',
                            'margin-bottom': '0px' }">
                    </ngx-skeleton-loader>
                    <ngx-skeleton-loader *ngIf="isFirstLoading && !isMobile"
                        animation="progress-dark"
                        [theme]="{ 
                            width: '190px', 
                            height: '40px',
                            'margin-bottom': '0px' }">
                    </ngx-skeleton-loader>
                    <ngx-skeleton-loader *ngIf="isFirstLoading && !isMobile"
                        animation="progress-dark"
                        [theme]="{ 
                            width: '190px', 
                            height: '40px',
                            'margin-bottom': '0px' }">
                    </ngx-skeleton-loader>
                    <ngx-skeleton-loader *ngIf="isFirstLoading && isMobile"
                            count="5"
                            animation="progress-dark"
                            [theme]="{ 
                                width: '100%', 
                                height: '45px',
                                'margin': '10px 0 0 0' }">
                    </ngx-skeleton-loader>
                    <div *ngIf="!isFirstLoading" class="data-card total-patients" matTooltip="Todos os pacientes que estão na Unidade agora.">
                        <mat-icon class="lean-icon"><img src="/assets/icons/lean-panel/paciente.png" aria-hidden="false" aria-label="vazio"></mat-icon>
                        <span class="data">{{this.globalDataStruct.totalPatients}}</span>
                        <span>Pacientes</span>
                    </div>
                    <div *ngIf="!isFirstLoading" class="data-card percentage-evasion" matTooltip="Episódios iniciados hoje e que foram evadidos.">
                        <mat-icon class="lean-icon"><img src="/assets/icons/lean-panel/evasao.png" aria-hidden="false" aria-label="evasão"></mat-icon>
                        <span class="data">{{this.globalDataStruct.dropoutRate}}</span>
                        <span>Evasões</span>
    
                    </div>
                    <div *ngIf="!isFirstLoading" class="data-card journey-time" matTooltip="Tempo médio de duração desde a entrada do paciente até o desfecho assistencial.">
                        <mat-icon class="lean-icon"><img src="/assets/icons/lean-panel/journey.png" aria-hidden="false" aria-label="vazio"></mat-icon>
                        <span class="data">{{this.globalDataStruct.journeyTime}}</span>
                        <span>Jornada</span>
                    </div>
                    <div *ngIf="!isFirstLoading" class="data-card" matTooltip="% de ocupação de leitos neste momento, considerando apenas leitos ativos.">
                        <mat-icon class="lean-icon"><img src="/assets/icons/lean-panel/internacao_porcentagem.png" aria-hidden="false" aria-label="vazio"></mat-icon>
                        <span class="data">{{this.leanOutputFlowStruct.hospitalizationOutputStruct.currentUnitBedOccupancyRate}}</span>
                        <span>Ocupação</span>
                    </div>
                    <div *ngIf="!isFirstLoading" class="data-card" matTooltipClass="tooltip-sonet"
                        [matTooltip]="this.tooltipSonet">
                        <mat-icon class="lean-icon"><img src="/assets/icons/lean-panel/sonet.png" aria-hidden="false" aria-label="vazio"></mat-icon>
                        <span class="data"
                            [ngClass]="{'sonet-normal': this.globalDataStruct.sonet <= 100, 
                            'sonet-alert': this.globalDataStruct.sonet > 100 && this.globalDataStruct.sonet <= 140,
                            'sonet-critic': this.globalDataStruct.sonet > 140}">{{this.globalDataStruct.sonet}}</span>
                        <span>Sonet</span>
                    </div>
                </section>
                
            </section>
            <section *ngIf="!isCallError" class="flow-lean-panel" [ngClass]="{'slider-container': isMobile}">
                <div class="flow-content margin-right" [ngClass]="{'slide': isMobile, 'col-4': !isMobile}">
                    <div class="flow-chart">
                        <ngx-skeleton-loader *ngIf="isFirstLoading"
                            animation="progress-dark"
                            [theme]="{ 
                                width: '190px', 
                                height: '40px',
                                'margin-bottom': '0px' }">
                        </ngx-skeleton-loader>
                        <div *ngIf="!isFirstLoading" class="data-card">
                            <h1>Entrada</h1>
                        </div>
                        <div class="flow-value">
                            <div class="flow-cards-content">
                                <ngx-skeleton-loader *ngIf="isFirstLoading"
                                    animation="progress-dark"
                                    [theme]="{ 
                                        width: '60px', 
                                        height: '70px',
                                        'margin': '1px',
                                        position: 'relative',   
                                        'top': '-23px',
                                        'left': '-2px',
                                        'z-index': '1', }">
                                </ngx-skeleton-loader>
                                <ngx-skeleton-loader *ngIf="isFirstLoading"
                                    animation="progress-dark"
                                    [theme]="{ 
                                        width: '60px', 
                                        height: '70px',
                                        'margin': '1px',
                                        'position': 'relative',
                                        'top': '6px',
                                        'left': '-52px', }">
                                </ngx-skeleton-loader>
                                <div *ngIf="!isFirstLoading" class="total-patients"
                                    matTooltip="Pacientes que, no momento, estão em alguma fase do processo de entrada.">
                                    <mat-icon class="lean-icon"><img src="/assets/icons/lean-panel/paciente.png" aria-hidden="false" aria-label="vazio"></mat-icon>
                                    <span class="title">Pacientes</span>
                                    <span class="data"><b>{{this.leanInputFlowStruct.totalPatients}}</b></span>
                                    <mat-icon *ngIf="this.leanInputFlowStruct.totalPatientTypeAlert != normalAlert"
                                        class="warning-icon warning-critic" 
                                        [ngClass]="{'warning-alert': this.leanInputFlowStruct.totalPatientTypeAlert == warningAlert, 
                                                    'warning-critic': this.leanInputFlowStruct.totalPatientTypeAlert == criticalAlert}" 
                                        [matTooltip]="this.leanInputFlowStruct.totalPatientTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                        aria-hidden="false" 
                                        aria-label="warning"
                                        >warning
                                    </mat-icon>
                                </div>
                                
                                <div *ngIf="!isFirstLoading" class="total-evasion"
                                    matTooltip="% de pacientes que evadiram em alguma fase do processo de entrada.">
                                    <mat-icon class="lean-icon">directions_run</mat-icon>
                                    <span class="title">Evasões</span>
                                    <span class="data"><b>{{this.leanInputFlowStruct.totalEvasion}}</b></span>
                                    <mat-icon *ngIf="this.leanInputFlowStruct.totalEvasionTypeAlert != normalAlert"
                                        class="warning-icon warning-critic" 
                                        [ngClass]="{'warning-alert': this.leanInputFlowStruct.totalEvasionTypeAlert == warningAlert, 
                                                    'warning-critic': this.leanInputFlowStruct.totalEvasionTypeAlert == criticalAlert}" 
                                        [matTooltip]="this.leanInputFlowStruct.totalEvasionTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                        aria-hidden="false" 
                                        aria-label="warning">warning
                                    </mat-icon>
                                </div>
                            </div>
                            <ngx-skeleton-loader *ngIf="isFirstLoading"
                                animation="progress-dark"
                                [theme]="{ 
                                    width: '190px', 
                                    height: '130px',
                                    'margin': '10px 50px 18px 30px' }">
                            </ngx-skeleton-loader>
                            <div *ngIf="!isFirstLoading" class="gauge-chart">
                                <app-gauge-chart [title]="'Porta médico'" 
                                                [value]="this.leanInputFlowStruct.speedometerGraphStruct.percentage" 
                                                [showValue]="this.leanInputFlowStruct.averageMedicalPassDoor" 
                                                [chartLimits]="this.flowInputLimits"
                                                [textTooltip]="'Tempo médio desde a chegada até o início do atendimento médico, nas últimas 4 horas.'">
                                </app-gauge-chart>
                            </div>
                        </div>
                        <div *ngIf="!isFirstLoading" class="center-ball"></div>
                    </div>
                    <div class="flow-entry">
                        <ngx-skeleton-loader *ngIf="isFirstLoading"
                            count="4"
                            animation="progress-dark"
                            [theme]="{ 
                                width: '100%', 
                                height: '60px',
                                'margin': '0px' }">
                        </ngx-skeleton-loader>
                        <mat-accordion *ngIf="!isFirstLoading" class="accordion" multi>
    
                            <mat-expansion-panel [expanded]="true">
                                <mat-expansion-panel-header class="accordion-head">
                                    <div class="module">
                                        <mat-icon class="lean-icon"><img src="/assets/icons/lean-panel/classifica.png" aria-hidden="false" aria-label="vazio"></mat-icon>
                                        <span>Classificação</span>
                                    </div>
                                    <div class="accordion-info double-line-pink">
                                        <div class="info" matTooltip="Quantidade de pacientes aguardando triagem no momento.">
                                            <span>Aguardando</span>
                                            <span class="data">{{this.leanInputFlowStruct.riskClassificationInputStruct.patientWaiting}}</span>
                                        </div>
                                        <mat-icon *ngIf="this.leanInputFlowStruct.riskClassificationInputStruct.patientWaitingTypeAlert != normalAlert"
                                            class="warning-icon" 
                                            [ngClass]="{'warning-alert': this.leanInputFlowStruct.riskClassificationInputStruct.patientWaitingTypeAlert == warningAlert, 
                                                        'warning-critic': this.leanInputFlowStruct.riskClassificationInputStruct.patientWaitingTypeAlert == criticalAlert}" 
                                            [matTooltip]="this.leanInputFlowStruct.riskClassificationInputStruct.patientWaitingTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                            aria-hidden="false" 
                                            aria-label="warning">warning
                                        </mat-icon>
                                    </div>
                                    <div class="accordion-info">
                                        <div class="info" 
                                            matTooltip="Tempo médio de espera desde a chegada na CR até o início da triagem.">
                                            <span>Espera</span>
                                            <span class="data">{{this.leanInputFlowStruct.riskClassificationInputStruct.averagePatientWaitingTime}}</span>
                                        </div>
                                        <mat-icon *ngIf="this.leanInputFlowStruct.riskClassificationInputStruct.averagePatientWaitingTimeTypeAlert != normalAlert"
                                            class="warning-icon" 
                                            [ngClass]="{'warning-alert': this.leanInputFlowStruct.riskClassificationInputStruct.averagePatientWaitingTimeTypeAlert == warningAlert, 
                                                        'warning-critic': this.leanInputFlowStruct.riskClassificationInputStruct.averagePatientWaitingTimeTypeAlert == criticalAlert}"
                                            [matTooltip]="this.leanInputFlowStruct.riskClassificationInputStruct.averagePatientWaitingTimeTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                            aria-hidden="false" 
                                            aria-label="warning">warning
                                        </mat-icon>
                                    </div>
                                </mat-expansion-panel-header>
                            
                                <div class="info-per-hour">
                                    <div class="card-per-hour col-5">
                                        <mat-icon class="lean-icon"><img src="/assets/icons/lean-panel/senhas.png" aria-hidden="false" aria-label="vazio"></mat-icon>
                                        <div class="accordion-info">
                                            <div class="info" matTooltip="Total de senhas geradas para este serviço, na última hora.">
                                                <span class="data">{{this.leanInputFlowStruct.riskClassificationInputStruct.ticketPerHour}}</span>
                                                <span>Senhas/hora</span>
                                            </div>
                                        </div>
                                        <mat-icon *ngIf="this.leanInputFlowStruct.riskClassificationInputStruct.ticketPerHourTypeAlert != normalAlert"
                                            class="warning-icon" 
                                            [ngClass]="{'warning-alert': this.leanInputFlowStruct.riskClassificationInputStruct.ticketPerHourTypeAlert == warningAlert, 
                                                        'warning-critic': this.leanInputFlowStruct.riskClassificationInputStruct.ticketPerHourTypeAlert == criticalAlert}"
                                            [matTooltip]="this.leanInputFlowStruct.riskClassificationInputStruct.ticketPerHourTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                            aria-hidden="false" 
                                            aria-label="warning">warning
                                        </mat-icon>
                                    </div>
                                    <div class="card-per-hour line-gray-left col-7">
                                        <div class="accordion-info">
                                            <div class="info" matTooltip="Total de classificações realizadas, na última hora.">
                                                <span class="data">{{this.leanInputFlowStruct.riskClassificationInputStruct.attendancePerHour}}</span>
                                                <span>Classificações/hora</span>
                                            </div>
                                        </div>
                                        <mat-icon class="lean-icon"><img src="/assets/icons/lean-panel/classifica_espera.png" aria-hidden="false" aria-label="vazio"></mat-icon>
                                        <mat-icon *ngIf="this.leanInputFlowStruct.riskClassificationInputStruct.attendancePerHourTypeAlert != normalAlert"
                                            class="warning-icon" 
                                            [ngClass]="{'warning-alert': this.leanInputFlowStruct.riskClassificationInputStruct.attendancePerHourTypeAlert == warningAlert, 
                                                        'warning-critic': this.leanInputFlowStruct.riskClassificationInputStruct.attendancePerHourTypeAlert == criticalAlert}"
                                            [matTooltip]="this.leanInputFlowStruct.riskClassificationInputStruct.attendancePerHourTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                            aria-hidden="false" 
                                            aria-label="warning">warning
                                        </mat-icon>
                                    </div>
                                </div>
                                <div *ngIf="this.leanInputFlowStruct.riskClassificationInputStruct.listSectorByModuleStruct.length > 0">
                                    <div class="head-flow-sector">
                                        <span class="time-flow-sector">Duração</span>
                                        <span class="time-flow-sector">Ciclo</span>
                                    </div>
                                    <div class="flow-sector-container">
                                        <div class="loop" *ngFor="let item of this.leanInputFlowStruct.riskClassificationInputStruct.listSectorByModuleStruct">
                                            <mat-icon class="warning-icon" 
                                                [ngClass]="{'icon-visible': item.averageDurationBySectorTypeAlert != normalAlert,
                                                            'icon-invisible': item.averageDurationBySectorTypeAlert == normalAlert,
                                                            'warning-alert': item.averageDurationBySectorTypeAlert == warningAlert, 
                                                            'warning-critic': item.averageDurationBySectorTypeAlert == criticalAlert}" 
                                                [matTooltip]="item.averageDurationBySectorTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                                aria-hidden="false" 
                                                aria-label="warning">warning
                                            </mat-icon>
                                            <div class="item-flow-sector">
                                                <div class="time-flow-sector classification-flow" matTooltip="Tempo médio de duração da classificação de risco, nas últimas 4h, neste setor.">
                                                    <span>{{item.averageDurationBySector}}</span>
                                                </div>
                                                <div class="name-flow-sector classification-flow">
                                                    <span>{{item.sectorName}}</span>
                                                </div>
                                                <div class="time-flow-sector classification-flow" matTooltip="Tempo médio de ociosidade entre o fim de um atendimento e o início do próximo, neste setor.">
                                                    <span>{{item.cycleTimeBySector}}</span>
                                                </div>
                                            </div>
                                            <mat-icon
                                                class="warning-icon" 
                                                [ngClass]="{'icon-visible': item.cycleTimeBySectorTypeAlert != normalAlert,
                                                            'icon-invisible': item.cycleTimeBySectorTypeAlert == normalAlert,
                                                            'warning-alert': item.cycleTimeBySectorTypeAlert == warningAlert, 
                                                            'warning-critic': item.cycleTimeBySectorTypeAlert == criticalAlert}" 
                                                [matTooltip]="item.cycleTimeBySectorTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                                aria-hidden="false" 
                                                aria-label="warning">warning
                                            </mat-icon>
                                        </div>
                                    </div>
                                </div>
                                <div class="empty-list-sector" *ngIf="!this.leanInputFlowStruct.riskClassificationInputStruct.listSectorByModuleStruct.length > 0">
                                    <img src="/assets/images/file-searching.gif" aria-hidden="false" aria-label="vazio">
                                </div>
                          
                            </mat-expansion-panel>
    
                            <mat-expansion-panel [expanded]="true">
                                <mat-expansion-panel-header class="accordion-head">
                                    <div class="module">
                                        <mat-icon class="lean-icon"><img src="/assets/icons/lean-panel/admissao.png" aria-hidden="false" aria-label="vazio"></mat-icon>
                                        <span>Admissão</span>
                                    </div>
                                    <div class="accordion-info double-line-pink">
                                        <div class="info" matTooltip="Quantidade de pacientes aguardando cadastro no momento.">
                                            <span>Aguardando</span>
                                            <span class="data">{{this.leanInputFlowStruct.frontDeskInputStruct.patientWaiting}}</span>
                                        </div>
                                        <mat-icon *ngIf="this.leanInputFlowStruct.frontDeskInputStruct.patientWaitingTypeAlert != normalAlert"
                                            class="warning-icon" 
                                            [ngClass]="{'warning-alert': this.leanInputFlowStruct.frontDeskInputStruct.patientWaitingTypeAlert == warningAlert, 
                                                        'warning-critic': this.leanInputFlowStruct.frontDeskInputStruct.patientWaitingTypeAlert == criticalAlert}" 
                                            [matTooltip]="this.leanInputFlowStruct.frontDeskInputStruct.patientWaitingTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                            aria-hidden="false" 
                                            aria-label="warning">warning
                                        </mat-icon>
                                    </div>
                                    <div class="accordion-info">
                                        <div class="info" matTooltip="Tempo médio de espera desde a chegada na admissão até o início do cadastro.">
                                            <span>Espera</span>
                                            <span class="data">{{this.leanInputFlowStruct.frontDeskInputStruct.averagePatientWaitingTime}}</span>
                                        </div>
                                        <mat-icon *ngIf="this.leanInputFlowStruct.frontDeskInputStruct.averagePatientWaitingTimeTypeAlert != normalAlert"
                                            class="warning-icon" 
                                            [ngClass]="{'warning-alert': this.leanInputFlowStruct.frontDeskInputStruct.averagePatientWaitingTimeTypeAlert == warningAlert, 
                                                        'warning-critic': this.leanInputFlowStruct.frontDeskInputStruct.averagePatientWaitingTimeTypeAlert == criticalAlert}" 
                                            [matTooltip]="this.leanInputFlowStruct.frontDeskInputStruct.averagePatientWaitingTimeTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                            aria-hidden="false" 
                                            aria-label="warning">warning
                                        </mat-icon>
                                    </div>
                                </mat-expansion-panel-header>
                            
                                <div class="info-per-hour">
                                    <div class="card-per-hour col-5">
                                        <mat-icon class="lean-icon"><img src="/assets/icons/lean-panel/senhas.png" aria-hidden="false" aria-label="vazio"></mat-icon>
                                        <div class="accordion-info">
                                            <div class="info" matTooltip="Total de senhas geradas para este serviço, na última hora.">
                                                <span class="data">{{this.leanInputFlowStruct.frontDeskInputStruct.ticketPerHour}}</span>
                                                <span>Senhas/hora</span>
                                            </div>
                                        </div>
                                        <mat-icon *ngIf="this.leanInputFlowStruct.frontDeskInputStruct.ticketPerHourTypeAlert != normalAlert"
                                            class="warning-icon" 
                                            [ngClass]="{'warning-alert': this.leanInputFlowStruct.frontDeskInputStruct.ticketPerHourTypeAlert == warningAlert, 
                                                        'warning-critic': this.leanInputFlowStruct.frontDeskInputStruct.ticketPerHourTypeAlert == criticalAlert}" 
                                            [matTooltip]="this.leanInputFlowStruct.frontDeskInputStruct.ticketPerHourTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                            aria-hidden="false" 
                                            aria-label="warning">warning
                                        </mat-icon>
                                    </div>
                                    <div class="card-per-hour line-gray-left col-7">
                                        <div class="accordion-info">
                                            <div class="info" matTooltip="Total de admissões realizadas, na última hora.">
                                                <span class="data">{{this.leanInputFlowStruct.frontDeskInputStruct.attendancePerHour}}</span>
                                                <span>Admissão/hora</span>
                                            </div>
                                        </div>
                                        <mat-icon class="lean-icon"><img src="/assets/icons/lean-panel/espera_admissao.png" aria-hidden="false" aria-label="vazio"></mat-icon>
                                        <mat-icon *ngIf="this.leanInputFlowStruct.frontDeskInputStruct.attendancePerHourTypeAlert != normalAlert"
                                            class="warning-icon" 
                                            [ngClass]="{'warning-alert': this.leanInputFlowStruct.frontDeskInputStruct.attendancePerHourTypeAlert == warningAlert, 
                                                        'warning-critic': this.leanInputFlowStruct.frontDeskInputStruct.attendancePerHourTypeAlert == criticalAlert}" 
                                            [matTooltip]="this.leanInputFlowStruct.frontDeskInputStruct.attendancePerHourTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                            aria-hidden="false" 
                                            aria-label="warning">warning
                                        </mat-icon>
                                    </div>
                                </div>
                                <div *ngIf="this.leanInputFlowStruct.frontDeskInputStruct.listSectorByModuleStruct.length > 0">
                                    <div class="head-flow-sector">
                                        <span class="time-flow-sector">Duração</span>
                                        <span class="time-flow-sector">Ciclo</span>
                                    </div>
                                    <div class="flow-sector-container">
                                        <div class="loop" *ngFor="let item of this.leanInputFlowStruct.frontDeskInputStruct.listSectorByModuleStruct">
                                            <mat-icon class="warning-icon" 
                                                [ngClass]="{'icon-visible': item.averageDurationBySectorTypeAlert != normalAlert,
                                                            'icon-invisible': item.averageDurationBySectorTypeAlert == normalAlert,
                                                            'warning-alert': item.averageDurationBySectorTypeAlert == warningAlert, 
                                                            'warning-critic': item.averageDurationBySectorTypeAlert == criticalAlert}" 
                                                [matTooltip]="item.averageDurationBySectorTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                                aria-hidden="false" 
                                                aria-label="warning">warning
                                            </mat-icon>
                                            <div class="item-flow-sector">
                                                <div class="time-flow-sector admission-flow"  matTooltip="Tempo médio de duração do cadastro, nas últimas 4h, neste setor.">
                                                    <span>{{item.averageDurationBySector}}</span>
                                                </div>
                                                <div class="name-flow-sector admission-flow">
                                                    <span>{{item.sectorName}}</span>
                                                </div>
                                                <div class="time-flow-sector admission-flow" matTooltip="Tempo médio de ociosidade entre o fim de um atendimento e o início do próximo, neste setor.">
                                                    <span>{{item.cycleTimeBySector}}</span>
                                                </div>
                                            </div>
                                            <mat-icon
                                                class="warning-icon" 
                                                [ngClass]="{'icon-visible': item.cycleTimeBySectorTypeAlert != normalAlert,
                                                            'icon-invisible': item.cycleTimeBySectorTypeAlert == normalAlert,
                                                            'warning-alert': item.cycleTimeBySectorTypeAlert == warningAlert, 
                                                            'warning-critic': item.cycleTimeBySectorTypeAlert == criticalAlert}" 
                                                [matTooltip]="item.cycleTimeBySectorTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                                aria-hidden="false" 
                                                aria-label="warning">warning
                                            </mat-icon>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="empty-list-sector" *ngIf="!this.leanInputFlowStruct.frontDeskInputStruct.listSectorByModuleStruct.length > 0">
                                    <img src="/assets/images/file-searching.gif" aria-hidden="false" aria-label="vazio">
                                </div>
                          
                            </mat-expansion-panel>
    
                            <mat-expansion-panel [expanded]="true">
                                <mat-expansion-panel-header class="accordion-head">
                                    <div class="module">
                                        <mat-icon class="lean-icon"><img src="/assets/icons/lean-panel/medico.png" aria-hidden="false" aria-label="vazio"></mat-icon>
                                        <span>Médico</span>
                                    </div>
                                    <div class="accordion-info double-line-pink">
                                        <div class="info" matTooltip="Quantidade de pacientes aguardando atendimento médico (1ª avaliação) no momento.">
                                            <span>Aguardando</span>
                                            <span class="data">{{this.leanInputFlowStruct.medicInputStruct.patientWaiting}}</span>
                                        </div>
                                        <mat-icon *ngIf="this.leanInputFlowStruct.medicInputStruct.patientWaitingTypeAlert != normalAlert"
                                            class="warning-icon" 
                                            [ngClass]="{'warning-alert': this.leanInputFlowStruct.medicInputStruct.patientWaitingTypeAlert == warningAlert, 
                                                        'warning-critic': this.leanInputFlowStruct.medicInputStruct.patientWaitingTypeAlert == criticalAlert}" 
                                            [matTooltip]="this.leanInputFlowStruct.medicInputStruct.patientWaitingTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                            aria-hidden="false" 
                                            aria-label="warning">warning
                                        </mat-icon>
                                    </div>
                                    <div class="accordion-info">
                                        <div class="info" matTooltip="Tempo médio de espera desde o fim da triagem até o início do atendimento médico, nas últimas 4h.">
                                            <span>Espera</span>
                                            <span class="data">{{this.leanInputFlowStruct.medicInputStruct.averagePatientWaitingTime}}</span>
                                        </div>
                                        <mat-icon *ngIf="this.leanInputFlowStruct.medicInputStruct.averagePatientWaitingTimeTypeAlert != normalAlert"
                                            class="warning-icon" 
                                            [ngClass]="{'warning-alert': this.leanInputFlowStruct.medicInputStruct.averagePatientWaitingTimeTypeAlert == warningAlert, 
                                                        'warning-critic': this.leanInputFlowStruct.medicInputStruct.averagePatientWaitingTimeTypeAlert == criticalAlert}" 
                                            [matTooltip]="this.leanInputFlowStruct.medicInputStruct.averagePatientWaitingTimeTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                            aria-hidden="false" 
                                            aria-label="warning">warning
                                        </mat-icon>
                                    </div>
                                </mat-expansion-panel-header>

                                <div class="info-per-hour">
                                    <div class="card-per-hour col-5">
                                        <mat-icon class="lean-icon"><img src="/assets/icons/lean-panel/senhas.png" aria-hidden="false" aria-label="vazio"></mat-icon>
                                        <div class="accordion-info">
                                            <div class="info" matTooltip="Total de senhas geradas para este serviço, na última hora.">
                                                <span class="data">{{this.leanInputFlowStruct.medicInputStruct.ticketPerHour}}</span>
                                                <span>Senhas/hora</span>
                                            </div>
                                        </div>
                                        <mat-icon *ngIf="this.leanInputFlowStruct.medicInputStruct.ticketPerHourTypeAlert != normalAlert"
                                            class="warning-icon" 
                                            [ngClass]="{'warning-alert': this.leanInputFlowStruct.medicInputStruct.ticketPerHourTypeAlert == warningAlert, 
                                                        'warning-critic': this.leanInputFlowStruct.medicInputStruct.ticketPerHourTypeAlert == criticalAlert}" 
                                            [matTooltip]="this.leanInputFlowStruct.medicInputStruct.ticketPerHourTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                            aria-hidden="false" 
                                            aria-label="warning">warning
                                        </mat-icon>
                                    </div>
                                </div>
                                <div  *ngIf="this.leanInputFlowStruct.medicInputStruct.listSectorByModuleStruct.length > 0">
                                    <div class="head-flow-sector">
                                        <span class="time-flow-sector">Espera</span>
                                        <span class="time-flow-sector">Aguardando</span>
                                    </div>
                                    <div class="flow-sector-container">
                                        <div class="loop" *ngFor="let item of this.leanInputFlowStruct.medicInputStruct.listSectorByModuleStruct">
                                            <mat-icon class="warning-icon" 
                                                [ngClass]="{'icon-visible': item.averageWaitingTimeBySectorTypeAlert != normalAlert,
                                                            'icon-invisible': item.averageWaitingTimeBySectorTypeAlert == normalAlert,    
                                                            'warning-alert': item.averageWaitingTimeBySectorTypeAlert == warningAlert, 
                                                            'warning-critic': item.averageWaitingTimeBySectorTypeAlert == criticalAlert}" 
                                                [matTooltip]="item.averageWaitingTimeBySectorTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                                aria-hidden="false" 
                                                aria-label="warning">warning
                                            </mat-icon>
                                            <div class="item-flow-sector">
                                                <div class="time-flow-sector medic-flow" matTooltip="Tempo médio de espera desde o fim da triagem até o início do atendimento médico, nas últimas 4h neste setor.">
                                                    <span>{{item.averageWaitingTimeBySector}}</span>
                                                </div>
                                                <div class="name-flow-sector medic-flow">
                                                    <span>{{item.sectorName}}</span>
                                                </div>
                                                <div class="wait-flow-sector" matTooltip="Quantidade de pacientes aguardando atendimento médico (1ª avaliação) no momento neste setor.">
                                                    <div class="bar medic-flow"
                                                        [ngStyle]="{'width': (100*item.patientAwaitingBySector/this.leanInputFlowStruct.medicInputStruct.patientWaiting) + '%'}">
                                                    </div>
                                                    <div class="count">
                                                        {{item.patientAwaitingBySector}}
                                                    </div>
                                                </div>
                                            </div>
                                            <mat-icon class="warning-icon" 
                                                [ngClass]="{'icon-visible': item.patientAwaitingBySectorTypeAlert != normalAlert,
                                                            'icon-invisible': item.patientAwaitingBySectorTypeAlert == normalAlert,
                                                            'warning-alert': item.patientAwaitingBySectorTypeAlert == warningAlert, 
                                                            'warning-critic': item.patientAwaitingBySectorTypeAlert == criticalAlert}" 
                                                [matTooltip]="item.patientAwaitingBySectorTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                                aria-hidden="false" 
                                                aria-label="warning">warning
                                            </mat-icon>                                        
                                        </div>
                                    </div>
                                </div>

                                <div class="empty-list-sector" *ngIf="!this.leanInputFlowStruct.medicInputStruct.listSectorByModuleStruct.length > 0">
                                    <img src="/assets/images/file-searching.gif" aria-hidden="false" aria-label="vazio">
                                </div>
                          
                            </mat-expansion-panel>
    
                            <mat-expansion-panel [expanded]="true">
                                <mat-expansion-panel-header class="accordion-head">
                                    <div class="module">
                                        <mat-icon class="lean-icon"><img src="/assets/icons/lean-panel/multiprofessional.png" aria-hidden="false" aria-label="vazio"></mat-icon>
                                        <span>Multiprofissional</span>
                                    </div>
                                    <div class="accordion-info double-line-pink">
                                        <div class="info" matTooltip="Quantidade de pacientes aguardando atendimento multiprofissional no momento.">
                                            <span>Aguardando</span>
                                            <span class="data">{{this.leanInputFlowStruct.multiProfessionalInputStruct.patientWaiting}}</span>
                                        </div>
                                        <mat-icon *ngIf="this.leanInputFlowStruct.multiProfessionalInputStruct.patientWaitingTypeAlert != normalAlert"
                                            class="warning-icon" 
                                            [ngClass]="{'warning-alert': this.leanInputFlowStruct.multiProfessionalInputStruct.patientWaitingTypeAlert == warningAlert, 
                                                        'warning-critic': this.leanInputFlowStruct.multiProfessionalInputStruct.patientWaitingTypeAlert == criticalAlert}" 
                                            [matTooltip]="this.leanInputFlowStruct.multiProfessionalInputStruct.patientWaitingTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                            aria-hidden="false" 
                                            aria-label="warning">warning
                                        </mat-icon>
                                    </div>
                                    <div class="accordion-info">
                                        <div class="info" matTooltip="Tempo médio de espera desde o fim da triagem até o início do atendimento multiprofissional, nas últimas 4h.">
                                            <span>Espera</span>
                                            <span class="data">{{this.leanInputFlowStruct.multiProfessionalInputStruct.averagePatientWaitingTime}}</span>
                                        </div>
                                        <mat-icon *ngIf="this.leanInputFlowStruct.multiProfessionalInputStruct.averagePatientWaitingTimeTypeAlert != normalAlert"
                                            class="warning-icon" 
                                            [ngClass]="{'warning-alert': this.leanInputFlowStruct.multiProfessionalInputStruct.averagePatientWaitingTimeTypeAlert == warningAlert, 
                                                        'warning-critic': this.leanInputFlowStruct.multiProfessionalInputStruct.averagePatientWaitingTimeTypeAlert == criticalAlert}" 
                                            [matTooltip]="this.leanInputFlowStruct.multiProfessionalInputStruct.averagePatientWaitingTimeTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                            aria-hidden="false" 
                                            aria-label="warning">warning
                                        </mat-icon>
                                    </div>
                                </mat-expansion-panel-header>

                                <div class="info-per-hour">
                                    <div class="card-per-hour col-5">
                                        <mat-icon class="lean-icon"><img src="/assets/icons/lean-panel/senhas.png" aria-hidden="false" aria-label="vazio"></mat-icon>
                                        <div class="accordion-info">
                                            <div class="info" matTooltip="Total de senhas geradas para este serviço, na última hora.">
                                                <span class="data">{{this.leanInputFlowStruct.multiProfessionalInputStruct.ticketPerHour}}</span>
                                                <span>Senhas/hora</span>
                                            </div>
                                        </div>
                                        <mat-icon *ngIf="this.leanInputFlowStruct.multiProfessionalInputStruct.ticketPerHourTypeAlert != normalAlert"
                                            class="warning-icon" 
                                            [ngClass]="{'warning-alert': this.leanInputFlowStruct.multiProfessionalInputStruct.ticketPerHourTypeAlert == warningAlert, 
                                                        'warning-critic': this.leanInputFlowStruct.multiProfessionalInputStruct.ticketPerHourTypeAlert == criticalAlert}" 
                                            [matTooltip]="this.leanInputFlowStruct.multiProfessionalInputStruct.ticketPerHourTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                            aria-hidden="false" 
                                            aria-label="warning">warning
                                        </mat-icon>
                                    </div>
                                </div>
                                <div *ngIf="this.leanInputFlowStruct.multiProfessionalInputStruct.listSectorByModuleStruct.length > 0">
                                    <div class="head-flow-sector">
                                        <span class="time-flow-sector">Espera</span>
                                        <span class="time-flow-sector">Aguardando</span>
                                    </div>
                                    <div class="flow-sector-container">
                                        <div class="loop" *ngFor="let item of this.leanInputFlowStruct.multiProfessionalInputStruct.listSectorByModuleStruct">
                                            <mat-icon class="warning-icon" 
                                                [ngClass]="{'icon-visible': item.averageWaitingTimeBySectorTypeAlert != normalAlert,
                                                            'icon-invisible': item.averageWaitingTimeBySectorTypeAlert == normalAlert,    
                                                            'warning-alert': item.averageWaitingTimeBySectorTypeAlert == warningAlert, 
                                                            'warning-critic': item.averageWaitingTimeBySectorTypeAlert == criticalAlert}" 
                                                [matTooltip]="item.averageWaitingTimeBySectorTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                                aria-hidden="false" 
                                                aria-label="warning">warning
                                            </mat-icon>
                                            <div class="item-flow-sector">
                                                <div class="time-flow-sector multi-professional-flow" matTooltip="Tempo médio de espera desde o fim da triagem até o início do atendimento multiprofissional, nas últimas 4h neste setor.">
                                                    <span>{{item.averageWaitingTimeBySector}}</span>
                                                </div>
                                                <div class="name-flow-sector multi-professional-flow">
                                                    <span>{{item.sectorName}}</span>
                                                </div>
                                                
                                                <div class="wait-flow-sector" matTooltip="Quantidade de pacientes aguardando atendimento multiprofissional no momento neste setor.">
                                                    <div class="bar multi-professional-flow"
                                                        [ngStyle]="{'width': (100*item.patientAwaitingBySector/this.leanInputFlowStruct.multiProfessionalInputStruct.patientWaiting) + '%'}">
                                                    </div>
                                                    <div class="count">
                                                        {{item.patientAwaitingBySector}}
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <mat-icon class="warning-icon" 
                                                [ngClass]="{'icon-visible': item.patientAwaitingBySectorTypeAlert != normalAlert,
                                                            'icon-invisible': item.patientAwaitingBySectorTypeAlert == normalAlert,
                                                            'warning-alert': item.patientAwaitingBySectorTypeAlert == warningAlert, 
                                                            'warning-critic': item.patientAwaitingBySectorTypeAlert == criticalAlert}" 
                                                [matTooltip]="item.patientAwaitingBySectorTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                                aria-hidden="false" 
                                                aria-label="warning">warning
                                            </mat-icon>
                                        </div>
                                    </div>
                                </div>

                                <div class="empty-list-sector" *ngIf="!this.leanInputFlowStruct.multiProfessionalInputStruct.listSectorByModuleStruct.length > 0">
                                    <img src="/assets/images/file-searching.gif" aria-hidden="false" aria-label="vazio">
                                </div>
                          
                            </mat-expansion-panel>
    
                        </mat-accordion>
                        <div class="medic-flow-data">
                            <ngx-skeleton-loader *ngIf="isFirstLoading"
                                animation="progress-dark"
                                [theme]="{ 
                                    width: '145px', 
                                    height: '50px',
                                    'margin-bottom': '0px' }">
                            </ngx-skeleton-loader>
                            <ngx-skeleton-loader *ngIf="isFirstLoading"
                                animation="progress-dark"
                                [theme]="{ 
                                    width: '145px', 
                                    height: '50px',
                                    'margin-bottom': '0px' }">
                            </ngx-skeleton-loader>
                            <ngx-skeleton-loader *ngIf="isFirstLoading"
                                animation="progress-dark"
                                [theme]="{ 
                                    width: '145px', 
                                    height: '50px',
                                    'margin-bottom': '0px' }">
                            </ngx-skeleton-loader>
                            <div class="data-card" *ngIf="!isFirstLoading">
                                <div class="data-card-info ">
                                    <div class="flow" matTooltip="Tempo médio de espera de EMERGÊNCIA desde a triagem até o início do atendimento médico, nas últimas 4h.">
                                        <span>Fluxo Emergência</span>
                                        <span class="data">{{this.leanInputFlowStruct.averageWaitingTimeEmergencyFlow}}</span>
                                    </div>
                                    <mat-icon *ngIf="this.leanInputFlowStruct.averageWaitingTimeEmergencyFlowTypeAlert != normalAlert"
                                        class="warning-icon" 
                                        [ngClass]="{'warning-alert': this.leanInputFlowStruct.averageWaitingTimeEmergencyFlowTypeAlert == warningAlert, 
                                                    'warning-critic': this.leanInputFlowStruct.averageWaitingTimeEmergencyFlowTypeAlert == criticalAlert}" 
                                        [matTooltip]="this.leanInputFlowStruct.averageWaitingTimeEmergencyFlowTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                        aria-hidden="false" 
                                        aria-label="warning">warning
                                    </mat-icon>
                                </div>
                                <br>
                                <div class="data-card-info ">
                                    <div class="flow" matTooltip="Quantidade de pacientes de EMERGÊNCIA aguardando atendimento médico no momento.">
                                        <span>Aguardando</span>
                                        <span class="data">{{this.leanInputFlowStruct.patientWaitingTimeEmergencyFlow}}</span>
                                    </div>
                                    <mat-icon *ngIf="this.leanInputFlowStruct.patientWaitingTimeEmergencyFlowTypeAlert != normalAlert"
                                        class="warning-icon" 
                                        [ngClass]="{'warning-alert': this.leanInputFlowStruct.patientWaitingTimeEmergencyFlowTypeAlert == warningAlert, 
                                                    'warning-critic': this.leanInputFlowStruct.patientWaitingTimeEmergencyFlowTypeAlert == criticalAlert}" 
                                        [matTooltip]="this.leanInputFlowStruct.patientWaitingTimeEmergencyFlowTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                        aria-hidden="false" 
                                        aria-label="warning">warning
                                    </mat-icon>
                                </div>
                            </div>
                            <div class="data-card" *ngIf="!isFirstLoading">
                                <div class="data-card-info">
                                    <div class="flow" matTooltip="Tempo médio de espera desde o fim da triagem até o início do atendimento médico, nas últimas 4h.">
                                        <span>Fluxo Normal</span>
                                        <span class="data">{{this.leanInputFlowStruct.averageWaitingTimeNormalFlow}}</span>
                                    </div>
                                    <mat-icon *ngIf="this.leanInputFlowStruct.averageWaitingTimeNormalFlowTypeAlert != normalAlert"
                                        class="warning-icon" 
                                        [ngClass]="{'warning-alert': this.leanInputFlowStruct.averageWaitingTimeNormalFlowTypeAlert == warningAlert, 
                                                    'warning-critic': this.leanInputFlowStruct.averageWaitingTimeNormalFlowTypeAlert == criticalAlert}" 
                                        [matTooltip]="this.leanInputFlowStruct.averageWaitingTimeNormalFlowTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                        aria-hidden="false" 
                                        aria-label="warning">warning
                                    </mat-icon>
                                </div>
                                <br>
                                <div class="data-card-info ">
                                    <div class="flow" matTooltip="Quantidade de pacientes aguardando atendimento no setor médico normal no momento.">
                                        <span>Aguardando</span>
                                        <span class="data">{{this.leanInputFlowStruct.patientWaitingTimeNormalFlow}}</span>
                                    </div>
                                    <mat-icon *ngIf="this.leanInputFlowStruct.patientWaitingTimeNormalFlowTypeAlert != normalAlert"
                                        class="warning-icon" 
                                        [ngClass]="{'warning-alert': this.leanInputFlowStruct.patientWaitingTimeNormalFlowTypeAlert == warningAlert, 
                                                    'warning-critic': this.leanInputFlowStruct.patientWaitingTimeNormalFlowTypeAlert == criticalAlert}" 
                                        [matTooltip]="this.leanInputFlowStruct.patientWaitingTimeNormalFlowTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                        aria-hidden="false" 
                                        aria-label="warning">warning
                                    </mat-icon>
                                </div>
                            </div>
                            <div class="data-card" *ngIf="!isFirstLoading">
                                <div class="data-card-info">
                                    <div class="flow" matTooltip="Tempo médio de espera desde a triagem até o atendimento fast track, nas últimas 4h.">
                                        <span>Fluxo Rápido</span>
                                        <span class="data">{{this.leanInputFlowStruct.averageWaitingTimeFastFlow}}</span>
                                    </div>
                                    <mat-icon *ngIf="this.leanInputFlowStruct.averageWaitingTimeFastFlowTypeAlert != normalAlert"
                                        class="warning-icon" 
                                        [ngClass]="{'warning-alert': this.leanInputFlowStruct.averageWaitingTimeFastFlowTypeAlert == warningAlert, 
                                                    'warning-critic': this.leanInputFlowStruct.averageWaitingTimeFastFlowTypeAlert == criticalAlert}" 
                                        [matTooltip]="this.leanInputFlowStruct.averageWaitingTimeFastFlowTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                        aria-hidden="false" 
                                        aria-label="warning">warning
                                    </mat-icon>
                                </div>
                                <br>
                                <div class="data-card-info ">
                                    <div class="flow" matTooltip="Quantidade de pacientes aguardando atendimento atendimento fast track no momento.">
                                        <span>Aguardando</span>
                                        <span class="data">{{this.leanInputFlowStruct.patientWaitingTimeFastFlow}}</span>
                                    </div>
                                    <mat-icon *ngIf="this.leanInputFlowStruct.patientWaitingTimeFastFlowTypeAlert != normalAlert"
                                        class="warning-icon" 
                                        [ngClass]="{'warning-alert': this.leanInputFlowStruct.patientWaitingTimeFastFlowTypeAlert == warningAlert, 
                                                    'warning-critic': this.leanInputFlowStruct.patientWaitingTimeFastFlowTypeAlert == criticalAlert}" 
                                        [matTooltip]="this.leanInputFlowStruct.patientWaitingTimeFastFlowTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                        aria-hidden="false" 
                                        aria-label="warning">warning
                                    </mat-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flow-content margin-right"  [ngClass]="{'slide': isMobile, 'col-4': !isMobile}">
                    <div class="flow-chart line-gray-left">
                        <ngx-skeleton-loader *ngIf="isFirstLoading"
                            animation="progress-dark"
                            [theme]="{ 
                                width: '190px', 
                                height: '40px',
                                'margin-bottom': '0px' }">
                        </ngx-skeleton-loader>
                        <div *ngIf="!isFirstLoading" class="data-card">
                            <h1>Passagem</h1>
                        </div>
                        <div class="flow-value">
                            <div class="flow-cards-content">
                                <ngx-skeleton-loader *ngIf="isFirstLoading"
                                    animation="progress-dark"
                                    [theme]="{ 
                                        width: '60px', 
                                        height: '70px',
                                        'margin': '1px',
                                        position: 'relative',   
                                        'top': '-23px',
                                        'left': '-2px',
                                        'z-index': '1', }">
                                </ngx-skeleton-loader>
                                <ngx-skeleton-loader *ngIf="isFirstLoading"
                                    animation="progress-dark"
                                    [theme]="{ 
                                        width: '60px', 
                                        height: '70px',
                                        'margin': '1px',
                                        'position': 'relative',
                                        'top': '6px',
                                        'left': '-52px', }">
                                </ngx-skeleton-loader>
                                <div *ngIf="!isFirstLoading" class="total-patients" matTooltip="Pacientes que, no momento, estão em alguma fase do processo de passagem.">
                                    <mat-icon class="lean-icon"><img src="/assets/icons/lean-panel/paciente.png" aria-hidden="false" aria-label="vazio"></mat-icon>
                                    <span class="title">Pacientes</span>
                                    <span class="data"><b>{{this.leanPassFlowStruct.totalPatients}}</b></span>
                                    <mat-icon *ngIf="this.leanPassFlowStruct.totalPatientTypeAlert != normalAlert"
                                        class="warning-icon warning-critic" 
                                        [ngClass]="{'warning-alert': this.leanPassFlowStruct.totalPatientTypeAlert == warningAlert, 
                                                    'warning-critic': this.leanPassFlowStruct.totalPatientTypeAlert == criticalAlert}" 
                                        [matTooltip]="this.leanPassFlowStruct.totalPatientTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                        aria-hidden="false" 
                                        aria-label="warning">warning
                                    </mat-icon>
                                </div>
                                <div *ngIf="!isFirstLoading" class="total-evasion" matTooltip="% de pacientes que evadiram em alguma fase do processo de passagem, nas últimas 4h.">
                                    <mat-icon class="lean-icon">directions_run</mat-icon>
                                    <span class="title">Evasões</span>
                                    <span class="data"><b>{{this.leanPassFlowStruct.totalEvasion}}</b></span>
                                    <mat-icon *ngIf="this.leanPassFlowStruct.totalEvasionTypeAlert != normalAlert"
                                        class="warning-icon warning-critic" 
                                        [ngClass]="{'warning-alert': this.leanPassFlowStruct.totalEvasionTypeAlert == warningAlert, 
                                                    'warning-critic': this.leanPassFlowStruct.totalEvasionTypeAlert == criticalAlert}" 
                                        [matTooltip]="this.leanPassFlowStruct.totalEvasionTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                        aria-hidden="false" 
                                        aria-label="warning">warning
                                    </mat-icon>
                                </div>
                            </div>

                            <ngx-skeleton-loader *ngIf="isFirstLoading"
                                animation="progress-dark"
                                [theme]="{ 
                                    width: '190px', 
                                    height: '130px',
                                    'margin': '10px 50px 18px 30px' }">
                            </ngx-skeleton-loader>
                            <div *ngIf="!isFirstLoading" class="gauge-chart">
                                <app-gauge-chart [title]="'Médico decisão'" 
                                                [value]="this.leanPassFlowStruct.speedometerGraphStruct.percentage" 
                                                [showValue]="this.leanPassFlowStruct.averageMedicPassDecision" 
                                                [chartLimits]="this.flowPassLimits"
                                                [textTooltip]="'Tempo médio desde o início do primeiro atendimento médico até a alta ou solicitação de internação.'">
                                </app-gauge-chart>
                            </div>
                        </div>
                        <div *ngIf="!isFirstLoading" class="center-ball"></div>
                    </div>
                    <div class="flow-pass">
                        <ngx-skeleton-loader *ngIf="isFirstLoading"
                            count="5"
                            animation="progress-dark"
                            [theme]="{ 
                                width: '100%', 
                                height: '60px',
                                'margin': '0px' }">
                        </ngx-skeleton-loader>
                        <mat-accordion *ngIf="!isFirstLoading" class="accordion" multi>
                            
                            <mat-expansion-panel [expanded]="true">
                                <mat-expansion-panel-header class="accordion-head">
                                    <div class="module">
                                        <mat-icon class="lean-icon"><img src="/assets/icons/lean-panel/medico.png" aria-hidden="false" aria-label="vazio"></mat-icon>
                                        <span>Médico</span>
                                    </div>
                                    <div class="accordion-info double-line-pink">
                                        <div class="info" matTooltip="Total de pacientes aguardando o primeiro atendimento médico.">
                                            <span>Aguardando</span>
                                            <span class="data">{{this.leanPassFlowStruct.medicPassStruct.patientWaiting}}</span>
                                        </div>
                                        <mat-icon *ngIf="this.leanPassFlowStruct.medicPassStruct.patientWaitingTypeAlert != normalAlert"
                                            class="warning-icon" 
                                            [ngClass]="{'warning-alert': this.leanPassFlowStruct.medicPassStruct.patientWaitingTypeAlert == warningAlert, 
                                                        'warning-critic': this.leanPassFlowStruct.medicPassStruct.patientWaitingTypeAlert == criticalAlert}" 
                                            [matTooltip]="this.leanPassFlowStruct.medicPassStruct.patientWaitingTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                            aria-hidden="false" 
                                            aria-label="warning">warning
                                        </mat-icon>
                                    </div>
                                    <div class="accordion-info">
                                        <div class="info" matTooltip="Tempo médio de duração do primeiro atendimento médico, nas últimas 4h.">
                                            <span>Duração</span>
                                            <span class="data">{{this.leanPassFlowStruct.medicPassStruct.averagePatientDurationTime}}</span>
                                        </div>
                                        <mat-icon *ngIf="this.leanPassFlowStruct.medicPassStruct.averagePatientDurationTimeTypeAlert != normalAlert"
                                            class="warning-icon" 
                                            [ngClass]="{'warning-alert': this.leanPassFlowStruct.medicPassStruct.averagePatientDurationTimeTypeAlert == warningAlert, 
                                                        'warning-critic': this.leanPassFlowStruct.medicPassStruct.averagePatientDurationTimeTypeAlert == criticalAlert}" 
                                            [matTooltip]="this.leanPassFlowStruct.medicPassStruct.averagePatientDurationTimeTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                            aria-hidden="false" 
                                            aria-label="warning">warning
                                        </mat-icon>
                                    </div>
                                </mat-expansion-panel-header>

                                <div class="info-per-hour">
                                    <div class="card-per-hour col-5">
                                        <mat-icon class="lean-icon"><img src="/assets/icons/lean-panel/senhas.png" aria-hidden="false" aria-label="vazio"></mat-icon>
                                        <div class="accordion-info">
                                            <div class="info" matTooltip="Total de senhas geradas para este serviço, na última hora.">
                                                <span class="data">{{this.leanPassFlowStruct.medicPassStruct.ticketPerHour}}</span>
                                                <span>Senhas/hora</span>
                                            </div>
                                        </div>
                                        <mat-icon *ngIf="this.leanPassFlowStruct.medicPassStruct.ticketPerHourTypeAlert != normalAlert"
                                            class="warning-icon" 
                                            [ngClass]="{'warning-alert': this.leanPassFlowStruct.medicPassStruct.ticketPerHourTypeAlert == warningAlert, 
                                                        'warning-critic': this.leanPassFlowStruct.medicPassStruct.ticketPerHourTypeAlert == criticalAlert}"
                                            [matTooltip]="this.leanPassFlowStruct.medicPassStruct.ticketPerHourTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                            aria-hidden="false" 
                                            aria-label="warning">warning
                                        </mat-icon>
                                    </div>
                                    <div class="card-per-hour line-gray-left col-7">
                                        <div class="accordion-info">
                                            <div class="info" matTooltip="Total de atendimentos realizados neste serviço, na última hora.">
                                                <span class="data">{{this.leanPassFlowStruct.medicPassStruct.attendancePerHour}}</span>
                                                <span>Atendimento/hora</span>
                                            </div>
                                        </div>
                                        <mat-icon class="lean-icon"><img src="/assets/icons/lean-panel/medic_time.png" aria-hidden="false" aria-label="vazio"></mat-icon>
                                        <mat-icon *ngIf="this.leanPassFlowStruct.medicPassStruct.attendancePerHourTypeAlert != normalAlert"
                                            class="warning-icon" 
                                            [ngClass]="{'warning-alert': this.leanPassFlowStruct.medicPassStruct.attendancePerHourTypeAlert == warningAlert, 
                                                        'warning-critic': this.leanPassFlowStruct.medicPassStruct.attendancePerHourTypeAlert == criticalAlert}"
                                            [matTooltip]="this.leanPassFlowStruct.medicPassStruct.attendancePerHourTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                            aria-hidden="false" 
                                            aria-label="warning">warning
                                        </mat-icon>
                                    </div>
                                </div>
                                <div *ngIf="this.leanPassFlowStruct.medicPassStruct.listSectorByModuleStruct.length > 0">
                                    <div class="head-flow-sector">
                                        <span class="time-flow-sector">Duração</span>
                                        <span class="time-flow-sector">Aguardando</span>
                                    </div>
                                    <div class="flow-sector-container">
                                        <div class="loop" *ngFor="let item of this.leanPassFlowStruct.medicPassStruct.listSectorByModuleStruct">
                                            <mat-icon class="warning-icon" 
                                                [ngClass]="{'icon-visible': item.averageDurationBySectorTypeAlert != normalAlert,
                                                            'icon-invisible': item.averageDurationBySectorTypeAlert == normalAlert,    
                                                            'warning-alert': item.averageDurationBySectorTypeAlert == warningAlert, 
                                                            'warning-critic': item.averageDurationBySectorTypeAlert == criticalAlert}" 
                                                [matTooltip]="item.averageDurationBySectorTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                                aria-hidden="false" 
                                                aria-label="warning">warning
                                            </mat-icon>
                                            <div class="item-flow-sector">
                                                <div class="time-flow-sector medic-flow" matTooltip="Tempo médio de duração do primeiro atendimento médico neste setor, nas últimas 4h.">
                                                    <span>{{item.averageDurationBySector}}</span>
                                                </div>
                                                <div class="name-flow-sector medic-flow">
                                                    <span>{{item.sectorName}}</span>
                                                </div>
                                                
                                                <div class="wait-flow-sector" matTooltip="Total de pacientes aguardando o primeiro atendimento médico neste setor.">
                                                    <div class="bar medic-flow"
                                                        [ngStyle]="{'width': (100*item.patientAwaitingBySector/this.leanPassFlowStruct.medicPassStruct.patientWaiting) + '%'}">
                                                    </div>
                                                    <div class="count">
                                                        {{item.patientAwaitingBySector}}
                                                    </div>
                                                </div>
                                            </div>
                                            <mat-icon class="warning-icon" 
                                                [ngClass]="{'icon-visible': item.patientAwaitingBySectorTypeAlert != normalAlert,
                                                            'icon-invisible': item.patientAwaitingBySectorTypeAlert == normalAlert,
                                                            'warning-alert': item.patientAwaitingBySectorTypeAlert == warningAlert, 
                                                            'warning-critic': item.patientAwaitingBySectorTypeAlert == criticalAlert}" 
                                                [matTooltip]="item.patientAwaitingBySectorTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                                aria-hidden="false" 
                                                aria-label="warning">warning
                                            </mat-icon>   
                                        </div>
                                    </div>
                                </div>

                                <div class="empty-list-sector" *ngIf="!this.leanPassFlowStruct.medicPassStruct.listSectorByModuleStruct.length > 0">
                                    <img src="/assets/images/file-searching.gif" aria-hidden="false" aria-label="vazio">
                                </div>
                            
                            </mat-expansion-panel>
    
                            <mat-expansion-panel [expanded]="true">
                                <mat-expansion-panel-header class="accordion-head">
                                    <div class="module">
                                        <mat-icon class="lean-icon"><img src="/assets/icons/lean-panel/multiprofessional.png" aria-hidden="false" aria-label="vazio"></mat-icon>
                                        <span>Multiprofissional</span>
                                    </div>
                                    <div class="accordion-info double-line-pink">
                                        <div class="info" matTooltip="Total de pacientes aguardando o primeiro atendimento multiprofissional.">
                                            <span>Aguardando</span>
                                            <span class="data">{{this.leanPassFlowStruct.multiProfessionalPassStruct.patientWaiting}}</span>
                                        </div>
                                        <mat-icon *ngIf="this.leanPassFlowStruct.multiProfessionalPassStruct.patientWaitingTypeAlert != normalAlert"
                                            class="warning-icon" 
                                            [ngClass]="{'warning-alert': this.leanPassFlowStruct.multiProfessionalPassStruct.patientWaitingTypeAlert == warningAlert, 
                                                        'warning-critic': this.leanPassFlowStruct.multiProfessionalPassStruct.patientWaitingTypeAlert == criticalAlert}" 
                                            [matTooltip]="this.leanPassFlowStruct.multiProfessionalPassStruct.patientWaitingTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                            aria-hidden="false" 
                                            aria-label="warning">warning
                                        </mat-icon>
                                    </div>
                                    <div class="accordion-info">
                                        <div class="info" matTooltip="Tempo médio de duração do primeiro atendimento multiprofissional, nas últimas 4h.">
                                            <span>Duração</span>
                                            <span class="data">{{this.leanPassFlowStruct.multiProfessionalPassStruct.averagePatientDurationTime}}</span>
                                        </div>
                                        <mat-icon *ngIf="this.leanPassFlowStruct.multiProfessionalPassStruct.averagePatientDurationTimeTypeAlert != normalAlert"
                                            class="warning-icon" 
                                            [ngClass]="{'warning-alert': this.leanPassFlowStruct.multiProfessionalPassStruct.averagePatientDurationTimeTypeAlert == warningAlert, 
                                                        'warning-critic': this.leanPassFlowStruct.multiProfessionalPassStruct.averagePatientDurationTimeTypeAlert == criticalAlert}" 
                                            [matTooltip]="this.leanPassFlowStruct.multiProfessionalPassStruct.averagePatientDurationTimeTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                            aria-hidden="false" 
                                            aria-label="warning">warning
                                        </mat-icon>
                                    </div>
                                </mat-expansion-panel-header>
                                <div class="info-per-hour">
                                    <div class="card-per-hour col-5">
                                        <mat-icon class="lean-icon"><img src="/assets/icons/lean-panel/senhas.png" aria-hidden="false" aria-label="vazio"></mat-icon>
                                        <div class="accordion-info">
                                            <div class="info" matTooltip="Total de senhas geradas para este serviço, na última hora.">
                                                <span class="data">{{this.leanPassFlowStruct.multiProfessionalPassStruct.ticketPerHour}}</span>
                                                <span>Senhas/hora</span>
                                            </div>
                                        </div>
                                        <mat-icon *ngIf="this.leanPassFlowStruct.multiProfessionalPassStruct.ticketPerHourTypeAlert != normalAlert"
                                            class="warning-icon" 
                                            [ngClass]="{'warning-alert': this.leanPassFlowStruct.multiProfessionalPassStruct.ticketPerHourTypeAlert == warningAlert, 
                                                        'warning-critic': this.leanPassFlowStruct.multiProfessionalPassStruct.ticketPerHourTypeAlert == criticalAlert}"
                                            [matTooltip]="this.leanPassFlowStruct.multiProfessionalPassStruct.ticketPerHourTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                            aria-hidden="false" 
                                            aria-label="warning">warning
                                        </mat-icon>
                                    </div>
                                    <div class="card-per-hour line-gray-left col-7">
                                        <div class="accordion-info">
                                            <div class="info" matTooltip="Total de atendimentos realizados neste serviço, na última hora.">
                                                <span class="data">{{this.leanPassFlowStruct.multiProfessionalPassStruct.attendancePerHour}}</span>
                                                <span>Atendimento/hora</span>
                                            </div>
                                        </div>
                                        <mat-icon class="lean-icon"><img src="/assets/icons/lean-panel/multiprofessioanal_wait.png" aria-hidden="false" aria-label="vazio"></mat-icon>
                                        <mat-icon *ngIf="this.leanPassFlowStruct.multiProfessionalPassStruct.attendancePerHourTypeAlert != normalAlert"
                                            class="warning-icon" 
                                            [ngClass]="{'warning-alert': this.leanPassFlowStruct.multiProfessionalPassStruct.attendancePerHourTypeAlert == warningAlert, 
                                                        'warning-critic': this.leanPassFlowStruct.multiProfessionalPassStruct.attendancePerHourTypeAlert == criticalAlert}"
                                            [matTooltip]="this.leanPassFlowStruct.multiProfessionalPassStruct.attendancePerHourTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                            aria-hidden="false" 
                                            aria-label="warning">warning
                                        </mat-icon>
                                    </div>
                                </div>
                                <div  *ngIf="this.leanPassFlowStruct.multiProfessionalPassStruct.listSectorByModuleStruct.length > 0">
                                    <div class="head-flow-sector">
                                        <span class="time-flow-sector">Duração</span>
                                        <span class="time-flow-sector">Aguardando</span>
                                    </div>

                                    <div class="flow-sector-container">
                                        <div class="loop" *ngFor="let item of this.leanPassFlowStruct.multiProfessionalPassStruct.listSectorByModuleStruct">
                                            <mat-icon class="warning-icon" 
                                                [ngClass]="{'icon-visible': item.averageDurationBySectorTypeAlert != normalAlert,
                                                            'icon-invisible': item.averageDurationBySectorTypeAlert == normalAlert,    
                                                            'warning-alert': item.averageDurationBySectorTypeAlert == warningAlert, 
                                                            'warning-critic': item.averageDurationBySectorTypeAlert == criticalAlert}" 
                                                [matTooltip]="item.averageDurationBySectorTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                                aria-hidden="false" 
                                                aria-label="warning">warning
                                            </mat-icon>
                                            <div class="item-flow-sector">
                                                <div class="time-flow-sector multi-professional-flow" matTooltip="Tempo médio de duração do primeiro atendimento multiprofissional neste setor, nas últimas 4h.">
                                                    <span>{{item.averageDurationBySector}}</span>
                                                </div>
                                                <div class="name-flow-sector multi-professional-flow">
                                                    <span>{{item.sectorName}}</span>
                                                </div>
                                                
                                                <div class="wait-flow-sector" matTooltip="Total de pacientes aguardando o primeiro atendimento multiprofissional neste setor.">
                                                    <div class="bar multi-professional-flow"
                                                        [ngStyle]="{'width': (100*item.patientAwaitingBySector/this.leanPassFlowStruct.multiProfessionalPassStruct.patientWaiting) + '%'}">
                                                    </div>
                                                    <div class="count">
                                                        {{item.patientAwaitingBySector}}
                                                    </div>
                                                </div>
                                            </div>
                                            <mat-icon class="warning-icon" 
                                                [ngClass]="{'icon-visible': item.patientAwaitingBySectorTypeAlert != normalAlert,
                                                            'icon-invisible': item.patientAwaitingBySectorTypeAlert == normalAlert,
                                                            'warning-alert': item.patientAwaitingBySectorTypeAlert == warningAlert, 
                                                            'warning-critic': item.patientAwaitingBySectorTypeAlert == criticalAlert}" 
                                                [matTooltip]="item.patientAwaitingBySectorTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                                aria-hidden="false" 
                                                aria-label="warning">warning
                                            </mat-icon>   
                                        </div>
                                    </div>
                                </div>

                                <div class="empty-list-sector" *ngIf="!this.leanPassFlowStruct.multiProfessionalPassStruct.listSectorByModuleStruct.length > 0">
                                    <img src="/assets/images/file-searching.gif" aria-hidden="false" aria-label="vazio">
                                </div>
                            
                            </mat-expansion-panel>
    
                            <mat-expansion-panel [expanded]="true">
                                <mat-expansion-panel-header class="accordion-head">
                                    <div class="module">
                                        <mat-icon class="lean-icon"><img src="/assets/icons/lean-panel/sadt.png" aria-hidden="false" aria-label="vazio"></mat-icon>
                                        <span>SADT</span>
                                    </div>
                                    <div class="accordion-info double-line-pink">
                                        <div class="info" matTooltip="Total de pacientes aguardando atendimento em SADT.">
                                            <span>Aguardando</span>
                                            <span class="data">{{this.leanPassFlowStruct.sadtPassStruct.patientWaiting}}</span>
                                        </div>
                                        <mat-icon *ngIf="this.leanPassFlowStruct.sadtPassStruct.patientWaitingTypeAlert != normalAlert"
                                            class="warning-icon" 
                                            [ngClass]="{'warning-alert': this.leanPassFlowStruct.sadtPassStruct.patientWaitingTypeAlert == warningAlert, 
                                                        'warning-critic': this.leanPassFlowStruct.sadtPassStruct.patientWaitingTypeAlert == criticalAlert}" 
                                            [matTooltip]="this.leanPassFlowStruct.sadtPassStruct.patientWaitingTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                            aria-hidden="false" 
                                            aria-label="warning">warning
                                        </mat-icon>
                                    </div>
                                    <div class="accordion-info">
                                        <div class="info" matTooltip="Tempo médio de espera para atendimento em SADT, nas últimas 4h.">
                                            <span>Espera</span>
                                            <span class="data">{{this.leanPassFlowStruct.sadtPassStruct.averagePatientWaitingTime}}</span>
                                        </div>
                                        <mat-icon *ngIf="this.leanPassFlowStruct.sadtPassStruct.averagePatientWaitingTimeTypeAlert != normalAlert"
                                            class="warning-icon" 
                                            [ngClass]="{'warning-alert': this.leanPassFlowStruct.sadtPassStruct.averagePatientWaitingTimeTypeAlert == warningAlert, 
                                                        'warning-critic': this.leanPassFlowStruct.sadtPassStruct.averagePatientWaitingTimeTypeAlert == criticalAlert}" 
                                            [matTooltip]="this.leanPassFlowStruct.sadtPassStruct.averagePatientWaitingTimeTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                            aria-hidden="false" 
                                            aria-label="warning">warning
                                        </mat-icon>
                                    </div>
                                </mat-expansion-panel-header>
                                <div class="info-per-hour">
                                    <div class="card-per-hour col-5">
                                        <mat-icon class="lean-icon"><img src="/assets/icons/lean-panel/senhas.png" aria-hidden="false" aria-label="vazio"></mat-icon>
                                        <div class="accordion-info">
                                            <div class="info" matTooltip="Total de senhas geradas para este serviço, na última hora.">
                                                <span class="data">{{this.leanPassFlowStruct.sadtPassStruct.ticketPerHour}}</span>
                                                <span>Senhas/hora</span>
                                            </div>
                                        </div>
                                        <mat-icon *ngIf="this.leanPassFlowStruct.sadtPassStruct.ticketPerHourTypeAlert != normalAlert"
                                            class="warning-icon" 
                                            [ngClass]="{'warning-alert': this.leanPassFlowStruct.sadtPassStruct.ticketPerHourTypeAlert == warningAlert, 
                                                        'warning-critic': this.leanPassFlowStruct.sadtPassStruct.ticketPerHourTypeAlert == criticalAlert}"
                                            [matTooltip]="this.leanPassFlowStruct.sadtPassStruct.ticketPerHourTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                            aria-hidden="false" 
                                            aria-label="warning">warning
                                        </mat-icon>
                                    </div>
                                    <div class="card-per-hour line-gray-left col-7">
                                        <div class="accordion-info">
                                            <div class="info" matTooltip="Total de atendimentos realizados neste serviço, na última hora.">
                                                <span class="data">{{this.leanPassFlowStruct.sadtPassStruct.attendancePerHour}}</span>
                                                <span>Atendimento/hora</span>
                                            </div>
                                        </div>
                                        <mat-icon class="lean-icon"><img src="/assets/icons/lean-panel/sadt_wait.png" aria-hidden="false" aria-label="vazio"></mat-icon>
                                        <mat-icon *ngIf="this.leanPassFlowStruct.sadtPassStruct.attendancePerHourTypeAlert != normalAlert"
                                            class="warning-icon" 
                                            [ngClass]="{'warning-alert': this.leanPassFlowStruct.sadtPassStruct.attendancePerHourTypeAlert == warningAlert, 
                                                        'warning-critic': this.leanPassFlowStruct.sadtPassStruct.attendancePerHourTypeAlert == criticalAlert}"
                                            [matTooltip]="this.leanPassFlowStruct.sadtPassStruct.attendancePerHourTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                            aria-hidden="false" 
                                            aria-label="warning">warning
                                        </mat-icon>
                                    </div>
                                </div>
                                <div *ngIf="this.leanPassFlowStruct.sadtPassStruct.listSectorByModuleStruct.length > 0">
                                    <div class="head-flow-sector">
                                        <span class="time-flow-sector">Duração</span>
                                        <span class="time-flow-sector">Aguardando</span>
                                    </div>
                                    <div class="flow-sector-container">
                                        <div class="loop" *ngFor="let item of this.leanPassFlowStruct.sadtPassStruct.listSectorByModuleStruct">
                                            <mat-icon class="warning-icon" 
                                                [ngClass]="{'icon-visible': item.averageDurationBySectorTypeAlert != normalAlert,
                                                            'icon-invisible': item.averageDurationBySectorTypeAlert == normalAlert,    
                                                            'warning-alert': item.averageDurationBySectorTypeAlert == warningAlert, 
                                                            'warning-critic': item.averageDurationBySectorTypeAlert == criticalAlert}" 
                                                [matTooltip]="item.averageDurationBySectorTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                                aria-hidden="false" 
                                                aria-label="warning">warning
                                            </mat-icon>
                                            <div class="item-flow-sector">
                                                <div class="time-flow-sector sadt-flow" matTooltip="Tempo médio de duração do atendimento em SADT nete setor, nas últimas 4h.">
                                                    <span>{{item.averageDurationBySector}}</span>
                                                </div>
                                                <div class="name-flow-sector sadt-flow">
                                                    <span>{{item.sectorName}}</span>
                                                </div>
                                                <div class="wait-flow-sector" matTooltip="Total de pacientes aguardando atendimento em SADT neste setor.">
                                                    <div class="bar sadt-flow"
                                                        [ngStyle]="{'width': (100*item.patientAwaitingBySector/this.leanPassFlowStruct.sadtPassStruct.patientWaiting) + '%'}">
                                                    </div>
                                                    <div class="count">
                                                        {{item.patientAwaitingBySector}}
                                                    </div>
                                                </div>
                                            </div>
                                            <mat-icon class="warning-icon" 
                                                [ngClass]="{'icon-visible': item.patientAwaitingBySectorTypeAlert != normalAlert,
                                                            'icon-invisible': item.patientAwaitingBySectorTypeAlert == normalAlert,
                                                            'warning-alert': item.patientAwaitingBySectorTypeAlert == warningAlert, 
                                                            'warning-critic': item.patientAwaitingBySectorTypeAlert == criticalAlert}" 
                                                [matTooltip]="item.patientAwaitingBySectorTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                                aria-hidden="false" 
                                                aria-label="warning">warning
                                            </mat-icon>   
                                        </div>
                                    </div>
                                </div>

                                <div class="empty-list-sector" *ngIf="!this.leanPassFlowStruct.sadtPassStruct.listSectorByModuleStruct.length > 0">
                                    <img src="/assets/images/file-searching.gif" aria-hidden="false" aria-label="vazio">
                                </div>
                            
                            </mat-expansion-panel>
    
                            <mat-expansion-panel [expanded]="true">
                                <mat-expansion-panel-header class="accordion-head">
                                    <div class="module">
                                        <mat-icon class="lean-icon"><img src="/assets/icons/lean-panel/leito.png" aria-hidden="false" aria-label="vazio"></mat-icon>
                                        <span>Observação</span>
                                    </div>
                                    <div class="accordion-info double-line-pink">
                                        <div class="info" matTooltip="Total de pacientes aguardando atendimento em Observação.">
                                            <span>Aguardando</span>
                                            <span class="data">{{this.leanPassFlowStruct.observationPassStruct.patientWaiting}}</span>
                                        </div>
                                        <mat-icon *ngIf="this.leanPassFlowStruct.observationPassStruct.patientWaitingTypeAlert != normalAlert"
                                            class="warning-icon" 
                                            [ngClass]="{'warning-alert': this.leanPassFlowStruct.observationPassStruct.patientWaitingTypeAlert == warningAlert, 
                                                        'warning-critic': this.leanPassFlowStruct.observationPassStruct.patientWaitingTypeAlert == criticalAlert}" 
                                            [matTooltip]="this.leanPassFlowStruct.observationPassStruct.patientWaitingTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                            aria-hidden="false" 
                                            aria-label="warning">warning
                                        </mat-icon>
                                    </div>
                                    <div class="accordion-info">
                                        <div class="info" matTooltip="Tempo médio de espera para atendimento em Observação, nas últimas 4h.">
                                            <span>Espera</span>
                                            <span class="data">{{this.leanPassFlowStruct.observationPassStruct.averagePatientWaitingTime}}</span>
                                        </div>
                                        <mat-icon *ngIf="this.leanPassFlowStruct.observationPassStruct.averagePatientWaitingTimeTypeAlert != normalAlert"
                                            class="warning-icon" 
                                            [ngClass]="{'warning-alert': this.leanPassFlowStruct.observationPassStruct.averagePatientWaitingTimeTypeAlert == warningAlert, 
                                                        'warning-critic': this.leanPassFlowStruct.observationPassStruct.averagePatientWaitingTimeTypeAlert == criticalAlert}" 
                                            [matTooltip]="this.leanPassFlowStruct.observationPassStruct.averagePatientWaitingTimeTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                            aria-hidden="false" 
                                            aria-label="warning">warning
                                        </mat-icon>
                                    </div>
                                </mat-expansion-panel-header>
                                <div *ngIf="this.leanPassFlowStruct.observationPassStruct.listSectorByModuleStruct.length > 0">
                                    <div class="head-flow-sector">
                                        <span class="time-flow-sector">Duração</span>
                                        <span class="time-flow-sector">Aguardando</span>
                                    </div>
                                    <div class="flow-sector-container">
                                        <div class="loop" *ngFor="let item of this.leanPassFlowStruct.observationPassStruct.listSectorByModuleStruct">
                                            <mat-icon class="warning-icon" 
                                                [ngClass]="{'icon-visible': item.averageDurationBySectorTypeAlert != normalAlert,
                                                            'icon-invisible': item.averageDurationBySectorTypeAlert == normalAlert,    
                                                            'warning-alert': item.averageDurationBySectorTypeAlert == warningAlert, 
                                                            'warning-critic': item.averageDurationBySectorTypeAlert == criticalAlert}" 
                                                [matTooltip]="item.averageDurationBySectorTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                                aria-hidden="false" 
                                                aria-label="warning">warning
                                            </mat-icon>
                                            <div class="item-flow-sector">
                                                <div class="time-flow-sector observation-flow" matTooltip="Tempo médio de duração do atendimento em Observação neste setor, nas últimas 4h.">
                                                    <span>{{item.averageDurationBySector}}</span>
                                                </div>
                                                <div class="name-flow-sector observation-flow">
                                                    <span>{{item.sectorName}}</span>
                                                </div>
                                                
                                                <div class="wait-flow-sector" matTooltip="Total de pacientes aguardando atendimento em Observação neste setor.">
                                                    <div class="bar observation-flow"
                                                        [ngStyle]="{'width': (100*item.patientAwaitingBySector/this.leanPassFlowStruct.observationPassStruct.patientWaiting) + '%'}">
                                                    </div>
                                                    <div class="count">
                                                        {{item.patientAwaitingBySector}}
                                                    </div>
                                                </div>
                                            </div>
                                            <mat-icon class="warning-icon" 
                                                [ngClass]="{'icon-visible': item.patientAwaitingBySectorTypeAlert != normalAlert,
                                                            'icon-invisible': item.patientAwaitingBySectorTypeAlert == normalAlert,
                                                            'warning-alert': item.patientAwaitingBySectorTypeAlert == warningAlert, 
                                                            'warning-critic': item.patientAwaitingBySectorTypeAlert == criticalAlert}" 
                                                [matTooltip]="item.patientAwaitingBySectorTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                                aria-hidden="false" 
                                                aria-label="warning">warning
                                            </mat-icon>   
                                        </div>
                                    </div>
                                </div>

                                <div class="empty-list-sector" *ngIf="!this.leanPassFlowStruct.observationPassStruct.listSectorByModuleStruct.length > 0">
                                    <img src="/assets/images/file-searching.gif" aria-hidden="false" aria-label="vazio">
                                </div>
                            
                            </mat-expansion-panel>
    
                            <mat-expansion-panel [expanded]="true">
                                <mat-expansion-panel-header class="accordion-head">
                                    <div class="module">
                                        <mat-icon class="lean-icon"><img src="/assets/icons/lean-panel/reavaliacao.png" aria-hidden="false" aria-label="vazio"></mat-icon>
                                        <span>Reavaliação</span>
                                    </div>
                                    <div class="accordion-info double-line-pink">
                                        <div class="info" matTooltip="Total de pacientes aguardando reavaliação.">
                                            <span>Aguardando</span>
                                            <span class="data">{{this.leanPassFlowStruct.revaluationPassStruct.patientWaiting}}</span>
                                        </div>
                                        <mat-icon *ngIf="this.leanPassFlowStruct.revaluationPassStruct.patientWaitingTypeAlert != normalAlert"
                                            class="warning-icon" 
                                            [ngClass]="{'warning-alert': this.leanPassFlowStruct.revaluationPassStruct.patientWaitingTypeAlert == warningAlert, 
                                                        'warning-critic': this.leanPassFlowStruct.revaluationPassStruct.patientWaitingTypeAlert == criticalAlert}" 
                                            [matTooltip]="this.leanPassFlowStruct.revaluationPassStruct.patientWaitingTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                            aria-hidden="false" 
                                            aria-label="warning">warning
                                        </mat-icon>
                                    </div>
                                    <div class="accordion-info">
                                        <div class="info" matTooltip="Tempo médio de espera para reavaliação, nas últimas 4h..">
                                            <span>Espera</span>
                                            <span class="data">{{this.leanPassFlowStruct.revaluationPassStruct.averagePatientWaitingTime}}</span>
                                        </div>
                                        <mat-icon *ngIf="this.leanPassFlowStruct.revaluationPassStruct.averagePatientWaitingTimeTypeAlert != normalAlert"
                                            class="warning-icon" 
                                            [ngClass]="{'warning-alert': this.leanPassFlowStruct.revaluationPassStruct.averagePatientWaitingTimeTypeAlert == warningAlert, 
                                                        'warning-critic': this.leanPassFlowStruct.revaluationPassStruct.averagePatientWaitingTimeTypeAlert == criticalAlert}" 
                                            [matTooltip]="this.leanPassFlowStruct.revaluationPassStruct.averagePatientWaitingTimeTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                            aria-hidden="false" 
                                            aria-label="warning">warning
                                        </mat-icon>
                                    </div>
                                </mat-expansion-panel-header>

                                <div class="info-per-hour">
                                    <div class="card-per-hour col-5">
                                        <mat-icon class="lean-icon"><img src="/assets/icons/lean-panel/senhas.png" aria-hidden="false" aria-label="vazio"></mat-icon>
                                        <div class="accordion-info">
                                            <div class="info" matTooltip="Total de senhas geradas para este serviço, na última hora.">
                                                <span class="data">{{this.leanPassFlowStruct.revaluationPassStruct.ticketPerHour}}</span>
                                                <span>Senhas/hora</span>
                                            </div>
                                        </div>
                                        <mat-icon *ngIf="this.leanPassFlowStruct.revaluationPassStruct.ticketPerHourTypeAlert != normalAlert"
                                            class="warning-icon" 
                                            [ngClass]="{'warning-alert': this.leanPassFlowStruct.revaluationPassStruct.ticketPerHourTypeAlert == warningAlert, 
                                                        'warning-critic': this.leanPassFlowStruct.revaluationPassStruct.ticketPerHourTypeAlert == criticalAlert}"
                                            [matTooltip]="this.leanPassFlowStruct.revaluationPassStruct.ticketPerHourTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                            aria-hidden="false" 
                                            aria-label="warning">warning
                                        </mat-icon>
                                    </div>
                                    <div class="card-per-hour line-gray-left col-7">
                                        <div class="accordion-info">
                                            <div class="info" matTooltip="Total de atendimentos realizados neste serviço, na última hora.">
                                                <span class="data">{{this.leanPassFlowStruct.revaluationPassStruct.attendancePerHour}}</span>
                                                <span>Atendimento/hora</span>
                                            </div>
                                        </div>
                                        <mat-icon class="lean-icon"><img src="/assets/icons/lean-panel/medic_time.png" aria-hidden="false" aria-label="vazio"></mat-icon>
                                        <mat-icon *ngIf="this.leanPassFlowStruct.revaluationPassStruct.attendancePerHourTypeAlert != normalAlert"
                                            class="warning-icon" 
                                            [ngClass]="{'warning-alert': this.leanPassFlowStruct.revaluationPassStruct.attendancePerHourTypeAlert == warningAlert, 
                                                        'warning-critic': this.leanPassFlowStruct.revaluationPassStruct.attendancePerHourTypeAlert == criticalAlert}"
                                            [matTooltip]="this.leanPassFlowStruct.revaluationPassStruct.attendancePerHourTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                            aria-hidden="false" 
                                            aria-label="warning">warning
                                        </mat-icon>
                                    </div>
                                </div>
                                <div *ngIf="this.leanPassFlowStruct.revaluationPassStruct.listSectorByModuleStruct.length > 0">
                                    <div class="head-flow-sector">
                                        <span class="time-flow-sector">Duração</span>
                                        <span class="time-flow-sector">Aguardando</span>
                                    </div>
                                    <div class="flow-sector-container">
                                        <div class="loop" *ngFor="let item of this.leanPassFlowStruct.revaluationPassStruct.listSectorByModuleStruct">
                                            <mat-icon class="warning-icon" 
                                                [ngClass]="{'icon-visible': item.averageDurationBySectorTypeAlert != normalAlert,
                                                            'icon-invisible': item.averageDurationBySectorTypeAlert == normalAlert,    
                                                            'warning-alert': item.averageDurationBySectorTypeAlert == warningAlert, 
                                                            'warning-critic': item.averageDurationBySectorTypeAlert == criticalAlert}" 
                                                [matTooltip]="item.averageDurationBySectorTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                                aria-hidden="false" 
                                                aria-label="warning">warning
                                            </mat-icon>
                                            <div class="item-flow-sector">
                                                <div class="time-flow-sector medic-flow" matTooltip="Tempo médio de duração da reavaliação neste setor, nas últimas 4h.">
                                                    <span>{{item.averageDurationBySector}}</span>
                                                </div>
                                                <div class="name-flow-sector medic-flow">
                                                    <span>{{item.sectorName}}</span>
                                                </div>
                                                
                                                <div class="wait-flow-sector" matTooltip="Total de pacientes aguardando reavaliação neste setor.">
                                                    <div class="bar medic-flow"
                                                        [ngStyle]="{'width': (100*item.patientAwaitingBySector/this.leanPassFlowStruct.revaluationPassStruct.patientWaiting) + '%'}">
                                                    </div>
                                                    <div class="count">
                                                        {{item.patientAwaitingBySector}}
                                                    </div>
                                                </div>
                                            </div>
                                            <mat-icon class="warning-icon" 
                                                [ngClass]="{'icon-visible': item.patientAwaitingBySectorTypeAlert != normalAlert,
                                                            'icon-invisible': item.patientAwaitingBySectorTypeAlert == normalAlert,
                                                            'warning-alert': item.patientAwaitingBySectorTypeAlert == warningAlert, 
                                                            'warning-critic': item.patientAwaitingBySectorTypeAlert == criticalAlert}" 
                                                [matTooltip]="item.patientAwaitingBySectorTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                                aria-hidden="false" 
                                                aria-label="warning">warning
                                            </mat-icon>   
                                        </div>
                                    </div>
                                </div>

                                <div class="empty-list-sector" *ngIf="!this.leanPassFlowStruct.revaluationPassStruct.listSectorByModuleStruct.length > 0">
                                    <img src="/assets/images/file-searching.gif" aria-hidden="false" aria-label="vazio">
                                </div>
                            
                            </mat-expansion-panel>
    
                        </mat-accordion>
                    </div>
                </div>
                <div class="flow-content" [ngClass]="{'slide': isMobile, 'col-4': !isMobile}">
                    <div class="flow-chart line-gray-left">
                        <ngx-skeleton-loader *ngIf="isFirstLoading"
                            animation="progress-dark"
                            [theme]="{ 
                                width: '190px', 
                                height: '40px',
                                'margin-bottom': '0px' }">
                        </ngx-skeleton-loader>
                        <div *ngIf="!isFirstLoading" class="data-card">
                            <h1>Saída</h1>
                        </div>
                        <div class="flow-value">
                            <div class="flow-cards-content">
                                <ngx-skeleton-loader *ngIf="isFirstLoading"
                                    animation="progress-dark"
                                    [theme]="{ 
                                        width: '60px', 
                                        height: '70px',
                                        'margin': '1px',
                                        position: 'relative',   
                                        'top': '-23px',
                                        'left': '-2px',
                                        'z-index': '1', }">
                                </ngx-skeleton-loader>
                                <ngx-skeleton-loader *ngIf="isFirstLoading"
                                    animation="progress-dark"
                                    [theme]="{ 
                                        width: '60px', 
                                        height: '70px',
                                        'margin': '1px',
                                        'position': 'relative',
                                        'top': '6px',
                                        'left': '-52px', }">
                                </ngx-skeleton-loader>
                                <div *ngIf="!isFirstLoading" class="total-patients" matTooltip="Pacientes que, no momento, estão em alguma fase do processo de saída.">
                                    <mat-icon class="lean-icon"><img src="/assets/icons/lean-panel/paciente.png" aria-hidden="false" aria-label="vazio"></mat-icon>
                                    <span class="title">Pacientes</span>
                                    <span class="data"><b>{{this.leanOutputFlowStruct.totalPatients}}</b></span>
                                    <mat-icon *ngIf="this.leanOutputFlowStruct.totalPatientTypeAlert != normalAlert"
                                        class="warning-icon warning-critic" 
                                        [ngClass]="{'warning-alert': this.leanOutputFlowStruct.totalPatientTypeAlert == warningAlert, 
                                                    'warning-critic': this.leanOutputFlowStruct.totalPatientTypeAlert == criticalAlert}" 
                                        [matTooltip]="this.leanOutputFlowStruct.totalPatientTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                        aria-hidden="false" 
                                        aria-label="warning">warning
                                    </mat-icon>
                                </div>
                                <div *ngIf="!isFirstLoading" class="total-evasion" matTooltip="% de pacientes que evadiram em alguma fase do processo de saída.">
                                    <mat-icon class="lean-icon">directions_run</mat-icon>
                                    <span class="title">Evasões</span>
                                    <span class="data"><b>{{this.leanOutputFlowStruct.totalEvasion}}</b></span>
                                    <mat-icon *ngIf="this.leanOutputFlowStruct.totalEvasionTypeAlert != normalAlert"
                                        class="warning-icon warning-critic" 
                                        [ngClass]="{'warning-alert': this.leanOutputFlowStruct.totalEvasionTypeAlert == warningAlert, 
                                                    'warning-critic': this.leanOutputFlowStruct.totalEvasionTypeAlert == criticalAlert}" 
                                        [matTooltip]="this.leanOutputFlowStruct.totalEvasionTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                        aria-hidden="false" 
                                        aria-label="warning">warning
                                    </mat-icon>
                                </div>
                            </div>

                            <ngx-skeleton-loader *ngIf="isFirstLoading"
                                animation="progress-dark"
                                [theme]="{ 
                                    width: '190px', 
                                    height: '130px',
                                    'margin': '10px 50px 18px 30px' }">
                            </ngx-skeleton-loader>
                            <div *ngIf="!isFirstLoading" class="gauge-chart">
                                <app-gauge-chart [title]="'Decisão saída'" 
                                                [value]="this.leanOutputFlowStruct.speedometerGraphStruct.percentage" 
                                                [showValue]="this.leanOutputFlowStruct.averageMedicalDischargeDecision" 
                                                [chartLimits]="this.flowOutputLimits"
                                                [textTooltip]="'Tempo médio desde a chegada até o início do atendimento médico, nas últimas 4 horas.'">
                                </app-gauge-chart>
                            </div>
                        </div>
                        <div *ngIf="!isFirstLoading" class="center-ball"></div>
                    </div>
                    <div class="flow-exit">
                        <ngx-skeleton-loader *ngIf="isFirstLoading"
                            count="2"
                            animation="progress-dark"
                            [theme]="{ 
                                width: '100%', 
                                height: '60px',
                                'margin': '0px' }">
                        </ngx-skeleton-loader>
                        <mat-accordion *ngIf="!isFirstLoading" class="accordion" multi>
                            <mat-expansion-panel [expanded]="true">
                                <mat-expansion-panel-header class="accordion-head">
                                    <div class="module" matTooltip="Pacientes que receberem alta na Unidade, de hora em hora, nas últimas 12h">
                                        <mat-icon class="lean-icon"><img src="/assets/icons/lean-panel/alta.png" aria-hidden="false" aria-label="vazio"></mat-icon>
                                        <span>Alta</span>
                                    </div>
                                </mat-expansion-panel-header>
                            
                                <div *ngIf="this.barResults.length > 0">
                                    <div class="bar-chart">
                                        <app-loading-progress-bar *ngIf="isLoading"></app-loading-progress-bar>
                                        <ngx-charts-bar-vertical [scheme]="colorSchemeBar" [results]="barResults"
                                            [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend"
                                            [legendTitle]="legendTitle" [showXAxisLabel]="showXAxisLabel"
                                            [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel">
                                        </ngx-charts-bar-vertical>
                                    </div>
                                </div>
                                <div class="empty-list-sector" *ngIf="!this.barResults.length > 0">
                                    <img src="/assets/images/file-searching.gif" aria-hidden="false" aria-label="vazio">
                                </div>
                            
                            </mat-expansion-panel>
                            <mat-expansion-panel [expanded]="true">
                                <mat-expansion-panel-header class="accordion-head">
                                    <div class="module">
                                        <mat-icon class="lean-icon"><img src="/assets/icons/lean-panel/internacao.png" aria-hidden="false" aria-label="vazio"></mat-icon>
                                        <span>Internação</span>
                                    </div>
                                    <div class="accordion-info double-line-pink">
                                        <div class="info" matTooltip="Quantidade de pacientes aguardando internação no momento.">
                                            <span>Aguardando</span>
                                            <span class="data">{{this.leanOutputFlowStruct.hospitalizationOutputStruct.totalPatientsWaitingHospitalization}}</span>
                                        </div>
                                        <mat-icon *ngIf="this.leanOutputFlowStruct.hospitalizationOutputStruct.totalPatientsWaitingHospitalizationTypeAlert != normalAlert"
                                            class="warning-icon" 
                                            [ngClass]="{'warning-alert': this.leanOutputFlowStruct.hospitalizationOutputStruct.totalPatientsWaitingHospitalizationTypeAlert == warningAlert, 
                                                        'warning-critic': this.leanOutputFlowStruct.hospitalizationOutputStruct.totalPatientsWaitingHospitalizationTypeAlert == criticalAlert}" 
                                            [matTooltip]="this.leanOutputFlowStruct.hospitalizationOutputStruct.totalPatientsWaitingHospitalizationTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                            aria-hidden="false" 
                                            aria-label="warning">warning
                                        </mat-icon>
                                    </div>
                                    <div class="accordion-info">
                                        <div class="info" matTooltip="Tempo médio de espera por alocação em leito.">
                                            <span>Espera</span>
                                            <span class="data">{{this.leanOutputFlowStruct.hospitalizationOutputStruct.averageWaitTimeAdmission}}</span>
                                        </div>
                                        <mat-icon *ngIf="this.leanOutputFlowStruct.hospitalizationOutputStruct.averageWaitTimeAdmissionTypeAlert != normalAlert"
                                            class="warning-icon" 
                                            [ngClass]="{'warning-alert': this.leanOutputFlowStruct.hospitalizationOutputStruct.averageWaitTimeAdmissionTypeAlert == warningAlert, 
                                                        'warning-critic': this.leanOutputFlowStruct.hospitalizationOutputStruct.averageWaitTimeAdmissionTypeAlert == criticalAlert}" 
                                            [matTooltip]="this.leanOutputFlowStruct.hospitalizationOutputStruct.averageWaitTimeAdmissionTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                            aria-hidden="false" 
                                            aria-label="warning">warning
                                        </mat-icon>
                                    </div>
                                </mat-expansion-panel-header>
                            
                                <div class="info-per-hour">
                                    <div class="card-per-hour col-5">
                                        <mat-icon class="lean-icon"><img src="/assets/icons/lean-panel/senhas.png" aria-hidden="false" aria-label="vazio"></mat-icon>
                                        <div class="accordion-info">
                                            <div class="info" matTooltip="Total de leitos ativos na Unidade.">
                                                <span class="data">{{this.leanOutputFlowStruct.hospitalizationOutputStruct.totalActiveBedsUnit}}</span>
                                                <span>Leitos totais</span>
                                            </div>
                                        </div>
                                        <mat-icon *ngIf="this.leanOutputFlowStruct.hospitalizationOutputStruct.totalActiveBedsUnitTypeAlert != normalAlert"
                                            class="warning-icon" 
                                            [ngClass]="{'warning-alert': this.leanOutputFlowStruct.hospitalizationOutputStruct.totalActiveBedsUnitTypeAlert == warningAlert, 
                                                        'warning-critic': this.leanOutputFlowStruct.hospitalizationOutputStruct.totalActiveBedsUnitTypeAlert == criticalAlert}" 
                                            [matTooltip]="this.leanOutputFlowStruct.hospitalizationOutputStruct.totalActiveBedsUnitTypeAlert == warningAlert? 'Alerta':'Alerta crítico'"
                                            aria-hidden="false" 
                                            aria-label="warning">warning
                                        </mat-icon>
                                    </div>
                                    <div class="card-per-hour line-gray-left col-7">
                                        <div class="accordion-info">
                                            <div class="info" matTooltip="Número total de leitos ocupados agora.">
                                                <span class="data">{{this.leanOutputFlowStruct.hospitalizationOutputStruct.totalOccupiedBedsUnit}}</span>
                                                <span>Leitos ocupados</span>
                                            </div>
                                        </div>
                                        <mat-icon class="lean-icon"><img src="/assets/icons/lean-panel/internacao_ocupado.png" aria-hidden="false" aria-label="vazio"></mat-icon>
                                        <mat-icon *ngIf="this.leanOutputFlowStruct.hospitalizationOutputStruct.totalOccupiedBedsUnitTypeAlert != normalAlert"
                                            class="warning-icon" 
                                            [ngClass]="{'warning-alert': this.leanOutputFlowStruct.hospitalizationOutputStruct.totalOccupiedBedsUnitTypeAlert == warningAlert, 
                                                        'warning-critic': this.leanOutputFlowStruct.hospitalizationOutputStruct.totalOccupiedBedsUnitTypeAlert == criticalAlert}" 
                                            [matTooltip]="this.leanOutputFlowStruct.hospitalizationOutputStruct.totalOccupiedBedsUnitTypeAlert == warningAlert ? 'Alerta':'Alerta crítico'"
                                            aria-hidden="false" 
                                            aria-label="warning">warning
                                        </mat-icon>
                                    </div>
                                </div>
                            
                            </mat-expansion-panel>
    
                        </mat-accordion>
                    </div>
                </div>
    
            </section>

            <app-empty-list *ngIf="isCallError && !isFirstLoading && !isLoading"></app-empty-list>
        </div>
    </div>