<div class="container-generic">

    <app-menu [isClassification]="true" [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'
        [hasSecundaryHeader]="true" [episode]="idEpisode"  [hasCachedModule]="true"
        [cachedModuleName]="'classification'" [painColorCode]="painColorCode" [discriminators]="discriminators" (restartMeasurers)="restartMeasurers()"></app-menu>
    
    <div [ngClass]="{'container-body-secundary-header': idEpisode !== null, 'container-body': idEpisode === null}">
        <a class="back-link" (click)="goBack()">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Usuários
        </a>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <div class="title-header">
                <h1 style="display: inline-block;">{{flowchartName}}</h1>
                <div class="buttons-header">
                    <div class="back-button-header">
                        <button mat-flat-button type="button" (click)="previousFirstPage()" color="accent" class="btn-back">
                            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Voltar
                        </button>
                    </div>
                    <div *ngIf="isSelfTriage" class="self-triage-button-header">
                        <button mat-flat-button color="primary" type="button" class="btn-block" (click)="openSelfTriageResumeModal()">
                            Resumo Autotriagem
                        </button>
                    </div>
                </div>
            </div>
            <div *ngIf="discriminatorResponse && discriminatorResponse.discriminator.length > 0" class="row">
                <app-protocol-v2 *ngIf="!outOfOrder && this.data.idFlowchartType == 1"
                    (selectedValue)="selectDiscriminator($event)" [discriminatorResponse]="discriminatorResponse"
                    [discriminators]="discriminators" [painColorCode]="painColorCode" (goNextToFlowchartSelect)="goNextToFlowchartSelect()">
                </app-protocol-v2>
                <app-protocol-v1 *ngIf="outOfOrder && this.data.idFlowchartType == 1"
                    (selectedValue)="selectDiscriminator($event)" [discriminatorResponse]="discriminatorResponse"
                    [discriminators]="discriminators">
                </app-protocol-v1>
                <app-multiple-victim-primary *ngIf="this.data.idFlowchartType == this.multiplePrimaryFlowchart"
                    (selectedValue)="selectDiscriminator($event)" [discriminators]="discriminators" (goNextToFlowchartSelect)="goNextToFlowchartSelect()">
                </app-multiple-victim-primary>
                <app-multiple-victim-secondary *ngIf="this.data.idFlowchartType == this.multipleSecondaryFlowchart"
                    (selectedValue)="selectDiscriminator($event)" [discriminators]="discriminators" (goNextToFlowchartSelect)="goNextToFlowchartSelect()">
                </app-multiple-victim-secondary>
            </div>
        </div>
    </div>
    
</div>