import { Component, Inject, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { FreeBedService } from 'src/app/shared/services/API/bed/free-bed.service';
import { BedEpisodeRequest } from 'src/app/shared/services/requests/bed/bed-episode.request';
import { BedStruct } from 'src/app/shared/services/structs/bed/bed.struct';
import { ChangeBedEpisodeConfirmModalComponent } from './change-bed-episode-confirm-modal/change-bed-episode-confirm-modal.component';

@Component({
  selector: 'app-change-bed-episode-modal',
  templateUrl: './change-bed-episode-modal.component.html',
  styleUrls: ['./change-bed-episode-modal.component.css']
})
export class ChangeBedEpisodeModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<ChangeBedEpisodeModalComponent>,
  private alertService: AlertService,
  private formBuilder: UntypedFormBuilder,
  private freeBedService: FreeBedService,
  ) { }
  
  public isLoading:boolean;  
  public model: UntypedFormGroup;
  public listFreeBed: BedStruct[];
  
  ngOnInit(): void {
    this.isLoading = false;
    this.populateFreeBedSelect();

    this.model = this.formBuilder.group({
      idBed: ['', [Validators.required]],
    });
  }
  
  close(){
    this.matDialogRef.close(); 
  }
  
  submit(){
    if(this.isLoading || !this.model.valid){
      return;
    }
    this.isLoading = true;
    
    let bedEpisodeRequest: BedEpisodeRequest = new BedEpisodeRequest();
    
    bedEpisodeRequest.idBed = this.model.get('idBed').value;
    bedEpisodeRequest.idEpisode = this.data.idEpisode;
    
    this.openConfirmModal(bedEpisodeRequest);
  }
  
  clickCancel(){
    this.matDialogRef.close(); 
  }
  
  openConfirmModal(bedEpisodeRequest: BedEpisodeRequest){
    const dialogRef = this.dialog.open(ChangeBedEpisodeConfirmModalComponent, {
      data: {
        bedEpisodeRequest: bedEpisodeRequest,
      },
    });    
    dialogRef.afterClosed().subscribe(result => {
      this.isLoading = false;    
      this.matDialogRef.close({isChange: true});   
    });
  }

  populateFreeBedSelect() {
    this.freeBedService.listFreeBed().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }

      this.listFreeBed = response.listBedStruct;
    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }
}

