<h1>Encaminhar</h1>
<app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
<div class="white-body">
    <form class="form" [formGroup]="model" (ngSubmit)="submit()">
        <div class="row">
            <div class="col-md-6 col-separador">
                <div class="row">
                    <div class="col-md-9" *ngIf="!isFlowSequence">
                        <div *ngFor="let item of location" class="row">
                            <div class="col-md-12 input-radio-list">
                                <input [value]="item.idFlow" type="radio" id="radio-{{item.idFlow}}"
                                    formControlName="forward" name="forward">
                                <label for="radio-{{item.idFlow}}">{{item.locationName}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9" *ngIf="isFlowSequence">
                        <span>Próximo destino do paciente:</span>
                        <div class="flow-sequence-body">
                            <div class="location">
                                {{flowSequence[1].destinyRoomName ? 'Sala: ' :
                                (flowSequence[1].destinySectorName ? 'Setor: ' : 'Serviço: ')}}
                            </div>
                            <div class="location-name">
                                <b>{{flowSequence[1].destinyRoomName ? flowSequence[1].destinyRoomName :
                                    (flowSequence[1].destinySectorName ? flowSequence[1].destinySectorName :
                                    flowSequence[1].destinyServiceName)}}</b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Necessidades especiais</mat-label>
                            <mat-select formControlName="idSpecialNecessity"
                                (ngModelChange)="onChangeSpecialNecessity($event)">
                                <mat-option value="-1">Nenhuma</mat-option>
                                <mat-option *ngFor="let item of specialNecessityList"
                                    [value]="item.idSpecialNecessity">{{item.specialNecessityName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="idSpecialNecessity == idSpecialNecessityOthers" class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Descreva a necessidade especial</mat-label>
                            <textarea matInput formControlName="specialNecessity"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Observação</mat-label>
                            <textarea matInput formControlName="observation"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row" id="conclusionNotes">
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Conclusão</mat-label>
                            <textarea matInput formControlName="conclusionNotes"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <a mat-flat-button (click)="finishEpisode()" color="accent" class="btn-block">
                            Finalizar Episódio
                        </a>
                    </div>
                    <div class="col-6">
                        <button mat-flat-button type="submit" color="primary" class="btn-block">
                            Avançar <mat-icon aria-hidden="false" aria-label="Plus">arrow_forward</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>