import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { StorageReturnService } from 'src/app/shared/services/API/pharmacy/storage-return.service';
import { LocationModel } from 'src/app/shared/services/models/pharmacy/location.model';
import { StorageReturnRequest } from 'src/app/shared/services/requests/pharmacy/storage-return.request';
import { GetStorageReturnStruct } from 'src/app/shared/services/structs/pharmacy/get-storage-return.struct';
import { BarCodeModalComponent } from './bar-code-modal/bar-code-modal.component';

@Component({
  selector: 'app-return-medicine-item',
  templateUrl: './return-medicine-item.component.html',
  styleUrls: ['./return-medicine-item.component.css']
})
export class ReturnMedicineItemComponent implements OnInit {

  constructor(public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private storageReturnService: StorageReturnService,
    private formBuilder: UntypedFormBuilder,) { }

  public model: UntypedFormGroup;

  public listLocation: LocationModel[];
  public returnItemStruct: GetStorageReturnStruct;

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.pharmacy;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.pharmacy_return_medicine_item;

  public isLoading: boolean;

  ngOnInit(): void {
    this.isLoading = false;
    this.returnItemStruct = new GetStorageReturnStruct();
    this.model = this.formBuilder.group({
      amount: [null, [Validators.required]],
      idLocation: [null, [Validators.required]],
    });
    this.openModal();
  }

  submit() {
    if (this.model.invalid)
      return;

    let request: StorageReturnRequest = new StorageReturnRequest();
    request.idStorage = this.returnItemStruct.idStorage;
    request.idLocation = this.model.get('idLocation').value;
    request.amountReturned = this.model.get('amount').value;
    this.storageReturnService.returnProduct(request).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/pharmacy']);
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  openModal() {
    const dialogRef = this.dialog.open(BarCodeModalComponent, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.returnItemStruct = result.storage;
    });
  }

}
