import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WelcomeRoutingModule } from './welcome-routing.module';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { SharedModule } from '../shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { MatDialogModule } from '@angular/material/dialog';
import { ModuleModalComponent } from './pages/module-modal/module-modal.component';


@NgModule({
  declarations: [
    WelcomeComponent,
    ModuleModalComponent,
  ],
  imports: [
    CommonModule,
    WelcomeRoutingModule,
    SharedModule,
    MatIconModule,
    MatDialogModule,
  ],
  providers:[
    AuthGuard
  ],
})
export class WelcomeModule { }
