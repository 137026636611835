import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AihStatusEnum } from 'src/app/shared/enum/hospital-document/aih-status.enum';
import { AihService } from 'src/app/shared/services/API/orchestrator-patient/aih.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { AihGroupStruct } from 'src/app/shared/services/structs/hospital-document/aih-group.struct';
import { DeleteAihGroupModalComponent } from './delete-aih-group-modal/delete-aih-group-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { PostAihGroupRequest } from 'src/app/shared/services/requests/hospital-document/post-aih-group.request';
import { AihGroupModel } from 'src/app/shared/services/models/hospital-document/aih-group.model';

@Component({
  selector: 'app-aih-pending-list',
  templateUrl: './aih-pending-list.component.html',
  styleUrls: ['./aih-pending-list.component.css']
})
export class AihPendingListComponent implements OnInit {

  constructor(
    private aihService: AihService,
    private alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
    private maskService: MaskService,
    private router: Router,
    private dialog: MatDialog,) { }




  @ViewChild(MatPaginator) paginator: MatPaginator;
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.medic;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.aih_list_pending;
  public masks: Masks;
  public listAihGroupStruct: AihGroupStruct[] = [];
  public isLoading: boolean;
  public model: UntypedFormGroup;
  public fullSize: number;
  public pageIndex: number = 0;
  public patientName: string;
  public userCreatorName: string;
  public initialDate: Date;
  public endDate: Date;
  public idEpisode: number;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      patientName: [''],
      userCreatorName: [''],
      initialDate: [null],
      endDate: [null],
      idEpisode: [''],
    });

    this.masks = this.maskService.getMasks();    
    this.pageIndex = 0;

    this.search(null, null, null, null, null);
  }

  buttonSearch() {   
    this.pageIndex = 0;

    this.listAihGroupStruct = [];

    this.patientName = this.model.get("patientName").value;
    this.userCreatorName = this.model.get("userCreatorName").value;

    this.initialDate = this.model.get("initialDate").value;
    this.endDate = this.model.get("endDate").value;

    this.idEpisode = this.model.get("idEpisode").value;

    this.search(this.patientName,  this.userCreatorName, this.initialDate, this.endDate, this.idEpisode);
  }

  changePage(event: PageEvent) {
    this.pageIndex = event.pageIndex;

    this.listAihGroupStruct = [];
    
    this.model.get("patientName").setValue(this.patientName); 
    this.model.get("userCreatorName").setValue(this.userCreatorName);
    this.model.get("initialDate").setValue(this.initialDate);
    this.model.get("endDate").setValue(this.endDate);
    this.model.get("idEpisode").setValue(this.idEpisode);
    
    this.search(this.patientName,  this.userCreatorName, this.initialDate, this.endDate, this.idEpisode);
  }

  search(patientName: string, userCreatorName: string, initialDate: Date, endDate: Date, idEpisode: number) {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.aihService.listAihGroupByStatus(AihStatusEnum.pendente, this.pageIndex, patientName, userCreatorName, initialDate, endDate, idEpisode).subscribe({
      next: (response) => {
        if (response.isError && response.errorCode == 1) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
        }
        else if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.listAihGroupStruct = response.listAihGroupStruct;
        this.fullSize = response.fullSize;

      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openAih(aihGroupStruct: AihGroupStruct){
    this.router.navigate(['/medic/aih-editor', { idAihGroup: aihGroupStruct.idAihGroup, isPending: true, idEpisode: aihGroupStruct.idEpisode }]);
  }

  openModalDelete(aihGroupStruct: AihGroupStruct){
    const dialogRef = this.dialog.open(DeleteAihGroupModalComponent, {
      data: {
      },
    });

    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.isDeleted && result.deletedMotive && result.deletedMotive.trim() != "") 
          this.deleteAih(aihGroupStruct, result.deletedMotive);
      }
    });
  }

  deleteAih(aihGroupStruct: AihGroupStruct, deletedMotive: string){
    this.isLoading = true;

    let request: PostAihGroupRequest = new PostAihGroupRequest();
    request.aihGroup = aihGroupStruct;
    request.aihGroup.deletedMotive = deletedMotive;
    request.aihGroup.isDeleted = true;

    this.aihService.deleteAihGroup(aihGroupStruct.idAihGroup, request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Item deletado.", AlertType.success);
        
        let index: number = this.listAihGroupStruct.findIndex(item => item.idAihGroup == aihGroupStruct.idAihGroup);
        if (Number.isInteger(index) && index >= 0) 
          this.listAihGroupStruct.splice(index, 1);

        this.isLoading = false;
         

      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      }
    });
  }
}