<div class="row display-contents">
    <div class="col-12 padding-box">
        <div class="row border-box">
            <div class="title-header">
                <h3>Restrições de retorno</h3>
            </div>
            <div class="filters">
                <div class="row align-item-box">
                    <div class="col-12 col-md-4 col-lg-4 col-xl-4 search-correction">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Pesquisar</mat-label>
                            <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText"
                                (keyup)="onKeySearch($event)">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-3 col-lg-3 col-xl-2">
                        <button mat-flat-button color="primary" class="btn-block" (click)="openModal(null)">
                            <span>Nova restrição</span>
                        </button>
                    </div>
                </div>
                <div class="row" style="overflow: auto;">
                    <app-loading-progress-bar *ngIf="isLoading"></app-loading-progress-bar>
                    <table class=" col-12">
                        <thead>
                            <tr>
                                <th class="column-procedure">Procedimento</th>
                                <th class="column-period">Período</th>
                                <th class="column-number-days">Nº de dias</th>
                                <th class="column-same-professional">Mesmo profissional</th>
                                <th class="column-action">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let returnRestrictions of listReturnRestrictionsStruct">
                                <td>{{ returnRestrictions.nameProcedure }}</td>
                                <td>{{ returnRestrictions.periodName }}</td>
                                <td>{{ returnRestrictions.number !== null ? returnRestrictions.number : "-" }}</td>
                                <td>{{ returnRestrictions.returnWithSameProfessional ? 'Sim' : 'Não' }}</td>
                                <td class="ajust-buttons">
                                    <a class="action-icon">
                                        <mat-icon aria-hidden="false" aria-label="Editar"
                                            (click)="openModal(returnRestrictions)">
                                            edit
                                        </mat-icon>
                                    </a>
                                    <a class="action-icon">
                                        <mat-icon aria-hidden="false" aria-label="Editar"
                                            (click)="openAlertModal(returnRestrictions)">
                                            delete
                                        </mat-icon>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="row">
                        <div class="col-12">
                            <mat-paginator [length]="fullListSize" #paginator [pageIndex]="pageIndex"
                                [pageSize]="pageSize" aria-label="Select page" (page)="changePage($event)"
                                [hidePageSize]="true">
                            </mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>
</div>