import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ClassificationData, UtilsClassificationService } from '../../utils.service';
import { CounselingStruct } from 'src/app/shared/services/structs/risk-classification/counseling.struct';
import { CounselingService } from 'src/app/shared/services/API/risk-classification/counseling.service';
import { CounselingTypeEnum } from 'src/app/shared/enum/counseling-type.enum';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { TelemedicineConfigService } from 'src/app/shared/services/API/telemedicine/telemedicine-config.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { TelemedicineAccessService } from 'src/app/shared/services/API/telemedicine/telemedicine-access.service';
import { TelemedicineAccessRequest } from 'src/app/shared/services/requests/telemedicine/telemedicine-access.request';
import { environment } from 'src/environments/environment';
import { TelemedicineConfigStruct } from 'src/app/shared/services/structs/telemedicine/telemedicine-config.struct';

@Component({
  selector: 'app-counseling-selection',
  templateUrl: './counseling-selection.component.html',
  styleUrls: ['./counseling-selection.component.css']
})

export class CounselingSelectionComponent implements OnInit {

  constructor(
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    private utilsClassificationService: UtilsClassificationService,
    private counselingService: CounselingService,
    private maskService: MaskService,
    private utilService: UtilService,
    private telemedicineConfigService: TelemedicineConfigService,
    private telemedicineAccessService: TelemedicineAccessService,
  ) { }

  public masks: Masks;
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.classification;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.classification_pre_registration;
  public isFirstLoading: boolean = true;
  public idEpisode: number;
  public flowchartName: string;
  public outOfOrder: boolean;
  public discriminators: any[] = [];
  public data: ClassificationData;
  public model: UntypedFormGroup;
  public gravity: string;
  public phoneConfirmation: string;
  public patientAge: string;
  public noneOfTheAbove: CounselingStruct;
  public listCounseling: CounselingStruct[];
  public noOptions: boolean;
  public idModuleTelemedicine: number;
  public firstClick: boolean = false;
  public telemedicineConselingEnum:  number = CounselingTypeEnum.Telemedicina
  public telemedicineLink: string;
  public isTelemedicineConfigActive: boolean = false;
  public telemedicineConfig: TelemedicineConfigStruct;
  public isLoading: boolean = false;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      observation: [''],
      phone1: [null]
    });

    this.masks = this.maskService.getMasks();
    this.data = this.utilsClassificationService.getClassificationData();
    this.getTelemedicineConfig();
    this.getCounseling(this.data);
    this.noneOfTheAbove = new CounselingStruct();
    this.noneOfTheAbove.counselingName = "Nenhuma das opcões acima";
    this.noneOfTheAbove.isChecked = false;
    this.idEpisode = this.data.idEpisode;
    this.flowchartName = this.data.flowchartName;
    this.outOfOrder = this.data.outOfOrder;

    this.patientAge = this.utilService.getAgeString(this.maskService.formatDateToString(this.data.patientData.birthDate));
  }

  submit() {
    if (this.listCounseling) {
      this.data.listCounseling = this.listCounseling.filter(c => c.isChecked === true);
      if (this.data.listCounseling.filter(x => x.idCounselingType == CounselingTypeEnum.Telemedicina).length > 0
        && !this.model.get('phone1').value) {
        this.model.get('phone1').setValidators(Validators.required);
        this.alertService.show('Erro', 'Insira um telefone', AlertType.error)
        return;
      }
    }

    this.noOptions = (!this.data.listCounseling || this.data.listCounseling.length == 0) && (this.noneOfTheAbove.isChecked != true);
    if (this.noOptions) {
      this.alertService.show('Erro', 'Selecione algum aconselhamento ou o campo "Nenhuma das opções acima" ', AlertType.error);
      return;
    }

    this.data.observation = this.model.get('observation').value;

    if (this.noneOfTheAbove.isChecked && !this.data.observation) {
      this.alertService.show('Erro', 'Se nenhum aconselhamento for selecionado, o campo "Observação" é obrigatório', AlertType.error)
      return;
    }

    if (this.model.get('phone1').value != this.data.patientData.phone1)
      this.data.patientData.phoneConfirmation = this.maskService.formatPhoneToSave(this.model.get('phone1').value);
    else
      this.data.patientData.phoneConfirmation = this.data.patientData.phone1;

    this.utilsClassificationService.updateClassificationData(this.data);

    this.router.navigate(['/classification/telephone-classification/details']);

    return;
  }

  noneCheck(): void {
    if (this.noneOfTheAbove.isChecked == true) {
      this.model.get('observation').setValidators(Validators.required);
      if (this.listCounseling) {
        this.listCounseling.forEach((c: CounselingStruct) => {
          c.isChecked = false;
        });
      }
    }
  }

  getCounseling(data: ClassificationData) {
    data.listCounseling = null;
    this.counselingService.getCounseling(data.discriminator.idDiscriminator).subscribe({
      next: (response) => {
        if (response.isError) {
          this.utilsClassificationService.updateClassificationData(data);
          this.router.navigate(['/classification/telephone-classification/details']);
          return;
        }

        this.isFirstLoading = false;
        data.listCounseling = [...response.listCounseling];
        this.listCounseling = [...this.data.listCounseling];
        this.utilsClassificationService.updateClassificationData(data);
      }, error: (error) => {
        this.utilsClassificationService.updateClassificationData(data);
        this.router.navigate(['/classification/telephone-classification/details']);
      }
    });
  }

  check(event: any) {
    if (event == CounselingTypeEnum.Telemedicina && this.firstClick) {
      this.data.telemedicineCheck = false;
      this.firstClick = false;
    }
    else if (event == CounselingTypeEnum.Telemedicina) {
      this.data.telemedicineCheck = true;
      this.firstClick = true;
    }
  }

  getTelemedicineConfig() {
    this.telemedicineConfigService.getConfig().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.telemedicineConfig = response.telemedicineConfig;
        this.isTelemedicineConfigActive = response.telemedicineConfig.isActive;
        if (!response.telemedicineConfig.isActive) {
          this.listCounseling.forEach((x, index) => {
            if (x.idCounselingType == CounselingTypeEnum.Telemedicina)
              this.listCounseling.splice(index, x.idCounselingType);
          });
        }

        this.model.get('phone1').setValue(this.maskService.formatPhoneToField(this.data.patientData.phone1));
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  generateTelemedicineLink(){
    this.isLoading = true;
    let telemedicineAccessRequest: TelemedicineAccessRequest = new TelemedicineAccessRequest();
    telemedicineAccessRequest.idPatient = this.data.idPatient;
    this.telemedicineAccessService.generateGuid(telemedicineAccessRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.telemedicineConfig.url
        this.telemedicineLink = environment.urlBasePatientUI + "telemedicine/"+ this.telemedicineConfig.url + "/telephone-classification/"+response.telemedicineAccess.guid;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }
  onCopy(text) {
    this.alertService.show('Sucesso', text, AlertType.success);
  }
}