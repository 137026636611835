import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';

@Component({
  selector: 'app-phone-modal',
  templateUrl: './phone-modal.component.html',
  styleUrls: ['./phone-modal.component.css']
})
export class PhoneModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<PhoneModalComponent>,
  private alertService: AlertService,
  private maskService: MaskService,) { }
  
  public completeURL: string;
  public isLoading:boolean; 
  public masks: Masks;
  public maskToUse: Masks;

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();

    if (this.data && this.data.contactValue){
      if (this.data.contactValue.charAt(0) == '0')  this.maskToUse = this.masks.sacPhone;
      else this.maskToUse = this.masks.phone;
    }
    this.isLoading = false;
  }
  
  close(){
    this.matDialogRef.close(); 
  }

}