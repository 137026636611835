import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TypeItemKitEnum } from 'src/app/shared/enum/pharmacy/type-item-kit.enum';
import { VariablesTypeFlowEnum } from 'src/app/shared/enum/variables-flow.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { ItemModel } from 'src/app/shared/services/models/pharmacy/item.model';
import { MedicineModel } from 'src/app/shared/services/models/pharmacy/medicine.model';
import { StorageLocationStruct } from 'src/app/shared/services/structs/pharmacy/storage-location.struct';

@Component({
  selector: 'app-storage-location-item',
  templateUrl: './storage-location-item.component.html',
  styleUrls: ['./storage-location-item.component.css']
})
export class StorageLocationItemComponent implements OnInit {

  @Input() formGroup: UntypedFormGroup;
  @Input() listItem: ItemModel[];
  @Input() listMedicines: MedicineModel[];
  @Input() listStorage: StorageLocationStruct[];
  @Input() arrayLocationItem: any[];

  public listStorageFilter: any[];
  public listLocation: any[];
  public formArrayName: string = "storageLocationItem";
  public typeItemKitEnum: typeof TypeItemKitEnum = TypeItemKitEnum;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
  }

  selectTypeItem(event: any, i: number) {
    this.formGroup.get(this.formArrayName)['controls'][i].get('idItem').enable();
  }

  changeItem(event: any, i: number) {
    if(this.formGroup.get(this.formArrayName)['controls'][i].get('typeItem').value == this.typeItemKitEnum.Material)
      this.arrayLocationItem[i].listStorage = [...this.listStorage.filter(c => c.idItem == event.value)];
    if(this.formGroup.get(this.formArrayName)['controls'][i].get('typeItem').value == this.typeItemKitEnum.Medicine)
      this.arrayLocationItem[i].listStorage = [...this.listStorage.filter(c => c.idMedicine == event.value)];
    if (this.arrayLocationItem[i].listStorage.length == 0) {
      this.alertService.show("Aviso", "Item não contém estoque!", AlertType.warning);
    }
  }

  changeStorage(event: any, i: number) {
    let storages = this.listStorage.find(c => c.idStorage == event.value);
    this.arrayLocationItem[i].listLocations = [...storages.storageAmountLocation];
  }


  changeLocation(event: any, i: number) {
    this.arrayLocationItem[i].location = this.arrayLocationItem[i].listLocations.find(c => c.idLocation == event.value);
    ((this.formGroup.controls[this.formArrayName] as UntypedFormArray).at(i) as UntypedFormGroup).get('value').setValidators([Validators.max(this.arrayLocationItem[i].location.amountRemaining), Validators.required, Validators.min(0)]);
    ((this.formGroup.controls[this.formArrayName] as UntypedFormArray).at(i) as UntypedFormGroup).get('value').updateValueAndValidity();
  }


  onBlurValue(i: number) {
    let idLocation = ((this.formGroup.controls[this.formArrayName] as UntypedFormArray).at(i) as UntypedFormGroup).get('idLocation').value;
    if (idLocation) {
      let location = this.arrayLocationItem[i].listLocations.find(c => c.idLocation == idLocation);
      let quantity = ((this.formGroup.controls[this.formArrayName] as UntypedFormArray).at(i) as UntypedFormGroup).get('value').value;
      if (location.amountRemaining < quantity) {
        this.alertService.show("Aviso", "Nesta localização existe somente " + location.amountRemaining + " itens!", AlertType.warning);
      }
    }

  }
  createInput() {
    return this.formBuilder.group({
      typeItem: [null, [Validators.required]],
      idItem: [{value: '', disabled: true}, Validators.required],
      idStorage: ['', [Validators.required]],
      idLocation: ['', [Validators.required]],
      value: ['', [Validators.required, Validators.min(0)]],
    })
  }

  addNext() {
    this.arrayLocationItem.push({});
    (this.formGroup.controls[this.formArrayName] as UntypedFormArray).push(this.createInput())
    this.formGroup.markAllAsTouched();
  }

  removeLine(index: number) {
    this.arrayLocationItem.splice(index, 1);
    (this.formGroup.controls[this.formArrayName] as UntypedFormArray).removeAt(index)
  }
}
