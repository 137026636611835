<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <div class="page-title">
            <div>
                <a class="back-link" routerLink="/master">
                    <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Módulo Master
                </a>
                <h1>Configurações de Net Promoter Score (NPS)</h1>
            </div>
            <div class="col-12 col-md-2">
                <button mat-flat-button type="submit" color="primary" class=" btn-block" (click)="submit()">
                    <span *ngIf="isLoading == false">Salvar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
            </div>
        </div>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <form class="form" [formGroup]="model">
            <div class="white-body">
                <div class="title-header">
                    <h1>Configuração da página</h1>
                </div>
                <div class="identity-body">
                    <div class="customize-identity">
                        <div class="identity">
                            <div class="slider-checkbox">
                                <mat-slide-toggle formControlName="isLogoUploaded" checked="isLogoUploaded">Usar logo no
                                    topo da
                                    página
                                </mat-slide-toggle>
                            </div>
                            <div class="choice-identity">
                                <a class="link-uploaded-logo" (click)="openPanelLogoModal()"
                                    *ngIf="this.model.get('isLogoUploaded').value">
                                    <mat-icon class="img-uploaded-logo">image</mat-icon>
                                    <span *ngIf="this.logo">Visualizar Logo</span>
                                    <span *ngIf="!this.logo">Selecionar Logo</span>
                                </a>
                            </div>
                        </div>
                        <div class="identity">
                            <div class="slider-checkbox">
                                <mat-slide-toggle formControlName="useColor" checked="useColor">
                                    Alterar a cor padrão da página de NPS
                                </mat-slide-toggle>
                            </div>
                            <div class="choice-identity" *ngIf="this.model.get('useColor').value">
                                <ngx-colors class="color-picker-div" ngx-colors-trigger formControlName="colorCode"
                                    [acceptLabel]="'Aceitar'" [cancelLabel]="'Cancelar'"></ngx-colors>
                                <span class="color-picker-span">Selecionar cor</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="white-body">
                <div class="title-header">
                    <h1>Configuração de disparo da pesquisa</h1>
                </div>
                <div class="enable-trigger">
                    <mat-slide-toggle (change)="changeMessageTriggerActive()" [checked]="messageTriggerActive">Habilitar
                        disparo de NPS para pacientes da Unidade
                    </mat-slide-toggle>
                    <div class="help"
                        matTooltip="Pacientes contemplados pelo gatilho de disparo receberão a pesquisa por SMS de acordo com a periodicidade selecionada."
                        matTooltipPosition="right">
                        <mat-icon aria-hidden="false" aria-label="question_mark">question_mark</mat-icon>
                    </div>
                    <mat-slide-toggle (change)="changeCommentCollectionActive()" [checked]="commentCollectionActive">
                        Habilitar coleta de comentário após a nota
                    </mat-slide-toggle>
                </div>
                <div class="row messageFiringRules" formArrayName="listMessageFiringRules"
                    *ngFor="let item of model.get('listMessageFiringRules')['controls']; let i=index">
                    <div class="row" [formGroupName]="i">

                        <div class="idMessageFiringRules">
                            <mat-form-field appearance="outline">
                                <input matInput type="number" formControlName="idMessageFiringRules">
                            </mat-form-field>
                        </div>

                        <div class="col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Gatilho de disparo</mat-label>
                                <mat-select formControlName="idMessageFiringTrigger"
                                    (selectionChange)="setMessageFiringTrigger(i)">
                                    <mat-option *ngFor="let it of listMessageFiringTrigger"
                                        [value]="it.idMessageFiringTrigger"
                                        [disabled]="listSelectedMessageFiringRules.includes(it.idMessageFiringTrigger)">
                                        {{it.messageFiringTriggerName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Natureza de atendimento</mat-label>
                                <mat-select formControlName="idNatureOfAttendance">
                                    <mat-option *ngFor="let it of listNatureOfAttendance"
                                        [value]="it.idNatureOfAttendance">
                                        {{it.natureOfAttendanceName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Periodicidade</mat-label>
                                <mat-select formControlName="frequency">
                                    <mat-option value="Dia"> Dia </mat-option>
                                    <mat-option value="Hora"> Hora </mat-option>
                                </mat-select>
                                <mat-icon class="tooltip-frequency" aria-hidden="false" aria-label="question_mark"
                                    matTooltip="Defina o período em que a pesquisa será disparada, de acordo com o gatilho selecionado."
                                    matTooltipPosition="above">
                                    question_mark</mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="col-md-2 inputField">
                            <mat-form-field appearance="outline">
                                <mat-label>Tempo para disparo</mat-label>
                                <input matInput type="text" inputmode="numeric" formControlName="timeOfTrigger"
                                    maxlength="2">
                                <mat-error *ngIf="item.get('timeOfTrigger').invalid">Informe tempo para disparo</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-9 inputField">
                            <mat-form-field appearance="outline">
                                <mat-label>Conteúdo da mensagem</mat-label>
                                <textarea matInput type="text" maxlength="160"
                                    formControlName="descriptionOfMessage"></textarea>
                                <mat-error *ngIf="item.get('descriptionOfMessage').invalid">Informe o conteúdo da
                                    mensagem</mat-error>
                            </mat-form-field>
                            <span class="title-description-count">{{ item.get('descriptionOfMessage').value.length ?
                                item.get('descriptionOfMessage').value.length : 0 }}/160 caracteres</span>
                        </div>
                        <div class="col-6 col-md-3 rules-button">
                            <button class="side-button " color="primary" mat-mini-fab type="button" (click)="addNext()">
                                <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
                            </button>
                            <button class="side-button " color="primary" mat-mini-fab type="button"
                                (click)="removeField(i)" *ngIf="i != 0">
                                <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>