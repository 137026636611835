import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MedicineSubgroupService } from 'src/app/shared/services/API/pharmacy/medicine-subgroup.service';
import { MedicineSubgroupStruct } from 'src/app/shared/services/structs/pharmacy/medicine-subgroup.struct';
import { MedicineSubgroupDeleteModalComponent } from './medicine-subgroup-delete-modal/medicine-subgroup-delete-modal.component';

@Component({
  selector: 'app-medicine-subgroup-list',
  templateUrl: './medicine-subgroup-list.component.html',
  styleUrls: ['./medicine-subgroup-list.component.css']
})
export class MedicineSubgroupListComponent implements OnInit {

  constructor(private medicineSubgroupService: MedicineSubgroupService,
      private alertService: AlertService,
      public dialog: MatDialog
    ) { }
    
    public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.pharmacy;
    public menuFunctionalityEnum:MenuFunctionalityEnum = MenuFunctionalityEnum.pharmacy_medicine_subgroup;
    
    public listMedicineSubgroup: MedicineSubgroupStruct[];
    public isLoading: boolean;
    public searchText: string;

  ngOnInit(): void {
    this.isLoading = true;
    this.search();
  }
  search(){
    this.isLoading = true;  
    if(this.searchText == "")
      this.searchText = null;
    
    this.medicineSubgroupService.listMedicineSubgroup(this.searchText).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.listMedicineSubgroup = response.listMedicineSubgroupStruct; 

      this.isLoading = false;    
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  private timeoutKeySearch: any = null;
  
  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }
  
  openModal(idMedicineSubgroup){
    const dialogRef = this.dialog.open(MedicineSubgroupDeleteModalComponent, {
      data: {
        idMedicineSubgroup: idMedicineSubgroup
      },
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.deleteMedicineSubgroup){
        this.search();
      }
    });
  }
}
