<div mat-dialog-content>
    <div class="mat-dialog-header">
        <h3>Corrigir quantidade no local</h3>
    </div>
    <form class="form" [formGroup]="model" (ngSubmit)="onSubmit()">
        <div class="mat-dialog-content">
            <div class="row">
                <div class="col-12">
                    <p>Tipo de correção:</p>
                    <mat-radio-group aria-label="" formControlName="correctionType">
                        <mat-radio-button [value]="true">Adicionar</mat-radio-button>
                        <mat-radio-button [value]="false">Remover</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Quantidade </mat-label>
                        <input matInput type="number" formControlName="amount">
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="footer-panel-modal-list">
            <div class="row">
                <div class="col-6">
                    <a mat-flat-button color="accent" class=" btn-block" (click)="clickCancel()">Cancelar</a>
                </div>
                <div class="col-6">
                    <button mat-flat-button type="submit" color="primary" class=" btn-block">
                        <span *ngIf="isLoading == false" >Salvar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>