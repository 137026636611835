import { AfterContentChecked, AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ProcedureSigtapService } from 'src/app/shared/services/API/billing/procedure-sigtap.service';
import { ProcedureService } from 'src/app/shared/services/API/medic/procedure.service';
import { CboService } from 'src/app/shared/services/API/user/cbo.service';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { ProcedureSigtapModel } from 'src/app/shared/services/models/billing/procedure-sigtap.model';
import { ProcedureRequest } from 'src/app/shared/services/requests/medic/procedure-request';
import { ProcedureStruct } from 'src/app/shared/services/structs/medic/procedure-struct';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-procedure-register',
  templateUrl: './procedure-register.component.html',
  styleUrls: ['./procedure-register.component.css']
})
export class ProcedureRegisterComponent implements OnInit, AfterContentInit {

  constructor(
    private formBuilder: UntypedFormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private procedureService: ProcedureService,
    private cboService: CboService,
    private procedureSigtapService: ProcedureSigtapService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.medic;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.medic_procedure;

  public model: UntypedFormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;
  public actualServiceModule: number;

  public masks: Masks;
  public idProcedure: number;
  public procedureRequest: ProcedureRequest;
  public urlListCbo: string;
  public urlListProcedureSigtap: string = environment.urlApiBilling + 'ProcedureSigtap/getList';
  public preSelectCbo: any[] = [];
  public sigtapSelected: ProcedureSigtapModel[] = [];
  public loadingCBO: boolean = true;

  ngOnInit(): void {
    this.isLoading = false;
    this.isFirstLoading = true;
    this.urlListCbo = environment.urlApiUser + "Cbo";
    this.model = this.formBuilder.group({
      name: ['', [Validators.required]],
      code: ['', [Validators.required]],
      status: ['', [Validators.required]],
      description: [''],
      firstChildGroup: this.formBuilder.group({
        listIdCbo: [, [Validators.required]],
        idProcedureSigtap: [''],
      })
    });

    this.masks = this.maskService.getMasks();

    this.procedureRequest = new ProcedureRequest();

    this.idProcedure == null;
    this.isUpdate == false;
    if (this.activatedRoute.snapshot.paramMap.get('idProcedure'))
      this.idProcedure = parseInt(this.activatedRoute.snapshot.paramMap.get('idProcedure'));
    
  }

  ngAfterContentInit(): void {
    if (this.idProcedure != null) {
      this.isUpdate = true;
      this.populateProcedureData();
    }
    else {
      this.isFirstLoading = false;
      this.loadingCBO = false;
    }
  }

  submit() {
    if (this.isLoading) {
      return;
    }

    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    this.isLoading = true;

    this.procedureRequest.description = this.model.get('description').value;

    if (this.model.get('status').value === "true") {
      this.procedureRequest.isActive = true;
    }
    if (this.model.get('status').value === "false") {
      this.procedureRequest.isActive = false;
    }

    this.procedureRequest.procedureName = this.model.get('name').value;
    this.procedureRequest.procedureCode = this.model.get('code').value;

    this.procedureRequest.listCbos = this.model.get('firstChildGroup').get('listIdCbo').value;
    this.procedureRequest.idProcedureSigtap = this.model.get('firstChildGroup').get('idProcedureSigtap').value;

    if (this.isUpdate)
      this.updateProcedure();
    else
      this.createProcedure();
  }

  populateProcedureData() {
    this.procedureService.getProcedure(this.idProcedure).subscribe({
      next: (response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          this.isFirstLoading = false;
          return;
        }
        
        if(response.procedure.idProcedureSigtap)
          this.getProcedureSigtapById(response.procedure.idProcedureSigtap)
        if(response.procedure.procedureCbo && response.procedure.procedureCbo.length > 0)
          this.getListCBO(response.procedure);

        this.model.get('name').setValue(response.procedure.procedureName);
        this.model.get('code').setValue(response.procedure.procedureCode);
        this.model.get('status').setValue(response.procedure.isActive.toString());
        this.model.get('description').setValue(response.procedure.description);

        this.isLoading = false;
        this.isFirstLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.isFirstLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getListCBO(procedure: ProcedureStruct) {
    this.cboService.listAllCbo().subscribe({
      next: (responseCbo) => {

        if (responseCbo.isError) {
          this.alertService.show('Erro', responseCbo.errorDescription, AlertType.error);
          this.loadingCBO = false;
          return;
        }

        let cboObjectArray = responseCbo.list.filter(({ idCbo }) => procedure.procedureCbo.map(x => x.idCbo).includes(idCbo));
        this.model.get('firstChildGroup').get('listIdCbo').setValue(procedure.procedureCbo.map(x => x.idCbo));
        this.preSelectCbo = [...cboObjectArray];
        this.loadingCBO = false;
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.loadingCBO = false;
      }
    });
  }

  updateProcedure() {
    this.procedureService.updateProcedure(this.idProcedure, this.procedureRequest).subscribe({
      next: (response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;

        this.router.navigate(['/medic/procedure']);
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  createProcedure() {
    this.procedureService.createProcedure(this.procedureRequest).subscribe({
      next: (response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;

        this.router.navigate(['/medic/procedure']);
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getProcedureSigtapById(idProcedureSigtap: number) {
    this.procedureSigtapService.getProcedureSigtap(idProcedureSigtap).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        if(response.procedureSigtap)
          this.sigtapSelected.push(response.procedureSigtap);
        this.model.get('firstChildGroup').get('idProcedureSigtap').setValue(idProcedureSigtap);
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });

  }
  
}