export class EquipmentRequest
{
    public equipmentName: string;
    
    public department: string;
    
    public isActive: boolean;
    
    public quantity: number;
    
    public idEquipmentType: number;
}