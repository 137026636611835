import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ClassificationData, UtilsClassificationService } from 'src/app/classification/utils.service';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { DeviceTypeEnum } from 'src/app/shared/enum/device-type.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MeasurerService } from 'src/app/shared/services/API/measurer/measurer.service';
import { FlowchartGroupService } from 'src/app/shared/services/API/risk-classification/flowchart-group.service';
import { FlowchartService } from 'src/app/shared/services/API/risk-classification/flowchart.service';
import { FlowchartGroupModel } from 'src/app/shared/services/models/risk-classification/flowchart-group.model';
import { FlowchartModel } from 'src/app/shared/services/models/risk-classification/flowchart.model';
import { SearchFlowchartRequest } from 'src/app/shared/services/requests/risk-classification/search-flowchart.request';
import { DeviceTypeSectorResponse } from 'src/app/shared/services/responses/risk-classification/device-type-sector.response';


@Component({
  selector: 'app-flowchart-selection-reclassification',
  templateUrl: './flowchart-selection-reclassification.component.html',
  styleUrls: ['./flowchart-selection-reclassification.component.css']
})
export class FlowchartSelectionReclassificationComponent implements OnInit {

  constructor(private alertService: AlertService,
    public router: Router,
    private formBuilder: UntypedFormBuilder,
    private flowchartService: FlowchartService,
    public utilClassification: UtilsClassificationService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.classification;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.classification_pre_registration;
  public model: UntypedFormGroup;
  public idEpisode: number;
  public idFlowchart: number;
  public flowchart: string;
  public score: boolean;
  public isFirstLoading: boolean = true;
  public setUppercaseText: boolean = false;
  private idFlowchartType: number;

  @Output() selectedFlowchart = new EventEmitter<any>();

  ngOnInit(): void {
    let data = this.utilClassification.getClassificationData();
    let deviceTypeSectorResponse: DeviceTypeSectorResponse = this.utilClassification.getDevicetypeBySector()
    if (deviceTypeSectorResponse && deviceTypeSectorResponse.deviceTypeSector && deviceTypeSectorResponse.deviceTypeSector.setUppercaseText)
        this.setUppercaseText = deviceTypeSectorResponse.deviceTypeSector.setUppercaseText;
    this.idEpisode = data.idEpisode;
    this.idFlowchart = data.reclassification.idFlowchart;
    this.flowchart = data.reclassification.flowchart;
    this.score = data.reclassification.score;
    this.getFlowchart(data.reclassification.idFlowchart);
    this.model = this.formBuilder.group({
      complain: [data.complain, [Validators.required]],
    });
  }

  getFlowchart(idFlowchar: number) {
    this.flowchartService.getTheFlowchart(idFlowchar).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.idFlowchartType = response.flowchart.idFlowchartType;
      this.isFirstLoading = false;
    });
  }

  selectFlowchart() {
    if (this.model.invalid) {
      this.alertService.show('Erro', "Preencha a queixa!", AlertType.error);
      return;
    }
    let values = this.utilClassification.getClassificationData();
    values.complain = this.model.get("complain").value;
    values.idFlowchart = this.idFlowchart;
    values.flowchartName = this.flowchart;
    values.isPunctuationSystem = this.score;
    values.idFlowchartType = this.idFlowchartType;
    this.utilClassification.updateClassificationData(values);
    this.selectedFlowchart.emit(this.idFlowchart);
  }
}
