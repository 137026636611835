import { IntegrationLog } from "../../models/integration/Integration-log.model";

export class IntegrationLogStruct extends IntegrationLog {
    
    public httpVerb: string;

    public uriDescrption: string;
    
    public logType: string;

    public accessKeyName: string;
}