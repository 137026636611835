import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AihConfigService } from 'src/app/shared/services/API/hospital-document/aih-config.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { AihConfigModel } from 'src/app/shared/services/models/hospital-document/aih_config.model';
import { AihConfigDeleteModalComponent } from './aih-config-delete-modal/aih-config-delete-modal.component';

@Component({
  selector: 'app-aih-fill-config-list',
  templateUrl: './aih-fill-config-list.component.html',
  styleUrls: ['./aih-fill-config-list.component.css']
})
export class AihFillConfigListComponent implements OnInit {

  constructor(private aihConfigService: AihConfigService,
    private alertService: AlertService,
    public dialog: MatDialog) {
  }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.medic;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.aih_fill_config;

  public listAihConfig: AihConfigModel[] = [];
  public isLoading: boolean = true;

  ngOnInit(): void {
    this.search();
  }

  search() {
    this.aihConfigService.getListAihConfig().subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.listAihConfig = response.listAihConfig;
        this.isLoading = false;
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  openModal(idAihConfig: number) {
    const dialogRef = this.dialog.open(AihConfigDeleteModalComponent, {
      data: {
        idAihConfig: idAihConfig
      },
    });

    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.deleteAihConfig)
          this.search();
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  throwException(error: string) {
    console.log(error);
    this.isLoading = false;
    this.alertService.show('Erro inesperado', error, AlertType.error);
  }
}