export class ClassificationRiskAuditOthers {
    public idClassificationRiskAuditOthers: number;

    public idClassificationRiskAudit: number;

    public idClassificationRiskAuditTriage: number;

    public idTriage: number;

    public idUser: number;

    public observation: string;

    public isCorrectAuditor: boolean;

    public idStatus: number;

    public idProtocol: number;

    public numberOtherStatusAudit: number;

    public idHealthUnit: number;

    public datetimeInclusion: Date;

    public idIncorrectClassificationReason: number;

    public userName: string

    public idCorrectClassificationReason: number;

    public correctObservationReason: string
}