import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-patient-bar-code-modal',
  templateUrl: './patient-bar-code-modal.component.html',
  styleUrls: ['./patient-bar-code-modal.component.css']
})
export class PatientBarCodeModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<PatientBarCodeModalComponent>,
    private alertService: AlertService
  ) { }

  public isLoading:boolean;  
  public barcodeValue: string;
  public idEpisode: number;
  public isValid: boolean;

  ngOnInit(): void {
    this.isLoading = false;
    this.isValid = false;
  }
  
  close(){
    this.matDialogRef.close({checkPatient: null}); 
  }
  
  clickConfirm(){
    this.matDialogRef.close({checkPatient: true}); 
  }
  
  clickCancel(){
    this.matDialogRef.close({checkPatient: null}); 
  }

  checkPatient(){
    if(!this.idEpisode) return;
    if(this.idEpisode != this.data.idEpisode){
      this.alertService.show('Erro', "O episódio informado é diferente do paciente em atendimento", AlertType.error);
      return;
    }
    this.isValid = true;
    this.alertService.show('Sucesso', "Paciente confirmado", AlertType.success);
  }

  private timeoutKeySearch: any = null;
  
  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      $this.checkPatient();     
    }, 1000);
  }
}
