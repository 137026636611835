import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs/internal/Observable";
import { catchError } from "rxjs/internal/operators/catchError";
import { ReturnStruct } from "src/app/shared/structs/return.struct";
import { environment } from "src/environments/environment";
import { CommonService } from "../../common-service";
import { BillingAlertRequest } from "../../requests/billing-by-health-unit/billing-alert.request";
import { GetBillingAlertResponse } from "../../responses/billing-by-health-unit/get-billing-alert.response";
import { ListBillingAlertResponse } from "../../responses/billing-by-health-unit/list-billing-alert.response";

@Injectable({
  providedIn: 'root'
})
export class BillingAlertService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  listBillingTypeService(): Observable<ListBillingAlertResponse> {
    let uri = `BillingAlert/getLookup`

    return this.httpClient.get<ListBillingAlertResponse>(environment.urlApiBillingByHealthUnit + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  getBillingAlert(): Observable<GetBillingAlertResponse> {
    let uri = `BillingAlert`

    return this.httpClient.get<GetBillingAlertResponse>(environment.urlApiBillingByHealthUnit + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public postBillingAlert(billingAlertRequest: BillingAlertRequest): Observable<ReturnStruct>{
    let uri = `BillingAlert`;  
    
    return this.httpClient.post<ReturnStruct>(environment.urlApiBillingByHealthUnit + uri, billingAlertRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
  public putBillingAlert(billingAlertRequest: BillingAlertRequest): Observable<ReturnStruct>{
    let uri = `BillingAlert`;  
    
    return this.httpClient.put<ReturnStruct>(environment.urlApiBillingByHealthUnit + uri, billingAlertRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}