<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum' hasSecundaryHeader="true"
    [episode]="selectedEpisode" [hasCachedModule]="false" [cachedModuleName]="">
</app-menu>

<div class="container-body-secundary-header">
    <div *ngIf="listPatientResponse" class="indicator">
        <app-generic-indicator-display [iconColor]="'#85C443'" [mainInfo]="listPatientResponse?.totalTickets"
            description="Aguardando atendimento" [icon]="'person_add_alt'"></app-generic-indicator-display>
        <app-generic-indicator-display [iconColor]="'#85C443'" [mainInfo]="listPatientResponse?.averageWaitingTime"
            description="Tempo médio de espera última hora" [icon]="'schedule'"></app-generic-indicator-display>
        <app-generic-indicator-display [iconColor]="'#85C443'" [mainInfo]="listPatientResponse?.totalAttendedTicket"
            description="Episódios atendidos no dia" [icon]="'done_all'"></app-generic-indicator-display>
        <app-generic-indicator-display [iconColor]="'#85C443'" [mainInfo]="listPatientResponse?.totalEvadedTicket"
            description="Episódios evadidos no dia" [icon]="'remove_done'"></app-generic-indicator-display>
        <app-generic-indicator-display [iconColor]="'#85C443'" [mainInfo]="listPatientResponse?.returnEpisodes"
            description="Episódios aguardando Retorno" [icon]="'person_add_alt'"></app-generic-indicator-display>
        <app-generic-indicator-display [iconColor]="'#85C443'" [mainInfo]="listPatientResponse?.returnWaitingTimeMedian"
            description="Tempo médio de retorno" [icon]="'schedule'"></app-generic-indicator-display>
    </div>
    <div class="white-body">
        <div class="generic-call__info__container">
            <div class="row">
                <div class="col-md-9">
                    <span class="generic-call__info__container__selected-room"
                        *ngIf="selectedRoom">{{selectedRoom.sectorName}} - {{selectedRoom.roomName}}</span>
                    <span class="generic-call__info__container__selected-room" *ngIf="!selectedRoom">Selecionar
                        sala</span>
                    <mat-icon (click)="selectRoomModal()"
                        class="generic-call__info__container__edit-room">edit</mat-icon>
                </div>
            </div>
        </div>
        <div class="generic-call__table__container" *ngIf="selectedRoom && listPatientResponse">
            <div class="filters">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Pesquisar</mat-label>
                            <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText"
                                (keyup)="onKeySearch($event)">
                        </mat-form-field>
                    </div>
                    <app-attendance-status [isLoading]="isLoading" class="col-12 col-md-8"
                        [returnCount]="listPatientResponse?.returnEpisodes"
                        [scheduleCount]="listPatientResponse?.scheduleEpisodes"
                        (selectedStatus)="filterListPatient($event)"></app-attendance-status>
                </div>
            </div>
            <div *ngIf="listPatientResponse && listPatientResponse.listPatient.length >0">
                <table class="basic-table desktop">
                    <thead class="basic-table__head">
                        <tr>
                            <th>Episódio</th>
                            <th>Senha</th>
                            <th>Data/Hora</th>
                            <th>Linha de Cuidado</th>
                            <th>Prioridade</th>
                            <th>Nome</th>
                            <th>Data de nascimento</th>
                            <th>Gravidade</th>
                            <th>Espera</th>
                            <th>Situação</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody class="basic-table__body">
                        <tr class="basic-table__body__row" [class.queue-selected-patient]="item === selectedPatient"
                            (click)="selectPatient(item)"
                            *ngFor="let item of listPatientResponse.listPatient; let index = index;">
                            <td class="truncate"><span>{{item.idEpisode}}</span></td>
                            <td class="truncate">
                                <div class="display-in-ticket"><span>{{item.fullTicket}}</span>
                                    <span *ngIf="item.isSchedule" class="display-in-schedule">
                                        <mat-icon
                                            matTooltip="{{ item.dateStartSchedule | date: 'dd/MM/yyyy' }} - {{ item.dateStartSchedule | date: 'HH:mm' }}h"
                                            matTooltipPosition="above" class="reduce-icon-schedule">calendar_month
                                        </mat-icon>
                                    </span>
                                </div>
                            </td>
                            <td class="truncate"><span>{{item.ticketDatetimeInclusion | date: 'dd/MM/yyyy
                                    HH:mm'}}</span></td>
                            <td class="truncate">
                                <span *ngIf="item.idCareLinePriorityStatus != careLinePriorityStatusDenied"
                                    class="icon material-symbols-outlined"
                                    matTooltip="{{item.careLinePriorityName}}">{{item.careLinePriorityIcon}}</span>
                            </td>
                            <td class="truncate">
                                <span class="priority-icon" *ngFor="let priority of item.listPriority">
                                    <mat-icon class="icon"
                                        *ngIf="priority.idPriority !== this.overEightyYearsEnum && priority.idPriority !== this.overSixtyYearsEnum && priority.idPriority !== this.autismEnum">{{priority.icon}}</mat-icon>
                                    <img src="assets/images/autism-symbol.png" class="img-adjust"
                                        *ngIf="priority.idPriority === this.autismEnum" />
                                    <span class="icon"
                                        *ngIf="priority.idPriority === this.overEightyYearsEnum || priority.idPriority === this.overSixtyYearsEnum">{{priority.icon}}</span>
                                </span>
                            </td>
                            <td class="truncate"><span>{{item.patientSocialName ? item.patientSocialName :
                                    item.patientName}}</span></td>
                            <td class="truncate">
                                <span *ngIf="item.birthDate || item.patientAge">
                                    {{ item.birthDate | date: 'dd/MM/yyyy' }}
                                    <span *ngIf="item.birthDate && item.patientAge"> | </span>
                                    {{ item.patientAge }}
                                </span>
                            </td>
                            <td>
                                <div *ngIf="item.listTriage && item.listTriage.length > 0" class="gravity"
                                    [style.background-color]="item.listTriage[0].gravityColorCode"
                                    matTooltip="{{item.listTriage[0].gravityName}}"></div>
                            </td>

                            <td class="truncate" matTooltip="Tempo total na instituição: {{item.totalEpisodeTime}}">
                                <span>{{item.waitingTime}}</span>
                            </td>
                            <td class="truncate"
                                matTooltip="Responsável: {{item.attendantUserName}}&#13;Número de chamadas: {{item.ticketCallCount}}"
                                [matTooltipClass]="'my-tooltip'">
                                <app-status-bullet [idStatusQueue]="item.idStatusQueue"
                                    [statusQueueName]="item.statusQueueName"></app-status-bullet>
                            </td>
                            <td>
                                <div class="basic-table__body__row__action"
                                    *ngIf="item.isLoading == false && !isAttendedOrEvaded(item.idStatusQueue)">
                                    <mat-icon
                                        *ngIf="!isAttendedOrInAttendanceOrEvaded(item.idStatusQueue)"
                                        matTooltip="Atender Paciente"
                                        (click)="attendPatient(item, index)">person_add</mat-icon>
                                    <mat-icon matTooltip="Evadir Paciente"
                                        (click)="cancelPatient(item.idQueue, 2, item.idEpisode, index)">block</mat-icon>
                                </div>
                                <div class="basic-table__body__row__action"
                                    *ngIf="item.isLoading == false && isAttended(item.idStatusQueue)">
                                    <mat-icon matTooltip="Gerenciamento de Guias TISS"
                                        *ngIf="isAttended(item.idStatusQueue) && this.showTissGuideButton"
                                        (click)="openModalTiss(item)">description</mat-icon>
                                    <mat-icon *ngIf="item.reclassification && isInitiated(item.idEpisodeStatus)"
                                        matTooltip="Reclassificar"
                                        (click)="attendPatient(item, index, true)">person_add</mat-icon>
                                    <mat-icon *ngIf="reprintLoading == false" matTooltip="Reimprimir Fichas"
                                        (click)="reprintReports(item.idEpisode)">grading</mat-icon>
                                    <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                        *ngIf="reprintLoading == true"></mat-spinner>
                                </div>

                                <div class="basic-table__body__row__action"
                                    *ngIf="item.isLoading == false && isAttended(item.idStatusQueue)">
                                    <mat-icon matTooltip="Reimprimir Fichas"
                                        (click)="reprintReports(item.idEpisode)">grading</mat-icon>
                                </div>
                                <div>
                                    <mat-spinner [diameter]="30" *ngIf="item.isLoading == true"></mat-spinner>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <cdk-accordion class="accordion mobile">
                    <cdk-accordion-item *ngFor="let item of listPatientResponse.listPatient; let index = index;"
                        #accordionItem="cdkAccordionItem" class="accordion-item" role="button" tabindex="0"
                        [attr.id]="'accordion-header-' + index" [attr.aria-expanded]="accordionItem.expanded"
                        [attr.aria-controls]="'accordion-body-' + index"
                        [class.queue-selected-patient]="item === selectedPatient" (click)="selectPatient(item)">
                        <div [ngClass]="{'accordion-item-header-expanded': accordionItem.expanded}"
                            class="accordion-item-header" (click)="accordionItem.toggle()">
                            <div class="flex-space-between"><span
                                    class="truncate bold padding-right-0">Token</span><span
                                    class="truncate">{{item.fullTicket}}</span></div>
                            <div class="flex-space-between"><span class="truncate bold padding-right-0">Nome do
                                    paciente</span><span class="truncate">{{item.patientSocialName ?
                                    item.patientSocialName : item.patientName}}</span></div>
                            <div class="flex-space-between">
                                <span class="truncate bold padding-right-0">Linha de Cuidado</span>
                                <span class="material-symbols-outlined"
                                    matTooltip="{{item.careLinePriorityName}}">{{item.careLinePriorityIcon}}</span>
                            </div>
                            <div class="flex-space-between"><span class="truncate bold padding-right-0">Prioridade
                                    da
                                    senha</span>
                                <span class="truncate" *ngFor="let priority of item.listPriority">
                                    <mat-icon class="icon"
                                        *ngIf="priority.idPriority !== 1 && priority.idPriority !== 2">{{priority.icon}}</mat-icon>
                                    <span class="icon"
                                        *ngIf="priority.idPriority === 1 || priority.idPriority === 2">{{priority.icon}}</span>
                                </span>
                            </div>
                            <div class="flex-space-between"><span class="truncate bold padding-right-0">Gravidade</span>
                                <span class="truncate">
                                    <div *ngIf="item.listTriage && item.listTriage.length > 0" class="gravity"
                                        [style.background-color]="item.listTriage[0].gravityColorCode"></div>
                                </span>
                            </div>
                        </div>
                        <div class="accordion-item-body" role="region"
                            [style.display]="accordionItem.expanded ? '' : 'none'" [attr.id]="'accordion-body-' + index"
                            [attr.aria-labelledby]="'accordion-header-' + index">
                            <div class="feature-content">
                                <div class="padding-x">
                                    <div class="flex-space-between padding-y"><span
                                            class="truncate bold padding-right-0">Episódio</span> <span
                                            class="truncate">{{item.idEpisode}}</span></div>
                                    <div class="flex-space-between padding-y"><span
                                            class="truncate bold padding-right-0">Responsável pelo
                                            atendimento</span>
                                        <span class="truncate">{{item.attendantUserName}}</span>
                                    </div>
                                    <div class="flex-space-between padding-y"><span
                                            class="truncate bold padding-right-0">Nº de chamadas da senha</span>
                                        <span class="truncate">{{item.ticketCallCount}}</span>
                                    </div>
                                    <div class="flex-space-between padding-y"><span
                                            class="truncate bold padding-right-0">Retirada da senha</span> <span
                                            class="truncate">{{item.ticketDatetimeInclusion}}</span></div>
                                    <div class="flex-space-between padding-y"><span
                                            class="truncate bold padding-right-0">Tempo de espera</span> <span
                                            class="truncate">{{item.waitingTime}}</span></div>
                                    <div class="flex-space-between padding-y"><span
                                            class="truncate bold padding-right-0">Situação</span>
                                        <span class="truncate">
                                            <app-status-bullet [idStatusQueue]="item.idStatusQueue"
                                                [statusQueueName]="item.statusQueueName">
                                            </app-status-bullet>
                                        </span>
                                    </div>
                                </div>
                                <div class="basic-table__body__row__action"
                                    *ngIf="!isAttendedOrEvaded(item.idStatusQueue)">
                                    <mat-icon matTooltip="Chamar Paciente"
                                        (click)="callPatient(item.idEpisode, item.idQueue, index)">campaign</mat-icon>
                                    <mat-icon matTooltip="Atender Paciente"
                                        (click)="attendPatient(item, index)">person_add</mat-icon>
                                    <mat-icon matTooltip="Evadir Paciente"
                                        (click)="cancelPatient(item.idQueue, 2, item.idEpisode, index)">block</mat-icon>
                                </div>
                                <div class="basic-table__body__row__action"
                                    *ngIf="item.isLoading == false && item.reclassification && isAttended(item.idStatusQueue) && isInitiated(item.idEpisodeStatus)">
                                    <mat-icon matTooltip="Reclassificar"
                                        (click)="attendPatient(item, index, true)">person_add</mat-icon>
                                </div>
                            </div>
                        </div>
                    </cdk-accordion-item>
                </cdk-accordion>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12">
                <mat-paginator [length]="this.listPatientResponse.fullListSize" #paginator [pageIndex]="0"
                    [pageSize]="this.listPatientResponse.pageSize" aria-label="Select page" (page)="changePage($event)"
                    [hidePageSize]="true">
                </mat-paginator>
            </div>
        </div>
        <app-empty-list
            *ngIf="selectedRoom && listPatientResponse && listPatientResponse.listPatient.length == 0  && !isLoading"></app-empty-list>
        <app-not-seleted-room *ngIf="!selectedRoom"></app-not-seleted-room>
    </div>
    <app-loading-list *ngIf="isLoading"></app-loading-list>
</div>