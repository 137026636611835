import { Component, OnInit, Input, Renderer2, ElementRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BleScanModalComponent } from 'src/app/shared/components/ble-scan-modal/ble-scan-modal.component';
import { DeviceTypeEnum } from 'src/app/shared/enum/device-type.enum';
import { MeasurerTypeEnum } from 'src/app/shared/enum/measurer-type.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { RoomMeasurerService } from 'src/app/shared/services/API/risk-classification/room-measurer.service';
import { MeasurerModel } from 'src/app/shared/services/models/risk-classification/measurer.model';
import { BLEDevice } from 'src/app/shared/services/responses/bluetooth-api/ble-device';
import { RoomStruct } from 'src/app/shared/services/structs/flow/room.struct';
import { ProtocolSectorStruct } from 'src/app/shared/services/structs/risk-classification/protocol-sector.struct';
import { RoomMeasurerStruct } from 'src/app/shared/services/structs/risk-classification/room-measurer.struct';
import { UtilsMeasurerService } from '../../flowchart/protocol-v2/utils-measurer.service';

@Component({
  selector: 'app-room-measurer-select',
  templateUrl: './room-measurer-select.component.html',
  styleUrls: ['./room-measurer-select.component.css']
})
export class RoomMeasurerSelectComponent implements OnInit {

  @Input() idRoom: number;
  @Input() room: RoomStruct;
  @Input() sector: ProtocolSectorStruct;
  @Input() listOximeter: MeasurerModel[];
  @Input() listThermometer: MeasurerModel[];
  @Input() listPressureMeasurer: MeasurerModel[];
  @Input() listGlucometer: MeasurerModel[];
  @Input() formGroup: UntypedFormGroup;

  @Output() validateMacAddresses: EventEmitter<any>;

  constructor(
    private roomMeasurerService: RoomMeasurerService,
    private alertService: AlertService,
    public dialog: MatDialog,
    private renderer: Renderer2,
    private el: ElementRef,
    private measurerService: UtilsMeasurerService,
  ) { }

  public listRoomMeasurer: RoomMeasurerStruct[];
  public model: UntypedFormGroup;
  public bluetoothMeasurerType = DeviceTypeEnum.Bluetooth;
  public oximeterMeasurer: MeasurerTypeEnum = MeasurerTypeEnum.Oxímetro;
  public thermometerMeasurer: MeasurerTypeEnum = MeasurerTypeEnum.Termômetro;
  public glucometerMeasurer: MeasurerTypeEnum = MeasurerTypeEnum.Glicosímetro;
  public paMeasurer: MeasurerTypeEnum = MeasurerTypeEnum.PA;
  public oximeterDeviceBT: BLEDevice = null;
  public thermometerDeviceBT: BLEDevice = null;
  public glucometerDeviceBT: BLEDevice = null;
  public paDeviceBT: BLEDevice = null;

  ngOnInit(): void {
    this.model = this.formGroup;

    this.listRoomMeasurers();
  }

  listRoomMeasurers() {
    this.roomMeasurerService.listRoomMeasurer(this.room.idRoom).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        this.listRoomMeasurer = response.listRoomMeasurer;

        if (this.idRoom != undefined && this.idRoom === this.room.idRoom) {
          this.renderer.addClass(this.el.nativeElement.querySelector('#roomScroll'), 'valid-h3');
          const element = document.getElementById('roomScroll');
          if (element) {
            const windowHeight = window.innerHeight;
            const elementHeight = element.clientHeight;
            const verticalOffset = (elementHeight - windowHeight) / 2;
            const elementPosition = element.getBoundingClientRect().top + window.scrollY;
            const scrollToPosition = elementPosition - verticalOffset;
            window.scroll({ top: scrollToPosition, behavior: 'smooth' });
          }
        }

        this.model.get('idRoom').setValue(this.room.idRoom);



        response.listRoomMeasurer.forEach(x => {
          x.idOximeter ? this.model.get('idOximeter').setValue(x.idOximeter) : null;
          x.idThermometer ? this.model.get('idThermometer').setValue(x.idThermometer) : null;
          x.idGlucometer ? this.model.get('idGlucometer').setValue(x.idGlucometer) : null;
          x.idPressureMeasurer ? this.model.get('idPressureMeasurer').setValue(x.idPressureMeasurer) : null;
          if ((x.idDeviceType == DeviceTypeEnum.Trius || x.idDeviceType == DeviceTypeEnum.Berco || x.idDeviceType == DeviceTypeEnum.Bluetooth)) {
            this.measurerService.clearAllInterval();
          }
          if (x.idDeviceType == DeviceTypeEnum.Bluetooth && x.isBluetooth) {
            switch (x.idTypeMeasurer) {
              case MeasurerTypeEnum.Glicosímetro:
                this.model.get('addressGlucometer').setValue(x.macAddresss);
                this.model.get('nameGlucometer').setValue(x.name);
                this.glucometerDeviceBT = new BLEDevice(x.name, x.macAddresss);
                break;
              case MeasurerTypeEnum.Oxímetro:
                this.model.get('addressOximeter').setValue(x.macAddresss);
                this.model.get('nameOximeter').setValue(x.name);
                this.oximeterDeviceBT = new BLEDevice(x.name, x.macAddresss);
                break;
              case MeasurerTypeEnum.PA:
                this.model.get('addressPressureMeasurer').setValue(x.macAddresss);
                this.model.get('namePressureMeasurer').setValue(x.name);
                this.paDeviceBT = new BLEDevice(x.name, x.macAddresss);
                break;
              case MeasurerTypeEnum.Termômetro:
                this.model.get('addressThermometer').setValue(x.macAddresss);
                this.model.get('nameThermometer').setValue(x.name);
                this.thermometerDeviceBT = new BLEDevice(x.name, x.macAddresss);
                break;
            }
          }
        })
      }, error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  checkBluetooth(idMeasurer: number, type: number) {
    let listMeasurer: MeasurerModel[] = []
    switch (type) {
      case MeasurerTypeEnum.Glicosímetro:
        listMeasurer = this.listGlucometer
        break;
      case MeasurerTypeEnum.Oxímetro:
        listMeasurer = this.listOximeter
        break;
      case MeasurerTypeEnum.PA:
        listMeasurer = this.listPressureMeasurer
        break;
      case MeasurerTypeEnum.Termômetro:
        listMeasurer = this.listThermometer
        break;
    }

    let measurer = listMeasurer.find(x => x.idMeasurer == idMeasurer);

    if (measurer != undefined && measurer.isBluetooth) {
      const dialogRef = this.dialog.open(BleScanModalComponent);
      dialogRef.afterClosed().subscribe({
        next: result => {
          if (result && result.device) {
            switch (type) {
              case MeasurerTypeEnum.Glicosímetro:
                this.glucometerDeviceBT = result.device;
                this.model.get('addressGlucometer').setValue(result.device.address)
                this.model.get('nameGlucometer').setValue(result.device.name)
                break;
              case MeasurerTypeEnum.Oxímetro:
                this.oximeterDeviceBT = result.device;
                this.model.get('addressOximeter').setValue(result.device.address)
                this.model.get('nameOximeter').setValue(result.device.name)
                break;
              case MeasurerTypeEnum.PA:
                this.paDeviceBT = result.device;
                this.model.get('addressPressureMeasurer').setValue(result.device.address)
                this.model.get('namePressureMeasurer').setValue(result.device.name)
                break;
              case MeasurerTypeEnum.Termômetro:
                this.thermometerDeviceBT = result.device;
                this.model.get('addressThermometer').setValue(result.device.address);
                this.model.get('nameThermometer').setValue(result.device.name);
                break;
            }
          }
        }
      });
    }
    else {
      switch (type) {
        case MeasurerTypeEnum.Glicosímetro:
          this.glucometerDeviceBT = null;
          this.model.get('addressGlucometer').setValue(null)
          this.model.get('nameGlucometer').setValue(null)
          break;
        case MeasurerTypeEnum.Oxímetro:
          this.oximeterDeviceBT = null;
          this.model.get('addressOximeter').setValue(null)
          this.model.get('nameOximeter').setValue(null)
          break;
        case MeasurerTypeEnum.PA:
          this.paDeviceBT = null;
          this.model.get('addressPressureMeasurer').setValue(null)
          this.model.get('namePressureMeasurer').setValue(null)
          break;
        case MeasurerTypeEnum.Termômetro:
          this.thermometerDeviceBT = null;
          this.model.get('addressThermometer').setValue(null)
          this.model.get('nameThermometer').setValue(null)
          break;
      }
      return;
    }
  }
}