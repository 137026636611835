import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { EquipmentTypeService } from 'src/app/shared/services/API/form/equipment-type.service';
import { EquipmentService } from 'src/app/shared/services/API/form/equipment.service';
import { EquipmentTypeModel } from 'src/app/shared/services/models/form/equipment-type.model';
import { EquipmentStruct } from 'src/app/shared/services/structs/form/equipment.struct';
import { UtilService } from 'src/app/shared/services/util.service';
import { EquipmentDeleteModalComponent } from './equipment-delete-modal/equipment-delete-modal.component';

@Component({
  selector: 'app-equipment-list',
  templateUrl: './equipment-list.component.html',
  styleUrls: ['./equipment-list.component.css']
})
export class EquipmentListComponent implements OnInit {

  constructor(private equipmentService: EquipmentService,
    private equipmentTypeService: EquipmentTypeService,
    private router: Router,
    private alertService: AlertService,
    private utilService: UtilService,
    public dialog: MatDialog
  ) { }
    
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.form;
  public menuFunctionalityEnum:MenuFunctionalityEnum = MenuFunctionalityEnum.form_equipment;
    
  public listEquipment: EquipmentStruct[];
  public listEquipmentType: EquipmentTypeModel[];
  public isLoading: boolean;
  public isActive: boolean;
  public searchText: string;
  public idEquipmentType: number;
  public fullListSize: number;

  ngOnInit(): void {
    this.isLoading = true;
    this.populateEquipmentTypeSelect();
    this.search(0);
  }
  search(pageIndex :number){
    this.isLoading = true;  
    if(this.searchText == "")
    this.searchText = null;
    
    this.equipmentService.listEquipment(this.searchText, this.isActive, pageIndex).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.fullListSize = response.listEquipmentStruct.count;
      this.listEquipment = response.listEquipmentStruct.listEquipment;  
    
      this.isLoading = false;    
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  populateEquipmentTypeSelect(){
    var searchText = null;
    this.equipmentTypeService.listEquipmentType(searchText).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        
        return;
      }
      
      this.listEquipmentType = response.list;     
    },
    (error)=>{
      console.log(error)    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  private timeoutKeySearch: any = null;
  
  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search(0);
      }
    }, 1000);
  }
  
  openModal(idEquipment){
    const dialogRef = this.dialog.open(EquipmentDeleteModalComponent, {
      data: {
        idEquipment: idEquipment
      },
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.deleteEquipment){
        this.search(0);
      }
    });
  }

  changePage(event: PageEvent) {
    this.search(event.pageIndex);
  }
}
