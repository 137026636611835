<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <a class="back-link" routerLink="/audit/audit">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Módulo de Auditoria
        </a>
        <h1>Configurações de auditorias</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body" *ngIf="!isFirstLoading">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="title-header">
                    <h1>Configurações gerais</h1>
                </div>
                <div class="col-12" style="display: flex;">
                    <div class="col-6">
                        <div class="row">
                            <div class="col-12">
                                <mat-slide-toggle class="allow-audited" formControlName="allowAudited">
                                    Permitir selecionar enfermeiros que serão auditados
                                </mat-slide-toggle>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <mat-slide-toggle class="allow-audited" formControlName="viewTimeTag">
                                    Alerta para auditoria com duração maior que 3 minutos
                                </mat-slide-toggle>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="row">
                            <div class="col-12">
                                <mat-slide-toggle class="allow-audited" formControlName="displayCarePriorityInRiskClassification">
                                    Exibir linhas de cuidado em prioridade na CR
                                </mat-slide-toggle>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="title-header">
                    <h1>Cadastro de motivos para auditoria correta</h1>
                </div>
                <div *ngFor="let item of listCorrectClassificationReasonDefault; let i = index">
                    <div class="row">
                        <div class="col-9">
                            <mat-form-field appearance="outline">
                                <mat-label>Descrição</mat-label>
                                <input matInput type="text" [disabled]="true" [value]="item.description">
                            </mat-form-field>
                        </div>
                        <div class="col-2">
                            <button class="exception-button" color="primary" mat-mini-fab type="button"
                                (click)="addNextCorrectReason()"
                                *ngIf="0 == (model.get('listCorrectClassificationReasonNotDefault')['controls'].length) && i ==  listCorrectClassificationReasonDefault.length - 1">
                                <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div formArrayName="listCorrectClassificationReasonNotDefault"
                    *ngFor="let item of model.get('listCorrectClassificationReasonNotDefault')['controls']; let i = index">
                    <div class="row" [ngClass]="{'list-incorrect': i > 1}" [formGroupName]="i">
                        <div class="col-9">
                            <mat-form-field appearance="outline">
                                <mat-label>Descrição</mat-label>
                                <input maxlength="150" matInput type="text" formControlName="description">
                                <mat-error *ngIf="item.get('description').invalid">Informe a descrição</mat-error>
                            </mat-form-field>
                            <span class="description-count">
                                {{ model.controls['listCorrectClassificationReasonNotDefault']['controls'][i] &&
                                model.controls['listCorrectClassificationReasonNotDefault']['controls'][i].value.description
                                ?
                                model.controls['listCorrectClassificationReasonNotDefault']['controls'][i].value.description.length
                                : 0
                                }}/150 caracteres
                            </span>
                        </div>
                        <div class="col-3">
                            <button class="exception-button" color="primary" mat-mini-fab type="button"
                                (click)="addNextCorrectReason()"
                                *ngIf="(i + 1) == (model.get('listCorrectClassificationReasonNotDefault')['controls'].length)">
                                <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
                            </button>
                            <button class="exception-button " color="primary" mat-mini-fab type="button"
                                (click)="openRemovePriorReasonModal(i, true, false)">
                                <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="title-header">
                    <h1>Cadastro de motivos para auditoria incorreta</h1>
                </div>
                <div *ngFor="let item of listIncorrectClassificationReasonDefault; let i = index">
                    <div class="row">
                        <div class="col-9">
                            <mat-form-field appearance="outline">
                                <mat-label>Descrição</mat-label>
                                <input matInput type="text" [disabled]="true" [value]="item.description">
                            </mat-form-field>
                        </div>
                        <div class="col-2">
                            <button class="exception-button" color="primary" mat-mini-fab type="button"
                                (click)="addNextIncorrectReason()"
                                *ngIf="0 == (model.get('listIncorrectClassificationReasonNotDefault')['controls'].length) && i ==  listIncorrectClassificationReasonDefault.length - 1">
                                <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div formArrayName="listIncorrectClassificationReasonNotDefault"
                    *ngFor="let item of model.get('listIncorrectClassificationReasonNotDefault')['controls']; let i = index">
                    <div class="row" [ngClass]="{'list-incorrect': i > 1}" [formGroupName]="i">
                        <div class="col-9">
                            <mat-form-field appearance="outline">
                                <mat-label>Descrição</mat-label>
                                <input maxlength="150" matInput type="text" formControlName="description">
                                <mat-error *ngIf="item.get('description').invalid">Informe a descrição</mat-error>
                            </mat-form-field>
                            <span class="description-count">
                                {{ model.controls['listIncorrectClassificationReasonNotDefault']['controls'][i] &&
                                model.controls['listIncorrectClassificationReasonNotDefault']['controls'][i].value.description
                                ?
                                model.controls['listIncorrectClassificationReasonNotDefault']['controls'][i].value.description.length
                                : 0
                                }}/150 caracteres
                            </span>
                        </div>
                        <div class="col-3">
                            <button class="exception-button" color="primary" mat-mini-fab type="button"
                                (click)="addNextIncorrectReason()"
                                *ngIf="(i + 1) == (model.get('listIncorrectClassificationReasonNotDefault')['controls'].length)">
                                <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
                            </button>
                            <button class="exception-button " color="primary" mat-mini-fab type="button"
                                (click)="openRemovePriorReasonModal(i, false, true)">
                                <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <a mat-flat-button type="button" routerLink="/audit" color="accent" class=" btn-block">
                            Cancelar
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>