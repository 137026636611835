import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { VariableRequest } from '../../requests/flow/variable.request';
import { GetListVariableResponse } from '../../responses/flow/get-list-variable.response';
import { SelectVariablesFlow } from '../../responses/flow/select-variables-flow';

@Injectable({
  providedIn: 'root'
})
export class VariableService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getVariables(body: VariableRequest): Observable<GetListVariableResponse>{

    let uri = `Variable/getAllByOrigin`

    return this.httpClient.post<GetListVariableResponse>(environment.urlApiFlow + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public getValuesVariables(url:string): Observable<SelectVariablesFlow>{
    return this.httpClient.get<SelectVariablesFlow>(environment.urlApiBase + url, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
