import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { DataSourceService } from 'src/app/shared/services/API/data-intelligente/data-source.service';
import { GraphService } from 'src/app/shared/services/API/data-intelligente/graph.service';

@Component({
  selector: 'app-graph-delete-modal',
  templateUrl: './graph-delete-modal.component.html',
  styleUrls: ['./graph-delete-modal.component.css']
})
export class GraphDeleteModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<GraphDeleteModalComponent>,
    private graphService: GraphService,
    private alertService: AlertService) { }

  public isLoading: boolean;
  private hasDeteled: boolean = false;
  ngOnInit(): void {
    this.isLoading = false;
  }

  clickDelete() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.hasDeteled = true;

    this.graphService.delete(this.data.idGraph).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Gráfico excluído com sucesso!", AlertType.success);
      this.matDialogRef.close({ graphDeleted: this.hasDeteled });
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  clickCancel() {
    this.matDialogRef.close({ graphDeleted: this.hasDeteled });
  }
}

