export class ExpressionStruct {
    public idExpression: number;
    
    public idDataSource: number;
    
    public idConjunction: number;
    
    public idDataSourceVariable: number;
    
    public idLogicalExpression: number;
    
    public value: string;
    
    public variableType: number;
}