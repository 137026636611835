import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { TotemRequest } from '../../../shared/services/requests/totem/totem.request';
import { TotemService } from 'src/app/shared/services/API/totem/totem.service';
import { TotemButtonStruct } from 'src/app/shared/services/structs/totem/totem-button.struct';
import { PriorityService } from 'src/app/shared/services/API/totem/priority.service';
import { PriorityModel } from 'src/app/shared/services/models/totem/priority.model';
import { CareLinePriority } from 'src/app/shared/services/models/care-line-priority/care-line-priority.model';
import { GetCareLinePriorityService } from 'src/app/shared/services/API/care-line-priority/get-care-line-priority.service';
import { Observable, catchError, forkJoin, of, tap } from 'rxjs';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-totem-register',
  templateUrl: './totem-register.component.html',
  styleUrls: ['./totem-register.component.css']
})
export class TotemRegisterComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private totemService: TotemService,
    private priorityService: PriorityService,
    private getCareLinePriorityService: GetCareLinePriorityService,
    private utilService: UtilService,
  ) {
  }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.master;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.master_totem;

  public model: FormGroup;
  public isLoading: boolean = true;
  public isFirstLoading: boolean;
  public isUpdate: boolean = false;
  public useQrCode: boolean = false;
  public isScheduleToggle: boolean = false;
  public masks: Masks = this.maskService.getMasks();
  public listPriority: PriorityModel[];
  public listIdPriority: number[];
  public listPrioritiesNames: PriorityModel[];
  public idTotem: number = null;
  public totemRequest: TotemRequest = new TotemRequest();

  public listCareLinePriority: CareLinePriority[] = [];
  ip: string = '192.168.0.2';

  ngOnInit(): void {
    forkJoin({
      careLinePriority: this.populateCareLinePrioritySelect(),
      priority: this.populatePrioritySelect()
    }).subscribe({
      next: ({ careLinePriority, priority }) => {
        if (this.idTotem != null) {
          this.isFirstLoading = true;
          this.isUpdate = true;
          this.populateTotemData();
        }
        else {
          this.isLoading = false;

          this.addNext();
        }
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    });

    if (this.activatedRoute.snapshot.paramMap.get('idTotem'))
      this.idTotem = parseInt(this.activatedRoute.snapshot.paramMap.get('idTotem'));

    this.model = this.formBuilder.group({
      totemName: ['', [Validators.required]],
      description: [''],
      printerName: ['', [Validators.required]],
      isActive: ['', [Validators.required]],
      listButtons: this.formBuilder.array([]),
      listIdPriority: [],
      useQrCode: [false]
    });

    setTimeout(() => { this.getIP(); }, 2000);
  }

  getIP() {
    this.utilService.getIP().subscribe({
      next: (response) => {
        if (response && response.ip) {
          this.ip = response.ip;
        }
      }
    });
  }

  submit() {
    if (this.isLoading)
      return;

    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    this.isLoading = true;
    this.totemRequest.listButtons = [];

    if (this.model.get('listIdPriority').value)
      this.totemRequest.listIdPriorityRequired = this.model.get('listIdPriority').value.map(c => parseInt(c));

    this.totemRequest.totemName = this.model.get('totemName').value;
    this.totemRequest.printerName = this.model.get('printerName').value;
    this.totemRequest.description = this.model.get('description').value;
    this.totemRequest.isActive = this.model.get('isActive').value === "true" ? true : false;
    this.totemRequest.useQrCode = this.model.get('useQrCode').value;
    this.totemRequest.ip = this.ip;

    this.model.get('listButtons').value.forEach(x => {
      var button = new TotemButtonStruct();

      if (x.idButton)
        button.idTotemButton = parseInt(x.idButton);
      else
        button.idTotemButton = null;

      button.totemButtonName = x.buttonName;
      button.initials = x.initials;
      button.priorityInitials = x.priorityInitials;
      button.idCareLinePriority = x.idCareLinePriority;
      button.isScheduleToggle = x.isScheduleToggle;

      this.totemRequest.listButtons.push(button);
    });

    if (this.isUpdate)
      this.updateTotem();
    else
      this.createTotem();
  }

  populateTotemData() {
    this.totemService.getTotem(this.idTotem).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
          return;
        }

        if (response.listIdPriorityRequired && response.listIdPriorityRequired.length > 0)
          this.model.get('listIdPriority').setValue(response.listIdPriorityRequired.map(c => c.toString()));

        this.model.get('isActive').setValue(response.totemStruct.isActive.toString());
        this.model.get('description').setValue(response.totemStruct.description);
        this.model.get('totemName').setValue(response.totemStruct.totemName);
        this.model.get('printerName').setValue(response.totemStruct.printerName);
        this.model.get('useQrCode').setValue(response.totemStruct.useQrCode);

        response.totemStruct.listTotemButton.forEach(x => {
          (this.model.controls['listButtons'] as FormArray).push(
            this.formBuilder.group({
              idButton: [x.idTotemButton.toString()],
              buttonName: [x.totemButtonName.toString()],
              initials: [x.initials],
              priorityInitials: [x.priorityInitials],
              idCareLinePriority: x.idCareLinePriority !== null && this.listCareLinePriority.some(item => item.idCareLinePriority === x.idCareLinePriority) ? x.idCareLinePriority : null,
              isScheduleToggle: [x.isScheduleToggle]
            })
          )
        });

        this.isLoading = false;
        this.isFirstLoading = false;
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    });
  }

  updateTotem() {
    this.totemService.updateTotem(this.idTotem, this.totemRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.alertDynamic('Sucesso', "Informações salvas com sucesso", AlertType.success);

        this.router.navigate(['/master/totem']);
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    });
  }

  createTotem() {
    this.totemService.createTotem(this.totemRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.alertDynamic('Sucesso', "Informações salvas com sucesso", AlertType.success);

        this.router.navigate(['/master/totem']);
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    });
  }

  createInput() {
    return this.formBuilder.group({
      idButton: [''],
      buttonName: ['', [Validators.required]],
      initials: ['', [Validators.required]],
      priorityInitials: ['', [Validators.required]],
      idCareLinePriority: [],
      isScheduleToggle: [false]
    });
  }

  addNext() {
    (this.model.controls['listButtons'] as FormArray).push(this.createInput());
  }

  removeButton(index: number) {
    if (index != 0)
      (this.model.controls['listButtons'] as FormArray).removeAt(index);
  }

  populatePrioritySelect(): Observable<any> {
    return this.priorityService.listPriority().pipe(
      tap(response => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
        }

        this.listPriority = response.listPriority;
      }),
      catchError(error => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
        return of(null);
      })
    );
  }

  populateCareLinePrioritySelect(): Observable<any> {
    return this.getCareLinePriorityService.getAllByIdHealthUnit().pipe(
      tap(response => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
        }

        this.listCareLinePriority = response.listCareLinePriority;
      }),
      catchError(error => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
        return of(null);
      })
    );
  }

  alertDynamic(alertTypeDescription: string, alertDescription: string, alertType: AlertType) {
    if (alertType && alertType.valueOf() == AlertType.error)
      console.log(alertDescription);

    this.alertService.show(alertTypeDescription, alertDescription, alertType ? alertType.valueOf() : AlertType.error);
    this.endLoading();
  }

  endLoading() {
    this.isFirstLoading = false;
    this.isLoading = false;
  }
}