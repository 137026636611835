import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MedicineTemplateService } from 'src/app/shared/services/API/pharmacy/medicine-template.service';
import { TemplateModel } from 'src/app/shared/services/models/pharmacy/template.model';
import { TemplateStruct } from 'src/app/shared/services/structs/pharmacy/template.struct';
import { TemplateConfirmModalComponent } from './template-confirm-modal/template-confirm-modal.component';

@Component({
  selector: 'app-medicine-template-modal',
  templateUrl: './medicine-template-modal.component.html',
  styleUrls: ['./medicine-template-modal.component.css']
})
export class MedicineTemplateModalComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<MedicineTemplateModalComponent>,
    private medicineTemplateService: MedicineTemplateService,
    private alertService: AlertService,
  ) { }

  isLoading: boolean;
  idTemplate: number;
  listTemplate: TemplateModel[];
  templateStruct: TemplateStruct;

  ngOnInit(): void {
    this.isLoading = false;
    this.populateTemplateSelect();
  }

  populateTemplateSelect() {
    this.medicineTemplateService.listTemplate(null).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }

      this.listTemplate = response.listTemplate;

      this.isLoading = false

    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  visualizeTemplate() {
    this.isLoading = true;
    this.medicineTemplateService.getTemplate(this.idTemplate).subscribe((response) => {
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.templateStruct = response.templateStruct;
      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
    
  }

  clickApplyTemplate() {
    this.medicineTemplateService.insertTemplate(this.idTemplate).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Template aplicado com sucesso!!", AlertType.success);
      this.isLoading = false;

    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  openConfirmModal(idTemplate){
    if (this.isLoading) {
      return;
    }
    const dialogRef = this.dialog.open(TemplateConfirmModalComponent, {
      data: {
        idTemplate: idTemplate
      },
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.applyTemplate){
        this.matDialogRef.close({ applyTemplate: true });
      }
    });
  }

  clickCancel() {
    this.matDialogRef.close({ applyTemplate: false });
  }
}
