import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ObservationRoutingModule } from './observation-routing.module';
import { ObservationComponent } from './pages/observation/observation.component';
import { BedListComponent } from './pages/bed-list/bed-list.component';
import { BedRegisterComponent } from './pages/bed-register/bed-register.component';
import { BedDeleteModalComponent } from './pages/bed-list/bed-delete-modal/bed-delete-modal.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckbox, MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioButton, MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule, MatTree } from '@angular/material/tree';
import { NgxMaskModule } from 'ngx-mask';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { SharedModule } from '../shared/shared.module';
import { ObsertavionPatientListComponent } from './pages/obsertavion-patient-list/obsertavion-patient-list.component';
import { ObservationPatientListLinkBedModalComponent } from './pages/obsertavion-patient-list/observation-patient-list-link-bed-modal/observation-patient-list-link-bed-modal.component';
import { BedManagementComponent } from './pages/bed-management/bed-management.component';
import { BedManagementDetailsComponent } from './pages/bed-management-details/bed-management-details.component';
import { NursingConsultationAccordionComponent } from './pages/bed-management-details/nursing-consultation-accordion/nursing-consultation-accordion.component';
import { MedicalCareAccordionComponent } from './pages/bed-management-details/medical-care-accordion/medical-care-accordion.component';
import { ChangeBedEpisodeModalComponent } from './pages/bed-management-details/change-bed-episode-modal/change-bed-episode-modal.component';
import { ChangeBedEpisodeConfirmModalComponent } from './pages/bed-management-details/change-bed-episode-modal/change-bed-episode-confirm-modal/change-bed-episode-confirm-modal.component';
import { NewNursingConsultationComponent } from './pages/bed-management-details/new-nursing-consultation/new-nursing-consultation.component';
import { PresciptionListComponent } from './pages/bed-management-details/presciption-list/presciption-list.component';
import { MedicalPrescriptionModalComponent } from './pages/bed-management-details/presciption-list/medical-prescription-modal/medical-prescription-modal.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatPaginatorModule } from '@angular/material/paginator';
import { EpisodeAdministrativeReleaseConfirmModalComponent } from './pages/bed-management-details/episode-administrative-release-confirm-modal/episode-administrative-release-confirm-modal.component';
import { ExternalPrescriptionListComponent } from './pages/bed-management-details/external-prescription-list/external-prescription-list.component';
import { ExternalPrescriptionModalComponent } from './pages/bed-management-details/external-prescription-list/external-prescription-modal/external-prescription-modal.component';
import { BedManagementListPendingChecksComponent } from './pages/bed-management/bed-management-prescription-tab/bed-management-list-pending-checks/bed-management-list-pending-checks.component';
import { EpisodeReleaseConfirmModalComponent } from './pages/bed-management-details/episode-release-confirm-modal/episode-release-confirm-modal.component';
import { ReleaseBedEpisodeModalComponent } from './pages/bed-management-details/release-bed-episode-modal/release-bed-episode-modal.component';
import { ObservationConfigListComponent } from './pages/observation-config-list/observation-config-list.component';
import { ObservationConfigRegisterComponent } from './pages/observation-config-register/observation-config-register.component';
import { ObservationConfigDeleteModalComponent } from './pages/observation-config-list/observation-config-delete-modal/observation-config-delete-modal.component';
import { BedManagementPrescriptionTabComponent } from './pages/bed-management/bed-management-prescription-tab/bed-management-prescription-tab.component';
import { BedManagementProcedureTabComponent } from './pages/bed-management/bed-management-procedure-tab/bed-management-procedure-tab.component';
import { PatientBarCodeModalComponent } from '../shared/components/patient-bar-code-modal/patient-bar-code-modal.component';
import { ObservationMedicineBarCodeModalComponent } from './pages/bed-management-details/observation-medicine-bar-code-modal/observation-medicine-bar-code-modal.component';
import { BedManagementListProcedureChecksComponent } from './pages/bed-management/bed-management-procedure-tab/bed-management-list-pending-checks/bed-management-list-procedure-checks.component';
import { MedicalProcedureModalComponent } from './pages/bed-management-details/procedure-list/medical-procedure-modal/medical-procedure-modal.component';
import { ProcedureListComponent } from './pages/bed-management-details/procedure-list/procedure-list.component';
import { MedicalProcedureDeleteModalComponent } from './pages/bed-management-details/medical-procedure-delete-modal/medical-procedure-delete-modal.component';
import { BedManagementMaterialHistoryModalComponent } from './pages/bed-management-details/bed-management-material-history-modal/bed-management-material-history-modal.component';
import { BedManagementMaterialCreateModalComponent } from './pages/bed-management-details/bed-management-material-create-modal/bed-management-material-create-modal.component';
import { BedManagementProcedureModalComponent } from './pages/bed-management-details/bed-management-procedure-modal/bed-management-procedure-modal.component';
import { BedManagementMaterialHistoryDeleteModal } from './pages/bed-management-details/bed-management-material-history-modal/bed-management-material-history-delete-modal.component/bed-management-material-history-delete-modal.component';
import { ObservationProceduresModalComponent } from './pages/observation-config-list/observation-procedures-modal/observation-procedures-modal.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { BedHygienizationComponent } from './pages/bed-hygienization/bed-hygienization.component';
import { HygienizationModalComponent } from './pages/bed-hygienization/hygienization-modal/hygienization-modal.component';
import { HygienizationConfirmModalComponent } from './pages/bed-hygienization/hygienization-confirm-modal/hygienization-confirm-modal.component';
import { HygienizationTotemListComponent } from './pages/hygienization-totem-list/hygienization-totem-list.component';
import { HygienizationTotemRegisterComponent } from './pages/hygienization-totem-register/hygienization-totem-register.component';
import { HygienizationTotemDeleteModalComponent } from './pages/hygienization-totem-list/hygienization-totem-delete-modal/hygienization-totem-delete-modal.component';
import { PrescricptionCheckHistoryModalComponent } from './pages/bed-management-details/presciption-list/medical-prescription-modal/prescricption-check-history-modal/prescricption-check-history-modal.component';
import { MemedPrescriptionListComponent } from './pages/bed-management-details/memed-prescription-list/memed-prescription-list.component';
import { BedManagementMaterialValidateModal } from './pages/bed-management-details/bed-management-material-history-modal/bed-management-material-validate-modal.component/bed-management-material-validate-modal.component';
import { QuillModule } from 'ngx-quill';

@NgModule({
  declarations: [
    ObservationComponent,
    BedListComponent,
    BedRegisterComponent,
    BedDeleteModalComponent,
    BedHygienizationComponent,
    ObsertavionPatientListComponent,
    ObservationPatientListLinkBedModalComponent,
    BedManagementComponent,
    BedManagementDetailsComponent,
    NursingConsultationAccordionComponent,
    MedicalCareAccordionComponent,
    ChangeBedEpisodeModalComponent,
    ChangeBedEpisodeConfirmModalComponent,
    NewNursingConsultationComponent,
    PresciptionListComponent,
    MedicalPrescriptionModalComponent,
    PatientBarCodeModalComponent,
    ObservationMedicineBarCodeModalComponent,
    EpisodeAdministrativeReleaseConfirmModalComponent,
    ExternalPrescriptionListComponent,
    ExternalPrescriptionModalComponent,
    BedManagementListPendingChecksComponent,
    BedManagementListProcedureChecksComponent,
    EpisodeReleaseConfirmModalComponent,
    ReleaseBedEpisodeModalComponent,
    ObservationConfigListComponent,
    ObservationConfigRegisterComponent,
    ObservationConfigDeleteModalComponent,
    BedManagementPrescriptionTabComponent,
    BedManagementProcedureTabComponent,
    ProcedureListComponent,
    MedicalProcedureModalComponent,
    BedManagementProcedureModalComponent,
    MedicalProcedureDeleteModalComponent,
    BedManagementMaterialHistoryModalComponent,
    BedManagementMaterialCreateModalComponent,
    BedManagementMaterialHistoryDeleteModal,
    BedManagementMaterialValidateModal,
    ObservationProceduresModalComponent,
    BedHygienizationComponent,
    HygienizationModalComponent,
    HygienizationConfirmModalComponent,
    HygienizationTotemListComponent,
    HygienizationTotemRegisterComponent,
    HygienizationTotemDeleteModalComponent,
    PrescricptionCheckHistoryModalComponent,
    MemedPrescriptionListComponent,
  ],
  imports: [
    CommonModule,
    ObservationRoutingModule,
    SharedModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonModule,
    NgxMaskModule.forRoot(),
    MatCheckboxModule,
    MatTreeModule,
    MatRadioModule,
    ClipboardModule,
    MatTooltipModule,
    MatToolbarModule,
    NgxMatFileInputModule,
    CdkAccordionModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatPaginatorModule,
    MatTabsModule,
    MatDialogModule,
    QuillModule.forRoot(),
  ],
  providers: [
    AuthGuard,
    MatCheckbox,
    MatCheckboxChange,
    MatTree,
    MatRadioButton,
  ],
})
export class ObservationModule { }
