import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilsClientService } from 'src/app/client/utils.service';
import { TotemButtonStruct } from "src/app/shared/services/structs/totem/totem-button.struct";

@Component({
  selector: 'app-totem-custom-list',
  templateUrl: './totem-custom-list.component.html',
  styleUrls: ['./totem-custom-list.component.css']
})
export class TotemCustomListComponent implements OnInit {

  @Input() listTotemButton: TotemButtonStruct[];
  @Output() onSelect: EventEmitter<any> = new EventEmitter();

  constructor(private utilsClientService: UtilsClientService,) { }

  ngOnInit(): void {
  }

  selectOption(idTotemButton: number) {   
    this.utilsClientService.setIsButtonSchedule(this.listTotemButton.find(x=>x.idTotemButton == idTotemButton).isScheduleToggle);

    this.onSelect.emit(idTotemButton);
  }
}