<form class="form" [formGroup]="searchModel">
    <div class="title-header">
        <div class="row">
            <div class="col-6 col-sm-6">
                <h1>Buscar por CNS</h1>
            </div>
        </div>
    </div>
    <span class="pin-intro">Informe aqui o CNS</span>
    <div class="row">   
        <div class="col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>CNS</mat-label>
                <input #cnsInput matInput type="text" formControlName="cns" [mask]="masks.cns">
                <mat-error *ngIf="searchModel.get('cns').invalid">Informe o CNS</mat-error>
            </mat-form-field>
        </div>
    </div>
    
    <div class="row">
        <div class="col-12 col-sm-3">
            <button mat-flat-button (click)="searchByCNS()" color="primary" class=" btn-block">
                <span *ngIf="isLoading == false">Buscar</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
        </div>
    </div>
</form>
