<div [formGroup]="model">
    <div class="title-header">
        <h1>Novo Atendimento</h1>
    </div>
    <!-- <div class="row" *ngIf="hasAlergies">
        <div class="col-12 col-sm-12 col-md-12">
            <app-select-2 [formControlField]="'listIdAllergies'" (selectedValue)="addItemAllergies($event)"
                [multiple]="true" [url]="urlListAllergies" [idField]="'idAllergy'" [nameField]="'allergyName'"
                [labelField]="'Alergias'" [preSelectedlist]="allergies" [preSelectedBlock]="true"></app-select-2>
        </div>
    </div> -->
    <div class="row" *ngIf="hasAlergies">
        <div class="col-12 col-sm-12 col-md-12">
            <app-allergy-tag [inputListTags]="allergiesInput" [listTags]="allergiesToSave" [isError]="allergyError"
                (updateAllergiesToSave)="updateAllergiesToSave($event)">
            </app-allergy-tag>
        </div>
    </div>
    <div class="row" *ngIf="hasPriorityLineOfCare">
        <div class="col-12 care-line-priority" *ngIf="this.listCareLinePriority.length > 0">
            <mat-form-field appearance="outline">
                <mat-label>Linha de Cuidado em prioridade</mat-label>
                <mat-select formControlName="idCareLinePriority">
                    <mat-option *ngFor="let item of listCareLinePriority" [value]="item.idCareLinePriority">
                        {{item.careLinePriorityName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row" *ngIf="hasEvolution">
        <div class="col-12 col-sm-12 col-md-12">
            <mat-form-field appearance="outline">
                <mat-label>Evolução do paciente</mat-label>
                <textarea matInput formControlName="evolution"></textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="row" *ngIf="hasMedicalNotes">
        <div class="col-12 col-sm-12 col-md-12">
            <mat-form-field appearance="outline">
                <mat-label>Anotações médicas (queixas, história da doença atual, medicamentos em uso, história
                    pregressa)</mat-label>
                <textarea matInput formControlName="medicalNotes"></textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="row" *ngIf="hasPhysicalExamination">
        <div class="col-12 col-sm-12 col-md-12">
            <mat-form-field appearance="outline">
                <mat-label>Exame físico</mat-label>
                <textarea matInput formControlName="physicalExamination"></textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="row" *ngIf="hasCid">
        <div class="col-12 col-sm-12 col-md-12">
            <app-select-2 [formControlField]="'cid'" (selectedValue)="addItemCid($event)" [multiple]="true"
                [url]="urlListCid" [idField]="'idCid'" [nameField]="'cidName'" [labelField]="'CID'">
            </app-select-2>
        </div>
    </div>
    <div class="row" *ngIf="hasTherapeuticPlan">
        <div class="col-12 col-sm-12 col-md-12">
            <mat-form-field appearance="outline">
                <mat-label>Plano Terapêutico</mat-label>
                <textarea matInput formControlName="therapeuticPlan"></textarea>
            </mat-form-field>
        </div>
    </div>

    <div class="row" *ngIf="hasDiagnosedHypothesis">
        <div class="col-12 col-sm-12 col-md-12">
            <mat-form-field appearance="outline">
                <mat-label>Hipótese Diagnóstica</mat-label>
                <textarea matInput formControlName="diagnosedHypothesis"></textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="row" *ngIf="hasTemperature">
        <div class="col-6 col-sm-3 col-md-2">
            <mat-form-field appearance="outline">
                <mat-label>Temperatura</mat-label>
                <input (blur)="onBlurTemperature()" [mask]="masks.temperature" type="text" matInput
                    formControlName="temperature">
                <span matSuffix>ºC</span>
            </mat-form-field>
        </div>
        <div class="col-6 col-sm-3 col-md-2" *ngIf="hasRespiratoryFrequency">
            <mat-form-field appearance="outline">
                <mat-label>FR</mat-label>
                <input [mask]="masks.threeDigits" type="text" matInput formControlName="respiratoryFrequency">
                <span matSuffix>irpm</span>
            </mat-form-field>
        </div>
        <div class="col-6 col-sm-3 col-md-2" *ngIf="hasHeartRate">
            <mat-form-field appearance="outline">
                <mat-label>FC</mat-label>
                <input [mask]="masks.threeDigits" type="text" matInput formControlName="heartRate">
                <span matSuffix>bpm</span>
            </mat-form-field>
        </div>
        <div class="col-6 col-sm-3 col-md-2" *ngIf="hasGlucose">
            <mat-form-field appearance="outline">
                <mat-label>GC</mat-label>
                <input (blur)="onBlurGlucose()" [mask]="masks.threeDigits" type="text" matInput
                    formControlName="glucose">
                <span matSuffix>mg/dL</span>
            </mat-form-field>
        </div>
        <div class="col-6 col-sm-3 col-md-2" *ngIf="hasBloodPressure">
            <mat-form-field appearance="outline">
                <mat-label>PA</mat-label>
                <input (blur)="onBlurPressure()" [mask]="masks.pressure" type="text" matInput
                    formControlName="bloodPressure">
                <span matSuffix>mmHG</span>
            </mat-form-field>
        </div>
        <div class="col-6 col-sm-3 col-md-2" *ngIf="hasSaturation">
            <mat-form-field appearance="outline">
                <mat-label>SatO2</mat-label>
                <input [mask]="masks.threeDigits" type="text" matInput formControlName="saturation">
                <span matSuffix>%</span>
            </mat-form-field>
        </div>
    </div>
</div>