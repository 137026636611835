<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <div class="page-title">
            <div>
                <a class="back-link" routerLink="/digital-prompt-service">
                    <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Módulo PA Digital
                </a>
                <h1>Configurações de Autotriagem</h1>
            </div>
            <div class="col-12 col-md-2">
                <button mat-flat-button type="submit" color="primary" class=" btn-block" (click)="submit()">
                    <span *ngIf="isLoading == false">Salvar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
            </div>
        </div>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <form class="form" [formGroup]="model">

            <div class="white-body">

                <div class="title-header">
                    <h1>Configuração da página</h1>
                </div>
                <div class="identity-body">
                    <div class="customize-identity">
                        <div class="identity">
                            <div class="slider-checkbox">
                                <mat-slide-toggle formControlName="isLogoUploaded" checked="isLogoUploaded">Usar logo no
                                    topo da
                                    página
                                </mat-slide-toggle>
                            </div>
                            <div class="choice-identity">
                                <a class="link-uploaded-logo" (click)="openPanelLogoModal()"
                                    *ngIf="this.model.get('isLogoUploaded').value">
                                    <mat-icon class="img-uploaded-logo">image</mat-icon>
                                    <span>Inserir/Alterar logo</span>
                                </a>
                            </div>
                        </div>
                        <div class="identity">
                            <div class="slider-checkbox">
                                <mat-slide-toggle formControlName="useColor" checked="useColor">Alterar a cor padrão da
                                    página
                                    de
                                    Autotriagem
                                </mat-slide-toggle>
                            </div>
                            <div class="choice-identity" *ngIf="this.model.get('useColor').value">
                                <ngx-colors class="color-picker-div" ngx-colors-trigger formControlName="colorCode"
                                    [acceptLabel]="'Aceitar'" [cancelLabel]="'Cancelar'"
                                    (change)="updateColorCodePreview()"></ngx-colors>
                                <span class="color-picker-span">Selecionar cor</span>
                            </div>
                        </div>
                        <div class="identity" *ngIf="healthUnitHasPatientCenter">
                            <div class="slider-checkbox">
                                <mat-slide-toggle formControlName="useSelfTriageInPatientCenter"
                                    checked="useSelfTriageInPatientCenter">Habilitar uso na Central do Paciente
                                </mat-slide-toggle>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="button-preview-margin">
                    <button mat-flat-button color="primary" class="button-preview" (click)="openPreview()">
                        <span *ngIf="isLoading == false">
                            VISUALIZAR ALTERAÇÕES NA PÁGINA
                        </span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
            </div>
            <div class="white-body">

                <div class="title-header">
                    <h1>Busca externa</h1>
                </div>

                <div class="row">
                    <div class="col-6 col-sm-6 col-md-4 slider-checkbox">
                        <mat-slide-toggle formControlName="isExternalSearchEnabled" (change)="toggleExternalSearch()"
                            checked="isExternalSearchEnabled">{{this.model.get('isExternalSearchEnabled').value ?
                            'Desabilitar' : 'Habilitar'}} busca externa
                        </mat-slide-toggle>
                    </div>
                </div>
                <ngContainer *ngIf="this.model.get('isExternalSearchEnabled').value">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Nome</mat-label>
                                <input matInput type="text" formControlName="externalSearchTitle">
                                <mat-error *ngIf="model.get('externalSearchTitle').invalid">Informe o Nome</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Dado necessário para consulta</mat-label>
                                <mat-select formControlName="idExternalSearchField">
                                    <mat-option *ngFor="let field of listField" [value]="field.idExternalSearchField">
                                        {{field.externalSearchFieldDescription}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="model.get('idExternalSearchField').invalid">Informe a descrição
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12 ccol-sm-6 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Url</mat-label>
                                <input matInput type="text" formControlName="externalSearchUrl">
                                <mat-error *ngIf="model.get('externalSearchUrl').invalid">Informe a url de
                                    busca</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Chave</mat-label>
                                <input matInput type="text" formControlName="externalSearchKey">
                                <mat-error *ngIf="model.get('externalSearchKey').invalid">Informe a chave</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </ngContainer>

            </div>


            <div class="white-body">
                <div class="title-header">
                    <h1>Título e descrição da página</h1>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-6">
                        <mat-form-field class="my-form-field" appearance="outline">
                            <mat-label>Título</mat-label>
                            <textarea matInput type="text" maxlength="100" formControlName="titlePage"></textarea>
                            <mat-error *ngIf="model.get('titlePage').invalid">Informe o título</mat-error>
                        </mat-form-field>
                        <span class="title-description-count">{{ model.get('titlePage').value ?
                            model.get('titlePage').value.length : 0 }}/100 caracteres</span>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 ">
                        <mat-form-field class="my-form-field" appearance="outline">
                            <mat-label>Descrição</mat-label>
                            <textarea matInput maxlength="400" formControlName="descriptionPage"></textarea>
                            <mat-error *ngIf="model.get('descriptionPage').invalid">Informe a descrição</mat-error>
                        </mat-form-field>
                        <span class="title-description-count">{{ model.get('descriptionPage').value ?
                            model.get('descriptionPage').value.length : 0 }}/400 caracteres</span>
                    </div>
                </div>
            </div>
            <div>

                <div class="white-body">
                    <div class="title-header">
                        <h1>Orientações ao usuário</h1>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6">
                            <mat-form-field class="my-form-field" appearance="outline">
                                <mat-label>Veja se não é uma emergência</mat-label>
                                <textarea matInput type="text" maxlength="80" formControlName="emergency"></textarea>
                                <mat-error *ngIf="model.get('emergency').invalid">Informe o campo "veja se não é uma
                                    emergência"</mat-error>
                            </mat-form-field>
                            <span class="title-description-count">{{ model.get('emergency').value ?
                                model.get('emergency').value.length : 0 }}/80 caracteres</span>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 ">
                            <mat-form-field class="my-form-field" appearance="outline">
                                <mat-label>Sintomas</mat-label>
                                <textarea matInput maxlength="600" formControlName="symptoms"></textarea>
                                <mat-error *ngIf="model.get('symptoms').invalid">Informe os sintomas</mat-error>
                            </mat-form-field>
                            <span class="title-description-count">{{ model.get('symptoms').value ?
                                model.get('symptoms').value.length : 0 }}/600 caracteres</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-pages-config">

                <div class="white-body">
                    <div class="title-header">
                        <h1>Associação de Direcionamentos</h1>
                    </div>

                    <div class="row" formArrayName="listCounselingDirection"
                        *ngFor="let counselingDirection of counselingDirections().controls; let i=index">
                        <div [formGroupName]="i" class="row">
                            <div class="col-4 col-md-4">
                                <mat-form-field appearance="outline">
                                    <mat-label>Aconselhamento</mat-label>
                                    <mat-select formControlName="idSelfTriageCounseling"
                                        (selectionChange)="setAvailableCounselings()">
                                        <mat-option *ngFor="let item of listCounseling"
                                            [value]="item.idSelfTriageCounseling"
                                            [disabled]="listSelectedCounselings.includes(item.idSelfTriageCounseling)">
                                            {{item.selfTriageCounselingName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-7 col-md-5">
                                <mat-form-field appearance="outline">
                                    <mat-label>Direcionamentos</mat-label>
                                    <mat-select formControlName="listIdDirectionButton" multiple>
                                        <mat-option *ngFor="let item of listDirectionButton"
                                            [value]="item.idDirectionButton">
                                            {{item.buttonName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-10 col-md-3 counseling-direction-button">
                                <button color="primary" mat-mini-fab type="button" (click)="addNext()">
                                    <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
                                </button>
                                <button color="primary" mat-mini-fab type="button"
                                    (click)="removeCounselingDirection(i)"
                                    *ngIf="model.get('listCounselingDirection')['controls'].length > 0">
                                    <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="white-body">

                    <div class="title-header">
                        <h1>URL da página</h1>
                    </div>
                    <div class="row">
                        <div class="col-9">
                            <mat-form-field appearance="outline">
                                <mat-label>Nome</mat-label>
                                <input matInput type="text" formControlName="urlName">
                                <mat-error *ngIf="model.get('urlName').invalid">Informe o Nome</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-9">
                            <mat-form-field appearance="outline">
                                <mat-label>Status</mat-label>
                                <mat-select formControlName="urlStatus">
                                    <mat-option [value]="true">Ativo</mat-option>
                                    <mat-option [value]="false">Inativo</mat-option>
                                </mat-select>
                                <mat-error *ngIf="model.get('urlStatus').invalid">Informe o Status</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Url</mat-label>
                                <input matInput type="text" formControlName="url" (keyup)="getCompleteUrl($event)">
                                <mat-error *ngIf="model.get('url').invalid">Informe a url</mat-error>
                            </mat-form-field>
                            <span *ngIf="this.model.get('url').value">
                                <a type="button" class="complete-link" [href]="completeUrl"
                                    target="_blank">{{completeUrl}}</a>
                                <button matTooltip="Copiar" aria-label="Copiar" class="copy-to-clipboard" type="button"
                                    [cdkCopyToClipboard]="completeUrl" [cdkCopyToClipboardAttempts]="5"
                                    (click)="onCopy('item copiado')">
                                    <mat-icon class="copy-icons">content_copy</mat-icon>
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>