import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { PostPatientProcedureMadeService } from 'src/app/shared/services/API/orchestrator-billing/post-patient-procedure-made.service';
import { PatientProcedureMadeRequest } from 'src/app/shared/services/requests/orchestrator-billing/patient-procedure-made.request';
import { PatientConsolidatedDataStruct } from 'src/app/shared/services/structs/billing/patient-consonlidated-data.struct';
import { UtilService } from 'src/app/shared/services/util.service';
import { PatientDateRangeModalComponent } from './patient-date-range-modal/patient-date-range-modal.component';
import { ListPatientBillingDataService } from 'src/app/shared/services/API/orchestrator-billing/list-patient-billing-data.service';
import { ColumnsPatientProcedureMadeEnum } from 'src/app/shared/enum/columns-patient-procedure-made.enum';

@Component({
  selector: 'app-patient-procedure-made-register',
  templateUrl: './patient-procedure-made-register.component.html',
  styleUrls: ['./patient-procedure-made-register.component.css']
})
export class PatientProcedureMadeRegisterComponent implements OnInit {

  constructor(
    private postPatientProcedureMadeService: PostPatientProcedureMadeService,
    private router: Router,
    public dialog: MatDialog,
    public alertService: AlertService,
    private listPatientBillingDataService: ListPatientBillingDataService,
  ) { }
    
  public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.billing;
  public menuFunctionalityEnum:MenuFunctionalityEnum = MenuFunctionalityEnum.billing_patientProcedureMade;
  public isLoading:boolean;
  public listConsolidatedData: PatientConsolidatedDataStruct[] = [];
  public listConsolidatedDataToDisplay: PatientConsolidatedDataStruct[] = [];
  public beginPeriod: string;
  public endPeriod: string;
  public listColumnNames: { name: string, hidden: boolean }[] =[];
  public listColumnToDisplay: number[] = [];
  public listProcedureSigtapName: string[];
  public listProcedureSigtapCode: string[];
  public procedureSigtapNameToFilter: string = "";
  public procedureSigtapCodeToFilter: string = "";
  public searchTextInTable: string = "";
  public columns: typeof ColumnsPatientProcedureMadeEnum = ColumnsPatientProcedureMadeEnum;

  ngOnInit(): void {
    this.isLoading = false;
    this.openModal();
  }
  
  openModal(){
    const dialogRef = this.dialog.open(PatientDateRangeModalComponent);
    
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.listConsolidatedData){
        this.listConsolidatedData = result.listConsolidatedData;
        this.listProcedureSigtapName = [...new Set(this.listConsolidatedData.map(x => x.procedureSigtapName))];
        this.listProcedureSigtapCode = [...new Set(this.listConsolidatedData.map(x => x.codProcedureSigtap))];
        this.formatNames();
        this.beginPeriod = result.beginPeriod;
        this.endPeriod = result.endPeriod;
        this.resetSearchVariables(); 
      }
    });
  }

  search(searchTextProcedureSigTap: string, searchTextCodSigTap: string){
    if(this.isLoading){
      return;
    }
    this.isLoading = true;  
    
    this.listPatientBillingDataService.listPatientBillingData(this.beginPeriod, this.endPeriod, searchTextProcedureSigTap, searchTextCodSigTap).subscribe({
      next:(response)=>{      
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.listConsolidatedData = response.listConsolidatedData; 
        this.filterTable(true);
        this.isLoading = false;
      },
      error: (error)=>{
        console.log(error)
        this.isLoading = false;    
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  formatNames(){
    for( var item of this.listConsolidatedData){
      if(item.procedureSigtapName.length > 12){
        item.reducedProcedureName = item.procedureSigtapName.substring(0, 12)+"..."
      }
      if(item.cboName.length > 12){
        item.reducedCboName = item.cboName.substring(0, 12)+"..."
      }
      if(item.coordination.length > 12){
        item.reducedCoordination = item.coordination.substring(0, 12)+"..."
      }
      if(item.supervision.length > 12){
        item.reducedSupervision = item.supervision.substring(0, 12)+"..."
      }
      if(item.establishment.length > 12){
        item.reducedEstablishment = item.establishment.substring(0, 12)+"..."
      }
      if(item.patientName && item.patientName.length > 12){
        item.reducedPatientName = item.patientName.substring(0, 12)+"..."
      }
      if(item.userName.length > 12){
        item.reducedUserName = item.userName.substring(0, 12)+"..."
      }
      if(item.patientCns && item.patientCns.length > 12){
        item.reducedPatientCns = item.patientCns.substring(0, 12)+"..."
      }
    }

    this.listConsolidatedDataToDisplay = this.listConsolidatedData;
  }

  generateInitialColumnList(){
    this.listColumnNames = [
      { name: 'Coordenadoria', hidden: false },
      { name: 'Supervisão', hidden: false },
      { name: 'CNES Estabelecimento', hidden: false },
      { name: 'Estabelecimento', hidden: false },
      { name: 'Competência BPA', hidden: false },
      { name: 'CNS Profissional', hidden: false },
      { name: 'Nome do profissional', hidden: false },
      { name: 'Nome do Paciente', hidden: false },
      { name: 'Data de Nascimento', hidden: false },
      { name: 'CNS Paciente', hidden: false },
      { name: 'CID', hidden: false },
      { name: 'Código', hidden: false },
      { name: 'Procedimento', hidden: false },
      { name: 'Código CBO', hidden: false },
      { name: 'CBO', hidden: false },
      { name: 'Quantidade', hidden: false }
    ];
    this.listColumnToDisplay = this.listColumnNames.map((_,index)=> index + 1);
  }

  generateReport(){
    this.isLoading = true;
    let request: PatientProcedureMadeRequest = new PatientProcedureMadeRequest();

    this.beginPeriod

    request.datetimeStart = this.formatUtcDate(this.beginPeriod);
    request.datetimeEnd = this.formatUtcDate(this.endPeriod);
    request.listConsolidatedData = this.listConsolidatedDataToDisplay;
    request.listColumnToDisplay = this.listColumnToDisplay;

    this.postReportData(request);
  }

  formatUtcDate(date:string) {
    var dateComponentes = date.split(" ");

    var ymd = dateComponentes[0];
    var hours = dateComponentes[1];

    var auxDate = ymd.split('-');
    var auxHours = hours.split(':');

    var year = parseInt(auxDate[0]);
    var month = parseInt(auxDate[1]) - 1;
    var day = parseInt(auxDate[2]);
    var hour = parseInt(auxHours[0]);
    var minute = parseInt(auxHours[1]);
    var second = parseInt(auxHours[2]);

    var formatedDate = new Date(Date.UTC(year, month, day, hour, minute, second));

    return formatedDate;
  }

  postReportData(request : PatientProcedureMadeRequest){
    this.postPatientProcedureMadeService.postExcelReport(request).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.isLoading = false;

      this.downloadFile(response.fileBase64, response.fileName);
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  convertBase64ToBlobData(base64Data: string, contentType: string='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', sliceSize=512) {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  downloadFile(file64: string, fileName){
    const blobData = this.convertBase64ToBlobData(file64);
    const blob = new Blob([blobData], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
    this.router.navigate(['/billing/patient-procedure-made']);
  }

  onChangeDisplayColumn(index: number){
    let column = index + 1;
    if(column == this.columns.Procedimento || column == this.columns.Codigo){
      if(!this.listColumnToDisplay.includes(this.columns.Procedimento)){
        this.procedureSigtapNameToFilter = "";
      }
      if(!this.listColumnToDisplay.includes(this.columns.Codigo)){
        this.procedureSigtapCodeToFilter = "";
      }
      this.search(this.procedureSigtapNameToFilter, this.procedureSigtapCodeToFilter)
    } else {
      this.filterTable();
    }      
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;

    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13){
        $this.filterTable()
      }      
    }, 1000);
  }

  private timeoutToFilter: any = null;

  filterTable(isCallBySearch: boolean = false) {
    clearTimeout(this.timeoutToFilter);
    this.isLoading = true;    
    var $this = this;
    let timeToExecuteFunction: number;

    if (isCallBySearch)
      timeToExecuteFunction = 0;
    else  
      timeToExecuteFunction = 1000;

    this.timeoutToFilter = setTimeout(function () {
      let searchValue = $this.searchTextInTable.toLowerCase();
      if($this.listColumnToDisplay && $this.listColumnToDisplay.length > 0){
        $this.listConsolidatedDataToDisplay = $this.listConsolidatedData.filter(obj =>
          (obj.coordination?.toLowerCase().match(searchValue) && $this.listColumnToDisplay.includes($this.columns.Coordenadoria))
          || (obj.supervision?.toLowerCase().match(searchValue) && $this.listColumnToDisplay.includes($this.columns.Supervisao))
          || (obj.cnes?.toLowerCase().match(searchValue) && $this.listColumnToDisplay.includes($this.columns.CNESEstabelecimento))
          || (obj.establishment?.toLowerCase().match(searchValue) && $this.listColumnToDisplay.includes($this.columns.Estabelecimento))
          || (obj.cns?.toLowerCase().match(searchValue) && $this.listColumnToDisplay.includes($this.columns.CNSProfissional))
          || (obj.userName?.toLowerCase().match(searchValue) && $this.listColumnToDisplay.includes($this.columns.NomeProfissional))
          || (obj.codProcedureSigtap?.toLowerCase().match(searchValue) && $this.listColumnToDisplay.includes($this.columns.Codigo))
          || (obj.procedureSigtapName?.toLowerCase().match(searchValue) && $this.listColumnToDisplay.includes($this.columns.Procedimento))
          || (obj.codCbo?.toLowerCase().match(searchValue) && $this.listColumnToDisplay.includes($this.columns.CodigoCBO))
          || (obj.cboName?.toLowerCase().match(searchValue) && $this.listColumnToDisplay.includes($this.columns.CBO))
          || (obj.quantity?.toString().match(searchValue) && $this.listColumnToDisplay.includes($this.columns.Quantidade))
          || (obj.cid?.toLowerCase().match(searchValue) && $this.listColumnToDisplay.includes($this.columns.CID))
          || (obj.patientName?.toLowerCase().match(searchValue) && $this.listColumnToDisplay.includes($this.columns.NomePaciente))
          || (obj.patientCns?.toLowerCase().match(searchValue) && $this.listColumnToDisplay.includes($this.columns.CNSPaciente))
        );
      }
      
      for( var item of $this.listConsolidatedDataToDisplay){
        if(item.procedureSigtapName.length > 12){
          item.reducedProcedureName = item.procedureSigtapName.substring(0, 12)+"..."
        }
        if(item.cboName.length > 12){
          item.reducedCboName = item.cboName.substring(0, 12)+"..."
        }
        if(item.coordination.length > 12){
          item.reducedCoordination = item.coordination.substring(0, 12)+"..."
        }
        if(item.supervision.length > 12){
          item.reducedSupervision = item.supervision.substring(0, 12)+"..."
        }
        if(item.establishment.length > 12){
          item.reducedEstablishment = item.establishment.substring(0, 12)+"..."
        }
        if(item.patientName && item.patientName.length > 12){
          item.reducedPatientName = item.patientName.substring(0, 12)+"..."
        }
        if(item.userName.length > 12){
          item.reducedUserName = item.userName.substring(0, 12)+"..."
        }
        if(item.patientCns && item.patientCns.length > 12){
          item.reducedPatientCns = item.patientCns.substring(0, 12)+"..."
        }
      }
      $this.isLoading = false;
            
    }, timeToExecuteFunction);    
  }

  resetSearchVariables(){
    this.procedureSigtapNameToFilter = "";
    this.listColumnToDisplay = [];
    this.searchTextInTable = ""
    this.procedureSigtapCodeToFilter = "";

    this.generateInitialColumnList();
  }

  onKeySelectColumns(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        let trimmedSearchText = event.trim().toLowerCase();
        $this.listColumnNames.forEach((item) => {
          if (item.name.toLowerCase().includes(trimmedSearchText)) {
            item.hidden = false;
          } else {
            item.hidden = true;
          }
        });
      }
    }, 1000);
  }
}