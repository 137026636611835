import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { GetAllTriageResponse } from '../../responses/orchestrator-classification/get-all-triage.response';
import { ListTriageResponse } from '../../responses/orchestrator-classification/list-triage.response';
import { TriageResponse } from '../../responses/orchestrator-classification/triage.response';
import { TriageStruct } from '../../structs/orchestrator-patient/triage.struct';

@Injectable({
  providedIn: 'root'
})
export class TriageService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getTriageList(pageIndex: number, initialDate: string, finalDate: string, idGravity: number): Observable<GetAllTriageResponse> {
    let uri = `TelephoneClassification?`

    if (pageIndex) {
      uri = uri + "pageIndex=" + pageIndex + "&";
    }

    if (initialDate) {
      uri = uri + "initialDate=" + initialDate + "&";
    }

    if (finalDate) {
      uri = uri + "finalDate=" + finalDate + "&";
    }

    if (idGravity) {
      uri = uri + "idGravity=" + idGravity + "&";
    }

    return this.httpClient.get<GetAllTriageResponse>(environment.urlApiOrchestratorClassification + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getTriage(idTriage: number): Observable<TriageResponse> {
    let uri = `TelephoneClassification/idTriage/${idTriage}`

    return this.httpClient.get<TriageResponse>(environment.urlApiOrchestratorClassification + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public postTriage(triage: TriageStruct): Observable<ReturnStruct> {
    let uri = `TelephoneClassification`

    return this.httpClient.post<ReturnStruct>(environment.urlApiOrchestratorClassification + uri, triage, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getVirtualTriages(idPatient: number, cpf: string): Observable<ListTriageResponse> {
    let uri = `Triage/idPatient/${idPatient}/cpf/${cpf}`

    return this.httpClient.get<ListTriageResponse>(environment.urlApiOrchestratorClassification + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
