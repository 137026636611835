import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { AccessKeyListComponent } from './pages/access-key-list/access-key-list.component';
import { AccessKeyRegisterComponent } from './pages/access-key-register/access-key-register.component';
import { IntegrationIframeComponent } from './pages/integration-iframe/integration-iframe.component';
import { IntegrationLogDetailsComponent } from './pages/integration-log-details/integration-log-details.component';
import { IntegrationLogComponent } from './pages/integration-log/integration-log.component';
import { IntegrationComponent } from './pages/integration/integration.component';
import { WebhookListComponent } from './pages/webhook-list/webhook-list.component';
import { WebhookRegisterComponent } from './pages/webhook-register/webhook-register.component';

const routes: Routes = [
  { path: 'integration', component: IntegrationComponent, canActivate: [AuthGuard] },
  { path: 'integration/access-key-list', component: AccessKeyListComponent, canActivate: [AuthGuard] },
  { path: 'integration/access-key/register', component: AccessKeyRegisterComponent, canActivate: [AuthGuard] },
  { path: 'integration/integration-log', component: IntegrationLogComponent, canActivate: [AuthGuard] },
  { path: 'integration/integration-log/details', component: IntegrationLogDetailsComponent, canActivate: [AuthGuard] },
  { path: 'integration/webhook-list', component: WebhookListComponent, canActivate: [AuthGuard] },
  { path: 'integration/webhook/register', component: WebhookRegisterComponent, canActivate: [AuthGuard] },
  { path: 'integration/iframe', component: IntegrationIframeComponent, canActivate: [AuthGuard] },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IntegrationRoutingModule { }
