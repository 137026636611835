import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CommonService } from "src/app/shared/services/common-service";
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';
import { catchError } from "rxjs/operators";
import { ReportResponse } from "../../responses/orchestrator-patient/report.response";
import { MedicalRecordResponse } from "../../responses/orchestrator-patient/medical-record.response";
import { RequestMaterialResponse } from "../../models/pharmacy/request-material.model";

@Injectable({
    providedIn: 'root'
  })
  export class OrchestratorRequestMaterialService extends CommonService{
  
    constructor(private router: Router, private httpClient: HttpClient) {
      super();
    }
  
public getAllRequestsByEpisode(idEpisode: number): Observable<RequestMaterialResponse> {
  let uri = `Pharmacy/RequestMaterial/${idEpisode}`

  return this.httpClient.get<RequestMaterialResponse>(environment.urlApiOrchestratorPatient + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
}
}