import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { LogAuthService } from 'src/app/shared/services/API/user/log-auth.service';
import { LogLoginStruct } from 'src/app/shared/services/structs/user/log-login.struct';

@Component({
  selector: 'app-login-history-modal',
  templateUrl: './login-history-modal.component.html',
  styleUrls: ['./login-history-modal.component.css']
})
export class LoginHistoryModalComponent implements OnInit {

  constructor (
    @Inject(MAT_DIALOG_DATA) public data: any, 
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<LoginHistoryModalComponent>,
    private logAuthService: LogAuthService,
    private alertService: AlertService,
  ) { }
  
  public listLogLoginHistory: LogLoginStruct[] = [];

  public isLoading: boolean;
  public firstLoading: boolean;

  public fullListSize: number = 0;
  public pageSize: number = 0;

  ngOnInit(): void {
    this.firstLoading = true;
    this.getListLogin(0);
  }
  
  close(){
    this.matDialogRef.close(); 
  }

  changePage(event: PageEvent) {
    this.getListLogin(event.pageIndex);
  }
  
  getListLogin(pageIndex: number){
    this.isLoading = true;
    this.logAuthService.getLogUser(this.data.idUser, pageIndex).subscribe({
      next: (response)=>{
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.firstLoading = false;
          this.isLoading = false;
          return;
        }

        this.listLogLoginHistory = response.list;
        this.fullListSize = response.fullListSize;
        this.pageSize = response.pageSize;
        this.firstLoading = false;
        this.isLoading = false;
      },
      error: (error)=>{
        console.log(error)
        this.firstLoading = false;
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}
