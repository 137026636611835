import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ComplaintToFlowchartRequest } from '../../requests/orchestrator-classification/complaint-to-flowchart.request';
import { FlowPredResponse } from '../../responses/orchestrator-classification/flow-pred.response';

@Injectable({
  providedIn: 'root'
})
export class AiComplaintToFlowchartService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getAIFlowcharts(request: ComplaintToFlowchartRequest): Observable<FlowPredResponse> {
    let uri = `ClassificationAI/get`

    return this.httpClient.post<FlowPredResponse>(environment.urlApiOrchestratorClassification + uri, request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
