import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { RoomRequest } from '../../requests/flow/room.request';
import { ListRoomResponse } from '../../responses/flow/list-room.response';
import { GetRoomResponse } from '../../responses/flow/get-room.response';
import { PostRoomResponse } from '../../responses/flow/post-room.response';
import { retry, timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoomService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  public listRoom(searchText: string, isActive: boolean, idSector: number): Observable<ListRoomResponse> {
    let uri = 'Room?'

    if (searchText != null)
      uri = uri + `searchText=${searchText}&`;

    if (isActive != null)
      uri = uri + `isActive=${isActive}&`

    if (idSector != null)
      uri = uri + `idSector=${idSector}&`

    return this.httpClient.get<ListRoomResponse>(environment.urlApiFlow + uri, this.addHeaderToken())
      .pipe(timeout(environment.requestTimeOut), retry(environment.requestRetry), catchError(this.handleError));
  }

  public getRoom(idRoom: number): Observable<GetRoomResponse> {
    let uri = `Room/idRoom/${idRoom}`;

    return this.httpClient.get<GetRoomResponse>(environment.urlApiFlow + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public ValidateRoom(idRoom: number, idSector: number): Observable<ReturnStruct> {
    let uri = `RoomUn/idRoom/${idRoom}/idSector/${idSector}`;

    return this.httpClient.get<ReturnStruct>(environment.urlApiFlow + uri, this.addHeaderInternalToken())
      .pipe(catchError(this.handleError));
  }

  public createRoom(body: RoomRequest): Observable<PostRoomResponse> {
    let uri = `Room`

    return this.httpClient.post<PostRoomResponse>(environment.urlApiFlow + uri, body, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public updateRoom(idRoom: number, body: RoomRequest): Observable<ReturnStruct> {
    let uri = `Room/idRoom/${idRoom}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiFlow + uri, body, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public deleteRoom(idRoom: number): Observable<ReturnStruct> {
    let uri = `Room/idRoom/${idRoom}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiFlow + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public listAllRoom(): Observable<ListRoomResponse> {
    let uri = `Room/getAll`

    return this.httpClient.get<ListRoomResponse>(environment.urlApiFlow + uri, this.addHeaderToken())
      .pipe(timeout(environment.requestTimeOut), retry(environment.requestRetry), catchError(this.handleError));
  }
}
