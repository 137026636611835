import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { SearchPatientTotemRequest } from '../../requests/orchestrator-totem/search-patient-totem.request';
import { GetPatientByCpfResponse } from '../../responses/medical-record/get-patient-by-cpf.response';

@Injectable({
  providedIn: 'root'
})
export class ClientGetPatientByCpfTotemService extends CommonService {

  constructor(private httpClient: HttpClient) { super(); }

  public getClientPatientByCPF(idHealthUnit: number, request: SearchPatientTotemRequest): Observable<GetPatientByCpfResponse> {
    let uri = `api/SearchPatient/idHealthUnit/${idHealthUnit}`
    return this.httpClient.post<GetPatientByCpfResponse>(environment.urlApiOrchestratorTotem + uri, request, this.addHeaderHashTotem())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getClientPatientScheduleByCPF(idHealthUnit: number, timeZoneValue: number, request: SearchPatientTotemRequest): Observable<GetPatientByCpfResponse> {
    let uri = `api/SearchPatientSchedule/idHealthUnit/${idHealthUnit}/timeZoneValue/${timeZoneValue}`
    return this.httpClient.post<GetPatientByCpfResponse>(environment.urlApiOrchestratorTotem + uri, request, this.addHeaderHashTotem())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getClientPatientByCPFNps(idHealthUnit: number, request: SearchPatientTotemRequest): Observable<GetPatientByCpfResponse> {
    let uri = `api/SearchPatient/idHealthUnit/${idHealthUnit}`
    return this.httpClient.post<GetPatientByCpfResponse>(environment.urlApiOrchestratorTotem + uri, request, this.addHeaderHashTotemNps())
      .pipe(
        catchError(this.handleError)
      )
  }
}