import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { GetEvasionTimeResponse } from '../../responses/user/get-evasion-time.response';
import { ListEvasionTimeResponse } from '../../responses/user/list-evasion-time.response';

@Injectable({
  providedIn: 'root'
})
export class EvasionTimeService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listEvasionTime(): Observable<ListEvasionTimeResponse> {

    let uri = 'EvasionTime/getAll'

    return this.httpClient.get<ListEvasionTimeResponse>(environment.urlApiUser + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getEvasionTime(idEvasionTime: number): Observable<GetEvasionTimeResponse> {

    let uri = `EvasionTime/idEvasionTime/${idEvasionTime}`;

    return this.httpClient.get<GetEvasionTimeResponse>(environment.urlApiUser + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
