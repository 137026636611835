import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { LocationByModuleService } from 'src/app/shared/services/API/flow/location-by-module.service';
import { MultiProfessionalConfigService } from 'src/app/shared/services/API/multi-professional/multi-professional-config.service';
import { SectorStruct } from 'src/app/shared/services/structs/flow/sector.struct';
import { ListCompleteMultiProfessionalConfigStruct } from 'src/app/shared/services/structs/multi-professional/list-complete-multi-professional-config.struct';
import { MultiProfessionalConfigDeleteModalComponent } from '../multi-professional-config-register/multi-professional-config-delete-modal/multi-professional-config-delete-modal.component';

@Component({
  selector: 'app-multi-professional-config-list',
  templateUrl: './multi-professional-config-list.component.html',
  styleUrls: ['./multi-professional-config-list.component.css']
})
export class MultiProfessionalConfigListComponent implements OnInit {

  constructor(
    private locationByModuleService: LocationByModuleService,
    private alertService: AlertService,
    public dialog: MatDialog,
    private multiProfessionalConfigService: MultiProfessionalConfigService,
  ) { }
  
  public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.multi_professional;
  public menuFunctionalityEnum:MenuFunctionalityEnum = MenuFunctionalityEnum.multi_professional_config;

  public listMultiConfig: ListCompleteMultiProfessionalConfigStruct[];
  public searchText: string;
  public isLoading: boolean;
  public listSector: SectorStruct[];
  public listIdSector: number[];

  ngOnInit(): void {
    this.isLoading = true;
    this.listIdSector = [];
    this.populateSectorSelect();
    this.search();
  }

  search() {
    this.isLoading = true;
    if (this.searchText == "")
      this.searchText = null;

    this.multiProfessionalConfigService.listMultiConfig(this.searchText, this.listIdSector).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.listMultiConfig = response.listMultiProfessionalConfig;

      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  private timeoutKeySearch: any = null;
  
  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }
  
  populateSectorSelect() {
    this.locationByModuleService.listRoomByModule(MenuModuleEnum.multi_professional).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }

      this.listSector = response.sectors;
    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  getSectorName(idSector: number) : string {
    if(!idSector || !this.listSector)
      return null;

    return this.listSector.find(l => l.idSector == idSector)?.sectorName;
  }

  listSectorName(listSectorId: number[]) : string {
    if(!listSectorId || !this.listSector)
      return null;

    let listName = [];
    
    listSectorId.forEach(element => {
      listName.push(this.listSector.find(l => l.idSector == element)?.sectorName)
    });

    return listName.join(", ");
  }

  openModal(idMultiProfessionalConfig){
    const dialogRef = this.dialog.open(MultiProfessionalConfigDeleteModalComponent, {
      data: {
        idMultiProfessionalConfig: idMultiProfessionalConfig
      },
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.deleteMultiProfessionalConfig)
        this.search();
    });
  }
}