<div [formGroup]="formGroup">
    <div class="row">
        <div class="col-8 col-sm-8 col-md-4">
            <div class="vertical-aling-out">
                <div class="vertical-aling-inner">
                    <mat-radio-group aria-label="Selecione uma Origem">
                        <mat-radio-button value="1" [checked]="this.showSelected == 1"
                            (change)="changeRadioOrigin($event)">Serviço</mat-radio-button>
                        <mat-radio-button value="2" [checked]="this.showSelected == 2"
                            (change)="changeRadioOrigin($event)">Setor</mat-radio-button>
                        <mat-radio-button value="3" [checked]="this.showSelected == 3"
                            (change)="changeRadioOrigin($event)">Sala</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </div>
        <div class="col-3 col-sm-4 col-md-2">
            <mat-slide-toggle *ngIf="hasSelectedClassificationModule" formControlName="showAverageWaitingTime">
                Mostrar tempo médio de espera</mat-slide-toggle>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-sm-12 col-md-6 personalized-div-select" *ngIf="this.showSelected == 1">
            <mat-form-field appearance="outline">
                <mat-label>Serviço</mat-label>
                <mat-select formControlName="idService" (selectionChange)="selectService($event)">
                    <mat-option value="">Selecione</mat-option>
                    <mat-option *ngFor="let item of listService"
                        value="{{item.idService}}">{{item.serviceName}}</mat-option>
                </mat-select>
                <mat-error *ngIf="formGroup.get('idService').invalid">Informe o Serviço</mat-error>
            </mat-form-field>
        </div>
        <div class="col-12 col-sm-12 col-md-6 personalized-div-select" *ngIf="this.showSelected == 2">
            <mat-form-field appearance="outline">
                <mat-label>Setor</mat-label>
                <mat-select formControlName="idSector" (selectionChange)="selectSector($event)">
                    <mat-option value="">Selecione</mat-option>
                    <mat-option *ngFor="let item of listSector"
                        value="{{item.idSector}}">{{item.sectorName}}</mat-option>
                </mat-select>
                <mat-error *ngIf="formGroup.get('idService').invalid">Informe o Setor</mat-error>
            </mat-form-field>
        </div>
        <div class="col-12 col-sm-12 col-md-6 personalized-div-select" *ngIf="this.showSelected == 3">
            <mat-form-field appearance="outline">
                <mat-label>Sala</mat-label>
                <mat-select formControlName="idRoom" (selectionChange)="selectRoom($event)">
                    <mat-option value="">Selecione</mat-option>
                    <mat-option *ngFor="let item of listRoom" value="{{item.idRoom}}">{{item.roomName}}</mat-option>
                </mat-select>
                <mat-error *ngIf="formGroup.get('idService').invalid">Informe o Serviço</mat-error>
            </mat-form-field>
        </div>
        <div class="col-4 col-sm-4 col-md-2">
            <button class="panel-origin-button " color="primary" mat-mini-fab type="button" (click)="add()">
                <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
            </button>
            <button class="panel-origin-button " color="primary" mat-mini-fab type="button" (click)="remove()"
                *ngIf="this.index != 0">
                <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
            </button>
        </div>
    </div>
</div>