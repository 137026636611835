<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <a class="back-link" routerLink="/master/flow">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Fluxos
        </a>
        <h1>Cadastrar Fluxo</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome</mat-label>
                            <input matInput type="text" formControlName="name">
                            <mat-error *ngIf="model.get('name').invalid">Informe o Nome</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Descrição</mat-label>
                            <input matInput type="text" formControlName="description">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Status</mat-label>
                            <mat-select formControlName="status">
                                <mat-option [value]="true">Ativo</mat-option>
                                <mat-option [value]="false">Inativo</mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('status').invalid">Informe o Status</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Prioridade</mat-label>
                            <input matInput type="number" formControlName="priority">
                            <mat-error *ngIf="model.get('priority').invalid">Informe a Prioridade</mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <app-tag [listTags]="this.listTags" [allTags]="this.listAllTags"
                            *ngIf="this.listAllTags && this.listTags"></app-tag>
                    </div>
                </div>

                <div class="title-header">
                    <h1>Origem</h1>
                </div>
                <mat-radio-group *ngIf="!this.isUpdate" aria-label="Selecione uma Origem">
                    <mat-radio-button [value]="1" [checked]="true" (change)="changeRadioOrigin($event)">Serviço
                    </mat-radio-button>
                    <mat-radio-button [value]="2" (change)="changeRadioOrigin($event)">Setor</mat-radio-button>
                    <mat-radio-button [value]="3" (change)="changeRadioOrigin($event)">Sala</mat-radio-button>
                    <mat-radio-button [value]="4" (change)="changeRadioOrigin($event)">Totem</mat-radio-button>
                </mat-radio-group>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngIf="this.isOriginService">
                        <mat-form-field appearance="outline">
                            <mat-label>Serviço</mat-label>
                            <mat-select formControlName="idOriginService"
                                (selectionChange)="changeSelectOrigin($event)">
                                <mat-option *ngFor="let item of listService"
                                    [value]="item.idService">{{item.serviceName}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idOriginService').invalid">Informe o Serviço de
                                Origem</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4" *ngIf="this.isOriginRoom">
                        <mat-form-field appearance="outline">
                            <mat-label>Sala</mat-label>
                            <mat-select formControlName="idOriginRoom" (selectionChange)="changeSelectOrigin($event)">
                                <mat-option *ngFor="let item of listRoom" [value]="item.idRoom">{{item.roomName}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idOriginRoom').invalid">Informe a Sala de Origem</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngIf="this.isOriginSector">
                        <mat-form-field appearance="outline">
                            <mat-label>Setor</mat-label>
                            <mat-select formControlName="idOriginSector" (selectionChange)="changeSelectOrigin($event)">
                                <mat-option *ngFor="let item of listSector" [value]="item.idSector">{{item.sectorName}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idOriginSector').invalid">Informe o Setor de Origem</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4" *ngIf="this.isOriginTotemButton">
                        <mat-form-field appearance="outline">
                            <mat-label>Botão Totem</mat-label>
                            <mat-select formControlName="idOriginTotemButton">
                                <mat-option *ngFor="let item of listTotemButton" [value]="item.idTotemButton">
                                    {{item.totemName}} - {{item.totemButtonName}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idOriginTotemButton').invalid">Informe o Botão de Totem de
                                Origem</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="title-header" *ngIf="this.showIntermediaryLocation">
                    <h1>Destino Intermediário</h1>
                    <mat-slide-toggle [checked]="this.checkIntermediaryLocation"
                        (change)="changeCheckIntermediary($event)"
                        [disabled]="this.isUpdate || this.isOriginTelemedicine || this.isOriginDigitalPromptService"></mat-slide-toggle>
                </div>
                <div *ngIf="this.showIntermediaryLocation && !this.isOriginTelemedicine && !this.isOriginDigitalPromptService"
                    class="row">
                    <div *ngIf="this.checkIntermediaryLocation" class="row">
                        <mat-radio-group *ngIf="!this.isUpdate" aria-label="Selecione um Destino">
                            <mat-radio-button [value]="1" [checked]="this.isIntermediaryService"
                                (change)="changeRadioIntermediary($event)">Serviço
                            </mat-radio-button>
                            <mat-radio-button [value]="2" [checked]="this.isIntermediarySector"
                                (change)="changeRadioIntermediary($event)">Setor</mat-radio-button>
                            <mat-radio-button [value]="3" [checked]="this.isIntermediaryRoom"
                                (change)="changeRadioIntermediary($event)">Sala</mat-radio-button>
                        </mat-radio-group>
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-4" *ngIf="this.isIntermediaryService">
                                <mat-form-field appearance="outline">
                                    <mat-label>Serviço</mat-label>
                                    <mat-select formControlName="idIntermediaryService">
                                        <mat-option *ngFor="let item of listServiceReception" [value]="item.idService">
                                            {{item.serviceName}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="model.get('idIntermediaryService').invalid">Informe o Serviço
                                        Intermediário</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4" *ngIf="this.isIntermediaryRoom">
                                <mat-form-field appearance="outline">
                                    <mat-label>Sala</mat-label>
                                    <mat-select formControlName="idIntermediaryRoom">
                                        <mat-option *ngFor="let item of listRoomReception" [value]="item.idRoom">
                                            {{item.roomName}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="model.get('idIntermediaryRoom').invalid">Informe a Sala
                                        Intermediário</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4" *ngIf="this.isIntermediarySector">
                                <mat-form-field appearance="outline">
                                    <mat-label>Setor</mat-label>
                                    <mat-select formControlName="idIntermediarySector">
                                        <mat-option *ngFor="let item of listSectorReception" [value]="item.idSector">
                                            {{item.sectorName}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="model.get('idIntermediarySector').invalid">Informe o Setor
                                        Intermediário</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="title-header">
                    <h1>Destino</h1>
                </div>
                <mat-radio-group *ngIf="!this.isUpdate" aria-label="Selecione um Destino">
                    <mat-radio-button [value]="1" [checked]="true"
                        (change)="changeRadioDestiny($event)">Serviço</mat-radio-button>
                    <mat-radio-button [value]="2" (change)="changeRadioDestiny($event)">Setor</mat-radio-button>
                    <mat-radio-button [value]="3" (change)="changeRadioDestiny($event)">Sala</mat-radio-button>
                </mat-radio-group>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngIf="this.isDestinyService">
                        <mat-form-field appearance="outline">
                            <mat-label>Serviço</mat-label>
                            <mat-select formControlName="idDestinyService">
                                <ngContainer>
                                    <mat-option *ngFor="let item of listDestinationService"
                                        [value]="item.idService">{{item.serviceName}}
                                    </mat-option>
                                </ngContainer>
                            </mat-select>
                            <mat-error *ngIf="model.get('idDestinyService').invalid">Informe o Serviço de
                                Destino</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4" *ngIf="this.isDestinyRoom">
                        <mat-form-field appearance="outline">
                            <mat-label>Sala</mat-label>
                            <mat-select formControlName="idDestinyRoom">
                                <ngContainer>
                                    <mat-option *ngFor="let item of listDestinationRoom"
                                        [value]="item.idRoom">{{item.roomName}}
                                    </mat-option>
                                </ngContainer>
                            </mat-select>
                            <mat-error *ngIf="model.get('idDestinyRoom').invalid">Informe a Sala de Destino</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4" *ngIf="this.isDestinySector">
                        <mat-form-field appearance="outline">
                            <mat-label>Setor</mat-label>
                            <mat-select formControlName="idDestinySector">
                                <ngContainer>
                                    <mat-option *ngFor="let item of listDestinationSector"
                                        [value]="item.idSector">{{item.sectorName}}
                                    </mat-option>
                                </ngContainer>
                            </mat-select>
                            <mat-error *ngIf="model.get('idDestinySector').invalid">Informe o Setor de
                                Destino</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="this.isEligible">
                    <div class="title-header">
                        <h1>Regras de Elegibilidade</h1>
                        <mat-error *ngIf="this.isExpressionsInvalid">Preencha todos os campos ou apague as expressões!
                        </mat-error>
                    </div>
                    <app-eligibility-rules [formGroup]="this.model" [formArrayName]="'expressions'"
                        [listLogicalExpression]="listLogicalExpression" [listVariable]="listVariable"
                        [listConjunction]="listConjunction" [arrayVariables]="arrayVariables">
                    </app-eligibility-rules>
                    <div class="title-header">
                        <h1>Regras de Elegibilidade - Automáticas</h1>
                        <mat-error *ngIf="this.isExpressionsAutoInvalid">Preencha todos os campos ou apague as
                            expressões!
                        </mat-error>
                    </div>
                    <app-eligibility-rules [formGroup]="this.model" [formArrayName]="'expressionsAuto'"
                        [listLogicalExpression]="listLogicalExpression" [listVariable]="listVariable"
                        [listConjunction]="listConjunction" [arrayVariables]="arrayAutoVariables">
                    </app-eligibility-rules>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block"
                            [disabled]="isTelemedicineNotValid">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <a mat-flat-button type="button" routerLink="/master/flow" color="accent" class=" btn-block">
                            Cancelar
                        </a>
                    </div>
                </div>
                <div class="row" *ngIf="this.showExpressionMessage">
                    <div class="col-md-12">{{this.expressionMessage}}</div>
                </div>
            </form>
        </div>
    </div>

</div>