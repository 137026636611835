import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator'; import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { EpisodeStatusEnum } from 'src/app/shared/enum/episode-status.enum';
import { KpiStatusEnum } from 'src/app/shared/enum/flow/kpi-status.enum';
import { StatusQueueEnum } from 'src/app/shared/enum/status-queue.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { SectorService } from 'src/app/shared/services/API/flow/sector.service';
import { ServiceService } from 'src/app/shared/services/API/flow/service.service';
import { PatientListService } from 'src/app/shared/services/API/orchestrator-queue/patient-list.service';
import { ListPatientResponse } from 'src/app/shared/services/responses/orchestrator-queue/list-patient.response';
import { SectorStruct } from 'src/app/shared/services/structs/flow/sector.struct';
import { ServiceStruct } from 'src/app/shared/services/structs/flow/service.struct';
import { ListPatientStruct } from 'src/app/shared/services/structs/orchestrator-queue/list-patient.struct';
import { UtilService } from 'src/app/shared/services/util.service';


@Component({
  selector: 'app-flow-management-list',
  templateUrl: './flow-management-list.component.html',
  styleUrls: ['./flow-management-list.component.css']
})
export class FlowManagementListComponent implements OnInit {

  constructor(
    private serviceService: ServiceService,
    private sectorService: SectorService,
    private patientListService: PatientListService,
    private alertService: AlertService,
    private utilService: UtilService
  ) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  public isLoading: boolean = true;
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.flow_management;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.flow_management_flow_management;
  public searchText: string;
  public listPatientResponse: ListPatientResponse;
  public listPatientDisplay: ListPatientStruct[] = [];
  public listPatientOk: ListPatientStruct[] = [];
  public listPatientAlert: ListPatientStruct[] = [];
  public listPatientBeyondGoal: ListPatientStruct[] = [];
  public listQueueStatusFilter: number[] = [];
  public listKpiStatusFilter: number[] = [];
  public pageIndex: number = 0;
  public listColumnToDisplay: number[] = [];
  public listServiceStruct: ServiceStruct[] = [];
  public listServiceSelected: number[] = [];
  public listSectorStruct: SectorStruct[] = [];
  public listSectorSelected: number[] = [];
  public listColumnNames: { name: string, hidden: boolean }[] =[];
  public isAllOptions: boolean = true;

  public hasPrescription: boolean = false;

  ngOnInit(): void {
    this.search();
    this.generateInitialColumnList();
    this.getListService();
  }

  getListService() {
    this.isLoading = true;  
    if(this.searchText == "")
    this.searchText = null;
    
    this.serviceService.listService(this.searchText, true).subscribe({
      next: (response)=>{
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listServiceStruct = response.listService;  
        this.isLoading = false;    
      },
      error: (error)=>{
        console.log(error)
        this.isLoading = false;    
        this.alertService.show('Erro inesperado', error, AlertType.error);
      } 
    });
  }

  getListSector() {
    this.isLoading = true;

    if (this.searchText == "")
      this.searchText = null;

    this.sectorService.listSectorByIdService(this.listServiceSelected).subscribe({
      next: (response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listSectorStruct = response.listSectorStruct;

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  onChangeSelectService() {
    this.getListSector();
    this.search();
  }

  onChangeSelectSector() {
    this.search();
  }

  search() {
    this.isLoading = true;
    this.patientListService.listAllPatient(this.pageIndex, this.searchText, this.listQueueStatusFilter, this.listKpiStatusFilter, this.listServiceSelected, this.listSectorSelected).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.listPatientResponse = response;
        this.listPatientDisplay = [...this.listPatientResponse.listPatient];

        this.listPatientOk = [...this.listPatientResponse.listPatient.filter(x => x.kpiStatus == KpiStatusEnum.ok)];
        this.listPatientAlert = [...this.listPatientResponse.listPatient.filter(x => x.kpiStatus == KpiStatusEnum.alert)];
        this.listPatientBeyondGoal = [...this.listPatientResponse.listPatient.filter(x => x.kpiStatus == KpiStatusEnum.beyondGoal)];

        this.listPatientOk.forEach(x => {
          this.formatTimeAndLocation(x);
        });

        this.listPatientAlert.forEach(x => {
          this.formatTimeAndLocation(x);
        });

        this.listPatientBeyondGoal.forEach(x => {
          this.formatTimeAndLocation(x);
        });
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  formatTimeAndLocation(x: ListPatientStruct) {
    x.isLoading = false;
    x.totalEpisodeTime = x.totalEpisodeTime.replace(/:/g, ".");
    let totalTimeArray = x.totalEpisodeTime.split(".");
    x.totalEpisodeTimeDays = totalTimeArray[0];
    x.totalEpisodeTimeHours = totalTimeArray[1];
    x.totalEpisodeTimeMinutes = totalTimeArray[2];
    x.totalEpisodeTimeSeconds = totalTimeArray[3];

    x.waitingTime = x.waitingTime.replace(/:/g, ".");
    let waitingTimeArray = x.waitingTime.split(".");
    x.waitingTimeDays = waitingTimeArray[0];
    x.waitingTimeHours = waitingTimeArray[1];
    x.waitingTimeMinutes = waitingTimeArray[2];
    x.waitingTimeSeconds = waitingTimeArray[3];

    if (x.roomName) {
      x.locationDisplay = `Sala:  ${x.roomName}`;
    }
    else if (x.sectorName) {
      x.locationDisplay = `Setor:  ${x.sectorName}`;
    }
    else {
      x.locationDisplay = `Serviço:  ${x.serviceName}`;
    }
  }

  isAttendedOrEvaded(idStatusQueue) {
    if (idStatusQueue == StatusQueueEnum.atendido || idStatusQueue == StatusQueueEnum.evadido) return false;
    else return true;
  }

  isAttended(idStatusQueue) {
    return idStatusQueue == StatusQueueEnum.atendido;
  }

  isInitiated(idEpisodeStatus) {
    return (idEpisodeStatus == EpisodeStatusEnum.Iniciado);
  }

  isEvaded(idStatusQueue) {
    return idStatusQueue == StatusQueueEnum.evadido;
  }

  private timeoutKeySearch: any = null;

  queueStatusSelect(event: any) {
    this.listQueueStatusFilter = event.selectedItems;
    this.search();
  }

  kpiStatusSelect(event: any) {
    this.listKpiStatusFilter = event;
  }

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.pageIndex = 0;
        $this.paginator.firstPage();
        $this.search();
      }
    }, 1000);
  }

  changePage(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.search();
  }

  generateInitialColumnList(){
    this.listColumnNames = [
      { name: 'Episódio', hidden: false },
      { name: 'Senha', hidden: false },
      { name: 'Entrada', hidden: false },
      { name: 'Prioridade', hidden: false },
      { name: 'Linha de Cuidado', hidden: false },
      { name: 'Gravidade', hidden: false },
      { name: 'Nome do Paciente', hidden: false },
      { name: 'Data de nascimento', hidden: false },
      { name: 'Nome da mãe', hidden: false },
      { name: 'Localização atual', hidden: false },
      { name: 'Tempo de espera', hidden: false },
      { name: 'Situação', hidden: false },
      { name: 'Profissional Responsável', hidden: false },
      { name: 'Procedimentos', hidden: false }
    ];
    let collumns = this.utilService.getColumnFlowManagement();
    if(collumns && collumns.length > 0) {
      this.listColumnToDisplay = collumns;
      this.onChangeSelectColumn();
    } else {
      this.listColumnToDisplay = this.listColumnNames.map((_,index)=> index + 1);
    }
  }

  
  private timeoutKeyFilter: any = null;

  onKeySelectColumns(event: any) {
    clearTimeout(this.timeoutKeyFilter);
    var $this = this;
    this.timeoutKeyFilter = setTimeout(function () {
      if (event.keyCode != 13) {
        let trimmedSearchText = event.trim().toLowerCase();
        $this.listColumnNames.forEach((item) => {
          if (item.name.toLowerCase().includes(trimmedSearchText)) {
            item.hidden = false;
          } else {
            item.hidden = true;
          }
        });
      }
    }, 1000);
  }

  selectAllOptions() {
    this.isAllOptions = !this.isAllOptions;
    if(this.isAllOptions){
      this.listColumnToDisplay = this.listColumnNames.map((_,index)=> index + 1);
    } else {
      this.listColumnToDisplay = [];
    }
    this.utilService.setColumnFlowManagement(this.listColumnToDisplay);
  }

  onChangeSelectColumn() {
    if(this.listColumnToDisplay.length == this.listColumnNames.length){
      this.isAllOptions = true;
    }
    else {
      this.isAllOptions = false;
    }
    this.utilService.setColumnFlowManagement(this.listColumnToDisplay);
  }
}
