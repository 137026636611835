import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { PriorityEpisodeRequest } from '../../requests/medical-record/priority-episode.request';

@Injectable({
  providedIn: 'root'
})
export class PriorityEpisodeService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  updatePriority(idEpisode: number, body: PriorityEpisodeRequest): Observable<ReturnStruct>{
    let uri = `PriorityEpisode/idEpisode/${idEpisode}`
      
    return this.httpClient.put<ReturnStruct>(environment.urlApiMedicalRecord + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
