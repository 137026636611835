export class UserRegisterRequest{
    
    public userName: string;
    
    public login: string;
    
    public cpf: string;
    
    public tempPassword: string;
    
    public council: string;
    
    public email: string;
    
    public idProfession: number;
    
    public phone: string;
    
    public isActive: boolean;
    
    public listProfile: number[];

    public haveSignature: boolean;

    public cnpj: string;

    public cns: string;

    public idCbo: number;

    public idStateCouncil: number;

    public ip: string;

    public hasICPBrasilCertificate: boolean;

    public certificateIdentifier: string;

    public hasIcpBrasilTokenCertificate: boolean;

    public certifiedManchester: boolean;
}