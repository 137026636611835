<h1>Seleção de Fluxograma</h1>
<div class="white-body">
    <form class="form" [formGroup]="model" (ngSubmit)="selectFlowchart()"  [appUppercaseForm]="this.setUppercaseText">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12">
                <mat-form-field appearance="outline">
                    <mat-label>Queixas</mat-label>
                    <textarea matInput formControlName="complain"></textarea>
                    <mat-error *ngIf="model.get('complain').invalid">Informe a queixa do paciente</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="title-header">
            <div class="row">
                <div class="col-12 col-sm-8 col-md-10">
                    <h1>Reclassificação</h1>
                </div>
            </div>
        </div>
        <div class="row justify-content-md-center">
            <div class="col-md-10">
                <div class="row list-row">
                    <div class="col-md-4">
                    </div>
                    <div class="col-md-4">
                        <button mat-flat-button type="submit"
                            class="fluxograma-button">
                            {{flowchart}}
                        </button>
                    </div>
                    <div class="col-md-4">
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>