import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { FowardService } from 'src/app/shared/services/API/orchestrator-queue/foward.service';
import { SelectedLocationRequest } from 'src/app/shared/services/requests/orchestrator-queue/selected-location.request';

@Component({
  selector: 'app-forward-patient-sadt-confirm-modal',
  templateUrl: './forward-patient-sadt-confirm-modal.component.html',
  styleUrls: ['./forward-patient-sadt-confirm-modal.component.css']
})
export class ForwardPatientSadtConfirmModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ForwardPatientSadtConfirmModalComponent>,
    private alertService: AlertService,
    public fowardService: FowardService,
  ) { this.matDialogRef.disableClose = true; }

  public isLoading: boolean;
  public selectedLocationRequest: SelectedLocationRequest
  public idHealthUnit: number;
  public idEpisode: number;

  ngOnInit(): void {
    this.isLoading = false;
    this.selectedLocationRequest = new SelectedLocationRequest();
    this.idEpisode = this.data.idEpisode;
    this.selectedLocationRequest = this.data.selectedLocationRequest;
  }

  close() {
    if (this.isLoading == true) return;
    this.isLoading = true;
    this.matDialogRef.close({ fowardPatient: false });
  }

  clickConfirm() {
    this.fowardPatient(this.selectedLocationRequest);
  }

  clickCancel() {
    if (this.isLoading == true) return;
    this.isLoading = true;
    this.matDialogRef.close({ fowardPatient: false });
  }

  fowardPatient(body: SelectedLocationRequest) {
    if (this.isLoading)
      return;

    this.isLoading = true;

    this.fowardService.fowardPatient(body).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (response.errorCode == 4)
          this.alertService.show('Aviso', response.errorDescription, AlertType.warning);
        else
          this.alertService.show('Sucesso', "Paciente encaminhado com sucesso", AlertType.success);

        this.isLoading = false;
        this.matDialogRef.close({ fowardPatient: true });
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.matDialogRef.close({ fowardPatient: false });
      }
    });
  }
}