<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <a class="back-link" routerLink="/audit/audit">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Usuários
        </a>
        <h1>Auditar:</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <div class="audit-general-info">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12">
                        <h1>Informações gerais:</h1>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12">
                        <span>Classificadores Auditados: {{this.sumAuditedUserAmount}}</span>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12">
                        <span>Número total de classificações realizadas pelos profissionais auditados até a geração da
                            amostra:
                            {{this.sumTotalClassificationAmount}}</span>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12">
                        <span>Número de classificações a serem auditadas:
                            {{this.sumTotalAuditedClassificationAmount}}</span>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12">
                        <span>Amostra: {{this.sampleAmount}}%</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="white-body"> -->
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <ng-container formArrayName="listTriage" *ngFor="let item of listAuditTriageInfo">
                        <app-execute-audit-triage [infoStruct]="item" [gravityColorCode]="item.gravityColorCode"
                            [painColorCode]="item.painColorCode" [displayCarePriorityInRiskClassification]="displayCarePriorityInRiskClassification" 
                            [events]="submitInvalidateEventToAudtiTriage.asObservable()"
                            [idAuditStatus]="this.idAuditStatus" [showFirst]="this.showFirst"
                            [showOther]="this.showOther" [showSecond]="this.showSecond"
                            [userFirstAuditor]="userNameFirstAuditor" [userSecondAuditor]="userNameSecondAuditor"
                            [userOtherAuditor]="userNameOtherAuditor" [numberOtherStatusAudit]="numberOtherStatusAudit"
                            [listAllIncorrectClassificationReason]="this.listAllIncorrectClassificationReason"
                            [listAllCorrectClassificationReason]="this.listAllCorrectClassificationReason"
                            [viewTimeTag]="viewTimeTag" [datetimeInclusionClassificationRiskAudit]="datetimeInclusionClassificationRiskAudit">
                        </app-execute-audit-triage>
                    </ng-container>
                </div>
            </div>
            <div class="row">
                <div *ngIf="!this.isLocked" class="col-md-2">
                    <button mat-flat-button type="submit" color="primary" class=" btn-block">
                        <span *ngIf="isLoading == false">Salvar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
                <div class="col-md-2">
                    <a mat-flat-button type="button" routerLink="/audit/audit" color="accent" class=" btn-block"
                        *ngIf="!isFirstLoading">
                        <span *ngIf="!this.isLocked">
                            Cancelar
                        </span>
                        <span *ngIf="this.isLocked">
                            Voltar
                        </span>
                    </a>
                </div>
            </div>
        </form>
        <!-- </div> -->
    </div>
</div>