import { KitItemIdAndAmountToUseStruct } from "../../structs/pharmacy/kit-item-id-and-amount-to-use.struct";

export class LiberatePrescriptionRequest {
    public listIdItemAmount: KitItemIdAndAmountToUseStruct[];

    public medicineName: string;
    public administrationTypeName: string;
    public patientName: string;
    public motherName: string;
    public dilutionName: string;

    public birthDate: Date;

    public listIdKit: number[];
    public idStorage: number;
    public idMedicine: number;
    public idLocation: number;
    public quantity: number;
    public idStatusPrescription: number;
    public idMedicalPrescription: number;
    public idEpisode: number;
}