<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <h1>Kit</h1>
        <div class="white-body">
            <a mat-flat-button color="primary" class=" add-user" routerLink="/pharmacy/kit/register"><mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon> Cadastrar Kit</a>
            <div class="filters">
                <div class="row">   
                    <div class="col-12 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Pesquisar</mat-label>
                            <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText" (keyup)="onKeySearch($event)">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Tipo de Kit</mat-label>
                            <mat-select (selectionChange)="search()" [multiple]="false" [(ngModel)]="kitIdType">
                                <mat-option [value]="">Todos</mat-option>
                                <mat-option *ngFor="let item of listKitTypes" [value]="item.idKitType">
                                    {{item.kitTypeName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="card-list" *ngIf="listKit != null">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listKit">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary">{{item.kitName}}</span>
                                <span class="secundary-status last-info">{{item.kitCode}}</span>
                                <span class="secundary-status last-info">{{item.kitTypeName}}</span>
                            </div>
                            <div class="actions">
                                <a><mat-icon aria-hidden="false" aria-label="Editar" [routerLink] = "['/pharmacy/kit/register', {idKit: item.idKit}]">edit</mat-icon> </a>
                                <a><mat-icon aria-hidden="false" aria-label="Editar" (click)="openModal(item.idKit)">delete_forever</mat-icon> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <app-empty-list *ngIf="listKit != undefined && listKit.length == 0"></app-empty-list>
        </div>
    
        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>

</div>    
