import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { MedicineHierarchyResponse } from '../../responses/medic/medicine-hierarchy.response';

@Injectable({
  providedIn: 'root'
})
export class MedicineHierarchyService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listAllMedicine(): Observable<MedicineHierarchyResponse> {

    let uri = 'MedicineHierarchy'

    return this.httpClient.get<MedicineHierarchyResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}