<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <a class="back-link" routerLink="/master/meta-data">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Metadado
        </a>
        <h1>Cadastrar Metadado</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="row">                
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome</mat-label>
                            <input matInput type="text" formControlName="name">
                            <mat-error *ngIf="model.get('name').invalid">Informe o nome</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Tipo do metadado</mat-label>
                            <mat-select formControlName="idMetaDataType" (selectionChange)="verifyMetaDataType()">
                                <mat-option *ngFor="let item of listMetaDataType"
                                    [value]="item.idMetaDataType">{{item.metaDataTypeName}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idMetaDataType').invalid">Informe o tipo</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div *ngIf="isSelect">
                        <div formArrayName="listOptions"
                        *ngFor="let item of model.get('listOptions')['controls']; let i = index" >
                            <div class="row" [formGroupName]="i">
                                <div class="col-12 col-sm-6 col-md-4" >
                                    <mat-form-field appearance="outline">
                                        <mat-label>Opção</mat-label>
                                        <input matInput type="text" formControlName="metaDataOptionName">
                                    </mat-form-field>
                                </div>                  
                                <div class="col-2 col-sm-2 col-md-3">
                                    <button class="option-button " color="primary" mat-mini-fab type="button" (click)="addNext()">
                                        <mat-icon aria-hidden="false" aria-label="Menu apps" >add</mat-icon>
                                    </button>
                                    <button class="option-button " color="primary" mat-mini-fab type="button" (click)="removeOption(i)" *ngIf="i != 0">
                                        <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
                                    </button>
                                </div>                
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-md-2">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <a mat-flat-button type="button" routerLink="/master/meta-data" color="accent" class=" btn-block">
                            Cancelar
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>
    
</div>