import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cancel-confirm-patient-online',
  templateUrl: './cancel-confirm-patient-online-modal.component.html',
  styleUrls: ['./cancel-confirm-patient-online-modal.component.css']
})
export class CancelConfirmPatientOnlineModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<CancelConfirmPatientOnlineModalComponent>,
    public router:Router,
  ) { }

  public idPatientAppointment: number;
  public guideWasUpdate: boolean = false;

  ngOnInit(): void {
    if(this.data.guideWasUpdate != undefined && this.data.guideWasUpdate != null)
      this.guideWasUpdate = this.data.guideWasUpdate;
    if (this.data.idPatientAppointment){
      this.idPatientAppointment = this.data.idPatientAppointment;
    }
  }
  
  clickConfirm(){
    this.matDialogRef.close({isCancel:true});
  }

  close(){
    this.matDialogRef.close({isCancel:false}); 
  }

  clickCancel(){
    this.matDialogRef.close({isCancel:false}); 
  }
}

