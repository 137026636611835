import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { KitTypeService } from 'src/app/shared/services/API/pharmacy/kit-type.service';
import { KitService } from 'src/app/shared/services/API/pharmacy/kit.service';
import { KitTypeModel } from 'src/app/shared/services/models/pharmacy/kit-type.model';
import { ListKitStruct } from 'src/app/shared/services/structs/pharmacy/list-kit.struct';
import { KitDeleteModalComponent } from './kit-delete-modal/kit-delete-modal.component';

@Component({
  selector: 'app-kit-list',
  templateUrl: './kit-list.component.html',
  styleUrls: ['./kit-list.component.css']
})
export class KitListComponent implements OnInit {

  constructor(private kitService: KitService,
    private kitTypeService: KitTypeService,
    private alertService: AlertService,
    public dialog: MatDialog
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.pharmacy;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.pharmacy_kit;

  public listKit: ListKitStruct[];
  public listKitTypes: KitTypeModel[];
  public isLoading: boolean;
  public isActive: boolean;
  public searchText: string;
  public kitIdType: number;

  ngOnInit(): void {
    this.isLoading = true;
    this.populateKitTypes();
    this.search();
  }

  search() {
    this.isLoading = true;
    if (this.searchText == "")
      this.searchText = null;

    this.kitService.listKit(this.searchText, this.kitIdType).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.listKit = response.listKits;

      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  populateKitTypes() {
    this.kitTypeService.listAllKitTypes().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.listKitTypes = response.listKitTypes;

      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }

  openModal(idKit) {
    const dialogRef = this.dialog.open(KitDeleteModalComponent, {
      data: {
        idKit: idKit
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.deleteKit) {
        this.search();
      }
    });
  }
}
