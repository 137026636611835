import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UtilsClassificationService } from 'src/app/classification/utils.service';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { SelfTriageResumeModalComponent } from 'src/app/shared/components/self-triage-resume-modal/self-triage-resume-modal.component';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { DiscriminatorService } from 'src/app/shared/services/API/risk-classification/discriminator.service';
import { DiscriminatorResponse } from 'src/app/shared/services/responses/risk-classification/discriminator.response';
import { SelfTriageResponse } from 'src/app/shared/services/responses/self-triage/self-triage.response';

@Component({
  selector: 'app-discriminator-select',
  templateUrl: './discriminator-select.component.html',
  styleUrls: ['./discriminator-select.component.css']
})
export class DiscriminatorSelectComponent implements OnInit {

  constructor(
    private alertService: AlertService,
    public router: Router,
    private utilService: UtilsClassificationService,
    private dialog: MatDialog,
    private discriminatorService: DiscriminatorService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.digital_prompt_service;

  public isFirstLoading: boolean = true;
  public isSelfTriage: boolean = false;
  public discriminatorResponse: DiscriminatorResponse;
  public selfTriage: SelfTriageResponse;
  public flowchartName: string;
  @Input() discriminators: any[] = [];
  @Input() painColorCode: string[] = [];
  @Output() next = new EventEmitter<any>();

  ngOnInit(): void {
    let data = this.utilService.getClassificationData();

    this.isSelfTriage = data.selfTriage && data.selfTriage.listQuestionAnswer && data.selfTriage.listQuestionAnswer.length > 0;
    this.selfTriage = data.selfTriage;

    this.flowchartName = data.flowchartName;
    this.getDiscriminator(data.idFlowchart, data.birthDate);
  }

  getDiscriminator(idFlowchart: number, birthDate: Date) {
    this.discriminatorService.getDiscriminator(idFlowchart, birthDate, null).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.isFirstLoading = false;
      this.discriminatorResponse = response;
    });
  }


  selectDiscriminator(event: any) {
    let data = this.utilService.getClassificationData();

    data.isPatientWhite = false;
    data.discriminator = event.item;
    data.discriminatorValues = this.discriminators;
    data.discriminatorAllValues = event.discriminatorsValues;

    data.priority = this.discriminatorResponse.priorityColor.find(c => c.idPriorityColor == data.discriminator.idPriorityColor);
    this.utilService.updateClassificationData(data);
    this.next.emit();
  }

  openSelfTriageResumeModal() {
    const dialogRef = this.dialog.open(SelfTriageResumeModalComponent, {
      data: {
        selfTriage: this.selfTriage,
      },
    });
  }
}