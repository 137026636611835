<div class="feature-content">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12">
            <mat-label>
                <strong>Alergias do paciente: </strong>
                <div class="list-tag">
                    <div class="tag" *ngFor="let item of listAllergyEpisode">
                        <span class="active-tag" *ngIf="!item.isDeleted">
                            {{item.allergyName}}
                        </span>
                        <span class="deleted-tag" *ngIf="item.isDeleted">
                            {{item.allergyName}}
                        </span>
                    </div>
                </div>

            </mat-label>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12">
            <mat-label>
                <strong>Evolução do paciente: </strong>
                <p [innerHTML]="nursingConsultation.patientEvolution"></p>
            </mat-label>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12">
            <mat-label>
                <strong>Sinais vitais :</strong>
            </mat-label>
        </div>
    </div>
    <div class="row">
        <div class="col-6 col-sm-3 col-md-2">
            <mat-form-field appearance="outline">
                <mat-label>Temperatura</mat-label>
                <input type="number" readonly matInput value="{{nursingConsultation.temperature}}">
                <span matSuffix>ºC</span>
            </mat-form-field>
        </div>
        <div class="col-6 col-sm-3 col-md-2">
            <mat-form-field appearance="outline">
                <mat-label>FR</mat-label>
                <input type="number" readonly matInput value="{{nursingConsultation.respiratoryFrequency}}">
                <span matSuffix>irpm</span>
            </mat-form-field>
        </div>
        <div class="col-6 col-sm-3 col-md-2">
            <mat-form-field appearance="outline">
                <mat-label>FC</mat-label>
                <input type="number" readonly matInput value="{{nursingConsultation.heartRate}}">
                <span matSuffix>bpm</span>
            </mat-form-field>
        </div>
        <div class="col-6 col-sm-3 col-md-2">
            <mat-form-field appearance="outline">
                <mat-label>GC</mat-label>
                <input type="number" readonly matInput value="{{nursingConsultation.glucose}}">
                <span matSuffix>mg/dL</span>
            </mat-form-field>
        </div>
        <div class="col-6 col-sm-3 col-md-2">
            <mat-form-field appearance="outline">
                <mat-label>PA</mat-label>
                <input type="text" readonly matInput
                    value="{{nursingConsultation.bloodPressureSystole}}/{{nursingConsultation.bloodPressureDiastole}}">
                <span matSuffix>mmHG</span>
            </mat-form-field>
        </div>
        <div class="col-6 col-sm-3 col-md-2">
            <mat-form-field appearance="outline">
                <mat-label>SatO2</mat-label>
                <input type="number" readonly matInput value="{{nursingConsultation.saturation}}">
                <span matSuffix>%</span>
            </mat-form-field>
        </div>
    </div>
    <mat-label>
        <strong>Checagem de prescrição: </strong>
        <app-presciption-list [listPrescription]="nursingConsultation.listMedicalPrescriptionStructs"
            [listPrescriptionMedicatedSolution]="nursingConsultation.listMedicatedSolutionPrescription"
            [patientName]="patientName" [birthDate]="birthDate" [idEpisode]="idEpisode" [actions]="false"
            [idNursingConsultation]="nursingConsultation.idNursingConsultation">
        </app-presciption-list>
    </mat-label>
    <mat-label *ngIf="hasObservationProcedure">
        <strong>Checagem de procedimentos: </strong>
        <app-procedure-list [listProcedure]="nursingConsultation.listMedicalProcedureStructs"
            [patientName]="patientName" [birthDate]="birthDate" [idEpisode]="idEpisode" [actions]="false"
            [idNursingConsultation]="nursingConsultation.idNursingConsultation">
        </app-procedure-list>
    </mat-label>
</div>