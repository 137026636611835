import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SelectFavoriteMedicineModalComponent } from '../select-favorite-medicine-modal/select-favorite-medicine-modal.component';
import { ExamTypeModel } from 'src/app/shared/services/models/SADT/exam-type.model';
import { ExamStruct } from 'src/app/shared/services/structs/SADT/exam.struct';
import { FavoriteMedicatedSolutionStruct } from 'src/app/shared/services/structs/medic/favorite-medicated-solution.struct';
import { FavoriteStandardMedicine } from 'src/app/shared/services/models/medic/favorite-standard-medicine.model';

@Component({
  selector: 'app-invalid-prescription-modal',
  templateUrl: './invalid-prescription-modal.component.html',
  styleUrls: ['./invalid-prescription-modal.component.css']
})
export class InvalidPrescriptionModalComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<InvalidPrescriptionModalComponent>,
  ) { }

  public listInvalidMedicine: FavoriteStandardMedicine[] = this.data.listInvalidMedicine ? this.data.listInvalidMedicine : [];
  public listInvalidWithExamType: ExamTypeModel[] = this.data.listInvalidWithExamType ? this.data.listInvalidWithExamType : [];
  public listInvalidWithExam: ExamStruct[] = this.data.listInvalidWithExam ? this.data.listInvalidWithExam : [];
  public listInvalidMedicatedSolution: FavoriteMedicatedSolutionStruct[] = this.data.listInvalidMedicatedSolution? this.data.listInvalidMedicatedSolution : [];
  public description: string = "Os seguintes medicamentos não podem ser prescritos neste setor:";

  ngOnInit(): void {
    if(this.data.description)
      this.description = this.data.description;
  }

  clickCancel() {
    this.matDialogRef.close({ confirm: false });
  }

  clickOk() {
    this.matDialogRef.close({ confirm: true });
  }
}