import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { BusinessIntelligenceResponse } from '../../responses/business-intelligence/business-intelligence.response';

@Injectable({
  providedIn: 'root'
})
export class ReportHealthUnitService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getReports(): Observable<BusinessIntelligenceResponse> {
    let uri = `ReportHealthUnit/getAll`

    return this.httpClient.get<BusinessIntelligenceResponse>(environment.urlApiBusinessIntelligence + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
