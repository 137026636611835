import { TriageModel } from "../../models/medical-record/triage.model";
import { AllergyStruct } from "../../structs/medical-record/allergy.struct";
import { CareLinePriorityStatusStruct } from "../../structs/medical-record/care-line-priority-status.struct";
import { CounselingStruct } from "../../structs/risk-classification/counseling.struct";
import { BillingDataRequest } from "../billing/billing-data-request";

export class TriageRequest extends TriageModel {
    public conclusionNotes: string;
    public listAllergy: AllergyStruct[];
    public listCounseling: CounselingStruct[];
    public idRoom: number;
    public idQueue: number;
    public idFlow: number;
    public idSector: number;
    public idFlowCompleted: number;
    public roomName: string;
    public signed: boolean;
    public billingData: BillingDataRequest;
    public listIdComorbodity: number[];
    public comorbidities: string;
    public hasFlowchartIA: boolean;
    public iaResult: string;
    public idArrivalReason: number;
    public idArrivalType: number;
    public idOrigin: number;
    public idPatient: number;
    public careLinePriorityStatusStruct: CareLinePriorityStatusStruct = new CareLinePriorityStatusStruct();
    public idNatureOfAttendance: number;
    public isReclassification: boolean;
    public idSignatureProvider: number;
    public signatureAuthentication: string;
    public isAutistic: boolean;
}