import { Component, OnInit } from '@angular/core';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { DeviceTypeEnum } from 'src/app/shared/enum/device-type.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { LocationByModuleService } from 'src/app/shared/services/API/flow/location-by-module.service';
import { DeviceTypeService } from 'src/app/shared/services/API/risk-classification/device-type.service';
import { MeasurerService } from 'src/app/shared/services/API/risk-classification/measurer.service';
import { ProtocolSectorService } from 'src/app/shared/services/API/risk-classification/protocol-sector.service';
import { ProtocolService } from 'src/app/shared/services/API/risk-classification/protocol.service';
import { DeviceTypeModel } from 'src/app/shared/services/models/risk-classification/device-type.model';
import { MeasurerModel } from 'src/app/shared/services/models/risk-classification/measurer.model';
import { ProtocolModel } from 'src/app/shared/services/models/risk-classification/protocol.model';
import { RoomMeasurerModel } from 'src/app/shared/services/models/risk-classification/room-measurer.model';
import { ProtocolSectorRequest } from 'src/app/shared/services/requests/risk-classification/protocol-sector.request';
import { LocationByModuleResponse } from 'src/app/shared/services/responses/flow/location-by-module.response';
import { RoomStruct } from 'src/app/shared/services/structs/flow/room.struct';
import { SectorStruct } from 'src/app/shared/services/structs/flow/sector.struct';
import { LinkDeviceTypeSectorStruct } from 'src/app/shared/services/structs/risk-classification/link-device-type-sector.struct';
import { LinkProtocolSectorStruct } from 'src/app/shared/services/structs/risk-classification/link-protocol-sector.struct';
import { LinkRoomMeasurerStruct } from 'src/app/shared/services/structs/risk-classification/link-room-measurer.struct';
import { ProtocolSectorStruct } from 'src/app/shared/services/structs/risk-classification/protocol-sector.struct';
import { LookupRiskService } from 'src/app/shared/services/API/risk-classification/lookup-risk.service';
import { SupplementaryDataRequiredField } from 'src/app/shared/services/models/risk-classification/supplementary-data-required-field.model';
import { PreAdmissionRequiredField } from 'src/app/shared/services/models/risk-classification/pre-admission-required-field.model';
import { FormBuilder, FormGroup, FormArray, ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';
import { MetaDataService } from 'src/app/shared/services/API/meta-data/meta-data.service';
import { MetaDataStruct } from 'src/app/shared/services/structs/meta-data/meta-data.struct';
import { ActivatedRoute } from '@angular/router';
import { MeasurerStruct } from 'src/app/shared/services/structs/risk-classification/measurer.struct';
import { UtilService } from 'src/app/shared/services/util.service';
import { UserRoleEnum } from 'src/app/shared/enum/user-role.enum';
import { TimeSpanStruct } from 'src/app/shared/structs/time-span.struct';
import { TimeStruct } from 'src/app/shared/structs/time.struct';
import { MeasurerTypeEnum } from 'src/app/shared/enum/measurer-type.enum';

@Component({
  selector: 'app-config-sector-protocol',
  templateUrl: './config-sector-protocol.component.html',
  styleUrls: ['./config-sector-protocol.component.css']
})

export class ConfigSectorProtocolComponent implements OnInit {
  constructor(private protocolService: ProtocolService,
    private locationByModuleSerivce: LocationByModuleService,
    private protocolSectorService: ProtocolSectorService,
    private deviceTypeService: DeviceTypeService,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private measurerService: MeasurerService,
    private lookupRiskService: LookupRiskService,
    private metaDataService: MetaDataService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.classification;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.classification_config_protocol_sector;

  public model: FormGroup;

  public listProtocol: ProtocolModel[] = [];
  public listDeviceType: DeviceTypeModel[] = [];
  public listMetaDataRequiredSelected: MetaDataStruct[] = [];
  public listIdMetaDataRequiredSelected: number[] = [];
  public matrixProtocol: any[] = [];
  public listSector: SectorStruct[];
  public listRoom: RoomStruct[];
  public listOximeter: MeasurerModel[];
  public listPressureMeasurer: MeasurerModel[];
  public listThermometer: MeasurerModel[];
  public listGlucometer: MeasurerModel[];
  public listRoomMeasurer: RoomMeasurerModel[];
  public listSectorProtocol: ProtocolSectorStruct[];
  public listSupplementaryDataRequiredField: SupplementaryDataRequiredField[];
  public listPreAdmissionRequiredField: PreAdmissionRequiredField[];
  public listIdSector: number[];
  public listMetaData: MetaDataStruct[];
  public checkFilling: boolean = false;

  public protocolSectorRequest: ProtocolSectorRequest;
  public locationByModuleResponse: LocationByModuleResponse;

  public isFirstLoading: boolean;
  public isLoading: boolean;
  public hasConfigSectorVisualizarRole: boolean;

  public idRoom: number;

  ngOnInit(): void {
    this.isLoading = false;
    this.isFirstLoading = true;
    this.protocolSectorRequest = new ProtocolSectorRequest();

    let listRole = this.utilService.getRoles();
    if (listRole.some(x => x.idRole === UserRoleEnum.config_sector_alterar))
      this.hasConfigSectorVisualizarRole = false;
    else
      this.hasConfigSectorVisualizarRole = true;

    this.model = this.formBuilder.group({
      listSelect: this.formBuilder.array([]),
    }, {
      validators: [this.ValidateMacAddresses()],
      updateOn: 'change'
    });

    if (this.activatedRoute.snapshot.paramMap.get('idRoom'))
      this.idRoom = parseInt(this.activatedRoute.snapshot.paramMap.get('idRoom'));

    this.getAllMetaData();
    this.listAllRequiredFields();
    this.listHealthUnitProtocol();
    this.listAllDeviceType();
    this.listMeasurerByType();
  }

  submit() {
    if (!this.model.valid)
      return;

    this.isLoading = true;
    this.protocolSectorRequest.listProtocolSector = [];
    this.protocolSectorRequest.listDeviceTypeSector = [];
    this.protocolSectorRequest.listRoomMeasurer = [];
    this.protocolSectorRequest.listMetaData = [];

    let isError = false;

    this.model.get('listSelect').value.forEach(x => {

      var protocolSectorStruct = new LinkProtocolSectorStruct();
      protocolSectorStruct.idSector = x.idSector ? x.idSector : null;
      protocolSectorStruct.listIdProtocol = x.listIdProtocol;
      protocolSectorStruct.listSupplementaryDataIdRequiredField = x.listSupplementaryDataIdRequiredField;
      protocolSectorStruct.listPreAdmissionIdRequiredField = x.listPreAdmissionIdRequiredField;
      protocolSectorStruct.listIdMetaData = x.listMetadata;
      protocolSectorStruct.listIdRequiredMetaData = x.listMetaDataRequiredSelected;
      this.protocolSectorRequest.listProtocolSector.push(protocolSectorStruct);

      let linkDeviceTypeSectorStruct = new LinkDeviceTypeSectorStruct();
      linkDeviceTypeSectorStruct.idSector = x.idSector ? x.idSector : null;
      linkDeviceTypeSectorStruct.idDeviceType = x.idDeviceType;
      linkDeviceTypeSectorStruct.disableScreenSupplementaryData = x.disableScreenSupplementaryData;
      linkDeviceTypeSectorStruct.disableShowReport = x.disableShowReport;
      linkDeviceTypeSectorStruct.allowAdmissionDataChanging = x.allowAdmissionDataChanging;
      linkDeviceTypeSectorStruct.setUppercaseText = x.setUppercaseText;
      linkDeviceTypeSectorStruct.skipIdentifiedPatientPreRegister = x.skipIdentifiedPatientPreRegister;

      if ((protocolSectorStruct.listIdProtocol && protocolSectorStruct.listIdProtocol.length > 0)
        && !linkDeviceTypeSectorStruct.idDeviceType)
        isError = true;

      this.protocolSectorRequest.listDeviceTypeSector.push(linkDeviceTypeSectorStruct);

      x.listRoomMeasurer.forEach(element => {
        let linkRoomMeasurerStruct = new LinkRoomMeasurerStruct();
        linkRoomMeasurerStruct.idDeviceType = x.idDeviceType;
        linkRoomMeasurerStruct.listMeasurer = [];

        if (x.idDeviceType != DeviceTypeEnum.Remoto) {
          linkRoomMeasurerStruct.listMeasurer.push(new MeasurerStruct(element.idGlucometer, element.nameGlucometer, element.addressGlucometer));
          linkRoomMeasurerStruct.listMeasurer.push(new MeasurerStruct(element.idOximeter, element.nameOximeter, element.addressOximeter));
          linkRoomMeasurerStruct.listMeasurer.push(new MeasurerStruct(element.idPressureMeasurer, element.namePressureMeasurer, element.addressPressureMeasurer));
          linkRoomMeasurerStruct.listMeasurer.push(new MeasurerStruct(element.idThermometer, element.nameThermometer, element.addressThermometer));
          if (element.idOximeter == null || element.idThermometer == null || element.idGlucometer == null || element.idPressureMeasurer == null) {
            this.checkFilling = true;
          }
        }

        linkRoomMeasurerStruct.idRoom = parseInt(element.idRoom);
        this.protocolSectorRequest.listRoomMeasurer.push(linkRoomMeasurerStruct);
      });
    });

    this.protocolSectorRequest.listMetaData = this.listMetaData;
    if (this.checkFilling == true) {
      this.alertService.show('Erro', "Todos os tipos de medidores devem ser preenchidos para todas as salas", AlertType.error);
      this.isLoading = false;
      this.checkFilling = false;
      return;
    }

    if (isError) {
      this.alertService.show('Erro', "Ao escolher o protocolo, é obrigatório escolher o tipo de dispositivo", AlertType.error);
      this.isLoading = false;
      return;
    }

    this.linkProtocolSector();
  }

  listClassificationSector(listMetaData: MetaDataStruct[]) {
    this.locationByModuleSerivce.listRoomByModule(MenuModuleEnum.classification).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listRoom = response.rooms;
        this.listSector = response.sectors;
        this.listIdSector = this.listSector.map(x => x.idSector);

        this.listSector.forEach(element => {
          element.listRoom = this.listRoom.filter(x => x.idSector == element.idSector);
        });

        this.listProtocolSector(response.rooms, listMetaData);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  listHealthUnitProtocol() {
    this.protocolService.listProtocol().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listProtocol = response.listProtocol;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  listAllDeviceType() {
    this.deviceTypeService.listDevicetype().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listDeviceType = response.listDeviceType;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  listMeasurerByType() {
    this.measurerService.listMeasurerByType().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listGlucometer = response.listGlucometer;
        this.listOximeter = response.listOximeter;
        this.listPressureMeasurer = response.listPressureMeasurer;
        this.listThermometer = response.listThermometer;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  linkProtocolSector() {
    this.protocolSectorService.linkProtocolSector(this.protocolSectorRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', 'Configurações salvas com sucesso', AlertType.success);

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  createRoomMeasurerForm(protocolSector: ProtocolSectorStruct) {
    let arrayFormGroup = [];
    protocolSector.listRoom.forEach(x => {
      let formGroup = this.formBuilder.group({
        idRoom: [x.idRoom],
        idSector: [x.idSector],
        idOximeter: [],
        addressOximeter: [],
        nameOximeter: [],
        idThermometer: [],
        addressThermometer: [],
        nameThermometer: [],
        idGlucometer: [],
        addressGlucometer: [],
        nameGlucometer: [],
        idPressureMeasurer: [],
        addressPressureMeasurer: [],
        namePressureMeasurer: [],
      });
      arrayFormGroup.push(formGroup);
    });

    return arrayFormGroup;
  }

  populateFormArray() {
    this.listSectorProtocol.forEach(x => {
      (this.model.controls['listSelect'] as FormArray).push(this.createForm(x));
    });

    this.isFirstLoading = false;
  }

  createForm(protocolSector: ProtocolSectorStruct) {
    return this.formBuilder.group({
      idSector: [protocolSector.idSector],
      listIdProtocol: [protocolSector.listProtocol.map(x => x.idProtocol)],
      idDeviceType: [protocolSector.idDeviceType],
      disableScreenSupplementaryData: [protocolSector.disableScreenSupplementaryData],
      disableShowReport: [protocolSector.disableShowReport],
      allowAdmissionDataChanging: [protocolSector.allowAdmissionDataChanging],
      setUppercaseText: [protocolSector.setUppercaseText],
      skipIdentifiedPatientPreRegister: [protocolSector.skipIdentifiedPatientPreRegister],
      listSupplementaryDataIdRequiredField: [protocolSector.listSupplementaryDataRequiredField.map(x => x.idSupplementaryDataRequiredField)],
      listPreAdmissionIdRequiredField: [protocolSector.listPreAdmissionIdRequiredField],
      listRoomMeasurer: this.formBuilder.array(this.createRoomMeasurerForm(protocolSector)),
      listMetadata: [protocolSector.listIdMetaData],
      listMetaDataRequiredSelected: [protocolSector.listIdMetaDataRequired],
    });
  }

  ValidateMacAddresses(): ValidatorFn {
    function RetrieveRoomMeasurerControl(form: FormGroup, sectorIndex: number, roomIndex: number): AbstractControl | null {
      let control = form.get('listSelect')?.get(sectorIndex.toString())?.get('listRoomMeasurer')?.get(roomIndex.toString());
      return control;
    }

    return (formGroup: FormGroup): ValidationErrors | null => {
      if (!formGroup)
        return null;
      let sectors = formGroup.get('listSelect')?.getRawValue();

      let intersection: boolean[] = [];
      let intersectionError = { intersection: true };

      let addressPool = new Map();

      if (sectors) {
        for (let sectorIndex = 0; sectorIndex < sectors.length; sectorIndex++) {
          let sector = sectors[sectorIndex];
          if (sector.listRoomMeasurer) {
            for (let roomIndex = 0; roomIndex < sector.listRoomMeasurer.length; roomIndex++) {
              let roomMeasurerForm: AbstractControl = RetrieveRoomMeasurerControl(formGroup, sectorIndex, roomIndex);

              if (!roomMeasurerForm) {
                continue
              }

              let addressThermometer = roomMeasurerForm.get('addressThermometer');

              if (addressThermometer.value) {

                if (addressPool.has(addressThermometer.value)) {
                  roomMeasurerForm.get('addressThermometer').setErrors({ intersection: true });
                  roomMeasurerForm.get('addressThermometer').markAsTouched();

                  let value = addressPool.get(addressThermometer.value);
                  let duplicateControl = RetrieveRoomMeasurerControl(formGroup, value.sectorIndex, value.roomIndex);
                  duplicateControl.get('addressThermometer').setErrors({ intersection: true });
                  duplicateControl.get('addressThermometer').markAsTouched();
                  intersection.push(true);
                }
                else {
                  addressPool.set(addressThermometer.value, {
                    sectorIndex: sectorIndex,
                    roomIndex: roomIndex,
                    deviceType: MeasurerTypeEnum.Termômetro
                  });
                }
              }

              let addressOximeter = roomMeasurerForm.get('addressOximeter');

              if (addressOximeter.value) {

                if (addressPool.has(addressOximeter.value)) {
                  roomMeasurerForm.get('addressOximeter').setErrors({ intersection: true });
                  roomMeasurerForm.get('addressOximeter').markAsTouched();

                  let value = addressPool.get(addressOximeter.value);
                  let duplicateControl = RetrieveRoomMeasurerControl(formGroup, value.sectorIndex, value.roomIndex);
                  duplicateControl.get('addressOximeter').setErrors({ intersection: true });
                  duplicateControl.get('addressOximeter').markAsTouched();
                  intersection.push(true);
                }
                else {
                  addressPool.set(addressOximeter.value, {
                    sectorIndex: sectorIndex,
                    roomIndex: roomIndex,
                    deviceType: MeasurerTypeEnum.Oxímetro
                  });
                }
              }

              let addressPressureMeasurer = roomMeasurerForm.get('addressPressureMeasurer');

              if (addressPressureMeasurer.value) {

                if (addressPool.has(addressPressureMeasurer.value)) {
                  roomMeasurerForm.get('addressPressureMeasurer').setErrors({ intersection: true });
                  roomMeasurerForm.get('addressPressureMeasurer').markAsTouched();

                  let value = addressPool.get(addressPressureMeasurer.value);
                  let duplicateControl = RetrieveRoomMeasurerControl(formGroup, value.sectorIndex, value.roomIndex);
                  duplicateControl.get('addressPressureMeasurer').setErrors({ intersection: true });
                  duplicateControl.get('addressPressureMeasurer').markAsTouched();
                  intersection.push(true);
                }
                else {
                  addressPool.set(addressPressureMeasurer.value, {
                    sectorIndex: sectorIndex,
                    roomIndex: roomIndex,
                    deviceType: MeasurerTypeEnum.PA
                  });
                }
              }

              let addressGlucometer = roomMeasurerForm.get('addressGlucometer');

              if (addressGlucometer.value) {

                if (addressPool.has(addressGlucometer.value)) {
                  roomMeasurerForm.get('addressGlucometer').setErrors({ intersection: true });
                  roomMeasurerForm.get('addressGlucometer').markAsTouched();

                  let value = addressPool.get(addressGlucometer.value);
                  let duplicateControl = RetrieveRoomMeasurerControl(formGroup, value.sectorIndex, value.roomIndex);
                  duplicateControl.get('addressGlucometer').setErrors({ intersection: true });
                  duplicateControl.get('addressGlucometer').markAsTouched();
                  intersection.push(true);
                }
                else {
                  addressPool.set(addressGlucometer.value, {
                    sectorIndex: sectorIndex,
                    roomIndex: roomIndex,
                    deviceType: MeasurerTypeEnum.Glicosímetro
                  });
                }
              }
            }
          }
        };
      }
      if (intersection.some(x => x))
        return intersectionError;
      else
        return null
    };
  }

  listProtocolSector(rooms: RoomStruct[], listMetaData) {
    this.protocolSectorService.listProtocolBySector(this.listIdSector, listMetaData).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listSectorProtocol = response.listProtocolSectorStruct;

        this.listSectorProtocol.forEach(element => {
          element.listRoom = rooms.filter(x => x.idSector == element.idSector);
        });

        this.populateFormArray();
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  listAllRequiredFields() {
    this.lookupRiskService.getLookup().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listSupplementaryDataRequiredField = response.listSupplementaryDataRequiredField;
        this.listPreAdmissionRequiredField = response.listPreAdmissionRequiredField;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getAllMetaData() {
    this.metaDataService.getAllMetaData().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listMetaData = response.listMetaDataStruct;

        this.listClassificationSector(this.listMetaData);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}