import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ListFormStatusResponse } from '../../responses/form/list-form-status.response';


@Injectable({
  providedIn: 'root'
})
export class FormStatusService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getAllFormStatus() : Observable<ListFormStatusResponse>{
    let uri = `Status`
    
    return this.httpClient.get<ListFormStatusResponse>(environment.urlApiForm + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}

