import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { MemedUserTokenResponse } from '../../responses/orchestrator-integration/memed-user-token.response';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { DeleteMemedPrescriptionsRequest } from '../../requests/orchestrator-integration/delete-memed-prescriptions.request';

@Injectable({
  providedIn: 'root'
})
export class UserMemedService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getUserTokenMemed(): Observable<MemedUserTokenResponse>{
    let uri = `UserMemed`
    return this.httpClient.get<MemedUserTokenResponse>(environment.urlApiOrchestratorPatient + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public deletePrescriptionMemed(body: DeleteMemedPrescriptionsRequest): Observable<ReturnStruct>{
    let uri = `UserMemed/deletePrescription`

    return this.httpClient.put<ReturnStruct>(environment.urlApiOrchestratorPatient + uri, body,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}