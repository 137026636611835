import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { LocationService } from 'src/app/shared/services/API/pharmacy/location.service';
import { LocationModel } from 'src/app/shared/services/models/pharmacy/location.model';
import { LocationDeleteModalComponent } from './location-delete-modal/location-delete-modal.component';

@Component({
  selector: 'app-location-list',
  templateUrl: './location-list.component.html',
  styleUrls: ['./location-list.component.css']
})
export class LocationListComponent implements OnInit {

  constructor(private locationService: LocationService,
    private alertService: AlertService,
    public dialog: MatDialog
  ) { }
    
  public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.pharmacy;
  public menuFunctionalityEnum:MenuFunctionalityEnum = MenuFunctionalityEnum.pharmacy_location;
    
  public listLocation: LocationModel[];
  public isLoading: boolean;
  public isActive: boolean;
  public searchText: string;

  ngOnInit(): void {
    this.isLoading = true;
    this.search();
  }

  search(){
    this.isLoading = true;  
    if(this.searchText == "")
    this.searchText = null;
    
    this.locationService.readAll(this.searchText).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.listLocation = response.listLocation;  
    
      this.isLoading = false;    
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  private timeoutKeySearch: any = null;
  
  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }
  
  openModal(idLocation){
    const dialogRef = this.dialog.open(LocationDeleteModalComponent, {
      data: {
        idLocation: idLocation
      },
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.deleteLocation){
        this.search();
      }
    });
  }
}
