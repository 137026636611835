import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { ProcedureSigtapCompetenceResponse } from '../../responses/billing/procedure-sigtap-competence.response';
import { GetProcedureSigtapResponse } from '../../responses/billing/get-procedure-sigtap.response';

@Injectable({
  providedIn: 'root'
})
export class ProcedureSigtapService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  listProcedureSigtapCompetence(): Observable<ProcedureSigtapCompetenceResponse>{
    let uri = `ProcedureSigtap/getAll`
      
    return this.httpClient.get<ProcedureSigtapCompetenceResponse>(environment.urlApiBilling + uri,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  getProcedureSigtap(idProcedureSigtap: number): Observable<GetProcedureSigtapResponse>{
    let uri = `ProcedureSigtap/idProcedureSigtap/${idProcedureSigtap}`
      
    return this.httpClient.get<GetProcedureSigtapResponse>(environment.urlApiBilling + uri,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
