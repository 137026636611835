<div mat-dialog-contentl>
    <div class="mat-dialog-content">
        <div class="head-confirm-modal-list">
            <h1><b>Leito higienizado com sucesso!</b></h1>
            <h2>Retornar para tela de Login do Totem de Higienização?</h2>
        </div>
        <form class="form" (ngSubmit)="submit()">
            <div class="footer-confirm-modal-list">
                <button mat-flat-button color="primary" class="btn-block" type="submit">
                    <span *ngIf="isLoading == false">Sair</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
                <button mat-flat-button color="accent" class="btn-block" (click)="clickCancel()"
                    type="button">Higienizar outro leito</button>
            </div>
        </form>
    </div>
</div>