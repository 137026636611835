<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <h1>Log de Integração</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">      
                <div class="row">   
                    <div class="col-12 col-sm-4 col-md-4">
                        <label>Uri</label>
                        <p>{{integrationLogStruct.httpVerb}}{{integrationLogStruct.uriDescrption}}</p>
                    </div>
                    <div class="col-12 col-sm-4 col-md-4">           
                        <label>IP</label>
                        <p>{{integrationLogStruct.ipRequest}}</p>
                    </div>  
                    <div class="col-12 col-sm-4 col-md-4">
                        <label>Chave de integração</label>
                        <p>{{integrationLogStruct.accessKeyName}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-4 col-md-4">                  
                        <label>Data da requisição</label>
                        <p>{{integrationLogStruct.datetimeBeginRequest | date: 'dd/MM/yyyy HH:mm:ss'}}</p>
                    </div>
                    <div class="col-12 col-sm-4 col-md-4">
                        <label>Status</label>
                        <p>{{integrationLogStruct.logType}}</p>
                    </div>
                    <div class="col-12 col-sm-4 col-md-2">
                        <label>Episódio</label>
                        <p>{{integrationLogStruct.idEpisode}}</p>
                    </div>
                    <div class="col-2" *ngIf="this.canResend">
                        <button mat-flat-button type="button" color="primary" class=" btn-block" (click)="resendWebhook()">
                            <span *ngIf="isLoading == false">Reenviar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Conteudo da requisição</mat-label>
                            <textarea matInput readonly>{{requestJson | json}}</textarea>
                        </mat-form-field>
                    </div>
                </div>  
                <div class="row">             
                    <div col-12>
                        <mat-form-field appearance="outline">
                            <mat-label>Resposta da requisição</mat-label>
                            <textarea matInput readonly>{{responseJson | json}}</textarea>
                        </mat-form-field>
                    </div>
                </div>           
                <div class="row">             
                    <div class="col-md-2">
                        <button mat-flat-button type="button" routerLink="/integration/integration-log/" color="accent" class=" btn-block">
                            <span *ngIf="isLoading == false">Voltar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                </div>
        </div>
    </div>
    
</div>