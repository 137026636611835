<div [formGroup]="this.kitItemSelectStruct.formGroup">
    <div class="row">
        <div class="col-12 col-sm-6 col-md-3">
            <mat-form-field appearance="outline">
                <mat-label>Tipo de Item</mat-label>
                <mat-select formControlName="typeItem" (selectionChange)="selectTypeItem($event.value)">
                    <mat-option [value]="this.typeItemKitEnum.Material">Material</mat-option>
                    <mat-option [value]="this.typeItemKitEnum.Medicine">Medicamento</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-4 col-sm-4 col-md-4">
            <app-select-2 *ngIf="kitItemSelectStruct.formGroup.get('typeItem').value == this.typeItemKitEnum.Material || kitItemSelectStruct.formGroup.get('typeItem').value == null" 
                [formControlField]="'idItem'" [preSelectedlist]="kitItemSelectStruct.preSelectedItem" (selectedValue)="setItemId($event)"
                [multiple]="false" [url]="urlListAllItem" [idField]="'idItem'" [nameField]="'codeWithName'"
                [labelField]="'Item'">
            </app-select-2>

            <app-select-2 *ngIf="kitItemSelectStruct.formGroup.get('typeItem').value == this.typeItemKitEnum.Medicine" 
                [formControlField]="'idItem'" [preSelectedlist]="kitItemSelectStruct.preSelectedMedicine" (selectedValue)="setMedicineId($event)"
                [multiple]="false" [url]="urlListAllMedicine" [idField]="'idMedicine'" [nameField]="'medicineDescription'"
                [labelField]="'Medicamento'">
            </app-select-2>
        </div>
        <div class="col-4 col-sm-4 col-md-3">
            <mat-form-field appearance="outline">
                <mat-label>Quantidade</mat-label>
                <input matInput type="number" formControlName="amount">
                <mat-error *ngIf="kitItemSelectStruct.formGroup.get('amount').invalid">Informe a quantidade</mat-error>
            </mat-form-field>
        </div>
        <div class="col-4 col-sm-4 col-md-2">
            <button class="panel-origin-button " color="primary" mat-mini-fab type="button" (click)="add()">
                <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
            </button>
            <button class="panel-origin-button " color="primary" mat-mini-fab type="button" (click)="remove()"
                *ngIf="!this.kitItemSelectStruct.isSingle">
                <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
            </button>
        </div>
    </div>
</div>