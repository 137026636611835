import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { AuditConfigService } from 'src/app/shared/services/API/audit/audit-config.service';
import { CreateAuditService } from 'src/app/shared/services/API/orchestrator-audit/create-audit.service';
import { GetAuditService } from 'src/app/shared/services/API/orchestrator-audit/get-audit.service';
import { ProtocolService } from 'src/app/shared/services/API/risk-classification/protocol.service';
import { ProtocolModel } from 'src/app/shared/services/models/risk-classification/protocol.model';
import { PostClassificationRiskAuditRequest } from 'src/app/shared/services/requests/audit/post-classification-risk-audit.request';
import { GetSampleRequest } from 'src/app/shared/services/requests/medical-record/get-sample.request';
import { SearchFlowchartRequest } from 'src/app/shared/services/requests/risk-classification/search-flowchart.request';
import { AuditUserSelectStruct } from 'src/app/shared/services/structs/audit/audit-user-select.struct';
import { AuditUserSampleStruct } from 'src/app/shared/services/structs/medical-record/audit-user-sample.struct';
import { ListAllUserByPeriodClassificationStruct } from 'src/app/shared/services/structs/medical-record/list-all-user-by-period-classification.struct';
import { TriageSampleUserAmountStruct } from 'src/app/shared/services/structs/medical-record/triage-sample-user-amount.struct';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-audit-register',
  templateUrl: './audit-register.component.html',
  styleUrls: ['./audit-register.component.css']
})
export class AuditRegisterComponent implements OnInit {

  constructor(
    private formBuilder: UntypedFormBuilder,
    private protocolService: ProtocolService,
    private getAuditService: GetAuditService,
    private createAuditService: CreateAuditService,
    private router: Router,
    private alertService: AlertService,
    private auditConfigService: AuditConfigService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.audit;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.audit_list;

  public listDate: Date[];
  public listDays: number[];
  public listProtocol: ProtocolModel[];
  public listUserSelectStruct: AuditUserSelectStruct[];
  public listAllUser: ListAllUserByPeriodClassificationStruct[];
  public listUserTriageSample: AuditUserSampleStruct[];
  public model: UntypedFormGroup;
  public isMonthSelected: boolean;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;
  public listIdFlowchart: number[];
  public idManchesterV2Protocol: number = environment.idManchesterV2Protocol;
  public showSave: boolean = false;
  public getSampleRequest: GetSampleRequest;
  public postClassificationRiskAuditRequest: PostClassificationRiskAuditRequest;

  public allowAudited: boolean = true;

  public submitInvalidateEventToUserSelect: Subject<void> = new Subject<void>()

  ngOnInit(): void {
    this.isLoading = false;
    this.populateDateSelect();
    this.populateProtocolSelect();
    this.getConfig();

    this.model = this.formBuilder.group({
      yearMonth: ['', [Validators.required]],
      initialDate: ['', [Validators.required]],
      finalDate: ['', [Validators.required]],
      idProtocol: [{value: this.idManchesterV2Protocol, disabled: true}, [Validators.required]],
    });
  }

  submit() {
    if (this.isLoading) {
      return;
    }
    else if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      this.isLoading = false;
      return;
    }
    else if (!this.listUserSelectStruct || this.listUserSelectStruct.length == 0) {
      this.alertService.show('Erro', "Não existe usuário para ser auditado!", AlertType.error);
      this.isLoading = false;
      return;
    }

    this.isLoading = true;
    this.getSampleRequest = new GetSampleRequest();
    this.postClassificationRiskAuditRequest = new PostClassificationRiskAuditRequest();
    this.postClassificationRiskAuditRequest.listUserTriageStruct = [];
    this.getSampleRequest.listSampleUserAmount = [];

    this.listUserSelectStruct.forEach(x => {
      if (x.formGroup.invalid && x.formGroup.get('isAudited').value) {
        this.alertService.show('Erro', "Preencha corretamente os dados da auditoria do usuário", AlertType.error);
        this.isLoading = false;
        return;
      }
      
      if (x.formGroup.get('isAudited').value) {
        let triageSampleUserAmountStruct: TriageSampleUserAmountStruct = new TriageSampleUserAmountStruct();
        triageSampleUserAmountStruct.idUser = x.idUser;
        triageSampleUserAmountStruct.allSize = x.classificationAmount;
        triageSampleUserAmountStruct.sampleSize = x.formGroup.get('sampleAmount').value;
        this.getSampleRequest.listSampleUserAmount.push(triageSampleUserAmountStruct);
      }
    });

    if (!this.getSampleRequest.listSampleUserAmount || this.getSampleRequest.listSampleUserAmount.length == 0) {
      this.isLoading = false;
      this.alertService.show('Erro', "Nenhum usuário selecionado para ser auditado.", AlertType.error);
      return;
    }

    if(!this.isLoading)
      return;

    let year = this.model.get('yearMonth').value.getFullYear();
    let month = this.model.get('yearMonth').value.getMonth();

    this.getSampleRequest.beginPeriod = new Date(Date.UTC(year, month, this.model.get('initialDate').value, 0, 0, 1));
    this.getSampleRequest.endPeriod = new Date(Date.UTC(year, month, this.model.get('finalDate').value, 23, 59, 59));

    this.getSampleRequest.idProtocol = this.model.get('idProtocol').value;
    
    this.createAudit(this.getSampleRequest);
  }

  createAudit(getSampleRequest) {
    this.createAuditService.createTriageAudit(getSampleRequest).subscribe({next:(response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;
      this.router.navigate(['/audit/audit']);
    },
    error:(error) => {
      console.log(error);
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    }});
  }

  getAllUserEvenDeletedAndTriageAudited() {
    if (this.model.invalid || this.isLoading){
      return; 
    }

    this.isLoading = true;

    let year = this.model.get('yearMonth').value.getFullYear();
    let month = this.model.get('yearMonth').value.getMonth();

    let flowchartRequest = new SearchFlowchartRequest();
    flowchartRequest.idProtocol = this.model.get('idProtocol').value;
    flowchartRequest.beginPeriod = new Date(Date.UTC(year, month, this.model.get('initialDate').value, 0, 0, 1));
    flowchartRequest.endPeriod = new Date(Date.UTC(year, month, this.model.get('finalDate').value, 23, 59, 59));

    this.getAuditService.getAllUserEvenDeletedAndTriageAudited(flowchartRequest).subscribe({next:(response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      response.listAuditUserSelectStruct.forEach(element => {
        element.formGroup = this.formBuilder.group({
          sampleAmount: ['', [Validators.required, Validators.min(element.classificationAmount >= 10 ? 10 : element.classificationAmount), Validators.max(element.classificationAmount)]],
          isAudited: ['', [Validators.required]],
          isFixedSampleSize: ['']
        });
      });

      this.listUserSelectStruct = response.listAuditUserSelectStruct;

      if (this.listUserSelectStruct && this.listUserSelectStruct.length > 0) {
        this.showSave = true;
      }
      else{
        this.listUserSelectStruct = [];
        this.showSave = false;
      }

      this.isLoading = false;
    },
    error:(error) => {
      console.log(error)
      this.alertService.show('Erro inesperado', error, AlertType.error);
      this.isLoading = false;
    }});
  }

  clearFinalDayValue(initialDay: number) {
    if (this.model.get('finalDate').value <= initialDay) {
      this.model.get('finalDate').setValue(null);
      this.model.get('finalDate').markAsUntouched();
      this.model.get('finalDate').markAsPristine();
    }
  }

  populateProtocolSelect() {
    this.protocolService.listProtocol().subscribe({next:(response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.listProtocol = response.listProtocol;
    },
    error:(error) => {
      console.log(error)
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    }});
  }

  populateDateSelect() {
    this.listDate = [];
    let todayDate = new Date();
    let year = todayDate.getFullYear();
    let month = todayDate.getMonth();
    for (let _i = 0; _i < 60; _i++) {
      this.listDate.push(new Date(year, month - _i));
    }
  }

  setDays(yearMonth: Date) {
    this.model.get('finalDate').setValue(null);
    this.model.get('finalDate').markAsUntouched();
    this.model.get('finalDate').markAsPristine();
    this.model.get('initialDate').setValue(null);
    this.model.get('initialDate').markAsUntouched();
    this.model.get('initialDate').markAsPristine();
    this.listDays = [];
    let month: number = new Date(yearMonth).getMonth();
    let year: number = new Date(yearMonth).getFullYear();
    let longMonth: number[] = [0, 2, 4, 6, 7, 9, 11];
    let shortMonth: number[] = [3, 5, 8, 10];

    if (longMonth.indexOf(month) > -1) {
      this.listDays = Array(31).fill(0).map((x, i) => i + 1);
    }
    else if (shortMonth.indexOf(month) > -1) {
      this.listDays = Array(30).fill(0).map((x, i) => i + 1);
    }
    else {
      this.listDays = ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0) ? Array(29).fill(0).map((x, i) => i + 1) : Array(28).fill(0).map((x, i) => i + 1);
    }
    this.isMonthSelected = true;
  }

  getConfig() {
    this.auditConfigService.getAuditConfig().subscribe({
      next:(response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        if(response.auditConfig) {
          this.allowAudited = response.auditConfig.allowAudited;
        }
        
        this.isLoading = false;
      },
      error:(error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}