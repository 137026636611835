export class AttendanceSadtGroupStruct{
    public idSadtGroup: number;

    public observation: string;
    public sadtResult: string;

    public listSadtFile: SadtFile[];

    public datetimeStartAttendance: Date;

    public fileLinkedLater: boolean;
}

export class SadtFile {
    public sadtFile64: string;
    public fileExtension: string;
}