export enum ColumnsProfessionalProductionAttendanceEnum {
    Coordenadoria = 1,
    Supervisao = 2,
    CNESEstabelecimento = 3,
    Estabelecimento = 4,
    CompetenciaBPA = 5,
    CNSProfissional = 6,
    NomeProfissional = 7,
    CID = 8,
    Codigo = 9,
    Procedimento = 10,
    CodigoCBO = 11,
    CBO= 12,
    Quantidade=13
}