import { TimeStruct } from "./time.struct";

export class TimeSpanStruct {

    constructor(start: TimeStruct, end: TimeStruct) {
        this.startTime = start;
        this.endTime = end;
    }

    public startTime: TimeStruct;
    public endTime: TimeStruct;

    intersects(interval: TimeSpanStruct): boolean {
        let comparisonDateStart = new Date();
        let comparisonDateEnd = new Date();

        let thisDateStart = new Date();
        let thisDateEnd = new Date();

        comparisonDateStart.setHours(interval.startTime.hours);
        comparisonDateStart.setMinutes(interval.startTime.minutes);
        comparisonDateStart.setSeconds(0);

        comparisonDateEnd.setHours(interval.endTime.hours);
        comparisonDateEnd.setMinutes(interval.endTime.minutes);
        comparisonDateEnd.setSeconds(0);

        thisDateStart.setHours(this.startTime.hours);
        thisDateStart.setMinutes(this.startTime.minutes);
        thisDateStart.setSeconds(0);

        thisDateEnd.setHours(this.endTime.hours);
        thisDateEnd.setMinutes(this.endTime.minutes);
        thisDateEnd.setSeconds(0);

        if (comparisonDateStart.getTime() >= thisDateStart.getTime() && comparisonDateStart.getTime() < thisDateEnd.getTime()) {
            return true;
        }

        else if (comparisonDateEnd.getTime() > thisDateStart.getTime() && comparisonDateEnd.getTime() <= thisDateEnd.getTime()) {
            return true;
        }

        else if (comparisonDateStart.getTime() < thisDateStart.getTime() && comparisonDateEnd.getTime() > thisDateEnd.getTime()) {
            return true;
        }

        return false;
    }
}