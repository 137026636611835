import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { RemoteMonitoringPatientListComponent } from './pages/remote-monitoring-patient-list/remote-monitoring-patient-list.component';
import { RemoteMonitoringPatientComponent } from './pages/remote-monitoring-patient/remote-monitoring-patient.component';
import { RemoteMonitoringComponent } from './pages/remote-monitoring/remote-monitoring.component';
import { SyncMeasurerListComponent } from './pages/sync-measurer-list/sync-measurer-list.component';
import { SyncMeasurerRegisterComponent } from './pages/sync-measurer-register/sync-measurer-register.component';

const routes: Routes = [
  { path: 'remote-monitoring', component: RemoteMonitoringComponent, canActivate: [AuthGuard] },
  { path: 'remote-monitoring/sync-measurer', component: SyncMeasurerListComponent, canActivate: [AuthGuard] },
  { path: 'remote-monitoring/sync-measurer/register', component: SyncMeasurerRegisterComponent, canActivate: [AuthGuard] },
  { path: 'remote-monitoring/patient', component: RemoteMonitoringPatientComponent, canActivate: [AuthGuard] },
  { path: 'remote-monitoring/list', component: RemoteMonitoringPatientListComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RemoteMonitoringRoutingModule { }
