import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AlertModalComponent } from 'src/app/shared/components/alert-modal/alert-modal.component';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertPolicyService } from 'src/app/shared/services/API/alert-policy/alert-policy.service';
import { ServiceService } from 'src/app/shared/services/API/flow/service.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { AlertPolicy } from 'src/app/shared/services/models/alert-policy/alert-policy.model';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-alert-policy-list',
  templateUrl: './alert-policy-list.component.html',
  styleUrls: ['./alert-policy-list.component.css']
})
export class AlertPolicyListComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private alertPolicyService: AlertPolicyService,
    private serviceService: ServiceService,
    private alertService: AlertService,
    private utilService: UtilService,
    public dialog: MatDialog) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.master;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.master_alert_policy;

  public listAlertPolicy: AlertPolicy[] = [];
  public listAlertPolicyDisplay: AlertPolicy[] = [];
  public serviceMap = new Map();
  public isLoading: boolean = true;
  public isActive: boolean;
  public listIdService: number[];
  public model: FormGroup;
  ip: string = '192.168.0.2';

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      searchText: [''],
      listIdService: [[]],
    });

    this.listServices();
    
    setTimeout(() => { this.getIP(); }, 2000);
  }

  getIP() {
    this.utilService.getIP().subscribe({
      next: (response) => {
        if (response && response.ip) {
          this.ip = response.ip;
        }
      }
    });
  }

  search() {
    this.alertPolicyService.ListAlertPolicy().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listAlertPolicy = response.listPolicy;
        this.listAlertPolicyDisplay = this.listAlertPolicy;

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  listServices() {
    this.serviceService.listAllService().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        response.listService.forEach(x => {
          this.serviceMap.set(x.idService, x);
        });

        this.search();
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  private timeoutKeySearch: any = null;
  keyWordFilter(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        let services = new Set();
        let keyword = $this.model.get('searchText').value.toLowerCase();
        for (let service of $this.serviceMap.values()) {
          if (service.serviceName.toLowerCase().includes(keyword))
            services.add(service.idService);
        }
        $this.listAlertPolicyDisplay = $this.listAlertPolicy.filter(x => services.has(x.idService));
      }
    }, 1000);
  }

  serviceFilter() {
    let services = new Set(this.model.get('listIdService').value);
    if (services.size > 0)
      this.listAlertPolicyDisplay = this.listAlertPolicy.filter(x => services.has(x.idService));
    else
      this.listAlertPolicyDisplay = this.listAlertPolicy;
  }

  clearServices() {
    this.model.get('listIdService').setValue([]);
    this.listAlertPolicyDisplay = this.listAlertPolicy;
  }

  openDeleteModal(idAlertPolicy: number) {
    const dialogRef = this.dialog.open(AlertModalComponent, {
      data: {
        isTwoButtonsModal: true,
        title: 'Atenção',
        description: 'Você realmente deseja excluir esta política de alertas? Ela perderá todas as configurações realizadas e essa opção é irreversível.'
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.confirm)
          this.deletePolicy(idAlertPolicy);

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  deletePolicy(idAlertPolicy: number) {
    this.isLoading = true;
    this.alertPolicyService.DeleteAlertPolicy(idAlertPolicy, this.ip).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', 'Configuração removida com sucesso', AlertType.success);
        this.search();
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }
}
