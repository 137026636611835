import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SelectedLocationRequest } from 'src/app/shared/services/requests/orchestrator-queue/selected-location.request';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ReportService } from 'src/app/shared/services/API/orchestrator-patient/report.service';
import { ForwardPatientConfirmModalComponent } from './forward-patient-confirm-modal/forward-patient-confirm-modal.component';

@Component({
  selector: 'app-forward-patient-modal',
  templateUrl: './forward-patient-modal.component.html',
  styleUrls: ['./forward-patient-modal.component.css']
})
export class ForwardPatientModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ForwardPatientModalComponent>,
    private alertService: AlertService,
    public reportService: ReportService,
    public router: Router) { }

  public isLoading: boolean;
  public model: UntypedFormGroup;
  public selectedLocationRequest: SelectedLocationRequest;
  public fowardPatient: boolean = false;
  public isFlowSequence: boolean = false;
  public searchText: string;

  public filteredListforward: any[] = [];
  public listForward: any[] = [];

  ngOnInit(): void {
    this.isLoading = false;
    this.isFlowSequence = this.data.isFlowSequence;

    if (this.isFlowSequence && this.data.flowSequence.length > 1) {
      var destinyRoomName = this.data.flowSequence[1].destinyRoomName ? this.data.flowSequence[1].destinyRoomName : "";
      var destinySectorName = this.data.flowSequence[1].destinySectorName ? this.data.flowSequence[1].destinySectorName + " " : "";
      var destinyServiceName = this.data.flowSequence[1].destinyServiceName ? this.data.flowSequence[1].destinyServiceName : " ";
      this.listForward = [{ idFlow: this.data.flowSequence[1].idFlow, locationName: destinyServiceName + destinySectorName + destinyRoomName }];
      this.filteredListforward = [...this.listForward]
    }
    else if (this.data.listFoward) {
      this.listForward = this.data.listFoward;
      this.filteredListforward = [...this.data.listFoward];
    }
  }

  onKeySearch() {
    if (this.isLoading) return;

    setTimeout(() => {
      if (this.searchText) {
        this.filteredListforward = [...this.listForward.filter((item: any) => {
          return item.locationName.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1;
        })];
      }
      else
        this.filteredListforward = [...this.listForward];
    });
  }

  selectItem(idFlow: number) {
    if (this.isLoading) return;

    this.isLoading = true;

    this.selectedLocationRequest = new SelectedLocationRequest();

    this.selectedLocationRequest.idEpisode = this.data.idEpisode;
    this.selectedLocationRequest.idQueue = this.data.idQueue;
    this.selectedLocationRequest.idFlow = idFlow;
    this.selectedLocationRequest.idRoomOrigin = this.data.actualIdRoom;

    if (this.isFlowSequence)
      this.selectedLocationRequest.idFlowCompleted = this.data.flowSequence[0].idFlow;

    let roomName: string;
    if (this.data.listFoward && this.data.listFoward.length > 0)
      roomName = this.data.listFoward.find(c => c.idFlow == this.selectedLocationRequest.idFlow).locationName;
    else
      roomName = "";

    const dialogRef = this.dialog.open(ForwardPatientConfirmModalComponent, {
      disableClose: true,
      data: {
        idHealthUnit: this.data.idHealthUnit,
        selectedLocationRequest: this.selectedLocationRequest,
        idEpisode: this.data.idEpisode,
        idRoom: this.data.actualIdRoom,
        datetimeStartRegister: this.data.datetimeStartRegister,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.fowardPatient) {
        this.fowardPatient = true;
        this.isLoading = true;
        this.matDialogRef.close({ fowardPatient: true, roomName: roomName, idQueue: result.idQueue });
      }
      else this.isLoading = false;
    });
  }
}