<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <a class="back-link" routerLink="/master/classification">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Classificação
        </a>
        <h1>Impressão</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="row">
                    <div class="col-12 col-sm-4 col-md-3">
                        <mat-slide-toggle class="wristband-checkbox" [checked]="isWristbandActive"
                            (change)="toggleWristband($event)">Pulseira
                        </mat-slide-toggle>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 col-sm-4 col-md-2" *ngIf="isWristbandActive">
                        <mat-form-field appearance="outline">
                            <mat-label>Dimensão</mat-label>
                            <mat-select formControlName="idWristbandDimensions"
                                (selectionChange)="changeDimensionsSelect($event)">
                                <mat-option [value]="dimensions.idWristbandDimensions"
                                    *ngFor="let dimensions of listWristbandDimensions">
                                    {{dimensions.wristbandDimensionsDescription}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-4 col-sm-4 col-md-2" *ngIf="isWristbandActive">
                        <mat-form-field appearance="outline">
                            <mat-label>Largura</mat-label>
                            <input matInput type="number" formControlName="customWristbandWidth">
                            <mat-error *ngIf="model.get('customWristbandWidth').value < 70">O valor mínimo é 70mm</mat-error>
                            <mat-error *ngIf="model.get('customWristbandWidth').invalid && model.get('customWristbandWidth').value >= 70">Informe a Largura</mat-error>
                            <span matSuffix>mm</span>
                        </mat-form-field>
                    </div>
                    <div class="col-4 col-sm-4 col-md-2" *ngIf="isWristbandActive">
                        <mat-form-field appearance="outline">
                            <mat-label>Altura</mat-label>
                            <input matInput type="number" formControlName="customWristbandHeight">
                            <mat-error *ngIf="model.get('customWristbandHeight').value < 20">O valor mínimo é 20mm</mat-error>
                            <mat-error *ngIf="model.get('customWristbandHeight').invalid && model.get('customWristbandHeight').value >= 20">Informe a Altura</mat-error>
                            <span matSuffix>mm</span>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngIf="isWristbandActive">
                        <mat-form-field appearance="outline">
                            <mat-label>Identificação Padrão</mat-label>
                            <mat-select formControlName="standardIdentification">
                                <mat-option [value]="item.idIdentification" *ngFor="let item of listIdentification">
                                    {{item.identificationName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-4 col-md-2" *ngIf="isWristbandActive">
                        <button mat-flat-button type="button" color="primary" class=" visualize-btn" (click)="visualizeTemplate()">
                            <span *ngIf="isVisualizeLoading == false">Visualizar Etiqueta</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isVisualizeLoading == true">
                            </mat-spinner>
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-2" *ngIf="isWristbandActive">
                        <button mat-flat-button color="primary" class=" btn-block" (click)="othersAlterDimensions()">
                            <span *ngIf="!isLoading">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    
</div>