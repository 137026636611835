import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { FormPatientRequest } from '../../requests/medical-record/form-patient.request';
import { FormGetPatientReponse } from '../../responses/medical-record/form-get-patient.response';
import { FormPatientResponse } from '../../responses/medical-record/form.patient.response';

@Injectable({
  providedIn: 'root'
})
export class FormPatientService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public savePatient(body: FormPatientRequest): Observable<FormPatientResponse>{
    let uri = `FormPatient`

    return this.httpClient.post<FormPatientResponse>(environment.urlApiMedicalRecord + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public getPatient(idPatient: number): Observable<FormGetPatientReponse>{
    let uri = `FormPatient/idPatient/${idPatient}`

    return this.httpClient.get<FormGetPatientReponse>(environment.urlApiMedicalRecord + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
