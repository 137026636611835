<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-exam-modal-list">
            <h2>Código de barras</h2>
         </div>
         
         <div class="body-exam-modal-list">    
            <div class="row">
                <div class="col-12"> 
                    <mat-form-field appearance="outline">
                        <mat-label>Codigo de barra</mat-label>
                        <input autofocus matInput type="text" id="idEpisode" [(ngModel)]="idEpisode" (keyup)="onKeySearch($event)" (ngModelChange)="onKeySearch($event)">
                    </mat-form-field>
                </div>
            </div>
         </div>
         <div class="footer-exam-modal-list">
            <button mat-flat-button color="primary" class="btn-block" (click)="clickConfirm()" [disabled]='!isValid'>
                <span *ngIf="isLoading == false">Confirmar</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
            <button mat-flat-button color="accent" class="btn-block"(click)="clickCancel()">Cancelar</button>
        </div>
    </div>
</div>