<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-type-modal-list">
            <h2>Informações de acompanhamento</h2>
         </div>
         <div class="body-type-modal-list">
            <form class="form" [formGroup]="model">
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Linhas de cuidado</mat-label>
                            <mat-select formControlName="listIdCareLine" multiple>
                                <mat-option *ngFor="let item of this.data.listCareLine" value="{{item.idCareLine}}">
                                    {{item.careLineName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Doenças</mat-label>
                            <mat-select formControlName="listIdComorbidity" multiple>
                                <mat-option *ngFor="let item of this.data.listComorbidity" value="{{item.idComorbidity}}">
                                    {{item.comorbidityName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12  col-sm-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Outras doenças</mat-label>
                            <textarea matInput formControlName="otherComorbidity"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Medicação</mat-label>
                            <mat-select formControlName="listIdMedicine" multiple>
                                <mat-option value="{{this.data.listMedication.idMedicineOutros}}">OUTROS</mat-option>
                                <mat-option *ngFor="let item of this.data.listMedication" value="{{item.medicine.idMedicine}}">
                                    {{item.medicine.medicineName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12  col-sm-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Outras Medicações</mat-label>
                            <textarea matInput formControlName="otherMedicine"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </div>
        <div class="footer-modal-list">
            <button mat-flat-button color="accent" class="btn-block"(click)="close()">Fechar</button>
        </div>
    </div>
</div>