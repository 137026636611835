import { OnInit, Inject, Component } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ReportModalComponent } from "src/app/shared/components/report-modal/report-modal.component";
import { AlertService, AlertType } from "src/app/shared/services/alert.service";
import { BedResumeReportService } from "src/app/shared/services/API/orchestrator-patient/bed-resume-report.service";
import { BedResumeReportRequest } from "src/app/shared/services/requests/Report/bed-resume-report-request.request";

@Component({
  selector: 'app-hygienization-confirm-modal',
  templateUrl: './hygienization-confirm-modal.component.html',
  styleUrls: ['./hygienization-confirm-modal.component.css']
})

export class HygienizationConfirmModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<HygienizationConfirmModalComponent>,
    private bedResumeReportService: BedResumeReportService,
    private alertService: AlertService,
  ) {
    this.matDialogRef.disableClose = true;
  }

  public model: UntypedFormGroup;
  public isLoading: boolean;
  public hygienization: boolean;

  ngOnInit(): void {
    this.isLoading = false;
    this.hygienization = this.data.hygienization;
    this.model = this.formBuilder.group({
      printDocument: [false],
      socialName: [''],
    });
  }

  submit() {
    if (this.isLoading)
      return;

    this.isLoading = true;

    if (this.model.get('printDocument').value == true) {
      this.hygienization = this.data.hygienization;
      this.reportDeleteHygienization();
    }
    else
      this.matDialogRef.close({ printDocument: this.model.get('printDocument').value, confirm: true });
  }
  
  reportDeleteHygienization() {
    let bedResumeReportRequest: BedResumeReportRequest = new BedResumeReportRequest();
    bedResumeReportRequest.listUnhygienizedBed = this.data.listUnhygienizedBed;
    bedResumeReportRequest.listHygienizedBed = this.data.listHygienizedBed;
    bedResumeReportRequest.listHygienizingBed = this.data.listHygienizingBed;
    bedResumeReportRequest.listBedInUse = this.data.listBedInUse;

    let token = JSON.parse(sessionStorage.getItem("token"));
    bedResumeReportRequest.healthUnitName = token.healthUnit.healthUnitName;

    this.bedResumeReportService.getBedResumeReport(bedResumeReportRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (response.reportName && response.reportPdf64) {
          let reportDialog = this.dialog.open(ReportModalComponent, {
            data: {
              reportName: response.reportName,
              reportPdf64: response.reportPdf64,
            },
            disableClose: true,
          });
          this.isLoading = false;
          reportDialog.afterClosed().subscribe({
            next: result => {
              this.matDialogRef.close({
                printDocument: this.model.get('printDocument').value,
                confirm: true,
              });
            }
          });
        }
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  clickCancel() {
    this.matDialogRef.close({
      printDocument: this.model.get('printDocument').value,
      confirm: false,
    });
  }
}