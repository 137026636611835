import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdministrationTypeEnum, DilutionEnum, FrequencyEnum, PresentationEnum, TreatmentPeriodEnum, UsageEnum } from 'src/app/shared/enum/prescription.enum';
import { MedicRequiredFieldEnum } from 'src/app/shared/enum/medic-required-field.enum';
import { MedicineAdministrationTypeEnum } from 'src/app/shared/enum/medicine-administration-type.enum';
import { MedicineStruct } from 'src/app/shared/services/structs/pharmacy/medicine.struct';
import { AllergyModel } from 'src/app/shared/services/models/medical-record/allergy.model';
import { MedicatedSolutionStruct } from 'src/app/shared/services/structs/medical-record/medicated-solution.struct';
import { MedicineToPrescriptionStruct } from 'src/app/shared/services/structs/medical-record/medicine-to-prescription.struct';
import { ConfirmAllergicPatientModalComponent } from '../../confirm-allergic-patient-modal/confirm-allergic-patient-modal.component';
import { MedicineModel } from 'src/app/shared/services/models/pharmacy/medicine.model';

@Component({
  selector: 'app-medicated-solution-form',
  templateUrl: './medicated-solution-form.component.html',
  styleUrls: ['./medicated-solution-form.component.css']
})
export class MedicatedSolutionFormComponent implements OnInit {
  constructor(public dialog: MatDialog,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
  ) { }

  @Input() blockAllergicPrescription: boolean;
  @Input() isFastTrack: boolean;
  @Input() readOnly: boolean;
  @Input() medicatedSolutionStruct: MedicatedSolutionStruct;
  @Input() listAllergy: AllergyModel[] = [];
  @Input() selectedMedicineList: MedicineStruct[] = [];
  @Input() medicineList: MedicineStruct[] = [];
  @Input() listSubFrequency: any[];
  @Input() frequencyList: any[] = [];
  @Input() presentationList: any[] = [];
  @Input() treatmentList: any[] = [];
  @Input() dilutionList: any[] = [];
  @Input() measurementUnitList: any[] = [];
  @Input() usageList: any[] = [];
  @Input() administrationTypeList: any[] = [];
  @Input() lookupMedicalCareResponse: any;
  @Input() medicConfig: any;
  @Input() listMedicine: any;
  @Input() dilutionUnitList: any[] = [];
  @Input() hasNewStandardPrescription: boolean = false;

  @Output() save = new EventEmitter<any>();
  @Output() cancel = new EventEmitter<any>();

  public isLoading: boolean;
  public bypassPrescription: boolean = false;
  public isFrequency: boolean = false;
  public isMedicatedSolution: boolean = false;
  public undiluted: boolean = false;

  public idAdministrationType: number;
  public idFrequency: number
  public idTreatmentPeriod: number;
  public idPresentation: number;
  public idDilution: number;

  public justification: string;

  public frequencyNow: any;

  public administrationTypeListDisplay: any[] = [];

  public administrationTypeOthersEnum: AdministrationTypeEnum = AdministrationTypeEnum.outros;
  public presentationOthersEnum: PresentationEnum = PresentationEnum.outros;
  public dilutionOthersEnum: DilutionEnum = DilutionEnum.outros;
  public frequencyEnum: FrequencyEnum = FrequencyEnum.now;
  public ifNecessary: FrequencyEnum = FrequencyEnum.ifNecessary;
  public dilutionNoDilutionEnum: DilutionEnum = DilutionEnum.semDiluicao;

  public prescriptionModel: FormGroup;
  public selectedMedicine: MedicineStruct;

  public listIdSelectedMedicine: MedicineModel[] = [];
  selectedMedicineLists: any[] = [];
  
  ngOnInit(): void {
    this.isLoading = false;
    this.populateLookup();


    this.prescriptionModel = this.formBuilder.group({
      listMedicine: this.formBuilder.array([]),
      idAdministrationType: [{ value: null, disabled: this.readOnly }],
      idFrequency: [{ value: '', disabled: this.readOnly }, Validators.required],
      idPresentation: [{ value: null, disabled: this.readOnly }],
      idDilution: [{ value: null, disabled: this.readOnly }],
      idTreatmentPeriod: [{ value: '', disabled: this.readOnly }, Validators.required],
      presentation: [{ value: '', disabled: this.readOnly }],
      dilution: [{ value: '', disabled: this.readOnly }],
      administrationType: [{ value: '', disabled: this.readOnly }],
      observation: [{ value: '', disabled: this.readOnly }],
      idSubFrequency: [{ value: '', disabled: this.readOnly }],
      idDilutionUnit: [{ value: '', disabled: this.readOnly }],
      dilutionQuantity: [{ value: '', disabled: this.readOnly }],
    });

    if (this.medicatedSolutionStruct) {
      // this.prescriptionModel.get('idMedicine').disable();
      this.populateValues();
    }
    else {
      this.addNextMedicineInput();
      this.addNextMedicineInput();
    }

    this.verifyRequiredFields();
  }

  populateLookup() {
    if (this.frequencyList) 
      this.organizeList()
  }

  populateValues() {
    this.medicatedSolutionStruct.listMedicine.forEach((x, index) => {
      (this.prescriptionModel.controls['listMedicine'] as FormArray).push(
        this.formBuilder.group({
          idMedicine: [{ value: x.idMedicine.toString(), disabled: this.readOnly }, Validators.required],
          quantity: [{ value: x.quantity, disabled: this.readOnly }],
          idMeasurementUnit: [{ value: x.idMeasurementUnit, disabled: this.readOnly }],
        })
      );
      // (this.prescriptionModel.controls['listMedicine'] as FormArray).at(index).get('idMedicine').disable();
    });

    const arrayLength = (this.prescriptionModel.controls['listMedicine'] as FormArray).length;
    for (let i = 0; i < arrayLength; i++) {
        this.selectedMedicineLists[i] = this.selectedMedicineList;
    }

    let naoDefinida = this.administrationTypeList.find(f => f.idAdministrationType == MedicineAdministrationTypeEnum.NaoDefinida);
    this.prescriptionModel.get('idAdministrationType').setValue(this.medicatedSolutionStruct.idAdministrationType ? this.medicatedSolutionStruct.idAdministrationType : null);
    this.prescriptionModel.get('idFrequency').setValue(this.medicatedSolutionStruct.idFrequency ? this.medicatedSolutionStruct.idFrequency.toString() : null);
    this.prescriptionModel.get('idPresentation').setValue(this.medicatedSolutionStruct.idPresentation ? this.medicatedSolutionStruct.idPresentation.toString() : null);
    this.prescriptionModel.get('idTreatmentPeriod').setValue(this.medicatedSolutionStruct.idTreatmentPeriod ? this.medicatedSolutionStruct.idTreatmentPeriod.toString() : null);
    this.prescriptionModel.get('observation').setValue(this.medicatedSolutionStruct.observation);
    this.prescriptionModel.get('idDilution').setValue(this.medicatedSolutionStruct.idDilution ? this.medicatedSolutionStruct.idDilution.toString() : null);
    this.prescriptionModel.get('dilution').setValue(this.medicatedSolutionStruct.dilution);
    this.prescriptionModel.get('presentation').setValue(this.medicatedSolutionStruct.presentation);
    this.prescriptionModel.get('administrationType').setValue(this.medicatedSolutionStruct.administrationType);
    if (this.medicatedSolutionStruct.idSubFrequency != null) {
      this.isFrequency = true;
      this.prescriptionModel.get('idSubFrequency').setValue(this.medicatedSolutionStruct.idSubFrequency.toString());
    }

    if (this.medicatedSolutionStruct.idDilutionUnit != null) {
      this.undiluted = true;
      this.prescriptionModel.get('idDilutionUnit').setValue(this.medicatedSolutionStruct.idDilutionUnit);
      this.prescriptionModel.get('dilutionQuantity').setValue(this.medicatedSolutionStruct.dilutionQuantity);
    }

    if (this.readOnly) {
      this.idAdministrationType = this.medicatedSolutionStruct.idAdministrationType;
      this.idFrequency = this.medicatedSolutionStruct.idFrequency;
      this.idTreatmentPeriod = this.medicatedSolutionStruct.idTreatmentPeriod;
    }

    this.setSelectedMedicines();
  }

  clickCancel() {
    this.cancel.emit();
  }

  onChangeMedicine(event: any, index: number) {
    this.setSelectedMedicines();

    if(!event.value)
      return;

    this.selectedMedicine = this.medicineList.find(f => f.medicine.idMedicine == event.value);

    if (this.selectedMedicine.medicine.idMeasurementUnit) 
      (this.prescriptionModel.controls['listMedicine'] as FormArray).at(index).get('idMeasurementUnit').setValue(this.selectedMedicine.medicine.idMeasurementUnit);
    else 
      (this.prescriptionModel.controls['listMedicine'] as FormArray).at(index).get('idMeasurementUnit').setValue(null);
    
    if (this.selectedMedicine.listAllergy && this.selectedMedicine.listAllergy.length > 0) {
      let allergyDetected: boolean = false;

      allergyDetected = this.selectedMedicine.listAllergy.some((it) => this.listAllergy.find(it2 => it2.idAllergy == it));
      if (allergyDetected) {
        const dialogRef = this.dialog.open(ConfirmAllergicPatientModalComponent, {
          data: {blockAllergicPrescription: this.blockAllergicPrescription},
        });
        dialogRef.afterClosed().subscribe({
          next: (result) => {
            if (result && result.cancel) {
              (this.prescriptionModel.controls['listMedicine'] as FormArray).at(index).get('idMedicine').setValue('');
              (this.prescriptionModel.controls['listMedicine'] as FormArray).at(index).get('quantity').setValue('');
              (this.prescriptionModel.controls['listMedicine'] as FormArray).at(index).get('idMeasurementUnit').setValue('');
              this.prescriptionModel.get('idMedicine').setValue('');
              this.prescriptionModel.get('idAdministrationType').setValue('');
              this.prescriptionModel.get('idAdministrationType').enable();
              this.prescriptionModel.get('idDilution').setValue('');
              this.prescriptionModel.get('idPresentation').setValue(null);
            } 
            else if (result && !result.cancel && result.justification) 
              this.justification = result.justification;
          }
        });
      }
    }
  }

  onChangePresentation(event: number) {
    this.idPresentation = event;
    if (this.isMedicatedSolution) {
      if (this.idPresentation == this.presentationOthersEnum.valueOf()) 
        this.prescriptionModel.get('presentation').setValidators(Validators.required);
       else {
        this.prescriptionModel.get('presentation').setValue(null);
        this.prescriptionModel.get('presentation').setValidators(Validators.nullValidator);
      }
      this.prescriptionModel.get('presentation').updateValueAndValidity();
    }
    else {
      if (this.idPresentation == this.presentationOthersEnum.valueOf()) 
        this.prescriptionModel.get('presentation').setValidators(Validators.required);
      else {
        this.prescriptionModel.get('presentation').setValue(null);
        this.prescriptionModel.get('presentation').setValidators(Validators.nullValidator);
      }
      this.prescriptionModel.get('presentation').updateValueAndValidity();
    }
  }

  onChangeAdministrationType(event: number) {
    this.idAdministrationType = event;
    if (this.isMedicatedSolution) {
      if (this.idAdministrationType == this.administrationTypeOthersEnum.valueOf()) {
        this.prescriptionModel.get('administrationType').setValue(this.selectedMedicine.medicine.medicineAdministrationOther)
        this.prescriptionModel.get('administrationType').setValidators(Validators.required);
      }
       else {
        this.prescriptionModel.get('administrationType').setValue(null);
        this.prescriptionModel.get('administrationType').setValidators(Validators.nullValidator);
      }
      this.prescriptionModel.get('administrationType').updateValueAndValidity();
    }
    else {
      if (this.idAdministrationType == this.administrationTypeOthersEnum.valueOf()) {
        this.prescriptionModel.get('administrationType').setValue(this.selectedMedicine.medicine.medicineAdministrationOther)
        this.prescriptionModel.get('administrationType').setValidators(Validators.required);
      }
       else {
        this.prescriptionModel.get('administrationType').setValue(null);
        this.prescriptionModel.get('administrationType').setValidators(Validators.nullValidator);
      }
      this.prescriptionModel.get('administrationType').updateValueAndValidity();
    }
  }

  onChangeDilution(event: number) {
    this.idDilution = event;

    if (this.idDilution == this.dilutionNoDilutionEnum.valueOf() || this.idDilution == null) 
      this.undiluted = false;
    else 
      this.undiluted = true;
    
    if (this.idDilution == this.dilutionOthersEnum.valueOf()) 
      this.prescriptionModel.get('dilution').setValidators(Validators.required);
    else {
      this.prescriptionModel.get('dilution').setValue(null);
      this.prescriptionModel.get('dilution').setValidators(Validators.nullValidator);
    }

    this.prescriptionModel.get('dilution').updateValueAndValidity();
  }

  onKey(value: string, index: number) {
    this.selectedMedicineLists[index] = this.search(value);
}

  search(value: string) {
    let filter = value.toLowerCase();
    return this.medicineList.filter(option => option.medicine.medicineDescription.toLowerCase().includes(filter));
  }

  onSubmit() {
    if (this.prescriptionModel.invalid) {
      this.alertService.show('Erro', "Preencha todos os campos obrigatórios!", AlertType.error);
      return;
    }

    this.medicatedSolutionStruct = new MedicatedSolutionStruct();
    this.prescriptionModel.get('listMedicine').value.forEach(x => {

      var medicine = new MedicineToPrescriptionStruct();
      medicine.idMedicine = x.idMedicine;
      medicine.quantity = x.quantity ? x.quantity : null;
      medicine.idMeasurementUnit = x.idMeasurementUnit ? x.idMeasurementUnit : null;
      medicine.medicineName = this.medicineList.find(c => c.medicine.idMedicine == medicine.idMedicine).medicine.medicineDescription;

      this.medicatedSolutionStruct.listMedicine.push(medicine);
    });

    this.medicatedSolutionStruct.idUsage = UsageEnum.interno;
    this.medicatedSolutionStruct.usage = this.usageList.find(c => c.idUsage == this.medicatedSolutionStruct.idUsage).usageName;
    this.medicatedSolutionStruct.idAdministrationType = this.prescriptionModel.get('idAdministrationType').value ? this.prescriptionModel.get('idAdministrationType').value : this.administrationTypeList.find(x => x.idAdministrationType == MedicineAdministrationTypeEnum.NaoDefinida).idAdministrationType;
    this.medicatedSolutionStruct.administrationType = this.prescriptionModel.get('administrationType').value;
    this.medicatedSolutionStruct.administrationTypeName = this.medicatedSolutionStruct.idAdministrationType ? this.administrationTypeList.find(x => x.idAdministrationType == this.medicatedSolutionStruct.idAdministrationType).administrationTypeName : null;
    this.medicatedSolutionStruct.idPresentation = this.prescriptionModel.get('idPresentation').value ? this.prescriptionModel.get('idPresentation').value : null;
    this.medicatedSolutionStruct.presentationName = this.medicatedSolutionStruct.idPresentation ? this.presentationList.find(c => c.idPresentation == this.medicatedSolutionStruct.idPresentation).presentationName : null;
    this.medicatedSolutionStruct.presentation = this.prescriptionModel.get('presentation').value;
    this.medicatedSolutionStruct.idFrequency = this.prescriptionModel.get('idFrequency').value ? this.prescriptionModel.get('idFrequency').value : null;
    this.medicatedSolutionStruct.frequencyName = this.medicatedSolutionStruct.idFrequency ? this.frequencyList.find(c => c.idFrequency == this.medicatedSolutionStruct.idFrequency).frequencyName : null;
    this.medicatedSolutionStruct.idTreatmentPeriod = this.prescriptionModel.get('idTreatmentPeriod').value ? this.prescriptionModel.get('idTreatmentPeriod').value : null;
    this.medicatedSolutionStruct.treatmentPeriodName = this.medicatedSolutionStruct.idTreatmentPeriod ? this.treatmentList.find(c => c.idTreatmentPeriod == this.medicatedSolutionStruct.idTreatmentPeriod).treatmentPeriodName : null;
    this.medicatedSolutionStruct.idDilution = this.prescriptionModel.get('idDilution').value ? this.prescriptionModel.get('idDilution').value : null;
    this.medicatedSolutionStruct.dilutionName = this.dilutionList.find(c => c.idDilution == this.medicatedSolutionStruct.idDilution) ? this.dilutionList.find(c => c.idDilution == this.medicatedSolutionStruct.idDilution).dilutionName : null;
    this.medicatedSolutionStruct.dilution = this.prescriptionModel.get('dilution').value;
    this.medicatedSolutionStruct.observation = this.prescriptionModel.get('observation').value;
    this.medicatedSolutionStruct.idSubFrequency = this.prescriptionModel.get('idSubFrequency').value ? parseInt(this.prescriptionModel.get('idSubFrequency').value) : null;
    this.medicatedSolutionStruct.idDilutionUnit = this.prescriptionModel.get('idDilutionUnit').value ? this.prescriptionModel.get('idDilutionUnit').value : null;
    this.medicatedSolutionStruct.dilutionQuantity = this.prescriptionModel.get('dilutionQuantity').value;
    if (this.medicatedSolutionStruct.idSubFrequency != null) {
      let subFrequencyName = this.lookupMedicalCareResponse.listSubFrequency.find(x => x.idSubFrequency == this.medicatedSolutionStruct.idSubFrequency);
      if (subFrequencyName != null)
        this.medicatedSolutionStruct.subFrequencyName = subFrequencyName.subFrequencyName;
    }

    if (this.medicatedSolutionStruct.idDilutionUnit != null) 
      this.medicatedSolutionStruct.dilutionUnitName = this.lookupMedicalCareResponse.listDilutionUnit.find(x => x.idDilutionUnit == this.medicatedSolutionStruct.idDilutionUnit).dilutionUnitName;
    
    if (this.justification)
      this.medicatedSolutionStruct.justification = this.justification;

    this.save.emit({ medicatedSolutionStruct: this.medicatedSolutionStruct, bypassPrescription: this.bypassPrescription });
  }

  verifyRequiredFields() {
    if (this.medicConfig) {
      this.medicConfig.listIdRequiredField.forEach(x => {

        if (x == MedicRequiredFieldEnum.prescriptionQuantity) {
          this.prescriptionModel.get('quantity').setValidators([Validators.required]);
          this.prescriptionModel.get('quantity').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.prescriptionAdministrationType) {
          this.prescriptionModel.get('idAdministrationType').setValidators([Validators.required]);
          this.prescriptionModel.get('idAdministrationType').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.prescriptionDilution) {
          this.prescriptionModel.get('idDilution').setValidators([Validators.required]);
          this.prescriptionModel.get('idDilution').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.prescriptionPresentation) {
          this.prescriptionModel.get('idPresentation').setValidators([Validators.required]);
          this.prescriptionModel.get('idPresentation').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.prescriptionObservation) {
          this.prescriptionModel.get('observation').setValidators([Validators.required]);
          this.prescriptionModel.get('observation').updateValueAndValidity();
        }
        else if (x == MedicRequiredFieldEnum.prescriptionMeasurementUnit) {
          this.prescriptionModel.get('idMeasurementUnit').setValidators([Validators.required]);
          this.prescriptionModel.get('idMeasurementUnit').updateValueAndValidity();
        }
      });
    }
  }


  toggleBypassPrescription(event: any) {
    if (event.checked) {
      this.bypassPrescription = true;
      this.selectedMedicineList = this.listMedicine.listMedicineStruct;
    }
    else {
      this.bypassPrescription = false;
      this.selectedMedicineList = this.listMedicine.listMedicineStruct.filter(x => x.medicine.fastTrack == true);
      let idMedicine: number = this.prescriptionModel.get('idMedicine').value;
      let medicine: MedicineStruct = this.listMedicine.listMedicineStruct.find(x => x.medicine.idMedicine == idMedicine);
      if (!this.selectedMedicineList.includes(medicine)) 
        this.prescriptionModel.get('idMedicine').setValue(null);
    }
  }

  onChangeFrequency(event: number) {
    if (event == this.ifNecessary.valueOf()) 
      this.isFrequency = true;
    else {
      this.isFrequency = false;
      this.prescriptionModel.get('idSubFrequency').setValue(null);
    }

    if (this.hasNewStandardPrescription) {
      if (event == this.frequencyEnum) {
        this.prescriptionModel.get('idTreatmentPeriod').setValue(TreatmentPeriodEnum.UmDia.toString());
        this.prescriptionModel.get('idTreatmentPeriod').disable();
      }
      else
        this.prescriptionModel.get('idTreatmentPeriod').enable();

      this.prescriptionModel.get('idTreatmentPeriod').updateValueAndValidity();
    }
  }

  organizeList() {
    this.frequencyNow = this.frequencyList.find(x => x.idFrequency == this.frequencyEnum.valueOf());
    this.frequencyList.splice(this.frequencyList.findIndex(x => x.idFrequency == this.frequencyEnum.valueOf()), 1);
    this.frequencyList.unshift(this.frequencyNow);
  }

  createMedicineInput() {
    return this.formBuilder.group({
      idMedicine: [{ value: '', disabled: this.readOnly }, [Validators.required]],
      quantity: [{ value: '', disabled: this.readOnly }],
      idMeasurementUnit: [{ value: null, disabled: this.readOnly }],
    });
  }

  addNextMedicineInput() {
    const index = (this.prescriptionModel.controls['listMedicine'] as FormArray).length;
    (this.prescriptionModel.controls['listMedicine'] as FormArray).push(this.createMedicineInput());
    this.selectedMedicineLists[index] = this.selectedMedicineList;
  }

  removeMedicineInput(index: number) {
    if (index != 0) 
      (this.prescriptionModel.controls['listMedicine'] as FormArray).removeAt(index);
    
    this.setSelectedMedicines();
  }

  setSelectedMedicines() {
    let medicines = this.prescriptionModel.get('listMedicine')['controls'];
    this.listIdSelectedMedicine = medicines.map(x => x.value.idMedicine);
  }

  changeToMedicatedSolution(event: any) {
    this.isMedicatedSolution = !this.isMedicatedSolution;
  }
}