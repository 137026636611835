import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ListModuleGroupStruct } from 'src/app/shared/services/structs/user/list-module-group.struct';
import { ModuleStruct } from 'src/app/shared/services/structs/user/module.struct';
import { ModuleModalComponent } from '../module-modal/module-modal.component';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {

  public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.welcome;
  
  constructor(
    private authService: AuthService,
    public dialog: MatDialog,
    private router: Router,
  ) { }

  public isLoading: boolean = false;
  listModuleGroupStruct: ListModuleGroupStruct[];
  listModuleStruct: ModuleStruct[];
  filteredListModuleStruct: ModuleStruct[];

  ngOnInit(): void {
    this.listModuleGroupStruct = this.authService.getTokenMenu().listModuleGroup;
    this.listModuleStruct = this.authService.getTokenMenu().listModule;
  }

  openModuleModal (moduleGroup: ListModuleGroupStruct) {
    this.filteredListModuleStruct = this.listModuleStruct.filter(x => x.idModuleGroup == moduleGroup.idModuleGroup)
    const dialogRef = this.dialog.open(ModuleModalComponent, {
      data: {
        nameModuleGroup: moduleGroup.nameModuleGroup,
        iconClass: moduleGroup.iconClass,
        filteredListModuleStruct: this.filteredListModuleStruct
      },
      panelClass: "module-option",
    });
  }
  
  haveAccessModule(moduleGroup: ListModuleGroupStruct) : boolean {
    if(this.listModuleStruct.find(x => x.idModuleGroup == moduleGroup.idModuleGroup))
      return false;
    return true;
  }

}
