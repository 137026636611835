<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    
    <div class="container-body">
        <div class="white-body">
            <h1>Notificações de agravo</h1>
            <a mat-flat-button color="primary" class=" add-user" routerLink="/patient-history/grievance-notification-generate"><mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon>Gerar nova notificação</a>
        </div>
    
        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>
    
</div>