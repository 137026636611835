<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

    <div class="container-body">
        <a class="back-link" routerLink="/remote-monitoring/list">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Pacientes
        </a>
        <h1>Visualizar Histórico de Alertas e Medidas</h1>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="white-body">
            <div *ngIf="!onlineOffline || socketDisconneted" class="offline">
                <span>
                    <mat-icon class="offline--icon">wifi_off</mat-icon>
                    {{
                    socketDisconneted?
                    "A função de atualização automática está fora do ar.":
                    "Você esta sem conexão com a internet no momento."
                    }}
                </span>
            </div>
            <div class="title-header">
                <h1>Dados do Paciente</h1>
            </div>
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="row">
                    <div class="col-6 col-sm-2">
                        <mat-form-field appearance="outline" class="outline-spinner">
                            <mat-label>CPF</mat-label>
                            <input matInput type="text" formControlName="cpf" [mask]="masks.cpf">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Patient Name</mat-label>
                            <input matInput type="text" formControlName="patientName">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Data de nascimento</mat-label>
                            <input matInput type="text" formControlName="birthDate" [mask]="masks.date">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-1">
                        <mat-form-field appearance="outline" class="outline-spinner">
                            <mat-label>Idade</mat-label>
                            <input matInput type="text" readonly formControlName="patientAge">
                        </mat-form-field>
                    </div>
                    <div class="col-md-1">
                        <button mat-flat-button color="accent" class="" type="button" (click)="openModal()">
                            <mat-icon aria-hidden="false">add</mat-icon>
                            Dados
                        </button>
                    </div>
                </div>
                <div class="title-header">
                    <h1>Informações de acompanhamento</h1>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Linhas de cuidado</mat-label>
                            <mat-select formControlName="listIdCareLine" multiple>
                                <mat-option *ngFor="let item of listCareLine" value="{{item.idCareLine}}">
                                    {{item.careLineName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <button mat-flat-button color="primary" class="" type="button"
                            (click)="openModalClinicalObservation()">
                            <span *ngIf="!isFirstLoading">Observações Clínica</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isFirstLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <button mat-flat-button color="accent" class="" type="button" (click)="openModalCareLines()">
                            <mat-icon aria-hidden="false">add</mat-icon>
                            Dados
                        </button>
                    </div>
                </div>
                <div *ngIf="hasRedAlert" class="title-header">
                    <h1>Atendimento do Alerta Vermelho</h1>
                </div>
                <div *ngIf="hasRedAlert" class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Observação</mat-label>
                            <textarea matInput formControlName="observation"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="history != null && history.length > 0">
                    <div class="title-header">
                        <h1>Historico de Medições</h1>
                    </div>


                    <cdk-accordion class="profile-register-accordion">
                        <cdk-accordion-item *ngIf="saturationHistoryLenght > 0" #accordionItemModule="cdkAccordionItem"
                            class="profile-register-accordion-item" role="button">
                            <div [ngClass]="{'profile-register-accordion-item-header-expanded': accordionItemModule.expanded}"
                                class="profile-register-accordion-item-header" (click)="accordionItemModule.toggle()">
                                Saturação de oxigênio
                                <span
                                    [ngClass]="{'profile-register-accordion-item-description-expanded': accordionItemModule.expanded}"
                                    class="profile-register-accordion-item-description">
                                    Clique para {{ accordionItemModule.expanded ? 'fechar' : 'abrir' }}
                                </span>
                            </div>
                            <div class="profile-register-accordion-item-body triage-body" role="region"
                                [style.display]="accordionItemModule.expanded ? '' : 'none'">
                                <div class="row">
                                    <div class="col-5 col-md-2 col-sm-3">
                                        <span class="chartButton"
                                            *ngIf="saturationHistoryLenght / numberOfItemsChart > saturationPagination+1"
                                            (click)="previous('saturation')">
                                            <center>
                                                < Anterior</center>
                                        </span>
                                    </div>
                                    <div class="offset-2 col-5 offset-md-10 col-md-2 offset-sm-6 col-sm-3">
                                        <span class="chartButton" *ngIf="saturationPagination > 0"
                                            (click)="next('saturation')">
                                            <center>Próximo ></center>
                                        </span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <canvas baseChart [height]="calcHeight" [datasets]="saturationChartData"
                                            [labels]="saturationLabel" [options]="saturationChartOptions"
                                            [type]="chartType"></canvas>
                                    </div>
                                </div>
                            </div>
                        </cdk-accordion-item>

                        <cdk-accordion-item *ngIf="temperatureHistoryLenght > 0" #accordionItemModule="cdkAccordionItem"
                            class="profile-register-accordion-item" role="button">
                            <div [ngClass]="{'profile-register-accordion-item-header-expanded': accordionItemModule.expanded}"
                                class="profile-register-accordion-item-header" (click)="accordionItemModule.toggle()">
                                Temperatura
                                <span
                                    [ngClass]="{'profile-register-accordion-item-description-expanded': accordionItemModule.expanded}"
                                    class="profile-register-accordion-item-description">
                                    Clique para {{ accordionItemModule.expanded ? 'fechar' : 'abrir' }}
                                </span>
                            </div>
                            <div class="profile-register-accordion-item-body triage-body" role="region"
                                [style.display]="accordionItemModule.expanded ? '' : 'none'">
                                <div class="row">
                                    <div class="col-5 col-md-2 col-sm-3">
                                        <span class="chartButton"
                                            *ngIf="temperatureHistoryLenght / numberOfItemsChart > temperaturePagination+1"
                                            (click)="previous('temperature')">
                                            <center>
                                                < Anterior</center>
                                        </span>
                                    </div>
                                    <div class="offset-2 col-5 offset-md-10 col-md-2 offset-sm-6 col-sm-3">
                                        <span class="chartButton" *ngIf="temperaturePagination > 0"
                                            (click)="next('temperature')">
                                            <center>Próximo ></center>
                                        </span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <canvas baseChart [height]="calcHeight" [datasets]="temperatureChartData"
                                            [labels]="temperatureLabel" [options]="temperatureChartOptions"
                                            [type]="chartType"></canvas>
                                    </div>
                                </div>
                            </div>
                        </cdk-accordion-item>

                        <cdk-accordion-item *ngIf="heartRateHistoryLenght > 0" #accordionItemModule="cdkAccordionItem"
                            class="profile-register-accordion-item" role="button">
                            <div [ngClass]="{'profile-register-accordion-item-header-expanded': accordionItemModule.expanded}"
                                class="profile-register-accordion-item-header" (click)="accordionItemModule.toggle()">
                                Frequência cardíaca
                                <span
                                    [ngClass]="{'profile-register-accordion-item-description-expanded': accordionItemModule.expanded}"
                                    class="profile-register-accordion-item-description">
                                    Clique para {{ accordionItemModule.expanded ? 'fechar' : 'abrir' }}
                                </span>
                            </div>
                            <div class="profile-register-accordion-item-body triage-body" role="region"
                                [style.display]="accordionItemModule.expanded ? '' : 'none'">
                                <div class="row">
                                    <div class="col-5 col-md-2 col-sm-3">
                                        <span class="chartButton"
                                            *ngIf="heartRateHistoryLenght / numberOfItemsChart > heartRatePagination+1"
                                            (click)="previous('heartRate')">
                                            <center>
                                                < Anterior</center>
                                        </span>
                                    </div>
                                    <div class="offset-2 col-5 offset-md-10 col-md-2 offset-sm-6 col-sm-3">
                                        <span class="chartButton" *ngIf="heartRatePagination > 0"
                                            (click)="next('heartRate')">
                                            <center>Próximo ></center>
                                        </span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <canvas baseChart [height]="calcHeight" [datasets]="heartRateChartData"
                                            [labels]="heartRateLabel" [options]="heartRateChartOptions"
                                            [type]="chartType"></canvas>
                                    </div>
                                </div>
                            </div>
                        </cdk-accordion-item>

                        <cdk-accordion-item *ngIf="glucoseHistoryLenght > 0" #accordionItemModule="cdkAccordionItem"
                            class="profile-register-accordion-item" role="button">
                            <div [ngClass]="{'profile-register-accordion-item-header-expanded': accordionItemModule.expanded}"
                                class="profile-register-accordion-item-header" (click)="accordionItemModule.toggle()">
                                Glicose
                                <span
                                    [ngClass]="{'profile-register-accordion-item-description-expanded': accordionItemModule.expanded}"
                                    class="profile-register-accordion-item-description">
                                    Clique para {{ accordionItemModule.expanded ? 'fechar' : 'abrir' }}
                                </span>
                            </div>
                            <div class="profile-register-accordion-item-body triage-body" role="region"
                                [style.display]="accordionItemModule.expanded ? '' : 'none'">
                                <div class="row">
                                    <div class="col-5 col-md-2 col-sm-3">
                                        <span class="chartButton"
                                            *ngIf="glucoseHistoryLenght / numberOfItemsChart > glucosePagination+1"
                                            (click)="previous('glucose')">
                                            <center>
                                                < Anterior</center>
                                        </span>
                                    </div>
                                    <div class="offset-2 col-5 offset-md-10 col-md-2 offset-sm-6 col-sm-3">
                                        <span class="chartButton" *ngIf="glucosePagination > 0"
                                            (click)="next('glucose')">
                                            <center>Próximo ></center>
                                        </span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <canvas baseChart [height]="calcHeight" [datasets]="glucoseChartData"
                                            [labels]="glucoseLabel" [options]="glucoseChartOptions"
                                            [type]="chartType"></canvas>
                                    </div>
                                </div>
                            </div>
                        </cdk-accordion-item>

                        <cdk-accordion-item *ngIf="bloodPressureHistoryLenght > 0"
                            #accordionItemModule="cdkAccordionItem" class="profile-register-accordion-item"
                            role="button">
                            <div [ngClass]="{'profile-register-accordion-item-header-expanded': accordionItemModule.expanded}"
                                class="profile-register-accordion-item-header" (click)="accordionItemModule.toggle()">
                                Pressão arterial
                                <span
                                    [ngClass]="{'profile-register-accordion-item-description-expanded': accordionItemModule.expanded}"
                                    class="profile-register-accordion-item-description">
                                    Clique para {{ accordionItemModule.expanded ? 'fechar' : 'abrir' }}
                                </span>
                            </div>
                            <div class="profile-register-accordion-item-body triage-body" role="region"
                                [style.display]="accordionItemModule.expanded ? '' : 'none'">
                                <div class="row">
                                    <div class="col-5 col-md-2 col-sm-3">
                                        <span class="chartButton"
                                            *ngIf="bloodPressureHistoryLenght / numberOfItemsChart > bloodPressurePagination+1"
                                            (click)="previous('bloodPressure')">
                                            <center>
                                                < Anterior</center>
                                        </span>
                                    </div>
                                    <div class="offset-2 col-5 offset-md-10 col-md-2 offset-sm-6 col-sm-3">
                                        <span class="chartButton" *ngIf="bloodPressurePagination > 0"
                                            (click)="next('bloodPressure')">
                                            <center>Próximo ></center>
                                        </span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <canvas baseChart [height]="calcHeight" [datasets]="bloodPressureChartData"
                                            [labels]="bloodPressureLabel" [options]="bloodPressureChartOptions"
                                            [type]="chartType"></canvas>
                                    </div>
                                </div>
                            </div>
                        </cdk-accordion-item>
                    </cdk-accordion>
                </div>
            </form>
        </div>
    </div>
</div>