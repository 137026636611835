import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { ReportModalComponent } from 'src/app/shared/components/report-modal/report-modal.component';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ApiAuditMonthlyReportService } from 'src/app/shared/services/API/audit/audit-monthly-report.service';
import { AuditMonthlyReport } from 'src/app/shared/services/models/audit/audit-monthly-report.model';

@Component({
  selector: 'app-monthly-report',
  templateUrl: './monthly-report.component.html',
  styleUrls: ['./monthly-report.component.css']
})
export class MonthlyReportComponent implements OnInit {

  constructor(private apiAuditMonthlyReportService: ApiAuditMonthlyReportService,
    private alertService: AlertService,
    public dialog: MatDialog) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.audit;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.audit_monthly_report;
  public listDate: Date[];

  public isLoading: boolean;
  public isGeneratingReport: boolean;
  public yearMonth: Date;
  public idAuditMonthlyReport: number;

  public listAuditMonthlyReportModel: AuditMonthlyReport[];

  ngOnInit(): void {
    this.isLoading = true;
    this.populateDateSelect();
    this.search();
  }

  search() {
    this.isLoading = true;
    this.listAuditMonthlyReport();
  }

  listAuditMonthlyReport() {
    this.apiAuditMonthlyReportService.listAllAuditMonthlyReport(this.idAuditMonthlyReport, this.yearMonth).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listAuditMonthlyReportModel = response.listAuditMonthlyReport;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }

  populateDateSelect() {
    this.listDate = [];
    let todayDate = new Date();
    let year = todayDate.getFullYear();
    let month = todayDate.getMonth();
    for (let _i = 0; _i < 60; _i++) {
      this.listDate.push(new Date(year, month - _i));
    }
  }

  openDownloadModalReport(event: any) {
    this.isGeneratingReport = true;
    this.apiAuditMonthlyReportService.getAuditMonthlyReport(event).subscribe({
      next: (response) => {
        this.isLoading = false;
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isGeneratingReport = false;
          return;
        }

        let reportDialog = this.dialog.open(ReportModalComponent, {
          data: {
            reportName: response.reportName,
            reportPdf64: response.reportPdf64,
          },
        });
        reportDialog.afterClosed().subscribe({
          next: result => {
            this.isGeneratingReport = false;
          }
        });
      },
      error: (error) => {
        console.log(error)
        this.isGeneratingReport = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}