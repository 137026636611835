<div mat-dialog-content>
    <div class="mat-dialog-header">
        <h3>Prescrições Padronizadas</h3>
    </div>
    <form class="form" [formGroup]="prescriptionModel" (ngSubmit)="onSubmit()">
        <div class="mat-dialog-content">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Medicamento</mat-label>
                        <mat-select (selectionChange)="onChangeMedicine($event)" formControlName="idMedicine">
                            <input class="inputSearchSelect" type="text" (keyup)="onKey($event.target.value)"
                                placeholder="Pesquisar" autocomplete="off">
                            <mat-option *ngFor="let item of selectedMedicineList" value="{{item.medicine.idMedicine}}">
                                {{item.medicine.medicineDescription}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="checkbox-container" *ngIf="isFastTrack">
                <mat-checkbox [checked]="bypassPrescription" (change)="toggleBypassPrescription($event)">
                    Listar todos
                </mat-checkbox>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Quantidade</mat-label>
                        <input formControlName="quantity" matInput [readonly]="this.data.readOnly" type="text"
                            oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                        <mat-error *ngIf="prescriptionModel.get('quantity').invalid">A quantidade máxima é
                            32767!</mat-error>
                    </mat-form-field>
                </div>
                <!-- <div *ngIf="isBottle" class="col-12 col-sm-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Número total de gotas</mat-label>
                        <input matInput type="number" formControlName="numberDrops">
                    </mat-form-field>
                </div> -->
                <div class="col-12 col-sm-12 col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Unidade</mat-label>
                        <mat-select formControlName="idMeasurementUnit">
                            <mat-option *ngFor="let item of measurementUnitList" [value]="item.idMeasurementUnit">
                                {{item.measurementUnitName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Via</mat-label>
                        <mat-select formControlName="idAdministrationType"
                            (ngModelChange)="onChangeAdministrationType($event)">
                            <mat-option *ngFor="let item of administrationTypeListDisplay"
                                [value]="item.idAdministrationType">
                                {{item.administrationTypeName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-12 col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Frequência</mat-label>
                        <mat-select formControlName="idFrequency"
                        (ngModelChange)="onChangeFrequency($event)">
                            <mat-option *ngFor="let item of frequencyList" value="{{item.idFrequency}}">
                                {{item.frequencyName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row" *ngIf="isFrequency">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Defina a frequência</mat-label>
                        <mat-select formControlName="idSubFrequency">
                            <mat-option *ngFor="let item of this.data.lookupMedicalCareResponse.listSubFrequency"
                                value="{{item.idSubFrequency}}">
                                {{item.subFrequencyName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="this.prescriptionModel.get('idAdministrationType') && administrationTypeOthersEnum.valueOf() == this.prescriptionModel.get('idAdministrationType').value"
                class="row">
                <div class="col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Descreva a Via</mat-label>
                        <textarea [readonly]="this.data.readOnly" matInput
                            formControlName="administrationType"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Diluição</mat-label>
                        <mat-select (ngModelChange)="onChangeDilution($event)" formControlName="idDilution">
                            <mat-option *ngFor="let item of dilutionList" value="{{item.idDilution}}">
                                {{item.dilutionName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-12 col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Apresentação</mat-label>
                        <mat-select (ngModelChange)="onChangePresentation($event)" formControlName="idPresentation">
                            <mat-option *ngFor="let item of presentationList" value="{{item.idPresentation}}">
                                {{item.presentationName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="this.prescriptionModel.get('idDilution') && this.prescriptionModel.get('idDilution').value == dilutionOthersEnum.valueOf()"
                class="row">
                <div class="col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Descreva a diluição</mat-label>
                        <textarea [readonly]="this.data.readOnly" matInput formControlName="dilution"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="this.prescriptionModel.get('idPresentation') && this.prescriptionModel.get('idPresentation').value == presentationOthersEnum.valueOf()"
                class="row">
                <div class="col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Descreva a apresentação</mat-label>
                        <textarea [readonly]="this.data.readOnly" matInput formControlName="presentation"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Periodo de tratamento</mat-label>
                        <mat-select formControlName="idTreatmentPeriod">
                            <mat-option *ngFor="let item of treatmentList" value="{{item.idTreatmentPeriod}}">
                                {{item.treatmentPeriodName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Observação</mat-label>
                        <textarea [readonly]="this.data.readOnly" matInput formControlName="observation"></textarea>
                    </mat-form-field>
                </div>
            </div>

            <div class="footer-panel-modal-list">
                <div class="row">
                    <div *ngIf="!this.data.readOnly" class="col-6">
                        <a mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Cancelar</a>
                    </div>
                    <div *ngIf="!this.data.readOnly" class="col-6">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span>Salvar</span>
                        </button>
                    </div>
                    <div class="col-12">
                        <a *ngIf="this.data.readOnly" mat-flat-button color="primary" class="btn-block"
                            (click)="clickCancel()">Ok</a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>