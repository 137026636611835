import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { SectionFillEnum } from 'src/app/shared/enum/multi-professional/section-fill.enum';
import { VisibleFieldEnum } from 'src/app/shared/enum/multi-professional/visible-field.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { LocationByModuleService } from 'src/app/shared/services/API/flow/location-by-module.service';
import { MetaDataService } from 'src/app/shared/services/API/meta-data/meta-data.service';
import { MultiProfessionalConfigService } from 'src/app/shared/services/API/multi-professional/multi-professional-config.service';
import { MultiProfessionalSectionFillConfigService } from 'src/app/shared/services/API/multi-professional/multi-professional-section-fill-config.service';
import { MetaDataModel } from 'src/app/shared/services/models/meta-data/meta-data.model';
import { PostPutMultiProfessionalConfigRequest } from 'src/app/shared/services/requests/multi-professional/post-put-multi-professional-config.request';
import { SectorStruct } from 'src/app/shared/services/structs/flow/sector.struct';
import { MetaDataStruct } from 'src/app/shared/services/structs/meta-data/meta-data.struct';
import { SectionFillStruct } from 'src/app/shared/services/structs/multi-professional/section-fill-struct';
import { VisibleFieldStruct } from 'src/app/shared/services/structs/multi-professional/visible-field-struct';
import { MultiProfessionalConfigSectorStruct } from 'src/app/shared/structs/multi-professional/multi-professional-config-sector.struc';

@Component({
  selector: 'app-multi-professional-config-register',
  templateUrl: './multi-professional-config-register.component.html',
  styleUrls: ['./multi-professional-config-register.component.css']
})
export class MultiProfessionalConfigRegisterComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private alertService: AlertService,
    private router: Router,
    private multiProfessionalConfigService: MultiProfessionalConfigService,
    private activatedRoute: ActivatedRoute,
    private metaDataService: MetaDataService,
    private locationByModuleService: LocationByModuleService,
    private multiProfessionalSectionFillConfigService: MultiProfessionalSectionFillConfigService,
  ) { }

  public model: FormGroup;

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.multi_professional;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.multi_professional_config;

  public isFirstLoading: boolean = false;
  public isLoading: boolean = false;
  public showSectionFillSelect: boolean = false;
  public isUpdate: boolean;
  public listSectionFill: SectionFillStruct[] = [];
  public listVisibleField: VisibleFieldStruct[] = [];
  public listMetaDataSelected: MetaDataStruct[] = [];
  public listMetaDataRequiredSelected: MetaDataStruct[] = [];
  public listRequiredFieldSelected: VisibleFieldStruct[] = [];
  public listSector: SectorStruct[];
  public listMetaData: MetaDataStruct[];
  public listIdRequiredSelected: number[] = [];
  public listIdMetaDataRequiredSelected: number[] = [];
  public idMultiProfessionalConfig: number;

  ngOnInit(): void {
    this.getAllMetaData();

    this.model = this.formBuilder.group({
      multiProfessionalConfigName: ['', [Validators.required]],
      listSectionFill: ['', [Validators.required]],
      listSectionFieldSelected: [''],
      listRequiredFieldSelected: [''],
      listSector: [[], [Validators.required]],
      listMetadata: [],
      listMetaDataRequiredSelected: [],
      dischargeOutcome: [false, [Validators.required]],
      hideDataPatientHistory: [false, [Validators.required]]
    });

    this.idMultiProfessionalConfig == null;
    this.isUpdate == false;

    this.populateSectorSelect();
    this.populateSectionFillSelect();
  }

  submit() {
    if (this.isLoading)
      return;

    if (!this.model.valid) {
      this.throwException('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.");
      return;
    }

    this.isLoading = true;

    let request: PostPutMultiProfessionalConfigRequest = new PostPutMultiProfessionalConfigRequest();
    request.multiProfessionalConfigName = this.model.get('multiProfessionalConfigName').value;
    request.dischargeOutcome = this.model.get('dischargeOutcome').value;
    request.hideDataPatientHistory = this.model.get('hideDataPatientHistory').value
    request.listIdSectionFill = this.model.get('listSectionFill').value;
    if (this.model.get('listSectionFieldSelected').value)
      request.listIdVisibleField = this.model.get('listSectionFieldSelected').value;
    if (this.model.get('listRequiredFieldSelected').value)
      request.listIdRequiredField = this.model.get('listRequiredFieldSelected').value;
    request.listSector = this.convertIdSectorToObject(this.model.get('listSector').value);
    if (this.model.get('listMetadata').value)
      request.listIdMetaData = this.model.get('listMetadata').value;
    if (this.model.get('listMetaDataRequiredSelected').value)
      request.listIdRequiredMetaData = this.model.get('listMetaDataRequiredSelected').value;

    if (this.isUpdate)
      this.updateMultiProfessionalConfig(request);
    else
      this.createMultiProfessionalConfig(request);
  }

  getAllMetaData() {
    this.metaDataService.getAllMetaData().subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException('Erro', response.errorDescription)
          return;
        }

        this.listMetaData = response.listMetaDataStruct;

        if (this.activatedRoute.snapshot.paramMap.get('idMultiProfessionalConfig'))
          this.idMultiProfessionalConfig = parseInt(this.activatedRoute.snapshot.paramMap.get('idMultiProfessionalConfig'));

        if (this.idMultiProfessionalConfig != null) {
          this.isFirstLoading = true;
          this.isUpdate = true;
          this.populateMultiProfessionalConfigData(this.listMetaData);
        }
      },
      error: (error) => {
        this.throwException('Erro inesperado', error)
      }
    });
  }

  populateSectorSelect() {
    this.locationByModuleService.listRoomByModule(MenuModuleEnum.multi_professional).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException('Erro', response.errorDescription);
          return;
        }

        this.listSector = response.sectors;
      },
      error: (error) => {
        this.throwException('Erro inesperado', error);
      }
    });
  }

  populateSectionFillSelect() {
    this.multiProfessionalSectionFillConfigService.listMultiProfessionalSectionFillConfig().subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException('Erro', response.errorDescription);
          return;
        }

        if (response.listSectionFill)
          this.listSectionFill = response.listSectionFill;

        if (response.listVisibleField)
          this.listVisibleField = response.listVisibleField;
      },
      error: (error) => {
        this.throwException('Erro inesperado', error);
      }
    });
  }

  convertIdSectorToObject(listIdSector: number[]): MultiProfessionalConfigSectorStruct[] {
    if (!listIdSector || !this.listSector)
      return;

    let listRequestSector: MultiProfessionalConfigSectorStruct[] = [];

    listIdSector.forEach(element => {
      let requestSector: MultiProfessionalConfigSectorStruct = new MultiProfessionalConfigSectorStruct();
      let sector = this.listSector.find(l => l.idSector == element);
      requestSector.id = sector.idSector,
        requestSector.name = sector.sectorName;
      listRequestSector.push(requestSector);
    });

    return listRequestSector;
  }

  updateMultiProfessionalConfig(request: PostPutMultiProfessionalConfigRequest) {
    this.multiProfessionalConfigService.updateMultiProfessionalConfig(this.idMultiProfessionalConfig, request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException('Erro', response.errorDescription);
          return;
        }

        this.alertSave("Informações salvas com sucesso");
      },
      error: (error) => {
        this.throwException('Erro inesperado', error);
      }
    });
  }

  populateMultiProfessionalConfigData(listMetaData: MetaDataStruct[]) {
    this.multiProfessionalConfigService.getMultiProfessionalConfig(listMetaData, this.idMultiProfessionalConfig).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException('Erro', response.errorDescription);
          return;
        }
        this.model.get('multiProfessionalConfigName').setValue(response.multiProfessionalConfig.multiProfessionalConfigName);
        this.model.get('listSectionFill').setValue(response.multiProfessionalConfig.listIdSectionFill);
        this.model.get('listSector').setValue(response.multiProfessionalConfig.listIdSector);
        this.model.get('dischargeOutcome').setValue(response.multiProfessionalConfig.dischargeOutcome);
        this.model.get('hideDataPatientHistory').setValue(response.multiProfessionalConfig.hideDataPatientHistory);

        if (this.model.get('listSectionFill').value && this.model.get('listSectionFill').value.find(x => x == SectionFillEnum.NewAttendance)) {
          this.showSectionFillSelect = true;
          if (response.multiProfessionalConfig.listIdFieldRequired && response.multiProfessionalConfig.listIdFieldRequired.length > 0)
            this.model.get('listRequiredFieldSelected').setValue(response.multiProfessionalConfig.listIdFieldRequired);

          if (response.multiProfessionalConfig.listVisibleField && response.multiProfessionalConfig.listVisibleField.length > 0)
            this.listRequiredFieldSelected = response.multiProfessionalConfig.listVisibleField.filter(x => x.idVisibleField != VisibleFieldEnum.TodosOsCampos)
          else
            this.listRequiredFieldSelected = [];

          this.model.get('listSectionFieldSelected').setValue(response.multiProfessionalConfig.listIdVisibleField);
        }
        else
          this.showSectionFillSelect = false;

        this.model.get('listMetaDataRequiredSelected').setValue(response.multiProfessionalConfig.listIdMetaDataRequired);
        this.model.get('listMetadata').setValue(response.multiProfessionalConfig.listIdMetaData)

        this.listMetaDataRequiredSelected = response.multiProfessionalConfig.listMetaData.map((metaData: MetaDataModel) => {
          const metaDataStruct = new MetaDataStruct();
          metaDataStruct.metaData = metaData;
          return metaDataStruct;
        });

        this.isLoading = false;
        this.isFirstLoading = false;
      },
      error: (error) => {
        this.throwException('Erro inesperado', error);
      }
    });
  }

  createMultiProfessionalConfig(request: PostPutMultiProfessionalConfigRequest) {
    this.multiProfessionalConfigService.createMultiProfessionalConfig(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException('Erro', response.errorDescription);
          return;
        }

        this.alertSave("Informações salvas com sucesso");
      },
      error: (error) => {
        this.throwException('Erro inesperado', error);
      }
    });
  }

  selectSectionFill(event: any) {
    if (this.model.get('listSectionFill').value && this.model.get('listSectionFill').value.find(x => x == SectionFillEnum.NewAttendance))
      this.showSectionFillSelect = true;
    else {
      this.showSectionFillSelect = false;
      this.model.get('listSectionFieldSelected').setValue([]);
      this.model.get('listRequiredFieldSelected').setValue([]);
      this.listRequiredFieldSelected = [];
    }
  }

  selectField(event: any) {
    if (event == VisibleFieldEnum.TodosOsCampos && this.model.get('listSectionFieldSelected').value && this.model.get('listSectionFieldSelected').value.includes(VisibleFieldEnum.TodosOsCampos)) {
      let listIdVisibleField = this.listVisibleField.map(x => x.idVisibleField);
      this.model.get('listSectionFieldSelected').setValue(listIdVisibleField);
      this.listRequiredFieldSelected = this.listVisibleField.filter(x => x.idVisibleField != VisibleFieldEnum.TodosOsCampos);
    }
    else if (event == VisibleFieldEnum.TodosOsCampos && this.model.get('listSectionFieldSelected').value && !this.model.get('listSectionFieldSelected').value.includes(VisibleFieldEnum.TodosOsCampos)) {
      this.model.get('listSectionFieldSelected').setValue([]);
      this.model.get('listRequiredFieldSelected').setValue([]);
      this.listRequiredFieldSelected = [];
    }
    else if (event != VisibleFieldEnum.TodosOsCampos && this.model.get('listSectionFieldSelected').value) {
      this.listRequiredFieldSelected = this.listVisibleField.filter(x => this.model.get('listSectionFieldSelected').value.includes(x.idVisibleField) && x.idVisibleField != VisibleFieldEnum.TodosOsCampos);
      let listSectionFieldSelectedWithoutAllFieldOption = this.model.get('listSectionFieldSelected').value.filter(x => x != VisibleFieldEnum.TodosOsCampos);
      this.model.get('listSectionFieldSelected').setValue(listSectionFieldSelectedWithoutAllFieldOption);

      if (this.model.get('listRequiredFieldSelected').value) {
        let listRequiredFieldSelectedFilteredOptions = this.model.get('listRequiredFieldSelected').value.filter(x => this.listRequiredFieldSelected.map(item => item.idVisibleField).includes(x));
        this.model.get('listRequiredFieldSelected').setValue(listRequiredFieldSelectedFilteredOptions);
      }
    }
  }

  selectRequiredField(event: any) {
    let listIdVisibleField = this.listIdRequiredSelected.filter(x => x == event);
    if (listIdVisibleField.length == 0) {
      let idVisibleField = this.listRequiredFieldSelected.filter(x => x.idVisibleField == event)
      idVisibleField.forEach(x => {
        this.listIdRequiredSelected.push(x.idVisibleField)
      });
    }
    else
      this.listIdRequiredSelected = this.listIdRequiredSelected.filter(x => x != event)
  }

  selectMetaData(event: any) {
    let listIdMetaDataSelected = this.listMetaDataRequiredSelected.filter(x => x.metaData.idMetaData == event)
    if (listIdMetaDataSelected.length == 0) {
      let idMetaDataSelected = this.listMetaData.filter(x => x.metaData.idMetaData == event)
      idMetaDataSelected.forEach(x => {
        this.listMetaDataRequiredSelected.push(x)
      });
    }
    else
      this.listMetaDataRequiredSelected = this.listMetaDataRequiredSelected.filter(x => x.metaData.idMetaData != event)

    if (this.model.get('listMetaDataRequiredSelected'))
      this.model.get('listMetaDataRequiredSelected').setValue(this.model.get('listMetaDataRequiredSelected').value.filter(x => x != event));
  }

  selectRequiredMetaData(event: any) {
    let listIdMetaData = this.listIdMetaDataRequiredSelected.filter(x => x == event);
    if (listIdMetaData.length == 0) {
      let idMetaData = this.listMetaDataRequiredSelected.filter(x => x.metaData.idMetaData == event)
      idMetaData.forEach(x => {
        this.listIdMetaDataRequiredSelected.push(x.metaData.idMetaData)
      });
    }
    else
      this.listIdMetaDataRequiredSelected = this.listIdMetaDataRequiredSelected.filter(x => x != event)
  }

  throwException(alertTypeDescription: string, error: string) {
    this.alertService.show(alertTypeDescription, error, AlertType.error);
    this.isLoading = false;
    console.log(error);
  }

  alertSave(message: string) {
    this.alertService.show('Sucesso', message, AlertType.success);
    this.isLoading = false;
    this.router.navigate(['/multi-professional/multi-professional-config']);
  }
}