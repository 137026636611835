import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UtilsClassificationService } from 'src/app/classification/utils.service';
import { DeviceTypeEnum } from 'src/app/shared/enum/device-type.enum';
import { PriorityColorEnum } from 'src/app/shared/enum/priority-color.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { DiscriminatorResponse } from 'src/app/shared/services/responses/risk-classification/discriminator.response';
import { DiscriminatorStruct } from 'src/app/shared/services/structs/risk-classification/discriminator.struct';
import { UtilsMeasurerRemoteService } from '../protocol-v2/utils-measurer-remote.service';
import { UtilsMeasurerService } from '../protocol-v2/utils-measurer.service';
import { MultipleVictimModalComponent } from './multiple-victim-modal/multiple-victim-modal.component';

@Component({
  selector: 'app-multiple-victim-primary',
  templateUrl: './multiple-victim-primary-assessment.component.html',
  styleUrls: ['./multiple-victim-primary-assessment.component.css'],
})
export class MultipleVictimPrimaryAssessmentComponent implements OnInit, OnDestroy {

  constructor(private alertService: AlertService,
    private dialog: MatDialog,
    private utilService: UtilsClassificationService,
    public router: Router,
    private formBuilder: UntypedFormBuilder,
    private measurerService: UtilsMeasurerService,
    private measurerRemote: UtilsMeasurerRemoteService) { }

  @Input() discriminators: any[] = [];
  @Output() selectedValue = new EventEmitter<any>();
  @Output() goNextToFlowchartSelect = new EventEmitter<any>();

  public model: FormGroup;
  public walkDescription: string = "Em acidente envolvendo múltiplas vítimas, ao ser acionado o Plano de Catástrofe, qualquer paciente que possa caminhar, independentemente de seu ferimento, preenche este critério, sendo priorizado como padrão (verde).";
  public breathAfterObstructionDescription: string = "Em situações de múltiplas vítimas, deve ser avaliada a presença de respiração após abertura da via aérea. A ausência de respiração por 10 segundos após manobra de abertura da via aérea implica em declaração de óbito (uma competência médica), a não ser que haia recurso para ressuscitação cardiopulmonar sem prejuízo ao atendimento de outras vítimas classificadas como vermelho.";

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      "radio-anda": [],
      "radio-respira": [{ value: null, disabled: true }],
      "radio-respira-espontaneamente": [{ value: null, disabled: true }]
    });
    let data = this.utilService.getClassificationData();
    if (data.idDeviceType == DeviceTypeEnum.Trius || data.idDeviceType == DeviceTypeEnum.Berco || data.idDeviceType == DeviceTypeEnum.Bluetooth) {
      this.measurerService.setIndex(-1);
      this.measurerService.createReference(this.discriminators, null, null, data.deviceThermometer, data.deviceGlucometer, data.deviceOximeter, data.devicePressure);
      this.measurerService.restartMeasurer();
    } else if (data.idDeviceType == DeviceTypeEnum.Remoto) {
      this.measurerRemote.isOnline(data.device.idDevice);
      this.measurerRemote.createReference(this.discriminators, null, null);
      this.measurerRemote.startWebsocket(data.device.deviceHash);
    }
  }

  ngOnDestroy(): void {
    let data = this.utilService.getClassificationData();
    if (data.idDeviceType == DeviceTypeEnum.Trius || data.idDeviceType == DeviceTypeEnum.Berco || data.idDeviceType == DeviceTypeEnum.Bluetooth) {
      this.measurerService.setIndex(-1);
    } else if (data.idDeviceType == DeviceTypeEnum.Remoto) {
      this.measurerRemote.clearAllInterval();
    }
  }

  previous() {
    let data = this.utilService.getClassificationData();
    data.isPatientWhite = false;
    data.discriminatorAllValues = [];
    data.discriminatorsPersist = [];
    data.idFlowchart = null;
    this.utilService.updateClassificationData(data);
    this.goNextToFlowchartSelect.emit();
  }
  setValueAndaSim() {
    let data = this.utilService.getClassificationData();
    let result: DiscriminatorStruct = new DiscriminatorStruct;
    result.discriminatorName = "Anda";
    result.idDiscriminatorType = 10;
    result.idFlowchart = data.idFlowchart;
    result.idDiscriminator = 1512;
    result.idPriorityColor = PriorityColorEnum.PoucoUrgente.valueOf();
    this.selectedValue.emit({ item: result, discriminatorsValues: null });
  }
  setValueAndaNao() {
    this.model.get("radio-respira").enable();
  }
  setValueRespiraSim() {
    if (this.verifyDisable("radio-respira")) return;
    let data = this.utilService.getClassificationData();
    let result: DiscriminatorStruct = new DiscriminatorStruct;
    result.discriminatorName = "Respira só após abertura de vias aéreas";
    result.idDiscriminatorType = 10;
    result.idFlowchart = data.idFlowchart;
    result.idDiscriminator = 1514;
    result.idPriorityColor = PriorityColorEnum.Emergencia.valueOf();
    this.selectedValue.emit({ item: result, discriminatorsValues: null });
  }
  setValueRespiraNao() {
    if (this.verifyDisable("radio-respira")) return;
    this.model.get("radio-respira-espontaneamente").enable();
  }

  verifyDisable(formName: string): boolean {
    if (this.model.get(formName).disabled) {
      this.alertService.show('Aviso', "É necessário negar todos os discriminadores anteriores!", AlertType.warning);
      return true;
    }
    return false;
  }

  setValueRespiraEspontaneamenteSim() {
    if (this.verifyDisable("radio-respira-espontaneamente")) return;
    const dialogRef = this.dialog.open(MultipleVictimModalComponent, { data: { discriminators: this.discriminators } });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.result) {
        this.selectedValue.emit({ item: result.result, discriminatorsValues: null });
      }
    });
  }

  setValueRespiraEspontaneamenteNao() {
    if (this.verifyDisable("radio-respira-espontaneamente")) return;
    let data = this.utilService.getClassificationData();
    let result: DiscriminatorStruct = new DiscriminatorStruct;
    result.discriminatorName = "Respira espontaneamente (Não)";
    result.idDiscriminatorType = 10;
    result.idFlowchart = data.idFlowchart;
    result.idDiscriminator = 1513;
    result.idPriorityColor = PriorityColorEnum.Obito.valueOf();
    this.selectedValue.emit({ item: result, discriminatorsValues: null });
  }
}
