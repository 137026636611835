<div class="row display-contents">
    <div class="col-12 padding-box">
        <div class="row border-box">
            <div class="title-header">
                <h3>Valores dos procedimentos para o convênio</h3>
            </div>
            <div class="filters">
                <div class="row">
                    <div class="col-12 col-md-5 col-xl-5 col-lg-5 col-sm-5 search-correction">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Pesquisar</mat-label>
                            <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText"
                                (keyup)="onKeySearch($event)">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-3 col-xl-3 col-lg-3 col-sm-3">
                        <button class="custom-button" [class.active]="isButtonActive('ativos')"
                            (click)="controlButtonStatus('ativos')">Ativos</button>
                    </div>
                    <div class="col-12 col-md-3 col-xl-3 col-lg-3 col-sm-3">
                        <button class="custom-button" [class.active]="isButtonActive('inativos')"
                            (click)="controlButtonStatus('inativos')">Inativos</button>
                    </div>
                </div>
            </div>
            <div [ngClass]="{'table-height': listPriceProcedureHealthcareStruct?.length > 0}" style="overflow: auto;">
                <div class="row">
                    <app-loading-progress-bar *ngIf="isLoading"></app-loading-progress-bar>
                    <table class="col-12" *ngIf="!isLoading">
                        <thead>
                            <tr>
                                <th class="column-procedure">Procedimento</th>
                                <th class="column-active">Ativo</th>
                                <th class="column-value">Valor (R$)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let priceProcedureHealthcare of listPriceProcedureHealthcareStruct">
                                <td>{{ priceProcedureHealthcare.nameProcedure }}</td>
                                <td>
                                    <mat-slide-toggle [(ngModel)]="priceProcedureHealthcare.isActive"
                                        (change)="putStatus($event, priceProcedureHealthcare.idProcedureHealthcare)"
                                        [labelPosition]="'before'">
                                    </mat-slide-toggle>
                                </td>
                                <td class="value-customize">
                                    <mat-form-field appearance="outline" floatLabel="always"
                                        class="display-none control-space-field">
                                        <input matInput type="text" [(ngModel)]="priceProcedureHealthcare.valueFront"
                                            class="example-right-align" placeholder="0,00"
                                            (input)="formatCurrency($event, priceProcedureHealthcare)"
                                            [ngModelOptions]="{standalone: true}">
                                        <span matTextPrefix>R$&nbsp;</span>
                                    </mat-form-field>
                                    <div class="ball-control">
                                        <div class="ball"
                                            (click)="openHistoryCardModal(priceProcedureHealthcare.idProcedureHealthcare)">
                                            i</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <app-empty-list
                *ngIf="listPriceProcedureHealthcareStruct != undefined && listPriceProcedureHealthcareStruct.length == 0 && !this.isLoading">
            </app-empty-list>
            <div class="row">
                <div class="col-12">
                    <mat-paginator [length]="fullListSize" #paginator [pageIndex]="pageIndex" [pageSize]="pageSize"
                        aria-label="Select page" (page)="changePage($event)" [hidePageSize]="true">
                    </mat-paginator>
                </div>
            </div>
        </div>

        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>
    <div class="row" style="margin-top: 20px;">
        <div class="col-6">
            <button mat-flat-button (click)="onCancelarClick()" color="accent" class="btn-block">
                <span *ngIf="isLoading == false">Cancelar</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
        </div>
        <div class="col-6">
            <button mat-flat-button (click)="submit()" color="primary" class="btn-block">
                <span *ngIf="isLoading == false">Salvar</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
        </div>
    </div>
</div>