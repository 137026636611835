<div class="container-generic">

    <app-menu #menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'
        [hasSecundaryHeader]="idEpisode !== null" [episode]="idEpisode" [hasCachedModule]="true"
        [isClassification]="true" [cachedModuleName]="'classification'" [discriminators]="this.data.discriminatorValues"
        (restartMeasurers)="restartMeasurers()"></app-menu>

    <div *ngIf="!isFirstLoading" [ngClass]="{'container-body-secundary-header': idEpisode !== null, 'container-body': idEpisode === null}">
        <app-pre-register-default *ngIf="idDeviceTypeRemote != idDeviceType" [idEpisode]="idEpisode"
            [idRoom]="idRoom" [idSector]="idSector" [idService]="idService" [idDeviceType]="idDeviceType" [reclassification]="reclassification"
            [isFirstLoading]="isFirstLoading"
            [classificationData]="this.data" (repopulateHeader)="repopulateHeader()"></app-pre-register-default>
        <app-pre-register-remote *ngIf="idDeviceTypeRemote == idDeviceType" [idEpisode]="idEpisode"
            [idRoom]="idRoom" [idSector]="idSector" [idDeviceType]="idDeviceType"
            [isFirstLoading]="isFirstLoading"
            [reclassification]="reclassification"></app-pre-register-remote>
    </div>

</div>