import { ReturnStruct } from "src/app/shared/structs/return.struct";

export class RequestMaterialRequests {
    public listRequestMaterialsItemKit: MaterialItemKitRequests[];
    public idRequestMaterial: number;
    public idEpisode: number;
    public idProcedure: number;
    public observation: string;
    public isDeleted: boolean;
    public idStatus: number;
    public dateTimeInclusion: Date;
    public validateQuantity: boolean;
}

export class PostRequestMaterialRequests extends ReturnStruct {
    public listRequestMaterials: RequestMaterialValidateStruct[];
}

export class RequestMaterialValidateStruct {
    public idItem: number;
    public idMedicine: number;

    public idKit: number;
    
    public quantityNeeded: number;
    
    public quantityHaving: number;
    
    public hasEnough: boolean;
}



export class MaterialItemKitRequests {
    public idRequestMaterialItemKit?: number;
    public idRequestMaterial?: number;
    public idItem?: number;
    public idKit?: number;
    public quantity: number;
}

//só para o front (modal de itens inválidos)
export class ItemFormattedSctruct {
    public itemName: string;
    public medicineName: string;
    public quantityNeeded: number;
    public quantityHaving: number;
};