import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { PriceProcedureHealthcareService } from 'src/app/shared/services/API/private-billing/price-procedure-healthcare.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { PriceProcedureHealthcareStruct } from 'src/app/shared/services/structs/private-billing/price-procedure-healthcare.struct';
import { MatDialog } from '@angular/material/dialog';
import { ProcedureHealthcareService } from 'src/app/shared/services/API/private-billing/procedure-healthcare.service';
import { PriceProcedureHealthcareRequest } from 'src/app/shared/services/requests/private-billing/price-procedure-healthcare.request';
import { Router } from '@angular/router';
import { PriceHistoryModalComponent } from './price-history-modal/price-history-modal.component';
import { UtilsHealthcareAgreements } from '../../healthcare-agreements-and-plans-register/utils-healthcare-agreements.service';

@Component({
  selector: 'app-procedure-price-by-healthcare',
  templateUrl: './procedure-price-by-healthcare.component.html',
  styleUrls: ['./procedure-price-by-healthcare.component.css']
})
export class ProcedurePriceByHealthcareComponent implements OnInit, OnChanges {
  constructor(
    private priceProcedureHealthcareService: PriceProcedureHealthcareService,
    private procedureHealthcareService: ProcedureHealthcareService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private utilsHealthcareAgreements: UtilsHealthcareAgreements
  ) { }

  @Input() listPriceProcedureHealthcareStruct: PriceProcedureHealthcareStruct[] = [];
  @Input() idProcedure: number;

  public isLoading: boolean = false;

  public buttonSelected: 'ativos' | 'inativos' | null = null;
  public searchText: string = null;
  public isActiveProcedureHealthcare: boolean = null;
  public listOriginalPriceProcedureHealthcareStruct: PriceProcedureHealthcareStruct[] = [];

  ngOnInit(): void {
    this.controlButtonStatus('ativos');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.listPriceProcedureHealthcareStruct) {
      this.listPriceProcedureHealthcareStruct = changes.listPriceProcedureHealthcareStruct.currentValue ? changes.listPriceProcedureHealthcareStruct.currentValue : [];
      this.listPriceProcedureHealthcareStruct.forEach(x => {
        x.valueFront = this.utilsHealthcareAgreements.formatCurrencyBackToFront(x.value).trim();
      });

      this.listOriginalPriceProcedureHealthcareStruct = JSON.parse(JSON.stringify(this.listPriceProcedureHealthcareStruct));
    }
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    },
      1000);
  }

  search() {
    if (this.idProcedure) {
      if (this.isLoading)
        return;

      this.isLoading = true;

      this.priceProcedureHealthcareService.GetAllByIdProcedure(this.idProcedure, this.searchText, this.isActiveProcedureHealthcare).subscribe({
        next: (response) => {
          if (response.isError) {
            this.throwException(response.errorDescription);
            return;
          }

          this.listPriceProcedureHealthcareStruct = response.listPriceProcedureHealthcareStruct ? response.listPriceProcedureHealthcareStruct : [];

          if (response && this.listPriceProcedureHealthcareStruct && this.listPriceProcedureHealthcareStruct.length > 0) {
            this.listPriceProcedureHealthcareStruct.forEach(x => {
              x.valueFront = this.utilsHealthcareAgreements.formatCurrencyBackToFront(x.value).trim();
            });
          }

          this.listOriginalPriceProcedureHealthcareStruct = JSON.parse(JSON.stringify(this.listPriceProcedureHealthcareStruct));

          this.cdr.detectChanges();

          this.isLoading = false;
        },
        error: (error) => {
          this.throwException(error);
        }
      });
    }
  }

  controlButtonStatus(buttonType: 'ativos' | 'inativos') {
    if (this.buttonSelected === buttonType) {
      this.buttonSelected = null;
      this.isActiveProcedureHealthcare = null;
      this.search();
    }
    else {
      this.buttonSelected = buttonType;

      if (this.buttonSelected == 'ativos') {
        this.isActiveProcedureHealthcare = true;
        this.search();
      }
      else if (this.buttonSelected == 'inativos') {
        this.isActiveProcedureHealthcare = false;
        this.search();
      }
    }
  }

  isButtonActive(buttonType: 'ativos' | 'inativos'): boolean {
    return buttonType === this.buttonSelected;
  }

  submit() {
    if (this.isLoading)
      return;

    this.isLoading = true;

    let request: PriceProcedureHealthcareRequest = new PriceProcedureHealthcareRequest();
    request.listPriceProcedureHealthcareStruct = [];

    this.listPriceProcedureHealthcareStruct?.forEach(item => {
      if (!Number.isSafeInteger(item.idPriceProcedureHealthcare) && (!item.valueFront || item.valueFront.trim() == "0,00"))
        return;

      else if (!Number.isSafeInteger(item.idPriceProcedureHealthcare) && item.valueFront && item.valueFront.trim() != "0,00") {
        item.value = this.utilsHealthcareAgreements.formatCurrencyFrontToBack(item.valueFront);
        request.listPriceProcedureHealthcareStruct.push(item);
      }

      else {
        let priceProcedureHealthcare: PriceProcedureHealthcareStruct = this.listOriginalPriceProcedureHealthcareStruct?.find(itemOriginal => itemOriginal.idPriceProcedureHealthcare == item.idPriceProcedureHealthcare && (itemOriginal.value != item.value || itemOriginal.valueFront != item.valueFront));

        if (priceProcedureHealthcare) {
          item.value = this.utilsHealthcareAgreements.formatCurrencyFrontToBack(item.valueFront);
          request.listPriceProcedureHealthcareStruct.push(item);
        }
      }
    });

    if (!request.listPriceProcedureHealthcareStruct || request.listPriceProcedureHealthcareStruct.length == 0) {
      this.alertService.show('Erro', 'Nenhum valor foi alterado.', AlertType.error);
      this.isLoading = false;
      return;
    }

    request.idProcedure = this.idProcedure;
    request.isActivePriceProcedureHealthcare = this.isActiveProcedureHealthcare;
    request.searchText = this.searchText;

    this.priceProcedureHealthcareService.Put(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }
        this.listPriceProcedureHealthcareStruct = response.listPriceProcedureHealthcareStruct;

        if (response && this.listPriceProcedureHealthcareStruct && this.listPriceProcedureHealthcareStruct.length > 0) {
          this.listPriceProcedureHealthcareStruct.forEach(x => {
            x.valueFront = this.utilsHealthcareAgreements.formatCurrencyBackToFront(x.value).trim();
          });
        }

        this.listOriginalPriceProcedureHealthcareStruct = JSON.parse(JSON.stringify(this.listPriceProcedureHealthcareStruct));

        this.alertService.show('Sucesso', 'Procedimentos salvos com sucesso!', AlertType.success);
        this.isLoading = false;
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  backToProcedureList() {
    if (this.isLoading)
      return;

    this.router.navigate(['/billing/procedure']);
  }

  putStatus(event: any, idProcedureHealthcare: number) {
    if (this.isLoading)
      return;

    this.isLoading = true;

    this.procedureHealthcareService.PutStatus(idProcedureHealthcare, event.checked).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.isLoading = false;
        const procedureHealthcareToUpdate = this.listPriceProcedureHealthcareStruct?.find(x => x.idProcedureHealthcare === idProcedureHealthcare);

        if (procedureHealthcareToUpdate)
          procedureHealthcareToUpdate.isActive = event.checked;

        this.alertService.show('Sucesso', 'Status do convênio: ' + procedureHealthcareToUpdate.nameHealthcareAgreement + ' atualizado com sucesso.', AlertType.success);
      },
      error: (error) => {
        this.throwException(error);
        this.search();
      }
    });
  }

  openHistoryCardModal(idProcedureHealthcare: number) {
    if (this.isLoading)
      return;

    const dialogRef = this.dialog.open(PriceHistoryModalComponent, {
      data: {
        idProcedureHealthcare: idProcedureHealthcare
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  formatCurrency(event: any, item: PriceProcedureHealthcareStruct) {
    if (isNaN(parseFloat(event.target.value)))
      event.target.value = "0,00";

    let value = this.utilsHealthcareAgreements.formatCurrencyToString(event);
    item.valueFront = value.trim();
  }

  private throwException(error: string) {
    this.alertService.show('Erro inesperado', error, AlertType.error);
    this.isLoading = false;
  }
}