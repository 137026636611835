import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { TextTemplateService } from 'src/app/shared/services/API/meta-data/text-template.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { TextTemplateRequest } from 'src/app/shared/services/requests/meta-data/text-template.request';
import { UserLoginResponse } from 'src/app/shared/services/responses/user/auth.response';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-text-template-register',
  templateUrl: './text-template-register.component.html',
  styleUrls: ['./text-template-register.component.css']
})
export class TextTemplateRegisterComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private textTemplateService: TextTemplateService,
    private util: UtilService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.master;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.text_template;

  public model: FormGroup;
  public masks: Masks;
  public textTemplateRequest: TextTemplateRequest;

  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;
  public useQrCode: boolean = false;
  public trySubmit: boolean = false;
  
  public idTextTemplate: number;

  public quillConfiguration = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      [{ 'list': 'ordered'}, { 'list': 'check' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    
      [{ 'background': [] }],
    ],
  };

  ngOnInit(): void {
    this.isLoading = false;
  
    this.idTextTemplate == null;
    this.isUpdate == false;

    if (this.activatedRoute.snapshot.paramMap.get('idTextTemplate'))
      this.idTextTemplate = parseInt(this.activatedRoute.snapshot.paramMap.get('idTextTemplate'));

    this.model = this.formBuilder.group({
      textTemplateName: ['', [Validators.required, Validators.maxLength(50)]],
      textTemplateContent: ['', [Validators.required]],
    });

    if (this.idTextTemplate != null) {
      this.isFirstLoading = true;
      this.isUpdate = true;
      this.populateTextTemplateData();
    }
  }

  clickCancel() {
    this.router.navigate(['/master/text-template']);
  }

  submit() {
    this.trySubmit = true;
    if (this.isLoading) {
      return;
    }
    if (!this.model.valid) {
      if(this.model.get('textTemplateContent').value == null || this.model.get('textTemplateContent').value == ""){
        this.alertService.show('Erro', "O campo de texto é obrigatório. Insira um conteúdo para salvar.", AlertType.error);
        return
      }

      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }
    this.isLoading = true;
    
    this.textTemplateRequest = new TextTemplateRequest();
    this.textTemplateRequest.textTemplateName = this.model.get('textTemplateName').value;
    this.textTemplateRequest.textTemplateContent = this.model.get('textTemplateContent').value;
    
    if (this.isUpdate)
      this.updateTextTemplate();
    else
      this.createTextTemplate();
  }

  populateTextTemplateData() {
    this.textTemplateService.getByIdTextTemplate(this.idTextTemplate).subscribe({
      next: (response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.model.get('textTemplateName').setValue(response.textTemplate.textTemplateName);
      this.model.get('textTemplateContent').setValue(response.textTemplate.textTemplateContent);
     
      this.isLoading = false;
      this.isFirstLoading = false;
    },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  updateTextTemplate() {
    this.textTemplateService.updateTextTemplate(this.idTextTemplate, this.textTemplateRequest).subscribe({
      next: (response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/master/text-template']);
    },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  createTextTemplate() {
    this.textTemplateService.createTextTemplate(this.textTemplateRequest).subscribe({
      next: (response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/master/text-template']);

    },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}