import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MedicalRecordLookupService } from 'src/app/shared/services/API/medical-record/lookup.service';
import { TriageService } from 'src/app/shared/services/API/orchestrator-classification/triage.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { Gravity } from 'src/app/shared/services/responses/medical-record/lookup.response';
import { ListTriageStruct } from 'src/app/shared/services/structs/orchestrator-classification/list-triage.struct';

@Component({
  selector: 'app-telephone-classification',
  templateUrl: './telephone-classification.component.html',
  styleUrls: ['./telephone-classification.component.css']
})
export class TelephoneClassificationComponent implements OnInit {

  constructor(
    private triageService: TriageService,
    private alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
    private maskService: MaskService,
    private lookupService: MedicalRecordLookupService) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.classification;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.telephone_classification;
  public masks: Masks;

  public listTriage: ListTriageStruct[];
  public listGravity: Gravity[];
  public isLoading: boolean;
  public model: UntypedFormGroup;
  public fullSize: number;
  public pageIndex: number = 0;
  public initialDateString: string;
  public finalDateString: string;
  public initialDate: Date;
  public finalDate: Date;
  public idGravity: number;

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.populateLookup();
    this.pageIndex = 0;
    this.model = this.formBuilder.group({
      initialDate: [''],
      finalDate: [''],
      idGravity: [''],
    });
    this.search(null, null, null);
  }

  buttonSearch() {
    this.pageIndex = 0;
    this.paginator.firstPage();
    this.listTriage = [];
    let initialDate = this.model.get("initialDate").value ? this.model.get("initialDate").value.toString().split("") : null;
    this.initialDate = this.maskService.formatStringToDate(this.model.get("initialDate").value);
    this.initialDateString = initialDate ? initialDate[4] + initialDate[5] + initialDate[6] + initialDate[7] + "-" + initialDate[2] + initialDate[3] + "-" + initialDate[0] + initialDate[1] : null;
    let finalDate = this.model.get("finalDate").value ? this.model.get("finalDate").value.toString().split("") : null;
    this.finalDate = this.maskService.formatStringToDate(this.model.get("finalDate").value);
    this.finalDateString = finalDate ? finalDate[4] + finalDate[5] + finalDate[6] + finalDate[7] + "-" + finalDate[2] + finalDate[3] + "-" + finalDate[0] + finalDate[1] : null;
    this.idGravity = this.model.get("idGravity").value;
    this.search(this.initialDateString, this.finalDateString, this.idGravity);
  }

  changePage(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.listTriage = [];
    let initialDate = this.model.get("initialDate").value ? this.model.get("initialDate").value.toString().split("") : null;
    this.initialDate = this.maskService.formatStringToDate(this.model.get("initialDate").value);
    this.initialDateString = initialDate ? initialDate[4] + initialDate[5] + initialDate[6] + initialDate[7] + "-" + initialDate[2] + initialDate[3] + "-" + initialDate[0] + initialDate[1] : null;
    let finalDate = this.model.get("finalDate").value ? this.model.get("finalDate").value.toString().split("") : null;
    this.finalDate = this.maskService.formatStringToDate(this.model.get("finalDate").value);
    this.finalDateString = finalDate ? finalDate[4] + finalDate[5] + finalDate[6] + finalDate[7] + "-" + finalDate[2] + finalDate[3] + "-" + finalDate[0] + finalDate[1] : null;
    this.model.get("idGravity").setValue(this.idGravity);
    this.search(this.initialDateString, this.finalDateString, this.idGravity);
  }

  search(initialDate: string, finalDate: string, idGravity) {
    this.isLoading = true;
    this.triageService.getTriageList(this.pageIndex, initialDate, finalDate, idGravity).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.isLoading = false;
      this.listTriage = response.listTriageStructs;
      this.fullSize = response.fullsize;
    },
      (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  populateLookup() {
    this.lookupService.getLookup().subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.listGravity = response.listGravities;
    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }
}
