import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { LeanPanelResponse } from '../../responses/orchestrator-data-intelligence/lean-panel.response';

@Injectable({
    providedIn: 'root'
})
export class LeanPanelService extends CommonService {

    constructor(private router: Router, private httpClient: HttpClient) {
        super();
    }

    public getLeanPanelData(): Observable<LeanPanelResponse> {

        let uri = `LeanPanel`;

        return this.httpClient.get<LeanPanelResponse>(environment.urlApiOrchestratorDataIntelligence + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }
}