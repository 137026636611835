export class MedicineModel {
    public idMedicine: number;

    public idMedicinePresentationType: number;

    public idMedicineGroup: number;

    public idMedicineSubgroup: number;

    public medicineName: string;

    public medicineDescription: string;

    public datetimeInclusion: Date;

    public medicineCode: string;

    public idMedicineAdministrationType: number;

    public medicinePresentationOther: string;

    public medicineAdministrationOther: string;

    public isChecked:boolean;

    public isActive: boolean;

    public fastTrack: boolean;

    public idDilution: number;

    public idMeasurementUnit: number;

    public minimumAmount: number;
    
    public idKit: number;
}