import { Component, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ChartConfiguration, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { LineAxisStruct } from 'src/app/shared/services/structs/orchestrator-data-intelligence/line-axis.struct';

@Component({
  selector: 'app-plot-line',
  templateUrl: './plot-line.component.html',
  styleUrls: ['./plot-line.component.css']
})
export class PlotLineComponent implements OnInit {

  constructor() { }


  @Input() idSize: number;
  @Input() listLineGraphData: LineAxisStruct[];
  @Input() labelsDate: string[];
  public calcHeight: number = 150;

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  public chartType: ChartType = 'line';

  public lineChartData: ChartConfiguration['data'];

  ngOnInit(): void {
    this.calcHeight = 163.243 - (12.8378 * this.idSize);

    this.lineChartData = {
      datasets: this.listLineGraphData,
      labels: this.labelsDate
    };
  }

  public lineChartOptions: ChartConfiguration['options'] = {
    elements: {
      line: {
        tension: 0.5
      }
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      x: {},
      'y-axis-0':
      {
        position: 'left',
      }
    },
    plugins: {
      legend: { display: true },
    }
  };
}
