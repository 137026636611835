import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ReturnPatientRequest } from '../../requests/orchestrator-queue/return-patient.request';
import { ListQueueByEpisodeResponse } from '../../responses/orchestrator-queue/list-queue-by-episode.response';

@Injectable({
  providedIn: 'root'
})
export class QueueHistoryService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listQueueHistory(idEpisode: number): Observable<ListQueueByEpisodeResponse> {
    
    let uri = `QueueHistory/idEpisode/${idEpisode}?`
    

    return this.httpClient.get<ListQueueByEpisodeResponse>(environment.urlApiOrchestratorQueue + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public UpdateQueue(body:ReturnPatientRequest): Observable<ListQueueByEpisodeResponse> {
    
    let uri = `QueueHistory`
    

    return this.httpClient.patch<ListQueueByEpisodeResponse>(environment.urlApiOrchestratorQueue + uri, body,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
