import { MultiProfessionalConfigSectorStruct } from "../../../structs/multi-professional/multi-professional-config-sector.struc";

export class PostPutMultiProfessionalConfigRequest {
    public multiProfessionalConfigName: string;
    public isReleasablePharmacy: boolean;
    public listSector: MultiProfessionalConfigSectorStruct[];
    public listIdSectionFill: number[];
    public listIdVisibleField: number[];
    public listIdRequiredField: number[];
    public listIdMetaData: number[];
    public listIdRequiredMetaData: number[];
    public dischargeOutcome: boolean;
    public hideDataPatientHistory: boolean;
}