import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ExamTypeService } from 'src/app/shared/services/API/SADT/exam-type.service';
import { ExamTypeModel } from 'src/app/shared/services/models/SADT/exam-type.model';
import { UtilService } from 'src/app/shared/services/util.service';
import { ExamTypeDeleteModalComponent } from './exam-type-delete-modal/exam-type-delete-modal.component';
import { SadtTypeEnum } from 'src/app/shared/enum/sadt-type.enum';

@Component({
  selector: 'app-exam-type-list',
  templateUrl: './exam-type-list.component.html',
  styleUrls: ['./exam-type-list.component.css']
})
export class ExamTypeListComponent implements OnInit {

  constructor(private examTypeService: ExamTypeService,
    private router: Router,
    private alertService: AlertService,
    private utilService: UtilService,
    public dialog: MatDialog) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.sadt;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.master_exam_type;

  public listExamType: ExamTypeModel[];
  public isLoading: boolean;
  public isActive: boolean;
  public searchText: string;
  public sadtTypeOthers = SadtTypeEnum.Outros;

  ngOnInit(): void {
    this.isLoading = true;
    this.search();
  }
  search() {
    this.isLoading = true;
    if (this.searchText == "")
      this.searchText = null;

    this.examTypeService.listExamType(this.searchText, this.isActive).subscribe({
      next: (response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.listExamType = response.listExamType;

      this.isLoading = false;
    },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }

  openModal(idExamType) {
    const dialogRef = this.dialog.open(ExamTypeDeleteModalComponent, {
      data: {
        idExamType: idExamType
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.deleteExamType) {
        this.search();
      }
    });
  }
}