import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { MatDialog } from '@angular/material/dialog';
import { ReportService } from 'src/app/shared/services/API/orchestrator-patient/report.service';
import { FowardService } from 'src/app/shared/services/API/orchestrator-queue/foward.service';
import { RoomService } from 'src/app/shared/services/API/flow/room.service';
import { SectorService } from 'src/app/shared/services/API/flow/sector.service';
import { ViaCepService } from 'src/app/shared/services/API/integration/via-cep.service';
import { AllergyService } from 'src/app/shared/services/API/medical-record/allergy.service';
import { ClockService } from 'src/app/shared/services/API/orchestrator-queue/clock.service';
import { HealthUnitRelationService } from 'src/app/shared/services/API/integration-relation-config/health-unit-relation.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { IntegrationTypeEnum } from 'src/app/shared/enum/integration-type.enum';

@Component({
  selector: 'app-patient-register-master',
  templateUrl: './patient-register-master.component.html',
  styleUrls: ['./patient-register-master.component.css']
})

export class PatientRegisterMasterComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public fowardService: FowardService,
    public reportService: ReportService,
    public roomService: RoomService,
    public sectorService: SectorService,
    public router: Router,
    public viaCepService: ViaCepService,
    public allergyService: AllergyService,
    public clockService: ClockService,
    private alertService: AlertService,
    private healthUnitRelationService: HealthUnitRelationService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.frontdesk;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.frontdesk_patient_register;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public hasSiga: boolean;

  public idEpisode: number = null;
  public idSector: number = null;
  public idRoom: number = null;
  public ticket: string = '';
  public isUpdateFrontDesk: boolean = false;
  public showTissGuideButton: boolean = false;

  ngOnInit(): void {
    this.isLoading = true;
    this.isFirstLoading = true;

    if (this.activatedRoute.snapshot.paramMap.get('idEpisode'))
      this.idEpisode = parseInt(this.activatedRoute.snapshot.paramMap.get('idEpisode'));

    if (this.activatedRoute.snapshot.paramMap.get('isUpdateFrontdesk'))
      this.isUpdateFrontDesk = this.activatedRoute.snapshot.paramMap.get('isUpdateFrontdesk') == "true" ? true : false;

    if (this.activatedRoute.snapshot.paramMap.get('showTissGuideButton'))
      this.showTissGuideButton = this.activatedRoute.snapshot.paramMap.get('showTissGuideButton') == "true" ? true : false;

      
    this.idSector = parseInt(this.activatedRoute.snapshot.paramMap.get('idSector'));
    this.idRoom = parseInt(this.activatedRoute.snapshot.paramMap.get('idRoom'));
    this.ticket = this.activatedRoute.snapshot.paramMap.get('ticket');

    this.listHealthUnitRelationConfig();
  }


  listHealthUnitRelationConfig() {
    this.isLoading = true;

    this.healthUnitRelationService.listHealthUnitRelation(IntegrationTypeEnum.SIGA).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (response.listHealthUnitRelation && response.listHealthUnitRelation.length > 0)
          this.hasSiga = true;
        else
          this.hasSiga = false;

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}