import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MenuFunctionalityEnum } from 'src/app/shared/components/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MedicalRecordLookupService } from 'src/app/shared/services/API/medical-record/lookup.service';
import { PatientHistoryService } from 'src/app/shared/services/API/medical-record/patient-history.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { EpisodeStatus, Gravity } from 'src/app/shared/services/responses/medical-record/lookup.response';
import { PatientHistoryStruct } from 'src/app/shared/services/structs/medical-record/patient-history.struct';

@Component({
  selector: 'app-patient-history-list',
  templateUrl: './patient-history-list.component.html',
  styleUrls: ['./patient-history-list.component.css']
})
export class PatientHistoryListComponent implements OnInit {

  constructor(
    private patientHistoryService: PatientHistoryService,
    private alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
    private maskService: MaskService,
    private lookupService: MedicalRecordLookupService) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.patient_history;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.patient_history_list;
  public masks: Masks;
  public listHistory: PatientHistoryStruct[];
  public listGravity: Gravity[];
  public listOutcome: EpisodeStatus[];
  public isLoading: boolean;
  public model: UntypedFormGroup;
  public fullSize: number;
  public pageIndex: number = 0;
  public CPF: string;
  public CNS: string;
  public patientName: string;
  public idEpisode: number;
  public initialDateString: string;
  public finalDateString: string;
  public initialDate: Date;
  public finalDate: Date;
  public birthdate: Date;
  public birthdateString: string;
  public idGravity: number;
  public idOutcome: number;
  public evaded: boolean;
  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.populateLookup();
    this.pageIndex = 0;
    this.model = this.formBuilder.group({
      CPF: [''],
      CNS: [''],
      patientName: [''],
      idEpisode: [''],
      initialDate: [''],
      finalDate: [''],
      birthdate: [''],
      idGravity: [''],
      idOutcome: [''],
      evaded: [''],
    });
    this.search(null, null, null, null, null, null, null, null, null, null);
  }

  buttonSearch() {

    this.idEpisode = this.model.get("idEpisode").value;
    this.pageIndex = 0;
    if (this.paginator) this.paginator.firstPage();
    this.listHistory = [];
    this.CPF = this.model.get("CPF").value.replace(/[^0-9]+/g, '');
    this.CNS = this.model.get("CNS").value;
    this.patientName = this.model.get("patientName").value;
    this.initialDate = this.maskService.formatStringToDate(this.model.get("initialDate").value);
    this.finalDate = this.maskService.formatStringToDate(this.model.get("finalDate").value);
    this.birthdate = this.maskService.formatStringToDate(this.model.get("birthdate").value);
    this.initialDateString = this.maskService.formatDateToString(this.initialDate, null, true);
    this.finalDateString = this.maskService.formatDateToString(this.finalDate, null, true);
    this.birthdateString = this.maskService.formatDateToString(this.birthdate, null, true);
    this.idGravity = this.model.get("idGravity").value;
    this.idOutcome = this.model.get("idOutcome").value;
    this.evaded = this.model.get("evaded").value;
    this.search(this.CPF, this.CNS, this.patientName, this.idEpisode, this.initialDateString, this.finalDateString, this.birthdateString, this.idGravity, this.idOutcome, this.evaded);
  }

  changePage(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.listHistory = [];
    this.model.get("CPF").setValue(this.CPF);
    this.model.get("CNS").setValue(this.CNS);
    this.model.get("patientName").setValue(this.patientName);
    this.model.get("idEpisode").setValue(this.idEpisode);
    this.model.get("initialDate").setValue(this.maskService.formatDateToString(this.initialDate, false));
    this.model.get("finalDate").setValue(this.maskService.formatDateToString(this.finalDate, false));
    this.model.get("birthdate").setValue(this.maskService.formatDateToString(this.birthdate, false));
    this.model.get("idGravity").setValue(this.idGravity);
    this.model.get("idOutcome").setValue(this.idOutcome);
    this.model.get("evaded").setValue(this.evaded);
    this.search(this.CPF, this.CNS, this.patientName, this.idEpisode, this.initialDateString, this.finalDateString, this.birthdateString, this.idGravity, this.idOutcome, this.evaded);
  }

  search(CPF, CNS, patientName, idEpisode, initialDate: string, finalDate: string, birthdate: string, idGravity, idOutcome, evaded) {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.patientHistoryService.getHistory(this.pageIndex, CPF, CNS, patientName, idEpisode, initialDate, finalDate, birthdate, idGravity, idOutcome, evaded).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.isLoading = false;
      this.listHistory = response.patientHistory;
      this.fullSize = response.fullSize;
    },
      (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  populateLookup() {
    this.lookupService.getLookup().subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.listGravity = response.listGravities;
      this.listOutcome = response.listStatus;
    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }
}